import type { Position } from '@store/calendar/types';
import { LineType } from '@store/calendar/types';
import type { ConstructorParams, SidesSizes, Params, ActivityItem, TaskItem } from './types';
import { Color } from './types';

import { splitTextToTextsLines } from '../../../utils';
import { LINE_HEIGHT, GROUP_HEIGHT, PADDING } from '../../consts';

export class SidebarDrawer {
    private filledExpiredTaskIcon: HTMLImageElement;

    private canvas: HTMLCanvasElement;
    private params: Params;
    private startPosition: Position;
    private sidesSizes: SidesSizes;
    private currentPosition: Position;

    constructor({ canvas, params, startPosition, sidesSizes, filledExpiredTaskIcon }: ConstructorParams) {
        this.canvas = canvas;
        this.params = params;
        this.startPosition = startPosition;
        this.currentPosition = startPosition;
        this.sidesSizes = sidesSizes;
        this.filledExpiredTaskIcon = filledExpiredTaskIcon;
    }

    public draw(): void {
        this.drawBackground();
        this.drawItems();
    }

    private get sidebarWith(): number {
        return this.sidesSizes.width;
    }

    private drawItems(): void {
        const items = this.params.items;

        items.forEach((item) => {
            switch (item.type) {
                case LineType.GroupTitle:
                    this.drawGroupTitleItem(item as ActivityItem);
                    break;
                case LineType.Activity:
                    this.drawActivityItem(item as ActivityItem);
                    break;
                case LineType.Task:
                    this.drawTaskItem(item as TaskItem);
                    break;
                default:
                    break;
            }
        });
    }

    private drawGroupTitleItem(item: ActivityItem) {
        const ctx = this.canvas.getContext('2d');

        const linePosition = {
            x: this.startPosition.x + PADDING,
            y: this.currentPosition.y + GROUP_HEIGHT,
        };

        const lineSidesSizes = {
            width: this.sidebarWith - PADDING * 2,
            height: 1,
        };

        const textPosition = {
            x: linePosition.x,
            y: linePosition.y - LINE_HEIGHT / 2,
        };

        ctx.save();
        this.drawText(item.name, textPosition, null, 40);
        this.drawSeparatorLine(linePosition, lineSidesSizes);
        ctx.restore();

        this.currentPosition.y = linePosition.y;
    }

    private drawActivityItem(item: ActivityItem): void {
        const ctx = this.canvas.getContext('2d');

        const linePosition = {
            x: this.startPosition.x + PADDING * 2,
            y: this.currentPosition.y + LINE_HEIGHT,
        };

        const textPosition = {
            x: linePosition.x,
            y: linePosition.y - LINE_HEIGHT / 2 - 2,
        };

        const lineSidesSizes = {
            width: this.sidebarWith - PADDING * 3,
            height: 1,
        };

        ctx.save();

        if (item.hasExpiredStages) {
            this.drawExpiredTaskIndicator({ x: linePosition.x - 7, y: linePosition.y - LINE_HEIGHT / 2 });
        }

        this.drawText(item.name, textPosition, null, 35);
        this.drawSeparatorLine(linePosition, lineSidesSizes);

        ctx.restore();

        this.currentPosition.y = linePosition.y;
    }

    private drawTaskItem(item: TaskItem): void {
        const ctx = this.canvas.getContext('2d');

        const linePosition = {
            x: this.startPosition.x + PADDING * 3,
            y: this.currentPosition.y + LINE_HEIGHT,
        };

        const textPosition = {
            x: linePosition.x,
            y: linePosition.y - LINE_HEIGHT / 2 - 2,
        };

        const lineSidesSizes = {
            width: this.sidebarWith - PADDING * 4,
            height: 1,
        };

        const textColor = item.deadlineExpired ? Color.RED : item.taskIsClosed ? Color.GREEN : Color.BLACK;

        ctx.save();

        this.drawText(item.name, textPosition, textColor, 30);
        this.drawSeparatorLine(linePosition, lineSidesSizes);

        if (item.deadlineExpired) {
            this.drawExpiredTaskIcon({ x: linePosition.x - 10, y: linePosition.y - LINE_HEIGHT / 2 });
        }

        ctx.restore();

        this.currentPosition.y = linePosition.y;
    }

    private drawExpiredTaskIcon(position: Position): void {
        const ctx = this.canvas.getContext('2d');
        const expiredTaskIconPositionX = position.x - this.filledExpiredTaskIcon.width / 2;
        const expiredTaskIconPositionY = position.y - this.filledExpiredTaskIcon.height / 2;

        ctx.drawImage(this.filledExpiredTaskIcon, expiredTaskIconPositionX, expiredTaskIconPositionY);
    }

    private drawBackground(): void {
        const ctx = this.canvas.getContext('2d');

        const scale = 1;
        const x = this.startPosition.x;
        const y = this.startPosition.y;
        const width = this.sidesSizes.width;
        const height = this.sidesSizes.height;
        const radius = 5;

        ctx.save();
        ctx.setTransform(scale, 0, 0, scale, 0, 0);
        ctx.fillStyle = '#ffffff';
        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + width - radius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        ctx.lineTo(x + width, y + height - radius);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        ctx.lineTo(x + radius, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        ctx.lineTo(x, y + radius);
        ctx.quadraticCurveTo(x, y, x + radius, y);
        ctx.fill();
        ctx.closePath();
        ctx.restore();
    }

    private drawText(text: string, position: Position, color?: Color, maxLineLength?: number): void {
        const ctx = this.canvas.getContext('2d');
        const textLines = splitTextToTextsLines(text, maxLineLength || 40);

        ctx.fillStyle = color || Color.BLACK;
        ctx.font = `12px Open Sans`;

        if (textLines[1].length) {
            ctx.fillText(textLines[0], position.x, position.y);
            ctx.fillText(textLines[1], position.x, position.y + 14);
        } else {
            ctx.fillText(textLines[0], position.x, position.y + 6);
        }
    }

    private drawSeparatorLine(position: Position, sidesSizes: SidesSizes): void {
        const ctx = this.canvas.getContext('2d');

        ctx.fillStyle = '#f1f1f5';
        ctx.fillRect(position.x, position.y, sidesSizes.width, sidesSizes.height);
    }

    private drawExpiredTaskIndicator(position: Position): void {
        const ctx = this.canvas.getContext('2d');
        const radius = 3;

        ctx.beginPath();
        ctx.arc(position.x, position.y, radius, 0, 2 * Math.PI, false);
        ctx.fillStyle = '#e63900';
        ctx.fill();
        ctx.closePath();
    }
}
