// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-components-TelegramButton-Popup-Header-Title-_Title__root{letter-spacing:0.3px;color:#000000;font-family:'Open Sans';font-size:16px;font-weight:600;line-height:1.38}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/components/TelegramButton/Popup/Header/Title/Title.scss"],"names":[],"mappings":"AAAA,kGACI,oBAAqB,CAErB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,eAAgB,CAChB,gBAAiB","sourcesContent":[".root {\n    letter-spacing: 0.3px;\n\n    color: #000000;\n\n    font-family: 'Open Sans';\n    font-size: 16px;\n    font-weight: 600;\n    line-height: 1.38;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-components-TelegramButton-Popup-Header-Title-_Title__root"
};
export default ___CSS_LOADER_EXPORT___;
