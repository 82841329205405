import * as React from 'react';
import { MenuItem, OpacityTransition } from 'sber-marketing-ui';

import { PageMode } from '@store/calendar/types';

import { QuickAccessIcons } from '@common/QuickAccessIcons';

import { CalendarFilters } from './CalendarFilters';
import { WeekDigest } from './WeekDigest';
import { CalendarUploadButton } from './CalendarUploadButton';
import { Chart } from './Chart';
import { SwitchKeyActivityButton } from './SwitchKeyActivityButton';

export const enum PageUrl {
    WeekDigest = '/calendar/week',
    Chart = '/calendar/chart',
}
import * as style from './CalendarPage.scss';

const TOP_LINK_OPTIONS: LinkOptions[] = [
    {
        qaId: 'calendarWeekDigestButton',
        link: PageUrl.WeekDigest,
        title: 'Запуски недели',
    },
    {
        qaId: 'calendarChartButton',
        link: PageUrl.Chart,
        title: 'Кaлендарь',
    },
];

interface LinkOptions {
    qaId?: string;
    title: string;
    link: PageUrl;
    contains?: PageUrl[];
    disabled?: boolean;
}

interface Props {
    currentPage: PageMode;
    preloader: boolean;
}

export const CalendarPage = ({ currentPage, preloader }: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <OpacityTransition start={!preloader}>
                {!preloader && (
                    <React.Fragment>
                        {currentPage == PageMode.WeekDigest && <WeekDigest />}

                        {currentPage == PageMode.Chart && <Chart />}
                    </React.Fragment>
                )}
            </OpacityTransition>
        </div>
    );
};

interface HeaderProps {
    currentUrl?: string;
    isRequestInProgress: boolean;
}

export const HeaderTop = ({ isRequestInProgress, currentUrl }: HeaderProps): JSX.Element => {
    return (
        <div
            className={style.header}
            {...{
                'qa-id': 'calendarHeader',
            }}
        >
            <div className={style.leftMenu}>
                <div className={style.filters}>
                    <CalendarFilters />
                </div>

                <div className={style.keyActivitiesFilter}>
                    <SwitchKeyActivityButton />
                </div>
            </div>

            {TOP_LINK_OPTIONS.map((item) => (
                <MenuItem
                    qaId={item.qaId}
                    key={item.link}
                    currentUrl={currentUrl}
                    link={item.link}
                    disabled={item.disabled}
                    active={item.link == currentUrl}
                >
                    <span>{item.title}</span>
                </MenuItem>
            ))}

            <div className={style.rightMenu}>
                {!isRequestInProgress && (
                    <div className={style.quickAccessIcons}>
                        <QuickAccessIcons />
                    </div>
                )}

                <div className={style.calendarUploadButtonPosition}>
                    <CalendarUploadButton />
                </div>
            </div>
        </div>
    );
};
