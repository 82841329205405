import * as React from 'react';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';

import { StoreState } from '@store';
import { getAllUsers } from '@store/appUsers';
import { getDepartmentById } from '@store/departments';
import { getLoginUser } from '@store/user/selector';

import { Participant, PersonDataTheme } from './Participant';

interface Props extends OwnProps, Partial<MapProps> {}

interface OwnProps {
    id: number;
    canRemove: boolean;
    onParticipantClick?(userId: number): void;
    removeParticipant(participantId: number): void;
}

interface MapProps {
    loginnedUserId: number;
    name: string;
    department: string;
}

interface State {
    theme: PersonDataTheme;
}

@(connect(mapStateToProps) as any)
export class ParticipantContainer extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            theme: PersonDataTheme.TRANSLUCENT,
        };
    }

    public render(): JSX.Element {
        const { id, name, department, canRemove, loginnedUserId } = this.props;

        const { theme } = this.state;

        return (
            <Participant
                allowMention={id !== loginnedUserId}
                theme={theme}
                name={name}
                department={department}
                canRemove={canRemove}
                onParticipantClick={this.onParticipantClick}
                onRemoveParticipantClick={this.onRemoveParticipantClick}
                onMouseOver={this.onMouseOver}
                onMouseLeave={this.onMouseLeave}
            />
        );
    }

    @autobind
    private onMouseOver(): void {
        this.setState(() => ({ theme: PersonDataTheme.THIN_DEPARTMENT }));
    }

    @autobind
    private onMouseLeave(): void {
        this.setState(() => ({ theme: PersonDataTheme.TRANSLUCENT }));
    }

    @autobind
    private onParticipantClick(): void {
        const { id, onParticipantClick } = this.props;

        if (onParticipantClick) {
            this.props.onParticipantClick(id);
        }
    }

    @autobind
    private onRemoveParticipantClick(): void {
        this.props.removeParticipant(this.props.id);
    }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): MapProps {
    const user = getAllUsers(state).find((user) => user.id === ownProps.id);
    const departmentName = user.departmentId ? getDepartmentById(state, user.departmentId)?.name : null;
    const loginnedUserId = getLoginUser(state).attributes.id;

    return {
        loginnedUserId,
        name: `${user.firstName} ${user.secondName}`,
        department: departmentName,
    };
}
