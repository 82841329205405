import * as React from 'react';
import * as style from './Params.scss';

interface Props {
    data: ParamProps[];
}

export const Params: React.FC<Props> = ({ data }) => {
    return (
        <div className={style.root}>
            {data.map(({ name, value }) => (
                <Param key={`${name}-${name}`} name={name} value={value} />
            ))}
        </div>
    );
};

interface ParamProps {
    name: string;
    value: JSX.Element | React.ReactText;
}

const Param: React.FC<ParamProps> = ({ name, value }) => {
    return (
        <div className={style.param}>
            <div className={style.paramName}>{name}:&nbsp;</div>
            <div className={style.paramValue}>{value}</div>
        </div>
    );
};
