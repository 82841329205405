import { Dictionary } from 'lodash';
import { UserResponseParams } from 'sber-marketing-types/frontend';

import { LoadingStatus } from '@store/commonTypes';

export interface UsersState {
    entities: UserResponseParams[];
    byIds: Dictionary<UserResponseParams>;
    stores: Record<StoreTypes, EntitiesStore>;
}

export interface EntitiesStore {
    fetchersCount: number;
    loadingStatus: LoadingStatus;
    ids: number[];
}

export const enum StoreTypes {
    GENERAL = 'GENERAL',

    MY_TASKS_EXECUTORS_FILTER = 'MY_TASKS_EXECUTORS_FILTER',
    MY_TASKS_AUTHORS_FILTER = 'MY_TASKS_AUTHORS_FILTER',

    AVAILABLE_ACTIVITIES_RESPONSIBLES_FILTER = 'AVAILABLE_ACTIVITIES_RESPONSIBLES_FILTERS',
    AVAILABLE_ACTIVITIES_AUTHORS_FILTER = 'AVAILABLE_ACTIVITIES_AUTHORS_FILTER',

    MY_ACTIVITIES_RESPONSIBLES_FILTER = 'MY_ACTIVITIES_RESPONSIBLES_FILTER',
    MY_ACTIVITIES_AUTHORS_FITLER = 'MY_ACTIVITIES_AUTHORS_FILTER',

    ACTIVITY_PAGE_EXECUTORS_FILTER = 'ACTIVITY_PAGE_EXECUTORS_FILTER',
    ACTIVITY_PAGE_AUTHORS_FITLER = 'ACTIVITY_PAGE_AUTHORS_FILTER',

    NEWS_FILTER = 'NEWS_FILTER',
    DASHBOARD_SEARCH = 'DASHBOARD_SEARCH',
}

export interface LoadUsersParams {
    store: StoreTypes;
    ids?: number[];
}

export interface SetStoreIdsParams {
    store: StoreTypes;
    ids: number[];
}

export interface SetLoadingStatusParams {
    store: StoreTypes;
    status: LoadingStatus;
}

export type LoadEntitiesPayload = UserResponseParams[];
