import * as lodash from 'lodash';

import type { CreativeRequestTableItem, CreativeRequestTableItemTask, CreativeRequestTableSettings } from '@api';

import { IconType } from 'sber-marketing-ui';

export const enum ColumnHeaderType {
    Text = 'text',
}

export const enum LineType {
    SumWithoutVat = 'sumWithoutVat',
    Note = 'note',
}

export const enum ColumnName {
    Name = 'name',
    Total = 'total',
    InWork = 'inWork',
    OnSignature = 'onSignature',
    InPayment = 'inPayment',
    Paid = 'paid',
    ContractLimit = 'contractLimit',
    Delta = 'delta',
}

export const enum CellType {
    Text = 'text',
    Input = 'input',
    Textarea = 'textarea',
    FundsInput = 'funds_input',
    Select = 'select',
    FundsSelect = 'fundsSelect',
    Datepicker = 'datepicker',
}

export interface AccessorParams {
    id: string;
    lot: number;
    lines: CreativeRequestTableItem[];
    subLines: CreativeRequestTableItemTask[];
    tableSettings: CreativeRequestTableSettings;
}

type Accessor<T> = (params: AccessorParams) => Promise<T>;
type AccessorWithValue<T> = (params: AccessorParams, value: any) => Promise<T>;

export type ValueAccessor = Accessor<any>;
export type TitleAccessor = Accessor<React.ReactText>;
export type ValueSetter = AccessorWithValue<void>;
export type ItemsAccessor = Accessor<any[]>;
export type DescriptionAccessor = Accessor<string>;
export type ReadOnlyAccessor = Accessor<boolean>;
export type CustomStyleAccessor = Accessor<React.CSSProperties>;
export type ValidationAccessor = AccessorWithValue<boolean>;
export type SuggestItemsAccessor = Accessor<string[]>;
export type IconAccessor = Accessor<{ type: IconType; color: string; size: number }>;

export interface ColumnParams {
    title: string;
    headerType: ColumnHeaderType;
    type: Record<LineType, CellType>;
    disableFilters?: Partial<Record<LineType, boolean>>;
    defaultWidth: number;
    disableWidthChange?: boolean;
    readOnly?: Partial<Record<LineType, ReadOnlyAccessor | boolean>> | ReadOnlyAccessor | boolean;
    customStyle?: Partial<Record<LineType, CustomStyleAccessor>> | CustomStyleAccessor;
    linkedColumns?: Partial<Record<LineType, ColumnName[]>>;
    getTitle?: Partial<Record<LineType, TitleAccessor>>;
    getValue: Partial<Record<LineType, ValueAccessor>> | ValueAccessor;
    setValue?: Partial<Record<LineType, ValueSetter>>;
    getItems?: Partial<Record<LineType, ItemsAccessor>> | ItemsAccessor;
    getSuggestItems?: Partial<Record<LineType, SuggestItemsAccessor>> | SuggestItemsAccessor;
    getDescription?: Partial<Record<LineType, DescriptionAccessor>> | DescriptionAccessor;
    getIcon?: Partial<Record<LineType, IconAccessor>> | IconAccessor;
}

export const ColumnsConfig: { [columnName: string]: ColumnParams } = {
    [ColumnName.Name]: {
        title: 'Статус',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.SumWithoutVat]: CellType.Text,
            [LineType.Note]: CellType.Text,
        },
        defaultWidth: 105,
        readOnly: {
            [LineType.SumWithoutVat]: true,
            [LineType.Note]: true,
        },
        getValue: {
            [LineType.SumWithoutVat]: async (params: AccessorParams) => 'Сумма без НДС',
            [LineType.Note]: async (params: AccessorParams) => 'Примечание',
        },
    },
    [ColumnName.Total]: {
        title: 'Всего',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.SumWithoutVat]: CellType.FundsInput,
            [LineType.Note]: CellType.Text,
        },
        defaultWidth: 154,
        readOnly: {
            [LineType.SumWithoutVat]: true,
            [LineType.Note]: true,
        },
        getValue: {
            [LineType.SumWithoutVat]: async (params: AccessorParams) => {
                const subLines = params.subLines.filter((item) => !item.model.isArchived);

                const sum = lodash.sumBy(subLines, (item) => {
                    const status = item.model.status?.value;

                    const statuses = [
                        'Сбор информации в Агентстве',
                        'Подготовка документов в Агентстве',
                        'Отправлен в ПАО',
                        'Доработка по комментарию',
                        'На подписи',
                        'В оплате',
                        'Оплачено',
                    ];

                    return statuses.includes(status) ? formatMoney(item.model.fact) : 0;
                });

                return sum;
            },
            [LineType.Note]: async (params: AccessorParams) => '“В работе” + “На подписи” + “В оплате” + “Оплачено”',
        },
    },
    [ColumnName.InWork]: {
        title: 'В работе',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.SumWithoutVat]: CellType.FundsInput,
            [LineType.Note]: CellType.Text,
        },
        defaultWidth: 150,
        readOnly: {
            [LineType.SumWithoutVat]: true,
            [LineType.Note]: true,
        },
        getValue: {
            [LineType.SumWithoutVat]: async (params: AccessorParams) => {
                const subLines = params.subLines.filter((item) => !item.model.isArchived);

                const sum = lodash.sumBy(subLines, (item) => {
                    const status = item.model.status?.value;

                    const statuses = [
                        'Сбор информации в Агентстве',
                        'Подготовка документов в Агентстве',
                        'Отправлен в ПАО',
                        'Доработка по комментарию',
                    ];

                    return statuses.includes(status) ? formatMoney(item.model.fact) : 0;
                });

                return sum;
            },
            [LineType.Note]: async (params: AccessorParams) => '”Подготовка” + “Итерирование”',
        },
    },
    [ColumnName.OnSignature]: {
        title: 'На подписи',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.SumWithoutVat]: CellType.FundsInput,
            [LineType.Note]: CellType.Text,
        },
        defaultWidth: 150,
        readOnly: {
            [LineType.SumWithoutVat]: true,
            [LineType.Note]: true,
        },
        getValue: {
            [LineType.SumWithoutVat]: async (params: AccessorParams) => {
                const subLines = params.subLines.filter((item) => !item.model.isArchived);

                const sum = lodash.sumBy(subLines, (item) => {
                    const status = item.model.status?.value;

                    return status === 'На подписи' ? formatMoney(item.model.fact) : 0;
                });

                return sum;
            },
            [LineType.Note]: async (params: AccessorParams) => '“На подписи”',
        },
    },
    [ColumnName.InPayment]: {
        title: 'В оплате',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.SumWithoutVat]: CellType.FundsInput,
            [LineType.Note]: CellType.Text,
        },
        defaultWidth: 150,
        readOnly: {
            [LineType.SumWithoutVat]: true,
            [LineType.Note]: true,
        },
        getValue: {
            [LineType.SumWithoutVat]: async (params: AccessorParams) => {
                const subLines = params.subLines.filter((item) => !item.model.isArchived);

                const sum = lodash.sumBy(subLines, (item) => {
                    const status = item.model.status?.value;

                    return status === 'В оплате' ? formatMoney(item.model.fact) : 0;
                });

                return sum;
            },
            [LineType.Note]: async (params: AccessorParams) => '“В оплате”',
        },
    },
    [ColumnName.Paid]: {
        title: 'Оплачено',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.SumWithoutVat]: CellType.FundsInput,
            [LineType.Note]: CellType.Text,
        },
        defaultWidth: 150,
        readOnly: {
            [LineType.SumWithoutVat]: true,
            [LineType.Note]: true,
        },
        getValue: {
            [LineType.SumWithoutVat]: async (params: AccessorParams) => {
                const subLines = params.subLines.filter((item) => !item.model.isArchived);

                const sum = lodash.sumBy(subLines, (item) => {
                    const status = item.model.status?.value;

                    return status === 'Оплачено' ? formatMoney(item.model.fact) : 0;
                });

                return sum;
            },
            [LineType.Note]: async (params: AccessorParams) => '“Оплачено”',
        },
    },
    [ColumnName.ContractLimit]: {
        title: 'Лимит договора',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.SumWithoutVat]: CellType.FundsInput,
            [LineType.Note]: CellType.Text,
        },
        defaultWidth: 150,
        readOnly: {
            [LineType.SumWithoutVat]: async (params: AccessorParams) =>
                params.tableSettings ? !params.tableSettings.model.setContractLimitLot1 : true,
            [LineType.Note]: true,
        },
        getValue: {
            [LineType.SumWithoutVat]: async (params: AccessorParams) =>
                formatMoney(params.tableSettings?.model.contractLimitLot1) || null,
            [LineType.Note]: async (params: AccessorParams) => 'Свободное числовое поле',
        },
        setValue: {
            [LineType.SumWithoutVat]: async (params: AccessorParams, value: any) => {
                await params.tableSettings.model.setContractLimitLot1({
                    contractLimitLot1: formatMoneyInput(value),
                });
            },
        },
    },
    [ColumnName.Delta]: {
        title: 'Дельта',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.SumWithoutVat]: CellType.FundsInput,
            [LineType.Note]: CellType.Text,
        },
        defaultWidth: 150,
        readOnly: {
            [LineType.SumWithoutVat]: true,
            [LineType.Note]: true,
        },
        getValue: {
            [LineType.SumWithoutVat]: async (params: AccessorParams) => {
                const subLines = params.subLines.filter((item) => !item.model.isArchived);

                const total = lodash.sumBy(subLines, (item) => {
                    const status = item.model.status?.value;

                    const statuses = [
                        'Сбор информации в Агентстве',
                        'Подготовка документов в Агентстве',
                        'Отправлен в ПАО',
                        'Доработка по комментарию',
                        'На подписи',
                        'В оплате',
                        'Оплачено',
                    ];

                    return statuses.includes(status) ? formatMoney(item.model.fact) : 0;
                });

                const contractLimit =
                    params.lot === 1
                        ? formatMoney(params.tableSettings?.model.contractLimitLot1) || 0
                        : formatMoney(params.tableSettings?.model.contractLimitLot2) || 0;

                return contractLimit - total;
            },
            [LineType.Note]: async (params: AccessorParams) => '“Лимит договора” - “Всего”',
        },
    },
};

export const tableColumns: ColumnName[] = [
    ColumnName.Name,
    ColumnName.Total,
    ColumnName.InWork,
    ColumnName.OnSignature,
    ColumnName.InPayment,
    ColumnName.Paid,
    ColumnName.ContractLimit,
    ColumnName.Delta,
];

export const leftFixedColumns: ColumnName[] = [];
export const rightFixedColumns: ColumnName[] = [];

function formatMoney(value: React.ReactText): number {
    if (!value) {
        return 0;
    }

    const parsedValue = typeof value === 'string' ? parseInt(value, 10) : value;

    return parsedValue / 100;
}

function formatMoneyInput(value: React.ReactText): number {
    if (!value) {
        return 0;
    }

    const parsedValue = typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value;

    return Math.round(parsedValue * 100);
}
