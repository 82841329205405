import * as React from 'react';
import { WithTooltip } from 'sber-marketing-ui';

import { useTextOverflowCheck } from '@common/hooks';

import * as styles from './TitleWithCounter.scss';

interface Props {
    title: string;
    count: number;
}

export function TitleWithCounter({ title, count }: Props): JSX.Element {
    const titleRef = React.useRef<HTMLSpanElement>();
    const { horizontal: showTooltip } = useTextOverflowCheck(titleRef);

    return (
        <div className={styles.root}>
            <WithTooltip spanWrapper hidden={!showTooltip} content={title}>
                <span ref={titleRef} className={styles.overflow}>
                    {title}
                </span>
            </WithTooltip>

            <div className={styles.counter}>{count}</div>
        </div>
    );
}
