import * as React from 'react';
import { createPortal } from 'react-dom';

import { IconType } from 'sber-marketing-ui';

import { Button, ButtonProps, Buttons, Card, CardProps, IconChip, ModalHeader, modalsRef } from '@common/components';

export interface ModalProps extends Omit<CardProps, 'title'> {
    title?: React.ReactNode;
    buttons?: (Omit<ButtonProps, 'key'> & Required<Pick<ButtonProps, 'key'>>)[];
    hide?: boolean;
    width?: React.CSSProperties['width'];
    onClose?: () => void;
}

export function Modal({ hide, title, buttons, width, children, style, onClose, ...props }: ModalProps) {
    if (!modalsRef.current) {
        throw Error('Add <Modals /> as a global element');
    }

    if (hide) return null;

    const header =
        onClose || title ? (
            <ModalHeader justify="space-between">
                {title}
                {onClose && <IconChip onClick={onClose} flat ghost aligned icon={IconType.CROSS} />}
            </ModalHeader>
        ) : null;

    return createPortal(
        <Card gap={16} vertical padding={16} {...props} style={{ width, ...style }}>
            {header}
            {children}
            {buttons && (
                <Buttons>
                    {buttons.map((button) => (
                        <Button flex {...button} />
                    ))}
                </Buttons>
            )}
        </Card>,
        modalsRef.current,
    );
}
