// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-common-Body-_Body__root{display:grid;padding:5px 16px;cursor:pointer;transition:box-shadow 0.07s ease-out;border-radius:4px;background:#fefefe;box-shadow:0 1px 4px rgba(0,0,0,0.1);grid-template-columns:auto;grid-template-rows:max-content max-content;grid-row-gap:4px}.src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-common-Body-_Body__root:hover{box-shadow:0 1px 8px rgba(0,0,0,0.1)}.src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-common-Body-_Body__externalBlock{position:fixed}\n", "",{"version":3,"sources":["webpack://./src/client/modules/dashboard/RightSidebar/BudgetInfo/Corrections/templates/common/Body/Body.scss"],"names":[],"mappings":"AAAA,oGACI,YAAa,CAEb,gBAAiB,CAEjB,cAAe,CACf,oCAAqC,CAErC,iBAAkB,CAClB,kBAAmB,CACnB,oCAAwC,CAExC,0BAA2B,CAC3B,0CAA2C,CAC3C,gBAAiB,CAdrB,0GAiBQ,oCAAwC,CAC3C,6GAID,cAAe","sourcesContent":[".root {\n    display: grid;\n\n    padding: 5px 16px;\n\n    cursor: pointer;\n    transition: box-shadow 0.07s ease-out;\n\n    border-radius: 4px;\n    background: #fefefe;\n    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);\n\n    grid-template-columns: auto;\n    grid-template-rows: max-content max-content;\n    grid-row-gap: 4px;\n\n    &:hover {\n        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);\n    }\n}\n\n.externalBlock {\n    position: fixed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-common-Body-_Body__root",
	"externalBlock": "src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-common-Body-_Body__externalBlock"
};
export default ___CSS_LOADER_EXPORT___;
