import * as React from 'react';
import classNames from 'classnames';

import * as styles from './styles.scss';

interface Props {
    title: string;
    items: string[];
}

import { Item } from './Items';

export const List: React.FC<Props> = ({ title, items }) => {
    return (
        <div className={styles.root}>
            <div className={classNames(styles.title, styles.item)}>{title}</div>
            {items.map((value) => (
                <Item key={value} className={styles.item} value={value} />
            ))}
        </div>
    );
};
