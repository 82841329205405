import { combineReducers } from 'redux';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { LoadingStatus } from '@store/commonTypes';

import { ProfileMenuState, ProfileMenuRestState as State, ComponentState } from './types';
import * as actions from './actions/sync';

import { profileMenuVacationReducer } from './vacation';
import { profileMenuNotificationsReducer } from './notifications';

class Reducer {
    public static makeInitialState(): State {
        return {
            componentState: ComponentState.Closed,
            loadingStatus: LoadingStatus.NOT_LOADED,
        };
    }

    public static setComponentState(state: State, componentState: ComponentState): State {
        return Reducer.stateReducer(state, { componentState });
    }

    public static setLoadingStatus(state: State, loadingStatus: LoadingStatus): State {
        return Reducer.stateReducer(state, { loadingStatus });
    }

    private static stateReducer(state: State, payload: Partial<State>): State {
        return { ...state, ...payload };
    }
}

const profileMenuRestReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(actions.setComponentState, Reducer.setComponentState)
    .case(actions.setLoadingStatus, Reducer.setLoadingStatus);
export const profileMenuReducer = combineReducers<ProfileMenuState>({
    vacation: profileMenuVacationReducer,
    notifications: profileMenuNotificationsReducer,
    rest: profileMenuRestReducer,
});
