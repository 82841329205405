// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-BudgetItemHistoryWidget-HistoryItem-Content-RejectionComment-_RejectionComment__root{margin-top:8px;letter-spacing:0.15px;color:#7e8681;font-size:12px;line-height:16px}.src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-BudgetItemHistoryWidget-HistoryItem-Content-RejectionComment-_RejectionComment__comment{color:black}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetPlanning/LineModal/WidgetsGroups/BudgetItemHistoryWidget/HistoryItem/Content/RejectionComment/RejectionComment.scss"],"names":[],"mappings":"AAAA,kKACI,cAAe,CAEf,qBAAsB,CAEtB,aAAc,CAEd,cAAe,CACf,gBAAiB,CACpB,qKAGG,WAAY","sourcesContent":[".root {\n    margin-top: 8px;\n\n    letter-spacing: 0.15px;\n\n    color: #7e8681;\n\n    font-size: 12px;\n    line-height: 16px;\n}\n\n.comment {\n    color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-BudgetItemHistoryWidget-HistoryItem-Content-RejectionComment-_RejectionComment__root",
	"comment": "src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-BudgetItemHistoryWidget-HistoryItem-Content-RejectionComment-_RejectionComment__comment"
};
export default ___CSS_LOADER_EXPORT___;
