// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ExternalTransitionMenu-TransitionDirectionToggle-_TransitionDirectionToggle__root{display:flex}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ExternalTransitionMenu-TransitionDirectionToggle-_TransitionDirectionToggle__item{cursor:pointer;user-select:none;text-transform:uppercase;color:#7e8681;font-size:12px;font-weight:600;line-height:16px}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ExternalTransitionMenu-TransitionDirectionToggle-_TransitionDirectionToggle__item:not(:last-of-type){margin-right:12px}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ExternalTransitionMenu-TransitionDirectionToggle-_TransitionDirectionToggle__itemActive{cursor:default;color:black}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/ExternalTransitionMenu/TransitionDirectionToggle/TransitionDirectionToggle.scss"],"names":[],"mappings":"AAAA,2JACI,YAAa,CAChB,2JAGG,cAAe,CACf,gBAAiB,CACjB,wBAAyB,CAEzB,aAAc,CAEd,cAAe,CACf,eAAgB,CAChB,gBAAiB,CATrB,8KAYQ,iBAAkB,CACrB,iKAID,cAAe,CAEf,WAAY","sourcesContent":[".root {\n    display: flex;\n}\n\n.item {\n    cursor: pointer;\n    user-select: none;\n    text-transform: uppercase;\n\n    color: #7e8681;\n\n    font-size: 12px;\n    font-weight: 600;\n    line-height: 16px;\n\n    &:not(:last-of-type) {\n        margin-right: 12px;\n    }\n}\n\n.itemActive {\n    cursor: default;\n\n    color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ExternalTransitionMenu-TransitionDirectionToggle-_TransitionDirectionToggle__root",
	"item": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ExternalTransitionMenu-TransitionDirectionToggle-_TransitionDirectionToggle__item",
	"itemActive": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ExternalTransitionMenu-TransitionDirectionToggle-_TransitionDirectionToggle__itemActive"
};
export default ___CSS_LOADER_EXPORT___;
