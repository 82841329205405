import * as React from 'react';
import * as lodash from 'lodash';
import * as moment from 'moment';

import { ReservedFundsCorrection, CorrectionStatus, BudgetItem, Month, Funds, Persone } from '../types';

import { ReserveCorrectionCard, PlanData, ReserveData, PlanField, FondField, Warning } from './ReserveCorrectionCard';

import { monthIndexMap, orderOfMonth } from '../utils';
import { Utils } from '@common/Utils';

interface FundsByMonth {
    [month: number]: number;
}

interface Props extends ReservedFundsCorrection {}

export class ReserveCorrectionCardContainer extends React.Component<Props> {
    public render(): JSX.Element {
        const { serialNumber, creationTime, status, author, overwrittenSerialNumber } = this.props;

        const authorName = this.formatUserName(author);
        const approverName = this.getApproverName();

        const reserveData = this.makeReserveData();

        return React.createElement(ReserveCorrectionCard, {
            serialNumber,
            authorName,
            approverName,
            creationDate: this.formatDate(creationTime),
            planData: this.makePlanData(),
            reserveData,
            status,
            rejectComment: this.makeRejectComment(),
            overwrittenSerialNumber,
            warnings: this.makeWarnings(),
        });
    }

    private getApproverName(): string {
        const { status, expert, approver } = this.props;

        if (status === CorrectionStatus.NeedApproving) {
            return expert ? this.formatUserName(expert) : 'не задан';
        }

        return approver ? this.formatUserName(approver) : 'не задан';
    }

    private makeRejectComment(): string {
        const { comment, rejectedSerialNumber } = this.props;

        if (rejectedSerialNumber) {
            return `Корректировка отклонена автоматически после создания новой корректировки резерва для этой строки. Номер созданной корректировки - ${rejectedSerialNumber}`;
        }

        if (comment) {
            return comment;
        }

        return null;
    }

    private makeWarnings(): Warning[] {
        const { status } = this.props;

        // TODO В ответе graphQL висит поле __typename и для перебора свойст обхекта оно мешает
        delete this.budgetItem.plannedFunds['__typename'];
        const currentPlannedFunds = this.budgetItem.plannedFunds;

        const reserveFields = this.buildFondFieldsForReserveData();

        const beforeSumReserveFonds = reserveFields.reduce((acc, fond) => acc + fond.before, 0);
        const reservesSumDelta = reserveFields.reduce((acc, fond) => (fond.delta !== null ? acc + fond.delta : acc), 0);

        const sumCurrentPlannedFunds = lodash.values(currentPlannedFunds).reduce<number>((acc, fond) => {
            return acc + fond;
        }, 0);

        const sumReserveFunds = beforeSumReserveFonds + reservesSumDelta;

        const delta = sumReserveFunds - sumCurrentPlannedFunds;

        if (delta > 0) {
            return [
                {
                    delta,
                    budgetItemId: String(this.budgetItem.serialNumber),
                    isActive: status === CorrectionStatus.NeedApproving,
                },
            ];
        }

        return [];
    }

    private get budgetItem(): BudgetItem {
        const { status, budgetItemCurrent: budgetItemsCurrent, budgetItemBefore: budgetItemsBefore } = this.props;

        return status === CorrectionStatus.NeedApproving
            ? lodash.first(budgetItemsCurrent)
            : lodash.isEmpty(lodash.first(budgetItemsBefore))
            ? lodash.first(budgetItemsCurrent)
            : lodash.first(budgetItemsBefore);
    }

    private makePlanData(): PlanData {
        const { budgetItemCurrent: budgetItemsCurrent } = this.props;

        const currentBudgetItem = lodash.first(budgetItemsCurrent);

        // TODO В ответе graphQL висит поле __typename и для перебора свойст обхекта оно мешает
        delete this.budgetItem.plannedFunds['__typename'];
        const sumPlannedFunds = lodash.values(this.budgetItem.plannedFunds).reduce<number>((acc, fond) => {
            return acc + fond;
        }, 0);

        const fields: PlanField[] = [
            {
                name: 'ID план',
                value: currentBudgetItem.donors && currentBudgetItem.donors.join(', '),
            },
            {
                name: 'ID исполнение',
                value: currentBudgetItem.serialNumber,
            },
            {
                name: 'Тип проекта',
                value:
                    currentBudgetItem.dictionary &&
                    currentBudgetItem.dictionary.activity_type &&
                    currentBudgetItem.dictionary.activity_type.value,
            },
            {
                name: 'Направление',
                value:
                    currentBudgetItem.dictionary &&
                    currentBudgetItem.dictionary.direction &&
                    currentBudgetItem.dictionary.direction.value,
            },
            {
                name: 'Дата запуска',
                value: currentBudgetItem.realizationStart && this.formatDate(currentBudgetItem.realizationStart),
            },
            {
                name: 'Дата окончания',
                value: currentBudgetItem.realizationEnd && this.formatDate(currentBudgetItem.realizationEnd),
            },
            {
                name: 'ЦА/ТБ (Территория)',
                value: currentBudgetItem.dictionary?.regionality
                    ? Utils.getDictionaryValue(currentBudgetItem.dictionary.regionality)
                    : '',
            },
            {
                name: 'Территория',
                value: currentBudgetItem.dictionary?.territory
                    ? Utils.getDictionaryValue(currentBudgetItem.dictionary.territory)
                    : '',
            },
            {
                name: 'Статья',
                value:
                    currentBudgetItem.dictionary &&
                    currentBudgetItem.dictionary.item &&
                    currentBudgetItem.dictionary.item.value,
            },
            {
                name: 'Блок',
                value:
                    currentBudgetItem.dictionary &&
                    currentBudgetItem.dictionary.block &&
                    currentBudgetItem.dictionary.block.value,
                isWide: true,
            },
            {
                name: 'Инструмент',
                value:
                    currentBudgetItem.dictionary &&
                    currentBudgetItem.dictionary.tool &&
                    currentBudgetItem.dictionary.tool.value,
                isWide: true,
            },
        ];

        return {
            name: currentBudgetItem.sapComment,
            currentPlan: sumPlannedFunds,
            fields,
            comment: currentBudgetItem.comment,
        };
    }

    private makeReserveData(): ReserveData {
        const fields = this.buildFondFieldsForReserveData();

        return {
            beforeSumFonds: fields.reduce((acc, fond) => acc + fond.before, 0),
            delta: fields.reduce((acc, fond) => (fond.delta !== null ? acc + fond.delta : acc), 0),
            fields,
        };
    }

    private buildFondFieldsForReserveData(): FondField[] {
        const { params } = this.props;

        const fundsByMonths = this.buildFundsByMonths(this.budgetItem.reservedFunds);

        const changedMonthFonds = params;

        return orderOfMonth.map((month) => {
            const haveChangeCurrentMonthFund = changedMonthFonds.hasOwnProperty(month);

            if (haveChangeCurrentMonthFund) {
                return this.buildFondField({
                    before: fundsByMonths[month],
                    after: changedMonthFonds[month],
                    month,
                });
            }

            return this.buildFondField({
                before: fundsByMonths[month],
                after: fundsByMonths[month],
                month,
            });
        });
    }

    private buildFondField({ before, after, month }: { before: number; after: number; month: Month }): FondField {
        const delta = after - before;
        return {
            monthIndex: monthIndexMap[month],
            before,
            after,
            delta,
            deltaIsPositive: delta >= 0,
        };
    }

    private buildFundsByMonths(funds: Funds = {}): FundsByMonth {
        return orderOfMonth.reduce(
            (fundsByMonth, month) => ({
                [month]: funds[month] || 0,
                ...fundsByMonth,
            }),
            {},
        );
    }

    private formatUserName(user: Persone): string {
        return `${user.firstName} ${user.secondName}`;
    }

    private formatDate(date: string | Date): string {
        return moment(date).format('DD.MM.YYYY');
    }
}
