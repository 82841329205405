import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as lodash from 'lodash';

import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';

import { BudgetStageWidgetBehaviour } from './BudgetStageWidgetBehaviour';
import type { StoreState } from '@store';
import { getLoginUser } from '@store/user/selector';
import { setSelectedBudgetItemIds } from '@store/autopilot_legacy/actions';
import { getActivity, getSelectedBudgetItemIds } from '@store/autopilot_legacy/selectors';
import { WithProjectLinkedBudgetItems } from '@common/queries';
import { WithLinkedBudgetItems } from './WithLinkedBudgetItems';
import { WithBudgets } from './WithBudgets';

interface Props extends MapProps, DispatchProps {
    readOnly?: boolean;
}

interface MapProps {
    activity?: Activity;
    userOrganizationId?: string;
    selectedBudgetItemIds?: string[];
}

interface DispatchProps {
    setSelectedBudgetItemIds?: (ids: string[]) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BudgetStageWidgetConnector extends React.PureComponent<Props> {
    public render() {
        const { readOnly, activity, userOrganizationId, selectedBudgetItemIds, setSelectedBudgetItemIds } = this.props;

        return (
            <WithBudgets organizationIds={[userOrganizationId]}>
                {({ loading: loadingBudgets, budgets }) => (
                    <WithProjectLinkedBudgetItems activityId={lodash.get(activity, 'id')}>
                        {({ loading: loadingProectLinks, linkIds }) => (
                            <WithLinkedBudgetItems userOrganizationId={userOrganizationId} linkIds={linkIds}>
                                {({ loading: loadingLinkedBudgetItems, items }) =>
                                    React.createElement(BudgetStageWidgetBehaviour, {
                                        readOnly,
                                        loading: loadingProectLinks || loadingLinkedBudgetItems || loadingBudgets,
                                        activity,
                                        budgets,
                                        linkedBudgetItems: items,
                                        selectedBudgetItemIds,
                                        setSelectedBudgetItemIds,
                                    })
                                }
                            </WithLinkedBudgetItems>
                        )}
                    </WithProjectLinkedBudgetItems>
                )}
            </WithBudgets>
        );
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const currentUser = getLoginUser(state);

    return {
        activity: getActivity(state),
        userOrganizationId: currentUser.attributes.organizationId,
        selectedBudgetItemIds: getSelectedBudgetItemIds(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setSelectedBudgetItemIds,
        },
        dispatch,
    );
}
