// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-pivotTable-Lot1TotalTable-_TotalTable__root{display:block}\n", "",{"version":3,"sources":["webpack://./src/client/modules/pivotTable/Lot1TotalTable/TotalTable.scss"],"names":[],"mappings":"AAAA,gEACI,aAAc","sourcesContent":[".root {\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-pivotTable-Lot1TotalTable-_TotalTable__root"
};
export default ___CSS_LOADER_EXPORT___;
