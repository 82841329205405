import * as React from 'react';
import { Stage } from '@mrm/activity-stage';

import { useGetStagesQuery } from '@api';

import { OptionMultiple, OptionProps, Selector, SelectorProps } from '@common/components';

export interface StagesSelectorProps<M extends OptionMultiple>
    extends Omit<
        SelectorProps<
            M,
            OptionProps<Stage['stages'][number]>,
            Stage['stages'][number],
            'id',
            string,
            M extends true ? string[] : string
        >,
        'options' | 'optionId'
    > {
    activityId: number;
}

export function StagesSelector<M extends OptionMultiple>({ activityId, loading, ...props }: StagesSelectorProps<M>) {
    const { isLoading: isStagesLoading, data: stagesRaw = [] } = useGetStagesQuery({ activityIds: [activityId] });
    const stages = stagesRaw[0]?.stages || [];
    const options: OptionProps<Stage['stages'][number]>[] =
        [{ value: null, title: 'Без этапа' }, ...stages.map((value) => ({ value, title: value.dictionary.name }))] ||
        [];

    return <Selector {...props} optionId="id" options={options} loading={isStagesLoading || loading} />;
}
