import { createSelector } from 'reselect';
import { values } from 'lodash';

import type {
    FormState,
    Features,
    FormParams,
    FormText,
    FormMode,
    FormTextData,
    Text,
    FormTextOptions,
    TextOption,
} from './types';
import { LoadingStatus } from './types';

import type { StoreState } from '@store';

export const getGenerationTextFormState = (state: StoreState): FormState => state.autopilot.generationTextFormState;

export const getMode = createSelector(getGenerationTextFormState, (state): FormMode => state.mode);

export const getParams = createSelector(getGenerationTextFormState, (state): FormParams => state.params);

export const getTexts = createSelector(getGenerationTextFormState, (state): FormText => state.texts);

export const getFeatures = createSelector(getParams, (params): Features[] => {
    return values(params.featuresMap);
});

export const getTextOptions = createSelector(getTexts, (texts): FormTextOptions => {
    return texts.options;
});

export const getTextTitles = createSelector(getTextOptions, (options): TextOption[] => {
    return values(options.titles);
});

export const getTextContents = createSelector(getTextOptions, (options): TextOption[] => {
    return values(options.contents);
});

export const getSelectedTextTitles = createSelector(getTextTitles, (titles): TextOption[] =>
    titles.filter(({ selected }) => selected),
);

export const getSelectedTextContents = createSelector(getTextContents, (contents): TextOption[] =>
    contents.filter(({ selected }) => selected),
);

export const getTextData = createSelector(getTexts, (texts): FormTextData => texts.data);

export const getTextDataTitles = createSelector(getTextData, (data): Text[] => values(data.titles));

export const getTextDataContents = createSelector(getTextData, (data): Text[] => values(data.contents));

export const getParamsLoadingStatus = createSelector(getParams, (params): LoadingStatus => params.loading);

export const getTextsLoadingStatus = createSelector(getTexts, (texts): LoadingStatus => texts.loading);

export const canCreateTextOptions = createSelector(
    getFeatures,
    getParamsLoadingStatus,
    getTextsLoadingStatus,
    (features, paramsLoadingStatus, textsLoadingStatus): boolean => {
        return (
            !!features.map(({ value }) => value).join().length &&
            paramsLoadingStatus !== LoadingStatus.LOADING &&
            textsLoadingStatus !== LoadingStatus.LOADING
        );
    },
);

export const canSendSelectedTextOptions = createSelector(
    getSelectedTextTitles,
    getSelectedTextContents,
    (selectedTextTitleOptions, selectedTxtContentOptions): boolean => {
        const validTitle = selectedTextTitleOptions.length >= 3 && selectedTextTitleOptions.length <= 5;
        const validContents = selectedTxtContentOptions.length >= 3 && selectedTxtContentOptions.length <= 5;

        return validTitle && validContents;
    },
);
