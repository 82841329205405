import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { UserConfigType } from 'sber-marketing-types/openid';
import { uniq } from 'lodash';

import { SearchApi } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { saveUserConfig } from '@store/userConfig';
import { getCommonUserConfig } from '@store/userConfig/common';
import * as usersStore from '@store/users';

import {
    ComponentState,
    SearchQuery,
    SearchEntities,
    FileSearchResult,
    ActivitySearchResult,
    TaskSearchResult,
    CommentSearchResult,
} from './types';
import * as asyncActions from './actions/async';
import * as syncActions from './actions/sync';

import {
    areEntitiesLoading,
    getSearchState,
    getLoadingStatus,
    getTotalCount,
    getEntitiesCount,
    showEntitiesGroup,
} from './selectors';

const usersEntities = usersStore.StoreTypes.DASHBOARD_SEARCH;

function prepareIdsForFetching<T = string | number>(ids: T[]) {
    return uniq(ids.filter((id) => !!id));
}

function dateFormatter(date: string | Date): Date {
    if (date instanceof Date) {
        return date;
    }

    return date ? new Date(date) : null;
}

const limit = 10;

const entities = [SearchEntities.Files, SearchEntities.Activities, SearchEntities.Tasks, SearchEntities.Comments];

export const setVisibility = bindThunkAction<StoreState, boolean, void, Error>(
    asyncActions.setVisibility,
    async (isVisible, dispatch, getState) => {
        const state = getState();
        const { searchQuery } = getSearchState(state);
        const areEntitiesNotLoaded = entities.every(
            (entityType) => getLoadingStatus(state, entityType) === LoadingStatus.NOT_LOADED,
        );

        dispatch(syncActions.setVisibility(isVisible));

        if (areEntitiesNotLoaded) {
            dispatch(searchAll(searchQuery));
        }
    },
);

export const searchAll = bindThunkAction<StoreState, string, void, Error>(
    asyncActions.searchAll,
    async (query, dispatch, getState) => {
        dispatch(syncActions.setSearchQuery(query));

        const state = getState();
        const { isVisible } = getSearchState(state);
        const performSearch = isVisible && !areEntitiesLoading(state);
        if (performSearch) {
            dispatch(syncActions.clearLoadedEntities());

            if (query) {
                dispatch(syncActions.setState(ComponentState.ShowResults));
                dispatch(syncActions.setAnimatedGroup(null));
                dispatch(syncActions.setCollapseStatus(null));

                dispatch(searchFiles({ query, offset: 0 }));
                dispatch(searchActivities({ query, offset: 0 }));
                dispatch(searchTasks({ query, offset: 0 }));
                dispatch(searchComments({ query, offset: 0 }));
            } else {
                const { previousSearch } = getCommonUserConfig(state);

                dispatch(
                    syncActions.setState(
                        previousSearch
                            ? ComponentState.ShowEmptyQueryNotification
                            : ComponentState.ShowNoQueryNotification,
                    ),
                );
            }
        }
    },
);

export const searchActivities = bindThunkAction<StoreState, SearchQuery, void, Error>(
    asyncActions.searchActivities,
    async (query, dispatch, getState) => {
        const entitiesType = SearchEntities.Activities;
        const state = getState();
        const loadingStatus = getLoadingStatus(state, entitiesType);

        if (loadingStatus !== LoadingStatus.LOADING) {
            dispatch(
                syncActions.setLoadingStatus({
                    entitiesType,
                    status: LoadingStatus.LOADING,
                }),
            );

            const searchResult = await SearchApi.getActivities({
                ...query,
                limit,
            });

            let authorIds: number[] = [];
            const mapedEntities: ActivitySearchResult[] = searchResult.result.map((entity) => {
                authorIds.push(entity.authorId);

                return {
                    ...entity,
                    realizationStart: dateFormatter(entity.realizationStart),
                    realizationEnd: dateFormatter(entity.realizationEnd),
                    preparationDate: dateFormatter(entity.preparationDate),
                    debriefingDate: dateFormatter(entity.debriefingDate),
                };
            });
            authorIds = prepareIdsForFetching(authorIds);

            dispatch(usersStore.loadUsers({ store: usersEntities, ids: authorIds }));

            dispatch(
                syncActions.loadActivities({
                    result: mapedEntities,
                    total: searchResult.total,
                }),
            );
            dispatch(
                syncActions.setLoadingStatus({
                    entitiesType,
                    status: LoadingStatus.LOADED,
                }),
            );

            dispatch(performUpdatesAfterSearch(query.query));
        }
    },
);

export const searchTasks = bindThunkAction<StoreState, SearchQuery, void, Error>(
    asyncActions.searchTasks,
    async (query, dispatch, getState) => {
        const entitiesType = SearchEntities.Tasks;
        const state = getState();
        const loadingStatus = getLoadingStatus(state, entitiesType);

        if (loadingStatus !== LoadingStatus.LOADING) {
            dispatch(
                syncActions.setLoadingStatus({
                    entitiesType,
                    status: LoadingStatus.LOADING,
                }),
            );

            const searchResult = await SearchApi.getTasks({
                ...query,
                limit,
            });

            let authorIds: number[] = [];
            const mapedEntities: TaskSearchResult[] = searchResult.result.map((entity) => {
                authorIds.push(entity.authorId);

                return {
                    ...entity,
                    deadline: dateFormatter(entity.deadline),
                };
            });

            authorIds = prepareIdsForFetching(authorIds);

            dispatch(usersStore.loadUsers({ store: usersEntities, ids: authorIds }));

            dispatch(
                syncActions.loadTasks({
                    result: mapedEntities,
                    total: searchResult.total,
                }),
            );
            dispatch(
                syncActions.setLoadingStatus({
                    entitiesType,
                    status: LoadingStatus.LOADED,
                }),
            );

            dispatch(performUpdatesAfterSearch(query.query));
        }
    },
);

export const searchComments = bindThunkAction<StoreState, SearchQuery, void, Error>(
    asyncActions.searchComments,
    async (query, dispatch, getState) => {
        const entitiesType = SearchEntities.Comments;
        const state = getState();
        const loadingStatus = getLoadingStatus(state, entitiesType);

        if (loadingStatus !== LoadingStatus.LOADING) {
            dispatch(
                syncActions.setLoadingStatus({
                    entitiesType,
                    status: LoadingStatus.LOADING,
                }),
            );

            const searchResult = await SearchApi.getComments({
                ...query,
                limit,
            });

            let authorIds: number[] = [];
            const mappedEntities: CommentSearchResult[] = searchResult.result.map((entity) => {
                authorIds.push(entity.authorId);

                return {
                    ...entity,
                    createTime: dateFormatter(entity.createTime),
                    updateTime: dateFormatter(entity.updateTime),
                };
            });

            authorIds = prepareIdsForFetching(authorIds);
            dispatch(usersStore.loadUsers({ store: usersEntities, ids: authorIds }));

            dispatch(
                syncActions.loadComments({
                    result: mappedEntities,
                    total: searchResult.total,
                }),
            );
            dispatch(
                syncActions.setLoadingStatus({
                    entitiesType,
                    status: LoadingStatus.LOADED,
                }),
            );

            dispatch(performUpdatesAfterSearch(query.query));
        }
    },
);

export const searchFiles = bindThunkAction<StoreState, SearchQuery, void, Error>(
    asyncActions.searchFiles,
    async (query, dispatch, getState) => {
        const entitiesType = SearchEntities.Files;
        const state = getState();
        const loadingStatus = getLoadingStatus(state, entitiesType);

        if (loadingStatus !== LoadingStatus.LOADING) {
            dispatch(
                syncActions.setLoadingStatus({
                    entitiesType,
                    status: LoadingStatus.LOADING,
                }),
            );

            const searchResult = await SearchApi.getFiles({
                ...query,
                limit,
            });

            const mapedEntities: FileSearchResult[] = searchResult.result.map((entity) => ({
                ...entity,
                createTime: dateFormatter(entity.createTime),
                updateTime: dateFormatter(entity.updateTime),
            }));

            dispatch(
                syncActions.loadFiles({
                    result: mapedEntities,
                    total: searchResult.total,
                }),
            );
            dispatch(
                syncActions.setLoadingStatus({
                    entitiesType,
                    status: LoadingStatus.LOADED,
                }),
            );

            dispatch(performUpdatesAfterSearch(query.query));
        }
    },
);

export const performUpdatesAfterSearch = bindThunkAction<StoreState, string, void, Error>(
    asyncActions.performUpdatesAfterSearch,
    async (usedSearchQuery, dispatch, getState) => {
        const state = getState();

        const areEntitiesLoaded = entities.every(
            (entityType) => getLoadingStatus(state, entityType) === LoadingStatus.LOADED,
        );
        if (areEntitiesLoaded) {
            const { searchQuery } = getSearchState(state);

            if (searchQuery === usedSearchQuery) {
                if (getTotalCount(state)) {
                    const { searchQuery } = getSearchState(state);

                    dispatch(
                        saveUserConfig({
                            type: UserConfigType.Common,
                            payload: {
                                previousSearch: searchQuery,
                            },
                        }),
                    );
                } else {
                    dispatch(syncActions.setState(ComponentState.ShowNoResultsNotification));
                }
            } else {
                dispatch(searchAll(searchQuery));
            }

            dispatch(performSingleLoadedEntityCheck(null));
            dispatch(performShouldAnimateLogic(null));
        }
    },
);

export const performSingleLoadedEntityCheck = bindThunkAction<StoreState, void, void, Error>(
    asyncActions.performSingleLoadedEntityCheck,
    async (_, dispatch, getState) => {
        const state = getState();
        const entitiesCount = getEntitiesCount(state);
        const singleLoadedEntity = entities.find((entityType) =>
            entities.reduce((acc, et) => acc && (et === entityType ? !!entitiesCount[et] : !entitiesCount[et]), true),
        );

        dispatch(syncActions.setSingleLoadedEntityMode(!!singleLoadedEntity));
        if (singleLoadedEntity) {
            dispatch(syncActions.setCollapseStatus(singleLoadedEntity));
        }
    },
);

export const performShouldAnimateLogic = bindThunkAction<StoreState, void, void, Error>(
    asyncActions.performShouldAnimateLogic,
    async (_, dispatch, getState) => {
        const state = getState();
        const showActivitiesGroup = showEntitiesGroup(state, SearchEntities.Activities);
        const showTasksGroup = showEntitiesGroup(state, SearchEntities.Tasks);
        const showFilesGroup = showEntitiesGroup(state, SearchEntities.Files);

        const shouldAnimateTasksGroup = showActivitiesGroup;
        const shouldAnimateFilesGroup = showActivitiesGroup || showTasksGroup;
        const shouldAnimateCommentsGroup = showActivitiesGroup || showTasksGroup || showFilesGroup;

        dispatch(
            syncActions.setShouldAnimate({
                entitiesType: SearchEntities.Tasks,
                shouldAnimate: shouldAnimateTasksGroup,
            }),
        );

        dispatch(
            syncActions.setShouldAnimate({
                entitiesType: SearchEntities.Files,
                shouldAnimate: shouldAnimateFilesGroup,
            }),
        );

        dispatch(
            syncActions.setShouldAnimate({
                entitiesType: SearchEntities.Comments,
                shouldAnimate: shouldAnimateCommentsGroup,
            }),
        );
    },
);

export const triggerFetch = bindThunkAction<StoreState, void, void, Error>(
    asyncActions.triggerFetch,
    async (_, dispatch, getState) => {
        const state = getState();
        const { expandedGroup, entities, searchQuery: query } = getSearchState(state);
        const { totalCount, offset } = entities[expandedGroup];
        const loadingStatus = getLoadingStatus(state, expandedGroup);

        if (loadingStatus === LoadingStatus.LOADED && offset < totalCount) {
            switch (expandedGroup) {
                case SearchEntities.Activities:
                    dispatch(searchActivities({ query, offset }));
                    break;
                case SearchEntities.Tasks:
                    dispatch(searchTasks({ query, offset }));
                    break;
                case SearchEntities.Comments:
                    dispatch(searchComments({ query, offset }));
                    break;
                case SearchEntities.Files:
                    dispatch(searchFiles({ query, offset }));
                    break;
                default:
                    throw new Error(`Unknown searchEntity in triggerFetch: ${expandedGroup}`);
            }
        }
    },
);
