// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-_styles__root{display:grid;grid-template-columns:360px 1fr;background:#FFFFFF;border-radius:6px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/CreationForm/styles.scss"],"names":[],"mappings":"AAAA,wGACI,YAAa,CACb,+BAAgC,CAEhC,kBAAmB,CACnB,iBAAkB","sourcesContent":[".root {\n    display: grid;\n    grid-template-columns: 360px 1fr;\n\n    background: #FFFFFF;\n    border-radius: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
