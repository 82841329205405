import * as React from 'react';
import classNames from 'classnames';

import { formatMoneyForTooltip, formatMoney } from './utils';

import * as style from './Table.scss';

export interface FundRow {
    name: string;
    plan: number;
}

interface Props {
    fundsRows: FundRow[];
}

export const Table: React.FC<Props> = ({ fundsRows }) => {
    const sumPlansFunds = fundsRows.reduce((acc, { plan }) => acc + plan, 0);

    return (
        <div className={style.root}>
            <div className={classNames(style.tableRow, style.tableRowHeader)}>
                <div className={classNames(style.tableCell, style.tableCellAccent)}>Месяц</div>
                <div className={classNames(style.tableCell, style.tableCellAccent)}>План, Тыс, ₽</div>
            </div>
            {fundsRows.map(({ name, plan }) => (
                <div className={style.tableRow} key={name}>
                    <div className={style.tableCell}>{name}</div>
                    <div className={style.tableCell} title={formatMoneyForTooltip(plan)}>
                        {formatMoney(plan)}
                    </div>
                </div>
            ))}
            <div className={classNames(style.tableRow, style.tableRowFooter)}>
                <div className={classNames(style.tableCell, style.tableCellAccent)}>Итог, тыс. ₽</div>
                <div className={style.tableCell} title={formatMoneyForTooltip(sumPlansFunds)}>
                    {formatMoney(sumPlansFunds)}
                </div>
            </div>
        </div>
    );
};
