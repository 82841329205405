// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-layout-SkeletonText-_SkeletonText__root{display:inline-block;width:var(--skeleton-width, 100px);height:1.1em;vertical-align:middle}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/layout/SkeletonText/SkeletonText.scss"],"names":[],"mappings":"AAAA,8EACE,oBAAqB,CACrB,kCAAmC,CACnC,YAAa,CACb,qBAAsB","sourcesContent":[".root {\n  display: inline-block;\n  width: var(--skeleton-width, 100px);\n  height: 1.1em;\n  vertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-components-layout-SkeletonText-_SkeletonText__root"
};
export default ___CSS_LOADER_EXPORT___;
