import * as React from 'react';
import classNames from 'classnames';
import { OrganizationView } from 'sber-marketing-types/backend';
import { Budget, BudgetStatus } from '@mrm/budget';
import { Dropdown, Icon, IconType } from 'sber-marketing-ui';

import { DropdownItem } from './DropdownItem';

import * as styles from './BudgetAndOrganizationSelector.scss';

interface Props {
    budgets: Budget[];
    selectedBudgetId: string;
    budgetStatus: BudgetStatus;
    selectedOrganization: OrganizationView;
}

export function BudgetAndOrganizationSelector({
    budgets,
    budgetStatus,
    selectedBudgetId,
    selectedOrganization,
}: Props): JSX.Element {
    const selectedBudget = budgets.find((budget) => budget.id === selectedBudgetId);

    return (
        <div
            {...{
                'qa-id': 'budgetAndOrganizationSelector',
                'qa-value':
                    selectedBudget && selectedOrganization ? `${selectedOrganization.name} ${selectedBudget.year}` : '',
            }}
        >
            <Dropdown
                disableScroll
                closeOnContentClick
                title={(isOpened) => (
                    <div className={styles.title}>
                        <Icon type={IconType.BLOCK_ICON} svgSize={16} />

                        <Title selectedBudget={selectedBudget} selectedOrganization={selectedOrganization} />

                        <Icon
                            type={IconType.EXPAND_ICON}
                            svgSize={16}
                            className={classNames(styles.titleExpandIcon, isOpened && styles.titleExpandIconRotated)}
                        />
                    </div>
                )}
                content={
                    <div className={styles.content}>
                        {budgets.map((budget) => (
                            <DropdownItem
                                key={budget.id}
                                {...budget}
                                budgetStatus={budgetStatus}
                                selectedBudgetId={selectedBudgetId}
                            />
                        ))}
                    </div>
                }
            />
        </div>
    );
}

interface TitleProps {
    selectedBudget: Budget;
    selectedOrganization: OrganizationView;
}

function Title({ selectedBudget, selectedOrganization }: TitleProps): JSX.Element {
    if (!selectedBudget && !selectedOrganization) {
        return (
            <div className={styles.titleText}>
                Бюджет: Не выбран
                <Icon type={IconType.WARNING_IN_CIRCLE} svgSize={13} className={styles.noSelectedBudgetWarningIcon} />
            </div>
        );
    }

    return (
        <div className={styles.titleText}>
            Бюджет:&nbsp;
            <span className={styles.titleBold}>
                {selectedOrganization ? selectedOrganization.name : 'Организация не найдена'}
                &nbsp;
                {selectedBudget ? selectedBudget.year : 'Бюджет не найден'}
            </span>
        </div>
    );
}
