import { TaskCardParams as TaskCardParamsExternal, WorkTypeParams } from 'sber-marketing-types/frontend';

import { LoadingStatus } from '../commonTypes';

export interface TaskCardParams extends TaskCardParamsExternal {
    isLoading: boolean;
}

export interface TaskCardParamsResponse extends TaskCardParamsExternal {}

export const enum TasksListType {
    MY_TASKS = 'my_tasks',
    ACTIVITY_TASKS = 'activity_tasks',
    OTHER = 'other',
}

export const enum TaskCardType {
    NORMAL = 'normal',
    SHORT = 'short',
}

export interface TasksList {
    status: LoadingStatus;
    type: TasksListType;
    cardType: TaskCardType;
    activityId: number | null;
    canBeLoadedMore: boolean;
    tasks: TaskCardParams[];
    error: Error | null;
    lastRequestDate: string;
    workTypes: WorkTypesState;
}

export interface WorkTypesState {
    loadingStatus: LoadingStatus;
    dictionary: {
        [id: string]: WorkTypeParams;
    };
}

export interface TasksListUserConfig {
    cardType: TaskCardType;
}

export interface UpdateSavedTaskCardParams {
    taskId: string;
    params: Partial<TaskCardParams>;
}
