import { BudgetItemStatus } from '@mrm/budget';
import { Dictionary, flatMap } from 'lodash';
import { BudgetItem } from '@mrm/budget';

import {
    TableLine,
    ColumnData,
    ColumnName,
    CellValueType,
    ChangeList,
    REQUIRED_COLUMN_NAMES_SHORT_LIST,
    UnsavedChange,
} from '@store/budgetPlanning/types';
import { CellParams, CellBackgroundColor } from '../LayerManager';
import { getFieldValue } from './Utils';
import { MoneyFormatter, Money } from '@common/Utils';

interface Props {
    line: TableLine;
    column: ColumnData;
    unsavedChanges: ChangeList;
    validationStatus: boolean;
    budgetItemsByActivityId: Dictionary<BudgetItem[]>;
}

export class InputCellParamsCreator {
    private line: TableLine;
    private column: ColumnData;
    private unsavedChanges: ChangeList;
    private validationStatus: boolean;
    private budgetItemsByActivityId: Dictionary<BudgetItem[]>;

    constructor(props: Props) {
        this.line = props.line;
        this.column = props.column;
        this.unsavedChanges = props.unsavedChanges;
        this.validationStatus = props.validationStatus;
        this.budgetItemsByActivityId = props.budgetItemsByActivityId;
    }

    public makeCellParams(): CellParams {
        let value: string = null;
        let color: CellBackgroundColor = null;

        const unsavedChange = this.getUnsavedChangeForCurrentLine();
        const baseValue = this.getBaseValue(this.column);

        if (unsavedChange !== undefined) {
            value = unsavedChange.value as string;
            color = CellBackgroundColor.UnsavedChange;
        } else {
            value = baseValue;

            if (this.column.valueType === CellValueType.Currency && value) {
                value = String(parseFloat(value) / 100.0);
            }
        }

        const { title, tooltip } = this.makeTitleAndTooltip();

        const cellInfo = this.makeCellInfo();

        const lineIsDraft = this.line.status == BudgetItemStatus.Draft;
        const lineIsDisabled = !this.line.canEdit || this.line.status == BudgetItemStatus.Disabled;

        return {
            title,
            tooltip: tooltip || '-',
            value,
            originalValue: baseValue,
            bgColor: !lineIsDraft && color,
            displayValidationError: this.cellIsInvalid(value),
            disabled: lineIsDisabled,
            cellInfo,
        };
    }

    private makeCellInfo(): string {
        if (this.column.name === ColumnName.ActivityName) {
            const activityBudgetItems = this.budgetItemsByActivityId[this.line.activityId] || [];
            const unsavedChangesByActvitiyBudgetItems = this.getUnsavedChanges(
                activityBudgetItems.map((budgetItem) => budgetItem.id),
            );

            if (unsavedChangesByActvitiyBudgetItems.length && activityBudgetItems.length > 1) {
                return `Изменение повлияет на другие ячейки (${activityBudgetItems.length}) в рамках данной активности`;
            }
        }

        return null;
    }

    private makeTitleAndTooltip(): { title: string; tooltip: string } {
        const valueTypeIsCurrency = this.column.valueType == CellValueType.Currency;

        const unsavedChange = this.getUnsavedChangeForCurrentLine();
        const baseValue = this.getBaseValue(this.column);

        const hasUnsavedValue = unsavedChange !== undefined;

        let title: string;
        let tooltip = '';

        if (valueTypeIsCurrency) {
            const money =
                unsavedChange !== undefined
                    ? Money.fromStringRoubles(unsavedChange.value as string)
                    : Money.fromStringCopecks(baseValue);

            title = MoneyFormatter.toThousands(money, { hideCaption: true });

            tooltip = MoneyFormatter.toRoubles(money);
        } else {
            if (hasUnsavedValue) {
                title = unsavedChange.value as string;
                tooltip = title;
            } else {
                title = baseValue;
                tooltip = title;
            }

            title = unsavedChange !== undefined ? (unsavedChange.value as string) : baseValue;
        }

        return {
            title,
            tooltip,
        };
    }

    private getUnsavedChangeForCurrentLine(): UnsavedChange {
        return this.getUnsavedChanges([this.line.id])[0];
    }

    private getUnsavedChanges(lineIds: string[]): UnsavedChange[] {
        const changes = flatMap(lineIds.map((lineId) => this.unsavedChanges[lineId] || []));

        return changes.filter((item) => item.columnName === this.column.name);
    }

    private cellIsInvalid(value: string) {
        if (!this.validationStatus) {
            return false;
        }

        let displayError = false;

        const isActivityNameField = this.column.name == ColumnName.ActivityName;
        const isSapCommentField = this.column.name == ColumnName.SapComment;
        const isRequiredField = REQUIRED_COLUMN_NAMES_SHORT_LIST.includes(this.column.name);

        if (isActivityNameField || isSapCommentField) {
            displayError = !value || value.length < 3;
        } else if (isRequiredField) {
            displayError = !value;
        }

        return displayError;
    }

    private getBaseValue(column: ColumnData): string {
        return getFieldValue(this.line.fields[column.name], column.valueType) as string;
    }
}
