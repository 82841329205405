import * as React from 'react';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { StoreState } from '@store';
import {
    ColumnName,
    ColumnFilters,
    SortingMode,
    CellValueType,
    getTableFilters,
    getBudgetPlanningPageState,
    toggleColumnFix,
} from '@store/budgetPlanning';

import { HeaderDropdownCell } from './HeaderDropdownCell';
import { DropdownContent } from './DropdownContent';

interface Props extends Partial<MapProps & DispatchProps> {
    columnName: ColumnName;
    title: string;
    filters: ColumnFilters;
    columnValueType: CellValueType;
    isResizingColumn: boolean;
    currencySearch: boolean;
    onOpenStatusChange: (dropdownContent: JSX.Element) => void;
    onSelectionChange: (filters: ColumnFilters) => void;
    onColumnFixChange?: () => void;
    onApplyFiltersButtonClick: () => void;
}

interface MapProps {
    sortingMode: SortingMode;
    isFiltered: boolean;
    isColumnFixed: boolean;
}

interface DispatchProps {
    toggleColumnFix(columnName: ColumnName): void;
}

interface State {
    isOpened: boolean;
    isHovered: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class HeaderDropdownCellContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isOpened: false,
            isHovered: false,
        };
    }

    public render(): JSX.Element {
        const { title, sortingMode, columnName, isFiltered, isColumnFixed, isResizingColumn } = this.props;
        const { isOpened, isHovered } = this.state;

        return React.createElement(HeaderDropdownCell, {
            name: columnName,
            title,
            isSorted: sortingMode.columnName === this.props.columnName,
            isFiltered,
            isResizingColumn,
            isOpened,
            isHovered,
            isColumnFixed,
            onOpenerClick: this.onOpenerClick,
            onMaskClick: this.onMaskClick,
            onMouseEnter: this.onMouseEnter,
            onMouseLeave: this.onMouseLeave,
            onColumnFixChange: this.onColumnFixChange,
        });
    }

    @autobind
    protected onOpenerClick() {
        this.setState(
            {
                isOpened: !this.state.isOpened,
            },
            () => {
                if (this.props.onOpenStatusChange) {
                    this.props.onOpenStatusChange(this.state.isOpened ? this.makeDropdownContent() : null);
                }
            },
        );
    }

    @autobind
    protected onMaskClick() {
        this.setState(
            {
                isOpened: false,
                isHovered: false,
            },
            () => {
                if (this.props.onOpenStatusChange) {
                    this.props.onOpenStatusChange(null);
                }
            },
        );
    }

    @autobind
    protected onColumnFixChange() {
        this.setState(
            {
                isOpened: false,
            },
            () => {
                this.props.toggleColumnFix(this.props.columnName);

                if (this.props.onOpenStatusChange) {
                    this.props.onOpenStatusChange(null);
                }
            },
        );
    }

    private makeDropdownContent(): JSX.Element {
        return React.createElement(DropdownContent, {
            columnName: this.props.columnName,
            filters: this.props.filters,
            columnValueType: this.props.columnValueType,
            currencySearch: this.props.currencySearch,
            onFiltersChange: this.props.onSelectionChange,
            onColumnFixChange: this.onColumnFixChange,
            onApplyFiltersButtonClick: this.onApplyFiltersButtonClick,
        });
    }

    @autobind
    private onMouseEnter() {
        this.setState({
            isHovered: true,
        });
    }

    @autobind
    private onMouseLeave() {
        this.setState({
            isHovered: false,
        });
    }

    @autobind
    private onApplyFiltersButtonClick(): void {
        this.onMaskClick();
        this.props.onApplyFiltersButtonClick();
    }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
    const { sortingMode } = getTableFilters(state);
    const {
        columnFilters: { filters },
        fixedColumnsNames,
    } = getBudgetPlanningPageState(state);
    const filtersByColumn = filters[ownProps.columnName];
    const isFiltered = lodash.some(filtersByColumn, (filter) => filter == true);

    return {
        sortingMode,
        isFiltered,
        isColumnFixed: fixedColumnsNames.includes(ownProps.columnName),
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        toggleColumnFix: (columnName: ColumnName) => dispatch(toggleColumnFix(columnName)),
    };
}
