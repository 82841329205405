import gql from 'graphql-tag';

export const WITH_PROJECT_QUERY = gql`
    query getProject($id: Int!) {
        project: getProjects(filter: { id: $id }) {
            nodes {
                id
            }
        }
    }
`;

export const WITH_REQUEST_QUERY = gql`
    query getRequest($id: String!) {
        request: getApprovalRequests(filter: { id: [$id] }) {
            nodes {
                project {
                    permissions {
                        read
                        write
                    }
                }
                budgetItem {
                    id
                    budget {
                        id
                    }
                    permissions {
                        read
                        write
                    }
                }
            }
        }
    }
`;
