import * as React from 'react';
import { TaskStatus } from 'sber-marketing-types/frontend';

import {
    CardIndicator as Indicator,
    CardIndicatorColor as Color,
    cardIndicatorThickness as Thickness,
} from '@common/Card';

import { WithEditor } from '../WithEditor';
import { DropdownEditor } from '../DropdownEditor';

import * as styles from './Status.scss';

interface Props extends TemplateProps {
    canEdit: boolean;
    changeStatus: (status: TaskStatus) => Promise<void>;
}

export interface TemplateProps {
    type: StatusType;
    text?: string;
    editDropdownItems?: {
        id: TaskStatus;
        label: string;
    }[];
}

export const enum StatusType {
    EXPIRED_DEADLINE,
    CLOSED_TASK,
    FEW_DAYS_TO_DEADLINE,
    CRITICAL_FEW_DAYS_TO_DEADLINE,
    IN_WORK,
    DRAFT,
}

type Templates = {
    [P in StatusType]: (props: TemplateProps) => JSX.Element;
};

const templates: Templates = {
    [StatusType.CLOSED_TASK]: () => (
        <Indicator color={Color.BLUE} thickness={Thickness.NARROW}>
            Закрыта
        </Indicator>
    ),
    [StatusType.EXPIRED_DEADLINE]: () => (
        <Indicator color={Color.RED} thickness={Thickness.NARROW}>
            Просрочена
        </Indicator>
    ),
    [StatusType.FEW_DAYS_TO_DEADLINE]: ({ text }) => (
        <Indicator color={Color.YELLOW} thickness={Thickness.NARROW}>
            {text}
        </Indicator>
    ),
    [StatusType.CRITICAL_FEW_DAYS_TO_DEADLINE]: ({ text }) => (
        <Indicator color={Color.RED} thickness={Thickness.NARROW}>
            {text}
        </Indicator>
    ),
    [StatusType.IN_WORK]: () => (
        <Indicator color={Color.GREEN} thickness={Thickness.NARROW}>
            В работе
        </Indicator>
    ),
    [StatusType.DRAFT]: () => (
        <Indicator color={Color.GRAY} thickness={Thickness.NARROW}>
            Черновик
        </Indicator>
    ),
};

export function Status({ canEdit, editDropdownItems, changeStatus, ...props }: Props): JSX.Element {
    return (
        <WithEditor
            loadingClassName={styles.skeleton}
            canEdit={canEdit}
            valueContent={() => templates[props.type](props)}
            editorContent={(onValueEdit) => (
                <DropdownEditor title="Изменить статус" items={editDropdownItems} onSelect={onValueEdit} />
            )}
            editValue={changeStatus}
        />
    );
}
