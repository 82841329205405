// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-_Brief__blocks{user-select:none}.src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-_Brief__block{padding:16px 24px}.src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-_Brief__blockTitle{color:#262626;font-family:'Open Sans';font-size:14px;font-weight:600;line-height:20px}.src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-_Brief__blockFields{display:block}.src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-_Brief__field{display:block}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/EditPlannedBudgetPage/BudgetList/BudgetCard/Brief/Brief.scss"],"names":[],"mappings":"AAAA,4FACI,gBAAiB,CACpB,2FAGG,iBAAkB,CACrB,gGAGG,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACpB,iGAGG,aAAc,CACjB,2FAGG,aAAc","sourcesContent":[".blocks {\n    user-select: none;\n}\n\n.block {\n    padding: 16px 24px;\n}\n\n.blockTitle {\n    color: #262626;\n\n    font-family: 'Open Sans';\n    font-size: 14px;\n    font-weight: 600;\n    line-height: 20px;\n}\n\n.blockFields {\n    display: block;\n}\n\n.field {\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blocks": "src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-_Brief__blocks",
	"block": "src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-_Brief__block",
	"blockTitle": "src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-_Brief__blockTitle",
	"blockFields": "src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-_Brief__blockFields",
	"field": "src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-_Brief__field"
};
export default ___CSS_LOADER_EXPORT___;
