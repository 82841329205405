import { factory } from './factory';
import { TaskCommentTransferObject } from 'sber-marketing-types/frontend';
import {
    EditTaskPayload,
    RemoveNewTaskAssetPayload,
    EditCommentaryPayload,
    RemoveCommentaryAssetPayload,
    SetCommentaryIsLoadingPayload,
    AddNewTaskParticipatorPayload,
    Commentary,
} from '../types';
import { FileAsset } from '../../commonTypes';

/** Reset task store */
export const resetTaskPage = factory('RESET_TASK_PAGE');

/** Edit task */
export const editTask = factory<EditTaskPayload>('EDIT_TASK');

/** Set author identifier */
export const setAuthorId = factory<number>('SET_AUTHOR_ID');

/** Add new task asset */
export const addNewTaskAsset = factory<FileAsset>('ADD_NEW_TASK_ASSET');

/** Remove new task asset */
export const removeNewTaskAsset = factory<RemoveNewTaskAssetPayload>('REMOVE_NEW_TASK_ASSET');

/** Add new task participator */
export const addNewTaskParticipator = factory<AddNewTaskParticipatorPayload>('ADD_NEW_TASK_PARTICIPATOR');

/** Reset task editor */
export const resetEditor = factory('RESET_EDITOR');

/** Remove new task participator */
export const removeNewTaskParticipator = factory<AddNewTaskParticipatorPayload>('REMOVE_NEW_TASK_PARTICIPATOR');

/** Remove new task participator */
export const setIsUserSubscribed = factory<boolean>('SET_IS_USER_SUBSCRIBED');

/** Add new commentary asset */
export const addNewCommentaryAsset = factory<FileAsset>('ADD_NEW_COMMENTARY_ASSET');

/** Remove new commentary asset */
export const removeNewCommentaryAsset = factory<string>('REMOVE_NEW_COMMENTARY_ASSET');

/** Add existing commentary asset */
export const addCommentaryAsset = factory<FileAsset>('ADD_COMMENTARY_ASSET');

/** Remove existing commentary asset */
export const removeCommentaryAsset = factory<RemoveCommentaryAssetPayload>('REMOVE_COMMENTARY_ASSET');

/** Edit commentary */
export const editCommentary = factory<EditCommentaryPayload>('EDIT_COMMENTARY');

/** Set current editing commentary */
export const setEditingComment = factory<Commentary | symbol | null>('SET_EDITING_COMMENTARY');

export const clearComments = factory('CLEAR_COMMENTS');

/** Set commentary loading state */
export const setCommentaryIsLoading = factory<SetCommentaryIsLoadingPayload>('SET_COMMENTARY_IS_LOADING');

/** Set loading of comment assets */
export const setSendingComment = factory<boolean>('SET_SENDING_COMMENT');
export const setSavingComment = factory<string | null>('SET_SAVING_COMMENT');

export const replaceNewTaskAssets = factory<FileAsset[]>('REPLACE_NEW_TASK_ASSETS');

export const resetNewCommentary = factory('RESET_NEW_COMMENTARY');

export const resetTelegramFetchError = factory('RESET_TELEGRAM_FETCH_ERROR');

export const editActivityKeyStatus = factory<boolean>('EDIT_ACTIVITY_KEY_STATUS');

export const setRequestInProgress = factory<boolean>('SET_REQUEST_IN_PROGRESS');

export const setSubscribeIsDisabled = factory<boolean>('SET_SUBSCRIBE_IS_DISABLED');

export const updateExistingComment = factory<TaskCommentTransferObject>('UPDATE_EXISTING_COMMENT');

export const setCommentIdToReplyTo = factory<string>('SET_COMMENT_ID_TO_REPLY_TO');
