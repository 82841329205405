import * as React from 'react';
import { List } from 'react-virtualized';
import { CustomScrollbar_new as CustomScrollbar, ScrollValues } from 'sber-marketing-ui';

interface Item {
    id: React.ReactText;
}

interface Props<I extends Item> {
    items: I[];
    width: number;
    rowHeight: number;
    virtualizationThreshold?: number;
    maxHeight?: number;
    maxItems?: number;
    itemRenderer: (item: I) => JSX.Element;
}

function getMaxHeight<I extends Item>(props: Props<I>): number {
    return props.maxHeight || props.rowHeight * props.maxItems;
}

export function VirtualizedList<I extends Item>(p: Props<I>): JSX.Element {
    const props = {
        ...p,
        virtualizationThreshold: p.virtualizationThreshold || 50,
        maxItems: p.maxItems || 10,
    };

    return props.items.length > props.virtualizationThreshold ? <Virtualized {...props} /> : <Regular {...props} />;
}

function Virtualized<I extends Item>(props: Props<I>): JSX.Element {
    const listRef = React.useRef<List>();
    const scrollbarRef = React.useRef<any>();

    const { items, width, rowHeight, itemRenderer } = props;
    const maxHeight = getMaxHeight(props);

    function onScroll(scrollValues: ScrollValues, prevValues: ScrollValues) {
        const valuesHaveChanged = scrollValues.scrollTop !== prevValues.scrollTop;

        if (valuesHaveChanged && listRef.current && scrollbarRef.current) {
            const { scrollTop, scrollLeft } = scrollValues;

            listRef.current.Grid.handleScrollEvent({
                scrollTop,
                scrollLeft,
            });

            scrollbarRef.current.scrollbar.scrollTo(scrollTop, scrollLeft);
        }
    }

    return (
        <CustomScrollbar ref={scrollbarRef} maxHeight={maxHeight} freezeScrollX hideScrollX onScroll={onScroll}>
            <List
                ref={listRef}
                style={{
                    overflowX: 'visible',
                    overflowY: 'visible',
                    outline: 'none',
                }}
                rowCount={items.length}
                rowRenderer={(item) => (
                    <div key={items[item.index].id} style={item.style}>
                        {itemRenderer(items[item.index])}
                    </div>
                )}
                width={width}
                height={maxHeight}
                rowHeight={rowHeight}
            />
        </CustomScrollbar>
    );
}

function Regular<I extends Item>(props: Props<I>): JSX.Element {
    const { items, itemRenderer } = props;
    const maxHeight = getMaxHeight(props);

    return (
        <CustomScrollbar maxHeight={maxHeight} freezeScrollX hideScrollX>
            {items.map((item) => (
                <React.Fragment key={item.id}>{itemRenderer(item)}</React.Fragment>
            ))}
        </CustomScrollbar>
    );
}
