// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-files-components-DownloadFiles-_DownloadFiles__root{font-family:'Open Sans', sans-serif;background:none;padding:0;border:none;font-size:12px;color:#7e8681;cursor:pointer;transition:color .3s}.src-client-modules-files-components-DownloadFiles-_DownloadFiles__root:focus-visible,.src-client-modules-files-components-DownloadFiles-_DownloadFiles__root:hover{outline:none;color:#18BA4E}\n", "",{"version":3,"sources":["webpack://./src/client/modules/files/components/DownloadFiles/DownloadFiles.scss"],"names":[],"mappings":"AAAA,wEACE,mCAAoC,CACpC,eAAgB,CAChB,SAAU,CACV,WAAY,CACZ,cAAe,CACf,aAAc,CACd,cAAe,CACf,oBAAqB,CARvB,oKAYI,YAAa,CACb,aAAc","sourcesContent":[".root {\n  font-family: 'Open Sans', sans-serif;\n  background: none;\n  padding: 0;\n  border: none;\n  font-size: 12px;\n  color: #7e8681;\n  cursor: pointer;\n  transition: color .3s;\n\n  &:focus-visible,\n  &:hover {\n    outline: none;\n    color: #18BA4E;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-files-components-DownloadFiles-_DownloadFiles__root"
};
export default ___CSS_LOADER_EXPORT___;
