// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-TaskFiltersMenu-_TaskFiltersMenu__filterWrapper{margin-bottom:4px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/TaskFiltersMenu/TaskFiltersMenu.scss"],"names":[],"mappings":"AAIA,2EACI,iBAAkB","sourcesContent":[".root {\n\n}\n\n.filterWrapper {\n    margin-bottom: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterWrapper": "src-client-modules-common-TaskFiltersMenu-_TaskFiltersMenu__filterWrapper"
};
export default ___CSS_LOADER_EXPORT___;
