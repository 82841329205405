import * as React from 'react';
import classNames from 'classnames';

import { Color } from '../../enums';
import { getColorClassNames } from './utils';
import * as styles from './styles.scss';

interface Props {
    progress: number;
    color: Color;
}

export const ProgressBar: React.FC<Props> = ({ progress, color }) => {
    return (
        <div
            className={classNames(styles.root, getColorClassNames(color))}
            style={{ '--progress': `${progress}%` } as React.CSSProperties}
        />
    );
};

export { Props as ProgressBarProps };
