import * as React from 'react';

import * as styles from './ReactionForm.scss';

interface Props {
    children: React.ReactNode;
    onClick: () => void;
}

export function ReactionFormItem({ children, onClick }: Props): JSX.Element {
    return (
        <div className={styles.reactionFormItem} onClick={onClick}>
            {children}
        </div>
    );
}
