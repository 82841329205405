// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-_styles__root{display:grid;grid-template-columns:max-content auto max-content;grid-column-gap:5px;align-items:center}.src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-_styles__label{font-family:\"Open Sans\";font-size:14px;letter-spacing:0.1px;color:#7E8681}.src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-_styles__tagsList{display:grid;grid-auto-columns:max-content;grid-auto-flow:column;grid-column-gap:6px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetPlanning/FiltersColumnsTagsBar/styles.scss"],"names":[],"mappings":"AAAA,yFACI,YAAa,CACb,kDAAmD,CACnD,mBAAoB,CACpB,kBAAmB,CACtB,0FAGG,uBAAwB,CACxB,cAAe,CACf,oBAAqB,CACrB,aAAc,CACjB,6FAGG,YAAa,CACb,6BAA8B,CAC9B,qBAAsB,CACtB,mBAAoB","sourcesContent":[".root {\n    display: grid;\n    grid-template-columns: max-content auto max-content;\n    grid-column-gap: 5px;\n    align-items: center;\n}\n\n.label {\n    font-family: \"Open Sans\";\n    font-size: 14px;\n    letter-spacing: 0.1px;\n    color: #7E8681;\n}\n\n.tagsList {\n    display: grid;\n    grid-auto-columns: max-content;\n    grid-auto-flow: column;\n    grid-column-gap: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-_styles__root",
	"label": "src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-_styles__label",
	"tagsList": "src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-_styles__tagsList"
};
export default ___CSS_LOADER_EXPORT___;
