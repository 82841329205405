import * as React from 'react';

import { WithClientData } from './WithClientData';
import { ContractsSelectTemplate } from './ContractsSelectTemplate';

interface Props {
    lot: 'lot1' | 'lot2';
    onContractChange: (lot: number, contractId: string) => void;
}

export const ContractsSelectConnector: React.FC<Props> = ({ lot, onContractChange }) => {
    return (
        <WithClientData lot={lot} onContractChange={onContractChange}>
            {({ loading, selectedContractId, contracts, setSelectedContractId }) => (
                <ContractsSelectTemplate
                    loading={loading}
                    selectedContractId={selectedContractId}
                    contracts={contracts}
                    setSelectedContractId={setSelectedContractId}
                />
            )}
        </WithClientData>
    );
};
