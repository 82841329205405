import * as React from 'react';

import { BondActivitiesWithBudgetItemId } from '../../BondActivitiesWithBudgetItemId';
import { ActivityWidget } from '../../ActivityWidget';
import { BudgetInfo } from '../../BudgetInfo';
import { Comments } from '../../Comments';
import { FileGroups } from '../../FileGroups';

import { ChildrenProps } from './BudgetItemToProjectCardConnected';

interface Props extends ChildrenProps {
    cardId: string;
    activityId: number;
    updateCards: () => Promise<void>;
}

export function BudgetItemToProjectCardTemplate({
    cardId,
    activityId,
    enableControls,
    hasBudgetItemAccess,
    updateCards,
}: Props): JSX.Element {
    return (
        <React.Fragment>
            <BondActivitiesWithBudgetItemId enableControls={enableControls} updateCards={updateCards} />
            <BudgetInfo hasBudgetItemAccess={hasBudgetItemAccess} />
            <ActivityWidget requestId={cardId} />
            <FileGroups enableControls={enableControls} activityId={activityId} />
            <Comments enableControls={enableControls} cardId={cardId} />
        </React.Fragment>
    );
}
