import * as lodash from 'lodash';

import { Correction, CorrectionType } from '@mrm/budget';
import { TableLine, ColumnData, ColumnName } from '@store/budgetExecution';
import {
    BudgetTransferMenuState,
    CellPosition,
    isInternalTransferState,
    ComponentState,
    InternalTransferDirection,
} from '@store/budgetExecution/budgetTransferMenu';
import { CellParams, CellBackgroundColor, CellBorderColor } from '../LayerManager';
import { CellColors } from '../../CellTypes';

import { getFieldValue, getMonthByIndex } from './Utils';

import { MoneyFormatter, Money } from '@common/Utils';

interface Props {
    line: TableLine;
    column: ColumnData;
    transitionData: BudgetTransferMenuState;
    planCorrections: Correction<CorrectionType.PlanFundsTransfer>[];
    incomeExternalPlanCorrections: Correction<CorrectionType.IncomeExternalPlanFundsTransfer>[];
    outcomeExternalPlanCorrections: Correction<CorrectionType.OutcomeExternalPlanFundsTransfer>[];
    selectableCells: ColumnName[];
    isDisabled: boolean;
}

export class SelectableCellParamsCreator {
    private CELL_COLORS: { [colorName: string]: CellColors } = {
        donor: { background: CellBackgroundColor.Donor, border: CellBorderColor.Donor },
        acceptor: { background: CellBackgroundColor.Acceptor, border: CellBorderColor.Acceptor },
    };

    private line: TableLine;
    private column: ColumnData;
    private transitionData: BudgetTransferMenuState;
    private planCorrections: Correction<CorrectionType.PlanFundsTransfer>[];
    private incomeExternalPlanCorrections: Correction<CorrectionType.IncomeExternalPlanFundsTransfer>[];
    private outcomeExternalPlanCorrections: Correction<CorrectionType.OutcomeExternalPlanFundsTransfer>[];
    private selectableCells: ColumnName[];
    private isDisabled: boolean;

    constructor(props: Props) {
        const {
            line,
            column,
            transitionData,
            planCorrections,
            incomeExternalPlanCorrections,
            outcomeExternalPlanCorrections,
            selectableCells,
            isDisabled,
        } = props;

        this.line = line;
        this.column = column;
        this.transitionData = transitionData;
        this.planCorrections = planCorrections;
        this.incomeExternalPlanCorrections = incomeExternalPlanCorrections;
        this.outcomeExternalPlanCorrections = outcomeExternalPlanCorrections;
        this.selectableCells = selectableCells;
        this.isDisabled = isDisabled;
    }

    public makeCellParams(): CellParams {
        const { title, tooltip } = this.makeTitleAndTooltip();

        return {
            title,
            tooltip,
            ...this.makeSelectionParams(),
        };
    }

    // tslint:disable-next-line:cyclomatic-complexity
    private makeSelectionParams(): Partial<CellParams> {
        const {
            controls: { internalTransferDirection, componentState },
            cells: { from, to, hoveredCell },
        } = this.transitionData;

        const donorIsSelected = !!from.length;
        const acceptorIsSelected = !!to.length;

        const isSelectedAsDonor = from.some((fromCell) => this.isCurrentCell(fromCell));
        const isSelectedAsAcceptor = to.some((toCell) => this.isCurrentCell(toCell));
        const isHovered = this.isCurrentCell(hoveredCell);

        const isDonor = this.isDonor();
        const isAcceptor = this.isAcceptor();

        const isSelectable = lodash.includes(this.selectableCells, this.column.name);

        const isSelected = isSelectedAsDonor || isSelectedAsAcceptor;

        const isInternalTransfer = isInternalTransferState(componentState);

        let isClickable = isSelectable || isSelected;
        if (isInternalTransfer) {
            switch (internalTransferDirection) {
                case InternalTransferDirection.OneToMany:
                    const cellValue = parseFloat(this.getBaseValue(this.column));

                    isClickable = isClickable && !!cellValue;
                    break;
                case InternalTransferDirection.ManyToOne:
                    isClickable = isClickable || !acceptorIsSelected;
                    break;
                default:
                    break;
            }
        }

        const colorSelectedAsDonor =
            (isDonor || isSelectedAsDonor) &&
            ((isInternalTransfer && internalTransferDirection === InternalTransferDirection.OneToMany) ||
                componentState === ComponentState.ExternalOutcomeTransfer);
        const colorSelectedAsAcceptor =
            (isAcceptor || isSelectedAsAcceptor) &&
            ((isInternalTransfer && internalTransferDirection === InternalTransferDirection.ManyToOne) ||
                componentState === ComponentState.ExternalIncomeTransfer);

        let color: CellColors;
        if (colorSelectedAsDonor) {
            color = this.CELL_COLORS.donor;
        } else if (colorSelectedAsAcceptor) {
            color = this.CELL_COLORS.acceptor;
        }

        if (isHovered && !isSelectedAsDonor && !isSelectedAsAcceptor) {
            const colorAsDonor =
                (componentState === ComponentState.ExternalOutcomeTransfer && !donorIsSelected) ||
                (componentState === ComponentState.InternalTransferCellSelection &&
                    internalTransferDirection === InternalTransferDirection.OneToMany &&
                    !donorIsSelected);

            const colorAsAcceptor =
                (componentState === ComponentState.ExternalIncomeTransfer && !acceptorIsSelected) ||
                (componentState === ComponentState.InternalTransferCellSelection &&
                    internalTransferDirection === InternalTransferDirection.ManyToOne &&
                    !acceptorIsSelected);

            if (colorAsDonor) {
                color = this.CELL_COLORS.donor;
            } else if (colorAsAcceptor) {
                color = this.CELL_COLORS.acceptor;
            }
        }

        return {
            isClickable,
            isSelected,
            isHovered,
            cellColor: color,
            disabled: this.isDisabled,
        };
    }

    private makeTitleAndTooltip(): { title: string; tooltip: string } {
        const baseValue = this.getBaseValue(this.column);
        const correctionValue = this.getCorrectionValue(this.column);

        const isDonor = this.isDonor();
        const isAcceptor = this.isAcceptor();

        const baseMoney = Money.fromStringCopecks(baseValue);
        let title;
        let tooltip;
        if (isDonor || isAcceptor) {
            const correctionMoney = Money.fromCopecks(correctionValue);

            title = MoneyFormatter.pairToThousands(baseMoney, correctionMoney, { hideCaption: true });
            tooltip = MoneyFormatter.pairToRoubles(baseMoney, correctionMoney);
        } else {
            title = MoneyFormatter.toThousands(baseMoney, { hideCaption: true });
            tooltip = MoneyFormatter.toRoubles(baseMoney);
        }

        return {
            title,
            tooltip,
        };
    }

    private getCorrectionValue(column: ColumnData): number {
        const isDonor = this.isDonor();
        const isAcceptor = this.isAcceptor();

        if (!isDonor && !isAcceptor) {
            return undefined;
        }

        const baseValue = parseFloat(this.getBaseValue(this.column));

        const monthIndex = column.metaData.month - 1;
        const month = getMonthByIndex(monthIndex);

        const incomeValues = [...this.planCorrections, ...this.incomeExternalPlanCorrections].map((item) => {
            const isAcceptorCorrection =
                item.data.params.acceptorId == this.line.id && item.data.params.acceptorMonth === month;

            return isAcceptorCorrection ? item.data.params.value : 0;
        });

        const outcomeValues = [...this.planCorrections, ...this.outcomeExternalPlanCorrections].map((item) => {
            const isAcceptorCorrection =
                item.data.params.donorId == this.line.id && item.data.params.donorMonth === month;

            return isAcceptorCorrection ? item.data.params.value : 0;
        });

        const delta = lodash.sum(incomeValues) - lodash.sum(outcomeValues);

        return baseValue + delta;
    }

    private getBaseValue(column: ColumnData): string {
        const value = getFieldValue(this.line.fields[column.name], column.valueType) as string;
        return value || '0';
    }

    private isCurrentCell(cell: CellPosition): boolean {
        return cell.lineId == this.line.id && cell.columnName == this.column.name;
    }

    private isDonor(): boolean {
        const corrections = [...this.planCorrections, ...this.outcomeExternalPlanCorrections];

        const monthIndex = this.column.metaData.month - 1;
        const month = getMonthByIndex(monthIndex);

        return corrections.some((item) => {
            const monthIsEqual = item.data.params.donorMonth === month;
            const lineIdIsEqual = item.data.params.donorId == this.line.id;

            return monthIsEqual && lineIdIsEqual;
        });
    }

    private isAcceptor(): boolean {
        const corrections = [...this.planCorrections, ...this.incomeExternalPlanCorrections];

        const monthIndex = this.column.metaData.month - 1;
        const month = getMonthByIndex(monthIndex);

        return corrections.some((item) => {
            const monthIsEqual = item.data.params.acceptorMonth === month;
            const lineIdIsEqual = item.data.params.acceptorId == this.line.id;

            return monthIsEqual && lineIdIsEqual;
        });
    }
}
