import { createSelector } from 'reselect';
import { SelectItem } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { ActivityStageState } from './types';

export const getActivityStageState = (state: StoreState): ActivityStageState => state.activityTasksPage.stages;

export const getStageTemplatesAsSelectItems = createSelector(getActivityStageState, (state): SelectItem[] =>
    state.stageTemplates.entities
        .filter(({ status }) => status === 'active')
        .map((template) => ({
            label: template.value,
            value: template.id,
        })),
);

export const stagesHaveChanged = createSelector(getActivityStageState, (state) => {
    const {
        existingStages: { entities: existingStages },
        pendingStages: { entities: pendingStages },
    } = state;

    if (existingStages.length === pendingStages.length) {
        let arrsAreEqual = true;

        for (let i = 0; i !== existingStages.length; i++) {
            const existingStage = existingStages[i];
            const pendingStage = pendingStages[i];
            const itemsAreEqual =
                existingStage.id === pendingStage.id &&
                existingStage.start === pendingStage.start &&
                existingStage.end === pendingStage.end;

            if (!itemsAreEqual) {
                arrsAreEqual = false;
                break;
            }
        }

        if (arrsAreEqual) {
            return false;
        }
    }

    return true;
});
