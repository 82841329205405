import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Icon, IconType } from 'sber-marketing-ui';

import { BudgetItemLink, Budget } from '../types';

import { BudgetItemLinkCard } from '../BudgetItemLinkCard';

import * as styles from './LinksContainerWithToggle.scss';

interface Props {
    activityId: number;
    budget: Budget;
    links: BudgetItemLink[];
    userHasBudgetAccess: boolean;
    refetchLinks: () => void;
}

export function LinksContainerWithToggle({
    activityId,
    budget,
    links,
    userHasBudgetAccess,
    refetchLinks,
}: Props): JSX.Element {
    const [isOpened, setIsOpened] = React.useState(true);

    return (
        <div
            {...{
                'qa-id': 'activitySidebarBudgetTabBudgetContainer',
                'qa-budget-id': budget?.id,
                'qa-budget-year': budget?.year,
            }}
        >
            <div className={styles.title}>
                <div
                    onClick={() => setIsOpened(!isOpened)}
                    className={styles.titleClickable}
                    {...{
                        'qa-id': 'activitySidebarBudgetTabBudgetContainerOpenToggle',
                        'qa-is-opened': isOpened ? 'true' : 'false',
                    }}
                >
                    <Icon
                        svgSize={8}
                        type={IconType.TRIANGLE8_DOWN}
                        className={classNames(styles.toggleButton, isOpened && styles.toggleButtonActive)}
                    />
                    ID бюджета ({budget?.year})
                </div>

                <div className={styles.counter}>{links?.length || 0}</div>

                {userHasBudgetAccess && (
                    <Link
                        to={`/budget/execution?activityId=${activityId}&budgetId=${budget?.id}`}
                        target="_blank"
                        className={styles.budgetLink}
                    >
                        <span
                            {...{
                                'qa-id': 'activitySidebarBudgetTabBudgetContainerEditLinksButton',
                            }}
                        >
                            Привязать
                        </span>
                    </Link>
                )}
            </div>

            {isOpened ? (
                <div className={styles.serialNumberCards}>
                    {links?.length
                        ? links?.map((link) => (
                              <div key={link.id} className={styles.serialNumberCard}>
                                  <BudgetItemLinkCard link={link} refetchLinks={refetchLinks} />
                              </div>
                          ))
                        : 'Нет привязанных строк'}
                </div>
            ) : null}
        </div>
    );
}
