import * as React from 'react';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { v4 } from 'uuid';
import { Dictionary } from 'lodash';

import type { ActivityParams as Activity, UserCard } from 'sber-marketing-types/frontend';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user/selector';
import { PageOptions } from '@store/common/types';
import { FileAsset } from '@store/commonTypes';
import { resetTaskEditor } from '@store/taskEditor';
import { buildPermissions } from '@store/taskEditor2';
import type { Permissions } from '@store/taskEditor2';
import {
    TagsEditorInstanceDescriptor,
    InstanceActionPayload,
    initInstance as initTagsEditorInstance,
    dropInstance as dropTagsEditorInstance,
} from '@store/tagsEditor';
import { getAllUsers } from '@store/appUsers';
import { updatePageOptions, setRequestInProgress } from '@store/common/actions';
import {
    TaskInfo,
    TaskLoadingError,
    TaskLoadingStatus,
    loadTask,
    selectors,
    editCommentary,
    CommentEditionType,
    setEditingComment,
    editTask,
    EditTaskPayload,
    replaceNewTaskAssets,
    addNewTaskParticipator,
    AddNewTaskParticipatorPayload,
    bulkAddParticipators,
    BulkAddParticipatorsStartPayload,
    SwitchKeyActivityParams,
    switchKeyActivity,
    Channel,
    resetTaskPage,
    setSubscribeIsDisabled,
} from '@store/taskPage';
import { HistoryApi } from '@api';

import { withTaskData, PropsWithTaskData } from '../../withTaskData';

import { TaskPageError } from './TaskPageError';
import { TaskPage } from './TaskPage';
import { HeaderContent } from '../../components/HeaderContent';
import { TaskStatus } from 'sber-marketing-types/frontend';

interface Props extends Partial<MapProps>, Partial<DispatchProps>, Partial<PropsWithTaskData> {
    setHeaderRightContent: (content: JSX.Element) => void;
}

interface MapProps {
    allChannels: Dictionary<Channel>;
    channel: Channel;
    users: UserCard[];
    taskEditorPermissions: Permissions;
    loadingStatus?: TaskLoadingStatus;
    info?: TaskInfo;
    error?: TaskLoadingError;
    activity?: Activity;
    loginnedUserId: number;
    visibilityEstimator: boolean;
}

interface DispatchProps {
    loadTask?: (taskId: string, channelId: number) => void;
    resetTaskPage?: () => void;
    updatePageOptions?: (options: PageOptions) => void;
    setRequestInProgress?: (inProgress: boolean) => void;
    editCommentary: (content: string) => void;
    setEditingComment: (id: string | Symbol | null) => void;
    editTask: (content: EditTaskPayload) => void;
    replaceNewTaskAssets: (assets: FileAsset[]) => void;
    addNewTaskParticipator: (payload: AddNewTaskParticipatorPayload) => void;
    resetTaskEditor: () => void;
    bulkAddParticipators: (payload: BulkAddParticipatorsStartPayload) => void;
    switchKeyActivity: (params: SwitchKeyActivityParams) => void;
    addUserMention(userToMention: number, mentioningUserName: string): void;
    setSubscribeIsDisabled(isDisabled: boolean): void;
    initTagsEditorInstance(payload: InstanceActionPayload<TagsEditorInstanceDescriptor>): void;
    dropTagsEditorInstance(payload: string): void;
}

interface State {
    isDraftHandled: boolean;
    isTaskEditorOpen: boolean;
}

@(withTaskData as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class TaskPageContainer extends React.Component<Props, State> {
    private tagsEditorId: string = v4();

    constructor(props: Props) {
        super(props);

        this.state = {
            isDraftHandled: false,
            isTaskEditorOpen: false,
        };

        this.props.updatePageOptions({
            previousUrl: this.getPreviousUrl(),
            withoutDownPadding: true,
            withoutFooter: true,
        });
        HistoryApi.setRecentView(`task_${this.props.taskId}`);
    }

    public async componentDidMount() {
        const { loadTask, setRequestInProgress, taskId, channelId } = this.props;

        this.props.initTagsEditorInstance({
            id: this.tagsEditorId,
            payload: { taskId },
        });
        setRequestInProgress(true);
        loadTask(taskId, channelId);
    }

    public componentWillUnmount() {
        this.props.resetTaskEditor();
        this.props.resetTaskPage();
        this.props.dropTagsEditorInstance(this.tagsEditorId);

        HistoryApi.setRecentView(`task_${this.props.taskId}`);
    }

    public componentDidUpdate(prevProps: Props): void {
        const { loadingStatus: prevLoadingStatus, activity: prevActivity } = prevProps;
        const { activity, loadingStatus, setRequestInProgress } = this.props;

        if (loadingStatus === TaskLoadingStatus.LOADED && loadingStatus !== prevLoadingStatus) {
            this.checkCurrentChannel();
            setRequestInProgress(false);
            this.updateHeader(false);
        } else if (loadingStatus === TaskLoadingStatus.UPDATING && prevLoadingStatus === TaskLoadingStatus.LOADED) {
            setRequestInProgress(true);
        } else if (loadingStatus === TaskLoadingStatus.ERROR && loadingStatus !== prevLoadingStatus) {
            setRequestInProgress(true);
            this.updateHeader(true);
        } else if (prevActivity && activity && prevActivity.isKey !== activity.isKey) {
            this.updateHeader(false);
        }
    }

    public render() {
        const { activity, loadingStatus, error, info, channelId, taskEditorPermissions, visibilityEstimator } =
            this.props;

        switch (loadingStatus) {
            case TaskLoadingStatus.LOADED:
            case TaskLoadingStatus.UPDATING:
                return React.createElement(TaskPage, {
                    activity,
                    isBaseChannel: channelId === null,
                    taskId: info.id,
                    selectedParticipants: info.participants,
                    visibilityEstimator,
                    taskEditorPermissions,
                    isTaskEditorOpen: this.state.isTaskEditorOpen,
                    tagsEditorId: this.tagsEditorId,
                    previousUrl: this.getPreviousUrl(),
                    onTaskCreated: this.onTaskCreated,
                    onTaskEditorPopupClose: this.onTaskEditorPopupClose,
                    onTaskEditClick: this.onTaskEditClick,
                    onParticipantsChange: this.onParticipantsChange,
                    onParticipantClick: this.onParticipantClick,
                });
            case TaskLoadingStatus.ERROR:
                return React.createElement(TaskPageError, error);
            default:
                return null;
        }
    }

    @autobind
    protected onTaskEditClick(): void {
        this.setState(() => ({ isTaskEditorOpen: true }));
    }

    @autobind
    protected onTaskCreated(): void {
        this.setState(() => ({ isTaskEditorOpen: false }));
        this.props.loadTask(this.props.taskId, null);
    }

    @autobind
    protected onParticipantsChange(participators: { userId: number; canRemove: boolean }[]): void {
        this.props.bulkAddParticipators({
            participators,
        });
    }

    @autobind
    protected onTaskEditorPopupClose(): void {
        this.setState(() => ({ isTaskEditorOpen: false }));
        this.props.resetTaskEditor();
    }

    protected get taskId(): string {
        return this.getTaskId(this.props);
    }

    protected getTaskId(props: Props): string {
        return props.taskId;
    }

    protected getPreviousUrl(): string {
        return this.props.from || `/activity/${this.props.activityId}`;
    }

    @autobind
    private onParticipantClick(userId: number): void {
        const shouldAddMention =
            this.props.channel.id === null ||
            this.props.channel.participantIds.includes(userId) ||
            this.props.channel.authorId === userId;

        if (shouldAddMention) {
            const user = this.props.users.find((user) => user.id === userId);

            this.props.addUserMention(userId, `${user.firstName} ${user.secondName}`);
        }
    }

    @autobind
    private updateHeader(subscribeIsDisabled: boolean) {
        const headerContent = React.createElement(HeaderContent, {
            subscribeIsDisabled,
        });

        this.props.setHeaderRightContent(headerContent);
    }

    private checkCurrentChannel(): void {
        const { allChannels, channelId } = this.props;

        if (!allChannels[channelId]) {
            this.props.gotoChannel(null);
        }
    }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
    const task = selectors.getTaskInfo(state);
    const user = getLoginUser(state);
    const workType = selectors.getWorkTypeById(state, task.workType);
    const activity = selectors.getActivity(state);

    const taskEditorPermissions = buildPermissions({
        user,
        task: {
            organizationId: task.organizationId,
            authorId: task.authorId,
            executorId: task.executorId,
            canEdit: task.canEdit,
            createdBy: task.createdBy,
            status: task.status,
        },
        activity: {
            authorId: activity?.authorId,
            responsibleId: activity?.responsibleId,
        },
    });

    const isUserAuthor = task.authorId === user.attributes.id;
    const isUserParticipant = task.participants.some(({ userId }) => userId === user.attributes.id);
    const isUserExecutor = task.executorId === user.attributes.id;
    const isClosedTask = task.status === TaskStatus.Closed;

    const visibilityEstimator =
        (isUserAuthor || isUserParticipant) && !isUserExecutor && isClosedTask && workType?.enableRates;

    return {
        allChannels: state.taskPage.channels,
        channel: selectors.getChannelById(state, ownProps.channelId),
        users: getAllUsers(state),
        taskEditorPermissions,
        loadingStatus: selectors.getTaskLoadingStatus(state),
        info: selectors.getTaskInfo(state),
        error: selectors.getTaskError(state),
        activity: selectors.getActivity(state),
        loginnedUserId: getLoginUser(state).attributes.id,
        visibilityEstimator,
    };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
    return {
        loadTask: (taskId: string, channelId: number) => dispatch(loadTask({ taskId, channelId })),
        resetTaskPage: () => dispatch(resetTaskPage()),
        updatePageOptions: (options: PageOptions) => dispatch(updatePageOptions(options)),
        setRequestInProgress: (inProgress: boolean) => dispatch(setRequestInProgress(inProgress)),
        editCommentary: (content: string) => dispatch(editCommentary({ content })),
        setEditingComment: (id: string | symbol | null) => dispatch(setEditingComment(id)),
        addNewTaskParticipator: (payload: AddNewTaskParticipatorPayload) => dispatch(addNewTaskParticipator(payload)),
        resetTaskEditor: () => dispatch(resetTaskEditor()),
        replaceNewTaskAssets: (assets: FileAsset[]) => dispatch(replaceNewTaskAssets(assets)),
        editTask: ({ title, description, executorId }: { title?: string; description?: string; executorId: number }) =>
            dispatch(editTask({ title, description, executorId })),
        bulkAddParticipators: (payload: BulkAddParticipatorsStartPayload) => dispatch(bulkAddParticipators(payload)),
        switchKeyActivity: (params) => dispatch(switchKeyActivity(params)),
        addUserMention: (userToMention: number, mentioningUserName: string) =>
            dispatch(
                editCommentary({
                    content: `[@${mentioningUserName}#${userToMention}]`,
                    editionType: CommentEditionType.ADD_MENTION,
                }),
            ),
        setSubscribeIsDisabled: (isDisabled: boolean) => setSubscribeIsDisabled(isDisabled),
        initTagsEditorInstance: (payload: InstanceActionPayload<TagsEditorInstanceDescriptor>) =>
            dispatch(initTagsEditorInstance(payload)),
        dropTagsEditorInstance: (payload: string) => dispatch(dropTagsEditorInstance(payload)),
    };
}
