import gql from 'graphql-tag';

export const GET_FILTERS_VALUES_QUERY = gql`
    query GetFilterValues($filter: CorrectionFilter) {
        executionIds: getCorrectionsFilterValues(field: "planIds", filter: $filter) {
            values
        }
        approver: getCorrectionsFilterValues(field: "approver", filter: $filter) {
            values
        }
        author: getCorrectionsFilterValues(field: "author", filter: $filter) {
            values
        }
        status: getCorrectionsFilterValues(field: "status", filter: $filter) {
            values
        }
        directions: getCorrectionsFilterValues(field: "directions", filter: $filter) {
            values
        }
        types: getCorrectionsFilterValues(field: "type", filter: $filter) {
            values
        }
        activityNames: getCorrectionsFilterValues(field: "activityNames", filter: $filter) {
            values
        }
        budgetItemNames: getCorrectionsFilterValues(field: "budgetItemNames", filter: $filter) {
            values
        }
        blocks: getCorrectionsFilterValues(field: "blocks", filter: $filter) {
            values
        }
        activityTypes: getCorrectionsFilterValues(field: "activityTypes", filter: $filter) {
            values
        }
        tools: getCorrectionsFilterValues(field: "tools", filter: $filter) {
            values
        }
        regionalities: getCorrectionsFilterValues(field: "regionalities", filter: $filter) {
            values
        }
        territories: getCorrectionsFilterValues(field: "territories", filter: $filter) {
            values
        }
        divisionIds: getCorrectionsFilterValues(field: "divisionids", filter: $filter) {
            values
        }
        costCenterIds: getCorrectionsFilterValues(field: "costCenterIds", filter: $filter) {
            values
        }
    }
`;
