import * as React from 'react';
import classNames from 'classnames';

import { CorrectionStatus } from '@mrm/budget/Correction';

import { Popup } from 'sber-marketing-ui';
import { Comment } from './Comment';

import * as style from './WithPopup.scss';

interface Props extends RenderParams {
    children: (params: RenderParams) => React.ReactNode | React.ReactNode[];
    visibility: boolean;
    isStatusApproved: boolean;
    disableConfirm: boolean;
    userIsBudgetExpert: boolean;
    userIsAuthorCorrection: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    onOutOfContentClick: () => void;
    onChangeCommentStatus: (error: string) => void;
    onSetCommentValue: (value: string) => void;
}

interface RenderParams {
    userIsBudgetExpert: boolean;
    userIsAuthorCorrection: boolean;
    onSetNewCorrectionStatus: (correctionStatus: CorrectionStatus) => void;
}

export const WithPopup = ({
    children,
    visibility,
    isStatusApproved,
    disableConfirm,
    userIsBudgetExpert,
    userIsAuthorCorrection,
    onCancel,
    onConfirm,
    onOutOfContentClick,
    onChangeCommentStatus,
    onSetCommentValue,
    onSetNewCorrectionStatus,
}: Props): JSX.Element => {
    return (
        <>
            {children({
                onSetNewCorrectionStatus,
                userIsBudgetExpert,
                userIsAuthorCorrection,
            })}

            {visibility && (
                <Popup onOutOfContentClick={onOutOfContentClick}>
                    <div className={style.root}>
                        {!isStatusApproved && (
                            <div className={style.title}>Введите причину отклонения корректировки</div>
                        )}

                        {!isStatusApproved && (
                            <div className={style.commentWrapper}>
                                <Comment onChangeStatus={onChangeCommentStatus} setRejectComment={onSetCommentValue} />
                            </div>
                        )}

                        <div className={style.controller}>
                            <div className={style.titleController}>
                                {isStatusApproved ? 'Утвердить' : 'Отклонить'} корректировку?
                            </div>

                            <div className={style.buttonWrapper}>
                                <div
                                    className={classNames(style.button, disableConfirm ? style.disable : style.enable)}
                                    onClick={disableConfirm ? null : onConfirm}
                                >
                                    Да
                                </div>
                                <div className={classNames(style.button, style.enable)} onClick={onCancel}>
                                    Нет
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>
            )}
        </>
    );
};
