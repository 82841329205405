import * as React from 'react';
import classNames from 'classnames';

import { DropdownOptionsProvider, DropdownOptions, useDropdownOptions, useDefaultRef } from '@common/hooks';
import { Flex, FlexProps } from '@common/components';

import * as styles from './Dropdown.scss';

export interface DropdownProps extends FlexProps {
    dropdown: DropdownOptions;
    width?: React.HTMLProps<HTMLDivElement>['style']['width'];
    rootRef?: React.MutableRefObject<HTMLDivElement>;
}

const DropdownContent: React.FC<DropdownProps> = ({
    dropdown,
    width,
    style,
    className,
    rootRef,
    children,
    ...props
}) => {
    const ref = useDefaultRef(rootRef);
    const parentDropdown = useDropdownOptions();
    dropdown.parent = ref;

    React.useEffect(() => {
        const parent = parentDropdown?.parent.current || document.body;
        const current = ref.current;
        const target = dropdown.target.current;

        const listener = (e: MouseEvent) => {
            // TODO: change to `e.stopPropagation()` after update to React 17+
            // @ts-ignore
            e._stopPropagation = e._stopPropagation || current;
        };

        const close = (e: MouseEvent) => {
            // TODO: remove the next line of code after update to React 17+
            // @ts-ignore
            if (e._stopPropagation && e._stopPropagation !== e.currentTarget) return;
            dropdown.close();
        };

        const mouseenter = () => {
            const event = new MouseEvent('mouseenter', {
                view: window,
                bubbles: true,
                cancelable: true,
            });
            target.dispatchEvent(event);
        };
        const mouseleave = () => {
            const event = new MouseEvent('mouseleave', {
                view: window,
                bubbles: true,
                cancelable: true,
            });
            target.dispatchEvent(event);
        };

        current.addEventListener('mouseenter', mouseenter);
        current.addEventListener('mouseleave', mouseleave);
        current.addEventListener('click', listener);
        current.addEventListener('mousedown', listener);
        parent.addEventListener('mousedown', close);
        return () => {
            current.removeEventListener('mouseenter', mouseenter);
            current.removeEventListener('mouseleave', mouseleave);
            current.removeEventListener('click', listener);
            current.removeEventListener('mousedown', listener);
            parent.removeEventListener('mousedown', close);
        };
    }, []);

    return (
        <Flex
            {...props}
            style={{
                width,
                ...style,
            }}
            rootRef={ref}
            className={classNames(
                styles.root,
                dropdown.stretch && styles.stretch,
                dropdown.isDropdownHide && styles.hide,
                !dropdown.isHover && styles.leave,
                styles[dropdown.placement || 'bottom'],
                className,
                dropdown.dropdownClassName,
            )}
        >
            <DropdownOptionsProvider value={dropdown}>{children}</DropdownOptionsProvider>
        </Flex>
    );
};

export const Dropdown: React.FC<DropdownProps> = (props) =>
    props.dropdown.isDropdownShow ? <DropdownContent {...props} /> : null;
