// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-_styles__root{min-height:600px;padding:16px;position:relative;display:grid;grid-template-rows:auto 1fr auto;grid-row-gap:20px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/CreationForm/ParamsEditor/styles.scss"],"names":[],"mappings":"AAAA,qHACI,gBAAiB,CAEjB,YAAa,CACb,iBAAkB,CAElB,YAAa,CACb,gCAAiC,CACjC,iBAAkB","sourcesContent":[".root {\n    min-height: 600px;\n\n    padding: 16px;\n    position: relative;\n\n    display: grid;\n    grid-template-rows: auto 1fr auto;\n    grid-row-gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
