import * as React from 'react';
import * as lodash from 'lodash';
import classnames from 'classnames';
import { Moment } from 'moment';
import { TaskStatus } from 'sber-marketing-types/frontend';
import { Link } from 'react-router-dom';
import { Icon, IconType } from 'sber-marketing-ui';

import * as style from './ShortCard.scss';

import { CardBodyIndicatorPosition, CardBodyIndicatorColor, CardActivityNameInfo, CardAuthor } from '@common/Card';
import { Date } from '../Date';
import { Status } from '../Status';
import { ButtonList } from '@common/Card/CardFooter/ButtunList';
import { Indicator } from '@common/Card/CardBody';
import { round } from 'lodash';

interface Props {
    isLoading: boolean;
    link: string;
    canEdit: boolean;
    canEditDeadline: boolean;
    canDisplayRate: boolean;
    color: CardBodyIndicatorColor;
    activityId: number;
    activity: {
        name: string;
        organizationName: string;
        divisionName: string;
        productName: string;
    };
    deadline: Moment;
    title: string;
    status: TaskStatus;
    rate: number;
    buttons: JSX.Element[];
    author: {
        firstName: string;
        secondName: string;
        organizationName: string;
        departmentName: string;
    };
    isOpenedInSidebar: boolean;
    editDeadline: (date: Moment) => Promise<void>;
    editStatus: (status: TaskStatus) => Promise<void>;
    onCardClick: () => void;
}

export const ShortCard = ({
    isLoading,
    link,
    canEdit,
    canEditDeadline,
    canDisplayRate,
    color,
    activityId,
    activity,
    deadline,
    status,
    rate,
    author,
    title,
    buttons,
    isOpenedInSidebar,
    editDeadline,
    editStatus,
    onCardClick,
}: Props): JSX.Element => {
    return (
        <div className={classnames(style.root, isOpenedInSidebar && style.rootWithOpenedSidebar)} onClick={onCardClick}>
            <div className={style.leftSide}>
                {activity && <CardActivityNameInfo text={activity.name} activityId={activityId} />}

                <div className={style.title} title={title}>
                    {title}
                </div>
            </div>

            <div className={style.rightSide}>
                <div className={style.rightSideLine}>
                    <Date editDate={editDeadline} canEdit={canEdit || canEditDeadline} date={deadline} />

                    <div className={style.statusWrapper}>
                        <Status status={status} deadline={deadline} canEdit={canEdit} changeStatus={editStatus} />
                    </div>

                    {rate && canDisplayRate && (
                        <div className={style.rate}>
                            <div className={style.rateIcon}>
                                <Icon type={IconType.STAR_ACTIVE} svgSize={12} />
                            </div>
                            <div className={style.rateValue}>{round(rate, 1)}</div>
                        </div>
                    )}
                </div>

                <div className={style.rightSideLine}>
                    {author && (
                        <CardAuthor name={`${author.firstName}${author.secondName ? ` ${author.secondName}` : ''}`} />
                    )}
                </div>
            </div>

            <div className={style.buttons}>
                <Link to={isLoading ? null : link} className={style.openTaskLink}>
                    <Icon type={IconType.LINK_FOR_SHARING} svgSize={16} />
                </Link>

                {!lodash.isEmpty(buttons) && <ButtonList buttons={buttons} />}
            </div>

            <Indicator
                data={{
                    position: CardBodyIndicatorPosition.LEFT,
                    color,
                }}
            />
        </div>
    );
};
