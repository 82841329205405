import * as React from 'react';

import { TableWidgetTemplate } from './TableWidgetTemplate';

interface Props {
    creativeRequestId: string;
    currentStage: number;
}

export class TableWidgetBehaviour extends React.PureComponent<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        const { creativeRequestId, currentStage } = this.props;

        return React.createElement(TableWidgetTemplate, {
            creativeRequestId,
            // displayActualCostSummary: currentStage > 3,
            // displayFundsActions: currentStage > 3,
            displayActualCostSummary: false,
            displayFundsActions: false,
            displayStatusColumns: currentStage >= 4,
        });
    }
}
