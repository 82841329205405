import * as React from 'react';
import classNames from 'classnames';

import { Color } from '../enums';
import { getColorClassNames } from './utils';
import { Icon, IconType } from 'sber-marketing-ui';

import * as styles from './styles.scss';

interface Props {
    color?: Color;
}

export const OverdueStagesTitle: React.FC<Props> = ({ color }) => {
    return (
        <div className={styles.root}>
            <div className={classNames(styles.iconWrapper, styles.attentionIconWrapper, getColorClassNames(color))}>
                <Icon type={IconType.ATTENTION_CIRCLE} svgSize={16} />
            </div>
            Просрочены этапы
        </div>
    );
};

export const OverdueTasksTitle: React.FC<Props> = ({ color }) => {
    return (
        <div className={styles.root}>
            <div className={classNames(styles.iconWrapper, styles.filledIconWrapper, getColorClassNames(color))}>
                <Icon type={IconType.CIRCLE_BOLD_FILLED_CROSS} svgSize={16} />
            </div>
            Просрочены задачи
        </div>
    );
};

export const ActiveActivityTitle: React.FC<Props> = ({ color }) => {
    return (
        <div className={styles.root}>
            <div className={classNames(styles.iconWrapper, styles.activeIconWrapper, getColorClassNames(color))}>
                <Icon type={IconType.TARGET_POINT} svgSize={24} />
            </div>
            Активные
        </div>
    );
};

export const PlannedStartTitle: React.FC<Props> = ({ color }) => {
    return (
        <div className={styles.root}>
            <div className={classNames(styles.iconWrapper, styles.clockIconWrapper, getColorClassNames(color))}>
                <Icon type={IconType.CIRCLE_CLOCK} svgSize={16} />
            </div>
            Планируется запуск
        </div>
    );
};

export const DeadlineApproachingTitle: React.FC<Props> = ({ color }) => {
    return (
        <div className={styles.root}>
            <div className={classNames(styles.iconWrapper, styles.attentionIconWrapper, getColorClassNames(color))}>
                <Icon type={IconType.ATTENTION_CIRCLE} svgSize={16} />
            </div>
            Скоро дедлайн
        </div>
    );
};

export const OverdueTitle: React.FC<Props> = ({ color }) => {
    return (
        <div className={styles.root}>
            <div className={classNames(styles.iconWrapper, styles.filledIconWrapper, getColorClassNames(color))}>
                <Icon type={IconType.CIRCLE_BOLD_FILLED_CROSS} svgSize={16} />
            </div>
            Просрочены
        </div>
    );
};

export const ActiveTaskTitle: React.FC<Props> = ({ color }) => {
    return (
        <div className={styles.root}>
            <div className={classNames(styles.iconWrapper, styles.activeIconWrapper, getColorClassNames(color))}>
                <Icon type={IconType.TARGET_POINT} svgSize={24} />
            </div>
            Активные
        </div>
    );
};

export const ClosedTitle: React.FC<Props> = ({ color }) => {
    return (
        <div className={styles.root}>
            <div className={classNames(styles.iconWrapper, styles.clockIconWrapper, getColorClassNames(color))}>
                <Icon type={IconType.CIRCLE_CLOCK} svgSize={16} />
            </div>
            Закрытые
        </div>
    );
};
