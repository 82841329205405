import * as React from 'react';
import autobind from 'autobind-decorator';
import { FilterMenu, FilterItem, Filter, SelectionStatus } from 'sber-marketing-ui';

import { TasksByStageId } from '@store/activityTasksPage';
import { Stage } from '@store/activityTasksPage/stages';

import { Utils } from '@common/Utils';

interface Props {
    activityStages: Stage[];
    tasksByStageId: TasksByStageId;
    selectedItems: string[];
    onCheckItems: (checkedItems: React.ReactText[]) => void;
    onFilterChange?: () => void;
}

export class ActivityStagesFilter extends React.PureComponent<Props> implements Filter {
    private filterMenuRef: React.RefObject<FilterMenu> = React.createRef();

    public render(): JSX.Element {
        return (
            <FilterMenu
                qaId="dashboardActivityStageFilter"
                ref={this.filterMenuRef}
                filterTitle="Этапы задач"
                items={this.formatItems()}
                checkedItemsIds={this.props.selectedItems}
                onItemSelection={this.onItemSelection}
            />
        );
    }

    public getSelectionStatus(): SelectionStatus {
        return this.filterMenuRef.current ? this.filterMenuRef.current.getSelectionStatus() : SelectionStatus.None;
    }

    public check() {
        this.filterMenuRef.current.check();
    }

    public uncheck() {
        this.filterMenuRef.current.uncheck();
    }

    @autobind
    private onItemSelection(checkedItems: React.ReactText[]) {
        if (this.props.onFilterChange) {
            this.props.onFilterChange();
        }

        this.props.onCheckItems(checkedItems);
    }

    private formatItems(): FilterItem[] {
        const { activityStages, tasksByStageId } = this.props;
        let order = 1;

        return Object.keys(tasksByStageId).map((stageId) => {
            const stageIdToUse = stageId === 'null' ? null : stageId;
            const stage = activityStages.find((stage) => stage.id === stageIdToUse);
            const taskCount = tasksByStageId[stageIdToUse].length;
            const description = `${taskCount} ${Utils.getDeclensionByNumber(taskCount, ['задача', 'задачи', 'задач'])}`;

            const result: FilterItem = {
                id: stageIdToUse,
                title: '',
                description,
            };

            if (!stageIdToUse) {
                result.title = 'Вне этапов';
                result.order = 0;
            } else {
                result.order = order++;
                if (!stage) {
                    result.title = 'Этап не найден';
                } else {
                    result.title = stage.name;
                }
            }

            return result;
        });
    }
}
