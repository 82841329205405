import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Icon, IconType } from 'sber-marketing-ui';
import { List } from './List';

import * as styles from './styles.scss';

export const DropdownContent: React.FC = () => {
    const { creativeRequestId } = useParams<{ creativeRequestId: string }>();

    return (
        <div className={styles.root}>
            <div className={styles.header}>Действие с заявкой</div>
            <div className={styles.content}>
                <List
                    items={[
                        {
                            text: 'В сводную таблицу ЗНК',
                            icon: <Icon className={styles.sideArrowIcon} type={IconType.SIDE_ARROW} />,
                            link: `/pivotTable/lot1?creativeRequestId=${creativeRequestId}`,
                        },
                    ]}
                />
            </div>
        </div>
    );
};
