import { CustomColumnColor } from '@store/budgetExecution';

import { CellBackgroundColor, CellBorderColor } from '../LayerManager';

interface QaCellColor {
    'qa-color'?: string;
}

interface QaCellBorder {
    'qa-border'?: string;
}

export function getQaCellColor(color: CellBackgroundColor | CustomColumnColor): QaCellColor {
    if (!color) {
        return {};
    }

    let qaColor: string;
    switch (color) {
        case CellBackgroundColor.UnsavedChange:
            qaColor = 'UnsavedChange';
            break;
        case CellBackgroundColor.SavedChange:
            qaColor = 'SavedChange';
            break;
        case CellBackgroundColor.PlanOverflow:
            qaColor = 'PlanOverflow';
            break;
        case CellBackgroundColor.Donor:
            qaColor = 'Donor';
            break;
        case CellBackgroundColor.Acceptor:
            qaColor = 'Acceptor';
            break;
        case CustomColumnColor.CurrencySum:
            qaColor = 'CurrencySum';
            break;
        default:
            console.warn(`Missing case for (CellBackgroundColor | CustomColumnColor).${color}`);
            return {};
    }

    return { 'qa-color': qaColor };
}

export function getQaCellBorder(border: CellBorderColor): QaCellBorder {
    if (!border) {
        return {};
    }

    let qaBorder: string;
    switch (border) {
        case CellBorderColor.Invalid:
            qaBorder = 'Invalid';
            break;
        case CellBorderColor.Donor:
            qaBorder = 'Donor';
            break;
        case CellBorderColor.Acceptor:
            qaBorder = 'Acceptor';
            break;
        default:
            console.warn(`Missing case for CellBorderColor.${border}`);
            return {};
    }

    return { 'qa-border': qaBorder };
}
