import { actionCreatorFactory } from 'typescript-fsa';
import { BudgetItem } from '@mrm/budget';

import { LoadingStatus } from '@store/commonTypes';

const actionCreator = actionCreatorFactory('MISC_BYDGET_ITEMS_SYNC');

export const setLoadingStatus = actionCreator<LoadingStatus>('SET_LOADING_STATUS');
export const addBudgetItems = actionCreator<BudgetItem[]>('ADD_BUDGET_ITEMS');
export const resetState = actionCreator('RESET_STATE');
