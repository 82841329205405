import type { BriefField } from 'sber-marketing-types/frontend';
import { FieldType } from 'sber-marketing-types/frontend';

export const checkerByFieldTypeForAvailabilityFormulasInFields: Record<FieldType, (field: BriefField) => boolean> = {
    [FieldType.TEXT]: (field) => Boolean(field?.properties?.formulas?.length),
    [FieldType.DROPDOWN]: (field) =>
        field?.properties?.selectContent?.map((item) => Boolean(item?.formulas?.length)).some((item) => item),
    [FieldType.SWITCH_GROUP]: (field) =>
        field?.properties?.switches?.map((item) => Boolean(item?.formulas?.length)).some((item) => item),
    [FieldType.UNLOCKABLE_INPUT]: (field) => Boolean(field?.properties?.formulas?.length),
    [FieldType.INPUT]: () => false,
    [FieldType.FILE]: () => false,
    [FieldType.TOGGLE]: () => false,
    [FieldType.TEXTAREA]: () => false,
};
