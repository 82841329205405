import * as React from 'react';
import classNames from 'classnames';

import * as style from './StagesListWidget.scss';

import { Icon, IconType } from 'sber-marketing-ui';
import { SidebarButton } from './SidebarButton';
import { Name } from './Name';
import { ActionsMenu } from './ActionsMenu';

interface Props {
    stages: StageProps[];
}

export const StagesListWidgetTemplate = ({ stages }: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.topLine}>
                <Name />
                <ActionsMenu />
                <SidebarButton />
            </div>

            <div className={style.stages}>
                {stages.map((item) => (
                    <Stage {...item} key={item.stageOrder} />
                ))}
            </div>
        </div>
    );
};

export interface StageProps {
    stageOrder: number;
    name: string;
    title: string;
    isSelected: boolean;
    isActive: boolean;
    isFinished: boolean;
    onClick: (stageOrder: number) => void;
}

function Stage({ stageOrder, name, title, isSelected, isActive, isFinished, onClick }: StageProps) {
    return (
        <div
            className={classNames(
                style.stage,
                isSelected && style.selected,
                isActive && style.active,
                isFinished && style.finished,
            )}
            onClick={isFinished || isActive ? () => onClick(stageOrder) : () => {}}
        >
            <div className={style.stageOrder}>
                {isFinished ? <Icon type={IconType.CHECK28} svgSize={28} /> : stageOrder}
            </div>

            <div className={style.stageProperties}>
                <div className={style.stageName}>{name}</div>

                <div className={style.stageTitle}>{title}</div>
            </div>
        </div>
    );
}
