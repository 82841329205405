import * as React from 'react';
import { ActivityParams } from 'sber-marketing-types/frontend';
import { LabeledSelect_redesign as LabeledSelect, Popup, PopupTheme, Preloader } from 'sber-marketing-ui';

import { ActivityFiles, ActivityNameDropdown } from '@budgetCommon';

import { Header } from './Header';
import { Footer } from './Footer';

import * as style from './BindActivityPopup.scss';

export interface Props {
    title: string;
    loading: boolean;
    isDisabledBindButton: boolean;
    selectedExpertUserId: number;
    selectedActivityId: number;
    expertUsers: {
        id: number;
        name: string;
    }[];
    onCloseButtonClick(): void;
    onBindButtonClick(): void;
    onCancelButtonClick(): void;
    onExpertUserSelection(expertUserId: number): void;
    onActivitySelection(expertUserId: number): void;
    ignoreActivityInDropdown(activity: ActivityParams): void;
}

export const BindActivityPopup: React.FC<Props> = ({
    title,
    loading,
    isDisabledBindButton,
    selectedExpertUserId,
    selectedActivityId,
    expertUsers,
    onCloseButtonClick,
    onCancelButtonClick,
    onBindButtonClick,
    onExpertUserSelection,
    onActivitySelection,
    ignoreActivityInDropdown,
}) => {
    return (
        <Popup theme={PopupTheme.bindActivityTheme}>
            <div
                className={style.root}
                {...{
                    'qa-id': 'budgetExecutionLineModalBindActivityPopup',
                }}
            >
                <div className={style.headerWrapper}>
                    <Header titleText={title} onCloseButtonClick={onCloseButtonClick} />
                </div>

                <div className={style.blockWrapper}>
                    <ActivityNameDropdown
                        selectedActivityId={selectedActivityId}
                        selectActivity={onActivitySelection}
                        ignoreActivity={ignoreActivityInDropdown}
                    />
                </div>

                <div className={style.blockWrapper}>
                    <LabeledSelect
                        title={'Согласующий эксперт'}
                        items={expertUsers.map(({ id, name }) => ({
                            label: name,
                            value: id,
                        }))}
                        selectedValue={selectedExpertUserId}
                        onSelection={onExpertUserSelection}
                        qaId="budgetExecutionLineModalBindActivityPopupExpertSelect"
                    />
                </div>

                {selectedActivityId && (
                    <div className={style.endBlockWrapper}>
                        <ActivityFiles activityId={selectedActivityId} />
                    </div>
                )}

                <div className={style.footerWrapper}>
                    <Footer
                        isDisabledBindButton={isDisabledBindButton}
                        onBindButtonClick={onBindButtonClick}
                        onCancelButtonClick={onCancelButtonClick}
                    />
                </div>

                {loading && <Preloader />}
            </div>
        </Popup>
    );
};
