import * as React from 'react';
// import * as classnames from 'classnames';

import * as style from './CreativeStageActions.scss';

import { Icon, IconType, Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';

interface Props {
    canEdit: boolean;
    counter: number;
    onContinueButtonClick: () => void;
}

export const CreativeStageActionsTemplate = ({ canEdit, counter, onContinueButtonClick }: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.title}>
                Добавьте ссылки на посты
                {counter == 2 && (
                    <div className={style.titleIcon}>
                        <Icon type={IconType.ACCEPT_ACTIVE} svgSize={24} />
                    </div>
                )}
                {counter < 2 && (
                    <>
                        &nbsp;
                        <span className={style.counter}>({counter}/2)</span>
                    </>
                )}
            </div>

            <div className={style.button}>
                <Button
                    disabled={!canEdit || counter < 2}
                    theme={ButtonTheme.MainRounded}
                    onClick={onContinueButtonClick}
                >
                    Продолжить
                </Button>
            </div>
        </div>
    );
};
