import * as React from 'react';
import { StaticSkeleton as Skeleton } from 'sber-marketing-ui';

import * as styles from './NewsColumnPreloader.scss';

export function NewsFitlerPreloader(): JSX.Element {
    return (
        <React.Fragment>
            <div className={styles.filters}>
                <Skeleton loadingClassName={styles.filtersFirstSkeleton} />
                <Skeleton loadingClassName={styles.filtersSecondSkeleton} />
            </div>

            <div className={styles.horizontalBar} />
        </React.Fragment>
    );
}
