import { axios } from '../lib/axios';
import {
    Autopilot,
    GetAutopilotListParams,
    CreateAutopilotParams,
    UpdateAutopilotParams,
    UpdateAutopilotBriefParams,
    UpdateAutopilotBudgetParams,
    UpdateAutopilotCreativeParams,
    AutopilotPlacementPlanData,
    UpdateAutopilotPlacementParams,
    UpdateAutopilotDashboardParams,
} from 'sber-marketing-types/backend';

const BASE_URL = '/api/autopilot/v1';

export class AutopilotLegacyApi {
    public static async getAutopilot(autopilotId: string): Promise<Autopilot> {
        const res = await axios.get(`${BASE_URL}/${autopilotId}`);

        return res.data;
    }

    public static async getMediaplan(autopilotId: string): Promise<AutopilotPlacementPlanData[]> {
        const res = await axios.get(`${BASE_URL}/${autopilotId}/mediaplan`);

        return res.data;
    }

    public static async getList(params?: GetAutopilotListParams): Promise<Autopilot[]> {
        const res = await axios.post(`${BASE_URL}/list`, params);

        return res.data;
    }

    public static async createAutopilot(params: CreateAutopilotParams): Promise<void> {
        const res = await axios.post(BASE_URL, params);

        return res.data;
    }

    public static async updateAutopilot(autopilotId: string, params: UpdateAutopilotParams): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}`, params);
    }

    public static async updateBrief(autopilotId: string, params: UpdateAutopilotBriefParams): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/brief`, params);
    }

    public static async updateMediaplan(autopilotId: string): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/mediaplan`, {});
    }

    public static async updateBudget(autopilotId: string, params: UpdateAutopilotBudgetParams): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/budget`, params);
    }

    public static async updateCreative(autopilotId: string, params: UpdateAutopilotCreativeParams): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/creative`, params);
    }

    public static async updatePlacement(autopilotId: string, params: UpdateAutopilotPlacementParams): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/placement`, params);
    }

    public static async updateDashboard(autopilotId: string, params: UpdateAutopilotDashboardParams): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/dashboard`, params);
    }

    public static async launchPlacements(autopilotId: string): Promise<void> {
        await axios.post<void>(`${BASE_URL}/${autopilotId}/launch`);
    }

    public static async stopPlacements(autopilotId: string): Promise<void> {
        await axios.post<void>(`${BASE_URL}/${autopilotId}/stop`);
    }
}
