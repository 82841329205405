import * as React from 'react';
import classnames from 'classnames';

import { DatesFormatter } from '@common/Utils';

import { QaIds } from '../CellDescription';

import * as styles from '../CommonStyles.scss';

interface Props {
    grayTheme?: boolean;
    serialNumber: number;
    sapComment: string;
    useMonth: boolean;
    monthIndex: number;
}

export function TitleRowContentBlock({
    grayTheme,
    serialNumber,
    sapComment,
    useMonth,
    monthIndex,
}: Props): JSX.Element {
    return (
        <div className={styles.fullRowContent}>
            <span
                className={classnames(styles.content, styles.contentBold, grayTheme && styles.contentGray)}
                {...{
                    'qa-id': QaIds.SerialNumber,
                }}
            >
                ID {serialNumber} &nbsp;
            </span>

            <span
                title={sapComment}
                className={classnames(styles.content, styles.activityName, grayTheme && styles.contentGray)}
                {...{
                    'qa-id': QaIds.SapComment,
                }}
            >
                {sapComment}
            </span>

            {useMonth && (
                <span
                    className={classnames(styles.month, styles.monthAlignRight)}
                    {...{
                        'qa-id': QaIds.Month,
                    }}
                >
                    {DatesFormatter.months[monthIndex]}
                </span>
            )}
        </div>
    );
}
