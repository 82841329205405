import * as React from 'react';
import { Moment } from 'moment';
import { round } from 'lodash';
import { Link } from 'react-router-dom';

import { TaskStatus } from 'sber-marketing-types/frontend';

import * as style from '../TaskCard.scss';

import { Stage as StageParams } from '@store/activityTasksPage/stages';

import { Icon, IconType } from 'sber-marketing-ui';
import {
    CardTitle,
    CardBody,
    CardBodyIndicatorPosition,
    CardBodyIndicatorColor,
    CardContent,
    CardContentLeftSidebar,
    CardContentRightSidebar,
    CardActivityNameInfo,
    CardOrganizationAndBlockSubInfo,
    CardProductSubInfo,
    CardWorkTypeSubInfo,
    CardAuthor,
    CardFooter,
} from '@common/Card';
import { Date } from '../Date';
import { Status } from '../Status';
import { Stage } from '../Stage';
import classNames from 'classnames';

interface Props {
    isLoading: boolean;
    canEdit: boolean;
    canEditDeadline: boolean;
    canDisplayRate: boolean;
    color: CardBodyIndicatorColor;
    displayActivityName: boolean;
    activityId: number;
    activity: {
        name: string;
        organizationName: string;
        divisionName: string;
        productName: string;
        blockName: string;
    };
    workTypeName: string;
    deadline: Moment;
    title: string;
    status: TaskStatus;
    indicators: JSX.Element[];
    buttons: JSX.Element[];
    stage: string;
    rate: number;
    author: {
        firstName: string;
        secondName: string;
        organizationName: string;
        departmentName: string;
    };
    activityStages?: StageParams[];
    link: string;
    isOpenedInSidebar: boolean;
    onCardClick: () => void;
    editDeadline: (date: Moment) => Promise<void>;
    editStatus: (status: TaskStatus) => Promise<void>;
    editStage: (stageId: string) => Promise<void>;
}

export const FullCard = ({
    isLoading,
    color,
    displayActivityName,
    activityId,
    activity,
    workTypeName,
    deadline,
    status,
    author,
    title,
    indicators,
    buttons,
    canEdit,
    canEditDeadline,
    canDisplayRate,
    stage,
    rate,
    activityStages,
    link,
    isOpenedInSidebar,
    editDeadline,
    editStatus,
    editStage,
    onCardClick,
}: Props): JSX.Element => {
    const linkClassNames = classNames([style.openTaskLink, isOpenedInSidebar && style.openTaskLinkSelected]);
    function onLinkClick(event: React.MouseEvent<HTMLAnchorElement>) {
        if (link === '#') {
            event.preventDefault();
        } else {
            event.stopPropagation();
        }
    }

    return (
        <CardBody
            indicator={{
                position: CardBodyIndicatorPosition.LEFT,
                color,
            }}
            isOpenedInSidebar={isOpenedInSidebar}
            onCardClick={onCardClick}
        >
            <CardContent>
                <CardContentLeftSidebar>
                    {displayActivityName ? (
                        <>
                            {activity && <CardActivityNameInfo text={activity.name} activityId={activityId} />}
                            {activity && activity.productName && <CardProductSubInfo text={activity.productName} />}
                            {workTypeName && <CardWorkTypeSubInfo text={workTypeName} />}
                        </>
                    ) : (
                        <>
                            <Stage
                                canEdit={canEdit && !!activityStages.length}
                                stages={activityStages}
                                stage={stage}
                                changeStage={editStage}
                            />

                            {workTypeName && <CardWorkTypeSubInfo text={workTypeName} />}
                            {activity && (
                                <CardOrganizationAndBlockSubInfo
                                    text={`${activity.organizationName}${
                                        activity.blockName ? `, ${activity.blockName}` : ''
                                    }`}
                                />
                            )}
                            {activity && activity.productName && <CardProductSubInfo text={activity.productName} />}
                        </>
                    )}
                </CardContentLeftSidebar>

                <CardContentRightSidebar>
                    <div className={style.rightSideHeader}>
                        <Date canEdit={canEdit || canEditDeadline} date={deadline} editDate={editDeadline} />

                        <div className={style.statusWrapper}>
                            <Status canEdit={canEdit} status={status} deadline={deadline} changeStatus={editStatus} />
                        </div>

                        {rate && canDisplayRate && (
                            <div className={style.rate}>
                                <div className={style.rateIcon}>
                                    <Icon type={IconType.STAR_ACTIVE} svgSize={12} />
                                </div>
                                <div className={style.rateValue}>{round(rate, 1)}</div>
                            </div>
                        )}
                    </div>

                    {author && (
                        <CardAuthor
                            name={`${author.firstName}${author.secondName ? ` ${author.secondName}` : ''}`}
                            subText={`${author.organizationName}${
                                author.departmentName ? `, ${author.departmentName}` : ''
                            }`}
                        />
                    )}
                </CardContentRightSidebar>
            </CardContent>

            <div className={style.titleContainer}>
                <div className={style.titleWrapper}>
                    <CardTitle tooltip={title}>
                        <div className={style.title}>{title}</div>
                    </CardTitle>
                </div>
                {link !== '#' && (
                    <Link to={isLoading ? null : link} className={linkClassNames} onClick={onLinkClick}>
                        Открыть задачу
                    </Link>
                )}
            </div>

            <CardFooter indicators={indicators} buttons={buttons} />
        </CardBody>
    );
};
