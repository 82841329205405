// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-layout-Tabs-_Tabs__root{display:flex;gap:16px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/layout/Tabs/Tabs.scss"],"names":[],"mappings":"AAAA,8DACE,YAAa,CACb,QAAS","sourcesContent":[".root {\n  display: flex;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-components-layout-Tabs-_Tabs__root"
};
export default ___CSS_LOADER_EXPORT___;
