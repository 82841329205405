import * as React from 'react';

import * as style from './SearchInput.scss';

import { Icon, IconType, Input_redesign as Input, InputTheme_redesign as InputTheme } from 'sber-marketing-ui';

interface Props {
    value: string;
    onValueChange: (value: string) => void;
}

export const SearchInputTemplate = ({ value, onValueChange }: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <Icon type={IconType.SEARCH} svgSize={10} />

            <Input
                autoFocus
                placeholder={'Поиск'}
                value={value}
                theme={InputTheme.Simple}
                className={style.input}
                onInputChange={onValueChange}
            />
        </div>
    );
};
