import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { TaskApi } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';

import * as actions from './actions';
import { LoadTasksParams } from './types';
import { getLoadingStatus, getTaskById, getFetchersCount } from './selectors';

export const loadTasks = bindThunkAction<StoreState, LoadTasksParams, void, Error>(
    actions.loadTasksAsync,
    async (params, dispatch, getState) => {
        const { store } = params;
        let { ids } = params;

        const setStatus: (status: LoadingStatus) => void = (status) =>
            dispatch(
                actions.setLoadingStatus({
                    store,
                    status,
                }),
            );
        const state = getState();
        const loadingStatus = getLoadingStatus(state, store);

        try {
            let tasks;

            if (ids) {
                const notLoadedTasksIds = ids.filter((id) => !getTaskById(state, id));

                if (notLoadedTasksIds.length) {
                    dispatch(actions.incFetchersCount(store));
                    setStatus(LoadingStatus.LOADING);
                    tasks = await TaskApi.getTaskList({ ids: notLoadedTasksIds });
                }
            } else {
                if (loadingStatus !== LoadingStatus.LOADED) {
                    dispatch(actions.incFetchersCount(store));
                    setStatus(LoadingStatus.LOADING);
                    tasks = await TaskApi.getTaskList({});

                    ids = tasks.map((task) => task.id);
                }
            }

            if (tasks) {
                dispatch(actions.decFetchersCount(store));
                dispatch(actions.loadEntities(tasks));
            }
            dispatch(
                actions.setStoreIds({
                    store,
                    ids,
                }),
            );

            const fetchersCount = getFetchersCount(getState(), store);
            if (!fetchersCount) {
                setStatus(LoadingStatus.LOADED);
            }
        } catch (e) {
            setStatus(LoadingStatus.ERROR);
        }
    },
);
