import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import { getTableFilters, AppliedFiltersNames } from '@store/budgetPlanning';
import * as actions from '@store/budgetPlanning';

interface Props {
    children: (params: RenderParams) => React.ReactElement;
}

interface RenderParams {
    filtersNames: AppliedFiltersNames;
    resetFilters: () => void;
}

export const WithStore: React.FC<Props> = ({ children }) => {
    const dispatch = useDispatch();

    const { filtersNames } = useSelector((state: StoreState) => {
        const { appliedFiltersNames } = getTableFilters(state);

        return {
            filtersNames: appliedFiltersNames || [],
        };
    });

    const resetFilters = React.useCallback(() => {
        dispatch(actions.loadBudgetItems([]));
        dispatch(actions.resetFilters());
        dispatch(actions.resetPreviouslyLoadedFilters());
    }, []);

    return children({
        filtersNames,
        resetFilters,
    });
};
