import * as React from 'react';

import { ArchiveDescription } from './ArchiveDescription';
import { FilesInfo } from './FilesInfo';

import * as styles from './ArchiveInfo.scss';

export function ArchiveInfo(): JSX.Element {
    return (
        <div className={styles.root}>
            <FilesInfo />
            <ArchiveDescription />
        </div>
    );
}
