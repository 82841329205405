import * as React from 'react';
import { useSelector } from 'react-redux';
import { Icon, IconType } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { ComponentState, getBudgetTransferMenuState } from '@store/budgetExecution/budgetTransferMenu';

import * as styles from './SourceDestinationDescription.scss';

export function SourceDestinationDescription(): JSX.Element {
    const componentState = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.componentState,
    );

    let cellDescription: string;
    let arrowClass: string;
    switch (componentState) {
        case ComponentState.ExternalIncomeTransfer:
            cellDescription = 'Ячейка-получатель';
            break;
        case ComponentState.ExternalOutcomeTransfer:
            cellDescription = 'Ячейка-источник';
            arrowClass = styles.arrowRotated;
            break;
    }

    return (
        <div className={styles.root}>
            <div className={styles.cellDescription}>{cellDescription}</div>

            <div className={styles.arrowWrapper}>
                <Icon type={IconType.BUDGET_TRANSFER_MENU_ARROW} svgSize={25} className={arrowClass} />
            </div>
        </div>
    );
}
