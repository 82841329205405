import * as React from 'react';
import classNames from 'classnames';

import * as style from './Menu.scss';

import { CorrectionStatus } from '@mrm/budget';

import { Icon, IconType } from 'sber-marketing-ui';

interface Props {
    status: CorrectionStatus;
    userIsBudgetExpert: boolean;
    userIsAuthorCorrection: boolean;
    acceptButtonIsLocked: boolean;
    onAcceptButtonClick: () => void;
    onRejectButtonClick: () => void;
}

export const Menu = ({
    status,
    userIsBudgetExpert,
    userIsAuthorCorrection,
    acceptButtonIsLocked,
    onAcceptButtonClick,
    onRejectButtonClick,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            {status == CorrectionStatus.NeedApproving && userIsBudgetExpert && renderNeedApprovingStatus()}

            {status == CorrectionStatus.NeedApproving &&
                !userIsBudgetExpert &&
                !userIsAuthorCorrection &&
                renderOnApprovingStatus()}

            {status === CorrectionStatus.NeedApproving &&
                !userIsBudgetExpert &&
                userIsAuthorCorrection &&
                renderNeedRejectedStatus()}

            {status == CorrectionStatus.Approved && renderAcceptedStatus()}

            {status == CorrectionStatus.Rejected && renderRejectedStatus()}
        </div>
    );

    function renderNeedApprovingStatus(): JSX.Element {
        return (
            <div className={style.needApprovingStatus}>
                <div
                    className={classNames(style.statusButton, style.approveButton)}
                    onClick={!acceptButtonIsLocked ? onAcceptButtonClick : null}
                    {...{
                        'qa-id': 'correctionCardAcceptButton',
                    }}
                >
                    <Icon type={IconType.CIRCLE_CHECK} />
                </div>

                <div
                    className={classNames(style.statusButton, style.rejectButton)}
                    {...{
                        'qa-id': 'correctionCardRejectButton',
                    }}
                    onClick={onRejectButtonClick}
                >
                    <Icon type={IconType.CIRCLE_CROSS} />
                </div>
            </div>
        );
    }

    function renderOnApprovingStatus(): JSX.Element {
        return (
            <div className={style.onApprovingStatus}>
                <div
                    className={style.statusName}
                    {...{
                        'qa-id': 'correctionCardStatus',
                    }}
                >
                    На согласовании
                </div>
            </div>
        );
    }

    function renderNeedRejectedStatus(): JSX.Element {
        return (
            <div className={style.needRejectedStatus}>
                <div
                    className={classNames(style.statusButton, style.rejectButton)}
                    onClick={onRejectButtonClick}
                    {...{
                        'qa-id': 'correctionCardRejectButton',
                    }}
                >
                    <Icon type={IconType.CIRCLE_CROSS} />
                </div>
            </div>
        );
    }

    function renderAcceptedStatus(): JSX.Element {
        return (
            <div className={style.acceptedStatus}>
                <div
                    className={style.statusName}
                    {...{
                        'qa-id': 'correctionCardStatus',
                    }}
                >
                    Принята
                </div>
            </div>
        );
    }

    function renderRejectedStatus(): JSX.Element {
        return (
            <div className={style.rejectedStatus}>
                <div
                    className={style.statusName}
                    {...{
                        'qa-id': 'correctionCardStatus',
                    }}
                >
                    Отклонена
                </div>
            </div>
        );
    }
};
