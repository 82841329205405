import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { AxiosError } from 'axios';

import { StoreState } from '../..';
import { AddFileToTaskPayload, FetchError } from '../types';
import { asyncActions } from '../actions';
import { getTaskInfo } from '../selectors';
import { FileAsset } from '../../commonTypes';
import { runUploadOne } from '../../fileAssets';

export const addFileToTask = bindThunkAction<StoreState, AddFileToTaskPayload, FileAsset, FetchError>(
    asyncActions.addFileToTask,
    async ({ file }: AddFileToTaskPayload, dispatch, getState): Promise<FileAsset> => {
        const { id: taskId } = getTaskInfo(getState());
        let result: FileAsset | null = null;

        try {
            result = await runUploadOne({
                dispatch,
                params: { taskId },
                fileOrAssetOrId: file,
            });
        } catch (error) {
            const { response, message } = <AxiosError>error;
            if (response) {
                throw new FetchError(response.status, response.statusText, message);
            } else {
                throw new FetchError(0, 'Unknown', message);
            }
        }

        return result;
    },
);
