import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';

import { useDefaultRef, useDefaultState } from '@common/hooks';

import * as styles from './Details.scss';

export interface DetailsProps extends Omit<React.HTMLProps<HTMLDetailsElement>, 'summary'> {
    summary?: React.ReactNode;
    onToggle?: (open: boolean) => void;
    defaultOpen?: boolean;
    summaryClassName?: string;
    summaryBefore?: React.ReactNode;
    summaryAfter?: React.ReactNode;
    rootRef?: React.MutableRefObject<HTMLElement>;
    wrapperRef?: React.MutableRefObject<HTMLDivElement>;
}

export function Details({
    className,
    children,
    style,
    summary,
    open: openProp,
    defaultOpen = false,
    summaryBefore,
    summaryAfter,
    summaryClassName,
    onToggle,
    rootRef: rootRefProp,
    wrapperRef: wrapperRefProp,
    ...props
}: DetailsProps) {
    const rootRef = useDefaultRef(rootRefProp);
    const wrapperRef = useDefaultRef(wrapperRefProp);
    const [open, setOpen] = useDefaultState(openProp, onToggle, defaultOpen);
    const [closeDelay, setCloseDelay] = React.useState(false);
    const [summaryHeight, setSummaryHeight] = React.useState(17);
    const [opened, setOpened] = React.useState(defaultOpen);
    const [height, setHeight] = React.useState(17);
    const openRef = React.useRef(false);
    const summaryRef = React.useRef<HTMLElement>();
    const isClosing = !open && closeDelay;
    const isOpening = !opened && open;
    const isHideIcon = !onToggle && openProp;

    openRef.current = open;

    const newStyle = {
        ...style,
        '--details-height': `${height || summaryHeight}px`,
    } as React.CSSProperties;

    React.useEffect(() => {
        let timer: any;

        if (open) {
            setHeight(rootRef.current.scrollHeight);
            setCloseDelay(true);
            timer = setTimeout(() => {
                setOpened(true);
            }, 300);
        } else {
            setHeight(summaryHeight);
            timer = setTimeout(() => {
                setCloseDelay(false);
                setOpened(false);
            }, 300);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [open]);

    React.useEffect(() => {
        const listener = () => {
            if (openRef.current && wrapperRef.current) {
                setHeight(summaryHeight + wrapperRef.current.scrollHeight + 21);
            }
        };
        const resizeObserver = new ResizeObserver(listener);
        resizeObserver.observe(wrapperRef.current);

        listener();

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    React.useEffect(() => {
        setSummaryHeight(summaryRef.current.offsetHeight);
    }, []);

    const handleSummaryClick: React.MouseEventHandler<HTMLElement> = (e) => {
        e.preventDefault();
    };

    const handleSummaryContentClick: React.MouseEventHandler<HTMLElement> = () => {
        setOpen(!open);
    };

    return (
        <details
            {...props}
            ref={rootRef}
            style={newStyle}
            open={open || closeDelay}
            className={classNames(styles.root, (isClosing || isOpening) && styles.animate, className)}
        >
            <summary
                onClick={handleSummaryClick}
                ref={summaryRef}
                className={classNames(styles.summary, open && styles.open, summaryClassName)}
            >
                {summaryBefore}
                <span
                    data-qa-id="Details__summary"
                    onClick={handleSummaryContentClick}
                    className={styles.summaryContent}
                >
                    {summary}
                    {!isHideIcon && <Icon className={styles.summaryIcon} svgSize={8} type={IconType.ARROW_ROUNDED} />}
                </span>
                {summaryAfter}
            </summary>
            <div className={styles.wrapper} ref={wrapperRef}>
                {children}
            </div>
        </details>
    );
}
