// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-task-components-buttons-AddTaskFileButton-_AddTaskFileButton__input{position:absolute;left:-999px;top:-999px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/task/components/buttons/AddTaskFileButton/AddTaskFileButton.scss"],"names":[],"mappings":"AAIA,wFACE,iBAAkB,CAClB,WAAY,CACZ,UAAW","sourcesContent":[".root {\n\n}\n\n.input {\n  position: absolute;\n  left: -999px;\n  top: -999px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "src-client-modules-task-components-buttons-AddTaskFileButton-_AddTaskFileButton__input"
};
export default ___CSS_LOADER_EXPORT___;
