// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-ChannelEditor-_ChannelEditor__root{box-sizing:border-box;width:309px;max-height:489px;padding:16px 0 24px;background-color:#ffffff;box-shadow:0 3px 10px 0 rgba(0,0,0,0.17)}.src-client-modules-activity-pages-task-ChannelEditor-_ChannelEditor__nameInput{width:calc(100% - 32px);margin:0 auto}.src-client-modules-activity-pages-task-ChannelEditor-_ChannelEditor__suggest{width:100%;margin:16px auto 0}.src-client-modules-activity-pages-task-ChannelEditor-_ChannelEditor__buttons{display:flex;width:calc(100% - 32px);margin:29px auto 0;align-items:center;justify-content:space-between}.src-client-modules-activity-pages-task-ChannelEditor-_ChannelEditor__button{height:26px}.src-client-modules-activity-pages-task-ChannelEditor-_ChannelEditor__button_cancel{width:118px}.src-client-modules-activity-pages-task-ChannelEditor-_ChannelEditor__button_submit{width:131px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/ChannelEditor/ChannelEditor.scss"],"names":[],"mappings":"AAAA,2EACI,qBAAsB,CACtB,WAAY,CACZ,gBAAiB,CACjB,mBAAoB,CAEpB,wBAAyB,CACzB,wCAA4C,CAC/C,gFAGG,uBAAwB,CACxB,aAAc,CACjB,8EAGG,UAAW,CACX,kBAAmB,CACtB,8EAGG,YAAa,CAEb,uBAAwB,CACxB,kBAAmB,CAEnB,kBAAmB,CACnB,6BAA8B,CACjC,6EAGG,WAAY,CACf,oFAGG,WAAY,CACf,oFAGG,WAAY","sourcesContent":[".root {\n    box-sizing: border-box;\n    width: 309px;\n    max-height: 489px;\n    padding: 16px 0 24px;\n\n    background-color: #ffffff;\n    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.17);\n}\n\n.nameInput {\n    width: calc(100% - 32px);\n    margin: 0 auto;\n}\n\n.suggest {\n    width: 100%;\n    margin: 16px auto 0;\n}\n\n.buttons {\n    display: flex;\n\n    width: calc(100% - 32px);\n    margin: 29px auto 0;\n\n    align-items: center;\n    justify-content: space-between;\n}\n\n.button {\n    height: 26px;\n}\n\n.button_cancel {\n    width: 118px;\n}\n\n.button_submit {\n    width: 131px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-ChannelEditor-_ChannelEditor__root",
	"nameInput": "src-client-modules-activity-pages-task-ChannelEditor-_ChannelEditor__nameInput",
	"suggest": "src-client-modules-activity-pages-task-ChannelEditor-_ChannelEditor__suggest",
	"buttons": "src-client-modules-activity-pages-task-ChannelEditor-_ChannelEditor__buttons",
	"button": "src-client-modules-activity-pages-task-ChannelEditor-_ChannelEditor__button",
	"button_cancel": "src-client-modules-activity-pages-task-ChannelEditor-_ChannelEditor__button_cancel",
	"button_submit": "src-client-modules-activity-pages-task-ChannelEditor-_ChannelEditor__button_submit"
};
export default ___CSS_LOADER_EXPORT___;
