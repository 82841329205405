import { FileAsset } from '@store/commonTypes';
import { useSelector } from 'react-redux';
import { StoreState } from '@store';
import { getAssetsByIds, getCommentaryAssets } from '@store/taskPage/selectors';
import { FileAssetListWithGallery } from '@common/FileAssetListWithGallery';
import * as React from 'react';

export interface AssetsProps {
    userIsCommentAuthor: boolean;
    taskId: string;
    useR7Controls: boolean;
    commentId?: string;
    assetsIds?: string[];
    hideAssetDate?: boolean;
    assetIsDeletable?: boolean;
    disableAssetDownload?: boolean;
    className: string;
    showByDefault?: boolean;
    onAssetRemoveClick: (data: FileAsset) => void;
}

export function Assets({
    userIsCommentAuthor,
    taskId,
    useR7Controls,
    commentId,
    assetsIds,
    hideAssetDate,
    assetIsDeletable,
    disableAssetDownload,
    className,
    onAssetRemoveClick,
}: AssetsProps): JSX.Element {
    const assets: FileAsset[] = useSelector((state: StoreState) => {
        const res: FileAsset[] = assetsIds ? getAssetsByIds(state, assetsIds) : getCommentaryAssets(state, commentId);

        if (!res.length) {
            console.warn(`Expected fileAssets for comment, but found none`);
        }

        return res;
    }).map((asset) => ({
        ...asset,
        hideDate: hideAssetDate,
        isDeletable: assetIsDeletable,
    }));

    return assets.length ? (
        <FileAssetListWithGallery
            useR7Controls={useR7Controls}
            canEditR7Files={userIsCommentAuthor}
            taskId={taskId}
            commentId={commentId}
            assets={assets}
            className={className}
            canDelete={true}
            disableDownload={disableAssetDownload}
            onRemove={onAssetRemoveClick}
        />
    ) : null;
}
