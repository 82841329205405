import * as React from 'react';
import classNames from 'classnames';

import * as style from './SapComment.scss';

import { Icon, IconType, Preloader } from 'sber-marketing-ui';
import { InputWithSendButton } from './InputWithSendButton';

interface Props {
    canEditBudgetItem: boolean;
    value: string;
    originalValue: string;
    correctionValue: string;
    editEnabled: boolean;
    loading: boolean;
    className?: string;
    onInputChange: (value: string) => void;
    onInputSendButtonClick: () => void;
    onEditButtonClick: () => void;
    onInputOutsideClick: () => void;
}

export const SapComment = ({
    canEditBudgetItem,
    value,
    originalValue,
    correctionValue,
    editEnabled,
    loading,
    className,
    onInputChange,
    onInputSendButtonClick,
    onEditButtonClick,
    onInputOutsideClick,
}: Props): JSX.Element => {
    const valueIsChanged = value !== (correctionValue || originalValue);
    const hasCorrection = correctionValue !== null;

    return (
        <div
            className={classNames(style.root, className)}
            {...{
                'qa-id': 'dashboardRightSidebarBudgetInfoSapComment',
            }}
        >
            <div className={style.titleLine}>
                <div className={style.title}>Название проекта (комментарий для SAP)</div>

                {canEditBudgetItem && !editEnabled && !hasCorrection && (
                    <div
                        className={style.editButton}
                        onClick={onEditButtonClick}
                        {...{
                            'qa-id': 'dashboardRightSidebarBudgetInfoSapCommentEditButton',
                        }}
                    >
                        <div className={style.editButtonIcon}>
                            <Icon type={IconType.PROJECT_STAGES_EDIT_ICON} svgSize={18} />
                        </div>
                        Редактировать
                    </div>
                )}

                {hasCorrection && (
                    <div
                        className={style.correctionIndicator}
                        {...{
                            'qa-id': 'dashboardRightSidebarBudgetInfoSapCommentCorrectionIndicator',
                        }}
                    >
                        Корректировка согласуется
                    </div>
                )}
            </div>

            {editEnabled && (
                <div className={style.nameInput}>
                    <InputWithSendButton
                        value={value}
                        placeholder={''}
                        sendButtonDisabled={!valueIsChanged}
                        onInputChange={onInputChange}
                        onSendButtonClick={onInputSendButtonClick}
                        onClickOutside={onInputOutsideClick}
                    />
                </div>
            )}

            {!editEnabled && !hasCorrection && (
                <div
                    className={style.name}
                    {...{
                        'qa-id': 'dashboardRightSidebarBudgetInfoSapCommentValue',
                    }}
                >
                    {value}
                </div>
            )}

            {!editEnabled && hasCorrection && (
                <div className={style.name}>
                    <span className={style.originalName}>{originalValue}</span>
                    <span className={style.arrowSign}>⟶</span>
                    {correctionValue}
                </div>
            )}

            {loading && <Preloader />}
        </div>
    );
};
