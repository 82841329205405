import * as React from 'react';
import classNames from 'classnames';

import * as style from './CreativePage.scss';

import { OpacityTransition, WithTooltip, TooltipAnchor, Icon, IconType } from 'sber-marketing-ui';
import { StagesListWidget } from './StagesListWidget';
import { StageActions } from './StageActions';
import { RequestStageWidget, TableWidget } from './StageWidgets';
import { Sidebar } from './Sidebar';

interface Props {
    loading: boolean;
    currentStage: number;
    selectedStage: number;
    activityId: number;
    creativeRequestId: string;
    openedSidebar: boolean;
    onCloseSidebar: () => void;
}

export const CreativePageTemplate = ({
    loading,
    currentStage,
    selectedStage,
    activityId,
    creativeRequestId,
    openedSidebar = false,
    onCloseSidebar = () => {},
}: Props): JSX.Element => {
    return (
        <OpacityTransition start={!loading}>
            <div className={classNames(style.root, openedSidebar ? style.rootWithIndent : '')}>
                <div className={style.content}>
                    <div className={style.stageList}>
                        <StagesListWidget currentStage={currentStage} />
                    </div>

                    <div
                        className={classNames(style.stageActions, selectedStage < 1.5 ? style.stageActionsSticky : '')}
                    >
                        <StageActions creativeRequestId={creativeRequestId} currentStage={currentStage} />
                    </div>

                    <div className={style.stageWidget}>
                        {selectedStage === 1 && (
                            <RequestStageWidget activityId={activityId} creativeRequestId={creativeRequestId} />
                        )}

                        {selectedStage > 1 && (
                            <TableWidget creativeRequestId={creativeRequestId} currentStage={currentStage} />
                        )}
                    </div>
                </div>

                {openedSidebar && (
                    <div className={style.sidebar}>
                        <Sidebar creativeRequestId={creativeRequestId} onClose={onCloseSidebar} />
                    </div>
                )}
            </div>
        </OpacityTransition>
    );
};

interface DownloadButtonProps {
    onClick: () => void;
}

export const DownloadButton = ({ onClick }: DownloadButtonProps) => {
    return (
        <div className={style.downloadButton} onClick={onClick}>
            <WithTooltip content={'Скачать таблицу'} anchor={TooltipAnchor.LEFT}>
                <Icon type={IconType.DOWNLOAD_DOCUMENT} svgSize={20} />
            </WithTooltip>
        </div>
    );
};
