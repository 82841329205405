import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { AutopilotTPeriodPercent } from 'sber-marketing-types/backend';
import { CellParams, CellPosition, LineId } from '../../types';
import {
    Accessor,
    TitleAccessor,
    ValueSetter,
    DescriptionAccessor,
    CustomStyleAccessor,
    AccessorParams,
    CellType,
    LineType,
    ColumnParams,
    ColumnName,
} from '../../ColumnsConfig';

import { InputCell, InputCellEdit, SelectCell, SelectCellEdit, TextCell } from '../../CellTypes';

export const CellComponentsByColumnType: Record<
    CellType,
    {
        cell: React.ClassType<any, any, any>;
        editCell?: React.ClassType<any, any, any>;
    }
> = {
    [CellType.Text]: {
        cell: TextCell,
    },
    [CellType.Input]: {
        cell: InputCell,
        editCell: InputCellEdit,
    },

    [CellType.Select]: {
        cell: SelectCell,
        editCell: SelectCellEdit,
    },
};

interface Props {
    getColumnsConfig: () => { [columnName: string]: ColumnParams };
    getManualRatingSplit: () => AutopilotTPeriodPercent[];
    getValidation: () => boolean;
    onCellClose: () => void;
}

export class CellsFactory {
    private getColumnsConfig: () => { [columnName: string]: ColumnParams };
    private getManualRatingSplit: () => AutopilotTPeriodPercent[];
    private getValidation: () => boolean;
    private onCellClose: () => void;

    public constructor(props: Props) {
        this.getColumnsConfig = props.getColumnsConfig;
        this.getManualRatingSplit = props.getManualRatingSplit;
        this.getValidation = props.getValidation;
        this.onCellClose = props.onCellClose;
    }

    @autobind
    public makeCellParams(cellPosition: CellPosition, edit: boolean): CellParams {
        const value = this.getCellValue(cellPosition);

        return {
            component: value !== undefined ? this.getCellComponent(cellPosition, edit) : null,
            cellProps: value !== undefined ? this.makeCellProps(cellPosition, edit) : null,
            readOnly: value !== undefined ? this.checkReadOnlyStatus(cellPosition, edit) : true,
        };
    }

    @autobind
    public getCellComponent(cellPosition: CellPosition, edit: boolean): React.ClassType<any, any, any> {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const columnType = lodash.get(this.getColumnsConfig(), [columnName, 'type', lineType]);

        if (!columnType) {
            return null;
        }

        return edit ? CellComponentsByColumnType[columnType].editCell : CellComponentsByColumnType[columnType].cell;
    }

    @autobind
    public makeCellProps(cellPosition: CellPosition, edit: boolean): any {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const columnType = lodash.get(this.getColumnsConfig(), [columnName, 'type', lineType]);

        if (!columnType) {
            return null;
        }

        let cellProps: any;

        switch (columnType) {
            case CellType.Text:
                cellProps = this.makeTextCellProps(cellPosition);
                break;

            case CellType.Input:
                cellProps = this.makeInputCellProps(cellPosition, edit);
                break;

            case CellType.Select:
                cellProps = this.makeSelectCellProps(cellPosition, edit);
                break;
        }

        cellProps = this.applyCustomStyles(cellPosition, cellProps);

        return cellProps;
    }

    public getCellValue(cellPosition: CellPosition) {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const accessor: Accessor = lodash.get(this.getColumnsConfig(), [columnName, 'getValue', lineType]);

        if (!accessor) {
            return undefined;
        }

        const params = this.makeAccessorParams(cellPosition);

        return accessor(params);
    }

    @autobind
    public checkReadOnlyStatus(cellPosition: CellPosition, edit: boolean): boolean {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const cellComponent = this.getCellComponent(cellPosition, edit);

        if (!cellComponent) {
            return true;
        }

        return lodash.get(this.getColumnsConfig(), [columnName, 'readOnly', lineType]) || false;
    }

    private makeTextCellProps(cellPosition: CellPosition): any {
        return {
            title: this.getCellValue(cellPosition) || '—',
        };
    }

    private makeInputCellProps(cellPosition: CellPosition, edit: boolean): any {
        const value = this.getCellValue(cellPosition);

        return edit
            ? {
                  title: value,
                  placeholder: '',
                  onValueChange: this.makeValueChangeHandler(cellPosition),
              }
            : {
                  title: value !== null ? value : '—',
              };
    }

    private makeSelectCellProps(cellPosition: CellPosition, edit: boolean): any {
        const value = this.getCellValue(cellPosition);
        const items = this.getCellItems(cellPosition);
        const description = this.getCellDescription(cellPosition);

        return edit
            ? {
                  title: value !== null ? items.find((item) => item.value === value)?.title : `—`,
                  description,
                  items,
                  selectedValue: value,
                  onValueChange: this.makeValueChangeHandler(cellPosition),
              }
            : {
                  title: value !== null ? items.find((item) => item.value === value)?.title : `—`,
                  description,
              };
    }

    public getCellTitle(cellPosition: CellPosition) {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const accessor: TitleAccessor = lodash.get(this.getColumnsConfig(), [columnName, 'getTitle', lineType]);

        if (!accessor) {
            return undefined;
        }

        const params = this.makeAccessorParams(cellPosition);

        return accessor(params);
    }

    private getCellItems(cellPosition: CellPosition): any[] {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const itemsAccessor: Accessor = lodash.get(this.getColumnsConfig(), [columnName, 'getItems', lineType]);

        if (!itemsAccessor) {
            return [];
        }

        const params = this.makeAccessorParams(cellPosition);

        return itemsAccessor(params);
    }

    private getCellDescription(cellPosition: CellPosition): string {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const descriptionAccessor: DescriptionAccessor = lodash.get(this.getColumnsConfig(), [
            columnName,
            'getDescription',
            lineType,
        ]);

        if (!descriptionAccessor) {
            return null;
        }

        const params = this.makeAccessorParams(cellPosition);

        return descriptionAccessor(params);
    }

    private getLineType(lineId: LineId): LineType {
        return LineType.Line;
    }

    private makeAccessorParams(cellPosition: CellPosition): AccessorParams {
        const { lineId } = cellPosition;

        return {
            id: lineId,
            manualRatingSplit: this.getManualRatingSplit(),
        };
    }

    private makeValueChangeHandler(cellPosition: CellPosition) {
        return async (value: any) => {
            const { lineId, columnName } = cellPosition;

            const lineType = this.getLineType(lineId);
            const params = this.makeAccessorParams(cellPosition);

            const valueSetter: ValueSetter = lodash.get(this.getColumnsConfig(), [columnName, 'setValue', lineType]);

            await valueSetter(params, value);

            this.onCellClose();
        };
    }

    private applyCustomStyles(cellPosition: CellPosition, cellProps: any): any {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const customStyleAccessor: CustomStyleAccessor = lodash.get(this.getColumnsConfig(), [
            columnName,
            'customStyle',
            lineType,
        ]);

        if (customStyleAccessor) {
            const params = this.makeAccessorParams(cellPosition);

            const customStyle = customStyleAccessor(params);

            cellProps = {
                ...cellProps,
                customStyle: { ...cellProps.customStyle, ...customStyle },
            };
        }

        if (columnName !== ColumnName.Name) {
            const percentsAreValid = this.getValidation();

            if (!percentsAreValid) {
                cellProps = {
                    ...cellProps,
                    customStyle: {
                        ...cellProps.customStyle,
                        backgroundColor: '#fff6f6',
                    },
                };
            }
        }

        return cellProps;
    }
}
