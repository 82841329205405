import * as React from 'react';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { SelectItem } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import {
    loadExperts,
    getExpertsAsSelectItems,
    getBudgetTransferMenuState,
} from '@store/budgetExecution/budgetTransferMenu';

import { BudgetExpertsPopup } from './BudgetExpertsPopup';

interface Props extends Partial<MapProps & DispatchProps> {
    closePopup: () => void;
    submitPopup: (expertId: number) => void;
}

interface MapProps {
    experts: SelectItem[];
    expertsLoadingStatus: LoadingStatus;
}

interface DispatchProps {
    loadExperts(): void;
}

interface State {
    selectedExpert: number;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BudgetExpertsPopupContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedExpert: props.experts.length ? +props.experts[0].value : null,
        };
    }

    public componentDidMount(): void {
        this.props.loadExperts();
    }

    public UNSAFE_componentWillReceiveProps(props: Props): void {
        if (!this.state.selectedExpert && props.experts.length) {
            this.setState({
                selectedExpert: +props.experts[0].value,
            });
        }
    }

    public render(): JSX.Element {
        const { experts, expertsLoadingStatus, closePopup } = this.props;
        const { selectedExpert } = this.state;

        return React.createElement(BudgetExpertsPopup, {
            experts,
            selectedExpert,
            showPreloader: expertsLoadingStatus !== LoadingStatus.LOADED,
            closePopup,
            submitPopup: this.onSubmitButtonClick,
            onExpertSelection: this.onExpertSelection,
            disabledSubmitButton: !selectedExpert,
        });
    }

    @autobind
    private onExpertSelection(value: string): void {
        this.setState({ selectedExpert: +value });
    }

    @autobind
    private onSubmitButtonClick(): void {
        this.props.submitPopup(this.state.selectedExpert);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const expertsLoadingStatus = getBudgetTransferMenuState(state).experts.loadingStatus;

    return {
        experts: getExpertsAsSelectItems(state),
        expertsLoadingStatus,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        loadExperts: () => dispatch(loadExperts(null)),
    };
}
