import * as React from 'react';
import { ActivityParams } from 'sber-marketing-types/frontend';
import { OneTaskResponseParams } from 'sber-marketing-types/backend';
import { WithTooltip, TooltipAnchor, StaticSkeleton } from 'sber-marketing-ui';

import { HeaderWithOverflowCheck } from '@common/SidebarWithTabs/common/HeaderWithOverflowCheck';
import { useTextOverflowCheck } from '@common/hooks';

import * as styles from './Header.scss';

interface Props {
    activityContent: ActivityParams;
    taskContent: OneTaskResponseParams;
    closeTaskSidebar?: () => void;
    goBackToActivitySidebar: (activityId: number) => void;
}

export function Header({
    activityContent,
    taskContent,
    goBackToActivitySidebar,
    closeTaskSidebar,
}: Props): JSX.Element {
    return (
        <React.Fragment>
            <ActivityName activityContent={activityContent} goBackToActivitySidebar={goBackToActivitySidebar} />

            <HeaderWithOverflowCheck
                qaId="taskSidebarTaskHeader"
                showPreloader={!taskContent}
                content={taskContent?.title}
                sourceLink={`/activity/${taskContent?.activityId}/task/${taskContent?.id}`}
                onCloseButtonClick={closeTaskSidebar}
            />
        </React.Fragment>
    );
}

interface ActivityNameProps {
    activityContent: ActivityParams;
    goBackToActivitySidebar: (activityId: number) => void;
}

function ActivityName({ activityContent, goBackToActivitySidebar }: ActivityNameProps): JSX.Element {
    const ref = React.useRef<HTMLSpanElement>();
    const { horizontal: activityNameOverflow } = useTextOverflowCheck(ref);

    const showPreloader = !activityContent;

    function onBackToActivityButtonClick() {
        goBackToActivitySidebar(activityContent?.id);
    }

    if (showPreloader) {
        return <StaticSkeleton loadingClassName={styles.activityTitleSkeleton} />;
    }

    return (
        <WithTooltip hidden={!activityNameOverflow} content={activityContent.name} anchor={TooltipAnchor.LEFT}>
            <div
                className={styles.activityTitle}
                {...{
                    'qa-id': 'taskSidebarActivityHeader',
                }}
            >
                <span className={styles.activityTitleTextField}>Проект:&nbsp;</span>
                <span ref={ref} onClick={onBackToActivityButtonClick} className={styles.activityTitleText}>
                    {activityContent.name}
                </span>
            </div>
        </WithTooltip>
    );
}
