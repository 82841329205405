import { createSelector } from 'reselect';
import { includes, sortBy } from 'lodash';

import { TaskPageState, Department, SortOrder } from '../types';
import { getTaskPageState, getIds, getSortOrderAsThird, getId } from './lib';

export const getDepartmentById = createSelector(
    [getTaskPageState, getId],
    ({ departments }: TaskPageState, id: string): Department => departments[id] || ({} as any),
);

export const getDepartmentsByIds = createSelector(
    [getTaskPageState, getIds],
    ({ departments }: TaskPageState, ids: string[]): Department[] => ids.map((id) => departments[id]),
);

export const getDepartmentsByIdsSorted = createSelector(
    [getTaskPageState, getDepartmentsByIds, getSortOrderAsThird],
    (state: TaskPageState, departments: Department[], order: SortOrder): Department[] => {
        const sorted = sortBy(departments, ['name']);
        if (order === SortOrder.DESC) {
            sorted.reverse();
        }
        return sorted;
    },
);

export const getDepartmentsByOrganizationsIds = createSelector(
    [getTaskPageState, getIds],
    ({ departments }: TaskPageState, organizationsIds: string[]): Department[] =>
        Object.keys(departments)
            .map((id) => departments[id])
            .filter(({ organizationId }) => includes(organizationsIds, organizationId)),
);

export const getDepartmentsByOrganizationId = createSelector(
    [getTaskPageState, getId],
    ({ departments }: TaskPageState, orgId: string): Department[] =>
        Object.keys(departments)
            .map((id) => departments[id])
            .filter(({ organizationId }) => organizationId === orgId),
);

export const getDepartmentsExecutors = createSelector(
    getTaskPageState,
    ({ workType, workTypes, departments, departmentExecutorId }: TaskPageState): Department[] => {
        const departmentIds = workTypes[workType] ? workTypes[workType].departmentIds : [departmentExecutorId];
        return departmentIds.map((id) => departments[id]);
    },
);
