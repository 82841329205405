import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { AutopilotDOOHMediaplanCalculation } from 'sber-marketing-types/backend';
import {
    CellPosition,
    ColumnWidths,
    LineId,
    OrderType,
    SortingParams,
    TableBodyCellParams,
    TableHeaderCellParams,
} from './types';
import { CellEvent } from './types';
import type { StoreState } from '@store';
import type { BriefStageForm } from '@store/autopilotDOOH/types';

import { RegionsTableTemplate } from './RegionsTableTemplate';
import { TableView } from './TableView';
import {
    MakeTableColumns,
    leftFixedColumns,
    rightFixedColumns,
    MakeColumnsConfig,
    ColumnParams,
    ColumnsConfigParams,
    ColumnName,
} from './ColumnsConfig';
import { ColumnHeaderFactory, CellsFactory } from './modules';
import {
    setBriefFormValues,
    setManualMediaplanSideQuantityValues,
    setMediaplanLinesBudgetSnapShot,
    setMediaplanLinesSideQuantitySnapShot,
} from '@store/autopilotDOOH/actions';
import {
    getBriefStageForm,
    getMediaplan,
    getMediaplanItemsIdsByFormat,
    getMediaplanLineBudgetsByLineId,
    getMediaplanSideQuantityByLineId,
    getManualMediaplanSideQuantityValues,
    getBudgetAutomationStatus,
} from '@store/autopilotDOOH/selectors';
import { Loader, Saver } from '../../../modules';
import { CellsStorage } from './CellsStorage';

interface Props extends Partial<MapProps & DispatchProps> {}

interface MapProps {
    briefForm: BriefStageForm;
    mediaplan: AutopilotDOOHMediaplanCalculation[];
    mediaplanItemsIdsByFormat: Record<string, string[]>;
    mediaplanLineBudgetsByLineId: Record<string, number>;
    mediaplanSideQuantityByLineId: Record<string, number>;
    manualMediaplanSideQuantityValues: Record<string, number>;
    budgetAutomation: boolean;
}

interface DispatchProps {
    setBriefFormValues: (values: Partial<BriefStageForm>) => void;
    setManualMediaplanSideQuantityValues: (values: Record<string, number>) => void;
    setMediaplanLinesBudgetSnapShot: (values: Record<string, number>) => void;
    setMediaplanLinesSideQuantitySnapShot: (values: Record<string, number>) => void;
}

interface State {
    lineIds: string[];
    sortingParams: SortingParams;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class RegionsTableBehaviour extends React.PureComponent<Props, State> {
    private headerCellsStorage: CellsStorage<string, TableHeaderCellParams>;
    private tableCellsStorage: CellsStorage<CellPosition, TableBodyCellParams>;
    private table: TableView;
    private columnHeaderFactory: ColumnHeaderFactory;
    private cellsFactory: CellsFactory;
    private tableColumns: string[] = [];
    private defaultColumnWidths: ColumnWidths = {};
    // private loader: Loader;
    private saver: Saver;

    public constructor(props: Props) {
        super(props);

        this.state = {
            lineIds: [],
            sortingParams: null,
        };

        // this.loader = Loader.getInstance();
        this.saver = Saver.getInstance();

        this.columnHeaderFactory = new ColumnHeaderFactory({
            getColumnsConfig: this.getColumnsConfig,
            getSortingParams: this.getSortingParams,
            getAllLines: this.getAllLines,
            getSelectedLinesIds: this.getSelectedLinesIds,
            onCheckboxClick: this.onCheckboxHeaderClick,
            onSortingClick: this.onSortingClick,
        });

        this.cellsFactory = new CellsFactory({
            getLine: this.getLine,
            getAllLines: this.getAllLines,
            getColumnsConfig: this.getColumnsConfig,
            getSelectedLinesIds: this.getSelectedLinesIds,
            getSelectedFormatTimings: this.getSelectedFormatTimings,
            getMediaplanLineBudgetsByLineId: this.getMediaplanLineBudgetsByLineId,
            getMediaplanSideQuantityByLineId: this.getMediaplanSideQuantityByLineId,
            getManualMediaplanSideQuantityValues: this.getManualMediaplanSideQuantityValues,
            onCheckboxClick: this.onCheckboxClick,
            onManualMediaplanSideQuantityValueCancel: this.onManualMediaplanSideQuantityValueCancel,
            onCellValueChange: this.onCellValueChange,
        });

        this.headerCellsStorage = new CellsStorage({
            makeCellParams: this.columnHeaderFactory.makeColumnHeaderParams,
        });

        this.tableCellsStorage = new CellsStorage({
            makeCellParams: this.cellsFactory.makeCellParams,
        });

        this.updateDefaultColumnWidths();
        this.updateTableColumns();
    }

    public async componentDidMount() {
        this.updateLineIds();
    }

    public async componentDidUpdate(prevProps: Props, prevState: State) {
        const selectedRowsIdsChanged = this.props.briefForm.selectedRowsIds !== prevProps.briefForm.selectedRowsIds;
        const sortingChanged = this.state.sortingParams !== prevState.sortingParams;

        const selectedFormatTimingsChanged =
            this.props.briefForm.selectedFormatTimings !== prevProps.briefForm.selectedFormatTimings;

        const mediaplanSideQuantityByLineIdChanged =
            this.props.mediaplanSideQuantityByLineId !== prevProps.mediaplanSideQuantityByLineId;

        const mediaplanLineBudgetsByLineIdChanged =
            this.props.mediaplanLineBudgetsByLineId !== prevProps.mediaplanLineBudgetsByLineId;

        const budgetAutomationChanged = this.props.budgetAutomation !== prevProps.budgetAutomation;

        if (selectedRowsIdsChanged) {
            const uncheckedIds = lodash.difference(
                prevProps.briefForm.selectedRowsIds,
                this.props.briefForm.selectedRowsIds,
            );

            const checkedIds = lodash.difference(
                this.props.briefForm.selectedRowsIds,
                prevProps.briefForm.selectedRowsIds,
            );

            const idsToUpdate = [...checkedIds, ...uncheckedIds];

            idsToUpdate.forEach((lineId) => {
                this.updateLineCells(lineId);
            });

            this.updateHeaderCell(ColumnName.Checkbox);
        }

        if (sortingChanged) {
            const columnsToUpdate = lodash.uniq(
                lodash.compact([this.state.sortingParams?.columnName, prevState.sortingParams?.columnName]),
            );

            columnsToUpdate.forEach((columnName) => {
                this.updateHeaderCell(columnName as ColumnName);
            });

            this.updateLineIds();
        }

        if (selectedFormatTimingsChanged) {
            const formats = lodash.keys({
                ...this.props.briefForm.selectedFormatTimings,
                ...prevProps.briefForm.selectedFormatTimings,
            });

            const changedFormats = formats.filter(
                (item) =>
                    this.props.briefForm.selectedFormatTimings[item] !==
                    prevProps.briefForm.selectedFormatTimings[item],
            );

            const lineIdsToUpdate = lodash.flatMap(
                changedFormats,
                (item) => this.props.mediaplanItemsIdsByFormat[item],
            );

            lineIdsToUpdate.forEach((lineId) => {
                this.updateCell({ lineId, columnName: ColumnName.Chrono });
            });
        }

        if (mediaplanSideQuantityByLineIdChanged) {
            this.updateColumnCells(ColumnName.TargetSideQuantity);
        }

        if (mediaplanLineBudgetsByLineIdChanged) {
            this.updateColumnCells(ColumnName.Budget);
        }

        if (budgetAutomationChanged) {
            this.props.setMediaplanLinesBudgetSnapShot(
                !this.props.budgetAutomation ? this.props.mediaplanLineBudgetsByLineId : null,
            );
            this.props.setMediaplanLinesSideQuantitySnapShot(
                !this.props.budgetAutomation ? this.props.mediaplanSideQuantityByLineId : null,
            );
        }
    }

    public render(): JSX.Element {
        const { lineIds } = this.state;

        return React.createElement(RegionsTableTemplate, {
            headerCellsStorage: this.headerCellsStorage,
            tableCellsStorage: this.tableCellsStorage,
            columns: this.tableColumns,
            leftFixedColumns,
            rightFixedColumns,
            lineIds,
            columnWidths: this.defaultColumnWidths,
            tableRef: this.tableRef,
            onCellEvent: this.onCellEvent,
        });
    }

    public setColumnWidths(columnWidths: ColumnWidths) {
        if (columnWidths) {
            this.table.setColumnWidths(columnWidths);
        }
    }

    @autobind
    protected tableRef(component: TableView) {
        this.table = component ? (component as any).getInstance() : null;
    }

    @autobind
    protected onCellValueChange() {
        this.table.setCursorPosition(null);
    }

    @autobind
    protected async onCheckboxHeaderClick() {
        const { briefForm, mediaplan } = this.props;

        const allLinesAreSelected = mediaplan.every((line) => briefForm.selectedRowsIds.includes(line.rowId));

        const updatedSelectedRowsIds = !allLinesAreSelected ? mediaplan.map((line) => line.rowId) : [];

        this.props.setBriefFormValues({ selectedRowsIds: updatedSelectedRowsIds });

        if (lodash.isEmpty(updatedSelectedRowsIds)) {
            this.props.setManualMediaplanSideQuantityValues({});
        }

        await this.saver.saveBriefForm();
    }

    @autobind
    protected async onCheckboxClick(position: CellPosition) {
        const { lineId } = position;

        let updatedSelectedIds = lodash.clone(this.props.briefForm.selectedRowsIds);

        updatedSelectedIds = lodash.xor(updatedSelectedIds, [lineId]);

        this.props.setBriefFormValues({ selectedRowsIds: updatedSelectedIds });

        if (!lodash.includes(updatedSelectedIds, lineId)) {
            this.removeLineIdFromManualMediaplanSideQuantityValues(lineId);
        }

        await this.saver.saveBriefForm();
    }

    @autobind
    protected async onManualMediaplanSideQuantityValueCancel(lineId: LineId) {
        this.removeLineIdFromManualMediaplanSideQuantityValues(lineId);

        await this.saver.saveBriefForm();
    }

    @autobind
    protected async onSortingClick(columnName: string) {
        const { sortingParams } = this.state;

        let updatedSortingParams: SortingParams;

        if (!sortingParams || sortingParams.columnName !== columnName) {
            updatedSortingParams = {
                columnName,
                orderType: OrderType.Desc,
            };
        } else if (sortingParams.columnName === columnName && sortingParams.orderType === OrderType.Desc) {
            updatedSortingParams = {
                columnName,
                orderType: OrderType.Asc,
            };
        } else {
            updatedSortingParams = null;
        }

        this.setState({
            sortingParams: updatedSortingParams,
        });
    }

    @autobind
    protected async onCellEvent(eventType: CellEvent, position: CellPosition) {
        switch (eventType) {
            case CellEvent.EditStart:
                this.updateCell(position, true);
                break;

            case CellEvent.EditEnd:
                this.updateCell(position, false);
                this.table.setCursorPosition(null);
                break;

            case CellEvent.MouseSelection:
                this.updateCell(position, false);
                break;
        }
    }

    private removeLineIdFromManualMediaplanSideQuantityValues(lineId: LineId) {
        const updatedManualMediaplanSideQuantityValues = lodash.clone(this.props.manualMediaplanSideQuantityValues);

        delete updatedManualMediaplanSideQuantityValues[lineId];

        this.props.setManualMediaplanSideQuantityValues(updatedManualMediaplanSideQuantityValues);
    }

    private updateHeaderCell(columnName: ColumnName) {
        this.headerCellsStorage.setCellParams(columnName, this.columnHeaderFactory.makeColumnHeaderParams(columnName));
    }

    private updateCell(position: CellPosition, edit = false) {
        this.tableCellsStorage.setCellParams(position, this.cellsFactory.makeCellParams(position, edit));
    }

    @autobind
    private updateLineCells(lineId: LineId) {
        const allColumns = [...this.tableColumns, ...leftFixedColumns, ...rightFixedColumns];

        allColumns.forEach((columnName) => {
            const cellPosition = { lineId, columnName };

            const cellEditStatus = this.table.getCellEditStatus(cellPosition);
            this.updateCell(cellPosition, cellEditStatus);
        });
    }

    @autobind
    private updateColumnCells(columnName: string) {
        this.state.lineIds.forEach((lineId) => {
            const cellPosition = { lineId, columnName };

            const cellEditStatus = this.table.getCellEditStatus(cellPosition);
            this.updateCell(cellPosition, cellEditStatus);
        });
    }

    private updateTableColumns() {
        const columnsConfigParams = this.makeColumnsConfigParams();

        this.tableColumns = MakeTableColumns(columnsConfigParams);
    }

    private updateLineIds() {
        const sortedLines = this.sortLines(this.props.mediaplan);

        const lineIds = sortedLines.map((item) => item.rowId);

        lineIds.push('total');

        this.setState(
            {
                lineIds,
            },
            () => {
                lineIds.forEach((lineId) => {
                    this.updateLineCells(lineId);
                });
            },
        );
    }

    private sortLines(lines: AutopilotDOOHMediaplanCalculation[]): AutopilotDOOHMediaplanCalculation[] {
        const { sortingParams } = this.state;

        if (!sortingParams) {
            return lines;
        }

        let sortedLines: AutopilotDOOHMediaplanCalculation[];

        sortedLines = lines;

        return sortedLines;
    }

    private updateDefaultColumnWidths() {
        const columnsConfigParams = this.makeColumnsConfigParams();

        const columnsConfig = MakeColumnsConfig(columnsConfigParams);

        this.defaultColumnWidths = lodash.mapValues(columnsConfig, (item) => item.defaultWidth);
    }

    @autobind
    private getColumnsConfig(): { [columnName: string]: ColumnParams } {
        const columnsConfigParams = this.makeColumnsConfigParams();

        return MakeColumnsConfig(columnsConfigParams);
    }

    private makeColumnsConfigParams(): ColumnsConfigParams {
        return {};
    }

    @autobind
    private getSortingParams(): SortingParams {
        return this.state.sortingParams;
    }

    @autobind
    private getSelectedLinesIds(): string[] {
        return this.props.briefForm.selectedRowsIds;
    }

    @autobind
    private getSelectedFormatTimings(): Record<string, number> {
        return this.props.briefForm.selectedFormatTimings;
    }

    @autobind
    private getMediaplanLineBudgetsByLineId(): Record<string, number> {
        return this.props.mediaplanLineBudgetsByLineId;
    }

    @autobind
    private getMediaplanSideQuantityByLineId(): Record<string, number> {
        return this.props.mediaplanSideQuantityByLineId;
    }

    @autobind
    private getManualMediaplanSideQuantityValues(): Record<string, number> {
        return this.props.manualMediaplanSideQuantityValues;
    }

    @autobind
    private getLine(lineId: LineId): AutopilotDOOHMediaplanCalculation {
        return this.props.mediaplan.find((item) => item.rowId === lineId);
    }

    @autobind
    private getAllLines(): AutopilotDOOHMediaplanCalculation[] {
        return this.props.mediaplan;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        briefForm: getBriefStageForm(state),
        mediaplan: getMediaplan(state),
        mediaplanItemsIdsByFormat: getMediaplanItemsIdsByFormat(state),
        mediaplanLineBudgetsByLineId: getMediaplanLineBudgetsByLineId(state),
        mediaplanSideQuantityByLineId: getMediaplanSideQuantityByLineId(state),
        manualMediaplanSideQuantityValues: getManualMediaplanSideQuantityValues(state),
        budgetAutomation: getBudgetAutomationStatus(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setBriefFormValues,
            setManualMediaplanSideQuantityValues,
            setMediaplanLinesBudgetSnapShot,
            setMediaplanLinesSideQuantitySnapShot,
        },
        dispatch,
    );
}
