import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';

import { Flex, Tag, TagProps } from '@common/components';

import * as styles from './Option.scss';

export interface OptionProps<V> extends Omit<TagProps, 'value' | 'onSelect'> {
    value: V;
    title?: string;
    subtitle?: string;
    titleContent?: React.ReactNode;
    subtitleContent?: React.ReactNode;
    height?: number;
    icon?: string | IconType;
    selected?: boolean;
    onSelect?: (value: V) => void;
}

export function Option<V>({
    selected,
    value,
    title,
    subtitle,
    titleContent = title,
    subtitleContent = subtitle,
    icon,
    height,
    className,
    children,
    onSelect,
    onClick,
    ...props
}: OptionProps<V>) {
    const handleClick: typeof onClick = (e) => {
        onSelect?.(value);
        onClick?.(e);
    };

    const iconElement =
        icon &&
        (Object.values(IconType).includes(icon as IconType) ? <Icon type={icon as IconType} /> : <span>{icon}</span>);

    return (
        <Tag
            data-qa-id="Option"
            data-qa-option={title}
            ghost={!selected}
            type={selected ? 'accent' : 'soft'}
            vertical
            align="stretch"
            flat
            {...props}
            style={{ ...props.style, height: height || props.style?.height }}
            onClick={handleClick}
            className={classNames(styles.root, styles[props.type], className)}
        >
            <Flex align="center" className={styles.title} gap={8}>
                {iconElement}
                <div className={styles.titleText}>{titleContent}</div>
            </Flex>
            {subtitleContent && (
                <div data-qa-id="Option__subtitle" className={styles.subtitle}>
                    {subtitleContent}
                </div>
            )}
            {children}
        </Tag>
    );
}
