import * as React from 'react';
import classNames from 'classnames';
import { ActivityFileGroup } from 'sber-marketing-types/frontend';
import { Icon, IconType, WithTooltip, AnimatedEllipsis, Skeleton } from 'sber-marketing-ui';

import { FileAsset } from '@store/commonTypes';

import { TitleWithCountIndicator } from '@common/TitleWithCountIndicator';
import { FileInput } from '@common/FileInput';
import { Utils } from '@common/Utils';
import { DragNDrop } from '@common/DragNDrop';
import { AnimatedInProgressIcon } from '@common/AnimatedInProgressIcon';

import { FileCard, FileProps } from './FileCard';
import { FileDeleteConfirm } from './FileDeleteConfirm';

import * as styles from './FileGroup.scss';

interface Props {
    loading: boolean;
    title: string;
    files: FileProps[];
    fileInputRef: React.RefObject<FileInput>;
    pendingFiles: FileAsset[];
    deletionFileName: string;
    enableControls: boolean;
    showDownloadingPreloader: boolean;
    fileGroup: ActivityFileGroup;
    onFileInput: (files: File[]) => void;
    onAttachButtonClick: () => void;
    onDownloadAllButtonClick: () => void;
    onDeleteButtonClick: (fileId: string) => void;
    onDeleteConfirm: () => void;
    onDeleteCancel: () => void;
}

export function FileGroupTemplate({
    loading,
    title,
    files,
    fileInputRef,
    pendingFiles,
    deletionFileName,
    enableControls,
    showDownloadingPreloader,
    fileGroup,
    onFileInput,
    onAttachButtonClick,
    onDownloadAllButtonClick,
    onDeleteButtonClick,
    onDeleteConfirm,
    onDeleteCancel,
}: Props): JSX.Element {
    const showStub = !(files.length || pendingFiles.length || loading);

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'dashboardRightSidebarFileGroup',
                'qa-file-group': fileGroup,
            }}
        >
            <div className={styles.header}>
                <Skeleton isReady={!loading} loadingClassName={styles.titleWithCountIndicatorLoading}>
                    <TitleWithCountIndicator title={title} count={files.length} zeroCountLablel="0" />
                </Skeleton>

                {!loading && (
                    <div className={styles.controls}>
                        {enableControls && (
                            <div
                                title="Загрузить файл"
                                className={classNames(styles.control, styles.attachButton)}
                                onClick={onAttachButtonClick}
                                {...{
                                    'qa-id': 'dashboardRightSidebarFileGroupAttachFileButton',
                                }}
                            >
                                <Icon svgSize={23} type={IconType.ATTACHMENT_ICON} />
                            </div>
                        )}

                        <DownloadAllButton
                            files={files}
                            showDownloadingPreloader={showDownloadingPreloader}
                            onDownloadAllButtonClick={onDownloadAllButtonClick}
                        />
                    </div>
                )}
            </div>

            <div className={styles.dragNDrop}>
                <DragNDrop onFileInput={onFileInput} />
            </div>

            <div className={styles.filesWrapper}>
                {!!files && (
                    <React.Fragment>
                        {files.map((file) => (
                            <div key={file.id} className={styles.file}>
                                <FileCard {...file} onDeleteButtonClick={onDeleteButtonClick} />
                            </div>
                        ))}

                        {loading && <FileCard loading={loading} />}
                    </React.Fragment>
                )}

                {showStub && 'Файлы отсутствуют'}

                {pendingFiles.map((pendingFile) => (
                    <FileCardStub key={pendingFile.id} title={pendingFile.originName} size={pendingFile.size} />
                ))}
            </div>

            {deletionFileName && (
                <FileDeleteConfirm
                    fileName={deletionFileName}
                    onConfirmButtonClick={onDeleteConfirm}
                    onCancelButtonClick={onDeleteCancel}
                />
            )}

            <FileInput ref={fileInputRef} onChange={onFileInput} />
        </div>
    );
}

type DownloadAllButtonProps = Pick<Props, 'files' | 'showDownloadingPreloader' | 'onDownloadAllButtonClick'>;

function DownloadAllButton({
    files,
    showDownloadingPreloader,
    onDownloadAllButtonClick,
}: DownloadAllButtonProps): JSX.Element {
    if (showDownloadingPreloader) {
        return (
            <div className={styles.downloadInProgressIcon}>
                <AnimatedInProgressIcon />
            </div>
        );
    }

    const title = `Скачать все (ожидаемый размер: ${Utils.formatFileSize(
        files.reduce((acc, file) => acc + file.size, 0),
    )})`;

    return files.length > 1 ? (
        <WithTooltip content={title}>
            <div
                className={classNames(styles.control, styles.downloadAllButton)}
                onClick={onDownloadAllButtonClick}
                {...{
                    'qa-id': 'dashboardRightSidebarFileGroupDownloadAllFilesButton',
                }}
            >
                <Icon svgSize={20} type={IconType.DOWNLOAD_ICON} />
            </div>
        </WithTooltip>
    ) : null;
}

interface FileCardStubProps {
    title: string;
    size: number;
}

function FileCardStub({ title, size }: FileCardStubProps): JSX.Element {
    return (
        <div className={styles.fileCardStub}>
            <div className={styles.fileCardStubFilePreview}>
                <Skeleton
                    isReady={false}
                    loadingClassName={styles.fileAssetLoading}
                    customStyle={{
                        borderRadius: '4px',
                    }}
                >
                    <div />
                </Skeleton>
            </div>

            <div className={styles.fileCardStubContent}>
                <div className={styles.fileCardStubTitle}>{title}</div>

                <div className={classNames(styles.fileCardStubGrey, styles.fileCardStubSize)}>
                    {Utils.formatFileSize(size)}
                </div>

                <div className={styles.fileCardStubGrey}>
                    <AnimatedEllipsis text="Загрузка" />
                </div>
            </div>
        </div>
    );
}
