import { bindActionCreators } from 'redux';
import * as lodash from 'lodash';
import { BudgetItem, BudgetItemStatus } from '@mrm/budget';

import {
    ColumnName,
    UnsavedChange,
    ChangeList,
    REQUIRED_COLUMN_NAMES_SHORT_LIST,
    LineStatusChanges,
    TableLine,
} from '@store/budgetPlanning/types';

import { store } from '@store';
import {
    getUnsavedChanges,
    getBudgetItems,
    getBudgetPlanningPageState,
    getTableLines,
    getLineIdsWithActualChanges,
} from '@store/budgetPlanning/selectors';
import { setValidationStatus } from '@store/budgetPlanning';

import { BudgetItemApi } from '@api';

interface StoreProps {
    unsavedChanges: ChangeList;
    budgetItems: BudgetItem[];
    lineStatusChanges: LineStatusChanges;
    approverStatusChanges: LineStatusChanges;
    allLines: TableLine[];
    lineIdsWithActualChanges: string[];
}

export class TableValidator {
    private static instance: TableValidator;

    private dispatch = bindActionCreators(
        {
            setValidationStatus,
        },
        store.dispatch,
    );

    public static getInstance(): TableValidator {
        if (!TableValidator.instance) {
            TableValidator.instance = new TableValidator();
        }
        return TableValidator.instance;
    }

    public checkTableValidation(): boolean {
        const { unsavedChanges, lineIdsWithActualChanges } = this.getStoreProps();

        return lineIdsWithActualChanges.every((lineId) => this.validateLineChanges(unsavedChanges[lineId]));
    }

    public checkLineValidation(lineId: string): boolean {
        const changes = this.getLineChanges(lineId);

        return this.validateLineChanges(changes);
    }

    public updateValidationDisplay() {
        this.dispatch.setValidationStatus(!this.checkTableValidation());
    }

    public async checkTableDataRelevance(): Promise<boolean> {
        const { lineIdsWithActualChanges, approverStatusChanges, lineStatusChanges, allLines } = this.getStoreProps();

        const changedLinesIds = lodash.uniq([
            ...lineIdsWithActualChanges,
            ...Object.keys(approverStatusChanges),
            ...Object.keys(lineStatusChanges),
            ...allLines.filter((line) => line.status === BudgetItemStatus.Draft).map((line) => line.id),
        ]);

        const linesCheckResults = await Promise.all(
            changedLinesIds.map((lineId) => this.checkLineDataRelevance(lineId)),
        );

        return lodash.every(linesCheckResults, (item) => item);
    }

    public async checkLineDataRelevance(lineId: string): Promise<boolean> {
        const actualBudgetItem = await BudgetItemApi.getBudgetItem(lineId);

        const budgetItem = this.getBudgetItemByLineId(lineId);

        const dictionariesAreActual = lodash.isEqual(actualBudgetItem.dictionary, budgetItem.dictionary);
        const approversAreActual = lodash.isEqual(
            lodash.compact(actualBudgetItem.approvers),
            lodash.compact(budgetItem.approvers),
        );
        const statusAreActual = actualBudgetItem.status === budgetItem.status;

        return dictionariesAreActual && approversAreActual && statusAreActual;
    }

    private validateLineChanges(changes: UnsavedChange[]): boolean {
        const requiredFieldsChanges = this.filterChangesByColumnName(changes, REQUIRED_COLUMN_NAMES_SHORT_LIST);
        const requiredFieldsHaveValues = requiredFieldsChanges.every((change) => !!change.value);

        let activityNameIsValid = true;

        const activityNameChange = changes.find((item) => item.columnName == ColumnName.ActivityName);

        if (activityNameChange) {
            const activityName = activityNameChange.value as string;

            activityNameIsValid = activityName && activityName.length >= 3;
        }

        let sapCommentIsValid = true;

        const sapCommentChange = changes.find((item) => item.columnName == ColumnName.SapComment);

        if (sapCommentChange) {
            const sapComment = sapCommentChange.value as string;

            sapCommentIsValid = sapComment && sapComment.length >= 3;
        }

        return requiredFieldsHaveValues && activityNameIsValid && sapCommentIsValid;
    }

    private filterChangesByColumnName(changes: UnsavedChange[], columnNames: ColumnName[]): UnsavedChange[] {
        return changes.filter((item) => lodash.includes(columnNames, item.columnName));
    }

    private getLineChanges(lineId: string): UnsavedChange[] {
        const { unsavedChanges } = this.getStoreProps();

        return unsavedChanges[lineId] || [];
    }

    private getBudgetItemByLineId(lineId: string): BudgetItem {
        const { budgetItems } = this.getStoreProps();

        return budgetItems.find((budgetItem) => budgetItem.id === lineId);
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        const { lineStatusChanges, approverStatusChanges } = getBudgetPlanningPageState(storeState);

        return {
            unsavedChanges: getUnsavedChanges(storeState),
            budgetItems: getBudgetItems(storeState),
            lineStatusChanges,
            approverStatusChanges,
            allLines: getTableLines(storeState),
            lineIdsWithActualChanges: getLineIdsWithActualChanges(storeState),
        };
    }
}
