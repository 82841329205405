import * as React from 'react';
import classNames from 'classnames';
// import * as lodash from 'lodash';

import * as style from './CreativeStageWidget.scss';

import { Icon, IconType } from 'sber-marketing-ui';
import { LinkInput } from './LinkInput';

interface Props {
    creativeItems: CreativeItemProps[];
    counter: number;
}

export const CreativeStageWidgetTemplate = ({ creativeItems, counter }: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.title}>
                Ссылки на креативы
                <div className={classNames(style.counter, counter === 0 && style.empty)}>{counter}</div>
            </div>

            <div className={style.list}>
                {creativeItems.map((item) => (
                    <CreativeItem {...item} key={item.name} />
                ))}
            </div>
        </div>
    );
};

export interface CreativeItemProps {
    readOnly: boolean;
    name: string;
    icon: IconType;
    value: string;
    onValueChange: (value: string) => void;
}

function CreativeItem({ readOnly, name, icon, value, onValueChange }: CreativeItemProps) {
    return (
        <label className={style.item}>
            <div className={style.itemTitle}>
                <div className={style.itemIcon}>
                    <Icon type={icon} svgSize={24} />
                </div>
                Пост в {name}
            </div>

            {(!readOnly || value) && (
                <div className={style.input}>
                    <LinkInput
                        value={value}
                        placeholder={'Вставьте ссылку на пост'}
                        readOnly={readOnly}
                        onValueChange={onValueChange}
                    />
                </div>
            )}

            {readOnly && !value && <div className={style.readOnlyMessage}>Ссылка не заполнена</div>}
        </label>
    );
}
