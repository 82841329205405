import * as React from 'react';
import { Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';

export interface Props {
    onClick(): void;
}

export const CancelButton: React.FC<Props> = ({ onClick }) => {
    return (
        <Button theme={ButtonTheme.GhostRoundedUpdated} onClick={onClick}>
            Отмена
        </Button>
    );
};
