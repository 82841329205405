import * as React from 'react';
import classNames from 'classnames';

import { Flex, FlexProps } from '@common/components';

import * as styles from './Button.scss';

export type ButtonViews = 'primary' | 'secondary' | 'danger' | 'link';

export type ButtonProps<E extends keyof JSX.IntrinsicElements = 'button'> = FlexProps<E> & {
    view?: ButtonViews;
    disabled?: boolean;
    handleDisabled?: boolean;
    loading?: boolean;
};

export function Button<E extends keyof JSX.IntrinsicElements = 'button'>({
    className,
    children,
    view = 'secondary',
    disabled,
    handleDisabled,
    loading,
    onClick,
    ...props
}: ButtonProps<E>) {
    disabled = disabled || loading;
    const rootClassName = classNames(
        styles.root,
        loading && styles.loading,
        styles[view],
        disabled && styles.disabled,
        className,
    );

    const handleClick: typeof onClick = (e: any) => {
        if (disabled) {
            e.preventDefault();
        } else {
            onClick?.(e);
        }
    };

    return (
        <Flex
            element="button"
            {...(props as any)}
            onClick={handleClick}
            disabled={loading || (disabled && !handleDisabled)}
            loading={loading}
            className={rootClassName}
        >
            {children}
        </Flex>
    );
}
