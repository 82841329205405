import * as React from 'react';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { CreativeRequestDomain } from '@store/creative';

import { DictionaryType } from './types';
import type {
    FieldType,
    Fields,
    Item,
    OnFieldChangeParams,
    Dictionary,
    CreativeRequestSubject,
    CreativeRequestAgency,
    CreativeRequestContractId,
    CreativeRequestContract,
} from './types';
import { MainInfoTemplate } from './MainInfoTemplate';
import { MrmClient } from '@api';
import { getUserName } from './utils';

interface Props {
    creativeRequestId: string;
}

interface State {
    selectedItemIds: Record<FieldType, string | string[]>;
    items: Record<FieldType, Item[]>;
}

export class MainInfoBehavior extends React.PureComponent<Props, State> {
    private lotDictionaries: Dictionary[] = [];
    private contracts: Record<CreativeRequestContractId, CreativeRequestContract> = null;
    private creativeRequestDomain: CreativeRequestDomain = null;

    constructor(props: Props) {
        super(props);

        this.state = {
            selectedItemIds: {
                lot: null,
                description: null,
                expert: null,
                customer: null,
                agencyExecutors: null,
                initiator: null,
                agencies: null,
                contract: null,
            },
            items: {
                lot: [],
                description: [],
                expert: [],
                customer: [],
                agencyExecutors: [],
                initiator: [],
                agencies: [],
                contract: [],
            },
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.loadCreativeRequestDomain();
        await this.loadData();
        await this.load();
        this.subscribe();

        this.autoselectContract();
    }

    public componentWillUnmount() {
        this.unsubscribe();
    }

    public render(): JSX.Element {
        return React.createElement(MainInfoTemplate, {
            fields: this.getFields(),
            onFieldChange: this.onFieldChange,
        });
    }

    @autobind
    private async onFieldChange({ type, value }: OnFieldChangeParams): Promise<void> {
        const { selectedItemIds, items } = this.state;

        switch (type) {
            case 'lot':
                await this.creativeRequestDomain.model.setLot({
                    lot: this.lotDictionaries.find((lot) => lot.id === value),
                });
                this.setState({
                    selectedItemIds: {
                        ...selectedItemIds,
                        lot: value,
                    },
                });
                break;
            case 'initiator':
                const idsToAdd = lodash.difference<string>(value, selectedItemIds.initiator);
                const idsToRemove = lodash.difference<string>(selectedItemIds.initiator, value);

                await Promise.all([
                    idsToAdd.map((id) => this.creativeRequestDomain.model.addInitiator({ userId: Number(id) })),
                    idsToRemove.map((id) => this.creativeRequestDomain.model.removeInitiator({ userId: Number(id) })),
                ]);

                this.setState({
                    selectedItemIds: {
                        ...selectedItemIds,
                        initiator: [
                            ...lodash.difference(selectedItemIds.initiator, idsToRemove),
                            ...lodash.difference(idsToAdd, selectedItemIds.initiator),
                        ],
                    },
                });
                break;
            case 'customer':
                this.setState({
                    selectedItemIds: {
                        ...selectedItemIds,
                        customer: value,
                    },
                });
                await this.creativeRequestDomain.model.setCustomer({ customer: value as string });
                break;
            case 'agencyExecutors':
                const agencyExecutorIdsToAdd = lodash.difference<string>(value, selectedItemIds.agencyExecutors);
                const agencyExecutorIdsToRemove = lodash.difference<string>(selectedItemIds.agencyExecutors, value);

                await Promise.all([
                    agencyExecutorIdsToAdd.map(
                        async (id) => await this.creativeRequestDomain.model.addAgencyExecutor({ userId: Number(id) }),
                    ),
                    agencyExecutorIdsToRemove.map(
                        async (id) =>
                            await this.creativeRequestDomain.model.removeAgencyExecutor({ userId: Number(id) }),
                    ),
                ]);

                this.setState({
                    selectedItemIds: {
                        ...selectedItemIds,
                        agencyExecutors: [
                            ...lodash.difference(selectedItemIds.agencyExecutors, agencyExecutorIdsToRemove),
                            ...lodash.difference(agencyExecutorIdsToAdd, selectedItemIds.agencyExecutors),
                        ],
                    },
                });
                break;
            case 'description':
                this.setState({
                    selectedItemIds: {
                        ...selectedItemIds,
                        description: value,
                    },
                });
                await this.creativeRequestDomain.model.setDescription({ description: value as string });
                break;
            case 'agencies':
                await this.creativeRequestDomain.model.setAgency({ agency: value as string });

                const [selectedContract, contractItems, selectedAgencyExecutors, agencyExecutorItems] =
                    await Promise.all([
                        this.creativeRequestDomain.model.contract,
                        this.creativeRequestDomain.model.findAvailableContracts(),
                        this.creativeRequestDomain.model.getAgencyExecutors(),
                        this.creativeRequestDomain.model.findAvailableAgencyExecutors(),
                    ]);

                this.setState({
                    selectedItemIds: {
                        ...selectedItemIds,
                        agencies: value,
                        agencyExecutors: selectedAgencyExecutors.map(({ id }) => id?.toString()) || null,
                        contract: selectedContract?.id || null,
                    },
                    items: {
                        ...items,
                        agencyExecutors: agencyExecutorItems.map(({ id, middleName, firstName, secondName }) => ({
                            value: id.toString(),
                            label: getUserName({ middleName, firstName, secondName }),
                        })),
                        contract: contractItems.map(({ id }) => ({
                            value: id.toString(),
                            label: this.contracts[id]?.model?.name || 'Имя не найдено',
                        })),
                    },
                });
                break;
            case 'contract':
                await this.creativeRequestDomain.model.setContract({ contractId: value as CreativeRequestContractId });

                this.setState({
                    selectedItemIds: {
                        ...selectedItemIds,
                        contract: value,
                    },
                });
                break;
        }
    }

    private getFields(): Fields {
        return {
            lot: {
                label: 'Лот',
                items: this.state.items.lot,
                value: this.state.selectedItemIds.lot,
                disabled: !this.creativeRequestDomain?.model?.setLot,
            },
            description: {
                label: 'Краткое описание',
                items: [],
                value: this.state.selectedItemIds.description,
                disabled: !this.creativeRequestDomain?.model?.setDescription,
            },
            expert: {
                label: 'Согласующий заявки на креатив',
                items: this.state.items.expert,
                value: this.state.selectedItemIds.expert,
                disabled: false,
            },
            customer: {
                label: 'Бизнес-заказчик',
                items: this.state.items.customer,
                value: this.state.selectedItemIds.customer,
                disabled: !this.creativeRequestDomain?.model?.setCustomer,
            },
            agencyExecutors: {
                label: 'Исполнитель от агентства',
                items: this.state.items.agencyExecutors,
                value: this.state.selectedItemIds.agencyExecutors,
                disabled:
                    !this.creativeRequestDomain?.model?.addAgencyExecutor ||
                    !this.creativeRequestDomain?.model?.removeAgencyExecutor,
            },
            initiator: {
                label: 'Инициатор заявки на креатив',
                items: this.state.items.initiator,
                value: this.state.selectedItemIds.initiator,
                disabled: !(
                    this.creativeRequestDomain?.model?.addInitiator &&
                    this.creativeRequestDomain?.model?.removeInitiator
                ),
            },
            agencies: {
                label: 'Агентство',
                items: this.state.items.agencies,
                value: this.state.selectedItemIds.agencies,
                disabled: !this.creativeRequestDomain?.model?.setAgency,
            },
            contract: {
                label: 'Контракт',
                items: this.state.items.contract,
                value: this.state.selectedItemIds.contract,
                disabled: !this.creativeRequestDomain?.model?.setContract,
            },
        };
    }

    private async load(): Promise<void> {
        const selectedCreativeRequestEntities = await this.requestSelectedCreativeRequestEntities();
        const availableCreativeRequestEntities = await this.requestAvailableCreativeRequestEntities();

        this.setState({
            selectedItemIds: {
                ...this.state.selectedItemIds,
                lot: selectedCreativeRequestEntities.lot?.id || null,
                initiator: selectedCreativeRequestEntities.initiators?.map(({ id }) => id.toString()) || null,
                agencyExecutors:
                    selectedCreativeRequestEntities.agencyExecutors.map(({ id }) => id?.toString()) || null,
                description: selectedCreativeRequestEntities.description || null,
                customer: selectedCreativeRequestEntities.customer || null,
                expert: selectedCreativeRequestEntities.experts.map(({ id }) => id.toString() || null),
                agencies: (selectedCreativeRequestEntities.agency as unknown as string) || null,
                contract: selectedCreativeRequestEntities.contractId || null,
            },
            items: {
                ...this.state.items,
                lot: this.lotDictionaries.map(({ value, id }) => ({
                    value: id,
                    label: value,
                    disabled: value === 'ЛОТ 2',
                })),
                initiator: lodash
                    .uniqBy(
                        availableCreativeRequestEntities.initiators.concat(selectedCreativeRequestEntities.initiators),
                        'id',
                    )
                    .map(({ id, middleName, firstName, secondName }) => ({
                        value: id.toString(),
                        label: getUserName({ middleName, firstName, secondName }),
                    })),
                agencyExecutors: availableCreativeRequestEntities.agencyExecutors.map(
                    ({ id, middleName, firstName, secondName }) => ({
                        value: id.toString(),
                        label: getUserName({ middleName, firstName, secondName }),
                    }),
                ),
                expert: availableCreativeRequestEntities.experts.map(({ id, middleName, firstName, secondName }) => ({
                    value: id.toString(),
                    label: getUserName({ middleName, firstName, secondName }),
                })),
                agencies: availableCreativeRequestEntities.agencies.map(({ id, name }) => ({
                    value: id.toString(),
                    label: name,
                })),
                contract: availableCreativeRequestEntities.contracts.map(({ id }) => ({
                    value: id.toString(),
                    label: this.contracts[id]?.model?.name || 'Имя не найдено',
                })),
            },
        });
    }

    private async loadCreativeRequestDomain(): Promise<void> {
        const { creativeRequestId } = this.props;
        const client = await MrmClient.getInstance();
        this.creativeRequestDomain = await client.domain.creativeRequests.getCreativeRequest({ id: creativeRequestId });
    }

    private async loadData(): Promise<void> {
        const [lotDictionaries, contracts] = await Promise.all([
            this.requestLotDictionaries(),
            this.requestContracts(),
        ]);

        this.lotDictionaries = lotDictionaries;
        this.contracts = lodash.keyBy(contracts, 'model.id');
    }

    private async requestLotDictionaries(): Promise<Dictionary[]> {
        try {
            const client = await MrmClient.getInstance();
            return await client.Dictionary.getByType(DictionaryType.Lot);
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    private async requestContracts(): Promise<CreativeRequestContract[]> {
        try {
            const client = await MrmClient.getInstance();
            return await client.domain.creativeRequests.getContracts();
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    private subscribe(): void {
        this.creativeRequestDomain?.events.onReloaded(this.onReloadedHandler);
    }

    private unsubscribe(): void {
        this.creativeRequestDomain?.events.offReloaded(this.onReloadedHandler);
    }

    @autobind
    private async onReloadedHandler(): Promise<void> {
        this.unsubscribe();
        await this.loadCreativeRequestDomain();
        await this.load();
        this.subscribe();
    }

    private async requestSelectedCreativeRequestEntities(): Promise<{
        initiators: CreativeRequestSubject[];
        agencyExecutors: CreativeRequestSubject[];
        experts: CreativeRequestSubject[];
        description: string;
        customer: string;
        lot: Dictionary;
        agency: CreativeRequestAgency;
        contractId: CreativeRequestContractId;
    }> {
        try {
            const [initiators, agencyExecutors, experts, description, customer, lot, agency, contractId] =
                await Promise.all([
                    this.creativeRequestDomain.model.getInitiators(),
                    this.creativeRequestDomain.model.getAgencyExecutors(),
                    this.creativeRequestDomain.model.findAvailableExperts(),
                    this.creativeRequestDomain.model.description,
                    this.creativeRequestDomain.model.customer,
                    this.creativeRequestDomain.model.lot,
                    this.creativeRequestDomain.model.agency,
                    this.creativeRequestDomain.model.contractId,
                ]);

            return {
                initiators,
                agencyExecutors,
                experts,
                description,
                customer,
                lot,
                agency,
                contractId,
            };
        } catch (error) {
            console.error(error);
            return {
                initiators: null,
                agencyExecutors: null,
                experts: null,
                description: null,
                customer: null,
                lot: null,
                agency: null,
                contractId: null,
            };
        }
    }

    private async requestAvailableCreativeRequestEntities(): Promise<{
        initiators: CreativeRequestSubject[];
        agencyExecutors: CreativeRequestSubject[];
        experts: CreativeRequestSubject[];
        agencies: CreativeRequestAgency[];
        contracts: {
            id: CreativeRequestContractId;
        }[];
    }> {
        try {
            const [initiators, agencyExecutors, experts, agencies, contracts] = await Promise.all([
                this.creativeRequestDomain.model?.findAvailableInitiators() || [],
                this.creativeRequestDomain.model?.findAvailableAgencyExecutors(),
                this.creativeRequestDomain.model?.findAvailableExperts(),
                this.creativeRequestDomain.model?.findAvailableAgencies(),
                this.creativeRequestDomain.model?.findAvailableContracts(),
            ]);
            return {
                initiators,
                agencyExecutors,
                experts,
                agencies: agencies as undefined as CreativeRequestAgency[],
                contracts,
            };
        } catch (error) {
            console.error(error);
            return {
                initiators: null,
                agencyExecutors: null,
                experts: null,
                agencies: null,
                contracts: null,
            };
        }
    }

    private autoselectContract() {
        const contractId: CreativeRequestContractId = (this.creativeRequestDomain.model as any).contractId;
        const contractItems = this.state.items.contract;

        if (!contractId && contractItems.length === 1) {
            const newContractId = lodash.first(contractItems).value as CreativeRequestContractId;

            this.onFieldChange({ type: 'contract', value: newContractId });
        }
    }
}
