import * as React from 'react';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';

import { StoreState } from '@store';
import { makeActivityBudgetParams, getExecutionBudgetEditPageState } from '@store/executionBudgetEdit/selectors';

interface ChildrenProps {
    nameInputValue: string;
    isNameInputFocused: boolean;
}

interface Props extends Partial<MapProps> {
    children: (props: ChildrenProps) => JSX.Element;
}

interface MapProps {
    nameInputValue: string;
    isNameInputFocused: boolean;
}

interface State {
    nameInputValueForQuery: string;
}

const NAME_CHANGE_DEBOUNCE_VALUE = 300;

@(connect(mapStateToProps, null) as any)
export class ActivitySuggestWithStore extends React.PureComponent<Props, State> {
    private stateUpdateTimeout: NodeJS.Timeout;

    public constructor(props: Props) {
        super(props);

        this.stateUpdateTimeout = null;

        this.state = {
            nameInputValueForQuery: props.nameInputValue,
        };
    }

    public componentDidUpdate() {
        if (this.state.nameInputValueForQuery !== this.props.nameInputValue) {
            this.initStateUpdateTimeout();
        }
    }

    public render(): JSX.Element {
        const { children, isNameInputFocused } = this.props;
        const { nameInputValueForQuery } = this.state;

        return children({
            nameInputValue: nameInputValueForQuery,
            isNameInputFocused,
        });
    }

    private initStateUpdateTimeout(): void {
        if (this.stateUpdateTimeout) {
            clearTimeout(this.stateUpdateTimeout);
        }

        this.stateUpdateTimeout = setTimeout(this.updateState, NAME_CHANGE_DEBOUNCE_VALUE);
    }

    @autobind
    private updateState(): void {
        this.stateUpdateTimeout = null;

        this.setState({
            nameInputValueForQuery: this.props.nameInputValue,
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { name } = makeActivityBudgetParams(state);
    const { isNameInputFocused } = getExecutionBudgetEditPageState(state);

    return {
        nameInputValue: name,
        isNameInputFocused,
    };
}
