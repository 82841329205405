import * as React from 'react';
import * as lodash from 'lodash';
import { Skeleton } from 'sber-marketing-ui';

import * as style from './BudgetInfo.scss';

import { Correction, CardBudgetItem } from './types';

import { Params, FieldName } from './Params';
import { Corrections } from './Corrections';

interface Props {
    hasBudgetItemAccess: boolean;
    loading: boolean;
    hoveredCorrectionCardId: string;
    paramsFields: Record<FieldName, InfoBlockParams>;
    budgetItem: CardBudgetItem;
    corrections: Correction[];
    onHoverCorrectionCard: (correctionId: string) => void;
    updateCorrections: () => Promise<void>;
    updateCard: () => Promise<void>;
    refetchData: () => Promise<void>;
}

export interface InfoBlockParams {
    title: string;
    content: string;
    warning?: boolean;
    link?: string;
}

export const BudgetInfo: React.FC<Props> = ({
    hasBudgetItemAccess,
    loading,
    paramsFields,
    budgetItem,
    corrections,
    hoveredCorrectionCardId,
    onHoverCorrectionCard,
    updateCorrections,
    updateCard,
    refetchData,
}) => {
    const haveBudgetIdOfBudgetItem = Boolean(budgetItem && budgetItem.budget && budgetItem.budget.id);

    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'dashboardRightSidebarBudgetInfo',
            }}
        >
            {!loading && (
                <>
                    <div className={style.title}>Бюджет</div>

                    <Params
                        canEditBudgetItem={hasBudgetItemAccess}
                        fields={paramsFields}
                        budgetItem={budgetItem}
                        corrections={corrections}
                        hoveredCorrectionCardId={hoveredCorrectionCardId}
                        updateCorrections={updateCorrections}
                        updateCard={updateCard}
                    />

                    {!lodash.isEmpty(corrections) && haveBudgetIdOfBudgetItem && (
                        <div className={style.correctionsWrapper}>
                            <Corrections
                                budgetId={budgetItem.budget.id}
                                corrections={corrections}
                                onHoverCorrectionCard={onHoverCorrectionCard}
                                onChangeCorrections={updateCorrections}
                                refetchBudgetData={refetchData}
                            />
                        </div>
                    )}
                </>
            )}

            {loading && <BudgetInfoPreloader />}
        </div>
    );
};

/* tslint:disable:max-line-length */
function BudgetInfoPreloader(): JSX.Element {
    return (
        <div className={style.preloader}>
            <Skeleton customStyle={{ height: 20, width: '12%', borderRadius: 2, marginBottom: 10 }} />

            <Skeleton customStyle={{ height: 16, width: '5%', borderRadius: 2, marginBottom: 4 }} />
            <Skeleton customStyle={{ height: 13, width: '12%', borderRadius: 2, marginBottom: 16 }} />

            <Skeleton customStyle={{ height: 16, width: '56%', borderRadius: 2, marginBottom: 4 }} />
            <Skeleton customStyle={{ height: 13, width: '32%', borderRadius: 2, marginBottom: 16 }} />
            <Skeleton
                customStyle={{ height: 16, width: '20%', borderRadius: 2, position: 'absolute', top: 78, left: '80%' }}
            />

            <Skeleton customStyle={{ height: 16, width: '20%', borderRadius: 2, marginBottom: 4 }} />
            <Skeleton customStyle={{ height: 12, width: '24%', borderRadius: 2, marginBottom: 16 }} />

            <Skeleton customStyle={{ height: 16, width: '24%', borderRadius: 2, marginBottom: 4 }} />
            <Skeleton customStyle={{ height: 13, width: '40%', borderRadius: 2, marginBottom: 16 }} />

            <Skeleton customStyle={{ height: 16, width: '14%', borderRadius: 2, marginBottom: 4 }} />
            <Skeleton customStyle={{ height: 13, width: '44%', borderRadius: 2, marginBottom: 16 }} />

            <Skeleton customStyle={{ height: 16, width: '8%', borderRadius: 2, marginBottom: 4 }} />
            <Skeleton customStyle={{ height: 13, width: '29%', borderRadius: 2, marginBottom: 16 }} />

            <Skeleton customStyle={{ height: 16, width: '8%', borderRadius: 2, marginBottom: 4 }} />
            <Skeleton customStyle={{ height: 13, width: '16%', borderRadius: 2, marginBottom: 16 }} />
            <Skeleton
                customStyle={{ height: 16, width: '8%', borderRadius: 2, position: 'absolute', top: 323, left: '26%' }}
            />
            <Skeleton
                customStyle={{ height: 13, width: '16%', borderRadius: 2, position: 'absolute', top: 342, left: '26%' }}
            />
            <Skeleton
                customStyle={{ height: 16, width: '8%', borderRadius: 2, position: 'absolute', top: 323, left: '52%' }}
            />
            <Skeleton
                customStyle={{ height: 13, width: '16%', borderRadius: 2, position: 'absolute', top: 342, left: '52%' }}
            />
            <Skeleton
                customStyle={{ height: 32, width: '20%', borderRadius: 2, position: 'absolute', top: 323, left: '80%' }}
            />

            <Skeleton customStyle={{ height: 16, width: '58%', borderRadius: 2 }} />

            <div className={style.preloaderCorrection} style={{ top: 395 }}>
                <Skeleton
                    customStyle={{ height: 20, width: '32%', borderRadius: 2, position: 'absolute', top: 5, left: 16 }}
                />
                <Skeleton
                    customStyle={{ height: 16, width: '64%', borderRadius: 2, position: 'absolute', top: 29, left: 16 }}
                />
            </div>

            <div className={style.preloaderCorrection} style={{ top: 458 }}>
                <Skeleton
                    customStyle={{ height: 20, width: '32%', borderRadius: 2, position: 'absolute', top: 5, left: 16 }}
                />
                <Skeleton
                    customStyle={{ height: 16, width: '64%', borderRadius: 2, position: 'absolute', top: 29, left: 16 }}
                />
            </div>
        </div>
    );
}
/* tslint:enable:max-line-length */
