// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-FlagToggle-_FlagToggle__root{transition:color 0.2s ease-in-out}.src-client-modules-common-FlagToggle-_FlagToggle__root.src-client-modules-common-FlagToggle-_FlagToggle__noSelected{color:#7e8681}.src-client-modules-common-FlagToggle-_FlagToggle__root.src-client-modules-common-FlagToggle-_FlagToggle__selected{color:#f5a623}.src-client-modules-common-FlagToggle-_FlagToggle__root.src-client-modules-common-FlagToggle-_FlagToggle__change{cursor:pointer}.src-client-modules-common-FlagToggle-_FlagToggle__root.src-client-modules-common-FlagToggle-_FlagToggle__change.src-client-modules-common-FlagToggle-_FlagToggle__noSelected:hover,.src-client-modules-common-FlagToggle-_FlagToggle__root.src-client-modules-common-FlagToggle-_FlagToggle__change.src-client-modules-common-FlagToggle-_FlagToggle__noSelected.src-client-modules-common-FlagToggle-_FlagToggle__hover,.src-client-modules-common-FlagToggle-_FlagToggle__root.src-client-modules-common-FlagToggle-_FlagToggle__change.src-client-modules-common-FlagToggle-_FlagToggle__selected:hover,.src-client-modules-common-FlagToggle-_FlagToggle__root.src-client-modules-common-FlagToggle-_FlagToggle__change.src-client-modules-common-FlagToggle-_FlagToggle__selected.src-client-modules-common-FlagToggle-_FlagToggle__hover{color:#19bb4f}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/FlagToggle/FlagToggle.scss"],"names":[],"mappings":"AAIA,wDACI,iCAAkC,CADtC,qHAIQ,aAPM,CAGd,mHAQQ,aAVQ,CAEhB,iHAYQ,cAAe,CAZvB,gzBAgBgB,aApBD","sourcesContent":["$green: #19bb4f;\n$grey: #7e8681;\n$yellow: #f5a623;\n\n.root {\n    transition: color 0.2s ease-in-out;\n\n    &.noSelected {\n        color: $grey;\n    }\n\n    &.selected {\n        color: $yellow;\n    }\n\n    &.change {\n        cursor: pointer;\n\n        &.noSelected, &.selected {\n            &:hover, &.hover {\n                color: $green;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-FlagToggle-_FlagToggle__root",
	"noSelected": "src-client-modules-common-FlagToggle-_FlagToggle__noSelected",
	"selected": "src-client-modules-common-FlagToggle-_FlagToggle__selected",
	"change": "src-client-modules-common-FlagToggle-_FlagToggle__change",
	"hover": "src-client-modules-common-FlagToggle-_FlagToggle__hover"
};
export default ___CSS_LOADER_EXPORT___;
