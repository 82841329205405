import { bindActionCreators } from 'redux';
import * as lodash from 'lodash';

import type { Brief } from 'sber-marketing-types/frontend';

import { store } from '@store';
import { setBrief, setCurrentBrief } from '@store/brief/actions';
import { add as addFiles } from '@store/fileAssets';
import { ActivityApi, TaskApi, FileApi } from '@api';

interface ConstructorParams {
    activityId: number;
    taskId: string;
}

export class CommonBriefLoader {
    private activityId: number;
    private taskId: string;

    private brief: Brief;

    private dispatch = bindActionCreators(
        {
            setBrief,
            setCurrentBrief,
            addFiles,
        },
        store.dispatch,
    );

    constructor({ activityId, taskId }: ConstructorParams) {
        this.activityId = activityId;
        this.taskId = taskId;
    }

    public async updateBrief(): Promise<void> {
        if (this.taskId) {
            await this.loadTaskBrief();
        } else {
            await this.loadActivityBrief();
        }

        this.dispatch.setBrief(this.brief);
    }

    public pasteCurrentBrief(copiedBrief: Brief, currentBrief: Brief): void {
        const formattedBrief = this.formatFilesData(copiedBrief);
        this.dispatch.setCurrentBrief({
            ...currentBrief,
            blocks: formattedBrief.blocks,
        });
    }

    private async loadActivityBrief() {
        const brief = await ActivityApi.getActivityBrief(this.activityId);
        this.brief = this.formatFilesData(brief);
    }

    private async loadTaskBrief() {
        const brief = await TaskApi.getTaskBrief(this.taskId);
        this.brief = this.formatFilesData(brief);
    }

    private formatFilesData(brief: Brief) {
        const updatedBrief = lodash.cloneDeep(brief);

        updatedBrief.blocks.forEach((block) =>
            block.fields.forEach((field) => {
                if (field.value && field.value.files) {
                    const files = FileApi.mapFiles(
                        {
                            activityId: String(this.activityId),
                            fieldId: field.id,
                        },
                        field.value.files as any[],
                        '',
                    ) as any[];
                    field.value.files = files;
                    this.dispatch.addFiles(...files);
                }
            }),
        );

        return updatedBrief;
    }
}
