import * as React from 'react';

import { BudgetStageWidget } from './BudgetStageWidget';
import type { CardProps } from './BudgetStageWidget';

import * as style from './BudgetId.scss';

interface Props {
    loading: boolean;
    visibleAddButton: boolean;
    cards: CardProps[];
    onAddButtonClick: () => void;
}

export const BudgetIdTemplate = ({ cards, loading, visibleAddButton, onAddButtonClick }: Props): JSX.Element => {
    return cards.length || visibleAddButton ? (
        <div className={style.root}>
            <div className={style.title}>ID источника</div>

            <div className={style.items}>
                <BudgetStageWidget
                    cards={cards}
                    loading={loading}
                    visibleAddButton={visibleAddButton}
                    onAddButtonClick={onAddButtonClick}
                />
            </div>
        </div>
    ) : null;
};

export type { CardProps };
