import { Success } from 'typescript-fsa';
import { SaveCommentaryPayload, TaskPageState, Commentary } from '../types';

export const saveCommentaryDoneReducer = (
    { comments, ...restState }: TaskPageState,
    { result }: Success<SaveCommentaryPayload, Commentary>,
): TaskPageState => {
    comments[result.id] = result;
    return { ...restState, comments };
};
