import * as ExcelJS from 'exceljs';
import { DictionaryType } from '@mrm/dictionary';
import { Month } from '@mrm/budget';
import { compact, compose, map, values } from 'lodash/fp';
import { ColumnName } from 'sber-marketing-ui';

export const EXCEL_FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const DATA_SHEET_TITLE = 'Строки бюджета Планирование';
export const ROWS_COUNT = 1000;
export const DEFAULT_CELL_WIDTH = 18;

export interface SumManyFormulaOperator {
    _type: 'SumMany';
    args: CellFormula[];
}

export interface SumRangeFormulaOperator {
    _type: 'SumRange';
    from: ColumnNames;
    to: ColumnNames;
}

export interface CellValueFormulaOperator {
    _type: 'CellValue';
    cell: ColumnName;
}

export type CellFormula = SumManyFormulaOperator | SumRangeFormulaOperator | CellValueFormulaOperator;

// cannot use BudgetPlanning.ColumnName directly because typescript permits to mix computed values with strings in enums
// (mix between BudgetPlanning.ColumnName and custom value is possible in case of columns missmatch)
export enum ColumnNames {
    ActivityName = 'ActivityName',
    Regionality = 'Regionality',
    ActivityType = 'ActivityType',
    Direction = 'Direction',
    SapComment = 'SapComment',
    Tool = 'Tool',
    CostCenter = 'CostCenter',
    Block = 'Block',
    Division = 'Division',
    Item = 'Item',
    Resource = 'Resource',
    Territory = 'Territory',
    LocationDriver = 'LocationDriver',
    Segment = 'Segment',
    Product = 'Product',
    Channel = 'Channel',
    BusinessGoal = 'BusinessGoal',
    Customer = 'Customer',
    StartDate = 'StartDate',
    EndDate = 'EndDate',
    Comment = 'Comment',
    Responsible = 'Responsible',
    PlanJan = 'PlanJan',
    PlanFeb = 'PlanFeb',
    PlanMar = 'PlanMar',
    PlanApr = 'PlanApr',
    PlanMay = 'PlanMay',
    PlanJun = 'PlanJun',
    PlanJul = 'PlanJul',
    PlanAug = 'PlanAug',
    PlanSep = 'PlanSep',
    PlanOct = 'PlanOct',
    PlanNov = 'PlanNov',
    PlanDec = 'PlanDec',
    FactPreviousPeriod = 'FactPreviousPeriod',
    PlanSum = 'PlanSum',
}

export interface ColumnProps {
    header: string;
    key: ColumnNames;
    isRequired: boolean;
    markAsRequired?: boolean;
    linkedValues?: LinkedValues;
    linkedDictionary?: DictionaryType;
    customDataType?: ExcelJS.ValueType;
    formula?: CellFormula;
    ignoreForImport?: boolean;
}

type LinkedValues = 'users' | DictionaryType;

export const ColumnParams: Record<ColumnNames, ColumnProps> = {
    [ColumnNames.ActivityName]: {
        header: 'Активность',
        key: ColumnNames.ActivityName,
        isRequired: true,
    },
    [ColumnNames.Regionality]: {
        header: 'ЦА/ТБ (территория)',
        key: ColumnNames.Regionality,
        isRequired: true,
        linkedValues: DictionaryType.Regionality,
        linkedDictionary: DictionaryType.Regionality,
    },
    [ColumnNames.ActivityType]: {
        header: 'Тип проекта',
        key: ColumnNames.ActivityType,
        isRequired: true,
        linkedValues: DictionaryType.ActivityType,
        linkedDictionary: DictionaryType.ActivityType,
    },
    [ColumnNames.Direction]: {
        header: 'Направление',
        key: ColumnNames.Direction,
        isRequired: true,
        linkedValues: DictionaryType.Direction,
        linkedDictionary: DictionaryType.Direction,
    },
    [ColumnNames.SapComment]: {
        header: 'Название проекта',
        key: ColumnNames.SapComment,
        isRequired: true,
    },
    [ColumnNames.Tool]: {
        header: 'Инструмент',
        key: ColumnNames.Tool,
        isRequired: true,
        linkedValues: DictionaryType.Tool,
        linkedDictionary: DictionaryType.Tool,
    },
    [ColumnNames.Block]: {
        header: 'Блок',
        key: ColumnNames.Block,
        isRequired: true,
        linkedValues: DictionaryType.Block,
        linkedDictionary: DictionaryType.Block,
    },
    [ColumnNames.CostCenter]: {
        header: 'ЦЗ',
        key: ColumnNames.CostCenter,
        isRequired: false,
        linkedValues: DictionaryType.CostCenter,
        linkedDictionary: DictionaryType.CostCenter,
    },
    [ColumnNames.Division]: {
        header: 'Дивизион',
        key: ColumnNames.Division,
        isRequired: true,
        linkedValues: DictionaryType.Division,
        linkedDictionary: DictionaryType.Division,
    },
    [ColumnNames.Item]: {
        header: 'Статья',
        key: ColumnNames.Item,
        isRequired: true,
        linkedValues: DictionaryType.Item,
        linkedDictionary: DictionaryType.Item,
    },
    [ColumnNames.Resource]: {
        header: 'Ресурс',
        key: ColumnNames.Resource,
        isRequired: false,
        linkedValues: DictionaryType.Resource,
        linkedDictionary: DictionaryType.Resource,
    },
    [ColumnNames.Territory]: {
        header: 'Территория',
        key: ColumnNames.Territory,
        isRequired: false,
        linkedValues: DictionaryType.Territory,
        linkedDictionary: DictionaryType.Territory,
    },
    [ColumnNames.LocationDriver]: {
        header: 'Драйвер аллокации (Бизнес-блок)',
        key: ColumnNames.LocationDriver,
        isRequired: false,
        linkedValues: DictionaryType.LocationDriver,
        linkedDictionary: DictionaryType.LocationDriver,
    },
    [ColumnNames.Segment]: {
        header: 'Сегмент',
        key: ColumnNames.Segment,
        isRequired: true,
        linkedValues: DictionaryType.Segment,
        linkedDictionary: DictionaryType.Segment,
    },
    [ColumnNames.Product]: {
        header: 'Продукт',
        key: ColumnNames.Product,
        isRequired: true,
        linkedValues: DictionaryType.Product,
        linkedDictionary: DictionaryType.Product,
    },
    [ColumnNames.Channel]: {
        header: 'Канал',
        key: ColumnNames.Channel,
        isRequired: false,
        linkedValues: DictionaryType.Channel,
        linkedDictionary: DictionaryType.Channel,
    },
    [ColumnNames.Responsible]: {
        header: 'Руководители проекта (ответственные от ДМиК/ДУД)',
        key: ColumnNames.Responsible,
        linkedValues: 'users',
        isRequired: false,
    },
    [ColumnNames.BusinessGoal]: {
        header: 'Бизнес-цель',
        key: ColumnNames.BusinessGoal,
        isRequired: true,
    },
    [ColumnNames.Customer]: {
        header: 'ФИО заказчика',
        key: ColumnNames.Customer,
        isRequired: true,
    },
    [ColumnNames.StartDate]: {
        header: 'Дата старта',
        key: ColumnNames.StartDate,
        isRequired: true,
        customDataType: ExcelJS.ValueType.Date,
    },
    [ColumnNames.EndDate]: {
        header: 'Дата окончания',
        key: ColumnNames.EndDate,
        isRequired: true,
        customDataType: ExcelJS.ValueType.Date,
    },
    [ColumnNames.Comment]: {
        header: 'Примечание к строке',
        key: ColumnNames.Comment,
        isRequired: false,
    },
    [ColumnNames.PlanJan]: {
        header: 'Январь, сумма без НДС, руб',
        key: ColumnNames.PlanJan,
        isRequired: false,
        markAsRequired: true,
        customDataType: ExcelJS.ValueType.Number,
    },
    [ColumnNames.PlanFeb]: {
        header: 'Февраль, сумма без НДС, руб',
        key: ColumnNames.PlanFeb,
        isRequired: false,
        markAsRequired: true,
        customDataType: ExcelJS.ValueType.Number,
    },
    [ColumnNames.PlanMar]: {
        header: 'Март, сумма без НДС, руб',
        key: ColumnNames.PlanMar,
        isRequired: false,
        markAsRequired: true,
        customDataType: ExcelJS.ValueType.Number,
    },
    [ColumnNames.PlanApr]: {
        header: 'Апрель, сумма без НДС, руб',
        key: ColumnNames.PlanApr,
        isRequired: false,
        markAsRequired: true,
        customDataType: ExcelJS.ValueType.Number,
    },
    [ColumnNames.PlanMay]: {
        header: 'Май, сумма без НДС, руб',
        key: ColumnNames.PlanMay,
        isRequired: false,
        markAsRequired: true,
        customDataType: ExcelJS.ValueType.Number,
    },
    [ColumnNames.PlanJun]: {
        header: 'Июнь, сумма без НДС, руб',
        key: ColumnNames.PlanJun,
        isRequired: false,
        markAsRequired: true,
        customDataType: ExcelJS.ValueType.Number,
    },
    [ColumnNames.PlanJul]: {
        header: 'Июль, сумма без НДС, руб',
        key: ColumnNames.PlanJul,
        isRequired: false,
        markAsRequired: true,
        customDataType: ExcelJS.ValueType.Number,
    },
    [ColumnNames.PlanAug]: {
        header: 'Август, сумма без НДС, руб',
        key: ColumnNames.PlanAug,
        isRequired: false,
        markAsRequired: true,
        customDataType: ExcelJS.ValueType.Number,
    },
    [ColumnNames.PlanSep]: {
        header: 'Сентябрь, сумма без НДС, руб',
        key: ColumnNames.PlanSep,
        isRequired: false,
        markAsRequired: true,
        customDataType: ExcelJS.ValueType.Number,
    },
    [ColumnNames.PlanOct]: {
        header: 'Октябрь, сумма без НДС, руб',
        key: ColumnNames.PlanOct,
        isRequired: false,
        markAsRequired: true,
        customDataType: ExcelJS.ValueType.Number,
    },
    [ColumnNames.PlanNov]: {
        header: 'Ноябрь, сумма без НДС, руб',
        key: ColumnNames.PlanNov,
        isRequired: false,
        markAsRequired: true,
        customDataType: ExcelJS.ValueType.Number,
    },
    [ColumnNames.PlanDec]: {
        header: 'Декабрь, сумма без НДС, руб',
        key: ColumnNames.PlanDec,
        isRequired: false,
        markAsRequired: true,
        customDataType: ExcelJS.ValueType.Number,
    },
    [ColumnNames.PlanSum]: {
        header: `Итого план, руб`,
        key: ColumnNames.PlanSum,
        isRequired: false,
        markAsRequired: false,
        customDataType: ExcelJS.ValueType.Formula,
        formula: {
            _type: 'SumRange',
            from: ColumnNames.PlanJan,
            to: ColumnNames.PlanDec,
        },
        ignoreForImport: true,
    },
    [ColumnNames.FactPreviousPeriod]: {
        header: `Факт предыдущего периода, руб`,
        key: ColumnNames.FactPreviousPeriod,
        isRequired: false,
        markAsRequired: false,
        customDataType: ExcelJS.ValueType.Number,
    },
};
export const UsedDictionaryTypes = new Set(
    compose(
        compact,
        map<ColumnProps, DictionaryType>((columnProps) => columnProps.linkedDictionary),
        values,
    )(ColumnParams),
);

export const MonthColumns = [
    ColumnNames.PlanJan,
    ColumnNames.PlanFeb,
    ColumnNames.PlanMar,
    ColumnNames.PlanApr,
    ColumnNames.PlanMay,
    ColumnNames.PlanJun,
    ColumnNames.PlanJul,
    ColumnNames.PlanAug,
    ColumnNames.PlanSep,
    ColumnNames.PlanOct,
    ColumnNames.PlanNov,
    ColumnNames.PlanDec,
];

export const ColumnToMonthMap: Partial<Record<ColumnNames, Month>> = {
    [ColumnNames.PlanJan]: Month.Jan,
    [ColumnNames.PlanFeb]: Month.Feb,
    [ColumnNames.PlanMar]: Month.Mar,
    [ColumnNames.PlanApr]: Month.Apr,
    [ColumnNames.PlanMay]: Month.May,
    [ColumnNames.PlanJun]: Month.Jun,
    [ColumnNames.PlanJul]: Month.Jul,
    [ColumnNames.PlanAug]: Month.Aug,
    [ColumnNames.PlanSep]: Month.Sept,
    [ColumnNames.PlanOct]: Month.Oct,
    [ColumnNames.PlanNov]: Month.Nov,
    [ColumnNames.PlanDec]: Month.Dec,
};

export const Columns = Object.values(ColumnParams);

export const ColumnPriorities: Partial<Record<ColumnNames, ColumnNames[]>> = {
    [ColumnNames.Regionality]: [ColumnNames.Regionality, ColumnNames.Block],
    [ColumnNames.Block]: [ColumnNames.Regionality, ColumnNames.Block],
};
