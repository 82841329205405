import * as lodash from 'lodash';

import { BudgetItemStatus } from '@mrm/budget';
import { ColumnData, TableLine, ChangeList, ColumnName, ColumnsWithSameData } from '@store/budgetPlanning/types';
import { activityBudgetRequiredFields, budgetItemRequiredFields } from '@store/plannedBudgetEdit/types';
import { DropdownCellOptions } from '../../CellTypes';

import { CellParams, CellBackgroundColor } from '../LayerManager';
import { getFieldValue } from './Utils';

interface Props {
    line: TableLine;
    column: ColumnData;
    unsavedChanges: ChangeList;
    dropdownsOptions: { [columnName: string]: DropdownCellOptions[] };
    validationStatus: boolean;
}

export class DropdownCellParamsCreator {
    private line: TableLine;
    private column: ColumnData;
    private unsavedChanges: ChangeList;
    private dropdownsOptions: { [columnName: string]: DropdownCellOptions[] };
    private validationStatus: boolean;

    constructor(props: Props) {
        this.line = props.line;
        this.column = props.column;
        this.unsavedChanges = props.unsavedChanges;
        this.dropdownsOptions = props.dropdownsOptions;
        this.validationStatus = props.validationStatus;
    }

    public makeCellParams(): CellParams {
        let title: string = null;
        let value: string;
        let color: CellBackgroundColor;

        const unsavedValue = this.getUnsavedValue(this.column);
        const baseValue = this.getBaseValue(this.column);

        if (unsavedValue !== undefined) {
            value = unsavedValue;
            color = CellBackgroundColor.UnsavedChange;
        } else {
            value = baseValue;
        }

        const options = this.dropdownsOptions[this.column.name];

        const lineIsDraft = this.line.status == BudgetItemStatus.Draft;
        const lineIsDisabled = !this.line.canEdit || this.line.status == BudgetItemStatus.Disabled;

        return {
            title,
            options,
            value,
            originalValue: baseValue,
            bgColor: !lineIsDraft && color,
            displayValidationError: this.cellIsInvalid(value),
            disabled: lineIsDisabled,
            allowMultipleItems: this.column.name === ColumnName.Responsible,
        };
    }

    private getUnsavedValue(column: ColumnData): string {
        const changes = this.unsavedChanges[this.line.id] || [];

        const unsavedChange = lodash.last(
            changes.filter((item) => {
                const isBudgetItemMatched = item.budgetItemId === this.line.id;

                const columnsToChange: ColumnName[] = ColumnsWithSameData[item.columnName] || [item.columnName];
                const isColumnMatched = columnsToChange.some((columnName) => columnName === column.name);

                return isBudgetItemMatched && isColumnMatched;
            }),
        );

        return unsavedChange ? (unsavedChange.value as string) : undefined;
    }

    private getBaseValue(column: ColumnData): string {
        return getFieldValue(this.line.fields[column.name], column.valueType) as string;
    }

    private cellIsInvalid(value: string) {
        return (
            [...activityBudgetRequiredFields, ...budgetItemRequiredFields].includes(this.column.name) &&
            this.validationStatus &&
            !value
        );
    }
}
