import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { v4 as uuidV4 } from 'uuid';

import { TaskPageState, TaskLoadingStatus } from '../types';
import { asyncActions, syncActions } from '../actions';
import { resetTaskPageReducer } from './resetTaskPageReducer';
import { setAuthorIdReducer } from './setAuthorIdReducer';
import { editTaskReducer } from './editTaskReducer';
import { setIsUserSubscribed } from './setIsUserSubscribedReducer';
import { editActivityKeyStatus } from './editActivityKeyStatus';
import {
    addCommentaryStartedReducer,
    addCommentaryDoneReducer,
    resetNewCommentaryReducer,
    setSendingCommentReducer,
    setSavingCommentReducer,
} from './addCommentaryReducers';
import { switchKeyActivityStartedReducer, switchKeyActivityFailedReducer } from './switchKeyActivityReducers';
import { saveCommentaryDoneReducer } from './saveCommentaryReducers';
import { loadTaskStartedReducer, loadTaskDoneReducer, loadTaskErrorReducer } from './loadTaskReducers';
import { updateTelegramStartedReducer, updateTelegramDoneReducer, updateTelegramErrorReducer } from './updateTelegram';
import { plugTelegramStartedReducer, plugTelegramDoneReducer, plugTelegramErrorReducer } from './plugTelegramReducer';
import {
    unplugTelegramStartedReducer,
    unplugTelegramDoneReducer,
    unplugTelegramErrorReducer,
} from './unplugTelegramReducer';
import { resetTelegramFetchErrorReducer } from './resetTelegramFetchErrorReducer';
import { setRateStartedReducer, setRateDoneReducer, setRateErrorReducer } from './setRateReducer';
import {
    removeCommentaryStartedReducer,
    removeCommentaryDoneReducer,
    removeCommentaryErrorReducer,
} from './removeCommentaryReducers';
import {
    addFileToTaskDoneReducer,
    addFileToTaskErrorReducer,
    addFileToTaskStartedReducer,
} from './addFileToTaskReducers';
import {
    addFileToCommentaryDoneReducer,
    addFileToCommentaryErrorReducer,
    addFileToCommentaryStartedReducer,
} from './addFileToCommentaryReducers';
import {
    removeFileFromTaskErrorReducer,
    removeFileFromTaskStartedReducer,
    removeFileFromTaskSuccessReducer,
} from './removeFileFromTaskReducers';
import {
    removeFileFromCommentaryStartedReducer,
    removeFileFromCommentaryErrorReducer,
    removeFileFromCommentarySuccessReducer,
} from './removeFileFromCommentaryReducers';
import {
    changeExecutorStartedReducer,
    changeExecutorErrorReducer,
    changeExecutorDoneReducer,
} from './changeExecutorReducers';

import {
    removeParticipatorStartReducer,
    removeParticipatorDoneReducer,
    removeParticipatorErrorReducer,
} from './removeParticipatorReducers';
import {
    moveToWorkTaskStartedReducer,
    moveToWorkTaskErrorReducer,
    moveToWorkTaskDoneReducer,
} from './moveToWorkTaskReducer';
import { cancelTaskStartedReducer, cancelTaskErrorReducer, cancelTaskDoneReducer } from './cancelTaskReducer';
import {
    loadEditorDataStartedReducer,
    loadEditorDataErrorReducer,
    loadEditorDataSuccessReducer,
} from './loadEditorDataReducers';
import {
    addNewTaskAssetReducer,
    replaceNewTaskAssetsReducer,
    removeNewTaskAssetReducer,
} from './newTaskAssetsReducers';
import { addNewTaskParticipator, removeNewTaskParticipator } from './newTaskParticipator';
import {
    createNewTaskStartReducer,
    createNewTaskFailureReducer,
    createNewTaskSuccessReducer,
} from './createNewTaskReducers';
import { updateTaskStartReducer, updateTaskFailReducer, updateTaskDoneReducer } from './updateTaskReducers';
import {
    addNewCommentaryAssetReducer,
    removeNewCommentaryAssetReducer,
    editCommentaryReducer,
    setEditingCommentReducer,
    clearComments,
    addCommentaryAssetReducer,
    removeCommentaryAssetReducer,
    setCommentaryIsLoadingReducer,
} from './commentary';
import { bulkAddParticipatorsStartReducer, bulkAddParticipatorsErrorReducer } from './bulkAddParticipatorsReducers';
import {
    createChannelDoneReducer,
    updateChannelDoneReducer,
    updateChannelFailedReducer,
    deleteChannelDoneReducer,
} from './channelReducers';
import { setRequestInProgressReducer } from './setRequestInProgressReducer';
import { setSubscribeIsDisabledReducer } from './setSubscribeIsDisabledReducer';
import { updateExistingCommentReducer } from './updateExistingCommentReducer';
import { setCommentIdToReplyToReducer } from './setCommentIdToReplyToReducer';
import { resetEditorReducer } from './resetEditorReducer';

export const taskPageReducer = reducerWithInitialState<TaskPageState>({
    loadingStatus: TaskLoadingStatus.NOT_LOADED,
    comments: {},
    channels: {},
    departments: {},
    workTypes: {},
    isUserSubscribed: false,
    editingComment: null,
    newCommentary: {
        id: uuidV4(),
        isLoading: false,
        content: '',
        authorId: -1,
        assetsIds: [],
        createdAt: Date.now(),
        reactions: [],
    },
    sendingComment: false,
    savingComment: null,
    participants: [],
    editingParticipants: [],
    isParticipantEditorFormVisible: false,
    isRequestInProgress: false,
    telegram: {
        hasUserAccessToChat: false,
        isUserCreatedChat: false,
        isUserAuthorized: false,
        fetchError: null,
        loading: false,
        linkToAuthorization: null,
    },
    rate: null,
    subscribeIsDisabled: false,
    organizationId: null,
    canDelete: false,
    users: [],
    createdBy: null,
    commentIdToReplyTo: null,
})
    .case(syncActions.resetNewCommentary, resetNewCommentaryReducer)
    .case(syncActions.setSendingComment, setSendingCommentReducer)
    .case(syncActions.setSavingComment, setSavingCommentReducer)
    .case(syncActions.replaceNewTaskAssets, replaceNewTaskAssetsReducer)
    .case(syncActions.addCommentaryAsset, addCommentaryAssetReducer)
    .case(syncActions.removeCommentaryAsset, removeCommentaryAssetReducer)
    .case(syncActions.editCommentary, editCommentaryReducer)
    .case(syncActions.setEditingComment, setEditingCommentReducer)
    .case(syncActions.resetEditor, resetEditorReducer)
    .case(syncActions.addNewCommentaryAsset, addNewCommentaryAssetReducer)
    .case(syncActions.removeNewCommentaryAsset, removeNewCommentaryAssetReducer)
    .case(syncActions.setAuthorId, setAuthorIdReducer)
    .case(syncActions.resetTaskPage, resetTaskPageReducer)
    .case(syncActions.resetTelegramFetchError, resetTelegramFetchErrorReducer)
    .case(syncActions.editTask, editTaskReducer)
    .case(syncActions.addNewTaskAsset, addNewTaskAssetReducer)
    .case(syncActions.addNewTaskParticipator, addNewTaskParticipator)
    .case(syncActions.removeNewTaskParticipator, removeNewTaskParticipator)
    .case(syncActions.removeNewTaskAsset, removeNewTaskAssetReducer)
    .case(syncActions.setIsUserSubscribed, setIsUserSubscribed)
    .case(syncActions.clearComments, clearComments)
    .case(syncActions.setCommentaryIsLoading, setCommentaryIsLoadingReducer)
    .case(syncActions.editActivityKeyStatus, editActivityKeyStatus)
    .case(syncActions.setRequestInProgress, setRequestInProgressReducer)
    .case(syncActions.setSubscribeIsDisabled, setSubscribeIsDisabledReducer)
    .case(syncActions.updateExistingComment, updateExistingCommentReducer)
    .case(syncActions.setCommentIdToReplyTo, setCommentIdToReplyToReducer)
    .case(asyncActions.createChannel.done, createChannelDoneReducer)
    .case(asyncActions.updateChannel.done, updateChannelDoneReducer)
    .case(asyncActions.updateChannel.failed, updateChannelFailedReducer)
    .case(asyncActions.updateTask.started, updateTaskStartReducer)
    .case(asyncActions.updateTask.failed, updateTaskFailReducer)
    .case(asyncActions.updateTask.done, updateTaskDoneReducer)
    .case(asyncActions.updateTelegram.started, updateTelegramStartedReducer)
    .case(asyncActions.updateTelegram.done, updateTelegramDoneReducer)
    .case(asyncActions.updateTelegram.failed, updateTelegramErrorReducer)
    .case(asyncActions.createNewTask.started, createNewTaskStartReducer)
    .case(asyncActions.createNewTask.failed, createNewTaskFailureReducer)
    .case(asyncActions.createNewTask.done, createNewTaskSuccessReducer)
    .case(asyncActions.loadEditorData.started, loadEditorDataStartedReducer)
    .case(asyncActions.loadEditorData.failed, loadEditorDataErrorReducer)
    .case(asyncActions.loadEditorData.done, loadEditorDataSuccessReducer)
    .case(asyncActions.changeExecutor.started, changeExecutorStartedReducer)
    .case(asyncActions.changeExecutor.failed, changeExecutorErrorReducer)
    .case(asyncActions.changeExecutor.done, changeExecutorDoneReducer)
    .case(asyncActions.addFileToTask.started, addFileToTaskStartedReducer)
    .case(asyncActions.addFileToTask.done, addFileToTaskDoneReducer)
    .case(asyncActions.addFileToTask.failed, addFileToTaskErrorReducer)
    .case(asyncActions.removeFileFromTask.started, removeFileFromTaskStartedReducer)
    .case(asyncActions.removeFileFromTask.failed, removeFileFromTaskErrorReducer)
    .case(asyncActions.removeFileFromTask.done, removeFileFromTaskSuccessReducer)
    .case(asyncActions.addFileToCommentary.started, addFileToCommentaryStartedReducer)
    .case(asyncActions.addFileToCommentary.done, addFileToCommentaryDoneReducer)
    .case(asyncActions.addFileToCommentary.failed, addFileToCommentaryErrorReducer)
    .case(asyncActions.removeFileFromCommentary.started, removeFileFromCommentaryStartedReducer)
    .case(asyncActions.removeFileFromCommentary.failed, removeFileFromCommentaryErrorReducer)
    .case(asyncActions.removeFileFromCommentary.done, removeFileFromCommentarySuccessReducer)
    .case(asyncActions.addCommentary.started, addCommentaryStartedReducer)
    .case(asyncActions.addCommentary.done, addCommentaryDoneReducer)
    .case(asyncActions.saveCommentary.done, saveCommentaryDoneReducer)
    .case(asyncActions.removeCommentary.started, removeCommentaryStartedReducer)
    .case(asyncActions.removeCommentary.done, removeCommentaryDoneReducer)
    .case(asyncActions.removeCommentary.failed, removeCommentaryErrorReducer)
    .case(asyncActions.removeParticipator.started, removeParticipatorStartReducer)
    .case(asyncActions.removeParticipator.done, removeParticipatorDoneReducer)
    .case(asyncActions.removeParticipator.failed, removeParticipatorErrorReducer)
    .case(asyncActions.loadTask.started, loadTaskStartedReducer)
    .case(asyncActions.loadTask.done, loadTaskDoneReducer)
    .case(asyncActions.loadTask.failed, loadTaskErrorReducer)
    .case(asyncActions.plugTelegram.started, plugTelegramStartedReducer)
    .case(asyncActions.plugTelegram.done, plugTelegramDoneReducer)
    .case(asyncActions.plugTelegram.failed, plugTelegramErrorReducer)
    .case(asyncActions.unplugTelegram.started, unplugTelegramStartedReducer)
    .case(asyncActions.unplugTelegram.done, unplugTelegramDoneReducer)
    .case(asyncActions.unplugTelegram.failed, unplugTelegramErrorReducer)
    .case(asyncActions.moveToWorkTask.started, moveToWorkTaskStartedReducer)
    .case(asyncActions.moveToWorkTask.done, moveToWorkTaskDoneReducer)
    .case(asyncActions.moveToWorkTask.failed, moveToWorkTaskErrorReducer)
    .case(asyncActions.cancelTask.started, cancelTaskStartedReducer)
    .case(asyncActions.cancelTask.done, cancelTaskDoneReducer)
    .case(asyncActions.cancelTask.failed, cancelTaskErrorReducer)
    .case(asyncActions.bulkAddParticipators.started, bulkAddParticipatorsStartReducer)
    .case(asyncActions.bulkAddParticipators.failed, bulkAddParticipatorsErrorReducer)
    .case(asyncActions.switchKeyActivityAsync.started, switchKeyActivityStartedReducer)
    .case(asyncActions.switchKeyActivityAsync.failed, switchKeyActivityFailedReducer)
    .case(asyncActions.setRate.started, setRateStartedReducer)
    .case(asyncActions.setRate.done, setRateDoneReducer)
    .case(asyncActions.setRate.failed, setRateErrorReducer)
    .case(asyncActions.deleteChannel.done, deleteChannelDoneReducer);
