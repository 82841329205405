import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { LoadingStatus } from '@store/commonTypes';

import { ProfileMenuNotificationsState as State } from './types';

import * as actions from './actions/sync';

class Reducer {
    public static makeInitialState(): State {
        return {
            loadingStatus: LoadingStatus.NOT_LOADED,
            isRequestInProgress: false,
            notifications: null,
        };
    }

    public static setNotifications(state: State, notifications: any): State {
        return Reducer.stateReducer(state, { notifications });
    }

    public static setLoadingStatus(state: State, loadingStatus: LoadingStatus): State {
        return Reducer.stateReducer(state, { loadingStatus });
    }

    public static setRequestInProgress(state: State, isRequestInProgress: boolean): State {
        return Reducer.stateReducer(state, { isRequestInProgress });
    }

    private static stateReducer(state: State, payload: Partial<State>): State {
        return { ...state, ...payload };
    }
}

export const profileMenuNotificationsReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(actions.setNotifications, Reducer.setNotifications)
    .case(actions.setLoadingStatus, Reducer.setLoadingStatus)
    .case(actions.setRequestInProgress, Reducer.setRequestInProgress);
