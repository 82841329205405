import { bindActionCreators } from 'redux';
import * as lodash from 'lodash';

import { Brief } from 'sber-marketing-types/frontend';

import { store } from '@store';
import { setBrief, setChangedBrief, loadEditBudgetPage } from '@store/plannedBudgetEdit/actions';
import { add as addFiles } from '@store/fileAssets';
import { BriefApi, FileApi } from '@api';

interface ConstructorParams {
    briefId: string;
}

export class BriefLoader {
    private briefId: string;
    private brief: Brief;

    private dispatch = bindActionCreators(
        {
            setBrief,
            setChangedBrief,
            loadEditBudgetPage,
            addFiles,
        },
        store.dispatch,
    );

    constructor(params: ConstructorParams) {
        this.briefId = params.briefId;
    }

    public async updateBrief(): Promise<void> {
        await this.loadBrief();
        this.dispatch.setBrief(this.brief);
    }

    public async createBrief(schemeId: string): Promise<Brief> {
        const createdBrief = await BriefApi.createBrief({ schemeId });
        this.brief = this.formatFilesDataOfBrief(createdBrief);
        this.briefId = this.brief.id;
        this.dispatch.setBrief(this.brief);
        return this.brief;
    }

    public pasteCurrentBrief(copiedBrief: Brief, currentBrief: Brief): void {
        const formattedBrief = this.formatFilesDataOfBrief(copiedBrief);
        this.dispatch.setChangedBrief({
            ...currentBrief,
            blocks: formattedBrief.blocks,
        });
    }

    private async loadBrief() {
        const brief = await BriefApi.getBrief(this.briefId);
        this.brief = this.formatFilesDataOfBrief(brief);
    }

    private formatFilesDataOfBrief(brief: Brief) {
        const updatedBrief = lodash.cloneDeep(brief);

        updatedBrief.blocks.forEach((block) =>
            block.fields.forEach((field) => {
                if (field.value && field.value.files) {
                    const files = FileApi.mapFiles(
                        {
                            fieldId: field.id,
                            briefId: brief.id,
                        },
                        field.value.files as any[],
                        '',
                    ) as any[];
                    field.value.files = files;
                    this.dispatch.addFiles(...files);
                }
            }),
        );

        return updatedBrief;
    }
}
