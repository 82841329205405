import * as React from 'react';

import { Flex, FlexProps } from '@common/components';

export interface SkeletonBlockProps extends FlexProps {
    width?: React.CSSProperties['width'];
    height?: React.CSSProperties['height'];
}

export const SkeletonBlock: React.FC<SkeletonBlockProps> = ({ width, height, style, ...props }) => {
    return (
        <Flex
            loading
            {...props}
            style={{
                width,
                height,
                minWidth: width,
                minHeight: height,
                ...style,
            }}
        />
    );
};
