import { Period } from '@store/calendar/types';

import { store } from '@store';
import { getCalendarPageState } from '@store/calendar/selectors';
import { Scroller, TimeCalculator } from './';

interface StoreProps {
    period: Period;
}

export class Scaler {
    private static instance: Scaler;
    private viewportWidthInPx: number;
    private timeCalculator: TimeCalculator;
    private scroller: Scroller;

    private constructor() {
        this.timeCalculator = TimeCalculator.getInstance();
        this.scroller = Scroller.getInstance();
    }

    public static getInstance(): Scaler {
        if (!Scaler.instance) {
            Scaler.instance = new Scaler();
        }
        return Scaler.instance;
    }

    public dispose() {
        Scaler.instance = null;

        this.timeCalculator = null;
        this.scroller = null;

        this.viewportWidthInPx = null;
    }

    public getFullWidthInPx(): number {
        return (this.getFullWidthInDays() / this.getViewportWidthInDays()) * this.viewportWidthInPx;
    }

    public getFullWidthInDays(): number {
        return this.timeCalculator.getAllYearsDaysCount();
    }

    public setViewportWidthInPx(width: number) {
        this.viewportWidthInPx = width;
    }

    public getViewportWidthInPx() {
        return this.viewportWidthInPx;
    }

    public getViewportWidthInDays(): number {
        const { period } = this.getStoreProps();

        return this.timeCalculator.getPeriodDaysCount(period);
    }

    public getViewportPositionInDays(): { start: number; end: number } {
        const { currentScroll } = this.scroller;
        const viewportWidthInDays = this.getViewportWidthInDays();

        const start = currentScroll * viewportWidthInDays;
        const end = start + viewportWidthInDays;

        return {
            start,
            end,
        };
    }

    public convertDayToRangeInPx(day: number): { start: number; end: number } {
        if (day === null) {
            return {
                start: null,
                end: null,
            };
        }

        const viewportWidthInDays = this.getViewportWidthInDays();
        const dayWidthInPx = this.viewportWidthInPx / viewportWidthInDays;

        const start = dayWidthInPx * (day - 1);
        const end = dayWidthInPx * day;

        return {
            start,
            end,
        };
    }

    public convertPxToDay(coordInPx: number): number {
        return Math.ceil((coordInPx / this.getFullWidthInPx()) * this.getFullWidthInDays());
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        const { period } = getCalendarPageState(storeState);

        return {
            period,
        };
    }
}
