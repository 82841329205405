import * as React from 'react';
import classNames from 'classnames';
import { Skeleton } from 'sber-marketing-ui';

import * as styles from './styles.scss';

export const ContentPreloader: React.FC = (): JSX.Element => {
    return (
        <div className={styles.root}>
            <div className={classNames(styles.preloader, styles.commonShortReports)}>
                <ShortReportPreloader />
            </div>
            <div className={classNames(styles.preloader, styles.someShortReports)}>
                <ShortReportPreloader />
                <ShortReportPreloader />
                <ShortReportPreloader />
                <ShortReportPreloader />
            </div>
            <div className={styles.preloader}>
                <VerticalChartPreloader />
            </div>
            <div className={styles.horizontalChartsPreloaderWrapper}>
                <HorizontalChartPreloader />
                <HorizontalChartPreloader />
                <HorizontalChartPreloader />
                <HorizontalChartPreloader />
            </div>
        </div>
    );
};

const ShortReportPreloader: React.FC = () => (
    <div className={styles.shortReportPreloader}>
        <Skeleton customStyle={{ height: 20, width: '80%', borderRadius: 2, marginBottom: 5 }} />
        <Skeleton customStyle={{ height: 54, width: '45%', borderRadius: 2, marginBottom: 5 }} />
        <Skeleton customStyle={{ height: 4, width: '100%', borderRadius: 2, marginBottom: 5 }} />
        <Skeleton customStyle={{ height: 16, width: '80%', borderRadius: 2, marginBottom: 5 }} />
    </div>
);

const HorizontalChartPreloader: React.FC = () => (
    <div className={classNames(styles.preloader, styles.horizontalChartPreloader)}>
        <Skeleton customStyle={{ height: '100%', width: '80%', borderRadius: 2, gridArea: 'text' }} />
        <Skeleton customStyle={{ height: '100%', width: '100%', borderRadius: 2, gridArea: 'first' }} />
        <Skeleton customStyle={{ height: '100%', width: '100%', borderRadius: 2, gridArea: 'second' }} />
        <Skeleton customStyle={{ height: '100%', width: '100%', borderRadius: 2, gridArea: 'chart' }} />
    </div>
);

const VerticalChartPreloader: React.FC = () => (
    <div className={classNames(styles.preloader, styles.verticalChartPreloader)}>
        <Skeleton customStyle={{ height: '100%', width: '100%', borderRadius: 2, gridArea: 'title' }} />
        <Skeleton customStyle={{ height: '100%', width: '100%', borderRadius: 2, gridArea: 'first' }} />
        <Skeleton customStyle={{ height: '100%', width: '100%', borderRadius: 2, gridArea: 'second' }} />
        <Skeleton customStyle={{ height: '100%', width: '100%', borderRadius: 2, gridArea: 'third' }} />
        <Skeleton customStyle={{ height: '100%', width: '100%', borderRadius: 2, gridArea: 'fourth' }} />
        <Skeleton customStyle={{ height: '100%', width: '100%', borderRadius: 2, gridArea: 'chart' }} />
    </div>
);
