import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { Dispatch, AnyAction } from 'redux';
import { uniq, sortBy } from 'lodash';

import { MrmClient } from '@api';

import { StoreState } from '@store';
import { StoreTypes as DivisionsStore, loadDivisions } from '@store/divisions';
import { StoreTypes as ActivityTypesStore, loadActivityTypes } from '@store/activityTypes';
import { StoreTypes as UsersStore, loadUsers } from '@store/users';
import { StoreTypes as OrganizationsStore, loadOrganizations } from '@store/organizations';
import { StoreTypes as WorkTypesStore, loadWorkTypes } from '@store/workTypes';
import { StoreTypes as ProductsStore, loadProducts } from '@store/products';
import { StoreTypes as DepartmentsStore, loadDepartments } from '@store/departments';
import { LoadingStatus } from '@store/commonTypes';
import { getTagsState } from '@store/tags';

import { ActivityApi, TaskApi, BudgetItemApi, BriefApi } from '@api';

import { TagGroup, SelectedNode, SelectedNodeData, SelectedTagData } from './types';

import * as actions from './actions/sync';
import * as asyncActions from './actions/async';

import { getTagsPageState } from './selectors';

import { makeSelectedTagData } from './misc';

export const loadPageData = bindThunkAction<StoreState, string, void, Error>(
    asyncActions.loadPageData,
    async (tagId, dispatch, getState) => {
        if (getTagsPageState(getState()).loadingStatus.common === LoadingStatus.NOT_LOADED) {
            dispatch(loadOrganizations({ store: OrganizationsStore.GENERAL }));
            dispatch(loadUsers({ store: UsersStore.GENERAL }));
            dispatch(loadDivisions({ store: DivisionsStore.GENERAL }));
            dispatch(loadActivityTypes({ store: ActivityTypesStore.GENERAL }));
            dispatch(loadWorkTypes({ store: WorkTypesStore.GENERAL }));
            dispatch(loadProducts({ store: ProductsStore.GENERAL }));
            dispatch(loadDepartments({ store: DepartmentsStore.GENERAL }));

            const tags = getTagsState(getState());
            const tagIdToUse = tags.byId.dictionary[tagId] ? tagId : tags.entities[0]?.id;
            if (tagIdToUse) {
                dispatch(setSelectedTag(tagIdToUse));
            }

            dispatch(actions.setCommonLoadingStatus(LoadingStatus.LOADED));
        }
    },
);

// function makeStubData(tag: Tag): SelectedTagData {
//     const tagId = tag.id;

//     const projects: TagsPageProject[] = [
//         { id: v4(), tagId, title: 'RDRC автогонки (СберИнвестиционный Портфель' },
//         { id: v4(), tagId, title: 'Согласование мероприятия с заказчиком из банка' },
//         { id: v4(), tagId, title: 'СберИнвестиции' }
//     ];
//     const tasks: TagsPageTask[] = [
//         { id: v4(), tagId, title: 'Акселератор с 500 Startups #1' },
//         { id: v4(), tagId, title: 'Акселератор с 500 Startups #2' },
//         { id: v4(), tagId, activityId: projects[0].id, title: 'Акселератор с 500 Startups a11' },
//         { id: v4(), tagId, activityId: projects[0].id, title: 'Акселератор с 500 Startups a12' },
//         { id: v4(), tagId, activityId: projects[0].id, title: 'Акселератор с 500 Startups a13' },
//         { id: v4(), tagId, activityId: projects[2].id, title: 'Акселератор с 500 Startups a31' },
//         { id: v4(), tagId, activityId: projects[2].id, title: 'Акселератор с 500 Startups a31' },
//         { id: v4(), tagId, activityId: projects[2].id, title: 'Акселератор с 500 Startups a32' },
//         { id: v4(), tagId, activityId: projects[2].id, title: 'Акселератор с 500 Startups a33' },
//     ];
//     const executionBudgetItems: TagsPageBudgetItem[] = [
//         { id: v4(), tagId, serialNumber: 5008 },
//         { id: v4(), tagId, serialNumber: 608 },
//         { id: v4(), tagId, activityId: projects[0].id, serialNumber: 5001 },
//         { id: v4(), tagId, activityId: projects[0].id, serialNumber: 5012 },
//         { id: v4(), tagId, activityId: projects[2].id, serialNumber: 2004 }
//     ];
//     const planBudgetItems: TagsPageBudgetItem[] = [
//         { id: v4(), tagId, parentId: executionBudgetItems[0].id, serialNumber: 6608 },
//         { id: v4(), tagId, parentId: executionBudgetItems[2].id, serialNumber: 5004 },
//         { id: v4(), tagId, parentId: executionBudgetItems[3].id, serialNumber: 5002 },
//         { id: v4(), tagId, parentId: executionBudgetItems[4].id, serialNumber: 2001 },
//         { id: v4(), tagId, parentId: executionBudgetItems[4].id, serialNumber: 5012 },
//         { id: v4(), tagId, parentId: executionBudgetItems[4].id, serialNumber: 2002 },
//     ];

//     return {
//         projects, tasks, executionBudgetItems, planBudgetItems
//     };
// }

// function makeStubData2(tagId: string): SelectedTagData {
//     const projects: TagsPageProject[] = [
//         { id: '12205', tagId, title: 'Для уведомлений' }
//     ];
//     const tasks: TagsPageTask[] = [
//         { id: "c3ac21f6-3ae7-4ede-8c18-f2f054eb9075", tagId, title: '1111111111' }
//     ];
//     const executionBudgetItems: TagsPageBudgetItem[] = [
//         { id: "23f59b19-1686-427e-aa83-f965673a649a", tagId, serialNumber: 6971 }
//     ];
//     const planBudgetItems: TagsPageBudgetItem[] = [
//         { id: 'cf921109-c250-4fbc-aaba-d99c61e5105a', tagId, serialNumber: 2895 }
//     ];

//     return { projects, tasks, executionBudgetItems, planBudgetItems };
// }

export const toggleTagGroupFilter = bindThunkAction<StoreState, TagGroup, void, Error>(
    asyncActions.toggleTagGroupFilter,
    async (tagGroup, dispatch, getState) => {
        const tagGroupsFilter = getTagsPageState(getState()).filters.groups;
        const tagGroupsFilterUpd = tagGroupsFilter.includes(tagGroup)
            ? tagGroupsFilter.filter((tagGroupItem) => tagGroupItem !== tagGroup)
            : [...tagGroupsFilter, tagGroup];

        dispatch(actions.setTagGroupFilter(tagGroupsFilterUpd));
    },
);

async function withSelectedNodeDataRequestInProgress(
    dispatch: Dispatch<AnyAction>,
    callback: () => Promise<any>,
): Promise<any> {
    dispatch(actions.setSelectedNodeDataLoadingStatus(LoadingStatus.LOADING));

    let res: any;
    try {
        res = await callback();
    } catch (e) {
        dispatch(actions.setSelectedNodeDataLoadingStatus(LoadingStatus.ERROR));
    }

    return res;
}

export const toggleSelectedNode = bindThunkAction<StoreState, SelectedNode, void, Error>(
    asyncActions.toggleSelectedNode,
    async (selectedNode, dispatch, getState) => {
        dispatch(actions.setSelectedNode(selectedNode));
        const { selectedNodeData } = getTagsPageState(getState());

        if (selectedNode) {
            const { id, group } = selectedNode;
            const updSelectedNodeData: SelectedNodeData = {};

            switch (group) {
                case TagGroup.Project:
                    const nId = +id;

                    updSelectedNodeData.project =
                        selectedNodeData?.project?.activity?.id === nId
                            ? selectedNodeData.project
                            : await withSelectedNodeDataRequestInProgress(dispatch, async () => {
                                  const activity = await ActivityApi.getActivity(nId);
                                  const briefScheme = activity.brief?.schemeId
                                      ? await BriefApi.getBriefSchemeById(activity.brief.schemeId)
                                      : null;

                                  return { activity, briefScheme };
                              });

                    break;
                case TagGroup.Task:
                    updSelectedNodeData.task =
                        selectedNodeData?.task?.task?.id === id
                            ? selectedNodeData.task
                            : await withSelectedNodeDataRequestInProgress(dispatch, async () => {
                                  const task = (await TaskApi.getTask(id)).task;
                                  const activity = task?.activityId
                                      ? await ActivityApi.getActivity(task.activityId)
                                      : null;

                                  return { task, activity };
                              });

                    break;
                case TagGroup.ExecutionId:
                    updSelectedNodeData.executionBudgetItem =
                        selectedNodeData?.executionBudgetItem?.id === id
                            ? selectedNodeData.executionBudgetItem
                            : await withSelectedNodeDataRequestInProgress(dispatch, async () =>
                                  BudgetItemApi.getBudgetItem(id),
                              );

                    break;
                case TagGroup.PlanId:
                    updSelectedNodeData.planBudgetItem =
                        selectedNodeData?.planBudgetItem?.id === id
                            ? selectedNodeData.planBudgetItem
                            : await withSelectedNodeDataRequestInProgress(dispatch, async () =>
                                  BudgetItemApi.getBudgetItem(id),
                              );

                    break;
                default:
                    console.warn(`Missing case-clause for TagGroup.${group}`);
            }

            dispatch(actions.setSelectedNodeData(updSelectedNodeData));
            dispatch(actions.setSelectedNodeDataLoadingStatus(LoadingStatus.LOADED));
        }
    },
);

async function withSelectedTagDataRequestInProgress(
    dispatch: Dispatch<AnyAction>,
    callback: () => Promise<any>,
): Promise<any> {
    dispatch(actions.setSelectedTagDataLoadingStatus(LoadingStatus.LOADING));

    let res: any;
    try {
        res = await callback();

        dispatch(actions.setSelectedTagDataLoadingStatus(LoadingStatus.LOADED));
    } catch (e) {
        dispatch(actions.setSelectedTagDataLoadingStatus(LoadingStatus.ERROR));
    }

    return res;
}

function getYearsFilter(data: SelectedTagData): number[] {
    const items: number[] = [
        ...Object.values(data.projects).map((item) => item.year),
        ...Object.values(data.tasks).map((item) => item.year),
        ...Object.values(data.executionBudgetItems).map((item) => item.year),
        ...Object.values(data.planBudgetItems).map((item) => item.year),
    ];

    return sortBy(uniq(items));
}

export const setSelectedTag = bindThunkAction<StoreState, string, void, Error>(
    asyncActions.setSelectedTag,
    async (tagId, dispatch, getState) => {
        dispatch(actions.setTagFilter(tagId));

        await withSelectedTagDataRequestInProgress(dispatch, async () => {
            const api = (await MrmClient.getInstance()).api.tags;

            const params: any = { tagId, years: [] };
            const data = makeSelectedTagData(
                await api.getProjects(params),
                await api.getTasks(params),
                await api.getExecutionBudgetItems(params),
                await api.getPlanBudgetItems(params),
            );
            dispatch(actions.setSelectedTagData(data));

            const dataYears = getYearsFilter(data);
            dispatch(actions.setYears(dataYears));
            dispatch(actions.setYearsFilter(dataYears));
        });
    },
);
