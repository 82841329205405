import { CellValue, CellValueType, NumericCellValue, DateCellValue } from '@store/budgetPlanning/types';

export function getFieldValue(field: CellValue, valueType: CellValueType): string | Date {
    let value: string | Date;

    switch (valueType) {
        case CellValueType.String:
            value = field as string;
            break;

        case CellValueType.Number:
            value = field && (field as NumericCellValue).title;
            break;

        case CellValueType.Currency:
            value = field && (field as NumericCellValue).title;
            break;

        case CellValueType.Date:
            value = field && (field as DateCellValue).date;
            break;
    }

    return value;
}
