/** API request error */
export class FetchError extends Error {
    /** HTTP error code */
    public code: number | string;
    /** HTTP error type */
    public type: string;
    /** HTTP error message */
    public message: string;
    /** @constructor */
    public constructor(code: number | string, type: string, message: string) {
        super(`${code} ${type}: ${message}`);
    }
}
