import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';
import * as style from './Indicators.scss';

const enum ColorMap {
    GREY = 'grey',
    GREEN = 'green',
    RED = 'red',
}

const colorStyleMap = {
    [ColorMap.GREY]: style.grey,
    [ColorMap.GREEN]: style.green,
    [ColorMap.RED]: style.red,
};

const IndicatorBody: React.FC<{ color: ColorMap; qaId: string }> = ({ children, color, qaId }) => {
    return (
        <div
            className={classNames(style.indicatorBody, colorStyleMap[color])}
            {...{
                'qa-id': 'qaId',
            }}
        >
            {children}
        </div>
    );
};

// const EllipsesIcon: React.FC = () => {
//     return <div className={style.iconWrapper}><Icon type={IconType.CIRCLE_BOLD_ELLIPSES} svgSize={16}/></div>;
// };

const CrossIcon: React.FC = () => {
    return (
        <div className={style.iconWrapper}>
            <Icon type={IconType.CIRCLE_BOLD_CROSS} svgSize={16} />
        </div>
    );
};

const PendingIcon: React.FC = () => {
    return (
        <div className={style.iconWrapper}>
            <Icon type={IconType.PENDING_ICON} svgSize={16} />
        </div>
    );
};

const CheckIcon: React.FC = () => {
    return (
        <div className={style.iconWrapper}>
            <Icon type={IconType.CIRCLE_BOLD_CHECK} svgSize={16} />
        </div>
    );
};

// const Button: React.FC<{ onClick?: () => void; }> = ({ onClick }) => {
//     return <div className={style.buttonRoot} onClick={onClick} >Отменить</div>;
// };

interface IndicatorProps {
    enableControls?: boolean;
    onRevertClick?: () => void;
}

export const ApprovedIndicator: React.FC<IndicatorProps> = (
    {
        // enableControls,
        // onRevertClick
    },
) => {
    return (
        <IndicatorBody
            color={ColorMap.GREEN}
            qaId="dashboardRightSidebarBondActivitiesWithBudgetItemIdApprovedIndicator"
        >
            <CheckIcon />
            Связь ID и проекта подтверждена
            {/* { enableControls && <Button onClick={onRevertClick} /> } */}
        </IndicatorBody>
    );
};

export const RejectedIndicator: React.FC<IndicatorProps> = (
    {
        // enableControls,
        // onRevertClick
    },
) => {
    return (
        <IndicatorBody color={ColorMap.RED} qaId="dashboardRightSidebarBondActivitiesWithBudgetItemIdRejectedIndicator">
            <CrossIcon />
            Связь ID и проекта отклонена
            {/* { enableControls && <Button onClick={onRevertClick} /> } */}
        </IndicatorBody>
    );
};

export const DeletedIndicator: React.FC<IndicatorProps> = (
    {
        // enableControls,
        // onRevertClick
    },
) => {
    return (
        <IndicatorBody color={ColorMap.RED} qaId="dashboardRightSidebarBondActivitiesWithBudgetItemIdDeletedIndicator">
            <CrossIcon />
            Связь ID и проекта удалена
            {/* { enableControls && <Button onClick={onRevertClick} /> } */}
        </IndicatorBody>
    );
};

export const PendingIndicator: React.FC<{}> = ({}) => {
    return (
        <IndicatorBody color={ColorMap.GREY} qaId="dashboardRightSidebarBondActivitiesWithBudgetItemIdPendingIndicator">
            <PendingIcon />
            Связь ID и проекта согласовывается
        </IndicatorBody>
    );
};
