import * as React from 'react';
import classNames from 'classnames';

import * as style from './Tooltip.scss';

import { Direction } from '../types';

interface Props {
    title: string;
    direction: Direction;
}

export function Tooltip({ title, direction }: Props): JSX.Element {
    return (
        <div
            className={classNames(
                style.root,
                direction == Direction.Top && style.top,
                direction == Direction.Right && style.right,
            )}
        >
            {title}
        </div>
    );
}
