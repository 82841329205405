import { actionCreatorFactory } from 'typescript-fsa';

import {
    LoadEntitiesPayload,
    LoadDivisionsParams,
    SetStoreIdsParams,
    SetLoadingStatusParams,
    StoreTypes,
} from './types';

const actionCreator = actionCreatorFactory('DIVISIONS');

const loadDivisionsAsync = actionCreator.async<LoadDivisionsParams, void, Error>('LOAD_DIVISIONS_ASYNC');

const loadEntities = actionCreator<LoadEntitiesPayload>('LOAD_ENTITIES');
const setStoreIds = actionCreator<SetStoreIdsParams>('SET_STORE_IDS');
const setLoadingStatus = actionCreator<SetLoadingStatusParams>('SET_LOADING_STATUS');
const resetStore = actionCreator<StoreTypes>('RESET_STORE');
const incFetchersCount = actionCreator<StoreTypes>('INC_FETCHERS_COUNT');
const decFetchersCount = actionCreator<StoreTypes>('DEC_FETCHERS_COUNT');

export {
    loadDivisionsAsync,
    loadEntities,
    setStoreIds,
    setLoadingStatus,
    resetStore,
    incFetchersCount,
    decFetchersCount,
};
