import * as ExcelJS from 'exceljs';

import { FetchedData } from './FetchedData';
import { DATA_SHEET_TITLE, ROWS_COUNT, Columns, ColumnProps } from '../types';

export class DataReader {
    public static read(workbook: ExcelJS.Workbook): FetchedData[] {
        const data: FetchedData[] = [];

        const dataSheet = workbook.worksheets.find((sheet) => sheet.name === DATA_SHEET_TITLE);
        // generating data->column indices
        // column indices start from 1, so we need null stub
        const columnDescriptors: ColumnProps[] = [null];
        dataSheet.getRow(1).eachCell((cell) => {
            const columnParams = Columns.find((column) => column.header === cell.value);

            columnDescriptors.push(columnParams || null);
        });

        // pulling data from sheet's columns
        dataSheet.getRows(2, ROWS_COUNT).forEach((row) => {
            let rowHasData = false;
            const fetchedData = new FetchedData();

            row.eachCell((cell) => {
                let cellValue = cell.value as any;
                if (typeof cellValue === 'string') {
                    cellValue = cellValue.trim();
                }

                const columnDesc: ColumnProps = columnDescriptors[cell.col];
                if (!columnDesc?.ignoreForImport) {
                    rowHasData = true;
                    fetchedData.setFieldByName(columnDesc?.key, cellValue);
                }
            });

            if (rowHasData) {
                data.push(fetchedData);
            }
        });

        return data;
    }
}
