import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import { getTagsPageState, getTagsFilterAsItems, setSelectedTag } from '@store/tagsPage';

import { FilterTitle, WithDropdown } from '../common';

import * as styles from './TagsFilter.scss';

function useInteractivity() {
    const dispatch = useDispatch();

    const items = useSelector((state: StoreState) => getTagsFilterAsItems(state));
    const selectedItemId = useSelector((state: StoreState) => getTagsPageState(state).filters.tag);

    function onItemSelect(itemId: string) {
        if (selectedItemId !== itemId) {
            dispatch(setSelectedTag(itemId));
        }
    }

    return { items, selectedItemId, onItemSelect };
}

export function TagsFilter(): JSX.Element {
    const { items, selectedItemId, onItemSelect } = useInteractivity();

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'tagsPageTagsFitler',
            }}
        >
            <div className={styles.title}>
                <FilterTitle title="Тег" />
            </div>

            {!!items.length && (
                <WithDropdown
                    singleItemSelect
                    title={items.find((item) => item.id === selectedItemId).title}
                    items={items}
                    selectedItemId={selectedItemId}
                    width={250}
                    onItemSelect={onItemSelect}
                />
            )}
        </div>
    );
}
