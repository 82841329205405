// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-Footer-_Footer__root{position:relative;display:flex;width:100%;height:64px;background-color:#ffffff;font-family:'Open Sans';align-items:center;justify-content:center}.src-client-modules-common-Footer-_Footer__text{user-select:none;text-align:center;letter-spacing:-0.1px;color:#9b9b9b;font-size:14px}.src-client-modules-common-Footer-_Footer__icon{margin-right:40px;padding-top:6px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/Footer/Footer.scss"],"names":[],"mappings":"AAAA,gDACI,iBAAkB,CAElB,YAAa,CAEb,UAAW,CACX,WAAY,CAEZ,wBAAyB,CAEzB,uBAAwB,CAExB,kBAAmB,CACnB,sBAAuB,CAC1B,gDAGG,gBAAiB,CACjB,iBAAkB,CAClB,qBAAsB,CAEtB,aAAc,CAEd,cAAe,CAClB,gDAGG,iBAAkB,CAClB,eAAgB","sourcesContent":[".root {\n    position: relative;\n\n    display: flex;\n\n    width: 100%;\n    height: 64px;\n\n    background-color: #ffffff;\n\n    font-family: 'Open Sans';\n\n    align-items: center;\n    justify-content: center;\n}\n\n.text {\n    user-select: none;\n    text-align: center;\n    letter-spacing: -0.1px;\n\n    color: #9b9b9b;\n\n    font-size: 14px;\n}\n\n.icon {\n    margin-right: 40px;\n    padding-top: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-Footer-_Footer__root",
	"text": "src-client-modules-common-Footer-_Footer__text",
	"icon": "src-client-modules-common-Footer-_Footer__icon"
};
export default ___CSS_LOADER_EXPORT___;
