// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-EditPlannedBudgetPage-NotificationMessages-_NotificationMessages__root{letter-spacing:0.15px;color:#000000;font-family:'Open Sans';font-size:12px;font-weight:600;line-height:16px}.src-client-modules-budget-EditPlannedBudgetPage-NotificationMessages-_NotificationMessages__link{text-decoration:none}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/EditPlannedBudgetPage/NotificationMessages/NotificationMessages.scss"],"names":[],"mappings":"AAAA,kGACI,qBAAsB,CAEtB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACpB,kGAGG,oBAAqB","sourcesContent":[".root {\n    letter-spacing: 0.15px;\n\n    color: #000000;\n\n    font-family: 'Open Sans';\n    font-size: 12px;\n    font-weight: 600;\n    line-height: 16px;\n}\n\n.link {\n    text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-EditPlannedBudgetPage-NotificationMessages-_NotificationMessages__root",
	"link": "src-client-modules-budget-EditPlannedBudgetPage-NotificationMessages-_NotificationMessages__link"
};
export default ___CSS_LOADER_EXPORT___;
