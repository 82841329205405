import { actionCreatorFactory } from 'typescript-fsa';
import { ActivityCard } from 'sber-marketing-types/frontend';

import {
    ActivitiesListType,
    ShareActivityPayload,
    UpdateActivityOwnershipsPayload,
    SwitchKeyActivityPayload,
} from './types';

const creator = actionCreatorFactory('ACTIVITIES_LIST');

// Async actions

/** Fetch more activities by settled filters */
export const fetchMoreActivities = creator.async<any, boolean, Error>('FETCH_MORE_ACTIVITIES');

/** Delete activities by identifiers */
export const deleteActivities = creator.async<number | number[], void, Error>('DELETE_ACTIVITIES');

/** Make activities favorite */
export const makeActivitiesFavorite = creator.async<number, void, Error>('MAKE_ACTIVITIES_FAVORITE');

/** Share activity with users */
export const shareActivity = creator.async<ShareActivityPayload, void, Error>('SHARE_ACTIVITY');

export const switchKeyActivity = creator.async<SwitchKeyActivityPayload, void, Error>('SWITCH_KEY_ACTIVITY');

// Sync actions

/** Set "canBeLoadedMore" value */
export const setCanBeLoadedMore = creator<boolean>('SET_CAN_BE_LOADED_MORE');

/** Set type of activities list */
export const setType = creator<ActivitiesListType>('SET_TYPE');

/** Reset user activities store */
export const resetMyActivities = creator('RESET_MY_ACTIVITIES');

/** Clear activities list */
export const clearActivitiesList = creator('CLEAR_ACTIVITIES_LIST');

/** Add one or more activities into store */
export const pushActivities = creator<ActivityCard | ActivityCard[]>('PUSH_ACTIVITIES');

/** Remove one ore more activities from store */
export const removeActivities = creator<number | number[]>('REMOVE_ACTIVITIES');

/** Pin activities */
export const switchActivityFavorite = creator<number>('PIN_ACTIVITIES');

/** Update activity ownerships */
export const updateActivityOwnerships = creator<UpdateActivityOwnershipsPayload>('UPDATE_ACTIVITY_OWNERSHIPS');

export const setLastRequestTimestamp = creator<Date>('SET_LAST_REQUEST_TIMESTAMP');
export const resetLastRequestTimestamp = creator('RESET_LAST_REQUEST_TIMESTAMP');

export const setPendingDeletionIds = creator<number[]>('SET_PENDING_DELETION_IDS');
