import * as React from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { CustomScrollbar_new as CustomScrollbar, Icon, IconType } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getUserById } from '@store/appUsers';
import { ParticipantParams, getTaskEditorState, setParticipants } from '@store/taskEditor2';

import { EditParticipantsForm } from '@common/TaskParticipants/EditParticipantsForm';

import * as styles from './ParticipantsDropdown.scss';

function useInteractivity() {
    const dispatch = useDispatch();

    const [showEditParticipantsForm, setShowEditParticipantsForm] = React.useState(false);

    const participants = useSelector((state: StoreState) => getTaskEditorState(state).values.participants.editing);
    const disabled = useSelector((state: StoreState) => !getTaskEditorState(state).permissions.canChangeRest);
    function changeParticipants(participants: ParticipantParams[]) {
        dispatch(setParticipants(participants));
    }
    function removeParticipant(participantId: number) {
        dispatch(setParticipants(participants.filter((participant) => participant.userId !== participantId)));
    }

    return {
        participants,
        changeParticipants,
        removeParticipant,
        showEditParticipantsForm,
        setShowEditParticipantsForm,
        disabled,
    };
}

export function ParticipantsDropdown(): JSX.Element {
    const {
        participants,
        changeParticipants,
        removeParticipant,
        showEditParticipantsForm,
        setShowEditParticipantsForm,
        disabled,
    } = useInteractivity();

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'taskEditorParticipantsDropdown',
            }}
        >
            <div className={styles.titleRow}>
                <div className={styles.title}>
                    <span className={styles.titleBlack}>Участники в задаче&nbsp;</span>

                    {participants.length}
                </div>

                <div
                    className={classNames(
                        styles.addParticipantsButton,
                        disabled && styles.addParticipantsButtonDisabled,
                    )}
                    onClick={!disabled ? () => setShowEditParticipantsForm(!showEditParticipantsForm) : null}
                    {...{
                        'qa-id': 'taskEditorParticipantsDropdownAddParticipantButton',
                    }}
                >
                    Добавить
                </div>

                {showEditParticipantsForm && (
                    <EditParticipantsForm
                        participants={participants}
                        onCloseForm={() => setShowEditParticipantsForm(false)}
                        onParticipantsChange={changeParticipants}
                    />
                )}
            </div>

            <ParticipantsList participants={participants} removeParticipant={removeParticipant} />

            {showEditParticipantsForm && (
                <div className={styles.editorMask} onClick={() => setShowEditParticipantsForm(false)} />
            )}
        </div>
    );
}

interface ParticipantsListProps {
    participants: ParticipantParams[];
    removeParticipant: (id: number) => void;
}

const ITEM_HEIGHT = 44;

function ParticipantsList({ participants, removeParticipant }: ParticipantsListProps): JSX.Element {
    return (
        <div
            className={styles.participantsList}
            {...{
                'qa-id': 'taskEditorParticipantsList',
            }}
        >
            <CustomScrollbar hideScrollX maxHeight={ITEM_HEIGHT * 5}>
                {participants.map((participant) => (
                    <Participant {...participant} key={participant.userId} removeParticipant={removeParticipant} />
                ))}
            </CustomScrollbar>
        </div>
    );
}

interface ParticipantProps extends ParticipantParams {
    removeParticipant: (userID: number) => void;
}

function Participant({ userId, canRemove, removeParticipant }: ParticipantProps): JSX.Element {
    const [showDeleteButton, setShowDeleteButton] = React.useState(false);
    const participant = useSelector((state: StoreState) => getUserById(state, userId));
    function updateShowDeleteButton(value: boolean) {
        if (canRemove) {
            setShowDeleteButton(value);
        }
    }

    return (
        <div
            className={styles.participantItem}
            onMouseEnter={() => updateShowDeleteButton(true)}
            onMouseLeave={() => updateShowDeleteButton(false)}
            {...{
                'qa-id': 'taskEditorParticipantsListItem',
            }}
        >
            <div>
                <div className={styles.participantTitle}>
                    {participant?.firstName} {participant?.secondName}
                </div>

                <div className={styles.participantDepartment}>{participant?.department}</div>
            </div>

            {showDeleteButton && (
                <div
                    className={styles.participantItemDeleteButton}
                    onClick={() => removeParticipant(userId)}
                    {...{
                        'qa-id': 'taskEditorParticipantsListItemDeleteButton',
                    }}
                >
                    <Icon type={IconType.DELETE_ROUND} svgSize={12} />
                </div>
            )}
        </div>
    );
}
