// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-StageActions-WithTooltip-TooltipOfFirstStage-TooltipContent-_styles__root{text-align:left}.src-client-modules-activity-pages-creative-StageActions-WithTooltip-TooltipOfFirstStage-TooltipContent-_styles__title{margin-bottom:5px;font-weight:600}.src-client-modules-activity-pages-creative-StageActions-WithTooltip-TooltipOfFirstStage-TooltipContent-_styles__paramRoot{margin-top:10px}.src-client-modules-activity-pages-creative-StageActions-WithTooltip-TooltipOfFirstStage-TooltipContent-_styles__paramFields{margin-top:3px;padding-left:11px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/StageActions/WithTooltip/TooltipOfFirstStage/TooltipContent/styles.scss"],"names":[],"mappings":"AAAA,sHACI,eAAgB,CACnB,uHAGG,iBAAkB,CAElB,eAAgB,CACnB,2HAGG,eAAgB,CACnB,6HAGG,cAAe,CACf,iBAAkB","sourcesContent":[".root {\n    text-align: left;\n}\n\n.title {\n    margin-bottom: 5px;\n\n    font-weight: 600;\n}\n\n.paramRoot {\n    margin-top: 10px;\n}\n\n.paramFields {\n    margin-top: 3px;\n    padding-left: 11px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-StageActions-WithTooltip-TooltipOfFirstStage-TooltipContent-_styles__root",
	"title": "src-client-modules-activity-pages-creative-StageActions-WithTooltip-TooltipOfFirstStage-TooltipContent-_styles__title",
	"paramRoot": "src-client-modules-activity-pages-creative-StageActions-WithTooltip-TooltipOfFirstStage-TooltipContent-_styles__paramRoot",
	"paramFields": "src-client-modules-activity-pages-creative-StageActions-WithTooltip-TooltipOfFirstStage-TooltipContent-_styles__paramFields"
};
export default ___CSS_LOADER_EXPORT___;
