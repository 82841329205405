import { actionCreatorFactory } from 'typescript-fsa';

import { LoadingStatus } from '@store/commonTypes';

import { ComponentState } from '../types';

const actionCreator = actionCreatorFactory('PROFILE_MENU');

export const setComponentState = actionCreator<ComponentState>('SET_COMPONENT_STATE');
export const openProfileMenu = setComponentState.bind(null, ComponentState.Menu);
export const closeProfileMenu = setComponentState.bind(null, ComponentState.Closed);

export const setLoadingStatus = actionCreator<LoadingStatus>('SET_LOADING_STATUS');
