import * as React from 'react';
import { graphql } from 'react-apollo';

import { QueryProps, BudgetItem } from './types';
import { LINKED_ACTIVITIES_QUERY } from './graphqlQuery';

interface ExternalProps {
    budgetItem: BudgetItem;
}
interface Props extends QueryProps {}

interface State {
    isLoading: boolean;
}

export const withLinkedActivities = (WrappedComponent: any) =>
    graphql<ExternalProps>(LINKED_ACTIVITIES_QUERY, {
        options: ({ budgetItem }) => ({
            variables: {
                budgetItemId: budgetItem.id,
            },
            fetchPolicy: 'no-cache',
        }),
    })(
        // @ts-ignore
        class extends React.Component<Props, State> {
            constructor(props: Props) {
                super(props);

                this.state = { isLoading: false };
                this.updateLinkedActivities = this.updateLinkedActivities.bind(this);
            }
            public render() {
                const { data } = this.props;

                return React.createElement(WrappedComponent, {
                    ...this.props,
                    linkedActivitiesLoading: data.loading || this.state.isLoading,
                    linkedActivities: data.linkedActivities || [],
                    updateLinkedActivities: this.updateLinkedActivities,
                });
            }

            private async updateLinkedActivities(): Promise<void> {
                this.setState({ isLoading: true });
                await this.props.data.refetch();
                this.setState({ isLoading: false });
            }
        },
    );
