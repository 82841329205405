import * as React from 'react';
import classNames from 'classnames';
import * as styles from './styles.scss';

interface Props {
    className?: string;
}

export const Header: React.FC<Props> = ({ className }) => {
    return (
        <div className={classNames(styles.root, className)}>
            <div className={styles.title}>Внимание!</div>
            <div className={styles.content}>Перед согласованием проверьте выбранные тексты:</div>
        </div>
    );
};
