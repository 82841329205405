// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-layout-Scrollbar-_Scrollbar__root{scrollbar-color:#3b8200 #cdcdcd;--ui-scrollbar-size: 12px}@-moz-document url-prefix(){.src-client-modules-common-components-layout-Scrollbar-_Scrollbar__root{--ui-scrollbar-size: 0px}}.src-client-modules-common-components-layout-Scrollbar-_Scrollbar__root::-webkit-scrollbar{width:var(--ui-scrollbar-size);height:var(--ui-scrollbar-size)}.src-client-modules-common-components-layout-Scrollbar-_Scrollbar__root::-webkit-scrollbar-track{background-color:#cdcdcd;background-clip:padding-box;border-radius:6px;border:2px solid transparent}.src-client-modules-common-components-layout-Scrollbar-_Scrollbar__root::-webkit-scrollbar-thumb{background-color:#3b8200;background-clip:padding-box;border-radius:6px;border:2px solid transparent}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/layout/Scrollbar/Scrollbar.scss"],"names":[],"mappings":"AAAA,wEACE,+BAAgC,CAEhC,yBAAoB,CAEpB,4BALF,wEAMI,wBAAoB,CAmBvB,CAzBD,2FAUI,8BAA+B,CAC/B,+BAAgC,CAXpC,iGAcI,wBAAyB,CACzB,2BAA4B,CAC5B,iBAAkB,CAClB,4BAA6B,CAjBjC,iGAoBI,wBAAyB,CACzB,2BAA4B,CAC5B,iBAAkB,CAClB,4BAA6B","sourcesContent":[".root {\n  scrollbar-color: #3b8200 #cdcdcd;\n\n  --ui-scrollbar-size: 12px;\n\n  @-moz-document url-prefix() {\n    --ui-scrollbar-size: 0px;\n  }\n\n  &::-webkit-scrollbar {\n    width: var(--ui-scrollbar-size);\n    height: var(--ui-scrollbar-size);\n  }\n  &::-webkit-scrollbar-track {\n    background-color: #cdcdcd;\n    background-clip: padding-box;\n    border-radius: 6px;\n    border: 2px solid transparent;\n  }\n  &::-webkit-scrollbar-thumb {\n    background-color: #3b8200;\n    background-clip: padding-box;\n    border-radius: 6px;\n    border: 2px solid transparent;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-components-layout-Scrollbar-_Scrollbar__root"
};
export default ___CSS_LOADER_EXPORT___;
