// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-Block-_Block__root{padding:16px}.src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-Block-_Block__header{display:flex;align-items:center}.src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-Block-_Block__toggle{margin-right:10px}.src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-Block-_Block__title{color:#262626;font-family:'Open Sans';font-size:14px;font-weight:600;line-height:20px}.src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-Block-_Block__fields{display:block}.src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-Block-_Block__field{display:block}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/EditPlannedBudgetPage/BudgetList/BudgetCard/Brief/Block/Block.scss"],"names":[],"mappings":"AAAA,gGACI,YAAa,CAChB,kGAGG,YAAa,CAEb,kBAAmB,CACtB,kGAGG,iBAAkB,CACrB,iGAGG,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACpB,kGAGG,aAAc,CACjB,iGAGG,aAAc","sourcesContent":[".root {\n    padding: 16px;\n}\n\n.header {\n    display: flex;\n\n    align-items: center;\n}\n\n.toggle {\n    margin-right: 10px;\n}\n\n.title {\n    color: #262626;\n\n    font-family: 'Open Sans';\n    font-size: 14px;\n    font-weight: 600;\n    line-height: 20px;\n}\n\n.fields {\n    display: block;\n}\n\n.field {\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-Block-_Block__root",
	"header": "src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-Block-_Block__header",
	"toggle": "src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-Block-_Block__toggle",
	"title": "src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-Block-_Block__title",
	"fields": "src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-Block-_Block__fields",
	"field": "src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-Block-_Block__field"
};
export default ___CSS_LOADER_EXPORT___;
