// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-Card-CardAuthor-_CardAuthor__root{width:100%;text-align:right;font-family:\"Open Sans\"}.src-client-modules-common-Card-CardAuthor-_CardAuthor__title{letter-spacing:0.5px;color:#000000;font-size:12px}.src-client-modules-common-Card-CardAuthor-_CardAuthor__content{overflow:hidden;white-space:nowrap;letter-spacing:0.4px;text-overflow:ellipsis;color:#7e8681;font-size:10px}.src-client-modules-common-Card-CardAuthor-_CardAuthor__loading{width:100px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/Card/CardAuthor/CardAuthor.scss"],"names":[],"mappings":"AAAA,6DACI,UAAW,CAEX,gBAAiB,CAEjB,uBAAwB,CAC3B,8DAGG,oBAAqB,CAErB,aAAc,CAEd,cAAe,CAClB,gEAGG,eAAgB,CAEhB,kBAAmB,CACnB,oBAAqB,CACrB,sBAAuB,CAEvB,aAAc,CAEd,cAAe,CAClB,gEAGG,WAAY","sourcesContent":[".root {\n    width: 100%;\n\n    text-align: right;\n\n    font-family: \"Open Sans\";\n}\n\n.title {\n    letter-spacing: 0.5px;\n\n    color: #000000;\n\n    font-size: 12px;\n}\n\n.content {\n    overflow: hidden;\n\n    white-space: nowrap;\n    letter-spacing: 0.4px;\n    text-overflow: ellipsis;\n\n    color: #7e8681;\n\n    font-size: 10px;\n}\n\n.loading {\n    width: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-Card-CardAuthor-_CardAuthor__root",
	"title": "src-client-modules-common-Card-CardAuthor-_CardAuthor__title",
	"content": "src-client-modules-common-Card-CardAuthor-_CardAuthor__content",
	"loading": "src-client-modules-common-Card-CardAuthor-_CardAuthor__loading"
};
export default ___CSS_LOADER_EXPORT___;
