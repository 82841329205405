// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-task-components-tags-TaskDeadline-_TaskDeadline__root{align-items:center}.src-client-modules-task-components-tags-TaskDeadline-_TaskDeadline__wrapper{overflow:hidden;border-radius:6px;margin:-8px}.src-client-modules-task-components-tags-TaskDeadline-_TaskDeadline__root:not(.src-client-modules-task-components-tags-TaskDeadline-_TaskDeadline__danger) .src-client-modules-task-components-tags-TaskDeadline-_TaskDeadline__icon{color:var(--color-primary-50)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/task/components/tags/TaskDeadline/TaskDeadline.scss"],"names":[],"mappings":"AAAA,0EACE,kBAAmB,CACpB,6EAGC,eAAgB,CAChB,iBAAkB,CAClB,WAAY,CACb,qOAIG,6BAA8B","sourcesContent":[".root {\n  align-items: center;\n}\n\n.wrapper {\n  overflow: hidden;\n  border-radius: 6px;\n  margin: -8px;\n}\n\n.icon {\n  .root:not(.danger) & {\n    color: var(--color-primary-50);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-task-components-tags-TaskDeadline-_TaskDeadline__root",
	"wrapper": "src-client-modules-task-components-tags-TaskDeadline-_TaskDeadline__wrapper",
	"danger": "src-client-modules-task-components-tags-TaskDeadline-_TaskDeadline__danger",
	"icon": "src-client-modules-task-components-tags-TaskDeadline-_TaskDeadline__icon"
};
export default ___CSS_LOADER_EXPORT___;
