import { axios } from '../lib/axios';
import { GetPreviewPayload, GetPreviewResponse } from '@mrm/videoPreview';

const BASE_URL = `/api/video-preview`;

export class VideoPreviewApi {
    public static async getPreview(params: GetPreviewPayload): Promise<GetPreviewResponse> {
        return (await axios.post<GetPreviewResponse>(`${BASE_URL}/preview`, params)).data;
    }
}
