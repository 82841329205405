import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getActivityStageState } from '@store/activityTasksPage/stages';
import * as workTypesStore from '@store/workTypes';
import * as organizationsStore from '@store/organizations';
import * as usersStore from '@store/users';
import * as departmentsStore from '@store/departments';
import * as activityPageStore from '@store/activityTasksPage';

import { Props, MapProps, DispatchProps, TaskFiltersMenuContainer } from './TaskFiltersMenuContainer';

interface OwnProps {
    activityId: number;
    delayLoading: boolean;
}

const workTypesEntities = workTypesStore.StoreTypes.ACTIVITY_PAGE_FILTER;
const organizationsEntities = organizationsStore.StoreTypes.ACTIVITY_PAGE_FILTERS;
const executorsEntities = usersStore.StoreTypes.ACTIVITY_PAGE_EXECUTORS_FILTER;
const authorsEntities = usersStore.StoreTypes.ACTIVITY_PAGE_AUTHORS_FITLER;
const departmentsEntities = departmentsStore.StoreTypes.ACTIVITY_PAGE_FILTERS;

function mapStateToProps(state: StoreState, ownProps: OwnProps): MapProps {
    const { activityId } = ownProps;

    const filters = activityPageStore.getRawTaskFilters(state);

    const workTypesLoadingStatus = workTypesStore.getLoadingStatus(state, workTypesEntities);
    const organizationsLoadingStatus = organizationsStore.getLoadingStatus(state, organizationsEntities);
    const executorsLoadingStatus = usersStore.getLoadingStatus(state, executorsEntities);
    const authorsLoadingStatus = usersStore.getLoadingStatus(state, authorsEntities);
    const departmentsLoadingStatus = departmentsStore.getLoadingStatus(state, departmentsEntities);

    const isLoading =
        workTypesLoadingStatus !== LoadingStatus.LOADED ||
        executorsLoadingStatus !== LoadingStatus.LOADED ||
        authorsLoadingStatus !== LoadingStatus.LOADED ||
        organizationsLoadingStatus !== LoadingStatus.LOADED ||
        departmentsLoadingStatus !== LoadingStatus.LOADED;

    const workTypes = workTypesStore.getWorkTypes(state, workTypesEntities);
    const executors = usersStore.getUsers(state, executorsEntities);
    const authors = usersStore.getUsers(state, authorsEntities);
    const departments = departmentsStore.getDepartments(state, departmentsEntities);

    const tasksByStageId = activityPageStore.getActivityTasksPageState(state).activityTasks.byStageId;

    const { entities: activityStages } = getActivityStageState(state).existingStages;

    return {
        isLoading,
        filters,
        workTypes,
        authors,
        executors,
        departments,
        activityStages,
        tasksByStageId,
        loadFiltersParams: {
            activityId,
        },
    };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
    return {
        setFilters: (filters) => dispatch(activityPageStore.setRawTaskFilters(filters)),
        save: () => dispatch(activityPageStore.saveTaskFilters()),
        loadFiltersEntities: (filters) => {
            const { workTypes, executors, authors, departments } = filters;

            dispatch(
                workTypesStore.loadWorkTypes({
                    store: workTypesEntities,
                    ids: workTypes,
                }),
            );

            dispatch(
                organizationsStore.loadOrganizations({
                    store: organizationsEntities,
                }),
            );

            dispatch(
                usersStore.loadUsers({
                    store: executorsEntities,
                    ids: executors,
                }),
            );

            dispatch(
                usersStore.loadUsers({
                    store: authorsEntities,
                    ids: authors,
                }),
            );

            dispatch(
                departmentsStore.loadDepartments({
                    store: departmentsEntities,
                    ids: departments,
                }),
            );
        },
        resetEntitiesStores: () => {
            dispatch(workTypesStore.resetStore(workTypesEntities));
            dispatch(usersStore.resetStore(executorsEntities));
            dispatch(usersStore.resetStore(authorsEntities));
            dispatch(departmentsStore.resetStore(departmentsEntities));
        },
    };
}

function mergeProps(mapProps: MapProps, dispatchProps: DispatchProps, ownProps: OwnProps): Props {
    return {
        ...mapProps,
        ...dispatchProps,
        delayLoading: ownProps.delayLoading,
    };
}

export const ActivityPageTaskFilterMenu = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
)(TaskFiltersMenuContainer);
