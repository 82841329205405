import * as React from 'react';
import * as lodash from 'lodash';
import autobind from 'autobind-decorator';

import { MrmClient } from '@api';
import {
    CreativeRequestDomain,
    CreativeRequestParamDomain,
    Dictionary,
    DictionaryType,
    Param,
    ParamType,
    FieldType,
} from './types';

import { convertCreativeRequestDomain } from './convertCreativeRequestDomain';
import { convertCreativeRequestParamDomain } from './convertCreativeRequestParamDomin';

interface Props extends ExternalProps {
    creativeRequestId: string;
}

interface ExternalProps {
    children: (props: ChildrenProps) => JSX.Element;
}

export interface ChildrenProps {
    loading: boolean;
    params: Param[];
}

interface State {
    loading: boolean;
    params: Param[];
}

export class WithValidationCreativeRequestParams extends React.Component<Props, State> {
    private dictionaries: Dictionary[] = [];
    private creativeRequestDomain: CreativeRequestDomain;
    private creativeRequireParamDomains: CreativeRequestParamDomain[];

    constructor(props: Props) {
        super(props);

        this.state = {
            loading: true,
            params: [],
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.init();
        this.setState({
            loading: false,
            params: await this.getParams(),
        });
    }

    public render(): JSX.Element {
        return this.props.children(this.getChildrenProps());
    }

    private getChildrenProps(): ChildrenProps {
        const { loading, params } = this.state;
        return {
            loading,
            params,
        };
    }

    private async init(): Promise<void> {
        await this.load();
        this.subscribe();
    }

    private async load(): Promise<void> {
        await this.loadCreativeRequestDomain();
        await Promise.all([this.loadDictionary(), this.loadCreativeRequestParamsDomains()]);
    }

    private subscribe(): void {
        this.subscribeOnCreativeRequestDomainChange();
        this.subscribeOnCreativeRequestParamDomainsChange();
    }

    private unsubscribe(): void {
        this.unsubscribeOnCreativeRequestDomainChange();
        this.unsubscribeOnCreativeRequestParamDomainsChange();
    }

    private async loadDictionary(): Promise<void> {
        const client = await MrmClient.getInstance();

        this.dictionaries = lodash.flatten(
            await Promise.all([
                client.Dictionary.getByType(DictionaryType.Block),
                client.Dictionary.getByType(DictionaryType.Division),
                client.Dictionary.getByType(DictionaryType.Product),
            ]),
        );
    }

    private async loadCreativeRequestDomain(): Promise<void> {
        const { creativeRequestId } = this.props;
        const client = await MrmClient.getInstance();
        this.creativeRequestDomain = await client.domain.creativeRequests.getCreativeRequest({ id: creativeRequestId });
    }

    private async loadCreativeRequestParamsDomains(): Promise<void> {
        this.creativeRequireParamDomains = lodash.sortBy(
            await this.creativeRequestDomain.model.getParams(),
            'model.createdAt',
        );
    }

    private subscribeOnCreativeRequestDomainChange(): void {
        (this.creativeRequestDomain as any).events.onContractIdUpdated(this.onContractIdUpdatedHandler);

        this.creativeRequestDomain.events.onParamsAdded(this.onParamsAddedHandler);
        this.creativeRequestDomain.events.onParamsRemoved(this.onParamsRemovedHandler);

        this.creativeRequestDomain.events.onReloaded(this.onReloadedHandler);
    }

    private unsubscribeOnCreativeRequestDomainChange(): void {
        (this.creativeRequestDomain as any).events.offContractIdUpdated(this.onContractIdUpdatedHandler);

        this.creativeRequestDomain.events.offParamsAdded(this.onParamsAddedHandler);
        this.creativeRequestDomain.events.offParamsRemoved(this.onParamsRemovedHandler);

        this.creativeRequestDomain.events.offReloaded(this.onReloadedHandler);
    }

    private subscribeOnCreativeRequestParamDomainsChange(): void {
        this.creativeRequireParamDomains.map((creativeRequestParamDomain) =>
            this.subscribeOnCreativeRequestParamDomainChange(creativeRequestParamDomain),
        );
    }

    private unsubscribeOnCreativeRequestParamDomainsChange(): void {
        this.creativeRequireParamDomains.map((creativeRequestParamDomain) =>
            this.unsubscribeOnCreativeRequestParamDomainChange(creativeRequestParamDomain),
        );
    }

    private subscribeOnCreativeRequestParamDomainChange({ events }: CreativeRequestParamDomain): void {
        events.onBlockUpdated(this.onCreativeRequestParamDomainChangeHandler);
        events.onDivisionUpdated(this.onCreativeRequestParamDomainChangeHandler);
        events.onSegmentUpdated(this.onCreativeRequestParamDomainChangeHandler);
        events.onProductUpdated(this.onCreativeRequestParamDomainChangeHandler);
        events.onChannelUpdated(this.onCreativeRequestParamDomainChangeHandler);
    }

    private unsubscribeOnCreativeRequestParamDomainChange({ events }: CreativeRequestParamDomain): void {
        events.offBlockUpdated(this.onCreativeRequestParamDomainChangeHandler);
        events.offDivisionUpdated(this.onCreativeRequestParamDomainChangeHandler);
        events.offSegmentUpdated(this.onCreativeRequestParamDomainChangeHandler);
        events.offProductUpdated(this.onCreativeRequestParamDomainChangeHandler);
        events.offChannelUpdated(this.onCreativeRequestParamDomainChangeHandler);
    }

    @autobind
    private async onContractIdUpdatedHandler() {
        this.setState({ params: await this.getParams() });
    }

    @autobind
    private async onParamsAddedHandler(addedCreativeRequestParamDomain: CreativeRequestParamDomain): Promise<void> {
        this.subscribeOnCreativeRequestParamDomainChange(addedCreativeRequestParamDomain);
        this.addCreativeRequestParamDomain(addedCreativeRequestParamDomain);
        this.setState({
            params: [
                ...this.state.params,
                await this.getParamFromCreativeRequestParams(addedCreativeRequestParamDomain),
            ],
        });
    }

    @autobind
    private async onParamsRemovedHandler(removedCreativeRequestParam: CreativeRequestParamDomain): Promise<void> {
        this.removeCreativeRequestParamDomain(removedCreativeRequestParam);
        this.setState({ params: this.state.params.filter(({ id }) => id !== removedCreativeRequestParam.model.id) });
    }

    @autobind
    private async onReloadedHandler(): Promise<void> {
        this.unsubscribe();
        await this.updateDomains();
        this.subscribe();

        this.setState({ params: await this.getParams() });
    }

    @autobind
    private async onCreativeRequestParamDomainChangeHandler(): Promise<void> {
        this.setState({ params: await this.getParams() });
    }

    private async updateDomains(): Promise<void> {
        await this.loadCreativeRequestDomain();
        await this.loadCreativeRequestParamsDomains();
    }

    private addCreativeRequestParamDomain(addedCreativeRequestParamDomain: CreativeRequestParamDomain): void {
        this.creativeRequireParamDomains = [...this.creativeRequireParamDomains, addedCreativeRequestParamDomain];
    }

    private removeCreativeRequestParamDomain(removedCreativeRequestParamDomain: CreativeRequestParamDomain): void {
        this.creativeRequireParamDomains = this.creativeRequireParamDomains.filter(
            ({ model }) => model.id !== removedCreativeRequestParamDomain.model.id,
        );
    }

    private async getParams(): Promise<Param[]> {
        return lodash.flatten(
            await Promise.all([this.getParamsFromCreativeRequest(), this.getParamsFromCreativeRequestParams()]),
        );
    }

    private async getParamsFromCreativeRequest(): Promise<Param[]> {
        return [await convertCreativeRequestDomain(this.creativeRequestDomain)];
    }

    private async getParamsFromCreativeRequestParams(): Promise<Param[]> {
        return await Promise.all(
            this.creativeRequireParamDomains.map(async (creativeRequireParamDomains) =>
                this.getParamFromCreativeRequestParams(creativeRequireParamDomains),
            ),
        );
    }

    private async getParamFromCreativeRequestParams(
        creativeRequireParamDomains: CreativeRequestParamDomain,
    ): Promise<Param> {
        return await convertCreativeRequestParamDomain(creativeRequireParamDomains, {
            dictionaries: lodash.groupBy(this.dictionaries, 'type') as Record<DictionaryType, Dictionary[]>,
        });
    }
}

export type { FieldType, Param, ParamType };
