import * as React from 'react';
import { IconType } from 'sber-marketing-ui';

import { NotificationBar, Color } from './NotificationBar';

interface Props {
    text: React.ReactNode;
}

export function ErrorNotificationBar({ text }: Props): React.ReactElement {
    return <NotificationBar icon={IconType.WARNING_IN_CIRCLE} color={Color.Red} text={text} />;
}
