import * as React from 'react';

import * as style from './DoubleTextColumnHeader.scss';

interface Props {
    title: [string, string];
}

export const DoubleTextColumnHeader = ({ title }: Props): JSX.Element => {
    const [title1, title2] = title;

    return (
        <div className={style.root}>
            <div className={style.titleWrapper}>
                <div className={style.title}>{title1}</div>
            </div>

            <div className={style.titleWrapper}>
                <div className={style.title}>{title2}</div>
            </div>
        </div>
    );
};
