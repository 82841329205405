import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { cloneDeep } from 'lodash';

import * as actions from './actions/sync';

import {
    BudgetCorrectionsFiltersAndExportState as State,
    TargetFilter,
    FilterItems,
    FilterValues,
    SetFilterValuePayload,
    ExportInProgressState,
} from './types';

class Reducer {
    public static makeInitialState(): State {
        return {
            exportInProgressState: ExportInProgressState.None,
            items: {
                [TargetFilter.Status]: [],
                [TargetFilter.Directions]: [],
                [TargetFilter.Authors]: [],
                [TargetFilter.Approvers]: [],
                [TargetFilter.Types]: [],
                [TargetFilter.ActivityNames]: [],
                [TargetFilter.BudgetItemNames]: [],
                [TargetFilter.Blocks]: [],
                [TargetFilter.ActivityTypes]: [],
                [TargetFilter.Tools]: [],
                [TargetFilter.ExecutionIds]: [],
                [TargetFilter.Regionalities]: [],
                [TargetFilter.Territories]: [],
                [TargetFilter.Divisions]: [],
                [TargetFilter.CostCenter]: [],
            },
            currentFilters: Reducer.makeInitialFilters(),
            savedFilters: Reducer.makeInitialFilters(),
        };
    }

    public static setExportInProgressState(state: State, exportInProgressState: ExportInProgressState): State {
        return { ...state, exportInProgressState };
    }

    public static moveCurrentFiltersToSaved(state: State): State {
        return {
            ...state,
            savedFilters: cloneDeep(state.currentFilters),
        };
    }

    public static moveSavedFiltersToCurrent(state: State): State {
        return {
            ...state,
            currentFilters: cloneDeep(state.savedFilters),
        };
    }

    public static resetFilterValues(state: State): State {
        const initialState = Reducer.makeInitialState();

        return {
            ...state,
            currentFilters: cloneDeep(initialState.currentFilters),
            savedFilters: cloneDeep(initialState.savedFilters),
        };
    }

    public static setFilterValue(state: State, payload: SetFilterValuePayload): State {
        const { filter, value } = payload;

        return Reducer.currentFiltersReducer(state, { [filter]: value });
    }

    public static setIsExportModeEnabled(state: State, isExportModeEnabled: boolean): State {
        return Reducer.currentFiltersReducer(state, { isExportModeEnabled });
    }

    public static setDateRangeStart(state: State, dateRangeStart: Date): State {
        return Reducer.currentFiltersReducer(state, { dateRangeStart });
    }

    public static setDateRangeEnd(state: State, dateRangeEnd: Date): State {
        return Reducer.currentFiltersReducer(state, { dateRangeEnd });
    }

    public static itemsReducer(state: State, items: FilterItems): State {
        return { ...state, items };
    }

    public static currentFiltersReducer(state: State, currentFilters: Partial<FilterValues>): State {
        return {
            ...state,
            currentFilters: { ...state.currentFilters, ...currentFilters },
        };
    }

    public static savedFiltersReducer(state: State, savedFilters: Partial<FilterValues>): State {
        return {
            ...state,
            savedFilters: { ...state.savedFilters, ...savedFilters },
        };
    }

    private static makeInitialFilters(): FilterValues {
        return {
            [TargetFilter.Status]: [],
            [TargetFilter.Directions]: [],
            [TargetFilter.Authors]: [],
            [TargetFilter.Approvers]: [],
            [TargetFilter.Types]: [],
            [TargetFilter.ActivityNames]: [],
            [TargetFilter.BudgetItemNames]: [],
            [TargetFilter.Blocks]: [],
            [TargetFilter.ActivityTypes]: [],
            [TargetFilter.Tools]: [],
            [TargetFilter.ExecutionIds]: [],
            [TargetFilter.Regionalities]: [],
            [TargetFilter.Territories]: [],
            [TargetFilter.Divisions]: [],
            [TargetFilter.CostCenter]: [],
            isExportModeEnabled: false,
            dateRangeStart: null,
            dateRangeEnd: null,
        };
    }
}

export const budgetCorrectionsFiltersAndExportReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(actions.setExportInProgressState, Reducer.setExportInProgressState)
    .case(actions.setFilterItems, Reducer.itemsReducer)
    .case(actions.setCurrentFilters, Reducer.currentFiltersReducer)
    .case(actions.setSavedFilters, Reducer.savedFiltersReducer)
    .case(actions.moveCurrentFiltersToSaved, Reducer.moveCurrentFiltersToSaved)
    .case(actions.moveSavedFiltersToCurrent, Reducer.moveSavedFiltersToCurrent)
    .case(actions.resetFilterValues, Reducer.resetFilterValues)
    .case(actions.setFilterValue, Reducer.setFilterValue)
    .case(actions.setIsExportModeEnabled, Reducer.setIsExportModeEnabled)
    .case(actions.setDateRangeStart, Reducer.setDateRangeStart)
    .case(actions.setDateRangeEnd, Reducer.setDateRangeEnd)
    .case(actions.resetComponentState, Reducer.makeInitialState);
