import * as lodash from 'lodash';

import type { BriefStageForm, MediaplanItem } from '@store/autopilotTv/types';
import type { MediaplanPlanDataTVTotalResponse as MediaplanPlanDataTVTotal } from 'sber-marketing-types/backend';

export const enum ColumnHeaderType {
    Text = 'text',
}

export const enum LineType {
    Line = 'line',
    Total = 'total',
}

export const enum ColumnName {
    Region = 'region',
    TargetAudience = 'targetAudience',
    Reach1 = 'reach1',
    Reach1Percent = 'reach1Percent',
    Reach7 = 'reach7',
    Reach7Percent = 'reach7Percent',
    Affinity = 'affinity',
    Frequency = 'frequency',
    OST = 'oST',
    TRP = 'tRP',
    TCPP = 'tCPP',
    TCPRP = 'tCPRP',
    Prediction = 'prediction',
    KpiCost = 'kpiCost',
    BudgetWithoutMinuteChannels = 'budgetWithoutMinuteChannels',
    BudgetWithMinuteChannels = 'budgetWithMinuteChannels',
}

export const enum CellType {
    Text = 'text',
    Input = 'input',
    FundsInput = 'fundsInput',
}

export interface AccessorParams {
    id?: string;
    line?: MediaplanPlanDataTVTotal;
    briefForm?: BriefStageForm;
    mediaplanItems?: MediaplanItem[];
}

export type Accessor<T> = (params: AccessorParams) => T;
export type AccessorWithValue<T> = (params: AccessorParams, value: any) => T;

export type ValueAccessor = Accessor<any>;
export type TitleAccessor = Accessor<React.ReactText>;
export type ValueSetter = AccessorWithValue<void>;
export type ItemsAccessor = Accessor<any[]>;
export type DescriptionAccessor = Accessor<string>;
export type CustomStyleAccessor = Accessor<React.CSSProperties>;

export interface ColumnParams {
    title: string | [string, string] | Accessor<string | [string, string]>;
    headerType: ColumnHeaderType;
    type: Record<LineType, CellType>;
    defaultWidth: number;
    disableWidthChange?: boolean;
    readOnly?: Partial<Record<LineType, boolean>>;
    customStyle?: Partial<Record<LineType, CustomStyleAccessor>>;
    linkedColumns?: Partial<Record<LineType, ColumnName[]>>;
    getTitle?: Partial<Record<LineType, TitleAccessor>>;
    getValue: Record<LineType, ValueAccessor>;
    setValue?: Partial<Record<LineType, ValueSetter>>;
    getItems?: Partial<Record<LineType, ItemsAccessor>>;
    getDescription?: Partial<Record<LineType, DescriptionAccessor>>;
}

export interface ColumnsConfigParams {
    displayPrediction: boolean;
}

export const MakeColumnsConfig: (columnsConfigParams: ColumnsConfigParams) => { [columnName: string]: ColumnParams } = (
    columnsConfigParams: ColumnsConfigParams,
) => ({
    [ColumnName.Region]: {
        title: 'Регион',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: null,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => 'Вся Россия',
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.TargetAudience]: {
        title: 'Целевая аудитория',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: null,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.briefForm.targetGroup.name,
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Reach1]: {
        title: 'Reach 1+, тыс.',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: null,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.line?.reach_1,
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Reach1Percent]: {
        title: 'Reach 1+, %',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: null,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.line?.reach0_1,
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Reach7]: {
        title: 'Reach 7+, тыс.',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: null,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.line?.reach_n,
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Reach7Percent]: {
        title: 'Reach 7+, %',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: null,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.line?.reach0_n,
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Affinity]: {
        title: 'Affinity',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: null,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => {
                const totalTrp = lodash.sumBy(params.mediaplanItems, (line) =>
                    lodash.sumBy(line.intervals, (item) => item.properties.trp),
                );

                const totalGrp = lodash.sumBy(params.mediaplanItems, (line) =>
                    lodash.sumBy(line.intervals, (item) => item.properties.grps),
                );

                return Math.round((totalTrp / totalGrp) * 100);
            },
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Frequency]: {
        title: 'Freq',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: null,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.line?.freq,
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.OST]: {
        title: 'OTS, тыс.',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: null,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.line?.OTS,
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.TRP]: {
        title: 'TRP',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: null,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) =>
                roundNumber(lodash.sumBy(params.mediaplanItems, (line) => line.totalInterval.properties.trp)),
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.TCPP]: {
        title: 'TCPP',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.FundsInput,
            [LineType.Total]: null,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => {
                const totalBudget = lodash.sumBy(params.mediaplanItems, (line) => line.totalInterval.properties.budget);

                const trp = Math.round(
                    lodash.sumBy(params.mediaplanItems, (line) => line.totalInterval.properties.trp),
                );

                return roundNumber(totalBudget / trp);
            },
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.TCPRP]: {
        title: 'TCPRP',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.FundsInput,
            [LineType.Total]: null,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => {
                const totalBudget = lodash.sumBy(params.mediaplanItems, (line) => line.totalInterval.properties.budget);

                const reach1Percent = params.line.reach0_1;

                return totalBudget / reach1Percent;
            },
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.BudgetWithoutMinuteChannels]: {
        title: 'Бюджет до НДС без мин. каналов',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.FundsInput,
            [LineType.Total]: null,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) =>
                lodash.sumBy(params.mediaplanItems, (line) => line.totalInterval.properties.budget),
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Prediction]: {
        title: (params: AccessorParams) => params.line.prediction.kpi,
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: null,
        },
        defaultWidth: 200,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => Math.round(params.line.prediction.value),
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.KpiCost]: {
        title: 'Стоимость за KPI',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.FundsInput,
            [LineType.Total]: null,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => {
                const { prediction } = params.line;

                const budgetSum = lodash.sumBy(params.mediaplanItems, (line) => line.totalInterval.properties.budget);

                return prediction.value !== 0 ? Math.round(budgetSum / prediction.value) : 0;
            },
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.BudgetWithMinuteChannels]: {
        title: 'Бюджет до НДС с мин. каналами',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.FundsInput,
            [LineType.Total]: null,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) =>
                lodash.sumBy(params.mediaplanItems, (line) => line.totalInterval.properties.budget),
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
});

export const MakeTableColumns: (columnsConfigParams: ColumnsConfigParams) => string[] = (columnsConfigParams) => [
    ColumnName.TargetAudience,
    ColumnName.Reach1,
    ColumnName.Reach1Percent,
    ColumnName.Reach7,
    ColumnName.Reach7Percent,
    ColumnName.Affinity,
    ColumnName.Frequency,
    ColumnName.OST,
    ColumnName.TRP,
    ColumnName.TCPP,
    ColumnName.TCPRP,
    ...(columnsConfigParams.displayPrediction ? [ColumnName.Prediction, ColumnName.KpiCost] : []),
    ColumnName.BudgetWithoutMinuteChannels,
    ColumnName.BudgetWithMinuteChannels,
];

export const leftFixedColumns: ColumnName[] = [ColumnName.Region];

export const rightFixedColumns: ColumnName[] = [];

function roundNumber(value: number, digitsAfterComma = 2): string {
    if (value === undefined) {
        return null;
    }

    const formatedValue = value.toFixed(digitsAfterComma);

    const [decimalPart, fractionPart] = formatedValue.split('.');

    return `${decimalPart}${fractionPart ? `.${fractionPart}` : ''}`;
}
