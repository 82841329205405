// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-ActivitySidebar-tabs-BudgetsTab-BudgetItemLinkCard-StatusBar-_StatusBar__root{margin-bottom:-2px;padding:2px 6px;white-space:pre;letter-spacing:0.3px;border-radius:3px;font-size:12px;line-height:16px}.src-client-modules-common-ActivitySidebar-tabs-BudgetsTab-BudgetItemLinkCard-StatusBar-_StatusBar__rootGrey{color:#878e8a;background:#ebeeec}.src-client-modules-common-ActivitySidebar-tabs-BudgetsTab-BudgetItemLinkCard-StatusBar-_StatusBar__rootGreen{color:#18b84e;background:#e9fff0}.src-client-modules-common-ActivitySidebar-tabs-BudgetsTab-BudgetItemLinkCard-StatusBar-_StatusBar__rootRed{color:#e63900;background:#ffebee}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/ActivitySidebar/tabs/BudgetsTab/BudgetItemLinkCard/StatusBar/StatusBar.scss"],"names":[],"mappings":"AAAA,yGACI,kBAAmB,CACnB,eAAgB,CAEhB,eAAgB,CAChB,oBAAqB,CAErB,iBAAkB,CAElB,cAAe,CACf,gBAAiB,CACpB,6GAGG,aAAc,CACd,kBAAmB,CACtB,8GAGG,aAAc,CACd,kBAAmB,CACtB,4GAGG,aAAc,CACd,kBAAmB","sourcesContent":[".root {\n    margin-bottom: -2px;\n    padding: 2px 6px;\n\n    white-space: pre;\n    letter-spacing: 0.3px;\n\n    border-radius: 3px;\n\n    font-size: 12px;\n    line-height: 16px;\n}\n\n.rootGrey {\n    color: #878e8a;\n    background: #ebeeec;\n}\n\n.rootGreen {\n    color: #18b84e;\n    background: #e9fff0;\n}\n\n.rootRed {\n    color: #e63900;\n    background: #ffebee;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-ActivitySidebar-tabs-BudgetsTab-BudgetItemLinkCard-StatusBar-_StatusBar__root",
	"rootGrey": "src-client-modules-common-ActivitySidebar-tabs-BudgetsTab-BudgetItemLinkCard-StatusBar-_StatusBar__rootGrey",
	"rootGreen": "src-client-modules-common-ActivitySidebar-tabs-BudgetsTab-BudgetItemLinkCard-StatusBar-_StatusBar__rootGreen",
	"rootRed": "src-client-modules-common-ActivitySidebar-tabs-BudgetsTab-BudgetItemLinkCard-StatusBar-_StatusBar__rootRed"
};
export default ___CSS_LOADER_EXPORT___;
