import * as React from 'react';

import { Icon, IconType } from 'sber-marketing-ui';

import { ChipProps, Chip } from '@common/components';

export type IconChipProps<E extends keyof JSX.IntrinsicElements = 'div'> = ChipProps<E> & {
    icon: string | IconType;
    iconSize?: number;
};

export function IconChip<E extends keyof JSX.IntrinsicElements = 'div'>({
    icon,
    iconSize,
    style,
    children,
    ...props
}: IconChipProps<E>) {
    const content = Object.values(IconType).includes(icon as IconType) ? (
        <Icon svgSize={iconSize} type={icon as IconType} />
    ) : (
        <span>{icon}</span>
    );

    return (
        <Chip gap={6} {...(props as any)} style={{ fontSize: iconSize, ...style }}>
            {content}
            {children}
        </Chip>
    );
}
