import * as React from 'react';

import { Icon, IconType } from 'sber-marketing-ui';
import { CreationFormContext } from '../../CreationFormContext';

import * as styles from './styles.scss';

interface Props {}

export const BackButton: React.FC<Props> = () => {
    const { close } = React.useContext(CreationFormContext);

    return (
        <div className={styles.root}>
            <div className={styles.icon} onClick={() => close()}>
                <Icon type={IconType.ARROW_RIGHT} />
            </div>
            <div className={styles.text}>Параметры для генерации</div>
        </div>
    );
};
