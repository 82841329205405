import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { AxiosError } from 'axios';

import { StoreState } from '../..';
import { RemoveCommentaryPayload, FetchError } from '../types';
import { asyncActions } from '../actions';
import { TaskApi } from '../../../api/TaskApi';

export const removeCommentary = bindThunkAction<StoreState, RemoveCommentaryPayload, void, FetchError>(
    asyncActions.removeCommentary,
    async ({ id }: RemoveCommentaryPayload, _, getState): Promise<void> => {
        const {
            taskPage: { id: taskId },
        } = getState();

        try {
            await TaskApi.deleteComment(taskId, id);
        } catch (error) {
            const { response, message } = <AxiosError>error;
            if (response) {
                throw new FetchError(response.status, response.statusText, message);
            } else {
                throw new FetchError(0, 'Unknown', message);
            }
        }
    },
);
