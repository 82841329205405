// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-ActivityCard-Title-_Title__flagTogglePosition{margin:0 4px 0 -3px}.src-client-modules-common-ActivityCard-Title-_Title__text{overflow:hidden;white-space:nowrap;text-overflow:ellipsis}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/ActivityCard/Title/Title.scss"],"names":[],"mappings":"AAAA,yEACI,mBAAoB,CACvB,2DAGG,eAAgB,CAEhB,kBAAmB,CACnB,sBAAuB","sourcesContent":[".flagTogglePosition {\n    margin: 0 4px 0 -3px;\n}\n\n.text {\n    overflow: hidden;\n\n    white-space: nowrap;\n    text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flagTogglePosition": "src-client-modules-common-ActivityCard-Title-_Title__flagTogglePosition",
	"text": "src-client-modules-common-ActivityCard-Title-_Title__text"
};
export default ___CSS_LOADER_EXPORT___;
