import * as React from 'react';
import classNames from 'classnames';

import { ColumnName } from '@store/budgetExecution';

import { CellBackgroundColor, CellBorderColor } from '../../LayerManager';
import { getQaCellColor, getQaCellBorder } from '../misc';

import * as style from './SelectableCell.scss';

export interface CellColors {
    background: CellBackgroundColor;
    border: CellBorderColor;
}

interface Props {
    columnName: ColumnName;
    title: React.ReactText;
    tooltip?: string;
    isClickable: boolean;
    isSelected: boolean;
    isHovered: boolean;
    cellColor: CellColors;
    disabled: boolean;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
    onCopy: (event: React.ClipboardEvent<HTMLDivElement>) => void;
}

export const SelectableCell = ({
    columnName,
    title,
    tooltip,
    isClickable,
    isSelected,
    isHovered,
    cellColor,
    disabled,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onCopy,
}: Props): JSX.Element => {
    const valueInRubles = parseFloat((tooltip || '0').replace(/ /g, ''));
    let styles = {};
    let styles2 = {};
    if (cellColor) {
        styles = {
            backgroundColor: cellColor ? cellColor.background : null,
            borderColor: isSelected ? cellColor.border : isHovered ? cellColor.background : null,
        };
        styles2 = { backgroundColor: isHovered ? cellColor.background : null };
    }

    return (
        <div
            title={tooltip}
            className={classNames(style.root, disabled && !isSelected && style.disabled)}
            style={styles}
            onClick={isClickable ? onClick : null}
            onMouseEnter={isClickable ? onMouseEnter : null}
            onMouseLeave={isClickable ? onMouseLeave : null}
            {...{
                'qa-id': 'tableCell',
                'qa-index': columnName,
                'qa-disabled': disabled ? 'true' : 'false',
                ...getQaCellColor(cellColor?.background),
                ...getQaCellBorder(cellColor?.border),
            }}
            onCopy={onCopy}
        >
            <div
                className={style.title}
                style={styles2}
                {...{
                    'qa-id': 'tableCellContent',
                }}
            >
                {title || '–'}
            </div>

            <div className={style.valueInRubles}>{valueInRubles}</div>

            <div className={style.rubleSign}>₽</div>
        </div>
    );
};
