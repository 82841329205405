import { actionCreatorFactory } from 'typescript-fsa';

import { PageData, Filters, Period } from './types';

const actionCreator = actionCreatorFactory('EXECUTION_CALENDAR_PAGE');

const loadPageData = actionCreator<PageData>('LOAD_PAGE_DATA');
const resetPageState = actionCreator('RESET_PAGE_STATE');
const setFilters = actionCreator<Filters>('SET_FILTERS');
const setPeriod = actionCreator<Period>('SET_PERIOD');
const setExpandedGroupsIds = actionCreator<string[]>('SET_EXPANDED_GROUPS_IDS');
const setExpandedActivitiesIds = actionCreator<string[]>('SET_EXPANDED_ACTIVITIES_IDS');
const setHoveredItemId = actionCreator<string | number>('SET_HOVERED_ITEM_ID');

export {
    loadPageData,
    resetPageState,
    setFilters,
    setPeriod,
    setExpandedGroupsIds,
    setExpandedActivitiesIds,
    setHoveredItemId,
};
