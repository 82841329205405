import * as React from 'react';

import { TagsFilter } from './TagsFilter';
import { YearsFilter } from './YearsFilter';
import { TagGroupFilter } from './TagGroupFilter';

import * as styles from './Filters.scss';

export function Filters(): JSX.Element {
    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'tagsPageFilters',
            }}
        >
            <div className={styles.filter}>
                <TagsFilter />
            </div>

            <div className={styles.filter}>
                <YearsFilter />
            </div>

            <TagGroupFilter />
        </div>
    );
}
