import * as React from 'react';
import { useSelector } from 'react-redux';
import { CustomScrollbar_new as CustomScrollbar, ScrollbarComponent } from 'sber-marketing-ui';
import classnames from 'classnames';

import { StoreState } from '@store';
import {
    CellPosition,
    InternalTransferDirection,
    getBudgetTransferMenuState,
    isCellSelected,
    isLineDonor,
    isCellAcceptor,
} from '@store/budgetExecution/budgetTransferMenu';

import { LinesTransferDirectionToggle } from '../TransferDirectionToggle';
import { useStyleContext } from '../../BudgetTransferMenu';
import {
    CellDescription,
    CellDescriptionTemplates,
    CellDescriptionThemes,
    CellDescriptionStub,
    CellDescriptionStubThemes,
} from '../../CellDescription';
import { Lines } from './Lines';

import * as styles from './LinesDescription.scss';

const SCROLLBAR_MAX_HEIGHT = 234; // 5 rows + stub

function useStore() {
    const from = useSelector((state: StoreState) => getBudgetTransferMenuState(state).cells.from);
    const to = useSelector((state: StoreState) => getBudgetTransferMenuState(state).cells.to);
    const internalTransferDirection = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.internalTransferDirection,
    );
    const scrollToTop = useSelector((state: StoreState) => {
        const {
            cells: { hoveredCell },
        } = getBudgetTransferMenuState(state);

        let cellsToCheck: CellPosition[];
        switch (internalTransferDirection) {
            case InternalTransferDirection.OneToMany:
                cellsToCheck = to;
                break;
            case InternalTransferDirection.ManyToOne:
                cellsToCheck = from;
                break;
            default:
                cellsToCheck = [];
                break;
        }

        return !!hoveredCell.lineId && !cellsToCheck.some((cell) => hoveredCell.lineId === cell.lineId);
    });

    const [scrollbarRef, setScrollbarRef] = React.useState<ScrollbarComponent>();

    let shouldRender: boolean;
    let linesCount: number;
    switch (internalTransferDirection) {
        case InternalTransferDirection.OneToMany:
            shouldRender = isCellSelected(from);
            linesCount = to.length;
            break;
        case InternalTransferDirection.ManyToOne:
            shouldRender = isCellSelected(to);
            linesCount = from.length;
            break;
        default:
            shouldRender = false;
            linesCount = 0;
    }

    function onScrollbarRef(ref: ScrollbarComponent) {
        setScrollbarRef(ref);
    }

    React.useEffect(() => {
        if (scrollbarRef) {
            scrollbarRef.scrollToTop();
        }
    }, [scrollToTop]);

    return {
        shouldRender,
        linesCount,
        onScrollbarRef,
    };
}

export function LinesDescription(): JSX.Element {
    const paddingClass = useStyleContext();
    const { shouldRender, linesCount, onScrollbarRef } = useStore();

    return shouldRender ? (
        <div className={styles.root}>
            <div className={classnames(styles.titleRow, paddingClass)}>
                <LinesTransferDirectionToggle linesCount={linesCount} />
            </div>

            <CustomScrollbar hideScrollX maxHeight={SCROLLBAR_MAX_HEIGHT} scrollbarRef={onScrollbarRef}>
                <div className={paddingClass}>
                    <CellStub />

                    <HoveredLine />

                    <Lines itemClass={styles.cellDescription} />
                </div>
            </CustomScrollbar>
        </div>
    ) : null;
}

function useCellStubStore() {
    const hoveredCell = useSelector((state: StoreState) => getBudgetTransferMenuState(state).cells.hoveredCell);
    const internalTransferDirection = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.internalTransferDirection,
    );
    const isHoveredLineClickable = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.isHoveredLineClickable,
    );

    const from = useSelector((state: StoreState) => getBudgetTransferMenuState(state).cells.from);
    const isHoveredLineDonor = useSelector((state: StoreState) =>
        hoveredCell.lineId ? isLineDonor(state, hoveredCell.lineId) : false,
    );

    const to = useSelector((state: StoreState) => getBudgetTransferMenuState(state).cells.to);
    const isHoveredLineAcceptor = useSelector((state: StoreState) =>
        hoveredCell.lineId ? isCellAcceptor(state, hoveredCell) : false,
    );

    let text: string;
    let theme: CellDescriptionStubThemes;
    if (!isHoveredLineClickable) {
        text = 'Недостаточно средств для переноса';
        theme = CellDescriptionStubThemes.Red;
    }

    let isHoveredLineValid: boolean;
    switch (internalTransferDirection) {
        case InternalTransferDirection.OneToMany:
            isHoveredLineValid = isHoveredLineAcceptor;

            if (isHoveredLineClickable) {
                text = to.length
                    ? 'Выберите еще строки, в которые хотите перенести бюджет'
                    : 'Выберите строки, в которые хотите перенести бюджет';
            }

            break;
        case InternalTransferDirection.ManyToOne:
            isHoveredLineValid = isHoveredLineDonor;

            if (isHoveredLineClickable) {
                text = from.length
                    ? 'Выберите еще строки, из которых хотите перенести бюджет'
                    : 'Выберите строки, из которых хотите перенести бюджет';
            }

            break;
        default:
            isHoveredLineValid = false;
            text = '';
    }

    const shouldRender = !hoveredCell.lineId || !isHoveredLineClickable || isHoveredLineValid;

    return {
        shouldRender,
        text,
        theme,
    };
}

function CellStub(): JSX.Element {
    const { shouldRender, text, theme } = useCellStubStore();

    return shouldRender ? (
        <div className={styles.cellDescription}>
            <CellDescriptionStub text={text} qaId="internalTransitionLineSelectedLineStub" theme={theme} />
        </div>
    ) : null;
}

function useHoveredLineStore() {
    const internalTransferDirection = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.internalTransferDirection,
    );
    const hoveredCell = useSelector((state: StoreState) => getBudgetTransferMenuState(state).cells.hoveredCell);
    const isHoveredLineClickable = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.isHoveredLineClickable,
    );

    const from = useSelector((state: StoreState) => getBudgetTransferMenuState(state).cells.from);
    const to = useSelector((state: StoreState) => getBudgetTransferMenuState(state).cells.to);

    let isSelectedCellValid: boolean;
    let template: CellDescriptionTemplates;
    switch (internalTransferDirection) {
        case InternalTransferDirection.OneToMany:
            isSelectedCellValid = to.some((toCell) => hoveredCell.lineId === toCell.lineId);
            template = CellDescriptionTemplates.IdWithLineReservePlanDiff;
            break;
        case InternalTransferDirection.ManyToOne:
            isSelectedCellValid = from.some((fromCell) => hoveredCell.lineId === fromCell.lineId);
            template = CellDescriptionTemplates.IdWithLinePlanReserveDiff;
            break;
        default:
            isSelectedCellValid = false;
            template = null;
            break;
    }

    const shouldRender = isHoveredLineClickable && hoveredCell.lineId && !isSelectedCellValid;

    return { shouldRender, hoveredCell, template };
}

function HoveredLine(): JSX.Element {
    const { shouldRender, hoveredCell, template } = useHoveredLineStore();

    return shouldRender ? (
        <div className={styles.cellDescription}>
            <CellDescription
                qaId="internalTransitionLineSelecteedHoveredLine"
                cell={hoveredCell}
                theme={CellDescriptionThemes.Transparent}
                template={template}
            />
        </div>
    ) : null;
}
