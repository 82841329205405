import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';

import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type {
    AutopilotTV,
    MediaplanPlanDataTVTotalResponse as MediaplanPlanDataTVTotal,
    AutopilotTVTargetGroup,
    AutopilotTVVideoDuration,
} from 'sber-marketing-types/backend';
import type { PageState, BriefStageForm, GroupedDictionaries, MediaplanItem } from './types';
import { AutopilotStage } from './types';
import * as actions from './actions';

export const initialState: PageState = {
    autopilot: null,
    activity: null,
    mediaplan: [],
    mediaplanTotal: null,
    targetGroups: [],
    videoDurations: [],
    dictionaries: {},
    briefStageForm: {
        blockId: null,
        productId: null,
        budget: null,
        budgetError: null,
        dateStart: null,
        dateEnd: null,
        targetGroup: null,
        videos: [{ id: 'new', name: null, duration: null, percents: [] }],
        secondAdvertiserEnable: false,
        secondAdvertiserMargin: null,
        manualRatingSplitEnable: false,
        manualRatingSplit: [],
    },
    selectedBudgetItemIds: [],
    currentStage: AutopilotStage.Brief,
    preloader: false,
    sidebar: {
        opened: true,
    },
};

export class Reducer {
    public static loadAutopilot(state: PageState, payload: AutopilotTV): PageState {
        const { brief, budget } = payload;

        const briefStageForm: BriefStageForm = brief
            ? {
                  blockId: brief.block,
                  productId: brief.product,
                  budget: brief.budget ? brief.budget.toString() : null,
                  budgetError: null,
                  dateStart: brief.dateStart,
                  dateEnd: brief.dateEnd,
                  targetGroup: brief.targetGroup,
                  videos: lodash.isEmpty(brief.videos)
                      ? [{ id: 'new', name: null, duration: null, percents: [] }]
                      : brief.videos,
                  secondAdvertiserEnable: brief.secondAdvertiserEnable || false,
                  secondAdvertiserMargin: brief.secondAdvertiserMargin,
                  manualRatingSplitEnable: brief.manualRatingSplitEnable || false,
                  manualRatingSplit: brief.manualRatingSplit,
              }
            : lodash.cloneDeep(initialState.briefStageForm);

        const selectedBudgetItemIds = budget ? budget.budgetIds : [];

        return {
            ...state,
            autopilot: payload,
            briefStageForm,
            selectedBudgetItemIds,
        };
    }

    public static loadActivity(state: PageState, payload: Activity): PageState {
        return { ...state, activity: payload };
    }

    public static loadMediaplan(state: PageState, payload: MediaplanItem[]): PageState {
        return { ...state, mediaplan: payload };
    }

    public static loadMediaplanTotal(state: PageState, payload: MediaplanPlanDataTVTotal): PageState {
        return { ...state, mediaplanTotal: payload };
    }

    public static loadTargetGroups(state: PageState, payload: AutopilotTVTargetGroup[]): PageState {
        return { ...state, targetGroups: payload };
    }

    public static loadVideoDurations(state: PageState, payload: AutopilotTVVideoDuration[]): PageState {
        return { ...state, videoDurations: payload };
    }

    public static loadDictionaries(state: PageState, payload: GroupedDictionaries): PageState {
        return { ...state, dictionaries: payload };
    }

    public static resetPageState(): PageState {
        return lodash.cloneDeep(initialState);
    }

    public static setBriefFormValues(state: PageState, payload: Partial<BriefStageForm>): PageState {
        return { ...state, briefStageForm: { ...state.briefStageForm, ...payload } };
    }

    public static setSelectedBudgetItemIds(state: PageState, payload: string[]): PageState {
        return { ...state, selectedBudgetItemIds: payload };
    }

    public static setCurrentStage(state: PageState, payload: AutopilotStage): PageState {
        return { ...state, currentStage: payload };
    }

    public static setPreloaderStatus(state: PageState, payload: boolean): PageState {
        return { ...state, preloader: payload };
    }

    public static openSidebar(state: PageState): PageState {
        return { ...state, sidebar: { ...state.sidebar, opened: true } };
    }

    public static closeSidebar(state: PageState): PageState {
        return { ...state, sidebar: { ...state.sidebar, opened: false } };
    }
}

export const autopilotTvReducer = reducerWithInitialState(initialState)
    .case(actions.loadAutopilot, Reducer.loadAutopilot)
    .case(actions.loadActivity, Reducer.loadActivity)
    .case(actions.loadMediaplan, Reducer.loadMediaplan)
    .case(actions.loadMediaplanTotal, Reducer.loadMediaplanTotal)
    .case(actions.loadTargetGroups, Reducer.loadTargetGroups)
    .case(actions.loadVideoDurations, Reducer.loadVideoDurations)
    .case(actions.loadDictionaries, Reducer.loadDictionaries)
    .case(actions.resetPageState, Reducer.resetPageState)
    .case(actions.setBriefFormValues, Reducer.setBriefFormValues)
    .case(actions.setSelectedBudgetItemIds, Reducer.setSelectedBudgetItemIds)
    .case(actions.setCurrentStage, Reducer.setCurrentStage)
    .case(actions.setPreloaderStatus, Reducer.setPreloaderStatus)
    .case(actions.openSidebar, Reducer.openSidebar)
    .case(actions.closeSidebar, Reducer.closeSidebar);
