// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-CardTypes-common-CorrectionCardComment-_CorrectionCardComment__root{margin:0 -7px;padding:4px 7px;letter-spacing:0.3px;word-wrap:break-word;color:#000000;border-radius:3px;background-color:#f6f6f6;font-family:'Open Sans';font-size:12px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/dashboard/RightSidebar/BudgetInfo/Corrections/templates/CardTypes/common/CorrectionCardComment/CorrectionCardComment.scss"],"names":[],"mappings":"AAAA,gJACI,aAAc,CACd,eAAgB,CAEhB,oBAAqB,CACrB,oBAAqB,CAErB,aAAc,CACd,iBAAkB,CAClB,wBAAyB,CAEzB,uBAAwB,CACxB,cAAe","sourcesContent":[".root {\n    margin: 0 -7px;\n    padding: 4px 7px;\n\n    letter-spacing: 0.3px;\n    word-wrap: break-word;\n\n    color: #000000;\n    border-radius: 3px;\n    background-color: #f6f6f6;\n\n    font-family: 'Open Sans';\n    font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-CardTypes-common-CorrectionCardComment-_CorrectionCardComment__root"
};
export default ___CSS_LOADER_EXPORT___;
