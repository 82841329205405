// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-NotificationList-_NotificationList__root{position:absolute;z-index:1000;right:30px;bottom:60px}.src-client-modules-common-NotificationList-_NotificationList__notificationWrapper{margin-bottom:10px}.src-client-modules-common-NotificationList-_NotificationList__notificationWrapper:last-child{margin-bottom:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/NotificationList/NotificationList.scss"],"names":[],"mappings":"AAAA,oEACI,iBAAkB,CAClB,YAAa,CACb,UAAW,CACX,WAAY,CACf,mFAGG,kBAAmB,CADvB,8FAIQ,eAAgB","sourcesContent":[".root {\n    position: absolute;\n    z-index: 1000;\n    right: 30px;\n    bottom: 60px;\n}\n\n.notificationWrapper {\n    margin-bottom: 10px;\n\n    &:last-child {\n        margin-bottom: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-NotificationList-_NotificationList__root",
	"notificationWrapper": "src-client-modules-common-NotificationList-_NotificationList__notificationWrapper"
};
export default ___CSS_LOADER_EXPORT___;
