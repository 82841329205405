import { Dictionary } from 'lodash';
import { FileResponse } from 'sber-marketing-types/frontend';
import { ActivityTasksStatusDTO, ActivityStatusDTO } from '@mrm/dam';

import { LoadingStatus } from '@store/commonTypes';

export interface ActivityFilesDAMArchiveState {
    activityId: number;
    poolingInProgress: boolean;
    archiveStatus?: ActivityStatusDTO;
    archive: ActivityTasksStatusDTO;
    archiveContentPopup: ArchiveContentPopupState;
    tasksFiles: DAMArchiveTasksFiles;
    loadingStatus: DAMArchiveLoadingStatus;
}

export enum ArchiveContentPopupComponentState {
    Hidden = 'Hidden',
    ViewContent = 'ViewContent',
    Editor = 'Editor',
    NameDuplicatesPopup = 'NameDuplicatesPopup',
}

export interface ArchiveContentPopupState {
    componentState: ArchiveContentPopupComponentState;
    renameFilesWithSameName: boolean;
    filesToExport: string[];
}

export interface DAMArchiveLoadingStatus {
    common: LoadingStatus;
    tasksFiles: LoadingStatus;
}

export interface DAMArchiveTasksFiles {
    entities: FileResponse[];
    byTaskId: Dictionary<FileResponse[]>;
}
