// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetPlanning-SortingMenu-_SortingMenu__root{width:100%}.src-client-modules-budget-BudgetPage-BudgetPlanning-SortingMenu-_SortingMenu__root:focus{outline:0}.src-client-modules-budget-BudgetPage-BudgetPlanning-SortingMenu-_SortingMenu__title{padding:0 9px;user-select:none;color:#262626;font-family:'Open Sans';font-size:11px;line-height:20px}.src-client-modules-budget-BudgetPage-BudgetPlanning-SortingMenu-_SortingMenu__radiobuttons{margin-top:4px;padding-right:10px;padding-left:8px}.src-client-modules-budget-BudgetPage-BudgetPlanning-SortingMenu-_SortingMenu__radiobutton{margin-bottom:4px}.src-client-modules-budget-BudgetPage-BudgetPlanning-SortingMenu-_SortingMenu__radiobutton:last-child{margin-bottom:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetPlanning/SortingMenu/SortingMenu.scss"],"names":[],"mappings":"AAAA,oFACI,UAAW,CADf,0FAIQ,SAAU,CACb,qFAID,aAAc,CAEd,gBAAiB,CAEjB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACpB,4FAGG,cAAe,CACf,kBAAmB,CACnB,gBAAiB,CACpB,2FAGG,iBAAkB,CADtB,sGAIQ,eAAgB","sourcesContent":[".root {\n    width: 100%;\n\n    &:focus {\n        outline: 0;\n    }\n}\n\n.title {\n    padding: 0 9px;\n\n    user-select: none;\n\n    color: #262626;\n\n    font-family: 'Open Sans';\n    font-size: 11px;\n    line-height: 20px;\n}\n\n.radiobuttons {\n    margin-top: 4px;\n    padding-right: 10px;\n    padding-left: 8px;\n}\n\n.radiobutton {\n    margin-bottom: 4px;\n\n    &:last-child {\n        margin-bottom: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetPlanning-SortingMenu-_SortingMenu__root",
	"title": "src-client-modules-budget-BudgetPage-BudgetPlanning-SortingMenu-_SortingMenu__title",
	"radiobuttons": "src-client-modules-budget-BudgetPage-BudgetPlanning-SortingMenu-_SortingMenu__radiobuttons",
	"radiobutton": "src-client-modules-budget-BudgetPage-BudgetPlanning-SortingMenu-_SortingMenu__radiobutton"
};
export default ___CSS_LOADER_EXPORT___;
