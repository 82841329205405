import * as React from 'react';

import { CloseButton } from './CloseButton';
import { Title } from './Title';

import * as style from './Header.scss';

interface Props {
    onCloseButtonClick: () => void;
    lineId: string;
}

export const Header: React.FC<Props> = ({ lineId, onCloseButtonClick }) => {
    return (
        <div className={style.root}>
            <Title lineId={lineId} />
            <div
                className={style.closeButtonWrapper}
                {...{
                    'qa-id': 'budgetExecutionLineModalCloseButton',
                }}
            >
                <CloseButton onClick={onCloseButtonClick} />
            </div>
        </div>
    );
};
