// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-Header-BudgetItemStatus-_BudgetItemStatus__root{grid-column-start:1;grid-column-end:-1}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetPlanning/LineModal/Header/BudgetItemStatus/BudgetItemStatus.scss"],"names":[],"mappings":"AAAA,+GACI,mBAAoB,CACpB,kBAAmB","sourcesContent":[".root {\n    grid-column-start: 1;\n    grid-column-end: -1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-Header-BudgetItemStatus-_BudgetItemStatus__root"
};
export default ___CSS_LOADER_EXPORT___;
