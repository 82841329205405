import * as React from 'react';
import { Icon, IconType } from 'sber-marketing-ui';
import classNames from 'classnames';

import * as styles from './Participant.scss';

export enum PersonDataTheme {
    THIN_DEPARTMENT,
    TRANSLUCENT,
}

export interface Props {
    allowMention: boolean;
    theme: PersonDataTheme;
    name: string;
    department: string;
    canRemove: boolean;
    onMouseOver(): void;
    onMouseLeave(): void;
    onParticipantClick(): void;
    onRemoveParticipantClick(): void;
}

export function Participant({
    allowMention,
    name,
    department,
    canRemove,
    onParticipantClick,
    onRemoveParticipantClick,
    theme,
    onMouseOver,
    onMouseLeave,
}: Props): JSX.Element {
    return (
        <div
            className={styles.root}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            {...{
                'qa-id': 'taskParticipantCard',
                'qa-index': `${name} ${department}`,
            }}
        >
            <div
                className={classNames(
                    styles.personData,
                    theme === PersonDataTheme.THIN_DEPARTMENT && styles.personData_theme_thinDepartment,
                    theme === PersonDataTheme.TRANSLUCENT && styles.personData_theme_translucent,
                )}
                onClick={allowMention ? onParticipantClick : null}
            >
                <div className={styles.personData_name}>
                    {name} {!department ? '(Удален)' : ''}
                </div>

                <div className={styles.personData_department}>{department}</div>
            </div>

            {canRemove && (
                <div
                    className={styles.button}
                    onClick={onRemoveParticipantClick}
                    {...{
                        'qa-id': 'taskParticipantCardDeleteButton',
                    }}
                >
                    <div className={styles.buttonIcon}>
                        <Icon type={IconType.DELETE_ROUND} />
                    </div>
                </div>
            )}
        </div>
    );
}
