import { reducerWithInitialState } from 'typescript-fsa-reducers';

import * as actions from './actions/sync';

import { LineModalState as State, ShowLineModalParams, LineModalTabs } from './types';

class Reducer {
    public static makeInitialState(): State {
        return {
            show: false,
            selectedTab: LineModalTabs.GeneralInfo,
        };
    }

    public static showLineModal(state: State, payload: ShowLineModalParams): State {
        return {
            ...state,
            ...payload,
            show: true,
            selectedTab: payload.selectedTab || LineModalTabs.GeneralInfo,
        };
    }

    public static hideLineModal(state: State): State {
        return {
            ...state,
            show: false,
            lineId: null,
        };
    }

    public static setSelectedTab(state: State, selectedTab: LineModalTabs): State {
        return { ...state, selectedTab };
    }
}

export const lineModalReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(actions.showLineModal, Reducer.showLineModal)
    .case(actions.hideLineModal, Reducer.hideLineModal)
    .case(actions.setSelectedTab, Reducer.setSelectedTab)
    .case(actions.clearLineModal, Reducer.makeInitialState);
