import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { AxiosError } from 'axios';
import { uniq, compact } from 'lodash';

import { StoreState } from '../..';
import { RemoveParticipatorPayload, FetchError } from '../types';
import { asyncActions } from '../actions';
import { TaskApi } from '../../../api/TaskApi';
import { getTaskInfo } from '../selectors';

export const removeParticipator = bindThunkAction<StoreState, RemoveParticipatorPayload, void, FetchError>(
    asyncActions.removeParticipator,
    async ({ userId }, dispatch, getState): Promise<void> => {
        const { id: taskId, participants, executorId, authorId } = getTaskInfo(getState());
        const userIds = uniq(compact([executorId, authorId, ...participants.map((participant) => participant.userId)]));
        try {
            await TaskApi.updateTaskParticipants(taskId, userIds);
        } catch (error) {
            const { response, message } = <AxiosError>error;
            if (response) {
                throw new FetchError(response.status, response.statusText, message);
            } else {
                throw new FetchError(0, 'Unknown', message);
            }
        }
    },
);
