// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-EditPlannedBudgetPage-ActivitySuggest-ActivityAlreadyExistsCaption-_ActivityAlreadyExistsCaption__root{position:absolute;top:48px;left:0;display:flex;align-items:center;letter-spacing:0.15px;color:#7e8681;font-family:'Open Sans';font-size:12px;line-height:16px}.src-client-modules-budget-EditPlannedBudgetPage-ActivitySuggest-ActivityAlreadyExistsCaption-_ActivityAlreadyExistsCaption__icon{display:flex;align-items:center;justify-content:center;width:12px;height:12px;margin-right:2px;color:#1f9a48}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/EditPlannedBudgetPage/ActivitySuggest/ActivityAlreadyExistsCaption/ActivityAlreadyExistsCaption.scss"],"names":[],"mappings":"AAAA,kIACI,iBAAkB,CAClB,QAAS,CACT,MAAO,CAEP,YAAa,CACb,kBAAmB,CAEnB,qBAAsB,CAEtB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACpB,kIAGG,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CAEvB,UAAW,CACX,WAAY,CACZ,gBAAiB,CAEjB,aAAc","sourcesContent":[".root {\n    position: absolute;\n    top: 48px;\n    left: 0;\n\n    display: flex;\n    align-items: center;\n\n    letter-spacing: 0.15px;\n\n    color: #7e8681;\n\n    font-family: 'Open Sans';\n    font-size: 12px;\n    line-height: 16px;\n}\n\n.icon {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    width: 12px;\n    height: 12px;\n    margin-right: 2px;\n\n    color: #1f9a48;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-EditPlannedBudgetPage-ActivitySuggest-ActivityAlreadyExistsCaption-_ActivityAlreadyExistsCaption__root",
	"icon": "src-client-modules-budget-EditPlannedBudgetPage-ActivitySuggest-ActivityAlreadyExistsCaption-_ActivityAlreadyExistsCaption__icon"
};
export default ___CSS_LOADER_EXPORT___;
