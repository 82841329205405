import * as React from 'react';
import { UserCard } from 'sber-marketing-types/frontend';

import * as style from './Author.scss';

interface Props {
    user: UserCard;
}

export function Author({ user }: Props): JSX.Element {
    let firstName;
    let secondName;
    let department;

    if (user) {
        firstName = user.firstName;
        secondName = user.secondName;
        department = user.department;
    } else {
        firstName = '';
        secondName = '';
        department = '';
    }

    return (
        <div className={style.newsPartWrapper}>
            <div
                className={style.authorName}
                {...{
                    'qa-id': 'dashboardNewsColumnItemAuthor',
                }}
            >
                {secondName} {firstName}
            </div>

            <div className={style.caption}>{department}</div>
        </div>
    );
}
