import * as React from 'react';
import * as moment from 'moment';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { TaskCardParams } from 'sber-marketing-types/frontend';
import {
    Icon,
    IconType,
    Toggle,
    TogglePosition,
    ToggleThemes,
    WithTooltip,
    TooltipAnchor,
    WithGlobalPopup,
    AddaptivePositionCalculator,
    Skeleton,
} from 'sber-marketing-ui';

import { DictionaryApi } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getActivityTasksPageState, fillActivityTasks } from '@store/activityTasksPage';
import { getActivityStageState } from '@store/activityTasksPage/stages';
import { fetchMoreTasks, TasksListType, resetTasksList } from '@store/tasksList';
import {
    Stage,
    setStageStartDate,
    setStageEndDate,
    editStage,
    loadStagesData,
    setLoadingStatus,
    setStageActiveStatus,
} from '@store/activityTasksPage/stages';

import { RangeDatepicker } from '@common/RangeDatepicker';
import { Utils } from '@common/Utils';

import * as styles from './Item.scss';

type Moment = moment.Moment;

interface Props extends Stage {}

function momentToDate(date: Moment): Date {
    return date ? date.toDate() : null;
}

function stringToDate(date: string | Date): Date {
    if (date) {
        return date instanceof Date ? date : new Date(date);
    }

    return null;
}

function useInteractivity(id: string, active: boolean) {
    const taskInfoIconRef = React.useRef();
    const [showTaskInfoPopup, setTaskInfoPopupVisibility] = React.useState(false);

    const activityId = useSelector((state: StoreState) => getActivityTasksPageState(state).activity.id);
    const tasksByStageId = useSelector((state: StoreState) => getActivityTasksPageState(state).activityTasks.byStageId);
    const projectStart = stringToDate(
        useSelector((state: StoreState) => getActivityTasksPageState(state).activity.preparationDate),
    );
    const projectEnd = stringToDate(
        useSelector((state: StoreState) => getActivityTasksPageState(state).activity.debriefingDate),
    );
    const shouldPermormStatusChangeRequest = useSelector((state: StoreState) => {
        const { existingStages, pendingStages } = getActivityStageState(state);

        return (
            existingStages.selectedTemplateId === pendingStages.selectedTemplateId &&
            !!existingStages.selectedTemplateId
        );
    });
    const stageTasks = tasksByStageId[id] || [];

    const dispatch = useDispatch();

    const onStartChange = React.useCallback(
        (momentDate: Moment) => {
            const start = momentToDate(momentDate);

            dispatch(setStageStartDate({ id, start }));
        },
        [id, dispatch, setStageStartDate],
    );

    const onEndChange = React.useCallback(
        (momentDate: Moment) => {
            const end = momentToDate(momentDate);

            dispatch(setStageEndDate({ id, end }));
        },
        [id, dispatch, setStageEndDate],
    );

    const toggleStageActiveState = React.useCallback(() => {
        if (shouldPermormStatusChangeRequest) {
            dispatch(editStage({ id, active: !active }));
            dispatch(setLoadingStatus(LoadingStatus.NOT_LOADED));
            dispatch(loadStagesData(null));
            dispatch(fillActivityTasks(activityId));
            dispatch(resetTasksList());
            dispatch(fetchMoreTasks(TasksListType.ACTIVITY_TASKS));
        } else {
            dispatch(setStageActiveStatus({ id, active: !active }));
        }
    }, [id, active, shouldPermormStatusChangeRequest, dispatch, editStage]);

    return {
        showTaskInfoPopup,
        taskInfoIconRef,
        stageTasks,
        projectStart,
        projectEnd,
        setTaskInfoPopupVisibility,
        onStartChange,
        onEndChange,
        toggleStageActiveState,
    };
}

export function Item({ id, name, start, end, active, duration }: Props): JSX.Element {
    const {
        showTaskInfoPopup,
        taskInfoIconRef,
        stageTasks,
        projectStart,
        projectEnd,
        setTaskInfoPopupVisibility,
        onStartChange,
        onEndChange,
        toggleStageActiveState,
    } = useInteractivity(id, active);

    const tasksDeclension = stageTasks.length
        ? `${stageTasks.length} ${Utils.getDeclensionByNumber(stageTasks.length, ['задача', 'задачи', 'задач'])}`
        : 'Нет задач';

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'stagesEditorPopupStage',
            }}
        >
            <div className={styles.titleColumn}>
                <div className={styles.titleActiveToggleWrapper}>
                    <Toggle
                        qaId="stagesEditorPopupStageActiveToggle"
                        position={active ? TogglePosition.RIGHT : TogglePosition.LEFT}
                        onClick={toggleStageActiveState}
                        theme={ToggleThemes.LARGE}
                    />
                </div>

                <div className={styles.nameCountWrapper}>
                    <WithTooltip content={name} timeout={600} anchor={TooltipAnchor.TOP}>
                        <div
                            className={classNames(styles.name, !active && styles.nameDisabled)}
                            {...{
                                'qa-id': 'stagesEditorPopupStageName',
                                'qa-label': name,
                            }}
                        >
                            {name}
                        </div>
                    </WithTooltip>

                    <div
                        className={styles.taskCount}
                        {...{
                            'qa-id': 'stagesEditorPopupStageTasksCount',
                        }}
                    >
                        {tasksDeclension}

                        {false ? (
                            <div
                                ref={taskInfoIconRef}
                                className={styles.taskInfoIcon}
                                onMouseEnter={() => setTaskInfoPopupVisibility(true)}
                                onMouseLeave={() => setTaskInfoPopupVisibility(false)}
                            >
                                <Icon type={IconType.INFO_CIRCLE} svgSize={13} />
                            </div>
                        ) : null}

                        {showTaskInfoPopup && <TaskInfoPopup tasks={stageTasks} containerRef={taskInfoIconRef} />}
                    </div>
                </div>
            </div>

            <div className={styles.dividerColumn} />

            <Datepicker
                disabled={!active}
                duration={duration}
                stageStart={start}
                stageEnd={end}
                projectStart={projectStart}
                projectEnd={projectEnd}
                onStartChange={onStartChange}
                onEndChange={onEndChange}
            />
        </div>
    );
}

interface TaskInfoPopupProps {
    containerRef: React.RefObject<HTMLDivElement>;
    tasks: TaskCardParams[];
}

function TaskInfoPopup({ containerRef, tasks }: TaskInfoPopupProps): JSX.Element {
    return (
        <WithGlobalPopup container={containerRef} positionCalculator={AddaptivePositionCalculator}>
            <div className={styles.taskInfoPopup}>
                {tasks.map((task) => (
                    <TaskInfo key={task.id} {...task} />
                ))}
            </div>
        </WithGlobalPopup>
    );
}

function useTaskTemplateDuration(title: string) {
    const [duration, setDuration] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);

    const taskTemplateId = useSelector(
        (state: StoreState) => getActivityStageState(state).taskTemplates.byName[title]?.id,
    );

    async function fetcher(taskTemplateId: string) {
        setIsLoading(true);
        const dictionaryData = await DictionaryApi.getDictionaryData(taskTemplateId);
        setDuration(dictionaryData.data?.duration || 0);
        setIsLoading(false);
    }

    React.useEffect(() => {
        fetcher(taskTemplateId);
    }, [taskTemplateId]);

    return { isLoading, duration };
}

interface TaskInfoParams extends TaskCardParams {}

function TaskInfo({ title }: TaskInfoParams): JSX.Element {
    const { isLoading, duration } = useTaskTemplateDuration(title);
    const durationDeclension = duration
        ? `${duration} ${Utils.getDeclensionByNumber(duration, ['день', 'дня', 'дней'])}`
        : '';

    return (
        <Skeleton isReady={!isLoading} loadingClassName={styles.taskLoading}>
            <div className={styles.taskInfoItem}>
                <span className={styles.taskInfoItemTitle}>Задача:&nbsp;</span>
                {title} ({durationDeclension})
            </div>
        </Skeleton>
    );
}

interface DatepickerProps {
    disabled: boolean;
    duration: number;
    stageStart: Date;
    stageEnd: Date;
    projectStart: Date;
    projectEnd: Date;
    onStartChange: (date: Moment) => void;
    onEndChange: (date: Moment) => void;
}

function Datepicker({
    disabled,
    duration,
    stageStart,
    stageEnd,
    projectStart,
    projectEnd,
    onStartChange,
    onEndChange,
}: DatepickerProps): JSX.Element {
    if (projectStart) {
        projectStart.setHours(0);
    }
    const stageEndIsAfterProjectEnd = stageEnd && projectEnd < stageEnd;
    const stageStartIsBeforeProjectStart = stageStart && stageStart < projectStart;
    const dateOverlapError = stageEndIsAfterProjectEnd || stageStartIsBeforeProjectStart;

    let subContent: JSX.Element = null;
    if (dateOverlapError) {
        subContent = (
            <div
                className={classNames(styles.stageDuration, styles.stageDurationWarning)}
                {...{
                    'qa-id': 'stagesEditorPopupStageRangeDatepickerDateOverlapErrorMarker',
                }}
            >
                Период этапа выходит за рамки проекта
            </div>
        );
    } else if (duration) {
        const durationDeclension = `${duration} ${Utils.getDeclensionByNumber(duration, ['день', 'дня', 'дней'])}`;

        subContent = <div className={styles.stageDuration}>Рекомендованный период: {durationDeclension}</div>;
    } else {
        // shows subContent event if its empty
        subContent = <React.Fragment>&nbsp;</React.Fragment>;
    }

    return (
        <div
            className={styles.datepickerColumn}
            {...{
                'qa-id': 'stagesEditorPopupStageRangeDatepicker',
            }}
        >
            <RangeDatepicker
                autoSetEnd
                disabled={disabled}
                start={stageStart}
                end={stageEnd}
                showError={dateOverlapError}
                subContent={subContent}
                onStartChange={onStartChange}
                onEndChange={onEndChange}
            />
        </div>
    );
}
