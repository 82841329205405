import { Dictionary } from 'lodash';

import {
    DepartmentAttributes as DepartmentResponse,
    UserResponseParams as UserResponse,
} from 'sber-marketing-types/frontend';

import type {
    GetActivitiesDataForLeaderDashboardRequestDto as ActivitiesDataRequest,
    GetActivityDataForLeaderDashboardResponseDto as ActivityDataResponse,
    GetTasksDataForLeaderDashboardRequestDto as TasksDataRequest,
    GetTaskDataForLeaderDashboardResponseDto as TaskDataResponse,
    ActivityDataForLeaderDashboardDto as ActivityDadaResponseItem,
    TaskDataForLeaderDashboardDto as TaskDataResponseItem,
} from '@mrm-analytics-service/api-client';

import {
    LeaderDashboardDataFilterPeriod as FilterPeriod,
    LeaderDashboardDataFilterBy as DataFilterBy,
} from '@mrm-analytics-service/api-client';

export type StatisticsResponse<M extends Mode> = StatisticsResponseType[M];

export type StatisticsResponseType = {
    activities: ActivityDataResponse;
    tasks: TaskDataResponse;
};

export type ActivityStatisticsItemData = ActivityDadaResponseItem;
export type TaskStatisticsItemData = TaskDataResponseItem;

export interface PageState {
    data: PageData;
    selectedStatisticsItemId: string;
    selectedPeriod: FilterPeriod;
    selectedBy: DataFilterBy;
    selectedDate: string;
    statisticsTypeFilters: StatisticsTypeFilters;
    filters: Filters;
    statisticsItems: StatisticsItem<Mode>[];
    loading: boolean;
}

export type Mode = 'tasks' | 'activities';

export type StatisticsTypeFilters = Record<StatisticsType, boolean>;

export type StatisticsType = StatisticsActivityType | StatisticsTaskType;

export const enum StatisticsActivityType {
    OVERDUE_STAGES = 'overdue_stages',
    OVERDUE_TASKS = 'overdue_tasks',
    ACTIVE = 'active',
    PLANNED_START = 'planned_start',
}

export const enum StatisticsTaskType {
    COMING_DEADLINE = 'coming_deadline',
    OVERDUE = 'overdue',
    ACTIVE = 'active',
    CLOSED = 'closed',
}

export interface StatisticsItem<M extends Mode = Mode> {
    id: string;
    statistics: Statistics<M>;
    meta: StatisticsItemMeta;
}

export interface Statistics<M extends Mode> {
    current: StatisticItemType[M];
    past: StatisticItemType[M];
}

export interface StatisticsItemMeta {
    name: string;
    type: 'department' | 'usersGroup' | 'user';
    parentId: string;
    childrenIds: string[];
}

export type Filters = Dictionary<boolean>;

export interface LoadPagePayload {
    mode: Mode;
}

export interface LoadPageDone {
    pageData: PageData;
}

export interface PageData {
    departments: DepartmentResponse[];
    statistics: StatisticsResponse<Mode>;
    users: UserResponse[];
}

export interface Department extends DepartmentResponse {
    childrenDepartmentsIds: string[];
}

export interface TreeDepartment extends Department {
    children: TreeDepartment[];
}

export interface SetFiltersPayload {
    filters: Filters;
    mode: Mode;
}

export interface SetFilterPeriodPayload {
    filterPeriod: FilterPeriod;
    mode: Mode;
}

export interface SetFilterPeriodResult extends SetFilterPeriodPayload {
    data: PageData;
}

export interface SetFilterDatePayload {
    filterDate: string;
    mode: Mode;
}

export interface SetFilterDateResult extends SetFilterDatePayload {
    data: PageData;
}

export interface SetFilterByPayload {
    by: DataFilterBy;
    mode: Mode;
}

export interface SetFilterByResult extends SetFilterByPayload {
    data: PageData;
}

export { FilterPeriod, DataFilterBy };

export type StatisticItemType = {
    activities: Omit<ActivityStatisticsItemData, 'user' | 'department' | 'day'>;
    tasks: Omit<TaskStatisticsItemData, 'user' | 'department' | 'day'>;
};

export type {
    ActivitiesDataRequest,
    ActivityDataResponse,
    TasksDataRequest,
    TaskDataResponse,
    DepartmentResponse,
    UserResponse,
};
