import * as React from 'react';

import type { Correction, Dictionary, UnsavedChange } from '../types';
import { User as UserStore } from '@store/user';
import { CorrectionType } from '@mrm/budget';
import { PlanPermissionsSysNames, UserResponseParams } from 'sber-marketing-types/frontend';

import {
    PlanCorrectionCard,
    ReserveCorrectionCard,
    BudgetItemCorrectionCard,
    FactCorrectionCard,
    OutcomeExternalCorrectionCard,
    IncomeExternalCorrectionCard,
    ActivityCorrectionCard,
} from './CardTypes';

import * as styles from './CorrectionsList.scss';
import * as lodash from 'lodash';

interface Props {
    budgetId: string;
    corrections: Correction[];
    user: UserStore;
    users: UserResponseParams[];
    dictionaries: Dictionary[];
    setUnsavedChange: (unsavedChange: UnsavedChange) => void;
}

export const CorrectionsList = ({
    budgetId,
    corrections,
    user,
    users,
    dictionaries,
    setUnsavedChange,
}: Props): JSX.Element => {
    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'correctionsList',
            }}
        >
            {corrections.map((correction) => (
                <div key={correction.id} className={styles.card}>
                    <CorrectionCard
                        budgetId={budgetId}
                        correction={correction}
                        user={user}
                        users={users}
                        dictionaries={dictionaries}
                        setUnsavedChange={setUnsavedChange}
                    />
                </div>
            ))}
        </div>
    );
};

function CorrectionCard({
    budgetId,
    correction,
    user,
    users,
    dictionaries,
    setUnsavedChange,
}: {
    budgetId: string;
    correction: Correction;
    user: UserStore;
    users: UserResponseParams[];
    dictionaries: Dictionary[];
    setUnsavedChange: (unsavedChange: UnsavedChange) => void;
}): JSX.Element {
    const userIsBudgetExpert = lodash.includes(user.attributes.permissions, PlanPermissionsSysNames.OrganizationAccess);
    const userIsAuthorCorrection = user.attributes.id === correction.author.id;

    switch (correction.type) {
        case CorrectionType.OutcomeExternalPlanFundsTransfer:
            return (
                <OutcomeExternalCorrectionCard
                    {...correction}
                    budgetId={budgetId}
                    userIsBudgetExpert={userIsBudgetExpert}
                    userIsAuthorCorrection={userIsAuthorCorrection}
                    setUnsavedChange={setUnsavedChange}
                    dictionaries={dictionaries}
                />
            );
        case CorrectionType.IncomeExternalPlanFundsTransfer:
            return (
                <IncomeExternalCorrectionCard
                    {...correction}
                    budgetId={budgetId}
                    userIsBudgetExpert={userIsBudgetExpert}
                    userIsAuthorCorrection={userIsAuthorCorrection}
                    setUnsavedChange={setUnsavedChange}
                    dictionaries={dictionaries}
                />
            );
        case CorrectionType.PlanFundsTransfer:
            return (
                <PlanCorrectionCard
                    {...correction}
                    budgetId={budgetId}
                    userIsBudgetExpert={userIsBudgetExpert}
                    userIsAuthorCorrection={userIsAuthorCorrection}
                    setUnsavedChange={setUnsavedChange}
                />
            );
        case CorrectionType.ReservedFunds:
            return (
                <ReserveCorrectionCard
                    {...correction}
                    budgetId={budgetId}
                    userIsBudgetExpert={userIsBudgetExpert}
                    userIsAuthorCorrection={userIsAuthorCorrection}
                    setUnsavedChange={setUnsavedChange}
                />
            );
        case CorrectionType.FactFunds:
            return (
                <FactCorrectionCard
                    {...correction}
                    budgetId={budgetId}
                    userIsBudgetExpert={userIsBudgetExpert}
                    userIsAuthorCorrection={userIsAuthorCorrection}
                    setUnsavedChange={setUnsavedChange}
                />
            );
        case CorrectionType.BudgetItem:
            return (
                <BudgetItemCorrectionCard
                    {...correction}
                    budgetId={budgetId}
                    userIsBudgetExpert={userIsBudgetExpert}
                    userIsAuthorCorrection={userIsAuthorCorrection}
                    setUnsavedChange={setUnsavedChange}
                    users={users}
                    dictionaries={dictionaries}
                />
            );
        case CorrectionType.Activity:
            return (
                <ActivityCorrectionCard
                    {...correction}
                    budgetId={budgetId}
                    userIsBudgetExpert={userIsBudgetExpert}
                    userIsAuthorCorrection={userIsAuthorCorrection}
                    setUnsavedChange={setUnsavedChange}
                />
            );
    }
}
