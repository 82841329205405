// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-Title-_Title__root{padding-left:5px;color:#000000;font-size:14px;font-weight:600;line-height:19px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/Title/Title.scss"],"names":[],"mappings":"AAAA,4FACI,gBAAiB,CAEjB,aAAc,CAEd,cAAe,CACf,eAAgB,CAChB,gBAAiB","sourcesContent":[".root {\n    padding-left: 5px;\n\n    color: #000000;\n\n    font-size: 14px;\n    font-weight: 600;\n    line-height: 19px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-Title-_Title__root"
};
export default ___CSS_LOADER_EXPORT___;
