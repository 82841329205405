// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-layout-Fieldset-_Fieldset__root{display:flex;flex-direction:column;border:none;gap:8px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/layout/Fieldset/Fieldset.scss"],"names":[],"mappings":"AAAA,sEACE,YAAa,CACb,qBAAsB,CACtB,WAAY,CACZ,OAAQ","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  border: none;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-components-layout-Fieldset-_Fieldset__root"
};
export default ___CSS_LOADER_EXPORT___;
