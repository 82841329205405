// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ProgrammaticStageWidget-_ProgrammaticStageWidget__root{position:relative;width:100%;overflow:hidden;border-radius:6px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ProgrammaticStageWidget/ProgrammaticStageWidget.scss"],"names":[],"mappings":"AAAA,iHACI,iBAAkB,CAClB,UAAW,CACX,eAAgB,CAChB,iBAAkB","sourcesContent":[".root {\n    position: relative;\n    width: 100%;\n    overflow: hidden;\n    border-radius: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-ProgrammaticStageWidget-_ProgrammaticStageWidget__root"
};
export default ___CSS_LOADER_EXPORT___;
