import * as React from 'react';
import classNames from 'classnames';

import { LiteLineWarning } from '../LineWarnign';

import * as style from './RejectedCorrectionWarning.scss';

export interface Props {
    correctionSerialNumberId: string;
    isActive: boolean;
}

export const RejectedCorrectionWarning: React.FC<Props> = ({ correctionSerialNumberId, isActive }) => {
    const onClick = () => {
        window.open(`/budget/corrections?serialNumber=${correctionSerialNumberId}`, '_blank');
    };

    return (
        <LiteLineWarning isActive={isActive}>
            <div className={style.body}>
                <div>
                    Предыдущая неутвержденная корректировка
                    <span className={style.accentText}>#{correctionSerialNumberId}</span> резерва автоматически
                    отклонена
                </div>
                <div className={classNames(style.accentText, style.button, isActive && style.active)} onClick={onClick}>
                    Посмотреть
                </div>
            </div>
        </LiteLineWarning>
    );
};

export type { Props as RejectedCorrectionWarningProps };
