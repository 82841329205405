import { axios } from '../lib/axios';
import {
    AutopilotDOOH,
    // AutopilotDOOHMediaplanPlanDataResponse,
    AutopilotDOOHMediaplanCalculation,
    GetAutopilotDOOHListParams,
    CreateAutopilotDOOHParams,
    UpdateAutopilotDOOHParams,
    UpdateAutopilotDOOHBriefParams,
    UpdateAutopilotDOOHBudgetParams,
    AutopilotDOOHRegionality,
    AutopilotDOOHFormatWithTimings,
} from 'sber-marketing-types/backend';

const BASE_URL = '/api/autopilot-dooh';

export class AutopilotDOOHApi {
    public static async getAutopilot(autopilotId: string): Promise<AutopilotDOOH> {
        const res = await axios.get(`${BASE_URL}/${autopilotId}`);

        return res.data;
    }

    // public static async getMediaplan(autopilotId: string): Promise<AutopilotDOOHMediaplanPlanDataResponse> {
    //     const res = await axios.get(`${BASE_URL}/${autopilotId}/mediaplan`);

    //     return res.data;
    // }

    public static async calculateMediaplan(autopilotId: string): Promise<AutopilotDOOHMediaplanCalculation[]> {
        const res = await axios.post(`${BASE_URL}/${autopilotId}/mediaplan-calculate`);

        return res.data;
    }

    public static async getAutopilotList(params?: GetAutopilotDOOHListParams): Promise<AutopilotDOOH[]> {
        const res = await axios.post(`${BASE_URL}/list`, params);

        return res.data;
    }

    public static async createAutopilot(params: CreateAutopilotDOOHParams): Promise<void> {
        const res = await axios.post(BASE_URL, params);

        return res.data;
    }

    public static async updateAutopilot(autopilotId: string, params: UpdateAutopilotDOOHParams): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}`, params);
    }

    public static async updateBrief(autopilotId: string, params: UpdateAutopilotDOOHBriefParams): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/brief`, params);
    }

    public static async updateMediaplan(autopilotId: string): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/mediaplan`);
    }

    public static async updateBudget(autopilotId: string, params: UpdateAutopilotDOOHBudgetParams): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/budget`, params);
    }

    public static async getRegions(autopilotId: string): Promise<AutopilotDOOHRegionality[]> {
        const res = await axios.get(`${BASE_URL}/${autopilotId}/mediaplan-regionality`);

        return res.data;
    }

    public static async getFormats(autopilotId: string): Promise<AutopilotDOOHFormatWithTimings[]> {
        const res = await axios.get(`${BASE_URL}/${autopilotId}/mediaplan-format`);

        return res.data;
    }
}
