import * as React from 'react';
import { CorrectionType } from '@mrm/budget';

import { Menu, Params, SerialNumber, Body } from '../common';
import { FactFundsCorrection } from '../../../types';
import { FactCorrectionCard as FactCorrectionCardTooltip } from '../CardTypes';

import { Money, MoneyFormatter } from '@common/Utils';
import * as style from './FactCorrectionCard.scss';

interface Props {
    amountDelta: number;
    correction: FactFundsCorrection;
    onChange: () => void;
    onCardMouseEnter: () => void;
    onCardMouseLeave: () => void;
}

export const FactCorrectionCard: React.FC<Props> = ({
    correction,
    amountDelta,
    onChange,
    onCardMouseEnter,
    onCardMouseLeave,
}) => {
    const isPositiveDelta = amountDelta >= 0;

    return (
        <Body
            qaId="dashboardRightSidebarBudgetInfoFactCorrectionCard"
            qaCorrectionType={CorrectionType.FactFunds}
            tooltipComponent={<FactCorrectionCardTooltip {...correction} />}
            onMouseEnter={onCardMouseEnter}
            onMouseLeave={onCardMouseLeave}
        >
            <div className={style.header}>
                <SerialNumber>{correction.serialNumber}</SerialNumber>
                <div className={style.title}>Корректировка факта</div>
                <Menu
                    status={correction.status}
                    correctionId={correction.id}
                    correctionAuthorId={correction.author.id}
                    onChange={onChange}
                />
            </div>
            <Params
                data={[
                    {
                        name: 'Факт по строке',
                        value: (
                            <div className={isPositiveDelta ? style.positiveValue : style.negativeValue}>
                                {formatMoneyWithSign(amountDelta)}
                            </div>
                        ),
                    },
                ]}
            />
        </Body>
    );
};

function formatMoneyWithSign(money: number): string {
    return MoneyFormatter.toThousands(Money.fromCopecks(money), { forceSign: true });
}
