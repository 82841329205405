import * as React from 'react';
import classNames from 'classnames';
import * as moment from 'moment';

import * as style from './BriefStageWidget.scss';

import type { BriefStageForm } from '@store/autopilotTv/types';

import {
    OpacityTransition,
    LabeledInput,
    InputType,
    LabeledSelect,
    SelectItem,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    Icon,
    IconType,
} from 'sber-marketing-ui';

interface Props {
    readOnly: boolean;
    loading: boolean;
    isBudgetFocus: boolean;
    briefStageForm: BriefStageForm;
    datesRange: string;
    blockItems: SelectItem[];
    productItems: SelectItem[];
    targetGroupsItems: SelectItem[];
    videoDurationsItems: SelectItem[];
    onBlockChange: (value: string) => void;
    onProductChange: (value: string) => void;
    onBudgetChange: (value: string) => void;
    onBudgetFocus: () => void;
    onBudgetBlur: () => void;
    onStartDateChange: (value: moment.Moment) => void;
    onEndDateChange: (value: moment.Moment) => void;
    onTargetGroupChange: (value: string) => void;
    onVideoAddClick: () => void;
    onVideoNameChange: (id: string, value: string) => void;
    onVideoDurationChange: (id: string, value: number) => void;
    onVideoDeleteClick: (id: string) => void;
}

export const BriefStageWidgetTemplate = ({
    readOnly,
    loading,
    isBudgetFocus,
    briefStageForm,
    datesRange,
    blockItems,
    productItems,
    targetGroupsItems,
    videoDurationsItems,
    onBlockChange,
    onProductChange,
    onBudgetChange,
    onBudgetFocus,
    onBudgetBlur,
    onStartDateChange,
    onEndDateChange,
    onTargetGroupChange,
    onVideoAddClick,
    onVideoNameChange,
    onVideoDurationChange,
    onVideoDeleteClick,
}: Props): JSX.Element => {
    return (
        <OpacityTransition start={!loading}>
            <div className={style.root}>
                <div className={style.title}>Бриф запуска РК</div>

                <div className={style.form}>
                    <div className={classNames(style.field, style.line)}>
                        <LabeledSelect
                            title={'Блок *'}
                            items={blockItems}
                            selectedValue={briefStageForm.blockId}
                            onSelection={onBlockChange}
                            readOnly={readOnly}
                        />
                    </div>

                    <div className={classNames(style.field, style.line)}>
                        <LabeledSelect
                            title={'Продукт *'}
                            items={productItems}
                            selectedValue={briefStageForm.productId}
                            onSelection={onProductChange}
                            readOnly={readOnly}
                        />
                    </div>

                    <div className={classNames(style.smallField, style.line)}>
                        <LabeledInput
                            title={'Бюджет на весь период *'}
                            value={briefStageForm.budget}
                            errorMessage={briefStageForm.budgetError}
                            displayError={
                                !!briefStageForm.budget?.length &&
                                !!briefStageForm.budgetError?.length &&
                                !isBudgetFocus
                            }
                            onValueChange={onBudgetChange}
                            onFocus={onBudgetFocus}
                            onBlur={onBudgetBlur}
                            onlyNumbers
                            readOnly={readOnly}
                        />
                    </div>

                    <div className={style.line}>
                        <div className={style.smallField}>
                            <LabeledInput
                                title={'Старт проекта *'}
                                value={moment(briefStageForm.dateStart)}
                                maxDate={moment(briefStageForm.dateEnd)}
                                type={InputType.DatePicker}
                                onValueChange={onStartDateChange}
                                readOnly={readOnly}
                            />
                        </div>

                        <span className={style.separator}>—</span>

                        <div className={style.smallField}>
                            <LabeledInput
                                title={'Окончание проекта *'}
                                value={moment(briefStageForm.dateEnd)}
                                minDate={moment(briefStageForm.dateStart)}
                                type={InputType.DatePicker}
                                onValueChange={onEndDateChange}
                                readOnly={readOnly}
                            />

                            {datesRange && <div className={style.fieldMessage}>Длительность проекта: {datesRange}</div>}
                        </div>
                    </div>

                    <div className={classNames(style.field, style.line)}>
                        <LabeledSelect
                            title={'Целевая аудитория *'}
                            items={targetGroupsItems}
                            selectedValue={briefStageForm.targetGroup?._id || null}
                            onSelection={onTargetGroupChange}
                            readOnly={readOnly}
                        />
                    </div>
                </div>

                <div className={style.title}>
                    Ролики
                    <span className={style.counter}>
                        {briefStageForm.videos.filter((item) => item.id !== 'new').length}
                    </span>
                </div>

                <div className={style.form}>
                    <div className={style.addVideoButton}>
                        <Button
                            theme={ButtonTheme.MainRounded}
                            disabled={readOnly || briefStageForm.videos.some((item) => item.id === 'new')}
                            onClick={onVideoAddClick}
                        >
                            + Добавить ролик
                        </Button>
                    </div>

                    <div className={style.videosList}>
                        {briefStageForm.videos.map((item, index) => (
                            <div className={style.video} key={index}>
                                <div className={style.video}>
                                    <div className={style.videoName}>
                                        <LabeledInput
                                            title={'Название *'}
                                            value={item.name}
                                            onValueChange={(value) => onVideoNameChange(item.id, value as string)}
                                            readOnly={readOnly}
                                        />
                                    </div>

                                    <div className={style.videoLength}>
                                        <LabeledSelect
                                            title={'Длительность *'}
                                            items={videoDurationsItems}
                                            selectedValue={item.duration}
                                            onSelection={(value) => onVideoDurationChange(item.id, value as number)}
                                            readOnly={readOnly}
                                        />
                                    </div>

                                    {briefStageForm.videos.length > 1 && (
                                        <div
                                            className={style.deleteVideoButton}
                                            onClick={() => onVideoDeleteClick(item.id)}
                                        >
                                            <Icon type={IconType.BIN} svgSize={24} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </OpacityTransition>
    );
};
