import { Dictionary } from 'lodash';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Tag, TagColorType, TagEmojiType } from '@mrm/tags';

import { LoadingStatus } from '@store/commonTypes';

import {
    TagsEditorState as State,
    TagsEditorTagsState as TagsState,
    TagsEditorInstanceState as InstanceState,
    TagsEditorInstanceDescriptor as InstanceDescriptor,
    ComponentState,
    InstanceActionPayload,
} from './types';
import { makeTag } from './misc';

import * as actions from './actions/sync';

class Reducer {
    public static makeInitialState(): State {
        return {
            instances: {},
        };
    }

    public static initInstance(state: State, payload: InstanceActionPayload<InstanceDescriptor>): State {
        const { id, payload: descriptor } = payload;

        return Reducer.instancesReducer(state, (instances) => {
            if (instances[id]) {
                console.warn(`State for descriptor ${id} already exists`);
            } else {
                instances[id] = Reducer.makeEmptyInstanceState(descriptor);
            }

            return instances;
        });
    }

    public static dropInstance(state: State, id: string): State {
        return Reducer.instancesReducer(state, (instances) => {
            delete instances[id];

            return instances;
        });
    }

    public static updateInstanceDescriptor(state: State, payload: InstanceActionPayload<InstanceDescriptor>): State {
        const { id, payload: descriptor } = payload;

        return Reducer.singleInstanceReducer(state, id, (instance) => {
            instance.descriptor = descriptor;

            return instance;
        });
    }

    public static setLoadingStatus(state: State, payload: InstanceActionPayload<LoadingStatus>): State {
        const { id, payload: loadingStatus } = payload;

        return Reducer.singleInstanceReducer(state, id, (instance) => ({
            ...instance,
            loadingStatus,
        }));
    }

    public static setTagIdWithActiveEditor(state: State, payload: InstanceActionPayload<string>): State {
        const { id, payload: tagIdWithActiveEditor } = payload;

        return Reducer.singleInstanceReducer(state, id, (instance) => ({
            ...instance,
            tagIdWithActiveEditor,
        }));
    }

    public static setPendingTag(state: State, payload: InstanceActionPayload<Tag>): State {
        const { id, payload: pending } = payload;

        return Reducer.singleInstanceReducer(state, id, (instance) => Reducer.pendingTagReducer(instance, pending));
    }

    public static setComponentState(state: State, payload: InstanceActionPayload<ComponentState>): State {
        const { id, payload: componentState } = payload;

        return Reducer.singleInstanceReducer(state, id, (instance) => ({
            ...instance,
            componentState,
            previousComponentState:
                instance.componentState === componentState ? instance.previousComponentState : instance.componentState,
        }));
    }

    public static setNewTagInputValue(state: State, payload: InstanceActionPayload<string>): State {
        const { id, payload: newTagInputValue } = payload;

        return Reducer.singleInstanceReducer(state, id, (instance) => ({
            ...instance,
            newTagInputValue,
        }));
    }

    public static setCanEdit(state: State, payload: InstanceActionPayload<boolean>): State {
        const { id, payload: canEdit } = payload;

        return Reducer.singleInstanceReducer(state, id, (instance) => ({
            ...instance,
            canEdit,
        }));
    }

    public static setPendingTagTitle(state: State, payload: InstanceActionPayload<string>): State {
        const { id, payload: title } = payload;

        return Reducer.singleInstanceReducer(state, id, (instance) => Reducer.pendingTagReducer(instance, { title }));
    }

    public static setPendingTagEmoji(state: State, payload: InstanceActionPayload<TagEmojiType>): State {
        const { id, payload: emoji } = payload;

        return Reducer.singleInstanceReducer(state, id, (instance) => Reducer.pendingTagReducer(instance, { emoji }));
    }

    public static setPendingTagColor(state: State, payload: InstanceActionPayload<TagColorType>): State {
        const { id, payload: color } = payload;

        return Reducer.singleInstanceReducer(state, id, (instance) => Reducer.pendingTagReducer(instance, { color }));
    }

    public static setSelectedTags(state: State, payload: InstanceActionPayload<string[]>): State {
        const { id, payload: selected } = payload;

        return Reducer.singleInstanceReducer(state, id, (instance) => Reducer.tagsReducer(instance, { selected }));
    }

    private static tagsReducer(instance: InstanceState, payload: Partial<TagsState>): InstanceState {
        return { ...instance, tags: { ...instance.tags, ...payload } };
    }

    private static pendingTagReducer(instance: InstanceState, payload: Partial<Tag>): InstanceState {
        return Reducer.tagsReducer(instance, { pending: { ...instance.tags.pending, ...payload } });
    }

    private static makeEmptyInstanceState(descriptor: InstanceDescriptor): InstanceState {
        return {
            descriptor,
            componentState: ComponentState.Default,
            previousComponentState: null,
            loadingStatus: LoadingStatus.NOT_LOADED,
            tagIdWithActiveEditor: null,
            newTagInputValue: '',
            tags: {
                pending: makeTag(),
                selected: [],
            },
            canEdit: false,
        };
    }

    private static instancesReducer(
        state: State,
        reducer: (instances: Dictionary<InstanceState>) => Dictionary<InstanceState>,
    ): State {
        const instances = reducer({ ...state.instances });

        return { ...state, instances };
    }

    private static singleInstanceReducer(
        state: State,
        id: string,
        reducer: (instance: InstanceState) => InstanceState,
    ): State {
        return Reducer.instancesReducer(state, (instances) => {
            instances[id] = reducer({ ...instances[id] });

            return instances;
        });
    }
}

export const tagsEditorReducer = reducerWithInitialState(Reducer.makeInitialState());

tagsEditorReducer
    .case(actions.resetState, Reducer.makeInitialState)
    .case(actions.initInstance, Reducer.initInstance)
    .case(actions.dropInstance, Reducer.dropInstance)
    .case(actions.updateInstanceDescriptor, Reducer.updateInstanceDescriptor);

tagsEditorReducer
    .case(actions.setLoadingStatus, Reducer.setLoadingStatus)
    .case(actions.setTagIdWithActiveEditor, Reducer.setTagIdWithActiveEditor)
    .case(actions.setComponentState, Reducer.setComponentState)
    .case(actions.setNewTagInputValue, Reducer.setNewTagInputValue)
    .case(actions.setCanEdit, Reducer.setCanEdit);

tagsEditorReducer
    .case(actions.setPendingTag, Reducer.setPendingTag)
    .case(actions.setPendingTagTitle, Reducer.setPendingTagTitle)
    .case(actions.setPendingTagEmoji, Reducer.setPendingTagEmoji)
    .case(actions.setPendingTagColor, Reducer.setPendingTagColor)
    .case(actions.setSelectedTags, Reducer.setSelectedTags);
