import * as React from 'react';
import classNames from 'classnames';
import { WithTooltip } from 'sber-marketing-ui';

import { CorrectionMenu } from '../CorrectionMenu';
import { CorrectionStatus } from '@mrm/budget';

import * as style from './CorrectionCardHeader.scss';

const STATUS_COLOR = {
    [CorrectionStatus.NeedApproving]: '#f5a623',
    [CorrectionStatus.Approved]: '#19bb4f',
    [CorrectionStatus.Rejected]: '#e63901',
};

type CorrectionIdStyleMap = {
    [P in CorrectionStatus]?: string;
};

const correctionIdStyleMap: CorrectionIdStyleMap = {
    [CorrectionStatus.NeedApproving]: style.correctionIdNeedApproving,
    [CorrectionStatus.Approved]: style.correctionIdApproved,
    [CorrectionStatus.Rejected]: style.correctionIdRejected,
};

interface Props {
    title: string;
    serialNumber: number;
    authorName: string;
    creationDate: string;
    approverName: string;
    url: string;
    status: CorrectionStatus;
    userIsBudgetExpert: boolean;
    userIsAuthorCorrection: boolean;
    onStatusChange: (status: CorrectionStatus) => void;
}

export const CorrectionCardHeader: React.FC<Props> = ({
    title,
    serialNumber,
    authorName,
    creationDate,
    approverName,
    url,
    status,
    userIsBudgetExpert,
    userIsAuthorCorrection,
    onStatusChange,
}) => {
    return (
        <div className={style.root}>
            <div className={style.header}>
                <div className={style.mainInfo}>
                    <WithTooltip content={String(serialNumber)}>
                        <div
                            className={classNames(style.correctionId, correctionIdStyleMap[status])}
                            style={{ color: STATUS_COLOR[status] }}
                            {...{
                                'qa-id': 'correctionCardSerialNumber',
                            }}
                        >
                            {serialNumber}
                        </div>
                    </WithTooltip>

                    <WithTooltip content={title}>
                        <div className={style.title}>{title}</div>
                    </WithTooltip>
                </div>

                <div className={style.correctionMenuWrapper}>
                    <CorrectionMenu
                        status={status}
                        userIsBudgetExpert={userIsBudgetExpert}
                        userIsAuthorCorrection={userIsAuthorCorrection}
                        url={url}
                        onStatusChange={onStatusChange}
                    />
                </div>
            </div>

            <div className={style.participants}>
                <div>
                    <span
                        className={style.userName}
                        {...{
                            'qa-id': 'correctionCardAuthor',
                        }}
                    >
                        {authorName}
                    </span>
                    <span
                        className={style.creationDate}
                        {...{
                            'qa-id': 'correctionCardDate',
                        }}
                    >
                        {creationDate}
                    </span>
                </div>
                <div>
                    <span className={style.userName}>
                        Согласующий эксперт:{' '}
                        <span
                            {...{
                                'qa-id': 'correctionCardApprovingExpert',
                            }}
                        >
                            {approverName}
                        </span>
                    </span>
                </div>
            </div>
        </div>
    );
};
