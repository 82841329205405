import * as React from 'react';
import * as style from './TmEnumCell.scss';

export interface TmEnumCellProps<T> {
    value: keyof T;
    labels: {
        [key in keyof T]: string;
    };
}

export const TmEnumCell = function <T>({ value, labels }: TmEnumCellProps<T>) {
    return <div className={style.cell}>{labels[value]}</div>;
};
