// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-WithRequestInProgressMask-_WithRequestInProgressMask__root{position:relative}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-WithRequestInProgressMask-_WithRequestInProgressMask__mask{position:absolute;width:100%;height:100%;background:rgba(0,0,0,0.05);border-radius:8px;z-index:10}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-WithRequestInProgressMask-_WithRequestInProgressMask__maskJoinTop{border-radius:0 0 8px 8px}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-WithRequestInProgressMask-_WithRequestInProgressMask__maskJoinBottom{border-radius:8px 8px 0 0}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-WithRequestInProgressMask-_WithRequestInProgressMask__maskJoinTopBottom{border-radius:0}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-WithRequestInProgressMask-_WithRequestInProgressMask__content{padding:5px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/WithRequestInProgressMask/WithRequestInProgressMask.scss"],"names":[],"mappings":"AAAA,oIACI,iBAAkB,CACrB,oIAGG,iBAAkB,CAElB,UAAW,CACX,WAAY,CAEZ,2BAA+B,CAC/B,iBAAkB,CAElB,UAAW,CACd,2IAGG,yBAA0B,CAC7B,8IAGG,yBAA0B,CAC7B,iJAGG,eAAgB,CACnB,uIAGG,WAAY","sourcesContent":[".root {\n    position: relative;\n}\n\n.mask {\n    position: absolute;\n    \n    width: 100%;\n    height: 100%;\n\n    background: rgba(0, 0, 0, 0.05);\n    border-radius: 8px;\n\n    z-index: 10;\n}\n\n.maskJoinTop {\n    border-radius: 0 0 8px 8px;\n}\n\n.maskJoinBottom {\n    border-radius: 8px 8px 0 0;\n}\n\n.maskJoinTopBottom {\n    border-radius: 0;\n}\n\n.content {\n    padding: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-WithRequestInProgressMask-_WithRequestInProgressMask__root",
	"mask": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-WithRequestInProgressMask-_WithRequestInProgressMask__mask",
	"maskJoinTop": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-WithRequestInProgressMask-_WithRequestInProgressMask__maskJoinTop",
	"maskJoinBottom": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-WithRequestInProgressMask-_WithRequestInProgressMask__maskJoinBottom",
	"maskJoinTopBottom": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-WithRequestInProgressMask-_WithRequestInProgressMask__maskJoinTopBottom",
	"content": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-WithRequestInProgressMask-_WithRequestInProgressMask__content"
};
export default ___CSS_LOADER_EXPORT___;
