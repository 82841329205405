import * as React from 'react';

import { Item } from './Item';
import type { ItemProps } from './Item';

import * as styles from './styles.scss';

interface Props {
    items: ItemProps[];
}

export const List: React.FC<Props> = ({ items }) => {
    return (
        <div className={styles.root}>
            {items.map((item) => (
                <Item key={item.text} {...item} />
            ))}
        </div>
    );
};
