import * as React from 'react';

import * as style from './ActivityPage.scss';

import {
    ActivityTasksPage,
    AutopilotDOOHPage,
    AutopilotRadioPage,
    AutopilotTvPage,
    AutopilotPage,
    AutopilotPage_legacy,
    CreativePage,
    TaskPage,
} from './pages';
import { BriefPage } from '../brief';
import { HeaderView } from '@common/Page';

export const enum PageMode {
    Brief = 'brief',
    Tasks = 'tasks',
    Task = 'task',
    TaskBrief = 'taskBrief',
    AutopilotDOOH = 'autopilotDOOH',
    AutopilotRadio = 'autopilotRadio',
    AutopilotTv = 'autopilotTv',
    Autopilot = 'autopilot',
    Autopilot_legacy = 'autopilot_legacy',
    Creative = 'creative',
}

export const enum PagePath {
    Brief = '/activity/:activityId/brief',
    Tasks = '/activity/:activityId/tasks',
    Task = '/activity/:activityId/task/:taskId',
    TaskBrief = '/activity/:activityId/task/:taskId/brief',
    AutopilotDOOH = '/activity/:activityId/autopilotDOOH',
    AutopilotRadio = '/activity/:activityId/autopilotRadio',
    AutopilotTv = '/activity/:activityId/autopilotTv',
    Autopilot = '/activity/:activityId/autopilot_v2',
    Autopilot_legacy = '/activity/:activityId/autopilot',
    Creative = '/activity/:activityId/creative/:creativeRequestId',
}

interface Props {
    currentPage: PageMode;
    setHeaderView: (view: HeaderView) => void;
    setHeaderRightContent: (content: JSX.Element) => void;
    setSubHeaderRightContent: (content: JSX.Element) => void;
}

export const ActivityPageTemplate = ({
    currentPage,
    setHeaderView,
    setHeaderRightContent,
    setSubHeaderRightContent,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            {currentPage == PageMode.Tasks && <ActivityTasksPage setHeaderRightContent={setHeaderRightContent} />}
            {currentPage == PageMode.Task && <TaskPage setHeaderRightContent={setHeaderRightContent} />}
            {(currentPage == PageMode.Brief || currentPage == PageMode.TaskBrief) && (
                <BriefPage setHeaderView={setHeaderView} setHeaderRightContent={setHeaderRightContent} />
            )}
            {currentPage == PageMode.AutopilotDOOH && <AutopilotDOOHPage />}
            {currentPage == PageMode.AutopilotRadio && <AutopilotRadioPage />}
            {currentPage == PageMode.AutopilotTv && <AutopilotTvPage />}
            {currentPage == PageMode.Autopilot && <AutopilotPage />}
            {currentPage == PageMode.Autopilot_legacy && <AutopilotPage_legacy />}
            {currentPage == PageMode.Creative && <CreativePage setSubHeaderRightContent={setSubHeaderRightContent} />}
        </div>
    );
};
