import * as React from 'react';

import { Button } from './Button';
import { CreationForm } from './CreationForm';
import { Texts } from './Texts';

import * as style from './ContextStageWidget.scss';

interface Props {
    readOnly?: boolean;
}

export const ContextStageWidget = ({ readOnly }: Props): JSX.Element => {
    const [formVisibility, setFormVisibility] = React.useState(false);

    const onButtonClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(() => {
        setFormVisibility(true);
    }, []);

    const onCreationFormClose = React.useCallback(() => {
        setFormVisibility(false);
    }, []);

    return (
        <div className={style.root}>
            <div className={style.title}>Контекстная рекалама</div>

            <div className={style.form}>
                {formVisibility ? <CreationForm onClose={onCreationFormClose} /> : <Button onClick={onButtonClick} />}
            </div>

            <Texts className={style.texts} />
        </div>
    );
};
