import gql from 'graphql-tag';

export const WITH_COMMENTS_QUERY = gql`
    query withComments($requestId: String!) {
        request: getApprovalRequests(filter: { id: [$requestId] }) {
            nodes {
                comments {
                    id
                    text
                    createTime
                    author {
                        firstName
                        secondName
                        departmentName
                        organizationName
                    }
                    files {
                        id
                        name
                        originName
                        type
                    }
                }
            }
        }
    }
`;

export const CREATE_COMMENT_MUTATION = gql`
    mutation createComment($id: String!, $text: String!, $fileIds: [String]) {
        createComment(comment: { id: $id, text: $text, fileIds: $fileIds })
    }
`;

export const ADD_COMMENT_TO_APPROVAL_REQUEST_MUTATION = gql`
    mutation addCommentToApprovalRequest($requestId: String!, $commentId: String!) {
        addCommentToApprovalRequest(requestId: $requestId, commentId: $commentId)
    }
`;
