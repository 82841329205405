import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { LineId } from '../CreativeTable/types';
import {
    CreativeRequestLine,
    CreativeRequestSubLine,
    CreativeRequestTableItemData,
    CreativeRequestTableItemTaskData,
    CreativeRequestTableSettings,
    CreativeRequestTableSettingsData,
    CreativeRequestContract,
    Dictionary,
    DictionaryType,
} from '@api';
import type { StoreState } from '@store';
import { SaveUserConfig, UserConfigType } from '@store/userConfig/types';

import { MrmClient } from '@api';
import { setSelectedLot1ContractId, setSelectedLot2ContractId } from '@store/pivotTable/actions';
import { getContracts, getSelectedLot1ContractId, getSelectedLot2ContractId } from '@store/pivotTable/selectors';
import { saveUserConfig } from '@store/userConfig/thunks';
import { Tabs } from '@store/userConfig/creative/enums';

export const DICTIONARY_TYPES_TO_LOAD = [DictionaryType.Lot, DictionaryType.Block];

interface Table {
    onLineCreate: (lineId: string) => void;
    onLineUpdate: (lineId: string) => void;
    onLineContractChange: (lineId: string) => void;
    onSubLineCreate: (subLineId: string) => void;
    onSubLineUpdate: (subLineId: string) => void;
    onTableSettingsChange?: () => void;
}

export interface ChildrenProps {
    loading: boolean;
    getLine: (lineId: LineId) => CreativeRequestLine;
    getSubLine: (subLineId: LineId) => CreativeRequestSubLine;
    getLines: () => CreativeRequestLine[];
    getSubLines: () => CreativeRequestSubLine[];
    getSubLinesByLineId: (lineId: LineId) => CreativeRequestSubLine[];
    getTableSettings: () => CreativeRequestTableSettings;
    getDictionaries: () => Partial<Record<DictionaryType, Dictionary[]>>;
    createLine: () => Promise<void>;
    createSubLine: (lineId: string) => Promise<void>;
    transferLine: (lineId: string, contractId: string) => Promise<void>;
    archiveLine: (lineId: string) => Promise<void>;
    restoreLine: (lineId: string) => Promise<void>;
}

interface Props extends ExternalProps, Partial<MapProps & DispatchProps> {}

interface ExternalProps {
    lot: 1 | 2;
    creativeTableRef: React.MutableRefObject<Table>;
    totalTableRef: React.MutableRefObject<Table>;
    creativeRequestId?: string;
    setLot: (lot: 1 | 2) => void;
    onLoad?: () => void;
    onContractChange: (lot: number, contractId: string) => void;
    children: (props: ChildrenProps) => JSX.Element;
}

interface MapProps {
    contracts: Record<'lot1' | 'lot2', CreativeRequestContract[]>;
    selectedLot1ContractId: string;
    selectedLot2ContractId: string;
}

interface DispatchProps {
    setSelectedLot1ContractId: (contractId: string) => void;
    setSelectedLot2ContractId: (contractId: string) => void;
    saveUserConfig: (params: SaveUserConfig<UserConfigType.Creative>) => void;
}

interface State {
    loading: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class WithClientData extends React.PureComponent<Props, State> {
    private lines: CreativeRequestLine[] = [];
    private subLines: CreativeRequestSubLine[] = [];
    private dictionariesByType: Partial<Record<DictionaryType, Dictionary[]>> = {};
    private tableSettings: CreativeRequestTableSettings;

    public constructor(props: Props) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    public async componentDidMount() {
        await this.loadDictionaries();

        if (this.props.creativeRequestId) {
            await this.loadCreativeRequestLines();
            this.openSidebar();
        } else {
            await Promise.all([this.loadLines(), this.loadSubLines(), this.loadTableSettings()]);

            this.subscribeLineCreation(this.onLineCreate);
            this.subscribeSubLineCreation(this.onSubLineCreate);
            this.subscribeLineContractChanged(this.onLineContractChange);
            this.subscribeTableSettingsChanges(this.onTableSettingsChange);
        }

        this.setState(
            {
                loading: false,
            },
            () => {
                if (this.props.onLoad) {
                    this.props.onLoad();
                }
            },
        );
    }

    public async componentDidUpdate(prevProps: Props, prevState: State) {
        const lotChanged = this.props.lot !== prevProps.lot;
        const creativeRequestIdChanged = this.props.creativeRequestId !== prevProps.creativeRequestId;

        const contractChanged =
            this.props.lot === 1
                ? this.props.selectedLot1ContractId !== prevProps.selectedLot1ContractId
                : this.props.selectedLot2ContractId !== prevProps.selectedLot2ContractId;

        if (lotChanged || contractChanged || creativeRequestIdChanged) {
            this.setState({ loading: true });

            this.removeAll();

            await this.loadDictionaries();

            if (this.props.creativeRequestId) {
                await this.loadCreativeRequestLines();
                this.openSidebar();
            } else {
                await Promise.all([this.loadLines(), this.loadSubLines(), this.loadTableSettings()]);
            }

            this.setState(
                {
                    loading: false,
                },
                () => {
                    if (this.props.onLoad) {
                        this.props.onLoad();
                    }
                },
            );
        }
    }

    public render(): JSX.Element {
        const childrenProps = this.makeChildrenProps();

        return this.props.children(childrenProps);
    }

    private makeChildrenProps(): ChildrenProps {
        const { loading } = this.state;

        return {
            loading,
            getLine: this.getLine,
            getSubLine: this.getSubLine,
            getLines: this.getLines,
            getSubLines: this.getSubLines,
            getSubLinesByLineId: this.getSubLinesByLineId,
            getTableSettings: this.getTableSettings,
            getDictionaries: this.getDictionaries,
            createLine: this.createLine,
            createSubLine: this.createSubLine,
            transferLine: this.transferLine,
            archiveLine: this.archiveLine,
            restoreLine: this.restoreLine,
        };
    }

    @autobind
    protected async onLineCreate(payload: { event: string; data: { id: string } }) {
        const lineId = payload.data.id;

        await this.loadLine(lineId);

        const line = this.getLine(lineId);

        if (line) {
            this.props.creativeTableRef.current.onLineCreate(lineId);
            this.props.totalTableRef.current.onLineCreate(lineId);
        }
    }

    @autobind
    protected async onSubLineCreate(payload: { event: string; data: { id: string } }) {
        const subLineId = payload.data.id;

        await this.loadSubLine(subLineId);

        const newSubLine = this.getSubLine(subLineId);

        if (newSubLine) {
            this.props.creativeTableRef.current.onSubLineCreate(subLineId);
            this.props.totalTableRef.current.onSubLineCreate(subLineId);
        }
    }

    @autobind
    protected async onLineContractChange(payload: {
        event: string;
        data: { id: string; newContractId: string; oldContractId: string };
    }) {
        const { id: lineId, newContractId, oldContractId } = payload.data;

        const selectedContractId = this.getSelectedContractId();

        if (newContractId === selectedContractId) {
            await Promise.all([this.loadLine(lineId), this.loadSubLines(lineId)]);

            this.props.creativeTableRef.current.onLineContractChange(lineId);
            this.props.totalTableRef.current.onLineContractChange(lineId);
        }

        if (oldContractId === selectedContractId) {
            const line = this.getLine(lineId);
            const subLines = this.getSubLinesByLineId(lineId);

            this.removeLine(line);

            subLines.forEach((subLine) => {
                this.removeSubLine(subLine);
            });

            this.props.creativeTableRef.current.onLineContractChange(lineId);
            this.props.totalTableRef.current.onLineContractChange(lineId);
        }
    }

    @autobind
    protected async onTableSettingsChange(payload: Partial<CreativeRequestTableSettingsData>) {
        this.props.totalTableRef.current.onTableSettingsChange();
    }

    @autobind
    protected onLineUpdate(updatedData: Partial<CreativeRequestTableItemData>) {
        this.props.creativeTableRef.current.onLineUpdate(updatedData.id);
        this.props.totalTableRef.current.onLineUpdate(updatedData.id);
    }

    @autobind
    protected onSubLineUpdate(updatedData: Partial<CreativeRequestTableItemTaskData>) {
        this.props.creativeTableRef.current.onSubLineUpdate(updatedData.id);
        this.props.totalTableRef.current.onSubLineUpdate(updatedData.id);
    }

    private async loadLines(): Promise<void> {
        const client = await MrmClient.getInstance();

        const lotId = this.getLotDictionaryId();
        const contractId = this.getSelectedContractId();

        if (!!lotId && contractId !== undefined) {
            const lines = await client.domain.creativeRequests.getCreativeRequestTableItems({
                lotId,
                contractId,
            });

            lines.forEach((line) => {
                this.addLine(line);
            });

            this.sortLines();
        }
    }

    private async loadSubLines(lineId?: string): Promise<void> {
        const client = await MrmClient.getInstance();

        const lotId = this.getLotDictionaryId();
        const contractId = this.getSelectedContractId();

        if (!!lotId && contractId !== undefined) {
            const subLines = await client.domain.creativeRequests.getCreativeRequestTableItemTasks({
                lotId,
                contractId,
                itemId: lineId,
            });

            subLines.forEach((subLine) => {
                this.addSubLine(subLine);
            });

            this.sortSubLines();
        }
    }

    private async loadLine(lineId: string): Promise<void> {
        const alreadyLoaded = this.lines.some((item) => item.model.id === lineId);

        if (!alreadyLoaded) {
            const client = await MrmClient.getInstance();

            const line: CreativeRequestLine = await client.domain.creativeRequests.getCreativeRequestTableItem({
                id: lineId,
            });

            const selectedContractId = this.getSelectedContractId();
            const lineContractId = line.model.contract?.id || null;

            const lotMatches = this.getLineLot(line) === this.props.lot;
            const contractMatches = selectedContractId !== undefined && selectedContractId === lineContractId;

            if (lotMatches && contractMatches) {
                this.addLine(line);
                this.sortLines();
            }
        }
    }

    private async loadSubLine(subLineId: string): Promise<void> {
        const { lot } = this.props;

        const alreadyLoaded = this.subLines.some((item) => item.model.id === subLineId);

        if (!alreadyLoaded) {
            const client = await MrmClient.getInstance();

            const subLine: CreativeRequestSubLine =
                await client.domain.creativeRequests.getCreativeRequestTableItemTask({ id: subLineId });

            const selectedContractId = this.getSelectedContractId();

            const line = this.getLine(subLine.model.itemId);
            const lineContractId = line?.model.contract?.id || null;

            const lotMatches = this.getLineLot(this.getLine(subLine.model.itemId)) === lot;
            const contractMatches = selectedContractId !== undefined && selectedContractId === lineContractId;

            if (lotMatches && contractMatches) {
                this.addSubLine(subLine);
                this.sortSubLines();
            }
        }
    }

    private async loadDictionaries(): Promise<void> {
        const client = await MrmClient.getInstance();

        await Promise.all(
            DICTIONARY_TYPES_TO_LOAD.map(async (dictionaryType) => {
                const dictionaries = await client.Dictionary.getByType(dictionaryType as any);

                this.dictionariesByType[dictionaryType] = dictionaries;
            }),
        );
    }

    private async loadTableSettings(): Promise<void> {
        const client = await MrmClient.getInstance();

        this.tableSettings = await client.domain.creativeRequests.getCreativeRequestTableSettings();
    }

    private async loadCreativeRequestLines(): Promise<void> {
        const { creativeRequestId, lot: selectedLot, selectedLot1ContractId, selectedLot2ContractId } = this.props;

        const client = await MrmClient.getInstance();

        const creativeRequest = await client.domain.creativeRequests.getCreativeRequest({ id: creativeRequestId });

        const lotDictionary = await creativeRequest.model.lot;
        const contractId = (creativeRequest.model as any).contractId;

        const lot = parseInt(lodash.first(lotDictionary.value?.match(/\d/g)), 10) as 1 | 2;

        if (selectedLot !== lot) {
            this.props.setLot(lot);
        }

        const selectedContractId = selectedLot === 1 ? selectedLot1ContractId : selectedLot2ContractId;

        if (selectedContractId !== contractId) {
            this.setSelectedContractId(contractId);
        }

        const lines = await client.domain.creativeRequests.getCreativeRequestTableItems({
            lotId: lotDictionary.id,
            contractId,
        });

        const line = lines.find((item) => item.model.creativeRequestId === creativeRequestId);

        this.addLine(line);

        await Promise.all([this.loadSubLines(line.model.id), this.loadTableSettings()]);

        this.subscribeLineCreation(this.onLineCreate);
        this.subscribeSubLineCreation(this.onSubLineCreate);
        this.subscribeLineContractChanged(this.onLineContractChange);
        this.subscribeTableSettingsChanges(this.onTableSettingsChange);
    }

    private addLine(line: CreativeRequestLine) {
        const alreadyAdded = this.lines.some((item) => item.model.id === line.model.id);

        if (!alreadyAdded) {
            this.lines.push(line);

            this.subscribeLineChanges(line.model.id, this.onLineUpdate);
        }
    }

    private removeLine(line: CreativeRequestLine) {
        this.lines = lodash.without(this.lines, line);

        this.unsubscribeLineChanges(line.model.id, this.onLineUpdate);
    }

    private addSubLine(subLine: CreativeRequestSubLine) {
        const alreadyAdded = this.subLines.some((item) => item.model.id === subLine.model.id);

        if (!alreadyAdded) {
            this.subLines.push(subLine);

            this.subscribeSubLineChanges(subLine.model.id, this.onSubLineUpdate);
        }
    }

    private removeSubLine(subLine: CreativeRequestSubLine) {
        this.subLines = lodash.without(this.subLines, subLine);

        this.unsubscribeSubLineChanges(subLine.model.id, this.onSubLineUpdate);
    }

    private removeAll() {
        this.lines.forEach((line) => {
            this.unsubscribeLineChanges(line.model.id, this.onLineUpdate);
        });

        this.subLines.forEach((subLine) => {
            this.unsubscribeSubLineChanges(subLine.model.id, this.onSubLineUpdate);
        });

        this.lines = [];
        this.subLines = [];
    }

    private sortLines() {
        this.lines = lodash.sortBy(this.lines, (item) => item.model.number);
    }

    private sortSubLines() {
        this.subLines = lodash.sortBy(this.subLines, (item) => item.model.number);
    }

    private subscribeLineChanges(lineId: string, handler: (data: Partial<CreativeRequestTableItemData>) => void) {
        const line = this.getLine(lineId);

        if (line) {
            line.events.onUpdated(handler);
        }
    }

    private unsubscribeLineChanges(lineId: string, handler: (data: Partial<CreativeRequestTableItemData>) => void) {
        const line = this.getLine(lineId);

        if (line) {
            line.events.offUpdated(handler);
        }
    }

    private subscribeSubLineChanges(
        subLineId: string,
        handler: (data: Partial<CreativeRequestTableItemTaskData>) => void,
    ) {
        const subLine = this.getSubLine(subLineId);

        if (subLine) {
            subLine.events.onUpdated(handler);
        }
    }

    private unsubscribeSubLineChanges(
        subLineId: string,
        handler: (data: Partial<CreativeRequestTableItemTaskData>) => void,
    ) {
        const subLine = this.getSubLine(subLineId);

        if (subLine) {
            subLine.events.offUpdated(handler);
        }
    }

    private async subscribeLineCreation(handler: (payload: { event: string; data: { id: string } }) => void) {
        const client = await MrmClient.getInstance();

        client.events.creativeRequests.on('TableItemCreated', handler as any);
    }

    private async subscribeSubLineCreation(handler: (payload: { event: string; data: { id: string } }) => void) {
        const client = await MrmClient.getInstance();

        client.events.creativeRequests.on('TableItemTaskCreated', handler as any);
    }

    private async subscribeLineContractChanged(
        handler: (payload: {
            event: string;
            data: { id: string; newContractId: string; oldContractId: string };
        }) => void,
    ) {
        const client = await MrmClient.getInstance();

        client.events.creativeRequests.on('TableItemContractChanged', handler as any);
    }

    private async subscribeTableSettingsChanges(handler: (data: Partial<CreativeRequestTableSettingsData>) => void) {
        this.tableSettings.events.onUpdated(handler);
    }

    @autobind
    private getLine(lineId: LineId): CreativeRequestLine {
        return this.lines.find((item) => item.model.id === lineId) || null;
    }

    @autobind
    private getSubLine(subLineId: LineId): CreativeRequestSubLine {
        return this.subLines.find((item) => item.model.id === subLineId) || null;
    }

    @autobind
    private getSubLinesByLineId(lineId: LineId): CreativeRequestSubLine[] {
        return this.subLines.filter((item) => item.model.itemId === lineId) || [];
    }

    @autobind
    private getLines(): CreativeRequestLine[] {
        return this.lines || [];
    }

    @autobind
    private getSubLines(): CreativeRequestSubLine[] {
        return this.subLines || [];
    }

    @autobind
    private getLineLot(line: CreativeRequestLine): number {
        if (!line) {
            return null;
        }

        const lotDictionary = line.model.dictionary.lot;

        return parseInt(lodash.last(lotDictionary.value.split(' ')), 10);
    }

    @autobind
    private getDictionaries(): Partial<Record<DictionaryType, Dictionary[]>> {
        return this.dictionariesByType;
    }

    @autobind
    private getTableSettings(): CreativeRequestTableSettings {
        return this.tableSettings;
    }

    @autobind
    private async createLine() {
        const client = await MrmClient.getInstance();

        const lotId = this.getLotDictionaryId();
        const contractId = this.getSelectedContractId();

        await client.api.creativeRequests.createCreativeRequestTableItem({
            dictionaryId: lotId,
            contractId,
        });
    }

    @autobind
    private async createSubLine(lineId: string) {
        const client = await MrmClient.getInstance();

        await client.api.creativeRequests.createCreativeRequestTableItemTask({
            itemId: lineId,
        });
    }

    @autobind
    private async transferLine(lineId: string, contractId: string) {
        const line = this.getLine(lineId);

        await line.model.setContract({ contractId });
    }

    @autobind
    private async archiveLine(lineId: string) {
        const subLine = this.getSubLine(lineId);

        if (subLine) {
            await subLine.model.archive();
        } else {
            const line = this.getLine(lineId);

            const subLines = this.getSubLinesByLineId(lineId);

            await Promise.all([
                line.model.archive(),
                subLines.filter((item) => !item.model.isArchived).map((item) => item.model.archive()),
            ]);
        }
    }

    @autobind
    private async restoreLine(lineId: string) {
        const subLine = this.getSubLine(lineId);

        if (subLine) {
            await subLine.model.restore();
        } else {
            const line = this.getLine(lineId);

            await line.model.restore();
        }
    }

    @autobind
    private getLotDictionaryId(): string {
        const { lot } = this.props;

        if (!lot) {
            return null;
        }

        const lotDictionaries = this.dictionariesByType['lot'];

        const lotDictionary = lotDictionaries.find((item) => lodash.last(item.value.split(' ')) === lot.toString());

        return lotDictionary?.id || null;
    }

    private getSelectedContractId(): string {
        const { lot, selectedLot1ContractId, selectedLot2ContractId } = this.props;

        return lot === 1 ? selectedLot1ContractId : selectedLot2ContractId;
    }

    @autobind
    private setSelectedContractId(contractId: string) {
        const { lot } = this.props;

        if (lot === 1) {
            this.props.setSelectedLot1ContractId(contractId);
            this.props.onContractChange(1, contractId);
        } else {
            this.props.setSelectedLot2ContractId(contractId);
            this.props.onContractChange(2, contractId);
        }
    }

    @autobind
    private openSidebar() {
        this.props.saveUserConfig({
            type: UserConfigType.Creative,
            payload: {
                sidebar: {
                    visibility: true,
                    selectedTab: Tabs.Comments,
                },
            },
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        contracts: getContracts(state),
        selectedLot1ContractId: getSelectedLot1ContractId(state),
        selectedLot2ContractId: getSelectedLot2ContractId(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setSelectedLot1ContractId,
            setSelectedLot2ContractId,
            saveUserConfig,
        },
        dispatch,
    );
}
