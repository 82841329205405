import { useCallback, useState, useEffect } from 'react';
import { MrmClient } from '@api';
import type { CreativeRequest as CreativeRequestDomain } from '@api';

interface UseCreativeRequestDomain {
    (params: UseCreativeRequestDomainParams): UseCreativeRequestDomainReturn;
}

interface UseCreativeRequestDomainParams {
    creativeRequestId: string;
}

interface UseCreativeRequestDomainReturn {
    creativeRequestDomain: CreativeRequestDomain;
}

interface RequestCreativeRequestDomain {
    (): Promise<CreativeRequestDomain>;
}

interface LoadCreativeRequestDomain {
    (): void;
}

interface Subscribe {
    (): void;
}

interface Unsubscribe {
    (): void;
}

export const useCreativeRequestDomain: UseCreativeRequestDomain = ({ creativeRequestId }) => {
    const [creativeRequestDomain, setCreativeRequestDomain] = useState<CreativeRequestDomain>();

    const requestCreativeRequestDomain: RequestCreativeRequestDomain = useCallback(async () => {
        try {
            const client = await MrmClient.getInstance();
            return client.domain.creativeRequests.getCreativeRequest({ id: creativeRequestId });
        } catch (error) {
            console.error(error);
            return null;
        }
    }, [creativeRequestId]);

    const loadCreativeRequestDomain: LoadCreativeRequestDomain = useCallback(async () => {
        setCreativeRequestDomain(await requestCreativeRequestDomain());
    }, []);

    const subscribe: Subscribe = useCallback(() => {
        creativeRequestDomain?.events?.onReloaded(loadCreativeRequestDomain);
    }, [creativeRequestDomain]);

    const unsubscribe: Unsubscribe = useCallback(() => {
        creativeRequestDomain?.events?.offReloaded(loadCreativeRequestDomain);
    }, [creativeRequestDomain]);

    useEffect(() => loadCreativeRequestDomain(), [creativeRequestId]);
    useEffect(() => {
        subscribe();
        return () => {
            unsubscribe();
        };
    }, [creativeRequestDomain]);

    return {
        creativeRequestDomain,
    };
};

export type { CreativeRequestDomain };
