import { axios } from '../lib/axios';

import { GetEditorPayload, GetEditorResponse, GetDocumentStatusPayload, GetDocumentStatusResponse } from '@mrm/R7';

const BASE_PATH = '/api/r7';

export class R7Api {
    public static async getEditor(params: GetEditorPayload): Promise<GetEditorResponse> {
        const res = await axios.post<GetEditorResponse>(`${BASE_PATH}/editor`, params);

        return res.data;
    }

    public static async getDocumentStatus(params: GetDocumentStatusPayload): Promise<GetDocumentStatusResponse> {
        const res = await axios.post<GetDocumentStatusResponse>(`${BASE_PATH}/document`, params);

        return res.data;
    }
}

// window.openEditor = async () => {
//     const editorContent = (await R7Api.getEditor({
//         fileName: "category-theory-for-programmers",
//         fileType: "pdf",
//         fileId: "958cba64-ec41-48f2-a235-1df3638066db",
//         taskId: "f58da42b-d7fb-4780-9e8e-e1d9784b444a",
//         commentId: "2cf2368a-d182-4e87-9930-395a5d7b1cd8"
//     })).content;

//     const editorWindow = window.open("", "_blank");

//     const cssResetLink = document.createElement('link');
//     cssResetLink.href = "http://web.simmons.edu/~grovesd/comm244/notes/week4/reset.css";
//     cssResetLink.rel = "stylesheet";
//     cssResetLink.type = "text/css";
//     editorWindow.document.head.appendChild(cssResetLink);

//     const frame = document.createElement('iframe');
//     frame.style.width="100vw";
//     frame.style.height="100vh";
//     editorWindow.document.body.appendChild(frame);
//     frame.contentDocument.write(editorContent);
// };
