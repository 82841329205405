// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Content-List-Header-_styles__root{display:grid;grid-template-rows:auto;grid-row-gap:7px;font-family:'Open Sans';font-size:12px}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Content-List-Header-_styles__title{font-weight:600;font-size:12px;letter-spacing:0.2px;color:#092A3A}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Content-List-Header-_styles__subtitle{font-weight:400;color:#7E8681}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/CreationForm/GeneratedTextEditor/Editor/Content/List/Header/styles.scss"],"names":[],"mappings":"AAAA,uJACI,YAAa,CACb,uBAAwB,CACxB,gBAAiB,CAEjB,uBAAwB,CACxB,cAAe,CAClB,wJAGG,eAAgB,CAChB,cAAe,CACf,oBAAqB,CACrB,aAAc,CACjB,2JAGG,eAAgB,CAChB,aAAc","sourcesContent":[".root {\n    display: grid;\n    grid-template-rows: auto;\n    grid-row-gap: 7px;\n\n    font-family: 'Open Sans';\n    font-size: 12px;\n}\n\n.title {\n    font-weight: 600;\n    font-size: 12px;\n    letter-spacing: 0.2px;\n    color: #092A3A;\n}\n\n.subtitle {\n    font-weight: 400;\n    color: #7E8681;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Content-List-Header-_styles__root",
	"title": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Content-List-Header-_styles__title",
	"subtitle": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Content-List-Header-_styles__subtitle"
};
export default ___CSS_LOADER_EXPORT___;
