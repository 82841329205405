import * as React from 'react';
import classNames from 'classnames';

import * as style from './PlacementStatusEditor.scss';

import type { AutopilotPlacementStatus } from 'sber-marketing-types/backend';

import { Icon, IconType } from 'sber-marketing-ui';

interface Props {
    isOpened: boolean;
    status: AutopilotPlacementStatus;
    onOpenerClick: () => void;
    onSelection: (value: AutopilotPlacementStatus) => void;
}

export const PlacementStatusEditorTemplate = ({ isOpened, status, onOpenerClick, onSelection }: Props): JSX.Element => {
    const items: AutopilotPlacementStatus[] = [
        'На модерации',
        'Ошибка размещения',
        'Отклонено модератором',
        'Запущено',
        'Приостановлено',
        'Завершено',
    ];

    return (
        <div className={style.root}>
            <div
                className={classNames(
                    style.opener,
                    ['Запущено', 'Завершено'].includes(status) && style.running,
                    ['Ошибка размещения', 'Отклонено модератором'].includes(status) && style.stopped,
                    ['На модерации', 'Приостановлено', 'Неизвестный статус'].includes(status) && style.moderation,
                )}
                onClick={onOpenerClick}
            >
                {status}

                <div className={style.openerIcon}>
                    <Icon type={isOpened ? IconType.ARROW16_UP_BLACK : IconType.ARROW16_DOWN_BLACK} svgSize={16} />
                </div>
            </div>

            {isOpened && (
                <div className={style.content}>
                    <div className={style.title}>Изменить статус</div>

                    {items.map((item) => (
                        <div className={style.item} key={item} onClick={() => onSelection(item)}>
                            {item}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
