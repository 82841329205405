import { intersection, values } from 'lodash';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Failure, Success } from 'typescript-fsa';

import { LoadingStatus } from '@store/commonTypes';

import type {
    InitParams,
    InitResult,
    SetSelectedBudgetItemIdsParams,
    State,
    UpdateParams,
    UpdateResult,
    BudgetItem,
    SetBudgetParams,
    SetBudgetResult,
} from './types';
import * as asyncActions from './actions/async';
import * as syncActions from './actions/sync';

class Reducer {
    public static makeInitialState(): State {
        return {
            params: {
                selectedCreativeRequestId: null,
                selectedBudgetItemIds: [],
            },
            data: {
                creativeRequests: {
                    loading: LoadingStatus.NOT_LOADED,
                    items: {},
                },
                budgetItems: {
                    loading: LoadingStatus.NOT_LOADED,
                    items: {},
                },
            },
        };
    }

    public static initStarted(state: State, payload: InitParams): State {
        return state;
    }

    public static initDone(state: State, payload: Success<InitParams, InitResult>): State {
        const {
            params: { creativeRequestId },
            result: { creativeRequests, budgetItems },
        } = payload;

        return {
            ...state,
            params: {
                selectedCreativeRequestId: creativeRequestId,
                selectedBudgetItemIds: creativeRequests[creativeRequestId].budgetItems.map(({ id }) => id),
            },
            data: {
                creativeRequests: {
                    loading: LoadingStatus.LOADED,
                    items: creativeRequests,
                },
                budgetItems: {
                    loading: LoadingStatus.LOADED,
                    items: budgetItems,
                },
            },
        };
    }

    public static initFailed(state: State, payload: Failure<InitParams, Error>): State {
        return state;
    }

    public static saveStarted(state: State): State {
        return state;
    }

    public static saveDone(state: State): State {
        return state;
    }

    public static saveFailed(state: State): State {
        return state;
    }

    public static updateStarted(state: State): State {
        return {
            ...state,
            data: {
                ...state.data,
                creativeRequests: {
                    ...state.data.creativeRequests,
                    loading: LoadingStatus.LOADING,
                },
                budgetItems: {
                    ...state.data.budgetItems,
                    loading: LoadingStatus.LOADING,
                },
            },
        };
    }

    public static updateDone(state: State, payload: Success<UpdateParams, UpdateResult>): State {
        const {
            result: { creativeRequests, budgetItems },
        } = payload;

        return {
            ...state,
            params: {
                ...state.params,
                selectedBudgetItemIds: updateSelectedBudgetItemIds(state.params.selectedBudgetItemIds, { budgetItems }),
            },
            data: {
                creativeRequests: {
                    loading: LoadingStatus.LOADED,
                    items: creativeRequests,
                },
                budgetItems: {
                    loading: LoadingStatus.LOADED,
                    items: budgetItems,
                },
            },
        };
    }

    public static updateFailed(state: State): State {
        return state;
    }

    public static setBudgetStarted(state: State): State {
        return {
            ...state,
            data: {
                creativeRequests: {
                    ...state.data.creativeRequests,
                    loading: LoadingStatus.LOADING,
                },
                budgetItems: {
                    ...state.data.budgetItems,
                    loading: LoadingStatus.LOADING,
                },
            },
        };
    }

    public static setBudgetDone(state: State, payload: Success<SetBudgetParams, SetBudgetResult>): State {
        const {
            result: { creativeRequests, budgetItems },
        } = payload;

        return {
            ...state,
            params: {
                ...state.params,
                selectedBudgetItemIds: creativeRequests[state.params.selectedCreativeRequestId].budgetItems.map(
                    ({ id }) => id,
                ),
            },
            data: {
                creativeRequests: {
                    loading: LoadingStatus.LOADED,
                    items: creativeRequests,
                },
                budgetItems: {
                    loading: LoadingStatus.LOADED,
                    items: budgetItems,
                },
            },
        };
    }

    public static setBudgetFailed(state: State): State {
        return state;
    }

    public static setSelectedBudgetItemIds(
        state: State,
        { selectedBudgetItemIds }: SetSelectedBudgetItemIdsParams,
    ): State {
        return {
            ...state,
            params: {
                ...state.params,
                selectedBudgetItemIds,
            },
        };
    }
}

const updateSelectedBudgetItemIds = (
    selectedBudgetItemIds: string[],
    { budgetItems }: { budgetItems: Record<string, BudgetItem> },
) => {
    const budgetItemsIds = values(budgetItems).map(({ id }) => id);
    return intersection(selectedBudgetItemIds, budgetItemsIds);
};

export const creativeReferenceMenuReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(asyncActions.init.started, Reducer.initStarted)
    .case(asyncActions.init.done, Reducer.initDone)
    .case(asyncActions.init.failed, Reducer.initFailed)
    .case(asyncActions.save.started, Reducer.saveStarted)
    .case(asyncActions.save.done, Reducer.saveDone)
    .case(asyncActions.save.failed, Reducer.saveFailed)
    .case(asyncActions.update.started, Reducer.updateStarted)
    .case(asyncActions.update.done, Reducer.updateDone)
    .case(asyncActions.update.failed, Reducer.updateFailed)
    .case(asyncActions.reset.done, Reducer.makeInitialState)
    .case(asyncActions.setBudget.started, Reducer.setBudgetStarted)
    .case(asyncActions.setBudget.done, Reducer.setBudgetDone)
    .case(asyncActions.setBudget.failed, Reducer.setBudgetFailed)
    .case(syncActions.setSelectedBudgetItemIds, Reducer.setSelectedBudgetItemIds);
