import * as React from 'react';
import autobind from 'autobind-decorator';

import { ActivitySuggestTemplate } from './ActivitySuggestTemplate';
import { ChildrenProps } from './ActivitySuggestConnected';

interface Props extends ChildrenProps {
    budgetId: string;
    isNameInputFocused: boolean;
    nameInputValue: string;
    onItemClick: (activityBudgetId: string, activityBudgetName: string) => void;
}

interface State {
    hoveredItemId: string;
}

export class ActivitySuggestContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            hoveredItemId: null,
        };
    }

    public render(): JSX.Element {
        return (
            <ActivitySuggestTemplate
                {...this.props}
                hoveredItemId={this.state.hoveredItemId}
                onMouseOver={this.onMouseOver}
            />
        );
    }

    @autobind
    private onMouseOver(activityBudgetId: string) {
        this.setState({
            hoveredItemId: activityBudgetId,
        });
    }
}
