// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-Header-MenuItemDropdown-_styles__root{position:relative}.src-client-modules-activity-Header-MenuItemDropdown-_styles__menuItem{display:flex;align-items:center}.src-client-modules-activity-Header-MenuItemDropdown-_styles__listWrapper{position:absolute;top:calc(100% + 4px);left:-10px}.src-client-modules-activity-Header-MenuItemDropdown-_styles__arrowUp{transform:rotate(-90deg)}.src-client-modules-activity-Header-MenuItemDropdown-_styles__arrowDown{transform:rotate(90deg) translateY(-2px)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/Header/MenuItemDropdown/styles.scss"],"names":[],"mappings":"AAAA,mEACI,iBAAkB,CACrB,uEAGG,YAAa,CACb,kBAAmB,CACtB,0EAGG,iBAAkB,CAClB,oBAAqB,CACrB,UAAW,CACd,sEAGG,wBAAyB,CAC5B,wEAGG,wCAAyC","sourcesContent":[".root {\n    position: relative;\n}\n\n.menuItem {\n    display: flex;\n    align-items: center;\n}\n\n.listWrapper {\n    position: absolute;\n    top: calc(100% + 4px);\n    left: -10px;\n}\n\n.arrowUp {\n    transform: rotate(-90deg);\n}\n\n.arrowDown {\n    transform: rotate(90deg) translateY(-2px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-Header-MenuItemDropdown-_styles__root",
	"menuItem": "src-client-modules-activity-Header-MenuItemDropdown-_styles__menuItem",
	"listWrapper": "src-client-modules-activity-Header-MenuItemDropdown-_styles__listWrapper",
	"arrowUp": "src-client-modules-activity-Header-MenuItemDropdown-_styles__arrowUp",
	"arrowDown": "src-client-modules-activity-Header-MenuItemDropdown-_styles__arrowDown"
};
export default ___CSS_LOADER_EXPORT___;
