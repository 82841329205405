import * as React from 'react';
import { connect } from 'react-redux';

import { RequestStageWidgetTemplate } from './RequestStageWidgetTemplate';
import type { StoreState } from '@store';
import { CreativeRequestDomain } from '@store/creative/types';
import { getCreativeRequestDomain } from '@store/creative/selectors';

interface Props extends MapProps, DispatchProps {
    creativeRequestId: string;
    activityId: number;
}

interface MapProps {
    creativeRequestDomain?: CreativeRequestDomain;
}

interface DispatchProps {}

@(connect(mapStateToProps, null) as any)
export class RequestStageWidgetBehaviour extends React.PureComponent<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        const { creativeRequestId } = this.props;

        return React.createElement(RequestStageWidgetTemplate, {
            loading: false,
            creativeRequestId,
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        creativeRequestDomain: getCreativeRequestDomain(state),
    };
}
