import * as React from 'react';

import { Button } from './Button';
import { Dropdown } from './Dropdown';

import * as styles from './styles.scss';

interface Props {}

export const ActionsMenu: React.FC<Props> = () => {
    const rootRef = React.useRef<HTMLDivElement>(null);
    const [isVisibleDropdown, setDropdownVisibility] = React.useState<boolean>(false);

    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (rootRef.current && !rootRef.current.contains(event.target as Node)) {
                setDropdownVisibility(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const onButtonClick = React.useCallback(() => {
        setDropdownVisibility(!isVisibleDropdown);
    }, [isVisibleDropdown]);

    return (
        <div className={styles.root} ref={rootRef}>
            <Button onClick={onButtonClick} />
            {isVisibleDropdown && <Dropdown />}
        </div>
    );
};
