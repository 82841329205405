import * as React from 'react';
import * as lodash from 'lodash';
import classNames from 'classnames';

import * as style from './CardGroup.scss';

import { Card } from '../../types';

import { Icon, IconType, TogglePosition } from 'sber-marketing-ui';
import { CardDeleteConfirm } from '../CardDeleteConfirm';
import { ActivityCardPreloader } from '../ActivityCardPreloader';
import { ActivityCard } from '../ActivityCard';
import { Switch } from './Switch';

export const MIN_COLUMN_WIDTH = 253;
export const MARGIN = 16;

interface Props {
    title: string;
    cards: Card[];
    cardOnDeleteConfirmation: Card;
    columnsCount: number;
    switchPosition: TogglePosition;
    loading: boolean;
    isOpened: boolean;
    totalCardsCount: number;
    userHasBudgetAccess: boolean;
    onOpenerClick: () => void;
    onCardDeleteClick: (cardId: string) => void;
    onCardDeleteConfirm: () => Promise<void>;
    onCardDeleteCancel: () => void;
    onSwitch: (position: TogglePosition) => void;
    rootRef: React.RefObject<HTMLDivElement>;
}

export function CardGroup({
    title,
    cards,
    cardOnDeleteConfirmation,
    columnsCount,
    switchPosition,
    loading,
    isOpened,
    totalCardsCount,
    userHasBudgetAccess,
    onOpenerClick,
    onCardDeleteClick,
    onCardDeleteConfirm,
    onCardDeleteCancel,
    onSwitch,
    rootRef,
}: Props): JSX.Element {
    const columns = groupCardsByColumns(addCardPreloaders(cards, loading, columnsCount), columnsCount);

    return (
        <div
            className={style.root}
            ref={rootRef}
            {...{
                'qa-id': 'dashboardCargGroup',
            }}
        >
            <div className={style.opener}>
                <GroupOpener
                    title={title}
                    cardsCount={cards.length}
                    switchPosition={switchPosition}
                    isOpened={isOpened}
                    totalCardsCount={totalCardsCount}
                    onOpenerClick={onOpenerClick}
                    onSwitch={onSwitch}
                />
            </div>

            {isOpened && (!!cards.length || loading) && (
                <div className={style.columns}>
                    {columns.map((columnCards, columnIndex) => (
                        <div
                            key={columnIndex}
                            className={style.column}
                            style={{ width: `calc((100% - ${MARGIN}px * ${columnsCount - 1}) / ${columnsCount})` }}
                        >
                            {columnCards.map((item, index) => (
                                <div key={index} className={style.card}>
                                    {item === null ? (
                                        <ActivityCardPreloader />
                                    ) : (
                                        <ActivityCard
                                            card={item}
                                            userHasBudgetAccess={userHasBudgetAccess}
                                            onDeleteButtonClick={onCardDeleteClick}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            )}

            {isOpened && !loading && lodash.isEmpty(cards) && (
                <div className={style.noCardsStub}>
                    <div className={style.noCardsStubContent}>Нет результатов</div>
                </div>
            )}

            {cardOnDeleteConfirmation && (
                <CardDeleteConfirm
                    activityId={cardOnDeleteConfirmation.project.id}
                    activityName={cardOnDeleteConfirmation.project.name}
                    serialNumber={cardOnDeleteConfirmation.budgetItem.serialNumber}
                    onConfirmButtonClick={onCardDeleteConfirm}
                    onCancelButtonClick={onCardDeleteCancel}
                />
            )}
        </div>
    );
}

interface GroupOpenerProps {
    title: string;
    cardsCount: number;
    switchPosition: TogglePosition;
    isOpened: boolean;
    totalCardsCount: number;
    onOpenerClick: () => void;
    onSwitch: (position: TogglePosition) => void;
}

function GroupOpener({
    title,
    cardsCount,
    switchPosition,
    isOpened,
    totalCardsCount,
    onOpenerClick,
    onSwitch,
}: GroupOpenerProps): JSX.Element {
    return (
        <>
            <div className={classNames(style.titleWrapper, isOpened && style.opened)} onClick={onOpenerClick}>
                <Icon className={style.openerIcon} svgSize={8} type={IconType.TRIANGLE8_DOWN} />

                <div className={style.title} title={title}>
                    {title}
                </div>

                <div className={style.cardsCount}>
                    {cardsCount} из {totalCardsCount}
                </div>
            </div>

            <div className={style.toggle}>
                <Switch position={switchPosition} onSwitch={onSwitch} />
            </div>
        </>
    );
}

function groupCardsByColumns<T>(cards: T[], columnsCount: number): T[][] {
    return lodash.times(columnsCount, (columnIndex) => {
        const columnCards: T[] = [];

        let cardIndex = 0;

        while (cardIndex < cards.length) {
            if (cardIndex % columnsCount == columnIndex) {
                columnCards.push(cards[cardIndex]);
            }

            cardIndex += 1;
        }

        return columnCards;
    });
}

function addCardPreloaders<T>(cards: T[], loading: boolean, columnsCount: number): T[] {
    if (loading) {
        const newList = lodash.clone(cards);

        let cardsCountToAdd = 12;
        if (!lodash.isEmpty(cards)) {
            const nRowsWithPreloader = Math.ceil(cards.length / columnsCount) + 1;
            cardsCountToAdd = nRowsWithPreloader * columnsCount - cards.length;
        }

        lodash.times(cardsCountToAdd, () => {
            newList.push(null);
        });

        return newList;
    }

    return cards;
}
