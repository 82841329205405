import * as React from 'react';
import type { WorkType } from 'sber-marketing-types/frontend';

import { useGetWorkTypesQuery } from '@api';

import { OptionProps } from '@common/components';

export interface UseWorkTypeOptions extends WorkType.ListQueryParams {}

export function useWorkTypeOptions(options: UseWorkTypeOptions): [OptionProps<WorkType.Data>[], boolean] {
    const { isLoading, data } = useGetWorkTypesQuery(options);

    return [
        React.useMemo<OptionProps<WorkType.Data>[]>(
            () =>
                data?.map((value) => ({
                    value,
                    title: value.name,
                })) || [],
            [data, isLoading],
        ),
        isLoading,
    ];
}
