// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-TaskExecutor-_TaskExecutor__root{width:100%}.src-client-modules-activity-pages-task-TaskExecutor-_TaskExecutor__buttons{display:flex;margin-bottom:16px}.src-client-modules-activity-pages-task-TaskExecutor-_TaskExecutor__button{display:block;width:157px;height:32px;margin-right:14px}.src-client-modules-activity-pages-task-TaskExecutor-_TaskExecutor__button:last-child{margin-right:0}.src-client-modules-activity-pages-task-TaskExecutor-_TaskExecutor__tagsEditor{margin:10px 0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/TaskExecutor/TaskExecutor.scss"],"names":[],"mappings":"AAAA,yEACI,UAAW,CACd,4EAGG,YAAa,CAEb,kBAAmB,CACtB,2EAGG,aAAc,CAEd,WAAY,CACZ,WAAY,CACZ,iBAAkB,CALtB,sFAQQ,cAAe,CAClB,+EAID,aAAc","sourcesContent":[".root {\n    width: 100%;\n}\n\n.buttons {\n    display: flex;\n\n    margin-bottom: 16px;\n}\n\n.button {\n    display: block;\n\n    width: 157px;\n    height: 32px;\n    margin-right: 14px;\n\n    &:last-child {\n        margin-right: 0;\n    }\n}\n\n.tagsEditor {\n    margin: 10px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-TaskExecutor-_TaskExecutor__root",
	"buttons": "src-client-modules-activity-pages-task-TaskExecutor-_TaskExecutor__buttons",
	"button": "src-client-modules-activity-pages-task-TaskExecutor-_TaskExecutor__button",
	"tagsEditor": "src-client-modules-activity-pages-task-TaskExecutor-_TaskExecutor__tagsEditor"
};
export default ___CSS_LOADER_EXPORT___;
