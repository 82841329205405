import * as React from 'react';

import { FileGroupsConnected } from './FileGroupsConnected';
import { FileGroupsTemplate } from './FileGroupsTemplate';

interface Props {
    activityId: number;
    enableControls: boolean;
}

export function FileGroups({ activityId, enableControls }: Props): JSX.Element {
    return (
        <FileGroupsConnected activityId={activityId}>
            {(gqlProps) => <FileGroupsTemplate {...gqlProps} enableControls={enableControls} />}
        </FileGroupsConnected>
    );
}
