import * as React from 'react';

import * as style from './SpecialFilters.scss';

import { Filter, CustomFilter, CustomFilterType } from '../types';

import {
    Icon,
    IconType,
    Input_redesign as Input,
    InputTheme_redesign as InputTheme,
    CustomScrollbar_new as CustomScrollbar,
    CustomScrollbarTheme,
    BodyPortal,
} from 'sber-marketing-ui';

interface Props {
    setFilters: (filter: Filter[]) => void;
    setSpecialFilter: (filter: CustomFilter) => void;
}

export const SpecialFilters = ({ setFilters, setSpecialFilter }: Props): JSX.Element => {
    const [isOpened, setOpenedStatus] = React.useState(false);
    const [searchString, setSearchString] = React.useState('');

    let f: React.ReactText[] = [];
    let notClosedFilter = {
        filterType: CustomFilterType.NotClosed,
        name: 'Незакрытые строки ЗНК',
        params: f,
    };

    const onSearchInput = React.useCallback((value: string) => {
        setSearchString(value);
    }, []);

    const onOpenerClick = React.useCallback(() => {
        setOpenedStatus(!isOpened);
        setSearchString('');
    }, [isOpened]);

    const onMaskClick = React.useCallback(() => {
        setOpenedStatus(false);
        setSearchString('');
    }, []);

    const onItemClick = React.useCallback((itemId: string) => {
        setOpenedStatus(false);
        setSearchString('');

        if (itemId === 'not_closed') {
            setSpecialFilter(notClosedFilter);
            setFilters([]);
        }
    }, []);

    interface SecialFilterSelector {
        id: string;
        name: string;
    }

    const customFilters: SecialFilterSelector[] = [
        {
            id: 'not_closed',
            name: 'Незакрытые строки ЗНК',
        },
    ];

    const items = !!searchString
        ? customFilters.filter((item) => item.name.toLowerCase().includes(searchString.toLowerCase()))
        : customFilters;

    return (
        <div className={style.root}>
            <div className={style.opener} onClick={onOpenerClick}>
                <Icon className={style.openerIcon} type={IconType.FILTERS} svgSize={24} />

                <div className={style.openerTitle}>
                    <span className={style.gray}>Фильтры:</span>
                    &nbsp;
                    <span className={style.black}></span>
                </div>
            </div>

            {isOpened && (
                <>
                    <div className={style.content}>
                        <div className={style.contentDescription}>Предустановленные фильтры</div>

                        <div className={style.search}>
                            <SearchInput value={searchString} onChange={onSearchInput} />
                        </div>

                        <div className={style.dropdownItems}>
                            <CustomScrollbar theme={CustomScrollbarTheme.GrayRounded} maxHeight={329}>
                                {items.map((item) => (
                                    <DropdownItem key={item.id} {...item} onClick={onItemClick} />
                                ))}
                            </CustomScrollbar>
                        </div>
                    </div>

                    <BodyPortal>
                        <div className={style.mask} onClick={onMaskClick} />
                    </BodyPortal>
                </>
            )}
        </div>
    );
};

interface SearchInputProps {
    value: string;
    onChange: (value: string) => void;
}

function SearchInput({ value, onChange }: SearchInputProps): JSX.Element {
    return (
        <div className={style.searchInput}>
            <Icon className={style.searchIcon} type={IconType.MAGNIFIER} svgSize={10} />

            <Input value={value} placeholder={'Поиск'} theme={InputTheme.Borderless} onInputChange={onChange} />
        </div>
    );
}

interface SelectItem {
    id: string;
    name: string;
}

interface DropdownItemProps extends SelectItem {
    onClick: (id: string) => void;
}

function DropdownItem({ id, name, onClick }: DropdownItemProps): JSX.Element {
    return (
        <div className={style.dropdownItem} onClick={() => onClick(id)}>
            <span className={style.black}>{name}</span>
        </div>
    );
}
