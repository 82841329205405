import * as React from 'react';
import classnames from 'classnames';
import { OpacityTransition, Popup, CloseButton } from 'sber-marketing-ui';

import { ComponentState } from '@store/budgetExecution/budgetTransferMenu';

import { Title } from './Title';
import { TransferSwitch } from './TransferSwitch';
import { InternalTransitionLineSelection } from './InternalTransitionLineSelection';
import { InternalTransitionSumEntering } from './InternalTransitionSumEntering';
import { InternalTransitionNegativeLineBalanceWarning } from './InternalTransitionNegativeLineBalanceWarning';
import { ExternalTransitionMenu } from './ExternalTransitionMenu';

import * as styles from './BudgetTransferMenu.scss';

const contextValue = {
    paddingClass: styles.wrapperRightPadding as string,
};
const StyleContext = React.createContext(contextValue);
export function useStyleContext() {
    return React.useContext(StyleContext).paddingClass;
}

interface Props {
    shouldDisplay: boolean;
    componentState: ComponentState;
    onCloseButtonClick: () => void;
}

export function BudgetTransferMenu(props: Props): JSX.Element {
    const { shouldDisplay, componentState, onCloseButtonClick } = props;

    const content = shouldDisplay ? <BudgetTransferMenuContent {...props} /> : null;
    const renderAsPopup =
        componentState === ComponentState.InternalTransferSumEntering ||
        componentState === ComponentState.InternalTransferNegativeLineBalanceWarning;

    return renderAsPopup ? (
        <Popup onCloseIconClick={onCloseButtonClick} onOutOfContentClick={onCloseButtonClick}>
            <StyleContext.Provider value={contextValue}>
                <OpacityTransition>
                    <div className={classnames(styles.root, styles.rootSumEntering)}>{content}</div>
                </OpacityTransition>
            </StyleContext.Provider>
        </Popup>
    ) : (
        <OpacityTransition>
            <StyleContext.Provider value={contextValue}>
                <div className={styles.root}>{content}</div>
            </StyleContext.Provider>
        </OpacityTransition>
    );
}

function BudgetTransferMenuContent({ componentState, onCloseButtonClick }: Props): JSX.Element {
    let content: JSX.Element;
    switch (componentState) {
        case ComponentState.ExternalIncomeTransfer:
        case ComponentState.ExternalOutcomeTransfer:
            content = (
                <div className={styles.wrapperRightPadding}>
                    <ExternalTransitionMenu />
                </div>
            );
            break;
        case ComponentState.InternalTransferCellSelection:
        case ComponentState.InternalTransferLineSelection:
            content = <InternalTransitionLineSelection />;
            break;
        case ComponentState.InternalTransferSumEntering:
            content = <InternalTransitionSumEntering />;
            break;
        case ComponentState.InternalTransferNegativeLineBalanceWarning:
            content = <InternalTransitionNegativeLineBalanceWarning />;
            break;
        default:
            content = null;
            break;
    }

    return (
        <React.Fragment>
            <div
                className={styles.wrapper}
                {...{
                    'qa-id': 'budgetTransferMenu',
                }}
            >
                <div className={styles.wrapperContent}>
                    <div className={classnames(styles.titleRow, styles.wrapperRightPadding)}>
                        <Title />

                        <TransferSwitch />

                        <div
                            className={styles.closeButton}
                            {...{
                                'qa-id': 'budgetTransferMenuCloseButton',
                            }}
                        >
                            <CloseButton onClick={onCloseButtonClick} />
                        </div>
                    </div>

                    {content ? <div className={styles.content}>{content}</div> : null}
                </div>
                {/* <div className={classnames(style.column, style.column_left)}>
                    <div className={style.title}>
                        Перенос бюджета:
                    </div>

                    <div className={style.columnContent}>
                        <LeftContentColumn />
                    </div>
                </div>

                <div className={style.column}>
                    <Controls />

                    <div className={style.columnContent}>
                        <RightContentColumn />
                    </div>
                </div> */}
            </div>
        </React.Fragment>
    );
}
