import * as React from 'react';
import autobind from 'autobind-decorator';
import { HistoryActionType } from 'sber-marketing-types/backend';

import { DifferenceItemParams } from '@store/news';

import { DifferenceList } from './DifferenceList';

interface Props {
    action: HistoryActionType;
    difference?: DifferenceItemParams[];
}

interface State {
    isOpened: boolean;
}

export class DifferenceListContainer extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isOpened: false,
        };
    }

    public render(): JSX.Element {
        const { difference } = this.props;
        const { isOpened } = this.state;

        return (
            <DifferenceList
                differences={difference}
                isOpened={isOpened}
                onToggleButtonClick={this.onToggleButtonClick}
            />
        );
    }

    @autobind
    private onToggleButtonClick(): void {
        this.setState((prevState) => ({
            isOpened: !prevState.isOpened,
        }));
    }
}
