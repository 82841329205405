import * as React from 'react';

import * as style from './CancelableInputCell.scss';

import { Icon, IconType } from 'sber-marketing-ui';

interface Props {
    title: string;
    customStyle?: any;
    hasManualValue: boolean;
    onCancelClick: () => void;
}

export const CancelableInputCell = ({ title, customStyle, hasManualValue, onCancelClick }: Props): JSX.Element => {
    function onButtonClick(event: React.MouseEvent<HTMLDivElement>) {
        event.stopPropagation();
        event.preventDefault();

        onCancelClick();
    }

    return (
        <div className={style.root} style={{ ...customStyle }}>
            <div className={style.title}>{title}</div>

            {hasManualValue && (
                <div className={style.button} onClick={onButtonClick}>
                    <Icon className={style.buttonIcon} type={IconType.CROSS_BRIEF} svgSize={20} />
                </div>
            )}
        </div>
    );
};
