import * as React from 'react';

import { Icon, IconType } from 'sber-marketing-ui';
import { TagColorType } from '@mrm/tags';

import { useGetTagQuery } from '@api';

import { useDefaultRef } from '@common/hooks';

import { DropdownTag, DropdownTagProps } from '@modules/common/components';

import { getEmojiPicture } from '@modules/tags/utils';
import { EditTag } from '@modules/tags/components/EditTag';

export interface EditableTagProps extends DropdownTagProps {
    tagId: string;
}

export function EditableTag({
    children,
    tagId,
    loading,
    dropdownProps,
    onShowChangeDropdown,
    dropdownRef,
    ...props
}: EditableTagProps) {
    const { data: tag, isLoading } = useGetTagQuery(tagId);
    const [color, setColor] = React.useState<string>();
    const [picture, setPicture] = React.useState<string>();
    const dropdown = useDefaultRef(dropdownRef);
    const resetRef = React.useRef<() => void>();

    const reset = () => {
        if (tag) {
            setColor(tag.color === 'transparent' ? null : tag.color);
            setPicture(getEmojiPicture(tag.picture));
        }
    };

    resetRef.current = reset;

    React.useEffect(reset, [tag]);

    const handleShowChangeDropdown: typeof onShowChangeDropdown = (show) => {
        if (!show) {
            resetRef.current();
        }

        onShowChangeDropdown?.(show);
    };

    const tagContent = (
        <>
            {picture && <Icon data-qa-id="TagIcon" type={picture as IconType} />}
            {tag?.title}
            {children}
        </>
    );

    return (
        <DropdownTag
            tagContent={tagContent}
            color={color}
            {...props}
            dropdownRef={dropdown}
            dropdownProps={{
                width: 148,
                ...dropdownProps,
            }}
            onShowChangeDropdown={handleShowChangeDropdown}
            loading={isLoading || loading}
        >
            <EditTag
                tagId={tagId}
                picture={picture}
                color={color as TagColorType}
                onChangePicture={setPicture}
                onChangeColor={setColor}
                onSubmit={() => dropdown.current.close()}
            />
        </DropdownTag>
    );
}
