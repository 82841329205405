import * as React from 'react';
import classnames from 'classnames';
import { Dictionary, groupBy, orderBy } from 'lodash';

import { TableNumberByType, TableType } from '@store/creative';

import { Utils, DatesFormatter } from '@common/Utils';
import { FileAsset } from '@common/FileAssetRedesign';
import { MediaFilesGallery } from '@common/MediaFilesGallery';

import { FileWithCreativeRequestItem } from '../hooks';

import * as styles from './FileGroup.scss';
import * as commonStyles from '../../../CommonStyles.scss';
import { DownloadAllButton } from '../DownloadAllButton';

interface FileGroupProps {
    title: string;
    files: FileWithCreativeRequestItem[];
}

export function FileGroup({ files, title }: FileGroupProps): JSX.Element {
    const [fileIdForGallery, setFileIdForGallery] = React.useState<string>(null);

    function openGallery(fileId: string) {
        setFileIdForGallery(fileId);
    }

    function closeGallery() {
        setFileIdForGallery(null);
    }

    const filesByItemGroup = React.useMemo(() => groupBy(files, (file) => file.itemGroup.value), [files]);

    return (
        <React.Fragment>
            <div className={classnames(styles.titleRow, commonStyles.sidePaddings)}>
                <div className={styles.title}>
                    {title}

                    <div className={styles.titleCounter}>({files?.length})</div>
                </div>

                <div className={styles.buttons}>
                    <DownloadAllButton fileGroupTitle={title} files={files} />
                </div>
            </div>

            <div className={classnames(styles.files, commonStyles.sidePaddings)}>
                <FileTableGroup allFiles={filesByItemGroup} tableType={TableType.Tariff} openGallery={openGallery} />

                <FileTableGroup
                    allFiles={filesByItemGroup}
                    tableType={TableType.Production}
                    openGallery={openGallery}
                />

                <FileTableGroup allFiles={filesByItemGroup} tableType={TableType.Ak} openGallery={openGallery} />
            </div>

            {fileIdForGallery && (
                <FilesGallery fileIdForGallery={fileIdForGallery} files={files} closeGallery={closeGallery} />
            )}
        </React.Fragment>
    );
}

interface FileTableGroup {
    tableType: TableType;
    allFiles: Dictionary<FileWithCreativeRequestItem[]>;
    openGallery: (fileId: string) => void;
}

function FileTableGroup({ tableType, allFiles, openGallery }: FileTableGroup): JSX.Element {
    const files = allFiles[tableType];

    return files?.length ? (
        <div className={styles.filesByTableGroup}>
            {orderBy(files, (file) => file.item.model.number).map((file) => (
                <File
                    key={file.file.model.id}
                    file={file}
                    groupIndex={TableNumberByType[tableType]}
                    onGalleryOpen={() => openGallery(file.file.model.id)}
                />
            ))}
        </div>
    ) : null;
}

interface FileProps {
    file: FileWithCreativeRequestItem;
    groupIndex: number;
    onGalleryOpen(): void;
}

function File({ file, groupIndex, onGalleryOpen }: FileProps): JSX.Element {
    const { number } = file.item.model;
    const { id, name, originName, type, size, createdAt } = file.file.model;

    return (
        <div className={styles.file}>
            <div className={styles.fileExtension}>
                <FileAsset
                    useR7Controls
                    canEditInR7
                    useMediaControls
                    showDownloadButton
                    id={id}
                    name={name}
                    originName={originName}
                    type={type}
                    fileId={id}
                    onGalleryButtonClick={onGalleryOpen}
                    showDownloadButton
                />
            </div>

            <div className={styles.fileDescription}>
                <div className={styles.fileName}>{originName}</div>

                <div className={styles.fileInfo}>
                    <span>
                        {Utils.formatFileSize(size)}&nbsp;•&nbsp;
                        {DatesFormatter.ddMonthyy(createdAt)}
                    </span>

                    {/* <span>
                        Строка {groupIndex}.{number}
                    </span> */}
                </div>
            </div>
        </div>
    );
}

interface FilesGalleryProps {
    fileIdForGallery: string;
    files: FileWithCreativeRequestItem[];
    closeGallery: () => void;
}

function FilesGallery(props: FilesGalleryProps): JSX.Element {
    const items = Utils.mapCreativeRequestItemFilesToMediaGalleryItems(
        props.files.map((file) => file.file),
        (file) => ({ fileId: file.id }),
    );
    const { fileIdForGallery, closeGallery } = props;

    return (
        <MediaFilesGallery
            startIndex={items.findIndex((item) => item.id === fileIdForGallery)}
            items={items}
            onOutsideClick={closeGallery}
        />
    );
}
