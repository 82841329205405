import { actionCreatorFactory } from 'typescript-fsa';
import { UserCard } from 'sber-marketing-types/frontend';

import { LoadingStatus } from '@store/commonTypes';

const creator = actionCreatorFactory('APP_USERS');

// Async actions

/** Load users cards */
export const loadUsers = creator.async<null | undefined, void, Error>('LOAD_USERS');

// Sync actions

/** Put users into store */
export const addUsers = creator<UserCard | UserCard[]>('ADD_USERS');

/** Reset users store */
export const resetUsers = creator('RESET_USERS');

export const setLoadingStatus = creator<LoadingStatus>('SET_LOADING_STATUS');
