import * as qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { ParsedQuery } from 'query-string';

export function useSearch<D extends ParsedQuery>(): [D, (search: D) => void] {
    const history = useHistory();
    return [
        qs.parse(useLocation().search) as D,
        (search) => {
            history.push(`?${qs.stringify(search)}`);
        },
    ];
}
