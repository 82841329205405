import * as React from 'react';
import { TagEmojiType } from '@mrm/tags';

import * as styles from './TagEmoji.scss';

interface Props {
    type: TagEmojiType;
    useInTag?: boolean;
}

export function TagEmoji({ type, useInTag }: Props): JSX.Element {
    const props: EmojiProps = {
        type,
        width: 0,
        height: 0,
    };

    switch (props.type) {
        case TagEmojiType.Profile:
        case TagEmojiType.Popup:
        case TagEmojiType.Phone:
        case TagEmojiType.Chain:
        case TagEmojiType.Folder:
        case TagEmojiType.Calendar:
        case TagEmojiType.Lighbulb:
        case TagEmojiType.Money:
        case TagEmojiType.Pencil:
        case TagEmojiType.Star:
        case TagEmojiType.Question:
        case TagEmojiType.Gear:
            if (useInTag) {
                props.width = 24;
            } else {
                props.width = 20;
            }

            props.height = 16;
            break;
        case TagEmojiType.PatternDiagonalStripes:
        case TagEmojiType.PatternStars:
        case TagEmojiType.PatternDashes:
        case TagEmojiType.PatternHorizontalStripes:
        case TagEmojiType.PatternDots:
        case TagEmojiType.PatternBackArrow:
            if (useInTag) {
                props.width = 20;
                props.height = 24;
                props.padding = 8;
            } else {
                props.width = 33;
                props.height = 32;
            }

            break;
        default:
            console.warn(`Unknown TagEmoji type: ${type}`);
            return null;
    }

    return <Emoji {...props} />;
}

interface EmojiProps extends Props {
    width: number;
    height: number;
    padding?: number;
}

function Emoji({ type, width, height, padding }: EmojiProps): JSX.Element {
    return (
        <svg
            className={styles.rootSvg}
            width={width}
            height={height}
            style={{ marginRight: padding ? `${padding}px` : null }}
        >
            <use href={`#TagEmoji${type}`} />
        </svg>
    );
}
