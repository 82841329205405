// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-files-components-FileThumbnail-_FileThumbnail__image{background-size:cover;background-position:center}.src-client-modules-files-components-FileThumbnail-_FileThumbnail__changed{position:absolute;top:4px;right:4px;font-size:6px;transition:font-size .3s, opacity .3s;opacity:1}.src-client-modules-files-components-FileThumbnail-_FileThumbnail__root:hover .src-client-modules-files-components-FileThumbnail-_FileThumbnail__changed{opacity:0;font-size:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/files/components/FileThumbnail/FileThumbnail.scss"],"names":[],"mappings":"AAIA,yEACE,qBAAsB,CACtB,0BAA2B,CAC5B,2EAGC,iBAAkB,CAClB,OAAQ,CACR,SAAU,CACV,aAAc,CACd,qCAAsC,CACtC,SAAU,CAEV,yJACE,SAAU,CACV,WAAY","sourcesContent":[".root {\n\n}\n\n.image {\n  background-size: cover;\n  background-position: center;\n}\n\n.changed {\n  position: absolute;\n  top: 4px;\n  right: 4px;\n  font-size: 6px;\n  transition: font-size .3s, opacity .3s;\n  opacity: 1;\n\n  .root:hover & {\n    opacity: 0;\n    font-size: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "src-client-modules-files-components-FileThumbnail-_FileThumbnail__image",
	"changed": "src-client-modules-files-components-FileThumbnail-_FileThumbnail__changed",
	"root": "src-client-modules-files-components-FileThumbnail-_FileThumbnail__root"
};
export default ___CSS_LOADER_EXPORT___;
