import * as React from 'react';

import { SomeShortReports } from './SomeShortReports';
import { SomeReportsCharts } from './SomeReportsCharts';
import { CommonShortReport } from './CommonShortReport';
import { CommonReportChart } from './CommonReportChart';

import * as styles from './styles.scss';

export const Content: React.FC = () => {
    return (
        <div className={styles.root}>
            <CommonShortReport />
            <SomeShortReports />
            <CommonReportChart />
            <SomeReportsCharts />
        </div>
    );
};
