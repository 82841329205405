import * as React from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { Icon, IconType } from 'sber-marketing-ui';

import * as styles from './AddAsset.scss';

/** Add asset component properties */
export interface AddAssetProps {
    /** Input ref */
    inputRef: React.Ref<HTMLInputElement>;
    /** Click event handler */
    onClick: React.MouseEventHandler<HTMLDivElement>;
    /** Change event handler */
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    /** If "true" upload input is disabled */
    isDisabled?: boolean;
    /** Additional CSS class */
    className?: string;
}
/* tslint:disable:no-magic-numbers */
/** Add asset component */
export function AddAsset({ inputRef, onClick, onChange, isDisabled, className }: AddAssetProps): JSX.Element {
    const classList: string[] = [styles.root];
    if (!isNil(className)) {
        classList.push(className);
    }
    return (
        <div
            className={classNames(classList)}
            onClick={onClick}
            {...{
                'qa-id': 'taskCommentFormAddAssetButton',
            }}
        >
            <div className={styles.icon}>
                <Icon
                    svgSize={22}
                    color="transparent"
                    type={isDisabled ? IconType.ATTACH_DISABLED : IconType.ATTACH_NORMAL}
                />
            </div>

            <input className={styles.input} type="file" ref={inputRef} onChange={onChange} multiple />
        </div>
    );
}
