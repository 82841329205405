import * as React from 'react';
import classnames from 'classnames';
import { debounce } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
    Icon,
    IconType,
    Input_redesign as Input,
    InputTheme_redesign as InputTheme,
    WithGlobalPopup,
    AbsoluteLikePositionCalculator,
    CustomScrollbar_new as CustomScrollbar,
} from 'sber-marketing-ui';

import { StoreState } from '@store';
import { ActivityStageParams, getTaskEditorState, setStageId } from '@store/taskEditor2';

import * as styles from './StageDropdown.scss';

function useInteractivity() {
    const dispatch = useDispatch();
    React.useEffect(() => setMatchedStageAsInputValue(selectedStageId), []);

    const selectedStageId = useSelector((state: StoreState) => getTaskEditorState(state).values.stageId);
    const nonFilteredActivityStages = useSelector(
        (state: StoreState) => getTaskEditorState(state).data.activityStages.entities,
    );
    const disabled = useSelector((state: StoreState) => !getTaskEditorState(state).permissions.canChangeRest);
    const isVisible = nonFilteredActivityStages.length > 1;

    const setIsOpened = debounce((isOpened: boolean) => isOpenedSetter(isOpened), 200);
    function setMatchedStageAsInputValue(stageId: string) {
        const matchedStage = nonFilteredActivityStages.find((activityStage) => activityStage.id === stageId);

        if (matchedStage) {
            setInputValue(matchedStage.title);
        }
    }
    function saveStageId(stageId: string) {
        dispatch(setStageId(stageId));
        setIsOpened(false);
        setMatchedStageAsInputValue(stageId);
    }
    function closeDropdown() {
        setIsOpened(false);
        setMatchedStageAsInputValue(selectedStageId);
    }

    const contentRef = React.useRef<HTMLDivElement>();
    const contentWidth = React.useMemo(() => {
        if (contentRef.current) {
            return contentRef.current.getBoundingClientRect().width;
        }

        return 0;
    }, [contentRef.current]);

    const [isOpened, isOpenedSetter] = React.useState(false);
    const [inputValue, setInputValue] = React.useState('');
    const [filterByInputValue, setFilterByInputValue] = React.useState(false);

    const inputValueRegexp = new RegExp(inputValue, 'i');
    const activityStages =
        inputValue && filterByInputValue
            ? nonFilteredActivityStages.filter((activityStage) => activityStage.title.match(inputValueRegexp))
            : nonFilteredActivityStages;

    return {
        isVisible,
        contentRef,
        contentWidth,
        isOpened,
        setIsOpened,
        closeDropdown,
        activityStages,
        selectedStageId,
        inputValue,
        setInputValue,
        setFilterByInputValue,
        saveStageId,
        disabled,
    };
}

export function StageDropdown(): JSX.Element {
    const {
        isVisible,
        contentRef,
        contentWidth,
        isOpened,
        setIsOpened,
        closeDropdown,
        activityStages,
        selectedStageId,
        inputValue,
        setInputValue,
        setFilterByInputValue,
        saveStageId,
        disabled,
    } = useInteractivity();

    return isVisible ? (
        <div
            ref={contentRef}
            className={styles.root}
            {...{
                'qa-id': 'taskEditorStageDropdown',
            }}
        >
            <div className={styles.title}>Привязать задачу к этапу</div>

            <DropdownTitle
                isOpened={isOpened}
                inputValue={inputValue}
                disabled={disabled}
                openDropdown={() => setIsOpened(true)}
                setFilterByInputValue={setFilterByInputValue}
                closeDropdown={closeDropdown}
                setInputValue={setInputValue}
            />

            {!disabled && isOpened && (
                <WithGlobalPopup
                    container={contentRef}
                    positionCalculator={AbsoluteLikePositionCalculator}
                    bottom={-4}
                    maskZIndex={100}
                    onMaskClick={closeDropdown}
                >
                    <DropdownContent
                        width={contentWidth}
                        selectedItemId={selectedStageId}
                        items={activityStages}
                        onClick={saveStageId}
                    />
                </WithGlobalPopup>
            )}
        </div>
    ) : null;
}

interface DropdownTitleProps {
    isOpened: boolean;
    inputValue: string;
    disabled: boolean;
    openDropdown: () => void;
    closeDropdown: () => void;
    setFilterByInputValue: (filterByInputValue: boolean) => void;
    setInputValue: (values: string) => void;
}

function DropdownTitle({
    isOpened,
    inputValue,
    disabled,
    openDropdown,
    closeDropdown,
    setFilterByInputValue,
    setInputValue,
}: DropdownTitleProps): JSX.Element {
    return (
        <div className={classnames(styles.dropdownTitle, isOpened && styles.dropdownTitleActive)}>
            <Input
                qaId="taskEditorStageDropdownInout"
                placeholder="Название этапа"
                theme={InputTheme.Simple}
                disabled={disabled}
                value={inputValue}
                onFocus={openDropdown}
                onBlur={() => {
                    closeDropdown();
                    setFilterByInputValue(false);
                }}
                onInputChange={(value: string) => {
                    setFilterByInputValue(true);
                    setInputValue(value);
                }}
            />

            {!disabled && (
                <Icon
                    type={isOpened ? IconType.ARROW16_DOWN_BLACK : IconType.ARROW16_DOWN_GRAY}
                    svgSize={8}
                    className={classnames(styles.dropdownOpenIcon, isOpened && styles.dropdownOpenIconOpened)}
                    onClick={openDropdown}
                />
            )}
        </div>
    );
}

interface DropdownContentProps {
    width: number;
    items: ActivityStageParams[];
    selectedItemId: string;
    onClick: (itemId: string) => void;
}

const ITEM_HEIGHT = 61;

function DropdownContent({ width, items, selectedItemId, onClick }: DropdownContentProps): JSX.Element {
    return (
        <div className={styles.dropdownContent} style={{ width: `${width}px` }}>
            <CustomScrollbar hideScrollX freezeScrollX maxHeight={4 * ITEM_HEIGHT}>
                {items.map((item) => (
                    <Item key={item.id} selectedItemId={selectedItemId} onClick={onClick} {...item} />
                ))}
            </CustomScrollbar>
        </div>
    );
}

interface ItemProps extends ActivityStageParams {
    selectedItemId: string;
    onClick: (itemId: string) => void;
}

function Item({ id, title, subtitle, selectedItemId, onClick }: ItemProps): JSX.Element {
    return (
        <div
            className={styles.dropdownItem}
            onClick={() => onClick(id)}
            {...{
                'qa-id': 'taskEditorStageDropdownItem',
            }}
        >
            <div className={styles.dropdownItemTitle}>{title}</div>

            <div className={styles.dropdownItemSubtitle}>{subtitle}</div>

            {id === selectedItemId && (
                <Icon type={IconType.CHECK_ICON} svgSize={14} className={styles.dropdownItemSelectedIcon} />
            )}
        </div>
    );
}
