import { createSelector } from 'reselect';
import * as lodash from 'lodash';
import * as moment from 'moment';

import type { Activity } from '@mrm/activity';
import type {
    ActivityType,
    DepartmentAttributes as Department,
    TaskAttributes as Task,
    UserResponseParams as User,
} from 'sber-marketing-types/frontend';
import { ActivityStatus, TaskStatus } from 'sber-marketing-types/frontend';
import type {
    PageState,
    PageData,
    Filters,
    GroupedActivities,
    GroupedBlocks,
    ActivityCardsGroup,
    ActivityCardParams,
    ActivityTypeColor,
    StagesInfo,
    Stage,
} from './types';
import { PageMode, DEFAULT_TYPE_COLOR } from './types';
import type { StoreState } from '../';
import type { PlainDictionary } from '@mrm/dictionary';

export const getCalendarPageState = (state: StoreState): PageState => state.calendar;

export const getPageData = createSelector(getCalendarPageState, (state: PageState): PageData => {
    return state.pageData;
});

export const getPageMode = createSelector(getCalendarPageState, (state: PageState): PageMode => {
    return state.currentPageMode;
});

export const getIsDisabledUploadPdfButton = createSelector(getCalendarPageState, (state: PageState): boolean => {
    return state.isDisabledUploadPdfButton;
});

export const getBlocks = createSelector(getPageData, (pageData: PageData): PlainDictionary[] => {
    return pageData.blocks;
});

export const getCalendarGroups = createSelector(getPageData, (pageData: PageData): PlainDictionary[] => {
    return pageData.calendarGroups;
});

export const getActivityTypeColors = createSelector(
    getPageData,
    (pageData: PageData): { [typeId: string]: ActivityTypeColor } => {
        const { activityTypes } = pageData;

        const activityTypeColors: { [id: string]: ActivityTypeColor } = {};

        activityTypes.forEach((item) => {
            const color: ActivityTypeColor = item.color ? JSON.parse(item.color) : {};

            if (color.preparation && color.realization && color.hover) {
                activityTypeColors[item.id] = color;
            }
        });

        return activityTypeColors;
    },
);

export const getExpiredTasks = createSelector(getPageData, (pageData: PageData): Task[] => {
    const { tasks } = pageData;

    const today = new Date().valueOf();

    return lodash
        .flatMap(tasks)
        .filter(
            (item) => item.status !== TaskStatus.Closed && item.deadline && new Date(item.deadline).valueOf() < today,
        );
});

export const getExpiredActivityStages = createSelector(getPageData, (pageData: PageData): Stage[] => {
    const today = new Date().valueOf();

    return pageData.stages.filter((stage) => {
        const { start, end, done } = stage;

        if (!start || !end) {
            return false;
        }

        return !done && today > new Date(end).valueOf();
    });
});

export const getFilters = createSelector(getCalendarPageState, (state: PageState): Filters => {
    return state.filters;
});

export const getActivityAuthorsByIds = createSelector(getPageData, (pageData: PageData): { [userId: number]: User } => {
    const { activityAuthors } = pageData;

    return lodash.keyBy(activityAuthors, (item) => item.id);
});

export const getDepartmentsByIds = createSelector(getPageData, (pageData: PageData): { [id: string]: Department } => {
    const { departments } = pageData;

    return lodash.keyBy(departments, (item) => item.id);
});

export const getFilteredActivities = createSelector(
    getPageData,
    getFilters,
    getPageMode,
    getActivityAuthorsByIds,
    (
        pageData: PageData,
        filters: Filters,
        pageMode: PageMode,
        groupedActivityAuthors: { [userId: number]: User },
    ): Activity[] => {
        const { activities } = pageData;
        const {
            organizationIds,
            activityTypeIds,
            divisionIds,
            productIds,
            departmentIds,
            responsibleUserIds,
            weekStart,
            onlyKeyActivities,
        } = filters;

        let result = lodash.clone(activities);

        if (onlyKeyActivities) {
            result = result.filter((result) => result.isKey);
        }

        if (!lodash.isEmpty(organizationIds)) {
            result = result.filter((item) => lodash.includes(organizationIds, item.organizationId));
        }

        if (!lodash.isEmpty(activityTypeIds)) {
            result = result.filter((item) => lodash.includes(activityTypeIds, item.typeId));
        }

        if (!lodash.isEmpty(divisionIds)) {
            result = result.filter((item) => lodash.includes(divisionIds, item.calendarGroupId));
        }

        if (!lodash.isEmpty(productIds)) {
            result = result.filter((item) => lodash.includes(productIds, item.productId));
        }

        if (!lodash.isEmpty(departmentIds)) {
            result = result.filter((item) =>
                lodash.includes(departmentIds, groupedActivityAuthors[item.authorId].departmentId),
            );
        }

        if (!lodash.isEmpty(responsibleUserIds)) {
            result = result.filter((item) => lodash.includes(responsibleUserIds, item.responsibleId));
        }

        if (pageMode == PageMode.WeekDigest) {
            const weekStartInMs = weekStart.getTime();
            const weekEndInMs = moment(weekStart).add(1, 'week').valueOf();

            result = result.filter((item) => {
                const startInMs = new Date(item.realizationStart).getTime();

                return weekStartInMs <= startInMs && startInMs < weekEndInMs;
            });
        }

        return result;
    },
);

export const getYears = createSelector(getPageData, (pageData: PageData): number[] => {
    const { activities, tasks } = pageData;

    const activitiesDates = lodash.flatMap(activities, (item) => [
        item.preparationDate,
        item.realizationStart,
        item.realizationEnd,
        item.debriefingDate,
    ]);

    const tasksDates = lodash.flatMap(tasks, (item) => item.map((task) => task.deadline));

    const years = lodash.compact([...activitiesDates, ...tasksDates]).map((item) => new Date(item).getFullYear());

    return lodash.uniq(years).sort();
});

export const getActivitiesGroupedByCalendarGroup = createSelector(
    getPageData,
    getCalendarGroups,
    (pageData: PageData, calendarGroups: PlainDictionary[]): GroupedActivities => {
        const { activities } = pageData;

        const groupedActivities: GroupedActivities = calendarGroups.reduce((acc, group) => {
            const groupActivities = activities.filter((activity) => activity.calendarGroupId === group.id);

            if (!lodash.isEmpty(groupActivities)) {
                acc[group.id] = groupActivities;
            }

            return acc;
        }, {});

        return groupedActivities;
    },
);

export const getCalendarGroupsGroupedByOrganization = createSelector(
    getCalendarGroups,
    (calendarGroups: PlainDictionary[]): GroupedBlocks => {
        return lodash.groupBy(calendarGroups, (item) => item.organizationId);
    },
);

export const getActivityCardGroups = createSelector(
    getActivitiesGroupedByCalendarGroup,
    getCalendarGroupsGroupedByOrganization,
    getFilteredActivities,
    getActivityTypeColors,
    getPageData,
    (
        groupedActivities: GroupedActivities,
        groupedCalendarGroups: GroupedBlocks,
        filteredActivities: Activity[],
        activityTypeColors: { [id: string]: ActivityTypeColor },
        pageData: PageData,
    ): ActivityCardsGroup[] => {
        const { organizations, accessibleActivitiesIds, tasks, activityTypes, stages } = pageData;

        const sortedCalendarGroups = lodash.compact(
            lodash.flatMap(organizations, (organization) => groupedCalendarGroups[organization.id]),
        );

        const groups: ActivityCardsGroup[] = lodash.flatMap(sortedCalendarGroups, (calendarGroup) => {
            const groupActivities = groupedActivities[calendarGroup.id] || [];
            const organization = organizations.find((item) => item.id == calendarGroup.organizationId);

            return {
                id: calendarGroup.id,
                organizationName: organization.name,
                blockName: calendarGroup.value,
                activities: groupActivities
                    .filter((item) => lodash.includes(filteredActivities, item))
                    .map((activity) =>
                        formatActivity(
                            activity,
                            accessibleActivitiesIds,
                            activityTypes,
                            activityTypeColors,
                            stages,
                            tasks[activity.id],
                        ),
                    ),
            };
        });

        return groups.filter((group) => !lodash.isEmpty(group.activities));
    },
);

function formatActivity(
    activity: Activity,
    accessibleActivitiesIds: number[],
    activityTypes: ActivityType[],
    activityTypeColors: { [id: string]: ActivityTypeColor },
    stages: Stage[],
    tasks: Task[],
): ActivityCardParams {
    const activityType = activityTypes.find((item) => item.id == activity.typeId);
    const stagesData = stages.filter((item) => item.activityId == activity.id);

    return {
        id: activity.id,
        name: activity.name,
        typeName: activityType ? activityType.name : 'Тип активности не найден',
        isActive:
            lodash.includes(accessibleActivitiesIds, activity.id) &&
            activity.status != ActivityStatus.Draft &&
            activity.status != ActivityStatus.Planned,
        startDate: new Date(activity.realizationStart),
        color: activityTypeColors[activity.typeId] || DEFAULT_TYPE_COLOR,
        tasks: tasks || [],
        stagesInfo: makeStagesInfo(stagesData),
        needStages: activity.needStages,
    };
}

function makeStagesInfo(stages: Stage[]): StagesInfo {
    if (!stages || !stages.length) {
        return null;
    }

    const today = new Date();

    const finishedStages = stages.filter((item) => item.done);
    const hasExpiredStages = stages.some((item) => {
        const { start, end } = item;

        return start && end ? !item.done && today.valueOf() > new Date(end).valueOf() : false;
    });

    return {
        stagesCount: stages.length,
        finishedStagesCount: finishedStages.length,
        hasExpiredStages,
    };
}
