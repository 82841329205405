import * as React from 'react';

import { Utils } from '@common/Utils';

import * as styles from './SuccessMessage.scss';

interface Props {
    rowsCount: number;
}

export function SuccessMessage({ rowsCount }: Props): JSX.Element {
    const wasUploaded = rowsCount === 1 ? 'была загружена' : 'было загружено';
    const rowsDeclension = Utils.getDeclensionByNumber(rowsCount, ['строка', 'строки', 'строк']);

    return (
        <span className={styles.root}>
            В систему {wasUploaded} <span className={styles.rowsCount}>{rowsCount}</span> {rowsDeclension}
        </span>
    );
}
