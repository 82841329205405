import { actionCreatorFactory } from 'typescript-fsa';

import {
    BudgetUserConfig,
    UpdateBudgetUserConfig,
    LoadBudgetUserConfig,
    SaveBudgetByStatusUserConfigPayload,
} from './types';

const actionCreator = actionCreatorFactory('USER_CONFIG_BUDGET');

const loadBudgetUserConfig = actionCreator.async<BudgetUserConfig, LoadBudgetUserConfig, Error>(
    'LOAD_BUDGET_USER_CONFIG',
);
const updateBudgetUserConfig = actionCreator.async<UpdateBudgetUserConfig, void, Error>('UPDATE_BUDGET_USER_CONFIG');
const saveBudgetByStatusUserConfig = actionCreator.async<SaveBudgetByStatusUserConfigPayload, void, Error>(
    'SAVE_BUDGET_BY_STATUS_USER_CONFIG',
);

export { loadBudgetUserConfig, updateBudgetUserConfig, saveBudgetByStatusUserConfig };
