import * as React from 'react';
import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import { InternalTransferDirection, getBudgetTransferMenuState } from '@store/budgetExecution/budgetTransferMenu';

import { CellDescription, CellDescriptionTemplates } from '../../CellDescription';

import * as styles from './CellDescriptions.scss';

function useStore() {
    const useMonth = useSelector(
        (state: StoreState) =>
            getBudgetTransferMenuState(state).controls.internalTransferDirection ===
            InternalTransferDirection.OneToMany,
    );

    const currentFromCell = useSelector((state: StoreState) => {
        const {
            cells: { from },
            controls: { rowForSumEntering, internalTransferDirection },
        } = getBudgetTransferMenuState(state);

        if (internalTransferDirection === InternalTransferDirection.OneToMany) {
            return from[0];
        } else {
            return from.find((fromCell) => fromCell.lineId === rowForSumEntering);
        }
    });

    return { useMonth, currentFromCell };
}

export function SourceCellDescription(): JSX.Element {
    const { useMonth, currentFromCell } = useStore();

    return (
        <div className={styles.root}>
            <CellDescription
                qaId="internalTransitinoSumEnteringSourceCellDescription"
                useMonth={useMonth}
                cell={currentFromCell}
                template={CellDescriptionTemplates.CellFullWithLinePlanReserveDiff}
            />
        </div>
    );
}
