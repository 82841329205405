// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-components-AddAsset-_AddAsset__root,.src-client-modules-activity-pages-task-components-AddAsset-_AddAsset__icon{width:22px;height:22px}.src-client-modules-activity-pages-task-components-AddAsset-_AddAsset__root{position:relative}.src-client-modules-activity-pages-task-components-AddAsset-_AddAsset__root:hover{cursor:pointer}.src-client-modules-activity-pages-task-components-AddAsset-_AddAsset__input{display:none}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/components/AddAsset/AddAsset.scss"],"names":[],"mappings":"AAAA,wJAEI,UAAW,CACX,WAAY,CACf,4EAGG,iBAAkB,CACrB,kFAGG,cAAe,CAClB,6EAGG,YAAa","sourcesContent":[".root,\n.icon {\n    width: 22px;\n    height: 22px;\n}\n\n.root {\n    position: relative;\n}\n\n.root:hover {\n    cursor: pointer;\n}\n\n.input {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-components-AddAsset-_AddAsset__root",
	"icon": "src-client-modules-activity-pages-task-components-AddAsset-_AddAsset__icon",
	"input": "src-client-modules-activity-pages-task-components-AddAsset-_AddAsset__input"
};
export default ___CSS_LOADER_EXPORT___;
