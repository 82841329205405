// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-common-NotificationBar-_NotificationBar__root{display:flex;overflow:hidden;width:100%;padding:4px 8px;white-space:nowrap;letter-spacing:0.3px;text-overflow:ellipsis;border-radius:4px;font-family:'Open Sans';font-size:12px}.src-client-modules-budget-common-NotificationBar-_NotificationBar__yellow{color:#db8c0a;background:#fff7ea}.src-client-modules-budget-common-NotificationBar-_NotificationBar__red{color:#e63900;background:#ffebee}.src-client-modules-budget-common-NotificationBar-_NotificationBar__icon{padding-right:4px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/common/NotificationBar/NotificationBar.scss"],"names":[],"mappings":"AAAA,yEACI,YAAa,CACb,eAAgB,CAEhB,UAAW,CACX,eAAgB,CAEhB,kBAAmB,CACnB,oBAAqB,CACrB,sBAAuB,CAEvB,iBAAkB,CAElB,uBAAwB,CACxB,cAAe,CAClB,2EAGG,aAAc,CACd,kBAAmB,CACtB,wEAGG,aAAc,CACd,kBAAmB,CACtB,yEAGG,iBAAkB","sourcesContent":[".root {\n    display: flex;\n    overflow: hidden;\n\n    width: 100%;\n    padding: 4px 8px;\n\n    white-space: nowrap;\n    letter-spacing: 0.3px;\n    text-overflow: ellipsis;\n\n    border-radius: 4px;\n\n    font-family: 'Open Sans';\n    font-size: 12px;\n}\n\n.yellow {\n    color: #db8c0a;\n    background: #fff7ea;\n}\n\n.red {\n    color: #e63900;\n    background: #ffebee;\n}\n\n.icon {\n    padding-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-common-NotificationBar-_NotificationBar__root",
	"yellow": "src-client-modules-budget-common-NotificationBar-_NotificationBar__yellow",
	"red": "src-client-modules-budget-common-NotificationBar-_NotificationBar__red",
	"icon": "src-client-modules-budget-common-NotificationBar-_NotificationBar__icon"
};
export default ___CSS_LOADER_EXPORT___;
