import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';
import * as moment from 'moment';

import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type { Autopilot, AutopilotPlacementPlanData } from 'sber-marketing-types/backend';
import type { PageState, BriefStageForm, CreativeStageForm, GroupedDictionaries } from './types';
import * as actions from './actions';

export const initialState: PageState = {
    autopilot: null,
    activity: null,
    mediaplan: null,
    dictionaries: {},
    briefStageForm: {
        blockId: null,
        productId: null,
        budget: null,
        budgetError: null,
        dateStart: null,
        dateEnd: null,
        peakDates: [],
        targetAgeFrom: null,
        targetAgeTo: null,
    },
    selectedBudgetItemIds: [],
    creativeStageForm: {
        vkUrl: '',
        facebookUrl: '',
        okUrl: '',
        youtubeUrl: '',
    },
    currentStage: 1,
    calculatingMediaplan: false,
};

export class Reducer {
    public static loadAutopilot(state: PageState, payload: Autopilot): PageState {
        const { brief, budget, creative } = payload;

        const briefStageForm = brief
            ? {
                  blockId: brief.block,
                  productId: brief.product,
                  budget: brief.budget ? brief.budget.toString() : null,
                  budgetError: null,
                  dateStart: moment(brief.dateStart),
                  dateEnd: moment(brief.dateEnd),
                  peakDates: brief.peak_dates.map((item) => new Date(item)),
                  targetAgeFrom: brief.age_from ? brief.age_from.toString() : null,
                  targetAgeTo: brief.age_to ? brief.age_to.toString() : null,
              }
            : lodash.cloneDeep(initialState.briefStageForm);

        const selectedBudgetItemIds = budget ? budget.budgetIds : [];

        const creativeStageForm = creative
            ? {
                  vkUrl: creative.vk,
                  facebookUrl: creative.fb,
                  okUrl: creative.ok,
                  youtubeUrl: creative.youtube,
              }
            : lodash.cloneDeep(initialState.creativeStageForm);

        return {
            ...state,
            autopilot: payload,
            briefStageForm,
            selectedBudgetItemIds,
            creativeStageForm,
        };
    }

    public static loadActivity(state: PageState, payload: Activity): PageState {
        return { ...state, activity: payload };
    }

    public static loadMediaplan(state: PageState, payload: AutopilotPlacementPlanData[]): PageState {
        return { ...state, mediaplan: payload };
    }

    public static loadDictionaries(state: PageState, payload: GroupedDictionaries): PageState {
        return { ...state, dictionaries: payload };
    }

    public static resetPageState(): PageState {
        return lodash.cloneDeep(initialState);
    }

    public static setBriefFormValues(state: PageState, payload: Partial<BriefStageForm>): PageState {
        return { ...state, briefStageForm: { ...state.briefStageForm, ...payload } };
    }

    public static setSelectedBudgetItemIds(state: PageState, payload: string[]): PageState {
        return { ...state, selectedBudgetItemIds: payload };
    }

    public static setCreativeFormValues(state: PageState, payload: Partial<CreativeStageForm>): PageState {
        return { ...state, creativeStageForm: { ...state.creativeStageForm, ...payload } };
    }

    public static setCurrentStage(state: PageState, payload: number): PageState {
        return { ...state, currentStage: payload };
    }

    public static setMediaplanStatus(state: PageState, payload: boolean): PageState {
        return { ...state, calculatingMediaplan: payload };
    }
}

export const autopilotReducer = reducerWithInitialState(initialState)
    .case(actions.loadAutopilot, Reducer.loadAutopilot)
    .case(actions.loadActivity, Reducer.loadActivity)
    .case(actions.loadMediaplan, Reducer.loadMediaplan)
    .case(actions.loadDictionaries, Reducer.loadDictionaries)
    .case(actions.resetPageState, Reducer.resetPageState)
    .case(actions.setBriefFormValues, Reducer.setBriefFormValues)
    .case(actions.setSelectedBudgetItemIds, Reducer.setSelectedBudgetItemIds)
    .case(actions.setCreativeFormValues, Reducer.setCreativeFormValues)
    .case(actions.setCurrentStage, Reducer.setCurrentStage)
    .case(actions.setMediaplanStatus, Reducer.setMediaplanStatus);
