import type { ActivityLineParams, LinesGroup, TaskLineParams } from '@store/calendar/types';
import { DEFAULT_TYPE_COLOR, LineType, Period } from '@store/calendar/types';
import type { ActivityDrawParams, TaskDrawParams } from '../../../index';
import type { SidesSizes } from '../../types';

import { store } from '@store';
import { getCalendarPageState } from '@store/calendar/selectors';
import { Scaler, Scroller, TimeCalculator } from '../../../index';
import { Drawer } from './Drawer';

interface StoreProps {
    period: Period;
}

interface Options {
    sidesSizes: SidesSizes;
    group: LinesGroup;
    filledExpiredTaskIcon: HTMLImageElement;
    finishedTaskIcon: HTMLImageElement;
}

export class SceneDrawer {
    private filledExpiredTaskIcon: HTMLImageElement;
    private finishedTaskIcon: HTMLImageElement;

    private group: LinesGroup;
    private sidesSizes: SidesSizes;

    private canvas: HTMLCanvasElement;

    private timeCalculator: TimeCalculator;
    private scroller: Scroller;
    private scaler: Scaler;
    private drawer: Drawer;

    public constructor({ group, sidesSizes, filledExpiredTaskIcon, finishedTaskIcon }: Options) {
        this.timeCalculator = TimeCalculator.getInstance();
        this.scroller = Scroller.getInstance();
        this.scaler = Scaler.getInstance();

        const withTopIndent = group.lines.some(({ type }) => type === LineType.GroupTitle);
        this.drawer = new Drawer({ withTopIndent });

        this.group = group;
        this.sidesSizes = sidesSizes;
        this.filledExpiredTaskIcon = filledExpiredTaskIcon;
        this.finishedTaskIcon = finishedTaskIcon;

        this.initCanvas();
    }

    public getCanvas(): HTMLCanvasElement {
        this.drawScene();
        return this.canvas;
    }

    private drawScene() {
        this.updateScroll();
        this.drawCharts();
    }

    private initCanvasSize(): void {
        this.canvas.width = this.sidesSizes.width;
        this.canvas.height = this.sidesSizes.height;
    }

    private initCanvas(): void {
        this.canvas = this.createCanvasElement();
        this.initCanvasSize();
    }

    private createCanvasElement(): HTMLCanvasElement {
        return document.createElement('CANVAS') as HTMLCanvasElement;
    }

    private updateScroll() {
        this.drawer.setScroll(this.canvas, this.getLeftScroll());
    }

    private drawCharts(): void {
        this.drawBackground();
        this.drawHorizontalLines();
        this.drawTodayLine();
        this.drawSeparatorLines();
        this.drawGroupLines();
    }

    private drawBackground(): void {
        this.drawer.drawBackground(this.canvas);
    }

    private drawHorizontalLines() {
        this.group.lines.forEach((line, lineIndex) => {
            const lineIsActivity = line.type == LineType.Activity;
            const lineIsTask = line.type == LineType.Task;

            if (lineIsActivity || lineIsTask) {
                this.drawer.drawHorizontalLine(this.canvas, lineIndex);
            }
        });
    }

    private drawTodayLine() {
        const today = new Date().toISOString();
        const dayIndex = this.timeCalculator.getDayIndexByDate(today);
        const { start, end } = this.scaler.convertDayToRangeInPx(dayIndex);
        const todayX = (start + end) / 2;

        this.drawer.drawTodayLine(this.canvas, todayX);
    }

    private drawSeparatorLines() {
        const separatorPositions = this.getSeparatorPositions();
        const linesIndexes = this.group.lines.map((_, index) => index);

        separatorPositions.forEach((positionX) => {
            this.drawer.drawSeparator(this.canvas, positionX, linesIndexes);
        });
    }

    private getSeparatorPositions(): number[] {
        const { period } = this.getStoreProps();

        const periodFirstDays =
            period == Period.Year
                ? this.timeCalculator.getQuartersFirstDays()
                : this.timeCalculator.getMonthsFirstDays();

        return periodFirstDays.map((day) => this.scaler.convertDayToRangeInPx(day).start);
    }

    private drawGroupLines() {
        this.group.lines.forEach((line, lineIndex) => {
            switch (line.type) {
                case LineType.GroupTitle:
                    const text = this.makeGroupTitleText(this.group);

                    this.drawer.drawGroupNameText(this.canvas, text);
                    break;

                case LineType.Activity:
                    const activityDrawParams = this.makeActivityDrawParams(line as ActivityLineParams, lineIndex);

                    this.drawer.drawActivity(this.canvas, activityDrawParams, this.filledExpiredTaskIcon);
                    break;

                case LineType.Task:
                    const taskDrawParams = this.makeTaskDrawParams(line as TaskLineParams, lineIndex);

                    this.drawer.drawTask(
                        this.canvas,
                        taskDrawParams,
                        this.filledExpiredTaskIcon,
                        this.finishedTaskIcon,
                    );
                    break;
            }
        });
    }

    private makeGroupTitleText(group: LinesGroup): string {
        let text: string;

        if (group.isExpanded && group.lines.length > 1) {
            text = '';
        }

        if (group.isExpanded && group.lines.length <= 1) {
            text = 'Активностей на данный период нет';
        }

        if (!group.isExpanded) {
            text = `Скрытые активности: ${group.activitiesCount}`;
        }

        return text;
    }

    private makeActivityDrawParams(line: ActivityLineParams, lineIndex: number): ActivityDrawParams {
        return {
            lineIndex,
            preparationStart: this.scaler.convertDayToRangeInPx(line.preparationStart).start,
            realizationStart: this.scaler.convertDayToRangeInPx(line.realizationStart).start,
            realizationEnd: this.scaler.convertDayToRangeInPx(line.realizationEnd).end,
            debriefingEnd: this.scaler.convertDayToRangeInPx(line.debriefingEnd).end,
            isOneDayActivity: line.isOneDayActivity,
            color: line.color || DEFAULT_TYPE_COLOR,
            hasExpiredStages: line.hasExpiredStages,
            isActive: line.isActive,
            isHovered: false,
        };
    }

    private makeTaskDrawParams(line: TaskLineParams, lineIndex: number): TaskDrawParams {
        const deadline = this.scaler.convertDayToRangeInPx(line.deadline);

        return {
            lineIndex,
            deadline: (deadline.start + deadline.end) / 2,
            deadlineExpired: line.deadlineExpired,
            taskIsClosed: line.taskIsClosed,
            color: line.color || DEFAULT_TYPE_COLOR,
            isActive: line.activityIsActive,
            isHovered: false,
        };
    }

    private getLeftScroll() {
        return this.scroller.currentScroll * this.scaler.getViewportWidthInPx();
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        const { period } = getCalendarPageState(storeState);

        return {
            period,
        };
    }
}
