import * as React from 'react';

import { ReleaseNotesPage, ReleaseNotesPageHeader } from './ReleaseNotesPage';
import { releaseData } from './ReleaseData';

import { HeaderView } from '@common/Page';

interface Props {
    setHeaderView?(view: HeaderView): void;
}

export class ReleaseNotesPageContainer extends React.PureComponent<Props> {
    public componentDidMount(): void {
        this.renderHeader();
    }

    public render() {
        return React.createElement(ReleaseNotesPage, {
            messages: releaseData,
        });
    }

    private renderHeader(): void {
        this.props.setHeaderView({
            firstLine: ReleaseNotesPageHeader(),
        });
    }
}
