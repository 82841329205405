import * as React from 'react';

import classNames from 'classnames';
import { CustomScrollbar_new as CustomScrollbar, Icon, IconType } from 'sber-marketing-ui';

import { HideFilterProvider, useFilterButtonPopup } from './helpers';

import * as style from './FilterButton.scss';

export interface FilterButtonProps extends React.ComponentPropsWithoutRef<'div'> {
    count: number;
    onReset: () => void;
    onShow?: () => void;
    onHide?: () => void;
}

export const FilterButton: React.FC<FilterButtonProps> = ({
    count,
    children,
    onReset,
    onShow,
    onHide,
    className,
    ...props
}) => {
    const [top, setTop] = React.useState(0);
    const ref = React.useRef<HTMLDivElement>();
    const [show, shown, setShow] = useFilterButtonPopup();
    const popupClassNames = classNames(style.popup, !show && style.popupHide);

    React.useEffect(() => {
        if (show) {
            setTop(ref.current.getBoundingClientRect().top);
        }
    }, [show]);

    const handleHide = () => {
        setShow(false);
        onHide?.();
    };

    const handleShow = () => {
        setShow(true);
        onShow?.();
    };

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.stopPropagation();

        if (show) {
            handleHide();
        } else {
            handleShow();
        }
    };

    const handlePopupClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
        e.stopPropagation();
    };

    const handleReset: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault();
        onReset();
    };

    return (
        <div {...props} className={classNames(style.root, className)}>
            <button className={style.button} onClick={handleClick}>
                <Icon type={IconType.FILTERS} svgSize={16} />
                Фильтры
                {count ? <span className={style.badge}>{count}</span> : null}
            </button>
            {count ? (
                <a href="#" className={style.reset} onClick={handleReset}>
                    сбросить
                </a>
            ) : null}
            {shown && (
                <div
                    ref={ref}
                    onClick={handlePopupClick}
                    style={{ '--filter-button-top': `${top}px` } as React.CSSProperties}
                    className={popupClassNames}
                >
                    <CustomScrollbar hideScrollX>
                        <div className={style.popupContent}>
                            <HideFilterProvider value={handleHide}>{children}</HideFilterProvider>
                        </div>
                    </CustomScrollbar>
                </div>
            )}
        </div>
    );
};
