import * as lodash from 'lodash';

import type { CreativeRequestTableItem, CreativeRequestTableItemTask, CreativeRequestTableSettings } from '@api';

import { IconType } from 'sber-marketing-ui';

export const enum ColumnHeaderType {
    Text = 'text',
    Expandable = 'expandableColumnHeader',
}

export const enum LineType {
    Tariff = 'tariff',
    Production = 'production',
    Orders = 'orders',
    Additions = 'additions',
    NotIssued = 'notIssued',
    Total = 'total',
}

export const enum ColumnName {
    ContractTotal = 'contractTotal',
    FactWithoutVat = 'factWithoutVat',
    Limit = 'limit',
    Delta = 'delta',
}

export const enum CellType {
    Text = 'text',
    Input = 'input',
    Textarea = 'textarea',
    FundsInput = 'funds_input',
    Select = 'select',
    FundsSelect = 'fundsSelect',
    Datepicker = 'datepicker',
    Placeholder = 'placeholder',
}

export interface AccessorParams {
    id: string;
    lot: number;
    lines: CreativeRequestTableItem[];
    subLines: CreativeRequestTableItemTask[];
    tableSettings: CreativeRequestTableSettings;
}

type Accessor<T> = (params: AccessorParams) => Promise<T>;
type AccessorWithValue<T> = (params: AccessorParams, value: any) => Promise<T>;

export type ValueAccessor = Accessor<any>;
export type TitleAccessor = Accessor<React.ReactText>;
export type ValueSetter = AccessorWithValue<void>;
export type ItemsAccessor = Accessor<any[]>;
export type DescriptionAccessor = Accessor<string>;
export type ReadOnlyAccessor = Accessor<boolean>;
export type CustomStyleAccessor = Accessor<React.CSSProperties>;
export type ValidationAccessor = AccessorWithValue<boolean>;
export type SuggestItemsAccessor = Accessor<string[]>;
export type IconAccessor = Accessor<{ type: IconType; color: string; size: number }>;

export interface ColumnParams {
    title: string;
    headerType: ColumnHeaderType;
    headerTooltip?: string;
    type: Record<LineType, CellType>;
    disableFilters?: Partial<Record<LineType, boolean>>;
    defaultWidth: number;
    disableWidthChange?: boolean;
    readOnly?: Partial<Record<LineType, ReadOnlyAccessor | boolean>> | ReadOnlyAccessor | boolean;
    customStyle?: Partial<Record<LineType, CustomStyleAccessor>> | CustomStyleAccessor;
    linkedColumns?: Partial<Record<LineType, ColumnName[]>>;
    getTitle?: Partial<Record<LineType, TitleAccessor>>;
    getValue: Partial<Record<LineType, ValueAccessor>> | ValueAccessor;
    setValue?: Partial<Record<LineType, ValueSetter>>;
    getItems?: Partial<Record<LineType, ItemsAccessor>> | ItemsAccessor;
    getSuggestItems?: Partial<Record<LineType, SuggestItemsAccessor>> | SuggestItemsAccessor;
    getDescription?: Partial<Record<LineType, DescriptionAccessor>> | DescriptionAccessor;
    getIcon?: Partial<Record<LineType, IconAccessor>> | IconAccessor;
}

export const ColumnsConfig: { [columnName: string]: ColumnParams } = {
    [ColumnName.ContractTotal]: {
        title: 'Итоги контракта',
        headerType: ColumnHeaderType.Expandable,
        type: {
            [LineType.Tariff]: CellType.Text,
            [LineType.Production]: CellType.Text,
            [LineType.Orders]: CellType.Text,
            [LineType.Additions]: CellType.Text,
            [LineType.NotIssued]: CellType.Text,
            [LineType.Total]: CellType.Text,
        },
        defaultWidth: 220,
        disableWidthChange: true,
        readOnly: {
            [LineType.Tariff]: true,
            [LineType.Production]: true,
            [LineType.Orders]: true,
            [LineType.Additions]: true,
            [LineType.NotIssued]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Tariff]: async (params: AccessorParams) => 'Тариф',
            [LineType.Production]: async (params: AccessorParams) => 'Производство',
            [LineType.Orders]: async (params: AccessorParams) => 'Поручения',
            [LineType.Additions]: async (params: AccessorParams) => 'Дополнения',
            [LineType.NotIssued]: async (params: AccessorParams) => 'Неоформленное',
            [LineType.Total]: async (params: AccessorParams) => 'Итого',
        },
        customStyle: {
            [LineType.Orders]: async (params: AccessorParams) => ({ color: '#7e8681', paddingLeft: 16 }),
            [LineType.Additions]: async (params: AccessorParams) => ({ color: '#7e8681', paddingLeft: 16 }),
            [LineType.Total]: async (params: AccessorParams) => ({ fontWeight: 600 }),
        },
    },
    [ColumnName.FactWithoutVat]: {
        title: 'Прошло (Фактическая стоимость без НДС)',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Tariff]: CellType.FundsInput,
            [LineType.Production]: CellType.FundsInput,
            [LineType.Orders]: CellType.FundsInput,
            [LineType.Additions]: CellType.FundsInput,
            [LineType.NotIssued]: CellType.FundsInput,
            [LineType.Total]: CellType.FundsInput,
        },
        defaultWidth: 220,
        disableWidthChange: true,
        readOnly: {
            [LineType.Tariff]: true,
            [LineType.Production]: true,
            [LineType.Orders]: true,
            [LineType.Additions]: true,
            [LineType.NotIssued]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Tariff]: async (params: AccessorParams) => {
                const subLines = params.subLines.filter(
                    (item) => !item.model.isArchived && item.model.document?.includes('Тариф'),
                );

                return lodash.sumBy(subLines, (item) => formatMoney(item.model.fact));
            },
            [LineType.Production]: async (params: AccessorParams) => {
                const subLines = params.subLines.filter(
                    (item) =>
                        !item.model.isArchived &&
                        (item.model.document?.includes('Дополнение') || item.model.document?.includes('Поручение')),
                );

                return lodash.sumBy(subLines, (item) => formatMoney(item.model.fact));
            },
            [LineType.Orders]: async (params: AccessorParams) => {
                const subLines = params.subLines.filter(
                    (item) => !item.model.isArchived && item.model.document?.includes('Поручение'),
                );

                return lodash.sumBy(subLines, (item) => formatMoney(item.model.fact));
            },
            [LineType.Additions]: async (params: AccessorParams) => {
                const subLines = params.subLines.filter(
                    (item) => !item.model.isArchived && item.model.document?.includes('Дополнение'),
                );

                return lodash.sumBy(subLines, (item) => formatMoney(item.model.fact));
            },
            [LineType.NotIssued]: async (params: AccessorParams) => {
                const subLines = params.subLines.filter((item) => !item.model.isArchived && !item.model.document);

                return lodash.sumBy(subLines, (item) => formatMoney(item.model.fact));
            },
            [LineType.Total]: async (params: AccessorParams) => {
                const subLines = params.subLines.filter(
                    (item) =>
                        !item.model.isArchived &&
                        (item.model.document?.includes('Тариф') ||
                            item.model.document?.includes('Дополнение') ||
                            item.model.document?.includes('Поручение') ||
                            !item.model.document),
                );

                return lodash.sumBy(subLines, (item) => formatMoney(item.model.fact));
            },
        },
        customStyle: {
            [LineType.Orders]: async (params: AccessorParams) => ({ paddingLeft: 16 }),
            [LineType.Additions]: async (params: AccessorParams) => ({ paddingLeft: 16 }),
            [LineType.Total]: async (params: AccessorParams) => ({ fontWeight: 600 }),
        },
    },
    [ColumnName.Limit]: {
        title: 'Лимит',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Tariff]: CellType.FundsInput,
            [LineType.Production]: CellType.FundsInput,
            [LineType.Orders]: CellType.Placeholder,
            [LineType.Additions]: CellType.Placeholder,
            [LineType.NotIssued]: CellType.Placeholder,
            [LineType.Total]: CellType.FundsInput,
        },
        defaultWidth: 220,
        disableWidthChange: true,
        readOnly: {
            [LineType.Tariff]: async (params: AccessorParams) =>
                params.tableSettings ? !params.tableSettings.model.setContractLimitLot2 : true,
            [LineType.Production]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Tariff]: async (params: AccessorParams) =>
                formatMoney(params.tableSettings?.model.contractLimitLot2) || null,
            [LineType.Production]: async (params: AccessorParams) => {
                const lines = params.lines.filter((item) => !item.model.isArchived);

                const protocolLimitSum = lodash.sumBy(lines, (item) => formatMoney(item.model.protocolLimit));

                const limit = formatMoney(params.tableSettings?.model.contractLimitLot2) || null;

                return protocolLimitSum - limit;
            },
            [LineType.Orders]: async (params: AccessorParams) => null,
            [LineType.Additions]: async (params: AccessorParams) => null,
            [LineType.NotIssued]: async (params: AccessorParams) => null,
            [LineType.Total]: async (params: AccessorParams) => {
                const lines = params.lines.filter((item) => !item.model.isArchived);

                return lodash.sumBy(lines, (item) => formatMoney(item.model.protocolLimit));
            },
        },
        setValue: {
            [LineType.Tariff]: async (params: AccessorParams, value: any) => {
                await params.tableSettings.model.setContractLimitLot2({
                    contractLimitLot2: formatMoneyInput(value),
                });
            },
        },
        customStyle: {
            [LineType.Total]: async (params: AccessorParams) => ({ fontWeight: 600 }),
        },
    },
    [ColumnName.Delta]: {
        title: 'Дельта',
        headerType: ColumnHeaderType.Text,
        headerTooltip: 'Расчет дельты: Лимит - Фактическая стоимость без НДС',
        type: {
            [LineType.Tariff]: CellType.FundsInput,
            [LineType.Production]: CellType.Placeholder,
            [LineType.Orders]: CellType.Placeholder,
            [LineType.Additions]: CellType.Placeholder,
            [LineType.NotIssued]: CellType.Placeholder,
            [LineType.Total]: CellType.FundsInput,
        },
        defaultWidth: 220,
        disableWidthChange: true,
        readOnly: {
            [LineType.Tariff]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Tariff]: async (params: AccessorParams) => {
                const subLines = params.subLines.filter(
                    (item) => !item.model.isArchived && item.model.document?.includes('Тариф'),
                );

                const fastSumWithoutVat = lodash.sumBy(subLines, (item) => formatMoney(item.model.fact));

                const limit = formatMoney(params.tableSettings?.model.contractLimitLot2);

                return limit - fastSumWithoutVat;
            },
            [LineType.Production]: async (params: AccessorParams) => null,
            [LineType.Orders]: async (params: AccessorParams) => null,
            [LineType.Additions]: async (params: AccessorParams) => null,
            [LineType.NotIssued]: async (params: AccessorParams) => null,
            [LineType.Total]: async (params: AccessorParams) => {
                const lines = params.lines.filter((item) => !item.model.isArchived);

                const subLines = params.subLines.filter(
                    (item) =>
                        !item.model.isArchived &&
                        (item.model.document?.includes('Тариф') ||
                            item.model.document?.includes('Дополнение') ||
                            item.model.document?.includes('Поручение') ||
                            !item.model.document),
                );

                const totalLimit = lodash.sumBy(lines, (item) => formatMoney(item.model.protocolLimit));
                const totalFact = lodash.sumBy(subLines, (item) => formatMoney(item.model.fact));

                return totalLimit - totalFact;
            },
        },
        customStyle: {
            [LineType.Total]: async (params: AccessorParams) => ({ fontWeight: 600 }),
        },
    },
};

export const tableColumns: ColumnName[] = [
    ColumnName.ContractTotal,
    ColumnName.FactWithoutVat,
    ColumnName.Limit,
    ColumnName.Delta,
];

export const leftFixedColumns: ColumnName[] = [];
export const rightFixedColumns: ColumnName[] = [];

function formatMoney(value: React.ReactText): number {
    if (!value) {
        return 0;
    }

    const parsedValue = typeof value === 'string' ? parseInt(value, 10) : value;

    return parsedValue / 100;
}

function formatMoneyInput(value: React.ReactText): number {
    if (!value) {
        return 0;
    }

    const parsedValue = typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value;

    return Math.round(parsedValue * 100);
}
