// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-Warnings-_Warnings__blockWrapper{padding:16px 24px 0 24px}.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-Warnings-_Warnings__bottomBorder{padding-bottom:16px;border-bottom:solid 1px #e3e9e5}.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-Warnings-_Warnings__root{width:100%;padding:16px}.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-Warnings-_Warnings__margin{display:block;margin-bottom:8px}.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-Warnings-_Warnings__bold{font-weight:bold}.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-Warnings-_Warnings__link{text-decoration:none}.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-Warnings-_Warnings__transferFundsButton{cursor:pointer}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/LineModal/CommonStyles.scss","webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/LineModal/Content/Warnings/Warnings.scss"],"names":[],"mappings":"AAAA,yGACI,wBAAyB,CAC5B,yGAGG,mBAAoB,CAEpB,+BAAgC,CCLpC,iGACI,UAAW,CACX,YAAa,CAChB,mGAGG,aAAc,CAEd,iBAAkB,CACrB,iGAGG,gBAAiB,CACpB,iGAGG,oBAAqB,CACxB,gHAGG,cAAe","sourcesContent":[".blockWrapper {\n    padding: 16px 24px 0 24px;\n}\n\n.bottomBorder {\n    padding-bottom: 16px;\n\n    border-bottom: solid 1px #e3e9e5;\n}\n","@import '../../CommonStyles.scss';\n\n.root {\n    width: 100%;\n    padding: 16px;\n}\n\n.margin {\n    display: block;\n\n    margin-bottom: 8px;\n}\n\n.bold {\n    font-weight: bold;\n}\n\n.link {\n    text-decoration: none;\n}\n\n.transferFundsButton {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blockWrapper": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-Warnings-_Warnings__blockWrapper",
	"bottomBorder": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-Warnings-_Warnings__bottomBorder",
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-Warnings-_Warnings__root",
	"margin": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-Warnings-_Warnings__margin",
	"bold": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-Warnings-_Warnings__bold",
	"link": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-Warnings-_Warnings__link",
	"transferFundsButton": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-Warnings-_Warnings__transferFundsButton"
};
export default ___CSS_LOADER_EXPORT___;
