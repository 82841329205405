import { createSelector } from 'reselect';
import { HistoryView, RecentView } from 'sber-marketing-types/frontend';

import { StoreState } from '../';
import { HistoryState } from './types';

export const getHistoryState = (state: StoreState): HistoryState => state.history;

const getId = (_: StoreState, id: string): string => id;

export const getHistories = createSelector(getHistoryState, ({ histories }): HistoryView[] => histories);

export const getRecentViews = createSelector(getHistoryState, ({ recentViews }): RecentView[] => recentViews);

export const getTaskHistoryById = createSelector([getHistories, getId], (histories, id): HistoryView[] =>
    histories.filter(({ id: historyId, type }) => historyId === id && type === 'task'),
);
