import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { BudgetStatus } from '@mrm/budget';
import autobind from 'autobind-decorator';

import { StoreState } from '@store';
import { User, getLoginUser } from '@store/user';
import { getBudgetByStatusUserConfig } from '@store/userConfig/budget';
import {
    TotalBudgets,
    ColumnsVisiblityFilter,
    ColumnName,
    ColumnsWidth,
    changeCellValue,
    getBudgetExecutionPageState,
    getTableFilters,
    getActivitiesTotalBudgets,
    ChangeCellValueParams,
    TableLine,
    CellValueType,
    userCanEditOrCreateBudgetData,
} from '@store/budgetExecution';
import {
    ComponentState as TransitionMenuComponentState,
    InternalTransferDirection,
    CellPosition,
    setHoveredCell,
    onCellClick,
    setDonorCell,
    setAcceptorCell,
    isBudgetTransferMenuClosed,
    getBudgetTransferMenuState,
} from '@store/budgetExecution/budgetTransferMenu';
import {
    ComponentState as TransferBudgetItemsToPlanningMenuComponentState,
    getTransferBudgetItemsToPlanningMenuState,
    initActivityTransfer,
} from '@store/budgetExecution/transferBudgetItemsToPlanningMenu';
import { loadByActivityId } from '@store/budgetExecution/miscBudgetItems';

import { TableBody } from './TableBody';
import { Captions } from '@common/Utils';
import { ColumnsList } from '../../ColumnsConfig';
import { FrameManager } from '../FrameManager';
import { CellParams } from '../LayerManager';

interface Props extends Partial<MapProps>, Partial<DispatchProps>, Partial<RouteComponentProps> {
    frameManager: FrameManager;
    currentFrameIndex: number;
    rootWidth: number;
    visibleColumnsNames: ColumnName[];
    currentYScroll: number;
    onLineMouseEnter: (id: string, skipCheck?: boolean) => void;
    fixedColumnsLinesRef: (element: HTMLDivElement) => void;
    totalLinesContentRef: (element: HTMLDivElement) => void;
    onLineMouseLeave: () => void;
    onInfoMouseEnter: (lineId: string) => void;
    onInfoMouseLeave: () => void;
    onDropdownCellClick: (
        columnName: ColumnName,
        budgetItemId: string,
        dropdownContent: JSX.Element,
        contentHeight: number,
    ) => void;
}

interface MapProps {
    budgetId: string;
    fixedColumnsNames: ColumnName[];
    columnsWidth: ColumnsWidth;
    columnsVisiblityFilter: ColumnsVisiblityFilter;
    isBudgetTransferMenuClosed: boolean;
    donorCell: CellPosition[];
    acceptorCell: CellPosition[];
    activitiesTotalBudgets: TotalBudgets;
    lines: TableLine[];
    user: User;
    linesForTransferToPlanning: string[];
    shouldColorSelectedLinesForTransition: boolean;
    shouldColorSelectedLinesForLineTransferToPlanning: boolean;
    lineForTransferBudgetItemsToPlan: string;
    userCanEditOrCreateBudgetData: boolean;
    internalTransferDirection: InternalTransferDirection;
}

interface DispatchProps {
    loadByActivityId: (activityIds: string[]) => void;
    onCellClick: (cellPosition: CellPosition) => void;
    setHoveredCell: (cellPosition: CellPosition) => void;
    setDonorCell: (cellPosition: CellPosition) => void;
    setAcceptorCell: (cellPosition: CellPosition) => void;
    changeCellValue: (payload: ChangeCellValueParams) => void;
    initActivityTransfer: (activityId: string) => void;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class TableBodyContainer extends React.Component<Props> {
    public render(): JSX.Element {
        return React.createElement(TableBody, {
            shouldColorSelectedLinesForTransition: this.props.shouldColorSelectedLinesForTransition,
            shouldColorSelectedLinesForLineTransferToPlanning:
                this.props.shouldColorSelectedLinesForLineTransferToPlanning,
            donorCell: this.props.donorCell,
            acceptorCell: this.props.acceptorCell,
            frameManager: this.props.frameManager,
            currentFrameIndex: this.props.currentFrameIndex,
            fixedColumnsNames: this.props.fixedColumnsNames,
            activitiesTotalBudgets: this.props.activitiesTotalBudgets,
            columnsVisiblityFilter: this.props.columnsVisiblityFilter,
            rootWidth: this.props.rootWidth,
            columnsWidth: this.props.columnsWidth,
            visibleColumnsNames: this.props.visibleColumnsNames,
            isBudgetTransferMenuClosed: this.props.isBudgetTransferMenuClosed,
            linesForTransferToPlanning: this.props.linesForTransferToPlanning,
            lineForTransferBudgetItemsToPlan: this.props.lineForTransferBudgetItemsToPlan,
            userCanEditOrCreateBudgetData: this.props.userCanEditOrCreateBudgetData,
            internalTransferDirection: this.props.internalTransferDirection,
            currentYScroll: this.props.currentYScroll,
            fixedColumnsLinesRef: this.props.fixedColumnsLinesRef,
            totalLinesContentRef: this.props.totalLinesContentRef,
            onLineMouseEnter: this.props.onLineMouseEnter,
            onLineMouseLeave: this.props.onLineMouseLeave,
            onCellClick: this.onCellClick,
            onCellMouseEnter: this.onCellMouseEnter,
            onCellMouseLeave: this.onCellMouseLeave,
            onEditActivityButtonClick: this.onEditActivityButtonClick,
            onCopyActivityButtonClick: this.onCopyActivityButtonClick,
            onInfoMouseEnter: this.props.onInfoMouseEnter,
            onInfoMouseLeave: this.props.onInfoMouseLeave,
            onInputValueChange: this.onInputValueChange,
            onDropdownCellClick: this.props.onDropdownCellClick,
            onDropdownItemSelection: this.onDropdownItemSelection,
            onDatepickerValueChange: this.onDatepickerValueChange,
            onCellCopy: this.onCellCopy,
            onTransferBudgetItemsToPlanningButtonClick: this.props.initActivityTransfer,
        });
    }

    @autobind
    protected onCellCopy(
        lineId: string,
        columnName: ColumnName,
        cellParams: CellParams,
        event: React.ClipboardEvent<HTMLDivElement>,
    ): void {
        const column = ColumnsList.find((column) => column.name === columnName);

        if (column.valueType === CellValueType.Currency) {
            const value = String(cellParams.tooltip || cellParams.title)
                .replace(/ /g, '')
                .replace(Captions.Roubles, '')
                .replace('.', ',');

            event.clipboardData.setData('text/plain', value);
            event.preventDefault();
        }
    }

    @autobind
    protected onInputValueChange(
        budgetItemId: string,
        columnName: ColumnName,
        value: string,
        originalValue: string,
        generateSameValueChange: boolean,
    ) {
        this.props.changeCellValue({
            change: {
                budgetItemId,
                columnName,
                value: value || null,
                originalValue: originalValue || null,
            },
            generateSameValueChange,
            line: this.props.lines.find((line) => line.id === budgetItemId),
        });

        if (columnName === ColumnName.ActivityName && value !== originalValue) {
            const activityId = this.props.lines.find((line) => line.id === budgetItemId)?.activityId;
            if (activityId) {
                this.props.loadByActivityId([activityId]);
            }
        }
    }

    @autobind
    protected onCellClick(lineId: string, columnName: ColumnName) {
        this.props.onCellClick({ lineId, columnName });
        this.props.onLineMouseEnter(lineId, true);
        // const { donorCell, acceptorCell, lockedFrom, lockedTo, transitionDirection } = this.props;

        // const donorIsSelected = !!donorCell.lineId;
        // const acceptorIsSelected = !!acceptorCell.lineId;

        // const cellIsDonor = donorCell.lineId == lineId && donorCell.columnName == columnName;
        // const cellIsAcceptor = acceptorCell.lineId == lineId && acceptorCell.columnName == columnName;

        // if (cellIsDonor) {
        //     this.props.setDonorCell({ lineId: null, columnName: null });

        //     if (!lockedFrom) {
        //         this.props.setDonorData(null);
        //     }
        // }

        // if (cellIsAcceptor) {
        //     this.props.setAcceptorCell({ lineId: null, columnName: null });

        //     if (!lockedTo) {
        //         this.props.setAcceptorData(null);
        //     }
        // }

        // if (!cellIsDonor && !cellIsAcceptor) {
        //     const budgetItem = this.getBudgetItemById(lineId);

        //     if (transitionDirection == TransitionDirection.Outcome) {
        //         this.props.setDonorCell({ lineId, columnName });

        //         if (budgetItem) {
        //             this.props.setDonorData({ budgetItem });
        //         }
        //     } else if (!acceptorIsSelected) {
        //         this.props.setAcceptorCell({ lineId, columnName });

        //         if (budgetItem) {
        //             this.props.setAcceptorData({ budgetItem });
        //         }
        //     } else if (!donorIsSelected) {
        //         this.props.setDonorCell({ lineId, columnName });

        //         if (budgetItem) {
        //             this.props.setDonorData({ budgetItem });
        //         }
        //     }
        // }
    }

    @autobind
    protected onDropdownItemSelection(budgetItemId: string, columnName: ColumnName, id: string, originalId: string) {
        const { lines } = this.props;

        this.props.changeCellValue({
            change: {
                budgetItemId,
                columnName,
                value: id,
                originalValue: originalId,
            },
            line: lines.find((item) => item.id == budgetItemId),
        });
    }

    @autobind
    protected onDatepickerValueChange(budgetItemId: string, columnName: ColumnName, value: Date, originalValue: Date) {
        this.props.changeCellValue({
            change: {
                budgetItemId,
                columnName,
                value,
                originalValue,
            },
            line: this.props.lines.find((line) => line.id === budgetItemId),
        });
    }

    @autobind
    protected onCellMouseEnter(budgetId: string, columnName: ColumnName) {
        if (!this.props.shouldColorSelectedLinesForTransition) {
            this.props.setHoveredCell({
                lineId: budgetId,
                columnName,
            });
        }
    }

    @autobind
    protected onCellMouseLeave() {
        if (!this.props.shouldColorSelectedLinesForTransition) {
            this.props.setHoveredCell({
                lineId: null,
                columnName: null,
            });
        }
    }

    @autobind
    protected onEditActivityButtonClick(activityBudgetId: string) {
        const { budgetId } = this.props;
        this.openUrlInSameTab(`/budget/execution/${activityBudgetId}?budgetId=${budgetId}`);
    }

    @autobind
    protected onCopyActivityButtonClick(activityBudgetId: string) {
        const { budgetId } = this.props;
        this.openUrlInSameTab(`/budget/execution/${activityBudgetId}/copy?budgetId=${budgetId}`);
    }

    private openUrlInSameTab(url: string) {
        this.props.history.push(url);
    }

    // private getBudgetItemById(budgetItemId: string): BudgetItem {
    //     return this.props.budgetItems.find(item => item.id === budgetItemId);
    // }
}

function mapStateToProps(state: StoreState): MapProps {
    const { budgetId } = getBudgetByStatusUserConfig(state, BudgetStatus.Execution);
    const {
        fixedColumnsNames,
        columnsWidth,
        computedData: { tableLines },
    } = getBudgetExecutionPageState(state);
    const { columnsVisiblityFilter } = getTableFilters(state);
    const {
        controls: { componentState: transitionComponentState, internalTransferDirection },
        cells: { from, to },
    } = getBudgetTransferMenuState(state);
    const shouldColorSelectedLinesForTransition =
        transitionComponentState === TransitionMenuComponentState.InternalTransferLineSelection ||
        transitionComponentState === TransitionMenuComponentState.InternalTransferSumEntering;

    const {
        componentState: transferBudgetItemsToPlanningComponentState,
        linesToTransfer,
        activityToSelectLines: lineForTransferBudgetItemsToPlan,
    } = getTransferBudgetItemsToPlanningMenuState(state).props;
    const shouldColorSelectedLinesForLineTransferToPlanning =
        transferBudgetItemsToPlanningComponentState === TransferBudgetItemsToPlanningMenuComponentState.Opened;

    return {
        budgetId,
        fixedColumnsNames,
        columnsVisiblityFilter,
        columnsWidth,
        isBudgetTransferMenuClosed: !isBudgetTransferMenuClosed(transitionComponentState),
        donorCell: from,
        acceptorCell: to,
        activitiesTotalBudgets: getActivitiesTotalBudgets(state),
        lines: tableLines,
        user: getLoginUser(state),
        shouldColorSelectedLinesForTransition,
        shouldColorSelectedLinesForLineTransferToPlanning,
        linesForTransferToPlanning: linesToTransfer,
        lineForTransferBudgetItemsToPlan,
        userCanEditOrCreateBudgetData: userCanEditOrCreateBudgetData(state),
        internalTransferDirection,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return bindActionCreators(
        {
            setHoveredCell,
            setDonorCell,
            setAcceptorCell,
            changeCellValue,
            onCellClick,
            initActivityTransfer,
            loadByActivityId,
        },
        dispatch,
    );
}
