import { keyBy, omit, mapValues, mapKeys } from 'lodash';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { FileAssets } from './types';
import { addOne, addList, removeOne, removeList, clearList, updateIds } from './actions';

const INITIAL_STATE: FileAssets = { assets: {}, nameIndex: {} };

export const fileAssets = reducerWithInitialState<FileAssets>(INITIAL_STATE)
    .case(addOne, ({ assets, nameIndex }, asset) => ({
        assets: {
            ...assets,
            [asset.id]: asset,
        },
        nameIndex: {
            ...nameIndex,
            [asset.name]: asset.id,
        },
    }))
    .case(addList, ({ assets, nameIndex }, newAssets) => ({
        assets: {
            ...assets,
            ...keyBy(newAssets, 'id'),
        },
        nameIndex: {
            ...nameIndex,
            ...mapValues(keyBy(newAssets, 'name'), 'id'),
        },
    }))
    .case(removeOne, ({ assets, nameIndex }, id) => ({
        assets: omit(assets, [id]),
        nameIndex: omit(nameIndex, [id]),
    }))
    .case(removeList, ({ assets, nameIndex }, ids) => ({
        assets: omit(assets, ids),
        nameIndex: omit(nameIndex, ids),
    }))
    .case(updateIds, ({ assets, ...rest }, ids) => ({
        ...rest,
        assets: mapValues(
            mapKeys(assets, (value, key) => ids[key] || key),
            ({ id, ...rest }) => ({ ...rest, id: ids[id] || id }),
        ),
    }))
    .case(clearList, () => ({ ...INITIAL_STATE }));
