import * as React from 'react';

import { useGetActivityQuery } from '@api';

import { CopyTextIconButton, CopyTextIconButtonProps } from '@common/components';

export interface CopyActivityTitleIconButtonProps extends Omit<CopyTextIconButtonProps, 'text'> {
    activityId: number;
}

export function CopyActivityTitleIconButton({ activityId, ...props }: CopyActivityTitleIconButtonProps) {
    const { data } = useGetActivityQuery({ id: activityId });

    if (!data) return null;

    return (
        <CopyTextIconButton
            data-qa-id="CopyActivityTitleIconButton"
            title="Скопировать название проекта в буфер обмена"
            {...props}
            text={data.name || ''}
        />
    );
}
