import { v4 as uuidv4 } from 'uuid';
import { keyBy } from 'lodash';

import type { TypeResponseAutopilotGenerate } from 'sber-marketing-types/backend';
import type { FormTextData } from '../types';

interface Converter {
    (params: ConverterParams): ConverterResult;
}

type ConverterParams = TypeResponseAutopilotGenerate;

type ConverterResult = FormTextData;

export const convertResponseToStore: Converter = ({ title, description }) => {
    return {
        titles: keyBy(
            title.map((title) => ({ id: uuidv4(), value: title })),
            'id',
        ),
        contents: keyBy(
            description.map((content) => ({ id: uuidv4(), value: content })),
            'id',
        ),
    };
};
