// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-pages-TaskPage-TaskPageError-_TaskPageError__root{display:flex;flex-direction:column;width:100%;height:100%;font-family:Roboto;align-items:center;justify-content:center}.src-client-modules-activity-pages-task-pages-TaskPage-TaskPageError-_TaskPageError__title,.src-client-modules-activity-pages-task-pages-TaskPage-TaskPageError-_TaskPageError__message{width:80%}.src-client-modules-activity-pages-task-pages-TaskPage-TaskPageError-_TaskPageError__title{height:48px;text-align:center;font-size:48px;font-weight:bold;line-height:48px}.src-client-modules-activity-pages-task-pages-TaskPage-TaskPageError-_TaskPageError__message{margin-top:24px;font-size:24px;line-height:24px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/pages/TaskPage/TaskPageError/TaskPageError.scss"],"names":[],"mappings":"AAAA,0FACI,YAAa,CACb,qBAAsB,CAEtB,UAAW,CACX,WAAY,CAEZ,kBAAmB,CAEnB,kBAAmB,CACnB,sBAAuB,CAC1B,wLAIG,SAAU,CACb,2FAGG,WAAY,CAEZ,iBAAkB,CAElB,cAAe,CACf,gBAAiB,CACjB,gBAAiB,CACpB,6FAGG,eAAgB,CAEhB,cAAe,CACf,gBAAiB","sourcesContent":[".root {\n    display: flex;\n    flex-direction: column;\n\n    width: 100%;\n    height: 100%;\n\n    font-family: Roboto;\n\n    align-items: center;\n    justify-content: center;\n}\n\n.title,\n.message {\n    width: 80%;\n}\n\n.title {\n    height: 48px;\n\n    text-align: center;\n\n    font-size: 48px;\n    font-weight: bold;\n    line-height: 48px;\n}\n\n.message {\n    margin-top: 24px;\n\n    font-size: 24px;\n    line-height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-pages-TaskPage-TaskPageError-_TaskPageError__root",
	"title": "src-client-modules-activity-pages-task-pages-TaskPage-TaskPageError-_TaskPageError__title",
	"message": "src-client-modules-activity-pages-task-pages-TaskPage-TaskPageError-_TaskPageError__message"
};
export default ___CSS_LOADER_EXPORT___;
