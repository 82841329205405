import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { UserConfigType } from 'sber-marketing-types/openid';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getLoadingStatus, mergeMyTasksPageUserConfig } from '@store/userConfig/dashboard';
import * as userConfigStore from '@store/userConfig';
import * as workTypesStore from '@store/workTypes';
import * as divisionsStore from '@store/divisions';
import * as usersStore from '@store/users';
import * as departmentsStore from '@store/departments';
import * as organizationsStore from '@store/organizations';

import { MapProps, DispatchProps, TaskFiltersMenuContainer } from './TaskFiltersMenuContainer';
import { setRawMyTasksFilter } from '@store/userConfig/dashboard/actions';

const workTypesEntities = workTypesStore.StoreTypes.MY_TASKS_FILTER;
const divisionsEntities = divisionsStore.StoreTypes.MY_TASKS_FILTER;
const organizationsEntities = organizationsStore.StoreTypes.MY_TASKS_FILTER;
const executorsEntities = usersStore.StoreTypes.MY_TASKS_EXECUTORS_FILTER;
const authorsEntities = usersStore.StoreTypes.MY_TASKS_AUTHORS_FILTER;
const departmentsEntities = departmentsStore.StoreTypes.MY_TASKS_FILTER;

function mapStateToProps(state: StoreState): MapProps {
    const filters = userConfigStore.getRawMyTasksPageFilters(state);

    const userConfigLoadingStatus = getLoadingStatus(state);
    const workTypesLoadingStatus = workTypesStore.getLoadingStatus(state, workTypesEntities);
    const divisionsLoadingStatus = divisionsStore.getLoadingStatus(state, divisionsEntities);
    const organizationsLoadingStatus = organizationsStore.getLoadingStatus(state, organizationsEntities);
    const executorsLoadingStatus = usersStore.getLoadingStatus(state, executorsEntities);
    const authorsLoadingStatus = usersStore.getLoadingStatus(state, authorsEntities);
    const departmentsLoadingStatus = departmentsStore.getLoadingStatus(state, departmentsEntities);

    const shouldMergeUserConfig =
        userConfigLoadingStatus === LoadingStatus.LOADED &&
        workTypesLoadingStatus === LoadingStatus.LOADED &&
        divisionsLoadingStatus === LoadingStatus.LOADED &&
        executorsLoadingStatus === LoadingStatus.LOADED &&
        authorsLoadingStatus === LoadingStatus.LOADED &&
        organizationsLoadingStatus === LoadingStatus.LOADED &&
        departmentsLoadingStatus === LoadingStatus.LOADED;

    const isLoading =
        userConfigLoadingStatus !== LoadingStatus.LOADED ||
        workTypesLoadingStatus !== LoadingStatus.LOADED ||
        divisionsLoadingStatus !== LoadingStatus.LOADED ||
        executorsLoadingStatus !== LoadingStatus.LOADED ||
        authorsLoadingStatus !== LoadingStatus.LOADED ||
        organizationsLoadingStatus !== LoadingStatus.LOADED ||
        departmentsLoadingStatus !== LoadingStatus.LOADED;

    const workTypes = workTypesStore.getWorkTypes(state, workTypesEntities);
    const executors = usersStore.getUsers(state, executorsEntities);
    const authors = usersStore.getUsers(state, authorsEntities);
    const departments = departmentsStore.getDepartments(state, departmentsEntities);

    return {
        isLoading,
        shouldMergeUserConfig,
        filters,
        myTasksFiltersPage: true,
        workTypes,
        authors,
        executors,
        departments,
    };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
    return {
        setFilters: (filters) => dispatch(setRawMyTasksFilter(filters)),
        save: (filters) =>
            dispatch(
                userConfigStore.saveUserConfig({
                    type: UserConfigType.Dashboard,
                    payload: {
                        myTasksFilters: filters,
                    },
                }),
            ),
        loadFiltersEntities: (filters) => {
            const { divisions, workTypes, executors, authors, departments } = filters;

            dispatch(
                divisionsStore.loadDivisions({
                    store: divisionsEntities,
                    ids: divisions,
                }),
            );

            dispatch(
                workTypesStore.loadWorkTypes({
                    store: workTypesEntities,
                    ids: workTypes,
                }),
            );

            dispatch(
                organizationsStore.loadOrganizations({
                    store: organizationsEntities,
                }),
            );

            dispatch(
                usersStore.loadUsers({
                    store: executorsEntities,
                    ids: executors,
                }),
            );

            dispatch(
                usersStore.loadUsers({
                    store: authorsEntities,
                    ids: authors,
                }),
            );

            dispatch(
                departmentsStore.loadDepartments({
                    store: departmentsEntities,
                    ids: departments,
                }),
            );
        },
        resetEntitiesStores: () => {
            dispatch(divisionsStore.resetStore(divisionsEntities));
            dispatch(workTypesStore.resetStore(workTypesEntities));
            dispatch(usersStore.resetStore(executorsEntities));
            dispatch(usersStore.resetStore(authorsEntities));
            dispatch(departmentsStore.resetStore(departmentsEntities));
        },
        mergeUserConfig: () => dispatch(mergeMyTasksPageUserConfig(null)),
    };
}

export const MyTasksPageTaskFilterMenu = connect(mapStateToProps, mapDispatchToProps)(TaskFiltersMenuContainer);
