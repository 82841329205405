import * as moment from 'moment';

import { Month } from '@mrm/budget';
import type { PlainDictionary } from '@mrm/dictionary';
import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type { Autopilot, AutopilotPlacementPlanData } from 'sber-marketing-types/backend';

export interface PageState {
    autopilot: Autopilot;
    activity: Activity;
    mediaplan: AutopilotPlacementPlanData[];
    dictionaries: GroupedDictionaries;
    briefStageForm: BriefStageForm;
    selectedBudgetItemIds: string[];
    creativeStageForm: CreativeStageForm;
    currentStage: number;
    calculatingMediaplan: boolean;
}

export interface BriefStageForm {
    blockId: string;
    productId: string;
    budget: string;
    budgetError: string;
    dateStart: moment.Moment;
    dateEnd: moment.Moment;
    peakDates: Date[];
    targetAgeFrom: string;
    targetAgeTo: string;
}

export interface CreativeStageForm {
    vkUrl: string;
    facebookUrl: string;
    okUrl: string;
    youtubeUrl: string;
}

export interface GroupedDictionaries {
    [dictionaryType: string]: PlainDictionary[];
}

export interface SwitchKeyActivityParams {
    id: number;
    isKey: boolean;
}

export const MONTHS = [
    Month.Jan,
    Month.Feb,
    Month.Mar,
    Month.Apr,
    Month.May,
    Month.Jun,
    Month.Jul,
    Month.Aug,
    Month.Sept,
    Month.Oct,
    Month.Nov,
    Month.Dec,
];
