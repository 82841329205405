import * as React from 'react';
import * as lodash from 'lodash';
import classNames from 'classnames';
import { BriefField } from 'sber-marketing-types/frontend';
import { Toggle, TogglePosition, ToggleThemes } from 'sber-marketing-ui';

import { Field } from './Field';

import * as style from './Block.scss';

interface Props {
    id: string;
    briefId: string;
    name: string;
    fields: BriefField[];
    isOpened: boolean;
    isRequired: boolean;
    editRight: boolean;
    onClickToggle: () => void;
}

export const Block: React.FC<Props> = ({
    id,
    briefId,
    name,
    fields,
    isRequired,
    isOpened,
    editRight,
    onClickToggle,
}) => {
    return (
        <div
            className={classNames(style.root, 'section', `section ${briefId}-${id}`)}
            key={`block ${id}`}
            {...{
                'qa-id': 'briefBlock',
                // 'qa-index': index
            }}
            data-section-id={`${briefId}-${id}`}
        >
            <div className={style.header}>
                {!isRequired && editRight && (
                    <div className={style.toggle}>
                        <Toggle
                            theme={ToggleThemes.GREY_AND_GREEN}
                            position={isOpened ? TogglePosition.RIGHT : TogglePosition.LEFT}
                            onClick={onClickToggle}
                        />
                    </div>
                )}
                <div className={style.title}>{name || 'Новый блок'}</div>
            </div>

            {!isRequired ? (
                isOpened && <Fields briefId={briefId} fields={fields} />
            ) : (
                <Fields briefId={briefId} fields={fields} />
            )}
        </div>
    );
};

interface FieldsProps {
    briefId: string;
    fields: BriefField[];
}

const Fields: React.FC<FieldsProps> = ({ briefId, fields }) => (
    <div className={style.fields}>
        {lodash
            .sortBy(fields, (item) => item.order)
            .filter((field) => !field.properties.switchPropertyId)
            .map((field, index) => (
                <div className={style.field} key={`field ${field.id}`}>
                    <Field
                        index={index}
                        id={field.id}
                        briefId={briefId}
                        uniqId={field.uniqId}
                        parentUniqId={field.parentUniqId}
                        fields={fields}
                    />
                </div>
            ))}
    </div>
);
