import * as React from 'react';
import { OneTaskResponseParams } from 'sber-marketing-types/backend';
import { useSelector, useDispatch } from 'react-redux';

import { BudgetItemApi, TaskApi, DictionaryApi } from '@api';

import { StoreState } from '@store';
import * as workTypesStore from '@store/workTypes';
import { getUserById } from '@store/appUsers';
import { DataUpdateStrategy } from '@store/tagsEditor';
import { getLoginUser } from '@store/user';

import { DictionaryType } from '@mrm/dictionary';
import { DatesFormatter } from '@common/Utils';
import {
    InfoSection,
    InfoSectionPreloader,
    InfoSectionFieldPreloader,
} from '@common/SidebarWithTabs/common/InfoSection';
import { TagsPreviewAndEditor, VerticalPosition } from '@modules/tags/TagsPreviewAndEditor';

import * as styles from './InfoTab.scss';

const BudgetWorktype = 'Согласование ID бюджета';

interface Props {
    taskContent: OneTaskResponseParams;
    tagsEditorId: string;
    zIndex: number;
}

export function InfoTab({ taskContent, tagsEditorId, zIndex }: Props): JSX.Element {
    if (!taskContent) {
        return <Preloader />;
    }

    const { title, description, workTypeId, deadline, authorId, executorId } = taskContent;

    const workType = useSelector((state: StoreState) => workTypesStore.getWorkTypeById(state, workTypeId));
    const showBudgetEditor = workType?.name === BudgetWorktype;

    return (
        <div
            {...{
                'qa-id': 'tasksSiddebarInfoTab',
            }}
        >
            <span>
                <InfoSection qaId="tasksSiddebarInfoTabTitle" title="Название" content={title} />

                <InfoSection qaId="tasksSiddebarInfoTabDescription" title="Описание" content={description} />

                <WorkTypeSection id={workTypeId} />

                <InfoSection
                    qaId="tasksSiddebarInfoTabDeadline"
                    title="Дедлайн"
                    content={DatesFormatter.ddMonthyyyy(deadline)}
                />

                <UserSection qaId="tasksSiddebarInfoTabAuthor" userId={authorId} title="Автор" />

                <UserSection qaId="tasksSiddebarInfoTabExecutor" userId={executorId} title="Исполнитель" />
            </span>
            <TagsPreviewAndEditor
                dataUpdateStrategy={DataUpdateStrategy.Immediate}
                taskId={taskContent?.id}
                id={tagsEditorId}
                verticalPosition={VerticalPosition.Top}
                className={styles.subWidget}
                zIndex={zIndex + 5}
            />
            {showBudgetEditor && <BudgetSection task={taskContent} />}
        </div>
    );
}

interface WorkTypeSectionProps {
    id: string;
}

function WorkTypeSection({ id }: WorkTypeSectionProps): JSX.Element {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(
            workTypesStore.loadWorkTypes({
                store: workTypesStore.StoreTypes.GENERAL,
            }),
        );
    }, []);

    const workType = useSelector((state: StoreState) => workTypesStore.getWorkTypeById(state, id));

    return <InfoSection qaId="tasksSiddebarInfoTabWorkType" title="Вид работ" content={workType?.name} />;
}

interface UserSectionProps {
    qaId: string;
    userId: number;
    title: string;
}

function UserSection({ userId, title, qaId }: UserSectionProps): JSX.Element {
    const user = useSelector((state: StoreState) => getUserById(state, userId));

    return (
        <InfoSection
            qaId={qaId}
            title={title}
            content={user ? `${user.firstName} ${user.secondName}` : null}
            subContent={user?.department}
        />
    );
}

interface BudgetSectionProps {
    task: OneTaskResponseParams;
}

function BudgetSection({ task }: BudgetSectionProps): JSX.Element {
    const user = useSelector((state: StoreState) => getLoginUser(state));

    const [budgetApproval, setBudgetApproval] = React.useState(null);
    const [budgetItemSerialNumber, setBudgetItemSetialNumber] = React.useState<number>(null);
    const [division, setDivision] = React.useState(null);
    const [segment, setSegment] = React.useState(null);

    React.useEffect(() => {
        TaskApi.getTaskBudgetApproval(task.id).then((data) => {
            setBudgetApproval(data);
        });
    }, []);

    React.useEffect(() => {
        if (budgetApproval && budgetApproval.budgetItemId) {
            BudgetItemApi.getSerialNumber(budgetApproval.budgetItemId).then((data) => {
                setBudgetItemSetialNumber(data);
            });
        }
    }, [budgetApproval?.budgetItemId]);

    React.useEffect(() => {
        if (budgetApproval && budgetApproval.clientDivisionId && !division) {
            DictionaryApi.getDictionaryList({
                types: [DictionaryType.Division],
                organizationId: user.attributes.organizationId,
                ids: [budgetApproval.clientDivisionId],
            }).then((dictionary) => {
                dictionary && setDivision(dictionary[0]);
            });
        }
    }, [budgetApproval?.clientDivisionId]);

    React.useEffect(() => {
        if (budgetApproval && budgetApproval.clientDivisionId && !segment) {
            DictionaryApi.getDictionaryList({
                types: [DictionaryType.Segment],
                organizationId: user.attributes.organizationId,
                ids: [budgetApproval.segmentId],
            }).then((dictionary) => {
                dictionary && setSegment(dictionary[0]);
            });
        }
    }, [budgetApproval?.segmentId]);

    return (
        <div className={styles.budgetApproval}>
            <InfoSection
                qaId="tasksSiddebarInfoTabBudgetApproval"
                title="Заказчик"
                content={budgetApproval?.clientDivision || division?.value}
            />
            <InfoSection
                qaId="tasksSiddebarInfoTabBudgetApproval"
                title="Представитель заказчика"
                content={budgetApproval?.clientName}
            />
            {(budgetApproval?.budgetItemId || budgetApproval?.budgetItemWasSelected) && (
                <InfoSection
                    qaId="tasksSiddebarInfoTabBudgetApproval"
                    title="ID-источник"
                    content={
                        budgetApproval ? (
                            <div>
                                <a>{budgetItemSerialNumber || 'Не задан'}</a>
                            </div>
                        ) : (
                            <InfoSectionFieldPreloader />
                        )
                    }
                />
            )}
            <InfoSection qaId="tasksSiddebarInfoTabBudgetApproval" title="Продукт" content={budgetApproval?.product} />
            <InfoSection
                qaId="tasksSiddebarInfoTabBudgetApproval"
                title="Сегмент"
                content={budgetApproval?.segment || segment?.value}
            />
            <InfoSection qaId="tasksSiddebarInfoTabBudgetApproval" title="Период РК" content={budgetApproval?.period} />
            {budgetApproval?.hasLot && (
                <>
                    <InfoSection
                        qaId="tasksSiddebarInfoTabBudgetApproval"
                        title="ЛОТ креатива"
                        content={
                            budgetApproval?.mediaRequest === 'not'
                                ? 'Нет креатива по ЛОТам'
                                : `ЛОТ ${budgetApproval?.mediaRequest}`
                        }
                    />
                    {budgetApproval?.hasTitle && (
                        <>
                            <InfoSection
                                qaId="tasksSiddebarInfoTabBudgetApproval"
                                title="Название креатива"
                                content={budgetApproval?.naming}
                            />
                            <InfoSection
                                qaId="tasksSiddebarInfoTabBudgetApproval"
                                title="РК на основании"
                                content={budgetApproval?.project}
                            />
                        </>
                    )}
                </>
            )}
        </div>
    );
}

function Preloader(): JSX.Element {
    return (
        <React.Fragment>
            <InfoSectionPreloader />
            <InfoSectionPreloader />
            <InfoSectionPreloader />
            <InfoSectionPreloader />
            <InfoSectionPreloader />
        </React.Fragment>
    );
}
