import { Dictionary } from 'lodash';
import { OrganizationView } from 'sber-marketing-types/backend';

import { LoadingStatus } from '@store/commonTypes';

export interface OrganizationsState {
    entities: OrganizationView[];
    byIds: Dictionary<OrganizationView>;
    stores: Record<StoreTypes, EntitiesStore>;
    loadingStatus: LoadingStatus;
}

export const enum StoreTypes {
    GENERAL = 'GENERAL',
    MY_TASKS_FILTER = 'MY_TASKS_FILTER',
    AVAILABLE_ACTIVITIES_FILTERS = 'AVAILABLE_ACTIVITIES_FILTER',
    MY_ACTIVITIES_FILTER = 'MY_ACTIVITIES_FILTER',
    ACTIVITY_PAGE_FILTERS = 'ACTIVITY_PAGE_FILTERS',
    BUDGET_FILTERS = 'BUDGET_FILTERS',
}

export interface EntitiesStore {
    loadingStatus: LoadingStatus;
    ids: string[];
}

export interface LoadOrganizationsParams {
    store: StoreTypes;
    ids?: string[];
}

export interface SetStoreIdsParams {
    store: StoreTypes;
    ids: string[];
}

export interface SetLoadingStatusParams {
    store: StoreTypes;
    status: LoadingStatus;
}

export type LoadEntitiesPayload = OrganizationView[];
