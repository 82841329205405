import * as React from 'react';
import classNames from 'classnames';

import type { UserCard } from 'sber-marketing-types/frontend';

import { useGetUserCardsQuery, useGetWorkTypesQuery } from '@api';

import { useDefaultState } from '@common/hooks';

import { OptionProps, useFormField } from '@common/components';
import { UserWorkTypeChip } from '@modules/workType/components';
import { UserSelectorProps, WorkTypeUserOption } from '@modules/user/components';

import { UserSelector } from '../UserSelector';

import * as styles from './WorkTypeUserFormSelector.scss';

export interface WorkTypeUserSelectorProps
    extends Omit<UserSelectorProps<false>, 'renderOption' | 'selected' | 'onSelect'> {
    selected?: [string, number];
    workTypeFieldName?: string;
    userFieldName?: string;
    onSelect?: (workTypeId: string, userId: number) => void;
}

export const WorkTypeUserFormSelector: React.FC<WorkTypeUserSelectorProps> = ({
    selected,
    onSelect,
    className,
    children,
    workTypeFieldName,
    userFieldName,
    organizationIds,
    error,
    inputRef,
    required,
    search: searchProp,
    clearable: clearableProp,
    onClear,
    onSearch,
    ...props
}) => {
    const [clearable, setClearable] = useDefaultState(
        clearableProp,
        (clearable: boolean) => {
            if (clearable) {
                onClear?.();
            }
        },
        false,
    );
    const [search, setSearch] = useDefaultState(searchProp, onSearch, '');
    const usersDropdownDivRef = React.useRef<HTMLDivElement>();
    useGetUserCardsQuery({ hasWorkTypes: true });
    useGetWorkTypesQuery({ isArchived: false });

    const { errorMessage: userErrorMessage, inputRef: userInputRef } = useFormField({
        name: userFieldName,
        value: selected[1],
        inputRef,
        required,
    });

    const { errorMessage: workTypeErrorMessage } = useFormField({
        name: workTypeFieldName,
        value: selected[0],
        inputRef: userInputRef,
        required,
    });

    const handleSelect = (workTypeId: string, userId: number) => {
        onSelect?.(workTypeId, userId);
    };

    const handleClear = () => {
        onSelect?.(null, null);
        setSearch('');
        userInputRef.current?.focus();
    };
    const renderOption = ({ value, ...props }: OptionProps<UserCard>) => (
        <WorkTypeUserOption
            {...props}
            organizationId={organizationIds?.[0]}
            isArchived={false}
            onSelect={(workTypeId) => {
                handleSelect(workTypeId, value.id);
                props.onSelect?.(value);
            }}
            user={value}
            portal={usersDropdownDivRef}
            value={selected?.[0]}
        />
    );

    React.useEffect(() => {
        setClearable(Boolean(selected[0] || selected[1]));
    }, [selected]);

    return (
        <div className={classNames(styles.root, className)}>
            <UserSelector
                {...props}
                organizationIds={organizationIds}
                search={search}
                onSearch={setSearch}
                required={required}
                inputRequired={false}
                inputRef={userInputRef}
                error={error || userErrorMessage || workTypeErrorMessage}
                name={userFieldName}
                placement="bottomRight"
                optionsAfter={<div className={styles.portal} ref={usersDropdownDivRef} />}
                renderOption={renderOption}
                selected={selected?.[1]}
                clearable={clearable}
                onClear={handleClear}
                hasWorkTypes
                dropdownClassName={styles.usersPopup}
                placeholder="Имя исполнителя"
                inputContent={
                    <UserWorkTypeChip
                        organizationId={organizationIds?.[0]}
                        isArchived={false}
                        onSelect={handleSelect}
                        selected={selected}
                        searchable
                        type="soft"
                        className={styles.workTypesChip}
                        placement="bottomLeft"
                        stretch
                        dropdownClassName={styles.workTypesPopup}
                    >
                        Тип работ:
                    </UserWorkTypeChip>
                }
            />
        </div>
    );
};
