import * as React from 'react';
import * as lodash from 'lodash';
import * as moment from 'moment';

import { FactFundsCorrection, CorrectionStatus, BudgetItem, Month, Funds, Persone } from '../types';

import { FactCorrectionCard, FactData, PlanData, PlanField, FondField } from './FactCorrectionCard';

import { orderOfMonth, monthIndexMap } from '../utils';
import { Utils } from '@common/Utils';

interface FundsByMonth {
    [month: number]: number;
}

interface Props extends FactFundsCorrection {}

export class FactCorrectionCardContainer extends React.Component<Props> {
    public render(): JSX.Element {
        const { serialNumber, creationTime, status, comment, author } = this.props;

        const authorName = this.formatUserName(author);
        const approverName = this.getApproverName();
        const factData = this.makeFactData();

        return React.createElement(FactCorrectionCard, {
            serialNumber,
            authorName,
            approverName,
            creationDate: this.formatDate(creationTime),
            planData: this.makePlanData(),
            factData,
            status,
            rejectComment: comment,
        });
    }

    private getApproverName(): string {
        const { status, expert, approver } = this.props;

        if (status === CorrectionStatus.NeedApproving) {
            return expert ? this.formatUserName(expert) : 'не задан';
        }

        return approver ? this.formatUserName(approver) : 'не задан';
    }

    private get budgetItem(): BudgetItem {
        const { status, budgetItemCurrent: budgetItemsCurrent, budgetItemBefore: budgetItemsBefore } = this.props;

        return status === CorrectionStatus.NeedApproving
            ? lodash.first(budgetItemsCurrent)
            : lodash.isEmpty(lodash.first(budgetItemsBefore))
            ? lodash.first(budgetItemsCurrent)
            : lodash.first(budgetItemsBefore);
    }

    private makePlanData(): PlanData {
        const { budgetItemCurrent: budgetItemsCurrent } = this.props;

        const currentBudgetItem = lodash.first(budgetItemsCurrent);

        // TODO В ответе graphQL висит поле __typename и для перебора свойст обхекта оно мешает
        delete this.budgetItem.plannedFunds['__typename'];
        const currentSumPlannedFunds = lodash.values(this.budgetItem.plannedFunds).reduce<number>((acc, fond) => {
            return acc + fond;
        }, 0);

        const fields: PlanField[] = [
            {
                name: 'ID план',
                value: currentBudgetItem.donors && currentBudgetItem.donors.join(', '),
            },
            {
                name: 'ID исполнение',
                value: currentBudgetItem.serialNumber,
            },
            {
                name: 'Тип проекта',
                value:
                    currentBudgetItem.dictionary &&
                    currentBudgetItem.dictionary.activity_type &&
                    currentBudgetItem.dictionary.activity_type.value,
            },
            {
                name: 'Направление',
                value:
                    currentBudgetItem.dictionary &&
                    currentBudgetItem.dictionary.direction &&
                    currentBudgetItem.dictionary.direction.value,
            },
            {
                name: 'Дата запуска',
                value: currentBudgetItem.realizationStart && this.formatDate(currentBudgetItem.realizationStart),
            },
            {
                name: 'Дата окончания',
                value: currentBudgetItem.realizationEnd && this.formatDate(currentBudgetItem.realizationEnd),
            },
            {
                name: 'ЦА/ТБ (Территория)',
                value: currentBudgetItem.dictionary?.regionality
                    ? Utils.getDictionaryValue(currentBudgetItem.dictionary.regionality)
                    : '',
            },
            {
                name: 'Территория',
                value: currentBudgetItem.dictionary?.territory
                    ? Utils.getDictionaryValue(currentBudgetItem.dictionary.territory)
                    : '',
            },
            {
                name: 'Статья',
                value:
                    currentBudgetItem.dictionary &&
                    currentBudgetItem.dictionary.item &&
                    currentBudgetItem.dictionary.item.value,
            },
            {
                name: 'Блок',
                value:
                    currentBudgetItem.dictionary &&
                    currentBudgetItem.dictionary.block &&
                    currentBudgetItem.dictionary.block.value,
                isWide: true,
            },
            {
                name: 'Инструмент',
                value:
                    currentBudgetItem.dictionary &&
                    currentBudgetItem.dictionary.tool &&
                    currentBudgetItem.dictionary.tool.value,
                isWide: true,
            },
        ];

        return {
            name: currentBudgetItem.sapComment,
            currentPlan: currentSumPlannedFunds,
            fields,
            comment: currentBudgetItem.comment,
        };
    }

    private makeFactData(): FactData {
        const fields = this.buildFondFieldsForFactData();

        return {
            delta: fields.reduce((acc, field) => (field.delta !== null ? acc + field.delta : acc), 0),
            beforeSumFonds: fields.reduce((acc, field) => acc + field.before, 0),
            fields,
        };
    }

    private buildFondFieldsForFactData(): FondField[] {
        const { params } = this.props;

        const fundsByMonths = this.buildFundsByMonths(this.budgetItem.factFunds);

        const changedMonthFonds = params;

        return orderOfMonth.map((month) => {
            const haveChangeCurrentMonthFund = changedMonthFonds.hasOwnProperty(month);

            if (haveChangeCurrentMonthFund) {
                return this.buildFondField({
                    before: fundsByMonths[month],
                    after: changedMonthFonds[month],
                    month,
                });
            }

            return this.buildFondField({
                before: fundsByMonths[month],
                after: fundsByMonths[month],
                month,
            });
        });
    }

    private buildFondField({ before, after, month }: { before: number; after: number; month: Month }): FondField {
        const delta = after - before;
        return {
            monthIndex: monthIndexMap[month],
            before,
            after,
            delta,
            deltaIsPositive: delta >= 0,
        };
    }

    private buildFundsByMonths(funds: Funds = {}): FundsByMonth {
        return orderOfMonth.reduce(
            (fundsByMonth, month) => ({
                [month]: funds[month] || 0,
                ...fundsByMonth,
            }),
            {},
        );
    }

    private formatUserName(user: Persone): string {
        return `${user.firstName} ${user.secondName}`;
    }

    private formatDate(date: string | Date): string {
        return moment(date).format('DD.MM.YYYY');
    }
}
