import * as React from 'react';
import autobind from 'autobind-decorator';

import { MrmClient, CreativeRequestStatus } from '@api';
import { CreativeRequestDomain } from '@store/creative/types';

interface Data {
    status: CreativeRequestStatus;
}

export interface ChildrenProps {
    data: Data;
}

interface Props extends ExternalProps {}

interface State {
    creativeRequestStatus: CreativeRequestStatus;
}

interface ExternalProps {
    creativeRequestId: string;
    children: (props: ChildrenProps) => JSX.Element;
}

export class WithCreativeStageWidget extends React.PureComponent<Props, State> {
    private creativeRequestDomain: CreativeRequestDomain = null;

    constructor(props: Props) {
        super(props);

        this.state = {
            creativeRequestStatus: null,
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.loadCreativeRequestDomain();
        this.subscribe();

        this.setState({
            creativeRequestStatus: this.getCreativeRequestStatus(),
        });
    }

    public componentWillUnmount() {
        this.unsubscribe();
    }

    public render(): JSX.Element {
        const { creativeRequestStatus } = this.state;

        return this.props.children({
            data: {
                status: creativeRequestStatus,
            },
        });
    }

    private getCreativeRequestStatus(): CreativeRequestStatus {
        return (this.creativeRequestDomain?.model?.status as undefined as CreativeRequestStatus) || null;
    }

    private async loadCreativeRequestDomain(): Promise<void> {
        const { creativeRequestId } = this.props;
        const client = await MrmClient.getInstance();
        this.creativeRequestDomain = await client.domain.creativeRequests.getCreativeRequest({ id: creativeRequestId });
    }

    private subscribe(): void {
        this.creativeRequestDomain.events.onReloaded(this.onReloaded);
    }

    private unsubscribe(): void {
        this.creativeRequestDomain.events.offReloaded(this.onReloaded);
    }

    @autobind
    private async onReloaded(): Promise<void> {
        this.unsubscribe();
        await this.loadCreativeRequestDomain();
        this.subscribe();
        this.setState({
            creativeRequestStatus: this.getCreativeRequestStatus(),
        });
    }
}
