import * as React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { HistoryActionType } from 'sber-marketing-types/backend';

import { Colors } from '../../qaData';

import * as style from './TaskDescription.scss';

interface Props {
    action: HistoryActionType;
    activityId: number | string;
    taskId: number | string;
    taskName: string;
    wasTaskAssignedToLoginedUser: boolean;
}

export function TaskDescription({
    action,
    activityId,
    taskId,
    taskName,
    wasTaskAssignedToLoginedUser,
}: Props): JSX.Element {
    let colorClass: string;
    let qaColor: Colors;

    switch (action) {
        case HistoryActionType.UpdateTaskExecutor:
            if (wasTaskAssignedToLoginedUser) {
                colorClass = classNames([style.colorGreen, style.weightBold]);
                qaColor = Colors.Green;
            } else {
                colorClass = style.colorBlack;
                qaColor = Colors.Black;
            }

            break;
        case HistoryActionType.AddTaskParticipant:
        case HistoryActionType.TaskClosed:
        case HistoryActionType.TaskToProgress:
        case HistoryActionType.TaskReturned:
            colorClass = classNames([style.colorGreen, style.weightBold]);
            qaColor = Colors.Green;
            break;
        case HistoryActionType.DeleteTask:
            colorClass = style.colorRed;
            qaColor = Colors.Red;
            break;
        default:
            colorClass = style.colorBlack;
            qaColor = Colors.Black;
    }

    const shouldRenderLink: boolean =
        action !== HistoryActionType.DeleteTask && action !== HistoryActionType.RemoveTaskParticipant;

    const qaParams: any = {
        'qa-id': 'dashboardNewsColumnItemTashDescription',
        'qa-color': qaColor,
    };

    return (
        <div className={style.root}>
            <div className={style.caption}>Задача</div>

            <div className={style.content}>
                {shouldRenderLink ? (
                    <div {...qaParams}>
                        <Link
                            to={`/activity/${activityId}/task/${taskId}`}
                            className={classNames([style.link, colorClass])}
                        >
                            {taskName}
                        </Link>
                    </div>
                ) : (
                    <div className={classNames([style.text, colorClass])} {...qaParams}>
                        {taskName}
                    </div>
                )}
            </div>
        </div>
    );
}
