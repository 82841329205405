import { checkIfTaskStatusAvailableChange } from '@modules/task/utils';
import { useGetTaskQuery, useSetTaskMutation } from '@api';

import { TaskStatus as ApiTaskStatus } from 'sber-marketing-types/backend';

export type ChangeTaskStatus = (status: ApiTaskStatus) => void;

export function useChangeTaskStatus(
    taskId: string,
    onSuccess?: () => void,
    onError?: () => void,
): { setStatus: ChangeTaskStatus; isLoading: boolean } {
    const { isLoading: isTaskLoading, data: task } = useGetTaskQuery({ id: taskId });
    const [setTask, { isLoading: isUpdating }] = useSetTaskMutation();

    return {
        setStatus: (status) => {
            if (checkIfTaskStatusAvailableChange(status, task?.task)) {
                setTask({ id: taskId, status });
                onSuccess?.();
            } else {
                onError?.();
            }
        },
        isLoading: isTaskLoading || isUpdating,
    };
}
