import * as React from 'react';
import { Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';

import { ColumnFilters, ColumnName, CellValueType } from '@store/budgetPlanning';

import { ColumnsList } from '../../../../ColumnsConfig';
import { SortingMenu } from '../../../../SortingMenu';
import { FilterMenu } from '../../../../FilterMenu';
import { ColumnFixingMenu } from '../../../../ColumnFixingMenu';

import * as styles from './DropdownContent.scss';

interface Props {
    columnName: ColumnName;
    filters: ColumnFilters;
    columnValueType: CellValueType;
    currencySearch: boolean;
    showApplyFiltersButton: boolean;
    onFiltersChange: (filters: ColumnFilters) => void;
    onColumnFixChange: () => void;
    onApplyFiltersButtonClick: () => void;
}

export function DropdownContent({
    columnName,
    filters,
    columnValueType,
    currencySearch,
    showApplyFiltersButton,
    onFiltersChange,
    onColumnFixChange,
    onApplyFiltersButtonClick,
}: Props): JSX.Element {
    const column = ColumnsList.find((column) => column.name === columnName);
    const filterNamesAreNumeral = columnValueType == CellValueType.Number || columnValueType == CellValueType.Currency;

    return (
        <div
            className={styles.dropdownContent}
            {...{
                'qa-id': 'tableHeader',
            }}
        >
            <div className={styles.dropdownTitle}>{column?.title}</div>

            <ColumnFixingMenu columnName={columnName} onColumnFixChange={onColumnFixChange} />

            <SortingMenu columnName={columnName} />

            <FilterMenu
                columnName={columnName}
                filters={filters}
                filterNamesAreNumeral={filterNamesAreNumeral}
                currencySearch={currencySearch}
                onFiltersChange={onFiltersChange}
            />

            {showApplyFiltersButton && (
                <React.Fragment>
                    <div className={styles.divider} />

                    <div className={styles.applyFiltersButtonWrapper}>
                        <Button theme={ButtonTheme.GhostRounded} onClick={onApplyFiltersButtonClick}>
                            <div className={styles.applyFiltersButtonContent}>
                                <svg
                                    width="10"
                                    height="10"
                                    viewBox="0 0 10 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={styles.applyFilterButtonIcon}
                                >
                                    <path
                                        d="M9.07274 5.07264C9.07274 5.45675 8.84757 5.78786 8.51644 5.9733L1.48343 9.60242C1.1788 9.76136 0.79469 9.76136 0.490061 9.57593C0.185431 9.3905 0 9.05939 0 8.70176V1.03299C0 0.675383 0.19867 0.344257 0.516547 0.132339C0.675485 0.0263805 0.847671 -0.000113487 1.03309 -0.000113487C1.21852 -0.000113487 1.3907 0.0528641 1.54964 0.132339L8.58266 4.14558C8.88729 4.33101 9.07274 4.68855 9.07274 5.07264Z"
                                        fill="black"
                                    />
                                </svg>
                                Применить
                            </div>
                        </Button>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
}
