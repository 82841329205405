import * as React from 'react';
import { useParams } from 'react-router-dom';
import { head } from 'lodash';

import { useStore } from './useStore';
import { useClient } from './useClient';

interface UseBehavior {
    (): UseBehaviorReturn;
}

interface UseBehaviorReturn {
    name: string;
    number: string;
    disabled: boolean;
    onInputChange: (value: string) => void;
    onInputBlur: () => void;
}

export const useBehavior: UseBehavior = () => {
    const { creativeRequestId } = useParams<{ creativeRequestId: string }>();

    const { activityName } = useStore();
    const {
        loading,
        canEditName,
        creativeRequestName,
        creativeRequestLotName,
        creativeRequestTableItemNumber,
        setCreativeRequestName,
    } = useClient({
        creativeRequestId,
    });

    const [name, setName] = React.useState(creativeRequestName);

    React.useEffect(() => {
        if (!loading) {
            if (!creativeRequestName.length) {
                const updatedCreativeRequestName = buildName({ activityName, creativeRequestLotName });

                setCreativeRequestName(updatedCreativeRequestName);
                setName(updatedCreativeRequestName);
            } else {
                if (/(ЛОТ 1|ЛОТ 2)$/g.test(creativeRequestName)) {
                    const currentCreativeRequestLotName = head(creativeRequestName.match(/(ЛОТ 1|ЛОТ 2)$/g));

                    if (currentCreativeRequestLotName !== creativeRequestLotName) {
                        const updatedCreativeRequestName = creativeRequestName.replace(
                            /(ЛОТ 1|ЛОТ 2)$/g,
                            creativeRequestLotName,
                        );
                        setCreativeRequestName(updatedCreativeRequestName);
                        setName(updatedCreativeRequestName);
                    } else {
                        setName(creativeRequestName);
                    }
                } else {
                    setName(creativeRequestName);
                }
            }
        }
    }, [loading]);

    const onInputChange = React.useCallback(
        (value) => {
            setCreativeRequestName(value);
            setName(value);
        },
        [setName],
    );

    const onInputBlur = React.useCallback(() => {
        if (!name.length) {
            const creativeRequestName = buildName({ activityName, creativeRequestLotName });

            setCreativeRequestName(creativeRequestName);
            setName(creativeRequestName);
        }
    }, [name, activityName, setCreativeRequestName, setName]);

    return {
        name,
        number: creativeRequestTableItemNumber,
        disabled: !canEditName,
        onInputChange,
        onInputBlur,
    };
};

const buildName = ({
    activityName,
    creativeRequestLotName,
}: {
    activityName: string;
    creativeRequestLotName: string;
}): string => {
    return `${activityName} - ${creativeRequestLotName}`;
};
