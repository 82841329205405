import { CreativeRequestSubject } from './types';
import { compact } from 'lodash';

interface GetUserName {
    (params: Pick<CreativeRequestSubject, 'middleName' | 'firstName' | 'secondName'>): string;
}

export const getUserName: GetUserName = ({ middleName, firstName, secondName }) => {
    return compact([secondName, firstName, middleName]).join(' ');
};
