import { useHistory } from 'react-router-dom';
import * as qs from 'query-string';

export type SetSearch = (params: Record<string, any>) => void;

export function useSetSearch(): SetSearch {
    const history = useHistory();

    return (params: Record<string, any>) => {
        history.push(`?${qs.stringify(params)}`);
    };
}
