export const splitTextToTextsLines = (text: string, maxLineLength: number): string[] => {
    const textLines: string[] = ['', ''];
    const words = text.split(' ');

    let i = 0;

    while (i <= words.length - 1) {
        if (textLines[0].length < maxLineLength) {
            const newTextLine = `${textLines[0]} ${words[i]}`;
            if (newTextLine.length < maxLineLength) {
                textLines[0] = newTextLine;
            } else {
                break;
            }
        } else {
            break;
        }
        ++i;
    }

    while (i <= words.length - 1) {
        if (textLines[1].length < maxLineLength) {
            const newTextLine = `${textLines[1]} ${words[i]}`;
            if (newTextLine.length < maxLineLength) {
                textLines[1] = newTextLine;
            } else {
                textLines[1] = `${newTextLine.slice(0, maxLineLength)}...`;
                break;
            }
        } else {
            break;
        }
        ++i;
    }

    return textLines;
};
