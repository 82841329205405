import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { R7State as State, SetDocumentStatusPayload } from './types';

import * as actions from './actions/sync';

class Reducer {
    public static makeInitialState(): State {
        return {
            documentsStatusById: {},
        };
    }

    public static setDocumentStatus(state: State, payload: SetDocumentStatusPayload): State {
        const { documentId, status } = payload;

        const documentsStatusById = {
            ...state.documentsStatusById,
            [documentId]: status,
        };

        return { ...state, documentsStatusById };
    }
}

export const R7Reducer = reducerWithInitialState(Reducer.makeInitialState());

R7Reducer.case(actions.setDocumentStatus, Reducer.setDocumentStatus);
