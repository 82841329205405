import * as React from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, IconType } from 'sber-marketing-ui';

import { StoreState } from '@store';
import {
    InternalTransferDirection,
    getBudgetTransferMenuState,
    toggleInternalTransitionDirectrion,
    setIsInternalTransferDirrectionToggleHovered,
    canMoveAcceptorCellToDonor,
    getLinesMovebaleToDonor,
} from '@store/budgetExecution/budgetTransferMenu';

import * as styles from './TransferDirrectionToggle.scss';

function useInteractivity() {
    const dispatch = useDispatch();
    const internalTransferDirection = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.internalTransferDirection,
    );
    const isHovered = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.isTransferDirectionToggleHovered,
    );
    const isTogglable = useSelector((state: StoreState) => {
        const to = getBudgetTransferMenuState(state).cells.to;
        const linesMovableToDonor = getLinesMovebaleToDonor(state);

        return canMoveAcceptorCellToDonor(state) && to.length === linesMovableToDonor.length;
    });

    function setIsHovered(isHovered: boolean) {
        dispatch(setIsInternalTransferDirrectionToggleHovered(isHovered));
    }

    function onClick() {
        dispatch(toggleInternalTransitionDirectrion(null));
    }

    return {
        internalTransferDirection,
        isTogglable,
        onClick,
        isHovered,
        setIsHovered,
    };
}

interface Props {
    oneToManyContent: string;
    manyToOneContent: string;
    activeContent?: JSX.Element;
}

function TransferDirrectionToggle({ oneToManyContent, manyToOneContent, activeContent }: Props): JSX.Element {
    const { internalTransferDirection, isTogglable, onClick, isHovered, setIsHovered } = useInteractivity();

    let leftContent: string;
    let rightContent: string;
    switch (internalTransferDirection) {
        case InternalTransferDirection.OneToMany:
            leftContent = oneToManyContent;
            rightContent = manyToOneContent;
            break;
        case InternalTransferDirection.ManyToOne:
            leftContent = manyToOneContent;
            rightContent = oneToManyContent;
            break;
        default:
            leftContent = null;
            rightContent = null;
    }

    return (
        <div
            className={styles.root}
            onClick={isTogglable ? onClick : null}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            {...{
                'qa-id': 'budgetExecutionBudgetTransferMenuTransferDirectionToggle',
            }}
        >
            <div className={classnames(styles.title, styles.titleActive)}>
                {leftContent} {activeContent}
            </div>

            <Icon
                type={
                    isTogglable && isHovered
                        ? IconType.TOGGLE_TRANSFER_DIRRECTION_HOVERED
                        : IconType.TOGGLE_TRANSFER_DIRRECTION
                }
                svgSize={20}
                className={styles.icon}
            />

            <div className={classnames(styles.title)}>{rightContent}</div>
        </div>
    );
}

export function CellTransferDirectionToggle(): JSX.Element {
    return <TransferDirrectionToggle oneToManyContent="Ячейка-источник" manyToOneContent="Ячейка-получатель" />;
}

interface LinesTransferDirectionToggleProps {
    linesCount: number;
}

export function LinesTransferDirectionToggle({ linesCount }: LinesTransferDirectionToggleProps): JSX.Element {
    const counter = linesCount ? <div className={styles.counter}>{linesCount}</div> : null;

    return (
        <TransferDirrectionToggle
            oneToManyContent="Строки-получатели"
            manyToOneContent="Строки-источники"
            activeContent={counter}
        />
    );
}
