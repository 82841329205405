import * as React from 'react';
import * as lodash from 'lodash';
import * as moment from 'moment';

import { ActivityCorrectionCard, ChangedParam } from './ActivityCorrectionCard';
import { Persone, ActivityCorrection, CorrectionStatus } from '../types';

interface Props extends ActivityCorrection {}

export class ActivityCorrectionCardContainer extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        const { serialNumber, creationTime, status, comment, author } = this.props;

        const authorName = this.formatUserName(author);
        const approverName = this.getApproverName();

        return React.createElement(ActivityCorrectionCard, {
            serialNumber,
            authorName,
            approverName,
            activityName: this.getActivityName(),
            changedParams: this.makeChangedParams(),
            creationDate: this.formatDate(creationTime),
            status,
            rejectComment: comment,
        });
    }

    private getApproverName(): string {
        const { status, expert, approver } = this.props;

        if (status === CorrectionStatus.NeedApproving) {
            return expert ? this.formatUserName(expert) : 'не задан';
        }

        return approver ? this.formatUserName(approver) : 'не задан';
    }

    private getActivityName(): string {
        const { activityCurrent } = this.props;
        return lodash.first(activityCurrent).name;
    }

    private makeChangedParams(): ChangedParam[] {
        const { status, activityBefore, activityCurrent, params } = this.props;

        const defineActivityName =
            status === CorrectionStatus.NeedApproving
                ? lodash.first(activityCurrent).name
                : lodash.isEmpty(lodash.first(activityBefore))
                ? lodash.first(activityCurrent).name
                : lodash.first(activityBefore).name;

        return [
            {
                title: 'Активность',
                oldValue: {
                    value: defineActivityName,
                },
                newValue: {
                    value: params.name,
                },
            },
        ];
    }

    private formatUserName(user: Persone): string {
        return `${user.firstName} ${user.secondName}`;
    }

    private formatDate(date: string | Date): string {
        return moment(date).format('DD.MM.YYYY');
    }
}
