import * as React from 'react';

import * as style from './ManualRatingsSplitTable.scss';

import type { CellEvent, CellPosition, ColumnName, ColumnWidths, LineId } from './types';

import { TableViewModel } from './TableViewModel';
import { TableView } from './TableView';

interface Props {
    viewModel: TableViewModel;
    columns: ColumnName[];
    lineIds: LineId[];
    columnWidths: ColumnWidths;
    tableRef: (component: TableView) => void;
    onCellEvent: (eventType: CellEvent, position: CellPosition) => void;
    onColumnWidthsChange: (columnWidths: ColumnWidths) => void;
}

export const ManualRatingsSplitTableTemplate = ({
    viewModel,
    columns,
    lineIds,
    columnWidths,
    tableRef,
    onCellEvent,
    onColumnWidthsChange,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <TableView
                ref={tableRef}
                viewModel={viewModel}
                columns={columns}
                leftFixedColumns={[]}
                rightFixedColumns={[]}
                lines={lineIds}
                columnWidths={columnWidths}
                onCellEvent={onCellEvent}
                onColumnWidthsChange={onColumnWidthsChange}
            />
        </div>
    );
};
