import * as React from 'react';
import * as Dotdotdot from 'react-dotdotdot';
import classNames from 'classnames';
import * as lodash from 'lodash';

import * as style from './Sidebar.scss';

import type {
    OrganizationGroups,
    LinesGroup,
    GroupTitleParams,
    ActivityLineParams,
    TaskLineParams,
} from '@store/calendar/types';
import { LineType } from '@store/calendar/types';

import { Icon, IconType, WithTooltip } from 'sber-marketing-ui';

const GROUP_NAME_HEIGHT = 44;
const LINE_HEIGHT = 32;
const PADDING_BOTTOM = 6;

interface Props {
    organizationGroups: OrganizationGroups[];
    displayOrganizationNames: boolean;
    visibleLinesIds: React.ReactText[];
    onGroupTitleClick: (groupId: string) => void;
    onActivityTitleClick: (activityId: number) => void;
}

export function Sidebar({
    organizationGroups,
    displayOrganizationNames,
    visibleLinesIds,
    onGroupTitleClick,
    onActivityTitleClick,
}: Props): JSX.Element {
    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'sidebar',
            }}
        >
            {organizationGroups.map((organizationGroup) => (
                <div
                    key={organizationGroup.id}
                    {...{
                        'qa-id': 'sidebarOrganizationGroup',
                        'qa-label': organizationGroup.name,
                    }}
                >
                    {displayOrganizationNames && (
                        <div className={style.organizationGroupHeader}>
                            <div className={style.organizationGroupName}>{organizationGroup.name}</div>
                        </div>
                    )}

                    {organizationGroup.groups.map((group) => (
                        <LineGroup
                            key={group.id}
                            group={group}
                            isLastInOrganization={group == lodash.last(organizationGroup.groups)}
                            visibleLinesIds={visibleLinesIds}
                            onGroupTitleClick={onGroupTitleClick}
                            onActivityTitleClick={onActivityTitleClick}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
}

interface LineGroupProps {
    group: LinesGroup;
    isLastInOrganization: boolean;
    visibleLinesIds: React.ReactText[];
    onGroupTitleClick: (groupId: string) => void;
    onActivityTitleClick: (activityId: number) => void;
}

function LineGroup({
    group,
    isLastInOrganization,
    visibleLinesIds,
    onGroupTitleClick,
    onActivityTitleClick,
}: LineGroupProps): JSX.Element {
    let groupHeight = GROUP_NAME_HEIGHT + LINE_HEIGHT * (group.lines.length - 1);

    const hasVisibleLines = group.isExpanded && group.lines.length > 1;

    if (hasVisibleLines) {
        groupHeight += PADDING_BOTTOM;
    }

    return (
        <div
            className={classNames(
                style.activityGroup,
                hasVisibleLines && style.expanded,
                isLastInOrganization && style.lastInOrganization,
            )}
            style={{ height: groupHeight }}
        >
            {group.lines.map((line, lineIndex) => (
                <React.Fragment key={line.id}>
                    {line.type === LineType.GroupTitle && lodash.includes(visibleLinesIds, line.id) && (
                        <GroupTitleLine
                            line={line as GroupTitleParams}
                            groupIsExpanded={group.isExpanded}
                            onGroupTitleClick={onGroupTitleClick}
                        />
                    )}

                    {line.type === LineType.Activity && lodash.includes(visibleLinesIds, line.id) && (
                        <ActivityLine
                            line={line as ActivityLineParams}
                            lineIndex={lineIndex}
                            onActivityTitleClick={onActivityTitleClick}
                        />
                    )}

                    {line.type === LineType.Task && lodash.includes(visibleLinesIds, line.id) && (
                        <TaskLine line={line as TaskLineParams} lineIndex={lineIndex} />
                    )}
                </React.Fragment>
            ))}
        </div>
    );
}

interface GroupTitleLineProps {
    line: GroupTitleParams;
    groupIsExpanded: boolean;
    onGroupTitleClick: (groupId: string) => void;
}

function GroupTitleLine({ line, groupIsExpanded, onGroupTitleClick }: GroupTitleLineProps): JSX.Element {
    return (
        <div
            className={classNames(style.groupTitleLine, groupIsExpanded && style.expanded)}
            onClick={() => onGroupTitleClick(line.id)}
            {...{
                'qa-id': 'sidebarOrganizationGroupTitleLine',
                'qa-label': line.title,
            }}
        >
            <WithTooltip content={line.title}>
                <div className={style.groupTitleName}>
                    <Dotdotdot clamp={2}>{line.title}</Dotdotdot>
                </div>
            </WithTooltip>

            <div className={style.titleIcon}>
                <Icon type={groupIsExpanded ? IconType.ARROW16_UP_BLACK : IconType.ARROW16_DOWN_BLACK} />
            </div>
        </div>
    );
}

interface ActivityLineProps {
    line: ActivityLineParams;
    lineIndex: number;
    onActivityTitleClick: (activityId: number) => void;
}

function ActivityLine({ line, lineIndex, onActivityTitleClick }: ActivityLineProps): JSX.Element {
    return (
        <div
            className={classNames(
                style.activityLine,
                !line.isActive && style.inactive,
                line.hasTasks && style.hasTasks,
                line.isExpanded && style.expanded,
            )}
            style={{ top: GROUP_NAME_HEIGHT + LINE_HEIGHT * (lineIndex - 1) }}
            onClick={() => onActivityTitleClick(line.id)}
            {...{
                'qa-id': 'sidebarOrganizationGroupActivityLine',
                'qa-label': line.title,
            }}
        >
            {line.hasExpiredStages && (
                <div
                    className={style.activityIndicator}
                    {...{
                        'qa-id': 'sidebarOrganizationGroupActivityLineExpiredStagesIndicator',
                    }}
                />
            )}

            <WithTooltip spanWrapper content={line.title}>
                {line.isActive ? (
                    <a
                        className={style.activityName}
                        href={`/activity/${line.id}`}
                        target="_blank"
                        onClick={(event) => event.stopPropagation()}
                    >
                        <Dotdotdot clamp={2}>{line.title}</Dotdotdot>
                    </a>
                ) : (
                    <div className={classNames(style.activityName, style.inactive)}>
                        <Dotdotdot clamp={2}>{line.title}</Dotdotdot>
                    </div>
                )}
            </WithTooltip>

            {line.hasTasks && (
                <div
                    className={style.titleIcon}
                    {...{
                        'qa-id': 'sidebarOrganizationGroupActivityLineTasksListToggle',
                    }}
                >
                    <Icon type={line.isExpanded ? IconType.ARROW16_UP_BLACK : IconType.ARROW16_DOWN_BLACK} />
                </div>
            )}
        </div>
    );
}

interface TaskLineProps {
    line: TaskLineParams;
    lineIndex: number;
}

function TaskLine({ line, lineIndex }: TaskLineProps): JSX.Element {
    let qaStatus: string;
    if (!line.activityIsActive) {
        qaStatus = 'inactive';
    } else if (line.taskIsClosed) {
        qaStatus = 'closed';
    } else if (line.deadlineExpired) {
        qaStatus = 'expired';
    } else {
        qaStatus = 'normal';
    }

    return (
        <React.Fragment>
            {line.activityIsActive ? (
                <a
                    className={classNames(
                        style.taskLine,
                        !line.activityIsActive && style.inactive,
                        line.taskIsClosed && style.closed,
                        line.deadlineExpired && style.expired,
                    )}
                    href={`/activity/${line.activityId}/task/${line.id}`}
                    target="_blank"
                    style={{ top: GROUP_NAME_HEIGHT + LINE_HEIGHT * (lineIndex - 1) }}
                    {...{
                        'qa-id': 'sidebarOrganizationGroupTaskLine',
                        'qa-label': line.title,
                        'qa-status': qaStatus,
                    }}
                >
                    <Line title={line.title} deadlineExpired={line.deadlineExpired} />
                </a>
            ) : (
                <div
                    className={classNames(
                        style.taskLine,
                        style.inactive,
                        !line.activityIsActive && style.inactive,
                        line.deadlineExpired && style.expired,
                    )}
                    style={{ top: GROUP_NAME_HEIGHT + LINE_HEIGHT * (lineIndex - 1) }}
                    {...{
                        'qa-id': 'sidebarOrganizationGroupTaskLine',
                        'qa-label': line.title,
                        'qa-status': qaStatus,
                    }}
                >
                    <Line title={line.title} deadlineExpired={line.deadlineExpired} />
                </div>
            )}
        </React.Fragment>
    );
}

interface LineProps {
    title: string;
    deadlineExpired: boolean;
}

function Line({ title, deadlineExpired }: LineProps): JSX.Element {
    const tooltipContent = <div className={style.tooltipContent}>{title}</div>;

    return (
        <WithTooltip content={tooltipContent}>
            <React.Fragment>
                {deadlineExpired && (
                    <div className={style.taskIndicator}>
                        <Icon type={IconType.EXPIRED_TASK} svgSize={16} />
                    </div>
                )}

                <Dotdotdot clamp={2}>{title}</Dotdotdot>
            </React.Fragment>
        </WithTooltip>
    );
}
