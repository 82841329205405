import { actionCreatorFactory } from 'typescript-fsa';

import {
    ComponentState,
    TransferBudgetItemsToPlanningMenuPlanningBudgets,
    TransferBudgetItemsToPlanningMenuActivityBudgetItems,
} from '../types';

const actionCreator = actionCreatorFactory('TRANSFER_BUDGET_ITEMS_TO_PLANNING_MENU');

const setComponentState = actionCreator<ComponentState>('SET_COMPONENT_STATE');
const setActivityToTransferLines = actionCreator<string>('SET_ACTIVITY_TO_TRANSFER_LINES');
const setLinesToTransfer = actionCreator<string[]>('SET_LINES_TO_TRANSFER');
const setPlanningBudgetId = actionCreator<string>('SET_PLANNING_BUDGET_ID');
const setHoveredLineId = actionCreator<string>('SET_HOVERED_LINE_ID');
const resetComponentState = actionCreator('RESET_COMPONENT_STATE');

const setPlanningBudgets =
    actionCreator<Partial<TransferBudgetItemsToPlanningMenuPlanningBudgets>>('SET_PLANNING_BUDGETS');
const setActivityBudgetItems =
    actionCreator<Partial<TransferBudgetItemsToPlanningMenuActivityBudgetItems>>('SET_ACTIVITY_BUDGET_ITEMS');

export {
    setComponentState,
    setActivityToTransferLines,
    setLinesToTransfer,
    setPlanningBudgetId,
    setHoveredLineId,
    resetComponentState,
    setPlanningBudgets,
    setActivityBudgetItems,
};
