import * as React from 'react';
import { DataProps, graphql } from 'react-apollo';
import autobind from 'autobind-decorator';
import { get } from 'lodash';
import { GET_CARDS_QUERY } from './query';

import {
    UserRoleFilter,
    UserRole,
    CardsLoadingModeFilter,
    CardType,
    CardsLoadingMode,
} from '@store/dashboardPage/types';
import { Card } from '../types';

export interface ChildrenProps {
    cards: Card[];
    loading: boolean;
    totalCardsCount: number;
    fetchMore: () => Promise<void>;
    update: () => Promise<void>;
}

const LIMIT = 10;
const START_LIMIT = 30;

interface Props extends QueryProps, ExternalProps {}

interface ExternalProps {
    userId: number;
    userRoleFilter: UserRoleFilter;
    cardsLoadingModeFilter: CardsLoadingModeFilter;
    scrollPageToTop: () => void;
    children?: (props: ChildrenProps) => JSX.Element;
}

type QueryProps = DataProps<{
    cards: {
        count: number;
        nodes: Card[];
    };
}>;

interface State {
    isRequestInProgress: boolean;
}

class WithCardsByFiltersWrapper extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isRequestInProgress: false,
        };
    }

    public componentDidUpdate(prevProps: Props) {
        const userRoleFilterChanged = this.props.userRoleFilter !== prevProps.userRoleFilter;
        const cardsLoadingModeFilterChanged = this.props.cardsLoadingModeFilter !== prevProps.cardsLoadingModeFilter;

        if (userRoleFilterChanged || cardsLoadingModeFilterChanged) {
            this.update();
        }
    }

    public render() {
        return this.props.children(this.getChildrenProps());
    }

    private getChildrenProps(): ChildrenProps {
        const cards: Card[] = get(this.props, 'data.cards.nodes') || [];
        const totalCardsCount = get(this.props, 'data.cards.count') || 0;

        const loading = this.props.data.loading || this.state.isRequestInProgress;

        return {
            cards,
            loading,
            totalCardsCount,
            fetchMore: this.fetchMore,
            update: this.update,
        };
    }

    @autobind
    private async update(): Promise<void> {
        // this.props.scrollPageToTop();

        await this.refetch(START_LIMIT);
    }

    @autobind
    private async fetchMore(): Promise<any> {
        const totalCardsCount = get(this.props, 'data.cards.count') || 0;
        const fetchedCorrectionsCount = get(this.props, 'data.cards.nodes.length') || 0;

        if (totalCardsCount !== fetchedCorrectionsCount) {
            await this.refetch(fetchedCorrectionsCount + LIMIT);
        }
    }

    @autobind
    private async refetch(limit: number): Promise<void> {
        return new Promise<void>((resolve) =>
            this.setState(
                {
                    isRequestInProgress: true,
                },
                () => resolve(),
            ),
        ).then(async () => {
            const { userId, userRoleFilter, cardsLoadingModeFilter } = this.props;

            await this.props.data.refetch({
                filter: makeGraphqlFilters(userId, userRoleFilter, cardsLoadingModeFilter),
                limit,
            });

            return new Promise<void>((resolve) =>
                this.setState(
                    {
                        isRequestInProgress: false,
                    },
                    () => resolve(),
                ),
            );
        });
    }
}

export const WithCardsByFilters = graphql<ExternalProps>(GET_CARDS_QUERY, {
    options: ({ userId, userRoleFilter, cardsLoadingModeFilter }) => ({
        variables: {
            filter: makeGraphqlFilters(userId, userRoleFilter, cardsLoadingModeFilter),
            limit: START_LIMIT,
        },
        fetchPolicy: 'no-cache',
    }),
})(WithCardsByFiltersWrapper);

function makeGraphqlFilters(
    userId: number,
    userRoleFilter: UserRoleFilter,
    cardsLoadingModeFilter: CardsLoadingModeFilter,
) {
    const userRoleGraphqlFilter = makeUserRoleGraphqlFilter(userId, userRoleFilter);
    const cardsLoadingModeGraphqlFilter = makeCardsLoadingModeGraphqlFilter(cardsLoadingModeFilter);

    return {
        ...userRoleGraphqlFilter,
        ...cardsLoadingModeGraphqlFilter,
        type: 'linkBudgetItemToProject',
    };
}

function makeUserRoleGraphqlFilter(userId: number, userRoleFilter: UserRoleFilter): {} {
    const filter = {};

    if (userRoleFilter[UserRole.Author]) {
        filter['author'] = { id: userId.toString() };
    }

    if (userRoleFilter[UserRole.Participant]) {
        filter['project'] = { participants: [{ id: userId.toString() }] };
    }

    if (userRoleFilter[UserRole.Responsible]) {
        if (!filter['project']) {
            filter['project'] = {};
        }

        filter['project']['responsible'] = { id: userId.toString() };
    }

    if (userRoleFilter[UserRole.Supervisor]) {
    }

    return filter;
}

function makeCardsLoadingModeGraphqlFilter(cardsLoadingModeFilter: CardsLoadingModeFilter): any {
    const filter: any = {};

    const cardsLoadingMode = cardsLoadingModeFilter[CardType.BudgetItemToProjectLinkRequest];
    filter.status = getStatusByLoadingMode(cardsLoadingMode);

    return filter;
}

export function getStatusByLoadingMode(cardsLoadingMode: CardsLoadingMode): string[] {
    switch (cardsLoadingMode) {
        case CardsLoadingMode.Current:
            return ['pending'];

        case CardsLoadingMode.Archive:
            return ['approved', 'rejected', 'deleted'];
    }
}
