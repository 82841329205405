import { TagProject, TagTask, TagExecutionBudgetItem, TagPlanBudgetItem } from '@api';

import { SelectedTagData } from './types';

type Key = string | number;
function toKeyValPair<Item extends { id: Key }>(items: Item[], shift: number): [Key, Item][] {
    return items.map((item) => [item.id, item]);
}

export function makeSelectedTagData(
    projects: TagProject[],
    tasks: TagTask[],
    executionBudgetItems: TagExecutionBudgetItem[],
    planBudgetItems: TagPlanBudgetItem[],
): SelectedTagData {
    return {
        projects: Object.fromEntries(toKeyValPair(projects, 1)),
        tasks: Object.fromEntries(toKeyValPair(tasks, 2)),
        executionBudgetItems: Object.fromEntries(toKeyValPair(executionBudgetItems, 0)),
        planBudgetItems: Object.fromEntries(toKeyValPair(planBudgetItems, -1)),
    };
}
