import * as React from 'react';
import classNames from 'classnames';

import { Money, MoneyFormatter } from '@common/Utils';

import * as style from './Tooltip.scss';

export const enum TooltipDirection {
    Up = 'up',
    Down = 'down',
}

interface Props {
    activityType: string;
    activityName: string;
    product: string;
    segment: string;
    directions: string[];
    budgetsByTools: { name: string; value: number }[];
    activityStart: string;
    activityEnd: string;
    direction: TooltipDirection;
}

export const Tooltip = ({
    activityType,
    activityName,
    product,
    segment,
    directions,
    budgetsByTools,
    activityStart,
    activityEnd,
    direction,
}: Props): JSX.Element => {
    return (
        <div
            className={classNames(
                style.root,
                direction == TooltipDirection.Up && style.directedUp,
                direction == TooltipDirection.Down && style.directedDown,
            )}
            {...{
                'qa-id': 'budgetPlanningLineInfoTooptip',
            }}
        >
            <div
                className={classNames(style.text, style.activityName)}
                {...{
                    'qa-id': 'budgetPlanningLineInfoTooptipActivityName',
                }}
            >
                <b>{activityName}</b>
            </div>

            <div
                className={style.text}
                {...{
                    'qa-id': 'budgetPlanningLineInfoTooptipActivityType',
                }}
            >
                Тип проекта: {activityType || '–'}
            </div>

            <div
                className={style.text}
                {...{
                    'qa-id': 'budgetPlanningLineInfoTooptipProduct',
                }}
            >
                Продукт: {product || '–'}
            </div>

            <div
                className={style.text}
                {...{
                    'qa-id': 'budgetPlanningLineInfoTooptipSegment',
                }}
            >
                Сегмент: {segment || '–'}
            </div>

            <div
                className={classNames(style.text)}
                {...{
                    'qa-id': 'budgetPlanningLineInfoTooptipDirections',
                }}
            >
                <div className={style.directions}>Направления:</div>

                {directions.map((item, index) => (
                    <div className={style.directionItem} key={index}>
                        {item || '–'}
                    </div>
                ))}
            </div>

            {budgetsByTools.length > 0 && (
                <div
                    className={classNames(style.text, style.toolBudget)}
                    {...{
                        'qa-id': 'budgetPlanningLineInfoTooptipBudgetsByTools',
                    }}
                >
                    <div>Бюджеты по инструментам:</div>

                    {budgetsByTools.map((item) => (
                        <div className={classNames(style.text, style.toolBudget)} key={item.name}>
                            {item.name} - <b>{MoneyFormatter.toThousands(Money.fromCopecks(item.value))}</b>
                        </div>
                    ))}
                </div>
            )}

            {(activityStart || activityEnd) && (
                <div
                    className={classNames(style.dates, style.text)}
                    {...{
                        'qa-id': 'budgetPlanningLineInfoTooptipActivityStartEnd',
                    }}
                >
                    <b>
                        {activityStart || '–'} - {activityEnd || '–'}
                    </b>
                </div>
            )}
        </div>
    );
};
