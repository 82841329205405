import { first, last } from 'lodash';
import { parse, format } from 'url';
import { axios } from '../../lib/axios';

import { FileUploadParams, FileDownloadLinkParams } from 'sber-marketing-types/backend';
import { UploadImageResponse, FileApiUploadParams, ImageData, ImageUrls } from './types';
import { FileService } from './FileService';
import { FileAsset } from '../../store/commonTypes';

const BASE_URL = '/api/image';

const DEFAULT_PREVIEW_SIZE = 160;

const IMAGE_TYPES = new Set(['gif', 'png', 'jpg', 'jpeg', 'jfif', 'jpe']);

export class ImageService extends FileService {
    public static isImage(fileName: string): boolean {
        const type = last(fileName.split('.'));
        return ImageService.isImageType(type);
    }

    public static isImageType(type: string): boolean {
        return IMAGE_TYPES.has(type);
    }

    public static getUrlsByIdAndType(id: string, type: string): ImageUrls {
        const { protocol, host } = parse(window.location.href);
        const prefix = first(id);
        const original = format({
            protocol,
            host,
            pathname: `${BASE_URL}/i/${prefix}/${id}/uploaded.${type}`,
        });
        const preview = format({
            protocol,
            host,
            pathname: `${BASE_URL}/i/${prefix}/${id}/${DEFAULT_PREVIEW_SIZE}.${type}`,
        });
        return { original, preview };
    }

    public async getDownloadLink(params: FileApiUploadParams, settings: FileDownloadLinkParams): Promise<string> {
        const type = last(settings.originName.split('.'));
        const { original } = ImageService.getUrlsByIdAndType(settings.fileName, type);
        return original;
    }

    public async makeSharedLink(params: FileApiUploadParams, settings: FileDownloadLinkParams): Promise<string> {
        return this.getDownloadLink(params, settings);
    }

    public async upload(params: FileApiUploadParams, fileAsset: FileAsset, settings: FileUploadParams): Promise<void> {
        const { id } = await this.uploadImage(fileAsset.file, fileAsset.type);
        const { preview, original } = ImageService.getUrlsByIdAndType(id, fileAsset.type);

        fileAsset.name = id;
        fileAsset.fullSizeUrl = original;
        fileAsset.previewUrl = preview;
    }

    public async *uploadProcess(
        params: FileApiUploadParams,
        fileAsset: FileAsset,
        settings: FileUploadParams,
    ): AsyncIterableIterator<FileAsset> {
        fileAsset.isLoading = true;
        fileAsset.loadingProgress = 0;
        yield fileAsset;
        await this.upload(params, fileAsset, settings);
        fileAsset.loadingProgress = 100;
    }

    protected async uploadImage(file: File, type?: string): Promise<ImageData> {
        const mime: string = this.getMimeType(type);
        const {
            data: { image },
        } = await axios.post<UploadImageResponse>(`${BASE_URL}/image`, file, {
            headers: {
                'Content-Type': mime,
            },
            params: {
                defaultPreviews: `${DEFAULT_PREVIEW_SIZE}x${DEFAULT_PREVIEW_SIZE}`,
            },
        });
        return image;
    }

    protected getMimeType(type?: string): string {
        switch (type) {
            case 'gif':
                return 'image/gif';
            case 'png':
                return 'image/png';
            case 'jpg':
            case 'jpeg':
            case 'jfif':
            case 'jpe':
                return 'image/jpeg';
            default:
                return 'application/octet-stream';
        }
    }

    protected async multipartUpload(
        params: FileApiUploadParams,
        chunks: Blob[],
        settings: FileUploadParams,
    ): Promise<void> {
        return undefined;
    }

    protected async *multipartUploadProcess(
        params: FileApiUploadParams,
        chunks: Blob[],
        settings: FileUploadParams,
    ): AsyncIterableIterator<number> {
        yield 0;
        yield 100;
    }

    protected async whollyUpload(params: FileApiUploadParams, file: File, settings: FileUploadParams): Promise<void> {
        return undefined;
    }
}
