import * as React from 'react';
import classnames from 'classnames';

import { CreativeRequestItemComment as CreativeRequestItemCommentDTO } from '@api';

import { CreativeRequestComment } from '../CreativeRequestComment';
import { ColumnMarker } from '../../ColumnMarker';

import * as styles from './CreativeRequestItemComment.scss';

import * as commonStyles from '../../../../CommonStyles.scss';

interface Props {
    comment: CreativeRequestItemCommentDTO;
    chilrenCommentsCount: number;
    activeCreativeRequestItemId: string;
    activeCreativeRequestColumn: string;
    addReplyButton: boolean;
    refetchComments: () => Promise<void>;
    onReplyButtonClick: () => void;
}

export function CreativeRequestItemComment({
    comment,
    chilrenCommentsCount,
    activeCreativeRequestItemId,
    activeCreativeRequestColumn,
    addReplyButton,
    refetchComments,
    onReplyButtonClick,
}: Props): JSX.Element {
    const { author, text, files, column, isRead, isFavorite, createdAt } = comment.model;

    React.useEffect(function initCreativeRequestItemCommentEventListeners() {
        comment.events.onIsFavoriteUpdated(refetchComments);
        comment.events.onIsReadUpdated(refetchComments);

        return function resetCreativeRequestItemCommentEventListeners() {
            comment.events.offIsFavoriteUpdated(refetchComments);
            comment.events.offIsReadUpdated(refetchComments);
        };
    }, []);

    return (
        <div
            className={classnames(
                styles.root,
                (isFavorite || !isRead) && styles.rootWithFavoriteMarker,
                column === activeCreativeRequestColumn && styles.rootWithActiveColumn,
            )}
            onMouseEnter={() => {
                if (!isRead) {
                    comment.model.setRead();
                }
            }}
        >
            <CreativeRequestComment
                isFavorite={comment.model.isFavorite}
                addFavoriteButton
                addReplyButton={addReplyButton}
                author={author}
                text={text}
                files={files}
                createdAt={createdAt}
                childrenCommentsCount={chilrenCommentsCount}
                onReplyButtonClick={onReplyButtonClick}
                onFavoriteButtonClick={() => {
                    comment.model.setFavorite({ isFavorite: !isFavorite });
                }}
            />

            <div className={classnames(styles.columnMarker, commonStyles.sidePaddings)}>
                <ColumnMarker creativeRequestItemId={activeCreativeRequestItemId} column={column} />
            </div>

            {/* <ReactionForm withoutAnimation className={styles.reactionForm} formIsActive={isHovered}>
                <ReactionFormItem
                    onClick={() => {
                        comment.model.setFavorite({ isFavorite: !isFavorite });
                    }}
                >
                    <Icon
                        type={
                            isFavorite
                                ? IconType.CREATIVE_REQUEST_REACTION_FORM_FAVORITE_BUTTON_ACTIVE
                                : IconType.CREATIVE_REQUEST_REACTION_FORM_FAVORITE_BUTTON_UNCTIVE
                        }
                        className={isFavorite ? styles.favoriteIconActive : styles.icon}
                        svgSize={24}
                    />
                </ReactionFormItem>

                <ReactionFormItem onClick={onChildrenCommentsButtonClick}>
                    <Icon
                        type={IconType.CREATIVE_REQUEST_REACTION_FORM_REPLY_BUTTON}
                        svgSize={24}
                        className={styles.icon}
                    />
                </ReactionFormItem>
            </ReactionForm> */}
        </div>
    );
}
