import * as React from 'react';

import * as style from './TopMenu.scss';

import { WeekSelection } from './WeekSelection';

interface Props {
    columnsTitles: { dayName: string; dayNumber: string }[];
    showExpiredStagesIndicator: boolean;
}

export const TopMenu = ({ columnsTitles, showExpiredStagesIndicator }: Props): JSX.Element => {
    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'topMenu',
            }}
        >
            <div className={style.topLine}>
                {showExpiredStagesIndicator && (
                    <div
                        className={style.expiredStagesIndicator}
                        {...{
                            'qa-id': 'topMenuExpiredStages',
                        }}
                    >
                        <div className={style.expiredStagesTitle}>Просрочены этапы</div>

                        <div className={style.expiredStagesIcon}>
                            <img srcSet="/static/images/flame16@2x.png 2x" src="/static/images/flame16.png" />
                        </div>
                    </div>
                )}

                <WeekSelection />
            </div>

            <div
                className={style.bottomLine}
                {...{
                    'qa-id': 'topLineDatesColumns',
                }}
            >
                {columnsTitles.map((item) => (
                    <div
                        className={style.column}
                        key={item.dayNumber}
                        {...{
                            'qa-id': 'topLineDatesColumn',
                            'qa-label': `${item.dayNumber} ${item.dayName}`,
                        }}
                    >
                        <div className={style.dayNumber}>{item.dayNumber}</div>

                        <div className={style.dayName}>{item.dayName}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};
