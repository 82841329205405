import * as React from 'react';
import { Skeleton } from 'sber-marketing-ui';

import * as styles from './LinkedBudgetItems.scss';

import { LinkProps } from './LinkedBudgetItemsConnected';

import { HeaderBody, HeaderTitle } from '../common';
import { BudgetItemLink } from './BudgetItemLink';

interface Props {
    links: LinkProps[];
    loading: boolean;
}

export function LinkedBudgetItemsTemplate({ links, loading }: Props): JSX.Element {
    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'dashboardRightSidebarActivityWidgetLinkedBudgetItems',
            }}
        >
            {!loading && (
                <>
                    <HeaderBody>
                        <HeaderTitle>Привязанные к проекту ID</HeaderTitle>
                    </HeaderBody>

                    <div className={styles.budgetItemsIdsList}>
                        {links.length ? (
                            links.map((link) => <BudgetItemLink {...link} key={link.id} />)
                        ) : (
                            <div className={styles.noItemsMessage}>Нет других ID</div>
                        )}
                    </div>
                </>
            )}

            {loading && <LinkedBudgetItemsPreloader />}
        </div>
    );
}

function LinkedBudgetItemsPreloader(): JSX.Element {
    return (
        <>
            <Skeleton customStyle={{ height: 16, width: '14%', borderRadius: 2, marginBottom: 4 }} />
            <Skeleton customStyle={{ height: 18, width: '40%', borderRadius: 2 }} />
        </>
    );
}
