import * as React from 'react';
import { useSelector } from 'react-redux';
import { Skeleton } from 'sber-marketing-ui';

import { MenuItemDropdown } from './MenuItemDropdown';
import { getAutopilotLoading } from '@store/activityPage/selectors';

interface Props {
    activityId: number;
}

export const AutopilotLink = ({ activityId }: Props): JSX.Element => {
    const preloader = useSelector(getAutopilotLoading);

    if (preloader) {
        return <Skeleton customStyle={{ height: 20, width: 120, borderRadius: 2 }} />;
    }

    return <MenuItemDropdown activityId={activityId} />;
};
