import * as React from 'react';
import classnames from 'classnames';
import { Icon, IconType, CustomScrollbar } from 'sber-marketing-ui';

import * as styles from './SidebarWithTabs.scss';

const TAB_ICON_DEFAULT_ICON_SIZE = 24;

export interface SidebarTabRendererProps {
    tab: SidebarTab;
    tabIsActive: boolean;
    onActiveTabChange: (tabId: string) => void;
}
export type SidebarTabRenderer = (props: SidebarTabRendererProps) => JSX.Element;

export interface SidebarTab {
    qaId: string;
    id: string;
    title: string;
    icon: IconType;
    iconSize?: number;
    renderSidebarTab?: SidebarTabRenderer;
}

interface Props {
    qaId: string;
    header?: JSX.Element;
    width?: number;
    top?: number;
    zIndex?: number;
    tabs: SidebarTab[];
    activeTab: string;
    onActiveTabChange(tabId: string): void;
    renderTab(tabId: string): JSX.Element;
}

export interface ISidebarContext {
    contentRef: React.RefObject<HTMLDivElement>;
    scrollToBotom: () => void;
    scrollToTop: () => void;
    scrollTo: (top: number) => void;
    getCurrentScroll: () => number;
}

export const SidebarContext = React.createContext<ISidebarContext>({
    contentRef: null,
    scrollToBotom: null,
    scrollToTop: null,
    scrollTo: null,
    getCurrentScroll: null,
});

export function SidebarWithTabs({
    qaId,
    header,
    tabs,
    activeTab,
    width,
    top = 49,
    zIndex,
    onActiveTabChange,
    renderTab,
}: Props): JSX.Element {
    function getCurrentScroll(): number {
        return scrollbarRef?.current?.scrollTop;
    }

    function scrollToBotom() {
        if (scrollbarRef.current) {
            const top = scrollbarRef.current.clientHeight + scrollbarRef.current.scrollHeight;

            scrollbarRef.current.scrollTo({ top });
        }
    }

    function scrollToTop() {
        if (scrollbarRef.current) {
            scrollbarRef.current.scrollTo({ top: 0 });
        }
    }

    function scrollTo(top: number) {
        if (scrollbarRef.current) {
            scrollbarRef.current.scrollTo({ top });
        }
    }

    const activeTabDesc = tabs.find((tab) => tab.id === activeTab);
    if (!activeTabDesc) {
        console.warn(`Missing tab with id ${activeTab}`);
    }

    const scrollbarRef = React.useRef<HTMLDivElement>();
    const contentRef = React.useRef<HTMLDivElement>();

    return (
        <SidebarContext.Provider value={{ contentRef, scrollToBotom, scrollTo, getCurrentScroll, scrollToTop }}>
            <div
                className={styles.root}
                style={{
                    zIndex,
                    top,
                }}
                {...{
                    'qa-id': qaId,
                }}
            >
                {header}

                <div className={styles.sidePaddings}>
                    <Tabs tabs={tabs} activeTab={activeTab} onActiveTabChange={onActiveTabChange} />
                </div>

                <div className={styles.horizontalBar} />

                <div ref={contentRef} className={styles.tabContentWrapper}>
                    <div className={styles.tabContent}>
                        <CustomScrollbar
                            scrollRef={(ref) => (scrollbarRef.current = ref as any)}
                            hideHorizontalScrollbar
                            hideTracksWhenNotNeeded
                        >
                            <div className={styles.tabContentPadding} />

                            <TabContent tab={activeTabDesc} renderTab={renderTab} />
                        </CustomScrollbar>
                    </div>
                </div>
            </div>
        </SidebarContext.Provider>
    );
}

type TabsProps = Pick<Props, 'tabs' | 'activeTab' | 'onActiveTabChange'>;

function Tabs({ tabs, activeTab, onActiveTabChange }: TabsProps): JSX.Element {
    return (
        <div className={styles.tabs}>
            {tabs.map((tab) => {
                const tabIsActive = activeTab === tab.id;

                const tabRenderer = tab.renderSidebarTab || DefaultSidebarTabRenderer;

                return (
                    <div
                        key={tab.id}
                        className={classnames(styles.tab, tabIsActive && styles.tabActive)}
                        onClick={() => {
                            onActiveTabChange(tab.id);
                        }}
                        {...{
                            'qa-id': tab.qaId,
                        }}
                    >
                        {tabRenderer({ tab, tabIsActive, onActiveTabChange })}
                    </div>
                );
            })}
        </div>
    );
}

export const DefaultSidebarTabRenderer: SidebarTabRenderer = ({ tab, tabIsActive }) => {
    return (
        <React.Fragment>
            <Icon type={tab.icon} svgSize={tab.iconSize || TAB_ICON_DEFAULT_ICON_SIZE} className={styles.tabIcon} />
            {tab.title}
        </React.Fragment>
    );
};

interface TabContentProps {
    tab: SidebarTab;
    renderTab: (tabId: string) => JSX.Element;
}

function TabContent({ tab, renderTab }: TabContentProps): JSX.Element {
    const content = renderTab(tab.id);

    if (!content) {
        return null;
    }

    return <div className={styles.sidePaddings}>{content}</div>;
}
