import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { UserConfigType } from 'sber-marketing-types/openid';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import {
    getLoadingStatus as getUserConfigLoadingStatus,
    mergeMyActivitiesPageUserConfig,
} from '@store/userConfig/dashboard';
import * as activityTypesStore from '@store/activityTypes';
import * as divisionsStore from '@store/divisions';
import * as organizationsStore from '@store/organizations';
import * as productsStore from '@store/products';
import * as usersStore from '@store/users';
import * as departmentsStore from '@store/departments';
import * as userConfigStore from '@store/userConfig';
import { setRawMyActivitiesFilter } from '@store/userConfig/dashboard/actions';

import { MapProps, DispatchProps, ActivityListFiltersContainer } from './ActivityListFiltersContainer';

const activityTypesEntities = activityTypesStore.StoreTypes.MY_ACTIVITIES_FILTER;
const divisionsEntities = divisionsStore.StoreTypes.MY_ACTIVITIES_FILTER;
const organizationsEntities = organizationsStore.StoreTypes.MY_ACTIVITIES_FILTER;
const productsEntities = productsStore.StoreTypes.MY_ACTIVITIES_FILTER;
const responsiblesEntities = usersStore.StoreTypes.MY_ACTIVITIES_RESPONSIBLES_FILTER;
const authorsEntities = usersStore.StoreTypes.MY_ACTIVITIES_AUTHORS_FITLER;
const departmentsEntities = departmentsStore.StoreTypes.MY_ACTIVITIES_RESPONSIBLE_FILTERS;

const loadFiltersParams = {
    authorOrResponsible: true,
};

function mapStateToProps(state: StoreState): MapProps {
    const filters = userConfigStore.getRawMyActivitiesPageFilters(state);

    const userConfigLoadingStatus = getUserConfigLoadingStatus(state);
    const activityTypesLoadingStatus = activityTypesStore.getLoadingStatus(state, activityTypesEntities);
    const divisionsLoadingStatus = divisionsStore.getLoadingStatus(state, divisionsEntities);
    const organizationsLoadingStatus = organizationsStore.getLoadingStatus(state, organizationsEntities);
    const productsLoadingStatus = productsStore.getLoadingStatus(state, productsEntities);
    const responsiblesLoadingStatus = usersStore.getLoadingStatus(state, responsiblesEntities);
    const authorsLoadingStatus = usersStore.getLoadingStatus(state, authorsEntities);
    const departmentsLoadingStatus = departmentsStore.getLoadingStatus(state, departmentsEntities);

    const shouldMergeUserConfig =
        userConfigLoadingStatus === LoadingStatus.LOADED &&
        activityTypesLoadingStatus === LoadingStatus.LOADED &&
        divisionsLoadingStatus === LoadingStatus.LOADED &&
        organizationsLoadingStatus === LoadingStatus.LOADED &&
        responsiblesLoadingStatus === LoadingStatus.LOADED &&
        authorsLoadingStatus === LoadingStatus.LOADED &&
        productsLoadingStatus === LoadingStatus.LOADED &&
        departmentsLoadingStatus === LoadingStatus.LOADED;

    const isLoading =
        userConfigLoadingStatus !== LoadingStatus.LOADED ||
        activityTypesLoadingStatus !== LoadingStatus.LOADED ||
        divisionsLoadingStatus !== LoadingStatus.LOADED ||
        organizationsLoadingStatus !== LoadingStatus.LOADED ||
        responsiblesLoadingStatus !== LoadingStatus.LOADED ||
        authorsLoadingStatus !== LoadingStatus.LOADED ||
        productsLoadingStatus !== LoadingStatus.LOADED ||
        departmentsLoadingStatus !== LoadingStatus.LOADED;

    const activityTypes = activityTypesStore.getActivityTypes(state, activityTypesEntities);
    const products = productsStore.getProducts(state, productsEntities);
    const responsibles = usersStore.getUsers(state, responsiblesEntities);
    const authors = usersStore.getUsers(state, authorsEntities);
    const responsibleDepartments = departmentsStore.getDepartments(state, departmentsEntities);

    return {
        isLoading,
        shouldMergeUserConfig,
        filters,
        activityTypes,
        products,
        authors,
        responsibles,
        responsibleDepartments,
        loadFiltersParams,
    };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
    return {
        setFilters: (filters) => dispatch(setRawMyActivitiesFilter(filters)),
        save: (filters) => {
            dispatch(
                userConfigStore.saveUserConfig({
                    type: UserConfigType.Dashboard,
                    payload: {
                        myActivitiesFilters: filters,
                    },
                }),
            );
        },
        loadEntitiesForFilters: (filters) => {
            const { activityTypes, divisions, products, responsibles, responsibleDepartments, authors } = filters;

            dispatch(
                activityTypesStore.loadActivityTypes({
                    store: activityTypesEntities,
                    ids: activityTypes,
                }),
            );

            dispatch(
                divisionsStore.loadDivisions({
                    store: divisionsEntities,
                    ids: divisions,
                }),
            );

            dispatch(
                organizationsStore.loadOrganizations({
                    store: organizationsEntities,
                }),
            );

            dispatch(
                productsStore.loadProducts({
                    store: productsEntities,
                    ids: products,
                }),
            );

            dispatch(
                usersStore.loadUsers({
                    store: responsiblesEntities,
                    ids: responsibles,
                }),
            );

            dispatch(
                usersStore.loadUsers({
                    store: authorsEntities,
                    ids: authors,
                }),
            );

            dispatch(
                departmentsStore.loadDepartments({
                    store: departmentsEntities,
                    ids: responsibleDepartments,
                }),
            );
        },
        resetEntitiesStores: () => {
            dispatch(activityTypesStore.resetStore(activityTypesEntities));
            dispatch(divisionsStore.resetStore(divisionsEntities));
            dispatch(productsStore.resetStore(productsEntities));
            dispatch(usersStore.resetStore(responsiblesEntities));
            dispatch(usersStore.resetStore(authorsEntities));
            dispatch(departmentsStore.resetStore(departmentsEntities));
        },
        mergeUserConfig: () => dispatch(mergeMyActivitiesPageUserConfig(null)),
    };
}

export const MyActivitiesListFilter = connect(mapStateToProps, mapDispatchToProps)(ActivityListFiltersContainer);
