// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-layout-Label-_Label__root{color:var(--color-primary-45);font-weight:600}.src-client-modules-common-components-layout-Label-_Label__required:after{content:'\\a0*';color:var(--color-red-50)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/layout/Label/Label.scss"],"names":[],"mappings":"AAAA,gEACE,6BAA8B,CAC9B,eAAgB,CACjB,0EAIG,cAAe,CACf,yBAA0B","sourcesContent":[".root {\n  color: var(--color-primary-45);\n  font-weight: 600;\n}\n\n.required {\n  &:after {\n    content: '\\a0*';\n    color: var(--color-red-50);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-components-layout-Label-_Label__root",
	"required": "src-client-modules-common-components-layout-Label-_Label__required"
};
export default ___CSS_LOADER_EXPORT___;
