import * as React from 'react';
import autobind from 'autobind-decorator';

import type { Permissions } from './types';
import { ParamFormTemplate, Fields, OnFieldChangeParams } from './ParamFormTemplate';
import type { FieldType } from './ParamFormTemplate';

interface Props {
    loading: boolean;
    selectedItemIds: Record<FieldType, string>;
    fieldItems: Record<
        FieldType,
        {
            id: string;
            name: string;
            code: string;
        }[]
    >;
    permissions: Permissions;
    visibilityRemoveButton: boolean;
    onFieldItemSelect: OnFieldItemSelect;
    onRemove: () => void;
}

interface OnFieldItemSelect {
    (params: OnFieldItemSelectParams): void;
}

interface OnFieldItemSelectParams {
    type: FieldType;
    selectedItemId: string;
}

interface Field {
    type: FieldType;
    selectedItemId: string;
}

export class ParamFormBehavior extends React.Component<Props> {
    public render(): JSX.Element {
        const { loading, visibilityRemoveButton, permissions } = this.props;

        return !loading
            ? React.createElement(ParamFormTemplate, {
                  fields: this.getFields(),
                  visibilityRemoveButton: permissions.canRemove && visibilityRemoveButton,
                  onFieldChange: this.onFieldChange,
                  onRemoveButtonClick: this.onRemoveButtonClick,
              })
            : null;
    }

    @autobind
    private onFieldChange({ type, value }: OnFieldChangeParams): void {
        this.props.onFieldItemSelect({ type, selectedItemId: value });
    }

    @autobind
    private onRemoveButtonClick(): void {
        this.props.onRemove();
    }

    private getFields(): Fields {
        const { fieldItems, permissions, selectedItemIds } = this.props;

        return {
            channel: {
                label: 'Канал',
                value: selectedItemIds.channel,
                code: fieldItems.channel.find(({ id }) => id === selectedItemIds.channel)?.code,
                items: fieldItems.channel.map(({ id, name }) => ({
                    value: id,
                    label: name,
                })),
                disabled: !permissions.canChangeChannel,
            },
            block: {
                label: 'Блок',
                value: selectedItemIds.block,
                code: fieldItems.block.find(({ id }) => id === selectedItemIds.block)?.code,
                items: fieldItems.block.map(({ id, name }) => ({
                    value: id,
                    label: name,
                })),
                disabled: !permissions.canChangeBlock,
            },
            division: {
                label: 'Дивизион бизнес заказчика',
                value: selectedItemIds.division,
                code: fieldItems.division.find(({ id }) => id === selectedItemIds.division)?.code,
                items: fieldItems.division.map(({ id, name }) => ({
                    value: id,
                    label: name,
                })),
                disabled: !permissions.canChangeDivision,
            },
            segment: {
                label: 'Сегмент',
                value: selectedItemIds.segment,
                code: fieldItems.segment.find(({ id }) => id === selectedItemIds.segment)?.code,
                items: fieldItems.segment.map(({ id, name }) => ({
                    value: id,
                    label: name,
                })),
                disabled: !permissions.canChangeSegment,
            },
            product: {
                label: 'Продукт',
                value: selectedItemIds.product,
                code: fieldItems.product.find(({ id }) => id === selectedItemIds.product)?.code,
                items: fieldItems.product.map(({ id, name }) => ({
                    value: id,
                    label: name,
                })),
                disabled: !permissions.canChangeProduct,
            },
        };
    }
}

export { Field, FieldType, OnFieldItemSelectParams };
