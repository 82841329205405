import { headerViewContext } from './helpers';
import * as React from 'react';

export function useHeaderView() {
    return React.useContext(headerViewContext);
}

// TODO: JSX element usage in state is not good idea, page header needs to be refactored
export function useFirstLineHeader(content: JSX.Element, deps: any[] = []) {
    const [state, setHeaderView] = useHeaderView();

    React.useEffect(() => {
        setHeaderView({
            ...state,
            firstLine: content,
        });

        return () => {
            setHeaderView({
                ...state,
                firstLine: null,
            });
        };
    }, deps);
}

export function useAdditionalContentHeader(content: JSX.Element, deps: any[] = []) {
    const [state, setHeaderView] = useHeaderView();

    React.useEffect(() => {
        setHeaderView({
            ...state,
            additionalContent: content,
        });

        return () => {
            setHeaderView({
                ...state,
                additionalContent: null,
            });
        };
    }, deps);
}
