import { actionCreatorFactory } from 'typescript-fsa';

import { GraphqlFilterValues, DownloadCorrectionsPayload } from '../types';

const actionCreator = actionCreatorFactory('BUDGET_CORRECTIONS_FILTERS_AND_EXPORT_ASYNC').async;

export const loadPageData = actionCreator<null, void, Error>('LOAD_PAGE_DATA');
export const updateUserConfig = actionCreator<null, void, Error>('UPDATE_USER_CONFIG');
export const loadFilterItems = actionCreator<GraphqlFilterValues, void, Error>('LOAD_FILTER_ITEMS');
export const downloadCorrections = actionCreator<DownloadCorrectionsPayload, void, Error>('DOWNLOAD_CORRECTIONS');
