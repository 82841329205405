import { includes } from 'lodash';
import { SuggestHOC, SuggestHOCInstance } from 'sber-marketing-ui';

import { UsersSelectSuggestItem, UsersSelectSuggestProps } from './types';
import { UsersSelectSuggestTemplate } from './UsersSelectSuggestTemplate';

const SuggestHOCEnhancer: SuggestHOCInstance<UsersSelectSuggestProps, UsersSelectSuggestItem> = SuggestHOC({
    disableInputAutoFill: true,
    enableSelectItemOnEnter: true,
    getItemsToFilter: ({ users }: UsersSelectSuggestProps) => users,
    getInitialValue: () => ({ id: '', name: '', department: '', isSelected: false }),
    filter: (value: UsersSelectSuggestItem, item: UsersSelectSuggestItem) =>
        includes(item.name.toLowerCase().trim(), value.name.toLowerCase().trim()) ||
        (item.department && includes(item.department.toLowerCase().trim(), value.name.toLowerCase().trim())),
    equals: (value: UsersSelectSuggestItem, item: UsersSelectSuggestItem) =>
        value.name.toLowerCase().trim() === item.name.toLowerCase().trim(),
});

export const UsersSelectSuggestEnhanced = SuggestHOCEnhancer(UsersSelectSuggestTemplate);
