import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon, IconType, StaticSkeleton } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { TagGroup, SelectedNode, getTagsPageState } from '@store/tagsPage';

import * as styles from './Description.scss';

function useStore() {
    const selectedNode = useSelector((state: StoreState) => getTagsPageState(state).selectedNode);
    const showPreloader = useSelector(
        (state: StoreState) => getTagsPageState(state).loadingStatus.selectedNodeData !== LoadingStatus.LOADED,
    );

    const shouldRender = selectedNode && selectedNode.group !== TagGroup.Tag;

    return {
        selectedNode,
        showPreloader,
        shouldRender,
    };
}

export function Description(): JSX.Element {
    const { selectedNode, showPreloader, shouldRender } = useStore();

    if (shouldRender) {
        let content: JSX.Element;

        if (showPreloader) {
            content = <StaticSkeleton />;
        } else {
            switch (selectedNode.group) {
                case TagGroup.Project:
                    content = <ProjectDescription {...selectedNode} />;
                    break;
                case TagGroup.Task:
                    content = <TaskDescription {...selectedNode} />;
                    break;
                case TagGroup.ExecutionId:
                case TagGroup.PlanId:
                    content = <BudgetItemDescription {...selectedNode} />;
                    break;
                default:
                    console.warn(`Missing case-clause for TagGroup.${selectedNode.group}`);
                    content = null;
                    break;
            }
        }

        return (
            <div
                className={styles.root}
                {...{
                    'qa-id': 'tagsPageRightSidebarDescription',
                }}
            >
                {content}
            </div>
        );
    }

    return null;
}

interface DescriptionProps extends SelectedNode {}

function ProjectDescription(props: DescriptionProps): JSX.Element {
    const { id } = props;
    const nId = +id;

    const project = useSelector((state: StoreState) => getTagsPageState(state).selectedTagData.projects[nId]);

    return (
        <React.Fragment>
            <Icon type={IconType.PROJECT_FLAG_ICON} svgSize={24} className={styles.icon} />

            <span className={styles.description}>{project?.title}</span>

            <OpenSourceButton {...props} />
        </React.Fragment>
    );
}

function TaskDescription(props: DescriptionProps): JSX.Element {
    const { id } = props;

    const task = useSelector((state: StoreState) => getTagsPageState(state).selectedTagData.tasks[id]);

    return (
        <React.Fragment>
            <span className={styles.description}>{task?.title}</span>

            <OpenSourceButton {...props} />
        </React.Fragment>
    );
}

function BudgetItemDescription(props: DescriptionProps): JSX.Element {
    const { id, group } = props;

    const budgetItemSerialNumber = useSelector((state: StoreState) => {
        const { selectedTagData } = getTagsPageState(state);

        switch (group) {
            case TagGroup.ExecutionId:
                return selectedTagData.executionBudgetItems[id]?.serialNumber;
            case TagGroup.PlanId:
                return selectedTagData.planBudgetItems[id]?.serialNumber;
            default:
                return null;
        }
    });

    return (
        <React.Fragment>
            <span className={styles.description}>ID: {budgetItemSerialNumber}</span>

            <OpenSourceButton {...props} />
        </React.Fragment>
    );
}

function OpenSourceButton({ id, group }: DescriptionProps): JSX.Element {
    const taskActivityId = useSelector(
        (state: StoreState) => getTagsPageState(state).selectedNodeData?.task?.task?.activityId,
    );
    const executionBudgetItemBudgetId = useSelector(
        (state: StoreState) => getTagsPageState(state).selectedNodeData.executionBudgetItem?.budgetId,
    );
    const planBudgetItemBudgetId = useSelector(
        (state: StoreState) => getTagsPageState(state).selectedNodeData.planBudgetItem?.budgetId,
    );

    let url = '';

    switch (group) {
        case TagGroup.Project:
            url = `activity/${id}`;
            break;
        case TagGroup.Task:
            url = `activity/${taskActivityId}/task/${id}`;
            break;
        case TagGroup.ExecutionId:
            url = `/budget/execution?filters=id:${id}&budgetId=${executionBudgetItemBudgetId}`;
            break;
        case TagGroup.PlanId:
            url = `/budget/planning?filters=id:${id}&budgetId=${planBudgetItemBudgetId}`;
            break;
        default:
            console.warn(`Missing case-declaration for TagGroup.${group}`);
            break;
    }

    return (
        <Link to={url} className={styles.openSourceLink} target="_blank">
            <div
                {...{
                    'qa-id': 'tagsPageRightSidebarDescriptionOpenSourceButton',
                }}
            >
                <Icon type={IconType.LINK_FOR_SHARING} svgSize={24} />
            </div>
        </Link>
    );
}
