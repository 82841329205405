import * as React from 'react';
import autobind from 'autobind-decorator';

import { Correction } from '../types';
import { withDictionaries, WithDictionaryProps } from './withDictionary';
import { withUsers, WithUsersProps } from './withUsers';

import { Corrections } from './Corrections';

interface Props extends Partial<WithDictionaryProps>, Partial<WithUsersProps> {
    budgetId: string;
    corrections: Correction[];
    onHoverCorrectionCard: (correctionId: string) => void;
    onChangeCorrections: () => void;
    refetchBudgetData: () => Promise<void>;
}

interface State {
    isOpened: boolean;
}

@(withUsers as any)
@(withDictionaries as any)
export class CorrectionsContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isOpened: true,
        };
    }

    public render() {
        const { corrections, dictionaries, dictionariesLoading, users, usersLoading, onHoverCorrectionCard } =
            this.props;

        const loading = dictionariesLoading || usersLoading;

        return dictionaries.length
            ? React.createElement(Corrections, {
                  isOpened: this.state.isOpened,
                  loading,
                  corrections,
                  users,
                  dictionaries,
                  onTitleClick: this.onTitleClick,
                  onHoverCorrectionCard,
                  onChangeCorrections: this.onChangeCorrections,
              })
            : null;
    }

    @autobind
    private onTitleClick(): void {
        this.setState(({ isOpened }) => ({ isOpened: !isOpened }));
    }

    @autobind
    private onChangeCorrections(): void {
        this.props.onChangeCorrections();
        this.props.refetchBudgetData();
    }
}
