import * as React from 'react';
import { Popup as PopupUI, PopupTheme } from 'sber-marketing-ui';

import { Header } from './Header';
import { Code } from './Code';
import { Separator } from './Separator';
import { Button } from './Button';

import * as style from './styles.scss';

export interface Props {
    onClose: () => void;
    message: string;
}

const LINK = 'https://t.me/MRMUserAssistance';

export const ErrorPopup: React.FC<Props> = ({ onClose, message }) => {
    const onClickButton = React.useCallback(() => {
        window.open(LINK, '_blank');
    }, [LINK]);

    return (
        <PopupUI theme={PopupTheme.bindActivityTheme}>
            <div className={style.root}>
                <div className={style.headerWrapper}>
                    <Header titleText={'Внимание!'} onCloseButtonClick={onClose} />
                </div>

                <div className={style.content}>
                    <Code link={LINK} text={message} />
                    <Separator />
                    <Button onClick={onClickButton} />
                </div>
            </div>
        </PopupUI>
    );
};
