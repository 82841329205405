// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-task-components-icons-DeleteTaskParticipantIcon-_DeleteTaskParticipantIcon__root{color:var(--color-primary-50);cursor:pointer;transition:color .3s}@media (pointer: fine){.src-client-modules-task-components-icons-DeleteTaskParticipantIcon-_DeleteTaskParticipantIcon__root:hover{color:var(--color-red-50)}}.src-client-modules-task-components-icons-DeleteTaskParticipantIcon-_DeleteTaskParticipantIcon__button{background:transparent;border:none;font-family:\"Open Sans\", sans-serif}\n", "",{"version":3,"sources":["webpack://./src/client/modules/task/components/icons/DeleteTaskParticipantIcon/DeleteTaskParticipantIcon.scss"],"names":[],"mappings":"AAAA,qGACE,6BAA8B,CAC9B,cAAe,CACf,oBAAqB,CAErB,uBALF,2GAOM,yBAA0B,CAC3B,CAIL,uGACE,sBAAuB,CACvB,WAAY,CACZ,mCAAoC","sourcesContent":[".root {\n  color: var(--color-primary-50);\n  cursor: pointer;\n  transition: color .3s;\n\n  @media (pointer: fine) {\n    &:hover {\n      color: var(--color-red-50);\n    }\n  }\n}\n\n.button {\n  background: transparent;\n  border: none;\n  font-family: \"Open Sans\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-task-components-icons-DeleteTaskParticipantIcon-_DeleteTaskParticipantIcon__root",
	"button": "src-client-modules-task-components-icons-DeleteTaskParticipantIcon-_DeleteTaskParticipantIcon__button"
};
export default ___CSS_LOADER_EXPORT___;
