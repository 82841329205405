// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-StageWidgets-TableWidget-Table-CellTypes-CheckboxListCell-ExecutionBudgetTableButton-_styles__root{color:#417ec6;font-family:'Open Sans';font-size:12px;line-height:16px;letter-spacing:0.15px;user-select:none;cursor:pointer}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/StageWidgets/TableWidget/Table/CellTypes/CheckboxListCell/ExecutionBudgetTableButton/styles.scss"],"names":[],"mappings":"AAAA,+IACI,aAAc,CACd,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACjB,qBAAsB,CACtB,gBAAiB,CACjB,cAAe","sourcesContent":[".root {\n    color: #417ec6;\n    font-family: 'Open Sans';\n    font-size: 12px;\n    line-height: 16px;\n    letter-spacing: 0.15px;\n    user-select: none;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-StageWidgets-TableWidget-Table-CellTypes-CheckboxListCell-ExecutionBudgetTableButton-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
