import * as React from 'react';

import { DropdownPortal, DropdownPortalProps } from '../../popup';
import { Option, OptionProps } from '../Option';

export interface DropdownPortalOptionProps<O> extends OptionProps<O> {
    portal: React.MutableRefObject<HTMLElement>;
    params?: Partial<DropdownPortalProps>;
}

export function DropdownPortalOption<O>({ rootRef, params, portal, children, ...props }: DropdownPortalOptionProps<O>) {
    const parentRef = React.useRef<HTMLDivElement>();
    const parent = rootRef || parentRef;

    return (
        <Option rootRef={parent} {...props}>
            <DropdownPortal {...params} portal={portal} parent={parent}>
                {children}
            </DropdownPortal>
        </Option>
    );
}
