import * as React from 'react';
import { useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { StaticSkeleton } from 'sber-marketing-ui';

import { loadAutopilots } from '@store/autopilotsTab/thunks';

import { autopilotNames } from '@modules/autopilot/constants';
import { getAutopilots, getAutopilotsError, getAutopilotsLoading } from '@store/autopilotsTab/selectors';
import { AutopilotStatus } from '@modules/activity/AutopilotStatus';
import { NoResultsMarker } from '@common/SidebarWithTabs/common/NoResultsMarker';

import * as styles from './AutopilotTab.scss';

type DivProps = JSX.IntrinsicElements['div'];
export interface AutopilotTabProps extends DivProps {
    activityId: number;
}

export const AutopilotTab: React.FC<AutopilotTabProps> = ({ activityId, className, ...props }) => {
    const dispatch = useDispatch();
    const loading = useSelector(getAutopilotsLoading);
    const error = useSelector(getAutopilotsError);
    const autopilots = useSelector(getAutopilots);
    const availableAutopilotNames = autopilotNames.filter(({ id }) => autopilots[id]);

    useEffect(() => {
        dispatch(loadAutopilots(activityId));
    }, []);

    if (loading) {
        return (
            <div className={styles.root}>
                <StaticSkeleton loadingClassName={styles.skeletonCard} />
                <StaticSkeleton loadingClassName={styles.skeletonCard} />
            </div>
        );
    }

    if (error) {
        return <NoResultsMarker text="Не удалось загрузить информацию по автопилотам :(" />;
    }

    if (!availableAutopilotNames.length) {
        return <NoResultsMarker text="Автопилоты не подключены" />;
    }

    return (
        <div {...props} className={classNames(styles.root, className)}>
            {availableAutopilotNames.map(({ id, name }) => (
                <Link to={`/activity/${activityId}/${id}`} key={id} className={styles.card}>
                    <span className={styles.name}>{name}</span>
                    <AutopilotStatus autopilot={autopilots[id]} />
                </Link>
            ))}
        </div>
    );
};
