import { TableLine } from '@store/budgetExecution/types';

const LINE_HEIGHT = 38;

interface Params {
    index: number;
    position: number;
    lines: TableLine[];
    firstLineIndex: number;
    isLast: boolean;
}

export class PlainFrame {
    public readonly index: number;

    public readonly position: number;

    public readonly lines: TableLine[];

    public readonly firstLineIndex: number;

    public readonly isLast: boolean;

    constructor(props: Params) {
        this.index = props.index;
        this.position = props.position;
        this.lines = props.lines;
        this.firstLineIndex = props.firstLineIndex;
        this.isLast = props.isLast;
    }

    public getHeight(): number {
        return this.lines.length * LINE_HEIGHT;
    }
}
