import { Color } from '../../../../common/enums';
import {
    Mode,
    StatisticsItem,
    StatisticsTypeFilters,
    StatisticsActivityType,
    StatisticsTaskType,
} from '@store/leaderDashboardPage';

export interface BuildData<M extends Mode = Mode> {
    (params: BuildDataParams<M>): Data;
}

export type BuilderDataByMap = Record<Mode, BuildData>;

interface BuildDataParams<M extends Mode> {
    statisticsItem: StatisticsItem<M>;
    statisticsTypeFilters: StatisticsTypeFilters;
}

export interface Data {
    id: string;
    name: string;
    bar: {
        first: {
            value: number;
            color: Color;
        };
        second: {
            value: number;
            color: Color;
        };
        third: {
            value: number;
            color: Color;
        };
        fourth: {
            value: number;
            color: Color;
        };
    };
}

export { StatisticsActivityType, StatisticsTaskType };
