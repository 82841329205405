import * as React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { IconType } from 'sber-marketing-ui';
import { Link } from 'react-router-dom';

import { StoreState } from '@store';
import { getActivityTypeById } from '@store/activityTypes';
import { getDivisionById } from '@store/divisions';
import { getUserById } from '@store/users';
import { getTagsPageState } from '@store/tagsPage';
import { getOrganizationById } from '@store/organizations';

import { DatesFormatter } from '@common/Utils';

import { Item } from '../Item';

import * as styles from './Activity.scss';

function useStore() {
    const activityData = useSelector((state: StoreState) => getTagsPageState(state).selectedNodeData?.project);
    const activityType = useSelector((state: StoreState) => getActivityTypeById(state, activityData?.activity?.typeId));
    const division = useSelector((state: StoreState) => getDivisionById(state, activityData?.activity?.divisionId));

    const organization = useSelector((state: StoreState) =>
        getOrganizationById(state, activityData?.activity?.organizationId),
    );
    const author = useSelector((state: StoreState) => getUserById(state, activityData?.activity?.authorId));
    const responsibleOrganization = useSelector((state: StoreState) =>
        getOrganizationById(state, activityData?.activity?.responsible?.organizationId),
    );

    return {
        activityData,
        activityType,
        division,
        organization,
        author,
        responsibleOrganization,
    };
}

export function Activity(): JSX.Element {
    const { activityData, activityType, division, organization, author, responsibleOrganization } = useStore();

    if (!activityData) {
        console.warn(`Expected activity data was not found in store`);

        return null;
    }

    const {
        activity: {
            id,
            name,
            description,
            brief,
            responsible,
            productName,
            preparationDate,
            debriefingDate,
            realizationStart,
            realizationEnd,
            createTime,
        },
        briefScheme,
    } = activityData;

    const briefContent = (
        <span className={classnames(!brief, styles.briefMissing)}>
            {briefScheme ? (
                <Link to={`activity/${id}/brief`} target="_blank" className={styles.link}>
                    {briefScheme?.name}
                </Link>
            ) : (
                'Отсутствует'
            )}
        </span>
    );
    const responsibleContent = (
        <a className={styles.link}>
            {responsible?.secondName} {responsible.firstName} {responsible.middleName}
            &nbsp;
            <span className={styles.responsibleOrganization}>{responsibleOrganization?.name}</span>
        </a>
    );
    const datesContent = (
        <span>
            {DatesFormatter.ddMonthyyyy(realizationStart)} -- {DatesFormatter.ddMonthyyyy(realizationEnd)}
        </span>
    );
    const organizationAndBlockContent = (
        <span>
            {organization?.name}, {division?.name}
        </span>
    );
    const authorContent = (
        <span>
            {author?.secondName} {author?.firstName}
        </span>
    );

    return (
        <React.Fragment>
            <Item qaId="tagspageRightSidebarActivityContentTitle" title="Название" content={name} />
            <Item qaId="tagspageRightSidebarActivityContentBrief" title="Бриф" content={briefContent} />
            <Item
                qaId="tagspageRightSidebarActivityContentResponsible"
                title="Ответственный"
                content={responsibleContent}
                icon={IconType.USER16}
            />
            <Item
                qaId="tagspageRightSidebarActivityContentDescription"
                title="Описание проекта"
                content={description}
                icon={IconType.INFO_CIRCLE}
            />
            <Item
                qaId="tagspageRightSidebarActivityContentPreparationDate"
                title="Дата начала подготовки"
                content={DatesFormatter.ddMonthyyyy(preparationDate)}
                icon={IconType.CALENDAR_ICON}
            />
            <Item
                qaId="tagspageRightSidebarActivityContentDebriefingDate"
                title="Дата подведения итогов"
                content={DatesFormatter.ddMonthyyyy(debriefingDate)}
                icon={IconType.CALENDAR_ICON}
            />
            <Item
                qaId="tagspageRightSidebarActivityContentDates"
                title="Даты проведения"
                content={datesContent}
                icon={IconType.CALENDAR_ICON}
            />
            <Item
                qaId="tagspageRightSidebarActivityContentActivityType"
                title="Тип проекта"
                content={activityType?.name}
            />
            <Item
                qaId="tagspageRightSidebarActivityContentProduct"
                title="Продукт"
                content={productName}
                icon={IconType.PRODUCT_ICON}
            />
            <Item
                qaId="tagspageRightSidebarActivityContentOrganizationAndBlock"
                title="Организация и блок"
                content={organizationAndBlockContent}
                icon={IconType.BLOCK_ICON}
            />
            <Item qaId="tagspageRightSidebarActivityContentAuthor" title="Автор проекта" content={authorContent} />
            <Item
                qaId="tagspageRightSidebarActivityContentCreationTime"
                title="Дата создания проекта"
                content={DatesFormatter.ddMonthyyyy(createTime)}
            />
        </React.Fragment>
    );
}
