import * as React from 'react';
import * as moment from 'moment';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';
import { useBehavior, Period } from './useBehavior';

import * as styles from './styles.scss';

export interface Props {}

export const Selector: React.FC<Props> = () => {
    const { selectedFilterDate, selectedFilterPeriod, onDateSelect } = useBehavior();

    const onClickLeftButton = React.useCallback(() => {
        const timeKey = getTimeKeyByPeriod(selectedFilterPeriod);
        const date = moment(selectedFilterDate).subtract(1, timeKey).format('YYYY-MM-DD');
        onDateSelect({ date });
    }, [selectedFilterPeriod, selectedFilterDate]);

    const onClickRightButton = React.useCallback(() => {
        const timeKey = getTimeKeyByPeriod(selectedFilterPeriod);
        const date = moment(selectedFilterDate).add(1, timeKey).format('YYYY-MM-DD');
        onDateSelect({ date });
    }, [selectedFilterPeriod, selectedFilterDate]);

    const formattedData = formatDate({
        date: selectedFilterDate,
        period: selectedFilterPeriod,
    });

    return (
        <div className={styles.root}>
            <div className={classNames(styles.button, styles.leftButton)} onClick={onClickLeftButton}>
                <Icon type={IconType.ARROW16} svgSize={13} />
            </div>

            <div className={styles.text}>{formattedData}</div>

            <div className={classNames(styles.button, styles.rightButton)} onClick={onClickRightButton}>
                <Icon type={IconType.ARROW16} svgSize={13} />
            </div>
        </div>
    );
};

const keys: Record<Period, 'y' | 'Q' | 'M' | 'w'> = {
    [Period.Year]: 'y',
    [Period.Quarter]: 'Q',
    [Period.Month]: 'M',
    [Period.Week]: 'w',
};

const getTimeKeyByPeriod = (period: Period): 'y' | 'Q' | 'M' | 'w' => {
    return keys[period] || keys[Period.Week];
};

const formatsByPeriod: Record<Period, string> = {
    [Period.Year]: 'YYYY',
    [Period.Quarter]: 'Q [квартал] YYYY',
    [Period.Month]: 'MMMM YYYY',
    [Period.Week]: 'W [неделя] YYYY',
};

const formatDate = ({ date, period }: { date: string; period: Period }): string => {
    if (period === Period.Week) {
        const currentDate = moment(date);
        const fromDate = currentDate.startOf('week').format('D MMMM YYYY');
        const toDate = currentDate.endOf('week').format('D MMMM YYYY');

        return `${fromDate} - ${toDate}`;
    }

    return moment(date).format(getDateFormatByPeriod(period));
};

export const getDateFormatByPeriod = (period: Period): string => {
    return formatsByPeriod[period] || formatsByPeriod[Period.Week];
};
