import * as React from 'react';
import { useSelector } from 'react-redux';
import { sum, values } from 'lodash';

import { StoreState } from '@store';
import { MONTH_BY_COLUMN_NAMES } from '@store/budgetExecution';
import {
    InternalTransferDirection,
    getTransitionBudgetItem,
    getBudgetTransferMenuState,
    getTotalTransferAmountForSource,
} from '@store/budgetExecution/budgetTransferMenu';

import { MonthNames, Balance, YearPlanReserveDiff, PlanWithPlanReserveDiff } from './Balance';

import * as styles from './Balance.scss';

function useStore() {
    const fromRow = useSelector((state: StoreState) => {
        const {
            cells: { from },
            controls: { rowForSumEntering, internalTransferDirection },
        } = getBudgetTransferMenuState(state);

        if (internalTransferDirection === InternalTransferDirection.OneToMany) {
            return from[0].lineId;
        } else {
            return rowForSumEntering;
        }
    });
    const fromBudgetItem = useSelector((state: StoreState) => getTransitionBudgetItem(state, fromRow));
    const transferAmount = useSelector((state: StoreState) => getTotalTransferAmountForSource(state, fromRow));

    const totalPlanReserveDiff =
        sum(values(fromBudgetItem?.plannedFunds || {})) -
        sum(values(fromBudgetItem.reservedFunds || {})) -
        transferAmount;

    return {
        fromBudgetItem,
        transferAmount,
        totalPlanReserveDiff,
    };
}

export function SourceBalance(): JSX.Element {
    const internalTransferDirection = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.internalTransferDirection,
    );

    switch (internalTransferDirection) {
        case InternalTransferDirection.OneToMany:
            return <SourceCellBalance />;
        case InternalTransferDirection.ManyToOne:
            return <SourceRowBalance />;
        default:
            return null;
    }
}

function SourceRowBalance(): JSX.Element {
    const { fromBudgetItem, transferAmount, totalPlanReserveDiff } = useStore();

    return (
        <Balance
            qaId="internalTransitionSumEnteringSourceBalance"
            title={
                <span className={styles.title}>
                    Из строки{' '}
                    <span
                        className={styles.titleBold}
                        {...{
                            'qa-id': 'internalTransitionSumEnteringSourceBalanceSerialNumber',
                        }}
                    >
                        ID {fromBudgetItem.serialNumber}
                    </span>{' '}
                    будет вычтено:
                </span>
            }
            transferAmount={transferAmount}
            subtitle={<YearPlanReserveDiff diff={totalPlanReserveDiff} />}
        />
    );
}

function useSourceCellBalanceStore() {
    const storeContent = useStore();

    const fromCell = useSelector((state: StoreState) => getBudgetTransferMenuState(state).cells.from[0]);

    const planValue = storeContent.fromBudgetItem.plannedFunds[MONTH_BY_COLUMN_NAMES[fromCell.columnName]];
    const reserveValue = storeContent.fromBudgetItem.reservedFunds[MONTH_BY_COLUMN_NAMES[fromCell.columnName]];

    const planDiff = planValue - storeContent.transferAmount;
    const planReserveDiff = planValue - reserveValue - storeContent.transferAmount;

    return {
        ...storeContent,
        fromCell,
        planDiff,
        planReserveDiff,
    };
}

function SourceCellBalance(): JSX.Element {
    const { fromCell, fromBudgetItem, transferAmount, planDiff, planReserveDiff } = useSourceCellBalanceStore();

    return (
        <Balance
            qaId="internalTransitionSumEnteringSourceBalance"
            title={
                <span className={styles.title}>
                    Из ячейки{' '}
                    <span
                        className={styles.titleBold}
                        {...{
                            'qa-id': 'internalTransitionSumEnteringSourceBalanceSerialNumber',
                        }}
                    >
                        ID {fromBudgetItem.serialNumber} {MonthNames[fromCell.columnName]}
                    </span>{' '}
                    будет вычтено:
                </span>
            }
            transferAmount={transferAmount}
            subtitle={<PlanWithPlanReserveDiff planDiff={planDiff} planReserveDiff={planReserveDiff} />}
        />
    );
}
