import * as React from 'react';
import * as moment from 'moment';
import { map } from 'lodash';
import { Preloader, StyledPopup, StyledPopupTheme } from 'sber-marketing-ui';

import * as style from './TaskCard.scss';

import { TaskStatus } from 'sber-marketing-types/frontend';
import { CardBodyIndicatorColor } from '@common/Card';
import { TaskCardType } from '@store/tasksList/types';
import { ComponentMode as TaskEditorMode } from '@store/taskEditor2';
import { TaskCardTemplateProps } from './types';

import { FullCard } from './FullCard';
import { ShortCard } from './ShortCard';
import { TaskEditor } from '@common/TaskEditor';

// tslint:disable:cyclomatic-complexity
export const TaskCard: React.FC<TaskCardTemplateProps> = ({
    id,
    canEdit,
    canDisplayRate,
    taskPermissions,
    department,
    authorDepartment,
    author,
    workTypeName,
    isTaskEditorOpen,
    activityId,
    activity,
    deadline,
    title,
    status,
    indicators,
    buttons,
    change,
    link,
    participantIds,
    isLoading,
    displayActivityName,
    cardType,
    stage,
    rate,
    // isSharingPopupOpened,
    isDeleteConfirmationOpened,
    activityStages,
    showTaskEditorForEnteringRequiredFields,
    isOpenedInSidebar,
    deleteTask,
    closeDeletionConfirmation,
    onTaskEditorPopupClose,
    // onSharingPopupClose,
    // onSharingPopupButtonClick,
    onTaskEditorPopupCreated,
    editDeadline,
    editStatus,
    editStage,
    closeStatusChangeErrorPopup,
    onRootClick,
    onCardClick,
}: TaskCardTemplateProps): JSX.Element => {
    const isDeadlineExpired = moment().isAfter(deadline);
    const canEditDeadline = map(taskPermissions, (value) => value).some((value) => value);

    return (
        <>
            <div
                {...{
                    'qa-id': 'taskCard',
                    'qa-task-id': id,
                }}
                className={style.root}
                onClick={onRootClick}
            >
                {cardType == TaskCardType.NORMAL && (
                    <FullCard
                        isLoading={isLoading}
                        link={link}
                        canEdit={canEdit}
                        canDisplayRate={canDisplayRate}
                        color={getStatusColor(status, isDeadlineExpired)}
                        displayActivityName={displayActivityName}
                        activityId={activityId}
                        activity={activity}
                        workTypeName={workTypeName}
                        deadline={deadline}
                        title={title}
                        status={status}
                        indicators={indicators}
                        buttons={buttons}
                        author={author}
                        stage={stage}
                        rate={rate}
                        activityStages={activityStages}
                        canEditDeadline={canEditDeadline}
                        isOpenedInSidebar={isOpenedInSidebar}
                        editDeadline={editDeadline}
                        editStatus={editStatus}
                        editStage={editStage}
                        onCardClick={onCardClick}
                    />
                )}

                {cardType == TaskCardType.SHORT && (
                    <ShortCard
                        isLoading={isLoading}
                        link={link}
                        canEdit={canEdit}
                        canDisplayRate={canDisplayRate}
                        color={getStatusColor(status, isDeadlineExpired)}
                        activityId={activityId}
                        activity={activity}
                        deadline={deadline}
                        title={title}
                        status={status}
                        rate={rate}
                        buttons={buttons}
                        author={author}
                        canEditDeadline={canEditDeadline}
                        isOpenedInSidebar={isOpenedInSidebar}
                        editDeadline={editDeadline}
                        editStatus={editStatus}
                        onCardClick={onCardClick}
                    />
                )}

                {isLoading && <Preloader />}
            </div>

            {isDeleteConfirmationOpened && (
                <StyledPopup
                    theme={StyledPopupTheme.Redesign}
                    qaId="taskCardDeletePopup"
                    title={'Внимание!'}
                    fullMessage={'Задача будет удалена без возможности ее восстановить'}
                    firstButtonQaId="taskCardFirstButton"
                    secondButtonQaId="taskCardSecondButton"
                    secondButtonText={'Отменить'}
                    firstButtonText={'Удалить'}
                    secondButtonHandler={closeDeletionConfirmation}
                    firstButtonHandler={deleteTask}
                />
            )}

            {/*{isSharingPopupOpened &&*/}
            {/*    <TaskSharingPopup*/}
            {/*        taskId={id}*/}
            {/*        title={`Поделиться задачей «${ title }»`}*/}
            {/*        onClose={onSharingPopupClose}*/}
            {/*        onShareButtonClick={onSharingPopupButtonClick}*/}
            {/*        selectedUserIds={participantIds}*/}
            {/*    />*/}
            {/*}*/}

            {isTaskEditorOpen && (
                <TaskEditor
                    activityId={activityId}
                    taskId={id}
                    permissions={taskPermissions}
                    componentMode={
                        showTaskEditorForEnteringRequiredFields
                            ? TaskEditorMode.enterRequiredFieldsForExistingTask
                            : null
                    }
                    onTaskCreated={onTaskEditorPopupCreated}
                    closePopup={onTaskEditorPopupClose}
                />
            )}
        </>
    );
};

function getStatusColor(status: TaskStatus, isDeadlineExpired: boolean): CardBodyIndicatorColor {
    let result: CardBodyIndicatorColor;

    switch (status) {
        case TaskStatus.InProgress:
            result = isDeadlineExpired ? CardBodyIndicatorColor.RED : CardBodyIndicatorColor.GREEN;
            break;
        case TaskStatus.Closed:
            result = CardBodyIndicatorColor.BLUE;
            break;
        case TaskStatus.Draft:
            result = CardBodyIndicatorColor.GRAY;
            break;
        default:
            result = CardBodyIndicatorColor.GREEN;
            break;
    }

    return result;
}
