import * as React from 'react';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as lodash from 'lodash';

import { UserCard } from 'sber-marketing-types/frontend';

import { MentionItem, MentionItemStatus } from 'sber-marketing-ui';
import { TaskCommentForm } from './TaskCommentForm';
import { StoreState } from '@store';
import {
    addCommentary,
    editCommentary,
    addNewCommentaryAsset,
    removeNewCommentaryAsset,
    selectors,
    setEditingComment,
    NEW_COMMENTARY,
} from '@store/taskPage';
import { FileAsset } from '@store/commonTypes';
import { getChannelById } from '@store/taskPage/selectors';
import { getAllUsers } from '@store/appUsers';
import { add, remove } from '@store/fileAssets';
import { getHeaderHeight } from '@store/common/selectors';
import { withTaskData, PropsWithTaskData } from '../../withTaskData';
import { TaskApi, FileApi } from '@api';

interface Props extends Partial<MapProps>, Partial<DispatchProps>, Partial<PropsWithTaskData> {
    taskId: string;
    className?: string;
}

interface MapProps {
    assetsIds: string[];
    editingComment: string | symbol | null;
    id: string;
    content: string;
    authorId: number;
    hasAssets: boolean;
    participantUsers: UserCard[];
    headerHeight: number;
}

interface DispatchProps {
    addCommentary(): void;
    editNewCommentary(content: string): void;
    addNewCommentaryAsset(asset: FileAsset): void;
    removeNewCommentaryAsset(id: string): void;
    setEditingComment(value: string | symbol | null): void;
}

interface State {
    displayError: boolean;
}

@(withTaskData as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class TaskCommentFormContainer extends React.Component<Props, State> {
    public state: State = {
        displayError: false,
    };

    public render(): JSX.Element {
        return React.createElement(TaskCommentForm, {
            assetsIds: this.props.assetsIds,
            displayError: this.state.displayError,
            errorMessage: 'Невозможно отправить пустой комментарий',
            headerHeight: this.props.headerHeight,
            hasAssets: this.props.hasAssets,
            message: this.props.content,
            className: this.props.className,
            mentionableUsers: this.makeMentionableUsers(this.props.participantUsers),
            onChange: this.onChange,
            onAddAsset: this.onAddAsset,
            onSubmit: this.onSubmit,
            onAssetRemoveClick: this.onRemoveAsset,
            onFocus: this.onFocus,
        });
    }

    @autobind
    protected onFocus() {
        this.setState(() => ({ displayError: false }));
        this.props.setEditingComment(NEW_COMMENTARY);
    }

    @autobind
    protected onChange(message: string): void {
        this.props.editNewCommentary(message);

        window.clearTimeout(window['newCommentTimer']);

        window['newCommentTimer'] = window.setTimeout(
            () => TaskApi.editTaskDraft({ id: this.props.taskId, newComment: message }),
            10000,
        );
    }

    @autobind
    protected async onAddAsset(file: FileList | FileList): Promise<void> {
        if (file instanceof FileList) {
            Array.from(file).forEach((file) => this.addAsset(file));
        } else {
            this.addAsset(file);
        }
    }

    @autobind
    protected addAsset(file: File) {
        const { taskId, id, authorId } = this.props;
        const params = { taskId, commentId: id };

        const asset = FileApi.makeFileAsset(params, file, String(authorId));
        asset.id = asset.name;
        this.props.addNewCommentaryAsset(asset);

        this.setState(() => ({ displayError: false }));
    }

    @autobind
    protected onRemoveAsset({ name }: FileAsset): void {
        this.props.removeNewCommentaryAsset(name);
    }

    @autobind
    protected onSubmit(): void {
        const isValid = this.isCommentValid();

        if (isValid) {
            if (this.props.editingComment === NEW_COMMENTARY) {
                this.props.setEditingComment(null);
            }

            this.props.addCommentary();
        }

        this.setState({ displayError: !isValid });
    }

    private isCommentValid(): boolean {
        const { content, hasAssets } = this.props;

        return (content && content.trim().length > 0) || hasAssets;
    }

    private makeMentionableUsers(users: UserCard[]): MentionItem[] {
        const formatedUsers: MentionItem[] = users.map((user) => ({
            id: user.id,
            title: `${user.firstName} ${user.secondName}`,
            description: user.department,
            status: user.vacation ? MentionItemStatus.Vacation : null,
        }));

        return lodash.sortBy(formatedUsers, (item) => item.title);
    }
}

function mapStateToProps(state: StoreState, { channelId }: Props): MapProps {
    const { id, content, authorId, assetsIds } = selectors.getNewCommentaryState(state);
    const { participants } = selectors.getTaskInfo(state);
    const users = getAllUsers(state);

    const { authorId: channelAuthorId, participantIds } = getChannelById(state, channelId);

    const channelUserIds = [channelAuthorId, ...participantIds];

    const participantUsers = users.filter(
        (user) =>
            participants.some((item) => item.userId == user.id) &&
            (channelId === null || channelUserIds.includes(user.id)),
    );

    return {
        id,
        content,
        authorId,
        assetsIds,
        editingComment: selectors.getEditingComment(state),
        hasAssets: !!selectors.getAssetsByIds(state, assetsIds).length,
        participantUsers,
        headerHeight: getHeaderHeight(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>, { channelId }: Props): DispatchProps {
    return {
        addCommentary: () => dispatch(addCommentary(channelId)),
        editNewCommentary: (content: string) => dispatch(editCommentary({ content })),
        addNewCommentaryAsset: (asset: FileAsset) => {
            dispatch(add(asset));
            dispatch(addNewCommentaryAsset(asset));
        },
        removeNewCommentaryAsset: (assetId: string) => {
            dispatch(remove(assetId));
            dispatch(removeNewCommentaryAsset(assetId));
        },
        setEditingComment: (value: string | symbol | null) => dispatch(setEditingComment(value)),
    };
}
