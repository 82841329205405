import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { StoreState } from '@store';
import { LineModalTabs, getLineModalState, setSelectedTab } from '@store/budgetPlanning/lineModal';

import * as style from './Header.scss';

export function Header(): JSX.Element {
    return (
        <div className={style.root}>
            <Tab tab={LineModalTabs.GeneralInfo} text="Основная информация" />

            <Tab tab={LineModalTabs.BudgetItemSnapshotStory} text="История изменений строки" />
        </div>
    );
}

interface TabProps {
    tab: LineModalTabs;
    text: string;
}

function Tab({ tab, text }: TabProps): JSX.Element {
    const dispatch = useDispatch();
    const selectedTab = useSelector((state: StoreState) => getLineModalState(state).selectedTab);

    function onTabSelect(selectedTab: LineModalTabs) {
        dispatch(setSelectedTab(selectedTab));
    }

    return (
        <div
            className={classNames(style.tab, tab === selectedTab && style.selected)}
            onClick={() => onTabSelect(tab)}
            {...{
                'qa-id': 'budgetPlanningLineModalTab',
            }}
        >
            {text}
        </div>
    );
}
