import * as React from 'react';
import classNames from 'classnames';
import QRCode from 'react-qr-code';
import * as styles from './styles.scss';

interface Props {
    link: string;
}

export const Code: React.FC<Props> = ({ link }) => {
    return (
        <div className={styles.root}>
            <div>
                <div className={styles.paragraph}>Если используете мобильную версию Telegram:</div>
                <div className={classNames(styles.paragraph, styles.point)}>отсканируйте QR-код камерой устройства</div>
                <div className={classNames(styles.paragraph, styles.point)}>
                    нажмите кнопку "Старт" в чат-боте для создания чата
                </div>
            </div>

            <div className={styles.qrCode}>
                <QRCode value={link} size={160} />
            </div>
        </div>
    );
};
