import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import {
    getFilterPeriod,
    getFilterDate,
    getFilteredChildrenOfSelectedStatisticsItem,
    getStatisticsTypeFilters,
    FilterPeriod,
    StatisticsActivityType,
    StatisticsTaskType,
} from '@store/leaderDashboardPage';
import type { StatisticsItem, StatisticsType } from '@store/leaderDashboardPage';

interface UseState {
    (): UseStateReturn;
}

interface UseStateReturn extends Selected {}

interface Selected {
    selectedPeriod: FilterPeriod;
    selectedDate: string;
    statisticsItems: StatisticsItem[];
    statisticsTypeFilters: Record<StatisticsType, boolean>;
}

export const useState: UseState = () => {
    const { selectedPeriod, selectedDate, statisticsItems, statisticsTypeFilters } = useSelector<StoreState, Selected>(
        (state) => {
            return {
                selectedPeriod: getFilterPeriod(state),
                selectedDate: getFilterDate(state),
                statisticsItems: getFilteredChildrenOfSelectedStatisticsItem(state),
                statisticsTypeFilters: getStatisticsTypeFilters(state),
            };
        },
    );

    return {
        selectedPeriod,
        selectedDate,
        statisticsItems,
        statisticsTypeFilters,
    };
};

export { StatisticsType, StatisticsActivityType, StatisticsTaskType };
