import * as React from 'react';
import classNames from 'classnames';

import { WithTitle } from '../WithTitle';
import * as style from './SubPramList.scss';

export interface SubParamData {
    value: React.ReactNode;
    name?: string;
    isWide?: boolean;
    notCropContent?: boolean;
}

interface Props {
    supParams: SubParamData[];
}

export const SubPramList: React.FC<Props> = ({ supParams }) => (
    <div className={style.root}>
        {supParams.map((data, index) => (
            <Param key={index} {...data} />
        ))}
    </div>
);

const Param: React.FC<SubParamData> = ({ isWide, name, value, notCropContent }) => (
    <div
        className={classNames(
            style.field,
            isWide && style.wide,
            notCropContent ? style.notCroppedField : style.croppedField,
        )}
    >
        {name ? (
            <WithTitle title={name}>
                <div
                    className={style.value}
                    {...{
                        'qa-id': 'correctionCardSubParams',
                        'qa-label': name,
                    }}
                >
                    {value || '–'}
                </div>
            </WithTitle>
        ) : (
            <div className={style.value}>{value || '–'}</div>
        )}
    </div>
);
