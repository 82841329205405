import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { Autopilot } from 'sber-marketing-types/backend';
import { User, getLoginUser } from '@store/user';

import { StagesListWidgetTemplate, StageProps } from './StagesListWidgetTemplate';
import type { StoreState } from '@store';
import { setCurrentStage } from '@store/autopilot_legacy/actions';
import { getAutopilot } from '@store/autopilot_legacy/selectors';
import { loadAutopilotLegacy as loadAutopilot } from '@store/activityPage/thunks';
import { Loader, Saver } from '../modules';

interface Props extends MapProps, DispatchProps {
    currentStage: number;
}

interface MapProps {
    autopilot?: Autopilot;
    user?: User;
}

interface DispatchProps {
    setCurrentStage?: (stage: number) => void;
    loadAutopilot?: (activityId: number) => void;
}

interface State {
    displayAutopilotConfirmModal: boolean;
    displayPlacementsConfirmModal: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class StagesListWidgetBehaviour extends React.PureComponent<Props, State> {
    private loader: Loader;
    private saver: Saver;

    public constructor(props: Props) {
        super(props);

        this.state = {
            displayAutopilotConfirmModal: false,
            displayPlacementsConfirmModal: false,
        };

        this.loader = Loader.getInstance();
        this.saver = Saver.getInstance();
    }

    public render(): JSX.Element {
        const { autopilot } = this.props;

        const { displayAutopilotConfirmModal, displayPlacementsConfirmModal } = this.state;

        const autopilotIsEnabled = autopilot && autopilot.status == 'on';
        const placementsAreLaunched = this.checkLaunchedPlacements();

        const userHasAutopilotClientAccess = this.checkUserAutopilotClientAccess();
        const userHasAutopilotClientServiceAccess = this.checkUserAutopilotClientServiceAccess();

        const displayAutopilotEnableButton =
            (userHasAutopilotClientAccess || userHasAutopilotClientServiceAccess) &&
            !placementsAreLaunched &&
            !autopilotIsEnabled;

        const displayAutopilotDisableButton =
            (userHasAutopilotClientAccess || userHasAutopilotClientServiceAccess) &&
            !placementsAreLaunched &&
            autopilotIsEnabled;

        const displayPlacementsStopButton =
            (userHasAutopilotClientAccess || userHasAutopilotClientServiceAccess) && placementsAreLaunched;

        return React.createElement(StagesListWidgetTemplate, {
            stages: this.makeStages(),
            displayAutopilotEnableButton,
            displayAutopilotDisableButton,
            displayPlacementsStopButton,
            displayAutopilotEnableConfirmModal: displayAutopilotConfirmModal && !autopilotIsEnabled,
            displayAutopilotDisableConfirmModal: displayAutopilotConfirmModal && autopilotIsEnabled,
            displayPlacementsConfirmModal,
            onAutopilotButtonClick: this.onAutopilotButtonClick,
            onPlacementsButtonClick: this.onPlacementsButtonClick,
            onAutopilotEnableConfirm: this.onAutopilotEnableConfirm,
            onAutopilotDisableConfirm: this.onAutopilotDisableConfirm,
            onPlacementsStopConfirm: this.onPlacementsStopConfirm,
            onModalCancel: this.onModalCancel,
        });
    }

    @autobind
    protected onAutopilotButtonClick() {
        this.setState({
            displayAutopilotConfirmModal: true,
        });
    }

    @autobind
    protected onPlacementsButtonClick() {
        this.setState({
            displayPlacementsConfirmModal: true,
        });
    }

    @autobind
    protected onModalCancel() {
        this.setState({
            displayAutopilotConfirmModal: false,
            displayPlacementsConfirmModal: false,
        });
    }

    @autobind
    protected async onAutopilotEnableConfirm() {
        const { autopilot } = this.props;

        this.setState({ displayAutopilotConfirmModal: false });

        await this.saver.setAutopilotStatus(true);
        await Promise.all([this.props.loadAutopilot(autopilot.activityId), this.loader.loadAutopilot()]);
    }

    @autobind
    protected async onAutopilotDisableConfirm() {
        const { autopilot } = this.props;

        this.setState({ displayAutopilotConfirmModal: false });

        await this.saver.setAutopilotStatus(false);
        await Promise.all([this.props.loadAutopilot(autopilot.activityId), this.loader.loadAutopilot()]);
    }

    @autobind
    protected async onPlacementsStopConfirm() {
        this.setState({ displayPlacementsConfirmModal: false });
        await this.saver.stopPlacements();
        await this.loader.loadAutopilot();
    }

    @autobind
    protected onStageClick(stageOrder: number) {
        if (stageOrder !== this.props.currentStage) {
            this.props.setCurrentStage(stageOrder);
        }
    }

    private checkLaunchedPlacements(): boolean {
        const { autopilot } = this.props;

        if (!autopilot) {
            return false;
        }

        return (
            autopilot.status === 'on' &&
            !lodash.isEmpty(autopilot.placements) &&
            !autopilot.placements.every((item) => item.auto_status === 'AUTO_OFF')
        );
    }

    private checkUserAutopilotClientAccess() {
        // check if user has role === Autopilot Client
        return this.props.user.attributes.roles.map(({ id }) => id).includes(22);
    }

    private checkUserAutopilotClientServiceAccess() {
        // check if user has role === Autopilot Client Service
        return this.props.user.attributes.roles.map(({ id }) => id).includes(24);
    }

    private makeStages(): StageProps[] {
        const { autopilot, currentStage } = this.props;

        if (!autopilot) {
            return [];
        }

        const { brief, mediaplan, budget, creative, placements } = autopilot;

        const briefIsFinished = brief
            ? [
                  brief.block,
                  brief.product,
                  brief.budget,
                  brief.dateStart,
                  brief.dateEnd,
                  brief.age_from,
                  brief.age_to,
              ].every((item) => (typeof item == 'number' ? item !== null : !lodash.isEmpty(item)))
            : false;

        const mediaplanIsFinished = mediaplan ? !lodash.isEmpty(mediaplan.url) : false;

        const budgetIsFinished = budget ? !lodash.isEmpty(budget.budgetIds) : false;

        const creativeCounter = creative
            ? [
                  creative.vk,
                  // creative.fb,
                  creative.ok,
                  // creative.youtube
              ].filter((item) => !lodash.isEmpty(item)).length
            : 0;

        const creativeIsFinished = creativeCounter == 2;

        return [
            {
                stageOrder: 1,
                name: 'Бриф',
                title: briefIsFinished ? 'Заполнен' : 'Не заполнен',
                isActive: currentStage == 1,
                isFinished: briefIsFinished,
                onClick: this.onStageClick,
            },
            {
                stageOrder: 2,
                name: 'Медиаплан',
                title: mediaplanIsFinished ? 'Рассчитан' : 'Не рассчитан',
                isActive: currentStage == 2,
                isFinished: mediaplanIsFinished,
                onClick: this.onStageClick,
            },
            {
                stageOrder: 3,
                name: 'Бюджет',
                title: budgetIsFinished ? `Привязано ${budget.budgetIds.length} ID` : 'Не заполнен',
                isActive: currentStage == 3,
                isFinished: budgetIsFinished,
                onClick: this.onStageClick,
            },
            {
                stageOrder: 4,
                name: 'Креатив',
                title: `Заполнено ${creativeCounter}/2`,
                isActive: currentStage == 4,
                isFinished: creativeIsFinished,
                onClick: this.onStageClick,
            },
            {
                stageOrder: 5,
                name: 'Финальное согласование',
                title: placements && placements.length > 0 ? 'Согласовано' : 'Не согласовано',
                isActive: currentStage == 5,
                isFinished: placements && placements.length > 0 ? true : false,
                onClick: this.onStageClick,
            },
        ];
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        autopilot: getAutopilot(state),
        user: getLoginUser(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setCurrentStage,
            loadAutopilot,
        },
        dispatch,
    );
}
