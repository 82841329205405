import { Success } from 'typescript-fsa';
import { get } from 'lodash';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { BudgetStatus } from '@mrm/budget';

import { LoadingStatus } from '@store/commonTypes';

import {
    BudgetUserConfigState,
    BudgetUserConfig,
    LoadBudgetUserConfig,
    UpdateBudgetUserConfig,
    BudgetByStatusUserConfig,
} from './types';
import * as actions from './actions';

class Reducer {
    public static makeInitialState(): BudgetUserConfigState {
        return {
            status: LoadingStatus.NOT_LOADED,
            fields: {
                budgetStatus: {
                    [BudgetStatus.Plan]: Reducer.makeEmptyBudgetByStatusState(),
                    [BudgetStatus.Execution]: Reducer.makeEmptyBudgetByStatusState(),
                },
            },
        };
    }

    public static loadUserConfig(
        state: BudgetUserConfigState,
        payload: Success<BudgetUserConfig, LoadBudgetUserConfig>,
    ): BudgetUserConfigState {
        const {
            result: { receivedUserConfig },
        } = payload;

        return {
            status: LoadingStatus.LOADED,
            fields: Reducer.mergeUserConfigs(state.fields, receivedUserConfig),
        };
    }

    public static updateUserConfig(
        state: BudgetUserConfigState,
        payload: UpdateBudgetUserConfig,
    ): BudgetUserConfigState {
        return {
            ...state,
            fields: Reducer.mergeUserConfigs(state.fields, payload),
        };
    }

    private static makeEmptyBudgetByStatusState(): BudgetByStatusUserConfig {
        return {
            budgetId: null,
        };
    }

    private static mergeUserConfigs(
        stateUserConfig: BudgetUserConfig,
        receivedUserConfig: Partial<BudgetUserConfig>,
    ): BudgetUserConfig {
        return {
            budgetStatus: {
                [BudgetStatus.Plan]: {
                    ...stateUserConfig.budgetStatus[BudgetStatus.Plan],
                    ...(get(receivedUserConfig, `budgetStatus[${BudgetStatus.Plan}]`) || {}),
                },
                [BudgetStatus.Execution]: {
                    ...stateUserConfig.budgetStatus[BudgetStatus.Execution],
                    ...(get(receivedUserConfig, `budgetStatus[${BudgetStatus.Execution}]`) || {}),
                },
            },
        };
    }
}

export const budgetUserConfigReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(actions.loadBudgetUserConfig.done, Reducer.loadUserConfig)
    .case(actions.updateBudgetUserConfig.started, Reducer.updateUserConfig);
