import * as React from 'react';

import { Chip, ChipProps } from '@common/components';
import { useGetUserQuery } from '@api';

export interface UserChipProps extends ChipProps {
    userId: number;
}

export function UserChip({ userId, loading, children, ...props }: UserChipProps) {
    const { data: user, isLoading } = useGetUserQuery({ id: userId });

    return (
        <Chip {...props} loading={isLoading || loading}>
            {user?.secondName} <span>{user?.firstName[0]}.</span>
            {children}
        </Chip>
    );
}
