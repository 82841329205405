// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-components-Description-_Description__description{overflow-x:hidden;width:100%;text-overflow:ellipsis;color:#262626;font-size:15px;line-height:1.47;white-space:pre-wrap}.src-client-modules-activity-pages-task-components-Description-_Description__description ul{list-style:disc}.src-client-modules-activity-pages-task-components-Description-_Description__description ul,.src-client-modules-activity-pages-task-components-Description-_Description__description ol{padding-left:20px}.src-client-modules-activity-pages-task-components-Description-_Description__mention{color:#2e80e0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/components/Description/Description.scss"],"names":[],"mappings":"AAAA,yFACI,iBAAkB,CAElB,UAAW,CAEX,sBAAuB,CAEvB,aAAc,CAEd,cAAe,CACf,gBAAiB,CAEjB,oBAAqB,CAZzB,4FAeQ,eAAgB,CAfxB,wLAmBQ,iBAAkB,CACrB,qFAID,aAAc","sourcesContent":[".description {\n    overflow-x: hidden;\n\n    width: 100%;\n\n    text-overflow: ellipsis;\n\n    color: #262626;\n\n    font-size: 15px;\n    line-height: 1.47;\n\n    white-space: pre-wrap;\n\n    ul {\n        list-style: disc;\n    }\n\n    ul, ol {\n        padding-left: 20px;\n    }\n}\n\n.mention {\n    color: #2e80e0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "src-client-modules-activity-pages-task-components-Description-_Description__description",
	"mention": "src-client-modules-activity-pages-task-components-Description-_Description__mention"
};
export default ___CSS_LOADER_EXPORT___;
