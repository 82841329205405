// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-calendar-WeekDigest-TopMenu-WeekSelection-_WeekSelection__root{display:flex;align-items:center}.src-client-modules-calendar-WeekDigest-TopMenu-WeekSelection-_WeekSelection__title{display:flex;width:280px;user-select:none;color:#000000;font-family:'Open Sans';font-size:16px;font-weight:600;line-height:24px;align-items:center;justify-content:center}.src-client-modules-calendar-WeekDigest-TopMenu-WeekSelection-_WeekSelection__button{display:flex;width:24px;height:24px;cursor:pointer;font-size:16px;align-items:center;justify-content:center}\n", "",{"version":3,"sources":["webpack://./src/client/modules/calendar/WeekDigest/TopMenu/WeekSelection/WeekSelection.scss"],"names":[],"mappings":"AAAA,mFACI,YAAa,CAEb,kBAAmB,CACtB,oFAGG,YAAa,CAEb,WAAY,CAEZ,gBAAiB,CAEjB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,eAAgB,CAChB,gBAAiB,CAEjB,kBAAmB,CACnB,sBAAuB,CAC1B,qFAGG,YAAa,CAEb,UAAW,CACX,WAAY,CAEZ,cAAe,CAEf,cAAe,CAEf,kBAAmB,CACnB,sBAAuB","sourcesContent":[".root {\n    display: flex;\n\n    align-items: center;\n}\n\n.title {\n    display: flex;\n\n    width: 280px;\n\n    user-select: none;\n\n    color: #000000;\n\n    font-family: 'Open Sans';\n    font-size: 16px;\n    font-weight: 600;\n    line-height: 24px;\n\n    align-items: center;\n    justify-content: center;\n}\n\n.button {\n    display: flex;\n\n    width: 24px;\n    height: 24px;\n\n    cursor: pointer;\n\n    font-size: 16px;\n\n    align-items: center;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-calendar-WeekDigest-TopMenu-WeekSelection-_WeekSelection__root",
	"title": "src-client-modules-calendar-WeekDigest-TopMenu-WeekSelection-_WeekSelection__title",
	"button": "src-client-modules-calendar-WeekDigest-TopMenu-WeekSelection-_WeekSelection__button"
};
export default ___CSS_LOADER_EXPORT___;
