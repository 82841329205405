import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { SearchResponse } from '@mrm/search';

import { LoadingStatus } from '@store/commonTypes';

import * as actions from './actions/sync';

import {
    SearchState as State,
    EntitiesState,
    ComponentState,
    SearchEntities,
    SetLoadingStatusPayload,
    SetShouldAnimatePayload,
    FileSearchResult,
    ActivitySearchResult,
    TaskSearchResult,
    CommentSearchResult,
} from './types';

class Reducer {
    public static makeInitialState(): State {
        return {
            isVisible: false,
            singleLoadedEntityMode: false,
            searchQuery: '',
            componentState: ComponentState.ShowNoQueryNotification,
            expandedGroup: null,
            animatedGroup: null,
            entities: {
                [SearchEntities.Activities]: Reducer.makeEmptyEntitiesState(),
                [SearchEntities.Tasks]: Reducer.makeEmptyEntitiesState(),
                [SearchEntities.Comments]: Reducer.makeEmptyEntitiesState(),
                [SearchEntities.Files]: Reducer.makeEmptyEntitiesState(),
            },
        };
    }

    public static setVisibility(state: State, isVisible: boolean): State {
        return {
            ...state,
            isVisible,
        };
    }

    public static setSearchQuery(state: State, searchQuery: string): State {
        return {
            ...state,
            searchQuery,
        };
    }

    public static setState(state: State, componentState: ComponentState): State {
        return {
            ...state,
            componentState,
        };
    }

    public static setCollapseStatus(state: State, entitiesType: SearchEntities): State {
        return {
            ...state,
            expandedGroup: entitiesType,
        };
    }

    public static setLoadingStatus(state: State, payload: SetLoadingStatusPayload): State {
        const { entitiesType, status } = payload;

        return {
            ...state,
            entities: {
                ...state.entities,
                [entitiesType]: {
                    ...state.entities[entitiesType],
                    loadingStatus: status,
                },
            },
        };
    }

    public static clearLoadedEntities(state: State): State {
        const initialState = Reducer.makeInitialState();

        return {
            ...state,
            entities: Object.keys(state.entities).reduce(
                (acc, entityType) => ({
                    ...acc,
                    [entityType]: {
                        ...initialState.entities[entityType],
                        fetchersCount: state.entities[entityType].fetchersCount,
                    },
                }),
                {},
            ) as any,
        };
    }

    public static setAnimatedGroup(state: State, animatedGroup: SearchEntities): State {
        return {
            ...state,
            animatedGroup,
        };
    }

    public static setSingleLoadedEntityMode(state: State, singleLoadedEntityMode: boolean): State {
        return {
            ...state,
            singleLoadedEntityMode,
        };
    }

    public static setShouldAnimate(state: State, payload: SetShouldAnimatePayload): State {
        const { entitiesType, shouldAnimate } = payload;

        return Reducer.extendEntitiesStateReducer(state, entitiesType, (state) => ({
            ...state,
            shouldAnimate,
        }));
    }

    public static loadActivities(state: State, payload: SearchResponse<ActivitySearchResult>): State {
        return {
            ...state,
            entities: {
                ...state.entities,
                [SearchEntities.Activities]: Reducer.addLoadedEntitiesReducer<ActivitySearchResult>(
                    state.entities[SearchEntities.Activities],
                    payload,
                ),
            },
        };
    }

    public static loadTasks(state: State, payload: SearchResponse<TaskSearchResult>): State {
        return {
            ...state,
            entities: {
                ...state.entities,
                [SearchEntities.Tasks]: Reducer.addLoadedEntitiesReducer<TaskSearchResult>(
                    state.entities[SearchEntities.Tasks],
                    payload,
                ),
            },
        };
    }

    public static loadComments(state: State, payload: SearchResponse<CommentSearchResult>): State {
        return {
            ...state,
            entities: {
                ...state.entities,
                [SearchEntities.Comments]: Reducer.addLoadedEntitiesReducer<CommentSearchResult>(
                    state.entities[SearchEntities.Comments],
                    payload,
                ),
            },
        };
    }

    public static loadFiles(state: State, payload: SearchResponse<FileSearchResult>): State {
        return {
            ...state,
            entities: {
                ...state.entities,
                [SearchEntities.Files]: Reducer.addLoadedEntitiesReducer<FileSearchResult>(
                    state.entities[SearchEntities.Files],
                    payload,
                ),
            },
        };
    }

    private static makeEmptyEntitiesState<T>(): EntitiesState<T> {
        return {
            loadingStatus: LoadingStatus.NOT_LOADED,
            shouldAnimate: false,
            entities: [] as T[],
            totalCount: 0,
            offset: 0,
        };
    }

    private static addLoadedEntitiesReducer<T>(state: EntitiesState<T>, response: SearchResponse<T>): EntitiesState<T> {
        const { result, total } = response;

        return {
            ...state,
            entities: [...state.entities, ...result],
            totalCount: total,
            offset: state.offset + result.length,
        };
    }

    private static extendEntitiesStateReducer<T extends SearchEntities>(
        state: State,
        entitiesType: T,
        reducer: (state: EntitiesState<T>) => EntitiesState<T>,
    ): State {
        return {
            ...state,
            entities: {
                ...state.entities,
                [entitiesType]: reducer(state.entities[entitiesType] as any as EntitiesState<T>),
            },
        };
    }
}

export const searchReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(actions.setVisibility, Reducer.setVisibility)
    .case(actions.setSearchQuery, Reducer.setSearchQuery)
    .case(actions.setState, Reducer.setState)
    .case(actions.setCollapseStatus, Reducer.setCollapseStatus)
    .case(actions.setLoadingStatus, Reducer.setLoadingStatus)
    .case(actions.clearLoadedEntities, Reducer.clearLoadedEntities)
    .case(actions.setAnimatedGroup, Reducer.setAnimatedGroup)
    .case(actions.setSingleLoadedEntityMode, Reducer.setSingleLoadedEntityMode)
    .case(actions.setShouldAnimate, Reducer.setShouldAnimate)
    .case(actions.resetState, Reducer.makeInitialState)
    .case(actions.loadActivities, Reducer.loadActivities)
    .case(actions.loadTasks, Reducer.loadTasks)
    .case(actions.loadComments, Reducer.loadComments)
    .case(actions.loadFiles, Reducer.loadFiles);
