import * as React from 'react';

import { WithCreateCommentMutation } from './WithCreateCommentMutation';
import { CommentInput } from './CommentInput';

interface Props {
    parrentCommentId?: string;
    usersOrganizationsForMention?: string[];
    onCommentCreated: (commentId: string) => void;
}

export function CreateCommentForm({
    usersOrganizationsForMention,
    parrentCommentId,
    onCommentCreated,
}: Props): JSX.Element {
    return (
        <WithCreateCommentMutation parrentCommentId={parrentCommentId} onCommentCreated={onCommentCreated}>
            {({ createComment }) => (
                <CommentInput
                    usersOrganizationsForMention={usersOrganizationsForMention}
                    createComment={createComment}
                />
            )}
        </WithCreateCommentMutation>
    );
}
