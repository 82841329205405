import * as React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { TaskStatus as ApiTaskStatus } from 'sber-marketing-types/frontend';
import { IconType } from 'sber-marketing-ui';

import { useDeleteTaskMutation, useGetTaskQuery, useSetTaskMutation } from '@api';

import { useDefaultRef } from '@common/hooks';

import { TaskAccessAction, useTaskAccess } from '@modules/task/hooks';

import {
    Button,
    Buttons,
    Divider,
    DropdownKebab,
    DropdownKebabProps,
    DropdownOption,
    Label,
    Option,
} from '@common/components';

import * as styles from './TaskKebabMenu.scss';

export interface TaskKebabMenuProps extends DropdownKebabProps {
    taskId: string;
    activityId?: number;
}

export function TaskKebabMenu({
    children,
    className,
    dropdownProps,
    activityId,
    taskId,
    loading,
    dropdownRef: dropdownRefProp,
    ...props
}: TaskKebabMenuProps) {
    const history = useHistory();
    const dropdownRef = useDefaultRef(dropdownRefProp);
    const deleteDropdownRef = React.useRef<typeof dropdownRef['current']>();
    const [isCopied, setCopied] = React.useState(false);
    const { isLoading: isTaskLoading, data: task } = useGetTaskQuery({ id: taskId });
    const [deleteTask] = useDeleteTaskMutation();
    const [setTask, { isLoading: isUpdating }] = useSetTaskMutation();
    const {
        isLoading: isAccessLoading,
        access: [canDelete, canClose, canReopen],
    } = useTaskAccess([TaskAccessAction.remove, TaskAccessAction.close, TaskAccessAction.reopen], taskId, activityId);
    const isLoading = isAccessLoading || isTaskLoading || loading;

    React.useEffect(() => {
        if (isCopied) {
            const timer = setTimeout(() => {
                setCopied(false);
            }, 3000);

            return () => {
                clearTimeout(timer);
            };
        }

        return () => {};
    }, [isCopied]);

    const handleDeleteTask = () => {
        deleteTask(taskId);

        if (history.location.pathname.includes(taskId)) {
            history.push('/');
        }
    };

    const handleCopyLink = () => {
        navigator.clipboard?.writeText(location.href);
        setCopied(true);
    };

    const handleClose = () => {
        setTask({ id: taskId, status: ApiTaskStatus.Closed });
    };

    const handleReopen = () => {
        setTask({ id: taskId, status: ApiTaskStatus.InProgress });
    };

    const handleCancel = () => {
        deleteDropdownRef.current?.close();
    };

    const secondElements = canDelete && (
        <>
            <Divider />
            <DropdownOption
                dropdownWidth={220}
                dropdownRef={deleteDropdownRef}
                placement="leftTop"
                value="delete"
                title="Удалить"
                icon={IconType.TRASH}
                type="danger"
            >
                <h2>Внимание!</h2>
                <p>Задача будет удалена без возможности ее восстановить</p>
                <Buttons>
                    <Button flex onClick={handleCancel}>
                        Отменить
                    </Button>
                    <Button flex view="danger" onClick={handleDeleteTask}>
                        Удалить
                    </Button>
                </Buttons>
            </DropdownOption>
        </>
    );

    return (
        <DropdownKebab
            dropdownWidth={220}
            dropdownProps={{
                gap: 4,
                ...dropdownProps,
            }}
            iconSize={14}
            placement="bottomRight"
            flat
            ghost
            {...props}
            loading={isLoading}
            dropdownRef={dropdownRef}
            className={classNames(styles.root, className)}
        >
            <Label padding={[4, 12]}>Действия с задачей</Label>
            {canClose && task?.task.status === ApiTaskStatus.InProgress ? (
                <Option
                    loading={isUpdating}
                    onClick={handleClose}
                    value="close"
                    title="Закрыть"
                    icon={IconType.CIRCLE_BOLD_FILLED_CHECK}
                />
            ) : null}
            {canReopen && task?.task.status === ApiTaskStatus.Closed ? (
                <Option
                    loading={isUpdating}
                    onClick={handleReopen}
                    value="reopen"
                    title="Вернуть в работу"
                    icon={IconType.REOPEN}
                />
            ) : null}
            <Option
                onClick={handleCopyLink}
                value="copyLink"
                title={isCopied ? 'Скопировано!' : 'Скопировать ссылку'}
                icon={IconType.CHAIN_FULL}
            />
            {/*<Option value="duplicate" title="Дублировать" icon={IconType.HANGING_PLUS_SIDE} />*/}
            {secondElements}
            {children}
        </DropdownKebab>
    );
}
