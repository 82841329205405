import createCashedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { OrganizationView } from 'sber-marketing-types/backend';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';

import { OrganizationsState, StoreTypes } from './types';

export const getOrganizationsState = (state: StoreState): OrganizationsState => state.organizations;

export const getCommonLoadingStatus = createSelector(
    getOrganizationsState,
    (state: OrganizationsState): LoadingStatus => state.loadingStatus,
);

export const getOrganizationById = createCashedSelector(
    getOrganizationsState,
    (state: StoreState, id: string): string => id,
    (state: OrganizationsState, id: string): OrganizationView => state.byIds[id],
)((state: StoreState, id: string): string => id);

export const getAllLoadedOrganizations = createSelector(
    getOrganizationsState,
    (state: OrganizationsState): OrganizationView[] => state.entities,
);

export const getOrganizations = createCashedSelector(
    getOrganizationsState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: OrganizationsState, store: StoreTypes): OrganizationView[] => {
        const entitiesStore = state.stores[store];

        if (entitiesStore.loadingStatus === LoadingStatus.LOADED) {
            return state.stores[store].ids.map((id) => state.byIds[id]);
        }

        return [];
    },
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getOrganizationIds = createCashedSelector(
    getOrganizationsState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: OrganizationsState, store: StoreTypes): string[] => state.stores[store].ids,
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getLoadingStatus = createCashedSelector(
    getOrganizationsState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: OrganizationsState, store: StoreTypes): LoadingStatus => state.stores[store].loadingStatus,
)((state: StoreState, store: StoreTypes): StoreTypes => store);
