import * as React from 'react';
import { MrmClient } from '@api';
import type { Project as ProjectDomain } from '@api';

interface Props extends ExternalProps {}

interface ExternalProps {
    projectId: number;
    children: (props: ChildrenProps) => JSX.Element;
}

export interface ChildrenProps {
    loading: boolean;
    addBudgetItemId: AddBudgetItemId;
    removeBudgetItemId: RemoveBudgetItemId;
}

interface AddBudgetItemId {
    (budgetItemId: string): Promise<void>;
}

interface RemoveBudgetItemId extends AddBudgetItemId {}

export const WithProjectDomain: React.FC<Props> = ({ children, projectId }) => {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [projectDomain, setProjectDomain] = React.useState<ProjectDomain>(null);

    React.useEffect(() => {
        if (projectId) {
            MrmClient.getInstance()
                .then((client) => {
                    setLoading(true);
                    return client.domain.projects.getProject({ id: projectId });
                })
                .then((projectDomain) => {
                    setProjectDomain(projectDomain);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [projectId]);

    const addBudgetItemId: AddBudgetItemId = React.useCallback(
        async (budgetItemId) => {
            await projectDomain.model.addBudgetItem({ budgetItemId });
        },
        [projectDomain],
    );

    const removeBudgetItemId: RemoveBudgetItemId = React.useCallback(
        async (budgetItemId) => {
            await projectDomain.model.removeBudgetItem({ budgetItemId });
        },
        [projectDomain],
    );

    return children({
        loading,
        addBudgetItemId,
        removeBudgetItemId,
    });
};
