import { Moment } from 'moment';
import { TaskStatus, UserCard, WorkTypeParams } from 'sber-marketing-types/frontend';
import { TaskCardParams, TaskCardType, UpdateSavedTaskCardParams } from '@store/tasksList/types';
import { Stage } from '@store/activityTasksPage/stages';
import { Permissions } from '@store/taskEditor2';

import { NewsStyle } from '../lib';

export { TaskStatus };

export interface TaskCardStoreProps {
    id: string;
    from?: string;
    task?: TaskCardParams;
    displayActivityName?: boolean;
    activityStages?: Stage[];
    isOpenedInSidebar: boolean;
    onTaskDelete?(id?: string): void | Promise<void>;
    onTaskUpdate?(id: string, params: Partial<TaskCardParams>): void;
    reloadStagesData?(): void;
    openTaskSidebar(taskId: string): void;
}

export interface TaskCardMappedState {
    task: TaskCardParams;
    taskPermissions: Permissions;
    cardType: TaskCardType;
    appUsers: UserCard[];
    taskWorkType?: WorkTypeParams;
}

export interface TaskCardMappedActions {
    deleteTask(): void | Promise<void>;
    updateTask(): void | Promise<void>;
    updateSavedTask(params: UpdateSavedTaskCardParams): void;
    fillActivityTasks(activityId: number): void;
}

export interface TaskCardContainerProps extends TaskCardMappedActions {
    id: string;
    taskWorkType?: WorkTypeParams;
    change?: NewsStyle;
    link?: string;
    task: TaskCardParams & { workTypeName?: string };
    displayActivityName?: boolean;
    cardType: TaskCardType;
    activityStages?: Stage[];
    appUsers: UserCard[];
    taskPermissions: Permissions;
    isOpenedInSidebar: boolean;
    onTaskUpdate?(id: string, params: Partial<TaskCardParams>): void;
    reloadStagesData?(): void;
    openTaskSidebar(taskId: string): void;
}

export interface TaskCardContainerState {
    isTaskEditorOpen: boolean;
    isDeleteConfirmationOpened: boolean;
    showTaskEditorForEnteringRequiredFields: boolean;
    // isSharingPopupOpened: boolean;
}

export interface TaskCardTemplateProps extends TaskCardContainerState {
    id: string;
    canEdit: boolean;
    canDisplayRate: boolean;
    taskPermissions: Permissions;
    change?: NewsStyle;
    link?: string;
    department: string;
    workTypeName: string;
    authorDepartment: string;
    activityId: number;
    activity: {
        name: string;
        organizationName: string;
        divisionName: string;
        productName: string;
        blockName: string;
    };
    deadline: Moment;
    title: string;
    description?: string;
    budgetApproval: TaskCardParams['budgetApproval'];
    status: TaskStatus;
    indicators: JSX.Element[];
    buttons: JSX.Element[];
    author: {
        firstName: string;
        secondName: string;
        organizationName: string;
        departmentName: string;
    };
    participantIds: number[];
    isLoading: boolean;
    displayActivityName: boolean;
    cardType: TaskCardType;
    stage: string;
    rate: number;
    activityStages?: Stage[];
    showTaskEditorForEnteringRequiredFields: boolean;
    isOpenedInSidebar: boolean;
    // isSharingPopupOpened: boolean;
    onTaskEditorPopupClose(): void;
    // onSharingPopupClose(): void;
    // onSharingPopupButtonClick(): void;
    onTaskEditorPopupCreated(): Promise<void> | void;
    closeDeletionConfirmation(): void;
    deleteTask(): void;
    editDeadline(deadline: Moment): Promise<void>;
    editStatus(status: TaskStatus): Promise<void>;
    editStage(stageId: string): Promise<void>;
    closeStatusChangeErrorPopup(): void;
    onRootClick(): void;
    onCardClick(): void;
}
