import * as React from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import * as style from './Link.scss';

interface Props {
    children: string;
    url: string;
}

export const Link: React.FC<Props> = ({ url, children }) => {
    return (
        <ReactRouterDomLink to={url} target="_blank" className={style.root}>
            {children}
        </ReactRouterDomLink>
    );
};
