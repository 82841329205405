import * as React from 'react';
import classNames from 'classnames';

import * as style from './ActivitiesInfo.scss';

interface Props {
    activityCardsCount: number;
    weekExpiredStagesActivities: number;
    expiredStagesFilter: boolean;
    onWeekExpiredStagesClick: () => void;
}

export function ActivitiesInfo({
    activityCardsCount,
    weekExpiredStagesActivities,
    expiredStagesFilter,
    onWeekExpiredStagesClick,
}: Props): JSX.Element {
    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'activitiesInfo',
            }}
        >
            <div className={style.groupTitle}>Проекты выбранной недели:</div>

            <div className={style.items}>
                <div
                    className={classNames(style.item, style.totalCount)}
                    {...{
                        'qa-id': 'activitiesInfoWeekActivities',
                    }}
                >
                    <div className={style.itemIcon}>
                        <img srcSet="/static/images/calendar32@2x.png 2x" src="/static/images/calendar32.png" />
                    </div>

                    <div className={style.itemTitle}>Все проекты:</div>

                    <div
                        className={style.сounter}
                        {...{
                            'qa-id': 'activitiesInfoWeekActivitiesCount',
                        }}
                    >
                        {activityCardsCount}
                    </div>
                </div>

                {weekExpiredStagesActivities ? (
                    <div
                        className={classNames(
                            style.item,
                            style.expiredStages,
                            style.clickable,
                            expiredStagesFilter && style.toggled,
                        )}
                        onClick={onWeekExpiredStagesClick}
                        {...{
                            'qa-id': 'activitiesInfoWeekExpiredStages',
                        }}
                    >
                        <div className={style.itemIcon}>
                            <img srcSet="/static/images/flame32@2x.png 2x" src="/static/images/flame32.png" />
                        </div>

                        <div className={style.itemTitle}>С просроченными этапами:</div>

                        <div
                            className={style.сounter}
                            {...{
                                'qa-id': 'activitiesInfoWeekExpiredStagesCount',
                            }}
                        >
                            {weekExpiredStagesActivities}
                        </div>
                    </div>
                ) : (
                    <div
                        className={classNames(
                            style.item,
                            style.noExpiredStages,
                            style.clickable,
                            expiredStagesFilter && style.toggled,
                        )}
                        onClick={onWeekExpiredStagesClick}
                        {...{
                            'qa-id': 'activitiesInfoWeekExpiredStages',
                        }}
                    >
                        <div className={style.itemTitle}>Нет просроченных этапов</div>

                        <div className={style.itemIcon}>
                            <img srcSet="/static/images/thumb32@2x.png 2x" src="/static/images/thumb32.png" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
