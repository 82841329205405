import * as React from 'react';
import autobind from 'autobind-decorator';
import { DataProps, graphql } from 'react-apollo';
import { groupBy, flatMap, sortBy } from 'lodash';

import { GET_PROJECT_LINKED_BUDGET_ITEMS } from './query';

export interface ChildrenProps {
    loading: boolean;
    linkIds: string[];
    refetch?: () => void;
}

interface Props extends ExternalProps, QueryProps {}

interface ExternalProps {
    activityId: number;
    children?: (props: ChildrenProps) => JSX.Element;
    forwardData?: (props: ChildrenProps) => void;
}

type QueryProps = DataProps<{
    approvedLinks: {
        nodes: {
            budgetItems: {
                id: string;
            }[];
        }[];
    };
    allLinks: {
        nodes: {
            id: string;
            status: 'pending' | 'approved' | 'rejected';
            budgetItem: {
                id: string;
            };
        }[];
    };
}>;

interface State {
    refetchInProgress: boolean;
}

class WithProjectLinkedBudgetItemsConnected extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            refetchInProgress: false,
        };
    }

    public componentDidUpdate(prevProps: Props): void {
        const needToForwardData =
            this.props.forwardData &&
            (this.state.refetchInProgress || (!this.props.data?.loading && prevProps.data?.loading));

        if (needToForwardData) {
            this.setState(
                {
                    refetchInProgress: false,
                },
                () => {
                    const loading = this.getLoadingStatus();
                    const linkIds = this.getLinkIds();
                    const refetch = this.refetch;

                    this.props.forwardData({ loading, linkIds, refetch });
                },
            );
        }
    }

    public render(): JSX.Element {
        const { children } = this.props;

        return children
            ? children({
                  refetch: this.refetch,
                  loading: this.getLoadingStatus(),
                  linkIds: this.getLinkIds(),
              })
            : null;
    }

    @autobind
    private refetch(): void {
        if (this.props.data) {
            this.setState(
                {
                    refetchInProgress: true,
                },
                () => this.props.data.refetch(),
            );
        }
    }

    private getLoadingStatus(): boolean {
        return this.props.data ? this.props.data.loading : false;
    }

    private getLinkIds(): string[] {
        if (!this.props.data) {
            return [];
        }

        const { approvedLinks: receivedApprovedLinks, allLinks: receivedAllLinks } = this.props.data;

        if (!receivedApprovedLinks || !receivedAllLinks) {
            return [];
        }

        const approvedLinks = receivedApprovedLinks.nodes[0];
        const linkApprovalRequests = receivedAllLinks.nodes;

        if (!approvedLinks || !approvedLinks.budgetItems) {
            return [];
        }

        const linkedBudgetItemIds = approvedLinks.budgetItems.map((budgetItem) => budgetItem.id);

        const requestsGroupedByBudgetItemId = groupBy(linkApprovalRequests, (request) => request.budgetItem.id);
        const actualRequests = flatMap(
            requestsGroupedByBudgetItemId,
            (groupedRequests) => sortBy(groupedRequests, 'updatedAt')[0],
        );

        const res = actualRequests
            .filter((request) => linkedBudgetItemIds.includes(request.budgetItem.id))
            .map((request) => request.id);

        return res;
    }
}

export const WithProjectLinkedBudgetItems = graphql<ExternalProps>(GET_PROJECT_LINKED_BUDGET_ITEMS, {
    options: ({ activityId }) => ({
        variables: {
            projectId: activityId,
        },
        fetchPolicy: 'no-cache',
    }),
    skip: ({ activityId }) => !activityId,
})(WithProjectLinkedBudgetItemsConnected);
