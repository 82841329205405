import * as React from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { WithTooltip } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getBudgetExecutionPageState } from '@store/budgetExecution';
import {
    getTransferBudgetItemsToPlanningMenuState,
    isHoveredLineClickable,
    setLinesToTransfer,
} from '@store/budgetExecution/transferBudgetItemsToPlanningMenu';

import { AnimatedInProgressIcon } from '@common/AnimatedInProgressIcon';

import { Line } from './Line';

import * as styles from './LinesDescriptions.scss';

function useStore() {
    const linesToTransfer = useSelector(
        (state: StoreState) => getTransferBudgetItemsToPlanningMenuState(state).props.linesToTransfer,
    );
    const renderNoLinesToTransferMarker = useSelector((state: StoreState) => {
        const {
            data: {
                activityBudgetItems: { loadingStatus, items },
            },
            props: { activityToSelectLines },
        } = getTransferBudgetItemsToPlanningMenuState(state);

        return loadingStatus === LoadingStatus.LOADED && activityToSelectLines && !items.length;
    });

    return {
        renderNoLinesToTransferMarker,
        linesToTransfer,
    };
}

export function LinesDescriptions(): JSX.Element {
    const { renderNoLinesToTransferMarker, linesToTransfer } = useStore();

    return (
        <div className={styles.root}>
            <Title />

            {renderNoLinesToTransferMarker ? (
                <NoLinesToTransferMarker />
            ) : (
                <React.Fragment>
                    <HoveredLine />

                    {linesToTransfer.map((lineId) => (
                        <Line key={lineId} lineId={lineId} wrapperClass={styles.itemPadding} />
                    ))}
                </React.Fragment>
            )}
        </div>
    );
}

function NoLinesToTransferMarker() {
    return <ErrorLineStub content="В данной активности отсутствуют строки, пригодные для переноса" />;
}

function HoveredLine(): JSX.Element {
    const hoveredLineId = useSelector(
        (state: StoreState) => getTransferBudgetItemsToPlanningMenuState(state).props.hoveredLineId,
    );
    const hoveredBudgetItem = useSelector((state: StoreState) =>
        getBudgetExecutionPageState(state).computedData.pageBudgetItems.find(
            (budgetItem) => budgetItem.id === hoveredLineId,
        ),
    );

    const hoveredLineIsClickable = useSelector((state: StoreState) => isHoveredLineClickable(state));
    const hoveredLineIsSelected = useSelector((state: StoreState) => {
        const { linesToTransfer, hoveredLineId } = getTransferBudgetItemsToPlanningMenuState(state).props;

        return linesToTransfer.includes(hoveredLineId);
    });
    const allLinesAreSelected = useSelector((state: StoreState) => {
        const {
            props: { linesToTransfer },
            data: { activityBudgetItems },
        } = getTransferBudgetItemsToPlanningMenuState(state);

        return linesToTransfer.length === activityBudgetItems.items.length;
    });

    if (hoveredLineId && !hoveredLineIsClickable) {
        if (hoveredBudgetItem?.childrenBudgetItems?.length) {
            return <ErrorLineStub content="На основе данной строки уже созданы строки планирования" />;
        } else {
            return <ErrorLineStub content="Данная строка относится к другой активности" />;
        }
    } else if (!allLinesAreSelected) {
        if (!hoveredLineId) {
            return (
                <div className={classNames(styles.itemPadding, styles.lineStub)}>
                    Выберите строки одной активности для переноса в Исполнение
                </div>
            );
        } else if (!hoveredLineIsSelected) {
            return <Line lineIsHovered wrapperClass={styles.itemPadding} lineId={hoveredLineId} />;
        }
    }

    return null;
}

function useTitleInteractivity() {
    const dispatch = useDispatch();

    const selectedBudgetItems = useSelector(
        (state: StoreState) => getTransferBudgetItemsToPlanningMenuState(state).props.linesToTransfer,
    );
    const activityBudgetItems = useSelector(
        (state: StoreState) => getTransferBudgetItemsToPlanningMenuState(state).data.activityBudgetItems.items,
    );
    const isLoading = useSelector(
        (state: StoreState) =>
            getTransferBudgetItemsToPlanningMenuState(state).data.activityBudgetItems.loadingStatus ===
            LoadingStatus.LOADING,
    );

    function selectAll() {
        dispatch(setLinesToTransfer(activityBudgetItems.map((budgetItem) => budgetItem.id)));
    }

    return {
        isLoading,
        selectedBudgetItems,
        activityBudgetItems,
        selectAll,
    };
}

function Title(): JSX.Element {
    const { isLoading, selectedBudgetItems, activityBudgetItems, selectAll } = useTitleInteractivity();

    const showCounter = !isLoading && !!activityBudgetItems.length;

    const showSelectAllButton = !isLoading && selectedBudgetItems.length !== activityBudgetItems.length;

    return (
        <div className={styles.titleWrapper}>
            <div className={styles.title}>Строки Исполнения</div>

            {isLoading && (
                <div className={styles.animatedInProgressIcon}>
                    <AnimatedInProgressIcon />
                </div>
            )}

            {showCounter && (
                <WithTooltip content="Выбрано строк из данной активности">
                    <div className={styles.counter}>
                        {selectedBudgetItems.length}/{activityBudgetItems.length}
                    </div>
                </WithTooltip>
            )}

            {showSelectAllButton && (
                <div className={styles.selectAllButton} onClick={selectAll}>
                    Выбрать все
                </div>
            )}
        </div>
    );
}

interface ErrorLineStubProps {
    content: string;
}

function ErrorLineStub({ content }: ErrorLineStubProps): JSX.Element {
    return <div className={classNames(styles.itemPadding, styles.lineStub, styles.lineStubError)}>{content}</div>;
}
