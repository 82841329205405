import { reducerWithInitialState } from 'typescript-fsa-reducers';

import type { CreativeRequestContract } from '@api';
import type { PageState } from './types';
import * as actions from './actions';

export const initialState: PageState = {
    contracts: {
        lot1: [],
        lot2: [],
    },
    selectedLot1ContractId: undefined,
    selectedLot2ContractId: undefined,
    totalTableExpanded: false,
};

export class Reducer {
    public static loadContracts(
        state: PageState,
        payload: Record<'lot1' | 'lot2', CreativeRequestContract[]>,
    ): PageState {
        return { ...state, contracts: payload };
    }

    public static setSelectedLot1ContractId(state: PageState, payload: string): PageState {
        return { ...state, selectedLot1ContractId: payload };
    }

    public static setSelectedLot2ContractId(state: PageState, payload: string): PageState {
        return { ...state, selectedLot2ContractId: payload };
    }

    public static setTotalTableExpandedStatus(state: PageState, payload: boolean): PageState {
        return { ...state, totalTableExpanded: payload };
    }
}

export const pivotTableReducer = reducerWithInitialState(initialState)
    .case(actions.loadContracts, Reducer.loadContracts)
    .case(actions.setSelectedLot1ContractId, Reducer.setSelectedLot1ContractId)
    .case(actions.setSelectedLot2ContractId, Reducer.setSelectedLot2ContractId)
    .case(actions.setTotalTableExpandedStatus, Reducer.setTotalTableExpandedStatus);
