import { Success, Failure } from 'typescript-fsa';

import { RemoveFileFromTaskPayload, FetchError, TaskPageState } from '../types';

export const removeFileFromTaskStartedReducer = (
    state: TaskPageState,
    payload: RemoveFileFromTaskPayload,
): TaskPageState => {
    return state;
};

export const removeFileFromTaskErrorReducer = (
    state: TaskPageState,
    payload: Failure<RemoveFileFromTaskPayload, FetchError>,
): TaskPageState => {
    return state;
};

export const removeFileFromTaskSuccessReducer = (
    { assetsIds: prevAssetsIds, ...restState }: TaskPageState,
    { params: { fileName } }: Success<RemoveFileFromTaskPayload, void>,
): TaskPageState => {
    const assetsIds = prevAssetsIds.filter((id) => id !== fileName);
    return {
        ...restState,
        assetsIds,
    };
};
