import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import { getLoading } from '@store/leaderDashboardPage';

interface UseState {
    (): UseStateReturn;
}

interface UseStateReturn extends Selected {}

interface Selected {
    loading: boolean;
}

export const useStore: UseState = () => {
    const { loading } = useSelector<StoreState, Selected>((state) => ({ loading: getLoading(state) }));

    return { loading };
};
