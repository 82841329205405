import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconType, WithTooltip } from 'sber-marketing-ui';

import * as style from './CardsViewSwitch.scss';

import { TaskCardType } from '@store/tasksList/types';

interface Props {
    cardType: TaskCardType;
    onSelection: (cardType: TaskCardType) => void;
}

export const CardsViewSwitch = ({ cardType, onSelection }: Props): JSX.Element => {
    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'cardsViewSwitch',
            }}
        >
            <div
                className={classNames(style.button, cardType == TaskCardType.SHORT && style.selected)}
                onClick={() => onSelection(TaskCardType.SHORT)}
                {...{
                    'qa-id': 'cardsViewSwitchItem',
                    'qa-is-selected': cardType == TaskCardType.SHORT ? 'true' : 'false',
                }}
            >
                <WithTooltip content="Компактный вид">
                    <Icon type={IconType.LIST24} svgSize={24} />
                </WithTooltip>
            </div>

            <div
                className={classNames(style.button, cardType == TaskCardType.NORMAL && style.selected)}
                onClick={() => onSelection(TaskCardType.NORMAL)}
                {...{
                    'qa-id': 'cardsViewSwitchItem',
                    'qa-is-selected': cardType == TaskCardType.NORMAL ? 'true' : 'false',
                }}
            >
                <WithTooltip content="Обычный вид">
                    <Icon type={IconType.CARDS24} svgSize={24} />
                </WithTooltip>
            </div>
        </div>
    );
};
