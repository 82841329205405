import * as lodash from 'lodash';
import autobind from 'autobind-decorator';

import type { Activity } from '@mrm/activity';
import type {
    GroupedActivities,
    OrganizationGroups,
    LinesGroup,
    LineParams,
    ActivityLineParams,
    TaskLineParams,
    Stage,
} from '@store/calendar/types';
import { LineType } from '@store/calendar/types';

import { store } from '@store';
import {
    getCalendarPageState,
    getFilters,
    getActivitiesGroupedByCalendarGroup,
    getFilteredActivities,
    getExpiredActivityStages,
} from '@store/calendar/selectors';
import { GroupsMaker, Scroller, Scaler } from './';

type ListenerRecord = { id: string; callback: (value: OrganizationGroups[]) => void };

interface StoreProps {
    groupedActivities: GroupedActivities;
    filteredActivities: Activity[];
    selectedOrganizationIds: string[];
    expandedGroupsIds: string[];
    expandedActivitiesIds: number[];
    expiredStagesFilter: boolean;
    expiredActivityStages: Stage[];
}

export class GroupsFilter {
    private static instance: GroupsFilter;
    public filteredGroups: OrganizationGroups[];
    private oldStoreProps: StoreProps;
    private unsubscribeStore: () => void;
    private groupsMaker: GroupsMaker;
    private scroller: Scroller;
    private scaler: Scaler;
    private currentValue: OrganizationGroups[];
    private registrationList: ListenerRecord[] = [];

    private constructor() {
        this.groupsMaker = GroupsMaker.getInstance();
        this.scroller = Scroller.getInstance();
        this.scaler = Scaler.getInstance();

        this.scroller.register('groupsFilter', this.onScrollerEmit);

        this.unsubscribeStore = store.subscribe(this.onStoreUpdate);
        this.oldStoreProps = this.getStoreProps();
    }

    public static getInstance(): GroupsFilter {
        if (!GroupsFilter.instance) {
            GroupsFilter.instance = new GroupsFilter();
        }
        return GroupsFilter.instance;
    }

    public dispose() {
        GroupsFilter.instance = null;

        this.groupsMaker.dispose();

        this.groupsMaker = null;
        this.scroller = null;
        this.scaler = null;

        this.currentValue = null;
        this.oldStoreProps = null;
        this.registrationList = [];

        this.unsubscribeStore();
    }

    public register(id: string, callback: (value: OrganizationGroups[]) => void) {
        const alreadyRegistered = this.registrationList.some((item) => item.id == id);

        if (alreadyRegistered) {
            this.unregister(id);
        }

        this.registrationList.push({ id, callback });
    }

    public unregister(id: string) {
        this.registrationList = this.registrationList.filter((item) => item.id !== id);
    }

    public setValue(id: string, value: OrganizationGroups[]) {
        if (value !== this.currentValue) {
            this.currentValue = value;

            this.registrationList.filter((item) => item.id !== id).forEach((item) => item.callback(value));
        }
    }

    public getFilteredGroups(): OrganizationGroups[] {
        if (!this.filteredGroups) {
            this.filteredGroups = this.makeFilteredGroups();
        }

        return this.filteredGroups;
    }

    @autobind
    private onStoreUpdate() {
        const newStoreProps = this.getStoreProps();

        const storePropsChanged = !lodash.isEqual(newStoreProps, this.oldStoreProps);

        if (storePropsChanged) {
            this.onStorePropsChange();

            this.oldStoreProps = newStoreProps;
        }
    }

    @autobind
    private onStorePropsChange() {
        this.updateFilteredGroups();
    }

    @autobind
    private onScrollerEmit() {
        this.updateFilteredGroups();
    }

    private updateFilteredGroups() {
        const newFilteredGroups = this.makeFilteredGroups();

        const filteredGroupsChanged = !lodash.isEqual(newFilteredGroups, this.filteredGroups);

        if (filteredGroupsChanged) {
            this.filteredGroups = newFilteredGroups;

            this.setValue('groupsFilter', newFilteredGroups);
        }
    }

    private makeFilteredGroups(): OrganizationGroups[] {
        const { selectedOrganizationIds } = this.getStoreProps();

        const allGroups = this.groupsMaker.getGroups();

        let filteredOrganizationGroups = lodash.isEmpty(selectedOrganizationIds)
            ? allGroups
            : allGroups.filter((item) => lodash.includes(selectedOrganizationIds, item.id));

        filteredOrganizationGroups = filteredOrganizationGroups.map((organizationGroups) => {
            let filteredGroups = this.filterLinesGroupsByFilters(organizationGroups.groups);

            filteredGroups = this.filterLinesGroupsByExpansion(filteredGroups);

            return { ...organizationGroups, groups: filteredGroups };
        });

        filteredOrganizationGroups = filteredOrganizationGroups.filter((item) => !lodash.isEmpty(item.groups));

        return filteredOrganizationGroups;
    }

    private filterLinesGroupsByFilters(groups: LinesGroup[]): LinesGroup[] {
        const { filteredActivities, expiredStagesFilter, expiredActivityStages } = this.getStoreProps();

        const filteredGroups: LinesGroup[] = [];

        let lastSeenActivityIsFiltered: boolean;

        groups.forEach((group) => {
            const filteredLines: LineParams[] = [];

            group.lines.forEach((line) => {
                switch (line.type) {
                    case LineType.GroupTitle:
                        filteredLines.push(line);
                        break;

                    case LineType.Activity:
                        const activityIsVisible =
                            filteredActivities.some((item) => item.id == line.id) &&
                            (!expiredStagesFilter || expiredActivityStages.some((item) => item.activityId == line.id));

                        if (activityIsVisible) {
                            filteredLines.push(line);
                            lastSeenActivityIsFiltered = false;
                        } else {
                            lastSeenActivityIsFiltered = true;
                        }
                        break;

                    case LineType.Task:
                        if (!lastSeenActivityIsFiltered) {
                            filteredLines.push(line);
                        }
                        break;
                }
            });

            if (filteredLines.length > 1) {
                filteredGroups.push({
                    ...group,
                    lines: filteredLines,
                });
            }
        });

        return filteredGroups;
    }

    private filterLinesGroupsByExpansion(groups: LinesGroup[]): LinesGroup[] {
        const { expandedGroupsIds } = this.getStoreProps();

        const filteredGroups: LinesGroup[] = [];

        groups.forEach((group) => {
            const groupIsExpanded = lodash.includes(expandedGroupsIds, group.id);

            let filteredLines: LineParams[] = [lodash.first(group.lines)];

            if (groupIsExpanded) {
                const viewportPosition = this.scaler.getViewportPositionInDays();

                filteredLines = this.filterGroupLinesByExpantion(group.lines);
                filteredLines = this.filterGroupLinesByDates(filteredLines, viewportPosition);
            } else {
                filteredLines = [lodash.first(group.lines)];
            }

            filteredGroups.push({
                ...group,
                lines: filteredLines,
                isExpanded: groupIsExpanded,
            });
        });

        return filteredGroups;
    }

    private filterGroupLinesByExpantion(lines: LineParams[]): LineParams[] {
        const updatedLines = this.addExpansionStatus(lines);

        const filteredLines: LineParams[] = [];

        let lastSeenActivityLine: ActivityLineParams;

        updatedLines.forEach((line) => {
            switch (line.type) {
                case LineType.GroupTitle:
                    filteredLines.push(line);
                    break;

                case LineType.Activity:
                    filteredLines.push(line);
                    lastSeenActivityLine = line as ActivityLineParams;
                    break;

                case LineType.Task:
                    if (lastSeenActivityLine.isExpanded) {
                        filteredLines.push(line);
                    }
                    break;
            }
        });

        return filteredLines;
    }

    private addExpansionStatus(lines: LineParams[]): LineParams[] {
        const { expandedActivitiesIds } = this.getStoreProps();

        return lines.map((line) => {
            const activityIsExpanded = lodash.includes(expandedActivitiesIds, line.id);

            return activityIsExpanded ? { ...line, isExpanded: true } : line;
        });
    }

    private filterGroupLinesByDates(
        lines: LineParams[],
        viewportPosition: { start: number; end: number },
    ): LineParams[] {
        const { start: viewportStart, end: viewportEnd } = viewportPosition;

        const visibleLines: LineParams[] = [];

        let lastNonvisibleActivityLine: LineParams;

        lines.forEach((line) => {
            let lineIsVisible: boolean;

            switch (line.type) {
                case LineType.GroupTitle:
                    visibleLines.push(line);
                    break;

                case LineType.Activity:
                    const { preparationStart, realizationStart, realizationEnd, debriefingEnd } =
                        line as ActivityLineParams;

                    const activityStart = preparationStart || realizationStart;
                    const activityEnd = debriefingEnd || realizationEnd;

                    lineIsVisible = activityStart < viewportEnd && activityEnd > Math.ceil(viewportStart);

                    if (lineIsVisible) {
                        visibleLines.push(line);
                        lastNonvisibleActivityLine = null;
                    } else {
                        lastNonvisibleActivityLine = line;
                    }

                    break;

                case LineType.Task:
                    const { deadline } = line as TaskLineParams;

                    lineIsVisible = viewportStart <= deadline && deadline <= viewportEnd;

                    if (lineIsVisible) {
                        if (lastNonvisibleActivityLine) {
                            visibleLines.push(lastNonvisibleActivityLine);

                            lastNonvisibleActivityLine = null;
                        }

                        visibleLines.push(line);
                    }

                    break;
            }
        });

        return visibleLines;
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        const { expandedGroupsIds, expandedActivitiesIds } = getCalendarPageState(storeState);
        const { organizationIds, expiredStagesFilter } = getFilters(storeState);
        const filteredActivities = getFilteredActivities(storeState);

        return {
            groupedActivities: getActivitiesGroupedByCalendarGroup(storeState),
            filteredActivities,
            selectedOrganizationIds: organizationIds,
            expandedGroupsIds,
            expandedActivitiesIds,
            expiredStagesFilter,
            expiredActivityStages: getExpiredActivityStages(storeState),
        };
    }
}
