import { PureComponent, createElement } from 'react';
import { AuthErrorPage, AuthErrorPageProps } from 'sber-marketing-ui';
import { parse } from 'query-string';
import { isNil } from 'lodash';

import { AuthApi } from '../../../api';
import { AuthErrorPageContainerProps } from './types';

/** "AuthErrorPage" container component */
export class AuthErrorPageContainer extends PureComponent<AuthErrorPageContainerProps> {
    public render() {
        return createElement(AuthErrorPage, this.getRenderProps());
    }

    public async componentDidMount() {
        const { userFirstName, userSecondName, loadUser } = this.props;
        if (isNil(userFirstName) || isNil(userSecondName)) {
            try {
                const { user } = await AuthApi.getAuthorizedUser();
                loadUser(user);
            } catch (err) {}
        }
    }

    private getRenderProps(): AuthErrorPageProps {
        const {
            userFirstName,
            userSecondName,
            location: { search },
        } = this.props;
        return {
            userFirstName,
            userSecondName,
            type: <'not-authorized' | 'no-access' | 'other'>parse(search).type,
            onLogoutClick: AuthApi.logout,
        };
    }
}
