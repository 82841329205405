import * as React from 'react';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { OrganizationView } from 'sber-marketing-types/backend';
import { Budget, BudgetStatus } from '@mrm/budget';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getOrganizationById } from '@store/organizations';
import { getBudgetUserConfigState, getBudgetByStatusUserConfig } from '@store/userConfig/budget';
import { getBudgetState, loadBudgets } from '@store/budgetPage';

import { Utils } from '@common/Utils';

import { BudgetAndOrganizationSelector } from './BudgetAndOrganizationSelector';

interface Props extends OwnProps, Partial<MapProps & DispatchProps> {}

interface OwnProps {
    budgetStatus: BudgetStatus;
}

interface MapProps {
    budgets: Budget[];
    selectedBudgetId: string;
    selectedOrganization: OrganizationView;
    userConfigLoadingStatus: LoadingStatus;
    budgetsLoadingStatus: LoadingStatus;
}

interface DispatchProps {
    loadBudgets: (budget: BudgetStatus) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BudgetAndOrganizationSelectorBehaviour extends React.PureComponent<Props> {
    public componentDidUpdate(): void {
        const userConfigLoaded = this.props.userConfigLoadingStatus === LoadingStatus.LOADED;
        const budgetsNotLoaded = this.props.budgetsLoadingStatus === LoadingStatus.NOT_LOADED;

        if (userConfigLoaded && budgetsNotLoaded) {
            this.props.loadBudgets(this.props.budgetStatus);
        }
    }

    public render(): JSX.Element {
        const { budgets, selectedBudgetId, budgetStatus, selectedOrganization } = this.props;

        return (
            <BudgetAndOrganizationSelector
                budgets={budgets}
                budgetStatus={budgetStatus}
                selectedBudgetId={selectedBudgetId}
                selectedOrganization={selectedOrganization}
            />
        );
    }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): MapProps {
    const { budgets, loadingStatus: budgetsLoadingStatus } = getBudgetState(state, ownProps.budgetStatus);
    const { status: userConfigLoadingStatus } = getBudgetUserConfigState(state);
    const { budgetId: selectedBudgetId } = getBudgetByStatusUserConfig(state, ownProps.budgetStatus);

    const selectedOrganization = Utils.withErrorHandler<OrganizationView>(() =>
        getOrganizationById(state, budgets.find((budget) => budget.id === selectedBudgetId).dictionaryOrganizationId),
    );

    return {
        budgets,
        selectedBudgetId,
        userConfigLoadingStatus,
        budgetsLoadingStatus,
        selectedOrganization,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        loadBudgets: (budgetStatus: BudgetStatus) => dispatch(loadBudgets(budgetStatus)),
    };
}
