import * as React from 'react';
import autobind from 'autobind-decorator';

import { FileApi } from '@api';

import { FileAssetProps } from '@common/FileAssetRedesign';

import { AttachedFile } from './AttachedFile';

interface Props extends FileAssetProps {
    originName: string;
    size: number;
}

interface State {
    isMouseOver: boolean;
    showDownloadButton: boolean;
}

export class AttachedFileBehaviour extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isMouseOver: false,
            showDownloadButton: false,
        };
    }

    public render(): JSX.Element {
        return (
            <AttachedFile
                {...this.props}
                {...this.state}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                onDownloadButtonClick={this.onDownloadButtonClick}
            />
        );
    }

    @autobind
    private onMouseEnter(): void {
        this.setState(
            {
                isMouseOver: true,
            },
            this.initMouseOverUpdateTimeout,
        );
    }

    @autobind
    private onMouseLeave(): void {
        this.setState({
            isMouseOver: false,
            showDownloadButton: false,
        });
    }

    @autobind
    private initMouseOverUpdateTimeout(): void {
        setTimeout(() => {
            if (this.state.isMouseOver) {
                this.setState({
                    showDownloadButton: true,
                });
            }
        }, 300);
    }

    @autobind
    private onDownloadButtonClick(): void {
        const { id, activityId, briefId, fieldId, taskId, commentId, originName, type } = this.props;

        FileApi.downloadFile(
            {
                activityId,
                briefId,
                fieldId,
                taskId,
                commentId,
                originName,
                type,
            },
            id,
        );
    }
}
