import * as React from 'react';
import { connect } from 'react-redux';
import { AccountStatus } from 'sber-marketing-types/openid';
import { MentionItem, MentionItemStatus } from 'sber-marketing-ui';
import * as lodash from 'lodash';

import { UserCard as User } from 'sber-marketing-types/frontend';

import { CommentInputBehaviour } from './CommentInputBehaviour';
import { StoreState } from '@store';
import { getLoginUser } from '@store/user/selector';
import { UserApi } from '@api';

const SBER_ORGANIZATIONS_IDS = [
    'e2ee630b-8fe9-4e68-a6cf-ea3c9d2709e8',
    '1f11c60a-c021-4092-8697-67c83cb5d5d9',
    '68a38c6a-79f7-49b5-9356-b779fbbb9b64',
    '6aa41a85-1716-43a7-9c91-9933c9b945a4',
];

interface Props extends Partial<MapProps> {
    elKey: any;
    mentionableUsers?: User[];
    createComment: (text: string, files: File[]) => Promise<void>;
}

interface MapProps {
    userOrganizationId: string;
}

interface State {
    mentionableUsers: MentionItem[];
}

@(connect(mapStateToProps) as any)
export class CommentInputConnector extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            mentionableUsers: [],
        };
    }

    public async componentDidMount() {
        await this.loadMentionableUsers();
    }

    public async componentDidUpdate(prevProps: Props) {
        const mentionableUsersChanged = this.props.mentionableUsers !== prevProps.mentionableUsers;

        if (mentionableUsersChanged) {
            await this.loadMentionableUsers();
        }
    }

    public render(): JSX.Element {
        const { elKey, createComment } = this.props;
        const { mentionableUsers } = this.state;

        return (
            <CommentInputBehaviour elKey={elKey} mentionableUsers={mentionableUsers} createComment={createComment} />
        );
    }

    private async loadMentionableUsers(): Promise<void> {
        const { mentionableUsers } = this.props;

        let formatedUsers: MentionItem[];

        if (mentionableUsers) {
            formatedUsers = mentionableUsers.map((user) => ({
                id: user.id,
                title: `${user.secondName} ${user.firstName}`,
                description: user.department || 'Департамент не задан',
            }));
        } else {
            const organizationIds = lodash.includes(SBER_ORGANIZATIONS_IDS, this.props.userOrganizationId)
                ? SBER_ORGANIZATIONS_IDS
                : [this.props.userOrganizationId];

            const users = await UserApi.getUserListFiltered({ organizationIds, embed: ['vacation'] });

            formatedUsers = users
                .filter(
                    (user) =>
                        (user.status === AccountStatus.ACTIVE || user.status === AccountStatus.NEED_UPDATE_PASSWORD) &&
                        !!user.departmentId,
                )
                .map((user) => ({
                    id: user.id,
                    title: `${user.secondName} ${user.firstName}`,
                    description: user.departmentName || 'Департамент не задан',
                    status: user.vacation ? MentionItemStatus.Vacation : null,
                }));
        }

        this.setState({
            mentionableUsers: formatedUsers,
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        userOrganizationId: getLoginUser(state).attributes.organizationId,
    };
}
