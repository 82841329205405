// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-FeaturesSelector-_styles__root{display:grid;grid-auto-rows:max-content;grid-row-gap:12px}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-FeaturesSelector-_styles__label{font-family:'Open Sans';font-weight:600;font-size:12px;line-height:16px;color:#092A3A}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/CreationForm/ParamsEditor/FeaturesSelector/styles.scss"],"names":[],"mappings":"AAAA,sIACI,YAAa,CACb,0BAA2B,CAC3B,iBAAkB,CACrB,uIAGG,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,aAAc","sourcesContent":[".root {\n    display: grid;\n    grid-auto-rows: max-content;\n    grid-row-gap: 12px;\n}\n\n.label {\n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 12px;\n    line-height: 16px;\n    color: #092A3A;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-FeaturesSelector-_styles__root",
	"label": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-FeaturesSelector-_styles__label"
};
export default ___CSS_LOADER_EXPORT___;
