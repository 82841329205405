import * as React from 'react';
import classNames from 'classnames';
import { WithTooltip, Skeleton } from 'sber-marketing-ui';

import * as style from './CardAuthor.scss';

interface Props {
    name: string;
    subText?: string;
    loading?: boolean;
}

export const CardAuthor: React.FC<Props> = ({ name, subText, loading }) => {
    const tooltip = `Автор: ${name}${subText ? `. ${subText}` : ''}`;

    return (
        <div
            className={classNames(style.root, loading && style.loading)}
            {...{
                'qa-id': 'cardAuthor',
                'qa-value': `${name} ${subText}`,
            }}
        >
            <WithTooltip content={tooltip}>
                <Skeleton isReady={!loading}>
                    <div className={style.title}>{name}</div>

                    {subText && <div className={style.content}>{subText}</div>}
                </Skeleton>
            </WithTooltip>
        </div>
    );
};
