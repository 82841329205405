import * as React from 'react';
import classNames from 'classnames';

import * as style from './NavItemDropdown.scss';

import { Icon, IconType } from 'sber-marketing-ui';

interface Props {
    title: string;
    isOpen: boolean;
    disabled: boolean;
    children: React.ReactNode;
    onTitleClick: () => void;
    onArrowClick: () => void;
}

export const NavItemDropdown: React.FC<Props> = ({ title, isOpen, disabled, children, onTitleClick, onArrowClick }) => {
    return (
        <div>
            <div
                className={style.header}
                {...{
                    'qa-id': 'briefNavItemDropdown',
                }}
            >
                <div
                    className={classNames(
                        style.arrowWrapper,
                        disabled ? style.arrowDisabled : '',
                        isOpen ? style.arrowWrapperUp : style.arrowWrapperDown,
                    )}
                    onClick={!disabled ? onArrowClick : () => {}}
                >
                    <Icon type={IconType.ARROW_ROUNDED} svgSize={8} />
                </div>

                <div
                    title={title}
                    className={classNames(style.title, isOpen ? style.titleActive : '')}
                    onClick={onTitleClick}
                >
                    {title}
                </div>
            </div>

            {isOpen && <div>{children}</div>}
        </div>
    );
};
