import * as React from 'react';
import * as styles from './styles.scss';

import type { EstimateValue } from './types';

import { EstimateBar } from './EstimateBar';
import { SelectedEstimate } from './SelectedEstimate';
import type { Mode } from './types';

interface Props {
    mode: Mode;
    selectedEstimateValue: EstimateValue;
    onEstimateValueSelect: (estimateValue: EstimateValue) => void;
    onEstimateValueCancel: () => void;
}

export const TaskEstimatorTemplate: React.FC<Props> = ({
    mode,
    selectedEstimateValue,
    onEstimateValueSelect,
    onEstimateValueCancel,
}) => {
    return (
        <div className={styles.root}>
            {!selectedEstimateValue && (
                <div className={styles.header}>
                    <div className={styles.title}>Оценка задачи</div>
                    <div className={styles.subtitle}>Насколько вам понравилась работа по задаче?</div>
                </div>
            )}
            <div className={styles.content}>
                {mode === 'display' ? (
                    <SelectedEstimate
                        selectedEstimateValue={selectedEstimateValue}
                        onEstimateValueCancel={onEstimateValueCancel}
                    />
                ) : (
                    <EstimateBar onEstimateValueSelect={onEstimateValueSelect} />
                )}
            </div>
        </div>
    );
};

export type { EstimateValue };
