import * as React from 'react';
import classNames from 'classnames';
import * as moment from 'moment';

import * as styles from './FileAsset.scss';

import { FileAssetTemplateProps, FileAssetSize, FileAssetType } from './types';

import { RemoveFileAssetPopup } from '../RemoveFileAssetPopup';

export const FileAsset: React.FunctionComponent<FileAssetTemplateProps> = ({
    type,
    originName,
    createdAt,
    onRemoveClick,
    onDownloadClick,
    isDeletable,
    className,
    hideDate,
    size,
    isRemovePopupOpen,
    onRemoveCancelClick,
    onRemoveOpenClick,
}: FileAssetTemplateProps): JSX.Element => {
    return (
        <div
            className={classNames(
                styles.asset,
                className,
                size == FileAssetSize.BIG ? styles.big : styles.small,
                isDeletable && styles.isDeletable,
            )}
            title={getTitle(originName, createdAt, type)}
            {...{
                'qa-id': 'fileAsset',
                'qa-file-name': originName,
            }}
        >
            <div className={styles.assetLink} onClick={() => onDownloadClick()}>
                <div className={getAssetTypeClassNames(type)}>
                    <span>{formatType(type)}</span>
                </div>

                <div className={classNames(styles.assetInfo, hideDate && styles.assetInfoSmall)}>
                    <div className={styles.assetName}>{originName}</div>

                    {!hideDate && <div className={styles.createdAt}>{moment(createdAt).format('DD MMM')}</div>}
                </div>
            </div>

            {isDeletable && (
                <div className={styles.removeButton} onClick={onRemoveOpenClick}>
                    +
                </div>
            )}

            {isRemovePopupOpen && (
                <RemoveFileAssetPopup
                    type={type}
                    originName={originName}
                    onCancelClick={onRemoveCancelClick}
                    onRemoveClick={onRemoveClick}
                />
            )}
        </div>
    );
};

function getAssetTypeClassNames(type: FileAssetType | string): string {
    const assetTypeClassList: string[] = [styles.assetType];

    switch (type) {
        case FileAssetType.TXT: {
            assetTypeClassList.push(styles.assetTypeTxt);
            break;
        }
        case FileAssetType.DOC: {
            assetTypeClassList.push(styles.assetTypeDoc);
            break;
        }
        case FileAssetType.JPG: {
            assetTypeClassList.push(styles.assetTypeJpg);
            break;
        }
        case FileAssetType.XLS: {
            assetTypeClassList.push(styles.assetTypeXls);
            break;
        }
        case FileAssetType.PNG: {
            assetTypeClassList.push(styles.assetTypePng);
            break;
        }
        case FileAssetType.PDF:
        default: {
            assetTypeClassList.push(styles.assetTypePdf);
            break;
        }
    }

    return classNames(assetTypeClassList);
}

function getTitle(originName: string, createdAt: number | string | Date, type?: string): string {
    return type
        ? `${originName}.${type} ${moment(createdAt).format('DD MMM')}`
        : `${originName} ${moment(createdAt).format('DD MMM')}`;
}

function formatType(type?: string): string {
    return type ? type.toUpperCase().slice(0, 3) : '?';
}
