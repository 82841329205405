import * as React from 'react';

import * as style from './Footer.scss';
import { FixedIcons } from 'sber-marketing-ui';

export function Footer() {
    return (
        <div className={style.root}>
            <div className={style.icon}>{FixedIcons.getFooterLogo()}</div>
            <div className={style.text}>ПАО Сбербанк. Генеральная лицензия Банка России № 1481 от 11.08.2015 г.</div>
        </div>
    );
}
