import * as React from 'react';

import { WithTooltip } from 'sber-marketing-ui';

import { useGetActivityQuery } from '@api';

import { SkeletonText, Link, LinkProps } from '@common/components';

export interface ActivityLinkProps extends Omit<LinkProps, 'to'> {
    activityId: number;
    name?: string;
    linkClassName?: string;
}

export const ActivityLink: React.FC<ActivityLinkProps> = ({ activityId, name, linkClassName, ...props }) => {
    const { isLoading, data } = useGetActivityQuery({ id: activityId }, { skip: Boolean(name) });

    const content = name || (isLoading ? <SkeletonText width={260} /> : data.name);

    return (
        <WithTooltip hidden={isLoading} content={name || data?.name || ''}>
            <Link data-qa-id="ActivityLink" {...props} className={linkClassName} to={`/activity/${activityId}/tasks`}>
                {content}
            </Link>
        </WithTooltip>
    );
};
