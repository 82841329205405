import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';

import { Period } from '@store/calendar/types';

import { DateFilter } from './DateFilter';
import type { StoreState } from '@store';
import { setPeriod } from '@store/calendar/actions';
import { getCalendarPageState } from '@store/calendar/selectors';
import { Scroller, TimeCalculator } from '../modules';

interface Props extends Partial<MapProps>, Partial<DispatchProps> {}

interface MapProps {
    period: Period;
}

interface DispatchProps {
    setPeriod: (period: Period) => void;
}

interface State {
    width: number;
    selectedQuarter: number;
    selectedMonth: number;
    selectedYear: number;
    calendarScroll: number;
}

@(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true }) as any)
export class DateFilterContainer extends React.PureComponent<Props, State> {
    private root: HTMLDivElement;
    private calculator: TimeCalculator;
    private scroller: Scroller;

    constructor(props: Props) {
        super(props);

        this.calculator = TimeCalculator.getInstance();
        this.scroller = Scroller.getInstance();

        this.scroller.register('dateFilter', this.onScrollerEmit);

        this.state = {
            width: 0,
            selectedQuarter: null,
            selectedMonth: null,
            selectedYear: this.calculator.getCurrentYear(),
            calendarScroll: 0,
        };
    }

    public componentDidMount() {
        this.setState(
            {
                width: this.getRootWidth(),
            },
            () => {
                this.setCalendarScroll(this.getScrollOnYearPeriod(this.calculator.getCurrentYear()));
            },
        );

        window.addEventListener('resize', this.onPageResize, { passive: true });
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.onPageResize);
    }

    public render(): JSX.Element {
        return React.createElement(DateFilter, {
            scroll: this.state.calendarScroll,
            width: this.state.width,
            quarters: this.calculator.getAllQuarters(),
            months: this.calculator.getAllMonths(),
            selectedQuarter: this.state.selectedQuarter,
            selectedMonth: this.state.selectedMonth,
            selectedYear: this.state.selectedYear,
            onYearClick: this.onYearClick,
            onQuarterClick: this.onQuarterClick,
            onMonthClick: this.onMonthClick,
            rootRef: this.rootRef,
        });
    }

    public setYear(year: number) {
        this.onYearClick(year);
    }

    @autobind
    protected rootRef(element: HTMLDivElement) {
        this.root = element;
    }

    @autobind
    protected onScrollerEmit(scrollValue: number) {
        this.setState(
            {
                calendarScroll: scrollValue,
            },
            () => {
                this.updateSelection(scrollValue);
            },
        );
    }

    @autobind
    protected onPageResize() {
        this.setState({
            width: this.getRootWidth(),
        });
    }

    @autobind
    protected onYearClick(year: number) {
        this.setState(
            {
                selectedQuarter: null,
                selectedMonth: null,
                selectedYear: year,
            },
            () => {
                if (this.props.period !== Period.Year) {
                    this.props.setPeriod(Period.Year);
                }

                const newScroll = this.getScrollOnYearPeriod(year, Period.Year);

                setTimeout(() => this.setCalendarScroll(newScroll));
            },
        );
    }

    @autobind
    protected onQuarterClick(value: number, year: number) {
        this.setState(
            {
                selectedQuarter: value,
                selectedYear: year,
                selectedMonth: null,
            },
            () => {
                const newScroll = this.getScrollOnQuarterPeriod(value, year, Period.Quarter);

                if (this.props.period !== Period.Quarter) {
                    this.props.setPeriod(Period.Quarter);
                }

                setTimeout(() => this.setCalendarScroll(newScroll));
            },
        );
    }

    @autobind
    protected onMonthClick(value: number, year: number) {
        this.setState(
            {
                selectedQuarter: Math.floor((value - 1) / 3) + 1,
                selectedYear: year,
                selectedMonth: value,
            },
            () => {
                const newScroll = this.getScrollOnMonthPeriod(value, year, Period.Month);

                if (this.props.period !== Period.Month) {
                    this.props.setPeriod(Period.Month);
                }

                setTimeout(() => this.setCalendarScroll(newScroll));
            },
        );
    }

    private setCalendarScroll(scrollValue: number) {
        this.setState(
            {
                calendarScroll: scrollValue,
            },
            () => {
                this.scroller.setScroll('dateFilter', scrollValue);
            },
        );
    }

    private updateSelection(scroll: number) {
        const { period } = this.props;

        const leftOffsetInDays = this.getPreviousDaysCount(scroll, period);

        const { year, quarter, month } = this.calculator.getTimeInfoByDays(leftOffsetInDays, period);

        this.setState(({ selectedMonth, selectedQuarter }) => ({
            selectedYear: year,
            selectedMonth: period === Period.Month ? month : selectedMonth,
            selectedQuarter: period === Period.Quarter || period === Period.Month ? quarter : selectedQuarter,
        }));
    }

    private getScrollOnYearPeriod(year: number, period: Period = this.props.period): number {
        return this.getScroll(this.calculator.getDaysBeforeYear(year), period);
    }

    private getScrollOnQuarterPeriod(quarter: number, year: number, period: Period = this.props.period): number {
        return this.getScroll(this.calculator.getDaysBeforeQuarter(year, quarter), period);
    }

    private getScrollOnMonthPeriod(month: number, year: number, period: Period = this.props.period): number {
        return this.getScroll(this.calculator.getDaysBeforeMonth(year, month), period);
    }

    private getScroll(previousDaysCount: number, period: Period = this.props.period): number {
        return this.calculator.scrollIndexByDaysCount(previousDaysCount, period);
    }

    private getPreviousDaysCount(scroll: number, period: Period = this.props.period): number {
        return this.calculator.daysCountByScrollIndex(scroll, period);
    }

    private getRootWidth(): number {
        return this.root.offsetWidth;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { period } = getCalendarPageState(state);

    return {
        period,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setPeriod,
        },
        dispatch,
    );
}
