import * as React from 'react';
import classNames from 'classnames';
import * as moment from 'moment';

import * as style from './BriefStageWidget.scss';

import { OpacityTransition, LabeledInput, InputType, LabeledSelect, SelectItem } from 'sber-marketing-ui';
import { MultiselectDatepicker } from './MultiselectDatepicker';

interface Props {
    readOnly: boolean;
    loading: boolean;
    isBudgetFocus: boolean;
    blockId: string;
    productId: string;
    budget: string;
    budgetError: string;
    startDate: moment.Moment;
    endDate: moment.Moment;
    datesRange: string;
    peakDates: Date[];
    targetAgeFrom: string;
    targetAgeTo: string;
    blockItems: SelectItem[];
    productItems: SelectItem[];
    onBlockChange: (value: string) => void;
    onProductChange: (value: string) => void;
    onBudgetChange: (value: string) => void;
    onBudgetFocus: () => void;
    onBudgetBlur: () => void;
    onStartDateChange: (value: moment.Moment) => void;
    onEndDateChange: (value: moment.Moment) => void;
    onPeakDatesChange: (value: Date[]) => void;
    onTargetAgeFromChange: (value: string) => void;
    onTargetAgeToChange: (value: string) => void;
}

export const BriefStageWidgetTemplate = ({
    readOnly,
    loading,
    isBudgetFocus,
    blockId,
    productId,
    budget,
    budgetError,
    startDate,
    endDate,
    datesRange,
    peakDates,
    blockItems,
    productItems,
    targetAgeFrom,
    targetAgeTo,
    onBlockChange,
    onProductChange,
    onBudgetChange,
    onBudgetFocus,
    onBudgetBlur,
    onStartDateChange,
    onEndDateChange,
    onPeakDatesChange,
    onTargetAgeFromChange,
    onTargetAgeToChange,
}: Props): JSX.Element => {
    const today = moment().startOf('day');

    return (
        <OpacityTransition start={!loading}>
            <div className={style.root}>
                <div className={style.title}>Бриф</div>

                <div className={style.form}>
                    <div className={classNames(style.field, style.line)}>
                        <LabeledSelect
                            title={'Блок'}
                            items={blockItems}
                            selectedValue={blockId}
                            onSelection={onBlockChange}
                            readOnly={readOnly}
                        />
                    </div>

                    <div className={classNames(style.field, style.line)}>
                        <LabeledSelect
                            title={'Продукт'}
                            items={productItems}
                            selectedValue={productId}
                            onSelection={onProductChange}
                            readOnly={readOnly}
                        />
                    </div>

                    <div className={style.line}>
                        <div className={style.smallField}>
                            <LabeledInput
                                title={'Бюджет на весь период'}
                                value={budget}
                                errorMessage={budgetError}
                                displayError={!!budget?.length && !!budgetError?.length && !isBudgetFocus}
                                onValueChange={onBudgetChange}
                                onFocus={onBudgetFocus}
                                onBlur={onBudgetBlur}
                                onlyNumbers
                                readOnly={readOnly}
                            />
                        </div>
                    </div>

                    <div className={style.line}>
                        <div className={style.smallField}>
                            <LabeledInput
                                title={'Старт проекта'}
                                value={startDate}
                                minDate={today}
                                maxDate={endDate}
                                type={InputType.DatePicker}
                                onValueChange={onStartDateChange}
                                readOnly={readOnly}
                            />
                        </div>

                        <span className={style.separator}>—</span>

                        <div className={style.smallField}>
                            <LabeledInput
                                title={'Окончание проекта'}
                                value={endDate}
                                minDate={startDate}
                                type={InputType.DatePicker}
                                onValueChange={onEndDateChange}
                                readOnly={readOnly}
                            />

                            {datesRange && <div className={style.fieldMessage}>Длительность проекта: {datesRange}</div>}
                        </div>
                    </div>

                    <div className={classNames(style.field, style.line)}>
                        <div className={style.fieldTitle}>Пиковые даты (необязательное поле)</div>

                        <div className={style.multiselectDatepicker}>
                            <MultiselectDatepicker
                                dates={peakDates}
                                minDate={startDate ? startDate.toDate() : null}
                                maxDate={endDate ? endDate.toDate() : null}
                                onDatesChange={onPeakDatesChange}
                                readOnly={readOnly}
                            />
                        </div>
                    </div>

                    <div className={style.line}>
                        <div className={style.smallField}>
                            <LabeledInput
                                title={'Минимальный возраст'}
                                value={targetAgeFrom}
                                onValueChange={onTargetAgeFromChange}
                                onlyNumbers
                                readOnly={readOnly}
                            />
                        </div>

                        <div className={style.smallField}>
                            <LabeledInput
                                title={'Максимальный возраст'}
                                value={targetAgeTo}
                                onValueChange={onTargetAgeToChange}
                                onlyNumbers
                                readOnly={readOnly}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </OpacityTransition>
    );
};
