import { useGetTaskQuery } from '@api';

import { useAuthUserId } from '@modules/auth/hooks';

export function useIsTaskExecutor(taskId: string) {
    const { isLoading: isAuthUserLoading, authUserId } = useAuthUserId();
    const { isLoading: isTaskLoading, data: { task } = {} } = useGetTaskQuery({ id: taskId });

    const isExecutor = task && authUserId && task?.executor?.id === authUserId;

    return {
        isLoading: isAuthUserLoading || isTaskLoading,
        isExecutor,
    } as const;
}
