import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
import * as queryString from 'query-string';
import * as lodash from 'lodash';

import type { RouteComponentProps } from 'react-router-dom';
import { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type { Autopilot } from 'sber-marketing-types/backend';
import { SwitchKeyActivityParams } from '@store/autopilot_legacy/types';
import type { HeaderView } from '@common/Page';

import { AutopilotPageTemplate } from './AutopilotPageTemplate';
import type { StoreState } from '@store';
import { resetPageState, setCurrentStage } from '@store/autopilot_legacy/actions';
import { getAutopilot, getActivity, getCurrentStage } from '@store/autopilot_legacy/selectors';
import { switchKeyActivity } from '@store/autopilot_legacy/thunks';
import { Loader } from './modules';
import { User, getLoginUser } from '@store/user';

interface Props extends MapProps, DispatchProps, Partial<RouteComponentProps<RouteParams>> {}

interface MapProps {
    autopilot?: Autopilot;
    activity?: Activity;
    currentStage?: number;
    user?: User;
}

interface DispatchProps {
    setHeaderView?: (view: HeaderView) => void;
    setCurrentStage?: (stage: number) => void;
    switchKeyActivity?: (params: SwitchKeyActivityParams) => Promise<void>;
    resetPageState?: () => void;
}

interface RouteParams {
    activityId: string;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class AutopilotPageBehaviour extends React.PureComponent<Props> {
    private activityId = Number(this.props.match.params.activityId);
    private loader: Loader;

    public constructor(props: Props) {
        super(props);

        this.loader = Loader.getInstance();
    }

    public async componentDidMount() {
        this.initFromQuery();
        await this.loader.init(this.activityId);
    }

    public componentWillUnmount() {
        this.props.resetPageState();
    }

    public render(): JSX.Element {
        const { autopilot, currentStage } = this.props;

        return React.createElement(AutopilotPageTemplate, {
            isAutopilotClient: this.checkUserAutopilotClientAccess(),
            isAutopilotClientService: this.checkUserAutopilotClientServiceAccess(),
            placementsAreLaunched: this.checkLaunchedPlacements(),
            autopilotId: autopilot && autopilot.id,
            autopilotIsEnabled: autopilot && autopilot.status == 'on',
            currentStage,
        });
    }

    private initFromQuery() {
        const stageNumber = this.getStageNumberFromQuery();

        if (stageNumber) {
            this.props.setCurrentStage(stageNumber);
            this.removeQueryFromUrl();
        }
    }

    private getStageNumberFromQuery(): number {
        const stage = queryString.parse(this.props.location.search).stage as string;

        return stage ? parseInt(stage, 10) : null;
    }

    private removeQueryFromUrl() {
        this.props.history.push({
            search: '',
        });
    }

    private checkLaunchedPlacements(): boolean {
        const { autopilot } = this.props;

        if (!autopilot) {
            return false;
        }

        return autopilot.status === 'on' && !lodash.isEmpty(autopilot.placements);
    }

    private checkUserAutopilotClientAccess() {
        return this.props.user.attributes.roles.map(({ name }) => name).includes('Autopilot Client');
    }

    private checkUserAutopilotClientServiceAccess() {
        return this.props.user.attributes.roles.map(({ name }) => name).includes('Autopilot Client Service');
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        autopilot: getAutopilot(state),
        activity: getActivity(state),
        currentStage: getCurrentStage(state),
        user: getLoginUser(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setCurrentStage,
            switchKeyActivity,
            resetPageState,
        },
        dispatch,
    );
}
