import * as React from 'react';

import { useStore, By } from './useStore';
import { useMode } from '../../hooks';

interface UseBehavior {
    (): Return;
}

interface Return {
    selectedFilterBy: string;
    onFilterByChange: OnFilterByChange;
}

interface OnFilterByChange {
    (params: OnFilterByChangeParams): void;
}

interface OnFilterByChangeParams {
    by: By;
}

export const useBehavior: UseBehavior = () => {
    const { mode } = useMode();
    const { selectedFilterBy, setFilterBy } = useStore();

    const onFilterByChange: OnFilterByChange = React.useCallback(
        ({ by }) => {
            setFilterBy({
                by,
                mode,
            });
        },
        [mode, setFilterBy],
    );

    return {
        selectedFilterBy,
        onFilterByChange,
    };
};

export { By };
