import * as React from 'react';
import classNames from 'classnames';
import {
    Icon,
    IconType,
    Input_redesign as Input,
    InputTheme_redesign as InputTheme,
    InputProps_redesign as InputProps,
} from 'sber-marketing-ui';

import * as styles from './InputWithSendButton.scss';

interface Props extends InputProps {
    isFocused: boolean;
    onContainerMouseOver: () => void;
    onContainerMouseOut: () => void;
    onClearButtonClick: () => void;
    onSendButtonClick: () => void;
    onInputFocus: () => void;
    onClearButtonMouseOver: () => void;
    onSendButtonMouseOver: () => void;
    onInputBlur: () => void;
    onClearButtonMouseOut: () => void;
    onSendButtonMouseOut: () => void;
}

export function InputWithSendButton({
    isFocused,
    onContainerMouseOver,
    onContainerMouseOut,
    onClearButtonClick,
    onSendButtonClick,
    onInputFocus,
    onClearButtonMouseOut,
    onSendButtonMouseOut,
    onInputBlur,
    onClearButtonMouseOver,
    onSendButtonMouseOver,
    ...props
}: Props): JSX.Element {
    return (
        <div className={styles.root} onMouseOver={onContainerMouseOver} onMouseOut={onContainerMouseOut}>
            <Icon
                type={IconType.REJECT_ICON_ROUND}
                svgSize={16}
                className={classNames(styles.button, styles.clearButton)}
                onClick={onClearButtonClick}
                onMouseOver={onClearButtonMouseOver}
                onMouseOut={onClearButtonMouseOut}
            />

            <div className={classNames(styles.borderWrapper, isFocused && styles.borderWrapperFocused)}>
                <Input theme={InputTheme.Borderless} onFocus={onInputFocus} onBlur={onInputBlur} {...props} />
            </div>

            <div
                className={classNames(styles.button, styles.sendButton, !props.value && styles.sendButtonDisabled)}
                onClick={onSendButtonClick}
                onMouseOver={onSendButtonMouseOver}
                onMouseOut={onSendButtonMouseOut}
            >
                Сохранить
            </div>
        </div>
    );
}
