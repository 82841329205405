// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-leaderDashboard-LeaderDashboard-common-IntervalProgress-Value-Arrow-_styles__root{display:inline-flex;align-items:center;justify-content:center}.src-client-modules-leaderDashboard-LeaderDashboard-common-IntervalProgress-Value-Arrow-_styles__up{transform:rotate(-90deg)}.src-client-modules-leaderDashboard-LeaderDashboard-common-IntervalProgress-Value-Arrow-_styles__down{transform:rotate(90deg)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/leaderDashboard/LeaderDashboard/common/IntervalProgress/Value/Arrow/styles.scss"],"names":[],"mappings":"AAAA,sGACI,mBAAoB,CACpB,kBAAmB,CACnB,sBAAuB,CAC1B,oGAGG,wBAAyB,CAC5B,sGAGG,uBAAwB","sourcesContent":[".root {\n    display: inline-flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.up {\n    transform: rotate(-90deg);\n}\n\n.down {\n    transform: rotate(90deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-leaderDashboard-LeaderDashboard-common-IntervalProgress-Value-Arrow-_styles__root",
	"up": "src-client-modules-leaderDashboard-LeaderDashboard-common-IntervalProgress-Value-Arrow-_styles__up",
	"down": "src-client-modules-leaderDashboard-LeaderDashboard-common-IntervalProgress-Value-Arrow-_styles__down"
};
export default ___CSS_LOADER_EXPORT___;
