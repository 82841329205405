import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconType, DynamicIcon, WithTooltip, StyledPopup } from 'sber-marketing-ui';
import { BudgetItemStatus } from '@mrm/budget';

import * as style from './LineMenu.scss';
import { ApprovingButtons } from '../../CellTypes/IndicatorsCell/ApprovingButtons';
import { ApprovingStatus } from '../../CellTypes/IndicatorsCell/ApprovingStatus';

export enum DeleteLineModalState {
    Hidden = 'Hidden',
    DeleteLine = 'DeleteLine',
    DisableLine = 'DisableLine',
}
interface Props {
    lineId: string;
    backgroundColor: string;
    approversMenuIsOpened: boolean;
    lineIsHovered: boolean;
    lineStatus: BudgetItemStatus;
    expertComment: string;
    enableControls: boolean;
    lineHasChanges: boolean;
    userIsSupervisor: boolean;
    canMoveToExpertApprovement: boolean;
    deleteLineModalState: DeleteLineModalState;
    userCanEditOrCreateBudgetData: boolean;
    onSaveButtonClick: () => void;
    onRestoreButtonClick: () => void;
    onDeleteButtonClick: () => void;
    onApproversButtonClick: () => void;
    onDisableButtonClick: () => void;
    onInfoClick: () => void;
    onInfoMouseEnter: () => void;
    onInfoMouseLeave: () => void;
    onCopyLineButtonClick: () => void;

    closeDeleteLineModal: () => void;
    initLineDeletion: () => void;
    initLineDisable: () => void;
}

// tslint:disable-next-line:cyclomatic-complexity
export const LineMenu = ({
    lineId,
    backgroundColor,
    approversMenuIsOpened,
    lineIsHovered,
    lineStatus,
    expertComment,
    enableControls,
    lineHasChanges,
    userCanEditOrCreateBudgetData,
    onSaveButtonClick,
    onRestoreButtonClick,
    onDeleteButtonClick,
    onApproversButtonClick,
    onDisableButtonClick,
    onInfoClick,
    onInfoMouseEnter,
    onInfoMouseLeave,
    onCopyLineButtonClick,
    deleteLineModalState,
    userIsSupervisor,
    closeDeleteLineModal,
    initLineDeletion,
    initLineDisable,
}: Props): JSX.Element => {
    const lineIsDisabled = lineStatus == BudgetItemStatus.Disabled;
    const lineIsRejected = lineStatus == BudgetItemStatus.Rejected;
    const lineIsDraft = lineStatus == BudgetItemStatus.Draft;

    const approversQaParams: any = {
        'qa-id': 'lineMenuApproversButton',
    };
    const infoQaParams: any = {
        'qa-id': 'lineMenuInfoButton',
    };
    const saveQaParams: any = {
        'qa-id': 'lineMenuSaveButton',
    };
    const restoreQaParams: any = {
        'qa-id': 'lineMenuRestoreButton',
    };
    const deleteQaParams: any = {
        'qa-id': 'lineMenuDeleteButton',
    };
    const copyBudgetItemQaParams: any = {
        'qa-id': 'copyBudgetItemButton',
    };

    const disableSaveButton = !lineHasChanges && !lineIsDraft;
    const isExpanded = approversMenuIsOpened || lineIsHovered;
    const showSaveButton = enableControls && !lineIsDisabled;
    const showRestoreButton = enableControls && lineIsDisabled;
    const showDeleteButton = enableControls && lineIsDraft;
    const showDisableButton = enableControls && !(lineIsDisabled || lineIsDraft);
    // const showApproversButton = enableControls && !lineIsDisabled && !lineIsDraft;

    return (
        <div
            className={classNames(style.root, isExpanded && style.expanded, lineIsHovered && style.lineHover)}
            {...{
                'qa-id': 'budgetPlanningLineMenu',
            }}
        >
            <div className={style.content}>
                <div
                    className={style.menu}
                    style={{
                        background: `linear-gradient(to right,
                            rgba(${backgroundColor}, 0) 0%,
                            rgba(${backgroundColor}, 1) 16px,
                            rgba(${backgroundColor}, 1) 100%
                        )`,
                    }}
                >
                    <div className={style.buttons}>
                        {/* <ApprovingButtons
                            lineIsHovered={lineIsHovered}
                            lineId={lineId}
                            wrapperRef={contentRef}
                            onLineMouseLeave={onInfoMouseLeave}
                            className=""
                        /> */}

                        {/* {userIsSupervisor && (
                            <div className={style.approvingStatusWrapper}>
                                <ApprovingStatus
                                    lineIsHovered={lineIsHovered}
                                    lineId={lineId}
                                    wrapperRef={contentRef}
                                />
                            </div>
                        )} */}
                        {/* 
                        {showApproversButton && (
                            <WithTooltip content="Список согласующих">
                                <div className={style.button} onClick={onApproversButtonClick} {...approversQaParams}>
                                    <Icon type={IconType.APPROVERS} className={style.iconWithFill} />
                                </div>
                            </WithTooltip>
                        )} */}

                        <WithTooltip content={lineIsRejected ? expertComment : 'Информация'}>
                            <div
                                className={style.button}
                                onClick={onInfoClick}
                                onMouseEnter={onInfoMouseEnter}
                                onMouseLeave={onInfoMouseLeave}
                                {...infoQaParams}
                            >
                                <Icon
                                    type={IconType.INFO}
                                    className={classNames(style.iconWithFill, lineIsRejected && style.iconWithFillRed)}
                                />
                            </div>
                        </WithTooltip>

                        {userCanEditOrCreateBudgetData && (
                            <React.Fragment>
                                <WithTooltip content="Копирование строки">
                                    <div
                                        className={style.button}
                                        onClick={onCopyLineButtonClick}
                                        {...copyBudgetItemQaParams}
                                    >
                                        <DynamicIcon
                                            common={{ svgSize: 24 }}
                                            normal={{ type: IconType.COPY }}
                                            hover={{ type: IconType.COPY_HOVER }}
                                        />
                                    </div>
                                </WithTooltip>

                                {showSaveButton && (
                                    <WithTooltip content="Опубликовать">
                                        <div
                                            className={classNames(
                                                style.button,
                                                disableSaveButton && style.button_disabled,
                                            )}
                                            onClick={disableSaveButton ? null : onSaveButtonClick}
                                            {...saveQaParams}
                                        >
                                            <Icon
                                                type={IconType.PUBLISH}
                                                className={classNames(
                                                    style.icon,
                                                    disableSaveButton && style.icon_disabled,
                                                )}
                                            />
                                        </div>
                                    </WithTooltip>
                                )}

                                {showRestoreButton && (
                                    <WithTooltip content="Восстановить">
                                        <div
                                            className={style.button}
                                            onClick={onRestoreButtonClick}
                                            {...restoreQaParams}
                                        >
                                            <Icon type={IconType.RESTORE} className={style.icon} />
                                        </div>
                                    </WithTooltip>
                                )}

                                {showDeleteButton && (
                                    <WithTooltip content="Удалить">
                                        <div className={style.button} onClick={onDeleteButtonClick} {...deleteQaParams}>
                                            <Icon type={IconType.DELETE} className={style.iconWithFill} />
                                        </div>
                                    </WithTooltip>
                                )}

                                {showDisableButton && (
                                    <WithTooltip content="Удалить">
                                        <div
                                            className={style.button}
                                            title={'Удалить'}
                                            onClick={onDisableButtonClick}
                                            {...deleteQaParams}
                                        >
                                            <Icon type={IconType.DISABLE} className={style.icon} />
                                        </div>
                                    </WithTooltip>
                                )}
                            </React.Fragment>
                        )}

                        <DeleteLineModal
                            state={deleteLineModalState}
                            closeModal={closeDeleteLineModal}
                            initLineDeletion={initLineDeletion}
                            initLineDisable={initLineDisable}
                        />

                        {/* { canMoveToExpertApprovement && !userIsSupervisor &&
                            <div
                                className={classnames(
                                    style.button,
                                    disableSendToExpertApprovementButton && style.button_disabled
                                )}
                                title={'Отправить на утверждение'}
                                onClick={disableSendToExpertApprovementButton ?
                                    null :
                                    onSendToExpertApprovementButtonClick
                                }
                                {...sendToExpertApprovementQaParams}
                            >
                                <Icon
                                    type={IconType.SEND_FOR_APPROVAL}
                                    className={classnames(
                                        style.icon,
                                        disableSendToExpertApprovementButton && style.icon_disabled
                                    )}
                                />
                            </div>
                        } */}
                    </div>
                </div>
            </div>
        </div>
    );
};

interface DeleteLineModalProps {
    state: DeleteLineModalState;
    closeModal: () => void;
    initLineDeletion: () => void;
    initLineDisable: () => void;
}

function DeleteLineModal({ state, closeModal, initLineDeletion, initLineDisable }: DeleteLineModalProps): JSX.Element {
    let title;
    let secondButtonText;
    let secondButtonHandler;

    switch (state) {
        case DeleteLineModalState.DeleteLine:
            title = 'Удалить строку?';
            secondButtonText = 'Да, удалить';
            secondButtonHandler = initLineDeletion;
            break;
        case DeleteLineModalState.DisableLine:
            title = 'Перевести строку в удаленные?';
            secondButtonText = 'Да, перевести';
            secondButtonHandler = initLineDisable;
            break;
        case DeleteLineModalState.Hidden:
        default:
            return null;
    }

    return (
        <StyledPopup
            qaId="deleteLinePopup"
            firstButtonQaId="deleteLinePopupFirstButton"
            secondButtonQaId="deleteLinePopupSecondButton"
            title={title}
            firstButtonText={'Отмена'}
            firstButtonHandler={closeModal}
            secondButtonText={secondButtonText}
            secondButtonHandler={secondButtonHandler}
        />
    );
}
