import autobind from 'autobind-decorator';
import { PureComponent, createElement } from 'react';
import { ImageGalleryItem } from 'sber-marketing-ui';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';

import { FileAsset } from '@store/commonTypes';
import { OpenEditorPayload, LoadDocumentStatusPayload, openEditor, loadDocumentStatus } from '@store/R7';

import { Utils } from '@common/Utils';

import { FileAssetListWithGalleryProps as OwnProps, FileAssetListWithGalleryContainerState as State } from './types';
import { FileAssetListWithGalleryTemplate } from './FileAssetListWithGalleryTemplate';

interface Props extends OwnProps, Partial<DispatchProps> {}

interface DispatchProps {
    openEditor: (payload: OpenEditorPayload) => void;
    loadDocumentStatus: (payload: LoadDocumentStatusPayload) => void;
}

/** "FileAssetListWithGallery" container component */
@(connect(null, mapDispatchToProps) as any)
export class FileAssetListWithGalleryContainer extends PureComponent<Props, State> {
    public static readonly displayName: string = 'FileAssetListWithGalleryContainer';

    public constructor(props: Props) {
        super(props);

        this.state = {
            isGalleryOpened: false,
            startIndex: 0,
            assetsR7StatusWasLoaded: false,
        };
    }

    public componentDidMount(): void {
        this.loadR7StatusIfNecessary();
    }

    public componentDidUpdate(): void {
        this.loadR7StatusIfNecessary();
    }

    public render() {
        return createElement(FileAssetListWithGalleryTemplate, {
            ...(this.props as Props),
            ...this.state,
            onGalleryClick: this.onGalleryClick,
            onPreviewClick: this.onPreviewClick,
        });
    }

    @autobind
    public onGalleryClick(initialIndex: number): void {
        this.setState(({ isGalleryOpened }) => ({ startIndex: initialIndex, isGalleryOpened: !isGalleryOpened }));
    }

    @autobind
    private onPreviewClick(index: number, items: ImageGalleryItem[]): void {
        const { assets, taskId, commentId, canEditR7Files } = this.props;
        const asset = assets[index];

        if (this.useR7Logic(asset)) {
            this.props.openEditor({
                fileName: asset.originName,
                fileType: asset.type,
                fileId: asset.id,
                editable: canEditR7Files,
                fileParams: { taskId, commentId },
            });
        } else {
            const selectedItem = items.find((item) => item.originalUrl === asset.fullSizeUrl);

            if (selectedItem) {
                this.onGalleryClick(items.indexOf(selectedItem));
            }
        }
    }

    private useR7Logic(asset: FileAsset): boolean {
        return this.props.useR7Controls && Utils.fileValidForR7(asset.type);
    }

    private loadR7StatusIfNecessary(): void {
        const { assets, taskId, commentId, disableDownload } = this.props;
        const initLoading = !this.state.assetsR7StatusWasLoaded && !disableDownload;

        if (initLoading) {
            this.setState(
                {
                    assetsR7StatusWasLoaded: true,
                },
                () => {
                    assets.forEach((asset) => {
                        if (this.useR7Logic(asset)) {
                            this.props.loadDocumentStatus({
                                fileId: asset.id,
                                fileParams: { taskId, commentId },
                            });
                        }
                    });
                },
            );
        }
    }
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        openEditor: (payload: OpenEditorPayload) => dispatch(openEditor(payload)),
        loadDocumentStatus: (payload: LoadDocumentStatusPayload) => dispatch(loadDocumentStatus(payload)),
    };
}
