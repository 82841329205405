import * as React from 'react';
import { ActivityType, ProductParams, UserResponseParams, DepartmentAttributes } from 'sber-marketing-types/frontend';
import { FilterGroup, FilterItem, CheckboxGroupFilter } from 'sber-marketing-ui';

import { ActivitiesFilter, ActivityStatus } from '@store/userConfig';

import { FilterMenuPreloader } from '../DashboardsPreloaders';
import { ActivityTypesFilter, ProductsFilter, UsersFilter, DepartmentsFilter } from '../Filters';

import * as style from './ActivityListFilters.scss';
import { ConfirmFilterButton } from '@common/ConfirmFilterButton';
import { useHideFilter } from '@common/FilterButton';

const STATUS: FilterItem[] = [
    {
        id: ActivityStatus.ACTIVE,
        title: 'Текущие',
    },
    {
        id: ActivityStatus.ACTIVE_ALL_TASKS_CLOSED,
        title: 'Текущие, задачи закрыты',
    },
    {
        id: ActivityStatus.FINISHED,
        title: 'Завершенные',
    },
];

interface ActivityListFiltersTemplateProps {
    isLoading: boolean;
    filters: ActivitiesFilter;
    activityTypes: ActivityType[];
    products: ProductParams[];
    authors: UserResponseParams[];
    responsibles: UserResponseParams[];
    responsibleDepartments: DepartmentAttributes[];
    onSave(): void;
    onStatusSelection(status: ActivityStatus | undefined | null): void;
    onActivityTypeSelect(selectedItemIds: React.ReactText[]): void;
    onProductSelect(selectedItemIds: React.ReactText[]): void;
    onAuthorSelect(selectedItemIds: React.ReactText[]): void;
    onResponsibleSelect(selectedItemIds: React.ReactText[]): void;
    onResponsibleDepartmentSelect(selectedItemIds: React.ReactText[]): void;
}

export function ActivityListFilters({
    filters,
    isLoading,
    activityTypes,
    products,
    authors,
    responsibles,
    responsibleDepartments,
    onSave,
    onStatusSelection,
    onActivityTypeSelect,
    onProductSelect,
    onAuthorSelect,
    onResponsibleSelect,
    onResponsibleDepartmentSelect,
}: ActivityListFiltersTemplateProps): JSX.Element {
    const hideFilter = useHideFilter();
    const handleSave = () => {
        onSave();
        hideFilter?.();
    };

    const content = isLoading ? (
        <FilterMenuPreloader />
    ) : (
        <>
            <div
                className={style.filterWrapper}
                {...{
                    'qa-id': 'dashboardActivitiesFilter',
                }}
            >
                <CheckboxGroupFilter
                    qaId="dashbordStatusFilter"
                    title="Статус"
                    items={STATUS}
                    checkedItemId={filters.status}
                    onItemSelection={onStatusSelection}
                />
            </div>

            <FilterGroup hideAll>
                <UsersFilter
                    title="Автор"
                    users={authors}
                    selectedItems={filters.author}
                    onCheckItems={onAuthorSelect}
                />

                <UsersFilter
                    title={'Ответственный'}
                    users={responsibles}
                    selectedItems={filters.responsible}
                    onCheckItems={onResponsibleSelect}
                />

                <DepartmentsFilter
                    title="Департамент ответственного"
                    departments={responsibleDepartments}
                    selectedItems={filters.responsibleDepartment}
                    onCheckItems={onResponsibleDepartmentSelect}
                />

                <ActivityTypesFilter
                    activityTypes={activityTypes}
                    selectedItems={filters.activityType}
                    onCheckItems={onActivityTypeSelect}
                />

                <ProductsFilter products={products} selectedItems={filters.product} onCheckItems={onProductSelect} />
            </FilterGroup>
            <ConfirmFilterButton onClick={handleSave}>Применить</ConfirmFilterButton>
        </>
    );

    return <div className={style.root}>{content}</div>;
}
