import * as React from 'react';

import { DropdownOptionsProps, useDropdown } from '@common/hooks';

import { Dropdown, DropdownProps } from '../../popup';
import { InputValues } from '../InputBase';
import { Input, InputProps } from '../Input';

export interface DropdownInputProps<V extends InputValues> extends Omit<InputProps<V>, 'target'>, DropdownOptionsProps {
    /** A content inside the tag button */
    inputContent?: React.ReactNode;
    dropdownProps?: Omit<DropdownProps, 'dropdown'>;
}

export function DropdownInput<V extends InputValues>({
    inputRef,
    inputContent,
    dropdownProps,
    children,
    ...rest
}: DropdownInputProps<V>) {
    const [dropdown, ref, props] = useDropdown({ trigger: 'focus', target: inputRef, ...rest }, HTMLInputElement);

    return (
        <Input {...props} inputRef={ref}>
            {inputContent}
            <Dropdown {...dropdownProps} dropdown={dropdown}>
                {children}
            </Dropdown>
        </Input>
    );
}
