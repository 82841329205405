// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-popup-ModalHeader-_ModalHeader__root{font-size:16px;font-weight:600}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/popup/ModalHeader/ModalHeader.scss"],"names":[],"mappings":"AAAA,2EACE,cAAe,CACf,eAAgB","sourcesContent":[".root {\n  font-size: 16px;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-components-popup-ModalHeader-_ModalHeader__root"
};
export default ___CSS_LOADER_EXPORT___;
