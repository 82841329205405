import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { Autopilot2 } from 'sber-marketing-types/backend';
import { AutopilotStage } from '@store/autopilot/types';

import { StagesListWidgetTemplate, StageGroupProps } from './StagesListWidgetTemplate';
import type { StoreState } from '@store';
import { setCurrentStage } from '@store/autopilot/actions';
import { getAutopilot, contextIsFinished } from '@store/autopilot/selectors';

interface Props extends MapProps, DispatchProps {
    currentStage: AutopilotStage;
}

interface MapProps {
    autopilot?: Autopilot2;
    contextIsFinished: boolean;
}

interface DispatchProps {
    setCurrentStage?: (stage: AutopilotStage) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class StagesListWidgetBehaviour extends React.PureComponent<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        const { autopilot } = this.props;

        return React.createElement(StagesListWidgetTemplate, {
            stages: this.makeStages(),
            preloader: !autopilot,
        });
    }

    @autobind
    protected onStageClick(stageOrder: AutopilotStage) {
        if (stageOrder !== this.props.currentStage) {
            this.props.setCurrentStage(stageOrder);
        }
    }

    private makeStages(): StageGroupProps[] {
        const { autopilot, currentStage, contextIsFinished } = this.props;

        if (!autopilot) {
            return [];
        }

        const { brief, mediaplan, mediaplanSettings, budget } = autopilot;

        const autopilotTurnedOff = autopilot.status === 'off';

        const briefIsFinished = brief
            ? [
                  brief.block,
                  brief.product,
                  brief.budget,
                  brief.dateStart,
                  brief.dateEnd,
                  brief.ageFrom,
                  brief.ageTo,
              ].every((item) => (typeof item == 'number' ? item !== null : !lodash.isEmpty(item)))
            : false;

        const mediaplanSettingsIsFinished = !!mediaplanSettings;

        const mediaplanIsFinished = mediaplan ? !lodash.isEmpty(mediaplan.url) : false;

        const budgetIsFinished = budget ? !lodash.isEmpty(budget.budgetIds) : false;

        // const socialIsFinished = social ? [
        //     social.vk,
        //     social.ok
        // ].every(item => !lodash.isEmpty(item)) : false;

        return [
            {
                name: 'Бриф',
                stages: [
                    {
                        name: 'Форма брифа',
                        isActive: currentStage == AutopilotStage.Brief,
                        isFinished: briefIsFinished,
                        isDisabled: autopilotTurnedOff,
                        onClick: () => this.onStageClick(AutopilotStage.Brief),
                    },
                ],
                isActive: currentStage == AutopilotStage.Brief,
                isDisabled: autopilotTurnedOff,
            },
            {
                name: 'Медиаплан',
                stages: [
                    {
                        name: 'Параметры',
                        isActive: currentStage == AutopilotStage.MediaplanSettings,
                        isFinished: mediaplanSettingsIsFinished,
                        isDisabled: autopilotTurnedOff || !briefIsFinished,
                        onClick: () => this.onStageClick(AutopilotStage.MediaplanSettings),
                    },
                    {
                        name: 'Итоговый медиаплан',
                        isActive: currentStage == AutopilotStage.Mediaplan,
                        isFinished: mediaplanIsFinished,
                        isDisabled: autopilotTurnedOff || !mediaplanSettingsIsFinished,
                        onClick: () => this.onStageClick(AutopilotStage.Mediaplan),
                    },
                ],
                isActive: [AutopilotStage.MediaplanSettings, AutopilotStage.Mediaplan].includes(currentStage),
                isDisabled: autopilotTurnedOff || !briefIsFinished,
            },
            {
                name: 'Бюджет',
                stages: [
                    {
                        name: 'Форма привязки',
                        isActive: currentStage == AutopilotStage.Budget,
                        isFinished: budgetIsFinished,
                        isDisabled: autopilotTurnedOff || !mediaplanIsFinished,
                        onClick: () => this.onStageClick(AutopilotStage.Budget),
                    },
                ],
                isActive: currentStage == AutopilotStage.Budget,
                isDisabled: autopilotTurnedOff,
            },
            {
                name: 'Креатив',
                stages: [
                    {
                        name: 'Контекстная реклама',
                        isActive: currentStage == AutopilotStage.Context,
                        isFinished: contextIsFinished,
                        isDisabled: autopilotTurnedOff || !budgetIsFinished,
                        onClick: () => this.onStageClick(AutopilotStage.Context),
                    },
                    {
                        name: 'Статусы',
                        isActive: currentStage == AutopilotStage.CreativeStatuses,
                        isFinished: autopilot.placements?.every(
                            (item) =>
                                item.creativeStatus === 'MODERATION_SUCCESS' || item.creativeStatus === 'APPROVED',
                        ),
                        isDisabled: autopilotTurnedOff || !contextIsFinished,
                        onClick: () => this.onStageClick(AutopilotStage.CreativeStatuses),
                    },
                    // {
                    //     name: 'Соц. сети',
                    //     isActive: currentStage == AutopilotStage.Social,
                    //     isFinished: socialIsFinished,
                    //     isDisabled: autopilotTurnedOff || !budgetIsFinished,
                    //     onClick: () => this.onStageClick(AutopilotStage.Social)
                    // },
                    // {
                    //     name: 'Программатик',
                    //     isActive: currentStage == AutopilotStage.Programmatic,
                    //     isFinished: false,
                    //     isDisabled: autopilotTurnedOff || !socialIsFinished,
                    //     onClick: () => this.onStageClick(AutopilotStage.Programmatic)
                    // }
                ],
                isActive: [AutopilotStage.Social, AutopilotStage.Context, AutopilotStage.Programmatic].includes(
                    currentStage,
                ),
                isDisabled: autopilotTurnedOff,
            },
            {
                name: 'Итог',
                stages: [
                    {
                        name: 'Финальное согласование',
                        isActive: currentStage == AutopilotStage.Final,
                        isFinished: false,
                        isDisabled: autopilotTurnedOff || !budgetIsFinished,
                        onClick: () => this.onStageClick(AutopilotStage.Final),
                    },
                ],
                isActive: false,
                isDisabled: autopilotTurnedOff || false,
            },
        ];
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        autopilot: getAutopilot(state),
        contextIsFinished: contextIsFinished(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setCurrentStage,
        },
        dispatch,
    );
}
