import * as React from 'react';
import { WithTooltip } from 'sber-marketing-ui';

import type { WithTooltipProps } from '../types';
import type { TooltipContentProps } from './TooltipContent';

import { TooltipContent } from './TooltipContent';

interface Props extends WithTooltipProps, TooltipContentProps {}

export const TooltipOfFirstStageTemplate: React.FC<Props> = ({ children, params }) => {
    return (
        <WithTooltip hidden={!params.length} content={<TooltipContent params={params} />}>
            {children}
        </WithTooltip>
    );
};

export { Props as TooltipOfFirstStageTemplateProps };
