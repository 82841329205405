import * as React from 'react';

import { WithProjectLinkedBudgetItems } from '@common/queries';

import { LinkedBudgetItemsConnected } from './LinkedBudgetItemsConnected';
import { LinkedBudgetItemsTemplate } from './LinkedBudgetItemsTemplate';

interface Props {
    activityId: number;
}

export function LinkedBudgetItems({ activityId }: Props): JSX.Element {
    return (
        <WithProjectLinkedBudgetItems activityId={activityId}>
            {({ linkIds, loading }) => (
                <LinkedBudgetItemsConnected linkIds={linkIds}>
                    {(gqlProps) => <LinkedBudgetItemsTemplate {...gqlProps} loading={loading || gqlProps.loading} />}
                </LinkedBudgetItemsConnected>
            )}
        </WithProjectLinkedBudgetItems>
    );
}
