import { TrademarkInterface as TrademarkRawInterface } from 'sber-marketing-types/trademark';

export interface TrademarkInterface extends TrademarkRawInterface, ResizeHeightInterface {}

export interface TrademarkCollectionInterface {
    [uuid: string]: TrademarkInterface;
}

export interface ResizeHeightInterface {
    height: number;
}

export enum TmRegistered {
    Unregistered = 0,
    Registered = 1,
}

export enum SortDirection {
    Asc = 1,
    Desc = -1,
}

export interface TrademarkFieldProp {
    id: string;
    name: string;
    visible: boolean;
    sort?: SortDirection;
    filterItem?: { id: string | number; title: string }[];
    filterFunction?: (filterItems: (string | number)[], value: any) => boolean;
    filterItems?: (string | number)[];
}

export interface TrademarkFieldProps {
    [fieldName: string]: TrademarkFieldProp;
}
