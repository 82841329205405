import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import {
    selectors,
    TelegramInfo,
    plugTelegram,
    unplugTelegram,
    updateTelegram,
    resetTelegramFetchError,
} from '@store/taskPage';

interface UseStore {
    (): UseStoreReturn;
}

interface UseStoreReturn {
    telegram: TelegramInfo;
    plug: () => void;
    unplug: () => void;
    update: () => void;
    resetFetchError: () => void;
}

export const useStore: UseStore = () => {
    const dispatch = useDispatch();

    const telegram = useSelector((state: StoreState) => selectors.getTaskInfo(state).telegram);
    const taskId = useSelector((state: StoreState) => selectors.getTaskInfo(state).id);

    function plug() {
        dispatch(plugTelegram({ taskId }));
    }
    function unplug() {
        dispatch(unplugTelegram({ taskId }));
    }
    function update() {
        dispatch(updateTelegram({ taskId }));
    }
    function resetFetchError() {
        dispatch(resetTelegramFetchError());
    }

    return {
        telegram,
        plug,
        unplug,
        update,
        resetFetchError,
    };
};
