import * as React from 'react';
import * as moment from 'moment';

import * as style from './DatepickerCell.scss';

import { DatepickerContent } from 'sber-marketing-ui';
import { SnapshotTooltip } from '../SnapshotTooltip';
import { CellEditorWrapper } from '../CellEditorWrapper';
import { CommentsButton } from '../CommentsButton';
import { ContextMenu } from '../ContextMenu';

interface Props {
    edit: boolean;
    title: string;
    value: moment.Moment;
    customStyle?: any;
    snapshot?: any;
    commentButtonProps?: { hasNewComments: boolean; onClick: () => void };
    onValueChange: (value: any) => void;
}

export const DatepickerCell = ({
    edit,
    title,
    value,
    customStyle,
    snapshot,
    commentButtonProps,
    onValueChange,
}: Props): JSX.Element => {
    function onClearValueClick() {
        onValueChange(null);
    }

    return (
        <>
            <CommentsButton {...commentButtonProps} />
            <SnapshotTooltip snapshot={snapshot} />
            <ContextMenu
                items={[
                    { title: 'Смотреть комментарии', onClick: commentButtonProps?.onClick },
                    { title: 'Очистить', onClick: onClearValueClick },
                ]}
            />

            <div className={style.root} style={{ ...customStyle }}>
                {title}

                {edit && (
                    <CellEditorWrapper>
                        <DatepickerContent date={value} onChange={(value) => onValueChange(value)} />
                    </CellEditorWrapper>
                )}
            </div>
        </>
    );
};
