import * as React from 'react';
import classNames from 'classnames';
import * as style from './BindButton.scss';

export interface Props {
    disabled: boolean;
    onClick(): void;
}

export const BindButton: React.FC<Props> = ({ disabled, onClick }) => {
    return (
        <div
            className={classNames(style.root, disabled && style.disabled)}
            onClick={!disabled ? onClick : null}
            {...{
                'qa-id': 'budgetExecutionLineModalBindActivityPopupBindlButton',
            }}
        >
            Привязать
        </div>
    );
};
