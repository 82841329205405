import { actionCreatorFactory } from 'typescript-fsa';

import { PageData, SelectedCard, UserRoleFilter, CardsLoadingModeFilter } from './types';

const actionCreator = actionCreatorFactory('DASHBOARD_PAGE_NEW');

const loadPageData = actionCreator<PageData>('LOAD_PAGE_DATA');
const resetPageStore = actionCreator('RESET_PAGE_STORE');
const setSelectedCard = actionCreator<SelectedCard>('SET_SELECTED_CARD');
const setUserRoleFilter = actionCreator<UserRoleFilter>('SET_USERROLE_FILTER');
const setCardsLoadingModeFilter = actionCreator<CardsLoadingModeFilter>('SET_CARDS_LOADING_MODE_FILTER');

const setRequestIdForStatusCheck = actionCreator<string>('SET_REQUEST_ID_FOR_STATUS_CHECK');
const setCardIdRequestInProgress = actionCreator<string>('SET_CARD_ID_REQUEST_IN_PROGRESS');

export {
    loadPageData,
    resetPageStore,
    setSelectedCard,
    setUserRoleFilter,
    setCardsLoadingModeFilter,
    setRequestIdForStatusCheck,
    setCardIdRequestInProgress,
};
