import { get } from 'lodash';

import { DifferenceTransformer, DifferencesTitles } from './types';

const accessors = {
    activity: 'activity.name',
    comment: 'comment',
    sapComment: 'sapComment',
    businessTarget: 'businessTarget',
    customerName: 'customerName',
};

export const regularDifference: DifferenceTransformer = function (prev, curr, item) {
    return [
        {
            title: DifferencesTitles[item],
            before: get(prev, accessors[item]),
            after: get(curr, accessors[item]),
        },
    ];
};
