import * as React from 'react';
import { useForm, FieldValues, DefaultValues, FormProvider, UseFormReturn } from 'react-hook-form';

import { Flex, FlexProps } from '@common/components';

export interface FormSubmitOptions<D = any> {
    event?: React.BaseSyntheticEvent;
    form: UseFormReturn<D>;
}

export interface FormProps<D extends FieldValues> extends Omit<FlexProps<'form'>, 'defaultValue' | 'onSubmit'> {
    defaultValue?: DefaultValues<D>;
    onSubmit?: (data: D, options?: FormSubmitOptions) => void;
}

export function Form<D extends FieldValues>({ defaultValue, onSubmit, ...props }: FormProps<D>) {
    const form = useForm<D>({
        defaultValues: defaultValue,
    });

    return (
        <FormProvider {...form}>
            <Flex
                {...props}
                onSubmit={form.handleSubmit((data, event) => onSubmit?.(data, { event, form }))}
                element="form"
            />
        </FormProvider>
    );
}
