import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { MenuItem } from 'sber-marketing-ui';
import { Content } from './Content';
import { ContentPreloader } from './ContentPreloader';
import { Header } from './Header';

import { HeaderView } from '@common/Page';
import { QuickAccessIcons } from '@common/QuickAccessIcons';

import { useBehavior } from './hooks';

import * as styles from './styles.scss';

interface Props extends Partial<RouteComponentProps> {
    setHeaderView?: (view: HeaderView) => void;
}

const LeaderDashboard: React.FC<Props> = ({ setHeaderView }) => {
    const { loading, statisticsItems } = useBehavior();

    React.useEffect(() => {
        setHeaderView({
            firstLine: HeaderTop(),
        });
    }, []);

    return (
        <div className={styles.root}>
            <Header />

            {!loading ? statisticsItems.length ? <Content /> : <Hint /> : <ContentPreloader />}
        </div>
    );
};

const Hint: React.FC = () => (
    <div className={styles.hintRoot}>
        <div className={styles.hintTitle}>Статистика за выбранный период отсутствует</div>
        <div className={styles.hintSubTitle}>Выберите другой период</div>
    </div>
);

export const LeaderDashboardWithRouter = withRouter(LeaderDashboard);

export function HeaderTop(): JSX.Element {
    return (
        <div className={styles.header}>
            <div className={styles.headerMenuItems}>
                <MenuItem link={'/leaderDashboard/activities'} active>
                    Дашборд руководителя
                </MenuItem>
            </div>

            <div className={styles.quickAccessIcons}>
                <QuickAccessIcons />
            </div>
        </div>
    );
}
