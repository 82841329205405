import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { combineReducers } from 'redux';
import { stubFalse } from 'lodash';

import { News, NewsFilters, NewsTypeFilterText, SaveFiltersActionParams } from '../types';
import { fetchMore, setCanBeLoadedMore, resetNews, saveFilters, setFiltersLoadingStatus } from '../actions';
import { resetStore } from '@store/common';
import { LoadingStatus } from '@store/commonTypes';

import { entitiesReducer } from './EntitiesReducer';

const status = reducerWithInitialState<LoadingStatus>(LoadingStatus.NOT_LOADED)
    .cases([resetNews, resetStore], () => LoadingStatus.NOT_LOADED)
    .case(fetchMore.started, () => LoadingStatus.LOADING)
    .case(fetchMore.failed, () => LoadingStatus.ERROR)
    .case(fetchMore.done, () => LoadingStatus.LOADED);

const filtersLoadingStatus = reducerWithInitialState<LoadingStatus>(LoadingStatus.NOT_LOADED)
    .cases([resetNews, resetStore], () => LoadingStatus.NOT_LOADED)
    .case(setFiltersLoadingStatus, (loadingStatus) => loadingStatus);

const canBeLoadedMore = reducerWithInitialState<boolean>(false)
    .cases([resetNews, resetStore], stubFalse)
    .case(setCanBeLoadedMore, (_, canBeLoadedMore) => canBeLoadedMore);

const error = reducerWithInitialState<Error | null>(null)
    .cases([fetchMore.started, fetchMore.done, resetNews, resetStore], () => null)
    .case(fetchMore.failed, (_, { error }) => error);

const filters = reducerWithInitialState<NewsFilters>({
    type: [],
    activity: [],
    author: [],
}).case(saveFilters, (_, payload: SaveFiltersActionParams) => ({
    type: payload.filters.types.map((item) => ({
        id: item,
        title: NewsTypeFilterText[item],
    })),
    author: payload.filters.authorIds.map((item) => {
        const user = payload.users.find((user) => user.id === item);

        return {
            id: item as number,
            title: `${user.secondName} ${user.firstName}`,
        };
    }),
    activity: payload.filters.activityData.map((item) => ({
        id: item.activityId,
        title: item.activityName,
    })),
}));

export const news = combineReducers<News>({
    status,
    filtersLoadingStatus,
    canBeLoadedMore,
    error,
    entities: entitiesReducer,
    filters,
});
