import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { StoreState } from '../';
import { LoadingStatus } from '@store/commonTypes';
import { addUsers, loadUsers as loadUsersAction } from './actions';
import { UserApi } from '../../api';

/** Load users cards */
export const loadUsers = bindThunkAction<StoreState, null | undefined, void, Error>(
    loadUsersAction,
    async (_, dispatch, getState) => {
        if (getState().appUsers?.status !== LoadingStatus.LOADED) {
            const users = await UserApi.getUserCardListFiltered({ embed: ['vacation'] });

            dispatch(addUsers(users));
        }
    },
);
