import * as React from 'react';

import { MentionItem, MentionItemStatus } from 'sber-marketing-ui';

import { useDefaultState } from '@common/hooks';

import { useGetChannelParticipants } from '@modules/task/hooks';

import { LexicalTextarea } from '@common/LexicalTextarea';
import { Props } from '@common/LexicalTextarea/LexicalTextareaContainer';

export interface CommentTextareaProps extends Partial<Props> {
    taskId: string;
    channelId: number;
}

export function CommentTextarea({ taskId, channelId, value, onValueChange, ...props }: CommentTextareaProps) {
    const [text, setText] = useDefaultState(value, onValueChange, '');
    const { participants } = useGetChannelParticipants(taskId, channelId);

    const mentions: MentionItem[] = participants.map(({ id, firstName, secondName, vacation }) => ({
        id,
        title: `${secondName} ${firstName}`,
        status: vacation ? MentionItemStatus.Vacation : MentionItemStatus.Default,
        description: 'FIXME',
    }));

    return (
        <LexicalTextarea
            qaId="CommentTextarea"
            title="Написать сообщение, используйте ‘@’ для упоминания участника"
            {...props}
            value={text}
            onValueChange={setText}
            focusOnValueChange
            mentions={mentions}
        />
    );
}
