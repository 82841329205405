import * as React from 'react';
import { useSelector } from 'react-redux';
import { sum, values } from 'lodash';

import { StoreState } from '@store';
import {
    InternalTransferDirection,
    getBudgetTransferMenuState,
    getTransitionBudgetItem,
    getTotalTransferAmountForDest,
} from '@store/budgetExecution/budgetTransferMenu';

import { CopyableValue } from '../../../CopyableValue';

import * as styles from './SumHelper.scss';

function useStore() {
    const toLine = useSelector((state: StoreState) => {
        const {
            cells: { to },
            controls: { rowForSumEntering, internalTransferDirection },
        } = getBudgetTransferMenuState(state);

        switch (internalTransferDirection) {
            case InternalTransferDirection.OneToMany:
                return rowForSumEntering;
            case InternalTransferDirection.ManyToOne:
                return to[0].lineId;
            default:
                return null;
        }
    });

    const currentToBudgetItem = useSelector((state: StoreState) => getTransitionBudgetItem(state, toLine));
    const totalTransferAmount = useSelector((state: StoreState) => getTotalTransferAmountForDest(state, toLine));

    const diff =
        sum(values(currentToBudgetItem?.plannedFunds || {})) -
            sum(values(currentToBudgetItem?.reservedFunds || {})) +
            totalTransferAmount || 0;

    return { currentToBudgetItem, diff };
}

export function SumHelper(): JSX.Element {
    const { currentToBudgetItem, diff } = useStore();

    if (!diff) {
        return null;
    }

    const content =
        diff < 0 ? (
            <React.Fragment>
                Осталось внести в{' '}
                <span className={styles.planReserveDiffBold}>ID {currentToBudgetItem.serialNumber}</span>:&nbsp;
            </React.Fragment>
        ) : (
            <React.Fragment>Внесенный бюджет больше на:</React.Fragment>
        );

    return (
        <div
            className={styles.planReserveDiff}
            {...{
                'qa-id': 'internalTransitionSumEnteringSumHelper',
            }}
        >
            {content}&nbsp;
            <CopyableValue value={Math.abs(diff)} />
        </div>
    );
}
