import * as React from 'react';
import classNames from 'classnames';

import * as style from './CommentsButton.scss';

import { Icon, IconType } from 'sber-marketing-ui';

interface Props {
    hasNewComments: boolean;
    onClick: () => void;
}

export const CommentsButton = ({ hasNewComments, onClick: externalOnClick }: Props): JSX.Element => {
    const [cellIsHovered, setHoveredStatus] = React.useState(false);

    const rootRef = React.useRef<HTMLDivElement>();

    React.useEffect(() => {
        if (rootRef.current?.parentNode.addEventListener) {
            rootRef.current.parentNode.addEventListener('mouseenter', onMouseEnter);
            rootRef.current.parentNode.addEventListener('mouseleave', onMouseLeave);
        }
    }, [rootRef.current]);

    function onMouseEnter() {
        setHoveredStatus(true);
    }

    function onMouseLeave(event: MouseEvent) {
        event.stopPropagation();

        setHoveredStatus(false);
    }

    function onButtonClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        event.stopPropagation();
        externalOnClick();
    }

    return (
        <div className={style.root} ref={rootRef}>
            {(cellIsHovered || hasNewComments) && !!externalOnClick && (
                <div
                    className={classNames(style.button, hasNewComments && style.newCommentsIndicator)}
                    onClick={onButtonClick}
                >
                    <Icon type={IconType.COMMENT10} svgSize={11} />
                </div>
            )}
        </div>
    );
};
