import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconType, WithTooltip } from 'sber-marketing-ui';

import * as styles from './FixedColumnButton.scss';

export enum Theme {
    Regular = 'Regular',
    Small = 'Small',
}

interface Props {
    theme?: Theme;
    isColumnFixed: boolean;
    onClick: () => void;
}

export function FixedColumnButton({ theme = Theme.Regular, isColumnFixed, onClick }: Props): JSX.Element {
    function onClickHandler(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();
        onClick();
    }

    switch (theme) {
        case Theme.Small:
            return <SmallTheme isColumnFixed={isColumnFixed} onClick={onClickHandler} />;
        case Theme.Regular:
        default:
            return <RegularTheme isColumnFixed={isColumnFixed} onClick={onClickHandler} />;
    }
}

interface ThemeProps {
    isColumnFixed: boolean;
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

function RegularTheme({ isColumnFixed, onClick }: ThemeProps): JSX.Element {
    return (
        <div
            className={classNames(styles.root, isColumnFixed && styles.rootActive)}
            onClick={onClick}
            {...{
                'qa-id': 'tableHeaderFixedColumnButton',
            }}
        >
            <Icon type={IconType.PIN} svgSize={16} className={styles.icon} />
            Закрепить колонку
        </div>
    );
}

function SmallTheme({ isColumnFixed, onClick }: ThemeProps): JSX.Element {
    return (
        <div
            className={classNames(styles.root, styles.rootSmallTheme, isColumnFixed && styles.rootActive)}
            onClick={onClick}
        >
            <WithTooltip content={isColumnFixed ? 'Открепить колонку' : 'Закрепить колонку'}>
                <Icon type={IconType.PIN} svgSize={16} className={styles.icon} />
            </WithTooltip>
        </div>
    );
}
