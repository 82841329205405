import * as React from 'react';
import * as moment from 'moment';
import * as lodash from 'lodash';
import autobind from 'autobind-decorator';

import { ActivityCard } from '@store/activitiesList/types';
import { LoadingStatus } from '@store/commonTypes';
import { ActivitySortingMode } from '@store/userConfig';

import { DeleteButton, ShareButton, EditButton } from '@common/Card';
import { ActivityCardStageName } from '@common/ActivityCard';
import { PageScrollWatcher } from '@common/PageScrollWatcher';

import { Props, State, ActivityCardGroup, ActivityCardGroupedProps } from './types';
import { ActivitiesListTemplate } from './ActivitiesListTemplate';

/** "ActivitiesList" container component */
export class ActivitiesListContainer extends React.Component<Props, State> {
    public state: State = {
        isRemoveModalOpened: false,
        isShareModalOpen: false,
        activityToRemoveId: null,
        activityToShareId: null,
    };

    private pageScrollWatcher: PageScrollWatcher = new PageScrollWatcher();

    public componentDidMount() {
        const { type, filters, loadingStatus, userConfigLoadingStatus } = this.props;

        this.props.setListType(type);
        this.pageScrollWatcher.reInit();
        this.pageScrollWatcher.on(PageScrollWatcher.SCROLLED_DOWN_EVENT, this.onScrolledDown);
        if (loadingStatus === LoadingStatus.NOT_LOADED && userConfigLoadingStatus === LoadingStatus.LOADED) {
            this.props.fetchMoreActivities(filters);
        }
    }

    public componentDidUpdate(prevProps: Props) {
        const prevFilters = prevProps.filters;
        const { filters, clearActivitiesList, fetchMoreActivities, loadingStatus, type, userConfigLoadingStatus } =
            this.props;
        const isTypeChanged: boolean = type !== prevProps.type;
        const isFiltersChanged = !lodash.isEqual(filters, prevFilters);
        const isLoadingStatusChanged: boolean =
            prevProps.loadingStatus !== loadingStatus || prevProps.userConfigLoadingStatus !== userConfigLoadingStatus;
        const isNotLoaded: boolean = loadingStatus === LoadingStatus.NOT_LOADED;

        if (isTypeChanged || isFiltersChanged) {
            clearActivitiesList();
            fetchMoreActivities(filters);
        } else if (isLoadingStatusChanged && isNotLoaded) {
            fetchMoreActivities(filters);
        }
    }

    public componentWillUnmount() {
        this.props.resetMyActivities();
        this.pageScrollWatcher.dispose();
    }

    public render() {
        const { loadingStatus, openActivitySidebar } = this.props;
        const { isRemoveModalOpened, isShareModalOpen, activityToShareId } = this.state;

        const isLoading = loadingStatus === LoadingStatus.LOADING || loadingStatus === LoadingStatus.NOT_LOADED;

        return React.createElement(ActivitiesListTemplate, {
            isLoading,
            isRemoveModalOpened,
            isShareModalOpen,
            activityToShareId,
            openActivitySidebar,
            closeShareModal: this.closeShareModal,
            deleteActivity: this.deleteActivity,
            closeDeleteModal: this.closeDeleteModal,
            groupedActivities: this.getMappedList(),
            isFilterModeOn: this.isFilterModeOn(),
        });
    }

    private getMappedList(): ActivityCardGroupedProps[] {
        const { userHasBudgetAccess, pendingDeletionIds, activityIdWithSidebar } = this.props;

        return this.getGroupedList().map(({ title, cards }) => ({
            title,
            cards: cards.map((activityCard) => {
                const showBindBudgetIdsButton = userHasBudgetAccess;

                return {
                    showPreloader: pendingDeletionIds.includes(activityCard.id),
                    status: activityCard.status,
                    departmentName: activityCard.department,
                    activityId: activityCard.id,
                    activityName: activityCard.name,
                    productName: activityCard.productName,
                    organizationName: activityCard.organization.name,
                    divisionName: activityCard.divisionName,
                    blockName: activityCard.blockName,
                    author: activityCard.author,
                    responsible: activityCard.responsible,
                    isKey: activityCard.isKey,
                    isLoading: activityCard.isLoading || false,
                    canSwitchKey: activityCard.canSwitchKey,
                    isFavorite: activityCard.isFavorite,
                    isUnseen: activityCard.isUnseen,
                    hasBrief: activityCard.hasBrief,
                    buttons: this.buildButtons(activityCard),
                    onFavoriteClick: () => this.onSwitchActivityFavorite(activityCard.id, !activityCard.isFavorite),
                    onSwitchKeyButtonClick: this.onSwitchKeyButtonClick(activityCard.id),
                    start: moment(activityCard.realizationStart),
                    end: moment(activityCard.realizationEnd),
                    stage: getStage(
                        moment(activityCard.realizationStart),
                        moment(activityCard.realizationEnd),
                        moment(activityCard.debriefingDate),
                    ),
                    budgetItemSerialNumbers: activityCard.budgetItemSerialNumbers,
                    showBindBudgetIdsButton,
                    isOpenedInSidebar: activityCard.id === activityIdWithSidebar,
                };
            }),
        }));
    }

    private async onSwitchActivityFavorite(id: number, isFavorite: boolean) {
        const { makeActivitiesFavorite, clearActivitiesList, fetchMoreActivities, filters } = this.props;

        await makeActivitiesFavorite(id);

        clearActivitiesList();
        fetchMoreActivities(filters);
    }

    private getGroupedList(): ActivityCardGroup[] {
        const {
            activities,
            filters: { sorting },
        } = this.props;

        let result: ActivityCardGroup[];

        switch (sorting) {
            case ActivitySortingMode.CREATION_DATE:
                result = this.getCreateTimeGroups();
                break;

            case ActivitySortingMode.START_DATE:
                result = this.getStartTimeGroups();
                break;

            case ActivitySortingMode.UNRESOLVED_FIRST:
                result = this.getSeenGroups();
                break;

            case ActivitySortingMode.UPDATING_DATE:
            default:
                result = this.getUpdateTimeGroups();
                break;
        }

        const minimal = lodash.minBy(result, ({ sortIndex }) => sortIndex);

        const favorites: ActivityCardGroup = {
            sortIndex: minimal ? minimal.sortIndex - 1 : -1,
            title: 'Важные',
            cards: [],
        };

        for (const activity of activities) {
            if (activity.isFavorite) {
                favorites.cards.push(activity);
            }
        }

        result.push(favorites);

        return lodash.sortBy(result, (group) => group.sortIndex).filter((group) => !lodash.isEmpty(group.cards));
    }

    private getCreateTimeGroups(): ActivityCardGroup[] {
        const { activities } = this.props;
        const createTimeGroups: ActivityCardGroup[] = [];
        for (const activity of activities) {
            if (!activity.isFavorite) {
                const title = getActivityCreateDate(activity);
                const sortIndex = getActivityCreateDateSortIndex(activity);
                const index = createTimeGroups.findIndex((group) => group.sortIndex == sortIndex);
                if (index === -1) {
                    createTimeGroups.push({
                        title,
                        sortIndex,
                        cards: [activity],
                    });
                } else {
                    createTimeGroups[index].cards.push(activity);
                }
            }
        }
        return createTimeGroups;
    }

    private getUpdateTimeGroups(): ActivityCardGroup[] {
        const { activities } = this.props;
        const updateTimeGroups: ActivityCardGroup[] = [];
        for (const activity of activities) {
            if (!activity.isFavorite) {
                const title = getActivityUpdateDate(activity);
                const sortIndex = getActivityUpdateDateSortIndex(activity);
                const index = updateTimeGroups.findIndex((group) => group.sortIndex == sortIndex);
                if (index === -1) {
                    updateTimeGroups.push({
                        title,
                        sortIndex,
                        cards: [activity],
                    });
                } else {
                    updateTimeGroups[index].cards.push(activity);
                }
            }
        }

        return updateTimeGroups;
    }

    private getStartTimeGroups(): ActivityCardGroup[] {
        const { activities } = this.props;
        const startTimeGroups: ActivityCardGroup[] = [];
        for (const activity of activities) {
            if (!activity.isFavorite) {
                const title = getActivityStart(activity);
                const sortIndex = getActivityStartSortIndex(activity);
                const index = startTimeGroups.findIndex((group) => group.sortIndex == sortIndex);
                if (index === -1) {
                    startTimeGroups.push({
                        title,
                        sortIndex,
                        cards: [activity],
                    });
                } else {
                    startTimeGroups[index].cards.push(activity);
                }
            }
        }
        return startTimeGroups;
    }

    private getSeenGroups(): ActivityCardGroup[] {
        const { activities } = this.props;
        const unseen: ActivityCardGroup = {
            sortIndex: 1,
            title: 'Непросмотренные',
            cards: [],
        };
        const seen: ActivityCardGroup = {
            sortIndex: 2,
            title: 'Просмотренные',
            cards: [],
        };

        for (const activity of activities) {
            if (!activity.isFavorite) {
                (activity.isUnseen ? unseen.cards : seen.cards).push(activity);
            }
        }

        return [unseen, seen];
    }

    private buildButtons({ id, canEdit, canAssignOwnerships, canDelete, organization }: ActivityCard) {
        const menuOptions: JSX.Element[] = [];
        const { userOrganizationId } = this.props;

        if (canEdit && organization.id === userOrganizationId) {
            menuOptions.push(
                React.createElement(EditButton, {
                    onClick: this.createHandlerOnEditButtonClick(id),
                    qaId: 'activityCardEditButton',
                }),
            );
        }
        if (canAssignOwnerships) {
            menuOptions.push(
                React.createElement(ShareButton, {
                    onClick: this.createHandlerOnShareButtonClick(id),
                    qaId: 'activityCardShareButton',
                }),
            );
        }
        if (canDelete) {
            menuOptions.push(
                React.createElement(DeleteButton, {
                    onClick: this.createHandlerOnDeleteButtonClick(id),
                    qaId: 'activityCardDeleteButton',
                }),
            );
        }
        return menuOptions;
    }

    private onEditActivityClick(id: number): void {
        const { history } = this.props;
        history.push(`/activity/${id}/edit`);
    }

    @autobind
    private createHandlerOnEditButtonClick(id: number): React.MouseEventHandler<HTMLElement> {
        return (event) => {
            event.preventDefault();
            this.onEditActivityClick(id);
        };
    }

    @autobind
    private createHandlerOnDeleteButtonClick(id: number): React.MouseEventHandler<HTMLElement> {
        return (event) => {
            event.preventDefault();
            this.openDeleteModal(id);
        };
    }

    @autobind
    private createHandlerOnShareButtonClick(id: number): React.MouseEventHandler<HTMLElement> {
        return (event) => {
            event.preventDefault();
            this.openSharingForm(id);
        };
    }

    private openSharingForm(activityToShareId: number): void {
        this.setState(() => ({
            activityToShareId,
            isShareModalOpen: true,
        }));
    }

    private openDeleteModal(activityToRemoveId: number): void {
        this.setState(() => ({
            activityToRemoveId,
            isRemoveModalOpened: true,
        }));
    }

    @autobind
    private closeShareModal(): void {
        this.setState(() => ({
            activityToShareId: null,
            isShareModalOpen: false,
        }));
    }

    @autobind
    private deleteActivity(): void {
        const { activityToRemoveId } = this.state;
        const { deleteActivities } = this.props;
        deleteActivities(activityToRemoveId);
        this.closeDeleteModal();
    }

    @autobind
    private closeDeleteModal(): void {
        this.setState(() => ({
            activityToRemoveId: null,
            isRemoveModalOpened: false,
        }));
    }

    @autobind
    private onScrolledDown(): void {
        const { canBeLoadedMore, loadingStatus, fetchMoreActivities, filters } = this.props;
        if (canBeLoadedMore && loadingStatus === LoadingStatus.LOADED) {
            fetchMoreActivities(filters);
        }
    }

    @autobind
    private onSwitchKeyButtonClick(activityId: number): () => void {
        const { activities } = this.props;
        const activity = activities.find((activity) => activity.id === activityId);

        if (activity) {
            return () => {
                this.props.switchKeyActivity({
                    id: activity.id,
                    isKey: !activity.isKey,
                });
            };
        }

        return () => {};
    }

    @autobind
    private isFilterModeOn(): boolean {
        const { status, activityType, sorting } = this.props.filters;

        return !!status || !!activityType.length || !!sorting;
    }
}

function getStage(start: moment.Moment, end: moment.Moment, debriefing?: moment.Moment): ActivityCardStageName {
    return moment().isAfter(end)
        ? debriefing
            ? moment().isAfter(debriefing)
                ? ActivityCardStageName.COMPLETED
                : ActivityCardStageName.RESULTS
            : ActivityCardStageName.COMPLETED
        : moment().isBefore(start)
        ? ActivityCardStageName.PREPARATION
        : ActivityCardStageName.EXECUTION;
}

function getActivityStartSortIndex({ realizationStart }: ActivityCard) {
    return dateSortIndex(realizationStart);
}

function getActivityStart({ realizationStart }: ActivityCard) {
    return formatDate(realizationStart);
}

function getActivityUpdateDateSortIndex({ updateTime }: ActivityCard) {
    return dateSortIndex(updateTime);
}

function getActivityUpdateDate({ updateTime }: ActivityCard) {
    return formatDate(updateTime);
}

function getActivityCreateDateSortIndex({ createTime }: ActivityCard): number {
    return dateSortIndex(createTime);
}

function getActivityCreateDate({ createTime }: ActivityCard) {
    return formatDate(createTime);
}

function dateSortIndex(date: string | number | Date) {
    const multiplier = -1000;
    return moment(date).hours(0).minutes(0).seconds(0).milliseconds(0).unix() * multiplier;
}

function formatDate(date: string | number | Date) {
    return moment().year() == moment(date).year() ? moment(date).format('D MMMM') : moment(date).format('D MMMM YYYY');
}
