import * as React from 'react';
import classnames from 'classnames';
import EmojiPicker, { EmojiStyle, SkinTonePickerLocation, SuggestionMode, EmojiClickData } from 'emoji-picker-react';
import { Dictionary, values, uniq } from 'lodash';
import { useSelector } from 'react-redux';
import { TaskCommentReaction } from 'sber-marketing-types/frontend';
import {
    WithTooltip,
    TooltipAnchor,
    Icon,
    IconType,
    StaticSkeleton,
    WithGlobalPopup,
    AbsoluteLikePositionCalculator,
} from 'sber-marketing-ui';

import { ReactionForm as CommonReactionForm, ReactionFormItem } from '@common/ReactionForm';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';
import { getAppUsers } from '@store/appUsers';

import * as styles from './CommentReactionForm.scss';

interface ReactionSourceDescription {
    commentId: string;
    taskId?: string;
}

type ToggleCommentReaction = (source: ReactionSourceDescription, reaction: string) => void;

interface Props {
    canInteract: boolean;
    taskId: string;
    commentId: string;
    reactions: TaskCommentReaction[];
    addReactionFormIsActive: boolean;
    addReactionFormClassName: string;
    onReplyButtonClick?: () => void;
    toggleCommentReaction: ToggleCommentReaction;
}

function useCommentReactionForm({
    taskId,
    commentId,
    reactions,
    toggleCommentReaction: toggleCommentReactionAction,
}: Props) {
    const [isUpdateInProgress, setIsUpdateInProgress] = React.useState(false);

    const reactionsDesc = React.useMemo(
        () =>
            reactions
                ? reactions.reduce((acc, reactionObj) => {
                      const { id, authorId, reaction } = reactionObj;

                      if (!acc[reaction]) {
                          acc[reaction] = {
                              id,
                              reaction,
                              users: [],
                          };
                      }

                      acc[reaction].users = uniq([...acc[reaction].users, authorId]);

                      return acc;
                  }, {} as Dictionary<ReactionProps>)
                : {},
        [reactions],
    );

    function toggleCommentReaction(reaction: string) {
        setIsUpdateInProgress(true);
        toggleCommentReactionAction({ commentId, taskId }, reaction);
    }

    React.useEffect(
        function resetUpdateInProgress() {
            setIsUpdateInProgress(false);
        },
        [reactions],
    );

    return { reactionsDesc, isUpdateInProgress, toggleCommentReaction };
}

export function CommentReactionForm(props: Props): JSX.Element {
    const { reactionsDesc, isUpdateInProgress, toggleCommentReaction } = useCommentReactionForm(props);
    const { canInteract, addReactionFormIsActive, addReactionFormClassName, onReplyButtonClick } = props;

    if (isUpdateInProgress) {
        return <ReactionsListPreloader />;
    }

    return (
        <React.Fragment>
            <div className={styles.userReactionsList}>
                {values(reactionsDesc).map((reaction) => (
                    <UsedReactionCard
                        {...reaction}
                        key={reaction.reaction}
                        canInteract={canInteract}
                        toggleCommentReaction={toggleCommentReaction}
                    />
                ))}
            </div>

            <ReactionForm
                showPreloader={isUpdateInProgress}
                addReactionFormIsActive={addReactionFormIsActive}
                addReactionFormClassName={addReactionFormClassName}
                addReaction={toggleCommentReaction}
                onReplyButtonClick={onReplyButtonClick}
            />
        </React.Fragment>
    );
}

interface ReactionProps {
    id: string;
    reaction: string;
    users: number[];
}

interface UsedReactionCardProps extends ReactionProps {
    canInteract: boolean;
    toggleCommentReaction: (reaction: string) => void;
}

function UsedReactionCard({
    id,
    reaction,
    users,
    canInteract,
    toggleCommentReaction,
}: UsedReactionCardProps): JSX.Element {
    const loginnedUserId = useSelector((state: StoreState) => getLoginUser(state).attributes.id);

    const [isHovered, setIsHovered] = React.useState(false);

    function withInteractionCheck(callback: () => void): () => void {
        return function () {
            if (canInteract) {
                callback();
            }
        };
    }

    const currentUserHasReacted = users.includes(loginnedUserId);
    const useBlueTheme = canInteract && (isHovered || currentUserHasReacted);

    return (
        <WithTooltip content={<ReactionUsersMention users={users} />} anchor={TooltipAnchor.RIGHT}>
            <div
                className={classnames(
                    styles.userReactionCard,
                    canInteract && styles.userReactionCardClickable,
                    useBlueTheme && styles.userReactionCardBlueTheme,
                )}
                onMouseEnter={withInteractionCheck(() => setIsHovered(true))}
                onMouseLeave={withInteractionCheck(() => setIsHovered(false))}
                onClick={withInteractionCheck(() => toggleCommentReaction(reaction))}
            >
                <div>{reaction}</div>

                {users.length}
            </div>
        </WithTooltip>
    );
}

interface ReactionUsersMentionProps {
    users: number[];
}

function ReactionUsersMention({ users }: ReactionUsersMentionProps): JSX.Element {
    const loginnedUserId = useSelector((state: StoreState) => getLoginUser(state).attributes.id);
    const appUsers = useSelector((state: StoreState) => getAppUsers(state).entities);

    const [hasLoginnedUser, usersDesc] = users.reduce(
        (acc, userId) => {
            const [hasLoginnedUser, users] = acc;

            if (userId === loginnedUserId) {
                return [true, users];
            } else {
                const user = appUsers[userId];

                const usersUpd = [...users, user ? `${user.firstName} ${user.secondName}` : 'Пользователь не найден'];
                return [hasLoginnedUser, usersUpd];
            }
        },
        [false, [] as string[]],
    );

    if (hasLoginnedUser) {
        usersDesc.unshift('Вы');
    }

    return <React.Fragment>{usersDesc.join(', ')}</React.Fragment>;
}

interface ReactionFormProps {
    showPreloader: boolean;
    addReactionFormIsActive: boolean;
    addReactionFormClassName: string;
    addReaction: (reaction: string) => void;
    onReplyButtonClick?: () => void;
}

const MOST_USED_REACTIONS = ['👍', '👌', '❤️', '✅'];

function ReactionForm({
    showPreloader,
    addReactionFormIsActive,
    addReactionFormClassName,
    addReaction,
    onReplyButtonClick,
}: ReactionFormProps): JSX.Element {
    const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);

    function onEmojiClick(data: EmojiClickData) {
        addReaction(data.emoji);
        setShowEmojiPicker(false);
    }

    if (showPreloader) {
        return <ReactionFormPreloader />;
    }

    const ref = React.useRef<HTMLDivElement>();

    const formIsActive = addReactionFormIsActive || showEmojiPicker;

    return (
        <React.Fragment>
            <div ref={ref}>
                <CommonReactionForm className={addReactionFormClassName} formIsActive={formIsActive}>
                    {MOST_USED_REACTIONS.map((reaction) => (
                        <ReactionFormItem key={reaction} onClick={() => addReaction(reaction)}>
                            {reaction}
                        </ReactionFormItem>
                    ))}

                    <WithTooltip spanWrapper content={'Добавить реакцию'}>
                        <ReactionFormItem onClick={() => setShowEmojiPicker(true)}>
                            <Icon
                                type={IconType.COMMENT_REACTION_FORM_ADD_REACTION_BUTTON}
                                svgSize={24}
                                className={styles.reactionFormItem}
                            />
                        </ReactionFormItem>
                    </WithTooltip>

                    {onReplyButtonClick && (
                        <WithTooltip spanWrapper content={'Ответить'}>
                            <ReactionFormItem onClick={onReplyButtonClick}>
                                <Icon
                                    type={IconType.COMMENT_REACTION_FORM_REPLY_BUTTON}
                                    svgSize={24}
                                    className={styles.reactionFormItem}
                                />
                            </ReactionFormItem>
                        </WithTooltip>
                    )}
                </CommonReactionForm>
            </div>

            {showEmojiPicker && (
                <WithGlobalPopup
                    positionCalculator={AbsoluteLikePositionCalculator}
                    container={ref}
                    onMaskClick={() => setShowEmojiPicker(false)}
                    top={0}
                    left={14}
                    zIndex={100}
                >
                    <div className={styles.emojiPicker}>
                        <EmojiPicker
                            onEmojiClick={onEmojiClick}
                            // searchDisabled
                            // width="100%"
                            height={300}
                            skinTonesDisabled
                            previewConfig={{
                                showPreview: false,
                            }}
                            emojiStyle={EmojiStyle.APPLE}
                            skinTonePickerLocation={SkinTonePickerLocation.PREVIEW}
                            emojiVersion="0.6"
                            lazyLoadEmojis
                            suggestedEmojisMode={SuggestionMode.RECENT}
                            // defaultSkinTone={SkinTones.LIGHT}
                            // emojiStyle={EmojiStyle.NATIVE}
                        />
                    </div>
                </WithGlobalPopup>
            )}
        </React.Fragment>
    );
}

function ReactionsListPreloader(): JSX.Element {
    return (
        <div className={styles.userReactionsList}>
            <ReactionListItemPrelaoder />
            <ReactionListItemPrelaoder />
            <ReactionListItemPrelaoder />
        </div>
    );
}

function ReactionListItemPrelaoder(): JSX.Element {
    return (
        <div className={styles.userReactionCard}>
            <StaticSkeleton className={styles.reactionItemPreloader} />
        </div>
    );
}

function ReactionFormPreloader(): JSX.Element {
    return (
        <div className={styles.reactionForm}>
            <StaticSkeleton className={styles.reactionFormItemPreloader} />
            <StaticSkeleton className={styles.reactionFormItemPreloader} />
            <StaticSkeleton className={styles.reactionFormItemPreloader} />
            <StaticSkeleton className={styles.reactionFormItemPreloader} />
        </div>
    );
}
