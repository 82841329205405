export interface Point {
    x: number;
    y: number;
}

export interface Size {
    width: number;
    height: number;
}

export interface CellPosition {
    columnName: ColumnName;
    lineId: LineId;
}

export interface ColumnHeaderParams {
    component: React.Component;
    columnHeaderProps: any;
    disableWidthChange: boolean;
}

export interface CellParams {
    component: React.Component;
    cellProps: any;
    readOnly: boolean;
    сellBackgroundColor: string;
    onPropsChange?: (cellProps: any) => void;
}

export type CellUpdateHandler = (cellProps: any) => void;

export type ColumnName = string;
export type LineId = string;

export type ColumnWidths = Record<ColumnName, number>;
export type LineHeights = Record<LineId, number>;

export type ColumnHeadersStorage = Record<ColumnName, ColumnHeaderParams>;
export type CellsStorage = Record<LineId, Record<ColumnName, CellParams>>;

export enum TableEvent {
    Scroll = 'scroll',
    SizeChange = 'size_change',
}

export const enum CellEvent {
    Click = 'click',
    MouseEnter = 'mouse_enter',
    MouseLeave = 'mouse_leave',
    MouseSelection = 'mouseSelection',
    ArrowsSelection = 'arrowsSelection',
    EditStart = 'edit_start',
    EditEnd = 'edit_end',
}

export interface Filter {
    columnName: ColumnName;
    selectedValues: React.ReactText[];
}

export interface SortingParams {
    columnName: ColumnName;
    orderType: OrderType;
}

export const enum OrderType {
    Asc = 'asc',
    Desc = 'desc',
}
