// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-brief-Brief-Block-Field-FieldTypes-SwitchGroup-SwitchGroupFields-_SwitchGroupFields__fields{width:100%}.src-client-modules-brief-Brief-Block-Field-FieldTypes-SwitchGroup-SwitchGroupFields-_SwitchGroupFields__fieldsWrapper{width:100%;margin:2px 0;padding:0 10px;background:#F6F6F6;border:1px solid #E3E9E5;border-radius:8px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/brief/Brief/Block/Field/FieldTypes/SwitchGroup/SwitchGroupFields/SwitchGroupFields.scss"],"names":[],"mappings":"AAAA,gHACI,UAAW,CACd,uHAGG,UAAW,CACX,YAAa,CACb,cAAe,CACf,kBAAmB,CACnB,wBAAyB,CACzB,iBAAkB","sourcesContent":[".fields {\n    width: 100%;\n}\n\n.fieldsWrapper {\n    width: 100%;\n    margin: 2px 0;\n    padding: 0 10px;\n    background: #F6F6F6;\n    border: 1px solid #E3E9E5;\n    border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fields": "src-client-modules-brief-Brief-Block-Field-FieldTypes-SwitchGroup-SwitchGroupFields-_SwitchGroupFields__fields",
	"fieldsWrapper": "src-client-modules-brief-Brief-Block-Field-FieldTypes-SwitchGroup-SwitchGroupFields-_SwitchGroupFields__fieldsWrapper"
};
export default ___CSS_LOADER_EXPORT___;
