import * as React from 'react';
import classNames from 'classnames';
import { Brief as BriefParams } from 'sber-marketing-types/frontend';
import { Icon, IconType, Preloader } from 'sber-marketing-ui';

import { SchemeSelectionForm } from './SchemeSelectionForm';

import * as style from './Header.scss';

interface Props {
    brief: BriefParams;
    editRight: boolean;
    isLoading: boolean;
    isBriefCopyDisabled: boolean;
    isBriefPasteDisabled: boolean;
    isBriefExportDisabled: boolean;
    isBriefReady: boolean;
    isExpanded: boolean;
    onBriefCopy: () => void;
    onBriefPaste: () => void;
    onSchemeSelection: (schemeId: string) => void;
    toggleIsExpandedState: () => void;
}

export const Header: React.FC<Props> = ({
    brief,
    editRight,
    isLoading,
    isBriefCopyDisabled,
    isBriefPasteDisabled,
    isBriefExportDisabled,
    isBriefReady,
    isExpanded,
    onBriefCopy,
    onBriefPaste,
    onSchemeSelection,
    toggleIsExpandedState,
}) => {
    return (
        <div className={classNames(style.root, `section`)} data-section-id={``}>
            <div className={style.header}>
                <div className={style.titleContainer} onClick={toggleIsExpandedState}>
                    <Icon
                        svgSize={8}
                        type={IconType.TRIANGLE8_DOWN}
                        className={classNames(
                            style.toggleIsOpenedButton,
                            isExpanded && style.toggleIsOpenedButtonActive,
                        )}
                    />

                    <div className={style.title}>Бриф</div>
                </div>

                {isExpanded && (
                    <div className={style.buttons}>
                        <div
                            className={classNames(style.button, isBriefCopyDisabled ? style.button_disabled : '')}
                            onClick={isBriefCopyDisabled ? null : onBriefCopy}
                        >
                            <Icon type={IconType.COPY_BOLD} svgSize={14} />
                            Скопировать
                        </div>
                        <div
                            className={classNames(
                                style.button,
                                (isBriefPasteDisabled || !editRight) && style.button_disabled,
                            )}
                            onClick={isBriefPasteDisabled || !editRight ? null : onBriefPaste}
                        >
                            <Icon type={IconType.PASTE} svgSize={14} />
                            Вставить
                        </div>
                    </div>
                )}
            </div>

            {isExpanded && (
                <SchemeSelectionForm
                    briefId={brief ? brief.id : null}
                    isBriefReady={isBriefReady}
                    editRight={editRight}
                    onSchemeSelection={onSchemeSelection}
                />
            )}

            {isLoading && <Preloader />}
        </div>
    );
};
