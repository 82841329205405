import * as React from 'react';
import * as moment from 'moment';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';

import { useGetTaskQuery, useSetTaskMutation } from '@api';

import { DatesFormatter } from '@common/Utils';

import { Tag, DropdownTag, DropdownTagProps } from '@common/components';
import { TaskDeadlineCalendar } from '@modules/task/components';

import { TaskAccessAction, useTaskAccess } from '@modules/task/hooks';

import * as styles from './TaskDeadline.scss';

export interface TaskDeadlineProps extends DropdownTagProps {
    taskId: string;
    activityId?: number;
}

export const TaskDeadline: React.FC<TaskDeadlineProps> = ({
    taskId,
    activityId,
    children,
    absolute,
    stretch,
    className,
    loading,
    type,
    ...props
}) => {
    const {
        isLoading: isAuthorLoading,
        access: [canChange],
    } = useTaskAccess([TaskAccessAction.changeDeadline], taskId, activityId);
    const [setTask, { isLoading: isUpdating }] = useSetTaskMutation();
    const { data } = useGetTaskQuery({ id: taskId });
    const deadlineText = data?.task.deadline
        ? DatesFormatter.ddMonthyyyy(data.task.deadline)
        : canChange
        ? 'Выберите'
        : 'Не заполнено';

    const normDanger = type === 'danger' || new Date(data?.task.deadline) < new Date();
    const isLoading = loading || isAuthorLoading || isUpdating;

    const content: any = (
        <>
            <Icon className={styles.icon} svgSize={16} type={IconType.CALENDAR_ICON} />
            {deadlineText}
            {children}
        </>
    );

    const rootClassName = classNames(styles.root, normDanger && styles.danger, className);

    if (!canChange) {
        return (
            <Tag
                data-qa-id="TaskDeadline"
                type={normDanger ? 'danger' : 'default'}
                {...props}
                loading={isLoading}
                className={rootClassName}
            >
                {content}
            </Tag>
        );
    }

    const handleDeadlineSelect = (date: moment.Moment) => {
        setTask({ id: taskId, deadline: date.toString() });
    };

    return (
        <DropdownTag
            data-qa-id="TaskDeadline"
            absolute={absolute}
            stretch={stretch}
            tagContent={content}
            type={normDanger ? 'danger' : 'default'}
            {...props}
            loading={isLoading}
            className={rootClassName}
        >
            <div className={styles.wrapper}>
                <TaskDeadlineCalendar onSelect={handleDeadlineSelect} taskId={taskId} />
            </div>
        </DropdownTag>
    );
};
