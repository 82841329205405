import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { FilterItem } from 'sber-marketing-ui';
import { BudgetItem } from '@mrm/budget';

import { BudgetItemSelect } from './BudgetItemSelect';
import { StoreState } from '@store';
import { getActivityReferenceData } from '@store/brief/selectors';
import { setActivityReferenceMenuSelectedBudgetItemIds } from '@store/brief/actions';
import type { ActivityReferenceFilter } from '@store/brief/types';
import { getLoginUser } from '@store/user';

interface Props extends Partial<MapProps & DispatchProps> {
    activityId: number;
    budgetId: string;
    budgetItems: BudgetItem[];
}

interface MapProps {
    userId: number;
    selectedItemIds: string[];
    filters: ActivityReferenceFilter;
}

interface DispatchProps {
    setSelectedBudgetItemIds: (ids: string[]) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BudgetItemSelectContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { activityId, budgetId, selectedItemIds } = this.props;

        return (
            <BudgetItemSelect
                activityId={activityId}
                budgetId={budgetId}
                selectItems={this.makeSelectItems()}
                selectedItemIds={selectedItemIds}
                onItemSelect={this.onItemSelect}
            />
        );
    }

    @autobind
    private onItemSelect(selectedIds: string[]): void {
        this.props.setSelectedBudgetItemIds(selectedIds);
    }

    private makeSelectItems(): FilterItem[] {
        const filteredBudgetItems = this.filterBudgetItems();

        const filterItems = filteredBudgetItems.map((budgetItem) => ({
            id: budgetItem.id,
            title: budgetItem.serialNumber.toString(),
            description: budgetItem.activity.name,
        }));

        return lodash.sortBy(filterItems, ['description', 'title']);
    }

    private filterBudgetItems(): BudgetItem[] {
        const { budgetItems, filters } = this.props;

        const filterDivisionIds = filters?.divisionIds;

        return !filterDivisionIds?.length
            ? budgetItems.filter((budgetItem) => budgetItem.actions.canEdit || budgetItem.actions.canView)
            : budgetItems
                  .filter((budgetItem) => budgetItem.actions.canEdit || budgetItem.actions.canView)
                  .filter((budgetItem) => {
                      const budgetItemDivisionId = budgetItem?.dictionary?.division?.id;
                      return budgetItemDivisionId
                          ? lodash.includes(filterDivisionIds, budgetItemDivisionId) || budgetItemDivisionId
                          : false;
                  });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { selectedBudgetItemIds, filters } = getActivityReferenceData(state);
    const user = getLoginUser(state);

    const userId = user.attributes.id;

    return {
        userId,
        selectedItemIds: selectedBudgetItemIds,
        filters,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return {
        setSelectedBudgetItemIds: (ids: string[]) => dispatch(setActivityReferenceMenuSelectedBudgetItemIds(ids)),
    };
}
