import * as React from 'react';
// import * as classnames from 'classnames';

import * as style from './Table.scss';

import type { ColumnName, LineId } from './types';

import { TableViewModel } from './TableViewModel';
import { TableView } from './TableView';

interface Props {
    viewModel: TableViewModel;
    columns: ColumnName[];
    lines: LineId[];
}

export const TableTemplate = ({ viewModel, columns, lines }: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <TableView viewModel={viewModel} columns={columns} lines={lines} />
        </div>
    );
};
