import { useAuthUserOrganizations } from '@modules/auth/hooks';
import { useMemo } from 'react';

export interface UseAuthUserOrganizationIds {
    isLoading: boolean;
    organizationIds: string[];
}

export function useAuthUserOrganizationIds(skip?: boolean): UseAuthUserOrganizationIds {
    const { isLoading, organizations } = useAuthUserOrganizations(skip);

    const organisationIds = useMemo(() => organizations.map(({ id }) => id), [organizations]);

    return { isLoading, organizationIds: organisationIds };
}
