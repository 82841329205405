import { OneTaskResponseParams, TaskStatus as ApiTaskStatus } from 'sber-marketing-types/backend';

const budgetRequiredKeys: string[] = [
    'clientDivisionId',
    'clientName',
    'budgetItemId',
    'productId',
    'period',
    'segmentId',
];

export function checkIfTaskStatusAvailableChange(status: ApiTaskStatus, task?: OneTaskResponseParams) {
    if (!task || !task.canEdit || task.status === status) return false;

    if (task.status !== ApiTaskStatus.Draft) return true;
    if (!task.executorId || !task.deadline) return false;

    if (!task.budgetApproval) return true;

    const budgetKeys = Object.keys(task.budgetApproval);

    return budgetRequiredKeys.every((key) => budgetKeys.includes(key));
}
