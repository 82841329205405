import * as React from 'react';
import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import { InternalTransferDirection, getBudgetTransferMenuState } from '@store/budgetExecution/budgetTransferMenu';

import { CellDescription, CellDescriptionTemplates } from '../../CellDescription';

import * as styles from './CellDescriptions.scss';

function useStore() {
    const useMonth = useSelector(
        (state: StoreState) =>
            getBudgetTransferMenuState(state).controls.internalTransferDirection ===
            InternalTransferDirection.ManyToOne,
    );

    const currentToCell = useSelector((state: StoreState) => {
        const {
            cells: { to },
            controls: { rowForSumEntering, internalTransferDirection },
        } = getBudgetTransferMenuState(state);

        if (internalTransferDirection === InternalTransferDirection.ManyToOne) {
            return to[0];
        } else {
            return to.find((toCell) => toCell.lineId === rowForSumEntering);
        }
    });

    return { useMonth, currentToCell };
}

export function DestCellDescription(): JSX.Element {
    const { useMonth, currentToCell } = useStore();

    return (
        <div className={styles.root}>
            <CellDescription
                qaId="internalTransitionSumEnteringDestCellDescription"
                useMonth={useMonth}
                cell={currentToCell}
                template={CellDescriptionTemplates.CellFullWithLineReservePlanDiff}
            />
        </div>
    );
}
