import * as React from 'react';
import * as styles from './styles.scss';

import type { EstimateValue } from '../types';
import { getEstimateValueStyle } from './utils';

interface Props {
    hoveredEstimateValue: EstimateValue;
    onEstimateValueMouseEnter: (estimateValue: EstimateValue) => void;
    onEstimateValuesMouseLeave: () => void;
    onEstimateValueClick: (estimateValue: EstimateValue) => void;
}

const ESTIMATE_VALUES: EstimateValue[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const EstimateBarTemplate: React.FC<Props> = ({
    hoveredEstimateValue,
    onEstimateValueClick,
    onEstimateValueMouseEnter,
    onEstimateValuesMouseLeave,
}) => {
    return (
        <div className={styles.root}>
            <div className={styles.values} onMouseLeave={() => onEstimateValuesMouseLeave()}>
                {ESTIMATE_VALUES.map((currentEstimateValue) => (
                    <div
                        key={currentEstimateValue}
                        className={styles.value}
                        onClick={() => onEstimateValueClick(currentEstimateValue)}
                        onMouseEnter={() => onEstimateValueMouseEnter(currentEstimateValue)}
                        style={getEstimateValueStyle({
                            currentEstimateValue,
                            hoveredEstimateValue,
                        })}
                    >
                        {currentEstimateValue}
                    </div>
                ))}
            </div>
            <div className={styles.hints}>
                <div className={styles.hint}>Очень плохо</div>
                <div className={styles.hint}>Очень хорошо</div>
            </div>
        </div>
    );
};
