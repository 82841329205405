import * as React from 'react';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import * as lodash from 'lodash';

import { ProductParams, OrganizationView } from 'sber-marketing-types/frontend';

import { FilterMenu, FilterItem, Filter, SelectionStatus } from 'sber-marketing-ui';
import { StoreState } from '@store';
import { getAllLoadedOrganizations } from '@store/organizations';

interface Props extends Partial<MapProps> {
    products: ProductParams[];
    selectedItems: string[];
    onCheckItems: (checkedItems: React.ReactText[]) => void;
    onFilterChange?: () => void;
}

interface MapProps {
    organizations: OrganizationView[];
}

interface State {
    groupedProductIds: lodash.Dictionary<string[]>;
    items: FilterItem[];
    selectedItemIds: string[];
}

@(connect(mapStateToProps, null, null, { forwardRef: true }) as any)
export class ProductsFilter extends React.PureComponent<Props, State> implements Filter {
    private filterMenuRef: React.RefObject<FilterMenu> = React.createRef();

    public constructor(props: Props) {
        super(props);

        this.state = {
            groupedProductIds: {},
            items: [],
            selectedItemIds: [],
        };
    }

    public static getDerivedStateFromProps(props: Props): State {
        const { products, selectedItems, organizations } = props;

        const groupedProducts = lodash.groupBy(lodash.compact(products), (product) =>
            ProductsFilter.generateKey(product),
        );
        const items = Object.keys(groupedProducts).map((key) => {
            const organization = organizations.find(
                (organization) => organization.id === groupedProducts[key][0].organizationId,
            );

            return {
                id: key,
                title: groupedProducts[key][0].name,
                organization: organization ? organization.name : 'Неизвестная организация',
                description: organization ? organization.name : 'Неизвестная организация',
            };
        });
        const groupedProductIds = Object.keys(groupedProducts).reduce(
            (acc, key) => ({
                ...acc,
                [key]: groupedProducts[key].map((product) => product.id),
            }),
            {},
        );

        const selectedItemIds = Object.keys(
            lodash.groupBy(
                products.filter((product) => selectedItems.includes(product.id)),
                (product) => ProductsFilter.generateKey(product),
            ),
        );

        return {
            items,
            groupedProductIds,
            selectedItemIds,
        };
    }

    private static generateKey(product: ProductParams): string {
        return `${product.name}-${product.organizationId}`;
    }

    public render(): JSX.Element {
        const { items, selectedItemIds } = this.state;

        return (
            <FilterMenu
                sortSelectedItemsToTop
                qaId="dashboardProductFilter"
                ref={this.filterMenuRef}
                filterTitle="Продукты"
                items={items}
                checkedItemsIds={selectedItemIds}
                onItemSelection={this.onItemSelection}
            />
        );
    }

    public getSelectionStatus(): SelectionStatus {
        return this.filterMenuRef.current ? this.filterMenuRef.current.getSelectionStatus() : SelectionStatus.None;
    }

    public check() {
        this.filterMenuRef.current.check();
    }

    public uncheck() {
        this.filterMenuRef.current.uncheck();
    }

    @autobind
    private onItemSelection(selectedIds: string[]): void {
        if (this.props.onFilterChange) {
            this.props.onFilterChange();
        }

        const ids = selectedIds.reduce((acc, id) => [...acc, ...this.state.groupedProductIds[id]], []);

        this.props.onCheckItems(ids);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        organizations: getAllLoadedOrganizations(state),
    };
}
