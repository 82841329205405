import * as React from 'react';
import { isEmpty } from 'lodash';
import { LabeledInput, Button_redesign, ButtonTheme_redesign, Popup } from 'sber-marketing-ui';
import classNames from 'classnames';

import { DepartmentsUsersSelectSuggest } from '@common/DepartmentsUsersSelectSuggest';

import { ChannelEditorTemplateProps } from './types';

import * as styles from './ChannelEditor.scss';

const NAME_INPUT_TITLE = 'НАЗВАНИЕ КАНАЛА';

const CANCEL_BUTTON_LABEL = 'ОТМЕНА';

const SUBMIT_BUTTON_LABEL = 'ГОТОВО';

/** "ChannelEditor" template component */
export const ChannelEditorTemplate: React.SFC<ChannelEditorTemplateProps> = ({
    authorId,
    className,
    channelName,
    selectedUsers,
    taskParticipantsIds,
    onChannelNameChange,
    showNameInput,
    onSubmit,
    onCancel,
    onAddParticipants,
    onRemoveParticipants,
}: ChannelEditorTemplateProps) => (
    <Popup onOutOfContentClick={onCancel}>
        <div
            className={classNames(styles.root, className)}
            {...{
                'qa-id': 'taskChannelEditorPopup',
            }}
        >
            {showNameInput && (
                <div className={styles.nameInput}>
                    <LabeledInput
                        qaId="taskChannelEditorPopupInput"
                        title={NAME_INPUT_TITLE}
                        value={channelName}
                        onValueChange={onChannelNameChange}
                    />
                </div>
            )}

            <DepartmentsUsersSelectSuggest
                availableUsers={taskParticipantsIds}
                selectedUsers={selectedUsers}
                addEditingParticipants={onAddParticipants}
                removeEditingParticipants={onRemoveParticipants}
            />

            <div className={styles.buttons}>
                <div className={classNames(styles.button, styles.button_cancel)}>
                    <Button_redesign theme={ButtonTheme_redesign.Ghost} onClick={onCancel}>
                        {CANCEL_BUTTON_LABEL}
                    </Button_redesign>
                </div>

                <div className={classNames(styles.button, styles.button_submit)}>
                    <Button_redesign
                        theme={ButtonTheme_redesign.Main}
                        onClick={onSubmit}
                        disabled={
                            isEmpty(channelName) || isEmpty(selectedUsers.filter((user) => user.userId !== authorId))
                        }
                    >
                        {SUBMIT_BUTTON_LABEL}
                    </Button_redesign>
                </div>
            </div>
        </div>
    </Popup>
);
