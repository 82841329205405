import * as React from 'react';
import { HistoryActionType } from 'sber-marketing-types/backend';

import { NewsItemParams } from '@store/news';

import { ActivityName } from './ActivityName';
import { TaskDescription } from './TaskDescription';
import { NewResponsibleDescription } from './NewResponsibleDescription';
import { Comment } from './Comment';
import { File } from './File';
import { Channel } from './Channel';
import { DifferenceList } from './DifferenceList';

interface Props {
    item: NewsItemParams;
}

const SHOULD_RENDER_TASK_DESCRIPTION = [
    HistoryActionType.AddTaskParticipant,
    HistoryActionType.UpdateTaskExecutor,
    HistoryActionType.TaskClosed,
    HistoryActionType.TaskToProgress,
    HistoryActionType.UpdateTaskDeadline,
    HistoryActionType.RemoveTaskParticipant,
    HistoryActionType.AddTaskComment,
    HistoryActionType.MentionedInTaskComment,
    HistoryActionType.AddTaskFile,
    HistoryActionType.AddTaskCommentFile,
    HistoryActionType.DeleteTask,
    HistoryActionType.AddTaskChannel,
    HistoryActionType.UpdateTask,
    HistoryActionType.TaskReturned,
];

const SHOULD_RENDER_DIFFERENCE_LIST = [
    HistoryActionType.UpdateTaskExecutor,
    HistoryActionType.ActivityRealizationChanged,
    HistoryActionType.UpdateTaskDeadline,
    HistoryActionType.ActivityPreparationTimingChanged,
    HistoryActionType.ActivityDebriefingTimingChanged,
    HistoryActionType.UpdateActivity,
    HistoryActionType.UpdateTask,
];

export function OptionalParts({
    item: {
        action,
        shouldDisplayActivityName,
        activityName,
        activityId,
        taskId,
        commentText,
        fileName,
        taskName,
        shouldDisplayTaskName,
        taskChannelName,
        wasActivityAssignedToLoginedUser,
        newResponsibleId,
        wasTaskAssignedToLoginedUser,
        difference,
    },
}: Props): JSX.Element {
    const shouldRenderNewResponsibleDescription: boolean =
        action === HistoryActionType.ChangeResponsible && !wasActivityAssignedToLoginedUser;

    const shouldRenderTaskDescription: boolean =
        shouldDisplayTaskName && SHOULD_RENDER_TASK_DESCRIPTION.indexOf(action) !== -1;

    const shouldRenderComment: boolean =
        action === HistoryActionType.AddTaskComment || action === HistoryActionType.MentionedInTaskComment;

    const shouldRenderFile: boolean =
        action === HistoryActionType.AddTaskFile || action === HistoryActionType.AddTaskCommentFile;

    const shouldRenderChannel: boolean =
        action === HistoryActionType.AddTaskChannel || action == HistoryActionType.DeleteTaskChannel;

    const shouldRenderDifferenceList: boolean =
        !wasTaskAssignedToLoginedUser && SHOULD_RENDER_DIFFERENCE_LIST.indexOf(action) !== -1;

    return (
        <React.Fragment>
            {shouldDisplayActivityName && (
                <ActivityName
                    action={action}
                    activityId={activityId}
                    activityName={activityName}
                    wasActivityAssignedToLoginedUser={wasActivityAssignedToLoginedUser}
                    wasTaskAssignedToLoginedUser={wasTaskAssignedToLoginedUser}
                />
            )}

            {shouldRenderNewResponsibleDescription ? <NewResponsibleDescription id={newResponsibleId} /> : null}

            {shouldRenderTaskDescription ? (
                <TaskDescription
                    action={action}
                    activityId={activityId}
                    taskId={taskId}
                    taskName={taskName}
                    wasTaskAssignedToLoginedUser={wasTaskAssignedToLoginedUser}
                />
            ) : null}

            {shouldRenderComment ? <Comment text={commentText} /> : null}

            {shouldRenderFile ? <File fileName={fileName} /> : null}

            {shouldRenderChannel ? <Channel name={taskChannelName} /> : null}

            {shouldRenderDifferenceList ? <DifferenceList action={action} difference={difference} /> : null}
        </React.Fragment>
    );
}
