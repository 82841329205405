import * as React from 'react';
import autobind from 'autobind-decorator';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { AllAssets } from './AllAssets';
import { FileAssetProps, FileAssetData, FileAssetSize } from '../../../../../common/FileAsset';
import { StoreState } from '@store';
import { selectors, removeFileFromCommentary, removeFileFromTask, AllAssetsSortOrder } from '@store/taskPage';
import { getLoginUser } from '@store/user/selector';
import { FileAssetTarget } from '@store/commonTypes';
import { withTaskData, PropsWithTaskData } from '../../withTaskData';

export enum AssetsListSortOrder {
    ASC = 'asc',
    DESC = 'desc',
}

export enum AssetsListMode {
    LIST = 'list',
    TILES = 'tiles',
}

/** All assets properties store */
export interface AllAssetsStoreProps {
    /** Additional CSS class */
    className?: string;
    /** Assets list order */
    sortOrder: AllAssetsSortOrder;
    /** Assets list showing mode */
    listMode: AssetsListMode;
    /** Component mode (base assets or current assets) */
    mode?: 'base' | 'current';
    /** Number of hidden assets */
    assetsToShow: number;
    /** Change display mode button click handler */
    onChangeDisplayModeClick(): any;
    /** Sort assets button click handler */
    onSortAssetsClick(): any;
    /** Load more assets button click handler */
    onLoadMoreAssetsButtonClick(assetsToShow: number): any;
}

/** Mapped Redux Store state */
interface MappedState {
    /** Additional title */
    additionalTitle: string;
    /** Assets to show */
    assets: FileAssetProps[];
}

/** Mapped Redux Store actions */
interface MappedActions {
    /** Remove file from commentary */
    removeFileFromCommentary(commentId: string, fileName: string): void;
    /** Remove file from task */
    removeFileFromTask(fileName: string): void;
}

/** Properties */
type Props = PropsWithTaskData<AllAssetsStoreProps> & MappedState & MappedActions;

/** Redux Store state mapper */
function mapStateToProps(
    state: StoreState,
    { sortOrder, listMode, channelId, mode = 'base' }: PropsWithTaskData<AllAssetsStoreProps>,
): MappedState {
    const { attributes } = getLoginUser(state);
    const { authorId } = selectors.getTaskInfo(state);
    const isAuthor: boolean = authorId === attributes.id;
    const assets = selectors.sortAssets(
        mode === 'base' ? selectors.getBaseAssets(state) : selectors.getChannelAssets(state, channelId),
        sortOrder,
    );
    const additionalTitle =
        mode === 'base'
            ? 'ЗАДАЧИ И ОСНОВНОГО КАНАЛА'
            : `КАНАЛА "${selectors.getChannelById(state, channelId)?.title?.toUpperCase?.()}"`;
    return {
        additionalTitle,
        assets: assets.map(({ uploadedBy, targetType, ...restAsset }) => ({
            ...restAsset,
            targetType,
            isDeletable: (targetType === FileAssetTarget.TASK && isAuthor) || uploadedBy === String(attributes.id),
            size: listMode === AssetsListMode.LIST ? FileAssetSize.SMALL : FileAssetSize.BIG,
        })),
    };
}

/** Redux Store actions mapper */
function mapDispatchToProps(dispatch: Dispatch<StoreState>): MappedActions {
    return {
        removeFileFromCommentary: (commentaryId: string, fileName: string) =>
            dispatch(
                removeFileFromCommentary({
                    commentaryId,
                    fileName,
                }),
            ),
        removeFileFromTask: (fileName: string) => dispatch(removeFileFromTask({ fileName })),
    };
}

/** Store enhancer */
const StoreEnhancer = connect(mapStateToProps, mapDispatchToProps);

/** All assets store component */
class AllAssetsStoreComponent extends React.Component<Props> {
    public render(): JSX.Element {
        const {
            className,
            assetsToShow,
            onSortAssetsClick,
            onChangeDisplayModeClick,
            additionalTitle,
            assets,
            listMode,
            sortOrder,
        } = this.props;
        return (
            <AllAssets
                additionalTitle={additionalTitle}
                className={className}
                assetsToShow={assetsToShow}
                moreCount={assets.length - assetsToShow}
                onLoadMoreAssetsButtonClick={this.onLoadMoreAssetsButtonClick}
                onSortAssetsClick={onSortAssetsClick}
                onChangeDisplayModeClick={onChangeDisplayModeClick}
                assets={assets}
                onRemoveAssetClick={this.onRemoveAssetClick}
                sortOrder={sortOrder}
                isTiles={listMode === AssetsListMode.TILES}
            />
        );
    }

    @autobind
    protected onLoadMoreAssetsButtonClick(): void {
        this.props.onLoadMoreAssetsButtonClick(this.props.assets.length);
    }

    @autobind
    protected onRemoveAssetClick({ name, targetType, targetId }: FileAssetData): void {
        switch (targetType) {
            case FileAssetTarget.TASK: {
                this.props.removeFileFromTask(name);
                break;
            }
            case FileAssetTarget.COMMENTARY:
            default: {
                this.props.removeFileFromCommentary(targetId, name);
                break;
            }
        }
    }
}

export const AllAssetsStore: React.ComponentType<AllAssetsStoreProps> = withTaskData(
    StoreEnhancer(AllAssetsStoreComponent),
);
