export const enum Direction {
    UP = 'Up',
    DOWN = 'Down',
    NEUTRAL = 'neutral',
}

export const enum Result {
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
    NEUTRAL = 'neutral',
}
