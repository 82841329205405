import * as React from 'react';
import classNames from 'classnames';
import * as lodash from 'lodash';

import * as style from './TableHeader.scss';

import {
    ColumnName,
    ColumnsVisiblityFilter,
    ColumnsWidth,
    SortingMode,
    OrderType,
    Filters,
    ColumnFilters,
    CellValueType,
} from '@store/budgetExecution/types';

import { HeaderDropdownCell } from '../CellTypes';
import { ColumnsList } from '../../ColumnsConfig';

const LINE_MENU_WIDTH = 184;

interface Props {
    columnsVisiblityFilter: ColumnsVisiblityFilter;
    columnsWidth: ColumnsWidth;
    sortingMode: SortingMode;
    filters: Filters;
    fixedColumnsNames: ColumnName[];
    hoveredColumnName: ColumnName;
    draggedEdgeColumnName: ColumnName;
    isResizingColumn: boolean;
    headerFixedColumnsRef: (element: HTMLDivElement) => void;
    onCellMouseEnter: (columnName: ColumnName) => void;
    onCellMouseLeave: () => void;
    onDropdownClick: (columnName: ColumnName, dropdownContent: JSX.Element) => void;
    onSortingChange: (columnName: ColumnName, sortingOrder: OrderType) => void;
    onColumnFixChange?: (columnName: ColumnName) => void;
    onFiltersSelection: (columnName: ColumnName, filters: ColumnFilters) => void;
    onColumnEdgeMousedown: (columnName: ColumnName, mouseDownX: number) => void;
    onApplyFiltersButtonClick: () => void;
}

export const TableHeader = ({
    columnsVisiblityFilter,
    columnsWidth,
    sortingMode,
    filters,
    fixedColumnsNames = [],
    hoveredColumnName,
    draggedEdgeColumnName,
    isResizingColumn,
    headerFixedColumnsRef,
    onCellMouseEnter,
    onCellMouseLeave,
    onDropdownClick,
    onSortingChange,
    onColumnFixChange,
    onFiltersSelection,
    onColumnEdgeMousedown,
    onApplyFiltersButtonClick,
}: Props): JSX.Element => {
    const allColumnsAreHidden = lodash.every(columnsVisiblityFilter, (item) => item === false);

    const visibleColumns = allColumnsAreHidden
        ? ColumnsList
        : ColumnsList.filter((column) => columnsVisiblityFilter[column.name]);

    const nonfixedColumns = visibleColumns.filter((item) => !lodash.includes(fixedColumnsNames, item.name));

    const fixedColumns = fixedColumnsNames
        .filter((item) => visibleColumns.some((column) => column.name == item))
        .map((item) => visibleColumns.find((column) => column.name == item));

    const fixedColumnsWidthSum = fixedColumns.reduce((acc, item) => acc + columnsWidth[item.name], 0);

    return (
        <div
            className={style.root}
            style={{
                paddingLeft: fixedColumnsWidthSum,
                paddingRight: LINE_MENU_WIDTH,
            }}
        >
            {nonfixedColumns.map((column) => (
                <React.Fragment key={column.name}>
                    <div
                        className={classNames(
                            style.cell,
                            column.name == hoveredColumnName && style.hover,
                            column.name == draggedEdgeColumnName && style.dragged,
                        )}
                        style={{ width: columnsWidth[column.name] }}
                        onMouseEnter={() => onCellMouseEnter(column.name)}
                        onMouseLeave={onCellMouseLeave}
                    >
                        <HeaderDropdownCell
                            name={column.name}
                            title={column.title}
                            currencySearch={column.valueType === CellValueType.Currency}
                            filters={filters[column.name]}
                            columnValueType={column.valueType}
                            isResizingColumn={isResizingColumn}
                            isColumnFixed={lodash.includes(fixedColumnsNames, column.name)}
                            orderType={column.name == sortingMode.columnName ? sortingMode.order : null}
                            onOpenStatusChange={(content) => onDropdownClick(column.name, content)}
                            onSortingChange={(sortingOrder) => onSortingChange(column.name, sortingOrder)}
                            onSelectionChange={(filters) => onFiltersSelection(column.name, filters)}
                            onColumnFixChange={() => onColumnFixChange(column.name)}
                            onApplyFiltersButtonClick={onApplyFiltersButtonClick}
                        />
                    </div>

                    <div className={style.dragzoneWrapper}>
                        <div
                            className={style.dragzone}
                            onMouseDown={(event) => onColumnEdgeMousedown(column.name, event.clientX)}
                        />
                    </div>
                </React.Fragment>
            ))}

            <div className={style.fixedColumnsCells} ref={headerFixedColumnsRef}>
                {fixedColumns.map((column) => (
                    <React.Fragment key={column.name}>
                        <div
                            className={classNames(
                                style.cell,
                                column.name == hoveredColumnName && style.hover,
                                column.name == draggedEdgeColumnName && style.dragged,
                            )}
                            style={{ width: columnsWidth[column.name] }}
                            onMouseEnter={() => onCellMouseEnter(column.name)}
                            onMouseLeave={onCellMouseLeave}
                        >
                            <HeaderDropdownCell
                                name={column.name}
                                title={column.title}
                                filters={filters[column.name]}
                                currencySearch={column.valueType === CellValueType.Currency}
                                columnValueType={column.valueType}
                                isResizingColumn={isResizingColumn}
                                isColumnFixed={lodash.includes(fixedColumnsNames, column.name)}
                                orderType={column.name == sortingMode.columnName ? sortingMode.order : null}
                                onOpenStatusChange={(content) => onDropdownClick(column.name, content)}
                                onSortingChange={(sortingOrder) => onSortingChange(column.name, sortingOrder)}
                                onSelectionChange={(filters) => onFiltersSelection(column.name, filters)}
                                onColumnFixChange={() => onColumnFixChange(column.name)}
                                onApplyFiltersButtonClick={onApplyFiltersButtonClick}
                            />
                        </div>

                        {lodash.last(visibleColumns).name != column.name && (
                            <div className={style.dragzoneWrapper}>
                                <div
                                    className={style.dragzone}
                                    onMouseDown={(event) => onColumnEdgeMousedown(column.name, event.clientX)}
                                />
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};
