import * as React from 'react';
import classnames from 'classnames';
import { CustomScrollbar_new as CustomScrollbar } from 'sber-marketing-ui';

import * as styles from './DropdownEditor.scss';

interface ItemParams {
    id: string | number;
    label: string;
}

const ITEM_HEIGHT = 26;

interface Props {
    title: string;
    items: ItemParams[];
    onSelect: (item: string | number) => void;
}

export function DropdownEditor({ title, items, onSelect }: Props): JSX.Element {
    const nullItem = items ? items.find((item) => !item.id) : null;

    return (
        <div className={styles.root}>
            <div className={classnames(styles.padding, styles.title)}>{title}</div>

            {nullItem ? (
                <React.Fragment>
                    <Item {...nullItem} onSelect={onSelect} />

                    <div className={styles.horizontalBar} />
                </React.Fragment>
            ) : null}

            <CustomScrollbar hideScrollX maxHeight={10 * ITEM_HEIGHT}>
                {items
                    ? items.map((item) => (item.id ? <Item key={item.id} onSelect={onSelect} {...item} /> : null))
                    : null}
            </CustomScrollbar>
        </div>
    );
}

interface ItemProps extends ItemParams {
    onSelect: (item: string | number) => void;
}

function Item({ id, label, onSelect }: ItemProps): JSX.Element {
    return (
        <div className={classnames(styles.padding, styles.item)} onClick={() => onSelect(id)}>
            {label}
        </div>
    );
}
