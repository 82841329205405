// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-EditExecutionBudgetPage-BudgetList-_BudgetList__root{width:100%}.src-client-modules-budget-EditExecutionBudgetPage-BudgetList-_BudgetList__budgetCard{margin-bottom:16px}.src-client-modules-budget-EditExecutionBudgetPage-BudgetList-_BudgetList__budgetCard:last-child{margin-bottom:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/EditExecutionBudgetPage/BudgetList/BudgetList.scss"],"names":[],"mappings":"AAAA,gFACI,UAAW,CACd,sFAGG,kBAAmB,CADvB,iGAIQ,eAAgB","sourcesContent":[".root {\n    width: 100%;\n}\n\n.budgetCard {\n    margin-bottom: 16px;\n\n    &:last-child {\n        margin-bottom: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-EditExecutionBudgetPage-BudgetList-_BudgetList__root",
	"budgetCard": "src-client-modules-budget-EditExecutionBudgetPage-BudgetList-_BudgetList__budgetCard"
};
export default ___CSS_LOADER_EXPORT___;
