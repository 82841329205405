import * as React from 'react';
import classNames from 'classnames';

import { useGetTaskQuery } from '@api';

import { getFilesCountText } from '@modules/files/utils';
import { FileData } from '@modules/files/types';
import { DownloadFiles } from '@modules/files/components';

import { Details, DetailsProps, SkeletonText } from '@common/components';

import { TaskFiles } from '@modules/task/components';

import * as styles from './TaskFilesDetails.scss';

export interface TaskFilesDetailsProps extends DetailsProps {
    taskId: string;
}

export function TaskFilesDetails({ taskId, className, ...props }: TaskFilesDetailsProps) {
    const { data: task, isLoading: isTaskLoading } = useGetTaskQuery({ id: taskId });
    const files: FileData[] = (task?.task.files || []).map((file) => ({
        ...file,
        containerName: taskId,
        parent: 'task',
    }));
    const filesCount = files.length;

    const filesCountElement = isTaskLoading ? (
        <span>
            <SkeletonText width={8} /> файлов
        </span>
    ) : (
        getFilesCountText(filesCount)
    );

    const summaryAfter = !isTaskLoading && (
        <DownloadFiles
            files={files}
            fileName={`Вложения задачи «${task.task.title}» от ${new Date().toLocaleDateString()}.zip`}
        >
            Скачать файлы
        </DownloadFiles>
    );

    return (
        <Details
            data-qa-id="TaskFilesDetails"
            {...props}
            className={classNames(styles.root, className)}
            summaryAfter={summaryAfter}
            summary={filesCountElement}
        >
            <TaskFiles taskId={taskId} />
        </Details>
    );
}
