import * as React from 'react';
import { CustomScrollbar } from 'sber-marketing-ui';

import { QuickAccessIcons } from '@common/QuickAccessIcons';
import { DashboardHeaderItems } from '@common/DashboardHeaderItems';

import { Card } from './types';

import { CardsList } from './CardsList';
import { LeftSidebar } from './LeftSidebar';
import { RightSidebar } from './RightSidebar';

import * as style from './DashboardPage.scss';

interface Props {
    cards: Card[];
    preloader: boolean;
    isLoadingCards: boolean;
    pageRef: React.RefObject<HTMLDivElement>;
    totalCardsCount: number;
    updateCards: () => Promise<void>;
    fetchMore: () => Promise<void>;
}

export const DashboardPage = ({
    cards,
    preloader,
    isLoadingCards,
    pageRef,
    totalCardsCount,
    updateCards,
    fetchMore,
}: Props): JSX.Element => {
    return (
        <div>
            <div className={style.root} ref={pageRef}>
                <div className={style.leftSidebar}>
                    <LeftSidebar />
                </div>

                <div className={style.centralContent}>
                    <CardsList
                        cards={cards}
                        isLoadingCards={isLoadingCards}
                        totalCardsCount={totalCardsCount}
                        fetchMoreCards={fetchMore}
                        updateCards={updateCards}
                    />
                </div>

                <div className={style.rightSidebar}>
                    <CustomScrollbar hideHorizontalScrollbar hideTracksWhenNotNeeded autoHide>
                        <RightSidebar updateCards={updateCards} />
                    </CustomScrollbar>
                </div>
            </div>
        </div>
    );
};

interface DashboardPageHeaderProps {
    currentUrl: string;
}

export function DashboardPageHeader(props: DashboardPageHeaderProps): JSX.Element {
    return (
        <div className={style.header}>
            <div className={style.headerMenuItems}>
                <DashboardHeaderItems {...props} />
            </div>

            <div className={style.quickAccessIcons}>
                <QuickAccessIcons />
            </div>
        </div>
    );
}
