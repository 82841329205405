import { JivoSiteApi as OriginJivoSiteApi } from 'sber-marketing-ui';

export class JivoSiteApi {
    public static readonly appName: string = 'Маркетинг';

    public static setUserId(id: number) {
        OriginJivoSiteApi.getInstance(JivoSiteApi.appName).setUserId(id);
    }
    public static setUserData(email: string, name: string) {
        OriginJivoSiteApi.getInstance(JivoSiteApi.appName).setUserData(email, name);
    }
    public static setCurrentPage(url: string) {
        OriginJivoSiteApi.getInstance(JivoSiteApi.appName).setCurrentPage(url);
    }
}
