// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetPlanning-Table-CellTypes-IndicatorsCell-RequiredFieldsAreEmptyMarker-_RequiredFieldsAreEmptyMarker__tooltipContent{font-family:'Open Sans';font-size:12px;font-weight:normal;line-height:16px}.src-client-modules-budget-BudgetPage-BudgetPlanning-Table-CellTypes-IndicatorsCell-RequiredFieldsAreEmptyMarker-_RequiredFieldsAreEmptyMarker__tooltipContentBold{font-weight:600}.src-client-modules-budget-BudgetPage-BudgetPlanning-Table-CellTypes-IndicatorsCell-RequiredFieldsAreEmptyMarker-_RequiredFieldsAreEmptyMarker__tooltipContentList{margin-left:15px;list-style:disc}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetPlanning/Table/CellTypes/IndicatorsCell/RequiredFieldsAreEmptyMarker/RequiredFieldsAreEmptyMarker.scss"],"names":[],"mappings":"AAAA,+JACI,uBAAwB,CACxB,cAAe,CACf,kBAAmB,CACnB,gBAAiB,CACpB,mKAGG,eAAgB,CACnB,mKAGG,gBAAiB,CAEjB,eAAgB","sourcesContent":[".tooltipContent {\n    font-family: 'Open Sans';\n    font-size: 12px;\n    font-weight: normal;\n    line-height: 16px;\n}\n\n.tooltipContentBold {\n    font-weight: 600;\n}\n\n.tooltipContentList {\n    margin-left: 15px;\n\n    list-style: disc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipContent": "src-client-modules-budget-BudgetPage-BudgetPlanning-Table-CellTypes-IndicatorsCell-RequiredFieldsAreEmptyMarker-_RequiredFieldsAreEmptyMarker__tooltipContent",
	"tooltipContentBold": "src-client-modules-budget-BudgetPage-BudgetPlanning-Table-CellTypes-IndicatorsCell-RequiredFieldsAreEmptyMarker-_RequiredFieldsAreEmptyMarker__tooltipContentBold",
	"tooltipContentList": "src-client-modules-budget-BudgetPage-BudgetPlanning-Table-CellTypes-IndicatorsCell-RequiredFieldsAreEmptyMarker-_RequiredFieldsAreEmptyMarker__tooltipContentList"
};
export default ___CSS_LOADER_EXPORT___;
