import * as React from 'react';

import { CloseButton } from './CloseButton';
import { Title } from './Title';

import * as style from './styles.scss';

interface Props {
    titleText: string;
    onCloseButtonClick(): void;
}

export const Header: React.FC<Props> = ({ titleText, onCloseButtonClick }) => {
    return (
        <div className={style.root}>
            <Title text={titleText} />
            <CloseButton onClick={onCloseButtonClick} />
        </div>
    );
};
