import * as moment from 'moment';
import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { AxiosError } from 'axios';
import { TaskStatus } from 'sber-marketing-types/frontend';
import { compact, uniq } from 'lodash';

import { StoreState } from '../..';
import { CreateNewTaskPayload, FetchError } from '../types';
import { asyncActions } from '../actions';
import { TaskApi } from '../../../api/TaskApi';
import { FileApi } from '../../../api/file-api/FileApiNew';
import { getTaskInfo, getAssetsByIds } from '../selectors';
import { getLoginUser } from '../../user/selector';

export const createNewTask = bindThunkAction<StoreState, CreateNewTaskPayload, void, FetchError>(
    asyncActions.createNewTask,
    async ({ isDraft }: CreateNewTaskPayload, dispatch, getState): Promise<void> => {
        const { attributes: user } = getLoginUser(getState());
        const {
            id,
            activityId,
            executorId,
            departmentExecutorId,
            description,
            assetsIds,
            title,
            workType,
            participants,
            deadlineAt,
        } = getTaskInfo(getState());
        const assets = getAssetsByIds(getState(), assetsIds);
        const date = moment().toISOString();

        try {
            const task = {
                id,
                title,
                departmentExecutorId,
                description,
                activityId: Number(activityId),
                workTypeId: workType,
                authorId: Number(user.id),
                executorId: Number(executorId),
                deadline: moment(deadlineAt).toISOString(),
                createTime: date,
                updateTime: date,
                status: isDraft ? TaskStatus.Draft : TaskStatus.InProgress,
            };
            await TaskApi.createTask(task as any);
            if (participants.length > 0) {
                await TaskApi.updateTaskParticipants(
                    id,
                    uniq(compact([user.id, executorId, ...participants.map((participant) => participant.userId)])),
                );
            }
            await FileApi.uploadFiles({ taskId: id }, assets, String(user.id));
        } catch (error) {
            const { response, message } = <AxiosError>error;
            if (response) {
                throw new FetchError(response.status, response.statusText, message);
            } else {
                throw new FetchError(0, 'Unknown', message);
            }
        }
    },
);
