import { Dispatch } from 'redux';
import { isNil } from 'lodash';

import { StoreState } from '@store';
import { runDeleteOne, runUploadOne } from '@store/fileAssets';
import { resetFilesUploadStatus, setFileUploadStatus, FileUploadStatus, errorIsFromAntivirus } from '@store/common';
import { getFiles, getFilesToRemove } from '../selectors';

export const uploadFilesWorker = async (
    taskId: string,
    dispatch: Dispatch<StoreState>,
    getState: () => StoreState,
): Promise<void> => {
    dispatch(resetFilesUploadStatus());

    // Remove files from server if it's needed and clear removing list
    const filesToRemove = getFilesToRemove(getState()).filter(({ file }) => isNil(file));
    const files = getFiles(getState());
    for (const file of filesToRemove) {
        await runDeleteOne({ taskId }, file, dispatch);
    }
    for (const file of files.filter((item) => !isNil(item.file))) {
        try {
            await runUploadOne({
                dispatch,
                params: { taskId },
                fileOrAssetOrId: file,
            });
        } catch (e) {
            if (errorIsFromAntivirus(e)) {
                dispatch(
                    setFileUploadStatus({
                        fileName: `${file.originName}.${file.type}`,
                        status: FileUploadStatus.VirusFound,
                    }),
                );
            }

            throw e;
        }
    }
};
