import * as React from 'react';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { ScrollbarComponent } from 'sber-marketing-ui';
import type { ColumnName, ColumnWidths, LineHeights, LineId, Size } from '../types';

import { TableViewTemplate } from './TableViewTemplate';
import { TableViewModel } from '../TableViewModel';
import { Virtualizer } from './modules';

const DEFAULT_COLUMN_WIDTH = 140;
const DEFAULT_LINE_HEIGHT = 60;

const HEADER_HEIGHT = 40;
const CELLS_SPACING = 1;

interface Props {
    viewModel: TableViewModel;
    columns: ColumnName[];
    lines: LineId[];
}

interface State {
    columnWidths: ColumnWidths;
    lineHeights: LineHeights;
    visibleColumnsIndexes: number[];
    visibleLinesIndexes: number[];
}

export class TableViewBehaviour extends React.PureComponent<Props, State> {
    private rootRef: React.RefObject<HTMLDivElement>;
    private tableHeader: ScrollbarComponent;
    private tableBody: ScrollbarComponent;
    private virtualizer: Virtualizer;

    public constructor(props: Props) {
        super(props);

        this.state = {
            columnWidths: props.columns.reduce((acc, item) => {
                acc[item] = DEFAULT_COLUMN_WIDTH;
                return acc;
            }, {}),
            lineHeights: props.lines.reduce((acc, item) => {
                acc[item] = DEFAULT_LINE_HEIGHT;
                return acc;
            }, {}),
            visibleColumnsIndexes: [],
            visibleLinesIndexes: [],
        };

        this.rootRef = React.createRef<HTMLDivElement>();

        this.virtualizer = new Virtualizer();
    }

    public async componentDidMount() {
        this.updateViewportSize();
        this.updateVisibleColumns();
        this.updateVisibleLines();

        window.addEventListener('resize', this.onPageResize);
    }

    public async componentWillUnmount() {
        window.removeEventListener('resize', this.onPageResize);
    }

    public componentDidUpdate(prevProps: Props) {
        const columnsChanged = this.props.columns == prevProps.columns;
        const linesChanged = this.props.lines == prevProps.lines;

        if (columnsChanged) {
            this.updateVisibleColumns();
        }

        if (linesChanged) {
            this.updateVisibleLines();
        }
    }

    public render(): JSX.Element {
        const { viewModel, columns, lines } = this.props;
        const { visibleColumnsIndexes, columnWidths, visibleLinesIndexes, lineHeights } = this.state;
        const { getColumnHeader, getCell } = viewModel;

        const viewportSize = this.getViewportSize();

        return React.createElement(TableViewTemplate, {
            columns,
            lines,
            visibleColumnsIndexes,
            visibleLinesIndexes,
            columnWidths,
            lineHeights,
            sumWidth: this.getSumWidth(),
            sumHeight: this.getSumHeight(),
            tableBodyMaxHeight: viewportSize.height,
            rootRef: this.rootRef,
            tableHeaderRef: this.tableHeaderRef,
            tableBodyRef: this.tableBodyRef,
            getColumnHeader,
            getCell,
            onBodyScroll: this.onBodyScroll,
        });
    }

    @autobind
    protected onPageResize() {
        this.updateViewportSize();
        this.updateVisibleColumns();
        this.updateVisibleLines();
    }

    @autobind
    protected onBodyScroll() {
        this.updateHeaderScroll();
        this.updateVisibleColumns();
        this.updateVisibleLines();
    }

    @autobind
    protected tableHeaderRef(component: ScrollbarComponent) {
        this.tableHeader = component;
    }

    @autobind
    protected tableBodyRef(component: ScrollbarComponent) {
        this.tableBody = component;
    }

    private getSumWidth(): number {
        return (
            this.props.columns.reduce(
                (acc, columnName) => acc + this.state.columnWidths[columnName] + CELLS_SPACING,
                0,
            ) - CELLS_SPACING
        );
    }

    private getSumHeight(): number {
        return (
            this.props.lines.reduce((acc, lineId) => acc + this.state.lineHeights[lineId] + CELLS_SPACING, 0) -
            CELLS_SPACING
        );
    }

    private updateViewportSize() {
        if (this.rootRef.current) {
            const size = this.getViewportSize();

            this.virtualizer.setViewportSize(size);
        }
    }

    private getViewportSize(): Size {
        if (!this.rootRef.current) {
            return {
                width: 0,
                height: 0,
            };
        }

        return {
            width: this.rootRef.current.offsetWidth - CELLS_SPACING * 2,
            height: this.rootRef.current.offsetHeight - HEADER_HEIGHT - CELLS_SPACING,
        };
    }

    private updateHeaderScroll() {
        this.tableHeader.scrollTo(0, this.tableBody.scrollLeft);
    }

    private updateVisibleColumns() {
        const { columns } = this.props;
        const { columnWidths, visibleColumnsIndexes: visibleColumns } = this.state;

        const newVisibleColumnsIndexes = this.virtualizer.getVisibleColumnsIndexes(
            columns,
            columnWidths,
            this.tableBody.scrollLeft,
        );

        const newVisibleColumnsChanged = !lodash.isEqual(newVisibleColumnsIndexes, visibleColumns);

        if (newVisibleColumnsChanged) {
            this.setState({
                visibleColumnsIndexes: newVisibleColumnsIndexes,
            });
        }
    }

    private updateVisibleLines() {
        const { lines } = this.props;
        const { lineHeights, visibleLinesIndexes: visibleLines } = this.state;

        const newVisibleLines = this.virtualizer.getVisibleLinesIndexes(lines, lineHeights, this.tableBody.scrollTop);

        const newVisibleLinesChanged = !lodash.isEqual(newVisibleLines, visibleLines);

        if (newVisibleLinesChanged) {
            this.setState({
                visibleLinesIndexes: newVisibleLines,
            });
        }
    }
}
