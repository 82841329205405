import * as React from 'react';

import { useStyleContext } from '../BudgetTransferMenu';

import { CellDescription } from './CellDescription';
import { Arrow } from './Arrow';
import { ToSumEnteringButton } from './ToSumEnteringButton';
import { LinesDescription } from './LinesDescription';

import * as styles from './InternalTransitionLineSelection.scss';

export function InternalTransitionLineSelection(): JSX.Element {
    const paddingClass = useStyleContext();

    return (
        <div className={styles.root}>
            <div className={paddingClass}>
                <CellDescription />

                <Arrow />
            </div>

            <LinesDescription />

            <div className={paddingClass}>
                <ToSumEnteringButton />
            </div>
        </div>
    );
}
