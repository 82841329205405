import gql from 'graphql-tag';

export const GET_CARDS_QUERY = gql`
    query Cards($limit: Int, $filter: ApprovalRequestFilter!) {
        cards: getApprovalRequests(limit: $limit, filter: $filter) {
            count
            nodes {
                id
                status
                permissions {
                    read
                    delete
                }
                author {
                    id
                    firstName
                    secondName
                }
                project {
                    id
                    name
                    permissions {
                        read
                    }
                }
                budgetItem {
                    id
                    serialNumber
                    permissions {
                        read
                    }
                    budget {
                        id
                    }
                    reservedFunds {
                        jan
                        feb
                        mar
                        apr
                        may
                        jun
                        jul
                        aug
                        sept
                        oct
                        nov
                        dec
                    }
                    factFunds {
                        jan
                        feb
                        mar
                        apr
                        may
                        jun
                        jul
                        aug
                        sept
                        oct
                        nov
                        dec
                    }
                    plannedFunds {
                        jan
                        feb
                        mar
                        apr
                        may
                        jun
                        jul
                        aug
                        sept
                        oct
                        nov
                        dec
                    }
                    dictionary {
                        tool {
                            status
                            value
                        }
                    }
                }
                createdAt
            }
        }
    }
`;
