import * as React from 'react';
import classNames from 'classnames';

import * as style from './ActivityGroup.scss';

import type { ActivityCardParams, Position, Direction } from '@store/calendar/types';
import { VerticalDirection, HorizontalDirection } from '@store/calendar/types';

import { Icon, IconType, BodyPortal } from 'sber-marketing-ui';
import { Tooltip } from '../../Tooltip';

interface Props {
    blockName: string;
    activitiesByDays: ActivityCardParams[][];
    tooltipPosition: Position;
    tooltipDirection: Direction;
    hoveredActivityCardId: number;
    onCardMousemove: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, activityId: number) => void;
    onCardMouseleave: () => void;
    onCardClick: (activityId: number) => void;
}

export const ActivityGroup = ({
    blockName,
    activitiesByDays,
    tooltipPosition,
    tooltipDirection,
    hoveredActivityCardId,
    onCardMousemove,
    onCardMouseleave,
    onCardClick,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.columns}>
                {activitiesByDays.map((dayActivities, dayIndex) => (
                    <div className={style.column} key={dayIndex}>
                        {dayIndex == 0 && <div className={style.blockName}>{blockName}</div>}

                        {dayActivities.map((activity) => (
                            <ActivityCard
                                key={activity.id}
                                dayIndex={dayIndex}
                                activity={activity}
                                onMousemove={onCardMousemove}
                                onMouseleave={onCardMouseleave}
                                onClick={onCardClick}
                            />
                        ))}
                    </div>
                ))}
            </div>

            {tooltipPosition && hoveredActivityCardId && (
                <BodyPortal>
                    <div
                        className={style.tooltip}
                        style={{
                            top: tooltipPosition.y,
                            left:
                                tooltipDirection.horizontal == HorizontalDirection.Right
                                    ? `${tooltipPosition.x}px`
                                    : `calc(100% - ${tooltipPosition.x}px)`,
                            transform: `
                                ${tooltipDirection.vertical == VerticalDirection.Up ? 'translateY(-100%)' : ''}
                                ${
                                    tooltipDirection.horizontal == HorizontalDirection.Center
                                        ? `translateX(calc(${tooltipPosition.x}px - (${window.innerWidth}px - ${tooltipPosition.x}px) - 50%))`
                                        : ''
                                }
                                ${
                                    tooltipDirection.horizontal == HorizontalDirection.Left
                                        ? `translateX(calc(${tooltipPosition.x}px - (${window.innerWidth}px - ${tooltipPosition.x}px) - 100%))`
                                        : ''
                                }
                            `,
                        }}
                    >
                        <Tooltip />
                    </div>
                </BodyPortal>
            )}
        </div>
    );
};

interface ActivityCardProps {
    dayIndex: number;
    activity: ActivityCardParams;
    onMousemove: (event: React.MouseEvent<HTMLElement>, activityId: number) => void;
    onMouseleave: () => void;
    onClick: (activityId: number) => void;
}

function ActivityCard({ dayIndex, activity, onMousemove, onMouseleave, onClick }: ActivityCardProps) {
    const { isActive, stagesInfo } = activity;

    const onMouseMove: React.MouseEventHandler<HTMLDivElement> = (event) => {
        onMousemove(event, activity.id);
    };

    return (
        <div
            className={classNames(style.activityCard, !activity.isActive && style.inactive)}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseleave}
            onClick={() => activity.isActive && onClick(activity.id)}
        >
            <div className={dayIndex == 0 ? style.todayActivityName : style.activityName}>{activity.name}</div>

            <div className={style.activityType} style={{ backgroundColor: activity.color.realization }}>
                <div className={style.activityTypeTitle}>{activity.typeName}</div>
            </div>

            {isActive && (
                <>
                    {!stagesInfo && activity.needStages && (
                        <div className={classNames(style.stageInfo, style.noStages)}>
                            <div className={style.stageTitle}>Этапы не назначены</div>
                        </div>
                    )}

                    {!stagesInfo && !activity.needStages && (
                        <div className={style.stageInfo}>
                            <div className={style.stageTitle}>Проект без этапов</div>
                        </div>
                    )}

                    {stagesInfo && stagesInfo.hasExpiredStages && (
                        <div className={classNames(style.stageInfo, style.expired)}>
                            <div className={style.stagesIcon}>
                                <Icon type={IconType.EXPIRED_TASK} svgSize={16} />
                            </div>

                            <div className={style.stageTitle}>Просрочены этапы</div>
                        </div>
                    )}

                    {stagesInfo && !stagesInfo.hasExpiredStages && (
                        <div className={style.stageInfo}>
                            <div className={style.stagesIcon}>
                                <Icon
                                    type={
                                        stagesInfo.finishedStagesCount == stagesInfo.stagesCount
                                            ? IconType.CHECKBOX16_UNCHECKED_GREEN
                                            : IconType.CHECKBOX16_UNCHECKED_LIGHTGREEN
                                    }
                                    svgSize={16}
                                />
                            </div>

                            <div className={style.stageTitle}>
                                Этапы: {stagesInfo.finishedStagesCount}/{stagesInfo.stagesCount}
                            </div>
                        </div>
                    )}
                </>
            )}

            <div
                className={classNames(style.activityTypeInducator, !activity.isActive && style.inactive)}
                style={{ backgroundColor: activity.color.realization }}
            />
        </div>
    );
}
