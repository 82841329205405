import * as React from 'react';

import { LeaderDashboardApi } from '@api';
import type { GetDepartmentRatesResponseDto } from '@mrm-analytics-service/api-client';
import { FilterPeriod, StatisticsType, StatisticsTaskType } from './types';
import { useMode } from '../../../../../hooks';

interface UseRateByDepartments {
    (params: UseRateByDepartmentsParams): UseRateByDepartmentsReturn;
}

interface UseRateByDepartmentsParams {
    period: FilterPeriod;
    date: string;
    departmentId: string;
    type: StatisticsType;
}

interface UseRateByDepartmentsReturn {
    mainRate: number;
    additionalRate: number;
    loading: boolean;
}

export const useRateByDepartments: UseRateByDepartments = ({ period, date, departmentId, type }) => {
    const { mode } = useMode();

    const needRate = type === StatisticsTaskType.CLOSED && mode === 'tasks';

    const [result, setResult] = React.useState<GetDepartmentRatesResponseDto>(null);
    const [loading, setLoading] = React.useState<boolean>(needRate);

    React.useEffect(() => {
        if (needRate) {
            LeaderDashboardApi.getRate({
                period,
                departmentsIds: [departmentId],
                currentDate: date,
            }).then((result) => {
                setResult(result);
                setLoading(false);
            });
        }
    }, []);

    const mainRate = result?.current
        ? result?.current?.reduce((prev, current) => prev + current.avgRate, 0) / result.current.length
        : 0;

    const additionalRate = result?.current
        ? result?.past?.reduce((prev, past) => prev + past.avgRate, 0) / result.past.length
        : 0;

    return {
        mainRate,
        additionalRate,
        loading,
    };
};
