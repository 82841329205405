import { FileResponse, FileStorage } from 'sber-marketing-types/backend';

import { DEFAULT_PREVIEW_WIDTH } from '@modules/task/components';

export type GetImagePreviewData = Pick<FileResponse, 'id' | 'type' | 'storage'>;

export function getImagePreview({ id, type, storage }: GetImagePreviewData): string | null {
    return storage === FileStorage.IMAGE_SERVICE
        ? `/api/image/i/${id[0]}/${id}/${DEFAULT_PREVIEW_WIDTH}.${type}`
        : null;
}
