import * as React from 'react';

import * as style from './MediaplanStageWidget.scss';

import { XLSBuilder } from './XLSBuilder';
import { PerformanceMetrics } from './PerformanceMetrics';
import { MediaplanTable } from './MediaplanTable';

interface Props {
    readOnly?: boolean;
}

export const MediaplanStageWidget = ({ readOnly }: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.downloadButton}>
                <XLSBuilder />
            </div>

            <div className={style.title}>Показатели эффективности кампании</div>

            <div className={style.performanceMetrics}>
                <PerformanceMetrics />
            </div>

            <div className={style.title}>Эстимация по радиостанциям</div>

            <div className={style.mediaplanTable}>
                <MediaplanTable />
            </div>
        </div>
    );
};
