// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-leaderDashboard-LeaderDashboard-Header-UsersToggle-_styles__root{display:grid;grid-auto-flow:column;grid-auto-columns:max-content;grid-column-gap:10px;align-items:center}.src-client-modules-leaderDashboard-LeaderDashboard-Header-UsersToggle-_styles__item{font-family:\"Open Sans\";font-size:14px;line-height:14px;color:#7E8681;transition:color 75ms ease-out;user-select:none}.src-client-modules-leaderDashboard-LeaderDashboard-Header-UsersToggle-_styles__selectedItem{color:#000000}\n", "",{"version":3,"sources":["webpack://./src/client/modules/leaderDashboard/LeaderDashboard/Header/UsersToggle/styles.scss"],"names":[],"mappings":"AAAA,qFACI,YAAa,CACb,qBAAsB,CACtB,6BAA8B,CAC9B,oBAAqB,CACrB,kBAAmB,CACtB,qFAGG,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CAEjB,aAAc,CACd,8BAA+B,CAE/B,gBAAiB,CACpB,6FAGG,aAAc","sourcesContent":[".root {\n    display: grid;\n    grid-auto-flow: column;\n    grid-auto-columns: max-content;\n    grid-column-gap: 10px;\n    align-items: center;\n}\n\n.item {\n    font-family: \"Open Sans\";\n    font-size: 14px;\n    line-height: 14px;\n\n    color: #7E8681;\n    transition: color 75ms ease-out;\n\n    user-select: none;\n}\n\n.selectedItem {\n    color: #000000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-leaderDashboard-LeaderDashboard-Header-UsersToggle-_styles__root",
	"item": "src-client-modules-leaderDashboard-LeaderDashboard-Header-UsersToggle-_styles__item",
	"selectedItem": "src-client-modules-leaderDashboard-LeaderDashboard-Header-UsersToggle-_styles__selectedItem"
};
export default ___CSS_LOADER_EXPORT___;
