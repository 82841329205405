import * as React from 'react';

import { useEditTagMutation } from '@api';

import { useDefaultState } from '@common/hooks';

import { TagSettings, TagSettingsProps, TagSettingsSubmit } from '@modules/tags/components';

export interface EditTagProps extends TagSettingsProps {
    tagId: string;
}

export function EditTag({
    tagId,
    name,
    onSubmit,
    color: colorProp,
    picture: pictureProp,
    onChangeColor,
    onChangePicture,
    ...props
}: EditTagProps) {
    const [color, setColor] = useDefaultState(colorProp, onChangeColor);
    const [picture, setPicture] = useDefaultState(pictureProp, onChangePicture);
    const [editTag] = useEditTagMutation();

    const handleSubmit = (data: TagSettingsSubmit) => {
        onSubmit?.(data);
        editTag({
            id: tagId,
            ...data,
        });
    };

    return (
        <TagSettings
            {...props}
            color={color}
            picture={picture}
            onChangeColor={setColor}
            onChangePicture={setPicture}
            onSubmit={handleSubmit}
        >
            Сохранить
        </TagSettings>
    );
}
