import gql from 'graphql-tag';

export const WITH_COMMENTS_QUERY = gql`
    query withComments($budgetItemId: String!) {
        budgetItemComments: getBudgetItemComments(budgetItemId: $budgetItemId) {
            nodes {
                id
                text
                createTime
                author {
                    firstName
                    secondName
                    departmentName
                    organizationName
                }
                files {
                    id
                    name
                    originName
                    type
                }
            }
        }
    }
`;

export const CREATE_COMMENT_MUTATION = gql`
    mutation createComment($id: String!, $text: String!, $fileIds: [String]) {
        createComment(comment: { id: $id, text: $text, fileIds: $fileIds })
    }
`;

export const ADD_COMMENT_TO_BUDGET_ITEM_MUTATION = gql`
    mutation addCommentToBudgetItem($budgetItemId: String!, $commentId: String!) {
        addCommentToBudgetItem(commentId: $commentId, budgetItemId: $budgetItemId)
    }
`;
