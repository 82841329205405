import * as React from 'react';
import { WithTooltip } from 'sber-marketing-ui';

import * as style from './WithTitle.scss';

interface Props {
    title: string;
    subTitle?: string;
    month?: string;
}

export const WithTitle: React.FC<Props> = ({ children, title, subTitle, month }) => (
    <div>
        <div className={style.header}>
            <div className={style.title}>{title}</div>
            {month && (
                <div
                    className={style.month}
                    {...{
                        'qa-id': 'correctionCardWithTitleMonth',
                    }}
                >
                    {month}
                </div>
            )}
        </div>

        {subTitle ? (
            <div className={style.content}>
                <WithTooltip spanWrapper content={subTitle} hidden={!subTitle}>
                    <div className={style.contentInner}>{children}</div>
                </WithTooltip>
            </div>
        ) : (
            <div className={style.contentInner}>{children}</div>
        )}
    </div>
);
