import * as React from 'react';
import autobind from 'autobind-decorator';

import { CellValueType, ColumnName, CustomColumnColor } from '@store/budgetExecution/types';

import { CellBackgroundColor } from '../../LayerManager';

import { InputCell } from './InputCell';

interface Props {
    lineId: string;
    columnName: ColumnName;
    title: string;
    value: string;
    originalValue: string;
    valueType: CellValueType;
    tooltip?: string;
    bgColor?: CellBackgroundColor | CustomColumnColor;
    disabled?: boolean;
    cellInfo?: string | JSX.Element;
    onValueChange?: (
        lineId: string,
        columnName: ColumnName,
        value: string,
        originalValue: string,
        generateSameValueChange: boolean,
    ) => void;
    onCellCopy: (event: React.ClipboardEvent<HTMLDivElement>) => void;
}

interface State {
    editMode: boolean;
}

export class InputCellContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            editMode: false,
        };
    }

    public render(): JSX.Element {
        return React.createElement(InputCell, {
            columnName: this.props.columnName,
            title: this.props.title,
            value: this.props.value,
            valueType: this.props.valueType,
            tooltip: this.props.tooltip,
            editMode: this.state.editMode,
            bgColor: this.props.bgColor,
            disabled: this.props.disabled,
            cellInfo: this.props.cellInfo,
            onRootClick: this.onRootClick,
            onInputBlur: this.onInputBlur,
            onValueChange: this.onValueChangeHandler,
            onCopy: this.props.onCellCopy,
        });
    }

    @autobind
    protected onRootClick() {
        this.setState(() => ({
            editMode: true,
        }));
    }

    @autobind
    protected onValueChangeHandler(value: string) {
        this.onValueChange(value, true);
    }

    @autobind
    protected onInputBlur() {
        this.setState(() => ({
            editMode: false,
        }));

        const value = this.removeTrailingZeros(this.props.value);

        this.onValueChange(value?.trim(), false);
    }

    @autobind
    protected onValueChange(value: string, generateSameValueChange: boolean) {
        const { lineId, columnName, originalValue, valueType } = this.props;

        let usedOriginalValue = originalValue;
        if (valueType == CellValueType.Currency && originalValue) {
            usedOriginalValue = String(Number(originalValue) / 100.0);
        }

        if (this.props.onValueChange) {
            this.props.onValueChange(lineId, columnName, value, usedOriginalValue, generateSameValueChange);
        }
    }

    private removeTrailingZeros(value: string): string {
        if (!value) {
            return value;
        }
        const trailingZerosRegex = /^((([1-9][0-9]*?)|0)\.([0-9]*[1-9]+)?)0+$/;
        const trailingDotRegex = /\.$/;

        return value.replace(trailingZerosRegex, '$1').replace(trailingDotRegex, '');
    }
}
