import * as React from 'react';

import { Money, MoneyFormatter } from '@common/Utils';
import { HeavyLineWarning } from '../LineWarning';

import * as style from './ExcessReserveWarning.scss';

export interface Props {
    delta: number;
    budgetItemId: string;
    isActive: boolean;
}

export const ExcessReserveWarning: React.FC<Props> = ({ delta, budgetItemId, isActive }) => {
    const formattedDelta = formatMoney(delta);

    return (
        <HeavyLineWarning isActive={isActive}>
            Резерв {isActive ? 'превысит' : 'превысил'} План на&nbsp;
            <span title={formattedDelta} className={style.accentText}>
                {formattedDelta}
            </span>
            &nbsp; по ID&nbsp;
            <span className={style.accentText}>{budgetItemId}</span>
        </HeavyLineWarning>
    );
};

const formatMoney = (money: number): string => {
    return MoneyFormatter.toRoubles(Money.fromCopecks(money));
};
