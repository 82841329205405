import * as React from 'react';
import classNames from 'classnames';
import * as moment from 'moment';
import * as lodash from 'lodash';

import * as style from './MultiselectDatepicker.scss';

import { Icon, IconType, DatepickerContent } from 'sber-marketing-ui';

export const enum SelectionType {
    Date = 'date',
    Period = 'period',
}

export interface DatePeriod {
    title: string;
    dates: Date[];
}

interface Props {
    readOnly: boolean;
    isOpened: boolean;
    selectionType: SelectionType;
    selectedDates: Date[];
    selectedPeriods: DatePeriod[];
    periodStart: Date;
    minDate: Date;
    maxDate: Date;
    onOpenerClick: () => void;
    onSelect: (value: Date | [Date, Date]) => void;
    onSelectionTypeChange: (type: SelectionType) => void;
    onDeleteButtonClick: (dates: Date[]) => void;
}

export const MultiselectDatepickerTemplate = ({
    readOnly,
    isOpened,
    selectionType,
    selectedDates,
    selectedPeriods,
    periodStart,
    minDate,
    maxDate,
    onOpenerClick,
    onSelect,
    onSelectionTypeChange,
    onDeleteButtonClick,
}: Props): JSX.Element => {
    return (
        <div className={classNames(style.root, isOpened && style.opened, readOnly && style.readOnly)}>
            <div className={style.opener} onClick={!readOnly ? onOpenerClick : null}>
                <div className={style.openerIcon}>
                    <Icon type={IconType.CALENDAR_ICON} svgSize={16} />
                </div>
                Выбрать даты
            </div>

            {!lodash.isEmpty(selectedPeriods) && (
                <div className={style.list}>
                    <div className={style.items}>
                        {selectedPeriods.map((item) => (
                            <div className={style.period} key={item.title}>
                                {item.title}

                                {!readOnly && (
                                    <div className={style.deleteButton} onClick={() => onDeleteButtonClick(item.dates)}>
                                        <Icon type={IconType.CROSS} svgSize={7} />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {isOpened && (
                <div className={style.content}>
                    <DatepickerContent
                        shouldCloseOnSelect={false}
                        highlightDates={selectedDates}
                        startDate={periodStart}
                        minDate={minDate ? moment(minDate) : null}
                        maxDate={maxDate ? moment(maxDate) : null}
                        footer={
                            <div className={style.buttons}>
                                <div
                                    className={classNames(
                                        style.button,
                                        selectionType === SelectionType.Date && style.selected,
                                    )}
                                    onClick={() => onSelectionTypeChange(SelectionType.Date)}
                                >
                                    Выбор даты
                                </div>

                                <div
                                    className={classNames(
                                        style.button,
                                        selectionType === SelectionType.Period && style.selected,
                                    )}
                                    onClick={() => onSelectionTypeChange(SelectionType.Period)}
                                >
                                    Выбор периода
                                </div>
                            </div>
                        }
                        selectsRange={selectionType === SelectionType.Period}
                        onChange={(value) =>
                            onSelect(
                                selectionType === SelectionType.Date
                                    ? (value as Partial<moment.Moment>).toDate()
                                    : (value as [Date, Date]),
                            )
                        }
                    />
                </div>
            )}

            {isOpened && <div className={style.mask} onClick={onOpenerClick} />}
        </div>
    );
};
