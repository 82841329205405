import { actionCreatorFactory } from 'typescript-fsa';
import { Moment } from 'moment';
import { TaskAttributes, ActivityParams, ActivityStatus } from 'sber-marketing-types/frontend';

import { FileAsset } from '../commonTypes';
import { ReplaceFilesPayload, SaveTaskParams } from './types';

const creator = actionCreatorFactory('TASK_EDITOR');

// Async actions

/** Save (create or update) task on backend */
export const saveTask = creator.async<SaveTaskParams, void, Error>('SAVE_TASK');

/** Load task from backend by it's identifier */
export const loadTask = creator.async<string, void, Error>('LOAD_TASK');

/** Load activity from backend by it's identifier */
export const loadActivity = creator.async<number, void, Error>('LOAD_ACTIVITY');

// Sync actions

/** Fill editor fields by task attributes */
export const editByTaskData = creator<TaskAttributes>('EDIT_BY_TASK_DATA');

/** Fill editor fields by activity parameters */
export const editByActivityData = creator<ActivityParams>('EDIT_BY_ACTIVITY_DATA');

/** Edit task identifier */
export const editId = creator<string | null>('SET_ID');

/** Edit work type identifier */
export const editWorkTypeId = creator<string | null>('EDIT_WORK_TYPE_ID');

/** Edit department identifier */
export const editDepartmentId = creator<string | null>('EDIT_DEPARTMENT_ID');

/** Edit activity identifier */
export const editActivityId = creator<number | null>('EDIT_ACTIVITY_ID');

/** Edit activity status */
export const editActivityStatus = creator<ActivityStatus>('EDIT_ACTIVITY_STATUS');

/** Edit activity start date */
export const editActivityStartDate = creator<Moment>('EDIT_ACTIVITY_START_DATE');

/** Edit activity end date */
export const editActivityEndDate = creator<Moment>('EDIT_ACTIVITY_END_DATE');

/** Edit deadline date */
export const editDeadline = creator<Moment>('EDIT_DEADLINE');

/** Edit author identifier */
export const editAuthorId = creator<number | null>('EDIT_AUTHOR_ID');

/** Edit executor identifier */
export const editExecutorId = creator<number | null>('EDIT_EXECUTOR_ID');

/** Add task participant identifier */
export const addParticipantId = creator<number>('ADD_PARTICIPANT_ID');

/** Add several participant identifiers */
export const bulkAddParticipantsIds = creator<number[]>('BULK_ADD_PARTICIPANTS_IDS');

/** Remove task participant identifier */
export const removeParticipantId = creator<number>('REMOVE_PARTICIPANT_ID');

/** Remove several task participants identifiers */
export const bulkRemoveParticipantsIds = creator<number[]>('BULK_REMOVE_PARTICIPANTS_IDS');

/** Reset editor */
export const resetTaskEditor = creator('RESET_EDITOR');

/** Reset deadline date */
export const resetDeadline = creator('RESET_DEADLINE');

// Wrapped sync actions - doesn't uses directly, only wrapped in thunks

/** Edit task title */
export const editTitle = creator<string>('EDIT_TITLE');

/** Edit task description text */
export const editDescription = creator<string>('EDIT_DESCRIPTION');

/** Edit task status */
export const editStatus = creator<string>('EDIT_STATUS');

/** Add files to store */
export const addFiles = creator<FileAsset | FileAsset[]>('ADD_FILES');

/** Reload files */
export const reloadFiles = creator<FileAsset | FileAsset[]>('RELOAD_FILES');

/** Replaces files at store */
export const replaceFiles = creator<ReplaceFilesPayload | ReplaceFilesPayload[]>('REPLACE_FILES');

/** Remove files from store */
export const removeFiles = creator<string | string[]>('REMOVE_FILES');

/** Clear removing files list */
export const clearRemoveList = creator('CLEAR_REMOVE_LIST');

export const setPreloaderStatus = creator<boolean>('SET_PRELOADER_STATUS');

export const setTaskStageId = creator<string>('SET_TASK_STAGE_ID');
