import * as React from 'react';
import { Link } from 'react-router-dom';

import * as style from './CardDeleteConfirm.scss';

import {
    Icon,
    IconType,
    BodyPortal,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
} from 'sber-marketing-ui';

interface Props {
    activityId: number;
    activityName: string;
    serialNumber: number;
    onConfirmButtonClick: () => void;
    onCancelButtonClick: () => void;
}

export function CardDeleteConfirm({
    activityId,
    activityName,
    serialNumber,
    onConfirmButtonClick,
    onCancelButtonClick,
}: Props) {
    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'dashboardCardDeleteConfirm',
            }}
        >
            <div className={style.title}>Удалить связь проекта и ID?</div>

            <div className={style.text}>
                <div
                    className={style.textLine}
                    {...{
                        'qa-id': 'dashboardCardDeleteConfirmActivityName',
                    }}
                >
                    Проект:&nbsp;
                    <Link className={style.link} to={`/activity/${activityId}`} target="_blank">
                        {activityName}
                    </Link>
                </div>

                <div
                    className={style.textLine}
                    {...{
                        'qa-id': 'dashboardCardDeleteConfirmSerialNumber',
                    }}
                >
                    ID:&nbsp;
                    <Link
                        className={style.link}
                        to={`/budget/execution?filters=serialNumber:${serialNumber}`}
                        target="_blank"
                    >
                        {serialNumber}
                    </Link>
                </div>
            </div>

            <div className={style.buttons}>
                <div className={style.button}>
                    <Button
                        qaId="dashboardCardDeleteConfirmCancelButton"
                        theme={ButtonTheme.GhostRounded}
                        onClick={onCancelButtonClick}
                    >
                        Отменить
                    </Button>
                </div>

                <div className={style.button}>
                    <Button
                        qaId="dashboardCardDeleteConfirmConfirmButton"
                        theme={ButtonTheme.MainRounded}
                        onClick={onConfirmButtonClick}
                    >
                        Удалить
                    </Button>
                </div>
            </div>

            <div className={style.closeButton} onClick={onCancelButtonClick}>
                <div className={style.closeIcon}>
                    <Icon type={IconType.CLOSE_SEMIBOLD} svgSize={16} />
                </div>
            </div>

            <BodyPortal>
                <div className={style.mask} />
            </BodyPortal>
        </div>
    );
}
