import * as React from 'react';

import * as style from './FileUploader.scss';

interface FileUploaderProps {
    fileInputRef: (element: HTMLInputElement) => void;
    onAddButtonClick: () => void;
    onFileAdded: () => void;
    children: JSX.Element;
}

export const FileUploader = ({
    fileInputRef,
    onAddButtonClick,
    onFileAdded,
    children,
}: FileUploaderProps): JSX.Element => {
    return (
        <div className={style.root} onClick={onAddButtonClick}>
            {children}

            <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={onFileAdded} />
        </div>
    );
};
