import gql from 'graphql-tag';

export const GET_ACTIVITY_QUERY = gql`
    query Cards($cardsFilter: ProjectFilter) {
        activities: getProjects(filter: $cardsFilter) {
            nodes {
                id
                name
                brief {
                    schema {
                        name
                    }
                }
                workTypes {
                    name
                }
                product {
                    name
                }
                budgetItems {
                    serialNumber
                }
            }
        }
    }
`;

export const GET_REQUEST_QUERY = gql`
    query getRequest($requestId: String) {
        request: getApprovalRequests(filter: { id: [$requestId] }) {
            nodes {
                project {
                    name
                }
            }
        }
    }
`;
