import * as React from 'react';
import autobind from 'autobind-decorator';

import { EstimateValue } from '../types';
import { EstimateBarTemplate } from './EstimateBarTemplate';

interface State {
    hoveredEstimateValue: EstimateValue;
}

interface Props {
    onEstimateValueSelect: (estimateValue: EstimateValue) => void;
}

export class EstimateBarBehavior extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            hoveredEstimateValue: null,
        };
    }

    public render() {
        const { hoveredEstimateValue } = this.state;

        return React.createElement(EstimateBarTemplate, {
            hoveredEstimateValue,
            onEstimateValueClick: this.onEstimateValueClick,
            onEstimateValueMouseEnter: this.onEstimateValueMouseEnter,
            onEstimateValuesMouseLeave: this.onEstimateValuesMouseLeave,
        });
    }

    @autobind
    private onEstimateValueClick(estimateValue: EstimateValue): void {
        const { onEstimateValueSelect } = this.props;
        onEstimateValueSelect(estimateValue);
    }

    @autobind
    private onEstimateValueMouseEnter(estimateValue: EstimateValue): void {
        this.setState({ hoveredEstimateValue: estimateValue });
    }

    @autobind
    private onEstimateValuesMouseLeave(): void {
        this.setState({ hoveredEstimateValue: null });
    }
}

export type { EstimateValue };
