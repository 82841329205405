import { Success } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { uniqBy } from 'lodash';
import { BudgetItem } from '@mrm/budget';

import { LoadingStatus } from '@store/commonTypes';

import * as actions from '../actions/sync';
import * as asyncActions from '../actions/async';

import { checkCellsEquality, cellDescriptor } from '../misc';
import {
    BudgetTransferMenuState as State,
    CellPosition,
    TransferDescriptorsState,
    BudgetTransferDesciptor,
} from '../types';

import { CellsReducer } from './cells';
import { ControlsReducer } from './controls';
import { DataReducer } from './data';
import { ExpertsReducer } from './experts';
import { ParticipatorDataReducer } from './participatorData';

export class Reducer {
    public static makeInitialState(): State {
        return {
            controls: ControlsReducer.makeInitialState(),
            cells: CellsReducer.makeInitialState(),
            experts: ExpertsReducer.makeInitialState(),
            data: DataReducer.makeInitialState(),
            participatorData: ParticipatorDataReducer.makeInitialState(),
            transferDescriptors: [],
        };
    }

    public static setComponentStateDone(state: State, payload: Success<any, State>): State {
        return { ...state, ...payload.result };
    }

    public static updateBudgetItemsDone(state: State, payload: Success<any, BudgetItem[]>): State {
        return DataReducer.merge(state, { budgetItems: payload.result });
    }

    public static updateTransferDescriptorsDone(state: State, payload: Success<any, TransferDescriptorsState>): State {
        return { ...state, transferDescriptors: payload.result };
    }

    public static startLoadingExperts(state: State): State {
        return ExpertsReducer.merge(state, { loadingStatus: LoadingStatus.LOADING });
    }

    public static setHoveredCell(state: State, hoveredCell: CellPosition): State {
        return CellsReducer.merge(state, { hoveredCell });
    }

    public static setDonorCell(state: State, fromUpd: CellPosition): State {
        const from = uniqBy([...state.cells.from, fromUpd], (cell) => cellDescriptor(cell));

        return CellsReducer.merge(state, { from });
    }

    public static clearDonorCell(state: State): State {
        return CellsReducer.merge(state, { from: [] });
    }

    public static setAcceptorCell(state: State, toUpd: CellPosition): State {
        const to = uniqBy([...state.cells.to, toUpd], (cell) => cellDescriptor(cell));

        return CellsReducer.merge(state, { to });
    }

    public static setTransferAmount(state: State, payload: BudgetTransferDesciptor): State {
        return {
            ...state,
            transferDescriptors: state.transferDescriptors.map((descriptor) => {
                if (
                    checkCellsEquality(descriptor.from, payload.from) &&
                    checkCellsEquality(descriptor.to, payload.to)
                ) {
                    return {
                        ...payload,
                        amount: +payload.amount.toFixed(0),
                    };
                }

                return descriptor;
            }),
        };
    }

    public static setParticipatorItemId(state: State, participatorItemId: string): State {
        return ParticipatorDataReducer.merge(state, { participatorItemId });
    }

    public static setParticipatorComment(state: State, participatorComment: string): State {
        return ParticipatorDataReducer.merge(state, { participatorComment });
    }

    public static selectExpert(state: State, selectedExpert: number): State {
        return ExpertsReducer.merge(state, { selectedExpert });
    }

    public static setRequestInProgress(state: State, isRequestInProgress: boolean): State {
        return ControlsReducer.merge(state, { isRequestInProgress });
    }

    public static setRowForSumEntering(state: State, rowForSumEntering: string): State {
        return ControlsReducer.merge(state, { rowForSumEntering });
    }

    public static setIsInternalTransferDirrectionToggleHovered(
        state: State,
        isTransferDirectionToggleHovered: boolean,
    ): State {
        return ControlsReducer.merge(state, { isTransferDirectionToggleHovered });
    }
}

export const budgetTransferMenuReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(asyncActions.setComponentState.done, Reducer.setComponentStateDone)
    .case(asyncActions.toggleLineStatus.done, CellsReducer.mergeAfterAsyncDone)
    .case(asyncActions.onCellClick.done, CellsReducer.mergeAfterAsyncDone)
    .case(asyncActions.loadExperts.done, ExpertsReducer.mergeAfterAsyncDone)
    .case(asyncActions.toggleInternalTransitionDirectrion.done, ControlsReducer.mergeAfterAsyncDone)
    .case(asyncActions.updateIsHoveredLineClickable.done, ControlsReducer.mergeAfterAsyncDone)
    .case(asyncActions.updateBudgetItems.done, Reducer.updateBudgetItemsDone)
    .case(asyncActions.updateTransferDescriptors.done, Reducer.updateTransferDescriptorsDone)
    .case(actions.updateCells, CellsReducer.merge)
    .case(actions.startLoadingExperts, Reducer.startLoadingExperts)
    .case(actions.setHoveredCell, Reducer.setHoveredCell)
    .case(actions.setDonorCell, Reducer.setDonorCell)
    .case(actions.clearDonorCell, Reducer.clearDonorCell)
    .case(actions.setAcceptorCell, Reducer.setAcceptorCell)
    .case(actions.setTransferAmount, Reducer.setTransferAmount)
    .case(actions.setParticipatorItemId, Reducer.setParticipatorItemId)
    .case(actions.setParticipatorComment, Reducer.setParticipatorComment)
    .case(actions.selectExpert, Reducer.selectExpert)
    .case(actions.setRequestInProgress, Reducer.setRequestInProgress)
    .case(actions.setRowForSumEntering, Reducer.setRowForSumEntering)
    .case(actions.setIsInternalTransferDirrectionToggleHovered, Reducer.setIsInternalTransferDirrectionToggleHovered);
