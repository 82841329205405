import * as React from 'react';
import classNames from 'classnames';

import * as style from './TitleWithCountIndicator.scss';

interface Props {
    title: string;
    count: number;
    zeroCountLablel?: string;
}

export function TitleWithCountIndicator({ title, count, zeroCountLablel }: Props): JSX.Element {
    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'titleWithCountIndicator',
            }}
        >
            <div className={style.title}>{title}</div>

            <div className={classNames(style.countIndicator, !count && style.countIndicatorEmpty)}>
                {count || zeroCountLablel || 'Отсутствуют'}
            </div>
        </div>
    );
}
