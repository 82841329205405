import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { UserConfigType } from 'sber-marketing-types/openid';
import { debounce } from 'lodash';

import { UserConfigApi } from '@api';

import { StoreState } from '@store';
import { loadCommonUserConfig, getCommonUserConfig, updateCommonUserConfig, CommonUserConfig } from './common';
import { loadBudgetUserConfig, getBudgetUserConfig, updateBudgetUserConfig, BudgetUserConfig } from './budget';
import {
    loadCreativeUserConfig,
    getCreativeUserConfig,
    updateCreativeUserConfig,
    CreativeUserConfig,
} from './creative';
import {
    loadDashboardUserConfig,
    getDashboardUserConfig,
    updateDashboardUserConfig,
    DashboardUserConfig,
} from './dashboard';
import {
    getBudgetCorrectionsUserConfig,
    updateBudgetCorrectionsUserConfig,
    loadBudgetCorrectionsUserConfig,
    BudgetCorrectionsUserConfig,
} from './budgetCorrections';
import {
    loadPersonalDashboardUserConfig,
    getPersonalDashboardUserConfig,
    updatePersonalDashboardUserConfig,
    PersonalDashboardUserConfig,
} from './personalDashboard';

import {
    loadUserConfig as loadUserConfigAction,
    saveUserConfig as saveUserConfigAction,
    updateUserConfig as updateUserConfigAction,
} from './actions';
import { SaveUserConfig, AllUserConfigs } from './types';

type UserConfigUpdater = (userConfig: object) => Promise<void>;
const cache: Partial<Record<UserConfigType, UserConfigUpdater>> = {};
function getUpdateUserConfigDebounced(type: UserConfigType): UserConfigUpdater {
    if (!cache[type]) {
        cache[type] = debounce((userConfig: object) => {
            return UserConfigApi.savePageConfig(type, userConfig);
        }, 500);
    }

    return cache[type];
}

export const loadUserConfig = bindThunkAction<StoreState, UserConfigType, void, Error>(
    loadUserConfigAction,
    async (userConfigType, dispatch, getState) => {
        const receivedUserConfig = await UserConfigApi.getPageConfig(userConfigType);

        switch (userConfigType) {
            case UserConfigType.Common:
                dispatch(loadCommonUserConfig(receivedUserConfig as CommonUserConfig));
                break;
            case UserConfigType.Dashboard:
                dispatch(loadDashboardUserConfig(receivedUserConfig as DashboardUserConfig));
                break;
            case UserConfigType.BudgetCorrections:
                dispatch(loadBudgetCorrectionsUserConfig(receivedUserConfig as BudgetCorrectionsUserConfig));
                break;
            case UserConfigType.PersonalDashboard:
                dispatch(loadPersonalDashboardUserConfig(receivedUserConfig as PersonalDashboardUserConfig));
                break;
            case UserConfigType.Budget:
                dispatch(loadBudgetUserConfig(receivedUserConfig as BudgetUserConfig));
                break;
            case UserConfigType.Creative:
                dispatch(loadCreativeUserConfig(receivedUserConfig as CreativeUserConfig));
                break;
            default:
                throw new Error(`Missing store declaration for userConfig: ${userConfigType}`);
        }
    },
);

export const saveUserConfig = bindThunkAction<StoreState, SaveUserConfig<AllUserConfigs>, void, Error>(
    saveUserConfigAction,
    async (params, dispatch, getState) => {
        const { type, payload, preventBackendUpdate } = params;

        switch (type) {
            case UserConfigType.Common:
                dispatch(
                    updateCommonUserConfig({
                        ...payload,
                    }),
                );
                break;
            case UserConfigType.Dashboard:
                dispatch(
                    updateDashboardUserConfig({
                        userConfig: payload,
                    }),
                );
                break;
            case UserConfigType.BudgetCorrections:
                dispatch(
                    updateBudgetCorrectionsUserConfig({
                        preventBackendUpdate,
                        ...payload,
                    }),
                );
                break;
            case UserConfigType.PersonalDashboard:
                dispatch(
                    updatePersonalDashboardUserConfig({
                        userConfig: payload,
                    }),
                );
                break;
            case UserConfigType.Budget:
                dispatch(
                    updateBudgetUserConfig({
                        ...payload,
                    }),
                );
                break;
            case UserConfigType.Creative:
                dispatch(
                    updateCreativeUserConfig({
                        ...payload,
                    }),
                );
                break;

            default:
                throw new Error(`Missing store declaration for userConfig: ${type}`);
        }
    },
);

export const updateUserConfig = bindThunkAction<StoreState, AllUserConfigs, void, Error>(
    updateUserConfigAction,
    async (type, dispatch, getState) => {
        const state = getState();
        let userConfigType;
        let userConfigContent;

        switch (type) {
            case UserConfigType.Common:
                userConfigType = UserConfigType.Common;
                userConfigContent = getCommonUserConfig(state);
                break;
            case UserConfigType.Dashboard:
                userConfigType = UserConfigType.Dashboard;
                userConfigContent = getDashboardUserConfig(state);
                break;
            case UserConfigType.BudgetCorrections:
                userConfigType = UserConfigType.BudgetCorrections;
                userConfigContent = getBudgetCorrectionsUserConfig(state);
                break;
            case UserConfigType.PersonalDashboard:
                userConfigType = UserConfigType.PersonalDashboard;
                userConfigContent = getPersonalDashboardUserConfig(state);
                break;
            case UserConfigType.Budget:
                userConfigType = UserConfigType.Budget;
                userConfigContent = getBudgetUserConfig(state);
                break;
            case UserConfigType.Creative:
                userConfigType = UserConfigType.Creative;
                userConfigContent = getCreativeUserConfig(state);
                break;
            default:
                throw new Error(`Missing store declaration for userConfig: ${type}`);
        }

        await getUpdateUserConfigDebounced(userConfigType)(userConfigContent);
    },
);
