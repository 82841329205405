import * as moment from 'moment';
import { cloneDeep, includes } from 'lodash';

import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Success } from 'typescript-fsa';

import {
    PageState,
    LoadPagePayload,
    LoadPageDone,
    SetFiltersPayload,
    FilterPeriod,
    DataFilterBy,
    SetFilterPeriodResult,
    SetFilterPeriodPayload,
    SetFilterDateResult,
    SetFilterDatePayload,
    SetFilterByResult,
    SetFilterByPayload,
    StatisticsActivityType,
    StatisticsTaskType,
    StatisticsType,
    StatisticsItem,
    StatisticsResponse,
} from './types';
import * as actions from './actions';
import { buildersStatisticsByMode } from './utils';
import { getAncestorStatisticsItem, convertStatisticsItemsToFilters } from './utils/utilsByMode/common';

const initialState: PageState = {
    data: {
        users: [],
        departments: [],
        statistics: {
            current: [],
            past: [],
        },
    },
    selectedStatisticsItemId: null,
    selectedBy: DataFilterBy.Responsible,
    selectedPeriod: FilterPeriod.Month,
    selectedDate: moment(Date.now()).format('YYYY-MM-DD'),
    statisticsTypeFilters: null,
    statisticsItems: [],
    filters: {},
    loading: true,
};

class Reducer {
    public static loadPageStarted(state: PageState): PageState {
        return {
            ...state,
            loading: true,
        };
    }

    public static loadPageDone(state: PageState, payload: Success<LoadPagePayload, LoadPageDone>): PageState {
        let statisticsItems: StatisticsItem[] = [];

        if (payload.params.mode === 'activities') {
            const buildStatisticsItems = buildersStatisticsByMode['activities'];

            statisticsItems = buildStatisticsItems({
                departments: payload.result.pageData.departments,
                statistics: payload.result.pageData.statistics as StatisticsResponse<'activities'>,
                users: payload.result.pageData.users,
            });
        }

        if (payload.params.mode === 'tasks') {
            const buildStatisticsItems = buildersStatisticsByMode['tasks'];

            statisticsItems = buildStatisticsItems({
                departments: payload.result.pageData.departments,
                statistics: payload.result.pageData.statistics as StatisticsResponse<'tasks'>,
                users: payload.result.pageData.users,
            });
        }

        const selectedStatisticsItemId = statisticsItems.length
            ? getAncestorStatisticsItem(statisticsItems).id
            : state.selectedStatisticsItemId;

        const filters = convertStatisticsItemsToFilters(statisticsItems);

        const statisticsTypeFilters = {
            [StatisticsActivityType.OVERDUE_STAGES]: true,
            [StatisticsActivityType.OVERDUE_TASKS]: true,
            [StatisticsActivityType.ACTIVE]: true,
            [StatisticsActivityType.PLANNED_START]: true,
            [StatisticsTaskType.CLOSED]: true,
            [StatisticsTaskType.ACTIVE]: true,
            [StatisticsTaskType.COMING_DEADLINE]: true,
            [StatisticsTaskType.OVERDUE]: true,
        };

        return {
            ...state,
            statisticsItems,
            selectedStatisticsItemId,
            filters,
            statisticsTypeFilters,
            loading: false,
            data: {
                ...state.data,
                ...payload?.result.pageData,
            },
        };
    }

    public static setFilters(state: PageState, payload: SetFiltersPayload): PageState {
        const filters = {
            ...state.filters,
            ...payload.filters,
        };

        let statisticsItems: StatisticsItem[] = [];

        if (payload.mode === 'activities') {
            const buildStatisticsItems = buildersStatisticsByMode['activities'];

            statisticsItems = buildStatisticsItems({
                departments: state.data.departments,
                statistics: state.data.statistics as StatisticsResponse<'activities'>,
                users: state.data.users,
                filters,
            });
        }

        if (payload.mode === 'tasks') {
            const buildStatisticsItems = buildersStatisticsByMode['tasks'];

            statisticsItems = buildStatisticsItems({
                departments: state.data.departments,
                statistics: state.data.statistics as StatisticsResponse<'tasks'>,
                users: state.data.users,
                filters,
            });
        }

        return {
            ...state,
            statisticsItems,
            filters,
        };
    }

    public static setStatisticsTypeFilter(state: PageState, payload: Record<StatisticsType, boolean>): PageState {
        const filters = { ...state.statisticsTypeFilters, ...payload };

        return {
            ...state,
            statisticsTypeFilters: filters,
        };
    }

    public static setFilterPeriodStarted(state: PageState, payload: SetFilterPeriodPayload): PageState {
        return {
            ...state,
            loading: true,
            selectedPeriod: payload.filterPeriod,
        };
    }

    public static setFilterPeriodDone(
        state: PageState,
        payload: Success<SetFilterPeriodPayload, SetFilterPeriodResult>,
    ): PageState {
        let statisticsItems: StatisticsItem[] = [];

        if (payload.params.mode === 'activities') {
            const buildStatisticsItems = buildersStatisticsByMode['activities'];

            statisticsItems = buildStatisticsItems({
                departments: payload.result.data.departments,
                statistics: payload.result.data.statistics as StatisticsResponse<'activities'>,
                users: payload.result.data.users,
                filters: state.filters,
            });
        }

        if (payload.params.mode === 'tasks') {
            const buildStatisticsItems = buildersStatisticsByMode['tasks'];

            statisticsItems = buildStatisticsItems({
                departments: payload.result.data.departments,
                statistics: payload.result.data.statistics as StatisticsResponse<'tasks'>,
                users: payload.result.data.users,
                filters: state.filters,
            });
        }

        const filters = convertStatisticsItemsToFilters(statisticsItems);

        const selectedStatisticsItemId = statisticsItems.length
            ? includes(
                  statisticsItems.map(({ id }) => id),
                  state.selectedStatisticsItemId,
              )
                ? state.selectedStatisticsItemId
                : getAncestorStatisticsItem(statisticsItems).id
            : state.selectedStatisticsItemId;

        return {
            ...state,
            statisticsItems,
            filters: {
                ...filters,
                ...state.filters,
            },
            data: {
                ...state.data,
                ...payload.result.data,
            },
            loading: false,
            selectedStatisticsItemId,
            selectedPeriod: payload.result.filterPeriod,
        };
    }

    public static setFilterDateStarted(state: PageState, payload: SetFilterDatePayload): PageState {
        return {
            ...state,
            loading: true,
            selectedDate: payload.filterDate,
        };
    }

    public static setFilterDateDone(
        state: PageState,
        payload: Success<SetFilterDatePayload, SetFilterDateResult>,
    ): PageState {
        let statisticsItems: StatisticsItem[] = [];

        if (payload.params.mode === 'activities') {
            const buildStatisticsItems = buildersStatisticsByMode['activities'];

            statisticsItems = buildStatisticsItems({
                departments: payload.result.data.departments,
                statistics: payload.result.data.statistics as StatisticsResponse<'activities'>,
                users: payload.result.data.users,
                filters: state.filters,
            });
        }

        if (payload.params.mode === 'tasks') {
            const buildStatisticsItems = buildersStatisticsByMode['tasks'];

            statisticsItems = buildStatisticsItems({
                departments: payload.result.data.departments,
                statistics: payload.result.data.statistics as StatisticsResponse<'tasks'>,
                users: payload.result.data.users,
                filters: state.filters,
            });
        }

        const filters = convertStatisticsItemsToFilters(statisticsItems);

        const selectedStatisticsItemId = statisticsItems.length
            ? includes(
                  statisticsItems.map(({ id }) => id),
                  state.selectedStatisticsItemId,
              )
                ? state.selectedStatisticsItemId
                : getAncestorStatisticsItem(statisticsItems).id
            : state.selectedStatisticsItemId;

        return {
            ...state,
            statisticsItems,
            filters: {
                ...filters,
                ...state.filters,
            },
            data: {
                ...state.data,
                ...payload.result.data,
            },
            loading: false,
            selectedStatisticsItemId,
            selectedDate: payload.result.filterDate,
        };
    }

    public static setFilterByStarted(state: PageState, payload: SetFilterByPayload): PageState {
        return {
            ...state,
            loading: true,
            selectedBy: payload.by,
        };
    }

    public static setFilterByDone(
        state: PageState,
        payload: Success<SetFilterByPayload, SetFilterByResult>,
    ): PageState {
        let statisticsItems: StatisticsItem[] = [];

        if (payload.params.mode === 'activities') {
            const buildStatisticsItems = buildersStatisticsByMode['activities'];

            statisticsItems = buildStatisticsItems({
                departments: payload.result.data.departments,
                statistics: payload.result.data.statistics as StatisticsResponse<'activities'>,
                users: payload.result.data.users,
                filters: state.filters,
            });
        }

        if (payload.params.mode === 'tasks') {
            const buildStatisticsItems = buildersStatisticsByMode['tasks'];

            statisticsItems = buildStatisticsItems({
                departments: payload.result.data.departments,
                statistics: payload.result.data.statistics as StatisticsResponse<'tasks'>,
                users: payload.result.data.users,
                filters: state.filters,
            });
        }

        const filters = convertStatisticsItemsToFilters(statisticsItems);

        const selectedStatisticsItemId = statisticsItems.length
            ? includes(
                  statisticsItems.map(({ id }) => id),
                  state.selectedStatisticsItemId,
              )
                ? state.selectedStatisticsItemId
                : getAncestorStatisticsItem(statisticsItems).id
            : state.selectedStatisticsItemId;

        return {
            ...state,
            statisticsItems,
            filters: {
                ...filters,
                ...state.filters,
            },
            data: {
                ...state.data,
                ...payload.result.data,
            },
            loading: false,
            selectedStatisticsItemId,
            selectedBy: payload.result.by,
        };
    }

    public static setSelectedStatisticsItemId(state: PageState, payload: string): PageState {
        return {
            ...state,
            selectedStatisticsItemId: payload,
        };
    }

    public static resetPageStore(): PageState {
        return cloneDeep(initialState);
    }
}

export const leaderDashboardPageReducer = reducerWithInitialState(initialState)
    .case(actions.loadPageAsync.started, Reducer.loadPageStarted)
    .case(actions.loadPageAsync.done, Reducer.loadPageDone)
    .case(actions.resetPageStore, Reducer.resetPageStore)
    .case(actions.setSelectedStatisticsItemId, Reducer.setSelectedStatisticsItemId)
    .case(actions.setStatisticsTypeFilters, Reducer.setStatisticsTypeFilter)
    .case(actions.setFilters, Reducer.setFilters)
    .case(actions.setFilterPeriodAsync.started, Reducer.setFilterPeriodStarted)
    .case(actions.setFilterPeriodAsync.done, Reducer.setFilterPeriodDone)
    .case(actions.setFilterDateAsync.started, Reducer.setFilterDateStarted)
    .case(actions.setFilterDateAsync.done, Reducer.setFilterDateDone)
    .case(actions.setFilterByAsync.started, Reducer.setFilterByStarted)
    .case(actions.setFilterByAsync.done, Reducer.setFilterByDone);
