import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { last } from 'lodash';

import { StoreState } from '@store';
import { TasksList, TasksListType, TaskCardType, TaskCardParams } from './types';
import { LoadingStatus } from '../commonTypes';

const getIndex = (_: StoreState, index: number): number => index;

const getId = (_: StoreState, id: string): string => id;

export const getTasksListState = (state: StoreState): TasksList => state.tasksList;

export const getStatus = createSelector(getTasksListState, ({ status }): LoadingStatus => status);

export const getType = createSelector(getTasksListState, ({ type }): TasksListType => type);

export const getCardType = createSelector(getTasksListState, ({ cardType }): TaskCardType => cardType);

export const getActivityId = createSelector(getTasksListState, ({ activityId }): number | null => activityId);

export const getCanBeLoadedMore = createSelector(getTasksListState, ({ canBeLoadedMore }): boolean => canBeLoadedMore);

export const getTasksList = createSelector(getTasksListState, ({ tasks }): TaskCardParams[] => tasks);

export const getTasksListByActivityId = createCachedSelector(
    getTasksListState,
    (state: StoreState, activityId: number) => activityId,
    (state: TasksList, activityId: number) =>
        activityId ? state.tasks.filter((task) => task.activityId == activityId) : state.tasks,
)((state: StoreState, activityId: number) => `${activityId}-${getTasksListState(state).tasks.join()}`);

export const getError = createSelector(getTasksListState, ({ error }): Error | null => error);

export const getTaskByIndex = createSelector(
    [getTasksList, getIndex],
    (tasks, index): TaskCardParams | undefined => tasks[index],
);

export const getTaskById = createSelector([getTasksList, getId], (tasks, id): TaskCardParams | undefined =>
    tasks.find((value) => value.id === id),
);

export const getLastTask = createSelector(getTasksList, last);

export const getLastRequestDate = createSelector(getTasksListState, (state): string => state.lastRequestDate);
