import * as React from 'react';
import { useSelector } from 'react-redux';

import { ConfirmButton } from './ConfirmButton';
import { Counter } from './Counter';
import { Tips } from './Tips';

import * as styles from './styles.scss';
import { StoreState } from '@store';
import { getSelectedTextContents, getSelectedTextTitles } from '@store/autopilot/generationTextForm';

interface Props {}

export const Footer: React.FC<Props> = ({}) => {
    const selectedTitles = useSelector((state: StoreState) => getSelectedTextTitles(state));
    const selectedContents = useSelector((state: StoreState) => getSelectedTextContents(state));

    return (
        <div className={styles.root}>
            {!selectedTitles.length && !selectedContents.length ? <Tips /> : <Counter />}
            <ConfirmButton />
        </div>
    );
};
