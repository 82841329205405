// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-TitleWithCountIndicator-_TitleWithCountIndicator__root{display:flex;font-family:\"Open Sans\";align-items:center;justify-content:space-between}.src-client-modules-common-TitleWithCountIndicator-_TitleWithCountIndicator__title{margin-right:4px;letter-spacing:0.3px;color:#000000;font-family:'Open Sans';font-size:13px;font-weight:600;user-select:none}.src-client-modules-common-TitleWithCountIndicator-_TitleWithCountIndicator__countIndicator{display:inline-block;padding:0 8px;letter-spacing:0.22px;color:#ffffff;border-radius:8px;background-color:#19bb4f;font-family:'Open Sans';font-size:12px;font-weight:600;line-height:16px}.src-client-modules-common-TitleWithCountIndicator-_TitleWithCountIndicator__countIndicatorEmpty{background-color:#7e8681}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/TitleWithCountIndicator/TitleWithCountIndicator.scss"],"names":[],"mappings":"AAAA,kFACI,YAAa,CAEb,uBAAwB,CAExB,kBAAmB,CACnB,6BAA8B,CACjC,mFAGG,gBAAiB,CAEjB,oBAAqB,CAErB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACpB,4FAGG,oBAAqB,CAErB,aAAc,CAEd,qBAAsB,CAEtB,aAAc,CACd,iBAAkB,CAClB,wBAAyB,CAEzB,uBAAwB,CACxB,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACpB,iGAGG,wBAAyB","sourcesContent":[".root {\n    display: flex;\n\n    font-family: \"Open Sans\";\n\n    align-items: center;\n    justify-content: space-between;\n}\n\n.title {\n    margin-right: 4px;\n\n    letter-spacing: 0.3px;\n\n    color: #000000;\n\n    font-family: 'Open Sans';\n    font-size: 13px;\n    font-weight: 600;\n    user-select: none;\n}\n\n.countIndicator {\n    display: inline-block;\n\n    padding: 0 8px;\n\n    letter-spacing: 0.22px;\n\n    color: #ffffff;\n    border-radius: 8px;\n    background-color: #19bb4f;\n\n    font-family: 'Open Sans';\n    font-size: 12px;\n    font-weight: 600;\n    line-height: 16px;\n}\n\n.countIndicatorEmpty {\n    background-color: #7e8681;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-TitleWithCountIndicator-_TitleWithCountIndicator__root",
	"title": "src-client-modules-common-TitleWithCountIndicator-_TitleWithCountIndicator__title",
	"countIndicator": "src-client-modules-common-TitleWithCountIndicator-_TitleWithCountIndicator__countIndicator",
	"countIndicatorEmpty": "src-client-modules-common-TitleWithCountIndicator-_TitleWithCountIndicator__countIndicatorEmpty"
};
export default ___CSS_LOADER_EXPORT___;
