import * as React from 'react';
import { CorrectionType } from '@mrm/budget';

import { Menu, Params, SerialNumber, Transition, Body } from '../common';

import { ActivityCorrection } from '../../../types';
import { ActivityCorrectionCard as ActivityCorrectionCardTooltip } from '../CardTypes';

import * as style from './ActivityCorrectionCard.scss';

interface Props {
    correction: ActivityCorrection;
    newActivityName: string;
    oldActivityName: string;
    onCardMouseEnter: () => void;
    onCardMouseLeave: () => void;
    onChange: () => void;
}

export const ActivityCorrectionCard: React.FC<Props> = ({
    correction,
    oldActivityName,
    newActivityName,
    onCardMouseEnter,
    onCardMouseLeave,
    onChange,
}) => {
    return (
        <Body
            qaId="dashboardRightSidebarBudgetInfoActivityCorrectionCard"
            qaCorrectionType={CorrectionType.Activity}
            tooltipComponent={<ActivityCorrectionCardTooltip {...correction} />}
            onMouseEnter={onCardMouseEnter}
            onMouseLeave={onCardMouseLeave}
        >
            <div className={style.header}>
                <SerialNumber>{correction.serialNumber}</SerialNumber>
                <div className={style.title}>Корректировка активности</div>
                <Menu
                    status={correction.status}
                    correctionId={correction.id}
                    correctionAuthorId={correction.author.id}
                    onChange={onChange}
                />
            </div>
            <Params
                data={[
                    {
                        name: 'Корректируемые параметры',
                        value: <Transition from={oldActivityName} to={newActivityName} />,
                    },
                ]}
            />
        </Body>
    );
};
