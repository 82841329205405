import { actionCreatorFactory } from 'typescript-fsa';
import { SearchResponse } from '@mrm/search';

import {
    ComponentState,
    SearchEntities,
    SetLoadingStatusPayload,
    SetShouldAnimatePayload,
    FileSearchResult,
    ActivitySearchResult,
    TaskSearchResult,
    CommentSearchResult,
} from '../types';

const actionCreator = actionCreatorFactory('SEARCH_SYNC');

const setVisibility = actionCreator<boolean>('SET_VISIBILITY');
const setState = actionCreator<ComponentState>('SET_STATE');
const setSearchQuery = actionCreator<string>('SET_SEARCH_QUERY');
const setCollapseStatus = actionCreator<SearchEntities>('SET_COLLAPSE_STATUS');
const setLoadingStatus = actionCreator<SetLoadingStatusPayload>('SET_LOADING_STATUS');
const clearLoadedEntities = actionCreator('CLEAR_LOADED_ENTITIES');
const setAnimatedGroup = actionCreator<SearchEntities>('SET_ANIMATED_GROUP');
const setSingleLoadedEntityMode = actionCreator<boolean>('SET_SINGLE_LOADED_ENTITY_MODE');
const setShouldAnimate = actionCreator<SetShouldAnimatePayload>('SET_SHOULD_ANIMATE');
const resetState = actionCreator('REST_STATE');

const loadActivities = actionCreator<SearchResponse<ActivitySearchResult>>('LOAD_ACTIVITIES');
const loadTasks = actionCreator<SearchResponse<TaskSearchResult>>('LOAD_TASKS');
const loadComments = actionCreator<SearchResponse<CommentSearchResult>>('LOAD_COMMENTS');
const loadFiles = actionCreator<SearchResponse<FileSearchResult>>('LOAD_FILES');

export {
    setVisibility,
    setState,
    setSearchQuery,
    setCollapseStatus,
    setLoadingStatus,
    clearLoadedEntities,
    setAnimatedGroup,
    setSingleLoadedEntityMode,
    setShouldAnimate,
    resetState,
    loadActivities,
    loadTasks,
    loadComments,
    loadFiles,
};
