import * as React from 'react';
import * as style from './TmHeaderDropdown.scss';
import classNames from 'classnames';
import { SortDirection } from '../../../Trademark';
import {
    BodyPortal,
    CheckboxGroupFilter,
    CheckboxGroupFilterThemes,
    FilterItem,
    FilterMenu,
    FilterMenuBorderType,
    Icon,
    IconType,
} from 'sber-marketing-ui';

interface TmHeaderDropdownProps {
    title: string;
    fieldName: string;
    sort?: SortDirection;
    onSortingChange: (fieldName: string, direction: SortDirection) => void;
    filterItems: FilterItem[];
    filterCheckedItems: React.ReactText[];
    onFilterChange: (fieldName: string, items: React.ReactText[]) => void;
}

interface TmHeaderDropdownState {
    isOpened: boolean;
}

export class TmHeaderDropdown extends React.Component<TmHeaderDropdownProps, TmHeaderDropdownState> {
    constructor(props: TmHeaderDropdownProps) {
        super(props);
        this.state = {
            isOpened: false,
        };
    }

    public render(): JSX.Element {
        const { title, fieldName, sort, onSortingChange, filterItems, filterCheckedItems, onFilterChange } = this.props;

        /**
         * @description Adapter
         */
        const onItemSelection = (selectedItemId: string | number) => {
            onSortingChange(fieldName, selectedItemId as SortDirection);
        };

        const filterChange = (checkedItemsIds: React.ReactText[]) => {
            onFilterChange(fieldName, checkedItemsIds);
        };

        const onOpen = () => {
            this.setState({ isOpened: true });
        };

        const onMaskClick = (e: React.FormEvent) => {
            e.stopPropagation();
            this.setState({ isOpened: false });
        };

        return (
            <div className={style.root} onClick={onOpen}>
                <div className={classNames(style.title, (sort || this.state.isOpened) && style.opened)}>{title}</div>
                <div className={style.icon}>
                    <Icon type={this.state.isOpened ? IconType.ARROW20_UP : IconType.ARROW20_DOWN} />
                </div>
                {this.state.isOpened ? (
                    <>
                        <div className={style.content}>
                            <CheckboxGroupFilter
                                title="Сортировка"
                                items={[
                                    {
                                        id: SortDirection.Asc,
                                        title: 'От А до Я',
                                    },
                                    {
                                        id: SortDirection.Desc,
                                        title: 'От Я до А',
                                    },
                                ]}
                                checkedItemId={sort}
                                onItemSelection={onItemSelection}
                                theme={CheckboxGroupFilterThemes.Budget}
                            />
                            <FilterMenu
                                filterTitle="Отображаемые значения"
                                items={filterItems}
                                checkedItemsIds={filterCheckedItems}
                                onItemSelection={filterChange}
                                isExpanded
                                border={FilterMenuBorderType.NONE}
                            />
                        </div>
                        <BodyPortal>
                            <div className={style.mask} onClick={onMaskClick} />
                        </BodyPortal>
                    </>
                ) : null}
            </div>
        );
    }
}
