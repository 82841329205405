import * as React from 'react';
import { Moment } from 'moment';

import { StageName } from '../../types';
import {
    CardBody,
    CardBodyIndicatorColor,
    CardBodyIndicatorPosition,
    CardContent,
    CardContentLeftSidebar,
    CardContentRightSidebar,
    CardAuthor,
    CardOrganizationAndBlockSubInfo,
    CardProductSubInfo,
    CardDate,
    DeleteButton,
} from '@common/Card';
import { CardTitle } from './CardTitle';
import { Footer } from './Footer';
import { UnbindActivityPopup } from './UnbindActivityPopup';

interface Props {
    id: number;
    budgetItemId: string;
    productName: string;
    organizationAndBlockName: string;
    authorName: string;
    activityName: string;
    stage: StageName;
    startDate: Moment | null;
    endDate: Moment | null;
    loading?: boolean;
    disabled: boolean;
    canUnbind: boolean;
    isOpenUnbindActivityPopup: boolean;
    onDeleteButtonClick: React.MouseEventHandler<HTMLElement>;
    onUnbindActivityPopupClose: () => void;
    onUnbindActivityPopupFinish: () => void;
}

type ColorStageMap = Record<StageName, CardBodyIndicatorColor>;

const colorStageMap: ColorStageMap = {
    [StageName.PREPARATION]: CardBodyIndicatorColor.YELLOW,
    [StageName.EXECUTION]: CardBodyIndicatorColor.GREEN,
    [StageName.RESULTS]: CardBodyIndicatorColor.BLUE,
    [StageName.COMPLETED]: CardBodyIndicatorColor.GRAY,
};

export const ActivityCard: React.FC<Partial<Props>> = ({
    id,
    budgetItemId,
    productName,
    organizationAndBlockName,
    authorName,
    activityName,
    stage,
    startDate,
    endDate,
    loading = false,
    canUnbind,
    disabled,
    isOpenUnbindActivityPopup,
    onDeleteButtonClick,
    onUnbindActivityPopupClose,
    onUnbindActivityPopupFinish,
}) => {
    const linkTo = `/activity/${id}?from=${encodeURIComponent('/budget/execution')}`;

    return (
        <Link to={linkTo} disable={disabled}>
            <CardBody
                indicator={{
                    position: CardBodyIndicatorPosition.TOP,
                    color: colorStageMap[stage],
                }}
            >
                <CardContent>
                    <CardContentLeftSidebar>
                        <CardOrganizationAndBlockSubInfo text={organizationAndBlockName} loading={loading} />
                        <CardProductSubInfo text={productName} loading={loading} />
                    </CardContentLeftSidebar>
                    <CardContentRightSidebar>
                        <CardDate startDate={startDate} endDate={endDate} loading={loading} />
                        <CardAuthor name={authorName} loading={loading} />
                    </CardContentRightSidebar>
                </CardContent>
                <Footer>
                    <CardTitle loading={loading}>{activityName}</CardTitle>
                    {canUnbind && <DeleteButton qaId="activityDeleteButton" onClick={onDeleteButtonClick} />}
                </Footer>
            </CardBody>

            {isOpenUnbindActivityPopup && (
                <UnbindActivityPopup
                    activityId={id}
                    activityName={activityName}
                    budgetItemId={budgetItemId}
                    onClose={onUnbindActivityPopupClose}
                    onFinish={onUnbindActivityPopupFinish}
                />
            )}
        </Link>
    );
};

interface LinkProps {
    disable: boolean;
    to: string;
}

const Link: React.FC<LinkProps> = ({ disable, to, children }) => {
    return disable ? (
        <span>{children}</span>
    ) : (
        <a target="_blank" href={to} style={{ textDecoration: 'none' }}>
            {children}
        </a>
    );
};
