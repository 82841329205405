// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-ActivityCorrectionCard-_ActivityCorrectionCard__subInfo{display:flex;flex-direction:column;height:100%;padding:8px 22px 9px;background-color:#f6f6f6}.src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-ActivityCorrectionCard-_ActivityCorrectionCard__subInfoTitle{margin-bottom:6px;letter-spacing:0.2px;color:#000000;font-family:'Open Sans';font-size:12px;font-weight:600}.src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-ActivityCorrectionCard-_ActivityCorrectionCard__subInfoContent{height:100%;padding-bottom:16px}.src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-ActivityCorrectionCard-_ActivityCorrectionCard__countChangesWrapper{margin-top:auto}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetCorrections/CorrectionsList/CardTypes/ActivityCorrectionCard/ActivityCorrectionCard.scss"],"names":[],"mappings":"AAAA,0IACI,YAAa,CACb,qBAAsB,CAEtB,WAAY,CACZ,oBAAqB,CAErB,wBAAyB,CAC5B,+IAGG,iBAAkB,CAElB,oBAAqB,CAErB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,eAAgB,CACnB,iJAGG,WAAY,CACZ,mBAAoB,CACvB,sJAGG,eAAgB","sourcesContent":[".subInfo {\n    display: flex;\n    flex-direction: column;\n\n    height: 100%;\n    padding: 8px 22px 9px;\n\n    background-color: #f6f6f6;\n}\n\n.subInfoTitle {\n    margin-bottom: 6px;\n\n    letter-spacing: 0.2px;\n\n    color: #000000;\n\n    font-family: 'Open Sans';\n    font-size: 12px;\n    font-weight: 600;\n}\n\n.subInfoContent {\n    height: 100%;\n    padding-bottom: 16px;\n}\n\n.countChangesWrapper {\n    margin-top: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subInfo": "src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-ActivityCorrectionCard-_ActivityCorrectionCard__subInfo",
	"subInfoTitle": "src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-ActivityCorrectionCard-_ActivityCorrectionCard__subInfoTitle",
	"subInfoContent": "src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-ActivityCorrectionCard-_ActivityCorrectionCard__subInfoContent",
	"countChangesWrapper": "src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-ActivityCorrectionCard-_ActivityCorrectionCard__countChangesWrapper"
};
export default ___CSS_LOADER_EXPORT___;
