import { Month } from '@mrm/budget';

import { ColumnNames } from '../types';
import { ValidationError } from './validationErrors/ValidationError';

type CellValue = string | number | Date | Month;

export class FetchedData {
    private fields: Partial<Record<ColumnNames, CellValue>>;
    private validationErrors: ValidationError[];

    public constructor() {
        this.fields = {};
        this.validationErrors = [];
    }

    public removeFieldByName(fieldName: ColumnNames): void {
        delete this.fields[fieldName];
    }

    public setFieldByName(fieldName: ColumnNames, value: CellValue): void {
        if (value) {
            this.fields[fieldName] = value;
        } else {
            delete this.fields[fieldName];
        }
    }

    public getFieldByName(fieldName: ColumnNames): CellValue {
        return this.fields[fieldName];
    }

    public iterateOverKeys(callback: (key: ColumnNames) => void): void {
        Object.keys(this.fields).forEach((key) => callback(key as ColumnNames));
    }

    public addValidationError(error: ValidationError): void {
        this.validationErrors.push(error);
    }

    public getValidationErrors(): ValidationError[] {
        return this.validationErrors;
    }
}
