import * as React from 'react';
import * as lodash from 'lodash';

import * as style from './TableHeader.scss';

import type { ColumnName, ColumnWidths, TableHeaderCellParams } from '../../types';

import { CustomScrollbar_new as CustomScrollbar, ScrollbarComponent } from 'sber-marketing-ui';
import { StickyWrapper, StickyWrapperPosition } from '../StickyWrapper';
import { Cell } from '../Cell';

interface Props {
    columns: ColumnName[];
    leftFixedColumns: ColumnName[];
    rightFixedColumns: ColumnName[];
    visibleColumnsIndexes: number[];
    columnWidths: ColumnWidths;
    columnsSumWidth: number;
    leftFixedSumWidth: number;
    rightFixedSumWidth: number;
    rootRef: React.RefObject<HTMLDivElement>;
    tableHeaderRef: (component: ScrollbarComponent) => void;
    stickyTableHeaderRef: (component: ScrollbarComponent) => void;
    getColumnHeader: (columnName: string) => TableHeaderCellParams;
    onColumnEdgeMousedown: (columnName: ColumnName, mouseDownX: number) => void;
}

export const TableHeader = ({
    columns,
    leftFixedColumns,
    rightFixedColumns,
    visibleColumnsIndexes,
    columnWidths,
    columnsSumWidth,
    leftFixedSumWidth,
    rightFixedSumWidth,
    rootRef,
    tableHeaderRef,
    stickyTableHeaderRef,
    getColumnHeader,
    onColumnEdgeMousedown,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            {!lodash.isEmpty(leftFixedColumns) && (
                <div className={style.leftFixedColumns} style={{ width: leftFixedSumWidth }}>
                    {renderFixedColumnsCells(leftFixedColumns)}
                </div>
            )}

            <CustomScrollbar scrollbarRef={tableHeaderRef} hideScrollX hideScrollY freezeScrollY>
                <div className={style.headScroller} style={{ width: columnsSumWidth }}>
                    {renderCells()}
                </div>
            </CustomScrollbar>

            {!lodash.isEmpty(rightFixedColumns) && (
                <div className={style.rightFixedColumns} style={{ width: rightFixedSumWidth }}>
                    {renderFixedColumnsCells(rightFixedColumns)}
                </div>
            )}

            <StickyWrapper
                position={StickyWrapperPosition.Top}
                block={rootRef.current}
                customStyle={{ paddingLeft: 80, paddingRight: 440 }}
            >
                <div className={style.tableHeader} style={{ border: '1px solid #e6edf1' }}>
                    {!lodash.isEmpty(leftFixedColumns) && (
                        <div className={style.leftFixedColumns} style={{ width: leftFixedSumWidth }}>
                            {renderFixedColumnsCells(leftFixedColumns)}
                        </div>
                    )}

                    <CustomScrollbar scrollbarRef={stickyTableHeaderRef} hideScrollX hideScrollY freezeScrollY>
                        <div className={style.headScroller} style={{ width: columnsSumWidth }}>
                            {renderCells()}
                        </div>
                    </CustomScrollbar>

                    {!lodash.isEmpty(rightFixedColumns) && (
                        <div className={style.rightFixedColumns} style={{ width: rightFixedSumWidth }}>
                            {renderFixedColumnsCells(rightFixedColumns)}
                        </div>
                    )}
                </div>
            </StickyWrapper>
        </div>
    );

    function renderCells(): JSX.Element[] {
        const columnHeaders: JSX.Element[] = [];
        let sum = 0;

        if (!lodash.isEmpty(visibleColumnsIndexes)) {
            columns.forEach((columnName, columnIndex) => {
                if (visibleColumnsIndexes.includes(columnIndex)) {
                    columnHeaders.push(renderHeaderCell(columnName, sum));
                }

                sum += columnWidths[columnName] + 1;
            });
        }

        return columnHeaders;
    }

    function renderFixedColumnsCells(fixedColumns: ColumnName[]): JSX.Element[] {
        const columnHeaders: JSX.Element[] = [];
        let sum = 0;

        fixedColumns.forEach((columnName) => {
            columnHeaders.push(renderHeaderCell(columnName, sum));

            sum += columnWidths[columnName] + 1;
        });

        return columnHeaders;
    }

    function renderHeaderCell(columnName: ColumnName, x: number): JSX.Element {
        return (
            <React.Fragment key={columnName}>
                <div
                    className={style.columnHeader}
                    key={columnName}
                    style={{ left: x, width: columnWidths[columnName] }}
                >
                    <Cell position={columnName} getCellParams={getColumnHeader} />
                </div>

                {!getColumnHeader(columnName).disableWidthChange && (
                    <div
                        className={style.columnHeaderDragzone}
                        style={{ left: x + columnWidths[columnName] }}
                        onMouseDown={(event) => onColumnEdgeMousedown(columnName, event.clientX)}
                    />
                )}
            </React.Fragment>
        );
    }
};
