// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-_styles__root{padding:16px 12px;display:grid;grid-template-columns:1fr max-content;grid-column-gap:10px;align-items:center;border:1px solid #E3E9E5}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/CreationForm/GeneratedTextEditor/Editor/Footer/styles.scss"],"names":[],"mappings":"AAAA,0IACI,iBAAkB,CAElB,YAAa,CACb,qCAAsC,CACtC,oBAAqB,CACrB,kBAAmB,CAEnB,wBAAyB","sourcesContent":[".root {\n    padding: 16px 12px;\n\n    display: grid;\n    grid-template-columns: 1fr max-content;\n    grid-column-gap: 10px;\n    align-items: center;\n\n    border: 1px solid #E3E9E5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
