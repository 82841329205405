import * as React from 'react';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { FileInput } from '../FileInput';
import { DragNDrop } from './DragNDrop';

interface Props {
    onFileInput: (files: File[]) => void;
}

interface State {
    isActive: boolean;
    isOverRootElement: boolean;
}

export class DragNDropContainer extends React.PureComponent<Props, State> {
    private rootRef: React.RefObject<HTMLDivElement>;
    private fileInputRef: React.RefObject<FileInput>;
    private windowDragCounter: number;
    private rootDragCounter: number;

    constructor(props: Props) {
        super(props);

        this.state = {
            isActive: false,
            isOverRootElement: false,
        };

        this.rootRef = React.createRef<HTMLDivElement>();
        this.fileInputRef = React.createRef<FileInput>();
        this.windowDragCounter = 0;
        this.rootDragCounter = 0;
    }

    public componentDidMount() {
        window.addEventListener('dragenter', this.onWindowDragEnter);
        window.addEventListener('dragover', this.onWindowDragOver);
        window.addEventListener('dragleave', this.onWindowDragLeave);
        window.addEventListener('drop', this.onWindowFileDrop);
    }

    public componentWillUnmount() {
        window.removeEventListener('dragenter', this.onWindowDragEnter);
        window.removeEventListener('dragover', this.onWindowDragOver);
        window.removeEventListener('dragleave', this.onWindowDragLeave);
        window.removeEventListener('drop', this.onWindowFileDrop);
    }

    public render(): JSX.Element {
        return (
            <DragNDrop
                {...this.state}
                rootRef={this.rootRef}
                fileInputRef={this.fileInputRef}
                onFileInputChange={this.props.onFileInput}
                onAttachButtonClick={this.onAttachButtonClick}
            />
        );
    }

    @autobind
    private onAttachButtonClick() {
        this.fileInputRef.current.triggerOpen();
    }

    @autobind
    private onWindowFileDrop(event: DragEvent) {
        event.preventDefault();
        this.setWindowDragoverStatus(false);
        this.windowDragCounter = 0;
    }

    @autobind
    private onRootDrop(event: DragEvent) {
        event.preventDefault();
        this.setWindowDragoverStatus(false);
        this.windowDragCounter = 0;
        this.rootDragCounter = 0;

        const { files } = event.dataTransfer;

        if (!lodash.isEmpty(files)) {
            this.props.onFileInput(Array.from(files));
        }

        this.setState({
            isOverRootElement: false,
        });
    }

    @autobind
    private onWindowDragEnter(event: DragEvent) {
        event.preventDefault();
        this.setWindowDragoverStatus(true);
        this.windowDragCounter += 1;
    }

    @autobind
    private onRootDragEnter(event: DragEvent) {
        event.preventDefault();
        this.setRootDragoverStatus(true);
        this.rootDragCounter += 1;
    }

    @autobind
    private onWindowDragOver(event: DragEvent) {
        event.preventDefault();
        // this.setWindowDragoverStatus(true);
    }

    @autobind
    private onRootDragOver(event: DragEvent) {
        event.preventDefault();
        // this.setWindowDragoverStatus(true);
    }

    @autobind
    private onWindowDragLeave(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.windowDragCounter -= 1;

        if (this.windowDragCounter <= 0) {
            this.setWindowDragoverStatus(false);
        }
    }

    @autobind
    private onRootDragLeave(event: DragEvent) {
        // event.preventDefault();
        // event.stopPropagation();
        this.rootDragCounter -= 1;

        if (this.rootDragCounter <= 0) {
            this.setRootDragoverStatus(false);
        }
    }

    private setWindowDragoverStatus(isActive: boolean) {
        if (isActive !== this.state.isActive) {
            if (!isActive) {
                this.removeRootListeners();
            }

            this.setState(
                {
                    isActive,
                },
                () => {
                    if (isActive) {
                        this.initRootListeners();
                    }
                },
            );
        }
    }

    private setRootDragoverStatus(isOverRootElement: boolean) {
        if (isOverRootElement !== this.state.isOverRootElement) {
            this.setState({
                isOverRootElement,
            });
        }
    }

    private initRootListeners(): void {
        if (this.rootRef.current) {
            this.rootRef.current.addEventListener('drop', this.onRootDrop);
            this.rootRef.current.addEventListener('dragenter', this.onRootDragEnter);
            this.rootRef.current.addEventListener('dragover', this.onRootDragOver);
            this.rootRef.current.addEventListener('dragleave', this.onRootDragLeave);
        }
    }

    private removeRootListeners(): void {
        if (this.rootRef.current) {
            this.rootRef.current.removeEventListener('drop', this.onRootDrop);
            this.rootRef.current.removeEventListener('dragenter', this.onRootDragEnter);
            this.rootRef.current.removeEventListener('dragover', this.onRootDragOver);
            this.rootRef.current.removeEventListener('dragleave', this.onRootDragLeave);
        }
    }
}
