import createCashedSelector from 're-reselect';
import { DepartmentAttributes } from 'sber-marketing-types/frontend';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';

import { DepartmentsState, StoreTypes } from './types';

const getDepartmentsState = (state: StoreState): DepartmentsState => state.departments;

export const getFetchersCount = createCashedSelector(
    getDepartmentsState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: DepartmentsState, store: StoreTypes): number => state.stores[store].fetchersCount,
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getDepartmentById = createCashedSelector(
    getDepartmentsState,
    (state: StoreState, id: string): string => id,
    (state: DepartmentsState, id: string): DepartmentAttributes => state.byIds[id],
)((state: StoreState, id: string): string => id);

export const getDepartments = createCashedSelector(
    getDepartmentsState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: DepartmentsState, store: StoreTypes): DepartmentAttributes[] => {
        const entitiesStore = state.stores[store];

        if (entitiesStore.loadingStatus === LoadingStatus.LOADED) {
            return state.stores[store].ids.map((id) => state.byIds[id]);
        }

        return [];
    },
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getChildrenDepartments = createCashedSelector(
    getDepartmentsState,
    (state: StoreState, id: string): string => id,
    (state: DepartmentsState, id: string): DepartmentAttributes[] => [
        state.byIds[id],
        ...(state.byParentIds[id] || []),
    ],
)((state: StoreState, id: string): string => id);

export const getDepartmentsByOrganizationId = createCashedSelector(
    getDepartmentsState,
    (state: StoreState, id: string): string => id,
    (state: DepartmentsState, id: string): DepartmentAttributes[] => state.byOrganizationIds[id],
)((state: StoreState, id: string): string => id);

export const getDepartmentIds = createCashedSelector(
    getDepartmentsState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: DepartmentsState, store: StoreTypes): string[] => state.stores[store].ids,
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getLoadingStatus = createCashedSelector(
    getDepartmentsState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: DepartmentsState, store: StoreTypes): LoadingStatus => state.stores[store].loadingStatus,
)((state: StoreState, store: StoreTypes): StoreTypes => store);
