// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-dashboard-CardsList-_CardsList__root{padding-top:32px}.src-client-modules-dashboard-CardsList-_CardsList__cardGroup{margin-bottom:20px}.src-client-modules-dashboard-CardsList-_CardsList__cardGroup:last-child{margin-bottom:0}.src-client-modules-dashboard-CardsList-_CardsList__preloader{overflow:hidden}\n", "",{"version":3,"sources":["webpack://./src/client/modules/dashboard/CardsList/CardsList.scss"],"names":[],"mappings":"AAAA,yDACI,gBAAiB,CACpB,8DAGG,kBAAmB,CADvB,yEAIQ,eAAgB,CACnB,8DAID,eAAgB","sourcesContent":[".root {\n    padding-top: 32px;\n}\n\n.cardGroup {\n    margin-bottom: 20px;\n\n    &:last-child {\n        margin-bottom: 0;\n    }\n}\n\n.preloader {\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-dashboard-CardsList-_CardsList__root",
	"cardGroup": "src-client-modules-dashboard-CardsList-_CardsList__cardGroup",
	"preloader": "src-client-modules-dashboard-CardsList-_CardsList__preloader"
};
export default ___CSS_LOADER_EXPORT___;
