import * as React from 'react';
import {
    FilterGroup,
    Preloader,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    Icon,
    IconType,
    CustomScrollbar,
    WithTooltip,
    TooltipAnchor,
} from 'sber-marketing-ui';

import { TargetFilter } from '@store/budgetCorrections/filtersAndExport';

import { DatesFilter } from './DatesFilter';
import { Filter } from './Filter';

import * as style from './Filters.scss';

interface Props {
    loading: boolean;
    isApplyFiltersButtonDisabled: boolean;
    onApplyFiltersButtonClick: () => void;
    onResetFiltersButtonClick: () => void;
}

const FILTER_QA_ID = 'budgetCorrectionsFilter';

export const FiltersTemplate: React.FC<Props> = ({
    loading,
    isApplyFiltersButtonDisabled,
    onApplyFiltersButtonClick,
    onResetFiltersButtonClick,
}) => {
    return (
        <div className={style.root}>
            {loading ? (
                <div className={style.preloaderWrapper}>
                    <Preloader />
                </div>
            ) : (
                <div className={style.content}>
                    <div className={style.filterGroupWrapper}>
                        <CustomScrollbar hideHorizontalScrollbar>
                            <div className={style.filterInnerGroupWrapper}>
                                <DatesFilter />

                                <FilterGroup qaId="budgetCorrectionsFilters">
                                    <Filter
                                        targetFilter={TargetFilter.Status}
                                        qaId={FILTER_QA_ID}
                                        filterTitle="Статус согласования"
                                        isExpanded
                                        preserveAllSelectedState
                                    />

                                    <Filter
                                        targetFilter={TargetFilter.Types}
                                        qaId={FILTER_QA_ID}
                                        filterTitle="Тип корректировки"
                                        isExpanded
                                        preserveAllSelectedState
                                    />

                                    <Filter
                                        targetFilter={TargetFilter.ActivityNames}
                                        qaId={FILTER_QA_ID}
                                        filterTitle="Название активности"
                                        preserveAllSelectedState
                                    />

                                    <Filter
                                        targetFilter={TargetFilter.Authors}
                                        qaId={FILTER_QA_ID}
                                        filterTitle="Автор"
                                        preserveAllSelectedState
                                    />

                                    <Filter
                                        targetFilter={TargetFilter.Approvers}
                                        qaId={FILTER_QA_ID}
                                        filterTitle="Согласующий эксперт"
                                        preserveAllSelectedState
                                    />

                                    <Filter
                                        targetFilter={TargetFilter.Blocks}
                                        qaId={FILTER_QA_ID}
                                        filterTitle="Блок"
                                        preserveAllSelectedState
                                    />

                                    <Filter
                                        cropLongItemNames
                                        targetFilter={TargetFilter.Divisions}
                                        qaId={FILTER_QA_ID}
                                        filterTitle="МВЗ (Дивизион)"
                                        preserveAllSelectedState
                                    />

                                    <Filter
                                        cropLongItemNames
                                        targetFilter={TargetFilter.CostCenter}
                                        qaId={FILTER_QA_ID}
                                        filterTitle="Центр затрат"
                                        preserveAllSelectedState
                                    />

                                    <Filter
                                        targetFilter={TargetFilter.ActivityTypes}
                                        qaId={FILTER_QA_ID}
                                        filterTitle="Тип проекта"
                                        preserveAllSelectedState
                                    />

                                    <Filter
                                        targetFilter={TargetFilter.BudgetItemNames}
                                        qaId={FILTER_QA_ID}
                                        filterTitle="Название проекта"
                                        preserveAllSelectedState
                                    />

                                    <Filter
                                        targetFilter={TargetFilter.Directions}
                                        qaId={FILTER_QA_ID}
                                        filterTitle="Направление"
                                        preserveAllSelectedState
                                    />

                                    <Filter
                                        targetFilter={TargetFilter.Tools}
                                        qaId={FILTER_QA_ID}
                                        filterTitle="Инструмент"
                                        preserveAllSelectedState
                                    />

                                    <Filter
                                        targetFilter={TargetFilter.ExecutionIds}
                                        qaId={FILTER_QA_ID}
                                        filterTitle="ID исполнения"
                                        preserveAllSelectedState
                                    />

                                    <Filter
                                        targetFilter={TargetFilter.Regionalities}
                                        qaId={FILTER_QA_ID}
                                        filterTitle="ЦА/ТБ (Территория)"
                                        preserveAllSelectedState
                                    />

                                    <Filter
                                        targetFilter={TargetFilter.Territories}
                                        qaId={FILTER_QA_ID}
                                        filterTitle="Территория"
                                        preserveAllSelectedState
                                    />
                                </FilterGroup>
                            </div>
                        </CustomScrollbar>
                    </div>

                    <div className={style.controlBar}>
                        <Button
                            theme={ButtonTheme.GhostRounded}
                            disabled={isApplyFiltersButtonDisabled}
                            onClick={onApplyFiltersButtonClick}
                        >
                            Применить фильтры
                        </Button>

                        <WithTooltip content="Сбросить параметры фильтров" anchor={TooltipAnchor.RIGHT}>
                            <div
                                onClick={onResetFiltersButtonClick}
                                className={style.resetFiltersButton}
                                {...{
                                    'qa-id': 'budgetPlanningResetPageFiltersButton',
                                }}
                            >
                                <Icon type={IconType.RESET_USER_CONFIG} />
                            </div>
                        </WithTooltip>
                    </div>
                </div>
            )}
        </div>
    );
};
