import { BudgetItemApi } from '@api';
import { BudgetItem } from '@mrm/budget';

interface Request {
    (params: Params): Promise<BudgetItem[]>;
}

interface Params {
    budgetId: string;
    filter: {
        divisionIds: string[];
    };
}

const requestBudgetItems: Request = async (params) => {
    try {
        return await tryRequestBudgetItems(params);
    } catch (error) {
        console.error(error);
        return [];
    }
};

const tryRequestBudgetItems: Request = async ({ budgetId, filter }) => {
    return await BudgetItemApi.getBudgetItemList({
        budgetId,
        filter: filter?.divisionIds?.length
            ? {
                  dictionary: {
                      division: {
                          id: filter.divisionIds,
                      },
                  },
              }
            : {},
    });
};

export { requestBudgetItems };
