import * as React from 'react';
import { useSelector } from 'react-redux';
import * as lodash from 'lodash';

import * as style from './PlacementList.scss';

import { FieldTypeEnum, ILCreativeFieldSpec } from 'sber-marketing-types/backend';
import type { StoreState } from '@store';

import * as fields from './Fields';
import { getPlacementSpecs } from '@store/autopilot/selectors';

interface Props {}

export const PlacementList = ({}: Props): JSX.Element => {
    const placementSpecs = useSelector((state: StoreState) => getPlacementSpecs(state));

    return (
        <div className={style.root}>
            {placementSpecs.map((placement, placementIndex) => (
                <div className={style.placement} key={`placement ${placementIndex}`}>
                    <div className={style.placementTitle}>
                        Площадка: <span className={style.placementName}>{placement.name}</span>
                    </div>

                    {placement.creativeGroupSpecs.map((creativeGroup, creativeGroupIndex) => (
                        <div className={style.creativeGroup} key={`creativeGroup ${creativeGroupIndex}`}>
                            <div className={style.creativeGroupTitle}>{creativeGroup.title}</div>

                            <div className={style.creativeGroupFields}>
                                {lodash
                                    .flatMap(creativeGroup.creativeSpecs, (item) => item.fieldSpecs)
                                    .map((field, fieldIndex) => (
                                        <CreativeGroupField field={field} key={`field ${fieldIndex}`} />
                                    ))}
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

const FIELD_COMPONENTS_BY_FIELD_TYPE = {
    [FieldTypeEnum.Text]: fields.TextField,
    [FieldTypeEnum.TextArr]: fields.TextArrField,
    [FieldTypeEnum.Href]: fields.HrefField,
    [FieldTypeEnum.HrefArr]: fields.HrefArrField,
    [FieldTypeEnum.Boolean]: fields.BooleanField,
    [FieldTypeEnum.Long]: fields.LongField,
    [FieldTypeEnum.LongId]: fields.LongIdField,
    [FieldTypeEnum.LongIdArr]: fields.LongIdArrField,
    [FieldTypeEnum.StringId]: fields.StringIdField,
    [FieldTypeEnum.StringIdArr]: fields.StringIdArrField,
    [FieldTypeEnum.Image]: fields.ImageField,
    [FieldTypeEnum.ImageArr]: fields.ImageArrField,
    [FieldTypeEnum.Video]: fields.VideoField,
    [FieldTypeEnum.VideoArr]: fields.VideoArrField,
    [FieldTypeEnum.Bundle]: fields.BundleField,
    [FieldTypeEnum.Int]: fields.IntField,
    [FieldTypeEnum.Float]: fields.FloatField,
    [FieldTypeEnum.Money]: fields.MoneyField,
    [FieldTypeEnum.Unknown]: fields.UnknownField,
};

export interface CreativeGroupFieldProps {
    field: ILCreativeFieldSpec;
}

function CreativeGroupField({ field }: CreativeGroupFieldProps) {
    const fieldComponent = FIELD_COMPONENTS_BY_FIELD_TYPE[field.fieldType];

    return <div className={style.field}>{React.createElement(fieldComponent, { field })}</div>;
}
