import * as React from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import {
    Icon,
    IconType,
    WithTooltip,
    TooltipAnchor,
    CustomScrollbar_new as CustomScrollbar,
    StaticSkeleton,
} from 'sber-marketing-ui';

import { FileApi } from '@api';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';
import { FileParams, FileStatus, getTaskEditorState, setFiles, setFileStatus } from '@store/taskEditor2';

import { FileAsset as CommonFileAsset, FileAssetProps as CommonFileAssetProps } from '@common/FileAssetRedesign';
import { FileInput } from '@common/FileInput';
import { DragNDrop } from '@common/DragNDrop';

import * as styles from './AttachedFilesInput.scss';

function useInteractivity() {
    const dispatch = useDispatch();
    const fileInputRef = React.useRef<FileInput>();

    const loginnedUserId = `${useSelector((state: StoreState) => getLoginUser(state).attributes.id)}`;
    const taskId = useSelector((state: StoreState) => getTaskEditorState(state).common.taskId);
    const disabled = useSelector(
        (state: StoreState) => !getTaskEditorState(state).permissions.canChangeExecutorDeadlineAndFiles,
    );
    const allFiles = useSelector((state: StoreState) => getTaskEditorState(state).values.files.entities);
    const files = allFiles.filter((file) => file.status !== FileStatus.Deleted);

    function openFileInput() {
        if (fileInputRef.current) {
            fileInputRef.current.triggerOpen();
        }
    }
    function addFiles(filesToAdd: File[]) {
        const fileAssets: FileParams[] = filesToAdd.map((file) => ({
            asset: FileApi.makeFileAsset({ taskId }, file, loginnedUserId),
            status: FileStatus.ReadyToUpload,
        }));

        dispatch(setFiles([...allFiles, ...fileAssets]));
    }
    function removeFile(fileId: string) {
        dispatch(
            setFileStatus({
                fileId,
                status: FileStatus.Deleted,
            }),
        );
    }

    return {
        taskId,
        fileInputRef,
        openFileInput,
        files,
        addFiles,
        removeFile,
        disabled,
    };
}

export function AttachedFilesInput(): JSX.Element {
    const { taskId, fileInputRef, openFileInput, files, addFiles, removeFile, disabled } = useInteractivity();

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'taskEditorAttachedFiles',
            }}
        >
            <div className={styles.buttonWrapper}>
                <div
                    className={classNames(styles.button, disabled && styles.disabledButton)}
                    onClick={!disabled ? openFileInput : null}
                    {...{
                        'qa-id': 'taskEditorAttachedFilesAttachFileButton',
                    }}
                >
                    <Icon type={IconType.ATTACHMENT_ICON} svgSize={20} className={styles.icon} />
                    Прикрепить файл
                </div>
            </div>

            {!disabled && <FileInput ref={fileInputRef} onChange={addFiles} />}

            {!disabled && <DragNDrop onFileInput={addFiles} />}

            {!disabled && (
                <div className={styles.filesWrapper}>
                    <CustomScrollbar hideScrollX maxHeight={177}>
                        <div className={styles.files}>
                            {files.map((file) => (
                                <FileAsset
                                    taskId={taskId}
                                    key={file.asset.id}
                                    id={file.asset.id}
                                    status={file.status}
                                    removeFile={removeFile}
                                    {...file.asset}
                                />
                            ))}
                        </div>
                    </CustomScrollbar>
                </div>
            )}
        </div>
    );
}

interface FileAssetProps extends CommonFileAssetProps {
    status: FileStatus;
    originName: string;
    removeFile(fileId: string): void;
}

function FileAsset({ id, name, originName, type, status, taskId, removeFile }: FileAssetProps): JSX.Element {
    const [isMouseOver, setIsMouseOver] = React.useState(false);

    const filename = `${originName}.${type}`;

    const content: JSX.Element =
        status === FileStatus.Uploading ? (
            <StaticSkeleton className={styles.skeleton} />
        ) : (
            <div
                className={styles.fileAsset}
                onMouseEnter={() => setIsMouseOver(true)}
                onMouseLeave={() => setIsMouseOver(false)}
                {...{
                    'qa-id': 'taskEditorAttachedFilesSingleFile',
                    'qa-file-name': filename,
                }}
            >
                <CommonFileAsset
                    id={id}
                    name={name}
                    originName={originName}
                    type={type}
                    taskId={taskId}
                    preventDownload={status !== FileStatus.Uploaded}
                />

                <div
                    className={classNames(
                        styles.deleteFileButtonWrapper,
                        isMouseOver && styles.deleteFileButtonWrapperVisible,
                    )}
                >
                    <Icon
                        type={IconType.DELETE_ROUND}
                        svgSize={12}
                        className={styles.deleteFileButton}
                        onClick={() => removeFile(id)}
                    />
                </div>
            </div>
        );

    return (
        <div className={styles.file}>
            {content}

            <WithTooltip content={filename} anchor={TooltipAnchor.BOTTOM}>
                <div className={styles.fileName}>{filename}</div>
            </WithTooltip>
        </div>
    );
}
