import { actionCreatorFactory } from 'typescript-fsa';

import type { CreativeRequestContract } from '@api';

const actionCreator = actionCreatorFactory('PIVOT_TABLE_PAGE');

const loadContracts = actionCreator<Record<'lot1' | 'lot2', CreativeRequestContract[]>>('LOAD_CONTRACTS');
const setSelectedLot1ContractId = actionCreator<string>('SET_SELECTED_LOT1_CONTRACT_ID');
const setSelectedLot2ContractId = actionCreator<string>('SET_SELECTED_LOT2_CONTRACT_ID');
const setTotalTableExpandedStatus = actionCreator<boolean>('SET_TOTAL_TABLE_EXPANDED_STATUS');

export { loadContracts, setSelectedLot1ContractId, setSelectedLot2ContractId, setTotalTableExpandedStatus };
