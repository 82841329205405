// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Header-CloseButton-_CloseButton__root{display:flex;width:32px;height:32px;margin:-6px;cursor:pointer;transition:0.2s ease-out;color:#000000;border-radius:50%;line-height:0;align-items:center;justify-content:center}.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Header-CloseButton-_CloseButton__root:hover{background-color:#f6f6f6}.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Header-CloseButton-_CloseButton__root:active{background-color:#e6e6e6}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/LineModal/Header/CloseButton/CloseButton.scss"],"names":[],"mappings":"AAAA,sGACI,YAAa,CAEb,UAAW,CACX,WAAY,CACZ,WAAY,CAEZ,cAAe,CACf,wBAAyB,CAEzB,aAAc,CACd,iBAAkB,CAElB,aAAc,CAEd,kBAAmB,CACnB,sBAAuB,CAhB3B,4GAmBQ,wBAAyB,CAnBjC,6GAuBQ,wBAAyB","sourcesContent":[".root {\n    display: flex;\n\n    width: 32px;\n    height: 32px;\n    margin: -6px;\n\n    cursor: pointer;\n    transition: 0.2s ease-out;\n\n    color: #000000;\n    border-radius: 50%;\n\n    line-height: 0;\n\n    align-items: center;\n    justify-content: center;\n\n    &:hover {\n        background-color: #f6f6f6;\n    }\n\n    &:active {\n        background-color: #e6e6e6;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Header-CloseButton-_CloseButton__root"
};
export default ___CSS_LOADER_EXPORT___;
