import * as React from 'react';
import * as moment from 'moment';
import classNames from 'classnames';

import * as style from './ContractsSelect.scss';

import type { CreativeRequestContract } from '@api';

import {
    Icon,
    IconType,
    Input_redesign as Input,
    InputTheme_redesign as InputTheme,
    CustomScrollbar_new as CustomScrollbar,
    CustomScrollbarTheme,
    BodyPortal,
    Skeleton,
} from 'sber-marketing-ui';

interface Props {
    loading: boolean;
    selectedContractId: string;
    contracts: CreativeRequestContract[];
    setSelectedContractId: (contractId: string) => void;
}

export const ContractsSelectTemplate = ({
    loading,
    selectedContractId,
    contracts,
    setSelectedContractId,
}: Props): JSX.Element => {
    const [isOpened, setOpenedStatus] = React.useState(false);
    const [searchString, setSearchString] = React.useState('');

    const onOpenerClick = React.useCallback(() => {
        setOpenedStatus(!isOpened);
        setSearchString('');
    }, [isOpened]);

    const onMaskClick = React.useCallback(() => {
        setOpenedStatus(false);
        setSearchString('');
    }, []);

    const onItemClick = React.useCallback((itemId: string) => {
        setSelectedContractId(itemId);
        setOpenedStatus(false);
        setSearchString('');
    }, []);

    const onSearchInput = React.useCallback((value: string) => {
        setSearchString(value);
    }, []);

    const items: SelectItem[] = [
        {
            id: null,
            name: 'Заявки без контракта',
            startDate: null,
            endDate: null,
        },
        ...contracts.map((item) => ({
            id: item.model.id,
            name: item.model.name,
            startDate: item.model.startDate,
            endDate: item.model.endDate,
        })),
    ];

    const filteredItems = !!searchString
        ? items.filter((item) => item.name.toLowerCase().includes(searchString.toLowerCase()))
        : items;

    const openerTitle = makeOpenerTitle(items, selectedContractId);

    return (
        <div className={style.root}>
            {!loading && (
                <>
                    <div className={style.opener} onClick={onOpenerClick}>
                        <Icon className={style.openerIcon} type={IconType.NOTE} svgSize={16} />

                        <div className={style.openerTitle}>
                            <span className={style.gray}>Контракт:</span>
                            &nbsp;
                            <span className={style.black}>{openerTitle}</span>
                        </div>

                        <Icon
                            className={classNames(style.arrowIcon, isOpened && style.rotated)}
                            type={IconType.EXPAND_ICON}
                            svgSize={16}
                        />
                    </div>

                    {isOpened && (
                        <>
                            <div className={style.content}>
                                <div className={style.contentDescription}>
                                    Выберите по какому контракту показывать ЗНК
                                </div>

                                <div className={style.search}>
                                    <SearchInput value={searchString} onChange={onSearchInput} />
                                </div>

                                <div className={style.dropdownItems}>
                                    <CustomScrollbar theme={CustomScrollbarTheme.GrayRounded} maxHeight={329}>
                                        {filteredItems.map((item) => (
                                            <DropdownItem key={item.id} {...item} onClick={onItemClick} />
                                        ))}
                                    </CustomScrollbar>
                                </div>
                            </div>

                            <BodyPortal>
                                <div className={style.mask} onClick={onMaskClick} />
                            </BodyPortal>
                        </>
                    )}
                </>
            )}

            {loading && <Skeleton customStyle={{ height: 32, width: 400, borderRadius: 4 }} />}
        </div>
    );
};

interface SearchInputProps {
    value: string;
    onChange: (value: string) => void;
}

function SearchInput({ value, onChange }: SearchInputProps): JSX.Element {
    return (
        <div className={style.searchInput}>
            <Icon className={style.searchIcon} type={IconType.MAGNIFIER} svgSize={10} />

            <Input value={value} placeholder={'Поиск'} theme={InputTheme.Borderless} onInputChange={onChange} />
        </div>
    );
}

interface SelectItem {
    id: string;
    name: string;
    startDate: Date;
    endDate: Date;
}

interface DropdownItemProps extends SelectItem {
    onClick: (id: string) => void;
}

function DropdownItem({ id, name, startDate, endDate, onClick }: DropdownItemProps): JSX.Element {
    const displayDates = !!startDate && !!endDate;

    return (
        <div className={style.dropdownItem} onClick={() => onClick(id)}>
            <span className={style.black}>{name}</span>

            {displayDates && (
                <>
                    <br />
                    <span className={style.gray}>
                        {formatDate(startDate)} — {formatDate(endDate)}
                    </span>
                </>
            )}
        </div>
    );
}

function formatDate(date: Date): string {
    if (!date) {
        return null;
    }

    return moment(date).format('DD.MM.YY');
}

function makeOpenerTitle(items: SelectItem[], selectedContractId: string): string {
    if (selectedContractId === null) {
        return 'Заявки без контракта';
    }

    if (selectedContractId === undefined) {
        return 'Не выбран';
    }

    const selectedItem = items.find((item) => item.id === selectedContractId);

    if (!selectedItem) {
        return null;
    }

    const { name, startDate, endDate } = selectedItem;

    return `${name} ${formatDate(startDate)} — ${formatDate(endDate)}`;
}
