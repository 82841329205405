import { isNil } from 'lodash';
import {
    UserResponseParams,
    UserCard,
    UserCardListResponse,
    UserRelatedOrganizations,
    GetExpertsParams,
} from 'sber-marketing-types/frontend';

import { axios } from '../lib/axios';

export interface UserListFilters {
    ids?: number[];
    roleIds?: number[];
    embed?: string[];
    departmentIds?: string[];
    organizationIds?: string[];
    sortingOrder?: 'ASC' | 'DESC';
}

interface UserListFiltersInternal {
    ids?: string;
    roleIds?: string;
    embed?: string;
    departmentIds?: string;
    organizationIds?: string;
    sortingOrder?: 'ASC' | 'DESC';
}

export class UserApi {
    public static async getUser(userId: number): Promise<UserResponseParams> {
        const res = await axios.get(`/api/user/${userId}`);
        return res.data;
    }

    public static async getUserRelatedOrganizations(userId: number): Promise<UserRelatedOrganizations> {
        const res = await axios.get<UserRelatedOrganizations>(`/api/user/${userId}/organizations`);
        return res.data;
    }

    public static async getFullUserList(): Promise<UserResponseParams[]> {
        const response = await axios.get<{ users: UserResponseParams[] }>('/api/user');

        return response.data.users;
    }

    public static async getUserList(ids: number[] = []): Promise<UserResponseParams[]> {
        let result: UserResponseParams[] = [];

        if (ids.length) {
            const response = await axios.get('/api/user', {
                params: { ids: ids.join(',') },
            });
            result = response.data.users as UserResponseParams[];
        }

        return result;
    }

    public static async getUserListFiltered(filters: UserListFilters): Promise<UserResponseParams[]> {
        const { ids, departmentIds, organizationIds, sortingOrder, roleIds, embed } = filters;
        const params: UserListFiltersInternal = {};
        if (!isNil(ids) && ids.length > 0) {
            params.ids = ids.join(',');
        }
        if (!isNil(departmentIds) && departmentIds.length > 0) {
            params.departmentIds = departmentIds.join(',');
        }
        if (!isNil(organizationIds) && organizationIds.length > 0) {
            params.organizationIds = organizationIds.join(',');
        }
        if (!isNil(roleIds) && roleIds.length > 0) {
            params.roleIds = roleIds.join(',');
        }
        if (!isNil(embed) && embed.length > 0) {
            params.embed = embed.join(',');
        }
        if (!isNil(sortingOrder)) {
            params.sortingOrder = sortingOrder;
        }
        const {
            data: { users },
        } = await axios.get<{ users: UserResponseParams[] }>('/api/user', { params });

        return users;
    }

    public static async getUserCardListFiltered(filters: UserListFilters): Promise<UserCard[]> {
        const { ids, departmentIds, organizationIds, sortingOrder, roleIds, embed } = filters;
        const params: UserListFiltersInternal = {};
        if (!isNil(ids) && ids.length > 0) {
            params.ids = ids.join(',');
        }
        if (!isNil(departmentIds) && departmentIds.length > 0) {
            params.departmentIds = departmentIds.join(',');
        }
        if (!isNil(organizationIds) && organizationIds.length > 0) {
            params.organizationIds = organizationIds.join(',');
        }
        if (!isNil(roleIds) && roleIds.length > 0) {
            params.roleIds = roleIds.join(',');
        }
        if (!isNil(embed) && embed.length > 0) {
            params.embed = embed.join(',');
        }
        if (!isNil(sortingOrder)) {
            params.sortingOrder = sortingOrder;
        }
        const {
            data: { users },
        } = await axios.get<UserCardListResponse>('/api/user/cards', { params });

        return users;
    }

    public static async getOrganizationExperts(params: GetExpertsParams): Promise<UserResponseParams[]> {
        const response = await axios.get('/api/user/experts', { params });

        return response.data;
    }
}
