import { actionCreatorFactory } from 'typescript-fsa';
import { Dictionary } from 'lodash';
import { FileResponse } from 'sber-marketing-types/frontend';
import { ActivityTasksStatusDTO, ActivityStatusDTO } from '@mrm/dam';

import { LoadingStatus } from '@store/commonTypes';

import { ArchiveContentPopupComponentState } from '../types';

const actionCreator = actionCreatorFactory('ACTIVITY_FILES_DAM_ARCHIVE');

export const resetComponentState = actionCreator('RESET_COMPONENT_STATE');
export const setActivityId = actionCreator<number>('SET_ACTIVITY_ID');
export const setArchive = actionCreator<ActivityTasksStatusDTO>('SET_ARCHIVE');
export const setArchiveStatus = actionCreator<ActivityStatusDTO>('SET_ARCHIVE_STATUS');
export const setPoolingInProgress = actionCreator<boolean>('SET_POOLING_IN_PROGRESS');
export const setTasksFiles = actionCreator<Dictionary<FileResponse[]>>('SET_TASKS_FILES');

export const setArchiveContentPopupState = actionCreator<ArchiveContentPopupComponentState>(
    'SET_ARCHIVE_CONTENT_POPUP_STATE',
);
export const setRenameFilesWithSameName = actionCreator<boolean>('SET_RENAME_FILES_WITH_SAME_NAME');
export const setFilesToExport = actionCreator<string[]>('SET_FILES_TO_EXPORT');

export const setCommonLoadingStatus = actionCreator<LoadingStatus>('SET_COMMON_LOADING_STATUS');
export const setTasksFilesLoadingStatus = actionCreator<LoadingStatus>('SET_TASKS_FILES_LOADING_STATUS');
