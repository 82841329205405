import * as React from 'react';
import autobind from 'autobind-decorator';
import { InputProps_redesign as InputProps } from 'sber-marketing-ui';

import { InputWithSendButton } from './InputWithSendButton';

interface Props extends InputProps {
    onSendButtonClick: () => void;
}

interface State {
    isInputFocused: boolean;
    isWrapperFocused: boolean;
}

export class InputWithSendButtonContainer extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isInputFocused: false,
            isWrapperFocused: false,
        };
    }

    public render(): JSX.Element {
        const { isWrapperFocused } = this.state;

        return (
            <InputWithSendButton
                {...this.props}
                isFocused={isWrapperFocused}
                onInputFocus={this.onInputFocus}
                onInputBlur={this.onInputBlur}
                onClearButtonClick={this.onClearButtonClick}
                onContainerMouseOver={this.setWrapperFocused}
                onContainerMouseOut={this.unsetWrapperFocused}
                onClearButtonMouseOver={this.setWrapperFocused}
                onSendButtonMouseOver={this.setWrapperFocused}
                onClearButtonMouseOut={this.unsetWrapperFocused}
                onSendButtonMouseOut={this.unsetWrapperFocused}
                onSendButtonClick={this.onSendButtonClick}
            />
        );
    }

    @autobind
    private onInputFocus(): void {
        this.setState(
            {
                isInputFocused: true,
                isWrapperFocused: true,
            },
            () => {
                if (this.props.onFocus) {
                    this.props.onFocus();
                }
            },
        );
    }

    @autobind
    private onInputBlur(): void {
        this.setState(
            {
                isInputFocused: false,
            },
            () => {
                if (this.props.onBlur) {
                    this.props.onBlur();
                }
            },
        );
    }

    @autobind
    private setWrapperFocused(): void {
        this.setState({
            isWrapperFocused: true,
        });
    }

    @autobind
    private unsetWrapperFocused(): void {
        this.setState((state) => ({
            isWrapperFocused: state.isInputFocused ? state.isWrapperFocused : false,
        }));
    }

    @autobind
    private onClearButtonClick(): void {
        if (this.props.onInputChange) {
            this.props.onInputChange('');
        }
    }

    @autobind
    private onSendButtonClick(): void {
        if (this.props.value) {
            this.props.onSendButtonClick();
        }
    }
}
