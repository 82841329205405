import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getAutopilot } from '@store/autopilot/selectors';
import { loadTexts, canCreateTextOptions } from '@store/autopilot/generationTextForm';

type OnClickHandler = () => void;

export const CreateButton: React.FC = () => {
    const dispatch = useDispatch();

    const {
        id: autopilotId,
        brief: { product: productId },
    } = useSelector((state: StoreState) => getAutopilot(state));
    const disabled = useSelector((state: StoreState) => !canCreateTextOptions(state));

    const onClick: OnClickHandler = React.useCallback(() => {
        dispatch(loadTexts({ autopilotId, productId }));
    }, []);

    return (
        <Button
            style={{
                height: '48px',
            }}
            theme={ButtonTheme.MainRounded}
            onClick={onClick}
            disabled={disabled}
        >
            Создать тексты
        </Button>
    );
};
