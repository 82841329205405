import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconProps, IconType } from 'sber-marketing-ui';
import { RemoveTaskParticipantModal } from '@modules/task/components';

import * as styles from './DeleteTaskParticipantIcon.scss';

export interface DeleteTaskParticipantIconProps extends Partial<IconProps> {
    taskId: string;
    userId: number;
}

export function DeleteTaskParticipantIcon({
    taskId,
    className,
    onClick,
    userId,
    ...props
}: DeleteTaskParticipantIconProps) {
    const [show, setShow] = React.useState(false);

    const handleClick: React.MouseEventHandler = (e) => {
        e.stopPropagation();
    };

    const handleShow: React.MouseEventHandler<SVGSVGElement> = (e) => {
        handleClick(e);
        setShow(true);
    };

    const handleHide = () => {
        setShow(false);
    };

    return (
        <>
            <Icon
                data-qa-id="DeleteTaskParticipantIcon"
                type={IconType.BIN}
                {...props}
                onClick={handleShow}
                className={classNames(styles.root, className)}
            />
            <RemoveTaskParticipantModal
                onClick={handleClick}
                hide={!show}
                taskId={taskId}
                userId={userId}
                onRemove={handleHide}
                onCancel={handleHide}
            />
        </>
    );
}
