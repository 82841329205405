import * as React from 'react';

import * as style from './BudgetSplitTable.scss';

import type { CellEvent, CellPosition, ColumnName, ColumnWidths, LineId } from './types';

import { TableViewModel } from './TableViewModel';
import { TableView } from './TableView';
import { Preloader } from './Preloader';

interface Props {
    loading: boolean;
    viewModel: TableViewModel;
    columns: ColumnName[];
    leftFixedColumns: ColumnName[];
    rightFixedColumns: ColumnName[];
    lineIds: LineId[];
    columnWidths: ColumnWidths;
    tableRef: (component: TableView) => void;
    onCellEvent: (eventType: CellEvent, position: CellPosition) => void;
}

export const BudgetSplitTableTemplate = ({
    loading,
    viewModel,
    columns,
    leftFixedColumns,
    rightFixedColumns,
    lineIds,
    columnWidths,
    tableRef,
    onCellEvent,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            {!loading && (
                <>
                    <div className={style.table}>
                        <TableView
                            ref={tableRef}
                            viewModel={viewModel}
                            columns={columns}
                            leftFixedColumns={leftFixedColumns}
                            rightFixedColumns={rightFixedColumns}
                            lines={lineIds}
                            columnWidths={columnWidths}
                            onCellEvent={onCellEvent}
                        />
                    </div>
                </>
            )}

            {loading && <Preloader />}
        </div>
    );
};
