import * as React from 'react';
import * as queryString from 'query-string';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { scroller } from 'react-scroll';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { UserConfigType } from 'sber-marketing-types/openid';
import { AccountStatus } from 'sber-marketing-types/openid';
import { FormField } from 'sber-marketing-ui';
import {
    ActivityBudget,
    BudgetItem,
    CreateActivityBudgetForm,
    CreateBudgetItemForm,
    UpdateBudgetItemForm,
    BudgetItemStatus,
    ChangeableBudgetItemStatus,
} from '@mrm/budget';
import { PlainDictionary, DictionaryType } from '@mrm/dictionary';
import { UserResponseParams } from 'sber-marketing-types/frontend';
import { HeaderView } from '@common/Page';
import { PageOptions, NotificationType, NotificationMessage, NotificationActionType } from '@store/common/types';
import { User } from '@store/user/types';
import { PlanningTableUserConfig } from '@store/budgetPlanning';

import { EditPlannedBudgetPage, HeaderTop } from './EditPlannedBudgetPage';
import {
    ActivityBudgetCreateMessage,
    ActivityBudgetUpdateMessage,
    BudgetItemCreateMessage,
    BudgetItemUpdateMessage,
    BudgetItemDeleteMessage,
} from './NotificationMessages';
import { makeFormFields as makeActivityFormFields } from './ActivityBudgetCard/ActivityBudgetForm/ActivityBudgetFormData';
import { makeFormFields as makeBudgetFormFields } from './BudgetList/BudgetForm/BudgetFormData';
import { StoreState } from '@store';
import { setRequestInProgress, updatePageOptions, setNotification } from '@store/common/actions';
import { getPageOptions, isRequestInProgress } from '@store/common/selectors';
import {
    loadEditBudgetPage,
    resetEditBudgetPage,
    updateActivityForm,
    updateActivityFormValidation,
    updateBudgetFormsValidation,
    removeMarkedFilesFromBrief,
    setNameInputFocus,
    setActivityInputFocus,
    addBudgetForm,
} from '@store/plannedBudgetEdit/actions';
import {
    makeActivityBudgetParams,
    makeCreatedBudgetsItems,
    makeUpdatedBudgetsItems,
    makeDeletedBudgetsItemsIds,
    checkActivityFormValidation,
    checkBudgetItemFormsValidation,
    getFirstUnvalidFieldScrollId,
    // makeUpdateValuesForms,
    getUnvalidFieldsNames,
} from '@store/plannedBudgetEdit/selectors';
import { getLoginUser } from '@store/user/selector';
import {
    ActivityBudgetApi,
    DictionaryApi,
    UserApi,
    BudgetItemApi,
    BudgetApi,
    UserConfigApi,
    MultiReferenceDictionaryApi,
} from '@api';
import { PageState, FormData, GroupedDictionaries, SBER_ORGANIZATIONS_IDS } from '@store/plannedBudgetEdit/types';
import { UUID } from '@mrm/budget/common';
import { BriefsSaver } from './BudgetList/modules';

const uuidRegexp = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;

/*tslint:disable:max-file-line-count*/
const DEFAULT_ID = 'new';
const PLAN_TABLE_URL = '/budget/planning';

interface Props extends Partial<MapProps>, Partial<DispatchProps>, RouteComponentProps<RouteParams> {}

interface MapProps {
    isRequestInProgress: boolean;
    activityBudget: ActivityBudget;
    budgetItems: BudgetItem[];
    currentActivityBudget: CreateActivityBudgetForm;
    createdBudgetItems: CreateBudgetItemForm[];
    updatedBudgetItems: UpdateBudgetItemForm[];
    deletedBudgetItemsIds: string[];
    user: User;
    users: UserResponseParams[];
    activityBudgetIsValid: boolean;
    budgetsItemsAreValid: boolean;
    // updateValuesForms: lodash.Dictionary<UpdateValuesForm>;
    activityForm: FormData;
    budgetItemForms: FormData[];
    firstUnvalidFieldScrollId: string;
    headerTitle: string;
    unvalidFieldNames: string[];
    activityAlreadyExists: boolean;
}

interface DispatchProps {
    setRequestInProgress: (requestStatus: boolean) => void;
    setHeaderView?: (view: HeaderView) => void;
    loadEditBudgetPage: (pageData: PageState) => void;
    // updateActivityBudget: (activityBudget: ActivityBudget) => void;
    resetEditBudgetPage: () => void;
    removeMarkedFilesFromBrief: () => void;
    updateActivityForm: (form: FormData) => void;
    updateActivityFormValidation: () => void;
    updateBudgetFormsValidation: () => void;
    updatePageOptions: (options: PageOptions) => void;
    setNotification: (notification: NotificationMessage) => void;
    setNameInputFocus: (isFocused: boolean) => void;
    setActivityInputFocus: (params: { budgetItemId: string; isFocused: boolean }) => void;
    addBudgetForm: (form: FormData) => void;
}

interface State {
    localPreloader: boolean;
    displayPopup: boolean;
}

interface RouteParams {
    activityBudgetId: string;
    action: string;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class EditPlannedBudgetPageContainer extends React.Component<Props, State> {
    private pageIsInCopyMode: boolean;
    private pageIsInCopyFromExecutionMode: boolean;
    private activityBudgetIsNew: boolean;
    private queryBudgetId: string;
    private querySourceBudgetId: string;
    private queryBudgetItemIds: string[];
    private queryDictionariesOrganizationId: string;
    private addLine: boolean;
    private isComponentMounted: boolean;
    private multiReferenceDictionaryApi: MultiReferenceDictionaryApi;

    private briefsSaver: BriefsSaver;

    constructor(props: Props) {
        super(props);

        this.state = {
            localPreloader: false,
            displayPopup: false,
        };

        const {
            match: {
                params: { action, activityBudgetId },
            },
            location: { search },
        } = props;
        this.activityBudgetIsNew = activityBudgetId === DEFAULT_ID;
        this.pageIsInCopyMode = action === 'copy';
        this.pageIsInCopyFromExecutionMode = action === 'copyFromExecution';

        this.isComponentMounted = false;
        const parsedQueryString = queryString.parse(search);

        this.queryBudgetId = parsedQueryString.budgetId as string;
        this.querySourceBudgetId = parsedQueryString.sourceBudgetId as string;
        this.queryBudgetItemIds = this.parseQueryBudgetItemIds(parsedQueryString.budgetItemIds as string | string[]);
        this.queryDictionariesOrganizationId = parsedQueryString.dictionariesOrganizationId as string;
        this.addLine = Boolean(parsedQueryString.addLine);
        this.multiReferenceDictionaryApi = new MultiReferenceDictionaryApi();

        this.briefsSaver = BriefsSaver.getInstance();

        this.props.setRequestInProgress(true);
    }

    public async componentDidMount() {
        this.isComponentMounted = true;
        await this.initPage();

        this.props.updateActivityFormValidation();
        this.props.updateBudgetFormsValidation();
    }

    public componentWillUnmount() {
        this.isComponentMounted = false;
        this.props.resetEditBudgetPage();
    }

    public componentDidUpdate(prevProps: Props): void {
        const headerTitleChanged = this.props.headerTitle !== prevProps.headerTitle;
        const unvalidFieldNamesChanged = this.props.unvalidFieldNames !== prevProps.unvalidFieldNames;

        if (headerTitleChanged || unvalidFieldNamesChanged) {
            this.updateHeader(this.props.headerTitle);
        }
    }

    public render(): JSX.Element {
        const { isRequestInProgress, activityBudgetIsValid, budgetsItemsAreValid } = this.props;
        const showActivitySuggest =
            this.pageIsInCopyMode || this.pageIsInCopyFromExecutionMode || this.activityBudgetIsNew;
        const disableSaveButton = !(activityBudgetIsValid && budgetsItemsAreValid);

        return React.createElement(EditPlannedBudgetPage, {
            budgetId: this.queryBudgetId,
            preloader: isRequestInProgress,
            localPreloader: this.state.localPreloader,
            showActivitySuggest,
            disableSaveButton,
            unvalidFields: this.props.unvalidFieldNames,
            pageIsInCopyFromExecutionMode: this.pageIsInCopyFromExecutionMode,
            multiReferenceDictionaryApi: this.multiReferenceDictionaryApi,
            // displayDeletePopup: this.state.displayPopup,
            onAddButtonClick: this.onAddButtonClick,
            onSaveButtonClick: this.onSaveButtonClick,
            // onDeletePopupConfirm: this.onDeletePopupConfirm,
            // onDeletePopupCancel: this.onDeletePopupCancel
        });
    }

    private parseQueryBudgetItemIds(budgetItemIds: string | string[]): string[] {
        if (!budgetItemIds) {
            return [];
        }

        if (typeof budgetItemIds === 'string') {
            return [budgetItemIds];
        }

        return budgetItemIds;
    }

    @autobind
    protected onAddButtonClick() {
        const { budgetItemForms } = this.props;

        const lastForm: FormData = lodash.last(budgetItemForms);

        const newForm = this.cloneBudgetItemForm(lastForm);

        this.props.addBudgetForm(newForm);

        this.props.updateBudgetFormsValidation();
    }

    @autobind
    protected async onSaveButtonClick() {
        const { activityBudgetIsValid, budgetsItemsAreValid } = this.props;

        if (activityBudgetIsValid && budgetsItemsAreValid) {
            this.setState({ localPreloader: true });

            await this.save();

            this.setState({ localPreloader: false });

            this.openUrlInSameTab(PLAN_TABLE_URL);
        } else {
            this.props.updateActivityFormValidation();
            this.props.updateBudgetFormsValidation();
            this.scrollIntoFirstUnvalidField();
        }
    }

    @autobind
    protected async onSaveAndPublishButtonClick() {
        const { activityBudgetIsValid, budgetsItemsAreValid } = this.props;

        if (activityBudgetIsValid && budgetsItemsAreValid) {
            this.setState({ localPreloader: true });

            await this.save();
            await this.publish();
            // await this.restoreDeletedBudgets();

            this.setState({ localPreloader: false });

            this.openUrlInSameTab(PLAN_TABLE_URL);
        } else {
            this.props.updateActivityFormValidation();
            this.props.updateBudgetFormsValidation();
            this.scrollIntoFirstUnvalidField();
        }
    }

    private async save() {
        this.setState({ localPreloader: true });

        const budgetIdToUse = this.queryBudgetId;

        if (!budgetIdToUse || !budgetIdToUse.match(uuidRegexp)) {
            throw new Error('Invalid budget id');
        }

        if (this.activityBudgetIsNew || this.pageIsInCopyMode || this.pageIsInCopyFromExecutionMode) {
            if (!this.props.activityAlreadyExists) {
                await this.createActivityBudget();
            }
            await this.createBudgetItems();
            await this.saveBrief();
        } else {
            await this.updateActivityBudget();
            await this.updateBudgetItems();
            await this.createBudgetItems();
            await this.deleteBudgetItems();
            await this.saveBrief();
        }

        await this.initActivityBudget();
    }

    // private async createBudget(): Promise<string> {
    //     const id = uuid();
    //     const year = (new Date()).getFullYear() + 1;

    //     await BudgetApi.createBudget({ id, year, status: 'plan' });

    //     return id;
    // }

    private async createActivityBudget() {
        const { currentActivityBudget } = this.props;
        const { id, name, budgetId } = currentActivityBudget;

        await ActivityBudgetApi.createActivityBudget({
            ...currentActivityBudget,
            budgetId: this.queryBudgetId,
        });

        this.notifyAboutActivityCreate(budgetId, id, name);
    }

    private async updateActivityBudget() {
        const checkHaveNameChanges = this.checkNameChanges();

        if (checkHaveNameChanges) {
            const { id, name, budgetId } = this.props.currentActivityBudget;

            await ActivityBudgetApi.updateActivityBudget({ id, name });

            this.notifyAboutActivityUpdate(budgetId, id, name);
        }
    }

    private async createBudgetItems() {
        const { currentActivityBudget, createdBudgetItems } = this.props;

        const haveCreatedBudgetItems = Boolean(createdBudgetItems.length);

        if (haveCreatedBudgetItems) {
            const activityBudgetId = currentActivityBudget.id;

            await Promise.all(
                createdBudgetItems.map(
                    (item) =>
                        new Promise<void>(async (resolve) => {
                            await BudgetItemApi.createBudgetItem({
                                ...item,
                                activityId: activityBudgetId,
                                budgetId: this.queryBudgetId,
                            });

                            const { activity, budgetId, serialNumber, sapComment } = await BudgetItemApi.getBudgetItem(
                                item.id,
                            );

                            this.notifyAboutBudgetItemCreate(activity.id, budgetId, serialNumber, sapComment);

                            resolve();
                        }),
                ),
            );

            this.updateExecutionTableUserConfig(this.queryBudgetId, createdBudgetItems);
        }
    }

    private async updateBudgetItems() {
        const { budgetItems, updatedBudgetItems } = this.props;
        const haveUpdatedBudgetItems = Boolean(updatedBudgetItems.length);

        if (haveUpdatedBudgetItems) {
            await Promise.all(
                updatedBudgetItems.map(
                    (budgetItemForm) =>
                        new Promise<void>(async (resolve) => {
                            const { id, sapComment } = budgetItemForm;

                            await BudgetItemApi.updateBudgetItem(budgetItemForm);

                            const { activity, budgetId, serialNumber } = budgetItems.find((item) => item.id == id);

                            this.notifyAboutBudgetItemUpdate(activity.id, budgetId, serialNumber, sapComment);

                            resolve();
                        }),
                ),
            );
        }
    }

    private async deleteBudgetItems() {
        const { budgetItems, deletedBudgetItemsIds } = this.props;
        const haveDeletedBudgetItemsIds = Boolean(deletedBudgetItemsIds.length);

        if (haveDeletedBudgetItemsIds) {
            const deletedBudgetItems = budgetItems.filter((budgetItem) =>
                lodash.includes(deletedBudgetItemsIds, budgetItem.id),
            );

            const deletedBudgetItemsWithStatusDraft = deletedBudgetItems.filter(
                (deletedBudgetItem) => deletedBudgetItem.status === BudgetItemStatus.Draft,
            );

            const deletedBudgetItemsWithoutStatusDraft = deletedBudgetItems.filter(
                (deletedBudgetItem) => deletedBudgetItem.status !== BudgetItemStatus.Draft,
            );

            await Promise.all(
                deletedBudgetItemsWithStatusDraft.map((BudgetItem) => this.fetchDeleteBudgetItem(BudgetItem.id)),
            );

            await Promise.all(
                deletedBudgetItemsWithoutStatusDraft.map((BudgetItem) =>
                    this.fetchChangeStatus(BudgetItem.id, BudgetItemStatus.Disabled),
                ),
            );

            deletedBudgetItems.forEach((budgetItem) => {
                this.notifyAboutBudgetItemDelete(budgetItem.serialNumber, budgetItem.sapComment);
            });
        }
    }

    private async saveBrief() {
        this.props.removeMarkedFilesFromBrief();
        await this.briefsSaver.saveBriefs();
    }

    private async initActivityBudget() {
        const { currentActivityBudget } = this.props;

        const activityBudget = await ActivityBudgetApi.getActivityBudget(currentActivityBudget.id);
        const budgetItems = await this.fetchBudgetItems({
            activityBudgetIds: activityBudget.id,
        });

        this.props.loadEditBudgetPage({ activityBudget, budgetItems });
    }

    private checkNameChanges(): boolean {
        const { currentActivityBudget, activityBudget } = this.props;
        return currentActivityBudget.name !== activityBudget.name;
    }

    private async fetchBudgetItems(params: { activityBudgetIds: string; budgetId?: string }) {
        return await BudgetItemApi.getBudgetItemList({
            budgetId: params.budgetId || this.queryBudgetId,
            activityIds: [params.activityBudgetIds],
            sort: [
                {
                    field: 'creationTime',
                    order: 'ASC',
                },
            ],
            filter: {
                status: [
                    BudgetItemStatus.Draft,
                    BudgetItemStatus.Published,
                    BudgetItemStatus.Approved,
                    BudgetItemStatus.Rejected,
                    BudgetItemStatus.OnExpertApprovement,
                    BudgetItemStatus.OnExecution,
                ],
            },
        });
    }

    private async fetchChangeStatus(budgetItemId: UUID, status: ChangeableBudgetItemStatus) {
        await BudgetItemApi.changeBudgetItemStatus({
            id: budgetItemId,
            status,
        });
    }

    private async fetchDeleteBudgetItem(budgetItemId: UUID) {
        await BudgetItemApi.deleteBudgetItem({
            id: budgetItemId,
        });
    }

    private async publish() {
        const { budgetItems } = this.props;

        const draftBudgetItems = budgetItems.filter((budgetItem) => budgetItem.status === BudgetItemStatus.Draft);

        await Promise.all(
            draftBudgetItems.map((BudgetItem) => this.fetchChangeStatus(BudgetItem.id, BudgetItemStatus.Published)),
        );
    }

    private async initPage() {
        const { user } = this.props;
        const { activityBudgetId } = this.props.match.params;

        let currentLabel = 'Новая активность';

        const userOrganizationId = user.attributes.organizationId;

        const organizationIds = lodash.includes(SBER_ORGANIZATIONS_IDS, userOrganizationId)
            ? SBER_ORGANIZATIONS_IDS
            : [userOrganizationId];

        const [users, dictionaries] = await Promise.all([
            UserApi.getUserListFiltered({ organizationIds }),
            DictionaryApi.getDictionariesForBudget({
                organizationId: this.queryDictionariesOrganizationId,
                budgetId: this.queryBudgetId,
                userId: user.attributes.id,
                treeview: true,
            }),
        ]);

        const pageData: PageState = {
            availableDictionaries: this.groupDictionaries(dictionaries),
            users: users.filter((user) => user.status === AccountStatus.ACTIVE),
        };

        this.multiReferenceDictionaryApi.init(pageData.availableDictionaries);

        if (!this.activityBudgetIsNew) {
            const activityBudget = await ActivityBudgetApi.getActivityBudget(activityBudgetId);

            let budgetItems = await this.fetchBudgetItems({
                activityBudgetIds: activityBudget.id,
                budgetId: activityBudget.budgetId,
            });
            if (this.queryBudgetItemIds.length) {
                budgetItems = budgetItems.filter((budgetItem) => this.queryBudgetItemIds.includes(budgetItem.id));
            }

            if (this.pageIsInCopyMode) {
                currentLabel = this.queryBudgetItemIds?.length === 1 ? 'Копирование строки' : 'Копирование активности';
            } else {
                currentLabel = activityBudget.name;
            }

            pageData.activityBudget = activityBudget;
            pageData.budgetItems = budgetItems;

            if (budgetItems.length) {
                const usedDictionaryIds = lodash
                    .flatMap(budgetItems, (budgetItem) => lodash.values(budgetItem.dictionary))
                    .map((dictionary) => dictionary.id);

                const usedDictionaries = await DictionaryApi.getDictionaryList({
                    organizationId: this.queryDictionariesOrganizationId,
                    ids: usedDictionaryIds,
                });

                pageData.usedDictionaries = this.groupDictionaries(usedDictionaries);
            }
        }

        if (this.queryBudgetId) {
            const budget = await BudgetApi.getBudget(this.queryBudgetId);
            currentLabel = `${currentLabel} (${budget.year} год)`;

            if (this.pageIsInCopyFromExecutionMode) {
                const sourceBudget = await BudgetApi.getBudget(this.querySourceBudgetId);

                currentLabel = `Копирование строк из исполнения (${sourceBudget.year} год) в планирование (${budget.year} год)`;

                pageData.yearForDataValidation = budget.year;
            }
        }

        this.props.loadEditBudgetPage(pageData);

        this.props.updatePageOptions({
            previousUrl: PLAN_TABLE_URL,
            previousLabel: 'Таблица планирования бюджета',
            currentLabel,
            withoutFooter: true,
        });

        this.initActivityBudgetForm();
        this.initBudgetItemForms();

        this.props.setRequestInProgress(false);
    }

    private initActivityBudgetForm() {
        const { activityBudget } = this.props;

        this.props.updateActivityForm({
            id:
                activityBudget && !this.pageIsInCopyMode && !this.pageIsInCopyFromExecutionMode
                    ? activityBudget.id
                    : uuid(),
            fields: makeActivityFormFields({
                activityBudget,
                onNameInputFocus: this.onNameInputFocus,
                onNameInputBlur: this.onNameInputBlur,
            }),
            collapsed: false,
        });
    }

    private initBudgetItemForms() {
        const { budgetItems } = this.props;
        let budgetItemForms: FormData[];

        const singleBudgetItemToCopy =
            this.pageIsInCopyMode &&
            this.queryBudgetItemIds.length === 1 &&
            budgetItems.find((budgetItem) => budgetItem.id === this.queryBudgetItemIds[0]);
        const makeOnlyOneBudgetForm = singleBudgetItemToCopy || lodash.isEmpty(budgetItems);

        if (makeOnlyOneBudgetForm) {
            const id = uuid();
            const tagsEditorId = uuid();
            const isNew = this.pageIsInCopyMode || this.activityBudgetIsNew;

            budgetItemForms = [
                {
                    id,
                    tagsEditorId,
                    fields: this.makeBudgetFormFields(id, singleBudgetItemToCopy, tagsEditorId, isNew),
                    collapsed: false,
                    isNew,
                    briefId: null,
                },
            ];
        } else {
            budgetItemForms = budgetItems.map((item) => {
                const isNew = this.pageIsInCopyMode || this.pageIsInCopyFromExecutionMode;
                const id = isNew ? uuid() : item.id;
                const tagsEditorId = uuid();

                return {
                    id,
                    tagsEditorId,
                    fields: this.makeBudgetFormFields(id, item, tagsEditorId, isNew),
                    collapsed: budgetItems.length > 1,
                    isNew,
                    briefId: item.briefId || null,
                    sourceId: this.pageIsInCopyFromExecutionMode ? item.id : null,
                };
            });
        }

        if (this.addLine) {
            budgetItemForms.unshift(this.cloneBudgetItemForm(lodash.last(budgetItemForms)));
        }

        this.props.loadEditBudgetPage({
            budgetItemForms,
        });
    }

    private makeBudgetFormFields(
        budgetItemId: string,
        budgetItem: BudgetItem,
        tagsEditorId: string,
        isNew: boolean,
    ): FormField[] {
        const { activityBudget, users } = this.props;

        const pageIsInCopyMode = this.pageIsInCopyMode;
        const pageIsInCopyFromExecutionMode = this.pageIsInCopyFromExecutionMode;
        const canBeTransfered =
            !pageIsInCopyMode &&
            !this.pageIsInCopyFromExecutionMode &&
            !!activityBudget &&
            !!lodash.get(budgetItem, 'actions.canEdit');

        return makeBudgetFormFields({
            tagsEditorId,
            activityBudget,
            budgetItemId,
            budgetItem,
            users,
            canBeTransfered,
            pageIsInCopyMode,
            pageIsInCopyFromExecutionMode,
            onActivityInputFocus: this.onActivityInputFocus,
            onActivityInputBlur: this.onActivityInputBlur,
        });
    }

    private cloneBudgetItemForm(budgetItemForm: FormData): FormData {
        const newForm: FormData = lodash.cloneDeep(budgetItemForm);

        const formId = uuid();

        newForm.id = formId;
        newForm.tagsEditorId = uuid();
        newForm.collapsed = false;
        newForm.isNew = true;

        const newFormFields = this.makeBudgetFormFields(newForm.id, null, newForm.tagsEditorId, true);
        newFormFields.forEach((field, i) => {
            const oldField = newForm.fields[i];

            const updItemsCanUseOldValue = field.items?.some((item) => item.value === oldField.value);

            if (updItemsCanUseOldValue) {
                field.value = oldField.value;
            }
        });
        newForm.fields = newFormFields;

        return newForm;
    }

    private groupDictionaries(dictionaries: PlainDictionary[]): GroupedDictionaries {
        const byId = dictionaries.reduce(
            (acc, dictionary) => ({
                ...acc,
                [dictionary.id]: dictionary,
            }),
            {} as Record<string, PlainDictionary>,
        );

        const byType = lodash.groupBy(dictionaries, (item) => item.type) as Partial<
            Record<DictionaryType, PlainDictionary[]>
        >;

        return { byId, byType };
    }

    private openUrlInSameTab(url: string) {
        this.props.history.push(url);
    }

    private notifyAboutActivityCreate(budgetId: string, activityId: string, activityName: string): void {
        const organizationId = this.queryDictionariesOrganizationId;

        this.setNotificationMessage(
            NotificationType.SUCCESS,
            NotificationActionType.PLAN_EDIT_PAGE_ACTIVITY_CREATE,
            ActivityBudgetCreateMessage({ activityId, budgetId, organizationId, activityName }),
        );
    }

    private notifyAboutActivityUpdate(budgetId: string, activityId: string, activityName: string): void {
        const organizationId = this.queryDictionariesOrganizationId;

        this.setNotificationMessage(
            NotificationType.SUCCESS,
            NotificationActionType.PLAN_EDIT_PAGE_ACTIVITY_UPDATE,
            ActivityBudgetUpdateMessage({ activityId, budgetId, organizationId, activityName }),
        );
    }

    private notifyAboutBudgetItemCreate(
        activityId: string,
        budgetId: string,
        serialNumber: number,
        sapComment: string,
    ): void {
        const organizationId = this.queryDictionariesOrganizationId;

        this.setNotificationMessage(
            NotificationType.SUCCESS,
            NotificationActionType.PLAN_EDIT_PAGE_BUDGET_ITEM_CREATE,
            BudgetItemCreateMessage({ activityId, budgetId, organizationId, serialNumber, sapComment }),
        );
    }

    private notifyAboutBudgetItemUpdate(
        activityId: string,
        budgetId: string,
        serialNumber: number,
        sapComment: string,
    ): void {
        const organizationId = this.queryDictionariesOrganizationId;

        this.setNotificationMessage(
            NotificationType.SUCCESS,
            NotificationActionType.PLAN_EDIT_PAGE_BUDGET_ITEM_UPDATE,
            BudgetItemUpdateMessage({ activityId, budgetId, organizationId, serialNumber, sapComment }),
        );
    }

    private notifyAboutBudgetItemDelete(serialNumber: number, sapComment: string): void {
        this.setNotificationMessage(
            NotificationType.SUCCESS,
            NotificationActionType.PLAN_EDIT_PAGE_BUDGET_ITEM_DELETE,
            BudgetItemDeleteMessage({ serialNumber, sapComment }),
        );
    }

    private setNotificationMessage(
        type: NotificationType,
        typeAction: NotificationActionType,
        comment: JSX.Element | string,
    ): void {
        this.props.setNotification({ type, typeAction, comment });
    }

    private scrollIntoFirstUnvalidField(): void {
        const { firstUnvalidFieldScrollId } = this.props;

        if (firstUnvalidFieldScrollId) {
            const options = {
                offset: -90,
                smooth: 'easeOutQuad',
                duration: 500,
                containerId: 'pageContent',
            };

            scroller.scrollTo(firstUnvalidFieldScrollId, options);
        }
    }

    @autobind
    private onNameInputFocus(): void {
        this.props.setNameInputFocus(true);
    }

    @autobind
    private onNameInputBlur(): void {
        setTimeout(() => {
            if (this.isComponentMounted) {
                this.props.setNameInputFocus(false);
            }
        }, 150);
    }

    @autobind
    private onActivityInputFocus(budgetItemId: string): void {
        this.props.setActivityInputFocus({ budgetItemId, isFocused: true });
    }

    @autobind
    private onActivityInputBlur(budgetItemId: string): void {
        setTimeout(() => {
            if (this.isComponentMounted) {
                this.props.setActivityInputFocus({ budgetItemId, isFocused: false });
            }
        }, 150);
    }

    private updateHeader(title: string) {
        const { unvalidFieldNames, activityBudgetIsValid, budgetsItemsAreValid } = this.props;

        const disableSaveButton = !(activityBudgetIsValid && budgetsItemsAreValid);

        this.props.setHeaderView({
            firstLine: HeaderTop({
                title,
                unvalidFields: unvalidFieldNames,
                disableSaveButton,
                onSaveAndPublishButtonClick: this.onSaveAndPublishButtonClick,
            }),
        });
    }

    private async updateExecutionTableUserConfig(budgetId: string, budgetItems: CreateBudgetItemForm[]): Promise<void> {
        const userConfig = (await UserConfigApi.getPageConfig(
            UserConfigType.BudgetPlanning,
        )) as PlanningTableUserConfig;
        const budgetUserConfig = userConfig[budgetId];

        if (!budgetUserConfig) {
            console.warn(`Missing userConfig for budget width id ${budgetId}`);
        } else {
            budgetUserConfig.budgetItemsToIgnoreFilters = lodash.uniq([
                ...budgetItems.map((budgetItem) => budgetItem.id),
                ...budgetUserConfig.budgetItemsToIgnoreFilters,
            ]);

            const updUserConfig = {
                ...userConfig,
                [budgetId]: budgetUserConfig,
            };

            await UserConfigApi.savePageConfig(UserConfigType.BudgetPlanning, updUserConfig);
        }
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { activityBudget, budgetItems, users, activityForm, budgetItemForms, activityAlreadyExists } =
        state.plannedBudgetEditPage;
    const { currentLabel } = getPageOptions(state);

    return {
        isRequestInProgress: isRequestInProgress(state),
        activityBudget,
        budgetItems,
        currentActivityBudget: makeActivityBudgetParams(state),
        createdBudgetItems: makeCreatedBudgetsItems(state),
        updatedBudgetItems: makeUpdatedBudgetsItems(state),
        deletedBudgetItemsIds: makeDeletedBudgetsItemsIds(state),
        user: getLoginUser(state),
        users,
        activityBudgetIsValid: checkActivityFormValidation(state),
        budgetsItemsAreValid: checkBudgetItemFormsValidation(state),
        activityForm,
        budgetItemForms,
        firstUnvalidFieldScrollId: getFirstUnvalidFieldScrollId(state),
        headerTitle: currentLabel,
        unvalidFieldNames: getUnvalidFieldsNames(state),
        activityAlreadyExists,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setRequestInProgress,
            loadEditBudgetPage,
            resetEditBudgetPage,
            removeMarkedFilesFromBrief,
            updateActivityForm,
            updateActivityFormValidation,
            updateBudgetFormsValidation,
            updatePageOptions,
            setNotification,
            setNameInputFocus,
            setActivityInputFocus,
            addBudgetForm,
        },
        dispatch,
    );
}
