import * as React from 'react';
import autobind from 'autobind-decorator';

import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';

import { FavoriteIcon } from './FavoriteIcon';
import { ActivityApi } from '@api';

interface Props extends Partial<DispatchProps> {
    qaId?: string;
    activity: Activity;
}

interface DispatchProps {
    setRequestInProgress: (isRequestInProgress: boolean) => void;
}

interface State {
    isFavorite: boolean;
}

export class FavoriteIconContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isFavorite: this.props.activity.isFavorite,
        };
    }

    public render(): JSX.Element {
        const { qaId } = this.props;
        const { isFavorite } = this.state;

        return React.createElement(FavoriteIcon, {
            qaId,
            isFavorite,
            onClick: this.onClick,
        });
    }

    @autobind
    protected async onClick(): Promise<void> {
        const { activity } = this.props;

        await ActivityApi.pinActivity(activity.id);

        this.setState({
            isFavorite: !this.state.isFavorite,
        });
    }
}
