import * as React from 'react';
import classNames from 'classnames';

import type { Column, ColumnType } from '../types';
import * as styles from './styles.scss';

type Props = {
    columns: Column[];
};

const columnMap: Record<ColumnType, string> = {
    money: classNames(styles.cell, styles.moneyCell),
    text: styles.cell,
};

export const Table: React.FC<Props> = ({ columns }) => {
    return (
        <table className={styles.table}>
            <thead className={styles.header}>
                <tr>
                    {columns.map(({ title, name }) => (
                        <th key={name} className={classNames(styles.cell, styles.headerCell)}>
                            {title}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                <tr className={styles.line}>
                    {columns.map(({ value, name, type }) => (
                        <td key={name} className={columnMap[type] || null}>
                            {value}
                        </td>
                    ))}
                </tr>
            </tbody>
        </table>
    );
};
