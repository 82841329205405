import * as React from 'react';

import { BudgetItem } from '@store/dashboardPage';
import { Month } from '@mrm/budget/common';
import { PlanFundsTransferCorrection } from '../types';
import { getMonthName, orderOfMonth } from '../utils';

import { TableWithPlanTransferCorrection, FundCell, FundRow } from './TableWithPlanTransferCorrection';

interface Props {
    budgetItem: BudgetItem;
    correction: PlanFundsTransferCorrection;
}

export class TableWithPlanTransferCorrectionContainer extends React.Component<Props> {
    public render() {
        return React.createElement(TableWithPlanTransferCorrection, {
            fundsRows: this.buildFoundsRows(),
        });
    }

    private buildFoundsRows(): FundRow[] {
        const { reservedFunds, factFunds } = this.props.budgetItem;

        return orderOfMonth.map((month) => ({
            name: getMonthName(month),
            planned: this.buildPlanFundCell(month),
            reserve: reservedFunds[month] || 0,
            fact: factFunds[month] || 0,
        }));
    }

    private buildPlanFundCell(month: Month): FundCell {
        const { plannedFunds } = this.props.budgetItem;

        return {
            currentValue: plannedFunds[month] || 0,
            correctionValue: this.getPlanCorrectionValueByMonth(month),
        };
    }

    private getPlanCorrectionValueByMonth(month: Month): number {
        const { budgetItem, correction } = this.props;
        const {
            params: { acceptorMonth, acceptorId, donorMonth, donorId, value },
        } = correction;

        if (budgetItem.id === acceptorId && acceptorMonth === month) {
            return value;
        }

        if (budgetItem.id === donorId && donorMonth === month) {
            return -value;
        }

        return 0;
    }
}
