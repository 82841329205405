import { Success, Failure } from 'typescript-fsa';

import { TaskPageState, FetchTelegramError, UnplugTelegramSuccess, UnplugTelegramPayload } from '../types';

export const unplugTelegramStartedReducer = (state: TaskPageState): TaskPageState => ({
    ...state,
    telegram: {
        ...state.telegram,
        isUserCreatedChat: false,
        linkToAuthorization: '',
        loading: true,
    },
});

export const unplugTelegramErrorReducer = (
    state: TaskPageState,
    { error: { message } }: Failure<UnplugTelegramPayload, FetchTelegramError>,
): TaskPageState => ({
    ...state,
    telegram: {
        ...state.telegram,
        isUserCreatedChat: true,
        loading: false,
        fetchError: {
            message,
        },
    },
});

export const unplugTelegramDoneReducer = (
    state: TaskPageState,
    _: Success<UnplugTelegramPayload, UnplugTelegramSuccess>,
): TaskPageState => {
    return {
        ...state,
        telegram: {
            ...state.telegram,
            isUserCreatedChat: false,
            linkToAuthorization: '',
            loading: false,
        },
    };
};
