import * as React from 'react';
import autobind from 'autobind-decorator';
// import * as lodash from 'lodash';

import { LinkInputTemplate } from './LinkInputTemplate';

interface Props {
    value: string;
    placeholder: string;
    readOnly?: boolean;
    onValueChange: (value: string) => void;
}

interface State {
    inEditMode: boolean;
}

export class LinkInputBehaviour extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            inEditMode: !props.value,
        };
    }

    public render(): JSX.Element {
        const { value, placeholder, readOnly } = this.props;

        return React.createElement(LinkInputTemplate, {
            link: this.formatLink(value),
            value,
            placeholder,
            inEditMode: this.state.inEditMode,
            readOnly: readOnly || false,
            onInputChange: this.onInputChange,
            onInputBlur: this.onInputBlur,
            onClearButtonClick: this.onClearButtonClick,
        });
    }

    @autobind
    protected onInputChange(value: string): void {
        this.props.onValueChange(value);
    }

    @autobind
    protected onInputBlur(): void {
        if (this.props.value !== '') {
            this.setState({
                inEditMode: false,
            });
        }
    }

    @autobind
    protected onClearButtonClick(): void {
        this.props.onValueChange('');

        this.setState({
            inEditMode: true,
        });
    }

    private formatLink(value: string): string {
        const PROTOCOL_REGEX = /^(https?:\/\/)/i;

        const urlHasProtocol = PROTOCOL_REGEX.test(value);

        return urlHasProtocol ? value : `https://${value}`;
    }
}
