import { actionCreatorFactory } from 'typescript-fsa';

import { LoadingStatus } from '@store/commonTypes';

const actionCreator = actionCreatorFactory('PROFILE_MENU_NOTIFICATIONS');

export const setLoadingStatus = actionCreator<LoadingStatus>('SET_LOADING_STATUS');
export const setRequestInProgress = actionCreator<boolean>('SET_REQUEST_IN_PROGRESS');

export const setNotifications = actionCreator<any>('SET_NOTIFICATIONS');
