import * as React from 'react';
import * as moment from 'moment';
import { isEqual, isNil, debounce, compact, isEmpty } from 'lodash';
import { TaskPageSortBy } from 'sber-marketing-types/frontend';
import { parse as parseUrl } from 'url';
import autobind from 'autobind-decorator';

import { UserConfigType } from 'sber-marketing-types/openid';
import { TasksListUserConfig } from '@store/tasksList/types';
import { LoadingStatus } from '@store/commonTypes';
import { TasksListType } from '@store/tasksList';
// import { TasksFilter } from '@store/userConfig/dashboard';

import { PageScrollWatcher } from '../PageScrollWatcher';

import { TasksList } from './TasksList';
import { TasksListContainerProps, GroupedTask, TaskCardParams } from './types';
import { UserConfigApi } from '@api';

const TIMEOUT_FETCH = 800;

interface State {
    groupedTasks: GroupedTask[];
    tasksEnabled: boolean;
}

export class TasksListContainer extends React.Component<TasksListContainerProps, State> {
    private pageScrollWatcher: PageScrollWatcher = new PageScrollWatcher();
    private delayedFetchMoreTasks: () => void;

    constructor(props: TasksListContainerProps) {
        super(props);

        this.state = {
            groupedTasks: [],
            tasksEnabled: true,
        };

        this.delayedFetchMoreTasks = debounce(props.fetchMoreTasks, TIMEOUT_FETCH);
    }

    public async componentDidMount() {
        this.props.loadWorkTypes();
        this.props.setType(this.props.type);

        if (this.props.type === TasksListType.ACTIVITY_TASKS) {
            if (isNil(this.props.activityId) || isNil(this.props.briefStatus)) {
                throw new Error(
                    'Properties "activityId" and "briefStatus" are required when property "type" ' +
                        `equals "${TasksListType.ACTIVITY_TASKS}" ("TasksListType.ACTIVITY_TASKS")`,
                );
            }
            this.props.setActivityId(this.props.activityId);
        }
        this.pageScrollWatcher.reInit();
        this.pageScrollWatcher.on(PageScrollWatcher.SCROLLED_DOWN_EVENT, this.onScrolledDown);

        const userConfig = (await UserConfigApi.getPageConfig(UserConfigType.TasksList)) as TasksListUserConfig;
        if (!isEmpty(userConfig)) {
            const { cardType } = userConfig;
            this.props.setCardType(cardType);
        }

        const isDashboardUserConfigLoaded = this.props.dashboardUserConfigLoadingStatus === LoadingStatus.LOADED;
        const needTaskFetching =
            this.props.loadingStatus === LoadingStatus.NOT_LOADED &&
            isDashboardUserConfigLoaded &&
            !this.props.delayLoading;
        if (needTaskFetching) {
            this.props.fetchMoreTasks();
        }
        this.props.loadDepartments();
    }

    public async componentDidUpdate(prevProps: TasksListContainerProps) {
        const prevFilters = prevProps.filters;
        const curFilters = this.props.filters;
        const { clearTasksList, fetchMoreTasks, dashboardUserConfigLoadingStatus, type, tasks, loadingStatus } =
            this.props;
        const isTypeChanged: boolean = type !== prevProps.type;
        const isFiltersChanged = !isEqual(curFilters, prevFilters);
        const isDashboardUserConfigLoaded = dashboardUserConfigLoadingStatus === LoadingStatus.LOADED;
        const isCardsNotLoaded = loadingStatus === LoadingStatus.NOT_LOADED;

        if (tasks !== prevProps.tasks) {
            this.setState({
                groupedTasks: this.groupTasks(this.props.tasks),
                tasksEnabled: true,
            });
        }

        if (isDashboardUserConfigLoaded && isCardsNotLoaded && !this.props.delayLoading) {
            fetchMoreTasks();
        }

        if ((isTypeChanged || isFiltersChanged) && isDashboardUserConfigLoaded && !isCardsNotLoaded) {
            await this.setState({ tasksEnabled: false });
            await clearTasksList();
            await this.delayedFetchMoreTasks();
        }
    }

    public componentWillUnmount() {
        this.props.resetMyTasks();
        this.pageScrollWatcher.dispose();
    }

    // public shouldComponentUpdate(nextProps: TasksListContainerProps, nextState: TasksListContainerState): boolean {
    //     const propsHaveChanged = nextProps.loadingStatus !== this.props.loadingStatus ||
    //         nextProps.error !== this.props.error ||
    //         nextProps.type !== this.props.type ||
    //         nextProps.briefStatus !== this.props.briefStatus ||
    //         nextProps.activityId !== this.props.activityId ||
    //         nextProps.activityExpired !== this.props.activityExpired;
    //     const stateHasChanged = nextState.tasksEnabled !== this.state.tasksEnabled ||
    //         nextState.groupedTasks !== this.state.groupedTasks;

    //     return propsHaveChanged || stateHasChanged;
    // }

    public render(): JSX.Element {
        const {
            loadingStatus,
            error,
            type,
            briefStatus,
            activityId,
            activityExpired,
            taskIdWithSidebar,
            onCreateTaskButtonClick,
            onTaskUpdate,
            reloadStagesData,
            openTaskSidebar,
        } = this.props;

        return React.createElement(TasksList, {
            tasks: this.state.groupedTasks.map((task) => ({
                ...task,
                workTypeName: this.getWorkTypeNameByWorkTypeId(task.workTypeId),
                enabled: this.state.tasksEnabled,
            })),
            error,
            type,
            briefStatus,
            activityId,
            activityExpired,
            onCreateTaskButtonClick,
            from: this.getFrom(),
            isFilterModeOn: this.isFilterModeOn(),
            isLoading: loadingStatus === LoadingStatus.LOADING || loadingStatus === LoadingStatus.NOT_LOADED,
            hasError: loadingStatus === LoadingStatus.ERROR,
            onTaskDelete: this.onTaskDelete,
            delayLoading: this.props.delayLoading,
            activityStages: this.props.activityStages,
            taskIdWithSidebar,
            onTaskUpdate,
            reloadStagesData,
            openTaskSidebar,
        });
    }

    @autobind
    private async onTaskDelete(taskId: string) {
        const { clearTasksList, fetchMoreTasks, fillActivityTasks } = this.props;

        this.setState(() => ({
            tasksEnabled: false,
        }));

        this.props.onBeforeTaskDeleted(taskId);

        await clearTasksList();
        await fetchMoreTasks();
        this.props.reloadTaskFilters();
        if (fillActivityTasks) {
            fillActivityTasks();
        }
    }

    @autobind
    private onScrolledDown() {
        const { canBeLoadedMore, loadingStatus, fetchMoreTasks, dashboardUserConfigLoadingStatus } = this.props;
        const isDashboardUserConfigLoaded = dashboardUserConfigLoadingStatus === LoadingStatus.LOADED;
        if (canBeLoadedMore && loadingStatus === LoadingStatus.LOADED && isDashboardUserConfigLoaded) {
            fetchMoreTasks();
        }
    }

    private getWorkTypeNameByWorkTypeId(workTypeId: string): string {
        const { tasksWorkTypes } = this.props;
        const workType = compact(tasksWorkTypes).find((workType) => workType.id === workTypeId);
        return workType ? workType.name : '';
    }

    private isFilterModeOn(): boolean {
        const { status, workType, participation } = this.props.filters;

        return !!status || !!participation || !!workType.length;
    }

    private groupTasks(tasks: TaskCardParams[]): GroupedTask[] {
        switch (this.props.filters.sorting) {
            case TaskPageSortBy.DEADLINE:
                return tasks.map((task) => ({ ...task, groupName: getTaskDeadline(task) }));
            case TaskPageSortBy.UPDATING_DATE:
                return tasks.map((task) => ({ ...task, groupName: getTaskUpdateDate(task) }));
            case TaskPageSortBy.CREATION_DATE:
                return tasks.map((task) => ({ ...task, groupName: getTaskCreateDate(task) }));
            case TaskPageSortBy.WORK_TYPE:
                return tasks.map((task) => {
                    const workType = this.props.tasksWorkTypes.find((workType) => workType.id === task.workTypeId);
                    const groupName = workType ? workType.name : 'Без вида работ';

                    return {
                        ...task,
                        groupName,
                    };
                });
            case TaskPageSortBy.UNRESOLVED_FIRST:
            default:
                return tasks.map((task) => ({ ...task, groupName: getTaskSeenStatus(task) }));
        }
    }

    private getFrom(): string {
        const { pathname, search } = parseUrl(window.location.href);
        return isNil(search) ? pathname : `${pathname}${search}`;
    }
}

function getTaskSeenStatus({ readingTime, updateTime, change }: TaskCardParams): string {
    const isTaskSeen =
        !isNil(readingTime) && change
            ? moment(readingTime).isAfter(moment(change.updateTime))
            : moment(readingTime).isAfter(moment(updateTime));

    return isTaskSeen ? 'Просмотренные' : 'Непросмотренные';
}

function getTaskDeadline({ deadline }: TaskCardParams) {
    return moment(deadline).format('D MMMM');
}

function getTaskUpdateDate({ updateTime }: TaskCardParams) {
    return moment(updateTime).format('D MMMM');
}

function getTaskCreateDate({ createTime }: TaskCardParams) {
    return moment(createTime).format('D MMMM');
}

// const taskFiltersFieldsToSkip = [ 'activityStage' ];

// function checkFiltersEquality(filterA: TasksFilter, filterB: TasksFilter): boolean {
//     const filterAToCheck = omit(filterA, taskFiltersFieldsToSkip);
//     const filterBToCheck = omit(filterB, taskFiltersFieldsToSkip);
//
//     return isEqual(filterAToCheck, filterBToCheck);
// }
