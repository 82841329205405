import * as React from 'react';

import { CancelButton } from './CancelButton';
import { BindButton } from './BindButton';

import * as style from './Footer.scss';

export interface Props {
    isDisabledBindButton: boolean;
    onCancelButtonClick(): void;
    onBindButtonClick(): void;
}

export const Footer: React.FC<Props> = ({ onCancelButtonClick, onBindButtonClick, isDisabledBindButton }) => {
    return (
        <div className={style.root}>
            <CancelButton onClick={onCancelButtonClick} />
            <BindButton disabled={isDisabledBindButton} onClick={onBindButtonClick} />
        </div>
    );
};
