import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { DepartmentApi } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';

import * as actions from './actions';
import { LoadDepartmentsParams } from './types';
import { getLoadingStatus, getDepartmentById, getFetchersCount } from './selectors';

export const loadDepartments = bindThunkAction<StoreState, LoadDepartmentsParams, void, Error>(
    actions.loadDepartmentsAsync,
    async (params, dispatch, getState) => {
        const { store } = params;
        let { ids } = params;

        const setStatus: (status: LoadingStatus) => void = (status) =>
            dispatch(
                actions.setLoadingStatus({
                    store,
                    status,
                }),
            );

        const state = getState();
        const loadingStatus = getLoadingStatus(state, store);

        try {
            let departments;

            if (ids) {
                const notLoadedDepartmentIds = ids.filter((id) => !getDepartmentById(state, id));

                if (notLoadedDepartmentIds.length) {
                    dispatch(actions.incFetchersCount(store));
                    setStatus(LoadingStatus.LOADING);
                    departments = await DepartmentApi.getList({
                        ids: notLoadedDepartmentIds,
                    });
                }
            } else {
                if (loadingStatus !== LoadingStatus.LOADED) {
                    dispatch(actions.incFetchersCount(store));
                    setStatus(LoadingStatus.LOADING);
                    departments = await DepartmentApi.getList();

                    ids = departments.map((department) => department.id);
                }
            }

            if (departments) {
                dispatch(actions.decFetchersCount(store));
                dispatch(actions.loadEntities(departments));
            }
            if (ids) {
                dispatch(
                    actions.setStoreIds({
                        store,
                        ids,
                    }),
                );
            }

            const fetchersCount = getFetchersCount(getState(), store);
            if (!fetchersCount) {
                setStatus(LoadingStatus.LOADED);
            }
        } catch (e) {
            setStatus(LoadingStatus.ERROR);
        }
    },
);
