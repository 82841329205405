import * as React from 'react';

import { ColumnName, CustomColumnColor } from '@store/budgetExecution';

import { CellBackgroundColor } from '../../LayerManager';

import { TextCell } from './TextCell';

interface Props {
    columnName: ColumnName;
    title: string;
    tooltip?: string;
    bgColor?: CellBackgroundColor | CustomColumnColor;
    valueTypeIsCurrency?: boolean;
    disabled?: boolean;
    onCellCopy: (event: React.ClipboardEvent<HTMLDivElement>) => void;
}

export class TextCellContainer extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        return React.createElement(TextCell, {
            columnName: this.props.columnName,
            title: this.props.title,
            tooltip: this.props.tooltip,
            bgColor: this.props.bgColor,
            valueTypeIsCurrency: this.props.valueTypeIsCurrency,
            disabled: this.props.disabled,
            onCopy: this.props.onCellCopy,
        });
    }
}
