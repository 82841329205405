import * as React from 'react';

import {
    convertValueFromExternalToVisibleFormat,
    checkValueForValidity,
    convertValueFromVisibleToExternalFormat,
    convertValueFromEditableToVisibleFormatWithCopecks,
    convertValueFromEditableToVisibleFormatWithoutCopecks,
    convertValueFromVisibleToEditableFormat,
    convertValueFromEditableToVisibleFormat,
} from './utils';

import * as styles from './styles.scss';

interface Props {
    value: number;
    disabled: boolean;
    onChange: (value: number) => void;
}

interface DefineCursor {
    (params: DefineCursorParams): void;
}

interface DefineCursorParams {
    beforeValue: string;
    afterValue: string;
}

interface UpdateCursor {
    (): void;
}

export const ProtocolLimitInput: React.FC<Props> = ({
    value: externalValue = 0,
    disabled = false,
    onChange: externalOnChange,
}) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const cursorPositionRef = React.useRef<number>(0);

    const [value, setValue] = React.useState('0');

    React.useEffect(() => {
        setValue(convertValueFromExternalToVisibleFormat(externalValue));
    }, [externalValue]);

    React.useEffect(() => {
        updateCursor();
    }, [value]);

    const defineCursor: DefineCursor = React.useCallback(
        ({ beforeValue, afterValue }) => {
            const lengthDelta = afterValue.length - beforeValue.length;

            cursorPositionRef.current =
                lengthDelta >= 0
                    ? lengthDelta + inputRef.current.selectionStart - 1
                    : lengthDelta + inputRef.current.selectionStart + 1;
        },
        [cursorPositionRef.current, inputRef.current],
    );

    const updateCursor: UpdateCursor = React.useCallback(() => {
        inputRef.current.selectionEnd = cursorPositionRef.current;
        inputRef.current.selectionStart = cursorPositionRef.current;
    }, [cursorPositionRef.current, inputRef.current]);

    const onChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
        (event) => {
            const editableValueFormat = convertValueFromVisibleToEditableFormat(event.target.value);

            if (checkValueForValidity(editableValueFormat)) {
                const updatedValue = convertValueFromEditableToVisibleFormat(editableValueFormat);
                defineCursor({ beforeValue: value, afterValue: updatedValue });
                setValue(() => updatedValue);
            }
        },
        [value, setValue, defineCursor],
    );

    const onBlur: React.FocusEventHandler<HTMLInputElement> = React.useCallback(
        (event) => {
            const editableValueFormat = convertValueFromVisibleToEditableFormat(event.target.value);
            const visibleValueFormatWithCopecks =
                convertValueFromEditableToVisibleFormatWithCopecks(editableValueFormat);

            setValue(visibleValueFormatWithCopecks);
            externalOnChange(convertValueFromVisibleToExternalFormat(visibleValueFormatWithCopecks));
        },
        [externalOnChange, setValue],
    );

    const onFocus: React.FocusEventHandler<HTMLInputElement> = React.useCallback(
        (event) => {
            const editableValueFormat = convertValueFromVisibleToEditableFormat(event.target.value);
            const visibleValueFormatWithoutCopecks =
                convertValueFromEditableToVisibleFormatWithoutCopecks(editableValueFormat);

            setValue(visibleValueFormatWithoutCopecks);
        },
        [externalOnChange, setValue],
    );

    return (
        <label className={styles.root}>
            <div className={styles.label}>Лимит по протоколу*</div>

            <input
                className={styles.input}
                ref={inputRef}
                title={'Лимит по протоколу'}
                value={value}
                disabled={disabled}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        </label>
    );
};
