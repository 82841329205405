import { actionCreatorFactory } from 'typescript-fsa';
import { Tag } from '@mrm/tags';

import { LoadingStatus } from '@store/commonTypes';

import { UpdateExistingTagPayload } from '../types';

const actionCreator = actionCreatorFactory('TAGS');

export const setLoadingStatus = actionCreator<LoadingStatus>('SET_LOADING_STATUS');
export const setTags = actionCreator<Tag[]>('SET_TAGS');
export const addTag = actionCreator<Tag>('ADD_TAG');
export const updateExistingTag = actionCreator<UpdateExistingTagPayload>('UPDATE_EXISTING_TAG');
