import * as React from 'react';

import * as styles from './WithTitle.scss';

interface Props {
    qaId?: string;
    title: string;
    text: string;
}

export function WithTitle({ qaId, title, text }: Props): JSX.Element {
    return (
        <div
            className={styles.root}
            {...{
                'qa-id': qaId,
                'qa-label': text,
            }}
        >
            <span className={styles.title}>{title}: </span>

            {text}
        </div>
    );
}
