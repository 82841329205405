// tslint:disable:max-file-line-count
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import * as lodash from 'lodash';
import formatNumber from 'format-number';

import {
    BudgetItem,
    BudgetItemStatus,
    BudgetItemApproverStatus,
    BudgetItemSnapshot,
    ActivityBudget,
} from '@mrm/budget';
import { PlainDictionary, DictionaryType, DictionaryStatus } from '@mrm/dictionary';
import { Tag } from '@mrm/tags';
import {
    PageState,
    PageData,
    PageFilters,
    TableLine,
    TableLineGroup,
    CustomCellType,
    CellValueType,
    ColumnFilters,
    ColumnName,
    Filters,
    SortingMode,
    OrderType,
    CellValue,
    NumericCellValue,
    DateCellValue,
    UnsavedChange,
    ChangeList,
    TotalBudgets,
    ColumnData,
    ColumnsNameWithCodes,
    GroupedDictionaries,
} from './types';

import { DropdownCellOptions } from 'client/modules/budget/BudgetPage/BudgetPlanning/Table/CellTypes/DropdownCell/DropdownCell';

import { getLoginUser, User } from '@store/user';
import { getTagsState } from '@store/tags';

import { StoreState } from '../';
import {
    CalculatableColumns,
    ColumnsList,
    DictionaryColumns,
} from '../../modules/budget/BudgetPage/BudgetPlanning/ColumnsConfig';
import { ActualValue } from 'client/modules/budget/BudgetPage/BudgetPlanning/Table/LayerManager';
import { UserResponseParams } from 'sber-marketing-types/backend';

import { Utils } from '@common/Utils';
import { MultiReferenceDictionaryApi } from 'client/api';

const formatter = formatNumber({
    integerSeparator: ' ',
    decimal: ',',
    truncate: 3,
});

function formatCurrency(value: number): string {
    return formatter(Math.round(value) / 100000.0);
}

export const getBudgetPlanningPageState = (state: StoreState): PageState => state.budgetPlanningPage.restState;

export const getPageData = createSelector(getBudgetPlanningPageState, (state: PageState): PageData => state.pageData);

export const getTableFilters = createSelector(
    getBudgetPlanningPageState,
    (state: PageState): PageFilters => state.pageFilters,
);

export const getUnsavedChanges = createSelector(
    getBudgetPlanningPageState,
    (state: PageState): ChangeList => state.unsavedChanges,
);

export const canUndoUnsavedChanges = createSelector(
    getBudgetPlanningPageState,
    (state: PageState): boolean => state.currentChangePosition !== 0,
);

export const canRedoUnsavedChanges = createSelector(
    getBudgetPlanningPageState,
    (state: PageState): boolean => state.currentChangePosition !== state.changesHistory.length,
);

export const getBudgetItems = createSelector(getBudgetPlanningPageState, (state: PageState) => {
    const {
        pageData: { budgetItems, budgetItemsToIgnoreFilters },
    } = state;

    return lodash.uniqBy([...budgetItems, ...budgetItemsToIgnoreFilters], (budgetItem) => budgetItem.id);
});

export const getChangedLinesIds = createSelector(getUnsavedChanges, (unsavedChanges: ChangeList): string[] => {
    const lineIds: string[] = [];

    lodash.forEach(unsavedChanges, (lineChanges, lineId) => {
        if (lineChanges.length > 0) {
            lineIds.push(lineId);
        }
    });

    return lineIds;
});

export const getActivitiesTotalBudgets = createSelector(getPageData, (pageData: PageData): TotalBudgets => {
    const { budgetItems, budgetItemsToIgnoreFilters } = pageData;

    const totalBudgets: TotalBudgets = {};

    lodash
        .uniqBy([...budgetItems, ...budgetItemsToIgnoreFilters], (budgetItem) => budgetItem.id)
        .forEach((budgetItem) => {
            if (totalBudgets[budgetItem.activity.id] === undefined) {
                totalBudgets[budgetItem.activity.id] = 0;
            }

            if (budgetItem.status !== BudgetItemStatus.Disabled) {
                const linePlan = lodash.sum(lodash.values(budgetItem.plannedFunds));

                totalBudgets[budgetItem.activity.id] += linePlan;
            }
        });

    return totalBudgets;
});

export const getTableTotalBudget = createSelector(getActivitiesTotalBudgets, (plannedBudgets: TotalBudgets): number => {
    return lodash.reduce(plannedBudgets, (acc, item) => acc + item, 0);
});

export const userCanEditOrCreateBudgetData = createSelector(
    getPageData,
    (pageData: PageData) => pageData.budget?.actions?.createActivity,
);

export function makeLine(
    budgetItem: BudgetItem,
    activityBudgets: ActivityBudget[],
    users: UserResponseParams[],
    tags: lodash.Dictionary<Tag>,
    user: User,
) {
    const line: TableLine = {
        id: budgetItem.id,
        budgetItem,
        activityId: budgetItem.activity.id,
        status: budgetItem.status,
        userIsAuthor: budgetItem.initiator.id == user.attributes.id,
        canEdit: budgetItem.actions.canEdit,
        canMoveToExpertApprovement: budgetItem.actions.canMoveToExpertApprovement,
        fields: {},
        creationTime: budgetItem.creationTime,
        planValue: lodash.sum(lodash.values(budgetItem.plannedFunds)),
    };

    ColumnsList.forEach((column) => {
        if (column.accessor) {
            line.fields[column.name] = column.accessor({
                budgetItem,
                activityBudgets,
                users,
                tags,
            });
        }
    });

    CalculatableColumns.forEach((column) => {
        const { calculateActualValue } = column.metaData;

        const cellActualValue: ActualValue = calculateActualValue((columnName: ColumnName) => ({
            value: getCellValue(line, columnName),
        }));

        line.fields[column.name] = {
            number: parseInt(cellActualValue.value, 10),
            title: cellActualValue.value,
        } as NumericCellValue;
    });

    return line;
}

export const getTableLines = createSelector(
    getPageData,
    getLoginUser,
    (state: StoreState) => getTagsState(state).byId.dictionary,
    (pageData: PageData, user: User, tags: lodash.Dictionary<Tag>): TableLine[] => {
        const { activityBudgets, budgetItems, budgetItemsToIgnoreFilters, users } = pageData;

        const sortedBudgetItems = lodash.sortBy(
            lodash.uniqBy([...budgetItemsToIgnoreFilters, ...budgetItems], (budgetItem) => budgetItem.id),
            'serialNumber',
        );

        return sortedBudgetItems.map((budgetItem) => makeLine(budgetItem, activityBudgets, users, tags, user));
    },
);

export const getDefaultFilters = createSelector(getTableLines, (lines: TableLine[]): Filters => {
    const linesFields = lines.map((item) => item.fields);

    const filters: Filters = {};

    ColumnsList.forEach((column) => {
        if (!column.disableFilter) {
            const columnFilters: ColumnFilters = {};

            const columnFields = linesFields.map((item) => item[column.name]);

            let columnValues;

            if (column.name === ColumnName.Responsible) {
                columnValues = columnFields.reduce((acc, item) => {
                    if (!item) {
                        return [...acc, item];
                    }

                    const splitedItems = (item as string).split(',');
                    return [...acc, ...splitedItems];
                }, []);
            } else {
                columnValues = columnFields.map((item) => {
                    switch (column.valueType) {
                        case CellValueType.String:
                            return item as string;
                        case CellValueType.Date:
                            return item ? (item as any).title : null;
                        case CellValueType.Number:
                            return getNumberValue(item).toString();
                        case CellValueType.Currency:
                            return item ? formatCurrency((item as any).number) : null;
                        default:
                            throw new Error(`No DefaultFilter getter for ${column.valueType}`);
                    }
                });
            }

            let columnUniqueValues = lodash.uniq(columnValues);

            const hasNull = lodash.includes(columnUniqueValues, null);
            const hasEmptyString = lodash.includes(columnUniqueValues, '');

            if (hasNull) {
                columnUniqueValues = lodash.remove(columnUniqueValues, null);
                columnUniqueValues.push(null);
            }

            if (hasEmptyString) {
                columnUniqueValues = lodash.compact(columnUniqueValues);
                columnUniqueValues.push('');
            }

            columnUniqueValues.forEach((item) => {
                columnFilters[item] = false;
            });

            filters[column.name] = columnFilters;
        }
    });

    return filters;
});

export const getFilteredTableLines = createSelector(
    getTableLines,
    getPageData,
    getTableFilters,
    (lines: TableLine[], pageData: PageData, pageFilters: PageFilters): TableLine[] => {
        const { budgetItems, userDictionaries, users, budgetItemsToIgnoreFilters, budgetItemSnapshots } = pageData;

        const filteredLines = filterTableLines(
            lines,
            [...budgetItems, ...budgetItemsToIgnoreFilters],
            pageFilters,
            budgetItemSnapshots,
        );

        return pageFilters.sortingMode.columnName && pageFilters.sortingMode.columnName != ColumnName.ActivityName
            ? sortTableLines(
                  filteredLines,
                  userDictionaries.byId,
                  users,
                  pageFilters.sortingMode,
                  budgetItemsToIgnoreFilters,
              )
            : filteredLines;
    },
);

export const getLinesGroupedByActivities = createSelector(
    getFilteredTableLines,
    getTableFilters,
    (lines: TableLine[], pageFilters: PageFilters): TableLineGroup[] => {
        const { sortingMode } = pageFilters;

        const activityIds = lodash.uniq(lines.map((item) => item.activityId));

        let groupedLines = activityIds.map((activityId) => {
            const activityLines = lines.filter((line) => line.activityId == activityId);

            return {
                activityId,
                lines: lodash.sortBy(activityLines, 'serialNumber'),
                canEdit: activityLines.every((line) => line.canEdit),
            };
        });

        groupedLines = lodash.sortBy(groupedLines, (item) => lodash.first(item.lines).fields[ColumnName.ActivityName]);

        if (sortingMode.order == OrderType.Desc) {
            groupedLines.reverse();
        }

        return groupedLines;
    },
);

export const getUnsavedChangesByLineId = createCachedSelector(
    getUnsavedChanges,
    (state: StoreState, lineId: string): string => lineId,
    (unsavedChanges: ChangeList, lineId: string): UnsavedChange[] => {
        return unsavedChanges[lineId];
    },
)((state: StoreState, lineId: string): string => lineId);

export function getDropdownsOptionsByLineId(
    line: TableLine,
    users: UserResponseParams[],
    groupedDictionaries: GroupedDictionaries,
    changes: UnsavedChange[],
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi,
) {
    const lineDictionaryValue: Partial<Record<DictionaryType, PlainDictionary>> = {};
    DictionaryColumns.forEach((dictionaryColumn) => {
        if (!ColumnsNameWithCodes.includes(dictionaryColumn.name)) {
            const columnChange = changes?.find((change) => change.columnName === dictionaryColumn.name);
            const valueToUse = (columnChange ? columnChange?.value : line.fields[dictionaryColumn.name]) as string;

            if (valueToUse) {
                lineDictionaryValue[dictionaryColumn.metaData.dictionaryType] = groupedDictionaries.byId[valueToUse];
            }
        }
    });

    const dropdownsOptions: { [columnName: string]: DropdownCellOptions[] } = {};

    ColumnsList.forEach((column) => {
        const columnDictionaryTitleAccessor = lodash.includes(ColumnsNameWithCodes, column.name) ? 'code' : 'value';

        const dictionaryType = lodash.get(column, 'metaData.dictionaryType') as DictionaryType;

        if (column.customCellType == CustomCellType.Dropdown && dictionaryType) {
            if (!line.canEdit) {
                const budetItemDictionary = groupedDictionaries.byId[line.budgetItem.dictionary?.[dictionaryType]?.id];

                dropdownsOptions[column.name] = makeDropdownOptions(
                    columnDictionaryTitleAccessor,
                    budetItemDictionary ? [budetItemDictionary] : [],
                );
            } else {
                const visibleDictionaries = multiReferenceDictionaryApi.getDictionariesForValue(
                    lineDictionaryValue,
                    dictionaryType,
                );
                const availableDictionaries =
                    visibleDictionaries[dictionaryType] || groupedDictionaries.byType[dictionaryType] || [];
                dropdownsOptions[column.name] = makeDropdownOptions(
                    columnDictionaryTitleAccessor,
                    availableDictionaries,
                );
            }
        } else if (column.metaData && column.metaData.dropdownItems) {
            dropdownsOptions[column.name] = column.metaData.dropdownItems({ users });
        }
    });

    return dropdownsOptions;
}

export const getDropdownsOptions = createSelector(
    getTableLines,
    (state: StoreState) => getPageData(state),
    (state: StoreState) => getUnsavedChanges(state),
    (state: StoreState) => getBudgetPlanningPageState(state).multiReferenceDictionaryApi,
    (
        lines: TableLine[],
        pageData: PageData,
        unsavedChanges: ChangeList,
        multiReferenceDicitonaryApi: MultiReferenceDictionaryApi,
    ): { [lineId: string]: { [columnName: string]: DropdownCellOptions[] } } => {
        const { users, userDictionaries } = pageData;

        const dropdownsOptions = {};

        lines.forEach((line) => {
            dropdownsOptions[line.id] = getDropdownsOptionsByLineId(
                line,
                users,
                userDictionaries,
                unsavedChanges[line.id] || [],
                multiReferenceDicitonaryApi,
            );
        });

        return dropdownsOptions;
    },
);

export const getLineIdsWithActualChanges = createSelector(
    getUnsavedChanges,
    getFilteredTableLines,
    (unsavedChanges, filteredLines) =>
        Object.keys(unsavedChanges).filter(
            (changesGroupLineId) =>
                !!unsavedChanges[changesGroupLineId].length &&
                filteredLines.some((line) => line.id === changesGroupLineId),
        ),
);

function filterTableLines(
    lines: TableLine[],
    budgetItems: BudgetItem[],
    pageFilters: PageFilters,
    budgetItemSnapshots: lodash.Dictionary<BudgetItemSnapshot[]>,
): TableLine[] {
    const {
        displayOnlyUnapproved,
        displayOnlyWithSnapshots,
        displayDisabledLines,
        // filters,
        budgetItemsToDisplay,
        budgetItemsApproversToDisplay,
        showOnlyLinesWithPlanBudget,
        showOnlyLinesWithoutPlanBudget,
    } = pageFilters;

    let filteredLines: TableLine[] = lodash.clone(lines).filter((line) => {
        let shouldUseThisLine = false;
        let filterWasUsed = false;

        if (showOnlyLinesWithPlanBudget) {
            shouldUseThisLine = !!line.planValue;
            filterWasUsed = true;
        } else if (showOnlyLinesWithoutPlanBudget) {
            shouldUseThisLine = !line.planValue;
            filterWasUsed = true;
        }

        if (budgetItemsToDisplay[BudgetItemStatus.Draft]) {
            shouldUseThisLine = shouldUseThisLine || line.status === BudgetItemStatus.Draft;
            filterWasUsed = true;
        }

        if (budgetItemsToDisplay[BudgetItemStatus.Published]) {
            shouldUseThisLine = shouldUseThisLine || line.status === BudgetItemStatus.Published;
            filterWasUsed = true;
        }

        if (budgetItemsToDisplay[BudgetItemStatus.OnExpertApprovement]) {
            shouldUseThisLine = shouldUseThisLine || line.status === BudgetItemStatus.OnExpertApprovement;
            filterWasUsed = true;
        }

        if (budgetItemsToDisplay[BudgetItemStatus.Approved]) {
            shouldUseThisLine = shouldUseThisLine || line.status === BudgetItemStatus.Approved;
            filterWasUsed = true;
        }

        if (budgetItemsToDisplay[BudgetItemStatus.Rejected]) {
            shouldUseThisLine = shouldUseThisLine || line.status === BudgetItemStatus.Rejected;
            filterWasUsed = true;
        }

        if (
            budgetItemsApproversToDisplay[BudgetItemApproverStatus.Approved] ||
            budgetItemsApproversToDisplay[BudgetItemApproverStatus.Rejected] ||
            budgetItemsApproversToDisplay[BudgetItemApproverStatus.Waiting]
        ) {
            filterWasUsed = true;

            Utils.withErrorHandler(() => {
                const budgetItem = budgetItems.find((budgetItem) => budgetItem.id === line.id);
                const status = budgetItem.approvers
                    ? lodash.uniq(budgetItem.approvers.map((approver) => approver.status))
                    : null;

                if (status) {
                    if (budgetItemsApproversToDisplay[BudgetItemApproverStatus.Approved]) {
                        shouldUseThisLine = shouldUseThisLine || status.includes(BudgetItemApproverStatus.Approved);
                    }

                    if (budgetItemsApproversToDisplay[BudgetItemApproverStatus.Rejected]) {
                        shouldUseThisLine = shouldUseThisLine || status.includes(BudgetItemApproverStatus.Rejected);
                    }

                    if (budgetItemsApproversToDisplay[BudgetItemApproverStatus.Waiting]) {
                        shouldUseThisLine = shouldUseThisLine || status.includes(BudgetItemApproverStatus.Waiting);
                    }
                } else {
                    shouldUseThisLine = false;
                }
            });
        }

        if (!filterWasUsed) {
            shouldUseThisLine = true;
        }

        return shouldUseThisLine;
    });

    if (displayOnlyUnapproved) {
        filteredLines = filterUnapproved(filteredLines, budgetItems);
    }

    if (displayOnlyWithSnapshots) {
        filteredLines = filterWithSnapshots(filteredLines, budgetItemSnapshots);
    }

    if (!displayDisabledLines) {
        filteredLines = filterDisabledLines(filteredLines);
    }

    // filteredLines = filteredLines.filter(line => lodash.every(ColumnsList, column => {
    //     const valueIsString = column.valueType == CellValueType.String;
    //     const valueIsNumber = column.valueType == CellValueType.Number;
    //     const valueIsDate = column.valueType === CellValueType.Date;
    //     const valueIsCurrency = column.valueType === CellValueType.Currency;
    //     const columnIsResponsible = column.name === ColumnName.Responsible;
    //     const noFiltersSelected = lodash.every(filters[column.name], item => item === false);

    //     const columnFilters = filters[column.name];

    //     if (columnIsResponsible && !noFiltersSelected) {
    //         const keys: string[] = line.fields[column.name] ?
    //             (line.fields[column.name] as any).split(',') :
    //             null;

    //         return keys ?
    //             keys.some(key => columnFilters[key]) :
    //             columnFilters[null as any];
    //     }

    //     if (valueIsCurrency && !noFiltersSelected) {
    //         const key = line.fields[column.name] ?
    //             formatCurrency((line.fields[column.name] as any).number) :
    //             null;

    //         return columnFilters[key];
    //     }

    //     if (valueIsDate && !noFiltersSelected) {
    //         const key = line.fields[column.name] ?
    //             (line.fields[column.name] as any).title :
    //             null;

    //         return columnFilters[key];
    //     }

    //     if (valueIsString && !noFiltersSelected) {
    //         const cellValue = line.fields[column.name] as string;

    //         return columnFilters[cellValue];
    //     }

    //     if (valueIsNumber && !noFiltersSelected) {
    //         const cellValue = getNumberValue(line.fields[column.name]);

    //         return columnFilters[cellValue.toString()];
    //     }

    //     return true;
    // }));

    return filteredLines;
}

function filterUnapproved(lines: TableLine[], budgetItems: BudgetItem[]): TableLine[] {
    return lines.filter((line) => {
        const budgetItem = budgetItems.find((budgetItem) => budgetItem.id === line.id);

        return budgetItem.status !== BudgetItemStatus.Approved;
    });
}

function filterWithSnapshots(
    lines: TableLine[],
    budgetItemSnapshots: lodash.Dictionary<BudgetItemSnapshot[]>,
): TableLine[] {
    return lines.filter((line) => !!budgetItemSnapshots[line.id]);
}

function filterDisabledLines(lines: TableLine[]): TableLine[] {
    return lines.filter((item) => item.status !== BudgetItemStatus.Disabled);
}

function sortTableLines(
    lines: TableLine[],
    dictionaries: Record<string, PlainDictionary>,
    users: UserResponseParams[],
    sortingMode: SortingMode,
    budgetItemsToIgnoreFilters: BudgetItem[],
): TableLine[] {
    const { columnName, order } = sortingMode;

    const linesToIgnoreFilters: TableLine[] = [];
    const linesToUseFilters: TableLine[] = [];
    lines.forEach((line) => {
        if (budgetItemsToIgnoreFilters.some((budgetItem) => budgetItem.id === line.id)) {
            linesToIgnoreFilters.push(line);
        } else {
            linesToUseFilters.push(line);
        }
    });

    return [
        ...lodash.orderBy(linesToIgnoreFilters, [(line) => line.creationTime], ['desc']),
        ...linesToUseFilters.sort(getSortingMethod(columnName, order, dictionaries, users)),
    ];
}

function getSortingMethod(
    columnName: ColumnName,
    order: OrderType,
    dictionaries: Record<string, PlainDictionary>,
    users: UserResponseParams[],
) {
    return (lineA: TableLine, lineB: TableLine) => {
        let valueA = getCellValue(lineA, columnName, dictionaries, users);
        let valueB = getCellValue(lineB, columnName, dictionaries, users);

        if (typeof valueA == 'string') {
            valueA = valueA.toUpperCase();
        }

        if (typeof valueB == 'string') {
            valueB = valueB.toUpperCase();
        }

        if (valueA == null && valueB == null) {
            return 0;
        }

        if (valueA == null) {
            return 1;
        }

        if (valueB == null) {
            return -1;
        }

        if (order == OrderType.Asc) {
            return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
        }

        if (order == OrderType.Desc) {
            return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
        }

        return 0;
    };
}

function getCellValue(
    line: TableLine,
    columnName: ColumnName,
    dictionaries?: Record<string, PlainDictionary>,
    users?: UserResponseParams[],
): React.ReactText | React.ReactText[] | Date {
    let value: React.ReactText | React.ReactText[] | Date = null;

    const column = ColumnsList.find((item) => item.name == columnName);

    if (columnName === ColumnName.Responsible) {
        value = line.fields[columnName] as string;
        value = value
            ? value.split(',').map((id) => {
                  const user = users.find((user) => user.id === Number(id));
                  return user ? `${user.secondName} ${user.firstName}` : '';
              })
            : [];
    } else if (column.customCellType == CustomCellType.Dropdown) {
        value = getDropdownValue(line.fields[columnName], column, dictionaries);
    } else {
        const valueType = ColumnsList.find((item) => item.name == columnName).valueType;

        switch (valueType) {
            case CellValueType.String:
                value = getStringValue(line.fields[columnName]);
                break;

            case CellValueType.Number:
                value = getNumberValue(line.fields[columnName]);
                break;

            case CellValueType.Currency:
                value = getNumberValue(line.fields[columnName]);
                break;

            case CellValueType.Date:
                value = getDateValue(line.fields[columnName]);
                break;
        }
    }

    return value;
}

export const getBudgetItemByLineId = createCachedSelector(
    getPageData,
    (state: StoreState, lineId: string): string => lineId,
    (pageData: PageData, lineId: string): BudgetItem => {
        if (!lineId) {
            return null;
        }

        const { budgetItems, budgetItemsToIgnoreFilters } = pageData;

        const allBudgetItems = [...budgetItems, ...budgetItemsToIgnoreFilters];

        return allBudgetItems.find((budgetItem) => budgetItem.id === lineId);
    },
)((state: StoreState, lineId: string): string => lineId || '');

function getStringValue(cellValue: CellValue): string {
    return cellValue as string;
}

function getNumberValue(cellValue: CellValue): number {
    const value = cellValue as NumericCellValue;

    return value ? value.number : null;
}

function getDateValue(cellValue: CellValue): Date {
    const value = cellValue as DateCellValue;

    return value ? value.date : null;
}

function getDropdownValue(
    cellValue: CellValue,
    column: ColumnData,
    dictionaries?: Record<string, PlainDictionary>,
): string {
    let value: string = null;

    const selectedId = cellValue as string;

    if (column.metaData && column.metaData.dictionaryType) {
        const dictionaryItem = dictionaries[selectedId];

        if (dictionaryItem) {
            value = lodash.includes(ColumnsNameWithCodes, column.name) ? dictionaryItem.code : dictionaryItem.value;
        }
    }

    return value;
}

function makeDropdownOptions(
    titleAccessor: 'code' | 'value',
    dictionaryItems: PlainDictionary[],
): DropdownCellOptions[] {
    const res = dictionaryItems
        .filter((item) => (titleAccessor === 'code' ? !!item.code : true))
        .map((item) => {
            const isDeleted = item.status === DictionaryStatus.DELETED;

            const titleStatus = isDeleted ? ' (Удален)' : '';

            return {
                id: item.id,
                title: `${lodash.get(item, titleAccessor)}${titleStatus}`,
                order: item.order,
                hide: isDeleted,
            };
        });

    return res;
}
