import * as React from 'react';
import classNames from 'classnames';

import { Flex, FlexProps } from '@common/components';

import * as styles from './ModalHeader.scss';

export interface ModalHeaderProps extends FlexProps {}

export function ModalHeader({ className, ...props }: ModalHeaderProps) {
    return <Flex {...props} className={classNames(styles.root, className)} />;
}
