import * as React from 'react';
import { Icon, IconType, WithTooltip, TooltipAnchor } from 'sber-marketing-ui';

import * as style from './CopyTextButton.scss';

interface Props {
    title?: string;
    tooltipAnchor?: TooltipAnchor;
    onClick: () => Promise<void>;
    onMouseLeave: () => void;
}

export const CopyTextButton: React.FC<Props> = ({
    title,
    tooltipAnchor = TooltipAnchor.BOTTOM,
    onClick,
    onMouseLeave,
}) => {
    return (
        <WithTooltip content={title} hidden={!title} anchor={tooltipAnchor}>
            <div
                className={style.root}
                onClick={onClick}
                onMouseLeave={onMouseLeave}
                {...{
                    'qa-id': 'copyTextButton',
                }}
            >
                <Icon type={IconType.COPY_CURRENT_COLOR} svgSize={18} />
            </div>
        </WithTooltip>
    );
};
