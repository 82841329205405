// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-tags-Tag-_Tag__root{max-width:100%;display:flex;align-items:center;padding:0 12px;font-family:'Open Sans';font-size:12px;line-height:24px;letter-spacing:0.15px;color:black;border-radius:6px}.src-client-modules-tags-Tag-_Tag__rootClickable{cursor:pointer}.src-client-modules-tags-Tag-_Tag__rootWithColor{color:#FFFFFF;font-weight:600}.src-client-modules-tags-Tag-_Tag__rootWithEmoji{padding-left:0}.src-client-modules-tags-Tag-_Tag__emoji{display:flex}.src-client-modules-tags-Tag-_Tag__title{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.src-client-modules-tags-Tag-_Tag__deleteButton{min-width:8px;margin-left:9px;cursor:pointer}\n", "",{"version":3,"sources":["webpack://./src/client/modules/tags/Tag/Tag.scss"],"names":[],"mappings":"AAAA,wCACI,cAAe,CAEf,YAAa,CACb,kBAAmB,CAEnB,cAAe,CAEf,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACjB,qBAAsB,CACtB,WAAY,CAEZ,iBAAkB,CACrB,iDAGG,cAAe,CAClB,iDAGG,aAAc,CACd,eAAgB,CACnB,iDAGG,cAAe,CAClB,yCAGG,YAAa,CAChB,yCAGG,eAAgB,CAChB,sBAAuB,CACvB,kBAAmB,CACtB,gDAGG,aAAc,CACd,eAAgB,CAChB,cAAe","sourcesContent":[".root {\n    max-width: 100%;\n\n    display: flex;\n    align-items: center;\n\n    padding: 0 12px;\n\n    font-family: 'Open Sans';\n    font-size: 12px;\n    line-height: 24px;\n    letter-spacing: 0.15px;\n    color: black;\n\n    border-radius: 6px;\n}\n\n.rootClickable {\n    cursor: pointer;\n}\n\n.rootWithColor {\n    color: #FFFFFF;\n    font-weight: 600;\n}\n\n.rootWithEmoji {\n    padding-left: 0;\n}\n\n.emoji {\n    display: flex;\n}\n\n.title {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n.deleteButton {\n    min-width: 8px;\n    margin-left: 9px;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-tags-Tag-_Tag__root",
	"rootClickable": "src-client-modules-tags-Tag-_Tag__rootClickable",
	"rootWithColor": "src-client-modules-tags-Tag-_Tag__rootWithColor",
	"rootWithEmoji": "src-client-modules-tags-Tag-_Tag__rootWithEmoji",
	"emoji": "src-client-modules-tags-Tag-_Tag__emoji",
	"title": "src-client-modules-tags-Tag-_Tag__title",
	"deleteButton": "src-client-modules-tags-Tag-_Tag__deleteButton"
};
export default ___CSS_LOADER_EXPORT___;
