import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { Editor } from './Editor';
import { Screensaver } from './Screensaver';

import { StoreState } from '@store';
import { getMode, getTextsLoadingStatus, LoadingStatus } from '@store/autopilot/generationTextForm';

import * as styles from './styles.scss';

export const GeneratedTextEditor: React.FC = () => {
    const mode = useSelector((state: StoreState) => getMode(state));
    const loadingStatus = useSelector((state: StoreState) => getTextsLoadingStatus(state));

    return loadingStatus !== LoadingStatus.LOADING && mode === 'editingTexts' ? (
        <div className={classNames(styles.root)}>
            <Editor />
        </div>
    ) : (
        <div className={classNames(styles.root, styles.screensaverWrapper)}>
            <Screensaver />
        </div>
    );
};
