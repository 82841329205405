import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';

import type { StoreState } from '@store';
import { getFilters } from '@store/calendar/selectors';
import { setFilters } from '@store/calendar/actions';
import { isRequestInProgress } from '@store/common/selectors';
import type { Filters } from '@store/calendar/types';
import { SwitchKeyActivityButton } from './SwitchKeyActivityButton';
import type { EventClick } from './SwitchKeyActivityButton';

interface Props extends Partial<MapProps>, Partial<DispatchProps> {}

interface MapProps {
    isRequestInProgress: boolean;
    selected: boolean;
}

interface DispatchProps {
    setFilters: (filters: Filters) => void;
}

interface State {
    hover: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class SwitchKeyActivityButtonContainer extends React.PureComponent<Props, State> {
    public state: State = { hover: false };

    public render() {
        return React.createElement(SwitchKeyActivityButton, {
            displayed: !this.props.isRequestInProgress,
            selected: this.props.selected,
            hover: this.state.hover,
            onClick: this.onClick,
            onMouseEnter: this.onMouseEnter,
            onMouseLeave: this.onMouseLeave,
        });
    }

    @autobind
    private onClick(event: EventClick): void {
        event.preventDefault();
        this.props.setFilters({ onlyKeyActivities: !this.props.selected });
    }

    @autobind
    private onMouseEnter(): void {
        this.setState({ hover: true });
    }

    @autobind
    private onMouseLeave(): void {
        this.setState({ hover: false });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { onlyKeyActivities } = getFilters(state);

    return {
        selected: onlyKeyActivities,
        isRequestInProgress: isRequestInProgress(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setFilters,
        },
        dispatch,
    );
}
