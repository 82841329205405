import * as lodash from 'lodash';

import type { OrganizationGroups } from '@store/calendar/types';

import { GroupsFilter } from '../index';

import type { Page } from './organizationGroupClipper/OrganizationGroupClipper';
import { OrganizationGroupClipper } from './organizationGroupClipper/OrganizationGroupClipper';

import { PageCanvasMaker } from './PageCanvasMaker';

export class PagesCanvasMaker {
    private emptyExpiredTaskIcon: HTMLImageElement;
    private finishedTaskIcon: HTMLImageElement;
    private filledExpiredTaskIcon: HTMLImageElement;

    private pages: Page[];
    private pagesCanvases: HTMLCanvasElement[];

    private groupsFilter: GroupsFilter;

    public constructor() {
        this.groupsFilter = GroupsFilter.getInstance();
        this.pagesCanvases = [];
    }

    public async getCanvases(): Promise<HTMLCanvasElement[]> {
        await this.init();
        await this.drawPages();

        return this.pagesCanvases;
    }

    private async init(): Promise<void> {
        await this.initLoadingIcons();
        this.initOrganizationsGroupsByDocument();
    }

    private async initLoadingIcons(): Promise<void> {
        const [emptyExpiredTaskIcon, filledExpiredTaskIcon, finishedTaskIcon] = await Promise.all([
            this.loadImage('/static/images/empty_expired_task.png'),
            this.loadImage('/static/images/filled_expired_task.png'),
            this.loadImage('/static/images/finished_task.png'),
        ]);

        this.emptyExpiredTaskIcon = emptyExpiredTaskIcon;
        this.filledExpiredTaskIcon = filledExpiredTaskIcon;
        this.finishedTaskIcon = finishedTaskIcon;
    }

    private initOrganizationsGroupsByDocument(): void {
        const organizations = this.getOrganizationsWithExpandedGroups();
        const organizationGroupClipper = new OrganizationGroupClipper({ organizationGroupsList: organizations });

        this.pages = organizationGroupClipper.getPages();
    }

    private async drawPages(): Promise<void> {
        for (const index of lodash.keys(this.pages)) {
            const page = this.pages[index];
            const isFirstPage = index === '0';
            const pageCanvasMaker = new PageCanvasMaker({
                pageData: {
                    ...page,
                    iconMap: {
                        emptyExpiredTaskIcon: this.emptyExpiredTaskIcon,
                        filledExpiredTaskIcon: this.filledExpiredTaskIcon,
                        finishedTaskIcon: this.finishedTaskIcon,
                    },
                },
                isFirstPage,
                topIndentOfFirstPage: this.getDateFilterElement().offsetHeight,
                chartWidth: this.getDateFilterElement().offsetWidth,
            });

            const canvas = await pageCanvasMaker.getCanvas();

            this.pagesCanvases.push(canvas);
        }
    }

    private getOrganizationsWithExpandedGroups(): OrganizationGroups[] {
        const organizationGroups = this.groupsFilter.getFilteredGroups();

        const organizationGroupsWhereSomeExpandedGroups = organizationGroups.filter((organization) =>
            organization.groups.some((group) => group.isExpanded),
        );

        return organizationGroupsWhereSomeExpandedGroups.map((organizationGroup) => ({
            ...organizationGroup,
            groups: organizationGroup.groups.filter((group) => group.isExpanded),
        }));
    }

    private loadImage(src: string): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.addEventListener('load', () => resolve(img));
            img.addEventListener('error', (err) => reject(err));
            img.src = src;
        });
    }

    private getDateFilterElement(): HTMLElement {
        return document.getElementById('pdf-date-filter');
    }
}
