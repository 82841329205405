import * as React from 'react';

import { HeaderView } from '@common/Page';
import { WithRouter } from './WithRouter';
import { WithInitCreativeRequireDomain } from './WithInitCreativeRequireDomain';
import { WithCreativeStageWidget } from './WithCreativeStageWidget';
import { CreativePageBehaviour } from './CreativePageBehaviour';
import { TableContext } from './Context';
import { TableBehaviour } from './StageWidgets/TableWidget/Table/TableBehaviour';
import { useTableDescriptionDataForXLSX } from './hooks';

interface Props {
    setHeaderView?: (view: HeaderView) => void;
    setSubHeaderRightContent: (content: JSX.Element) => void;
}

export const CreativePageConnector: React.FC<Props> = ({ setHeaderView, setSubHeaderRightContent }) => {
    const tableRef = React.useRef<TableBehaviour>(null);

    const tableDescriptionDataForXLSX = useTableDescriptionDataForXLSX();

    return (
        <WithRouter>
            {({ creativeRequestId }) => (
                <WithInitCreativeRequireDomain creativeRequestId={creativeRequestId}>
                    {({ loading }) =>
                        loading ? null : (
                            <WithCreativeStageWidget creativeRequestId={creativeRequestId}>
                                {({ data }) => (
                                    <TableContext.Provider value={tableRef}>
                                        <CreativePageBehaviour
                                            creativeRequestStatus={data.status}
                                            tableRef={tableRef}
                                            setHeaderView={setHeaderView}
                                            setSubHeaderRightContent={setSubHeaderRightContent}
                                            tableDescriptionDataForXLSX={tableDescriptionDataForXLSX}
                                        />
                                    </TableContext.Provider>
                                )}
                            </WithCreativeStageWidget>
                        )
                    }
                </WithInitCreativeRequireDomain>
            )}
        </WithRouter>
    );
};
