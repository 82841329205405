import * as React from 'react';

import type { ExcessReserveWarningProps as Warning, FondField } from '../common';
import {
    CommonFondsChange,
    CorrectionCardComment,
    CorrectionCardHeader,
    ExcessReserveWarningList,
    FondsByMonthTable,
    RejectComment,
    RejectedCorrectionWarningList,
    SubPramList,
    SumWithTitle,
} from '../common';

import { CorrectionStatus } from '@mrm/budget';

import * as style from './ReserveCorrectionCard.scss';

export type { FondField, Warning };

const STATUS_COLOR = {
    [CorrectionStatus.NeedApproving]: '#f5a623',
    [CorrectionStatus.Approved]: '#19bb4f',
    [CorrectionStatus.Rejected]: '#e63901',
};

export interface PlanData {
    name: string;
    currentPlan: number;
    fields: PlanField[];
    comment: string;
}

export interface PlanField {
    name: string;
    value: React.ReactText;
    isWide?: boolean;
}

export interface ReserveData {
    beforeSumFonds: number;
    delta: number;
    fields: FondField[];
}

interface Props {
    serialNumber: number;
    authorName: string;
    approverName: string;
    creationDate: string;
    planData: PlanData;
    reserveData: ReserveData;
    status: CorrectionStatus;
    rejectComment: string;
    overwrittenSerialNumber: string;
    warnings: Warning[];
}

export const ReserveCorrectionCard = ({
    serialNumber,
    authorName,
    approverName,
    creationDate,
    planData,
    reserveData,
    status,
    rejectComment,
    overwrittenSerialNumber,
    warnings,
}: Props): JSX.Element => {
    const haveWarnings = Boolean(warnings.length);

    return (
        !!planData &&
        !!reserveData && (
            <div className={style.root}>
                <CorrectionCardHeader
                    title={'Корректировка резерва'}
                    serialNumber={serialNumber}
                    authorName={authorName}
                    approverName={approverName}
                    creationDate={creationDate}
                    status={status}
                />

                {rejectComment && <RejectComment text={rejectComment} />}

                {haveWarnings && <ExcessReserveWarningList warnings={warnings} />}

                {overwrittenSerialNumber && (
                    <RejectedCorrectionWarningList
                        warnings={[
                            {
                                correctionSerialNumberId: overwrittenSerialNumber,
                                isActive: status === CorrectionStatus.NeedApproving,
                            },
                        ]}
                    />
                )}

                <div className={style.participators}>
                    <div
                        className={style.participatorsRow}
                        {...{
                            'qa-id': 'correctionCardParticipatorsInfo',
                        }}
                    >
                        <div
                            className={style.participatorsCell}
                            {...{
                                'qa-id': 'correctionCardParticipatorLeftColumn',
                            }}
                        >
                            <PlanSubInfo
                                comment={planData.comment}
                                name={planData.name}
                                currentPlan={planData.currentPlan}
                                fields={planData.fields}
                            />
                        </div>
                        <div
                            className={style.participatorsCell}
                            {...{
                                'qa-id': 'correctionCardParticipatorRightColumn',
                            }}
                        >
                            <ReserveSubInfo
                                fields={reserveData.fields}
                                beforeSumFonds={reserveData.beforeSumFonds}
                                delta={reserveData.delta}
                            />
                        </div>
                    </div>
                    <div
                        className={style.participatorsRow}
                        {...{
                            'qa-id': 'correctionCardCorrectionParams',
                        }}
                    >
                        <div
                            className={style.participatorsCell}
                            {...{
                                'qa-id': 'correctionCardCorrectionParamsLeftColumn',
                            }}
                        >
                            <div className={style.subInfoContent}>
                                <SubPramList supParams={planData.fields} />
                                {planData.comment && (
                                    <SubPramList
                                        supParams={[
                                            {
                                                name: 'Комментарий к строке',
                                                value: <CorrectionCardComment text={planData.comment} />,
                                                isWide: true,
                                                notCropContent: true,
                                            },
                                        ]}
                                    />
                                )}
                            </div>
                        </div>
                        <div
                            className={style.participatorsCell}
                            {...{
                                'qa-id': 'correctionCardCorrectionParamsRightColumn',
                            }}
                        >
                            <div className={style.subInfoContent}>
                                <FondsByMonthTable fields={reserveData.fields} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={style.status} style={{ backgroundColor: STATUS_COLOR[status] }} />
            </div>
        )
    );
};

const PlanSubInfo: React.FC<PlanData> = (data) => (
    <div className={style.subInfo}>
        <div className={style.subInfoTitle}>{data.name}</div>

        <SumWithTitle sum={data.currentPlan} title={'Плановый бюджет по строке'} />
    </div>
);

const ReserveSubInfo: React.FC<ReserveData> = (data) => (
    <div className={style.subInfo}>
        <div className={style.commonFondsChangeWrapper}>
            <CommonFondsChange title={'Резерв по строке'} before={data.beforeSumFonds} delta={data.delta} />
        </div>
    </div>
);
