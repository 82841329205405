import * as React from 'react';
import { useSelector } from 'react-redux';
import * as lodash from 'lodash';

import * as style from './CreativeStatusesStageWidget.scss';

import type { StoreState } from '@store';

import { Table } from './Table';
import { getAutopilot } from '@store/autopilot/selectors';

interface Props {
    readOnly?: boolean;
}

export const CreativeStatusesStageWidget = ({ readOnly }: Props): JSX.Element => {
    const autopilot = useSelector((state: StoreState) => getAutopilot(state));

    return (
        <div className={style.root}>
            {!lodash.isEmpty(autopilot.placements) && (
                <>
                    <div className={style.title}>Статусы</div>

                    <div className={style.table}>
                        <Table readOnly={readOnly} />
                    </div>
                </>
            )}
        </div>
    );
};
