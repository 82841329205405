import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { findIndex, includes, keys, take } from 'lodash';
import { BudgetItem, BudgetStatus, Filter } from '@mrm/budget';

import { BudgetItemApi } from '@api';

import { StoreState } from '@store';
import { getBudgetByStatusUserConfig } from '@store/userConfig/budget';
import { LoadingStatus } from '@store/commonTypes';
import { getTagsState } from '@store/tags';

import * as actions from '../actions/sync';
import * as asyncActions from '../actions/async';

import { getBudgetPlanningPageState } from '../selectors';
import {
    ColumnName,
    ColumnNameToFilterMap,
    AppliedFiltersNames,
    BUDGET_ITEM_DICTIONARY_TYPES,
    ColumnFiltersUpdated,
    MONTH_BY_COLUMN_NAMES,
    PLANNED_COLUMN_NAMES,
    FilterKey,
    ChangeCellValueParams,
    TOTAL_PLAN_QUARTER_COLUMN_NAMES,
} from '../types';
import { getAppliedFilterValues, getColumnNameFromDictionaryType } from '../utils';

// injects tags into payload
export const changeCellValue = bindThunkAction<StoreState, ChangeCellValueParams, ChangeCellValueParams, Error>(
    asyncActions.changeCellValue,
    async (payload, dispatch, getState) => {
        const tags = getTagsState(getState()).byId.dictionary;

        return { ...payload, tags };
    },
);

export const loadFiltersByColumn = bindThunkAction<StoreState, FilterKey, void, Error>(
    asyncActions.loadFiltersByColumnName,
    async (columnName, dispatch, getState) => {
        const state = getState();
        const { budgetId } = getBudgetByStatusUserConfig(state, BudgetStatus.Plan);
        const {
            columnFilters: { filters: existingColumnsFilters, loadingStatus },
            pageFilters: { appliedFiltersNames },
        } = getBudgetPlanningPageState(state);

        if (loadingStatus[columnName] === LoadingStatus.NOT_LOADED) {
            dispatch(actions.setFiltersPreloaderStatus(true));
            dispatch(actions.incLoadingFiltersCount());
            dispatch(
                actions.setFiltersLoadingStatus({
                    columnName,
                    loadingStatus: LoadingStatus.LOADING,
                }),
            );

            const filters = (
                await BudgetItemApi.getBudgetItemFilters({
                    budgetId,
                    column: ColumnNameToFilterMap[columnName],
                    filter: convertFilters(existingColumnsFilters, appliedFiltersNames, columnName),
                })
            ).reduce((acc, key) => {
                acc[key] = existingColumnsFilters[columnName][key] || false;

                return acc;
            }, {});

            dispatch(
                actions.loadFilters({
                    columnName,
                    filters,
                }),
            );
            dispatch(
                actions.setPreviouslyLoadedFilter({
                    columnName,
                    filter: filters,
                }),
            );
            dispatch(actions.decLoadingFiltersCount());

            const { loadingFiltersCount } = getBudgetPlanningPageState(getState());

            if (!loadingFiltersCount) {
                dispatch(actions.setFiltersPreloaderStatus(false));
            }

            dispatch(
                actions.setFiltersLoadingStatus({
                    columnName,
                    loadingStatus: LoadingStatus.LOADED,
                }),
            );
        }
    },
);

const convertFilters = (
    externalsFilters: ColumnFiltersUpdated,
    appliedFiltersColumnNames: AppliedFiltersNames,
    targetFilterColumnName: FilterKey,
): Filter<BudgetItem> => {
    let filters: Filter<BudgetItem> = {};

    const parentColumnFilters = getParentColumnFilters({
        columnFilters: externalsFilters,
        targetFilterColumnName,
        appliedFiltersColumnNames,
    });

    const dictionary = buildDictionaryFromColumnFilters(parentColumnFilters);
    const serialNumbers = buildSerialNumbersFromColumnFilters(parentColumnFilters);
    const comments = buildCommentFromColumnFilters(parentColumnFilters);
    const activityNames = buildActivityNamesFromColumnFilters(parentColumnFilters);
    const sapComments = buildSapCommentFromColumnFilters(parentColumnFilters);
    const responsibles = buildResponsiblesFromColumnFilters(parentColumnFilters);
    const realizationStart = buildRealizationStartFromColumnFilters(parentColumnFilters);
    const realizationEnd = buildRealizationEndFromColumnFilters(parentColumnFilters);
    const plannedFunds = buildPlannedFundsFilters(parentColumnFilters);
    const computedFunds = buildComputedFundsFilters(parentColumnFilters);
    const author = buildAuthorsFromColumnFilters(parentColumnFilters);
    const tags = buildTagsFromColumnFilters(parentColumnFilters);

    const quarterBalances = TOTAL_PLAN_QUARTER_COLUMN_NAMES.reduce((filters, columnName) => {
        const quarterBalances = getAppliedFilterValueKeysNumber(parentColumnFilters[columnName]);

        if (quarterBalances.length) {
            filters[columnName] = quarterBalances;
        }

        return filters;
    }, {});

    if (keys(dictionary).length) {
        filters = {
            ...filters,
            dictionary,
        };
    }

    if (serialNumbers.length) {
        filters = {
            ...filters,
            serialNumber: serialNumbers,
        };
    }

    if (comments.length) {
        filters = {
            ...filters,
            comment: comments,
        };
    }

    if (activityNames.length) {
        filters = {
            ...filters,
            activity: {
                name: activityNames,
            },
        };
    }

    if (sapComments.length) {
        filters = {
            ...filters,
            sapComment: sapComments,
        };
    }

    if (responsibles.length) {
        filters = {
            ...filters,
            responsibleIds: responsibles,
        };
    }

    if (realizationStart.length) {
        filters = {
            ...filters,
            realizationStart,
        };
    }

    if (realizationEnd.length) {
        filters = {
            ...filters,
            realizationEnd,
        };
    }

    if (keys(plannedFunds).length) {
        filters = {
            ...filters,
            plannedFunds,
        };
    }

    if (keys(computedFunds).length) {
        filters = {
            ...filters,
            computedFunds,
        };
    }

    if (author.id.length) {
        filters = {
            ...filters,
            author,
        };
    }

    if (tags.length) {
        filters = {
            ...filters,
            tags,
        };
    }

    if (keys(quarterBalances).length) {
        filters = {
            ...filters,
            ...quarterBalances,
        };
    }

    return filters;
};

const buildComputedFundsFilters = (columnFilters: ColumnFiltersUpdated) => {
    let computedFundsFilters = {};

    const totalPlan = getAppliedFilterValueKeysNumber(columnFilters[ColumnName.TotalPlan]);

    if (totalPlan.length) {
        computedFundsFilters = {
            ...computedFundsFilters,
            totalPlan,
        };
    }

    return computedFundsFilters;
};

const buildPlannedFundsFilters = (columnFilters: ColumnFiltersUpdated): any => {
    return PLANNED_COLUMN_NAMES.reduce((planned, month) => {
        const plannedMonth = getAppliedFilterValueKeysNumber(columnFilters[month]);
        return plannedMonth.length
            ? {
                  ...planned,
                  [MONTH_BY_COLUMN_NAMES[month]]: plannedMonth,
              }
            : planned;
    }, {});
};

const buildRealizationStartFromColumnFilters = (columnFilters: ColumnFiltersUpdated): string[] => {
    return getAppliedFilterValueKeysString(columnFilters[ColumnName.EndDate]);
};

const buildRealizationEndFromColumnFilters = (columnFilters: ColumnFiltersUpdated): string[] => {
    return getAppliedFilterValueKeysString(columnFilters[ColumnName.StartDate]);
};

const buildResponsiblesFromColumnFilters = (columnFilters: ColumnFiltersUpdated): number[] => {
    return getAppliedFilterValueKeysNumber(columnFilters[ColumnName.Responsible]);
};

const buildSapCommentFromColumnFilters = (columnFilters: ColumnFiltersUpdated): string[] => {
    return getAppliedFilterValueKeysString(columnFilters[ColumnName.SapComment]);
};

const buildAuthorsFromColumnFilters = (columnFilters: ColumnFiltersUpdated): { id: number[] } => {
    const authorIds = getAppliedFilterValueKeysNumber(columnFilters[ColumnName.Author]);
    return { id: authorIds };
};

const buildActivityNamesFromColumnFilters = (columnFilters: ColumnFiltersUpdated): string[] => {
    return getAppliedFilterValueKeysString(columnFilters[ColumnName.ActivityName]);
};

const buildCommentFromColumnFilters = (columnFilters: ColumnFiltersUpdated): string[] => {
    return getAppliedFilterValueKeysString(columnFilters[ColumnName.Comment]);
};

const buildSerialNumbersFromColumnFilters = (columnFilters: ColumnFiltersUpdated): number[] => {
    return getAppliedFilterValueKeysNumber(columnFilters[ColumnName.Id]);
};

const buildTagsFromColumnFilters = (columnFilters: ColumnFiltersUpdated): string[] => {
    return getAppliedFilterValueKeysString(columnFilters[ColumnName.Tags]);
};

const buildDictionaryFromColumnFilters = (columnFilters: ColumnFiltersUpdated): Record<string, boolean> => {
    return BUDGET_ITEM_DICTIONARY_TYPES.reduce((prevDictionary, budgetItemDictionaryTypes) => {
        const columnFilterName = getColumnNameFromDictionaryType(budgetItemDictionaryTypes);
        const appliedFilterValuesIds = getAppliedFilterValueKeysString(columnFilters[columnFilterName]);

        return appliedFilterValuesIds.length
            ? {
                  ...prevDictionary,
                  [budgetItemDictionaryTypes]: {
                      id: appliedFilterValuesIds,
                  },
              }
            : prevDictionary;
    }, {});
};

interface FilterColumnsFiltersParams {
    columnFilters: ColumnFiltersUpdated;
    targetFilterColumnName: FilterKey;
    appliedFiltersColumnNames: AppliedFiltersNames;
}

const NOT_FOUND_KEY = -1;

const getParentColumnFilters = ({
    columnFilters,
    targetFilterColumnName,
    appliedFiltersColumnNames,
}: FilterColumnsFiltersParams): ColumnFiltersUpdated => {
    const countFilters = findIndex(
        appliedFiltersColumnNames,
        (filterColumnName) => filterColumnName === targetFilterColumnName,
    );

    const applyAllParentFilters = countFilters == NOT_FOUND_KEY;

    if (applyAllParentFilters) {
        return columnFilters;
    }

    const appliedParentFiltersColumnNames = take(appliedFiltersColumnNames, countFilters);

    return keys(columnFilters).reduce((newColumnFilters, columnName) => {
        return {
            ...newColumnFilters,
            [columnName]: includes(appliedParentFiltersColumnNames, columnName) ? columnFilters[columnName] : {},
        };
    }, {});
};

interface GetAppliedFilterValueName<T extends number | string> {
    (filter: Record<string, boolean>): T[];
}

const getAppliedFilterValueKeysString: GetAppliedFilterValueName<string> = (filter) => {
    return keys(getAppliedFilterValues(filter)).map((filterKey) => {
        return filterKey === 'null' ? null : filterKey;
    });
};

const getAppliedFilterValueKeysNumber: GetAppliedFilterValueName<number> = (filter) => {
    return keys(getAppliedFilterValues(filter)).map((filterKey) => {
        return filterKey === 'null' ? null : Number(filterKey);
    });
};
