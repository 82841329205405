import * as React from 'react';
import classNames from 'classnames';

import * as style from './LaunchButton.scss';

import { Icon, IconType } from 'sber-marketing-ui';
import { ConfirmModal } from '../../../ConfirmModal';

interface Props {
    title: string;
    isLaunched: boolean;
    disabled: boolean;
    onLaunchConfirm: () => void;
    onStopConfirm: () => void;
}

export const LaunchButton = ({ title, isLaunched, disabled, onLaunchConfirm, onStopConfirm }: Props): JSX.Element => {
    const [displayConfirmModal, setDisplayConfirmModal] = React.useState(false);

    const onButtonClick = React.useCallback(() => {
        setDisplayConfirmModal(true);
    }, []);

    const onModalConfirm = React.useCallback(() => {
        if (isLaunched) {
            onStopConfirm();
        } else {
            onLaunchConfirm();
        }

        setDisplayConfirmModal(false);
    }, [isLaunched]);

    const onModalCancel = React.useCallback(() => {
        setDisplayConfirmModal(false);
    }, []);

    return (
        <>
            <div
                className={classNames(style.root, isLaunched && style.launched, disabled && style.disabled)}
                onClick={!disabled ? onButtonClick : null}
            >
                <div className={style.icon}>
                    <Icon type={isLaunched ? IconType.PAUSE : IconType.PLAY_FILLED} svgSize={12} />
                </div>

                {title}
            </div>

            {displayConfirmModal && (
                <ConfirmModal
                    title={'Внимание!'}
                    message={isLaunched ? 'Размещение будет остановлено' : 'Размещение будет запущено'}
                    closeButtonText={'Отмена'}
                    confirmButtonText={'Подтвердить'}
                    onCloseButtonClick={onModalCancel}
                    onConfirmButtonClick={onModalConfirm}
                />
            )}
        </>
    );
};
