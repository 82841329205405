import * as React from 'react';
import classNames from 'classnames';

import { Utils } from '@common/Utils/Utils';
import * as styles from './styles.scss';

interface Props {
    value: string;
    className?: string;
}

export const Item: React.FC<Props> = ({ value, className }) => {
    return (
        <div className={classNames(styles.root, className)}>
            <div className={styles.text}>{value}</div>
            <div className={styles.count}>
                {value.length} {Utils.getDeclensionByNumber(value.length, ['символ', 'символа', 'символов'])}
            </div>
        </div>
    );
};
