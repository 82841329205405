import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { FormData } from '@store/executionBudgetEdit/types';

import { FormField, FieldValue } from 'sber-marketing-ui';
import { ActivityBudgetCard } from './ActivityBudgetCard';
import { StoreState } from '@store';
import { isRequestInProgress } from '@store/common/selectors';
import { updateActivityForm, updateBudgetForm } from '@store/executionBudgetEdit/actions';

interface Props extends Partial<MapProps>, Partial<DispatchProps> {
    budgetId: string;
    showActivitySuggest: boolean;
}

interface MapProps {
    id: string;
    fields: FormField[];
    budgetItemForms: FormData[];
    isRequestInProgress: boolean;
}

interface DispatchProps {
    updateActivityForm: (form: FormData) => void;
    updateBudgetForm: (form: FormData) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class ActivityBudgetCardContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { budgetId, fields, showActivitySuggest } = this.props;

        return React.createElement(ActivityBudgetCard, {
            budgetId,
            fields,
            showActivitySuggest,
            onFieldValueChange: this.onFieldValueChange,
        });
    }

    @autobind
    protected onFieldValueChange(fieldId: string, newValue: FieldValue): void {
        const { fields, budgetItemForms } = this.props;

        const changedField = fields.find((item) => item.id == fieldId);

        changedField.value = newValue;
        changedField.errorMessage = '';

        this.props.updateActivityForm({
            id: this.props.id,
            fields: lodash.clone(fields),
            collapsed: false,
            isNew: false,
        });

        budgetItemForms.forEach((form) => {
            const updatedForm = lodash.cloneDeep(form);

            const activityNameField = updatedForm.fields.find((item) => item.name === 'activityBudget');

            activityNameField.value = newValue;

            this.props.updateBudgetForm(updatedForm);
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { activityForm, budgetItemForms } = state.executionBudgetEditPage;
    const { id, fields } = activityForm;

    return {
        id,
        fields,
        budgetItemForms,
        isRequestInProgress: isRequestInProgress(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            updateActivityForm,
            updateBudgetForm,
        },
        dispatch,
    );
}
