import * as React from 'react';
import { connect } from 'react-redux';

import { BudgetItem } from '@mrm/budget';
import { StoreState } from '@store';
import { getBudgetItemByLineId } from '@store/budgetPlanning';

import { CommentsBlock } from '@common/CommentsBlock';
import { CommentsWidgetConnected } from './CommentsWidgetConnected';
import { CommentsList } from './CommentsList';
import { withChangesHistoryOfBudgetItem, WithChangesHistoryBudgetItemProps } from './withChangesHistoryOfBudgetItem';

interface Props extends Partial<MapProps>, Partial<WithChangesHistoryBudgetItemProps> {
    lineId: string;
}

interface MapProps {
    budgetItem: BudgetItem;
}

@(connect(mapStateToProps) as any)
@(withChangesHistoryOfBudgetItem(({ budgetItem }) => ({ budgetItem })) as any)
export class CommentsWidget extends React.Component<Props> {
    public render() {
        const { lineId, changesHistoryOfBudgetItem } = this.props;

        return (
            <CommentsWidgetConnected budgetItemId={lineId}>
                {({ comments, createComment, loading }) => (
                    <CommentsBlock
                        comments={comments}
                        loading={loading}
                        createComment={createComment}
                        elKey={lineId}
                        disableControls={false}
                    >
                        {({ comments, pendingComments, isLoading }) => {
                            return (
                                <CommentsList
                                    isLoading={isLoading}
                                    comments={comments}
                                    pendingComments={pendingComments}
                                    changesBudgetItemStatus={changesHistoryOfBudgetItem}
                                />
                            );
                        }}
                    </CommentsBlock>
                )}
            </CommentsWidgetConnected>
        );
    }
}

function mapStateToProps(store: StoreState, props: Props): MapProps {
    const { lineId } = props;

    return {
        budgetItem: getBudgetItemByLineId(store, lineId),
    };
}
