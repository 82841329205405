import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { AxiosError } from 'axios';

import { StoreState } from '../..';
import { FetchError, ChangeExecutorPayload, ExecutionOrder } from '../types';
import { asyncActions } from '../actions';
import { TaskApi } from '../../../api/TaskApi';
import { getTaskInfo } from '../selectors';

export const changeExecutor = bindThunkAction<StoreState, ChangeExecutorPayload, void, FetchError>(
    asyncActions.changeExecutor,
    async ({ executorId }, dispatch, getState): Promise<void> => {
        const { id, workType } = getTaskInfo(getState());
        const executionOrder: ExecutionOrder = {
            workTypeId: workType,
        };

        executionOrder.executorId = Number(executorId);

        try {
            await TaskApi.editTask(id, { executionOrder });
        } catch (error) {
            const { response, message } = <AxiosError>error;
            if (response) {
                throw new FetchError(response.status, response.statusText, message);
            } else {
                throw new FetchError(0, 'Unknown', message);
            }
        }
    },
);
