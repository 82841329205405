import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import type { ActivityParams, TaskCardParams } from 'sber-marketing-types/frontend';

import { ActivityApi, TaskApi } from '@api';

import type { StoreState } from '@store';

import { resetActivityStages } from './stages';

import * as actions from './actions/sync';
import * as asyncActions from './actions/async';

import { getActivity } from './selectors';
import type { SwitchKeyActivityParams } from './types';

export const loadActivity = bindThunkAction<StoreState, number, ActivityParams, Error>(
    asyncActions.loadActivity,
    async (id, dispatch, getState) => {
        return await ActivityApi.getActivity(id, true);
    },
);

export const setActivityNeedStages = bindThunkAction<StoreState, boolean, void, Error>(
    asyncActions.setActivityNeedStages,
    async (needStages, dispatch, getState) => {
        const { id, realizationStart, realizationEnd } = getActivity(getState());

        await ActivityApi.editActivity(id, { needStages, realizationStart, realizationEnd });

        dispatch(actions.updateActivity({ needStages }));
    },
);

export const switchKeyActivity = bindThunkAction<StoreState, SwitchKeyActivityParams, void, Error>(
    asyncActions.switchKeyActivity,
    async (params, dispatch, getState) => {
        const { id, isKey } = params;
        const { realizationStart, realizationEnd } = getActivity(getState());

        await ActivityApi.editActivity(id, { isKey, realizationEnd, realizationStart });
    },
);

export const fillActivityTasks = bindThunkAction<StoreState, number, TaskCardParams[], Error>(
    asyncActions.fillActivityTasks,
    async (activityId, dispatch, getState) => {
        return (await TaskApi.getFilteredTaskCardsList({ activityId })).tasks;
    },
);

export const resetActivityPage = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.resetActivityPage,
    async (_, dispatch, getState) => {
        dispatch(actions.resetActivity());
        dispatch(resetActivityStages());
    },
);
