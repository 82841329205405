import { bindActionCreators } from 'redux';
import { v4 as uuid } from 'uuid';
import * as lodash from 'lodash';

import type { PlainDictionary } from '@mrm/dictionary';
import type { AutopilotRadio } from 'sber-marketing-types/backend';
import type { User } from '@store/user/types';
import type { GroupedDictionaries } from '@store/autopilotRadio/types';

import { store } from '@store';
import { getLoginUser } from '@store/user/selector';
import {
    loadAutopilot,
    loadActivity,
    loadMediaplan,
    loadTargetGroups,
    loadDictionaries,
} from '@store/autopilotRadio/actions';
import { getAutopilot } from '@store/autopilotRadio/selectors';
import { loadUserOrganizations } from '@store/userOrganizations/thunks';
import { AutopilotRadioApi, ActivityApi, DictionaryApi } from '@api';

interface StoreProps {
    user: User;
    autopilot: AutopilotRadio;
}

export class Loader {
    private static instance: Loader;
    private activityId: number;

    private dispatch = bindActionCreators(
        {
            loadAutopilot,
            loadActivity,
            loadMediaplan,
            loadTargetGroups,
            loadUserOrganizations,
            loadDictionaries,
        },
        store.dispatch,
    );

    public static getInstance(): Loader {
        if (!Loader.instance) {
            Loader.instance = new Loader();
        }
        return Loader.instance;
    }

    public async init(activityId: number) {
        this.activityId = activityId;

        await this.initPageData();
    }

    public async initPageData() {
        await this.loadAutopilot();

        await Promise.all([
            this.loadActivity(),
            this.loadCalculatedMediaplan(),
            this.loadTargetGroups(),
            this.loadUsersData(),
            this.loadDictionaries(),
        ]);
    }

    public async loadAutopilot(): Promise<void> {
        let list = await AutopilotRadioApi.getAutopilotList({ activityIds: [this.activityId] });

        let autopilot: AutopilotRadio = list.find((item) => item.activityId === this.activityId);

        if (!autopilot) {
            await AutopilotRadioApi.createAutopilot({ activityId: this.activityId, id: uuid() });
            list = await AutopilotRadioApi.getAutopilotList({ activityIds: [this.activityId] });
            autopilot = list.find((item) => item.activityId === this.activityId);
        }

        this.dispatch.loadAutopilot(autopilot);
    }

    public async loadActivity(): Promise<void> {
        const activity = await ActivityApi.getActivity(this.activityId);

        this.dispatch.loadActivity(activity);
    }

    public async loadCalculatedMediaplan(): Promise<void> {
        const { autopilot } = this.getStoreProps();

        if (autopilot.brief) {
            const mediaplan = await AutopilotRadioApi.calculateAutopilotMediaplanPlanData(autopilot.id);

            this.dispatch.loadMediaplan(mediaplan);
        }
    }

    public async loadMediaplan(): Promise<void> {
        const { autopilot } = this.getStoreProps();

        const mediaplan = await AutopilotRadioApi.getMediaplan(autopilot.id);

        this.dispatch.loadMediaplan(mediaplan);
    }

    public async loadTargetGroups(): Promise<void> {
        const { autopilot } = this.getStoreProps();

        const targetGroups = await AutopilotRadioApi.getTargetGroups(autopilot.id);

        this.dispatch.loadTargetGroups(targetGroups);
    }

    public async loadUsersData(): Promise<void> {
        await this.dispatch.loadUserOrganizations(null);
    }

    public async loadDictionaries(): Promise<void> {
        const { user } = this.getStoreProps();

        const userOrganizationId = user.attributes.organizationId;

        const dictionaries = await DictionaryApi.getDictionaryList({
            organizationId: userOrganizationId,
            treeview: true,
        });

        const sortedDictionaries = this.sortDictionaries(dictionaries);

        const groupedDictionaries = this.groupDictionaries(sortedDictionaries);

        if (userOrganizationId === '68a38c6a-79f7-49b5-9356-b779fbbb9b64') {
            // Сбербанк ДМИК
            groupedDictionaries['block'] = groupedDictionaries['block'].filter(
                (item) => item.id === 'e313aac8-aa95-4f45-bd97-52372a38e152',
            ); // Розничный бизнес
        }

        this.dispatch.loadDictionaries(groupedDictionaries);
    }

    private sortDictionaries(dictionaries: PlainDictionary[]): PlainDictionary[] {
        return lodash.sortBy(dictionaries, (item) => item.value);
    }

    private groupDictionaries(dictionaries: PlainDictionary[]): GroupedDictionaries {
        return lodash.groupBy(dictionaries, (item) => item.type);
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        return {
            user: getLoginUser(storeState),
            autopilot: getAutopilot(storeState),
        };
    }
}
