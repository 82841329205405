import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';

import { StoreState } from '@store';
import { getNotificationList } from '@store/common/selectors';
import { resetNotification } from '@store/common/actions';

import { NotificationMessage } from '@store/common/types';
import { NotificationList } from './NotificationList';

interface Props extends Partial<MapProps>, Partial<DispatchProps> {}

interface DispatchProps {
    resetNotification: (key: string) => void;
}

interface MapProps {
    notificationList: { [key: string]: NotificationMessage };
}

@(connect(mapStateToProps, mapDispatchProps) as any)
export class NotificationListContainer extends React.Component<Props> {
    public render(): JSX.Element {
        return React.createElement(NotificationList, {
            notificationList: this.props.notificationList,
            onClose: this.onClose,
        });
    }

    @autobind
    private onClose(key: string): void {
        this.props.resetNotification(key);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        notificationList: getNotificationList(state),
    };
}

function mapDispatchProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            resetNotification,
        },
        dispatch,
    );
}
