import type { CreativeRequestParamDomain } from '../types';
import { Handlers } from '../converters';

import { unsubscribeCreativeRequestParamDomain } from './unsubscribeCreativeRequestParamDomain';

interface Unsubscriber {
    (domain: CreativeRequestParamDomain[], params: { handlers: Handlers }): void;
}

export const unsubscribeCreativeRequestParamDomains: Unsubscriber = (domains, params) => {
    domains.forEach((domain) => unsubscribeCreativeRequestParamDomain(domain, params));
};
