import * as React from 'react';

import { Popup as PopupUI, PopupTheme } from 'sber-marketing-ui';
import { Header } from './Header';
import { Code } from './Code';
import { Separator } from './Separator';
import { Button } from './Button';

import * as style from './styles.scss';

export interface Props {
    link: string;
    onClose: () => void;
}

export const AuthorizationPopup: React.FC<Props> = ({ link, onClose }) => {
    const onClickButton = React.useCallback(() => {
        window.open(link, '_blank');
    }, [link]);

    return (
        <PopupUI theme={PopupTheme.bindActivityTheme}>
            <div className={style.root}>
                <div className={style.headerWrapper}>
                    <Header titleText={'Создание чата'} onCloseButtonClick={onClose} />
                </div>

                <div className={style.content}>
                    <Code link={link} />
                    <Separator />
                    <Button onClick={onClickButton} />
                </div>
            </div>
        </PopupUI>
    );
};
