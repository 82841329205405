import { OptionProps } from '@common/components';

export const mediaRequestOptions: OptionProps<string>[] = [
    {
        title: 'ЛОТ 1',
        value: '1',
    },
    {
        title: 'ЛОТ 2',
        value: '2',
    },
    {
        title: 'Нет креатива по ЛОТам',
        value: 'not',
    },
];
