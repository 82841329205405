import { DifferenceTransformer } from './types';
import { datesDifference } from './DatesDifference';
import { regularDifference } from './RegularDifference';
import { fundsDifference } from './FundsDifference';
import { dictionaryDifference } from './DictionaryDifference';
import { usersDifference } from './UsersDifference';

const DifferenceTransformerMap: {
    [key: string]: DifferenceTransformer;
} = {
    realizationStart: datesDifference,
    realizationEnd: datesDifference,
    activity: regularDifference,
    comment: regularDifference,
    sapComment: regularDifference,
    businessTarget: regularDifference,
    customerName: regularDifference,
    plannedFunds: fundsDifference,
    dictionary: dictionaryDifference,
    responsibles: usersDifference,
};

export { DifferenceTransformerMap };
