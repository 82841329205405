import * as React from 'react';

import { useGetTaskQuery, useSetTaskTagsMutation } from '@api';

import { SkeletonBlock, TagClose, TagProps } from '@common/components';
import { EditableTag } from '@modules/tags/components';

export interface TaskTagsProps extends TagProps {
    taskId: string;
}

export function TaskTags({ taskId, ...props }: TaskTagsProps) {
    const { data, isLoading } = useGetTaskQuery({ id: taskId });
    const [setTaskTags] = useSetTaskTagsMutation();

    if (isLoading) {
        return <SkeletonBlock width={100} height={28} />;
    }

    const handleCloseTask = (tagId: string) => {
        setTaskTags({
            id: taskId,
            tags: data.task.tags.filter((id) => tagId !== id),
        });
    };

    return (
        <>
            {data.task.tags.map((tagId) => (
                <EditableTag data-qa-id="TaskTag" autoPlace="vertical" type="soft" {...props} tagId={tagId} key={tagId}>
                    <TagClose onClick={() => handleCloseTask(tagId)} />
                </EditableTag>
            ))}
        </>
    );
}
