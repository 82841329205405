import * as React from 'react';
import classNames from 'classnames';

import * as style from './RadiobuttonGroup.scss';

import { Icon, IconType } from 'sber-marketing-ui';

export interface RadiobuttonItem<T> {
    title: string;
    value: T;
}

interface Props<T> {
    items: RadiobuttonItem<T>[];
    selectedValue: T;
    disabled?: boolean;
    onSelection: (value: T) => void;
}

export function RadiobuttonGroup<T>({ items, selectedValue, disabled, onSelection }: Props<T>): JSX.Element {
    return (
        <div className={classNames(style.root, disabled && style.disabled)}>
            {items.map((item) => (
                <div
                    className={style.item}
                    key={`${item.title} ${item.value}`}
                    onClick={!disabled ? () => onSelection(item.value) : null}
                >
                    <div className={style.itemIcon}>
                        <Icon
                            type={item.value === selectedValue ? IconType.RADIOBUTTON_CHECKED : IconType.RADIOBUTTON}
                            svgSize={14}
                        />
                    </div>

                    {item.title}
                </div>
            ))}
        </div>
    );
}
