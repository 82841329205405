import * as React from 'react';

import { CreativeRequestTenderDocumentStatus } from '../types';

import { StatusApprovedContent } from './StatusApprovedContent';
import type { StatusApprovedContentProps } from './StatusApprovedContent';

import { StatusWaitingContent } from './StatusWaitingContent';
import type { StatusWaitingContentProps } from './StatusWaitingContent';

import * as styles from './styles.scss';

interface Props extends ContentProps {
    status: CreativeRequestTenderDocumentStatus;
}

type ContentProps = StatusWaitingContentProps & StatusApprovedContentProps;

const contentByStatusMap: Record<CreativeRequestTenderDocumentStatus, React.FC<ContentProps>> = {
    [CreativeRequestTenderDocumentStatus.approved]: StatusApprovedContent,
    [CreativeRequestTenderDocumentStatus.waiting]: StatusWaitingContent,
};

export const Status: React.FC<Props> = ({ status, ...restProps }) => {
    const Content = contentByStatusMap[status];

    return (
        <div className={styles.root}>
            <Content {...restProps} />
        </div>
    );
};
