import * as React from 'react';
import { connect } from 'react-redux';
import { BudgetStatus } from '@mrm/budget';

import { StoreState } from '@store';
import { getBudgetByStatusUserConfig } from '@store/userConfig/budget';

import { WithCorrectionsQuery } from './WithCorrectionsQuery';
import { BudgetCorrectionsBehaviour } from './BudgetCorrectionsBehaviour';

interface Props extends Partial<MapProps> {
    scrollPageToTop: () => void;
}

interface MapProps {
    selectedBudgetId: string;
}
@(connect(mapStateToProps) as any)
export class BudgetCorrections extends React.PureComponent<Props> {
    public render() {
        const { scrollPageToTop, selectedBudgetId } = this.props;

        return (
            <WithCorrectionsQuery budgetId={selectedBudgetId} scrollPageToTop={scrollPageToTop}>
                {(gqlProps) => <BudgetCorrectionsBehaviour {...gqlProps} selectedBudgetId={selectedBudgetId} />}
            </WithCorrectionsQuery>
        );
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { budgetId: selectedBudgetId } = getBudgetByStatusUserConfig(state, BudgetStatus.Execution);

    return {
        selectedBudgetId,
    };
}
