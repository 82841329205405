import * as React from 'react';
import * as moment from 'moment';
import { Link } from 'react-router-dom';

import * as style from './ActivityCard.scss';

// import { ActivityStatus } from 'sber-marketing-types/backend';

import { Preloader } from 'sber-marketing-ui';
import {
    CardBody,
    CardBodyIndicatorColor,
    CardBodyIndicatorPosition,
    CardFooter,
    CardContent,
    CardContentLeftSidebar,
    CardContentRightSidebar,
    CardAuthor,
    CardOrganizationAndBlockSubInfo,
    CardProductSubInfo,
    CardDate,
} from '@common/Card';
import { FavoriteToggle } from './FavoriteToggle';
import { BudgetIds } from './BudgetIds';
import { Title } from './Title';
import classNames from 'classnames';

export const enum StageName {
    PREPARATION = 'Подготовка',
    EXECUTION = 'Проведение',
    RESULTS = 'Итоги',
    COMPLETED = 'Завершен',
}

type ColorStageMap = {
    [P in StageName]: CardBodyIndicatorColor;
};

const colorStageMap: ColorStageMap = {
    [StageName.PREPARATION]: CardBodyIndicatorColor.YELLOW,
    [StageName.EXECUTION]: CardBodyIndicatorColor.GREEN,
    [StageName.RESULTS]: CardBodyIndicatorColor.BLUE,
    [StageName.COMPLETED]: CardBodyIndicatorColor.GRAY,
};

interface Props {
    showPreloader: boolean;
    buttons?: JSX.Element[];
    indicators: JSX.Element[];
    status: string;
    departmentName: string;
    isKey: boolean;
    isLoading: boolean;
    activityId: number;
    activityName: string;
    productName: string;
    organizationName: string;
    author: {
        firstName: string;
        secondName: string;
        organizationName: string;
        departmentName: string;
    };
    start: moment.Moment;
    end: moment.Moment;
    stage: string;
    from?: string;
    canSwitchKey: boolean;
    isFavorite?: boolean;
    isUnseen?: boolean;
    isDropDownOpened?: boolean;
    isOpenedInSidebar: boolean;
    onFavoriteClick?: (event?: React.MouseEvent<HTMLDivElement>) => void;
    onBudgetItemsButtonClick: (event: React.MouseEvent<HTMLSpanElement>) => void;
    onSwitchKeyButtonClick: () => void;
    toggleDropDown?: (event: React.MouseEvent<HTMLDivElement>) => void;
    openActivitySidebar?: (activityId: number) => void;
    groupName?: string;
    blockName: string;
    divisionName: string;
    budgetItemSerialNumbers: number[];
    showBindBudgetIdsButton: boolean;
}

export const ActivityCard = ({
    showPreloader,
    buttons,
    indicators,
    status,
    activityId,
    activityName,
    productName,
    organizationName,
    divisionName,
    blockName,
    isKey,
    isLoading,
    canSwitchKey,
    author,
    start,
    end,
    stage,
    from,
    isUnseen,
    departmentName,
    isFavorite,
    isDropDownOpened,
    isOpenedInSidebar,
    onFavoriteClick,
    onBudgetItemsButtonClick,
    onSwitchKeyButtonClick,
    toggleDropDown,
    budgetItemSerialNumbers,
    showBindBudgetIdsButton,
    openActivitySidebar,
}: Props): JSX.Element => {
    const linkClassNames = classNames([style.openActivityLink, isOpenedInSidebar && style.openActivityLinkSelected]);
    const linkTo = from ? `/activity/${activityId}?from=${encodeURIComponent(from)}` : `/activity/${activityId}`;

    function onLinkClick(event: React.MouseEvent<HTMLAnchorElement>) {
        event.stopPropagation();
    }

    return (
        <div
            className={style.root}
            onClick={() => openActivitySidebar(activityId)}
            {...{
                'qa-id': 'activityCard',
                'qa-activity-id': activityId,
                'qa-activity-name': activityName,
            }}
        >
            <CardBody
                isOpenedInSidebar={isOpenedInSidebar}
                indicator={{
                    position: CardBodyIndicatorPosition.TOP,
                    color: colorStageMap[stage],
                }}
            >
                <CardContent>
                    <CardContentLeftSidebar>
                        <BudgetIds
                            showBindBudgetIdsButton={/*showBindBudgetIdsButton*/ false}
                            budgetItemsSerialNumbers={budgetItemSerialNumbers}
                            onBudgetItemsButtonClick={onBudgetItemsButtonClick}
                        />
                        {organizationName && (
                            <CardOrganizationAndBlockSubInfo
                                text={`${organizationName}${blockName ? `, ${blockName}` : ''}`}
                            />
                        )}
                        {productName && <CardProductSubInfo text={productName} />}
                    </CardContentLeftSidebar>
                    <CardContentRightSidebar>
                        <div className={style.rightSidebarHeader}>
                            <div className={style.cardDatePosition}>
                                <CardDate startDate={start} endDate={end} />
                            </div>
                            <FavoriteToggle onClick={onFavoriteClick} selected={isFavorite} />
                        </div>
                        {author && (
                            <CardAuthor
                                name={`${author.firstName}${author.secondName ? ` ${author.secondName}` : ''}`}
                                subText={`${author.organizationName}${
                                    author.departmentName ? `, ${author.departmentName}` : ''
                                }`}
                            />
                        )}
                    </CardContentRightSidebar>
                </CardContent>

                <div className={style.titleWrapper}>
                    <div className={style.title}>
                        <Title
                            activityName={activityName}
                            isKey={isKey}
                            canSwitchKey={canSwitchKey}
                            onSwitchKeyButtonClick={onSwitchKeyButtonClick}
                        />
                    </div>
                    <Link to={isLoading ? null : linkTo} onClick={onLinkClick} className={linkClassNames}>
                        Открыть проект
                    </Link>
                </div>

                <CardFooter indicators={indicators} buttons={buttons} />

                {(isLoading || showPreloader) && <Preloader />}
            </CardBody>
        </div>
    );
};
