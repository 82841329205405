import * as React from 'react';

import { ActualCostSummaryTemplate } from './ActualCostSummaryTemplate';

interface Props {}

export class ActualCostSummaryBehaviour extends React.PureComponent<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        return React.createElement(ActualCostSummaryTemplate, {});
    }
}
