// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-NewsColumn-OptionalParts-ToggleButton-_ToggleButton__root{padding-top:8px;cursor:pointer;text-align:center;color:#7d7d7d;font-size:14px;line-height:1.43}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/NewsColumn/OptionalParts/ToggleButton/ToggleButton.scss"],"names":[],"mappings":"AAAA,qFACI,eAAgB,CAEhB,cAAe,CACf,iBAAkB,CAElB,aAAc,CAEd,cAAe,CACf,gBAAiB","sourcesContent":[".root {\n    padding-top: 8px;\n\n    cursor: pointer;\n    text-align: center;\n\n    color: #7d7d7d;\n\n    font-size: 14px;\n    line-height: 1.43;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-NewsColumn-OptionalParts-ToggleButton-_ToggleButton__root"
};
export default ___CSS_LOADER_EXPORT___;
