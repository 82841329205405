import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { IconType } from 'sber-marketing-ui';
import type { CreativeStageForm } from '@store/autopilot_legacy/types';

import { CreativeStageWidgetTemplate, CreativeItemProps } from './CreativeStageWidgetTemplate';
import type { StoreState } from '@store';
import { setCreativeFormValues } from '@store/autopilot_legacy/actions';
import { getCreativeStageForm } from '@store/autopilot_legacy/selectors';

interface Props extends MapProps, DispatchProps {
    readOnly?: boolean;
}

interface MapProps {
    creativeStageForm?: CreativeStageForm;
}

interface DispatchProps {
    setCreativeFormValues?: (values: Partial<CreativeStageForm>) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class CreativeStageWidgetBehaviour extends React.PureComponent<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        const { creativeStageForm } = this.props;

        const counter = [
            creativeStageForm.vkUrl,
            // creativeStageForm.facebookUrl,
            creativeStageForm.okUrl,
            // creativeStageForm.youtubeUrl
        ].filter((item) => !lodash.isEmpty(item)).length;

        return React.createElement(CreativeStageWidgetTemplate, {
            creativeItems: this.makeCreativeItems(),
            counter,
        });
    }

    @autobind
    protected onVkUrlChange(value: string): void {
        this.props.setCreativeFormValues({ vkUrl: value });
    }

    @autobind
    protected onFbUrlChange(value: string): void {
        this.props.setCreativeFormValues({ facebookUrl: value });
    }

    @autobind
    protected onOkUrlChange(value: string): void {
        this.props.setCreativeFormValues({ okUrl: value });
    }

    @autobind
    protected onYoutubeUrlChange(value: string): void {
        this.props.setCreativeFormValues({ youtubeUrl: value });
    }

    private makeCreativeItems(): CreativeItemProps[] {
        const { creativeStageForm, readOnly } = this.props;

        if (!creativeStageForm) {
            return [];
        }

        const { vkUrl, facebookUrl, okUrl, youtubeUrl } = creativeStageForm;

        return [
            {
                readOnly,
                name: 'VK',
                icon: IconType.SOCIAL_VK,
                value: vkUrl,
                onValueChange: this.onVkUrlChange,
            },
            // {
            //     readOnly,
            //     name: 'Facebook',
            //     icon: IconType.SOCIAL_FB,
            //     value: facebookUrl,
            //     onValueChange: this.onFbUrlChange
            // },
            {
                readOnly,
                name: 'Одноклассники',
                icon: IconType.SOCIAL_OK,
                value: okUrl,
                onValueChange: this.onOkUrlChange,
            },
            // {
            //     readOnly,
            //     name: 'YouTube',
            //     icon: IconType.SOCIAL_YOUTUBE,
            //     value: youtubeUrl,
            //     onValueChange: this.onYoutubeUrlChange
            // }
        ];
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        creativeStageForm: getCreativeStageForm(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setCreativeFormValues,
        },
        dispatch,
    );
}
