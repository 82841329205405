import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';

import { StagesListWidgetTemplate, StageProps } from './StagesListWidgetTemplate';
import type { Author } from './WithStagesListWidget';

import type { StoreState } from '@store';
import { getLoginUser } from '@store/user/selector';
import { setCurrentStage, setSelectedStage } from '@store/creative/actions';
import { getSelectedStage } from '@store/creative/selectors';

interface Props extends Partial<MapProps & DispatchProps> {
    author: Author;
    currentStage: number;
    sendToExecutor: () => Promise<void>;
    sendToCustomer: () => Promise<void>;
    sendToExpert: () => Promise<void>;
}

interface MapProps {
    selectedStage: number;
    currentUserName?: string;
}

interface DispatchProps {
    setCurrentStage?: (stage: number) => void;
    setSelectedStage?: (stage: number) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class StagesListWidgetBehaviour extends React.PureComponent<Props> {
    public render(): JSX.Element {
        return React.createElement(StagesListWidgetTemplate, {
            stages: this.makeStages(),
        });
    }

    @autobind
    protected async onStageClick(stageOrder: number) {
        this.props.setSelectedStage(stageOrder);
    }

    private makeStages(): StageProps[] {
        const { currentStage, selectedStage, author } = this.props;

        return [
            {
                stageOrder: 1,
                name: currentStage > 1 ? 'Форма заявки' : 'Создание заявки',
                title: `${author?.firstName} ${author?.secondName}`,
                isSelected: selectedStage == 1,
                isActive: currentStage == 1,
                isFinished: currentStage > 1,
                onClick: this.onStageClick,
            },
            {
                stageOrder: 2,
                name: currentStage > 1.5 ? 'Заполнена' : 'Заполнение',
                title:
                    currentStage < 1.5
                        ? 'Заказчик (в ожидании)'
                        : currentStage == 1.5
                        ? 'Заказчик (в работе)'
                        : 'Заказчик',
                isSelected: selectedStage == 1.5,
                isActive: currentStage == 1.5,
                isFinished: currentStage > 1.5,
                onClick: this.onStageClick,
            },
            {
                stageOrder: 3,
                name: currentStage > 2 ? 'Заполнена' : 'Заполнение',
                title:
                    currentStage < 2
                        ? 'Агентство (в ожидании)'
                        : currentStage == 2
                        ? 'Агентство (в работе)'
                        : 'Агентство',
                isSelected: selectedStage == 2,
                isActive: currentStage == 2,
                isFinished: currentStage > 2,
                onClick: this.onStageClick,
            },
            {
                stageOrder: 4,
                name: currentStage > 3 ? 'Проверена' : 'Проверка',
                title:
                    currentStage < 3 ? 'Заказчик (в ожидании)' : currentStage == 3 ? 'Заказчик (в работе)' : 'Заказчик',
                isSelected: selectedStage == 3,
                isActive: currentStage == 3,
                isFinished: currentStage > 3,
                onClick: this.onStageClick,
            },
            {
                stageOrder: 5,
                name: currentStage > 4 ? 'Строки и акты согласованы' : 'Согласование строк и актов',
                title:
                    currentStage < 4
                        ? 'Финансисты (в ожидании)'
                        : currentStage == 4
                        ? 'Финансисты (в работе)'
                        : 'Финансисты',
                isSelected: selectedStage == 4,
                isActive: currentStage == 4,
                isFinished: currentStage > 4,
                onClick: this.onStageClick,
            },
            {
                stageOrder: 6,
                name: 'Заявка закрыта',
                title: currentStage < 5 ? 'В ожидании' : 'Готова',
                isSelected: selectedStage == 5,
                isActive: currentStage == 5,
                isFinished: currentStage == 5,
                onClick: this.onStageClick,
            },
        ];
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const currentUser = getLoginUser(state);
    const selectedStage = getSelectedStage(state);

    const { firstName, secondName } = currentUser.attributes;

    return {
        currentUserName: `${firstName} ${secondName}`,
        selectedStage,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setCurrentStage,
            setSelectedStage,
        },
        dispatch,
    );
}
