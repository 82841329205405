// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-Sidebar-widgets-Files-DownloadAllButton-_DownloadAllButton__button,.src-client-modules-activity-pages-creative-Sidebar-widgets-Files-DownloadAllButton-_DownloadAllButton__buttonClickable{display:flex;color:#7E8681}.src-client-modules-activity-pages-creative-Sidebar-widgets-Files-DownloadAllButton-_DownloadAllButton__buttonClickable{cursor:pointer}.src-client-modules-activity-pages-creative-Sidebar-widgets-Files-DownloadAllButton-_DownloadAllButton__buttonClickable:hover{color:black}.src-client-modules-activity-pages-creative-Sidebar-widgets-Files-DownloadAllButton-_DownloadAllButton__flex{display:flex;gap:15px}.src-client-modules-activity-pages-creative-Sidebar-widgets-Files-DownloadAllButton-_DownloadAllButton__title{color:#7E8681;font-family:'Open Sans';font-weight:400;font-size:12px;cursor:pointer}.src-client-modules-activity-pages-creative-Sidebar-widgets-Files-DownloadAllButton-_DownloadAllButton__title:hover{color:black}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/Sidebar/widgets/Files/DownloadAllButton/DownloadAllButton.scss"],"names":[],"mappings":"AAAA,uOACI,YAAa,CAEb,aAAc,CACjB,wHAKG,cAAe,CAHnB,8HAMQ,WAAY,CACf,6GAID,YAAa,CACb,QAAS,CACZ,8GAGG,aAAc,CACd,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CAEf,cAAe,CANnB,oHASQ,WAAY","sourcesContent":[".button {\n    display: flex;\n\n    color: #7E8681;\n}\n\n.buttonClickable {\n    @extend .button;\n\n    cursor: pointer;\n\n    &:hover {\n        color: black;\n    }\n}\n\n.flex {\n    display: flex;\n    gap: 15px;\n}\n\n.title {\n    color: #7E8681;\n    font-family: 'Open Sans';\n    font-weight: 400;\n    font-size: 12px;\n\n    cursor: pointer;\n\n    &:hover {\n        color: black;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "src-client-modules-activity-pages-creative-Sidebar-widgets-Files-DownloadAllButton-_DownloadAllButton__button",
	"buttonClickable": "src-client-modules-activity-pages-creative-Sidebar-widgets-Files-DownloadAllButton-_DownloadAllButton__buttonClickable",
	"flex": "src-client-modules-activity-pages-creative-Sidebar-widgets-Files-DownloadAllButton-_DownloadAllButton__flex",
	"title": "src-client-modules-activity-pages-creative-Sidebar-widgets-Files-DownloadAllButton-_DownloadAllButton__title"
};
export default ___CSS_LOADER_EXPORT___;
