import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { BudgetStatus } from '@mrm/budget';
import { sortBy } from 'lodash';

import { BudgetApi } from '@api';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';
import { LoadingStatus } from '@store/commonTypes';
import { saveBudgetByStatusUserConfig, getBudgetByStatusUserConfig } from '@store/userConfig/budget';

import { Utils } from '@common/Utils';

import * as asyncActions from './actions/async';
import * as syncActions from './actions/sync';

import { getBudgetState } from './selectors';

export const loadBudgets = bindThunkAction<StoreState, BudgetStatus, void, Error>(
    asyncActions.loadBudgets,
    async (budgetStatus, dispatch, getState) => {
        const state = getState();
        const { loadingStatus } = getBudgetState(state, budgetStatus);
        const { budgetId: previouslySelectedBudgetId } = getBudgetByStatusUserConfig(state, budgetStatus);

        if (loadingStatus === LoadingStatus.NOT_LOADED) {
            const currentYear = new Date(Date.now()).getFullYear();
            const {
                attributes: { organizationId: currentUserOrganizationId },
            } = getLoginUser(state);

            const combinedStatuses: Partial<Record<BudgetStatus, BudgetStatus[]>> = {
                [BudgetStatus.Plan]: [BudgetStatus.Plan, BudgetStatus.ArchivePlan],
                [BudgetStatus.Execution]: [BudgetStatus.Execution, BudgetStatus.ArchiveExecution],
            };

            const [activeBudgets, archiveBudgets] = await Promise.all(
                combinedStatuses[budgetStatus].map((status) => BudgetApi.getBudgetList({ status })),
            );

            const budgets = sortBy([...activeBudgets, ...archiveBudgets], (budget) => budget.year);

            const selectedBudgetId =
                previouslySelectedBudgetId ||
                Utils.withErrorHandler<string>(
                    () =>
                        budgets.find(
                            (budget) =>
                                budget.year === currentYear &&
                                budget.dictionaryOrganizationId === currentUserOrganizationId,
                        ).id,
                );
            if (!budgets.some((budget) => budget.id === selectedBudgetId)) {
                dispatch(
                    saveBudgetByStatusUserConfig({
                        budgetStatus,
                        payload: {
                            budgetId: null,
                        },
                    }),
                );
            }

            dispatch(
                syncActions.loadBudgetState({
                    status: budgetStatus,
                    state: {
                        loadingStatus: LoadingStatus.LOADED,
                        budgets,
                    },
                }),
            );
            dispatch(
                saveBudgetByStatusUserConfig({
                    budgetStatus,
                    payload: {
                        budgetId: selectedBudgetId,
                    },
                }),
            );
        }
    },
);
