// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-StageWidgets-RequestStageWidget-BudgetId-_BudgetId__root{user-select:none}.src-client-modules-activity-pages-creative-StageWidgets-RequestStageWidget-BudgetId-_BudgetId__title{color:#000000;font-weight:600;font-family:'Open Sans';font-size:16px;line-height:22px;margin-bottom:16px}.src-client-modules-activity-pages-creative-StageWidgets-RequestStageWidget-BudgetId-_BudgetId__items{display:block}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/StageWidgets/RequestStageWidget/BudgetId/BudgetId.scss"],"names":[],"mappings":"AAAA,qGACI,gBAAiB,CACpB,sGAGG,aAAc,CACd,eAAgB,CAChB,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACjB,kBAAmB,CACtB,sGAGG,aAAc","sourcesContent":[".root {\n    user-select: none;\n}\n\n.title {\n    color: #000000;\n    font-weight: 600;\n    font-family: 'Open Sans';\n    font-size: 16px;\n    line-height: 22px;\n    margin-bottom: 16px;\n}\n\n.items {\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-StageWidgets-RequestStageWidget-BudgetId-_BudgetId__root",
	"title": "src-client-modules-activity-pages-creative-StageWidgets-RequestStageWidget-BudgetId-_BudgetId__title",
	"items": "src-client-modules-activity-pages-creative-StageWidgets-RequestStageWidget-BudgetId-_BudgetId__items"
};
export default ___CSS_LOADER_EXPORT___;
