import { createSelector } from 'reselect';
import { DepartmentAttributes } from 'sber-marketing-types/frontend';

import { AvailableDepartments } from './types';
import { getTaskData } from '../selectors';

export const getAvailableDepartmentsState = createSelector(
    getTaskData,
    ({ availableDepartments }): AvailableDepartments => availableDepartments,
);

export const getIsAvailableDepartmentsLoading = createSelector(
    getAvailableDepartmentsState,
    ({ isLoading }): boolean => isLoading,
);

export const getAvailableDepartments = createSelector(
    getAvailableDepartmentsState,
    ({ departments }): DepartmentAttributes[] => departments,
);
