import * as React from 'react';

import { DisapproveButton } from './DisapproveButton';
import { SuccessIndicator } from './SuccessIndicator';

import * as styles from './styles.scss';

export interface Props {
    disabled: boolean;
    onDisapprove: () => void;
}

export const StatusApprovedContent: React.FC<Props> = ({ disabled, onDisapprove }) => {
    const [visibilityDisapproveButton, setVisibilityDisapproveButton] = React.useState(false);

    const onMouseEnter = React.useCallback(() => {
        if (!disabled) {
            setVisibilityDisapproveButton(true);
        }
    }, [disabled, setVisibilityDisapproveButton]);

    const onMouseLeave = React.useCallback(() => {
        if (!disabled) {
            setVisibilityDisapproveButton(false);
        }
    }, [disabled, setVisibilityDisapproveButton]);

    return (
        <div className={styles.root} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {visibilityDisapproveButton ? <DisapproveButton onClick={onDisapprove} /> : <SuccessIndicator />}
        </div>
    );
};
