import * as React from 'react';
import { CorrectionType } from '@mrm/budget';

import { Menu, Params, SerialNumber, Transition, Body } from '../common';

import * as style from './PlanCorrectionCard.scss';
import { PlanFundsTransferCorrection } from '../../../types';
import { Money, MoneyFormatter } from '@common/Utils';
import { PlanCorrectionCard as PlanCorrectionCardTooltip } from '../CardTypes';

interface Props {
    correction: PlanFundsTransferCorrection;
    donor: Participant;
    acceptor: Participant;
    onCardMouseEnter: () => void;
    onCardMouseLeave: () => void;
    onChange: () => void;
}

export interface Participant {
    budgetItemSerialNumber: number;
    activityName: string;
    value: number;
}

export const PlanCorrectionCard: React.FC<Props> = ({
    correction,
    donor,
    acceptor,
    onCardMouseEnter,
    onCardMouseLeave,
    onChange,
}) => {
    return (
        <Body
            qaId="dashboardRightSidebarBudgetInfoPlanCorrectionCard"
            qaCorrectionType={CorrectionType.PlanFundsTransfer}
            tooltipComponent={<PlanCorrectionCardTooltip {...correction} />}
            onMouseEnter={onCardMouseEnter}
            onMouseLeave={onCardMouseLeave}
        >
            <div className={style.header}>
                <SerialNumber>{correction.serialNumber}</SerialNumber>
                <div className={style.title}>Корректировка плана</div>
                <Menu
                    status={correction.status}
                    correctionId={correction.id}
                    correctionAuthorId={correction.author.id}
                    onChange={onChange}
                />
            </div>
            <Params
                data={[
                    {
                        name: 'ID',
                        value: <Transition from={donor.budgetItemSerialNumber} to={acceptor.budgetItemSerialNumber} />,
                    },
                    {
                        name: 'Название проекта',
                        value: <Transition from={donor.activityName} to={acceptor.activityName} />,
                    },
                    {
                        name: 'План по строке',
                        value: (
                            <Transition
                                from={<span className={style.negativeValue}>{formatMoneyWithSign(donor.value)}</span>}
                                to={<span className={style.positiveValue}>{formatMoneyWithSign(acceptor.value)}</span>}
                            />
                        ),
                    },
                ]}
            />
        </Body>
    );
};

function formatMoneyWithSign(money: number): string {
    return MoneyFormatter.toThousands(Money.fromCopecks(money), { forceSign: true });
}
