// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-CurrentRowSumInputs-TitleRow-SumHelper-_SumHelper__planReserveDiff{font-family:'Open Sans';font-size:12px;line-height:16px;letter-spacing:0.15px;color:#000000}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-CurrentRowSumInputs-TitleRow-SumHelper-_SumHelper__planReserveDiffBold{font-weight:600}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/InternalTransitionSumEntering/CurrentRowSumInputs/TitleRow/SumHelper/SumHelper.scss"],"names":[],"mappings":"AAAA,0KACI,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACjB,qBAAsB,CAEtB,aAAc,CACjB,8KAGG,eAAgB","sourcesContent":[".planReserveDiff {\n    font-family: 'Open Sans';\n    font-size: 12px;\n    line-height: 16px;\n    letter-spacing: 0.15px;\n\n    color: #000000;\n}\n\n.planReserveDiffBold {\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"planReserveDiff": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-CurrentRowSumInputs-TitleRow-SumHelper-_SumHelper__planReserveDiff",
	"planReserveDiffBold": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-CurrentRowSumInputs-TitleRow-SumHelper-_SumHelper__planReserveDiffBold"
};
export default ___CSS_LOADER_EXPORT___;
