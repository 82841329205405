import * as React from 'react';
import { LabeledInput, InputType } from 'sber-marketing-ui';
import { FieldValue, FieldProperties } from 'sber-marketing-types/frontend';

import { FieldInput } from '@store/brief/types';

import * as style from './UnlockableInput.scss';

const DEFAULT_VALIDATION_MESSAGE = 'Обязательное поле';

export interface UnlockableInputProps extends FieldProperties {
    index?: number;
    value?: FieldValue;
    displayError?: boolean;
    disabled?: boolean;
    onValueChange: (value: FieldInput) => void;
}

export function UnlockableInput({
    index,
    value = {},
    name,
    validationMessage,
    displayError,
    onValueChange,
    disabled,
}: UnlockableInputProps): JSX.Element {
    const { text, unlocked } = value;

    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'briefBlockField',
                'qa-index': index,
                'qa-field-type': 'UnlockableInput',
            }}
        >
            <LabeledInput
                qaId="briefBlockUnlockableInputFieldInput"
                value={{ text, unlocked }}
                title={name || 'Новое поле'}
                errorMessage={validationMessage || DEFAULT_VALIDATION_MESSAGE}
                displayError={displayError}
                type={InputType.Unlockable}
                onValueChange={(value) => onValueChange(value)}
                disabled={disabled}
            />
        </div>
    );
}
