import { createSelector } from 'reselect';
import { intersection, includes, sortBy } from 'lodash';

import { getTaskPageState } from './lib';
import { StoreState } from '../../';
import { TaskPageState, WorkType } from '../types';

export const getWorkTypeById = createSelector(
    getTaskPageState,
    (state: StoreState, id: string): string => id,
    ({ workTypes }: TaskPageState, id: string): WorkType => workTypes[id],
);

export const getWorkTypesByIds = createSelector(
    getTaskPageState,
    (state: StoreState, ids: string[]): string[] => ids,
    ({ workTypes }: TaskPageState, ids: string[]): WorkType[] => ids.map((id) => workTypes[id]),
);

export const getWorkTypesByDepartmentsIds = createSelector(
    getTaskPageState,
    (state: StoreState, departmentsIds: string[]): string[] => departmentsIds,
    ({ workTypes }: TaskPageState, ids: string[]): WorkType[] =>
        Object.keys(workTypes)
            .map((id) => workTypes[id])
            .filter(({ departmentIds }) => intersection(ids, departmentIds).length > 0),
);

export const getWorkTypesByDepartmentId = createSelector(
    getTaskPageState,
    (state: StoreState, departmentId: string): string => departmentId,
    ({ workTypes }: TaskPageState, departmentId: string): WorkType[] =>
        Object.keys(workTypes)
            .map((id) => workTypes[id])
            .filter(({ departmentIds }) => includes(departmentIds, departmentId)),
);

export const getWorkTypesByOrganizationsIds = createSelector(
    getTaskPageState,
    (state: StoreState, organizationsIds: string[]): string[] => organizationsIds,
    ({ workTypes }: TaskPageState, organizationsIds: string[]): WorkType[] =>
        Object.keys(workTypes)
            .map((id) => workTypes[id])
            .filter(({ organizationId }) => includes(organizationsIds, organizationId)),
);

export const getWorkTypesByOrganizationId = createSelector(
    getTaskPageState,
    (state: StoreState, organizationId: string): string => organizationId,
    ({ workTypes }: TaskPageState, orgId: string): WorkType[] =>
        Object.keys(workTypes)
            .map((id) => workTypes[id])
            .filter(({ organizationId }) => orgId === organizationId),
);

export const getSortedWorkTypes = createSelector(
    getTaskPageState,
    (state: StoreState, order: 'asc' | 'desc' = 'asc'): 'asc' | 'desc' => order,
    ({ workTypes }: TaskPageState, order: 'asc' | 'desc'): WorkType[] => {
        const result: WorkType[] = sortBy(
            Object.keys(workTypes).map((id) => workTypes[id]),
            ['name'],
        );
        if (order === 'desc') {
            result.reverse();
        }
        return result;
    },
);
