import * as React from 'react';
import autobind from 'autobind-decorator';
import gql from 'graphql-tag';
import { DataProps, graphql } from 'react-apollo';

import { ActivityContent } from './types';

const GET_ACTIVITY_CONTENT_QUERY = gql`
    query getContent($projectId: Int!) {
        project: getProjects(filter: { id: $projectId }) {
            nodes {
                name
                description
                createTime
                preparation
                debriefing
                realizationStart
                realizationEnd
                product {
                    name
                    organizationId
                }
                division {
                    id
                    name
                }
                type {
                    name
                }
                author {
                    id
                    firstName
                    secondName
                    departmentName
                }
                responsible {
                    id
                    firstName
                    secondName
                    email
                    departmentName
                }
                participants {
                    id
                    firstName
                    secondName
                    departmentName
                }
                tasks {
                    id
                    title
                    deadline
                }
            }
        }
    }
`;

interface Props extends ExternalProps, QueryProps {}

interface ExternalProps {
    activityId: number;
    forwardContent(content: ActivityContent, refetch: () => void): void;
}

type QueryProps = DataProps<{
    project: {
        nodes: ActivityContent[];
    };
}>;

class ActivityContentFetcherBehaviour extends React.PureComponent<Props> {
    public componentDidUpdate(prevProps: Props): void {
        const shouldForward = prevProps.data.loading && !this.props.data.loading;

        if (shouldForward) {
            this.checkAndForwardResponse();
        }
    }

    public render(): JSX.Element {
        return null; // component used only for fetching side-effect
    }

    private checkAndForwardResponse(): void {
        const receivedContent = this.props.data?.project?.nodes?.[0];

        if (receivedContent) {
            const data = {
                name: receivedContent.name,
                description: receivedContent.description,
                createTime: receivedContent.createTime,
                preparation: receivedContent.preparation,
                debriefing: receivedContent.debriefing,
                realizationStart: receivedContent.realizationStart,
                realizationEnd: receivedContent.realizationEnd,
                product: receivedContent.product,
                division: receivedContent.division,
                author: receivedContent.author,
                responsible: receivedContent.responsible,
                participants: receivedContent.participants,
                type: receivedContent.type,
                tasks: receivedContent.tasks,
            };

            this.props.forwardContent(data, this.refetch);
        }
    }

    @autobind
    private refetch() {
        this.props.forwardContent(null, this.refetch);
        this.props.data.refetch();
    }
}

export const ActivityContentFetcher = graphql<ExternalProps>(GET_ACTIVITY_CONTENT_QUERY, {
    options: ({ activityId }) => ({
        variables: {
            projectId: activityId,
        },
        fetchPolicy: 'no-cache',
    }),
})(ActivityContentFetcherBehaviour);
