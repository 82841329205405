export enum DataUpdateStrategy {
    Immediate = 'Immediate',
    OnDemand = 'OnDemand',
}

export enum ComponentState {
    Default = 'Default',
    TagsPreviewOpened = 'TagsPreviewOpened',
    TagEditorOpened = 'TagEditorOpened',
    CreateNewTagPopup = 'CreateNewTagPopup',
}
