import * as React from 'react';
import classnames from 'classnames';

import { ColumnName } from '@store/budgetExecution';

import { Money, Captions, MoneyFormatter } from '@common/Utils';

import * as styles from './Balance.scss';

export const MonthNames = {
    [ColumnName.PlanJan]: 'Январь',
    [ColumnName.PlanFeb]: 'Февраль',
    [ColumnName.PlanMar]: 'Март',
    [ColumnName.PlanApr]: 'Апрель',
    [ColumnName.PlanMay]: 'Май',
    [ColumnName.PlanJun]: 'Июнь',
    [ColumnName.PlanJul]: 'Июль',
    [ColumnName.PlanAug]: 'Август',
    [ColumnName.PlanSep]: 'Сентябрь',
    [ColumnName.PlanOct]: 'Октябрь',
    [ColumnName.PlanNov]: 'Ноябрь',
    [ColumnName.PlanDec]: 'Декабрь',
};

interface Props {
    qaId: string;
    title: JSX.Element;
    transferAmount: number;
    subtitle: JSX.Element;
}

export function Balance({ qaId, title, transferAmount, subtitle }: Props): JSX.Element {
    return (
        <div
            className={styles.root}
            {...{
                'qa-id': `internalTransitionSumEnteringBalance ${qaId}`,
            }}
        >
            {title}

            <div
                className={styles.sum}
                {...{
                    'qa-id': 'internalTransitionSumEnteringBalanceSum',
                }}
            >
                {MoneyFormatter.toRoubles(Money.fromCopecks(transferAmount), { hideCaption: true })}
                &nbsp;
                <span className={styles.roubleSign}>{Captions.Roubles}</span>
            </div>

            {subtitle}
        </div>
    );
}

interface YearPlanReserveDiffProps {
    diff: number;
}

export function YearPlanReserveDiff({ diff }: YearPlanReserveDiffProps): JSX.Element {
    return (
        <div className={styles.subtitle}>
            План-Резерв за год станет:&nbsp;
            <span
                className={classnames(diff < 0 ? styles.subtitleRed : styles.titleBlack)}
                {...{
                    'qa-id': 'internalTransitionSumEnteringYearPlanReserveDiff',
                }}
            >
                {MoneyFormatter.toMostAppropriate(Money.fromCopecks(diff))}
            </span>
        </div>
    );
}

interface PlanWithPlanReserveDiffProps {
    planDiff: number;
    planReserveDiff: number;
}

export function PlanWithPlanReserveDiff({ planDiff, planReserveDiff }: PlanWithPlanReserveDiffProps): JSX.Element {
    return (
        <div className={classnames(styles.subtitle, styles.subtitleColumn)}>
            <span
                className={styles.subtitlePlan}
                {...{
                    'qa-id': 'internalTransitionSumEnteringPlanWIthPlanReserveDiffPlan',
                }}
            >
                План:&nbsp;
                <span className={styles.titleBlack}>
                    {MoneyFormatter.toMostAppropriate(Money.fromCopecks(planDiff))}
                </span>
            </span>

            <span>
                План-Резерв:&nbsp;
                <span
                    className={classnames(planReserveDiff < 0 ? styles.subtitleRed : styles.titleBlack)}
                    {...{
                        'qa-id': 'internalTransitionSumEnteringPlanWIthPlanReserveDiffPlanReserveDiff',
                    }}
                >
                    {MoneyFormatter.toMostAppropriate(Money.fromCopecks(planReserveDiff))}
                </span>
            </span>
        </div>
    );
}
