// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-components-TelegramButton-ErrorPopup-Separator-_styles__root{position:relative;font-family:\"Open Sans\";font-size:14px;letter-spacing:0.15px;color:#7E8681;display:flex;justify-content:center;align-items:center}.src-client-modules-activity-pages-task-components-TelegramButton-ErrorPopup-Separator-_styles__root:after{content:' ';position:absolute;top:calc(50% - 1px);display:block;background-color:#E3E9E5;height:1px;width:100%;z-index:0}.src-client-modules-activity-pages-task-components-TelegramButton-ErrorPopup-Separator-_styles__text{position:relative;z-index:1;padding:0 12px;margin-top:-3px;background-color:#ffffff}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/components/TelegramButton/ErrorPopup/Separator/styles.scss"],"names":[],"mappings":"AAAA,qGACI,iBAAkB,CAElB,uBAAwB,CACxB,cAAe,CACf,qBAAsB,CACtB,aAAc,CAEd,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CAVvB,2GAaQ,WAAY,CACZ,iBAAkB,CAClB,mBAAoB,CACpB,aAAc,CACd,wBAAyB,CACzB,UAAW,CACX,UAAW,CACX,SAAU,CACb,qGAID,iBAAkB,CAClB,SAAU,CAEV,cAAe,CACf,eAAgB,CAEhB,wBAAyB","sourcesContent":[".root {\n    position: relative;\n\n    font-family: \"Open Sans\";\n    font-size: 14px;\n    letter-spacing: 0.15px;\n    color: #7E8681;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    &:after {\n        content: ' ';\n        position: absolute;\n        top: calc(50% - 1px);\n        display: block;\n        background-color: #E3E9E5;\n        height: 1px;\n        width: 100%;\n        z-index: 0;\n    }\n}\n\n.text {\n    position: relative;\n    z-index: 1;\n\n    padding: 0 12px;\n    margin-top: -3px;\n\n    background-color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-components-TelegramButton-ErrorPopup-Separator-_styles__root",
	"text": "src-client-modules-activity-pages-task-components-TelegramButton-ErrorPopup-Separator-_styles__text"
};
export default ___CSS_LOADER_EXPORT___;
