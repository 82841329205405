/* tslint:disable:max-file-line-count */
import type { ActivityBudget, BudgetItem, Correction, Budget } from '@mrm/budget';
import { CorrectionType, Month } from '@mrm/budget';
import type { PlainDictionary } from '@mrm/dictionary';
import { DictionaryType } from '@mrm/dictionary';
import { Tag } from '@mrm/tags';
import type { UserResponseParams } from 'sber-marketing-types/frontend';
import { LinkedList } from './lib/LinkedList';
import { Dictionary } from 'lodash';

import { MultiReferenceDictionaryApi } from '@api';
import { LoadingStatus } from '@store/commonTypes';

import { LineCellsParams } from '@modules/budget/BudgetPage/BudgetExecution/Table/LayerManager/LayerManager';
import { DropdownCellOptions } from 'client/modules/budget/BudgetPage/BudgetExecution/Table/CellTypes/DropdownCell/DropdownCell';

import { BudgetTransferMenuState } from './budgetTransferMenu';
import { LineModalState } from './lineModal';
import { TransferBudgetItemsToPlanningMenuState } from './transferBudgetItemsToPlanningMenu';
import { ActivityReferenceMenuState } from './activityReferenceMenu';
import { CreativeReferenceMenuState } from './creativeReferenceMenu';
import { MiscBudgetItemsState } from './miscBudgetItems';

export interface BudgetExecutionPageState {
    budgetTransferMenuState: BudgetTransferMenuState;
    lineModal: LineModalState;
    transferBudgetItemsToPlannningMenu: TransferBudgetItemsToPlanningMenuState;
    miscBudgetItems: MiscBudgetItemsState;
    activityReferenceMenu: ActivityReferenceMenuState;
    creativeReferenceMenu: CreativeReferenceMenuState;
    restState: PageState;
}

export interface PageState {
    pageData?: PageData;
    computedData?: ComputedData;
    pageFilters?: PageFilters;
    activityReferenceMenuVisibility: ActivityReferenceMenuVisibility;
    creativeReferenceMenuVisibility: CreativeReferenceMenuVisibility;
    columnsWidth?: ColumnsWidth;
    resizingColumnName?: ColumnName;
    hoveredLineId?: string;
    hoveredColumnName?: ColumnName;
    unsavedChanges?: ChangeList;
    changesHistory?: LinkedList<UnsavedChange[]>;
    currentChangePosition?: number;
    fixedColumnsNames?: ColumnName[];
    correctionPopup?: CorrectionPopup;
    preloader?: boolean;
    columnFilters: {
        filters: Filters;
        loadingStatus: ColumnFiltersLoadingStatus;
    };
    previouslyLoadedFilters: Filters;
    loadingFiltersCount: number;
    showTagsHaveChangedMarker: boolean;
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi;
}

export type FilterKey = ColumnName;
export type AppliedFiltersNames = FilterKey[];
export type ColumnFiltersLoadingStatus = { [columnName: string]: LoadingStatus };

export { LoadingStatus };

export const enum ColumnName {
    Comment = 'comment',
    Id = 'id',
    DonorIds = 'donorIds',
    Regionality = 'regionality',
    RegionalityCode = 'regionalityCode',
    ActivityName = 'activityName',
    Direction = 'direction',
    DirectionCode = 'directionCode',
    Tool = 'tool',
    ToolCode = 'toolCode',
    SapComment = 'sapComment',
    Block = 'block',
    BlockCode = 'blockCode',
    Division = 'division',
    DivisionCode = 'divisionCode',
    CostCenter = 'costCenter',
    CostCenterCode = 'costCenterCode',
    ActivityType = 'activityType',
    ActivityTypeCode = 'activityTypeCode',
    Channel = 'channel',
    ChannelCode = 'channelCode',
    LocationDriver = 'locationDriver',
    LocationDriverCode = 'locationDriverCode',
    Item = 'item',
    Resource = 'resource',
    ResourceCode = 'resourceCode',
    Responsible = 'responsible',
    Segment = 'segment',
    SegmentCode = 'segmentCode',
    Product = 'product',
    ProductCode = 'productCode',
    Territory = 'territory',
    TerritoryCode = 'territoryCode',
    StartDate = 'startDate',
    EndDate = 'endDate',
    LastYearFact = 'lastYearFact',
    PlanQuarter1 = 'planQuarter1',
    PlanQuarter2 = 'planQuarter2',
    PlanQuarter3 = 'planQuarter3',
    PlanQuarter4 = 'planQuarter4',
    ReserveQuarter1 = 'reserveQuarter1',
    ReserveQuarter2 = 'reserveQuarter2',
    ReserveQuarter3 = 'reserveQuarter3',
    ReserveQuarter4 = 'reserveQuarter4',
    FactQuarter1 = 'factQuarter1',
    FactQuarter2 = 'factQuarter2',
    FactQuarter3 = 'factQuarter3',
    FactQuarter4 = 'factQuarter4',
    PlanJan = 'planJan',
    PlanFeb = 'planFeb',
    PlanMar = 'planMar',
    PlanApr = 'planApr',
    PlanMay = 'planMay',
    PlanJun = 'planJun',
    PlanJul = 'planJul',
    PlanAug = 'planAug',
    PlanSep = 'planSep',
    PlanOct = 'planOct',
    PlanNov = 'planNov',
    PlanDec = 'planDec',
    ReserveJan = 'reserveJan',
    ReserveFeb = 'reserveFeb',
    ReserveMar = 'reserveMar',
    ReserveApr = 'reserveApr',
    ReserveMay = 'reserveMay',
    ReserveJun = 'reserveJun',
    ReserveJul = 'reserveJul',
    ReserveAug = 'reserveAug',
    ReserveSep = 'reserveSep',
    ReserveOct = 'reserveOct',
    ReserveNov = 'reserveNov',
    ReserveDec = 'reserveDec',
    FactJan = 'factJan',
    FactFeb = 'factFeb',
    FactMar = 'factMar',
    FactApr = 'factApr',
    FactMay = 'factMay',
    FactJun = 'factJun',
    FactJul = 'factJul',
    FactAug = 'factAug',
    FactSep = 'factSep',
    FactOct = 'factOct',
    FactNov = 'factNov',
    FactDec = 'factDec',
    TotalPlan = 'totalPlan',
    TotalReserve = 'totalReserve',
    TotalFact = 'totalFact',
    SapZns = 'sapZns',
    SapCorrectionNumber = 'sapCorrectionNumber',
    Ifkv = 'ifkv',
    ResourceUsage = 'resourceUsage',
    CostDirecrtion = 'costDirecrtion',
    Subcategory = 'subcategory',
    Objective = 'objective',
    ActivityBalanceJan = 'activityBalanceJan',
    ActivityBalanceFeb = 'activityBalanceFeb',
    ActivityBalanceMar = 'activityBalanceMar',
    ActivityBalanceApr = 'activityBalanceApr',
    ActivityBalanceMay = 'activityBalanceMay',
    ActivityBalanceJun = 'activityBalanceJun',
    ActivityBalanceJul = 'activityBalanceJul',
    ActivityBalanceAug = 'activityBalanceAug',
    ActivityBalanceSep = 'activityBalanceSep',
    ActivityBalanceOct = 'activityBalanceOct',
    ActivityBalanceNov = 'activityBalanceNov',
    ActivityBalanceDec = 'activityBalanceDec',
    FactBalanceJan = 'factBalanceJan',
    FactBalanceFeb = 'factBalanceFeb',
    FactBalanceMar = 'factBalanceMar',
    FactBalanceApr = 'factBalanceApr',
    FactBalanceMay = 'factBalanceMay',
    FactBalanceJun = 'factBalanceJun',
    FactBalanceJul = 'factBalanceJul',
    FactBalanceAug = 'factBalanceAug',
    FactBalanceSep = 'factBalanceSep',
    FactBalanceOct = 'factBalanceOct',
    FactBalanceNov = 'factBalanceNov',
    FactBalanceDec = 'factBalanceDec',
    // ActivityBalanceQuarter1 = 'activityBalanceQuarter1',
    // ActivityBalanceQuarter2 = 'activityBalanceQuarter2',
    // ActivityBalanceQuarter3 = 'activityBalanceQuarter3',
    // ActivityBalanceQuarter4 = 'activityBalanceQuarter4',
    // FactBalanceQuarter1 = 'factBalanceQuarter1',
    // FactBalanceQuarter2 = 'factBalanceQuarter2',
    // FactBalanceQuarter3 = 'factBalanceQuarter3',
    // FactBalanceQuarter4 = 'factBalanceQuarter4',
    ActivityBalanceYear = 'activityBalanceYear',
    FactBalanceYear = 'factBalanceYear',
    Status = 'status',
    BusinessGoal = 'businessGoal',
    Customer = 'customer',
    Author = 'author',
    Tags = 'tags',
    TotalPlanQuarter1 = 'totalPlanQuarter1',
    TotalPlanQuarter2 = 'totalPlanQuarter2',
    TotalPlanQuarter3 = 'totalPlanQuarter3',
    TotalPlanQuarter4 = 'totalPlanQuarter4',
    TotalReserveQuarter1 = 'totalReserveQuarter1',
    TotalReserveQuarter2 = 'totalReserveQuarter2',
    TotalReserveQuarter3 = 'totalReserveQuarter3',
    TotalReserveQuarter4 = 'totalReserveQuarter4',
    TotalFactQuarter1 = 'totalFactQuarter1',
    TotalFactQuarter2 = 'totalFactQuarter2',
    TotalFactQuarter3 = 'totalFactQuarter3',
    TotalFactQuarter4 = 'totalFactQuarter4',
}

// noop acts as skip loading marker
export const ColumnNameToFilterMap = {
    [ColumnName.Comment]: 'comment',
    [ColumnName.Id]: 'serialNumber',
    [ColumnName.DonorIds]: 'donors',
    [ColumnName.Regionality]: 'dictionary.regionality',
    [ColumnName.RegionalityCode]: 'dictionary.regionality.code',
    [ColumnName.ActivityName]: 'activity.name',
    [ColumnName.Direction]: 'dictionary.direction',
    [ColumnName.DirectionCode]: 'dictionary.direction.code',
    [ColumnName.Tool]: 'dictionary.tool',
    [ColumnName.ToolCode]: 'dictionary.tool.code',
    [ColumnName.SapComment]: 'sapComment',
    [ColumnName.Block]: 'dictionary.block',
    [ColumnName.BlockCode]: 'dictionary.block.code',
    [ColumnName.Division]: 'dictionary.division',
    [ColumnName.DivisionCode]: 'dictionary.division.code',
    [ColumnName.CostCenter]: 'dictionary.cost_center',
    [ColumnName.CostCenterCode]: 'dictionary.cost_center.code',
    [ColumnName.ActivityType]: 'dictionary.activity_type',
    [ColumnName.ActivityTypeCode]: 'dictionary.activity_type.code',
    [ColumnName.Channel]: 'dictionary.channel',
    [ColumnName.ChannelCode]: 'dictionary.channel.code',
    [ColumnName.LocationDriver]: 'dictionary.location_driver',
    [ColumnName.LocationDriverCode]: 'dictionary.location_driver.code',
    [ColumnName.Item]: 'dictionary.item',
    [ColumnName.Resource]: 'dictionary.resource',
    [ColumnName.ResourceCode]: 'dictionary.resource.code',
    [ColumnName.Responsible]: 'responsibles',
    [ColumnName.Segment]: 'dictionary.segment',
    [ColumnName.SegmentCode]: 'dictionary.segment.code',
    [ColumnName.Product]: 'dictionary.product',
    [ColumnName.ProductCode]: 'dictionary.product.code',
    [ColumnName.Territory]: 'dictionary.territory',
    [ColumnName.TerritoryCode]: 'dictionary.territory.code',
    [ColumnName.StartDate]: 'realizationStart',
    [ColumnName.EndDate]: 'realizationEnd',
    [ColumnName.LastYearFact]: 'previousFunds',
    [ColumnName.PlanQuarter1]: 'noop',
    [ColumnName.PlanQuarter2]: 'noop',
    [ColumnName.PlanQuarter3]: 'noop',
    [ColumnName.PlanQuarter4]: 'noop',
    [ColumnName.ReserveQuarter1]: 'noop',
    [ColumnName.ReserveQuarter2]: 'noop',
    [ColumnName.ReserveQuarter3]: 'noop',
    [ColumnName.ReserveQuarter4]: 'noop',
    [ColumnName.FactQuarter1]: 'noop',
    [ColumnName.FactQuarter2]: 'noop',
    [ColumnName.FactQuarter3]: 'noop',
    [ColumnName.FactQuarter4]: 'noop',
    [ColumnName.PlanJan]: 'plannedFunds.jan',
    [ColumnName.PlanFeb]: 'plannedFunds.feb',
    [ColumnName.PlanMar]: 'plannedFunds.mar',
    [ColumnName.PlanApr]: 'plannedFunds.apr',
    [ColumnName.PlanMay]: 'plannedFunds.may',
    [ColumnName.PlanJun]: 'plannedFunds.jun',
    [ColumnName.PlanJul]: 'plannedFunds.jul',
    [ColumnName.PlanAug]: 'plannedFunds.aug',
    [ColumnName.PlanSep]: 'plannedFunds.sept',
    [ColumnName.PlanOct]: 'plannedFunds.oct',
    [ColumnName.PlanNov]: 'plannedFunds.nov',
    [ColumnName.PlanDec]: 'plannedFunds.dec',
    [ColumnName.ReserveJan]: 'reservedFunds.jan',
    [ColumnName.ReserveFeb]: 'reservedFunds.feb',
    [ColumnName.ReserveMar]: 'reservedFunds.mar',
    [ColumnName.ReserveApr]: 'reservedFunds.apr',
    [ColumnName.ReserveMay]: 'reservedFunds.may',
    [ColumnName.ReserveJun]: 'reservedFunds.jun',
    [ColumnName.ReserveJul]: 'reservedFunds.jul',
    [ColumnName.ReserveAug]: 'reservedFunds.aug',
    [ColumnName.ReserveSep]: 'reservedFunds.sept',
    [ColumnName.ReserveOct]: 'reservedFunds.oct',
    [ColumnName.ReserveNov]: 'reservedFunds.nov',
    [ColumnName.ReserveDec]: 'reservedFunds.dec',
    [ColumnName.FactJan]: 'factFunds.jan',
    [ColumnName.FactFeb]: 'factFunds.feb',
    [ColumnName.FactMar]: 'factFunds.mar',
    [ColumnName.FactApr]: 'factFunds.apr',
    [ColumnName.FactMay]: 'factFunds.may',
    [ColumnName.FactJun]: 'factFunds.jun',
    [ColumnName.FactJul]: 'factFunds.jul',
    [ColumnName.FactAug]: 'factFunds.aug',
    [ColumnName.FactSep]: 'factFunds.sept',
    [ColumnName.FactOct]: 'factFunds.oct',
    [ColumnName.FactNov]: 'factFunds.nov',
    [ColumnName.FactDec]: 'factFunds.dec',
    [ColumnName.TotalPlan]: 'computedFunds.totalPlan',
    [ColumnName.TotalReserve]: 'computedFunds.totalReserved',
    [ColumnName.TotalFact]: 'computedFunds.totalFact',
    [ColumnName.SapZns]: 'sapZns',
    [ColumnName.SapCorrectionNumber]: 'sapNumber',
    [ColumnName.Ifkv]: 'dictionary.ifkv',
    [ColumnName.ResourceUsage]: 'dictionary.resource_usage',
    [ColumnName.CostDirecrtion]: 'dictionary.cost_direction',
    [ColumnName.Subcategory]: 'dictionary.subcategory',
    [ColumnName.Objective]: 'dictionary.objective',
    [ColumnName.ActivityBalanceJan]: 'noop',
    [ColumnName.ActivityBalanceFeb]: 'noop',
    [ColumnName.ActivityBalanceMar]: 'noop',
    [ColumnName.ActivityBalanceApr]: 'noop',
    [ColumnName.ActivityBalanceMay]: 'noop',
    [ColumnName.ActivityBalanceJun]: 'noop',
    [ColumnName.ActivityBalanceJul]: 'noop',
    [ColumnName.ActivityBalanceAug]: 'noop',
    [ColumnName.ActivityBalanceSep]: 'noop',
    [ColumnName.ActivityBalanceOct]: 'noop',
    [ColumnName.ActivityBalanceNov]: 'noop',
    [ColumnName.ActivityBalanceDec]: 'noop',
    [ColumnName.FactBalanceJan]: 'noop',
    [ColumnName.FactBalanceFeb]: 'noop',
    [ColumnName.FactBalanceMar]: 'noop',
    [ColumnName.FactBalanceApr]: 'noop',
    [ColumnName.FactBalanceMay]: 'noop',
    [ColumnName.FactBalanceJun]: 'noop',
    [ColumnName.FactBalanceJul]: 'noop',
    [ColumnName.FactBalanceAug]: 'noop',
    [ColumnName.FactBalanceSep]: 'noop',
    [ColumnName.FactBalanceOct]: 'noop',
    [ColumnName.FactBalanceNov]: 'noop',
    [ColumnName.FactBalanceDec]: 'noop',
    // [ColumnName.ActivityBalanceQuarter1]: 'computedFunds.activityBalanceQuarter1',
    // [ColumnName.ActivityBalanceQuarter2]: 'computedFunds.activityBalanceQuarter2',
    // [ColumnName.ActivityBalanceQuarter3]: 'computedFunds.activityBalanceQuarter3',
    // [ColumnName.ActivityBalanceQuarter4]: 'computedFunds.activityBalanceQuarter4',
    // [ColumnName.FactBalanceQuarter1]: 'computedFunds.factBalanceQuarter1',
    // [ColumnName.FactBalanceQuarter2]: 'computedFunds.factBalanceQuarter2',
    // [ColumnName.FactBalanceQuarter3]: 'computedFunds.factBalanceQuarter3',
    // [ColumnName.FactBalanceQuarter4]: 'computedFunds.factBalanceQuarter4',
    [ColumnName.ActivityBalanceYear]: 'computedFunds.activityBalanceYear',
    [ColumnName.FactBalanceYear]: 'computedFunds.factBalanceYear',
    [ColumnName.Status]: 'noop',
    [ColumnName.BusinessGoal]: 'businessTarget',
    [ColumnName.Customer]: 'customerName',
    [ColumnName.Author]: 'author',
    [ColumnName.Tags]: 'tags',
    [ColumnName.TotalPlanQuarter1]: 'computedFunds.totalPlanQuarter1',
    [ColumnName.TotalPlanQuarter2]: 'computedFunds.totalPlanQuarter2',
    [ColumnName.TotalPlanQuarter3]: 'computedFunds.totalPlanQuarter3',
    [ColumnName.TotalPlanQuarter4]: 'computedFunds.totalPlanQuarter4',
    [ColumnName.TotalReserveQuarter1]: 'computedFunds.totalReserveQuarter1',
    [ColumnName.TotalReserveQuarter2]: 'computedFunds.totalReserveQuarter2',
    [ColumnName.TotalReserveQuarter3]: 'computedFunds.totalReserveQuarter3',
    [ColumnName.TotalReserveQuarter4]: 'computedFunds.totalReserveQuarter4',
    [ColumnName.TotalFactQuarter1]: 'computedFunds.totalFactQurter1',
    [ColumnName.TotalFactQuarter2]: 'computedFunds.totalFactQurter2',
    [ColumnName.TotalFactQuarter3]: 'computedFunds.totalFactQurter3',
    [ColumnName.TotalFactQuarter4]: 'computedFunds.totalFactQurter4',
};

export interface CorrectionPopup {
    show: boolean;
    lineId?: string;
}
export type ExecutionTableUserConfig = {
    [key: string]: {
        columnsWidth?: ColumnsWidth;
        fixedColumnsNames?: ColumnName[];
        columnsVisiblityFilter?: ColumnsVisiblityFilter;
        sortingMode?: SortingMode;
        filters?: Filters;
        unsavedChanges?: ChangeList;
        showOnlyLinesWithoutPlan?: boolean;
        corrections?: LineCorrectionTypes;
        showOnlyLinesWithNegativeBalance?: boolean;
        appliedFiltersNames: AppliedFiltersNames;
        budgetItemsToIgnoreFilters?: string[];
        useLinesWithoutPlanInSorting: boolean;
        showOnlyLinesWithPlanBudget: boolean;
        showOnlyLinesWithNegativePlanFactDiff: boolean;
    };
};

export interface PageData {
    activityBudgets: ActivityBudget[];
    budget: Budget;
    budgetItems: BudgetItem[];
    budgetItemsToIgnoreFilters?: BudgetItem[];
    userDictionaries: GroupedDicitonaries;
    allDictionaries: GroupedDicitonaries;
    users: UserResponseParams[];
    allUsers: UserResponseParams[];
    userRole?: UserRole;
    corrections: CorrectionsGroup;
}

export interface ComputedData {
    pageBudgetItems: BudgetItem[];
    tableLinesCellsParams: TableLinesCellsParams;
    lineIdsWithActualChanges: string[];
    tableLines: TableLine[];
    dropdownOptions: DropdownOptions;
}

export interface GroupedDicitonaries {
    byId: Record<string, PlainDictionary>;
    byType: Partial<Record<DictionaryType, PlainDictionary[]>>;
}

export interface CorrectionsGroup {
    activityCorrections: GroupedCorrections<CorrectionType.Activity>;
    budgetItemCorrections: GroupedCorrections<CorrectionType.BudgetItem>;
    planCorrections: GroupedCorrections<CorrectionType.PlanFundsTransfer>;
    reserveCorrections: GroupedCorrections<CorrectionType.ReservedFunds>;
    factCorrections: GroupedCorrections<CorrectionType.FactFunds>;
    incomeExternalPlanCorrections: GroupedCorrections<CorrectionType.IncomeExternalPlanFundsTransfer>;
    outcomeExternalPlanCorrections: GroupedCorrections<CorrectionType.OutcomeExternalPlanFundsTransfer>;
}

export interface PageFilters {
    columnsVisiblityFilter?: ColumnsVisiblityFilter;
    sortingMode?: SortingMode;
    filters?: Filters;
    appliedFiltersNames: AppliedFiltersNames;
    validationStatus?: boolean;
    showOnlyLinesWithoutPlan: boolean;
    useLinesWithoutPlanInSorting: boolean;
    showOnlyLinesWithNegativeBalance: boolean;
    correctionsToDisplay: LineCorrectionTypes;
    showOnlyLinesWithPlanBudget: boolean;
    showOnlyLinesWithNegativePlanFactDiff: boolean;
}

export interface LineCorrectionTypes {
    [correctionFilterType: string]: boolean;
}

export const CORRECTION_FILTER_TYPE = {
    ACTIVITY_CORRECTION: 'activity_correction',
    BUDGET_ITEM_CORRECTION: 'budget_item_correction',
    PLAN_CORRECTION: 'plan_correction',
    RESERVE_CORRECTION: 'reserve_correction',
    NO_CORRECTIONS: 'no_corrections',
};

export interface ColumnsVisiblityFilter {
    [columnName: string]: boolean;
}

export interface ColumnsWidth {
    [columnName: string]: number;
}

export interface Filters {
    [columnName: string]: ColumnFilters;
}

export interface ColumnFilters {
    [title: string]: boolean;
}

export const PLANNED_COLUMN_NAMES = [
    ColumnName.PlanJan,
    ColumnName.PlanFeb,
    ColumnName.PlanMar,
    ColumnName.PlanApr,
    ColumnName.PlanMay,
    ColumnName.PlanJun,
    ColumnName.PlanJul,
    ColumnName.PlanAug,
    ColumnName.PlanSep,
    ColumnName.PlanOct,
    ColumnName.PlanNov,
    ColumnName.PlanDec,
];

export const RESERVED_COLUMN_NAMES = [
    ColumnName.ReserveJan,
    ColumnName.ReserveFeb,
    ColumnName.ReserveMar,
    ColumnName.ReserveApr,
    ColumnName.ReserveMay,
    ColumnName.ReserveJun,
    ColumnName.ReserveJul,
    ColumnName.ReserveAug,
    ColumnName.ReserveSep,
    ColumnName.ReserveOct,
    ColumnName.ReserveNov,
    ColumnName.ReserveDec,
];

export const FACT_COLUMN_NAMES = [
    ColumnName.FactJan,
    ColumnName.FactFeb,
    ColumnName.FactMar,
    ColumnName.FactApr,
    ColumnName.FactMay,
    ColumnName.FactJun,
    ColumnName.FactJul,
    ColumnName.FactAug,
    ColumnName.FactSep,
    ColumnName.FactOct,
    ColumnName.FactNov,
    ColumnName.FactDec,
];

export const MONTH_BY_COLUMN_NAMES = {
    [ColumnName.PlanJan]: Month.Jan,
    [ColumnName.ReserveJan]: Month.Jan,
    [ColumnName.FactJan]: Month.Jan,
    [ColumnName.PlanFeb]: Month.Feb,
    [ColumnName.ReserveFeb]: Month.Feb,
    [ColumnName.FactFeb]: Month.Feb,
    [ColumnName.PlanMar]: Month.Mar,
    [ColumnName.ReserveMar]: Month.Mar,
    [ColumnName.FactMar]: Month.Mar,
    [ColumnName.PlanApr]: Month.Apr,
    [ColumnName.ReserveApr]: Month.Apr,
    [ColumnName.FactApr]: Month.Apr,
    [ColumnName.PlanMay]: Month.May,
    [ColumnName.ReserveMay]: Month.May,
    [ColumnName.FactMay]: Month.May,
    [ColumnName.PlanJun]: Month.Jun,
    [ColumnName.ReserveJun]: Month.Jun,
    [ColumnName.FactJun]: Month.Jun,
    [ColumnName.PlanJul]: Month.Jul,
    [ColumnName.ReserveJul]: Month.Jul,
    [ColumnName.FactJul]: Month.Jul,
    [ColumnName.PlanAug]: Month.Aug,
    [ColumnName.ReserveAug]: Month.Aug,
    [ColumnName.FactAug]: Month.Aug,
    [ColumnName.PlanSep]: Month.Sept,
    [ColumnName.ReserveSep]: Month.Sept,
    [ColumnName.FactSep]: Month.Sept,
    [ColumnName.PlanOct]: Month.Oct,
    [ColumnName.ReserveOct]: Month.Oct,
    [ColumnName.FactOct]: Month.Oct,
    [ColumnName.PlanNov]: Month.Nov,
    [ColumnName.ReserveNov]: Month.Nov,
    [ColumnName.FactNov]: Month.Nov,
    [ColumnName.PlanDec]: Month.Dec,
    [ColumnName.ReserveDec]: Month.Dec,
    [ColumnName.FactDec]: Month.Dec,
};

// TODO Check actual
export const ACTIVITY_MONTH_BALANCE_COLUMN_NAMES = [
    ColumnName.ActivityBalanceJan,
    ColumnName.ActivityBalanceFeb,
    ColumnName.ActivityBalanceMar,
    ColumnName.ActivityBalanceApr,
    ColumnName.ActivityBalanceMay,
    ColumnName.ActivityBalanceJun,
    ColumnName.ActivityBalanceJul,
    ColumnName.ActivityBalanceAug,
    ColumnName.ActivityBalanceSep,
    ColumnName.ActivityBalanceOct,
    ColumnName.ActivityBalanceNov,
    ColumnName.ActivityBalanceDec,
];

// TODO Check actual
export const FACT_MONTH_BALANCE_COLUMN_NAMES = [
    ColumnName.FactBalanceJan,
    ColumnName.FactBalanceFeb,
    ColumnName.FactBalanceMar,
    ColumnName.FactBalanceApr,
    ColumnName.FactBalanceMay,
    ColumnName.FactBalanceJun,
    ColumnName.FactBalanceJul,
    ColumnName.FactBalanceAug,
    ColumnName.FactBalanceSep,
    ColumnName.FactBalanceOct,
    ColumnName.FactBalanceNov,
    ColumnName.FactBalanceDec,
];

export const TOTAL_PLAN_QUARTER_COLUMN_NAMES = [
    ColumnName.TotalPlanQuarter1,
    ColumnName.TotalPlanQuarter2,
    ColumnName.TotalPlanQuarter3,
    ColumnName.TotalPlanQuarter4,
];

export const TOTAL_RESERVE_QUARTER_COLUMN_NAMES = [
    ColumnName.TotalReserveQuarter1,
    ColumnName.TotalReserveQuarter2,
    ColumnName.TotalReserveQuarter3,
    ColumnName.TotalReserveQuarter4,
];

export const TOTAL_FACT_QUARTER_COLUMN_NAMES = [
    ColumnName.TotalFactQuarter1,
    ColumnName.TotalFactQuarter2,
    ColumnName.TotalFactQuarter3,
    ColumnName.TotalFactQuarter4,
];

// export const ACTIVITY_QUARTER_BALANCE_COLUMN_NAMES = [
//     ColumnName.ActivityBalanceQuarter1,
//     ColumnName.ActivityBalanceQuarter2,
//     ColumnName.ActivityBalanceQuarter3,
//     ColumnName.ActivityBalanceQuarter4,
// ];

// export const FACT_QUARTER_BALANCE_COLUMN_NAMES = [
//     ColumnName.FactBalanceQuarter1,
//     ColumnName.FactBalanceQuarter2,
//     ColumnName.FactBalanceQuarter3,
//     ColumnName.FactBalanceQuarter4,
// ];

export const ACTIVITY_FIELDS_COLUMN_NAMES = [ColumnName.ActivityName];

export const BUDGET_ITEM_FIELDS_COLUMN_NAMES = [
    ColumnName.LastYearFact,
    ColumnName.SapZns,
    ColumnName.SapCorrectionNumber,
    ColumnName.Comment,
    ColumnName.SapComment,
    ColumnName.Responsible,
    ColumnName.StartDate,
    ColumnName.EndDate,
    ColumnName.BusinessGoal,
    ColumnName.Customer,
];

export const BUDGET_ITEM_DICTIONARY_COLUMN_NAMES = [
    ColumnName.Channel,
    ColumnName.CostCenter,
    ColumnName.Direction,
    ColumnName.Item,
    ColumnName.LocationDriver,
    ColumnName.Objective,
    ColumnName.Resource,
    ColumnName.ResourceUsage,
    ColumnName.Subcategory,
    ColumnName.Tool,
    ColumnName.Ifkv,
    ColumnName.CostDirecrtion,
    ColumnName.Block,
    ColumnName.Division,
    ColumnName.Product,
    ColumnName.Segment,
    ColumnName.Territory,
    ColumnName.Regionality,
    ColumnName.ActivityType,
];

export const BUDGET_ITEM_DICTIONARY_TYPES = [
    DictionaryType.Block,
    DictionaryType.Division,
    DictionaryType.Product,
    DictionaryType.Segment,
    DictionaryType.Territory,
    DictionaryType.Regionality,
    DictionaryType.ActivityType,
    DictionaryType.Channel,
    DictionaryType.CostCenter,
    DictionaryType.CostDirection,
    DictionaryType.Direction,
    DictionaryType.FunctionalBlock,
    DictionaryType.IFKV,
    DictionaryType.Item,
    DictionaryType.LocationDriver,
    DictionaryType.Objective,
    DictionaryType.Resource,
    DictionaryType.ResourceUsage,
    DictionaryType.Subcategory,
    DictionaryType.Tool,
];

export const REQUIRED_COLUMN_NAMES = [ColumnName.ActivityName, ColumnName.SapComment];

export const DictionaryNames = {
    [DictionaryType.ActivityType]: 'Тип проекта',
    [DictionaryType.Block]: 'Блок',
    [DictionaryType.Division]: 'Дивизион',
    [DictionaryType.Product]: 'Продукт',
    [DictionaryType.Segment]: 'Сегмент',
    [DictionaryType.Territory]: 'Территория',
    [DictionaryType.Regionality]: 'ЦА/ТБ',
    [DictionaryType.Channel]: 'Канал',
    [DictionaryType.CostCenter]: 'Наименование ЦЗ',
    [DictionaryType.CostDirection]: '',
    [DictionaryType.Direction]: 'Направление',
    [DictionaryType.FunctionalBlock]: '',
    [DictionaryType.IFKV]: 'ИФКВ',
    [DictionaryType.Item]: 'Статья',
    [DictionaryType.LocationDriver]: 'Драйвер аллокации',
    [DictionaryType.Objective]: 'Задача',
    [DictionaryType.Resource]: 'Ресурс',
    [DictionaryType.ResourceUsage]: 'Порядок использования ресурсов',
    [DictionaryType.Subcategory]: 'Подкатегория',
    [DictionaryType.Tool]: 'Инструмент',
};

// Budget from line with this item can only be transfered to line with the same article
export const CAPITAL_EXPENSES_ITEM = '206.6';

export const OldToNewMonth = {
    1: Month.Jan,
    2: Month.Feb,
    3: Month.Mar,
    4: Month.Apr,
    5: Month.May,
    6: Month.Jun,
    7: Month.Jul,
    8: Month.Aug,
    9: Month.Sept,
    10: Month.Oct,
    11: Month.Nov,
    12: Month.Dec,
};

export interface ColumnData {
    name: ColumnName;
    title: string;
    width: number;
    disableSorting?: boolean;
    disableFilter?: boolean;
    minAccessRole?: string;
    hiddenByDefault?: boolean;
    customColumnColor?: CustomColumnColor;
    valueType: CellValueType;
    metaData?: { [key: string]: any };
    customCellType?: CustomCellType;
    accessor?: (params: AccessorParams) => CellValue;
}

export const enum CustomCellType {
    Selectable = 'selectable',
    Input = 'input',
    Dropdown = 'dropdown',
    Datepicker = 'datepicker',
}

export interface AccessorParams {
    budgetItem: BudgetItem;
    activityBudgets: ActivityBudget[];
    users: UserResponseParams[];
    tags: Dictionary<Tag>;
}

export interface TableLine {
    id: string;
    budgetItem: BudgetItem;
    activityId: string;
    userIsAuthor: boolean;
    fields: { [columnName: string]: CellValue };
    isDisabled: boolean;
    planValue: number;
    factValue: number;
    reserveValue: number;
    creationTime: string | Date;
}

export type TableLineGroup = {
    activityId: string;
    lines: TableLine[];
    isDisabled: boolean;
};

export type CellValue = string | NumericCellValue | DateCellValue;

export interface NumericCellValue {
    title: string;
    number: number;
}

export interface DateCellValue {
    title: string;
    date: Date;
}

export const enum CellValueType {
    String = 'string',
    Number = 'number',
    Currency = 'currency',
    Date = 'date',
}

export interface SortingMode {
    columnName: ColumnName;
    order: OrderType;
}

export const enum OrderType {
    Asc = 'asc',
    Desc = 'desc',
}

export interface TotalBudgets {
    [activityId: string]: number;
}

export interface SelectableCellsList {
    [budgetId: string]: ColumnName[];
}

export const enum UserRole {
    BUDGET_EXPERT = 'budgetExpert',
    SUPERVISOR = 'supervisor',
    BRAND_MANAGER = 'brandManager',
}

export const enum BudgetLineStatus {
    Approved = 'approved',
    Rejected = 'rejected',
    Closed = 'closed',
    Changed = 'changed',
    OnApprovemant = 'onApprovemant',
}

export interface GroupedCorrections<T extends CorrectionType> {
    [budgetItemId: string]: Correction<T>[];
}

export interface UnsavedChange {
    budgetItemId: string;
    columnName: ColumnName;
    value: string | Date;
    originalValue: string | number | Date;
}

export type ChangeList = { [lineId: string]: UnsavedChange[] };

export interface UpdateLinesDataParams {
    activityBudgets: ActivityBudget[];
    budgetItems: BudgetItem[];
    budgetItemsToIgnoreFilters: BudgetItem[];
    corrections: CorrectionsGroup;
}

export interface ChangeCellValueParams {
    change: UnsavedChange;
    generateSameValueChange?: boolean;
    line: TableLine;
    tags?: Dictionary<Tag>;
}

export interface LoadFiltersPaylaod {
    columnName: FilterKey;
    filters: {
        [key: string]: boolean;
    };
}

export interface SetFiltersLoadingStatusPayload {
    columnName: FilterKey;
    loadingStatus: LoadingStatus;
}

export interface ActivityReferenceMenuVisibility {
    visibility: boolean;
    selectedActivityId?: string;
    filters?: ActivityReferenceMenuFilter;
}

export interface CreativeReferenceMenuVisibility {
    visibility: boolean;
    creativeRequestId?: string;
}

export interface ActivityReferenceMenuFilter {
    divisionIds: string[];
}

export const ColumnsNameWithCodes: ColumnName[] = [
    ColumnName.DivisionCode,
    ColumnName.RegionalityCode,
    ColumnName.DirectionCode,
    ColumnName.ToolCode,
    ColumnName.BlockCode,
    ColumnName.CostCenterCode,
    ColumnName.ActivityTypeCode,
    ColumnName.ChannelCode,
    ColumnName.LocationDriverCode,
    ColumnName.ResourceCode,
    ColumnName.SegmentCode,
    ColumnName.ProductCode,
    ColumnName.TerritoryCode,
];

// LinkedColumns graph
export const ColumnsWithSameData = {
    [ColumnName.Division]: [ColumnName.Division, ColumnName.DivisionCode],
    [ColumnName.DivisionCode]: [ColumnName.Division, ColumnName.DivisionCode],
    [ColumnName.Regionality]: [ColumnName.Regionality, ColumnName.RegionalityCode],
    [ColumnName.RegionalityCode]: [ColumnName.Regionality, ColumnName.RegionalityCode],
    [ColumnName.Direction]: [ColumnName.Direction, ColumnName.DirectionCode],
    [ColumnName.DirectionCode]: [ColumnName.Direction, ColumnName.DirectionCode],
    [ColumnName.Tool]: [ColumnName.Tool, ColumnName.ToolCode],
    [ColumnName.ToolCode]: [ColumnName.Tool, ColumnName.ToolCode],
    [ColumnName.Block]: [ColumnName.Block, ColumnName.BlockCode],
    [ColumnName.BlockCode]: [ColumnName.Block, ColumnName.BlockCode],
    [ColumnName.CostCenter]: [ColumnName.CostCenter, ColumnName.CostCenterCode],
    [ColumnName.CostCenterCode]: [ColumnName.CostCenter, ColumnName.CostCenterCode],
    [ColumnName.ActivityType]: [ColumnName.ActivityType, ColumnName.ActivityTypeCode],
    [ColumnName.ActivityTypeCode]: [ColumnName.ActivityType, ColumnName.ActivityTypeCode],
    [ColumnName.Channel]: [ColumnName.Channel, ColumnName.ChannelCode],
    [ColumnName.ChannelCode]: [ColumnName.Channel, ColumnName.ChannelCode],
    [ColumnName.LocationDriver]: [ColumnName.LocationDriver, ColumnName.LocationDriverCode],
    [ColumnName.LocationDriverCode]: [ColumnName.LocationDriver, ColumnName.LocationDriverCode],
    [ColumnName.Resource]: [ColumnName.Resource, ColumnName.ResourceCode],
    [ColumnName.ResourceCode]: [ColumnName.Resource, ColumnName.ResourceCode],
    [ColumnName.Segment]: [ColumnName.Segment, ColumnName.SegmentCode],
    [ColumnName.SegmentCode]: [ColumnName.Segment, ColumnName.SegmentCode],
    [ColumnName.Product]: [ColumnName.Product, ColumnName.ProductCode],
    [ColumnName.ProductCode]: [ColumnName.Product, ColumnName.ProductCode],
    [ColumnName.Territory]: [ColumnName.Territory, ColumnName.TerritoryCode],
    [ColumnName.TerritoryCode]: [ColumnName.Territory, ColumnName.TerritoryCode],
};

export interface ResetFilterPayload {
    filterName: string;
}

export interface SetPreviouslyLoadedFilterPayload {
    columnName: FilterKey;
    filter: { [key: string]: boolean };
}

export interface SetPreviouslyLoadedFiltersPayload {
    filters: Filters;
}

export enum CustomColumnColor {
    CurrencySum = '#e8f2ff',
}

export interface TableLinesCellsParams {
    [lineId: string]: LineCellsParams;
}

export interface DownloadTableAsXLSXPaylaod {
    useFilters: boolean;
}

export interface DropdownOptions {
    [lineId: string]: { [columnName: string]: DropdownCellOptions[] };
}
