// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-CommonInfoWidget-Link-_Link__root{cursor:pointer;text-decoration:none;color:#427ec6;font-size:12px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetPlanning/LineModal/WidgetsGroups/CommonInfoWidget/Link/Link.scss"],"names":[],"mappings":"AAAA,+GACI,cAAe,CACf,oBAAqB,CAErB,aAAc,CAEd,cAAe","sourcesContent":[".root {\n    cursor: pointer;\n    text-decoration: none;\n\n    color: #427ec6;\n\n    font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-CommonInfoWidget-Link-_Link__root"
};
export default ___CSS_LOADER_EXPORT___;
