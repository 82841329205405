import * as EventEmitter from 'events';
import autobind from 'autobind-decorator';
import { isNil } from 'lodash';

/** Scroll watcher event types */
export const enum ScrollWatcherEventType {
    /** Scroll event type */
    SCROLL_EVENT = 'scroll',
    /** Scrolled to top event type */
    SCROLLED_TOP_EVENT = 'scrolledTop',
    /** Scrolled to down event type */
    SCROLLED_DOWN_EVENT = 'scrolledDown',
}

/** Scroll watching service */
export class ScrollWatcher extends EventEmitter {
    /** Scrollable element identifier */
    private scrollableElementId: string;

    /** Founded element */
    private scrollableElement: HTMLElement | null = null;

    public constructor(scrollableElementId: string) {
        super();
        this.scrollableElementId = scrollableElementId;
        this.reInit();
    }

    /** Re-initialize */
    public reInit(): void {
        if (!isNil(this.scrollableElement)) {
            this.scrollableElement.removeEventListener('scroll', this.onScroll);
        }
        this.scrollableElement = document.getElementById(this.scrollableElementId);
        if (!isNil(this.scrollableElement)) {
            this.scrollableElement.addEventListener('scroll', this.onScroll);
        }
    }

    /** Remove all listeners (should be called on "componentWillUnmount" lifecycle method) */
    public dispose(): void {
        this.removeAllListeners();
        if (!isNil(this.scrollableElement)) {
            this.scrollableElement.removeEventListener('scroll', this.onScroll);
        }
    }

    /** Scroll event handler */
    @autobind
    private onScroll(): void {
        this.emit(ScrollWatcherEventType.SCROLL_EVENT);
        if (!isNil(this.scrollableElement)) {
            const { scrollTop, scrollHeight, clientHeight } = this.scrollableElement;
            if (clientHeight === scrollHeight - scrollTop) {
                this.emit(ScrollWatcherEventType.SCROLLED_DOWN_EVENT);
            }
            if (scrollTop === 0) {
                this.emit(ScrollWatcherEventType.SCROLLED_TOP_EVENT);
            }
        }
    }
}
