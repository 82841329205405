import { BudgetItem, BudgetItemListQueryParams } from '@mrm/budget';

import { rtkApi } from './rtkApi';

export const budgetApi = rtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getBudgets: builder.query<BudgetItem[], BudgetItemListQueryParams>({
            query: (body) => ({
                url: '/budgetItem/list',
                body,
                method: 'post',
            }),
        }),
        getBudget: builder.query<BudgetItem, string>({
            query: (budgetId) => ({
                url: `/budgetItem/single/${budgetId}`,
                method: 'post',
            }),
        }),
    }),
});

export const { useGetBudgetsQuery, useGetBudgetQuery } = budgetApi;
