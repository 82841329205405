import * as React from 'react';
import { useParams } from 'react-router-dom';

import { TaskCommentTransferObject } from 'sber-marketing-types/backend';

import { useGetTaskQuery } from '@api';

import { getFilesCountText } from '@modules/files/utils';
import { FileData } from '@modules/files/types';

import { useAuthUserId } from '@modules/auth/hooks';
import { useIsTaskAuthor, useIsTaskExecutor, useIsTaskParticipant } from '@modules/task/hooks';

import { ActivityTaskPageParams } from '@modules/task/pages';

import { Scrollbar, SkeletonText } from '@common/components';
import { DownloadFiles, FileTag, FileTagLoader } from '@modules/files/components';
import { TaskFilesGallery } from '@modules/task/components';

import * as styles from './TaskPageFiles.scss';

const countLoader = (
    <>
        <SkeletonText width={8} /> файлов
    </>
);

function getFileEditable(
    isTaskFile: boolean,
    isTaskAuthor: boolean,
    isTaskParticipant: boolean,
    isTaskExecutor: boolean,
    isCommentAuthor: boolean,
) {
    return isTaskFile ? isTaskAuthor || isTaskParticipant || isTaskExecutor : isCommentAuthor;
}

export const TaskPageFiles: React.FC = () => {
    const { taskId } = useParams<ActivityTaskPageParams>();
    const { authUserId } = useAuthUserId();
    const { isAuthor } = useIsTaskAuthor(taskId);
    const { isParticipant } = useIsTaskParticipant(taskId);
    const { isExecutor } = useIsTaskExecutor(taskId);
    const [viewFile, setViewFile] = React.useState<FileData>();
    const { data: task, isLoading: isTaskLoading } = useGetTaskQuery({ id: taskId });
    const taskFilesCount = task?.task.files.length;
    const taskFilesIds = React.useMemo(() => task?.task.files.map(({ id }) => id), [task]);

    const taskFiles: FileData[] = React.useMemo(
        () => task?.task.files.map((file) => ({ ...file, containerName: taskId, parent: 'task' })) || [],
        [task],
    );

    const taskComments: TaskCommentTransferObject[] = React.useMemo(
        () => task?.task.chanels.reduce((rest, { comments }) => [...rest, ...(comments || [])], []),
        [task],
    );

    const commentsFiles: FileData[] = React.useMemo(
        () =>
            taskComments?.reduce(
                (rest, { id, files }) => [
                    ...rest,
                    ...files?.map((file) => ({ ...file, containerName: id, parent: 'comment' })),
                ],
                [],
            ) || [],
        [taskComments],
    );

    const commentsFilesCount = commentsFiles.length;

    const files: FileData[] = React.useMemo(
        () =>
            [...taskFiles, ...commentsFiles].sort(
                (a: FileData, b: FileData) => new Date(b.createTime).getTime() - new Date(a.createTime).getTime(),
            ) || [],
        [taskFiles, commentsFiles],
    );

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div data-qa-id="TaskPageFiles__filesCount" className={styles.filesCount}>
                    {isTaskLoading ? countLoader : getFilesCountText(commentsFilesCount + taskFilesCount)}
                </div>
                <DownloadFiles
                    files={files}
                    fileName={`Все вложения задачи «${task?.task.title}» от ${new Date().toLocaleDateString()}.zip`}
                >
                    Скачать все
                </DownloadFiles>
            </div>
            <Scrollbar gutter="stable" className={styles.files}>
                {isTaskLoading && <FileTagLoader />}
                {files.map((file) => (
                    <FileTag
                        editable={getFileEditable(
                            taskFilesIds.includes(file.id),
                            isAuthor,
                            isParticipant,
                            isExecutor,
                            taskComments.find(({ id }) => id === file.containerName)?.authorId === authUserId,
                        )}
                        key={file.id}
                        file={file}
                        onView={() => setViewFile(file)}
                    />
                ))}
            </Scrollbar>
            <TaskFilesGallery taskId={taskId} files={files} file={viewFile} onShowFile={setViewFile} />
        </div>
    );
};
