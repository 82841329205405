import * as React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Icon, IconType, WithTooltip, TooltipAnchor, StaticSkeleton } from 'sber-marketing-ui';
import { TaskCardParams, TaskStatus } from 'sber-marketing-types/frontend';

import { DatesFormatter } from '@common/Utils';
import { NoResultsMarker } from '@common/SidebarWithTabs/common/NoResultsMarker';
import { useTextOverflowCheck } from '@common/hooks';

import * as styles from './TasksTab.scss';

interface Props {
    activityId: number;
    taskCards: TaskCardParams[];
    isLoading: boolean;
    openTaskSidebar: (taskId: string) => void;
}

export function TasksTab({ activityId, taskCards, isLoading, openTaskSidebar }: Props): JSX.Element {
    const showPreloader = !taskCards || isLoading;
    const showNoResultsMarker = !taskCards?.length;

    if (showPreloader) {
        return <Preloader />;
    }
    if (showNoResultsMarker) {
        return <NoResultsMarker text="Нет задач" />;
    }

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'activitySidebarTasksTab',
            }}
        >
            {taskCards?.map((task) => (
                <Task {...task} key={task.id} activityId={activityId} openTaskSidebar={openTaskSidebar} />
            ))}
        </div>
    );
}

interface TaskProps extends TaskCardParams {
    activityId: number;
    openTaskSidebar: (taskId: string) => void;
}

function Task({ id, title, deadline, activityId, status, openTaskSidebar }: TaskProps): JSX.Element {
    const titleRef = React.useRef<HTMLDivElement>();
    const { horizontal: titleOverflow } = useTextOverflowCheck(titleRef);

    function onLinkClick(event: React.MouseEvent<HTMLAnchorElement>) {
        event.stopPropagation();
    }

    return (
        <div
            className={styles.taskClickable}
            onClick={() => openTaskSidebar(id)}
            {...{
                'qa-id': 'activitySidebarTasksTabTaskCard',
            }}
        >
            <Link
                to={`/activity/${activityId}/task/${id}`}
                target="_blank"
                className={styles.taskOpenLink}
                onClick={onLinkClick}
            >
                <Icon type={IconType.LINK_FOR_SHARING} svgSize={17} />
            </Link>

            <div className={styles.titleWrapper}>
                <WithTooltip spanWrapper hidden={!titleOverflow} content={title} anchor={TooltipAnchor.LEFT}>
                    <div
                        ref={titleRef}
                        className={styles.taskTitle}
                        {...{
                            'qa-id': 'activitySidebarTasksTabTaskCardTitle',
                        }}
                    >
                        {title}
                    </div>
                </WithTooltip>
            </div>

            <Deadline deadline={deadline} status={status} />
        </div>
    );
}

interface DeadlineProps {
    deadline: string | number | Date;
    status: TaskStatus;
}

function Deadline({ deadline, status }: DeadlineProps): JSX.Element {
    const deadlineDate = new Date(deadline);
    const deadlineIsOverdue = deadlineDate < new Date();

    const content =
        status == TaskStatus.Draft ? (
            'Черновик'
        ) : (
            <React.Fragment>
                <Icon type={IconType.CALENDAR_ICON} svgSize={16} />

                {DatesFormatter.ddMonthyyyy(deadlineDate)}
            </React.Fragment>
        );

    return (
        <div
            className={classnames(styles.taskDeadline, deadlineIsOverdue && styles.taskDeadlineOverdue)}
            {...{
                'qa-id': 'activitySidebarTasksTabTaskCardDeadline',
            }}
        >
            {content}
        </div>
    );
}

function Preloader(): JSX.Element {
    return (
        <div className={styles.root}>
            <TaskPreloader />
            <TaskPreloader />
        </div>
    );
}

function TaskPreloader(): JSX.Element {
    return (
        <div className={styles.task}>
            <StaticSkeleton loadingClassName={styles.taskOpenLinkPreloader} />
            <StaticSkeleton loadingClassName={styles.taskTitlePreloader} />
            <StaticSkeleton loadingClassName={styles.taskDeadlinePreloader} />
        </div>
    );
}
