import * as React from 'react';

import type { UserCard } from 'sber-marketing-types/frontend';

import { OptionMultiple, OptionProps, Selector, SelectorProps } from '@common/components';
import { UseUserOptions, useUserOptions } from '@modules/user/hooks';

export type IUserSelectorProps<M extends OptionMultiple> = Omit<
    SelectorProps<M, OptionProps<UserCard>, UserCard, 'id', number, M extends true ? number[] : number>,
    'options' | 'optionId'
>;

export interface UserSelectorProps<M extends OptionMultiple> extends IUserSelectorProps<M>, UseUserOptions {}

export function UserSelector<M extends OptionMultiple>({
    departmentIds,
    hasWorkTypes,
    ids,
    embed,
    organizationIds,
    roleIds,
    sortingOrder,
    selected,
    ...props
}: UserSelectorProps<M>) {
    const userOptions: Required<UseUserOptions> = {
        hasWorkTypes,
        departmentIds,
        ids,
        embed,
        organizationIds,
        roleIds,
        sortingOrder,
    };
    const [options, isLoading] = useUserOptions(userOptions);

    return <Selector {...props} loading={isLoading} selected={selected} optionId="id" options={options} />;
}
