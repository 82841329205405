/**
 * @fileoverview Here is trigger actions - simple synchronous actions which triggers other actions.
 * For example, changing of work type identifier reloads available departments for editor
 */

import { Dispatch } from 'redux';

import { StoreState } from '../../';
import { editWorkTypeId as editWorkTypeIdAction } from '../actions';
import { loadDepartments } from '../availableDepartments';

/** Edit work type identifier */
export const editWorkTypeId =
    (id: string | null): any =>
    (dispatch: Dispatch<StoreState>) => {
        dispatch(loadDepartments(id));
        dispatch(editWorkTypeIdAction(id));
    };
