import * as React from 'react';
import * as style from './FiltersList.scss';
import * as lodash from 'lodash';

import { Icon, IconType } from 'sber-marketing-ui';

import { ColumnName, Filter, CustomFilter, CustomFilterType } from '../types';
import { ColumnsConfig } from '../ColumnsConfig';

interface Props {
    filters: Filter[];
    customFilter: CustomFilter;
    setFilters: (filters: Filter[]) => void;
    setSpecialFilter: (filter: CustomFilter) => void;
}

export const FiltersList = ({ filters, customFilter, setFilters, setSpecialFilter }: Props): JSX.Element => {
    const onFilterRemove = React.useCallback(
        (columnName: ColumnName) => {
            let updatedFilters = filters.filter((item) => item.columnName !== columnName);

            setFilters(updatedFilters);
        },
        [filters],
    );

    const onSpecialFilterRemove = React.useCallback(() => {
        let f: React.ReactText[] = [];
        let noneFilter = {
            filterType: CustomFilterType.None,
            name: '',
            params: f,
        };
        setSpecialFilter(noneFilter);
    }, [customFilter]);
    return (
        <div className={style.root}>
            <div className={style.filtersWrapper}>
                <div className={style.filters}>
                    {customFilter.filterType != CustomFilterType.None && (
                        <div className={style.filter}>
                            <div className={style.filterName}>{customFilter.name}&nbsp;</div>

                            <div className={style.removeFilterButton} onClick={() => onSpecialFilterRemove()}>
                                <Icon type={IconType.CROSS} svgSize={7} />
                            </div>
                        </div>
                    )}

                    {!lodash.isEmpty(filters) &&
                        filters.map((item) => (
                            <div className={style.filter} key={item.columnName}>
                                <div className={style.filterName}>
                                    {ColumnsConfig[item.columnName]?.title}&nbsp;
                                    <span className={style.filterCounter}>{item.selectedValues.length}</span>
                                </div>

                                <div
                                    className={style.removeFilterButton}
                                    onClick={() => onFilterRemove(item.columnName)}
                                >
                                    <Icon type={IconType.CROSS} svgSize={7} />
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};
