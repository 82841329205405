import { ColumnNames, ColumnParams } from '../../types';
import { ValidationError } from './ValidationError';

export class EmptyRequiredFieldError extends ValidationError {
    private fields: ColumnNames[];

    public constructor(fields: ColumnNames[]) {
        super();
        this.fields = fields;
    }

    public toString(): string {
        const fields = this.fields.map((field) => ColumnParams[field].header).join(', ');

        if (this.fields.length === 1) {
            return `Не заполнено обязательное поле ${fields}`;
        }

        return `Не заполнены обязательные поля ${fields}`;
    }
}
