// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ExternalTransitionMenu-SourceDestinationDescription-_SourceDestinationDescription__root{display:flex;margin:6px 0;align-items:baseline;justify-content:space-between}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ExternalTransitionMenu-SourceDestinationDescription-_SourceDestinationDescription__cellDescription{color:#000000;font-size:12px;font-weight:600;line-height:16px}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ExternalTransitionMenu-SourceDestinationDescription-_SourceDestinationDescription__arrowWrapper{display:flex;width:calc(50% - 6px);justify-content:center}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ExternalTransitionMenu-SourceDestinationDescription-_SourceDestinationDescription__arrowRotated{transform:rotate(180deg)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/ExternalTransitionMenu/SourceDestinationDescription/SourceDestinationDescription.scss"],"names":[],"mappings":"AAAA,iKACI,YAAa,CAEb,YAAa,CAEb,oBAAqB,CACrB,6BAA8B,CACjC,4KAGG,aAAc,CAEd,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACpB,yKAGG,YAAa,CAEb,qBAAsB,CAEtB,sBAAuB,CAC1B,yKAGG,wBAAyB","sourcesContent":[".root {\n    display: flex;\n\n    margin: 6px 0;\n\n    align-items: baseline;\n    justify-content: space-between;\n}\n\n.cellDescription {\n    color: #000000;\n\n    font-size: 12px;\n    font-weight: 600;\n    line-height: 16px;\n}\n\n.arrowWrapper {\n    display: flex;\n\n    width: calc(50% - 6px);\n\n    justify-content: center;\n}\n\n.arrowRotated {\n    transform: rotate(180deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ExternalTransitionMenu-SourceDestinationDescription-_SourceDestinationDescription__root",
	"cellDescription": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ExternalTransitionMenu-SourceDestinationDescription-_SourceDestinationDescription__cellDescription",
	"arrowWrapper": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ExternalTransitionMenu-SourceDestinationDescription-_SourceDestinationDescription__arrowWrapper",
	"arrowRotated": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ExternalTransitionMenu-SourceDestinationDescription-_SourceDestinationDescription__arrowRotated"
};
export default ___CSS_LOADER_EXPORT___;
