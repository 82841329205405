import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDefaultState } from '@common/hooks';

import { DropdownParams, DropdownOptions } from './types';
import { parsePlacement, stringifyPlacement, shiftByDirection } from './helpers';

export function useDropdown<E extends Element, P extends DropdownParams>(
    props?: P,
    // Hack to define ref types
    element?: new () => E,
): [DropdownOptions, MutableRefObject<E>, Omit<P, keyof DropdownParams>] {
    const {
        dropdownRef,
        trigger,
        placement: placementProp = 'bottom',
        autoPlace,
        dropdownClassName,
        stretch,
        closeDelay = 500,
        showDelay = trigger === 'hover' || trigger?.includes('hover') ? 100 : 0,
        target,
        isDropdownShow,
        isDropdownHide,
        onShowChangeDropdown,
        onHideChangeDropdown,
        onChangeDropdownPlacement,
        ...rest
    } = props || ({} as P);
    const destroyedRef = useRef(false);
    const [placement, setPlacement] = useDefaultState<typeof placementProp, typeof onChangeDropdownPlacement>(
        undefined,
        onChangeDropdownPlacement,
        placementProp,
    );
    const [isShow, setShow] = useDefaultState(isDropdownShow, onShowChangeDropdown, false);
    const [isHide, setHide] = useDefaultState(isDropdownHide, onHideChangeDropdown, true);
    const [isHover, setHover] = useState(false);
    const isShowRef = useRef<boolean>();
    const isHideRef = useRef<boolean>();
    const showTimer = useRef<any>();
    const hideTimer = useRef<any>();
    const defaultTargetRef = useRef<E>();
    const update = useRef<boolean>(false);
    const destructors = useRef<Function[]>([]);
    const parsedPlacement = useMemo(() => parsePlacement(placement), [placement]);

    isShowRef.current = isShow;
    isHideRef.current = isHide;

    const normalizedTrigger = typeof trigger === 'string' ? [trigger] : trigger || ['click'];
    const memoizedTrigger = useMemo(() => normalizedTrigger, normalizedTrigger); // FIXME: dynamic deps

    useEffect(
        () => () => {
            destroyedRef.current = true;
        },
        [],
    );

    useEffect(() => {
        if (isShow && autoPlace) {
            const observer = new IntersectionObserver(
                ([{ intersectionRatio, intersectionRect }]) => {
                    if (intersectionRatio !== 1) {
                        const rect = dropdown.parent.current.getBoundingClientRect();
                        const top = (rect.top - intersectionRect.top) | 0;
                        const left = (rect.left - intersectionRect.left) | 0;
                        const bottom = (rect.bottom - intersectionRect.bottom) | 0;
                        const right = (rect.right - intersectionRect.right) | 0;
                        const direction = top ? 'top' : left ? 'left' : bottom ? 'bottom' : right ? 'right' : '';

                        if (direction) {
                            const shiftedPlacement = shiftByDirection(direction, parsedPlacement, autoPlace);
                            const newPlacement = stringifyPlacement(shiftedPlacement);
                            setPlacement(newPlacement);
                        }
                    }
                },
                {
                    threshold: [0.9, 1],
                },
            );

            observer.observe(dropdown.parent.current);

            return () => {
                observer.disconnect();
            };
        } else if (placement !== placementProp && !isShow) {
            setPlacement(placementProp);
        }

        return () => {};
    }, [isShow, parsedPlacement, placementProp]);

    const close = useCallback(() => {
        if (showTimer.current) {
            clearTimeout(showTimer.current);
            showTimer.current = undefined;
            setShow(false);
            return;
        }

        setHide(true);

        clearTimeout(hideTimer.current);
        hideTimer.current = setTimeout(() => {
            hideTimer.current = undefined;
            if (!destroyedRef.current) {
                setShow(false);
            }
        }, closeDelay);
    }, []);

    const show = useCallback(() => {
        if (hideTimer.current) {
            clearTimeout(hideTimer.current);
            hideTimer.current = undefined;
            setHide(false);
            return;
        }

        clearTimeout(showTimer.current);
        showTimer.current = setTimeout(() => {
            showTimer.current = setTimeout(() => {
                showTimer.current = undefined;
                if (!destroyedRef.current) {
                    setHide(false);
                }
            }, 100);
            if (!destroyedRef.current) {
                setShow(true);
            }
        }, showDelay);
    }, []);

    const dropdown: DropdownOptions = useMemo(
        () => ({
            trigger,
            dropdownClassName,
            isDropdownShow: isShow,
            isDropdownHide: isHide,
            show,
            close,
            stretch: stretch,
            isHover,
            placement,
            target: target || defaultTargetRef,
        }),
        [target, trigger, dropdownClassName, isShow, isHide, show, close, stretch, placement],
    );

    if (dropdownRef) {
        dropdownRef.current = dropdown;
    }

    useEffect(() => {
        const destroy = () => {
            destructors.current.forEach((destructor) => destructor());
        };
        if (update.current) {
            destroy();
            destructors.current = [];
        }

        if (dropdown.target.current instanceof Element) {
            const element = dropdown.target.current;

            if (memoizedTrigger.includes('click')) {
                const listener = (e: MouseEvent) => {
                    // TODO: remove the next line of code after update to React 17+
                    // @ts-ignore
                    if (e._stopPropagation && e._stopPropagation !== e.currentTarget) return;

                    if (isShowRef.current) {
                        close();
                    } else {
                        show();
                    }
                };

                element.addEventListener('click', listener);
                destructors.current.push(() => element.removeEventListener('click', listener));
            } else {
                const clickListener = (e: MouseEvent) => {
                    if (isShowRef.current) {
                        // TODO: change to `e.stopPropagation()` after update to React 17+
                        // @ts-ignore
                        e._stopPropagation = e._stopPropagation || element;
                    }
                };

                element.addEventListener('mousedown', clickListener);
                destructors.current.push(() => element.removeEventListener('mousedown', clickListener));
            }

            if (memoizedTrigger.includes('hover')) {
                const listenerEnter = () => {
                    setHover(true);
                    show();
                };
                const listenerLeave = () => {
                    setHover(false);
                    close();
                };

                element.addEventListener('mouseenter', listenerEnter);
                element.addEventListener('mouseleave', listenerLeave);
                destructors.current.push(() => {
                    element.removeEventListener('mouseenter', listenerEnter);
                    element.removeEventListener('mouseleave', listenerLeave);
                });
            }

            if (memoizedTrigger.includes('focus')) {
                const focusListener = () => {
                    show();
                };

                element.addEventListener('focus', focusListener);
                destructors.current.push(() => element.removeEventListener('focus', focusListener));
            }
        }

        update.current = true;

        return destroy;
    }, [memoizedTrigger]);

    return [dropdown, dropdown.target, rest];
}
