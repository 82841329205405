import * as React from 'react';
import classNames from 'classnames';
import * as style from './CorrectionCardTable.scss';

export const CorrectionCardTable: React.FC = ({ children }) => {
    return <div className={style.body}>{children}</div>;
};

export const CorrectionCardCellHeader: React.FC = ({ children }) => {
    return <div className={classNames(style.cellHeader, style.cell)}>{children}</div>;
};

export const CorrectionCardCellContent: React.FC = ({ children }) => {
    return <div className={style.cell}>{children}</div>;
};
