import * as React from 'react';

import * as style from './DashboardUrlEditor.scss';

import {
    Icon,
    IconType,
    Input_redesign as Input,
    InputTheme_redesign as InputTheme,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
} from 'sber-marketing-ui';

interface Props {
    isOpened: boolean;
    inputValue: string;
    onOpenerClick: () => void;
    onInputEdit: (value: string) => void;
    onCopyButtonClick: () => void;
    onSaveButtonClick: () => void;
}

export const DashboardUrlEditorTemplate = ({
    isOpened,
    inputValue,
    onOpenerClick,
    onInputEdit,
    onCopyButtonClick,
    onSaveButtonClick,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.opener} onClick={onOpenerClick}>
                <Icon type={IconType.PENCIL} svgSize={16} />
            </div>

            {isOpened && (
                <div className={style.content}>
                    <div className={style.topLine}>
                        <div className={style.title}>Редактировать ссылку</div>

                        <div className={style.copyButton} onClick={onCopyButtonClick}>
                            Скопировать текущую
                        </div>
                    </div>

                    <div className={style.edit}>
                        <div className={style.input}>
                            <Input
                                value={inputValue}
                                placeholder={'Вставьте новую ссылку на дашборд'}
                                theme={InputTheme.Borderless}
                                onInputChange={onInputEdit}
                            />
                        </div>

                        <div className={style.saveButton}>
                            <Button theme={ButtonTheme.MainRounded} onClick={onSaveButtonClick}>
                                Сохранить
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
