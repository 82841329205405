import { Dictionary } from 'lodash';
import { Tag, TagColorType, TagEmojiType } from '@mrm/tags';

import { LoadingStatus } from '@store/commonTypes';

export interface TagsState {
    entities: Tag[];
    loadingStatus: LoadingStatus;
    byId: EntitiesStore;
    byTitle: EntitiesStore;
}

export interface EntitiesStore {
    keys: string[];
    dictionary: Dictionary<Tag>;
}

export const TagColors: TagColorType[] = [
    TagColorType.Color1,
    TagColorType.Color2,
    TagColorType.Color3,
    TagColorType.Color4,
    TagColorType.Color5,
    TagColorType.Color6,
    TagColorType.Color7,
    TagColorType.Color8,
    TagColorType.Color9,
    TagColorType.Color10,
    TagColorType.Color11,
    TagColorType.Color12,
];

export const TagEmojis: TagEmojiType[] = [
    TagEmojiType.Profile,
    TagEmojiType.Popup,
    TagEmojiType.Phone,
    TagEmojiType.Chain,
    TagEmojiType.Folder,
    TagEmojiType.Calendar,
    TagEmojiType.Lighbulb,
    TagEmojiType.Money,
    TagEmojiType.Pencil,
    TagEmojiType.Star,
    TagEmojiType.Question,
    TagEmojiType.Gear,
    TagEmojiType.PatternDiagonalStripes,
    TagEmojiType.PatternStars,
    TagEmojiType.PatternDashes,
    TagEmojiType.PatternHorizontalStripes,
    TagEmojiType.PatternDots,
    TagEmojiType.PatternBackArrow,
];

export interface UpdateExistingTagPayload extends Pick<Tag, 'id'>, Partial<Pick<Tag, 'color' | 'emoji'>> {}
