// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-ConfirmFilterButton-_ConfirmFilterButton__root{display:flex;justify-content:center;width:100%;margin-top:16px;padding:12px;font-family:'Open Sans', sans-serif;font-size:14px;background:#000;border:none;border-radius:4px;color:#fff;cursor:pointer;transition:background .3s}.src-client-modules-common-ConfirmFilterButton-_ConfirmFilterButton__root:hover{background:#262626}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/ConfirmFilterButton/ConfirmFilterButton.scss"],"names":[],"mappings":"AAAA,0EACE,YAAa,CACb,sBAAuB,CAEvB,UAAW,CAEX,eAAgB,CAChB,YAAa,CAEb,mCAAoC,CACpC,cAAe,CAEf,eAAgB,CAChB,WAAY,CACZ,iBAAkB,CAClB,UAAW,CACX,cAAe,CACf,yBAA0B,CAjB5B,gFAoBI,kBAAmB","sourcesContent":[".root {\n  display: flex;\n  justify-content: center;\n\n  width: 100%;\n\n  margin-top: 16px;\n  padding: 12px;\n\n  font-family: 'Open Sans', sans-serif;\n  font-size: 14px;\n\n  background: #000;\n  border: none;\n  border-radius: 4px;\n  color: #fff;\n  cursor: pointer;\n  transition: background .3s;\n\n  &:hover {\n    background: #262626;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-ConfirmFilterButton-_ConfirmFilterButton__root"
};
export default ___CSS_LOADER_EXPORT___;
