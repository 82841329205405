import * as React from 'react';
import * as moment from 'moment';

import * as style from './TaskDates.scss';

import { InputType, LabeledInput } from 'sber-marketing-ui';

interface Props {
    deadline: moment.Moment;
    editRight: boolean;
    onTaskDeadlineChange: (value: moment.Moment) => void;
}

export const TaskDates: React.FC<Props> = ({ deadline, editRight, onTaskDeadlineChange }) => {
    return (
        <div className={style.root}>
            <div className={style.date}>
                <LabeledInput
                    qaId="briefTaskDeadlineDatepicker"
                    value={deadline}
                    title={'Дедлайн'}
                    errorMessage={'Обязательное поле'}
                    displayError={!deadline}
                    type={InputType.DatePicker}
                    onValueChange={(value) => onTaskDeadlineChange(value as moment.Moment)}
                    disabled={!editRight}
                />
            </div>
        </div>
    );
};
