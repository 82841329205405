import * as React from 'react';
import * as lodash from 'lodash';

import * as style from './TableBody.scss';

import type {
    CellPosition,
    Point,
    ColumnName,
    ColumnWidths,
    LineHeights,
    LineId,
    TableBodyCellParams,
    CellUpdateHandler,
} from '../../types';
import { CellEvent } from '../../types';

import { CustomScrollbar_new as CustomScrollbar, ScrollbarComponent } from 'sber-marketing-ui';
import { Cursor } from '../Cursor';
import { Cell } from '../Cell';
import { StickyWrapper, StickyWrapperPosition } from '../StickyWrapper';

interface Props {
    allColumns: ColumnName[];
    columns: ColumnName[];
    leftFixedColumns: ColumnName[];
    rightFixedColumns: ColumnName[];
    lines: LineId[];
    cursorPosition: CellPosition;
    visibleColumnsIndexes: number[];
    visibleLinesIndexes: number[];
    columnWidths: ColumnWidths;
    lineHeights: LineHeights;
    columnsSumWidth: number;
    leftFixedSumWidth: number;
    rightFixedSumWidth: number;
    sumHeight: number;
    tableBodyMaxHeight: number;
    rootRef: React.RefObject<HTMLDivElement>;
    viewportRef: React.RefObject<HTMLDivElement>;
    tableBodyRef: (component: ScrollbarComponent) => void;
    leftFixedColumnsRef: (component: ScrollbarComponent) => void;
    rightFixedColumnsRef: (component: ScrollbarComponent) => void;
    stickyScrollbarRef: (component: ScrollbarComponent) => void;
    cursorRef: (component: Cursor) => void;
    getCellParams: (
        position: CellPosition,
        onCellParamsUpdateHandler?: CellUpdateHandler<TableBodyCellParams>,
    ) => TableBodyCellParams;
    selectCell: (position: CellPosition) => void;
    onBodyScroll: () => void;
    onLeftFixedColumnsScroll: () => void;
    onRightFixedColumnsScroll: () => void;
    onStickyScrollbarScroll: () => void;
    onCellEvent: (eventType: CellEvent, position: CellPosition) => void;
}

export const TableBody = ({
    allColumns,
    columns,
    leftFixedColumns,
    rightFixedColumns,
    lines,
    cursorPosition,
    visibleColumnsIndexes,
    visibleLinesIndexes,
    columnWidths,
    lineHeights,
    columnsSumWidth,
    leftFixedSumWidth,
    rightFixedSumWidth,
    sumHeight,
    tableBodyMaxHeight,
    rootRef,
    viewportRef,
    tableBodyRef,
    leftFixedColumnsRef,
    rightFixedColumnsRef,
    stickyScrollbarRef,
    cursorRef,
    getCellParams,
    selectCell,
    onBodyScroll,
    onLeftFixedColumnsScroll,
    onRightFixedColumnsScroll,
    onStickyScrollbarScroll,
    onCellEvent,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            {!lodash.isEmpty(leftFixedColumns) && (
                <div className={style.leftFixedColumns} style={{ width: leftFixedSumWidth }}>
                    <CustomScrollbar
                        scrollbarRef={leftFixedColumnsRef}
                        maxHeight={tableBodyMaxHeight}
                        onScroll={onLeftFixedColumnsScroll}
                        freezeScrollX
                        hideScrollX
                        hideScrollY
                    >
                        <div className={style.leftFixedColumnsScroller} style={{ height: sumHeight }}>
                            {renderFixedColumnsCells(leftFixedColumns)}
                            {renderCursor(cursorPosition, leftFixedColumns)}
                        </div>
                    </CustomScrollbar>
                </div>
            )}

            <div className={style.viewport} ref={viewportRef}>
                <CustomScrollbar
                    scrollbarRef={tableBodyRef}
                    maxHeight={tableBodyMaxHeight}
                    hideScrollY={!lodash.isEmpty(rightFixedColumns)}
                    onScroll={onBodyScroll}
                >
                    <div className={style.bodyScroller} style={{ width: columnsSumWidth, height: sumHeight }}>
                        {renderCells()}
                        {renderCursor(cursorPosition, columns)}
                    </div>
                </CustomScrollbar>
            </div>

            {!lodash.isEmpty(rightFixedColumns) && (
                <div className={style.rightFixedColumns} style={{ width: rightFixedSumWidth }}>
                    <CustomScrollbar
                        scrollbarRef={rightFixedColumnsRef}
                        maxHeight={tableBodyMaxHeight}
                        onScroll={onRightFixedColumnsScroll}
                        freezeScrollX
                        hideScrollX
                    >
                        <div className={style.rightFixedColumnsScroller} style={{ height: sumHeight }}>
                            {renderFixedColumnsCells(rightFixedColumns)}
                            {renderCursor(cursorPosition, rightFixedColumns)}
                        </div>
                    </CustomScrollbar>
                </div>
            )}

            <StickyWrapper
                position={StickyWrapperPosition.Bottom}
                block={rootRef.current}
                customStyle={{ paddingLeft: 80, paddingRight: 440 }}
            >
                <div style={{ paddingLeft: leftFixedSumWidth, paddingRight: rightFixedSumWidth, height: 8 }}>
                    <CustomScrollbar scrollbarRef={stickyScrollbarRef} hideScrollY onScroll={onStickyScrollbarScroll}>
                        <div style={{ width: columnsSumWidth, height: 8 }} />
                    </CustomScrollbar>
                </div>
            </StickyWrapper>
        </div>
    );

    function renderCells(): JSX.Element[] {
        const cells: JSX.Element[] = [];
        let heightSum = 0;
        let widthSum = 0;

        // if (!lodash.isEmpty(visibleLinesIndexes) && !lodash.isEmpty(visibleColumnsIndexes)) {
        if (!lodash.isEmpty(visibleColumnsIndexes)) {
            lines.forEach((lineId, lineIndex) => {
                // if (visibleLinesIndexes.includes(lineIndex)) {
                widthSum = 0;

                columns.forEach((columnName, columnIndex) => {
                    if (visibleColumnsIndexes.includes(columnIndex)) {
                        cells.push(renderCell({ columnName, lineId }, { x: widthSum, y: heightSum }));
                    }

                    widthSum += columnWidths[columnName] + 1;
                });
                // }

                const lineHeight = lineHeights[lineId] || 0;

                heightSum += lineHeight + 1;
            });
        }

        return cells;
    }

    function renderFixedColumnsCells(fixedColumns: ColumnName[]): JSX.Element[] {
        const fixedCells: JSX.Element[] = [];
        let heightSum = 0;
        let widthSum = 0;

        // if (!lodash.isEmpty(visibleLinesIndexes)) {
        lines.forEach((lineId, lineIndex) => {
            // if (visibleLinesIndexes.includes(lineIndex)) {
            widthSum = 0;

            fixedColumns.forEach((columnName) => {
                fixedCells.push(renderCell({ columnName, lineId }, { x: widthSum, y: heightSum }));

                widthSum += columnWidths[columnName] + 1;
            });
            // }

            const lineHeight = lineHeights[lineId] || 0;

            heightSum += lineHeight + 1;
        });
        // }

        return fixedCells;
    }

    function renderCell(position: CellPosition, coords: Point): JSX.Element {
        const { columnName, lineId } = position;
        const { x, y } = coords;

        const { сellBackgroundColor } = getCellParams(position);

        return (
            <div
                className={style.cell}
                key={`${columnName} ${lineId}`}
                style={{
                    top: y,
                    left: x,
                    width: columnWidths[columnName],
                    height: lineHeights[lineId],
                    backgroundColor: сellBackgroundColor,
                }}
                onClick={() => onCellEvent(CellEvent.Click, { lineId, columnName })}
            >
                {(!cursorPosition ||
                    !(cursorPosition.columnName === columnName && cursorPosition.lineId === lineId)) && (
                    <Cell position={{ columnName, lineId }} getCellParams={getCellParams} />
                )}
            </div>
        );
    }

    function renderCursor(position: CellPosition, columns: ColumnName[]): JSX.Element {
        if (position === null || !columns.includes(position.columnName) || !lines.includes(position.lineId)) {
            return null;
        }

        const { columnName, lineId } = position;

        return (
            <div
                className={style.cursor}
                style={{
                    top: getItemPosition(lineId, lines, lineHeights),
                    left: getItemPosition(columnName, columns, columnWidths),
                    width: columnWidths[columnName],
                    height: lineHeights[lineId],
                }}
            >
                <Cursor
                    ref={cursorRef}
                    position={position}
                    columns={columns}
                    allColumns={allColumns}
                    lines={lines}
                    getCellParams={getCellParams}
                    selectCell={selectCell}
                    onCellEvent={onCellEvent}
                />
            </div>
        );
    }
};

function getItemPosition<T extends React.ReactText>(itemName: T, items: T[], itemsSize: Record<T, number>): number {
    let sum = 0;
    let index = 0;

    while (items[index] !== itemName && index < items.length + 1) {
        sum += itemsSize[items[index]] + 1;
        index += 1;
    }

    return sum;
}
