import * as React from 'react';
import { WithTooltip } from 'sber-marketing-ui';
import classnames from 'classnames';

import * as styles from '../CommonStyles.scss';

interface Props {
    qaId: string;
    subItemTheme?: boolean;
    skipPadding?: boolean;
    boldValue?: boolean;
    text: string;
    content: string | JSX.Element;
    contentTitle?: string;
}

export function ContentBlock({
    qaId,
    text,
    content,
    contentTitle,
    subItemTheme,
    skipPadding,
    boldValue,
}: Props): JSX.Element {
    let renderedContent: JSX.Element;
    if (typeof content === 'object') {
        renderedContent = content;
    } else {
        renderedContent = (
            <WithTooltip hidden={!contentTitle} content={contentTitle}>
                <div className={styles.contentOverflow}>
                    <span className={classnames(styles.content, boldValue && styles.contentBold)}>
                        {content || '-'}
                    </span>
                </div>
            </WithTooltip>
        );
    }

    return (
        <div
            className={classnames(
                styles.row,
                !skipPadding && styles.rowPadding,
                subItemTheme && styles.rowSubItemTheme,
            )}
            {...{
                'qa-id': qaId,
            }}
        >
            <span className={styles.title}>{text}:&nbsp;</span>

            <div className={styles.contentOverflow}>{renderedContent}</div>
        </div>
    );
}
