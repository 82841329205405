import * as React from 'react';
import { connect } from 'react-redux';
import { RoleId } from 'sber-marketing-types/backend';

import { StoreState } from '@store';
import { getSelectedCard } from '@store/dashboardPage/selectors';
import { getLoginUser } from '@store/user';

import { BondActivitiesWithBudgetItemIdContainer } from './BondActivitiesWithBudgetItemIdContainer';
import { WithRequestsQuery } from './WithRequestsQuery';

interface Props extends Partial<MapProps> {
    enableControls: boolean;
    updateCards: () => Promise<void>;
}

interface MapProps {
    requestId: string;
    activityId: number;
    userIsBudgetExpert: boolean;
}

@(connect(mapStateToProps, null) as any)
export class BondActivitiesWithBudgetItemIdConnected extends React.PureComponent<Props> {
    public render() {
        const { enableControls, requestId, activityId, userIsBudgetExpert } = this.props;

        return (
            <WithRequestsQuery requestId={requestId} activityId={activityId}>
                {({ requestStatus, approveRequest, rejectRequest, revertRequest }) =>
                    React.createElement(BondActivitiesWithBudgetItemIdContainer, {
                        requestId,
                        requestStatus,
                        enableControls,
                        userIsBudgetExpert,
                        approveRequest,
                        rejectRequest,
                        revertRequest,
                        updateCards: this.props.updateCards,
                    })
                }
            </WithRequestsQuery>
        );
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const selectedCard = getSelectedCard(state);
    const { roles } = getLoginUser(state).attributes;
    const userIsBudgetExpert = roles.some((role) => role.id === RoleId.BudgetExpert);

    if (!selectedCard) {
        return {
            requestId: null,
            activityId: null,
            userIsBudgetExpert,
        };
    }

    return {
        requestId: selectedCard.id,
        activityId: Number(selectedCard.activityId),
        userIsBudgetExpert,
    };
}
