// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-pivotTable-CreativeTable-CellTypes-LineHeader-ContextMenuWrapper-_ContextMenuWrapper__root{position:absolute;top:0;bottom:0;left:0;right:0;pointer-events:none}.src-client-modules-pivotTable-CreativeTable-CellTypes-LineHeader-ContextMenuWrapper-_ContextMenuWrapper__wrapper{position:absolute;z-index:60;background:#ffffff;box-shadow:0px 2px 10px rgba(0,0,0,0.15);border-radius:6px;pointer-events:auto;transform:translateY(-100%)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/pivotTable/CreativeTable/CellTypes/LineHeader/ContextMenuWrapper/ContextMenuWrapper.scss"],"names":[],"mappings":"AAAA,+GACI,iBAAkB,CAClB,KAAM,CACN,QAAS,CACT,MAAO,CACP,OAAQ,CAER,mBAAoB,CACvB,kHAGG,iBAAkB,CAClB,UAAW,CACX,kBAAmB,CACnB,wCAA4C,CAC5C,iBAAkB,CAElB,mBAAoB,CACpB,2BAA4B","sourcesContent":[".root {\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n\n    pointer-events: none;\n}\n\n.wrapper {\n    position: absolute;\n    z-index: 60;\n    background: #ffffff;\n    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);\n    border-radius: 6px;\n\n    pointer-events: auto;\n    transform: translateY(-100%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-pivotTable-CreativeTable-CellTypes-LineHeader-ContextMenuWrapper-_ContextMenuWrapper__root",
	"wrapper": "src-client-modules-pivotTable-CreativeTable-CellTypes-LineHeader-ContextMenuWrapper-_ContextMenuWrapper__wrapper"
};
export default ___CSS_LOADER_EXPORT___;
