import { Budget, BudgetItem } from '@mrm/budget';

import { LoadingStatus } from '@store/commonTypes';

export enum ComponentState {
    Closed = 'Closed',
    Opened = 'Opened',
}

export interface TransferBudgetItemsToPlanningMenuState {
    props: TransferBudgetItemsToPlanningMenuComponentProps;
    data: TransferBudgetItemsToPlanningMenuComponentData;
}

export interface TransferBudgetItemsToPlanningMenuComponentProps {
    componentState: ComponentState;
    activityToSelectLines: string;
    hoveredLineId: string;
    linesToTransfer: string[];
    planningBudgetId: string;
}

export interface TransferBudgetItemsToPlanningMenuComponentData {
    planningBudgets: TransferBudgetItemsToPlanningMenuPlanningBudgets;
    activityBudgetItems: TransferBudgetItemsToPlanningMenuActivityBudgetItems;
}

export interface TransferBudgetItemsToPlanningMenuPlanningBudgets {
    loadingStatus: LoadingStatus;
    items: Budget[];
}

export interface TransferBudgetItemsToPlanningMenuActivityBudgetItems {
    loadingStatus: LoadingStatus;
    items: BudgetItem[];
}
