import * as React from 'react';
import EmojiPickerReact, {
    EmojiStyle,
    SkinTonePickerLocation,
    SuggestionMode,
    EmojiClickData,
} from 'emoji-picker-react';
import classNames from 'classnames';

import { IconType } from 'sber-marketing-ui';

import { DropdownIconTag, DropdownIconTagProps } from '@common/components';

import * as styles from './EmojiPicker.scss';

export interface EmojiPickerProps extends Omit<DropdownIconTagProps, 'icon'> {
    onEmojiClick?: (emoji: EmojiClickData, event: MouseEvent) => void;
}

export function EmojiPicker({ children, className, onEmojiClick, ...props }: EmojiPickerProps) {
    return (
        <DropdownIconTag
            data-qa-id="EmojiPicker"
            icon={IconType.ADD_EMOJI}
            tagContent={children}
            {...props}
            dropdownClassName={styles.dropdown}
            className={classNames(styles.root, className)}
        >
            <EmojiPickerReact
                onEmojiClick={onEmojiClick}
                lazyLoadEmojis
                skinTonesDisabled
                height={300}
                emojiVersion="0.6"
                emojiStyle={EmojiStyle.APPLE}
                autoFocusSearch={false}
                suggestedEmojisMode={SuggestionMode.RECENT}
                skinTonePickerLocation={SkinTonePickerLocation.PREVIEW}
                previewConfig={{
                    showPreview: false,
                }}
            />
        </DropdownIconTag>
    );
}
