import * as React from 'react';
import classNames from 'classnames';

import * as styles from './styles.scss';

interface Props {
    items: string[];
}

export const Table: React.FC<Props> = ({ items }) => {
    return (
        <table className={styles.root}>
            <thead>
                <tr className={classNames(styles.row, styles.headerRow)}>
                    <td className={classNames(styles.cell, styles.firstCell, styles.headerCell)}>Канал/Площадка</td>
                    <td className={classNames(styles.cell, styles.headerCell)}>Текст</td>
                </tr>
            </thead>
            <tbody>
                {items.map((item) => (
                    <tr key={item} className={styles.row}>
                        <td className={classNames(styles.cell, styles.firstCell)}>Яндекс Директ</td>
                        <td className={styles.cell}>{item}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
