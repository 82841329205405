import * as React from 'react';

import { Utils } from '@common/Utils';

import { WithTitle } from '../WithTitle';
import * as style from './CountChanges.scss';

interface Props {
    count: number;
}

export const CountChanges: React.FC<Props> = ({ count }) => (
    <WithTitle title={'Корректируемые параметры'}>
        <div className={style.text}>
            {count} {Utils.getDeclensionByNumber(count, ['параметр', 'параметра', 'параметров'])}
        </div>
    </WithTitle>
);
