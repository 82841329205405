import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import {
    getSelectedStatisticsItem,
    StatisticsItem,
    StatisticsActivityType,
    StatisticsTaskType,
} from '@store/leaderDashboardPage';
import * as actions from '@store/leaderDashboardPage/actions';

interface UseState {
    (): UseStateReturn;
}

interface UseStateReturn extends Selected {
    resetStatisticsTypeFilter: () => void;
}

interface Selected {
    statisticsItem: StatisticsItem;
}

export const useState: UseState = () => {
    const dispatch = useDispatch();

    const { statisticsItem } = useSelector<StoreState, Selected>((state) => {
        return {
            statisticsItem: getSelectedStatisticsItem(state),
        };
    });

    const resetStatisticsTypeFilter = React.useCallback(() => {
        dispatch(
            actions.setStatisticsTypeFilters({
                [StatisticsActivityType.ACTIVE]: true,
                [StatisticsActivityType.PLANNED_START]: true,
                [StatisticsActivityType.OVERDUE_TASKS]: true,
                [StatisticsActivityType.OVERDUE_STAGES]: true,
                [StatisticsTaskType.OVERDUE]: true,
                [StatisticsTaskType.COMING_DEADLINE]: true,
                [StatisticsTaskType.ACTIVE]: true,
                [StatisticsTaskType.CLOSED]: true,
            }),
        );
    }, [actions.setStatisticsTypeFilters]);

    return {
        statisticsItem,
        resetStatisticsTypeFilter,
    };
};
