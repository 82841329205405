import * as React from 'react';

import * as style from './FilesCell.scss';

import { CommentsButton } from '../CommentsButton';
import { ContextMenu } from '../ContextMenu';

interface Props {
    title: string;
    customStyle?: any;
    commentButtonProps?: { hasNewComments: boolean; onClick: () => void };
}

export const FilesCellTemplate = ({ title, customStyle, commentButtonProps }: Props): JSX.Element => {
    return (
        <>
            <CommentsButton {...commentButtonProps} />
            <ContextMenu items={[{ title: 'Смотреть комментарии', onClick: commentButtonProps?.onClick }]} />

            <div className={style.root} style={{ ...customStyle }}>
                {title}
            </div>
        </>
    );
};
