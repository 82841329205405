import { includes } from 'lodash';
import { Utils } from '@common/Utils';
import type { StatisticsItem } from '@store/leaderDashboardPage';
import type { ListItemParams } from './types';

export const convertStatisticsItemsToFilterItems = (
    currentStatisticsItems: StatisticsItem[],
    statisticsItems: StatisticsItem[],
): ListItemParams[] => {
    return currentStatisticsItems.map((statisticsItem) => {
        const hasChildren = statisticsItem.meta.childrenIds.length;

        if (hasChildren) {
            const childrenStatisticsItems = getChildrenStatisticsItems(statisticsItem, statisticsItems);
            return {
                id: statisticsItem.id,
                name: statisticsItem.meta.name,
                childrenItems: convertStatisticsItemsToFilterItems(childrenStatisticsItems, statisticsItems),
            };
        }

        return {
            id: statisticsItem.id,
            name: statisticsItem.meta.name,
            childrenItems: [],
        };
    });
};

export const getDepartmentDeclensionByNumber = (count: number): string => {
    return Utils.getDeclensionByNumber(count, ['подразделение', 'подразделения', 'подразделений']);
};

export const getUserDeclensionByNumber = (count: number): string => {
    return Utils.getDeclensionByNumber(count, ['пользователь', 'пользователя', 'пользователей']);
};

const getChildrenStatisticsItems = (statisticsItem: StatisticsItem, statisticsItems: StatisticsItem[]) => {
    return statisticsItems.filter(({ id }) => includes(statisticsItem.meta.childrenIds, id));
};

export const getTypeNameFromStatisticsItems = (statisticsItems: StatisticsItem[], count: number): string => {
    const haveTypeUsersInFilteredChildrenStatisticsItems = statisticsItems.every(({ meta }) => meta.type === 'user');

    return haveTypeUsersInFilteredChildrenStatisticsItems
        ? getUserDeclensionByNumber(count)
        : getDepartmentDeclensionByNumber(count);
};
