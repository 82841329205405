import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import {
    getFilteredChildrenOfSelectedStatisticsItem,
    getStatisticsTypeFilters,
    getFilterPeriod,
    getFilterDate,
    FilterPeriod,
    StatisticsActivityType,
    StatisticsTaskType,
} from '@store/leaderDashboardPage';
import type { StatisticsType, StatisticsItem } from '@store/leaderDashboardPage';
import * as action from '@store/leaderDashboardPage/actions';

interface UseState {
    (): UseStateReturn;
}

interface UseStateReturn extends Selected {
    setSelectedStatisticsItemId: (id: string) => void;
}

interface Selected {
    selectedFilterPeriod: FilterPeriod;
    selectedFilterDate: string;
    statisticsItems: StatisticsItem[];
    statisticsTypeFilters: Record<StatisticsType, boolean>;
}

export const useState: UseState = () => {
    const dispatch = useDispatch();

    const { selectedFilterPeriod, selectedFilterDate, statisticsItems, statisticsTypeFilters } = useSelector<
        StoreState,
        Selected
    >((state) => {
        return {
            selectedFilterPeriod: getFilterPeriod(state),
            selectedFilterDate: getFilterDate(state),
            statisticsItems: getFilteredChildrenOfSelectedStatisticsItem(state),
            statisticsTypeFilters: getStatisticsTypeFilters(state),
        };
    });

    const setSelectedStatisticsItemId = React.useCallback((id) => {
        dispatch(action.setSelectedStatisticsItemId(id));
    }, []);

    return {
        selectedFilterPeriod,
        selectedFilterDate,
        statisticsItems,
        statisticsTypeFilters,
        setSelectedStatisticsItemId,
    };
};

export type { StatisticsItem };

export { StatisticsType, StatisticsTaskType, StatisticsActivityType };
