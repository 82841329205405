import { Success, Failure } from 'typescript-fsa';
import { omit, without } from 'lodash';

import { TaskPageState, RemoveCommentaryPayload, FetchError } from '../types';

export const removeCommentaryStartedReducer = (
    state: TaskPageState,
    payload: RemoveCommentaryPayload,
): TaskPageState => {
    return state;
};

export const removeCommentaryDoneReducer = (
    { comments, channels, commentIdToReplyTo, ...restState }: TaskPageState,
    { params: { id, channelId } }: Success<RemoveCommentaryPayload, void>,
): TaskPageState => ({
    ...restState,
    commentIdToReplyTo: id === commentIdToReplyTo ? null : commentIdToReplyTo,
    channels: {
        ...channels,
        [channelId]: {
            ...channels[channelId],
            commentsIds: without(channels[channelId].commentsIds, id),
        },
    },
    comments: omit(comments, id),
});

export const removeCommentaryErrorReducer = (
    state: TaskPageState,
    payload: Failure<RemoveCommentaryPayload, FetchError>,
): TaskPageState => state;
