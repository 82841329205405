import * as React from 'react';
import autobind from 'autobind-decorator';
import { TooltipAnchor } from 'sber-marketing-ui';

import { CopyTextButton } from './CopyTextButton';

interface Props {
    text: string;
    title?: string;
    coppiedTitle?: string;
    tooltipAnchor?: TooltipAnchor;
}

interface State {
    isCopied: boolean;
}

export class CopyTextButtonContainer extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isCopied: false,
        };
    }

    public render(): JSX.Element {
        const { title, coppiedTitle, tooltipAnchor } = this.props;
        const { isCopied } = this.state;

        const titleToUse = isCopied && coppiedTitle ? coppiedTitle : title;

        return (
            <CopyTextButton
                title={titleToUse}
                tooltipAnchor={tooltipAnchor}
                onClick={this.onClick}
                onMouseLeave={this.onMouseLeave}
            />
        );
    }

    @autobind
    public async onClick(): Promise<void> {
        const { text } = this.props;
        await (navigator as any).clipboard.writeText(text);

        this.setState({
            isCopied: true,
        });
    }

    @autobind
    private onMouseLeave(): void {
        this.setState({
            isCopied: false,
        });
    }
}
