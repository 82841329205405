import * as React from 'react';

import { CloseButton } from './CloseButton';
import { Title } from './Title';

import * as style from './Header.scss';

interface Props {
    titleText: string;
    onCloseButtonClick(): void;
}

export const Header: React.FC<Props> = ({ titleText, onCloseButtonClick }) => {
    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'budgetExecutionLineModalBindActivityPopupHeader',
            }}
        >
            <Title text={titleText} />
            <CloseButton onClick={onCloseButtonClick} />
        </div>
    );
};
