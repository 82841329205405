import * as React from 'react';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { TaskCardParams } from 'sber-marketing-types/frontend';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getActivityTasksPageState, setActivityNeedStages, setTaskFilters } from '@store/activityTasksPage';
import {
    Stage,
    getActivityStageState,
    setStagesEditorPopupVisibility,
    resetStagesEditorPopup,
    loadStagesData,
    editStage,
} from '@store/activityTasksPage/stages';

import { ActivityStages, States } from './ActivityStages';

interface Props extends Partial<MapProps & DispatchProps> {
    delayLoading: boolean;
    createTaskByNameAndStage(taskName: string, stageId: string): Promise<void>;
}

interface MapProps {
    loadingStatus: LoadingStatus;
    projectStart: Date;
    projectEnd: Date;
    showPreloader: boolean;
    stages: Stage[];
    canEditStages: boolean;
    showStagesEditorPopup: boolean;
    templateName?: string;
    pageState: States;
    tasksByStageId: {
        [stageId: string]: TaskCardParams[];
    };
}

interface DispatchProps {
    setStagesEditorPopupVisible: () => void;
    loadStagesData: () => void;
    saveActivityAsStageless: () => void;
    setStageStatus: (stageId: string, done: boolean) => void;
    onSelectStageClick: (stageId: string) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class ActivityStagesContainer extends React.PureComponent<Props> {
    public componentDidMount(): void {
        if (!this.props.delayLoading) {
            this.props.loadStagesData();
        }
    }

    public componentDidUpdate(): void {
        if (!this.props.delayLoading && this.props.loadingStatus === LoadingStatus.NOT_LOADED) {
            this.props.loadStagesData();
        }
    }

    public render(): JSX.Element {
        const {
            projectStart,
            projectEnd,
            showPreloader,
            pageState,
            showStagesEditorPopup,
            templateName,
            stages,
            canEditStages,
            tasksByStageId,
            createTaskByNameAndStage,
            setStagesEditorPopupVisible,
            saveActivityAsStageless,
            setStageStatus,
            onSelectStageClick,
        } = this.props;

        return (
            <ActivityStages
                projectStart={projectStart}
                projectEnd={projectEnd}
                showPreloader={showPreloader}
                pageState={pageState}
                showStagesEditorPopup={showStagesEditorPopup}
                templateName={templateName}
                stages={stages}
                canEditStages={canEditStages}
                createTaskByNameAndStage={createTaskByNameAndStage}
                onOpenStagesEditorPopupClick={setStagesEditorPopupVisible}
                onSaveActivityAsStagelessButtonClick={saveActivityAsStageless}
                onStageStatusChange={setStageStatus}
                tasksByStageId={tasksByStageId}
                onSelectStageClick={onSelectStageClick}
            />
        );
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const {
        activity: { needStages, canEdit, realizationStart, realizationEnd },
        activityTasks,
    } = getActivityTasksPageState(state);
    const {
        loadingStatus,
        isStagesEditorPopupVisible,
        existingStages: { entities: stages, selectedTemplateId },
        stageTemplates,
    } = getActivityStageState(state);

    const showPreloader =
        loadingStatus !== LoadingStatus.LOADED || activityTasks.loadingStatus !== LoadingStatus.LOADED;
    const stageTemplate = selectedTemplateId ? stageTemplates.byId[selectedTemplateId] : null;
    const templateName = stageTemplate ? stageTemplate.value : '';

    let pageState: States;
    if (stages.length) {
        pageState = States.ActivityWithStages;
    } else if (needStages) {
        pageState = States.NoAssignedStages;
    } else {
        pageState = States.StagelessActivity;
    }

    return {
        loadingStatus,
        projectStart: new Date(realizationStart),
        projectEnd: new Date(realizationEnd),
        showPreloader,
        pageState,
        showStagesEditorPopup: isStagesEditorPopupVisible,
        templateName,
        stages,
        canEditStages: canEdit,
        tasksByStageId: activityTasks.byStageId,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        setStagesEditorPopupVisible: () => dispatch(setStagesEditorPopupVisibility(true)),
        loadStagesData: () => dispatch(loadStagesData(null)),
        saveActivityAsStageless: () => {
            dispatch(setActivityNeedStages(false));
            dispatch(resetStagesEditorPopup());
        },
        setStageStatus: (id, done) => dispatch(editStage({ id, done })),
        onSelectStageClick: (stageId: string) =>
            dispatch(
                setTaskFilters({
                    activityStage: [stageId],
                }),
            ),
    };
}
