import * as React from 'react';
import classNames from 'classnames';

import { Flex, FlexProps } from '@common/components';

import * as styles from './Typography.scss';

export interface BulletedListProps extends FlexProps {}

export function Typography({ children, className, ...props }: BulletedListProps) {
    return (
        <Flex vertical gap={16} {...props} className={classNames(styles.root, className)}>
            {children}
        </Flex>
    );
}
