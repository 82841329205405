import * as React from 'react';

import { FilterKey } from '@store/budgetPlanning';
import { WithStore } from './WithStore';
import { ItemBehavior } from './ItemBehavior';

export interface Props {
    columnName: FilterKey;
    isExpanded: boolean;
    onExpandChange: (isExpanded: boolean) => void;
    onApplyFiltersButtonClick: () => void;
}

export const ItemConnector: React.FC<Props> = ({
    columnName,
    isExpanded,
    onExpandChange,
    onApplyFiltersButtonClick,
}) => {
    return (
        <WithStore columnName={columnName}>
            {({
                dictionaryType,
                users,
                dictionaries,
                filters,
                filterLoadingStatus,
                showApplyFiltersButton,
                tags,
                loadFilters,
                loadFiltersByColumn,
                setFilters,
            }) => (
                <ItemBehavior
                    columnName={columnName}
                    filters={filters}
                    filterLoadingStatus={filterLoadingStatus}
                    users={users}
                    dictionaryType={dictionaryType}
                    dictionaries={dictionaries}
                    isExpanded={isExpanded}
                    showApplyFiltersButton={showApplyFiltersButton}
                    tags={tags}
                    loadFiltersByColumn={loadFiltersByColumn}
                    loadFilters={loadFilters}
                    setFilters={setFilters}
                    onExpandChange={onExpandChange}
                    onApplyFiltersButtonClick={onApplyFiltersButtonClick}
                />
            )}
        </WithStore>
    );
};
