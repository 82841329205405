import * as React from 'react';

import type { WorkType } from 'sber-marketing-types/frontend';

import { DropdownPortalOption, DropdownPortalOptionProps, Option } from '@common/components';
import { UserOptions } from '@modules/user/components';

export interface UserWorkTypeOptionProps extends DropdownPortalOptionProps<number> {
    portal: React.MutableRefObject<HTMLElement>;
    workType: WorkType.Data;
    isLoading?: boolean;
}

export function UserWorkTypeOption({
    value,
    onSelect,
    portal,
    workType,
    isLoading,
    ...props
}: UserWorkTypeOptionProps) {
    return isLoading ? (
        <Option value={value} {...props} />
    ) : workType.usersCount < 2 ? (
        <Option value={value} {...props} onSelect={onSelect} />
    ) : (
        <DropdownPortalOption
            {...props}
            value={workType}
            params={{ options: { trigger: 'hover', placement: 'rightTop', stretch: true } }}
            portal={portal}
        >
            <UserOptions selected={value} onSelect={onSelect} departmentIds={workType.departmentIds} />
        </DropdownPortalOption>
    );
}
