import * as React from 'react';

import * as style from './TextColumnHeader.scss';

import { WithTooltip, TooltipAnchor, Icon, IconType } from 'sber-marketing-ui';

interface Props {
    title: string;
    tooltip?: string;
}

export const TextColumnHeader = ({ title, tooltip }: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <WithTooltip content={title}>
                <div className={style.title}>{title}</div>
            </WithTooltip>

            {!!tooltip && (
                <WithTooltip className={style.tooltip} anchor={TooltipAnchor.TOP} content={tooltip}>
                    <Icon type={IconType.INFO12} svgSize={12} />
                </WithTooltip>
            )}
        </div>
    );
};
