import * as React from 'react';
import { useSelector } from 'react-redux';

import { FeaturesSelector } from './FeaturesSelector';
import { FeatureList } from './FeatureList';
import { BackButton } from './BackButton';
import { CreateButton } from './CreateButton';
import { ChangeButton } from './ChangeButton';
import { Preloader } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getParamsLoadingStatus, LoadingStatus, getMode } from '@store/autopilot/generationTextForm';

import * as styles from './styles.scss';

interface Props {}

export const ParamsEditor: React.FC<Props> = ({}) => {
    const loading = useSelector((state: StoreState) => getParamsLoadingStatus(state));
    const mode = useSelector((state: StoreState) => getMode(state));

    return (
        <div className={styles.root}>
            <BackButton />
            {mode === 'editingParams' ? <FeaturesSelector /> : <FeatureList />}
            {mode === 'editingParams' ? <CreateButton /> : <ChangeButton />}

            {loading === LoadingStatus.LOADING && <Preloader />}
        </div>
    );
};
