import * as React from 'react';
import classNames from 'classnames';

import { Header } from './Header';
import { ActivityCardList } from './ActivityCardList';
import type { ActivityCardListData } from './ActivityCardList';
import { BindActivityPopup } from './BindActivityPopup';

import * as style from './RelatedActivities.scss';

interface Props {
    lineId: string;
    activityId: string;
    isOpenActivitiesList: boolean;
    isOpenBindActivityPopup: boolean;
    loading: boolean;
    disableBindActivityButton: boolean;
    disableCreateActivityButton: boolean;
    activitiesCards: ActivityCardListData[];
    activitiesCount: number;
    onActivityPopupClose: () => void;
    onOpenActivityListButtonClick: () => void;
    onBindActivityButtonClick: () => void;
    onBindActivityFinish: () => Promise<void>;
}

export const RelatedActivities: React.FC<Props> = ({
    lineId,
    activityId,
    isOpenBindActivityPopup,
    isOpenActivitiesList,
    loading,
    disableBindActivityButton,
    disableCreateActivityButton,
    activitiesCards,
    activitiesCount,
    onOpenActivityListButtonClick,
    onActivityPopupClose,
    onBindActivityButtonClick,
    onBindActivityFinish,
}) => {
    return (
        <div
            className={classNames(style.root, style.blockWrapper, style.bottomBorder)}
            {...{
                'qa-id': 'budgetExecutionLineModalRelatedActivities',
            }}
        >
            <div className={style.headerWrapper}>
                <Header
                    lineId={lineId}
                    activitiesCount={activitiesCount}
                    hasOpenList={isOpenActivitiesList}
                    disableBindActivityButton={disableBindActivityButton}
                    disableCreateActivityButton={disableCreateActivityButton}
                    onBindActivityButtonClick={onBindActivityButtonClick}
                    onOpenListButtonClick={onOpenActivityListButtonClick}
                />
            </div>

            {(Boolean(activitiesCards.length) || loading) && (
                <div className={style.activityCardListWrapper}>
                    <ActivityCardList data={activitiesCards} loading={loading} />
                </div>
            )}

            {isOpenBindActivityPopup && (
                <BindActivityPopup
                    lineId={lineId}
                    activityId={activityId}
                    linkedActivitiesIds={activitiesCards.map(({ id }) => id)}
                    loading={loading}
                    onClose={onActivityPopupClose}
                    onFinish={onBindActivityFinish}
                />
            )}
        </div>
    );
};

export type { ActivityCardListData };
