// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-Card-CardButtonsGroup-_CardButtonsGroup__root{cursor:pointer;transition:0.2s color ease-in-out;color:#8d948f;line-height:0}.src-client-modules-common-Card-CardButtonsGroup-_CardButtonsGroup__deleteButton:hover{color:#e63900}.src-client-modules-common-Card-CardButtonsGroup-_CardButtonsGroup__editButton:hover{color:#19bb4f}.src-client-modules-common-Card-CardButtonsGroup-_CardButtonsGroup__shareButton:hover{color:#19bb4f}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/Card/CardButtonsGroup/CardButtonsGroup.scss"],"names":[],"mappings":"AAAA,yEACI,cAAe,CACf,iCAAkC,CAElC,aAAc,CAEd,aAAc,CACjB,uFAIO,aAAc,CACjB,qFAKG,aAAc,CACjB,sFAKG,aAAc","sourcesContent":[".root {\n    cursor: pointer;\n    transition: 0.2s color ease-in-out;\n\n    color: #8d948f;\n\n    line-height: 0;\n}\n\n.deleteButton {\n    &:hover {\n        color: #e63900;\n    }\n}\n\n.editButton {\n    &:hover {\n        color: #19bb4f;\n    }\n}\n\n.shareButton {\n    &:hover {\n        color: #19bb4f;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-Card-CardButtonsGroup-_CardButtonsGroup__root",
	"deleteButton": "src-client-modules-common-Card-CardButtonsGroup-_CardButtonsGroup__deleteButton",
	"editButton": "src-client-modules-common-Card-CardButtonsGroup-_CardButtonsGroup__editButton",
	"shareButton": "src-client-modules-common-Card-CardButtonsGroup-_CardButtonsGroup__shareButton"
};
export default ___CSS_LOADER_EXPORT___;
