// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-BudgetItemHistoryWidget-HistoryItem-Content-_Content__skeleton{width:71.5%}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetPlanning/LineModal/WidgetsGroups/BudgetItemHistoryWidget/HistoryItem/Content/Content.scss"],"names":[],"mappings":"AAAA,4IACI,WAAY","sourcesContent":[".skeleton {\n    width: 71.5%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeleton": "src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-BudgetItemHistoryWidget-HistoryItem-Content-_Content__skeleton"
};
export default ___CSS_LOADER_EXPORT___;
