import * as React from 'react';
import classnames from 'classnames';
import { orderBy } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { CloseButton } from 'sber-marketing-ui';

import {
    CreativeRequest,
    CreativeRequestItem,
    CreativeRequestComment as CreativeRequestCommentDTO,
    CreativeRequestItemComment as CreativeRequestItemCommentDTO,
} from '@api';

import { StoreState } from '@store';
import { getCreativeSidebarState, setWatchCommentsByItemAndColumn } from '@store/creative/sidebar';

import { CreateCommentForm as CommonCreateCommentForm } from '@common/CreateCommentForm';

import {
    CreativeRequestItemWithTitle,
    useUsersForMentionOrganizationIds,
    useCreativeRequestItems,
    useCreativeRequestComments,
    useCreativeRequestItemComments,
    useCommentsReplyChain,
    useCreativeRequesteItemUnreadComments,
} from './hooks';
import { GroupedComments, CreativeRequestComment, CreativeRequestItemComment } from './GroupedComments';
import { ColumnMarker } from './ColumnMarker';

import * as styles from './Comments.scss';
import * as commonStyles from '../../CommonStyles.scss';

interface Props {
    creativeRequest: CreativeRequest;
}

interface CommentToReplayTo {
    commentId: string;
    columnName: string;
}

function useComments({ creativeRequest }: Props) {
    const dispatch = useDispatch();

    const [activeCreativeRequestItemId, setActiveCreativeRequestItemId] = React.useState<string>(null);
    const [activeCreativeRequestColumn, setActiveCreativeRequestColumn] = React.useState<string>(null);
    const [commentToReplyTo, setCommentToReplyTo] = React.useState<CommentToReplayTo>(null);

    const watchParams = useSelector((state: StoreState) => getCreativeSidebarState(state).watchComentsByItemAndColumn);

    const usersForMentionOrganizationIds = useUsersForMentionOrganizationIds(creativeRequest);
    const creativeRequestItems = useCreativeRequestItems(creativeRequest);
    const activeCreativeRequestItem = creativeRequestItems?.find(
        (creativeRequestItem) => creativeRequestItem.dto.model.id === activeCreativeRequestItemId,
    );

    function onCommmentCreated(commentId: string) {
        if (!activeCreativeRequestItem) {
            creativeRequest.model.addComment({ commentId });
        } else {
            activeCreativeRequestItem?.dto?.model?.addComment({
                creativeRequestId: creativeRequest.model.id,
                commentId,
                column: activeCreativeRequestColumn || commentToReplyTo?.columnName,
            });
        }
    }

    function closeActiveCreativeRequestColumn() {
        setActiveCreativeRequestColumn(null);
    }

    React.useEffect(function onMount() {
        return function onUnmount() {
            dispatch(
                setWatchCommentsByItemAndColumn({
                    creativeRequestItemId: null,
                    column: null,
                }),
            );
        };
    }, []);

    React.useEffect(
        function resetCommentIdToReplyToAfterActiveCreativeRequesteItemChange() {
            setCommentToReplyTo(null);
        },
        [activeCreativeRequestItemId],
    );

    React.useEffect(
        function applyWatchCommentsByItemAndColumn() {
            const applySavedParams =
                creativeRequestItems.length && watchParams.creativeRequestItemId && watchParams.column;

            if (applySavedParams) {
                setActiveCreativeRequestColumn(watchParams.column);
                setActiveCreativeRequestItemId(watchParams.creativeRequestItemId);
            }
        },
        [creativeRequestItems, watchParams],
    );

    return {
        usersForMentionOrganizationIds,
        creativeRequestItems,
        activeCreativeRequestItem,
        activeCreativeRequestColumn,
        activeCreativeRequestItemId,
        setActiveCreativeRequestItemId,
        commentToReplyTo,
        setCommentToReplyTo,
        onCommmentCreated,
        closeActiveCreativeRequestColumn,
    };
}

export function Comments(props: Props): JSX.Element {
    const {
        usersForMentionOrganizationIds,
        creativeRequestItems,
        activeCreativeRequestItem,
        activeCreativeRequestColumn,
        activeCreativeRequestItemId,
        setActiveCreativeRequestItemId,
        commentToReplyTo,
        setCommentToReplyTo,
        onCommmentCreated,
        closeActiveCreativeRequestColumn,
    } = useComments(props);
    const { creativeRequest } = props;

    const renderColumnMarker = activeCreativeRequestItemId && activeCreativeRequestColumn;

    let content: JSX.Element = null;
    let shouldRenderCreateCommentForm: boolean;

    if (!activeCreativeRequestItemId) {
        content = (
            <CreativeRequestComments
                creativeRequest={creativeRequest}
                commentIdToReplyTo={commentToReplyTo?.commentId}
                setCommentToReplyTo={setCommentToReplyTo}
            />
        );
        shouldRenderCreateCommentForm = true;
    } else {
        content = (
            <CreativeRequestItemComments
                creativeRequestItem={activeCreativeRequestItem.dto}
                commentIdToReplyTo={commentToReplyTo?.commentId}
                activeCreativeRequestColumn={activeCreativeRequestColumn}
                setCommentToReplyTo={setCommentToReplyTo}
            />
        );
        shouldRenderCreateCommentForm = !!(commentToReplyTo?.commentId || activeCreativeRequestColumn);
    }

    return (
        <React.Fragment>
            <div className={styles.root}>
                <CreativeRequestItemCard
                    creativeRequestItem={null}
                    activeCreativeRequestItemId={activeCreativeRequestItemId}
                    setActiveCreativeRequestItemId={setActiveCreativeRequestItemId}
                />

                {orderBy(creativeRequestItems, (item) => parseInt((item.title.match(/d/g) || []).join('')))?.map(
                    (creativeRequestItem) => (
                        <CreativeRequestItemCard
                            key={creativeRequestItem.dto.model.id}
                            creativeRequestItem={creativeRequestItem}
                            activeCreativeRequestItemId={activeCreativeRequestItemId}
                            setActiveCreativeRequestItemId={setActiveCreativeRequestItemId}
                        />
                    ),
                )}
            </div>

            {content}

            {shouldRenderCreateCommentForm && (
                <div className={classnames(styles.createCommentForm, commonStyles.sidePaddings)}>
                    <CommonCreateCommentForm
                        usersOrganizationsForMention={usersForMentionOrganizationIds}
                        parrentCommentId={commentToReplyTo?.commentId}
                        onCommentCreated={onCommmentCreated}
                    />

                    {renderColumnMarker && (
                        <div className={styles.columnMarker}>
                            <ColumnMarker
                                creativeRequestItemId={activeCreativeRequestItemId}
                                column={activeCreativeRequestColumn}
                            />

                            <CloseButton onClick={closeActiveCreativeRequestColumn} size={16} />
                        </div>
                    )}
                </div>
            )}
        </React.Fragment>
    );
}

interface CreativeRequestItemCardProps {
    creativeRequestItem?: CreativeRequestItemWithTitle;
    activeCreativeRequestItemId: string;
    setActiveCreativeRequestItemId: (activeCreativeRequestItem: string) => void;
}

function CreativeRequestItemCard({
    creativeRequestItem,
    activeCreativeRequestItemId,
    setActiveCreativeRequestItemId,
}: CreativeRequestItemCardProps): JSX.Element {
    const isUnread = creativeRequestItem ? useCreativeRequesteItemUnreadComments(creativeRequestItem.dto) : null;

    const creativeRequestItemId = creativeRequestItem?.dto?.model?.id || null;
    const isActive = creativeRequestItemId === activeCreativeRequestItemId;

    const title: string = creativeRequestItem ? `Строка №${creativeRequestItem?.title}` : 'Общее';

    return (
        <div
            className={classnames(
                styles.creativeRequestItem,
                isUnread && styles.creativeRequestItemMessagesMarker,
                isActive && styles.creativeRequestItemActive,
            )}
            onClick={isActive ? null : () => setActiveCreativeRequestItemId(creativeRequestItemId)}
        >
            {title}
        </div>
    );
}

interface CreativeRequestCommentsProps {
    creativeRequest: CreativeRequest;
    commentIdToReplyTo: string;
    setCommentToReplyTo: (commentToReplyTo: CommentToReplayTo) => void;
}

function CreativeRequestComments({
    creativeRequest,
    commentIdToReplyTo = null,
    setCommentToReplyTo,
}: CreativeRequestCommentsProps): JSX.Element {
    const comments = useCreativeRequestComments(creativeRequest);
    const { commentsByReplyId, commentToReplyTo, commentsToDisplay } = useCommentsReplyChain(
        comments,
        commentIdToReplyTo,
    );

    function Comment({ comment }: { comment: CreativeRequestCommentDTO }): JSX.Element {
        return (
            <CreativeRequestComment
                addReplyButton={!commentIdToReplyTo}
                author={comment.model.author}
                text={comment.model.text}
                files={comment.model.files}
                createdAt={comment.model.createdAt}
                childrenCommentsCount={commentsByReplyId[comment.model.id]?.length}
                onReplyButtonClick={() =>
                    setCommentToReplyTo({
                        commentId: comment.model.id,
                        columnName: comment.model.column,
                    })
                }
            />
        );
    }

    return (
        <div className={styles.commentsGroup}>
            {commentToReplyTo ? (
                <CommentAtTheStartOfReplyChain
                    comment={<Comment comment={commentToReplyTo} />}
                    setCommentToReplyTo={setCommentToReplyTo}
                />
            ) : null}

            <GroupedComments comments={commentsToDisplay || []} commentRenderer={Comment} />
        </div>
    );
}

interface CreativeRequestItemCommentsProps {
    creativeRequestItem: CreativeRequestItem;
    commentIdToReplyTo: string;
    activeCreativeRequestColumn: string;
    setCommentToReplyTo: (commentToReplyTo: CommentToReplayTo) => void;
}

function CreativeRequestItemComments({
    creativeRequestItem,
    commentIdToReplyTo = null,
    activeCreativeRequestColumn,
    setCommentToReplyTo,
}: CreativeRequestItemCommentsProps): JSX.Element {
    const { comments, refetchComments } = useCreativeRequestItemComments(creativeRequestItem);
    const { commentsByReplyId, commentToReplyTo, commentsToDisplay } = useCommentsReplyChain(
        comments,
        commentIdToReplyTo,
    );

    function Comment({ comment }: { comment: CreativeRequestItemCommentDTO }): JSX.Element {
        return (
            <CreativeRequestItemComment
                comment={comment}
                addReplyButton={!commentIdToReplyTo}
                chilrenCommentsCount={commentsByReplyId[comment.model.id]?.length}
                activeCreativeRequestItemId={creativeRequestItem.model.id}
                activeCreativeRequestColumn={activeCreativeRequestColumn}
                refetchComments={refetchComments}
                onReplyButtonClick={() =>
                    setCommentToReplyTo({
                        commentId: comment.model.id,
                        columnName: comment.model.column,
                    })
                }
            />
        );
    }

    return (
        <div className={styles.commentsGroup}>
            {commentToReplyTo ? (
                <CommentAtTheStartOfReplyChain
                    comment={<Comment comment={commentToReplyTo} />}
                    setCommentToReplyTo={setCommentToReplyTo}
                />
            ) : null}

            <GroupedComments comments={commentsToDisplay || []} commentRenderer={Comment} />
        </div>
    );
}

interface CommentAtTheStartOfReplyChainProps {
    comment: JSX.Element;
    setCommentToReplyTo: (params: CommentToReplayTo) => void;
}

function CommentAtTheStartOfReplyChain({
    comment,
    setCommentToReplyTo,
}: CommentAtTheStartOfReplyChainProps): JSX.Element {
    return (
        <div>
            <div
                className={classnames(styles.commentAtTheStartOfReplyChainGoBackButton, commonStyles.sidePaddings)}
                onClick={() => setCommentToReplyTo(null)}
            >
                <svg width="10" height="4" viewBox="0 0 10 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M2.42 3.48C2.172 3.184 1.912 2.928 1.64 2.712C1.376 2.488 1.096 2.304 0.8 2.16V1.644C1.392 1.34 1.932 0.896 2.42 0.312H3.344C3.256 0.544 3.152 0.764 3.032 0.972C2.92 1.172 2.8 1.364 2.672 1.548V2.256C2.8 2.424 2.92 2.612 3.032 2.82C3.152 3.02 3.256 3.24 3.344 3.48H2.42ZM2.6 2.388L2.612 1.404H9.2V2.388H2.6Z"
                        fill="#7E8681"
                    />
                </svg>
                Обсуждение
            </div>

            <div className={styles.commentAtTheStartOfReplyChainCommentWrapper}>{comment}</div>
        </div>
    );
}
