import * as React from 'react';
import classNames from 'classnames';

import { Flex, FlexProps } from '@common/components';

import * as styles from './Tag.scss';

export type TagTypes = 'default' | 'accent' | 'danger' | 'secondary' | 'soft';

export type TagProps<E extends keyof JSX.IntrinsicElements = 'div'> = FlexProps<E> & {
    type?: TagTypes;
    ghost?: boolean;
    flat?: boolean;
    clickable?: boolean;
    disabled?: boolean;
    aligned?: boolean;
    even?: boolean;
    relative?: boolean;
    color?: string;
};

export function Tag<E extends keyof JSX.IntrinsicElements = 'div'>({
    type = 'default',
    className,
    children,
    ghost,
    loading,
    disabled = loading,
    aligned,
    even,
    flat,
    relative,
    color,
    style,
    onClick,
    onKeyUp,
    clickable = Boolean(onClick),
    ...props
}: TagProps<E>) {
    const newStyle = {
        backgroundColor: color,
        borderColor: color,
        color: color && '#fff',
        ...style,
    } as React.CSSProperties;

    const handleClick: typeof onClick = (e: any) => {
        if (disabled) {
            e.preventDefault();
        } else {
            onClick?.(e);
        }
    };

    const handleKeyPress: typeof onKeyUp = (e: any) => {
        if (['Enter', ' '].includes(e.key)) {
            e.stopPropagation();
            handleClick(e as any);
        }
        onKeyUp?.(e);
    };

    return (
        <Flex
            tabIndex={clickable ? 0 : undefined}
            inline
            gap={[4, 6]}
            align="center"
            padding={[5, 7]}
            {...(props as any)}
            loading={loading}
            style={newStyle}
            onClick={handleClick}
            onKeyUp={handleKeyPress}
            className={classNames(
                styles.root,
                styles[`type_${type}`],
                {
                    [styles.skeleton]: loading !== undefined,
                    [styles.ghost]: ghost,
                    [styles.flat]: flat,
                    [styles.clickable]: clickable,
                    [styles.disabled]: disabled,
                    [styles.relative]: relative,
                    [styles.aligned]: aligned,
                    [styles.even]: even,
                },
                className,
            )}
        >
            {children}
        </Flex>
    );
}
