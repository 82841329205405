import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LabeledSelect } from 'sber-marketing-ui';

import { StoreState } from '@store';
import {
    getTransferBudgetItemsToPlanningMenuState,
    getBudgetsAsSelectItems,
    setPlanningBudgetId,
} from '@store/budgetExecution/transferBudgetItemsToPlanningMenu';

import * as styles from './BudgetSelection.scss';

function useStore() {
    const dispatch = useDispatch();

    const items = useSelector((state: StoreState) => getBudgetsAsSelectItems(state));
    const selectedBudgetId = useSelector(
        (state: StoreState) => getTransferBudgetItemsToPlanningMenuState(state).props.planningBudgetId,
    );

    function onSelect(itemId: string) {
        dispatch(setPlanningBudgetId(itemId));
    }

    return {
        items,
        selectedBudgetId,
        onSelect,
    };
}

export function BudgetSelection(): JSX.Element {
    const { items, selectedBudgetId, onSelect } = useStore();

    return (
        <div className={styles.root}>
            <LabeledSelect
                title="Бюджет планирования"
                items={items}
                selectedValue={selectedBudgetId}
                onSelection={onSelect}
            />
        </div>
    );
}
