import { Dictionary } from 'lodash';
import { WorkTypeParams } from 'sber-marketing-types/frontend';

import { LoadingStatus } from '@store/commonTypes';

export interface WorkTypesState {
    entities: WorkTypeParams[];
    byIds: Dictionary<WorkTypeParams>;
    stores: Record<StoreTypes, EntitiesStore>;
}

export interface EntitiesStore {
    fetchersCount: number;
    loadingStatus: LoadingStatus;
    ids: string[];
}

export const enum StoreTypes {
    GENERAL = 'GENERAL',
    CURRENT_ORGANIZATION = 'CURRENT_ORGANIZATION',
    MY_TASKS_FILTER = 'MY_TASKS_FILTER',
    ACTIVITY_PAGE_FILTER = 'ACTIVITY_PAGE_FILTER',
}

export interface LoadWorkTypesParams {
    store: StoreTypes;
    ids?: string[];
}

export interface SetStoreIdsParams {
    store: StoreTypes;
    ids: string[];
}

export interface SetLoadingStatusParams {
    store: StoreTypes;
    status: LoadingStatus;
}

export type LoadEntitiesPayload = WorkTypeParams[];
