import * as lodash from 'lodash';
import * as moment from 'moment';

import type { AutopilotTVVideo } from 'sber-marketing-types/backend';
import type { LineId } from './types';

import { store } from '@store';
import { setBriefFormValues } from '@store/autopilotTv/actions';

export const enum ColumnHeaderType {
    Text = 'text',
}

export const enum LineType {
    Line = 'line',
    Total = 'total',
}

export const enum ColumnName {
    Name = 'name',
    Duration = 'duration',
}

export const enum CellType {
    Text = 'text',
    Input = 'input',
    Select = 'select',
}

export interface AccessorParams {
    id: string;
    line: AutopilotTVVideo;
    lines: AutopilotTVVideo[];
    onLineChange: (lineId: LineId) => void;
}

export type Accessor = (params: AccessorParams) => any;
export type TitleAccessor = (params: AccessorParams) => string;
export type ValueSetter = (params: AccessorParams, value: any) => Promise<void>;
export type ItemsAccessor = (params: AccessorParams) => any[];
export type DescriptionAccessor = (params: AccessorParams) => string;
export type CustomStyleAccessor = (params: AccessorParams) => React.CSSProperties;

export interface ColumnParams {
    title: string;
    headerType: ColumnHeaderType;
    type: Record<LineType, CellType>;
    defaultWidth: number;
    disableWidthChange?: boolean;
    readOnly?: Partial<Record<LineType, boolean>>;
    customStyle?: Partial<Record<LineType, CustomStyleAccessor>>;
    linkedColumns?: Partial<Record<LineType, ColumnName[]>>;
    getTitle?: Partial<Record<LineType, TitleAccessor>>;
    getValue: Record<LineType, Accessor>;
    setValue?: Partial<Record<LineType, ValueSetter>>;
    getItems?: Partial<Record<LineType, ItemsAccessor>>;
    getDescription?: Partial<Record<LineType, DescriptionAccessor>>;
}

export interface ColumnsConfigParams {
    weeks: { dateStart: string; dateEnd: string }[];
}

export const MakeColumnsConfig: (columnsConfigParams: ColumnsConfigParams) => { [columnName: string]: ColumnParams } = (
    columnsConfigParams: ColumnsConfigParams,
) => ({
    [ColumnName.Name]: {
        title: 'Ролик',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: CellType.Text,
        },
        customStyle: {
            [LineType.Total]: (params: AccessorParams) => ({
                fontWeight: 600,
                backgroundColor: '#f8f8f8',
            }),
        },
        defaultWidth: 200,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.line.name,
            [LineType.Total]: (params: AccessorParams) => 'Итого',
        },
    },
    [ColumnName.Duration]: {
        title: 'Хронометраж',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: CellType.Text,
        },
        customStyle: {
            [LineType.Total]: (params: AccessorParams) => ({
                fontWeight: 600,
                backgroundColor: '#f8f8f8',
            }),
        },
        defaultWidth: 180,
        disableWidthChange: true,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => `${params.line.duration} секунд` || null,
            [LineType.Total]: (params: AccessorParams) => '—',
        },
    },
    ...columnsConfigParams.weeks.reduce((acc, item, weekIndex) => {
        acc[`month${weekIndex}`] = {
            title: makeMonthTitle(item.dateStart, item.dateEnd),
            headerType: ColumnHeaderType.Text,
            type: {
                [LineType.Line]: CellType.Input,
                [LineType.Total]: CellType.Input,
            },
            customStyle: {
                [LineType.Total]: (params: AccessorParams) => ({
                    fontWeight: 600,
                    backgroundColor: '#f8f8f8',
                }),
            },
            defaultWidth: 180,
            disableWidthChange: true,
            readOnly: {
                [LineType.Line]: false,
                [LineType.Total]: true,
            },
            getValue: {
                [LineType.Line]: (params: AccessorParams) => params.line.percents[weekIndex]?.value,
                [LineType.Total]: (params: AccessorParams) =>
                    lodash.sumBy(params.lines, (item) => item.percents[weekIndex]?.value || 0),
            },
            setValue: {
                [LineType.Line]: async (params: AccessorParams, value: any) => {
                    const updatedVideos = lodash.clone(params.lines);

                    const lineIndex = params.lines.indexOf(params.line);
                    const parsedValue = value !== '' ? parseInt(value, 10) : 0;

                    updatedVideos[lineIndex].percents[weekIndex].value = parsedValue;

                    store.dispatch(
                        setBriefFormValues({
                            videos: updatedVideos,
                        }),
                    );

                    params.onLineChange(params.id);
                },
            },
        };

        return acc;
    }, {}),
});

export const MakeTableColumns: (columnsConfigParams: ColumnsConfigParams) => string[] = (columnsConfigParams) => [
    ColumnName.Name,
    ColumnName.Duration,
    ...columnsConfigParams.weeks.map((item, index) => `month${index}`),
];

export const leftFixedColumns: ColumnName[] = [];

export const rightFixedColumns: ColumnName[] = [];

function makeMonthTitle(dateStart: string, dateEnd: string): string {
    let title: string;

    const startDate = moment(dateStart);
    const endDate = moment(dateEnd);

    if (startDate.clone().startOf('month').isSame(endDate.clone().startOf('month'))) {
        title = `${startDate.format('MMMM')}\n${startDate.format('D')} — ${endDate.format('D')}`;
    } else {
        title = `${startDate.format('MMMM D')} —\n${endDate.format('MMMM D')}`;
    }

    return title;
}
