/* tslint:disable:max-file-line-count */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import * as queryString from 'query-string';
import { BudgetItem, BudgetStatus } from '@mrm/budget';

import { User } from '@store/user';
import { NotificationMessage, NotificationType, NotificationActionType } from '@store/common/types';
import {
    ChangeList,
    ColumnName,
    CorrectionPopup,
    ActivityReferenceMenuVisibility,
    CreativeReferenceMenuVisibility,
} from '@store/budgetExecution/types';

import { BudgetExecution } from './BudgetExecution';
import { StoreState } from '@store';
import { getBudgetByStatusUserConfig } from '@store/userConfig/budget';
import { setNotification, clearAllNotifications, setRequestInProgress } from '@store/common/actions';
import { isRequestInProgress } from '@store/common/selectors';
import {
    PageData,
    showLineModal,
    clearUnsavedChanges,
    clearUnsavedChangesByLines,
    initUnsavedChanges,
    redoUnsavedChanges,
    resetChangesHistory,
    resetPageStore,
    setActivityReferenceMenuVisibility,
    setCreativeReferenceMenuVisibility,
    resetFilters,
    resetViewSettings,
    undoUnsavedChanges,
    setCorrectionPopup,
    setPreloaderStatus,
    canRedoUnsavedChanges,
    canUndoUnsavedChanges,
    getActivityReferenceMenuVisibility,
    getCreativeReferenceMenuVisibility,
    getCorrectionPopup,
    loadBudgetItems,
    resetBudgetRelatedData,
    ShowLineModalParams,
    initPreviouslyLoadedFilter,
    resetPreviouslyLoadedFilters,
    loadPageData,
    setShowTagsHaveChangedMarker,
    downloadTableAsXLSX,
    getBudgetExecutionPageState,
    DownloadTableAsXLSXPaylaod,
    resetLoadedFilters,
} from '@store/budgetExecution';
import {
    getBudgetTransferMenuState,
    openTransitionMenu,
    isBudgetTransferMenuClosed,
    closeTransitionMenu,
} from '@store/budgetExecution/budgetTransferMenu';
import {
    ComponentState,
    getTransferBudgetItemsToPlanningMenuState,
    resetComponentState,
} from '@store/budgetExecution/transferBudgetItemsToPlanningMenu';
import { resetState as resetMiscBudgetItemsState } from '@store/budgetExecution/miscBudgetItems';

import { getLoginUser } from '@store/user/selector';
import { Loader, Saver, Validator } from './modules';
import { BudgetApi } from '@api';
import { withQueryParams, WithQueryParamsProps } from './withQueryParams';

import { UserConfigEffect } from './Effects';
import { Table } from './Table';

const TABLE_MARGIN_BOTTOM = 24;

interface Props extends Partial<MapProps & DispatchProps & RouteComponentProps<RouteParams> & WithQueryParamsProps> {}
interface MapProps {
    budgetId: string;
    isRequestInProgress: boolean;
    activityReferenceMenuVisibility: ActivityReferenceMenuVisibility;
    creativeReferenceMenuVisibility: CreativeReferenceMenuVisibility;
    user: User;
    isBudgetTransferMenuClosed: boolean;
    canUndoUnsavedChanges: boolean;
    canRedoUnsavedChanges: boolean;
    correctionPopup: CorrectionPopup;
    lineIdsWithActualChanges: string[];
    isTransferBudgetItemsToPlanningOpened: boolean;
}

interface DispatchProps {
    showLineModal: (params: ShowLineModalParams) => void;
    loadBudgetItems: (budgetItems: BudgetItem[]) => void;
    setRequestInProgress: (requestStatus: boolean) => void;
    initUnsavedChanges: (unsavedChanges: ChangeList) => void;
    clearUnsavedChanges: () => void;
    clearUnsavedChangesByLines: (lineIds: string[]) => void;
    resetBudgetExecutionPage: () => void;
    openTransitionMenu: () => void;
    resetFilters: () => void;
    resetViewSettings: () => void;
    undoUnsavedChanges: () => void;
    redoUnsavedChanges: () => void;
    resetChangesHistory: () => void;
    setActivityReferenceMenuVisibility: (params: ActivityReferenceMenuVisibility) => void;
    setCreativeReferenceMenuVisibility: (params: CreativeReferenceMenuVisibility) => void;
    setNotification: (notification: NotificationMessage) => void;
    clearAllNotifications: () => void;
    setCorrectionPopup: (correctionPopup: CorrectionPopup) => void;
    setPreloaderStatus: (status: boolean) => void;
    closeTransitionMenu: () => void;
    resetBudgetRelatedData: () => void;
    initPreviouslyLoadedFilter: () => void;
    resetPreviouslyLoadedFilters: () => void;
    loadPageData: (pageData: Partial<PageData>) => void;
    resetTransferBudgetItemsToPlanningMenuState: () => void;
    resetMiscBudgetItemsState: () => void;
    setShowTagsHaveChangedMarker: (payload: boolean) => void;
    downloadTableAsXLSX: (payload: DownloadTableAsXLSXPaylaod) => void;
    resetLoadedFilters: () => void;
}

interface State {
    refetchInProgress: boolean;
    maxTableHeight: number;
    initialScrollColumn: ColumnName;
}

interface RouteParams {
    pageName: string;
}

/* tslint:disable:max-line-length */
@(withRouter as any)
@(withQueryParams as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BudgetExecutionContainer extends React.PureComponent<Props, State> {
    private root: HTMLDivElement;
    private tableWrapper: HTMLDivElement;
    private tableContent: Table;
    private isComponentMounted = false;
    private tableLoader: Loader;
    private tableValidator: Validator;
    private tableSaver: Saver;
    private userConfigEffectRef: React.RefObject<UserConfigEffect> = React.createRef();

    constructor(props: Props) {
        super(props);

        this.props.setRequestInProgress(true);

        this.state = {
            maxTableHeight: 0,
            initialScrollColumn: this.getScrollLeftColumn(),
            refetchInProgress: false,
        };

        this.tableLoader = Loader.getInstance();
        this.tableValidator = Validator.getInstance();
        this.tableSaver = Saver.getInstance();
    }

    public async componentDidMount() {
        this.isComponentMounted = true;

        await Promise.all([this.tableLoader.initPageData(), this.tableLoader.loadBudgetRelatedData()]);

        if (this.isComponentMounted) {
            this.tableLoader.initFilters();

            this.props.clearUnsavedChanges();

            let query = this.getQueryParams();

            if (!lodash.isEmpty(query) && !this.haveActivityAndBudgetItemToLink) {
                // assuming budgetId for budget with year: 2020, if res.budgetId is missing
                if (!query.budgetId) {
                    query = {
                        ...query,
                        budgetId: (
                            await BudgetApi.getBudgetList({
                                status: BudgetStatus.Execution,
                            })
                        ).find((budget) => budget.year === 2020).id,
                    };
                }

                await this.tableLoader.initFromQuery(query);

                this.removeQueryFromUrl();
            } else {
                await this.tableLoader.initFromUserConfig();
            }

            if (this.haveActivityAndBudgetItemToLink) {
                this.showLineModal();
                this.removeQueryFromUrl();
            }

            this.props.setRequestInProgress(false);

            this.updateMaxTableHeight();

            window.addEventListener('resize', this.onPageResize);
        }
    }

    public async componentWillUnmount() {
        this.isComponentMounted = false;

        this.props.setRequestInProgress(false);

        window.removeEventListener('resize', this.onPageResize);

        this.props.closeTransitionMenu();
        this.props.resetTransferBudgetItemsToPlanningMenuState();
        this.props.resetBudgetExecutionPage();
        this.props.resetMiscBudgetItemsState();
    }

    public async componentDidUpdate(prevProps: Props): Promise<void> {
        const isChangedBudgetId = prevProps.budgetId !== this.props.budgetId;

        if (isChangedBudgetId) {
            this.props.resetBudgetRelatedData();
            this.enablePreloader();

            await new Promise<void>((resolve) => this.setState({ refetchInProgress: true }, resolve));

            this.tableContent?.resetScroll();
            this.tableLoader.initFilters();

            await Promise.all([
                this.tableLoader.initPageData(),
                this.tableLoader.loadBudgetRelatedData(),
                this.tableLoader.initFromUserConfig(),
            ]);

            await new Promise<void>((resolve) => this.setState({ refetchInProgress: false }, resolve));

            this.disablePreloader();
        }

        // if (defaultFiltersChanged) {
        //     this.props.setFilters(this.mergeFilters(this.props.defaultFilters));
        // }
    }

    public render(): JSX.Element {
        const { budgetId, canUndoUnsavedChanges, canRedoUnsavedChanges, isTransferBudgetItemsToPlanningOpened } =
            this.props;

        const { maxTableHeight, initialScrollColumn } = this.state;

        return React.createElement(BudgetExecution, {
            budgetId,
            isRequestInProgress: this.props.isRequestInProgress,
            pageContentHeight: this.root ? this.root.clientHeight : null,
            tableOffsetTop: this.tableWrapper ? this.tableWrapper.offsetTop : null,
            rootRef: this.rootRef,
            tableRef: this.tableRef,
            tableContentRef: this.tableContentRef,
            maxTableHeight,
            activityReferenceMenuVisibility: this.props.activityReferenceMenuVisibility,
            creativeReferenceMenuVisibility: this.props.creativeReferenceMenuVisibility,
            scrollLeftColumn: initialScrollColumn,
            sendButtonIsDisabled: this.hasUnsavedChanges(),
            transferFormIsVisible: this.props.isBudgetTransferMenuClosed,
            onResetPageFiltersButtonClick: this.onResetPageFiltersButtonClick,
            onResetPageViewSettingsButtonClick: this.onResetPageViewSettingsButtonClick,
            onTransferSendButtonClick: this.onTransferSendButtonClick,
            onActivityReferenceButtonClick: this.onActivityReferenceButtonClick,
            onSendButtonClick: this.onSendButtonClick,
            onDownloadXLSXButtonClick: this.onDownloadXLSXButtonClick,
            onUndoButtonClick: this.onUndoButtonClick,
            disableUndoButton: !canUndoUnsavedChanges,
            onRedoButtonClick: this.onRedoButtonClick,
            disableRedoButton: !canRedoUnsavedChanges,
            onResetChangesHistoryClick: this.onResetChangesHistoryClick,
            disableResetChangesHistoryButton: this.hasUnsavedChanges(),
            showBudgetExpertsPopup: this.props.correctionPopup.show,
            onCloseBudgetExpertsPopupButtonClick: this.onCloseBudgetExpertsPopupButtonClick,
            onSubmitBudgetExpertsPopupButtonClick: this.onSubmitBudgetExpertsPopupButtonClick,
            onActivityReferenceMenuClose: this.onActivityReferenceMenuClose,
            onActivityReferenceMenuFinish: this.onActivityReferenceMenuFinish,
            onCreativeReferenceMenuClose: this.onCreativeReferenceMenuClose,
            onApplyFiltersButtonClick: this.onApplyFiltersButtonClick,
            isTransferBudgetItemsToPlanningOpened,
            pageDataRefetchInProgress: this.state.refetchInProgress,
            userConfigEffectRef: this.userConfigEffectRef,
        });
    }

    @autobind
    protected async onApplyFiltersButtonClick(): Promise<void> {
        this.userConfigEffectRef?.current?.updateUserConfig();

        this.props.initPreviouslyLoadedFilter();
        this.enablePreloader();

        await this.tableLoader.loadDataByFilters();

        this.disablePreloader();

        this.props.setShowTagsHaveChangedMarker(false);
        this.props.resetLoadedFilters();
    }

    @autobind
    protected rootRef(element: HTMLDivElement) {
        this.root = element;
    }

    @autobind
    protected tableRef(element: HTMLDivElement) {
        this.tableWrapper = element;
    }

    @autobind
    protected tableContentRef(element: Table) {
        this.tableContent = element;
    }

    @autobind
    protected onPageResize() {
        this.updateMaxTableHeight();
    }

    @autobind
    protected onResetPageFiltersButtonClick() {
        this.props.loadBudgetItems([]);
        this.props.resetFilters();
        this.props.resetPreviouslyLoadedFilters();
    }

    @autobind
    protected onResetPageViewSettingsButtonClick() {
        this.props.resetViewSettings();
    }

    @autobind
    protected onTransferSendButtonClick() {
        this.props.openTransitionMenu();
    }

    @autobind
    protected async onActivityReferenceButtonClick() {
        this.props.setActivityReferenceMenuVisibility({
            visibility: true,
        });
    }

    @autobind
    protected async onSendButtonClick() {
        this.enablePreloader();

        const isValid = this.tableValidator.checkTableValidation();

        if (isValid) {
            const tableDataIsActual = await this.tableValidator.checkTableDataRelevance();

            if (tableDataIsActual) {
                const tableDictionariesAreActual = await this.tableValidator.checkTableDictionariesRelevance();

                if (tableDictionariesAreActual) {
                    this.clearAllNotify();

                    if (this.isUserBudgetExpert()) {
                        await this.saveChanges(this.props.user.attributes.id);
                    } else {
                        this.showCorrectionPopup();
                    }
                } else {
                    this.notifyLineDictionariesRelevance();
                }
            } else {
                this.notifyLineDataRelevanceError();
            }
        } else {
            this.notifyValidationError();
        }

        this.disablePreloader();

        this.tableValidator.updateValidationDisplay();
    }

    @autobind
    private async onDownloadXLSXButtonClick(payload: DownloadTableAsXLSXPaylaod): Promise<void> {
        this.props.downloadTableAsXLSX(payload);
    }

    @autobind
    private async onSubmitBudgetExpertsPopupButtonClick(approverId: number): Promise<void> {
        this.hideCorrectionPopup();

        if (this.isCorrectionPopupForSavingLine()) {
            const { lineId } = this.props.correctionPopup;

            await this.saveChangesByLineId(lineId, approverId);
        } else {
            await this.saveChanges(approverId);
        }
    }

    private get haveActivityAndBudgetItemToLink(): boolean {
        const { budgetItemIdToLink, activityIdToLink } = this.props;
        return Boolean(budgetItemIdToLink) && Boolean(activityIdToLink);
    }

    private isCorrectionPopupForSavingLine(): boolean {
        return Boolean(this.props.correctionPopup.lineId);
    }

    private async saveChangesByLineId(lineId: string, approverId: number): Promise<void> {
        this.enablePreloader();

        await this.tableSaver.saveLine(lineId, approverId);

        if (this.isComponentMounted) {
            await this.tableLoader.updatePageDataByLineIds([lineId]);
        }

        this.props.clearUnsavedChangesByLines([lineId]);

        if (this.isComponentMounted) {
            this.notifyAboutChangeActivity();
            this.disablePreloader();
        }
    }

    private async saveChanges(approverId: number): Promise<void> {
        this.enablePreloader();

        const { lineIdsWithActualChanges } = this.props;

        await this.tableSaver.saveTable(approverId);

        if (this.isComponentMounted) {
            await this.tableLoader.updatePageDataByLineIds(lineIdsWithActualChanges);
        }

        this.props.resetChangesHistory();
        this.props.clearUnsavedChangesByLines(lineIdsWithActualChanges);

        if (this.isComponentMounted) {
            this.notifyAboutChangeActivity();
            this.disablePreloader();
        }
    }

    @autobind
    private onCloseBudgetExpertsPopupButtonClick(): void {
        this.hideCorrectionPopup();
    }

    @autobind
    private onUndoButtonClick(): void {
        if (this.props.canUndoUnsavedChanges) {
            this.props.undoUnsavedChanges();
        }
    }

    @autobind
    private onRedoButtonClick(): void {
        if (this.props.canRedoUnsavedChanges) {
            this.props.redoUnsavedChanges();
        }
    }

    @autobind
    private onResetChangesHistoryClick(): void {
        this.props.resetChangesHistory();
        this.props.clearUnsavedChanges();
    }

    @autobind
    private onActivityReferenceMenuClose(): void {
        const {
            setActivityReferenceMenuVisibility,
            activityReferenceMenuVisibility: { selectedActivityId },
        } = this.props;

        setActivityReferenceMenuVisibility({
            visibility: false,
        });

        selectedActivityId && window.close();
    }

    @autobind
    private onActivityReferenceMenuFinish(): void {
        const {
            activityReferenceMenuVisibility: { selectedActivityId },
        } = this.props;

        selectedActivityId && window.close();
    }

    @autobind
    private onCreativeReferenceMenuClose(): void {
        this.props.setCreativeReferenceMenuVisibility({
            visibility: false,
        });
    }

    private updateMaxTableHeight() {
        if (this.isComponentMounted) {
            const pageContentHeight = this.root.clientHeight;
            const tableOffsetTop = this.tableWrapper.offsetTop;

            const newMaxTableHeight = pageContentHeight - tableOffsetTop - TABLE_MARGIN_BOTTOM;

            if (newMaxTableHeight !== this.state.maxTableHeight) {
                this.setState({
                    maxTableHeight: newMaxTableHeight,
                });
            }
        }
    }

    private getQueryParams(): queryString.ParsedQuery {
        return queryString.parse(this.props.location.search);
    }

    private removeQueryFromUrl() {
        this.props.history.push('/budget/execution');
    }

    private showLineModal(): void {
        const { activityIdToLink, budgetItemIdToLink, showLineModal } = this.props;

        showLineModal({
            lineId: budgetItemIdToLink,
            activityIdToLink,
        });
    }

    private getScrollLeftColumn(): ColumnName {
        const query = queryString.parse(this.props.location.search);

        return query.column as ColumnName;
    }

    private isUserBudgetExpert(): boolean {
        return this.props.user.attributes.roles.map(({ id }) => id).includes(9);
    }

    private notifyAboutChangeActivity(): void {
        this.setNotificationMessage(
            NotificationType.SUCCESS,
            NotificationActionType.CHANGES_SENT_TO_APPROVAL_ON_BUDGET_EXECUTION_PAGE,
            'Изменения отправлены на согласование',
        );
    }

    private notifyValidationError(): void {
        this.setNotificationMessage(
            NotificationType.ERROR,
            NotificationActionType.TABLE_VALIDATION_ON_BUDGET_EXECUTION_PAGE,
            'Нельзя отправить на согласование активность или строку с незаполненным обязательным полем, ' +
                'и название активности не должно быть меньше трех символов. Введите значение и отправьте на согласование еще раз.',
        );
    }

    private notifyLineDataRelevanceError() {
        this.setNotificationMessage(
            NotificationType.ERROR,
            NotificationActionType.DATA_IS_NOT_ACTIAL_ON_BUDGET_EXECUTION_PAGE,
            'В редактируемую строку были внесены изменения. Обновите страницу.',
        );
    }

    private notifyLineDictionariesRelevance() {
        this.setNotificationMessage(
            NotificationType.ERROR,
            NotificationActionType.DATA_IS_NOT_ACTIAL_ON_BUDGET_EXECUTION_PAGE,
            'В редактируемой строке существует неутверждённая корректировка. Утвердите или отклоните её',
        );
    }

    private clearAllNotify(): void {
        this.props.clearAllNotifications();
    }

    private setNotificationMessage(type: NotificationType, typeAction: NotificationActionType, comment: string): void {
        this.props.setNotification({ type, typeAction, comment });
    }

    private hasUnsavedChanges(): boolean {
        const { lineIdsWithActualChanges } = this.props;

        return !lineIdsWithActualChanges?.length;
    }

    private enablePreloader(): void {
        this.props.setPreloaderStatus(true);
    }

    private disablePreloader(): void {
        this.props.setPreloaderStatus(false);
    }

    private showCorrectionPopup() {
        this.props.setCorrectionPopup({ show: true });
    }

    private hideCorrectionPopup() {
        this.props.setCorrectionPopup({ show: false });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { lineIdsWithActualChanges } = getBudgetExecutionPageState(state).computedData;

    const {
        props: { componentState },
    } = getTransferBudgetItemsToPlanningMenuState(state);
    const isTransferBudgetItemsToPlanningOpened = componentState === ComponentState.Opened;

    const { budgetId } = getBudgetByStatusUserConfig(state, BudgetStatus.Execution);

    return {
        budgetId,
        isRequestInProgress: isRequestInProgress(state),
        activityReferenceMenuVisibility: getActivityReferenceMenuVisibility(state),
        creativeReferenceMenuVisibility: getCreativeReferenceMenuVisibility(state),
        user: getLoginUser(state),
        isBudgetTransferMenuClosed: !isBudgetTransferMenuClosed(
            getBudgetTransferMenuState(state).controls.componentState,
        ),
        canUndoUnsavedChanges: canUndoUnsavedChanges(state),
        canRedoUnsavedChanges: canRedoUnsavedChanges(state),
        correctionPopup: getCorrectionPopup(state),
        lineIdsWithActualChanges,
        isTransferBudgetItemsToPlanningOpened,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            showLineModal,
            loadBudgetItems,
            setRequestInProgress,
            initUnsavedChanges,
            clearUnsavedChanges,
            clearUnsavedChangesByLines,
            resetBudgetExecutionPage: resetPageStore,
            openTransitionMenu,
            resetFilters,
            resetViewSettings,
            undoUnsavedChanges,
            redoUnsavedChanges,
            resetChangesHistory,
            setActivityReferenceMenuVisibility,
            setCreativeReferenceMenuVisibility,
            setNotification,
            clearAllNotifications,
            setCorrectionPopup,
            setPreloaderStatus,
            closeTransitionMenu,
            resetBudgetRelatedData,
            initPreviouslyLoadedFilter,
            resetPreviouslyLoadedFilters,
            loadPageData,
            resetTransferBudgetItemsToPlanningMenuState: () => resetComponentState(),
            resetMiscBudgetItemsState,
            setShowTagsHaveChangedMarker,
            downloadTableAsXLSX,
            resetLoadedFilters,
        },
        dispatch,
    );
}
