import * as React from 'react';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';

import { StoreState } from '@store';
import { getBudgetItemByLineId } from '@store/budgetExecution';
import { BudgetItem } from '@mrm/budget';

import { withUnlinkActivity, WithUnlinkActivityProps } from './withUnlinkActivity';
import { UnbindActivityPopup } from './UnbindActivityPopup';

interface Props extends Partial<MapProps & WithUnlinkActivityProps> {
    budgetItemId: string;
    activityId: number;
    activityName: string;
    onClose: () => void;
    onFinish: () => void;
}

interface MapProps {
    budgetItem: BudgetItem;
}

interface State {
    loading: boolean;
}

@(connect(mapStateToProps) as any)
@(withUnlinkActivity as any)
export class UnbindActivityPopupContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    public render() {
        const { activityId, activityName } = this.props;
        const { loading } = this.state;

        return React.createElement(UnbindActivityPopup, {
            title: `Отвязать проект от ID ${this.props.budgetItem.serialNumber}`,
            activityId,
            activityName,
            loading,
            onCloseButtonClick: this.onCloseButtonClick,
            onUnbindButtonClick: this.onUnbindButtonClick,
            onCancelButtonClick: this.onCancelButtonClick,
        });
    }

    @autobind
    private async onUnbindButtonClick(): Promise<void> {
        await this.unbindActivity();
    }

    @autobind
    private onCancelButtonClick(): void {
        this.props.onClose();
    }

    @autobind
    private onCloseButtonClick(): void {
        this.props.onClose();
    }

    private async unbindActivity(): Promise<void> {
        const { budgetItemId, activityId } = this.props;

        try {
            this.setState({ loading: true });
            await this.props.unlinkActivity({
                budgetItemId,
                activityId,
            });
            this.setState({ loading: false });
            this.props.onFinish();
            this.props.onClose();
        } catch {
            this.setState({ loading: false });
            this.props.onFinish();
            this.props.onClose();
        }
    }
}

function mapStateToProps(store: StoreState, props: Props): MapProps {
    const { budgetItemId } = props;

    return {
        budgetItem: getBudgetItemByLineId(store, budgetItemId),
    };
}
