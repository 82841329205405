import * as React from 'react';
import { Skeleton } from 'sber-marketing-ui';

import * as style from './ActivityCardPreloader.scss';

interface Props {}

export function ActivityCardPreloader({}: Props): JSX.Element {
    return (
        <div className={style.root} style={{}}>
            <Skeleton customStyle={{ height: 20, width: '60%', borderRadius: 2, marginBottom: 5 }} />
            <Skeleton customStyle={{ height: 16, width: '25%', borderRadius: 2, marginBottom: 5 }} />
            <Skeleton customStyle={{ height: 16, width: '50%', borderRadius: 2, marginBottom: 5 }} />
            <Skeleton customStyle={{ height: 16, width: '40%', borderRadius: 2, marginBottom: 5 }} />
            <Skeleton customStyle={{ height: 16, width: '87%', borderRadius: 2 }} />

            <hr className={style.separator} />

            <Skeleton customStyle={{ height: 16, width: '75%', borderRadius: 2, marginBottom: 5 }} />
            <Skeleton customStyle={{ height: 16, width: '55%', borderRadius: 2 }} />
        </div>
    );
}
