import * as React from 'react';
import classNames from 'classnames';

import { Tag, TagProps } from '@common/components';

import * as styles from './SimpleFileThumbnail.scss';

export interface FileTypeColor {
    text: string;
    background: string;
}

export const typeColors: Record<string, FileTypeColor> = {
    xls: {
        text: '#1F9A48',
        background: '#def9e6',
    },
    xlsx: {
        text: '#1F9A48',
        background: '#def9e6',
    },
    doc: {
        text: '#417ec6',
        background: '#d7eaff',
    },
    docx: {
        text: '#417ec6',
        background: '#d7eaff',
    },
    pdf: {
        text: '#e63900',
        background: '#ffebee',
    },
};

export interface SimpleFileThumbnailProps extends TagProps {
    fileType: string;
    size?: number;
    typeClassName?: string;
    hideType?: boolean;
}

export function SimpleFileThumbnail({
    fileType,
    size = 48,
    style,
    className,
    typeClassName,
    children,
    hideType,
    ...props
}: SimpleFileThumbnailProps) {
    const bgColor = typeColors[fileType]?.background || '#f7f7f7';
    const textColor = typeColors[fileType]?.text || '#7e8681';

    return (
        <Tag
            color={bgColor}
            relative
            {...props}
            padding={undefined}
            style={
                {
                    ...style,
                    '--file-thumbnail-size': `${size}px`,
                    color: textColor,
                } as any
            }
            className={classNames(styles.root, className)}
        >
            {!hideType && (
                <div data-qa-id="SimpleFileThumbnail__type" className={classNames(styles.type, typeClassName)}>
                    {fileType}
                </div>
            )}
            {children}
        </Tag>
    );
}
