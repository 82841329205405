import * as React from 'react';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';

import { setCardIdRequestInProgress } from '@store/dashboardPage';

import { RequestStatus } from './types';
import { BondActivitiesWithBudgetItemId } from './BondActivitiesWithBudgetItemId';

interface Props extends Partial<DispatchProps> {
    requestId: string;
    requestStatus: RequestStatus;
    enableControls: boolean;
    userIsBudgetExpert: boolean;
    approveRequest: () => Promise<void>;
    rejectRequest: () => Promise<void>;
    revertRequest: () => Promise<void>;
    updateCards: () => Promise<void>;
}

interface DispatchProps {
    setrequestIdIdRequestInProgress: (requestId: string) => void;
}

@(connect(null, mapDispatchToProps) as any)
export class BondActivitiesWithBudgetItemIdContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { requestStatus, enableControls, userIsBudgetExpert } = this.props;

        return React.createElement(BondActivitiesWithBudgetItemId, {
            requestStatus,
            enableControls: userIsBudgetExpert && enableControls,
            onApproveClick: this.onApproveClick,
            onRejectClick: this.onRejectClick,
            onRevertClick: this.onRevertClick,
        });
    }

    @autobind
    private async onApproveClick() {
        await this.performMutation(this.props.approveRequest);
    }

    @autobind
    private async onRejectClick() {
        await this.performMutation(this.props.rejectRequest);
    }

    @autobind
    private async onRevertClick() {
        await this.performMutation(this.props.revertRequest);
    }

    private async performMutation(mutation: () => Promise<void>): Promise<void> {
        this.props.setrequestIdIdRequestInProgress(this.props.requestId);

        await mutation();
        await this.props.updateCards();

        this.props.setrequestIdIdRequestInProgress(null);
    }
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        setrequestIdIdRequestInProgress: (requestId: string) => dispatch(setCardIdRequestInProgress(requestId)),
    };
}
