import * as React from 'react';
import { take } from 'lodash';
import * as styles from './styles.scss';

import { useStore } from './hooks';
import { ColumnsFiltersDropdown } from './ColumnsFiltersDropdown';
import { TagsList } from './TagsList';

const COUNT_DISPLAY_TAGS = 3;

interface Props {
    onApplyFiltersButtonClick: () => void;
}

export const FiltersColumnsTagsBar = ({ onApplyFiltersButtonClick }: Props) => {
    const { filtersNames, filtersValuesCounts, onTagClose } = useStore();

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'budgetExecutionFiltersColumns',
            }}
        >
            <div className={styles.label}>Фильтры по столбцам: </div>

            <TagsList
                filterNames={take(filtersNames, COUNT_DISPLAY_TAGS)}
                filtersValuesCounts={filtersValuesCounts}
                onTagClose={onTagClose}
                onApplyFiltersButtonClick={onApplyFiltersButtonClick}
            />

            <ColumnsFiltersDropdown displayLabel={filtersNames.length > COUNT_DISPLAY_TAGS} />
        </div>
    );
};
