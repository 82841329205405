// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-TaskSidebar-_TaskSidebar__commentsTab{display:flex;align-items:center}.src-client-modules-common-TaskSidebar-_TaskSidebar__commentsTabExpandIcon{margin-left:8px}.src-client-modules-common-TaskSidebar-_TaskSidebar__commentsTabExpandIconActive{transform:rotate(180deg)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/TaskSidebar/TaskSidebar.scss"],"names":[],"mappings":"AAAA,iEACI,YAAa,CACb,kBAAmB,CACtB,2EAGG,eAAgB,CACnB,iFAGG,wBAAyB","sourcesContent":[".commentsTab {\n    display: flex;\n    align-items: center;\n}\n\n.commentsTabExpandIcon {\n    margin-left: 8px;\n}\n\n.commentsTabExpandIconActive {\n    transform: rotate(180deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commentsTab": "src-client-modules-common-TaskSidebar-_TaskSidebar__commentsTab",
	"commentsTabExpandIcon": "src-client-modules-common-TaskSidebar-_TaskSidebar__commentsTabExpandIcon",
	"commentsTabExpandIconActive": "src-client-modules-common-TaskSidebar-_TaskSidebar__commentsTabExpandIconActive"
};
export default ___CSS_LOADER_EXPORT___;
