import * as React from 'react';
import classNames from 'classnames';
import * as style from './CardBody.scss';

interface Props {
    indicator: Indicator;
    isOpenedInSidebar?: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onCardClick?: () => void;
}

interface Indicator {
    position: IndicatorPosition;
    color: IndicatorColor;
}

export const enum IndicatorPosition {
    TOP = 'top',
    LEFT = 'left',
}

export const enum IndicatorColor {
    BLUE = 'blue',
    RED = 'red',
    GRAY = 'gray',
    GREEN = 'green',
    YELLOW = 'yellow',
}

interface IndicatorProps {
    data: Indicator;
}

export const Indicator: React.FC<IndicatorProps> = ({ data }) => {
    const { position, color } = data;

    return <div className={classNames(style.indicator, style[position], style[color])} />;
};

export const CardBody: React.FC<Props> = ({
    children,
    indicator,
    isOpenedInSidebar,
    onMouseEnter,
    onMouseLeave,
    onCardClick,
}) => (
    <div
        className={classNames(style.root, isOpenedInSidebar && style.rootWithOpenedSidebar, style[indicator.position])}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onCardClick}
        {...{
            'qa-id': 'cardBody',
        }}
    >
        {children}

        <Indicator data={indicator} />
    </div>
);
