import * as React from 'react';
import classnames from 'classnames';
import { v4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { IconType } from 'sber-marketing-ui';
import { ActivityParams } from 'sber-marketing-types/frontend';
import { OneTaskResponseParams } from 'sber-marketing-types/backend';
import { WithGlobalPopup, AbsoluteLikePositionCalculator } from 'sber-marketing-ui';

import { TaskApi, ActivityApi } from '@api';

import { FileAsset } from '@store/commonTypes';
import { initInstance as initTagsEditorInstance, dropInstance as dropTagsEditorInstance } from '@store/tagsEditor';

import {
    SidebarTabRendererProps,
    SidebarWithTabs,
    SidebarTab,
    DefaultSidebarTabRenderer,
} from '@common/SidebarWithTabs';

import { Header } from './Header';
import { CommentChannelSelector } from './CommentChannelSelector';
import { InfoTab, ParticipantsTab, CommentsTab, FilesTab } from './tabs';

import * as styles from './TaskSidebar.scss';

const enum Tabs {
    Info = 'Info',
    Participants = 'Participants',
    Comments = 'Comments',
    Files = 'Files',
}

export interface TaskSidebarRef {
    refetchContent: () => void;
}

interface Props {
    sidebarRef?: React.Ref<TaskSidebarRef>;
    taskId: string;
    width?: number;
    top?: number;
    zIndex?: number;
    closeTaskSidebar?: () => void;
    goBackToActivitySidebar: (activityId: number) => void;
}

interface Content {
    taskContent: OneTaskResponseParams;
    activityContent: ActivityParams;
    taskRefetchInProgress: boolean;
}

function makeDefaultContent(): Content {
    return { taskContent: null, activityContent: null, taskRefetchInProgress: false };
}

interface ChannelSelectorContextProps {
    taskContent: OneTaskResponseParams;
    selectedChannelId: number;
    zIndex: number;
    setSelectedChannelId: (channelId: number) => void;
}

const ChannelSelectorContext = React.createContext<ChannelSelectorContextProps>({
    taskContent: null,
    selectedChannelId: null,
    setSelectedChannelId: null,
    zIndex: null,
});

const TabsDesc: SidebarTab[] = [
    {
        qaId: 'taskSidebarInfoTabButton',
        id: Tabs.Info,
        title: 'Информация',
        icon: IconType.TASK_SIDEBAR_INFO_ICON,
    },
    {
        qaId: 'taskSidebarParticipantsTabButton',
        id: Tabs.Participants,
        title: 'Участники',
        icon: IconType.TASK_SIDEBAR_USERS_ICON,
    },
    {
        qaId: 'taskSidebarCommentsTabButton',
        id: Tabs.Comments,
        title: 'Комментарии',
        icon: IconType.TASK_SIDEBAR_COMMENTS_ICON,
        renderSidebarTab: CommentsTabItem,
    },
    {
        qaId: 'taskSidebarFilesTabButton',
        id: Tabs.Files,
        title: 'Вложения',
        icon: IconType.TASK_SIDEBAR_FILES_ICON,
    },
];

function useContent({ taskId }: Props) {
    const [content, setContent] = React.useState<Content>(makeDefaultContent());
    const fetcherId = React.useRef<string>(null);

    React.useEffect(() => {
        fetcherId.current = v4();
        refetchContent();
    }, [taskId]);

    async function refetchContent() {
        const currentFetcherId = fetcherId.current;

        if (content.taskContent) {
            setContent(makeDefaultContent());
        }

        const taskContent = await TaskApi.getTaskShortened(taskId);
        if (currentFetcherId === fetcherId.current) {
            setContent((content) => ({
                ...content,
                taskContent,
            }));

            const activityContent = await ActivityApi.getActivity(taskContent?.activityId);
            if (currentFetcherId === fetcherId.current) {
                setContent((content) => ({
                    ...content,
                    activityContent,
                    taskRefetchInProgress: false,
                }));
            }
        }
    }

    async function sendComment(
        channelId: number,
        commentId: string,
        text: string,
        files: FileAsset[],
        replyId: string,
    ) {
        setTaskRefetchInProgress();

        await TaskApi.createComment(taskId, {
            id: commentId,
            text,
            chanelId: channelId,
            files: files as any,
            replyId,
        });

        await refetchTask();
    }

    async function toggleCommentReaction(
        params: { commentId: string; channelId: number; taskId: string },
        reaction: string,
    ) {
        const { taskId, commentId, channelId } = params;

        await TaskApi.reactToTaskComment(taskId, commentId, { reaction });
        const comment = (await TaskApi.getTaskComment(taskId, commentId)).comment;

        setContent((content) => ({
            ...content,
            taskContent: {
                ...content.taskContent,
                chanels: content.taskContent.chanels.map((channel) => ({
                    ...channel,
                    comments:
                        channel.id === channelId
                            ? channel.comments.map((c) => (c.id === commentId ? comment : c))
                            : channel.comments,
                })),
            },
        }));
    }

    async function updateParticipants(participantIds: number[]) {
        setTaskRefetchInProgress();

        await TaskApi.updateTaskParticipants(content?.taskContent?.id, participantIds);

        await refetchTask();
    }

    async function setTaskRefetchInProgress() {
        setContent({ ...content, taskRefetchInProgress: true });
    }

    async function refetchTask() {
        setContent({
            activityContent: content.activityContent,
            taskContent: await TaskApi.getTaskShortened(taskId),
            taskRefetchInProgress: false,
        });
    }

    return { content, sendComment, updateParticipants, refetchContent, toggleCommentReaction };
}

function useTagsEditor(taskId: string) {
    const dispatch = useDispatch();
    const tagsEditorId = React.useMemo<string>(v4, []);

    React.useEffect(function initTagsEditorEffect() {
        dispatch(
            initTagsEditorInstance({
                id: tagsEditorId,
                payload: { taskId },
            }),
        );

        return function resetTaskEditorEffect() {
            dispatch(dropTagsEditorInstance(tagsEditorId));
        };
    }, []);

    return tagsEditorId;
}

function useSidebarTabs(taskContent: OneTaskResponseParams) {
    const [activeTab, setActiveTab] = React.useState<Tabs>(Tabs.Comments);
    const [selectedChannelId, setSelectedChannelId] = React.useState<number>(null);

    return {
        activeTab,
        setActiveTab,
        selectedChannelId,
        setSelectedChannelId,
    };
}

function useTaskSidebar(props: Props) {
    const { content, sendComment, updateParticipants, refetchContent, toggleCommentReaction } = useContent(props);
    const { activeTab, setActiveTab, selectedChannelId, setSelectedChannelId } = useSidebarTabs(content?.taskContent);
    const tagsEditorId = useTagsEditor(props.taskId);

    React.useImperativeHandle(props.sidebarRef, () => ({
        refetchContent,
    }));

    return {
        activeTab,
        setActiveTab,
        content,
        tagsEditorId,
        sendComment,
        updateParticipants,
        selectedChannelId,
        setSelectedChannelId,
        toggleCommentReaction,
    };
}

export function TaskSidebar(props: Props): JSX.Element {
    const {
        activeTab,
        setActiveTab,
        content,
        tagsEditorId,
        sendComment,
        updateParticipants,
        selectedChannelId,
        setSelectedChannelId,
        toggleCommentReaction,
    } = useTaskSidebar(props);
    const { width, top, zIndex, closeTaskSidebar, goBackToActivitySidebar } = props;

    return (
        <ChannelSelectorContext.Provider
            value={{ taskContent: content.taskContent, selectedChannelId, zIndex: props.zIndex, setSelectedChannelId }}
        >
            <SidebarWithTabs
                qaId="taskSidebar"
                header={
                    <Header
                        {...content}
                        closeTaskSidebar={closeTaskSidebar}
                        goBackToActivitySidebar={goBackToActivitySidebar}
                    />
                }
                tabs={TabsDesc}
                activeTab={activeTab}
                width={width}
                top={top}
                zIndex={zIndex}
                onActiveTabChange={setActiveTab as (tabId: Tabs) => void}
                renderTab={(activeTab: Tabs) => (
                    <RenderTab
                        activeTab={activeTab}
                        taskContent={content.taskContent}
                        selectedChannelId={selectedChannelId}
                        taskRefetchInProgress={content.taskRefetchInProgress}
                        tagsEditorId={tagsEditorId}
                        zIndex={zIndex}
                        sendComment={sendComment}
                        updateParticipants={updateParticipants}
                        toggleCommentReaction={toggleCommentReaction}
                    />
                )}
            />
        </ChannelSelectorContext.Provider>
    );
}

interface RenderTabProps {
    activeTab: Tabs;
    taskContent: OneTaskResponseParams;
    selectedChannelId: number;
    taskRefetchInProgress: boolean;
    tagsEditorId: string;
    zIndex: number;
    sendComment: (
        channelId: number,
        commentId: string,
        text: string,
        files: FileAsset[],
        replyId: string,
    ) => Promise<void>;
    updateParticipants: (particiopantIds: number[]) => Promise<void>;
    toggleCommentReaction: (
        params: { commentId: string; taskId: string; channelId: number },
        reaction: string,
    ) => Promise<void>;
}

function RenderTab({
    activeTab,
    taskContent,
    selectedChannelId,
    taskRefetchInProgress,
    tagsEditorId,
    zIndex,
    sendComment,
    updateParticipants,
    toggleCommentReaction,
}: RenderTabProps): JSX.Element {
    switch (activeTab) {
        case Tabs.Info:
            return <InfoTab taskContent={taskContent} tagsEditorId={tagsEditorId} zIndex={zIndex} />;
        case Tabs.Participants:
            return (
                <ParticipantsTab
                    taskContent={taskContent}
                    taskRefetchInProgress={taskRefetchInProgress}
                    updateParticipants={updateParticipants}
                />
            );
        case Tabs.Comments:
            return (
                <CommentsTab
                    taskContent={taskContent}
                    channelId={selectedChannelId}
                    taskRefetchInProgress={taskRefetchInProgress}
                    sendComment={sendComment}
                    toggleCommentReaction={toggleCommentReaction}
                />
            );
        case Tabs.Files:
            return <FilesTab taskContent={taskContent} />;
        default:
            console.warn(`Missing tab definition for ${activeTab}`);
            return null;
    }
}

function CommentsTabItem(props: SidebarTabRendererProps): JSX.Element {
    const [isChannelSelectorOpened, setIsChannelSelectorOpened] = React.useState(false);

    const ref = React.useRef<HTMLSpanElement>();
    const channelSelectorContext = React.useContext(ChannelSelectorContext);

    const addCommentsSelectorControls =
        !channelSelectorContext.taskContent?.chanels || channelSelectorContext.taskContent?.chanels?.length > 1;

    function onClick(event: React.MouseEvent<HTMLSpanElement>) {
        event.preventDefault();
        event.stopPropagation();

        if (addCommentsSelectorControls) {
            setIsChannelSelectorOpened(true);
        } else {
            channelSelectorContext.setSelectedChannelId(channelSelectorContext.taskContent.chanels[0].id);
            props.onActiveTabChange(props.tab.id);
        }
    }

    function onChannelSelect(channelId: number) {
        setIsChannelSelectorOpened(false);
        channelSelectorContext.setSelectedChannelId(channelId);
    }

    return (
        <React.Fragment>
            <span ref={ref} className={styles.commentsTab} onClick={onClick}>
                <DefaultSidebarTabRenderer {...props} />

                {addCommentsSelectorControls && (
                    <svg
                        className={classnames(
                            styles.commentsTabExpandIcon,
                            isChannelSelectorOpened && styles.commentsTabExpandIconActive,
                        )}
                        width="8"
                        height="16"
                        viewBox="0 0 8 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7.69398 8.76777C8.10201 8.36337 8.10201 7.7077 7.69398 7.3033C7.3151 6.92779 6.71763 6.90096 6.30752 7.22283L6.21639 7.3033L4.0005 9.5005L1.78361 7.3033C1.40473 6.92779 0.807258 6.90096 0.397149 7.22283L0.306019 7.3033C-0.0728618 7.67882 -0.0999247 8.27098 0.224831 8.67745L0.306019 8.76777L3.2612 11.6967C3.64008 12.0722 4.23756 12.099 4.64767 11.7772L4.7388 11.6967L7.69398 8.76777Z"
                            fill="#7E8681"
                        />
                    </svg>
                )}
            </span>

            {isChannelSelectorOpened && (
                <WithGlobalPopup
                    container={ref}
                    positionCalculator={AbsoluteLikePositionCalculator}
                    top={33}
                    left={0}
                    zIndex={channelSelectorContext.zIndex + 10}
                    maskZIndex={channelSelectorContext.zIndex + 5}
                    onMaskClick={() => setIsChannelSelectorOpened(false)}
                >
                    <CommentChannelSelector
                        taskContent={channelSelectorContext.taskContent}
                        onChannelSelect={onChannelSelect}
                    />
                </WithGlobalPopup>
            )}
        </React.Fragment>
    );
}
