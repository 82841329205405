import * as React from 'react';
import autobind from 'autobind-decorator';

import { FileCard, FileProps } from './FileCard';

interface Props extends Partial<FileProps> {
    showDeleteButton: boolean;
    showDownloadButton: boolean;
    loading?: boolean;
    onDeleteButtonClick?: (fileId: string) => void;
}

interface State {
    showDeleteButton: boolean;
}

export class FileCardContainer extends React.PureComponent<Props, State> {
    private showButtonTimeout: NodeJS.Timeout;

    public constructor(props: Props) {
        super(props);

        this.showButtonTimeout = null;

        this.state = {
            showDeleteButton: false,
        };
    }

    public render(): JSX.Element {
        return (
            <FileCard
                {...this.props}
                {...this.state}
                onCardMouseEnter={this.onCardMouseEnter}
                onCardMouseLeave={this.onCardMouseLeave}
                onDeleteButtonClick={this.onDeleteButtonClick}
            />
        );
    }

    @autobind
    private onCardMouseEnter(): void {
        if (this.props.showDeleteButton) {
            this.showButtonTimeout = setTimeout(() => {
                if (this.showButtonTimeout) {
                    this.setState({
                        showDeleteButton: true,
                    });
                    this.showButtonTimeout = null;
                }
            }, 250);
        }
    }

    @autobind
    private onCardMouseLeave(): void {
        this.setState({
            showDeleteButton: false,
        });
        if (this.showButtonTimeout) {
            clearTimeout(this.showButtonTimeout);
            this.showButtonTimeout = null;
        }
    }

    @autobind
    private onDeleteButtonClick(): void {
        if (this.props.onDeleteButtonClick) {
            this.props.onDeleteButtonClick(this.props.id);
        }
    }
}
