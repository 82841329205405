import * as React from 'react';

import * as style from './CampaignPerformanceTable.scss';

import type {
    CellEvent,
    CellPosition,
    ColumnName,
    ColumnWidths,
    LineId,
    TableBodyCellParams,
    TableHeaderCellParams,
} from './types';

import { TableView } from './TableView';
import { CellsStorage } from './CellsStorage';

interface Props {
    headerCellsStorage: CellsStorage<string, TableHeaderCellParams>;
    tableCellsStorage: CellsStorage<CellPosition, TableBodyCellParams>;
    columns: ColumnName[];
    leftFixedColumns: ColumnName[];
    rightFixedColumns: ColumnName[];
    lineIds: LineId[];
    columnWidths: ColumnWidths;
    tableRef: (component: TableView) => void;
    onCellEvent: (eventType: CellEvent, position: CellPosition) => void;
}

export const CampaignPerformanceTableTemplate = ({
    headerCellsStorage,
    tableCellsStorage,
    columns,
    leftFixedColumns,
    rightFixedColumns,
    lineIds,
    columnWidths,
    tableRef,
    onCellEvent,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <TableView
                ref={tableRef}
                headerCellsStorage={headerCellsStorage}
                tableCellsStorage={tableCellsStorage}
                columns={columns}
                leftFixedColumns={leftFixedColumns}
                rightFixedColumns={rightFixedColumns}
                lines={lineIds}
                columnWidths={columnWidths}
                onCellEvent={onCellEvent}
            />
        </div>
    );
};
