import * as React from 'react';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';

import { SelectItem } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getActivity } from '@store/activityTasksPage';
import { fetchMoreTasks, TasksListType, resetTasksList } from '@store/tasksList';
import {
    setStagesEditorPopupVisibility,
    resetStagesEditorPopup,
    selectStageTemplate,
    stagesHaveChanged,
    getActivityStageState,
    getStageTemplatesAsSelectItems,
    saveActivityStages,
} from '@store/activityTasksPage/stages';

import { StagesEditorPopup } from './StagesEditorPopup';

interface Props extends Partial<MapProps & DispatchProps> {}

interface MapProps {
    activityId: number;
    requestInProgress: boolean;
    selectedTemplateId: string;
    templateSelectItems: SelectItem[];
    editExistingStagesMode: boolean;
    stageTemplateHaveChanged: boolean;
    stagesHaveChanged: boolean;
}

interface DispatchProps {
    closeStagesEditorPopup: () => void;
    resetStagesEditorPopup: () => void;
    selectStageTemplate: (templateId: string) => void;
    saveActivityStages: () => void;
    resetSelectedTemplate: () => void;
    resetTasksList: () => void;
    fetchMoreTasks: () => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class StagesEditorPopupContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const {
            editExistingStagesMode,
            templateSelectItems,
            selectedTemplateId,
            stageTemplateHaveChanged,
            stagesHaveChanged,
            closeStagesEditorPopup,
            resetSelectedTemplate,
        } = this.props;

        return (
            <StagesEditorPopup
                editExistingStagesMode={editExistingStagesMode}
                templateSelectItems={templateSelectItems}
                selectedTemplate={selectedTemplateId}
                stageTemplateHaveChanged={stageTemplateHaveChanged}
                stagesHaveChanged={stagesHaveChanged}
                closePopup={closeStagesEditorPopup}
                onTemplateSelect={this.onTemplateSelect}
                onCancelButtonClick={this.onCancelButtonClick}
                onSaveButtonClick={this.onSaveButtonClick}
                onOutOfContextClick={this.onOutOfContextClick}
                onDeleteTemplateButtonClick={resetSelectedTemplate}
            />
        );
    }

    @autobind
    private onTemplateSelect(item: SelectItem): void {
        const value = item && (item.value as string);

        if (value !== this.props.selectedTemplateId) {
            this.props.selectStageTemplate(value);
        }
    }

    @autobind
    private onCancelButtonClick(): void {
        if (!this.props.requestInProgress) {
            this.props.closeStagesEditorPopup();
            this.props.resetStagesEditorPopup();
        }
    }

    @autobind
    private onSaveButtonClick(): void {
        this.props.saveActivityStages();
        this.props.closeStagesEditorPopup();
        this.props.resetTasksList();
        this.props.fetchMoreTasks();
    }

    @autobind
    private onOutOfContextClick(): void {
        if (!this.props.requestInProgress) {
            this.props.closeStagesEditorPopup();
        }
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { id: activityId } = getActivity(state);
    const { pendingStages, existingStages, stageRequestInProgress } = getActivityStageState(state);

    const selectedTemplateId = pendingStages.selectedTemplateId;
    const stageTemplateHaveChanged =
        pendingStages.selectedTemplateId !== existingStages.selectedTemplateId && !!existingStages.selectedTemplateId;
    const editExistingStagesMode = existingStages.selectedTemplateId && stageTemplateHaveChanged;
    const templateSelectItems = getStageTemplatesAsSelectItems(state);

    return {
        requestInProgress: stageRequestInProgress,
        activityId,
        selectedTemplateId,
        templateSelectItems,
        editExistingStagesMode,
        stageTemplateHaveChanged,
        stagesHaveChanged: stagesHaveChanged(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        closeStagesEditorPopup: () => dispatch(setStagesEditorPopupVisibility(false)),
        selectStageTemplate: (templateId: string) => dispatch(selectStageTemplate(templateId)),
        resetStagesEditorPopup: () => dispatch(resetStagesEditorPopup()),
        saveActivityStages: () => dispatch(saveActivityStages(null)),
        resetSelectedTemplate: () => dispatch(selectStageTemplate(null)),
        fetchMoreTasks: () => dispatch(fetchMoreTasks(TasksListType.ACTIVITY_TASKS)),
        resetTasksList: () => dispatch(resetTasksList()),
    };
}
