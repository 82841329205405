import * as React from 'react';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { UserResponseParams } from 'sber-marketing-types/backend';

import { StoreState } from '@store';
import { getLoginUser, User } from '@store/user';
import { getPageData, setUnsavedChange } from '@store/budgetCorrections';

import { PageScrollWatcher } from '@common/PageScrollWatcher';

import { Correction, Dictionary, UnsavedChange } from '../types';

import { CorrectionsList } from './CorrectionsList';

interface Props extends Partial<MapProps & DispatchProps> {
    budgetId: string;
    corrections: Correction[];
    fetchMore: () => void;
}

interface MapProps {
    user: User;
    users: UserResponseParams[];
    dictionaries: Dictionary[];
}

interface DispatchProps {
    setUnsavedChange: (unsavedChange: UnsavedChange) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class CorrectionsListContainer extends React.PureComponent<Props> {
    private pageScrollWatcher: PageScrollWatcher = new PageScrollWatcher({ offset: 3 });

    public componentDidMount() {
        this.pageScrollWatcher.reInit();
        this.pageScrollWatcher.on(PageScrollWatcher.SCROLLED_DOWN_EVENT, this.props.fetchMore);
    }

    public componentWillUnmount() {
        this.pageScrollWatcher.dispose();
    }

    public render(): JSX.Element {
        const { corrections, dictionaries, users, user, budgetId } = this.props;

        return React.createElement(CorrectionsList, {
            budgetId,
            corrections,
            user,
            users,
            dictionaries,
            setUnsavedChange: this.props.setUnsavedChange,
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { users, dictionaries } = getPageData(state);
    const user = getLoginUser(state);

    return {
        user,
        users,
        dictionaries,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        setUnsavedChange: (unsavedChange: UnsavedChange) => dispatch(setUnsavedChange(unsavedChange)),
    };
}
