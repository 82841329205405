import * as React from 'react';

import * as style from './StickyWrapper.scss';

import { BodyPortal } from 'sber-marketing-ui';

export const HEADER_HEIGHT = 97;

export const enum Position {
    Top = 'top',
    Bottom = 'bottom',
}

interface Props {
    children: React.ReactNode;
    position: Position;
    isVisible: boolean;
    rootRef: React.RefObject<HTMLDivElement>;
    customStyle?: any;
}

export const StickyWrapperTemplate = ({ children, position, isVisible, rootRef, customStyle }: Props): JSX.Element => {
    if (!isVisible) {
        return null;
    }

    return (
        <BodyPortal>
            <div
                className={style.root}
                ref={rootRef}
                style={{
                    top: position == Position.Top ? HEADER_HEIGHT : null,
                    bottom: position == Position.Bottom ? 0 : null,
                    ...customStyle,
                }}
            >
                {children}
            </div>
        </BodyPortal>
    );
};
