import * as React from 'react';

import * as style from './WeekSelection.scss';

import { Icon, IconType } from 'sber-marketing-ui';

interface Props {
    title: string;
    onWeekChangeForward: () => void;
    onWeekChangeBackward: () => void;
}

export const WeekSelection = ({ title, onWeekChangeForward, onWeekChangeBackward }: Props): JSX.Element => {
    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'topMenuWeekSelection',
            }}
        >
            <div
                className={style.button}
                onClick={onWeekChangeBackward}
                {...{
                    'qa-id': 'topMenuWeekChangeBackwardButton',
                }}
            >
                <Icon type={IconType.ARROW16_LEFT} />
            </div>

            <div className={style.title}>{title}</div>

            <div
                className={style.button}
                onClick={onWeekChangeForward}
                {...{
                    'qa-id': 'topMenuWeekChangeForwarddButton',
                }}
            >
                <Icon type={IconType.ARROW16_RIGHT} />
            </div>
        </div>
    );
};
