// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-task-components-rest-TaskFiles-_TaskFiles__root{position:relative}.src-client-modules-task-components-rest-TaskFiles-_TaskFiles__upload{gap:8px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/task/components/rest/TaskFiles/TaskFiles.scss"],"names":[],"mappings":"AAAA,oEACE,iBAAkB,CACnB,sEAGC,OAAQ","sourcesContent":[".root {\n  position: relative;\n}\n\n.upload {\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-task-components-rest-TaskFiles-_TaskFiles__root",
	"upload": "src-client-modules-task-components-rest-TaskFiles-_TaskFiles__upload"
};
export default ___CSS_LOADER_EXPORT___;
