import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { uniq } from 'lodash';

import { StoreState } from '@store';
import type { FileAsset } from '@store/commonTypes';
import {
    getTaskPageState,
    getCommentariesSortedIds,
    getCommentaryById,
    getCommentChildrenIds,
} from '@store/taskPage/selectors';

import { TaskTitle } from '../TaskTitle';
import { ChannelsBoard } from '../../ChannelsBoard';
import { TaskInfo } from '../TaskInfo';
import { TaskCommentForm } from '../TaskCommentForm';
import { TaskComment } from '../TaskComment';

import * as style from './CommentsAndDescription.scss';

interface Props {
    className?: string;
    id?: string;
    title?: string;
    description?: string;
    leader?: string;
    createdAt?: number | string;
    deadlineAt?: number | string;
    isAddCommentDisable?: boolean;
    isMainChannel: boolean;
    canDeleteFiles: boolean;
    canDisplayDropdownMenu: boolean;
    updateCommentDisabled: boolean;
    previousUrl: string;
    channelId: number;
    onTaskEditClick?: () => void | Promise<void>;
    onAssetRemoveClick?: (data: FileAsset) => void | Promise<void>;
}

export function CommentsAndDescription({
    className,
    id,
    createdAt,
    deadlineAt,
    leader,
    description,
    isAddCommentDisable,
    isMainChannel,
    canDeleteFiles,
    canDisplayDropdownMenu,
    updateCommentDisabled,
    previousUrl,
    channelId,
    onTaskEditClick,
    onAssetRemoveClick,
}: Props): JSX.Element {
    return (
        <div className={classNames(style.root, className)}>
            <TaskTitle
                previousUrl={previousUrl}
                className={style.taskTitle}
                showEditButton={canDisplayDropdownMenu}
                onEditButtonClick={onTaskEditClick}
            />

            <ChannelsBoard className={style.channelsBoard} />

            {isMainChannel && (
                <TaskInfo
                    taskId={id}
                    className={style.taskInfo}
                    createdAt={createdAt}
                    deadlineAt={deadlineAt}
                    leader={leader}
                    description={description}
                    canDeleteFiles={canDeleteFiles}
                    onAssetRemoveClick={onAssetRemoveClick}
                />
            )}

            {!isAddCommentDisable && <TaskCommentForm taskId={id} className={style.commentForm} />}

            <CommentsList
                channelId={channelId}
                isMainChannel={isMainChannel}
                updateCommentDisabled={updateCommentDisabled}
            />
        </div>
    );
}

interface CommentsListProps {
    channelId: number;
    isMainChannel: boolean;
    updateCommentDisabled: boolean;
}

function CommentsList({ channelId, isMainChannel, updateCommentDisabled }: CommentsListProps): JSX.Element {
    const commentIdToReplyTo = useSelector((state: StoreState) => getTaskPageState(state).commentIdToReplyTo);

    return commentIdToReplyTo ? (
        <CommentWithChildrenList
            commentId={commentIdToReplyTo}
            isMainChannel={isMainChannel}
            updateCommentDisabled={updateCommentDisabled}
        />
    ) : (
        <ChannelCommentsList
            channelId={channelId}
            isMainChannel={isMainChannel}
            updateCommentDisabled={updateCommentDisabled}
        />
    );
}

interface ChannelCommentsListProps {
    channelId: number;
    isMainChannel: boolean;
    updateCommentDisabled: boolean;
}

function ChannelCommentsList({
    channelId,
    isMainChannel,
    updateCommentDisabled,
}: ChannelCommentsListProps): JSX.Element {
    const commentsIds = useSelector((state: StoreState) => getCommentariesSortedIds(state, channelId));

    return (
        <React.Fragment>
            {commentsIds.map((id, index, allIds) => (
                <Comment
                    key={id}
                    id={id}
                    index={index}
                    allIds={allIds}
                    isMainChannel={isMainChannel}
                    updateDisabled={updateCommentDisabled}
                />
            ))}
        </React.Fragment>
    );
}

interface CommentWithChildrenListProps {
    commentId: string;
    isMainChannel: boolean;
    updateCommentDisabled: boolean;
}

function CommentWithChildrenList({
    commentId,
    isMainChannel,
    updateCommentDisabled,
}: CommentWithChildrenListProps): JSX.Element {
    const comment = useSelector((state: StoreState) => getCommentaryById(state, commentId));
    const childrenCommentIds = useSelector((state: StoreState) => getCommentChildrenIds(state, commentId));

    const allIds = uniq([commentId, ...childrenCommentIds]);

    return (
        <React.Fragment>
            <Comment
                id={comment.id}
                index={0}
                allIds={allIds}
                isMainChannel={isMainChannel}
                updateDisabled={updateCommentDisabled}
            />

            {childrenCommentIds.map((id, index) => (
                <Comment
                    key={id}
                    id={id}
                    index={index + 1}
                    allIds={allIds}
                    isMainChannel={isMainChannel}
                    updateDisabled={updateCommentDisabled}
                />
            ))}
        </React.Fragment>
    );
}

interface CommentProps {
    id: string;
    index: number;
    allIds: string[];
    updateDisabled: boolean;
    isMainChannel: boolean;
}

const Comment: React.FunctionComponent<CommentProps> = ({ id, index, allIds, updateDisabled, isMainChannel }) => (
    <TaskComment
        className={classNames(style.comment, isMainChannel && index === allIds.length - 1 && style.comment_last)}
        id={id}
        updateDisabled={updateDisabled}
    />
);
