import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { Icon, IconType, AnimatedEllipsis, StaticSkeleton } from 'sber-marketing-ui';

import { AuthApi } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getLoginUser } from '@store/user';
import { getProfileMenuVacationState, setDeleteVacationPopupVisibility } from '@store/profileMenu/vacation';
import { ComponentState, setComponentState, getProfileMenuState } from '@store/profileMenu';

import { DatesFormatter } from '@common/Utils';

import * as commonStyles from '../CommonStyles.scss';
import * as styles from './Menu.scss';

export function Menu(): JSX.Element {
    const isLoading = useSelector(
        (state: StoreState) => getProfileMenuState(state).loadingStatus !== LoadingStatus.LOADED,
    );

    if (isLoading) {
        return (
            <React.Fragment>
                <div className={commonStyles.paddingT}>
                    <Title />
                    <Skeleton />
                </div>

                <div className={styles.divider} />

                <Skeleton />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className={commonStyles.paddingT}>
                <Title />
                <VacationButton />
                <NotificationButton />
            </div>

            <div className={styles.divider} />

            <LogoutButton />
        </React.Fragment>
    );
}

function Title(): JSX.Element {
    const user = useSelector((state: StoreState) => getLoginUser(state));

    return (
        <div className={classnames(styles.title, commonStyles.paddingRL)}>
            {user.attributes.firstName} {user.attributes.secondName}
        </div>
    );
}

function useVacationButtonInteractivity() {
    const dispatch = useDispatch();

    const existingVacation = useSelector((state: StoreState) => getProfileMenuVacationState(state).existingVacation);

    function onClick() {
        dispatch(setComponentState(ComponentState.VacationEditor));
    }

    return { existingVacation, onClick };
}

function VacationButton(): JSX.Element {
    const { existingVacation, onClick } = useVacationButtonInteractivity();

    let content: JSX.Element;
    if (existingVacation) {
        const subText = `${DatesFormatter.ddMonthyy(existingVacation?.start)} - ${DatesFormatter.ddMonthyy(
            existingVacation?.end,
        )}`;

        content = (
            <div className={styles.vacationButtonContent}>
                <StandartButtonContent
                    icon={IconType.PROFILE_MENU_VACATION_BUTTON}
                    text="Изменить даты отпуска"
                    subText={subText}
                />

                <DeleteVacationButton />
            </div>
        );
    } else {
        content = <StandartButtonContent icon={IconType.PROFILE_MENU_VACATION_BUTTON} text="Назначить даты отпуска" />;
    }

    return <Button content={content} onClick={onClick} />;
}

function useDeleteVacationButtonInteractivity() {
    const dispatch = useDispatch();

    const isRequestInProgress = useSelector<StoreState>(
        (state) => getProfileMenuVacationState(state).isRequestInProgress,
    );

    function onClick(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();

        dispatch(setDeleteVacationPopupVisibility(true));
    }

    return { isRequestInProgress, onClick };
}

function DeleteVacationButton(): JSX.Element {
    const { isRequestInProgress, onClick } = useDeleteVacationButtonInteractivity();

    const content: JSX.Element = isRequestInProgress ? (
        <AnimatedEllipsis text="Отмена" />
    ) : (
        <React.Fragment>Отменить отпуск</React.Fragment>
    );

    return (
        <div
            className={classnames(
                styles.deleteVacationButton,
                !isRequestInProgress && styles.deleteVacationButtonHoverable,
            )}
            onClick={onClick}
        >
            {content}
        </div>
    );
}

function useNotificationButtonInteractivity() {
    const dispatch = useDispatch();

    function onClick() {
        dispatch(setComponentState(ComponentState.NotificationEditor));
    }

    return { onClick };
}

function NotificationButton(): JSX.Element {
    const { onClick } = useNotificationButtonInteractivity();

    return (
        <Button
            content={
                <div className={styles.standartButtonContent}>
                    <Icon type={IconType.NOTIFICATIONS_NORMAL} svgSize={24} className={styles.buttonIcon} />

                    <div>
                        <div className={styles.standartButtonText}>Настройки уведомлений</div>
                    </div>
                </div>
            }
            onClick={onClick}
        />
    );
}

function LogoutButton(): JSX.Element {
    return (
        <Button
            content={<StandartButtonContent icon={IconType.PROFILE_MENU_LOGOUT_BUTTON} text="Выйти из профиля" />}
            onClick={AuthApi.logout}
        />
    );
}

interface ButtonProps {
    content: JSX.Element;
    onClick: () => void;
}

function Button({ content, onClick }: ButtonProps): JSX.Element {
    return (
        <div className={classnames(styles.button, styles.buttonHoverable, commonStyles.paddingRL)} onClick={onClick}>
            {content}
        </div>
    );
}

interface StandartButtonContentProps {
    icon: IconType;
    text: string;
    subText?: string;
}

function StandartButtonContent({ icon, text, subText }: StandartButtonContentProps): JSX.Element {
    return (
        <div className={styles.standartButtonContent}>
            <Icon type={icon} svgSize={24} className={styles.buttonIcon} />

            <div>
                <div className={styles.standartButtonText}>{text}</div>

                {subText && <div className={styles.standartButtonSubtext}>{subText}</div>}
            </div>
        </div>
    );
}

function Skeleton(): JSX.Element {
    return (
        <div className={classnames(styles.button, commonStyles.paddingRL)}>
            <StaticSkeleton className={styles.skeleton} />
        </div>
    );
}
