import * as React from 'react';
import * as style from './ControlButtons.scss';
import { Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';

import { CommentInput } from './CommentInput';

interface Props {
    mode: Mode;
    disableRejectButton: boolean;
    onApproveClick: () => void;
    onRejectClick: () => void;
    onCommentInputCreate: (comment: string) => void;
    onUnsetCommentInputFocused: (comment: string) => void;
    onCommentInputCancel: () => void;
}

export const enum Mode {
    Buttons = 'buttons',
    Comment = 'comment',
}

export const ControlButtons = ({
    mode,
    disableRejectButton,
    onApproveClick,
    onRejectClick,
    onCommentInputCreate,
    onUnsetCommentInputFocused,
    onCommentInputCancel,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            {mode === Mode.Comment ? (
                <div className={style.commentInputWrapper}>
                    <CommentInput
                        createComment={onCommentInputCreate}
                        unsetInputFocused={onUnsetCommentInputFocused}
                        onCancel={onCommentInputCancel}
                    />
                </div>
            ) : (
                <>
                    <Button
                        theme={ButtonTheme.GhostRounded}
                        onClick={onRejectClick}
                        qaId="budgetPlanningLineModalBudgetItemStatusRejectButton"
                    >
                        Отклонить
                    </Button>

                    <div>
                        <Button
                            disabled={disableRejectButton}
                            theme={ButtonTheme.MainRoundedUpdated}
                            onClick={onApproveClick}
                            qaId="budgetPlanningLineModalBudgetItemStatusApproveButton"
                        >
                            Согласовать
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};
