import * as React from 'react';
import { Icon, IconType } from 'sber-marketing-ui';
import { Tag, TagColorType, TagEmojiType } from '@mrm/tags';

import { TagColors, TagEmojis } from '@store/tags';

import { TagEmoji } from '@modules/tags/TagEmoji';

import * as styles from './TagEditor.scss';

interface Props {
    tag: Tag;
    onBackButtonClick: () => void;
    onColorChange: (color: TagColorType) => void;
    onEmojiChange: (emoji: TagEmojiType) => void;
}

export function TagEditor({ tag, onBackButtonClick, onColorChange, onEmojiChange }: Props): JSX.Element {
    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'tagsPreviewAndEditorTagEditor',
            }}
        >
            <div className={styles.title}>
                <div
                    className={styles.titleBackButton}
                    {...{
                        'qa-id': 'tagsPreviewAndEditorTagEditorBackButton',
                    }}
                >
                    <Icon type={IconType.UPD_ARROW_BACK} svgSize={16} onClick={onBackButtonClick} />
                </div>
                Редактирование тега
            </div>

            <div className={styles.tagTitle}>{tag.title}</div>

            <div className={styles.tagColorWrapper}>
                <Editor
                    qaId="tagsPreviewAndEditorColorEditor"
                    itemRenderer={ColorItemRenderer}
                    title="Цвет тега"
                    items={TagColors}
                    selectedItem={tag.color}
                    onItemSelect={onColorChange}
                    onItemRemove={() => onColorChange(null)}
                />
            </div>

            <div>
                <Editor
                    qaId="tagsPreviewAndEditorEmojiEditor"
                    itemRenderer={EmojiItemRenderer}
                    title="Рисунок на теге"
                    items={TagEmojis}
                    selectedItem={tag.emoji}
                    onItemSelect={onEmojiChange}
                    onItemRemove={() => onEmojiChange(null)}
                />
            </div>
        </div>
    );
}

interface SubtitleWithRemoveButtonProps {
    title: string;
    onRemoveButtonClick?: () => void;
}

function SubtitleWithRemoveButton({ title, onRemoveButtonClick }: SubtitleWithRemoveButtonProps): JSX.Element {
    return (
        <div className={styles.subTitleRow}>
            <div className={styles.subTitle}>{title}</div>

            <div
                className={styles.removeButton}
                onClick={onRemoveButtonClick}
                {...{
                    'qa-id': 'tagsEditorRemoveButton',
                }}
            >
                Удалить
            </div>
        </div>
    );
}

interface EditorProps {
    qaId: string;
    title: string;
    items: string[];
    selectedItem: string;
    itemRenderer: (item: string) => JSX.Element;
    onItemSelect: (itemId: string) => void;
    onItemRemove: () => void;
}

function Editor({
    qaId,
    title,
    items,
    selectedItem,
    itemRenderer,
    onItemSelect,
    onItemRemove,
}: EditorProps): JSX.Element {
    return (
        <div {...{ 'qa-id': qaId }}>
            <SubtitleWithRemoveButton title={title} onRemoveButtonClick={onItemRemove} />

            <div className={styles.editorItems}>
                {items.map((item) => (
                    <div
                        key={item}
                        className={styles.editorItem}
                        onClick={() => onItemSelect(item)}
                        {...{
                            'qa-id': 'tagsPreviewAndEditorTagEditorItem',
                            'qa-value': item,
                            'qa-is-selected': item === selectedItem ? 'true' : 'false',
                        }}
                    >
                        {itemRenderer(item)}

                        {item === selectedItem && (
                            <React.Fragment>
                                <div className={styles.editorItemSelectedBackground} />

                                <svg
                                    className={styles.editorItemSelectedIcon}
                                    width="12"
                                    height="9"
                                    viewBox="0 0 12 9"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0.387465 3.50554C-0.129155 4.02216 -0.129155 4.85977 0.387465 5.37639L3.50554 8.49446C4.02216 9.01108 4.85977 9.01108 5.37639 8.49446L11.6125 2.25831C12.1292 1.74169 12.1292 0.904085 11.6125 0.387465C11.0959 -0.129155 10.2583 -0.129155 9.74169 0.387465L4.44096 5.68819L2.25831 3.50554C1.74169 2.98892 0.904084 2.98892 0.387465 3.50554Z"
                                        fill="#F7F7F7"
                                    />
                                </svg>
                            </React.Fragment>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

function ColorItemRenderer(item: TagColorType): JSX.Element {
    return <div className={styles.colorItem} style={{ background: item }} />;
}

function EmojiItemRenderer(item: TagEmojiType): JSX.Element {
    return (
        <div className={styles.tagItem}>
            <TagEmoji type={item} />
        </div>
    );
}
