import * as React from 'react';

import * as style from './HeaderTextCell.scss';

export interface HeaderTextCellProps {
    value: React.ReactText;
}

export const HeaderTextCell = ({ value }: HeaderTextCellProps): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.title}>{value}</div>
        </div>
    );
};
