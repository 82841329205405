import * as React from 'react';
import autobind from 'autobind-decorator';
import * as moment from 'moment';
import * as lodash from 'lodash';

import { CorrectionSapExportMode } from '@store/budgetCorrections/types';
import type { UnsavedChange } from '@store/budgetCorrections/types';
import {
    PlanFundsTransferCorrection,
    CorrectionStatus,
    BudgetItem,
    Month,
    Persone,
    PLANNED_COLUMN_NAMES,
} from '../../../types';

import { PlanCorrectionCard, ParticipatorData, ParticipatorField, Warning } from './PlanCorrectionCard';

import { Utils } from '@common/Utils';
import { monthIndexMap } from '../utils';

interface Props extends PlanFundsTransferCorrection {
    budgetId: string;
    userIsBudgetExpert: boolean;
    userIsAuthorCorrection: boolean;
    setUnsavedChange: (unsavedChange: UnsavedChange) => void;
}

interface State {
    sapExportMode: CorrectionSapExportMode;
}

export class PlanCorrectionCardContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            sapExportMode: CorrectionSapExportMode.Upload,
        };
    }

    public render(): JSX.Element {
        const { serialNumber, creationTime, status, comment, author } = this.props;

        const authorName = this.formatUserName(author);
        const approverName = this.getApproverName();

        return React.createElement(PlanCorrectionCard, {
            serialNumber,
            authorName,
            approverName,
            userIsBudgetExpert: this.props.userIsBudgetExpert,
            userIsAuthorCorrection: this.props.userIsAuthorCorrection,
            creationDate: this.formatDate(creationTime),
            donorData: this.makeDonorData(),
            acceptorData: this.makeAcceptorData(),
            status,
            url: this.makeUrl(),
            rejectComment: comment,
            warnings: this.makeWarnings(),
            sapExportMode: this.state.sapExportMode,
            onSapExportModeSelection: this.onSapExportModeSelection,
            onStatusChange: this.onStatusChange,
        });
    }

    @autobind
    protected onSapExportModeSelection(value: CorrectionSapExportMode) {
        this.setState({
            sapExportMode: value,
        });
    }

    @autobind
    protected onStatusChange(status: CorrectionStatus) {
        const { id } = this.props;

        this.props.setUnsavedChange({
            correctionId: id,
            newStatus: status,
        });
    }

    private getApproverName(): string {
        const { status, expert, approver } = this.props;

        if (status === CorrectionStatus.NeedApproving) {
            return expert ? this.formatUserName(expert) : 'не задан';
        }

        return approver ? this.formatUserName(approver) : 'не задан';
    }

    private makeWarnings(): Warning[] {
        const warnings: Warning[] = [];

        const donorWarning = this.makeDonorWarning();
        const acceptorWarning = this.makeAcceptorWarning();

        if (donorWarning) {
            warnings.push(donorWarning);
        }

        if (acceptorWarning) {
            warnings.push(acceptorWarning);
        }

        return warnings;
    }

    private makeDonorWarning(): Warning | null {
        const { params } = this.props;

        const correctionValue = -params.value;

        return this.makeWarningData(correctionValue, this.donorBudgetItem);
    }

    private makeAcceptorWarning(): Warning | null {
        const { params } = this.props;

        const correctionValue = params.value;

        return this.makeWarningData(correctionValue, this.acceptorBudgetItem);
    }

    private makeWarningData(correctionValue: number, budgetItem: BudgetItem): Warning | null {
        const { status } = this.props;
        const currentPlannedFunds = budgetItem.plannedFunds;
        const currentReservedFonds = budgetItem.reservedFunds;

        const sumCurrentPlannedFunds = lodash.values(currentPlannedFunds).reduce<number>((acc, fond) => {
            return acc + fond;
        }, 0);
        const sumCurrentReservedFonds = lodash.values(currentReservedFonds).reduce<number>((acc, fond) => {
            return acc + fond;
        }, 0);

        const delta = sumCurrentReservedFonds - (correctionValue + sumCurrentPlannedFunds);

        if (delta > 0) {
            return {
                delta,
                budgetItemId: String(budgetItem.serialNumber),
                isActive: status === CorrectionStatus.NeedApproving,
            };
        }

        return null;
    }

    private makeAcceptorData(): ParticipatorData {
        const { params } = this.props;

        const budgetItem = this.acceptorBudgetItem;

        const delta = params.value;

        const month = params.acceptorMonth;

        // TODO В ответе graphQL висит поле __typename и для перебора свойст обхекта оно мешает
        delete budgetItem.plannedFunds['__typename'];
        const beforeFond = budgetItem.plannedFunds[month];

        const allBeforeFond = lodash
            .values(budgetItem.plannedFunds)
            .reduce<number>((acc, monthFond) => acc + monthFond, 0);

        const allFond = this.donorAndAcceptorFromSomeBudgetItem()
            ? { delta: 0, beforeFond: allBeforeFond, afterFond: allBeforeFond }
            : { delta, beforeFond: allBeforeFond, afterFond: allBeforeFond + delta };

        return {
            monthFond: {
                month: this.getMonthName(month),
                delta,
                beforeFond,
                afterFond: beforeFond + delta,
            },
            allFond,
            fondName: budgetItem.sapComment,
            fields: this.buildParticipatorFields(budgetItem, this.getAcceptorBudgetItemDonor()),
            comment: budgetItem.comment,
        };
    }

    private makeDonorData(): ParticipatorData {
        const { params } = this.props;

        const budgetItem = this.donorBudgetItem;

        const delta = -params.value;

        const month = params.donorMonth;

        // TODO В ответе graphQL висит поле __typename и для перебора свойст обхекта оно мешает
        delete budgetItem.plannedFunds['__typename'];
        const beforeFond = budgetItem.plannedFunds[month];

        const allBeforeFond = lodash
            .values(budgetItem.plannedFunds)
            .reduce<number>((acc, monthFond) => acc + monthFond, 0);

        const allFond = this.donorAndAcceptorFromSomeBudgetItem()
            ? { delta: 0, beforeFond: allBeforeFond, afterFond: allBeforeFond }
            : { delta, beforeFond: allBeforeFond, afterFond: allBeforeFond + delta };

        return {
            monthFond: {
                month: this.getMonthName(month),
                delta,
                beforeFond,
                afterFond: beforeFond + delta,
            },
            allFond,
            fondName: budgetItem.sapComment,
            fields: this.buildParticipatorFields(budgetItem, this.getDonorBudgetItemDonor()),
            comment: budgetItem.comment,
        };
    }

    private donorAndAcceptorFromSomeBudgetItem(): boolean {
        const { params, budgetItemCurrent: budgetItemsCurrent } = this.props;

        const serialNumberOfDonorBudgetItem = budgetItemsCurrent.find(({ id }) => id === params.donorId).serialNumber;
        const serialNumberOfAcceptorBudgetItem = budgetItemsCurrent.find(
            ({ id }) => id === params.acceptorId,
        ).serialNumber;

        return serialNumberOfAcceptorBudgetItem === serialNumberOfDonorBudgetItem;
    }

    // tslint:disable-next-line:cyclomatic-complexity
    private buildParticipatorFields(
        budgetItem: BudgetItem,
        donor: { donors: string; donorsShorted: string },
    ): ParticipatorField[] {
        return [
            {
                name: 'ID план',
                value: donor.donorsShorted,
                title: donor.donors,
            },
            {
                name: 'ID исполнение',
                value: `${budgetItem.serialNumber}`,
            },
            {
                name: 'Тип проекта',
                value:
                    budgetItem.dictionary &&
                    budgetItem.dictionary.activity_type &&
                    Utils.getDictionaryValue(budgetItem.dictionary.activity_type),
            },
            {
                name: 'Направление',
                value:
                    budgetItem.dictionary &&
                    budgetItem.dictionary.direction &&
                    Utils.getDictionaryValue(budgetItem.dictionary.direction),
            },
            {
                name: 'Дата запуска',
                value: budgetItem.realizationStart && this.formatDate(budgetItem.realizationStart),
            },
            {
                name: 'Дата окончания',
                value: budgetItem.realizationEnd && this.formatDate(budgetItem.realizationEnd),
            },
            {
                name: 'ЦА/ТБ (Территория)',
                value: budgetItem.dictionary?.regionality
                    ? Utils.getDictionaryValue(budgetItem.dictionary.regionality)
                    : '',
            },
            {
                name: 'Территория',
                value: budgetItem.dictionary?.territory
                    ? Utils.getDictionaryValue(budgetItem.dictionary.territory)
                    : '',
            },
            {
                name: 'Статья',
                value:
                    budgetItem.dictionary &&
                    budgetItem.dictionary.item &&
                    Utils.getDictionaryValue(budgetItem.dictionary.item),
            },
            {
                name: 'Номер ресурса',
                value: budgetItem.dictionary && budgetItem.dictionary.resource && budgetItem.dictionary.resource.value,
            },
            {
                name: 'Блок',
                value:
                    budgetItem.dictionary &&
                    budgetItem.dictionary.block &&
                    Utils.getDictionaryValue(budgetItem.dictionary.block),
                isWide: true,
            },
            {
                name: 'МВЗ / Дивизион',
                value:
                    budgetItem.dictionary &&
                    budgetItem.dictionary.division &&
                    Utils.getDictionaryValue(budgetItem.dictionary.division),
                isWide: true,
            },
            {
                name: 'Инструмент',
                value:
                    budgetItem.dictionary &&
                    budgetItem.dictionary.tool &&
                    Utils.getDictionaryValue(budgetItem.dictionary.tool),
                isWide: true,
            },
            {
                name: 'Центр затрат',
                value: budgetItem.dictionary?.cost_center?.value,
                isWide: true,
            },
        ].map((field) => ({
            ...field,
            title: field.value,
        }));
    }

    private get donorBudgetItem(): BudgetItem {
        const {
            status,
            budgetItemCurrent: budgetItemsCurrent,
            budgetItemBefore: budgetItemsBefore,
            params,
        } = this.props;

        const currentBudgetItem = budgetItemsCurrent.find(({ id }) => id === params.donorId);
        const beforeBudgetItem = lodash.isEmpty(budgetItemsBefore)
            ? null
            : budgetItemsBefore.find(({ id }) => id === params.donorId);

        const budgetItem =
            status === CorrectionStatus.NeedApproving
                ? currentBudgetItem
                : lodash.isEmpty(beforeBudgetItem)
                ? currentBudgetItem
                : beforeBudgetItem;

        delete budgetItem.plannedFunds['__typename'];
        delete budgetItem.reservedFunds['__typename'];
        return budgetItem;
    }

    private get acceptorBudgetItem(): BudgetItem {
        const {
            status,
            budgetItemCurrent: budgetItemsCurrent,
            budgetItemBefore: budgetItemsBefore,
            params,
        } = this.props;

        const currentBudgetItem = budgetItemsCurrent.find(({ id }) => id === params.acceptorId);
        const beforeBudgetItem = lodash.isEmpty(budgetItemsBefore)
            ? null
            : budgetItemsBefore.find(({ id }) => id === params.acceptorId);

        const budgetItem =
            status === CorrectionStatus.NeedApproving
                ? currentBudgetItem
                : lodash.isEmpty(beforeBudgetItem)
                ? currentBudgetItem
                : beforeBudgetItem;

        delete budgetItem.plannedFunds['__typename'];
        delete budgetItem.reservedFunds['__typename'];
        return budgetItem;
    }

    private formatUserName(user: Persone): string {
        return `${user.firstName} ${user.secondName}`;
    }

    private formatDate(date: string | Date): string {
        return moment(date).format('DD.MM.YY');
    }

    private getMonthName(month: Month): string {
        moment.locale('ru');
        const monthName = moment.months()[monthIndexMap[month]];

        return lodash.capitalize(monthName);
    }

    private makeUrl() {
        const {
            params: { acceptorId, donorId, acceptorMonth, donorMonth },
            budgetId,
        } = this.props;
        const acceptorMonthIndex = monthIndexMap[acceptorMonth];
        const DonorMonthIndex = monthIndexMap[donorMonth];

        const month = acceptorMonthIndex <= DonorMonthIndex ? acceptorMonthIndex : DonorMonthIndex;
        const column = PLANNED_COLUMN_NAMES[month];

        return `/budget/execution?filters=id:${acceptorId},${donorId}&column=${column}&budgetId=${budgetId}`;
    }

    private getDonorBudgetItemDonor() {
        return this.getBudgetItemDonors(
            this.props.budgetItemCurrent.find((budgetItem) => budgetItem.id === this.props.params.donorId),
        );
    }

    private getAcceptorBudgetItemDonor() {
        return this.getBudgetItemDonors(
            this.props.budgetItemCurrent.find((budgetItem) => budgetItem.id === this.props.params.acceptorId),
        );
    }

    // this.props.budgetItemBefore doesn't have donors, so wee nedd to calculate it based on a this.props.budgetItemCurrent;
    private getBudgetItemDonors(budgetItem: BudgetItem) {
        let budgetItemDonors = '';
        let budgetItemDonorsShortened = '';
        if (budgetItem.donors) {
            const budgetItemDonorsParts: number[][] = [];
            for (let i = 0; i < budgetItem.donors.length; i += 20) {
                budgetItemDonorsParts.push(budgetItem.donors.slice(i, i + 20));
            }

            budgetItemDonors = budgetItemDonorsParts.map((part) => part.join(', ')).join('\n');
            budgetItemDonorsShortened = `${budgetItem.donors.slice(0, 4).join(', ')}${
                budgetItem.donors.length > 4 ? '...' : ''
            }`;
        }

        return {
            donors: budgetItemDonors,
            donorsShorted: budgetItemDonorsShortened,
        };
    }
}
