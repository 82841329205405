import * as React from 'react';
import * as lodash from 'lodash';
import classNames from 'classnames';

import * as style from './AutopilotWidget.scss';

import { Autopilot2PlacementDeployStatus } from 'sber-marketing-types/backend';

import { Icon, IconType } from 'sber-marketing-ui';
import { DashboardUrlEditor } from './DashboardUrlEditor';
// import { PlacementStatusEditor } from './PlacementStatusEditor';

interface Props {
    autopilotIsLaunched: boolean;
    dashboardUrl: string;
    userHasAutopilotClientAccess: boolean;
    placementItems: Omit<PlacementItemProps, 'canEditStatus' | 'onStatusSelection'>[];
    onDashboardUrlChange: (value: string) => void;
    onStatusSelection: (name: string, value: Autopilot2PlacementDeployStatus) => void;
}

export const AutopilotWidgetTemplate = ({
    autopilotIsLaunched,
    dashboardUrl,
    userHasAutopilotClientAccess,
    placementItems,
    onDashboardUrlChange,
    onStatusSelection,
}: Props): JSX.Element => {
    const canEditDashboardUrl = userHasAutopilotClientAccess;
    const canEditPlacementStatus = userHasAutopilotClientAccess;

    return (
        <div className={style.root}>
            <div className={style.topLine}>
                {/* {finishedStagesCount < 5 &&
                    <div className={style.title}>
                        Этапы автопилота&nbsp;
                        <span className={style.titleCounter}>({finishedStagesCount}/5)</span>
                    </div>
                } */}

                {autopilotIsLaunched && (
                    <>
                        <div className={style.title}>
                            Digital & Performance
                            {(dashboardUrl || canEditDashboardUrl) && (
                                <>
                                    ,&nbsp;
                                    <a className={style.link} href={dashboardUrl}>
                                        посмотреть в дашборде
                                    </a>
                                </>
                            )}
                            {canEditDashboardUrl && (
                                <div className={style.urlEditor}>
                                    <DashboardUrlEditor value={dashboardUrl} onSave={onDashboardUrlChange} />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>

            {!lodash.isEmpty(placementItems) && (
                <div className={style.placementItems}>
                    {placementItems.map((item) => (
                        <PlacementItem
                            {...item}
                            canEditStatus={canEditPlacementStatus}
                            onStatusSelection={onStatusSelection}
                            key={item.code}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export interface PlacementItemProps {
    name: string;
    title: string;
    code: string;
    icon: IconType;
    status: Autopilot2PlacementDeployStatus;
    format: string;
    canEditStatus: boolean;
    onStatusSelection: (name: string, value: Autopilot2PlacementDeployStatus) => void;
}

function PlacementItem({ name, title, icon, status, format, canEditStatus, onStatusSelection }: PlacementItemProps) {
    const titleByDeployStatus = {
        ['MODERATION']: 'На модерации',
        ['MODERATION_REJECTED']: 'Отклонено модератором',
        ['DEPLOY_ERROR']: 'Ошибка размещения',
        ['ACTIVE']: 'Запущено',
        ['PAUSED']: 'Приостановлено',
        ['FINISHED']: 'Завершено',
    };

    return (
        <div className={style.placementItem}>
            {icon && (
                <div className={style.itemIcon}>
                    <Icon type={icon} svgSize={24} />
                </div>
            )}

            <div className={style.itemTitle}>
                {title} ({format})
            </div>

            {/* {!canEditStatus && */}
            <div
                className={classNames(
                    style.itemStatus,
                    ['ACTIVE', 'FINISHED'].includes(status) && style.running,
                    ['DEPLOY_ERROR', 'MODERATION_REJECTED'].includes(status) && style.stopped,
                    ['MODERATION', 'PAUSED'].includes(status) && style.moderation,
                )}
            >
                {titleByDeployStatus[status]}
            </div>
            {/* } */}

            {/* {(name === 'youtube' && canEditStatus) &&
                <div className={style.statusEditor}>
                    <PlacementStatusEditor
                        status={status}
                        onSelection={value => onStatusSelection(name, value)}
                    />
                </div>
            } */}
        </div>
    );
}
