import * as React from 'react';

import { ActivitySuggestWithStore } from './ActivitySuggestWithStore';
import { ActivitySuggestConnected } from './ActivitySuggestConnected';
import { ActivitySuggestContainer } from './ActivitySuggestContainer';

interface Props {
    budgetId: string;
    pageIsInCopyFromExecutionMode: boolean;
}

export function ActivitySuggest({ budgetId, pageIsInCopyFromExecutionMode }: Props): JSX.Element {
    return (
        <ActivitySuggestWithStore>
            {(containerProps) => (
                <ActivitySuggestConnected activityName={containerProps.nameInputValue} budgetId={budgetId}>
                    {(gqlProps) => (
                        <ActivitySuggestContainer
                            {...containerProps}
                            {...gqlProps}
                            pageIsInCopyFromExecutionMode={pageIsInCopyFromExecutionMode}
                        />
                    )}
                </ActivitySuggestConnected>
            )}
        </ActivitySuggestWithStore>
    );
}
