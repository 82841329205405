import * as React from 'react';
import { StaticSkeleton as Skeleton } from 'sber-marketing-ui';

import * as styles from './FilterMenuPreloader.scss';

export function FilterMenuPreloader(): JSX.Element {
    return (
        <React.Fragment>
            <div className={styles.firstFilterGroupWrapper}>
                <FirstFilter />
                <FirstFilter />
            </div>

            <div className={styles.filter}>
                <div className={styles.secondFilterTitle}>
                    <Skeleton loadingClassName={styles.secondFilterTitleFirstSkeleton} />
                    <Skeleton loadingClassName={styles.secondFilterTitleSecondSkeleton} />
                </div>

                <div className={styles.secondFilterContent}>
                    <Skeleton loadingClassName={styles.secondFilterContentFirstSkeleton} />
                    <Skeleton loadingClassName={styles.secondFilterContentSecondSkeleton} />
                    <Skeleton loadingClassName={styles.secondFilterContentThirdSkeleton} />
                    <Skeleton loadingClassName={styles.secondFilterContentFourthSkeleton} />
                    <Skeleton loadingClassName={styles.secondFilterContentFifthSkeleton} />
                    <Skeleton loadingClassName={styles.secondFilterContentSixthSkeleton} />
                    <Skeleton loadingClassName={styles.secondFilterContentSeventhSkeleton} />
                    <Skeleton loadingClassName={styles.secondFilterContentEighthSkeleton} />
                </div>
            </div>
        </React.Fragment>
    );
}

function FirstFilter(): JSX.Element {
    return (
        <div className={styles.firstFilter}>
            <Skeleton loadingClassName={styles.firstFilterFirstSkeleton} />
            <Skeleton loadingClassName={styles.firstFilterSecondSkeleton} />
            <Skeleton loadingClassName={styles.firstFilterThirdSkeleton} />
            <Skeleton loadingClassName={styles.firstFilterFourthSkeleton} />
        </div>
    );
}
