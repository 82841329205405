import * as React from 'react';

import * as style from './LongIdArrField.scss';

import { ILCreativeFieldSpec } from 'sber-marketing-types/backend';

import {} from 'sber-marketing-ui';

interface Props {
    field: ILCreativeFieldSpec;
}

export const LongIdArrField = ({}: Props): JSX.Element => {
    return <div className={style.root}>LongIdArrField</div>;
};
