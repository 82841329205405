import { useGetTaskQuery } from '@api';

import { useAuthUserId } from '@modules/auth/hooks';

export function useIsTaskParticipant(taskId: string) {
    const { isLoading: isAuthUserLoading, authUserId } = useAuthUserId();
    const { isLoading: isTaskLoading, data: { task } = {} } = useGetTaskQuery({ id: taskId });

    const isParticipant = task && authUserId && task?.participants?.map(({ userId }) => userId)?.includes(authUserId);

    return {
        isLoading: isAuthUserLoading || isTaskLoading,
        isParticipant,
    } as const;
}
