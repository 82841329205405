import { createSelector } from 'reselect';
import { UserConfigType } from 'sber-marketing-types/openid';

import { LoadingStatus } from '@store/commonTypes';
import { UserConfigState } from '@store/userConfig';
import { getUserConfigState } from '../selectors';

import { BudgetCorrectionsUserConfigState, BudgetCorrectionsUserConfig } from './types';

export const getBudgetCorrectionsUserConfigState = createSelector(
    getUserConfigState,
    (state: UserConfigState): BudgetCorrectionsUserConfigState => state[UserConfigType.BudgetCorrections],
);

export const getBudgetCorrectionsUserConfig = createSelector(
    getBudgetCorrectionsUserConfigState,
    (state: BudgetCorrectionsUserConfigState): BudgetCorrectionsUserConfig => state.fields,
);

export const getLoadingStatus = createSelector(
    getBudgetCorrectionsUserConfigState,
    (state: BudgetCorrectionsUserConfigState): LoadingStatus => state.status,
);
