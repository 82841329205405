import * as React from 'react';
import { Icon, IconType } from 'sber-marketing-ui';

import { FileAsset, FileAssetProps } from '@common/FileAssetRedesign';
import { Utils } from '@common/Utils';

import * as styles from './AttachedFile.scss';

interface Props extends FileAssetProps {
    originName: string;
    size: number;
    showDownloadButton: boolean;
    onMouseEnter(): void;
    onMouseLeave(): void;
    onDownloadButtonClick(): void;
}

export function AttachedFile({
    showDownloadButton,
    onMouseEnter,
    onMouseLeave,
    onDownloadButtonClick,
    ...props
}: Props): JSX.Element {
    return (
        <div
            className={styles.root}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            {...{
                'qa-id': 'searchCommentCardAttachedFile',
                'qa-name': props.originName,
                'qa-type': props.type,
            }}
        >
            <div className={styles.content}>
                <div className={styles.fileAsset}>
                    <FileAsset preventDownload {...props} />
                </div>

                <div className={styles.info}>
                    <div className={styles.title}>{props.originName}</div>

                    <div className={styles.size}>{Utils.formatFileSize(props.size)}</div>
                </div>
            </div>

            {showDownloadButton && (
                <div
                    {...{
                        'qa-id': 'searchCommentCardAttachedFileDownloadButton',
                    }}
                >
                    <Icon
                        type={IconType.DOWNLOAD_ICON_V2}
                        svgSize={16}
                        className={styles.downloadButton}
                        onClick={onDownloadButtonClick}
                    />
                </div>
            )}
        </div>
    );
}
