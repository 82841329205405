// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-EditPlannedBudgetPage-BudgetList-ActivitySuggest-_ActivitySuggest__root{position:relative}.src-client-modules-budget-EditPlannedBudgetPage-BudgetList-ActivitySuggest-_ActivitySuggest__existingNamesWrapper{width:100%;background:#ffffff}.src-client-modules-budget-EditPlannedBudgetPage-BudgetList-ActivitySuggest-_ActivitySuggest__stubWrapper{display:flex;border-bottom:1px solid #e3e9e5}.src-client-modules-budget-EditPlannedBudgetPage-BudgetList-ActivitySuggest-_ActivitySuggest__tooltip{position:absolute;z-index:200;top:0;left:calc(100% + 10px)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/EditPlannedBudgetPage/BudgetList/ActivitySuggest/ActivitySuggest.scss"],"names":[],"mappings":"AAAA,mGACI,iBAAkB,CACrB,mHAGG,UAAW,CAEX,kBAAmB,CACtB,0GAGG,YAAa,CAEb,+BAAgC,CACnC,sGAGG,iBAAkB,CAClB,WAAY,CACZ,KAAM,CACN,sBAAuB","sourcesContent":[".root {\n    position: relative;\n}\n\n.existingNamesWrapper {\n    width: 100%;\n\n    background: #ffffff;\n}\n\n.stubWrapper {\n    display: flex;\n\n    border-bottom: 1px solid #e3e9e5;\n}\n\n.tooltip {\n    position: absolute;\n    z-index: 200;\n    top: 0;\n    left: calc(100% + 10px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-EditPlannedBudgetPage-BudgetList-ActivitySuggest-_ActivitySuggest__root",
	"existingNamesWrapper": "src-client-modules-budget-EditPlannedBudgetPage-BudgetList-ActivitySuggest-_ActivitySuggest__existingNamesWrapper",
	"stubWrapper": "src-client-modules-budget-EditPlannedBudgetPage-BudgetList-ActivitySuggest-_ActivitySuggest__stubWrapper",
	"tooltip": "src-client-modules-budget-EditPlannedBudgetPage-BudgetList-ActivitySuggest-_ActivitySuggest__tooltip"
};
export default ___CSS_LOADER_EXPORT___;
