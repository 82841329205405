// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-ProfileMenu-_CommonStyles__paddingT{padding-top:12px}.src-client-modules-common-ProfileMenu-_CommonStyles__paddingRL{padding-left:16px;padding-right:16px}.src-client-modules-common-ProfileMenu-_CommonStyles__paddingB{padding-bottom:16px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/ProfileMenu/CommonStyles.scss"],"names":[],"mappings":"AAAA,+DACI,gBAAiB,CACpB,gEAGG,iBAAkB,CAClB,kBAAmB,CACtB,+DAGG,mBAAoB","sourcesContent":[".paddingT {\n    padding-top: 12px;\n}\n\n.paddingRL {\n    padding-left: 16px;\n    padding-right: 16px;\n}\n\n.paddingB {\n    padding-bottom: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paddingT": "src-client-modules-common-ProfileMenu-_CommonStyles__paddingT",
	"paddingRL": "src-client-modules-common-ProfileMenu-_CommonStyles__paddingRL",
	"paddingB": "src-client-modules-common-ProfileMenu-_CommonStyles__paddingB"
};
export default ___CSS_LOADER_EXPORT___;
