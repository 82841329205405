import { convertBudgetItemDomain } from './convertBudgetItemDomain';
import { CreativeRequestBudgetItemDomain, BudgetItem } from '../types';

interface Converter {
    (domains: CreativeRequestBudgetItemDomain[]): Promise<BudgetItem[]>;
}

export const convertBudgetItemDomains: Converter = async (budgetItemDomains) => {
    return await Promise.all(
        budgetItemDomains.map(async (budgetItemDomain) => await convertBudgetItemDomain(budgetItemDomain)),
    );
};
