import createCashedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { UserResponseParams } from 'sber-marketing-types/frontend';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';

import { UsersState, StoreTypes } from './types';

const getUsersState = (state: StoreState): UsersState => state.users;

export const getFetchersCount = createCashedSelector(
    getUsersState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: UsersState, store: StoreTypes): number => state.stores[store].fetchersCount,
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getUserById = createCashedSelector(
    getUsersState,
    (state: StoreState, id: number): number => id,
    (state: UsersState, id: number): UserResponseParams => state.byIds[id],
)((state: StoreState, id: number): number => id);

export const getAllUsers = createSelector(getUsersState, (state: UsersState): UserResponseParams[] => state.entities);

export const getUsers = createCashedSelector(
    getUsersState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: UsersState, store: StoreTypes): UserResponseParams[] => {
        const entitiesStore = state.stores[store];

        if (entitiesStore.loadingStatus === LoadingStatus.LOADED) {
            return state.stores[store].ids.map((id) => state.byIds[id]);
        }

        return [];
    },
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getUserIds = createCashedSelector(
    getUsersState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: UsersState, store: StoreTypes): number[] => state.stores[store].ids,
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getLoadingStatus = createCashedSelector(
    getUsersState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: UsersState, store: StoreTypes): LoadingStatus => state.stores[store].loadingStatus,
)((state: StoreState, store: StoreTypes): StoreTypes => store);
