import * as React from 'react';
import * as lodash from 'lodash';

import * as style from './Table.scss';

import { TableType } from '@store/creative/types';
import type {
    CellPosition,
    ColumnName,
    ColumnWidths,
    LineId,
    TableBodyCellParams,
    TableHeaderCellParams,
} from './types';

import { Icon, IconType, OpacityTransition, TableView, CellsStorage, CellEvent } from 'sber-marketing-ui';
import { TableSettings } from './TableSettings';
import { Preloader } from './Preloader';

interface Props {
    loading: boolean;
    headerCellsStorage: Record<TableType, CellsStorage<string, TableHeaderCellParams>>;
    tableCellsStorage: CellsStorage<CellPosition, TableBodyCellParams>;
    tableColumns: Record<TableType, Record<'left' | 'center' | 'right', ColumnName[]>>;
    fixedWidthColumns: ColumnName[];
    visibleColumns: Record<TableType, ColumnName[]>;
    tariffTablelines: LineId[];
    productionTablelines: LineId[];
    akTablelines: LineId[];
    columnWidths: ColumnWidths;
    tariffTableRef: (component: TableView) => void;
    productionTableRef: (component: TableView) => void;
    akTableRef: (component: TableView) => void;
    onAddButtonClick: (table: TableType) => void;
    onCellEvent: (eventType: CellEvent, position: CellPosition) => void;
    onVisibleColumnsChange: (table: TableType, visibleColumns: ColumnName[]) => void;
    onColumnWidthsChange: (columnWidths: ColumnWidths) => void;
}

export const TableTemplate = ({
    loading,
    headerCellsStorage,
    tableCellsStorage,
    tableColumns,
    fixedWidthColumns,
    visibleColumns,
    tariffTablelines,
    productionTablelines,
    akTablelines,
    columnWidths,
    tariffTableRef,
    productionTableRef,
    akTableRef,
    onAddButtonClick,
    onCellEvent,
    onVisibleColumnsChange,
    onColumnWidthsChange,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <OpacityTransition start={!loading}>
                <CreativeRequestGroupTable
                    title={'Тариф'}
                    headerCellsStorage={headerCellsStorage[TableType.Tariff]}
                    tableCellsStorage={tableCellsStorage}
                    tableColumns={tableColumns[TableType.Tariff]}
                    fixedWidthColumns={fixedWidthColumns}
                    visibleColumns={visibleColumns[TableType.Tariff]}
                    tablelines={tariffTablelines}
                    columnWidths={columnWidths}
                    customBackgroundColor={'#f1f5f7'}
                    tableRef={tariffTableRef}
                    onAddButtonClick={() => onAddButtonClick(TableType.Tariff)}
                    onCellEvent={onCellEvent}
                    onVisibleColumnsChange={(value) => onVisibleColumnsChange(TableType.Tariff, value)}
                    onColumnWidthsChange={onColumnWidthsChange}
                />

                <CreativeRequestGroupTable
                    title={'Производство'}
                    headerCellsStorage={headerCellsStorage[TableType.Production]}
                    tableCellsStorage={tableCellsStorage}
                    tableColumns={tableColumns[TableType.Production]}
                    fixedWidthColumns={fixedWidthColumns}
                    visibleColumns={visibleColumns[TableType.Production]}
                    tablelines={productionTablelines}
                    columnWidths={columnWidths}
                    customBackgroundColor={'#f1f5f7'}
                    tableRef={productionTableRef}
                    onAddButtonClick={() => onAddButtonClick(TableType.Production)}
                    onCellEvent={onCellEvent}
                    onVisibleColumnsChange={(value) => onVisibleColumnsChange(TableType.Production, value)}
                    onColumnWidthsChange={onColumnWidthsChange}
                />

                <CreativeRequestGroupTable
                    title={'АК'}
                    headerCellsStorage={headerCellsStorage[TableType.Ak]}
                    tableCellsStorage={tableCellsStorage}
                    tableColumns={tableColumns[TableType.Ak]}
                    fixedWidthColumns={fixedWidthColumns}
                    visibleColumns={visibleColumns[TableType.Ak]}
                    tablelines={akTablelines}
                    columnWidths={columnWidths}
                    customBackgroundColor={'#f1f5f7'}
                    tableRef={akTableRef}
                    onCellEvent={onCellEvent}
                    onVisibleColumnsChange={(value) => onVisibleColumnsChange(TableType.Ak, value)}
                    onColumnWidthsChange={onColumnWidthsChange}
                />
            </OpacityTransition>

            {loading && (
                <div className={style.preloader}>
                    <Preloader />
                </div>
            )}
        </div>
    );
};

interface CreativeRequestGroupTableProps {
    title: string;
    headerCellsStorage: CellsStorage<ColumnName, TableHeaderCellParams>;
    tableCellsStorage: CellsStorage<CellPosition, TableBodyCellParams>;
    tableColumns: Record<'left' | 'center' | 'right', ColumnName[]>;
    fixedWidthColumns: ColumnName[];
    visibleColumns: ColumnName[];
    tablelines: LineId[];
    columnWidths: ColumnWidths;
    customBackgroundColor?: string;
    tableRef: (component: TableView) => void;
    onAddButtonClick?: () => void;
    onCellEvent: (eventType: CellEvent, position: CellPosition) => void;
    onVisibleColumnsChange: (visibleColumns: ColumnName[]) => void;
    onColumnWidthsChange: (columnWidths: ColumnWidths) => void;
}

function CreativeRequestGroupTable({
    title,
    headerCellsStorage,
    tableCellsStorage,
    tableColumns,
    fixedWidthColumns,
    visibleColumns,
    tablelines,
    columnWidths,
    customBackgroundColor,
    tableRef,
    onAddButtonClick,
    onCellEvent,
    onVisibleColumnsChange,
    onColumnWidthsChange,
}: CreativeRequestGroupTableProps): JSX.Element {
    return (
        <>
            <div className={style.topLine}>
                <div className={style.title}>{title}</div>

                {onAddButtonClick && (
                    <div className={style.addButton} onClick={onAddButtonClick}>
                        <div className={style.addButtonIcon}>
                            <Icon type={IconType.PLUS_20} svgSize={18} />
                        </div>
                        Добавить новую строку
                    </div>
                )}

                <div className={style.tableSettings}>
                    <TableSettings
                        columns={tableColumns.center}
                        leftFixedColumns={tableColumns.left}
                        rightFixedColumns={tableColumns.right}
                        visibleColumns={visibleColumns}
                        onVisibleColumnsChange={onVisibleColumnsChange}
                    />
                </div>
            </div>

            <div className={style.table}>
                <TableView
                    ref={tableRef}
                    headerCellsStorage={headerCellsStorage}
                    tableCellsStorage={tableCellsStorage}
                    columns={
                        lodash.isEmpty(visibleColumns)
                            ? tableColumns.center
                            : lodash.intersection(tableColumns.center, visibleColumns)
                    }
                    leftFixedColumns={
                        lodash.isEmpty(visibleColumns)
                            ? tableColumns.left
                            : lodash.intersection(tableColumns.left, visibleColumns)
                    }
                    rightFixedColumns={
                        lodash.isEmpty(visibleColumns)
                            ? tableColumns.right
                            : lodash.intersection(tableColumns.right, visibleColumns)
                    }
                    fixedWidthColumns={fixedWidthColumns}
                    lines={tablelines}
                    columnWidths={columnWidths}
                    customBackgroundColor={customBackgroundColor}
                    onCellEvent={onCellEvent}
                    onColumnWidthsChange={onColumnWidthsChange}
                />
            </div>
        </>
    );
}
