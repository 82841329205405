import * as React from 'react';

import { CategorySelector } from './CategorySelector';
import { RangeSelector } from './RangeSelector';
import { UsersToggle } from './UsersToggle';

import * as styles from './styles.scss';

export const Header: React.FC = () => {
    return (
        <div className={styles.root}>
            <div className={styles.left}>
                <CategorySelector />
            </div>

            <div className={styles.center}>
                <RangeSelector />
            </div>

            <div className={styles.right}>
                <UsersToggle />
            </div>
        </div>
    );
};
