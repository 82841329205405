import { Columns, ColumnNames, MonthColumns } from '../../types';

import { FetchedData } from '../FetchedData';
import { EmptyRequiredFieldError } from '../validationErrors/EmptyRequiredFieldError';
import { AllMonthSumsAreEmpty } from '../validationErrors/AllMonthSumsAreEmptyError';

export class RequiredFieldsValidator {
    public static validate(data: FetchedData[]): FetchedData[] {
        data.forEach((dataItem) => this._validateItem(dataItem));

        return data;
    }

    private static _validateItem(data: FetchedData): void {
        const emptyRequiredFields: ColumnNames[] = [];

        Columns.forEach((column) => {
            const columnValue = data.getFieldByName(column.key);

            if (column.isRequired && !columnValue) {
                emptyRequiredFields.push(column.key);
            }
        });

        const allMonthSumsAreEmpty = MonthColumns.every((column) => !data.getFieldByName(column));

        if (emptyRequiredFields.length) {
            data.addValidationError(new EmptyRequiredFieldError(emptyRequiredFields));
        }

        if (allMonthSumsAreEmpty) {
            data.addValidationError(new AllMonthSumsAreEmpty());
        }
    }
}
