import * as React from 'react';
import autobind from 'autobind-decorator';
import { ControlButtons, Mode } from './ControlButtons';

interface Props {
    disableRejectButton: boolean;
    onApproveClick: () => void;
    onRejectClick: (comment: string) => void;
}

interface State {
    mode: Mode;
}

export class ControlButtonsContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            mode: Mode.Buttons,
        };
    }

    public render() {
        const { disableRejectButton, onApproveClick } = this.props;
        const { mode } = this.state;

        return React.createElement(ControlButtons, {
            mode,
            disableRejectButton,
            onApproveClick,
            onRejectClick: this.onRejectClick,
            onUnsetCommentInputFocused: this.onUnsetCommentInputFocused,
            onCommentInputCreate: this.onCommentInputCreate,
            onCommentInputCancel: this.onCommentInputCancel,
        });
    }

    @autobind
    private onCommentInputCancel(): void {
        this.setState({ mode: Mode.Buttons });
    }

    @autobind
    private onRejectClick(): void {
        this.setState({ mode: Mode.Comment });
    }

    @autobind
    private onUnsetCommentInputFocused(comment: string): void {
        if (!comment) {
            this.setState({ mode: Mode.Buttons });
        }
    }

    @autobind
    private onCommentInputCreate(comment: string): void {
        this.props.onRejectClick(comment);
    }
}
