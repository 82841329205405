import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';

import { PageState, PageData, Filters, Period } from './types';
import * as actions from './actions';

export const initialState: PageState = {
    pageData: {
        activityBudgets: [],
        budgetItems: [],
        dictionaries: {},
        responsibleUsers: [],
    },
    filters: {
        activityTypeIds: [],
        blockIds: [],
        productIds: [],
        responsibleUserIds: [],
    },
    period: Period.Year,
    expandedGroupsIds: [],
    expandedActivitiesIds: [],
    hoveredItemId: null,
};

export class Reducer {
    public static loadPageData(state: PageState, payload: PageData): PageState {
        return { ...state, pageData: { ...state.pageData, ...payload } };
    }

    public static resetPageState(): PageState {
        return lodash.cloneDeep(initialState);
    }

    public static setFilters(state: PageState, payload: Filters): PageState {
        return { ...state, filters: { ...state.filters, ...payload } };
    }

    public static setPeriod(state: PageState, payload: Period): PageState {
        return { ...state, period: payload };
    }

    public static setExpandedGroupsIds(state: PageState, payload: string[]): PageState {
        return { ...state, expandedGroupsIds: payload };
    }

    public static setExpandedActivitiesIds(state: PageState, payload: string[]): PageState {
        return { ...state, expandedActivitiesIds: payload };
    }

    public static setHoveredItemId(state: PageState, payload: React.ReactText): PageState {
        const hoveredItemIdChanged = state.hoveredItemId !== payload;

        return hoveredItemIdChanged ? { ...state, hoveredItemId: payload } : state;
    }
}

export const executionCalendarReducer = reducerWithInitialState(initialState)
    .case(actions.loadPageData, Reducer.loadPageData)
    .case(actions.resetPageState, Reducer.resetPageState)
    .case(actions.setFilters, Reducer.setFilters)
    .case(actions.setPeriod, Reducer.setPeriod)
    .case(actions.setExpandedGroupsIds, Reducer.setExpandedGroupsIds)
    .case(actions.setExpandedActivitiesIds, Reducer.setExpandedActivitiesIds)
    .case(actions.setHoveredItemId, Reducer.setHoveredItemId);
