import * as React from 'react';
import * as lodash from 'lodash';
import autobind from 'autobind-decorator';

import { ReservedFundsCorrection, BudgetItem, CorrectionStatus, Month } from '../../../types';
import { ReserveCorrectionCard } from './ReserveCorrectionCard';

export const orderOfMonth = [
    Month.Jan,
    Month.Feb,
    Month.Mar,
    Month.Apr,
    Month.May,
    Month.Jun,
    Month.Jul,
    Month.Aug,
    Month.Sept,
    Month.Oct,
    Month.Nov,
    Month.Dec,
];

interface Props {
    correction: ReservedFundsCorrection;
    onHover: (correctionId: string) => void;
    onChange: () => void;
}

export class ReserveCorrectionCardContainer extends React.Component<Props> {
    public render() {
        const { correction, onChange } = this.props;
        return React.createElement(ReserveCorrectionCard, {
            correction,
            amountDelta: this.getAmountDelta(),
            onCardMouseEnter: this.onCardMouseEnter,
            onCardMouseLeave: this.onCardMouseLeave,
            onChange,
        });
    }

    @autobind
    private onCardMouseEnter(): void {
        this.props.onHover(this.props.correction.id);
    }

    @autobind
    private onCardMouseLeave(): void {
        this.props.onHover(null);
    }

    private get budgetItem(): BudgetItem {
        const {
            status,
            budgetItemCurrent: budgetItemsCurrent,
            budgetItemBefore: budgetItemsBefore,
        } = this.props.correction;

        return status === CorrectionStatus.NeedApproving
            ? lodash.first(budgetItemsCurrent)
            : lodash.isEmpty(lodash.first(budgetItemsBefore))
            ? lodash.first(budgetItemsCurrent)
            : lodash.first(budgetItemsBefore);
    }

    private getAmountDelta(): number {
        const fields = this.calculateAmountBeforeAndAfter();

        const after = fields.reduce((acc, field) => acc + field.after, 0);
        const before = fields.reduce((acc, field) => acc + field.before, 0);

        return after - before;
    }

    private calculateAmountBeforeAndAfter() {
        const { params: changedMonthFonds } = this.props.correction;
        const reserveFunds = this.budgetItem.reservedFunds;

        return orderOfMonth.map((month) => {
            const currentFund = reserveFunds[month] || 0;

            return {
                before: currentFund,
                after: changedMonthFonds.hasOwnProperty(month) ? changedMonthFonds[month] : currentFund,
            };
        });
    }
}
