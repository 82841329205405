import * as React from 'react';
import * as moment from 'moment';
import { round } from 'lodash';

import { getDateFormatByPeriod, getTimeKeyByPeriod } from '@modules/leaderDashboard/LeaderTasksDashboard/common';

import { useRateByDepartments } from './useRateByDepartments';

import { FilterPeriod, StatisticsType, StatisticsTaskType } from './types';
import * as styles from './styles.scss';
import * as style from '@common/TaskCard/TaskCard.scss';

import { Icon, IconType, Preloader } from 'sber-marketing-ui';

interface Props {
    coordinate: {
        x: number;
        y: number;
    };
    data: {
        id: string;
        name: string;
        mainValue: number;
        additionalValue: number;
    };
    period: FilterPeriod;
    date: string;
    type: StatisticsType;
}

export const TooltipTemplate: React.FC<Props> = ({ coordinate, data, period, date, type }) => {
    const { mainRate, additionalRate, loading } = useRateByDepartments({
        date,
        period,
        type,
        departmentId: data.id,
    });

    const visibilityRate = type === StatisticsTaskType.CLOSED;

    return (
        <div
            className={styles.tooltip}
            style={{
                left: coordinate.x,
                top: coordinate.y,
            }}
        >
            <div className={styles.tooltipTitle}>{data.name}</div>
            <div className={styles.tooltipContent}>
                <div className={styles.item}>
                    <div className={styles.tooltipDate}>
                        {moment(date).format(getDateFormatByPeriod(period))}:
                        <span className={styles.tooltipDateValue}>{data.mainValue}</span>
                    </div>
                    {visibilityRate && !!mainRate && <Rate value={mainRate} />}
                </div>
                <div className={styles.item}>
                    <div className={styles.tooltipDate}>
                        {moment(date).subtract(1, getTimeKeyByPeriod(period)).format(getDateFormatByPeriod(period))}:
                        <span className={styles.tooltipDateValue}> {data.additionalValue}</span>
                    </div>
                    {visibilityRate && !!additionalRate && <Rate value={additionalRate} />}
                </div>
            </div>
            {loading && <Preloader />}
        </div>
    );
};

interface RateProps {
    value: number;
}

const Rate: React.FC<RateProps> = ({ value }) => {
    return (
        <div className={style.rate}>
            <div className={style.rateIcon}>
                <Icon type={IconType.STAR_ACTIVE} svgSize={12} />
            </div>
            <div className={style.rateValue}>{round(value, 1)}</div>
        </div>
    );
};
