import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconType, Skeleton } from 'sber-marketing-ui';

import { CommentSearchResult } from '@store/search';

import { DatesFormatter } from '@common/Utils';

import { Activity, Task, Author } from '../WithSkeleton';
import { CommentContent } from './CommentContent';

import * as styles from './CommentCard.scss';

interface Props extends CommentSearchResult {}

export function CommentCard(props: Props): JSX.Element {
    const { id, activity, task, createTime, authorId } = props;

    const createTimeFormatted = createTime ? DatesFormatter.ddMonthyy(createTime) : null;

    return (
        <div
            {...{
                'qa-id': 'searchCommentCard',
                'qa-comment-id': id,
            }}
        >
            <div className={styles.row}>
                <div className={styles.activity}>
                    <Activity {...activity} className={styles.skeleton} />
                </div>

                <div
                    className={classNames(styles.text, styles.date)}
                    {...{
                        'qa-id': 'searchCommentCardCreateTime',
                        'qa-value': createTimeFormatted,
                    }}
                >
                    <Skeleton isReady={createTime} className={styles.skeleton}>
                        <Icon svgSize={16} type={IconType.CALENDAR_ICON} className={styles.icon} />
                        {createTimeFormatted}
                    </Skeleton>
                </div>
            </div>

            <div className={styles.row}>
                <Task {...task} activityId={activity && activity.id} />

                <div className={styles.author}>
                    <Author authorId={authorId} />
                </div>
            </div>

            <CommentContent {...props} />
        </div>
    );
}
