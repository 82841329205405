// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-common-Transition-_Transition__root,.src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-common-Transition-_Transition__item{display:inline-block}.src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-common-Transition-_Transition__arrow{color:#7e8681}\n", "",{"version":3,"sources":["webpack://./src/client/modules/dashboard/RightSidebar/BudgetInfo/Corrections/templates/common/Transition/Transition.scss"],"names":[],"mappings":"AAAA,gOACI,oBAAqB,CACxB,iHAGG,aAAc","sourcesContent":[".root, .item {\n    display: inline-block;\n}\n\n.arrow {\n    color: #7e8681;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-common-Transition-_Transition__root",
	"item": "src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-common-Transition-_Transition__item",
	"arrow": "src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-common-Transition-_Transition__arrow"
};
export default ___CSS_LOADER_EXPORT___;
