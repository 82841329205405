import { Stage } from '@mrm/activity-stage';
import { ListStagesParams } from '@mrm/stage';

import { rtkApi } from './rtkApi';

export const { useGetStagesQuery } = rtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getStages: builder.query<Stage[], ListStagesParams>({
            query: ({ activityIds }) => ({
                url: '/graphql',
                method: 'POST',
                body: {
                    operationName: 'getActivityStages',
                    query: `query getActivityStages { activityStages:getProjects(filter:{ids:[${activityIds}]}){ nodes { id stages { id start end isDone isActive dictionary { id name templateId weight } } }} }`,
                },
            }),
            transformResponse: (res: any) => res.data.activityStages.nodes,
        }),
    }),
});
