import * as React from 'react';
import { CorrectionType } from '@mrm/budget';

import { Menu, Params, SerialNumber, Body } from '../common';
import { BudgetItemCorrectionCard as BudgetItemCorrectionCardTooltip } from '../CardTypes';
import { BudgetItemCorrection } from '../../../types';
import { User } from '../../withUsers';
import { Dictionary } from '../../withDictionary';

import * as style from './BudgetItemCorrectionCard.scss';

interface Props {
    correction: BudgetItemCorrection;
    users: User[];
    dictionaries: Dictionary[];
    changesQuantity: number;
    onCardMouseEnter: () => void;
    onCardMouseLeave: () => void;
    onChange: () => void;
}

export const BudgetItemCorrectionCard: React.FC<Props> = ({
    correction,
    users,
    dictionaries,
    changesQuantity,
    onCardMouseEnter,
    onCardMouseLeave,
    onChange,
}) => {
    return (
        <Body
            qaId="dashboardRightSidebarBudgetInfoBudgetCorrectionCard"
            qaCorrectionType={CorrectionType.BudgetItem}
            tooltipComponent={
                <BudgetItemCorrectionCardTooltip {...correction} dictionaries={dictionaries} users={users} />
            }
            onMouseEnter={onCardMouseEnter}
            onMouseLeave={onCardMouseLeave}
        >
            <div className={style.header}>
                <SerialNumber>{correction.serialNumber}</SerialNumber>
                <div className={style.title}>Корректировка параметров строки</div>
                <Menu
                    status={correction.status}
                    correctionId={correction.id}
                    correctionAuthorId={correction.author.id}
                    onChange={onChange}
                />
            </div>
            <Params
                data={[
                    {
                        name: 'Корректируемые параметры',
                        value: changesQuantity,
                    },
                ]}
            />
        </Body>
    );
};
