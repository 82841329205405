// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-CurrentRowSumInputs-MonthInput-_MonthInput__root{display:flex;justify-content:space-between}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-CurrentRowSumInputs-MonthInput-_MonthInput__column{width:calc(50%)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/InternalTransitionSumEntering/CurrentRowSumInputs/MonthInput/MonthInput.scss"],"names":[],"mappings":"AAAA,wJACI,YAAa,CACb,6BAA8B,CACjC,0JAGG,eAAgB","sourcesContent":[".root {\n    display: flex;\n    justify-content: space-between;\n}\n\n.column {\n    width: calc(50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-CurrentRowSumInputs-MonthInput-_MonthInput__root",
	"column": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-CurrentRowSumInputs-MonthInput-_MonthInput__column"
};
export default ___CSS_LOADER_EXPORT___;
