import { Month } from '@mrm/budget';

export type MonthIndexMap = {
    [P in Month]: number;
};

export const orderOfMonth = [
    Month.Jan,
    Month.Feb,
    Month.Mar,
    Month.Apr,
    Month.May,
    Month.Jun,
    Month.Jul,
    Month.Aug,
    Month.Sept,
    Month.Oct,
    Month.Nov,
    Month.Dec,
];

export const monthIndexMap: MonthIndexMap = {
    [Month.Jan]: 0,
    [Month.Feb]: 1,
    [Month.Mar]: 2,
    [Month.Apr]: 3,
    [Month.May]: 4,
    [Month.Jun]: 5,
    [Month.Jul]: 6,
    [Month.Aug]: 7,
    [Month.Sept]: 8,
    [Month.Oct]: 9,
    [Month.Nov]: 10,
    [Month.Dec]: 11,
};
