import * as React from 'react';

import * as style from './ImageArrField.scss';

import { ILCreativeFieldSpec } from 'sber-marketing-types/backend';

import {} from 'sber-marketing-ui';

interface Props {
    field: ILCreativeFieldSpec;
}

export const ImageArrField = ({}: Props): JSX.Element => {
    return <div className={style.root}>ImageArrField</div>;
};
