import autobind from 'autobind-decorator';

import type { TableHeaderCellParams, ColumnName, Line } from '../../types';
import { ColumnHeaderType, ColumnParams } from '../../ColumnsConfig';
import { TableType } from '@store/creative/types';

import * as HeaderCells from '../../ColumnHeaderTypes';

export const ColumnHeaderComponentsByType: Record<ColumnHeaderType, React.ClassType<any, any, any>> = {
    [ColumnHeaderType.Text]: HeaderCells.TextColumnHeader,
    [ColumnHeaderType.Filters]: HeaderCells.FiltersColumnHeader,
};

interface Props {
    getColumnsConfig: () => { [columnName: string]: ColumnParams };
    getAllLines: () => Line[];
    getLeftFixedColumns: () => Record<TableType, ColumnName[]>;
    onColumnFixClick: (table: TableType, columnName: ColumnName) => void;
}

export class ColumnHeaderFactory {
    private getColumnsConfig: () => { [columnName: string]: ColumnParams };
    private getAllLines: () => Line[];
    private getLeftFixedColumns: () => Record<TableType, ColumnName[]>;
    private onColumnFixClick: (table: TableType, columnName: ColumnName) => void;

    public constructor(props: Props) {
        this.getColumnsConfig = props.getColumnsConfig;
        this.getAllLines = props.getAllLines;
        this.getLeftFixedColumns = props.getLeftFixedColumns;
        this.onColumnFixClick = props.onColumnFixClick;
    }

    @autobind
    public async makeColumnHeaderParams(table: TableType, columnName: ColumnName): Promise<TableHeaderCellParams> {
        return {
            component: this.getColumnHeaderComponent(columnName),
            cellProps: await this.makeColumnHeaderProps(table, columnName),
        };
    }

    private getColumnHeaderComponent(columnName: ColumnName): React.ClassType<any, any, any> {
        const columnType = this.getColumnsConfig()[columnName].headerType;

        return ColumnHeaderComponentsByType[columnType];
    }

    private async makeColumnHeaderProps(table: TableType, columnName: ColumnName): Promise<any> {
        const headerType = this.getColumnsConfig()[columnName].headerType;

        let cellProps: any;

        switch (headerType) {
            case ColumnHeaderType.Text:
                cellProps = await this.makeTextColumnHeaderProps(columnName as ColumnName);
                break;

            case ColumnHeaderType.Filters:
                cellProps = await this.makeFiltersColumnHeaderProps(table, columnName as ColumnName);
                break;
        }

        return cellProps;
    }

    private async makeTextColumnHeaderProps(columnName: ColumnName): Promise<any> {
        return {
            title: this.getColumnsConfig()[columnName].title,
        };
    }

    private async makeFiltersColumnHeaderProps(table: TableType, columnName: ColumnName): Promise<any> {
        return {
            table,
            title: this.getColumnsConfig()[columnName].title,
            columnName,
            getFixedColumns: this.getLeftFixedColumns,
            onColumnFixClick: this.onColumnFixClick,
        };
    }
}
