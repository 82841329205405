import gql from 'graphql-tag';

export const GET_PROJECT_FILES_QUERY = gql`
    query withProjectFiles($projectId: Int!) {
        project: getProjects(filter: { id: $projectId }) {
            nodes {
                files {
                    category
                    file {
                        id
                        name
                        originName
                        type
                        size
                    }
                }
            }
        }
    }
`;
