import * as React from 'react';

import { Toggle, ToggleProps, InputValues } from '../../main';
import { FormField, useFormField } from '../Form';

export interface FormToggleProps<V extends InputValues> extends Omit<ToggleProps, keyof FormField>, FormField {}

export function FormToggle<V extends InputValues>(props: FormToggleProps<V>) {
    const { value, onChange, name, rest } = useFormField({
        ...props,
        value: props.checked,
        onChange: props.onToggle,
    });

    return <Toggle {...(rest as any)} checked={value} onToggle={onChange} name={name} />;
}
