import * as React from 'react';

import * as style from './TextCell.scss';

interface Props {
    title: string;
    customStyle?: any;
}

export const TextCellTemplate = ({ title, customStyle }: Props): JSX.Element => {
    return (
        <div className={style.root} style={{ ...customStyle }}>
            {title}
        </div>
    );
};
