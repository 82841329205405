// import * as lodash from 'lodash';

import type {
    AutopilotRadioMediaplanAvailableStation as AvailableStation,
    AutopilotRadioMediaplanSelectedStation as SelectedStation,
    AutopilotRadioMediaplanKeyValue,
} from 'sber-marketing-types/backend';

export const enum ColumnHeaderType {
    Text = 'text',
}

export const enum LineType {
    Line = 'line',
    Total = 'total',
}

export const enum ColumnName {
    StationName = 'stationName',
    Aqh = 'aqh',
    Reach = 'reach',
    Affinity = 'affinity',
    SplitPercents = 'splitPercents',
    SplitFunds = 'splitFunds',
    Actions = 'actions',
}

export const enum CellType {
    Text = 'text',
    Input = 'input',
    FundsInput = 'fundsInput',
    Actions = 'actions',
}

export interface AccessorParams {
    id: string;
    line: SelectedStation | AvailableStation;
    totalLine: AutopilotRadioMediaplanKeyValue[];
}

export type Accessor = (params: AccessorParams) => any;
export type TitleAccessor = (params: AccessorParams) => React.ReactText;
export type ValueSetter = (params: AccessorParams, value: any) => Promise<void>;
export type ItemsAccessor = (params: AccessorParams) => any[];
export type DescriptionAccessor = (params: AccessorParams) => string;
export type CustomStyleAccessor = (params: AccessorParams) => React.CSSProperties;

export interface ColumnParams {
    title: string | [string, string];
    headerType: ColumnHeaderType;
    type: Record<LineType, CellType>;
    defaultWidth: number;
    disableWidthChange?: boolean;
    readOnly?: Partial<Record<LineType, boolean>>;
    customStyle?: Partial<Record<LineType, CustomStyleAccessor>>;
    linkedColumns?: Partial<Record<LineType, ColumnName[]>>;
    getTitle?: Partial<Record<LineType, TitleAccessor>>;
    getValue: Record<LineType, Accessor>;
    setValue?: Partial<Record<LineType, ValueSetter>>;
    getItems?: Partial<Record<LineType, ItemsAccessor>>;
    getDescription?: Partial<Record<LineType, DescriptionAccessor>>;
}

export interface ColumnsConfigParams {}

export const MakeColumnsConfig: (columnsConfigParams: ColumnsConfigParams) => { [columnName: string]: ColumnParams } = (
    columnsConfigParams: ColumnsConfigParams,
) => ({
    [ColumnName.StationName]: {
        title: 'Станция',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: CellType.Text,
        },
        defaultWidth: 200,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        customStyle: {
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.line.name,
            [LineType.Total]: (params: AccessorParams) => 'Итого',
        },
    },
    [ColumnName.Aqh]: {
        title: 'AQH, %',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Input,
            [LineType.Total]: CellType.Input,
        },
        defaultWidth: 200,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        customStyle: {
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) =>
                roundNumber(params.line.metrics.find((item) => item.key === 'AQH%').value as number, 1),
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Reach]: {
        title: 'Reach Dly, %',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Input,
            [LineType.Total]: CellType.Input,
        },
        defaultWidth: 200,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        customStyle: {
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) =>
                roundNumber(params.line.metrics.find((item) => item.key === 'Reach% Dly').value as number, 2),
            [LineType.Total]: (params: AccessorParams) => null,
            // params.totalLine.find(item => item.key === 'Reach% Dly').value
        },
    },
    [ColumnName.Affinity]: {
        title: 'Index T/U Reach Dly',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: CellType.Text,
        },
        defaultWidth: 200,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        customStyle: {
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) =>
                Math.round(params.line.metrics.find((item) => item.key === 'Affinity').value as number),
            [LineType.Total]: (params: AccessorParams) => null,
            // params.totalLine.find(item => item.key === 'Affinity').value
        },
    },
    [ColumnName.SplitPercents]: {
        title: 'Split Budget, %',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Input,
            [LineType.Total]: CellType.Input,
        },
        defaultWidth: 200,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        customStyle: {
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) =>
                roundNumber(params.line.metrics.find((item) => item.key === 'Split Budget, %').value as number, 2),
            [LineType.Total]: (params: AccessorParams) => 100,
        },
    },
    [ColumnName.SplitFunds]: {
        title: 'Split Budget, ₽',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.FundsInput,
            [LineType.Total]: CellType.FundsInput,
        },
        defaultWidth: 200,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        customStyle: {
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) =>
                params.line.metrics.find((item) => item.key === 'Split Budget, ₽').value,
            [LineType.Total]: (params: AccessorParams) =>
                params.totalLine.find((item) => item.key === 'Split Budget, ₽').value,
        },
    },
    [ColumnName.Actions]: {
        title: 'Действие',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Actions,
            [LineType.Total]: null,
        },
        defaultWidth: 200,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        customStyle: {
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => null,
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
});

export const MakeSelectedRadiosTableColumns: (columnsConfigParams: ColumnsConfigParams) => string[] = () => [
    ColumnName.Aqh,
    ColumnName.Reach,
    ColumnName.Affinity,
    ColumnName.SplitPercents,
    ColumnName.SplitFunds,
];

export const MakeAvailableRadiosTableColumns: (columnsConfigParams: ColumnsConfigParams) => string[] = () => [
    ColumnName.Aqh,
    ColumnName.Reach,
    ColumnName.Affinity,
    ColumnName.Actions,
];

export const leftFixedColumns: ColumnName[] = [ColumnName.StationName];

export const selectedRadiosTableRightFixedColumns: ColumnName[] = [ColumnName.Actions];

export const availableRadiosTableRightFixedColumns: ColumnName[] = [];

function roundNumber(value: number, digitsAfterComma: number): string {
    if (value === undefined || value === null) {
        return null;
    }

    const formatedValue = value.toFixed(digitsAfterComma);

    const [decimalPart, fractionPart] = formatedValue.split('.');

    return `${decimalPart}${fractionPart ? `.${fractionPart}` : ''}`;
}
