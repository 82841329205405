import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type {
    AutopilotDOOHMediaplanBaseByRegion,
    AutopilotDOOHMediaplanBaseByRegionFormat,
    AutopilotDOOHMediaplanTotal,
} from 'sber-marketing-types/backend';
import { TableBodyCellParams, CellPosition, LineId } from '../../types';
import {
    Accessor,
    TitleAccessor,
    DescriptionAccessor,
    CustomStyleAccessor,
    AccessorParams,
    CellType,
    LineType,
    ColumnParams,
    ValueSetter,
} from '../../ColumnsConfig';

import { FundsInputCell, FundsInputCellEdit, InputCell, InputCellEdit, TextCell } from '../../CellTypes';

export const CellComponentsByColumnType: Record<
    CellType,
    {
        cell: React.ClassType<any, any, any>;
        editCell?: React.ClassType<any, any, any>;
    }
> = {
    [CellType.Text]: {
        cell: TextCell,
    },
    [CellType.Input]: {
        cell: InputCell,
        editCell: InputCellEdit,
    },
    [CellType.FundsInput]: {
        cell: FundsInputCell,
        editCell: FundsInputCellEdit,
    },
};

interface Props {
    getLine: (lineId: LineId) => any;
    getAllLines: () => {
        byRegion?: AutopilotDOOHMediaplanBaseByRegion;
        byRegionFormat?: AutopilotDOOHMediaplanBaseByRegionFormat;
        total?: AutopilotDOOHMediaplanTotal;
    }[];
    getColumnsConfig: () => { [columnName: string]: ColumnParams };
    onCellValueChange: () => void;
}

export class CellsFactory {
    private getLine: (lineId: LineId) => any;
    private getAllLines: () => any[];
    private getColumnsConfig: () => { [columnName: string]: ColumnParams };
    private onCellValueChange: () => void;

    public constructor(props: Props) {
        this.getLine = props.getLine;
        this.getAllLines = props.getAllLines;
        this.getColumnsConfig = props.getColumnsConfig;
        this.onCellValueChange = props.onCellValueChange;
    }

    @autobind
    public makeCellParams(cellPosition: CellPosition, edit?: boolean): TableBodyCellParams {
        const value = this.getCellValue(cellPosition);

        return {
            component: value !== undefined ? this.getCellComponent(cellPosition, edit) : null,
            cellProps: value !== undefined ? this.makeCellProps(cellPosition, edit) : null,
            readOnly: value !== undefined ? this.checkReadOnlyStatus(cellPosition, edit) : true,
            сellBackgroundColor: this.getCellBackgroundColor(cellPosition),
        };
    }

    @autobind
    public getCellComponent(cellPosition: CellPosition, edit: boolean): React.ClassType<any, any, any> {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const columnType = lodash.get(this.getColumnsConfig(), [columnName, 'type', lineType]);

        if (!columnType) {
            return null;
        }

        return edit ? CellComponentsByColumnType[columnType].editCell : CellComponentsByColumnType[columnType].cell;
    }

    @autobind
    public makeCellProps(cellPosition: CellPosition, edit: boolean): any {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const columnType = lodash.get(this.getColumnsConfig(), [columnName, 'type', lineType]);

        if (!columnType) {
            return null;
        }

        let cellProps: any;

        switch (columnType) {
            case CellType.Text:
                cellProps = this.makeTextCellProps(cellPosition);
                break;

            case CellType.Input:
                cellProps = this.makeInputCellProps(cellPosition, edit);
                break;

            case CellType.FundsInput:
                cellProps = this.makeMoneyInputCellProps(cellPosition, edit);
                break;
        }

        cellProps = this.applyCustomStyles(cellPosition, cellProps);

        return cellProps;
    }

    public getCellValue(cellPosition: CellPosition) {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const accessor: Accessor = lodash.get(this.getColumnsConfig(), [columnName, 'getValue', lineType]);

        if (!accessor) {
            return undefined;
        }

        const params = this.makeAccessorParams(cellPosition);

        return accessor(params);
    }

    @autobind
    public checkReadOnlyStatus(cellPosition: CellPosition, edit: boolean): boolean {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const cellComponent = this.getCellComponent(cellPosition, edit);

        if (!cellComponent) {
            return true;
        }

        return lodash.get(this.getColumnsConfig(), [columnName, 'readOnly', lineType]) || false;
    }

    private getCellBackgroundColor(cellPosition: CellPosition): string {
        const { lineId } = cellPosition;

        const lineType = this.getLineType(lineId);

        const colorByLineType = {
            [LineType.Line]: '#ffffff',
            [LineType.GroupTotal]: '#f1f1f1',
        };

        return colorByLineType[lineType];
    }

    private makeTextCellProps(cellPosition: CellPosition): any {
        return {
            title: this.getCellValue(cellPosition) || '—',
        };
    }

    private makeInputCellProps(cellPosition: CellPosition, edit: boolean): any {
        const value = this.getCellValue(cellPosition);

        return edit
            ? {
                  title: value,
                  placeholder: '',
                  onValueChange: this.makeValueChangeHandler(cellPosition),
              }
            : {
                  title: value || '—',
              };
    }

    private makeMoneyInputCellProps(cellPosition: CellPosition, edit: boolean): any {
        const value = this.getCellValue(cellPosition);
        const description = this.getCellDescription(cellPosition);

        const params: any = {
            title: this.formatCurrencyValue(this.roundNumber(value, 2)) || '—',
            description,
        };

        return params;
    }

    public getCellTitle(cellPosition: CellPosition) {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const accessor: TitleAccessor = lodash.get(this.getColumnsConfig(), [columnName, 'getTitle', lineType]);

        if (!accessor) {
            return undefined;
        }

        const params = this.makeAccessorParams(cellPosition);

        return accessor(params);
    }

    private getCellDescription(cellPosition: CellPosition): string {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const descriptionAccessor: DescriptionAccessor = lodash.get(this.getColumnsConfig(), [
            columnName,
            'getDescription',
            lineType,
        ]);

        if (!descriptionAccessor) {
            return null;
        }

        const params = this.makeAccessorParams(cellPosition);

        return descriptionAccessor(params);
    }

    private getLineType(lineId: LineId): LineType {
        let lineType: LineType = LineType.Line;

        if (lodash.first(lineId.split('|')) === 'groupTotal') {
            lineType = LineType.GroupTotal;
        }

        return lineType;
    }

    private makeAccessorParams(cellPosition: CellPosition): AccessorParams {
        const { lineId } = cellPosition;

        return {
            id: lineId,
            line: this.getLine(lineId),
            allLines: this.getAllLines(),
        };
    }

    private makeValueChangeHandler(cellPosition: CellPosition) {
        return async (value: any) => {
            const { lineId, columnName } = cellPosition;

            this.onCellValueChange();

            const lineType = this.getLineType(lineId);
            const params = this.makeAccessorParams(cellPosition);

            const valueSetter: ValueSetter = lodash.get(this.getColumnsConfig(), [columnName, 'setValue', lineType]);

            await valueSetter(params, value);
        };
    }

    private applyCustomStyles(cellPosition: CellPosition, cellProps: any): any {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        if (lineType === LineType.GroupTotal) {
            cellProps.customStyle = { ...cellProps.customStyle, backgroundColor: '#f1f1f1' };
        }

        const customStyleAccessor: CustomStyleAccessor = lodash.get(this.getColumnsConfig(), [
            columnName,
            'customStyle',
            lineType,
        ]);

        if (customStyleAccessor) {
            const params = this.makeAccessorParams(cellPosition);

            const customStyle = customStyleAccessor(params);

            cellProps = {
                ...cellProps,
                customStyle: { ...cellProps.customStyle, ...customStyle },
            };
        }

        return cellProps;
    }

    private roundNumber(value: number, digitsAfterComma: number): string {
        const roundedValue = Math.round(value * 100) / 100;
        const formatedValue = roundedValue.toFixed(digitsAfterComma);

        const [decimalPart, fractionPart] = formatedValue.split('.');

        return `${decimalPart}${fractionPart ? `.${fractionPart}` : ''}`;
    }

    private formatCurrencyValue(value: number | string): string {
        let [decimalPart, fractionPart] = value.toString().split(/[.,]/);

        let sign = '';

        if (lodash.first(decimalPart) === '-') {
            decimalPart = decimalPart.substring(1);
            sign = '-';
        }

        const splittedDecimal = decimalPart.split(/(?=(?:...)*$)/).join(' ');

        return `${sign}${splittedDecimal}${fractionPart ? `,${fractionPart}` : ''}`;
    }
}
