import { useGetTaskQuery } from '@api';
import type { ChanelObject } from 'sber-marketing-types/frontend';

export interface UseGetTaskChannel {
    channel?: ChanelObject;
    isLoading: boolean;
}

export function useGetTaskChannel(taskId: string, channelId: number, skip?: boolean): UseGetTaskChannel {
    const { data: { task } = {}, isLoading } = useGetTaskQuery({ id: taskId }, { skip });
    const channel = task?.chanels.find(({ id }) => id === channelId);

    return { channel, isLoading };
}
