// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-CorrectionBody-_CorrectionBody__root{position:relative;overflow:hidden;max-width:964px;margin-bottom:8px;padding-left:3px;border-radius:6px;background-color:#ffffff;box-shadow:0 1px 6px 0 rgba(0,0,0,0.1);font-family:'Open Sans'}.src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-CorrectionBody-_CorrectionBody__statusIndicator{position:absolute;top:0;bottom:0;left:0;width:3px;background-color:#f5a623}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetCorrections/CorrectionsList/CardTypes/common/CorrectionBody/CorrectionBody.scss"],"names":[],"mappings":"AAAA,8HACI,iBAAkB,CAElB,eAAgB,CAEhB,eAAgB,CAChB,iBAAkB,CAClB,gBAAiB,CAEjB,iBAAkB,CAClB,wBAAyB,CACzB,sCAA0C,CAE1C,uBAAwB,CAC3B,yIAGG,iBAAkB,CAClB,KAAM,CACN,QAAS,CACT,MAAO,CAEP,SAAU,CAEV,wBAAyB","sourcesContent":[".root {\n    position: relative;\n\n    overflow: hidden;\n\n    max-width: 964px;\n    margin-bottom: 8px;\n    padding-left: 3px;\n\n    border-radius: 6px;\n    background-color: #ffffff;\n    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);\n\n    font-family: 'Open Sans';\n}\n\n.statusIndicator {\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n\n    width: 3px;\n\n    background-color: #f5a623;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-CorrectionBody-_CorrectionBody__root",
	"statusIndicator": "src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-CorrectionBody-_CorrectionBody__statusIndicator"
};
export default ___CSS_LOADER_EXPORT___;
