import { Dictionary } from 'lodash';
import { ActivityCard } from 'sber-marketing-types/frontend';

import { LoadingStatus } from '@store/commonTypes';

export interface ActivitiesState {
    entities: ActivityCard[];
    byIds: Dictionary<ActivityCard>;
    stores: Record<StoreTypes, EntitiesStore>;
}

export interface EntitiesStore {
    fetchersCount: number;
    loadingStatus: LoadingStatus;
    ids: number[];
}

export const enum StoreTypes {}

export interface LoadActivitiesParams {
    store: StoreTypes;
    ids?: number[];
}

export interface SetStoreIdsParams {
    store: StoreTypes;
    ids: number[];
}

export interface SetLoadingStatusParams {
    store: StoreTypes;
    status: LoadingStatus;
}

export type LoadEntitiesPayload = ActivityCard[];
