import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Dispatch, bindActionCreators } from 'redux';
import { BudgetStatus } from '@mrm/budget';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { BudgetItemStatus } from '@mrm/budget';

import { DeleteLineModalState, LineMenu } from './LineMenu';
import { StoreState } from '@store';
import { getBudgetByStatusUserConfig } from '@store/userConfig/budget';
import { NotificationActionType, NotificationMessage, NotificationType } from '@store/common/types';
import { setNotification, clearAllNotifications } from '@store/common/actions';
import { getBudgetState } from '@store/budgetPage';
import {
    getBudgetPlanningPageState,
    setApproverMenuLineId,
    clearUnsavedChangesByLines,
    setPreloaderStatus,
    setErrorPopupStatus,
    showLineModal,
    ShowLineModalParams,
    getBudgetItemByLineId,
    userCanEditOrCreateBudgetData,
} from '@store/budgetPlanning';
import { TableLoader, TableValidator, TableSaver } from '../../../modules';

interface Props extends Partial<MapProps & DispatchProps & RouteComponentProps> {
    activityId: string;
    lineId: string;
    backgroundColor: string;
    lineIsHovered: boolean;
    lineStatus: BudgetItemStatus;
    canEdit: boolean;
    userIsSupervisor: boolean;
    canMoveToExpertApprovement: boolean;
    onInfoMouseEnter: (lineId: string) => void;
    onInfoMouseLeave: () => void;
}

interface MapProps {
    budgetId: string;
    dictionariesOrganizationId: string;
    approverStatus: BudgetItemStatus;
    approversMenuIsOpened: boolean;
    expertComment: string;
    lineHasChanges: boolean;
    userCanEditOrCreateBudgetData: boolean;
}

interface DispatchProps {
    setApproverMenuLineId: (lineId: string) => void;
    clearUnsavedChangesByLines: (lineIds: string[]) => void;
    setPreloaderStatus: (preloader: boolean) => void;
    setErrorPopupStatus: (status: boolean) => void;
    setNotification: (notification: NotificationMessage) => void;
    clearAllNotifications: () => void;
    showLineModal: (params: ShowLineModalParams) => void;
}

interface State {
    deleteLineModalState: DeleteLineModalState;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class LineMenuContainer extends React.PureComponent<Props, State> {
    private tableLoader: TableLoader;
    private tableValidator: TableValidator;
    private tableSaver: TableSaver;

    constructor(props: Props) {
        super(props);

        this.tableLoader = TableLoader.getInstance();
        this.tableValidator = TableValidator.getInstance();
        this.tableSaver = TableSaver.getInstance();

        this.state = {
            deleteLineModalState: DeleteLineModalState.Hidden,
        };
    }

    public render(): JSX.Element {
        return React.createElement(LineMenu, {
            lineId: this.props.lineId,
            backgroundColor: this.props.backgroundColor,
            approversMenuIsOpened: this.props.approversMenuIsOpened,
            lineIsHovered: this.props.lineIsHovered,
            lineStatus: this.props.lineStatus,
            expertComment: this.props.expertComment,
            enableControls: this.props.canEdit,
            lineHasChanges: this.props.lineHasChanges,
            userIsSupervisor: this.props.userIsSupervisor,
            canMoveToExpertApprovement: this.props.canMoveToExpertApprovement,
            userCanEditOrCreateBudgetData: this.props.userCanEditOrCreateBudgetData,
            deleteLineModalState: this.state.deleteLineModalState,
            closeDeleteLineModal: this.closeDeleteLineModal,
            initLineDeletion: this.initLineDeletion,
            initLineDisable: this.initLineDisable,
            onSaveButtonClick: this.onSaveButtonClick,
            onRestoreButtonClick: this.onRestoreButtonClick,
            onDeleteButtonClick: this.onDeleteButtonClick,
            onApproversButtonClick: this.onApproversButtonClick,
            onDisableButtonClick: this.onDisableButtonClick,
            onInfoClick: this.onInfoClick,
            onInfoMouseEnter: this.onInfoMouseEnter,
            onInfoMouseLeave: this.onInfoMouseLeave,
            onCopyLineButtonClick: this.onCopyLineButtonClick,
        });
    }

    @autobind
    protected async onSaveButtonClick() {
        const { lineId } = this.props;

        const isValid = this.tableValidator.checkLineValidation(lineId);

        if (isValid) {
            this.props.setErrorPopupStatus(false);
            this.props.setPreloaderStatus(true);
            this.props.clearAllNotifications();

            const lineIsActual = await this.tableValidator.checkLineDataRelevance(lineId);

            if (lineIsActual) {
                await this.tableSaver.saveLine(lineId);

                if (this.props.lineStatus == BudgetItemStatus.Draft) {
                    await this.tableSaver.publishLine(lineId);
                }

                if (this.props.lineStatus == BudgetItemStatus.OnExpertApprovement) {
                    await this.tableSaver.applyLineApprovement(lineId);
                }

                if (
                    this.props.approverStatus == BudgetItemStatus.Approved ||
                    this.props.approverStatus == BudgetItemStatus.Rejected
                ) {
                    await this.tableSaver.applyApproverStatus(lineId);
                }

                await this.tableLoader.updateActivitiesByLineIds([lineId]);

                this.notifyAboutLinePublish();
                this.props.clearUnsavedChangesByLines([lineId]);
            } else {
                this.notifyLineDataRelevanceError();
            }

            this.props.setPreloaderStatus(false);
        } else {
            this.props.setErrorPopupStatus(true);
            this.notifyAboutValidationError();
        }

        this.tableValidator.updateValidationDisplay();
    }

    @autobind
    protected onApproversButtonClick() {
        this.props.setApproverMenuLineId(this.props.lineId);
    }

    @autobind
    protected async onRestoreButtonClick() {
        const { lineId } = this.props;

        this.props.setPreloaderStatus(true);

        const lineIsActual = await this.tableValidator.checkLineDataRelevance(lineId);
        if (lineIsActual) {
            await this.tableSaver.restoreLine(lineId);
            await this.tableLoader.updateActivitiesByLineIds([lineId]);
            this.notifyAboutLineRestoration();
        } else {
            this.notifyLineDataRelevanceError();
        }

        this.props.setPreloaderStatus(false);
    }

    @autobind
    protected closeDeleteLineModal() {
        this.setState({
            deleteLineModalState: DeleteLineModalState.Hidden,
        });
    }

    @autobind
    protected onDeleteButtonClick() {
        this.setState({
            deleteLineModalState: DeleteLineModalState.DeleteLine,
        });
    }

    @autobind
    protected async initLineDeletion() {
        this.setState(
            {
                deleteLineModalState: DeleteLineModalState.Hidden,
            },
            async () => {
                const { lineId } = this.props;

                this.props.setPreloaderStatus(true);

                const lineIsActual = await this.tableValidator.checkLineDataRelevance(lineId);
                if (lineIsActual) {
                    this.props.clearUnsavedChangesByLines([lineId]);
                    await this.tableSaver.deleteLine(lineId);
                    await this.tableLoader.deleteLines([lineId]);
                    this.notifyAboutLineDeletion();
                } else {
                    this.notifyLineDataRelevanceError();
                }

                this.props.setPreloaderStatus(false);
            },
        );
    }

    @autobind
    protected onDisableButtonClick() {
        this.setState({
            deleteLineModalState: DeleteLineModalState.DisableLine,
        });
    }

    @autobind
    protected async initLineDisable() {
        this.setState(
            {
                deleteLineModalState: DeleteLineModalState.Hidden,
            },
            async () => {
                const { lineId } = this.props;

                this.props.setPreloaderStatus(true);

                const lineIsActual = await this.tableValidator.checkLineDataRelevance(lineId);
                if (lineIsActual) {
                    this.props.clearUnsavedChangesByLines([lineId]);
                    await this.tableSaver.disableLine(lineId);
                    await this.tableLoader.updateActivitiesByLineIds([lineId]);
                    this.notifyAboutLineDeletion();
                } else {
                    this.notifyLineDataRelevanceError();
                }

                this.props.setPreloaderStatus(false);
            },
        );
    }

    @autobind
    private onInfoClick(): void {
        const { lineId } = this.props;
        this.props.showLineModal({ lineId });
    }

    @autobind
    private onInfoMouseEnter(): void {
        this.props.onInfoMouseEnter(this.props.lineId);
    }

    @autobind
    private onInfoMouseLeave(): void {
        this.props.onInfoMouseLeave();
    }

    @autobind
    protected onCopyLineButtonClick() {
        const { budgetId, lineId, dictionariesOrganizationId } = this.props;
        this.props.history.push(
            `/budget/planning/${this.props.activityId}/copy?budgetItemIds=${lineId}&budgetId=${budgetId}&dictionariesOrganizationId=${dictionariesOrganizationId}`,
        );
    }

    private notifyLineDataRelevanceError() {
        this.props.setNotification({
            type: NotificationType.ERROR,
            typeAction: NotificationActionType.BUDGET_PLANNING_LINE_UPDATE,
            comment: 'В редактируемую строку были внесены изменения. Обновите страницу.',
        });
    }

    private notifyAboutValidationError(): void {
        this.props.setNotification({
            type: NotificationType.ERROR,
            typeAction: NotificationActionType.BUDGET_PLANNING_LINE_UPDATE,
            comment:
                'Нельзя опубликовать активность или строку с незаполненным обязательным полем, ' +
                'и название активности не должно быть меньше трех символов. Введите значение и опубликуйте еще раз.',
        });
    }

    private notifyAboutLinePublish(): void {
        this.props.setNotification({
            type: NotificationType.SUCCESS,
            typeAction: NotificationActionType.BUDGET_PLANNING_LINE_UPDATE,
            comment: 'Строка опубликована',
        });
    }

    private notifyAboutLineDeletion(): void {
        this.props.setNotification({
            type: NotificationType.SUCCESS,
            typeAction: NotificationActionType.BUDGET_PLANNING_LINE_UPDATE,
            comment: 'Строка удалена',
        });
    }

    private notifyAboutLineRestoration(): void {
        this.props.setNotification({
            type: NotificationType.SUCCESS,
            typeAction: NotificationActionType.BUDGET_PLANNING_LINE_UPDATE,
            comment: 'Строка восстановлена',
        });
    }
}

function mapStateToProps(state: StoreState, props: Props): MapProps {
    const { budgetId } = getBudgetByStatusUserConfig(state, BudgetStatus.Plan);
    const { budgets } = getBudgetState(state, BudgetStatus.Plan);
    const { lineId } = props;
    const { approversMenuLineId, lineStatusChanges, approverStatusChanges, unsavedChanges } =
        getBudgetPlanningPageState(state);
    const budgetItem = getBudgetItemByLineId(state, lineId);

    const approverStatus = approverStatusChanges[lineId] ? approverStatusChanges[lineId].status : null;

    const approversMenuIsOpened = approversMenuLineId == props.lineId;
    const lineHasChanges =
        !lodash.isEmpty(unsavedChanges[lineId]) || !!lineStatusChanges[lineId] || !!approverStatusChanges[lineId];
    const selectedBudget = budgets.find((budget) => budget.id === budgetId);

    return {
        budgetId,
        approverStatus,
        approversMenuIsOpened,
        expertComment: budgetItem ? budgetItem.expertComment : null,
        lineHasChanges,
        dictionariesOrganizationId: selectedBudget ? selectedBudget.dictionaryOrganizationId : null,
        userCanEditOrCreateBudgetData: userCanEditOrCreateBudgetData(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setApproverMenuLineId,
            clearUnsavedChangesByLines,
            setPreloaderStatus,
            setErrorPopupStatus,
            setNotification,
            clearAllNotifications,
            showLineModal,
        },
        dispatch,
    );
}
