// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-dashboard-RightSidebar-ActivityWidget-LinkedBudgetItems-_LinkedBudgetItems__root{font-family:\"Open Sans\"}.src-client-modules-dashboard-RightSidebar-ActivityWidget-LinkedBudgetItems-_LinkedBudgetItems__budgetItemsIdsList{margin:8px -8px -7px 0}.src-client-modules-dashboard-RightSidebar-ActivityWidget-LinkedBudgetItems-_LinkedBudgetItems__noItemsMessage{margin-top:3px;letter-spacing:0.15px;color:#000000;font-size:12px;line-height:16px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/dashboard/RightSidebar/ActivityWidget/LinkedBudgetItems/LinkedBudgetItems.scss"],"names":[],"mappings":"AAAA,qGACI,uBAAwB,CAC3B,mHAGG,sBAAuB,CAC1B,+GAGG,cAAe,CAEf,qBAAsB,CAEtB,aAAc,CAEd,cAAe,CACf,gBAAiB","sourcesContent":[".root {\n    font-family: \"Open Sans\";\n}\n\n.budgetItemsIdsList {\n    margin: 8px -8px -7px 0;\n}\n\n.noItemsMessage {\n    margin-top: 3px;\n\n    letter-spacing: 0.15px;\n\n    color: #000000;\n\n    font-size: 12px;\n    line-height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-dashboard-RightSidebar-ActivityWidget-LinkedBudgetItems-_LinkedBudgetItems__root",
	"budgetItemsIdsList": "src-client-modules-dashboard-RightSidebar-ActivityWidget-LinkedBudgetItems-_LinkedBudgetItems__budgetItemsIdsList",
	"noItemsMessage": "src-client-modules-dashboard-RightSidebar-ActivityWidget-LinkedBudgetItems-_LinkedBudgetItems__noItemsMessage"
};
export default ___CSS_LOADER_EXPORT___;
