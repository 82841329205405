import { OrganizationType, WorkTypeParams } from 'sber-marketing-types/frontend';

import { axios } from '../lib/axios';

/** Work type */
export interface WorkType {
    /** Identifier UUID v4 */
    id: string;
    /** Name */
    name: string;
    /** Organization identifier */
    organizationId: string;
    /** Archived flag */
    isArchived: boolean;
    /** Departments identifiers */
    departmentIds: string[];
}

const WORK_TYPE_URL = '/api/work-type';

export class WorkTypeApi {
    public static async getList(queryParams?: OrganizationType.QueryParams): Promise<WorkTypeParams[]> {
        const res = await axios.get(`${WORK_TYPE_URL}/list`, { params: { ...queryParams } });

        return res.data;
    }
}
