import * as React from 'react';
import { WithTooltip } from 'sber-marketing-ui';

import { Utils } from '@common/Utils';
import { Icon, IconType } from 'sber-marketing-ui';

import * as style from './FavoriteToggle.scss';

interface Props {
    selected: boolean;
    onClick: (event?: React.MouseEvent<HTMLDivElement>) => void;
}

export const FavoriteToggle: React.FC<Props> = ({ selected, onClick }) => (
    <WithTooltip content={selected ? `Убрать из "Избранных"` : `Добавить в "Избранные"`}>
        <div
            className={style.root}
            style={Utils.isTouchScreen() || selected ? { transform: 'scale(1)', opacity: 1 } : {}}
            onClick={onClick}
            {...{
                'qa-id': 'favoriteToggle',
                'qa-is-selected': selected ? 'true' : 'false',
            }}
        >
            <div className={style.icon_normal} style={selected ? { opacity: 0 } : {}}>
                <Icon type={IconType.STAR_NORMAL} svgSize={16} color={'#ffffff'} />
            </div>

            <div className={style.icon_hover} style={selected ? { opacity: 0 } : {}}>
                <Icon type={IconType.STAR_HOVER} svgSize={16} color={'#ffffff'} />
            </div>

            <div className={style.icon_select} style={selected ? { opacity: 1 } : {}}>
                <Icon type={IconType.STAR_ACTIVE} svgSize={16} color={'#F5A623'} />
            </div>
        </div>
    </WithTooltip>
);
