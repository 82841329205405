import * as React from 'react';
import * as lodash from 'lodash';
import { Skeleton } from 'sber-marketing-ui';

import * as style from './ActivityWidget.scss';

import { Activity } from '../types';

import { ActivityName } from './ActivityName';
import { BriefName } from './BriefName';
import { LinkedBudgetItems } from './LinkedBudgetItems';

interface Props {
    loading: boolean;
    activity: Activity;
    requestActivityName: string;
}

export const ActivityWidget = ({ loading, activity, requestActivityName }: Props): JSX.Element => {
    const activityId = activity ? Number(activity.id) : null;

    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'dashboardRightSidebarActivityWidget',
            }}
        >
            {!loading && (
                <>
                    <div className={style.title}>Проект</div>

                    <div className={style.item}>
                        <ActivityName activityId={activityId} title={requestActivityName} />
                    </div>

                    <div className={style.item}>
                        <BriefName activityId={activityId} title={lodash.get(activity, 'brief.schema.name') || null} />
                    </div>

                    <div className={style.item}>
                        <LinkedBudgetItems activityId={activityId} />
                    </div>
                </>
            )}

            {loading && <ActivityPreloader />}
        </div>
    );
};

function ActivityPreloader(): JSX.Element {
    return (
        <>
            <Skeleton customStyle={{ height: 20, width: '12%', borderRadius: 2, marginBottom: 10 }} />

            <Skeleton customStyle={{ height: 16, width: '14%', borderRadius: 2, marginBottom: 4 }} />
            <Skeleton customStyle={{ height: 13, width: '84%', borderRadius: 2, marginBottom: 16 }} />

            <Skeleton customStyle={{ height: 16, width: '10%', borderRadius: 2, marginBottom: 4 }} />
            <Skeleton customStyle={{ height: 13, width: '40%', borderRadius: 2, marginBottom: 16 }} />

            <Skeleton customStyle={{ height: 16, width: '16%', borderRadius: 2, marginBottom: 4 }} />
            <Skeleton customStyle={{ height: 13, width: '24%', borderRadius: 2, marginBottom: 16 }} />

            <Skeleton customStyle={{ height: 16, width: '47%', borderRadius: 2, marginBottom: 4 }} />
            <Skeleton customStyle={{ height: 24, width: '25%', borderRadius: 2, marginBottom: 16 }} />
        </>
    );
}
