import * as React from 'react';
import { sortBy } from 'lodash';

import * as style from './styles.scss';

import { Icon, IconType } from 'sber-marketing-ui';
import { ParamForm } from './ParamForm';

import { CreativeRequestParamDomain, Dictionary } from './types';

interface Props {
    creativeRequestParamDomains: CreativeRequestParamDomain[];
    dictionaries: Dictionary[];
    loading: boolean;
    version: number;
    displayAddButton: boolean;
    onAddButtonClick: () => Promise<void>;
}

export const ParamListTemplate: React.FC<Props> = ({
    creativeRequestParamDomains,
    dictionaries,
    loading,
    version,
    displayAddButton,
    onAddButtonClick,
}) => {
    const sortedCreativeRequestParamDomains = sortBy(creativeRequestParamDomains, 'model.createdAt');

    return (
        <div className={style.root}>
            <div className={style.title}>Параметры заявки на креатив</div>

            <div className={style.list}>
                {!loading &&
                    sortedCreativeRequestParamDomains.map((creativeRequestParamDomain) => (
                        <ParamForm
                            key={`${creativeRequestParamDomain.model.id}-${version}`}
                            creativeRequestParamDomain={creativeRequestParamDomain}
                            dictionaries={dictionaries}
                            visibilityRemoveButton={sortedCreativeRequestParamDomains.length > 1}
                        />
                    ))}
            </div>

            {displayAddButton && (
                <div className={style.addSetButton} onClick={onAddButtonClick}>
                    <div className={style.addSetButtonIcon}>
                        <Icon type={IconType.PLUS_20} svgSize={20} />
                    </div>
                    Добавить параметры
                </div>
            )}
        </div>
    );
};
