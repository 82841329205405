import * as React from 'react';
import * as moment from 'moment';

import * as style from './ActivityDates.scss';

import { InputType, LabeledInput } from 'sber-marketing-ui';

interface Props {
    realizationStart: moment.Moment;
    realizationEnd: moment.Moment;
    preparationDate: moment.Moment;
    debriefingDate: moment.Moment;
    editRight: boolean;
    onRealizationStartChange: (value: moment.Moment) => void;
    onRealizationEndChange: (value: moment.Moment) => void;
    onPreparationDateChange: (value: moment.Moment) => void;
    onDebriefingDateChange: (value: moment.Moment) => void;
}

export const ActivityDates: React.FC<Props> = ({
    realizationStart,
    realizationEnd,
    preparationDate,
    debriefingDate,
    editRight,
    onRealizationStartChange,
    onRealizationEndChange,
    onPreparationDateChange,
    onDebriefingDateChange,
}) => {
    return (
        <div className={style.root}>
            <div className={style.block}>
                <div className={style.date}>
                    <LabeledInput
                        qaId="briefActivityPreparationDatePicker"
                        value={preparationDate}
                        title={'Старт подготовки'}
                        maxDate={realizationStart}
                        errorMessage={'Обязательное поле'}
                        displayError={!preparationDate}
                        type={InputType.DatePicker}
                        onValueChange={onPreparationDateChange}
                        disabled={!editRight}
                    />
                </div>
                <div className={style.date}>
                    <LabeledInput
                        qaId="briefActivityRealizationStartDatePicker"
                        value={realizationStart}
                        title={'Старт проекта'}
                        minDate={preparationDate}
                        maxDate={realizationEnd}
                        errorMessage={'Обязательное поле'}
                        displayError={!realizationStart}
                        type={InputType.DatePicker}
                        onValueChange={onRealizationStartChange}
                        disabled={!editRight}
                    />
                </div>
            </div>

            <div className={style.block}>
                <div className={style.date}>
                    <LabeledInput
                        qaId="briefActivityRealizationEndDatePicker"
                        value={realizationEnd}
                        title={'Окончание проекта'}
                        minDate={realizationStart}
                        maxDate={debriefingDate}
                        errorMessage={'Обязательное поле'}
                        displayError={!realizationEnd}
                        type={InputType.DatePicker}
                        onValueChange={onRealizationEndChange}
                        disabled={!editRight}
                    />
                </div>
                <div className={style.date}>
                    <LabeledInput
                        qaId="briefActivityDebriefingDatePicker"
                        value={debriefingDate}
                        title={'Подведение итогов'}
                        minDate={realizationEnd}
                        errorMessage={'Обязательное поле'}
                        displayError={!debriefingDate}
                        type={InputType.DatePicker}
                        onValueChange={onDebriefingDateChange}
                        disabled={!editRight}
                    />
                </div>
            </div>
        </div>
    );
};
