import { createSelector } from 'reselect';
import { identity, flatten, uniqBy, property } from 'lodash';

import { StoreState } from '../../';
import { getTaskPageState } from './lib';
import { getChannelById } from './channelsSelectors';
import { getFileAssetByName, getFileAssetsByNames, getFileAssetsByTargetId } from '../../fileAssets';
import { getCommentaryById } from './commentarySelectors';
import { getTaskInfo } from './getTaskInfo';

export const getAssetById = getFileAssetByName;

export const getAssetsByIds = getFileAssetsByNames;

export const getCommentaryAssetsIds = createSelector(getCommentaryById, (comment) => comment?.assetsIds || []);

const getCommentaryAssetsBySavedIds = createSelector([identity, getCommentaryAssetsIds], getAssetsByIds);

export const getCommentaryAssets = createSelector(
    getFileAssetsByTargetId,
    getCommentaryAssetsBySavedIds,
    (byId, saved) => uniqBy([...saved, ...byId], 'name'),
);

export const getChannelAssets = createSelector([identity, getChannelById], (state, channel) =>
    channel ? uniqBy(flatten(channel.commentsIds.map((id) => getCommentaryAssets(state, id))), 'name') : [],
);

const getTaskAssetsIds = createSelector(getTaskPageState, ({ assetsIds }) => assetsIds);

const getTaskAssetsBySavedIds = createSelector([identity, getTaskAssetsIds], getAssetsByIds);

const getTaskId = createSelector(getTaskInfo, property<any, string>('id'));

const getTaskAssetsByTaskId = createSelector([identity, getTaskId], getFileAssetsByTargetId);

export const getTaskAssets = createSelector(getTaskAssetsBySavedIds, getTaskAssetsByTaskId, (saved, byId) =>
    uniqBy([...saved, ...byId], 'name'),
);

export const getBaseAssets = createSelector(identity, (state: StoreState) => [
    ...getTaskAssets(state),
    ...getChannelAssets(state, null),
]);
