// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-DAMFilesArchiveWidget-ArchiveContentPopup-TaskFiles-TitleWithCounter-_TitleWithCounter__root{max-width:90%;margin-bottom:4px;display:flex;align-items:center;gap:0 6px;font-family:'Open Sans';font-weight:600;font-size:13px;line-height:18px;color:#000000}.src-client-modules-common-DAMFilesArchiveWidget-ArchiveContentPopup-TaskFiles-TitleWithCounter-_TitleWithCounter__counter{padding:1px 8px;background:#19BB4F;border-radius:8px;font-family:'Open Sans';font-weight:600;font-size:12px;line-height:16px;letter-spacing:0.22px;color:#FFFFFF}.src-client-modules-common-DAMFilesArchiveWidget-ArchiveContentPopup-TaskFiles-TitleWithCounter-_TitleWithCounter__overflow{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/DAMFilesArchiveWidget/ArchiveContentPopup/TaskFiles/TitleWithCounter/TitleWithCounter.scss"],"names":[],"mappings":"AAAA,wHACI,aAAc,CAEd,iBAAkB,CAElB,YAAa,CACb,kBAAmB,CACnB,SAAU,CAEV,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,aAAc,CACjB,2HAGG,eAAgB,CAEhB,kBAAmB,CACnB,iBAAkB,CAElB,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,qBAAsB,CACtB,aAAc,CACjB,4HAGG,eAAgB,CAChB,sBAAuB,CACvB,kBAAmB","sourcesContent":[".root {\n    max-width: 90%;\n\n    margin-bottom: 4px;\n\n    display: flex;\n    align-items: center;\n    gap: 0 6px;\n\n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 13px;\n    line-height: 18px;\n    color: #000000;\n}\n\n.counter {\n    padding: 1px 8px;\n\n    background: #19BB4F;\n    border-radius: 8px;\n\n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 12px;\n    line-height: 16px;\n    letter-spacing: 0.22px;\n    color: #FFFFFF;\n}\n\n.overflow {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-DAMFilesArchiveWidget-ArchiveContentPopup-TaskFiles-TitleWithCounter-_TitleWithCounter__root",
	"counter": "src-client-modules-common-DAMFilesArchiveWidget-ArchiveContentPopup-TaskFiles-TitleWithCounter-_TitleWithCounter__counter",
	"overflow": "src-client-modules-common-DAMFilesArchiveWidget-ArchiveContentPopup-TaskFiles-TitleWithCounter-_TitleWithCounter__overflow"
};
export default ___CSS_LOADER_EXPORT___;
