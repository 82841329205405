import { createSelector } from 'reselect';

import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type {
    Autopilot2,
    Autopilot2MediaplanPlanDataResponse as Autopilot2MediaplanPlanData,
    Autopilot2MediaplanChannelsGroups,
    ILPlacementSpec,
} from 'sber-marketing-types/backend';
import type {
    PageState,
    BriefStageForm,
    MediaplanSettingsForm,
    SocialStageForm,
    GroupedDictionaries,
    Sidebar,
} from './types';
import { AutopilotStage } from './types';

import type { StoreState } from '../';

export const getAutopilotPageState = (state: StoreState): PageState => state.autopilot.restState;

export const getAutopilot = createSelector(getAutopilotPageState, (state: PageState): Autopilot2 => {
    return state.autopilot;
});

export const getContext = createSelector(getAutopilotPageState, ({ context }) => context);

export const getActivity = createSelector(getAutopilotPageState, (state: PageState): Activity => {
    return state.activity;
});

export const getMediaplan = createSelector(getAutopilotPageState, (state: PageState): Autopilot2MediaplanPlanData => {
    return state.mediaplan;
});

export const contextIsFinished = createSelector(getContext, ({ titles, descriptions }): boolean => {
    return !!titles.length && !!descriptions.length;
});

export const canPushStageStatuses = createSelector(
    getContext,
    getMediaplan,
    contextIsFinished,
    ({ titles, descriptions }, mediaplan, contextIsFinished): boolean => {
        const haveYandexMetric = (mediaplan?.items || []).some(({ ilId }) => ilId === 'yandex_yad');
        return haveYandexMetric ? contextIsFinished : true;
    },
);

export const getDictionaries = createSelector(getAutopilotPageState, (state: PageState): GroupedDictionaries => {
    return state.dictionaries;
});

export const getMediaplanChannelsGroups = createSelector(
    getAutopilotPageState,
    (state: PageState): Autopilot2MediaplanChannelsGroups => {
        return state.mediaplanChannelsGroups;
    },
);

export const getBriefStageForm = createSelector(getAutopilotPageState, (state: PageState): BriefStageForm => {
    return state.briefStageForm;
});

export const getMediaplanSettingsForm = createSelector(
    getAutopilotPageState,
    (state: PageState): MediaplanSettingsForm => {
        return state.mediaplanSettingsForm;
    },
);

export const getPlacementSpecs = createSelector(getAutopilotPageState, (state: PageState): ILPlacementSpec[] => {
    return state.placementSpecs;
});

export const getSelectedBudgetItemIds = createSelector(getAutopilotPageState, (state: PageState): string[] => {
    return state.selectedBudgetItemIds;
});

export const getChangedCreativeStatuses = createSelector(
    getAutopilotPageState,
    (state: PageState): Record<string, string> => {
        return state.changedCreativeStatuses;
    },
);

export const getSocialStageForm = createSelector(getAutopilotPageState, (state: PageState): SocialStageForm => {
    return state.socialStageForm;
});

export const getCurrentStage = createSelector(getAutopilotPageState, (state: PageState): AutopilotStage => {
    return state.currentStage;
});

export const getMediaplanStatus = createSelector(getAutopilotPageState, (state: PageState): boolean => {
    return state.calculatingMediaplan;
});

export const getPreloaderStatus = createSelector(getAutopilotPageState, (state: PageState): boolean => {
    return state.preloader;
});

export const getSidebar = createSelector(getAutopilotPageState, (state: PageState): Sidebar => {
    return state.sidebar;
});
