// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-leaderDashboard-LeaderDashboard-Header-_styles__root{display:grid;grid-template-columns:1fr 1fr 1fr;grid-template-areas:\"left center right\";align-items:center;padding:0 24px;height:64px;background:#FFFFFF;box-shadow:0 1px 4px rgba(0,0,0,0.1);border-radius:6px}.src-client-modules-leaderDashboard-LeaderDashboard-Header-_styles__left{grid-area:left}.src-client-modules-leaderDashboard-LeaderDashboard-Header-_styles__center{display:flex;align-items:center;justify-content:center;grid-area:center}.src-client-modules-leaderDashboard-LeaderDashboard-Header-_styles__right{display:flex;align-items:center;justify-content:right;grid-area:right}\n", "",{"version":3,"sources":["webpack://./src/client/modules/leaderDashboard/LeaderDashboard/Header/styles.scss"],"names":[],"mappings":"AAAA,yEACI,YAAa,CACb,iCAAkC,CAClC,uCACuB,CACvB,kBAAmB,CAEnB,cAAe,CACf,WAAY,CAEZ,kBAAmB,CACnB,oCAAwC,CACxC,iBAAkB,CACrB,yEAGG,cAAe,CAClB,2EAGG,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CAEvB,gBAAiB,CACpB,0EAGG,YAAa,CACb,kBAAmB,CACnB,qBAAsB,CAEtB,eAAgB","sourcesContent":[".root {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    grid-template-areas:\n        \"left center right\";\n    align-items: center;\n\n    padding: 0 24px;\n    height: 64px;\n\n    background: #FFFFFF;\n    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);\n    border-radius: 6px;\n}\n\n.left {\n    grid-area: left;\n}\n\n.center {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    grid-area: center;\n}\n\n.right {\n    display: flex;\n    align-items: center;\n    justify-content: right;\n\n    grid-area: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-leaderDashboard-LeaderDashboard-Header-_styles__root",
	"left": "src-client-modules-leaderDashboard-LeaderDashboard-Header-_styles__left",
	"center": "src-client-modules-leaderDashboard-LeaderDashboard-Header-_styles__center",
	"right": "src-client-modules-leaderDashboard-LeaderDashboard-Header-_styles__right"
};
export default ___CSS_LOADER_EXPORT___;
