import * as React from 'react';

import { TaskCommentReaction } from 'sber-marketing-types/backend';
import { WithTooltip } from 'sber-marketing-ui';

import { useGetAuthUserQuery } from '@api';

import { IconTag, TagProps } from '@common/components';
import { UserFlex } from '@modules/user/components';

export interface CommentReactionProps extends TagProps {
    reaction: string;
    reactions: TaskCommentReaction[];
}

export function CommentReaction({ reactions, reaction, loading, onClick, ...props }: CommentReactionProps) {
    const { data: authUser, isLoading: isAuthUserLoading } = useGetAuthUserQuery();
    const myId = authUser?.user.attributes.id;
    const isMine = reactions.some(({ authorId }) => myId === authorId);
    const isLoading = loading || isAuthUserLoading;

    const tooltipContent = (
        <>
            {isMine && 'Вы'}
            {reactions.map(
                ({ authorId }, index) =>
                    authorId !== myId && (
                        <UserFlex key={authorId} userId={authorId} before={isMine || index ? ', ' : null} />
                    ),
            )}
        </>
    );

    return (
        <WithTooltip content={tooltipContent}>
            <IconTag
                onClick={(e) => !isLoading && onClick(e)}
                padding={[1, 7]}
                type={isMine ? 'secondary' : 'default'}
                {...props}
                loading={isLoading}
                icon={reaction}
            >
                {reactions.length}
            </IconTag>
        </WithTooltip>
    );
}
