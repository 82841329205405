import { createSelector } from 'reselect';

import type { CreativeRequestContract } from '@api';
import type { PageState } from './types';
import type { StoreState } from '../';

export const getPivotTablePageState = (state: StoreState): PageState => state.pivotTablePage;

export const getContracts = createSelector(
    getPivotTablePageState,
    (state: PageState): Record<'lot1' | 'lot2', CreativeRequestContract[]> => {
        return state.contracts;
    },
);

export const getSelectedLot1ContractId = createSelector(getPivotTablePageState, (state: PageState): string => {
    return state.selectedLot1ContractId;
});

export const getSelectedLot2ContractId = createSelector(getPivotTablePageState, (state: PageState): string => {
    return state.selectedLot2ContractId;
});

export const getTotalTableExpandedStatus = createSelector(getPivotTablePageState, (state: PageState): boolean => {
    return state.totalTableExpanded;
});
