import { useParams } from 'react-router-dom';
import type { Mode } from '@store/leaderDashboardPage';

interface UseMode {
    (): UseModeReturn;
}

interface UseModeReturn {
    mode: Mode;
}

export const useMode: UseMode = () => {
    const { mode } = useParams();

    return {
        mode,
    };
};

export type { Mode };
