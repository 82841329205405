import * as React from 'react';
import * as lodash from 'lodash';
import autobind from 'autobind-decorator';

import { BudgetItemCorrection } from '../../../types';
import { Dictionary } from '../../withDictionary';
import { User } from '../../withUsers';
import { BudgetItemCorrectionCard } from './BudgetItemCorrectionCard';
import type { BudgetItem } from '@mrm/budget';
import { CorrectionStatus } from '@mrm/budget';

export const enum BudgetItemEditableParamsKey {
    ResponsibleIds = 'responsibleIds',
    BusinessTarget = 'businessTarget',
    CustomerName = 'customerName',
    Comment = 'comment',
    ExpertComment = 'expertComment',
    SapComment = 'sapComment',
    SapNumber = 'sapNumber',
    SapZns = 'sapZns',
    RealizationStart = 'realizationStart',
    RealizationEnd = 'realizationEnd',
    PreviousFunds = 'previousFunds',
    DictionaryIds = 'dictionaryIds',
}

type BuilderChangeAttributeList = {
    [P in BudgetItemEditableParamsKey]: number;
};

const targetEditableParamsKeys = [
    BudgetItemEditableParamsKey.ResponsibleIds,
    BudgetItemEditableParamsKey.BusinessTarget,
    BudgetItemEditableParamsKey.CustomerName,
    BudgetItemEditableParamsKey.Comment,
    BudgetItemEditableParamsKey.ExpertComment,
    BudgetItemEditableParamsKey.SapComment,
    BudgetItemEditableParamsKey.SapNumber,
    BudgetItemEditableParamsKey.SapZns,
    BudgetItemEditableParamsKey.RealizationStart,
    BudgetItemEditableParamsKey.RealizationEnd,
    BudgetItemEditableParamsKey.PreviousFunds,
    BudgetItemEditableParamsKey.DictionaryIds,
];

interface Props {
    correction: BudgetItemCorrection;
    users: User[];
    dictionaries: Dictionary[];
    onHover: (correctionId: string) => void;
    onChange: () => void;
}

export class BudgetItemCorrectionCardContainer extends React.Component<Props> {
    public render() {
        const { correction, dictionaries, users, onChange } = this.props;
        return React.createElement(BudgetItemCorrectionCard, {
            correction,
            users,
            dictionaries,
            changesQuantity: this.calculateChangesQuantity(),
            onCardMouseEnter: this.onCardMouseEnter,
            onCardMouseLeave: this.onCardMouseLeave,
            onChange,
        });
    }

    @autobind
    private onCardMouseEnter(): void {
        this.props.onHover(this.props.correction.id);
    }

    @autobind
    private onCardMouseLeave(): void {
        this.props.onHover(null);
    }

    private get budgetItem(): BudgetItem {
        const {
            status,
            budgetItemCurrent: budgetItemsCurrent,
            budgetItemBefore: budgetItemsBefore,
        } = this.props.correction;

        return status === CorrectionStatus.NeedApproving
            ? lodash.first(budgetItemsCurrent)
            : lodash.isEmpty(lodash.first(budgetItemsBefore))
            ? lodash.first(budgetItemsCurrent)
            : lodash.first(budgetItemsBefore);
    }

    private calculateChangesQuantity(): number {
        const changedParamsKeys = this.getChangedParamKeys();

        return changedParamsKeys.reduce((previousChangesQuantity, changedParamKey) => {
            const changesQuantity = this.getChangesQuantityByParamKey(changedParamKey as BudgetItemEditableParamsKey);
            return previousChangesQuantity + changesQuantity;
        }, 0);
    }

    private getChangedParamKeys(): string[] {
        const { params } = this.props.correction;
        const changedParamsKeys = lodash.keys(params);

        return changedParamsKeys.filter((changedParamsKey) => {
            return targetEditableParamsKeys.find(
                (targetEditableParamKey) => changedParamsKey === targetEditableParamKey,
            );
        });
    }

    private getChangesQuantityByParamKey(paramKey: BudgetItemEditableParamsKey): number {
        const builderChangeAttributeList: BuilderChangeAttributeList = {
            [BudgetItemEditableParamsKey.ResponsibleIds]: 1,
            [BudgetItemEditableParamsKey.BusinessTarget]: 0,
            [BudgetItemEditableParamsKey.CustomerName]: 0,
            [BudgetItemEditableParamsKey.Comment]: 1,
            [BudgetItemEditableParamsKey.ExpertComment]: 0,
            [BudgetItemEditableParamsKey.SapComment]: 1,
            [BudgetItemEditableParamsKey.SapNumber]: 1,
            [BudgetItemEditableParamsKey.SapZns]: 1,
            [BudgetItemEditableParamsKey.RealizationStart]: 1,
            [BudgetItemEditableParamsKey.RealizationEnd]: 1,
            [BudgetItemEditableParamsKey.PreviousFunds]: 1,
            [BudgetItemEditableParamsKey.DictionaryIds]: this.makeChangedAttributesOfDictionaries(),
        };

        return builderChangeAttributeList[paramKey];
    }

    private makeChangedAttributesOfDictionaries(): number {
        const {
            correction: { params },
            dictionaries,
        } = this.props;

        // TODO В ответе graphQL висит поле __typename и для перебора свойст обхекта оно мешает
        if (this.budgetItem.dictionary) {
            delete this.budgetItem.dictionary['__typename'];
        }

        const currentDictionariesIds = lodash
            .compact(lodash.values(this.budgetItem.dictionary))
            .map((dictionaryItem) => dictionaryItem.id);

        const removedDictionariesIds = lodash.difference(currentDictionariesIds, params.dictionaryIds);
        const newDictionariesIds = lodash.difference(params.dictionaryIds, currentDictionariesIds);

        const removedDictionaries = dictionaries.filter((dictionary) =>
            lodash.includes(removedDictionariesIds, dictionary.id),
        );
        const newDictionaries = dictionaries.filter((dictionary) => lodash.includes(newDictionariesIds, dictionary.id));

        const removedDictionariesTypes = removedDictionaries.map((removeDictionary) => removeDictionary.type);
        const newDictionariesTypes = newDictionaries.map((newDictionary) => newDictionary.type);

        const changesDictionariesTypes = lodash.uniq([...removedDictionariesTypes, ...newDictionariesTypes]);

        return changesDictionariesTypes.length;
    }
}
