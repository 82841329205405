// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-Header-_styles__root{font-family:'Open Sans';color:#000000}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-Header-_styles__title{margin-bottom:10px;font-size:16px;font-weight:600;letter-spacing:0.3px}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-Header-_styles__description{font-weight:400;font-size:14px;letter-spacing:0.15px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/CreationForm/GeneratedTextEditor/Editor/Footer/ConfirmButton/Popup/Header/styles.scss"],"names":[],"mappings":"AAAA,qKACI,uBAAwB,CACxB,aAAc,CACjB,sKAGG,kBAAmB,CAEnB,cAAe,CACf,eAAgB,CAChB,oBAAqB,CACxB,4KAGG,eAAgB,CAChB,cAAe,CACf,qBAAsB","sourcesContent":[".root {\n    font-family: 'Open Sans';\n    color: #000000;\n}\n\n.title {\n    margin-bottom: 10px;\n\n    font-size: 16px;\n    font-weight: 600;\n    letter-spacing: 0.3px;\n}\n\n.description {\n    font-weight: 400;\n    font-size: 14px;\n    letter-spacing: 0.15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-Header-_styles__root",
	"title": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-Header-_styles__title",
	"description": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-Header-_styles__description"
};
export default ___CSS_LOADER_EXPORT___;
