import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';

import * as style from './LineWarning.scss';

interface Props {
    isActive: boolean;
}

export const HeavyLineWarning: React.FC<Props> = ({ children, isActive }) => (
    <div className={classNames(style.root, style.heavy, isActive && style.active)}>
        <div className={style.iconWrapper}>
            <Icon type={IconType.WARNING_IN_CIRCLE} svgSize={15} />
        </div>
        {children}
    </div>
);

export const LiteLineWarning: React.FC<Props> = ({ children, isActive }) => (
    <div className={classNames(style.root, style.late, isActive && style.active)}>
        <div className={style.iconWrapper}>
            <Icon type={IconType.WARNING_IN_TRIANGLE} svgSize={15} />
        </div>
        {children}
    </div>
);
