import * as React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { useMode } from '../../hooks';
import { useStore } from './useStore';

import * as styles from './styles.scss';

export const CategorySelector: React.FC = () => {
    const { mode } = useMode();
    const { loading } = useStore();

    return (
        <div className={styles.root}>
            <Link
                to={'activities'}
                className={classNames(mode === 'activities' && styles.selected, styles.item)}
                onClick={(event) => loading && event.preventDefault()}
            >
                Активность по проектам
            </Link>
            <Link
                to={'tasks'}
                className={classNames(mode === 'tasks' && styles.selected, styles.item)}
                onClick={(event) => loading && event.preventDefault()}
            >
                Активность по задачам
            </Link>
        </div>
    );
};
