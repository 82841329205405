import { createSelector } from 'reselect';
import { uniq, compact, isNull, pullAt } from 'lodash';

import { StoreState } from '../../';
import { Channel } from '../types';
import { getTaskPageState } from './lib';

const getState = (state: StoreState): StoreState => state;

const getId = (_: StoreState, id: number): number => id;

const getIds = (_: StoreState, ids: number[]): number[] => uniq(ids);

const compareChannels = ({ createTime: createTimeA }: Channel, { createTime: createTimeB }: Channel): number => {
    let result = 0;

    if (createTimeA.isAfter(createTimeB)) {
        result = 1;
    } else if (createTimeB.isAfter(createTimeA)) {
        result = -1;
    }

    return result;
};

export const getChannelById = createSelector(
    [getTaskPageState, getId],
    ({ channels }, id): Channel | undefined => channels[id],
);

export const getChannelsByIds = createSelector([getState, getIds], (state, ids): Channel[] => {
    let result = compact(ids.map((id) => getChannelById(state, id))).sort(compareChannels);
    const defaultIndex = result.findIndex(({ id }) => isNull(id));
    if (defaultIndex !== -1) {
        const [defaultChannel] = pullAt(result, [defaultIndex]);
        result = [defaultChannel, ...result];
    }
    return result;
});

export const getAllChannelsIds = createSelector(getTaskPageState, ({ channels }): number[] =>
    Object.keys(channels).map((id) => JSON.parse(id)),
);

export const getAllChannels = createSelector([getState, getAllChannelsIds], getChannelsByIds);
