import * as React from 'react';
import { createPortal } from 'react-dom';

import { DropdownOptionsProps, useDropdown } from '@common/hooks';

import { Dropdown, DropdownProps } from '../Dropdown';

export type DropdownPortalOptions = Omit<DropdownOptionsProps, 'ref'>;

export interface DropdownPortalProps extends Omit<DropdownProps, 'dropdown'> {
    portal: React.MutableRefObject<HTMLElement>;
    parent: React.MutableRefObject<HTMLElement>;
    options?: DropdownPortalOptions;
}

export const DropdownPortal: React.FC<DropdownPortalProps> = ({ portal, options, parent, ...props }) => {
    const [dropdown] = useDropdown({ ...options, target: parent });
    return createPortal(<Dropdown {...props} dropdown={dropdown} />, portal.current);
};
