import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as style from './CreativeStatusesStageActions.scss';

import { AutopilotStage } from '@store/autopilot/types';
import type { StoreState } from '@store';

import { Icon, IconType, Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';
import { setCurrentStage } from '@store/autopilot/actions';
import { getAutopilot } from '@store/autopilot/selectors';

interface Props {
    canEdit: boolean;
}

export const CreativeStatusesStageActions = ({ canEdit }: Props): JSX.Element => {
    const dispatch = useDispatch();

    const autopilot = useSelector((state: StoreState) => getAutopilot(state));

    const approvedPlacementsCount = autopilot.placements.filter(
        (item) => item.creativeStatus === 'MODERATION_SUCCESS' || item.creativeStatus === 'APPROVED',
    ).length;

    const onContinueButtonClick = React.useCallback(() => {
        dispatch(setCurrentStage(AutopilotStage.Final));
    }, []);

    return (
        <div className={style.root}>
            <div className={style.title}>
                Пройдена модерация каналов/площадок
                {approvedPlacementsCount === autopilot.placements.length && (
                    <div className={style.titleIcon}>
                        <Icon type={IconType.ACCEPT_ACTIVE} svgSize={24} />
                    </div>
                )}
                {approvedPlacementsCount !== autopilot.placements.length && (
                    <>
                        &nbsp;
                        <span className={style.counter}>
                            ({approvedPlacementsCount}/{autopilot.placements.length})
                        </span>
                    </>
                )}
            </div>

            <div className={style.button}>
                <Button theme={ButtonTheme.MainRounded} onClick={onContinueButtonClick}>
                    К финальному согласованию
                </Button>
            </div>
        </div>
    );
};
