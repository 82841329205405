import { useGetChannelParticipantIds } from '@modules/task/hooks';
import { Utils } from '@common/Utils';

export interface UseGetChannelParticipantsCountText {
    text?: string;
    isLoading: boolean;
}

export function useGetChannelParticipantsCountText(
    taskId: string,
    channelId: number,
): UseGetChannelParticipantsCountText {
    const { participants, isLoading } = useGetChannelParticipantIds(taskId, channelId);

    return {
        text:
            participants &&
            `${participants.length} ${Utils.getDeclensionByNumber(participants.length, [
                'участник',
                'участника',
                'участников',
            ])}`,
        isLoading,
    };
}
