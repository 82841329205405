import * as React from 'react';
import classnames from 'classnames';
import { get, sum, values } from 'lodash';
import { Link } from 'react-router-dom';
import { MonthValue } from '@mrm/budget';
import { Icon, IconType, WithTooltip } from 'sber-marketing-ui';

import { Utils, DatesFormatter, Money, MoneyFormatter, Captions } from '@common/Utils';

import { WithTitle } from './WithTitle';
import { BudgetItemLinkCardPreloader } from './Preloader';
import { BudgetItemFetcher } from './BudgetItemFetcher';

import * as styles from './BudgetItemLinkCard.scss';

interface Props {
    id: string;
    budgetId: string;
    activityId: number;
    userHasBudgetAccess?: boolean;
}

export function BudgetItemLinkCard(props: Props): JSX.Element {
    const [data, setData] = React.useState<any>(null);
    const budgetItem = data?.budget || {};
    const { creationTime, sapComment, serialNumber, dictionary, plannedFunds, reservedFunds, factFunds } = budgetItem;

    return (
        <React.Fragment>
            {!data || data?.loading ? (
                <BudgetItemLinkCardPreloader />
            ) : (
                <div
                    className={classnames(styles.root)}
                    {...{
                        'qa-id': 'serialNumberCard',
                    }}
                >
                    <div className={styles.generalInfoWrapper}>
                        <div className={styles.topRow}>
                            <SerialNumberLink
                                renderLink={props.userHasBudgetAccess}
                                activityId={props.activityId}
                                serialNumber={serialNumber}
                                budgetId={props.budgetId}
                            />
                            {creationTime && (
                                <div
                                    className={styles.date}
                                    {...{
                                        'qa-id': 'serialNumberCardDate',
                                    }}
                                >
                                    <Icon type={IconType.CALENDAR_ICON} svgSize={16} className={styles.dateIcon} />

                                    {DatesFormatter.ddMonthyy(creationTime)}
                                </div>
                            )}
                        </div>

                        <WithTitle
                            qaId="serialNumberCardProduct"
                            title="Название проекта (комментарий для SAP):"
                            text={sapComment || 'Не задан'}
                        />

                        <div className={styles.withTitle}>
                            <WithTitle
                                qaId="serialNumberCardTool"
                                title="Инструмент"
                                text={
                                    Utils.withErrorHandler(() => Utils.getDictionaryValue(dictionary.tool)) ||
                                    'Не задан'
                                }
                            />
                        </div>

                        <div className={styles.withTitle}>
                            <WithTitle
                                qaId="serialNumberCardProduct"
                                title="Продукт"
                                text={get(dictionary, 'product.value') || 'Не задан'}
                            />
                        </div>

                        <div className={styles.withTitle}>
                            <WithTitle
                                qaId="serialNumberCardSegment"
                                title="Сегмент"
                                text={get(dictionary, 'segment.value') || 'Не задан'}
                            />
                        </div>
                    </div>

                    <div className={styles.budgetInfoWrapper}>
                        <BudgetInfo title="План" budget={plannedFunds} />

                        <BudgetInfo title="Резерв" budget={reservedFunds} />

                        <BudgetInfo title="Факт" budget={factFunds} />
                    </div>
                </div>
            )}

            <BudgetItemFetcher forwardData={setData} id={props.id} />
        </React.Fragment>
    );
}

interface SerialNumberLinkProps {
    renderLink: boolean;
    activityId: number;
    serialNumber: number;
    budgetId: string;
}

function SerialNumberLink({ renderLink, activityId, serialNumber, budgetId }: SerialNumberLinkProps): JSX.Element {
    if (renderLink) {
        const url = `/budget/execution?activityId=${activityId}&filters=serialNumber:${serialNumber}&budgetId=${budgetId}`;

        return (
            <Link to={url} target="_blank" className={styles.idLink}>
                ID {serialNumber}
            </Link>
        );
    }

    return <div className={styles.id}>ID {serialNumber}</div>;
}

interface BudgetInfoProps {
    title: string;
    budget: MonthValue;
}

function BudgetInfo({ title, budget }: BudgetInfoProps): JSX.Element {
    const money = Money.fromCopecks(sum(values(budget).filter((budget) => typeof budget === 'number')));
    const thousandContent = MoneyFormatter.toThousands(money, { hideCaption: true });

    return (
        <div
            className={styles.budgetInfo}
            {...{
                'qa-id': 'serialNumberCardBudgetInfo',
                'qa-label': title,
                'qa-value': thousandContent,
            }}
        >
            <div className={styles.budgetInfoTitle}>
                {title}, {Captions.Thousands}
            </div>

            <WithTooltip content={MoneyFormatter.toRoubles(money)}>
                <div className={styles.budgetInfoSum}>{thousandContent}</div>
            </WithTooltip>
        </div>
    );
}
