// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-EditPlannedBudgetPage-ActivitySuggest-_ActivitySuggest__existingNamesWrapper{width:100%;background:#ffffff}.src-client-modules-budget-EditPlannedBudgetPage-ActivitySuggest-_ActivitySuggest__stubWrapper{display:flex;border-bottom:1px solid #e3e9e5}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/EditPlannedBudgetPage/ActivitySuggest/ActivitySuggest.scss"],"names":[],"mappings":"AAAA,wGACI,UAAW,CAEX,kBAAmB,CACtB,+FAGG,YAAa,CAEb,+BAAgC","sourcesContent":[".existingNamesWrapper {\n    width: 100%;\n\n    background: #ffffff;\n}\n\n.stubWrapper {\n    display: flex;\n\n    border-bottom: 1px solid #e3e9e5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"existingNamesWrapper": "src-client-modules-budget-EditPlannedBudgetPage-ActivitySuggest-_ActivitySuggest__existingNamesWrapper",
	"stubWrapper": "src-client-modules-budget-EditPlannedBudgetPage-ActivitySuggest-_ActivitySuggest__stubWrapper"
};
export default ___CSS_LOADER_EXPORT___;
