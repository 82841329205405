import * as React from 'react';

import { OptionMultiple, OptionProps, FormSelector, FormSelectorProps } from '@common/components';
import { useGetDictionaryQuery, DictionaryData, GetDictionaryPathParams } from '@api';

export interface DictionaryFormSelectorProps<M extends OptionMultiple>
    extends Omit<
            FormSelectorProps<
                M,
                OptionProps<DictionaryData>,
                DictionaryData,
                'id',
                string,
                M extends true ? string[] : string
            >,
            'options'
        >,
        GetDictionaryPathParams {
    skip?: boolean;
}

export function DictionaryFormSelector<M extends OptionMultiple>({
    organizationId,
    loading,
    types,
    ids,
    limit,
    offset,
    skip,
    ...props
}: DictionaryFormSelectorProps<M>) {
    const params: Required<GetDictionaryPathParams> = {
        types,
        organizationId,
        ids,
        limit,
        offset,
    };
    const { isLoading: isDivisionsLoading, data: divisions } = useGetDictionaryQuery(params, { skip });

    const options: OptionProps<DictionaryData>[] = React.useMemo(
        () =>
            divisions?.map((division) => ({
                title: division.value,
                value: division,
            })),
        [divisions],
    );

    return <FormSelector loading={isDivisionsLoading || loading} {...props} optionId="id" options={options} />;
}
