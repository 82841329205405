import * as React from 'react';
import { graphql } from 'react-apollo';
import autobind from 'autobind-decorator';
import { GET_CORRECTIONS_QUERY } from './graphqlQueries';
import { QueryProps, Correction } from '../types';
import * as lodash from 'lodash';
import { CorrectionType } from '@mrm/budget';

import {
    TargetFilter,
    FilterValues,
    CorrectionFilterName,
    STATUS_FITLER_ITEMS_FOR_EXPORT_MODE,
    TYPE_FILTER_ITEMS_FOR_EXPORT_MODE,
} from '@store/budgetCorrections/filtersAndExport';

const LIMIT = 10;

interface Props extends QueryProps, ExternalProps {}

interface ExternalProps {
    budgetId: string;
    scrollPageToTop: () => void;
    children?: (params: RenderParams) => JSX.Element;
}

export interface RenderParams extends State {
    allCorrectionsIds: string[];
    corrections: Correction[];
    fetchMore: (filters: FilterValues) => void;
    applyFilters: (filters: FilterValues) => void;
}

interface State {
    applyFiltersInProgress: boolean;
    fetchMoreInProgress: boolean;
}

class CorrectionsQuery extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            applyFiltersInProgress: true,
            fetchMoreInProgress: false,
        };
    }

    public render() {
        const allCorrectionsIds = this.props.data?.allCorrections?.nodes?.map((correction) => correction.id) || [];
        const corrections =
            this.props.data?.corrections?.nodes?.map((correction) => ({
                ...correction,
                params: JSON.parse(correction.params),
            })) || [];

        return this.props.children({
            ...this.state,
            allCorrectionsIds,
            corrections,
            fetchMore: this.fetchMore,
            applyFilters: this.applyFilters,
        });
    }

    @autobind
    private async fetchMore(filters: FilterValues): Promise<void> {
        const couldFetchMore =
            this.props.data?.corrections?.nodes?.length < this.props.data?.allCorrections?.nodes?.length;

        if (couldFetchMore) {
            await new Promise<void>((resolve) => this.setState({ fetchMoreInProgress: true }, resolve));

            const newFetchLimit = (this.props.data?.corrections?.nodes?.length || 0) + LIMIT;
            const filtersForFetch = this.prepareFitlersForFetch(filters);

            await this.props.data.refetch({
                filter: filtersForFetch,
                limit: newFetchLimit,
            });

            await new Promise<void>((resolve) => this.setState({ fetchMoreInProgress: false }, resolve));
        }
    }

    @autobind
    private async applyFilters(filters: FilterValues): Promise<void> {
        await new Promise<void>((resolve) => this.setState({ applyFiltersInProgress: true }, resolve));

        this.props.scrollPageToTop();

        const filtersForFetch = this.prepareFitlersForFetch(filters);

        await this.props.data.refetch({ filter: filtersForFetch, limit: LIMIT });

        await new Promise<void>((resolve) => this.setState({ applyFiltersInProgress: false }, resolve));
    }

    private prepareFitlersForFetch(filters: FilterValues) {
        return {
            budgetId: this.props.budgetId,
            ...this.convertFormFields(this.cutEmptyFilterFields(this.transformFilterItemsForExport(filters))),
        };
    }

    private transformFilterItemsForExport(filters: FilterValues): FilterValues {
        const res = { ...filters };

        if (filters.isExportModeEnabled) {
            res[TargetFilter.Status] = STATUS_FITLER_ITEMS_FOR_EXPORT_MODE;

            if (res[TargetFilter.Types]) {
                res[TargetFilter.Types] = res[TargetFilter.Types].filter((item: CorrectionType) =>
                    TYPE_FILTER_ITEMS_FOR_EXPORT_MODE.includes(item),
                );
            }
        }

        return res;
    }

    private cutEmptyFilterFields(filters: FilterValues): Partial<FilterValues> {
        const { isExportModeEnabled, dateRangeStart, dateRangeEnd, ...restFilters } = filters;
        const res: Partial<FilterValues> = {
            isExportModeEnabled,
            dateRangeStart,
            dateRangeEnd,
        };

        return Object.keys(restFilters).reduce((acc, filterKey) => {
            if (!lodash.isEmpty(restFilters[filterKey])) {
                acc[filterKey] = restFilters[filterKey];
            }

            return acc;
        }, res);
    }

    private convertFormFields(filters: Partial<FilterValues>) {
        const { isExportModeEnabled, dateRangeStart, dateRangeEnd, ...restFilters } = filters;
        const newFilter: any = {};

        lodash.keys(restFilters).forEach((filedKey) => {
            switch (filedKey) {
                case TargetFilter.Types:
                    newFilter[filedKey] = lodash.flatMap(
                        filters[filedKey].map((type: any) => CorrectionFilterName[type]),
                    );
                    break;
                case TargetFilter.Approvers:
                    newFilter[filedKey] = { id: filters[filedKey].map((id: any) => String(id)) };
                    break;
                case TargetFilter.Authors:
                    newFilter[filedKey] = { id: filters[filedKey].map((id: any) => String(id)) };
                    break;
                case TargetFilter.ExecutionIds:
                    newFilter[filedKey] = filters[filedKey].map((id: any) => Number(id));
                    break;
                case TargetFilter.Divisions:
                    newFilter.divisionids = filters[filedKey];
                    break;
                case TargetFilter.CostCenter:
                    newFilter.costCenterIds = filters[filedKey];
                    break;
                default:
                    newFilter[filedKey] = filters[filedKey];
            }
        });

        if (isExportModeEnabled) {
            if (dateRangeStart && dateRangeEnd) {
                newFilter.dateRange = [dateRangeStart, dateRangeEnd];
            }
        }

        return newFilter;
    }
}

export const WithCorrectionsQuery = graphql<ExternalProps>(GET_CORRECTIONS_QUERY, {
    options: (props) => ({
        variables: {
            limit: 10,
            filter: {
                budgetId: props.budgetId,
            },
        },
        fetchPolicy: 'no-cache',
    }),
})(CorrectionsQuery);
