import { TaskPageState } from '../types';

export const setAuthorIdReducer = (
    {
        authorId: oldAuthorId,
        newCommentary: { authorId: commentAuthorId, ...restNewCommentary },
        ...restState
    }: TaskPageState,
    authorId: number,
): TaskPageState => ({
    ...restState,
    newCommentary: {
        ...restNewCommentary,
        authorId,
    },
    authorId,
});
