import * as React from 'react';
import classNames from 'classnames';
import * as lodash from 'lodash';

import * as style from './DateFilter.scss';

import type { QuarterData, MonthData } from '../modules';

import { Breadcrumbs } from './Breadcrumbs';
import { Month } from './Month';
import { Days } from './Days';
import { Quarter } from './Quarter';
import { TodayLineIndicator } from './TodayLineIndicator';

interface Props {
    quarters: QuarterData[];
    months: MonthData[];
    scroll: number;
    width: number;
    selectedQuarter: number;
    selectedMonth: number;
    selectedYear: number;
    onYearClick: (value: number) => void;
    onQuarterClick: (value: number, year: number) => void;
    onMonthClick: (value: number, year: number) => void;
    rootRef: (element: HTMLDivElement) => void;
}

export const DateFilter = ({
    scroll,
    quarters,
    months,
    width,
    selectedQuarter,
    selectedMonth,
    selectedYear,
    onYearClick,
    onQuarterClick,
    onMonthClick,
    rootRef,
}: Props): JSX.Element => {
    const periodIsYear = lodash.isNil(selectedMonth) && lodash.isNil(selectedQuarter);
    const periodIsQuarter = !periodIsYear && !selectedMonth;
    const periodIsMonth = selectedMonth;

    return (
        <div
            className={style.root}
            ref={rootRef}
            {...{
                'qa-id': 'dateFilter',
            }}
        >
            <div className={style.wrapper} style={{ width: width || 'auto' }}>
                <div className={style.topLine}>
                    <Breadcrumbs
                        selectedYear={selectedYear}
                        selectedQuarter={selectedQuarter}
                        selectedMonth={selectedMonth}
                        onYearClick={onYearClick}
                        onQuarterClick={onQuarterClick}
                        onMonthClick={onMonthClick}
                    />
                </div>

                <div className={style.bottomLine}>
                    {periodIsYear && <Quarters quarters={quarters} onQuarterClick={onQuarterClick} scroll={scroll} />}

                    {periodIsQuarter && <Months months={months} onMonthClick={onMonthClick} scroll={scroll} />}

                    {periodIsMonth && <Days scroll={scroll} />}
                </div>

                <TodayLineIndicator />
            </div>
        </div>
    );
};

interface QuartersProps {
    quarters: QuarterData[];
    scroll: number;
    onQuarterClick(quarter: number, year: number): void;
}

const Quarters: React.SFC<QuartersProps> = ({ quarters, scroll, onQuarterClick }: QuartersProps): JSX.Element => {
    return (
        <div
            className={classNames(style.slider, style.fullWidth)}
            style={{ marginLeft: `-${scroll * 100}%` }}
            {...{
                'qa-id': 'quarters',
            }}
        >
            {quarters.map(({ year, quarter }) => (
                <Quarter
                    key={`year ${year} quarter ${quarter}`}
                    year={year}
                    quarter={quarter}
                    onQuarterClick={onQuarterClick}
                />
            ))}
        </div>
    );
};

interface MonthsProps {
    months: MonthData[];
    scroll: number;
    onMonthClick(quarter: number, year: number): void;
}

const Months: React.SFC<MonthsProps> = ({ months, scroll, onMonthClick }: MonthsProps): JSX.Element => {
    return (
        <div
            className={classNames(style.slider, style.fullWidth)}
            style={{ marginLeft: `-${scroll * 100}%` }}
            {...{
                'qa-id': 'months',
            }}
        >
            {months.map(({ year, isYearLeap, month, daysCount, monthShortName, monthName }) => (
                <Month
                    key={`year ${year} month ${month}`}
                    year={year}
                    month={month}
                    monthShortName={monthShortName}
                    monthName={monthName}
                    isYearLeap={isYearLeap}
                    daysCount={daysCount}
                    onMonthClick={onMonthClick}
                />
            ))}
        </div>
    );
};
