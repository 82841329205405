import createCashedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { BudgetStatus } from '@mrm/budget';
import { UserConfigType } from 'sber-marketing-types/openid';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { UserConfigState } from '@store/userConfig';
import { getUserConfigState } from '../selectors';

import { BudgetUserConfigState, BudgetUserConfig, BudgetByStatusUserConfig } from './types';

export const getBudgetUserConfigState = createSelector(
    getUserConfigState,
    (state: UserConfigState): BudgetUserConfigState => state[UserConfigType.Budget],
);

export const getBudgetUserConfig = createSelector(
    getBudgetUserConfigState,
    (state: BudgetUserConfigState): BudgetUserConfig => state.fields,
);

export const getLoadingStatus = createSelector(
    getBudgetUserConfigState,
    (state: BudgetUserConfigState): LoadingStatus => state.status,
);

export const getBudgetByStatusUserConfig = createCashedSelector(
    getBudgetUserConfigState,
    (state: StoreState, budgetStatus: BudgetStatus): BudgetStatus => budgetStatus,
    (state: BudgetUserConfigState, budgetStatus: BudgetStatus): BudgetByStatusUserConfig =>
        state.fields.budgetStatus[budgetStatus],
)((state: StoreState, budgetStatus: BudgetStatus): BudgetStatus => budgetStatus);
