import { createElement, PureComponent } from 'react';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';

import { Period } from '@store/calendar/types';
import type { MonthData } from '../../modules';

import { Month } from './Month';
import type { StoreState } from '@store/index';
import { getCalendarPageState } from '@store/calendar/selectors';
import { TimeCalculator } from '../../modules';

interface Props extends MonthData, Partial<MapProps> {
    onMonthClick?(month: number, year: number): void;
}

interface MapProps {
    period: Period;
}

interface State {
    label: string;
    width: number;
}

@(connect(mapStateToProps) as any)
export class MonthContainer extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            label: this.getLabel(this.props),
            width: this.getStylesheet(this.props),
        };
    }

    public UNSAFE_componentWillReceiveProps(newProps: Props) {
        const shouldUpdateStylesheet: boolean =
            this.props.year !== newProps.year || this.props.month !== newProps.month;
        const shouldUpdateLabel: boolean =
            this.props.monthName !== newProps.monthName ||
            this.props.monthShortName !== newProps.monthShortName ||
            this.props.year !== newProps.year;
        if (shouldUpdateLabel || shouldUpdateStylesheet) {
            this.setState((prevState) => ({
                label: shouldUpdateLabel ? this.getLabel(newProps) : prevState.label,
                width: shouldUpdateStylesheet ? this.getStylesheet(newProps) : prevState.width,
            }));
        }
    }

    public render(): JSX.Element {
        return createElement(Month, {
            label: this.state.label,
            width: this.state.width,
            onMonthClick: this.onMonthClick,
        });
    }

    @autobind
    private onMonthClick() {
        const { onMonthClick, month, year } = this.props;
        if (onMonthClick) {
            onMonthClick(month, year);
        }
    }

    private getLabel({ monthName, year }: Props): string {
        return `${monthName} ${year}`;
    }

    private getStylesheet({ month, year }: Props): number {
        const calculator = TimeCalculator.getInstance();
        return calculator.getMonthWidth(year, month);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { period } = getCalendarPageState(state);

    return {
        period,
    };
}
