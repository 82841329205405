// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-dashboard-CardsList-ActivityCardPreloader-_ActivityCardPreloader__root{width:100%;height:175px;padding:10px 16px 12px;user-select:none;border-radius:4px;background-color:#ffffff;box-shadow:0px 1px 4px rgba(0,0,0,0.1)}.src-client-modules-dashboard-CardsList-ActivityCardPreloader-_ActivityCardPreloader__root:hover{box-shadow:0px 3px 10px rgba(0,0,0,0.13)}.src-client-modules-dashboard-CardsList-ActivityCardPreloader-_ActivityCardPreloader__separator{height:1px;margin:7px -16px 5px;border:0;background-color:#e7ece8}\n", "",{"version":3,"sources":["webpack://./src/client/modules/dashboard/CardsList/ActivityCardPreloader/ActivityCardPreloader.scss"],"names":[],"mappings":"AAAA,2FACI,UAAW,CACX,YAAa,CACb,sBAAuB,CAEvB,gBAAiB,CAEjB,iBAAkB,CAClB,wBAAyB,CACzB,sCAA0C,CAT9C,iGAYQ,wCAA4C,CAC/C,gGAID,UAAW,CACX,oBAAqB,CAErB,QAAS,CACT,wBAAyB","sourcesContent":[".root {\n    width: 100%;\n    height: 175px;\n    padding: 10px 16px 12px;\n\n    user-select: none;\n\n    border-radius: 4px;\n    background-color: #ffffff;\n    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);\n\n    &:hover {\n        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.13);\n    }\n}\n\n.separator {\n    height: 1px;\n    margin: 7px -16px 5px;\n\n    border: 0;\n    background-color: #e7ece8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-dashboard-CardsList-ActivityCardPreloader-_ActivityCardPreloader__root",
	"separator": "src-client-modules-dashboard-CardsList-ActivityCardPreloader-_ActivityCardPreloader__separator"
};
export default ___CSS_LOADER_EXPORT___;
