import * as React from 'react';

import { UserApi } from '@api';
import { User } from './types';

interface Props {}

interface State {
    loading: boolean;
    users: User[];
}

export const withUsers = (WrappedComponent: any) => {
    return class extends React.Component<Props, State> {
        constructor(props: Props) {
            super(props);

            this.state = {
                loading: true,
                users: null,
            };
        }

        public async componentDidMount(): Promise<void> {
            const users = await this.fetch();
            this.setState({
                loading: false,
                users,
            });
        }

        public render() {
            const { users, loading } = this.state;
            return React.createElement(WrappedComponent, {
                ...this.props,
                users,
                usersLoading: loading,
            });
        }

        public async fetch(): Promise<User[]> {
            return await UserApi.getFullUserList();
        }
    };
};
