import * as React from 'react';
import classNames from 'classnames';

import * as styles from './TaskCommentForm.scss';

import { CommentLikeCard } from '../CommentLikeCard';
import { CommentEditable, CommentEditableProps } from '../CommentEditable';
import { CommentAssetOpenProvider } from '../CommentAssets';

interface Props extends CommentEditableProps {}

export function TaskCommentForm({
    taskId,
    assetsIds,
    message,
    onChange,
    onAddAsset,
    onSubmit,
    hasAssets,
    headerHeight,
    className,
    errorMessage,
    displayError,
    mentionableUsers,
    onAssetRemoveClick,
    onFocus,
    onBlur,
}: Props): JSX.Element {
    return (
        <CommentLikeCard className={classNames(styles.root, className)}>
            <CommentAssetOpenProvider>
                <CommentEditable
                    useR7Controls={false}
                    showAssetsByDefault
                    hasAssets={hasAssets}
                    assetsIds={assetsIds}
                    assetIsDeletable
                    disableAssetDownload
                    message={message}
                    headerHeight={headerHeight}
                    errorMessage={errorMessage}
                    displayError={displayError}
                    mentionableUsers={mentionableUsers}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={onChange}
                    onAddAsset={onAddAsset}
                    onSubmit={onSubmit}
                    onAssetRemoveClick={onAssetRemoveClick}
                />
            </CommentAssetOpenProvider>
        </CommentLikeCard>
    );
}
