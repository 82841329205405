import * as React from 'react';

import * as style from './ActivityBudgetCard.scss';

import { Form, FormField, FieldValue, HeightAnimation } from 'sber-marketing-ui';
import { ActivitySuggest } from '../ActivitySuggest';

interface Props {
    budgetId: string;
    fields: FormField[];
    showActivitySuggest: boolean;
    onFieldValueChange: (id: string, value: FieldValue) => void;
}

export const ActivityBudgetCard = ({
    budgetId,
    fields,
    showActivitySuggest,
    onFieldValueChange,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <HeightAnimation>
                <div className={style.topLine}>
                    <div className={style.title}>Общая информация</div>
                </div>

                <div className={style.form}>
                    <Form fields={fields} onFieldValueChange={onFieldValueChange} />

                    {showActivitySuggest && (
                        <div className={style.activitySuggest}>
                            <ActivitySuggest budgetId={budgetId} />
                        </div>
                    )}
                </div>
            </HeightAnimation>
        </div>
    );
};
