import * as moment from 'moment';

import { Month } from '@mrm/budget';
import type { PlainDictionary } from '@mrm/dictionary';
import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type {
    Autopilot2,
    Autopilot2MediaplanPlanDataResponse as Autopilot2MediaplanPlanData,
    Autopilot2MediaplanChannelsGroups,
    ILPlacementSpec,
} from 'sber-marketing-types/backend';
import type { GenerationTextFormState } from './generationTextForm';

export interface AutopilotPageState {
    generationTextFormState: GenerationTextFormState;
    restState: PageState;
}

export interface PageState {
    autopilot: Autopilot2;
    activity: Activity;
    mediaplan: Autopilot2MediaplanPlanData;
    dictionaries: GroupedDictionaries;
    context: Context;
    mediaplanChannelsGroups: Autopilot2MediaplanChannelsGroups;
    placementSpecs: ILPlacementSpec[];
    briefStageForm: BriefStageForm;
    mediaplanSettingsForm: MediaplanSettingsForm;
    selectedBudgetItemIds: string[];
    changedCreativeStatuses: Record<string, string>;
    socialStageForm: SocialStageForm;
    currentStage: AutopilotStage;
    calculatingMediaplan: boolean;
    preloader: boolean;
    sidebar: Sidebar;
}

export interface Context {
    titles: string[];
    descriptions: string[];
}

export interface BriefStageForm {
    blockId: string;
    productId: string;
    budget: string;
    budgetError: string;
    dateStart: moment.Moment;
    dateEnd: moment.Moment;
    targetAgeFrom: string;
    targetAgeTo: string;
    landingPage: string;
    peakDates: Date[];
    yandexMetrikaTarget: string;
}

export interface MediaplanSettingsForm {
    hasYearCompanies: boolean;
    conversion: number;
    isManuallySet: boolean;
    channelGroups: Autopilot2MediaplanChannelsGroups;
}

export interface SocialStageForm {
    vkUrl: string;
    okUrl: string;
}

export interface GroupedDictionaries {
    [dictionaryType: string]: PlainDictionary[];
}

export interface Sidebar {
    opened: boolean;
}

export interface SwitchKeyActivityParams {
    id: number;
    isKey: boolean;
}

export const enum AutopilotStage {
    Brief = 'brief',
    MediaplanSettings = 'mediaplan_settings',
    Mediaplan = 'mediaplan',
    Budget = 'budget',
    CreativeStatuses = 'creativeStatuses',
    Social = 'social',
    Context = 'context',
    Programmatic = 'programmatic',
    Final = 'final',
}

export const MONTHS = [
    Month.Jan,
    Month.Feb,
    Month.Mar,
    Month.Apr,
    Month.May,
    Month.Jun,
    Month.Jul,
    Month.Aug,
    Month.Sept,
    Month.Oct,
    Month.Nov,
    Month.Dec,
];
