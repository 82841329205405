import * as React from 'react';
import { ActivityFileGroup } from 'sber-marketing-types/frontend';

import { FileGroupConnected } from './FileGroupConnected';
import { FileGroupContainer } from './FileGroupContainer';
import { FileProps } from './FileCard';

interface Props {
    loading: boolean;
    title: string;
    fileGroup: ActivityFileGroup;
    files: FileProps[];
    enableControls: boolean;
    reloadContent: () => Promise<void>;
}

export function FileGroup(props: Props): JSX.Element {
    return <FileGroupConnected>{(gqlProps) => <FileGroupContainer {...props} {...gqlProps} />}</FileGroupConnected>;
}
