// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-components-TaskSideForm-_TaskSideForm__root{background-color:#ffffff}.src-client-modules-activity-pages-task-components-TaskSideForm-_TaskSideForm__internalWrap{box-sizing:border-box;width:100%;padding-top:24px;padding-right:30px;padding-bottom:24px;padding-left:30px}.src-client-modules-activity-pages-task-components-TaskSideForm-_TaskSideForm__taskExecutor{margin-bottom:34px}.src-client-modules-activity-pages-task-components-TaskSideForm-_TaskSideForm__taskParticipators{margin-bottom:38px}.src-client-modules-activity-pages-task-components-TaskSideForm-_TaskSideForm__assets{margin-bottom:24px}.src-client-modules-activity-pages-task-components-TaskSideForm-_TaskSideForm__assets:last-child{margin-bottom:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/components/TaskSideForm/TaskSideForm.scss"],"names":[],"mappings":"AAAA,oFACI,wBAAyB,CAC5B,4FAGG,qBAAsB,CACtB,UAAW,CACX,gBAAiB,CACjB,kBAAmB,CACnB,mBAAoB,CACpB,iBAAkB,CACrB,4FAGG,kBAAmB,CACtB,iGAGG,kBAAmB,CACtB,sFAGG,kBAAmB,CACtB,iGAGG,eAAgB","sourcesContent":[".root {\n    background-color: #ffffff;\n}\n\n.internalWrap {\n    box-sizing: border-box;\n    width: 100%;\n    padding-top: 24px;\n    padding-right: 30px;\n    padding-bottom: 24px;\n    padding-left: 30px;\n}\n\n.taskExecutor {\n    margin-bottom: 34px;\n}\n\n.taskParticipators {\n    margin-bottom: 38px;\n}\n\n.assets {\n    margin-bottom: 24px;\n}\n\n.assets:last-child {\n    margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-components-TaskSideForm-_TaskSideForm__root",
	"internalWrap": "src-client-modules-activity-pages-task-components-TaskSideForm-_TaskSideForm__internalWrap",
	"taskExecutor": "src-client-modules-activity-pages-task-components-TaskSideForm-_TaskSideForm__taskExecutor",
	"taskParticipators": "src-client-modules-activity-pages-task-components-TaskSideForm-_TaskSideForm__taskParticipators",
	"assets": "src-client-modules-activity-pages-task-components-TaskSideForm-_TaskSideForm__assets"
};
export default ___CSS_LOADER_EXPORT___;
