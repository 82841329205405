import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input_redesign as Input, InputTheme_redesign as InputTheme } from 'sber-marketing-ui';
import classnames from 'classnames';

import { StoreState } from '@store';
import {
    ComponentState,
    getInstance,
    setPendingTagTitle,
    setNewTagInputValue,
    setComponentState,
    removeTagFromSelected,
    shouldAddNewTagItem,
} from '@store/tagsEditor';

import { useDescriptor } from '@modules/tags/TagsPreviewAndEditor/Context';

import { SelectedTags } from './SelectedTags';

import * as styles from './TagsInput.scss';

function useInteractivity(props: Props) {
    const dispatch = useDispatch();
    const { onAttachedTagsChange } = props;
    const { id, makeActionParams, makeDataUpdateActionParams } = useDescriptor();

    const canEdit = useSelector((state: StoreState) => getInstance(state, id).canEdit);
    const addNewTagItem = useSelector((state: StoreState) => shouldAddNewTagItem(state, id));
    const componentState = useSelector((state: StoreState) => getInstance(state, id).componentState);
    const value = useSelector((state: StoreState) => getInstance(state, id).newTagInputValue);
    const lastSelectedTag = useSelector((state: StoreState) => {
        const { selected } = getInstance(state, id).tags;

        return selected?.length ? selected[selected.length - 1] : null;
    });

    const isFocused = componentState === ComponentState.TagsPreviewOpened;

    const wrapperRef = React.useRef<HTMLDivElement>();
    const [inputRef, setInputRef] = React.useState<Input>();

    function onInputFocus() {
        dispatch(setComponentState(makeActionParams(ComponentState.TagsPreviewOpened)));
    }

    function onInputValueChange(value: string) {
        const trimedValue = value.trim();

        dispatch(setNewTagInputValue(makeActionParams(trimedValue)));
        dispatch(setPendingTagTitle(makeActionParams(trimedValue)));
    }

    function onInputKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        switch (event.key) {
            case 'Enter':
                if (addNewTagItem) {
                    inputRef.blur();
                    dispatch(setComponentState(makeActionParams(ComponentState.CreateNewTagPopup)));
                }

                break;
            case 'Backspace':
                const initLastTagRemove = !value && lastSelectedTag;

                if (initLastTagRemove) {
                    dispatch(removeTagFromSelected(makeDataUpdateActionParams(lastSelectedTag)));
                    onAttachedTagsChange?.();
                }

                break;
            default:
                break;
        }
    }

    React.useEffect(() => {
        if (inputRef) {
            if (isFocused) {
                inputRef.focus();
            } else {
                inputRef.blur();
            }
        }
    }, [isFocused, value]);

    return {
        setInputRef,
        wrapperRef,
        isFocused,
        canEdit,
        value,
        onInputValueChange,
        onInputKeyDown,
        onInputFocus,
    };
}

interface Props {
    parentZIndex: number;
    onAttachedTagsChange?: () => void;
}

export function TagsInput(props: Props): JSX.Element {
    const { onAttachedTagsChange } = props;
    const { setInputRef, wrapperRef, isFocused, canEdit, value, onInputValueChange, onInputKeyDown, onInputFocus } =
        useInteractivity(props);

    return (
        <React.Fragment>
            <div
                className={classnames(styles.root, canEdit && styles.rootEditable, isFocused && styles.rootFocused)}
                ref={wrapperRef}
                {...{
                    'qa-id': 'tagsPreviewAndEditorSelectedTagsAndInput',
                }}
            >
                <SelectedTags parentZIndex={props.parentZIndex} onAttachedTagsChange={onAttachedTagsChange} />

                {canEdit ? (
                    <div className={styles.input} style={{ zIndex: props.parentZIndex + 1 }}>
                        <Input
                            qaId="tagsPreviewAndEditorTagsInput"
                            ref={setInputRef}
                            placeholder="Начните печатать.."
                            value={value}
                            theme={InputTheme.Simple}
                            onInputChange={onInputValueChange}
                            onKeyDown={onInputKeyDown}
                            onFocus={onInputFocus}
                        />
                    </div>
                ) : null}
            </div>
        </React.Fragment>
    );
}
