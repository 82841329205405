import * as React from 'react';
import { BudgetItemSnapshot } from '@mrm/budget';
import { StaticSkeleton } from 'sber-marketing-ui';

import { shouldRender as shouldRenderRejectionComment, RejectionComment } from './RejectionComment';
import { shouldRender as shouldRenderDifferenceList, DifferenceList } from './DifferenceList';

import * as styles from './Content.scss';

export function shouldRender(diffs: string[], curr: BudgetItemSnapshot): boolean {
    return shouldRenderRejectionComment(diffs, curr) || shouldRenderDifferenceList(diffs);
}

interface Props {
    diffs: string[];
    curr: BudgetItemSnapshot;
    prev: BudgetItemSnapshot;
    differences?: {
        [key: string]: string[];
    };
}

export function Content(props: Props): JSX.Element {
    return (
        <React.Fragment>
            <RejectionComment {...props} />
            <DifferenceList {...props} />
        </React.Fragment>
    );
}

export function ContentSkeleton(): JSX.Element {
    return (
        <div className={styles.root}>
            <StaticSkeleton loadingClassName={styles.skeleton} />
        </div>
    );
}
