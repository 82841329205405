import gql from 'graphql-tag';

export const CREATE_LINK_MUTATION = gql`
    mutation createLink($projectId: Int!, $budgetItemId: String!, $approverId: Int!) {
        linkBudgetItemToProject(projectId: $projectId, budgetItemId: $budgetItemId, approverId: $approverId)
    }
`;

export const REMOVE_LINK_MUTATION = gql`
    mutation removeLink($projectId: Int!, $budgetItemId: String!) {
        unlinkBudgetItemFromProject(projectId: $projectId, budgetItemId: $budgetItemId)
    }
`;
