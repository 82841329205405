import createCashedSelector from 're-reselect';

import { StoreState } from '@store';
import { getTagsState } from '@store/tags';

import { TagsEditorState as State, TagsEditorInstanceState as InstanceState } from './types';

export const getTagsEditorState = (state: StoreState): State => state.tagsEditor;

export const getInstance = createCashedSelector(
    getTagsEditorState,
    (state: StoreState, id: string): string => id,
    (state: State, descriptor: string): InstanceState => state.instances[descriptor] || null,
)((state: StoreState, id: string): string => id);

export function shouldAddNewTagItem(state: StoreState, id: string): boolean {
    const instance = getInstance(state, id);
    const existingTags = getTagsState(state).entities;

    if (instance) {
        const { newTagInputValue } = instance;

        return newTagInputValue && !existingTags.some((tag) => tag.title === newTagInputValue);
    }

    return false;
}
