import { Success, Failure } from 'typescript-fsa';

import { TaskPageState, AddFileToTaskPayload, FetchError } from '../types';
import { FileAsset } from '../../commonTypes';

export const addFileToTaskDoneReducer = (
    { assetsIds: prevAssetsIds, ...restState }: TaskPageState,
    { result: fileAsset }: Success<AddFileToTaskPayload, FileAsset>,
): TaskPageState => {
    const assetsIds = prevAssetsIds.concat([name]);
    return {
        ...restState,
        assetsIds,
    };
};

export const addFileToTaskErrorReducer = (
    state: TaskPageState,
    payload: Failure<AddFileToTaskPayload, FetchError>,
): TaskPageState => {
    return state;
};

export const addFileToTaskStartedReducer = (state: TaskPageState, payload: AddFileToTaskPayload): TaskPageState => {
    return state;
};
