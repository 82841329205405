// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-brief-Header-_Header__root{margin-left:48px}.src-client-modules-brief-Header-_Header__center{display:flex;height:48px;align-items:center;justify-content:center}.src-client-modules-brief-Header-_Header__title{letter-spacing:0.15px;color:#000000;font-family:\"Open Sans\";font-size:16px;line-height:16px}.src-client-modules-brief-Header-_Header__right{display:flex;height:48px;align-items:center;justify-content:flex-end}.src-client-modules-brief-Header-_Header__buttonWrapper{flex:none;width:134px;height:26px;margin-right:12px}.src-client-modules-brief-Header-_Header__buttonWrapper:last-child{margin-right:0}.src-client-modules-brief-Header-_Header__quickAccessIcons{margin:0 21px 6px 0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/brief/Header/Header.scss"],"names":[],"mappings":"AAAA,+CACI,gBAAiB,CACpB,iDAGG,YAAa,CAEb,WAAY,CAEZ,kBAAmB,CACnB,sBAAuB,CAC1B,gDAGG,qBAAsB,CAEtB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACpB,gDAGG,YAAa,CAEb,WAAY,CAEZ,kBAAmB,CACnB,wBAAyB,CAC5B,wDAIG,SAAU,CAEV,WAAY,CACZ,WAAY,CACZ,iBAAkB,CALtB,mEAQQ,cAAe,CAClB,2DAID,mBAAoB","sourcesContent":[".root {\n    margin-left: 48px;\n}\n\n.center {\n    display: flex;\n\n    height: 48px;\n\n    align-items: center;\n    justify-content: center;\n}\n\n.title {\n    letter-spacing: 0.15px;\n\n    color: #000000;\n\n    font-family: \"Open Sans\";\n    font-size: 16px;\n    line-height: 16px;\n}\n\n.right {\n    display: flex;\n\n    height: 48px;\n\n    align-items: center;\n    justify-content: flex-end;\n}\n\n\n.buttonWrapper {\n    flex: none;\n\n    width: 134px;\n    height: 26px;\n    margin-right: 12px;\n\n    &:last-child {\n        margin-right: 0;\n    }\n}\n\n.quickAccessIcons {\n    margin: 0 21px 6px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-brief-Header-_Header__root",
	"center": "src-client-modules-brief-Header-_Header__center",
	"title": "src-client-modules-brief-Header-_Header__title",
	"right": "src-client-modules-brief-Header-_Header__right",
	"buttonWrapper": "src-client-modules-brief-Header-_Header__buttonWrapper",
	"quickAccessIcons": "src-client-modules-brief-Header-_Header__quickAccessIcons"
};
export default ___CSS_LOADER_EXPORT___;
