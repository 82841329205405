import * as React from 'react';
import classnames from 'classnames';
import { Icon, IconType, WithTooltip, WithGlobalPopup, AbsoluteLikePositionCalculator } from 'sber-marketing-ui';

import { Utils } from '@common/Utils';
import { useTextOverflowCheck } from '@common/hooks';
import { VirtualizedList } from '@common/VirtualizedList';
import { SearchInput } from '@common/SearchInput';

import * as styles from './WithDropdown.scss';

export type ItemId = string | number;

export interface DropdownItemParams {
    id: ItemId;
    title: ItemId;
}

interface Props {
    title: ItemId;
    items: DropdownItemParams[];
    selectedItemId: ItemId | ItemId[];
    singleItemSelect?: boolean;
    width?: number;
    onItemSelect: (itemId: ItemId) => void;
}

function useInteractivity() {
    const childrenWrapperRef = React.useRef<HTMLDivElement>();

    const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);

    function onClick() {
        setIsDropdownVisible(true);
    }

    function closeDropdown() {
        setIsDropdownVisible(false);
    }

    return {
        childrenWrapperRef,
        isDropdownVisible,
        onClick,
        closeDropdown,
    };
}

const WIDTH_MARGIN = 50;

export function WithDropdown({
    title,
    items,
    selectedItemId,
    singleItemSelect,
    width,
    onItemSelect,
}: Props): JSX.Element {
    const { childrenWrapperRef, isDropdownVisible, onClick, closeDropdown } = useInteractivity();

    function onItemSelectHandler(itemId: string) {
        onItemSelect(itemId);

        if (singleItemSelect) {
            closeDropdown();
        }
    }

    const dropdownWidth = width || (childrenWrapperRef.current?.clientWidth || 0) + WIDTH_MARGIN;

    return (
        <div
            {...{
                'qa-id': 'tagsPageWithDropdown',
            }}
        >
            <div
                ref={childrenWrapperRef}
                onClick={onClick}
                className={styles.children}
                {...{
                    'qa-id': 'tagsPageWithDropdownTitle',
                }}
            >
                {title}

                <Icon
                    type={IconType.EXPAND_ICON}
                    svgSize={14}
                    className={classnames(styles.expandIcon, isDropdownVisible && styles.expandIconActive)}
                />
            </div>

            {isDropdownVisible && (
                <WithGlobalPopup
                    container={childrenWrapperRef}
                    positionCalculator={AbsoluteLikePositionCalculator}
                    onMaskClick={closeDropdown}
                >
                    <Dropdown
                        width={dropdownWidth}
                        items={items}
                        selectedItemId={selectedItemId}
                        onItemSelect={onItemSelectHandler}
                    />
                </WithGlobalPopup>
            )}
        </div>
    );
}

interface DropdownProps {
    width: number;
    items: DropdownItemParams[];
    selectedItemId: ItemId | ItemId[];
    onItemSelect: (itemId: ItemId) => void;
}

function Dropdown({ width, items, selectedItemId, onItemSelect }: DropdownProps): JSX.Element {
    const [searchInput, setSearchInput] = React.useState('');
    const addSearch = items.length > 10;

    const filteredItems = React.useMemo(() => {
        if (searchInput) {
            const searchInputRegexp = new RegExp(Utils.escapeRegExp(searchInput), 'i');

            return items.filter((item) => `${item.title}`.match(searchInputRegexp));
        }

        return items;
    }, [items, searchInput]);

    return (
        <div
            className={styles.dropdown}
            style={{ width }}
            {...{
                'qa-id': 'tagsEditorWithDropdownDropdown',
            }}
        >
            {addSearch ? (
                <div className={styles.dropdownSearch}>
                    <SearchInput
                        qaId="tagsEditorWithDropdownSearchInout"
                        value={searchInput}
                        onChange={setSearchInput}
                    />
                </div>
            ) : null}

            {filteredItems.length ? (
                <VirtualizedList
                    items={filteredItems}
                    width={width}
                    rowHeight={25}
                    itemRenderer={(item) => (
                        <DropdownItem
                            {...item}
                            key={item.id}
                            selectedItemId={selectedItemId}
                            onItemSelect={onItemSelect}
                        />
                    )}
                />
            ) : (
                <div className={styles.dropdownItem}>Теги не найдены</div>
            )}
        </div>
    );
}

interface DropdownItemProps extends DropdownItemParams {
    selectedItemId: ItemId | ItemId[];
    onItemSelect: (itemId: ItemId) => void;
}

function DropdownItem({ id, title, selectedItemId, onItemSelect }: DropdownItemProps): JSX.Element {
    const titleRef = React.useRef<HTMLSpanElement>();
    const { horizontal } = useTextOverflowCheck(titleRef);

    const isItemSelected = selectedItemId instanceof Array ? selectedItemId.includes(id) : selectedItemId === id;

    return (
        <div
            className={styles.dropdownItem}
            onClick={isItemSelected ? null : () => onItemSelect(id)}
            {...{
                'qa-id': 'tagsEditorWithDropdownItem',
                'qa-is-selected': isItemSelected ? 'true' : 'false',
            }}
        >
            <WithTooltip spanWrapper hidden={!horizontal} content={title}>
                <span
                    ref={titleRef}
                    className={classnames(styles.dropdownItemTitle, horizontal && styles.dropdownItemTitleOverflow)}
                >
                    {title}
                </span>
            </WithTooltip>

            {isItemSelected && <Icon type={IconType.CHECK_ICON} className={styles.dropdownItemSelectedIcon} />}
        </div>
    );
}
