import * as React from 'react';
import classnames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { WithTooltip, TooltipAnchor, CloseButton, StaticSkeleton } from 'sber-marketing-ui';

import { useTextOverflowCheck } from '@common/hooks';

import * as styles from './HeaderWithOverflowCheck.scss';

interface Props {
    qaId: string;
    showPreloader: boolean;
    sourceLink?: string;
    content: string;
    onCloseButtonClick?: () => void;
}

export function HeaderWithOverflowCheck(props: Props): JSX.Element {
    return props.showPreloader ? <Preloader /> : <HeaderWithOverflowCheckContent {...props} />;
}

function HeaderWithOverflowCheckContent({ qaId, sourceLink, content, onCloseButtonClick }: Props): JSX.Element {
    const ref = React.useRef<HTMLDivElement>();
    const { horizontal: overflow } = useTextOverflowCheck(ref);

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': qaId,
                'qa-with-link': sourceLink ? 'true' : 'false',
            }}
        >
            <div className={styles.contentWrapper}>
                <Link to={sourceLink}>
                    <span
                        className={classnames(sourceLink ? styles.openSourceLinkText : styles.openSourceLinkTextShort)}
                    >
                        <WithTooltip spanWrapper hidden={!overflow} content={content} anchor={TooltipAnchor.LEFT}>
                            <span className={styles.content}>
                                <div
                                    ref={ref}
                                    className={classnames(styles.overflow, overflow && styles.overflowActive)}
                                >
                                    {content}
                                </div>
                            </span>
                        </WithTooltip>
                    </span>
                </Link>

                {onCloseButtonClick && (
                    <div className={styles.closeButton}>
                        <CloseButton size={24} onClick={onCloseButtonClick} />
                    </div>
                )}
            </div>
        </div>
    );
}

interface LinkProps {
    to: string;
    children: JSX.Element;
}

function Link({ to, children }: LinkProps): JSX.Element {
    return to ? (
        <RouterLink
            className={classnames(styles.openSourceLink, styles.openSourceLinkActive, styles.openSourceLinkShort)}
            to={to}
        >
            {children}
        </RouterLink>
    ) : (
        <span className={styles.openSourceLink}>{children}</span>
    );
}

function Preloader(): JSX.Element {
    return (
        <div className={styles.root}>
            <StaticSkeleton loadingClassName={styles.preloader} />
        </div>
    );
}
