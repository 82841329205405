import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';

import { useDeleteTaskFileMutation, useGetTaskQuery } from '@api';

import { FileData } from '@modules/files/types';

import { TaskAccessAction, useTaskAccess } from '@modules/task/hooks';

import { FileTag, FileTagLoader } from '@modules/files/components';
import { AddTaskFileButton, TaskFilesGallery } from '@modules/task/components';

import * as styles from './TaskFiles.scss';
import { Flex, FlexProps } from '@common/components';

export interface TaskFilesProps extends FlexProps {
    taskId: string;
    activityId?: number;
}

export function TaskFiles({ className, taskId, activityId, loading, children, ...props }: TaskFilesProps) {
    const [viewFile, setViewFile] = React.useState<FileData>();
    const { data: task, isLoading: isTaskLoading } = useGetTaskQuery({ id: taskId });
    const [deleteFile] = useDeleteTaskFileMutation();
    const files: FileData[] = [...(task?.task.files || [])]
        .reverse()
        .map((file) => ({ ...file, containerName: taskId, parent: 'task' }));
    const {
        isLoading: isAccessLoading,
        access: [canAdd, canRemove, canEdit],
    } = useTaskAccess(
        [TaskAccessAction.addFile, TaskAccessAction.removeFile, TaskAccessAction.editFile],
        taskId,
        activityId,
    );
    const isLoading = loading || isAccessLoading;

    const handleRemove = (fileId: string) => {
        deleteFile({
            taskId,
            fileId,
        });
    };

    return (
        <Flex vertical gap={6} {...props} loading={isLoading} className={classNames(styles.root, className)}>
            {canAdd && (
                <AddTaskFileButton taskId={taskId} className={styles.upload}>
                    <Icon svgSize={14} type={IconType.SCRAPER} />
                    Загрузить файлы
                </AddTaskFileButton>
            )}
            {isTaskLoading && <FileTagLoader />}
            {files.map((file: FileData) => (
                <FileTag
                    editable={canEdit}
                    removable={canRemove}
                    onRemove={() => handleRemove(file.id)}
                    onView={() => setViewFile(file)}
                    key={file.id}
                    file={file}
                />
            ))}
            <TaskFilesGallery taskId={taskId} files={files} file={viewFile} onShowFile={setViewFile} />
        </Flex>
    );
}
