import * as React from 'react';

import { ColumnName } from '@store/budgetExecution';

import { Header } from './Header';
import { List } from './List';

import * as styles from './styles.scss';

interface Props {
    columnNames: ColumnName[];
    onResetButtonClick: () => void;
}

export const ContentTemplate: React.FC<Props> = ({ columnNames = [], onResetButtonClick = () => {} }) => {
    return (
        <div className={styles.root}>
            <Header count={columnNames.length || 0} onResetButtonClick={onResetButtonClick} />
            <List columnNames={columnNames} />
        </div>
    );
};
