import * as moment from 'moment';
import { stubArray, stubString, without, includes, difference, isEmpty } from 'lodash';
import { v4 as uuidV4 } from 'uuid';

import { resetableReducerWithInitialState, stubNull, replaceReducer } from '../../common/utils';
import {
    editByTaskData,
    editId,
    editTitle,
    editWorkTypeId,
    editDepartmentId,
    editDeadline,
    editAuthorId,
    editExecutorId,
    editDescription,
    editStatus,
    addParticipantId,
    bulkAddParticipantsIds,
    removeParticipantId,
    bulkRemoveParticipantsIds,
    resetTaskEditor,
    resetDeadline,
    setTaskStageId,
} from '../actions';
import { resetStore } from '../../common/actions';

export const id = resetableReducerWithInitialState<string>(uuidV4, resetStore, resetTaskEditor)
    .case(editByTaskData, (_, { id }) => id)
    .case(editId, replaceReducer);

export const title = resetableReducerWithInitialState<string>(stubString, resetStore, resetTaskEditor)
    .case(editByTaskData, (_, { title }) => title)
    .case(editTitle, replaceReducer);

export const workTypeId = resetableReducerWithInitialState<string | null>(stubNull, resetStore, resetTaskEditor)
    .case(editByTaskData, (_, { workTypeId }) => workTypeId)
    .case(editWorkTypeId, replaceReducer);

export const departmentId = resetableReducerWithInitialState<string | null>(stubNull, resetStore, resetTaskEditor)
    .case(editByTaskData, (_, { departmentExecutorId }) => departmentExecutorId)
    .case(editDepartmentId, replaceReducer);

export const deadline = resetableReducerWithInitialState<moment.Moment>(
    moment(),
    resetStore,
    resetTaskEditor,
    resetDeadline,
)
    .case(editByTaskData, (_, { deadline }) => moment(deadline))
    .case(editDeadline, replaceReducer);

export const authorId = resetableReducerWithInitialState<number | null>(stubNull, resetStore, resetTaskEditor)
    .case(editByTaskData, (_, { authorId }) => authorId)
    .case(editAuthorId, replaceReducer);

export const executorId = resetableReducerWithInitialState<number | null>(stubNull, resetStore, resetTaskEditor)
    .case(editByTaskData, (_, { executorId }) => executorId)
    .case(editExecutorId, replaceReducer);

export const description = resetableReducerWithInitialState<string>(stubString, resetStore, resetTaskEditor)
    .case(editByTaskData, (_, { description }) => description)
    .case(editDescription, replaceReducer);

export const status = resetableReducerWithInitialState<string>(stubNull, resetStore, resetTaskEditor)
    .case(editByTaskData, (_, { status }) => status)
    .case(editStatus, replaceReducer);

export const stageId = resetableReducerWithInitialState<string>(stubNull, resetStore, resetTaskEditor).case(
    setTaskStageId,
    (_, stageId) => stageId,
);

export const participantsIds = resetableReducerWithInitialState<number[]>(stubArray, resetStore, resetTaskEditor)
    .case(editByTaskData, (_, { participantIds, authorId, executorId }) =>
        without(participantIds, authorId, executorId),
    )
    .case(addParticipantId, (participantsIds, payload) =>
        includes(participantsIds, payload) ? participantsIds : [...participantsIds, payload],
    )
    .case(bulkAddParticipantsIds, (participantsIds, payload) => {
        const newIds = difference(participantsIds, payload);
        return isEmpty(newIds) ? participantsIds : [...participantsIds, ...newIds];
    })
    .case(removeParticipantId, (participantsIds, payload) =>
        includes(participantsIds, payload) ? without(participantsIds, payload) : participantsIds,
    )
    .case(bulkRemoveParticipantsIds, (participantsIds, payload) => {
        const removeIds = difference(payload, participantsIds);
        return isEmpty(removeIds) ? participantsIds : without(participantsIds, ...removeIds);
    });
