import * as React from 'react';
import * as style from './SerialNumber.scss';

export const SerialNumber: React.FC = ({ children }) => {
    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'dashboardRightSidebarBudgetInfoCorrectionCardSerialNumber',
            }}
        >
            {children}
        </div>
    );
};
