import * as React from 'react';
import onClickOutside from 'react-onclickoutside';
import autobind from 'autobind-decorator';

import { DashboardUrlEditorTemplate } from './DashboardUrlEditorTemplate';

interface Props {
    value: string;
    onSave: (value: string) => void;
}

interface State {
    inputValue: string;
    isOpened: boolean;
}

@(onClickOutside as any)
export class DashboardUrlEditorBehaviour extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            inputValue: props.value,
            isOpened: false,
        };
    }

    public render(): JSX.Element {
        return React.createElement(DashboardUrlEditorTemplate, {
            isOpened: this.state.isOpened,
            inputValue: this.state.inputValue,
            onOpenerClick: this.onOpenerClick,
            onInputEdit: this.onInputEdit,
            onCopyButtonClick: this.onCopyButtonClick,
            onSaveButtonClick: this.onSaveButtonClick,
        });
    }

    @autobind
    protected handleClickOutside() {
        this.setState({
            isOpened: false,
        });
    }

    @autobind
    private onOpenerClick() {
        const { value } = this.props;
        const { isOpened, inputValue } = this.state;

        this.setState({
            isOpened: !isOpened,
            inputValue: isOpened ? inputValue : value,
        });
    }

    @autobind
    private onInputEdit(value: string) {
        this.setState({
            inputValue: value,
        });
    }

    @autobind
    private onCopyButtonClick() {
        this.copyTextToClipboard(this.state.inputValue);
    }

    @autobind
    private onSaveButtonClick() {
        this.props.onSave(this.state.inputValue);
    }

    private copyTextToClipboard(text: string) {
        navigator.clipboard.writeText(text);
    }
}
