import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as style from './ContextStageActions.scss';

import { Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';

import type { StoreState } from '@store';
import { AutopilotStage } from '@store/autopilot/types';
import { setCurrentStage } from '@store/autopilot/actions';
import { canPushStageStatuses } from '@store/autopilot/selectors';

interface Props {
    canEdit: boolean;
}

export const ContextStageActions: React.FC<Props> = (): JSX.Element => {
    const dispatch = useDispatch();

    const disabled = useSelector((state: StoreState) => !canPushStageStatuses(state));

    const onContinueButtonClick = React.useCallback(async () => {
        dispatch(setCurrentStage(AutopilotStage.CreativeStatuses));
    }, []);

    return (
        <div className={style.root}>
            <div className={style.title}>
                Создайте тексты для контекстной рекламы и отправьте их на размещение&nbsp;
            </div>

            <div className={style.button}>
                <Button disabled={disabled} theme={ButtonTheme.MainRounded} onClick={onContinueButtonClick}>
                    К Статусам
                </Button>
            </div>
        </div>
    );
};
