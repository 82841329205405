import { CustomColumnColor } from '@store/budgetPlanning/types';

import { CellBackgroundColor } from '../LayerManager';

interface QaCellColor {
    'qa-color'?: string;
}

export function getQaCellColor(color: CellBackgroundColor | CustomColumnColor): QaCellColor {
    if (!color) {
        return {};
    }

    let qaColor: string;
    switch (color) {
        case CellBackgroundColor.UnsavedChange:
            qaColor = 'UnsavedChange';
            break;
        case CustomColumnColor.CurrencySum:
            qaColor = 'currencySum';
            break;
        default:
            console.warn(`Missing case for CellBackgroundColor.${color}`);
            return {};
    }

    return { 'qa-color': qaColor };
}
