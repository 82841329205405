import { FundsDTO as Funds } from '@mrm/budget/Funds';
import { MentionItem } from 'sber-marketing-ui';

export interface PageState {
    pageData?: PageData;
    selectedCard: SelectedCard;
    userRoleFilter: UserRoleFilter;
    cardsLoadingModeFilter: CardsLoadingModeFilter;
    rightSidebarStates: RightSidebarState;
}

export interface PageData {
    mentionableUsers: MentionItem[];
}

export interface SelectedCard {
    cardType: CardType;
    id: string;
    activityId: React.ReactText;
}

export interface BudgetItem {
    id: string;
    serialNumber: number;
    plannedFunds: Funds;
    reservedFunds: Funds;
    factFunds: Funds;
    dictionary: {
        tool: {
            value: string;
        };
    };
}

export const enum CardType {
    BudgetItemToProjectLinkRequest = 'BudgetItemToProjectLinkRequest',
}

export type UserRoleFilter = Partial<{ [roleType in UserRole]: boolean }>;

export const enum UserRole {
    Author = 'author',
    Participant = 'participant',
    Responsible = 'responsible',
    Supervisor = 'supervisor',
}

export type CardsLoadingModeFilter = Partial<{ [cardsType in CardType]: CardsLoadingMode }>;

export const enum CardsLoadingMode {
    Current = 'current',
    Archive = 'archive',
}

export interface RightSidebarState {
    [CardType.BudgetItemToProjectLinkRequest]: {
        requestIdForStatusCheck: string;
        cardIdRequestInProgress: string;
    };
}
