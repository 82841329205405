export type { AddCommentarySuccess } from './AddCommentarySuccess';
export type { AddFileToCommentaryPayload } from './AddFileToCommentaryPayload';
export type { AddFileToNewCommentaryPayload } from './AddFileToNewCommentaryPayload';
export type { AddFileToTaskPayload } from './AddFileToTaskPayload';
export { AllAssetsSortOrder } from './AllAssetsSortOrder';
export type { ChangeExecutorPayload } from './ChangeExecutorPayload';
export type { Commentary } from './Commentary';
export { CommentEditionType } from './CommentEditionType';
export type { CreateNewTaskPayload } from './CreateNewTaskPayload';
export type { Department } from './Department';
export type { EditCommentaryPayload } from './EditCommentaryPayload';
export type { EditNewCommentaryPayload } from './EditNewCommentaryPayload';
export type { EditTaskPayload } from './EditTaskPayload';
export type { ExecutionOrder } from './ExecutionOrder';
export { FetchError } from './FetchError';
export { FetchTelegramError } from './FetchTelegramError';
export type { LoadEditorDataSuccess } from './LoadEditorDataSuccess';
export type { LoadTaskPayload } from './LoadTaskPayload';
export type { LoadTaskSuccess } from './LoadTaskSuccess';
export type { RemoveCommentaryAssetPayload } from './RemoveCommentaryAssetPayload';
export type { RemoveCommentaryPayload } from './RemoveCommentaryPayload';
export type { RemoveFileFromCommentaryPayload } from './RemoveFileFromCommentaryPayload';
export type { RemoveFileFromNewCommentaryPayload } from './RemoveFileFromNewCommentaryPayload';
export type { RemoveFileFromTaskPayload } from './RemoveFileFromTaskPayload';
export type { RemoveNewTaskAssetPayload } from './RemoveNewTaskAssetPayload';
export type { RemoveParticipatorPayload } from './RemoveParticipatorPayload';
export type { SaveCommentaryPayload } from './SaveCommentaryPayload';
export { SortOrder } from './SortOrder';
export type { TaskInfo } from './TaskInfo';
export type { TelegramInfo } from './TelegramInfo';
export { TelegramUserStatus } from './TelegramInfo';
export type { TaskLoadingError } from './TaskLoadingError';
export { TaskLoadingStatus } from './TaskLoadingStatus';
export type { TaskPageState } from './TaskPageState';
export type { User } from './User';
export type { WorkType } from './WorkType';
export type { Channel } from './Channel';
export type { UpdateChannelPayload } from './UpdateChannelPayload';
export type { SetCommentaryIsLoadingPayload } from './SetCommentaryIsLoadingPayload';
export type { AddNewTaskParticipatorPayload } from './AddNewTaskParticipatorPayload';
export type { BulkAddParticipatorsStartPayload } from './BulkAddParticipatorsStartPayload';
export type { PlugTelegramSuccess } from './PlugTelegramSuccess';
export type { PlugTelegramPayload } from './PlugTelegramPayload';
export type { UnplugTelegramSuccess } from './UnplugTelegramSuccess';
export type { UnplugTelegramPayload } from './UnplugTelegramPayload';
export type { UpdateTelegramSuccess } from './UpdateTelegramSuccess';
export type { UpdateTelegramPayload } from './UpdateTelegramPayload';
export type { SetRatePayload } from './SetRatePayload';
export type { SetRateSuccess } from './SetRateSuccess';
export type { DeleteChannelPayload } from './DeleteChannelPayload';

export interface SwitchKeyActivityParams {
    id: number;
    isKey: boolean;
}

export interface ToggleCommentReactionPayload {
    taskId: string;
    commentId: string;
    reaction: string;
}
