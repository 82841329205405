import * as React from 'react';

import * as style from './StageActions.scss';

import { Icon, IconType, Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';
import { WithTooltip } from './WithTooltip';

const BUTTON_TITLES_BY_STAGE = {
    [1]: 'Продолжить',
    [1.5]: 'Отправить в агентство',
    [2]: 'Отправить на проверку',
    [3]: 'Отправить на согласование',
    [4]: 'Закрыть заявку на креатив',
};

const TITLE_BY_STAGE = {
    [1]: 'Заполните поля',
    [1.5]: 'Создайте необходимое количество строк-креативов и отправьте исполнителю в агентство',
    [2]: 'Заполните данные в таблице, приложите смету и другие документы, отправьте на проверку заказчику',
    [3]: 'Проверьте данные в таблице, смете и вложенных документах, отправьте на согласование',
    [4]: 'Проверьте данные в таблице, смете и вложенных документах, и согласуйте строки заявки на креатив',
    [5]: 'Заявка закрыта',
};

interface Props {
    creativeRequestId: string;
    fieldsCount: number;
    filledFieldsCount: number;
    currentStage: number;
    visibilityButton: boolean;
    disabledButton: boolean;
    onButtonClick: () => void;
}

export const StageActionsTemplate = ({
    creativeRequestId,
    fieldsCount,
    filledFieldsCount,
    currentStage,
    visibilityButton,
    disabledButton,
    onButtonClick,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.title}>
                {TITLE_BY_STAGE[currentStage]}

                {filledFieldsCount === fieldsCount && currentStage === 1 && (
                    <div className={style.titleIcon}>
                        <Icon type={IconType.ACCEPT_ACTIVE} svgSize={24} />
                    </div>
                )}

                {filledFieldsCount < fieldsCount && (
                    <>
                        &nbsp;
                        <span className={style.counter}>
                            ({filledFieldsCount}/{fieldsCount})
                        </span>
                    </>
                )}
            </div>

            {currentStage < 5 && visibilityButton && (
                <WithTooltip currentStage={currentStage} creativeRequestId={creativeRequestId}>
                    <div className={style.button}>
                        <Button
                            disabled={filledFieldsCount < fieldsCount || disabledButton}
                            theme={ButtonTheme.MainRounded}
                            onClick={onButtonClick}
                        >
                            {BUTTON_TITLES_BY_STAGE[currentStage]}
                        </Button>
                    </div>
                </WithTooltip>
            )}
        </div>
    );
};
