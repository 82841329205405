// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-BudgetItemHistoryWidget-_BudgetItemHistoryWidget__root{padding:16px 24px}.src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-BudgetItemHistoryWidget-_BudgetItemHistoryWidget__item{margin-bottom:12px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetPlanning/LineModal/WidgetsGroups/BudgetItemHistoryWidget/BudgetItemHistoryWidget.scss"],"names":[],"mappings":"AAAA,oIACI,iBAAkB,CACrB,oIAGG,kBAAmB","sourcesContent":[".root {\n    padding: 16px 24px;\n}\n\n.item {\n    margin-bottom: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-BudgetItemHistoryWidget-_BudgetItemHistoryWidget__root",
	"item": "src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-BudgetItemHistoryWidget-_BudgetItemHistoryWidget__item"
};
export default ___CSS_LOADER_EXPORT___;
