import { actionCreatorFactory } from 'typescript-fsa';
import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type { Autopilot, Autopilot2, AutopilotTV, AutopilotRadio, AutopilotDOOH } from 'sber-marketing-types/backend';

import { SwitchKeyActivityParams } from '../types';

const creator = actionCreatorFactory('ACTIVITY_PAGE_ASYNC').async;

export const loadActivity = creator<number, Activity, Error>('LOAD_ACTIVITY_ASYNC');
export const loadAutopilotDOOH = creator<number, AutopilotDOOH, Error>('LOAD_AUTOPILOT_DOOH_ASYNC');
export const loadAutopilotRadio = creator<number, AutopilotRadio, Error>('LOAD_AUTOPILOT_RADIO_ASYNC');
export const loadAutopilotTv = creator<number, AutopilotTV, Error>('LOAD_AUTOPILOT_TV_ASYNC');
export const loadAutopilot = creator<number, Autopilot2, Error>('LOAD_AUTOPILOT_ASYNC');
export const loadAutopilotLegacy = creator<number, Autopilot, Error>('LOAD_AUTOPILOT_LEGACY_ASYNC');
export const switchKeyActivity = creator<SwitchKeyActivityParams, void, Error>('SWITCH_KEY_ACTIVITY_ASYNC');
export const resetActivityPage = creator<null, void, Error>('RESET_ACTIVITY_PAGE');
