import { ScrollWatcher } from './ScrollWatcher';

/** Page scroll watcher */
export class PageScrollWatcher extends ScrollWatcher {
    /** Page content element identifier */
    public static readonly PAGE_CONTENT_ID: string = 'pageContent';
    /** */
    public constructor() {
        super(PageScrollWatcher.PAGE_CONTENT_ID);
    }
}
