import * as React from 'react';

import { Card } from '@common/components';

import * as styles from './TaskNotFound.scss';

export function TaskNotFound() {
    return (
        <div className={styles.root}>
            <Card padding={[24, 32]}>
                <h2>Данная задача не существует</h2>
            </Card>
        </div>
    );
}
