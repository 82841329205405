import * as React from 'react';
import reactOnclickoutside from 'react-onclickoutside';
import autobind from 'autobind-decorator';
// import * as lodash from 'lodash';

import { SuggestTemplate, SuggestItem } from './SuggestTemplate';

const MAX_ITEMS_DISPLAYED_COUNT = 8;
const ITEM_HEIGHT = 36;

export interface Props {
    title: string;
    sign?: string;
    value: string;
    items: SuggestItem[];
    onValueChange: (value: string) => void;
    disabled?: boolean;
}

interface State {
    isSuggestOpened: boolean;
}

@(reactOnclickoutside as any)
export class SuggestBehaviour extends React.Component<Props, State> {
    public input: any = null;

    constructor(props: Props) {
        super(props);

        this.state = {
            isSuggestOpened: false,
        };
    }

    public render(): JSX.Element {
        return React.createElement(SuggestTemplate, {
            title: this.props.title,
            sign: this.props.sign,
            value: this.props.value,
            items: this.props.items,
            disabled: this.props.disabled,
            suggestHeight: this.getSuggestHeight(),
            isSuggestOpened: this.state.isSuggestOpened,
            inputRef: this.inputRef,
            onInputFocus: this.onInputFocus,
            onValueChange: this.props.onValueChange,
            onItemClick: this.onItemClick,
        });
    }

    @autobind
    public handleClickOutside() {
        this.setState({
            isSuggestOpened: false,
        });
    }

    @autobind
    protected inputRef(element: HTMLInputElement) {
        this.input = element;
    }

    @autobind
    protected onInputFocus() {
        this.setState({
            isSuggestOpened: true,
        });
    }

    @autobind
    protected onValueChange(value: string) {
        this.setState({
            isSuggestOpened: false,
        });

        this.props.onValueChange(value);
    }

    @autobind
    protected onItemClick(value: React.ReactText) {
        this.setState({
            isSuggestOpened: false,
        });

        this.props.onValueChange(value.toString());
    }

    private getSuggestHeight() {
        return this.props.items.length >= MAX_ITEMS_DISPLAYED_COUNT
            ? ITEM_HEIGHT * MAX_ITEMS_DISPLAYED_COUNT
            : ITEM_HEIGHT * this.props.items.length;
    }
}
