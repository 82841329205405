/** Common loading status */
export const enum LoadingStatus {
    /** Entity not loaded yet */
    NOT_LOADED = 'not_loaded',
    /** Entity loading in progress */
    LOADING = 'loading',
    /** Entity successfully loaded */
    LOADED = 'loaded',
    /** Entity loading ended with error */
    ERROR = 'error',
}
