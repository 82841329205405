import * as React from 'react';
import autobind from 'autobind-decorator';
import { positionValues } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { BudgetItemSnapshot, BudgetItem } from '@mrm/budget';

import { BudgetItemApi } from '@api';

import { StoreState } from '@store';
import { getBudgetItemByLineId } from '@store/budgetPlanning';

import { BudgetItemHistoryWidgetTemplate } from './BudgetItemHistoryWidgetTemplate';

const limit = 10;

interface Props extends OwnProps, Partial<MapProps> {}

interface OwnProps {
    budgetItemId: string;
}

interface MapProps {
    budgetItem: BudgetItem;
}

interface State {
    isLoading: boolean;
    canLoadMore: boolean;
    items: BudgetItemSnapshot[];
}

@(connect(mapStateToProps) as any)
export class BudgetItemHistoryWidgetConnector extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            canLoadMore: true,
            items: [props.budgetItem],
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.fetchMoreData();
    }

    public render(): JSX.Element {
        return <BudgetItemHistoryWidgetTemplate {...this.state} onScroll={this.onScroll} />;
    }

    @autobind
    private onScroll({ scrollTop, scrollHeight, clientHeight }: positionValues): void {
        const isScrolledDown = clientHeight === scrollHeight - scrollTop;

        if (isScrolledDown && this.state.canLoadMore) {
            this.fetchMoreData();
        }
    }

    private async fetchMoreData(): Promise<void> {
        const { budgetItemId } = this.props;

        await new Promise<void>((resolve) => this.setState({ isLoading: true }, resolve));

        const offset = this.state.items.length - 1; // skip first item (currentBudgetItem)

        const items = await BudgetItemApi.getBudgetItemSnapshots(budgetItemId, {
            id: budgetItemId,
            limit,
            offset,
            sort: '"creationTime:desc"' as any,
            columns: [
                'id',
                'responsibles',
                'activity',
                'dictionary',
                'comment',
                'sapComment',
                'sapNumber',
                'sapZns',
                'realizationStart',
                'realizationEnd',
                'businessTarget',
                'customerName',
                'previousFunds',
                'plannedFunds',
                'status',
                'expertComment',
                'creationTime',
                'initiator',
            ],
        });

        this.setState((state) => ({
            isLoading: false,
            canLoadMore: items.length === limit,
            items: [...state.items, ...items],
        }));
    }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): MapProps {
    return {
        budgetItem: getBudgetItemByLineId(state, ownProps.budgetItemId),
    };
}
