import * as React from 'react';
import classnames from 'classnames';
import { Handle, Position, NodeProps } from 'react-flow-renderer';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, IconType } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { TagGroup, TagGroupColors, getTagsPageState, toggleSelectedNode } from '@store/tagsPage';

import * as styles from './CustomNode.scss';

interface NodeData {
    group: TagGroup;
    size: number;
    label: string;
}

interface Props extends NodeProps<NodeData> {}

function useInteractivity(props: Props) {
    const {
        id,
        data: { group },
    } = props;
    const dispatch = useDispatch();

    const selectedNode = useSelector((state: StoreState) => getTagsPageState(state).selectedNode);
    const isSelected = selectedNode && selectedNode.id === id && selectedNode.group === group;

    function onClick() {
        if (group !== TagGroup.Tag) {
            dispatch(toggleSelectedNode(isSelected ? null : { id, group }));
        }
    }

    return { isSelected, onClick };
}

export function CustomNode(props: Props): JSX.Element {
    const { group, size, label } = props.data;
    const { isSelected, onClick } = useInteractivity(props);

    return (
        <div
            onClick={onClick}
            className={styles.root}
            {...{
                'qa-id': 'tagsPageGraphCustomNode',
                'qa-type': group,
                'qa-is-selected': isSelected ? 'true' : 'false',
            }}
        >
            <Label isSelected={isSelected} group={group} label={label} />

            <div
                style={{
                    background: TagGroupColors[group],
                    width: `${size}px`,
                    height: `${size}px`,
                    top: `${-size + 1}px`,
                    left: `calc(50% - ${size / 2}px)`,
                }}
                className={styles.nodeBackground}
                {...{
                    'qa-id': 'tagsPageGraphCustonNodeCircle',
                }}
            >
                <Content group={group} size={size} />

                <Handle type="target" position={Position.Top} />

                <Handle type="source" position={Position.Bottom} />
            </div>
        </div>
    );
}

interface LabelProps extends Pick<NodeData, 'group' | 'label'> {
    isSelected: boolean;
}

function Label({ isSelected, group, label }: LabelProps): JSX.Element {
    let content: React.ReactNode;

    switch (group) {
        case TagGroup.Tag:
            content = <span className={styles.nodeLabelBold}>{label}</span>;
            break;
        case TagGroup.Project:
            content = (
                <span className={styles.nodeLabelBold}>
                    <Icon type={IconType.PROJECT_FLAG_ICON} svgSize={16} className={styles.nodeLabelIcon} />

                    {label}
                </span>
            );
            break;

        case TagGroup.Task:
            content = <span className={styles.nodeLabelRegular}>{label}</span>;
            break;
        case TagGroup.ExecutionId:
        case TagGroup.PlanId:
            content = (
                <span className={styles.nodeLabelRegular}>
                    <span
                        className={classnames(styles.nodeLabelSubText, isSelected && styles.nodeLabelSubTextSelected)}
                    >
                        ID&nbsp;
                    </span>
                    {label}
                </span>
            );
            break;
        default:
            console.warn(`Missing case-declaration for tagGroup ${group}`);
            break;
    }

    if (!content) {
        return null;
    }

    return (
        <div
            title={label}
            className={classnames(styles.nodeLabel, isSelected && styles.nodeLabelSelected)}
            style={{
                background: isSelected ? TagGroupColors[group] : 'white',
            }}
            {...{
                'qa-id': 'tagsPageGraphCustomNodeLabel',
            }}
        >
            {content}
        </div>
    );
}

type ContentProps = Pick<NodeData, 'group' | 'size'>;

function Content({ group, size }: ContentProps): JSX.Element {
    switch (group) {
        case TagGroup.Tag:
            return (
                <span className={styles.nodeContentText} style={{ fontSize: `${size / 2}px` }}>
                    #
                </span>
            );
        case TagGroup.Project:
        case TagGroup.Task:
        case TagGroup.ExecutionId:
        case TagGroup.PlanId:
            break;
        default:
            console.warn(`Missing case-declaration for tagGroup ${group}`);
            break;
    }

    return null;
}
