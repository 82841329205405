import * as React from 'react';
import { TaskCardParams } from 'sber-marketing-types/frontend';

export interface StageCalendarItem {
    id: React.ReactText;
    name: string;
    start?: Date;
    end?: Date;
    done: boolean;
}

export const enum STAGE_TYPE {
    PLANNED = 'planned',
    ALMOST_EXPIRED = 'almost_expired',
    EXPIRED = 'expired',
    FINISHED = 'finished',
}

export const STAGE_COLORS: { [stageType: string]: LineColor } = {
    [STAGE_TYPE.PLANNED]: { normal: null, hover: '#f8f8f8', bar: '#19bb4f' },
    [STAGE_TYPE.ALMOST_EXPIRED]: { normal: null, hover: '#f8f8f8', bar: '#f5a623' },
    [STAGE_TYPE.EXPIRED]: { normal: '#ffebee', hover: '#ffd8de', bar: '#e63900' },
    [STAGE_TYPE.FINISHED]: { normal: '#e9fff0', hover: '#d4ffe1', bar: '#a4c7f0' },
};

export enum TASKS_COLORS {
    RED = '#E63900',
    GREEN = '#19BB4F',
    GREY = '#7E8681',
    BLUE = '#417EC6',
}

export interface LineColor {
    normal: string;
    hover: string;
    bar: string;
}

export interface LineParams {
    id: React.ReactText;
    name: string;
    start: Date;
    end: Date;
    type: STAGE_TYPE;
    roundedTop?: boolean;
    roundedBottom?: boolean;
}

export interface TimeUnitGroup {
    title: string;
    items: TimeUnitItem[];
    daysCount: number;
}

export interface TimeUnitItem {
    title: string;
    daysCount: number;
}

export interface Point {
    x: number;
    y: number;
}

export enum Direction {
    Top = 'top',
    Right = 'right',
}

export interface TasksByStageId {
    [stageId: string]: TaskCardParams[];
}

export interface DateMark {
    date: Date;
    label?: string;
    isVisible: boolean;
    isMarkerVisible: boolean;
    color: DateMarkColors;
}

export enum DateMarkColors {
    Green = '#19BB4F',
    Grey = '#CCCCCC',
}
