// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-ActivitySidebar-tabs-BudgetsTab-_BudgetsTab__root{display:flex;flex-direction:column;gap:10px 0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/ActivitySidebar/tabs/BudgetsTab/BudgetsTab.scss"],"names":[],"mappings":"AAAA,6EACI,YAAa,CACb,qBAAsB,CACtB,UAAW","sourcesContent":[".root {\n    display: flex;\n    flex-direction: column;\n    gap: 10px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-ActivitySidebar-tabs-BudgetsTab-_BudgetsTab__root"
};
export default ___CSS_LOADER_EXPORT___;
