import { jsPDF } from 'jspdf';
import type { SidesSizes } from './types';

interface PageParams {
    sidesSizes: SidesSizes;
}

interface AddedCanvas {
    canvas: HTMLCanvasElement;
    position: Position;
    sidesSizes: SidesSizes;
}

interface Position {
    x: number;
    y: number;
}

export class PdfBuilder {
    private jsPDF: jsPDF;

    public addPage(params: PageParams): void {
        const { sidesSizes } = params;

        const format = [sidesSizes.width, sidesSizes.height];
        const orientation = this.defineOrientation(sidesSizes);

        if (this.jsPDF) {
            this.jsPDF.addPage(format, orientation);
        } else {
            this.jsPDF = new jsPDF(orientation, 'px', format);
        }

        this.drawPageBackground(sidesSizes);
    }

    public addCanvas({ canvas, position, sidesSizes }: AddedCanvas): void {
        this.jsPDF.addImage(canvas, 'JPEG', position.x, position.y, sidesSizes.width, sidesSizes.height);
    }

    public save(documentTitle: string): void {
        this.jsPDF.save(documentTitle);
    }

    private defineOrientation(sidesSizes: SidesSizes): 'landscape' | 'portrait' {
        return sidesSizes.width > sidesSizes.height ? 'landscape' : 'portrait';
    }

    private drawPageBackground(sidesSizes: SidesSizes): void {
        this.jsPDF.setFillColor('#f1f5f7').rect(0, 0, sidesSizes.width, sidesSizes.height, 'F');
    }
}
