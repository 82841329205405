import * as React from 'react';
import classNames from 'classnames';
import * as lodash from 'lodash';

import * as styles from './AllAssets.scss';

import { FileAsset, FileAssetProps, FileAssetData } from '../../../../../common/FileAsset';
import { AllAssetsTitle } from '../AllAssetsTitle';
import { Utils } from '../../../../../common/Utils';
import { AllAssetsSortOrder } from '@store/taskPage';

interface Props {
    /** "true" is must be used tiles icon for change display mode icon */
    isTiles: boolean;
    /** If "true" reversed letters icon will be showed */
    sortOrder: AllAssetsSortOrder;
    /** Assets to show */
    assets: FileAssetProps[];
    /** Count assets to show */
    assetsToShow: number;
    /** Number of hidden assets */
    moreCount: number;
    /** Additional CSS class */
    className?: string;
    /** Additional title */
    additionalTitle?: string;
    /** Change display mode button click handler */
    onChangeDisplayModeClick(): any;
    /** Sort assets button click handler */
    onSortAssetsClick(): any;
    /** Load more assets button click handler */
    onLoadMoreAssetsButtonClick(): any;
    /** Remove asset click handler */
    onRemoveAssetClick(data: FileAssetData): any;
}

export function AllAssets({
    onChangeDisplayModeClick,
    onSortAssetsClick,
    onLoadMoreAssetsButtonClick,
    onRemoveAssetClick,
    assets,
    isTiles,
    sortOrder,
    moreCount,
    assetsToShow,
    className,
    additionalTitle,
}: Props): JSX.Element {
    return (
        <div
            className={classNames(styles.root, className)}
            {...{
                'qa-id': 'taskSideFormAssets',
            }}
        >
            <AllAssetsTitle
                onChangeDisplayModeClick={onChangeDisplayModeClick}
                onSortAssetsClick={onSortAssetsClick}
                isTiles={isTiles}
                sortOrder={sortOrder}
                additionalTitle={additionalTitle}
            />

            <div className={styles.assetsList}>
                {lodash.times(
                    assetsToShow,
                    (index) =>
                        index < assets.length && (
                            <div className={styles.assetWrap} key={assets[index].name}>
                                <FileAsset
                                    className={styles.asset}
                                    onRemoveClick={onRemoveAssetClick}
                                    {...assets[index]}
                                />
                            </div>
                        ),
                )}
            </div>

            {moreCount > 0 && (
                <div className={styles.loadMoreButton} onClick={onLoadMoreAssetsButtonClick}>
                    {getLoadMoreButtonLabel(moreCount)}
                </div>
            )}
        </div>
    );
}

function getLoadMoreButtonLabel(moreCount: number): string {
    return `Еще ${moreCount} ${Utils.getDeclensionByNumber(moreCount, ['вложение', 'вложения', 'вложений'])}`;
}
