import { TagColorType, TagEmojiType } from '@mrm/tags';
import { TagPicture } from '@api';

export function clientToStoreColor(color: string): TagColorType {
    if (!color || color === 'transparent') {
        return null;
    }

    const colorMap = {
        [TagColorType.Color1]: TagColorType.Color1,
        [TagColorType.Color2]: TagColorType.Color2,
        [TagColorType.Color3]: TagColorType.Color3,
        [TagColorType.Color4]: TagColorType.Color4,
        [TagColorType.Color5]: TagColorType.Color5,
        [TagColorType.Color6]: TagColorType.Color6,
        [TagColorType.Color7]: TagColorType.Color7,
        [TagColorType.Color8]: TagColorType.Color8,
        [TagColorType.Color9]: TagColorType.Color9,
        [TagColorType.Color10]: TagColorType.Color10,
        [TagColorType.Color11]: TagColorType.Color11,
        [TagColorType.Color12]: TagColorType.Color12,
    };

    if (colorMap[color]) {
        return colorMap[color];
    }

    console.warn(`Unknown tagColor: ${color}`);

    return null;
}

export function clientToStoreEmoji(picture: TagPicture): TagEmojiType {
    if (!picture) {
        return null;
    }

    const emojiMap = {
        [TagEmojiType.Profile]: TagEmojiType.Profile,
        [TagEmojiType.Popup]: TagEmojiType.Popup,
        [TagEmojiType.Phone]: TagEmojiType.Phone,
        [TagEmojiType.Chain]: TagEmojiType.Chain,
        [TagEmojiType.Folder]: TagEmojiType.Folder,
        [TagEmojiType.Calendar]: TagEmojiType.Calendar,
        [TagEmojiType.Lighbulb]: TagEmojiType.Lighbulb,
        [TagEmojiType.Money]: TagEmojiType.Money,
        [TagEmojiType.Pencil]: TagEmojiType.Pencil,
        [TagEmojiType.Star]: TagEmojiType.Star,
        [TagEmojiType.Question]: TagEmojiType.Question,
        [TagEmojiType.Gear]: TagEmojiType.Gear,
        [TagEmojiType.PatternDiagonalStripes]: TagEmojiType.PatternDiagonalStripes,
        [TagEmojiType.PatternStars]: TagEmojiType.PatternStars,
        [TagEmojiType.PatternDashes]: TagEmojiType.PatternDashes,
        [TagEmojiType.PatternHorizontalStripes]: TagEmojiType.PatternHorizontalStripes,
        [TagEmojiType.PatternDots]: TagEmojiType.PatternDots,
        [TagEmojiType.PatternBackArrow]: TagEmojiType.PatternBackArrow,
    };

    let result: TagEmojiType = null;
    switch (picture.type) {
        case 'emoji':
            result = emojiMap[picture.text];
            break;
        case 'pattern':
            result = emojiMap[picture.name];
            break;
        default:
            break;
    }

    if (!result) {
        console.warn(`Unknows tagPucture: ${JSON.stringify(picture)}`);
    }

    return result;
}

export function storeToClientEmoji(emoji: TagEmojiType): TagPicture {
    if (!emoji) {
        return null;
    }

    switch (emoji) {
        case TagEmojiType.Profile:
        case TagEmojiType.Popup:
        case TagEmojiType.Phone:
        case TagEmojiType.Chain:
        case TagEmojiType.Folder:
        case TagEmojiType.Calendar:
        case TagEmojiType.Lighbulb:
        case TagEmojiType.Money:
        case TagEmojiType.Pencil:
        case TagEmojiType.Star:
        case TagEmojiType.Question:
        case TagEmojiType.Gear:
            return { type: 'emoji', text: emoji };
        case TagEmojiType.PatternDiagonalStripes:
        case TagEmojiType.PatternStars:
        case TagEmojiType.PatternDashes:
        case TagEmojiType.PatternHorizontalStripes:
        case TagEmojiType.PatternDots:
        case TagEmojiType.PatternBackArrow:
            return { type: 'pattern', name: emoji };
        default:
            console.warn(`Missing case-clause for TagEmojiType.${emoji}`);
            return null;
    }
}
