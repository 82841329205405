import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import autobind from 'autobind-decorator';

import { BudgetItemProps } from '../ActivitySuggestConnected';

import { ExistingNameItem } from './ExistingNameItem';

interface Props extends BudgetItemProps, Partial<RouteComponentProps<{}>> {
    pageIsInCopyFromExecutionMode: boolean;
    setActivityIsAlreadyExists: (activity: { id: string; name: string }) => void;
}

@(withRouter as any)
export class ExistingNameItemContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const {
            activity: { name },
            budget: { year: budgetYear },
        } = this.props;

        return <ExistingNameItem name={name} budgetYear={budgetYear} onClick={this.onClick} />;
    }

    @autobind
    private onClick(): void {
        const {
            pageIsInCopyFromExecutionMode,
            activity,
            budget: { id, dictionaryOrganizationId },
        } = this.props;

        if (pageIsInCopyFromExecutionMode) {
            this.props.setActivityIsAlreadyExists(activity);
        } else {
            this.props.history.push(
                `/budget/planning/${activity.id}?budgetId=${id}&dictionariesOrganizationId=${dictionaryOrganizationId}&addLine=true`,
            );
            this.props.history.go(0);
        }
    }
}
