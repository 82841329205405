import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';

import { OptionProps, OptionMultiple, OptionValues, OptionsChipProps, OptionsChip } from '@common/components';

import * as styles from './KebabMenu.scss';

export interface KebabMenuProps<
    M extends OptionMultiple,
    OP extends OptionProps<any>,
    O extends OP extends OptionProps<infer O1> ? O1 : never,
    ID extends O extends OptionValues ? never : keyof O,
    V extends O extends OptionValues ? O : ID extends keyof O ? O[ID] : never,
    SV extends M extends true ? V[] : V,
> extends OptionsChipProps<M, OP, O, ID, V, SV> {
    iconSize?: number;
    optionsWidth?: number;
}

export function KebabMenu<
    M extends OptionMultiple,
    OP extends OptionProps<any>,
    O extends OP extends OptionProps<infer O1> ? O1 : never,
    ID extends O extends OptionValues ? never : keyof O,
    V extends O extends OptionValues ? O : ID extends keyof O ? O[ID] : never,
    SV extends M extends true ? V[] : V,
>({ className, children, iconSize, optionsWidth = 220, optionsProps, ...props }: KebabMenuProps<M, OP, O, ID, V, SV>) {
    return (
        <OptionsChip
            padding={4}
            relative
            hideValue
            {...props}
            optionsProps={{
                ...optionsProps,
                width: optionsWidth,
            }}
            className={classNames(styles.root, className)}
        >
            <Icon svgSize={iconSize} type={IconType.KEBAB} />
            {children}
        </OptionsChip>
    );
}
