import * as React from 'react';
import * as moment from 'moment';
import * as lodash from 'lodash';

import { Utils } from '@common/Utils';

import { PlanFundsTransferCorrection, CorrectionStatus, BudgetItem, Month, Persone } from '../types';

import { PlanCorrectionCard, ParticipatorData, ParticipatorField, Warning } from './PlanCorrectionCard';

import { monthIndexMap } from '../utils';

interface Props extends PlanFundsTransferCorrection {}

export class PlanCorrectionCardContainer extends React.Component<Props> {
    public render(): JSX.Element {
        const { serialNumber, creationTime, status, comment, author } = this.props;

        const authorName = this.formatUserName(author);
        const approverName = this.getApproverName();

        return React.createElement(PlanCorrectionCard, {
            serialNumber,
            authorName,
            approverName,
            creationDate: this.formatDate(creationTime),
            donorData: this.makeDonorData(),
            acceptorData: this.makeAcceptorData(),
            status,
            rejectComment: comment,
            warnings: this.makeWarnings(),
        });
    }

    private getApproverName(): string {
        const { status, expert, approver } = this.props;

        if (status === CorrectionStatus.NeedApproving) {
            return expert ? this.formatUserName(expert) : 'не задан';
        }

        return approver ? this.formatUserName(approver) : 'не задан';
    }

    private makeWarnings(): Warning[] {
        const warnings: Warning[] = [];

        const donorWarning = this.makeDonorWarning();
        const acceptorWarning = this.makeAcceptorWarning();

        if (donorWarning) {
            warnings.push(donorWarning);
        }

        if (acceptorWarning) {
            warnings.push(acceptorWarning);
        }

        return warnings;
    }

    private makeDonorWarning(): Warning | null {
        const { params } = this.props;

        const correctionValue = -params.value;

        return this.makeWarningData(correctionValue, this.donorBudgetItem);
    }

    private makeAcceptorWarning(): Warning | null {
        const { params } = this.props;

        const correctionValue = params.value;

        return this.makeWarningData(correctionValue, this.acceptorBudgetItem);
    }

    private makeWarningData(correctionValue: number, budgetItem: BudgetItem): Warning | null {
        const { status } = this.props;
        const currentPlannedFunds = budgetItem.plannedFunds;
        const currentReservedFonds = budgetItem.reservedFunds;

        const sumCurrentPlannedFunds = lodash.values(currentPlannedFunds).reduce<number>((acc, fond) => {
            return acc + fond;
        }, 0);
        const sumCurrentReservedFonds = lodash.values(currentReservedFonds).reduce<number>((acc, fond) => {
            return acc + fond;
        }, 0);

        const delta = sumCurrentReservedFonds - (correctionValue + sumCurrentPlannedFunds);

        if (delta > 0) {
            return {
                delta,
                budgetItemId: String(budgetItem.serialNumber),
                isActive: status === CorrectionStatus.NeedApproving,
            };
        }

        return null;
    }

    private makeAcceptorData(): ParticipatorData {
        const {
            status,
            params,
            budgetItemCurrent: budgetItemsCurrent,
            budgetItemBefore: budgetItemsBefore,
        } = this.props;

        const currentBudgetItem = budgetItemsCurrent.find(({ id }) => id === params.acceptorId);
        const beforeBudgetItem = lodash.isEmpty(budgetItemsBefore)
            ? null
            : budgetItemsBefore.find(({ id }) => id === params.acceptorId);

        const definedBudgetItem =
            status === CorrectionStatus.NeedApproving
                ? currentBudgetItem
                : lodash.isEmpty(beforeBudgetItem)
                ? currentBudgetItem
                : beforeBudgetItem;

        const delta = params.value;

        const month = params.acceptorMonth;

        // TODO В ответе graphQL висит поле __typename и для перебора свойст обхекта оно мешает
        delete definedBudgetItem.plannedFunds['__typename'];
        const beforeFond = definedBudgetItem.plannedFunds[month];

        const allBeforeFond = lodash
            .values(definedBudgetItem.plannedFunds)
            .reduce<number>((acc, monthFond) => acc + monthFond, 0);

        const allFond = this.donorAndAcceptorFromSomeBudgetItem()
            ? { delta: 0, beforeFond: allBeforeFond, afterFond: allBeforeFond }
            : { delta, beforeFond: allBeforeFond, afterFond: allBeforeFond + delta };

        return {
            monthFond: {
                month: this.getMonthName(month),
                delta,
                beforeFond,
                afterFond: beforeFond + delta,
            },
            allFond,
            fondName: currentBudgetItem.sapComment,
            fields: this.buildParticipatorFields(currentBudgetItem),
            comment: currentBudgetItem.comment,
        };
    }

    private makeDonorData(): ParticipatorData {
        const {
            status,
            params,
            budgetItemCurrent: budgetItemsCurrent,
            budgetItemBefore: budgetItemsBefore,
        } = this.props;

        const currentBudgetItem = budgetItemsCurrent.find(({ id }) => id === params.donorId);
        const beforeBudgetItem = lodash.isEmpty(budgetItemsBefore)
            ? null
            : budgetItemsBefore.find(({ id }) => id === params.donorId);

        const definedBudgetItem =
            status === CorrectionStatus.NeedApproving
                ? currentBudgetItem
                : lodash.isEmpty(beforeBudgetItem)
                ? currentBudgetItem
                : beforeBudgetItem;

        const delta = -params.value;

        const month = params.donorMonth;

        // TODO В ответе graphQL висит поле __typename и для перебора свойст обхекта оно мешает
        delete definedBudgetItem.plannedFunds['__typename'];
        const beforeFond = definedBudgetItem.plannedFunds[month];

        const allBeforeFond = lodash
            .values(definedBudgetItem.plannedFunds)
            .reduce<number>((acc, monthFond) => acc + monthFond, 0);

        const allFond = this.donorAndAcceptorFromSomeBudgetItem()
            ? { delta: 0, beforeFond: allBeforeFond, afterFond: allBeforeFond }
            : { delta, beforeFond: allBeforeFond, afterFond: allBeforeFond + delta };

        return {
            monthFond: {
                month: this.getMonthName(month),
                delta,
                beforeFond,
                afterFond: beforeFond + delta,
            },
            allFond,
            fondName: currentBudgetItem.sapComment,
            fields: this.buildParticipatorFields(currentBudgetItem),
            comment: currentBudgetItem.comment,
        };
    }

    private donorAndAcceptorFromSomeBudgetItem(): boolean {
        const { params, budgetItemCurrent: budgetItemsCurrent } = this.props;

        const serialNumberOfDonorBudgetItem = budgetItemsCurrent.find(({ id }) => id === params.donorId).serialNumber;
        const serialNumberOfAcceptorBudgetItem = budgetItemsCurrent.find(
            ({ id }) => id === params.acceptorId,
        ).serialNumber;

        return serialNumberOfAcceptorBudgetItem === serialNumberOfDonorBudgetItem;
    }

    // tslint:disable-next-line:cyclomatic-complexity
    private buildParticipatorFields(budgetItem: BudgetItem): ParticipatorField[] {
        return [
            {
                name: 'ID план',
                value: budgetItem.donors && budgetItem.donors.join(', '),
            },
            {
                name: 'ID исполнение',
                value: budgetItem.serialNumber,
            },
            {
                name: 'Тип проекта',
                value:
                    budgetItem.dictionary &&
                    budgetItem.dictionary.activity_type &&
                    budgetItem.dictionary.activity_type.value,
            },
            {
                name: 'Направление',
                value:
                    budgetItem.dictionary && budgetItem.dictionary.direction && budgetItem.dictionary.direction.value,
            },
            {
                name: 'Дата запуска',
                value: budgetItem.realizationStart && this.formatDate(budgetItem.realizationStart),
            },
            {
                name: 'Дата окончания',
                value: budgetItem.realizationEnd && this.formatDate(budgetItem.realizationEnd),
            },
            {
                name: 'ЦА/ТБ (Территория)',
                value: budgetItem.dictionary?.regionality
                    ? Utils.getDictionaryValue(budgetItem.dictionary.regionality)
                    : '',
            },
            {
                name: 'Территория',
                value: budgetItem.dictionary?.territory
                    ? Utils.getDictionaryValue(budgetItem.dictionary.territory)
                    : '',
            },
            {
                name: 'Статья',
                value: budgetItem.dictionary && budgetItem.dictionary.item && budgetItem.dictionary.item.value,
            },
            {
                name: 'Номер ресурса',
                value: budgetItem.dictionary && budgetItem.dictionary.resource && budgetItem.dictionary.resource.value,
            },
            {
                name: 'Блок',
                value: budgetItem.dictionary && budgetItem.dictionary.block && budgetItem.dictionary.block.value,
                isWide: true,
            },
            {
                name: 'МВЗ / Дивизион',
                value: budgetItem.dictionary && budgetItem.dictionary.division && budgetItem.dictionary.division.value,
                isWide: true,
            },
            {
                name: 'Инструмент',
                value: budgetItem.dictionary && budgetItem.dictionary.tool && budgetItem.dictionary.tool.value,
                isWide: true,
            },
        ];
    }

    private get donorBudgetItem(): BudgetItem {
        const {
            status,
            budgetItemCurrent: budgetItemsCurrent,
            budgetItemBefore: budgetItemsBefore,
            params,
        } = this.props;

        const currentBudgetItem = budgetItemsCurrent.find(({ id }) => id === params.donorId);
        const beforeBudgetItem = lodash.isEmpty(budgetItemsBefore)
            ? null
            : budgetItemsBefore.find(({ id }) => id === params.donorId);

        const budgetItem =
            status === CorrectionStatus.NeedApproving
                ? currentBudgetItem
                : lodash.isEmpty(beforeBudgetItem)
                ? currentBudgetItem
                : beforeBudgetItem;

        delete budgetItem.plannedFunds['__typename'];
        delete budgetItem.reservedFunds['__typename'];
        return budgetItem;
    }

    private get acceptorBudgetItem(): BudgetItem {
        const {
            status,
            budgetItemCurrent: budgetItemsCurrent,
            budgetItemBefore: budgetItemsBefore,
            params,
        } = this.props;

        const currentBudgetItem = budgetItemsCurrent.find(({ id }) => id === params.acceptorId);
        const beforeBudgetItem = lodash.isEmpty(budgetItemsBefore)
            ? null
            : budgetItemsBefore.find(({ id }) => id === params.acceptorId);

        const budgetItem =
            status === CorrectionStatus.NeedApproving
                ? currentBudgetItem
                : lodash.isEmpty(beforeBudgetItem)
                ? currentBudgetItem
                : beforeBudgetItem;

        delete budgetItem.plannedFunds['__typename'];
        delete budgetItem.reservedFunds['__typename'];
        return budgetItem;
    }

    private formatUserName(user: Persone): string {
        return `${user.firstName} ${user.secondName}`;
    }

    private formatDate(date: string | Date): string {
        return moment(date).format('DD.MM.YYYY');
    }

    private getMonthName(month: Month): string {
        moment.locale('ru');
        const monthName = moment.months()[monthIndexMap[month]];

        return lodash.capitalize(monthName);
    }
}
