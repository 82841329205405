import { BudgetItem } from '@mrm/budget';

import { LoadingStatus } from '@store/commonTypes';
export interface State extends ActivityReferenceMenu {}

export interface ActivityReferenceMenu {
    loadingStatus?: LoadingStatus;
    selectedActivityId?: number;
    selectedExpertId?: number;
    budgetItems?: BudgetItem[];
    selectedBudgetItemIds?: string[];
}

export interface InitParams {
    budgetId: string;
    selectedActivityId?: string;
    filters?: {
        divisionIds: string[];
    };
}

export interface InitResult {
    budgetItems: BudgetItem[];
}

export { LoadingStatus };
