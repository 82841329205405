import { v4 as uuidV4 } from 'uuid';

import { TaskPageState, TaskLoadingStatus } from '../types';

export const resetTaskPageReducer = (): TaskPageState => ({
    loadingStatus: TaskLoadingStatus.NOT_LOADED,
    comments: {},
    departments: {},
    workTypes: {},
    channels: {},
    editingComment: null,
    isUserSubscribed: false,
    newCommentary: {
        authorId: -1,
        isLoading: false,
        id: uuidV4(),
        content: '',
        createdAt: Date.now(),
        assetsIds: [],
    },
    participants: [],
    editingParticipants: [],
    isParticipantEditorFormVisible: false,
    telegram: {
        hasUserAccessToChat: false,
        isUserCreatedChat: false,
        isUserAuthorized: false,
        fetchError: null,
        loading: false,
        linkToAuthorization: null,
    },
});
