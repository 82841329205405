import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Money, MoneyFormatter } from '@common/Utils';
import { WarningNotificationBar, ErrorNotificationBar } from '@budgetCommon';

import * as styles from './Warnings.scss';

interface Props {
    lineSerialNumber: number;
    lineBudgetId: string;
    factExcess: number;
    reserveExcess: number;
    hasUnaprovedCorrections: boolean;
    initCorrectionToLine: () => void;
}

export function Warnings({
    lineSerialNumber,
    lineBudgetId,
    factExcess,
    reserveExcess,
    hasUnaprovedCorrections,
    initCorrectionToLine,
}: Props): JSX.Element {
    const showReserveExcessWarning = reserveExcess > 0;
    const showFactExcessWarning = factExcess > 0;

    const unaprovedCorrectionsWarningStyle = classNames(
        (showReserveExcessWarning || showFactExcessWarning) && styles.margin,
    );
    const reserveExcessWarningStyle = classNames(showFactExcessWarning && styles.margin);

    return (
        <div
            className={classNames(styles.root, styles.bottomBorder)}
            {...{
                'qa-id': 'budgetExecutionLineModalWarnings',
            }}
        >
            {hasUnaprovedCorrections && (
                <div
                    className={unaprovedCorrectionsWarningStyle}
                    {...{
                        'qa-id': 'budgetExeuctionLineModalUnaprovedCorrectionsWarning',
                    }}
                >
                    <UnaprovedCorrectionsWarning lineSerialNumber={lineSerialNumber} lineBudgetId={lineBudgetId} />
                </div>
            )}

            {reserveExcess > 0 && (
                <div
                    className={reserveExcessWarningStyle}
                    {...{
                        'qa-id': 'budgetExeuctionLineModalReserveExeccWarning',
                    }}
                >
                    <ReserveExcessWarning reserveExcess={reserveExcess} initCorrectionToLine={initCorrectionToLine} />
                </div>
            )}

            {factExcess > 0 && (
                <div
                    {...{
                        'qa-id': 'budgetExeuctionLineModalFactExessWarning',
                    }}
                >
                    <FactExcessWarning factExcess={factExcess} initCorrectionToLine={initCorrectionToLine} />
                </div>
            )}
        </div>
    );
}

type UnaprovedCorrectionsWarningProps = Pick<Props, 'lineSerialNumber' | 'lineBudgetId'>;

function UnaprovedCorrectionsWarning({
    lineSerialNumber,
    lineBudgetId,
}: UnaprovedCorrectionsWarningProps): JSX.Element {
    return (
        <Link
            to={`/budget/corrections?executorId=${lineSerialNumber}&budgetId=${lineBudgetId}&status=need_approving`}
            target="_blank"
            className={styles.link}
        >
            <WarningNotificationBar text="Есть неутвержденные корректировки" />
        </Link>
    );
}

type ReserveExcessWarningProps = Pick<Props, 'reserveExcess' | 'initCorrectionToLine'>;

function ReserveExcessWarning({ reserveExcess, initCorrectionToLine }: ReserveExcessWarningProps): JSX.Element {
    return (
        <div className={styles.transferFundsButton} onClick={initCorrectionToLine}>
            <ErrorNotificationBar
                text={
                    <React.Fragment>
                        Резерв превысил План на&nbsp;
                        <span className={styles.bold}>
                            {MoneyFormatter.toThousands(Money.fromCopecks(reserveExcess))}
                        </span>
                    </React.Fragment>
                }
            />
        </div>
    );
}

type FactExcessWarningProps = Pick<Props, 'factExcess' | 'initCorrectionToLine'>;

function FactExcessWarning({ factExcess, initCorrectionToLine }: FactExcessWarningProps): JSX.Element {
    return (
        <div className={styles.transferFundsButton} onClick={initCorrectionToLine}>
            <ErrorNotificationBar
                text={
                    <React.Fragment>
                        Факт превысил План на&nbsp;
                        <span className={styles.bold}>{MoneyFormatter.toThousands(Money.fromCopecks(factExcess))}</span>
                    </React.Fragment>
                }
            />
        </div>
    );
}
