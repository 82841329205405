// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-CardTypes-common-CommentWithTitle-_CommentWithTitle__text{margin:0 -6px;padding:6px;color:#000000;border-radius:6px;background-color:#f6f6f6;font-family:'Open Sans';font-size:12px;line-height:1.43}\n", "",{"version":3,"sources":["webpack://./src/client/modules/dashboard/RightSidebar/BudgetInfo/Corrections/templates/CardTypes/common/CommentWithTitle/CommentWithTitle.scss"],"names":[],"mappings":"AAAA,sIACI,aAAc,CACd,WAAY,CAEZ,aAAc,CACd,iBAAkB,CAClB,wBAAyB,CAEzB,uBAAwB,CACxB,cAAe,CACf,gBAAiB","sourcesContent":[".text {\n    margin: 0 -6px;\n    padding: 6px;\n\n    color: #000000;\n    border-radius: 6px;\n    background-color: #f6f6f6;\n\n    font-family: 'Open Sans';\n    font-size: 12px;\n    line-height: 1.43;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-CardTypes-common-CommentWithTitle-_CommentWithTitle__text"
};
export default ___CSS_LOADER_EXPORT___;
