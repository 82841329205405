// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-Block-Field-FieldTypes-Dropdown-_Dropdown__root{position:relative;width:100%;height:80px;padding-top:4px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/EditPlannedBudgetPage/BudgetList/BudgetCard/Brief/Block/Field/FieldTypes/Dropdown/Dropdown.scss"],"names":[],"mappings":"AAAA,6HACI,iBAAkB,CAElB,UAAW,CACX,WAAY,CACZ,eAAgB","sourcesContent":[".root {\n    position: relative;\n\n    width: 100%;\n    height: 80px;\n    padding-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-Block-Field-FieldTypes-Dropdown-_Dropdown__root"
};
export default ___CSS_LOADER_EXPORT___;
