import * as React from 'react';
import classNames from 'classnames';
import * as moment from 'moment';

import { Icon, IconType, WithTooltip } from 'sber-marketing-ui';
import * as style from './FondsByMonthTable.scss';
import { Money, MoneyFormatter } from '@common/Utils';

const MONTH_NAMES = makeMonthNames();

export interface Props {
    fields: FondField[];
}

export interface FondField {
    monthIndex: number;
    before: number;
    after: number;
    delta: number;
    deltaIsPositive: boolean;
}

export const FondsByMonthTable: React.FC<Props> = ({ fields }) => {
    return (
        <table
            className={style.root}
            {...{
                'qa-id': 'correctionCardMonthFunds',
            }}
        >
            <thead className={style.header}>
                <tr>
                    <td className={classNames(style.title, style.monthTitle)}>
                        <WithTooltip spanWrapper content="Месяц">
                            <React.Fragment>Месяц</React.Fragment>
                        </WithTooltip>
                    </td>
                    <td className={classNames(style.title, style.beforeBudgetTitle)}>
                        <WithTooltip spanWrapper content="Текущий, тыс ₽">
                            <React.Fragment>Текущий, тыс ₽</React.Fragment>
                        </WithTooltip>
                    </td>
                    <td className={classNames(style.title, style.deltaValueTitle)}>
                        <WithTooltip spanWrapper content="Корректир., тыс ₽">
                            <React.Fragment>Корректир., тыс ₽</React.Fragment>
                        </WithTooltip>
                    </td>
                    <td className={classNames(style.title, style.afterBudgetTitle)}>
                        <WithTooltip spanWrapper content="Итог, тыс ₽">
                            <React.Fragment>Итог, тыс ₽</React.Fragment>
                        </WithTooltip>
                    </td>
                </tr>
            </thead>
            <tbody className={style.body}>
                {fields.map((fond, index) => {
                    const month = MONTH_NAMES[fond.monthIndex];

                    const beforeSum = formatMoneyWithoutSign(fond.before);
                    const beforeSumForTooltip = formatMoneyForTooltipWithoutSign(fond.before);

                    const delta = formatMoneyWithSign(fond.delta);
                    const deltaForTooltip = formatMoneyForTooltipWithSign(fond.delta);

                    const afterSum = formatMoneyWithoutSign(fond.after);
                    const afterSumForTooltip = formatMoneyForTooltipWithoutSign(fond.after);

                    const isNotEmptyDelta = fond.delta !== 0;

                    return (
                        <tr
                            key={index}
                            {...{
                                'qa-id': 'correctionCardMonthFundsMonthRow',
                                'qa-index': month,
                            }}
                        >
                            <td
                                className={style.month}
                                {...{
                                    'qa-id': 'correctionCardMonthFundsMonthRowMonthCell',
                                }}
                            >
                                <WithTooltip spanWrapper content={month}>
                                    <React.Fragment>{month}</React.Fragment>
                                </WithTooltip>
                            </td>

                            <td
                                {...{
                                    'qa-id': 'correctionCardMonthFundsMonthRowBeforeCell',
                                }}
                            >
                                <WithTooltip spanWrapper content={beforeSumForTooltip}>
                                    <React.Fragment>{beforeSum}</React.Fragment>
                                </WithTooltip>
                            </td>

                            {isNotEmptyDelta ? (
                                <td
                                    className={classNames(
                                        fond.deltaIsPositive && style.positiveDelta,
                                        !fond.deltaIsPositive && style.negativeDelta,
                                    )}
                                    {...{
                                        'qa-id': 'correctionCardMonthFundsMonthRowDeltaCell',
                                    }}
                                >
                                    <WithTooltip spanWrapper content={deltaForTooltip}>
                                        <React.Fragment>{delta}</React.Fragment>
                                    </WithTooltip>
                                </td>
                            ) : (
                                <td
                                    {...{
                                        'qa-id': 'correctionCardMonthFundsMonthRowDeltaCell',
                                    }}
                                >
                                    <WithTooltip spanWrapper content={'0'}>
                                        <React.Fragment>{0}</React.Fragment>
                                    </WithTooltip>
                                </td>
                            )}

                            <td className={style.afterBudget}>
                                <WithTooltip
                                    spanWrapper
                                    content={
                                        isNotEmptyDelta
                                            ? fond.deltaIsPositive
                                                ? `↑ ${afterSumForTooltip}`
                                                : `↓ ${afterSumForTooltip}`
                                            : afterSumForTooltip
                                    }
                                >
                                    <React.Fragment>
                                        {isNotEmptyDelta && (
                                            <div
                                                className={classNames(
                                                    style.deltaArrow,
                                                    fond.deltaIsPositive && style.positiveDelta,
                                                    !fond.deltaIsPositive && style.negativeDelta,
                                                )}
                                            >
                                                {fond.deltaIsPositive ? (
                                                    <div className={style.positiveDeltaArrow}>
                                                        <Icon type={IconType.ARROW_UP} svgSize={10} />
                                                    </div>
                                                ) : (
                                                    <div className={style.negativeDeltaArrow}>
                                                        <Icon type={IconType.ARROW_DOWN} svgSize={10} />
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {
                                            <span
                                                {...{
                                                    'qa-id': 'correctionCardMonthFundsMonthRowAfterCell',
                                                }}
                                            >
                                                {afterSum}
                                            </span>
                                        }
                                    </React.Fragment>
                                </WithTooltip>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

function makeMonthNames(): string[] {
    moment.locale('ru');
    return moment.months().map((item) => item.slice(0, 3));
}

function formatMoneyWithSign(money: number): string {
    return MoneyFormatter.toRoubles(Money.fromCopecks(money), { forceSign: true, hideCaption: true });
}

function formatMoneyForTooltipWithSign(money: number): string {
    return MoneyFormatter.toRoubles(Money.fromCopecks(money), { forceSign: true, hideCaption: true });
}

function formatMoneyWithoutSign(money: number): string {
    return MoneyFormatter.toRoubles(Money.fromCopecks(money), { hideCaption: true });
}

function formatMoneyForTooltipWithoutSign(money: number): string {
    return MoneyFormatter.toRoubles(Money.fromCopecks(money), { hideCaption: true });
}
