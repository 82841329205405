import * as styles from './styles.scss';
import { Color } from '../enums';

export const colorClassNames: Record<Color, string> = {
    [Color.RED]: styles.red,
    [Color.BLUE]: styles.blue,
    [Color.GREEN]: styles.green,
    [Color.MUSTARD]: styles.mustard,
};

const DEFAULT_COLOR_NAME = colorClassNames[Color.GREEN];

export const getColorClassNames = (color: Color) => {
    return colorClassNames[color] || DEFAULT_COLOR_NAME;
};
