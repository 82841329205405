import * as React from 'react';
import { connect } from 'react-redux';
import { scroller } from 'react-scroll';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import { CreateBudgetItemForm, UpdateBudgetItemForm } from '@mrm/budget';
import { DictionaryType } from '@mrm/dictionary';

import { StoreState } from '@store';
import { makeBudgetsItems } from '@store/plannedBudgetEdit/selectors';
import { GroupedDictionaries } from '@store/plannedBudgetEdit/types';

import { Utils } from '@common/Utils';

import { ContentNav, NavItem } from './ContentNav';

const SCROLL_OFFSET = -90;
const SCROLL_DURATION = 500;

interface Props extends Partial<MapProps> {}

interface MapProps {
    currentBudgetsItems: CreateBudgetItemForm[];
    availableDictionaries: GroupedDictionaries;
    usedDictionaries: GroupedDictionaries;
}

interface State {
    selectedIndex: number;
}

@(connect(mapStateToProps, null) as any)
export class ContentNavContainer extends React.PureComponent<Props, State> {
    private scrollContainer: HTMLElement;

    constructor(props: Props) {
        super(props);

        this.state = {
            selectedIndex: 0,
        };
    }

    public componentDidMount() {
        this.scrollContainer = document.getElementById('pageContent');

        this.scrollContainer.addEventListener('scroll', this.handleScroll);
    }

    public componentWillUnmount() {
        this.scrollContainer.removeEventListener('scroll', this.handleScroll);
    }

    public render(): JSX.Element {
        return React.createElement(ContentNav, {
            items: this.makeNavItems(),
            selectedIndex: this.state.selectedIndex,
            onItemClick: this.handleItemClick,
        });
    }

    @autobind
    protected handleItemClick(name: string) {
        this.scrollTo(name);
    }

    @autobind
    private handleScroll() {
        const newIndex = this.getNewSelectedIndex();

        if (newIndex != this.state.selectedIndex) {
            this.setState({
                selectedIndex: newIndex,
            });
        }
    }

    private makeNavItems(): NavItem[] {
        const { currentBudgetsItems } = this.props;

        return [
            {
                name: 'activity',
                title: 'Общая информация',
            },
            ...currentBudgetsItems.map((currentBudgetItem, index) => {
                const tool = this.getDictionaryValue(currentBudgetItem, DictionaryType.Tool);

                const plannedBudget = currentBudgetsItems
                    ? lodash
                          .keys((currentBudgetItem as UpdateBudgetItemForm).plannedFunds)
                          .reduce(
                              (acc, monthKey) =>
                                  acc +
                                  parseInt(
                                      (currentBudgetItem as UpdateBudgetItemForm).plannedFunds[monthKey].toString(),
                                      10,
                                  ),
                              0,
                          )
                    : null;

                return {
                    name: `budget${index}`,
                    title: tool || 'Новая строка',
                    budget: plannedBudget,
                };
            }),
        ];
    }

    private getDictionaryValue(budgetItem: CreateBudgetItemForm, dictionaryType: DictionaryType) {
        const { availableDictionaries, usedDictionaries } = this.props;

        if (!budgetItem) {
            return null;
        }

        const dictionaryItems =
            availableDictionaries.byType[dictionaryType] || usedDictionaries.byType[dictionaryType] || [];

        const foundDictionary = dictionaryItems.find((item) => lodash.includes(budgetItem.dictionaryIds, item.id));

        return Utils.getDictionaryValue(foundDictionary);
    }

    private getNewSelectedIndex(): number {
        const sections = document.querySelectorAll('.planningSection');

        const sectionsOffsets = Array.from(sections).map((item) => item.getBoundingClientRect().top);

        let closestOffset: number = null;

        const result = sectionsOffsets.reduce((acc, item, index) => {
            if (closestOffset == null || Math.abs(item + SCROLL_OFFSET) <= Math.abs(closestOffset + SCROLL_OFFSET)) {
                closestOffset = item;
                acc = index;
            }

            return acc;
        }, 0);

        return result;
    }

    private scrollTo(name: string) {
        const options = {
            offset: SCROLL_OFFSET,
            smooth: 'easeOutQuad',
            duration: SCROLL_DURATION,
            containerId: 'pageContent',
        };

        scroller.scrollTo(name, options);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { availableDictionaries, usedDictionaries } = state.plannedBudgetEditPage;

    return {
        currentBudgetsItems: makeBudgetsItems(state),
        availableDictionaries,
        usedDictionaries,
    };
}
