// import { bindActionCreators } from 'redux';
import * as lodash from 'lodash';
// import * as moment from 'moment';
// import autobind from 'autobind-decorator';

import type {
    AutopilotDOOH,
    AutopilotDOOHBrief,
    AutopilotDOOHBriefSelectedRow,
    AutopilotDOOHMediaplanCalculation,
} from 'sber-marketing-types/backend';
import { AutopilotStatus } from 'sber-marketing-types/backend';
import type { BriefStageForm } from '@store/autopilotDOOH/types';

import { store } from '@store';
import {
    getAutopilot,
    getMediaplan,
    getBriefStageForm,
    getSelectedBudgetItemIds,
    getMediaplanLineBudgetsByLineId,
    getMediaplanSideQuantityByLineId,
    getManualMediaplanSideQuantityValues,
} from '@store/autopilotDOOH/selectors';
import { AutopilotDOOHApi } from '@api';

interface StoreProps {
    autopilot: AutopilotDOOH;
    mediaplan: AutopilotDOOHMediaplanCalculation[];
    briefForm: BriefStageForm;
    selectedBudgetItemIds: string[];
    mediaplanLineBudgetsByLineId: Record<string, number>;
    mediaplanSideQuantityByLineId: Record<string, number>;
    manualMediaplanSideQuantityValues: Record<string, number>;
}

export class Saver {
    private static instance: Saver;

    // private dispatch = bindActionCreators({

    // }, store.dispatch);

    public static getInstance(): Saver {
        if (!Saver.instance) {
            Saver.instance = new Saver();
        }
        return Saver.instance;
    }

    public init(): void {}

    public async setAutopilotStatus(status: boolean) {
        const { autopilot } = this.getStoreProps();

        if (autopilot) {
            await AutopilotDOOHApi.updateAutopilot(autopilot.id, {
                status: (status ? 'on' : 'off') as AutopilotStatus,
            });
        }
    }

    public async saveBriefForm() {
        const { autopilot } = this.getStoreProps();

        if (autopilot) {
            const updateParams = this.makeAutopilotBriefParams();

            await AutopilotDOOHApi.updateBrief(autopilot.id, updateParams);
        }
    }

    public async saveSelectedBudgetItems() {
        const { autopilot, selectedBudgetItemIds } = this.getStoreProps();

        if (autopilot) {
            await AutopilotDOOHApi.updateBudget(autopilot.id, { budgetIds: selectedBudgetItemIds });
        }
    }

    public async updateMediaplan() {
        const { autopilot } = this.getStoreProps();

        if (autopilot) {
            await AutopilotDOOHApi.updateMediaplan(autopilot.id);
        }
    }

    private makeAutopilotBriefParams(): AutopilotDOOHBrief {
        const {
            briefForm,
            mediaplan,
            mediaplanLineBudgetsByLineId,
            mediaplanSideQuantityByLineId,
            manualMediaplanSideQuantityValues,
        } = this.getStoreProps();

        const selectedRows: AutopilotDOOHBriefSelectedRow[] = !lodash.isEmpty(mediaplan)
            ? briefForm.selectedRowsIds.map((lineId) => {
                  const foundLine = mediaplan.find((item) => item.rowId === lineId);

                  const timing = briefForm.selectedFormatTimings[foundLine.format.abbreviation] || 0;
                  const budget = mediaplanLineBudgetsByLineId[lineId];
                  const sideQuantity = mediaplanSideQuantityByLineId[lineId];
                  const manual = manualMediaplanSideQuantityValues[lineId] !== undefined;

                  return {
                      rowId: lineId,
                      timing,
                      budget,
                      sideQuantity,
                      manual,
                  };
              })
            : [];

        return {
            block: briefForm.blockId,
            product: briefForm.productId,
            budget: parseInt(briefForm.budget, 10),
            dateStart: briefForm.dateStart,
            dateEnd: briefForm.dateEnd,
            regionalityId: briefForm.regionalityId,
            selectedRows,
            budgetAutomation: briefForm.budgetAutomation,
        };
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        return {
            autopilot: getAutopilot(storeState),
            mediaplan: getMediaplan(storeState),
            briefForm: getBriefStageForm(storeState),
            selectedBudgetItemIds: getSelectedBudgetItemIds(storeState),
            mediaplanLineBudgetsByLineId: getMediaplanLineBudgetsByLineId(storeState),
            mediaplanSideQuantityByLineId: getMediaplanSideQuantityByLineId(storeState),
            manualMediaplanSideQuantityValues: getManualMediaplanSideQuantityValues(storeState),
        };
    }
}
