// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-ActivitySidebar-tabs-AutopilotTab-_AutopilotTab__root{display:flex;flex-direction:column;gap:10px;font-size:12px}.src-client-modules-common-ActivitySidebar-tabs-AutopilotTab-_AutopilotTab__card{display:flex;align-items:center;justify-content:space-between;gap:8px;padding:4px 12px;box-shadow:0 1px 4px 0 #0000001A;border-radius:4px;color:#000;text-decoration:none}.src-client-modules-common-ActivitySidebar-tabs-AutopilotTab-_AutopilotTab__name{flex:1;min-width:0;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.src-client-modules-common-ActivitySidebar-tabs-AutopilotTab-_AutopilotTab__skeletonCard{height:32px;box-shadow:0 1px 4px 0 #0000001A}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/ActivitySidebar/tabs/AutopilotTab/AutopilotTab.scss"],"names":[],"mappings":"AAAA,iFACE,YAAa,CACb,qBAAsB,CACtB,QAAS,CACT,cAAe,CAChB,iFAGC,YAAa,CACb,kBAAmB,CACnB,6BAA8B,CAC9B,OAAQ,CACR,gBAAiB,CACjB,gCAAiC,CACjC,iBAAkB,CAClB,UAAW,CACX,oBAAqB,CACtB,iFAGC,MAAO,CACP,WAAY,CACZ,kBAAmB,CACnB,eAAgB,CAChB,sBAAuB,CACxB,yFAGC,WAAY,CACZ,gCAAiC","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  font-size: 12px;\n}\n\n.card {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 8px;\n  padding: 4px 12px;\n  box-shadow: 0 1px 4px 0 #0000001A;\n  border-radius: 4px;\n  color: #000;\n  text-decoration: none;\n}\n\n.name {\n  flex: 1;\n  min-width: 0;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.skeletonCard {\n  height: 32px;\n  box-shadow: 0 1px 4px 0 #0000001A;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-ActivitySidebar-tabs-AutopilotTab-_AutopilotTab__root",
	"card": "src-client-modules-common-ActivitySidebar-tabs-AutopilotTab-_AutopilotTab__card",
	"name": "src-client-modules-common-ActivitySidebar-tabs-AutopilotTab-_AutopilotTab__name",
	"skeletonCard": "src-client-modules-common-ActivitySidebar-tabs-AutopilotTab-_AutopilotTab__skeletonCard"
};
export default ___CSS_LOADER_EXPORT___;
