// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilotRadio-StageWidgets-MediaplanSettingsStageWidget-RadioSplit-TableView-StickyWrapper-_StickyWrapper__root{position:absolute;left:0;right:0;z-index:20;pointer-events:none}.src-client-modules-activity-pages-autopilotRadio-StageWidgets-MediaplanSettingsStageWidget-RadioSplit-TableView-StickyWrapper-_StickyWrapper__content{width:100%;height:100%;pointer-events:auto}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilotRadio/StageWidgets/MediaplanSettingsStageWidget/RadioSplit/TableView/StickyWrapper/StickyWrapper.scss"],"names":[],"mappings":"AAAA,oJACI,iBAAkB,CAClB,MAAO,CACP,OAAQ,CAER,UAAW,CACX,mBAAoB,CACvB,uJAGG,UAAW,CACX,WAAY,CACZ,mBAAoB","sourcesContent":[".root {\n    position: absolute;\n    left: 0;\n    right: 0;\n\n    z-index: 20;\n    pointer-events: none;\n}\n\n.content {\n    width: 100%;\n    height: 100%;\n    pointer-events: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilotRadio-StageWidgets-MediaplanSettingsStageWidget-RadioSplit-TableView-StickyWrapper-_StickyWrapper__root",
	"content": "src-client-modules-activity-pages-autopilotRadio-StageWidgets-MediaplanSettingsStageWidget-RadioSplit-TableView-StickyWrapper-_StickyWrapper__content"
};
export default ___CSS_LOADER_EXPORT___;
