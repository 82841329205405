import * as React from 'react';
import { uniqBy } from 'lodash';

import { CreativeRequest, CreativeRequestItem, CreativeRequestItemFile, Dictionary } from '@api';

import { useCreativeRequestData } from '../../hooks';

export function useCreativeRequestItems(creativeRequest: CreativeRequest) {
    function onItemsAdded(setter: (items: CreativeRequestItem[]) => void, item: CreativeRequestItem) {
        setter([...itemsRef.current, item]);
    }
    let onItemsAddedBounded: (item: CreativeRequestItem) => void;

    const itemsRef = React.useRef<CreativeRequestItem[]>();
    const items = useCreativeRequestData(
        creativeRequest,
        async (creativeRequest) => {
            const items = [...(await creativeRequest.model.getItems())];

            itemsRef.current = items;
            return items;
        },
        (creativeRequest, setItems) => {
            onItemsAddedBounded = onItemsAdded.bind(null, setItems);

            creativeRequest.events.onItemsAdded(onItemsAddedBounded);
        },
        (creativeRequest) => creativeRequest.events.offItemsAdded(onItemsAddedBounded),
    );

    return items;
}

export interface FileWithCreativeRequestItem {
    item: CreativeRequestItem;
    itemGroup: Dictionary;
    file: CreativeRequestItemFile;
}
export type FilesCache = FileWithCreativeRequestItem[];

export function useFilesCache() {
    const [cache, setCache] = React.useState<FilesCache>([]);
    const cacheRef = React.useRef<FilesCache>();

    async function addFiles(item: CreativeRequestItem, files: CreativeRequestItemFile[]) {
        const itemGroup = await item.model.creativeRequestGroup;

        const cache: FilesCache = uniqBy(
            [
                ...cacheRef.current,
                ...(files || []).map((file) => ({
                    item,
                    itemGroup,
                    file,
                })),
            ],
            ({ file }) => file.model.id,
        );

        cacheRef.current = cache;
        setCache(cache);
    }

    function deleteFile(file: CreativeRequestItemFile) {
        const fileId = file.model.id;

        const cache: FilesCache = cacheRef.current.filter((file) => file.file.model.id !== fileId);

        cacheRef.current = cache;
        setCache(cache);
    }

    React.useEffect(() => {
        cacheRef.current = cache;
    }, []);

    return {
        cache,
        addFiles,
        deleteFile,
    };
}
