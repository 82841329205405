import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { isNil } from 'lodash';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user/selector';
import { selectors, createChannel, updateChannel } from '@store/taskPage';

import { ChannelEditorMappedState, ChannelEditorMappedActions, ChannelEditorProps } from './types';
import { ChannelEditorContainer } from './ChannelEditorContainer';

const mapStateToProps = (state: StoreState, ownProps: ChannelEditorProps): ChannelEditorMappedState => {
    const { id, isNew, isUserAuthor } = ownProps;

    const channel = selectors.getChannelById(state, id);
    const taskInfo = selectors.getTaskInfo(state);
    const loginnedUserId = getLoginUser(state).attributes.id;
    if (!isNew && isNil(channel)) {
        throw new Error(`Channel with id=${id} not found`);
    }

    return {
        taskParticipantsIds:
            [
                ...taskInfo.participants.map((participant) => participant.userId),
                taskInfo.authorId,
                taskInfo.executorId,
            ].filter((id) => id !== loginnedUserId) || [],
        channelParticipantsIds: isNew ? [] : channel.participantIds,
        channelName: isNew ? '' : channel.title,
        isUserAuthor,
        authorId: channel && channel.authorId,
    };
};

const mapDispatchToProps = (
    dispatch: Dispatch<StoreState>,
    { isNew, id: channelId }: ChannelEditorProps,
): ChannelEditorMappedActions => ({
    saveChannel: isNew
        ? (params) => dispatch(createChannel(params))
        : (params) => dispatch(updateChannel({ ...params, channelId })),
});

const withStore = connect(mapStateToProps, mapDispatchToProps);

/** Channel editor popup */
export const ChannelEditorStore: ComponentType<ChannelEditorProps> = withStore(ChannelEditorContainer);
