import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { AxiosError } from 'axios';

import { TaskApi } from '@api';

import { StoreState } from '@store';
import { asyncActions } from '@store/taskPage/actions';
import { FetchError, UpdateTelegramPayload, UpdateTelegramSuccess } from '@store/taskPage/types';

export const updateTelegram = bindThunkAction<StoreState, UpdateTelegramPayload, UpdateTelegramSuccess, FetchError>(
    asyncActions.updateTelegram,
    async ({ taskId }) => {
        try {
            return await TaskApi.getTelegramStatus(taskId);
        } catch (error) {
            const { response, message } = <AxiosError>error;

            if (response) {
                throw new FetchError(response.status, response.statusText, message);
            } else {
                throw new FetchError(0, 'Unknown', message);
            }
        }
    },
);
