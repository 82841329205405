import * as React from 'react';

import { IconType } from 'sber-marketing-ui';

import { OptionProps } from '../Option';
import { DEFAULT_OPTIONS_HEIGHT, OptionMultiple, Options, OptionsProps, OptionValues } from '../Options';
import { Input, InputOnChangeHandler } from '../Input';

import * as styles from './SearchOptions.scss';
import { useDefaultState } from '@common/hooks';

export interface SearchOptionProps<
    M extends OptionMultiple,
    OP extends OptionProps<any>,
    O extends OP extends OptionProps<infer O1> ? O1 : never,
    ID extends O extends OptionValues ? never : keyof O,
    V extends O extends OptionValues ? O : ID extends keyof O ? O[ID] : never,
    SV extends M extends true ? V[] : V,
> extends OptionsProps<M, OP, O, ID, V, SV> {
    search?: string;
    hideSearch?: boolean | number;
    preventTitleSearch?: boolean;
    preventSubtitleSearch?: boolean;
    fallback?: React.ReactNode;
    searchFallback?: React.ReactNode;
    emptyFallback?: React.ReactNode;
    onSearch?: InputOnChangeHandler<string>;
}

export function SearchOptions<
    M extends OptionMultiple,
    OP extends OptionProps<any>,
    O extends OP extends OptionProps<infer O1> ? O1 : never,
    ID extends O extends OptionValues ? never : keyof O,
    V extends O extends OptionValues ? O : ID extends keyof O ? O[ID] : never,
    SV extends M extends true ? V[] : V,
>({
    options,
    search,
    onSearch,
    optionsBefore,
    hideSearch = 10,
    optionsHeight = DEFAULT_OPTIONS_HEIGHT,
    preventTitleSearch,
    preventSubtitleSearch,
    searchFallback = 'Поиск не дал результат',
    emptyFallback = 'Нет доступных опций',
    fallback,
    children,
    optionsLoading,
    ...props
}: SearchOptionProps<M, OP, O, ID, V, SV>) {
    const [currentSearch, setDefaultSearch] = useDefaultState(search, onSearch, '');
    const splitSearch = currentSearch.toLowerCase().split(' ');
    const normHideInput = hideSearch === false ? 0 : typeof hideSearch === 'number' ? hideSearch : Infinity;
    const isShowSearch = options.length > normHideInput;
    const preventSearch = preventTitleSearch && preventSubtitleSearch;

    fallback = fallback ?? currentSearch ? searchFallback : emptyFallback;

    const lowerValues = React.useMemo(
        () =>
            options?.map(({ title, subtitle }) => [
                !preventTitleSearch && typeof title === 'string' ? title.toLowerCase() : '',
                !preventSubtitleSearch && typeof subtitle === 'string' ? subtitle.toLowerCase() : '',
            ]) || [],
        [options, preventTitleSearch, preventSubtitleSearch],
    );

    const searchOptions = React.useMemo(
        () =>
            (preventSearch
                ? options
                : options?.filter((_, index) =>
                      lowerValues[index].some((value) => splitSearch.every((search) => value.includes(search))),
                  )) || [],
        [options, lowerValues, splitSearch, preventSearch],
    );

    const handleSearch: InputOnChangeHandler<string> = (value: string, e) => {
        setDefaultSearch(value, e);
    };

    const before = (
        <>
            {optionsBefore}
            {isShowSearch && (
                <Input
                    data-qa-id="SearchOptions__search"
                    selectOnFocus
                    iconBefore={IconType.SEARCH}
                    placeholder="Поиск"
                    value={currentSearch}
                    onChange={handleSearch}
                />
            )}
        </>
    );

    return (
        <Options
            {...props}
            optionsLoading={optionsLoading}
            optionsHeight={isShowSearch && optionsHeight ? optionsHeight - 38 : optionsHeight}
            options={searchOptions}
            optionsBefore={before}
        >
            {children}
            {!optionsLoading && !searchOptions?.length && <span className={styles.fallback}>{fallback}</span>}
        </Options>
    );
}
