// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetPlanning-FilterMenu-_FilterMenu__root{width:100%}.src-client-modules-budget-BudgetPage-BudgetPlanning-FilterMenu-_FilterMenu__root:focus{outline:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetPlanning/FilterMenu/FilterMenu.scss"],"names":[],"mappings":"AAAA,kFACI,UAAW,CADf,wFAIQ,SAAU","sourcesContent":[".root {\n    width: 100%;\n\n    &:focus {\n        outline: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetPlanning-FilterMenu-_FilterMenu__root"
};
export default ___CSS_LOADER_EXPORT___;
