import * as lodash from 'lodash';

import type { LineParams, LinesGroup, OrganizationGroups } from '@store/calendar/types';

import { ItemsSplitter, ItemType } from './ItemsSplitter';
import { ORGANIZATION_HEIGHT, LINE_HEIGHT } from '../consts';

interface Item {
    id: string | number;
    type: ItemType;
    height: number;
}

interface Props {
    organizationGroupsList: OrganizationGroups[];
}

export interface Page {
    organizations: OrganizationGroups[];
    iconMap?: IconMap;
}

export interface IconMap {
    emptyExpiredTaskIcon: HTMLImageElement;
    finishedTaskIcon: HTMLImageElement;
    filledExpiredTaskIcon: HTMLImageElement;
}

export class OrganizationGroupClipper {
    private organizationGroupsList: OrganizationGroups[];
    private groups: LinesGroup[];
    private lines: LineParams[];

    private organizationsItems: Item[];
    private groupsItems: Item[];
    private linesItems: Item[];

    private flattedItems: Item[];

    private pages: Page[];

    constructor(props: Props) {
        this.organizationGroupsList = props.organizationGroupsList;
        this.groups = lodash.flatMap(props.organizationGroupsList.map((organizationGroup) => organizationGroup.groups));
        this.lines = lodash.flatMap(this.groups.map((group) => group.lines));

        this.flattedItems = [];

        this.organizationsItems = [];
        this.groupsItems = [];
        this.linesItems = [];

        this.pages = [];

        this.initItems();
    }

    public getPages() {
        this.flatItems();
        this.splitItemsByPage();

        return this.pages;
    }

    private initItems(): void {
        this.organizationGroupsList.forEach((organizationGroup) => {
            this.organizationsItems.push({
                id: organizationGroup.id,
                height: ORGANIZATION_HEIGHT,
                type: ItemType.ORGANIZATION,
            });

            organizationGroup.groups.forEach((group) => {
                this.groupsItems.push({
                    id: group.id,
                    height: 10,
                    type: ItemType.GROUP,
                });

                group.lines.forEach((line) => {
                    this.linesItems.push({
                        id: line.id,
                        height: LINE_HEIGHT,
                        type: ItemType.LINE,
                    });
                });
            });
        });
    }

    private flatItems(): void {
        this.organizationGroupsList.forEach((organizationGroup) => {
            this.flattedItems.push(this.organizationsItems.find(({ id }) => id === organizationGroup.id));

            organizationGroup.groups.forEach((group) => {
                this.flattedItems.push(this.groupsItems.find(({ id }) => id === group.id));

                group.lines.forEach((line) => {
                    this.flattedItems.push(this.linesItems.find(({ id }) => id === line.id));
                });
            });
        });
    }

    private splitItemsByPage(): void {
        const itemsSplitter = new ItemsSplitter({ items: this.flattedItems });

        itemsSplitter.split().forEach((items, index) => {
            this.pages[index] = {
                organizations: this.restoreStructureOrganizations(items),
            };
        });
    }

    private restoreStructureOrganizations(items: Item[]): OrganizationGroups[] {
        const organizationGroupsList: OrganizationGroups[] = [];

        items.forEach((item) => {
            if (item.type === ItemType.ORGANIZATION) {
                const organization = this.organizationGroupsList.find((organization) => organization.id === item.id);
                organizationGroupsList.push({
                    ...organization,
                    groups: [],
                });
            }

            if (item.type === ItemType.GROUP) {
                const group = this.groups.find((group) => group.id === item.id);
                const lastOrganization = lodash.last(organizationGroupsList);

                if (lastOrganization) {
                    lastOrganization.groups.push({
                        ...group,
                        lines: [],
                    });
                } else {
                    const organization: OrganizationGroups = {
                        id: '',
                        name: '',
                        groups: [
                            {
                                ...group,
                                lines: [],
                            },
                        ],
                    };

                    this.organizationGroupsList.push(organization);
                }
            }

            if (item.type === ItemType.LINE) {
                const line = this.lines.find((line) => line.id === item.id);
                const lastOrganization = lodash.last(organizationGroupsList);

                if (lastOrganization) {
                    const lastGroupOfOrganizationGroups = lodash.last(lastOrganization.groups);

                    if (lastGroupOfOrganizationGroups) {
                        lastGroupOfOrganizationGroups.lines.push(line);
                    } else {
                        lastOrganization.groups.push({
                            id: '',
                            lines: [line],
                            activitiesCount: 0,
                            isExpanded: true,
                        });
                    }
                } else {
                    const organization: OrganizationGroups = {
                        id: '',
                        name: '',
                        groups: [
                            {
                                id: '',
                                lines: [line],
                                activitiesCount: 0,
                                isExpanded: true,
                            },
                        ],
                    };

                    organizationGroupsList.push(organization);
                }
            }
        });

        return organizationGroupsList;
    }
}
