import * as React from 'react';
import * as lodash from 'lodash';
import { WithTooltip } from 'sber-marketing-ui';

import {
    CardIndicator as Indicator,
    CardIndicatorColor as IndicatorColor,
    cardIndicatorThickness as IndicatorThickness,
} from '@common/Card';

import * as style from './BudgetIds.scss';

interface Props extends BindBudgetIdsButtonProps {
    showBindBudgetIdsButton: boolean;
    budgetItemsSerialNumbers: number[];
}

const LackBudgetIdsIndicator = () => (
    <div className={style.lackBudgetIdsIndicatorRoot}>
        <Indicator color={IndicatorColor.RED} thickness={IndicatorThickness.NARROW}>
            Отсутствует
        </Indicator>
    </div>
);

interface BindBudgetIdsButtonProps {
    onBudgetItemsButtonClick: React.MouseEventHandler<HTMLDivElement>;
}

const BindBudgetIdsButton: React.FC<BindBudgetIdsButtonProps> = ({ onBudgetItemsButtonClick }) => (
    <div className={style.budgetItemsButtonRoot} onClick={onBudgetItemsButtonClick}>
        Привязать
    </div>
);

export const BudgetIds: React.FC<Props> = ({
    showBindBudgetIdsButton,
    budgetItemsSerialNumbers,
    onBudgetItemsButtonClick,
}) => {
    const hasBudgetItemsSerialNumbers = !lodash.isEmpty(budgetItemsSerialNumbers);
    const serialNumbers = budgetItemsSerialNumbers.join(', ');

    return (
        <div className={style.budgetItems}>
            {hasBudgetItemsSerialNumbers && (
                <WithTooltip spanWrapper content={`ID бюджета: ${serialNumbers}`}>
                    <React.Fragment>
                        <span className={style.budgetItemsTitle}>ID бюджета:</span>
                        <span className={style.budgetItemsSerialNumbers}>{serialNumbers}</span>
                        {showBindBudgetIdsButton && (
                            <BindBudgetIdsButton onBudgetItemsButtonClick={onBudgetItemsButtonClick} />
                        )}
                    </React.Fragment>
                </WithTooltip>
            )}

            {!hasBudgetItemsSerialNumbers && (
                <>
                    <span className={style.budgetItemsTitle}>ID бюджета:</span>
                    <LackBudgetIdsIndicator />
                    {showBindBudgetIdsButton && (
                        <BindBudgetIdsButton onBudgetItemsButtonClick={onBudgetItemsButtonClick} />
                    )}
                </>
            )}
        </div>
    );
};

// function convertSerialNumbers(serialNumbers: number[]): string {
//     const MAX_COUNT_SERIAL_NUMBERS_DISPLAYED = 3;
//
//     return serialNumbers.reduce((acc, serialNumber, i) => {
//         const maxCountSerialNumberNotExceeded = i < MAX_COUNT_SERIAL_NUMBERS_DISPLAYED;
//         const needEllipses = i == MAX_COUNT_SERIAL_NUMBERS_DISPLAYED;
//         const isAccEmpty = !acc;
//
//         return maxCountSerialNumberNotExceeded
//             ? `${acc}${isAccEmpty ? '' : ','} ${serialNumber}`
//             : `${acc}${needEllipses ? '...' : ''}`;
//     }, '');
// }
