import { ComponentType, createElement } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { omit } from 'lodash';

import { StoreState } from '@store';
import { getError, getStatus, getUserOrganizationsIds, loadUserOrganizations } from '@store/userOrganizations';
import { UserOrganizationsIdsWrapper } from './UserOrganizationsIdsWrapper';
import {
    UserOrganizationsIdsMappedActions,
    UserOrganizationsIdsMappedState,
    PropsWithUserOrganizationsIds,
} from './types';

const mapStateToProps = (state: StoreState): UserOrganizationsIdsMappedState => ({
    userOrganizationsIds: getUserOrganizationsIds(state),
    userOrganizationsIdsLoadingError: getError(state),
    userOrganizationsIdsLoadingStatus: getStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch<StoreState>): UserOrganizationsIdsMappedActions => ({
    loadUserOrganizationsIds: () => dispatch(loadUserOrganizations(null)),
});

const withStore = connect<UserOrganizationsIdsMappedState, UserOrganizationsIdsMappedActions, {}, StoreState>(
    mapStateToProps,
    mapDispatchToProps,
);

/** High-Order Component (HOC) which provides authorized user's related organizations identifiers */
export function withUserOrganizationsIds<P extends {} = {}>(
    Component: ComponentType<P>,
): ComponentType<PropsWithUserOrganizationsIds<P>> {
    const displayName = `withUserOrganizationsIds(${Component.displayName || Component.name || 'Unknown'})`;
    const Wrapper = class extends UserOrganizationsIdsWrapper<P> {
        public static displayName: string = displayName;

        public render() {
            return createElement(
                Component,
                omit<any>(this.props, ['children']) as PropsWithUserOrganizationsIds<P>,
                this.props.children,
            );
        }
    };
    return withStore(Wrapper as any);
}
