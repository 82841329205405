import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { OrganizationType } from 'sber-marketing-types/frontend';

import { load } from '../actions';

import { OrganizationTypeReducer } from './OrganizationTypeReducer';

const organizationType: OrganizationType.OrganizationTypeParams[] = [];

const initialState = {
    organizationType,
};

export const organizationTypeReducer = reducerWithInitialState(initialState).case(load, OrganizationTypeReducer.load);
