// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-Content-List-Items-_styles__root{font-family:'Open Sans';font-weight:400}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-Content-List-Items-_styles__text{font-size:14px;color:#000000}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-Content-List-Items-_styles__count{font-size:12px;color:#AAAAAA}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/CreationForm/GeneratedTextEditor/Editor/Footer/ConfirmButton/Popup/Content/List/Items/styles.scss"],"names":[],"mappings":"AAAA,iLACI,uBAAwB,CACxB,eAAgB,CACnB,iLAGG,cAAe,CACf,aAAc,CACjB,kLAGG,cAAe,CACf,aAAc","sourcesContent":[".root {\n    font-family: 'Open Sans';\n    font-weight: 400;\n}\n\n.text {\n    font-size: 14px;\n    color: #000000;\n}\n\n.count {\n    font-size: 12px;\n    color: #AAAAAA;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-Content-List-Items-_styles__root",
	"text": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-Content-List-Items-_styles__text",
	"count": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-Content-List-Items-_styles__count"
};
export default ___CSS_LOADER_EXPORT___;
