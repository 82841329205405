import { actionCreatorFactory } from 'typescript-fsa';

import { LoadingStatus } from '@store/commonTypes';

import { LoadStagesContentParams, EditStageParams, FillStagesParams } from '../types';

const creator = actionCreatorFactory('ACTIVITY_STAGES_SYNC');

export const setLoadingStatus = creator<LoadingStatus>('SET_LOADING_STATUS');
export const setStagesEditorPopupVisibility = creator<boolean>('SET_STAGES_EDITOR_POPUP_VISIBILITY');
export const loadStagesContent = creator<LoadStagesContentParams>('LOAD_STAGES_CONTENT');
export const setStageStartDate = creator<EditStageParams>('SET_STAGE_START_DATE');
export const setStageEndDate = creator<EditStageParams>('SET_STAGE_END_DATE');
export const setStageActiveStatus = creator<EditStageParams>('SET_STAGE_ACTIVE_STATUS');
export const resetStagesEditorPopup = creator('RESET_STAGES_EDITOR_POPUP');
export const fillPendingStages = creator<FillStagesParams>('FILL_PENDING_STAGES');
export const fillExistingAndPendingStages = creator<FillStagesParams>('FILL_EXISTING_AND_PENDING_STAGES');
export const setStateRequestInProgress = creator<boolean>('SET_STATE_REQUEST_IN_PROGRESS');
export const resetActivityStages = creator('RESET_ACTIVITY_STAGES');
