import * as lodash from 'lodash';
import * as moment from 'moment';

import type { MediaplanItem } from '@store/autopilotTv/types';

const GROUP_NAMES_BY_DIRECTION_ID = {
    1: 'Национальное тв — рейтинговые каналы',
    3: 'Не эфирное тв — рейтинговые каналы',
};

const GROUP_NAMES_IN_TOTAL_BY_DIRECTION_ID = {
    1: 'НАЦ ТВ',
    3: 'НЕ ЭФ ТВ',
};

export const enum ColumnHeaderType {
    Text = 'text',
    DoubleText = 'doubleText',
}

export const enum LineType {
    GroupName = 'groupName',
    Line = 'line',
    GroupTotal = 'groupTotal',
    Total = 'total',
    Separator = 'separator',
}

export const enum ColumnName {
    TvChannel = 'tvChannel',
    Affinity = 'affinity',
    TrpsSplit = 'trpsSplit',
    Trps = 'trps',
    Trps20 = 'trps20',
    Gps = 'gps',
    Gps20 = 'gps20',
    Tccp = 'tccp',
    TotalFunds = 'totalFunds',
}

export const enum CellType {
    Text = 'text',
    Input = 'input',
    FundsInput = 'fundsInput',
}

export interface AccessorParams {
    id: string;
    line: MediaplanItem;
    allLines?: MediaplanItem[];
    groupLines?: MediaplanItem[];
    group?: { id: number; name: string };
}

export type Accessor = (params: AccessorParams) => any;
export type TitleAccessor = (params: AccessorParams) => React.ReactText;
export type ValueSetter = (params: AccessorParams, value: any) => Promise<void>;
export type ItemsAccessor = (params: AccessorParams) => any[];
export type DescriptionAccessor = (params: AccessorParams) => string;
export type CustomStyleAccessor = (params: AccessorParams) => React.CSSProperties;

export interface ColumnParams {
    title: string | [string, string];
    headerType: ColumnHeaderType;
    type: Record<LineType, CellType>;
    defaultWidth: number;
    disableWidthChange?: boolean;
    readOnly?: Partial<Record<LineType, boolean>>;
    customStyle?: Partial<Record<LineType, CustomStyleAccessor>>;
    linkedColumns?: Partial<Record<LineType, ColumnName[]>>;
    getTitle?: Partial<Record<LineType, TitleAccessor>>;
    getValue: Record<LineType, Accessor>;
    setValue?: Partial<Record<LineType, ValueSetter>>;
    getItems?: Partial<Record<LineType, ItemsAccessor>>;
    getDescription?: Partial<Record<LineType, DescriptionAccessor>>;
}

export interface ColumnsConfigParams {
    periods: { dateStart: string; dateEnd: string }[];
}

export const MakeColumnsConfig: (columnsConfigParams: ColumnsConfigParams) => { [columnName: string]: ColumnParams } = (
    columnsConfigParams: ColumnsConfigParams,
) => ({
    [ColumnName.TvChannel]: {
        title: 'ТВ канал',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.GroupName]: CellType.Text,
            [LineType.Line]: CellType.Text,
            [LineType.GroupTotal]: CellType.Text,
            [LineType.Total]: CellType.Text,
            [LineType.Separator]: null,
        },
        customStyle: {
            [LineType.GroupName]: (params: AccessorParams) => ({
                fontWeight: 600,
                color: '#ffffff',
                fontSize: 16,
                textTransform: 'uppercase',
            }),
            [LineType.GroupTotal]: (params: AccessorParams) => ({ fontWeight: 600 }),
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        defaultWidth: 200,
        readOnly: {
            [LineType.GroupName]: true,
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
            [LineType.Total]: true,
            [LineType.Separator]: true,
        },
        getValue: {
            [LineType.GroupName]: (params: AccessorParams) => GROUP_NAMES_BY_DIRECTION_ID[params.group.id],
            [LineType.Line]: (params: AccessorParams) => params.line.name,
            [LineType.GroupTotal]: (params: AccessorParams) =>
                `Итого ${GROUP_NAMES_IN_TOTAL_BY_DIRECTION_ID[params.group.id]}`,
            [LineType.Total]: (params: AccessorParams) => 'Итого по всем каналам',
            [LineType.Separator]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Affinity]: {
        title: 'Affinity',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.GroupName]: CellType.Text,
            [LineType.Line]: CellType.Input,
            [LineType.GroupTotal]: CellType.Text,
            [LineType.Total]: CellType.Input,
            [LineType.Separator]: null,
        },
        customStyle: {
            [LineType.GroupTotal]: (params: AccessorParams) => ({ fontWeight: 600 }),
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        defaultWidth: 100,
        readOnly: {
            [LineType.GroupName]: true,
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
            [LineType.Total]: true,
            [LineType.Separator]: true,
        },
        getValue: {
            [LineType.GroupName]: (params: AccessorParams) => null,
            [LineType.Line]: (params: AccessorParams) => Math.round(params.line.affinity * 100),
            [LineType.GroupTotal]: (params: AccessorParams) => {
                const groupTrp = lodash.sumBy(params.groupLines, (line) =>
                    lodash.sumBy(line.intervals, (item) => item.properties.trp),
                );

                const groupGrp = lodash.sumBy(params.groupLines, (line) =>
                    lodash.sumBy(line.intervals, (item) => item.properties.grps),
                );

                return Math.round((groupTrp / groupGrp) * 100);
            },
            [LineType.Total]: (params: AccessorParams) => {
                const totalTrp = lodash.sumBy(params.allLines, (line) =>
                    lodash.sumBy(line.intervals, (item) => item.properties.trp),
                );

                const totalGrp = lodash.sumBy(params.allLines, (line) =>
                    lodash.sumBy(line.intervals, (item) => item.properties.grps),
                );

                return Math.round((totalTrp / totalGrp) * 100);
            },
            [LineType.Separator]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.TrpsSplit]: {
        title: `TRP's split, %`,
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.GroupName]: CellType.Text,
            [LineType.Line]: CellType.Input,
            [LineType.GroupTotal]: CellType.Input,
            [LineType.Total]: CellType.Input,
            [LineType.Separator]: null,
        },
        customStyle: {
            [LineType.GroupTotal]: (params: AccessorParams) => ({ fontWeight: 600 }),
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        defaultWidth: 100,
        readOnly: {
            [LineType.GroupName]: true,
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
            [LineType.Total]: true,
            [LineType.Separator]: true,
        },
        getValue: {
            [LineType.GroupName]: (params: AccessorParams) => null,
            [LineType.Line]: (params: AccessorParams) => {
                const trp = params.line.totalInterval.properties.trp;
                const totalTrp = lodash.sumBy(params.allLines, (line) => line.totalInterval.properties.trp);

                return roundNumber((trp / totalTrp) * 100);
            },
            [LineType.GroupTotal]: (params: AccessorParams) => {
                const groupTrp = lodash.sumBy(params.groupLines, (line) => line.totalInterval.properties.trp);
                const totalTrp = lodash.sumBy(params.allLines, (line) => line.totalInterval.properties.trp);

                return roundNumber((groupTrp / totalTrp) * 100);
            },
            [LineType.Total]: (params: AccessorParams) => 100,
            [LineType.Separator]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Trps]: {
        title: `TRP's`,
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.GroupName]: CellType.Text,
            [LineType.Line]: CellType.Text,
            [LineType.GroupTotal]: CellType.Text,
            [LineType.Total]: CellType.Text,
            [LineType.Separator]: null,
        },
        customStyle: {
            [LineType.GroupTotal]: (params: AccessorParams) => ({ fontWeight: 600 }),
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        defaultWidth: 100,
        readOnly: {
            [LineType.GroupName]: true,
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
            [LineType.Total]: true,
            [LineType.Separator]: true,
        },
        getValue: {
            [LineType.GroupName]: (params: AccessorParams) => null,
            [LineType.Line]: (params: AccessorParams) => params.line.totalInterval.properties.trp,
            [LineType.GroupTotal]: (params: AccessorParams) =>
                roundNumber(lodash.sumBy(params.groupLines, (line) => line.totalInterval.properties.trp)),
            [LineType.Total]: (params: AccessorParams) =>
                roundNumber(lodash.sumBy(params.allLines, (line) => line.totalInterval.properties.trp)),
            [LineType.Separator]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Trps20]: {
        title: `TRP's 20`,
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.GroupName]: CellType.Text,
            [LineType.Line]: CellType.Text,
            [LineType.GroupTotal]: CellType.Text,
            [LineType.Total]: CellType.Text,
            [LineType.Separator]: null,
        },
        customStyle: {
            [LineType.GroupTotal]: (params: AccessorParams) => ({ fontWeight: 600 }),
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        defaultWidth: 100,
        readOnly: {
            [LineType.GroupName]: true,
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
            [LineType.Total]: true,
            [LineType.Separator]: true,
        },
        getValue: {
            [LineType.GroupName]: (params: AccessorParams) => null,
            [LineType.Line]: (params: AccessorParams) => params.line.totalInterval.properties.trps20,
            [LineType.GroupTotal]: (params: AccessorParams) =>
                roundNumber(lodash.sumBy(params.groupLines, (line) => line.totalInterval.properties.trps20)),
            [LineType.Total]: (params: AccessorParams) =>
                roundNumber(lodash.sumBy(params.allLines, (line) => line.totalInterval.properties.trps20)),
            [LineType.Separator]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Gps]: {
        title: `GRP's`,
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.GroupName]: CellType.Text,
            [LineType.Line]: CellType.Text,
            [LineType.GroupTotal]: CellType.Text,
            [LineType.Total]: CellType.Text,
            [LineType.Separator]: null,
        },
        customStyle: {
            [LineType.GroupTotal]: (params: AccessorParams) => ({ fontWeight: 600 }),
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        defaultWidth: 100,
        readOnly: {
            [LineType.GroupName]: true,
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
            [LineType.Total]: true,
            [LineType.Separator]: true,
        },
        getValue: {
            [LineType.GroupName]: (params: AccessorParams) => null,
            [LineType.Line]: (params: AccessorParams) => params.line.totalInterval.properties.grps,
            [LineType.GroupTotal]: (params: AccessorParams) =>
                roundNumber(lodash.sumBy(params.groupLines, (line) => line.totalInterval.properties.grps)),
            [LineType.Total]: (params: AccessorParams) =>
                roundNumber(lodash.sumBy(params.allLines, (line) => line.totalInterval.properties.grps)),
            [LineType.Separator]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Gps20]: {
        title: `GRP's 20`,
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.GroupName]: CellType.Text,
            [LineType.Line]: CellType.Text,
            [LineType.GroupTotal]: CellType.Text,
            [LineType.Total]: CellType.Text,
            [LineType.Separator]: null,
        },
        customStyle: {
            [LineType.GroupTotal]: (params: AccessorParams) => ({ fontWeight: 600 }),
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        defaultWidth: 100,
        readOnly: {
            [LineType.GroupName]: true,
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
            [LineType.Total]: true,
            [LineType.Separator]: true,
        },
        getValue: {
            [LineType.GroupName]: (params: AccessorParams) => null,
            [LineType.Line]: (params: AccessorParams) => params.line.totalInterval.properties.grps20,
            [LineType.GroupTotal]: (params: AccessorParams) =>
                roundNumber(lodash.sumBy(params.groupLines, (line) => line.totalInterval.properties.grps20)),
            [LineType.Total]: (params: AccessorParams) =>
                roundNumber(lodash.sumBy(params.allLines, (line) => line.totalInterval.properties.grps20)),
            [LineType.Separator]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Tccp]: {
        title: `TCPP incl. Duration K`,
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.GroupName]: CellType.Text,
            [LineType.Line]: CellType.FundsInput,
            [LineType.GroupTotal]: CellType.FundsInput,
            [LineType.Total]: CellType.FundsInput,
            [LineType.Separator]: null,
        },
        customStyle: {
            [LineType.GroupTotal]: (params: AccessorParams) => ({ fontWeight: 600 }),
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        defaultWidth: 100,
        readOnly: {
            [LineType.GroupName]: true,
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
            [LineType.Total]: true,
            [LineType.Separator]: true,
        },
        getValue: {
            [LineType.GroupName]: (params: AccessorParams) => null,
            [LineType.Line]: (params: AccessorParams) => {
                const lineTotalBudget = params.line.totalInterval.properties.budget;
                const trp = params.line.totalInterval.properties.trp;

                return roundNumber(lineTotalBudget / trp);
            },
            [LineType.GroupTotal]: (params: AccessorParams) => {
                const groupTotalBudget = lodash.sumBy(
                    params.groupLines,
                    (line) => line.totalInterval.properties.budget,
                );
                const groupTrp = lodash.sumBy(params.groupLines, (line) => line.totalInterval.properties.trp);

                return roundNumber(groupTotalBudget / groupTrp);
            },
            [LineType.Total]: (params: AccessorParams) => {
                const totalBudget = lodash.sumBy(params.allLines, (line) => line.totalInterval.properties.budget);
                const totalTrp = lodash.sumBy(params.allLines, (line) => line.totalInterval.properties.trp);

                return roundNumber(totalBudget / totalTrp);
            },
            [LineType.Separator]: (params: AccessorParams) => null,
        },
    },
    ...columnsConfigParams.periods.reduce((acc, period, periodIndex) => {
        acc[`periodPercents${periodIndex}`] = {
            title: ['Split Budget, %', moment(period.dateStart).format('MMMM')],
            headerType: ColumnHeaderType.DoubleText,
            type: {
                [LineType.GroupName]: CellType.Text,
                [LineType.Line]: CellType.Input,
                [LineType.GroupTotal]: CellType.Input,
                [LineType.Total]: CellType.Input,
                [LineType.Separator]: null,
            },
            customStyle: {
                [LineType.GroupTotal]: (params: AccessorParams) => ({ fontWeight: 600 }),
                [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
            },
            defaultWidth: 100,
            readOnly: {
                [LineType.GroupName]: true,
                [LineType.Line]: true,
                [LineType.GroupTotal]: true,
                [LineType.Total]: true,
                [LineType.Separator]: true,
            },
            getValue: {
                [LineType.GroupName]: (params: AccessorParams) => null,
                [LineType.Line]: (params: AccessorParams) => {
                    const budget = params.line.intervals[periodIndex].properties.budget;
                    const totalBudget = lodash.sumBy(
                        params.allLines,
                        (line) => line.intervals[periodIndex].properties.budget,
                    );

                    return roundNumber((budget / totalBudget) * 100);
                },
                [LineType.GroupTotal]: (params: AccessorParams) => {
                    const groupBudget = lodash.sumBy(
                        params.groupLines,
                        (line) => line.intervals[periodIndex].properties.budget,
                    );
                    const totalBudget = lodash.sumBy(
                        params.allLines,
                        (line) => line.intervals[periodIndex].properties.budget,
                    );

                    return roundNumber((groupBudget / totalBudget) * 100);
                },
                [LineType.Total]: (params: AccessorParams) => 100,
                [LineType.Separator]: (params: AccessorParams) => null,
            },
        } as ColumnParams;

        acc[`periodFunds${periodIndex}`] = {
            title: ['Бюджет', moment(period.dateStart).format('MMMM')],
            headerType: ColumnHeaderType.DoubleText,
            type: {
                [LineType.GroupName]: CellType.Text,
                [LineType.Line]: CellType.FundsInput,
                [LineType.GroupTotal]: CellType.FundsInput,
                [LineType.Total]: CellType.FundsInput,
                [LineType.Separator]: null,
            },
            customStyle: {
                [LineType.GroupTotal]: (params: AccessorParams) => ({ fontWeight: 600 }),
                [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
            },
            defaultWidth: 100,
            readOnly: {
                [LineType.GroupName]: true,
                [LineType.Line]: true,
                [LineType.GroupTotal]: true,
                [LineType.Total]: true,
                [LineType.Separator]: true,
            },
            getValue: {
                [LineType.GroupName]: (params: AccessorParams) => null,
                [LineType.Line]: (params: AccessorParams) => params.line.intervals[periodIndex].properties.budget,
                [LineType.GroupTotal]: (params: AccessorParams) =>
                    lodash.sumBy(params.groupLines, (line) => line.intervals[periodIndex].properties.budget),
                [LineType.Total]: (params: AccessorParams) =>
                    lodash.sumBy(params.allLines, (line) => line.intervals[periodIndex].properties.budget),
                [LineType.Separator]: (params: AccessorParams) => null,
            },
        } as ColumnParams;

        return acc;
    }, {}),
    [ColumnName.TotalFunds]: {
        title: ['Бюджет', 'За весь период'],
        headerType: ColumnHeaderType.DoubleText,
        type: {
            [LineType.GroupName]: CellType.Text,
            [LineType.Line]: CellType.FundsInput,
            [LineType.GroupTotal]: CellType.FundsInput,
            [LineType.Total]: CellType.FundsInput,
            [LineType.Separator]: null,
        },
        customStyle: {
            [LineType.GroupTotal]: (params: AccessorParams) => ({ fontWeight: 600 }),
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.GroupName]: true,
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
            [LineType.Total]: true,
            [LineType.Separator]: true,
        },
        getValue: {
            [LineType.GroupName]: (params: AccessorParams) => null,
            [LineType.Line]: (params: AccessorParams) => params.line.totalInterval.properties.budget,
            [LineType.GroupTotal]: (params: AccessorParams) =>
                lodash.sumBy(params.groupLines, (line) => line.totalInterval.properties.budget),
            [LineType.Total]: (params: AccessorParams) =>
                lodash.sumBy(params.allLines, (line) => line.totalInterval.properties.budget),
            [LineType.Separator]: (params: AccessorParams) => null,
        },
    },
});

export const MakeTableColumns: (columnsConfigParams: ColumnsConfigParams) => string[] = (columnsConfigParams) => [
    ColumnName.Affinity,
    ...columnsConfigParams.periods.map((item, index) => `periodPercents${index}`),
    ColumnName.TrpsSplit,
    ColumnName.Trps,
    ColumnName.Trps20,
    ColumnName.Gps,
    ColumnName.Gps20,
    ColumnName.Tccp,
    ...columnsConfigParams.periods.map((item, index) => `periodFunds${index}`),
    ColumnName.TotalFunds,
];

export const leftFixedColumns: ColumnName[] = [ColumnName.TvChannel];

export const rightFixedColumns: ColumnName[] = [];

function roundNumber(value: number, digitsAfterComma = 2): string {
    const formatedValue = value.toFixed(digitsAfterComma);

    const [decimalPart, fractionPart] = formatedValue.split('.');

    return `${decimalPart}${fractionPart ? `.${fractionPart}` : ''}`;
}
