import { actionCreatorFactory } from 'typescript-fsa';
import type { Features, FeaturesId, ChangeOptionTextSelectionParams, ChangeTextOptionValueParams } from '../types';

const actionCreator = actionCreatorFactory('GENERATION_TEXT_FORM');

export const addFeatures = actionCreator<void>('ADD_FEATURES');
export const removeFeatures = actionCreator<FeaturesId>('REMOVE_FEATURES');
export const changeFeatures = actionCreator<Features>('CHANGE_FEATURES');
export const reset = actionCreator<void>('RESET');
export const resetTexts = actionCreator<void>('RESET_TESTS');
export const changeTextOptionSelection = actionCreator<ChangeOptionTextSelectionParams>('CHANGE_TEXT_OPTION_SELECTION');
export const changeTextOptionValue = actionCreator<ChangeTextOptionValueParams>('CHANGE_TEXT_OPTION_VALUE');
