import * as React from 'react';

import {
    Button_redesign as Button,
    ButtonTheme_redesign,
    CentralContent,
    LeftSidebar,
    ResponsiveLayout,
    LayoutTheme,
    RightSidebar,
} from 'sber-marketing-ui';
import { BriefType } from '../enums';

import { QuickAccessIcons } from '@common/QuickAccessIcons';

import * as style from './Header.scss';

interface Props {
    briefType: BriefType;
    disabledSaveButton: boolean;
    onSaveButtonClick: () => void;
}

export const Header: React.FC<Props> = (props) => {
    return (
        <div className={style.root}>
            <ResponsiveLayout theme={LayoutTheme.Large}>
                <LeftSidebar />

                <CentralContent>
                    <div className={style.center}>
                        <div className={style.title}>
                            {props.briefType === BriefType.ActivityBrief ? 'Брифы' : 'Бриф'}
                        </div>
                    </div>
                </CentralContent>

                <RightSidebar>
                    <HeaderRightContent {...props} />
                </RightSidebar>
            </ResponsiveLayout>
        </div>
    );
};

export const HeaderRightContent: React.FC<Partial<Props>> = ({ disabledSaveButton, onSaveButtonClick }) => {
    return (
        <div className={style.right}>
            <div className={style.quickAccessIcons}>
                <QuickAccessIcons />
            </div>

            <div className={style.buttonWrapper}>
                <Button
                    qaId="briefPageSaveButton"
                    theme={ButtonTheme_redesign.MainRoundedUpdated}
                    onClick={onSaveButtonClick}
                    disabled={disabledSaveButton}
                >
                    Готово
                </Button>
            </div>
        </div>
    );
};
