import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { MediaplanItem } from '@store/autopilotTv/types';
import { CellPosition, ColumnHeaderParams, ColumnName, LineId } from '../../types';
import { Accessor, AccessorParams, LineType, ColumnHeaderType, ColumnParams } from '../../ColumnsConfig';

import { DoubleTextColumnHeader, TextColumnHeader } from '../../ColumnHeaderTypes';

export const ColumnHeaderComponentsByType: Record<ColumnHeaderType, React.ClassType<any, any, any>> = {
    [ColumnHeaderType.Text]: TextColumnHeader,
    [ColumnHeaderType.DoubleText]: DoubleTextColumnHeader,
};

interface Props {
    getLine: (lineId: LineId) => MediaplanItem;
    getAllLines: () => MediaplanItem[];
    getColumnsConfig: () => { [columnName: string]: ColumnParams };
}

export class ColumnHeaderFactory {
    private getLine: (lineId: LineId) => MediaplanItem;
    private getAllLines: () => MediaplanItem[];
    private getColumnsConfig: () => { [columnName: string]: ColumnParams };

    public constructor(props: Props) {
        this.getLine = props.getLine;
        this.getAllLines = props.getAllLines;
        this.getColumnsConfig = props.getColumnsConfig;
    }

    @autobind
    public makeColumnHeaderParams(columnName: ColumnName): ColumnHeaderParams {
        return {
            component: this.getColumnHeaderComponent(columnName),
            columnHeaderProps: this.makeColumnHeaderProps(columnName),
            disableWidthChange: !this.columnWidthCanBeChanged(columnName),
        };
    }

    private getColumnHeaderComponent(columnName: ColumnName): React.ClassType<any, any, any> {
        const columnType = this.getColumnsConfig()[columnName].headerType;

        return ColumnHeaderComponentsByType[columnType];
    }

    private makeColumnHeaderProps(columnName: ColumnName): any {
        const headerType = this.getColumnsConfig()[columnName].headerType;

        let cellProps: any;

        switch (headerType) {
            case ColumnHeaderType.Text:
                cellProps = this.makeTextColumnHeaderProps(columnName as ColumnName);
                break;

            case ColumnHeaderType.DoubleText:
                cellProps = this.makeDoubleTextColumnHeaderProps(columnName as ColumnName);
                break;
        }

        return cellProps;
    }

    private makeTextColumnHeaderProps(columnName: ColumnName): any {
        return {
            title: this.getColumnsConfig()[columnName].title as string,
        };
    }

    private makeDoubleTextColumnHeaderProps(columnName: ColumnName): any {
        return {
            title: this.getColumnsConfig()[columnName].title as [string, string],
        };
    }

    private getCellValue(cellPosition: CellPosition) {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const accessor: Accessor = lodash.get(this.getColumnsConfig(), [columnName, 'getValue', lineType]);

        if (!accessor) {
            return undefined;
        }

        const params = this.makeAccessorParams(cellPosition);

        return accessor(params);
    }

    private getLineType(lineId: LineId): LineType {
        const lineType: LineType = LineType.Line;

        return lineType;
    }

    private makeAccessorParams(cellPosition: CellPosition): AccessorParams {
        const { lineId } = cellPosition;

        const line = this.getLine(lineId);

        return {
            id: lineId,
            line,
        };
    }

    private columnWidthCanBeChanged(columnName: ColumnName): boolean {
        return lodash.get(this.getColumnsConfig(), [columnName, 'disableWidthChange']) !== undefined
            ? !lodash.get(this.getColumnsConfig(), [columnName, 'disableWidthChange'])
            : true;
    }
}
