// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-Description-_Description__description{width:100%;text-overflow:ellipsis;white-space:pre-wrap}.src-client-modules-common-Description-_Description__description ul{list-style:disc}.src-client-modules-common-Description-_Description__description ul,.src-client-modules-common-Description-_Description__description ol{padding-left:20px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/Description/Description.scss"],"names":[],"mappings":"AAAA,iEACI,UAAW,CAEX,sBAAuB,CAEvB,oBAAqB,CALzB,oEAQQ,eAAgB,CARxB,wIAYQ,iBAAkB","sourcesContent":[".description {\n    width: 100%;\n\n    text-overflow: ellipsis;\n\n    white-space: pre-wrap;\n\n    ul {\n        list-style: disc;\n    }\n\n    ul, ol {\n        padding-left: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "src-client-modules-common-Description-_Description__description"
};
export default ___CSS_LOADER_EXPORT___;
