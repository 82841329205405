import * as React from 'react';
import * as lodash from 'lodash';

import * as style from './TextareaWithLinkCell.scss';

import {
    WithTooltip,
    Icon,
    IconType,
    Textarea_redesign as Textarea,
    Textarea_theme as TextareaTheme,
} from 'sber-marketing-ui';

interface Props {
    edit: boolean;
    title: string;
    url?: string;
    urlTooltip?: string;
    placeholder: string;
    customStyle?: any;
    onValueChange: (value: string) => void;
}

export const TextareaWithLinkCell = ({
    edit,
    title,
    url,
    urlTooltip,
    placeholder,
    customStyle,
    onValueChange,
}: Props): JSX.Element => {
    const [isHovered, setHoveredStatus] = React.useState(false);

    const textareaRef = React.useRef<Textarea>();
    const lastClickedCharacterIndexRef = React.useRef(null);

    React.useEffect(() => {
        if (edit) {
            const index = lastClickedCharacterIndexRef.current || -1;

            const textareaElement = textareaRef.current.getTextElement();

            textareaElement.setSelectionRange(index, index);
            textareaElement.focus();
        } else {
            lastClickedCharacterIndexRef.current = null;
        }
    }, [edit]);

    function onMouseEnter() {
        setHoveredStatus(true);
    }

    function onMouseLeave() {
        setHoveredStatus(false);
    }

    function onCharacterClick(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
        const spanElement = event.target as HTMLSpanElement;

        const index = lodash.findIndex(spanElement.parentElement.childNodes, spanElement);

        lastClickedCharacterIndexRef.current = index + 1;
    }

    return (
        <div className={style.root} style={{ ...customStyle }}>
            {!edit && (
                <>
                    <WithTooltip content={title} className={style.tooltipWrapper}>
                        <div className={style.title} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                            {!isHovered && (title || '—')}

                            {isHovered &&
                                title
                                    .toString()
                                    .split('')
                                    .map((item, index) => (
                                        <span key={index} onClick={onCharacterClick}>
                                            {item}
                                        </span>
                                    ))}
                        </div>
                    </WithTooltip>

                    {url && (
                        <a className={style.link} title={urlTooltip} href={url} target="_blank">
                            <Icon type={IconType.URL} svgSize={14} />
                        </a>
                    )}
                </>
            )}

            {edit && (
                <WithTooltip content={title} className={style.tooltipWrapper}>
                    <Textarea
                        ref={textareaRef}
                        value={title}
                        placeholder={placeholder}
                        theme={TextareaTheme.Borderless}
                        onBlur={onValueChange}
                        autoFocus
                    />
                </WithTooltip>
            )}
        </div>
    );
};
