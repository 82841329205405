import * as React from 'react';

interface Value {
    selectedTabIndex: number;
    changeSelectedTabIndex: (tabIndex: number) => void;
}

export const TabBarContext = React.createContext<Value>({
    selectedTabIndex: 0,
    changeSelectedTabIndex: () => {},
});
