import * as React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import * as style from './Params.scss';

import { Mode, Correction, CardBudgetItem, FieldName, CorrectionType } from './types';

import { Icon, IconType } from 'sber-marketing-ui';
import { BudgetWindow } from './BudgetWindow';
import { SapComment } from './SapComment';

export interface InfoBlockParams {
    title: string;
    content: string;
    link?: string;
}

interface Props {
    canEditBudgetItem: boolean;
    mode: Mode;
    isTooltipVisible: boolean;
    fields: Record<FieldName, InfoBlockParams>;
    budgetItem: CardBudgetItem;
    corrections: Correction[];
    hoveredCorrectionCardId: string;
    refObject: React.RefObject<HTMLDivElement>;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
    onPinButtonClick: () => void;
    onCloseButtonClick: () => void;
    updateCorrections: () => Promise<void>;
    updateCard: () => Promise<void>;
}

export function Params({
    canEditBudgetItem,
    mode,
    isTooltipVisible,
    fields,
    budgetItem,
    corrections,
    hoveredCorrectionCardId,
    refObject,
    onMouseEnter,
    onMouseLeave,
    onPinButtonClick,
    onCloseButtonClick,
    updateCorrections,
    updateCard,
}: Props): JSX.Element {
    const sapCommentCorrection =
        corrections.find((item) => item.type === CorrectionType.BudgetItem && item.params.sapComment) || null;

    const correctionName = sapCommentCorrection ? sapCommentCorrection.params.sapComment : null;

    return (
        <React.Fragment>
            <div
                className={style.root}
                {...{
                    'qa-id': 'dashboardRightSidebarBudgetInfoParams',
                }}
            >
                <InfoBlock
                    qaId="dashboardRightSidebarBudgetInfoId"
                    params={fields[FieldName.Id]}
                    className={style.info}
                />
                <SapComment
                    canEditBudgetItem={canEditBudgetItem}
                    className={style.info}
                    budgetItemId={budgetItem.id}
                    name={fields[FieldName.SapComment].content}
                    correctionName={correctionName}
                    updateCorrections={updateCorrections}
                    updateCard={updateCard}
                />
                <InfoBlock
                    qaId="dashboardRightSidebarBudgetInfoTool"
                    params={fields[FieldName.Tool]}
                    className={style.info}
                />
                <InfoBlock
                    qaId="dashboardRightSidebarBudgetInfoProduct"
                    params={fields[FieldName.Product]}
                    className={style.info}
                />
                <InfoBlock
                    qaId="dashboardRightSidebarBudgetInfoActivity"
                    params={fields[FieldName.Activity]}
                    className={style.info}
                />
                <InfoBlock
                    qaId="dashboardRightSidebarBudgetInfoBlock"
                    params={fields[FieldName.Block]}
                    className={style.info}
                />

                <div className={style.line}>
                    <InfoBlock
                        qaId="dashboardRightSidebarBudgetInfoPlan"
                        params={fields[FieldName.Plan]}
                        className={style.lineItem}
                    />
                    <InfoBlock
                        qaId="dashboardRightSidebarBudgetInfoReserve"
                        params={fields[FieldName.Reserve]}
                        className={style.lineItem}
                    />
                    <InfoBlock
                        qaId="dashboardRightSidebarBudgetInfoFact"
                        params={fields[FieldName.Fact]}
                        className={style.lineItem}
                    />

                    <div
                        ref={refObject}
                        className={style.button}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                    >
                        По месяцам
                        <div className={style.pinWrapper} onClick={onPinButtonClick}>
                            <Icon
                                className={classNames(style.pin, mode === Mode.DRAG_WINDOW && style.active)}
                                type={IconType.PIN}
                                svgSize={14}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {(isTooltipVisible || mode === Mode.DRAG_WINDOW) && (
                <BudgetWindow
                    parentRef={refObject}
                    mode={mode}
                    selectedCorrectionId={hoveredCorrectionCardId}
                    corrections={corrections}
                    budgetItem={budgetItem}
                    onCloseButtonClick={onCloseButtonClick}
                />
            )}
        </React.Fragment>
    );
}

interface InfoBlockProps {
    params: InfoBlockParams;
    className: string;
    qaId: string;
}

function InfoBlock({ params, className, qaId }: InfoBlockProps): JSX.Element {
    const { title, content, link } = params;

    return (
        <div
            className={className}
            {...{
                'qa-id': qaId,
            }}
        >
            <div className={style.title}>{title}</div>

            {link ? (
                <Link className={classNames(style.content, style.contentLink)} to={link} target="_blank">
                    {content}
                </Link>
            ) : (
                <div className={style.content}>{content}</div>
            )}
        </div>
    );
}
