import * as React from 'react';
import { useSelector } from 'react-redux';
import { MenuItem } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';
import {
    userHasPersonalDashboardAccess as userHasPersonalDashboardAccess_,
    userHasPivotTableAccess as userHasPivotTableAccess_,
} from '@store/user';

interface Props {
    currentUrl: string;
}

function useStore() {
    const userHasPersonalDashboardAccess = useSelector((state: StoreState) =>
        userHasPersonalDashboardAccess_(getLoginUser(state)),
    );

    const userHasPivotTableAccess = useSelector((state: StoreState) => userHasPivotTableAccess_(getLoginUser(state)));

    return {
        userHasPersonalDashboardAccess,
        userHasPivotTableAccess,
    };
}

export function DashboardHeaderItems({ currentUrl }: Props): JSX.Element {
    const { userHasPersonalDashboardAccess, userHasPivotTableAccess } = useStore();

    const linkOptions: any[] = [
        {
            qaId: 'calendarWeekDigestButton',
            link: '/managerDashboard',
            title: 'Персональный дашборд',
            disabled: !userHasPersonalDashboardAccess,
        },
        {
            qaId: 'tagsPageButton',
            link: '/tags',
            title: 'Теги',
        },
    ];

    if (userHasPivotTableAccess) {
        linkOptions.push({
            qaId: 'pivotTableButton',
            link: '/pivotTable',
            additionalLinks: ['/pivotTable/lot1', '/pivotTable/lot2'],
            title: 'Сводная таблица ЗНК',
        });
    }

    return (
        <>
            {linkOptions.map((item) => (
                <MenuItem
                    key={item.link}
                    currentUrl={currentUrl}
                    link={item.link}
                    disabled={item.disabled}
                    active={item.link == currentUrl || item.additionalLinks?.includes(currentUrl)}
                >
                    <span>{item.title}</span>
                </MenuItem>
            ))}
        </>
    );
}
