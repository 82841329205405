import * as React from 'react';

import * as styles from './FileInput.scss';

interface Props {
    multiple: boolean;
    inputRef: React.RefObject<HTMLInputElement>;
    onChange: () => void;
}

export function FileInput({ multiple, inputRef, onChange }: Props): JSX.Element {
    return <input className={styles.root} type="file" multiple={multiple} ref={inputRef} onChange={onChange} />;
}
