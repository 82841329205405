import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { AxiosError } from 'axios';
import { isNumber, uniq, compact, isNil } from 'lodash';

import { StoreState } from '../..';
import { FetchError } from '../types';
import { asyncActions } from '../actions';
import { TaskApi } from '../../../api/TaskApi';
import { getTaskInfo, getAssetsByIds } from '../selectors';
import { FileAsset } from '../../commonTypes';
import { runUploadOne } from '../../fileAssets';

export const updateTask = bindThunkAction<StoreState, null, void, FetchError>(
    asyncActions.updateTask,
    async (payload, dispatch, getState): Promise<void> => {
        const {
            id,
            description,
            title,
            workType: workTypeId,
            executorId,
            departmentExecutorId,
            participants,
            authorId,
            deadlineAt,
            assetsIds,
        } = getTaskInfo(getState());

        const assets = getAssetsByIds(getState(), assetsIds);
        const filesToUpload: FileAsset[] = assets.filter(({ file }) => !isNil(file));

        try {
            const executionOrder = isNumber(executorId)
                ? { workTypeId, executorId }
                : { workTypeId, departmentExecutorId };
            const userIds: number[] = uniq(
                compact([authorId, executorId].concat(participants.map((participant) => participant.userId))),
            );
            await TaskApi.editTask(id, { title, description, executionOrder, deadline: deadlineAt as string });
            await TaskApi.updateTaskParticipants(id, userIds);
            await TaskApi.deleteDraftTaskInfo(id);
            for (const asset of filesToUpload) {
                await runUploadOne({
                    dispatch,
                    params: { taskId: id },
                    fileOrAssetOrId: asset,
                });
            }
            window.clearTimeout(window['taskTimer']);
        } catch (error) {
            const { response, message } = <AxiosError>error;
            if (response) {
                throw new FetchError(response.status, response.statusText, message);
            } else {
                throw new FetchError(0, 'Unknown', message);
            }
        }
    },
);
