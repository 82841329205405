// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-ControlButtons-_ControlButtons__root{width:100%;height:32px;display:flex;justify-content:space-between}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-ControlButtons-_ControlButtons__column{position:relative;width:calc(50% - 6px)}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-ControlButtons-_ControlButtons__columnDisabledMask{position:absolute;width:100%;height:100%;background:rgba(255,255,255,0.8)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/InternalTransitionSumEntering/ControlButtons/ControlButtons.scss"],"names":[],"mappings":"AAAA,4IACI,UAAW,CACX,WAAY,CAEZ,YAAa,CACb,6BAA8B,CACjC,8IAGG,iBAAkB,CAClB,qBAAsB,CACzB,0JAGG,iBAAkB,CAElB,UAAW,CACX,WAAY,CAEZ,gCAAiC","sourcesContent":[".root {\n    width: 100%;\n    height: 32px;\n\n    display: flex;\n    justify-content: space-between;\n}\n\n.column {\n    position: relative;\n    width: calc(50% - 6px);\n}\n\n.columnDisabledMask {\n    position: absolute;\n\n    width: 100%;\n    height: 100%;\n    \n    background: rgba(255,255,255,0.8);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-ControlButtons-_ControlButtons__root",
	"column": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-ControlButtons-_ControlButtons__column",
	"columnDisabledMask": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-ControlButtons-_ControlButtons__columnDisabledMask"
};
export default ___CSS_LOADER_EXPORT___;
