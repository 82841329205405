// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-store-budgetPlanning-thunks-xlsxImportExport-loadXLSXContent-SuccessMessage-_SuccessMessage__root{letter-spacing:0.15px;color:#000000;font-family:'Open Sans';font-size:12px;font-weight:600;line-height:16px}.src-client-store-budgetPlanning-thunks-xlsxImportExport-loadXLSXContent-SuccessMessage-_SuccessMessage__rowsCount{color:#1212EF}\n", "",{"version":3,"sources":["webpack://./src/client/store/budgetPlanning/thunks/xlsxImportExport/loadXLSXContent/SuccessMessage/SuccessMessage.scss"],"names":[],"mappings":"AAAA,8GACI,qBAAsB,CAEtB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACpB,mHAGG,aAAc","sourcesContent":[".root {\n    letter-spacing: 0.15px;\n\n    color: #000000;\n\n    font-family: 'Open Sans';\n    font-size: 12px;\n    font-weight: 600;\n    line-height: 16px;\n}\n\n.rowsCount {\n    color: #1212EF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-store-budgetPlanning-thunks-xlsxImportExport-loadXLSXContent-SuccessMessage-_SuccessMessage__root",
	"rowsCount": "src-client-store-budgetPlanning-thunks-xlsxImportExport-loadXLSXContent-SuccessMessage-_SuccessMessage__rowsCount"
};
export default ___CSS_LOADER_EXPORT___;
