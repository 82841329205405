import * as React from 'react';
import { UserResponseParams } from 'sber-marketing-types/frontend';
import { SelectItem } from 'sber-marketing-ui';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';

import { StoreState } from '@store';
import { loadExperts, selectExpert, getBudgetTransferMenuState } from '@store/budgetExecution/budgetTransferMenu';

import { ExpertSelect } from './ExpertSelect';

interface Props extends Partial<MapProps & DispatchProps> {}

interface MapProps {
    experts: UserResponseParams[];
    selectedExpert: number;
}

interface DispatchProps {
    loadExperts: () => void;
    onSelect: (item: SelectItem) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class ExpertSelectBehaviour extends React.PureComponent<Props> {
    public componentDidMount(): void {
        this.props.loadExperts();
    }

    public render(): JSX.Element {
        const { experts, selectedExpert, onSelect } = this.props;

        return <ExpertSelect experts={experts} selectedExpert={selectedExpert} onSelect={onSelect} />;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { entities: experts, selectedExpert } = getBudgetTransferMenuState(state).experts;

    return {
        experts,
        selectedExpert,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        onSelect: (item: SelectItem) => dispatch(selectExpert(item.value as number)),
        loadExperts: () => dispatch(loadExperts(null)),
    };
}
