import * as React from 'react';
import { ImageGalleryItem, ImageGalleryItemTypes } from 'sber-marketing-ui';

import type { CreativeRequestItemFile } from '@api';
import { FileApi } from '@api';

import { Utils } from '@common/Utils';
import { MediaFilesGallery } from '@common/MediaFilesGallery';

import { UploadButton } from './UploadButton';
import { FileCard } from './FileCard';
import { CellEditorWrapper } from '../CellEditorWrapper';

import * as style from './FilesCell.scss';

interface Props {
    title: string;
    files: CreativeRequestItemFile[];
    onValueChange: (file: File) => Promise<void>;
}

function useFilesCellEditTemplate({ files, onValueChange }: Props) {
    const [loading, setLoading] = React.useState(false);
    const [fileIdForGallery, setFileIdForGallery] = React.useState<string>(null);

    const onAddFile = React.useCallback((file) => {
        setLoading(true);

        FileApi.uploadFile({}, file)
            .then((responseFile) => {
                onValueChange({
                    // @ts-ignore
                    id: responseFile.id,
                    name: responseFile.name,
                    originName: responseFile.originName,
                    type: responseFile.type,
                    storage: responseFile.storage,
                    size: responseFile.size,
                });
            })
            .finally(() => setLoading(false));
    }, []);

    function openGallery(fileId: string) {
        setFileIdForGallery(fileId);
    }

    function closeGallery() {
        setFileIdForGallery(null);
    }

    return {
        loading,
        onAddFile,
        fileIdForGallery,
        openGallery,
        closeGallery,
    };
}

export const FilesCellEditTemplate = (props: Props): JSX.Element => {
    const { title, files } = props;
    const { loading, onAddFile, fileIdForGallery, openGallery, closeGallery } = useFilesCellEditTemplate(props);

    return (
        <div
            className={style.root}
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            {title}

            <CellEditorWrapper>
                <div className={style.editor}>
                    <div className={style.title}>
                        Документы&nbsp;
                        <span className={style.counter}>{files.length}</span>
                    </div>

                    <div className={style.items}>
                        {loading && <FileCard loading={true} onDeleteButtonClick={() => {}} />}

                        {files.map((file) => {
                            return (
                                <FileCard
                                    id={file.model.id}
                                    name={file.model.name}
                                    originName={file.model.originName}
                                    size={file.model.size}
                                    type={file.model.type}
                                    key={file.model.id}
                                    onDeleteButtonClick={() => file.model.remove()}
                                    onGalleryButtonClick={() => openGallery(file.model.id)}
                                />
                            );
                        })}
                    </div>

                    <div className={style.buttonWrapper}>
                        <UploadButton addFile={onAddFile} />
                    </div>
                </div>
            </CellEditorWrapper>

            {fileIdForGallery && (
                <FilesGallery fileIdForGallery={fileIdForGallery} files={files} closeGallery={closeGallery} />
            )}
        </div>
    );
};

interface FilesGalleryProps extends Pick<Props, 'files'> {
    fileIdForGallery: string;
    closeGallery: () => void;
}

function useFilesGallery({ files }: FilesGalleryProps) {
    const items: ImageGalleryItem[] = React.useMemo(
        () =>
            files
                .filter((file) => {
                    const type = file.model.type;

                    return Utils.isImage(type) || Utils.isVideo(type) || Utils.isAudio(type);
                })
                .map((file) => {
                    const { id, name, originName, type, size, storage } = file.model;

                    const fileWithParams = FileApi.mapFile(
                        { fileId: id },
                        {
                            id: id,
                            name: name,
                            originName: originName,
                            type: type,
                            size: size,
                            storage: storage,
                        },
                    );

                    let mediaType: ImageGalleryItemTypes;
                    if (Utils.isImage(type)) {
                        mediaType = 'image';
                    } else if (Utils.isVideo(type)) {
                        mediaType = 'video';
                    } else if (Utils.isAudio(type)) {
                        mediaType = 'audio';
                    } else {
                        console.warn(`Unknowm media type ${type}`);
                        mediaType = null;
                    }

                    return {
                        id,
                        originalUrl: fileWithParams.fullSizeUrl,
                        previewUrl: fileWithParams.previewUrl,
                        name,
                        originName,
                        extension: type,
                        type: mediaType,
                    };
                }),
        [files],
    );

    return {
        items,
    };
}

function FilesGallery(props: FilesGalleryProps): JSX.Element {
    const { items } = useFilesGallery(props);
    const { fileIdForGallery, closeGallery } = props;

    return (
        <MediaFilesGallery
            startIndex={items.findIndex((item) => item.id === fileIdForGallery)}
            items={items}
            onOutsideClick={closeGallery}
        />
    );
}
