import * as React from 'react';

import * as style from './ProgrammaticStageActions.scss';

import { Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';

const FIELDS_COUNT = 6;

interface Props {
    canEdit: boolean;
}

export const ProgrammaticStageActions = ({ canEdit }: Props): JSX.Element => {
    const filledFieldsCount = 0;

    return (
        <div className={style.root}>
            <div className={style.title}>
                Параметры и креативы для программатика&nbsp;
                <span className={style.counter}>
                    ({filledFieldsCount}/{FIELDS_COUNT})
                </span>
            </div>

            <div className={style.button}>
                <Button
                    disabled={!canEdit || filledFieldsCount < FIELDS_COUNT}
                    theme={ButtonTheme.MainRounded}
                    onClick={() => {}}
                >
                    К финальному согласованию
                </Button>
            </div>
        </div>
    );
};
