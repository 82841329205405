import * as React from 'react';
import classnames from 'classnames';
import { debounce } from 'lodash';
import { List } from 'react-virtualized';
import { UserVacation } from '@mrm/user-vacation';
import {
    CustomScrollbar_new as CustomScrollbar,
    ScrollValues,
    WithGlobalPopup,
    AbsoluteLikePositionCalculator,
} from 'sber-marketing-ui';

import { Utils } from '@common/Utils';
import { SearchInput } from '@common/SearchInput';

import * as styles from './DropdownEditor.scss';

type Id = number | string;

interface Props {
    items: ItemParams[];
    maxItems?: number;
    subDropdownMaxItems?: number;
    subDropdownTitle?: string;
    addSearch?: boolean;
    searchBySubtitle?: boolean;
    subDropdownItemId?: string;
    onSelect?(itemId: Id, subItemId?: Id): void;
}

const DROPDOWN_ITEM_HEIGHT = 50;
const SUBDROPDOWN_ITEM_HEIGHT = 30;

export function DropdownEditor({
    items,
    maxItems = 4,
    subDropdownMaxItems = 6,
    addSearch = true,
    searchBySubtitle = true,
    subDropdownTitle,
    subDropdownItemId,
    onSelect,
}: Props): JSX.Element {
    React.useEffect(() => setFilteredItems(items), [items.map((item) => item.id).join(',')]);

    const maxHeight = maxItems * DROPDOWN_ITEM_HEIGHT;
    const showSearch = addSearch && items.length > maxItems;

    const listRef = React.useRef<List>();
    const rootRef = React.useRef<HTMLDivElement>();
    const scrollbarRef = React.useRef<any>();
    const [filteredItems, setFilteredItems] = React.useState(items);

    const listHeight = Math.min(filteredItems.length * DROPDOWN_ITEM_HEIGHT, maxHeight);

    function onScroll(scrollValues: ScrollValues, prevValues: ScrollValues) {
        const valuesHaveChanged = scrollValues.scrollTop !== prevValues.scrollTop;

        if (valuesHaveChanged && listRef.current && scrollbarRef.current) {
            const { scrollTop, scrollLeft } = scrollValues;

            listRef.current.Grid.handleScrollEvent({
                scrollTop,
                scrollLeft,
            });

            scrollbarRef.current.scrollbar.scrollTo(scrollTop, scrollLeft);
        }
    }

    return (
        <div
            ref={rootRef}
            className={styles.root}
            {...{
                'qa-id': 'taskEditorDropdownEditor',
            }}
        >
            {showSearch && (
                <Search
                    onChange={(inputValue) => {
                        const inputValueRegexp = new RegExp(inputValue, 'i');

                        setFilteredItems(
                            items.filter((item) => {
                                const titleMatch = item.title.match(inputValueRegexp);
                                const subtitleMatch =
                                    searchBySubtitle && item.subTitle ? item.subTitle.match(inputValueRegexp) : false;

                                return titleMatch || subtitleMatch;
                            }),
                        );

                        if (listRef.current) {
                            listRef.current.forceUpdateGrid();
                        }
                    }}
                />
            )}

            <CustomScrollbar hideScrollX ref={scrollbarRef} maxHeight={maxHeight} onScroll={onScroll}>
                <List
                    ref={listRef}
                    width={260}
                    height={listHeight}
                    rowCount={filteredItems.length}
                    rowHeight={DROPDOWN_ITEM_HEIGHT}
                    rowRenderer={(listProps) => (
                        <div key={listProps.index} style={listProps.style}>
                            <Item
                                {...filteredItems[listProps.index]}
                                rootRef={rootRef}
                                subItems={filteredItems[listProps.index].subItems.filter((subItem) =>
                                    subDropdownItemId ? subItem.id === subDropdownItemId : true,
                                )}
                                subDropdownTitle={subDropdownTitle}
                                subDropdownMaxItems={subDropdownMaxItems}
                                onSelect={onSelect}
                            />
                        </div>
                    )}
                    className={styles.virtualizedList}
                />
            </CustomScrollbar>
        </div>
    );
}

interface ItemParams {
    id: Id;
    title: string;
    vacation?: UserVacation;
    subTitle?: string;
    subItems: SubItemParams[];
}

interface SubItemParams {
    id: Id;
    title: string;
    vacation?: UserVacation;
}

interface ItemProps extends ItemParams {
    subDropdownTitle: string;
    subDropdownMaxItems: number;
    rootRef: React.MutableRefObject<HTMLDivElement>;
    onSelect(itemId: Id, subItem: Id): void;
}

function Item({
    id,
    title,
    subTitle,
    subItems,
    vacation,
    subDropdownTitle,
    subDropdownMaxItems,
    rootRef,
    onSelect,
}: ItemProps): JSX.Element {
    const singleSubItemIsAvailable = !!(subItems && subItems.length == 1);
    const itemRef = React.useRef<HTMLDivElement>();

    const [isSubDropdownVisible, setIsSubDropdownVisible] = React.useState(false);
    const setSubDropdownVisibility = debounce((isVisible: boolean) => {
        if (!singleSubItemIsAvailable) {
            setIsSubDropdownVisible(isVisible);
        }
    }, 150);

    function closeSubDropdown() {
        setSubDropdownVisibility(false);
    }

    return (
        <div
            ref={itemRef}
            className={styles.item}
            onMouseEnter={() => setSubDropdownVisibility(true)}
            onMouseLeave={closeSubDropdown}
            onClick={() => {
                if (singleSubItemIsAvailable) {
                    onSelect(id, subItems[0].id);
                }
            }}
            {...{
                'qa-id': 'taskEditorDropdownEditorItem',
            }}
        >
            <div className={styles.itemTitle}>
                <WithVacationStatus text={title} vacation={vacation} />
            </div>

            <div className={classnames(styles.itemTitle, styles.itemSubTitle)}>{subTitle}</div>

            {!singleSubItemIsAvailable && (
                <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={styles.subDropdownIcon}
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.5146 7.18274C7.1618 6.52953 7.1618 5.47047 6.5146 4.81726L1.95738 0.217736C1.66974 -0.0725785 1.20338 -0.0725786 0.915732 0.217736C0.628088 0.50805 0.628089 0.978742 0.915733 1.26906L5.47295 5.86859C5.54486 5.94116 5.54486 6.05884 5.47295 6.13141L0.915733 10.7309C0.628089 11.0213 0.628088 11.492 0.915732 11.7823C1.20338 12.0726 1.66974 12.0726 1.95738 11.7823L6.5146 7.18274Z"
                        fill={isSubDropdownVisible ? '#7E8681' : '#E3E9E5'}
                    />
                </svg>
            )}

            {isSubDropdownVisible && (
                <WithGlobalPopup
                    container={rootRef}
                    positionCalculator={AbsoluteLikePositionCalculator}
                    top={0}
                    right={-264}
                    maskZIndex={0}
                >
                    <SubDropdown
                        itemId={id}
                        title={subDropdownTitle}
                        maxItems={subDropdownMaxItems}
                        items={subItems}
                        onSelect={onSelect}
                        closeSubDropdown={closeSubDropdown}
                    />
                </WithGlobalPopup>
            )}
        </div>
    );
}

interface SubDropdownParams {
    title: string;
    items: SubItemParams[];
}

interface SubDropdownProps extends SubDropdownParams {
    itemId: Id;
    maxItems: number;
    closeSubDropdown(): void;
    onSelect(itemId: Id, subItemId: Id): void;
}

function SubDropdown({ itemId, title, maxItems, items, onSelect, closeSubDropdown }: SubDropdownProps): JSX.Element {
    const maxHeight = SUBDROPDOWN_ITEM_HEIGHT * maxItems;
    const showSearch = items.length > maxItems;

    const [filteredItems, setFilteredItems] = React.useState(items);

    return (
        <div>
            <div
                className={styles.subDropdown}
                onMouseLeave={closeSubDropdown}
                {...{
                    'qa-id': 'taskEditorDropdownEditorSubDropdown',
                }}
            >
                <div className={classnames(styles.subDropdownPaddings, styles.subDropdownTitle)}>{title}</div>

                {showSearch && (
                    <Search
                        onChange={(inputValue) => {
                            const inputValueRegexp = new RegExp(inputValue, 'i');

                            setFilteredItems(
                                Utils.spacebarInsensitiveFilter(inputValueRegexp, items, (item) => item.title),
                            );
                        }}
                    />
                )}

                <CustomScrollbar hideScrollX maxHeight={maxHeight}>
                    {filteredItems.map((item) => (
                        <div
                            key={item.id}
                            className={classnames(styles.subDropdownPaddings, styles.subDropdownItem)}
                            onClick={() => onSelect(itemId, item.id)}
                            {...{
                                'qa-id': 'taskEditorDropdownEditorSubDropdownItem',
                            }}
                        >
                            <WithVacationStatus text={item.title} vacation={item.vacation} />
                        </div>
                    ))}
                </CustomScrollbar>
            </div>
        </div>
    );
}

interface SearchProps {
    value?: string;
    onChange(value: string): void;
}

function Search({ value, onChange }: SearchProps): JSX.Element {
    const [inputValue, setInputValue] = React.useState(value || '');
    const onInputChange = debounce((value: string) => {
        setInputValue(value);
        onChange(value);
    }, 200);

    return (
        <React.Fragment>
            <div className={styles.searchTopMargin} />

            <div className={styles.search}>
                <SearchInput qaId="taskEditorDropdownEditorSearchInput" value={inputValue} onChange={onInputChange} />
            </div>
        </React.Fragment>
    );
}

interface WithVacationStatusProps {
    text: string;
    vacation: UserVacation;
}

function WithVacationStatus({ text, vacation }: WithVacationStatusProps): JSX.Element {
    return vacation ? (
        <span title={vacation?.comment}>
            🌴&nbsp;
            {text}&nbsp;
            <span className={styles.itemTitleStatus}>(в отпуске)</span>
        </span>
    ) : (
        <React.Fragment>{text}</React.Fragment>
    );
}
