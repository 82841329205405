import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import * as style from './ActivityName.scss';

import { HeaderBody, HeaderTitle } from '../common';

interface Props {
    activityId: number;
    title: string;
}

export const ActivityName = ({ activityId, title }: Props): JSX.Element => {
    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'dashboardRightSidebarActivityWidgetActivityName',
            }}
        >
            <HeaderBody>
                <HeaderTitle>Название</HeaderTitle>
            </HeaderBody>

            {activityId ? (
                <Link className={classNames(style.name, style.nameLink)} to={`/activity/${activityId}`} target="_blank">
                    {title}
                </Link>
            ) : (
                <div className={style.name}> {title} </div>
            )}
        </div>
    );
};
