import * as React from 'react';

import * as styles from './styles.scss';

interface Props {
    className?: string;
    value: Value;
    disabled?: boolean;
    onChange: (value: Value) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

type Value = string;

export const EditableTextLine: React.FC<Props> = ({
    value,
    disabled = false,
    onChange: externalOnChange = () => {},
    onFocus: externalOnFocus = () => {},
    onBlur: externalOnBlur = () => {},
}) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const textRef = React.useRef<HTMLDivElement>(null);

    const [prevValue, setPrevValue] = React.useState(value);

    const onClickText = React.useCallback(() => {
        if (!disabled) {
            if (inputRef?.current) {
                inputRef.current.focus();
            }
        }
    }, [disabled]);

    const onInputChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
        (event) => {
            externalOnChange(event.target.value);
        },
        [externalOnChange],
    );

    const onInputFocus: React.FocusEventHandler<HTMLInputElement> = React.useCallback(
        (event) => {
            setPrevValue(value);
            externalOnFocus(event);
        },
        [value, setPrevValue, externalOnFocus],
    );

    const onInputBlur: React.FocusEventHandler<HTMLInputElement> = React.useCallback(
        (event) => {
            externalOnBlur(event);
        },
        [externalOnBlur],
    );

    const onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement> = React.useCallback(
        (event) => {
            if (event.key === 'Escape') {
                externalOnChange(prevValue);
                if (inputRef?.current) {
                    inputRef.current.blur();
                }
            }

            if (event.key === 'Enter') {
                if (inputRef?.current) {
                    inputRef.current.blur();
                }
            }
        },
        [prevValue, inputRef.current, externalOnChange],
    );

    return (
        <div className={styles.root}>
            <div className={styles.text} ref={textRef} onClick={onClickText}>
                {value}
            </div>

            {!disabled && (
                <input
                    className={styles.input}
                    ref={inputRef}
                    value={value}
                    onChange={onInputChange}
                    onBlur={onInputBlur}
                    onFocus={onInputFocus}
                    onKeyDown={onInputKeyDown}
                />
            )}
        </div>
    );
};
