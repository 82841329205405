import * as React from 'react';
import { withRouter } from 'react-router-dom';
import autobind from 'autobind-decorator';

import { FileAssetTarget } from '@store/commonTypes';
import { FileAssetContainerProps, FileAssetContainerState } from './types';

import { FileAsset } from './FileAsset';
import { FileApi, FileApiUploadParams } from '@api';

@(withRouter as any)
export class FileAssetContainer extends React.Component<FileAssetContainerProps, FileAssetContainerState> {
    public state: FileAssetContainerState = {
        isRemovePopupOpen: false,
    };

    public render(): JSX.Element {
        return (
            <FileAsset
                isLoading={this.props.isLoading}
                name={this.props.name}
                originName={this.props.originName}
                type={this.props.type}
                createdAt={this.props.createdAt}
                size={this.props.size}
                targetId={this.props.targetId}
                targetType={this.props.targetType}
                className={this.props.className}
                hideDate={this.props.hideDate}
                onRemoveClick={this.onRemoveClick}
                onDownloadClick={this.handleDownloadClick}
                isDeletable={this.props.isDeletable}
                isRemovePopupOpen={this.state.isRemovePopupOpen}
                onRemoveOpenClick={this.onRemoveOpenClick}
                onRemoveCancelClick={this.onRemoveCancelClick}
            />
        );
    }

    @autobind
    protected onRemoveClick(): void {
        const { name, originName, type, createdAt, isDeletable, onRemoveClick, targetId, targetType } = this.props;

        if (isDeletable && onRemoveClick) {
            onRemoveClick({ name, originName, type, createdAt, targetId, targetType });
            this.onRemoveCancelClick();
        }
    }

    @autobind
    protected onRemoveOpenClick(): void {
        this.setState({ isRemovePopupOpen: true });
    }

    @autobind
    protected onRemoveCancelClick(): void {
        this.setState({ isRemovePopupOpen: false });
    }

    @autobind
    protected async handleDownloadClick() {
        if (!this.props.disableDownload) {
            const { targetId, targetType, match, name, originName, type } = this.props;

            const params: FileApiUploadParams = {
                originName,
                type,
            };

            if (targetType == FileAssetTarget.TASK) {
                params.taskId = targetId;
            } else {
                params.taskId = match.params.taskId;
                params.commentId = targetId;
            }

            await FileApi.downloadFile(params, name);
        }
    }
}
