import type { CreativeRequestParamDomain } from '../types';

interface ConvertCreativeRequestParamDomains {
    (domains: CreativeRequestParamDomain[]): Promise<
        {
            id: string;
            division: {
                id: string;
                name: string;
            };
        }[]
    >;
}

interface ConvertCreativeRequestParamDomain {
    (domains: CreativeRequestParamDomain): Promise<{
        id: string;
        division: {
            id: string;
            name: string;
        };
    }>;
}

export const convertCreativeRequestParamDomains: ConvertCreativeRequestParamDomains = async (
    creativeRequestParamDomains,
) => {
    return await Promise.all(
        creativeRequestParamDomains.map(async (domain) => convertCreativeRequestParamDomain(domain)),
    );
};

export const convertCreativeRequestParamDomain: ConvertCreativeRequestParamDomain = async ({ model }) => {
    const division = await model.division;

    return {
        id: model.id,
        division: division
            ? {
                  id: division.id,
                  name: division.value,
              }
            : null,
    };
};
