import * as React from 'react';
import { useSelector } from 'react-redux';

import { List } from './List';
import { AddButton } from './AddButton';
import { Recommendation } from './Recommendation';

import { StoreState } from '@store';
import { getFeatures } from '@store/autopilot/generationTextForm';

import * as styles from './styles.scss';

export const FeaturesSelector: React.FC<Props> = () => {
    const features = useSelector((state: StoreState) => getFeatures(state));

    return (
        <div className={styles.root}>
            {!features?.length && <Recommendation />}
            <div className={styles.label}>Ключевые особенности</div>
            <List />
            <AddButton />
        </div>
    );
};
