import * as React from 'react';
import classNames from 'classnames';

import type { Field } from '../../../types';
import * as styles from './styles.scss';

interface Props {
    field: Field;
    onChange: (value: string) => void;
}

export const LotSelector: React.FC<Props> = ({ field, onChange }) => {
    const onClick = React.useCallback(
        ({ value }) => {
            onChange(value);
        },
        [onChange],
    );

    return (
        <div>
            <div className={styles.label}>Выберите лот</div>

            <div className={styles.lots}>
                {field.items.map(({ value, label, disabled }) => (
                    <div
                        key={value}
                        className={classNames(
                            styles.button,
                            field.value === value && styles.selected,
                            (field.disabled || disabled) && styles.disabled,
                        )}
                        onClick={() => !field.disabled && !disabled && field.value !== value && onClick({ value })}
                    >
                        {label}
                    </div>
                ))}
            </div>
        </div>
    );
};
