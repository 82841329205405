// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot_legacy-_AutopilotPage__root{position:relative;height:100%;padding-right:410px;padding-left:48px}.src-client-modules-activity-pages-autopilot_legacy-_AutopilotPage__content{padding:24px 32px}.src-client-modules-activity-pages-autopilot_legacy-_AutopilotPage__rightSidebar{position:fixed;top:49px;right:0;padding:58px 10px 10px;width:410px;height:calc(100vh - 49px);background:#ffffff;box-shadow:0 1px 6px 0 rgba(0,0,0,0.1)}.src-client-modules-activity-pages-autopilot_legacy-_AutopilotPage__stageList{display:block}.src-client-modules-activity-pages-autopilot_legacy-_AutopilotPage__stageActions{margin-top:12px}.src-client-modules-activity-pages-autopilot_legacy-_AutopilotPage__stageWidget{margin-top:40px;padding-bottom:150px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot_legacy/AutopilotPage.scss"],"names":[],"mappings":"AAAA,yEACI,iBAAkB,CAElB,WAAY,CACZ,mBAAoB,CACpB,iBAAkB,CACrB,4EAGG,iBAAkB,CACrB,iFAGG,cAAe,CACf,QAAS,CACT,OAAQ,CACR,sBAAuB,CACvB,WAAY,CACZ,yBAA0B,CAE1B,kBAAmB,CACnB,sCAA0C,CAC7C,8EAGG,aAAc,CACjB,iFAGG,eAAgB,CACnB,gFAGG,eAAgB,CAChB,oBAAqB","sourcesContent":[".root {\n    position: relative;\n\n    height: 100%;\n    padding-right: 410px;\n    padding-left: 48px;\n}\n\n.content {\n    padding: 24px 32px;\n}\n\n.rightSidebar {\n    position: fixed;\n    top: 49px;\n    right: 0;\n    padding: 58px 10px 10px;\n    width: 410px;\n    height: calc(100vh - 49px);\n\n    background: #ffffff;\n    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);\n}\n\n.stageList {\n    display: block;\n}\n\n.stageActions {\n    margin-top: 12px;\n}\n\n.stageWidget {\n    margin-top: 40px;\n    padding-bottom: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot_legacy-_AutopilotPage__root",
	"content": "src-client-modules-activity-pages-autopilot_legacy-_AutopilotPage__content",
	"rightSidebar": "src-client-modules-activity-pages-autopilot_legacy-_AutopilotPage__rightSidebar",
	"stageList": "src-client-modules-activity-pages-autopilot_legacy-_AutopilotPage__stageList",
	"stageActions": "src-client-modules-activity-pages-autopilot_legacy-_AutopilotPage__stageActions",
	"stageWidget": "src-client-modules-activity-pages-autopilot_legacy-_AutopilotPage__stageWidget"
};
export default ___CSS_LOADER_EXPORT___;
