import { axios, AxiosResponse } from '../lib/axios';
import * as lodash from 'lodash';

import { UserConfigType, UserConfigParams, UserConfigQuery, UserConfigResponse } from 'sber-marketing-types/openid';

const BASE_URL = '/api/userConfig';

export class UserConfigApi {
    public static async getPageConfig(pageName: UserConfigType): Promise<object> {
        const params: Partial<UserConfigQuery> = {
            type: pageName,
        };

        const res: AxiosResponse<UserConfigResponse> = await axios.get(BASE_URL, { params });

        const configData = lodash.get(res, 'data.config.configData');

        return configData ? JSON.parse(`${configData}`) : {};
    }

    public static async savePageConfig(pageName: UserConfigType, pageConfig: object): Promise<void> {
        const params: Partial<UserConfigParams> = {
            type: pageName,
            configData: JSON.stringify(pageConfig),
        };

        await axios.put(BASE_URL, params);
    }
}
