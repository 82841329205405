import * as React from 'react';
import { StaticSkeleton } from 'sber-marketing-ui';

import * as styles from './LinksContainerWithToggle.scss';

export function LinksContainerWIthTogglePreloader(): JSX.Element {
    return (
        <div className={styles.root}>
            <div className={styles.title}>
                <StaticSkeleton className={styles.firstSkeleton} />
            </div>
        </div>
    );
}
