import { ComponentState, CellPosition } from './types';

export function isInternalTransferState(componentState: ComponentState): boolean {
    return (
        componentState === ComponentState.InternalTransferCellSelection ||
        componentState === ComponentState.InternalTransferLineSelection ||
        componentState === ComponentState.InternalTransferSumEntering ||
        componentState === ComponentState.InternalTransferNegativeLineBalanceWarning
    );
}

export function isExternalTransferState(componentState: ComponentState): boolean {
    return (
        componentState === ComponentState.ExternalIncomeTransfer ||
        componentState === ComponentState.ExternalOutcomeTransfer
    );
}

export function isCellSelected(cell: CellPosition | CellPosition[]): boolean {
    const arr = cell instanceof Array ? cell : [cell];

    return !!arr.length && arr.every((cell) => !!(cell && cell.lineId && cell.columnName));
}

export function cellBelongsToLine(cell: CellPosition, lineId: string): boolean {
    return cell && cell.lineId === lineId;
}

export function checkCellsEquality(cell1: CellPosition, cell2: CellPosition): boolean {
    if (!cell1 && !cell2) {
        return true;
    } else if ((!cell1 && cell2) || (cell1 && !cell2)) {
        return false;
    } else {
        return cell1.lineId === cell2.lineId && cell1.columnName === cell2.columnName;
    }
}

export function cellDescriptor(cell: CellPosition): string {
    return `${cell.lineId}-${cell.columnName}`;
}
