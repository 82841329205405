// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-components-CommentEditDropdown-_CommentEditDropdown__root{position:relative;overflow:visible}.src-client-modules-activity-pages-task-components-CommentEditDropdown-_CommentEditDropdown__root,.src-client-modules-activity-pages-task-components-CommentEditDropdown-_CommentEditDropdown__icon{width:32px;height:32px}.src-client-modules-activity-pages-task-components-CommentEditDropdown-_CommentEditDropdown__icon:hover{cursor:pointer}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/components/CommentEditDropdown/CommentEditDropdown.scss"],"names":[],"mappings":"AAAA,kGACI,iBAAkB,CAElB,gBAAiB,CACpB,oMAIG,UAAW,CACX,WAAY,CACf,wGAGG,cAAe","sourcesContent":[".root {\n    position: relative;\n\n    overflow: visible;\n}\n\n.root,\n.icon {\n    width: 32px;\n    height: 32px;\n}\n\n.icon:hover {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-components-CommentEditDropdown-_CommentEditDropdown__root",
	"icon": "src-client-modules-activity-pages-task-components-CommentEditDropdown-_CommentEditDropdown__icon"
};
export default ___CSS_LOADER_EXPORT___;
