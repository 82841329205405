import { Money, Captions } from './Money';

import { CommonFormatter } from './CommonFormatter';

interface Params {
    forceSign?: boolean;
    hideCaption?: boolean;
    isCopecksRequired?: boolean;
}

interface FormatParams {
    decimal: string;
    delimeter: string;
    fraction?: string;
    sign?: string;
    caption?: string;
}

export class MoneyFormatter {
    public static toRoubles(money: Money, params?: Params): string {
        const sign = this.getSign(money, params);
        const caption = this.getParamValue(params, 'hideCaption') ? '' : Captions.Roubles;
        const isCopecksRequired = this.getParamValue(params, 'isCopecksRequired');

        const roubles = CommonFormatter.splitNumberByScales(money.getRoubles());
        const copecks = isCopecksRequired
            ? this.strictFormatFraction(money.getCopecks() % 100.0, 2)
            : this.lazyFormatFraction(money.getCopecks() % 100.0, 2);

        return this.format({
            decimal: roubles,
            delimeter: '.',
            fraction: copecks,
            sign,
            caption,
        });
    }

    public static toThousands(money: Money, params?: Params): string {
        const sign = this.getSign(money, params);
        const caption = this.getParamValue(params, 'hideCaption') ? '' : Captions.Thousands;

        const thousands = CommonFormatter.splitNumberByScales(money.getRoundedThousands());
        const roubles = this.lazyFormatFraction(money.getRoundedRoubles() % 1000.0, 3);

        return this.format({
            decimal: thousands,
            delimeter: ',',
            fraction: roubles,
            sign,
            caption,
        });
    }

    public static toMostAppropriate(money: Money, params?: Params): string {
        const thousands = money.getThousands();

        return thousands ? this.toThousands(money, params) : this.toRoubles(money, params);
    }

    public static pairToRoubles(m1: Money, m2: Money, p1?: Params, p2?: Params): string {
        return `${this.toRoubles(m1, p1)} ⟶ ${this.toRoubles(m2, p1 || p2)}`;
    }

    public static pairToThousands(m1: Money, m2: Money, p1?: Params, p2?: Params): string {
        return `${this.toThousands(m1, p1)} ⟶ ${this.toThousands(m2, p1 || p2)}`;
    }

    private static format(params: FormatParams): string {
        const { decimal, delimeter, fraction, sign, caption } = params;

        const fractionPart = fraction ? `${delimeter}${fraction}` : '';
        const captionPart = caption ? ` ${caption}` : '';

        return `${sign}${decimal}${fractionPart}${captionPart}`;
    }

    private static getSign(money: Money, params?: Params): string {
        const showSign = this.getParamValue(params, 'forceSign');

        const plus = showSign ? '+' : '';
        const minus = '-';

        const sign = money.getSign();

        return sign < 0 ? minus : plus;
    }

    private static lazyFormatFraction(value: number, nDigits: number): string {
        return value ? this.formatFraction(value, nDigits) : '';
    }

    private static strictFormatFraction(value: number, nDigits: number): string {
        return this.formatFraction(value, nDigits);
    }

    private static formatFraction(value: number, nDigits: number): string {
        return String(value).padStart(nDigits, '0');
    }

    private static getParamValue(params: Params, key: string): boolean {
        return params && params[key];
    }
}
