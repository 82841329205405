// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-ExpandFilesButton-_ExpandFilesButton__root{display:flex;align-items:center;font-family:'Open Sans';font-weight:400;font-size:12px;color:#7E8681;cursor:pointer}.src-client-modules-common-ExpandFilesButton-_ExpandFilesButton__expandIcon{margin-left:5px}.src-client-modules-common-ExpandFilesButton-_ExpandFilesButton__expandIconUnactive{transform:rotate(180deg)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/ExpandFilesButton/ExpandFilesButton.scss"],"names":[],"mappings":"AAAA,sEACI,YAAa,CACb,kBAAmB,CAEnB,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,aAAc,CAEd,cAAe,CAClB,4EAGG,eAAgB,CACnB,oFAGG,wBAAyB","sourcesContent":[".root {\n    display: flex;\n    align-items: center;\n\n    font-family: 'Open Sans';\n    font-weight: 400;\n    font-size: 12px;\n    color: #7E8681;\n\n    cursor: pointer;\n}\n\n.expandIcon {\n    margin-left: 5px;\n}\n\n.expandIconUnactive {\n    transform: rotate(180deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-ExpandFilesButton-_ExpandFilesButton__root",
	"expandIcon": "src-client-modules-common-ExpandFilesButton-_ExpandFilesButton__expandIcon",
	"expandIconUnactive": "src-client-modules-common-ExpandFilesButton-_ExpandFilesButton__expandIconUnactive"
};
export default ___CSS_LOADER_EXPORT___;
