import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as lodash from 'lodash';

import * as style from './BudgetStageActions.scss';

import type { StoreState } from '@store';

import { Icon, IconType, Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';
import { setPreloaderStatus } from '@store/autopilotTv/actions';
import { getSelectedBudgetItemIds } from '@store/autopilotTv/selectors';
import { Loader, Saver } from '../../modules';

interface Props {
    canEdit: boolean;
}

export const BudgetStageActions = ({ canEdit }: Props): JSX.Element => {
    const saver = Saver.getInstance();
    const loader = Loader.getInstance();
    const dispatch = useDispatch();

    const selectedBudgetItemIds = useSelector((state: StoreState) => getSelectedBudgetItemIds(state));

    const onContinueButtonClick = React.useCallback(async () => {
        dispatch(setPreloaderStatus(true));

        await saver.saveSelectedBudgetItems();
        await loader.loadAutopilot();

        dispatch(setPreloaderStatus(false));
    }, []);

    const budgetItemSelected = !lodash.isEmpty(selectedBudgetItemIds);

    return (
        <div className={style.root}>
            <div className={style.title}>
                Привяжите ID бюджета
                {budgetItemSelected && (
                    <div className={style.titleIcon}>
                        <Icon type={IconType.ACCEPT_ACTIVE} svgSize={24} />
                    </div>
                )}
                {!budgetItemSelected && (
                    <>
                        &nbsp;
                        <span className={style.counter}>(0/1)</span>
                    </>
                )}
            </div>

            <div className={style.button}>
                <Button
                    disabled={!canEdit || !budgetItemSelected}
                    theme={ButtonTheme.MainRounded}
                    onClick={onContinueButtonClick}
                >
                    Сохранить
                </Button>
            </div>
        </div>
    );
};
