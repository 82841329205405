// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-TransferBudgetItemsToPlanningMenu-InitButton-_InitButton__root{padding-top:16px;width:100%}.src-client-modules-budget-BudgetPage-BudgetExecution-TransferBudgetItemsToPlanningMenu-InitButton-_InitButton__button{height:32px;display:flex;align-items:center}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/TransferBudgetItemsToPlanningMenu/InitButton/InitButton.scss"],"names":[],"mappings":"AAAA,qHACI,gBAAiB,CAEjB,UAAW,CACd,uHAGG,WAAY,CACZ,YAAa,CACb,kBAAmB","sourcesContent":[".root {\n    padding-top: 16px;\n\n    width: 100%;\n}\n\n.button {\n    height: 32px;\n    display: flex;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-TransferBudgetItemsToPlanningMenu-InitButton-_InitButton__root",
	"button": "src-client-modules-budget-BudgetPage-BudgetExecution-TransferBudgetItemsToPlanningMenu-InitButton-_InitButton__button"
};
export default ___CSS_LOADER_EXPORT___;
