import * as React from 'react';
import classNames from 'classnames';
import * as styles from './TopLineItem.scss';
import { isNil } from 'lodash';

/** Top line item properties */
export interface TopLineItemProps {
    /** Title */
    title: string;
    /** Value to show */
    value?: any;
    /** If "true" items will be aligned right */
    isAlignRight?: boolean;
    /** Additional class name */
    className?: string;
    qaId?: string;
}

/** Top line item */
export function TopLineItem({ title, value, isAlignRight, className, qaId }: TopLineItemProps): JSX.Element {
    const classList: string[] = [styles.topLineItem];
    if (isAlignRight) {
        classList.push(styles.topLineItemRight);
    }
    if (!isNil(className)) {
        classList.push(className);
    }
    return (
        <div className={classNames(classList)}>
            {React.isValidElement(value) ? (
                value
            ) : (
                <React.Fragment>
                    <div className={styles.topLineItem_title}>{title}</div>
                    {!isNil(value) ? (
                        <div
                            className={styles.topLineItem_value}
                            {...{
                                'qa-id': qaId,
                            }}
                        >
                            {value}
                        </div>
                    ) : null}
                </React.Fragment>
            )}
        </div>
    );
}
