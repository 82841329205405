import * as React from 'react';
import autobind from 'autobind-decorator';
import { positionValues } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { StoreState } from '@store';
import {
    getLineModalState,
    hideLineModal,
    fetchComments,
    clearLineModal,
    setShowTagsHaveChangedMarker,
} from '@store/budgetExecution';

import { LineModal } from './LineModal';

interface Props extends Partial<DispatchProps>, Partial<MapProps> {}

interface MapProps {
    lineId: string;
    // showOnlyRelatedActivitiesBlock: boolean;
}

interface DispatchProps {
    hideLineModal: () => void;
    fetchComments: () => void;
    clearLineModal: () => void;
    setShowTagsHaveChangedMarker: (payload: boolean) => void;
}

interface State {
    lineTagshaveChanged: boolean;
}

@(connect(mapStoreToProps, mapDispatchToProps) as any)
export class LineModalContainer extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            lineTagshaveChanged: false,
        };
    }

    public componentDidMount(): void {
        // this.props.fetchComments();
    }

    public componentWillUnmount(): void {
        if (this.state.lineTagshaveChanged) {
            this.props.setShowTagsHaveChangedMarker(true);
        }

        this.props.clearLineModal();
    }

    public render(): JSX.Element {
        const {
            lineId,
            // showOnlyRelatedActivitiesBlock,
        } = this.props;

        return (
            <LineModal
                lineId={lineId}
                // showOnlyRelatedActivitiesBlock={showOnlyRelatedActivitiesBlock}
                onCloseButtonClick={this.onCloseButtonClick}
                onCommonInfoScroll={this.onCommonInfoScroll}
                onAttachedTagsChange={this.onAttachedTagsChange}
            />
        );
    }

    @autobind
    private onAttachedTagsChange(): void {
        this.setState({ lineTagshaveChanged: true });
    }

    @autobind
    private onCloseButtonClick(): void {
        this.props.hideLineModal();
    }

    @autobind
    private onCommonInfoScroll(positionValues: positionValues): void {
        const {
            // showOnlyRelatedActivitiesBlock
        } = this.props;
        const {
            // scrollHeight,
            // scrollTop,
            // clientHeight
        } = positionValues;
        // const haveReachedEnd = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

        // if (!showOnlyRelatedActivitiesBlock && haveReachedEnd) {
        //     this.props.fetchComments();
        // }
    }
}

function mapStoreToProps(store: StoreState): MapProps {
    const {
        lineId,
        // isRelatedActivitiesTabExpanded
    } = getLineModalState(store);

    return {
        lineId,
        // showOnlyRelatedActivitiesBlock: isRelatedActivitiesTabExpanded
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return {
        hideLineModal: () => dispatch(hideLineModal()),
        fetchComments: () => dispatch(fetchComments(null)),
        clearLineModal: () => dispatch(clearLineModal()),
        setShowTagsHaveChangedMarker: (payload: boolean) => dispatch(setShowTagsHaveChangedMarker(payload)),
    };
}
