// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-CopyTextButton-_CopyTextButton__root{cursor:pointer;color:#7e8681;line-height:0}.src-client-modules-common-CopyTextButton-_CopyTextButton__root:hover{color:#19bb4f}.src-client-modules-common-CopyTextButton-_CopyTextButton__root:active{color:#18a049}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/CopyTextButton/CopyTextButton.scss"],"names":[],"mappings":"AAAA,gEACI,cAAe,CAEf,aAAc,CAEd,aAAc,CALlB,sEAQQ,aAAc,CARtB,uEAYQ,aAAc","sourcesContent":[".root {\n    cursor: pointer;\n\n    color: #7e8681;\n\n    line-height: 0;\n\n    &:hover {\n        color: #19bb4f;\n    }\n\n    &:active {\n        color: #18a049;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-CopyTextButton-_CopyTextButton__root"
};
export default ___CSS_LOADER_EXPORT___;
