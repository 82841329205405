import * as React from 'react';

import { SAPCommentInputConnected } from './SAPCommentInputConnected';
import { SAPCommentInputContainer } from './SAPCommentInputContainer';

interface Props {
    canEdit: boolean;
    linkId: string;
    budgetId: string;
    serialNumber: number;
    budgetItemId: string;
    expertId: number;
    isRequestApproved: boolean;
}

export function SAPCommentInput(props: Props): JSX.Element {
    return (
        <SAPCommentInputConnected {...props}>
            {(gqlProps) => (
                <SAPCommentInputContainer
                    {...gqlProps}
                    canEdit={props.canEdit}
                    expertId={props.expertId}
                    serialNumber={props.serialNumber}
                    isRequestApproved={props.isRequestApproved}
                />
            )}
        </SAPCommentInputConnected>
    );
}
