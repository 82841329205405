import * as React from 'react';
import classNames from 'classnames';
import * as moment from 'moment';
import { DatepickerContent as Datepicker, BodyPortal } from 'sber-marketing-ui';

import { ColumnName, CustomColumnColor } from '@store/budgetPlanning/types';

import { CellBackgroundColor } from '../../LayerManager';
import { getQaCellColor } from '../misc';

import * as style from './DatepickerCell.scss';

interface Props {
    columnName: ColumnName;
    title: string;
    bgColor: CellBackgroundColor | CustomColumnColor;
    disabled: boolean;
    tooltip?: string;
    onOpenerClick: () => void;
}

export const DatepickerCell = ({
    columnName,
    title,
    bgColor,
    disabled,
    tooltip,
    onOpenerClick,
}: Props): JSX.Element => {
    return (
        <div
            title={tooltip}
            className={classNames(style.root, disabled && style.disabled)}
            onClick={!disabled ? onOpenerClick : null}
            style={{ backgroundColor: bgColor }}
            {...{
                'qa-id': 'tableCell',
                'qa-index': columnName,
                'qa-disabled': disabled ? 'true' : 'false',
                ...getQaCellColor(bgColor),
            }}
        >
            <div className={style.title}>{title || '–'}</div>
        </div>
    );
};

interface DatepickerContentProps {
    value: Date;
    maxValue?: Date;
    minValue?: Date;
    onDateSelection: (date: Date) => void;
    onMaskClick: () => void;
}

export const DatepickerContent = ({
    value,
    maxValue,
    minValue,
    onDateSelection,
    onMaskClick,
}: DatepickerContentProps): JSX.Element => {
    return (
        <div
            className={style.content}
            {...{
                'qa-id': 'tableCellContent',
            }}
        >
            <Datepicker
                date={moment(value)}
                maxDate={maxValue ? moment(maxValue) : null}
                minDate={minValue ? moment(minValue) : null}
                customDateFormat={'DD MMMM YYYY'}
                onChange={(date) => onDateSelection((date as moment.Moment).toDate())}
            />

            <BodyPortal>
                <div className={style.mask} onClick={onMaskClick} />
            </BodyPortal>
        </div>
    );
};
