import * as React from 'react';
import classNames from 'classnames';
import { Dispatch, AnyAction } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { BudgetItemApproverStatus } from '@mrm/budget';
import { Icon, IconType, WithGlobalPopup, AddaptivePositionCalculator } from 'sber-marketing-ui';

import { StoreState } from '@store';
import {
    setRejectionCommentPopupVisibility,
    getBudgetPlanningPageState,
    getBudgetItemByLineId,
} from '@store/budgetPlanning';

import { Utils } from '@common/Utils';

import { ApproversPopup } from './ApproversPopup';

import * as styles from './ApprovingStatus.scss';

interface Props {
    wrapperRef: React.RefObject<HTMLDivElement>;
    lineId: string;
    lineIsHovered: boolean;
}

function useApproversPopupVisibility(dispatch: Dispatch<AnyAction>) {
    const [isVisible, setVisibility] = React.useState(false);
    const setPopupVisibility = React.useCallback(
        (isVisible: boolean) => {
            if (isVisible) {
                dispatch(setRejectionCommentPopupVisibility(false));
            }

            setVisibility(isVisible);
        },
        [dispatch],
    );

    return [isVisible, setPopupVisibility, setPopupVisibility.bind(null, false)];
}

function useStore(lineId: string) {
    const budgetItem = useSelector((state: StoreState) => getBudgetItemByLineId(state, lineId));

    return useSelector((state: StoreState) => {
        const { showRejectionCommentPopup } = getBudgetPlanningPageState(state);

        let totalApproversCount = 0;
        let approvedApproversCount = 0;
        let hasRejectedApprover = false;

        Utils.withErrorHandler<void>(() => {
            totalApproversCount = budgetItem.approvers.length;
            approvedApproversCount = budgetItem.approvers.filter(
                (approver) => approver.status == BudgetItemApproverStatus.Approved,
            ).length;
            hasRejectedApprover = budgetItem.approvers.some(
                (approver) => approver.status === BudgetItemApproverStatus.Rejected,
            );
        });

        return {
            totalApproversCount,
            approvedApproversCount,
            hasRejectedApprover,
            showRejectionCommentPopup,
        };
    });
}

function useInteractivity(lineId: string, lineIsHovered: boolean) {
    const dispatch = useDispatch();
    const [isApproversPopupVisible, setIsApproversPopupVisible, closePopup] = useApproversPopupVisibility(dispatch);
    const { totalApproversCount, approvedApproversCount, hasRejectedApprover, showRejectionCommentPopup } =
        useStore(lineId);
    React.useEffect(closePopup, [lineIsHovered, showRejectionCommentPopup]);

    return {
        totalApproversCount,
        approvedApproversCount,
        hasRejectedApprover,
        isApproversPopupVisible,
        setIsApproversPopupVisible,
        closePopup,
    };
}

export function ApprovingStatus({ wrapperRef, lineId, lineIsHovered }: Props): JSX.Element {
    const {
        totalApproversCount,
        approvedApproversCount,
        hasRejectedApprover,
        isApproversPopupVisible,
        setIsApproversPopupVisible,
        closePopup,
    } = useInteractivity(lineId, lineIsHovered);

    return totalApproversCount ? (
        <div
            className={classNames(styles.root, isApproversPopupVisible && styles.rootActive)}
            onClick={() => setIsApproversPopupVisible(!isApproversPopupVisible)}
            {...{
                'qa-id': 'budgetPlanningIndicatorsCellApprovingStatus',
            }}
        >
            <div className={classNames(styles.iconWrapper, hasRejectedApprover && styles.iconWrapperWithRedMarker)}>
                <Icon type={IconType.USER16} svgSize={24} />
            </div>
            {approvedApproversCount}/{totalApproversCount}
            {isApproversPopupVisible ? (
                <WithGlobalPopup
                    container={wrapperRef}
                    positionCalculator={AddaptivePositionCalculator}
                    onMaskClick={closePopup}
                    withoutMask
                >
                    <div
                        className={styles.appproversPopup}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                    >
                        <ApproversPopup lineId={lineId} closePopup={closePopup} />
                    </div>
                </WithGlobalPopup>
            ) : null}
        </div>
    ) : null;
}
