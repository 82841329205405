import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import * as moment from 'moment';
import {
    Icon,
    IconType,
    DatepickerContent,
    WithGlobalPopup,
    AbsoluteLikePositionCalculator,
    CloseButton,
} from 'sber-marketing-ui';
import { TaskStatus } from 'sber-marketing-types/frontend';

import { StoreState } from '@store';
import { ComponentMode, getTaskEditorState, setDeadline } from '@store/taskEditor2';

import { Utils, DatesFormatter } from '@common/Utils';

import * as styles from './DeadlineDatepicker.scss';

const BudgetWorktype = 'Согласование ID бюджета';

function useInteractivity() {
    const dispatch = useDispatch();
    const itemRef = React.useRef<HTMLDivElement>();

    const [isEditorHovered, setIsEditorHovered] = React.useState(false);
    const [isEditorVisible, setEditorVisibility] = React.useState(false);

    const { deadline, disabled, workType } = useSelector((state: StoreState) => {
        const { values, permissions } = getTaskEditorState(state);

        return {
            workType: values.workType,
            deadline: values.deadline,
            disabled: !permissions.canChangeExecutorDeadlineAndFiles,
        };
    });

    function saveDeadline(deadline: moment.Moment) {
        setEditorVisibility(false);
        dispatch(setDeadline(deadline));
    }

    return {
        itemRef,
        isEditorHovered,
        setIsEditorHovered,
        isEditorVisible,
        setEditorVisibility,
        deadline,
        saveDeadline,
        disabled,
        workType,
    };
}

interface Props {
    componentMode: ComponentMode;
}

export function DeadlineDatepicker({ componentMode }: Props): JSX.Element {
    const {
        itemRef,
        isEditorHovered,
        setIsEditorHovered,
        isEditorVisible,
        setEditorVisibility,
        deadline,
        saveDeadline,
        disabled,
        workType,
    } = useInteractivity();

    const status = useSelector((state: StoreState) => getTaskEditorState(state).data?.rawTask?.status);
    const workTypes = useSelector(
        (state: StoreState) => getTaskEditorState(state).data.userWorkType.workTypesWithUsers,
    );
    const selectedWorkType = workTypes.find((wType) => wType.id === workType);

    const budgetMinDeadline = moment().startOf('day').add(5, 'days');
    const content = deadline ? DatesFormatter.ddMonthyyyy(deadline) : 'Выберите дедлайн';

    const hasBudgetEditor = selectedWorkType?.title === BudgetWorktype;
    const addActiveStyle = isEditorHovered || isEditorVisible;
    const showRequiredMarker = componentMode === ComponentMode.enterRequiredFieldsForExistingTask;
    const canEdit = !status || status === TaskStatus.Draft;

    React.useEffect(() => {
        if (hasBudgetEditor && canEdit && deadline && deadline.isBefore(budgetMinDeadline, 'day')) {
            saveDeadline(null);
        }
    }, []);

    return (
        <div ref={itemRef} className={styles.root}>
            <div className={styles.title}>
                Дедлайн
                {showRequiredMarker ? <React.Fragment>&nbsp;*</React.Fragment> : null}
            </div>

            <div
                className={classNames(
                    styles.datepicker,
                    addActiveStyle && styles.datepickerActive,
                    disabled && styles.datepickerDisabled,
                )}
                onClick={!disabled ? () => setEditorVisibility(true) : null}
                onMouseEnter={!disabled ? () => setIsEditorHovered(true) : null}
                onMouseLeave={!disabled ? () => setIsEditorHovered(false) : null}
                {...{
                    'qa-id': 'taskEditorDeadlineDatepicker',
                }}
            >
                <Icon
                    type={IconType.CALENDAR_ICON}
                    svgSize={16}
                    className={classNames(styles.datepickerIcon, addActiveStyle && styles.datepickerIconActive)}
                />

                <div
                    className={classNames(
                        styles.datepickerDate,
                        deadline && styles.datepickerDateFilled,
                        disabled && styles.datepickerDateDisabled,
                    )}
                >
                    {content}
                </div>

                {!disabled && isEditorHovered && deadline && (
                    <div
                        className={styles.datepickerDeleteButton}
                        {...{
                            'qa-id': 'taskEditorDeadlineDatepickerDeleteDeadlineButton',
                        }}
                    >
                        <CloseButton
                            size={20}
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                saveDeadline(null);
                            }}
                        />
                    </div>
                )}
            </div>

            <div
                className={styles.duration}
                {...{
                    'qa-id': 'taskEditorDeadlineDatepickerDuration',
                }}
            >
                {duration(deadline)}
            </div>

            {!disabled && isEditorVisible && (
                <WithGlobalPopup
                    container={itemRef}
                    positionCalculator={AbsoluteLikePositionCalculator}
                    maskZIndex={100}
                    onMaskClick={() => setEditorVisibility(false)}
                >
                    <div
                        className={styles.datepickerContent}
                        {...{
                            'qa-id': 'taskEditorDeadlineDatepickerDatepickerContent',
                        }}
                    >
                        <DatepickerContent
                            minDate={hasBudgetEditor ? budgetMinDeadline : null}
                            date={deadline}
                            onChange={saveDeadline}
                        />
                    </div>
                </WithGlobalPopup>
            )}
        </div>
    );
}

const DAYS_FACTOR = 1000 * 60 * 60 * 24;
const daysDeclensionArr = ['день', 'дня', 'дней'];

function duration(dateObj: moment.Moment): string {
    if (!dateObj) {
        return ' ';
    }

    let res = 'Длительность задачи: ';
    const date = dateObj.toDate();
    const currDate = new Date();
    currDate.setHours(0);
    currDate.setMinutes(0);
    currDate.setSeconds(0);
    currDate.setMilliseconds(0);

    if (date < currDate) {
        res += 'Задача просрочена';
    } else {
        const duration = Math.ceil((+date - +currDate) / DAYS_FACTOR);
        const durationDeclension = `${duration} ${Utils.getDeclensionByNumber(duration, daysDeclensionArr)}`;

        res += durationDeclension;
    }

    return res;
}
