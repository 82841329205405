import { Success } from 'typescript-fsa';

import { BudgetTransferMenuState, DataState as State } from '../types';

export class DataReducer {
    public static makeInitialState(): State {
        return {
            budgetItems: [],
        };
    }

    public static mergeAfterAsyncDone(
        state: BudgetTransferMenuState,
        payload: Success<any, Partial<State>>,
    ): BudgetTransferMenuState {
        return DataReducer.merge(state, payload.result);
    }

    public static merge(state: BudgetTransferMenuState, data: Partial<State>): BudgetTransferMenuState {
        return { ...state, data: { ...state.data, ...data } };
    }
}
