import * as React from 'react';
import { connect } from 'react-redux';

import { BudgetIdBehavior } from './BudgetIdBehavior';

import type { StoreState } from '@store';
import { getLoginUser } from '@store/user/selector';
import { getCreativeRequestDomain } from '@store/creative';
import type { CreativeRequestDomain } from '@store/creative';

import { WithBudgets } from './WithBudgets';
import { WithBudgetItems } from '../../../WithBudgetItems';
import { WithPermissions } from './WithPermissions';

interface Props extends Partial<MapProps> {}

interface MapProps {
    userOrganizationId?: string;
    creativeRequestDomain: CreativeRequestDomain;
}

@(connect(mapStateToProps, null) as any)
export class BudgetIdConnector extends React.PureComponent<Props> {
    public render() {
        const { userOrganizationId, creativeRequestDomain } = this.props;

        return (
            <WithBudgets organizationIds={[userOrganizationId]}>
                {({ loading: loadingBudgets, budgets }) => (
                    <WithBudgetItems>
                        {({ loading: loadingBudgetItems, budgetItems }) => (
                            <WithPermissions>
                                {({ loading: loadingPermissions, permissions }) => (
                                    <BudgetIdBehavior
                                        loading={loadingBudgets || loadingPermissions || loadingBudgetItems}
                                        permissions={permissions}
                                        budgets={budgets}
                                        creativeRequestDomain={creativeRequestDomain}
                                        budgetItems={budgetItems}
                                    />
                                )}
                            </WithPermissions>
                        )}
                    </WithBudgetItems>
                )}
            </WithBudgets>
        );
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const currentUser = getLoginUser(state);
    const creativeRequestDomain = getCreativeRequestDomain(state);

    return {
        userOrganizationId: currentUser.attributes.organizationId,
        creativeRequestDomain,
    };
}
