import * as React from 'react';
import classNames from 'classnames';

import { TextClamper } from '../TextClamper';
import { Description } from '@common/Description';

import * as style from '../../CommonStyles.scss';

interface Props {
    text: string;
}

export function Comment({ text }: Props): JSX.Element {
    return (
        <div className={style.newsPartWrapper}>
            <div className={style.caption}>Комментарий</div>

            <TextClamper lines={2}>
                <div
                    className={classNames([style.text, style.colorGreen, style.weightBold])}
                    {...{
                        'qa-id': 'dashboardNewsColumnItemComment',
                    }}
                >
                    <Description description={text} />
                </div>
            </TextClamper>
        </div>
    );
}
