import * as React from 'react';
import classNames from 'classnames';

import * as style from './styles.scss';

import { Icon, IconType, LabeledInput, LabeledSelect, SelectItem } from 'sber-marketing-ui';

interface Props {
    fields: Fields;
    visibilityRemoveButton: boolean;
    onFieldChange: OnFieldChange;
    onRemoveButtonClick: () => void;
}

type Fields = Record<FieldType, Field>;

type FieldType = 'block' | 'division' | 'segment' | 'product' | 'channel';

interface Field {
    label: string;
    value: string;
    code: string;
    items: SelectItem[];
    disabled: boolean;
}

interface OnFieldChange {
    (params: OnFieldChangeParams): void;
}

interface OnFieldChangeParams {
    type: FieldType;
    value: string;
}

export const ParamFormTemplate: React.FC<Props> = ({
    fields,
    visibilityRemoveButton,
    onFieldChange,
    onRemoveButtonClick,
}) => {
    return (
        <div className={style.root}>
            <div className={classNames(style.line, style.field)}>
                <LabeledSelect
                    title={`${fields.block.label} *`}
                    items={fields.block.items || []}
                    selectedValue={fields.block.value}
                    disabled={fields.block.disabled}
                    onSelection={(value) => onFieldChange({ type: 'block', value: value?.toString() || null })}
                />
            </div>

            <div className={style.line}>
                <div className={classNames(style.fieldsPair, style.field)}>
                    <div className={style.fieldsPairItem}>
                        <LabeledSelect
                            title={`${fields.division.label} *`}
                            items={fields.division.items || []}
                            selectedValue={fields.division.value}
                            disabled={fields.division.disabled}
                            onSelection={(value) =>
                                onFieldChange({ type: 'division', value: value?.toString() || null })
                            }
                        />
                    </div>

                    <div className={style.fieldsPairItem}>
                        <LabeledInput title={'Код дивизиона *'} value={fields.division.code} disabled />
                    </div>
                </div>

                <div className={classNames(style.fieldsPair, style.field)}>
                    <div className={style.fieldsPairItem}>
                        <LabeledSelect
                            title={`${fields.segment.label} *`}
                            items={fields.segment.items || []}
                            selectedValue={fields.segment.value}
                            disabled={fields.segment.disabled}
                            onSelection={(value) =>
                                onFieldChange({ type: 'segment', value: value?.toString() || null })
                            }
                        />
                    </div>

                    <div className={style.fieldsPairItem}>
                        <LabeledInput title={'Код сегмента *'} value={fields.segment.code} disabled />
                    </div>
                </div>

                <div className={classNames(style.fieldsPair, style.field)}>
                    <div className={style.fieldsPairItem}>
                        <LabeledSelect
                            title={`${fields.product.label} *`}
                            items={fields.product.items || []}
                            selectedValue={fields.product.value}
                            disabled={fields.product.disabled}
                            onSelection={(value) =>
                                onFieldChange({ type: 'product', value: value?.toString() || null })
                            }
                        />
                    </div>

                    <div className={style.fieldsPairItem}>
                        <LabeledInput title={'Код продукта *'} value={fields.product.code} disabled />
                    </div>
                </div>

                <div className={classNames(style.fieldsPair, style.field)}>
                    <div className={style.fieldsPairItem}>
                        <LabeledSelect
                            title={`${fields.channel.label} *`}
                            items={fields.channel.items || []}
                            selectedValue={fields.channel.value}
                            disabled={fields.channel.disabled}
                            onSelection={(value) =>
                                onFieldChange({ type: 'channel', value: value?.toString() || null })
                            }
                        />
                    </div>

                    <div className={style.fieldsPairItem}>
                        <LabeledInput title={'Код канала *'} value={fields.channel.code} disabled />
                    </div>
                </div>
            </div>

            {visibilityRemoveButton && (
                <div className={style.deteleButton} onClick={onRemoveButtonClick}>
                    <div className={style.deteleButtonIcon}>
                        <Icon type={IconType.CROSS_BRIEF} svgSize={28} />
                    </div>
                    Удалить
                </div>
            )}
        </div>
    );
};

export { Field, Fields, FieldType, OnFieldChangeParams };
