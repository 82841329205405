import * as React from 'react';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import { Dispatch, AnyAction, bindActionCreators } from 'redux';

import { StoreState } from '@store';
import {
    ComponentState,
    closeTransitionMenu,
    getBudgetTransferMenuState,
} from '@store/budgetExecution/budgetTransferMenu';

import { BudgetTransferMenu } from './BudgetTransferMenu';

interface Props extends Partial<MapProps & DispatchProps> {}

interface MapProps {
    componentState: ComponentState;
    isRequestInProgress: boolean;
}

interface State {
    shouldDisplay: boolean;
}

interface DispatchProps {
    closeTransitionMenu: () => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BudgetTransferMenuContainer extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            shouldDisplay: true,
        };
    }

    public componentDidMount(): void {
        window.addEventListener('keydown', this.onKeydown);
    }

    public componentWillUnmount(): void {
        window.removeEventListener('keydown', this.onKeydown);
    }

    public componentDidUpdate(prevProps: Props): void {
        // this hack is used to solve CurrentRowSumInputs.CustomScrollbar bug
        // (scrollbar's content height doesn't get updated on first render of component)
        const initFlashEffect =
            this.props.componentState === ComponentState.InternalTransferSumEntering &&
            prevProps.componentState !== ComponentState.InternalTransferSumEntering;

        if (initFlashEffect) {
            this.setState(
                {
                    shouldDisplay: false,
                },
                () =>
                    setTimeout(
                        () =>
                            this.setState({
                                shouldDisplay: true,
                            }),
                        50,
                    ),
            );
        }
    }

    public render(): JSX.Element {
        const { componentState } = this.props;

        return (
            <BudgetTransferMenu
                {...this.state}
                componentState={componentState}
                onCloseButtonClick={this.onCloseButtonClick}
            />
        );
    }

    @autobind
    private onCloseButtonClick(): void {
        this.closeWindow();
    }

    @autobind
    private onKeydown(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            this.closeWindow();
        }
    }

    private closeWindow(): void {
        if (!this.props.isRequestInProgress) {
            this.props.closeTransitionMenu();
        }
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const {
        controls: { componentState, isRequestInProgress },
    } = getBudgetTransferMenuState(state);

    return {
        componentState,
        isRequestInProgress,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return bindActionCreators(
        {
            closeTransitionMenu,
        },
        dispatch,
    );
}
