import * as React from 'react';

import { WithStore } from './WithStore';
import { WithFileQuery } from './WithFileQuery';
import { WithFileMutations } from './WithFileMutations';
import { ActivityFilesContainer } from './ActivityFilesContainer';

interface Props {
    activityId: number;
}

export class ActivityFilesConnected extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { activityId } = this.props;

        return (
            <WithStore>
                {({ userId }) => (
                    <WithFileQuery activityId={activityId}>
                        {({ filesByGroup, refetch }) => (
                            <WithFileMutations activityId={activityId}>
                                {({ attachFile, deleteFile }) => (
                                    <ActivityFilesContainer
                                        userId={userId}
                                        filesByGroup={filesByGroup}
                                        updateFiles={refetch}
                                        attachFile={attachFile}
                                        deleteFile={deleteFile}
                                    />
                                )}
                            </WithFileMutations>
                        )}
                    </WithFileQuery>
                )}
            </WithStore>
        );
    }
}
