import * as React from 'react';
import classNames from 'classnames';
import { EmojiClickData } from 'emoji-picker-react';

import { TaskCommentReaction, TaskCommentTransferObject, TaskStatus } from 'sber-marketing-types/backend';
import { Icon, IconType } from 'sber-marketing-ui';

import { DatesFormatter } from '@common/Utils';

import { useCommentReactionMutation, useGetAuthUserQuery, useGetTaskQuery, useGetUserQuery } from '@api';

import { DropdownOptions, useSearch } from '@common/hooks';

import { getFilesCountText } from '@modules/files/utils';
import { getCommentsCountText } from '@modules/comment/utils';
import { FileData } from '@modules/files/types';

import { Card, Details, Divider, EmojiPicker, Flex, HtmlText, IconTag, Tag, TagProps } from '@common/components';
import { CommentReaction, RemoveCommentModal } from '@modules/comment/components';
import { DownloadFiles, FileTag } from '@modules/files/components';
import { TaskFilesGallery } from '@modules/task/components';

import * as styles from './Comment.scss';

export interface CommentProps extends TagProps {
    comment: TaskCommentTransferObject;
    replayCount?: number;
    onEdit?: () => void;
    onReplay?: () => void;
}

const defaultReactions = ['👍', '👌', '❤️', '✅'];

export function Comment({
    comment,
    replayCount,
    className,
    children,
    onMouseEnter,
    onEdit,
    onReplay,
    ...props
}: CommentProps) {
    const { data: authUser, isLoading: isAuthUserLoading } = useGetAuthUserQuery();
    const { data: author, isLoading: isAuthorLoading } = useGetUserQuery({ id: comment.authorId });
    const { data: { task } = {} } = useGetTaskQuery({ id: comment.taskId });
    const [search, setSearch] = useSearch();
    const [viewFile, setViewFile] = React.useState<FileData>();
    const [hovered, setHovered] = React.useState(false);
    const [selected, setSelected] = React.useState(false);
    const [isRemove, setIsRemove] = React.useState(false);
    const isAuthor = authUser?.user.attributes.id === comment.authorId;
    const [commentReaction, { isLoading: isReactionUpdate, originalArgs: reactionArgs }] = useCommentReactionMutation();
    const emojiDropdownRef = React.useRef<DropdownOptions>();
    const reactions = React.useMemo<Record<string, TaskCommentReaction[]>>(
        () =>
            comment.reactions.reduce((result, value) => {
                if (!(value.reaction in result)) {
                    result[value.reaction] = [];
                }
                result[value.reaction].push(value);
                return result;
            }, {}),
        [comment.reactions],
    );

    const files: FileData[] =
        comment.files?.map((file) => ({ ...file, containerName: comment.id, parent: 'comment' })) || [];
    const isClosed = task.status === TaskStatus.Closed;

    const handleHover: typeof onMouseEnter = (e) => {
        setHovered(true);
        onMouseEnter?.(e);
    };

    const handleReaction = (reaction: string) => {
        commentReaction({
            taskId: comment.taskId,
            commentId: comment.id,
            reaction,
        });
    };

    const handleEmojiPicker = (emoji: EmojiClickData) => {
        handleReaction(emoji.emoji);
        emojiDropdownRef.current.close();
    };

    const handleShowReplayComment = () => {
        setSearch({ ...search, commentId: comment.id });
        onReplay?.();
    };

    const handleDeleteComment = () => {
        if ('commentId' in search) {
            setSearch({ ...search, commentId: undefined });
        }
    };

    const handleShowRemoveModal = () => {
        setIsRemove(true);
    };

    const handleHideRemoveModal = () => {
        setIsRemove(false);
    };

    const authorActions = !isClosed && isAuthor && (
        <>
            <Divider vertical />
            <IconTag
                data-qa-id="Comment__edit"
                flat
                onClick={onEdit}
                padding={4}
                aligned
                ghost
                clickable
                icon={IconType.PROJECT_STAGES_EDIT_ICON}
            />
            <IconTag
                data-qa-id="Comment__remove"
                onClick={handleShowRemoveModal}
                padding={4}
                aligned
                ghost
                flat
                clickable
                type="danger"
                icon={IconType.TRASH}
            />
            <RemoveCommentModal
                hide={!isRemove}
                commentId={comment.id}
                taskId={comment.taskId}
                onRemove={handleDeleteComment}
                onCancel={handleHideRemoveModal}
            />
        </>
    );

    const summaryAfter = files.length ? (
        <DownloadFiles
            files={files}
            fileName={`Вложения комментария «${comment.id}» от ${new Date().toLocaleDateString()}.zip`}
        >
            Скачать файлы
        </DownloadFiles>
    ) : null;

    return (
        <Tag
            data-qa-id="Comment"
            data-qa-comment-id={comment.id}
            clickable
            align="stretch"
            flat
            vertical
            {...props}
            onMouseEnter={handleHover}
            className={classNames(styles.root, selected && styles.selected, className)}
        >
            <Flex vertical gap={8}>
                <Flex justify="space-between" className={styles.header}>
                    <Flex gap={4} align="center" loading={isAuthorLoading}>
                        <div data-qa-id="Comment__author" className={styles.author}>
                            {author?.firstName} {author?.secondName}
                        </div>
                        <div data-qa-id="Comment__department" className={styles.department}>
                            {author?.departmentName},
                        </div>
                        <div data-qa-id="Comment__time" className={styles.time}>
                            {DatesFormatter.hhmm(comment.createTime)}
                        </div>
                    </Flex>
                    {hovered && (
                        <Card
                            loading={isAuthUserLoading || isReactionUpdate}
                            className={styles.actions}
                            padding={[9, 13]}
                            gap={24}
                        >
                            {comment.replyId || search.commentId || isClosed ? null : (
                                <IconTag
                                    data-qa-id="Comment__replay"
                                    onClick={handleShowReplayComment}
                                    padding={4}
                                    aligned
                                    ghost
                                    flat
                                    clickable
                                    icon={IconType.REPLAY}
                                />
                            )}
                            {defaultReactions.map((reaction) => (
                                <IconTag
                                    data-qa-id="Comment__react"
                                    data-qa-reaction={reaction}
                                    flat
                                    key={reaction}
                                    padding={4}
                                    aligned
                                    ghost
                                    onClick={() => handleReaction(reaction)}
                                    icon={reaction}
                                />
                            ))}
                            <EmojiPicker
                                placement="topRight"
                                autoPlace="vertical"
                                absolute
                                dropdownRef={emojiDropdownRef}
                                aligned
                                ghost
                                flat
                                padding={4}
                                onShowChangeDropdown={setSelected}
                                onEmojiClick={handleEmojiPicker}
                            />
                            {authorActions}
                        </Card>
                    )}
                </Flex>
                <HtmlText data-qa-id="Comment__text" className={styles.text} text={comment.text} />
                {files.length ? (
                    <Details
                        className={styles.files}
                        summary={getFilesCountText(files.length)}
                        summaryAfter={summaryAfter}
                    >
                        <Flex vertical gap={6}>
                            {files.map((file) => (
                                <FileTag
                                    editable={isAuthor}
                                    key={file.id}
                                    file={file}
                                    onView={() => setViewFile(file)}
                                />
                            ))}
                        </Flex>
                        <TaskFilesGallery
                            taskId={comment.taskId}
                            files={files}
                            file={viewFile}
                            onShowFile={setViewFile}
                        />
                    </Details>
                ) : null}
                {Object.keys(reactions).length ? (
                    <Flex className={styles.reactions} gap={6}>
                        {Object.keys(reactions).map((key) => (
                            <CommentReaction
                                loading={isReactionUpdate}
                                key={key}
                                reactions={reactions[key]}
                                reaction={key}
                                onClick={() => handleReaction(key)}
                            />
                        ))}
                        {isReactionUpdate && !(reactionArgs.reaction in reactions) && (
                            <IconTag key={reactionArgs.reaction} icon={reactionArgs.reaction} padding={[1, 7]} loading>
                                1
                            </IconTag>
                        )}
                    </Flex>
                ) : null}
                {replayCount ? (
                    <Flex className={styles.replayButton}>
                        <IconTag
                            data-qa-id="Comment__replayButton"
                            onClick={handleShowReplayComment}
                            type="soft"
                            icon={IconType.BADGE_MESSAGE}
                        >
                            {getCommentsCountText(replayCount)}
                            <Icon type={IconType.ARROW_RIGHT} />
                        </IconTag>
                    </Flex>
                ) : null}
            </Flex>
        </Tag>
    );
}
