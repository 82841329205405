import * as React from 'react';
import autobind from 'autobind-decorator';

import { Item } from '../Item';

interface Props extends OwnProps {}

interface OwnProps {
    id: number;
    name: string;
    department: string;
    isSelected: boolean;
    canRemove: boolean;
    addEditingParticipants: (userIds: number[]) => void;
    removeEditingParticipants: (userIds: number[]) => void;
}

export class UserItemContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { name, department, isSelected, canRemove } = this.props;

        return (
            <Item
                title={name}
                department={department}
                isSelected={isSelected}
                canBeUnselected={canRemove}
                onClick={this.onClick}
            />
        );
    }

    @autobind
    private onClick(): void {
        if (this.props.isSelected) {
            this.props.removeEditingParticipants([this.props.id]);
        } else {
            this.props.addEditingParticipants([this.props.id]);
        }
    }
}
