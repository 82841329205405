import * as React from 'react';
import { head } from 'lodash';
import { MrmClient, CreativeRequest as CreativeRequestDomain } from '@api';

interface UseClient {
    (params: UseClientParams): UseClientReturn;
}

interface UseClientParams {
    creativeRequestId: string;
}

interface UseClientReturn {
    loading: boolean;
    canEditName: boolean;
    creativeRequestName: string;
    creativeRequestLotName: string;
    creativeRequestTableItemNumber: string;
    setCreativeRequestName: SetCreativeRequestName;
}

interface SetCreativeRequestName {
    (name: string): void;
}

interface Init {
    (): void;
}

export const useClient: UseClient = ({ creativeRequestId }) => {
    const creativeRequestDomainRef = React.useRef<CreativeRequestDomain>(null);

    const [loading, setLoading] = React.useState<boolean>(true);
    const [creativeRequestName, setCreativeRequestName] = React.useState('');
    const [creativeRequestLotName, setCreativeRequestLotName] = React.useState('');
    const [creativeRequestTableItemNumber, setCreativeRequestTableItemNumber] = React.useState('');

    const init: Init = React.useCallback(() => {
        MrmClient.getInstance()
            .then((client) => {
                return Promise.all([
                    client.domain.creativeRequests.getCreativeRequest({ id: creativeRequestId }),
                    client.domain.creativeRequests.getCreativeRequestTableItems({ creativeRequestId }),
                ]);
            })
            .then(([creativeRequestDomain, creativeRequestTableItemDomains]) => {
                creativeRequestDomainRef.current = creativeRequestDomain;
                const creativeRequestTableItemDomain = head(creativeRequestTableItemDomains);
                creativeRequestDomain.events.onReloaded(() => {
                    setLoading(true);
                    init();
                });
                return Promise.all([
                    creativeRequestDomain.model.name,
                    creativeRequestDomain.model.lot,
                    creativeRequestTableItemDomain?.model?.number,
                ]);
            })
            .then(([creativeRequestName, { value: creativeRequestLotName }, creativeRequestTableItemNumber]) => {
                setCreativeRequestName(creativeRequestName);
                setCreativeRequestLotName(creativeRequestLotName);
                setCreativeRequestTableItemNumber(creativeRequestTableItemNumber?.toString() || '');
            })
            .catch((error) => {
                console.warn(error);
                setCreativeRequestName('');
                setCreativeRequestLotName('');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [creativeRequestId, setCreativeRequestName, setCreativeRequestLotName]);

    React.useEffect(() => {
        setLoading(true);
        init();
    }, []);

    const onChangeCreativeRequestName = React.useCallback(
        (updatedCreativeRequestName) => {
            creativeRequestDomainRef.current.model.setName({ name: updatedCreativeRequestName });
            setCreativeRequestName(updatedCreativeRequestName);
        },
        [creativeRequestDomainRef],
    );

    const canEditName: boolean = React.useMemo(() => {
        return !!creativeRequestDomainRef?.current?.model?.setName;
    }, [creativeRequestDomainRef?.current?.model?.setName]);

    return {
        loading,
        canEditName,
        creativeRequestName,
        creativeRequestLotName,
        creativeRequestTableItemNumber,
        setCreativeRequestName: onChangeCreativeRequestName,
    };
};
