import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import autobind from 'autobind-decorator';
import { AccountStatus } from 'sber-marketing-types/openid';
import { MentionItem, MentionItemStatus } from 'sber-marketing-ui';

import { UserApi } from '@api';

import { User } from '@store/user/types';
import { PageData } from '@store/dashboardPage';

import { HeaderView } from '@common/Page';

import { Card } from './types';
import { DashboardPage, DashboardPageHeader } from './DashboardPage';

interface Props extends Partial<RouteComponentProps> {
    preloader: boolean;
    user: User;
    cards: Card[];
    isLoadingCards: boolean;
    totalCardsCount: number;
    loadUserConfig: () => void;
    resetPageStore: () => void;
    loadPageData: (data: PageData) => void;
    fetchMore: () => Promise<void>;
    updateCards: () => Promise<void>;
    setHeaderView: (view: HeaderView) => void;
}

@(withRouter as any)
export class DashboardPageContainer extends React.PureComponent<Props> {
    private pageRef: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);

        this.pageRef = React.createRef<HTMLDivElement>();
        this.renderHeader();
    }

    public async componentDidMount(): Promise<void> {
        this.renderHeader();

        this.props.loadUserConfig();

        const mentionableUsers: MentionItem[] = (
            await UserApi.getUserListFiltered({
                organizationIds: [this.props.user.attributes.organizationId],
                embed: ['vacation'],
            })
        )
            .filter(
                (user) =>
                    (user.status === AccountStatus.ACTIVE || user.status === AccountStatus.NEED_UPDATE_PASSWORD) &&
                    !!user.departmentId,
            )
            .map((user) => ({
                id: user.id,
                title: `${user.secondName} ${user.firstName}`,
                description: user.departmentName || 'Департамент не задан',
                status: user.vacation ? MentionItemStatus.Vacation : null,
            }));

        this.props.loadPageData({
            mentionableUsers,
        });
    }

    public componentWillUnmount() {
        this.props.resetPageStore();
    }

    public async componentDidUpdate(prevProps: Props) {
        const pageModeChanged = this.props.match.url !== prevProps.match.url;

        if (pageModeChanged) {
            await this.renderHeader();
        }
    }

    public render(): JSX.Element {
        const { cards, preloader, isLoadingCards, totalCardsCount } = this.props;

        return React.createElement(DashboardPage, {
            cards,
            preloader,
            isLoadingCards,
            totalCardsCount,
            pageRef: this.pageRef,
            updateCards: this.updateCards,
            fetchMore: this.props.fetchMore,
        });
    }

    @autobind
    private async updateCards(): Promise<void> {
        this.scrollPageToTop();
        await this.props.updateCards();
    }

    @autobind
    private scrollPageToTop() {
        this.pageRef.current.scrollIntoView({
            behavior: 'auto',
            block: 'start',
        });
    }

    private renderHeader(): void {
        const currentUrl = this.props.match.url;

        this.props.setHeaderView({
            firstLine: DashboardPageHeader({
                currentUrl,
            }),
        });
    }
}
