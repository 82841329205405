import * as React from 'react';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import type { ScrollbarComponent } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { ColumnName, ColumnsWidth, ColumnsVisiblityFilter, getBudgetExecutionPageState } from '@store/budgetExecution';

import { ColumnsList } from '../../ColumnsConfig';

interface Props extends Partial<MapProps> {
    tableScrollbar: ScrollbarComponent;
}

interface MapProps {
    fixedColumnsNames: ColumnName[];
    columnsWidth: ColumnsWidth;
    columnsVisiblityFilter: ColumnsVisiblityFilter;
}

// Hook used for BudgetExecution autotests
@(connect(mapStateToProps) as any)
export class TableScrollerHook extends React.PureComponent<Props> {
    public componentDidMount(): void {
        window.scrollTableTo = this.scrollTableTo;
    }

    public componentWillUnmount(): void {
        window.scrollTableTo = null;
    }

    public render(): JSX.Element {
        // component used only for scrollTableTo hook
        return null;
    }

    @autobind
    private scrollTableTo(column: ColumnName): void {
        const { tableScrollbar, fixedColumnsNames, columnsWidth, columnsVisiblityFilter } = this.props;

        if (!columnsVisiblityFilter[column]) {
            console.warn(`Collumn ${column} is hidden`);
            return;
        }

        if (fixedColumnsNames.includes(column)) {
            console.warn(`Column ${column} is fixed, scrolling is unnecessary`);
            return;
        }

        const filteredColumns = ColumnsList.filter((column) => columnsVisiblityFilter[column.name]);

        const scrollerData = filteredColumns.reduce(
            (acc, col) => {
                const colWidth = columnsWidth[col.name];

                if (acc.columnWasReached) {
                    return acc;
                } else if (col.name === column) {
                    return {
                        scroll: acc.scroll,
                        columnWasReached: true,
                    };
                } else {
                    return {
                        scroll: acc.scroll + colWidth,
                        columnWasReached: false,
                    };
                }
            },
            { scroll: 0, columnWasReached: false },
        );

        if (!scrollerData.columnWasReached) {
            console.warn(`Column ${column} was not found`);
            return;
        }

        tableScrollbar.scrollTo(0, scrollerData.scroll);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const {
        fixedColumnsNames,
        columnsWidth,
        pageFilters: { columnsVisiblityFilter },
    } = getBudgetExecutionPageState(state);

    return {
        fixedColumnsNames,
        columnsWidth,
        columnsVisiblityFilter,
    };
}
