import * as React from 'react';
import { sanitize } from 'dompurify';
import classNames from 'classnames';

import * as styles from './HtmlText.scss';

export interface HtmlTextProps extends React.HTMLProps<HTMLDivElement> {
    text: string;
}

export function HtmlText({ text, className, ...props }: HtmlTextProps) {
    return (
        <div
            {...props}
            className={classNames(styles.root, className)}
            dangerouslySetInnerHTML={{
                __html: sanitize(text, {
                    ALLOWED_TAGS: [
                        'a',
                        'hr',
                        'br',
                        'span',
                        'b',
                        'strong',
                        'i',
                        'em',
                        'color',
                        'ul',
                        'li',
                        'ol',
                        'div',
                        'p',
                        'h1',
                        'h2',
                        'h3',
                    ],
                    ALLOWED_ATTR: ['target', 'href', 'class', 'title', 'style', 'color'],
                }),
            }}
        />
    );
}
