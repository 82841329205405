import * as React from 'react';
import * as moment from 'moment';
import { connect } from 'react-redux';

import { StoreState } from '@store';
import { getBudgetItemByLineId } from '@store/budgetExecution';
import { BudgetItem } from '@mrm/budget';

import { Utils } from '@common/Utils';

import { GeneralInfo, InfoBlockParams } from './GeneralInfo';

interface Props extends Partial<MapProps> {
    lineId: string;
}

interface MapProps {
    budgetItem: BudgetItem;
}

@(connect(mapStateToProps) as any)
export class GeneralInfoContainer extends React.Component<Props> {
    public render() {
        return React.createElement(GeneralInfo, {
            infoBlockParamsList: this.buildInfoBlockParamsList(),
        });
    }

    private buildInfoBlockParamsList(): InfoBlockParams[] {
        const { budgetItem } = this.props;

        return [
            {
                title: 'Автор:',
                content: budgetItem.author ? `${budgetItem.author.firstName} ${budgetItem.author.secondName} ` : '',
                qaId: 'budgetExecutionLineModalCommonInfoAuthor',
            },
            {
                title: 'Ответственные',
                content: budgetItem.responsibles
                    ? budgetItem.responsibles.map((user) => `${user.firstName} ${user.secondName}`).join(', ')
                    : '',
                qaId: 'budgetExecutionLineModalCommonInfoResponsibles',
            },
            {
                title: 'Дата создания:',
                content: budgetItem.creationTime ? this.formatDate(budgetItem.creationTime) : '',
                qaId: 'budgetExecutionLineModalCommonInfoCreationTime',
            },
            {
                title: 'Название активности:',
                content: budgetItem.activity ? budgetItem.activity.name : '',
                qaId: 'budgetExecutionLineModalCommonInfoActivityName',
            },
            {
                title: 'Блок:',
                content:
                    budgetItem.dictionary && budgetItem.dictionary.block
                        ? Utils.getDictionaryValue(budgetItem.dictionary.block)
                        : '',
                qaId: 'budgetExecutionLineModalCommonInfoBlock',
            },
            {
                title: 'Дивизион:',
                content:
                    budgetItem.dictionary && budgetItem.dictionary.division
                        ? Utils.getDictionaryValue(budgetItem.dictionary.division)
                        : '',
                qaId: 'budgetExecutionLineModalCommonInfoDivision',
            },
            {
                title: 'Инструмент:',
                content:
                    budgetItem.dictionary && budgetItem.dictionary.tool
                        ? Utils.getDictionaryValue(budgetItem.dictionary.tool)
                        : '',
                qaId: 'budgetExecutionLineModalCommonInfoTool',
            },
            {
                title: 'Статья:',
                content:
                    budgetItem.dictionary && budgetItem.dictionary.item
                        ? Utils.getDictionaryValue(budgetItem.dictionary.item)
                        : '',
                qaId: 'budgetExecutionLineModalCommonInfoItem',
            },
            {
                title: 'Ресурс:',
                content:
                    budgetItem.dictionary && budgetItem.dictionary.resource
                        ? Utils.getDictionaryValue(budgetItem.dictionary.resource)
                        : '',
                qaId: 'budgetExecutionLineModalCommonInfoResource',
            },
        ];
    }

    private formatDate(date: string | Date): string {
        return moment(date).format('DD.MM.YYYY');
    }
}

function mapStateToProps(store: StoreState, props: Props): MapProps {
    const { lineId } = props;

    return {
        budgetItem: getBudgetItemByLineId(store, lineId),
    };
}
