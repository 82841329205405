// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-TaskExecutorSuggest-_TaskExecutorSuggest__root{position:relative}.src-client-modules-activity-pages-task-TaskExecutorSuggest-_TaskExecutorSuggest__button{position:absolute;z-index:2;right:0;bottom:10px;cursor:pointer}.src-client-modules-activity-pages-task-TaskExecutorSuggest-_TaskExecutorSuggest__button_disabled{color:#979797}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/TaskExecutorSuggest/TaskExecutorSuggest.scss"],"names":[],"mappings":"AAAA,uFACI,iBAAkB,CACrB,yFAGG,iBAAkB,CAClB,SAAU,CACV,OAAQ,CACR,WAAY,CAEZ,cAAe,CAClB,kGAGG,aAAc","sourcesContent":[".root {\n    position: relative;\n}\n\n.button {\n    position: absolute;\n    z-index: 2;\n    right: 0;\n    bottom: 10px;\n\n    cursor: pointer;\n}\n\n.button_disabled {\n    color: #979797;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-TaskExecutorSuggest-_TaskExecutorSuggest__root",
	"button": "src-client-modules-activity-pages-task-TaskExecutorSuggest-_TaskExecutorSuggest__button",
	"button_disabled": "src-client-modules-activity-pages-task-TaskExecutorSuggest-_TaskExecutorSuggest__button_disabled"
};
export default ___CSS_LOADER_EXPORT___;
