import { DataProps } from 'react-apollo';

interface Data {
    requests: {
        nodes: Request[];
    };
}

interface Values {
    filter: {};
    limit: number;
}

export interface Request {
    status: RequestStatus;
}

export const enum RequestStatus {
    Pending = 'pending',
    Approved = 'approved',
    Rejected = 'rejected',
    Deleted = 'deleted',
}

export type RequestsQueryProps = DataProps<Data, Values>;
