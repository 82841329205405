import { Success } from 'typescript-fsa';

import { BudgetTransferMenuState, ParticipatorDataState as State } from '../types';

export class ParticipatorDataReducer {
    public static makeInitialState(): State {
        return { participatorItemId: null, participatorComment: null };
    }

    public static mergeAfterAsyncDone(
        state: BudgetTransferMenuState,
        payload: Success<any, Partial<State>>,
    ): BudgetTransferMenuState {
        return ParticipatorDataReducer.merge(state, payload.result);
    }

    public static merge(state: BudgetTransferMenuState, participatorData: Partial<State>): BudgetTransferMenuState {
        return { ...state, participatorData: { ...state.participatorData, ...participatorData } };
    }
}
