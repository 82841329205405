import * as React from 'react';

import { AppliedFiltersNames } from '@store/budgetExecution';

import { Dropdown } from './Dropdown';
import { Content } from './Content';

import * as styles from './styles.scss';

export interface Props {
    filtersNames: AppliedFiltersNames;
    displayLabel: boolean;
    onReset: () => void;
}

export const ColumnsFiltersDropdownTemplate: React.FC<Props> = ({ filtersNames, displayLabel, onReset }) => {
    return (
        <div className={styles.root}>
            <div className={styles.dropdownWrapper}>
                <Dropdown
                    label={(displayLabel && <Label count={filtersNames.length} />) || null}
                    content={<Content columnNames={filtersNames} onResetButtonClick={onReset} />}
                />
            </div>
        </div>
    );
};

interface LabelProps {
    count: number;
}

const Label: React.FC<LabelProps> = ({ count }) => {
    return <div className={styles.label}>смотреть все {count}</div>;
};
