import * as React from 'react';
import { connect } from 'react-redux';
import * as lodash from 'lodash';
import * as moment from 'moment';
import { sortBy } from 'lodash';

import type { PlainDictionary } from '@mrm/dictionary';
import type { Activity } from '@mrm/activity';
import type { TaskAttributes, TaskCardParams } from 'sber-marketing-types/frontend';
import type { ActivityTypeColor, ResponsibleUser, Stage } from '@store/calendar/types';
import { DEFAULT_TYPE_COLOR } from '@store/calendar/types';

import { Tooltip } from './Tooltip';
import type { StoreState } from '@store';
import { getCalendarPageState, getPageData, getActivityTypeColors } from '@store/calendar/selectors';

interface Props extends Partial<MapProps> {}

interface MapProps {
    activity: Activity;
    task: TaskAttributes;
    activityTasks: TaskCardParams[];
    activityTypeName: string;
    activityTypeColor: ActivityTypeColor;
    responsibleUser: ResponsibleUser;
    product: PlainDictionary;
    activityStages: Stage[];
}

@(connect(mapStateToProps, null) as any)
export class TooltipContainer extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        const {
            activity,
            task,
            activityTasks,
            responsibleUser,
            product,
            activityTypeName,
            activityTypeColor,
            activityStages,
        } = this.props;

        return React.createElement(Tooltip, {
            activityTasks,
            dates: task ? this.formatTaskDate(task) : this.formatActivityDate(activity),
            name: task ? task.title : activity.name,
            stages: activityStages,
            responsibleName: responsibleUser.name,
            productName: product ? product.value : 'Продукт не указан',
            activityTypeName,
            activityTypeColor: activityTypeColor.realization,
            projectStart: new Date(activity.realizationStart),
            projectEnd: new Date(activity.realizationEnd),
        });
    }

    private formatActivityDate(activity: Activity): string {
        const start = moment(activity.realizationStart).format('DD MMMM');
        const end = moment(activity.realizationEnd).format('DD MMMM');

        return `${start} - ${end}`;
    }

    private formatTaskDate(task: TaskAttributes): string {
        return moment(task.deadline).format('DD MMMM YYYY');
    }

    // private formatStages(stageData: ActivityStage, stagesTemplate: StageTemplate): Stage[] {
    //     if (!stageData) {
    //         return null;
    //     }

    //     const { stages } = stageData;

    //     const sortedStages = stagesTemplate.stages.map(stageName => stages.find(item => item.name == stageName));

    //     return sortedStages;
    // }
}

function mapStateToProps(state: StoreState): MapProps {
    const { hoveredItemId } = getCalendarPageState(state);
    const { activities, tasks, activityTypes, responsibleUsers, products, stages } = getPageData(state);
    const activityTypeColors = getActivityTypeColors(state);

    const task = lodash.flatMap(tasks).find((item) => item.id == hoveredItemId);

    const activityId = task ? task.activityId : hoveredItemId;
    const activityTasks = tasks[activityId];

    const activity = activities.find((item) => item.id == activityId);
    const activityType = activityTypes.find((item) => item.id == activity.typeId);
    const responsibleUser = responsibleUsers.find((item) => item.id == activity.responsibleId);
    const product = products.find((item) => item.id == activity.productId);

    const activityStages = sortBy(
        stages.filter((stage) => stage.activityId === activityId),
        (stage) => -stage.weight,
    );

    return {
        activity,
        task,
        activityTasks,
        activityTypeName: activityType ? activityType.name : 'Тип активности не найден',
        activityTypeColor: activityTypeColors[activity.typeId] || DEFAULT_TYPE_COLOR,
        responsibleUser,
        product,
        activityStages,
    };
}
