import * as React from 'react';
import * as queryString from 'query-string';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { UserConfigType } from 'sber-marketing-types/openid';
import autobind from 'autobind-decorator';
import { BudgetStatus } from '@mrm/budget';

import { StoreState } from '@store';
import { loadUserConfig } from '@store/userConfig';
import { LoadingStatus } from '@store/commonTypes';
import { SaveBudgetByStatusUserConfigPayload, saveBudgetByStatusUserConfig } from '@store/userConfig/budget';
import {
    BudgetCorrectionsUserConfig,
    getBudgetCorrectionsUserConfig,
    getLoadingStatus,
    makeInitialState as makeInitialUserConfig,
} from '@store/userConfig/budgetCorrections';
import {
    GraphqlFilterValues,
    FilterValues,
    filtersHaveChanged,
    setCurrentFilters,
    setSavedFilters,
    resetFilterValues,
    saveUserConfig,
    moveCurrentFiltersToSaved,
    loadFilterItems,
    getBudgetCorrectionsFiltersAndExportState,
} from '@store/budgetCorrections/filtersAndExport';

import { FiltersTemplate } from './FiltersTemplate';

interface Props extends Partial<MapProps & DispatchProps>, Partial<RouteComponentProps> {
    loading: boolean;
    filtersValues: GraphqlFilterValues;
    applyFilters: () => void;
}

interface MapProps {
    filters: FilterValues;
    userConfig: BudgetCorrectionsUserConfig;
    userConfigLoadingStatus: LoadingStatus;
    filtersHaveChanged: boolean;
}

interface DispatchProps {
    loadFilterItems(payload: GraphqlFilterValues): void;
    saveUserConfig(): void;
    loadUserConfig(): void;
    setCurrentFilters(filters: Partial<FilterValues>): void;
    setSavedFilters(filters: Partial<FilterValues>): void;
    moveCurrentFiltersToSaved(): void;
    resetFilterValues(): void;
    saveBudgetByStatusUserConfig(payload: SaveBudgetByStatusUserConfigPayload): void;
}

interface QueryParams {
    executorId?: string;
    serialNumber?: string;
    status?: string;
    budgetId?: string;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class FiltersContainer extends React.Component<Props> {
    private isUserConfigLoaded = false;

    public componentDidMount(): void {
        this.props.loadUserConfig();
    }

    public componentDidUpdate(prevProps: Props): void {
        const shouldProcessUserConfig =
            !this.isUserConfigLoaded && this.props.userConfigLoadingStatus === LoadingStatus.LOADED;
        const filtersAreLoaded = !this.props.loading && prevProps.loading;

        if (shouldProcessUserConfig) {
            this.isUserConfigLoaded = true;
            this.processUserConfig();
        }
        if (filtersAreLoaded) {
            this.props.loadFilterItems(this.props.filtersValues);
        }
    }

    public render(): JSX.Element {
        return (
            <FiltersTemplate
                loading={this.props.loading}
                isApplyFiltersButtonDisabled={!this.props.filtersHaveChanged}
                onApplyFiltersButtonClick={this.onApplyFiltersButtonClick}
                onResetFiltersButtonClick={this.onResetFiltersButtonClick}
            />
        );
    }

    @autobind
    private onApplyFiltersButtonClick(): void {
        this.props.applyFilters();

        if (!this.props.filters.isExportModeEnabled) {
            this.props.saveUserConfig();
            this.props.moveCurrentFiltersToSaved();
        }
    }

    @autobind
    private onResetFiltersButtonClick(): void {
        this.props.resetFilterValues();
        this.props.saveUserConfig();
        this.props.applyFilters();
    }

    private processUserConfig(): void {
        const [haveQueryParams, queryParams] = this.getQueryParams();

        if (queryParams.budgetId) {
            this.props.saveBudgetByStatusUserConfig({
                budgetStatus: BudgetStatus.Execution,
                payload: {
                    budgetId: queryParams.budgetId as string,
                },
            });
        }

        const filters: Partial<FilterValues> = haveQueryParams
            ? makeInitialUserConfig().fields
            : { ...this.props.userConfig };
        if (queryParams.executorId) {
            filters.planIds = [queryParams.executorId];
        }
        if (queryParams.serialNumber) {
            filters.serialNumber = [queryParams.serialNumber];
        }
        if (queryParams.status) {
            filters.status = [queryParams.status];
        }

        this.props.setCurrentFilters(filters);
        this.props.setSavedFilters(filters);
        this.props.applyFilters();
    }

    private getQueryParams(): [boolean, QueryParams] {
        const { location, history } = this.props;

        const query = queryString.parse(location.search);

        let result: QueryParams = {};
        let haveValues = false;

        if (query.executorId) {
            result.executorId = query.executorId as string;
            haveValues = true;
        }
        if (query.serialNumber) {
            result.serialNumber = query.serialNumber as string;
            haveValues = true;
        }
        if (query.status) {
            result.status = query.status as string;
            haveValues = true;
        }
        if (query.budgetId) {
            result.budgetId = query.budgetId as string;
            haveValues = true;
        }

        history.push('/budget/corrections');

        return [haveValues, result];
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const userConfig = getBudgetCorrectionsUserConfig(state);
    const userConfigLoadingStatus = getLoadingStatus(state);
    const filters = getBudgetCorrectionsFiltersAndExportState(state).currentFilters;

    return {
        userConfig,
        userConfigLoadingStatus,
        filtersHaveChanged: filtersHaveChanged(state),
        filters,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        loadUserConfig: () => dispatch(loadUserConfig(UserConfigType.BudgetCorrections)),
        saveUserConfig: () => dispatch(saveUserConfig(null)),
        loadFilterItems: (payload: GraphqlFilterValues) => dispatch(loadFilterItems(payload)),
        setCurrentFilters: (filters: Partial<FilterValues>) => dispatch(setCurrentFilters(filters)),
        setSavedFilters: (filters: Partial<FilterValues>) => dispatch(setSavedFilters(filters)),
        moveCurrentFiltersToSaved: () => dispatch(moveCurrentFiltersToSaved()),
        resetFilterValues: () => dispatch(resetFilterValues()),
        saveBudgetByStatusUserConfig: (payload: SaveBudgetByStatusUserConfigPayload) =>
            dispatch(saveBudgetByStatusUserConfig(payload)),
    };
}
