import * as React from 'react';
import { debounce } from 'lodash';

import { Icon, IconType } from 'sber-marketing-ui';
import { Dropdown } from './Dropdown';

import { useStore } from './useStore';
import { useMode } from '../../../../hooks';
import { convertStatisticsItemsToFilterItems, getTypeNameFromStatisticsItems } from './utils';
import { Filters } from './types';

import * as styles from './styles.scss';

export const Filter: React.FC = () => {
    const { mode } = useMode();

    const [open, setOpen] = React.useState(false);
    const [hover, setHover] = React.useState<boolean>(false);

    const { filters, selectedStatisticsItem, statisticsItems, childrenOfSelectedStatisticsItem, setFilters } = useStore(
        { mode },
    );

    const delay = React.useCallback(
        debounce((callback: () => void) => callback(), 400),
        [],
    );

    React.useEffect(() => {
        delay(() => {
            if (!hover) {
                setOpen(false);
            }
        });
    }, [hover]);

    const onClickButton: React.MouseEventHandler<HTMLDivElement> = React.useCallback(
        (event) => {
            event.preventDefault();
            event.stopPropagation();
            setOpen(!open);
            setHover(!hover);
        },
        [open],
    );

    const onMouseEnterList = React.useCallback(() => {
        setHover(true);
    }, []);

    const onMouseEnterButton = React.useCallback(() => {
        setHover(true);
    }, []);

    const onMouseLeave = React.useCallback(
        (event) => {
            setHover(false);
        },
        [hover, setHover, setOpen],
    );

    const items = convertStatisticsItemsToFilterItems(childrenOfSelectedStatisticsItem, statisticsItems);

    const onChange = React.useCallback(
        (filters: Filters) => {
            setFilters(filters);
        },
        [filters],
    );

    const filteredChildrenStatisticsItems = childrenOfSelectedStatisticsItem.filter(
        (childStatisticsItem) => filters[childStatisticsItem.id],
    );

    const selectedItemCount = filteredChildrenStatisticsItems.length;

    return (
        <div className={styles.root}>
            <div
                className={styles.button}
                onClick={onClickButton}
                onMouseEnter={onMouseEnterButton}
                onMouseLeave={onMouseLeave}
            >
                <div>
                    {`${selectedItemCount} `}
                    {getTypeNameFromStatisticsItems(childrenOfSelectedStatisticsItem, selectedItemCount)}
                </div>

                <div className={styles.iconWrapper}>
                    <Icon type={IconType.GEAR} />
                </div>
            </div>

            {open && (
                <div className={styles.listContainer} onMouseEnter={onMouseEnterList} onMouseLeave={onMouseLeave}>
                    <Dropdown
                        id={selectedStatisticsItem.id}
                        name={selectedStatisticsItem.meta.name}
                        items={items}
                        filters={filters}
                        onChange={onChange}
                    />
                </div>
            )}
        </div>
    );
};
