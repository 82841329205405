// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageActions-ContextStageActions-_ContextStageActions__root{display:flex;align-items:center;justify-content:space-between;height:48px;padding:0 24px;border-radius:6px;background:#ffffff;box-shadow:0px 2px 6px rgba(0,0,0,0.1)}.src-client-modules-activity-pages-autopilot-StageActions-ContextStageActions-_ContextStageActions__title{display:flex;align-items:center;user-select:none;color:#092a3a;font-family:'Open Sans';font-size:16px;font-weight:600;line-height:22px}.src-client-modules-activity-pages-autopilot-StageActions-ContextStageActions-_ContextStageActions__counter{color:#7e8681}.src-client-modules-activity-pages-autopilot-StageActions-ContextStageActions-_ContextStageActions__button{width:222px;height:32px;flex:none}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageActions/ContextStageActions/ContextStageActions.scss"],"names":[],"mappings":"AAAA,yGACI,YAAa,CACb,kBAAmB,CACnB,6BAA8B,CAE9B,WAAY,CACZ,cAAe,CAEf,iBAAkB,CAClB,kBAAmB,CACnB,sCAA0C,CAC7C,0GAGG,YAAa,CACb,kBAAmB,CACnB,gBAAiB,CACjB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACpB,4GAGG,aAAc,CACjB,2GAGG,WAAY,CACZ,WAAY,CACZ,SAAU","sourcesContent":[".root {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    height: 48px;\n    padding: 0 24px;\n\n    border-radius: 6px;\n    background: #ffffff;\n    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);\n}\n\n.title {\n    display: flex;\n    align-items: center;\n    user-select: none;\n    color: #092a3a;\n\n    font-family: 'Open Sans';\n    font-size: 16px;\n    font-weight: 600;\n    line-height: 22px;\n}\n\n.counter {\n    color: #7e8681;\n}\n\n.button {\n    width: 222px;\n    height: 32px;\n    flex: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageActions-ContextStageActions-_ContextStageActions__root",
	"title": "src-client-modules-activity-pages-autopilot-StageActions-ContextStageActions-_ContextStageActions__title",
	"counter": "src-client-modules-activity-pages-autopilot-StageActions-ContextStageActions-_ContextStageActions__counter",
	"button": "src-client-modules-activity-pages-autopilot-StageActions-ContextStageActions-_ContextStageActions__button"
};
export default ___CSS_LOADER_EXPORT___;
