import * as React from 'react';
import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import {
    getSelectedTextTitles,
    getSelectedTextContents,
    SELECTED_TEXT_OPTION_LENGTH_RANGE,
} from '@store/autopilot/generationTextForm';

import * as styles from './styles.scss';

interface Props {}

export const Counter: React.FC<Props> = ({}) => {
    const selectedTitles = useSelector((state: StoreState) => getSelectedTextTitles(state));
    const selectedContents = useSelector((state: StoreState) => getSelectedTextContents(state));

    return (
        <div className={styles.root}>
            <div className={styles.item}>
                <div>Заголовки:&nbsp;</div>
                <div className={styles.count}>
                    {selectedTitles?.length || 0}/{SELECTED_TEXT_OPTION_LENGTH_RANGE.MAX}{' '}
                    {selectedTitles?.length < SELECTED_TEXT_OPTION_LENGTH_RANGE.MIN
                        ? `(минимум ${SELECTED_TEXT_OPTION_LENGTH_RANGE.MIN})`
                        : ''}
                </div>
            </div>
            <div className={styles.item}>
                <div>Описания:&nbsp;</div>
                <div className={styles.count}>
                    {selectedContents?.length || 0}/{SELECTED_TEXT_OPTION_LENGTH_RANGE.MAX}{' '}
                    {selectedContents?.length < SELECTED_TEXT_OPTION_LENGTH_RANGE.MIN
                        ? `(минимум ${SELECTED_TEXT_OPTION_LENGTH_RANGE.MIN})`
                        : ''}
                </div>
            </div>
        </div>
    );
};
