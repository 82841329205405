import * as React from 'react';
import { get } from 'lodash';

import { Queries } from '@api';

import { ActivityCardBehaviour } from './ActivityCardBehaviour';

import { Card } from '../../types';

interface Props {
    card: Card;
    userHasBudgetAccess: boolean;
    onDeleteButtonClick: (cardId: string) => void;
}

interface State {
    userHasBudgetItemAccess: boolean;
}

export class ActivityCardConnector extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            userHasBudgetItemAccess: false,
        };
    }

    public async componentDidMount(): Promise<void> {
        const {
            userHasBudgetAccess,
            card: { budgetItem },
        } = this.props;
        const budgetId = get(budgetItem, 'budget.id') || null;
        const budgetItemId = get(budgetItem, 'id') || null;

        if (userHasBudgetAccess && budgetId && budgetItemId) {
            this.setState({
                userHasBudgetItemAccess: await Queries.userHasBudgetItemReadPermission(budgetId, budgetItemId),
            });
        }
    }

    public render(): JSX.Element {
        return <ActivityCardBehaviour {...this.props} {...this.state} />;
    }
}
