import * as React from 'react';
import reactOnclickoutside from 'react-onclickoutside';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { ScrollbarComponent } from 'sber-marketing-ui';
import type { CellPosition, ColumnName, ColumnWidths, LineHeights, LineId, Point, Size } from '../types';
import { TableEvent, CellEvent } from '../types';

import { TableViewTemplate } from './TableViewTemplate';
import { CellsStorage } from '../CellsStorage';
import { Cursor } from './Cursor';
import { Virtualizer } from './modules';

const PAGE_SCROLL_CONTAINER_ID = 'pageContent';

// const TABLE_BODY_MAX_HEIGHT = 731;
const MAX_COLUMN_WIDTH = 1500;
const MIN_COLUMN_WIDTH = 80;

const DEFAULT_COLUMN_WIDTH = 120;
const DEFAULT_LINE_HEIGHT = 32;

const HEADER_HEIGHT = 40;
const CELLS_SPACING = 1;

interface Props {
    headerCellsStorage: CellsStorage<any, any>;
    tableCellsStorage: CellsStorage<any, any>;
    columns: ColumnName[];
    leftFixedColumns?: ColumnName[];
    rightFixedColumns?: ColumnName[];
    lines: LineId[];
    columnWidths?: ColumnWidths;
    onCellEvent?: (eventType: CellEvent, position: CellPosition) => void;
    onColumnWidthsChange?: (columnWidths: ColumnWidths) => void;
}

interface State {
    cursorPosition: CellPosition;
    columnWidths: ColumnWidths;
    lineHeights: LineHeights;
    visibleColumnsIndexes: number[];
    visibleLinesIndexes: number[];
}

@(reactOnclickoutside as any)
export class TableViewBehaviour extends React.PureComponent<Props, State> {
    private rootRef: React.RefObject<HTMLDivElement>;
    private viewportRef: React.RefObject<HTMLDivElement>;
    private scrollContainer: HTMLElement;
    private cursor: Cursor;
    private tableHeader: ScrollbarComponent;
    private stickyTableHeader: ScrollbarComponent;
    private tableBody: ScrollbarComponent;
    private leftFixedColumns: ScrollbarComponent;
    private rightFixedColumns: ScrollbarComponent;
    private stickyScrollbar: ScrollbarComponent;
    private virtualizer: Virtualizer;
    private tableEventsSubscribers: { [key in TableEvent]?: ((eventType: TableEvent) => void)[] } = {};
    private draggedEdgeColumnInitialWidth: number;
    private columnEdgeDragStartX: number;
    private resizingColumnName: ColumnName;

    public constructor(props: Props) {
        super(props);

        this.state = {
            cursorPosition: null,
            columnWidths: {
                ...[...props.columns, ...(props.leftFixedColumns || []), ...(props.rightFixedColumns || [])].reduce(
                    (acc, item) => {
                        acc[item] = DEFAULT_COLUMN_WIDTH;
                        return acc;
                    },
                    {},
                ),
                ...(props.columnWidths || {}),
            },
            lineHeights: props.lines.reduce((acc, item) => {
                acc[item] = DEFAULT_LINE_HEIGHT;
                return acc;
            }, {}),
            visibleColumnsIndexes: [],
            visibleLinesIndexes: [],
        };

        this.rootRef = React.createRef<HTMLDivElement>();
        this.viewportRef = React.createRef<HTMLDivElement>();

        this.virtualizer = new Virtualizer();
    }

    public async componentDidMount() {
        this.updateViewportSize();
        this.updateVisibleColumns();
        // this.updateVisibleLines();

        this.scrollContainer = document.getElementById(PAGE_SCROLL_CONTAINER_ID);

        this.scrollContainer.addEventListener('scroll', this.onPageScroll);
        window.addEventListener('resize', this.onPageResize);
    }

    public async componentWillUnmount() {
        this.tableEventsSubscribers = null;

        this.scrollContainer.removeEventListener('scroll', this.onPageScroll);
        window.removeEventListener('resize', this.onPageResize);
    }

    public componentDidUpdate(prevProps: Props) {
        const columnsChanged = this.props.columns !== prevProps.columns;
        const linesChanged = this.props.lines !== prevProps.lines;

        if (columnsChanged) {
            this.updateVisibleColumns();
        }

        if (linesChanged) {
            if (!this.props.lines.includes(this.state.cursorPosition?.lineId)) {
                this.selectCell(null);
            }

            this.setState(
                {
                    lineHeights: this.props.lines.reduce((acc, item) => {
                        acc[item] = DEFAULT_LINE_HEIGHT;
                        return acc;
                    }, {}),
                },
                () => {
                    this.updateViewportSize();
                    // this.updateVisibleLines();
                },
            );
        }
    }

    public render(): JSX.Element {
        const {
            headerCellsStorage,
            tableCellsStorage,
            columns,
            leftFixedColumns = [],
            rightFixedColumns = [],
            lines,
        } = this.props;
        const { cursorPosition, visibleColumnsIndexes, columnWidths, visibleLinesIndexes, lineHeights } = this.state;

        const allColumns = [...leftFixedColumns, ...columns, ...rightFixedColumns];

        // const viewportSize = this.getViewportSize();

        return React.createElement(TableViewTemplate, {
            allColumns,
            columns,
            leftFixedColumns,
            rightFixedColumns,
            lines,
            cursorPosition,
            visibleColumnsIndexes,
            visibleLinesIndexes,
            columnWidths,
            lineHeights,
            columnsSumWidth: this.getColumnsSumWidth(columns),
            leftFixedSumWidth: this.getColumnsSumWidth(leftFixedColumns),
            rightFixedSumWidth: this.getColumnsSumWidth(rightFixedColumns),
            sumHeight: this.getSumHeight(),
            tableBodyMaxHeight: this.getSumHeight(),
            // tableBodyMaxHeight: viewportSize.height,
            // tableBodyMaxHeight: TABLE_BODY_MAX_HEIGHT,
            rootRef: this.rootRef,
            viewportRef: this.viewportRef,
            tableHeaderRef: this.tableHeaderRef,
            stickyTableHeaderRef: this.stickyTableHeaderRef,
            tableBodyRef: this.tableBodyRef,
            leftFixedColumnsRef: this.leftFixedColumnsRef,
            rightFixedColumnsRef: this.rightFixedColumnsRef,
            stickyScrollbarRef: this.stickyScrollbarRef,
            cursorRef: this.cursorRef,
            headerCellsStorage,
            tableCellsStorage,
            selectCell: this.selectCell,
            onBodyScroll: this.onBodyScroll,
            onLeftFixedColumnsScroll: this.onLeftFixedColumnsScroll,
            onRightFixedColumnsScroll: this.onRightFixedColumnsScroll,
            onStickyScrollbarScroll: this.onStickyScrollbarScroll,
            onColumnEdgeMousedown: this.onColumnEdgeMousedown,
            onCellEvent: this.onCellEvent,
        });
    }

    @autobind
    public getColumnWidths(): ColumnWidths {
        return this.state.columnWidths;
    }

    @autobind
    public setColumnWidths(columnWidths: ColumnWidths) {
        const updatedColumnWidths: ColumnWidths = {
            ...this.state.columnWidths,
            ...columnWidths,
        };

        this.setState({
            columnWidths: updatedColumnWidths,
        });
    }

    @autobind
    public subscribeTableEvent(eventType: TableEvent, handler: () => void) {
        if (!this.tableEventsSubscribers[eventType]) {
            this.tableEventsSubscribers[eventType] = [];
        }

        if (!this.tableEventsSubscribers[eventType].includes(handler)) {
            this.tableEventsSubscribers[eventType].push(handler);
        }
    }

    @autobind
    public getBodyScroll(): Point {
        if (!this.tableBody) {
            return null;
        }

        return {
            x: this.tableBody.scrollLeft,
            y: this.tableBody.scrollTop,
        };
    }

    @autobind
    public setBodyScroll(scroll: Point) {
        if (!this.tableBody) {
            return;
        }

        if (this.tableBody.scrollTop !== scroll.y) {
            this.tableBody.scrollTop = scroll.y;
            // this.updateVisibleLines();
        }

        if (this.tableBody.scrollLeft !== scroll.x) {
            this.tableBody.scrollLeft = scroll.x;
            this.updateVisibleColumns();
        }
    }

    @autobind
    public getCellEditStatus(position: CellPosition): boolean {
        const { cursorPosition } = this.state;

        const cellIsSelected = lodash.isEqual(position, cursorPosition);

        return cellIsSelected ? this.cursor.getEditStatus() : false;
    }

    @autobind
    public setCursorEditStatus(status: boolean) {
        if (this.cursor) {
            this.cursor.setEditStatus(status);
        }
    }

    @autobind
    public setCursorPosition(position: CellPosition) {
        this.selectCell(position);
    }

    @autobind
    public handleClickOutside() {
        const cursorEditEnabled = this.cursor && this.cursor.getEditStatus();

        if (!cursorEditEnabled) {
            this.selectCell(null);
        }
    }

    @autobind
    protected onPageResize() {
        this.updateViewportSize();
        this.updateVisibleColumns();
        // this.updateVisibleLines();
        this.emitTableEvent(TableEvent.SizeChange);
    }

    @autobind
    protected onPageScroll() {
        this.updateViewportSize();
        // this.updateVisibleLines();
    }

    @autobind
    protected onBodyScroll() {
        const newScrollValue = this.tableBody.scrollLeft;

        this.setHorizontalScroll(newScrollValue);
        this.updateVerticalScroll('body');
        this.updateVisibleColumns();
        // this.updateVisibleLines();
        this.emitTableEvent(TableEvent.Scroll);
    }

    @autobind
    protected onStickyScrollbarScroll() {
        const newScrollValue = this.stickyScrollbar.scrollLeft;

        this.setHorizontalScroll(newScrollValue);
        this.updateVisibleColumns();
        this.emitTableEvent(TableEvent.Scroll);
    }

    @autobind
    protected onLeftFixedColumnsScroll() {
        this.updateVerticalScroll('leftFixedColumns');
        // this.updateVisibleLines();
    }

    @autobind
    protected onRightFixedColumnsScroll() {
        this.updateVerticalScroll('rightFixedColumns');
        // this.updateVisibleLines();
    }

    @autobind
    protected onColumnEdgeMousedown(columnName: ColumnName, mouseDownX: number) {
        this.draggedEdgeColumnInitialWidth = this.state.columnWidths[columnName];
        this.columnEdgeDragStartX = mouseDownX;

        this.resizingColumnName = columnName;

        document.body.style.cursor = 'col-resize';

        document.addEventListener('mousemove', this.onColumnEdgeMousemove);
        document.addEventListener('mouseup', this.onColumnEdgeMouseup);
    }

    @autobind
    protected onColumnEdgeMousemove(event: MouseEvent) {
        const deltaX = event.clientX - this.columnEdgeDragStartX;

        let columnWidth = this.draggedEdgeColumnInitialWidth + deltaX;

        if (columnWidth > MAX_COLUMN_WIDTH) {
            columnWidth = MAX_COLUMN_WIDTH;
        }

        if (columnWidth < MIN_COLUMN_WIDTH) {
            columnWidth = MIN_COLUMN_WIDTH;
        }

        this.setColumnWidth(this.resizingColumnName, columnWidth);
    }

    @autobind
    protected onColumnEdgeMouseup() {
        this.draggedEdgeColumnInitialWidth = null;
        this.columnEdgeDragStartX = null;

        this.resizingColumnName = null;

        document.body.style.cursor = '';

        document.removeEventListener('mousemove', this.onColumnEdgeMousemove);
        document.removeEventListener('mouseup', this.onColumnEdgeMouseup);
    }

    @autobind
    protected onCellEvent(eventType: CellEvent, position: CellPosition) {
        switch (eventType) {
            case CellEvent.Click:
                this.onCellClick(position);
                break;
        }

        if (this.props.onCellEvent) {
            this.props.onCellEvent(eventType, position);
        }
    }

    @autobind
    protected onCellClick(position: CellPosition) {
        const { readOnly } = this.props.tableCellsStorage.getCellParams(position);

        if (!readOnly) {
            this.selectCell(position);
        }
    }

    @autobind
    protected tableHeaderRef(component: ScrollbarComponent) {
        this.tableHeader = component;
    }

    @autobind
    protected stickyTableHeaderRef(component: ScrollbarComponent) {
        this.stickyTableHeader = component;

        if (this.stickyTableHeader) {
            this.stickyTableHeader.scrollLeft = this.tableBody.scrollLeft;
        }
    }

    @autobind
    protected tableBodyRef(component: ScrollbarComponent) {
        this.tableBody = component;
    }

    @autobind
    protected leftFixedColumnsRef(component: ScrollbarComponent) {
        this.leftFixedColumns = component;
    }

    @autobind
    protected rightFixedColumnsRef(component: ScrollbarComponent) {
        this.rightFixedColumns = component;
    }

    @autobind
    protected stickyScrollbarRef(component: ScrollbarComponent) {
        this.stickyScrollbar = component;

        if (this.stickyScrollbar) {
            this.stickyScrollbar.scrollLeft = this.tableBody.scrollLeft;
        }
    }

    @autobind
    protected cursorRef(component: Cursor) {
        this.cursor = component;
    }

    @autobind
    private setColumnWidth(columnName: ColumnName, width: number) {
        const updatedColumnWidths = lodash.clone(this.state.columnWidths);
        updatedColumnWidths[columnName] = width;

        this.setColumnWidths(updatedColumnWidths);

        if (this.props.onColumnWidthsChange) {
            this.props.onColumnWidthsChange(updatedColumnWidths);
        }
    }

    private getColumnsSumWidth(columns: ColumnName[]): number {
        if (!columns) {
            return 0;
        }

        return (
            columns.reduce((acc, columnName) => acc + this.state.columnWidths[columnName] + CELLS_SPACING, 0) -
            CELLS_SPACING
        );
    }

    private getSumHeight(): number {
        let height =
            this.props.lines.reduce((acc, lineId) => acc + this.state.lineHeights[lineId] + CELLS_SPACING, 0) -
            CELLS_SPACING;

        if (!height || height < 0) {
            height = 0;
        }

        return height;
    }

    private updateViewportSize() {
        if (this.viewportRef.current) {
            const size = this.getViewportSize();

            this.virtualizer.setViewportSize(size);
        }
    }

    // private getViewportSize(): Size {
    //     let height = this.viewportRef.current.offsetHeight;
    //     const totalHeight = this.getSumHeight();

    //     if (height < totalHeight) {
    //         height = totalHeight;
    //     }

    //     if (height > TABLE_BODY_MAX_HEIGHT) {
    //         height = TABLE_BODY_MAX_HEIGHT;
    //     }

    //     return {
    //         width: this.viewportRef.current.offsetWidth,
    //         height
    //     };

    //     // if (!this.rootRef.current) {
    //     //     return {
    //     //         width: 0,
    //     //         height: 0
    //     //     };
    //     // }

    //     // return {
    //     //     width: this.rootRef.current.offsetWidth - CELLS_SPACING * 2,
    //     //     height: this.rootRef.current.offsetHeight - HEADER_HEIGHT - CELLS_SPACING + 1
    //     // };
    // }

    private getViewportSize(): Size {
        if (!this.rootRef.current) {
            return {
                width: 0,
                height: 0,
            };
        }

        return {
            width: this.rootRef.current.offsetWidth - CELLS_SPACING * 2,
            height: this.rootRef.current.offsetHeight - HEADER_HEIGHT - CELLS_SPACING + 1,
        };
    }

    private setHorizontalScroll(scrollValue: number) {
        if (this.tableHeader.scrollLeft !== scrollValue) {
            this.tableHeader.scrollLeft = scrollValue;
        }

        if (this.tableBody.scrollLeft !== scrollValue) {
            this.tableBody.scrollLeft = scrollValue;
        }

        if (this.stickyTableHeader && this.stickyTableHeader.scrollLeft !== scrollValue) {
            this.stickyTableHeader.scrollLeft = scrollValue;
        }

        if (this.stickyScrollbar && this.stickyScrollbar.scrollLeft !== scrollValue) {
            this.stickyScrollbar.scrollLeft = scrollValue;
        }
    }

    private updateVerticalScroll(source: 'body' | 'leftFixedColumns' | 'rightFixedColumns') {
        switch (source) {
            case 'body':
                if (this.leftFixedColumns) {
                    this.leftFixedColumns.scrollTop = this.tableBody.scrollTop;
                }

                if (this.rightFixedColumns) {
                    this.rightFixedColumns.scrollTop = this.tableBody.scrollTop;
                }
                break;

            case 'leftFixedColumns':
                if (this.tableBody) {
                    this.tableBody.scrollTop = this.leftFixedColumns.scrollTop;
                }

                if (this.rightFixedColumns) {
                    this.rightFixedColumns.scrollTop = this.leftFixedColumns.scrollTop;
                }
                break;

            case 'rightFixedColumns':
                if (this.leftFixedColumns) {
                    this.leftFixedColumns.scrollTop = this.rightFixedColumns.scrollTop;
                }

                if (this.tableBody) {
                    this.tableBody.scrollTop = this.rightFixedColumns.scrollTop;
                }
                break;
        }
    }

    private updateVisibleColumns() {
        const { columns } = this.props;
        const { columnWidths, visibleColumnsIndexes: visibleColumns } = this.state;

        const newVisibleColumnsIndexes = this.virtualizer.getVisibleColumnsIndexes(
            columns,
            columnWidths,
            this.tableBody.scrollLeft,
        );

        const newVisibleColumnsChanged = !lodash.isEqual(newVisibleColumnsIndexes, visibleColumns);

        if (newVisibleColumnsChanged) {
            this.setState({
                visibleColumnsIndexes: newVisibleColumnsIndexes,
            });
        }
    }

    // private updateVisibleLines() {
    //     const { lines } = this.props;
    //     const { lineHeights, visibleLinesIndexes: visibleLines } = this.state;

    //     const newVisibleLines = this.virtualizer.getVisibleLinesIndexes(lines, lineHeights, this.tableBody.scrollTop);

    //     const newVisibleLinesChanged = !lodash.isEqual(newVisibleLines, visibleLines)

    //     if (newVisibleLinesChanged) {
    //         this.setState({
    //             visibleLinesIndexes: newVisibleLines
    //         });
    //     }
    // }

    @autobind
    private selectCell(position: CellPosition) {
        const { cursorPosition } = this.state;

        const cellIsSelected = lodash.isEqual(cursorPosition, position);

        if (cellIsSelected) {
            return;
        }

        this.setState(
            {
                cursorPosition: position,
            },
            () => {
                if (position) {
                    this.props.onCellEvent(CellEvent.MouseSelection, position);
                }

                // this.scrollToCell(position);
            },
        );
    }

    // private scrollToCell(position: CellPosition) {
    //     const { lines, columns, leftFixedColumns, rightFixedColumns } = this.props;
    //     const { visibleColumnsIndexes, visibleLinesIndexes, columnWidths, lineHeights } = this.state;

    //     if (position == null) {
    //         return;
    //     }

    //     const viewportSize = this.getViewportSize();

    //     let scrollX = this.tableBody.scrollLeft;
    //     let scrollY = this.tableBody.scrollTop;

    //     const cellIndexX = this.getIndexOfColumn(position.columnName);
    //     const cellIndexY = this.getIndexOfLine(position.lineId);

    //     const cellBordersViewportLeft = cellIndexX <= lodash.first(visibleColumnsIndexes);
    //     const cellBordersViewportRight = cellIndexX >= lodash.last(visibleColumnsIndexes);
    //     const cellBordersViewportTop = cellIndexY <= lodash.first(visibleLinesIndexes);
    //     const cellBordersViewportBottom = cellIndexY >= lodash.last(visibleLinesIndexes);

    //     const cellIsFixedLeft = leftFixedColumns.includes(position.columnName);
    //     const cellIsFixedRight = rightFixedColumns.includes(position.columnName);

    //     if (cellBordersViewportLeft && !cellIsFixedLeft && !cellIsFixedRight) {
    //         scrollX = (cellIndexX > 0) ? getItemPosition<ColumnName>(cellIndexX - 1, columns, columnWidths) : 0;
    //     }

    //     if (cellBordersViewportTop) {
    //         scrollY = (cellIndexY > 0) ? getItemPosition<LineId>(cellIndexY - 1, lines, lineHeights) : 0;
    //     }

    //     if (cellBordersViewportRight && !cellIsFixedLeft && !cellIsFixedRight) {
    //         scrollX = (cellIndexX < columns.length - 1)
    //             ? getItemPosition<ColumnName>(cellIndexX + 2, columns, columnWidths) - viewportSize.width
    //             : this.getColumnsSumWidth(columns) - viewportSize.width;
    //     }

    //     if (cellBordersViewportBottom) {
    //         scrollY = (cellIndexY < lines.length - 1)
    //             ? getItemPosition<LineId>(cellIndexY + 2, lines, lineHeights) - viewportSize.height
    //             : this.getSumHeight() - viewportSize.height;
    //     }

    //     this.tableBody.scrollTo(scrollY, scrollX);
    // }

    // private getIndexOfColumn(columnName: ColumnName): number {
    //     return this.props.columns.indexOf(columnName);
    // }

    // private getIndexOfLine(lineId: LineId): number {
    //     return this.props.lines.indexOf(lineId);
    // }

    private emitTableEvent(eventType: TableEvent) {
        if (!this.tableEventsSubscribers[eventType]) {
            return;
        }

        this.tableEventsSubscribers[eventType].forEach((handler) => {
            handler(eventType);
        });
    }
}

// function getItemPosition<T extends number | string>(
//     index: number,
//     items: T[],
//     itemsSize: Record<T, number>
// ): number {
//     return lodash.range(0, index).reduce((acc, item) => {
//         const itemName = items[item];

//         return acc + itemsSize[itemName] + CELLS_SPACING;
//     }, 0);
// }
