import { PureComponent } from 'react';

import { LoadingStatus } from '@store/commonTypes';
import { PropsWithUserOrganizationsIds } from './types';

export class UserOrganizationsIdsWrapper<P extends {} = {}> extends PureComponent<PropsWithUserOrganizationsIds<P>> {
    public componentDidMount() {
        const { userOrganizationsIdsLoadingStatus, loadUserOrganizationsIds } = this.props;
        if (userOrganizationsIdsLoadingStatus === LoadingStatus.NOT_LOADED) {
            loadUserOrganizationsIds();
        }
    }
}
