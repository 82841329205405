import * as React from 'react';
import { BudgetItemStatus } from '@mrm/budget';

import { ApprovementButtons } from '../ApprovementButtons';

import * as styles from '../ApprovementButtons.scss';

interface Props {
    lineHasChanges: boolean;
    lineUnsavedStatus: BudgetItemStatus;
    onApproveButtonClick: () => void;
    onRejectButtonClick: () => void;
}

export function ExpertApprovementButtons({
    lineHasChanges,
    lineUnsavedStatus,
    onApproveButtonClick,
    onRejectButtonClick,
}: Props): JSX.Element {
    const disabled = !lineUnsavedStatus && lineHasChanges;

    return (
        <div className={styles.root}>
            <ApprovementButtons
                disableApproveButton={disabled}
                disableRejectButton={disabled}
                approveButtonTooltip="Утвердить"
                rejectButtonTooltip="Отправить на доработку"
                isApproveButtonActive={lineUnsavedStatus == BudgetItemStatus.Approved}
                isRejectButtonActive={lineUnsavedStatus == BudgetItemStatus.Rejected}
                onApproveButtonClick={onApproveButtonClick}
                onRejectButtonClick={onRejectButtonClick}
            />
        </div>
    );
}
