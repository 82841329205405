// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-main-Selector-_Selector__multiple{min-height:40px;flex-wrap:wrap !important}.src-client-modules-common-components-main-Selector-_Selector__arrow{transition:transform .3s;transform:rotateZ(0)}.src-client-modules-common-components-main-Selector-_Selector__openArrow{transform:rotateZ(180deg)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/main/Selector/Selector.scss"],"names":[],"mappings":"AAAA,wEACE,eAAgB,CAChB,yBAA0B,CAC3B,qEAGC,wBAAyB,CACzB,oBAAqB,CACtB,yEAGC,yBAA0B","sourcesContent":[".multiple {\n  min-height: 40px;\n  flex-wrap: wrap !important; // FIXME: wrong order of <style> element, webpack issue.\n}\n\n.arrow {\n  transition: transform .3s;\n  transform: rotateZ(0);\n}\n\n.openArrow {\n  transform: rotateZ(180deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"multiple": "src-client-modules-common-components-main-Selector-_Selector__multiple",
	"arrow": "src-client-modules-common-components-main-Selector-_Selector__arrow",
	"openArrow": "src-client-modules-common-components-main-Selector-_Selector__openArrow"
};
export default ___CSS_LOADER_EXPORT___;
