import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';
import { TitleWithCountIndicator } from '../../common';

import * as style from './Header.scss';

interface Props {
    lineId: string;
    hasOpenList: boolean;
    disableBindActivityButton: boolean;
    disableCreateActivityButton: boolean;
    activitiesCount: number;
    onOpenListButtonClick: () => void;
    onBindActivityButtonClick: () => void;
}

const BindActivityButton: React.FC<{ onClick: () => void; disable: boolean }> = ({ onClick, disable }) => (
    <div
        title={'Привязать существующий проект'}
        className={classNames(style.button, disable && style.disableButton)}
        onClick={!disable ? onClick : null}
        {...{
            'qa-id': 'budgetExecutionLineModalBindActivityButton',
        }}
    >
        <Icon type={IconType.HANGING_ARROW_SIDE} svgSize={24} />
    </div>
);

const CreateActivityButton: React.FC<{ lineId: string; disable: boolean }> = ({ lineId, disable }) => {
    const tooltip = 'Создать новый проект и связать';

    return disable ? (
        <div
            title={tooltip}
            className={classNames(style.button, disable && style.disableButton)}
            {...{
                'qa-id': 'budgetExecutionLineModalCreateActivityButton',
                'qa-disabled': 'true',
            }}
        >
            <Icon type={IconType.HANGING_PLUS_SIDE} svgSize={24} />
        </div>
    ) : (
        <a
            title={tooltip}
            className={classNames(style.button, disable && style.disableButton)}
            target="_blank"
            href={`/activity/new?budgetItemIdToLink=${lineId}`}
            {...{
                'qa-id': 'budgetExecutionLineModalCreateActivityButton',
                'qa-disabled': 'false',
            }}
        >
            <Icon type={IconType.HANGING_PLUS_SIDE} svgSize={24} />
        </a>
    );
};

export const Header: React.FC<Props> = ({
    lineId,
    hasOpenList,
    activitiesCount,
    disableBindActivityButton,
    disableCreateActivityButton,
    onOpenListButtonClick,
    onBindActivityButtonClick,
}) => (
    <div
        className={style.root}
        {...{
            'qa-id': 'budgetExecutionLineModalRelatedActivitiesHeader',
        }}
    >
        <TitleWithCountIndicator title={'Связанные проекты'} count={activitiesCount} />
        <BindActivityButton onClick={onBindActivityButtonClick} disable={disableBindActivityButton} />
        <CreateActivityButton lineId={lineId} disable={disableCreateActivityButton} />
        {activitiesCount > 1 && (
            <div className={style.headerOpenButton} onClick={onOpenListButtonClick}>
                {hasOpenList ? 'Свернуть' : 'Развернуть'}
            </div>
        )}
    </div>
);
