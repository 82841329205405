import * as React from 'react';
import classNames from 'classnames';

import * as style from './RejectMenu.scss';

import {
    LabeledInput,
    BodyPortal,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
} from 'sber-marketing-ui';

export const enum RejectMenuDirection {
    Up = 'up',
    Down = 'down',
}

interface Props {
    direction: RejectMenuDirection;
    rejectComment: string;
    onRejectCommentChange: (newValue: string) => void;
    onAcceptButtonClick: () => void;
    onCancelButtonClick: () => void;
    onMaskClick: () => void;
}

export const RejectMenu = ({
    direction,
    rejectComment,
    onRejectCommentChange,
    onAcceptButtonClick,
    onCancelButtonClick,
    onMaskClick,
}: Props): JSX.Element => {
    const commentIsEmpty = !rejectComment || !rejectComment.trim();

    return (
        <div
            className={classNames(
                style.root,
                direction == RejectMenuDirection.Up && style.directedUp,
                direction == RejectMenuDirection.Down && style.directedDown,
            )}
            {...{
                'qa-id': 'budgetPlanningRejectionMenu',
            }}
        >
            <div className={style.input}>
                <LabeledInput
                    qaId="budgetPlanningRejectionMenuCommentInput"
                    title={'Причина отклонения'}
                    value={rejectComment}
                    onValueChange={onRejectCommentChange}
                />
            </div>

            <div className={style.buttons}>
                <div className={style.button}>
                    <Button
                        qaId="budgetPlanningRejectionMenuAcceptButton"
                        theme={ButtonTheme.Main}
                        onClick={onAcceptButtonClick}
                        disabled={commentIsEmpty}
                    >
                        Сохранить
                    </Button>
                </div>

                <div className={style.button}>
                    <Button
                        qaId="budgetPlanningRejectionMenuCancelButton"
                        theme={ButtonTheme.Ghost}
                        onClick={onCancelButtonClick}
                    >
                        Отмена
                    </Button>
                </div>
            </div>

            <BodyPortal>
                <div className={style.mask} onClick={onMaskClick} />
            </BodyPortal>
        </div>
    );
};
