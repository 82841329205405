import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

// import { IconType } from 'sber-marketing-ui';
import type { Autopilot2, Autopilot2PlacementDeployStatus } from 'sber-marketing-types/backend';
import type { User } from '@store/user';

import { AutopilotWidgetTemplate, PlacementItemProps } from './AutopilotWidgetTemplate';
import type { StoreState } from '@store';
import { getLoginUser } from '@store/user';
import { getAutopilot } from '@store/activityPage/selectors';
import { loadAutopilot } from '@store/activityPage/thunks';
import { AutopilotApi } from '@api';

interface Props extends MapProps, DispatchProps {}

interface MapProps {
    activityId?: number;
    autopilot?: Autopilot2;
    user?: User;
}

interface DispatchProps {
    loadAutopilot?: (activityId: number) => Promise<void>;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class AutopilotWidgetBehaviour extends React.PureComponent<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        const { autopilot } = this.props;

        if (!autopilot) {
            return null;
        }

        return React.createElement(AutopilotWidgetTemplate, {
            autopilotIsLaunched: autopilot.placements?.some((item) => item.deployStatus !== 'OFF'),
            dashboardUrl: lodash.get(autopilot, 'dashboard.url') as string,
            userHasAutopilotClientAccess: this.checkUserAutopilotClientAccess(),
            placementItems: this.makePlacementItems(),
            onDashboardUrlChange: this.onDashboardUrlChange,
            onStatusSelection: this.onStatusSelection,
        });
    }

    @autobind
    private async onDashboardUrlChange(value: string) {
        const { activityId, autopilot } = this.props;

        await AutopilotApi.updateDashboard(autopilot.id, { url: value });
        await this.props.loadAutopilot(activityId);
    }

    @autobind
    private async onStatusSelection(name: string, status: Autopilot2PlacementDeployStatus) {
        const { activityId, autopilot } = this.props;

        // await AutopilotApi.updatePlacement(autopilot.id, { name, deployStatus: status });
        await this.props.loadAutopilot(activityId);
    }

    private makePlacementItems(): Omit<PlacementItemProps, 'canEditStatus' | 'onStatusSelection'>[] {
        const { autopilot } = this.props;

        if (!autopilot || !autopilot.placements) {
            return [];
        }

        const { placements } = autopilot;

        return placements
            .filter((item) => item.deployStatus !== 'OFF')
            .map((item) => ({
                name: item.name,
                title: item.name,
                code: item.code,
                icon: null,
                status: item.deployStatus,
                format: item.format,
            }));
    }

    private checkUserAutopilotClientAccess(): boolean {
        // check if user has role === Autopilot Client Service
        return this.props.user.attributes.roles.map(({ id }) => id).includes(24);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        autopilot: getAutopilot(state),
        user: getLoginUser(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            loadAutopilot,
        },
        dispatch,
    );
}
