export * from '../types';

export enum Mode {
    TOOLTIP = 'tooltip',
    DRAG_WINDOW = 'dragWindow',
}

export enum FieldName {
    Id = 'id',
    SapComment = 'sapComment',
    Tool = 'tool',
    Product = 'product',
    Activity = 'activity',
    Block = 'block',
    Plan = 'plan',
    Reserve = 'reserve',
    Fact = 'fact',
}
