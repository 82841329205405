import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ActivityParams } from 'sber-marketing-types/frontend';
import autobind from 'autobind-decorator';

import { StoreState } from '@store';
import { getBudgetItemByLineId } from '@store/budgetExecution';
import { getBudgetTransferMenuState, loadExperts } from '@store/budgetExecution/budgetTransferMenu';
import { BudgetItem } from '@mrm/budget';
import { User } from './types';

import { BindActivityPopup } from './BindActivityPopup';
import { withLinkActivity, WithLinkActivityProps } from './withLinkActivity';

interface Props extends Partial<MapProps>, Partial<DispatchProps>, Partial<WithLinkActivityProps> {
    lineId: string;
    linkedActivitiesIds: number[];
    activityId?: string;
    loading: boolean;
    onClose: () => void;
    onFinish: () => Promise<void>;
}

interface MapProps {
    budgetItem: BudgetItem;
    expertUsers: User[];
}

interface DispatchProps {
    loadExperts: () => void;
}

interface State {
    loading: boolean;
    selectedActivityId: number;
    selectedExpertUserId: number;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
@(withLinkActivity as any)
export class BindActivityPopupContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
            selectedActivityId: Number(props.activityId) || null,
            selectedExpertUserId: null,
        };
    }

    public componentDidMount() {
        this.props.loadExperts();
    }

    public render() {
        const { selectedExpertUserId, selectedActivityId } = this.state;

        return React.createElement(BindActivityPopup, {
            title: `Привязать существующий проект к ID ${this.props.budgetItem.serialNumber}`,
            loading: this.loading,
            isDisabledBindButton: this.isDisabledBindButton,
            selectedExpertUserId,
            selectedActivityId,
            expertUsers: this.expertUsers,
            onCloseButtonClick: this.onCloseButtonClick,
            onBindButtonClick: this.onBindButtonClick,
            onCancelButtonClick: this.onCancelButtonClick,
            onExpertUserSelection: this.onExpertUserSelection,
            onActivitySelection: this.onActivitySelection,
            ignoreActivityInDropdown: this.ignoreActivityInDropdown,
        });
    }

    @autobind
    private async onBindButtonClick(): Promise<void> {
        await this.bindActivityWithLine();
        await this.props.onFinish();
    }

    @autobind
    private onCancelButtonClick(): void {
        this.props.onClose();
    }

    @autobind
    private onCloseButtonClick(): void {
        this.props.onClose();
    }

    @autobind
    private onExpertUserSelection(expertUserId: number): void {
        this.setState({
            selectedExpertUserId: expertUserId,
        });
    }

    @autobind
    private onActivitySelection(activityId: number): void {
        this.setState({ selectedActivityId: activityId });
    }

    @autobind
    private ignoreActivityInDropdown(activity: ActivityParams): boolean {
        return !(
            activity.budgetItemIds.includes(this.props.lineId) || this.props.linkedActivitiesIds.includes(activity.id)
        );
    }

    private get isDisabledBindButton(): boolean {
        const { selectedExpertUserId, selectedActivityId } = this.state;
        return !selectedActivityId || !selectedExpertUserId;
    }

    private get expertUsers(): { id: number; name: string }[] {
        return this.props.expertUsers.map(({ id, firstName, secondName }) => ({
            id,
            name: `${secondName} ${firstName}`,
        }));
    }

    private get loading(): boolean {
        return this.props.loading || this.state.loading || this.props.linkActivityLoading;
    }

    private async bindActivityWithLine(): Promise<void> {
        const { selectedActivityId, selectedExpertUserId } = this.state;

        try {
            this.setState({ loading: true });

            await this.props.linkActivity({
                budgetItemId: this.props.lineId,
                activityId: selectedActivityId,
                approverId: selectedExpertUserId,
            });

            this.setState({ loading: false });
            this.props.onClose();
        } catch {
            this.setState({ loading: false });
            this.props.onClose();
        }
    }
}

function mapStateToProps(store: StoreState, props: Props): MapProps {
    const { lineId } = props;

    return {
        budgetItem: getBudgetItemByLineId(store, lineId),
        expertUsers: getBudgetTransferMenuState(store).experts.entities,
    };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
    return {
        loadExperts: () => dispatch(loadExperts(null)),
    };
}
