import { Success, Failure } from 'typescript-fsa';

import { TaskPageState, FetchTelegramError, PlugTelegramSuccess, PlugTelegramPayload } from '../types';

export const plugTelegramStartedReducer = (state: TaskPageState): TaskPageState => ({
    ...state,
    telegram: {
        ...state.telegram,
        isUserCreatedChat: state.telegram.isUserAuthorized,
        loading: true,
    },
});

export const plugTelegramErrorReducer = (
    state: TaskPageState,
    { error: { message } }: Failure<PlugTelegramPayload, FetchTelegramError>,
): TaskPageState => ({
    ...state,
    telegram: {
        ...state.telegram,
        isUserCreatedChat: false,
        loading: false,
        fetchError: {
            message,
        },
    },
});

export const plugTelegramDoneReducer = (
    state: TaskPageState,
    { result }: Success<PlugTelegramPayload, PlugTelegramSuccess>,
): TaskPageState => {
    return {
        ...state,
        telegram: {
            ...state.telegram,
            isUserCreatedChat: state.telegram.isUserAuthorized,
            linkToAuthorization: result.linkToConnect,
            loading: false,
        },
    };
};
