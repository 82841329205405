// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-components-CommentStatic-_CommentStatic__root{width:100%}.src-client-modules-activity-pages-task-components-CommentStatic-_CommentStatic__assets{margin-top:17px}.src-client-modules-activity-pages-task-components-CommentStatic-_CommentStatic__assets:first-child{margin-top:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/components/CommentStatic/CommentStatic.scss"],"names":[],"mappings":"AAAA,sFACI,UAAW,CACd,wFAGG,eAAgB,CADpB,oGAIQ,YAAa","sourcesContent":[".root {\n    width: 100%;\n}\n\n.assets {\n    margin-top: 17px;\n\n    &:first-child {\n        margin-top: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-components-CommentStatic-_CommentStatic__root",
	"assets": "src-client-modules-activity-pages-task-components-CommentStatic-_CommentStatic__assets"
};
export default ___CSS_LOADER_EXPORT___;
