import * as React from 'react';

import * as styles from './Preloader.scss';

export function Preloder(): JSX.Element {
    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'tagsPageGraphPreloader',
            }}
        >
            <svg width="100%" height="100%" viewBox="0 0 357 288" preserveAspectRatio="xMidYMin meet">
                <g className={styles.graph}>
                    <path stroke="#d6e1e8" d="M 0 0 L 88.6828 99.3078" />
                    <path stroke="#d6e1e8" d="M 0 0 L -127.5109 101.8078" />
                    <path stroke="#d6e1e8" d="M 0 0 L -140.7296 -81.3078" />
                    <path stroke="#d6e1e8" d="M 0 0 L 44.8859 -81.3078" />

                    <circle cx="0" cy="0" r="20" fill="#d6e1e8" />
                    <circle cx="88.6828" cy="99.3078" r="12" fill="#d6e1e8" />
                    <circle cx="-127.5109" cy="101.8078" r="10" fill="#d6e1e8" />
                    <circle cx="-140.7296" cy="-81.3078" r="7" fill="#d6e1e8" />
                    <circle cx="44.8859" cy="-81.3078" r="7" fill="#d6e1e8" />
                </g>
            </svg>
        </div>
    );
}
