// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-dashboard-RightSidebar-ActivityWidget-_ActivityWidget__root{padding:12px 24px;border-bottom:1.3px solid #e3e9e5;font-family:'Open Sans'}.src-client-modules-dashboard-RightSidebar-ActivityWidget-_ActivityWidget__title{margin-bottom:11px;user-select:none;color:#000000;font-size:13px;font-weight:600;line-height:16px}.src-client-modules-dashboard-RightSidebar-ActivityWidget-_ActivityWidget__item{margin-bottom:14px}.src-client-modules-dashboard-RightSidebar-ActivityWidget-_ActivityWidget__item:last-child{margin-bottom:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/dashboard/RightSidebar/ActivityWidget/ActivityWidget.scss"],"names":[],"mappings":"AAAA,gFACI,iBAAkB,CAElB,iCAAkC,CAElC,uBAAwB,CAC3B,iFAGG,kBAAmB,CAEnB,gBAAiB,CAEjB,aAAc,CAEd,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACpB,gFAGG,kBAAmB,CADvB,2FAIQ,eAAgB","sourcesContent":[".root {\n    padding: 12px 24px;\n\n    border-bottom: 1.3px solid #e3e9e5;\n\n    font-family: 'Open Sans';\n}\n\n.title {\n    margin-bottom: 11px;\n\n    user-select: none;\n\n    color: #000000;\n\n    font-size: 13px;\n    font-weight: 600;\n    line-height: 16px;\n}\n\n.item {\n    margin-bottom: 14px;\n\n    &:last-child {\n        margin-bottom: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-dashboard-RightSidebar-ActivityWidget-_ActivityWidget__root",
	"title": "src-client-modules-dashboard-RightSidebar-ActivityWidget-_ActivityWidget__title",
	"item": "src-client-modules-dashboard-RightSidebar-ActivityWidget-_ActivityWidget__item"
};
export default ___CSS_LOADER_EXPORT___;
