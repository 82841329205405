import { createSelector } from 'reselect';

import { TaskPageState, TaskLoadingError } from '../types';
import { getTaskPageState } from './lib';

export const getTaskError = createSelector(
    getTaskPageState,
    ({ errorCode, errorMessage, errorType }: TaskPageState): TaskLoadingError => ({
        errorCode,
        errorMessage,
        errorType,
    }),
);
