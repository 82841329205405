// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-components-SubscribeButton-_SubscribeButton__root{display:flex;align-items:center;justify-content:space-between}.src-client-modules-activity-pages-task-components-SubscribeButton-_SubscribeButton__root:hover{cursor:pointer}.src-client-modules-activity-pages-task-components-SubscribeButton-_SubscribeButton__icon{width:24px;height:24px;pointer-events:none}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/components/SubscribeButton/SubscribeButton.scss"],"names":[],"mappings":"AAAA,0FACI,YAAa,CACb,kBAAmB,CACnB,6BAA8B,CAHlC,gGAMQ,cAAe,CAClB,0FAID,UAAW,CACX,WAAY,CAEZ,mBAAoB","sourcesContent":[".root {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    &:hover {\n        cursor: pointer;\n    }\n}\n\n.icon {\n    width: 24px;\n    height: 24px;\n\n    pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-components-SubscribeButton-_SubscribeButton__root",
	"icon": "src-client-modules-activity-pages-task-components-SubscribeButton-_SubscribeButton__icon"
};
export default ___CSS_LOADER_EXPORT___;
