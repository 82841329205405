import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { TaskStatus } from 'sber-marketing-types/frontend';
import { Icon, IconType, WithTooltip, Skeleton } from 'sber-marketing-ui';

import { TaskSearchResult } from '@store/search';

import { DatesFormatter } from '@common/Utils';

import { Activity, Author } from '../WithSkeleton';
import { HighlightQuery, Theme } from '../HighlightQuery';

import * as styles from './TaskCard.scss';

interface Props extends Partial<TaskSearchResult> {}

export function TaskCard({ id, title, deadline, status, activity, workType, authorId }: Props): JSX.Element {
    const date = new Date();
    const deadlineHasPassed = date > deadline;

    let statusColor: string;
    let dateColor: string;
    if (status === TaskStatus.Closed) {
        statusColor = styles.backgroundMediumBlue;
    } else if (deadlineHasPassed) {
        statusColor = styles.backgroundRed;
        dateColor = styles.colorRed;
    } else if (status) {
        statusColor = styles.backgroundGreen;
    }

    const deadlineFormatted = deadline ? DatesFormatter.ddMonthyy(deadline) : null;

    return (
        <div
            {...{
                'qa-id': 'searchTaskCard',
                'qa-task-id': id,
            }}
        >
            <div className={classNames(styles.statusLeft, statusColor)} />

            <div className={styles.row}>
                <div className={classNames(styles.text, styles.growingBlock)}>
                    <Activity {...activity} className={styles.skeleton} />
                </div>

                <div
                    className={classNames(styles.text, styles.dateAndStatus)}
                    {...{
                        'qa-id': 'searchTaskCardDeadline',
                        'qa-value': deadlineFormatted,
                    }}
                >
                    <Skeleton isReady={deadline} className={styles.skeleton}>
                        <div className={classNames(styles.dateAndStatusContent, dateColor)}>
                            <Icon svgSize={16} type={IconType.CALENDAR_ICON} className={styles.icon} />
                            {deadlineFormatted}
                        </div>

                        <Status />
                    </Skeleton>
                </div>
            </div>

            <div className={styles.row}>
                <div className={classNames(styles.text, styles.growingBlock)}>
                    <WorkType workType={workType} />
                </div>

                <div className={styles.author}>
                    <Author authorId={authorId} />
                </div>
            </div>

            <div
                className={styles.title}
                {...{
                    'qa-id': 'searchTaskCardTaskTitle',
                    'qa-value': title,
                }}
            >
                <Skeleton isReady={title} className={styles.skeleton}>
                    <Link
                        to={`/activity/${activity ? activity.id : ''}/task/${id}`}
                        target="_blank"
                        className={styles.queryLink}
                    >
                        <HighlightQuery theme={Theme.Bold} text={title} />
                    </Link>
                </Skeleton>
            </div>
        </div>
    );
}

type StatusProps = Pick<Props, 'deadline' | 'status'>;

function Status({ deadline, status }: StatusProps): JSX.Element {
    const date = new Date();
    const deadlineHasPassed = date > deadline;
    const deadlineIsInFiveDays = false;
    const deadlineIsInThreeDays = false;

    let content: JSX.Element;

    if (deadlineIsInFiveDays) {
        content = (
            <div className={classNames(styles.status, styles.backgroundLightYellow, styles.colorYellow, styles.bold)}>
                5 дней
            </div>
        );
    } else if (deadlineIsInThreeDays) {
        content = (
            <div className={classNames(styles.status, styles.backgroundLightRed, styles.colorRed, styles.bold)}>
                3 дня
            </div>
        );
    } else if (status === TaskStatus.Closed) {
        content = (
            <div className={classNames(styles.status, styles.backgroundMediumBlue, styles.colorDarkBlue)}>Закрыта</div>
        );
    } else if (deadlineHasPassed) {
        content = (
            <div className={classNames(styles.status, styles.backgroundLightRed, styles.colorRed)}>Просрочена</div>
        );
    } else {
        content = null;
    }

    return content;
}

type WorkTypeProps = Pick<Props, 'workType'>;

function WorkType({ workType }: WorkTypeProps): JSX.Element {
    const isReady = workType !== undefined;
    let title;
    let content;

    if (isReady) {
        if (workType === null) {
            title = 'Не задан';
            content = 'Тип работ не задан';
        } else {
            title = `${workType.name} ${workType['technical_status'] === 'deleted' ? '(Удален)' : ''}`;
            content = `Тип работ: ${title}`;
        }
    }

    return (
        <Skeleton isReady={isReady} className={styles.skeleton} loadingClassName={styles.workTypeLoading}>
            <WithTooltip content={title}>
                <div
                    className={styles.text}
                    {...{
                        'qa-id': 'searchTaskCardWorkType',
                        'qa-value': content,
                    }}
                >
                    <Icon
                        svgSize={16}
                        type={IconType.WORK_TYPE_ICON}
                        className={classNames(styles.icon, styles.workTypeIcon)}
                    />
                    {content}
                </div>
            </WithTooltip>
        </Skeleton>
    );
}
