import { ColumnData, ColumnName, TableLine, ChangeList, REQUIRED_COLUMN_NAMES } from '@store/budgetExecution/types';
import { CellParams, CellBackgroundColor } from '../LayerManager';
import { Correction, CorrectionType } from '@mrm/budget';

import { getFieldValue, formatDateToString } from './Utils';
import { ColumnsList } from '../../../ColumnsConfig';

interface Props {
    line: TableLine;
    column: ColumnData;
    unsavedChanges: ChangeList;
    validationStatus: boolean;
    isDisabled: boolean;
    budgetItemCorrections: Correction<CorrectionType.BudgetItem>[];
}

export class DatepickerCellParamsCreator {
    private line: TableLine;
    private column: ColumnData;
    private unsavedChanges: ChangeList;
    private validationStatus: boolean;
    private isDisabled: boolean;
    private budgetItemCorrections: Correction<CorrectionType.BudgetItem>[];

    constructor(props: Props) {
        const { line, column, unsavedChanges, validationStatus, isDisabled, budgetItemCorrections } = props;

        this.line = line;
        this.column = column;
        this.unsavedChanges = unsavedChanges;
        this.validationStatus = validationStatus;
        this.isDisabled = isDisabled;
        this.budgetItemCorrections = budgetItemCorrections;
    }

    public makeCellParams(): CellParams {
        let value: Date;
        let color: CellBackgroundColor;

        const unsavedValue = this.getUnsavedValue(this.column);
        const correctionValue = this.getCorrectionValue(this.column);
        const baseValue = this.getBaseValue(this.column);

        if (unsavedValue !== undefined) {
            value = unsavedValue;
            color = CellBackgroundColor.UnsavedChange;
        } else if (correctionValue) {
            value = correctionValue;
            color = CellBackgroundColor.SavedChange;
        } else {
            value = baseValue;
        }

        const title = value ? formatDateToString(value) : null;

        let minDate = null;
        let maxDate = null;

        if (this.column.name == ColumnName.StartDate) {
            maxDate = this.getValueByColumnName(ColumnName.EndDate);
        }

        if (this.column.name == ColumnName.EndDate) {
            minDate = this.getValueByColumnName(ColumnName.StartDate);
        }

        return {
            title,
            tooltip: title || '-',
            value,
            originalValue: baseValue,
            minDate: minDate || null,
            maxDate: maxDate || null,
            bgColor: color,
            displayValidationError: this.cellIsInvalid(value),
            disabled: this.isDisabled,
        };
    }

    private getUnsavedValue(column: ColumnData): Date {
        const changes = this.unsavedChanges[this.line.id] || [];

        const unsavedChange = changes.find(
            (item) => item.budgetItemId == this.line.id && item.columnName == column.name,
        );

        return unsavedChange ? new Date(unsavedChange.value as string) : undefined;
    }

    private getBaseValue(column: ColumnData): Date {
        return getFieldValue(this.line.fields[column.name], column.valueType) as Date;
    }

    private getValueByColumnName(columnName: ColumnName): Date {
        const column = ColumnsList.find((item) => item.name == columnName);

        let value: Date;

        const unsavedValue = this.getUnsavedValue(column);

        if (unsavedValue !== undefined) {
            value = unsavedValue;
        } else {
            const baseValue = this.getBaseValue(column);

            value = baseValue;
        }

        return value;
    }

    private cellIsInvalid(value: Date) {
        return REQUIRED_COLUMN_NAMES.includes(this.column.name) && this.validationStatus && !value;
    }

    private getCorrectionValue(column: ColumnData): Date {
        let result = null;

        if (column.name === ColumnName.StartDate) {
            const correction = this.budgetItemCorrections.find(
                (correction) => !!correction.data.params.realizationStart,
            );

            if (correction) {
                result = correction.data.params.realizationStart;
            }
        } else {
            const correction = this.budgetItemCorrections.find((correction) => !!correction.data.params.realizationEnd);

            if (correction) {
                result = correction.data.params.realizationEnd;
            }
        }

        return result ? new Date(result) : null;
    }
}
