// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-TaskParticipants-Tooltip-_Tooltip__root{position:absolute;z-index:2;margin-left:50%;transform:translateX(-50%);white-space:nowrap;font-size:13px}.src-client-modules-common-TaskParticipants-Tooltip-_Tooltip__triangle{width:0;height:0;margin:0 auto;border-width:0 5px 10px 5px;border-style:solid;border-color:transparent transparent #f1f5f8 transparent}.src-client-modules-common-TaskParticipants-Tooltip-_Tooltip__text{padding:10px;text-align:center;border-radius:10px;background-color:#f1f5f8;box-shadow:0 5px 23px -3px rgba(0,0,0,0.1)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/TaskParticipants/Tooltip/Tooltip.scss"],"names":[],"mappings":"AAEA,mEACI,iBAAkB,CAClB,SAAU,CAEV,eAAgB,CAEhB,0BAA2B,CAC3B,kBAAmB,CAEnB,cAAe,CAClB,uEAGG,OAAQ,CACR,QAAS,CACT,aAAc,CAEd,2BAA4B,CAC5B,kBAAmB,CACnB,wDAAgE,CACnE,mEAGG,YAAa,CAEb,iBAAkB,CAElB,kBAAmB,CACnB,wBA9BmB,CA+BnB,0CAA8C","sourcesContent":["$tooltip-color: #f1f5f8;\n\n.root {\n    position: absolute;\n    z-index: 2;\n\n    margin-left: 50%;\n\n    transform: translateX(-50%);\n    white-space: nowrap;\n\n    font-size: 13px;\n}\n\n.triangle {\n    width: 0;\n    height: 0;\n    margin: 0 auto;\n\n    border-width: 0 5px 10px 5px;\n    border-style: solid;\n    border-color: transparent transparent $tooltip-color transparent;\n}\n\n.text {\n    padding: 10px;\n\n    text-align: center;\n\n    border-radius: 10px;\n    background-color: $tooltip-color;\n    box-shadow: 0 5px 23px -3px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-TaskParticipants-Tooltip-_Tooltip__root",
	"triangle": "src-client-modules-common-TaskParticipants-Tooltip-_Tooltip__triangle",
	"text": "src-client-modules-common-TaskParticipants-Tooltip-_Tooltip__text"
};
export default ___CSS_LOADER_EXPORT___;
