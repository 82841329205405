import { actionCreatorFactory } from 'typescript-fsa';

import { State } from '../types';

const actionCreator = actionCreatorFactory('ACTIVITY_REFERENCE_MENU');

const setActivityReferenceMenu = actionCreator<Partial<State>>('SET_ACTIVITY_REFERENCE_MENU');
const setSelectedBudgetItemIds = actionCreator<string[]>('SET_SELECTED_BUDGET_ITEM_IDS');
const reset = actionCreator<void>('RESET');

export { setActivityReferenceMenu, setSelectedBudgetItemIds, reset };
