// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-SidebarWithTabs-common-NoResultsMarker-_NoResultsMarker__root{padding:12px;display:flex;flex-direction:column;gap:8px 0;align-items:center;justify-content:center;border-radius:6px;background:#f6f6f6;font-family:'Open Sans';font-size:14px;font-weight:600;line-height:20px;letter-spacing:0.25px;color:#7e8681}.src-client-modules-common-SidebarWithTabs-common-NoResultsMarker-_NoResultsMarker__icon{color:#7e8681}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/SidebarWithTabs/common/NoResultsMarker/NoResultsMarker.scss"],"names":[],"mappings":"AAAA,yFACI,YAAa,CAEb,YAAa,CACb,qBAAsB,CACtB,SAAU,CACV,kBAAmB,CACnB,sBAAuB,CAEvB,iBAAkB,CAClB,kBAAmB,CAEnB,uBAAwB,CACxB,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACjB,qBAAsB,CACtB,aAAc,CACjB,yFAGG,aAAc","sourcesContent":[".root {\n    padding: 12px;\n\n    display: flex;\n    flex-direction: column;\n    gap: 8px 0;\n    align-items: center;\n    justify-content: center;\n    \n    border-radius: 6px;\n    background: #f6f6f6;\n\n    font-family: 'Open Sans';\n    font-size: 14px;\n    font-weight: 600;\n    line-height: 20px;\n    letter-spacing: 0.25px;\n    color: #7e8681;\n}\n\n.icon {\n    color: #7e8681;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-SidebarWithTabs-common-NoResultsMarker-_NoResultsMarker__root",
	"icon": "src-client-modules-common-SidebarWithTabs-common-NoResultsMarker-_NoResultsMarker__icon"
};
export default ___CSS_LOADER_EXPORT___;
