import * as React from 'react';
import { useSelector } from 'react-redux';
import { CustomScrollbar } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { LineModalTabs, getLineModalState } from '@store/budgetPlanning/lineModal';

import { CommonInfoWidget, CommentsWidget, BudgetItemHistoryWidget, TagsEditorWidget } from '../../WidgetsGroups';

import * as style from './Content.scss';

interface Props {
    lineId: string;
    onAppliedTagsChange: () => void;
}

export function Content({ lineId, onAppliedTagsChange }: Props): JSX.Element {
    return (
        <React.Fragment>
            <TabContent tab={LineModalTabs.GeneralInfo}>
                <div className={style.tabBarContentWrapper}>
                    <CustomScrollbar hideHorizontalScrollbar>
                        <div className={style.widgetWrapper}>
                            <CommonInfoWidget lineId={lineId} />
                        </div>

                        <div className={style.tagsEditorWrapper}>
                            <TagsEditorWidget lineId={lineId} onAttachedTagsChange={onAppliedTagsChange} />
                        </div>

                        <CommentsWidget lineId={lineId} />
                    </CustomScrollbar>
                </div>
            </TabContent>

            <TabContent tab={LineModalTabs.BudgetItemSnapshotStory}>
                <div className={style.tabBarContentWrapper}>
                    <BudgetItemHistoryWidget budgetItemId={lineId} />
                </div>
            </TabContent>
        </React.Fragment>
    );
}

interface TabContentProps {
    tab: LineModalTabs;
    children: JSX.Element;
}

function TabContent({ tab, children }: TabContentProps): JSX.Element {
    const selectedTab = useSelector((state: StoreState) => getLineModalState(state).selectedTab);

    return tab === selectedTab ? children : null;
}
