import * as React from 'react';
import { connect } from 'react-redux';
import { UserCard } from 'sber-marketing-types/frontend';

import { StoreState } from '@store';
import { getUserById } from '@store/appUsers';

import { Author } from './Author';

interface Props extends Partial<MapProps>, OwnProps {}

interface MapProps {
    user: UserCard;
}

interface OwnProps {
    id: number;
}

@(connect(mapStateToProps) as any)
export class AuthorContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { user } = this.props;

        return <Author user={user} />;
    }
}

function mapStateToProps(state: StoreState, props: OwnProps): MapProps {
    return {
        user: getUserById(state, props.id),
    };
}
