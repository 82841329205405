import { UserResponseParams } from 'sber-marketing-types/frontend';
import { NumericCellValue, DateCellValue } from '@store/budgetExecution/types';

export function makeNumberValue(value: number): NumericCellValue {
    return {
        title: value !== undefined && value !== null ? value.toString() : null,
        number: value,
    };
}

export function makeDateValue(date: Date): DateCellValue {
    return {
        title: formatDateToString(date),
        date,
    };
}

export function formatResponsibleName(user: UserResponseParams): string {
    const { firstName, middleName, secondName } = user;

    return middleName ? `${secondName} ${firstName} ${middleName}` : `${secondName} ${firstName}`;
}

function formatDateToString(date: Date): string {
    return `${date.toLocaleString('ru', { day: 'numeric', month: 'long' })} ${date.getFullYear()}`;
}
