import { Dictionary } from 'lodash';
import { ProductParams } from 'sber-marketing-types/frontend';

import { LoadingStatus } from '@store/commonTypes';

export interface ProductsState {
    entities: ProductParams[];
    byIds: Dictionary<ProductParams>;
    stores: Record<StoreTypes, EntitiesStore>;
}

export interface EntitiesStore {
    fetchersCount: number;
    loadingStatus: LoadingStatus;
    ids: string[];
}

export const enum StoreTypes {
    GENERAL = 'GENERAL',
    AVAILABLE_ACTIVITIES_FILTER = 'AVAILABLE_ACTIVITIES_PAGE_FILTER',
    MY_ACTIVITIES_FILTER = 'MY_ACTIVITIES_PAGE_FILTER',
}

export interface LoadProductsParams {
    store: StoreTypes;
    ids?: string[];
}

export interface SetStoreIdsParams {
    store: StoreTypes;
    ids: string[];
}

export interface SetLoadingStatusParams {
    store: StoreTypes;
    status: LoadingStatus;
}

export type LoadEntitiesPayload = ProductParams[];
