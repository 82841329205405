// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ParticipatorCommentInput-_ParticipatorCommentInput__root{width:100%;margin-top:25px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/ParticipatorCommentInput/ParticipatorCommentInput.scss"],"names":[],"mappings":"AAAA,kIACI,UAAW,CACX,eAAgB","sourcesContent":[".root {\n    width: 100%;\n    margin-top: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ParticipatorCommentInput-_ParticipatorCommentInput__root"
};
export default ___CSS_LOADER_EXPORT___;
