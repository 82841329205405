import * as React from 'react';

import ReactFlow, {
    ReactFlowProvider,
    Node,
    Edge,
    applyEdgeChanges,
    applyNodeChanges,
    useReactFlow,
} from 'react-flow-renderer';
import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import { getNodes, getEdges, getTagsToDisplay } from '@store/tagsPage';

import { NodeTypes } from './NodeTypes';

// custom styles for react-flow
import 'react-flow-renderer/dist/style.css';
import './ReactFlowCustomStyles.css';

import { injectIntoNodes } from './GraphLayout';

import * as styles from './Graph.scss';

export interface ContentSize {
    width: number;
    height: number;
}

function useInteractivity() {
    const contentRef = React.useRef<HTMLDivElement>();
    const [contentSize, setContentSize] = React.useState<ContentSize>(null);

    const { fitView } = useReactFlow();

    const storeNodes = useSelector((state: StoreState) => getNodes(state));
    const storeEdges = useSelector((state: StoreState) => getEdges(state));
    const tags = useSelector((state: StoreState) => getTagsToDisplay(state));

    const [nodes, setNodes] = React.useState<Node[]>(storeNodes);
    const [edges, setEdges] = React.useState<Edge[]>(storeEdges);

    const onNodesChange = React.useCallback((changes) => setNodes((nds) => applyNodeChanges(changes, nds)), [setNodes]);
    const onEdgesChange = React.useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), [setEdges]);

    React.useEffect(() => {
        const nodesWithLayout = contentSize
            ? injectIntoNodes({
                  tags,
                  nodes: storeNodes,
                  edges: storeEdges,
                  contentSize,
              })
            : storeNodes;

        setNodes(nodesWithLayout);
        setEdges(storeEdges);
    }, [storeNodes, storeEdges, contentSize]);

    React.useEffect(() => fitView({ duration: 500 }), [nodes]);

    React.useEffect(() => {
        if (contentRef.current) {
            setContentSize({
                width: contentRef.current.clientWidth,
                height: contentRef.current.clientHeight,
            });
        }
    }, [contentRef.current]);

    return {
        contentRef,
        nodes,
        edges,
        onNodesChange,
        onEdgesChange,
    };
}

export function Graph(): JSX.Element {
    return (
        <ReactFlowProvider>
            <GraphWithPropvider />
        </ReactFlowProvider>
    );
}

function GraphWithPropvider(): JSX.Element {
    const { contentRef, nodes, edges, onNodesChange, onEdgesChange } = useInteractivity();

    return (
        <div
            ref={contentRef}
            className={styles.root}
            {...{
                'qa-id': 'tagsPageGraph',
            }}
        >
            <ReactFlow
                fitView
                nodesConnectable={false}
                nodes={[...nodes]}
                edges={edges}
                nodeTypes={NodeTypes}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
            />
        </div>
    );
}
