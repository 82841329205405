import * as React from 'react';
import classNames from 'classnames';

import * as style from './CheckboxWithSortingColumnHeader.scss';

import { OrderType } from '../../types';

import { Icon, IconType } from 'sber-marketing-ui';

interface Props {
    displayChecked: boolean;
    displayMinus: boolean;
    sortingOrder: OrderType;
    onCheckboxClick: () => void;
    onSortingClick: () => void;
}

export const CheckboxWithSortingColumnHeader = ({
    displayChecked,
    displayMinus,
    sortingOrder,
    onCheckboxClick,
    onSortingClick,
}: Props): JSX.Element => {
    let checkboxIconType: IconType;

    if (displayChecked) {
        checkboxIconType = IconType.CHECKBOX24_CHECKED;
    } else if (displayMinus) {
        checkboxIconType = IconType.CHECKBOX24_MINUS;
    } else {
        checkboxIconType = IconType.CHECKBOX24_UNCHECKED;
    }

    return (
        <div className={style.root}>
            <div className={style.checkboxIconWrapper} onClick={onCheckboxClick}>
                <Icon className={style.checkboxIcon} type={checkboxIconType} />
            </div>

            <Icon
                className={classNames(
                    style.sortingIcon,
                    !!sortingOrder && style.enabled,
                    sortingOrder === OrderType.Asc && style.sortedAsc,
                )}
                type={IconType.SORTING}
                svgSize={20}
                onClick={onSortingClick}
            />
        </div>
    );
};
