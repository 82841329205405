import * as React from 'react';

import * as styles from './ResetLinksGroupTooltip.scss';

export function ResetRegionalityLinksTooltip(): JSX.Element {
    return (
        <div className={styles.tooltip}>
            <div className={styles.tooltipSubtitle}>При выборе этого пункта будут сброшены следующие значения</div>

            <ul className={styles.tooltipList}>
                <li> Направление </li>
                <br />

                <li> Инструмент </li>
                <br />

                <li> Ресурс </li>
                <br />

                <li> Статья </li>
            </ul>
        </div>
    );
}

export function ResetBlockLinksTooltip(): JSX.Element {
    return (
        <div className={styles.tooltip}>
            <div className={styles.tooltipSubtitle}>При выборе этого пункта будут сброшены следующие значения</div>

            <ul className={styles.tooltipList}>
                <li> Блок </li>
                <br />

                <li> МВЗ/Дивизион </li>
                <br />

                <li> Сегмент </li>
                <br />

                <li> Продукт </li>
                <br />

                <li> Канал </li>
            </ul>
        </div>
    );
}
