import * as React from 'react';
import autobind from 'autobind-decorator';
import * as moment from 'moment';

import { LabeledDatepicker } from './LabeledDatepicker';

interface Props {
    qaId?: string;
    title: string;
    value?: moment.Moment;
    maxDate?: moment.Moment;
    minDate?: moment.Moment;
    errorMessage?: string;
    displayError?: boolean;
    disabled?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    onValueChange?: (value: moment.Moment) => void;
}

interface State {
    value: moment.Moment;
    isFocused: boolean;
}

export class LabeledDatepickerContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            value: this.props.value,
            isFocused: false,
        };
    }

    public UNSAFE_componentWillReceiveProps(newProps: Props) {
        const valueChanged = newProps.value !== this.state.value;

        if (valueChanged) {
            this.setState({
                value: newProps.value,
            });
        }
    }

    public render(): JSX.Element {
        const { value, isFocused } = this.state;

        return React.createElement(LabeledDatepicker, {
            qaId: this.props.qaId,
            title: this.props.title,
            value,
            maxDate: this.props.maxDate,
            minDate: this.props.minDate,
            errorMessage: this.props.errorMessage,
            displayError: this.props.displayError,
            isFocused,
            disabled: this.props.disabled,
            onInputFocus: this.onInputFocus,
            onInputBlur: this.onInputBlur,
            onDateChange: this.onDateChange,
        });
    }

    @autobind
    protected onInputFocus() {
        this.setState({
            isFocused: true,
        });

        if (this.props.onFocus) {
            this.props.onFocus();
        }
    }

    @autobind
    protected onInputBlur() {
        this.setState({
            isFocused: false,
        });

        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    @autobind
    protected onDateChange(date: moment.Moment) {
        this.setState(
            {
                value: date,
            },
            () => {
                if (this.props.onValueChange) {
                    this.props.onValueChange(date);
                }
            },
        );
    }
}
