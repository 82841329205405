import { CommentUpdateParams, FileResponse, TaskCommentTransferObject } from 'sber-marketing-types/backend';

import type {
    Comment,
    CommentCreateParams,
    ReactToTaskCommentParams,
    TaskCommentReaction,
} from 'sber-marketing-types/frontend';

import { rtkApi } from './rtkApi';
import { taskApi, TaskPathParams } from './task';
import { selectMyUserId } from './auth';

export interface TaskCommentPathParams extends TaskPathParams {
    commentId: string;
}

export interface TaskCommentFilePathParams extends TaskCommentPathParams {
    fileId: string;
}

export interface SendCommentMutation extends CommentCreateParams, TaskPathParams {}
export interface EditCommentMutation extends CommentUpdateParams, TaskCommentPathParams {}

export interface CommentReactionMutation extends TaskCommentPathParams, ReactToTaskCommentParams {}

export const commentApi = rtkApi.injectEndpoints({
    endpoints: (builder) => ({
        sendComment: builder.mutation<{ comment: Comment }, SendCommentMutation>({
            query: ({ taskId, ...body }) => ({
                url: `/task/${taskId}/comment`,
                method: 'POST',
                body,
            }),
            async onQueryStarted({ taskId, chanelId, files }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        taskApi.util.updateQueryData('getTask', { id: taskId }, (draft) => {
                            const channel = draft.task.chanels.find(({ id }) => id === chanelId);

                            if (!channel.comments) {
                                channel.comments = [];
                            }

                            channel.comments.push(data.comment as TaskCommentTransferObject);
                        }),
                    );
                } catch (e) {
                    console.error(e);
                }
            },
        }),
        deleteComment: builder.mutation<void, TaskCommentPathParams>({
            query: ({ taskId, commentId }) => ({
                url: `/task/${taskId}/comment/${commentId}`,
                method: 'DELETE',
            }),
            async onQueryStarted({ taskId, commentId }, { dispatch, queryFulfilled }) {
                const patches = dispatch(
                    taskApi.util.updateQueryData('getTask', { id: taskId }, (draft) => {
                        for (const channel of draft.task.chanels) {
                            if (!channel.comments) continue;

                            const filtered = channel.comments.filter(({ id }) => id !== commentId);

                            if (filtered.length !== channel.comments.length) {
                                channel.comments = filtered;
                                return;
                            }
                        }
                    }),
                );

                try {
                    await queryFulfilled;
                } catch (e) {
                    patches.undo();
                    console.error(e);
                }
            },
        }),
        editComment: builder.mutation<{ comment: Comment }, EditCommentMutation>({
            query: ({ taskId, commentId, ...body }) => ({
                url: `/task/${taskId}/comment/${commentId}`,
                method: 'PUT',
                body,
            }),
            async onQueryStarted({ taskId, commentId, text }, { dispatch, queryFulfilled }) {
                const patches = dispatch(
                    taskApi.util.updateQueryData('getTask', { id: taskId }, (draft) => {
                        for (const channel of draft.task.chanels) {
                            if (!channel.comments) continue;

                            const comment = channel.comments.find(({ id }) => id === commentId);

                            if (comment) {
                                comment.text = text;
                                return;
                            }
                        }
                    }),
                );

                try {
                    await queryFulfilled;
                } catch (e) {
                    patches.undo();
                    console.error(e);
                }
            },
        }),
        addCommentFile: builder.mutation<Comment, FileResponse & TaskCommentPathParams>({
            query: ({ taskId, commentId, ...body }) => ({
                url: `/task/${taskId}/comment/${commentId}/file`,
                method: 'POST',
                body,
            }),
            async onQueryStarted({ taskId, commentId, ...file }, { dispatch, queryFulfilled }) {
                const patches = dispatch(
                    taskApi.util.updateQueryData('getTask', { id: taskId }, (draft) => {
                        for (const channel of draft.task.chanels) {
                            if (!channel.comments) continue;

                            const comment = channel.comments.find(({ id }) => id === commentId);

                            if (comment) {
                                if (!comment.files) {
                                    comment.files = [];
                                }

                                comment.files = [file, ...comment.files];
                                return;
                            }
                        }
                    }),
                );

                try {
                    await queryFulfilled;
                } catch (e) {
                    patches.undo();
                    console.error(e);
                }
            },
        }),
        removeCommentFile: builder.mutation<Comment, TaskCommentFilePathParams>({
            query: ({ taskId, commentId, fileId }) => ({
                url: `/task/${taskId}/comment/${commentId}/file/${fileId}`,
                method: 'DELETE',
            }),
            async onQueryStarted({ taskId, commentId, fileId }, { dispatch, queryFulfilled }) {
                const patches = dispatch(
                    taskApi.util.updateQueryData('getTask', { id: taskId }, (draft) => {
                        for (const channel of draft.task.chanels) {
                            if (!channel.comments) continue;

                            const comment = channel.comments.find(({ id }) => id === commentId);

                            if (comment) {
                                comment.files = comment.files.filter(({ id }) => id !== fileId);
                                return;
                            }
                        }
                    }),
                );

                try {
                    await queryFulfilled;
                } catch (e) {
                    patches.undo();
                    console.error(e);
                }
            },
        }),
        commentReaction: builder.mutation<{ reaction: TaskCommentReaction }, CommentReactionMutation>({
            query: ({ taskId, commentId, ...body }) => ({
                url: `/task/${taskId}/comment/${commentId}/reaction`,
                method: 'POST',
                body,
            }),
            async onQueryStarted({ taskId, commentId, reaction }, { dispatch, queryFulfilled, getState }) {
                const { data } = await queryFulfilled;
                const userId = selectMyUserId(getState() as any);

                try {
                    dispatch(
                        taskApi.util.updateQueryData('getTask', { id: taskId }, (draft) => {
                            let comment: any;

                            for (const chanel of draft.task.chanels) {
                                if (!chanel.comments) continue;

                                for (const currentComment of chanel.comments) {
                                    if (currentComment.id === commentId) {
                                        comment = currentComment;
                                        break;
                                    }
                                }
                            }

                            if (comment) {
                                const responseReaction: null | TaskCommentReaction['reaction'] = data.reaction as any;
                                if (responseReaction) {
                                    comment.reactions.push(responseReaction);
                                } else {
                                    comment.reactions = comment.reactions.filter(
                                        ({ reaction: react, authorId }: any) =>
                                            !(reaction === react && userId === authorId),
                                    );
                                }
                            }
                        }),
                    );
                } catch (e) {
                    console.error(e);
                }
            },
        }),
    }),
});

export const {
    useSendCommentMutation,
    useCommentReactionMutation,
    useEditCommentMutation,
    useAddCommentFileMutation,
    useRemoveCommentFileMutation,
    useDeleteCommentMutation,
} = commentApi;
