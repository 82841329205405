import * as React from 'react';
import * as lodash from 'lodash';

import * as style from './SerialNumbersList.scss';

import { Icon, IconType } from 'sber-marketing-ui';

interface Props {
    title: string;
    serialNumbers: number[];
    checkedSerialNumbers?: number[];
    onRemoveButtonClick?: (id: number) => void;
}

export function SerialNumbersList({
    title,
    serialNumbers,
    checkedSerialNumbers,
    onRemoveButtonClick,
}: Props): JSX.Element {
    if (lodash.isEmpty(serialNumbers)) {
        return null;
    }

    const itemsCanBeRevomed = !!onRemoveButtonClick;

    return (
        <div className={style.root}>
            <div className={style.caption}>{title}</div>

            <div className={style.serialNumbers}>
                <div className={style.serialNumbersWrapper}>
                    {serialNumbers.map((item) => (
                        <div className={style.serialNumber} key={item}>
                            <div className={style.title}>{item}</div>

                            {lodash.includes(checkedSerialNumbers, item) && (
                                <div className={style.checkIcon}>
                                    <Icon type={IconType.CHECK_ICON} svgSize={11} />
                                </div>
                            )}

                            {itemsCanBeRevomed && (
                                <div className={style.removeButton} onClick={() => onRemoveButtonClick(item)}>
                                    <Icon type={IconType.CROSS_BRIEF} svgSize={20} />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
