import * as React from 'react';

import { WithTitle } from '../WithTitle';

import * as style from './CommentWithTitle.scss';

export interface Props {
    title: string;
    text: string;
}

export const CommentWithTitle: React.FC<Props> = ({ title, text }) => (
    <WithTitle title={title}>
        <div
            className={style.text}
            {...{
                'qa-id': 'correctionCardRejectionComment',
            }}
        >
            {text}
        </div>
    </WithTitle>
);
