import * as React from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';

import { useState, StatisticsItem } from './useState';
import * as styles from './styles.scss';

export const Header: React.FC = () => {
    const { statisticsItems, selectedStatisticsItem, setSelectedStatisticsItemId } = useState();

    const data = buildData(selectedStatisticsItem, statisticsItems);

    return (
        <div className={styles.root}>
            {data.map(({ id, name }, index) => {
                const isLast = data.length === index + 1;
                const isFirst = index === 0;

                return (
                    <span key={id}>
                        {!isFirst ? '/ ' : ' '}
                        <div
                            className={classNames(styles.link, isLast ? styles.disabledLink : '')}
                            onClick={!isLast ? () => setSelectedStatisticsItemId(id) : () => {}}
                        >
                            {name}
                        </div>
                    </span>
                );
            })}
        </div>
    );
};

const buildData = (
    statisticsItem: StatisticsItem,
    statisticsItems: StatisticsItem[],
): { id: string; name: string }[] => {
    const hasParentId = !isNil(statisticsItem.meta.parentId);

    if (hasParentId) {
        return [
            ...buildData(
                statisticsItems.find(({ id }) => id === statisticsItem.meta.parentId),
                statisticsItems,
            ),
            {
                id: statisticsItem.id,
                name: statisticsItem.meta.name,
            },
        ];
    }

    return [
        {
            id: statisticsItem.id,
            name: statisticsItem.meta.name,
        },
    ];
};
