import { axios } from '../../lib/axios';

import { FileUploadParams, FileDownloadLinkParams } from 'sber-marketing-types/backend';
import { FileService } from './FileService';
import { FileApiUploadParams } from './types';

interface UploadedPart {
    etag: string;
    partNumber: number;
}

export class CephService extends FileService {
    public async getDownloadLink(params: FileApiUploadParams, settings: FileDownloadLinkParams): Promise<string> {
        const res = await axios.post('/api/object/ceph/download-link', {
            ...params,
            fileName: settings.fileName,
        });

        return res.data.link;
    }

    public async makeSharedLink(params: FileApiUploadParams, settings: FileDownloadLinkParams): Promise<string> {
        const res = await axios.post('/api/object/ceph/shared-link', {
            ...params,
            fileName: settings.fileName,
        });

        return res.data.link;
    }

    protected async whollyUpload(params: FileApiUploadParams, file: File, settings: FileUploadParams): Promise<void> {
        const data = {
            ...params,
            fileName: settings.fileName,
        };

        await this.sendFile('/api/object/ceph/upload-file', file, data);
    }

    protected async multipartUpload(
        params: FileApiUploadParams,
        chunks: Blob[],
        settings: FileUploadParams,
    ): Promise<void> {
        const uploadId = await this.initUpload(params, settings);
        const uploadedParts = await this.chunksUpload(params, chunks, settings, uploadId);
        await this.finishUpload(params, settings, uploadId, uploadedParts);
    }

    protected async *multipartUploadProcess(
        params: FileApiUploadParams,
        chunks: Blob[],
        settings: FileUploadParams,
    ): AsyncIterableIterator<number> {
        const uploadId = await this.initUpload(params, settings);
        yield 0;

        const uploadedParts: UploadedPart[] = [];
        const uploadProcess = this.chunksUploadProcess(params, chunks, settings, uploadId);
        for await (const [uploadedPart, progress] of uploadProcess) {
            uploadedParts.push(uploadedPart);
            yield progress;
        }

        await this.finishUpload(params, settings, uploadId, uploadedParts);
        yield 100;
    }

    private async initUpload(params: FileApiUploadParams, settings: FileUploadParams): Promise<string> {
        const res = await axios.post('/api/object/ceph/multipart-upload-init', {
            ...params,
            fileName: settings.fileName,
        });

        return res.data.uploadId;
    }

    private async chunksUpload(
        params: FileApiUploadParams,
        chunks: Blob[],
        settings: FileUploadParams,
        uploadId: string,
    ): Promise<UploadedPart[]> {
        const uploadedParts: UploadedPart[] = [];

        const uploadProcess = this.chunksUploadProcess(params, chunks, settings, uploadId);
        for await (const [uploadedPart] of uploadProcess) {
            uploadedParts.push(uploadedPart);
        }

        return uploadedParts;
    }

    private async *chunksUploadProcess(
        params: FileApiUploadParams,
        chunks: Blob[],
        settings: FileUploadParams,
        uploadId: string,
    ): AsyncIterableIterator<[UploadedPart, number]> {
        const commonData = {
            ...params,
            uploadId,
            containerName: settings.containerName,
            fileName: settings.fileName,
        };

        for (let partNumber = 1; partNumber <= chunks.length; partNumber++) {
            const data = { ...commonData, partNumber };
            const { etag } = await this.sendFile('/api/object/ceph/multipart-upload', chunks[partNumber - 1], data);
            const progress = (partNumber / chunks.length) * 100;
            yield [{ etag, partNumber }, progress];
        }
    }

    private async finishUpload(
        params: FileApiUploadParams,
        settings: FileUploadParams,
        uploadId: string,
        uploadedParts: UploadedPart[],
    ): Promise<void> {
        await axios.post('/api/object/ceph/multipart-upload-complete', {
            ...params,
            uploadId,
            uploaded: uploadedParts,
            fileName: settings.fileName,
        });
    }
}
