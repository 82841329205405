import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';
import * as style from './BudgetInfo.scss';

interface Props {
    infoBlockParamsList: InfoBlockParams[];
}

export interface InfoBlockParams {
    title: string;
    content: string;
    warning?: boolean;
    qaId?: string;
}

export const BudgetInfo: React.FC<Props> = ({ infoBlockParamsList }) => {
    return (
        <div
            className={classNames(style.root, style.blockWrapper, style.bottomBorder)}
            {...{
                'qa-id': 'budgetExecutionLineModalBudgetInfo',
            }}
        >
            {infoBlockParamsList.map((infoBlockParams) => (
                <div
                    key={infoBlockParams.title}
                    className={classNames(style.info, infoBlockParams.warning && style.attention)}
                    {...(infoBlockParams.qaId ? { 'qa-id': infoBlockParams.qaId } : {})}
                >
                    <div className={style.title}>{infoBlockParams.title}</div>
                    <div className={style.content}>
                        {infoBlockParams.content && `${infoBlockParams.content} тыс. руб.`}
                    </div>
                    {infoBlockParams.warning && (
                        <div
                            className={style.warning}
                            {...{
                                'qa-id': 'budgetExecutionLineModalBudgetInfoWarning',
                            }}
                        >
                            <Icon type={IconType.WARNING_IN_CIRCLE} />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};
