import { actionCreatorFactory } from 'typescript-fsa';

import { LoadPageDataPayload, PageValues, ValidationMode } from '../types';

const actionCreator = actionCreatorFactory('NEW_ACTIVITY_SYNC');

export const loadNewActivityPageData = actionCreator<LoadPageDataPayload>('LOAD_NEW_PROJECT_PAGE_DATA');
export const resetPageState = actionCreator('RESET_PAGE_STATE');
export const setActivityPrivacy = actionCreator<boolean>('SET_ACTIVITY_PRIVACY');
export const setValidationMode = actionCreator<ValidationMode>('SET_VALIDATION_MODE');
export const setValues = actionCreator<Partial<PageValues>>('SET_VALUES');
export const setBpmWasInit = actionCreator<boolean>('SET_BPM_WAS_INIT');
