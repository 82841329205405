import * as React from 'react';
import * as style from './CardTitle.scss';
import { WithTooltip } from 'sber-marketing-ui';

interface Props {
    tooltip?: string;
}

export const CardTitle: React.FC<Props> = ({ children, tooltip }) => (
    <div className={style.root}>
        <WithTooltip spanWrapper hidden={!tooltip} content={tooltip}>
            <React.Fragment>{children}</React.Fragment>
        </WithTooltip>
    </div>
);
