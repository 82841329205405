import * as React from 'react';
import classNames from 'classnames';

import { Flex, FlexProps } from '@common/components';

import * as styles from './Card.scss';

export type CardProps<E extends keyof JSX.IntrinsicElements = 'div'> = FlexProps<E>;

export function Card({ className, children, ...props }: CardProps) {
    return (
        <Flex {...props} className={classNames(styles.root, className)}>
            {children}
        </Flex>
    );
}
