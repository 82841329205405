import * as React from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { List } from 'react-virtualized';
import { Icon, IconType, CustomScrollbar_new as CustomScrollbar, ScrollValues } from 'sber-marketing-ui';
import { Tag as TagProps } from '@mrm/tags';

import { StoreState } from '@store';
import { getTagsState } from '@store/tags';
import {
    ComponentState,
    getInstance,
    setTagIdWithActiveEditor,
    addTagToSelected,
    setComponentState,
    setNewTagInputValue,
    shouldAddNewTagItem,
} from '@store/tagsEditor';

import { Utils } from '@common/Utils';

import { Tag } from '@modules/tags/Tag';
import { useDescriptor } from '@modules/tags/TagsPreviewAndEditor/Context';

import * as styles from './TagsPreview.scss';

const ITEM_HEIGHT = 32;
const MAX_ITEMS_WITH_NEW_TAG = 5;
const MAX_ITEMS_WITHOUT_NEW_TAG = 6;

const MAX_SCROLLBAR_HEIGHT_WITH_NEW_TAG = MAX_ITEMS_WITH_NEW_TAG * ITEM_HEIGHT;
const MAX_SCROLLBAR_HEIGHT_WITHOUT_NEW_TAG = MAX_ITEMS_WITHOUT_NEW_TAG * ITEM_HEIGHT;

interface Props {
    wrapper: React.RefObject<HTMLDivElement>;
    onAttachedTagsChange?: () => void;
    onRerender: (height: number) => void;
}

function useInteractivity(props: Props) {
    const dispatch = useDispatch();
    const { id, makeActionParams, makeDataUpdateActionParams } = useDescriptor();
    const { wrapper, onAttachedTagsChange } = props;

    const listRef = React.useRef<List>();
    const scrollbarRef = React.useRef<any>();
    const scrollableBodyRef = React.useMemo(() => document.getElementById('pageContent'), []);
    const width = React.useMemo(() => wrapper.current?.getBoundingClientRect().width, [wrapper.current]);

    const existingTags = useSelector((state: StoreState) => getTagsState(state).entities);

    const inputText = useSelector((state: StoreState) => getInstance(state, id).newTagInputValue);
    const pendingTag = useSelector((state: StoreState) => getInstance(state, id).tags.pending);
    const selectedTags = useSelector((state: StoreState) => getInstance(state, id).tags.selected);
    const inputTextRegex = new RegExp(Utils.escapeRegExp(inputText), 'i');

    const tagsToUse = existingTags.filter((tag) => {
        const selectedTagsMatch = selectedTags.includes(tag.id);

        if (selectedTagsMatch) {
            return false;
        }

        if (inputText) {
            const inputTextMatch = tag.title.match(inputTextRegex);

            return inputTextMatch;
        }

        return true;
    });
    const addNewTagItem = useSelector((state: StoreState) => shouldAddNewTagItem(state, id));

    function onPendingTagClick() {
        dispatch(setComponentState(makeActionParams(ComponentState.CreateNewTagPopup)));
    }
    function onExistingTagClick(tagId: string) {
        dispatch(addTagToSelected(makeDataUpdateActionParams(tagId)));
        dispatch(setNewTagInputValue(makeActionParams('')));
        onAttachedTagsChange?.();
    }

    function onScroll(scrollValues: ScrollValues, prevValues: ScrollValues) {
        const valuesHaveChanged = scrollValues.scrollTop !== prevValues.scrollTop;

        if (valuesHaveChanged && listRef.current && scrollbarRef.current) {
            const { scrollTop, scrollLeft } = scrollValues;

            listRef.current.Grid.handleScrollEvent({
                scrollTop,
                scrollLeft,
            });

            scrollbarRef.current.scrollbar.scrollTo(scrollTop, scrollLeft);
        }
    }

    return {
        width,
        listRef,
        scrollbarRef,
        scrollableBodyRef,
        tagsToUse,
        pendingTag,
        addNewTagItem,
        onPendingTagClick,
        onExistingTagClick,
        onScroll,
    };
}

function getListHeight(tagsToUse: number, addNewTag: boolean): number {
    const threshold = addNewTag ? MAX_ITEMS_WITH_NEW_TAG : MAX_ITEMS_WITHOUT_NEW_TAG;

    return Math.min(tagsToUse, threshold) * ITEM_HEIGHT;
}

export function TagsPreview(props: Props): JSX.Element {
    const {
        width,
        listRef,
        scrollbarRef,
        scrollableBodyRef,
        tagsToUse,
        pendingTag,
        addNewTagItem,
        onPendingTagClick,
        onExistingTagClick,
        onScroll,
    } = useInteractivity(props);

    React.useEffect(() => {
        const heightToUse = getListHeight(tagsToUse.length, addNewTagItem) + (addNewTagItem ? 32 : 0) + 42;

        props.onRerender(heightToUse);
    });

    const scrollbarMaxHeight = addNewTagItem ? MAX_SCROLLBAR_HEIGHT_WITH_NEW_TAG : MAX_SCROLLBAR_HEIGHT_WITHOUT_NEW_TAG;

    return (
        <div
            className={styles.root}
            style={{ width: `${width}px` }}
            {...{
                'qa-id': 'tagsPreviewAndEditorTagsPreview',
            }}
        >
            <div className={styles.title}>Выберите тег или создайте новый</div>

            {addNewTagItem && (
                <TagItem
                    pretext="Создать тег"
                    scrollableBodyRef={scrollableBodyRef}
                    onClick={onPendingTagClick}
                    {...pendingTag}
                />
            )}

            <CustomScrollbar
                ref={scrollbarRef}
                maxHeight={scrollbarMaxHeight}
                freezeScrollX
                hideScrollX
                onScroll={onScroll}
            >
                <List
                    ref={listRef}
                    style={{
                        overflowX: 'visible',
                        overflowY: 'visible',
                        outline: 'none',
                    }}
                    width={width}
                    height={getListHeight(tagsToUse.length, addNewTagItem)}
                    rowCount={tagsToUse.length}
                    rowHeight={ITEM_HEIGHT}
                    rowRenderer={(listProps) => {
                        const tag = tagsToUse[listProps.index];

                        return (
                            <TagItem
                                key={tag.id}
                                style={listProps.style}
                                scrollableBodyRef={scrollableBodyRef}
                                onClick={onExistingTagClick}
                                {...tag}
                            />
                        );
                    }}
                />
            </CustomScrollbar>
        </div>
    );
}

interface TagItemProps extends TagProps {
    style?: React.CSSProperties;
    pretext?: string;
    scrollableBodyRef: HTMLElement;
    onClick: (id: string) => void;
}

function TagItem({ style, pretext, scrollableBodyRef, onClick, ...tag }: TagItemProps): JSX.Element {
    const dispatch = useDispatch();
    const { makeActionParams } = useDescriptor();

    function onEditButtonClick(event: React.MouseEvent<any>) {
        event.preventDefault();
        event.stopPropagation();

        // scrollableBodyRef?.scroll?.(0, scrollableBodyRef?.scrollHeight);
        // document.getElementById('pageContent').scroll(0, 800)

        dispatch(setTagIdWithActiveEditor(makeActionParams(tag.id)));
        dispatch(setComponentState(makeActionParams(ComponentState.TagEditorOpened)));
    }

    return (
        <React.Fragment>
            <div
                className={styles.tagItem}
                onClick={onClick ? () => onClick(tag.id) : null}
                style={style as any}
                {...{
                    'qa-id': 'tagsPreviewAndEditorTagItem',
                }}
            >
                <div className={classnames(styles.tagWrapper, pretext && styles.tagWrapperWithPretext)}>
                    {pretext && <span className={styles.tagItemPretext}>{pretext}&nbsp;</span>}

                    <span className={classnames(pretext && styles.tagWithPretext)}>
                        <Tag {...tag} />
                    </span>
                </div>

                <div
                    className={styles.editTagButton}
                    {...{
                        'qa-id': 'tagsPreviewAndEditorTagItemEditBUtton',
                    }}
                >
                    <Icon type={IconType.PROJECT_STAGES_EDIT_ICON} svgSize={24} onClick={onEditButtonClick} />
                </div>
            </div>
        </React.Fragment>
    );
}
