import { descending } from 'd3';
import { HierarchyNode, stratify, tree } from 'd3-hierarchy';
import { Node, Edge, XYPosition } from 'react-flow-renderer';
import { Tag } from '@mrm/tags';

import { ContentSize } from './Graph';

const FULL_CIRCLE = Math.PI * 2;
const MAX_DEPTH = 6;

function getPosition(angle: number, radius: number): XYPosition {
    return {
        x: radius * Math.cos(angle),
        y: radius * Math.sin(angle),
    };
}

interface Props {
    tags: Tag[];
    nodes: Node[];
    edges: Edge[];
    contentSize: ContentSize;
}

function getHierarchyDepth(node: HierarchyNode<Edge>): number {
    if (!node.children) {
        return 1;
    }

    return Math.max(...node.children.map((childNode) => getHierarchyDepth(childNode))) + 1;
}

export function injectIntoNodes({ tags, nodes, edges, contentSize }: Props): Node[] {
    const items: Edge[] = [
        ...tags.map((tag) => ({
            id: tag.id,
            target: tag.id,
            source: null,
        })),
        ...edges,
    ];

    const defaultHierarchy = stratify<Edge>()
        .id((edge) => edge.target)
        .parentId((edge) => edge.source)(items);
    defaultHierarchy.sort((a, b) => descending(a.height, b.height));

    const depth = getHierarchyDepth(defaultHierarchy);
    const radius = Math.max(contentSize.width, contentSize.height) / (MAX_DEPTH - depth);

    const treeHierarchy = tree()
        .size([FULL_CIRCLE, radius])
        .separation((a, b) => (a.parent == b.parent ? 1 : 2) / a.depth)(defaultHierarchy);

    return nodes.map((node) => {
        const nodeInHierarchy = treeHierarchy.find((hierarchyNode) => node.id === hierarchyNode.id);

        const angle = nodeInHierarchy?.x || 0;
        const radius = nodeInHierarchy?.y || 0;

        return { ...node, position: getPosition(angle, radius) };
    });
}
