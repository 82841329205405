import * as React from 'react';
import * as lodash from 'lodash';
import {
    FilterMenu as FilterMenuSelector,
    FilterMenuBorderType,
    Preloader,
    Icon,
    IconType,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
} from 'sber-marketing-ui';
import { DictionaryType, PlainDictionary } from '@mrm/dictionary';
import { Tag } from '@mrm/tags';

import { ColumnName, ColumnsNameWithCodes, FilterKey } from '@store/budgetExecution';

import { Filters, User } from './types';
import { DatesFormatter, Money, MoneyFormatter, Utils } from '@common/Utils';

import * as styles from './styles.scss';

interface Props {
    title: string;
    dictionaryType: DictionaryType;
    columnName: FilterKey;
    users: User[];
    dictionaries: Record<string, PlainDictionary>;
    tags: lodash.Dictionary<Tag>;
    filters: Filters;
    currencySearch: boolean;
    filterNamesAreNumeral: boolean;
    isLoading: boolean;
    isExpanded: boolean;
    selectedFilters: React.ReactText[];
    showApplyFiltersButton: boolean;
    onFiltersChange: (selectedFiltersIds: React.ReactText[]) => void;
    onExpandChange: (isExpanded: boolean) => void;
    onApplyFiltersButtonClick: () => void;
}

export const ItemTemplate: React.FC<Props> = ({
    title,
    dictionaryType,
    columnName,
    users,
    dictionaries,
    filters,
    currencySearch,
    isLoading,
    isExpanded,
    selectedFilters,
    filterNamesAreNumeral,
    showApplyFiltersButton,
    tags,
    onFiltersChange,
    onExpandChange,
    onApplyFiltersButtonClick,
}) => {
    const filterNames = lodash.keys(filters);

    let sortedFilterNames = filterNamesAreNumeral ? sortStringsAsNumbers(filterNames) : filterNames.sort();

    const nullElement = lodash.find(
        sortedFilterNames,
        (item) => item === 'null' || item === '' || item === 'undefined',
    );

    if (nullElement !== undefined) {
        sortedFilterNames = lodash.without(sortedFilterNames, nullElement);

        sortedFilterNames.push(nullElement);
    }

    return (
        <div className={styles.root}>
            {isLoading && <Preloader />}

            <FilterMenuSelector
                filterTitle={title}
                items={sortedFilterNames
                    .map((item, index) => ({
                        id: item,
                        title: getItemTitle(
                            item,
                            currencySearch,
                            dictionaryType,
                            columnName,
                            users,
                            dictionaries,
                            tags,
                        ),
                        order: filterNamesAreNumeral ? index : undefined,
                    }))
                    .filter((item) => !!item.title)}
                checkedItemsIds={selectedFilters}
                border={FilterMenuBorderType.ALL}
                onItemSelection={onFiltersChange}
                currencySearch={currencySearch}
                isExpanded={isExpanded}
                onExpandChange={onExpandChange}
                preserveAllSelectedState
            />

            {showApplyFiltersButton && (
                <React.Fragment>
                    <div className={styles.applyFiltersButtonWrapper}>
                        <Button theme={ButtonTheme.GhostRounded} onClick={onApplyFiltersButtonClick}>
                            <div className={styles.applyFiltersButtonContent}>
                                <Icon
                                    type={IconType.PLAY_FILLED}
                                    className={styles.applyFilterButtonIcon}
                                    svgSize={10}
                                />
                                Применить
                            </div>
                        </Button>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

function sortStringsAsNumbers(filterNames: string[]): string[] {
    return filterNames.sort(
        (itemA: string, itemB: string) =>
            parseInt(itemA.replace(/\s/g, ''), 10) - parseInt(itemB.replace(/\s/g, ''), 10),
    );
}

function getItemTitle(
    item: string,
    currencySearch: boolean,
    dictionaryType: DictionaryType,
    columnName: FilterKey,
    users: User[],
    dictionaries: Record<string, PlainDictionary>,
    tags: lodash.Dictionary<Tag>,
): string {
    if (item === 'null' || item === '' || item === 'undefined') {
        return 'Значение отсутствует';
    }

    if (columnName === ColumnName.Responsible) {
        const userId = Number(item);
        const user = users.find((user) => user.id === userId);

        return formatUser(user);
    }

    if (columnName === ColumnName.StartDate || columnName === ColumnName.EndDate) {
        return DatesFormatter.ddMonthyyyy(item);
    }

    if (columnName === ColumnName.Author) {
        const user = users.find((user) => user.id === Number(item));

        return formatUser(user);
    }

    if (columnName === ColumnName.Tags) {
        return tags[item].title;
    }

    if (dictionaryType && !ColumnsNameWithCodes.includes(columnName)) {
        const currentDict = dictionaries[item];

        return Utils.getDictionaryValue(currentDict);
    }

    if (currencySearch) {
        return MoneyFormatter.toThousands(Money.fromStringCopecks(item), { hideCaption: true });
    }

    return item;
}

function formatUser(user: User): string {
    return user ? `${user.secondName} ${user.firstName}` : 'Пользователь не найден';
}
