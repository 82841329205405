import * as React from 'react';

import * as style from './MyTasksPage.scss';

import { MyTasksSortingMenu } from '@common/DashboardSortingMenu';
import { CardsViewSwitch } from '@common/CardsViewSwitch';
import { TasksList } from '@common/TasksList';
import { TasksListType } from '@store/tasksList';

export interface MyTasksPageProps {
    taskIdWithSidebar: string;
    reloadTaskFilters: () => void;
    onBeforeTaskDeleted: (taskId: string) => void;
    openTaskSidebar: (taskId: string) => void;
}

export const MyTasksPage: React.FC<MyTasksPageProps> = ({
    taskIdWithSidebar,
    reloadTaskFilters,
    onBeforeTaskDeleted,
    openTaskSidebar,
}: MyTasksPageProps): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.topLine}>
                <div className={style.sortingMenu}>
                    <MyTasksSortingMenu />
                </div>

                <div className={style.cardsViewSwitch}>
                    <CardsViewSwitch />
                </div>
            </div>

            <div className={style.activityListWrapper}>
                <TasksList
                    type={TasksListType.MY_TASKS}
                    taskIdWithSidebar={taskIdWithSidebar}
                    reloadTaskFilters={reloadTaskFilters}
                    onBeforeTaskDeleted={onBeforeTaskDeleted}
                    openTaskSidebar={openTaskSidebar}
                />
            </div>
        </div>
    );
};
