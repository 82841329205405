import * as React from 'react';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';
import autobind from 'autobind-decorator';
import * as moment from 'moment';

import { ActivityDates } from './ActivityDates';
import type { StoreState } from '@store';
import {
    setActivityPreparationDate,
    setActivityRealizationStart,
    setActivityRealizationEnd,
    setActivityDebriefingDate,
} from '@store/brief/actions';
import { getBriefState } from '@store/brief/selectors';

interface Props extends Partial<MapProps & DispatchProps> {}

interface MapProps {
    preparationDate: moment.Moment;
    realizationStart: moment.Moment;
    realizationEnd: moment.Moment;
    debriefingDate: moment.Moment;
}

interface DispatchProps {
    setActivityPreparationDate: (date: moment.Moment) => void;
    setActivityRealizationStart: (date: moment.Moment) => void;
    setActivityRealizationEnd: (date: moment.Moment) => void;
    setActivityDebriefingDate: (date: moment.Moment) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class ActivityDatesContainer extends React.Component<Props> {
    public render() {
        const { preparationDate, realizationStart, realizationEnd, debriefingDate } = this.props;

        return React.createElement(ActivityDates, {
            realizationStart,
            realizationEnd,
            preparationDate,
            debriefingDate,
            editRight: true,
            onRealizationStartChange: this.onRealizationStartChange,
            onRealizationEndChange: this.onRealizationEndChange,
            onPreparationDateChange: this.onPreparationDateChange,
            onDebriefingDateChange: this.onDebriefingDateChange,
        });
    }

    @autobind
    private onRealizationStartChange(value: moment.Moment) {
        this.props.setActivityRealizationStart(value);
    }

    @autobind
    private onRealizationEndChange(value: moment.Moment) {
        this.props.setActivityRealizationEnd(value);
    }

    @autobind
    private onPreparationDateChange(value: moment.Moment) {
        this.props.setActivityPreparationDate(value);
    }

    @autobind
    private onDebriefingDateChange(value: moment.Moment) {
        this.props.setActivityDebriefingDate(value);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { changedActivity } = getBriefState(state);
    const { preparationDate, realizationStart, realizationEnd, debriefingDate } = changedActivity;

    return {
        preparationDate,
        realizationStart,
        realizationEnd,
        debriefingDate,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setActivityPreparationDate,
            setActivityRealizationStart,
            setActivityRealizationEnd,
            setActivityDebriefingDate,
        },
        dispatch,
    );
}
