import * as React from 'react';
import * as lodash from 'lodash';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';
import { BudgetItem } from '@mrm/budget';

import { StoreState } from '@store';
import { getBudgetExecutionPageState, setPageBudgetItems } from '@store/budgetExecution';
import { getBudgetTransferMenuState } from '@store/budgetExecution/budgetTransferMenu';

interface Props extends Partial<MapProps & DispatchProps> {}

interface MapProps {
    pageBudgetItems: BudgetItem[];
    filtersBudgetItems: BudgetItem[];
    budgetItemsToIgnoreFilters: BudgetItem[];
    budgetTransferMenuBudgetItems: BudgetItem[];
}

interface DispatchProps {
    setPageBudgetItems: (pageBudgetItems: BudgetItem[]) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class PageBudgetItemsEffect extends React.PureComponent<Props> {
    public componentDidMount(): void {
        this.updateBudgetItems();
    }

    public componentDidUpdate(prevProps: Props): void {
        this.updateBudgetItems(prevProps);
    }

    public render(): JSX.Element {
        // component used only for side-effect
        return null;
    }

    private updateBudgetItems(prevProps?: Props) {
        const { filtersBudgetItems, budgetItemsToIgnoreFilters, budgetTransferMenuBudgetItems } = this.props;

        const updPageBudgetItems = lodash.uniqBy(
            lodash.sortBy(
                [...filtersBudgetItems, ...budgetItemsToIgnoreFilters, ...budgetTransferMenuBudgetItems],
                this.budgetItemId,
            ),
            this.budgetItemId,
        );

        const shouldUpdateStore = prevProps ? !lodash.isEqual(updPageBudgetItems, prevProps.pageBudgetItems) : true;

        if (shouldUpdateStore) {
            this.props.setPageBudgetItems(updPageBudgetItems);
        }
    }

    private budgetItemId(budgetItem: BudgetItem) {
        return budgetItem.id;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const {
        pageData: { budgetItems: filtersBudgetItems, budgetItemsToIgnoreFilters },
        computedData: { pageBudgetItems },
    } = getBudgetExecutionPageState(state);
    const {
        data: { budgetItems: budgetTransferMenuBudgetItems },
    } = getBudgetTransferMenuState(state);

    return {
        pageBudgetItems,
        filtersBudgetItems,
        budgetItemsToIgnoreFilters,
        budgetTransferMenuBudgetItems,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        setPageBudgetItems: (pageBudgetItems: BudgetItem[]) => dispatch(setPageBudgetItems(pageBudgetItems)),
    };
}
