import { axios } from '../lib/axios';
import { DivisionParams, GetDivisionListParams } from 'sber-marketing-types/frontend';

export interface DivisionsTreeNode extends DivisionParams {
    childDivisions: any[];
}

export class DivisionApi {
    public static async getDivisions(params: GetDivisionListParams = {}): Promise<DivisionParams[]> {
        const res = await axios.post('/api/division/list', params);
        return res.data.divisions;
    }

    public static async getAllDivisions(): Promise<DivisionParams[]> {
        const res = await axios.get('/api/division/list-all');
        return res.data.divisions;
    }
}
