import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { StoreState } from '@store';
import { resetTexts, getTextsLoadingStatus, LoadingStatus } from '@store/autopilot/generationTextForm';
import * as styles from './styles.scss';

export const ChangeButton: React.FC = () => {
    const dispatch = useDispatch();

    const textLoadingStatus = useSelector((state: StoreState) => getTextsLoadingStatus(state));

    const onClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(() => {
        dispatch(resetTexts());
    }, []);

    const disabled = textLoadingStatus === LoadingStatus.LOADING;

    return (
        <div
            className={classNames(styles.root, disabled ? styles.disabled : '')}
            onClick={disabled ? () => {} : onClick}
        >
            Изменить параметры
        </div>
    );
};
