import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { StoreState } from '@store';
import { getDashboardPageState, loadPageAsync } from '@store/leaderDashboardPage';
import type { LoadPagePayload, LoadPageDone } from '../types';
import { utilsByMode } from '@store/leaderDashboardPage/utils';

import { DepartmentApi, LeaderDashboardApi, UserApi } from '@api';

export const loadPage = bindThunkAction<StoreState, LoadPagePayload, LoadPageDone, Error>(
    loadPageAsync,
    async ({ mode }, __, getState) => {
        const { selectedBy, selectedDate, selectedPeriod } = getDashboardPageState(getState());

        let utils;
        let dataResponse;
        let userIdsFromStatistics;

        if (mode === 'activities') {
            utils = utilsByMode['activities'];

            dataResponse = await LeaderDashboardApi.getActivitiesData({
                period: selectedPeriod,
                by: selectedBy,
                currentDate: selectedDate,
            });

            userIdsFromStatistics = utils.getUsersIdsFromStatistics(dataResponse);
        } else {
            utils = utilsByMode['tasks'];

            dataResponse = await LeaderDashboardApi.getTasksData({
                period: selectedPeriod,
                by: selectedBy,
                currentDate: selectedDate,
            });

            userIdsFromStatistics = utils.getUsersIdsFromStatistics(dataResponse);
        }

        const [users, departments] = await Promise.all([
            await UserApi.getUserList(userIdsFromStatistics),
            await DepartmentApi.getList(),
        ]);

        return {
            pageData: {
                users,
                departments: utils.filterDepartmentResponseByIds(departments, utils.getDepartmentsIdsFromUsers(users)),
                statistics: dataResponse,
            },
        };
    },
);
