import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { StoreState } from '@store';
import {
    getDashboardPageState,
    setFilterByAsync,
    SetFilterByPayload,
    SetFilterByResult,
} from '@store/leaderDashboardPage';
import { utilsByMode } from '@store/leaderDashboardPage/utils';

import { DepartmentApi, LeaderDashboardApi, UserApi } from '@api';

export const setFilterBy = bindThunkAction<StoreState, SetFilterByPayload, SetFilterByResult, Error>(
    setFilterByAsync,
    async ({ by, mode }, _, getState) => {
        const { selectedPeriod, selectedDate } = getDashboardPageState(getState());

        let utils;
        let statisticsDataResponse;
        let userIdsFromStatistics;

        if (mode === 'activities') {
            utils = utilsByMode['activities'];

            statisticsDataResponse = await LeaderDashboardApi.getActivitiesData({
                period: selectedPeriod,
                currentDate: selectedDate,
                by,
            });

            userIdsFromStatistics = utils.getUsersIdsFromStatistics(statisticsDataResponse);
        } else {
            utils = utilsByMode['tasks'];

            statisticsDataResponse = await LeaderDashboardApi.getTasksData({
                period: selectedPeriod,
                currentDate: selectedDate,
                by,
            });

            userIdsFromStatistics = utils.getUsersIdsFromStatistics(statisticsDataResponse);
        }

        const [users, departments] = await Promise.all([
            await UserApi.getUserList(userIdsFromStatistics),
            await DepartmentApi.getList(),
        ]);

        return {
            by,
            mode,
            data: {
                users,
                departments: utils.filterDepartmentResponseByIds(departments, utils.getDepartmentsIdsFromUsers(users)),
                statistics: statisticsDataResponse,
            },
        };
    },
);
