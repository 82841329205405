import { BudgetStatus, BudgetListQueryParams } from '@mrm/budget';
import { memoize } from 'lodash';

import { BudgetApi } from './BudgetApi';
import { BudgetItemApi } from './BudgetItemApi';

export class Queries {
    private static getBudgetList = memoize(BudgetApi.getBudgetList, (params: BudgetListQueryParams) => params.status);
    private static getBudgetItem = memoize(BudgetItemApi.getBudgetItem);

    public static async userHasBudgetItemReadPermission(budgetId: string, budgetItemId: string): Promise<boolean> {
        let result = false;

        const availableBudgets = await Queries.getBudgetList({
            status: BudgetStatus.Execution,
        });

        if (availableBudgets.some((budget) => budget.id === budgetId)) {
            try {
                const budgetItem = await Queries.getBudgetItem(budgetItemId, true);

                result = budgetItem.actions.canEdit;
            } catch (e) {}
        }

        return result;
    }
}
