// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilotDOOH-StageWidgets-MediaplanSettingsStageWidget-RegionsTable-_RegionsTable__root{display:block}.src-client-modules-activity-pages-autopilotDOOH-StageWidgets-MediaplanSettingsStageWidget-RegionsTable-_RegionsTable__table{margin-top:6px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilotDOOH/StageWidgets/MediaplanSettingsStageWidget/RegionsTable/RegionsTable.scss"],"names":[],"mappings":"AAAA,4HACI,aAAc,CACjB,6HAGG,cAAe","sourcesContent":[".root {\n    display: block;\n}\n\n.table {\n    margin-top: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilotDOOH-StageWidgets-MediaplanSettingsStageWidget-RegionsTable-_RegionsTable__root",
	"table": "src-client-modules-activity-pages-autopilotDOOH-StageWidgets-MediaplanSettingsStageWidget-RegionsTable-_RegionsTable__table"
};
export default ___CSS_LOADER_EXPORT___;
