import * as React from 'react';
import autobind from 'autobind-decorator';

import type { CreativeRequestParamDomain, Dictionary } from './types';
import { ParamListTemplate } from './ParamListTemplate';

interface Props {
    loading: boolean;
    version: number;
    creativeRequestParamDomains: CreativeRequestParamDomain[];
    dictionaries: Dictionary[];
    canAddParams: boolean;
    onAddParam: () => Promise<void>;
}

export class ParamListBehavior extends React.Component<Props> {
    public render(): JSX.Element {
        const { loading, version, creativeRequestParamDomains, dictionaries, canAddParams } = this.props;

        return React.createElement(ParamListTemplate, {
            creativeRequestParamDomains,
            dictionaries,
            loading,
            version,
            displayAddButton: canAddParams,
            onAddButtonClick: this.onAddButtonClick,
        });
    }

    @autobind
    public async onAddButtonClick(): Promise<void> {
        await this.props.onAddParam();
    }
}
