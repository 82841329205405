import type { DataProps } from 'react-apollo';
import type { BudgetItem } from '@mrm/budget';
import { Month } from '@mrm/budget';
import { CorrectionType, CorrectionStatus } from '@mrm/budget';
import type { PlainDictionary } from '@mrm/dictionary';
import { DictionaryType } from '@mrm/dictionary';
import type { FundsDTO as Funds } from '@mrm/budget/Funds';
import type { UserResponseParams } from 'sber-marketing-types/backend';
import type { UnsavedChange } from '@store/budgetCorrections/types';
import { RESERVED_COLUMN_NAMES, PLANNED_COLUMN_NAMES, FACT_COLUMN_NAMES, ColumnName } from '@store/budgetExecution';

interface Data {
    corrections: {
        nodes: Correction[];
    };
    allCorrections: {
        nodes: {
            id: string;
        }[];
    };
}

interface Values {
    filter: {};
    limit: number;
}

export interface RouterQueryFiltersValues {
    executorId: number | null;
}

export type IncomeExternalPlanFundsTransferCorrection = Correction<CorrectionType.IncomeExternalPlanFundsTransfer>;
export type OutcomeExternalPlanFundsTransferCorrection = Correction<CorrectionType.OutcomeExternalPlanFundsTransfer>;
export type ReservedFundsCorrection = Correction<CorrectionType.ReservedFunds>;
export type PlanFundsTransferCorrection = Correction<CorrectionType.PlanFundsTransfer>;
export type FactFundsCorrection = Correction<CorrectionType.FactFunds>;
export type BudgetItemCorrection = Correction<CorrectionType.BudgetItem>;
export type ActivityCorrection = Correction<CorrectionType.Activity>;

export interface Correction<TCorrectionType extends CorrectionType = any> {
    id: string;
    type: CorrectionType;
    status: CorrectionStatus;
    serialNumber: number;
    creationTime: Date;
    comment: string;
    author: Persone;
    expert: Persone;
    approver: Persone;
    overwrittenSerialNumber: string;
    rejectedSerialNumber: string;
    params: Params<TCorrectionType>;
    activityCurrent: {
        id: number;
        name: string;
    }[];
    activityBefore: {
        id: number;
        name: string;
    }[];
    budgetItemCurrent: BudgetItem[];
    budgetItemBefore: BudgetItem[];
}

type Params<TCorrectionType extends CorrectionType> = CorrectionParamsTypes[TCorrectionType];

type CorrectionParamsTypes = {
    [CorrectionType.Activity]: {
        name: string;
    };
    [CorrectionType.BudgetItem]: {
        businessTarget: string;
        customerName: string;
        comment: string;
        expertComment: string;
        sapComment: string;
        sapNumber: string;
        sapZns: string;
        dictionaryIds: string[];
        responsibleIds: number[];
        realizationEnd: Date;
        realizationStart: Date;
        previousFunds: number;
    };
    [CorrectionType.FactFunds]: Funds;
    [CorrectionType.ReservedFunds]: Funds;
    [CorrectionType.PlanFundsTransfer]: {
        value: number;
        donorId: string;
        acceptorId: string;
        acceptorMonth: Month;
        donorMonth: Month;
    };
    [CorrectionType.IncomeExternalPlanFundsTransfer]: {
        value: number;
        acceptorMonth: Month;
        acceptorId: string;
        dictionary: {
            item: {
                id: string;
            };
        };
        comment: string;
    };
    [CorrectionType.OutcomeExternalPlanFundsTransfer]: {
        value: number;
        donorMonth: Month;
        donorId: string;
        dictionary: {
            item: {
                id: string;
            };
        };
        comment: string;
    };
};

export interface Persone {
    id: number;
    firstName: string;
    secondName: string;
}

export type QueryProps = DataProps<Data, Values>;

export {
    CorrectionType,
    CorrectionStatus,
    Month,
    DictionaryType,
    ColumnName,
    PLANNED_COLUMN_NAMES,
    RESERVED_COLUMN_NAMES,
    FACT_COLUMN_NAMES,
};

export type { BudgetItem, Funds, PlainDictionary as Dictionary, UserResponseParams as User, UnsavedChange };
