import * as React from 'react';
import onClickOutside from 'react-onclickoutside';
import autobind from 'autobind-decorator';

import { CommentEditDropdownMenu } from './CommentEditDropdownMenu';

interface Props {
    className?: string;
    hideItem?: 'edit' | 'remove';
    onExpandClick: () => void;
    onEditClick: () => void;
    onRemoveClick: () => void;
}

@(onClickOutside as any)
export class CommentEditDropdownMenuContainer extends React.PureComponent<Props> {
    public render() {
        const { className, hideItem, onEditClick, onRemoveClick } = this.props;

        return React.createElement(CommentEditDropdownMenu, {
            className,
            hideItem,
            onEditClick,
            onRemoveClick,
        });
    }

    @autobind
    public handleClickOutside() {
        this.props.onExpandClick();
    }
}
