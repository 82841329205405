import * as moment from 'moment';
import { Month } from '@mrm/budget';

type Moment = moment.Moment;
type DateRepr = number | string | Date | Moment;

interface Params {
    withTodayMarker?: boolean;
}

const TODAY_MARKER = 'Сегодня';

export class DatesFormatter {
    public static months = moment.months();

    public static monthsShort: string[] = moment.monthsShort().map((month) => {
        const rest = month.slice(1, month.endsWith('.') ? month.length - 1 : month.length);
        return month.charAt(0).toUpperCase() + rest;
    });

    public static monthsCapitalized: string[] = DatesFormatter.months.map(
        (month) => `${month.slice(0, 1).toUpperCase()}${month.slice(1)}`,
    );

    public static MonthNames: Record<Month, string> = {
        [Month.Jan]: DatesFormatter.months[0],
        [Month.Feb]: DatesFormatter.months[1],
        [Month.Mar]: DatesFormatter.months[2],
        [Month.Apr]: DatesFormatter.months[3],
        [Month.May]: DatesFormatter.months[4],
        [Month.Jun]: DatesFormatter.months[5],
        [Month.Jul]: DatesFormatter.months[6],
        [Month.Aug]: DatesFormatter.months[7],
        [Month.Sept]: DatesFormatter.months[8],
        [Month.Oct]: DatesFormatter.months[9],
        [Month.Nov]: DatesFormatter.months[10],
        [Month.Dec]: DatesFormatter.months[11],
    };

    public static MonthOrder: Month[] = [
        Month.Jan,
        Month.Feb,
        Month.Mar,
        Month.Apr,
        Month.May,
        Month.Jun,
        Month.Jul,
        Month.Aug,
        Month.Sept,
        Month.Oct,
        Month.Nov,
        Month.Dec,
    ];

    public static hhmm(date: DateRepr, separator?: string): string {
        const separatorToUse = separator || ':';

        return this.withEmptyCheck(date, (date) => {
            const hours = `${date.getHours()}`.padStart(2, '0');
            const minutes = `${date.getMinutes()}`.padStart(2, '0');

            return `${hours}${separatorToUse}${minutes}`;
        });
    }

    public static yymmdd(date: DateRepr, params?: Params): string {
        return this.withEmptyCheck(date, (date) =>
            this.withTodayCheck(date, params, () => {
                const dd = date.getDate();
                const mm = date.getMonth();
                const yy = date.getFullYear() % 100;

                return `${yy} ${mm} ${dd}`;
            }),
        );
    }

    public static ddMonthyy(date: DateRepr, params?: Params): string {
        return this.withEmptyCheck(date, (date) =>
            this.withTodayCheck(date, params, () => {
                const dd = date.getDate();
                const mm = this.monthsShort[date.getMonth()];
                const yy = date.getFullYear() % 100;

                return `${dd} ${mm} ${yy}`;
            }),
        );
    }

    public static ddMonth(date: DateRepr, params?: Params): string {
        return this.withEmptyCheck(date, (date) =>
            this.withTodayCheck(date, params, () => `${date.getDate()} ${this.months[date.getMonth()]}`),
        );
    }

    public static ddMonthyyyy(date: DateRepr, params?: Params): string {
        return this.withEmptyCheck(date, (date) =>
            this.withTodayCheck(
                date,
                params,
                () => `${date.getDate()} ${this.months[date.getMonth()]} ${date.getFullYear()}`,
            ),
        );
    }

    private static toObj(date: DateRepr): Date {
        if (moment.isMoment(date)) {
            return date.toDate();
        }

        if (typeof date === 'string' || typeof date === 'number') {
            return new Date(date);
        }

        return date;
    }

    private static isToday(date: Date): boolean {
        const today = new Date();

        return (
            date.getFullYear() === today.getFullYear() &&
            date.getMonth() === today.getMonth() &&
            date.getDate() === today.getDate()
        );
    }

    private static withEmptyCheck(date: DateRepr, worker: (date: Date) => string): string {
        if (date) {
            const obj = this.toObj(date);

            return worker(obj);
        }

        return '';
    }

    private static withTodayCheck(date: Date, params: Params, worker: () => string): string {
        return params?.withTodayMarker && this.isToday(date) ? TODAY_MARKER : worker();
    }
}
