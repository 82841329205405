import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as lodash from 'lodash';
import { UserResponseParams } from 'sber-marketing-types/frontend';

import { StoreState } from '@store';
import {
    getBudgetPlanningPageState,
    ColumnName,
    LoadFiltersPaylaod,
    Filters,
    ColumnFilters,
    LoadingStatus,
    GroupedDictionaries,
} from '@store/budgetPlanning';
import * as actions from '@store/budgetPlanning';
import * as thunk from '@store/budgetPlanning/thunks';
import { DictionaryType } from '@mrm/dictionary/Dictionary';
import { ColumnsList } from '../../../../../ColumnsConfig';

interface Props {
    columnName: ColumnName;
    children: (params: RenderParams) => React.ReactElement;
}

interface RenderParams {
    filterLoadingStatus: LoadingStatus;
    filters: ColumnFilters;
    users: UserResponseParams[];
    dictionaryType: DictionaryType;
    dictionaries: GroupedDictionaries;
    loadFiltersByColumn: () => void;
    loadFilters: (payload: LoadFiltersPaylaod) => void;
    setFilters: (filters: Filters) => void;
}

export const WithStore: React.FC<Props> = ({ columnName, children }) => {
    const dispatch = useDispatch();

    const { dictionaryType, users, userDictionaries, filters, loadingStatues } = useSelector((state: StoreState) => {
        const {
            pageData: { allUsers: users, userDictionaries },
            columnFilters,
        } = getBudgetPlanningPageState(state);
        const column = ColumnsList.find((column) => column.name === columnName);
        const dictionaryType: DictionaryType = column.metaData && column.metaData.dictionaryType;

        const filters = columnFilters.filters[columnName];
        const areFiltersLoaded = !lodash.isEmpty(filters);

        return {
            dictionaryType,
            users,
            userDictionaries,
            areFiltersLoaded,
            filters,
            loadingStatues: columnFilters.loadingStatus,
        };
    });

    const loadFilters = React.useCallback(
        (payload: LoadFiltersPaylaod) => dispatch(actions.loadFilters(payload)),
        [actions.loadFilters],
    );

    const loadFiltersByColumn = React.useCallback(
        () => dispatch(thunk.loadFiltersByColumn(columnName)),
        [thunk.loadFiltersByColumn],
    );

    const setFilters = React.useCallback(
        (filters: Filters) => dispatch(actions.setFilters(filters)),
        [actions.setFilters],
    );

    return children({
        filterLoadingStatus: loadingStatues[columnName],
        dictionaryType,
        users,
        dictionaries: userDictionaries,
        filters,
        loadFilters,
        loadFiltersByColumn,
        setFilters,
    });
};
