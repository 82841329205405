import * as React from 'react';
import classNames from 'classnames';

import * as style from './RelativeConversionRate.scss';

import { Input_redesign as Input, InputTheme_redesign as InputTheme } from 'sber-marketing-ui';
import { Slider } from '../Slider';

interface Props {
    value: number;
    minValue: number;
    maxValue: number;
    disabled?: boolean;
    onValueChange: (value: number) => void;
}

export const RelativeConversionRate = ({ value, minValue, maxValue, disabled, onValueChange }: Props): JSX.Element => {
    const [editingInput, setEditingInput] = React.useState(false);

    const onValueClick = React.useCallback(() => {
        setEditingInput(!editingInput);
    }, [editingInput]);

    const onInputValueChange = React.useCallback(
        (stringValue: string) => {
            let newValue = stringValue ? parseInt(stringValue, 0) : 0;

            if (newValue < minValue) {
                newValue = minValue;
            }

            if (newValue > maxValue) {
                newValue = maxValue;
            }

            if (newValue !== value) {
                onValueChange(newValue);
            }
        },
        [value],
    );

    const onInputBlur = React.useCallback(() => {
        setEditingInput(false);
    }, []);

    return (
        <div className={classNames(style.root, disabled && style.disabled)}>
            <div className={style.title}>Относительная конверсионность</div>

            <div className={style.selection}>
                {!editingInput && (
                    <div className={style.value} onClick={!disabled ? onValueClick : null}>
                        {value}
                        <span className={style.valueUnit}>%</span>
                    </div>
                )}

                {editingInput && (
                    <div className={style.value}>
                        <Input
                            value={value}
                            placeholder={''}
                            valueType={'number'}
                            theme={InputTheme.Simple}
                            onInputChange={onInputValueChange}
                            onBlur={onInputBlur}
                            autofocus
                        />

                        <span className={style.valueUnit}>%</span>
                    </div>
                )}

                <div className={style.slider}>
                    <Slider
                        value={value}
                        minValue={minValue}
                        maxValue={maxValue}
                        dotsCount={2}
                        leftColor={'#5abefe'}
                        rightColor={null}
                        disabled={disabled}
                        onValueChange={onValueChange}
                    />
                </div>

                <div className={style.minValue} onClick={!disabled ? () => onValueChange(minValue) : null}>
                    {minValue}&nbsp;
                    <span className={style.valueUnit}>%</span>
                </div>

                <div className={style.maxValue} onClick={!disabled ? () => onValueChange(maxValue) : null}>
                    {maxValue > 0 && '+'}
                    {maxValue}&nbsp;
                    <span className={style.valueUnit}>%</span>
                </div>
            </div>
        </div>
    );
};
