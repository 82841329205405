import { Success } from 'typescript-fsa';
import { convertTelegramStatusResponseToTelegramInfo } from '@store/taskPage/utils';

import { TaskPageState, UpdateTelegramSuccess, UpdateTelegramPayload } from '../types';

export const updateTelegramStartedReducer = (state: TaskPageState): TaskPageState => ({
    ...state,
    telegram: {
        ...state.telegram,
        loading: true,
    },
});

export const updateTelegramErrorReducer = (state: TaskPageState): TaskPageState => ({
    ...state,
    telegram: {
        ...state.telegram,
        loading: false,
    },
});

export const updateTelegramDoneReducer = (
    state: TaskPageState,
    payload: Success<UpdateTelegramPayload, UpdateTelegramSuccess>,
): TaskPageState => {
    return {
        ...state,
        telegram: convertTelegramStatusResponseToTelegramInfo(payload.result),
    };
};
