import * as React from 'react';
import * as moment from 'moment';

import * as style from './DatepickerCell.scss';

import { DatepickerContent } from 'sber-marketing-ui';
import { CellEditorWrapper } from '../CellEditorWrapper';

interface Props {
    title: string;
    value: moment.Moment;
    customStyle?: any;
    onValueChange: (value: any) => void;
}

export const DatepickerCellEditTemplate = ({ title, value, customStyle, onValueChange }: Props): JSX.Element => {
    return (
        <div className={style.root} style={{ ...customStyle }}>
            {title}

            <CellEditorWrapper>
                <DatepickerContent date={value} onChange={(value) => onValueChange(value)} />
            </CellEditorWrapper>
        </div>
    );
};
