// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-_styles__root{position:relative;display:grid;grid-template-rows:max-content 1fr max-content}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/CreationForm/GeneratedTextEditor/Editor/styles.scss"],"names":[],"mappings":"AAAA,mIACI,iBAAkB,CAElB,YAAa,CACb,8CAA+C","sourcesContent":[".root {\n    position: relative;\n\n    display: grid;\n    grid-template-rows: max-content 1fr max-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
