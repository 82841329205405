import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { BudgetItem } from '@mrm/budget';
import { uniqBy, groupBy } from 'lodash';

import { LoadingStatus } from '@store/commonTypes';

import * as actions from './actions/sync';

import { MiscBudgetItemsState as State } from './types';

class Reducer {
    public static makeInitialState(): State {
        return {
            loadingStatus: LoadingStatus.LOADED,
            entities: [],
            stores: {
                byId: {},
                byActivityId: {},
            },
        };
    }

    public static setLoadingStatus(state: State, loadingStatus: LoadingStatus): State {
        return { ...state, loadingStatus };
    }

    public static addBudgetItems(state: State, newBudgetItems: BudgetItem[]): State {
        const entities = uniqBy([...state.entities, ...newBudgetItems], (budgetItem) => budgetItem.id);

        const byId = newBudgetItems.reduce(
            (acc, budgetItem) => ({
                ...acc,
                [budgetItem.id]: budgetItem,
            }),
            state.stores.byId,
        );

        const byActivityId = {
            ...state.stores.byActivityId,
            ...groupBy(newBudgetItems, 'activity.id'),
        };

        return {
            ...state,
            entities,
            stores: { byId, byActivityId },
        };
    }
}

export const miscBudgetItemsReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(actions.setLoadingStatus, Reducer.setLoadingStatus)
    .case(actions.addBudgetItems, Reducer.addBudgetItems)
    .case(actions.resetState, Reducer.makeInitialState);
