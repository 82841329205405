// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-components-CommentAssetsHeader-_CommentAssetsHeader__root{display:flex;align-items:center;gap:24px;padding-bottom:6px;color:#7e8681;font-size:12px;line-height:16px;cursor:pointer}.src-client-modules-activity-pages-task-components-CommentAssetsHeader-_CommentAssetsHeader__openButton{display:flex;align-items:center;user-select:none}.src-client-modules-activity-pages-task-components-CommentAssetsHeader-_CommentAssetsHeader__expandIcon{cursor:pointer;color:#7e8681;transition:transform .3s}.src-client-modules-activity-pages-task-components-CommentAssetsHeader-_CommentAssetsHeader__expandIconActive{transform:rotate(180deg)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/components/CommentAssetsHeader/CommentAssetsHeader.scss"],"names":[],"mappings":"AAAA,kGACI,YAAa,CACb,kBAAmB,CAEnB,QAAS,CAET,kBAAmB,CAEnB,aAAc,CAEd,cAAe,CACf,gBAAiB,CAEjB,cAAe,CAClB,wGAGG,YAAa,CACb,kBAAmB,CACnB,gBAAiB,CACpB,wGAGG,cAAe,CAEf,aAAc,CAEd,wBAAyB,CAC5B,8GAGG,wBAAyB","sourcesContent":[".root {\n    display: flex;\n    align-items: center;\n\n    gap: 24px;\n\n    padding-bottom: 6px;\n\n    color: #7e8681;\n\n    font-size: 12px;\n    line-height: 16px;\n\n    cursor: pointer;\n}\n\n.openButton {\n    display: flex;\n    align-items: center;\n    user-select: none;\n}\n\n.expandIcon {\n    cursor: pointer;\n\n    color: #7e8681;\n\n    transition: transform .3s;\n}\n\n.expandIconActive {\n    transform: rotate(180deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-components-CommentAssetsHeader-_CommentAssetsHeader__root",
	"openButton": "src-client-modules-activity-pages-task-components-CommentAssetsHeader-_CommentAssetsHeader__openButton",
	"expandIcon": "src-client-modules-activity-pages-task-components-CommentAssetsHeader-_CommentAssetsHeader__expandIcon",
	"expandIconActive": "src-client-modules-activity-pages-task-components-CommentAssetsHeader-_CommentAssetsHeader__expandIconActive"
};
export default ___CSS_LOADER_EXPORT___;
