// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-components-TopLine-_TopLine__topLine,.src-client-modules-activity-pages-task-components-TopLine-_TopLine__topLine_subgroup{display:flex;margin-bottom:16px;justify-content:space-between}.src-client-modules-activity-pages-task-components-TopLine-_TopLine__topLine_subgroup .src-client-modules-activity-pages-task-components-TopLine-_TopLine__topLineItem{margin-right:32px}.src-client-modules-activity-pages-task-components-TopLine-_TopLine__topLine_subgroup .src-client-modules-activity-pages-task-components-TopLine-_TopLine__topLineItem:last-child{margin-right:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/components/TopLine/TopLine.scss"],"names":[],"mappings":"AAAA,mKAEI,YAAa,CAEb,kBAAmB,CAEnB,6BAA8B,CACjC,uKAGG,iBAAkB,CACrB,kLAGG,cAAe","sourcesContent":[".topLine,\n.topLine_subgroup {\n    display: flex;\n\n    margin-bottom: 16px;\n\n    justify-content: space-between;\n}\n\n.topLine_subgroup .topLineItem {\n    margin-right: 32px;\n}\n\n.topLine_subgroup .topLineItem:last-child {\n    margin-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topLine": "src-client-modules-activity-pages-task-components-TopLine-_TopLine__topLine",
	"topLine_subgroup": "src-client-modules-activity-pages-task-components-TopLine-_TopLine__topLine_subgroup",
	"topLineItem": "src-client-modules-activity-pages-task-components-TopLine-_TopLine__topLineItem"
};
export default ___CSS_LOADER_EXPORT___;
