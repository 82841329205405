import { actionCreatorFactory } from 'typescript-fsa';

import { LoadEntitiesPayload, SetLoadingStatusParams, SetStoreIdsParams, LoadUsersParams, StoreTypes } from './types';

const actionCreator = actionCreatorFactory('USERS');

const loadUsersAsync = actionCreator.async<LoadUsersParams, void, Error>('LOAD_USERS_ASYNC');

const loadEntities = actionCreator<LoadEntitiesPayload>('LOAD_ENTITIES');
const setStoreIds = actionCreator<SetStoreIdsParams>('SET_STORE_IDS');
const setLoadingStatus = actionCreator<SetLoadingStatusParams>('SET_LOADING_STATUS');
const resetStore = actionCreator<StoreTypes>('RESET_STORE');
const incFetchersCount = actionCreator<StoreTypes>('INC_FETCHERS_COUNT');
const decFetchersCount = actionCreator<StoreTypes>('DEC_FETCHERS_COUNT');

export { loadUsersAsync, loadEntities, setStoreIds, setLoadingStatus, resetStore, incFetchersCount, decFetchersCount };
