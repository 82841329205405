import { axios } from '../lib/axios';
import {
    AutopilotRadio,
    AutopilotRadioMediaplanPlanDataResponse,
    GetAutopilotRadioListParams,
    CreateAutopilotRadioParams,
    UpdateAutopilotRadioParams,
    UpdateAutopilotRadioBriefParams,
    UpdateAutopilotRadioMediaplanParams,
    UpdateAutopilotRadioBudgetParams,
    AutopilotRadioTargetGroup,
} from 'sber-marketing-types/backend';

const BASE_URL = '/api/autopilot-radio';

export class AutopilotRadioApi {
    public static async getAutopilot(autopilotId: string): Promise<AutopilotRadio> {
        const res = await axios.get(`${BASE_URL}/${autopilotId}`);

        return res.data;
    }

    public static async getMediaplan(autopilotId: string): Promise<AutopilotRadioMediaplanPlanDataResponse> {
        const res = await axios.get(`${BASE_URL}/${autopilotId}/mediaplan`);

        return res.data;
    }

    public static async calculateAutopilotMediaplanPlanData(
        autopilotId: string,
    ): Promise<AutopilotRadioMediaplanPlanDataResponse> {
        const res = await axios.get(`${BASE_URL}/${autopilotId}/mediaplan-calculate`);

        return res.data;
    }

    public static async createMediaplan(autopilotId: string): Promise<void> {
        const res = await axios.post(`${BASE_URL}/${autopilotId}/mediaplan`);

        return res.data;
    }

    public static async getAutopilotList(params?: GetAutopilotRadioListParams): Promise<AutopilotRadio[]> {
        const res = await axios.post(`${BASE_URL}/list`, params);

        return res.data;
    }

    public static async createAutopilot(params: CreateAutopilotRadioParams): Promise<void> {
        const res = await axios.post(BASE_URL, params);

        return res.data;
    }

    public static async updateAutopilot(autopilotId: string, params: UpdateAutopilotRadioParams): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}`, params);
    }

    public static async updateBrief(autopilotId: string, params: UpdateAutopilotRadioBriefParams): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/brief`, params);
    }

    public static async updateMediaplan(
        autopilotId: string,
        params: UpdateAutopilotRadioMediaplanParams,
    ): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/mediaplan`, params);
    }

    public static async updateBudget(autopilotId: string, params: UpdateAutopilotRadioBudgetParams): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/budget`, params);
    }

    public static async getTargetGroups(autopilotId: string): Promise<AutopilotRadioTargetGroup[]> {
        const res = await axios.get(`${BASE_URL}/${autopilotId}/mediaplan-target-groups`);

        return res.data;
    }

    public static async getMediaplanXLS(autopilotId: string): Promise<Blob> {
        const res = await axios.get<Blob>(`${BASE_URL}/${autopilotId}/mediaplan-xls`, { responseType: 'blob' });

        return res.data;
    }
}
