import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { UserConfigType } from 'sber-marketing-types/openid';

import { StoreState } from '@store';
import { getMyActivitiesPageFilters } from '@store/userConfig/dashboard';
import { saveUserConfig, ActivitySortingMode } from '@store/userConfig';

import { DashboardSortingMenuContainer, Props } from './DashboardSortingMenuContainer';

interface MapProps {
    selectedOptionId: React.ReactText;
}

interface DispatchProps {
    onSelect: (selectedOptionId: number) => void;
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        selectedOptionId: getMyActivitiesPageFilters(state).sorting,
    };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
    return {
        onSelect: (selectedOptionId: number) =>
            dispatch(
                saveUserConfig({
                    type: UserConfigType.Dashboard,
                    payload: {
                        myActivitiesFilters: {
                            sorting: selectedOptionId,
                        },
                    },
                }),
            ),
    };
}

function mergeProps(mapProps: MapProps, dispatchProps: DispatchProps): Props {
    return {
        options: [
            {
                id: ActivitySortingMode.UNRESOLVED_FIRST,
                title: 'Сначала непросмотренные',
            },
            {
                id: ActivitySortingMode.UPDATING_DATE,
                title: 'По дате обновления',
            },
            {
                id: ActivitySortingMode.CREATION_DATE,
                title: 'По дате создания',
            },
            {
                id: ActivitySortingMode.START_DATE,
                title: 'По дате запуска',
            },
        ],
        selectedOptionId: mapProps.selectedOptionId,
        onSelect: dispatchProps.onSelect,
    };
}

export const MyActivitiesSortingMenu = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
)(DashboardSortingMenuContainer);
