import * as React from 'react';
import { v4 as uuid } from 'uuid';
import * as lodash from 'lodash';
import * as moment from 'moment';
import { FormField, FieldType, SelectItem } from 'sber-marketing-ui';
import { ActivityBudget, BudgetItem } from '@mrm/budget';
import { PlainDictionary, DictionaryType, DictionaryStatus } from '@mrm/dictionary';
import { UserResponseParams } from 'sber-marketing-types/frontend';

import { DataUpdateStrategy } from '@store/tagsEditor';

import { VerticalPosition, TagsPreviewAndEditor } from '@modules/tags/TagsPreviewAndEditor';
import { Utils } from '@common/Utils';

import * as styles from './BudgetForm.scss';

interface Props {
    tagsEditorId: string;
    budgetItemId?: string;
    activityBudget?: ActivityBudget;
    budgetItem?: BudgetItem;
    pageIsInCopyMode: boolean;
    canBeTransfered: boolean;
    users: UserResponseParams[];
    onActivityInputFocus: (budgetItemId: string) => void;
    onActivityInputBlur: (budgetItemId: string) => void;
}

export function makeFormFields({
    tagsEditorId,
    budgetItemId,
    activityBudget,
    budgetItem,
    pageIsInCopyMode,
    canBeTransfered,
    users,
    onActivityInputFocus,
    onActivityInputBlur,
}: Props): FormField[] {
    const realizationStart = budgetItem && budgetItem.realizationStart ? moment(budgetItem.realizationStart) : null;
    const realizationEnd = budgetItem && budgetItem.realizationEnd ? moment(budgetItem.realizationEnd) : null;

    return (
        [
            {
                qaId: 'activityBudgetInput',
                name: 'activityBudget',
                type: FieldType.Input,
                title: 'Относится к активности',
                value: activityBudget ? activityBudget.name : null,
                disabled: !canBeTransfered,
                onFocus: () => onActivityInputFocus(budgetItem ? budgetItem.id : null),
                onBlur: () => onActivityInputBlur(budgetItem ? budgetItem.id : null),
            },
            {
                qaId: 'sapCommentInput',
                name: 'sapComment',
                type: FieldType.Input,
                title: 'Название проекта (Комментарий для SAP)',
                value: budgetItem ? budgetItem.sapComment : null,
            },
            {
                qaId: 'regionalityDropdown',
                name: 'regionality',
                type: FieldType.Select,
                title: 'ЦА/ТБ (территория)',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.Regionality),
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.Regionality,
            },
            {
                qaId: 'activityTypeDropdown',
                name: 'activityType',
                type: FieldType.Select,
                title: 'Тип проекта',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.ActivityType),
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.ActivityType,
            },
            {
                qaId: 'directionDropdown',
                name: 'direction',
                type: FieldType.Select,
                title: 'Направление',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.Direction),
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.Direction,
            },
            {
                qaId: 'toolDropdown',
                name: 'tool',
                type: FieldType.Select,
                title: 'Инструмент',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.Tool),
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.Tool,
            },
            {
                qaId: 'costCenterDropdown',
                name: 'costCenter',
                type: FieldType.Select,
                title: 'ЦЗ',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.CostCenter),
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.CostCenter,
            },
            {
                qaId: 'blockDropdown',
                name: 'block',
                type: FieldType.Select,
                title: 'Блок',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.Block),
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.Block,
            },
            {
                qaId: 'divisionDropdown',
                name: 'division',
                type: FieldType.Select,
                title: 'Дивизион',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.Division),
                disableAutoSelectSingleItem: true,
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.Division,
            },
            {
                qaId: 'itemDropdown',
                name: 'item',
                type: FieldType.Select,
                title: 'Статья',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.Item),
                disableAutoSelectSingleItem: true,
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.Item,
            },
            {
                qaId: 'resourceDropdown',
                name: 'resource',
                type: FieldType.Select,
                title: 'Ресурс',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.Resource),
                disableAutoSelectSingleItem: true,
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.Resource,
            },
            {
                qaId: 'territoryDropdown',
                name: 'territory',
                type: FieldType.Select,
                title: 'Территория',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.Territory),
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.Territory,
            },
            {
                qaId: 'locationDriverDropdown',
                name: 'locationDriver',
                type: FieldType.Select,
                title: 'Драйвер аллокации (Бизнес-блок)',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.LocationDriver),
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.LocationDriver,
            },
            {
                qaId: 'segmentDropdown',
                name: 'segment',
                type: FieldType.Select,
                title: 'Сегмент',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.Segment),
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.Segment,
            },
            {
                qaId: 'productDropdown',
                name: 'product',
                type: FieldType.Select,
                title: 'Продукт',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.Product),
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.Product,
            },
            {
                qaId: 'channelDropdown',
                name: 'channel',
                type: FieldType.Select,
                title: 'Канал',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.Channel),
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.Channel,
            },
            {
                qaId: 'responsibleDropdown',
                name: 'responsibles',
                type: FieldType.Select,
                allowMultipleItems: true,
                title: 'Руководители проекта',
                items: lodash.sortBy(
                    users.length
                        ? users.map((item) => ({
                              label: makeResponsibleLabel(item),
                              value: item.id,
                          }))
                        : [],
                    'label',
                ),
                value: budgetItem && budgetItem.responsibles ? budgetItem.responsibles.map((user) => user.id) : [],
            },
            {
                qaId: 'businessTargetDropdown',
                name: 'businessTarget',
                type: FieldType.Input,
                title: 'Бизнес-цель',
                value: budgetItem ? budgetItem.businessTarget : null,
            },
            {
                qaId: 'customerNameInput',
                name: 'customerName',
                type: FieldType.Input,
                title: 'ФИО заказчика',
                value: budgetItem && budgetItem.customerName ? budgetItem.customerName : null,
            },
            {
                type: FieldType.CustomJSX,
                jsxValue: (
                    <TagsPreviewAndEditor
                        id={tagsEditorId}
                        verticalPosition={VerticalPosition.Top}
                        executionBudgetItemId={budgetItemId || budgetItem?.id || null}
                        dataUpdateStrategy={DataUpdateStrategy.OnDemand}
                        className={styles.tagsEditor}
                    />
                ),
            },
            {
                qaId: 'startDateSelector',
                name: 'startDate',
                type: FieldType.DatePicker,
                title: 'Дата старта',
                minDate: null,
                maxDate: realizationEnd,
                value: realizationStart,
                isSmall: true,
            },
            {
                qaId: 'endDateSelector',
                name: 'endDate',
                type: FieldType.DatePicker,
                title: 'Дата окончания',
                minDate: realizationStart,
                maxDate: null,
                value: realizationEnd,
                isSmall: true,
            },
            {
                qaId: 'commentInput',
                name: 'comment',
                type: FieldType.Textarea,
                title: 'Примечания к строке',
                value: !pageIsInCopyMode && budgetItem ? budgetItem.comment : null,
            },
        ] as any[]
    ).map((item) => ({
        ...item,
        id: item.id || uuid(),
        errorMessage: '',
        disabled: item.disabled === undefined ? budgetItem && !pageIsInCopyMode : item.disabled,
    }));
}

function getDictionaryValue(budgetItem: BudgetItem, dictionaryType: DictionaryType) {
    if (!budgetItem || !budgetItem.dictionary || !budgetItem.dictionary[dictionaryType]) {
        return null;
    }

    return budgetItem.dictionary[dictionaryType].id;
}

export function formatDictionaryItems(
    dictionaries: PlainDictionary[],
    tooltipAccessor?: (label: string) => JSX.Element,
    resetLinksTooltip?: JSX.Element,
): SelectItem[] {
    const items: SelectItem[] = lodash.sortBy(
        dictionaries.map((item) => {
            const label = Utils.getDictionaryValue(item);

            const res: SelectItem = {
                label,
                value: item.id,
                disabled: item.status === DictionaryStatus.DELETED,
            };

            if (tooltipAccessor) {
                res.tooltip = tooltipAccessor(label);
            }

            return res;
        }),
        (item) => (item.label ? item.label.replace(/\"|\'/g, '') : ''),
    );

    if (resetLinksTooltip) {
        items.unshift({
            label: 'Сброс связей',
            value: null,
            tooltip: resetLinksTooltip,
            style: { fontWeight: 600 },
        });
    } else if (items.length) {
        items.unshift({ label: '-', value: null });
    }

    return items;
}

function makeResponsibleLabel(user: UserResponseParams): string {
    const { firstName, middleName, secondName } = user;

    return middleName ? `${secondName} ${firstName} ${middleName}` : `${secondName} ${firstName}`;
}
