// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-tags-TagsPage-Filters-common-FilterTitle-_FilterTitle__root{font-family:'Open Sans';font-weight:400;font-size:14px;line-height:19px;letter-spacing:0.15px;color:#7E8681}\n", "",{"version":3,"sources":["webpack://./src/client/modules/tags/TagsPage/Filters/common/FilterTitle/FilterTitle.scss"],"names":[],"mappings":"AAAA,gFACI,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,qBAAsB,CACtB,aAAc","sourcesContent":[".root {\n    font-family: 'Open Sans';\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 19px;\n    letter-spacing: 0.15px;\n    color: #7E8681;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-tags-TagsPage-Filters-common-FilterTitle-_FilterTitle__root"
};
export default ___CSS_LOADER_EXPORT___;
