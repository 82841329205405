import { Color } from '../../../common';
import { FilterPeriod } from '@store/leaderDashboardPage';
import { Mode } from '@store/leaderDashboardPage';

interface Data {
    id: string;
    name: string;
    bar: Bar;
    tabBarConfig: TabBarConfig;
}

type Bar = Record<BarItem, { value: number; color: Color }>;

type BarItem = 'first' | 'second' | 'third' | 'fourth';

type TabBarConfig = TabBarConfigItem[];

interface TabBarConfigItem {
    name: string;
    color: Color;
}

export { Color };

export type { Data, Bar, BarItem, TabBarConfig, FilterPeriod, Mode };
