import * as moment from 'moment';

import { UserResponseParams } from 'sber-marketing-types/frontend';
import { NumericCellValue, DateCellValue } from '@store/budgetPlanning/types';

export function makeNumberValue(value: number): NumericCellValue {
    return {
        title: value !== undefined && value !== null ? value.toString() : null,
        number: value,
    };
}

export function makeDateValue(date: Date): DateCellValue {
    return {
        title: moment(date).format('DD MMMM YYYY'),
        date,
    };
}

export function formatResponsibleName(user: UserResponseParams): string {
    const { firstName, middleName, secondName } = user;

    return middleName ? `${secondName} ${firstName} ${middleName}` : `${secondName} ${firstName}`;
}
