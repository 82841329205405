import * as React from 'react';
import { graphql, DataProps } from 'react-apollo';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { CardBudgetItem } from '../types';

import { GET_CARDS_QUERY } from './graphqlQuery';

export interface Card {
    id: string;
    budgetItem: CardBudgetItem;
}

interface Props extends QueryProps, ExternalProps {
    children?: (params: RenderParams) => React.ReactNode | React.ReactNode[];
}

type QueryProps = DataProps<{
    cards: {
        nodes: Card[];
    };
}>;

interface ExternalProps {
    cardId: string;
    children?: (params: RenderParams) => React.ReactNode | React.ReactNode[];
}

interface RenderParams {
    card: Card;
    loading: boolean;
    refetch: () => Promise<any>;
    updateCard: () => Promise<void>;
}

class CardQuery extends React.Component<Props> {
    public render() {
        const { data } = this.props;

        const cards = (lodash.get(data, 'cards.nodes') as Card[]) || [];
        const card = lodash.first(cards) || null;

        return this.props.children({
            card,
            loading: data ? data.loading : true,
            updateCard: this.updateCard,
            refetch: this.props.data.refetch,
        });
    }

    @autobind
    private async updateCard(): Promise<void> {
        await this.props.data.refetch();
    }
}

export const WithCard = graphql<ExternalProps>(GET_CARDS_QUERY, {
    options: (props) => {
        return {
            variables: {
                cardId: [props.cardId],
            },
            fetchPolicy: 'no-cache',
        };
    },
})(CardQuery);
