// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-TaskSidebar-tabs-FilesTab-_FilesTab__downloadAllButtonWrapper{margin-bottom:10px;display:flex;justify-content:end}.src-client-modules-common-TaskSidebar-tabs-FilesTab-_FilesTab__text,.src-client-modules-common-TaskSidebar-tabs-FilesTab-_FilesTab__title{font-family:'Open Sans';font-weight:400;font-size:12px;line-height:16px;color:#7E8681}.src-client-modules-common-TaskSidebar-tabs-FilesTab-_FilesTab__titleMargin,.src-client-modules-common-TaskSidebar-tabs-FilesTab-_FilesTab__title,.src-client-modules-common-TaskSidebar-tabs-FilesTab-_FilesTab__titlePreloader{margin-bottom:10px}.src-client-modules-common-TaskSidebar-tabs-FilesTab-_FilesTab__titlePreloader{width:40px;height:16px}.src-client-modules-common-TaskSidebar-tabs-FilesTab-_FilesTab__downloadAllButtonPreloader{width:70px;height:17px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/TaskSidebar/tabs/FilesTab/FilesTab.scss"],"names":[],"mappings":"AAAA,yFACI,kBAAmB,CAEnB,YAAa,CACb,mBAAoB,CACvB,2IAGG,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,aAAc,CACjB,iOAGG,kBAAmB,CACtB,+EAUG,UAAW,CACX,WAAY,CACf,2FAGG,UAAW,CACX,WAAY","sourcesContent":[".downloadAllButtonWrapper {\n    margin-bottom: 10px;\n\n    display: flex;\n    justify-content: end;\n}\n\n.text {\n    font-family: 'Open Sans';\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 16px;\n    color: #7E8681;\n}\n\n.titleMargin {\n    margin-bottom: 10px;\n}\n\n.title {\n    @extend .text;\n    @extend .titleMargin;\n}\n\n.titlePreloader {\n    @extend .titleMargin;\n\n    width: 40px;\n    height: 16px;\n}\n\n.downloadAllButtonPreloader {\n    width: 70px;\n    height: 17px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downloadAllButtonWrapper": "src-client-modules-common-TaskSidebar-tabs-FilesTab-_FilesTab__downloadAllButtonWrapper",
	"text": "src-client-modules-common-TaskSidebar-tabs-FilesTab-_FilesTab__text",
	"title": "src-client-modules-common-TaskSidebar-tabs-FilesTab-_FilesTab__title",
	"titleMargin": "src-client-modules-common-TaskSidebar-tabs-FilesTab-_FilesTab__titleMargin",
	"titlePreloader": "src-client-modules-common-TaskSidebar-tabs-FilesTab-_FilesTab__titlePreloader",
	"downloadAllButtonPreloader": "src-client-modules-common-TaskSidebar-tabs-FilesTab-_FilesTab__downloadAllButtonPreloader"
};
export default ___CSS_LOADER_EXPORT___;
