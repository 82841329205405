import autobind from 'autobind-decorator';

import { TableHeaderCellParams, ColumnName } from '../../types';
import { ColumnsConfig, ColumnHeaderType } from '../../ColumnsConfig';

import { TextColumnHeader } from '../../ColumnHeaderTypes';

export const ColumnHeaderComponentsByType: Record<ColumnHeaderType, React.ClassType<any, any, any>> = {
    [ColumnHeaderType.Text]: TextColumnHeader,
};

interface Props {}

export class ColumnHeaderFactory {
    public constructor(props: Props) {}

    @autobind
    public async makeColumnHeaderParams(columnName: ColumnName): Promise<TableHeaderCellParams> {
        return {
            component: this.getColumnHeaderComponent(columnName),
            cellProps: await this.makeColumnHeaderProps(columnName),
        };
    }

    private getColumnHeaderComponent(columnName: ColumnName): React.ClassType<any, any, any> {
        const columnType = ColumnsConfig[columnName].headerType;

        return ColumnHeaderComponentsByType[columnType];
    }

    private async makeColumnHeaderProps(columnName: ColumnName): Promise<any> {
        const headerType = ColumnsConfig[columnName].headerType;

        let cellProps: any;

        switch (headerType) {
            case ColumnHeaderType.Text:
                cellProps = await this.makeTextColumnHeaderProps(columnName as ColumnName);
                break;
        }

        return cellProps;
    }

    private async makeTextColumnHeaderProps(columnName: ColumnName): Promise<any> {
        return {
            title: ColumnsConfig[columnName].title,
        };
    }
}
