// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-Card-CardSubInfoGroup-_CardSubInfoGroup__lightningIcon{margin:0 -3px}.src-client-modules-common-Card-CardSubInfoGroup-_CardSubInfoGroup__branchesIcon{margin:0 -3px 0 -2px}.src-client-modules-common-Card-CardSubInfoGroup-_CardSubInfoGroup__text{overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.src-client-modules-common-Card-CardSubInfoGroup-_CardSubInfoGroup__link{text-decoration:none;color:#417ec6}.src-client-modules-common-Card-CardSubInfoGroup-_CardSubInfoGroup__bold{padding:1px 6px;background:linear-gradient(0deg, rgba(0,0,0,0.03), rgba(0,0,0,0.03)),#F6F6F6;border-radius:4px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/Card/CardSubInfoGroup/CardSubInfoGroup.scss"],"names":[],"mappings":"AAAA,kFACI,aAAc,CACjB,iFAGG,oBAAqB,CACxB,yEAGG,eAAgB,CAEhB,kBAAmB,CACnB,sBAAuB,CAC1B,yEAGG,oBAAqB,CAErB,aAAc,CACjB,yEAGG,eAAgB,CAEhB,4EAAoF,CACpF,iBAAkB","sourcesContent":[".lightningIcon {\n    margin: 0 -3px;\n}\n\n.branchesIcon {\n    margin: 0 -3px 0 -2px;\n}\n\n.text {\n    overflow: hidden;\n\n    white-space: nowrap;\n    text-overflow: ellipsis;\n}\n\n.link {\n    text-decoration: none;\n\n    color: #417ec6;\n}\n\n.bold {\n    padding: 1px 6px;\n\n    background: linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)), #F6F6F6;\n    border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lightningIcon": "src-client-modules-common-Card-CardSubInfoGroup-_CardSubInfoGroup__lightningIcon",
	"branchesIcon": "src-client-modules-common-Card-CardSubInfoGroup-_CardSubInfoGroup__branchesIcon",
	"text": "src-client-modules-common-Card-CardSubInfoGroup-_CardSubInfoGroup__text",
	"link": "src-client-modules-common-Card-CardSubInfoGroup-_CardSubInfoGroup__link",
	"bold": "src-client-modules-common-Card-CardSubInfoGroup-_CardSubInfoGroup__bold"
};
export default ___CSS_LOADER_EXPORT___;
