import * as React from 'react';
import { connect } from 'react-redux';
import { CorrectionStatus } from '@mrm/budget';
import autobind from 'autobind-decorator';

import { StoreState } from '@store';
import { userCanEditOrCreateBudgetData } from '@store/budgetCorrections/selectors';

import { CorrectionMenu } from './CorrectionMenu';

interface Props extends Partial<MapProps> {
    status: CorrectionStatus;
    userIsBudgetExpert: boolean;
    userIsAuthorCorrection: boolean;
    url: string;
    acceptButtonIsLocked?: boolean;
    onStatusChange: (status: CorrectionStatus) => void;
}

interface MapProps {
    userCanEditOrCreateBudgetData: boolean;
}

@(connect(mapStateToProps) as any)
export class CorrectionMenuContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        return React.createElement(CorrectionMenu, {
            status: this.props.status,
            userIsBudgetExpert: this.props.userIsBudgetExpert,
            userIsAuthorCorrection: this.props.userIsAuthorCorrection,
            acceptButtonIsLocked: this.props.acceptButtonIsLocked,
            userCanEditOrCreateBudgetData: this.props.userCanEditOrCreateBudgetData,
            onEditButtonClick: this.onEditButtonClick,
            onAcceptButtonClick: this.onAcceptButtonClick,
            onRejectButtonClick: this.onRejectButtonClick,
        });
    }

    @autobind
    protected onEditButtonClick() {
        window.open(this.props.url, '_blank');
    }

    @autobind
    protected onAcceptButtonClick() {
        this.props.onStatusChange(CorrectionStatus.Approved);
    }

    @autobind
    protected onRejectButtonClick() {
        this.props.onStatusChange(CorrectionStatus.Rejected);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        userCanEditOrCreateBudgetData: userCanEditOrCreateBudgetData(state),
    };
}
