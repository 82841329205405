import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import * as moment from 'moment';

import { TableBodyCellParams, CellPosition, LineId } from '../../types';
import {
    ValueAccessor,
    TitleAccessor,
    ValueSetter,
    ItemsAccessor,
    SuggestItemsAccessor,
    DescriptionAccessor,
    CustomStyleAccessor,
    ReadOnlyAccessor,
    IconAccessor,
    AccessorParams,
    ColumnsConfig,
    CellType,
    LineType,
} from '../../ColumnsConfig';
import {
    CreativeRequestLine,
    CreativeRequestSubLine,
    CreativeRequestTableItemStatus,
    Dictionary,
    DictionaryType,
} from '@api';
import { IconType } from 'sber-marketing-ui';

import {
    AddSubLineCell,
    DatepickerCell,
    DatepickerCellEdit,
    FundsInputCell,
    FundsInputCellEdit,
    FundsSelectCell,
    FundsSelectCellEdit,
    InputCell,
    InputCellEdit,
    LineHeader,
    SelectCell,
    SelectCellEdit,
    TextareaCell,
    TextareaCellEdit,
    TextareaWithLinkCell,
    TextCell,
} from '../../CellTypes';

export const CellComponentsByColumnType: Record<
    CellType,
    {
        cell: React.ClassType<any, any, any>;
        editCell?: React.ClassType<any, any, any>;
    }
> = {
    [CellType.LineHeader]: {
        cell: LineHeader,
    },
    [CellType.Text]: {
        cell: TextCell,
    },
    [CellType.Input]: {
        cell: InputCell,
        editCell: InputCellEdit,
    },
    [CellType.Textarea]: {
        cell: TextareaCell,
        editCell: TextareaCellEdit,
    },
    [CellType.TextareaWithLink]: {
        cell: TextareaWithLinkCell,
        editCell: TextareaWithLinkCell,
    },
    [CellType.FundsInput]: {
        cell: FundsInputCell,
        editCell: FundsInputCellEdit,
    },
    [CellType.Select]: {
        cell: SelectCell,
        editCell: SelectCellEdit,
    },
    [CellType.FundsSelect]: {
        cell: FundsSelectCell,
        editCell: FundsSelectCellEdit,
    },
    [CellType.Datepicker]: {
        cell: DatepickerCell,
        editCell: DatepickerCellEdit,
    },
    [CellType.AddSubLine]: {
        cell: AddSubLineCell,
    },
};

interface Props {
    getLot: () => 1 | 2;
    getLine: (lineId: LineId) => CreativeRequestLine;
    getSubLine: (subLineId: LineId) => CreativeRequestSubLine;
    getSubLinesByLineId: (lineId: LineId) => CreativeRequestSubLine[];
    getDictionaries: () => Partial<Record<DictionaryType, Dictionary[]>>;
    setCreativeRequestId: (creativeRequestId: string) => void;
    onCreateSubLineClick: (cellPosition: CellPosition) => void;
    onLineTransfer: (lineId: LineId, contractId: string) => void;
    onLineArchive: (lineId: LineId) => void;
    onLineRestore: (lineId: LineId) => void;
    onCellClose: () => void;
}

export class CellsFactory {
    private getLot: () => 1 | 2;
    private getLine: (lineId: LineId) => CreativeRequestLine;
    private getSubLine: (subLineId: LineId) => CreativeRequestSubLine;
    private getSubLinesByLineId: (lineId: LineId) => CreativeRequestSubLine[];
    private getDictionaries: () => Partial<Record<DictionaryType, Dictionary[]>>;
    private setCreativeRequestId: (creativeRequestId: string) => void;
    private onCreateSubLineClick: (cellPosition: CellPosition) => void;
    private onLineTransfer: (lineId: LineId, contractId: string) => void;
    private onLineArchive: (lineId: LineId) => void;
    private onLineRestore: (lineId: LineId) => void;
    private onCellClose: () => void;

    public constructor(props: Props) {
        this.getLot = props.getLot;
        this.getLine = props.getLine;
        this.getSubLine = props.getSubLine;
        this.getSubLinesByLineId = props.getSubLinesByLineId;
        this.getDictionaries = props.getDictionaries;
        this.setCreativeRequestId = props.setCreativeRequestId;
        this.onCreateSubLineClick = props.onCreateSubLineClick;
        this.onLineTransfer = props.onLineTransfer;
        this.onLineArchive = props.onLineArchive;
        this.onLineRestore = props.onLineRestore;
        this.onCellClose = props.onCellClose;
    }

    @autobind
    public async makeCellParams(cellPosition: CellPosition, edit = false): Promise<TableBodyCellParams> {
        const value = await this.getCellValue(cellPosition);

        return {
            component: value !== undefined ? this.getCellComponent(cellPosition, edit) : null,
            cellProps: value !== undefined ? await this.makeCellProps(cellPosition, edit) : null,
            readOnly: value !== undefined ? await this.checkReadOnlyStatus(cellPosition, edit) : true,
            сellBackgroundColor: this.getCellBackgroundColor(cellPosition),
            preventCloseOnClick: this.checkPreventCloseOnClickStatus(cellPosition, edit),
        };
    }

    @autobind
    public getCellComponent(cellPosition: CellPosition, edit?: boolean): React.ClassType<any, any, any> {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const columnType = lodash.get(ColumnsConfig, [columnName, 'type', lineType]);

        if (!columnType) {
            return null;
        }

        return edit ? CellComponentsByColumnType[columnType].editCell : CellComponentsByColumnType[columnType].cell;
    }

    @autobind
    public async makeCellProps(cellPosition: CellPosition, edit: boolean): Promise<any> {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const columnType = lodash.get(ColumnsConfig, [columnName, 'type', lineType]);

        if (!columnType) {
            return null;
        }

        let cellProps: any;

        switch (columnType) {
            case CellType.LineHeader:
                cellProps = await this.makeLineHeaderProps(cellPosition);
                break;

            case CellType.Text:
                cellProps = await this.makeTextCellProps(cellPosition);
                break;

            case CellType.Input:
                cellProps = await this.makeInputCellProps(cellPosition, edit);
                break;

            case CellType.Textarea:
                cellProps = await this.makeTextareaCellProps(cellPosition, edit);
                break;

            case CellType.TextareaWithLink:
                cellProps = await this.makeTextareaWithLinkCellProps(cellPosition, edit);
                break;

            case CellType.FundsInput:
                cellProps = await this.makeMoneyInputCellProps(cellPosition, edit);
                break;

            case CellType.Datepicker:
                cellProps = await this.makeDatepickerCellProps(cellPosition, edit);
                break;

            case CellType.Select:
                cellProps = await this.makeSelectCellProps(cellPosition, edit);
                break;

            case CellType.FundsSelect:
                cellProps = await this.makeFundsSelectCellProps(cellPosition, edit);
                break;

            case CellType.AddSubLine:
                cellProps = await this.makeAddSubLineCellProps(cellPosition);
                break;
        }

        cellProps = await this.applyCustomStyles(cellPosition, cellProps);

        return cellProps;
    }

    @autobind
    public async checkReadOnlyStatus(cellPosition: CellPosition, edit: boolean): Promise<boolean> {
        const { lineId, columnName } = cellPosition;

        const cellComponent = this.getCellComponent(cellPosition, true);
        const isArchived = this.checkLineArchivedStatus(lineId);

        if (!cellComponent || isArchived) {
            return true;
        }

        const lineType = this.getLineType(lineId);

        const readOnlyStatusField: boolean | ReadOnlyAccessor =
            lodash.get(ColumnsConfig, [columnName, 'readOnly', lineType]) !== undefined
                ? lodash.get(ColumnsConfig, [columnName, 'readOnly', lineType])
                : lodash.get(ColumnsConfig, [columnName, 'readOnly']);

        if (readOnlyStatusField === undefined) {
            return false;
        }

        const readOnlyStatus: boolean | ReadOnlyAccessor = lodash.isFunction(readOnlyStatusField)
            ? await readOnlyStatusField(this.makeAccessorParams(cellPosition))
            : readOnlyStatusField;

        return readOnlyStatus || false;
    }

    @autobind
    public async getCellValue(cellPosition: CellPosition): Promise<any> {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const accessorField = lodash.get(ColumnsConfig, [columnName, 'getValue']);

        const accessor: ValueAccessor = lodash.isFunction(accessorField) ? accessorField : accessorField[lineType];

        if (!accessor) {
            return undefined;
        }

        const params = this.makeAccessorParams(cellPosition);

        return accessor(params);
    }

    private getCellBackgroundColor(cellPosition: CellPosition): string {
        const { lineId } = cellPosition;

        const lineType = this.getLineType(lineId);

        let backgroundColor = lineType === LineType.Creative ? '#ffffff' : '#cce6ff';

        if (lineType === LineType.Creative) {
            const lineStatus: string = this.getLine(lineId)?.model.status?.value;

            if (lineStatus === CreativeRequestTableItemStatus.closed) {
                backgroundColor = '#daf4e4';
            }
        } else if (lineType === LineType.Assignment) {
            const sublineStatus = this.getSubLine(lineId)?.model.status?.value;

            if (sublineStatus === 'Оплачено') {
                backgroundColor = '#edf8f1';
            }
        }

        return backgroundColor;
    }

    private checkPreventCloseOnClickStatus(cellPosition: CellPosition, edit: boolean): boolean {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const columnType = lodash.get(ColumnsConfig, [columnName, 'type', lineType]);

        if (!columnType) {
            return false;
        }

        return (
            edit &&
            [
                CellType.Input,
                CellType.Textarea,
                CellType.TextareaWithLink,
                CellType.FundsInput,
                CellType.Datepicker,
            ].includes(columnType)
        );
    }

    private async makeLineHeaderProps(cellPosition: CellPosition): Promise<any> {
        const { lineId } = cellPosition;

        const lineType = this.getLineType(lineId);
        const isArchived = this.checkLineArchivedStatus(lineId);

        const line = this.getLine(lineId);
        const subLine = this.getSubLine(lineId);

        const contractId: string =
            lineType === LineType.Creative
                ? line.model.contract?.id || null
                : this.getLine(subLine.model.itemId).model.contract?.id || null;

        const lineCanBeRestored =
            lineType === LineType.Assignment ? !this.checkLineArchivedStatus(subLine.model.itemId) : true;

        return {
            lot: this.getLot(),
            title: (await this.getCellValue(cellPosition)) || '—',
            contractId,
            creativeRequestId: line?.model.creativeRequestId || null,
            lineCanBeTransfered: lineType === LineType.Creative,
            lineIsArchived: isArchived,
            lineCanBeRestored,
            onCreativeRequestInfoDisplay: this.setCreativeRequestId,
            onLineTransfer: (contractId: string) => this.onLineTransfer(lineId, contractId),
            onLineArchive: () => this.onLineArchive(lineId),
            onLineRestore: () => this.onLineRestore(lineId),
        };
    }

    private async makeTextCellProps(cellPosition: CellPosition): Promise<any> {
        const value = await this.getCellValue(cellPosition);
        const description = await this.getCellDescription(cellPosition);

        return {
            title: value || '—',
            description,
        };
    }

    private async makeInputCellProps(cellPosition: CellPosition, edit: boolean): Promise<any> {
        const value = await this.getCellValue(cellPosition);

        return edit
            ? {
                  title: value || '',
                  placeholder: '',
                  preventCloseOnClick: true,
                  suggestItems: await this.getCellSuggestItems(cellPosition),
                  onValueChange: this.makeValueChangeHandler(cellPosition),
              }
            : {
                  title: value || '—',
              };
    }

    private async makeTextareaCellProps(cellPosition: CellPosition, edit: boolean): Promise<any> {
        const value = await this.getCellValue(cellPosition);

        return edit
            ? {
                  title: value || '',
                  placeholder: '',
                  preventCloseOnClick: true,
                  onValueChange: this.makeValueChangeHandler(cellPosition),
              }
            : {
                  title: value || '—',
              };
    }

    private async makeTextareaWithLinkCellProps(cellPosition: CellPosition, edit: boolean): Promise<any> {
        const { lineId } = cellPosition;

        const value = await this.getCellValue(cellPosition);

        const line = this.getLine(lineId);

        return {
            edit,
            title: value || '',
            placeholder: '',
            url: !!line?.model.creativeRequestId
                ? `/activity/${line?.model.projectId}/creative/${line?.model.creativeRequestId}`
                : null,
            urlTooltip: 'Перейти в ЗНК',
            preventCloseOnClick: true,
            onValueChange: this.makeValueChangeHandler(cellPosition),
        };
    }

    private async makeMoneyInputCellProps(cellPosition: CellPosition, edit: boolean): Promise<any> {
        const value = await this.getCellValue(cellPosition);
        const description = await this.getCellDescription(cellPosition);

        return edit
            ? {
                  title: value,
                  placeholder: '',
                  icon: await this.getCellIcon(cellPosition),
                  onValueChange: this.makeValueChangeHandler(cellPosition),
              }
            : {
                  title: this.formatCurrencyValue(this.roundNumber(value)) || '—',
                  description,
                  icon: await this.getCellIcon(cellPosition),
              };
    }

    private async makeDatepickerCellProps(cellPosition: CellPosition, edit: boolean): Promise<any> {
        const cellValue = await this.getCellValue(cellPosition);

        const momentValue = cellValue ? moment(cellValue) : null;

        return edit
            ? {
                  title: momentValue ? momentValue.format('DD.MM.YY') : '—',
                  value: momentValue,
                  onValueChange: this.makeValueChangeHandler(cellPosition),
              }
            : {
                  title: cellValue ? moment(cellValue).format('DD.MM.YY') : '—',
              };
    }

    private async makeSelectCellProps(cellPosition: CellPosition, edit: boolean): Promise<any> {
        const value = await this.getCellValue(cellPosition);
        const items = await this.getCellItems(cellPosition);
        const description = await this.getCellDescription(cellPosition);

        return edit
            ? {
                  title: value !== null ? items.find((item) => item.value === value)?.title : `—`,
                  description,
                  items,
                  selectedValue: value,
                  onValueChange: this.makeValueChangeHandler(cellPosition),
              }
            : {
                  title: value !== null ? items.find((item) => item.value === value)?.title : `—`,
                  description,
              };
    }

    private async makeFundsSelectCellProps(cellPosition: CellPosition, edit: boolean): Promise<any> {
        const value = await this.getCellValue(cellPosition);
        const items = await this.getCellItems(cellPosition);
        let title = await this.getCellTitle(cellPosition);
        const description = await this.getCellDescription(cellPosition);

        if (title === undefined) {
            title = value !== null ? items.find((item) => item.value === value)?.title : `—`;
        } else {
            title = this.formatCurrencyValue(this.roundNumber(title as number, 2)) || '—';
        }

        return edit
            ? {
                  title,
                  description,
                  items,
                  selectedValue: value,
                  onValueChange: this.makeValueChangeHandler(cellPosition),
              }
            : {
                  title,
                  description,
              };
    }

    private async makeAddSubLineCellProps(cellPosition: CellPosition): Promise<any> {
        const { lineId } = cellPosition;

        const subLines = this.getSubLinesByLineId(lineId);
        const isArchived = this.checkLineArchivedStatus(lineId);

        return {
            subLinesCount: subLines.length,
            disabled: isArchived,
            onButtonClick: () => this.onCreateSubLineClick(cellPosition),
        };
    }

    @autobind
    private async getCellItems(cellPosition: CellPosition): Promise<{ title: string; value: any }[]> {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const itemsAccessorField = lodash.get(ColumnsConfig, [columnName, 'getItems']);

        const itemsAccessor: ItemsAccessor = lodash.isFunction(itemsAccessorField)
            ? itemsAccessorField
            : itemsAccessorField[lineType];

        if (!itemsAccessor) {
            return undefined;
        }

        const params = this.makeAccessorParams(cellPosition);

        return itemsAccessor(params);
    }

    private async getCellTitle(cellPosition: CellPosition): Promise<React.ReactText> {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const titleAccessorField = lodash.get(ColumnsConfig, [columnName, 'getTitle']);

        if (!titleAccessorField) {
            return undefined;
        }

        const titleAccessor: TitleAccessor = lodash.isFunction(titleAccessorField)
            ? titleAccessorField
            : titleAccessorField[lineType];

        const params = this.makeAccessorParams(cellPosition);

        return titleAccessor(params);
    }

    private async getCellSuggestItems(cellPosition: CellPosition): Promise<any[]> {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const itemsAccessor: SuggestItemsAccessor = lodash.get(ColumnsConfig, [
            columnName,
            'getSuggestItems',
            lineType,
        ]);

        if (!itemsAccessor) {
            return [];
        }

        const params = this.makeAccessorParams(cellPosition);

        return itemsAccessor(params);
    }

    private async getCellDescription(cellPosition: CellPosition): Promise<string> {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const descriptionAccessorField = lodash.get(ColumnsConfig, [columnName, 'getDescription']);

        if (!descriptionAccessorField) {
            return undefined;
        }

        const descriptionAccessor: DescriptionAccessor = lodash.isFunction(descriptionAccessorField)
            ? descriptionAccessorField
            : descriptionAccessorField[lineType];

        if (!descriptionAccessor) {
            return null;
        }

        const params = this.makeAccessorParams(cellPosition);

        return descriptionAccessor(params);
    }

    private async getCellIcon(cellPosition: CellPosition): Promise<{ type: IconType; color: string; size: number }> {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const iconAccessor: IconAccessor = lodash.get(ColumnsConfig, [columnName, 'getIcon', lineType]);

        if (!iconAccessor) {
            return null;
        }

        const params = this.makeAccessorParams(cellPosition);

        return iconAccessor(params);
    }

    private getLineType(lineId: LineId): LineType {
        let lineType: LineType = null;

        const line = this.getLine(lineId);

        if (line) {
            lineType = LineType.Creative;
        } else {
            const subLine = this.getSubLine(lineId);

            if (subLine) {
                lineType = LineType.Assignment;
            }
        }

        return lineType;
    }

    private makeAccessorParams(cellPosition: CellPosition): AccessorParams {
        const { lineId } = cellPosition;

        const subLine = this.getSubLine(lineId);

        const line = subLine ? this.getLine(subLine.model.itemId) : this.getLine(lineId);

        const allSubLines = !subLine ? this.getSubLinesByLineId(lineId) : [];

        const dictionariesByType = this.getDictionaries();

        return {
            id: lineId,
            line: line.model,
            subLine: subLine?.model,
            allSubLines: allSubLines.map((item) => item.model),
            dictionariesByType,
        };
    }

    private makeValueChangeHandler(cellPosition: CellPosition) {
        return async (value: any) => {
            const { lineId, columnName } = cellPosition;

            const lineType = this.getLineType(lineId);
            const params = this.makeAccessorParams(cellPosition);

            const valueSetterField = lodash.get(ColumnsConfig, [columnName, 'setValue']);

            const valueSetter: ValueSetter = lodash.isFunction(valueSetterField)
                ? valueSetterField
                : valueSetterField[lineType];

            await valueSetter(params, value);

            this.onCellClose();
        };
    }

    private async applyCustomStyles(cellPosition: CellPosition, cellProps: any): Promise<any> {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        cellProps.customStyle = {
            ...cellProps.customStyle,
            backgroundColor: this.getCellBackgroundColor(cellPosition),
        };

        const customStyleAccessorField = lodash.get(ColumnsConfig, [columnName, 'customStyle']);

        if (customStyleAccessorField) {
            const customStyleAccessor: CustomStyleAccessor = lodash.isFunction(customStyleAccessorField)
                ? customStyleAccessorField
                : customStyleAccessorField[lineType];

            if (customStyleAccessor) {
                const params = this.makeAccessorParams(cellPosition);

                const customStyle = await customStyleAccessor(params);

                cellProps = {
                    ...cellProps,
                    customStyle: { ...cellProps.customStyle, ...customStyle },
                };
            }
        }

        const isArchived = this.checkLineArchivedStatus(lineId);

        if (isArchived) {
            cellProps.customStyle = {
                ...cellProps.customStyle,
                opacity: '0.6',
            };
        }

        return cellProps;
    }

    private checkLineArchivedStatus(lineId: string): boolean {
        const lineType = this.getLineType(lineId);

        if (!lineType) {
            return false;
        }

        const { isArchived } =
            lineType === LineType.Creative ? this.getLine(lineId).model : this.getSubLine(lineId).model;

        return isArchived;
    }

    private roundNumber(value: number, digitsAfterComma = 2): string {
        const roundedValue = Math.round(value * 100) / 100;
        const formatedValue = roundedValue.toFixed(digitsAfterComma);

        const [decimalPart, fractionPart] = formatedValue.split('.');

        return `${decimalPart}${fractionPart ? `.${fractionPart}` : ''}`;
    }

    private formatCurrencyValue(value: number | string): string {
        let [decimalPart, fractionPart] = value.toString().split(/[.,]/);

        let sign = '';

        if (lodash.first(decimalPart) === '-') {
            decimalPart = decimalPart.substring(1);
            sign = '-';
        }

        const splittedDecimal = decimalPart.split(/(?=(?:...)*$)/).join(' ');

        return `${sign}${splittedDecimal}${fractionPart ? `,${fractionPart}` : ''}`;
    }
}
