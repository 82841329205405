import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import * as style from './MediaplanStageActions.scss';

import type { StoreState } from '@store';
import { AutopilotStage } from '@store/autopilotRadio/types';

import { Loader, Saver } from '../../modules';
import { setPreloaderStatus, setCurrentStage } from '@store/autopilotRadio/actions';
import { getAutopilot } from '@store/autopilotRadio/selectors';

interface Props {
    canEdit: boolean;
}

export const MediaplanStageActions = ({ canEdit }: Props): JSX.Element => {
    const autopilot = useSelector((state: StoreState) => getAutopilot(state));

    const saver = Saver.getInstance();
    const loader = Loader.getInstance();
    const dispatch = useDispatch();

    const onBackButtonClick = React.useCallback(() => {
        dispatch(setCurrentStage(AutopilotStage.MediaplanSettings));
    }, []);

    const onApproveButtonClick = React.useCallback(async () => {
        const updateParams = { ...autopilot.mediaplan, approved: true };

        dispatch(setPreloaderStatus(true));

        await saver.updateMediaplan(updateParams);
        await loader.loadAutopilot();

        dispatch(setPreloaderStatus(false));
        dispatch(setCurrentStage(AutopilotStage.Budget));
    }, [autopilot]);

    return (
        <div className={style.root}>
            <div className={classNames(style.button, style.backButton)} onClick={onBackButtonClick}>
                Вернуться к параметрам
            </div>

            <div className={style.title}>Утвердите итоговый медиаплан</div>

            <div className={classNames(style.button, style.approveButton)} onClick={onApproveButtonClick}>
                Утвердить
            </div>
        </div>
    );
};
