import * as React from 'react';
import classNames from 'classnames';

import * as style from './SidebarButton.scss';

import { Icon, IconType } from 'sber-marketing-ui';
import { useStore } from './useStore';

export const SidebarButton: React.FC = () => {
    const { openedRightSidebar, openRightSidebar, closeRightSidebar } = useStore();

    const onClick = React.useCallback(() => {
        if (openedRightSidebar) {
            closeRightSidebar();
        } else {
            openRightSidebar();
        }
    }, [openedRightSidebar, openRightSidebar, closeRightSidebar]);

    return (
        <div className={style.root} onClick={onClick}>
            {!openedRightSidebar && (
                <div className={classNames(style.arrowIcon, style.left)}>
                    <Icon type={IconType.ARROW16_STRAIGHT_LEFT} svgSize={16} />
                </div>
            )}

            {openedRightSidebar ? 'Скрыть сайдбар' : 'Показать сайдбар'}

            {openedRightSidebar && (
                <div className={classNames(style.arrowIcon, style.right)}>
                    <Icon type={IconType.ARROW16_STRAIGHT_LEFT} svgSize={16} />
                </div>
            )}
        </div>
    );
};
