import * as React from 'react';
import classNames from 'classnames';
import * as moment from 'moment';

import * as style from './BriefStageWidget.scss';

import {
    OpacityTransition,
    LabeledInput,
    InputType,
    LabeledSelect,
    SelectItem,
    Icon,
    IconType,
} from 'sber-marketing-ui';
import { MultiselectDatepicker } from './MultiselectDatepicker';

interface Props {
    readOnly: boolean;
    loading: boolean;
    blockId: string;
    productId: string;
    budget: string;
    budgetError: string;
    startDate: moment.Moment;
    endDate: moment.Moment;
    datesRange: string;
    targetAgeFrom: string;
    targetAgeTo: string;
    landingPage: string;
    peakDates: Date[];
    yandexMetrikaTarget: string;
    blockItems: SelectItem[];
    productItems: SelectItem[];
    isBudgetFocus: boolean;
    yandexMetrikaTargetItems: SelectItem[];
    selectedYandexMetrikaTargetUpdateDate: string;
    onBlockChange: (value: string) => void;
    onProductChange: (value: string) => void;
    onBudgetChange: (value: string) => void;
    onBudgetFocus: () => void;
    onBudgetBlur: () => void;
    onStartDateChange: (value: moment.Moment) => void;
    onEndDateChange: (value: moment.Moment) => void;
    onTargetAgeFromChange: (value: string) => void;
    onTargetAgeToChange: (value: string) => void;
    onLandingPageChange: (value: string) => void;
    onPeakDatesChange: (value: Date[]) => void;
    onYandexMetrikaTargetChange: (value: string) => void;
}

export const BriefStageWidgetTemplate = ({
    readOnly,
    loading,
    blockId,
    productId,
    budget,
    budgetError,
    startDate,
    endDate,
    datesRange,
    targetAgeFrom,
    targetAgeTo,
    landingPage,
    peakDates,
    yandexMetrikaTarget,
    blockItems,
    productItems,
    isBudgetFocus,
    yandexMetrikaTargetItems,
    selectedYandexMetrikaTargetUpdateDate,
    onBlockChange,
    onProductChange,
    onBudgetChange,
    onBudgetFocus,
    onBudgetBlur,
    onStartDateChange,
    onEndDateChange,
    onTargetAgeFromChange,
    onTargetAgeToChange,
    onLandingPageChange,
    onPeakDatesChange,
    onYandexMetrikaTargetChange,
}: Props): JSX.Element => {
    const today = moment().startOf('day');

    return (
        <OpacityTransition start={!loading}>
            <div className={style.root}>
                <div className={style.title}>Бриф запуска РК в Интернете</div>

                <div className={style.form}>
                    <div className={classNames(style.field, style.line)}>
                        <LabeledSelect
                            title={'Блок'}
                            items={blockItems}
                            selectedValue={blockId}
                            onSelection={onBlockChange}
                            readOnly={readOnly}
                        />
                    </div>

                    <div className={classNames(style.field, style.line)}>
                        <LabeledSelect
                            title={'Продукт'}
                            items={productItems}
                            selectedValue={productId}
                            onSelection={onProductChange}
                            readOnly={readOnly}
                        />
                    </div>

                    <div className={style.line}>
                        <div className={style.smallField}>
                            <LabeledInput
                                title={'Бюджет на весь период'}
                                value={budget}
                                errorMessage={budgetError}
                                displayError={!!budget?.length && !!budgetError?.length && !isBudgetFocus}
                                onValueChange={onBudgetChange}
                                onFocus={onBudgetFocus}
                                onBlur={onBudgetBlur}
                                onlyNumbers
                                readOnly={readOnly}
                            />
                        </div>
                    </div>

                    <div className={style.line}>
                        <div className={style.smallField}>
                            <LabeledInput
                                title={'Старт проекта'}
                                value={startDate}
                                minDate={today}
                                maxDate={endDate}
                                type={InputType.DatePicker}
                                onValueChange={onStartDateChange}
                                readOnly={readOnly}
                            />
                        </div>

                        <span className={style.separator}>—</span>

                        <div className={style.smallField}>
                            <LabeledInput
                                title={'Окончание проекта'}
                                value={endDate}
                                minDate={startDate}
                                type={InputType.DatePicker}
                                onValueChange={onEndDateChange}
                                readOnly={readOnly}
                            />

                            {datesRange && <div className={style.fieldMessage}>Длительность проекта: {datesRange}</div>}
                        </div>
                    </div>

                    <div className={style.line}>
                        <div className={style.smallField}>
                            <LabeledInput
                                title={'Минимальный возраст'}
                                value={targetAgeFrom}
                                onValueChange={onTargetAgeFromChange}
                                onlyNumbers
                                readOnly={readOnly}
                            />
                        </div>

                        <span className={style.separator}>—</span>

                        <div className={style.smallField}>
                            <LabeledInput
                                title={'Максимальный возраст'}
                                value={targetAgeTo}
                                onValueChange={onTargetAgeToChange}
                                onlyNumbers
                                readOnly={readOnly}
                            />
                        </div>
                    </div>

                    <div className={classNames(style.field, style.line)}>
                        <LabeledInput
                            title={'Посадочная страница (необязательно)'}
                            value={landingPage}
                            onValueChange={onLandingPageChange}
                            readOnly={readOnly}
                        />
                    </div>

                    <div className={classNames(style.field, style.line)}>
                        <div className={style.fieldTitle}>Пиковые даты</div>

                        <div className={style.multiselectDatepicker}>
                            <MultiselectDatepicker
                                dates={peakDates}
                                minDate={startDate ? startDate.toDate() : null}
                                maxDate={endDate ? endDate.toDate() : null}
                                onDatesChange={onPeakDatesChange}
                                readOnly={readOnly}
                            />
                        </div>
                    </div>
                </div>

                <div className={style.title}>
                    Цель Яндекс Метрики
                    {/* {true &&
                        <div className={style.titleIndicator}>
                            Согласована
                        </div>
                    } */}
                </div>

                <div className={style.form}>
                    <div className={classNames(style.field, style.line)}>
                        <LabeledSelect
                            title={'Цель'}
                            items={yandexMetrikaTargetItems}
                            selectedValue={yandexMetrikaTarget}
                            onSelection={onYandexMetrikaTargetChange}
                            readOnly={readOnly}
                        />

                        <div className={style.warning}>
                            <div className={style.warningIcon}>
                                <Icon type={IconType.WARNING_IN_CIRCLE} svgSize={16} />
                            </div>
                            Если цель не найдена автоматически, или она не подходит, отправьте запрос на подбор
                            специалисту
                        </div>
                    </div>

                    {/* <div className={style.property}>
                        Последнее использование:&nbsp;
                        <span className={style.propertyValue}>05.02.2022 “Тестирование_Автопилота”</span>
                    </div> */}

                    {selectedYandexMetrikaTargetUpdateDate && (
                        <div className={style.property}>
                            Дата верефикации:&nbsp;
                            <span className={style.propertyValue}>
                                {formatDate(selectedYandexMetrikaTargetUpdateDate)}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </OpacityTransition>
    );
};

function formatDate(date: string): string {
    return moment(date).format('DD.MM.YYYY');
}
