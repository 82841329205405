import * as React from 'react';
import classNames from 'classnames';

import * as styles from './Divider.scss';

export interface DividerProps<E extends HTMLHRElement | HTMLFieldSetElement> extends React.HTMLProps<E> {
    vertical?: boolean;
    accent?: boolean;
    sticky?: boolean;
    hrProps?: JSX.IntrinsicElements['hr'];
    fieldsetProps?: JSX.IntrinsicElements['fieldset'];
    legendProps?: JSX.IntrinsicElements['legend'];
}

export function Divider<E extends HTMLHRElement | HTMLFieldSetElement>({
    className,
    children,
    vertical,
    accent,
    sticky,
    hrProps,
    fieldsetProps,
    legendProps,
    ...props
}: DividerProps<E>) {
    const rootClassName = classNames(
        styles.root,
        vertical && styles.vertical,
        accent && styles.accent,
        sticky && styles.sticky,
        className,
    );

    if (!children) {
        return <hr {...(props as React.HTMLProps<HTMLHRElement>)} {...hrProps} className={rootClassName} />;
    }

    return (
        <fieldset {...(props as React.HTMLProps<HTMLFieldSetElement>)} {...fieldsetProps} className={rootClassName}>
            <legend className={styles.legend} {...legendProps}>
                {children}
            </legend>
        </fieldset>
    );
}
