import * as lodash from 'lodash';
import { FormField } from 'sber-marketing-ui';
import * as moment from 'moment';

import type {
    Brief,
    BriefScheme,
    UserResponseParams,
    FieldValue,
    BriefFile,
    BriefBlock,
    SwitchValue,
    BriefField,
    OrganizationView,
} from 'sber-marketing-types/frontend';
import { FieldType } from 'sber-marketing-types/frontend';
import { ActivityBudget, BudgetItem, Month } from '@mrm/budget';
import { PlainDictionary, DictionaryType } from '@mrm/dictionary';

export interface PageState {
    activityBudget?: ActivityBudget;
    budgetItems?: BudgetItem[];
    activityForm?: FormData;
    budgetItemForms?: FormData[];
    usedDictionaries?: GroupedDictionaries;
    availableDictionaries?: GroupedDictionaries;
    users?: UserResponseParams[];
    isNameInputFocused?: boolean;
    originalBriefs?: lodash.Dictionary<Brief>;
    changedBriefs?: lodash.Dictionary<Brief>;
    briefsLoading?: lodash.Dictionary<{ id: string; loading: boolean }>;
    briefsSchemes?: BriefScheme[];
    filesToRemove?: FileToRemove[];
    displayValidation?: boolean;
    briefOrganizations?: OrganizationView[];
    activityAlreadyExists?: boolean;
    yearForDataValidation?: number;
}

export interface FormData {
    id: string;
    fields: FormField[];
    briefId?: string;
    collapsed?: boolean;
    deleted?: boolean;
    isNew?: boolean;
    activityNameIsFocused?: boolean;
    transferDestinationId?: string;
    sourceId?: string;
    tagsEditorId?: string;
}

export interface FileToRemove {
    fileName: string;
    fieldId: string;
}

export interface FieldChangeParams {
    briefId: string;
    fieldId: string;
    uniqId?: number;
    parentUniqId?: number;
    value: FieldValue;
}

export interface BriefLoading {
    briefId: string;
    loading: boolean;
}

export interface BriefsData {
    originalBriefs: lodash.Dictionary<Brief>;
    changedBriefs: lodash.Dictionary<Brief>;
    briefsLoading: lodash.Dictionary<{ id: string; loading: boolean }>;
    briefsSchemes: BriefScheme[];
    budgetItemForms?: FormData[];
}

export interface GroupedDictionaries {
    byId: Record<string, PlainDictionary>;
    byType: Partial<Record<DictionaryType, PlainDictionary[]>>;
}

export interface UnlockableInputValue {
    text: string;
    unlocked: boolean;
}

export type FieldInput = string | string[] | moment.Moment | SwitchValue | UnlockableInputValue | BriefFile[];

export type { BriefFile, Brief, BriefBlock, FieldValue, BriefField, BriefScheme };
export { FieldType };

export const activityBudgetRequiredFields = ['name'];

export const budgetItemRequiredFields = ['sapComment'];

export const budgetItemRequiredToCreateFields = [
    'sapComment',
    'responsibles',
    'businessTarget',
    'customerName',
    'startDate',
    'endDate',
    'responsibles',
    'month',
    'plannedAmount',
    DictionaryType.Regionality,
    DictionaryType.ActivityType,
    DictionaryType.Direction,
    DictionaryType.Tool,
    DictionaryType.Block,
    DictionaryType.Division,
    DictionaryType.Item,
    DictionaryType.Segment,
    DictionaryType.Product,
];

export const SBER_ORGANIZATIONS_IDS = [
    'e2ee630b-8fe9-4e68-a6cf-ea3c9d2709e8',
    '1f11c60a-c021-4092-8697-67c83cb5d5d9',
    '68a38c6a-79f7-49b5-9356-b779fbbb9b64',
    '6aa41a85-1716-43a7-9c91-9933c9b945a4',
];

export const MONTHS = [
    Month.Jan,
    Month.Feb,
    Month.Mar,
    Month.Apr,
    Month.May,
    Month.Jun,
    Month.Jul,
    Month.Aug,
    Month.Sept,
    Month.Oct,
    Month.Nov,
    Month.Dec,
];
