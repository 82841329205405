import * as React from 'react';
import { graphql } from 'react-apollo';

import { QueryVariables, QueryProps } from './types';
import { LINK_ACTIVITY_MUTATION } from './graphql';

interface ExternalProps {}
interface Props extends QueryProps {}

export const withLinkActivity = (WrappedComponent: any) =>
    graphql<ExternalProps>(LINK_ACTIVITY_MUTATION, {
        options: () => ({
            fetchPolicy: 'no-cache',
        }),
    })(
        // @ts-ignore
        class extends React.Component<Props> {
            constructor(props: Props) {
                super(props);

                this.linkActivity = this.linkActivity.bind(this);
            }
            public render() {
                return React.createElement(WrappedComponent, {
                    ...this.props,
                    linkActivityLoading: this.props.result.loading,
                    linkActivity: this.linkActivity,
                });
            }

            private async linkActivity({ budgetItemId, activityId, approverId }: QueryVariables): Promise<void> {
                await this.props.mutate({
                    variables: {
                        budgetItemId,
                        activityId,
                        approverId,
                    },
                });
            }
        },
    );
