import * as ExcelJS from 'exceljs';
import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { BudgetStatus, ImportBudgetItem } from '@mrm/budget';

import { BudgetItemApi } from '@api';

import { StoreState } from '@store';
import { getBudgetUserConfig } from '@store/userConfig/budget';
import { XLSXImportValidationError } from '@store/budgetPlanning/types';
import { NotificationType, NotificationActionType, setNotification } from '@store/common';

import * as asyncActions from '@store/budgetPlanning/actions/async';
import * as syncActions from '@store/budgetPlanning/actions/sync';

import { TableLoader } from '../../../../../modules/budget/BudgetPage/BudgetPlanning/modules';

import { WorkbookValidator, DictionariesValidator, RequiredFieldsValidator } from './validators';
import { EXCEL_FILE_TYPE } from '../types';
import { XLSXImportError } from './validationErrors/XLSXImportError';
import { FetchedData } from './FetchedData';
import { DataReader } from './DataReader';
import { LinkedValuesToDataTransformer } from './LinkedValuesToDataTransformer';
import { RequestParamsGenerator } from './RequestParamsGenerator';
import { SuccessMessage } from './SuccessMessage';

const BATCH_SIZE = 100;

function validateFile(file: File): void {
    if (file.type !== EXCEL_FILE_TYPE) {
        throw new XLSXImportError({
            error: XLSXImportValidationError.WrongFileType,
        });
    }
}

function generateWorkbook(file: File): Promise<ExcelJS.Workbook> {
    const workbook = new ExcelJS.Workbook();
    const fileReader = new FileReader();

    fileReader.readAsArrayBuffer(file);

    return new Promise<ExcelJS.Workbook>((resolve) => {
        fileReader.onloadend = async () => {
            await workbook.xlsx.load(fileReader.result as Buffer);
            resolve(workbook);
        };
    });
}

function getData(workbook: ExcelJS.Workbook, state: StoreState): FetchedData[] {
    const ditionaryValuesTransformer = new LinkedValuesToDataTransformer(state);

    return validateData(ditionaryValuesTransformer.transform(DataReader.read(workbook)), state);
}

function validateData(data: FetchedData[], state: StoreState): FetchedData[] {
    const dictionariesValidator = new DictionariesValidator(state);

    return dictionariesValidator.validate(RequiredFieldsValidator.validate(data));
}

async function createBudgetItems(budgetItems: ImportBudgetItem[], state: StoreState): Promise<void> {
    const budgetId = getBudgetUserConfig(state).budgetStatus[BudgetStatus.Plan].budgetId;

    for (let i = 0; i < budgetItems.length; i += BATCH_SIZE) {
        const budgetItemParams = budgetItems.slice(i, i + BATCH_SIZE);

        await BudgetItemApi.importBudgetItemsFromExcel({
            budgetId,
            budgetItemParams,
        });
    }
}

export const loadXLSXContent = bindThunkAction<StoreState, File, void, Error>(
    asyncActions.downloadXSLSXTemplate,
    async (file, dispatch, getState) => {
        const state = getState();
        const tableLoader = TableLoader.getInstance();

        try {
            dispatch(syncActions.setPreloaderStatus(true));

            validateFile(file);
            const workbook = await generateWorkbook(file);
            const validator = new WorkbookValidator(workbook);
            validator.validate();

            const data = getData(workbook, state);

            const budgetItemParams = RequestParamsGenerator.generateParams(data);

            await createBudgetItems(budgetItemParams, state);
            await tableLoader.updateAfterImportFromXLSX(budgetItemParams.map((budgetItem) => budgetItem.id));

            dispatch(
                setNotification({
                    type: NotificationType.SUCCESS,
                    typeAction: NotificationActionType.XLSX_IMPORT_SUCCESSFUL,
                    comment: SuccessMessage({ rowsCount: budgetItemParams.length }),
                }),
            );
        } catch (e) {
            if (e instanceof XLSXImportError) {
                dispatch(
                    syncActions.setXLSXImportErrorState({
                        ...e.state,
                        fileName: file.name,
                    }),
                );
            } else {
                throw e;
            }
        } finally {
            dispatch(syncActions.setPreloaderStatus(false));
        }
    },
);
