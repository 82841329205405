import * as React from 'react';
import { connect } from 'react-redux';

import { StoreState } from '@store';
import { getSelectedCard } from '@store/dashboardPage/selectors';

interface Props extends Partial<MapProps> {
    children?: (params: RenderParams) => React.ReactNode | React.ReactNode[];
}

interface MapProps {
    cardId: string;
    activityId: number;
}

interface RenderParams extends Partial<MapProps> {}

@(connect(mapStateToProps, null) as any)
export class WithStore extends React.PureComponent<Props> {
    public render() {
        const { cardId, activityId } = this.props;

        return this.props.children({
            cardId,
            activityId,
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const selectedCard = getSelectedCard(state);

    if (!selectedCard) {
        return {
            cardId: null,
            activityId: null,
        };
    }

    return {
        cardId: selectedCard.id,
        activityId: Number(selectedCard.activityId),
    };
}
