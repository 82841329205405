import * as React from 'react';

import { FileCard } from './FileCard';
import { UploadButton } from './UploadButton';

import type { CreativeRequestTenderDocumentProtocolFile } from '../types';
import * as style from './styles.scss';

interface Props {
    file: CreativeRequestTenderDocumentProtocolFile;
    disabledUpload: boolean;
    disabledDownload: boolean;
    disabledRemove: boolean;
    onAdd: (file: File) => void;
    onRemove: () => void;
}

export const FileInput: React.FC<Props> = ({
    file,
    disabledUpload,
    disabledDownload,
    disabledRemove,
    onAdd,
    onRemove,
}) => {
    return (
        <div className={style.root}>
            {file && (
                <FileCard
                    id={file.id}
                    name={file.name}
                    originName={file.originName}
                    size={Number(file.size) || 0}
                    type={file.type}
                    key={file.id}
                    showDeleteButton={!disabledRemove}
                    showDownloadButton={!disabledDownload}
                    onDeleteButtonClick={onRemove}
                />
            )}

            {!disabledUpload && <UploadButton addFile={onAdd} />}
        </div>
    );
};
