import * as React from 'react';
import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import { getFromLinesWithNegativePlanReserveDiff } from '@store/budgetExecution/budgetTransferMenu';

import { CellDescription, CellDescriptionTemplates, CellDescriptionThemes } from '../../CellDescription';

import * as styles from './LinesWithNegativeBalance.scss';

export function LinesWithNegativeBalance(): JSX.Element {
    const cells = useSelector((state: StoreState) => getFromLinesWithNegativePlanReserveDiff(state));

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'internalTransitionLinesWithNegativeBalance',
            }}
        >
            {cells.map((cell) => (
                <div key={cell.lineId} className={styles.item}>
                    <CellDescription
                        qaId="lineWithNegativeBalance"
                        cell={cell}
                        template={CellDescriptionTemplates.NegativeLineBalanceWarning}
                        theme={CellDescriptionThemes.WhiteWithShadow}
                    />
                </div>
            ))}
        </div>
    );
}
