// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-channel-components-ChannelCloseIcon-_ChannelCloseIcon__root{transition:color .3s}@media (pointer: fine){.src-client-modules-channel-components-ChannelCloseIcon-_ChannelCloseIcon__root:hover{color:#e63900}}.src-client-modules-channel-components-ChannelCloseIcon-_ChannelCloseIcon__dropdown{width:242px;right:auto;font-size:14px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/channel/components/ChannelCloseIcon/ChannelCloseIcon.scss"],"names":[],"mappings":"AAAA,gFACE,oBAAqB,CAErB,uBAHF,sFAKM,aAAc,CACf,CAIL,oFACE,WAAY,CACZ,UAAW,CACX,cAAe","sourcesContent":[".root {\n  transition: color .3s;\n\n  @media (pointer: fine) {\n    &:hover {\n      color: #e63900;\n    }\n  }\n}\n\n.dropdown {\n  width: 242px;\n  right: auto;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-channel-components-ChannelCloseIcon-_ChannelCloseIcon__root",
	"dropdown": "src-client-modules-channel-components-ChannelCloseIcon-_ChannelCloseIcon__dropdown"
};
export default ___CSS_LOADER_EXPORT___;
