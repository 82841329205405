import gql from 'graphql-tag';

export const WITH_BUDGET_ITEM_LINKS_QUERY = gql`
    query getBudgetItemLinks($linkIds: [String]!) {
        links: getProjectLinkBudgetItemApprovalRequests(filter: { id: $linkIds }) {
            nodes {
                id
                budgetItem {
                    serialNumber
                }
                project {
                    id
                }
                status
            }
        }
    }
`;
