// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-channel-components-Channels-_Channels__root{align-items:center;z-index:3}.src-client-modules-channel-components-Channels-_Channels__add{font-size:12px;margin-bottom:8px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/channel/components/Channels/Channels.scss"],"names":[],"mappings":"AAAA,gEACE,kBAAmB,CACnB,SAAU,CACX,+DAGC,cAAe,CACf,iBAAkB","sourcesContent":[".root {\n  align-items: center;\n  z-index: 3; // FIXME\n}\n\n.add {\n  font-size: 12px;\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-channel-components-Channels-_Channels__root",
	"add": "src-client-modules-channel-components-Channels-_Channels__add"
};
export default ___CSS_LOADER_EXPORT___;
