import * as React from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { StyledPopup, Button_redesign, ButtonTheme_redesign, AnimatedEllipsis } from 'sber-marketing-ui';

import { DataUpdateStrategy } from '@store/tagsEditor';

import { TagsPreviewAndEditor } from '@modules/tags/TagsPreviewAndEditor';
import { TaskExecutorSuggest } from '../TaskExecutorSuggest';

import * as styles from './TaskExecutor.scss';

export interface TaskExecutorProps {
    taskId: string;
    isStatusButtonDisable: boolean;
    isChangeExecutorButtonDisable: boolean;
    hasDescription: boolean;
    hasExecutor: boolean;
    hasBudgetApproval: boolean;
    buttonLabel: string;
    /** Executor input disabled flag */
    isExecutorInputDisabled: boolean;
    /** Identifier of executor */
    executorId: number | null | undefined;
    /** Department executor */
    departmentExecutorId: string;
    /** If "true" no description error modal will be opened */
    isNoDescriptionModalOpened: boolean;
    /** Additional CSS class */
    className?: string;
    showAddExecutorButton: boolean;
    departmentsLoaded: boolean;
    isRequestInProgress: boolean;
    tagsEditorId: string;
    /** No description error modal close handler */
    onNoDescriptionModalClose(): void;
    /** Close task button click handler */
    onChangeTaskStatusClick(): any;
    /** Change task executor button click handler */
    onChangeExecutorClick(): any;
    /** Executor input value change handler */
    onSuggestItemSelect(): void;
    /** Task brief button click handler */
    onTaskBriefButtonClick(): void;
}

export function TaskExecutor({
    taskId,
    isStatusButtonDisable,
    isChangeExecutorButtonDisable,
    buttonLabel,
    onChangeTaskStatusClick,
    onChangeExecutorClick,
    onSuggestItemSelect,
    onTaskBriefButtonClick,
    departmentExecutorId,
    isExecutorInputDisabled,
    executorId,
    className,
    isNoDescriptionModalOpened,
    onNoDescriptionModalClose,
    showAddExecutorButton,
    departmentsLoaded,
    isRequestInProgress,
    tagsEditorId,
    hasDescription,
    hasExecutor,
    hasBudgetApproval,
}: TaskExecutorProps): JSX.Element {
    return (
        <div className={classNames(styles.root, !isNil(className) && className)}>
            <div className={styles.buttons}>
                <div className={styles.button}>
                    <Button_redesign
                        qaId="taskBriefButton"
                        onClick={onTaskBriefButtonClick}
                        theme={ButtonTheme_redesign.GhostRoundedBlack}
                    >
                        Бриф задачи
                    </Button_redesign>
                </div>

                <div className={styles.button}>
                    <Button_redesign
                        qaId="taskStatusChangeButton"
                        onClick={isRequestInProgress ? null : onChangeTaskStatusClick}
                        disabled={isStatusButtonDisable}
                        theme={ButtonTheme_redesign.GhostRounded}
                    >
                        {isRequestInProgress ? <AnimatedEllipsis text={buttonLabel} /> : buttonLabel}
                    </Button_redesign>
                </div>
            </div>

            <TagsPreviewAndEditor
                id={tagsEditorId}
                taskId={taskId}
                dataUpdateStrategy={DataUpdateStrategy.Immediate}
                className={styles.tagsEditor}
            />

            {departmentsLoaded && (
                <TaskExecutorSuggest
                    showAddExecutorButton={showAddExecutorButton}
                    disabled={isExecutorInputDisabled}
                    executorId={executorId}
                    departmentExecutorId={departmentExecutorId}
                    buttonDisabled={isChangeExecutorButtonDisable}
                    onSuggestItemSelect={onSuggestItemSelect}
                    onChangeExecutorClick={onChangeExecutorClick}
                />
            )}

            {isNoDescriptionModalOpened && (
                <StyledPopup
                    title={
                        !hasDescription
                            ? 'Нет описания задачи'
                            : !hasExecutor
                            ? 'Нет исполнителя задачи'
                            : !hasBudgetApproval
                            ? 'Нет полей бюджета задачи'
                            : 'Нет всех полей задачи'
                    }
                    fullMessage="Для отправления задачи в работу, необходимо заполнить описание"
                    firstButtonText="OK"
                    firstButtonHandler={onNoDescriptionModalClose}
                />
            )}
        </div>
    );
}
