import * as React from 'react';
import { isEmpty } from 'lodash';
import { CardGrid, CardStyle, Preloader, Icon, IconType, ImageGallery, ImageGalleryItem } from 'sber-marketing-ui';

import { UploadErrorParams } from 'client/api/file-api';

import { FileToRemove } from '@store/brief/types';
import { FileAssetWithPreview } from '@common/FileAssetWithPreview';
import { FileAsset } from '@store/commonTypes';
import { Utils } from '@common/Utils';

import { FileUploader } from './FileUploader';

import * as style from './FileBlock.scss';

const CARD_STYLE: CardStyle = {
    width: 120,
    height: 154,
    marginHorizontal: 18,
    marginVertical: 16,
};

export interface FileBlockProps {
    index?: number;
    fieldId: string;
    disabled?: boolean;
    name?: string;
    files: FileAsset[];
    filesToRemove: FileToRemove[];
    loading: boolean;
    isGalleryOpen: boolean;
    startIndex: number;
    briefId: string;
    onUploadSuccess: (params: FileAsset) => void;
    onDeleteButtonClick: (asset: FileAsset) => void;
    onUnmarkFileForDeleteClick: (name: string) => void;
    onUploadStart: (asset: FileAsset) => void;
    onUploadError: (error: UploadErrorParams, asset: FileAsset) => void;
    onGalleryClick: (startIndex: number) => void;
    onGalleryClose: () => void;
}

export function FileBlock({
    index,
    fieldId,
    disabled,
    name,
    files = [],
    briefId,
    filesToRemove,
    onUploadSuccess,
    onDeleteButtonClick,
    onUnmarkFileForDeleteClick,
    onUploadStart,
    loading,
    startIndex,
    isGalleryOpen,
    onGalleryClick,
    onGalleryClose,
    onUploadError,
}: FileBlockProps): JSX.Element {
    const data = mapGalleryItems(files);
    const items: ImageGalleryItem[] = data.map(([item]) => item);
    const indexes: number[] = data.map(([_, index]) => index);

    function renderFileCard(file: FileAsset, index: number) {
        const markedForRemoval = filesToRemove.findIndex((fileToRemove) => fileToRemove.fileName == file.name) != -1;

        return (
            <div
                className={style.fileCard}
                key={file.id}
                {...{
                    'qa-id': 'briefBlockFileBlockFieldFileItem',
                    'qa-label': `${file.originName}.${file.type}`,
                }}
            >
                <div className={style.filePreview}>
                    {markedForRemoval ? (
                        <div
                            className={style.restoreFile}
                            onClick={() => onUnmarkFileForDeleteClick(file.name)}
                            {...{
                                'qa-id': 'briefBlockFileBlockFieldRestoreButton',
                            }}
                        >
                            Восстановить файл
                        </div>
                    ) : (
                        <FileAssetWithPreview
                            {...(file as any)}
                            briefId={briefId}
                            canDelete={true}
                            onRemove={onDeleteButtonClick}
                            onGalleryClick={() => onGalleryClick(indexes.indexOf(index))}
                        />
                    )}
                </div>
            </div>
        );
    }

    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'briefBlockField',
                'qa-index': index,
                'qa-field-type': 'FileBlock',
            }}
        >
            <div className={style.topLine}>
                <div className={style.fieldName}>{name || 'Новое поле файлов'}</div>

                {!disabled && (
                    <div className={style.fileUploader}>
                        <FileUploader
                            fieldId={fieldId}
                            briefId={briefId}
                            onUploadSuccess={onUploadSuccess}
                            onUploadStart={onUploadStart}
                            onUploadError={onUploadError}
                        >
                            <div
                                className={style.addFileButton}
                                {...{
                                    'qa-id': 'briefBlockFileBlockFieldOpenMenuButton',
                                }}
                            >
                                <div className={style.buttonTitle}>Загрузить файл</div>

                                <div className={style.buttonIcon}>
                                    <Icon type={IconType.PLUS_20} svgSize={20} color={'#19bb4f'} />
                                </div>
                            </div>
                        </FileUploader>
                    </div>
                )}
            </div>

            <div
                className={style.fileList}
                style={{ minHeight: loading ? CARD_STYLE.height : null }}
                {...{
                    'qa-id': 'briefBlockFileBlockFieldFileList',
                }}
            >
                {files.length > 0 && <CardGrid cards={files.map(renderFileCard)} cardStyle={CARD_STYLE} />}

                {loading && (
                    <div className={style.preloader}>
                        <Preloader />
                    </div>
                )}
            </div>

            {isGalleryOpen && <ImageGallery items={items} startIndex={startIndex} onOutsideClick={onGalleryClose} />}
        </div>
    );
}

const mapGalleryItems = (assets: FileAsset[]): [ImageGalleryItem, number][] =>
    assets.map(toTuple).filter(filterItem).map(mapGalleryItem);

const toTuple = (asset: FileAsset, index: number): [FileAsset, number] => [asset, index];

const filterItem = ([{ fullSizeUrl }]: [FileAsset, number]): boolean => !isEmpty(fullSizeUrl);

const mapGalleryItem = ([{ id, previewUrl, fullSizeUrl, originName, type }, index]: [FileAsset, number]): [
    ImageGalleryItem,
    number,
] => [
    {
        id,
        previewUrl,
        originalUrl: fullSizeUrl,
        name: originName,
        type: getGalleryItemType(type),
        extension: type,
    },
    index,
];

const getGalleryItemType = (type: string): 'image' | 'pdf' | 'video' | 'audio' => {
    if (type === 'pdf') {
        return 'pdf';
    }
    if (Utils.isVideo(type)) {
        return 'video';
    }
    if (Utils.isAudio(type)) {
        return 'audio';
    }
    return 'image';
};
