import * as React from 'react';

import { CorrectionStatus } from '@mrm/budget';

import { CommonFondsChange, CorrectionCardHeader, SubPramList, CorrectionCardComment, RejectComment } from '../common';

import * as style from './OutcomeExternalCorrectionCard.scss';

const STATUS_COLOR = {
    [CorrectionStatus.NeedApproving]: '#f5a623',
    [CorrectionStatus.Approved]: '#19bb4f',
    [CorrectionStatus.Rejected]: '#e63901',
};

export interface CorrectionData {
    dictionaryItem: string;
    comment: string;
}

interface PlanData {
    delta: number;
    beforeSumFonds: number;
}

export interface ParticipatorData {
    monthFond: {
        month: string;
        delta: number;
        beforeFond: number;
        afterFond: number;
    };
    allFond: {
        delta: number;
        beforeFond: number;
    };
    name: string;
    fields: ParticipatorField[];
    comment: string;
}

export interface ParticipatorField {
    name: string;
    value: React.ReactText;
    isWide?: boolean;
}

interface Props {
    serialNumber: number;
    authorName: string;
    approverName: string;
    creationDate: string;
    donorData: ParticipatorData;
    correctionData: CorrectionData;
    status: CorrectionStatus;
    rejectComment: string;
}

export const OutcomeExternalCorrectionCard = ({
    serialNumber,
    authorName,
    approverName,
    creationDate,
    correctionData,
    donorData,
    status,
    rejectComment,
}: Props): JSX.Element => {
    return (
        !!donorData && (
            <div className={style.root}>
                <CorrectionCardHeader
                    title={'Внешняя корректировка плана'}
                    serialNumber={serialNumber}
                    authorName={authorName}
                    approverName={approverName}
                    creationDate={creationDate}
                    status={status}
                />

                {rejectComment && <RejectComment text={rejectComment} />}

                <div className={style.participators}>
                    <div
                        className={style.participatorsRow}
                        {...{
                            'qa-id': 'correctionCardParticipatorsInfo',
                        }}
                    >
                        <div
                            className={style.participatorsCell}
                            {...{
                                'qa-id': 'correctionCardParticipatorLeftColumn',
                            }}
                        >
                            <MonthPlanSubInfo
                                name={donorData.name}
                                month={donorData.monthFond.month}
                                beforeFonds={donorData.monthFond.beforeFond}
                                delta={donorData.monthFond.delta}
                            />
                        </div>
                        <div
                            className={style.participatorsCell}
                            {...{
                                'qa-id': 'correctionCardParticipatorRightColumn',
                            }}
                        >
                            <PlanSubInfo
                                beforeSumFonds={donorData.allFond.beforeFond}
                                delta={donorData.allFond.delta}
                            />
                        </div>
                    </div>
                    <div
                        className={style.participatorsRow}
                        {...{
                            'qa-id': 'correctionCardCorrectionParams',
                        }}
                    >
                        <div
                            className={style.participatorsCell}
                            {...{
                                'qa-id': 'correctionCardCorrectionParamsLeftColumn',
                            }}
                        >
                            <div className={style.subInfoContent}>
                                <SubPramList
                                    supParams={[
                                        {
                                            name: 'Статья получателя',
                                            value: correctionData.dictionaryItem,
                                            isWide: true,
                                        },
                                    ]}
                                />
                                <SubPramList
                                    supParams={[
                                        {
                                            name: 'Комментарий к корректировке',
                                            value: <CorrectionCardComment text={correctionData.comment} />,
                                            isWide: true,
                                            notCropContent: true,
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                        <div
                            className={style.participatorsCell}
                            {...{
                                'qa-id': 'correctionCardCorrectionParamsRightColumn',
                            }}
                        >
                            <div className={style.subInfoContent}>
                                <SubPramList supParams={donorData.fields} />
                                {donorData.comment && (
                                    <SubPramList
                                        supParams={[
                                            {
                                                name: 'Комментарий к строке',
                                                value: <CorrectionCardComment text={donorData.comment} />,
                                                isWide: true,
                                                notCropContent: true,
                                            },
                                        ]}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={style.status} style={{ backgroundColor: STATUS_COLOR[status] }} />
            </div>
        )
    );
};

interface MonthPlanSubInfoProps {
    name: string;
    month: string;
    beforeFonds: number;
    delta: number;
}

const MonthPlanSubInfo: React.FC<MonthPlanSubInfoProps> = (data) => (
    <div className={style.subInfo}>
        <div className={style.subInfoTitle}>{data.name}</div>

        <CommonFondsChange title={'Бюджет'} month={data.month} before={data.beforeFonds} delta={data.delta} />
    </div>
);

const PlanSubInfo: React.FC<PlanData> = (data) => (
    <div className={style.subInfo}>
        <div className={style.commonFondsChangeWrapper}>
            <CommonFondsChange title={'Плановый бюджет по строке'} before={data.beforeSumFonds} delta={data.delta} />
        </div>
    </div>
);
