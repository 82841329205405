import * as React from 'react';
import * as lodash from 'lodash';
import * as moment from 'moment';

import {
    BudgetItemCorrection,
    CorrectionStatus,
    BudgetItem,
    Persone,
    DictionaryType,
    Dictionary,
    User,
} from '../types';

import { ColumnName } from '@store/budgetExecution/types';

import { BudgetItemCorrectionCard, PlanData, ChangedAttribute } from './BudgetItemCorrectionCard';
import { ColumnsList } from '../../../../../../../budget/BudgetPage/BudgetExecution/ColumnsConfig';

import { Money, MoneyFormatter, Utils } from '@common/Utils';

const enum BudgetItemEditableParamsKey {
    ResponsibleIds = 'responsibleIds',
    BusinessTarget = 'businessTarget',
    CustomerName = 'customerName',
    Comment = 'comment',
    ExpertComment = 'expertComment',
    SapComment = 'sapComment',
    SapNumber = 'sapNumber',
    SapZns = 'sapZns',
    RealizationStart = 'realizationStart',
    RealizationEnd = 'realizationEnd',
    PreviousFunds = 'previousFunds',
    DictionaryIds = 'dictionaryIds',
}

const targetEditableParamsKeys = [
    BudgetItemEditableParamsKey.ResponsibleIds,
    BudgetItemEditableParamsKey.BusinessTarget,
    BudgetItemEditableParamsKey.CustomerName,
    BudgetItemEditableParamsKey.Comment,
    BudgetItemEditableParamsKey.ExpertComment,
    BudgetItemEditableParamsKey.SapComment,
    BudgetItemEditableParamsKey.SapNumber,
    BudgetItemEditableParamsKey.SapZns,
    BudgetItemEditableParamsKey.RealizationStart,
    BudgetItemEditableParamsKey.RealizationEnd,
    BudgetItemEditableParamsKey.PreviousFunds,
    BudgetItemEditableParamsKey.DictionaryIds,
];

type BuilderChangeAttributeList = {
    [P in BudgetItemEditableParamsKey]: ChangedAttribute[];
};

const columnNameMap = {
    [BudgetItemEditableParamsKey.ResponsibleIds]: ColumnName.Responsible,
    [BudgetItemEditableParamsKey.BusinessTarget]: '',
    [BudgetItemEditableParamsKey.CustomerName]: '',
    [BudgetItemEditableParamsKey.Comment]: ColumnName.Comment,
    [BudgetItemEditableParamsKey.ExpertComment]: '',
    [BudgetItemEditableParamsKey.SapComment]: ColumnName.SapComment,
    [BudgetItemEditableParamsKey.SapNumber]: ColumnName.SapCorrectionNumber,
    [BudgetItemEditableParamsKey.SapZns]: ColumnName.SapZns,
    [BudgetItemEditableParamsKey.RealizationStart]: ColumnName.StartDate,
    [BudgetItemEditableParamsKey.RealizationEnd]: ColumnName.EndDate,
    [BudgetItemEditableParamsKey.PreviousFunds]: ColumnName.LastYearFact,
};

const PARAMS_TITLES = {
    [BudgetItemEditableParamsKey.ResponsibleIds]: 'Руководители проекта (Инициаторы от ДМиК)',
    [BudgetItemEditableParamsKey.BusinessTarget]: 'Бизнес-цель',
    [BudgetItemEditableParamsKey.CustomerName]: 'ФИО заказчика',
    [BudgetItemEditableParamsKey.Comment]: 'Примечания',
    [BudgetItemEditableParamsKey.ExpertComment]: 'Комментарий эксперта',
    [BudgetItemEditableParamsKey.SapComment]: 'Название проекта (Комментарий для SAP)',
    [BudgetItemEditableParamsKey.SapNumber]: 'Номер корректировки из САП',
    [BudgetItemEditableParamsKey.SapZns]: 'Номер ЗНС из САП',
    [BudgetItemEditableParamsKey.RealizationStart]: 'Дата старта',
    [BudgetItemEditableParamsKey.RealizationEnd]: 'Дата окончания',
    [BudgetItemEditableParamsKey.PreviousFunds]: 'Факт предыдщий год, тыс. ₽',
    [DictionaryType.Block]: 'Блок',
    [DictionaryType.Division]: 'МВЗ / Дивизион',
    [DictionaryType.ActivityType]: 'Тип проекта',
    [DictionaryType.CostCenter]: 'Наименование ЦЗ (центр затрат)',
    [DictionaryType.Channel]: 'Канал',
    [DictionaryType.CostDirection]: 'Направление затрат',
    [DictionaryType.LocationDriver]: 'Драйвер аллокации (Бизнес-блок)',
    [DictionaryType.IFKV]: 'ИФКВ',
    [DictionaryType.Objective]: 'Задача',
    [DictionaryType.Regionality]: 'ЦА/ТБ (территория)',
    [DictionaryType.Territory]: 'Территория',
    [DictionaryType.ResourceUsage]: 'Порядок использования ресурсов',
    [DictionaryType.Segment]: 'Сегмент',
    [DictionaryType.Subcategory]: 'Подкатегория',
    [DictionaryType.Direction]: 'Направление',
    [DictionaryType.Tool]: 'Инструмент',
    [DictionaryType.Item]: 'Статья',
    [DictionaryType.Resource]: 'Ресурс',
    [DictionaryType.Product]: 'Продукт',
};

interface Props extends BudgetItemCorrection {
    dictionaries: Dictionary[];
    users: User[];
}

export class BudgetItemCorrectionCardContainer extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        const { status, serialNumber, comment, creationTime, author } = this.props;

        const authorName = this.formatUserName(author);
        const approverName = this.getApproverName();

        return React.createElement(BudgetItemCorrectionCard, {
            serialNumber,
            authorName,
            approverName,
            creationDate: this.formatDate(creationTime),
            planData: this.makePlanData(),
            changedAttributes: this.makeChangedAttributes(),
            status,
            rejectComment: comment,
        });
    }

    private getApproverName(): string {
        const { status, expert, approver } = this.props;

        if (status === CorrectionStatus.NeedApproving) {
            return expert ? this.formatUserName(expert) : 'не задан';
        }

        return approver ? this.formatUserName(approver) : 'не задан';
    }

    private makePlanData(): PlanData {
        const { budgetItemCurrent: budgetItemsCurrent } = this.props;

        const budgetItem = lodash.first(budgetItemsCurrent);

        // TODO В ответе graphQL висит поле __typename и для перебора свойст обхекта оно мешает
        delete budgetItem.plannedFunds['__typename'];
        const currentSumPlannedFunds = lodash.values(budgetItem.plannedFunds).reduce<number>((acc, fond) => {
            return acc + fond;
        }, 0);

        const fields = [
            {
                name: 'ID план',
                value: budgetItem.donors && budgetItem.donors.join(', '),
            },
            {
                name: 'ID исполнение',
                value: budgetItem.serialNumber,
            },
            {
                name: 'Тип проекта',
                value:
                    budgetItem.dictionary &&
                    budgetItem.dictionary.activity_type &&
                    budgetItem.dictionary.activity_type.value,
            },
            {
                name: 'Направление',
                value:
                    budgetItem.dictionary && budgetItem.dictionary.direction && budgetItem.dictionary.direction.value,
            },
            {
                name: 'Дата запуска',
                value: budgetItem.realizationStart && this.formatDate(budgetItem.realizationStart),
            },
            {
                name: 'Дата окончания',
                value: budgetItem.realizationEnd && this.formatDate(budgetItem.realizationEnd),
            },
            {
                name: 'ЦА/ТБ (Территория)',
                value: budgetItem.dictionary?.regionality
                    ? Utils.getDictionaryValue(budgetItem.dictionary.regionality)
                    : '',
            },
            {
                name: 'Территория',
                value: budgetItem.dictionary?.territory
                    ? Utils.getDictionaryValue(budgetItem.dictionary.territory)
                    : '',
            },
            {
                name: 'Статья',
                value: budgetItem.dictionary && budgetItem.dictionary.item && budgetItem.dictionary.item.value,
            },
            {
                name: 'Блок',
                value: budgetItem.dictionary && budgetItem.dictionary.block && budgetItem.dictionary.block.value,
                isWide: true,
            },
            {
                name: 'Инструмент',
                value: budgetItem.dictionary && budgetItem.dictionary.tool && budgetItem.dictionary.tool.value,
                isWide: true,
            },
        ];

        return {
            name: budgetItem.sapComment,
            oldPlan: currentSumPlannedFunds,
            fields,
            comment: budgetItem.comment,
        };
    }

    private makeChangedAttributes(): ChangedAttribute[] {
        const targetChangedParamsKeys = this.gatTargetChangedParamKeys();

        return lodash.flatMap(
            targetChangedParamsKeys.map((changedParamKey) => {
                return this.makeChangedAttributeByParamKey(changedParamKey as BudgetItemEditableParamsKey);
            }),
        );
    }

    private gatTargetChangedParamKeys(): string[] {
        const { params } = this.props;
        const changedParamsKeys = lodash.keys(params);

        return changedParamsKeys.filter((changedParamsKey) => {
            return targetEditableParamsKeys.find(
                (targetEditableParamKey) => changedParamsKey === targetEditableParamKey,
            );
        });
    }

    private makeChangedAttributeByParamKey(paramKey: BudgetItemEditableParamsKey): ChangedAttribute[] {
        const builderChangeAttributeList: BuilderChangeAttributeList = {
            [BudgetItemEditableParamsKey.ResponsibleIds]: this.makeChangeResponsibleAttribute(),
            [BudgetItemEditableParamsKey.BusinessTarget]: [],
            [BudgetItemEditableParamsKey.CustomerName]: [],
            [BudgetItemEditableParamsKey.Comment]: this.makeChangeCommentAttributes(),
            [BudgetItemEditableParamsKey.ExpertComment]: [],
            [BudgetItemEditableParamsKey.SapComment]: this.makeChangedSapCommentAttributes(),
            [BudgetItemEditableParamsKey.SapNumber]: this.makeChangedSapNumberAttributes(),
            [BudgetItemEditableParamsKey.SapZns]: this.makeChangedSapZnsAttributes(),
            [BudgetItemEditableParamsKey.RealizationStart]: this.makeChangedRealizationStartAttributes(),
            [BudgetItemEditableParamsKey.RealizationEnd]: this.makeChangedRealizationEndAttributes(),
            [BudgetItemEditableParamsKey.PreviousFunds]: this.makeChangedPreviousFundsAttributes(),
            [BudgetItemEditableParamsKey.DictionaryIds]: this.makeChangedAttributesOfDictionaries(),
        };

        return builderChangeAttributeList[paramKey];
    }

    private makeChangedSapCommentAttributes(): ChangedAttribute[] {
        return this.makeChangedRestAttribute(BudgetItemEditableParamsKey.SapComment);
    }

    private makeChangedPreviousFundsAttributes(): ChangedAttribute[] {
        const { params } = this.props;

        const oldValueOfBudgetItem = this.budgetItem.previousFunds || null;
        const newValueOfBudgetItem = params.previousFunds || null;

        return [
            {
                title: PARAMS_TITLES[BudgetItemEditableParamsKey.PreviousFunds],
                name: this.getColumnNameByEditableParamOfBudgetItem(BudgetItemEditableParamsKey.PreviousFunds),
                oldValue: {
                    value: oldValueOfBudgetItem && this.formatMoney(oldValueOfBudgetItem),
                    tooltip: oldValueOfBudgetItem && this.formatMoneyForTooltip(oldValueOfBudgetItem),
                },
                newValue: {
                    value: newValueOfBudgetItem && this.formatMoney(newValueOfBudgetItem),
                    tooltip: newValueOfBudgetItem && this.formatMoneyForTooltip(newValueOfBudgetItem),
                },
            },
        ];
    }

    private makeChangedSapZnsAttributes(): ChangedAttribute[] {
        return this.makeChangedRestAttribute(BudgetItemEditableParamsKey.SapZns);
    }

    private makeChangedSapNumberAttributes(): ChangedAttribute[] {
        return this.makeChangedRestAttribute(BudgetItemEditableParamsKey.SapNumber);
    }

    private makeChangeCommentAttributes(): ChangedAttribute[] {
        return this.makeChangedRestAttribute(BudgetItemEditableParamsKey.Comment);
    }

    private makeChangedRealizationEndAttributes(): ChangedAttribute[] {
        const { params } = this.props;

        const oldValueOfBudgetItem = this.budgetItem.realizationEnd || null;
        const newValueOfBudgetItem = params.realizationEnd || null;

        return [
            {
                title: PARAMS_TITLES[BudgetItemEditableParamsKey.RealizationEnd],
                name: this.getColumnNameByEditableParamOfBudgetItem(BudgetItemEditableParamsKey.RealizationEnd),
                oldValue: {
                    value: oldValueOfBudgetItem && this.formatDate(oldValueOfBudgetItem),
                },
                newValue: {
                    value: newValueOfBudgetItem && this.formatDate(newValueOfBudgetItem),
                },
            },
        ];
    }

    private makeChangedRealizationStartAttributes(): ChangedAttribute[] {
        const { params } = this.props;

        const oldValueOfBudgetItem = this.budgetItem.realizationStart || null;
        const newValueOfBudgetItem = params.realizationStart || null;

        return [
            {
                title: PARAMS_TITLES[BudgetItemEditableParamsKey.RealizationStart],
                name: this.getColumnNameByEditableParamOfBudgetItem(BudgetItemEditableParamsKey.RealizationStart),
                oldValue: {
                    value: oldValueOfBudgetItem && this.formatDate(oldValueOfBudgetItem),
                },
                newValue: {
                    value: newValueOfBudgetItem && this.formatDate(newValueOfBudgetItem),
                },
            },
        ];
    }

    private makeChangeResponsibleAttribute(): ChangedAttribute[] {
        const { params, users } = this.props;

        const oldResponsibles = this.budgetItem.responsibles || null;
        const newResponsiblesIds = params.responsibleIds || null;

        const newResponsibles = newResponsiblesIds
            ? newResponsiblesIds.map((id) => users.find((user) => user.id === id))
            : null;

        return [
            {
                title: PARAMS_TITLES[BudgetItemEditableParamsKey.ResponsibleIds],
                name: this.getColumnNameByEditableParamOfBudgetItem(BudgetItemEditableParamsKey.ResponsibleIds),
                oldValue: {
                    value: oldResponsibles && oldResponsibles.map((user) => this.formatUserName(user)).join(', '),
                },
                newValue: {
                    value: newResponsibles && newResponsibles.map((user) => this.formatUserName(user)).join(', '),
                },
            },
        ];
    }

    private makeChangedAttributesOfDictionaries(): ChangedAttribute[] {
        const { params, dictionaries } = this.props;

        // TODO В ответе graphQL висит поле __typename и для перебора свойст обхекта оно мешает
        if (this.budgetItem.dictionary) {
            delete this.budgetItem.dictionary['__typename'];
        }

        const currentDictionariesIds = lodash
            .compact(lodash.values(this.budgetItem.dictionary))
            .map((dictionaryItem) => dictionaryItem.id);

        const removedDictionariesIds = lodash.difference(currentDictionariesIds, params.dictionaryIds);
        const newDictionariesIds = lodash.difference(params.dictionaryIds, currentDictionariesIds);

        const removedDictionaries = dictionaries.filter((dictionary) =>
            lodash.includes(removedDictionariesIds, dictionary.id),
        );
        const newDictionaries = dictionaries.filter((dictionary) => lodash.includes(newDictionariesIds, dictionary.id));

        const removedDictionariesTypes = removedDictionaries.map((removeDictionary) => removeDictionary.type);
        const newDictionariesTypes = newDictionaries.map((newDictionary) => newDictionary.type);

        const changesDictionariesTypes = lodash.uniq([...removedDictionariesTypes, ...newDictionariesTypes]);

        return changesDictionariesTypes.map((dictionaryType) => {
            const removeDictionaryValue =
                removedDictionaries.find((dictionary) => dictionary.type == dictionaryType) || null;
            const newDictionaryValue = newDictionaries.find((dictionary) => dictionary.type == dictionaryType) || null;

            return {
                title: PARAMS_TITLES[dictionaryType],
                name: this.getColumnNameByDictionaryType(dictionaryType as DictionaryType),
                oldValue: {
                    value: removeDictionaryValue && removeDictionaryValue.value,
                },
                newValue: {
                    value: newDictionaryValue && newDictionaryValue.value,
                },
            };
        });
    }

    private makeChangedRestAttribute(paramKey: BudgetItemEditableParamsKey): ChangedAttribute[] {
        const { params } = this.props;

        const oldValueOfBudgetItem = this.budgetItem[paramKey] || null;
        const newValueOfBudgetItem = params[paramKey] || null;

        return [
            {
                title: PARAMS_TITLES[paramKey],
                name: this.getColumnNameByEditableParamOfBudgetItem(paramKey),
                oldValue: {
                    value: oldValueOfBudgetItem && String(oldValueOfBudgetItem),
                },
                newValue: {
                    value: newValueOfBudgetItem && String(newValueOfBudgetItem),
                },
            },
        ];
    }

    private getColumnNameByDictionaryType(dictionaryType: DictionaryType): ColumnName {
        const column = ColumnsList.find((item) => {
            const columnDictionaryType = lodash.get(item, 'metaData.dictionaryType');
            return columnDictionaryType == dictionaryType;
        });

        return column ? column.name : null;
    }

    private getColumnNameByEditableParamOfBudgetItem(paramKey: BudgetItemEditableParamsKey): ColumnName {
        const column = ColumnsList.find((column) => column.name == columnNameMap[paramKey]);
        return column ? column.name : null;
    }

    private formatMoney(money: number): string {
        return MoneyFormatter.toThousands(Money.fromCopecks(money));
    }

    private formatMoneyForTooltip(money: number): string {
        return MoneyFormatter.toRoubles(Money.fromCopecks(money));
    }

    private get budgetItem(): BudgetItem {
        const { status, budgetItemCurrent: budgetItemsCurrent, budgetItemBefore: budgetItemsBefore } = this.props;

        return status === CorrectionStatus.NeedApproving
            ? lodash.first(budgetItemsCurrent)
            : lodash.isEmpty(lodash.first(budgetItemsBefore))
            ? lodash.first(budgetItemsCurrent)
            : lodash.first(budgetItemsBefore);
    }

    private formatUserName(user: Persone): string {
        return `${user.firstName} ${user.secondName}`;
    }

    private formatDate(date: string | Date): string {
        return moment(date).format('DD.MM.YYYY');
    }
}
