// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-layout-Card-_Card__root{position:relative;background:#fff;border-radius:6px;box-shadow:0 2px 7px 0 #0000001A}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/layout/Card/Card.scss"],"names":[],"mappings":"AAAA,8DACE,iBAAkB,CAClB,eAAgB,CAChB,iBAAkB,CAClB,gCAAiC","sourcesContent":[".root {\n  position: relative;\n  background: #fff;\n  border-radius: 6px;\n  box-shadow: 0 2px 7px 0 #0000001A;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-components-layout-Card-_Card__root"
};
export default ___CSS_LOADER_EXPORT___;
