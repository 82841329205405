import * as React from 'react';
import classNames from 'classnames';
import {
    OpacityTransition,
    CustomScrollbar_new as CustomScrollbar,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    Preloader,
} from 'sber-marketing-ui';

import { BudgetItem } from '@mrm/budget';

import { BudgetItemSelect } from './BudgetItemSelect';

import * as style from './ActivityReferenceMenu.scss';

const CONTENT_MAX_HEIGHT = 550;
interface Props {
    showPreloader: boolean;
    budgetId: string;
    budgetItems: BudgetItem[];
    selectedActivityId: number;
    onSaveButtonClick: () => void;
    onCancelButtonClick: () => void;
}

export const ActivityReferenceMenu = ({
    showPreloader,
    budgetId,
    budgetItems,
    selectedActivityId,
    onSaveButtonClick,
    onCancelButtonClick,
}: Props): JSX.Element => {
    const activityIsSelected = !!selectedActivityId;

    const saveButtonIsDisabled = !activityIsSelected;

    /* tslint:disable max-line-length */
    return (
        <OpacityTransition>
            <div className={classNames(style.root, activityIsSelected && style.activitySelected)}>
                <div className={style.title}>Привязать ID бюджета</div>
                {activityIsSelected && (
                    <>
                        <div className={style.scrollbar}>
                            <CustomScrollbar maxHeight={CONTENT_MAX_HEIGHT} freezeScrollX hideScrollX>
                                <div className={style.scrollContent}>
                                    <BudgetItemSelect
                                        activityId={selectedActivityId}
                                        budgetId={budgetId}
                                        budgetItems={budgetItems}
                                    />
                                </div>
                            </CustomScrollbar>
                        </div>
                    </>
                )}

                <div className={style.buttons}>
                    <div className={style.button}>
                        <Button theme={ButtonTheme.GhostRounded} onClick={onCancelButtonClick}>
                            Закрыть
                        </Button>
                    </div>

                    <div className={style.button}>
                        <Button
                            theme={ButtonTheme.MainRounded}
                            onClick={onSaveButtonClick}
                            disabled={saveButtonIsDisabled}
                        >
                            Сохранить
                        </Button>
                    </div>
                </div>

                {showPreloader && <Preloader />}
            </div>
        </OpacityTransition>
    );
    /* tslint:enable max-line-length */
};
