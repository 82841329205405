import * as React from 'react';
import * as moment from 'moment';

import * as style from './RangeDatepickerCell.scss';

interface Props {
    dates: [Date, Date];
}

export const RangeDatepickerCell = ({ dates }: Props): JSX.Element => {
    const [start, end] = dates;

    return (
        <div className={style.root}>
            {start && end && (
                <>
                    {formatDate(start)}
                    <br />
                    {formatDate(end)}
                </>
            )}

            {(!start || !end) && '—'}
        </div>
    );
};

function formatDate(date: Date): string {
    return moment(date).format('DD.MM.YY');
}
