import { Dictionary } from 'lodash';
import { TaskAttributes } from 'sber-marketing-types/frontend';

import { LoadingStatus } from '@store/commonTypes';

export interface TasksState {
    entities: TaskAttributes[];
    byIds: Dictionary<TaskAttributes>;
    stores: Record<StoreTypes, EntitiesStore>;
}

export interface EntitiesStore {
    fetchersCount: number;
    loadingStatus: LoadingStatus;
    ids: string[];
}

export const enum StoreTypes {}

export interface LoadTasksParams {
    store: StoreTypes;
    ids?: string[];
}

export interface SetStoreIdsParams {
    store: StoreTypes;
    ids: string[];
}

export interface SetLoadingStatusParams {
    store: StoreTypes;
    status: LoadingStatus;
}

export type LoadEntitiesPayload = TaskAttributes[];
