import * as React from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { Brief } from 'sber-marketing-types/frontend';
import { FieldType } from 'sber-marketing-types/frontend';
import type { NotificationMessage, PageOptions } from '@store/common/types';
import type { FileToRemove } from '@store/brief/types';
import type { HeaderView } from '@common/Page';

import { BriefPage } from './BriefPage';
import { Header, HeaderRightContent } from './Header';
import type { StoreState } from '@store';
import { setNotification, setRequestInProgress as setPreloaderStatus, updatePageOptions } from '@store/common/actions';
import {
    removeMarkedFilesFromBrief,
    resetBriefPage,
    setTaskDeadlineDate,
    setValidationDisplayStatus,
} from '@store/brief/actions';
import { getBriefState, checkFieldValidity } from '@store/brief/selectors';
import { getUserOrganizationId } from '@store/user/selector';
import { getHeaderHeight, isRequestInProgress } from '@store/common/selectors';

import { CommonPageLoader, PageLoader, PageSaver, TaskPageLoader, BudgetItemPageLoader } from './modules';
import { BriefType } from './enums';

interface Props extends Partial<MapProps>, Partial<DispatchProps>, Partial<RouteComponentProps<RouteParams>> {
    setHeaderView?: (view: HeaderView) => void;
    setHeaderRightContent?: (content: JSX.Element) => void;
}

interface MapProps {
    organizationId: string;
    briefs: lodash.Dictionary<Brief>;
    currentBriefs: lodash.Dictionary<Brief>;
    isLoadingSomeBriefs: boolean;
    filesToRemove: FileToRemove[];
    headerHeight: number;
    preloader: boolean;
    isBriefsValid: boolean;
}

interface DispatchProps {
    resetBriefPage: () => void;
    setPreloaderStatus: (status: boolean) => void;
    setNotification: (notification: NotificationMessage) => void;
    updatePageOptions: (options: PageOptions) => void;
    setValidationDisplayStatus: (status: boolean) => void;
    removeMarkedFilesFromBrief: () => void;
}

interface RouteParams {
    activityId: string;
    taskId?: string;
    budgetItemId?: string;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BriefPageContainer extends React.Component<Props> {
    private pageLoader: PageLoader;
    private pageSaver: PageSaver;
    private isComponentMounted = false;

    constructor(props: Props) {
        super(props);

        this.state = {
            isChangeBriefModalOpened: false,
            isPasteCopiedBriefModalOpened: false,
        };

        this.props.setPreloaderStatus(true);

        this.isComponentMounted = true;

        this.pageLoader = this.getPageLoaderInstance();
        this.pageSaver = PageSaver.getInstance();

        this.updateHeader();
    }

    public async componentDidMount() {
        await this.pageLoader.init();

        if (this.isComponentMounted) {
            this.props.setPreloaderStatus(false);

            this.updateHeader();
        }
    }

    public componentDidUpdate(prevProps: Props, currentProps: Props) {
        const isChangedLoadingStatus =
            prevProps.preloader !== this.props.preloader ||
            prevProps.isLoadingSomeBriefs !== this.props.isLoadingSomeBriefs;

        if (isChangedLoadingStatus) {
            this.updateHeader();
        }
    }

    public componentWillUnmount() {
        this.isComponentMounted = false;

        this.props.resetBriefPage();
    }

    public render(): JSX.Element {
        const { briefs, headerHeight, preloader } = this.props;

        const briefsIds = lodash.values(briefs).map(({ id }) => id);

        const { activityId, taskId, budgetItemId } = this.props.match.params;

        return React.createElement(BriefPage, {
            briefsIds,
            pageMode: this.briefType,
            headerHeight,
            selectedSectionId: taskId || activityId || budgetItemId,
            loading: preloader,
            onFinishPasteCopiedBrief: this.onFinishPasteCopiedBrief,
            onStartFetchBriefSum: this.onStartFetchBriefSum,
        });
    }

    @autobind
    protected async onSaveButtonClick() {
        if (this.props.isBriefsValid) {
            this.props.removeMarkedFilesFromBrief();

            this.props.setPreloaderStatus(true);

            await this.pageSaver.saveBriefs();
            await this.pageLoader.init();

            if (this.isComponentMounted) {
                this.props.setPreloaderStatus(false);

                this.updateHeader();
                this.props.setValidationDisplayStatus(false);
            } else {
                this.props.setValidationDisplayStatus(true);
            }
        }
    }

    @autobind
    private async onFinishPasteCopiedBrief(): Promise<void> {
        this.pageLoader.updateSchemes({ briefs: lodash.values(this.props.briefs) });
    }

    @autobind
    private async onStartFetchBriefSum(): Promise<void> {
        if (this.props.isBriefsValid) {
            this.props.removeMarkedFilesFromBrief();

            await this.pageSaver.saveBriefs();
            await this.pageLoader.init();

            if (this.isComponentMounted) {
                this.updateHeader();
                this.props.setValidationDisplayStatus(false);
            } else {
                this.props.setValidationDisplayStatus(true);
            }
        }
    }

    private updateHeader() {
        const { isLoadingSomeBriefs, preloader } = this.props;
        const disabledSaveButton = preloader || isLoadingSomeBriefs;

        if (this.briefType === BriefType.BudgetItemBrief) {
            this.props.setHeaderView({
                firstLine: Header({
                    briefType: this.briefType,
                    disabledSaveButton,
                    onSaveButtonClick: this.onSaveButtonClick,
                }),
            });
        } else {
            const headerContent = HeaderRightContent({
                disabledSaveButton,
                onSaveButtonClick: this.onSaveButtonClick,
            });

            this.props.setHeaderRightContent(headerContent);
        }

        this.props.updatePageOptions({
            previousUrl: this.makePreviousUrl(),
            withoutFooter: true,
        });
    }

    private makePreviousUrl(): string {
        const { activityId, taskId } = this.props.match.params;

        return taskId ? `/activity/${activityId}/task/${taskId}` : `/activity/${activityId}`;
    }

    private getPageLoaderInstance(): PageLoader {
        const { activityId, taskId, budgetItemId } = this.props.match.params;

        const params = {
            activityId: Number(activityId),
            taskId,
            organizationId: this.props.organizationId,
            budgetItemId,
        };

        switch (this.briefType) {
            case BriefType.BudgetItemBrief:
                return BudgetItemPageLoader.getInstance(params);
            case BriefType.ActivityBrief:
                return CommonPageLoader.getInstance(params);
            case BriefType.TaskBrief:
                return TaskPageLoader.getInstance(params);
        }
    }

    private get briefType(): BriefType {
        const { taskId, budgetItemId } = this.props.match.params;
        return taskId ? BriefType.TaskBrief : budgetItemId ? BriefType.BudgetItemBrief : BriefType.ActivityBrief;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { briefs, currentBriefs, filesToRemove, briefsLoading } = getBriefState(state);

    return {
        organizationId: getUserOrganizationId(state),
        briefs,
        currentBriefs,
        filesToRemove,
        headerHeight: getHeaderHeight(state),
        preloader: isRequestInProgress(state),
        isBriefsValid: checkBriefsValidity(currentBriefs),
        isLoadingSomeBriefs: lodash.values(briefsLoading).some(({ loading }) => loading),
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            resetBriefPage,
            setTaskDeadlineDate,
            setValidationDisplayStatus,
            setPreloaderStatus,
            setNotification,
            updatePageOptions,
            removeMarkedFilesFromBrief,
        },
        dispatch,
    );
}

export const checkBriefsValidity = (briefsDictionary: lodash.Dictionary<Brief>): boolean => {
    const briefs = lodash.values(briefsDictionary);
    const blocks = lodash.flatMap(briefs.map((briefs) => briefs.blocks));
    const fields = lodash.flatMap(blocks, (item) => item.fields);

    const haveRequiredBlocks = fields.some(({ type }) => type === FieldType.TOGGLE);

    if (haveRequiredBlocks) {
        const toggleFields = fields.filter(({ type }) => type === FieldType.TOGGLE);

        const requiredBlockIds = toggleFields
            .filter((field) => {
                const toggleFieldPosition = lodash.get(field, 'value.togglePosition') || 'right';
                return toggleFieldPosition === 'right';
            })
            .map((field) => field.briefBlockId);

        const regularBlocksIds = blocks
            .filter(
                ({ id }) =>
                    !lodash.includes(
                        toggleFields.map(({ briefBlockId }) => briefBlockId),
                        id,
                    ),
            )
            .map(({ id }) => id);

        return fields
            .filter(({ type }) => type !== FieldType.TOGGLE)
            .every((field) => {
                if (lodash.includes(requiredBlockIds, field.briefBlockId)) {
                    return checkFieldValidity(field, fields);
                }

                if (lodash.includes(regularBlocksIds, field.briefBlockId)) {
                    return checkFieldValidity(field, fields);
                }

                return true;
            });
    }

    return fields.every((field) => checkFieldValidity(field, fields));
};
