import * as React from 'react';

import { DropdownOptions } from '../useDropdown';

export const dropdownCloseContext = React.createContext<DropdownOptions>(null);

export const DropdownOptionsProvider = dropdownCloseContext.Provider;
export function useDropdownOptions() {
    return React.useContext(dropdownCloseContext);
}
