import * as React from 'react';
import { BudgetItemSnapshot, BudgetItemStatus } from '@mrm/budget';

import * as styles from './RejectionComment.scss';

export function shouldRender(diffs: string[], curr: BudgetItemSnapshot): boolean {
    const diffsContainsStatusChange = diffs.includes('status');
    return diffsContainsStatusChange && curr.status === BudgetItemStatus.Rejected;
}

interface Props {
    diffs: string[];
    curr: BudgetItemSnapshot;
}

export function RejectionComment({ diffs, curr }: Props): JSX.Element {
    return shouldRender(diffs, curr) ? (
        <span className={styles.root}>
            Причина отклонения: <span className={styles.comment}>{curr.expertComment}</span>
        </span>
    ) : null;
}
