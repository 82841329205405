// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-Counter-_styles__root{display:grid;grid-template-rows:max-content;grid-row-gap:8px}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-Counter-_styles__item{display:flex;font-family:'Open Sans';font-weight:400;font-size:14px;color:#000000}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-Counter-_styles__count{color:#565656}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/CreationForm/GeneratedTextEditor/Editor/Footer/Counter/styles.scss"],"names":[],"mappings":"AAAA,kJACI,YAAa,CACb,8BAA+B,CAC/B,gBAAiB,CACpB,kJAGG,YAAa,CACb,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,aAAc,CACjB,mJAGG,aAAc","sourcesContent":[".root {\n    display: grid;\n    grid-template-rows: max-content;\n    grid-row-gap: 8px;\n}\n\n.item {\n    display: flex;\n    font-family: 'Open Sans';\n    font-weight: 400;\n    font-size: 14px;\n    color: #000000;\n}\n\n.count {\n    color: #565656;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-Counter-_styles__root",
	"item": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-Counter-_styles__item",
	"count": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-Counter-_styles__count"
};
export default ___CSS_LOADER_EXPORT___;
