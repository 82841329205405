// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-TagsEditorWidget-_TagsEditorWidget__root{padding:16px 24px 24px 24px;border-bottom:1px solid #e3e9e5}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/LineModal/Content/TagsEditorWidget/TagsEditorWidget.scss"],"names":[],"mappings":"AAAA,iHACI,2BAA4B,CAE5B,+BAAgC","sourcesContent":[".root {\n    padding: 16px 24px 24px 24px;\n\n    border-bottom: 1px solid #e3e9e5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-TagsEditorWidget-_TagsEditorWidget__root"
};
export default ___CSS_LOADER_EXPORT___;
