import { createSelector } from 'reselect';
import { ActivityCard } from 'sber-marketing-types/frontend';
import { last } from 'lodash';

import { StoreState } from '../';
import { ActivitiesList, ActivitiesListType } from './types';
import { LoadingStatus } from '../commonTypes';

const getIndex = (_: StoreState, index: number): number => index;

const getId = (_: StoreState, id: number): number => id;

export const getActivitiesListState = (state: StoreState): ActivitiesList => state.activitiesList;

export const getStatus = createSelector(getActivitiesListState, ({ status }): LoadingStatus => status);

export const getType = createSelector(getActivitiesListState, ({ type }): ActivitiesListType => type);

export const getIsActivitySharingInProgress = createSelector(
    getActivitiesListState,
    ({ isActivitySharingInProgress }): boolean => isActivitySharingInProgress,
);

export const getCanBeLoadedMore = createSelector(
    getActivitiesListState,
    ({ canBeLoadedMore }): boolean => canBeLoadedMore,
);

export const getActivitiesList = createSelector(getActivitiesListState, ({ activities }): ActivityCard[] => activities);

export const getLasrActivityId = createSelector(getActivitiesListState, ({ activities }): number => {
    const lastActivity = last(activities);

    return lastActivity && lastActivity.id;
});

export const getFilteredActivitiesList = createSelector(
    getActivitiesListState,
    (state: StoreState, userId: number): number => userId,
    ({ activities, type }: ActivitiesList, userId: number): ActivityCard[] => {
        let filteredActivities = activities;
        switch (type) {
            case ActivitiesListType.ACCESSIBLE_ACTIVITIES:
                filteredActivities = activities.filter((activity) => {
                    const notAuthor = activity.author ? activity.author.id != userId : true;
                    const notResponsible = activity.responsible ? activity.responsible.id != userId : true;
                    return notAuthor && notResponsible;
                });
                break;
            case ActivitiesListType.MY_ACTIVITIES:
                filteredActivities = activities.filter((activity) => {
                    const isAuthor = activity.author ? activity.author.id == userId : false;
                    const isResponsible = activity.responsible ? activity.responsible.id == userId : false;
                    return isAuthor || isResponsible;
                });
                break;
        }
        return filteredActivities;
    },
);

export const getError = createSelector(getActivitiesListState, ({ error }): Error | null => error);

export const getActivityByIndex = createSelector(
    [getActivitiesList, getIndex],
    (tasks, index): ActivityCard | undefined => tasks[index],
);

export const getActivityById = createSelector([getActivitiesList, getId], (tasks, id): ActivityCard | undefined =>
    tasks.find((value) => value.id === id),
);

export const getActivityTask = createSelector(getActivitiesList, last);

export const getLastRequestTimestamp = createSelector(
    getActivitiesListState,
    (state): Date => state.lastRequestTimestamp,
);
