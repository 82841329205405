// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-Table-CellTypes-IndicatorsCell-RowIsNewIndicator-_RowIsNewIndicator__root{display:flex;cursor:pointer;position:absolute;right:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/Table/CellTypes/IndicatorsCell/RowIsNewIndicator/RowIsNewIndicator.scss"],"names":[],"mappings":"AAAA,gIACI,YAAa,CACb,cAAe,CACf,iBAAkB,CAClB,OAAQ","sourcesContent":[".root {\n    display: flex;\n    cursor: pointer;\n    position: absolute;\n    right: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-Table-CellTypes-IndicatorsCell-RowIsNewIndicator-_RowIsNewIndicator__root"
};
export default ___CSS_LOADER_EXPORT___;
