import * as React from 'react';
import { flatten, uniqBy } from 'lodash';
import autobind from 'autobind-decorator';

import { MrmClient } from '@api';
import { CreativeRequestParamDomain, Dictionary, DictionaryType } from './types';
import { CreativeRequestDomain } from '@store/creative';

import { ParamListBehavior } from './ParamListBehavior';

interface Props {
    creativeRequestId: string;
}

interface State {
    loading: boolean;
    version: number;
    dictionaries: Dictionary[];
    creativeRequestDomain: CreativeRequestDomain;
    creativeRequestParamDomains: CreativeRequestParamDomain[];
}

export class ParamListConnector extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loading: true,
            version: 0,
            dictionaries: null,
            creativeRequestDomain: null,
            creativeRequestParamDomains: [],
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.load();
    }

    public render(): JSX.Element {
        const { loading, version, creativeRequestParamDomains, dictionaries } = this.state;

        return React.createElement(ParamListBehavior, {
            loading,
            version,
            creativeRequestParamDomains,
            dictionaries: dictionaries || [],
            canAddParams: this.checkForAbilityAddParams(),
            onAddParam: this.onAddParam,
        });
    }

    private checkForAbilityAddParams(): boolean {
        const { creativeRequestDomain } = this.state;
        return !!creativeRequestDomain?.model.addParam;
    }

    private async load(): Promise<void> {
        const client = await MrmClient.getInstance();
        const creativeRequestDomain = await this.fetchCreativeRequestDomain();

        const creativeRequestParamDomains = (await creativeRequestDomain.model.getParams()) || [];

        const dictionaries = flatten(
            await Promise.all([
                client.Dictionary.getByType(DictionaryType.Block),
                client.Dictionary.getByType(DictionaryType.Division),
                client.Dictionary.getByType(DictionaryType.Segment),
                client.Dictionary.getByType(DictionaryType.Product),
                client.Dictionary.getByType(DictionaryType.Channel),
            ]),
        );

        this.setState(
            {
                loading: false,
                dictionaries,
                creativeRequestDomain,
                creativeRequestParamDomains,
            },
            () => {
                this.subscribe();
            },
        );
    }

    private async fetchCreativeRequestDomain(): Promise<CreativeRequestDomain> {
        const { creativeRequestId } = this.props;
        const client = await MrmClient.getInstance();

        return await client.domain.creativeRequests.getCreativeRequest({
            id: creativeRequestId,
        });
    }

    private subscribe(): void {
        const { creativeRequestDomain } = this.state;

        creativeRequestDomain?.events?.onParamsAdded(this.onParamsAddedHandler);
        creativeRequestDomain?.events?.onParamsRemoved(this.onParamsRemovedHandler);
        creativeRequestDomain?.events?.onReloaded(this.onReloadedHandler);
    }

    private unsubscribe(): void {
        const { creativeRequestDomain } = this.state;

        creativeRequestDomain?.events?.offParamsAdded(this.onParamsAddedHandler);
        creativeRequestDomain?.events?.offParamsRemoved(this.onParamsRemovedHandler);
        creativeRequestDomain?.events?.offReloaded(this.onReloadedHandler);
    }

    @autobind
    private onParamsAddedHandler(creativeRequestParam: CreativeRequestParamDomain): void {
        this.setState({
            creativeRequestParamDomains: uniqBy(
                [...this.state.creativeRequestParamDomains, creativeRequestParam],
                'model.id',
            ),
        });
    }

    @autobind
    private onParamsRemovedHandler(creativeRequestParam: CreativeRequestParamDomain): void {
        this.setState({
            creativeRequestParamDomains: this.state.creativeRequestParamDomains.filter(
                ({ model }) => model.id !== creativeRequestParam.model.id,
            ),
        });
    }

    @autobind
    private onReloadedHandler(): void {
        this.unsubscribe();

        this.fetchCreativeRequestDomain()
            .then((creativeRequestDomain) => {
                return Promise.all([creativeRequestDomain.model.getParams(), creativeRequestDomain]);
            })
            .then(([creativeRequestParamDomains, creativeRequestDomain]) => {
                this.setState(
                    {
                        version: this.state.version + 1,
                        creativeRequestDomain,
                        creativeRequestParamDomains,
                    },
                    () => {
                        this.subscribe();
                    },
                );
            });
    }

    @autobind
    private async onAddParam(): Promise<void> {
        const { creativeRequestDomain } = this.state;
        await creativeRequestDomain.model.addParam();
    }
}
