import * as React from 'react';

import { Filter } from './Filter';
import * as styles from './styles.scss';

export const Footer: React.FC = () => {
    return (
        <div className={styles.root}>
            <Filter />
        </div>
    );
};
