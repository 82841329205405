import * as React from 'react';

import { WithStagesListWidget } from './WithStagesListWidget';
import { StagesListWidgetBehaviour } from './StagesListWidgetBehaviour';

interface Props {
    currentStage: number;
}

export const StagesListWidgetConnector: React.FC<Props> = ({ currentStage }) => {
    return (
        <WithStagesListWidget>
            {({ author, sendToExecutor, sendToCustomer, sendToExpert }) => (
                <StagesListWidgetBehaviour
                    author={author}
                    currentStage={currentStage}
                    sendToExecutor={sendToExecutor}
                    sendToCustomer={sendToCustomer}
                    sendToExpert={sendToExpert}
                />
            )}
        </WithStagesListWidget>
    );
};
