import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { FileApi, VideoPreviewApi } from '@api';

import { StoreState } from '@store';

import { Utils } from '@common/Utils';

import * as asyncActions from './actions/async';
import * as actions from './actions/sync';

import { LoadPreviewPaylaod } from './types';

import { isPreviewValid, getPreviewByItemId } from './selectors';

export const loadPreview = bindThunkAction<StoreState, LoadPreviewPaylaod, void, Error>(
    asyncActions.loadPreview,
    async (payload, dispatch, getState) => {
        const { uploadParams, id, name, extension } = payload;

        if (Utils.isVideo(extension) && !isPreviewValid(getPreviewByItemId(getState(), id))) {
            const { containerName, storageEngine } = await FileApi.getDownloadSettings(uploadParams, id);

            const item = await VideoPreviewApi.getPreview({
                fileName: id,
                originName: `${name}.${extension}`,
                containerName,
                storageEngine,
            });

            dispatch(
                actions.setItem({
                    containerId: containerName,
                    itemId: id,
                    item,
                }),
            );
        }
    },
);
