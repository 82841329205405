import * as React from 'react';

import * as style from './ContentNav.scss';

import type { NavItem as NavItemParams } from './types';

import { NavItemList } from './NavItemList';

interface Props {
    items: NavItemParams[];
}

export const ContentNav = ({ items }: Props): JSX.Element => {
    return (
        <div className={style.root}>
            {items.length && (
                <div>
                    <NavItemList navItems={items} />
                </div>
            )}
        </div>
    );
};
