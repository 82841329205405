import * as React from 'react';
import autobind from 'autobind-decorator';

import { Comment } from './Comment';

const MIN_COUNT_CHARSETS = 3;

enum ErrorMessage {
    MIN_COUNT_CHARSETS = 'Недостаточно текста',
}

interface Props {
    onChangeStatus: (error: string) => void;
    setRejectComment: (rejectComment: string) => void;
}

interface State {
    value: string;
    errorMessage?: ErrorMessage;
    displayError: boolean;
}

export class CommentContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            value: '',
            errorMessage: null,
            displayError: false,
        };
    }

    public render(): JSX.Element {
        return React.createElement(Comment, {
            value: this.state.value,
            errorMessage: this.state.errorMessage,
            displayError: this.state.displayError,
            onValueChange: this.onValueChange,
            onFocus: this.onFocus,
            onBlur: this.onBlur,
        });
    }

    @autobind
    private onValueChange(value: string): void {
        this.setState(
            {
                errorMessage: this.defineErrorMessage(value),
                value,
            },
            () => {
                this.props.onChangeStatus(this.state.errorMessage);
            },
        );
    }

    @autobind
    private onFocus(): void {
        this.setState(
            {
                errorMessage: this.defineErrorMessage(this.state.value),
                displayError: false,
            },
            () => {
                this.props.onChangeStatus(this.state.errorMessage);
            },
        );
    }

    @autobind
    private onBlur(): void {
        this.setState(
            {
                displayError: true,
            },
            () => {
                this.props.setRejectComment(this.state.value);
                this.props.onChangeStatus(this.state.errorMessage);
            },
        );
    }

    private defineErrorMessage(value: string): ErrorMessage {
        const valueIsMoreThanMinCountCharsets = value.length < MIN_COUNT_CHARSETS;

        if (valueIsMoreThanMinCountCharsets) {
            return ErrorMessage.MIN_COUNT_CHARSETS;
        }

        return null;
    }
}
