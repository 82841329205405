import { BuilderStatisticsItem } from './types';

import { buildStatisticsItemsTasks } from './buildStatistcsItemTask';
import { buildStatisticsItemsActivities } from './buildStatisticsItemActivity';

type BuildersStatisticsByMode = {
    activities: BuilderStatisticsItem<'activities'>;
    tasks: BuilderStatisticsItem<'tasks'>;
};

export const buildersStatisticsByMode: BuildersStatisticsByMode = {
    activities: buildStatisticsItemsActivities,
    tasks: buildStatisticsItemsTasks,
};
