import { createSelector } from 'reselect';
import { some } from 'lodash';

import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type { Autopilot, Autopilot2, AutopilotTV, AutopilotRadio, AutopilotDOOH } from 'sber-marketing-types/backend';
import type { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import type { PageState } from './types';

export const getActivityPageState = (state: StoreState): PageState => state.activityPage;

export const getActivityLoadingStatus = createSelector(
    getActivityPageState,
    (state: PageState): LoadingStatus => state.loadingStatus.activity,
);

export const getAutopilotDOOHLoadingStatus = createSelector(
    getActivityPageState,
    (state: PageState): LoadingStatus => state.loadingStatus.autopilotDOOH,
);

export const getAutopilotRadioLoadingStatus = createSelector(
    getActivityPageState,
    (state: PageState): LoadingStatus => state.loadingStatus.autopilotRadio,
);

export const getAutopilotTvLoadingStatus = createSelector(
    getActivityPageState,
    (state: PageState): LoadingStatus => state.loadingStatus.autopilotTv,
);

export const getAutopilotLoadingStatus = createSelector(
    getActivityPageState,
    (state: PageState): LoadingStatus => state.loadingStatus.autopilot,
);

export const getAutopilotLegacyLoadingStatus = createSelector(
    getActivityPageState,
    (state: PageState): LoadingStatus => state.loadingStatus.autopilotLegacy,
);

export const getAutopilotLoading = createSelector(
    getAutopilotLoadingStatus,
    getAutopilotLegacyLoadingStatus,
    getAutopilotTvLoadingStatus,
    getAutopilotRadioLoadingStatus,
    getAutopilotDOOHLoadingStatus,
    (...args) => some(args, (state) => state !== LoadingStatus.LOADED),
);

export const getActivity = createSelector(getActivityPageState, (state: PageState): Activity => state.activity);

export const getAutopilotDOOH = createSelector(
    getActivityPageState,
    (state: PageState): AutopilotDOOH => state.autopilotDOOH,
);

export const getAutopilotRadio = createSelector(
    getActivityPageState,
    (state: PageState): AutopilotRadio => state.autopilotRadio,
);

export const getAutopilotTv = createSelector(
    getActivityPageState,
    (state: PageState): AutopilotTV => state.autopilotTv,
);

export const getAutopilot = createSelector(getActivityPageState, (state: PageState): Autopilot2 => state.autopilot);

export const getAutopilotLegacy = createSelector(
    getActivityPageState,
    (state: PageState): Autopilot => state.autopilotLegacy,
);

export const getAutopilots = createSelector(
    getAutopilot,
    getAutopilotLegacy,
    getAutopilotTv,
    getAutopilotRadio,
    getAutopilotDOOH,
    (autopilot_v2, autopilot, autopilotTv, autopilotRadio, autopilotDOOH) =>
        ({
            autopilot_v2,
            autopilot,
            autopilotTv,
            autopilotRadio,
            autopilotDOOH,
        } as const),
);
