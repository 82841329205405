import * as React from 'react';
import * as d3 from 'd3';

import { Color, ReportType, getColorHash } from '../../../common';
import * as styles from './styles.scss';
import { Utils } from '@common/Utils';

export interface Data {
    id: string;
    name: string;
    bar: {
        first: {
            value: number;
            color: Color;
        };
        second: {
            value: number;
            color: Color;
        };
        third: {
            value: number;
            color: Color;
        };
        fourth: {
            value: number;
            color: Color;
        };
    };
}

interface Params {
    selectorId: string;
    bottomNote: string;
    onMouseEnter: (id: string, event: React.MouseEvent<HTMLDivElement>) => void;
    onMouseLeave: (id: string, event: React.MouseEvent<HTMLDivElement>) => void;
    onClick: (id: string) => void;
}

export const buildChart = (data: Data[], { selectorId, bottomNote, onMouseEnter, onMouseLeave, onClick }: Params) => {
    const leftIndent = 5;
    let width;
    let height = data.length * 80;

    const container = d3
        .select(`#${selectorId}`)
        .attr(
            'style',
            '' +
                'overflow: hidden;' +
                'width: 100%;' +
                'height: 100%;' +
                'display: grid;' +
                'grid-template-rows: 1fr max-content max-content;',
        )
        .call((div) => {
            width = Number((div.node() as HTMLDivElement).getBoundingClientRect().width);
        });

    const rightContainer = container
        .append('div')
        .attr('style', '' + 'position: relative;' + 'overflow: hidden;' + 'width: 100%;' + 'height: 100%;')
        .call((div) => {
            const heightDiv = div.node().getBoundingClientRect().height;
            if (heightDiv > height) {
                height = heightDiv;
            }
        });

    const bottomSidebarSVG = container.append('svg').attr('width', width).attr('height', 20);

    const bottomNoteDiv = container
        .append('div')
        .text(bottomNote)
        .attr(
            'style',
            '' +
                'margin-top: 12px;' +
                'font-family: Open Sans;' +
                'font-size: 12px;' +
                'font-weight: 400;' +
                'text-align: center;' +
                'color: #7E8681;',
        );

    // @ts-ignore
    const xRange = [leftIndent, width - 20];
    const yRange = [0, height];

    const X = d3.map(data, ({ bar }) => ({
        total: d3.sum([bar.first.value, bar.second.value, bar.third.value, bar.fourth.value]),
        first: bar.first,
        second: bar.second,
        third: bar.third,
        fourth: bar.fourth,
    }));
    const Y = d3.map(data, (data) => ({
        id: data.id,
        name: data.name,
    }));

    const xDomain = [0, d3.max(X.map(({ total }) => total))];
    const yDomain = new d3.InternSet(d3.map(Y, (Y) => Y.name));

    const I = d3.range(Y.length).filter((i) => yDomain.has(d3.map(Y, (Y) => Y.name)[i]));

    const xScale = d3.scaleLinear(xDomain, xRange);
    const yScale = d3.scaleBand(yDomain, yRange).align(0);

    const xAxis = d3.axisBottom(xScale).ticks(4);
    const yAxis = d3.axisRight(yScale);

    bottomSidebarSVG
        .append('g')
        .call(xAxis)
        .call((g) => g.select('.domain').remove())
        .call((g) => g.selectAll('.tick line').remove())
        .call((g) =>
            g.selectAll('.tick').attr('style', '' + 'font-family: Open Sans;' + 'font-size: 12px;' + 'color: #7E8681;'),
        );

    const scrollDiv = rightContainer
        .append('div')
        .attr(
            'style',
            '' +
                'position: relative;' +
                'z-index: 1;' +
                'width: 100%;' +
                'height: 100%;' +
                'box-sizing: border-box;' +
                'overflow-x: hidden;' +
                'overflow-y: scroll;',
        );

    const svg = scrollDiv.append('svg').attr('width', `${width}px`).attr('height', `${height}px`);

    const coordinateRect = {
        x: xScale(0),
        y: 0,
        height: 0,
        width: xScale(d3.max(X.map(({ total }) => total))) - xScale(0),
    };

    // Отрисовка сетки
    rightContainer
        .append('svg')
        .attr(
            'style',
            '' +
                'position: absolute;' +
                'z-index: 0;' +
                'left: 0;' +
                'top: 0;' +
                `width: ${width}px;` +
                'height: 100%;',
        )
        .call((g) => (coordinateRect.height = g.node().getBoundingClientRect().height))
        .call(xAxis)
        .call((g) => g.select('.domain').remove())
        .call((g) => g.selectAll('.tick line').attr('stroke', '#F1F5F7').attr('y2', height))
        .call((g) => g.selectAll('.tick text').remove())
        .call((g) => g.select('.tick:first-child').remove())
        .call((g) => g.select('.tick:last-child').remove())
        .append('rect')
        .attr('x', `${coordinateRect.x}px`)
        .attr('y', '0,5px')
        .attr('height', `${coordinateRect.height - 1}px`)
        .attr('width', `${coordinateRect.width}px`)
        .attr('stroke-width', '1px')
        .attr('stroke', '#F1F5F7')
        .attr('stroke-linejoin', 'round')
        .attr('stroke-alignment', 'outer')
        .attr('rx', '2px')
        .attr('ry', '2px');

    const centerRect = yScale.bandwidth() / 2;
    const widthBins = 20;

    // отрисока столбцов
    svg.append('g')
        .selectAll('g')
        .data(I)
        .enter()
        .append('g')
        .attr('x', () => xScale(0))
        .attr('y', (i) => yScale(d3.map(Y, (Y) => Y.name)[i]) - widthBins / 2)
        .attr('height', yScale.bandwidth())
        .attr('width', (i) => xScale(X[i].total - xScale(0)))
        .call((g) =>
            g
                .append('rect')
                .attr('x', () => xScale(0))
                .attr('y', (i) => yScale(d3.map(Y, (Y) => Y.name)[i]) + centerRect - yScale.bandwidth() / 2)
                .attr('width', () => xScale(d3.max(X.map(({ total }) => total))) - xScale(0))
                .attr('height', yScale.bandwidth())
                .attr('class', styles.aggregatingBin)
                .attr('opacity', '0.5')
                .attr('cursor', 'pointer')
                .call(actions),
        )
        .call((g) =>
            g
                .append('rect')
                .attr('x', () => xScale(0))
                .attr('y', (i) => yScale(d3.map(Y, (Y) => Y.name)[i]) + centerRect - widthBins / 2 + 8)
                .attr('height', widthBins)
                .attr('width', (i) => xScale(X[i].first.value) - xScale(0))
                .attr('fill', (i) => getColorHash(X[i].first.color))
                .attr('pointer-events', 'none')
                .clone()
                .attr('x', (i) => xScale(X[i].first.value))
                .attr('width', (i) => xScale(X[i].second.value) - xScale(0))
                .attr('fill', (i) => getColorHash(X[i].second.color))
                .attr('opacity', '1')
                .clone()
                .attr('x', (i) => xScale(X[i].second.value) + xScale(X[i].first.value) - xScale(0))
                .attr('width', (i) => xScale(X[i].third.value) - xScale(0))
                .attr('fill', (i) => getColorHash(X[i].third.color))
                .attr('opacity', '1')
                .clone()
                .attr(
                    'x',
                    (i) =>
                        xScale(X[i].first.value) +
                        xScale(X[i].second.value) +
                        xScale(X[i].third.value) -
                        xScale(0) -
                        xScale(0),
                )
                .attr('width', (i) => xScale(X[i].fourth.value) - xScale(0))
                .attr('fill', (i) => getColorHash(X[i].fourth.color))
                .attr('opacity', '1'),
        )
        .call((g) =>
            g
                .append('text')
                .text((i) => `${X[i].total}`)
                .attr('x', () => xScale(0) + 5)
                .attr('y', (i) => yScale(d3.map(Y, (Y) => Y.name)[i]) + centerRect + 12)
                .attr('width', () => xScale(d3.max(X.map(({ total }) => total))) - xScale(0))
                .attr('height', widthBins)
                .attr('fill', '#515151')
                .attr('style', '' + 'font-family: "Open Sans";' + 'font-weight: 500;' + 'font-size: 12px;'),
        );

    svg.append('g')
        .attr('transform', `translate(-4, -13)`)
        .call(yAxis)
        .call((g) => g.select('.domain').remove())
        .call((g) => g.selectAll('.tick line').remove())
        .call((g) =>
            g
                .selectAll('.tick text')
                .call(wrap)
                .attr(
                    'style',
                    '' + 'font-family: Open Sans;' + 'font-size: 12px;' + 'cursor: pointer;' + 'color: #7E8681;',
                )
                .attr('pointer-events', 'none'),
        );

    function actions(element: { each: (arg0: (i: number) => void) => void }) {
        element.each(function (i: number) {
            // @ts-ignore
            d3.select(this)
                .on('mouseenter', (event) => onMouseEnter(Y[i].id, event))
                .on('mouseout', (event) => onMouseLeave(Y[i].id, event))
                .on('click', () => onClick(Y[i].id));
        });
    }

    return () => {
        bottomNoteDiv.remove();
        bottomSidebarSVG.remove();
        rightContainer.remove();
    };
};

function wrap(text: { each: (arg0: () => void) => void }): void {
    text.each(function () {
        // @ts-ignore
        const text = d3.select(this);
        const words = text.text();
        text.text(Utils.shortenString(words, 55));
    });
}

export const colorClassNames: Record<Color, string> = {
    [Color.RED]: styles.red,
    [Color.BLUE]: styles.blue,
    [Color.GREEN]: styles.green,
    [Color.MUSTARD]: styles.mustard,
};

export const titles: Record<ReportType, string> = {
    [ReportType.ACTIVE]: 'Активные проекты',
    [ReportType.OVERDUE_STAGES]: 'Проекты с просроченными этапами',
    [ReportType.OVERDUE_TASKS]: 'Проекты с просроченными задачами',
    [ReportType.PLANNED_START]: 'Планируется запуск проектов',
};

const DEFAULT_COLOR_NAME = colorClassNames[Color.GREEN];

export const getColorClassNames = (color: Color) => {
    return colorClassNames[color] || DEFAULT_COLOR_NAME;
};

export const getTitleByReportType = (type: ReportType): string => {
    return titles[type] || titles[ReportType.ACTIVE];
};
