import * as React from 'react';
import classNames from 'classnames';
import { Flex, FlexProps } from '@common/components';

import * as styles from './Link.scss';

export interface LinkProps extends FlexProps<'a'> {}

export const Link: React.FC<LinkProps> = ({ className, children, ...props }) => {
    return (
        <Flex element="a" {...props} className={classNames(styles.root, className)}>
            {children}
        </Flex>
    );
};
