import * as React from 'react';
import classNames from 'classnames';

import {
    Popup,
    SelectItem,
    Select_redesign as Select,
    OpenerIconType_redesign as SelectIconType,
    SelectTheme_redesign as SelectTheme,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    CloseButton,
} from 'sber-marketing-ui';

import { StagesList } from './StagesList';

import * as styles from './StagesEditorPopup.scss';

interface Props {
    templateSelectItems: SelectItem[];
    selectedTemplate: string;
    editExistingStagesMode: boolean;
    stageTemplateHaveChanged: boolean;
    stagesHaveChanged: boolean;
    onTemplateSelect: (item: SelectItem) => void;
    closePopup: () => void;
    onOutOfContextClick: () => void;
    onCancelButtonClick: () => void;
    onSaveButtonClick: () => void;
    onDeleteTemplateButtonClick: () => void;
}

export function StagesEditorPopup({
    templateSelectItems,
    selectedTemplate,
    editExistingStagesMode,
    stageTemplateHaveChanged,
    stagesHaveChanged,
    onTemplateSelect,
    closePopup,
    onOutOfContextClick,
    onCancelButtonClick,
    onSaveButtonClick,
    onDeleteTemplateButtonClick,
}: Props): JSX.Element {
    const showNoSelectedTemplateLabel = /*editExistingStagesMode && !selectedTemplate*/ false;

    return (
        <Popup onOutOfContentClick={onOutOfContextClick}>
            <div
                className={styles.root}
                {...{
                    'qa-id': 'stagesEditorPopup',
                }}
            >
                <div className={styles.content}>
                    <div className={styles.topLine}>
                        <div className={styles.topLineTitle}>Редактирование этапов</div>

                        <div
                            className={styles.closeButton}
                            {...{
                                'qa-id': 'stagesEditorPopupCloseButton',
                            }}
                        >
                            <CloseButton onClick={closePopup} />
                        </div>
                    </div>

                    <div className={styles.templateSelectWrapper}>
                        {showNoSelectedTemplateLabel ? (
                            <div className={classNames(styles.templateSelectTitle, styles.templateSelectTitle_green)}>
                                Шаблон этапов удален
                            </div>
                        ) : (
                            <div className={styles.templateSelectTitle}>Шаблон этапов</div>
                        )}

                        <div className={styles.templateSelect}>
                            <Select
                                qaId="stagesEditorPopupTemplateSelect"
                                placeholder={
                                    showNoSelectedTemplateLabel
                                        ? 'Если требуется, выберите другой шаблон'
                                        : 'Выберите шаблон этапов'
                                }
                                value={selectedTemplate}
                                onChange={onTemplateSelect}
                                theme={SelectTheme.LabeledSelectV2}
                                openerIcon={SelectIconType.Arrow8}
                                options={templateSelectItems}
                            />

                            {!stageTemplateHaveChanged && (
                                <div
                                    className={styles.deleteStagesButton}
                                    onClick={onDeleteTemplateButtonClick}
                                    {...{
                                        'qa-id': 'stagesEditorPopupDeleteTemplateButton',
                                    }}
                                >
                                    Удалить шаблон
                                </div>
                            )}
                        </div>
                    </div>

                    {selectedTemplate && (
                        <div className={styles.stagesList}>
                            <StagesList />
                        </div>
                    )}

                    <div className={styles.buttons}>
                        <div className={classNames(styles.button, styles.cancelButton)}>
                            <Button
                                qaId="stagesEditorPopupCancelButton"
                                theme={ButtonTheme.GhostRounded}
                                onClick={onCancelButtonClick}
                            >
                                Отменить
                            </Button>
                        </div>

                        <div className={styles.button}>
                            <Button
                                qaId="stagesEditorPopupSaveButton"
                                theme={ButtonTheme.GhostRoundedBlack}
                                disabled={!stagesHaveChanged}
                                onClick={onSaveButtonClick}
                            >
                                {editExistingStagesMode ? 'Сохранить' : 'Назначить'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Popup>
    );
}
