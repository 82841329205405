import * as React from 'react';
import classNames from 'classnames';

import * as styles from './HighlightQuery.scss';

export enum Theme {
    Bold = 'bold',
}

interface Props {
    theme?: Theme;
    text: string;
    query: string;
    multiline?: boolean;
    elRef?: (el: HTMLDivElement) => void;
}

function joinSplitedWithQuery(
    splited: string[],
    queryRenderer: (index: number) => string | JSX.Element,
): React.ReactNode {
    return splited.reduce((acc, part, i) => [...acc, part, i < splited.length - 1 ? queryRenderer(i) : ''], []);
}

export function HighlightQuery({ theme, text, query, multiline, elRef }: Props): JSX.Element {
    let themeClass: string;
    switch (theme) {
        case Theme.Bold:
            themeClass = styles.root_themeBold;
            break;
    }

    const queryRegexp = new RegExp(
        query.replace(/(\[|\\|\^|\$|\.|\||\?|\*|\+|\(|\))/g, '\\$&'), // auto-escaping
        'i',
    );
    const match = text.match(queryRegexp);

    let title: string;
    let content: React.ReactNode;
    if (match) {
        const matchedQuery = match[0];
        const splited = text.split(queryRegexp);

        title = (joinSplitedWithQuery(splited, (_) => matchedQuery.toUpperCase()) as string[]).join('');
        content = joinSplitedWithQuery(splited, (index) => (
            <span key={`matchedQuery-${index}`} className={styles.backbroudLightBlue}>
                {matchedQuery}
            </span>
        ));
    } else {
        title = text;
        content = <React.Fragment>{text}</React.Fragment>;
    }

    return (
        <div
            className={classNames(styles.root, multiline && styles.root_multiline, themeClass)}
            title={title}
            ref={elRef}
        >
            {content}
        </div>
    );
}
