// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-FinalStageWidget-ContextBlock-Texts-_styles__root{padding:30px 25px;display:grid;grid-auto-rows:auto;grid-row-gap:24px;background:#FFFFFF;box-shadow:0 1px 4px rgba(0,0,0,0.1);border-radius:6px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/FinalStageWidget/ContextBlock/Texts/styles.scss"],"names":[],"mappings":"AAAA,4GACI,iBAAkB,CAElB,YAAa,CACb,mBAAoB,CACpB,iBAAkB,CAElB,kBAAmB,CACnB,oCAAwC,CACxC,iBAAkB","sourcesContent":[".root {\n    padding: 30px 25px;\n\n    display: grid;\n    grid-auto-rows: auto;\n    grid-row-gap: 24px;\n\n    background: #FFFFFF;\n    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);\n    border-radius: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-FinalStageWidget-ContextBlock-Texts-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
