import * as React from 'react';

import * as style from './TextField.scss';

import { ILCreativeFieldSpec } from 'sber-marketing-types/backend';

import { TextInput } from './TextInput';

interface Props {
    field: ILCreativeFieldSpec;
}

export const TextField = ({ field }: Props): JSX.Element => {
    console.log('TextField', field);

    return (
        <div className={style.root}>
            <div className={style.title}>
                {field.fieldTitle}

                {field.required && (
                    <>
                        &nbsp;
                        <span className={style.requiredIndicator}>*</span>
                    </>
                )}
            </div>

            {field.description && <div className={style.description}>{field.description}</div>}

            <div className={style.input}>
                <TextInput
                    value={''}
                    placeholder={'Напишите описание продукта'}
                    readOnly={null}
                    onValueChange={() => {}}
                />
            </div>
        </div>
    );
};
