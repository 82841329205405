import * as React from 'react';

import { ShortReport, Color } from '../../common';
import { Footer } from './Footer';
import { Header } from './Header';
import { useState } from './useState';

import * as styles from './styles.scss';

export const CommonShortReport: React.FC = () => {
    const { statisticsItem, resetStatisticsTypeFilter } = useState();

    const onClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(() => {
        resetStatisticsTypeFilter();
    }, [resetStatisticsTypeFilter]);

    return (
        <div className={styles.root}>
            <ShortReport
                className={styles.shortReport}
                count={statisticsItem.statistics.current.totalCount}
                color={Color.RED}
                title={<Header />}
                footer={<Footer />}
                onClick={onClick}
            />
        </div>
    );
};
