import * as React from 'react';
import * as moment from 'moment';

import * as style from './Date.scss';

interface Props {
    time: Date;
}

export function Date({ time }: Props): JSX.Element {
    return (
        <div className={style.root}>
            <div
                className={style.date}
                {...{
                    'qa-id': 'dashboardNewsColumnItemDate',
                }}
            >
                {formatDate(time)}
            </div>
        </div>
    );
}

function formatDate(d: Date): string {
    const date = moment(d);

    return `${date.format('DD MMMM')} в ${date.format('HH:mm')}`;
}
