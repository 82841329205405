import * as React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { StoreState } from '@store';
import {
    BudgetTransferDesciptor,
    InternalTransferDirection,
    getBudgetTransferMenuState,
    getTransitionBudgetItem,
} from '@store/budgetExecution/budgetTransferMenu';

import { Utils } from '@common/Utils';

import { SumHelper } from './SumHelper';

import * as styles from './TitleRow.scss';

export function TitleRow(): JSX.Element {
    const internalTransferDirection = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.internalTransferDirection,
    );

    let className: string;
    switch (internalTransferDirection) {
        case InternalTransferDirection.OneToMany:
            className = classnames(styles.root, styles.rootReversed);
            break;
        case InternalTransferDirection.ManyToOne:
            className = styles.root;
            break;
        default:
            className = '';
            break;
    }

    return (
        <div className={className}>
            <Title />

            <SumHelper />
        </div>
    );
}

function useTitleStore() {
    const currentBudgetItem = useSelector((state: StoreState) =>
        getTransitionBudgetItem(state, getBudgetTransferMenuState(state).controls.rowForSumEntering),
    );

    const columnsCount = useSelector((state: StoreState) => {
        const {
            controls: { rowForSumEntering, internalTransferDirection },
            transferDescriptors,
        } = getBudgetTransferMenuState(state);

        let availableDescriptors: BudgetTransferDesciptor[];
        switch (internalTransferDirection) {
            case InternalTransferDirection.OneToMany:
                availableDescriptors = transferDescriptors.filter(
                    (descriptor) => descriptor.to.lineId === rowForSumEntering,
                );
                break;
            case InternalTransferDirection.ManyToOne:
                availableDescriptors = transferDescriptors.filter(
                    (descriptor) => descriptor.from.lineId === rowForSumEntering,
                );
                break;
            default:
                availableDescriptors = [];
                break;
        }

        return availableDescriptors.length;
    });

    return {
        currentBudgetItem,
        columnsCount,
    };
}

const AVAILABLE_DECS = ['доступна', 'доступно', 'доступно'];
const COLUMNS_DECS = ['ячейка', 'ячейки', 'ячеек'];

function Title(): JSX.Element {
    const { currentBudgetItem, columnsCount } = useTitleStore();

    const availableDec = Utils.getDeclensionByNumber(columnsCount, AVAILABLE_DECS);
    const columnsDec = Utils.getDeclensionByNumber(columnsCount, COLUMNS_DECS);

    return (
        <div className={styles.title}>
            В <span className={styles.titleBold}>ID {currentBudgetItem?.serialNumber}</span> {availableDec}{' '}
            {columnsCount} {columnsDec} для переноса бюджета:
        </div>
    );
}
