import * as moment from 'moment';
import { cloneDeep, includes } from 'lodash';

import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Success } from 'typescript-fsa';

import {
    PageState,
    PageData,
    Filters,
    FilterPeriod,
    DataFilterBy,
    SetFilterPeriodResult,
    SetFilterPeriodPayload,
    SetFilterDateResult,
    SetFilterDatePayload,
    SetFilterByResult,
    SetFilterByPayload,
    StatisticsType,
} from './types';
import * as actions from './actions';
import { buildStatisticsItems } from './utils/builderStatisticsItem';
import { getAncestorStatisticsItem, convertStatisticsItemsToFilters } from './utils/common';

const initialState: PageState = {
    data: {
        users: [],
        departments: [],
        statistics: {
            current: [],
            past: [],
        },
    },
    selectedStatisticsItemId: null,
    selectedBy: DataFilterBy.Author,
    selectedPeriod: FilterPeriod.Month,
    selectedDate: moment(Date.now()).format('YYYY-MM-DD'),
    statisticsTypeFilters: null,
    statisticsItems: [],
    filters: {},
    loading: true,
};

class Reducer {
    public static loadPage(state: PageState, payload: Success<null, PageData>): PageState {
        const statisticsItems = buildStatisticsItems({
            departments: payload.result.departments,
            statistics: payload.result.statistics,
            users: payload.result.users,
        });

        const selectedStatisticsItemId = statisticsItems.length
            ? getAncestorStatisticsItem(statisticsItems).id
            : state.selectedStatisticsItemId;

        const filters = convertStatisticsItemsToFilters(statisticsItems);

        const statisticsTypeFilters = {
            [StatisticsType.ACTIVE]: true,
            [StatisticsType.OVERDUE]: true,
            [StatisticsType.COMING_DEADLINE]: true,
            [StatisticsType.CLOSED]: true,
        };

        return {
            ...state,
            statisticsItems,
            selectedStatisticsItemId,
            filters,
            statisticsTypeFilters,
            loading: false,
            data: {
                ...state.data,
                ...payload?.result,
            },
        };
    }

    public static setFilters(state: PageState, payload: Filters): PageState {
        const filters = {
            ...state.filters,
            ...payload,
        };

        const statisticsItems = buildStatisticsItems({
            departments: state.data.departments,
            statistics: state.data.statistics,
            users: state.data.users,
            filters,
        });
        return {
            ...state,
            statisticsItems,
            filters,
        };
    }

    public static setStatisticsTypeFilter(state: PageState, payload: Record<StatisticsType, boolean>): PageState {
        const filters = { ...state.statisticsTypeFilters, ...payload };

        return {
            ...state,
            statisticsTypeFilters: filters,
        };
    }

    public static setFilterPeriodStarted(state: PageState, payload: SetFilterPeriodPayload): PageState {
        return {
            ...state,
            loading: true,
            selectedPeriod: payload.filterPeriod,
        };
    }

    public static setFilterPeriodDone(state: PageState, payload: Success<null, SetFilterPeriodResult>): PageState {
        const statisticsItems = buildStatisticsItems({
            departments: payload.result.data.departments,
            statistics: payload.result.data.statistics,
            users: payload.result.data.users,
            filters: state.filters,
        });

        const filters = convertStatisticsItemsToFilters(statisticsItems);

        const selectedStatisticsItemId = statisticsItems.length
            ? includes(
                  statisticsItems.map(({ id }) => id),
                  state.selectedStatisticsItemId,
              )
                ? state.selectedStatisticsItemId
                : getAncestorStatisticsItem(statisticsItems).id
            : state.selectedStatisticsItemId;

        return {
            ...state,
            statisticsItems,
            filters: {
                ...filters,
                ...state.filters,
            },
            data: {
                ...state.data,
                ...payload.result.data,
            },
            loading: false,
            selectedStatisticsItemId,
            selectedPeriod: payload.result.filterPeriod,
        };
    }

    public static setFilterDateStarted(state: PageState, payload: SetFilterDatePayload): PageState {
        return {
            ...state,
            loading: true,
            selectedDate: payload.filterDate,
        };
    }

    public static setFilterDateDone(state: PageState, payload: Success<null, SetFilterDateResult>): PageState {
        const statisticsItems = buildStatisticsItems({
            departments: payload.result.data.departments,
            statistics: payload.result.data.statistics,
            users: payload.result.data.users,
            filters: state.filters,
        });

        const filters = convertStatisticsItemsToFilters(statisticsItems);

        const selectedStatisticsItemId = statisticsItems.length
            ? includes(
                  statisticsItems.map(({ id }) => id),
                  state.selectedStatisticsItemId,
              )
                ? state.selectedStatisticsItemId
                : getAncestorStatisticsItem(statisticsItems).id
            : state.selectedStatisticsItemId;

        return {
            ...state,
            statisticsItems,
            filters: {
                ...filters,
                ...state.filters,
            },
            data: {
                ...state.data,
                ...payload.result.data,
            },
            loading: false,
            selectedStatisticsItemId,
            selectedDate: payload.result.filterDate,
        };
    }

    public static setFilterByStarted(state: PageState, payload: SetFilterByPayload): PageState {
        return {
            ...state,
            loading: true,
            selectedBy: payload.by,
        };
    }

    public static setFilterByDone(state: PageState, payload: Success<null, SetFilterByResult>): PageState {
        const statisticsItems = buildStatisticsItems({
            departments: payload.result.data.departments,
            statistics: payload.result.data.statistics,
            users: payload.result.data.users,
            filters: state.filters,
        });

        const filters = convertStatisticsItemsToFilters(statisticsItems);

        const selectedStatisticsItemId = statisticsItems.length
            ? includes(
                  statisticsItems.map(({ id }) => id),
                  state.selectedStatisticsItemId,
              )
                ? state.selectedStatisticsItemId
                : getAncestorStatisticsItem(statisticsItems).id
            : state.selectedStatisticsItemId;

        return {
            ...state,
            statisticsItems,
            filters: {
                ...filters,
                ...state.filters,
            },
            data: {
                ...state.data,
                ...payload.result.data,
            },
            loading: false,
            selectedStatisticsItemId,
            selectedBy: payload.result.by,
        };
    }

    public static setSelectedStatisticsItemId(state: PageState, payload: string): PageState {
        return {
            ...state,
            selectedStatisticsItemId: payload,
        };
    }

    public static resetPageStore(): PageState {
        return cloneDeep(initialState);
    }
}

export const leaderDashboardTasksPageReducer = reducerWithInitialState(initialState)
    .case(actions.loadPageAsync.done, Reducer.loadPage)
    .case(actions.resetPageStore, Reducer.resetPageStore)
    .case(actions.setSelectedStatisticsItemId, Reducer.setSelectedStatisticsItemId)
    .case(actions.setStatisticsTypeFilters, Reducer.setStatisticsTypeFilter)
    .case(actions.setFilters, Reducer.setFilters)
    .case(actions.setFilterPeriodAsync.started, Reducer.setFilterPeriodStarted)
    .case(actions.setFilterPeriodAsync.done, Reducer.setFilterPeriodDone)
    .case(actions.setFilterDateAsync.started, Reducer.setFilterDateStarted)
    .case(actions.setFilterDateAsync.done, Reducer.setFilterDateDone)
    .case(actions.setFilterByAsync.started, Reducer.setFilterByStarted)
    .case(actions.setFilterByAsync.done, Reducer.setFilterByDone);
