import * as lodash from 'lodash';

const MIN_OFFSET_FACTOR = 0.1;

interface Item {
    index: number;
    position: number;
    isLast: boolean;
    getHeight(): number;
}

interface Props<L> {
    viewportHeight: number;
    scrollOffset: number;
    items: L[];
}

export class FrameManager<L = any, F extends Item = any> {
    protected items: L[];
    protected itemsHeights: number[];
    protected viewportHeight: number;
    protected frames: F[];
    protected scrollOffset: number;
    private tableHeight: number;

    constructor({ items, viewportHeight, scrollOffset }: Props<L>) {
        this.items = items;
        this.viewportHeight = viewportHeight;
        this.scrollOffset = scrollOffset;

        this.itemsHeights = this.calculateItemsHeights();

        this.frames = this.makeFrames();
        this.tableHeight = this.calculateHeight();
    }

    public makeFrames(): F[] {
        let frameIndex = 0;
        let itemIndex = 0;

        const frames: F[] = [];

        let currentFrame;

        do {
            currentFrame = this.makeFrame(itemIndex, frameIndex);
            frames.push(currentFrame);
            frameIndex++;

            if (!currentFrame.isLast) {
                itemIndex++;
            }
        } while (!currentFrame.isLast);

        return frames;
    }

    public getCurrentFrameIndex(): number {
        let heightSum = 0;
        let itemIndex = 0;

        while (heightSum < this.scrollOffset) {
            heightSum += this.itemsHeights[itemIndex];
            itemIndex++;
        }

        let frameIndex = itemIndex - 1;

        if (frameIndex < 0) {
            frameIndex = 0;
        }

        if (frameIndex > this.frames.length - 1) {
            frameIndex = this.frames.length - 1;
        }

        return frameIndex;
    }

    public getFrameByIndex(index: number): F {
        return this.frames[index];
    }

    public setScrollOffset(scrollOffset: number) {
        this.scrollOffset = scrollOffset;
    }

    public getTableHeight() {
        return this.tableHeight;
    }

    protected makeFrame(startItemIndex: number, frameIndex: number): F {
        throw new Error('"makeFrame" method is undefined');
    }

    protected calculateMinOffset() {
        return Math.ceil(this.viewportHeight * MIN_OFFSET_FACTOR);
    }

    protected calculateMinFrameHeight() {
        return Math.ceil(this.viewportHeight + 50);
    }

    protected calculateItemsHeights(): number[] {
        throw new Error('"calculateItemsHeights" method is undefined');
    }

    private calculateHeight(): number {
        const lastFrame = lodash.last(this.frames);

        return lastFrame.position + lastFrame.getHeight();
    }
}
