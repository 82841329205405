import * as React from 'react';
import { differenceBy, uniqBy } from 'lodash';

import { LoadingStatus } from '@store/commonTypes';

import { PropsWithUserOrganizationsIds, withUserOrganizationsIds } from '@common/withUserOrganizationsIds';
import { PropsWithAppUsers, withAppUsers } from '@common/withAppUsers';

import { EditParticipantsForm, Position } from './EditParticipantsForm';

interface Props extends OwnProps {}

interface ExternalProps {
    participants: {
        userId: number;
        canRemove: boolean;
    }[];
    onCloseForm(): void;
    onParticipantsChange(participants: { userId: number; canRemove: boolean }[]): void;
}

interface OwnProps extends ExternalProps, PropsWithAppUsers, PropsWithUserOrganizationsIds {}

interface State {
    editingParticipants: {
        userId: number;
        canRemove: boolean;
    }[];
    position: Position;
}

export const EditParticipantsFormContainer: React.ComponentType<ExternalProps> = withAppUsers(
    withUserOrganizationsIds(
        class extends React.PureComponent<Props, State> {
            private rootRef: React.RefObject<HTMLDivElement> = null;

            public constructor(props: Props) {
                super(props);

                this.rootRef = React.createRef();

                this.state = {
                    editingParticipants: props.participants,
                    position: Position.UNDER,
                };
            }

            public componentDidMount(): void {
                if (this.isBottomSideOfElementNotDisplayedInViewport()) {
                    this.setState({
                        position: Position.OVER,
                    });
                }
            }

            public render() {
                const { appUsers, userOrganizationsIdsLoadingStatus, participants } = this.props;
                const { editingParticipants } = this.state;

                const availableUsers = appUsers.map((user) => user.id);
                const isLoading = userOrganizationsIdsLoadingStatus !== LoadingStatus.LOADED;

                const addedParticipantsCount = differenceBy(
                    editingParticipants,
                    participants,
                    (participant) => participant.userId,
                ).length;
                const removedParticipantsCount = differenceBy(
                    participants,
                    editingParticipants,
                    (participant) => participant.userId,
                ).length;

                return (
                    <EditParticipantsForm
                        rootRef={this.rootRef}
                        availableUsers={availableUsers}
                        selectedUsers={editingParticipants}
                        position={this.state.position}
                        isLoading={isLoading}
                        addedParticipantsCount={addedParticipantsCount}
                        removedParticipantsCount={removedParticipantsCount}
                        onAddParticipants={this.onAddParticipants}
                        onRemoveParticipants={this.onRemoveParticipants}
                        onSubmitButtonClick={this.onSubmitButtonClick}
                        onCancelButtonClick={this.onCancelButtonClick}
                    />
                );
            }

            private onAddParticipants = (participantsIds: number[]): void => {
                this.setState((state) => ({
                    editingParticipants: uniqBy(
                        [
                            ...state.editingParticipants,
                            ...participantsIds.map((userId) => ({
                                userId,
                                canRemove: true,
                            })),
                        ],
                        (participant) => participant.userId,
                    ),
                }));
            };

            private onRemoveParticipants = (participantsIds: number[]): void => {
                this.setState((state) => ({
                    editingParticipants: state.editingParticipants.filter(
                        (participant) => !participantsIds.includes(participant.userId),
                    ),
                }));
            };

            private onCancelButtonClick = (): void => {
                this.props.onCloseForm();
            };

            private onSubmitButtonClick = (): void => {
                this.props.onParticipantsChange(this.state.editingParticipants);
                this.props.onCloseForm();
            };

            private isBottomSideOfElementNotDisplayedInViewport = (): boolean => {
                const heightViewport = this.getHeightViewport();
                const heightOfBottomSideOfElementRelativeViewport =
                    this.getHeightOfBottomSideOfElementRelativeViewport();

                return heightViewport - heightOfBottomSideOfElementRelativeViewport < 0;
            };

            private getHeightViewport = (): number => {
                return document.documentElement.clientHeight;
            };

            private getHeightOfBottomSideOfElementRelativeViewport = (): number => {
                if (this.rootRef.current) {
                    const rootElement = this.rootRef.current;

                    return rootElement.getBoundingClientRect().bottom;
                }

                return 0;
            };
        },
    ),
);
