import * as React from 'react';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import { TaskStatus } from 'sber-marketing-types/frontend';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getTaskInfo } from '@store/taskPage/selectors';
import { getLoadingStatus } from '@store/appUsers';

import { TaskParticipants, MAX_PARTICIPANTS_FOR_REDUCED_LIST } from './TaskParticipants';

interface Props extends OwnProps, Partial<MapProps> {}

interface OwnProps {
    participants: {
        userId: number;
        canRemove: boolean;
    }[];
    onParticipantsChange: (participants: { userId: number; canRemove: boolean }[]) => void;
    onParticipantClick?: (userId: number) => void;
}

interface MapProps {
    isAddButtonDisabled: boolean;
    appUsersLoadingStatus: LoadingStatus;
    sortedParticipants: {
        userId: number;
        canRemove: boolean;
    }[];
}

interface State {
    isListExpanded: boolean;
    isEditorVisible: boolean;
}

@(connect(mapStateToProps) as any)
export class TaskParticipantsContainer extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isListExpanded: false,
            isEditorVisible: false,
        };
    }

    public render(): JSX.Element {
        const {
            sortedParticipants,
            isAddButtonDisabled,
            onParticipantsChange,
            onParticipantClick,
            appUsersLoadingStatus,
        } = this.props;

        const { isListExpanded, isEditorVisible } = this.state;

        return (
            <TaskParticipants
                participants={sortedParticipants}
                appUsersLoadingStatus={appUsersLoadingStatus}
                isAddButtonDisabled={isAddButtonDisabled}
                isListExpanded={isListExpanded}
                isEditorVisible={isEditorVisible}
                onParticipantsChange={onParticipantsChange}
                onMaskClick={this.onMaskClick}
                onAddButtonClick={this.onAddButtonClick}
                onExpandParticipatorsListButtonClick={this.onExpandParticipatorsListButtonClick}
                onReduceParticipatorsListButtonClick={this.onReduceParticipatorsListButtonClick}
                onCloseEditParticipantsForm={this.onCloseEditParticipantsForm}
                removeParticipant={this.removeParticipant}
                onParticipantClick={onParticipantClick}
            />
        );
    }

    @autobind
    private onExpandParticipatorsListButtonClick(): void {
        this.setState(() => ({ isListExpanded: true }));
    }

    @autobind
    private onReduceParticipatorsListButtonClick(): void {
        this.setState(() => ({ isListExpanded: false }));
    }

    @autobind
    private onCloseEditParticipantsForm(): void {
        this.setState({
            isEditorVisible: false,
        });
    }

    @autobind
    private onAddButtonClick(): void {
        this.setState({
            isEditorVisible: true,
        });
    }

    @autobind
    private onMaskClick(): void {
        this.setState({
            isEditorVisible: false,
        });
    }

    @autobind
    private removeParticipant(participantId: number): void {
        this.props.onParticipantsChange(
            this.props.sortedParticipants.filter((participant) => participant.userId !== participantId),
        );

        if (this.props.participants.length - 1 <= MAX_PARTICIPANTS_FOR_REDUCED_LIST) {
            this.setState({
                isListExpanded: false,
            });
        }
    }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): MapProps {
    const { authorId, executorId, status } = getTaskInfo(state);
    const { participants } = ownProps;

    const sortedParticipants = [];

    const executor = participants.find((participant) => participant.userId === executorId);
    if (executor) {
        sortedParticipants.push(executor);
    }

    const author = participants.find((participant) => participant.userId === authorId);
    if (author && author !== executor) {
        sortedParticipants.push(author);
    }

    sortedParticipants.push(
        ...participants.filter((participant) => participant.userId !== executorId && participant.userId !== authorId),
    );

    return {
        isAddButtonDisabled: status === TaskStatus.Draft,
        appUsersLoadingStatus: getLoadingStatus(state),
        sortedParticipants,
    };
}
