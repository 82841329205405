import * as React from 'react';
import classNames from 'classnames';
import { BudgetItemStatus } from '@mrm/budget';
import { Icon, IconType, WithTooltip, TooltipAnchor } from 'sber-marketing-ui';

import { ColumnName } from '@store/budgetPlanning';

import { RowIsNewIndicator } from './RowIsNewIndicator';
import { ApprovingStatus } from './ApprovingStatus';
import { RequiredFieldsAreEmptyMarker } from './RequiredFieldsAreEmptyMarker';
import { ApprovingApprovementButtons, ExpertApprovementButtons } from './ApprovementButtons';

import * as style from './IndicatorsCell.scss';

interface Props {
    lineId: string;
    lineIsHovered: boolean;
    showApprovingButtons: boolean;
    showSendToExpertApprovementButton: boolean;
    lineHasChanges: boolean;
    lineHasSnapshots: boolean;
    showExpertApprovementButtons: boolean;
    lineUnsavedStatus: BudgetItemStatus;
    onSendToExpertApprovementButtonClick: () => void;
    onLineMouseLeave: () => void;
    onApproveButtonClick: () => void;
    onRejectButtonClick: () => void;
    focusField: (columnName: ColumnName) => void;
    onSnapshotsButtonClick: () => void;
}

export const IndicatorsCell = ({
    lineId,
    lineIsHovered,
    showSendToExpertApprovementButton,
    showApprovingButtons,
    lineHasChanges,
    lineHasSnapshots,
    showExpertApprovementButtons,
    lineUnsavedStatus,
    onSendToExpertApprovementButtonClick,
    onLineMouseLeave,
    onApproveButtonClick,
    onRejectButtonClick,
    focusField,
    onSnapshotsButtonClick,
}: Props): JSX.Element => {
    const ref = React.useRef();
    const disableSendToExpertApprovementButton = lineHasChanges;

    return (
        <div className={style.root} ref={ref}>
            <div className={style.withMargins}>
                {showSendToExpertApprovementButton && (
                    <div
                        className={classNames(
                            style.button,
                            disableSendToExpertApprovementButton && style.button_disabled,
                        )}
                        title={'Отправить на утверждение'}
                        onClick={disableSendToExpertApprovementButton ? null : onSendToExpertApprovementButtonClick}
                        {...{
                            'qa-id': 'budgetPlanningIndicatorsCellSendToExpertApprovementButton',
                        }}
                    >
                        <WithTooltip content={'Отправить на утверждение'}>
                            <Icon
                                type={IconType.SEND_FOR_APPROVAL}
                                className={classNames(
                                    style.icon,
                                    disableSendToExpertApprovementButton && style.icon_disabled,
                                )}
                            />
                        </WithTooltip>
                    </div>
                )}

                {showApprovingButtons && (
                    <ApprovingApprovementButtons
                        lineIsHovered={lineIsHovered}
                        lineId={lineId}
                        wrapperRef={ref}
                        onLineMouseLeave={onLineMouseLeave}
                    />
                )}

                <ApprovingStatus lineIsHovered={lineIsHovered} lineId={lineId} wrapperRef={ref} />

                {showExpertApprovementButtons && (
                    <ExpertApprovementButtons
                        lineHasChanges={lineHasChanges}
                        lineUnsavedStatus={lineUnsavedStatus}
                        onApproveButtonClick={onApproveButtonClick}
                        onRejectButtonClick={onRejectButtonClick}
                    />
                )}

                <RequiredFieldsAreEmptyMarker lineId={lineId} focusField={focusField} />

                {lineHasSnapshots && (
                    <div
                        onClick={onSnapshotsButtonClick}
                        {...{
                            'qa-id': 'budgetPlanningInicatorsCellSnapsotsButton',
                        }}
                    >
                        <WithTooltip anchor={TooltipAnchor.LEFT} content="Открыть изменения по строке">
                            <Icon type={IconType.BUDGET_ITEM_SNAPSHOT_INDICATOR} className={style.borderlessIcon} />
                        </WithTooltip>
                    </div>
                )}
            </div>

            <RowIsNewIndicator
                lineId={lineId}
                normalPaddingClass={style.paddingRightNormal}
                extendedPaddingClass={style.paddingRightExtended}
            />
        </div>
    );
};
