import * as React from 'react';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import { connect } from 'react-redux';

import { CorrectionStatus, FinalCorrectionStatus } from '@mrm/budget';
import { StoreState } from '@store';
import { getLoginUser, User } from '@store/user';

import { BudgetCorrectionApi } from '@api';

import { WithPopup } from './WithPopup';
import { PlanPermissionsSysNames } from 'sber-marketing-types/frontend';

interface Props extends Partial<MapProps> {
    correctionId: string;
    correctionAuthorId: number;
    children: (params: RenderParams) => React.ReactNode | React.ReactNode[];
    onFinishUpdateCorrectionStatus: () => void;
}

interface MapProps {
    user: User;
}

interface RenderParams {
    userIsBudgetExpert: boolean;
    userIsAuthorCorrection: boolean;
    onSetNewCorrectionStatus: (correctionStatus: FinalCorrectionStatus) => void;
}

interface State {
    newCorrectionStatus: FinalCorrectionStatus;
    comment: {
        value: string;
        error: string;
    };
}

@(connect(mapStateToProps, null) as any)
export class WithPopupContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            newCorrectionStatus: null,
            comment: {
                value: '',
                error: 'true',
            },
        };
    }

    public render() {
        return React.createElement(WithPopup, {
            children: this.props.children,
            visibility: this.visibility,
            isStatusApproved: this.isStatusApproved,
            disableConfirm: this.isDisableConfirm,
            userIsBudgetExpert: this.userIsBudgetExpert,
            userIsAuthorCorrection: this.userIsAuthorCorrection,
            onSetNewCorrectionStatus: this.onSetNewCorrectionStatus,
            onCancel: this.onCancel,
            onConfirm: this.onConfirm,
            onOutOfContentClick: this.onOutOfContentClick,
            onChangeCommentStatus: this.onChangeCommentStatus,
            onSetCommentValue: this.onSetCommentValue,
        });
    }

    @autobind
    private async onConfirm(): Promise<void> {
        await this.fetchChangeCorrectionStatus();
        this.props.onFinishUpdateCorrectionStatus();
        this.close();
    }

    @autobind
    private onCancel(): void {
        this.close();
    }

    @autobind
    private onChangeCommentStatus(error: string): void {
        this.setState(({ comment }) => ({
            comment: { ...comment, error },
        }));
    }

    @autobind
    private onOutOfContentClick(): void {
        this.close();
    }

    @autobind
    private onSetCommentValue(value: string): void {
        this.setState(({ comment }) => ({
            comment: { ...comment, value },
        }));
    }

    @autobind
    private onSetNewCorrectionStatus(correctionStatus: FinalCorrectionStatus): void {
        this.setState({ newCorrectionStatus: correctionStatus });
    }

    private close(): void {
        this.setState({
            newCorrectionStatus: null,
            comment: {
                value: '',
                error: '',
            },
        });
    }

    private async fetchChangeCorrectionStatus(): Promise<void> {
        const { correctionId } = this.props;
        const { newCorrectionStatus, comment } = this.state;
        await BudgetCorrectionApi.changeCorrectionStatus({
            id: correctionId,
            status: newCorrectionStatus,
            comment: comment.value || '',
        });
    }

    private get isDisableConfirm(): boolean {
        return this.isStatusApproved ? false : Boolean(this.state.comment.error);
    }

    private get isStatusApproved(): boolean {
        const { newCorrectionStatus } = this.state;
        return CorrectionStatus.Approved === newCorrectionStatus;
    }

    private get visibility(): boolean {
        return Boolean(this.state.newCorrectionStatus);
    }

    private get userIsBudgetExpert(): boolean {
        const { user } = this.props;
        return lodash.includes(user.attributes.permissions, PlanPermissionsSysNames.OrganizationAccess);
    }

    private get userIsAuthorCorrection(): boolean {
        const { user, correctionAuthorId } = this.props;
        return user.attributes.id === correctionAuthorId;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const user = getLoginUser(state);

    return {
        user,
    };
}
