import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { isNil } from 'lodash';
import { withRouter } from 'react-router-dom';

import { StoreState } from '@store';
import * as activityTasksPageStore from '@store/activityTasksPage';
import { loadStagesData } from '@store/activityTasksPage/stages';
import { ShareActivityPayload, shareActivity, getIsActivitySharingInProgress } from '@store/activitiesList';
import * as appUsersStore from '@store/appUsers';
import * as divisionStore from '@store/divisions';
import * as commonActions from '@store/common/actions';
import * as taskEditorStore from '@store/taskEditor';
import { resetMyTasks, resetTasksList, fetchMoreTasks, TasksListType } from '@store/tasksList';
import { PageOptions } from '@store/common/types';
import { getLoginUser } from '@store/user';
import { resetDashboardUserConfig } from '@store/userConfig/dashboard';
import * as departmentsStore from '@store/departments';
import * as workTypesStore from '@store/workTypes';
import * as usersStore from '@store/users';

import { ActivityTasksPageContainer } from './ActivityTasksPageContainer';
import {
    ActivityTasksPageMapProps as MappedState,
    ActivityTasksPageDispatchProps as MappedActions,
    ActivityTasksPageStoreProps as StoreProps,
} from './types';

const divisionsEntities = divisionStore.StoreTypes.CURRENT_ORGANIZATION;
const workTypesEntities = workTypesStore.StoreTypes.ACTIVITY_PAGE_FILTER;
const executorsEntities = usersStore.StoreTypes.ACTIVITY_PAGE_EXECUTORS_FILTER;
const authorsEntities = usersStore.StoreTypes.ACTIVITY_PAGE_AUTHORS_FITLER;
const departmentsEntities = departmentsStore.StoreTypes.ACTIVITY_PAGE_FILTERS;

const mapStateToProps = (state: StoreState): MappedState => {
    const activity = activityTasksPageStore.getActivity(state);
    const division = !isNil(activity) ? divisionStore.getDivisionById(state, activity.divisionId) : null;
    const { attributes: user } = getLoginUser(state);

    return {
        activity,
        division,
        user,
        activityLoadingStatus: activityTasksPageStore.getActivityLoadingStatus(state),
        usersLoadingStatus: appUsersStore.getLoadingStatus(state),
        divisionLoadingStatus: divisionStore.getLoadingStatus(state, divisionsEntities),
        activitySharingInProgress: getIsActivitySharingInProgress(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<StoreState>): MappedActions => ({
    loadActivity: (id: number) => dispatch(activityTasksPageStore.loadActivity(id)),
    resetPageStore: () => dispatch(activityTasksPageStore.resetActivityPage(null)),
    switchKeyActivity: (params) => dispatch(activityTasksPageStore.switchKeyActivity(params)),
    loadDivisions: () =>
        dispatch(
            divisionStore.loadDivisions({
                store: divisionsEntities,
            }),
        ),
    resetDivisions: () => dispatch(divisionStore.resetStore(divisionsEntities)),
    resetMyTasks: () => dispatch(resetMyTasks()),
    updatePageOptions: (options: PageOptions) => dispatch(commonActions.updatePageOptions(options)),
    resetEditor: () => dispatch(taskEditorStore.resetTaskEditor()),
    resetTasksList: () => dispatch(resetTasksList()),
    fetchMoreTasks: () => dispatch(fetchMoreTasks(TasksListType.ACTIVITY_TASKS)),
    setRequestInProgress: (isRequestInProgress: boolean) =>
        dispatch(commonActions.setRequestInProgress(isRequestInProgress)),
    resetDashboardUserConfig: () => dispatch(resetDashboardUserConfig()),
    reloadTaskFilters: () => {
        dispatch(workTypesStore.resetStore(workTypesEntities));
        dispatch(usersStore.resetStore(executorsEntities));
        dispatch(usersStore.resetStore(authorsEntities));
        dispatch(departmentsStore.resetStore(departmentsEntities));
    },
    fillActivityTasks: (activityId: number) => dispatch(activityTasksPageStore.fillActivityTasks(activityId)),
    updateActivityTask: (params: activityTasksPageStore.UpdateActivityTaskParams) =>
        dispatch(activityTasksPageStore.updateActivityTask(params)),
    reloadStagesData: () => dispatch(loadStagesData(null)),
    setIsMounted: (isMounted: boolean) => dispatch(activityTasksPageStore.setIsMounted(isMounted)),
    shareActivity: (payload: ShareActivityPayload) => dispatch(shareActivity(payload)),
});

const withStore = connect<MappedState, MappedActions, StoreProps, StoreState>(mapStateToProps, mapDispatchToProps);

export const ActivityTasksPageStore: ComponentType<StoreProps> = withStore(withRouter(ActivityTasksPageContainer));
