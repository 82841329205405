import { createSelector } from 'reselect';
import * as lodash from 'lodash';
import * as moment from 'moment';

import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type {
    AutopilotDOOH,
    AutopilotDOOHRegionality,
    AutopilotDOOHFormatWithTimings,
    AutopilotDOOHMediaplanCalculation,
} from 'sber-marketing-types/backend';
import type { PageState, BriefStageForm, GroupedDictionaries, Sidebar } from './types';
import { AutopilotStage } from './types';

import type { StoreState } from '../';

export const getAutopilotPageState = (state: StoreState): PageState => state.autopilotDOOH;

export const getAutopilot = createSelector(getAutopilotPageState, (state: PageState): AutopilotDOOH => {
    return state.autopilot;
});

export const getActivity = createSelector(getAutopilotPageState, (state: PageState): Activity => {
    return state.activity;
});

export const getMediaplan = createSelector(
    getAutopilotPageState,
    (state: PageState): AutopilotDOOHMediaplanCalculation[] => {
        return state.mediaplan;
    },
);

export const getMediaplanItemsIdsByFormat = createSelector(
    getMediaplan,
    (mediaplanItems: AutopilotDOOHMediaplanCalculation[]): Record<string, string[]> => {
        const groupedMediaplanItemsIds: Record<string, string[]> = {};

        const mediaplanItemsByFormatType = lodash.groupBy(mediaplanItems, (item) => item.format.type);

        const digitalMediaplanItems = mediaplanItemsByFormatType['DIGITAL'];

        const digitalMediaplanItemsByFormat = lodash.groupBy(digitalMediaplanItems, (item) => item.format.abbreviation);

        lodash.forEach(digitalMediaplanItemsByFormat, (items, key) => {
            groupedMediaplanItemsIds[key] = items.map((item) => item.rowId);
        });

        groupedMediaplanItemsIds['static'] = mediaplanItemsByFormatType['STATIC'].map((item) => item.rowId);

        return groupedMediaplanItemsIds;
    },
);

export const getRegionalities = createSelector(
    getAutopilotPageState,
    (state: PageState): AutopilotDOOHRegionality[] => {
        return state.regionalities;
    },
);

export const getFormats = createSelector(
    getAutopilotPageState,
    (state: PageState): AutopilotDOOHFormatWithTimings[] => {
        return state.formats;
    },
);

export const getDictionaries = createSelector(getAutopilotPageState, (state: PageState): GroupedDictionaries => {
    return state.dictionaries;
});

export const getBriefStageForm = createSelector(getAutopilotPageState, (state: PageState): BriefStageForm => {
    return state.briefStageForm;
});

export const getManualMediaplanSideQuantityValues = createSelector(
    getAutopilotPageState,
    (state: PageState): Record<string, number> => {
        return state.manualMediaplanSideQuantityValues;
    },
);

export const getBudgetAutomationStatus = createSelector(getBriefStageForm, (briefForm: BriefStageForm): boolean => {
    return briefForm.budgetAutomation;
});

export const getMediaplanLinesBudgetSnapShot = createSelector(
    getAutopilotPageState,
    (state: PageState): Record<string, number> => {
        return state.mediaplanLinesBudgetSnapShot;
    },
);

export const getMediaplanLinesSideQuantitySnapShot = createSelector(
    getAutopilotPageState,
    (state: PageState): Record<string, number> => {
        return state.mediaplanLinesSideQuantitySnapShot;
    },
);

export const getAutopilotBudget = createSelector(getBriefStageForm, (briefForm: BriefStageForm): number => {
    return parseInt(briefForm.budget, 10);
});

export const getAutopilotDurationInDays = createSelector(getBriefStageForm, (briefForm: BriefStageForm): number => {
    return getDaysCountBetweenDates(briefForm.dateStart, briefForm.dateEnd) + 1;
});

export const getSelectedFormatTimings = createSelector(
    getBriefStageForm,
    (briefForm: BriefStageForm): Record<string, number> => {
        return briefForm.selectedFormatTimings;
    },
);

export const getMediaplanSelectedRowsIds = createSelector(getBriefStageForm, (briefForm: BriefStageForm): string[] => {
    return briefForm.selectedRowsIds;
});

export const getSideCostsByLineIds = createSelector(
    getMediaplan,
    getSelectedFormatTimings,
    getAutopilotDurationInDays,
    (
        mediaplanItems: AutopilotDOOHMediaplanCalculation[],
        selectedFormatTimings: Record<string, number>,
        autopilotDurationInDays: number,
    ): Record<string, number> => {
        return mediaplanItems.reduce((acc, line) => {
            const AVERAGE_MONTH_DAYS_COUNT = 29.3;

            let sideCost: number;

            switch (line.format.type) {
                case 'DIGITAL':
                    const timing = selectedFormatTimings[line.format.abbreviation] || 0;

                    sideCost =
                        (((line.side.basePrice * timing) / line.side.baseTiming) * autopilotDurationInDays) /
                        AVERAGE_MONTH_DAYS_COUNT;
                    break;

                case 'STATIC':
                    sideCost = (line.side.basePrice * autopilotDurationInDays) / AVERAGE_MONTH_DAYS_COUNT;
                    break;
            }

            acc[line.rowId] = sideCost;

            return acc;
        }, {});
    },
);

export const getMediaplanLineBudgetsByLineId = createSelector(
    getMediaplan,
    getManualMediaplanSideQuantityValues,
    getAutopilotBudget,
    getSideCostsByLineIds,
    getMediaplanLinesBudgetSnapShot,
    getMediaplanSelectedRowsIds,
    (
        mediaplanItems: AutopilotDOOHMediaplanCalculation[],
        manualMediaplanSideQuantityValues: Record<string, number>,
        briefBudget: number,
        sideCostsByLineIds: Record<string, number>,
        mediaplanLinesBudgetSnapShot: Record<string, number>,
        selectedLinesIds: string[],
    ): Record<string, number> => {
        const mediaplanLineBudgetsByLineId: Record<string, number> = {};

        const allLineIds = mediaplanItems.map((item) => item.rowId);

        const manualSideQuantityLineIds = lodash.keys(manualMediaplanSideQuantityValues);
        const nonselectedSideQuantityLineIds = lodash.without(
            allLineIds,
            ...manualSideQuantityLineIds,
            ...selectedLinesIds,
        );
        const autoSideQuantitySelectedLineIds = lodash.intersection(
            lodash.without(allLineIds, ...manualSideQuantityLineIds),
            selectedLinesIds,
        );

        manualSideQuantityLineIds.forEach((lineId) => {
            const lineSideCost = sideCostsByLineIds[lineId] || 0;
            const lineManualSideQuantity = manualMediaplanSideQuantityValues[lineId] || 0;

            const lineBudget = lineSideCost * lineManualSideQuantity;

            mediaplanLineBudgetsByLineId[lineId] = lineBudget;
        });

        nonselectedSideQuantityLineIds.forEach((lineId) => {
            mediaplanLineBudgetsByLineId[lineId] = 0;
        });

        const manualSideQuantityLinesBudget = lodash.sum(lodash.values(mediaplanLineBudgetsByLineId));

        const autoSideQuantityLinesBudget = briefBudget - manualSideQuantityLinesBudget;

        const maxSideCostSum = lodash.sumBy(autoSideQuantitySelectedLineIds, (lineId) => {
            const line = mediaplanItems.find((item) => item.rowId === lineId);
            const lineSideCost = sideCostsByLineIds[lineId] || 0;

            return line.side.maxSideQuantity * lineSideCost;
        });

        autoSideQuantitySelectedLineIds.forEach((lineId) => {
            if (mediaplanLinesBudgetSnapShot && mediaplanLinesBudgetSnapShot[lineId] !== undefined) {
                mediaplanLineBudgetsByLineId[lineId] = mediaplanLinesBudgetSnapShot[lineId];
            } else if (autoSideQuantityLinesBudget < 0) {
                mediaplanLineBudgetsByLineId[lineId] = 0;
            } else {
                const line = mediaplanItems.find((item) => item.rowId === lineId);
                const lineSideCost = sideCostsByLineIds[lineId] || 0;

                const lineBudget =
                    (autoSideQuantityLinesBudget * line.side.maxSideQuantity * lineSideCost) / maxSideCostSum;

                mediaplanLineBudgetsByLineId[lineId] = lineBudget;
            }
        });

        return mediaplanLineBudgetsByLineId;
    },
);

export const getMediaplanSideQuantityByLineId = createSelector(
    getMediaplan,
    getManualMediaplanSideQuantityValues,
    getSideCostsByLineIds,
    getMediaplanLineBudgetsByLineId,
    getMediaplanLinesSideQuantitySnapShot,
    getMediaplanSelectedRowsIds,
    (
        mediaplanItems: AutopilotDOOHMediaplanCalculation[],
        manualMediaplanSideQuantityValues: Record<string, number>,
        sideCostsByLineIds: Record<string, number>,
        lineBudgetsByLineId: Record<string, number>,
        mediaplanLinesSideQuantitySnapShot: Record<string, number>,
        selectedLinesIds: string[],
    ): Record<string, number> => {
        const mediaplanSideQuantityByLineId: Record<string, number> = {};

        const allLineIds = mediaplanItems.map((item) => item.rowId);

        const manualSideQuantityLineIds = lodash.keys(manualMediaplanSideQuantityValues);
        const nonselectedSideQuantityLineIds = lodash.without(
            allLineIds,
            ...manualSideQuantityLineIds,
            ...selectedLinesIds,
        );
        const autoSideQuantitySelectedLineIds = lodash.intersection(
            lodash.without(allLineIds, ...manualSideQuantityLineIds),
            selectedLinesIds,
        );

        lodash.forEach(manualMediaplanSideQuantityValues, (manualValue, lineId) => {
            mediaplanSideQuantityByLineId[lineId] = manualValue;
        });

        nonselectedSideQuantityLineIds.forEach((lineId) => {
            mediaplanSideQuantityByLineId[lineId] = 0;
        });

        autoSideQuantitySelectedLineIds.forEach((lineId) => {
            if (mediaplanLinesSideQuantitySnapShot && mediaplanLinesSideQuantitySnapShot[lineId] !== undefined) {
                mediaplanSideQuantityByLineId[lineId] = mediaplanLinesSideQuantitySnapShot[lineId];
            } else {
                const lineBudget = lineBudgetsByLineId[lineId] || 0;
                const lineSideCost = sideCostsByLineIds[lineId] || 0;

                const lineSideQuantity = Math.floor(lineBudget / lineSideCost);

                mediaplanSideQuantityByLineId[lineId] = lineSideQuantity;
            }
        });

        return mediaplanSideQuantityByLineId;
    },
);

export const getSelectedBudgetItemIds = createSelector(getAutopilotPageState, (state: PageState): string[] => {
    return state.selectedBudgetItemIds;
});

export const getCurrentStage = createSelector(getAutopilotPageState, (state: PageState): AutopilotStage => {
    return state.currentStage;
});

export const getPreloaderStatus = createSelector(getAutopilotPageState, (state: PageState): boolean => {
    return state.preloader;
});

export const getSidebar = createSelector(getAutopilotPageState, (state: PageState): Sidebar => {
    return state.sidebar;
});

function getDaysCountBetweenDates(date1: string, date2: string): number {
    if (!date1 || !date2) {
        return null;
    }

    let daysCount = Math.abs(moment(date1).diff(moment(date2), 'days'));

    if (daysCount > 0) {
        daysCount += 1;
    }

    return daysCount;
}
