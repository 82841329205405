import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import * as style from './Header.scss';

import { MenuItem, WithTooltip, TooltipAnchor, Icon, IconType } from 'sber-marketing-ui';
import { DashboardHeaderItems } from '@modules/common/DashboardHeaderItems';
import { QuickAccessIcons } from '@common/QuickAccessIcons';
import { ContractsSelect } from './ContractsSelect';

const enum LotUrl {
    Lot1 = '/pivotTable/lot1',
    Lot2 = '/pivotTable/lot2',
}

const TOP_LINK_OPTIONS: LinkOptions[] = [
    {
        qaId: 'headerMenuBudgetPlanning',
        link: LotUrl.Lot1,
        title: 'Лот 1',
    },
    {
        qaId: 'headerMenuBudgetExecution',
        link: LotUrl.Lot2,
        title: 'Лот 2',
    },
];

interface LinkOptions {
    qaId?: string;
    title: string;
    link: LotUrl;
    disabled?: boolean;
}

interface HeaderTopProps extends Partial<RouteComponentProps<{ lot: 'lot1' | 'lot2' }>> {}

function HeaderTop({ match }: HeaderTopProps): JSX.Element {
    const currentUrl = match.url;

    return (
        <div className={style.root}>
            <div className={style.menuItems}>
                <DashboardHeaderItems currentUrl={currentUrl} />
            </div>

            <div className={style.quickAccessIcons}>
                <QuickAccessIcons />
            </div>
        </div>
    );
}

export const HeaderTopWithRouter = withRouter(HeaderTop);

interface HeaderBottomProps extends RouteComponentProps<{ lot: 'lot1' | 'lot2' }> {
    onContractChange: (lot: number, contractId: string) => void;
    onDownloadXLSXButtonClick: () => void;
}

export const HeaderBottom = withRouter(
    ({ match, onContractChange, onDownloadXLSXButtonClick }: HeaderBottomProps): JSX.Element => {
        const currentUrl = match.url;

        return (
            <div className={style.root}>
                <div className={style.contractsSelect}>
                    <ContractsSelect lot={match.params.lot} onContractChange={onContractChange} />
                </div>

                {TOP_LINK_OPTIONS.map((item) => (
                    <MenuItem
                        qaId={item.qaId}
                        key={item.link}
                        currentUrl={currentUrl}
                        link={item.link}
                        disabled={item.disabled}
                        active={item.link == currentUrl}
                    >
                        <span>{item.title}</span>
                    </MenuItem>
                ))}

                <div className={style.downloadButton} onClick={onDownloadXLSXButtonClick}>
                    <WithTooltip content={'Скачать таблицу'} anchor={TooltipAnchor.LEFT}>
                        <Icon type={IconType.DOWNLOAD_DOCUMENT} svgSize={20} />
                    </WithTooltip>
                </div>
            </div>
        );
    },
);
