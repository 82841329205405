import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';

import { UserConfigType } from 'sber-marketing-types/openid';
import { TaskCardType } from '@store/tasksList/types';

import { CardsViewSwitch } from './CardsViewSwitch';
import { StoreState } from '@store';
import { setCardType, resetMyTasks, resetTasksList } from '@store/tasksList/actions';
import { getCardType, getActivityId } from '@store/tasksList/selectors';
import { UserConfigApi } from '@api';

interface Props extends Partial<MapProps>, Partial<DispatchProps> {}

interface MapProps {
    cardType: TaskCardType;
    activityId: number;
}

interface DispatchProps {
    setCardType: (cardType: TaskCardType) => void;
    resetMyTasks: () => void;
    resetTasksList: () => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class CardsViewSwitchContainer extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        return React.createElement(CardsViewSwitch, {
            cardType: this.props.cardType,
            onSelection: this.onSelection,
        });
    }

    @autobind
    protected async onSelection(cardType: TaskCardType) {
        if (cardType !== this.props.cardType) {
            this.props.setCardType(cardType);

            if (this.props.activityId) {
                this.props.resetTasksList();
            } else {
                this.props.resetMyTasks();
            }

            await UserConfigApi.savePageConfig(UserConfigType.TasksList, { cardType });
        }
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        cardType: getCardType(state),
        activityId: getActivityId(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setCardType,
            resetMyTasks,
            resetTasksList,
        },
        dispatch,
    );
}
