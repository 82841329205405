import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { Autopilot } from 'sber-marketing-types/backend';

import { BudgetStageActionsTemplate } from './BudgetStageActionsTemplate';
import type { StoreState } from '@store';
import { setCurrentStage } from '@store/autopilot_legacy/actions';
import { getAutopilot, getSelectedBudgetItemIds } from '@store/autopilot_legacy/selectors';
import { Loader, Saver } from '../../modules';

interface Props extends MapProps, DispatchProps {
    canEdit: boolean;
}

interface MapProps {
    autopilot?: Autopilot;
    selectedBudgetItemIds?: string[];
}

interface DispatchProps {
    setCurrentStage?: (stage: number) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BudgetStageActionsBehaviour extends React.PureComponent<Props> {
    private loader: Loader;
    private saver: Saver;

    public constructor(props: Props) {
        super(props);

        this.loader = Loader.getInstance();
        this.saver = Saver.getInstance();
    }

    public render(): JSX.Element {
        const { selectedBudgetItemIds } = this.props;

        return React.createElement(BudgetStageActionsTemplate, {
            canEdit: this.props.canEdit,
            budgetItemSelected: !lodash.isEmpty(selectedBudgetItemIds),
            onContinueButtonClick: this.onContinueButtonClick,
        });
    }

    @autobind
    protected async onContinueButtonClick() {
        await this.saver.saveSelectedBudgetItems();

        this.props.setCurrentStage(4);

        await this.loader.loadAutopilot();
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        autopilot: getAutopilot(state),
        selectedBudgetItemIds: getSelectedBudgetItemIds(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setCurrentStage,
        },
        dispatch,
    );
}
