import * as React from 'react';
import autobind from 'autobind-decorator';

import { StickyWrapperTemplate, Position, HEADER_HEIGHT } from './StickyWrapperTemplate';

const PAGE_SCROLL_CONTAINER_ID = 'pageContent';
const TABLE_HEADER_HEIGHT = 39;

interface Props {
    children: React.ReactNode;
    position: Position;
    block: HTMLDivElement;
    customStyle?: any;
}

interface State {
    isVisible: boolean;
}

export class StickyWrapperBehaviour extends React.PureComponent<Props, State> {
    private rootRef: React.RefObject<HTMLDivElement>;
    private scrollContainer: HTMLElement;

    public constructor(props: Props) {
        super(props);

        this.state = {
            isVisible: false,
        };

        this.rootRef = React.createRef<HTMLDivElement>();
    }

    public async componentDidMount() {
        this.scrollContainer = document.getElementById(PAGE_SCROLL_CONTAINER_ID);

        this.updateVisibility();

        this.scrollContainer.addEventListener('scroll', this.onPageScroll);
        window.addEventListener('resize', this.onPageResize);
    }

    public async componentWillUnmount() {
        this.scrollContainer.removeEventListener('scroll', this.onPageScroll);
        window.removeEventListener('resize', this.onPageResize);
    }

    public componentDidUpdate(prevProps: Props) {
        const blockChanged = this.props.block !== prevProps.block;

        if (blockChanged) {
            this.updateVisibility();
        }
    }

    public render(): JSX.Element {
        const { children, customStyle, position } = this.props;
        const { isVisible } = this.state;

        return React.createElement(StickyWrapperTemplate, {
            children,
            position,
            isVisible,
            rootRef: this.rootRef,
            customStyle,
        });
    }

    @autobind
    protected onPageResize() {
        this.updateVisibility();
    }

    @autobind
    protected onPageScroll() {
        this.updateVisibility();
    }

    private updateVisibility() {
        const { block, position } = this.props;

        if (!block) {
            this.setState({
                isVisible: false,
            });

            return;
        }

        const { top, bottom } = block.getBoundingClientRect();

        let isVisible = false;

        if (position === Position.Top) {
            const blockTopCrossesPageTop = top <= HEADER_HEIGHT;
            const blockBottomCrossesPageTop = Math.abs(top) + HEADER_HEIGHT + TABLE_HEADER_HEIGHT >= block.clientHeight;

            isVisible = blockTopCrossesPageTop && !blockBottomCrossesPageTop;
        } else {
            const pageContentHeight = this.scrollContainer.clientHeight;

            const blockBottomCrossesPageBottom = bottom >= HEADER_HEIGHT + pageContentHeight;
            const blockTopCrossesPageBottom = top >= HEADER_HEIGHT + pageContentHeight;

            isVisible = blockBottomCrossesPageBottom && !blockTopCrossesPageBottom;
        }

        this.setState({
            isVisible,
        });
    }
}
