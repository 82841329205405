import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type { SelectItem } from 'sber-marketing-ui';

import { CreativeSidebarState } from './sidebar';
import type {
    CreativeRequest,
    CreativeRequestSubject,
    CreativeRequestDonor,
    CreativeRequestTenderDocument,
    CreativeRequestBudgetItem,
    CreativeRequestTenderDocumentProtocolFile,
    CreativeRequestBudgetItemFunds,
} from '@api';

export const enum CreativeRequestTenderDocumentStatus {
    waiting = 'waiting',
    approved = 'approved',
}

export interface PageState {
    sidebar: CreativeSidebarState;
    rest: RestPageState;
}

export interface RestPageState {
    activity: Activity;
    requestStageForm: RequestStageForm;
    currentStage: number;
    selectedStage: number;
    creativeRequestDomain: CreativeRequestDomain;
    loading: boolean;
}

export type RequestStageForm = Record<FieldName, FieldParams>;

export interface FieldParams {
    name?: FieldName;
    value?: any;
    code?: string;
    items?: SelectItem[];
    required: boolean;
}

export interface CellPosition {
    columnName: ColumnName;
    lineId: any;
}

export interface SwitchKeyActivityParams {
    id: number;
    isKey: boolean;
}

export interface InitCreativeRequestDomainParams {
    creativeRequestId: string;
}

export type InitCreativeRequestDomainResult = CreativeRequestDomain;

export type CreativeRequestDomain = CreativeRequest;
export type CreativeRequestBudgetItemDomain = CreativeRequestBudgetItem;

export const enum FieldName {
    // Lot = 'lot',
    // Description = 'description',
    // Expert = 'expert',
    // AgencyExecutor = 'agencyExecutor',
    // Block = 'block',
    // Division = 'division',
    // Segment = 'segment',
    // Product = 'product',
    // Channel = 'channel',
    // Initiator = 'initiator',
    // Customer = 'customer',
    BudgetItemId = 'budgetItemId',
}

export const enum TableType {
    Tariff = 'Тариф',
    Production = 'Производство',
    Ak = 'АК',
}

export const TableNumberByType = {
    [TableType.Tariff]: 1,
    [TableType.Production]: 2,
    [TableType.Ak]: 3,
};

export const enum ColumnName {
    SerialNumber = 'serialNumber',
    SourceId = 'sourceId',
    ExecutionId = 'executionId',
    ProjectName = 'projectName',
    StartDate = 'startDate',
    EndDate = 'endDate',
    TariffName = 'tariffName',
    TariffQuantity = 'tariffQuantity',
    TariffLimit = 'tariffLimit',
    ActualCostExcludingVat = 'actualCostExcludingVat',
    Vat = 'vat',
    ActualCostWithVat = 'actualCostWithVat',
    RightsType = 'rightsType',
    RightsTypeInput = 'rightsTypeInput',
    ValidityTerritory = 'validityTerritory',
    RightsDuration = 'rightsDuration',
    ImageLink = 'imageLink',
    Reserve = 'reserve',
    Fact = 'fact',
    CreativeType = 'creativeType',
    ImageNumber = 'imageNumber',
    ThirdParty = 'thirdParty',
    CommissionAmount = 'commissionAmount',
    CommissionWithoutVat = 'commissionWithoutVat',
    CommissionWithVat = 'commissionWithVat',
    Documents = 'documents',
    Creatives = 'creatives',
    LineStatus = 'lineStatus',
    ActStatus = 'actStatus',
}

export const ColumnNameTitles: Record<ColumnName, string> = {
    [ColumnName.SerialNumber]: '№',
    [ColumnName.SourceId]: 'ID источ.',
    [ColumnName.ExecutionId]: 'ID испол.',
    [ColumnName.ProjectName]: 'Название РК/Проекта',
    [ColumnName.StartDate]: 'Дата начала кампании',
    [ColumnName.EndDate]: 'Дата оконч. кампании',
    [ColumnName.TariffName]: 'Название тарифа',
    [ColumnName.TariffQuantity]: 'Тарифы, шт.',
    [ColumnName.TariffLimit]: 'Лимит тарифа до НДС за 1 шт',
    [ColumnName.ActualCostExcludingVat]: 'Фактическая стоимость без НДС',
    [ColumnName.Vat]: 'Ставка НДС, %',
    [ColumnName.ActualCostWithVat]: 'Фактическая стоимость с НДС',
    [ColumnName.RightsType]: 'Вид передаваемых прав',
    [ColumnName.RightsTypeInput]: 'Вид передаваемых прав',
    [ColumnName.ValidityTerritory]: 'Территория действия прав',
    [ColumnName.RightsDuration]: 'Срок действия прав',
    [ColumnName.ImageLink]: 'Ссылка на имидж',
    [ColumnName.Reserve]: '',
    [ColumnName.Fact]: '',
    [ColumnName.CreativeType]: 'Тип работ / материала',
    [ColumnName.ImageNumber]: '№ имиджа',
    [ColumnName.ThirdParty]: '3е лицо (юр.название)',
    [ColumnName.CommissionAmount]: 'Размер комиссии',
    [ColumnName.CommissionWithoutVat]: 'Сумма комиссии без НДС',
    [ColumnName.CommissionWithVat]: 'Сумма комиссии с НДС',
    [ColumnName.Documents]: 'Смета\nБиддинг',
    [ColumnName.Creatives]: 'Результат\nработ',
    [ColumnName.LineStatus]: 'Стр\nока',
    [ColumnName.ActStatus]: 'Акт',
};

export const enum ColumnType {
    LineHeader = 'line_header',
    Text = 'text',
    Input = 'input',
    FundsInput = 'funds_input',
    FundsSelect = 'funds_select',
    Select = 'select',
    CheckboxList = 'checkbox_list',
    Datepicker = 'datepicker',
    RangeDatepickerCell = 'range_datepicker',
    Files = 'files',
    Status = 'status',
}

export type {
    CreativeRequestSubject,
    CreativeRequestDonor,
    CreativeRequestTenderDocument,
    CreativeRequestTenderDocumentProtocolFile,
    CreativeRequestBudgetItemFunds,
};
