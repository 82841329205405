import { actionCreatorFactory } from 'typescript-fsa';

import { SetSelectedBudgetItemIdsParams } from '../types';

const actionCreator = actionCreatorFactory('CREATIVE_REQUEST_MENU');

const setCreativeRequestId = actionCreator<string>('SET_CREATIVE_REQUEST_ID');
const removeCreativeRequestId = actionCreator<string>('REMOVE_CREATIVE_REQUEST_ID');

const setSelectedBudgetItemIds = actionCreator<SetSelectedBudgetItemIdsParams>('SET_SELECTED_BUDGET_ITEM_IDS');
const removeBudgetItemId = actionCreator<string>('REMOVE_BUDGET_ITEM_ID');

export { setCreativeRequestId, setSelectedBudgetItemIds, removeCreativeRequestId, removeBudgetItemId };
