import * as React from 'react';
import { sum, values, flatMap } from 'lodash';
import { Correction, CorrectionType } from '@mrm/budget';
import { Icon, IconType, WithGlobalPopup, AddaptivePositionCalculator } from 'sber-marketing-ui';

import { Money, MoneyFormatter } from '@common/Utils';

import * as styles from './CorrectionsPopup.scss';

interface Props {
    planCorrections: Correction<CorrectionType.PlanFundsTransfer>[];
    reserveCorrections: Correction<CorrectionType.ReservedFunds>[];
}

export function CorrectionsPopup({ planCorrections, reserveCorrections }: Props): JSX.Element {
    const iconRef = React.useRef();
    const [isActive, setIsActive] = React.useState(false);
    const shouldRender = !!(planCorrections?.length || reserveCorrections?.length);

    const planSum = planCorrections ? sum(planCorrections.map((correction) => correction.data.params.value)) : 0;
    const reserveSum = reserveCorrections
        ? sum(flatMap(reserveCorrections, (correction) => values(correction.data.params)))
        : 0;

    return shouldRender ? (
        <div
            ref={iconRef}
            className={styles.button}
            onMouseEnter={() => setIsActive(true)}
            onMouseLeave={() => setIsActive(false)}
        >
            <Icon type={IconType.INFO_CIRCLE} svgSize={16} />

            {isActive && (
                <WithGlobalPopup right={150} container={iconRef} positionCalculator={AddaptivePositionCalculator}>
                    <div className={styles.popup}>
                        <CorrectionsSum title="Сумма неутвержденных корр-ок по Плану" amount={planSum} />

                        <CorrectionsSum title="Сумма неутвержденных корр-ок по Резерву" amount={reserveSum} />
                    </div>
                </WithGlobalPopup>
            )}
        </div>
    ) : null;
}

interface CorrectionsSumProps {
    title: string;
    amount: number;
}

function CorrectionsSum({ title, amount }: CorrectionsSumProps): JSX.Element {
    return amount ? (
        <div className={styles.item}>
            {title}:&nbsp;
            <span className={styles.itemBold}>+{MoneyFormatter.toRoubles(Money.fromCopecks(amount))}</span>
        </div>
    ) : null;
}
