import * as React from 'react';

import * as style from './NotificationMessages.scss';

interface ActivityBudgetMessageProps {
    activityId: string;
    budgetId: string;
    organizationId: string;
    activityName: string;
}

export const ActivityBudgetCreateMessage = ({
    activityId,
    budgetId,
    organizationId,
    activityName,
}: ActivityBudgetMessageProps): JSX.Element => {
    const link = `/budget/planning/${activityId}?budgetId=${budgetId}&dictionariesOrganizationId=${organizationId}`;

    return (
        <span className={style.root}>
            Активность &nbsp;
            <a className={style.link} href={link}>
                {activityName}
            </a>
            &nbsp; создана успешно
        </span>
    );
};

export const ActivityBudgetUpdateMessage = ({
    activityId,
    budgetId,
    organizationId,
    activityName,
}: ActivityBudgetMessageProps): JSX.Element => {
    const link = `/budget/planning/${activityId}?budgetId=${budgetId}&dictionariesOrganizationId=${organizationId}`;

    return (
        <span className={style.root}>
            Активность &nbsp;
            <a className={style.link} href={link}>
                {activityName}
            </a>
            &nbsp; сохранена успешно
        </span>
    );
};

interface BudgetItemMessageProps {
    activityId: string;
    budgetId: string;
    organizationId: string;
    serialNumber: number;
    sapComment: string;
}

export const BudgetItemCreateMessage = ({
    activityId,
    budgetId,
    organizationId,
    serialNumber,
    sapComment,
}: BudgetItemMessageProps): JSX.Element => {
    const link = `/budget/planning/${activityId}?budgetId=${budgetId}&dictionariesOrganizationId=${organizationId}`;

    return (
        <span className={style.root}>
            Строка &nbsp;
            <a className={style.link} href={link}>
                ID {serialNumber} - {sapComment}
            </a>
            &nbsp; создана успешно
        </span>
    );
};

export const BudgetItemPublishMessage = ({
    activityId,
    budgetId,
    organizationId,
    serialNumber,
    sapComment,
}: BudgetItemMessageProps): JSX.Element => {
    const link = `/budget/planning/${activityId}?budgetId=${budgetId}&dictionariesOrganizationId=${organizationId}`;

    return (
        <span className={style.root}>
            Строка &nbsp;
            <a className={style.link} href={link}>
                ID {serialNumber} - {sapComment}
            </a>
            &nbsp; опубликована успешно
        </span>
    );
};

export const BudgetItemUpdateMessage = ({
    activityId,
    budgetId,
    organizationId,
    serialNumber,
    sapComment,
}: BudgetItemMessageProps): JSX.Element => {
    const link = `/budget/planning/${activityId}?budgetId=${budgetId}&dictionariesOrganizationId=${organizationId}`;

    return (
        <span className={style.root}>
            Строка &nbsp;
            <a className={style.link} href={link}>
                ID {serialNumber} - {sapComment}
            </a>
            &nbsp; сохранена успешно
        </span>
    );
};

export const BudgetItemDeleteMessage = ({
    serialNumber,
    sapComment,
}: Pick<BudgetItemMessageProps, 'serialNumber' | 'sapComment'>): JSX.Element => {
    return (
        <span className={style.root}>
            Строка ID {serialNumber} - {sapComment} удалена успешно
        </span>
    );
};
