import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { BudgetItemApi } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';

import * as asyncActions from './actions/async';
import * as syncActions from './actions/sync';
import { getLineModalState } from './selectors';
import { Comment } from './types';

const limit = 10;

export const sendComment = bindThunkAction<StoreState, string, void, Error>(
    asyncActions.sendComment,
    async (comment, dispatch, getState) => {
        const {
            lineId,
            comments: { items },
        } = getLineModalState(getState());
        const pendingComment: Comment = {
            ...items[0],
            id: `${items[0].id}sending`,
            text: comment,
        }; // workaround for missing api

        dispatch(syncActions.setPendingComment(pendingComment));

        await BudgetItemApi.sendComment(lineId, comment);

        dispatch(syncActions.setPendingComment(null));
    },
);

export const fetchComments = bindThunkAction<StoreState, void, void, Error>(
    asyncActions.fetchComments,
    async (_, dispatch, getState) => {
        const {
            lineId,
            comments: { loadingStatus, offset, canLoadMore },
        } = getLineModalState(getState());

        if (canLoadMore && loadingStatus !== LoadingStatus.LOADING) {
            dispatch(syncActions.setCommentsLoadingStatus(LoadingStatus.LOADING));

            const response = await BudgetItemApi.getComments(lineId, {
                limit,
                offset,
            });

            dispatch(
                syncActions.loadComments({
                    ...response,
                    canLoadMore: response.items.length === limit,
                }),
            );
            dispatch(syncActions.setCommentsLoadingStatus(LoadingStatus.LOADED));
        }
    },
);
