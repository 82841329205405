import * as React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { StaticSkeleton } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getActivityStageState } from '@store/activityTasksPage/stages';
import { getActivityTasksPageState } from '@store/activityTasksPage';

import { DatesFormatter } from '@common/Utils';

import { Item } from './Item';

import * as styles from './StagesList.scss';

export function StagesList(): JSX.Element {
    const stageRequestInProgress = useSelector(
        (state: StoreState) => getActivityStageState(state).stageRequestInProgress,
    );
    const stages = useSelector((state: StoreState) => getActivityStageState(state).pendingStages.entities);
    const projectStart = useSelector((state: StoreState) => getActivityTasksPageState(state).activity.preparationDate);
    const projectEnd = useSelector((state: StoreState) => getActivityTasksPageState(state).activity.debriefingDate);

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'stagesEditorPopupStagesList',
            }}
        >
            <div className={styles.title}>
                <div>
                    <span className={styles.titleBold}>Этапы</span>
                    &nbsp;
                    {!stageRequestInProgress ? stages.filter((stage) => stage.active).length : null}
                </div>

                {projectStart && projectEnd && (
                    <div>
                        <span className={styles.titleBold}>Даты проекта</span>
                        &nbsp;
                        {DatesFormatter.ddMonthyy(projectStart)} - {DatesFormatter.ddMonthyy(projectEnd)}
                    </div>
                )}
            </div>

            {!stageRequestInProgress ? (
                <div className={styles.content}>
                    {stages.map((stage) => (
                        <div key={stage.id} className={styles.item}>
                            <Item {...stage} />
                        </div>
                    ))}
                </div>
            ) : (
                <div className={classnames(styles.content, styles.preloaderContent)}>
                    <ItemPreloader />
                    <ItemPreloader />
                    <ItemPreloader />
                    <ItemPreloader />
                </div>
            )}
        </div>
    );
}

function ItemPreloader(): JSX.Element {
    return (
        <div className={styles.itemPreloaderWrapper}>
            <StaticSkeleton className={styles.itemPreloaderFirstColumn} />

            <div className={styles.itemPreloaderSecondColumn}>
                <StaticSkeleton className={styles.itemPreloaderSecondColumnFirstPreloader} />
                <StaticSkeleton className={styles.itemPreloaderSecondColumnSecondPreloader} />
            </div>

            <StaticSkeleton className={styles.itemPreloaderThirdColumn} />
        </div>
    );
}
