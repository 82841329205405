import * as React from 'react';
import classNames from 'classnames';
import { Moment } from 'moment';
import {
    Toggle,
    TogglePosition,
    ToggleThemes,
    OpacityTransition,
    MenuItem,
    LabeledSelect_redesign as LabeledSelect,
    LabeledTextarea_redesign as LabeledTextarea,
    SelectItem,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    Icon,
    IconType,
    WithTooltip,
} from 'sber-marketing-ui';

import { PageValues, ValidationMode } from '@store/newActivity/types';
import { DataUpdateStrategy } from '@store/tagsEditor';

import { Utils } from '@common/Utils';
import { QuickAccessIcons } from '@common/QuickAccessIcons';
import { TagsPreviewAndEditor } from '@modules/tags/TagsPreviewAndEditor';

import { LabeledDatepicker } from './LabeledDatepicker';
import { LabeledInput } from './LabeledInput';

import * as styles from './EditOrCreateProjectPage.scss';

const ERROR_MESSAGE = 'Обязательное поле';
const MAX_ACTIVITY_NAME_LENGTH = 255;

interface Props {
    activityId: number;
    preload: boolean;
    validationMode: ValidationMode;
    isPrivate: boolean;
    blocks: SelectItem[];
    productsByBlock: {
        [key: string]: SelectItem[];
    };
    calendarGroupsByBlock: {
        [key: string]: SelectItem[];
    };
    activityTypes: SelectItem[];
    responsibles: SelectItem[];
    values: PageValues;
    bpmWasInit: boolean;
    tagsEditorId: string;
    onActivityTypeSelect: (item: string) => void;
    onProductSelect: (item: string) => void;
    onCalendarGroupSelect: (item: string) => void;
    onResponsibleSelect: (item: number) => void;
    onTitleChange: (title: string) => void;
    onDescriptionChange: (description: string) => void;
    onBlockSelection: (value: React.ReactText) => void;
    onPreparationDateChange: (date: Moment) => void;
    onRealizationStartDateChange: (date: Moment) => void;
    onRealizationEndDateChange: (date: Moment) => void;
    onDebriefingDateChange: (date: Moment) => void;
    onAutoCreateTasksToggle: () => void;
}

export const EditOrCreateProjectPage = ({
    activityId,
    preload,
    validationMode,
    values,
    blocks,
    productsByBlock,
    calendarGroupsByBlock,
    activityTypes,
    responsibles,
    bpmWasInit,
    tagsEditorId,
    onProductSelect,
    onCalendarGroupSelect,
    onActivityTypeSelect,
    onResponsibleSelect,
    onTitleChange,
    onDescriptionChange,
    onBlockSelection,
    onPreparationDateChange,
    onRealizationStartDateChange,
    onRealizationEndDateChange,
    onDebriefingDateChange,
    onAutoCreateTasksToggle,
}: Props): JSX.Element => {
    const {
        name,
        description,
        divisionId,
        realizationStart,
        realizationEnd,
        preparationDate,
        debriefingDate,
        productId,
        calendarGroupId,
        activityTypeId,
        responsibleId,
    } = values;

    const activityDuration =
        realizationStart && realizationEnd ? Utils.calculateDateRange(realizationStart, realizationEnd) : null;

    function displayError(value: any, validationModesToCheck: ValidationMode[] = [ValidationMode.All]): boolean {
        return !value && validationModesToCheck.some((mode) => validationMode === mode);
    }

    // const responsibleLabel = displayError(responsibleId) ?
    //     ERROR_MESSAGE :
    //     'Контактное лицо может быть указано со стороны заказчика или исполнителя';

    return (
        <div className={styles.root}>
            <OpacityTransition start={!preload}>
                <div className={classNames(styles.section, styles.projectNameBlockWrapper)}>
                    <div className={styles.column} />

                    <div className={classNames(styles.content, styles.projectNameBlock)}>
                        <LabeledInput
                            qaId="titleInput"
                            title="Название проекта"
                            value={name}
                            onValueChange={onTitleChange}
                            errorMessage={ERROR_MESSAGE}
                            displayError={displayError(name, [ValidationMode.All, ValidationMode.Title])}
                            maxLength={MAX_ACTIVITY_NAME_LENGTH}
                        />

                        <LabeledTextarea
                            qaId="descriptionTextarea"
                            title="Описание проекта"
                            value={description}
                            onValueChange={onDescriptionChange}
                            errorMessage={ERROR_MESSAGE}
                            displayError={displayError(description)}
                        />

                        <TagsPreviewAndEditor
                            id={tagsEditorId}
                            activityId={activityId}
                            dataUpdateStrategy={DataUpdateStrategy.OnDemand}
                            className={styles.tagsEditor}
                        />

                        <div className={styles.autoCreateTasksToggleWrapper}>
                            <div className={styles.autoCreateTasksToggleLabel}>Автоматизация этапов и задач</div>

                            <WithTooltip content="Автоматизация уже включена" hidden={!bpmWasInit}>
                                <Toggle
                                    qaId={'autoCreateTasksToggle'}
                                    position={values.autoCreateTasks ? TogglePosition.RIGHT : TogglePosition.LEFT}
                                    theme={ToggleThemes.LARGE}
                                    onClick={!bpmWasInit ? onAutoCreateTasksToggle : null}
                                />
                            </WithTooltip>
                        </div>
                    </div>

                    <div className={styles.column}>
                        <div className={classNames(styles.infoButton, styles.autoCreateTasksToggleInfoButton)}>
                            <WithTooltip
                                content={`В созданном проекте будут\nавтоматически сгенерированы и\nпредзаполнены этапы и задачи проекта`}
                            >
                                <div
                                    {...{
                                        'qa-id': 'autoCreateTasksInfoMarker',
                                    }}
                                >
                                    <Icon type={IconType.INFO_ICON} svgSize={28} />
                                </div>
                            </WithTooltip>
                        </div>
                    </div>
                </div>

                <div className={classNames(styles.section, styles.projectInfoBlockWrapper)}>
                    <div className={styles.column} />

                    <div className={classNames(styles.content, styles.projectInfoBlock)}>
                        <div className={styles.row}>
                            <div className={styles.selectWrapper}>
                                <LabeledSelect
                                    qaId="blockDropdown"
                                    title="Блоки"
                                    items={blocks}
                                    selectedValue={divisionId}
                                    onSelection={onBlockSelection}
                                    errorMessage={ERROR_MESSAGE}
                                    displayError={displayError(divisionId)}
                                    hideDisabledOptions
                                    scrollMenuIntoView={false}
                                />
                            </div>

                            <div className={styles.selectWrapper}>
                                <LabeledSelect
                                    qaId="productDropdown"
                                    title="Название продукта"
                                    items={productsByBlock[divisionId] || []}
                                    selectedValue={productId}
                                    onSelection={onProductSelect}
                                    errorMessage={ERROR_MESSAGE}
                                    displayError={displayError(productId)}
                                    hideDisabledOptions
                                    scrollMenuIntoView={false}
                                />
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.selectWrapper}>
                                <LabeledSelect
                                    qaId="activityTypeDropdown"
                                    title="Тип проекта"
                                    items={activityTypes}
                                    selectedValue={activityTypeId}
                                    onSelection={onActivityTypeSelect}
                                    errorMessage={ERROR_MESSAGE}
                                    displayError={displayError(activityTypeId)}
                                    hideDisabledOptions
                                    scrollMenuIntoView={false}
                                />
                            </div>

                            <div className={styles.selectWrapper}>
                                <LabeledSelect
                                    qaId="responsibleDropdown"
                                    title="Ответственное лицо"
                                    items={responsibles}
                                    selectedValue={responsibleId}
                                    onSelection={onResponsibleSelect}
                                    errorMessage={ERROR_MESSAGE}
                                    displayError={displayError(responsibleId)}
                                    scrollMenuIntoView={false}
                                />

                                {!displayError(responsibleId) && (
                                    <div className={styles.responsibleSubtitle}>
                                        Контактное лицо может быть указано со стороны заказчика или исполнителя
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.selectWrapper}>
                                <LabeledSelect
                                    qaId="calendarGroupDropdown"
                                    title="Группа в календаре"
                                    items={calendarGroupsByBlock[divisionId] || []}
                                    selectedValue={calendarGroupId}
                                    onSelection={onCalendarGroupSelect}
                                    errorMessage={ERROR_MESSAGE}
                                    displayError={displayError(calendarGroupId)}
                                    hideDisabledOptions
                                    scrollMenuIntoView={false}
                                />
                            </div>
                        </div>

                        <div className={styles.row}>
                            <Datepicker
                                qaId="preparationDatePicker"
                                title="Старт подготовки"
                                date={preparationDate}
                                displayError={displayError(preparationDate)}
                                onChange={onPreparationDateChange}
                            />

                            <Datepicker
                                qaId="realizationStartDatePicker"
                                title="Старт проекта"
                                date={realizationStart}
                                displayError={displayError(realizationStart)}
                                onChange={onRealizationStartDateChange}
                            />

                            <div className={styles.dateRangeDash}>—</div>

                            <Datepicker
                                qaId="realizationEndDatePicker"
                                title="Окончание проекта"
                                date={realizationEnd}
                                displayError={displayError(realizationEnd)}
                                subtitle={activityDuration && `Длительность проекта: ${activityDuration}`}
                                onChange={onRealizationEndDateChange}
                            />

                            <Datepicker
                                qaId="debriefingDatePicker"
                                title="Подведение итогов"
                                date={debriefingDate}
                                displayError={displayError(debriefingDate)}
                                onChange={onDebriefingDateChange}
                            />
                        </div>
                    </div>

                    <div className={styles.column}>
                        <InfoIcon
                            tooltip={`Если вы не нашли нужное значение\nв справочниках, обратитесь к\nадминистратору системы`}
                            qaId="noDictionaryInfoMarker"
                        />
                    </div>
                </div>
            </OpacityTransition>
        </div>
    );
};

interface DatepickerProps {
    qaId: string;
    date: Moment;
    title: string;
    displayError: boolean;
    subtitle?: string;
    onChange: (date: Moment) => void;
}

function Datepicker({ qaId, date, title, displayError, subtitle, onChange }: DatepickerProps): JSX.Element {
    const subtitleContent = displayError ? ERROR_MESSAGE : subtitle || '\u00A0';

    return (
        <div className={styles.datepicker}>
            <LabeledDatepicker qaId={qaId} title={title} value={date} onValueChange={onChange} />

            <div className={classNames(styles.datepickerSubtitle, displayError && styles.datepickerSubtitleError)}>
                {subtitleContent}
            </div>
        </div>
    );
}

interface EditOrCreateProjectHeaderProps {
    activityIsNew: boolean;
    location: string;
    onStartActivityButtonClick: () => void;
}

export function EditOrCreateProjectHeader({
    activityIsNew,
    location,
    onStartActivityButtonClick,
}: EditOrCreateProjectHeaderProps): JSX.Element {
    return (
        <OpacityTransition>
            <div className={styles.header}>
                <div className={styles.headerHiddenLeftSidebar} />

                <div className={styles.headerTitleWrapper}>
                    <MenuItem link={location}>
                        {location == '/activity/new' ? 'Новый проект' : 'Редактирование проекта'}
                    </MenuItem>
                </div>

                <div className={styles.rightSidebar}>
                    <div className={styles.quickAccessIcons}>
                        <QuickAccessIcons />
                    </div>

                    <div className={styles.saveActivityButton}>
                        <Button
                            qaId="saveActivityButton"
                            onClick={onStartActivityButtonClick}
                            theme={ButtonTheme.GhostRoundedBlack}
                        >
                            {activityIsNew ? 'Запустить проект' : 'Сохранить'}
                        </Button>
                    </div>
                </div>
            </div>
        </OpacityTransition>
    );
}

interface InfoIconProps {
    tooltip: string;
    qaId: string;
    className?: string;
    size?: number;
}

function InfoIcon({ tooltip, qaId, className, size = 28 }: InfoIconProps): JSX.Element {
    return (
        <div className={classNames(styles.infoButton, className)}>
            <div {...{ 'qa-id': qaId }}>
                <WithTooltip content={tooltip}>
                    <Icon className={styles.infoButtonContent} type={IconType.INFO_ICON} svgSize={size} />
                </WithTooltip>
            </div>
        </div>
    );
}
