// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-StageWidgets-RequestStageWidget-TenderDocumentation-Status-StatusApprovedContent-DisapproveButton-_styles__root{display:flex;justify-content:center;align-items:center;padding:0 16px;height:32px;font-family:'Open Sans';font-weight:400;font-size:14px;letter-spacing:0.15px;color:#E63900;background:#FFFFFF;box-shadow:0 1px 6px rgba(0,0,0,0.13);border-radius:5px;cursor:pointer}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/StageWidgets/RequestStageWidget/TenderDocumentation/Status/StatusApprovedContent/DisapproveButton/styles.scss"],"names":[],"mappings":"AAAA,4JACI,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CACnB,cAAe,CACf,WAAY,CAEZ,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,qBAAsB,CACtB,aAAc,CAEd,kBAAmB,CACnB,qCAAyC,CACzC,iBAAkB,CAElB,cAAe","sourcesContent":[".root {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 0 16px;\n    height: 32px;\n\n    font-family: 'Open Sans';\n    font-weight: 400;\n    font-size: 14px;\n    letter-spacing: 0.15px;\n    color: #E63900;\n\n    background: #FFFFFF;\n    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);\n    border-radius: 5px;\n\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-StageWidgets-RequestStageWidget-TenderDocumentation-Status-StatusApprovedContent-DisapproveButton-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
