import * as React from 'react';
import autobind from 'autobind-decorator';

import { NotificationMessage, NotificationType, NotificationActionType } from '@store/common/types';

import { CreativeReferenceMenuTemplate } from './CreativeReferenceMenuTemplate';

interface Props {
    loading: boolean;
    creativeRequests: any[];
    selectedCreativeRequestId: string;
    setCreativeRequestId: (id: string) => void;
    setNotification: (requestNotification: NotificationMessage) => void;
    onClose: () => void;
    onSave: () => void;
}

export class CreativeReferenceMenuBehavior extends React.Component<Props> {
    public render(): JSX.Element {
        const { loading, creativeRequests, selectedCreativeRequestId } = this.props;

        return (
            <CreativeReferenceMenuTemplate
                showPreloader={loading}
                creativeRequests={creativeRequests}
                selectedCreativeRequestId={selectedCreativeRequestId}
                onCancelButtonClick={this.onCancelButtonClick}
                onSaveButtonClick={this.onSaveButtonClick}
                onCreativeRequestSelection={this.onCreativeRequestSelection}
            />
        );
    }

    @autobind
    private onCreativeRequestSelection(creativeRequestId: string): void {
        this.props.setCreativeRequestId(creativeRequestId);
    }

    @autobind
    private onCancelButtonClick(): void {
        this.props.onClose();
    }

    @autobind
    private async onSaveButtonClick(): Promise<void> {
        this.props.onSave();
        this.props.setNotification({
            type: NotificationType.SUCCESS,
            typeAction: NotificationActionType.CHANGE_REFERENCE_OF_ACTIVITY_IN_REFERENCE_MENU,
            comment: 'Связи успешно изменены',
        });
    }
}
