import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { StoreState } from '@store';
import * as workTypesStore from '@store/workTypes';
import * as departmentsStore from '@store/departments';
import * as usersStore from '@store/users';

import { MyTasksPage } from './MyTasksPage';

const workTypesEntities = workTypesStore.StoreTypes.MY_TASKS_FILTER;
const executorsEntities = usersStore.StoreTypes.MY_TASKS_EXECUTORS_FILTER;
const authorsEntities = usersStore.StoreTypes.MY_TASKS_AUTHORS_FILTER;
const departmentsEntities = departmentsStore.StoreTypes.MY_TASKS_FILTER;

interface Props extends Partial<DispatchProps> {
    taskIdWithSidebar: string;
    onBeforeTaskDeleted: (taskId: string) => void;
    openTaskSidebar: (taskId: string) => void;
}

interface DispatchProps {
    reloadTasksFilters: () => void;
}

@(connect(null, mapDispatchToProps) as any)
export class MyTasksPageContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { taskIdWithSidebar, openTaskSidebar, reloadTasksFilters, onBeforeTaskDeleted } = this.props;

        return (
            <MyTasksPage
                taskIdWithSidebar={taskIdWithSidebar}
                onBeforeTaskDeleted={onBeforeTaskDeleted}
                reloadTaskFilters={reloadTasksFilters}
                openTaskSidebar={openTaskSidebar}
            />
        );
    }
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
    return {
        reloadTasksFilters: () => {
            dispatch(workTypesStore.resetStore(workTypesEntities));
            dispatch(usersStore.resetStore(executorsEntities));
            dispatch(usersStore.resetStore(authorsEntities));
            dispatch(departmentsStore.resetStore(departmentsEntities));
        },
    };
}
