import * as React from 'react';

import { ColumnName } from '@store/budgetExecution';

import { SelectableCell, CellColors } from './SelectableCell';

interface Props {
    columnName: ColumnName;
    title: React.ReactText;
    tooltip?: string;
    isClickable: boolean;
    isSelected: boolean;
    isHovered: boolean;
    cellColor: CellColors;
    disabled: boolean;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
    onCellCopy: (event: React.ClipboardEvent<HTMLDivElement>) => void;
}

export class SelectableCellContainer extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        return React.createElement(SelectableCell, {
            columnName: this.props.columnName,
            title: this.props.title,
            tooltip: this.props.tooltip,
            isClickable: this.props.isClickable,
            isSelected: this.props.isSelected,
            isHovered: this.props.isHovered,
            cellColor: this.props.cellColor,
            disabled: this.props.disabled,
            onMouseEnter: this.props.onMouseEnter,
            onMouseLeave: this.props.onMouseLeave,
            onClick: this.props.onClick,
            onCopy: this.props.onCellCopy,
        });
    }
}
