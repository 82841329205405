import { keyBy, includes, compact, uniq } from 'lodash';
import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { StoreState } from '@store';
import * as asyncActions from './actions/async';
import { getSelectedCreativeRequestId, getBudgetItemIdsToCreate, getBudgetItemIdsToDelete } from './selectors';
import type {
    CreativeRequestDomain,
    InitParams,
    InitResult,
    UpdateParams,
    UpdateResult,
    SetBudgetParams,
    SetBudgetResult,
} from './types';
import { HandlerCreator } from './handlers';
import { convertCreativeRequestDomain } from './converters';
import { subscribeCreativeRequestDomain } from './subscribers';
import { unsubscribeCreativeRequestDomain } from './unsubscribers';
import { requestBudgetItems } from './requests';
import { MrmClient } from '@api';

const handlerCreator = new HandlerCreator();

let creativeRequestDomain: CreativeRequestDomain = null;

export const init = bindThunkAction<StoreState, InitParams, InitResult, Error>(
    asyncActions.init,
    async ({ creativeRequestId, budgetId }, dispatch) => {
        handlerCreator.setDispatch(dispatch);
        handlerCreator.setBudgetId(budgetId);

        const creativeRequestDomain = await requestCreativeRequestDomainById(creativeRequestId);
        const convertedCreativeRequest = await convertCreativeRequestDomain(creativeRequestDomain);
        await subscribeCreativeRequestDomain(creativeRequestDomain, { handlers: handlerCreator.getHandlers() });

        const creativeRequestBudgetItemIds = convertedCreativeRequest.budgetItems.map(({ id }) => id);
        const divisionIds = uniq(compact(convertedCreativeRequest.params.map(({ division }) => division?.id)));

        const budgetItems = await requestBudgetItems({ budgetId, filter: { divisionIds } });

        return {
            creativeRequests: keyBy(
                [
                    {
                        id: convertedCreativeRequest.id,
                        name: convertedCreativeRequest.name,
                        budgetItems: budgetItems
                            .filter(({ id: budgetItemId }) => includes(creativeRequestBudgetItemIds, budgetItemId))
                            .map(({ id, serialNumber, activity: { name: activityName } }) => ({
                                id,
                                serialNumber: serialNumber.toString(),
                                activityName,
                            })),
                    },
                ],
                'id',
            ),
            budgetItems: keyBy(
                budgetItems.map(({ id, serialNumber, activity: { name: activityName } }) => ({
                    id,
                    serialNumber: serialNumber.toString(),
                    activityName,
                })),
                'id',
            ),
        };
    },
);

export const update = bindThunkAction<StoreState, UpdateParams, UpdateResult, Error>(
    asyncActions.update,
    async ({ budgetId }, __, getState) => {
        const selectedCreativeRequestId = getSelectedCreativeRequestId(getState());

        const creativeRequestDomain = await requestCreativeRequestDomainById(selectedCreativeRequestId);
        const convertedCreativeRequest = await convertCreativeRequestDomain(creativeRequestDomain);
        await unsubscribeCreativeRequestDomain(creativeRequestDomain, { handlers: handlerCreator.getHandlers() });
        await subscribeCreativeRequestDomain(creativeRequestDomain, { handlers: handlerCreator.getHandlers() });

        const creativeRequestBudgetItemIds = convertedCreativeRequest.budgetItems.map(({ id }) => id);
        const divisionIds = uniq(compact(convertedCreativeRequest.params.map(({ division }) => division?.id)));

        const budgetItems = await requestBudgetItems({ budgetId, filter: { divisionIds } });

        return {
            creativeRequests: keyBy(
                [
                    {
                        id: convertedCreativeRequest.id,
                        name: convertedCreativeRequest.name,
                        budgetItems: budgetItems
                            .filter(({ id: budgetItemId }) => includes(creativeRequestBudgetItemIds, budgetItemId))
                            .map(({ id, serialNumber, activity: { name: activityName } }) => ({
                                id,
                                serialNumber: serialNumber.toString(),
                                activityName,
                            })),
                    },
                ],
                'id',
            ),
            budgetItems: keyBy(
                budgetItems.map(({ id, serialNumber, activity: { name: activityName } }) => ({
                    id,
                    serialNumber: serialNumber.toString(),
                    activityName,
                })),
                'id',
            ),
        };
    },
);

export const save = bindThunkAction<StoreState, void, void, Error>(asyncActions.save, async (_, __, getState) => {
    const selectedCreativeRequestId = getSelectedCreativeRequestId(getState());
    const budgetItemIdsToCreate = getBudgetItemIdsToCreate(getState());
    const budgetItemIdsToDelete = getBudgetItemIdsToDelete(getState());

    const creativeRequestDomain = await requestCreativeRequestDomainById(selectedCreativeRequestId);

    await Promise.all([
        Promise.all(
            budgetItemIdsToCreate.map(async (budgetItemId) => {
                await creativeRequestDomain.model.addBudgetItem({ budgetItemId });
            }),
        ),
        Promise.all(
            budgetItemIdsToDelete.map(async (budgetItemId) => {
                await creativeRequestDomain.model.removeBudgetItem({ budgetItemId });
            }),
        ),
    ]);
});

export const setBudget = bindThunkAction<StoreState, SetBudgetParams, SetBudgetResult, Error>(
    asyncActions.setBudget,
    async ({ budgetId }, __, getState) => {
        handlerCreator.setBudgetId(budgetId);

        const selectedCreativeRequestId = getSelectedCreativeRequestId(getState());

        const creativeRequestDomain = await requestCreativeRequestDomainById(selectedCreativeRequestId);
        const convertedCreativeRequest = await convertCreativeRequestDomain(creativeRequestDomain);
        await subscribeCreativeRequestDomain(creativeRequestDomain, { handlers: handlerCreator.getHandlers() });

        const creativeRequestBudgetItemIds = convertedCreativeRequest.budgetItems.map(({ id }) => id);
        const divisionIds = uniq(compact(convertedCreativeRequest.params.map(({ division }) => division?.id)));

        const budgetItems = await requestBudgetItems({ budgetId, filter: { divisionIds } });

        return {
            creativeRequests: keyBy(
                [
                    {
                        id: convertedCreativeRequest.id,
                        name: convertedCreativeRequest.name,
                        budgetItems: budgetItems
                            .filter(({ id: budgetItemId }) => includes(creativeRequestBudgetItemIds, budgetItemId))
                            .map(({ id, serialNumber, activity: { name: activityName } }) => ({
                                id,
                                serialNumber: serialNumber.toString(),
                                activityName,
                            })),
                    },
                ],
                'id',
            ),
            budgetItems: keyBy(
                budgetItems.map(({ id, serialNumber, activity: { name: activityName } }) => ({
                    id,
                    serialNumber: serialNumber.toString(),
                    activityName,
                })),
                'id',
            ),
        };
    },
);

export const reset = bindThunkAction<StoreState, void, void, Error>(asyncActions.reset, async (_, __, getState) => {
    const selectedCreativeRequestId = getSelectedCreativeRequestId(getState());

    const creativeRequestDomain = await requestCreativeRequestDomainById(selectedCreativeRequestId);
    await unsubscribeCreativeRequestDomain(creativeRequestDomain, { handlers: handlerCreator.getHandlers() });
});

const requestCreativeRequestDomainById = async (id: string): Promise<CreativeRequestDomain> => {
    if (id !== creativeRequestDomain?.model?.id) {
        const client = await MrmClient.getInstance();
        creativeRequestDomain = await client.domain.creativeRequests.getCreativeRequest({ id });
    }

    return creativeRequestDomain;
};
