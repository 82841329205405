// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ExternalTransitionMenu-Title-_Title__root{color:#000000;font-size:12px;font-weight:600;line-height:16px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/ExternalTransitionMenu/Title/Title.scss"],"names":[],"mappings":"AAAA,mHACI,aAAc,CAEd,cAAe,CACf,eAAgB,CAChB,gBAAiB","sourcesContent":[".root {\n    color: #000000;\n\n    font-size: 12px;\n    font-weight: 600;\n    line-height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ExternalTransitionMenu-Title-_Title__root"
};
export default ___CSS_LOADER_EXPORT___;
