import * as React from 'react';

import { useCreativeRequestDomain } from '../useCreativeRequestDomain';
import { useCreativeRequestItemDomains } from './useCreativeRequestItemDomains';
import { useConvert } from './useConvert';

import type { CreativeRequestItem } from './useConvert';

interface Props extends ExternalProps {}

interface ExternalProps {
    creativeRequestId: string;
    children: (props: ChildrenProps) => JSX.Element;
}

interface ChildrenProps {
    creativeRequestItems: CreativeRequestItem[];
}

export const WithCreativeRequestItems: React.FC<Props> = ({ creativeRequestId, children }) => {
    const { creativeRequestDomain } = useCreativeRequestDomain({
        creativeRequestId,
    });
    const { creativeRequestItemDomains } = useCreativeRequestItemDomains({ creativeRequestDomain });
    const { creativeRequestItems } = useConvert({ creativeRequestItemDomains });

    return React.createElement(children, {
        creativeRequestItems,
    });
};

export type { CreativeRequestItem };
