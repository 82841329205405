import * as React from 'react';

import { DropdownOptionsProps, useDropdown } from '@common/hooks';

import { IconTag, IconTagProps } from '@common/components';

import { Dropdown, DropdownProps } from '../../popup';

export interface DropdownIconTagProps extends IconTagProps, DropdownOptionsProps {
    /** A content inside the tag button */
    tagContent?: React.ReactNode;

    /** Makes dropdown popup width equals parent relative element */
    absolute?: boolean;

    dropdownProps?: Omit<DropdownProps, 'dropdown'>;
}

export const DropdownIconTag: React.FC<DropdownIconTagProps> = ({
    rootRef,
    children,
    tagContent,
    absolute,
    dropdownProps,
    ...rest
}) => {
    const [dropdown, ref, props] = useDropdown({ ...rest, target: rootRef }, HTMLDivElement);

    return (
        <IconTag
            clickable
            type={dropdown.isDropdownShow ? 'soft' : 'default'}
            relative={!absolute}
            {...props}
            rootRef={ref}
        >
            {tagContent}
            <Dropdown dropdown={dropdown} {...dropdownProps}>
                {children}
            </Dropdown>
        </IconTag>
    );
};
