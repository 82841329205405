// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-Card-CardFooter-ButtunList-_ButtonList__root{display:flex;height:100%;align-items:center;justify-content:flex-end}.src-client-modules-common-Card-CardFooter-ButtunList-_ButtonList__buttonWrapper{margin-right:10px}.src-client-modules-common-Card-CardFooter-ButtunList-_ButtonList__buttonWrapper:last-child{margin-right:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/Card/CardFooter/ButtunList/ButtonList.scss"],"names":[],"mappings":"AAAA,wEACI,YAAa,CAEb,WAAY,CAEZ,kBAAmB,CACnB,wBAAyB,CAC5B,iFAGG,iBAAkB,CADtB,4FAIQ,cAAe","sourcesContent":[".root {\n    display: flex;\n\n    height: 100%;\n\n    align-items: center;\n    justify-content: flex-end;\n}\n\n.buttonWrapper {\n    margin-right: 10px;\n\n    &:last-child {\n        margin-right: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-Card-CardFooter-ButtunList-_ButtonList__root",
	"buttonWrapper": "src-client-modules-common-Card-CardFooter-ButtunList-_ButtonList__buttonWrapper"
};
export default ___CSS_LOADER_EXPORT___;
