import * as React from 'react';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import {
    SearchEntities,
    EntitiesGroupData,
    getEntitiesGroupData,
    getSearchState,
    getLoadingStatus,
    setCollapseStatus,
} from '@store/search';

import { Group } from './Group';

interface Props extends OwnProps, Partial<MapProps & DispatchProps> {}

interface OwnProps {
    title: string;
    entityType: SearchEntities;
}

interface MapProps extends EntitiesGroupData<any> {
    isExpanded: boolean;
    isAnimated: boolean;
    isLoading: boolean;
    showCollapseButton: boolean;
}

interface DispatchProps {
    setCollapseStatus: (entityType: SearchEntities) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class GroupContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { entityType, title, totalCount, isExpanded, isAnimated, isLoading, entities, showCollapseButton } =
            this.props;

        return (
            <Group
                showCollapseButton={showCollapseButton}
                entityType={entityType}
                entities={entities}
                isLoading={isLoading}
                title={title}
                totalCount={totalCount}
                isExpanded={isExpanded}
                isAnimated={isAnimated}
                onToggleCollapseStateButtonClick={this.onToggleCollapseStateButtonClick}
            />
        );
    }

    @autobind
    private onToggleCollapseStateButtonClick(): void {
        const { isExpanded, entityType, setCollapseStatus } = this.props;

        setCollapseStatus(isExpanded ? null : entityType);
    }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): MapProps {
    const { entityType } = ownProps;
    const { expandedGroup, animatedGroup, singleLoadedEntityMode } = getSearchState(state);
    const groupData = getEntitiesGroupData(state, entityType);

    return {
        ...groupData,
        showCollapseButton: singleLoadedEntityMode || groupData.entities.length < 4,
        isExpanded: expandedGroup === entityType,
        isAnimated: animatedGroup === entityType,
        isLoading: getLoadingStatus(state, entityType) === LoadingStatus.LOADING,
    };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
    return {
        setCollapseStatus: (entityType: SearchEntities) => dispatch(setCollapseStatus(entityType)),
    };
}
