// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-tmRegistry-table-header-_TmHeader__header{display:flex;box-sizing:border-box;user-select:none;border-bottom:1px solid #e3e9e5;background-color:#f8f8f8}\n", "",{"version":3,"sources":["webpack://./src/client/modules/tmRegistry/table/header/TmHeader.scss","webpack://./src/client/modules/tmRegistry/table/const.scss"],"names":[],"mappings":"AAEA,8DACI,YAAa,CAEb,qBAAsB,CAEtB,gBAAiB,CAEjB,+BCJiC,CDKjC,wBCPmB","sourcesContent":["@import \"../const\";\n\n.header {\n    display: flex;\n\n    box-sizing: border-box;\n\n    user-select: none;\n\n    border-bottom: $table_border_base;\n    background-color: $table_bg_base;\n}\n\n\n","\n$table_color_base: #000000;\n$table_color_success: #19bb4f;\n$table_bg_base: #f8f8f8;\n$table_bg_secondary: #ffffff;\n$table_border_base: 1px solid #e3e9e5;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "src-client-modules-tmRegistry-table-header-_TmHeader__header"
};
export default ___CSS_LOADER_EXPORT___;
