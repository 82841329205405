// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-leaderDashboard-LeaderDashboard-Content-SomeReportsCharts-_styles__root{display:grid;grid-auto-flow:row;grid-auto-rows:1fr;grid-row-gap:12px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/leaderDashboard/LeaderDashboard/Content/SomeReportsCharts/styles.scss"],"names":[],"mappings":"AAAA,4FACI,YAAa,CACb,kBAAmB,CACnB,kBAAmB,CACnB,iBAAkB","sourcesContent":[".root {\n    display: grid;\n    grid-auto-flow: row;\n    grid-auto-rows: 1fr;\n    grid-row-gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-leaderDashboard-LeaderDashboard-Content-SomeReportsCharts-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
