import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { IconType } from 'sber-marketing-ui';
import type { SocialStageForm } from '@store/autopilot/types';

import { SocialStageWidgetTemplate, SocialItemProps } from './SocialStageWidgetTemplate';
import type { StoreState } from '@store';
import { setSocialFormValues } from '@store/autopilot/actions';
import { getSocialStageForm } from '@store/autopilot/selectors';

interface Props extends MapProps, DispatchProps {
    readOnly?: boolean;
}

interface MapProps {
    socialStageForm?: SocialStageForm;
}

interface DispatchProps {
    setSocialFormValues?: (values: Partial<SocialStageForm>) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class SocialStageWidgetBehaviour extends React.PureComponent<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        const { socialStageForm } = this.props;

        const counter = [socialStageForm.vkUrl, socialStageForm.okUrl].filter((item) => !lodash.isEmpty(item)).length;

        return React.createElement(SocialStageWidgetTemplate, {
            socialItems: this.makeSocialItems(),
            counter,
        });
    }

    @autobind
    protected onVkUrlChange(value: string): void {
        this.props.setSocialFormValues({ vkUrl: value });
    }

    @autobind
    protected onOkUrlChange(value: string): void {
        this.props.setSocialFormValues({ okUrl: value });
    }

    private makeSocialItems(): SocialItemProps[] {
        const { socialStageForm, readOnly } = this.props;

        if (!socialStageForm) {
            return [];
        }

        const { vkUrl, okUrl } = socialStageForm;

        return [
            {
                readOnly,
                name: 'VK',
                icon: IconType.SOCIAL_VK,
                value: vkUrl,
                onValueChange: this.onVkUrlChange,
            },
            {
                readOnly,
                name: 'Одноклассники',
                icon: IconType.SOCIAL_OK,
                value: okUrl,
                onValueChange: this.onOkUrlChange,
            },
        ];
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        socialStageForm: getSocialStageForm(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setSocialFormValues,
        },
        dispatch,
    );
}
