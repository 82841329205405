import { LoadingStatus } from '@store/commonTypes';

export interface FormState {
    mode: FormMode;
    params: FormParams;
    texts: FormText;
}

export type FormMode = 'editingParams' | 'editingTexts';
export type TextType = 'titles' | 'contents';

export interface FormParams {
    loading: LoadingStatus;
    featuresMap: FeaturesMap;
}

export interface FormText {
    loading: LoadingStatus;
    options: FormTextOptions;
    data: FormTextData;
}

export interface FormTextOptions {
    titles: Record<TextId, TextOption>;
    contents: Record<TextId, TextOption>;
}

export interface FormTextData {
    titles: Record<TextId, Text>;
    contents: Record<TextId, Text>;
}

export interface Text {
    id: TextId;
    value: TextValue;
}

export interface TextOption extends Text {
    disabled: boolean;
    selected: boolean;
}

export type TextId = string;
export type TextValue = string;

export type FeaturesMap = Record<FeaturesId, Features>;

export interface Features {
    id: FeaturesId;
    value: FeaturesValue;
}

export type FeaturesId = string;
export type FeaturesValue = string;

export interface LoadFeaturesParams {
    autopilotId: string;
    productId: string;
}
export interface LoadFeaturesResult {
    loadedFeaturesMap: FeaturesMap;
}

export interface LoadTextsParams {
    autopilotId: string;
    productId: string;
}

export type LoadTextsResult = {
    options: FormTextOptions;
    data: FormTextData;
};

export interface UpdateTextTitleParams {
    titleId: TextId;
    autopilotId: string;
    productId: string;
}

export type UpdateTextTitleResult = {
    updatedTextTitle: Text;
    removedTextDataTitleId: TextId;
    textData: FormTextData;
};

export interface UpdateTextContentParams {
    contentId: TextId;
    autopilotId: string;
    productId: string;
}

export type UpdateTextContentResult = {
    updatedTextContent: Text;
    removedTextDataContentId: TextId;
    textData: FormTextData;
};

export interface ChangeOptionTextSelectionParams {
    id: TextId;
    type: TextType;
    selected: boolean;
}

export interface ChangeTextOptionValueParams {
    id: TextId;
    type: TextType;
    value: TextValue;
}

export interface SaveParams {
    autopilotId: string;
}

export { LoadingStatus };
