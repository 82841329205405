import * as React from 'react';
import gql from 'graphql-tag';
import { get } from 'lodash';
import { graphql } from 'react-apollo';

import { Budget } from './types';

export const GET_BUDGETS_QUERY = gql`
    query getBudgets($organizationIds: [String]!) {
        budgets: getBudgets(filter: { organizationId: $organizationIds, status: execution }) {
            nodes {
                id
                year
                organizationIds
                actions {
                    createActivity
                }
            }
        }
    }
`;

export interface BudgetsData {
    loading: boolean;
    budgets: Budget[];
}

interface Props extends ExternalProps, QueryProps {}

interface ExternalProps {
    organizationIds: string[];
    forwardData: (props: BudgetsData) => void;
}

type QueryProps = {
    budgetsQuery: {
        loading: boolean;
        budgets: {
            nodes: Budget[];
        };
    };
};

class BudgetsFetcherBehaviour extends React.PureComponent<Props> {
    public componentDidUpdate(prevProps: Props): void {
        if (!this.props.budgetsQuery?.loading && prevProps.budgetsQuery?.loading) {
            this.forwardData();
        }
    }

    public render(): JSX.Element {
        return null;
    }

    private forwardData(): void {
        const { budgetsQuery } = this.props;

        const loading = budgetsQuery ? budgetsQuery.loading : true;
        const budgets: Budget[] = get(budgetsQuery, 'budgets.nodes') || [];

        this.props.forwardData({ loading, budgets });
    }
}

export const BudgetsFetcher = graphql<ExternalProps>(GET_BUDGETS_QUERY, {
    name: 'budgetsQuery',
    options: ({ organizationIds }) => ({
        variables: {
            organizationIds,
        },
        fetchPolicy: 'no-cache',
    }),
    skip: ({ organizationIds }) => !organizationIds?.length,
})(BudgetsFetcherBehaviour) as React.ComponentType<ExternalProps>;
