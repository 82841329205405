// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-leaderDashboard-LeaderDashboard-Content-CommonShortReport-_styles__root{padding:16px 24px;background:#FFFFFF;box-shadow:0 1px 6px rgba(0,0,0,0.1);border-radius:8px}.src-client-modules-leaderDashboard-LeaderDashboard-Content-CommonShortReport-_styles__shortReport{cursor:pointer}\n", "",{"version":3,"sources":["webpack://./src/client/modules/leaderDashboard/LeaderDashboard/Content/CommonShortReport/styles.scss"],"names":[],"mappings":"AAAA,4FACI,iBAAkB,CAClB,kBAAmB,CACnB,oCAAwC,CACxC,iBAAkB,CACrB,mGAGG,cAAe","sourcesContent":[".root {\n    padding: 16px 24px;\n    background: #FFFFFF;\n    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);\n    border-radius: 8px;\n}\n\n.shortReport {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-leaderDashboard-LeaderDashboard-Content-CommonShortReport-_styles__root",
	"shortReport": "src-client-modules-leaderDashboard-LeaderDashboard-Content-CommonShortReport-_styles__shortReport"
};
export default ___CSS_LOADER_EXPORT___;
