import { CreativeRequestBudgetItemDomain, BudgetItem } from '../types';

interface Converter {
    (domain: CreativeRequestBudgetItemDomain): Promise<BudgetItem>;
}

export const convertBudgetItemDomain: Converter = async ({ model }) => {
    const [block, division] = await Promise.all([model.block, model.division]);

    return {
        id: model.id,
        budgetYear: model?.budgetYear,
        serialNumber: model?.serialNumber,
        sapComment: model?.sapComment || '',
        plannedFunds: model?.plannedFunds,
        reservedFunds: model?.reservedFunds,
        factFunds: model?.factFunds,
        createdAt: model?.createdAt,
        block: block?.value || '',
        division: division?.value || '',
    };
};
