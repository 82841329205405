import * as React from 'react';
import classNames from 'classnames';
import QRCode from 'react-qr-code';
import * as styles from './styles.scss';

interface Props {
    link: string;
    text: string;
}

export const Code: React.FC<Props> = ({ link, text }) => {
    return (
        <div className={styles.root}>
            <div>
                <div className={styles.paragraph}>{text}</div>
                <div className={classNames(styles.paragraph, styles.point)}>
                    отсканируйте QR-код камерой устройства, если используете мобильную версию
                </div>
            </div>

            <div className={styles.qrCode}>
                <QRCode value={link} size={160} />
            </div>
        </div>
    );
};
