import * as React from 'react';
import classNames from 'classnames';
import { CorrectionStatus } from '@mrm/budget';
import { Popup as UIPopup, Preloader } from 'sber-marketing-ui';

import { UnsavedChange } from '@store/budgetCorrections/types';

import { Comment } from './Comment';

import * as style from './Popup.scss';

interface Props {
    unsavedChange: UnsavedChange;
    preloaderIsActive: boolean;
    commentIsValid: boolean;
    onConfirm: () => void;
    closePopup: () => void;
    onChangeCommentStatus: (error: string) => void;
}

export const Popup = ({
    unsavedChange,
    preloaderIsActive,
    commentIsValid,
    onConfirm,
    closePopup,
    onChangeCommentStatus,
}: Props): JSX.Element => {
    const { correctionId, newStatus } = unsavedChange;

    const shouldRender = !!(correctionId && newStatus);
    const newStatusIsApprovedStatus = newStatus === CorrectionStatus.Approved;
    const disableConfirmButton = !newStatusIsApprovedStatus && commentIsValid;

    return shouldRender ? (
        <UIPopup onOutOfContentClick={closePopup}>
            <div className={style.root}>
                {!newStatusIsApprovedStatus && (
                    <React.Fragment>
                        <div className={style.title}>Введите причину отклонения корректировки</div>

                        <div className={style.commentWrapper}>
                            <Comment onChangeStatus={onChangeCommentStatus} />
                        </div>
                    </React.Fragment>
                )}

                <div className={style.controller}>
                    <div className={style.titleController}>
                        {newStatusIsApprovedStatus ? 'Утвердить' : 'Отклонить'} корректировку?
                    </div>

                    <div className={style.buttonWrapper}>
                        <div
                            className={classNames(style.button, disableConfirmButton ? style.disable : style.enable)}
                            onClick={disableConfirmButton ? null : onConfirm}
                        >
                            Да
                        </div>

                        <div className={classNames(style.button, style.enable)} onClick={closePopup}>
                            Нет
                        </div>
                    </div>
                </div>

                {preloaderIsActive && <Preloader />}
            </div>
        </UIPopup>
    ) : null;
};
