import * as lodash from 'lodash';
import { createSelector } from 'reselect';
import { ActivityParams as Activity } from 'sber-marketing-types/frontend';

import { StoreState } from '@store';

import { RestPageState as PageState, RequestStageForm, FieldParams, CreativeRequestDomain } from './types';

export const getAutopilotPageState = (state: StoreState): PageState => state.creativePage.rest;

export const getActivity = createSelector(getAutopilotPageState, (state: PageState): Activity => {
    return state.activity;
});

export const getCreativeRequestDomain = createSelector(
    getAutopilotPageState,
    (state: PageState): CreativeRequestDomain => state.creativeRequestDomain,
);

export const getLoading = createSelector(getAutopilotPageState, (state: PageState): boolean => state.loading);

export const getRequestStageForm = createSelector(getAutopilotPageState, (state: PageState): RequestStageForm => {
    return state.requestStageForm;
});

export const getRequestStageFormFields = createSelector(
    getAutopilotPageState,
    getRequestStageForm,
    (state: PageState, requestStageForm: RequestStageForm): FieldParams[] => {
        return lodash.values(requestStageForm);
    },
);

export const getNotValidRequestStageFormFields = createSelector(
    getAutopilotPageState,
    getRequestStageFormFields,
    (state: PageState, fields: FieldParams[]) =>
        fields.filter(({ required }) => required).filter(({ value }) => !value),
);

export const defineValidRequestStageForm = createSelector(
    getAutopilotPageState,
    getNotValidRequestStageFormFields,
    (state: PageState, fields: FieldParams[]) => !fields.length,
);

export const getCurrentStage = createSelector(getAutopilotPageState, (state: PageState): number => {
    return state.currentStage;
});

export const getSelectedStage = createSelector(getAutopilotPageState, (state: PageState): number => {
    return state.selectedStage;
});
