import * as React from 'react';

import * as style from './styles.scss';

interface Props {
    onClick: () => void;
}

export const DisapproveButton: React.FC<Props> = ({ onClick }) => {
    return (
        <div className={style.root} onClick={onClick}>
            Отменить
        </div>
    );
};
