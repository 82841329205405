import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Dictionary, map } from 'lodash';

import { TaskStatus } from 'sber-marketing-types/frontend';
import { FileAsset } from '@store/commonTypes';

import { CommentsAndDescription } from './CommentsAndDescription';
import { StoreState } from '@store';
import { Channel, selectors, removeFileFromTask } from '@store/taskPage';
import { buildPermissions } from '@store/taskEditor2';
import type { Permissions } from '@store/taskEditor2';
import { getLoginUser } from '@store/user/selector';
import { getUserById } from '@store/appUsers';
import { withTaskData, PropsWithTaskData } from '../../withTaskData';

interface Props extends Partial<MapProps>, Partial<DispatchProps>, Partial<PropsWithTaskData> {
    previousUrl: string;
    className?: string;
    onTaskEditClick(): void | Promise<void>;
}

interface MapProps {
    channels: Dictionary<Channel>;
    id: string;
    title: string;
    description: string;
    leader: string;
    createdAt: number | string;
    deadlineAt: number | string;
    status: string;
    taskEditorPermissions: Permissions;
    hasAccessToTaskEditor: boolean;
    isCurrentUserAuthor: boolean;
}

interface DispatchProps {
    onAssetRemoveClick: (data: FileAsset) => void;
}

@(withTaskData as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class CommentsAndDescriptionContainer extends React.Component<Props> {
    public shouldComponentUpdate(nextProps: Props): boolean {
        const miscHaveChanged =
            this.props.id !== nextProps.id ||
            this.props.title !== nextProps.title ||
            this.props.className !== nextProps.className ||
            this.props.createdAt !== nextProps.createdAt ||
            this.props.deadlineAt !== nextProps.deadlineAt ||
            this.props.description !== nextProps.description ||
            this.props.leader !== nextProps.leader ||
            this.props.status !== nextProps.status ||
            this.props.channelId !== nextProps.channelId ||
            this.props.isCurrentUserAuthor !== nextProps.isCurrentUserAuthor ||
            this.props.hasAccessToTaskEditor !== nextProps.hasAccessToTaskEditor ||
            this.props.previousUrl !== nextProps.previousUrl ||
            this.props.onTaskEditClick !== nextProps.onTaskEditClick ||
            this.props.onAssetRemoveClick !== nextProps.onAssetRemoveClick;

        return miscHaveChanged;
    }

    public render(): JSX.Element {
        const {
            id,
            title,
            className,
            createdAt,
            deadlineAt,
            description,
            leader,
            status,
            channelId,
            isCurrentUserAuthor,
            hasAccessToTaskEditor,
            previousUrl,
            channels,
            onTaskEditClick,
            onAssetRemoveClick,
        } = this.props;

        return React.createElement(CommentsAndDescription, {
            className,
            isMainChannel: channelId === null,
            description,
            leader,
            id,
            title,
            createdAt,
            deadlineAt,
            canDeleteFiles: status !== TaskStatus.Closed && isCurrentUserAuthor,
            canDisplayDropdownMenu: hasAccessToTaskEditor && status !== TaskStatus.Closed,
            isAddCommentDisable: status == TaskStatus.Draft || status == TaskStatus.Closed || !channels[channelId],
            updateCommentDisabled: status == TaskStatus.Closed,
            previousUrl,
            channelId,
            onTaskEditClick,
            onAssetRemoveClick,
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { attributes } = getLoginUser(state);
    const {
        id,
        title,
        description,
        createdAt,
        deadlineAt,
        authorId,
        executorId,
        organizationId,
        status,
        canEdit,
        createdBy,
    } = selectors.getTaskInfo(state);
    const { firstName, secondName } = getUserById(state, authorId);
    const activity = selectors.getActivity(state);

    const taskEditorPermissions = buildPermissions({
        task: {
            organizationId,
            executorId,
            authorId,
            canEdit,
            createdBy,
            status,
        },
        activity: {
            authorId: activity.authorId,
            responsibleId: activity.responsibleId,
        },
        user: getLoginUser(state),
    });

    const hasAccessToTaskEditor = map(taskEditorPermissions, (value) => value).some((value) => value);

    return {
        id,
        title,
        description,
        createdAt,
        deadlineAt,
        status,
        leader: `${firstName} ${secondName}`,
        taskEditorPermissions,
        hasAccessToTaskEditor,
        isCurrentUserAuthor: authorId === attributes.id,
        channels: state.taskPage.channels,
    };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
    return {
        onAssetRemoveClick: ({ name }: FileAsset) => dispatch(removeFileFromTask({ fileName: name })),
    };
}
