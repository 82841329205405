import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { CellParams, CellPosition, CellsStorage, ColumnHeaderParams, ColumnHeadersStorage, LineId } from '../types';

interface Props {
    makeColumnHeaderParams: (columnName: string) => ColumnHeaderParams;
    makeCellParams: (cellPosition: CellPosition, edit: boolean) => CellParams;
}

export class TableViewModel {
    private columnHeaders: ColumnHeadersStorage = {};
    private cells: CellsStorage = {};
    private makeColumnHeaderParams: (columnName: string) => ColumnHeaderParams;
    private makeCellParams: (cellPosition: CellPosition, edit?: boolean) => CellParams;

    public constructor(props: Props) {
        this.makeColumnHeaderParams = props.makeColumnHeaderParams;
        this.makeCellParams = props.makeCellParams;
    }

    @autobind
    public setColumnHeader(columnName: string, columnHeader: ColumnHeaderParams): void {
        this.columnHeaders[columnName] = columnHeader;
    }

    @autobind
    public getColumnHeader(columnName: string): ColumnHeaderParams {
        if (!this.columnHeaders[columnName]) {
            this.columnHeaders[columnName] = this.makeColumnHeaderParams(columnName);
        }

        return this.columnHeaders[columnName];
    }

    @autobind
    public setCellParams(
        position: CellPosition,
        cellParams: {
            component: React.ClassType<any, any, any>;
            cellProps: any;
        },
    ): void {
        const { columnName, lineId } = position;

        if (!this.cells[lineId]) {
            this.cells[lineId] = {};
        }

        this.cells[lineId][columnName] = {
            ...this.cells[lineId][columnName],
            ...cellParams,
        };

        if (this.cells[lineId][columnName].onPropsChange) {
            this.cells[lineId][columnName].onPropsChange(cellParams);
        }
    }

    @autobind
    public getCellParams(
        position: CellPosition,
        onCellParamsUpdateHandler: (cellParams: { component: React.ClassType<any, any, any>; cellProps: any }) => void,
    ): {
        component: React.ClassType<any, any, any>;
        cellProps: any;
    } {
        const { columnName, lineId } = position;

        let cell = lodash.get(this.cells, [lineId, columnName]);

        if (!cell) {
            cell = this.makeCellParams(position);

            if (!this.cells[lineId]) {
                this.cells[lineId] = {};
            }

            this.cells[lineId][columnName] = cell;
        }

        cell.onPropsChange = onCellParamsUpdateHandler;

        const { component, cellProps } = cell;

        return {
            component,
            cellProps,
        };
    }

    @autobind
    public clearLineCells(lineId: LineId): void {
        const lineCells = this.cells[lineId];

        const handlers = lodash.map(lineCells, (item) => item.onPropsChange);

        this.cells[lineId] = {};

        handlers.forEach((handler) => {
            handler({
                component: null,
                cellProps: null,
            });
        });
    }
}
