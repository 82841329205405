import * as React from 'react';

import * as styles from './ActivityTypeTooltips.scss';

interface Tooltips {
    [activityType: string]: JSX.Element;
}

export function getActivityTypeTooltip(activityType: string): JSX.Element {
    return ActivityTypeTooltips[activityType] || null;
}

const ActivityTypeTooltips: Tooltips = {
    'Мероприятие 1-я категория': <FirstCategoryTooltip />,
    'Мероприятие 2-я категория': <SecondCategoryTooltip />,
    'Мероприятие 3-я категория': <ThirdCategoryTooltip />,
    'Мероприятие 4-я категория': <FourthCategoryTooltip />,
};

function FirstCategoryTooltip(): JSX.Element {
    return (
        <div className={styles.tooltip}>
            <ul className={styles.tooltipList}>
                <li> Внешние мероприятия; </li>
                <br />
                <li> Федеральная значимость; </li>
                <br />
                <li> Стратегическая важность; </li>
                <br />
                <li> Обязательное участие первых лиц государства; </li>
            </ul>

            <div className={styles.tooltipSubtitle}>ПМЭФ, ВЭФ, РИФ, Давос и др.</div>
        </div>
    );
}

function SecondCategoryTooltip(): JSX.Element {
    return (
        <div className={styles.tooltip}>
            <ul className={styles.tooltipList}>
                <li> Собственные мероприятия; </li>
                <br />
                <li> Инициированы бизнес-подразделением; </li>
                <br />
                <li> Предполагают участие Членов Правления Банка, Первых лиц государства; </li>
                <br />
                <li> Интегрированные комплексные проекты; </li>
            </ul>

            <div className={styles.tooltipSubtitle}>ГОСА, СберКонф, ICC, AI Journey и др.</div>
        </div>
    );
}

function ThirdCategoryTooltip(): JSX.Element {
    return (
        <div className={styles.tooltip}>
            <ul className={styles.tooltipList}>
                <li> Инициированы бизнес-подразделением по поручению Руководства; </li>
                <br />
                <li> Стратегическая важность; </li>
                <br />
                <li> Необходимость проведения возникла в связи с изменившейся ситуацией на рынке, в мире; </li>
                <br />
                <li> Требуют немедленного реагирования; </li>
            </ul>

            <div className={styles.tooltipSubtitle}>
                спец. выставки для представителей власти
                <br />
                открытие объектов Банка (фонтаны, школы)
            </div>
        </div>
    );
}

function FourthCategoryTooltip(): JSX.Element {
    return (
        <div className={styles.tooltip}>
            <ul className={styles.tooltipList}>
                <li> Инициированы бизнес-подразделением; </li>
                <br />
                <li> Не предполагают участие Президента Банка; </li>
                <br />
                <li> Направлены на решение бизнес - задач бизнес-подразделения; </li>
            </ul>

            <div className={styles.tooltipSubtitle}>
                бизнес-сессии, отраслевые конференции, воркшопы, деловые завтраки, митапы, организация небольших
                выставочных стендов
            </div>
        </div>
    );
}
