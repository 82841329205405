import * as React from 'react';

import { HeaderContentTemplate } from './HeaderContentTemplate';

interface Props {
    subscribeIsDisabled?: boolean;
}

export class HeaderContentContainer extends React.Component<Props> {
    public render(): JSX.Element {
        return React.createElement(HeaderContentTemplate, {});
    }
}
