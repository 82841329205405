import { actionCreatorFactory } from 'typescript-fsa';

import { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type {
    AutopilotRadio,
    AutopilotRadioTargetGroup,
    AutopilotRadioMediaplanPlanDataResponse as AutopilotRadioMediaplan,
} from 'sber-marketing-types/backend';
import type { BriefStageForm, GroupedDictionaries, SwitchKeyActivityParams } from './types';
import type { AutopilotStage } from './types';

const actionCreator = actionCreatorFactory('AUTOPILOT_RADIO_PAGE');

const loadAutopilot = actionCreator<AutopilotRadio>('LOAD_AUTOPILOT');
const loadActivity = actionCreator<Activity>('LOAD_ACTIVITY');
const loadMediaplan = actionCreator<AutopilotRadioMediaplan>('LOAD_MEDIAPLAN');
const loadTargetGroups = actionCreator<AutopilotRadioTargetGroup[]>('LOAD_TARGET_GROUPS');
const loadDictionaries = actionCreator<GroupedDictionaries>('LOAD_DICTIONARIES');
const resetPageState = actionCreator('RESET_PAGE_STATE');
const setBriefFormValues = actionCreator<Partial<BriefStageForm>>('SET_BRIEF_FORM_VALUES');
const setSelectedBudgetItemIds = actionCreator<string[]>('SET_SELECTED_BUDGET_ITEM_IDS');
const setCurrentStage = actionCreator<AutopilotStage>('SET_CURRENT_STAGE');
const setPreloaderStatus = actionCreator<boolean>('SET_PRELOADER_STATUS');
const openSidebar = actionCreator('OPEN_SIDEBAR');
const closeSidebar = actionCreator('CLOSE_SIDEBAR');

const switchKeyActivityAsync = actionCreator.async<SwitchKeyActivityParams, void, Error>('SWITCH_KEY_ACTIVITY_ASYNC');

export {
    loadAutopilot,
    loadActivity,
    loadMediaplan,
    loadTargetGroups,
    loadDictionaries,
    resetPageState,
    setBriefFormValues,
    setSelectedBudgetItemIds,
    setCurrentStage,
    setPreloaderStatus,
    openSidebar,
    closeSidebar,
    switchKeyActivityAsync,
};
