import * as React from 'react';
import * as lodash from 'lodash';

import * as style from './TextareaCell.scss';

import { Textarea_redesign as Textarea, Textarea_theme as TextareaTheme } from 'sber-marketing-ui';

import { SnapshotTooltip } from '../SnapshotTooltip';
import { CommentsButton } from '../CommentsButton';
import { ContextMenu } from '../ContextMenu';

interface Props {
    edit: boolean;
    title: string;
    placeholder: string;
    customStyle?: any;
    snapshot?: any;
    commentButtonProps?: { hasNewComments: boolean; onClick: () => void };
    onValueChange: (value: string) => void;
}

export const TextareaCell = ({
    edit,
    title,
    placeholder,
    customStyle,
    snapshot,
    commentButtonProps,
    onValueChange,
}: Props): JSX.Element => {
    const [isHovered, setHoveredStatus] = React.useState(false);

    const textareaRef = React.useRef<Textarea>();
    const lastClickedCharacterIndexRef = React.useRef(null);

    React.useEffect(() => {
        if (edit) {
            const index = lastClickedCharacterIndexRef.current || -1;

            const textareaElement = textareaRef.current.getTextElement();

            textareaElement.setSelectionRange(index, index);
            textareaElement.focus();
        } else {
            lastClickedCharacterIndexRef.current = null;
        }
    }, [edit]);

    function onMouseEnter() {
        setHoveredStatus(true);
    }

    function onMouseLeave() {
        setHoveredStatus(false);
    }

    function onCharacterClick(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
        const spanElement = event.target as HTMLSpanElement;

        const index = lodash.findIndex(spanElement.parentElement.childNodes, spanElement);
        lastClickedCharacterIndexRef.current = index + 1;
    }

    async function onCopyValueClick() {
        await navigator.clipboard.writeText(title);
    }

    async function onCutValueClick() {
        await navigator.clipboard.writeText(title);

        onValueChange('');
    }

    async function onPasteValueClick() {
        const newValue = await navigator.clipboard.readText();

        onValueChange(newValue);
    }

    function onClearValueClick() {
        onValueChange('');
    }

    return (
        <>
            <CommentsButton {...commentButtonProps} />
            <SnapshotTooltip snapshot={snapshot} />
            <ContextMenu
                items={[
                    { title: 'Копировать', onClick: onCopyValueClick },
                    { title: 'Вырезать', onClick: onCutValueClick },
                    { title: 'Вставить', onClick: onPasteValueClick },
                    { title: 'Смотреть комментарии', onClick: commentButtonProps?.onClick },
                    { title: 'Очистить', onClick: onClearValueClick },
                ]}
            />
            <div className={style.root} style={{ ...customStyle }}>
                {!edit && (
                    <>
                        <div className={style.title} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                            {!isHovered && (title || '—')}

                            {isHovered &&
                                title
                                    .toString()
                                    .split('')
                                    .map((item, index) => (
                                        <span key={index} onClick={onCharacterClick}>
                                            {item}
                                        </span>
                                    ))}
                        </div>
                    </>
                )}

                {edit && (
                    <Textarea
                        ref={textareaRef}
                        value={title}
                        placeholder={placeholder}
                        theme={TextareaTheme.Borderless}
                        onBlur={onValueChange}
                        autoFocus
                    />
                )}
            </div>
        </>
    );
};
