import * as React from 'react';
import { connect } from 'react-redux';
import { Dictionary } from 'lodash';
import { UserResponseParams } from 'sber-marketing-types/frontend';
import { BudgetItemStatus } from '@mrm/budget';
import autobind from 'autobind-decorator';

import { MultiReferenceDictionaryApi } from '@api';

import { StoreState } from '@store';
import { TableLine, ColumnName, ColumnData, getBudgetPlanningPageState } from '@store/budgetPlanning';

import { LineColor } from '../TableBody';
import { LineCellsParams } from '../LayerManager/LayerManager';

import { CellRef, Line } from './Line';

interface Props extends Partial<MapProps> {
    line: TableLine;
    cellsParams: { [lineId: string]: LineCellsParams };
    columns: ColumnData[];
    visibleColumnsNames: ColumnName[];
    fixedColumnsNames: ColumnName[];
    columnsWidth: { [columnName: string]: number };
    width: number;
    lineColor: LineColor;
    hoveredLineId: string;
    hoveredColumnName: string;
    draggedEdgeColumnName: string;
    lineUnsavedStatus: BudgetItemStatus.Approved | BudgetItemStatus.Rejected | BudgetItemStatus.OnExpertApprovement;
    lineHasUnsavedChanges: boolean;
    userIsBudgetExpert: boolean;
    userIsSupervisor: boolean;
    allUsers: UserResponseParams[];
    fixedColumnsLinesRef: (element: HTMLDivElement) => void;
    onMouseEnter: (lineId: string) => void;
    onMouseLeave: () => void;
    onInputValueChange: (lineId: string, columnName: ColumnName, value: string, originalValue: string) => void;
    onDropdownCellClick: (
        lineId: string,
        columnName: ColumnName,
        dropdownContent: JSX.Element,
        contentHeight: number,
    ) => void;
    onDropdownItemSelection: (lineId: string, columnName: ColumnName, id: string, originalId: string) => void;
    onDatepickerCellClick: (
        lineId: string,
        columnName: ColumnName,
        datepickerContent: JSX.Element,
        contentHeight: number,
    ) => void;
    onDatepickerValueChange: (lineId: string, columnName: ColumnName, value: Date, originalValue: Date) => void;
    onApproveButtonClick: (lineId: string) => void;
    onRejectButtonClick: (lineId: string) => void;
    onInfoMouseEnter: (lineId: string) => void;
    onInfoMouseLeave: () => void;
    onCellCopy: (lineId: string, columnName: ColumnName, event: React.ClipboardEvent<HTMLDivElement>) => void;
}

interface MapProps {
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi;
}

@(connect(mapStateToProps) as any)
export class LineContainer extends React.PureComponent<Props> {
    private cellsRefs: Dictionary<HTMLDivElement> = {};
    private cellsContentRefs: Dictionary<CellRef> = {};
    private pageContent: HTMLDivElement;

    public constructor(props: Props) {
        super(props);

        this.cellsRefs = {};
        this.cellsContentRefs = {};

        this.pageContent = document.getElementById('pageContent') as HTMLDivElement;
    }

    public render(): JSX.Element {
        return (
            <Line
                {...this.props}
                multiReferenceDictionaryApi={this.props.multiReferenceDictionaryApi}
                registerCellRef={this.registerCellRef}
                registerCellContentRef={this.registerCellContentRef}
                focusField={this.focusField}
                onMouseEnter={this.onMouseEnter}
            />
        );
    }

    @autobind
    private onMouseEnter(): void {
        this.props.onMouseEnter(this.props.line.id);
    }

    @autobind
    private registerCellRef(columnName: ColumnName, ref: HTMLDivElement) {
        this.cellsRefs[columnName] = ref;
    }

    @autobind
    private registerCellContentRef(columnName: ColumnName, ref: CellRef) {
        this.cellsContentRefs[columnName] = ref;
    }

    @autobind
    private focusField(columnName: ColumnName) {
        const cellRef = this.cellsRefs[columnName];

        if (!cellRef) {
            console.warn(`Missing cellRef for field ${columnName}`);
        } else {
            // scrollIntoView makes pageContent scrollbarVisible, this fix will hide it
            this.pageContent.style.marginRight = '0';
            this.pageContent.style.overflow = 'hidden';

            cellRef.scrollIntoView({ block: 'nearest', inline: 'center' });

            this.pageContent.style.marginRight = '-25px';
            this.pageContent.style.overflow = 'scroll';
            setTimeout(() => {
                const contentRef = this.cellsContentRefs[columnName];

                if (!contentRef) {
                    console.warn(`Missing contentRef for field ${columnName}`);
                } else {
                    contentRef.focus();
                }
            }, 300);
        }
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { multiReferenceDictionaryApi } = getBudgetPlanningPageState(state);

    return {
        multiReferenceDictionaryApi,
    };
}
