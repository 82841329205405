import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as lodash from 'lodash';

import type { StoreState } from '@store';

import { setMediaplanSettingsFormValues } from '@store/autopilot/actions';
import { getBriefStageForm, getMediaplanSettingsForm, getMediaplanChannelsGroups } from '@store/autopilot/selectors';

export const useStore = (readOnly?: boolean) => {
    const dispatch = useDispatch();

    const briefForm = useSelector((state: StoreState) => getBriefStageForm(state));
    const mediaplanForm = useSelector((state: StoreState) => getMediaplanSettingsForm(state));
    const dictionaryChannelsGroups = useSelector((state: StoreState) => getMediaplanChannelsGroups(state));

    const channelsGroups = mediaplanForm.isManuallySet ? mediaplanForm.channelGroups : dictionaryChannelsGroups;

    const onAllocationChange = React.useCallback(
        (value: number) => {
            if (mediaplanForm.isManuallySet) {
                const share = value / 100;

                if (share !== mediaplanForm.channelGroups.Performance.share) {
                    const updatedGroups = lodash.cloneDeep(mediaplanForm.channelGroups);

                    updatedGroups.Performance.share = share;
                    updatedGroups.Media.share = (100 - value) / 100;

                    dispatch(setMediaplanSettingsFormValues({ channelGroups: updatedGroups }));
                }
            }
        },
        [mediaplanForm],
    );

    const onGroupItemClick = React.useCallback(
        (params: { id: number; name: string }) => {
            const { id, name } = params;

            const updatedGroups = lodash.cloneDeep(mediaplanForm.channelGroups);

            const allGroupItems = [
                ...updatedGroups.Performance.digital_channels,
                ...updatedGroups.Media.digital_channels,
            ];

            allGroupItems.forEach((item) => {
                if (item.digital_channel.name === name && item.digital_channel.id === id) {
                    item.is_use = !item.is_use;
                }
            });

            dispatch(setMediaplanSettingsFormValues({ channelGroups: updatedGroups }));
        },
        [mediaplanForm],
    );

    return {
        disabled: !mediaplanForm.isManuallySet || readOnly,
        budget: parseInt(briefForm.budget, 10),
        sliderValue: Math.round(channelsGroups?.Performance.share * 100) || 0,
        channelsGroups,
        onAllocationChange,
        onGroupItemClick,
    };
};
