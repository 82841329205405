import { isNil } from 'lodash';
import { TaskApi } from './TaskApi';

/** Task draft scheduler - global service which manages and schedules any tasks drafts updating */
export class TaskDraftScheduler {
    /** Task draft scheduler instance */
    private static instance: TaskDraftScheduler | null = null;

    /** Interval of time when scheduled update should be triggered */
    private static readonly TIMEOUT_INTERVAL: number = 10000;

    /** Scheduled tasks draft updates */
    private readonly scheduled: Map<string, number>;

    private constructor() {
        this.scheduled = new Map<string, number>();
    }

    /** Returns instance of task draft scheduler */
    public static getInstance(): TaskDraftScheduler {
        if (isNil(TaskDraftScheduler.instance)) {
            TaskDraftScheduler.instance = new TaskDraftScheduler();
        }
        return TaskDraftScheduler.instance;
    }

    /** Schedule task draft edition */
    public scheduleUpdate(id: string, title: string, description: string): void {
        this.cancelUpdate(id);
        this.scheduled.set(
            id,
            window.setTimeout(async () => {
                await TaskApi.editTaskDraft({ id, title, description });
                window.clearTimeout(this.scheduled.get(id));
                this.scheduled.delete(id);
            }, TaskDraftScheduler.TIMEOUT_INTERVAL),
        );
    }

    /** Cancel task draft edition if it's planned */
    public cancelUpdate(id: string): void {
        if (this.scheduled.has(id)) {
            window.clearTimeout(this.scheduled.get(id));
            this.scheduled.delete(id);
        }
    }
}
