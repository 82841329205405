import * as React from 'react';
import * as lodash from 'lodash';
import classNames from 'classnames';

import type { BriefField, BriefCost } from 'sber-marketing-types/frontend';

import { Field } from '../../../../Field';

import * as style from './SwitchGroupFields.scss';

interface Props {
    activityId: number;
    briefId: string;
    blockId: string;
    switchPropertyId: string;
    fields: BriefField[];
    briefCost?: BriefCost;
}

export const SwitchGroupFields: React.FC<Props> = ({
    activityId,
    blockId,
    briefId,
    switchPropertyId,
    fields,
    briefCost,
}) => {
    return (
        <div
            data-section-id={`${briefId}-${blockId}-${switchPropertyId}`}
            className={classNames(style.fields, 'sub-section', `sub-section ${briefId}-${blockId}-${switchPropertyId}`)}
        >
            <Fields
                activityId={activityId}
                briefId={briefId}
                fields={fields}
                briefCost={briefCost}
                blockId={blockId}
                switchPropertyId={switchPropertyId}
            />
        </div>
    );
};

interface FieldsProps {
    activityId: number;
    briefId: string;
    blockId: string;
    switchPropertyId: string;
    fields: BriefField[];
    briefCost?: BriefCost;
}

const Fields: React.FC<FieldsProps> = ({ activityId, briefId, blockId, fields, briefCost, switchPropertyId }) => {
    const subFields = lodash
        .sortBy(fields, (item) => item.order)
        .filter((field) => field.properties.switchPropertyId === switchPropertyId);

    return subFields.length ? (
        <div className={style.fieldsWrapper}>
            {lodash
                .sortBy(fields, (item) => item.order)
                .filter((field) => field.properties.switchPropertyId === switchPropertyId)
                .map((field, index) => (
                    <div className={style.field} key={`field ${field.id}`}>
                        <Field
                            activityId={activityId}
                            index={index}
                            id={field.id}
                            uniqId={field.uniqId}
                            parentUniqId={field.parentUniqId}
                            briefId={briefId}
                            blockId={blockId}
                            briefCost={briefCost}
                            fields={fields}
                        />
                    </div>
                ))}
        </div>
    ) : null;
};
