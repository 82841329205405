import { actionCreatorFactory } from 'typescript-fsa';

import type { InitParams, InitResult } from '../types';

const actionCreator = actionCreatorFactory('ACTIVITY_REFERENCE_MENU_ASYNC').async;

const init = actionCreator<InitParams, InitResult, Error>('INIT');
const save = actionCreator<void, void, Error>('SAVE');

export { init, save };
