// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-task-pages-TaskPage-_TaskPage__root{font-family:\"Open Sans\", monospace;display:flex;padding-left:48px;min-height:calc(100vh - 49px);max-height:calc(100vh - 49px)}.src-client-modules-task-pages-TaskPage-_TaskPage__headerContent{font-size:14px;font-weight:600;min-width:100px}.src-client-modules-task-pages-TaskPage-_TaskPage__subheader{align-self:flex-start;font-weight:400;font-size:12px}.src-client-modules-task-pages-TaskPage-_TaskPage__activityName{white-space:nowrap;text-overflow:ellipsis;overflow:hidden}.src-client-modules-task-pages-TaskPage-_TaskPage__taskTitle{white-space:nowrap;text-overflow:ellipsis;overflow:hidden}.src-client-modules-task-pages-TaskPage-_TaskPage__taskTitleCopy{display:inline-block}.src-client-modules-task-pages-TaskPage-_TaskPage__headerSeparator{color:var(--color-primary-50);font-size:16px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/task/pages/TaskPage/TaskPage.scss"],"names":[],"mappings":"AAAA,wDACE,kCAAmC,CACnC,YAAa,CACb,iBAAkB,CAClB,6BAA8B,CAC9B,6BAA8B,CAC/B,iEAGC,cAAe,CACf,eAAgB,CAChB,eAAgB,CACjB,6DAGC,qBAAsB,CACtB,eAAgB,CAChB,cAAe,CAChB,gEAGC,kBAAmB,CACnB,sBAAuB,CACvB,eAAgB,CACjB,6DAGC,kBAAmB,CACnB,sBAAuB,CACvB,eAAgB,CACjB,iEAGC,oBAAqB,CACtB,mEAGC,6BAA8B,CAC9B,cAAe","sourcesContent":[".root {\n  font-family: \"Open Sans\", monospace;\n  display: flex;\n  padding-left: 48px;\n  min-height: calc(100vh - 49px);\n  max-height: calc(100vh - 49px);\n}\n\n.headerContent {\n  font-size: 14px;\n  font-weight: 600;\n  min-width: 100px;\n}\n\n.subheader {\n  align-self: flex-start;\n  font-weight: 400;\n  font-size: 12px;\n}\n\n.activityName {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.taskTitle {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.taskTitleCopy {\n  display: inline-block;\n}\n\n.headerSeparator {\n  color: var(--color-primary-50);\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-task-pages-TaskPage-_TaskPage__root",
	"headerContent": "src-client-modules-task-pages-TaskPage-_TaskPage__headerContent",
	"subheader": "src-client-modules-task-pages-TaskPage-_TaskPage__subheader",
	"activityName": "src-client-modules-task-pages-TaskPage-_TaskPage__activityName",
	"taskTitle": "src-client-modules-task-pages-TaskPage-_TaskPage__taskTitle",
	"taskTitleCopy": "src-client-modules-task-pages-TaskPage-_TaskPage__taskTitleCopy",
	"headerSeparator": "src-client-modules-task-pages-TaskPage-_TaskPage__headerSeparator"
};
export default ___CSS_LOADER_EXPORT___;
