import * as React from 'react';

import { CommentSearchResult } from '@store/search';

import { CommentCard } from './CommentCard';

interface Props extends Partial<CommentSearchResult> {}

export class CommentCardContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { id, text, files, createTime, updateTime, authorId, task, activity, channelId } = this.props;

        return (
            <CommentCard
                id={id}
                text={text}
                files={files}
                createTime={createTime}
                updateTime={updateTime}
                authorId={authorId}
                task={task}
                activity={activity}
                channelId={channelId}
            />
        );
    }
}
