// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-leaderDashboard-LeaderDashboard-common-_styles__red{color:#EA9C9C}.src-client-modules-leaderDashboard-LeaderDashboard-common-_styles__blue{color:#94B9E5}.src-client-modules-leaderDashboard-LeaderDashboard-common-_styles__green{color:#A7CEB4}.src-client-modules-leaderDashboard-LeaderDashboard-common-_styles__mustard{color:#EAC993}\n", "",{"version":3,"sources":["webpack://./src/client/modules/leaderDashboard/LeaderDashboard/common/styles.scss"],"names":[],"mappings":"AAmBA,wEACI,aAlBc,CAmBjB,yEAGG,aApBe,CAqBlB,0EAGG,aAzBgB,CA0BnB,4EAGG,aA/BkB","sourcesContent":["$colors: (\n    'mustard': #EAC993,\n    'red': #EA9C9C,\n    'green': #A7CEB4,\n    'blue': #94B9E5,\n);\n\n\n@function get-color($key: 'green') {\n    @return map-get($colors, $key);\n}\n\n@mixin cropped-text {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n\n.red {\n    color: get-color('red');\n}\n\n.blue {\n    color: get-color('blue');\n}\n\n.green {\n    color: get-color('green');\n}\n\n.mustard {\n    color: get-color('mustard');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"red": "src-client-modules-leaderDashboard-LeaderDashboard-common-_styles__red",
	"blue": "src-client-modules-leaderDashboard-LeaderDashboard-common-_styles__blue",
	"green": "src-client-modules-leaderDashboard-LeaderDashboard-common-_styles__green",
	"mustard": "src-client-modules-leaderDashboard-LeaderDashboard-common-_styles__mustard"
};
export default ___CSS_LOADER_EXPORT___;
