import * as React from 'react';
import { WorkTypeParams } from 'sber-marketing-types/frontend';
import { useSelector } from 'react-redux';

import { UserCurtIndicator } from '@common/Card';

import { StoreState } from '@store';
import { getAllUsers } from '@store/appUsers';

import { WithEditor } from '../WithEditor';
import { DropdownEditor } from '../DropdownEditor';

import * as styles from './Executor.scss';

interface Props {
    canEdit: boolean;
    text: string;
    executorId: number;
    taskDepartmentId: string;
    taskWorkType?: WorkTypeParams;
    editExecutor: (executorId: number) => Promise<void>;
}

export function Executor({ canEdit, text, executorId, taskWorkType, editExecutor }: Props): JSX.Element {
    const items = useSelector((state: StoreState) => getAllUsers(state))
        .filter((user) => {
            if (!user.isActive) {
                return false;
            }

            if (user.id === executorId) {
                return false;
            }

            if (taskWorkType) {
                return taskWorkType?.departmentIds.includes(user.departmentId);
            }

            return true;
        })
        .map((user) => ({
            id: user.id,
            label: `${user.secondName} ${user.firstName}`,
        }));

    return (
        <WithEditor
            canEdit={canEdit}
            loadingClassName={styles.skeleton}
            valueContent={() => <UserCurtIndicator tooltip={text}>{text}</UserCurtIndicator>}
            editorContent={(onValueEdit) => (
                <DropdownEditor title="Изменить исполнителя" items={items} onSelect={onValueEdit} />
            )}
            editValue={editExecutor}
        />
    );
}
