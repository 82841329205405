import * as React from 'react';

import { TaskStatus as ApiTaskStatus } from 'sber-marketing-types/frontend';
import { WithTooltip } from 'sber-marketing-ui';

import { useGetTaskQuery } from '@api';

import { SkeletonBlock, Tag, TagProps } from '@common/components';
import { useChangeTaskStatus } from '@modules/task/hooks';

export interface TaskStatusButtonProps extends TagProps {
    taskId: string;
    onSubmit?: () => void;
    onAskRequired?: () => void;
}

export const TaskInProgressButton: React.FC<TaskStatusButtonProps> = ({
    taskId,
    className,
    onClick,
    onSubmit,
    onAskRequired,
    ...props
}) => {
    const { isLoading, data: { task } = {} } = useGetTaskQuery({ id: taskId });
    const { setStatus, isLoading: isUpdating } = useChangeTaskStatus(taskId, onSubmit, onAskRequired);

    if (isLoading) {
        return <SkeletonBlock height={28} />;
    }

    const handleClick = (e: any) => {
        setStatus(ApiTaskStatus.InProgress);
        onClick?.(e);
    };

    const content = (
        <Tag loading={isUpdating} {...props} onClick={handleClick}>
            В работу
        </Tag>
    );

    if (!task.deadline || !task.executor) {
        return <WithTooltip content="Заполните обязательные поля">{content}</WithTooltip>;
    }

    return content;
};
