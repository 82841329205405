import * as React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { DifferenceItemParams } from '@store/news';

import { TextClamper } from '../../TextClamper';

import * as style from './Difference.scss';

interface Props extends DifferenceItemParams {
    index: number;
}

export function Difference({
    caption,
    before,
    beforeCaption,
    after,
    afterCaption,
    afterLink,
    index,
}: Props): JSX.Element {
    const defaultText = 'Значение не указано';

    return (
        <React.Fragment>
            <div className={style.caption}>{caption}</div>

            <div
                className={style.root}
                {...{
                    'qa-id': 'dashboardNewsColumnItemDifference',
                    'qa-index': index,
                }}
            >
                <div className={style.arrowContainer}>
                    <TextClamper lines={2}>
                        <div
                            className={style.text}
                            {...{
                                'qa-id': 'dashboardNewsColumnItemDifferenceBefore',
                            }}
                        >
                            {before || defaultText}
                        </div>
                    </TextClamper>

                    <div className={style.caption}>{beforeCaption}</div>

                    {afterLink ? (
                        <div
                            {...{
                                'qa-id': 'dashboardNewsColumnItemDifferenceAfter',
                            }}
                        >
                            <Link
                                to={afterLink}
                                className={classNames([style.after, style.text, style.colorGreen, style.weightBold])}
                            >
                                {after}
                            </Link>
                        </div>
                    ) : (
                        <TextClamper lines={2}>
                            <div
                                className={classNames([style.after, style.text, style.colorGreen, style.weightBold])}
                                {...{
                                    'qa-id': 'dashboardNewsColumnItemDifferenceAfter',
                                }}
                            >
                                {after || defaultText}
                            </div>
                        </TextClamper>
                    )}

                    <div className={style.arrow} />

                    <div className={style.arrowPointer} />
                </div>

                <div className={style.caption}>{afterCaption}</div>
            </div>
        </React.Fragment>
    );
}
