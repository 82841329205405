import { BriefViewResponse, FileResponse } from 'sber-marketing-types/backend';

import type {
    FullTaskInfoResponse,
    TaskCardParams,
    EditTaskParams,
    TaskResponse,
    UserResponseParams,
    PlugTelegramToTaskResponse,
} from 'sber-marketing-types/frontend';

import { IDPathParams, rtkApi } from './rtkApi';
import { MrmClient } from '../MrmClient';
import { selectMyUserId } from './auth';

export interface TaskPathParams {
    taskId: string;
}

export interface TaskData extends FullTaskInfoResponse {
    task: TaskCardParams & FullTaskInfoResponse['task'];
}

export interface TaskTagsMutation extends IDPathParams<string> {
    tags: string[];
}

export interface RemoveTaskParticipantMutation extends TaskPathParams {
    userId: number;
}

export interface AddTaskParticipantMutation extends TaskPathParams {
    userIds: number[];
}

export interface TaskFileParams extends TaskPathParams {
    fileId: string;
}

export interface AddTaskFileMutation extends FileResponse, TaskPathParams {}
export interface TaskParticipantsData extends TaskPathParams {
    search?: string;
}

export const taskApi = rtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getTask: builder.query<TaskData, IDPathParams<string>>({
            query: ({ id }) => `/task/${id}`,
            providesTags: (_, __, { id }) => [{ type: 'task', id }],
        }),
        setTask: builder.mutation<TaskResponse, EditTaskParams & IDPathParams<string>>({
            query: ({ id, ...body }) => ({
                url: `/task/${id}`,
                method: 'PUT',
                body,
            }),
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        taskApi.util.updateQueryData('getTask', { id }, (draft) => {
                            Object.assign(draft, data);
                        }),
                    );
                } catch {}
            },
        }),
        deleteTask: builder.mutation<undefined, string>({
            query: (taskId) => ({
                url: `/task/${taskId}`,
                method: 'DELETE',
            }),
        }),
        getTaskBrief: builder.query<BriefViewResponse, IDPathParams<string>>({
            query: ({ id }) => `/task/${id}/brief`,
            providesTags: ({ id }) => [{ type: 'brief', id }],
        }),
        subscribeTask: builder.mutation<void, IDPathParams<string>>({
            query: ({ id }) => ({
                url: `/task/${id}/subscription`,
                method: 'PUT',
            }),
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    dispatch(
                        taskApi.util.updateQueryData('getTask', { id }, (draft) => {
                            draft.task.subscription = true;
                        }),
                    );
                } catch {}
            },
        }),
        unsubscribeTask: builder.mutation<void, IDPathParams<string>>({
            query: ({ id }) => ({
                url: `/task/${id}/subscription`,
                method: 'DELETE',
            }),
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    dispatch(
                        taskApi.util.updateQueryData('getTask', { id }, (draft) => {
                            draft.task.subscription = false;
                        }),
                    );
                } catch {}
            },
        }),
        subscribeTgTask: builder.mutation<PlugTelegramToTaskResponse, IDPathParams<string>>({
            query: ({ id }) => ({
                url: `/task/${id}/telegram`,
                method: 'POST',
            }),
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                const patches = dispatch(
                    taskApi.util.updateQueryData('getTask', { id }, (draft) => {
                        draft.telegramStatus.task = { userConnected: true };
                    }),
                );
                try {
                    const { data } = await queryFulfilled;

                    if (data.connectLink) {
                        patches.undo();
                    }
                } catch {
                    patches.undo();
                }
            },
        }),
        unsubscribeTgTask: builder.mutation<void, IDPathParams<string>>({
            query: ({ id }) => ({
                url: `/task/${id}/telegram`,
                method: 'DELETE',
            }),
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    dispatch(
                        taskApi.util.updateQueryData('getTask', { id }, (draft) => {
                            draft.telegramStatus.task = null;
                        }),
                    );
                } catch {}
            },
        }),
        setTaskTags: builder.mutation<null, TaskTagsMutation>({
            async queryFn({ id, tags }) {
                try {
                    const client = await MrmClient.getInstance();
                    const task = await client.domain.projects.getTask({ id });
                    const operations: Promise<any>[] = [];

                    for (const tagId of task.model.tags) {
                        if (!tags.includes(tagId)) {
                            operations.push(task.model.removeTag({ tagId }));
                        }
                    }

                    for (const tagId of tags) {
                        if (!task.model.tags.includes(tagId)) {
                            operations.push(task.model.addTag({ tagId }));
                        }
                    }

                    await Promise.all(operations);
                } catch (e) {
                    console.error(e);
                }

                return { data: null };
            },
            async onQueryStarted({ id, tags }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    dispatch(
                        taskApi.util.updateQueryData('getTask', { id }, (draft) => {
                            draft.task.tags = tags;
                        }),
                    );
                } catch {}
            },
        }),
        addTaskFile: builder.mutation<void, AddTaskFileMutation>({
            query: ({ taskId, ...body }) => ({
                url: `/task/${taskId}/file`,
                body,
                method: 'POST',
            }),
            async onQueryStarted({ taskId, ...file }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    dispatch(
                        taskApi.util.updateQueryData('getTask', { id: taskId }, (draft) => {
                            draft.task.files.push(file);
                        }),
                    );
                } catch {}
            },
        }),
        deleteTaskFile: builder.mutation<void, TaskFileParams>({
            query: ({ taskId, fileId }) => ({
                url: `/task/${taskId}/file/${fileId}`,
                method: 'DELETE',
            }),
            async onQueryStarted({ taskId, fileId }, { dispatch, queryFulfilled }) {
                const patches = dispatch(
                    taskApi.util.updateQueryData('getTask', { id: taskId }, (draft) => {
                        draft.task.files = draft.task.files.filter(({ id }) => id !== fileId);
                    }),
                );

                try {
                    await queryFulfilled;
                } catch (e) {
                    console.error(e);
                    patches.undo();
                }
            },
        }),
        getTaskParticipants: builder.query<UserResponseParams[], TaskParticipantsData>({
            query: ({ taskId }) => `/task/${taskId}/participants`,
        }),
        addTaskParticipants: builder.mutation<UserResponseParams[], AddTaskParticipantMutation>({
            query: ({ taskId, userIds }) => ({
                url: `/task/${taskId}/participant/add`,
                method: 'POST',
                body: { userIds },
            }),
            async onQueryStarted({ taskId, userIds }, { dispatch, queryFulfilled, getState }) {
                try {
                    const result = await queryFulfilled;
                    const userId = selectMyUserId(getState() as any);

                    dispatch(
                        taskApi.util.updateQueryData('getTaskParticipants', { taskId }, (draft) => {
                            draft.splice(2, 0, ...[...result.data].reverse());
                        }),
                    );
                    dispatch(
                        taskApi.util.updateQueryData('getTask', { id: taskId }, (draft) => {
                            draft.task = {
                                ...draft.task,
                                participants: [
                                    ...(draft.task.participants || []),
                                    ...result.data.map(({ id }) => ({ userId: id, invitingUserId: userId })),
                                ],
                            };
                        }),
                    );
                } catch (e) {
                    console.error(e);
                }
            },
        }),
        removeTaskParticipant: builder.mutation<void, RemoveTaskParticipantMutation>({
            query: ({ taskId, userId }) => ({
                url: `/task/${taskId}/participant/${userId}`,
                method: 'DELETE',
            }),
            async onQueryStarted({ taskId, userId }, { dispatch, queryFulfilled }) {
                const participantPatches = dispatch(
                    taskApi.util.updateQueryData('getTaskParticipants', { taskId }, (draft) => {
                        const participants = draft.filter(({ id }) => id !== userId);
                        draft.length = 0;
                        for (const participant of participants) {
                            draft.push(participant);
                        }
                    }),
                );
                const taskPatches = dispatch(
                    taskApi.util.updateQueryData('getTask', { id: taskId }, (draft) => {
                        draft.task = {
                            ...draft.task,
                            chanels: draft.task.chanels?.map((channel) => ({
                                ...channel,
                                participantIds: channel.participantIds?.filter((id) => id !== userId),
                            })),
                            participants: draft.task.participants?.filter(({ userId: id }) => id !== userId),
                        };
                    }),
                );

                try {
                    await queryFulfilled;
                } catch {
                    participantPatches.undo();
                    taskPatches.undo();
                }
            },
        }),
    }),
});

export const {
    useGetTaskQuery,
    useGetTaskBriefQuery,
    useDeleteTaskMutation,
    useSubscribeTaskMutation,
    useUnsubscribeTaskMutation,
    useSubscribeTgTaskMutation,
    useUnsubscribeTgTaskMutation,
    useSetTaskMutation,
    useSetTaskTagsMutation,
    useAddTaskFileMutation,
    useDeleteTaskFileMutation,
    useGetTaskParticipantsQuery,
    useAddTaskParticipantsMutation,
    useRemoveTaskParticipantMutation,
} = taskApi;
