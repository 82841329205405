import * as React from 'react';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import * as moment from 'moment';

import { MonthValue } from '@mrm/budget';
import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type { SerialNumberProps } from './WithLinkedBudgetItems';
import type { CardProps } from './BudgetCard';
import { MONTHS } from '@store/autopilot/types';
import type { BudgetProps } from './WithBudgets';

import { BudgetStageWidgetTemplate } from './BudgetStageWidgetTemplate';
import { Money, MoneyFormatter } from '@common/Utils';

interface Props {
    readOnly: boolean;
    loading: boolean;
    activity: Activity;
    budgets: BudgetProps[];
    linkedBudgetItems: SerialNumberProps[];
    selectedBudgetItemIds: string[];
    setSelectedBudgetItemIds: (ids: string[]) => void;
}

export class BudgetStageWidgetBehaviour extends React.PureComponent<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        const { readOnly, loading, selectedBudgetItemIds } = this.props;

        return React.createElement(BudgetStageWidgetTemplate, {
            readOnly,
            loading,
            cards: this.makeCards(),
            selectedBudgetItemIds,
            onAddButtonClick: this.onAddButtonClick,
        });
    }

    @autobind
    protected onCardClick(id: string): void {
        const { selectedBudgetItemIds } = this.props;

        const updatedSelectionIds = lodash.xor(selectedBudgetItemIds, [id]);

        this.props.setSelectedBudgetItemIds(updatedSelectionIds);
    }

    @autobind
    protected onAddButtonClick(): void {
        const { activity, budgets } = this.props;

        const currentBudget = lodash.maxBy(budgets, (item) => item.year);

        this.openUrlInNewTab(`/budget/execution?activityId=${activity.id}&budgetId=${currentBudget.id}`);
    }

    private makeCards(): CardProps[] {
        const { linkedBudgetItems, selectedBudgetItemIds } = this.props;

        const cards: CardProps[] = linkedBudgetItems.map((item) => ({
            id: item.budgetItem.id,
            selected: lodash.includes(selectedBudgetItemIds, item.budgetItem.id),
            serialNumber: item.budgetItem.serialNumber,
            status: item.status,
            budgetYear: item.budgetItem.budget.year,
            date: moment(item.createdAt).format('D MMMM YY'),
            sapComment: item.budgetItem.sapComment,
            tool: lodash.get(item, 'budgetItem.dictionary.tool.value') || 'Не указан',
            expertName: item.approver ? `${item.approver.firstName} ${item.approver.secondName}` : 'не указан',
            planSum: this.sumFunds(item.budgetItem.plannedFunds),
            reserveSum: this.sumFunds(item.budgetItem.reservedFunds),
            factSum: this.sumFunds(item.budgetItem.factFunds),
            readOnly: this.props.readOnly || false,
            onClick: this.onCardClick,
        }));

        const cardsSortedBySerialNumber = lodash.sortBy(cards, (item) => item.serialNumber);
        const cardsSortedBySelection = lodash.sortBy(cardsSortedBySerialNumber, (item) => !item.selected);

        return cardsSortedBySelection;
    }

    private sumFunds(funds: MonthValue): string {
        const sum = MONTHS.reduce((acc, item) => acc + funds[item], 0);

        return MoneyFormatter.toThousands(Money.fromCopecks(sum), { hideCaption: true });
    }

    private openUrlInNewTab(url: string): void {
        window.open(url, '_blank');
    }
}
