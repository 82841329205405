// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-ResetLinksGroupTooltip-_ResetLinksGroupTooltip__tooltip{min-width:263px;text-align:left;font-family:'Open Sans';font-weight:400;font-size:12px;line-height:14px}.src-client-modules-common-ResetLinksGroupTooltip-_ResetLinksGroupTooltip__tooltipSubtitle{margin-bottom:20px;font-weight:600}.src-client-modules-common-ResetLinksGroupTooltip-_ResetLinksGroupTooltip__tooltipList{list-style-type:disc;padding-left:17px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/ResetLinksGroupTooltip/ResetLinksGroupTooltip.scss"],"names":[],"mappings":"AAAA,mFACI,eAAgB,CAChB,eAAgB,CAEhB,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACpB,2FAGG,kBAAmB,CAEnB,eAAgB,CACnB,uFAGG,oBAAqB,CACrB,iBAAkB","sourcesContent":[".tooltip {\n    min-width: 263px;\n    text-align: left;\n\n    font-family: 'Open Sans';\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 14px;\n}\n\n.tooltipSubtitle {\n    margin-bottom: 20px;\n\n    font-weight: 600;\n}\n\n.tooltipList {\n    list-style-type: disc;\n    padding-left: 17px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "src-client-modules-common-ResetLinksGroupTooltip-_ResetLinksGroupTooltip__tooltip",
	"tooltipSubtitle": "src-client-modules-common-ResetLinksGroupTooltip-_ResetLinksGroupTooltip__tooltipSubtitle",
	"tooltipList": "src-client-modules-common-ResetLinksGroupTooltip-_ResetLinksGroupTooltip__tooltipList"
};
export default ___CSS_LOADER_EXPORT___;
