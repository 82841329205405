import { actionCreatorFactory } from 'typescript-fsa';
import type {
    LoadFeaturesParams,
    LoadFeaturesResult,
    LoadTextsParams,
    LoadTextsResult,
    UpdateTextTitleParams,
    UpdateTextTitleResult,
    UpdateTextContentParams,
    UpdateTextContentResult,
    SaveParams,
} from '../types';

const actionCreator = actionCreatorFactory('GENERATION_TEXT_FORM_ASYNC');

export const loadFeatures = actionCreator.async<LoadFeaturesParams, LoadFeaturesResult, Error>('LOAD_FEATURES');
export const loadTexts = actionCreator.async<LoadTextsParams, LoadTextsResult, Error>('LOAD_TEXTS');
export const updateTextTitle = actionCreator.async<UpdateTextTitleParams, UpdateTextTitleResult, Error>(
    'UPDATE_TEXT_TITLE',
);
export const updateTextContent = actionCreator.async<UpdateTextContentParams, UpdateTextContentResult, Error>(
    'UPDATE_TEXT_CONTENT',
);

export const save = actionCreator.async<SaveParams, void, Error>('SAVE');
