import * as React from 'react';

import { DashboardSortingMenu, Option } from './DashboardSortingMenu';

export interface Props {
    options?: Option[];
    selectedOptionId?: React.ReactText;
    onSelect?: (selectedOptionId: React.ReactText) => void;
}

export class DashboardSortingMenuContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { options, selectedOptionId, onSelect } = this.props;

        return <DashboardSortingMenu options={options} selectedOptionId={selectedOptionId} onSelect={onSelect} />;
    }
}
