import { Success } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { TaskPageSortBy } from 'sber-marketing-types/frontend';
import { cloneDeep, assignWith, intersection, pick } from 'lodash';

import { LoadingStatus } from '@store/commonTypes';

import {
    DashboardUserConfigState as State,
    DashboardUserConfig,
    TasksFilterIds,
    TasksFilter,
    ActivitiesFilterIds,
    ActivitiesFilter,
    NewsFilter,
    ActivitySortingMode,
    UpdateDashboardUserConfig,
} from './types';
import * as actions from './actions';

class Reducer {
    public static initialState(): State {
        return {
            loadingStatus: LoadingStatus.NOT_LOADED,
            fields: {
                myTasksFilters: Reducer.emptyTaskFilter(),
                availableActivitiesFilters: Reducer.emptyActivitiesFilter(),
                myActivitiesFilters: Reducer.emptyActivitiesFilter(),
                newsFilters: Reducer.emptyNewsFilter(),
            },
            rawFields: {
                myTasksFilters: Reducer.emptyTaskFilter(),
                availableActivitiesFilters: Reducer.emptyActivitiesFilter(),
                myActivitiesFilters: Reducer.emptyActivitiesFilter(),
                newsFilters: Reducer.emptyNewsFilter(),
            },
        };
    }

    public static resetRawFields(state: State): State {
        return {
            ...state,
            rawFields: cloneDeep(state.fields),
        };
    }

    public static setRawMyTasksFields(state: State, myTasksFilters: Partial<TasksFilter>): State {
        return {
            ...state,
            rawFields: {
                ...state.rawFields,
                myTasksFilters: {
                    ...state.rawFields.myTasksFilters,
                    ...myTasksFilters,
                },
            },
        };
    }

    public static setRawAvailableActivitiesFields(
        state: State,
        availableActivitiesFilters: Partial<ActivitiesFilter>,
    ): State {
        return {
            ...state,
            rawFields: {
                ...state.rawFields,
                availableActivitiesFilters: {
                    ...state.rawFields.availableActivitiesFilters,
                    ...availableActivitiesFilters,
                },
            },
        };
    }

    public static setRawMyActivitiesFields(state: State, myActivitiesFilters: Partial<ActivitiesFilter>): State {
        return {
            ...state,
            rawFields: {
                ...state.rawFields,
                myActivitiesFilters: {
                    ...state.rawFields.myActivitiesFilters,
                    ...myActivitiesFilters,
                },
            },
        };
    }

    public static emptyTaskFilter(): TasksFilter {
        return {
            status: null,
            workType: [],
            department: [],
            author: [],
            executor: [],
            activityStage: [],
            participation: null,
            sorting: TaskPageSortBy.UPDATING_DATE,
        };
    }

    public static emptyActivitiesFilter(): ActivitiesFilter {
        return {
            status: null,
            activityType: [],
            product: [],
            author: [],
            responsible: [],
            responsibleDepartment: [],
            sorting: ActivitySortingMode.UPDATING_DATE,
        };
    }

    public static emptyNewsFilter(): NewsFilter {
        return {
            type: [],
            activity: [],
            author: [],
            onlyUnseen: false,
        };
    }

    public static loadUserConfig(state: State, payload: Success<DashboardUserConfig, DashboardUserConfig>): State {
        const { fields } = state;
        const { result } = payload;

        return {
            ...state,
            loadingStatus: LoadingStatus.LOADED,
            fields: {
                myTasksFilters: {
                    ...fields.myTasksFilters,
                    ...result.myTasksFilters,
                },
                availableActivitiesFilters: {
                    ...fields.availableActivitiesFilters,
                    ...result.availableActivitiesFilters,
                },
                myActivitiesFilters: {
                    ...fields.myActivitiesFilters,
                    ...result.myActivitiesFilters,
                },
                newsFilters: {
                    ...fields.newsFilters,
                    ...result.newsFilters,
                },
            },
        };
    }

    public static updateUserConfig(state: State, payload: UpdateDashboardUserConfig): State {
        return {
            ...state,
            fields: {
                ...assignWith(cloneDeep(state.fields), payload.userConfig, (source, dest) => ({
                    ...source,
                    ...dest,
                })),
            },
        };
    }

    public static mergeMyTasksPageUserConfig(state: State, payload: Success<void, TasksFilterIds>): State {
        const {
            fields: { myTasksFilters = Reducer.emptyTaskFilter() },
        } = state;
        const { result } = payload;

        return {
            ...state,
            fields: {
                ...state.fields,
                myTasksFilters: {
                    ...state.fields.myTasksFilters,
                    ...myTasksFilters,
                    workType: intersection(myTasksFilters.workType, result.workType),
                    department: intersection(myTasksFilters.department, result.department),
                },
            },
        };
    }

    public static mergeAvailableActivitiesPageUserConfig(
        state: State,
        payload: Success<void, ActivitiesFilterIds>,
    ): State {
        const {
            fields: { availableActivitiesFilters = Reducer.emptyActivitiesFilter() },
        } = state;
        const { result } = payload;

        return {
            ...state,
            fields: {
                ...state.fields,
                availableActivitiesFilters: {
                    ...state.fields.availableActivitiesFilters,
                    ...availableActivitiesFilters,
                    activityType: intersection(availableActivitiesFilters.activityType, result.activityType),
                    product: intersection(availableActivitiesFilters.product, result.product),
                },
            },
        };
    }

    public static mergeMyActivitiesPageUserConfig(state: State, payload: Success<void, ActivitiesFilterIds>): State {
        const {
            fields: { myActivitiesFilters = Reducer.emptyActivitiesFilter() },
        } = state;
        const { result } = payload;

        return {
            ...state,
            fields: {
                ...state.fields,
                myActivitiesFilters: {
                    ...state.fields.myActivitiesFilters,
                    ...myActivitiesFilters,
                    activityType: intersection(myActivitiesFilters.activityType, result.activityType),
                    product: intersection(myActivitiesFilters.product, myActivitiesFilters.product),
                },
            },
        };
    }
    public static resetMyTasksFilters(state: State): State {
        return {
            ...state,
            fields: {
                ...state.fields,
                myTasksFilters: {
                    ...state.fields.myTasksFilters,
                    ...pick(
                        Reducer.emptyTaskFilter(),
                        'status',
                        'author',
                        'workType',
                        'executor',
                        'department',
                        'participation',
                        'activityStage',
                    ),
                },
            },
        };
    }
    public static resetAvailableActivitiesFilters(state: State): State {
        return {
            ...state,
            fields: {
                ...state.fields,
                availableActivitiesFilters: {
                    ...state.fields.availableActivitiesFilters,
                    ...pick(
                        Reducer.emptyActivitiesFilter(),
                        'author',
                        'responsible',
                        'status',
                        'activityType',
                        'product',
                        'responsibleDepartment',
                    ),
                },
            },
        };
    }
    public static resetMyActivitiesFilters(state: State): State {
        return {
            ...state,
            fields: {
                ...state.fields,
                myActivitiesFilters: {
                    ...state.fields.myActivitiesFilters,
                    ...pick(
                        Reducer.emptyActivitiesFilter(),
                        'author',
                        'responsible',
                        'status',
                        'activityType',
                        'product',
                        'responsibleDepartment',
                    ),
                },
            },
        };
    }
}

export const dashboardUserConfigReducer = reducerWithInitialState(Reducer.initialState())
    .case(actions.loadDashboardUserConfig.done, Reducer.loadUserConfig)
    .case(actions.updateDashboardUserConfig.started, Reducer.updateUserConfig)
    .case(actions.mergeMyTasksPageUserConfig.done, Reducer.mergeMyTasksPageUserConfig)
    .case(actions.mergeAvailableActivitiesPageUserConfig.done, Reducer.mergeAvailableActivitiesPageUserConfig)
    .case(actions.mergeMyActivitiesPageUserConfig.done, Reducer.mergeMyActivitiesPageUserConfig)
    .case(actions.resetMyTasksFilters, Reducer.resetMyTasksFilters)
    .case(actions.resetAvailableActivitiesFilters, Reducer.resetAvailableActivitiesFilters)
    .case(actions.resetMyActivitiesFilters, Reducer.resetMyActivitiesFilters)
    .case(actions.resetRawFilters, Reducer.resetRawFields)
    .case(actions.setRawMyTasksFilter, Reducer.setRawMyTasksFields)
    .case(actions.setRawAvailableActivitiesFilter, Reducer.setRawAvailableActivitiesFields)
    .case(actions.setRawMyActivitiesFilter, Reducer.setRawMyActivitiesFields)
    .case(actions.resetDashboardUserConfig, Reducer.initialState);
