// tslint:disable:cyclomatic-complexity
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import classNames from 'classnames';

import { ColumnName } from '@store/budgetPlanning/types';

import * as style from './Table.scss';

import { CustomScrollbar_new, ScrollbarComponent } from 'sber-marketing-ui';
import { Tooltip, TooltipDirection } from './Tooltip';
import { ApproversMenu, Direction } from './ApproversMenu';
import { RejectMenu, RejectMenuDirection } from './RejectMenu';
import { TableHeader } from './TableHeader';
import { TableBody } from './TableBody';
import { SumLine } from './SumLine';
import { FrameManager } from './FrameManager';
import { LineCellsParams } from './LayerManager';

const HEADER_HEIGHT = 28;
const LINE_HEIGHT = 38;

export interface TooltipPosition {
    direction: TooltipDirection;
    y: number;
}

export interface ApproversMenuPosition {
    direction?: Direction;
    y: number;
}

export interface RejectMenuPosition {
    direction?: RejectMenuDirection;
    y: number;
}

export interface DropdownPosition {
    width: number;
    height: number;
    y: number;
    x: number;
    direction?: TooltipDirection;
}

interface TableProps {
    showNoLinesStub: boolean;
    frameManager: FrameManager;
    cellsParams: { [lineId: string]: LineCellsParams };
    currentFrameIndex: number;
    columnsWidth: { [columnName: string]: number };
    visibleColumnsNames: ColumnName[];
    hoveredColumnName: ColumnName;
    hoveredLineId: string;
    hoveredTotalLineActivityId: string;
    approversMenuLineId: string;
    rejectMenuLineId: string;
    draggedEdgeColumnName: ColumnName;
    currentDropdownContent: JSX.Element;
    dropdownContentX: number;
    tooltipPosition: TooltipPosition;
    rootWidth: number;
    maxBodyHeight: number;
    dropdownsContentWrapper: HTMLDivElement;
    dropdownCellContentWrapper: HTMLDivElement;
    dropdownCellContent: JSX.Element;
    dropdownCellPosition: DropdownPosition;
    approversMenuPosition: ApproversMenuPosition;
    rejectMenuPosition: RejectMenuPosition;
    isResizingColumn: boolean;
    rootRef: (element: HTMLDivElement) => void;
    headerRef: (component: ScrollbarComponent) => void;
    bodyRef: (component: ScrollbarComponent) => void;
    sumLineRef: (component: ScrollbarComponent) => void;
    headerFixedColumnsRef: (element: HTMLDivElement) => void;
    fixedColumnsLinesRef: (element: HTMLDivElement) => void;
    totalLinesContentRef: (element: HTMLDivElement) => void;
    dropdownsContentRef: (element: HTMLDivElement) => void;
    dropdownCellContentRef: (element: HTMLDivElement) => void;
    onBodyScroll: () => void;
    onHeaderCellMouseEnter: (columnName: ColumnName) => void;
    onHeaderCellMouseLeave: () => void;
    onLineMouseEnter: (id: string) => void;
    onLineMouseLeave: () => void;
    onColumnEdgeMousedown: (columnName: ColumnName, mouseDownX: number) => void;
    onHeaderDropdownClick: (columnName: ColumnName, dropdownContent: JSX.Element) => void;
    onInfoMouseEnter: (lineId: string) => void;
    onInfoMouseLeave: () => void;
    onDropdownCellClick: (
        columnName: ColumnName,
        budgetItemId: string,
        dropdownContent: JSX.Element,
        contentHeight: number,
    ) => void;
    onApplyFiltersButtonClick: () => void;
}

export const Table = ({
    showNoLinesStub,
    frameManager,
    cellsParams,
    currentFrameIndex,
    columnsWidth,
    visibleColumnsNames,
    hoveredColumnName,
    hoveredLineId,
    hoveredTotalLineActivityId,
    approversMenuLineId,
    rejectMenuLineId,
    draggedEdgeColumnName,
    currentDropdownContent,
    dropdownContentX,
    tooltipPosition,
    rootWidth,
    maxBodyHeight,
    dropdownsContentWrapper,
    dropdownCellContentWrapper,
    dropdownCellContent,
    dropdownCellPosition,
    approversMenuPosition,
    rejectMenuPosition,
    isResizingColumn,
    rootRef,
    headerRef,
    bodyRef,
    headerFixedColumnsRef,
    fixedColumnsLinesRef,
    totalLinesContentRef,
    dropdownsContentRef,
    dropdownCellContentRef,
    sumLineRef,
    onBodyScroll,
    onHeaderCellMouseEnter,
    onHeaderCellMouseLeave,
    onLineMouseEnter,
    onLineMouseLeave,
    onColumnEdgeMousedown,
    onHeaderDropdownClick,
    onInfoMouseEnter,
    onInfoMouseLeave,
    onDropdownCellClick,
    onApplyFiltersButtonClick,
}: TableProps): JSX.Element => {
    return (
        <div className={style.root} ref={rootRef}>
            <div className={style.tableHeader}>
                <CustomScrollbar_new
                    fixedHeight={HEADER_HEIGHT}
                    scrollbarRef={headerRef}
                    freezeScrollX
                    hideScrollX
                    hideScrollY
                >
                    <TableHeader
                        columnsWidth={columnsWidth}
                        hoveredColumnName={hoveredColumnName}
                        draggedEdgeColumnName={draggedEdgeColumnName}
                        isResizingColumn={isResizingColumn}
                        headerFixedColumnsRef={headerFixedColumnsRef}
                        onCellMouseEnter={onHeaderCellMouseEnter}
                        onCellMouseLeave={onHeaderCellMouseLeave}
                        onDropdownClick={onHeaderDropdownClick}
                        onColumnEdgeMousedown={onColumnEdgeMousedown}
                        onApplyFiltersButtonClick={onApplyFiltersButtonClick}
                    />
                </CustomScrollbar_new>

                <div
                    className={style.dropdownsContent}
                    style={{ left: `${dropdownContentX}px` }}
                    ref={dropdownsContentRef}
                >
                    {dropdownsContentWrapper && ReactDOM.createPortal(currentDropdownContent, dropdownsContentWrapper)}
                </div>
            </div>

            <div className={style.tableBody}>
                <CustomScrollbar_new maxHeight={maxBodyHeight} scrollbarRef={bodyRef} onScroll={onBodyScroll}>
                    {showNoLinesStub && <NoLinesStub />}

                    <TableBody
                        currentFrameIndex={currentFrameIndex}
                        frameManager={frameManager}
                        cellsParams={cellsParams}
                        rootWidth={rootWidth}
                        columnsWidth={columnsWidth}
                        visibleColumnsNames={visibleColumnsNames}
                        hoveredLineId={hoveredLineId}
                        hoveredColumnName={hoveredColumnName}
                        draggedEdgeColumnName={draggedEdgeColumnName}
                        fixedColumnsLinesRef={fixedColumnsLinesRef}
                        totalLinesContentRef={totalLinesContentRef}
                        onLineMouseEnter={onLineMouseEnter}
                        onLineMouseLeave={onLineMouseLeave}
                        onInfoMouseEnter={onInfoMouseEnter}
                        onInfoMouseLeave={onInfoMouseLeave}
                        onDropdownCellClick={onDropdownCellClick}
                    />
                </CustomScrollbar_new>
            </div>

            <div className={style.sumLine}>
                <SumLine scrollbarRef={sumLineRef} />
            </div>

            {tooltipPosition && (
                <div
                    className={style.tooltipWrapper}
                    style={{
                        top:
                            tooltipPosition.direction == TooltipDirection.Up
                                ? tooltipPosition.y
                                : tooltipPosition.y + LINE_HEIGHT,
                    }}
                >
                    <Tooltip lineId={hoveredTotalLineActivityId} direction={tooltipPosition.direction} />
                </div>
            )}

            {approversMenuLineId && approversMenuPosition && (
                <div
                    className={classNames(
                        style.approversMenu,
                        approversMenuPosition.direction == Direction.Up && style.directedUp,
                    )}
                    style={{
                        top:
                            approversMenuPosition.direction == Direction.Up
                                ? approversMenuPosition.y
                                : approversMenuPosition.y + LINE_HEIGHT,
                    }}
                >
                    <ApproversMenu lineId={approversMenuLineId} direction={approversMenuPosition.direction} />
                </div>
            )}

            {rejectMenuLineId && rejectMenuPosition && (
                <div
                    className={classNames(
                        style.rejectMenu,
                        rejectMenuPosition.direction == RejectMenuDirection.Up && style.directedUp,
                    )}
                    style={{
                        top:
                            rejectMenuPosition.direction == RejectMenuDirection.Up
                                ? rejectMenuPosition.y
                                : rejectMenuPosition.y + LINE_HEIGHT,
                    }}
                >
                    <RejectMenu lineId={rejectMenuLineId} direction={rejectMenuPosition.direction} />
                </div>
            )}

            <div
                className={style.dropdownCellWrapper}
                style={
                    dropdownCellPosition && {
                        left: dropdownCellPosition.x,
                        top: dropdownCellPosition.y,
                        width: dropdownCellPosition.width,
                        height: dropdownCellPosition.height,
                    }
                }
                ref={dropdownCellContentRef}
            >
                {dropdownCellContentWrapper &&
                    ReactDOM.createPortal(
                        dropdownCellContent &&
                            React.cloneElement(dropdownCellContent, {
                                direction: dropdownCellPosition
                                    ? dropdownCellPosition.direction
                                    : TooltipDirection.Down,
                            }),
                        dropdownCellContentWrapper,
                    )}
            </div>
        </div>
    );
};

function NoLinesStub(): JSX.Element {
    return <div className={style.noLinesStub}>Пожалуйста, выберите фильтры и нажмите кнопку "Применить фильтры"</div>;
}
