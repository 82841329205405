import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CorrectionType } from '@mrm/budget';
import {
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    AnimatedEllipsis,
    CloseButton,
    WithTooltip,
} from 'sber-marketing-ui';

import { StoreState } from '@store';
import {
    CorrectionTypeIds,
    TargetFilter,
    getBudgetCorrectionsFiltersAndExportState,
    setIsExportModeEnabled,
    ExportInProgressState,
    setFilterValue,
    moveSavedFiltersToCurrent,
    downloadCorrections,
} from '@store/budgetCorrections/filtersAndExport';

import * as styles from './ExportCorrectionsButton.scss';

interface Props {
    allCorrectionsIds: string[];
    applyFilters: () => void;
}

export function ExportCorrectionsButton(props: Props): JSX.Element {
    const useExportMode = useSelector(
        (state: StoreState) => getBudgetCorrectionsFiltersAndExportState(state).currentFilters.isExportModeEnabled,
    );

    return useExportMode ? <ExportMode {...props} /> : <NonExportMode applyFilters={props.applyFilters} />;
}

type NonExportModeProps = Pick<Props, 'applyFilters'>;

function NonExportMode({ applyFilters }: NonExportModeProps): JSX.Element {
    const dispatch = useDispatch();
    function onClick() {
        dispatch(setIsExportModeEnabled(true));
        dispatch(
            setFilterValue({
                filter: TargetFilter.Types,
                value: [CorrectionTypeIds[CorrectionType.PlanFundsTransfer]],
            }),
        );
        applyFilters();
    }

    return (
        <div className={styles.root}>
            <Button
                qaId="budgetCorrectionsSaveCorrectionsButton"
                theme={ButtonTheme.GhostRoundedUpdated}
                onClick={onClick}
            >
                <div className={styles.button}>Выгрузить корректировки</div>
            </Button>
        </div>
    );
}

function useExportModeInteractivity(props: Props) {
    const dispatch = useDispatch();
    const { allCorrectionsIds, applyFilters } = props;

    const exportInProgressState = useSelector(
        (state: StoreState) => getBudgetCorrectionsFiltersAndExportState(state).exportInProgressState,
    );

    function onCloseButtonClick() {
        dispatch(moveSavedFiltersToCurrent());
        applyFilters();
    }

    function exportWithFilters() {
        dispatch(
            downloadCorrections({
                useFilters: true,
                allCorrectionsIds,
            }),
        );
    }
    function exportWithoutFilters() {
        dispatch(downloadCorrections({ useFilters: false }));
    }

    return {
        exportInProgressState,
        onCloseButtonClick,
        exportWithFilters,
        exportWithoutFilters,
    };
}

function ExportMode(props: Props): JSX.Element {
    const { exportInProgressState, onCloseButtonClick, exportWithFilters, exportWithoutFilters } =
        useExportModeInteractivity(props);

    const disableExportWithFiltersButton =
        exportInProgressState !== ExportInProgressState.None || !props.allCorrectionsIds.length;
    const disableExportWithoutFiltersButton = exportInProgressState !== ExportInProgressState.None;

    const animatedExportLabel = <AnimatedEllipsis text="Скачивается" />;
    const exportWithFiltersButtonTitle =
        exportInProgressState === ExportInProgressState.WithFilters ? animatedExportLabel : 'Скачать с учетом фильтров';
    const exportWithoutFiltersButtonTitle =
        exportInProgressState === ExportInProgressState.WithoutFilters ? animatedExportLabel : 'Скачать все доступные';

    return (
        <div className={styles.exportMode}>
            <div className={styles.exportModeTitle}>
                Выгрузить корректировки
                <CloseButton onClick={onCloseButtonClick} />
            </div>

            <div className={styles.exportModeButtonMargin}>
                <Button
                    disabled={disableExportWithFiltersButton}
                    theme={ButtonTheme.GhostRoundedBlack}
                    onClick={exportWithFilters}
                >
                    <div className={styles.exportModeButton}>{exportWithFiltersButtonTitle}</div>
                </Button>
            </div>

            <WithTooltip content="Будут выгружены все согласованные корректировки плана">
                <Button
                    disabled={disableExportWithoutFiltersButton}
                    theme={ButtonTheme.GhostRounded}
                    onClick={exportWithoutFilters}
                >
                    <div className={styles.exportModeButton}>{exportWithoutFiltersButtonTitle}</div>
                </Button>
            </WithTooltip>
        </div>
    );
}
