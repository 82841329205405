// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-ActivitySidebar-tabs-BudgetsTab-BudgetItemLinkCard-WithTitle-_WithTitle__root{color:black;font-size:12px;line-height:16px}.src-client-modules-common-ActivitySidebar-tabs-BudgetsTab-BudgetItemLinkCard-WithTitle-_WithTitle__title{color:#7e8681}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/ActivitySidebar/tabs/BudgetsTab/BudgetItemLinkCard/WithTitle/WithTitle.scss"],"names":[],"mappings":"AAAA,yGACI,WAAY,CAEZ,cAAe,CACf,gBAAiB,CACpB,0GAGG,aAAc","sourcesContent":[".root {\n    color: black;\n\n    font-size: 12px;\n    line-height: 16px;\n}\n\n.title {\n    color: #7e8681;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-ActivitySidebar-tabs-BudgetsTab-BudgetItemLinkCard-WithTitle-_WithTitle__root",
	"title": "src-client-modules-common-ActivitySidebar-tabs-BudgetsTab-BudgetItemLinkCard-WithTitle-_WithTitle__title"
};
export default ___CSS_LOADER_EXPORT___;
