import { BudgetItemSnapshot } from '@mrm/budget';

export interface Difference {
    title: string;
    before: string;
    beforeTitle?: string;
    after: string;
    afterTitle?: string;
}

export const DifferencesTitles: {
    [key: string]: string;
} = {
    responsibles: 'Руководители проекта',
    activity: 'Проект',
    comment: 'Примечания',
    sapComment: 'Название проекта (Комментарий для SAP)',
    realizationStart: 'Дата старта',
    realizationEnd: 'Дата окончания',
    businessTarget: 'Бизнес-цель',
    customerName: 'ФИО Заказчика',
    plannedFunds: 'План',
};

export type DifferenceTransformer = (prev: BudgetItemSnapshot, curr: BudgetItemSnapshot, item: string) => Difference[];
