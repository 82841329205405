import { Success, Failure } from 'typescript-fsa';

import { TaskPageState, AddFileToCommentaryPayload, FetchError } from '../types';
import { FileAsset } from '../../commonTypes';

export const addFileToCommentaryDoneReducer = (
    { comments, ...restState }: TaskPageState,
    { params: { commentaryId }, result: asset }: Success<AddFileToCommentaryPayload, FileAsset>,
): TaskPageState => {
    const { name } = asset;

    comments[commentaryId].assetsIds = comments[commentaryId].assetsIds.concat([name]);
    return {
        ...restState,
        comments,
    };
};

export const addFileToCommentaryErrorReducer = (
    state: TaskPageState,
    payload: Failure<AddFileToCommentaryPayload, FetchError>,
): TaskPageState => {
    return state;
};

export const addFileToCommentaryStartedReducer = (
    state: TaskPageState,
    payload: AddFileToCommentaryPayload,
): TaskPageState => {
    return state;
};
