// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-brief-Brief-Header-TaskDates-_TaskDates__root{display:flex;margin-top:20px}.src-client-modules-brief-Brief-Header-TaskDates-_TaskDates__date{width:calc(50% - 15px);height:45px;margin-right:32px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/brief/Brief/Header/TaskDates/TaskDates.scss"],"names":[],"mappings":"AAAA,kEACI,YAAa,CAEb,eAAgB,CACnB,kEAGG,sBAAuB,CACvB,WAAY,CACZ,iBAAkB","sourcesContent":[".root {\n    display: flex;\n\n    margin-top: 20px;\n}\n\n.date {\n    width: calc(50% - 15px);\n    height: 45px;\n    margin-right: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-brief-Brief-Header-TaskDates-_TaskDates__root",
	"date": "src-client-modules-brief-Brief-Header-TaskDates-_TaskDates__date"
};
export default ___CSS_LOADER_EXPORT___;
