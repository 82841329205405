import { get } from 'lodash';

import { DictionaryNames } from '@store/budgetPlanning';

import { DifferenceTransformer } from './types';

export const dictionaryDifference: DifferenceTransformer = function (prev, curr, item) {
    const mergedDictionaries = {
        ...(prev[item] || {}),
        ...(curr[item] || {}),
    };

    const diffs = Object.keys(mergedDictionaries).filter(
        (key) => get(prev, `dictionary.${key}.value` || '') !== get(curr, `dictionary.${key}.value` || ''),
    );

    return diffs.map((diff) => ({
        title: DictionaryNames[diff],
        before: get(prev, `[${item}][${diff}].value`) || null,
        after: get(curr, `[${item}][${diff}].value`) || null,
    }));
};
