import * as React from 'react';

import { CorrectionStatus } from '@mrm/budget';

import { Menu } from './Menu';
import { WithPopup } from '../WithPopup';

interface Props {
    status: CorrectionStatus;
    correctionId: string;
    correctionAuthorId: number;
    onChange: () => void;
}

export class MenuContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { status, correctionId, correctionAuthorId, onChange } = this.props;
        return (
            <WithPopup
                correctionId={correctionId}
                correctionAuthorId={correctionAuthorId}
                onFinishUpdateCorrectionStatus={onChange}
            >
                {({ userIsBudgetExpert, userIsAuthorCorrection, onSetNewCorrectionStatus }) => (
                    <Menu
                        status={status}
                        acceptButtonIsLocked={false}
                        userIsBudgetExpert={userIsBudgetExpert}
                        userIsAuthorCorrection={userIsAuthorCorrection}
                        onAcceptButtonClick={() => {
                            onSetNewCorrectionStatus(CorrectionStatus.Approved);
                        }}
                        onRejectButtonClick={() => {
                            onSetNewCorrectionStatus(CorrectionStatus.Rejected);
                        }}
                    />
                )}
            </WithPopup>
        );
    }
}
