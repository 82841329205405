import { ActivityParams, BriefScheme } from 'sber-marketing-types/frontend';
import { OneTaskResponseParams } from 'sber-marketing-types/backend';
import { BudgetItem } from '@mrm/budget';
import { Dictionary } from 'lodash';

import { TagProject, TagTask, TagExecutionBudgetItem, TagPlanBudgetItem } from '@api';

import { LoadingStatus } from '@store/commonTypes';

import { TagGroup } from './enums';

export interface TagsPageState {
    loadingStatus: TagsPageLoadingStatus;
    years: number[];
    filters: TagsPageFilters;
    selectedTagData: SelectedTagData;
    selectedNode: SelectedNode;
    selectedNodeData: SelectedNodeData;
}

export interface TagsPageLoadingStatus {
    common: LoadingStatus;
    selectedTagData: LoadingStatus;
    selectedNodeData: LoadingStatus;
}

export interface TagsPageFilters {
    tag: string;
    years: number[];
    groups: TagGroup[];
}

export interface SelectedTagData {
    projects: Dictionary<TagProject>;
    tasks: Dictionary<TagTask>;
    executionBudgetItems: Dictionary<TagExecutionBudgetItem>;
    planBudgetItems: Dictionary<TagPlanBudgetItem>;
}

export const TagGroupColors: Record<TagGroup, string> = {
    [TagGroup.Tag]: '#000000',
    [TagGroup.Project]: '#0061BB',
    [TagGroup.Task]: '#94C2ED',
    [TagGroup.ExecutionId]: '#39A651',
    [TagGroup.PlanId]: '#94DCB5',
};

export interface SelectedNode {
    group: TagGroup;
    id: string;
}

export interface SelectedNodeData {
    project?: SelectedTagActivityData;
    task?: SelectedTagTaskData;
    executionBudgetItem?: BudgetItem;
    planBudgetItem?: BudgetItem;
}

export interface SelectedTagActivityData {
    activity: ActivityParams;
    briefScheme?: BriefScheme;
}

export interface SelectedTagTaskData {
    task: OneTaskResponseParams;
    activity: ActivityParams;
}

export interface NodeData {
    group: TagGroup;
    size: number;
    label: string | number;
}
