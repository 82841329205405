import * as React from 'react';
import classNames from 'classnames';
import * as moment from 'moment';

import * as styles from './ChannelInfo.scss';
import { ChannelInfoTemplateProps } from './types';
import { Utils } from '../../../../common/Utils';
import { ChannelEditor } from '../ChannelEditor';

const CREATOR_LABEL = 'Создатель';

const PARTICIPANT_PLURAL_FORMS: string[] = ['участник', 'участника', 'участников'];

/** "ChannelInfo" template component */
export const ChannelInfoTemplate: React.SFC<ChannelInfoTemplateProps> = ({
    className,
    channelId,
    authorFirstName,
    authorSecondName,
    authorMiddleName,
    authorDepartmentName,
    participantsCount,
    isEditorModalOpened,
    isUserAuthor,
    onEditCurrentClose,
    onEditCurrentOpen,
    createTime,
}: ChannelInfoTemplateProps): JSX.Element => {
    let authorInfo = `${CREATOR_LABEL}: ${authorSecondName} ${authorFirstName}`;
    if (authorMiddleName) {
        authorInfo = `${authorInfo} ${authorMiddleName}`;
    }
    authorInfo = `${authorInfo}, ${authorDepartmentName}`;

    const participantsCountLabel = `${participantsCount} ${Utils.getDeclensionByNumber(
        participantsCount,
        PARTICIPANT_PLURAL_FORMS,
    )}`;

    const rootCssClass = classNames(styles.root, className);
    const textInfoCssClass = classNames(styles.textLine, styles.textLine_info);
    const participantsCssClass = classNames(styles.textLine, styles.textLine_participants);

    return (
        <div className={rootCssClass}>
            <div className={textInfoCssClass}>Канал создан: {moment(createTime).format('DD.MM.YYYY')}</div>

            <div className={textInfoCssClass}>{authorInfo}</div>

            <div className={textInfoCssClass}>{participantsCountLabel}</div>

            <div
                className={participantsCssClass}
                onClick={onEditCurrentOpen}
                {...{
                    'qa-id': 'taskChannelEditButton',
                }}
            >
                {isUserAuthor ? 'Редактировать' : 'Добавить участников'}
            </div>

            {isEditorModalOpened && (
                <ChannelEditor
                    id={channelId}
                    isUserAuthor={isUserAuthor}
                    isNew={false}
                    onSubmit={onEditCurrentClose}
                    onCancel={onEditCurrentClose}
                />
            )}
        </div>
    );
};

ChannelInfoTemplate.displayName = 'frontend::task-board::ChannelInfo::Template';
