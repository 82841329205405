import { createSelector } from 'reselect';
import { Tag } from '@mrm/tags';

import { StoreState } from '@store';
import { getTagsState } from '@store/tags';

import { TagsPageState as State } from '../types';

interface ListFilterItem {
    id: string | number;
    title: string | number;
}

export const getTagsPageState = (state: StoreState): State => state.tagsPage;

export const getTagsFilterAsItems = createSelector(
    (state: StoreState) => getTagsState(state).entities,
    (tags: Tag[]): ListFilterItem[] => {
        return tags.map((tag) => ({
            id: tag.id,
            title: tag.title,
        }));
    },
);

export const getYearsFilterAsItems = createSelector(
    (state: StoreState): number[] => state.tagsPage.filters.years,
    (years: number[]): ListFilterItem[] =>
        years.map((year) => ({
            id: year,
            title: year,
        })),
);

export const getYearsAsItems = createSelector(
    (state: StoreState): number[] => state.tagsPage.years,
    (years: number[]): ListFilterItem[] =>
        years.map((year) => ({
            id: year,
            title: year,
        })),
);
