import autobind from 'autobind-decorator';

import { BudgetItemStatus } from '@mrm/budget';
import { TableLine, ColumnData, ColumnName, ChangeList, CellValueType } from '@store/budgetPlanning/types';
import { CellParams, CellBackgroundColor, ActualValue } from '../LayerManager';

import { getFieldValue } from './Utils';
import { MoneyFormatter, Money } from '@common/Utils';
import { ColumnsList } from '../../../ColumnsConfig';

interface Props {
    line: TableLine;
    column: ColumnData;
    unsavedChanges: ChangeList;
}

export class TextCellParamsCreator {
    private line: TableLine;
    private column: ColumnData;
    private unsavedChanges: ChangeList;

    constructor(props: Props) {
        this.line = props.line;
        this.column = props.column;
        this.unsavedChanges = props.unsavedChanges;
    }

    public makeCellParams(): CellParams {
        let value: string;
        let tooltip = '';
        let color: CellBackgroundColor;

        if (this.column.metaData && this.column.metaData.calculateActualValue) {
            const actualValue: ActualValue = this.column.metaData.calculateActualValue(this.getValueByColumnName);

            value = actualValue.value;
            color = actualValue.color;
        } else {
            value = this.getBaseValue(this.column);
        }

        if (this.column.valueType == CellValueType.Currency) {
            const money = Money.fromStringCopecks(value);

            value = MoneyFormatter.toThousands(money, { hideCaption: true });
            tooltip = MoneyFormatter.toRoubles(money);
        }

        const lineIsDraft = this.line.status == BudgetItemStatus.Draft;
        const lineIsDisabled = !this.line.canEdit || this.line.status == BudgetItemStatus.Disabled;

        return {
            title: value,
            tooltip: tooltip || value,
            bgColor: !lineIsDraft && color,
            disabled: lineIsDisabled,
        };
    }

    private getUnsavedValue(column: ColumnData): string {
        const changes = this.unsavedChanges[this.line.id] || [];

        const unsavedChange = changes.find(
            (item) => item.budgetItemId == this.line.id && item.columnName == column.name,
        );

        return unsavedChange ? (unsavedChange.value as string) : undefined;
    }

    private getBaseValue(column: ColumnData): string {
        return getFieldValue(this.line.fields[column.name], column.valueType) as string;
    }

    @autobind
    private getValueByColumnName(columnName: ColumnName): ActualValue {
        const column = ColumnsList.find((item) => item.name == columnName);

        let value: string;
        let color: CellBackgroundColor;

        const unsavedValue = this.getUnsavedValue(column);

        if (unsavedValue !== undefined) {
            value = unsavedValue;
            color = CellBackgroundColor.UnsavedChange;

            if (this.column.valueType === CellValueType.Currency) {
                value = value && String(parseFloat(value) * 100.0);
            }
        } else {
            const baseValue = this.getBaseValue(column);

            value = baseValue;
            color = null;
        }

        return {
            value,
            color,
        };
    }
}
