import * as React from 'react';

import * as style from './CheckboxCell.scss';

import { Icon, IconType } from 'sber-marketing-ui';

interface Props {
    checked: boolean;
    customStyle?: any;
    onClick: () => void;
}

export const CheckboxCell = ({ checked, customStyle, onClick }: Props): JSX.Element => {
    return (
        <div className={style.root} style={{ ...customStyle }} onClick={onClick}>
            <div className={style.iconWrapper}>
                <Icon
                    className={style.icon}
                    type={checked ? IconType.CHECKBOX24_CHECKED : IconType.CHECKBOX24_UNCHECKED}
                    svgSize={20}
                />
            </div>
        </div>
    );
};
