// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-dashboard-RightSidebar-BudgetInfo-_BudgetInfo__root{padding:12px 24px 16px;border-bottom:1.3px solid #e3e9e5}.src-client-modules-dashboard-RightSidebar-BudgetInfo-_BudgetInfo__title{margin-bottom:10px;color:#000000;font-family:'Open Sans';font-size:13px;font-weight:600;line-height:18px}.src-client-modules-dashboard-RightSidebar-BudgetInfo-_BudgetInfo__correctionsWrapper{margin-top:14px}.src-client-modules-dashboard-RightSidebar-BudgetInfo-_BudgetInfo__preloader{position:relative;height:511px}.src-client-modules-dashboard-RightSidebar-BudgetInfo-_BudgetInfo__preloaderCorrection{position:absolute;width:100%;height:53px;border-radius:4px;background:#fefefe;box-shadow:0px 1px 4px rgba(0,0,0,0.1)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/dashboard/RightSidebar/BudgetInfo/BudgetInfo.scss"],"names":[],"mappings":"AAAA,wEACI,sBAAuB,CAEvB,iCAAkC,CACrC,yEAGG,kBAAmB,CAEnB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACpB,sFAGG,eAAgB,CACnB,6EAGG,iBAAkB,CAElB,YAAa,CAChB,uFAGG,iBAAkB,CAElB,UAAW,CACX,WAAY,CAEZ,iBAAkB,CAClB,kBAAmB,CACnB,sCAA0C","sourcesContent":[".root {\n    padding: 12px 24px 16px;\n\n    border-bottom: 1.3px solid #e3e9e5;\n}\n\n.title {\n    margin-bottom: 10px;\n\n    color: #000000;\n\n    font-family: 'Open Sans';\n    font-size: 13px;\n    font-weight: 600;\n    line-height: 18px;\n}\n\n.correctionsWrapper {\n    margin-top: 14px;\n}\n\n.preloader {\n    position: relative;\n\n    height: 511px;\n}\n\n.preloaderCorrection {\n    position: absolute;\n\n    width: 100%;\n    height: 53px;\n\n    border-radius: 4px;\n    background: #fefefe;\n    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-dashboard-RightSidebar-BudgetInfo-_BudgetInfo__root",
	"title": "src-client-modules-dashboard-RightSidebar-BudgetInfo-_BudgetInfo__title",
	"correctionsWrapper": "src-client-modules-dashboard-RightSidebar-BudgetInfo-_BudgetInfo__correctionsWrapper",
	"preloader": "src-client-modules-dashboard-RightSidebar-BudgetInfo-_BudgetInfo__preloader",
	"preloaderCorrection": "src-client-modules-dashboard-RightSidebar-BudgetInfo-_BudgetInfo__preloaderCorrection"
};
export default ___CSS_LOADER_EXPORT___;
