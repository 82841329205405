import * as React from 'react';
import * as style from './CorrectionBody.scss';

import { CorrectionStatus, CorrectionType } from '@mrm/budget';

export interface Props {
    serialNumber: number;
    type: CorrectionType;
    status: CorrectionStatus;
}

const STATUS_COLOR = {
    [CorrectionStatus.NeedApproving]: '#f5a623',
    [CorrectionStatus.Approved]: '#19bb4f',
    [CorrectionStatus.Rejected]: '#e63901',
};

export const CorrectionBody: React.FC<Props> = ({ children, serialNumber, status, type }) => {
    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'correctionCard',
                'qa-type': type,
                'qa-serial-number': serialNumber,
                'qa-status': status,
            }}
        >
            {children}
            <div className={style.statusIndicator} style={{ backgroundColor: STATUS_COLOR[status] }} />
        </div>
    );
};
