// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-FileAssetListWithGallery-_FileAssetListWithGallery__root{display:flex;align-items:center;justify-content:flex-start;flex-wrap:wrap;align-content:flex-start}.src-client-modules-common-FileAssetListWithGallery-_FileAssetListWithGallery__asset{margin-top:31px;margin-right:31px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/FileAssetListWithGallery/FileAssetListWithGallery.scss"],"names":[],"mappings":"AAAA,oFACI,YAAa,CAEb,kBAAmB,CACnB,0BAA2B,CAC3B,cAAe,CAEf,wBAAyB,CAC5B,qFAGG,eAAgB,CAChB,iBAAkB","sourcesContent":[".root {\n    display: flex;\n\n    align-items: center;\n    justify-content: flex-start;\n    flex-wrap: wrap;\n\n    align-content: flex-start;\n}\n\n.asset {\n    margin-top: 31px;\n    margin-right: 31px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-FileAssetListWithGallery-_FileAssetListWithGallery__root",
	"asset": "src-client-modules-common-FileAssetListWithGallery-_FileAssetListWithGallery__asset"
};
export default ___CSS_LOADER_EXPORT___;
