import { compact, uniq, uniqBy, keyBy, mapValues, isNil, flatMap } from 'lodash';

import { DepartmentResponse, Filters, UserResponse, StatisticsItem, Mode } from '../../types';

export const getDepartmentsIdsFromUsers = (users: UserResponse[]): string[] => {
    return compact(uniq(users.map(({ departmentId }) => departmentId)));
};

export const filterDepartmentResponseByIds = (
    departments: DepartmentResponse[],
    ids: string[],
): DepartmentResponse[] => {
    return uniqBy(flatMap(ids.map((departmentId) => getParentsDepartments(departments, departmentId))), 'id');
};

const getParentsDepartments = (departments: DepartmentResponse[], departmentId: string): DepartmentResponse[] => {
    const targetDepartment = departments.find(({ id }) => id === departmentId);

    if (targetDepartment.parentDepartmentId) {
        return [targetDepartment, ...getParentsDepartments(departments, targetDepartment.parentDepartmentId)];
    }

    return [targetDepartment];
};

export const convertStatisticsItemsToFilters = (statisticsItems: StatisticsItem<Mode>[]): Filters => {
    return mapValues(
        keyBy(
            statisticsItems.map(({ id }) => id),
            (department) => department,
        ),
        () => true,
    );
};

export const convertDepartmentsToFilters = (departments: DepartmentResponse[]): Filters => {
    return mapValues(
        keyBy(
            departments.map(({ id }) => id),
            (department) => department,
        ),
        () => true,
    );
};

export const getAncestorStatisticsItem = <T extends Mode>(statisticsItems: StatisticsItem<T>[]): StatisticsItem<T> => {
    return statisticsItems.find((statisticsItem) => isNil(statisticsItem.meta.parentId));
};
