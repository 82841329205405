import * as React from 'react';

import { Flex, FlexProps } from '@common/components';
export interface ButtonsProps extends FlexProps {}

export function Buttons({ children, ...props }: ButtonsProps) {
    return (
        <Flex gap={8} wrap {...props}>
            {children}
        </Flex>
    );
}
