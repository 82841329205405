export async function download(url: string, fileName: string) {
    const response = await fetch(url);
    const blob = await response.blob();
    const urlBase64 = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.style.setProperty('display', 'none');
    document.body.appendChild(link);

    link.download = fileName;
    link.href = urlBase64;
    link.click();
    link.remove();
}
