import * as React from 'react';

import { TaskCommentTransferObject } from 'sber-marketing-types/backend';

import { useSearch } from '@common/hooks';

import { DatesFormatter } from '@common/Utils';

import { Divider, Flex } from '@common/components';

import { useGetTaskChannel } from '@modules/task/hooks';

import { Comment } from '@modules/comment/components';

export interface TaskChatContentProps {
    taskId: string;
    channelId: number;
    onEditComment?: (comment: TaskCommentTransferObject) => void;
    onReplay?: () => void;
}

export function ChatContent({ taskId, channelId, onEditComment, onReplay }: TaskChatContentProps) {
    const { channel } = useGetTaskChannel(taskId, channelId);
    const [{ commentId }] = useSearch();
    const comments = channel?.comments || [];
    const replayCountsMap: Record<string, number> = {};
    let dividerDate: string;
    const commentsByDate: { comments: TaskCommentTransferObject[]; date: string }[] = comments.reduce(
        (result, comment) => {
            const date = DatesFormatter.ddMonthyyyy(comment.createTime, {
                withTodayMarker: true,
            });

            if (commentId ? commentId !== comment.replyId : comment.replyId) {
                const count = replayCountsMap[comment.replyId] || 0;
                replayCountsMap[comment.replyId] = count + 1;
            } else {
                if (date !== dividerDate) {
                    result.push({ comments: [comment], date });
                    dividerDate = date;
                } else {
                    result[result.length - 1].comments.push(comment);
                }
            }

            return result;
        },
        [],
    );

    return (
        <>
            {commentsByDate.map(({ comments, date }) => (
                <Flex vertical reverse key={date}>
                    <Flex vertical gap={8}>
                        {comments.map((comment) => (
                            <Comment
                                replayCount={replayCountsMap[comment.id]}
                                padding={[3, 3, 3, 18]}
                                ghost
                                key={comment.id}
                                comment={comment}
                                onEdit={() => onEditComment?.(comment)}
                                onReplay={onReplay}
                            />
                        ))}
                    </Flex>
                    <Divider legendProps={{ 'data-qa-id': 'ChatContent__date' } as any} accent sticky>
                        {date}
                    </Divider>
                </Flex>
            ))}
        </>
    );
}
