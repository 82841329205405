import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';

interface Props {
    active: boolean;
    refObject: React.RefObject<HTMLDivElement>;
    onClick: () => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}

import * as style from './BudgetButton.scss';

export const BudgetButton: React.FC<Props> = ({ active, refObject, onClick, onMouseEnter, onMouseLeave }) => {
    return (
        <div ref={refObject} className={style.root} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            По месяцам
            <div className={style.pintWrapper} onClick={onClick}>
                <Icon
                    className={classNames(style.pint, active && style.pint__active)}
                    type={IconType.PIN}
                    svgSize={14}
                />
            </div>
        </div>
    );
};
