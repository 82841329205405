import * as React from 'react';
import autobind from 'autobind-decorator';
import { parse as parseUrl } from 'url';
import * as moment from 'moment';

import { ActivityCard, StageName } from './ActivityCard';
import { CardIndicator, UserCurtIndicator, CardIndicatorColor } from '@common/Card';

export { StageName };

export interface ActivityCardContainerProps {
    showPreloader: boolean;
    buttons?: JSX.Element[];
    status: string;
    departmentName: string;
    activityId: number;
    activityName: string;
    isKey: boolean;
    isLoading: boolean;
    productName: string;
    organizationName: string;
    blockName: string;
    divisionName: string;
    author: {
        firstName: string;
        secondName: string;
        organizationName: string;
        departmentName: string;
    };
    responsible: {
        firstName: string;
        secondName: string;
    };
    start: moment.Moment;
    end: moment.Moment;
    stage: StageName;
    hasBrief: boolean;
    from?: string;
    canSwitchKey: boolean;
    isFavorite?: boolean;
    isUnseen?: boolean;
    isDropDownOpened?: boolean;
    isOpenedInSidebar: boolean;
    onFavoriteClick?: (event?: React.MouseEvent<HTMLDivElement>) => void;
    onSwitchKeyButtonClick: () => void;
    toggleDropDown?: (event: React.MouseEvent<HTMLDivElement>) => void;
    openActivitySidebar?: (activityId: number) => void;
    groupName?: string;
    budgetItemSerialNumbers: number[];
    showBindBudgetIdsButton: boolean;
}

interface State {
    isDropDownOpened: boolean;
}

export class ActivityCardContainer extends React.PureComponent<ActivityCardContainerProps, State> {
    constructor(props: ActivityCardContainerProps) {
        super(props);

        this.state = {
            isDropDownOpened: false,
        };
    }

    public render(): JSX.Element {
        const { children, onFavoriteClick, responsible, hasBrief, ...restProps } = this.props;

        return React.createElement(ActivityCard, {
            ...restProps,
            indicators: this.buildIndicators(),
            isDropDownOpened: this.state.isDropDownOpened,
            toggleDropDown: this.toggleDropDown,
            onFavoriteClick: this.onFavoriteClick,
            onBudgetItemsButtonClick: this.onBudgetItemsButtonClick,
            onSwitchKeyButtonClick: this.props.onSwitchKeyButtonClick,
            from: this.getFrom(),
        });
    }

    @autobind
    private toggleDropDown(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault();

        this.setState((prevState: State) => ({
            isDropDownOpened: !prevState.isDropDownOpened,
        }));
    }

    @autobind
    private onFavoriteClick(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault();

        if (this.props.onFavoriteClick) {
            this.props.onFavoriteClick();
        }
    }

    @autobind
    private onBudgetItemsButtonClick(event: React.MouseEvent<HTMLSpanElement>) {
        event.stopPropagation();
        event.preventDefault();

        window.location.href = `/budget/execution?activityId=${this.props.activityId}`;
    }

    private getFrom() {
        const { pathname, search } = parseUrl(window.location.href);
        return !search ? pathname : `${pathname}${search}`;
    }

    private buildIndicators(): JSX.Element[] {
        const { stage, responsible, hasBrief } = this.props;
        const indicators: JSX.Element[] = [];

        if (responsible && responsible.firstName) {
            const text = `Ответственный: ${responsible.firstName}${
                responsible.secondName ? ` ${responsible.secondName}` : ''
            }`;
            indicators.push(React.createElement(UserCurtIndicator, { tooltip: text }, text));
        }

        if (stage === StageName.EXECUTION) {
            const text = 'Проведение';
            const tooltip = `Статус: ${text}`;
            indicators.push(React.createElement(CardIndicator, { color: CardIndicatorColor.GREEN, tooltip }, text));
        } else if (stage === StageName.PREPARATION) {
            const text = 'Подготовка';
            const tooltip = `Статус: ${text}`;
            indicators.push(React.createElement(CardIndicator, { color: CardIndicatorColor.YELLOW, tooltip }, text));
        } else if (stage === StageName.RESULTS) {
            const text = 'Итоги';
            const tooltip = `Статус: ${text}`;
            indicators.push(React.createElement(CardIndicator, { color: CardIndicatorColor.BLUE, tooltip }, text));
        } else if (stage === StageName.COMPLETED) {
            const text = 'Завершен';
            const tooltip = `Статус: ${text}`;
            indicators.push(React.createElement(CardIndicator, { color: CardIndicatorColor.GRAY, tooltip }, text));
        }

        if (hasBrief) {
            const text = 'Есть бриф проекта';
            const tooltip = `Наличие брифа: ${text}`;
            indicators.push(React.createElement(CardIndicator, { color: CardIndicatorColor.GREEN, tooltip }, text));
        } else {
            const text = 'Отсутствует бриф проекта';
            const tooltip = `Наличие брифа: ${text}`;
            indicators.push(React.createElement(CardIndicator, { color: CardIndicatorColor.RED, tooltip }, text));
        }

        return indicators;
    }
}
