import * as React from 'react';

import * as style from './FavoriteIcon.scss';

import { Icon, IconType, WithTooltip } from 'sber-marketing-ui';

interface Props {
    qaId?: string;
    isFavorite?: boolean;
    onClick(): void;
}

export function FavoriteIcon({ qaId, isFavorite, onClick }: Props): JSX.Element {
    const title = isFavorite ? `Убрать проект из "Избранных"` : `Добавить проект в "Избранные"`;

    return (
        <WithTooltip content={title}>
            <div
                {...{
                    'qa-id': qaId,
                    'qa-is-selected': isFavorite ? 'true' : 'false',
                }}
            >
                <Icon
                    className={isFavorite ? style.active : style.normal}
                    svgSize={15}
                    type={IconType.STAR_ACTIVE}
                    onClick={onClick}
                />
            </div>
        </WithTooltip>
    );
}
