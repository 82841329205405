import * as React from 'react';

import { ApproveButton } from './ApproveButton';
import * as styles from './styles.scss';

export interface Props {
    disabled: boolean;
    onApprove: () => void;
}

export const StatusWaitingContent: React.FC<Props> = ({ disabled, onApprove }) => {
    return (
        <div className={styles.root}>
            <ApproveButton disabled={disabled} onClick={onApprove} />
        </div>
    );
};
