import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import { getFilterPeriod, FilterPeriod } from '@store/leaderDashboardPage';
import { Mode } from '@store/leaderDashboardPage';

import * as actions from '@store/leaderDashboardPage/thunks';

interface UseStore {
    (): UseStateReturn;
}

interface UseStateReturn extends Selected {
    setFilterPeriod: SetFilterPeriod;
}

interface Selected {
    selectedFilterPeriod: FilterPeriod;
}

interface SetFilterPeriod {
    (params: SetFilterPeriodParams): void;
}

interface SetFilterPeriodParams {
    period: FilterPeriod;
    mode: Mode;
}

export const useStore: UseStore = () => {
    const dispatch = useDispatch();
    const { selectedFilterPeriod } = useSelector<StoreState, Selected>((state) => {
        return {
            selectedFilterPeriod: getFilterPeriod(state),
        };
    });

    const setFilterPeriod: SetFilterPeriod = React.useCallback(({ period, mode }) => {
        dispatch(
            actions.setFilterPeriod({
                filterPeriod: period,
                mode,
            }),
        );
    }, []);

    return {
        selectedFilterPeriod,
        setFilterPeriod,
    };
};

export { FilterPeriod };
