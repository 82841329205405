import * as React from 'react';

import { useRateByDepartments } from './useRateByDepartments';
import { useState } from './useState';
import { useMode } from '../../../../../hooks';

import { itemsBuildersByMode } from './itemsBuildersByMode';

import type { Bar, Items } from '../types';

interface UseBehavior {
    (params: Params): Return;
}

interface Params {
    departmentId: string;
    bar: Bar;
}

interface Return {
    loading: boolean;
    rate: number;
    items: Items;
}

export const useBehavior: UseBehavior = ({ departmentId, bar }) => {
    const { selectedFilterDate, selectedFilterPeriod } = useState();

    const { mode } = useMode();

    const { rate, loading, load } = useRateByDepartments({
        departmentId,
        date: selectedFilterDate,
        period: selectedFilterPeriod,
    });

    React.useEffect(() => {
        if (mode === 'tasks') {
            load();
        }
    }, []);

    const items = React.useMemo<Items>(() => {
        let items: any[] = [];

        if (mode === 'activities') {
            items = itemsBuildersByMode['activities']({
                bar,
            });
        }

        if (mode === 'tasks') {
            items = itemsBuildersByMode['tasks']({
                bar,
            });
        }

        return items;
    }, [bar]);

    return {
        rate,
        loading,
        items,
    };
};
