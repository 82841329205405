import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { uniqueId, stubArray } from 'lodash';
import * as moment from 'moment';
import { NewsView } from 'sber-marketing-types/frontend';
import type { Difference } from 'sber-marketing-types/backend';
import { HistoryActionType } from 'sber-marketing-types/backend';

import { resetStore } from '@store/common';
import { resetNews, pushNews } from '../actions';
import { DifferenceType } from '../types';
import type { NewsItemParams, PushNewsParams, DifferenceItemParams } from '../types';

function loadEntities(state: NewsItemParams[], payload: PushNewsParams): NewsItemParams[] {
    return [
        ...(payload.preserveEntities ? state : []),
        ...payload.news.map(
            ({
                id,
                action,
                updateTime,
                entityData: { activityId, activityName, taskId, taskName, taskChannelName },
                userId,
                difference,
            }: NewsView) => {
                const mappedDifferences = mapDifferences(action, difference, activityId, taskId);

                const shouldDisplayActivityName: boolean =
                    action !== HistoryActionType.UpdateActivity || !difference['name'];
                const shouldDisplayTaskName: boolean = action !== HistoryActionType.UpdateTask || !difference['title'];

                const wasActivityAssignedToLoginedUser: boolean =
                    action === HistoryActionType.ChangeResponsible &&
                    difference['responsibleId'].after === payload.loginedUserId;
                const newResponsibleId: number =
                    action === HistoryActionType.ChangeResponsible && difference['responsibleId'].after;

                const wasTaskAssignedToLoginedUser: boolean =
                    action === HistoryActionType.UpdateTaskExecutor &&
                    (difference['executor'] || difference['executorId']).after === payload.loginedUserId;

                const commentText: string =
                    (action === HistoryActionType.AddTaskComment ||
                        action === HistoryActionType.MentionedInTaskComment) &&
                    difference['text'].after.split(/\[|\#\d*\]/g).join(' ');

                const fileName: string =
                    (action === HistoryActionType.AddTaskFile || action === HistoryActionType.AddTaskCommentFile) &&
                    difference['fileName'].after;

                return {
                    id,
                    userId,
                    action,
                    shouldDisplayActivityName,
                    activityId,
                    activityName,
                    shouldDisplayTaskName,
                    taskId,
                    taskName,
                    updateTime,
                    taskChannelName,
                    commentText,
                    fileName,
                    wasActivityAssignedToLoginedUser,
                    newResponsibleId,
                    wasTaskAssignedToLoginedUser,
                    difference: mappedDifferences,
                    unseen: payload.onlyUnseenNews,
                };
            },
        ),
    ];
}

const defaultCaption = "You shouldn't have done that...";

enum UPDATE_ACTIVITY_CAPTIONS {
    description = 'Описание проекта',
    productName = 'Название продукта',
    divisionId = 'Блок',
    typeId = 'Тип проекта',
}

enum UPDATE_TITLE_CAPTIONS {
    description = 'Описание задачи',
    title = 'Задача',
    workType = 'Тип работ',
}

function mapDifferences(
    action: HistoryActionType,
    difference: Difference<any>,
    activityId: number,
    taskId: number | string,
): DifferenceItemParams[] {
    switch (action) {
        case HistoryActionType.UpdateTaskExecutor:
            return [
                {
                    id: uniqueId(),
                    type: DifferenceType.USER,
                    before: (difference['executor'] || difference['executorId']).before,
                    after: (difference['executor'] || difference['executorId']).after,
                },
            ];
        case HistoryActionType.ActivityRealizationChanged:
            return [
                {
                    id: uniqueId(),
                    type: DifferenceType.TEXT,
                    before: `${formatDate(difference['realizationStart'].before)} - ${formatDate(
                        difference['realizationEnd'].before,
                    )}`,
                    after: `${formatDate(difference['realizationStart'].after)} - ${formatDate(
                        difference['realizationEnd'].after,
                    )}`,
                },
            ];
        case HistoryActionType.UpdateTaskDeadline:
            return [
                {
                    id: uniqueId(),
                    type: DifferenceType.TEXT,
                    before: formatDate(difference['deadline'].before),
                    after: formatDate(difference['deadline'].after),
                },
            ];
        case HistoryActionType.ActivityPreparationTimingChanged:
            return [
                {
                    id: uniqueId(),
                    type: DifferenceType.TEXT,
                    before: formatDate(difference['preparationTime'].before),
                    after: formatDate(difference['preparationTime'].after),
                },
            ];
        case HistoryActionType.ActivityDebriefingTimingChanged:
            return [
                {
                    id: uniqueId(),
                    type: DifferenceType.TEXT,
                    before: formatDate(difference['debriefingTime'].before),
                    after: formatDate(difference['debriefingTime'].after),
                },
            ];
        case HistoryActionType.UpdateActivity:
            return Object.keys(difference)
                .sort((diffItem) => (diffItem === 'name' ? -1 : 1))
                .reduce((acc, diffItem) => {
                    let type: DifferenceType;
                    let caption: string;
                    let afterLink: string;

                    switch (diffItem) {
                        case 'status':
                            return acc;
                        case 'divisionId':
                            type = DifferenceType.DIVISION;
                            caption = UPDATE_ACTIVITY_CAPTIONS['divisionId'];
                            break;
                        case 'typeId':
                            type = DifferenceType.ACTIVITY;
                            caption = UPDATE_ACTIVITY_CAPTIONS['typeId'];
                            break;
                        case 'name':
                            type = DifferenceType.TEXT;
                            afterLink = `/activity/${activityId}`;
                            break;
                        default:
                            type = DifferenceType.TEXT;
                            caption = UPDATE_ACTIVITY_CAPTIONS[diffItem] || defaultCaption;
                            break;
                    }

                    return [
                        ...acc,
                        {
                            id: uniqueId(),
                            type,
                            caption,
                            before: difference[diffItem].before,
                            after: difference[diffItem].after,
                            afterLink,
                        },
                    ];
                }, []);
        case HistoryActionType.UpdateTask:
            return Object.keys(difference)
                .sort((diffItem) => (diffItem === 'title' ? -1 : 1))
                .map((diffItem) => {
                    let caption: string;
                    let afterLink: string;

                    if (diffItem === 'title') {
                        afterLink = `/activity/${activityId}/task/${taskId}`;
                    } else {
                        caption = UPDATE_TITLE_CAPTIONS[diffItem] || defaultCaption;
                    }

                    return {
                        id: uniqueId(),
                        type: DifferenceType.TEXT,
                        caption,
                        before: difference[diffItem].before,
                        after: difference[diffItem].after,
                        afterLink,
                    };
                });
        case HistoryActionType.AddTaskComment:
        case HistoryActionType.AddTaskFile:
        case HistoryActionType.AddTaskCommentFile:
        case HistoryActionType.MentionedInTaskComment:
        case HistoryActionType.ChangeResponsible:
            return [];
        default:
            return [
                {
                    id: uniqueId(),
                    type: DifferenceType.TEXT,
                    caption: defaultCaption,
                    before: 'Right here',
                    after: 'Right now',
                },
            ];
    }
}

function formatDate(date: string): string {
    return moment(date).format('DD MMMM');
}

export const entitiesReducer = reducerWithInitialState<NewsItemParams[]>([])
    .cases([resetNews, resetStore], stubArray)
    .case(pushNews, loadEntities);
