import { createSelector } from 'reselect';
import { UserConfigType } from 'sber-marketing-types/openid';

import { LoadingStatus } from '@store/commonTypes';
import { UserConfigState } from '@store/userConfig';
import { getUserConfigState } from '../selectors';

import { CommonUserConfigState, CommonUserConfig } from './types';

export const getCommonUserConfigState = createSelector(
    getUserConfigState,
    (state: UserConfigState): CommonUserConfigState => state[UserConfigType.Common],
);

export const getCommonUserConfig = createSelector(
    getCommonUserConfigState,
    (state: CommonUserConfigState): CommonUserConfig => state.fields,
);

export const getLoadingStatus = createSelector(
    getCommonUserConfigState,
    (state: CommonUserConfigState): LoadingStatus => state.status,
);
