import * as React from 'react';

import { CancelButton } from './CancelButton';
import { UnbindButton } from './UnbindButton';

import * as style from './Footer.scss';

export interface Props {
    onCancelButtonClick(): void;
    onUnbindButtonClick(): void;
}

export const Footer: React.FC<Props> = ({ onCancelButtonClick, onUnbindButtonClick }) => {
    return (
        <div className={style.root}>
            <CancelButton onClick={onCancelButtonClick} />
            <UnbindButton onClick={onUnbindButtonClick} />
        </div>
    );
};
