import gql from 'graphql-tag';

export const WITH_COMMENTS_QUERY = gql`
    query withComments($autopilotId: String!) {
        autopilotComments: getAutopilotComments(autopilotId: $autopilotId) {
            nodes {
                id
                text
                createTime
                author {
                    firstName
                    secondName
                    departmentName
                    organizationName
                }
                files {
                    id
                    name
                    originName
                    type
                }
            }
        }
    }
`;

export const CREATE_COMMENT_MUTATION = gql`
    mutation createComment($id: String!, $text: String!, $fileIds: [String]) {
        createComment(comment: { id: $id, text: $text, fileIds: $fileIds })
    }
`;

export const ADD_COMMENT_TO_AUTOPILOT_MUTATION = gql`
    mutation addCommentToAutopilot($autopilotId: String!, $commentId: String!) {
        addCommentToAutopilot(commentId: $commentId, autopilotId: $autopilotId)
    }
`;
