// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-QuickAccessIcons-_QuickAccessIcons__root{display:flex;align-items:center}.src-client-modules-common-QuickAccessIcons-_QuickAccessIcons__buttonWrapper{display:flex}.src-client-modules-common-QuickAccessIcons-_QuickAccessIcons__button{cursor:pointer;color:#7e8681}.src-client-modules-common-QuickAccessIcons-_QuickAccessIcons__button:not(:last-of-type){margin-right:20px}.src-client-modules-common-QuickAccessIcons-_QuickAccessIcons__button_active,.src-client-modules-common-QuickAccessIcons-_QuickAccessIcons__button:hover{color:black}.src-client-modules-common-QuickAccessIcons-_QuickAccessIcons__profileMenu{margin-left:8px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/QuickAccessIcons/QuickAccessIcons.scss"],"names":[],"mappings":"AAAA,oEACI,YAAa,CAEb,kBAAmB,CACtB,6EAGG,YAAa,CAChB,sEAGG,cAAe,CAEf,aAAc,CAHlB,yFAUQ,iBAAkB,CACrB,yJAID,WAAY,CACf,2EAGG,eAAgB","sourcesContent":[".root {\n    display: flex;\n\n    align-items: center;\n}\n\n.buttonWrapper {\n    display: flex;\n}\n\n.button {\n    cursor: pointer;\n\n    color: #7e8681;\n\n    &:hover {\n        @extend .button_active;\n    }\n\n    &:not(:last-of-type) {\n        margin-right: 20px;\n    }\n}\n\n.button_active {\n    color: black;\n}\n\n.profileMenu {\n    margin-left: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-QuickAccessIcons-_QuickAccessIcons__root",
	"buttonWrapper": "src-client-modules-common-QuickAccessIcons-_QuickAccessIcons__buttonWrapper",
	"button": "src-client-modules-common-QuickAccessIcons-_QuickAccessIcons__button",
	"button_active": "src-client-modules-common-QuickAccessIcons-_QuickAccessIcons__button_active",
	"profileMenu": "src-client-modules-common-QuickAccessIcons-_QuickAccessIcons__profileMenu"
};
export default ___CSS_LOADER_EXPORT___;
