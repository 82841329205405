import * as React from 'react';
import * as lodash from 'lodash';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { BudgetItemStatus } from '@mrm/budget';

import { StoreState } from '@store';
import { getLoginUser, isLoggedUserBudgetExpert } from '@store/user';
import { setNotification } from '@store/common/actions';
import { NotificationActionType, NotificationMessage, NotificationType } from '@store/common/types';
import {
    ColumnName,
    LineModalTabs,
    TableLine,
    ShowLineModalParams,
    getBudgetPlanningPageState,
    setPreloaderStatus,
    showLineModal,
    getBudgetItemByLineId,
} from '@store/budgetPlanning';

import { TableLoader, TableValidator, TableSaver } from '../../../modules';
import { Utils } from '@common/Utils';

import { IndicatorsCell } from './IndicatorsCell';

interface Props extends Partial<MapProps & DispatchProps> {
    line: TableLine;
    lineIsHovered: boolean;
    onLineMouseLeave: () => void;
    onApproveButtonClick: (lineId: string) => void;
    onRejectButtonClick: (lineId: string) => void;
    focusField: (columnName: ColumnName) => void;
}

interface MapProps {
    lineHasChanges: boolean;
    lineHasSnapshots: boolean;
    showApprovingButtons: boolean;
    showExpertApprovementButtons: boolean;
    lineUnsavedStatus: BudgetItemStatus;
}

interface DispatchProps {
    setPreloaderStatus: (preloader: boolean) => void;
    setNotification: (notification: NotificationMessage) => void;
    showLineModal: (params: ShowLineModalParams) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class IndicatorsCellContainer extends React.PureComponent<Props> {
    private tableLoader: TableLoader;
    private tableValidator: TableValidator;
    private tableSaver: TableSaver;

    constructor(props: Props) {
        super(props);

        this.tableLoader = TableLoader.getInstance();
        this.tableValidator = TableValidator.getInstance();
        this.tableSaver = TableSaver.getInstance();
    }

    public render(): JSX.Element {
        const {
            line,
            lineIsHovered,
            showApprovingButtons,
            showExpertApprovementButtons,
            lineHasChanges,
            lineHasSnapshots,
            lineUnsavedStatus,
            onLineMouseLeave,
            focusField,
        } = this.props;

        return React.createElement(IndicatorsCell, {
            lineId: line.id,
            lineIsHovered,
            showSendToExpertApprovementButton: line.canMoveToExpertApprovement,
            showApprovingButtons,
            showExpertApprovementButtons,
            lineHasChanges,
            lineHasSnapshots,
            lineUnsavedStatus,
            onLineMouseLeave,
            onApproveButtonClick: this.onApproveButtonClick,
            onRejectButtonClick: this.onRejectButtonClick,
            focusField,
            onSendToExpertApprovementButtonClick: this.onSendToExpertApprovementButtonClick,
            onSnapshotsButtonClick: this.onSnapshotsButtonClick,
        });
    }

    @autobind
    private onApproveButtonClick(): void {
        this.props.onApproveButtonClick(this.props.line.id);
    }

    @autobind
    private onRejectButtonClick(): void {
        this.props.onRejectButtonClick(this.props.line.id);
    }

    @autobind
    protected async onRestoreButtonClick() {
        const { id: lineId } = this.props.line;

        this.props.setPreloaderStatus(true);

        const lineIsActual = await this.tableValidator.checkLineDataRelevance(lineId);
        if (lineIsActual) {
            await this.tableSaver.restoreLine(lineId);
            await this.tableLoader.updateActivitiesByLineIds([lineId]);
            this.notifyAboutLineRestoration();
        } else {
            this.notifyLineDataRelevanceError();
        }

        this.props.setPreloaderStatus(false);
    }

    @autobind
    protected async onSendToExpertApprovementButtonClick() {
        const { id: lineId } = this.props.line;

        this.props.setPreloaderStatus(true);

        const lineIsActual = await this.tableValidator.checkLineDataRelevance(lineId);
        if (lineIsActual) {
            await this.tableSaver.sendLineToExpertApprovement(lineId);
            await this.tableLoader.updateActivitiesByLineIds([lineId]);
            this.notifyAboutLineSendToApproval();
        }

        this.props.setPreloaderStatus(false);
    }

    @autobind
    private onSnapshotsButtonClick(): void {
        const { id } = this.props.line;
        this.props.showLineModal({
            lineId: id,
            selectedTab: LineModalTabs.BudgetItemSnapshotStory,
        });
    }

    private notifyLineDataRelevanceError() {
        this.props.setNotification({
            type: NotificationType.ERROR,
            typeAction: NotificationActionType.BUDGET_PLANNING_LINE_UPDATE,
            comment: 'В редактируемую строку были внесены изменения. Обновите страницу.',
        });
    }

    private notifyAboutLineSendToApproval(): void {
        this.props.setNotification({
            type: NotificationType.SUCCESS,
            typeAction: NotificationActionType.BUDGET_PLANNING_LINE_UPDATE,
            comment: 'Строка отправлена на утверждение',
        });
    }

    private notifyAboutLineRestoration(): void {
        this.props.setNotification({
            type: NotificationType.SUCCESS,
            typeAction: NotificationActionType.BUDGET_PLANNING_LINE_UPDATE,
            comment: 'Строка восстановлена',
        });
    }
}

function mapStateToProps(state: StoreState, props: Props): MapProps {
    const {
        attributes: { id: loginnedUserId },
    } = getLoginUser(state);
    const { id: lineId, status: lineStatus } = props.line;
    const {
        lineStatusChanges,
        approverStatusChanges,
        unsavedChanges,
        pageData: { budgetItemSnapshots },
    } = getBudgetPlanningPageState(state);
    const budgetItem = getBudgetItemByLineId(state, lineId);

    const lineHasChanges =
        !lodash.isEmpty(unsavedChanges[lineId]) || !!lineStatusChanges[lineId] || !!approverStatusChanges[lineId];

    const showApprovingButtons = Utils.withErrorHandler<boolean>(() =>
        budgetItem.approvers.some((approver) => approver.approver.id === loginnedUserId),
    );

    const showExpertApprovementButtons =
        isLoggedUserBudgetExpert(state) && lineStatus == BudgetItemStatus.OnExpertApprovement;

    const lineUnsavedStatus = lineStatusChanges[lineId] ? lineStatusChanges[lineId].status : null;
    const lineHasSnapshots = !!budgetItemSnapshots[lineId];

    return {
        lineHasSnapshots,
        lineHasChanges,
        showApprovingButtons,
        showExpertApprovementButtons,
        lineUnsavedStatus,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setPreloaderStatus,
            setNotification,
            showLineModal,
        },
        dispatch,
    );
}
