import * as React from 'react';
import { connect } from 'react-redux';

import { StoreState } from '@store';
import { TaskPageState } from '@store/taskPage';
import { getTaskPageState } from '@store/taskPage/selectors';

import { TaskBudgetApproval } from './TaskBudgetApproval';

interface Props extends OwnProps, Partial<MapProps> {}

interface OwnProps {}

interface MapProps {
    task: TaskPageState;
}

@(connect(mapStateToProps) as any)
export class TaskBudgetApprovalContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { task } = this.props;
        return <TaskBudgetApproval task={task} />;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const taskState = getTaskPageState(state);
    return {
        task: taskState,
    };
}
