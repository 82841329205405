import * as React from 'react';
import * as style from './Title.scss';

interface Props {
    id: string;
    text: string;
}

export const Title: React.FC<Props> = ({ id, text }) => {
    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'budgetExecutionLineModalTitle',
            }}
        >
            <span className={style.id}>ID: {id},</span> {text}
        </div>
    );
};
