import * as React from 'react';
import autobind from 'autobind-decorator';
import { isNil, includes, without, difference } from 'lodash';

import { ActivitySharing } from './ActivitySharing';
import { ActivitySharingContainerProps as Props, ActivitySharingContainerState as State } from './types';

export class ActivitySharingContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            ownerships: props.ownerships,
        };
    }

    public componentDidUpdate(prevProps: Props) {
        const sharingWasFinised: boolean =
            !this.props.isActivitySharingInProgress &&
            prevProps.isActivitySharingInProgress !== this.props.isActivitySharingInProgress;
        if (sharingWasFinised && !isNil(this.props.onClose)) {
            this.props.onClose();
        }
    }

    public render() {
        const { appUsers, isActivitySharingInProgress, isLoading, authorId, userIsDirector, loginnedUserId } =
            this.props;
        const { ownerships } = this.state;
        return React.createElement(ActivitySharing, {
            isActivitySharingInProgress,
            isLoading,
            loginnedUserId,
            userIsAuthor: loginnedUserId === authorId,
            appUsers: appUsers.filter((user) => user.id != authorId),
            ownerships,
            userIsDirector,
            onSwitch: this.onSwitch,
            onFormClose: this.props.onClose,
            onSaveForm: this.onSave,
            newOwnerships: this.getNewOwnershipsCount(),
            removedOwnerships: this.getRemovedOwnershipsCount(),
        });
    }

    @autobind
    protected onSwitch(value: number) {
        this.setState(({ ownerships }) => ({
            ownerships: includes(ownerships, value) ? without(ownerships, value) : ownerships.concat([value]),
        }));
    }

    @autobind
    protected onSave() {
        this.props.shareActivity(this.state.ownerships);
    }

    private getNewOwnerships(): number[] {
        const { ownerships: stateOwnerships } = this.state;
        const { ownerships: propsOwnerships } = this.props;
        return difference(stateOwnerships, propsOwnerships);
    }

    private getRemovedOwnerships(): number[] {
        const { ownerships: stateOwnerships } = this.state;
        const { ownerships: propsOwnerships } = this.props;
        return difference(propsOwnerships, stateOwnerships);
    }

    private getNewOwnershipsCount(): number {
        return this.getNewOwnerships().length;
    }

    private getRemovedOwnershipsCount(): number {
        return this.getRemovedOwnerships().length;
    }
}
