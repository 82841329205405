import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { UserConfigType } from 'sber-marketing-types/openid';

import { StoreState } from '@store';
import { updateUserConfig } from '@store/userConfig';
import * as activityTypesStore from '@store/activityTypes';
import * as divisionsStore from '@store/divisions';
import * as workTypesStore from '@store/workTypes';
import * as productsStore from '@store/products';
import * as departmentsStore from '@store/departments';

import { DashboardUserConfig, TasksFilterIds, ActivitiesFilterIds, UpdateDashboardUserConfig } from './types';
import * as actions from './actions';

export const loadDashboardUserConfig = bindThunkAction<StoreState, DashboardUserConfig, DashboardUserConfig, Error>(
    actions.loadDashboardUserConfig,
    async (receivedUserConfig, dispatch, getState) => {
        return receivedUserConfig;
    },
);

export const mergeMyTasksPageUserConfig = bindThunkAction<StoreState, void, TasksFilterIds, Error>(
    actions.mergeMyTasksPageUserConfig,
    async (_, dispatch, getState) => {
        const state = getState();

        return {
            workType: workTypesStore.getWorkTypeIds(state, workTypesStore.StoreTypes.MY_TASKS_FILTER),
            department: departmentsStore.getDepartmentIds(state, departmentsStore.StoreTypes.MY_TASKS_FILTER),
        };
    },
);

export const mergeAvailableActivitiesPageUserConfig = bindThunkAction<StoreState, void, ActivitiesFilterIds, Error>(
    actions.mergeAvailableActivitiesPageUserConfig,
    async (_, dispatch, getState) => {
        const state = getState();

        return {
            activityType: activityTypesStore.getActivityTypeIds(
                state,
                activityTypesStore.StoreTypes.AVAILABLE_ACTIVITIES_FILTER,
            ),
            product: productsStore.getProductIds(state, productsStore.StoreTypes.AVAILABLE_ACTIVITIES_FILTER),
            responsibleDepartment: departmentsStore.getDepartmentIds(
                state,
                departmentsStore.StoreTypes.AVAILABLE_ACTIVITIES_RESPONSIBLE_FILTERS,
            ),
        };
    },
);

export const mergeMyActivitiesPageUserConfig = bindThunkAction<StoreState, void, ActivitiesFilterIds, Error>(
    actions.mergeMyActivitiesPageUserConfig,
    async (_, dispatch, getState) => {
        const state = getState();

        return {
            activityType: activityTypesStore.getActivityTypeIds(
                state,
                activityTypesStore.StoreTypes.MY_ACTIVITIES_FILTER,
            ),
            product: productsStore.getProductIds(state, productsStore.StoreTypes.MY_ACTIVITIES_FILTER),
            responsibleDepartment: departmentsStore.getDepartmentIds(
                state,
                departmentsStore.StoreTypes.MY_ACTIVITIES_RESPONSIBLE_FILTERS,
            ),
        };
    },
);

export const updateDashboardUserConfig = bindThunkAction<StoreState, UpdateDashboardUserConfig, void, Error>(
    actions.updateDashboardUserConfig,
    async (payload, dispatch) => {
        dispatch(updateUserConfig(UserConfigType.Dashboard));
    },
);
