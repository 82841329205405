// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Content-_styles__root{padding:0 16px 16px 16px;display:grid;grid-auto-rows:auto;grid-row-gap:24px;border-left:1px solid #E3E9E5}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/CreationForm/GeneratedTextEditor/Editor/Content/styles.scss"],"names":[],"mappings":"AAAA,2IACI,wBAAyB,CAEzB,YAAa,CACb,mBAAoB,CACpB,iBAAkB,CAElB,6BAA8B","sourcesContent":[".root {\n    padding: 0 16px 16px 16px;\n\n    display: grid;\n    grid-auto-rows: auto;\n    grid-row-gap: 24px;\n\n    border-left: 1px solid #E3E9E5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Content-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
