// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-RowSelectors-_RowSelectors__root{margin-bottom:8px;display:flex;gap:6px;flex-wrap:wrap}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-RowSelectors-_RowSelectors__item{box-shadow:0px 1px 4px rgba(0,0,0,0.1);border-radius:4px;padding:3px 6px 2px 6px;font-family:'Open Sans';font-size:12px;line-height:16px;letter-spacing:0.15px;color:#000000}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-RowSelectors-_RowSelectors__itemSelectable{color:#417EC6;cursor:pointer}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-RowSelectors-_RowSelectors__itemSelectable:hover{background:#F6F6F6;color:#0058BF}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/InternalTransitionSumEntering/RowSelectors/RowSelectors.scss"],"names":[],"mappings":"AAAA,wIACI,iBAAkB,CAElB,YAAa,CACb,OAAQ,CACR,cAAe,CAClB,wIAGG,sCAA0C,CAC1C,iBAAkB,CAElB,uBAAwB,CAExB,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACjB,qBAAsB,CAEtB,aAAc,CACjB,kJAGG,aAAc,CACd,cAAe,CAFnB,wJAKQ,kBAAmB,CACnB,aAAc","sourcesContent":[".root {\n    margin-bottom: 8px;\n\n    display: flex;\n    gap: 6px;\n    flex-wrap: wrap;\n}\n\n.item {\n    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);\n    border-radius: 4px;\n\n    padding: 3px 6px 2px 6px;\n\n    font-family: 'Open Sans';\n    font-size: 12px;\n    line-height: 16px;\n    letter-spacing: 0.15px;\n\n    color: #000000;\n}\n\n.itemSelectable {\n    color: #417EC6;\n    cursor: pointer;\n\n    &:hover {\n        background: #F6F6F6;\n        color: #0058BF;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-RowSelectors-_RowSelectors__root",
	"item": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-RowSelectors-_RowSelectors__item",
	"itemSelectable": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-RowSelectors-_RowSelectors__itemSelectable"
};
export default ___CSS_LOADER_EXPORT___;
