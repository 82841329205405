import * as React from 'react';
import * as lodash from 'lodash';

import * as style from './TableView.scss';

import type { CellPosition, Point, ColumnHeaderParams, ColumnName, ColumnWidths, LineHeights, LineId } from '../types';
import { CellEvent } from '../types';

import { CustomScrollbar_new as CustomScrollbar, ScrollbarComponent } from 'sber-marketing-ui';
import { Cursor } from './Cursor';
import { Cell } from './Cell';
import { StickyWrapper, StickyWrapperPosition } from './StickyWrapper';

interface Props {
    allColumns: ColumnName[];
    columns: ColumnName[];
    leftFixedColumns: ColumnName[];
    rightFixedColumns: ColumnName[];
    lines: LineId[];
    cursorPosition: CellPosition;
    visibleColumnsIndexes: number[];
    visibleLinesIndexes: number[];
    columnWidths: ColumnWidths;
    lineHeights: LineHeights;
    columnsSumWidth: number;
    leftFixedSumWidth: number;
    rightFixedSumWidth: number;
    sumHeight: number;
    tableBodyMaxHeight: number;
    rootRef: React.RefObject<HTMLDivElement>;
    viewportRef: React.RefObject<HTMLDivElement>;
    tableHeaderRef: (component: ScrollbarComponent) => void;
    stickyTableHeaderRef: (component: ScrollbarComponent) => void;
    tableBodyRef: (component: ScrollbarComponent) => void;
    leftFixedColumnsRef: (component: ScrollbarComponent) => void;
    rightFixedColumnsRef: (component: ScrollbarComponent) => void;
    stickyScrollbarRef: (component: ScrollbarComponent) => void;
    cursorRef: (component: Cursor) => void;
    getColumnHeader: (columnName: string) => ColumnHeaderParams;
    getCellParams: (
        position: CellPosition,
        onCellParamsUpdateHandler?: (cellProps: any) => void,
    ) => {
        component: React.ClassType<any, any, any>;
        cellProps: any;
        readOnly: boolean;
    };
    selectCell: (position: CellPosition) => void;
    onBodyScroll: () => void;
    onLeftFixedColumnsScroll: () => void;
    onRightFixedColumnsScroll: () => void;
    onStickyScrollbarScroll: () => void;
    onColumnEdgeMousedown: (columnName: ColumnName, mouseDownX: number) => void;
    onCellEvent: (eventType: CellEvent, position: CellPosition) => void;
}

export const TableViewTemplate = ({
    allColumns,
    columns,
    leftFixedColumns,
    rightFixedColumns,
    lines,
    cursorPosition,
    visibleColumnsIndexes,
    visibleLinesIndexes,
    columnWidths,
    lineHeights,
    columnsSumWidth,
    leftFixedSumWidth,
    rightFixedSumWidth,
    sumHeight,
    tableBodyMaxHeight,
    rootRef,
    viewportRef,
    tableHeaderRef,
    stickyTableHeaderRef,
    tableBodyRef,
    leftFixedColumnsRef,
    rightFixedColumnsRef,
    stickyScrollbarRef,
    cursorRef,
    getColumnHeader,
    getCellParams,
    selectCell,
    onBodyScroll,
    onLeftFixedColumnsScroll,
    onRightFixedColumnsScroll,
    onStickyScrollbarScroll,
    onColumnEdgeMousedown,
    onCellEvent,
}: Props): JSX.Element => {
    return (
        <div className={style.root} ref={rootRef}>
            <div className={style.tableHeader}>
                {!lodash.isEmpty(leftFixedColumns) && (
                    <div className={style.leftFixedColumns} style={{ width: leftFixedSumWidth }}>
                        {renderFixedColumnHeaders(leftFixedColumns)}
                    </div>
                )}

                <CustomScrollbar scrollbarRef={tableHeaderRef} hideScrollX hideScrollY freezeScrollY>
                    <div className={style.headScroller} style={{ width: columnsSumWidth }}>
                        {renderColumnHeaders()}
                    </div>
                </CustomScrollbar>

                {!lodash.isEmpty(rightFixedColumns) && (
                    <div className={style.rightFixedColumns} style={{ width: rightFixedSumWidth }}>
                        {renderFixedColumnHeaders(rightFixedColumns)}
                    </div>
                )}
            </div>

            <div className={style.tableBody}>
                {!lodash.isEmpty(leftFixedColumns) && (
                    <div className={style.leftFixedColumns} style={{ width: leftFixedSumWidth }}>
                        <CustomScrollbar
                            scrollbarRef={leftFixedColumnsRef}
                            maxHeight={tableBodyMaxHeight}
                            onScroll={onLeftFixedColumnsScroll}
                            freezeScrollX
                            hideScrollX
                            hideScrollY
                        >
                            <div className={style.leftFixedColumnsScroller} style={{ height: sumHeight }}>
                                {renderFixedColumnsCells(leftFixedColumns)}
                                {renderCursor(cursorPosition, leftFixedColumns)}
                            </div>
                        </CustomScrollbar>
                    </div>
                )}

                <div className={style.viewport} ref={viewportRef}>
                    <CustomScrollbar
                        scrollbarRef={tableBodyRef}
                        maxHeight={tableBodyMaxHeight}
                        hideScrollY={!lodash.isEmpty(rightFixedColumns)}
                        onScroll={onBodyScroll}
                    >
                        <div className={style.bodyScroller} style={{ width: columnsSumWidth, height: sumHeight }}>
                            {renderCells()}
                            {renderCursor(cursorPosition, columns)}
                        </div>
                    </CustomScrollbar>
                </div>

                {!lodash.isEmpty(rightFixedColumns) && (
                    <div className={style.rightFixedColumns} style={{ width: rightFixedSumWidth }}>
                        <CustomScrollbar
                            scrollbarRef={rightFixedColumnsRef}
                            maxHeight={tableBodyMaxHeight}
                            onScroll={onRightFixedColumnsScroll}
                            freezeScrollX
                            hideScrollX
                        >
                            <div className={style.rightFixedColumnsScroller} style={{ height: sumHeight }}>
                                {renderFixedColumnsCells(rightFixedColumns)}
                                {renderCursor(cursorPosition, rightFixedColumns)}
                            </div>
                        </CustomScrollbar>
                    </div>
                )}

                <StickyWrapper
                    position={StickyWrapperPosition.Top}
                    block={rootRef.current}
                    customStyle={{ paddingLeft: 104, paddingRight: 465 }}
                >
                    <div className={style.tableHeader} style={{ border: '1px solid #e6edf1' }}>
                        {!lodash.isEmpty(leftFixedColumns) && (
                            <div className={style.leftFixedColumns} style={{ width: leftFixedSumWidth }}>
                                {renderFixedColumnHeaders(leftFixedColumns)}
                            </div>
                        )}

                        <CustomScrollbar scrollbarRef={stickyTableHeaderRef} hideScrollX hideScrollY freezeScrollY>
                            <div className={style.headScroller} style={{ width: columnsSumWidth }}>
                                {renderColumnHeaders()}
                            </div>
                        </CustomScrollbar>

                        {!lodash.isEmpty(rightFixedColumns) && (
                            <div className={style.rightFixedColumns} style={{ width: rightFixedSumWidth }}>
                                {renderFixedColumnHeaders(rightFixedColumns)}
                            </div>
                        )}
                    </div>
                </StickyWrapper>

                <StickyWrapper
                    position={StickyWrapperPosition.Bottom}
                    block={rootRef.current}
                    customStyle={{ paddingLeft: 104, paddingRight: 465 }}
                >
                    <div style={{ paddingLeft: leftFixedSumWidth, paddingRight: rightFixedSumWidth, height: 8 }}>
                        <CustomScrollbar
                            scrollbarRef={stickyScrollbarRef}
                            hideScrollY
                            onScroll={onStickyScrollbarScroll}
                        >
                            <div style={{ width: columnsSumWidth, height: 8 }} />
                        </CustomScrollbar>
                    </div>
                </StickyWrapper>
            </div>
        </div>
    );

    function renderColumnHeaders(): JSX.Element[] {
        const columnHeaders: JSX.Element[] = [];
        let sum = 0;

        if (!lodash.isEmpty(visibleColumnsIndexes)) {
            columns.forEach((columnName, columnIndex) => {
                if (visibleColumnsIndexes.includes(columnIndex)) {
                    columnHeaders.push(
                        <React.Fragment key={columnName}>
                            <div
                                key={columnName}
                                className={style.columnHeader}
                                style={{ left: sum, width: columnWidths[columnName] }}
                            >
                                {React.createElement(
                                    getColumnHeader(columnName).component as any,
                                    getColumnHeader(columnName).columnHeaderProps,
                                )}
                            </div>

                            {!getColumnHeader(columnName).disableWidthChange && (
                                <div
                                    className={style.columnHeaderDragzone}
                                    style={{ left: sum + columnWidths[columnName] }}
                                    onMouseDown={(event) => onColumnEdgeMousedown(columnName, event.clientX)}
                                />
                            )}
                        </React.Fragment>,
                    );
                }

                sum += columnWidths[columnName] + 1;
            });
        }

        return columnHeaders;
    }

    function renderFixedColumnHeaders(fixedColumns: ColumnName[]): JSX.Element[] {
        const columnHeaders: JSX.Element[] = [];
        let sum = 0;

        fixedColumns.forEach((columnName) => {
            columnHeaders.push(
                <React.Fragment key={columnName}>
                    <div
                        key={columnName}
                        className={style.columnHeader}
                        style={{ left: sum, width: columnWidths[columnName] }}
                    >
                        {React.createElement(
                            getColumnHeader(columnName).component as any,
                            getColumnHeader(columnName).columnHeaderProps,
                        )}
                    </div>

                    {!getColumnHeader(columnName).disableWidthChange && (
                        <div
                            className={style.columnHeaderDragzone}
                            style={{ left: sum + columnWidths[columnName] }}
                            onMouseDown={(event) => onColumnEdgeMousedown(columnName, event.clientX)}
                        />
                    )}
                </React.Fragment>,
            );

            sum += columnWidths[columnName] + 1;
        });

        return columnHeaders;
    }

    function renderCells(): JSX.Element[] {
        const cells: JSX.Element[] = [];
        let heightSum = 0;
        let widthSum = 0;

        // if (!lodash.isEmpty(visibleLinesIndexes) && !lodash.isEmpty(visibleColumnsIndexes)) {
        if (!lodash.isEmpty(visibleColumnsIndexes)) {
            lines.forEach((lineId, lineIndex) => {
                // if (visibleLinesIndexes.includes(lineIndex)) {
                widthSum = 0;

                columns.forEach((columnName, columnIndex) => {
                    if (visibleColumnsIndexes.includes(columnIndex)) {
                        cells.push(renderCell({ columnName, lineId }, { x: widthSum, y: heightSum }));
                    }

                    widthSum += columnWidths[columnName] + 1;
                });
                // }

                const lineHeight = lineHeights[lineId] || 0;

                heightSum += lineHeight + 1;
            });
        }

        return cells;
    }

    function renderFixedColumnsCells(fixedColumns: ColumnName[]): JSX.Element[] {
        const fixedCells: JSX.Element[] = [];
        let heightSum = 0;
        let widthSum = 0;

        // if (!lodash.isEmpty(visibleLinesIndexes)) {
        lines.forEach((lineId, lineIndex) => {
            // if (visibleLinesIndexes.includes(lineIndex)) {
            widthSum = 0;

            fixedColumns.forEach((columnName) => {
                fixedCells.push(renderCell({ columnName, lineId }, { x: widthSum, y: heightSum }));

                widthSum += columnWidths[columnName] + 1;
            });
            // }

            const lineHeight = lineHeights[lineId] || 0;

            heightSum += lineHeight + 1;
        });
        // }

        return fixedCells;
    }

    function renderCell(position: CellPosition, coords: Point): JSX.Element {
        const { columnName, lineId } = position;
        const { x, y } = coords;

        return (
            <div
                className={style.cell}
                key={`${columnName} ${lineId}`}
                style={{
                    top: y,
                    left: x,
                    width: columnWidths[columnName],
                    height: lineHeights[lineId],
                }}
                onClick={() => onCellEvent(CellEvent.Click, { lineId, columnName })}
            >
                {(!cursorPosition ||
                    !(cursorPosition.columnName === columnName && cursorPosition.lineId === lineId)) && (
                    <Cell position={{ columnName, lineId }} getCellParams={getCellParams} />
                )}
            </div>
        );
    }

    function renderCursor(position: CellPosition, columns: ColumnName[]): JSX.Element {
        if (position === null || !columns.includes(position.columnName) || !lines.includes(position.lineId)) {
            return null;
        }

        const { columnName, lineId } = position;

        return (
            <div
                className={style.cursor}
                style={{
                    top: getItemPosition(lineId, lines, lineHeights),
                    left: getItemPosition(columnName, columns, columnWidths),
                    width: columnWidths[columnName],
                    height: lineHeights[lineId],
                }}
            >
                <Cursor
                    ref={cursorRef}
                    position={position}
                    columns={columns}
                    allColumns={allColumns}
                    lines={lines}
                    getCellParams={getCellParams}
                    selectCell={selectCell}
                    onCellEvent={onCellEvent}
                />
            </div>
        );
    }
};

function getItemPosition<T extends React.ReactText>(itemName: T, items: T[], itemsSize: Record<T, number>): number {
    let sum = 0;
    let index = 0;

    while (items[index] !== itemName && index < items.length + 1) {
        sum += itemsSize[items[index]] + 1;
        index += 1;
    }

    return sum;
}
