// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-brief-Brief-Block-Field-FieldTypes-Budgets-_Budgets__root{position:relative;width:100%;padding:4px 0 20px}.src-client-modules-brief-Brief-Block-Field-FieldTypes-Budgets-_Budgets__title{letter-spacing:1px;text-transform:uppercase;color:#404040;font-family:'Open Sans';font-size:8px;line-height:12px}.src-client-modules-brief-Brief-Block-Field-FieldTypes-Budgets-_Budgets__iconTooltip{position:absolute;top:4px;right:4px;display:flex;font-size:20px}.src-client-modules-brief-Brief-Block-Field-FieldTypes-Budgets-_Budgets__tooltipMessage{text-align:left}\n", "",{"version":3,"sources":["webpack://./src/client/modules/brief/Brief/Block/Field/FieldTypes/Budgets/Budgets.scss"],"names":[],"mappings":"AAAA,8EACI,iBAAkB,CAElB,UAAW,CACX,kBAAmB,CACtB,+EAGG,kBAAmB,CACnB,wBAAyB,CAEzB,aAAc,CAEd,uBAAwB,CACxB,aAAc,CACd,gBAAiB,CACpB,qFAGG,iBAAkB,CAClB,OAAQ,CACR,SAAU,CAEV,YAAa,CACb,cAAe,CAClB,wFAGG,eAAgB","sourcesContent":[".root {\n    position: relative;\n\n    width: 100%;\n    padding: 4px 0 20px;\n}\n\n.title {\n    letter-spacing: 1px;\n    text-transform: uppercase;\n\n    color: #404040;\n\n    font-family: 'Open Sans';\n    font-size: 8px;\n    line-height: 12px;\n}\n\n.iconTooltip {\n    position: absolute;\n    top: 4px;\n    right: 4px;\n\n    display: flex;\n    font-size: 20px;\n}\n\n.tooltipMessage {\n    text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-brief-Brief-Block-Field-FieldTypes-Budgets-_Budgets__root",
	"title": "src-client-modules-brief-Brief-Block-Field-FieldTypes-Budgets-_Budgets__title",
	"iconTooltip": "src-client-modules-brief-Brief-Block-Field-FieldTypes-Budgets-_Budgets__iconTooltip",
	"tooltipMessage": "src-client-modules-brief-Brief-Block-Field-FieldTypes-Budgets-_Budgets__tooltipMessage"
};
export default ___CSS_LOADER_EXPORT___;
