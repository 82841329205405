import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { includes, uniqBy, flatten } from 'lodash';
import { UserCard, DepartmentAttributes } from 'sber-marketing-types/frontend';
import { SuggestItemParams } from 'sber-marketing-ui';

import { withAppUsers } from '@common/withAppUsers';
import { withUserOrganizationsIds } from '@common/withUserOrganizationsIds';
import { LoadingStatus } from '@store/commonTypes';
import { getPriorityLoadingStatus } from '@store/common/utils';
import { StoreState } from '@store';
import { selectors, changeExecutor, WorkType } from '@store/taskPage';
import * as departmentsStore from '@store/departments';

import {
    TaskExecutorSuggestProps,
    TaskExecutorSuggestMappedState,
    TaskExecutorSuggestMappedActions,
    Props,
} from './types';

import { TaskExecutorSuggest } from './TaskExecutorSuggest';

const TITLE = 'Исполнитель';

const getShowPreloader = (
    userOrganizationsIdsLoadingStatus: LoadingStatus,
    departmentsLoadingStatus: LoadingStatus,
    appUsersLoadingStatus: LoadingStatus,
): boolean => {
    const loadingStatus = getPriorityLoadingStatus(
        userOrganizationsIdsLoadingStatus,
        departmentsLoadingStatus,
        appUsersLoadingStatus,
    );
    return loadingStatus !== LoadingStatus.LOADED;
};

const mapUserToItem = ({ id, firstName, secondName, department, isActive, vacation }: UserCard): SuggestItemParams => ({
    value: id,
    itemTitle: vacation?.comment,
    label: vacation ? `🌴 ${firstName} ${secondName} (в отпуске)` : `${firstName} ${secondName}`,
    subLabel: department,
    disabled: !isActive,
    type: 'user',
});

const mapDepartmentToItem = ({ id, name }: DepartmentAttributes) => ({
    value: id,
    label: name,
    subLabel: '-',
    disabled: false,
    type: 'department',
});

const filterUsers = (list: UserCard[], userOrganizationsIds: string[], departmentIds: string[]) =>
    list
        .filter(
            ({ organizationId, departmentId }) =>
                includes(userOrganizationsIds, organizationId) && includes(departmentIds, departmentId),
        )
        .map(mapUserToItem);

const mapAllAvailableDepartments = (
    state: StoreState,
    taskWorkType: WorkType,
    userOrganizationsIds: string[],
): DepartmentAttributes[] => {
    const baseDepartments = uniqBy(
        [
            ...(taskWorkType ? taskWorkType.departmentIds : []).map((id) =>
                departmentsStore.getDepartmentById(state, id),
            ),
            ...userOrganizationsIds
                .concat(taskWorkType ? [taskWorkType.organizationId] : [])
                .reduce((acc, id) => [...acc, ...departmentsStore.getDepartmentsByOrganizationId(state, id)], []),
        ],
        'id',
    );

    const childDepartments = uniqBy(
        flatten(baseDepartments.map(({ id }) => departmentsStore.getChildrenDepartments(state, id))),
        'id',
    );
    return uniqBy(baseDepartments.concat(childDepartments), 'id');
};

const mapStateToProps = (state: StoreState, { userOrganizationsIds }: any): TaskExecutorSuggestMappedState => {
    const { workType: workTypeId } = selectors.getTaskInfo(state);
    const taskWorkType = selectors.getWorkTypeById(state, workTypeId);
    const departments = mapAllAvailableDepartments(state, taskWorkType, userOrganizationsIds);
    return {
        taskWorkType,
        departments,
        departmentsLoadingStatus: departmentsStore.getLoadingStatus(state, departmentsStore.StoreTypes.ALL_DEPARTMENTS),
    };
};

const mapDispatchToProps = (
    dispatch: Dispatch<StoreState>,
    { onSuggestItemSelect }: any,
): TaskExecutorSuggestMappedActions => ({
    onItemSelect: (selectedExecutor) => {
        if (selectedExecutor) {
            dispatch(
                changeExecutor({
                    executorId: selectedExecutor.value,
                }),
            );
            onSuggestItemSelect();
        }
    },
    loadDepartments: () =>
        dispatch(
            departmentsStore.loadDepartments({
                store: departmentsStore.StoreTypes.ALL_DEPARTMENTS,
            }),
        ),
});

const getCurrentExecutor = (
    departmentExecutorId: string,
    executorId: number,
    appUsers: UserCard[],
    departments: DepartmentAttributes[],
) => {
    let result = null;

    if (departmentExecutorId) {
        const department = departments.find((item) => item.id == departmentExecutorId);
        if (department) {
            result = mapDepartmentToItem(department);
        }
    }
    if (executorId) {
        const user = appUsers.find((item) => item.id == executorId);
        if (user) {
            result = mapUserToItem(user);
        }
    }

    return result;
};

const mergeProps = (
    { departments, departmentsLoadingStatus, taskWorkType }: TaskExecutorSuggestMappedState,
    { onItemSelect, loadDepartments }: TaskExecutorSuggestMappedActions,
    {
        departmentExecutorId,
        executorId,
        disabled,
        appUsers,
        appUsersLoadingStatus,
        userOrganizationsIdsLoadingStatus,
        userOrganizationsIds,
        onChangeExecutorClick,
        showAddExecutorButton,
    }: any,
): Props => {
    return {
        onItemSelect,
        disabled,
        showPreloader: getShowPreloader(
            appUsersLoadingStatus,
            departmentsLoadingStatus,
            userOrganizationsIdsLoadingStatus,
        ),
        selectedItem: getCurrentExecutor(departmentExecutorId, executorId, appUsers, departments),
        items: filterUsers(appUsers, userOrganizationsIds, taskWorkType?.departmentIds || []),
        title: TITLE,
        showSubLabels: true,
        focusAfterDisable: true,
        onChangeExecutorClick,
        showAddExecutorButton,
        loadDepartments,
    };
};

// const withStore = connect(mapStateToProps, mapDispatchToProps, mergeProps);

@(connect(mapStateToProps, mapDispatchToProps, mergeProps) as any)
class TaskExecutorSuggestContainer extends React.PureComponent<Props> {
    public compoentDidMount(): void {
        this.props.loadDepartments();
    }

    public render(): JSX.Element {
        return <TaskExecutorSuggest {...this.props} />;
    }
}

/** Task executor suggest */
export const TaskExecutorSuggestWrapped: React.ComponentType<TaskExecutorSuggestProps> = withUserOrganizationsIds(
    withAppUsers(TaskExecutorSuggestContainer as any),
);
