import * as React from 'react';
import classNames from 'classnames';

import * as style from './AutopilotDOOHPage.scss';

import { AutopilotStage } from '@store/autopilotDOOH/types';

import { OpacityTransition, CustomScrollbar, Preloader, Skeleton } from 'sber-marketing-ui';
import { StagesListWidget } from './StagesListWidget';
import {
    BriefStageActions,
    MediaplanSettingsStageActions,
    MediaplanStageActions,
    BudgetStageActions,
} from './StageActions';
import {
    BriefStageWidget,
    MediaplanSettingsStageWidget,
    MediaplanStageWidget,
    BudgetStageWidget,
} from './StageWidgets';
import { CommentsWidget } from './CommentsWidget';

interface Props {
    preloader: boolean;
    pageDataLoaded: boolean;
    userIsAutopilotClient: boolean;
    userIsAutopilotClientService: boolean;
    autopilotId: string;
    autopilotIsEnabled: boolean;
    sidebarIsOpened: boolean;
    currentStage: AutopilotStage;
}

export const AutopilotDOOHPageTemplate = ({
    preloader,
    pageDataLoaded,
    userIsAutopilotClient,
    userIsAutopilotClientService,
    autopilotId,
    autopilotIsEnabled,
    sidebarIsOpened,
    currentStage,
}: Props): JSX.Element => {
    return (
        <OpacityTransition>
            <div className={classNames(style.root, sidebarIsOpened && style.sidebarOpened)}>
                <div className={style.content}>
                    <div className={style.stageList}>
                        <StagesListWidget currentStage={currentStage} />
                    </div>

                    {autopilotIsEnabled && pageDataLoaded && (
                        <>
                            <div className={style.stageActions}>
                                {currentStage === AutopilotStage.Brief && (
                                    <BriefStageActions canEdit={userIsAutopilotClient} />
                                )}

                                {currentStage === AutopilotStage.MediaplanSettings && (
                                    <MediaplanSettingsStageActions canEdit={userIsAutopilotClient} />
                                )}

                                {currentStage === AutopilotStage.Mediaplan && (
                                    <MediaplanStageActions canEdit={userIsAutopilotClient} />
                                )}

                                {currentStage === AutopilotStage.Budget && (
                                    <BudgetStageActions canEdit={userIsAutopilotClient} />
                                )}
                            </div>

                            <div className={style.stageWidget}>
                                {currentStage === AutopilotStage.Brief && (
                                    <BriefStageWidget readOnly={!userIsAutopilotClient} />
                                )}

                                {currentStage === AutopilotStage.MediaplanSettings && (
                                    <MediaplanSettingsStageWidget readOnly={!userIsAutopilotClient} />
                                )}

                                {currentStage === AutopilotStage.Mediaplan && (
                                    <MediaplanStageWidget readOnly={!userIsAutopilotClient} />
                                )}

                                {currentStage === AutopilotStage.Budget && (
                                    <BudgetStageWidget readOnly={!userIsAutopilotClient} />
                                )}
                            </div>

                            {preloader && (
                                <div className={style.preloader}>
                                    <Preloader />
                                </div>
                            )}
                        </>
                    )}

                    {!pageDataLoaded && (
                        <>
                            <div className={style.stageActions}>
                                <Skeleton customStyle={{ height: 48, width: '100%', borderRadius: 2 }} />
                            </div>

                            <div className={style.stageWidget}>
                                <Skeleton customStyle={{ height: 19, width: 120, borderRadius: 2, marginBottom: 8 }} />
                                <Skeleton customStyle={{ height: 350, width: '100%', borderRadius: 2 }} />
                            </div>
                        </>
                    )}
                </div>

                {sidebarIsOpened && (
                    <div className={style.rightSidebar}>
                        {autopilotId && (
                            <CustomScrollbar hideHorizontalScrollbar hideTracksWhenNotNeeded>
                                <CommentsWidget autopilotId={autopilotId} />
                            </CustomScrollbar>
                        )}
                    </div>
                )}
            </div>
        </OpacityTransition>
    );
};
