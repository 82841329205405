import { createSelector } from 'reselect';
import { isEqual } from 'lodash';

import { StoreState } from '@store';

import { BudgetCorrectionsFiltersAndExportState } from './types';

export const getBudgetCorrectionsFiltersAndExportState = (state: StoreState) =>
    state.budgetCorrectionsPage.filtersAndExport;

export const filtersHaveChanged = createSelector(
    getBudgetCorrectionsFiltersAndExportState,
    (state: BudgetCorrectionsFiltersAndExportState): boolean => !isEqual(state.savedFilters, state.currentFilters),
);
