import type { CreativeRequestDomain } from '../types';
import { Handlers } from '../converters';

import { unsubscribeCreativeRequestParamDomains } from './unsubscribeCreativeRequestParamDomains';

interface Unsubscriber {
    (domain: CreativeRequestDomain, params: { handlers: Handlers }): Promise<void>;
}

export const unsubscribeCreativeRequestDomain: Unsubscriber = async (domain, params) => {
    const { handlers } = params;

    domain.events.offParamsAdded(handlers.onParamsAdded);
    domain.events.offParamsRemoved(handlers.onParamsRemoved);
    domain.events.offBudgetItemsAdded(handlers.onBudgetItemsAdded);
    domain.events.offBudgetItemsRemoved(handlers.onBudgetItemsRemoved);
    domain.events.offBudgetItemsUpdated(handlers.onBudgetItemsUpdated);

    const creativeRequestParamDomains = await domain.model.getParams();
    unsubscribeCreativeRequestParamDomains(creativeRequestParamDomains, params);
};
