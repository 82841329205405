import gql from 'graphql-tag';

export const LINKED_ACTIVITIES_QUERY = gql`
    query LinkedActivities($budgetItemId: String!) {
        linkedActivities: getProjectsByBudgetItemId(budgetItemId: $budgetItemId) {
            id
            name
            product {
                id
                name
            }
            division {
                id
                name
            }
            author {
                id
                firstName
                secondName
                organizationName
            }
            realizationStart
            realizationEnd
            debriefing
            permissions {
                read
            }
        }
    }
`;
