import * as React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import {
    CellPosition,
    InternalTransferDirection,
    getBudgetTransferMenuState,
    isCellSelected,
} from '@store/budgetExecution/budgetTransferMenu';

import * as styles from './Arrow.scss';

function useStore() {
    const shouldRender = useSelector((state: StoreState) => {
        const {
            cells: { from, to },
            controls: { internalTransferDirection },
        } = getBudgetTransferMenuState(state);

        let cell: CellPosition;
        switch (internalTransferDirection) {
            case InternalTransferDirection.OneToMany:
                cell = from[0];
                break;
            case InternalTransferDirection.ManyToOne:
                cell = to[0];
                break;
            default:
                break;
        }

        return isCellSelected(cell);
    });
    const shouldRotate = useSelector(
        (state: StoreState) =>
            getBudgetTransferMenuState(state).controls.internalTransferDirection ===
            InternalTransferDirection.OneToMany,
    );

    return {
        shouldRender,
        shouldRotate,
    };
}

export function Arrow(): JSX.Element {
    const { shouldRender, shouldRotate } = useStore();

    return shouldRender ? (
        <div className={classnames(styles.root, shouldRotate && styles.rootRotated)}>
            <svg width="8" height="46" viewBox="0 0 8 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.98741 46C3.63301 46 3.34012 45.7372 3.29377 45.3962L3.28738 45.3014L3.2874 2.39917L1.19502 4.48567C0.944428 4.73574 0.551105 4.75658 0.276721 4.54819L0.205034 4.48567C-0.0455627 4.23559 -0.0664463 3.84308 0.142385 3.56927L0.205034 3.49773L3.505 0.204609C3.7556 -0.0454674 4.14892 -0.0663071 4.42331 0.14209L4.495 0.204609L7.79497 3.49773C8.06834 3.77055 8.06834 4.21286 7.79497 4.48567C7.54437 4.73574 7.15105 4.75658 6.87666 4.54819L6.80497 4.48567L4.68746 2.37123L4.68744 45.3014C4.68744 45.6872 4.37403 46 3.98741 46Z"
                    fill="#7E8681"
                />
            </svg>
        </div>
    ) : null;
}
