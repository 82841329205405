/* tslint:disable:max-file-line-count */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import { BudgetItem } from '@mrm/budget';

import { ColumnName } from '@store/budgetPlanning/types';
import type {
    ColumnsVisiblityFilter,
    TableLine,
    TableLineGroup,
    Filters,
    SortingMode,
    ChangeList,
    PageData,
    ColumnsWidth,
} from '@store/budgetPlanning/types';
import { getMiscBudgetItemsState } from '@store/budgetPlanning/miscBudgetItems';
import type { User } from '@store/user/types';
import type { LineCellsParams } from './LayerManager/LayerManager';
import type { DropdownCellOptions } from './CellTypes';
import { TooltipDirection } from './Tooltip';
import { Direction } from './ApproversMenu';
import { RejectMenuDirection } from './RejectMenu';

import type { ScrollbarComponent } from 'sber-marketing-ui';
import { Table } from './Table';
import type { TooltipPosition, DropdownPosition, ApproversMenuPosition, RejectMenuPosition } from './Table';
import type { StoreState } from '@store';
import {
    getBudgetPlanningPageState,
    getPageData,
    getTableFilters,
    getTableLines,
    getFilteredTableLines,
    getLinesGroupedByActivities,
    getDropdownsOptions,
    getUnsavedChanges,
    getBudgetItems,
} from '@store/budgetPlanning/selectors';
import { undoUnsavedChanges, redoUnsavedChanges, setColumnsWidth, setResizingColumnName } from '@store/budgetPlanning';
import { getLoginUser } from '@store/user/selector';
import { ColumnsList } from '../ColumnsConfig';
import { FrameManager, PlainFrameManager, GroupedFrameManager } from './FrameManager';
import { LayerManager } from './LayerManager';

const TABLE_HEADER_HEIGHT = 28;
const MAX_COLUMN_WIDTH = 1500;
const MIN_COLUMN_WIDTH = 45;
const LINE_MENU_WIDTH = 184;
const LINE_HEIGHT = 38;
const TOTAL_LINE_MARGIN = 10;
const BORDER_WIDTH = 1;
const SCROLL_TIMEOUT = 200;

const Z_KEY_CODE = 90;
const Y_KEY_CODE = 89;

interface Props extends Partial<MapProps>, Partial<DispatchProps> {
    maxHeight: number;
    pageContentHeight: number;
    tableOffsetTop: number;
    initColumnScroll: ColumnName;
    onApplyFiltersButtonClick: () => void;
}

interface MapProps {
    pageData: PageData;
    user: User;
    allLines: TableLine[];
    lines: TableLine[];
    lineGroups: TableLineGroup[];
    fixedColumnsNames: ColumnName[];
    columnsVisiblityFilter: ColumnsVisiblityFilter;
    validationStatus: boolean;
    filters: Filters;
    sortingMode: SortingMode;
    columnsWidth: ColumnsWidth;
    resizingColumnName: ColumnName;
    approversMenuLineId: string;
    rejectMenuLineId: string;
    unsavedChanges: ChangeList;
    dropdownsOptions: { [lineId: string]: { [columnName: string]: DropdownCellOptions[] } };
    showNoLinesStub: boolean;
    budgetItemsByActivityId: lodash.Dictionary<BudgetItem[]>;
}

interface DispatchProps {
    setColumnsWidth: (columnsWidth: ColumnsWidth) => void;
    setResizingColumnName: (columnName: ColumnName) => void;
    undoUnsavedChanges: () => void;
    redoUnsavedChanges: () => void;
}

interface State {
    currentFrameIndex: number;
    cellsParams: { [lineId: string]: LineCellsParams };
    visibleColumnsNames: ColumnName[];
    hoveredColumnName: ColumnName;
    hoveredLineId: string;
    hoveredTotalLineActivityId: string;
    rootWidth: number;
    currentDropdownContent: JSX.Element;
    dropdownContentX: number;
    tooltipPosition: TooltipPosition;
    dropdownCellContent: JSX.Element;
    dropdownCellPosition: DropdownPosition;
    approversMenuPosition: ApproversMenuPosition;
    rejectMenuPosition: RejectMenuPosition;
    isResizingColumn: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true }) as any)
export class TableContainer extends React.Component<Props, State> {
    private root: HTMLDivElement;
    private tableHeader: ScrollbarComponent;
    private tableBody: ScrollbarComponent;
    private sumLine: ScrollbarComponent;
    private headerFixedColumns: HTMLDivElement;
    private fixedColumnsLines: HTMLDivElement[] = [];
    private totalLinesContent: HTMLDivElement[] = [];
    private scrollTop: number;
    private scrollLeft: number;
    private dropdownsContentWrapper: HTMLDivElement;
    private dropdownCellContentWrapper: HTMLDivElement;
    private draggedEdgeColumnInitialWidth: number;
    private columnEdgeDragStartX: number;
    private scrollTimer: NodeJS.Timeout;
    private tableIsScrolling: boolean;
    private frameManager: FrameManager;
    private layerManager: LayerManager;

    constructor(props: Props) {
        super(props);

        this.tableIsScrolling = false;

        this.createLayerManager();

        this.state = {
            currentFrameIndex: null,
            cellsParams: null,
            visibleColumnsNames: [],
            hoveredColumnName: null,
            hoveredLineId: null,
            hoveredTotalLineActivityId: null,
            rootWidth: null,
            currentDropdownContent: null,
            dropdownContentX: 0,
            tooltipPosition: null,
            dropdownCellContent: null,
            dropdownCellPosition: null,
            approversMenuPosition: null,
            rejectMenuPosition: null,
            isResizingColumn: false,
        };
    }

    public componentDidMount(): void {
        const rootWidth = this.getRootWidth();

        this.initScrollLeftPosition();

        this.setState(() => ({
            rootWidth,
        }));

        this.updateCellsParams();

        window.addEventListener('resize', this.onPageResize);
        window.addEventListener('keydown', this.onKeydown);
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.onPageResize);
        window.removeEventListener('keydown', this.onKeydown);
    }

    /*tslint:disable:cyclomatic-complexity*/
    public componentDidUpdate(prevProps: Props, prevState: State): void {
        const rootWidthChanged = prevState.rootWidth !== this.state.rootWidth;

        if (rootWidthChanged) {
            this.updateVisibleColumns();
        }

        const sortingModeChanged = this.props.sortingMode !== prevProps.sortingMode;

        const linesChanged = this.checkLinesChanges(this.props.lines, prevProps.lines);
        const lineGroupsChanged = this.checkLineGroupsChanges(this.props.lineGroups, prevProps.lineGroups);

        const maxHeightChanged = this.props.maxHeight !== prevProps.maxHeight;
        const maxHeightIsPositive = this.props.maxHeight > 0;

        if ((linesChanged || lineGroupsChanged || sortingModeChanged || maxHeightChanged) && maxHeightIsPositive) {
            this.updateFrameManager();
        }

        const pageDataChanged = prevProps.pageData !== this.props.pageData;
        const validationStatusChanged = prevProps.validationStatus !== this.props.validationStatus;
        const budgetItemsByActivityIdhaveChanged = !lodash.isEqual(
            prevProps.budgetItemsByActivityId,
            this.props.budgetItemsByActivityId,
        );

        if (pageDataChanged || validationStatusChanged || budgetItemsByActivityIdhaveChanged) {
            this.createLayerManager();
            this.updateCellsParams();
        } else {
            const unsavedChangesChanged = !lodash.isEqual(prevProps.unsavedChanges, this.props.unsavedChanges);
            const dropdownsOptionsChanged = !lodash.isEqual(prevProps.dropdownsOptions, this.props.dropdownsOptions);

            if (unsavedChangesChanged) {
                this.layerManager.applyUnsavedChanges(this.props.unsavedChanges);
            }

            if (dropdownsOptionsChanged) {
                this.layerManager.updateDropdownsOptions(this.props.dropdownsOptions);
            }

            if (unsavedChangesChanged || dropdownsOptionsChanged) {
                this.updateCellsParams();
            }
        }

        const columnsFilterChanged = prevProps.columnsVisiblityFilter !== this.props.columnsVisiblityFilter;

        if (columnsFilterChanged) {
            this.updateVisibleColumns();
            this.updateFixedColumnsPosition();
            this.updateTableScroll();
        }

        const columnsWidthChanged = prevProps.columnsWidth !== this.props.columnsWidth;

        if (columnsWidthChanged) {
            this.updateTableScroll();
        }

        const approversMenuLineIdChanged = prevProps.approversMenuLineId !== this.props.approversMenuLineId;

        if (approversMenuLineIdChanged) {
            this.setState({
                approversMenuPosition: this.makeApproversMenuPosition(),
            });
        }

        const rejectMenuLineIdChanged = prevProps.rejectMenuLineId !== this.props.rejectMenuLineId;

        if (rejectMenuLineIdChanged) {
            this.setState({
                rejectMenuPosition: this.makeRejectMenuPosition(),
            });
        }
    }

    public render(): JSX.Element {
        return React.createElement(Table, {
            showNoLinesStub: this.props.showNoLinesStub,
            frameManager: this.frameManager,
            cellsParams: this.state.cellsParams,
            currentFrameIndex: this.state.currentFrameIndex,
            columnsWidth: this.props.columnsWidth,
            visibleColumnsNames: this.state.visibleColumnsNames,
            hoveredColumnName: this.state.hoveredColumnName,
            hoveredLineId: this.state.hoveredLineId,
            hoveredTotalLineActivityId: this.state.hoveredTotalLineActivityId,
            approversMenuLineId: this.props.approversMenuLineId,
            rejectMenuLineId: this.props.rejectMenuLineId,
            draggedEdgeColumnName: this.props.resizingColumnName,
            currentDropdownContent: this.state.currentDropdownContent,
            dropdownContentX: this.state.dropdownContentX,
            tooltipPosition: this.state.tooltipPosition,
            rootWidth: this.state.rootWidth,
            maxBodyHeight: this.props.maxHeight - TABLE_HEADER_HEIGHT,
            dropdownsContentWrapper: this.dropdownsContentWrapper,
            dropdownCellContentWrapper: this.dropdownCellContentWrapper,
            dropdownCellContent: this.state.dropdownCellContent,
            dropdownCellPosition: this.state.dropdownCellPosition,
            approversMenuPosition: this.state.approversMenuPosition,
            rejectMenuPosition: this.state.rejectMenuPosition,
            isResizingColumn: this.state.isResizingColumn,
            rootRef: this.rootRef,
            headerRef: this.headerRef,
            bodyRef: this.bodyRef,
            sumLineRef: this.sumLineRef,
            headerFixedColumnsRef: this.headerFixedColumnsRef,
            fixedColumnsLinesRef: this.fixedColumnsLinesRef,
            totalLinesContentRef: this.totalLinesContentRef,
            dropdownsContentRef: this.dropdownsContentRef,
            dropdownCellContentRef: this.dropdownCellContentRef,
            onBodyScroll: this.onBodyScroll,
            onHeaderCellMouseEnter: this.onHeaderCellMouseEnter,
            onHeaderCellMouseLeave: this.onHeaderCellMouseLeave,
            onLineMouseEnter: this.onLineMouseEnter,
            onLineMouseLeave: this.onLineMouseLeave,
            onColumnEdgeMousedown: this.onColumnEdgeMousedown,
            onHeaderDropdownClick: this.onHeaderDropdownClick,
            onInfoMouseEnter: this.onTotalLineMouseEnter,
            onInfoMouseLeave: this.onInfoMouseLeave,
            onDropdownCellClick: this.onDropdownCellClick,
            onApplyFiltersButtonClick: this.props.onApplyFiltersButtonClick,
        });
    }

    public resetScroll(): void {
        this.tableHeader?.scrollTo(0, 0);
        this.tableBody?.scrollTo(0, 0);
    }

    @autobind
    protected rootRef(element: HTMLDivElement) {
        this.root = element;
    }

    @autobind
    protected headerRef(component: ScrollbarComponent) {
        this.tableHeader = component;
    }

    @autobind
    protected bodyRef(component: ScrollbarComponent) {
        this.tableBody = component;
    }

    @autobind
    protected sumLineRef(component: ScrollbarComponent) {
        this.sumLine = component;
    }

    @autobind
    protected headerFixedColumnsRef(element: HTMLDivElement) {
        this.headerFixedColumns = element;
    }

    @autobind
    protected fixedColumnsLinesRef(element: HTMLDivElement) {
        if (!this.fixedColumnsLines.some((line) => line === element)) {
            this.fixedColumnsLines.push(element);

            this.updateFixedElementPosition(element);
        }
    }

    @autobind
    protected totalLinesContentRef(element: HTMLDivElement) {
        if (!this.totalLinesContent.some((line) => line === element)) {
            this.totalLinesContent.push(element);
        }
    }

    @autobind
    protected dropdownsContentRef(element: HTMLDivElement) {
        this.dropdownsContentWrapper = element;
    }

    @autobind
    protected dropdownCellContentRef(element: HTMLDivElement) {
        this.dropdownCellContentWrapper = element;
    }

    @autobind
    protected onPageResize() {
        const rootWidth = this.getRootWidth();

        this.setState({
            rootWidth,
        });
    }

    @autobind
    protected onBodyScroll() {
        const scrollIsVertical = this.scrollLeft == this.tableBody.scrollLeft;

        if (scrollIsVertical) {
            this.scrollTop = this.tableBody.scrollTop;

            if (this.frameManager) {
                this.updateCurrentFrame();
            }
        } else {
            this.scrollLeft = this.tableBody.scrollLeft;
            this.sumLine.scrollLeft = this.scrollLeft;

            this.tableHeader.scrollLeft = this.scrollLeft;

            this.updateVisibleColumns();
        }

        this.clearLineHover();
        this.clearTotalLineHover();
        this.updateFixedColumnsPosition();
        this.updateTotalLinesPosition();
        this.setScrollTimer();
    }

    @autobind
    protected onHeaderCellMouseEnter(columnName: ColumnName) {
        if (!this.props.resizingColumnName && !this.state.currentDropdownContent) {
            this.setState({
                hoveredColumnName: columnName,
            });
        }
    }

    @autobind
    protected onHeaderCellMouseLeave() {
        this.setState({
            hoveredColumnName: null,
        });
    }

    @autobind
    protected onLineMouseEnter(id: string) {
        if (!this.props.resizingColumnName && !this.tableIsScrolling) {
            this.setState({
                hoveredLineId: id,
            });
        }
    }

    @autobind
    protected onLineMouseLeave() {
        this.clearLineHover();
    }

    @autobind
    protected onColumnEdgeMousedown(columnName: ColumnName, mouseDownX: number) {
        this.draggedEdgeColumnInitialWidth = this.props.columnsWidth[columnName];
        this.columnEdgeDragStartX = mouseDownX;

        this.props.setResizingColumnName(columnName);

        document.body.style.cursor = 'col-resize';

        document.addEventListener('mousemove', this.onColumnEdgeMousemove);
        document.addEventListener('mouseup', this.onColumnEdgeMouseup);

        this.setState({
            isResizingColumn: true,
        });
    }

    @autobind
    protected onColumnEdgeMousemove(event: MouseEvent) {
        const deltaX = event.clientX - this.columnEdgeDragStartX;

        let columnWidth = this.draggedEdgeColumnInitialWidth + deltaX;

        if (columnWidth > MAX_COLUMN_WIDTH) {
            columnWidth = MAX_COLUMN_WIDTH;
        }

        if (columnWidth < MIN_COLUMN_WIDTH) {
            columnWidth = MIN_COLUMN_WIDTH;
        }

        this.props.setColumnsWidth({
            ...this.props.columnsWidth,
            [this.props.resizingColumnName]: columnWidth,
        });
    }

    @autobind
    protected onColumnEdgeMouseup() {
        this.draggedEdgeColumnInitialWidth = null;
        this.columnEdgeDragStartX = null;

        this.props.setResizingColumnName(null);

        document.body.style.cursor = '';

        document.removeEventListener('mousemove', this.onColumnEdgeMousemove);
        document.removeEventListener('mouseup', this.onColumnEdgeMouseup);

        this.setState({
            isResizingColumn: false,
        });
    }

    @autobind
    protected onHeaderDropdownClick(columnName: string, dropdownContent: JSX.Element) {
        this.setState(() => ({
            currentDropdownContent: dropdownContent,
            dropdownContentX: this.getDropdownX(columnName),
            hoveredColumnName: null,
        }));
    }

    @autobind
    protected onDropdownCellClick(
        columnName: string,
        budgetItemId: string,
        dropdownContent: JSX.Element,
        contentHeight: number,
    ) {
        this.setState(() => ({
            dropdownCellPosition: this.getDropdownPosition(columnName, budgetItemId, contentHeight),
            dropdownCellContent: dropdownContent,
        }));
    }

    @autobind
    protected getDropdownPosition(columnName: string, budgetItemId: string, height: number): DropdownPosition {
        const { y, direction } = this.getDropdownDirectionAndY(budgetItemId, height);

        return {
            height,
            width: this.getDropdownWidth(columnName),
            x: this.getDropdownX(columnName),
            y,
            direction,
        };
    }

    @autobind
    protected getDropdownWidth(columnName: string): number {
        return this.props.columnsWidth[columnName] + BORDER_WIDTH;
    }

    @autobind
    protected getDropdownX(columnName: string): number {
        const { columnsVisiblityFilter, fixedColumnsNames, columnsWidth } = this.props;
        const { rootWidth } = this.state;

        const allColumnsAreHidden = lodash.every(columnsVisiblityFilter, (isChecked) => !isChecked);

        const visibleColumns = allColumnsAreHidden
            ? ColumnsList
            : ColumnsList.filter((column) => columnsVisiblityFilter[column.name]);

        const nonfixedColumns = visibleColumns.filter((item) => !lodash.includes(fixedColumnsNames, item.name));

        const tableLeftScroll = this.tableBody.scrollLeft;

        let columnIndex: number;
        let previousColumnsWidthSum: number;
        let x: number;

        if (fixedColumnsNames.length > 0) {
            const fixedColumns = fixedColumnsNames
                .filter((item) => visibleColumns.some((column) => column.name == item))
                .map((item) => visibleColumns.find((column) => column.name == item));

            const columnIsFixed = lodash.includes(fixedColumnsNames, columnName);

            if (columnIsFixed) {
                columnIndex = lodash.findIndex(fixedColumns, (item) => item.name == columnName);

                previousColumnsWidthSum = fixedColumns
                    .slice(0, columnIndex)
                    .reduce((acc, item) => acc + columnsWidth[item.name], 0);

                x = previousColumnsWidthSum;
            } else {
                const fixedColumnsWidthSum = fixedColumns.reduce((acc, item) => acc + columnsWidth[item.name], 0);

                columnIndex = lodash.findIndex(nonfixedColumns, (item) => item.name == columnName);

                previousColumnsWidthSum = nonfixedColumns
                    .slice(0, columnIndex)
                    .reduce((acc, item) => acc + columnsWidth[item.name], 0);

                x = fixedColumnsWidthSum + previousColumnsWidthSum - tableLeftScroll;
            }
        } else {
            columnIndex = lodash.findIndex(nonfixedColumns, (item) => item.name == columnName);

            previousColumnsWidthSum = nonfixedColumns
                .slice(0, columnIndex)
                .reduce((acc, item) => acc + columnsWidth[item.name], 0);

            x = previousColumnsWidthSum - tableLeftScroll;
        }

        const dropdownWidth = this.getDropdownWidth(columnName);

        if (x < 0) {
            x = 0;
        }

        if (x + dropdownWidth > rootWidth) {
            x = rootWidth - dropdownWidth;
        }

        return x;
    }

    @autobind
    protected getDropdownDirectionAndY(
        budgetItemId: string,
        height: number,
    ): { y: number; direction: TooltipDirection } {
        const { lines, lineGroups, pageContentHeight, tableOffsetTop, sortingMode } = this.props;

        const tableIsSortedByActivityName = sortingMode.columnName == ColumnName.ActivityName;

        const tableScrollY = this.tableBody.scrollTop;

        let y = 0;

        if (tableIsSortedByActivityName) {
            const currentActivityIndex = lodash.findIndex(lineGroups, (item) =>
                item.lines.some((budgetItem) => budgetItem.id === budgetItemId),
            );

            const previousLineGroups = lineGroups.slice(0, currentActivityIndex);
            const previousLinesCount = previousLineGroups.reduce(
                (acc, item) => acc + item.lines.length + (tableIsSortedByActivityName ? 1 : 0),
                0,
            );

            const hoveredGroupLines = lineGroups[currentActivityIndex].lines;

            const activityBudgetItems = lodash.findIndex(hoveredGroupLines, (item) => item.id == budgetItemId);
            const activityBudgetItemsAmount = hoveredGroupLines.slice(0, activityBudgetItems + 1).length;

            y =
                (previousLinesCount + activityBudgetItemsAmount) * LINE_HEIGHT +
                TABLE_HEADER_HEIGHT -
                tableScrollY -
                BORDER_WIDTH +
                previousLineGroups.length * TOTAL_LINE_MARGIN;
        } else {
            const currentLineIndex = lodash.findIndex(lines, (item) => item.id == budgetItemId);

            const previousLinesCount = currentLineIndex;

            const previousLinesHeight = previousLinesCount * LINE_HEIGHT;

            y = TABLE_HEADER_HEIGHT + previousLinesHeight + LINE_HEIGHT - tableScrollY - BORDER_WIDTH;
        }

        const shouldOpenUpwards = pageContentHeight < tableOffsetTop + y + height;

        if (shouldOpenUpwards) {
            y -= LINE_HEIGHT - BORDER_WIDTH + height;
        }

        return {
            y,
            direction: shouldOpenUpwards ? TooltipDirection.Up : TooltipDirection.Down,
        };
    }

    @autobind
    protected onTotalLineMouseEnter(lineId: string) {
        if (!this.tableIsScrolling) {
            this.setState(() => ({
                hoveredTotalLineActivityId: lineId,
                tooltipPosition: this.makeTooltipPosition(lineId),
            }));
        }
    }

    @autobind
    protected onInfoMouseLeave() {
        this.clearTotalLineHover();
    }

    @autobind
    protected onKeydown(event: KeyboardEvent) {
        const ctrlZ = event.keyCode == Z_KEY_CODE && (event.ctrlKey || event.metaKey) && !event.shiftKey;
        const ctrlY = event.keyCode == Y_KEY_CODE && (event.ctrlKey || event.metaKey) && !event.shiftKey;
        const ctrlShiftZ = event.keyCode == Z_KEY_CODE && (event.ctrlKey || event.metaKey) && event.shiftKey;

        if (ctrlZ) {
            this.props.undoUnsavedChanges();
            event.preventDefault();
        }

        if (ctrlShiftZ || ctrlY) {
            this.props.redoUnsavedChanges();
            event.preventDefault();
        }
    }

    private createLayerManager() {
        this.layerManager = LayerManager.getInstance({
            lines: this.props.allLines,
            dropdownsOptions: this.props.dropdownsOptions,
            unsavedChanges: this.props.unsavedChanges,
            validationStatus: this.props.validationStatus,
            budgetItemsByActivityId: this.props.budgetItemsByActivityId,
        });
    }

    private updateCellsParams() {
        const cellsParams = this.layerManager.getCellsParams();

        if (!lodash.isEqual(cellsParams, this.state.cellsParams)) {
            this.setState({
                cellsParams: { ...cellsParams },
            });
        }
    }

    private makeTooltipPosition(hoveredInfoLineId: string): TooltipPosition {
        const { lines, lineGroups, pageContentHeight, tableOffsetTop, sortingMode } = this.props;

        if (hoveredInfoLineId == null) {
            return null;
        }

        const tableIsSortedByActivityName = sortingMode.columnName == ColumnName.ActivityName;

        let y: number;

        const tableScrollY = this.tableBody.scrollTop;

        if (tableIsSortedByActivityName) {
            const hoveredGroupIndex = lodash.findIndex(lineGroups, (group) =>
                group.lines.some((line) => line.id == hoveredInfoLineId),
            );

            const previousLineGroups = lineGroups.slice(0, hoveredGroupIndex);

            const previousLinesCount = previousLineGroups.reduce((acc, item) => acc + item.lines.length + 1, 0);

            const hoveredGroupLines = lineGroups[hoveredGroupIndex].lines;

            lodash.findIndex(hoveredGroupLines, (item) => item.id == hoveredInfoLineId);

            const hoveredGroupPreviousLinesCount = lodash.findIndex(
                hoveredGroupLines,
                (item) => item.id == hoveredInfoLineId,
            );

            y =
                (previousLinesCount + hoveredGroupPreviousLinesCount) * LINE_HEIGHT +
                previousLineGroups.length * TOTAL_LINE_MARGIN +
                TABLE_HEADER_HEIGHT -
                tableScrollY;
        } else {
            const previousLinesCount = lodash.findIndex(lines, (item) => item.id == hoveredInfoLineId);

            y = previousLinesCount * LINE_HEIGHT + TABLE_HEADER_HEIGHT - tableScrollY;
        }

        const direction =
            pageContentHeight / 2 > tableOffsetTop + y + LINE_HEIGHT / 2 ? TooltipDirection.Down : TooltipDirection.Up;

        return {
            direction,
            y,
        };
    }

    private makeApproversMenuPosition(): ApproversMenuPosition {
        const { approversMenuLineId, sortingMode, lineGroups, lines, pageContentHeight, tableOffsetTop } = this.props;

        if (approversMenuLineId == null) {
            return null;
        }

        let y: number;

        const tableIsSortedByActivityName = sortingMode.columnName == ColumnName.ActivityName;

        const tableScrollY = this.tableBody.scrollTop;

        if (tableIsSortedByActivityName) {
            const currentGroupIndex = lodash.findIndex(lineGroups, (group) =>
                group.lines.some((item) => item.id == approversMenuLineId),
            );

            const previousLineGroups = lineGroups.slice(0, currentGroupIndex);

            const previousLinesCount = previousLineGroups.reduce((acc, item) => acc + item.lines.length + 1, 0) + 0;

            const currentLineIndex = lodash.findIndex(
                lineGroups[currentGroupIndex].lines,
                (line) => line.id == approversMenuLineId,
            );

            y =
                (previousLinesCount + currentLineIndex) * LINE_HEIGHT +
                previousLineGroups.length * TOTAL_LINE_MARGIN +
                TABLE_HEADER_HEIGHT -
                tableScrollY;
        } else {
            const currentLineIndex = lodash.findIndex(lines, (line) => line.id == approversMenuLineId);

            y = currentLineIndex * LINE_HEIGHT + TABLE_HEADER_HEIGHT - tableScrollY;
        }

        const direction = pageContentHeight / 2 > tableOffsetTop + y + LINE_HEIGHT / 2 ? Direction.Down : Direction.Up;

        return {
            direction,
            y,
        };
    }

    private makeRejectMenuPosition(): RejectMenuPosition {
        const { rejectMenuLineId, sortingMode, lineGroups, lines, pageContentHeight, tableOffsetTop } = this.props;

        if (rejectMenuLineId == null) {
            return null;
        }

        let y: number;

        const tableIsSortedByActivityName = sortingMode.columnName == ColumnName.ActivityName;

        const tableScrollY = this.tableBody.scrollTop;

        if (tableIsSortedByActivityName) {
            const currentGroupIndex = lodash.findIndex(lineGroups, (group) =>
                group.lines.some((item) => item.id == rejectMenuLineId),
            );

            const previousLineGroups = lineGroups.slice(0, currentGroupIndex);

            const previousLinesCount = previousLineGroups.reduce((acc, item) => acc + item.lines.length + 1, 0) + 0;

            const currentLineIndex = lodash.findIndex(
                lineGroups[currentGroupIndex].lines,
                (line) => line.id == rejectMenuLineId,
            );

            y =
                (previousLinesCount + currentLineIndex) * LINE_HEIGHT +
                previousLineGroups.length * TOTAL_LINE_MARGIN +
                TABLE_HEADER_HEIGHT -
                tableScrollY;
        } else {
            const currentLineIndex = lodash.findIndex(lines, (line) => line.id == rejectMenuLineId);

            y = currentLineIndex * LINE_HEIGHT + TABLE_HEADER_HEIGHT - tableScrollY;
        }

        const direction =
            pageContentHeight / 2 > tableOffsetTop + y + LINE_HEIGHT / 2
                ? RejectMenuDirection.Down
                : RejectMenuDirection.Up;

        return {
            direction,
            y,
        };
    }

    private getRootWidth(): number {
        return this.root.getBoundingClientRect().width;
    }

    private checkLinesChanges(newLines: TableLine[], oldLines: TableLine[]): boolean {
        return !lodash.isEqual(newLines, oldLines);
    }

    private checkLineGroupsChanges(newGroups: TableLineGroup[], oldGroups: TableLineGroup[]): boolean {
        return !lodash.isEqual(newGroups, oldGroups);
    }

    private updateFrameManager() {
        const { sortingMode, maxHeight, lines, lineGroups } = this.props;

        const sortedByActivityName = sortingMode.columnName == ColumnName.ActivityName;

        const scrollOffset = this.tableBody ? this.tableBody.scrollTop : 0;

        if (sortedByActivityName) {
            this.frameManager = new GroupedFrameManager({
                viewportHeight: maxHeight,
                scrollOffset,
                items: lineGroups,
            });
        } else {
            this.frameManager = new PlainFrameManager({
                viewportHeight: maxHeight,
                scrollOffset,
                items: lines,
            });
        }

        const newFrameIndex = this.frameManager.getCurrentFrameIndex();

        if (newFrameIndex !== this.state.currentFrameIndex) {
            this.setState({
                currentFrameIndex: this.frameManager.getCurrentFrameIndex(),
            });
        }

        this.forceUpdate();
    }

    private clearTotalLineHover() {
        if (this.state.hoveredTotalLineActivityId !== null) {
            this.setState(() => ({
                hoveredTotalLineActivityId: null,
                tooltipPosition: null,
            }));
        }
    }

    private clearLineHover() {
        if (this.state.hoveredLineId !== null) {
            this.setState(() => ({
                hoveredLineId: null,
            }));
        }
    }

    private setScrollTimer() {
        if (this.scrollTimer) {
            clearTimeout(this.scrollTimer);
        }

        this.tableIsScrolling = true;

        this.scrollTimer = setTimeout(() => {
            this.tableIsScrolling = false;
        }, SCROLL_TIMEOUT);
    }

    private updateCurrentFrame() {
        const scrollOffset = this.scrollTop;

        this.frameManager.setScrollOffset(scrollOffset);
        const frameIndex = this.frameManager.getCurrentFrameIndex();

        if (frameIndex !== this.state.currentFrameIndex) {
            this.setState({
                currentFrameIndex: frameIndex,
            });
        }
    }

    private updateFixedColumnsPosition() {
        this.updateFixedElementPosition(this.headerFixedColumns);

        this.fixedColumnsLines.forEach((item) => this.updateFixedElementPosition(item));
    }

    private updateFixedElementPosition(element: HTMLDivElement) {
        this.setElementLeftPosition(element, this.scrollLeft);
    }

    private updateTotalLinesPosition() {
        this.totalLinesContent.forEach((line) => this.setElementLeftPosition(line, this.scrollLeft));
    }

    private setElementLeftPosition(element: HTMLElement, position: number) {
        if (element) {
            element.style.transform = `translateX(${position}px)`;
        }
    }

    private updateTableScroll() {
        const { columnsVisiblityFilter, columnsWidth } = this.props;
        const { rootWidth } = this.state;

        const allColumnsAreHidden = lodash.values(columnsVisiblityFilter).every((item) => item === false);

        const visibleColumns = allColumnsAreHidden
            ? ColumnsList
            : ColumnsList.filter((item) => columnsVisiblityFilter[item.name]);

        const tableWidth = visibleColumns.reduce((acc, item) => acc + columnsWidth[item.name], 0) + LINE_MENU_WIDTH;

        const currentScrollX = this.tableBody.scrollLeft;
        const currentScrollY = this.tableBody.scrollTop;

        if (currentScrollX + rootWidth > tableWidth) {
            const newScrollX = tableWidth - rootWidth;

            this.tableHeader.scrollTo(currentScrollY, newScrollX);
            this.tableBody.scrollTo(currentScrollY, newScrollX);
        }
    }

    private updateVisibleColumns() {
        const newVisibleColumns = this.calculateVisibleColumns(this.scrollLeft);

        const visibleColumnsChanged = !lodash.isEqual(newVisibleColumns, this.state.visibleColumnsNames);

        if (visibleColumnsChanged) {
            this.setState({
                visibleColumnsNames: newVisibleColumns,
            });
        }
    }

    private calculateVisibleColumns(scrollOffset: number): ColumnName[] {
        const { columnsVisiblityFilter, columnsWidth } = this.props;
        const { rootWidth } = this.state;

        const allColumnsAreHidden = lodash.every(columnsVisiblityFilter, (item) => item === false);

        const filteredColumns = allColumnsAreHidden
            ? ColumnsList
            : ColumnsList.filter((column) => columnsVisiblityFilter[column.name]);

        const viewportStart = scrollOffset;
        const viewportEnd = scrollOffset + rootWidth;

        let startIndex: number = null;
        let endIndex: number = null;

        let columnIndex = 0;
        let widthSum = 0;

        while (endIndex === null && filteredColumns[columnIndex]) {
            const columnName = filteredColumns[columnIndex].name;

            widthSum += columnsWidth[columnName];

            if (startIndex === null && widthSum >= viewportStart) {
                startIndex = columnIndex - 1;

                if (startIndex < 0) {
                    startIndex = 0;
                }
            }

            if (startIndex !== null && widthSum >= viewportEnd) {
                endIndex = columnIndex + 1;

                if (endIndex > ColumnsList.length - 1) {
                    endIndex = ColumnsList.length - 1;
                }
            }

            columnIndex++;
        }

        if (endIndex === null) {
            endIndex = columnIndex + 1;
        }

        return filteredColumns.slice(startIndex, endIndex + 1).map((item) => item.name);
    }

    private initScrollLeftPosition() {
        const list = ColumnsList.filter((item) => !item.hiddenByDefault);

        const columnName = this.props.initColumnScroll;
        const column = list.find((item) => item.name == columnName);
        const columnIndex = list.indexOf(column);

        const scrollLeftPosition =
            columnIndex > 0
                ? list.slice(0, columnIndex).reduce((acc, item) => acc + item.width, 0) + column.width / 2
                : 0;

        this.scrollLeft = scrollLeftPosition - 500 > 0 ? scrollLeftPosition - 500 : 0;

        window.setTimeout(() => {
            if (this.tableHeader && this.tableBody) {
                this.tableHeader.centerAt(0, scrollLeftPosition);
                this.tableBody.centerAt(0, scrollLeftPosition);
            }
        }, 100);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { fixedColumnsNames, columnsWidth, resizingColumnName, approversMenuLineId, rejectMenuLineId } =
        getBudgetPlanningPageState(state);
    const { columnsVisiblityFilter, filters, sortingMode, validationStatus } = getTableFilters(state);

    return {
        pageData: getPageData(state),
        user: getLoginUser(state),
        allLines: getTableLines(state),
        lines: getFilteredTableLines(state),
        lineGroups: getLinesGroupedByActivities(state),
        fixedColumnsNames,
        columnsVisiblityFilter,
        validationStatus,
        filters,
        sortingMode,
        columnsWidth,
        resizingColumnName,
        approversMenuLineId,
        rejectMenuLineId,
        unsavedChanges: getUnsavedChanges(state),
        dropdownsOptions: getDropdownsOptions(state),
        showNoLinesStub: !getBudgetItems(state).length,
        budgetItemsByActivityId: getMiscBudgetItemsState(state).stores.byActivityId,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setColumnsWidth,
            setResizingColumnName,
            undoUnsavedChanges,
            redoUnsavedChanges,
        },
        dispatch,
    );
}
