import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';
import * as styles from './styles.scss';

interface Props {
    onClick: () => void;
    disabled: boolean;
}

export const ReloadButton: React.FC<Props> = ({ disabled, onClick }) => {
    return (
        <div className={classNames(styles.root, disabled && styles.disabled)} onClick={disabled ? () => {} : onClick}>
            <Icon type={IconType.SPINNER} />
        </div>
    );
};
