import * as React from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { PASTE_COMMAND, COMMAND_PRIORITY_HIGH } from 'lexical';

interface Props {
    onPaste?: React.ClipboardEventHandler<HTMLTextAreaElement>;
}

export const PastePlugin = ({ onPaste }: Props) => {
    const [editor] = useLexicalComposerContext();

    React.useEffect(() => {
        if (onPaste) {
            editor.registerCommand(PASTE_COMMAND, onPaste, COMMAND_PRIORITY_HIGH);
        }
    }, []);

    return null;
};
