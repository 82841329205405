// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionLineSelection-LinesDescription-_LinesDescription__root{margin-top:13px;font-family:'Open Sans'}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionLineSelection-LinesDescription-_LinesDescription__cellDescription{position:relative}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionLineSelection-LinesDescription-_LinesDescription__cellDescription:not(:last-of-type){margin-bottom:6px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/InternalTransitionLineSelection/LinesDescription/LinesDescription.scss"],"names":[],"mappings":"AAAA,kJACI,eAAgB,CAEhB,uBAAwB,CAC3B,6JAGG,iBAAkB,CADtB,gLAIQ,iBAAkB","sourcesContent":[".root {\n    margin-top: 13px;\n\n    font-family: 'Open Sans';\n}\n\n.cellDescription {\n    position: relative;\n\n    &:not(:last-of-type) {\n        margin-bottom: 6px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionLineSelection-LinesDescription-_LinesDescription__root",
	"cellDescription": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionLineSelection-LinesDescription-_LinesDescription__cellDescription"
};
export default ___CSS_LOADER_EXPORT___;
