// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-ColumnsFiltersDropdown-Content-List-Item-_styles__root{position:relative;width:100%}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetPlanning/FiltersColumnsTagsBar/ColumnsFiltersDropdown/Content/List/Item/styles.scss"],"names":[],"mappings":"AAAA,kIACI,iBAAkB,CAClB,UAAW","sourcesContent":[".root {\n    position: relative;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-ColumnsFiltersDropdown-Content-List-Item-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
