import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Success } from 'typescript-fsa';
import { keyBy, omit, values, cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import type {
    ChangeOptionTextSelectionParams,
    ChangeTextOptionValueParams,
    Features,
    FeaturesId,
    FormState,
    LoadFeaturesParams,
    LoadFeaturesResult,
    LoadTextsParams,
    LoadTextsResult,
    UpdateTextContentParams,
    UpdateTextContentResult,
    UpdateTextTitleParams,
    UpdateTextTitleResult,
} from './types';
import { LoadingStatus } from './types';
import * as syncActions from './actions/sync';
import * as asyncActions from './actions/async';
import { SELECTED_TEXT_OPTION_LENGTH_RANGE } from './const';

export const initialState: FormState = {
    mode: 'editingParams',
    params: {
        loading: LoadingStatus.NOT_LOADED,
        featuresMap: {},
    },
    texts: {
        loading: LoadingStatus.NOT_LOADED,
        options: {
            titles: {},
            contents: {},
        },
        data: {
            titles: {},
            contents: {},
        },
    },
};

export class Reducer {
    public static addFeatures(state: FormState): FormState {
        const createdFeatures = {
            id: uuidv4(),
            value: '',
        };

        return {
            ...state,
            params: {
                ...state.params,
                featuresMap: {
                    ...state.params.featuresMap,
                    [createdFeatures.id]: createdFeatures,
                },
            },
        };
    }

    public static removeFeatures(state: FormState, payload: FeaturesId): FormState {
        return {
            ...state,
            params: {
                ...state.params,
                featuresMap: omit(state.params.featuresMap, payload),
            },
        };
    }

    public static changeFeatures(state: FormState, payload: Features): FormState {
        return {
            ...state,
            params: {
                ...state.params,
                featuresMap: {
                    ...state.params.featuresMap,
                    [payload.id]: {
                        ...state.params.featuresMap[payload.id],
                        value: payload.value,
                    },
                },
            },
        };
    }

    public static changeTextOptionSelection(state: FormState, payload: ChangeOptionTextSelectionParams): FormState {
        const { id, selected, type } = payload;

        const options = {
            ...state.texts.options,
            [type]: {
                ...state.texts.options[type],
                [id]: {
                    ...state.texts.options[type][id],
                    selected,
                },
            },
        };

        const selectedOptionsType = values(options[type]).filter(({ selected }) => selected);

        return selectedOptionsType.length < SELECTED_TEXT_OPTION_LENGTH_RANGE.MAX
            ? {
                  ...state,
                  texts: {
                      ...state.texts,
                      options: {
                          ...options,
                          [type]: keyBy(
                              values(options[type]).map((option) => ({ ...option, disabled: false })),
                              'id',
                          ),
                      },
                  },
              }
            : {
                  ...state,
                  texts: {
                      ...state.texts,
                      options: {
                          ...options,
                          [type]: keyBy(
                              values(options[type]).map((option) => ({ ...option, disabled: !option.selected })),
                              'id',
                          ),
                      },
                  },
              };
    }

    public static changeTextOptionValue(state: FormState, payload: ChangeTextOptionValueParams): FormState {
        const { id, value, type } = payload;

        return {
            ...state,
            texts: {
                ...state.texts,
                options: {
                    ...state.texts.options,
                    [type]: {
                        ...state.texts.options[type],
                        [id]: {
                            ...state.texts.options[type][id],
                            value,
                        },
                    },
                },
            },
        };
    }

    public static loadFeaturesStarted(state: FormState): FormState {
        return {
            ...state,
            params: {
                ...state.params,
                loading: LoadingStatus.LOADING,
            },
        };
    }

    public static loadFeaturesDone(
        state: FormState,
        payload: Success<LoadFeaturesParams, LoadFeaturesResult>,
    ): FormState {
        return {
            ...state,
            params: {
                ...state.params,
                loading: LoadingStatus.LOADED,
                featuresMap: {
                    ...state.params.featuresMap,
                    ...payload.result.loadedFeaturesMap,
                },
            },
        };
    }

    public static loadFeaturesFailed(state: FormState): FormState {
        return {
            ...state,
            params: {
                ...state.params,
                loading: LoadingStatus.ERROR,
            },
        };
    }

    public static loadTextsStarted(state: FormState): FormState {
        return {
            ...state,
            mode: 'editingTexts',
            texts: {
                ...state.texts,
                loading: LoadingStatus.LOADING,
            },
        };
    }

    public static loadTextsDone(state: FormState, payload: Success<LoadTextsParams, LoadTextsResult>): FormState {
        const { options, data } = payload.result;

        return {
            ...state,
            texts: {
                ...state.texts,
                loading: LoadingStatus.LOADED,
                options,
                data,
            },
        };
    }

    public static loadTextsFailed(state: FormState): FormState {
        return {
            ...state,
            params: {
                ...state.params,
                loading: LoadingStatus.ERROR,
            },
        };
    }

    public static updateTextTitleStarted(state: FormState): FormState {
        return {
            ...state,
            texts: {
                ...state.texts,
                loading: LoadingStatus.LOADING,
            },
        };
    }

    public static updateTextTitleDone(
        state: FormState,
        payload: Success<UpdateTextTitleParams, UpdateTextTitleResult>,
    ): FormState {
        const { updatedTextTitle, removedTextDataTitleId, textData } = payload.result;

        return {
            ...state,
            texts: {
                ...state.texts,
                loading: LoadingStatus.LOADED,
                options: {
                    titles: {
                        ...state.texts.options.titles,
                        [updatedTextTitle.id]: {
                            ...state.texts.options.titles[updatedTextTitle.id],
                            value: updatedTextTitle.value,
                        },
                    },
                    contents: state.texts.options.contents,
                },
                data: {
                    titles: {
                        ...omit({ ...state.texts.data.titles, ...textData.titles }, removedTextDataTitleId),
                    },
                    contents: {
                        ...state.texts.data.contents,
                        ...textData.contents,
                    },
                },
            },
        };
    }

    public static updateTextContentStarted(state: FormState): FormState {
        return {
            ...state,
            texts: {
                ...state.texts,
                loading: LoadingStatus.LOADING,
            },
        };
    }

    public static updateTextContentDone(
        state: FormState,
        payload: Success<UpdateTextContentParams, UpdateTextContentResult>,
    ): FormState {
        const { updatedTextContent, removedTextDataContentId, textData } = payload.result;

        return {
            ...state,
            texts: {
                ...state.texts,
                loading: LoadingStatus.LOADED,
                options: {
                    ...state.texts.options,
                    contents: {
                        ...state.texts.options.contents,
                        [updatedTextContent.id]: {
                            ...state.texts.options.contents[updatedTextContent.id],
                            value: updatedTextContent.value,
                        },
                    },
                },
                data: {
                    titles: {
                        ...state.texts.data.titles,
                        ...textData.titles,
                    },
                    contents: {
                        ...omit({ ...state.texts.data.contents, ...textData.contents }, removedTextDataContentId),
                    },
                },
            },
        };
    }

    public static resetTexts(state: FormState): FormState {
        return {
            ...state,
            mode: 'editingParams',
            texts: {
                loading: LoadingStatus.NOT_LOADED,
                options: {
                    titles: {},
                    contents: {},
                },
                data: {
                    titles: {},
                    contents: {},
                },
            },
        };
    }

    public static reset(): FormState {
        return { ...cloneDeep(initialState) };
    }
}

export const generationTextFormReducer = reducerWithInitialState(initialState)
    .case(syncActions.addFeatures, Reducer.addFeatures)
    .case(syncActions.removeFeatures, Reducer.removeFeatures)
    .case(syncActions.reset, Reducer.reset)
    .case(syncActions.resetTexts, Reducer.resetTexts)
    .case(syncActions.changeFeatures, Reducer.changeFeatures)
    .case(syncActions.changeTextOptionSelection, Reducer.changeTextOptionSelection)
    .case(syncActions.changeTextOptionValue, Reducer.changeTextOptionValue)
    .case(asyncActions.loadFeatures.started, Reducer.loadFeaturesStarted)
    .case(asyncActions.loadFeatures.done, Reducer.loadFeaturesDone)
    .case(asyncActions.loadFeatures.failed, Reducer.loadFeaturesFailed)
    .case(asyncActions.loadTexts.started, Reducer.loadTextsStarted)
    .case(asyncActions.loadTexts.done, Reducer.loadTextsDone)
    .case(asyncActions.loadTexts.failed, Reducer.loadTextsFailed)
    .case(asyncActions.updateTextTitle.started, Reducer.updateTextTitleStarted)
    .case(asyncActions.updateTextTitle.done, Reducer.updateTextTitleDone)
    .case(asyncActions.updateTextContent.started, Reducer.updateTextContentStarted)
    .case(asyncActions.updateTextContent.done, Reducer.updateTextContentDone);
