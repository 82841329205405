import * as React from 'react';
import classNames from 'classnames';
import * as styles from './CommentLikeCard.scss';

interface Props {
    children?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
}

export function CommentLikeCard({ children, className, style }: Props): JSX.Element {
    return (
        <div className={classNames(styles.root, className)} style={style}>
            {children}
        </div>
    );
}
