import * as React from 'react';
import { useSelector } from 'react-redux';
import { groupBy, uniqBy, isEmpty } from 'lodash';

import { StoreState } from '@store';
import { getLoginUser, userHasBudgetAccess as userHasBudgetAccessSelector } from '@store/user';

import { WithProjectLinkedBudgetItems, WithProjectLinkedBudgetItemProps } from '@common/queries';

import { Budget } from './types';
import { BudgetItemLinksFetcher, BudgetItemLinksData } from './BudgetItemLinksFetcher';
import { BudgetsFetcher, BudgetsData } from './BudgetsFetcher';
import { LinksContainerWithToggle, LinksContainerWIthTogglePreloader } from './LinksContainerWithToggle';

import * as styles from './BudgetsTab.scss';

interface Props {
    activityId: number;
}

function getBudgetKey(budget: Budget): string {
    return `${budget.year}-${budget.organizationIds[0]}`;
}

const CURR_YEAR = new Date().getFullYear();

function useBudgetsToDisplay(): [
    Budget[],
    (budgetItemLinksData: BudgetItemLinksData, budgetsData: BudgetsData) => void,
] {
    const [budgetsToDisplay, setBudgetsToDisplay] = React.useState<Budget[]>(null);

    function updateBudgetsToDisplay(budgetItemLinksData: BudgetItemLinksData, budgetsData: BudgetsData) {
        if (budgetItemLinksData?.items?.length || budgetsData?.budgets?.length) {
            const itemsByYearAndOrganization = groupBy(budgetItemLinksData.items, (item) =>
                getBudgetKey(item.budgetItem.budget),
            );
            const budgetsToUse: Budget[] = uniqBy(
                [
                    ...(budgetsData.budgets || []),
                    ...(budgetItemLinksData.items?.map((item) => item.budgetItem.budget) || []),
                ],
                (budget) => budget.id,
            );
            const yearsToUse = [CURR_YEAR, CURR_YEAR + 1];
            const budgetsToDisplay = budgetsToUse.filter(
                (item) => yearsToUse.includes(item.year) || !isEmpty(itemsByYearAndOrganization[getBudgetKey(item)]),
            );

            setBudgetsToDisplay(budgetsToDisplay);
        }
    }

    return [budgetsToDisplay, updateBudgetsToDisplay];
}

function useBudgetsTab() {
    const userOrganizationId = useSelector((state: StoreState) => getLoginUser(state).attributes.organizationId);
    const userHasBudgetAccess = useSelector((state: StoreState) => userHasBudgetAccessSelector(state));

    const [linkedBudgetItemsData, setLinkedBudgetItemsData_] = React.useState<WithProjectLinkedBudgetItemProps>({
        loading: true,
        linkIds: null,
    });
    const [budgetItemLinksData, setBudgetItemLinksData_] = React.useState<BudgetItemLinksData>({
        loading: true,
        items: null,
        organizationIds: [userOrganizationId],
    });
    const [budgetsData, setBudgetsData_] = React.useState<BudgetsData>({ loading: true, budgets: null });
    const [budgetsToDisplay, updateBudgetsToDisplay] = useBudgetsToDisplay();

    function setLinkedBudgetItemsData(linkedBudgetItemsData: WithProjectLinkedBudgetItemProps) {
        setLinkedBudgetItemsData_(linkedBudgetItemsData);

        if (!linkedBudgetItemsData.linkIds.length) {
            setBudgetItemLinksData_({ loading: false, items: null, organizationIds: [userOrganizationId] });
            setBudgetsData_({ loading: false, budgets: budgetsData.budgets });
        }
    }

    function setBudgetItemLinksData(budgetItemLinksData: BudgetItemLinksData) {
        setBudgetItemLinksData_(budgetItemLinksData);

        updateBudgetsToDisplay(budgetItemLinksData, budgetsData);
    }

    function setBudgetsData(budgetsData: BudgetsData) {
        setBudgetsData_(budgetsData);

        updateBudgetsToDisplay(budgetItemLinksData, budgetsData);
    }

    const budgetItemLinksByBudget = React.useMemo(
        () =>
            budgetItemLinksData?.items
                ? groupBy(budgetItemLinksData.items, (budgetItemLink) => budgetItemLink.budgetItem.budget.id)
                : {},
        [budgetItemLinksData?.items],
    );

    const showPreloader = linkedBudgetItemsData?.loading || budgetItemLinksData?.loading || budgetsData?.loading;

    return {
        showPreloader,
        userOrganizationId,
        userHasBudgetAccess,
        budgetsToDisplay,
        budgetItemLinksByBudget,
        linkedBudgetItemsData,
        setLinkedBudgetItemsData,
        budgetItemLinksData,
        setBudgetItemLinksData,
        setBudgetsData,
    };
}

export function BudgetsTab({ activityId }: Props): JSX.Element {
    const {
        showPreloader,
        userOrganizationId,
        userHasBudgetAccess,
        budgetsToDisplay,
        budgetItemLinksByBudget,
        linkedBudgetItemsData,
        setLinkedBudgetItemsData,
        budgetItemLinksData,
        setBudgetItemLinksData,
        setBudgetsData,
    } = useBudgetsTab();

    return (
        <React.Fragment>
            <div className={styles.root}>
                {showPreloader ? (
                    <LinksContainerWIthTogglePreloader />
                ) : (
                    budgetsToDisplay?.map((budget) => (
                        <LinksContainerWithToggle
                            key={budget.id}
                            activityId={activityId}
                            userHasBudgetAccess={userHasBudgetAccess}
                            budget={budget}
                            links={budgetItemLinksByBudget[budget.id]}
                            refetchLinks={linkedBudgetItemsData.refetch}
                        />
                    ))
                )}
            </div>

            <WithProjectLinkedBudgetItems forwardData={setLinkedBudgetItemsData} activityId={activityId} />
            <BudgetsFetcher forwardData={setBudgetsData} organizationIds={budgetItemLinksData?.organizationIds} />
            <BudgetItemLinksFetcher
                forwardData={setBudgetItemLinksData}
                userOrganizationId={userOrganizationId}
                linkIds={linkedBudgetItemsData?.linkIds}
            />
        </React.Fragment>
    );
}
