import * as React from 'react';
import { StaticSkeleton as Skeleton } from 'sber-marketing-ui';

import * as styles from './NewsColumnPreloader.scss';

export function NewsColumnPreloader(): JSX.Element {
    return (
        <React.Fragment>
            <NewsItem />
            <NewsItem />
            <NewsItem />
        </React.Fragment>
    );
}

export function NewsItem(): JSX.Element {
    return (
        <React.Fragment>
            <div className={styles.newsItem}>
                <Skeleton
                    loadingClassName={styles.newsItemFirstSkeleton}
                    customStyle={{
                        borderRadius: '8px',
                    }}
                />
                <Skeleton loadingClassName={styles.newsItemSecondSkeleton} />
                <Skeleton loadingClassName={styles.newsItemThirdSkeleton} />
                <Skeleton loadingClassName={styles.newsItemFourthSkeleton} />
                <Skeleton loadingClassName={styles.newsItemFifthSkeleton} />

                <div className={styles.horizontalBar} />

                <Skeleton loadingClassName={styles.newsItemSixthSkeleton} />
                <Skeleton loadingClassName={styles.newsItemSeventhSkeleton} />

                <div className={styles.newsItemDate}>
                    <Skeleton loadingClassName={styles.newsItemEighthSkeleton} />
                </div>
            </div>

            <div className={styles.horizontalBar} />
        </React.Fragment>
    );
}
