import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { UserApi } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';

import * as actions from './actions';
import { LoadUsersParams } from './types';
import { getLoadingStatus, getUserById, getFetchersCount } from './selectors';

export const loadUsers = bindThunkAction<StoreState, LoadUsersParams, void, Error>(
    actions.loadUsersAsync,
    async (params, dispatch, getState) => {
        const { store } = params;
        let { ids } = params;

        const setStatus: (status: LoadingStatus) => void = (status) =>
            dispatch(
                actions.setLoadingStatus({
                    store,
                    status,
                }),
            );
        const state = getState();
        const loadingStatus = getLoadingStatus(state, store);

        try {
            let users;

            if (ids) {
                const notLoadedUserIds = ids.filter((id) => id && !getUserById(state, id));

                if (notLoadedUserIds.length) {
                    dispatch(actions.incFetchersCount(store));
                    setStatus(LoadingStatus.LOADING);
                    users = await UserApi.getUserList(notLoadedUserIds);
                }
            } else {
                if (loadingStatus !== LoadingStatus.LOADED) {
                    dispatch(actions.incFetchersCount(store));
                    setStatus(LoadingStatus.LOADING);
                    users = await UserApi.getFullUserList();

                    ids = users.map((user) => user.id);
                }
            }

            if (users) {
                dispatch(actions.decFetchersCount(store));
                dispatch(actions.loadEntities(users));
            }
            dispatch(actions.setStoreIds({ store, ids }));

            const fetchersCount = getFetchersCount(getState(), store);
            if (!fetchersCount) {
                setStatus(LoadingStatus.LOADED);
            }
        } catch (e) {
            setStatus(LoadingStatus.ERROR);
        }
    },
);
