import { MutableRefObject } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';

import * as React from 'react';

export function useFormFieldProps<V>(options: UseControllerProps, parentRef?: MutableRefObject<any>) {
    const result = useController(options as UseControllerProps);
    const propsRef = result?.field?.ref;

    React.useEffect(() => {
        if (propsRef && parentRef) {
            propsRef(parentRef.current);
        }
    });

    return result;
}
