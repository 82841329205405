import * as React from 'react';
import { Icon, IconType, WithTooltip, StaticSkeleton } from 'sber-marketing-ui';

import * as styles from './FielAsset.scss';

interface Props {
    name: string;
    preloader: boolean;
    showDeleteButton: boolean;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
    onDeleteButtonClick: () => void;
}

export function FileAsset({
    name,
    preloader,
    showDeleteButton,
    onMouseEnter,
    onMouseLeave,
    onDeleteButtonClick,
}: Props): JSX.Element {
    const extensionIndex = name.lastIndexOf('.');
    const fileName = name.slice(0, extensionIndex);
    const fileExtension = name.slice(extensionIndex + 1);

    return (
        <div className={styles.root}>
            {preloader ? (
                <StaticSkeleton className={styles.skeleton} />
            ) : (
                <div
                    className={styles.preview}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    {...{
                        'qa-id': 'commentsBlockFileAsset',
                        'qa-title': `${fileName}`,
                    }}
                >
                    <div className={styles.fileExtension}>{fileExtension}</div>

                    {showDeleteButton && (
                        <Icon
                            type={IconType.REJECT_ICON_ROUND_ACTIVE}
                            className={styles.deleteButton}
                            onClick={onDeleteButtonClick}
                            svgSize={12}
                        />
                    )}
                </div>
            )}

            <WithTooltip content={fileName}>
                <div className={styles.fileName}>{fileName}</div>
            </WithTooltip>
        </div>
    );
}
