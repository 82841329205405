import { actionCreatorFactory } from 'typescript-fsa';

import { LoadingStatus } from '@store/commonTypes';

import {
    LoadEntitiesPayload,
    SetLoadingStatusParams,
    SetStoreIdsParams,
    LoadOrganizationsParams,
    StoreTypes,
} from './types';

const actionCreator = actionCreatorFactory('ORGANIZATIONS');

const loadOrganizationsAsync = actionCreator.async<LoadOrganizationsParams, void, Error>('LOAD_ORGANIZATIONS_ASYNC');

const loadEntities = actionCreator<LoadEntitiesPayload>('LOAD_ENTITIES');
const setStoreIds = actionCreator<SetStoreIdsParams>('SET_STORE_IDS');
const setLoadingStatus = actionCreator<SetLoadingStatusParams>('SET_LOADING_STATUS');
const setCommonLoadingStatus = actionCreator<LoadingStatus>('SET_COMMON_LOADOING_STATUS');
const resetStore = actionCreator<StoreTypes>('RESET_STORE');

export { loadOrganizationsAsync, loadEntities, setStoreIds, setLoadingStatus, resetStore, setCommonLoadingStatus };
