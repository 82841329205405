import * as React from 'react';
import { withRouter } from 'react-router-dom';
import autobind from 'autobind-decorator';

import type { UploadErrorParams } from 'sber-marketing-ui';
import type { FileAsset } from '@store/commonTypes';

import { FileUploader } from './FileUploader';
import { PageSaver } from '../../../../../../modules';

interface Props {
    fieldId: string;
    uniqId: number;
    parentUniqId: number;
    briefId: string;
    children: JSX.Element;
    onUploadSuccess: (params: FileAsset) => void;
    onUploadStart: (file: FileAsset) => void;
    onUploadError: (error: UploadErrorParams, asset: FileAsset) => void;
}

@(withRouter as any)
export class FileUploaderContainer extends React.PureComponent<Props> {
    protected fileInput: HTMLInputElement;
    private pageSaver: PageSaver;

    public constructor(props: Props) {
        super(props);

        this.pageSaver = PageSaver.getInstance();
    }

    public render(): JSX.Element {
        const { children } = this.props;

        return React.createElement(FileUploader, {
            children,
            fileInputRef: this.fileInputRef,
            onAddButtonClick: this.onAddButtonClick,
            onFileAdded: this.onFileAdded,
        });
    }

    @autobind
    protected fileInputRef(element: HTMLInputElement) {
        this.fileInput = element;
    }

    @autobind
    protected onAddButtonClick() {
        this.fileInput.click();
    }

    @autobind
    protected async onFileAdded() {
        await this.uploadFile(this.fileInput.files[0]);

        this.clearFileInput();
    }

    private async uploadFile(file: File): Promise<void> {
        const { fieldId, uniqId, parentUniqId, briefId } = this.props;
        const asset = this.pageSaver.createFileAsset({ briefId, fieldId, uniqId, parentUniqId, file });

        this.props.onUploadStart(asset);

        try {
            const loadedAsset = await this.pageSaver.uploadFile(fieldId, uniqId, parentUniqId, briefId, asset);

            this.props.onUploadSuccess(loadedAsset);
        } catch (error) {
            this.props.onUploadError(error, asset);
        }
    }

    private clearFileInput(): void {
        // easiest cross-browser way to clear file input
        // "this.fileInput.value = null" method doesn't work in IE11
        this.fileInput.type = '';
        this.fileInput.type = 'file';
    }
}
