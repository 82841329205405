import * as React from 'react';
import { Dispatch, AnyAction, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { TaskStatus } from 'sber-marketing-types/frontend';
import autobind from 'autobind-decorator';

import { TaskApi } from '@api';

import { StoreState } from '@store';
import { selectors, setIsUserSubscribed } from '@store/taskPage';

import { TaskTitleTemplate } from './TaskTitleTemplate';

interface Props extends Partial<MapProps & DispatchProps> {
    previousUrl: string;
    className?: string;
    showEditButton: boolean;
    onEditButtonClick: () => void;
}

interface MapProps {
    id: string;
    name: string;
    deadline: number;
    status: TaskStatus;
    isUserSubscribed: boolean;
    showDeleteButton: boolean;
    showTelegramButton: boolean;
    subscribeIsDisabled: boolean;
}

interface DispatchProps {
    setIsUserSubscribed: (isIsUserSubscribed: boolean) => void;
}

interface State {
    showDeleteTaskPopup: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class TaskTitleContainer extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            showDeleteTaskPopup: false,
        };
    }

    public render(): JSX.Element {
        const {
            className,
            name,
            deadline,
            status,
            isUserSubscribed,
            subscribeIsDisabled,
            showEditButton,
            showDeleteButton,
            showTelegramButton,
            onEditButtonClick,
        } = this.props;
        const { showDeleteTaskPopup } = this.state;

        return React.createElement(TaskTitleTemplate, {
            className,
            name,
            deadline,
            status,
            isUserSubscribed,
            subscribeIsDisabled,
            showEditButton,
            showDeleteButton,
            showTelegramButton,
            showDeleteTaskPopup,
            onSubscribeButtonClick: this.onSubscribeButtonClick,
            onEditButtonClick,
            setDeleteTaskPopupVisibility: this.setDeleteTaskPopupVisibility,
            initTaskDeletion: this.initTaskDeletion,
        });
    }

    @autobind
    protected async onSubscribeButtonClick(isUserSubscribed: boolean) {
        const { id, setIsUserSubscribed } = this.props;

        if (isUserSubscribed) {
            await TaskApi.removeSubscriber(id);
        } else {
            await TaskApi.addSubscriber(id);
        }

        setIsUserSubscribed(!isUserSubscribed);
    }

    @autobind
    private setDeleteTaskPopupVisibility(showDeleteTaskPopup: boolean): void {
        this.setState({
            showDeleteTaskPopup,
        });
    }

    @autobind
    private async initTaskDeletion(): Promise<void> {
        if (this.props.showDeleteButton) {
            await TaskApi.deleteTask(this.props.id);

            window.location.pathname = this.props.previousUrl;
        }
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const {
        id,
        title: name,
        deadlineAt: deadline,
        status,
        canDelete: showDeleteButton,
        telegram,
    } = selectors.getTaskInfo(state);
    const subscribeIsDisabled = selectors.getTaskPageState(state).subscribeIsDisabled;
    const isUserSubscribed = selectors.getIsUserSubscribed(state);

    return {
        id,
        name,
        deadline: deadline as number,
        status: status as TaskStatus,
        isUserSubscribed,
        showDeleteButton,
        showTelegramButton: telegram.hasUserAccessToChat,
        subscribeIsDisabled,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return bindActionCreators(
        {
            setIsUserSubscribed,
        },
        dispatch,
    );
}
