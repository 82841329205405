import * as React from 'react';
import classNames from 'classnames';
import * as qs from 'query-string';

import type { ChanelObject } from 'sber-marketing-types/frontend';

import { useSearch } from '@common/hooks';

import { Flex, FlexProps, Link } from '@common/components';

import { ChannelAccessAction, useChannelAccess } from '@modules/channel/hooks';

import { ChannelCloseIcon } from '@modules/channel/components';

import * as styles from './Channel.scss';

export interface ChannelProps extends FlexProps<'span'> {
    taskId: string;
    channel: ChanelObject;
}

export function Channel({ className, children, channel, taskId, loading, ...props }: ChannelProps) {
    const [search] = useSearch();
    const activeChannel = Number(search.channelId) || null;
    const { id, title } = channel;
    const {
        isLoading: isAccessLoading,
        access: [canDelete],
    } = useChannelAccess([ChannelAccessAction.close], channel.id, taskId);
    const isLoading = loading || isAccessLoading;

    return (
        <Flex
            data-qa-id="Channel"
            element="span"
            gap={8}
            align="center"
            {...props}
            loading={isLoading}
            className={classNames(styles.root, activeChannel === id && styles.active)}
            key={id}
        >
            <Link
                data-qa-id="Channel__link"
                className={classNames(styles.link)}
                to={`?${qs.stringify({ ...search, channelId: id || undefined, commentId: undefined })}`}
            >
                {title}
            </Link>
            {id && canDelete && <ChannelCloseIcon taskId={taskId} channelId={id} />}
        </Flex>
    );
}
