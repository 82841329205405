import * as lodash from 'lodash';

import type {
    AutopilotDOOHMediaplanBaseByRegion,
    AutopilotDOOHMediaplanBaseByRegionFormat,
    AutopilotDOOHMediaplanTotal,
} from 'sber-marketing-types/backend';

const DURATION_UNITS_TITLES = {
    month: 'мес.',
    day: 'дн.',
};

export const enum ColumnHeaderType {
    Text = 'text',
}

export const enum LineType {
    Line = 'line',
    GroupTotal = 'groupTotal',
}

export const enum ColumnName {
    Region = 'region',
    Format = 'format',
    Timing = 'timing',
    SideQuantity = 'sideQuantity',
    DateInterval = 'dateInterval',
    SideCost = 'sideCost',
    BudgetNet = 'budgetNet',
    BudgetGross = 'budgetGross',
    Reach1 = 'reach1',
    Reach10 = 'reach10',
    Reach20 = 'reach20',
    Ots = 'ots',
    AvgFreq = 'avgFreq',
    CPT = 'cpt',
}

export const enum CellType {
    Text = 'text',
    Input = 'input',
    FundsInput = 'fundsInput',
}

export interface AccessorParams {
    id: string;
    line: {
        byRegion?: AutopilotDOOHMediaplanBaseByRegion;
        byRegionFormat?: AutopilotDOOHMediaplanBaseByRegionFormat;
        total?: AutopilotDOOHMediaplanTotal;
    };
    allLines: any[];
}

export type Accessor = (params: AccessorParams) => any;
export type TitleAccessor = (params: AccessorParams) => React.ReactText;
export type ValueSetter = (params: AccessorParams, value: any) => Promise<void>;
export type ItemsAccessor = (params: AccessorParams) => any[];
export type DescriptionAccessor = (params: AccessorParams) => string;
export type CustomStyleAccessor = (params: AccessorParams) => React.CSSProperties;

export interface ColumnParams {
    title: string | [string, string];
    headerType: ColumnHeaderType;
    type: Record<LineType, CellType>;
    defaultWidth: number;
    disableWidthChange?: boolean;
    readOnly?: Partial<Record<LineType, boolean>>;
    customStyle?: Partial<Record<LineType, CustomStyleAccessor>>;
    linkedColumns?: Partial<Record<LineType, ColumnName[]>>;
    getTitle?: Partial<Record<LineType, TitleAccessor>>;
    getValue: Record<LineType, Accessor>;
    setValue?: Partial<Record<LineType, ValueSetter>>;
    getItems?: Partial<Record<LineType, ItemsAccessor>>;
    getDescription?: Partial<Record<LineType, DescriptionAccessor>>;
}

export interface ColumnsConfigParams {}

export const MakeColumnsConfig: (columnsConfigParams: ColumnsConfigParams) => { [columnName: string]: ColumnParams } = (
    columnsConfigParams: ColumnsConfigParams,
) => ({
    [ColumnName.Region]: {
        title: 'Город',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.GroupTotal]: CellType.Text,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.line.byRegionFormat.region.name,
            [LineType.GroupTotal]: (params: AccessorParams) => params.line.byRegion.region.name,
        },
    },
    [ColumnName.Format]: {
        title: 'Формат',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.GroupTotal]: CellType.Text,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.line.byRegionFormat.format.abbreviation,
            [LineType.GroupTotal]: (params: AccessorParams) =>
                params.line.byRegion.formats.map((item) => item.abbreviation).join(', '),
        },
    },
    [ColumnName.Timing]: {
        title: 'Хронометраж в блоке',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.GroupTotal]: CellType.Text,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => `${params.line.byRegionFormat.format.timing} сек`,
            [LineType.GroupTotal]: (params: AccessorParams) =>
                lodash.uniq(params.line.byRegion.formats.map((item) => `${item.timing} сек`)).join(', '),
        },
    },
    [ColumnName.SideQuantity]: {
        title: 'Кол-во сторон',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.GroupTotal]: CellType.Text,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.line.byRegionFormat.sideQuantity,
            [LineType.GroupTotal]: (params: AccessorParams) => params.line.byRegion.sideQuantity,
        },
    },
    [ColumnName.DateInterval]: {
        title: 'Период размещения',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.GroupTotal]: CellType.Text,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => {
                const { duration, durationUnit } = params.line.byRegionFormat.dateInterval;

                return `${duration} ${DURATION_UNITS_TITLES[durationUnit]}`;
            },
            [LineType.GroupTotal]: (params: AccessorParams) => {
                const { duration, durationUnit } = params.line.byRegion.dateInterval;

                return `${duration} ${DURATION_UNITS_TITLES[durationUnit]}`;
            },
        },
    },
    [ColumnName.SideCost]: {
        title: 'Средняя стоимость стороны до НДС',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.FundsInput,
            [LineType.GroupTotal]: CellType.FundsInput,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => (params.line.byRegionFormat as any).basePrice,
            [LineType.GroupTotal]: (params: AccessorParams) => params.line.byRegion.avgBasePrice,
        },
    },
    [ColumnName.BudgetNet]: {
        title: 'Стоимость размещения до НДС',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.FundsInput,
            [LineType.GroupTotal]: CellType.FundsInput,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.line.byRegionFormat.budget.net,
            [LineType.GroupTotal]: (params: AccessorParams) => params.line.byRegion.budget.net,
        },
    },
    [ColumnName.BudgetGross]: {
        title: 'Стоимость размещения с НДС',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.FundsInput,
            [LineType.GroupTotal]: CellType.FundsInput,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.line.byRegionFormat.budget.gross,
            [LineType.GroupTotal]: (params: AccessorParams) => params.line.byRegion.budget.gross,
        },
    },
    [ColumnName.Reach1]: {
        title: 'Reach 1+, %',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.GroupTotal]: CellType.Text,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => roundNumber(params.line.byRegionFormat.rf.campaignReach1Plus),
            [LineType.GroupTotal]: (params: AccessorParams) => roundNumber(params.line.byRegion.rf.campaignReach1Plus),
        },
    },
    [ColumnName.Reach10]: {
        title: 'Reach 10+, %',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.GroupTotal]: CellType.Text,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => roundNumber(params.line.byRegionFormat.rf.campaignReach10Plus),
            [LineType.GroupTotal]: (params: AccessorParams) => roundNumber(params.line.byRegion.rf.campaignReach10Plus),
        },
    },
    [ColumnName.Reach20]: {
        title: 'Reach 20+, %',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.GroupTotal]: CellType.Text,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => roundNumber(params.line.byRegionFormat.rf.campaignReach20Plus),
            [LineType.GroupTotal]: (params: AccessorParams) => roundNumber(params.line.byRegion.rf.campaignReach20Plus),
        },
    },
    [ColumnName.Ots]: {
        title: 'OTS, тыс.',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.GroupTotal]: CellType.Text,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => Math.round(params.line.byRegionFormat.rf.campaignOts),
            [LineType.GroupTotal]: (params: AccessorParams) => Math.round(params.line.byRegion.rf.campaignOts),
        },
    },
    [ColumnName.AvgFreq]: {
        title: 'Freq',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.GroupTotal]: CellType.Text,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => Math.round(params.line.byRegionFormat.rf.avgFreq),
            [LineType.GroupTotal]: (params: AccessorParams) => Math.round(params.line.byRegion.rf.avgFreq),
        },
    },
    [ColumnName.CPT]: {
        title: 'СРТ',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.FundsInput,
            [LineType.GroupTotal]: CellType.FundsInput,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.GroupTotal]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => roundNumber(params.line.byRegionFormat.rf.cpt),
            [LineType.GroupTotal]: (params: AccessorParams) => roundNumber(params.line.byRegion.rf.cpt),
        },
    },
});

export const MakeTableColumns: (columnsConfigParams: ColumnsConfigParams) => string[] = () => [
    ColumnName.Format,
    ColumnName.Timing,
    ColumnName.SideQuantity,
    ColumnName.DateInterval,
    ColumnName.SideCost,
    ColumnName.BudgetNet,
    ColumnName.BudgetGross,
    ColumnName.Reach1,
    ColumnName.Reach10,
    ColumnName.Reach20,
    ColumnName.Ots,
    ColumnName.AvgFreq,
    ColumnName.CPT,
];

export const leftFixedColumns: ColumnName[] = [ColumnName.Region];

export const rightFixedColumns: ColumnName[] = [];

function roundNumber(value: number, digitsAfterComma = 2): string {
    if (value === undefined) {
        return null;
    }

    const formatedValue = value.toFixed(digitsAfterComma);

    const [decimalPart, fractionPart] = formatedValue.split('.');

    return `${decimalPart}${fractionPart ? `.${fractionPart}` : ''}`;
}
