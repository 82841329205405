import * as React from 'react';
import { useSelector } from 'react-redux';
import { TaskStatus } from 'sber-marketing-types/frontend';
import { IconType } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getTagsPageState } from '@store/tagsPage';
import { getOrganizationById } from '@store/organizations';
import { getDepartmentById } from '@store/departments';
import { getWorkTypeById } from '@store/workTypes';

import { DatesFormatter } from '@common/Utils';

import { Item } from '../Item';

import * as styles from './Task.scss';

function useStore() {
    const taskData = useSelector((state: StoreState) => getTagsPageState(state).selectedNodeData.task);

    const authorOrganization = useSelector((state: StoreState) =>
        getOrganizationById(state, taskData?.task?.author?.organizationId),
    );
    const authorDepartment = useSelector((state: StoreState) =>
        getDepartmentById(state, taskData?.task?.author?.departmentId),
    );
    const workType = useSelector((state: StoreState) => getWorkTypeById(state, taskData?.task?.workTypeId));

    return { taskData, authorOrganization, authorDepartment, workType };
}

const NO_VALUE = '-';

function getValue(value: string): string {
    return value || NO_VALUE;
}

export function Task(): JSX.Element {
    const { taskData, authorOrganization, authorDepartment, workType } = useStore();

    if (!taskData) {
        console.warn(`Expected task data was not found in store`);
        return null;
    }

    const {
        task,
        activity: { productName },
    } = taskData;

    let statusContent: string;
    switch (task?.status) {
        case TaskStatus.Draft:
            statusContent = 'Черновик';
            break;
        case TaskStatus.InProgress:
            statusContent = 'В процессе выполнения';
            break;
        case TaskStatus.Closed:
            statusContent = 'Закрыта';
            break;
        default:
            console.warn(`Missing case-declaration for TaskStatus.${task.status}`);
            statusContent = NO_VALUE;
            break;
    }

    const organization = getValue(authorOrganization?.name);
    const department = getValue(authorDepartment?.name);
    const executorContent = `${task?.author?.secondName} ${task?.author?.firstName} (${organization}, ${department})`;

    return (
        <React.Fragment>
            <Item
                qaId="tagspageRightSidebarTaskContentCreateTime"
                title="Дата создания"
                content={DatesFormatter.ddMonthyy(task?.createTime)}
            />
            <Item qaId="tagspageRightSidebarTaskContentCreateStatus" title="Статус" content={statusContent} />
            <Item qaId="tagspageRightSidebarTaskContentExecutior" title="Исполнитель" content={executorContent} />
            <Item
                qaId="tagspageRightSidebarTaskContentCreateProduct"
                title="Продукт"
                content={getValue(productName)}
                icon={IconType.CUBE}
            />
            <Item
                qaId="tagspageRightSidebarTaskContentWorkType"
                title="Тип работ"
                content={getValue(workType?.name)}
                icon={IconType.WORK_TYPE_ICON}
                iconClassName={styles.workTypeIcon}
            />
        </React.Fragment>
    );
}
