import * as ExcelJS from 'exceljs';
import { values } from 'lodash';

import { XLSXImportValidationError } from '@store/budgetPlanning';

import { XLSXImportError } from '../validationErrors/XLSXImportError';
import { DATA_SHEET_TITLE, Columns, ColumnNames } from '../../types';

export class WorkbookValidator {
    private dataSheetToProcess: ExcelJS.Worksheet;
    private columnIndices: Partial<Record<ColumnNames, string>>;

    public constructor(workbook: ExcelJS.Workbook) {
        const dataSheet = this._getDataSheet(workbook);
        this._fillColumnIndices(dataSheet);
        this._fillSheetToProcess(workbook, dataSheet);
    }

    public validate(): void {
        this._hasDataSheet();
        this._allColumnsExist();
        this._allRequiredFieldsAreFilled();
    }

    private _getDataSheet(workbook: ExcelJS.Workbook): ExcelJS.Worksheet {
        return workbook.worksheets.find((sheet) => sheet.name === DATA_SHEET_TITLE);
    }

    private _fillColumnIndices(dataSheet: ExcelJS.Worksheet) {
        this.columnIndices = {};

        if (dataSheet) {
            dataSheet.getRow(1).eachCell((cell) => {
                const columnParams = Columns.find((column) => column.header === cell.value);

                if (columnParams) {
                    this.columnIndices[columnParams.key] = cell.col;
                }
            });
        }
    }

    private _fillSheetToProcess(workbook: ExcelJS.Workbook, dataSheet: ExcelJS.Worksheet): void {
        if (dataSheet) {
            this.dataSheetToProcess = workbook.addWorksheet();

            // copying title row
            this.dataSheetToProcess.addRow({});
            dataSheet.getRow(1).eachCell((cell) => {
                this.dataSheetToProcess.getCell(cell.row, cell.col).value = cell.value;
            });

            // copying data rows, but only columns without ignoreForImport are appended
            for (let i = 2; i !== dataSheet.rowCount; i++) {
                const row = this.dataSheetToProcess.addRow({});

                values(Columns).forEach((columnDesc) => {
                    const { ignoreForImport, key } = columnDesc;

                    const columnIndex = this.columnIndices[key];

                    if (columnIndex && !ignoreForImport) {
                        row.getCell(columnIndex).value = dataSheet.getCell(i, columnIndex).value;
                    }
                });
            }
        }
    }

    // 1. workbook must cotain sheet with name = DATA_SHEET_TITLE
    private _hasDataSheet(): void {
        if (!this.dataSheetToProcess) {
            throw new XLSXImportError({
                error: XLSXImportValidationError.MissingDataWorkbook,
            });
        }
    }

    // 2. dataSheet must contain all Columns
    // column is recognized by first row value (column's header)
    private _allColumnsExist(): void {
        const presentColumns: string[] = [];
        this.dataSheetToProcess.getRow(1).eachCell((cell) => presentColumns.push(cell.value as string));

        const missingColumns = Columns.filter((column) => !presentColumns.includes(column.header)).map(
            (column) => column.header,
        );
        if (missingColumns.length) {
            throw new XLSXImportError({
                error: XLSXImportValidationError.MissingColumns,
                missingColumns,
            });
        }
    }

    // 3. all ActivityName and SapComment columns must be non-empty
    private _allRequiredFieldsAreFilled(): void {
        const activityNameColumnIndex = this.columnIndices[ColumnNames.ActivityName];
        let allActivityNamesAreFilled = true;
        this.dataSheetToProcess.getColumn(activityNameColumnIndex).eachCell((cell) => {
            if (!cell.value || !`${cell.value}`.trim()) {
                allActivityNamesAreFilled = false;
            }
        });

        const sapCommentColumnIndex = this.columnIndices[ColumnNames.SapComment];
        let allSapCommentsAreFilled = true;
        this.dataSheetToProcess.getColumn(sapCommentColumnIndex).eachCell((cell) => {
            if (!cell.value || !`${cell.value}`.trim()) {
                allSapCommentsAreFilled = false;
            }
        });

        if (!allActivityNamesAreFilled || !allSapCommentsAreFilled) {
            throw new XLSXImportError({
                error: XLSXImportValidationError.ContainsRowsWithNonFilledRequiredFields,
            });
        }
    }
}
