// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-brief-ContentNav-NavItemList-NavItem-_NavItem__root{overflow:hidden;cursor:pointer;user-select:none;transition:color 0.07s ease-in;white-space:nowrap;text-overflow:ellipsis;color:#999999;font-family:'Open Sans';font-size:14px;line-height:20px}.src-client-modules-brief-ContentNav-NavItemList-NavItem-_NavItem__root:hover{color:#262626}.src-client-modules-brief-ContentNav-NavItemList-NavItem-_NavItem__selected{color:#19bb4f}\n", "",{"version":3,"sources":["webpack://./src/client/modules/brief/ContentNav/NavItemList/NavItem/NavItem.scss"],"names":[],"mappings":"AAAA,wEACI,eAAgB,CAEhB,cAAe,CACf,gBAAiB,CACjB,8BAA+B,CAC/B,kBAAmB,CACnB,sBAAuB,CAEvB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CAbrB,8EAgBQ,aAAc,CACjB,4EAID,aAAc","sourcesContent":[".root {\n    overflow: hidden;\n\n    cursor: pointer;\n    user-select: none;\n    transition: color 0.07s ease-in;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n\n    color: #999999;\n\n    font-family: 'Open Sans';\n    font-size: 14px;\n    line-height: 20px;\n\n    &:hover {\n        color: #262626;\n    }\n}\n\n.selected {\n    color: #19bb4f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-brief-ContentNav-NavItemList-NavItem-_NavItem__root",
	"selected": "src-client-modules-brief-ContentNav-NavItemList-NavItem-_NavItem__selected"
};
export default ___CSS_LOADER_EXPORT___;
