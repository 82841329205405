import * as React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Icon, IconType, AnimatedEllipsis, WithTooltip, TooltipAnchor } from 'sber-marketing-ui';
import { values } from 'lodash';
import { sanitize } from 'dompurify';

import { FileApi, CreativeRequestCommentFile, CreativeRequestCommentAuthor } from '@api';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';
import { getAppUsers } from '@store/appUsers';

import { parseMessage } from '@common/lib';
import { Utils, DatesFormatter } from '@common/Utils';
import { FileAsset } from '@common/FileAssetRedesign';
import { MediaFilesGallery } from '@common/MediaFilesGallery';
import { ReactionForm, ReactionFormItem } from '@common/ReactionForm';

import * as styles from './CreativeRequestComment.scss';
import * as commonStyles from '../../../../CommonStyles.scss';

interface Props {
    isFavorite?: boolean;
    author: CreativeRequestCommentAuthor;
    text: any;
    createdAt: Date;
    files: CreativeRequestCommentFile[];
    childrenCommentsCount: number;
    addReplyButton?: boolean;
    addFavoriteButton?: boolean;
    onReplyButtonClick?: () => void;
    onFavoriteButtonClick?: () => void;
}

function filesDeclension(count: number) {
    return Utils.getDeclensionByNumber(count, ['файл', 'файла', 'файлов']);
}

function commentsDeclension(count: number) {
    return Utils.getDeclensionByNumber(count, ['комментарий', 'комментария', 'комментариев']);
}

interface MediaGalleryState {
    isOpened: boolean;
    index: number;
}

function makeMediaGalleryDefaultState(): MediaGalleryState {
    return {
        isOpened: false,
        index: null,
    };
}

export function CreativeRequestComment({
    isFavorite,
    author,
    text,
    createdAt,
    files,
    childrenCommentsCount,
    addReplyButton,
    addFavoriteButton,
    onReplyButtonClick,
    onFavoriteButtonClick,
}: Props): JSX.Element {
    const loginnedUserId = useSelector((state: StoreState) => getLoginUser(state).attributes.id);
    const appUsers = useSelector(getAppUsers);

    const [isHovered, setIsHovered] = React.useState(false);
    const [filesExpanded, setFilesExpanded] = React.useState(false);
    const [galleryState, setGalleryState] = React.useState<MediaGalleryState>(makeMediaGalleryDefaultState());

    function openMediaGallery(file: CreativeRequestCommentFile) {
        const index = mediaGalleryItems.findIndex((item) => item.id === file.id);
        setGalleryState({ isOpened: true, index });
    }

    function closeMediaGallery() {
        setGalleryState(makeMediaGalleryDefaultState());
    }

    const mediaGalleryItems = React.useMemo(
        () => Utils.mapCreativeRequestCommentFilesToMediaGalleryItems(files, (file) => ({ fileId: file.id })),
        [files],
    );

    const showReactionForm = addReplyButton || addFavoriteButton;
    const canEditInR7 = author?.id === loginnedUserId;

    return (
        <React.Fragment>
            <div
                className={classnames(styles.root, commonStyles.sidePaddings)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div>
                    <WithTooltip
                        anchor={TooltipAnchor.LEFT}
                        content={`${author.firstName} ${author.secondName} (${author.departmentName})`}
                    >
                        <div className={styles.commentInfo}>
                            <span className={styles.authorName}>
                                {author.firstName} {author.secondName}
                            </span>
                            <span className={styles.authorDepartment}>{author.departmentName}</span>
                            <span className={styles.commentCreateTime}>{DatesFormatter.hhmm(createdAt)}</span>
                        </div>
                    </WithTooltip>

                    <div
                        className={styles.text}
                        dangerouslySetInnerHTML={{
                            __html: sanitize(
                                parseMessage(text as any as string, styles.textMention, values(appUsers.entities)),
                                {
                                    ALLOWED_TAGS: ['a', 'br', 'span'],
                                    ALLOWED_ATTR: ['target', 'href', 'class', 'title'],
                                },
                            ),
                        }}
                    />

                    {!!childrenCommentsCount && (
                        <div className={styles.childrenCommentsButton} onClick={onReplyButtonClick}>
                            {childrenCommentsCount} {commentsDeclension(childrenCommentsCount)}
                        </div>
                    )}

                    {!!files?.length && (
                        <div className={styles.filesWrapper}>
                            <div className={styles.filesTitleWrapper}>
                                <div className={styles.filesTitle} onClick={() => setFilesExpanded(!filesExpanded)}>
                                    <Icon type={IconType.ATTACHMENT_ICON} svgSize={20} />
                                    {files.length}&nbsp;{filesDeclension(files.length)}
                                    <Icon
                                        type={IconType.EXPAND_ICON}
                                        svgSize={16}
                                        className={filesExpanded ? styles.filesExpandIconActive : null}
                                    />
                                </div>

                                <DownloadAllButton files={files} />
                            </div>

                            {filesExpanded && (
                                <div className={styles.files}>
                                    {files.map((file) => (
                                        <File
                                            key={file.id}
                                            file={file}
                                            canEditInR7={canEditInR7}
                                            onMediaGalleryButtonClick={() => openMediaGallery(file)}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>

                {showReactionForm && (
                    <ReactionForm className={styles.reactionForm} formIsActive={isHovered}>
                        {addFavoriteButton && (
                            <ReactionFormItem onClick={onFavoriteButtonClick}>
                                <Icon
                                    type={
                                        isFavorite
                                            ? IconType.CREATIVE_REQUEST_REACTION_FORM_FAVORITE_BUTTON_ACTIVE
                                            : IconType.CREATIVE_REQUEST_REACTION_FORM_FAVORITE_BUTTON_UNCTIVE
                                    }
                                    className={isFavorite ? styles.favoriteIconActive : styles.icon}
                                    svgSize={24}
                                />
                            </ReactionFormItem>
                        )}

                        {addReplyButton && (
                            <ReactionFormItem onClick={onReplyButtonClick}>
                                <Icon
                                    type={IconType.CREATIVE_REQUEST_REACTION_FORM_REPLY_BUTTON}
                                    svgSize={24}
                                    className={styles.icon}
                                />
                            </ReactionFormItem>
                        )}
                    </ReactionForm>
                )}
            </div>

            {galleryState.isOpened && (
                <MediaFilesGallery
                    items={mediaGalleryItems}
                    startIndex={galleryState.index}
                    onOutsideClick={closeMediaGallery}
                />
            )}
        </React.Fragment>
    );
}

interface FileProps {
    file: CreativeRequestCommentFile;
    canEditInR7: boolean;
    onMediaGalleryButtonClick: () => void;
}

function File({ file, canEditInR7, onMediaGalleryButtonClick }: FileProps): JSX.Element {
    const { id, name, originName, size, type } = file;

    return (
        <div className={styles.file}>
            <div className={styles.fileExtension}>
                <FileAsset
                    useR7Controls
                    useMediaControls
                    canEditInR7={canEditInR7}
                    id={id}
                    name={name}
                    originName={originName}
                    type={type}
                    fileId={id}
                    onGalleryButtonClick={onMediaGalleryButtonClick}
                />
            </div>

            <div className={styles.fileDescription}>
                <div className={styles.fileName}>{originName}</div>
                <div className={styles.fileInfo}>{Utils.formatFileSize(+size)}</div>
            </div>
        </div>
    );
}

interface DownloadAllButtonProps {
    files: CreativeRequestCommentFile[];
}

function DownloadAllButton({ files }: DownloadAllButtonProps): JSX.Element {
    const [isDownloadInProgress, setIsDownloadInProgress] = React.useState(false);

    async function initDownload() {
        setIsDownloadInProgress(true);

        await FileApi.downloadMultipleFilesAsZip(
            'Заявка на креатив файлы',
            files.map((file) => ({
                id: file.id,
                name: file.originName,
                type: file.type,
                params: { fileId: file.id },
            })),
        );

        setIsDownloadInProgress(false);
    }

    if (files?.length < 1) {
        return null;
    }

    return isDownloadInProgress ? (
        <div className={styles.downloadAllButton}>
            <AnimatedEllipsis text="загружаем" />
        </div>
    ) : (
        <div className={styles.downloadAllButtonClickable} onClick={initDownload}>
            скачать все файлы
        </div>
    );
}
