import * as React from 'react';

import * as style from './AutopilotPage.scss';

import { OpacityTransition, CustomScrollbar } from 'sber-marketing-ui';
import { StagesListWidget } from './StagesListWidget';
import {
    BriefStageActions,
    MediaplanStageActions,
    BudgetStageActions,
    CreativeStageActions,
    FinalStageActions,
} from './StageActions';
import {
    BriefStageWidget,
    MediaplanStageWidget,
    BudgetStageWidget,
    CreativeStageWidget,
    FinalStageWidget,
} from './StageWidgets';
import { CommentsWidget } from './CommentsWidget';

interface Props {
    isAutopilotClient: boolean;
    isAutopilotClientService: boolean;
    placementsAreLaunched: boolean;
    autopilotId: string;
    autopilotIsEnabled: boolean;
    currentStage: number;
}

export const AutopilotPageTemplate = ({
    isAutopilotClient,
    isAutopilotClientService,
    placementsAreLaunched,
    autopilotId,
    autopilotIsEnabled,
    currentStage,
}: Props): JSX.Element => {
    return (
        <OpacityTransition>
            <div className={style.root}>
                <div className={style.content}>
                    <div className={style.stageList}>
                        <StagesListWidget currentStage={currentStage} />
                    </div>

                    {autopilotIsEnabled && (
                        <>
                            <div className={style.stageActions}>
                                {currentStage === 1 && (
                                    <BriefStageActions
                                        canEdit={
                                            (isAutopilotClient || isAutopilotClientService) && !placementsAreLaunched
                                        }
                                    />
                                )}

                                {currentStage === 2 && (
                                    <MediaplanStageActions
                                        canEdit={
                                            (isAutopilotClient || isAutopilotClientService) && !placementsAreLaunched
                                        }
                                    />
                                )}

                                {currentStage === 3 && (
                                    <BudgetStageActions canEdit={isAutopilotClient && !placementsAreLaunched} />
                                )}

                                {currentStage === 4 && (
                                    <CreativeStageActions
                                        canEdit={
                                            (isAutopilotClient || isAutopilotClientService) && !placementsAreLaunched
                                        }
                                    />
                                )}

                                {currentStage === 5 && (
                                    <FinalStageActions
                                        canEdit={
                                            (isAutopilotClient || isAutopilotClientService) && !placementsAreLaunched
                                        }
                                    />
                                )}
                            </div>

                            <div className={style.stageWidget}>
                                {currentStage === 1 && (
                                    <BriefStageWidget
                                        readOnly={
                                            !(isAutopilotClient || isAutopilotClientService) || placementsAreLaunched
                                        }
                                    />
                                )}

                                {currentStage === 2 && (
                                    <MediaplanStageWidget
                                        readOnly={
                                            !(isAutopilotClient || isAutopilotClientService) || placementsAreLaunched
                                        }
                                    />
                                )}

                                {currentStage === 3 && (
                                    <BudgetStageWidget readOnly={!isAutopilotClient || placementsAreLaunched} />
                                )}

                                {currentStage === 4 && (
                                    <CreativeStageWidget
                                        readOnly={
                                            !(isAutopilotClient || isAutopilotClientService) || placementsAreLaunched
                                        }
                                    />
                                )}

                                {currentStage === 5 && (
                                    <>
                                        <FinalStageWidget />

                                        <div className={style.stageActions}>
                                            <FinalStageActions
                                                canEdit={
                                                    (isAutopilotClient || isAutopilotClientService) &&
                                                    !placementsAreLaunched
                                                }
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>

                <div className={style.rightSidebar}>
                    {autopilotId && (
                        <CustomScrollbar hideHorizontalScrollbar hideTracksWhenNotNeeded>
                            <CommentsWidget autopilotId={autopilotId} />
                        </CustomScrollbar>
                    )}
                </div>
            </div>
        </OpacityTransition>
    );
};
