import { Success, Failure } from 'typescript-fsa';
import { keyBy } from 'lodash';

import { TaskPageState, LoadTaskPayload, TaskLoadingStatus, FetchError, LoadTaskSuccess } from '../types';

export const loadTaskStartedReducer = (state: TaskPageState, { taskId }: LoadTaskPayload): TaskPageState => ({
    ...state,
    id: taskId,
    loadingStatus: TaskLoadingStatus.LOADING,
});

export const loadTaskErrorReducer = (
    state: TaskPageState,
    { error: { code, message, type } }: Failure<LoadTaskPayload, FetchError>,
): TaskPageState => ({
    ...state,
    errorCode: code,
    errorType: type,
    errorMessage: message,
    loadingStatus: TaskLoadingStatus.ERROR,
});

export const loadTaskDoneReducer = (
    state: TaskPageState,
    {
        result: {
            id,
            title,
            assetsIds,
            loginnedUserId,
            authorId,
            channels,
            description,
            deadlineAt,
            createdAt,
            executorId,
            participants,
            departmentExecutorId,
            comments,
            departments,
            workTypes,
            workType,
            activityId,
            organizationId,
            activity,
            status,
            telegram,
            rate,
            isUserSubscribed,
            canEdit,
            canDelete,
            users,
            createdBy,
            budgetApproval,
        },
    }: Success<LoadTaskPayload, LoadTaskSuccess>,
): TaskPageState => {
    const {
        newCommentary: { authorId: oldAuthorId, ...restNewCommentary },
        subscribeIsDisabled,
    } = state;

    return {
        id,
        title,
        authorId,
        description,
        createdAt,
        deadlineAt,
        assetsIds,
        executorId,
        participants,
        departmentExecutorId,
        workType,
        activity,
        activityId,
        organizationId,
        status,
        newCommentary: {
            ...restNewCommentary,
            authorId: loginnedUserId,
        },
        editingComment: null,
        loadingStatus: TaskLoadingStatus.LOADED,
        workTypes: keyBy(workTypes, 'id'),
        comments: keyBy(comments, 'id'),
        channels: keyBy(channels, 'id'),
        departments: keyBy(departments, 'id'),
        telegram,
        rate,
        isUserSubscribed,
        editingParticipants: participants,
        isParticipantEditorFormVisible: false,
        canEdit,
        canDelete,
        users,
        subscribeIsDisabled,
        createdBy,
        budgetApproval,
        commentIdToReplyTo: null,
        sendingComment: false,
        savingComment: null,
    };
};
