import * as React from 'react';

import { Value } from './Value';

import { directionName } from './utils';
import { Direction, Result } from './enums';

import * as styles from './styles.scss';

export interface Props {
    direction: Direction;
    result: Result;
    value: number;
    period: string;
}

export const IntervalProgress: React.FC<Props> = ({ direction, result, value, period }) => {
    return (
        <div className={styles.root}>
            <span>{directionName[direction]} </span>

            {direction !== Direction.NEUTRAL && <Value direction={direction} result={result} value={value} />}

            <span> за {period}</span>
        </div>
    );
};
