import { bindActionCreators } from 'redux';
import * as moment from 'moment';
import * as lodash from 'lodash';
import autobind from 'autobind-decorator';

import type { Autopilot2, Autopilot2Brief, Autopilot2Social } from 'sber-marketing-types/backend';
import { AutopilotStatus } from 'sber-marketing-types/backend';
import type { BriefStageForm, MediaplanSettingsForm, SocialStageForm } from '@store/autopilot/types';

import { store } from '@store';
import { setChangedCreativeStatuses } from '@store/autopilot/actions';
import {
    getAutopilot,
    getBriefStageForm,
    getChangedCreativeStatuses,
    getMediaplanSettingsForm,
    getSelectedBudgetItemIds,
    getSocialStageForm,
} from '@store/autopilot/selectors';
import { AutopilotApi } from '@api';

interface StoreProps {
    autopilot: Autopilot2;
    briefForm: BriefStageForm;
    mediaplanForm: MediaplanSettingsForm;
    selectedBudgetItemIds: string[];
    socialStageForm: SocialStageForm;
    changedCreativeStatuses: Record<string, string>;
}

export class Saver {
    private static instance: Saver;

    private dispatch = bindActionCreators(
        {
            setChangedCreativeStatuses,
        },
        store.dispatch,
    );

    public static getInstance(): Saver {
        if (!Saver.instance) {
            Saver.instance = new Saver();
        }
        return Saver.instance;
    }

    public init(): void {}

    public async setAutopilotStatus(status: boolean) {
        const { autopilot } = this.getStoreProps();

        if (autopilot) {
            await AutopilotApi.updateAutopilot(autopilot.id, {
                status: (status ? 'on' : 'off') as AutopilotStatus,
            });
        }
    }

    public async saveBriefForm() {
        const { autopilot } = this.getStoreProps();

        if (autopilot) {
            const updateParams = this.makeAutopilotBriefParams();

            await AutopilotApi.updateBrief(autopilot.id, updateParams);
        }
    }

    @autobind
    public async saveMediaplanSettingsForm() {
        const { autopilot, mediaplanForm } = this.getStoreProps();

        if (autopilot) {
            await AutopilotApi.updateMediaplanSettings(autopilot.id, mediaplanForm);
            await AutopilotApi.updateMediaplan(autopilot.id);
        }
    }

    public async calculateMediaplan() {
        const { autopilot } = this.getStoreProps();

        if (autopilot) {
            await AutopilotApi.updateMediaplan(autopilot.id);
        }
    }

    public async saveSelectedBudgetItems() {
        const { autopilot, selectedBudgetItemIds } = this.getStoreProps();

        if (autopilot) {
            await AutopilotApi.updateBudget(autopilot.id, { budgetIds: selectedBudgetItemIds });
        }
    }

    public async saveSocialForm() {
        const { autopilot } = this.getStoreProps();

        if (autopilot) {
            const updateParams = this.makeAutopilotSocialParams();

            await AutopilotApi.updateSocial(autopilot.id, updateParams);
        }
    }

    public async launchPlacements(uiType: string) {
        const { autopilot } = this.getStoreProps();

        if (autopilot) {
            await AutopilotApi.launch(autopilot.id, { uiType });
        }
    }

    public async stopPlacements(uiType?: string) {
        const { autopilot } = this.getStoreProps();

        if (autopilot) {
            await AutopilotApi.stop(autopilot.id, { uiType });
        }
    }

    public async setPlacementStatus(lineId: string, status: string) {
        const { autopilot } = this.getStoreProps();

        const placement = autopilot.placements.find((item) => item.code === lineId);

        const updatedPlacement = { ...placement, creativeStatus: status };

        await AutopilotApi.updatePlacement(autopilot.id, updatedPlacement as any);
    }

    public async savePlacementChanges(lineId: string) {
        const { autopilot, changedCreativeStatuses } = this.getStoreProps();

        const placement = autopilot.placements.find((item) => item.code === lineId);
        const newStatus = changedCreativeStatuses[lineId];

        const updatedPlacement = { ...placement, creativeStatus: newStatus };

        await AutopilotApi.updatePlacement(autopilot.id, updatedPlacement as any);
    }

    public clearPlacementChanges(lineId: string) {
        const { changedCreativeStatuses } = this.getStoreProps();

        const updatedChangedCreativeStatuses = lodash.clone(changedCreativeStatuses);

        delete updatedChangedCreativeStatuses[lineId];

        this.dispatch.setChangedCreativeStatuses(updatedChangedCreativeStatuses);
    }

    private makeAutopilotBriefParams(): Autopilot2Brief {
        const { briefForm } = this.getStoreProps();

        return {
            block: briefForm.blockId,
            product: briefForm.productId,
            budget: parseInt(briefForm.budget, 10),
            dateStart: briefForm.dateStart.format('YYYY-MM-DD'),
            dateEnd: briefForm.dateEnd.format('YYYY-MM-DD'),
            ageFrom: parseInt(briefForm.targetAgeFrom, 10),
            ageTo: parseInt(briefForm.targetAgeTo, 10),
            landingPage: briefForm.landingPage,
            peakDates: briefForm.peakDates.map((item) => moment(item).format('YYYY-MM-DD')),
            yandexMetrikaTarget: briefForm.yandexMetrikaTarget,
        };
    }

    private makeAutopilotSocialParams(): Autopilot2Social {
        const { socialStageForm } = this.getStoreProps();

        return {
            vk: socialStageForm.vkUrl,
            ok: socialStageForm.okUrl,
        };
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        return {
            autopilot: getAutopilot(storeState),
            briefForm: getBriefStageForm(storeState),
            mediaplanForm: getMediaplanSettingsForm(storeState),
            selectedBudgetItemIds: getSelectedBudgetItemIds(storeState),
            socialStageForm: getSocialStageForm(storeState),
            changedCreativeStatuses: getChangedCreativeStatuses(storeState),
        };
    }
}
