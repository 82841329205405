import { sortBy } from 'lodash';
import { Color } from '../../../../common';
import { BuildReportChartConfig, StatisticsActivityType } from './types';

const statisticsItemsTypes: StatisticsActivityType[] = [
    StatisticsActivityType.OVERDUE_TASKS,
    StatisticsActivityType.OVERDUE_STAGES,
    StatisticsActivityType.ACTIVE,
    StatisticsActivityType.PLANNED_START,
];

const STATISTICS_ITEMS_TYPES_COLORS_MAP: Record<StatisticsActivityType, Color> = {
    [StatisticsActivityType.OVERDUE_TASKS]: Color.RED,
    [StatisticsActivityType.OVERDUE_STAGES]: Color.MUSTARD,
    [StatisticsActivityType.ACTIVE]: Color.GREEN,
    [StatisticsActivityType.PLANNED_START]: Color.BLUE,
};

const STATISTICS_ITEMS_TYPES_NAMES_MAP: Record<StatisticsActivityType, string> = {
    [StatisticsActivityType.OVERDUE_TASKS]: 'Проекты с просроченными задачами',
    [StatisticsActivityType.OVERDUE_STAGES]: 'Проекты с просроченными этапами',
    [StatisticsActivityType.ACTIVE]: 'Активные проекты',
    [StatisticsActivityType.PLANNED_START]: 'Планируется запуск проектов',
};

export const buildActivityReportChartConfig: BuildReportChartConfig<'activities'> = ({
    statisticsItems,
    statisticsTypeFilters,
}) => {
    const data = {
        [StatisticsActivityType.ACTIVE]: () =>
            sortBy(
                statisticsItems.map(({ id, statistics, meta }) => ({
                    id,
                    name: meta.name,
                    mainValue: statistics.current.activeCount,
                    additionalValue: statistics.past.activeCount,
                })),
                ({ mainValue }) => mainValue,
            ).reverse(),
        [StatisticsActivityType.PLANNED_START]: () =>
            sortBy(
                statisticsItems.map(({ id, statistics, meta }) => ({
                    id,
                    name: meta.name,
                    mainValue: statistics.current.planningCount,
                    additionalValue: statistics.past.planningCount,
                })),
                ({ mainValue }) => mainValue,
            ).reverse(),
        [StatisticsActivityType.OVERDUE_STAGES]: () =>
            sortBy(
                statisticsItems.map(({ id, statistics, meta }) => ({
                    id,
                    name: meta.name,
                    mainValue: statistics.current.withOverdueStagesCount,
                    additionalValue: statistics.past.withOverdueStagesCount,
                })),
                ({ mainValue }) => mainValue,
            ).reverse(),
        [StatisticsActivityType.OVERDUE_TASKS]: () =>
            sortBy(
                statisticsItems.map(({ id, statistics, meta }) => ({
                    id,
                    name: meta.name,
                    mainValue: statistics.current.withOverdueTasksCount,
                    additionalValue: statistics.past.withOverdueTasksCount,
                })),
                ({ mainValue }) => mainValue,
            ).reverse(),
    };

    return statisticsItemsTypes
        .filter((statisticsItemType) => statisticsTypeFilters[statisticsItemType])
        .map((statisticsItemType) => ({
            name: STATISTICS_ITEMS_TYPES_NAMES_MAP[statisticsItemType],
            color: STATISTICS_ITEMS_TYPES_COLORS_MAP[statisticsItemType],
            data: data[statisticsItemType](),
            type: statisticsItemType,
        }));
};
