/**
 * @fileoverview Utility selectors and helpers for internal usage
 */
import { uniq, compact, sortBy } from 'lodash';

import { TaskPageState, SortOrder, AllAssetsSortOrder } from '../types';
import { StoreState } from '../..';
import { FileAsset } from '../../commonTypes';

/** Task page sub-state getter */
export const getTaskPageState = (state: StoreState): TaskPageState => state.taskPage!;

/** One identifier getter */
export const getId = (state: StoreState, id: string): string => id;

/** Identifiers list getter */
export const getIds = (state: StoreState, ids: string[]): string[] => uniq(compact(ids));

/** Sorting order getter (as second selector argument) */
export const getSortOrder = (state: StoreState, order: SortOrder = SortOrder.ASC): SortOrder => order;

/** Sorting order getter (as third selector argument) */
export const getSortOrderAsThird = (state: StoreState, arg1: never, order: SortOrder): SortOrder =>
    getSortOrder(state, order);

/** Sort assets by order criteria */
export const sortAssets = (assets: FileAsset[], order: AllAssetsSortOrder) => {
    const sortFields = order === AllAssetsSortOrder.UPLOAD_DATE ? ['createdAt'] : ['originName'];
    const result: FileAsset[] = sortBy(assets, sortFields);

    if (order !== AllAssetsSortOrder.NAME_ASC) {
        result.reverse();
    }

    return result;
};
