import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { Dispatch, AnyAction } from 'redux';
import { BudgetStatus } from '@mrm/budget';
import { uniq } from 'lodash';

import { BudgetItemApi } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getBudgetByStatusUserConfig } from '@store/userConfig/budget';

import * as asyncActions from './actions/async';
import * as actions from './actions/sync';

import { getMiscBudgetItemsState } from './selectors';

async function withLoadingStatusUpdate<R>(
    dispatch: Dispatch<AnyAction>,
    callback: () => Promise<R>,
): Promise<R | null> {
    dispatch(actions.setLoadingStatus(LoadingStatus.LOADING));

    try {
        const res = await callback();
        dispatch(actions.setLoadingStatus(LoadingStatus.LOADED));

        return res;
    } catch (e) {
        dispatch(actions.setLoadingStatus(LoadingStatus.ERROR));
    }

    return null;
}

async function fetchByActivityIds(
    dispatch: Dispatch<AnyAction>,
    state: StoreState,
    activityIds: string[],
): Promise<void> {
    if (activityIds.length) {
        const { budgetId } = getBudgetByStatusUserConfig(state, BudgetStatus.Plan);

        const budgetItems = await withLoadingStatusUpdate(dispatch, () =>
            BudgetItemApi.getBudgetItemList({
                budgetId,
                activityIds,
            }),
        );

        if (budgetItems) {
            dispatch(actions.addBudgetItems(budgetItems));
        }
    }
}

export const loadByActivityId = bindThunkAction<StoreState, string[], void, Error>(
    asyncActions.loadByActivityId,
    async (activityIds, dispatch, getState) => {
        const state = getState();
        const { byActivityId } = getMiscBudgetItemsState(state).stores;
        const activityIdsToLoad = activityIds.filter((activityId) => !byActivityId[activityId]);

        await fetchByActivityIds(dispatch, state, activityIdsToLoad);
    },
);

export const loadById = bindThunkAction<StoreState, string[], void, Error>(
    asyncActions.loadById,
    async (ids, dispatch, getState) => {
        const state = getState();
        const { budgetId } = getBudgetByStatusUserConfig(state, BudgetStatus.Execution);
        const { byId } = getMiscBudgetItemsState(state).stores;

        const idsToLoad = ids.reduce((acc, id) => {
            if (byId[id]) {
                return acc;
            }

            return [...acc, id];
        }, []);

        if (idsToLoad.length) {
            const activityIdsToLoad = uniq(
                (
                    await withLoadingStatusUpdate(dispatch, () =>
                        BudgetItemApi.getBudgetItemList({
                            budgetId,
                            filter: { id: idsToLoad },
                        }),
                    )
                ).map((budgetItem) => budgetItem.activity.id),
            );

            await fetchByActivityIds(dispatch, state, activityIdsToLoad);
        }
    },
);
