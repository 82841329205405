import * as React from 'react';
import autobind from 'autobind-decorator';

import { CardGroupTemplate } from './CardGroupTemplate';

// const DEFAULT_MIN_COLUMN_WIDTH = 290;
// const DEFAULT_MARGIN = 16;

interface Props {
    cards: JSX.Element[];
    minCardWidth: number;
    cardsMargin: number;
}

interface State {
    columnsCount: number;
}

export class CardGroupBehaviour extends React.PureComponent<Props, State> {
    private rootRef: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: Props) {
        super(props);

        this.state = {
            columnsCount: this.getColumnsCount(),
        };
    }

    public componentDidMount() {
        this.updateColumnsCount();

        window.addEventListener('resize', this.onPageResize);
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.onPageResize);
    }

    public render(): JSX.Element {
        return React.createElement(CardGroupTemplate, {
            cards: this.props.cards,
            cardsMargin: this.props.cardsMargin,
            columnsCount: this.state.columnsCount,
            rootRef: this.rootRef,
        });
    }

    @autobind
    protected onPageResize() {
        this.updateColumnsCount();
    }

    private updateColumnsCount() {
        const newColumnsCount = this.getColumnsCount();

        if (newColumnsCount !== this.state.columnsCount) {
            this.setState({
                columnsCount: newColumnsCount,
            });
        }
    }

    private getColumnsCount(): number {
        const { minCardWidth, cardsMargin } = this.props;

        let columnsCount = 1;

        if (this.rootRef.current) {
            const rootWidth = this.rootRef.current.offsetWidth;

            let width = rootWidth - cardsMargin * 2 - minCardWidth;

            while (width >= minCardWidth + cardsMargin) {
                width -= minCardWidth + cardsMargin;

                columnsCount += 1;
            }
        }

        return columnsCount;
    }
}
