import * as React from 'react';
import { TaskCardParams } from 'sber-marketing-types/frontend';

import { DatesFormatter } from '@common/Utils';

import * as styles from './StageTasksPopup.scss';

interface Props {
    stageName: string;
    stageStart?: Date;
    stageEnd?: Date;
    tasks: TaskCardParams[];
}

export function StageTasksPopup({ stageName, stageStart, stageEnd, tasks }: Props): JSX.Element {
    const currentDate = new Date();
    const duration =
        stageStart && stageEnd ? `(${DatesFormatter.ddMonth(stageStart)} - ${DatesFormatter.ddMonth(stageEnd)})` : '';

    return (
        <div className={styles.root}>
            <div className={styles.title}>
                {stageName} {duration}
            </div>

            {tasks.map((task) => {
                const taskDeadline = task.deadline instanceof Date ? task.deadline : new Date(task.deadline);
                const deadlineIsOver = taskDeadline < currentDate;

                return (
                    <div key={task.id} className={styles.task}>
                        🔹&nbsp;{task.title}&nbsp;
                        <span className={deadlineIsOver ? styles.taskRed : styles.taskGray}>
                            ({DatesFormatter.ddMonthyyyy(taskDeadline)})
                        </span>
                    </div>
                );
            })}
        </div>
    );
}
