import * as React from 'react';
import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';

import * as styles from './Flex.scss';

export interface FlexOnlyProps<E extends keyof JSX.IntrinsicElements = 'div'> {
    vertical?: boolean;
    loading?: boolean;
    loadingOffset?: number | [number, number] | [number, number, number] | [number, number, number, number];
    align?: React.CSSProperties['alignItems'];
    justify?: React.CSSProperties['justifyContent'];
    gap?: number | [number, number];
    flex?: number | boolean;
    wrap?: boolean;
    inline?: boolean;
    reverse?: boolean;
    element?: E;
    padding?: number | [number, number] | [number, number, number] | [number, number, number, number];
    rootRef?: React.MutableRefObject<any>;
}

export type FlexProps<E extends keyof JSX.IntrinsicElements = 'div'> = Omit<
    E extends 'a' ? LinkProps : JSX.IntrinsicElements[E],
    keyof FlexOnlyProps<E>
> &
    FlexOnlyProps<E>;

export function Flex<E extends keyof JSX.IntrinsicElements = 'div'>({
    element,
    className,
    children,
    style,
    wrap,
    vertical,
    loading,
    loadingOffset,
    align,
    justify,
    gap,
    flex,
    inline,
    reverse,
    padding,
    rootRef,
    ...props
}: FlexProps<E>) {
    const Element = element === 'a' ? Link : (element as any) || 'div';
    const normLoadingOffset: any[] = !loadingOffset
        ? []
        : !Array.isArray(loadingOffset)
        ? [loadingOffset, loadingOffset, loadingOffset, loadingOffset]
        : loadingOffset.length === 2
        ? [loadingOffset[0], loadingOffset[1], loadingOffset[0], loadingOffset[1]]
        : loadingOffset.length === 3
        ? [loadingOffset[0], loadingOffset[1], loadingOffset[2], loadingOffset[1]]
        : loadingOffset;

    const refProps =
        element === 'a'
            ? {
                  ...props,
                  innerRef: rootRef,
              }
            : {
                  ...props,
                  ref: rootRef,
              };

    return (
        <Element
            data-qa-loading={loading === undefined ? undefined : loading}
            {...refProps}
            style={{
                display: inline ? 'inline-flex' : 'flex',
                flexWrap: wrap ? 'wrap' : undefined,
                justifyContent: justify,
                alignItems: align,
                gap: gap && Array.isArray(gap) ? `${gap[0]}px ${gap[1]}px` : `${gap}px`,
                flex: flex === true ? 1 : flex,
                flexDirection: vertical ? (reverse ? 'column-reverse' : 'column') : reverse ? 'row-reverse' : undefined,
                padding: padding && Array.isArray(padding) ? `${padding.join('px ')}px` : `${padding}px`,
                '--skeleton-offset-top': normLoadingOffset[0],
                '--skeleton-offset-right': normLoadingOffset[1],
                '--skeleton-offset-bottom': normLoadingOffset[2],
                '--skeleton-offset-left': normLoadingOffset[3],
                ...style,
            }}
            className={classNames(loading !== undefined && styles.loadable, loading && styles.loading, className)}
        >
            {children}
        </Element>
    );
}
