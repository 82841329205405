import { actionCreatorFactory } from 'typescript-fsa';
import { BudgetItem } from '@mrm/budget';

import { MultiReferenceDictionaryApi } from '@api';

import {
    PageData,
    ColumnsWidth,
    ColumnName,
    ColumnsVisiblityFilter,
    SortingMode,
    Filters,
    ChangeList,
    LineStatusChange,
    BudgetItemTypes,
    LoadFiltersPaylaod,
    SetFiltersLoadingStatusPayload,
    ResetFilterPayload,
    AppliedFiltersNames,
    SetPreviouslyLoadedFilterPayload,
    SetPreviouslyLoadedFiltersPayload,
    BudgetItemApproversToDisplay,
    XLSXImportErrorState,
} from '../types';

const actionCreator = actionCreatorFactory('BUDGET_PLANNING_PAGE_SYNC');

const loadPageData = actionCreator<PageData>('LOAD_PAGE_DATA');
const loadBudgetItems = actionCreator<BudgetItem[]>('LOAD_BUDGET_ITEM');
const initUnsavedChanges = actionCreator<ChangeList>('INIT_UNSAVED_CHANGES');
const resetPageStore = actionCreator('RESET_PAGE_STORE');
const setColumnsVisiblityFilter = actionCreator<ColumnsVisiblityFilter>('SET_COLUMNS_VISIBLITY_FILTER');
const setSortingMode = actionCreator<SortingMode>('SET_SORTING_MODE');
const setFilters = actionCreator<Filters>('SET_FILTERS');
const resetFilters = actionCreator('RESET_FILTERS');
const resetFilter = actionCreator<ResetFilterPayload>('RESET_FILTER');
const resetViewSettings = actionCreator('RESET_VIEW_SETTINGS');
const setColumnsWidth = actionCreator<ColumnsWidth>('SET_COLUMNS_WIDTH');
const setResizingColumnName = actionCreator<ColumnName>('SET_RESIZING_COLUMN_NAME');
const setDisplayOnlyUnapproved = actionCreator<boolean>('SET_DISPLAY_ONLY_UNAPPROVED');
const setDisplayOnlyWithSnapshots = actionCreator<boolean>('SET_DISPLAY_ONLY_WITH_SNAPSHOTS');
const setDisplayDisabledLines = actionCreator<boolean>('SET_DISPLAY_DISABLED_LINES');
const setValidationStatus = actionCreator<boolean>('TOGGLE_VALIDATION_STATUS');
const toggleColumnFix = actionCreator<ColumnName>('TOGGLE_COLUMN_FIX');
const setFixedColumnsNames = actionCreator<ColumnName[]>('SET_FIXED_COLUMNS_NAMES');
const undoUnsavedChanges = actionCreator('UNDO_UNSAVED_CHANGES');
const redoUnsavedChanges = actionCreator('RETURN_UNSAVED_CHANGES');
const setLineStatus = actionCreator<LineStatusChange>('SET_LINE_STATUS');
const deleteLineStatus = actionCreator<string>('DELETE_LINE_STATUS');
const setRejectMenuLineId = actionCreator<string>('SET_REJECT_MENU_LINE_ID');
const setApproverMenuLineId = actionCreator<string>('SET_APPROVER_MENU_LINE_ID');
const setApproverStatus = actionCreator<LineStatusChange>('SET_APPROVER_STATUS');
const clearLineStatuses = actionCreator<string[]>('CLEAR_LINE_STATUSES');
const clearApproverStatuses = actionCreator<string[]>('CLEAR_APPROVER_STATUSES');
const clearUnsavedChanges = actionCreator('CLEAR_UNSAVED_CHANGES');
const clearUnsavedChangesByLines = actionCreator<string[]>('CLEAR_UNSAVED_CHANGES_BY_LINES');
const setErrorPopupStatus = actionCreator<boolean>('SET_ERROR_POPUP_STATUS');
const setPreloaderStatus = actionCreator<boolean>('SET_PRELOADER_STATUS');
const resetChangesHistory = actionCreator('RESET_CHANGES_HISTORY');
const setBudgetItemsToDisplay = actionCreator<BudgetItemTypes>('SET_BUDGET_ITEMS_TO_DISPLAY');
const loadFilters = actionCreator<LoadFiltersPaylaod>('LOAD_FILTERS_PAYLOAD');
const incLoadingFiltersCount = actionCreator('INC_LOADING_FITLERS_COUNT');
const decLoadingFiltersCount = actionCreator('DEC_LOADING_FILTERS_COUNT');
const setFiltersLoadingStatus = actionCreator<SetFiltersLoadingStatusPayload>('SET_FILTERS_LOADING_STATUS');
const setFiltersPreloaderStatus = actionCreator<boolean>('SET_FILTERS_PRELOADER_STATUS');
const updateInProgressData = actionCreator('UPDATE_IN_PROGRESS_DATA');
const initPreviouslyLoadedFilter = actionCreator('INIT_PREVIOUSLY_LOADER_FILTER');
const setPreviouslyLoadedFilter = actionCreator<SetPreviouslyLoadedFilterPayload>('SET_PREVIOUSLY_LOADER_FILTER');
const setPreviouslyLoadedFilters = actionCreator<SetPreviouslyLoadedFiltersPayload>('SET_PREVIOUSLY_LOADED_FILTERS');
const resetPreviouslyLoadedFilters = actionCreator('RESET_PREVIOUSLY_LOADED_FILTERS');
const resetBudgetRelatedData = actionCreator('RESET_BUDGET_RELATED_DATA');
const setAppliedFiltersNames = actionCreator<AppliedFiltersNames>('SET_APPLIED_FILTERS_NAMES');
const setRejectionCommentPopupVisibility = actionCreator<boolean>('SET_REJECTION_COMMENT_POPUP_VISIBILITY');
const setBudgetItemsApproversToDisplay = actionCreator<BudgetItemApproversToDisplay>(
    'SET_BUDGET_ITEMS_APPROVERS_TO_DISPLAY',
);
const setXLSXImportErrorState = actionCreator<XLSXImportErrorState>('SET_XLSX_IMPORT_ERROR_STATE');
const setShowTagsHaveChangedMarker = actionCreator<boolean>('SET_SHOW_TAGS_HAVE_CHANGED_MARKER');
const setShowOnlyLinesWithPlanBudget = actionCreator<boolean>('SET_SHOW_ONLY_LINES_WITH_PLAN_BUDGET');
const setShowOnlyLinesWithoutPlanBudget = actionCreator<boolean>('SET_SHOW_ONLY_LINES_WITHOUT_PLAN_BUDGET');
const setMultiReferenceDictionaryApi = actionCreator<MultiReferenceDictionaryApi>('SET_MULTI_REFERENCE_DICTIONARY_API');

export {
    loadPageData,
    initUnsavedChanges,
    resetPageStore,
    setColumnsVisiblityFilter,
    setSortingMode,
    setFilters,
    resetFilters,
    resetFilter,
    resetViewSettings,
    setResizingColumnName,
    setColumnsWidth,
    setDisplayOnlyUnapproved,
    setDisplayOnlyWithSnapshots,
    setDisplayDisabledLines,
    setValidationStatus,
    toggleColumnFix,
    setFixedColumnsNames,
    undoUnsavedChanges,
    redoUnsavedChanges,
    setLineStatus,
    deleteLineStatus,
    setRejectMenuLineId,
    setApproverMenuLineId,
    setApproverStatus,
    clearApproverStatuses,
    clearLineStatuses,
    clearUnsavedChanges,
    clearUnsavedChangesByLines,
    setErrorPopupStatus,
    setPreloaderStatus,
    resetChangesHistory,
    setBudgetItemsToDisplay,
    loadFilters,
    incLoadingFiltersCount,
    decLoadingFiltersCount,
    setFiltersLoadingStatus,
    setFiltersPreloaderStatus,
    updateInProgressData,
    loadBudgetItems,
    initPreviouslyLoadedFilter,
    setPreviouslyLoadedFilter,
    setPreviouslyLoadedFilters,
    resetPreviouslyLoadedFilters,
    resetBudgetRelatedData,
    setRejectionCommentPopupVisibility,
    setAppliedFiltersNames,
    setBudgetItemsApproversToDisplay,
    setXLSXImportErrorState,
    setShowTagsHaveChangedMarker,
    setShowOnlyLinesWithPlanBudget,
    setShowOnlyLinesWithoutPlanBudget,
    setMultiReferenceDictionaryApi,
};
