// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-calendar-SwitchKeyActivityButton-_SwitchKeyActivityButton__root{display:flex;height:24px;padding:2px 8px 2px 4px;cursor:pointer;letter-spacing:0.15px;color:#7e8681;border:solid 1.3px #ebeeec;border-radius:4px;font-family:\"Open Sans\";font-size:12px;align-items:center}.src-client-modules-calendar-SwitchKeyActivityButton-_SwitchKeyActivityButton__root.src-client-modules-calendar-SwitchKeyActivityButton-_SwitchKeyActivityButton__selected{color:#18b84e;border:solid 1.3px #e9fff0;background-color:#e9fff0}.src-client-modules-calendar-SwitchKeyActivityButton-_SwitchKeyActivityButton__text{margin-top:-1px;letter-spacing:0.15px;font-family:\"Open Sans\";font-size:12px}.src-client-modules-calendar-SwitchKeyActivityButton-_SwitchKeyActivityButton__flagTogglePosition{margin-right:5px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/calendar/SwitchKeyActivityButton/SwitchKeyActivityButton.scss"],"names":[],"mappings":"AAAA,oFACI,YAAa,CAEb,WAAY,CACZ,uBAAwB,CAExB,cAAe,CACf,qBAAsB,CAEtB,aAAc,CACd,0BAA2B,CAC3B,iBAAkB,CAElB,uBAAwB,CACxB,cAAe,CAEf,kBAAmB,CAhBvB,2KAmBQ,aAAc,CACd,0BAA2B,CAC3B,wBAAyB,CAC5B,oFAID,eAAgB,CAEhB,qBAAsB,CAEtB,uBAAwB,CACxB,cAAe,CAClB,kGAGG,gBAAiB","sourcesContent":[".root {\n    display: flex;\n\n    height: 24px;\n    padding: 2px 8px 2px 4px;\n\n    cursor: pointer;\n    letter-spacing: 0.15px;\n\n    color: #7e8681;\n    border: solid 1.3px #ebeeec;\n    border-radius: 4px;\n\n    font-family: \"Open Sans\";\n    font-size: 12px;\n\n    align-items: center;\n\n    &.selected {\n        color: #18b84e;\n        border: solid 1.3px #e9fff0;\n        background-color: #e9fff0;\n    }\n}\n\n.text {\n    margin-top: -1px;\n\n    letter-spacing: 0.15px;\n\n    font-family: \"Open Sans\";\n    font-size: 12px;\n}\n\n.flagTogglePosition {\n    margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-calendar-SwitchKeyActivityButton-_SwitchKeyActivityButton__root",
	"selected": "src-client-modules-calendar-SwitchKeyActivityButton-_SwitchKeyActivityButton__selected",
	"text": "src-client-modules-calendar-SwitchKeyActivityButton-_SwitchKeyActivityButton__text",
	"flagTogglePosition": "src-client-modules-calendar-SwitchKeyActivityButton-_SwitchKeyActivityButton__flagTogglePosition"
};
export default ___CSS_LOADER_EXPORT___;
