// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-DownloadAllButton-_DownloadAllButton__root{font-family:'Open Sans';font-weight:400;font-size:12px;line-height:16px;color:#7E8681;cursor:pointer;display:flex;align-items:center;gap:6px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/DownloadAllButton/DownloadAllButton.scss"],"names":[],"mappings":"AAAA,sEACI,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,aAAc,CAEd,cAAe,CAEf,YAAa,CACb,kBAAmB,CACnB,OAAQ","sourcesContent":[".root {\n    font-family: 'Open Sans';\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 16px;\n    color: #7E8681;\n\n    cursor: pointer;\n\n    display: flex;\n    align-items: center;\n    gap: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-DownloadAllButton-_DownloadAllButton__root"
};
export default ___CSS_LOADER_EXPORT___;
