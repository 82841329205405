import * as React from 'react';

import { Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';

interface Props {
    disabled: boolean;
    onClick: () => void;
}

export const ApproveButton: React.FC<Props> = ({ disabled, onClick }) => {
    return (
        <div>
            <Button
                style={{
                    height: 32,
                    paddingLeft: 16,
                    paddingRight: 16,
                }}
                theme={ButtonTheme.MainRounded}
                disabled={disabled}
                onClick={onClick}
            >
                Согласовать
            </Button>
        </div>
    );
};
