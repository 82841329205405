import autobind from 'autobind-decorator';
import { PureComponent, createElement } from 'react';
import { isFunction, uniqBy, differenceBy } from 'lodash';

import { ChannelEditorContainerProps as Props, ChannelEditorContainerState as State } from './types';
import { ChannelEditorTemplate } from './ChannelEditorTemplate';

/** "ChannelEditor" container component */
export class ChannelEditorContainer extends PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        const { channelName, channelParticipantsIds, authorId, isUserAuthor } = props;

        this.state = {
            selectedUsers: [
                ...channelParticipantsIds.map((userId) => ({
                    userId,
                    canRemove: isUserAuthor,
                })),
                {
                    userId: authorId,
                    canRemove: false,
                },
            ],
            channelName,
        };
    }

    public render() {
        const { className, taskParticipantsIds, isUserAuthor, isNew, authorId } = this.props;

        return createElement(ChannelEditorTemplate, {
            ...this.state,
            authorId,
            className,
            taskParticipantsIds,
            showNameInput: isNew || isUserAuthor,
            onChannelNameChange: this.onChannelNameChange,
            onSubmit: this.onSubmit,
            onCancel: this.onCancel,
            onAddParticipants: this.onAddParticipants,
            onRemoveParticipants: this.onRemoveParticipants,
        });
    }

    /** Channel name change handler */
    @autobind
    private onChannelNameChange(channelName: string): void {
        this.setState(() => ({ channelName }));
    }

    @autobind
    private onAddParticipants(addedUsers: number[]): void {
        this.setState((state) => ({
            selectedUsers: uniqBy(
                [
                    ...state.selectedUsers,
                    ...addedUsers.map((userId) => ({
                        userId,
                        canRemove: true,
                    })),
                ],
                (user) => user.userId,
            ),
        }));
    }

    @autobind
    private onRemoveParticipants(usersToRemove: number[]): void {
        this.setState((state) => ({
            selectedUsers: differenceBy(
                state.selectedUsers,
                usersToRemove.map((userId) => ({
                    userId,
                    canRemove: true,
                })),
                (user) => user.userId,
            ),
        }));
    }

    /** Submit button click handler */
    @autobind
    private onSubmit(): void {
        const { channelName: title, selectedUsers: participants } = this.state;
        this.props.saveChannel({
            title,
            participantIds: participants
                .map((user) => user.userId)
                .filter((id) => id !== this.props.authorId)
                .join(','),
        });
        if (isFunction(this.props.onSubmit)) {
            this.props.onSubmit();
        }
    }

    /** Cancel button click handler */
    @autobind
    private onCancel(): void {
        if (isFunction(this.props.onCancel)) {
            this.props.onCancel();
        }
    }
}
