import { Month } from '@mrm/budget';

export type { CreativeRequestDomain, CreativeRequestDonor } from '@store/creative';

export type { Permissions } from './WithPermissions';
export type { BudgetItem } from './WithBudgetItems';

export const MONTHS = [
    Month.Jan,
    Month.Feb,
    Month.Mar,
    Month.Apr,
    Month.May,
    Month.Jun,
    Month.Jul,
    Month.Aug,
    Month.Sept,
    Month.Oct,
    Month.Nov,
    Month.Dec,
];
