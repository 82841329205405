import * as React from 'react';
import * as moment from 'moment';
import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import { selectors } from '@store/taskPage';
import { FileAsset } from '@store/commonTypes';

import { CommentLikeCard } from '../CommentLikeCard';
import { Description } from '../Description';
import { TopLine, TopLineProps } from '../TopLine';
import { FileAssetListWithGallery } from '../../../../../common/FileAssetListWithGallery';

import * as styles from './TaskInfo.scss';

const CREATED_AT_TITLE = 'Создана';
const DEADLINE_AT = 'Дедлайн';
const LEADER_TITLE = 'Руководитель';

/** Task information component properties */
export interface TaskInfoProps {
    taskId: string;
    /** Date of creation at numeric format */
    createdAt: number | string | Date;
    /** Date of deadline at numeric format */
    deadlineAt: number | string | Date;
    /** Name task's leader */
    leader: string;
    /** Task description */
    description: string;
    /** Additional class name */
    className?: string;
    canDeleteFiles: boolean;
    /** Asset remove click handler */
    onAssetRemoveClick?(data: FileAsset): void | Promise<void>;
}

/** Task information component */
export function TaskInfo({
    taskId,
    createdAt,
    deadlineAt,
    leader,
    description,
    className,
    canDeleteFiles,
    onAssetRemoveClick,
}: TaskInfoProps): JSX.Element {
    const assets = useSelector((state: StoreState) =>
        selectors.getTaskAssets(state).map((item) => ({ ...item, hideDate: true })),
    );

    const topLineProps: TopLineProps = {
        left: [
            {
                title: CREATED_AT_TITLE,
                value: convertDate(createdAt),
                qaId: 'taskInfoCreatedAt',
            },
            {
                title: DEADLINE_AT,
                value: convertDate(deadlineAt),
                qaId: 'taskInfoDeadline',
            },
        ],
        right: {
            title: LEADER_TITLE,
            value: leader,
        },
    };
    return (
        <CommentLikeCard className={className}>
            <div
                {...{
                    'qa-id': 'taskInfo',
                }}
            >
                <TopLine {...topLineProps} />
                <Description description={description} qaId="taskInfoDescription" className={styles.description} />
                {assets.length > 0 && (
                    <FileAssetListWithGallery
                        useR7Controls
                        canEditR7Files
                        taskId={taskId}
                        assets={assets}
                        className={styles.assets}
                        canDelete={canDeleteFiles}
                        onRemove={onAssetRemoveClick}
                    />
                )}
            </div>
        </CommentLikeCard>
    );
}

/** Convert date to needed form */
function convertDate(date: number | string | Date): string {
    return moment(date).format('DD MMMM');
}
