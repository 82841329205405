import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { isNil, includes } from 'lodash';
import { RoleId } from 'sber-marketing-types/backend';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';
import { getPriorityLoadingStatus } from '@store/common/utils';
import { LoadingStatus } from '@store/commonTypes';
import { withUserOrganizationsIds } from '../../withUserOrganizationsIds';
import { withAppUsers } from '../../withAppUsers';
import { getIsActivitySharingInProgress, getActivityById, shareActivity } from '@store/activitiesList';
import { ActivitySharingContainer } from './ActivitySharingContainer';
import {
    ActivitySharingStoreProps,
    ActivitySharingMappedState,
    ActivitySharingMappedActions,
    ActivitySharingContainerProps,
    PropsWithHOCs,
} from './types';

const mapStateToProps = (
    state: StoreState,
    { activity, activityId }: PropsWithHOCs<ActivitySharingStoreProps>,
): ActivitySharingMappedState => {
    const loginnedUserId = getLoginUser(state).attributes.id;
    const userIsDirector = getLoginUser(state).attributes.roles.some((role) => role.id === RoleId.Director);

    if (activity) {
        return {
            loginnedUserId,
            authorId: activity.authorId,
            responsibleId: activity.responsible.id,
            isActivitySharingInProgress: getIsActivitySharingInProgress(state),
            ownerships: isNil(activity) ? [] : activity.participantIds,
            userIsDirector,
        };
    }

    const activityFromStore = getActivityById(state, activityId);
    return {
        loginnedUserId,
        authorId: activityFromStore.author.id,
        responsibleId: activityFromStore.responsible.id,
        isActivitySharingInProgress: getIsActivitySharingInProgress(state),
        ownerships: isNil(activityFromStore) ? [] : activityFromStore.ownerships,
        userIsDirector,
    };
};

const mapDispatchToProps = (
    dispatch: Dispatch<StoreState>,
    { activity, activityId }: PropsWithHOCs<ActivitySharingStoreProps>,
): ActivitySharingMappedActions => ({
    shareActivity: (usersIds: number[]) =>
        dispatch(
            shareActivity({
                activityId: activity ? activity.id : activityId,
                usersIds,
            }),
        ),
});

const mergeProps = (
    { ownerships, ...state }: ActivitySharingMappedState,
    actions: ActivitySharingMappedActions,
    {
        onClose,
        appUsers,
        appUsersLoadingStatus,
        userOrganizationsIds,
        userOrganizationsIdsLoadingStatus,
    }: PropsWithHOCs<ActivitySharingStoreProps>,
): ActivitySharingContainerProps => ({
    ...state,
    ...actions,
    onClose,
    ownerships: ownerships.filter((userId) => userId !== state.authorId && userId !== state.responsibleId),
    appUsers: appUsers.filter(
        (user) =>
            includes(userOrganizationsIds, user.organizationId) &&
            user.id !== state.authorId &&
            user.id !== state.responsibleId &&
            user.isActive,
    ),
    isLoading:
        getPriorityLoadingStatus(appUsersLoadingStatus, userOrganizationsIdsLoadingStatus) !== LoadingStatus.LOADED,
});

const withStore = connect(mapStateToProps, mapDispatchToProps, mergeProps);

/** "ActivitySharing" store component */
export const ActivitySharingStore: ComponentType<ActivitySharingStoreProps> = withUserOrganizationsIds(
    withAppUsers(withStore(ActivitySharingContainer)),
);
