import { LoadingStatus } from '@store/commonTypes';
import type {
    CreativeRequest as CreativeRequestDomain,
    CreativeRequestBudgetItem as CreativeRequestBudgetItemDomain,
    CreativeRequestParam as CreativeRequestParamDomain,
} from '@api';

interface CreativeRequest {
    id: string;
    name: string;
    budgetItems: BudgetItem[];
}

interface BudgetItem {
    id: string;
    serialNumber: string;
    activityName: string;
}

export interface State {
    params: Params;
    data: Data;
}

export interface Params {
    selectedCreativeRequestId: string;
    selectedBudgetItemIds: string[];
}

export interface Data {
    creativeRequests: CreativeRequestsData;
    budgetItems: BudgetItemsData;
}

export interface CreativeRequestsData {
    loading: LoadingStatus;
    items: Record<string, CreativeRequest>;
}

export interface BudgetItemsData {
    loading: LoadingStatus;
    items: Record<string, BudgetItem>;
}

export interface InitParams {
    creativeRequestId: string;
    budgetId: string;
}
export interface InitResult {
    creativeRequests: Record<string, CreativeRequest>;
    budgetItems: Record<string, BudgetItem>;
}

export interface SetBudgetParams {
    budgetId: string;
}

export interface SetBudgetResult {
    creativeRequests: Record<string, CreativeRequest>;
    budgetItems: Record<string, BudgetItem>;
}

export interface UpdateParams {
    budgetId: string;
}

export interface UpdateResult {
    creativeRequests: Record<string, CreativeRequest>;
    budgetItems: Record<string, BudgetItem>;
}

export interface SetSelectedBudgetItemIdsParams {
    selectedBudgetItemIds: string[];
}

export type {
    CreativeRequestDomain,
    CreativeRequestBudgetItemDomain,
    CreativeRequestParamDomain,
    CreativeRequest,
    BudgetItem,
};
export { LoadingStatus };
