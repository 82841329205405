import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { LoadingStatus } from '@store/commonTypes';

import {
    TransferBudgetItemsToPlanningMenuState as State,
    TransferBudgetItemsToPlanningMenuComponentProps as Props,
    TransferBudgetItemsToPlanningMenuComponentData as Data,
    TransferBudgetItemsToPlanningMenuPlanningBudgets as PlanningBudgets,
    TransferBudgetItemsToPlanningMenuActivityBudgetItems as ActivityBudgetItems,
    ComponentState,
} from './types';
import * as actions from './actions/sync';

class Reducer {
    public static makeInitialState(): State {
        return {
            props: {
                componentState: ComponentState.Closed,
                activityToSelectLines: null,
                linesToTransfer: [],
                planningBudgetId: null,
                hoveredLineId: null,
            },
            data: {
                planningBudgets: {
                    loadingStatus: LoadingStatus.NOT_LOADED,
                    items: [],
                },
                activityBudgetItems: {
                    loadingStatus: LoadingStatus.NOT_LOADED,
                    items: [],
                },
            },
        };
    }

    public static setComponentState(state: State, componentState: ComponentState): State {
        return Reducer.propsReducer(state, { componentState });
    }

    public static setActivityToTransferLines(state: State, activityToSelectLines: string): State {
        return Reducer.propsReducer(state, { activityToSelectLines });
    }

    public static setLinesToTransfer(state: State, linesToTransfer: string[]): State {
        return Reducer.propsReducer(state, { linesToTransfer });
    }

    public static setPlanningBudgetId(state: State, planningBudgetId: string): State {
        return Reducer.propsReducer(state, { planningBudgetId });
    }

    public static setHoveredLineId(state: State, hoveredLineId: string): State {
        return Reducer.propsReducer(state, { hoveredLineId });
    }

    public static resetComponentState(state: State): State {
        const initialState = Reducer.makeInitialState();

        return {
            props: {
                ...initialState.props,
                planningBudgetId: state.data.planningBudgets.items.length === 1 ? state.props.planningBudgetId : null,
            },
            data: {
                ...initialState.data,
                planningBudgets: state.data.planningBudgets,
            },
        };
    }

    public static planningBudgetsReducer(state: State, planningBudgets: Partial<PlanningBudgets>): State {
        return Reducer.dataReducer(state, {
            ...state.data,
            planningBudgets: { ...state.data.planningBudgets, ...planningBudgets },
        });
    }

    public static activityBudgetItemsReducer(state: State, activityBudgetItems: Partial<ActivityBudgetItems>): State {
        return Reducer.dataReducer(state, {
            ...state.data,
            activityBudgetItems: { ...state.data.activityBudgetItems, ...activityBudgetItems },
        });
    }

    public static propsReducer(state: State, props: Partial<Props>): State {
        return {
            ...state,
            props: { ...state.props, ...props },
        };
    }

    public static dataReducer(state: State, data: Partial<Data>): State {
        return {
            ...state,
            data: { ...state.data, ...data },
        };
    }
}

export const transferBudgetItemsToPlanningMenuReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(actions.setComponentState, Reducer.setComponentState)
    .case(actions.setActivityToTransferLines, Reducer.setActivityToTransferLines)
    .case(actions.setLinesToTransfer, Reducer.setLinesToTransfer)
    .case(actions.setPlanningBudgetId, Reducer.setPlanningBudgetId)
    .case(actions.setHoveredLineId, Reducer.setHoveredLineId)
    .case(actions.resetComponentState, Reducer.resetComponentState)
    .case(actions.setPlanningBudgets, Reducer.planningBudgetsReducer)
    .case(actions.setActivityBudgetItems, Reducer.activityBudgetItemsReducer);
