import * as React from 'react';
import autobind from 'autobind-decorator';
import { positionValues } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { StoreState } from '@store';
import {
    ComponentState,
    SearchEntities,
    getTotalCount,
    getSearchState,
    showEntitiesGroup,
    shouldAnimate,
    areEntitiesLoading,
    setAnimatedGroup,
    triggerFetch,
} from '@store/search';

import { Results } from './Results';

interface Props extends Partial<MapProps & DispatchProps> {}

interface MapProps {
    shouldTriggerFetch: boolean;
    areEntitiesLoading: boolean;
    totalCount: number;
    showActivitiesGroup: boolean;
    shouldAnimateActivitiesGroup: boolean;
    showTasksGroup: boolean;
    shouldAnimateTasksGroup: boolean;
    showCommentsGroup: boolean;
    shouldAnimateCommentsGroup: boolean;
    showFilesGroup: boolean;
    shouldAnimateFilesGroup: boolean;
    expandedGroup: SearchEntities;
    animatedGroup: SearchEntities;
}

interface DispatchProps {
    setAnimatedGroup(element: HTMLDivElement): void;
    unsetAnimatedGroup(): void;
    triggerFetch(): void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class ResultsContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const {
            animatedGroup,
            areEntitiesLoading,
            totalCount,
            showActivitiesGroup,
            shouldAnimateActivitiesGroup,
            showTasksGroup,
            shouldAnimateTasksGroup,
            showCommentsGroup,
            shouldAnimateCommentsGroup,
            showFilesGroup,
            shouldAnimateFilesGroup,
            expandedGroup,
            setAnimatedGroup,
            unsetAnimatedGroup,
        } = this.props;

        return (
            <Results
                areEntitiesLoading={areEntitiesLoading}
                totalCount={totalCount}
                showActivitiesGroup={showActivitiesGroup}
                showTasksGroup={showTasksGroup}
                showCommentsGroup={showCommentsGroup}
                showFilesGroup={showFilesGroup}
                expandedGroup={expandedGroup}
                animatedGroup={animatedGroup}
                onAnimationStarted={setAnimatedGroup}
                onAnimationEnded={unsetAnimatedGroup}
                shouldAnimateFilesGroup={shouldAnimateFilesGroup}
                shouldAnimateActivitiesGroup={shouldAnimateActivitiesGroup}
                shouldAnimateTasksGroup={shouldAnimateTasksGroup}
                shouldAnimateCommentsGroup={shouldAnimateCommentsGroup}
                onScroll={this.onScroll}
            />
        );
    }

    @autobind
    private onScroll(positionValues: positionValues): void {
        const { shouldTriggerFetch, triggerFetch } = this.props;

        if (shouldTriggerFetch) {
            const { scrollHeight, scrollTop, clientHeight } = positionValues;
            const haveReachedEnd = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

            if (haveReachedEnd) {
                triggerFetch();
            }
        }
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { componentState, expandedGroup, animatedGroup } = getSearchState(state);

    return {
        shouldTriggerFetch: componentState === ComponentState.ShowResults && !!expandedGroup,
        expandedGroup,
        animatedGroup,
        areEntitiesLoading: areEntitiesLoading(state),
        totalCount: getTotalCount(state),
        showFilesGroup: showEntitiesGroup(state, SearchEntities.Files),
        shouldAnimateFilesGroup: shouldAnimate(state, SearchEntities.Files),
        showActivitiesGroup: showEntitiesGroup(state, SearchEntities.Activities),
        shouldAnimateActivitiesGroup: shouldAnimate(state, SearchEntities.Activities),
        showTasksGroup: showEntitiesGroup(state, SearchEntities.Tasks),
        shouldAnimateTasksGroup: shouldAnimate(state, SearchEntities.Tasks),
        showCommentsGroup: showEntitiesGroup(state, SearchEntities.Comments),
        shouldAnimateCommentsGroup: shouldAnimate(state, SearchEntities.Comments),
    };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
    return {
        setAnimatedGroup: (element: HTMLDivElement) =>
            dispatch(setAnimatedGroup(element.dataset.flipId as SearchEntities)),
        unsetAnimatedGroup: () => dispatch(setAnimatedGroup(null)),
        triggerFetch: () => dispatch(triggerFetch(null)),
    };
}
