import { axios, AxiosResponse } from '../lib/axios';
import { GetActivityStatusResult, GetActivityTasksStatusResult, SetActivityArchiveFilesPayload } from '@mrm/dam';

const BASE_URL = '/api/dam';

export class DAMApi {
    public static async getActivityStatus(activityId: number): Promise<GetActivityStatusResult> {
        const res: AxiosResponse<GetActivityStatusResult> = await axios.get(`${BASE_URL}/activity/${activityId}`);

        return res.data;
    }

    public static async getActivityTasksStatus(activityId: number): Promise<GetActivityTasksStatusResult> {
        const res: AxiosResponse<GetActivityTasksStatusResult> = await axios.get(
            `${BASE_URL}/activity/${activityId}/files`,
        );

        return res.data;
    }

    public static async setActivityArchiveFiles(
        activityId: number,
        params: SetActivityArchiveFilesPayload,
    ): Promise<void> {
        await axios.put(`${BASE_URL}/activity/${activityId}/archive`, params);
    }
}
