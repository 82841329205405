import * as React from 'react';
import { Moment } from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, IconType } from 'sber-marketing-ui';
import classnames from 'classnames';

import { StoreState } from '@store';
import {
    getBudgetCorrectionsFiltersAndExportState,
    setDateRangeStart,
    setDateRangeEnd,
} from '@store/budgetCorrections/filtersAndExport';

import { RangeDatepicker } from '@common/RangeDatepicker';

import * as styles from './DatesFilter.scss';

function useInteractivity() {
    const [isExpanded, setIsExpanded] = React.useState(true);

    const shouldRender = useSelector(
        (state: StoreState) => getBudgetCorrectionsFiltersAndExportState(state).currentFilters.isExportModeEnabled,
    );

    return {
        shouldRender,
        isExpanded,
        setIsExpanded,
    };
}

export function DatesFilter(): JSX.Element {
    const { shouldRender, isExpanded, setIsExpanded } = useInteractivity();

    return shouldRender ? (
        <div className={classnames(styles.root, isExpanded && styles.rootExpanded)}>
            <div className={styles.title} onClick={() => setIsExpanded(!isExpanded)}>
                Показать корректировки за период
                <Icon
                    svgSize={16}
                    className={styles.titleExpandIcon}
                    type={isExpanded ? IconType.ARROW16_UP_BLACK : IconType.ARROW16_DOWN_GRAY}
                />
            </div>

            <div className={styles.divider} />

            {isExpanded ? <Content /> : null}
        </div>
    ) : null;
}

function useContentInteractivity() {
    const dispatch = useDispatch();

    const maxDate = React.useMemo(() => new Date(), []);
    const dateRangeStart = useSelector(
        (state: StoreState) => getBudgetCorrectionsFiltersAndExportState(state).currentFilters.dateRangeStart,
    );
    const dateRangeEnd = useSelector(
        (state: StoreState) => getBudgetCorrectionsFiltersAndExportState(state).currentFilters.dateRangeEnd,
    );

    function onDateRangeStartChange(momentDate: Moment) {
        const date = momentDate ? momentDate.toDate() : null;

        dispatch(setDateRangeStart(date));
    }
    function onDateRangeEndChange(momentDate: Moment) {
        let date: Date = null;
        if (momentDate) {
            date = momentDate.toDate();
            date.setHours(23, 59, 59);
        }

        dispatch(setDateRangeEnd(date));
    }

    return {
        maxDate,
        dateRangeStart,
        dateRangeEnd,
        onDateRangeStartChange,
        onDateRangeEndChange,
    };
}

function Content(): JSX.Element {
    const { maxDate, dateRangeStart, dateRangeEnd, onDateRangeStartChange, onDateRangeEndChange } =
        useContentInteractivity();

    return (
        <div className={styles.content}>
            <div className={styles.dateRangeDatepicker}>
                <RangeDatepicker
                    maxDate={maxDate}
                    start={dateRangeStart}
                    end={dateRangeEnd}
                    onStartChange={onDateRangeStartChange}
                    onEndChange={onDateRangeEndChange}
                />
            </div>
        </div>
    );
}
