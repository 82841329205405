import { combineReducers } from 'redux';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { UserRelatedOrganizations } from 'sber-marketing-types/frontend';

import { loadUserOrganizations, resetUserOrganizations } from './actions';
import { resetStore } from '@store/common/actions';
import { LoadingStatus } from '@store/commonTypes';
import { UserOrganizations } from './types';

const status = reducerWithInitialState<LoadingStatus>(LoadingStatus.NOT_LOADED)
    .cases([resetUserOrganizations, resetStore], () => LoadingStatus.NOT_LOADED)
    .case(loadUserOrganizations.started, () => LoadingStatus.LOADING)
    .case(loadUserOrganizations.failed, () => LoadingStatus.ERROR)
    .case(loadUserOrganizations.done, () => LoadingStatus.LOADED);

const error = reducerWithInitialState<Error | null>(null)
    .cases([loadUserOrganizations.started, loadUserOrganizations.done, resetUserOrganizations, resetStore], () => null)
    .case(loadUserOrganizations.failed, (_, { error }) => error);

const data = reducerWithInitialState<UserRelatedOrganizations | null>(null)
    .cases(
        [loadUserOrganizations.started, loadUserOrganizations.failed, resetUserOrganizations, resetStore],
        () => null,
    )
    .case(loadUserOrganizations.done, (_, { result }) => result);

export const userOrganizations = combineReducers<UserOrganizations>({
    status,
    error,
    data,
});
