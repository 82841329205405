import * as React from 'react';

import { TabBarContext } from '../TabBarContext';

export const Content: React.FC = ({ children }) => {
    return (
        <TabBarContext.Consumer>
            {({ selectedTabIndex }) => (
                <React.Fragment>
                    {React.Children.map(children, (child, index) => {
                        return index === selectedTabIndex ? <>{child}</> : null;
                    })}
                </React.Fragment>
            )}
        </TabBarContext.Consumer>
    );
};
