import * as React from 'react';
import classNames from 'classnames';

import * as style from './FundsSelectCell.scss';

import { Icon, IconType } from 'sber-marketing-ui';
import { CellEditorWrapper } from '../CellEditorWrapper';

interface Props {
    title: string;
    description?: string;
    items: SelectItem[];
    selectedValue: React.ReactText;
    customStyle?: any;
    onValueChange: (value: React.ReactText) => void;
}

interface SelectItem {
    title: string;
    value: React.ReactText;
}

export const FundsSelectCellEditTemplate = ({
    title,
    description,
    items,
    selectedValue,
    customStyle,
    onValueChange,
}: Props): JSX.Element => {
    return (
        <div className={style.root} style={{ ...customStyle }}>
            <div className={style.title}>
                {title}

                <div className={style.currencySign}>₽</div>
            </div>

            {description && <div className={style.description}>{description}</div>}

            <div className={classNames(style.openerIcon, style.opened)}>
                <Icon type={IconType.TRIANGLE8_UP} svgSize={8} />
            </div>

            <CellEditorWrapper>
                <div className={style.content}>
                    {items.map((item) => (
                        <div key={item.value} className={style.item} onClick={() => onValueChange(item.value)}>
                            {item.title}

                            {item.value === selectedValue && (
                                <div className={style.checkIcon}>
                                    <Icon type={IconType.CHECK_ICON} svgSize={10} />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </CellEditorWrapper>
        </div>
    );
};
