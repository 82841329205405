import * as React from 'react';
import { render } from 'react-dom';
import { App } from '@common/App';
import * as moment from 'moment';

import './styles/colors.scss';

moment.locale('ru');
const app = <App />;
const root = document.getElementById('root');
render(app, root);
