/** Type of edition */
export enum CommentEditionType {
    /** Replace old content to new */
    REPLACE,
    /** Append new content to old */
    APPEND,
    /** Prepend new content to old */
    PREPEND,
    ADD_MENTION,
}
