import { FileAsset } from '@store/commonTypes';
import { FileResponse } from 'sber-marketing-types/frontend';

export function assetToFileResponse({ id, name, type, createdAt, size, storage, originName }: FileAsset): FileResponse {
    return {
        id,
        name,
        type,
        createTime: createdAt,
        originName,
        size,
        storage,
    };
}
