// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-task-pages-TaskPage-components-TaskPageContent-_TaskPageContent__root{flex:1;padding:24px;display:flex;flex-direction:column;gap:16px;align-items:stretch}\n", "",{"version":3,"sources":["webpack://./src/client/modules/task/pages/TaskPage/components/TaskPageContent/TaskPageContent.scss"],"names":[],"mappings":"AAAA,0FACE,MAAO,CACP,YAAa,CACb,YAAa,CACb,qBAAsB,CACtB,QAAS,CACT,mBAAoB","sourcesContent":[".root {\n  flex: 1;\n  padding: 24px;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  align-items: stretch;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-task-pages-TaskPage-components-TaskPageContent-_TaskPageContent__root"
};
export default ___CSS_LOADER_EXPORT___;
