import * as React from 'react';

import { Link } from 'react-router-dom';
import * as styles from './styles.scss';

export interface Props {
    text: string;
    icon: React.ReactNode;
    link: string;
}

export const Item: React.FC<Props> = ({ text, icon, link }) => {
    return (
        <Link className={styles.root} to={link}>
            <div className={styles.icon}>{icon}</div>
            <div className={styles.text}>{text}</div>
        </Link>
    );
};
