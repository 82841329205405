import * as React from 'react';
import classNames from 'classnames';
import { WithTooltip, Skeleton } from 'sber-marketing-ui';

import * as style from './SubInfo.scss';

interface Props {
    tooltip?: string;
    loading?: boolean;
    className?: string;
    qaId: string;
    qaValue: string;
}

export const SubInfo: React.FC<Props> = ({ children, tooltip, loading, className, qaId, qaValue }) => (
    <WithTooltip content={tooltip}>
        <div
            className={classNames(style.root, loading ? className : '')}
            {...{
                'qa-id': qaId,
                'qa-value': qaValue,
            }}
        >
            <Skeleton isReady={!loading}>{children}</Skeleton>
        </div>
    </WithTooltip>
);

export const SubInfoIconWrapper: React.FC = ({ children }) => <div className={style.iconWrapper}>{children}</div>;
