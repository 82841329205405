import { buildActivityReportChartConfig } from './buildActivityReportChartConfig';
import { buildTaskReportChartConfig } from './buildTaskReportChartConfig';

import type { BuilderReportChartConfigByMode, ReportChartConfig } from './types';

export const builderReportChartConfigByMode: BuilderReportChartConfigByMode = {
    activities: buildActivityReportChartConfig,
    tasks: buildTaskReportChartConfig,
};

export type { ReportChartConfig };
