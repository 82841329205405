import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { castArray } from 'lodash';
import { combineReducers } from 'redux';
import { HistoryView, RecentView } from 'sber-marketing-types/frontend';

import { addHistories, addRecentViews } from './actions';
import { HistoryState } from './types';

const histories = reducerWithInitialState<HistoryView[]>([]).case(addHistories, (state, payload) =>
    state.concat(castArray(payload)),
);

const recentViews = reducerWithInitialState<RecentView[]>([]).case(addRecentViews, (state, payload) =>
    state.concat(castArray(payload)),
);

export const history = combineReducers<HistoryState>({ histories, recentViews });
