import * as React from 'react';
import classnames from 'classnames';
import {
    Textarea_redesign as Textarea,
    Textarea_theme as TextareaThemes,
    Icon,
    IconType,
    MentionMenu,
    MentionItem,
} from 'sber-marketing-ui';

import { FileInput } from '@common/FileInput';
import { DragNDrop } from '@common/DragNDrop';

import { FileAsset } from './FileAsset';

import * as styles from './CommentInput.scss';

interface Props {
    text: string;
    disabled?: boolean;
    isFocused: boolean;
    fileInputRef: React.RefObject<FileInput>;
    files: File[];
    mentionableUsers: MentionItem[];
    uploadedFiles: File[];
    isFileUploadInProgress: boolean;
    isRequestInProgress: boolean;
    onWrapperMouseOver: () => void;
    onWrapperMouseOut: () => void;
    onInputChange: (value: string) => void;
    onInputFocus: () => void;
    onInputBlur: () => void;
    onAttachmentIconClick: () => void;
    onAttachmentIconMouseOver: () => void;
    onAttachmentIconMouseOut: () => void;
    onSendIconClick: () => void;
    onSendIconMouseOver: () => void;
    onSendIconMouseOut: () => void;
    onFileInput: (files: File[]) => void;
    deleteFile: (file: File) => void;
}

export function CommentInputTemplate({
    text,
    disabled,
    isFocused,
    fileInputRef,
    files,
    mentionableUsers,
    uploadedFiles,
    isFileUploadInProgress,
    isRequestInProgress,
    onWrapperMouseOver,
    onWrapperMouseOut,
    onInputChange,
    onInputFocus,
    onInputBlur,
    onAttachmentIconClick,
    onAttachmentIconMouseOver,
    onAttachmentIconMouseOut,
    onSendIconClick,
    onSendIconMouseOver,
    onSendIconMouseOut,
    onFileInput,
    deleteFile,
}: Props): JSX.Element {
    const handlePaste: React.ClipboardEventHandler<HTMLTextAreaElement> = (e) => {
        const { files } = e.clipboardData;
        if (files.length) {
            e.preventDefault();
            onFileInput(Array.from(files));
        }
    };

    return (
        <div className={classnames(styles.root, isRequestInProgress && styles.rootRequestInProgress)}>
            <div
                className={classnames(styles.inputWrapper, isFocused && styles.inputWrapperFocused)}
                onMouseOver={onWrapperMouseOver}
                onMouseOut={onWrapperMouseOut}
            >
                <div className={styles.input}>
                    <MentionMenu items={mentionableUsers} headerHeight={49}>
                        <Textarea
                            maxRows={5}
                            value={text}
                            placeholder="Напишите комментарий…"
                            theme={TextareaThemes.REGULAR}
                            disabled={disabled}
                            onPaste={handlePaste}
                            onChange={onInputChange}
                            onFocus={onInputFocus}
                            onBlur={onInputBlur}
                        />
                    </MentionMenu>
                </div>

                <div
                    className={classnames(styles.icon, styles.attachmentIcon)}
                    {...{
                        'qa-id': 'commentInutAttachFilesIcon',
                    }}
                >
                    <Icon
                        type={IconType.ATTACHMENT_ICON}
                        svgSize={24}
                        onClick={onAttachmentIconClick}
                        onMouseOver={onAttachmentIconMouseOver}
                        onMouseOut={onAttachmentIconMouseOut}
                    />
                </div>

                <div
                    className={classnames(styles.icon, styles.sendIcon)}
                    {...{
                        'qa-id': 'commentInputSendIcon',
                    }}
                >
                    <Icon
                        type={IconType.SEND_ICON}
                        svgSize={24}
                        onClick={onSendIconClick}
                        onMouseOver={onSendIconMouseOver}
                        onMouseOut={onSendIconMouseOut}
                    />
                </div>

                <FileInput ref={fileInputRef} onChange={onFileInput} />
            </div>

            {!!files.length && (
                <div className={styles.filesWrapper}>
                    {files.map((file, i) => (
                        <div key={`file-${i}`} className={styles.file}>
                            <FileAsset
                                file={file}
                                preloader={isFileUploadInProgress && !uploadedFiles.includes(file)}
                                deleteFile={deleteFile}
                            />
                        </div>
                    ))}
                </div>
            )}

            <div className={styles.dragNDrop}>
                <DragNDrop onFileInput={onFileInput} />
            </div>
        </div>
    );
}
