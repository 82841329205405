import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import { getSidebar } from '@store/autopilotRadio/selectors';
import * as actions from '@store/autopilotRadio/actions';

interface UseStore {
    (): UseStoreReturn;
}

interface UseStoreReturn {
    openedRightSidebar: boolean;
    openRightSidebar: () => void;
    closeRightSidebar: () => void;
}

export const useStore: UseStore = () => {
    const dispatch = useDispatch();

    const { rightSidebar } = useSelector((state: StoreState) => ({
        rightSidebar: getSidebar(state),
    }));

    const openRightSidebar = React.useCallback(() => dispatch(actions.openSidebar()), [actions.openSidebar]);
    const closeRightSidebar = React.useCallback(() => dispatch(actions.closeSidebar()), [actions.closeSidebar]);

    return {
        openedRightSidebar: rightSidebar.opened,
        openRightSidebar,
        closeRightSidebar,
    };
};
