import { isNil } from 'lodash';

import { EditTaskPayload, TaskPageState } from '../types';

export const editTaskReducer = (
    { workType: prevWorkType, departmentExecutorId: prevDepartmentExecutorId, ...restState }: TaskPageState,
    { workType, departmentExecutorId, ...restPayload }: EditTaskPayload,
): TaskPageState => {
    let newWorkType: string;
    let newDepartmentExecutorId: string;
    if (!isNil(workType) && workType !== prevWorkType) {
        newWorkType = workType;
        newDepartmentExecutorId = undefined;
    } else if (!isNil(departmentExecutorId) && departmentExecutorId !== prevDepartmentExecutorId) {
        newWorkType = prevWorkType;
        newDepartmentExecutorId = departmentExecutorId;
    } else {
        newWorkType = prevWorkType;
        newDepartmentExecutorId = prevDepartmentExecutorId;
    }
    return {
        ...restState,
        ...restPayload,
        workType: newWorkType,
        departmentExecutorId: newDepartmentExecutorId,
    };
};
