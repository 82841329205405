// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-task-components-tags-TaskBrief-_TaskBrief__link{display:flex;gap:4px 6px}.src-client-modules-task-components-tags-TaskBrief-_TaskBrief__draft{color:var(--color-primary-50)}.src-client-modules-task-components-tags-TaskBrief-_TaskBrief__icon{float:left}\n", "",{"version":3,"sources":["webpack://./src/client/modules/task/components/tags/TaskBrief/TaskBrief.scss"],"names":[],"mappings":"AAIA,oEACE,YAAa,CACb,WAAY,CACb,qEAGC,6BAA8B,CAC/B,oEAGC,UAAW","sourcesContent":[".root {\n\n}\n\n.link {\n  display: flex;\n  gap: 4px 6px;\n}\n\n.draft {\n  color: var(--color-primary-50);\n}\n\n.icon {\n  float: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "src-client-modules-task-components-tags-TaskBrief-_TaskBrief__link",
	"draft": "src-client-modules-task-components-tags-TaskBrief-_TaskBrief__draft",
	"icon": "src-client-modules-task-components-tags-TaskBrief-_TaskBrief__icon"
};
export default ___CSS_LOADER_EXPORT___;
