// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-ProfileMenu-_ProfileMenu__root{position:fixed;z-index:23;top:52px;right:16px;overflow:hidden;width:480px;background:#FFFFFF;box-shadow:0px 1px 4px rgba(0,0,0,0.15);border-radius:4px;font-family:'Open Sans'}.src-client-modules-common-ProfileMenu-_ProfileMenu__root.src-client-modules-common-ProfileMenu-_ProfileMenu__notifications{width:560px}.src-client-modules-common-ProfileMenu-_ProfileMenu__mask{position:fixed;z-index:20;top:0;left:0;width:100vw;height:100vh}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/ProfileMenu/ProfileMenu.scss"],"names":[],"mappings":"AAAA,0DACI,cAAe,CACf,UAAW,CACX,QAAS,CACT,UAAW,CAEX,eAAgB,CAEhB,WAAY,CAEZ,kBAAmB,CACnB,uCAA2C,CAC3C,iBAAkB,CAElB,uBAAwB,CAd5B,4HAiBQ,WAAY,CACf,0DAID,cAAe,CACf,UAAW,CACX,KAAM,CACN,MAAO,CAEP,WAAY,CACZ,YAAa","sourcesContent":[".root {\n    position: fixed;\n    z-index: 23;\n    top: 52px;\n    right: 16px;\n\n    overflow: hidden;\n\n    width: 480px;\n\n    background: #FFFFFF;\n    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);\n    border-radius: 4px;\n\n    font-family: 'Open Sans';\n\n    &.notifications {\n        width: 560px;\n    }\n}\n\n.mask {\n    position: fixed;\n    z-index: 20;\n    top: 0;\n    left: 0;\n\n    width: 100vw;\n    height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-ProfileMenu-_ProfileMenu__root",
	"notifications": "src-client-modules-common-ProfileMenu-_ProfileMenu__notifications",
	"mask": "src-client-modules-common-ProfileMenu-_ProfileMenu__mask"
};
export default ___CSS_LOADER_EXPORT___;
