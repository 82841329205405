import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconType, CustomScrollbar_new as CustomScrollbar, AnimatedEllipsis } from 'sber-marketing-ui';

import { Utils } from '@common/Utils';

import * as style from './FileList.scss';

const CONTENT_MAX_HEIGHT = 118;

export interface FileParams {
    id: string;
    name: string;
    originName: string;
    size: string;
    type: string;
    loading: boolean;
}

interface Props {
    files: FileParams[];
    isOpened: boolean;
    onOpenerClick: () => void;
    onDeleteButtonClick: (id: string) => void;
}

export function FileList({ files, isOpened, onOpenerClick, onDeleteButtonClick }: Props) {
    const filesAreLoading = files.some((item) => item.loading);
    const filesCount = files.length;

    return (
        <div className={style.root}>
            <div
                className={style.opener}
                onClick={onOpenerClick}
                {...{
                    'qa-id': 'budgetExecutionActivityFilesOpener',
                }}
            >
                <div className={style.openerFileIcon}>
                    <Icon type={IconType.ATTACHMENT_ICON} svgSize={22} />
                </div>

                <div className={style.openerTitle}>
                    {filesCount} {Utils.getDeclensionByNumber(filesCount, ['файл', 'файла', 'файлов'])}
                </div>

                <div className={style.openerCloseIcon}>
                    <Icon type={isOpened ? IconType.ARROW16_UP_BLACK : IconType.ARROW16_DOWN_BLACK} svgSize={16} />
                </div>

                {filesAreLoading && (
                    <div className={style.loadingStatus}>
                        <AnimatedEllipsis text="Загрузка" />

                        {/* <div className={style.loadingStatusIcon}>
                            <Icon type={IconType.CHECK_ICON} svgSize={11}/>
                        </div> */}
                    </div>
                )}
            </div>

            {isOpened && (
                <div className={style.content}>
                    <CustomScrollbar maxHeight={CONTENT_MAX_HEIGHT} freezeScrollX hideScrollX>
                        <div className={style.list}>
                            {files.map((item) => {
                                const fileName = `${item.originName}.${item.type}`;

                                return (
                                    <div
                                        key={item.id}
                                        className={classNames(style.file, item.loading && style.loading)}
                                        {...{
                                            'qa-id': 'budgetExecutionActivityFile',
                                        }}
                                    >
                                        <div className={style.fileName} title={fileName}>
                                            {fileName}
                                        </div>

                                        <div className={style.fileSize}>{item.size}</div>

                                        {!item.loading && (
                                            <div
                                                className={style.fileDeleteButton}
                                                onClick={() => onDeleteButtonClick(item.id)}
                                            >
                                                <Icon type={IconType.REJECT_ICON_ROUND} svgSize={16} />
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </CustomScrollbar>
                </div>
            )}
        </div>
    );
}
