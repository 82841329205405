import * as React from 'react';
import autobind from 'autobind-decorator';

import { FileInput } from '@common/FileInput';

import { UploadButtonTemplate } from './UploadButtonTemplate';

interface Props {
    addFile: (file: File) => void;
}

export class UploadButtonBehaviour extends React.PureComponent<Props> {
    private fileInputRef: React.RefObject<FileInput>;

    public constructor(props: Props) {
        super(props);
        this.fileInputRef = React.createRef<FileInput>();
    }

    public render(): JSX.Element {
        return (
            <UploadButtonTemplate
                fileInputRef={this.fileInputRef}
                onButtonClick={this.onButtonClick}
                onFileInput={this.onFileInput}
            />
        );
    }

    @autobind
    private onButtonClick(): void {
        if (this.fileInputRef.current) {
            this.fileInputRef.current.triggerOpen();
        }
    }

    @autobind
    private onFileInput(files: File[]): void {
        this.props.addFile(files[0]);
    }
}
