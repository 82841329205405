import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popup, CloseButton, StaticSkeleton } from 'sber-marketing-ui';
import { v4 } from 'uuid';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import {
    getTaskEditorState,
    commonLoadingIsFinished,
    initState,
    resetState,
    defaultPermissions,
    ComponentMode,
    Permissions,
} from '@store/taskEditor2';
import {
    DataUpdateStrategy,
    initInstance as initTagsEditorInstance,
    dropInstance as dropTagsEditorInstance,
} from '@store/tagsEditor';

import { TitleInput } from './TitleInput';
import { DescriptionInput } from './DescriptionInput';
import { StageDropdown } from './StageDropdown';
import { AttachedFilesInput } from './AttachedFilesInput';
import { WorkTypeExecutorEditor } from './WorkTypeExecutorEditor';
import { DeadlineDatepicker } from './DeadlineDatepicker';
import { ParticipantsDropdown } from './ParticipantsDropdown';
import { SaveButton } from './SaveButton';

import { TagsPreviewAndEditor } from '@modules/tags/TagsPreviewAndEditor';

import * as styles from './TaskEditor.scss';

interface Props {
    activityId: number;
    taskId?: string;
    permissions?: Permissions;
    componentMode?: ComponentMode;
    closePopup: () => void;
    onTaskCreated: () => void;
}

function useInteractivity(props: Props) {
    const dispatch = useDispatch();
    const { activityId, taskId, onTaskCreated, closePopup, permissions = defaultPermissions } = props;

    const tagsEditorId = React.useMemo(() => v4(), []);

    function initPopupClosing() {
        dispatch(resetState());
        closePopup();
    }

    const taskRequestHasFinished = useSelector(
        (state: StoreState) => getTaskEditorState(state).common.taskRequestHasFinished,
    );
    const activityStagesLoadingStatus = useSelector(
        (state: StoreState) => getTaskEditorState(state).data.activityStages.loadingStatus,
    );
    const showCommonPreloader = useSelector((state: StoreState) => commonLoadingIsFinished(state));

    React.useEffect(() => {
        dispatch(
            initState({
                activityId,
                taskId,
                permissions,
                tagsEditorId,
            }),
        );

        dispatch(
            initTagsEditorInstance({
                id: tagsEditorId,
                payload: { taskId },
            }),
        );

        return function resetTaskEditor() {
            dispatch(dropTagsEditorInstance(tagsEditorId));
        };
    }, []);

    React.useEffect(() => {
        if (taskRequestHasFinished) {
            dispatch(resetState());
            onTaskCreated();
        }
    });

    return {
        activityStagesLoadingStatus,
        showCommonPreloader,
        tagsEditorId,
        initPopupClosing,
    };
}

export function TaskEditor(props: Props): JSX.Element {
    const { activityStagesLoadingStatus, showCommonPreloader, tagsEditorId, initPopupClosing } =
        useInteractivity(props);

    return (
        <Popup>
            <div className={styles.root}>
                <div className={styles.title}>
                    <Title showCommonPreloader={showCommonPreloader} componentMode={props.componentMode} />

                    <div
                        {...{
                            'qa-id': 'taskEditorCloseButton',
                        }}
                    >
                        <CloseButton onClick={initPopupClosing} />
                    </div>
                </div>

                {showCommonPreloader ? (
                    <Preloader />
                ) : (
                    <Content
                        activityStagesLoadingStatus={activityStagesLoadingStatus}
                        tagsEditorId={tagsEditorId}
                        {...props}
                    />
                )}
            </div>
        </Popup>
    );
}

interface TitleProps {
    showCommonPreloader: boolean;
    componentMode: ComponentMode;
}

function Title({ showCommonPreloader, componentMode }: TitleProps): JSX.Element {
    if (showCommonPreloader) {
        return <StaticSkeleton className={styles.titlePreloader} />;
    }

    if (componentMode === ComponentMode.enterRequiredFieldsForExistingTask) {
        return <React.Fragment>Заполните обязательные поля</React.Fragment>;
    }

    return <React.Fragment>Информация о задаче</React.Fragment>;
}

function StageDropdownPreloader(): JSX.Element {
    return (
        <React.Fragment>
            <StaticSkeleton className={styles.fifthPreloader} />
            <StaticSkeleton className={styles.sixthPreloader} />
        </React.Fragment>
    );
}

function FilePreloader(): JSX.Element {
    return (
        <div>
            <StaticSkeleton className={styles.fileAssetPreloader} />
            <StaticSkeleton className={styles.fileTitlePreloader} />
        </div>
    );
}

function Preloader(): JSX.Element {
    return (
        <React.Fragment>
            <StaticSkeleton className={styles.firstPreloader} />
            <StaticSkeleton className={styles.secondPreloder} />
            <StaticSkeleton className={styles.thirdPreloader} />
            <StaticSkeleton className={styles.fourthPreloader} />

            <StaticSkeleton className={styles.filesTitlePreloader} />
            <div className={styles.filesPreloaderGridWrapper}>
                <FilePreloader />
                <FilePreloader />
                <FilePreloader />
                <FilePreloader />
                <FilePreloader />
                <FilePreloader />
                <FilePreloader />
            </div>

            <StageDropdownPreloader />

            <StaticSkeleton className={styles.seventhPreloader} />
            <StaticSkeleton className={styles.eightPreloader} />
            <StaticSkeleton className={styles.ninethPreloader} />
            <StaticSkeleton className={styles.tenthPreloader} />
            <StaticSkeleton className={styles.eleventhPreloader} />
            <StaticSkeleton className={styles.twelfthPreloader} />

            <div className={styles.thirteenthPreloaderWrapper}>
                <StaticSkeleton firstColor="#7E8681" className={styles.thirteenthPreloader} />
            </div>
        </React.Fragment>
    );
}

interface ContentProps extends Props {
    activityStagesLoadingStatus: LoadingStatus;
    tagsEditorId: string;
}

function Content({ taskId, tagsEditorId, activityStagesLoadingStatus, componentMode }: ContentProps): JSX.Element {
    const showStagesPreloader = activityStagesLoadingStatus !== LoadingStatus.LOADED;

    return (
        <React.Fragment>
            <TitleInput componentMode={componentMode} />

            <DescriptionInput />

            <AttachedFilesInput />

            <TagsPreviewAndEditor
                dataUpdateStrategy={DataUpdateStrategy.OnDemand}
                zIndex={60}
                taskId={taskId}
                id={tagsEditorId}
                className={styles.tagsEditor}
            />

            {showStagesPreloader ? <StageDropdownPreloader /> : <StageDropdown />}

            <WorkTypeExecutorEditor componentMode={componentMode} />

            <DeadlineDatepicker componentMode={componentMode} />

            <ParticipantsDropdown />

            <SaveButton componentMode={componentMode} />
        </React.Fragment>
    );
}
