import * as React from 'react';
import classNames from 'classnames';

import * as styles from './Fieldset.scss';

export interface FieldsetProps extends React.HTMLProps<HTMLFieldSetElement> {
    labelWidth?: number | string;
}

export const Fieldset: React.FC<FieldsetProps> = ({ className, labelWidth = 100, ...props }) => {
    const labelWidthVariable = typeof labelWidth === 'number' ? `${labelWidth}px` : labelWidth;

    const style = {
        '--fieldset-label-width': labelWidthVariable,
    } as React.CSSProperties;

    return <fieldset {...props} style={style} className={classNames(styles.root, className)} />;
};
