import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType, WithTooltip } from 'sber-marketing-ui';

import { useGetActivityQuery, useSwitchActivityFavouriteMutation } from '@api';

import * as styles from './ActivityFavorite.scss';

export interface ActivityFavoriteProps extends Omit<React.HTMLProps<HTMLButtonElement>, 'type'> {
    activityId: number;
    size?: number;
}

export const ActivityFavorite: React.FC<ActivityFavoriteProps> = ({
    activityId: id,
    size = 14,
    className,
    ...props
}) => {
    const { data, isFetching } = useGetActivityQuery({ id });
    const [switchFavourite, { isLoading: switchIsLoading }] = useSwitchActivityFavouriteMutation();
    const isUpdating = switchIsLoading || isFetching;

    const tooltip = data?.isFavorite ? 'Убрать проект из "Избранных"' : 'Добавить проект в "Избранные"';
    const iconClassName = classNames(
        styles.icon,
        data?.isFavorite != isUpdating ? styles.active : styles.normal,
        isUpdating ? styles.loading : '',
    );

    const clickHandler = () => {
        if (isUpdating) return;

        switchFavourite({ id });
    };

    return (
        <WithTooltip hidden={isUpdating} content={tooltip}>
            <button
                data-qa-id="ActivityFavorite"
                {...props}
                className={classNames(styles.root, className)}
                onClick={clickHandler}
            >
                <Icon className={iconClassName} svgSize={size} type={IconType.STAR_ACTIVE} />
            </button>
        </WithTooltip>
    );
};
