import { createSelector } from 'reselect';
import { values, without } from 'lodash';

import { StoreState } from '@store';
import type { BudgetItem, CreativeRequest, State } from './types';
import { LoadingStatus } from './types';

export const getCreativeReferenceMenuState = (state: StoreState): State =>
    state.budgetExecutionPage.creativeReferenceMenu;

export const isLoading = createSelector(
    getCreativeReferenceMenuState,
    (state): boolean =>
        state.data.creativeRequests.loading === LoadingStatus.LOADING ||
        state.data.budgetItems.loading === LoadingStatus.LOADING,
);

export const getSelectedCreativeRequestId = createSelector(
    getCreativeReferenceMenuState,
    (state): string => state.params.selectedCreativeRequestId,
);

export const getSelectedBudgetItemIds = createSelector(
    getCreativeReferenceMenuState,
    (state): string[] => state.params.selectedBudgetItemIds,
);

export const getCreativeRequests = createSelector(getCreativeReferenceMenuState, (state): CreativeRequest[] =>
    values(state.data.creativeRequests.items),
);

export const getBudgetItemMap = createSelector(getCreativeReferenceMenuState, (state) => state.data.budgetItems.items);

export const getBudgetItems = createSelector(getBudgetItemMap, (budgetItemMap): BudgetItem[] => values(budgetItemMap));

export const getBudgetItemIdsOfSelectedCreativeRequest = createSelector(
    getCreativeReferenceMenuState,
    (state): string[] =>
        state.data.creativeRequests.items[state.params.selectedCreativeRequestId].budgetItems.map(({ id }) => id),
);

export const getBudgetItemIdsToCreate = createSelector(
    getBudgetItemIdsOfSelectedCreativeRequest,
    getSelectedBudgetItemIds,
    (budgetItemIdsOfSelectedCreativeRequest, selectedBudgetItemIds): string[] => {
        return without(selectedBudgetItemIds, ...budgetItemIdsOfSelectedCreativeRequest).sort();
    },
);

export const getBudgetItemIdsToDelete = createSelector(
    getBudgetItemIdsOfSelectedCreativeRequest,
    getSelectedBudgetItemIds,
    (budgetItemIdsOfSelectedCreativeRequest, selectedBudgetItemIds): string[] => {
        return without(budgetItemIdsOfSelectedCreativeRequest, ...selectedBudgetItemIds).sort();
    },
);

export const getSerialNumbersToCreate = createSelector(
    getBudgetItemMap,
    getBudgetItemIdsToCreate,
    (budgetItemMap, budgetItemIdsToCreate): string[] => {
        return budgetItemIdsToCreate.map(
            (budgetItemId) => budgetItemMap[budgetItemId]?.serialNumber?.toString() || null,
        );
    },
);

export const getSerialNumbersToDelete = createSelector(
    getBudgetItemMap,
    getBudgetItemIdsToDelete,
    (budgetItemMap, budgetItemIdsToDelete): string[] => {
        return budgetItemIdsToDelete.map(
            (budgetItemId) => budgetItemMap[budgetItemId]?.serialNumber?.toString() || null,
        );
    },
);
