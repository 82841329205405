import type { CreativeRequestDomain } from '../types';
import { Handlers } from '../converters';

import { subscribeCreativeRequestParamDomains } from './subscribeCreativeRequestParamDomains';

interface Subscriber {
    (domain: CreativeRequestDomain, params: { handlers: Handlers }): Promise<void>;
}

export const subscribeCreativeRequestDomain: Subscriber = async (domain, params) => {
    const { handlers } = params;

    domain.events.onParamsAdded(handlers.onParamsAdded);
    domain.events.onParamsRemoved(handlers.onParamsRemoved);
    domain.events.onBudgetItemsAdded(handlers.onBudgetItemsAdded);
    domain.events.onBudgetItemsRemoved(handlers.onBudgetItemsRemoved);
    domain.events.onBudgetItemsUpdated(handlers.onBudgetItemsUpdated);

    const creativeRequestParamDomains = await domain.model.getParams();
    subscribeCreativeRequestParamDomains(creativeRequestParamDomains, params);
};
