import * as React from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { FieldProperties, FieldValue, BriefFile } from 'sber-marketing-types/frontend';
import type { FieldInput, FileToRemove } from '@store/brief/types';
import type { FileAsset } from '@store/commonTypes';

import { FileBlock } from './FileBlock';
import type { StoreState } from '@store';
import { setFileListToRemove } from '@store/brief/actions';
import { getFileAssetsByNames } from '@store/fileAssets';

interface Props extends FieldProperties, Partial<MapProps>, DispatchProps {
    index?: number;
    id: string;
    uniqId: number;
    parentUniqId: number;
    briefId: string;
    value?: FieldValue;
    isValid?: boolean;
    disabled?: boolean;
    onValueChange: (value: FieldInput) => void;
}

interface MapProps {
    assets: FileAsset[];
    filesToRemove: FileToRemove[];
}

interface DispatchProps {
    setFileListToRemove: (files: FileToRemove[]) => void;
}

interface State {
    loading: boolean;
    isGalleryOpen: boolean;
    startIndex: number;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class FileBlockContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
            isGalleryOpen: false,
            startIndex: 0,
        };
    }

    public render(): JSX.Element {
        const { id, uniqId, parentUniqId, name, tooltipMessage, disabled, filesToRemove, briefId, index } = this.props;
        return React.createElement(FileBlock, {
            index,
            fieldId: id,
            uniqId: uniqId,
            parentUniqId: parentUniqId,
            name,
            tooltipMessage,
            filesToRemove,
            startIndex: this.state.startIndex,
            files: this.getFiles(),
            briefId,
            onUploadSuccess: this.onUploadSuccess,
            onDeleteButtonClick: this.onDeleteButtonClick,
            onUnmarkFileForDeleteClick: this.onUnmarkFileForDeleteClick,
            onUploadStart: this.handleUploadStart,
            loading: this.state.loading,
            disabled,
            isGalleryOpen: this.state.isGalleryOpen,
            onGalleryClick: this.onGalleryClick,
            onGalleryClose: this.onGalleryClose,
            onUploadError: this.onUploadError,
        });
    }

    @autobind
    protected onGalleryClick(startIndex: number) {
        this.setState(() => ({ startIndex, isGalleryOpen: true }));
    }

    @autobind
    protected onGalleryClose() {
        this.setState(() => ({ startIndex: 0, isGalleryOpen: false }));
    }

    @autobind
    protected onUploadError(_: any, asset: FileAsset) {
        const newValue: FieldInput =
            this.props.value && this.props.value.files
                ? this.props.value.files.filter(({ name }) => name !== asset.name)
                : [];
        this.props.onValueChange(newValue);
    }

    @autobind
    protected handleUploadStart(fileParams: FileAsset) {
        const newValue: FieldInput = lodash.uniqBy(
            this.props.value ? [...(this.props.value.files || []), fileParams] : [fileParams],
            'name',
        ) as BriefFile[];
        this.props.onValueChange(newValue);
    }

    @autobind
    protected async onUploadSuccess(fileParams: FileAsset) {
        const { value } = this.props;

        const newValue: FieldInput = lodash.uniqBy(
            value ? [...(value.files || []), fileParams] : [fileParams],
            'name',
        ) as BriefFile[];

        this.setState(() => ({
            loading: false,
        }));

        this.props.onValueChange(newValue);
    }

    @autobind
    protected async onDeleteButtonClick({ name: fileName }: FileAsset) {
        const newFileToRemove = {
            fileName,
            fieldId: this.props.id,
        };
        const newFileList = [...this.props.filesToRemove, newFileToRemove];

        this.props.setFileListToRemove(newFileList);
    }

    @autobind
    protected async onUnmarkFileForDeleteClick(fileName: string) {
        const newFileList = this.props.filesToRemove.filter((file) => file.fileName != fileName);

        this.props.setFileListToRemove(newFileList);
    }

    protected getFiles(): FileAsset[] {
        return this.props.assets;
    }
}

function mapStateToProps(state: StoreState, { value }: Props): MapProps {
    const { filesToRemove } = state.briefPage;
    const names = lodash.defaultTo(lodash.get(value, 'files'), []).map(({ name }) => name);
    const assets = getFileAssetsByNames(state, names);

    return {
        assets,
        filesToRemove,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return {
        setFileListToRemove: (files: FileToRemove[]) => dispatch(setFileListToRemove(files)),
    };
}
