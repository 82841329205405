// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-tmRegistry-table-row-cell-enum-_TmEnumCell__cell{overflow:hidden;flex:4;box-sizing:border-box;min-width:140px;padding:5px;letter-spacing:0;border-right:1px solid #e3e9e5;font-size:12px;line-height:14px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/tmRegistry/table/row/cell/enum/TmEnumCell.scss","webpack://./src/client/modules/tmRegistry/table/const.scss"],"names":[],"mappings":"AAEA,qEACI,eAAgB,CAChB,MAAO,CAEP,qBAAsB,CACtB,eAAgB,CAChB,WAAY,CAEZ,gBAAiB,CAEjB,8BCPiC,CDSjC,cAAe,CACf,gBAAiB","sourcesContent":["@import \"../../../const\";\n\n.cell {\n    overflow: hidden;\n    flex: 4;\n\n    box-sizing: border-box;\n    min-width: 140px;\n    padding: 5px;\n\n    letter-spacing: 0;\n\n    border-right: $table_border_base;\n\n    font-size: 12px;\n    line-height: 14px;\n}\n","\n$table_color_base: #000000;\n$table_color_success: #19bb4f;\n$table_bg_base: #f8f8f8;\n$table_bg_secondary: #ffffff;\n$table_border_base: 1px solid #e3e9e5;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cell": "src-client-modules-tmRegistry-table-row-cell-enum-_TmEnumCell__cell"
};
export default ___CSS_LOADER_EXPORT___;
