import * as React from 'react';
import classNames from 'classnames';

import * as styles from './TagClose.scss';
import { Icon, IconProps, IconType } from 'sber-marketing-ui';

export interface TagCloseProps extends Partial<IconProps> {}

export function TagClose({ className, ...props }: TagCloseProps) {
    return (
        <Icon
            data-qa-id="TagClose"
            svgSize={8}
            type={IconType.CROSS}
            {...props}
            className={classNames(styles.root, className)}
        />
    );
}
