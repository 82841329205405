import * as React from 'react';

import { CloseButton } from './CloseButton';
import { BudgetItemStatus } from './BudgetItemStatus';

import * as style from './Header.scss';

interface Props {
    id: number;
    lineId: string;
    name: string;
    onCloseButtonClick: () => void;
}

export const Header: React.FC<Props> = ({ id, lineId, name, onCloseButtonClick }) => {
    const title = id && name ? `ID: ${id}, ${name}` : id && !name ? `ID: ${id}` : !id && name ? `${name}` : '';

    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'budgetPlanningLineModalHeader',
            }}
        >
            <div className={style.title} title={title}>
                {id && (
                    <span className={style.id}>
                        ID: {id}
                        {name ? ', ' : ''}
                    </span>
                )}
                {name ? name : ''}
            </div>
            <CloseButton onClick={onCloseButtonClick} />
            <BudgetItemStatus lineId={lineId} />
        </div>
    );
};
