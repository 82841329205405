// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-main-Option-_Option__root{font-size:12px}.src-client-modules-common-components-main-Option-_Option__subtitle{overflow:hidden;white-space:nowrap;text-overflow:ellipsis;font-size:12px;max-width:100%;color:var(--color-primary-50)}.src-client-modules-common-components-main-Option-_Option__title{font-size:12px}.src-client-modules-common-components-main-Option-_Option__titleText{overflow:hidden;white-space:nowrap;text-overflow:ellipsis;min-width:0;flex:1}.src-client-modules-common-components-main-Option-_Option__root:not(.src-client-modules-common-components-main-Option-_Option__danger) .src-client-modules-common-components-main-Option-_Option__titleText{color:var(--color-primary-100)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/main/Option/Option.scss"],"names":[],"mappings":"AAAA,gEACE,cAAe,CAChB,oEAGC,eAAgB,CAChB,kBAAmB,CACnB,sBAAuB,CACvB,cAAe,CACf,cAAe,CACf,6BAA8B,CAC/B,iEAGC,cAAe,CAChB,qEAGC,eAAgB,CAChB,kBAAmB,CACnB,sBAAuB,CACvB,WAAY,CACZ,MAAO,CAEP,4MACE,8BAA+B","sourcesContent":[".root {\n  font-size: 12px;\n}\n\n.subtitle{\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  font-size: 12px;\n  max-width: 100%;\n  color: var(--color-primary-50);\n}\n\n.title {\n  font-size: 12px;\n}\n\n.titleText {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  min-width: 0;\n  flex: 1;\n\n  .root:not(.danger) & {\n    color: var(--color-primary-100);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-components-main-Option-_Option__root",
	"subtitle": "src-client-modules-common-components-main-Option-_Option__subtitle",
	"title": "src-client-modules-common-components-main-Option-_Option__title",
	"titleText": "src-client-modules-common-components-main-Option-_Option__titleText",
	"danger": "src-client-modules-common-components-main-Option-_Option__danger"
};
export default ___CSS_LOADER_EXPORT___;
