import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';

import { useGetTaskBriefQuery, useGetTaskQuery } from '@api';

import { Tag, TagProps, Link } from '@common/components';

import * as styles from './TaskBrief.scss';

export interface TaskBriefProps extends Omit<TagProps, 'type'> {
    taskId: string;
    activityId?: string;
    className?: string;
}

export const TaskBrief: React.FC<TaskBriefProps> = ({ taskId, className, loading, activityId, children, ...props }) => {
    const { isLoading: isTaskLoading, data: task } = useGetTaskQuery({ id: taskId }, { skip: Boolean(activityId) });
    const { isLoading: isBriefLoading, data: brief } = useGetTaskBriefQuery({ id: taskId });
    const isLoading = isTaskLoading || isBriefLoading || loading;

    return (
        <Tag ghost {...props} loading={isLoading} className={classNames(styles.root, className)}>
            <Link
                data-qa-id="TaskBrief__link"
                className={styles.link}
                target="_blank"
                to={`/activity/${activityId || task.task.activityId}/task/${taskId}/brief`}
            >
                <Icon className={styles.icon} svgSize={16} type={IconType.LINK_FOR_SHARING} />
                Перейти в бриф
            </Link>
            {!brief?.schemeId && (
                <span data-qa-id="TaskBrief__empty" className={styles.draft}>
                    (не заполнен)
                </span>
            )}
            {children}
        </Tag>
    );
};
