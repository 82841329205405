import * as React from 'react';
import autobind from 'autobind-decorator';

import { CommentProps, Comment } from './Comment';

interface Props extends Partial<CommentProps> {
    pending?: boolean;
}

interface State {
    isExpanded: boolean;
}

export class CommentContainer extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isExpanded: false,
        };
    }

    public render(): JSX.Element {
        const { isExpanded } = this.state;

        return <Comment {...this.props} isExpanded={isExpanded} onExpandButtonClick={this.onExpandButtonClick} />;
    }

    @autobind
    private onExpandButtonClick(): void {
        this.setState((state) => ({
            isExpanded: !state.isExpanded,
        }));
    }
}
