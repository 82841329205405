import * as React from 'react';
import gql from 'graphql-tag';
import { get } from 'lodash';
import { graphql } from 'react-apollo';

import { BudgetItem } from './types';

const MONTHS_FRAGMENT = 'jan feb mar apr may jun jul aug sept oct nov dec';

export const GET_BUDGET_ITEM_QUERY = gql`
    query getBudgetItem($id: String!) {
        budgetItems: getBudgetItems(filter: { id: $id }) {
            nodes {
                id
                serialNumber
                sapComment
                creationTime
                budget {
                    id
                    year
                }
                dictionary {
                    tool {
                        value
                    }
                    product {
                        value
                    }
                    segment {
                        value
                    }
                }
                plannedFunds { ${MONTHS_FRAGMENT} }
                reservedFunds { ${MONTHS_FRAGMENT} }
                factFunds { ${MONTHS_FRAGMENT} }
            }
        }
    }
`;

export interface BudgetItemData {
    loading: boolean;
    budget: BudgetItem;
}

interface Props extends ExternalProps, QueryProps {}

interface ExternalProps {
    id: string;
    forwardData: (props: BudgetItemData) => void;
}

type QueryProps = {
    budgetsQuery: {
        loading: boolean;
        budget?: BudgetItem;
    };
};

class BudgetItemFetcherBehaviour extends React.PureComponent<Props> {
    public componentDidUpdate(prevProps: Props): void {
        if (!this.props.budgetsQuery?.loading && prevProps.budgetsQuery?.loading) {
            this.forwardData();
        }
    }

    public render(): JSX.Element {
        return null;
    }

    private forwardData(): void {
        const { budgetsQuery } = this.props;

        const loading = budgetsQuery ? budgetsQuery.loading : true;
        const budget: BudgetItem = get(budgetsQuery, 'budgetItems.nodes[0]');

        this.props.forwardData({ loading, budget });
    }
}

export const BudgetItemFetcher = graphql<ExternalProps>(GET_BUDGET_ITEM_QUERY, {
    name: 'budgetsQuery',
    options: ({ id }) => ({
        variables: {
            id,
        },
        fetchPolicy: 'no-cache',
    }),
    skip: ({ id }) => !id,
})(BudgetItemFetcherBehaviour) as React.ComponentType<ExternalProps>;
