import * as React from 'react';

import { Correction, CorrectionType, CardBudgetItem } from '../types';

import { TableWithFactCorrection } from './TableWithFactCorrection';
import { TableWithReserveCorrection } from './TableWithReserveCorrection';
import { TableWithIncomeExternalCorrection } from './TableWithIncomeExternalCorrection';
import { TableWithOutcomeExternalCorrection } from './TableWithOutcomeExternalCorrection';
import { TableWithPlanTransferCorrection } from './TableWithPlanTransferCorrection';
import { TableWithoutCorrection } from './TableWithoutCorrection';

interface Props {
    budgetItem: CardBudgetItem;
    corrections: Correction[];
    selectedCorrectionId: string;
}

export class Table extends React.Component<Props> {
    public render() {
        const { budgetItem, selectedCorrectionId } = this.props;
        const correction = this.getSelectedCorrection();

        if (correction && correction.type === CorrectionType.FactFunds) {
            return React.createElement(TableWithFactCorrection, {
                correction,
                budgetItem,
                selectedCorrectionId,
            });
        }

        if (correction && correction.type === CorrectionType.ReservedFunds) {
            return React.createElement(TableWithReserveCorrection, {
                correction,
                budgetItem,
                selectedCorrectionId,
            });
        }

        if (correction && correction.type === CorrectionType.IncomeExternalPlanFundsTransfer) {
            return React.createElement(TableWithIncomeExternalCorrection, {
                correction,
                budgetItem,
            });
        }

        if (correction && correction.type === CorrectionType.OutcomeExternalPlanFundsTransfer) {
            return React.createElement(TableWithOutcomeExternalCorrection, {
                correction,
                budgetItem,
            });
        }

        if (correction && correction.type === CorrectionType.PlanFundsTransfer) {
            return React.createElement(TableWithPlanTransferCorrection, {
                correction,
                budgetItem,
            });
        }

        return React.createElement(TableWithoutCorrection, {
            budgetItem,
        });
    }

    private getSelectedCorrection(): Correction {
        const { corrections, selectedCorrectionId } = this.props;
        return corrections.find(({ id }) => id === selectedCorrectionId);
    }
}
