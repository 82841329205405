import * as React from 'react';
import { Stage } from '../../utils';

import { WithTooltipProps } from './types';
import { TooltipOfFirstStage } from './TooltipOfFirstStage';

const TOOLTIP_COMPONENTS_BY_STAGE_NUMBER_MAP: Record<Stage, React.FC> = {
    1: TooltipOfFirstStage,
    1.5: null,
    2: null,
    3: null,
    4: null,
    5: null,
    6: null,
};

interface Props extends WithTooltipProps {
    currentStage: number;
}

export class WithTooltip extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const TooltipComponent = TOOLTIP_COMPONENTS_BY_STAGE_NUMBER_MAP[this.props.currentStage];

        return TooltipComponent ? <TooltipComponent {...this.props} /> : <>{this.props.children}</>;
    }
}
