import { ListNode } from './ListNode';

export class LinkedList<T> {
    public length = 0;
    private head: ListNode<T>;
    private tail: ListNode<T>;

    public add(value: T): void {
        const newNode = new ListNode(value);

        if (this.length == 0) {
            this.head = newNode;
        } else {
            this.tail.next = newNode;
        }

        this.tail = newNode;

        this.length++;
    }

    public remove(position: number): void {
        this.checkNodeExistenceAtPosition(position);

        if (position == 1) {
            this.head = null;
            this.tail = null;
        } else {
            const previousNode = this.getNodeAtPosition(position - 1);

            previousNode.next = null;
            this.tail = previousNode;
        }

        this.length = position - 1;
    }

    public getValueAtPosition(position: number): T {
        this.checkNodeExistenceAtPosition(position);

        const node = this.getNodeAtPosition(position);

        return node.value;
    }

    private getNodeAtPosition(position: number): ListNode<T> {
        let currentNode = this.head;

        for (let i = 1; i < position; i++) {
            currentNode = currentNode.next;
        }

        return currentNode;
    }

    private checkNodeExistenceAtPosition(position: number): void {
        if (this.length == 0 || position < 1 || position > this.length) {
            throw new Error(`LinkedList error: node at position ${position} doesn't exist`);
        }
    }
}
