import { Success, Failure } from 'typescript-fsa';

import { TaskStatus } from 'sber-marketing-types/frontend';

import { TaskPageState, FetchError } from '../types';

export const moveToWorkTaskDoneReducer = (state: TaskPageState, {}: Success<null, void>): TaskPageState => ({
    ...state,
    status: TaskStatus.InProgress,
});

export const moveToWorkTaskErrorReducer = (state: TaskPageState, payload: Failure<null, FetchError>): TaskPageState =>
    state;

export const moveToWorkTaskStartedReducer = (state: TaskPageState, payload: null): TaskPageState => state;
