import * as React from 'react';
import * as moment from 'moment';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';
import autobind from 'autobind-decorator';

import type { StoreState } from '@store';
import { getBriefState } from '@store/brief/selectors';
import { TaskDeadlineChangesParams } from '@store/brief/types';
import { setTaskDeadlineDate } from '@store/brief/actions';

import { TaskDates } from './TaskDates';

interface Props extends Partial<MapProps & DispatchProps> {
    taskId: string;
    briefId: string;
}

interface MapProps {
    deadline: moment.Moment;
}

interface DispatchProps {
    setTaskDeadlineDate: (params: TaskDeadlineChangesParams) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class TaskDatesContainer extends React.Component<Props> {
    public render() {
        const { deadline } = this.props;

        return React.createElement(TaskDates, {
            deadline,
            editRight: true,
            onTaskDeadlineChange: this.onTaskDeadlineChange,
        });
    }

    @autobind
    private onTaskDeadlineChange(value: moment.Moment): void {
        this.props.setTaskDeadlineDate({
            briefId: this.props.briefId,
            deadline: value,
        });
    }
}

function mapStateToProps(state: StoreState, props: Props): MapProps {
    const { briefId } = props;
    const { changedTasks } = getBriefState(state);
    const { deadline } = changedTasks[briefId];

    return { deadline };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setTaskDeadlineDate,
        },
        dispatch,
    );
}
