import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { OrganizationView } from 'sber-marketing-types/backend';
import { uniqBy } from 'lodash';

import { LoadingStatus } from '@store/commonTypes';

import * as actions from './actions';

import { OrganizationsState, SetStoreIdsParams, SetLoadingStatusParams, StoreTypes, EntitiesStore } from './types';
class Reducer {
    public static createEmptyState(): OrganizationsState {
        return {
            entities: [],
            byIds: {},
            loadingStatus: LoadingStatus.NOT_LOADED,
            stores: {
                [StoreTypes.GENERAL]: Reducer.emptyEntitiesStore(),
                [StoreTypes.MY_TASKS_FILTER]: Reducer.emptyEntitiesStore(),
                [StoreTypes.AVAILABLE_ACTIVITIES_FILTERS]: Reducer.emptyEntitiesStore(),
                [StoreTypes.MY_ACTIVITIES_FILTER]: Reducer.emptyEntitiesStore(),
                [StoreTypes.ACTIVITY_PAGE_FILTERS]: Reducer.emptyEntitiesStore(),
                [StoreTypes.BUDGET_FILTERS]: Reducer.emptyEntitiesStore(),
            },
        };
    }

    public static loadEntities(state: OrganizationsState, organizations: OrganizationView[]): OrganizationsState {
        const entities = uniqBy([...state.entities, ...organizations], (activityType) => activityType.id);

        const byIds = organizations.reduce(
            (acc, organization) => ({
                ...acc,
                [organization.id]: organization,
            }),
            state.byIds,
        );

        return {
            ...state,
            entities,
            byIds,
        };
    }

    public static setStoreIds(state: OrganizationsState, payload: SetStoreIdsParams): OrganizationsState {
        const { store, ids } = payload;

        const updatedEntitiesStore: EntitiesStore = {
            loadingStatus: state.stores[store].loadingStatus,
            ids,
        };

        return {
            ...state,
            stores: {
                ...state.stores,
                [store]: updatedEntitiesStore,
            },
        };
    }

    public static setLoadingStatus(state: OrganizationsState, payload: SetLoadingStatusParams): OrganizationsState {
        const { store, status } = payload;

        const updatedEntitiesStore: EntitiesStore = {
            loadingStatus: status,
            ids: state.stores[store].ids,
        };

        return {
            ...state,
            stores: {
                ...state.stores,
                [store]: updatedEntitiesStore,
            },
        };
    }

    public static setCommonLoadingStatus(state: OrganizationsState, loadingStatus: LoadingStatus): OrganizationsState {
        return {
            ...state,
            loadingStatus,
        };
    }

    public static resetStore(state: OrganizationsState, store: StoreTypes): OrganizationsState {
        const updatedStore: EntitiesStore = {
            loadingStatus: LoadingStatus.NOT_LOADED,
            ids: [],
        };

        return {
            ...state,
            stores: {
                ...state.stores,
                [store]: updatedStore,
            },
        };
    }

    private static emptyEntitiesStore(): EntitiesStore {
        return {
            loadingStatus: LoadingStatus.NOT_LOADED,
            ids: [],
        };
    }
}

const initialState: OrganizationsState = Reducer.createEmptyState();

export const organizationsReducer = reducerWithInitialState(initialState)
    .case(actions.loadEntities, Reducer.loadEntities)
    .case(actions.setStoreIds, Reducer.setStoreIds)
    .case(actions.setLoadingStatus, Reducer.setLoadingStatus)
    .case(actions.setCommonLoadingStatus, Reducer.setCommonLoadingStatus)
    .case(actions.resetStore, Reducer.resetStore);
