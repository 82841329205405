// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-TagsList-FiltersDropdown-Content-List-Item-_styles__root{position:relative;width:100%}.src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-TagsList-FiltersDropdown-Content-List-Item-_styles__applyFiltersButtonWrapper{padding:12px 16px 20px 16px;width:100%}.src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-TagsList-FiltersDropdown-Content-List-Item-_styles__applyFiltersButtonContent{font-family:'Open Sans';font-weight:600;font-size:12px;line-height:16px;letter-spacing:0.4px;height:32px;display:flex;align-items:center;color:#000000}.src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-TagsList-FiltersDropdown-Content-List-Item-_styles__applyFilterButtonIcon{margin-right:8px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetPlanning/FiltersColumnsTagsBar/TagsList/FiltersDropdown/Content/List/Item/styles.scss"],"names":[],"mappings":"AAAA,oIACI,iBAAkB,CAClB,UAAW,CACd,yJAGG,2BAA4B,CAC5B,UAAW,CACd,yJAGG,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,oBAAqB,CACrB,WAAY,CAEZ,YAAa,CACb,kBAAmB,CAEnB,aAAc,CACjB,qJAGG,gBAAiB","sourcesContent":[".root {\n    position: relative;\n    width: 100%;\n}\n\n.applyFiltersButtonWrapper {\n    padding: 12px 16px 20px 16px;\n    width: 100%;\n}\n\n.applyFiltersButtonContent {\n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 12px;\n    line-height: 16px;\n    letter-spacing: 0.4px;\n    height: 32px;\n\n    display: flex;\n    align-items: center;\n\n    color: #000000;\n}\n\n.applyFilterButtonIcon {\n    margin-right: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-TagsList-FiltersDropdown-Content-List-Item-_styles__root",
	"applyFiltersButtonWrapper": "src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-TagsList-FiltersDropdown-Content-List-Item-_styles__applyFiltersButtonWrapper",
	"applyFiltersButtonContent": "src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-TagsList-FiltersDropdown-Content-List-Item-_styles__applyFiltersButtonContent",
	"applyFilterButtonIcon": "src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-TagsList-FiltersDropdown-Content-List-Item-_styles__applyFilterButtonIcon"
};
export default ___CSS_LOADER_EXPORT___;
