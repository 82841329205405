import * as React from 'react';

import * as style from './PlanCorrectionCard.scss';

import { CorrectionStatus } from '@mrm/budget';

import type { ExcessReserveWarningProps as Warning } from '../common';

import {
    CorrectionCardHeader,
    RejectComment,
    ExcessReserveWarningList,
    CommonFondsChange,
    SubPramList,
    CorrectionCardComment,
} from '../common';

export type { Warning };

const STATUS_COLOR = {
    [CorrectionStatus.NeedApproving]: '#f5a623',
    [CorrectionStatus.Approved]: '#19bb4f',
    [CorrectionStatus.Rejected]: '#e63901',
};

export interface ParticipatorData {
    monthFond: {
        month: string;
        delta: number;
        beforeFond: number;
        afterFond: number;
    };
    allFond: {
        delta: number;
        beforeFond: number;
        afterFond: number;
    };
    fondName: string;
    fields: ParticipatorField[];
    comment: string;
}

export interface ParticipatorField {
    name: string;
    value: React.ReactText;
    isWide?: boolean;
}

interface Props {
    serialNumber: number;
    authorName: string;
    approverName: string;
    creationDate: string;
    donorData: ParticipatorData;
    acceptorData: ParticipatorData;
    status: CorrectionStatus;
    rejectComment: string;
    warnings: Warning[];
}

export const PlanCorrectionCard = ({
    serialNumber,
    authorName,
    approverName,
    creationDate,
    donorData,
    acceptorData,
    status,
    rejectComment,
    warnings,
}: Props): JSX.Element => {
    const haveWarnings = Boolean(warnings.length);

    return (
        !!donorData &&
        !!acceptorData && (
            <div className={style.root}>
                <CorrectionCardHeader
                    title={'Корректировка плана'}
                    serialNumber={serialNumber}
                    authorName={authorName}
                    approverName={approverName}
                    creationDate={creationDate}
                    status={status}
                />

                {rejectComment && <RejectComment text={rejectComment} />}

                {haveWarnings && <ExcessReserveWarningList warnings={warnings} />}

                <div className={style.participators}>
                    <div
                        className={style.participatorsRow}
                        {...{
                            'qa-id': 'correctionCardParticipatorsInfo',
                        }}
                    >
                        <div
                            className={style.participatorsCell}
                            {...{
                                'qa-id': 'correctionCardParticipatorLeftColumn',
                            }}
                        >
                            <ParticipatorSubInfo data={donorData} />
                        </div>
                        <div
                            className={style.participatorsCell}
                            {...{
                                'qa-id': 'correctionCardParticipatorRightColumn',
                            }}
                        >
                            <ParticipatorSubInfo data={acceptorData} />
                        </div>
                    </div>
                    <div
                        className={style.participatorsRow}
                        {...{
                            'qa-id': 'correctionCardCorrectionParams',
                        }}
                    >
                        <div
                            className={style.participatorsCell}
                            {...{
                                'qa-id': 'correctionCardCorrectionParamsLeftColumn',
                            }}
                        >
                            <div className={style.subInfoContent}>
                                <SubPramList supParams={donorData.fields} />
                                {donorData.comment && (
                                    <SubPramList
                                        supParams={[
                                            {
                                                name: 'Комментарий к строке',
                                                value: <CorrectionCardComment text={donorData.comment} />,
                                                isWide: true,
                                                notCropContent: true,
                                            },
                                        ]}
                                    />
                                )}
                            </div>
                        </div>
                        <div
                            className={style.participatorsCell}
                            {...{
                                'qa-id': 'correctionCardCorrectionParamsRightColumn',
                            }}
                        >
                            <div className={style.subInfoContent}>
                                <SubPramList supParams={acceptorData.fields} />
                                {acceptorData.comment && (
                                    <SubPramList
                                        supParams={[
                                            {
                                                name: 'Комментарий к строке',
                                                value: <CorrectionCardComment text={acceptorData.comment} />,
                                                isWide: true,
                                                notCropContent: true,
                                            },
                                        ]}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={style.status} style={{ backgroundColor: STATUS_COLOR[status] }} />
            </div>
        )
    );
};

interface ParticipatorProps {
    data: ParticipatorData;
}

const ParticipatorSubInfo: React.FC<ParticipatorProps> = ({ data }) => {
    const { monthFond, allFond, fondName } = data;

    return (
        <div className={style.subInfo}>
            <div className={style.subInfoTitle}>{fondName}</div>

            <div className={style.budgets}>
                <div className={style.budget}>
                    <CommonFondsChange
                        title={'Бюджет'}
                        month={monthFond.month}
                        before={monthFond.beforeFond}
                        delta={monthFond.delta}
                    />
                </div>

                <div className={style.budget}>
                    <CommonFondsChange
                        title={'Плановый бюджет по строке'}
                        before={allFond.beforeFond}
                        delta={allFond.delta}
                    />
                </div>
            </div>
        </div>
    );
};
