import gql from 'graphql-tag';

export const WITH_REQUEST_BY_ID_QUERY = gql`
    query requestById($requestId: String!) {
        request: getProjectLinkBudgetItemApprovalRequests(filter: { id: [$requestId] }) {
            nodes {
                status
            }
        }
    }
`;
