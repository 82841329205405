import { head } from 'lodash';
import { CommonFormatter, Money, MoneyFormatter } from '@common/Utils';

const COPECKS_REGEX = /,\d{1,2}/g;
const RUBLES_REGEX = /^\d*/g;
const SEPARATOR_REGEX = /,/g;

export interface MoneyParts {
    rubles: string;
    separator: string;
    copecks: string;
}

export const convertValueFromVisibleToEditableFormat = (value: string): string =>
    value.replace(/ /g, '').replace(/\./g, ',');

export const convertValueFromExternalToVisibleFormat = (externalValue: number): string => {
    return MoneyFormatter.toRoubles(Money.fromCopecks(externalValue), {
        forceSign: false,
        hideCaption: true,
        isCopecksRequired: true,
    }).replace(/\./g, ',');
};

export const convertValueFromEditableToVisibleFormat = (value: string): string => {
    const { rubles, separator, copecks } = getMoneyParts(value);
    const splitedRubles = CommonFormatter.splitNumberByScales(Number(rubles));

    return `${splitedRubles}${separator}${copecks}`;
};

export const convertValueFromEditableToVisibleFormatWithCopecks = (value: string): string => {
    const { rubles, copecks } = getMoneyParts(value);
    const splitedRubles = CommonFormatter.splitNumberByScales(Number(rubles));

    return `${splitedRubles},${copecks.padEnd(2, '0')}`;
};

export const convertValueFromEditableToVisibleFormatWithoutCopecks = (value: string): string => {
    const { rubles, copecks, separator } = getMoneyParts(value);
    const splitedRubles = CommonFormatter.splitNumberByScales(Number(rubles));

    if (/^0+/g.test(copecks)) {
        return `${splitedRubles}`;
    }

    return `${splitedRubles}${separator}${copecks}`;
};

export const convertValueFromVisibleToExternalFormat = (innerValue: string): number => {
    const formattedInnerValue = innerValue.replace(/ /g, '').replace(/,/g, '.');
    return Money.fromStringRoubles(formattedInnerValue).getCopecks();
};

export const getMoneyParts = (value: string) => {
    const rubles = head(value.match(RUBLES_REGEX)) || '';
    const separator = head(value.match(SEPARATOR_REGEX)) || '';
    const copecks = (head(value.match(COPECKS_REGEX)) || '').replace(/,/g, '');

    return {
        rubles,
        separator,
        copecks,
    };
};

export const checkValueForValidity = (value: string): boolean => /\d*(,\d{0,2})?/g.test(value);
