import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { FileAssetTarget } from '@store/commonTypes';

export const enum FileAssetType {
    DOC = 'doc',
    PDF = 'pdf',
    TXT = 'txt',
    JPG = 'jpg',
    XLS = 'xls',
    PNG = 'png',
}

export const enum FileAssetSize {
    SMALL = 'small',
    BIG = 'big',
}

export interface FileAssetData {
    name: string;
    originName: string;
    storage?: string;
    type: FileAssetType | string;
    createdAt: number | string | Date;
    targetType: FileAssetTarget | string;
    targetId: string;
    isLoading?: boolean;
}

/** Asset options */
export interface FileAssetOptions {
    /** Asset size ("AssetSize.SMALL" by default) */
    size?: FileAssetSize;
    /** Additional CSS classes */
    className?: string;
    /** If "true", creation date of asset will be hidden */
    hideDate?: boolean;
}

/** Asset container component properties */
export interface FileAssetContainerProps
    extends FileAssetData,
        FileAssetOptions,
        Partial<RouteComponentProps<RouteProps>> {
    /** If "true", asset can be deleted by use */
    isDeletable?: boolean;
    disableDownload?: boolean;
    /** Remove click handler */
    onRemoveClick?(asset: FileAssetData): any;
}

interface RouteProps {
    taskId: string;
}

/** Asset container component state */
export interface FileAssetContainerState {
    /** "true" if asset remove popup is opened */
    isRemovePopupOpen: boolean;
}

/** Asset properties */
export interface FileAssetTemplateProps extends FileAssetData, FileAssetContainerState, FileAssetOptions {
    isDeletable: boolean;
    /** Remove click handler */
    onRemoveOpenClick: React.MouseEventHandler<HTMLDivElement>;
    onDownloadClick: () => void;
    onRemoveClick: () => void;
    onRemoveCancelClick: () => void;
}
