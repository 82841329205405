import * as React from 'react';
import { BudgetItem } from '@mrm/budget';
import {
    Icon,
    IconType,
    WithTooltip,
    TooltipAnchor,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    StaticSkeleton,
} from 'sber-marketing-ui';

import { ActivityApi, BudgetItemApi } from '@api';

import * as styles from './ActivitySourveBudgetItem.scss';

interface Props {
    activityId: number;
    currentBudgetId: string;
    existingLinkIds: string[];
    selectBudgetItem: (budgetItemIds: string[]) => void;
}

function useBudgetItemSerialNumber(activityId: number) {
    const [isRequestInProgress, setIsRequestInProgress] = React.useState(false);
    const [budgetItem, setBudgetItem] = React.useState<BudgetItem>(null);

    async function fetchActivity() {
        if (activityId) {
            setIsRequestInProgress(true);
            const { sourceBudgetItemId } = await ActivityApi.getActivity(activityId);

            if (sourceBudgetItemId) {
                setBudgetItem(await BudgetItemApi.getBudgetItem(sourceBudgetItemId));
            }
        }

        setIsRequestInProgress(false);
    }

    React.useEffect(() => {
        fetchActivity();
    }, [activityId]);

    return { isRequestInProgress, budgetItem };
}

export function ActivitySourceBudgetItem({
    activityId,
    currentBudgetId,
    existingLinkIds,
    selectBudgetItem,
}: Props): JSX.Element {
    const { isRequestInProgress, budgetItem } = useBudgetItemSerialNumber(activityId);
    const shouldRender = budgetItem || isRequestInProgress;

    return shouldRender ? (
        <div className={styles.root}>
            {isRequestInProgress ? (
                <StaticSkeleton className={styles.skeleton} />
            ) : (
                <Content
                    budgetItem={budgetItem}
                    currentBudgetId={currentBudgetId}
                    existingLinkIds={existingLinkIds}
                    selectBudgetItem={selectBudgetItem}
                />
            )}
        </div>
    ) : null;
}

interface ContentProps extends Pick<Props, 'currentBudgetId' | 'selectBudgetItem' | 'existingLinkIds'> {
    budgetItem: BudgetItem;
}

function Content({ budgetItem, currentBudgetId, existingLinkIds, selectBudgetItem }: ContentProps): JSX.Element {
    const budgetItemId = budgetItem?.id;
    const budgetItemSerialNumber = budgetItem?.serialNumber;
    const budgetId = budgetItem?.budgetId;

    const showlinkButton = budgetId === currentBudgetId && !existingLinkIds.includes(budgetItemId);

    return (
        <React.Fragment>
            <WithTooltip
                spanWrapper
                content={`Проект создан на основе ID ${budgetItemSerialNumber}`}
                anchor={TooltipAnchor.LEFT}
            >
                <Icon svgSize={16} type={IconType.INFO_CIRCLE} className={styles.infoIcon} />
            </WithTooltip>

            <div className={styles.serialNumber}>ID {budgetItemSerialNumber}</div>

            <div className={styles.status}>Рекомендован</div>

            {showlinkButton && (
                <div className={styles.linkButton}>
                    <Button
                        theme={ButtonTheme.GhostRoundedBlack}
                        onClick={() => {
                            if (budgetItemId) {
                                selectBudgetItem([budgetItemId]);
                            }
                        }}
                    >
                        Выбрать
                    </Button>
                </div>
            )}
        </React.Fragment>
    );
}
