import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType, WithTooltip } from 'sber-marketing-ui';
import { CardIndicatorColor } from '@common/Card';
import * as style from './CardIndicator.scss';

export const enum ColorCssClassMap {
    GREEN = 'green',
    GRAY = 'gray',
    RED = 'red',
    BLUE = 'blue',
    YELLOW = 'yellow',
}

export const enum ThicknessCssClassMap {
    NARROW = 'narrow',
    WIDE = 'wide',
}

interface Props {
    color?: ColorCssClassMap;
    thickness?: ThicknessCssClassMap;
    tooltip?: string;
}

export const CardIndicator: React.FC<Props> = ({
    children,
    color = ColorCssClassMap.GRAY,
    thickness = ThicknessCssClassMap.WIDE,
    tooltip,
}) => (
    <WithTooltip hidden={!tooltip} content={tooltip}>
        <div
            className={classNames(style.root, style[color], style[thickness])}
            {...{
                'qa-id': 'cardIndicator',
                'qa-value': tooltip,
            }}
        >
            {children}
        </div>
    </WithTooltip>
);

export const IconWrapper: React.FC = ({ children }) => <div className={style.iconWrapper}>{children}</div>;

export const UserCurtIndicator: React.FC<{ tooltip: string }> = ({ children, tooltip }) => (
    <CardIndicator color={CardIndicatorColor.GRAY} tooltip={tooltip}>
        <IconWrapper>
            <Icon type={IconType.USER16} svgSize={16} />
        </IconWrapper>
        {children}
    </CardIndicator>
);

export { Props as CardIndicatorProps };
