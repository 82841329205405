import * as React from 'react';
import classNames from 'classnames';

import { buildChart, getColorClassNames, Data } from './utils';
import { Tooltip } from './Tooltip';
import type { TabBarConfig, Mode } from './types';
import { Color } from './types';

import { useMode } from '../../../hooks';

import * as styles from './styles.scss';

interface Props {
    data: Data[];
    color: Color;
    tabBarConfig: TabBarConfig;
    onClick: (statisticsItemId: string) => void;
}

const titleByMode: Record<Mode, string> = {
    activities: 'Распределение проектов в подразделениях',
    tasks: 'Распределение задач в подразделениях',
};

const histogramBottomNoteByMode: Record<Mode, string> = {
    activities: 'Суммарное число проектов',
    tasks: 'Суммарное число задач',
};

export const Histogram: React.FC<Props> = ({ data, color, tabBarConfig, onClick }) => {
    const { mode } = useMode();

    const [opened, open] = React.useState(false);
    const [hoveredId, setHoveredId] = React.useState('string');
    const [coordinate, setCoordinate] = React.useState({ x: 0, y: 0 });

    const onMouseEnter = React.useCallback((id: string, event: React.MouseEvent<HTMLDivElement>) => {
        const x =
            event.currentTarget.getBoundingClientRect().left + event.currentTarget.getBoundingClientRect().width / 2;
        const y = event.currentTarget.getBoundingClientRect().top;

        open(true);
        setCoordinate({ x, y });
        setHoveredId(id);
    }, []);

    const onMouseLeave = React.useCallback((id: string) => {
        open(false);
    }, []);

    const id = React.useMemo(() => `${color}`, [color]);

    const tooltipData = data.find(({ id }) => id === hoveredId);

    React.useEffect(() => {
        return buildChart(data, {
            selectorId: id,
            bottomNote: histogramBottomNoteByMode[mode] || '',
            onMouseEnter,
            onMouseLeave,
            onClick,
        });
    }, [data]);

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className={styles.title}>{titleByMode[mode] || 'По подразделениям'}</div>
                <div className={styles.explanations}>
                    {tabBarConfig.map(({ name, color }) => (
                        <div key={`${name}-${color}`} className={styles.explanation}>
                            <div
                                className={classNames(
                                    styles.indicator,
                                    getColorClassNames(color),
                                    styles.secondaryIndicator,
                                )}
                            />
                            {name}
                        </div>
                    ))}
                </div>
            </div>

            <div id={id} />

            {opened && tooltipData && (
                <Tooltip key={tooltipData.id} department={tooltipData} bar={tooltipData.bar} coordinate={coordinate} />
            )}
        </div>
    );
};
