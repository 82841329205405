import * as React from 'react';

import { ActivityWidgetContainer } from './ActivityWidgetContainer';
import { WithStore } from './WithStore';
import { WithActivityQuery } from './WithActivityQuery';

interface Props {
    requestId: string;
}

export class ActivityWidgetConnected extends React.PureComponent<Props> {
    public render() {
        return (
            <WithStore>
                {({ selectedCardId, activityId }) => (
                    <WithActivityQuery activityId={activityId} requestId={this.props.requestId}>
                        {({ activity, loading, requestActivityName, loadActivity }) =>
                            React.createElement(ActivityWidgetContainer, {
                                selectedCardId,
                                activityId,
                                activity,
                                loading,
                                requestActivityName,
                                loadActivity,
                            })
                        }
                    </WithActivityQuery>
                )}
            </WithStore>
        );
    }
}
