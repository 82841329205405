import * as React from 'react';

import { LineParams, TasksByStageId } from '../types';

import { Sidebar } from './Sidebar';

interface Props {
    lines: LineParams[];
    hoveredLineIndex: number;
    editable: boolean;
    tasksByStageId: TasksByStageId;
    onLineHover: (lineIndex: number) => void;
    onLineCheckboxClick: (lineIndex: number) => void;
    setCanShowStageDuration: (canShowStageDuration: boolean) => void;
    onSelectStageClick?: (stageId: string) => void;
    createTaskByNameAndStage?(taskName: string, stageId: string): Promise<void>;
}

export class SidebarContainer extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        return React.createElement(Sidebar, {
            lines: this.props.lines,
            hoveredLineIndex: this.props.hoveredLineIndex,
            editable: this.props.editable,
            tasksByStageId: this.props.tasksByStageId,
            onLineHover: this.props.onLineHover,
            onLineCheckboxClick: this.props.onLineCheckboxClick,
            setCanShowStageDuration: this.props.setCanShowStageDuration,
            onSelectStageClick: this.props.onSelectStageClick,
            createTaskByNameAndStage: this.props.createTaskByNameAndStage,
        });
    }
}
