import { axios } from '../../lib/axios';

import { FileUploadParams, FileDownloadLinkParams } from 'sber-marketing-types/backend';
import { FileService } from './FileService';
import { FileApiUploadParams } from './types';

interface CommonData extends FileApiUploadParams {
    containerName: string;
    fileName: string;
}

export class SelcdnService extends FileService {
    public async getDownloadLink(params: FileApiUploadParams, settings: FileDownloadLinkParams): Promise<string> {
        const res = await axios.post('/api/object/cloud/download-link', {
            ...params,
            containerName: settings.containerName,
            fileName: settings.fileName,
            originName: settings.originName,
        });

        return res.data.link;
    }

    public async makeSharedLink(params: FileApiUploadParams, settings: FileDownloadLinkParams): Promise<string> {
        const res = await axios.post('/api/object/cloud/shared-link', {
            ...params,
            containerName: settings.containerName,
            fileName: settings.fileName,
            originName: settings.originName,
        });

        return res.data.link;
    }

    protected async whollyUpload(params: FileApiUploadParams, file: File, settings: FileUploadParams): Promise<void> {
        const data = {
            ...params,
            containerName: settings.containerName,
            fileName: settings.fileName,
        };

        await this.sendFile('/api/object/cloud/upload-file', file, data);
    }

    protected async multipartUpload(
        params: FileApiUploadParams,
        chunks: Blob[],
        settings: FileUploadParams,
    ): Promise<void> {
        await this.initUpload(params, settings);
        await this.chunksUpload(params, chunks, settings);
        await this.finishUpload(params, settings);
    }

    protected async *multipartUploadProcess(
        params: FileApiUploadParams,
        chunks: Blob[],
        settings: FileUploadParams,
    ): AsyncIterableIterator<number> {
        await this.initUpload(params, settings);
        yield 0;
        yield* await this.chunksUploadProcess(this.getCommonData(params, settings), chunks);
        await this.finishUpload(params, settings);
        yield 100;
    }

    private async initUpload(params: FileApiUploadParams, settings: FileUploadParams): Promise<void> {
        await axios.post('/api/object/cloud/multipart-upload-init', {
            ...params,
            containerName: settings.containerName,
        });
    }

    private getCommonData(params: FileApiUploadParams, settings: FileUploadParams): CommonData {
        return {
            ...params,
            containerName: settings.containerName,
            fileName: settings.fileName,
        };
    }

    private async chunksUpload(params: FileApiUploadParams, chunks: Blob[], settings: FileUploadParams) {
        const uploadProcess = this.chunksUploadProcess(this.getCommonData(params, settings), chunks);
        for await (const progress of uploadProcess) {
            console.log(`${progress}% uploaded`);
        }
    }

    private async *chunksUploadProcess(commonData: CommonData, chunks: Blob[]): AsyncIterableIterator<number> {
        for (let partNumber = 1; partNumber <= chunks.length; partNumber++) {
            const data = { ...commonData, partNumber };
            await this.sendFile('/api/object/cloud/multipart-upload', chunks[partNumber - 1], data);
            yield (partNumber / chunks.length) * 100;
        }
    }

    private async finishUpload(params: FileApiUploadParams, settings: FileUploadParams): Promise<void> {
        await axios.post('/api/object/cloud/multipart-upload-complete', {
            ...params,
            fileName: settings.fileName,
            containerName: settings.containerName,
        });
    }
}
