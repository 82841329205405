import * as React from 'react';
import classNames from 'classnames';
import * as queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isNil } from 'lodash';
import { Link } from 'react-router-dom';
import { Icon, IconType, StyledPopup, StyledPopupTheme } from 'sber-marketing-ui';

import { deleteChannel, setCommentIdToReplyTo } from '@store/taskPage';

import { AnimatedInProgressIcon } from '@common/AnimatedInProgressIcon';

import * as styles from './ChannelButton.scss';
import { ChannelButtonProps } from './types';

/** Channel button */
export const ChannelButton: React.SFC<ChannelButtonProps> = ({
    canDelete,
    className,
    taskId,
    activityId,
    from,
    name,
    hasUnseenComments,
    isOpened,
    id,
    onClick,
}: ChannelButtonProps): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [isDeleteModalVisible, setIsDeleteModalVisible] = React.useState(false);
    const [isDeletionInProgress, setIsDeletionInProgress] = React.useState(false);

    const rootClassName = classNames(styles.root, isOpened && styles.root_isOpened, className);

    const query: string = queryString.stringify({
        from,
        channelId: isNil(id) ? undefined : id, // Because only "undefined" items skips at query strings
    });

    const to = `/activity/${activityId}/task/${taskId}?${query}`;

    function onLinkClick(event: React.MouseEvent<HTMLAnchorElement>) {
        if (!isDeletionInProgress) {
            onClick(event);
        }

        if (!isOpened) {
            dispatch(setCommentIdToReplyTo(null));
        }
    }

    async function initDeletion() {
        setIsDeletionInProgress(true);
        setIsDeleteModalVisible(false);

        if (isOpened) {
            history.push(`/activity/${activityId}/task/${taskId}`);
        }

        dispatch(deleteChannel({ taskId, channelId: id }));
    }

    return (
        <React.Fragment>
            <Link className={rootClassName} onClick={onLinkClick} to={isDeletionInProgress ? '' : to}>
                <div
                    className={styles.name}
                    {...{
                        'qa-id': 'taskChannelLink',
                        'qa-label': name,
                    }}
                >
                    {name}

                    {hasUnseenComments && <div className={styles.unseenMark} />}

                    {canDelete && !isDeletionInProgress && (
                        <Icon
                            type={IconType.CROSS}
                            svgSize={9}
                            className={styles.deleteButton}
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setIsDeleteModalVisible(true);
                            }}
                        />
                    )}

                    {isDeletionInProgress && <AnimatedInProgressIcon />}
                </div>

                <div className={styles.underline} />
            </Link>

            {isDeleteModalVisible && (
                <StyledPopup
                    theme={StyledPopupTheme.Redesign}
                    qaId="deleteChannelModal"
                    title="Удаление канала"
                    fullMessage="Вы уверены, что хотите удалить канал? Все комментарии и файлы канала также будут удалены."
                    firstButtonText="Да, удалить"
                    firstButtonQaId="deleteChannelModalConfirmButton"
                    firstButtonHandler={initDeletion}
                    secondButtonText="Отмена"
                    secondButtonQaId="deleteChannelModalCancelButton"
                    secondButtonHandler={() => setIsDeleteModalVisible(false)}
                />
            )}
        </React.Fragment>
    );
};

ChannelButton.displayName = 'frontend::task-board::ChannelButton';
