import axios, { AxiosResponse } from 'axios';
import { store } from '../../store';
import { updateError, updateErrorMessage } from '../../store/common/actions';

const UNAUTHORISED_CODE = 401;
const GATEWAY_TIMEOUT = 504;

const BASE_URL: string = typeof window != 'undefined' && window['BASE_URL'] ? window['BASE_URL'] : '';

const instance = axios.create({
    baseURL: BASE_URL,
});

instance.interceptors.response.use(
    (response) => response,
    (error) => {
        let errorData = error.response.data;
        errorData = errorData.data.code ? errorData.data : errorData;
        if (error.response.status == UNAUTHORISED_CODE) {
            window.location.pathname = '/dashboard';
            return Promise.reject(error.message);
        }

        if (error.response.status === GATEWAY_TIMEOUT) {
            store.dispatch(updateError('GatewayTimeout'));

            return Promise.reject(error.message);
        }

        if (!errorData?.ignoreErrorDispatch) {
            store.dispatch(updateError(errorData.code));
            store.dispatch(updateErrorMessage(errorData?.data?.message));
        }

        return Promise.reject(new Error(errorData.message));
    },
);

export { instance, AxiosResponse };
