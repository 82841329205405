import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { uniqBy, flatten } from 'lodash';
import { FileResponse } from 'sber-marketing-types/frontend';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getLoginUser } from '@store/user';
import { getActivity } from '@store/activityTasksPage/selectors';

export const getActivityDAMArchiveState = (state: StoreState) => state.activityFilesDAMArchive;

export const canEditArchive = createSelector(getLoginUser, getActivity, (user, activity) => {
    if (!activity) {
        return false;
    }

    const { authorId, responsibleId } = activity;
    const userId = user.attributes.id;

    return userId === authorId || userId === responsibleId;
});

export const shouldRenderArchiveControls = createSelector(
    canEditArchive,
    (state: StoreState) => getActivityDAMArchiveState(state).archive,
    (canEdit, archive) => canEdit && !!archive,
);

export const showPreloader = createSelector(
    (state: StoreState) => getActivityDAMArchiveState(state).loadingStatus,
    canEditArchive,
    (loadingStatus, canEdit) =>
        loadingStatus.common !== LoadingStatus.LOADED ||
        (canEdit ? loadingStatus.tasksFiles !== LoadingStatus.LOADED : false),
);

export const getArchiveTaskFiles = createCachedSelector(
    (state: StoreState) => getActivityDAMArchiveState(state).archive,
    (state: StoreState, taskId: string) => taskId,
    (archive, taskId) =>
        archive?.tasks?.find((task) => task.id === taskId)?.files?.filter((file) => file.damFile) || [],
)((state: StoreState, taskId: string) => taskId);

export const getTaskFilesForExport = createCachedSelector(
    (state: StoreState) => getActivityDAMArchiveState(state).tasksFiles.byTaskId,
    getArchiveTaskFiles,
    (state: StoreState, taskId: string) => taskId,
    (tasksFiles, archiveFiles, taskId) => {
        const files: FileResponse[] = tasksFiles[taskId] || [];
        const archiveFilesIds = archiveFiles.map((file) => file.mrmFile.id);

        return files.filter((file) => !archiveFilesIds.includes(file.id));
    },
)((state: StoreState, taskId: string) => taskId);

export const getArchiveFiles = createSelector(
    (state: StoreState) => getActivityDAMArchiveState(state).archive,
    (archive) => flatten(archive?.tasks?.map((task) => task.files))?.filter((file) => file.damFile)?.length || 0,
);

export const getTotalFiles = createSelector(
    (state: StoreState) => getActivityDAMArchiveState(state).tasksFiles.entities,
    (tasksFiles) => tasksFiles.length,
);

export const filesToExportContainsNameDuplicates = createSelector(
    (state: StoreState) => getActivityDAMArchiveState(state).tasksFiles.entities,
    (state: StoreState) => getActivityDAMArchiveState(state).archiveContentPopup.filesToExport,
    (tasksFiles, filesToExport) => {
        const selectedFiles = tasksFiles.filter((file) => filesToExport.includes(file.id));
        const hasFilesWithSameName =
            selectedFiles.length !== uniqBy(selectedFiles, (file) => `${file.originName}.${file.type}`).length;

        return hasFilesWithSameName;
    },
);
