import * as React from 'react';
import classNames from 'classnames';
import { v4 } from 'uuid';

import { IconType } from 'sber-marketing-ui';
import type { FileResponse, TaskCommentTransferObject } from 'sber-marketing-types/backend';

import {
    useAddCommentFileMutation,
    useEditCommentMutation,
    useRemoveCommentFileMutation,
    useSendCommentMutation,
} from '@api';

import { useSearch } from '@common/hooks';

import { getFilesCountText, parseFileName } from '@modules/files/utils';
import { useAddFiles } from '@modules/files/hooks';

import { Flex, FlexProps, IconTag, Tag, TagClose, Scrollbar, Space } from '@common/components';
import { SelectParticipantEventData } from '@modules/task/pages';

import { CommentTextarea } from '@modules/task/components';
import { FileThumbnail, SimpleFileThumbnail } from '@modules/files/components';

import * as styles from './CommentForm.scss';

export interface CommentFormProps extends FlexProps {
    taskId: string;
    channelId: number;
    comment?: TaskCommentTransferObject;
    onSubmit?: () => void;
    onCancel?: () => void;
}

export function CommentForm({
    taskId,
    channelId,
    comment,
    className,
    onFocus,
    onSubmit,
    onCancel,
    ...props
}: CommentFormProps) {
    const commentId = React.useMemo(() => comment?.id || v4(), [comment]);
    const [search] = useSearch();
    const [commentText, setCommentText] = React.useState('');
    const [focus, setFocus] = React.useState(false);
    const [files, setFiles] = React.useState<FileResponse[]>([]);
    const [removableFiles, setRemovableFiles] = React.useState<string[]>([]);
    const [addableFiles, setAddableFiles] = React.useState<FileResponse[]>([]);
    const [newFiles, setNewFiles] = React.useState<File[]>([]);
    const [addFiles, { isAddFileLoading, progress }] = useAddFiles({ taskId, commentId });
    const pasteHandlerRef = React.useRef<(rawFiles: File[]) => Promise<void>>();
    const [sendComment, { isLoading: isCommentSending }] = useSendCommentMutation();
    const [editComment] = useEditCommentMutation();
    const [addFile, { isLoading: isAddingFile }] = useAddCommentFileMutation();
    const [removeFile] = useRemoveCommentFileMutation();
    const filesLength = newFiles.length + files.length;
    const isActive =
        Boolean(commentText) ||
        Boolean(filesLength) ||
        Boolean(comment) ||
        focus ||
        isAddFileLoading ||
        isCommentSending;

    React.useEffect(() => {
        setCommentText(comment?.text || '');
        setFiles(comment?.files || []);
    }, [comment]);

    React.useEffect(() => {
        const listener = (e: CustomEvent<SelectParticipantEventData>) => {
            const {
                id,
                user: {
                    value: { firstName, secondName },
                },
            } = e.detail;
            setCommentText(commentText + `[@${secondName} ${firstName}#${id}]`);
        };
        document.addEventListener('clickParticipant', listener);

        return () => {
            document.removeEventListener('clickParticipant', listener);
        };
    }, [commentText]);

    const reset = () => {
        setFocus(false);
        setCommentText('');
        setFiles([]);
        setRemovableFiles([]);
        setAddableFiles([]);
        setNewFiles([]);
    };

    const handleDownloadFiles = async (files: File[], commentId?: string) =>
        (await addFiles(files, commentId && { commentId, taskId })).map(
            ({ name, storage, originName, type, size, createdAt }) => ({
                id: name,
                type,
                originName,
                name,
                createTime: createdAt,
                size,
                storage,
            }),
        );

    const handleAddFiles = async (rawFiles: File[]) => {
        if (comment) {
            const newFiles: FileResponse[] = await handleDownloadFiles(rawFiles);

            setAddableFiles([...addableFiles, ...newFiles]);
            setFiles([...newFiles, ...files]);
            return;
        }

        setNewFiles([...newFiles, ...rawFiles]);
    };

    pasteHandlerRef.current = handleAddFiles;

    const handleRemoveNewFile = (file: File) => {
        setNewFiles(newFiles.filter((f) => f !== file));
    };

    const handleRemoveFile = (fileId: string) => {
        if (comment) {
            setRemovableFiles([...removableFiles, fileId]);
            setAddableFiles(addableFiles.filter(({ id }) => id !== fileId));
        }

        setFiles(files.filter(({ id }) => id !== fileId));
    };

    const handleFocus = () => {
        setFocus(true);
    };

    const handleBlur = () => {
        setFocus(false);
    };

    const handleSendComment = async () => {
        if (!comment) {
            reset();
        }

        try {
            onSubmit?.();
            if (comment) {
                if (addableFiles.length) {
                    for (const addableFile of addableFiles) {
                        await addFile({
                            ...addableFile,
                            taskId,
                            commentId,
                        });
                    }
                }

                if (removableFiles.length) {
                    for (const fileId of removableFiles) {
                        await removeFile({
                            fileId,
                            taskId,
                            commentId,
                        });
                    }
                }
                reset();
                await editComment({
                    taskId,
                    commentId,
                    text: commentText,
                });
            } else {
                const id = v4();

                await sendComment({
                    id,
                    taskId,
                    chanelId: channelId,
                    text: commentText,
                    files,
                    replyId: search.commentId ? String(search.commentId) : undefined,
                });

                const downloadedFiles: FileResponse[] = await handleDownloadFiles(newFiles, id);

                for (const downloadedFile of downloadedFiles) {
                    await addFile({
                        ...downloadedFile,
                        taskId,
                        commentId: id,
                    });
                }
            }
        } catch (e) {
            setCommentText(commentText);
            setFiles(files);
        }
    };

    const handleCancel = () => {
        reset();
        onCancel?.();
    };

    const handleAddFile: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        handleAddFiles(Array.from(e.target.files));
        e.target.value = '';
    };

    const handlePaste: React.ClipboardEventHandler<HTMLTextAreaElement> = (e) => {
        const { files } = e.clipboardData;
        if (files.length) {
            e.preventDefault();
            pasteHandlerRef.current(Array.from(files));
        }
    };

    return (
        <Flex
            vertical
            justify="flex-end"
            loading={isAddingFile || isCommentSending || (!comment && isAddFileLoading)}
            data-skeleton-content={progress ? `${progress | 0}%` : undefined}
            {...props}
            className={classNames(styles.root, isActive && styles.active, className)}
        >
            {comment && (
                <Flex className={styles.editHeader} gap={8} padding={[9, 20]}>
                    <IconTag aligned ghost icon={IconType.PROJECT_STAGES_EDIT_ICON}>
                        Редактирование сообщения
                    </IconTag>
                    <Space />
                    <IconTag
                        data-qa-id="CommentForm__cancelEdit"
                        icon={IconType.REJECTED_ICON}
                        reverse
                        ghost
                        aligned
                        onClick={handleCancel}
                    >
                        отменить изменения
                    </IconTag>
                </Flex>
            )}
            <Flex padding={[8, 20, 0]} align="flex-start" gap={12}>
                <CommentTextarea
                    value={commentText}
                    taskId={taskId}
                    channelId={channelId}
                    shortLexical
                    onValueChange={setCommentText}
                    onPaste={handlePaste}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
                <IconTag
                    data-qa-id="CommentForm__attachFile"
                    className={styles.attach}
                    loading={isAddFileLoading}
                    element="label"
                    ghost
                    icon={IconType.ATTACHMENT_ICON}
                    clickable
                    relative
                    data-skeleton-content={progress ? `${progress | 0}%` : undefined}
                >
                    <input type="file" multiple onChange={handleAddFile} className={styles.file} />
                </IconTag>
                <IconTag
                    data-qa-id="CommentForm__send"
                    relative
                    disabled={isAddFileLoading || (!commentText && !filesLength)}
                    ghost
                    icon={IconType.SEND_ICON}
                    onClick={handleSendComment}
                />
            </Flex>
            {filesLength ? (
                <Scrollbar maxHeight={180} className={styles.files} align="center" wrap gap={8} padding={[0, 20, 14]}>
                    {getFilesCountText(filesLength)}:
                    {files.map((file) => (
                        <Tag data-qa-id="CommentForm__file" padding={[3, 7, 3, 3]} gap={8} key={file.id}>
                            <FileThumbnail size={24} file={{ ...file, containerName: commentId, parent: 'comment' }} />
                            {file.originName}
                            <TagClose onClick={() => handleRemoveFile(file.id)} />
                        </Tag>
                    ))}
                    {newFiles.map((file, index) => (
                        <Tag
                            data-qa-id="CommentForm__addFile"
                            padding={[3, 7, 3, 3]}
                            gap={8}
                            key={`${file.name}$${index}$`}
                        >
                            <SimpleFileThumbnail size={24} fileType={parseFileName(file.name).type} />
                            {parseFileName(file.name).name}
                            <TagClose onClick={() => handleRemoveNewFile(file)} />
                        </Tag>
                    ))}
                </Scrollbar>
            ) : null}
        </Flex>
    );
}
