import { Success, Failure } from 'typescript-fsa';

import { TaskPageState, RemoveParticipatorPayload, FetchError } from '../types';

export const removeParticipatorStartReducer = (
    { participants, editingParticipants, ...restState }: TaskPageState,
    payload: RemoveParticipatorPayload,
): TaskPageState => {
    return {
        ...restState,
        participants: participants.filter((participant) => participant.userId !== payload.userId),
        editingParticipants: editingParticipants.filter((participant) => participant.userId !== payload.userId),
    };
};

export const removeParticipatorDoneReducer = (
    state: TaskPageState,
    payload: Success<RemoveParticipatorPayload, void>,
): TaskPageState => {
    return state;
};

export const removeParticipatorErrorReducer = (
    { participants, editingParticipants, ...restState }: TaskPageState,
    { params }: Failure<RemoveParticipatorPayload, FetchError>,
): TaskPageState => {
    return {
        ...restState,
        participants: [...participants, params],
        editingParticipants: [...participants, params],
    };
};
