import * as React from 'react';

import * as style from './PlanCorrectionCard.scss';

import { CorrectionSapExportMode } from '@store/budgetCorrections/types';

import { CorrectionStatus, CorrectionType } from '@mrm/budget';

import {
    CorrectionCardHeader,
    CorrectionBody,
    RejectComment,
    CorrectionCardTable,
    CorrectionCardCellContent,
    CorrectionCardCellHeader,
    ExcessReserveWarningList,
    CommonFondsChange,
    SubPramList,
    CorrectionCardComment,
} from '../common';

import type { ExcessReserveWarningProps as Warning } from '../common';

export interface ParticipatorData {
    monthFond: {
        month: string;
        delta: number;
        beforeFond: number;
        afterFond: number;
    };
    allFond: {
        delta: number;
        beforeFond: number;
        afterFond: number;
    };
    fondName: string;
    fields: ParticipatorField[];
    comment: string;
}

export interface ParticipatorField {
    name: string;
    value: string | JSX.Element;
    isWide?: boolean;
    title?: string;
}

interface Props {
    serialNumber: number;
    authorName: string;
    approverName: string;
    userIsBudgetExpert: boolean;
    userIsAuthorCorrection: boolean;
    creationDate: string;
    donorData: ParticipatorData;
    acceptorData: ParticipatorData;
    status: CorrectionStatus;
    url: string;
    rejectComment: string;
    warnings: Warning[];
    sapExportMode: CorrectionSapExportMode;
    onSapExportModeSelection: (value: CorrectionSapExportMode) => void;
    onStatusChange: (status: CorrectionStatus) => void;
}

export const PlanCorrectionCard = ({
    serialNumber,
    authorName,
    approverName,
    userIsBudgetExpert,
    userIsAuthorCorrection,
    creationDate,
    donorData,
    acceptorData,
    status,
    url,
    rejectComment,
    warnings,
    onStatusChange,
}: Props): JSX.Element => {
    const haveWarnings = Boolean(warnings.length);

    return (
        !!donorData &&
        !!acceptorData && (
            <CorrectionBody status={status} serialNumber={serialNumber} type={CorrectionType.PlanFundsTransfer}>
                <CorrectionCardHeader
                    title={'Корректировка плана'}
                    serialNumber={serialNumber}
                    authorName={authorName}
                    approverName={approverName}
                    creationDate={creationDate}
                    url={url}
                    status={status}
                    userIsAuthorCorrection={userIsAuthorCorrection}
                    userIsBudgetExpert={userIsBudgetExpert}
                    onStatusChange={onStatusChange}
                />

                {rejectComment && <RejectComment text={rejectComment} />}

                {haveWarnings && <ExcessReserveWarningList warnings={warnings} />}

                <CorrectionCardTable>
                    <CorrectionCardCellHeader>
                        <ParticipatorSubInfo data={donorData} />
                    </CorrectionCardCellHeader>
                    <CorrectionCardCellHeader>
                        <ParticipatorSubInfo data={acceptorData} />
                    </CorrectionCardCellHeader>

                    <CorrectionCardCellContent>
                        <div className={style.subInfoContent}>
                            <SubPramList supParams={donorData.fields} />
                            {donorData.comment && (
                                <SubPramList
                                    supParams={[
                                        {
                                            name: 'Комментарий к строке',
                                            value: <CorrectionCardComment text={donorData.comment} />,
                                            isWide: true,
                                            notCropContent: true,
                                        },
                                    ]}
                                />
                            )}
                        </div>
                    </CorrectionCardCellContent>
                    <CorrectionCardCellContent>
                        <div className={style.subInfoContent}>
                            <SubPramList supParams={acceptorData.fields} />
                            {acceptorData.comment && (
                                <SubPramList
                                    supParams={[
                                        {
                                            name: 'Комментарий к строке',
                                            value: <CorrectionCardComment text={acceptorData.comment} />,
                                            isWide: true,
                                            notCropContent: true,
                                        },
                                    ]}
                                />
                            )}
                        </div>
                    </CorrectionCardCellContent>
                </CorrectionCardTable>
            </CorrectionBody>
        )
    );
};

interface ParticipatorProps {
    data: ParticipatorData;
}

const ParticipatorSubInfo: React.FC<ParticipatorProps> = ({ data }) => {
    const { monthFond, allFond, fondName } = data;

    return (
        <div className={style.subInfo}>
            <div className={style.subInfoTitle}>{fondName}</div>

            <div className={style.budgets}>
                <div className={style.budget}>
                    <CommonFondsChange
                        title={'Бюджет'}
                        month={monthFond.month}
                        before={monthFond.beforeFond}
                        delta={monthFond.delta}
                    />
                </div>

                <div className={style.budget}>
                    <CommonFondsChange
                        title={'Плановый бюджет по строке'}
                        before={allFond.beforeFond}
                        delta={allFond.delta}
                    />
                </div>
            </div>
        </div>
    );
};

export type { Warning };
