import * as React from 'react';
import classNames from 'classnames';
import { Button_redesign, ButtonTheme_redesign, Preloader } from 'sber-marketing-ui';

import * as styles from './EditParticipantsForm.scss';
import { DepartmentsUsersSelectSuggest } from '@common/DepartmentsUsersSelectSuggest';

export const enum Position {
    OVER = 'Over',
    UNDER = 'Under',
}

const rootStyleMap = {
    [Position.OVER]: styles.over,
    [Position.UNDER]: styles.under,
};

interface Props {
    rootRef: React.RefObject<HTMLDivElement>;
    availableUsers: number[];
    selectedUsers: {
        userId: number;
        canRemove: boolean;
    }[];
    addedParticipantsCount: number;
    removedParticipantsCount: number;
    position: Position;
    isLoading: boolean;
    onSubmitButtonClick(): void;
    onCancelButtonClick(): void;
    onAddParticipants(userIds: number[]): void;
    onRemoveParticipants(userIds: number[]): void;
}

export function EditParticipantsForm({
    rootRef,
    availableUsers,
    selectedUsers,
    addedParticipantsCount,
    removedParticipantsCount,
    position,
    isLoading,
    onSubmitButtonClick,
    onCancelButtonClick,
    onAddParticipants,
    onRemoveParticipants,
}: Props): JSX.Element {
    return (
        <div
            ref={rootRef}
            className={classNames(styles.root, rootStyleMap[position])}
            {...{
                'qa-id': 'editParticipantsForm',
            }}
        >
            {isLoading ? (
                <Preloader />
            ) : (
                <React.Fragment>
                    <DepartmentsUsersSelectSuggest
                        availableUsers={availableUsers}
                        selectedUsers={selectedUsers}
                        addEditingParticipants={onAddParticipants}
                        removeEditingParticipants={onRemoveParticipants}
                    />

                    <div className={styles.buttons}>
                        <div className={classNames(styles.button, styles.button_cancel)}>
                            <Button_redesign
                                qaId="editParticipantsFormCloseButton"
                                onClick={onCancelButtonClick}
                                theme={ButtonTheme_redesign.Ghost}
                            >
                                Отмена
                            </Button_redesign>
                        </div>

                        <div className={classNames(styles.button, styles.button_submit)}>
                            <Button_redesign
                                qaId="editParticipantsFormSaveButton"
                                onClick={onSubmitButtonClick}
                                theme={ButtonTheme_redesign.Main}
                            >
                                <React.Fragment>
                                    Сохранить
                                    {!!addedParticipantsCount && (
                                        <div className={classNames(styles.usersDiff, styles.usersDiff_positive)}>
                                            <div className={styles.usersDiffValue}>{addedParticipantsCount}</div>
                                        </div>
                                    )}
                                    {!!removedParticipantsCount && (
                                        <div className={classNames(styles.usersDiff, styles.usersDiff_negative)}>
                                            <div className={styles.usersDiffValue}>{removedParticipantsCount}</div>
                                        </div>
                                    )}
                                </React.Fragment>
                            </Button_redesign>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
}
