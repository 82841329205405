import { bindActionCreators } from 'redux';
import * as lodash from 'lodash';

import {
    ActivityBudget,
    BudgetItem,
    BudgetItemStatus,
    UpdateActivityBudgetForm,
    UpdateBudgetItemForm,
} from '@mrm/budget';

import { DictionaryType } from '@mrm/dictionary';

import {
    ColumnName,
    UnsavedChange,
    ChangeList,
    LineStatusChanges,
    ACTIVITY_FIELDS_COLUMN_NAMES,
    BUDGET_ITEM_FIELDS_COLUMN_NAMES,
    BUDGET_ITEM_DICTIONARY_COLUMN_NAMES,
    PLANNED_COLUMN_NAMES,
    CURRENCY_COLUMN_NAMES,
    MONTH_BY_COLUMN_NAMES,
} from '@store/budgetPlanning/types';

import { store } from '@store';
import { clearLineStatuses, clearApproverStatuses } from '@store/budgetPlanning';
import {
    getBudgetPlanningPageState,
    getPageData,
    getUnsavedChanges,
    getLineIdsWithActualChanges,
    getBudgetItems,
} from '@store/budgetPlanning/selectors';
import { ColumnsList } from '../ColumnsConfig';
import { ActivityBudgetApi, ApproverApi, BudgetItemApi } from '@api';

interface StoreProps {
    activityBudgets: ActivityBudget[];
    budgetItems: BudgetItem[];
    unsavedChanges: ChangeList;
    lineIdsWithActualChanges: string[];
    lineStatusChanges: LineStatusChanges;
    approverStatusChanges: LineStatusChanges;
}

export class TableSaver {
    private static instance: TableSaver;

    private dispatch = bindActionCreators(
        {
            clearLineStatuses,
            clearApproverStatuses,
        },
        store.dispatch,
    );

    public static getInstance(): TableSaver {
        if (!TableSaver.instance) {
            TableSaver.instance = new TableSaver();
        }
        return TableSaver.instance;
    }

    public async saveTable(): Promise<void> {
        const { lineIdsWithActualChanges } = this.store;

        const changedActivities = lodash.uniqBy(
            lineIdsWithActualChanges.map((lineId) => this.getActivityByLineId(lineId)),
            (activity) => activity.id,
        );

        for (const { id } of changedActivities) {
            await this.saveActivityBudget(id);
        }

        for (const lineId of lineIdsWithActualChanges) {
            await this.saveBudgetItem(lineId);
        }
    }

    public async saveLine(lineId: string): Promise<void> {
        const activity = this.getActivityByLineId(lineId);

        await Promise.all([this.saveActivityBudget(activity.id), this.saveBudgetItem(lineId)]);
    }

    public async publishTable(): Promise<void> {
        const draftLines = this.getLinesByStatus(BudgetItemStatus.Draft);

        await Promise.all(draftLines.map((line) => this.publishLine(line.id)));
    }

    public async publishLine(id: string): Promise<void> {
        await BudgetItemApi.changeBudgetItemStatus({
            id,
            status: BudgetItemStatus.Published,
        });
    }

    public async restoreLine(id: string): Promise<void> {
        await BudgetItemApi.changeBudgetItemStatus({
            id,
            status: BudgetItemStatus.Published,
        });
    }

    public async deleteLine(id: string): Promise<void> {
        await BudgetItemApi.deleteBudgetItem({ id });
    }

    public async disableLine(id: string): Promise<void> {
        await BudgetItemApi.changeBudgetItemStatus({
            id,
            status: BudgetItemStatus.Disabled,
        });
    }

    public async applyTableApprovements(): Promise<void> {
        const { lineStatusChanges } = this.store;

        const lineIds = Object.keys(lineStatusChanges);

        await Promise.all(lineIds.map((id) => this.applyLineStatusChange(id)));

        this.dispatch.clearLineStatuses(lineIds);
    }

    public async applyLineApprovement(id: string): Promise<void> {
        const { lineStatusChanges } = this.store;

        if (lineStatusChanges[id]) {
            await this.applyLineStatusChange(id);
        }

        this.dispatch.clearLineStatuses([id]);
    }

    public async applyApproverStatuses(): Promise<void> {
        const { approverStatusChanges } = this.store;

        const lineIds = Object.keys(approverStatusChanges);

        await Promise.all(lineIds.map((lineId) => this.applyLineApproverStatus(lineId)));

        this.dispatch.clearApproverStatuses(lineIds);
    }

    public async applyApproverStatus(id: string): Promise<void> {
        const { approverStatusChanges } = this.store;

        if (approverStatusChanges[id]) {
            await this.applyLineApproverStatus(id);
        }

        this.dispatch.clearApproverStatuses([id]);
    }

    public async sendLinesToExpertApprovement(): Promise<void> {
        const lines = this.getLinesByStatus(BudgetItemStatus.Published).filter(
            (line) => line.actions.canMoveToExpertApprovement,
        );

        for (const line of lines) {
            await this.sendLineToExpertApprovement(line.id);
        }
    }

    public async sendLineToExpertApprovement(id: string): Promise<void> {
        await BudgetItemApi.changeBudgetItemStatus({
            id,
            status: BudgetItemStatus.OnExpertApprovement,
        });
    }

    private async saveActivityBudget(activityId: string): Promise<void> {
        const changes = this.getActivityBudgetChanges(activityId);

        if (changes.length > 0) {
            const updateParams = this.makeActivityFieldsUpdateParams(changes);

            await ActivityBudgetApi.updateActivityBudget(updateParams);
        }
    }

    private async saveBudgetItem(id: string): Promise<void> {
        const changes = this.getBudgetItemChanges(id);

        if (changes.length) {
            const params = this.makeUpdateBudgetItemParams(changes);

            await BudgetItemApi.updateBudgetItem(params);
        }
    }

    private async applyLineStatusChange(id: string): Promise<void> {
        const { lineStatusChanges, lineIdsWithActualChanges } = this.store;

        const { status, rejectComment } = lineStatusChanges[id];

        const hasChanges = lineIdsWithActualChanges.includes(id);

        if (hasChanges) {
            await this.sendLineToExpertApprovement(id);
        } else {
            await BudgetItemApi.changeBudgetItemStatus({
                id,
                status: status === BudgetItemStatus.Approved ? BudgetItemStatus.Approved : BudgetItemStatus.Rejected,
                comment: rejectComment,
            });
        }
    }

    private async applyLineApproverStatus(id: string): Promise<void> {
        const { approverStatusChanges } = this.store;
        const { status } = approverStatusChanges[id];

        if (status == BudgetItemStatus.Approved) {
            await ApproverApi.approveBudgetItem({
                budgetItemId: id,
            });
        } else if (status == BudgetItemStatus.Rejected) {
            await ApproverApi.rejectBudgetItem({
                budgetItemId: id,
            });
        }
    }

    private makeActivityFieldsUpdateParams(changes: UnsavedChange[]): UpdateActivityBudgetForm {
        const nameChange = changes.find((item) => item.columnName == ColumnName.ActivityName);
        const activity = this.getActivityByLineId(nameChange.budgetItemId);

        const params: UpdateActivityBudgetForm = {
            id: activity.id,
            name: nameChange.value as string,
            // expertId
        };

        return params;
    }

    private makeUpdateBudgetItemParams(changes: UnsavedChange[]): UpdateBudgetItemForm {
        const { budgetItemId } = lodash.first(changes);

        const commentChange = changes.find((item) => item.columnName == ColumnName.Comment);
        const responsibleChange = changes.find((item) => item.columnName == ColumnName.Responsible);
        const startChange = changes.find((item) => item.columnName == ColumnName.StartDate);
        const endChange = changes.find((item) => item.columnName == ColumnName.EndDate);
        const businessTargetChange = changes.find((item) => item.columnName == ColumnName.BusinessGoal);
        const customerNameChange = changes.find((item) => item.columnName == ColumnName.Customer);
        const sapCommentChange = changes.find((item) => item.columnName == ColumnName.SapComment);
        const factPreviousPeriodChange = changes.find((item) => item.columnName === ColumnName.FactPreviousPeriod);

        const dictionariesChanges = changes.filter((item) =>
            BUDGET_ITEM_DICTIONARY_COLUMN_NAMES.includes(item.columnName),
        );

        const planChanged = changes.filter((item) => PLANNED_COLUMN_NAMES.includes(item.columnName));

        const params: UpdateBudgetItemForm = {
            id: budgetItemId,
        };

        if (commentChange) {
            params.comment = commentChange.value as string;
        }

        if (responsibleChange) {
            const packedIds = responsibleChange.value as string;
            const ids = packedIds ? packedIds.split(',').map((id) => parseInt(id, 10)) : null;

            params.responsibleIds = ids;
        }

        if (startChange) {
            params.realizationStart = startChange.value as Date;
        }

        if (endChange) {
            params.realizationEnd = endChange.value as Date;
        }

        if (businessTargetChange) {
            params.businessTarget = businessTargetChange.value as string;
        }

        if (customerNameChange) {
            params.customerName = customerNameChange.value as string;
        }

        if (sapCommentChange) {
            params.sapComment = sapCommentChange.value as string;
        }

        if (dictionariesChanges.length) {
            params.dictionary = dictionariesChanges.reduce((acc, change) => {
                const column = ColumnsList.find((column) => column.name === change.columnName);

                const dictionaryType = column.metaData.dictionaryType;

                return {
                    ...acc,
                    [dictionaryType]: change.value,
                };
            }, {} as { [key in DictionaryType]: string });
        }

        if (planChanged.length) {
            params.plannedFunds = planChanged.reduce(
                (acc, change) => ({
                    ...acc,
                    [MONTH_BY_COLUMN_NAMES[change.columnName]]: +(Number(change.value) * 100.0).toFixed(0),
                }),
                {},
            );
        }

        if (factPreviousPeriodChange) {
            params.factPreviousPeriod = +(Number(factPreviousPeriodChange.value) * 100.0).toFixed(0);
        }

        return params;
    }

    private getActivityByLineId(lineId: string): ActivityBudget {
        const { budgetItems } = this.store;

        const budgetItem = budgetItems.find((budgetItem) => budgetItem.id === lineId);

        return budgetItem.activity;
    }

    private getLinesByStatus(status: BudgetItemStatus): BudgetItem[] {
        const { budgetItems } = this.store;

        return budgetItems.filter((budgetItem) => budgetItem.status === status);
    }

    private getActivityBudgetChanges(activityId: string): UnsavedChange[] {
        const { unsavedChanges, budgetItems } = this.store;

        const budgetItem = budgetItems.find((budgetItem) => budgetItem.activity.id === activityId);

        return (unsavedChanges[budgetItem.id] || []).filter((change) =>
            ACTIVITY_FIELDS_COLUMN_NAMES.includes(change.columnName),
        );
    }

    private getBudgetItemChanges(lineId: string): UnsavedChange[] {
        const { unsavedChanges } = this.store;

        const budgetItemColumns = [
            ...BUDGET_ITEM_FIELDS_COLUMN_NAMES,
            ...BUDGET_ITEM_DICTIONARY_COLUMN_NAMES,
            ...CURRENCY_COLUMN_NAMES,
        ];

        return (unsavedChanges[lineId] || []).filter((change) => budgetItemColumns.includes(change.columnName));
    }

    private get store(): StoreProps {
        const storeState = store.getState();

        const { lineStatusChanges, approverStatusChanges } = getBudgetPlanningPageState(storeState);
        const { activityBudgets } = getPageData(storeState);

        return {
            activityBudgets,
            budgetItems: getBudgetItems(storeState),
            unsavedChanges: getUnsavedChanges(storeState),
            lineIdsWithActualChanges: getLineIdsWithActualChanges(storeState),
            lineStatusChanges,
            approverStatusChanges,
        };
    }
}
