import * as React from 'react';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import * as moment from 'moment';
import { CorrectionStatus } from '@mrm/budget';

import type { UnsavedChange } from '@store/budgetCorrections/types';

import type { ChangedParam } from './ActivityCorrectionCard';
import { ActivityCorrectionCard } from './ActivityCorrectionCard';
import { ColumnName } from '../../../types';
import type { Persone, ActivityCorrection } from '../../../types';

interface Props extends ActivityCorrection {
    budgetId: string;
    userIsBudgetExpert: boolean;
    userIsAuthorCorrection: boolean;
    setUnsavedChange: (unsavedChange: UnsavedChange) => void;
}

export class ActivityCorrectionCardContainer extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        const { serialNumber, creationTime, status, comment, author } = this.props;

        const authorName = this.formatUserName(author);
        const approverName = this.getApproverName();

        return React.createElement(ActivityCorrectionCard, {
            serialNumber,
            authorName,
            approverName,
            activityName: this.getActivityName(),
            changedParams: this.makeChangedParams(),
            userIsBudgetExpert: this.props.userIsBudgetExpert,
            userIsAuthorCorrection: this.props.userIsAuthorCorrection,
            creationDate: this.formatDate(creationTime),
            status,
            rejectComment: comment,
            url: this.makeUrl(),
            onStatusChange: this.onStatusChange,
        });
    }

    @autobind
    protected onStatusChange(status: CorrectionStatus) {
        const { id } = this.props;

        this.props.setUnsavedChange({
            correctionId: id,
            newStatus: status,
        });
    }

    private getApproverName(): string {
        const { status, expert, approver } = this.props;

        if (status === CorrectionStatus.NeedApproving) {
            return expert ? this.formatUserName(expert) : 'не задан';
        }

        return approver ? this.formatUserName(approver) : 'не задан';
    }

    private getActivityName(): string {
        const { activityCurrent } = this.props;
        return lodash.first(activityCurrent).name;
    }

    private makeChangedParams(): ChangedParam[] {
        const { status, activityBefore, activityCurrent, params } = this.props;

        const defineActivityName =
            status === CorrectionStatus.NeedApproving
                ? lodash.first(activityCurrent).name
                : lodash.isEmpty(lodash.first(activityBefore))
                ? lodash.first(activityCurrent).name
                : lodash.first(activityBefore).name;

        return [
            {
                title: 'Активность',
                oldValue: {
                    value: defineActivityName,
                },
                newValue: {
                    value: params.name,
                },
            },
        ];
    }

    private formatUserName(user: Persone): string {
        return `${user.firstName} ${user.secondName}`;
    }

    private formatDate(date: string | Date): string {
        return moment(date).format('DD.MM.YY');
    }

    private makeUrl() {
        const { activityCurrent, budgetId } = this.props;
        const filter = `activityId:${lodash.first(activityCurrent).id}`;
        return `/budget/execution?filters=${filter}&column=${ColumnName.ActivityName}&budgetId=${budgetId}`;
    }
}
