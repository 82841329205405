export class CanvasScaler {
    private canvas: HTMLCanvasElement;
    private scale: number;

    constructor(canvas: HTMLCanvasElement, scale: number) {
        this.scale = scale;
        this.canvas = canvas;
    }

    public getCanvas(): HTMLCanvasElement {
        return this.canvas;
    }

    public get height(): number {
        return this.canvas.height / this.scale;
    }

    public get width(): number {
        return this.canvas.width / this.scale;
    }
}
