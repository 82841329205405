import { combineReducers } from 'redux';

import { TaskEditor } from '../types';
import {
    id,
    title,
    workTypeId,
    departmentId,
    deadline,
    authorId,
    executorId,
    description,
    participantsIds,
    status,
    stageId,
} from './taskData';
import { filesData } from './filesData';
import { editorError, editorStatus, showPreloader } from './technicalData';
import { activityId, activityEndDate, activityStartDate, activityStatus } from './activityData';
import { availableDepartments } from '../availableDepartments';

export const taskEditor = combineReducers<TaskEditor>({
    editorError,
    editorStatus,
    showPreloader,
    id,
    title,
    workTypeId,
    departmentId,
    activityId,
    activityEndDate,
    activityStartDate,
    activityStatus,
    deadline,
    authorId,
    executorId,
    description,
    participantsIds,
    stageId,
    availableDepartments,
    filesData,
    status,
});
