import { Dispatch, AnyAction } from 'redux';
import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { TaskStatus, EditTaskParams } from 'sber-marketing-types/frontend';
import { uniq, isEqual, compact } from 'lodash';

import { TaskApi, FileApi } from '@api';

import { StoreState } from '@store';
import { fillActivityTasks } from '@store/activityTasksPage';
import { getLoginUser } from '@store/user';
import { flushOnDemandUpdates, updateInstanceDescriptor } from '@store/tagsEditor';
import { resetFilesUploadStatus, setFileUploadStatus, FileUploadStatus, errorIsFromAntivirus } from '@store/common';

import { getTaskEditorState } from '../selectors';
import { FileStatus, FileParams } from '../types';

import * as syncActions from '../actions/sync';
import * as asyncActions from '../actions/async';

export const saveTask = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.saveTask,
    async (_, dispatch, getState) => {
        const state = getState();
        const { taskId, activityId, taskIsCreated, tagsEditorId } = getTaskEditorState(state).common;

        dispatch(syncActions.setTaskRequestInProgress(true));

        if (taskIsCreated) {
            await editTask(state);
        } else {
            await createTask(state);
            dispatch(fillActivityTasks(activityId));

            dispatch(
                updateInstanceDescriptor({
                    id: tagsEditorId,
                    payload: { taskId },
                }),
            );
        }

        dispatch(flushOnDemandUpdates(tagsEditorId));

        await updateParticipants(state, !taskIsCreated);
        await processFiles(state, dispatch);

        dispatch(syncActions.setTaskRequestInProgress(false));
        dispatch(syncActions.setTaskRequestHasFinished(true));
    },
);

async function createTask(state: StoreState): Promise<void> {
    const {
        common: { taskId: id, activityId },
        values: { title, description, deadline, workType: workTypeId, executor: executorId, stageId, budgetApproval },
    } = getTaskEditorState(state);

    const hasBudget = budgetApproval
        ? !!budgetApproval.clientDivisionId &&
          !!budgetApproval.clientName &&
          !!(budgetApproval.budgetItemId || budgetApproval.budgetItemWasSelected) &&
          !!budgetApproval.segmentId &&
          !!budgetApproval.productId &&
          !!budgetApproval.period &&
          !!budgetApproval.productId &&
          (budgetApproval.hasLot ? !!budgetApproval.mediaRequest : true) &&
          (budgetApproval.hasTitle ? !!budgetApproval.project && !!budgetApproval.naming : true)
        : true;
    const status = title && executorId && deadline && hasBudget ? TaskStatus.InProgress : TaskStatus.Draft;

    await TaskApi.createTask({
        id,
        title,
        description,
        workTypeId,
        executorId,
        activityId,
        deadline: deadline?.toISOString(),
        status,
        stageId,
        tags: [],
        budgetApproval,
    });
}

async function editTask(state: StoreState): Promise<void> {
    const {
        common: { taskId },
        values: { title, description, deadline, workType: workTypeId, executor: executorId, stageId, budgetApproval },
    } = getTaskEditorState(state);

    const hasBudget = budgetApproval
        ? !!budgetApproval.clientDivisionId &&
          !!budgetApproval.clientName &&
          !!(budgetApproval.budgetItemId || budgetApproval.budgetItemWasSelected) &&
          !!budgetApproval.segmentId &&
          !!budgetApproval.productId &&
          !!budgetApproval.period &&
          !!budgetApproval.productId &&
          (budgetApproval.hasLot ? !!budgetApproval.mediaRequest : true) &&
          (budgetApproval.hasTitle ? !!budgetApproval.project && !!budgetApproval.naming : true)
        : true;
    const status = title && executorId && deadline && hasBudget ? TaskStatus.InProgress : TaskStatus.Draft;

    const taskParams: EditTaskParams = {
        title,
        description,
        workTypeId,
        executorId,
        deadline: deadline?.toISOString(),
        status,
        stageId,
        tags: [],
        budgetApproval,
    };
    if (executorId && workTypeId) {
        taskParams.executionOrder = {
            executorId,
            workTypeId,
        };
    }

    await TaskApi.editTask(taskId, taskParams);
}

async function updateParticipants(state: StoreState, skipParticipantsCheck: boolean): Promise<void> {
    const authorId = getLoginUser(state).attributes.id;
    const {
        common: { taskId },
        values: { executor: executorId, participants },
    } = getTaskEditorState(state);
    const newParticipants = compact(
        uniq([authorId, executorId, ...participants.editing.map((participant) => participant.userId)].sort()),
    );

    const shouldUpdateParticipants = skipParticipantsCheck ? true : !isEqual(newParticipants, participants.existing);

    if (shouldUpdateParticipants) {
        await TaskApi.updateTaskParticipants(taskId, newParticipants);
    }
}

async function processFiles(state: StoreState, dispatch: Dispatch<AnyAction>): Promise<void> {
    const files = getTaskEditorState(state).values.files;

    dispatch(resetFilesUploadStatus());

    const [filesToRemove, filesToUpload] = files.entities.reduce(
        (acc, file) => {
            switch (file.status) {
                case FileStatus.Deleted:
                    return [[...acc[0], file], acc[1]];
                case FileStatus.ReadyToUpload:
                    return [acc[0], [...acc[1], file]];
                default:
                    return acc;
            }
        },
        [[], []] as [FileParams[], FileParams[]],
    );

    await Promise.all(filesToRemove.map(async (file) => FileApi.deleteFile(file.asset.id)));
    await Promise.all(filesToUpload.map(async (file) => uploadFile(file, state, dispatch)));
}

async function uploadFile(fileParams: FileParams, state: StoreState, dispatch: Dispatch<AnyAction>): Promise<void> {
    const file = fileParams.asset;
    const fileId = file.id;

    const loginedUserId = `${getLoginUser(state).attributes.id}`;
    const taskId = getTaskEditorState(state).common.taskId;

    dispatch(
        syncActions.setFileStatus({
            fileId,
            status: FileStatus.Uploading,
        }),
    );

    try {
        await FileApi.uploadFile({ taskId }, file, loginedUserId);
    } catch (e) {
        if (errorIsFromAntivirus(e)) {
            dispatch(
                setFileUploadStatus({
                    fileName: `${file.originName}.${file.type}`,
                    status: FileUploadStatus.VirusFound,
                }),
            );
        }
    }

    dispatch(
        syncActions.setFileStatus({
            fileId,
            status: FileStatus.Uploaded,
        }),
    );
}
