import { buildActivityData } from './buildActivityData';
import { buildTaskData } from './buildTaskData';

import type { BuilderDataByMap, Data } from './types';

export const builderDataByMap: BuilderDataByMap = {
    activities: buildActivityData,
    tasks: buildTaskData,
};

export { Data };
