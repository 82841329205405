import * as React from 'react';
import { useSelector } from 'react-redux';
import { CustomScrollbar_new as CustomScrollbar } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getTransferDesctiptors, cellDescriptor } from '@store/budgetExecution/budgetTransferMenu';

import { useStyleContext } from '../../BudgetTransferMenu';
import { WithRequestInProgressMask, WithRequestInProgressMaskTheme } from '../../WithRequestInProgressMask';

import { TitleRow } from './TitleRow';
import { MonthInput } from './MonthInput';

import * as styles from './CurrentRowSumInputs.scss';

const SCROLLBAR_MAX_HEIGHT = 325; // 5 lines;
const SCROLLBAR_MIN_HEIGHT = 65; // 1 line

export function CurrentRowSumInputs(): JSX.Element {
    const paddingClass = useStyleContext();

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'internalTransitionSumEnteringCurrentRowSumInputs',
            }}
        >
            <div className={paddingClass}>
                <WithRequestInProgressMask theme={WithRequestInProgressMaskTheme.JoinTopBottom}>
                    <TitleRow />
                </WithRequestInProgressMask>
            </div>

            <CustomScrollbar hideScrollX maxHeight={SCROLLBAR_MAX_HEIGHT} minHeight={SCROLLBAR_MIN_HEIGHT}>
                <div className={paddingClass}>
                    <WithRequestInProgressMask theme={WithRequestInProgressMaskTheme.JoinTop}>
                        <MonthsInputs />
                    </WithRequestInProgressMask>
                </div>
            </CustomScrollbar>
        </div>
    );
}

function MonthsInputs(): JSX.Element {
    const transferDescriptors = useSelector((state: StoreState) => getTransferDesctiptors(state));

    return (
        <React.Fragment>
            {transferDescriptors.map((transferDescriptor) => (
                <div
                    key={`${cellDescriptor(transferDescriptor.from)}@${cellDescriptor(transferDescriptor.to)}`}
                    className={styles.monthInput}
                >
                    <MonthInput descriptor={transferDescriptor} />
                </div>
            ))}
        </React.Fragment>
    );
}
