interface Props {
    widthInPx: number;
    startDate: Date;
    endDate: Date;
}

export class Scaler {
    private widthInPx: number;
    private dates: Date[];

    constructor(props: Props) {
        this.dates = [props.startDate, props.endDate];
        this.widthInPx = props.widthInPx;
    }

    public getWidthInPx(): number {
        return this.widthInPx;
    }

    public getDates(): Date[] {
        return this.dates;
    }

    public convertDateToPx(date: Date): number {
        const [startDate, endDate] = this.dates;

        return Math.round(
            ((date.getTime() - startDate.getTime()) / (endDate.getTime() - startDate.getTime())) * this.widthInPx,
        );
    }
}
