import * as React from 'react';

import * as style from './FundsInputCell.scss';

import { Icon, IconType } from 'sber-marketing-ui';

interface Props {
    title: string;
    description?: string;
    customStyle?: any;
    icon?: { type: IconType; color: string; size: number };
}

export const FundsInputCellTemplate = ({ title, description, customStyle, icon }: Props): JSX.Element => {
    function onCopy(event: React.ClipboardEvent<HTMLSpanElement>) {
        const formattedValue = document.getSelection().toString().replace(/ /g, '');

        event.clipboardData.setData('text/plain', formattedValue);

        event.preventDefault();
        event.stopPropagation();
    }

    return (
        <div className={style.root} style={{ ...customStyle }}>
            <div className={style.title}>
                {icon && (
                    <div className={style.titleIcon} style={{ width: icon.size, height: icon.size }}>
                        <Icon type={icon.type} svgSize={icon.size} color={icon.color} />
                    </div>
                )}

                <span className={style.titleCopyContent} onCopy={onCopy}>
                    {title}
                </span>

                <div className={style.currencySign}>₽</div>
            </div>

            {description && <div className={style.description}>{description}</div>}
        </div>
    );
};
