// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-components-TopLineItem-_TopLineItem__topLineItem{color:#262626;font-size:11px}.src-client-modules-activity-pages-task-components-TopLineItem-_TopLineItem__topLineItemRight{text-align:right}.src-client-modules-activity-pages-task-components-TopLineItem-_TopLineItem__topLineItem_title{opacity:0.5;line-height:1.82}.src-client-modules-activity-pages-task-components-TopLineItem-_TopLineItem__topLineItem_value{line-height:1}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/components/TopLineItem/TopLineItem.scss"],"names":[],"mappings":"AAAA,yFACI,aAAc,CAEd,cAAe,CAClB,8FAGG,gBAAiB,CACpB,+FAGG,WAAY,CAEZ,gBAAiB,CACpB,+FAGG,aAAc","sourcesContent":[".topLineItem {\n    color: #262626;\n\n    font-size: 11px;\n}\n\n.topLineItemRight {\n    text-align: right;\n}\n\n.topLineItem_title {\n    opacity: 0.5;\n\n    line-height: 1.82;\n}\n\n.topLineItem_value {\n    line-height: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topLineItem": "src-client-modules-activity-pages-task-components-TopLineItem-_TopLineItem__topLineItem",
	"topLineItemRight": "src-client-modules-activity-pages-task-components-TopLineItem-_TopLineItem__topLineItemRight",
	"topLineItem_title": "src-client-modules-activity-pages-task-components-TopLineItem-_TopLineItem__topLineItem_title",
	"topLineItem_value": "src-client-modules-activity-pages-task-components-TopLineItem-_TopLineItem__topLineItem_value"
};
export default ___CSS_LOADER_EXPORT___;
