import { Dispatch, AnyAction } from 'redux';

import { store } from '@store';
import { NotificationActionType, NotificationType, setNotification } from '@store/common';

import { DatesFormatter } from '@common/Utils';

export class Notifier {
    private static _dispatch: Dispatch<AnyAction>;

    public static notifyAboutVacationSaved(start: Date, end: Date): void {
        this.notify(
            `Отпуск назначен в период с ${DatesFormatter.ddMonthyy(start)} по ${DatesFormatter.ddMonthyy(end)}`,
        );
    }

    public static notifyAboutVacationDeletion(): void {
        this.notify(`Отпуск был отменен`);
    }

    private static notify(comment: string): void {
        this.dispatch(
            setNotification({
                type: NotificationType.SUCCESS,
                typeAction: NotificationActionType.VACATION_EDIT_SUCCESSFULL,
                comment,
            }),
        );
    }

    private static get dispatch(): Dispatch<AnyAction> {
        if (!this._dispatch) {
            this._dispatch = store.dispatch;
        }

        return this._dispatch;
    }
}
