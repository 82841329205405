import * as React from 'react';
import { groupBy } from 'lodash';
import { FilesCache } from '../../hooks';
import { useEffect, useState } from 'react';
import { FileGroupByFeild } from './FileGroupByFeild';
import * as styles from './FileGroupByRow.scss';

interface Props {
    files: FilesCache;
    tableName: string;
}

export const FileGroupByRow = ({ files, tableName }: Props): JSX.Element => {
    const [groupedFilesByRow, setGroupedFilesByRow] = useState<{ [rowName: string]: FilesCache }>({});

    useEffect(() => {
        const groupedFilesByRow = groupBy(files, (file) => file.item.model.number);
        setGroupedFilesByRow(groupedFilesByRow);
    }, [files]);

    return (
        <>
            {Object.entries(groupedFilesByRow).map(([number, files]) => (
                <div key={tableName + number}>
                    <div className={styles.titleContainer}>
                        <div className={styles.title}>Строка №{(tableName === 'Тариф' ? '1.' : '2.') + number}</div>
                        <div className={styles.divider}></div>
                    </div>
                    <FileGroupByFeild files={files} />
                </div>
            ))}
        </>
    );
};
