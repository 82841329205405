// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-Sidebar-widgets-Comments-GroupedComments-CreativeRequestItemComment-_CreativeRequestItemComment__root{position:relative;padding:6px 0 4px 0}.src-client-modules-activity-pages-creative-Sidebar-widgets-Comments-GroupedComments-CreativeRequestItemComment-_CreativeRequestItemComment__rootWithFavoriteMarker::after{content:'';position:absolute;top:0;left:2px;bottom:0;width:4px;background:#4D86CA;border-radius:6px}.src-client-modules-activity-pages-creative-Sidebar-widgets-Comments-GroupedComments-CreativeRequestItemComment-_CreativeRequestItemComment__rootWithActiveColumn{background:#F8F8F8}.src-client-modules-activity-pages-creative-Sidebar-widgets-Comments-GroupedComments-CreativeRequestItemComment-_CreativeRequestItemComment__columnMarker{margin-top:8px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/Sidebar/widgets/Comments/GroupedComments/CreativeRequestItemComment/CreativeRequestItemComment.scss"],"names":[],"mappings":"AAAA,kJACI,iBAAkB,CAElB,mBAAoB,CACvB,2KAGG,UAAW,CACX,iBAAkB,CAClB,KAAM,CACN,QAAS,CACT,QAAS,CAET,SAAU,CAEV,kBAAmB,CACnB,iBAAkB,CACrB,kKAGG,kBAAmB,CACtB,0JAGG,cAAe","sourcesContent":[".root {\n    position: relative;\n\n    padding: 6px 0 4px 0;\n}\n\n.rootWithFavoriteMarker::after {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 2px;\n    bottom: 0;\n\n    width: 4px;\n\n    background: #4D86CA;\n    border-radius: 6px;\n}\n\n.rootWithActiveColumn {\n    background: #F8F8F8;\n}\n\n.columnMarker {\n    margin-top: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-Sidebar-widgets-Comments-GroupedComments-CreativeRequestItemComment-_CreativeRequestItemComment__root",
	"rootWithFavoriteMarker": "src-client-modules-activity-pages-creative-Sidebar-widgets-Comments-GroupedComments-CreativeRequestItemComment-_CreativeRequestItemComment__rootWithFavoriteMarker",
	"rootWithActiveColumn": "src-client-modules-activity-pages-creative-Sidebar-widgets-Comments-GroupedComments-CreativeRequestItemComment-_CreativeRequestItemComment__rootWithActiveColumn",
	"columnMarker": "src-client-modules-activity-pages-creative-Sidebar-widgets-Comments-GroupedComments-CreativeRequestItemComment-_CreativeRequestItemComment__columnMarker"
};
export default ___CSS_LOADER_EXPORT___;
