import * as React from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import {
    ComponentState,
    getBudgetTransferMenuState,
    clearDonorCell,
    setAcceptorCell,
    setComponentState,
} from '@store/budgetExecution/budgetTransferMenu';

import * as styles from './TransitionDirectionToggle.scss';

function useStore() {
    const dispatch = useDispatch();

    const componentState = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.componentState,
    );

    const toggleTransitionDirection = React.useCallback(() => {
        const componentStateToSet =
            componentState === ComponentState.ExternalIncomeTransfer
                ? ComponentState.ExternalOutcomeTransfer
                : ComponentState.ExternalIncomeTransfer;

        dispatch(setComponentState(componentStateToSet));
        dispatch(setAcceptorCell({ lineId: null, columnName: null }));
        dispatch(clearDonorCell());
    }, [componentState]);

    return { componentState, toggleTransitionDirection };
}

export function TransitionDirectionToggle(): JSX.Element {
    const props = useStore();

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'budgetTransferMenuExternalTransitionMenuTransitionDirectionToggle',
            }}
        >
            <Item itemComponentState={ComponentState.ExternalIncomeTransfer} {...props} />
            <Item itemComponentState={ComponentState.ExternalOutcomeTransfer} {...props} />
        </div>
    );
}

interface ItemProps {
    componentState: ComponentState;
    itemComponentState: ComponentState;
    toggleTransitionDirection: () => void;
}

function Item({ componentState, itemComponentState, toggleTransitionDirection }: ItemProps): JSX.Element {
    const isActive = componentState === itemComponentState;
    let content: string;
    switch (itemComponentState) {
        case ComponentState.ExternalIncomeTransfer:
            content = 'Добавить';
            break;
        case ComponentState.ExternalOutcomeTransfer:
            content = 'Забрать';
            break;
    }

    return (
        <div
            className={classnames(styles.item, isActive && styles.itemActive)}
            onClick={isActive ? null : toggleTransitionDirection}
            {...{
                'qa-id': 'budgetTransferMenuExternalTransitionMenuTransitionDirectionToggleButton',
            }}
        >
            {content}
        </div>
    );
}
