import { FieldType, ParamType } from './types';

const readableFieldNameMap: Record<FieldType, string> = {
    block: 'Блок',
    division: 'Дивизион бизнес заказчика',
    segment: 'Сегмент',
    product: 'Продукт',
    channel: 'Канал',
    contract: 'Контракт',
};

const readableParamNameMap: Record<ParamType, string> = {
    mainInfo: 'Основная информация',
    blockParam: 'Параметры',
};

export const getReadableFieldName = (fieldType: FieldType) => {
    return readableFieldNameMap[fieldType] || 'Without name';
};

export const getReadableTypeName = (paramType: ParamType) => {
    return readableParamNameMap[paramType] || 'Without name';
};
