import * as React from 'react';
import * as moment from 'moment';
import classNames from 'classnames';

import { buildChart, getColorClassNames } from './utils';
import { Color, getColorHash, getTimeKeyByPeriod, getDateFormatByPeriod } from '../../../../common';
import { FilterPeriod, StatisticsType } from '@store/leaderDashboardPage';

import { Tooltip } from './Tooltip';

import * as styles from './styles.scss';

interface Props {
    data: {
        id: string;
        name: string;
        mainValue: number;
        additionalValue: number;
    }[];
    period: FilterPeriod;
    date: string;
    color: Color;
    name: string;
    type: StatisticsType;
}

export const Histogram: React.FC<Props> = ({ data, date, period, color, name, type }) => {
    const [opened, open] = React.useState(false);
    const [hoveredId, setHoveredId] = React.useState('string');
    const [coordinate, setCoordinate] = React.useState({ x: 0, y: 0 });

    const id = React.useMemo(() => `${color}-${date.toString()}`, [color]);

    const onMouseEnter = React.useCallback((id: string, event: React.MouseEvent<HTMLDivElement>) => {
        const x =
            event.currentTarget.getBoundingClientRect().left + event.currentTarget.getBoundingClientRect().width / 2;
        const y = event.currentTarget.getBoundingClientRect().top;

        open(true);
        setCoordinate({ x, y });
        setHoveredId(id);
    }, []);

    const onMouseLeave = React.useCallback((id: string) => {
        open(false);
    }, []);

    React.useEffect(() => {
        return buildChart(data, {
            selectorId: id,
            color: getColorHash(color),
            onMouseEnter,
            onMouseLeave,
        });
    }, [data]);

    const tooltipData = data.find((data) => data.id === hoveredId);

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className={styles.title}>{name}</div>
                <div className={styles.explanations}>
                    <div className={styles.explanation}>
                        <div className={classNames(styles.indicator, getColorClassNames(color))} />
                        {`За выбранный период (${moment(date).format(getDateFormatByPeriod(period))})`}
                    </div>
                    <div className={styles.explanation}>
                        <div
                            className={classNames(
                                styles.indicator,
                                getColorClassNames(color),
                                styles.secondaryIndicator,
                            )}
                        />
                        {`За предыдущий период (${moment(date)
                            .subtract(1, getTimeKeyByPeriod(period))
                            .format(getDateFormatByPeriod(period))})`}
                    </div>
                </div>
            </div>

            <div id={id} />

            {opened && tooltipData && (
                <Tooltip
                    key={tooltipData.id}
                    period={period}
                    coordinate={coordinate}
                    date={date}
                    data={tooltipData}
                    type={type}
                />
            )}
        </div>
    );
};
