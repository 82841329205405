import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { UserConfigType } from 'sber-marketing-types/openid';

import { StoreState } from '@store';
import { updateUserConfig } from '@store/userConfig';
import { areEntitiesLoading, searchAll } from '@store/search';

import { CommonUserConfig, LoadCommonUserConfig, UpdateCommonUserConfig } from './types';
import {
    loadCommonUserConfig as loadCommonUserConfigAction,
    updateCommonUserConfig as updateCommonUserConfigAction,
} from './actions';

export const loadCommonUserConfig = bindThunkAction<StoreState, CommonUserConfig, LoadCommonUserConfig, Error>(
    loadCommonUserConfigAction,
    async (receivedUserConfig, dispatch, getState) => {
        const state = getState();
        const { previousSearch } = receivedUserConfig;

        if (!areEntitiesLoading(state) && previousSearch) {
            dispatch(searchAll(previousSearch));
        }

        return {
            receivedUserConfig,
        };
    },
);

export const updateCommonUserConfig = bindThunkAction<StoreState, UpdateCommonUserConfig, void, Error>(
    updateCommonUserConfigAction,
    async (_, dispatch) => {
        dispatch(updateUserConfig(UserConfigType.Common));
    },
);
