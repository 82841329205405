import * as React from 'react';
import { OneTaskResponseParams } from 'sber-marketing-types/backend';
import { WithTooltip, TooltipAnchor, StaticSkeleton } from 'sber-marketing-ui';

import { useTextOverflowCheck } from '@common/hooks';

import * as styles from './CommentChannelSelector.scss';

interface Props {
    taskContent: OneTaskResponseParams;
    onChannelSelect: (channelId: number) => void;
}

export function CommentChannelSelector({ taskContent, onChannelSelect }: Props): JSX.Element {
    const ref = React.useRef<HTMLDivElement>();
    const { horizontal: overflow } = useTextOverflowCheck(ref);

    const showPreloader = !taskContent;

    return showPreloader ? (
        <Preloader />
    ) : (
        <div
            className={styles.root}
            {...{
                'qa-id': 'taskSidebarCommentChannelSelector',
            }}
        >
            <div className={styles.title}>Выберите канал</div>

            <div className={styles.channelsList}>
                {taskContent?.chanels?.map((channel) => (
                    <WithTooltip
                        key={channel.id}
                        hidden={!overflow}
                        content={channel.title}
                        anchor={TooltipAnchor.LEFT}
                    >
                        <div
                            ref={ref}
                            className={styles.channel}
                            onClick={() => onChannelSelect(channel.id)}
                            {...{
                                'qa-id': 'taskSidebarCommentChannelSelectorItem',
                            }}
                        >
                            {channel.title}
                        </div>
                    </WithTooltip>
                ))}
            </div>
        </div>
    );
}

function Preloader(): JSX.Element {
    return (
        <div className={styles.root}>
            <StaticSkeleton loadingClassName={styles.titlePreloader} />

            <div className={styles.channelsList}>
                <StaticSkeleton loadingClassName={styles.channelPreloader} />
                <StaticSkeleton loadingClassName={styles.channelPreloader} />
                <StaticSkeleton loadingClassName={styles.channelPreloader} />
            </div>
        </div>
    );
}
