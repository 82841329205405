// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionLineSelection-LinesDescription-Lines-_Lines__deleteButtonWrapper{position:absolute;top:10px;right:8px;cursor:pointer}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionLineSelection-LinesDescription-Lines-_Lines__deleteButton{display:flex}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/InternalTransitionLineSelection/LinesDescription/Lines/Lines.scss"],"names":[],"mappings":"AAAA,4JACI,iBAAkB,CAElB,QAAS,CACT,SAAU,CAEV,cAAe,CAClB,qJAGG,YAAa","sourcesContent":[".deleteButtonWrapper {\n    position: absolute;\n\n    top: 10px;\n    right: 8px;\n\n    cursor: pointer;\n}\n\n.deleteButton {\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deleteButtonWrapper": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionLineSelection-LinesDescription-Lines-_Lines__deleteButtonWrapper",
	"deleteButton": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionLineSelection-LinesDescription-Lines-_Lines__deleteButton"
};
export default ___CSS_LOADER_EXPORT___;
