// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-Block-Field-FieldTypes-UnlockableInput-_UnlockableInput__root{position:relative;width:100%;padding-top:4px}.src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-Block-Field-FieldTypes-UnlockableInput-_UnlockableInput__onEdit{background-color:rgba(219,219,219,0.2)}.src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-Block-Field-FieldTypes-UnlockableInput-_UnlockableInput__previewMode{width:100%}.src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-Block-Field-FieldTypes-UnlockableInput-_UnlockableInput__icon{position:absolute;top:0;right:0;display:flex;cursor:pointer;font-size:20px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/EditPlannedBudgetPage/BudgetList/BudgetCard/Brief/Block/Field/FieldTypes/UnlockableInput/UnlockableInput.scss"],"names":[],"mappings":"AAAA,2IACI,iBAAkB,CAElB,UAAW,CACX,eAAgB,CACnB,6IAGG,sCAA0C,CAC7C,kJAGG,UAAW,CACd,2IAGG,iBAAkB,CAClB,KAAM,CACN,OAAQ,CAER,YAAa,CAEb,cAAe,CAEf,cAAe","sourcesContent":[".root {\n    position: relative;\n\n    width: 100%;\n    padding-top: 4px;\n}\n\n.onEdit {\n    background-color: rgba(219, 219, 219, 0.2);\n}\n\n.previewMode {\n    width: 100%;\n}\n\n.icon {\n    position: absolute;\n    top: 0;\n    right: 0;\n\n    display: flex;\n\n    cursor: pointer;\n\n    font-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-Block-Field-FieldTypes-UnlockableInput-_UnlockableInput__root",
	"onEdit": "src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-Block-Field-FieldTypes-UnlockableInput-_UnlockableInput__onEdit",
	"previewMode": "src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-Block-Field-FieldTypes-UnlockableInput-_UnlockableInput__previewMode",
	"icon": "src-client-modules-budget-EditPlannedBudgetPage-BudgetList-BudgetCard-Brief-Block-Field-FieldTypes-UnlockableInput-_UnlockableInput__icon"
};
export default ___CSS_LOADER_EXPORT___;
