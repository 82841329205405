import * as React from 'react';
import * as moment from 'moment';
import {
    Icon,
    IconType,
    CloseButton,
    WithGlobalPopup,
    DatepickerContent,
    AbsoluteLikePositionCalculator,
    PopupPositions,
} from 'sber-marketing-ui';
import classNames from 'classnames';

import { DatesFormatter } from '@common/Utils';

import * as styles from './RangeDatepicker.scss';

type Moment = moment.Moment;
interface HighlightDates {
    [className: string]: Date[];
}

export enum DatepickerPosition {
    Right = 'Right',
    Bottom = 'Bottom',
}

interface Props {
    disabled?: boolean;
    start: Date;
    end: Date;
    maxDate?: Date;
    showError?: boolean;
    subContent?: JSX.Element;
    autoSetEnd?: boolean;
    datepickerPosition?: DatepickerPosition;
    onStartChange: (date: Moment) => void;
    onEndChange: (date: Moment) => void;
}

enum ComponentState {
    Unactive = 'Unactive',
    SelectStart = 'SelectStart',
    SelectEnd = 'SelectEnd',
}

function getPopupPositions(datepickerPosition: DatepickerPosition): Partial<PopupPositions> {
    switch (datepickerPosition) {
        case DatepickerPosition.Bottom:
            return { left: 0, bottom: -5 };
        case DatepickerPosition.Right:
        default:
            return { right: -255, top: -50 };
    }
}

function useInteractivity(props: Props) {
    const { onStartChange, onEndChange } = props;

    const rootRef = React.useRef<HTMLDivElement>();

    const [componentState, setComponentState] = React.useState(ComponentState.Unactive);
    const [isMouseOver, setIsMouseOver] = React.useState(false);
    const [hoveredDate, setHoveredDate] = React.useState<Date>(null);

    const minDate = componentState === ComponentState.SelectEnd ? moment(props.start) : null;

    function onClick() {
        setComponentState(
            componentState === ComponentState.Unactive ? ComponentState.SelectStart : ComponentState.Unactive,
        );
    }
    function closeDatepicker() {
        if (!(props.start && props.end)) {
            onStartChange(null);
            onEndChange(null);
        }

        setComponentState(ComponentState.Unactive);
    }
    function deleteInterval() {
        onStartChange(null);
        onEndChange(null);
    }
    function onDatepickerChange(date: Moment) {
        switch (componentState) {
            case ComponentState.SelectStart:
                onStartChange(date);
                if (props.autoSetEnd) {
                    onEndChange(date);
                }
                setComponentState(ComponentState.SelectEnd);
                break;
            case ComponentState.SelectEnd:
                onEndChange(date);
                setComponentState(ComponentState.Unactive);
                break;
            default:
                break;
        }
    }

    return {
        rootRef,
        componentState,
        isMouseOver,
        minDate,
        hoveredDate,
        onClick,
        onDatepickerChange,
        closeDatepicker,
        deleteInterval,
        setIsMouseOver,
        setHoveredDate,
    };
}

export function RangeDatepicker(props: Props): JSX.Element {
    const {
        rootRef,
        componentState,
        isMouseOver,
        minDate,
        hoveredDate,
        onClick,
        onDatepickerChange,
        closeDatepicker,
        deleteInterval,
        setIsMouseOver,
        setHoveredDate,
    } = useInteractivity(props);
    const { start, end, showError, subContent, disabled, maxDate, datepickerPosition } = props;

    const showSubContent = showError || (subContent && componentState !== ComponentState.Unactive);
    const showDatepickerContent = componentState !== ComponentState.Unactive;
    const highlightDates = highlightDatesContent(start, end);

    return (
        <React.Fragment>
            <div
                ref={rootRef}
                className={classNames(
                    styles.root,
                    showDatepickerContent && styles.rootActive,
                    showError && styles.rootError,
                    disabled && styles.rootDisabled,
                )}
                onMouseEnter={disabled ? null : () => setIsMouseOver(true)}
                onMouseLeave={disabled ? null : () => setIsMouseOver(false)}
                onClick={disabled ? null : onClick}
            >
                <div
                    className={classNames(
                        styles.calendarIcon,
                        (showDatepickerContent || isMouseOver) && styles.calendarIconActive,
                        disabled && styles.calendarIconDisabled,
                    )}
                >
                    <Icon type={IconType.CALENDAR_ICON} svgSize={16} />
                </div>

                <Content
                    autoSetEnd={props.autoSetEnd}
                    startDate={start}
                    endDate={end}
                    hoveredDate={hoveredDate}
                    componentState={componentState}
                    disabled={disabled}
                />

                {showDatepickerContent && (
                    <WithGlobalPopup
                        {...getPopupPositions(datepickerPosition)}
                        container={rootRef}
                        positionCalculator={AbsoluteLikePositionCalculator}
                        zIndex={100}
                        maskZIndex={100}
                        onMaskClick={closeDatepicker}
                    >
                        <div
                            onMouseLeave={() => setHoveredDate(null)}
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                            }}
                        >
                            <DatepickerContent
                                maxDate={maxDate ? moment(maxDate) : null}
                                minDate={minDate}
                                highlightDates={highlightDates}
                                onChange={onDatepickerChange}
                                onHover={setHoveredDate}
                            />
                        </div>
                    </WithGlobalPopup>
                )}

                {isMouseOver && start && end && (
                    <div className={styles.deleteIntervalButton}>
                        <CloseButton size={20} onClick={deleteInterval} />
                    </div>
                )}
            </div>

            {showSubContent && <div className={styles.subContent}>{subContent}</div>}
        </React.Fragment>
    );
}

interface ContentProps {
    autoSetEnd: boolean;
    startDate: Date;
    endDate: Date;
    hoveredDate: Date;
    componentState: ComponentState;
    disabled: boolean;
}

function Content({ autoSetEnd, startDate, endDate, hoveredDate, componentState, disabled }: ContentProps): JSX.Element {
    const contentStyle = classNames(styles.content, disabled && styles.contentDisabled);

    if (!(startDate || endDate)) {
        return (
            <div
                className={contentStyle}
                {...{
                    'qa-id': 'stagesEditorPopupStageRangeDatepickerContent',
                }}
            >
                Выберите период
            </div>
        );
    }

    const startFormatted = (
        <span className={styles.contentBlack}>{startDate ? DatesFormatter.ddMonthyy(startDate) : ''}</span>
    );

    let endFormatted: JSX.Element;
    if (componentState === ComponentState.SelectEnd && hoveredDate) {
        endFormatted = <span>{DatesFormatter.ddMonthyy(hoveredDate)}</span>;
    } else {
        const endDateToUse = endDate || (autoSetEnd ? startDate : null);

        if (endDateToUse) {
            endFormatted = <span className={styles.contentBlack}>{DatesFormatter.ddMonthyy(endDateToUse)}</span>;
        }
    }

    return (
        <div
            className={contentStyle}
            {...{
                'qa-id': 'stagesEditorPopupStageRangeDatepickerContent',
            }}
        >
            {startFormatted} - {endFormatted}
        </div>
    );
}

function highlightDatesContent(start: Date, end: Date): HighlightDates[] {
    if (!start || !end) {
        return [];
    }

    if (start && start === end) {
        return [
            {
                [styles.dateRangeBorder]: [start],
            },
        ];
    }

    if (end < start) {
        return [
            {
                [styles.dateRangeBorder]: [start, end],
            },
        ];
    }

    const result: HighlightDates[] = [
        {
            [styles.dateRangeBorder]: [start, end],
        },
        {
            [styles.dateRangeInner]: [],
        },
    ];

    let curr = moment(start).add(1, 'day');
    let currDate = curr.toDate();
    while (currDate < end) {
        result[1][styles.dateRangeInner].push(currDate);

        curr = curr.add(1, 'day');
        currDate = curr.toDate();
    }

    return result;
}
