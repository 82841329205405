import * as React from 'react';
import classNames from 'classnames';
import { round } from 'lodash';

import { Icon, IconType, Preloader } from 'sber-marketing-ui';

import type { Bar } from './types';

import { useMode } from '../../../../hooks';
import type { Mode } from '../../../../hooks';
import { useBehavior } from './hooks';

import * as styles from './styles.scss';

interface Props {
    coordinate: {
        x: number;
        y: number;
    };
    department: {
        id: string;
        name: string;
    };
    bar: Bar;
}

const contentTitleByMode: Record<Mode, string> = {
    activities: 'Проекты',
    tasks: 'Задачи',
};

export const Tooltip: React.FC<Props> = ({ coordinate, department, bar }) => {
    const { rate, items, loading } = useBehavior({
        departmentId: department.id,
        bar,
    });

    const { mode } = useMode();

    return (
        <div className={styles.tooltip} style={{ left: coordinate.x, top: coordinate.y }}>
            <div className={styles.header}>
                <div className={styles.tooltipTitle}>{department.name}</div>
                {!!rate && <Rate value={rate} />}
            </div>

            <div className={styles.tooltipContent}>
                <div className={styles.tooltipContentTitle}>{contentTitleByMode[mode] || ''}</div>

                {items.map(({ name, icon, count }) => (
                    <div className={styles.tooltipContentItem} key={name}>
                        <div className={classNames(styles.iconWrapper, styles.clockIconWrapper)}>
                            <Icon
                                className={styles[icon.color]}
                                type={icon.type}
                                svgSize={icon.size}
                                style={{ marginLeft: icon.marginLeft }}
                            />
                        </div>
                        {name}
                        <div className={styles.tooltipContentItemCount}>{count}</div>
                    </div>
                ))}
            </div>
            {loading && <Preloader />}
        </div>
    );
};

interface RateProps {
    value: number;
}

const Rate: React.FC<RateProps> = ({ value }) => {
    return (
        <div className={styles.rate}>
            <div className={styles.rateIcon}>
                <Icon type={IconType.STAR_ACTIVE} svgSize={12} />
            </div>
            <div className={styles.rateValue}>{round(value, 1)}</div>
        </div>
    );
};
