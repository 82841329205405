import * as React from 'react';

import { Icon, IconType } from 'sber-marketing-ui';
import * as styles from './styles.scss';

interface Props {}

export const Tips: React.FC<Props> = () => {
    return (
        <div className={styles.root}>
            <div className={styles.label}>Выберите подходящие тексты</div>
            <div className={styles.text}>
                Предложенные варианты можно перегенерировать <Icon type={IconType.SPINNER} /> или отредактировать{' '}
                <Icon type={IconType.PROJECT_STAGES_EDIT_ICON} />
            </div>
        </div>
    );
};
