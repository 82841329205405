import * as React from 'react';
import autobind from 'autobind-decorator';

import { FileAsset } from './FileAsset';

interface Props {
    file: File;
    deleteFile?: (file: File) => void;
}

interface State {
    showDeleteButton: boolean;
}

export class FileAssetContainer extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            showDeleteButton: false,
        };
    }

    public render(): JSX.Element {
        const { name } = this.props.file;

        return (
            <FileAsset
                {...this.state}
                name={name}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                onDeleteButtonClick={this.onDeleteButtonClick}
            />
        );
    }

    @autobind
    private onMouseEnter(): void {
        this.setState({
            showDeleteButton: !!this.props.deleteFile,
        });
    }

    @autobind
    private onMouseLeave(): void {
        this.setState({
            showDeleteButton: false,
        });
    }

    @autobind
    private onDeleteButtonClick(): void {
        if (this.props.deleteFile) {
            this.props.deleteFile(this.props.file);
        }
    }
}
