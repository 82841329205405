import { axios, AxiosResponse } from '../lib/axios';

import { OrganizationListQuery, OrganizationView as Organization } from 'sber-marketing-types/backend';

const BASE_PATH = '/api/organization';

export class OrganizationApi {
    public static async getOrganization(params?: OrganizationListQuery): Promise<Organization[]> {
        const res: AxiosResponse<Organization[]> = await axios.get(`${BASE_PATH}/list`, { params });

        return res.data;
    }
}
