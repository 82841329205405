import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { LoadingStatus } from '@store/commonTypes';

import * as actions from './actions/sync';

import { BoundActivity, Comment, LineModalState as State, LoadCommentsParams, ShowLineModalParams } from './types';

class Reducer {
    public static makeInitialState(): State {
        return {
            show: false,
            comments: {
                loadingStatus: LoadingStatus.NOT_LOADED,
                canLoadMore: true,
                items: [],
                offset: 0,
                total: 0,
                pendingComment: null,
            },
            boundActivities: {
                loadingStatus: LoadingStatus.NOT_LOADED,
                items: [],
            },
            activityIdToLink: null,
        };
    }

    public static showLineModal(state: State, payload: ShowLineModalParams): State {
        return {
            ...state,
            ...payload,
            show: true,
        };
    }

    public static hideLineModal(state: State): State {
        return {
            ...state,
            show: false,
            lineId: null,
            activityIdToLink: null,
        };
    }

    public static setCommentsLoadingStatus(state: State, loadingStatus: LoadingStatus): State {
        return {
            ...state,
            comments: {
                ...state.comments,
                loadingStatus,
            },
        };
    }

    public static setBoundActivitiesLoadingStatus(state: State, loadingStatus: LoadingStatus): State {
        return {
            ...state,
            boundActivities: {
                ...state.boundActivities,
                loadingStatus,
            },
        };
    }

    public static loadComments(state: State, payload: LoadCommentsParams): State {
        const { items, total, canLoadMore } = payload;

        return {
            ...state,
            comments: {
                ...state.comments,
                items: [...state.comments.items, ...items],
                total,
                canLoadMore,
                offset: state.comments.offset + items.length,
            },
        };
    }

    public static loadBoundActivities(state: State, payload: BoundActivity[]): State {
        return {
            ...state,
            boundActivities: {
                ...state.boundActivities,
                items: [...payload],
            },
        };
    }

    public static setPendingComment(state: State, pendingComment: Comment): State {
        return {
            ...state,
            comments: {
                ...state.comments,
                pendingComment,
            },
        };
    }

    public static clearLineModal(): State {
        return Reducer.makeInitialState();
    }
}

export const lineModalReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(actions.showLineModal, Reducer.showLineModal)
    .case(actions.hideLineModal, Reducer.hideLineModal)
    .case(actions.setCommentsLoadingStatus, Reducer.setCommentsLoadingStatus)
    .case(actions.setBoundActivitiesLoadingStatus, Reducer.setBoundActivitiesLoadingStatus)
    .case(actions.loadComments, Reducer.loadComments)
    .case(actions.loadBoundActivities, Reducer.loadBoundActivities)
    .case(actions.setPendingComment, Reducer.setPendingComment)
    .case(actions.clearLineModal, Reducer.clearLineModal);
