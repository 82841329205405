import { actionCreatorFactory } from 'typescript-fsa';

import { FilterItems, SetFilterValuePayload, FilterValues, ExportInProgressState } from '../types';

const actionCreator = actionCreatorFactory('BUDGET_CORRECTIONS_FILTERS_AND_EXPORT');

export const setExportInProgressState = actionCreator<ExportInProgressState>('SET_IS_EXPORT_PROGRESS_STATE');

export const setFilterItems = actionCreator<FilterItems>('SET_FILTER_ITEMS');

export const setCurrentFilters = actionCreator<Partial<FilterValues>>('SET_CURRENT_FILTERS');
export const setSavedFilters = actionCreator<Partial<FilterValues>>('SET_SAVED_FILTERS');
export const moveCurrentFiltersToSaved = actionCreator('MOVE_CURRENT_FILTERS_TO_SAVED');
export const moveSavedFiltersToCurrent = actionCreator('MOVE_SAVED_FILTERS_TO_CURRENT');
export const resetFilterValues = actionCreator('RESET_FILTER_VALUES');

export const setFilterValue = actionCreator<SetFilterValuePayload>('SET_FILTER_VALUE');
export const setIsExportModeEnabled = actionCreator<boolean>('SET_IS_EXPORT_MODE_ENABLED');
export const setDateRangeStart = actionCreator<Date>('SET_DATE_RANGE_START');
export const setDateRangeEnd = actionCreator<Date>('SET_DATE_RANGE_END');
export const resetComponentState = actionCreator('RESET_COMPONENT_STATE');
