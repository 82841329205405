import * as React from 'react';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';

import type { StoreState } from '@store/index';
import { isRequestInProgress } from '@store/common/selectors';
import { getPageMode, getIsDisabledUploadPdfButton } from '@store/calendar/selectors';
import { PageMode } from '@store/calendar/types';

import { CalendarUploadButton } from './CalendarUploadButton';
import { WeekDigestPdfMaker, ChartPdfMaker } from './modules';

interface Props extends Partial<MapProps> {}

interface MapProps {
    preloader: boolean;
    disabled: boolean;
    pageMode: PageMode;
}

@(connect(mapStateToProps) as any)
export class CalendarUploadButtonContainer extends React.Component<Props> {
    public render(): JSX.Element {
        const { disabled, preloader } = this.props;

        return React.createElement(CalendarUploadButton, {
            preloader,
            disabled,
            onClick: this.onClick,
        });
    }

    @autobind
    private async onClick() {
        const { pageMode } = this.props;

        switch (pageMode) {
            case PageMode.Chart:
                await new ChartPdfMaker().upload();
                break;
            case PageMode.WeekDigest:
                await new WeekDigestPdfMaker().upload();
                break;
        }
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        preloader: isRequestInProgress(state),
        disabled: getIsDisabledUploadPdfButton(state),
        pageMode: getPageMode(state),
    };
}
