import * as React from 'react';
import { graphql, DataProps } from 'react-apollo';
import autobind from 'autobind-decorator';

import { GET_PROJECT_LINKED_BUDGET_ITEMS } from './query';

interface Props extends ExternalProps, QueryProps {}

interface ExternalProps {
    linkIds: string[];
    children: (props: ChildrenProps) => JSX.Element;
}

export interface ChildrenProps {
    loading: boolean;
    linkRequests: LinkRequest[];
    refetch: () => void;
}

type QueryProps = DataProps<{
    linkRequests: {
        nodes: LinkRequest[];
    };
}>;

export interface LinkRequest {
    id: string;
    status: 'pending' | 'approved';
    author: {
        id: number;
    };
    budgetItem: {
        id: string;
        serialNumber: number;
        budget: {
            id: string;
        };
    };
}

class LinkRequests extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { children } = this.props;

        return children({
            refetch: this.refetch,
            loading: this.getLoadingStatus(),
            linkRequests: this.getLinkRequests(),
        });
    }

    @autobind
    private refetch(): void {
        if (this.props.data) {
            this.props.data.refetch();
        }
    }

    private getLoadingStatus(): boolean {
        return this.props.data ? this.props.data.loading : false;
    }

    private getLinkRequests(): LinkRequest[] {
        const { data } = this.props;

        if (!data || !data.linkRequests) {
            return [];
        }

        return data.linkRequests.nodes;
    }
}

export const WithLinkRequests = graphql<ExternalProps>(GET_PROJECT_LINKED_BUDGET_ITEMS, {
    options: ({ linkIds }) => ({
        variables: {
            linkIds,
        },
    }),
    skip: ({ linkIds }) => !linkIds.length,
})(LinkRequests);
