import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BudgetStatus } from '@mrm/budget';

import { StoreState } from '@store';
import { getBudgetByStatusUserConfig } from '@store/userConfig/budget';
import * as commonStore from '@store/common';
import * as creativeReferenceMenuStore from '@store/budgetExecution/creativeReferenceMenu';
import type { CreativeRequest, BudgetItem } from '@store/budgetExecution/creativeReferenceMenu/types';

interface Props {
    creativeRequestId: string;
    children: (props: ChildrenProps) => JSX.Element;
}

interface ChildrenProps {
    loading: boolean;
    selectedCreativeRequestId: string;
    creativeRequests: CreativeRequest[];
    budgetItems: BudgetItem[];
    setCreativeRequestId: SetCreativeRequestId;
    setNotification: SetNotification;
    save: () => void;
}

interface SetNotification {
    (requestNotification: commonStore.NotificationMessage): void;
}

interface SetCreativeRequestId {
    (creativeRequestId: string): void;
}

interface Init {
    (): void;
}

interface Reset {
    (): void;
}

interface Save {
    (): void;
}

export const WithStore: React.FC<Props> = ({ children, creativeRequestId }) => {
    const dispatch = useDispatch();

    const { budgetId } = useSelector((state: StoreState) => getBudgetByStatusUserConfig(state, BudgetStatus.Execution));

    const loading = useSelector((state: StoreState) => creativeReferenceMenuStore.isLoading(state));

    const selectedCreativeRequestId = useSelector((state: StoreState) =>
        creativeReferenceMenuStore.getSelectedCreativeRequestId(state),
    );

    const creativeRequests = useSelector((state: StoreState) => creativeReferenceMenuStore.getCreativeRequests(state));

    const budgetItems = useSelector((state: StoreState) => creativeReferenceMenuStore.getBudgetItems(state));

    const init: Init = React.useCallback(() => {
        dispatch(creativeReferenceMenuStore.init({ creativeRequestId, budgetId }));
    }, [creativeRequestId, budgetId]);

    const reset: Reset = React.useCallback(() => {
        dispatch(creativeReferenceMenuStore.reset());
    }, []);

    const setNotification: SetNotification = React.useCallback((requestNotification) => {
        dispatch(commonStore.setNotification(requestNotification));
    }, []);

    const setCreativeRequestId: SetCreativeRequestId = React.useCallback((creativeRequestId) => {
        dispatch(creativeReferenceMenuStore.setCreativeRequestId(creativeRequestId));
    }, []);

    const save: Save = React.useCallback(() => {
        dispatch(creativeReferenceMenuStore.save()).then(() => window.close());
    }, []);

    React.useEffect(() => {
        init();

        return () => {
            reset();
        };
    }, []);

    React.useEffect(() => {
        if (budgetId) {
            dispatch(creativeReferenceMenuStore.setBudget({ budgetId }));
        }
    }, [budgetId]);

    return React.createElement(children, {
        loading,
        selectedCreativeRequestId,
        creativeRequests,
        budgetItems,
        setNotification,
        setCreativeRequestId,
        save,
    });
};
