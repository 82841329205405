import * as React from 'react';

import * as style from './BudgetItemCorrectionCard.scss';

import { CorrectionStatus } from '@mrm/budget';

import {
    CorrectionCardHeader,
    RejectComment,
    SumWithTitle,
    CountChanges,
    SubPramList,
    CorrectionCardComment,
    ChangedParamList,
    ChangedParam,
} from '../common';

const STATUS_COLOR = {
    [CorrectionStatus.NeedApproving]: '#f5a623',
    [CorrectionStatus.Approved]: '#19bb4f',
    [CorrectionStatus.Rejected]: '#e63901',
};

export interface ChangedAttribute extends ChangedParam {
    name: string;
}

export interface PlanData {
    name: string;
    oldPlan: number;
    fields: PlanField[];
    comment: string;
}

export interface PlanField {
    name: string;
    value: React.ReactText;
    isWide?: boolean;
}

interface Props {
    serialNumber: number;
    authorName: string;
    approverName: string;
    creationDate: string;
    planData: PlanData;
    changedAttributes: ChangedAttribute[];
    status: CorrectionStatus;
    rejectComment: string;
}

export const BudgetItemCorrectionCard = ({
    serialNumber,
    authorName,
    approverName,
    creationDate,
    planData,
    changedAttributes,
    status,
    rejectComment,
}: Props): JSX.Element => {
    return (
        !!planData && (
            <div className={style.root}>
                <CorrectionCardHeader
                    title={'Корректировка параметров строки'}
                    serialNumber={serialNumber}
                    authorName={authorName}
                    approverName={approverName}
                    creationDate={creationDate}
                    status={status}
                />

                {rejectComment && <RejectComment text={rejectComment} />}

                <div className={style.participators}>
                    <div
                        className={style.participatorsRow}
                        {...{
                            'qa-id': 'correctionCardParticipatorsInfo',
                        }}
                    >
                        <div
                            className={style.participatorsCell}
                            {...{
                                'qa-id': 'correctionCardParticipatorLeftColumn',
                            }}
                        >
                            <PlanSubInfo currentPlan={planData.oldPlan} title={planData.name} />
                        </div>
                        <div
                            className={style.participatorsCell}
                            {...{
                                'qa-id': 'correctionCardParticipatorRightColumn',
                            }}
                        >
                            <CountChangesSubInfo count={changedAttributes.length} />
                        </div>
                    </div>
                    <div
                        className={style.participatorsRow}
                        {...{
                            'qa-id': 'correctionCardCorrectionParams',
                        }}
                    >
                        <div
                            className={style.participatorsCell}
                            {...{
                                'qa-id': 'correctionCardCorrectionParamsLeftColumn',
                            }}
                        >
                            <div className={style.subInfoContent}>
                                <SubPramList supParams={planData.fields} />
                                {planData.comment && (
                                    <SubPramList
                                        supParams={[
                                            {
                                                name: 'Комментарий к строке',
                                                value: <CorrectionCardComment text={planData.comment} />,
                                                isWide: true,
                                                notCropContent: true,
                                            },
                                        ]}
                                    />
                                )}
                            </div>
                        </div>
                        <div
                            className={style.participatorsCell}
                            {...{
                                'qa-id': 'correctionCardCorrectionParamsRightColumn',
                            }}
                        >
                            <div className={style.subInfoContent}>
                                <ChangedParamList params={changedAttributes} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={style.status} style={{ backgroundColor: STATUS_COLOR[status] }} />
            </div>
        )
    );
};

interface PlanSubInfoProps {
    currentPlan: number;
    title: string;
}

const PlanSubInfo: React.FC<PlanSubInfoProps> = (data) => (
    <div className={style.subInfo}>
        <div className={style.subInfoTitle}>{data.title}</div>

        <SumWithTitle sum={data.currentPlan} title={'Плановый бюджет по строке'} />
    </div>
);

const CountChangesSubInfo: React.FC<{ count: number }> = ({ count }) => (
    <div className={style.subInfo}>
        <div className={style.countChangesWrapper}>
            <CountChanges count={count} />
        </div>
    </div>
);
