// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-task-components-selectors-AddTaskTagSelector-_AddTaskTagSelector__root{width:150px}.src-client-modules-task-components-selectors-AddTaskTagSelector-_AddTaskTagSelector__button{background:transparent;border:none;font-family:\"Open Sans\", sans-serif}.src-client-modules-task-components-selectors-AddTaskTagSelector-_AddTaskTagSelector__newTagDetails{margin:0 -8px;padding:0 8px}.src-client-modules-task-components-selectors-AddTaskTagSelector-_AddTaskTagSelector__newTagSummary{padding:0 8px}.src-client-modules-task-components-selectors-AddTaskTagSelector-_AddTaskTagSelector__tagControl{font-size:12px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/task/components/selectors/AddTaskTagSelector/AddTaskTagSelector.scss"],"names":[],"mappings":"AAAA,2FACE,WAAY,CACb,6FAGC,sBAAuB,CACvB,WAAY,CACZ,mCAAoC,CACrC,oGAGC,aAAc,CACd,aAAc,CACf,oGAGC,aAAc,CACf,iGAGC,cAAe","sourcesContent":[".root {\n  width: 150px;\n}\n\n.button {\n  background: transparent;\n  border: none;\n  font-family: \"Open Sans\", sans-serif;\n}\n\n.newTagDetails {\n  margin: 0 -8px;\n  padding: 0 8px;\n}\n\n.newTagSummary {\n  padding: 0 8px;\n}\n\n.tagControl {\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-task-components-selectors-AddTaskTagSelector-_AddTaskTagSelector__root",
	"button": "src-client-modules-task-components-selectors-AddTaskTagSelector-_AddTaskTagSelector__button",
	"newTagDetails": "src-client-modules-task-components-selectors-AddTaskTagSelector-_AddTaskTagSelector__newTagDetails",
	"newTagSummary": "src-client-modules-task-components-selectors-AddTaskTagSelector-_AddTaskTagSelector__newTagSummary",
	"tagControl": "src-client-modules-task-components-selectors-AddTaskTagSelector-_AddTaskTagSelector__tagControl"
};
export default ___CSS_LOADER_EXPORT___;
