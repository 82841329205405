import * as React from 'react';
import * as ExcelJS from 'exceljs';
import * as lodash from 'lodash';
import * as moment from 'moment';

import { Lot1TotalTable } from '../Lot1TotalTable';
import { Lot2TotalTable } from '../Lot2TotalTable';
import { Utils } from '@common/Utils';

interface Props {
    lot: 1 | 2;
    creativeTableRef: React.MutableRefObject<any>;
    totalTableRef: React.MutableRefObject<Lot1TotalTable | Lot2TotalTable>;
}

export const useDownloadXLSXHooks = ({ lot, creativeTableRef, totalTableRef }: Props) => {
    async function onDownloadXLSXButtonClick() {
        const [creativeTableData, totalTableData] = await Promise.all([
            creativeTableRef.current.getTableDataForXLSX(),
            totalTableRef.current.getTableDataForXLSX(),
        ]);

        const workbook = new ExcelJS.Workbook();

        addDataWorksheet(workbook, `Лот ${lot}`, creativeTableData);
        addDataWorksheet(workbook, `Итог по Лоту ${lot}`, totalTableData);

        Utils.downloadAsXLSX((await workbook.xlsx.writeBuffer()) as Buffer, makeXLSXFileName());
    }

    return {
        onDownloadXLSXButtonClick,
    };

    function addDataWorksheet(
        workbook: ExcelJS.Workbook,
        sheetName: string,
        tableData: {
            columnsWidths: number[];
            headers: string[];
            rows: React.ReactText[][];
        },
    ) {
        const sheet = workbook.addWorksheet(sheetName);

        sheet.columns = tableData.headers.map((item, index) => ({
            header: item,
            key: item,
            width: Math.round(tableData.columnsWidths[index] / 6),
        }));

        const headersRow = sheet.getRow(1);

        headersRow.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };

        tableData.rows.forEach((rowValues, rowIndex) => {
            sheet.addRow({
                id: rowIndex,
                value: rowIndex,
            });

            const rowIsSubLine = lodash.first(rowValues).toString().includes('.');

            const row = sheet.getRow(rowIndex + 2);

            rowValues.forEach((value, cellIndex) => {
                const cell = row.getCell(tableData.headers[cellIndex]);

                if (typeof value === 'number') {
                    cell.numFmt = '0.00';
                }

                if (value !== undefined) {
                    cell.value = value;
                    cell.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
                }

                if (rowIsSubLine) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'DCE6F1' },
                    };
                }
            });
        });
    }

    function makeXLSXFileName(): string {
        const date = moment().format('_DD MMM YY_hh.mm');

        return `Сводная таблица ЗНК_Лот${lot}${date}`;
    }
};
