import * as React from 'react';

import * as style from './FactCorrectionCard.scss';

import type { FondField } from '../common';
import {
    FondsByMonthTable,
    CorrectionCardHeader,
    RejectComment,
    SubPramList,
    CorrectionCardComment,
    CommonFondsChange,
    SumWithTitle,
} from '../common';
import { CorrectionStatus } from '@mrm/budget';

export type { FondField };

const STATUS_COLOR = {
    [CorrectionStatus.NeedApproving]: '#f5a623',
    [CorrectionStatus.Approved]: '#19bb4f',
    [CorrectionStatus.Rejected]: '#e63901',
};

export interface PlanData {
    name: string;
    currentPlan: number;
    fields: PlanField[];
    comment: string;
}

export interface PlanField {
    name: string;
    value: React.ReactText;
    isWide?: boolean;
}

export interface FactData {
    beforeSumFonds: number;
    delta: number;
    fields: FondField[];
}

interface Props {
    serialNumber: number;
    authorName: string;
    approverName: string;
    creationDate: string;
    planData: PlanData;
    factData: FactData;
    status: CorrectionStatus;
    rejectComment: string;
}

export const FactCorrectionCard = ({
    serialNumber,
    authorName,
    approverName,
    creationDate,
    planData,
    factData,
    status,
    rejectComment,
}: Props): JSX.Element => {
    return (
        !!planData &&
        !!factData && (
            <div className={style.root}>
                <CorrectionCardHeader
                    title={'Корректировка факта'}
                    serialNumber={serialNumber}
                    authorName={authorName}
                    approverName={approverName}
                    creationDate={creationDate}
                    status={status}
                />

                {rejectComment && <RejectComment text={rejectComment} />}

                <div className={style.participators}>
                    <div className={style.participatorsRow}>
                        <div className={style.participatorsCell}>
                            <PlanSubInfo
                                fields={planData.fields}
                                name={planData.name}
                                currentPlan={planData.currentPlan}
                                comment={planData.comment}
                            />
                        </div>
                        <div className={style.participatorsCell}>
                            <FactSubInfo
                                fields={factData.fields}
                                delta={factData.delta}
                                beforeSumFonds={factData.beforeSumFonds}
                            />
                        </div>
                    </div>
                    <div className={style.participatorsRow}>
                        <div className={style.participatorsCell}>
                            <div className={style.subInfoContent}>
                                <SubPramList supParams={planData.fields} />
                                {planData.comment && (
                                    <SubPramList
                                        supParams={[
                                            {
                                                name: 'Комментарий к строке',
                                                value: <CorrectionCardComment text={planData.comment} />,
                                                isWide: true,
                                                notCropContent: true,
                                            },
                                        ]}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={style.participatorsCell}>
                            <div className={style.subInfoContent}>
                                <FondsByMonthTable fields={factData.fields} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={style.status} style={{ backgroundColor: STATUS_COLOR[status] }} />
            </div>
        )
    );
};

const PlanSubInfo: React.FC<PlanData> = (data) => (
    <div className={style.subInfo}>
        <div className={style.subInfoTitle}>{data.name}</div>

        <SumWithTitle sum={data.currentPlan} title={'Плановый бюджет по строке'} />
    </div>
);

const FactSubInfo: React.FC<FactData> = (data) => (
    <div className={style.subInfo}>
        <div className={style.commonFondsChangeWrapper}>
            <CommonFondsChange title={'Факт по строке'} before={data.beforeSumFonds} delta={data.delta} />
        </div>
    </div>
);
