import { axios } from '../lib/axios';
import { ProductParams, QueryProductParams } from 'sber-marketing-types/frontend';

export class ProductApi {
    public static async getProducts(queryParams?: QueryProductParams): Promise<ProductParams[]> {
        const res = await axios.post('/api/product', queryParams);

        return res.data;
    }
}
