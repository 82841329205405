import createCachedSelector from 're-reselect';
import {} from 'reselect';

import { StoreState } from '@store';

export const getR7State = (state: StoreState) => state.R7;

export const getDocumentStatus = createCachedSelector(
    getR7State,
    (state: StoreState, documentId: string) => documentId,
    (state, documentId) => state.documentsStatusById[documentId],
)((state: StoreState, documentId: string) => documentId);

export const documentWasEditedInR7 = createCachedSelector(
    getDocumentStatus,
    (state: StoreState, documentId: string) => documentId,
    (status) => status?.currentVersion > 1,
)((state: StoreState, documentId: string) => documentId);
