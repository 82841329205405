import * as React from 'react';
import * as moment from 'moment';
import * as lodash from 'lodash';

import {
    OutcomeExternalPlanFundsTransferCorrection,
    CorrectionStatus,
    BudgetItem,
    Month,
    Persone,
    Dictionary,
} from '../types';

import {
    OutcomeExternalCorrectionCard,
    ParticipatorData,
    ParticipatorField,
    CorrectionData,
} from './OutcomeExternalCorrectionCard';

import { monthIndexMap } from '../utils';
import { Utils } from '@common/Utils';

interface Props extends OutcomeExternalPlanFundsTransferCorrection {
    dictionaries: Dictionary[];
}

export class OutcomeExternalCorrectionCardContainer extends React.Component<Props> {
    public render(): JSX.Element {
        const { serialNumber, creationTime, author, status, comment } = this.props;

        const authorName = this.formatUserName(author);
        const approverName = this.getApproverName();

        return React.createElement(OutcomeExternalCorrectionCard, {
            serialNumber,
            authorName,
            approverName,
            creationDate: this.formatDate(creationTime),
            correctionData: this.makeCorrectionData(),
            donorData: this.makeParticipatorData(),
            status,
            rejectComment: comment,
        });
    }

    private getApproverName(): string {
        const { status, expert, approver } = this.props;

        if (status === CorrectionStatus.NeedApproving) {
            return expert ? this.formatUserName(expert) : 'не задан';
        }

        return approver ? this.formatUserName(approver) : 'не задан';
    }

    private makeCorrectionData(): CorrectionData {
        const { params, dictionaries } = this.props;

        const dictionaryItem =
            params.dictionary && params.dictionary.item && params.dictionary.item.id
                ? dictionaries.find(({ id }) => id === params.dictionary.item.id)
                : null;

        return {
            dictionaryItem: (dictionaryItem && dictionaryItem.value) || null,
            comment: params.comment,
        };
    }

    private makeParticipatorData(): ParticipatorData {
        const {
            budgetItemCurrent: budgetItemsCurrent,
            budgetItemBefore: budgetItemsBefore,
            status,
            params,
        } = this.props;

        const currentBudgetItem = budgetItemsCurrent.find(({ id }) => id === params.donorId);
        const budgetItemBefore = lodash.isEmpty(budgetItemsBefore)
            ? null
            : budgetItemsBefore.find(({ id }) => id === params.donorId);

        const definedBudgetItem =
            status === CorrectionStatus.NeedApproving
                ? currentBudgetItem
                : lodash.isEmpty(budgetItemBefore)
                ? currentBudgetItem
                : budgetItemBefore;

        const delta = -params.value;

        const month = params.donorMonth;
        const beforeFond = definedBudgetItem.plannedFunds[month];

        // TODO В ответе graphQL висит поле __typename и для перебора свойст обхекта оно мешает
        delete definedBudgetItem.plannedFunds['__typename'];

        const allBeforeFond = lodash
            .values(definedBudgetItem.plannedFunds)
            .reduce<number>((acc, monthFond) => acc + monthFond, 0);

        return {
            monthFond: {
                month: this.getMonthName(month),
                delta,
                beforeFond,
                afterFond: beforeFond + delta,
            },
            allFond: {
                delta,
                beforeFond: allBeforeFond,
            },
            name: currentBudgetItem.sapComment,
            fields: this.buildParticipatorFields(currentBudgetItem),
            comment: currentBudgetItem.comment,
        };
    }

    private buildParticipatorFields(budgetItem: BudgetItem): ParticipatorField[] {
        return [
            {
                name: 'ID план',
                value: budgetItem.donors && budgetItem.donors.join(', '),
            },
            {
                name: 'ID исполнение',
                value: budgetItem.serialNumber,
            },
            {
                name: 'Тип проекта',
                value:
                    budgetItem.dictionary &&
                    budgetItem.dictionary.activity_type &&
                    budgetItem.dictionary.activity_type.value,
            },
            {
                name: 'Направление',
                value:
                    budgetItem.dictionary && budgetItem.dictionary.direction && budgetItem.dictionary.direction.value,
            },
            {
                name: 'Дата запуска',
                value: budgetItem.realizationStart && this.formatDate(budgetItem.realizationStart),
            },
            {
                name: 'Дата окончания',
                value: budgetItem.realizationEnd && this.formatDate(budgetItem.realizationEnd),
            },
            {
                name: 'ЦА/ТБ (Территория)',
                value: budgetItem.dictionary?.regionality
                    ? Utils.getDictionaryValue(budgetItem.dictionary.regionality)
                    : '',
            },
            {
                name: 'Территория',
                value: budgetItem.dictionary?.territory
                    ? Utils.getDictionaryValue(budgetItem.dictionary.territory)
                    : '',
            },
            {
                name: 'Статья',
                value: budgetItem.dictionary && budgetItem.dictionary.item && budgetItem.dictionary.item.value,
            },
            {
                name: 'Блок',
                value: budgetItem.dictionary && budgetItem.dictionary.block && budgetItem.dictionary.block.value,
                isWide: true,
            },
            {
                name: 'Инструмент',
                value: budgetItem.dictionary && budgetItem.dictionary.tool && budgetItem.dictionary.tool.value,
                isWide: true,
            },
        ];
    }

    private formatUserName(user: Persone): string {
        return `${user.firstName} ${user.secondName}`;
    }

    private formatDate(date: string | Date): string {
        return moment(date).format('DD.MM.YYYY');
    }

    private getMonthName(month: Month): string {
        moment.locale('ru');
        const monthName = moment.months()[monthIndexMap[month]];

        return lodash.capitalize(monthName);
    }
}
