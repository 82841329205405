import * as React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import * as style from './MediaplanStageActions.scss';

import { AutopilotStage } from '@store/autopilotDOOH/types';

import { setCurrentStage } from '@store/autopilotDOOH/actions';

interface Props {
    canEdit: boolean;
}

export const MediaplanStageActions = ({ canEdit }: Props): JSX.Element => {
    const dispatch = useDispatch();

    const onBackButtonClick = React.useCallback(() => {
        dispatch(setCurrentStage(AutopilotStage.MediaplanSettings));
    }, []);

    const onApproveButtonClick = React.useCallback(() => {
        dispatch(setCurrentStage(AutopilotStage.Budget));
    }, []);

    return (
        <div className={style.root}>
            <div className={classNames(style.button, style.backButton)} onClick={onBackButtonClick}>
                Вернуться к параметрам
            </div>

            <div className={style.title}>Утвердите итоговый медиаплан</div>

            <div className={classNames(style.button, style.approveButton)} onClick={onApproveButtonClick}>
                Утвердить
            </div>
        </div>
    );
};
