// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-DashboardSortingMenu-_DashboardSortingMenu__root{width:100%}.src-client-modules-common-DashboardSortingMenu-_DashboardSortingMenu__wrapper{display:flex;width:100%;flex-wrap:wrap}.src-client-modules-common-DashboardSortingMenu-_DashboardSortingMenu__sorting{flex:none;margin:0 2px 16px 0;cursor:pointer}.src-client-modules-common-DashboardSortingMenu-_DashboardSortingMenu__title{padding:0 8px;user-select:none;text-transform:uppercase;color:#404040;border-radius:7px;background-color:transparent;font-family:'Open Sans';font-size:10px;line-height:14px}.src-client-modules-common-DashboardSortingMenu-_DashboardSortingMenu__selected{cursor:default;font-weight:600}.src-client-modules-common-DashboardSortingMenu-_DashboardSortingMenu__selected .src-client-modules-common-DashboardSortingMenu-_DashboardSortingMenu__title{color:#ffffff;background-color:#19bb4f}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/DashboardSortingMenu/DashboardSortingMenu.scss"],"names":[],"mappings":"AAAA,4EACI,UAAW,CACd,+EAGG,YAAa,CAEb,UAAW,CAEX,cAAe,CAClB,+EAGG,SAAU,CAEV,mBAAoB,CAEpB,cAAe,CAClB,6EAGG,aAAc,CAEd,gBAAiB,CACjB,wBAAyB,CAEzB,aAAc,CACd,iBAAkB,CAClB,4BAA6B,CAE7B,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACpB,gFAGG,cAAe,CAEf,eAAgB,CAHpB,6JAKQ,aAAc,CACd,wBAAyB","sourcesContent":[".root {\n    width: 100%;\n}\n\n.wrapper {\n    display: flex;\n\n    width: 100%;\n\n    flex-wrap: wrap;\n}\n\n.sorting {\n    flex: none;\n\n    margin: 0 2px 16px 0;\n\n    cursor: pointer;\n}\n\n.title {\n    padding: 0 8px;\n\n    user-select: none;\n    text-transform: uppercase;\n\n    color: #404040;\n    border-radius: 7px;\n    background-color: transparent;\n\n    font-family: 'Open Sans';\n    font-size: 10px;\n    line-height: 14px;\n}\n\n.selected {\n    cursor: default;\n\n    font-weight: 600;\n    .title {\n        color: #ffffff;\n        background-color: #19bb4f;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-DashboardSortingMenu-_DashboardSortingMenu__root",
	"wrapper": "src-client-modules-common-DashboardSortingMenu-_DashboardSortingMenu__wrapper",
	"sorting": "src-client-modules-common-DashboardSortingMenu-_DashboardSortingMenu__sorting",
	"title": "src-client-modules-common-DashboardSortingMenu-_DashboardSortingMenu__title",
	"selected": "src-client-modules-common-DashboardSortingMenu-_DashboardSortingMenu__selected"
};
export default ___CSS_LOADER_EXPORT___;
