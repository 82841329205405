/* tslint:disable:max-file-line-count */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import {
    ColumnsVisiblityFilter,
    TableLine,
    TableLineGroup,
    Filters,
    SortingMode,
    ColumnsWidth,
    ColumnName,
    getBudgetExecutionPageState,
    getTableFilters,
    getFilteredTableLines,
    getLinesGroupedByActivities,
} from '@store/budgetExecution';
import {
    CellPosition,
    ComponentState as BudgetTransferMenuComponentState,
    getBudgetTransferMenuState,
    setHoveredCell,
    updateIsHoveredLineClickable,
} from '@store/budgetExecution/budgetTransferMenu';
import { TooltipDirection } from './Tooltip';

import type { ScrollbarComponent } from 'sber-marketing-ui';
import { Table } from './Table';
import type { TooltipPosition, DropdownPosition } from './Table';
import type { StoreState } from '@store';
import {
    setHoveredColumnName,
    setFilters,
    undoUnsavedChanges,
    redoUnsavedChanges,
    setHoveredLineId,
} from '@store/budgetExecution/actions';
import { ColumnsList } from '../ColumnsConfig';
import { FrameManager, PlainFrameManager, GroupedFrameManager } from './FrameManager';

import { setHoveredLineId as setTransferBudgetItemsToPlanningHoveredLineId } from '@store/budgetExecution/transferBudgetItemsToPlanningMenu';

const TABLE_HEADER_HEIGHT = 28;
const LINE_HEIGHT = 38;
const TOTAL_LINE_MARGIN = 10;
const LINE_MENU_WIDTH = 184;
const BORDER_WIDTH = 1;
const FIXED_CELL_WIDTH = 104;
const SCROLL_TIMEOUT = 200;

const Z_KEY_CODE = 90;
const Y_KEY_CODE = 89;

interface Props extends Partial<MapProps>, Partial<DispatchProps> {
    maxHeight: number;
    pageContentHeight: number;
    tableOffsetTop: number;
    initColumnScroll: ColumnName;
    onApplyFiltersButtonClick: () => void;
}

interface MapProps {
    showNoLinesStub: boolean;
    allLines: TableLine[];
    lines: TableLine[];
    lineGroups: TableLineGroup[];
    fixedColumnsNames: ColumnName[];
    columnsWidth: ColumnsWidth;
    columnsVisiblityFilter: ColumnsVisiblityFilter;
    resizingColumnName: ColumnName;
    hoveredColumnName: ColumnName;
    sortingMode: SortingMode;
    useLinesWithoutPlanInSorting: boolean;
    budgetTransferMenuisInLineSelectionState: boolean;
}

interface DispatchProps {
    setHoveredColumnName: (columnName: ColumnName) => void;
    setFilters: (filterMode: Filters) => void;
    undoUnsavedChanges: () => void;
    redoUnsavedChanges: () => void;
    setHoveredCell: (cell: CellPosition) => void;
    updateIsHoveredLineClickable: (lineId: string) => void;
    setHoveredLineId: (lineId: string) => void;
    setTransferBudgetItemsToPlanningHoveredLineId: (lineId: string) => void;
}

interface State {
    currentFrameIndex: number;
    currentYScroll: number;
    visibleColumnsNames: ColumnName[];
    hoveredInfoLineId: string;
    rootWidth: number;
    currentDropdownContent: JSX.Element;
    dropdownContentX: number;
    tooltipPosition: TooltipPosition;
    dropdownCellContent: JSX.Element;
    dropdownCellPosition: DropdownPosition;
}

@(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true }) as any)
export class TableContainer extends React.Component<Props, State> {
    private root: HTMLDivElement;
    private tableHeader: ScrollbarComponent;
    private tableBody: ScrollbarComponent;
    private sumLine: ScrollbarComponent;
    private headerFixedColumns: HTMLDivElement;
    private fixedColumnsLines: HTMLDivElement[] = [];
    private totalLinesContent: HTMLDivElement[] = [];
    private statusCellsContent: HTMLDivElement[] = [];
    private scrollTop = 0;
    private scrollLeft = 0;
    private dropdownsContentWrapper: HTMLDivElement;
    private dropdownCellContentWrapper: HTMLDivElement;
    private scrollTimer: NodeJS.Timeout;
    private tableIsScrolling: boolean;
    private frameManager: FrameManager;

    constructor(props: Props) {
        super(props);

        this.tableIsScrolling = false;

        this.state = {
            currentFrameIndex: null,
            currentYScroll: null,
            visibleColumnsNames: [],
            hoveredInfoLineId: null,
            rootWidth: null,
            currentDropdownContent: null,
            dropdownContentX: 0,
            tooltipPosition: null,
            dropdownCellContent: null,
            dropdownCellPosition: null,
        };
    }

    public componentDidMount(): void {
        const rootWidth = this.getRootWidth();

        this.initScrollLeftPosition();

        this.setState(() => ({
            rootWidth,
        }));
        this.updateStatusCellsPosition(rootWidth);

        window.addEventListener('resize', this.onPageResize);
        window.addEventListener('keydown', this.onKeydown);
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.onPageResize);
        window.removeEventListener('keydown', this.onKeydown);
    }

    /*tslint:disable:cyclomatic-complexity*/
    public componentDidUpdate(prevProps: Props, prevState: State): void {
        const rootWidthChanged = prevState.rootWidth !== this.state.rootWidth;

        if (rootWidthChanged) {
            this.updateVisibleColumns();
        }

        const sortingModeChanged = this.props.sortingMode !== prevProps.sortingMode;

        const linesChanged = this.checkLinesChanges(this.props.allLines, prevProps.allLines);
        const lineGroupsChanged = this.checkLineGroupsChanges(this.props.lineGroups, prevProps.lineGroups);
        const useLinesWithoutPlanInSortingChanged =
            this.props.useLinesWithoutPlanInSorting !== prevProps.useLinesWithoutPlanInSorting;

        const maxHeightChanged = this.props.maxHeight !== prevProps.maxHeight;
        const maxHeightIsPositive = this.props.maxHeight > 0;

        const needUpdateFrameManager =
            (linesChanged ||
                lineGroupsChanged ||
                useLinesWithoutPlanInSortingChanged ||
                sortingModeChanged ||
                maxHeightChanged) &&
            maxHeightIsPositive;
        if (needUpdateFrameManager) {
            this.updateFrameManager();
        }

        const columnsFilterChanged = prevProps.columnsVisiblityFilter !== this.props.columnsVisiblityFilter;

        if (columnsFilterChanged) {
            this.updateVisibleColumns();
            this.updateFixedColumnsPosition();
            this.updateTableScroll();
        }

        const columnsWidthChanged = prevProps.columnsWidth !== this.props.columnsWidth;

        if (columnsWidthChanged) {
            this.updateTableScroll();
        }
    }

    public render(): JSX.Element {
        return React.createElement(Table, {
            showNoLinesStub: this.props.showNoLinesStub,
            frameManager: this.frameManager,
            currentFrameIndex: this.state.currentFrameIndex,
            currentYScroll: this.state.currentYScroll,
            columnsWidth: this.props.columnsWidth,
            visibleColumnsNames: this.state.visibleColumnsNames,
            hoveredColumnName: this.props.hoveredColumnName,
            hoveredTotalLineActivityId: this.state.hoveredInfoLineId,
            currentDropdownContent: this.state.currentDropdownContent,
            dropdownContentX: this.state.dropdownContentX,
            tooltipPosition: this.state.tooltipPosition,
            rootWidth: this.state.rootWidth,
            maxBodyHeight: this.props.maxHeight - TABLE_HEADER_HEIGHT,
            dropdownsContentWrapper: this.dropdownsContentWrapper,
            dropdownCellContentWrapper: this.dropdownCellContentWrapper,
            dropdownCellContent: this.state.dropdownCellContent,
            dropdownCellPosition: this.state.dropdownCellPosition,
            tableScrollbarRef: this.tableBody,
            rootRef: this.rootRef,
            headerRef: this.headerRef,
            bodyRef: this.bodyRef,
            sumLineRef: this.sumLineRef,
            headerFixedColumnsRef: this.headerFixedColumnsRef,
            fixedColumnsLinesRef: this.fixedColumnsLinesRef,
            totalLinesContentRef: this.totalLinesContentRef,
            dropdownsContentRef: this.dropdownsContentRef,
            dropdownCellContentRef: this.dropdownCellContentRef,
            onBodyScroll: this.onBodyScroll,
            onHeaderCellMouseEnter: this.onHeaderCellMouseEnter,
            onHeaderCellMouseLeave: this.onHeaderCellMouseLeave,
            onLineMouseEnter: this.onLineMouseEnter,
            onLineMouseLeave: this.onLineMouseLeave,
            onHeaderDropdownClick: this.onHeaderDropdownClick,
            onInfoMouseEnter: this.onInfoMouseEnter,
            onInfoMouseLeave: this.onInfoMouseLeave,
            onDropdownCellClick: this.onDropdownCellClick,
            onApplyFiltersButtonClick: this.props.onApplyFiltersButtonClick,
        });
    }

    public resetScroll(): void {
        this.tableHeader?.scrollTo(0, 0);
        this.tableBody?.scrollTo(0, 0);
    }

    @autobind
    protected rootRef(element: HTMLDivElement) {
        this.root = element;
    }

    @autobind
    protected headerRef(component: ScrollbarComponent) {
        this.tableHeader = component;
    }

    @autobind
    protected bodyRef(component: ScrollbarComponent) {
        this.tableBody = component;
    }

    @autobind
    protected sumLineRef(component: ScrollbarComponent) {
        this.sumLine = component;
    }

    @autobind
    protected headerFixedColumnsRef(element: HTMLDivElement) {
        this.headerFixedColumns = element;
    }

    @autobind
    protected fixedColumnsLinesRef(element: HTMLDivElement) {
        if (!this.fixedColumnsLines.some((line) => line === element)) {
            this.fixedColumnsLines.push(element);

            this.updateFixedElementPosition(element);
        }
    }

    @autobind
    protected totalLinesContentRef(element: HTMLDivElement) {
        if (!this.totalLinesContent.some((line) => line === element)) {
            this.totalLinesContent.push(element);
        }
    }

    @autobind
    protected dropdownsContentRef(element: HTMLDivElement) {
        this.dropdownsContentWrapper = element;
    }

    @autobind
    protected dropdownCellContentRef(element: HTMLDivElement) {
        this.dropdownCellContentWrapper = element;
    }

    @autobind
    protected onPageResize() {
        const rootWidth = this.getRootWidth();

        this.setState({
            rootWidth,
        });

        this.updateStatusCellsPosition(rootWidth);
    }

    @autobind
    protected onBodyScroll() {
        const scrollIsVertical = this.scrollLeft == this.tableBody.scrollLeft;

        if (scrollIsVertical) {
            this.scrollTop = this.tableBody.scrollTop;

            this.updateStatusCellsPosition(this.state.rootWidth);

            if (this.frameManager) {
                this.updateCurrentFrame();
            }
        } else {
            this.scrollLeft = this.tableBody.scrollLeft;

            this.tableHeader.scrollLeft = this.scrollLeft;
            this.sumLine.scrollLeft = this.scrollLeft;

            this.setState({ currentYScroll: this.tableBody.scrollLeft });

            this.updateVisibleColumns();
        }

        this.clearLineHover();
        this.clearLineInfoHover();
        this.updateFixedColumnsPosition();
        this.updateTotalLinesPosition();
        this.setScrollTimer();
    }

    @autobind
    protected onHeaderCellMouseEnter(columnName: ColumnName) {
        if (!this.props.resizingColumnName && !this.state.currentDropdownContent) {
            this.props.setHoveredColumnName(columnName);
        }
    }

    @autobind
    protected onHeaderCellMouseLeave() {
        this.props.setHoveredColumnName(null);
    }

    @autobind
    protected onLineMouseEnter(lineId: string, skipCheck?: boolean) {
        const startHoveredStateCalulation = skipCheck || this.props.budgetTransferMenuisInLineSelectionState;

        if (startHoveredStateCalulation) {
            this.props.setHoveredCell({
                lineId,
                columnName: null,
            });

            if (skipCheck) {
                // dellay to allow onCellClick thunk finish
                setTimeout(() => this.props.updateIsHoveredLineClickable(lineId), 0);
            } else {
                this.props.updateIsHoveredLineClickable(lineId);
            }
        }

        if (!this.props.resizingColumnName && !this.tableIsScrolling) {
            this.props.setHoveredLineId(lineId);
        }

        this.props.setTransferBudgetItemsToPlanningHoveredLineId(lineId);
    }

    @autobind
    protected onLineMouseLeave() {
        if (this.props.budgetTransferMenuisInLineSelectionState) {
            this.props.setHoveredCell({
                lineId: null,
                columnName: null,
            });
            this.props.updateIsHoveredLineClickable(null);
        }

        this.clearLineHover();

        this.props.setTransferBudgetItemsToPlanningHoveredLineId(null);
    }

    @autobind
    protected onHeaderDropdownClick(columnName: string, dropdownContent: JSX.Element) {
        this.setState(() => ({
            currentDropdownContent: dropdownContent,
            dropdownContentX: this.getDropdownX(columnName),
        }));

        this.props.setHoveredColumnName(null);
    }

    @autobind
    protected onDropdownCellClick(
        columnName: string,
        budgetItemId: string,
        dropdownContent: JSX.Element,
        contentHeight: number,
    ) {
        this.setState(() => ({
            dropdownCellPosition: this.getDropdownPosition(columnName, budgetItemId, contentHeight),
            dropdownCellContent: dropdownContent,
        }));
    }

    @autobind
    protected getDropdownPosition(columnName: string, budgetItemId: string, height: number): DropdownPosition {
        return {
            height,
            width: this.getDropdownWidth(columnName),
            x: this.getDropdownX(columnName),
            y: this.getDropdownY(budgetItemId, height),
        };
    }

    @autobind
    protected getDropdownWidth(columnName: string): number {
        return this.props.columnsWidth[columnName] + BORDER_WIDTH;
    }

    @autobind
    protected getDropdownX(columnName: string): number {
        const { columnsWidth, columnsVisiblityFilter, fixedColumnsNames } = this.props;
        const { rootWidth } = this.state;

        const allColumnsAreHidden = lodash.every(columnsVisiblityFilter, (isChecked) => !isChecked);

        const visibleColumns = allColumnsAreHidden
            ? ColumnsList
            : ColumnsList.filter((column) => columnsVisiblityFilter[column.name]);

        const nonfixedColumns = visibleColumns.filter((item) => !lodash.includes(fixedColumnsNames, item.name));

        const tableLeftScroll = this.tableBody.scrollLeft;

        let columnIndex: number;
        let previousColumnsWidthSum: number;
        let x: number;

        if (fixedColumnsNames.length > 0) {
            const fixedColumns = fixedColumnsNames
                .filter((item) => visibleColumns.some((column) => column.name == item))
                .map((item) => visibleColumns.find((column) => column.name == item));

            const columnIsFixed = lodash.includes(fixedColumnsNames, columnName);

            if (columnIsFixed) {
                columnIndex = lodash.findIndex(fixedColumns, (item) => item.name == columnName);

                previousColumnsWidthSum = fixedColumns
                    .slice(0, columnIndex)
                    .reduce((acc, item) => acc + columnsWidth[item.name], 0);

                x = previousColumnsWidthSum;
            } else {
                const fixedColumnsWidthSum = fixedColumns.reduce((acc, item) => acc + columnsWidth[item.name], 0);

                columnIndex = lodash.findIndex(nonfixedColumns, (item) => item.name == columnName);

                previousColumnsWidthSum = nonfixedColumns
                    .slice(0, columnIndex)
                    .reduce((acc, item) => acc + columnsWidth[item.name], 0);

                x = fixedColumnsWidthSum + previousColumnsWidthSum - tableLeftScroll;
            }
        } else {
            columnIndex = lodash.findIndex(nonfixedColumns, (item) => item.name == columnName);

            previousColumnsWidthSum = nonfixedColumns
                .slice(0, columnIndex)
                .reduce((acc, item) => acc + columnsWidth[item.name], 0);

            x = previousColumnsWidthSum - tableLeftScroll;
        }

        const dropdownWidth = this.getDropdownWidth(columnName);

        if (x < 0) {
            x = 0;
        }

        if (x + dropdownWidth > rootWidth) {
            x = rootWidth - dropdownWidth;
        }

        return x;
    }

    @autobind
    protected getDropdownY(budgetItemId: string, height: number): number {
        const { lines, lineGroups, pageContentHeight, tableOffsetTop, sortingMode } = this.props;

        const tableIsSortedByActivityName = sortingMode.columnName == ColumnName.ActivityName;

        const tableScrollY = this.tableBody.scrollTop;

        let y = 0;

        if (tableIsSortedByActivityName) {
            const currentActivityIndex = lodash.findIndex(lineGroups, (item) =>
                item.lines.some((budgetItem) => budgetItem.id === budgetItemId),
            );

            const previousLineGroups = lineGroups.slice(0, currentActivityIndex);
            const previousLinesCount = previousLineGroups.reduce(
                (acc, item) => acc + item.lines.length + (tableIsSortedByActivityName ? 1 : 0),
                0,
            );

            const hoveredGroupLines = lineGroups[currentActivityIndex].lines;

            const activityBudgetItems = lodash.findIndex(hoveredGroupLines, (item) => item.id == budgetItemId);
            const activityBudgetItemsAmount = hoveredGroupLines.slice(0, activityBudgetItems + 1).length;

            y =
                (previousLinesCount + activityBudgetItemsAmount) * LINE_HEIGHT +
                TABLE_HEADER_HEIGHT -
                tableScrollY -
                BORDER_WIDTH +
                previousLineGroups.length * TOTAL_LINE_MARGIN;
        } else {
            const currentLineIndex = lodash.findIndex(lines, (item) => item.id == budgetItemId);

            const previousLinesCount = currentLineIndex;

            const previousLinesHeight = previousLinesCount * LINE_HEIGHT;

            y = TABLE_HEADER_HEIGHT + previousLinesHeight + LINE_HEIGHT - tableScrollY - BORDER_WIDTH;
        }

        const shouldOpenUpwards = pageContentHeight < tableOffsetTop + y + height;

        if (shouldOpenUpwards) {
            y -= LINE_HEIGHT - BORDER_WIDTH + height;
        }

        return y;
    }

    @autobind
    protected onInfoMouseEnter(lineId: string) {
        if (!this.tableIsScrolling) {
            this.setState(() => ({
                hoveredInfoLineId: lineId,
                tooltipPosition: this.getTooltipPosition(lineId),
            }));
        }
    }

    @autobind
    protected onInfoMouseLeave() {
        this.clearLineInfoHover();
    }

    @autobind
    protected onKeydown(event: KeyboardEvent) {
        const ctrlZ = event.keyCode == Z_KEY_CODE && (event.ctrlKey || event.metaKey) && !event.shiftKey;
        const ctrlY = event.keyCode == Y_KEY_CODE && (event.ctrlKey || event.metaKey) && !event.shiftKey;
        const ctrlShiftZ = event.keyCode == Z_KEY_CODE && (event.ctrlKey || event.metaKey) && event.shiftKey;

        if (ctrlZ) {
            this.props.undoUnsavedChanges();
            event.preventDefault();
        }

        if (ctrlShiftZ || ctrlY) {
            this.props.redoUnsavedChanges();
            event.preventDefault();
        }
    }

    private getTooltipPosition(hoveredInfoLineId: string): TooltipPosition {
        const { lines, lineGroups, sortingMode, pageContentHeight, tableOffsetTop } = this.props;

        if (hoveredInfoLineId == null) {
            return null;
        }

        const tableIsSortedByActivityName = sortingMode.columnName == ColumnName.ActivityName;

        let y: number;

        const tableScrollY = this.tableBody.scrollTop;

        if (tableIsSortedByActivityName) {
            const hoveredGroupIndex = lodash.findIndex(lineGroups, (group) =>
                group.lines.some((line) => line.id == hoveredInfoLineId),
            );

            const previousLineGroups = lineGroups.slice(0, hoveredGroupIndex);

            const previousLinesCount = previousLineGroups.reduce((acc, item) => acc + item.lines.length + 1, 0);

            const hoveredGroupLines = lineGroups[hoveredGroupIndex].lines;

            lodash.findIndex(hoveredGroupLines, (item) => item.id == hoveredInfoLineId);

            const hoveredGroupPreviousLinesCount = lodash.findIndex(
                hoveredGroupLines,
                (item) => item.id == hoveredInfoLineId,
            );

            y =
                (previousLinesCount + hoveredGroupPreviousLinesCount) * LINE_HEIGHT +
                previousLineGroups.length * TOTAL_LINE_MARGIN +
                TABLE_HEADER_HEIGHT -
                tableScrollY;
        } else {
            const previousLinesCount = lodash.findIndex(lines, (item) => item.id == hoveredInfoLineId);

            y = previousLinesCount * LINE_HEIGHT + TABLE_HEADER_HEIGHT - tableScrollY;
        }

        const direction =
            pageContentHeight / 2 > tableOffsetTop + y + LINE_HEIGHT / 2 ? TooltipDirection.Down : TooltipDirection.Up;

        return {
            direction,
            y,
        };
    }

    private getRootWidth(): number {
        return this.root.getBoundingClientRect().width;
    }

    private updateStatusCellsPosition(rootWidth: number): void {
        const cellContentLeft = this.tableBody.scrollLeft + rootWidth - FIXED_CELL_WIDTH;
        this.statusCellsContent.forEach((line) => (line.style.left = `${cellContentLeft}px`));
    }

    private checkLinesChanges(newLines: TableLine[], oldLines: TableLine[]): boolean {
        return !lodash.isEqual(newLines, oldLines);
    }

    private checkLineGroupsChanges(newGroups: TableLineGroup[], oldGroups: TableLineGroup[]): boolean {
        return !lodash.isEqual(newGroups, oldGroups);
    }

    private updateFrameManager() {
        const { sortingMode, maxHeight, lines, lineGroups } = this.props;

        const sortedByActivityName = sortingMode.columnName == ColumnName.ActivityName;

        const scrollOffset = this.tableBody ? this.tableBody.scrollTop : 0;

        if (sortedByActivityName) {
            this.frameManager = new GroupedFrameManager({
                viewportHeight: maxHeight - TABLE_HEADER_HEIGHT,
                scrollOffset,
                items: lineGroups,
            });
        } else {
            this.frameManager = new PlainFrameManager({
                viewportHeight: maxHeight - TABLE_HEADER_HEIGHT,
                scrollOffset,
                items: lines,
            });
        }

        const newFrameIndex = this.frameManager.getCurrentFrameIndex();

        if (newFrameIndex !== this.state.currentFrameIndex) {
            this.setState({
                currentFrameIndex: this.frameManager.getCurrentFrameIndex(),
            });
        }

        this.forceUpdate();
    }

    private clearLineInfoHover() {
        if (this.state.hoveredInfoLineId !== null) {
            this.setState(() => ({
                hoveredInfoLineId: null,
                tooltipPosition: null,
            }));
        }
    }

    private clearLineHover() {
        this.props.setHoveredLineId(null);
    }

    private setScrollTimer() {
        if (this.scrollTimer) {
            clearTimeout(this.scrollTimer);
        }

        this.tableIsScrolling = true;

        this.scrollTimer = setTimeout(() => {
            this.tableIsScrolling = false;
        }, SCROLL_TIMEOUT);
    }

    private updateCurrentFrame() {
        const scrollOffset = this.scrollTop;

        this.frameManager.setScrollOffset(scrollOffset);
        const frameIndex = this.frameManager.getCurrentFrameIndex();

        if (frameIndex !== this.state.currentFrameIndex) {
            this.setState({
                currentFrameIndex: frameIndex,
            });
        }
    }

    private updateFixedColumnsPosition() {
        this.updateFixedElementPosition(this.headerFixedColumns);

        this.fixedColumnsLines.forEach((item) => this.updateFixedElementPosition(item));
    }

    private updateFixedElementPosition(element: HTMLDivElement) {
        if (element) {
            element.style.left = `${this.scrollLeft}px`;
        }
    }

    private updateTotalLinesPosition() {
        this.totalLinesContent.forEach((line) => {
            if (line) {
                line.style.left = `${this.scrollLeft}px`;
            }
        });
    }

    private updateTableScroll() {
        const { columnsVisiblityFilter, columnsWidth } = this.props;
        const { rootWidth } = this.state;

        const allColumnsAreHidden = lodash.values(columnsVisiblityFilter).every((item) => item === false);

        const visibleColumns = allColumnsAreHidden
            ? ColumnsList
            : ColumnsList.filter((item) => columnsVisiblityFilter[item.name]);

        const tableWidth = visibleColumns.reduce((acc, item) => acc + columnsWidth[item.name], 0) + LINE_MENU_WIDTH;

        const currentScrollX = this.tableBody.scrollLeft;
        const currentScrollY = this.tableBody.scrollTop;

        if (currentScrollX + rootWidth > tableWidth) {
            const newScrollX = tableWidth - rootWidth;

            this.tableHeader.scrollTo(currentScrollY, newScrollX);
            this.tableBody.scrollTo(currentScrollY, newScrollX);
        }
    }

    private updateVisibleColumns() {
        const newVisibleColumns = this.calculateVisibleColumns(this.scrollLeft);

        const visibleColumnsChanged = !lodash.isEqual(newVisibleColumns, this.state.visibleColumnsNames);

        if (visibleColumnsChanged) {
            this.setState({
                visibleColumnsNames: newVisibleColumns,
            });
        }
    }

    private calculateVisibleColumns(scrollOffset: number): ColumnName[] {
        const { columnsWidth, columnsVisiblityFilter } = this.props;
        const { rootWidth } = this.state;

        const allColumnsAreHidden = lodash.every(columnsVisiblityFilter, (item) => item === false);

        const filteredColumns = allColumnsAreHidden
            ? ColumnsList
            : ColumnsList.filter((column) => columnsVisiblityFilter[column.name]);

        const viewportStart = scrollOffset;
        const viewportEnd = scrollOffset + rootWidth;

        let startIndex: number = null;
        let endIndex: number = null;

        let columnIndex = 0;
        let widthSum = 0;

        while (endIndex === null && filteredColumns[columnIndex]) {
            const columnName = filteredColumns[columnIndex].name;

            widthSum += columnsWidth[columnName];

            if (startIndex === null && widthSum >= viewportStart) {
                startIndex = columnIndex - 1;

                if (startIndex < 0) {
                    startIndex = 0;
                }
            }

            if (startIndex !== null && widthSum >= viewportEnd) {
                endIndex = columnIndex + 1;

                if (endIndex > ColumnsList.length - 1) {
                    endIndex = ColumnsList.length - 1;
                }
            }

            columnIndex++;
        }

        if (endIndex === null) {
            endIndex = columnIndex + 1;
        }

        return filteredColumns.slice(startIndex, endIndex + 1).map((item) => item.name);
    }

    private initScrollLeftPosition() {
        const { columnsVisiblityFilter, fixedColumnsNames, columnsWidth } = this.props;

        const columnName = this.props.initColumnScroll;
        let scrollLeftPosition: number;

        if (!columnName || fixedColumnsNames.includes(columnName)) {
            scrollLeftPosition = 0;
        } else {
            const columnNames = Object.keys(columnsVisiblityFilter);
            const allColumnsAreVisible =
                columnNames.every((column) => columnsVisiblityFilter[column]) ||
                columnNames.every((column) => !columnsVisiblityFilter[column]);
            const columnNamesToKeepScroll = columnNames
                .slice(0, columnNames.indexOf(columnName))
                .filter(
                    (columnName) =>
                        (allColumnsAreVisible || columnsVisiblityFilter[columnName]) && !fixedColumnsNames[columnName],
                );

            scrollLeftPosition = !columnNamesToKeepScroll.length
                ? 0
                : columnNamesToKeepScroll.reduce((acc, columnName) => acc + columnsWidth[columnName], 0);
        }

        window.setTimeout(() => {
            if (this.tableHeader && this.tableBody) {
                this.tableHeader.centerAt(0, scrollLeftPosition);
                this.tableBody.centerAt(0, scrollLeftPosition);
                this.sumLine.centerAt(0, scrollLeftPosition);

                this.scrollLeft = this.tableBody.scrollLeft;

                this.updateFixedColumnsPosition();
                this.updateTotalLinesPosition();
            }
        }, 100);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const {
        fixedColumnsNames,
        columnsWidth,
        resizingColumnName,
        hoveredColumnName,
        computedData: { pageBudgetItems, tableLines },
    } = getBudgetExecutionPageState(state);
    const { columnsVisiblityFilter, sortingMode, useLinesWithoutPlanInSorting } = getTableFilters(state);

    const budgetTransferMenuisInLineSelectionState =
        getBudgetTransferMenuState(state).controls.componentState ===
        BudgetTransferMenuComponentState.InternalTransferLineSelection;

    return {
        allLines: tableLines,
        lines: getFilteredTableLines(state),
        lineGroups: getLinesGroupedByActivities(state),
        fixedColumnsNames,
        columnsWidth,
        columnsVisiblityFilter,
        resizingColumnName,
        hoveredColumnName,
        sortingMode,
        showNoLinesStub: !pageBudgetItems.length,
        useLinesWithoutPlanInSorting,
        budgetTransferMenuisInLineSelectionState,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setHoveredColumnName,
            setFilters,
            undoUnsavedChanges,
            redoUnsavedChanges,
            setHoveredCell,
            setHoveredLineId,
            setTransferBudgetItemsToPlanningHoveredLineId,
            updateIsHoveredLineClickable,
        },
        dispatch,
    );
}
