import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { TableHeaderCellParams, ColumnName } from '../../types';
import { ColumnHeaderType, ColumnParams } from '../../ColumnsConfig';

import { TextColumnHeader } from '../../ColumnHeaderTypes';

export const ColumnHeaderComponentsByType: Record<ColumnHeaderType, React.ClassType<any, any, any>> = {
    [ColumnHeaderType.Text]: TextColumnHeader,
};

interface Props {
    getColumnsConfig: () => { [columnName: string]: ColumnParams };
}

export class ColumnHeaderFactory {
    private getColumnsConfig: () => { [columnName: string]: ColumnParams };

    public constructor(props: Props) {
        this.getColumnsConfig = props.getColumnsConfig;
    }

    @autobind
    public makeColumnHeaderParams(columnName: ColumnName): TableHeaderCellParams {
        return {
            component: this.getColumnHeaderComponent(columnName),
            cellProps: this.makeColumnHeaderProps(columnName),
            disableWidthChange: !this.columnWidthCanBeChanged(columnName),
        };
    }

    private getColumnHeaderComponent(columnName: ColumnName): React.ClassType<any, any, any> {
        const columnType = this.getColumnsConfig()[columnName].headerType;

        return ColumnHeaderComponentsByType[columnType];
    }

    private makeColumnHeaderProps(columnName: ColumnName): any {
        const headerType = this.getColumnsConfig()[columnName].headerType;

        let cellProps: any;

        switch (headerType) {
            case ColumnHeaderType.Text:
                cellProps = this.makeTextColumnHeaderProps(columnName as ColumnName);
                break;
        }

        return cellProps;
    }

    private makeTextColumnHeaderProps(columnName: ColumnName): any {
        return {
            title: this.getColumnsConfig()[columnName].title as string,
        };
    }

    private columnWidthCanBeChanged(columnName: ColumnName): boolean {
        return lodash.get(this.getColumnsConfig(), [columnName, 'disableWidthChange']) !== undefined
            ? !lodash.get(this.getColumnsConfig(), [columnName, 'disableWidthChange'])
            : true;
    }
}
