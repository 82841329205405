import * as React from 'react';

import { CommentsConnected } from './CommentsConnected';
import { CommentsContainer } from './CommentsContainer';

interface Props {
    cardId: string;
    enableControls: boolean;
}

export function Comments({ cardId, enableControls }: Props): JSX.Element {
    return (
        <CommentsConnected cardId={cardId}>
            {(gqlProps) => <CommentsContainer {...gqlProps} enableControls={enableControls} />}
        </CommentsConnected>
    );
}
