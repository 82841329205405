import * as React from 'react';

import * as style from './XLSBuilder.scss';

import { Icon, IconType } from 'sber-marketing-ui';
import { useHooks } from './hooks';

interface Props {}

export const XLSBuilder = ({}: Props): JSX.Element => {
    const { download } = useHooks();

    return (
        <div className={style.root} onClick={download}>
            <Icon className={style.icon} type={IconType.EXCEL} svgSize={20} />

            <div className={style.title}>Скачать в Excel</div>
        </div>
    );
};
