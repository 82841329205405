import * as React from 'react';
import classnames from 'classnames';

import { CreativeRequest, CreativeRequestParam } from '@api';

import { DatesFormatter } from '@common/Utils';

import {
    useDescription,
    useCreatedAt,
    useParams,
    useParamBlock,
    useParamDivision,
    useParamProduct,
    useParamSegment,
    useParamChannel,
} from './hooks';

import { WithScrollbar } from '../WithScrollbar';

import * as styles from './Info.scss';
import * as commonStyles from '../../CommonStyles.scss';

interface Props {
    creativeRequest: CreativeRequest;
}

export function Info(props: Props): JSX.Element {
    return (
        <WithScrollbar>
            <MainInfo {...props} />

            <div className={commonStyles.horizontalBar} />

            <Params {...props} />
        </WithScrollbar>
    );
}

function MainInfo({ creativeRequest }: Props): JSX.Element {
    const description = useDescription(creativeRequest);
    const createdAt = useCreatedAt(creativeRequest);

    return (
        <div className={classnames(styles.infoBlock, styles.verticalBlock, commonStyles.sidePaddings)}>
            <div className={styles.title}>Основная информация</div>

            <ItemWithTitle title="Краткое описание" text={description} />
            <ItemWithTitle title="Дата создания заявки" text={DatesFormatter.ddMonthyy(createdAt)} />
        </div>
    );
}

function Params({ creativeRequest }: Props): JSX.Element {
    const params = useParams(creativeRequest);

    return (
        <React.Fragment>
            {params?.map((param) => (
                <ParamInfo key={param.model.id} param={param} />
            ))}
        </React.Fragment>
    );
}

interface WithParamProps {
    param: CreativeRequestParam;
}

function ParamInfo({ param }: WithParamProps): JSX.Element {
    return (
        <React.Fragment>
            <div className={classnames(styles.infoBlock, styles.verticalBlock, commonStyles.sidePaddings)}>
                <div className={styles.verticalBlock}>
                    <Block param={param} />
                </div>

                <div className={styles.grid}>
                    <Division param={param} />
                    <Product param={param} />
                    <Segment param={param} />
                    <Channel param={param} />
                </div>
            </div>

            <div className={commonStyles.horizontalBar} />
        </React.Fragment>
    );
}

function Block({ param }: WithParamProps): JSX.Element {
    const block = useParamBlock(param);

    return <ItemWithTitle title="Блок" text={block?.value} />;
}

function Division({ param }: WithParamProps): JSX.Element {
    const division = useParamDivision(param);

    return <ItemWithTitle title="Дивизион бизн. заказч." text={division?.value} subText={division?.code} />;
}

function Product({ param }: WithParamProps): JSX.Element {
    const product = useParamProduct(param);

    return <ItemWithTitle title="Продукт" text={product?.value} subText={product?.code} />;
}

function Segment({ param }: WithParamProps): JSX.Element {
    const segment = useParamSegment(param);

    return <ItemWithTitle title="Сегмент" text={segment?.value} subText={segment?.code} />;
}

function Channel({ param }: WithParamProps): JSX.Element {
    const channel = useParamChannel(param);

    return <ItemWithTitle title="Канал" text={channel?.value} subText={channel?.code} />;
}

interface ItemWithTitle {
    title: string;
    text: string;
    subText?: string;
}

function ItemWithTitle({ title, text, subText }: ItemWithTitle): JSX.Element {
    return (
        <div>
            <div className={styles.itemTitle}>{title}</div>

            <div className={styles.itemText}>
                {text || '-'}
                {subText && (
                    <React.Fragment>
                        <br />
                        <span className={styles.itemSubText}>{subText}</span>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
}
