import { createSelector } from 'reselect';
import { Node, Edge } from 'react-flow-renderer';
import { Tag } from '@mrm/tags';
import { Dictionary } from 'lodash';

import { StoreState } from '@store';
import { getTagsState } from '@store/tags';

import { TagsPageState as State, TagsPageFilters as Filters, SelectedTagData, TagGroup, NodeData } from '../types';

import { getTagsPageState } from './rest';

import { makeSelectedTagData } from '../misc';

interface Tagable {
    id: string | number;
    tagId: string;
    year: number;
}

const NODE_TYPE = 'customNode';
const EDGE_TYPE = 'straight';

export const getTagsToDisplay = createSelector(
    (state: StoreState) => getTagsState(state).entities,
    (state: StoreState) => state.tagsPage.filters.tag,
    (tags: Tag[], filter: string): Tag[] => tags.filter((tag) => tag.id === filter),
);

function filterData<T extends Tagable>(filters: Filters, items: Dictionary<T>, tagGroup: TagGroup): T[] {
    const { groups, tag, years } = filters;

    if (groups.includes(tagGroup)) {
        return Object.values(items).filter((item) => {
            if (item.tagId !== tag) {
                return false;
            }

            return years.includes(item.year);
        });
    }

    return [];
}

export const getDataToDisplay = createSelector(getTagsPageState, (state: State): SelectedTagData => {
    const { filters, selectedTagData } = state;

    return makeSelectedTagData(
        filterData(filters, selectedTagData.projects, TagGroup.Project),
        filterData(filters, selectedTagData.tasks, TagGroup.Task),
        filterData(filters, selectedTagData.executionBudgetItems, TagGroup.ExecutionId),
        filterData(filters, selectedTagData.planBudgetItems, TagGroup.PlanId),
    );

    // return {
    //     projects: filterData(filters, selectedTagData.projects, TagGroup.Project),
    //     tasks: filterData(filters, selectedTagData.tasks, TagGroup.Task),
    //     executionBudgetItems: filterData(filters, selectedTagData.executionBudgetItems, TagGroup.ExecutionId),
    //     planBudgetItems: filterData(filters, selectedTagData.planBudgetItems, TagGroup.PlanId),
    // };
});

type NodeItem = { id: string | number };
type NodeDesc = Pick<Node, 'id' | 'type' | 'position'>;
function makeNode(item: NodeItem): NodeDesc {
    return {
        id: `${item.id}`,
        type: NODE_TYPE,
        position: { x: 0, y: 0 },
    };
}
export const getNodes = createSelector(
    getTagsToDisplay,
    getDataToDisplay,
    (tags: Tag[], data: SelectedTagData): Node<NodeData>[] => [
        ...tags.map((tag) => ({
            ...makeNode(tag),
            data: { group: TagGroup.Tag, label: tag.title, size: 40 },
        })),
        ...Object.values(data.projects).map((project) => ({
            ...makeNode(project),
            data: { group: TagGroup.Project, label: project.title, size: 25 },
        })),
        ...Object.values(data.tasks).map((task) => ({
            ...makeNode(task),
            data: { group: TagGroup.Task, label: task.title, size: 20 },
        })),
        ...Object.values(data.executionBudgetItems).map((executionBudgetItem) => ({
            ...makeNode(executionBudgetItem),
            data: { group: TagGroup.ExecutionId, label: executionBudgetItem.serialNumber, size: 15 },
        })),
        ...Object.values(data.planBudgetItems).map((planBudgetItem) => ({
            ...makeNode(planBudgetItem),
            data: { group: TagGroup.PlanId, label: planBudgetItem.serialNumber, size: 15 },
        })),
    ],
);

function makeEdge(item: Tagable, source: string | number): Edge {
    return {
        id: `${source}-${item.id}`,
        source: `${source}`,
        target: `${item.id}`,
        type: EDGE_TYPE,
    };
}

// Legend
// T - tag
// P - project, Ta - task
// E - execution budgetItem, P - plan budgetItem
export const getEdges = createSelector(
    (state: StoreState) => state.tagsPage.filters.groups,
    getDataToDisplay,
    (tagGroupsToDisplay: TagGroup[], data: SelectedTagData): Edge[] => {
        const activitiesAreVisible = tagGroupsToDisplay.includes(TagGroup.Project);
        const executionBudgetItemsAreVisible = tagGroupsToDisplay.includes(TagGroup.ExecutionId);
        const planBudgetItemsAreVisible = tagGroupsToDisplay.includes(TagGroup.PlanId);

        const linksFromExecutionBudgetItemsToProjects: Dictionary<number> = {};
        const linksFromPlanBudgetItemsToExecutionBudgetItems: Dictionary<string> = {};

        const result: Edge[] = [];

        // A -> T
        // preparing A -> E links
        Object.values(data.projects).forEach((project) => {
            result.push(makeEdge(project, project.tagId));

            const executionBudgetItemsToAddLinks = executionBudgetItemsAreVisible
                ? project.budgetItemIds?.filter((budgetItemId) => !!data.executionBudgetItems[budgetItemId])
                : [];
            executionBudgetItemsToAddLinks.forEach(
                (executionBudgetItemId) =>
                    (linksFromExecutionBudgetItemsToProjects[executionBudgetItemId] = project.id),
            );
        });

        // Ta -> P, Ta -> T
        Object.values(data.tasks).forEach((task) => {
            const sourceId = activitiesAreVisible && data.projects[task.projectId] ? `${task.projectId}` : task.tagId;

            result.push(makeEdge(task, sourceId));
        });

        // E -> A, E -> T
        // preparing E -> Pl links
        Object.values(data.executionBudgetItems).forEach((executionBudgetItem) => {
            const sourceId =
                activitiesAreVisible && linksFromExecutionBudgetItemsToProjects[executionBudgetItem.id]
                    ? linksFromExecutionBudgetItemsToProjects[executionBudgetItem.id]
                    : executionBudgetItem.tagId;

            result.push(makeEdge(executionBudgetItem, sourceId));

            const addLinkToPlanBudgetItem =
                planBudgetItemsAreVisible && data.planBudgetItems[executionBudgetItem.sourceBudgetItemId];
            if (addLinkToPlanBudgetItem) {
                linksFromPlanBudgetItemsToExecutionBudgetItems[executionBudgetItem.sourceBudgetItemId] =
                    executionBudgetItem.id;
            }
        });

        // Pl -> E, Pl -> T
        Object.values(data.planBudgetItems).forEach((planBudgetItem) => {
            const sourceId =
                executionBudgetItemsAreVisible && linksFromPlanBudgetItemsToExecutionBudgetItems[planBudgetItem.id]
                    ? linksFromPlanBudgetItemsToExecutionBudgetItems[planBudgetItem.id]
                    : planBudgetItem.tagId;

            result.push(makeEdge(planBudgetItem, sourceId));
        });

        return result;
    },
);
