import * as React from 'react';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';

import { StoreState } from '@store';
import { ComponentState, getSearchState, setVisibility } from '@store/search';

import { SearchMenu } from './SearchMenu';

interface Props extends Partial<MapProps & DispatchProps> {}

interface MapProps {
    isVisible: boolean;
    componentState: ComponentState;
}

interface DispatchProps {
    onMaskClick: () => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class SearchMenuContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { componentState, isVisible, onMaskClick } = this.props;

        return isVisible ? <SearchMenu componentState={componentState} onMaskClick={onMaskClick} /> : null;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { componentState, isVisible } = getSearchState(state);

    return {
        componentState,
        isVisible,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        onMaskClick: () => dispatch(setVisibility(false)),
    };
}
