import * as React from 'react';
import autobind from 'autobind-decorator';
import { graphql, compose } from 'react-apollo';
import { get } from 'lodash';
import { v4 } from 'uuid';

import { WITH_COMMENTS_QUERY, CREATE_COMMENT_MUTATION, ADD_COMMENT_TO_BUDGET_ITEM_MUTATION } from './query';

interface CommentProps {
    id: string;
    text: string;
    createTime: string;
    author: {
        firstName: string;
        secondName: string;
        organizationName: string;
        departmentName: string;
    };
    files: {
        id: string;
        name: string;
        originName: string;
        type: string;
    }[];
}

export interface ChildrenProps {
    comments: CommentProps[];
    loading: boolean;
    refetch: () => Promise<void>;
    createComment: (text: string, fileIds: string[]) => Promise<void>;
}

interface Props extends ExternalProps, QueryProps {}

interface ExternalProps {
    budgetItemId: string;
    children: (props: ChildrenProps) => JSX.Element;
}

type MutationFunc<V> = (params: { variables: V }) => Promise<void>;
type MutationResult = {
    loading: boolean;
};

type QueryProps = {
    commentsQuery: {
        loading: boolean;
        refetch: () => Promise<void>;
        budgetItemComments: {
            nodes: CommentProps[];
        };
    };
    createCommentMutation: MutationFunc<{
        id: string;
        text: string;
        fileIds?: any[];
    }>;
    createCommentMutationResult: MutationResult;
    addCommentToBudgetItemMutation: MutationFunc<{
        budgetItemId: string;
        commentId: string;
    }>;
    addCommentToBudgetItemMutationResult: MutationResult;
};

class CommentsWidgetConnectedWrapper extends React.PureComponent<Props> {
    public render(): JSX.Element {
        return this.props.children(this.getChildrenProps());
    }

    private getChildrenProps(): ChildrenProps {
        const { loading, refetch } = this.props.commentsQuery;
        const comments: CommentProps[] = get(this.props.commentsQuery, 'budgetItemComments.nodes') || [];

        return {
            comments,
            loading,
            refetch,
            createComment: this.createComment,
        };
    }

    @autobind
    private async createComment(text: string, fileIds: string[]): Promise<void> {
        const {
            budgetItemId,
            commentsQuery: { refetch },
            createCommentMutation,
            addCommentToBudgetItemMutation,
        } = this.props;
        const commentId = v4();

        await createCommentMutation({
            variables: {
                id: commentId,
                text,
                fileIds,
            },
        });

        await addCommentToBudgetItemMutation({
            variables: {
                budgetItemId,
                commentId,
            },
        });

        await refetch();
    }
}

export const CommentsWidgetConnected = compose(
    graphql<ExternalProps>(WITH_COMMENTS_QUERY, {
        name: 'commentsQuery',
        options: ({ budgetItemId }) => ({
            variables: {
                budgetItemId,
            },
            fetchPolicy: 'no-cache',
        }),
    }),
    graphql<ExternalProps>(CREATE_COMMENT_MUTATION, { name: 'createCommentMutation' }),
    graphql<ExternalProps>(ADD_COMMENT_TO_BUDGET_ITEM_MUTATION, { name: 'addCommentToBudgetItemMutation' }),
)(CommentsWidgetConnectedWrapper) as React.ComponentType<ExternalProps>;
