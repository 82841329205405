import * as React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '@store';
import { getCommentaryAssets } from '@store/taskPage/selectors';
import classnames from 'classnames';
import * as styles from './CommentAssetsHeader.scss';
import { Dictionary } from 'lodash';
import { FileApiUploadParams } from '@api';
import { Icon, IconType } from 'sber-marketing-ui';
import { DownloadAllButton } from '@common/DownloadAllButton';
import { assetToFileResponse } from './helpers';

export interface CommentAssetsHeaderProps {
    commentId: string;
    taskId: string;
    open: boolean;
    onToggle: () => void;
}

export const CommentAssetsHeader: React.FC<CommentAssetsHeaderProps> = ({ open, onToggle, commentId, taskId }) => {
    const files = useSelector((state: StoreState) => getCommentaryAssets(state, commentId).map(assetToFileResponse));

    const qaContentProps = { 'qa-id': 'commentsBlockCommentFilesExpandIcon' };
    const expandIconClassName = classnames(styles.expandIcon, open && styles.expandIconActive);
    const mainParams = { taskId, commentId };
    const params: Dictionary<FileApiUploadParams> = React.useMemo(() => {
        const result: Dictionary<FileApiUploadParams> = {};

        for (const file of files) {
            result[file.id] = mainParams;
        }

        return result;
    }, [files]);

    return (
        <div className={styles.root}>
            <div className={styles.openButton} onClick={onToggle}>
                <Icon type={IconType.ATTACHMENT_ICON} className={styles.icon} svgSize={20} />
                Вложения
                <div {...qaContentProps} className={expandIconClassName}>
                    <Icon type={IconType.EXPAND_ICON} svgSize={16} />
                </div>
            </div>
            <DownloadAllButton
                fileName={`Вложения комментария ${commentId} от ${new Date().toLocaleString()}`}
                files={files}
                params={params}
            />
        </div>
    );
};
