import { axios, AxiosResponse } from '../lib/axios';

import type {
    Comment,
    CommentCreateParams,
    CreateTaskParams,
    TaskResponse,
    EditTaskParams,
    CommentUpdateParams,
    TaskAttributes,
    TaskListFilters,
    TaskCardParamsResponse,
    ChanelParams,
    EditChanelParams,
    Brief,
    TaskCardParams,
    FullTaskInfoResponse,
    PlugTelegramToTaskResponse,
    TaskTelegramStatusResponse,
    UnplugTelegramToTaskResponse,
    GetTaskFilesPayload,
    FileResponse,
    ReactToTaskCommentParams,
    TaskCommentTransferObject,
} from 'sber-marketing-types/frontend';
import { TaskStatus } from 'sber-marketing-types/frontend';
import { OneTaskResponseParams } from 'sber-marketing-types/backend';

const BASE_PATH = '/api/task';

export class TaskApi {
    public static readonly DEFAULT_TASK_PAGINATION_SIZE: number = 10;

    public static async getTaskList(params: TaskListFilters): Promise<TaskAttributes[]> {
        const res = await axios.post<TaskAttributes[]>(`${BASE_PATH}/list`, params);

        return res.data;
    }

    public static async getFilteredTaskCardsList(params: TaskListFilters = {}): Promise<TaskCardParamsResponse> {
        const res = await axios.post<TaskCardParamsResponse>(`${BASE_PATH}/cards`, params);
        return res.data;
    }

    public static async getFilteredTaskCardsCount(params: TaskListFilters = {}): Promise<any> {
        const res = await axios.post<TaskCardParamsResponse>(`${BASE_PATH}/count`, params);
        return res.data;
    }

    public static async getTaskCard(taskId: string): Promise<TaskCardParams> {
        const res = await axios.get<TaskCardParams>(`${BASE_PATH}/card/${taskId}`);
        return res.data;
    }

    public static async getTask(taskId: string): Promise<FullTaskInfoResponse> {
        const res: AxiosResponse<FullTaskInfoResponse> = await axios.get(`${BASE_PATH}/${taskId}`);
        return res.data;
    }

    public static async getTaskBudgetApproval(taskId: string): Promise<any> {
        const res: AxiosResponse<any> = await axios.get(`${BASE_PATH}/${taskId}/budgetApproval`);
        return res.data;
    }

    public static async getTaskShortened(taskId: string): Promise<OneTaskResponseParams> {
        const res: AxiosResponse<OneTaskResponseParams> = await axios.get(`${BASE_PATH}/${taskId}/short`);
        return res.data;
    }

    public static async getTaskBrief(taskId: string): Promise<Brief> {
        const res = await axios.get(`${BASE_PATH}/${taskId}/brief`);

        return res.data;
    }

    public static async getTelegramStatus(taskId: string): Promise<TaskTelegramStatusResponse> {
        const res = await axios.get(`${BASE_PATH}/${taskId}/telegram`);

        return res.data;
    }

    public static async updateTaskBrief(taskId: string, briefData: any): Promise<any> {
        const res = await axios.put(`${BASE_PATH}/${taskId}/brief`, briefData);

        return res.data;
    }

    public static async plugTelegram(taskId: string): Promise<PlugTelegramToTaskResponse> {
        const res = await axios.post(`${BASE_PATH}/${taskId}/telegram`);

        return res.data;
    }

    public static async unplugTelegram(taskId: string): Promise<UnplugTelegramToTaskResponse> {
        const res = await axios.delete(`${BASE_PATH}/${taskId}/telegram`);
        return res.data;
    }

    public static async setRate(taskId: string, params: { rate: number }): Promise<void> {
        const res = await axios.post(`${BASE_PATH}/${taskId}/rates`, params);
        return res.data;
    }

    public static async getRate(taskId: string): Promise<any> {
        const res = await axios.get(`${BASE_PATH}/${taskId}/rates`);
        return res.data;
    }

    public static async getUserRate(taskId: string): Promise<any> {
        const res = await axios.get(`${BASE_PATH}/${taskId}/my-rate`);
        return res.data;
    }

    public static async deleteTask(taskId: string) {
        await axios.delete(`${BASE_PATH}/${taskId}`);
    }

    public static async editTask(taskId: string, params: EditTaskParams): Promise<TaskResponse> {
        const url = `${BASE_PATH}/${taskId}`;
        const res: AxiosResponse<TaskResponse> = await axios.put(url, params);

        return res.data;
    }

    public static async editTaskDraft(params: any): Promise<void> {
        const url = `${BASE_PATH}/draft`;

        await axios.post(url, params);
    }

    public static async cancelTask(taskId: string): Promise<TaskResponse> {
        const url = `${BASE_PATH}/${taskId}`;
        const res: AxiosResponse<TaskResponse> = await axios.put(url, { status: TaskStatus.Closed });

        return res.data;
    }

    public static async moveToWorkTask(taskId: string): Promise<TaskResponse> {
        const url = `${BASE_PATH}/${taskId}`;
        const res: AxiosResponse<TaskResponse> = await axios.put(url, { status: TaskStatus.InProgress });

        return res.data;
    }

    public static async createTask(params: CreateTaskParams): Promise<TaskAttributes> {
        const { data } = await axios.post<TaskResponse>(BASE_PATH, params);

        return data.task;
    }

    public static async updateTaskParticipants(taskId: string, userIds: number[]): Promise<void> {
        await axios.put(`${BASE_PATH}/${taskId}/participant`, { userIds });
    }

    public static async getTaskComment(
        taskId: string,
        commentId: string,
    ): Promise<{ comment: TaskCommentTransferObject }> {
        const res: AxiosResponse<{ comment: TaskCommentTransferObject }> = await axios.get(
            `${BASE_PATH}/${taskId}/comment/${commentId}`,
        );

        return res.data;
    }

    public static async createComment(taskId: string, commentData: CommentCreateParams): Promise<Comment> {
        const res: AxiosResponse = await axios.post(`${BASE_PATH}/${taskId}/comment`, commentData);

        return res.data.comment;
    }

    public static async updateComment(
        taskId: string,
        commentId: string,
        updates: CommentUpdateParams,
    ): Promise<Comment> {
        const res: AxiosResponse = await axios.put(`${BASE_PATH}/${taskId}/comment/${commentId}`, updates);

        return res.data.comment;
    }

    public static async deleteComment(taskId: string, commentId: string): Promise<void> {
        await axios.delete(`${BASE_PATH}/${taskId}/comment/${commentId}`);
    }

    public static async getDraft(taskId: string) {
        const res = await axios.get(`${BASE_PATH}/${taskId}/draft`);

        return res.data;
    }

    public static async deleteDraftTaskInfo(taskId: string) {
        await axios.post(`${BASE_PATH}/${taskId}/deleteDraftTaskInfo`);
    }

    public static async deleteDraftNewComment(taskId: string) {
        await axios.post(`${BASE_PATH}/${taskId}/deleteDraftNewComment`);
    }

    public static async deleteDraftComment(taskId: string, commentId: string) {
        await axios.post(`${BASE_PATH}/${taskId}/deleteDraftComment/${commentId}`);
    }

    public static async addSubscriber(taskId: string) {
        await axios.put(`${BASE_PATH}/${taskId}/subscription`);
    }

    public static async removeSubscriber(taskId: string) {
        await axios.delete(`${BASE_PATH}/${taskId}/subscription`);
    }

    public static async updateChannel(
        taskId: string,
        channelId: number,
        params: EditChanelParams,
    ): Promise<ChanelParams> {
        const res = await axios.put<ChanelParams>(`${BASE_PATH}/${taskId}/chanel/${channelId}`, params);
        return res.data;
    }

    public static async createChannel(taskId: string, params: EditChanelParams): Promise<ChanelParams> {
        const res = await axios.post<ChanelParams>(`${BASE_PATH}/${taskId}/chanel`, params);
        return res.data;
    }

    public static async deleteChannel(taskId: string, channelId: number): Promise<void> {
        await axios.delete<void>(`${BASE_PATH}/${taskId}/chanel/${channelId}`);
    }

    public static async getTaskFiles(taskId: string, params: GetTaskFilesPayload): Promise<FileResponse[]> {
        const res = await axios.post<FileResponse[]>(`${BASE_PATH}/${taskId}/files`, params);
        return res.data;
    }

    // if reaction doesn't exists, add it, otherwise remove it
    public static async reactToTaskComment(
        taskId: string,
        commentId: string,
        params: ReactToTaskCommentParams,
    ): Promise<any> {
        const res = await axios.post<any>(`${BASE_PATH}/${taskId}/comment/${commentId}/reaction`, params);

        return res.data;
    }
}
