import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { TaskPageSortBy } from 'sber-marketing-types/frontend';

import { StoreState } from '@store';
import { getTaskFilters, setTaskFilters } from '@store/activityTasksPage';

import { DashboardSortingMenuContainer, Props } from './DashboardSortingMenuContainer';

interface MapProps {
    selectedOptionId: React.ReactText;
}

interface DispatchProps {
    onSelect: (selectedOptionId: TaskPageSortBy) => void;
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        selectedOptionId: getTaskFilters(state).sorting,
    };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
    return {
        onSelect: (selectedOptionId: TaskPageSortBy) =>
            dispatch(
                setTaskFilters({
                    sorting: selectedOptionId,
                }),
            ),
    };
}

function mergeProps(mapProps: MapProps, dispatchProps: DispatchProps): Props {
    return {
        options: [
            {
                id: TaskPageSortBy.UNRESOLVED_FIRST,
                title: 'Сначала непросмотренные',
            },
            {
                id: TaskPageSortBy.UPDATING_DATE,
                title: 'По дате обновления',
            },
            {
                id: TaskPageSortBy.CREATION_DATE,
                title: 'По дате создания',
            },
            {
                id: TaskPageSortBy.WORK_TYPE,
                title: 'По виду работ',
            },
            {
                id: TaskPageSortBy.DEADLINE,
                title: 'По дедлайну',
            },
        ],
        selectedOptionId: mapProps.selectedOptionId,
        onSelect: dispatchProps.onSelect,
    };
}

export const ActivityPageSortingMenu = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
)(DashboardSortingMenuContainer);
