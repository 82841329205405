import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { AutopilotStage } from '@store/autopilotTv/types';

import { BriefStageActionsTemplate } from './BriefStageActionsTemplate';
import type { StoreState } from '@store';
import { setPreloaderStatus, setCurrentStage } from '@store/autopilotTv/actions';
import { getBriefStageForm } from '@store/autopilotTv/selectors';
import { Loader, Saver } from '../../modules';

interface Props extends Partial<MapProps & DispatchProps> {
    canEdit: boolean;
}

interface MapProps {
    filledFieldsCount: number;
    hasErrors: boolean;
}

interface DispatchProps {
    setPreloaderStatus: (status: boolean) => void;
    setCurrentStage: (stage: AutopilotStage) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BriefStageActionsBehaviour extends React.PureComponent<Props> {
    private loader: Loader;
    private saver: Saver;

    public constructor(props: Props) {
        super(props);

        this.loader = Loader.getInstance();
        this.saver = Saver.getInstance();
    }

    public render(): JSX.Element {
        return React.createElement(BriefStageActionsTemplate, {
            canEdit: this.props.canEdit,
            hasErrors: this.props.hasErrors,
            filledFieldsCount: this.props.filledFieldsCount,
            onButtonClick: this.onButtonClick,
        });
    }

    @autobind
    protected async onButtonClick() {
        this.props.setPreloaderStatus(true);
        await this.saver.saveBriefForm();
        await this.loader.loadAutopilot();
        this.props.setPreloaderStatus(false);

        this.props.setCurrentStage(AutopilotStage.MediaplanSettings);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const briefForm = getBriefStageForm(state);

    const filledFieldsCount = [
        briefForm.blockId,
        briefForm.productId,
        briefForm.budget,
        briefForm.dateStart,
        briefForm.dateEnd,
        briefForm.targetGroup,
        briefForm.videos.filter((item) => !!item.name && !!item.duration),
    ].filter((item) => item !== null && !lodash.isEmpty(item)).length;

    const hasErrors = [briefForm.budgetError].some((error) => !!error);

    return {
        filledFieldsCount,
        hasErrors,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setPreloaderStatus,
            setCurrentStage,
        },
        dispatch,
    );
}
