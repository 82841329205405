import * as React from 'react';
import classNames from 'classnames';

import * as style from './TitleWithCountIndicator.scss';

const CountIndicator: React.FC<{ count: number }> = ({ count }) => (
    <div className={classNames(style.countIndicator, !count && style.countIndicatorEmpty)}>
        {count || 'Отсутствуют'}
    </div>
);

const Title: React.FC = ({ children }) => <div className={style.title}>{children}</div>;

export const TitleWithCountIndicator: React.FC<{
    title: string;
    count: number;
}> = ({ title, count }) => (
    <div
        className={style.root}
        {...{
            'qa-id': 'budgetExecutionLineModalTitleWithCountIndicator',
        }}
    >
        <Title>{title}</Title>
        <CountIndicator count={count} />
    </div>
);
