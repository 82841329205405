import * as React from 'react';

import * as style from './TotalTable.scss';

import type { CellPosition, ColumnName, ColumnWidths, LineId } from './types';

import { CellsStorage, TableView, CellEvent } from 'sber-marketing-ui';
import { Preloader } from './Preloader';

interface Props {
    loading: boolean;
    headerCellsStorage: CellsStorage<ColumnName, any>;
    tableCellsStorage: CellsStorage<CellPosition, any>;
    columns: ColumnName[];
    leftFixedColumns: ColumnName[];
    rightFixedColumns: ColumnName[];
    fixedWidthColumns: ColumnName[];
    lineIds: LineId[];
    columnWidths: ColumnWidths;
    tableHeight: number;
    tableRef: (component: TableView) => void;
    onCellEvent: (eventType: CellEvent, position: CellPosition) => void;
}

export const TotalTableTemplate = ({
    loading,
    headerCellsStorage,
    tableCellsStorage,
    columns,
    leftFixedColumns,
    rightFixedColumns,
    fixedWidthColumns,
    lineIds,
    columnWidths,
    tableHeight,
    tableRef,
    onCellEvent,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            {!loading && (
                <TableView
                    ref={tableRef}
                    // maxHeight={tableHeight}
                    headerCellsStorage={headerCellsStorage}
                    tableCellsStorage={tableCellsStorage}
                    columns={columns}
                    leftFixedColumns={leftFixedColumns}
                    rightFixedColumns={rightFixedColumns}
                    fixedWidthColumns={fixedWidthColumns}
                    lines={lineIds}
                    columnWidths={columnWidths}
                    lineHeight={32}
                    onCellEvent={onCellEvent}
                />
            )}

            {loading && <Preloader />}
        </div>
    );
};
