// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-MediaplanBudgetTable-TableView-Cursor-_Cursor__root{height:100%;width:100%;position:relative;outline:2px solid #96bae4;outline-offset:-2px}.src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-MediaplanBudgetTable-TableView-Cursor-_Cursor__root:after{position:absolute;top:0;left:0;right:0;bottom:0;display:block;width:100%;height:100%;outline:2px solid #96bae4;outline-offset:-2px;content:''}.src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-MediaplanBudgetTable-TableView-Cursor-_Cursor__mask{position:fixed;z-index:9;top:0;right:0;bottom:0;left:48px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilotTv/StageWidgets/MediaplanStageWidget/MediaplanBudgetTable/TableView/Cursor/Cursor.scss"],"names":[],"mappings":"AAAA,qIACI,WAAY,CACZ,UAAW,CACX,iBAAkB,CAClB,yBAA0B,CAC1B,mBAAoB,CALxB,2IAQQ,iBAAkB,CAClB,KAAM,CACN,MAAO,CACP,OAAQ,CACR,QAAS,CACT,aAAc,CACd,UAAW,CACX,WAAY,CACZ,yBAA0B,CAC1B,mBAAoB,CACpB,UAAW,CACd,qIAID,cAAe,CACf,SAAU,CACV,KAAM,CACN,OAAQ,CACR,QAAS,CACT,SAAU","sourcesContent":[".root {\n    height: 100%;\n    width: 100%;\n    position: relative;\n    outline: 2px solid #96bae4;\n    outline-offset: -2px;\n\n    &:after {\n        position: absolute;\n        top: 0;\n        left: 0;\n        right: 0;\n        bottom: 0;\n        display: block;\n        width: 100%;\n        height: 100%;\n        outline: 2px solid #96bae4;\n        outline-offset: -2px;\n        content: '';\n    }\n}\n\n.mask {\n    position: fixed;\n    z-index: 9;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-MediaplanBudgetTable-TableView-Cursor-_Cursor__root",
	"mask": "src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-MediaplanBudgetTable-TableView-Cursor-_Cursor__mask"
};
export default ___CSS_LOADER_EXPORT___;
