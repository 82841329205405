import * as React from 'react';
import classNames from 'classnames';
import * as lodash from 'lodash';

import * as style from './SelectCell.scss';

import { Icon, IconType, CustomScrollbar_new as CustomScrollbar } from 'sber-marketing-ui';
import { CellEditorWrapper } from '../CellEditorWrapper';
import { SearchInput } from './SearchInput';

const BORDER_WIDTH = 1;
const MAX_ITEMS_SCROLLBAR_HEIGHT = 400;

interface Props {
    title: string;
    description?: string | null;
    iconType?: any;
    columnTitle: string;
    items: SelectItem[];
    selectedValue: React.ReactText;
    customStyle?: any;
    onValueChange: (value: React.ReactText) => void;
}

interface SelectItem {
    title: string;
    value: React.ReactText;
    description?: string | null;
    iconType?: any;
}

export const SelectWithIconCellEdit = ({
    title,
    description,
    iconType,
    columnTitle,
    items,
    selectedValue,
    customStyle,
    onValueChange,
}: Props): JSX.Element => {
    const rootRef = React.useRef(null);
    const [width, setWidth] = React.useState(0);
    const [searchString, setSearchString] = React.useState('');

    const filteredItems = items.filter((item) => item.title.toLowerCase().includes(searchString.toLowerCase()));

    React.useLayoutEffect(() => {
        setWidth(rootRef.current.offsetWidth + BORDER_WIDTH * 2);
    }, []);

    return (
        <div className={style.root} ref={rootRef} style={{ ...customStyle }}>
            {iconType && (
                <div className={style.iconWrapper}>
                    <Icon type={iconType} svgSize={16} />
                </div>
            )}
            <div className={style.container}>
                <div className={style.title}>{title}</div>
                {description && <div className={style.description}>{description}</div>}
            </div>

            <div className={classNames(style.openerIcon, style.opened)}>
                <Icon type={IconType.TRIANGLE8_UP} svgSize={8} />
            </div>

            <CellEditorWrapper>
                <div className={style.content} style={{ width }}>
                    <div className={style.contentTitle}>{columnTitle}</div>

                    {items.length > 10 && (
                        <div className={style.search}>
                            <SearchInput value={searchString} onValueChange={setSearchString} />
                        </div>
                    )}

                    {!lodash.isEmpty(filteredItems) && (
                        <div className={style.items}>
                            <CustomScrollbar maxHeight={MAX_ITEMS_SCROLLBAR_HEIGHT} hideScrollX freezeScrollX>
                                {filteredItems.map((item) => (
                                    <div
                                        key={item.value}
                                        className={style.item}
                                        onClick={() => onValueChange(item.value)}
                                    >
                                        <div className={style.flexCenter}>
                                            {item.iconType && (
                                                <div className={style.iconWrapper}>
                                                    <Icon type={item.iconType} svgSize={16} />
                                                </div>
                                            )}
                                            <div className={style.flexColumn}>
                                                <div className={style.title}>{item.title}</div>
                                                {item.description && (
                                                    <div className={style.description}>{item.description}</div>
                                                )}
                                            </div>
                                        </div>

                                        {item.value === selectedValue && (
                                            <div className={style.checkIcon}>
                                                <Icon type={IconType.CHECK_ICON} svgSize={10} />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </CustomScrollbar>
                        </div>
                    )}

                    {lodash.isEmpty(filteredItems) && <div className={style.noItemsMessage}>Ничего не найдено</div>}
                </div>
            </CellEditorWrapper>
        </div>
    );
};
