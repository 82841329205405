// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-StageWidgets-TableWidget-_TableWidget__root{user-select:none}.src-client-modules-activity-pages-creative-StageWidgets-TableWidget-_TableWidget__topLine{display:flex;margin-bottom:24px}.src-client-modules-activity-pages-creative-StageWidgets-TableWidget-_TableWidget__topLineWidget{width:calc(50% - 8px)}.src-client-modules-activity-pages-creative-StageWidgets-TableWidget-_TableWidget__topLineWidget:first-child{margin-right:auto}.src-client-modules-activity-pages-creative-StageWidgets-TableWidget-_TableWidget__table{position:relative}.src-client-modules-activity-pages-creative-StageWidgets-TableWidget-_TableWidget__summaryAmount{margin:32px 0 32px;width:auto;max-width:900px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/StageWidgets/TableWidget/TableWidget.scss"],"names":[],"mappings":"AAAA,wFACI,gBAAiB,CACpB,2FAGG,YAAa,CACb,kBAAmB,CACtB,iGAGG,qBAAsB,CAD1B,6GAIQ,iBAAkB,CACrB,yFAID,iBAAkB,CACrB,iGAGG,kBAAmB,CACnB,UAAW,CACX,eAAgB","sourcesContent":[".root {\n    user-select: none;\n}\n\n.topLine {\n    display: flex;\n    margin-bottom: 24px;\n}\n\n.topLineWidget {\n    width: calc(50% - 8px);\n\n    &:first-child {\n        margin-right: auto;\n    }\n}\n\n.table {\n    position: relative;\n}\n\n.summaryAmount {\n    margin: 32px 0 32px;\n    width: auto;\n    max-width: 900px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-StageWidgets-TableWidget-_TableWidget__root",
	"topLine": "src-client-modules-activity-pages-creative-StageWidgets-TableWidget-_TableWidget__topLine",
	"topLineWidget": "src-client-modules-activity-pages-creative-StageWidgets-TableWidget-_TableWidget__topLineWidget",
	"table": "src-client-modules-activity-pages-creative-StageWidgets-TableWidget-_TableWidget__table",
	"summaryAmount": "src-client-modules-activity-pages-creative-StageWidgets-TableWidget-_TableWidget__summaryAmount"
};
export default ___CSS_LOADER_EXPORT___;
