import * as React from 'react';
import classNames from 'classnames';

import * as style from './FinalStageActions.scss';

import { Preloader } from 'sber-marketing-ui';
import { ConfirmModal } from '../../ConfirmModal';

interface Props {
    isLoading: boolean;
    canLaunchAutopilot: boolean;
    displayConfirmModal: boolean;
    onLaunchButtonClick: () => void;
    onModalConfirm: () => void;
    onModalCancel: () => void;
}

export const FinalStageActionsTemplate = ({
    isLoading,
    canLaunchAutopilot,
    displayConfirmModal,
    onLaunchButtonClick,
    onModalConfirm,
    onModalCancel,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div
                className={classNames(style.button, (isLoading || !canLaunchAutopilot) && style.disabled)}
                onClick={!isLoading && canLaunchAutopilot ? onLaunchButtonClick : null}
            >
                Запустить размещения
            </div>

            {displayConfirmModal && (
                <ConfirmModal
                    title={'Внимание!'}
                    message={'Перед запуском размещений проверьте параметры рекламной кампании'}
                    closeButtonText={'Проверить'}
                    confirmButtonText={'Запустить'}
                    onCloseButtonClick={onModalCancel}
                    onConfirmButtonClick={onModalConfirm}
                />
            )}

            {isLoading && <Preloader />}
        </div>
    );
};
