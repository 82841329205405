import { ActivityParams } from 'sber-marketing-types/frontend';
import { ActivityQuery } from 'sber-marketing-types/backend';

import { IDPathParams, rtkApi } from './rtkApi';

export const { useGetActivityQuery, useSwitchActivityFavouriteMutation } = rtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getActivity: builder.query<ActivityParams, ActivityQuery & IDPathParams<number>>({
            query: ({ id }) => `/activity/${id}`,
            providesTags: ({ id }) => [{ type: 'activity', id }],
        }),
        switchActivityFavourite: builder.mutation<void, IDPathParams<number>>({
            query: ({ id }) => ({
                url: `/activity/${id}/switchFavorite`,
                method: 'POST',
            }),
            invalidatesTags: (_, __, { id }) => [{ type: 'activity', id }],
        }),
    }),
});
