import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import { TogglePosition } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { userHasBudgetAccess } from '@store/user';
import {
    SelectedCard,
    CardType,
    CardsLoadingMode,
    CardsLoadingModeFilter,
    getCardsLoadingModeFilter,
    setCardsLoadingModeFilter,
    setSelectedCard,
    setCardIdRequestInProgress,
} from '@store/dashboardPage';

import { Card } from '../../types';
import { CardGroup, MIN_COLUMN_WIDTH, MARGIN } from './CardGroup';

interface Props extends Partial<MapProps & DispatchProps> {
    title: string;
    cards: Card[];
    cardType: CardType;
    loading: boolean;
    totalCardsCount: number;
    deleteRequest: (requestId: string) => Promise<void>;
    updateCards: () => Promise<void>;
}

interface MapProps {
    cardsLoadingMode: CardsLoadingMode;
    userHasBudgetAccess: boolean;
}

interface DispatchProps {
    setCardsLoadingModeFilter: (filter: CardsLoadingModeFilter) => void;
    setSelectedCard: (card: SelectedCard) => void;
    setCardIdRequestInProgress: (cardId: string) => void;
}

interface State {
    columnsCount: number;
    cardOnDeleteConfirmation: Card;
    isOpened: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class CardGroupContainer extends React.PureComponent<Props, State> {
    private rootRef: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: Props) {
        super(props);

        this.state = {
            columnsCount: this.getColumnsCount(),
            cardOnDeleteConfirmation: null,
            isOpened: true,
        };
    }

    public componentDidMount() {
        this.updateColumnsCount();

        window.addEventListener('resize', this.onPageResize);
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.onPageResize);
    }

    public render(): JSX.Element {
        const switchPosition =
            this.props.cardsLoadingMode == CardsLoadingMode.Current ? TogglePosition.LEFT : TogglePosition.RIGHT;

        return React.createElement(CardGroup, {
            title: this.props.title,
            cards: this.props.cards,
            cardOnDeleteConfirmation: this.state.cardOnDeleteConfirmation,
            columnsCount: this.state.columnsCount,
            switchPosition,
            loading: this.props.loading,
            isOpened: this.state.isOpened,
            totalCardsCount: this.props.totalCardsCount,
            userHasBudgetAccess: this.props.userHasBudgetAccess,
            onOpenerClick: this.onOpenerClick,
            onCardDeleteClick: this.onCardDeleteClick,
            onCardDeleteConfirm: this.onCardDeleteConfirm,
            onCardDeleteCancel: this.onCardDeleteCancel,
            onSwitch: this.onSwitch,
            rootRef: this.rootRef,
        });
    }

    @autobind
    protected onOpenerClick() {
        this.setState({
            isOpened: !this.state.isOpened,
        });
    }

    @autobind
    protected onCardDeleteClick(cardId: string) {
        const card = this.props.cards.find((item) => item.id === cardId);

        this.setState({
            cardOnDeleteConfirmation: card,
        });
    }

    @autobind
    protected async onCardDeleteConfirm() {
        const requestId = this.state.cardOnDeleteConfirmation.id;

        this.setState(
            {
                cardOnDeleteConfirmation: null,
            },
            async () => {
                this.props.setSelectedCard(null);
                this.props.setCardIdRequestInProgress(requestId);

                await this.props.deleteRequest(requestId);
                await this.props.updateCards();

                this.props.setCardIdRequestInProgress(null);
            },
        );
    }

    @autobind
    protected onCardDeleteCancel() {
        this.setState({
            cardOnDeleteConfirmation: null,
        });
    }

    @autobind
    protected onSwitch(position: TogglePosition) {
        switch (position) {
            case TogglePosition.LEFT:
                this.setCardsLoadingMode(CardsLoadingMode.Current);
                break;

            case TogglePosition.RIGHT:
                this.setCardsLoadingMode(CardsLoadingMode.Archive);
                break;
        }
    }

    @autobind
    protected onPageResize() {
        this.updateColumnsCount();
    }

    private setCardsLoadingMode(mode: CardsLoadingMode) {
        this.props.setCardsLoadingModeFilter({
            [this.props.cardType]: mode,
        });
    }

    private updateColumnsCount() {
        const newColumnsCount = this.getColumnsCount();

        if (newColumnsCount !== this.state.columnsCount) {
            this.setState({
                columnsCount: newColumnsCount,
            });
        }
    }

    private getColumnsCount(): number {
        let columnsCount = 1;

        if (this.rootRef.current) {
            const rootWidth = this.rootRef.current.offsetWidth;

            let width = rootWidth - MARGIN * 2 - MIN_COLUMN_WIDTH;

            while (width >= MIN_COLUMN_WIDTH + MARGIN) {
                width -= MIN_COLUMN_WIDTH + MARGIN;

                columnsCount += 1;
            }
        }

        return columnsCount;
    }
}

function mapStateToProps(state: StoreState, { cardType }: Props): MapProps {
    const cardsLoadingModeFilter = getCardsLoadingModeFilter(state);

    return {
        cardsLoadingMode: cardsLoadingModeFilter[cardType],
        userHasBudgetAccess: userHasBudgetAccess(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setCardsLoadingModeFilter,
            setSelectedCard,
            setCardIdRequestInProgress,
        },
        dispatch,
    );
}
