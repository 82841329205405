import * as React from 'react';
import { throttle } from 'lodash';
import autobind from 'autobind-decorator';

import { Icon, IconType } from 'sber-marketing-ui';
import { Mode } from './types';

import * as style from './BudgetWindow.scss';

const HEIGHT_HEADER = 48;
const WIDTH_LEFT_SIDEBAR = 48;
const WIDTH_RIGHT_SIDEBAR = 576;
const PADDING = 12;

interface MoveArea {
    top: number;
    right: number;
    bottom: number;
    left: number;
}

interface Props {
    title: string;
    mode: Mode;
    parentRef: React.RefObject<HTMLDivElement>;
    children: React.ReactNode;
    onCloseButtonClick: () => void;
}

interface State {
    isDrag: boolean;
}

export class BudgetWindow extends React.Component<Props, State> {
    private ref: React.RefObject<HTMLDivElement> = React.createRef();

    private shiftX: number;
    private shiftY: number;

    private moveArea: MoveArea;
    private onMouseMoveWithThrottle: (event: MouseEvent) => void;

    constructor(props: Props) {
        super(props);

        this.state = { isDrag: false };
        this.onMouseMoveWithThrottle = throttle(this.onMouseMove, 16);
        this.moveArea = this.getMoveArea();

        this.shiftX = 0;
        this.shiftY = 0;
    }

    public componentDidMount() {
        this.initStartPosition();
        this.initHandlers();
    }

    public componentWillUnmount() {
        this.removeHandlers();
    }

    public render() {
        const { title, mode, onCloseButtonClick, children } = this.props;

        return (
            <div ref={this.ref} className={style.root}>
                <div className={style.header}>
                    <div className={style.title}>{title}</div>

                    {mode === Mode.DRAG_WINDOW && (
                        <div className={style.crossIconWrapper} onClick={onCloseButtonClick}>
                            <Icon type={IconType.THIN_CROSS} svgSize={24} />
                        </div>
                    )}
                </div>

                <div>{children}</div>
            </div>
        );
    }

    @autobind
    private onMouseMove(event: MouseEvent): void {
        const { isDrag } = this.state;

        if (isDrag) {
            const dragElementPosition = {
                top: event.pageY - this.shiftY,
                left: event.pageX - this.shiftX,
            };

            if (dragElementPosition.top < this.moveArea.top) {
                dragElementPosition.top = this.moveArea.top;
            }

            if (dragElementPosition.left < this.moveArea.left) {
                dragElementPosition.left = this.moveArea.left;
            }

            if (dragElementPosition.left + this.dragElement.offsetWidth > this.moveArea.right) {
                dragElementPosition.left = this.moveArea.right - this.dragElement.offsetWidth;
            }

            if (dragElementPosition.top + this.dragElement.offsetHeight > this.moveArea.bottom) {
                dragElementPosition.top = this.moveArea.bottom - this.dragElement.offsetHeight;
            }

            this.dragElement.style.top = `${dragElementPosition.top}px`;
            this.dragElement.style.left = `${dragElementPosition.left}px`;
        }
    }

    @autobind
    private onMouseDown(event: MouseEvent): void {
        this.shiftX = event.pageX - this.dragElement.getBoundingClientRect().left;
        this.shiftY = event.pageY - this.dragElement.getBoundingClientRect().top;

        this.setState({ isDrag: true });
    }

    @autobind
    private onMouseUp(): void {
        this.setState({ isDrag: false });
    }

    private initHandlers(): void {
        document.addEventListener('mousemove', this.onMouseMoveWithThrottle);
        document.addEventListener('mousedown', this.onMouseDown);
        document.addEventListener('mouseup', this.onMouseUp);
    }

    private removeHandlers(): void {
        document.removeEventListener('mousemove', this.onMouseMoveWithThrottle);
        document.removeEventListener('mousedown', this.onMouseDown);
        document.removeEventListener('mouseup', this.onMouseUp);
    }

    private get dragElement(): HTMLDivElement {
        return this.ref.current;
    }

    private initStartPosition(): void {
        const parentElement = this.props.parentRef.current;

        const positionedElementRect = this.dragElement.getBoundingClientRect();
        const parentElementRect = parentElement.getBoundingClientRect();
        const viewportHeight = document.documentElement.clientHeight;

        const innerElementTop = parentElementRect.top + parentElementRect.height / 2;

        const positionedElementPosition = {
            top: innerElementTop - positionedElementRect.height / 2 + PADDING,
            right: WIDTH_RIGHT_SIDEBAR + PADDING,
            bottom: innerElementTop + positionedElementRect.height / 2 + PADDING,
        };

        if (positionedElementPosition.bottom > viewportHeight) {
            positionedElementPosition.top = viewportHeight - positionedElementRect.height - PADDING;
        }

        if (positionedElementPosition.top <= HEIGHT_HEADER + PADDING) {
            positionedElementPosition.top = HEIGHT_HEADER + PADDING;
        }

        this.dragElement.style.top = `${positionedElementPosition.top}px`;
        this.dragElement.style.right = `${positionedElementPosition.right}px`;
    }

    private getMoveArea(): MoveArea {
        return {
            top: HEIGHT_HEADER + PADDING,
            right: document.documentElement.clientWidth - WIDTH_RIGHT_SIDEBAR - PADDING,
            bottom: document.documentElement.clientHeight - PADDING,
            left: WIDTH_LEFT_SIDEBAR + PADDING,
        };
    }
}
