import * as React from 'react';

import { Icon, IconType } from 'sber-marketing-ui';
import * as style from './BudgetCard.scss';

export interface Props {
    id: string;
    serialNumber: number;
    budgetYear: number;
    date: string;
    sapComment: string;
    block: string;
    division: string;
    planSum: string | null;
    reserveSum: string | null;
    factSum: string | null;
    onRemoveButtonClick: (id: string) => void;
}

export function BudgetCardTemplate({
    id,
    serialNumber,
    budgetYear,
    date,
    sapComment,
    block,
    division,
    planSum,
    reserveSum,
    factSum,
    onRemoveButtonClick,
}: Props) {
    return (
        <div className={style.root}>
            <div className={style.topLine}>
                <div className={style.title}>
                    ID {serialNumber}
                    &nbsp;
                    <span className={style.budgetYear}>({budgetYear})</span>
                </div>

                <div className={style.date}>
                    <div className={style.dateIcon}>
                        <Icon type={IconType.CALENDAR_ICON} svgSize={16} />
                    </div>

                    {date}
                </div>
            </div>

            <div className={style.properties}>
                <div className={style.property}>
                    Название проекта (комментарий для SAP):
                    <div className={style.value}>{sapComment}</div>
                </div>

                <div className={style.property}>
                    Блок:&nbsp;
                    <span className={style.value}>{block}</span>
                </div>

                <div className={style.property}>
                    Дивизион:&nbsp;
                    <span className={style.value}>{division}</span>
                </div>
            </div>

            {planSum !== null && reserveSum !== null && factSum !== null && (
                <div className={style.bottomLine}>
                    <div className={style.funds}>
                        <div className={style.fundsTitle}>План, тыс. ₽</div>

                        <div className={style.fundsValue}>{planSum}</div>
                    </div>

                    <div className={style.funds}>
                        <div className={style.fundsTitle}>Резерв, тыс. ₽</div>

                        <div className={style.fundsValue}>{reserveSum}</div>
                    </div>

                    <div className={style.funds}>
                        <div className={style.fundsTitle}>Факт, тыс. ₽</div>

                        <div className={style.fundsValue}>{factSum}</div>
                    </div>
                </div>
            )}

            <div className={style.removeButton}>
                <Icon type={IconType.CIRCLE_BOLD_FILLED_CROSS} svgSize={15} onClick={() => onRemoveButtonClick(id)} />
            </div>
        </div>
    );
}
