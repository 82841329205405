import * as React from 'react';
import classNames from 'classnames';

import { ColumnName, CustomColumnColor } from '@store/budgetPlanning/types';

import { CellBackgroundColor } from '../../LayerManager';
import { getQaCellColor } from '../misc';

import * as style from './TextCell.scss';

interface Props {
    columnName: ColumnName;
    title: string;
    tooltip?: string;
    bgColor?: CellBackgroundColor | CustomColumnColor;
    disabled?: boolean;
}

export const TextCell = ({ columnName, title, tooltip, bgColor, disabled }: Props): JSX.Element => {
    return (
        <div
            className={classNames(style.root, disabled && style.disabled)}
            style={{ backgroundColor: bgColor }}
            title={tooltip}
            {...{
                'qa-id': 'tableCell',
                'qa-index': columnName,
                'qa-disabled': disabled ? 'true' : 'false',
                ...getQaCellColor(bgColor),
            }}
        >
            <div
                className={style.title}
                {...{
                    'qa-id': 'tableCellContent',
                }}
            >
                {title || '–'}
            </div>
        </div>
    );
};
