import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { v4 as isUuidV4 } from 'is-uuid';
import { isNil, isEmpty, sortBy } from 'lodash';
import { DepartmentAttributes, WorkTypeParams } from 'sber-marketing-types/frontend';

import { DepartmentApi, WorkTypeApi } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getLoginUser } from '@store/user';
import * as workTypesStore from '@store/workTypes';

import { loadDepartments as loadDepartmentsAction } from './actions';
import { getWorkTypeId } from '../selectors';
import { getAvailableDepartments } from './selectors';

/** Find work type by it's identifier (at store or at server) */
const getWorkType = async (workTypeId: string, getState: () => StoreState): Promise<WorkTypeParams | undefined> => {
    const state = getState();

    const isLoaded =
        workTypesStore.getLoadingStatus(state, workTypesStore.StoreTypes.CURRENT_ORGANIZATION) === LoadingStatus.LOADED;
    let workType: WorkTypeParams | undefined;

    if (isLoaded) {
        workType = workTypesStore.getWorkTypeById(state, workTypeId);
    } else {
        const workTypes = await WorkTypeApi.getList({
            organizationId: getLoginUser(state).attributes.organizationId,
        });
        workType = workTypes && workTypes.find(({ id }) => id === workTypeId);
    }
    return workType;
};

/** Load departments by work type identifier */
export const loadDepartments = bindThunkAction<StoreState, string | null | undefined, DepartmentAttributes[], Error>(
    loadDepartmentsAction,
    async (workTypeId, _, getState) => {
        let result: DepartmentAttributes[] = getAvailableDepartments(getState());
        const currentWorkTypeId = getWorkTypeId(getState());

        if (isUuidV4(workTypeId) && workTypeId !== currentWorkTypeId) {
            const workType = await getWorkType(workTypeId, getState);
            if (!isNil(workType) && !isEmpty(workType.departmentIds)) {
                result = sortBy(
                    await DepartmentApi.getList({ ids: workType.departmentIds, embed: 'subDepartments' }),
                    'name',
                );
            } else {
                result = [];
            }
        }

        return result;
    },
);
