import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { Autopilot } from 'sber-marketing-types/backend';
import type { LinkedBudgetItem } from './WithLinkedBudgetItems/types';

import { FinalStageActionsTemplate } from './FinalStageActionsTemplate';
import { Saver } from '../../modules';

interface Props extends Partial<RouteComponentProps> {
    loading: boolean;
    canEdit: boolean;
    autopilot: Autopilot;
    linkedBudgetItems: LinkedBudgetItem[];
}

interface State {
    isLoading: boolean;
    displayConfirmModal: boolean;
}

@(withRouter as any)
export class FinalStageActionsBehaviour extends React.PureComponent<Props, State> {
    private saver: Saver;

    public constructor(props: Props) {
        super(props);

        this.saver = Saver.getInstance();

        this.state = {
            isLoading: false,
            displayConfirmModal: false,
        };
    }

    public render(): JSX.Element {
        return React.createElement(FinalStageActionsTemplate, {
            isLoading: this.state.isLoading,
            canLaunchAutopilot: this.canLaunchAutopilot(),
            displayConfirmModal: this.state.displayConfirmModal,
            onLaunchButtonClick: this.onLaunchButtonClick,
            onModalConfirm: this.onModalConfirm,
            onModalCancel: this.onModalCancel,
        });
    }

    @autobind
    protected async onLaunchButtonClick() {
        this.setState({
            displayConfirmModal: true,
        });
    }

    @autobind
    protected async onModalCancel() {
        this.setState({
            displayConfirmModal: false,
        });
    }

    @autobind
    protected async onModalConfirm() {
        this.setState({ isLoading: true, displayConfirmModal: false });
        await this.saver.launchPlacements();
        this.redirectToProjectPage();
        this.setState({ isLoading: false });
    }

    private canLaunchAutopilot(): boolean {
        const { canEdit, autopilot } = this.props;

        if (!autopilot) {
            return false;
        }

        const hasPlacements = !lodash.isEmpty(autopilot.placements) && autopilot.placements.length !== 0;

        return canEdit && !hasPlacements && this.checkStagesStatus();
    }

    private checkStagesStatus(): boolean {
        const { autopilot, linkedBudgetItems, loading } = this.props;

        if (!autopilot || loading) {
            return false;
        }

        const { brief, mediaplan, budget, creative } = autopilot;

        const briefIsFinished = brief
            ? [
                  brief.block,
                  brief.product,
                  brief.budget,
                  brief.dateStart,
                  brief.dateEnd,
                  brief.age_from,
                  brief.age_to,
              ].every((item) => (typeof item == 'number' ? item !== null : !lodash.isEmpty(item)))
            : false;

        const mediaplanIsFinished = mediaplan ? !lodash.isEmpty(mediaplan.url) : false;

        const autopilotBudgetItemIds = lodash.get(budget, 'budgetIds') || [];

        const budgetIsFinished = linkedBudgetItems.some(
            (item) => autopilotBudgetItemIds.includes(item.budgetItem.id) && item.status == 'approved',
        );

        const creativeIsFinished = creative
            ? [
                  creative.vk,
                  // creative.fb,
                  creative.ok,
                  // creative.youtube
              ].every((item) => !lodash.isEmpty(item))
            : false;

        return briefIsFinished && mediaplanIsFinished && budgetIsFinished && creativeIsFinished;
    }

    private redirectToProjectPage() {
        const { autopilot } = this.props;

        this.openUrlInSameTab(`/activity/${autopilot.activityId}`);
    }

    private openUrlInSameTab(url: string) {
        this.props.history.push(url);
    }
}
