import * as React from 'react';
import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import {
    InternalTransferDirection,
    getBudgetTransferMenuState,
    isCellSelected,
    canMoveAcceptorCellToDonor,
    checkCellsEquality,
} from '@store/budgetExecution/budgetTransferMenu';

import { CellTransferDirectionToggle } from '../TransferDirectionToggle';
import {
    CellDescriptionThemes,
    CellDescriptionTemplates,
    CellDescription as GeneralCellDescription,
    CellDescriptionStub,
} from '../../CellDescription';

function useSingleCell() {
    return useSelector((state: StoreState) => {
        const {
            cells: { from, to },
            controls: { internalTransferDirection },
        } = getBudgetTransferMenuState(state);

        switch (internalTransferDirection) {
            case InternalTransferDirection.OneToMany:
                return from[0];
            case InternalTransferDirection.ManyToOne:
                return to[0];
            default:
                return null;
        }
    });
}

function useTemplate() {
    const internalTransferDirection = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.internalTransferDirection,
    );
    const isTransferDirectionToggleHovered = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.isTransferDirectionToggleHovered,
    );
    const canMoveAcceptorCellToDonor_ = useSelector((state: StoreState) => canMoveAcceptorCellToDonor(state));

    switch (internalTransferDirection) {
        case InternalTransferDirection.OneToMany:
            return CellDescriptionTemplates.CellFullWithLinePlanReserveDiff;
        case InternalTransferDirection.ManyToOne:
            return isTransferDirectionToggleHovered && !canMoveAcceptorCellToDonor_
                ? CellDescriptionTemplates.CellWarningCannotTurnToDonor
                : CellDescriptionTemplates.CellFullWithLineReservePlanDiff;
        default:
            return null;
    }
}

export function CellDescription(): JSX.Element {
    return (
        <React.Fragment>
            <CellTransferDirectionToggle />

            <SelectedCellDescription />
            <HoveredCellDescription />
            <CellStub />
        </React.Fragment>
    );
}

function SelectedCellDescription() {
    const cell = useSingleCell();
    const template = useTemplate();
    const shouldRender = isCellSelected(cell);

    return shouldRender ? (
        <GeneralCellDescription
            qaId="internalTransitionLineSelectionSelectedCell"
            useMonth
            cell={cell}
            template={template}
        />
    ) : null;
}

function HoveredCellDescription() {
    const hoveredCell = useSelector((state: StoreState) => getBudgetTransferMenuState(state).cells.hoveredCell);
    const shouldRender = useSelector((state: StoreState) => {
        if (!isCellSelected(hoveredCell)) {
            return false;
        }

        const {
            controls: { internalTransferDirection },
            cells: { from, to },
        } = getBudgetTransferMenuState(state);

        switch (internalTransferDirection) {
            case InternalTransferDirection.OneToMany:
                return !checkCellsEquality(hoveredCell, from[0]);
            case InternalTransferDirection.ManyToOne:
                return !checkCellsEquality(hoveredCell, to[0]);
            default:
                return false;
        }
    });
    const template = useTemplate();

    return shouldRender ? (
        <GeneralCellDescription
            qaId="internalTransitionLineSelectionHoveredCell"
            useMonth
            cell={hoveredCell}
            template={template}
            theme={CellDescriptionThemes.Transparent}
        />
    ) : null;
}

function CellStub() {
    const cell = useSingleCell();
    const hoveredCell = useSelector((state: StoreState) => getBudgetTransferMenuState(state).cells.hoveredCell);
    const internalTransferDirection = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.internalTransferDirection,
    );

    const cellIsSelected = isCellSelected(cell);
    const hoveredCellIsSelected = isCellSelected(hoveredCell);
    const shouldRender = !(cellIsSelected || hoveredCellIsSelected);

    let text: string;
    switch (internalTransferDirection) {
        case InternalTransferDirection.OneToMany:
            text = 'Выберите ячейку, из которой хотите перенести бюджет';
            break;
        case InternalTransferDirection.ManyToOne:
            text = 'Выберите ячейку, в которую хотите перенести бюджет';
            break;
        default:
            break;
    }

    return shouldRender ? (
        <CellDescriptionStub large qaId="internalTransitionLineSelectionCellStub" text={text} />
    ) : null;
}
