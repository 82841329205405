import * as React from 'react';

import autobind from 'autobind-decorator';

import { Mode, Correction, CardBudgetItem, FieldName } from './types';

import { Params, InfoBlockParams } from './Params';

interface Props {
    canEditBudgetItem: boolean;
    fields: Record<FieldName, InfoBlockParams>;
    corrections: Correction[];
    budgetItem: CardBudgetItem;
    hoveredCorrectionCardId: string;
    updateCorrections: () => Promise<void>;
    updateCard: () => Promise<void>;
}

interface State {
    mode: Mode;
    isTooltipVisible: boolean;
}

export class ParamsContainer extends React.Component<Props, State> {
    private ref: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: Props) {
        super(props);

        this.state = {
            mode: Mode.TOOLTIP,
            isTooltipVisible: false,
        };
    }

    public render() {
        return (
            <Params
                {...this.props}
                {...this.state}
                refObject={this.ref}
                onPinButtonClick={this.onPinButtonClick}
                onCloseButtonClick={this.onCloseButtonClick}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            />
        );
    }

    @autobind
    private onPinButtonClick(): void {
        this.setState((state) => ({
            mode: state.mode === Mode.TOOLTIP ? Mode.DRAG_WINDOW : Mode.TOOLTIP,
            isTooltipVisible: false,
        }));
    }

    @autobind
    private onCloseButtonClick(): void {
        this.setState({
            mode: Mode.TOOLTIP,
            isTooltipVisible: false,
        });
    }

    @autobind
    private onMouseEnter(): void {
        this.setState({ isTooltipVisible: true });
    }

    @autobind
    private onMouseLeave(): void {
        this.setState({ isTooltipVisible: false });
    }
}
