import { includes } from 'lodash';

import { TaskPageState, RemoveNewTaskAssetPayload } from '../types';
import { FileAsset } from '../../commonTypes';

export const addNewTaskAssetReducer = (
    { assetsIds, ...restState }: TaskPageState,
    asset: FileAsset,
): TaskPageState => ({
    ...restState,
    assetsIds: includes(assetsIds, asset.name) ? assetsIds : assetsIds.concat([asset.name]),
});

export const replaceNewTaskAssetsReducer = (
    { assetsIds, ...restState }: TaskPageState,
    assets: FileAsset[],
): TaskPageState => ({
    ...restState,
    assetsIds: assets.map(({ name }) => name),
});

export const removeNewTaskAssetReducer = (
    { assetsIds, ...restState }: TaskPageState,
    { fileName }: RemoveNewTaskAssetPayload,
): TaskPageState => ({
    ...restState,
    assetsIds: assetsIds.filter((name) => name !== fileName),
});
