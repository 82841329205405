import { first } from 'lodash';

import { FileAsset } from '../commonTypes';
import { addList, addOne, removeList, removeOne, clearList } from './actions';

// Universal wrappers for action creators

/**
 * Add one or more asset to store.
 * If asset (or some assets from several) with same identifier(s) already exists, it/they will be replaced by new,
 * so you can use this action to update existing asset(s).
 */
export const add = (...assets: FileAsset[]) => (assets.length === 1 ? addOne(first(assets)!) : addList(assets));

/** Remove one or more assets from store */
export const remove = (...ids: string[]) => (ids.length === 1 ? removeOne(first(ids)!) : removeList(ids));

export * from './reducer';
export * from './selectors';
export * from './thunks';
export * from './types';
export { clearList } from './actions';
