import * as React from 'react';
import * as lodash from 'lodash';
import classNames from 'classnames';
import { UserResponseParams } from 'sber-marketing-types/frontend';

import * as style from './TableBody.scss';

import type {
    TableLine,
    TableLineGroup,
    TotalBudgets,
    ColumnsVisiblityFilter,
    ChangeList,
    LineStatusChanges,
} from '@store/budgetPlanning/types';
import { ColumnName } from '@store/budgetPlanning/types';

import { Line, LINE_MENU_WIDTH } from '../Line';
import { TotalLine } from '../TotalLine';
import { ColumnsList } from '../../ColumnsConfig';
import { FrameManager, PlainFrameManager, GroupedFrameManager } from '../FrameManager';
import { LineCellsParams } from '../LayerManager';

const SUMLINE_HEIGHT = 57;

export const enum LineColor {
    White = '255,255,255',
    Grey = '248,248,248',
    Draft = '255,250,216',
    Disabled = '241,245,247',
    Approved = '221,245,229',
    Rejected = '254,221,221',
    // Selected = '240,240,240',
    // SelectedDraft = '243,235,185',
    // SelectedApproved = '205,236,215',
    // SelectedRejected = '244,202,202',
    // SelectedDisabled = '232,236,239'
}

// Used in Line['qa-line-color']
export const LineColorName: Record<LineColor, string> = {
    [LineColor.White]: 'White',
    [LineColor.Grey]: 'Grey',
    [LineColor.Draft]: 'Draft',
    [LineColor.Disabled]: 'Disabled',
    [LineColor.Approved]: 'Approved',
    [LineColor.Rejected]: 'Rejected',
};

interface Props {
    frameManager: FrameManager;
    cellsParams: { [lineId: string]: LineCellsParams };
    currentFrameIndex: number;
    fixedColumnsNames: ColumnName[];
    activitiesTotalBudgets: TotalBudgets;
    unsavedChanges: ChangeList;
    columnsVisiblityFilter: ColumnsVisiblityFilter;
    rootWidth: number;
    columnsWidth: { [columnName: string]: number };
    visibleColumnsNames: ColumnName[];
    hoveredLineId: string;
    hoveredColumnName: ColumnName;
    draggedEdgeColumnName: ColumnName;
    lineStatusChanges: LineStatusChanges;
    userIsBudgetExpert: boolean;
    userIsSupervisor: boolean;
    allUsers: UserResponseParams[];
    userCanEditOrCreateBudgetData: boolean;
    fixedColumnsLinesRef: (element: HTMLDivElement) => void;
    totalLinesContentRef: (element: HTMLDivElement) => void;
    onLineMouseEnter: (budgetId: string) => void;
    onLineMouseLeave: () => void;
    onEditActivityButtonClick: (planId: string) => void;
    onCopyActivityButtonClick: (planId: string) => void;
    onInfoMouseEnter: (lineId: string) => void;
    onInfoMouseLeave: () => void;
    onInputValueChange: (budgetItemId: string, columnName: ColumnName, value: string, originalValue: string) => void;
    onDropdownCellClick: (
        columnName: ColumnName,
        budgetItemId: string,
        dropdownContent: JSX.Element,
        contentHeight: number,
    ) => void;
    onDropdownItemSelection: (budgetItemId: string, columnName: ColumnName, id: string, originalId: string) => void;
    onDatepickerValueChange: (budgetItemId: string, columnName: ColumnName, value: Date, originalValue: Date) => void;
    onApproveButtonClick: (lineId: string) => void;
    onRejectButtonClick: (lineId: string) => void;
    onCellCopy: (lineId: string, columnName: ColumnName, event: React.ClipboardEvent<HTMLDivElement>) => void;
}

export const TableBody = ({
    frameManager,
    cellsParams,
    currentFrameIndex,
    fixedColumnsNames,
    activitiesTotalBudgets,
    unsavedChanges,
    columnsVisiblityFilter,
    rootWidth,
    columnsWidth,
    visibleColumnsNames,
    hoveredLineId,
    hoveredColumnName,
    draggedEdgeColumnName,
    lineStatusChanges,
    userIsBudgetExpert,
    userIsSupervisor,
    allUsers,
    userCanEditOrCreateBudgetData,
    fixedColumnsLinesRef,
    totalLinesContentRef,
    onLineMouseEnter,
    onLineMouseLeave,
    onEditActivityButtonClick,
    onCopyActivityButtonClick,
    onInfoMouseEnter,
    onInfoMouseLeave,
    onInputValueChange,
    onDropdownCellClick,
    onDropdownItemSelection,
    onDatepickerValueChange,
    onApproveButtonClick,
    onRejectButtonClick,
    onCellCopy,
}: Props): JSX.Element => {
    const allColumnsAreHidden = lodash.every(columnsVisiblityFilter, (item) => item === false);

    const filteredColumns = allColumnsAreHidden
        ? ColumnsList
        : ColumnsList.filter((column) => columnsVisiblityFilter[column.name]);

    const tableHeight = frameManager ? frameManager.getTableHeight() : null;

    const lineWidth = lodash.sumBy(filteredColumns, (item) => columnsWidth[item.name]) + LINE_MENU_WIDTH;

    return (
        <div
            className={style.root}
            style={{
                height: tableHeight + SUMLINE_HEIGHT,
                width: lineWidth,
            }}
        >
            {frameManager instanceof PlainFrameManager && (
                <div
                    className={style.frame}
                    style={{
                        top: frameManager.getFrameByIndex(currentFrameIndex).position,
                        height: frameManager.getFrameByIndex(currentFrameIndex).getHeight(),
                    }}
                >
                    {frameManager
                        .getFrameByIndex(currentFrameIndex)
                        .lines.map((line, index) =>
                            renderLine(line, index % 2 == 0 ? LineColor.White : LineColor.Grey),
                        )}
                </div>
            )}

            {frameManager instanceof GroupedFrameManager && (
                <div
                    className={style.frame}
                    style={{
                        top: frameManager.getFrameByIndex(currentFrameIndex).position,
                        height: frameManager.getFrameByIndex(currentFrameIndex).getHeight(),
                    }}
                >
                    {frameManager.getFrameByIndex(currentFrameIndex).groups.map((group) => renderLineGroup(group))}
                </div>
            )}
        </div>
    );

    function renderLineGroup(group: TableLineGroup) {
        const { activityId, lines, canEdit } = group;

        const activityTotalBudget = activitiesTotalBudgets[activityId];

        const { fields } = lodash.first(lines);

        const activityName = fields[ColumnName.ActivityName] as string;

        return (
            <React.Fragment key={activityId}>
                {lines.map((line) => renderLine(line, LineColor.White))}

                {renderTotalLine(activityId, activityName, activityTotalBudget, canEdit)}
            </React.Fragment>
        );
    }

    function renderLine(line: TableLine, lineColor: LineColor) {
        const lineUnsavedStatus = lineStatusChanges[line.id] ? lineStatusChanges[line.id].status : null;

        const lineHasUnsavedChanges = unsavedChanges[line.id] && unsavedChanges[line.id].length > 0;

        return (
            <div className={style.line} key={line.id}>
                <Line
                    line={line}
                    cellsParams={cellsParams}
                    columns={filteredColumns}
                    visibleColumnsNames={visibleColumnsNames}
                    fixedColumnsNames={fixedColumnsNames}
                    columnsWidth={columnsWidth}
                    width={rootWidth}
                    lineColor={lineColor}
                    hoveredLineId={hoveredLineId}
                    hoveredColumnName={hoveredColumnName}
                    draggedEdgeColumnName={draggedEdgeColumnName}
                    lineUnsavedStatus={lineUnsavedStatus}
                    lineHasUnsavedChanges={lineHasUnsavedChanges}
                    userIsBudgetExpert={userIsBudgetExpert}
                    userIsSupervisor={userIsSupervisor}
                    allUsers={allUsers}
                    fixedColumnsLinesRef={fixedColumnsLinesRef}
                    onMouseEnter={onLineMouseEnter}
                    onMouseLeave={onLineMouseLeave}
                    onDropdownCellClick={onDropdownCellClick}
                    onDropdownItemSelection={onDropdownItemSelection}
                    onInputValueChange={onInputValueChange}
                    onDatepickerCellClick={onDropdownCellClick}
                    onDatepickerValueChange={onDatepickerValueChange}
                    onApproveButtonClick={onApproveButtonClick}
                    onRejectButtonClick={onRejectButtonClick}
                    onInfoMouseEnter={onInfoMouseEnter}
                    onInfoMouseLeave={onInfoMouseLeave}
                    onCellCopy={onCellCopy}
                />
            </div>
        );
    }

    function renderTotalLine(activityId: string, activityName: string, totalBudget: number, canEdit: boolean) {
        return (
            <div className={classNames(style.line, style.totalLine)}>
                <TotalLine
                    canEdit={canEdit}
                    width={rootWidth}
                    value={totalBudget}
                    userCanEditOrCreateBudgetData={userCanEditOrCreateBudgetData}
                    color={LineColor.White}
                    contentRef={totalLinesContentRef}
                    activityName={activityName}
                    onEditActivityButtonClick={() => onEditActivityButtonClick(activityId)}
                    onCopyActivityButtonClick={() => onCopyActivityButtonClick(activityId)}
                />
            </div>
        );
    }
};
