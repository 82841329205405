// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-task-components-rest-TaskTitle-_TaskTitle__root{display:inline-block}\n", "",{"version":3,"sources":["webpack://./src/client/modules/task/components/rest/TaskTitle/TaskTitle.scss"],"names":[],"mappings":"AAAA,oEACE,oBAAqB","sourcesContent":[".root {\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-task-components-rest-TaskTitle-_TaskTitle__root"
};
export default ___CSS_LOADER_EXPORT___;
