// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-tags-TagsPage-RightSidebar-Content-Task-_Task__workTypeIcon{color:transparent}\n", "",{"version":3,"sources":["webpack://./src/client/modules/tags/TagsPage/RightSidebar/Content/Task/Task.scss"],"names":[],"mappings":"AAAA,gFACI,iBAAkB","sourcesContent":[".workTypeIcon {\n    color: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workTypeIcon": "src-client-modules-tags-TagsPage-RightSidebar-Content-Task-_Task__workTypeIcon"
};
export default ___CSS_LOADER_EXPORT___;
