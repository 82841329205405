import * as EventEmitter from 'events';
import autobind from 'autobind-decorator';
import { isNil } from 'lodash';

export interface PageScrollWatcherOptions {
    offset?: number;
}

/** Page scroll watching service */
export class PageScrollWatcher extends EventEmitter {
    /** Scroll event type */
    public static readonly SCROLL_EVENT: string = 'scroll';

    /** Scrolled to top event type */
    public static readonly SCROLLED_TOP_EVENT: string = 'scrolledTop';

    /** Scrolled to down event type */
    public static readonly SCROLLED_DOWN_EVENT: string = 'scrolledDown';

    /** Page content element identifier */
    private static readonly PAGE_CONTENT_ID: string = 'pageContent';

    /** Constructor options */
    private options: PageScrollWatcherOptions = {
        offset: 0,
    };

    /** Founded page content element */
    private pageContentElement: HTMLElement | null = null;

    public constructor(options?: PageScrollWatcherOptions) {
        super();
        Object.assign(this.options, options);
        this.reInit();
    }

    /** Re-initialize */
    public reInit(): void {
        if (!isNil(this.pageContentElement)) {
            this.pageContentElement.removeEventListener('scroll', this.onScroll);
        }
        this.pageContentElement = document.getElementById(PageScrollWatcher.PAGE_CONTENT_ID);
        if (!isNil(this.pageContentElement)) {
            this.pageContentElement.addEventListener('scroll', this.onScroll);
        }
    }

    /** Remove all listeners (should be called on "componentWillUnmount" lifecycle method) */
    public dispose(): void {
        this.removeAllListeners();
        if (!isNil(this.pageContentElement)) {
            this.pageContentElement.removeEventListener('scroll', this.onScroll);
        }
    }

    public isScrolledDown(): boolean {
        return (
            Math.ceil(this.pageContentElement.scrollTop + this.pageContentElement.clientHeight) ===
            this.pageContentElement.scrollHeight
        );
    }

    /** Scroll event handler */
    @autobind
    private onScroll(): void {
        this.emit(PageScrollWatcher.SCROLL_EVENT);
        if (!isNil(this.pageContentElement)) {
            const { offset } = this.options;
            const { scrollTop, scrollHeight, clientHeight } = this.pageContentElement;

            if (Math.ceil(clientHeight + scrollTop + offset) >= scrollHeight) {
                this.emit(PageScrollWatcher.SCROLLED_DOWN_EVENT);
            }

            if (scrollTop - offset <= 0) {
                this.emit(PageScrollWatcher.SCROLLED_TOP_EVENT);
            }
        }
    }
}
