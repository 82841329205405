import { Success, Failure } from 'typescript-fsa';
import { TaskStatus } from 'sber-marketing-types/frontend';

import { TaskPageState, ChangeExecutorPayload, FetchError } from '../types';

export const changeExecutorDoneReducer = (
    state: TaskPageState,
    { params: { executorId } }: Success<ChangeExecutorPayload, void>,
): TaskPageState => {
    const participants = [
        ...state.participants.filter(
            (participant) => participant.userId !== state.executorId && participant.userId !== executorId,
        ),
        {
            userId: executorId as number,
            canRemove: false,
        },
    ];

    if (state.status !== TaskStatus.Draft && state.executorId) {
        participants.push({
            userId: state.executorId,
            canRemove: true,
        });
    }

    return {
        ...state,
        executorId: Number(executorId),
        participants,
    };
};

export const changeExecutorErrorReducer = (
    state: TaskPageState,
    payload: Failure<ChangeExecutorPayload, FetchError>,
): TaskPageState => state;

export const changeExecutorStartedReducer = (state: TaskPageState, payload: ChangeExecutorPayload): TaskPageState =>
    state;
