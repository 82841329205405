import * as React from 'react';
import { Popup as PopupUI, PopupTheme, Preloader } from 'sber-marketing-ui';

import { Header } from './Header';
import { Footer } from './Footer';

import * as style from './styles.scss';

export interface Props {
    onConfirm: () => void;
    onClose: () => void;
}

export const Popup: React.FC<Props> = ({ onConfirm, onClose }) => {
    const loading = false;

    return (
        <PopupUI theme={PopupTheme.bindActivityTheme}>
            <div className={style.root}>
                <div className={style.headerWrapper}>
                    <Header titleText={'Удаление чата'} onCloseButtonClick={onClose} />
                </div>

                <div className={style.content}>
                    Вы уверены, что хотите отключить задачу от Telegram? Чат со всей информацией о задаче будет удален.
                </div>

                <div className={style.footerWrapper}>
                    <Footer onConfirmButtonClick={onConfirm} onCancelButtonClick={onClose} />
                </div>

                {loading && <Preloader />}
            </div>
        </PopupUI>
    );
};
