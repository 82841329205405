import { Period } from './types';

export const periodName: Record<Period, string> = {
    [Period.Year]: 'год',
    [Period.Quarter]: 'квартал',
    [Period.Month]: 'месяц',
    [Period.Week]: 'неделя',
};

export const getPeriodName = (period: Period): string => {
    return periodName[period] || periodName[Period.Week];
};
