import * as React from 'react';

import * as style from './CardsList.scss';

import { Card } from '../types';

import { CardGroup } from './CardGroup';
import { CardType } from '@store/dashboardPage/types';
import { WithRequestsQuery } from './WithRequestsQuery';

interface Props {
    cards: Card[];
    isLoadingCards: boolean;
    totalCardsCount: number;
    updateCards: () => Promise<void>;
}

export const CardsList = ({ cards, isLoadingCards, totalCardsCount, updateCards }: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.cardGroup}>
                <WithRequestsQuery>
                    {({ deleteRequest }) =>
                        React.createElement(CardGroup, {
                            title: 'Связь проекта и ID бюджета',
                            cards,
                            cardType: CardType.BudgetItemToProjectLinkRequest,
                            loading: isLoadingCards,
                            totalCardsCount,
                            deleteRequest,
                            updateCards,
                        })
                    }
                </WithRequestsQuery>
            </div>
        </div>
    );
};
