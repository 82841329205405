import * as React from 'react';
import { useSelector } from 'react-redux';
import { Icon, IconType, CustomScrollbar } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { TagGroup, getTagsPageState } from '@store/tagsPage';

import { Description } from './Description';
import { Content } from './Content';

import * as styles from './RightSidebar.scss';

export function RightSidebar(): JSX.Element {
    const showNoNodeStub = useSelector((state: StoreState) => {
        const { selectedNode } = getTagsPageState(state);

        return !selectedNode || selectedNode.group === TagGroup.Tag;
    });

    if (showNoNodeStub) {
        return <NoNodeStub />;
    }

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'tagsPageRightSidebar',
            }}
        >
            <CustomScrollbar hideTracksWhenNotNeeded hideHorizontalScrollbar>
                <div className={styles.description}>
                    <Description />
                </div>

                <div className={styles.content}>
                    <Content />
                </div>
            </CustomScrollbar>
        </div>
    );
}

function NoNodeStub() {
    return (
        <div
            className={styles.noNodeStubWrapper}
            {...{
                'qa-id': 'tagsPageRightSidebarNoNodeStub',
            }}
        >
            <div className={styles.noNodeStub}>
                <div className={styles.notTagStubIcon}>
                    <Icon type={IconType.PIN} svgSize={35} />
                </div>

                <div className={styles.noNodeStubTitle}>Дополнительная информация</div>
            </div>
        </div>
    );
}
