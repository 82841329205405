// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-ActivityTypeTooltips-_ActivityTypeTooltips__tooltip{width:263px;font-family:'Open Sans';font-weight:400;font-size:12px;line-height:14px;color:#000000}.src-client-modules-common-ActivityTypeTooltips-_ActivityTypeTooltips__tooltipSubtitle{margin-top:20px;font-weight:600;color:#000000}.src-client-modules-common-ActivityTypeTooltips-_ActivityTypeTooltips__tooltipList{list-style-type:disc;padding-left:17px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/ActivityTypeTooltips/ActivityTypeTooltips.scss"],"names":[],"mappings":"AAAA,+EACI,WAAY,CAEZ,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,aAAc,CACjB,uFAGG,eAAgB,CAEhB,eAAgB,CAChB,aAAc,CACjB,mFAGG,oBAAqB,CACrB,iBAAkB","sourcesContent":[".tooltip {\n    width: 263px;\n\n    font-family: 'Open Sans';\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 14px;\n    color: #000000;\n}\n\n.tooltipSubtitle {\n    margin-top: 20px;\n\n    font-weight: 600;\n    color: #000000;\n}\n\n.tooltipList {\n    list-style-type: disc;\n    padding-left: 17px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "src-client-modules-common-ActivityTypeTooltips-_ActivityTypeTooltips__tooltip",
	"tooltipSubtitle": "src-client-modules-common-ActivityTypeTooltips-_ActivityTypeTooltips__tooltipSubtitle",
	"tooltipList": "src-client-modules-common-ActivityTypeTooltips-_ActivityTypeTooltips__tooltipList"
};
export default ___CSS_LOADER_EXPORT___;
