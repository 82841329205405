// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-CardsViewSwitch-_CardsViewSwitch__root{display:flex}.src-client-modules-common-CardsViewSwitch-_CardsViewSwitch__button{display:flex;width:24px;height:24px;margin-right:4px;cursor:pointer;color:#7e8681;align-items:center;justify-content:center}.src-client-modules-common-CardsViewSwitch-_CardsViewSwitch__button:hover{color:#000000}.src-client-modules-common-CardsViewSwitch-_CardsViewSwitch__button:last-child{margin-right:0}.src-client-modules-common-CardsViewSwitch-_CardsViewSwitch__button.src-client-modules-common-CardsViewSwitch-_CardsViewSwitch__selected{cursor:auto;color:#19bb4f}.src-client-modules-common-CardsViewSwitch-_CardsViewSwitch__button.src-client-modules-common-CardsViewSwitch-_CardsViewSwitch__selected:hover{color:#19bb4f}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/CardsViewSwitch/CardsViewSwitch.scss"],"names":[],"mappings":"AAAA,kEACI,YAAa,CAChB,oEAGG,YAAa,CAEb,UAAW,CACX,WAAY,CACZ,gBAAiB,CAEjB,cAAe,CAEf,aAAc,CAEd,kBAAmB,CACnB,sBAAuB,CAZ3B,0EAeQ,aAAc,CAftB,+EAmBQ,cAAe,CAnBvB,yIAuBQ,WAAY,CAEZ,aAAc,CAzBtB,+IA4BY,aAAc","sourcesContent":[".root {\n    display: flex;\n}\n\n.button {\n    display: flex;\n\n    width: 24px;\n    height: 24px;\n    margin-right: 4px;\n\n    cursor: pointer;\n\n    color: #7e8681;\n\n    align-items: center;\n    justify-content: center;\n\n    &:hover {\n        color: #000000;\n    }\n\n    &:last-child {\n        margin-right: 0;\n    }\n\n    &.selected {\n        cursor: auto;\n\n        color: #19bb4f;\n\n        &:hover {\n            color: #19bb4f;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-CardsViewSwitch-_CardsViewSwitch__root",
	"button": "src-client-modules-common-CardsViewSwitch-_CardsViewSwitch__button",
	"selected": "src-client-modules-common-CardsViewSwitch-_CardsViewSwitch__selected"
};
export default ___CSS_LOADER_EXPORT___;
