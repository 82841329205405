import * as React from 'react';
import { sortBy } from 'lodash';

import { Histogram } from './Histogram';
import { builderDataByMap, tabBarConfigByMode } from './utils';
import type { Data, TabBarConfig } from './utils';
import { useState } from './useState';
import { useMode } from '../../hooks';

import * as styles from './styles.scss';

export const CommonReportChart: React.FC = () => {
    const { statisticsItems, setSelectedStatisticsItemId, statisticsTypeFilters } = useState();
    const { mode } = useMode();

    let data: Data[] = [];
    let tabBarConfig: TabBarConfig = [];

    if (mode === 'activities') {
        data = sortBy(
            statisticsItems.map((statisticsItem) => {
                return builderDataByMap['activities']({
                    statisticsItem,
                    statisticsTypeFilters,
                });
            }),
            ({ bar }) => bar.first.value + bar.second.value + bar.fourth.value + bar.third.value,
        ).reverse();
        tabBarConfig = tabBarConfigByMode['activities'];
    }

    if (mode === 'tasks') {
        data = sortBy(
            statisticsItems.map((statisticsItem) => {
                return builderDataByMap['tasks']({
                    statisticsItem,
                    statisticsTypeFilters,
                });
            }),
            ({ bar }) => bar.first.value + bar.second.value + bar.fourth.value + bar.third.value,
        ).reverse();
        tabBarConfig = tabBarConfigByMode['tasks'];
    }

    const onClick = React.useCallback(
        (statisticsItemId: string) => {
            const statisticsItem = statisticsItems.find(({ id }) => id === statisticsItemId);

            if (statisticsItem.meta.type !== 'user') {
                setSelectedStatisticsItemId(statisticsItemId);
            }
        },
        [statisticsItems],
    );

    const everyStatisticsValuesEmpty = data.every(
        ({ bar }) => !(bar.first.value + bar.second.value + bar.fourth.value + bar.third.value),
    );

    return !everyStatisticsValuesEmpty ? (
        <div className={styles.root}>
            <Histogram data={data} tabBarConfig={tabBarConfig} onClick={onClick} />
        </div>
    ) : null;
};
