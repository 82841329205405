import type { CreativeRequestBudgetItemDomain } from '../types';

interface ConvertCreativeRequestBudgetItemDomains {
    (domains: CreativeRequestBudgetItemDomain[]): { id: string; serialNumber: string }[];
}

interface ConvertCreativeRequestBudgetItemDomain {
    (domains: CreativeRequestBudgetItemDomain): { id: string; serialNumber: string };
}

export const convertCreativeRequestBudgetItemDomains: ConvertCreativeRequestBudgetItemDomains = (
    creativeRequestBudgetItemDomains,
) => {
    return creativeRequestBudgetItemDomains.map((domain) => convertCreativeRequestBudgetItemDomain(domain));
};

export const convertCreativeRequestBudgetItemDomain: ConvertCreativeRequestBudgetItemDomain = ({ model }) => {
    return {
        id: model.id,
        serialNumber: model.serialNumber?.toString() || '',
    };
};
