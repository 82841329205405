import * as lodash from 'lodash';

import { AutopilotPlacementPlanData } from 'sber-marketing-types/backend';

export const LINE_NAME_TITLE = {
    vk: 'Вконтакте',
    fb: 'Facebook',
    ok: 'Одноклассники',
    youtube: 'Youtube',
    total: 'Итого',
};

export const enum COLUMN_NAME {
    LineName = 'lineName',
    Budget = 'budget',
    Impressions = 'impressions',
    Clicks = 'clicks',
    Views = 'views',
    Cpm = 'cpm',
    Cpv = 'cpv',
    Cpc = 'cpc',
    Ctr = 'ctr',
    Vtr = 'vtr',
    ReachTech = 'reachTech',
    ReachUnique = 'reachUnique',
    Quartile25 = 'quartile25',
    Quartile50 = 'quartile50',
    Quartile75 = 'quartile75',
    Quartile100 = 'quartile100',
}

export const columns = [
    COLUMN_NAME.LineName,
    COLUMN_NAME.Budget,
    COLUMN_NAME.Impressions,
    COLUMN_NAME.Clicks,
    COLUMN_NAME.Views,
    COLUMN_NAME.Cpm,
    COLUMN_NAME.Cpv,
    COLUMN_NAME.Cpc,
    COLUMN_NAME.Ctr,
    COLUMN_NAME.Vtr,
    COLUMN_NAME.ReachTech,
    COLUMN_NAME.ReachUnique,
    COLUMN_NAME.Quartile25,
    COLUMN_NAME.Quartile50,
    COLUMN_NAME.Quartile75,
    COLUMN_NAME.Quartile100,
];

export const lines = ['vk', 'ok', 'total'];

export interface ColumnParams {
    title: string;
    accessor: (mediaplan: AutopilotPlacementPlanData) => string;
    totalLineAccessor: (mediaplan: AutopilotPlacementPlanData[]) => string;
}

export const columnsConfig: { [columnName: string]: ColumnParams } = {
    [COLUMN_NAME.LineName]: {
        title: 'Платформа',
        accessor: (mediaplan) => LINE_NAME_TITLE[mediaplan.name],
        totalLineAccessor: () => LINE_NAME_TITLE['total'],
    },
    [COLUMN_NAME.Budget]: {
        title: 'Бюджет со скидкой (без НДС)',
        accessor: (mediaplan) => formatCurrencyValue(roundNumber(mediaplan.budget, 2)),
        totalLineAccessor: (mediaplan) => formatCurrencyValue(roundNumber(lodash.sumBy(mediaplan, 'budget'), 2)),
    },
    [COLUMN_NAME.Impressions]: {
        title: 'Показы',
        accessor: (mediaplan) => formatCurrencyValue(roundNumber(mediaplan.impressions, 0)),
        totalLineAccessor: (mediaplan) => formatCurrencyValue(roundNumber(lodash.sumBy(mediaplan, 'impressions'), 0)),
    },
    [COLUMN_NAME.Clicks]: {
        title: 'Клики',
        accessor: (mediaplan) => formatCurrencyValue(roundNumber(mediaplan.clicks, 0)),
        totalLineAccessor: (mediaplan) => formatCurrencyValue(roundNumber(lodash.sumBy(mediaplan, 'clicks'), 0)),
    },
    [COLUMN_NAME.Views]: {
        title: 'Полные просмотры',
        accessor: (mediaplan) => formatCurrencyValue(roundNumber(mediaplan.views, 0)),
        totalLineAccessor: (mediaplan) => formatCurrencyValue(roundNumber(lodash.sumBy(mediaplan, 'views'), 0)),
    },
    [COLUMN_NAME.Cpm]: {
        title: 'CPM',
        accessor: (mediaplan) => formatCurrencyValue(roundNumber(mediaplan.cpm, 2)),
        totalLineAccessor: (mediaplan) =>
            formatCurrencyValue(
                roundNumber((lodash.sumBy(mediaplan, 'budget') / lodash.sumBy(mediaplan, 'impressions')) * 1000, 2),
            ),
    },
    [COLUMN_NAME.Cpv]: {
        title: 'CPV',
        accessor: (mediaplan) => formatCurrencyValue(roundNumber(mediaplan.cpv, 2)),
        totalLineAccessor: (mediaplan) =>
            formatCurrencyValue(roundNumber(lodash.sumBy(mediaplan, 'budget') / lodash.sumBy(mediaplan, 'views'), 2)),
    },
    [COLUMN_NAME.Cpc]: {
        title: 'CPC',
        accessor: (mediaplan) => formatCurrencyValue(roundNumber(mediaplan.cpc, 2)),
        totalLineAccessor: (mediaplan) =>
            formatCurrencyValue(roundNumber(lodash.sumBy(mediaplan, 'budget') / lodash.sumBy(mediaplan, 'clicks'), 2)),
    },
    [COLUMN_NAME.Ctr]: {
        title: 'CTR, %',
        accessor: (mediaplan) => roundNumber(mediaplan.ctr * 100, 2).toString(),
        totalLineAccessor: (mediaplan) =>
            roundNumber(
                (lodash.sumBy(mediaplan, 'clicks') / lodash.sumBy(mediaplan, 'impressions')) * 100,
                2,
            ).toString(),
    },
    [COLUMN_NAME.Vtr]: {
        title: 'VTR, %',
        accessor: (mediaplan) => roundNumber(mediaplan.vtr * 100, 0).toString(),
        totalLineAccessor: (mediaplan) =>
            roundNumber(
                (lodash.sumBy(mediaplan, 'views') / lodash.sumBy(mediaplan, 'impressions')) * 100,
                0,
            ).toString(),
    },
    [COLUMN_NAME.ReachTech]: {
        title: 'Охват',
        accessor: (mediaplan) => formatCurrencyValue(roundNumber(mediaplan.reach_tech, 0)),
        totalLineAccessor: (mediaplan) =>
            formatCurrencyValue(
                roundNumber(
                    lodash.sumBy(mediaplan, (item) => item.reach_tech),
                    0,
                ),
            ),
    },
    [COLUMN_NAME.ReachUnique]: {
        title: 'Уникальный охват',
        accessor: (mediaplan) => formatCurrencyValue(roundNumber(mediaplan.reach_unique, 0)),
        totalLineAccessor: (mediaplan) =>
            formatCurrencyValue(
                roundNumber(
                    lodash.sumBy(mediaplan, (item) => item.reach_unique),
                    0,
                ),
            ),
    },
    [COLUMN_NAME.Quartile25]: {
        title: 'Квартиль25',
        accessor: (mediaplan) => formatCurrencyValue(roundNumber(mediaplan.quartile25, 0)),
        totalLineAccessor: (mediaplan) =>
            formatCurrencyValue(
                roundNumber(
                    lodash.sumBy(mediaplan, (item) => item.quartile25),
                    0,
                ),
            ),
    },
    [COLUMN_NAME.Quartile50]: {
        title: 'Квартиль50',
        accessor: (mediaplan) => formatCurrencyValue(roundNumber(mediaplan.quartile50, 0)),
        totalLineAccessor: (mediaplan) =>
            formatCurrencyValue(
                roundNumber(
                    lodash.sumBy(mediaplan, (item) => item.quartile50),
                    0,
                ),
            ),
    },
    [COLUMN_NAME.Quartile75]: {
        title: 'Квартиль75',
        accessor: (mediaplan) => formatCurrencyValue(roundNumber(mediaplan.quartile75, 0)),
        totalLineAccessor: (mediaplan) =>
            formatCurrencyValue(
                roundNumber(
                    lodash.sumBy(mediaplan, (item) => item.quartile75),
                    0,
                ),
            ),
    },
    [COLUMN_NAME.Quartile100]: {
        title: 'Квартиль100',
        accessor: (mediaplan) => formatCurrencyValue(roundNumber(mediaplan.quartile100, 0)),
        totalLineAccessor: (mediaplan) =>
            formatCurrencyValue(
                roundNumber(
                    lodash.sumBy(mediaplan, (item) => item.quartile100),
                    0,
                ),
            ),
    },
};

function roundNumber(value: number, digitsAfterComma: number): string {
    const roundedValue = Math.round(value * 100) / 100;
    const formatedValue = roundedValue.toFixed(digitsAfterComma);

    const [decimalPart, fractionPart] = formatedValue.split('.');

    return `${decimalPart}${fractionPart ? `.${fractionPart}` : ''}`;
}

function formatCurrencyValue(value: number | string): string {
    const [decimalPart, fractionPart] = value.toString().split('.');

    const splittedDecimal = decimalPart.split(/(?=(?:...)*$)/).join(' ');

    return `${splittedDecimal}${fractionPart ? `.${fractionPart}` : ''}`;
}
