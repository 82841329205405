import { axios, AxiosResponse } from '../lib/axios';

import {
    BriefScheme,
    BriefDraft,
    BriefXLSXParams,
    Brief,
    BriefCost,
    CreateBriefParams,
} from 'sber-marketing-types/frontend';

const BASE_URL = '/api/brief';

interface GetBriefSchemeListParams {
    organizationId: string;
    executorGroupIds?: string | number;
    name?: string;
}

export class BriefApi {
    public static async createBrief(params: CreateBriefParams): Promise<Brief> {
        const res = await axios.post(`${BASE_URL}`, params);
        return res.data;
    }

    public static async updateBrief(params: any): Promise<Brief> {
        const res = await axios.put(`${BASE_URL}/${params.id}`, params);
        return res.data;
    }

    public static async getBrief(briefId: string): Promise<Brief> {
        const res = await axios.get(`${BASE_URL}/${briefId}`);
        return res.data;
    }

    public static async getBriefSchemeList(params: GetBriefSchemeListParams): Promise<BriefScheme[]> {
        const res: AxiosResponse<BriefScheme[]> = await axios.get(`${BASE_URL}/scheme/list`, {
            params: {
                ...params,
                organizationName: true,
                common: true,
                activityCount: true,
                actual: true,
            },
        });

        return res.data;
    }

    public static async getBriefSchemeById(id: string): Promise<BriefScheme> {
        const res: AxiosResponse<BriefScheme> = await axios.get(`${BASE_URL}/scheme/${id}`, {
            params: {
                organizationName: true,
            },
        });
        return res.data;
    }

    public static async setBriefScheme(briefId: string, schemeId: string): Promise<any> {
        const res: AxiosResponse<any> = await axios.put(`${BASE_URL}/${briefId}/scheme/`, { schemeId });

        return res.data;
    }

    public static async saveBriefDraft(briefId: string, briefDraft: BriefDraft): Promise<void> {
        await axios.post(`${BASE_URL}/${briefId}/draft`, briefDraft);
    }

    public static async getBriefDraft(briefId: string): Promise<BriefDraft> {
        const res: AxiosResponse<BriefDraft> = await axios.get(`${BASE_URL}/${briefId}/draft`);

        return res.data;
    }

    public static async deleteBriefDraft(briefId: string): Promise<void> {
        await axios.delete(`${BASE_URL}/${briefId}/draft`);
    }

    public static async getBriefAsXLSL(briefId: string, params: BriefXLSXParams): Promise<Buffer> {
        const res: AxiosResponse<Buffer> = await axios.get(`${BASE_URL}/${briefId}/xlsx`, {
            params,
            responseType: 'arraybuffer',
        });

        return res.data;
    }

    public static async calculateBriefCost(briefId: string): Promise<BriefCost> {
        const res: AxiosResponse<BriefCost> = await axios.post(`${BASE_URL}/${briefId}/cost`);

        return res.data;
    }

    public static async updateFileBrief({
        briefId,
        oldContainerName,
        oldFileName,
        newContainerName,
        newFileName,
    }: {
        briefId: string;
        oldContainerName: string;
        oldFileName: string;
        newContainerName: string;
        newFileName: string;
    }): Promise<void> {
        const res = await axios.post(`${BASE_URL}/${briefId}/cloud/copy-file`, {
            oldContainerName,
            oldFileName,
            newContainerName,
            newFileName,
        });
        return res.data;
    }
}
