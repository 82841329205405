import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { get } from 'lodash';

import { StoreState } from '@store';
import { getPlannedBudgetEditPageState } from '@store/plannedBudgetEdit/selectors';
import { setNotification } from '@store/common/actions';
import { getUserOrganizationId } from '@store/user/selector';

import { Brief as BriefParams, BriefScheme } from 'sber-marketing-types/frontend/Brief2';
import { NotificationMessage } from '@store/common/types';

import { SchemeSelectionForm } from './SchemeSelectionForm';

interface Props extends Partial<MapProps & DispatchProps> {
    briefId: string;
    editRight: boolean;
    isBriefReady: boolean;
    onSchemeSelection: (schemeId: string) => void;
}

interface MapProps {
    schemes: BriefScheme[];
    currentBrief: BriefParams;
    organizationId: string;
}

interface DispatchProps {
    setNotification: (notification: NotificationMessage) => void;
}

interface State {
    selectedSchemeId: string;
    isChangeSchemeModalOpened: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class SchemeSelectionFormContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedSchemeId: (this.props.currentBrief && this.props.currentBrief.schemeId) || null,
            isChangeSchemeModalOpened: false,
        };
    }

    public componentDidUpdate(prevProps: Props) {
        const prevSchemeId = get(prevProps, 'currentBrief.schemeId') || null;
        const schemeId = get(this.props, 'currentBrief.schemeId') || null;

        if (prevSchemeId !== schemeId) {
            this.setState({ selectedSchemeId: schemeId });
        }
    }

    public render() {
        const { schemes, organizationId, editRight, isBriefReady } = this.props;

        const { selectedSchemeId, isChangeSchemeModalOpened } = this.state;

        return React.createElement(SchemeSelectionForm, {
            schemes,
            selectedSchemeId,
            organizationId,
            editRight,
            isBriefReady,
            isChangeSchemeModalOpened,
            onSchemeSelection: this.onBriefSchemeSelection,
            onSchemeSelectionButtonClick: this.onSchemeSelectionButtonClick,
            onModalConfirmButtonClick: this.onModalConfirmButtonClick,
            onModalCancelButtonClick: this.onModalCancelButtonClick,
        });
    }

    @autobind
    private onBriefSchemeSelection(schemeId: string): void {
        this.setState({
            selectedSchemeId: schemeId,
        });
    }

    @autobind
    private onSchemeSelectionButtonClick(): void {
        if (this.briefAlreadyHasScheme) {
            this.setState(() => ({ isChangeSchemeModalOpened: true }));
        } else {
            this.props.onSchemeSelection(this.state.selectedSchemeId);
        }
    }

    @autobind
    private onModalConfirmButtonClick() {
        this.setState(() => ({ isChangeSchemeModalOpened: false }));
        this.props.onSchemeSelection(this.state.selectedSchemeId);
    }

    @autobind
    private onModalCancelButtonClick() {
        this.setState(() => ({ isChangeSchemeModalOpened: false }));
    }

    private get briefAlreadyHasScheme(): boolean {
        const { currentBrief } = this.props;
        return Boolean(currentBrief && currentBrief.schemeId);
    }
}

function mapStateToProps(state: StoreState, props: Props): MapProps {
    const { briefId } = props;

    const { briefsSchemes, changedBriefs } = getPlannedBudgetEditPageState(state);

    return {
        schemes: briefsSchemes,
        currentBrief: changedBriefs[briefId],
        organizationId: getUserOrganizationId(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setNotification,
        },
        dispatch,
    );
}
