import * as React from 'react';
import classnames from 'classnames';
import { Icon, IconType } from 'sber-marketing-ui';

import { Utils } from '@common/Utils';

import * as styles from './styles.scss';

export interface Props {
    name: string;
    count: number;
    onClose?: () => void;
}

const SYMBOLS_COUNT = 20;

export const Tag: React.FC<Props> = ({ name, count, onClose }) => {
    const formattedName = Utils.shortenString(name, SYMBOLS_COUNT);

    return (
        <div
            className={styles.root}
            title={name}
            {...{
                'qa-id': 'budgetExecutionFiltersColumnsTag',
            }}
        >
            <div className={styles.text}>{formattedName}</div>
            <div className={classnames(styles.text, styles.count)}>{count}</div>

            {onClose ? (
                <div
                    className={styles.iconWrapper}
                    onClick={onClose}
                    {...{
                        'qa-id': 'budgetExecutionFiltersColumnsTagCloseButton',
                    }}
                >
                    <Icon type={IconType.BOLD_CROSS} svgSize={9} />
                </div>
            ) : null}
        </div>
    );
};
