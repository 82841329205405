import type { ActivityBudget, BudgetItem, Budget, BudgetItemSnapshot } from '@mrm/budget';
import { BudgetItemStatus, BudgetItemApproverStatus, Month } from '@mrm/budget';
import { DictionaryType, PlainDictionary } from '@mrm/dictionary';
import { ActivityDictionaryType, BudgetItemDictionaryType } from 'sber-marketing-types/budget';
import { Tag } from '@mrm/tags';
import { Dictionary } from 'lodash';
import { UserResponseParams } from 'sber-marketing-types/frontend';

import { MultiReferenceDictionaryApi } from '@api';

import { LoadingStatus } from '@store/commonTypes';

import { DropdownCellOptions } from 'client/modules/budget/BudgetPage/BudgetPlanning/Table/CellTypes/DropdownCell/DropdownCell';

import { LinkedList } from './lib/LinkedList';
import { LineModalState } from './lineModal';
import { MiscBudgetItemsState } from './miscBudgetItems';

export interface BudgetPlanningPageState {
    lineModal: LineModalState;
    miscBudgetItems: MiscBudgetItemsState;
    restState: PageState;
}
export interface PageState {
    pageData?: PageData;
    pageFilters?: PageFilters;
    unsavedChanges?: ChangeList;
    changesHistory?: LinkedList<UnsavedChange[]>;
    currentChangePosition?: number;
    fixedColumnsNames?: ColumnName[];
    columnsWidth?: ColumnsWidth;
    resizingColumnName?: ColumnName;
    lineStatusChanges?: LineStatusChanges;
    rejectMenuLineId?: string;
    approversMenuLineId?: string;
    approverStatusChanges?: LineStatusChanges;
    displayErrorPopup?: boolean;
    preloader?: boolean;
    columnFilters: {
        filters: ColumnFiltersUpdated;
        loadingStatus: ColumnFiltersLoadingStatus;
    };
    previouslyLoadedFilters: ColumnFiltersUpdated;
    loadingFiltersCount: number;
    filtersPreloader: boolean;
    showRejectionCommentPopup: boolean;
    xlsxImportErrorState: XLSXImportErrorState;
    showTagsHaveChangedMarker: boolean;
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi;
}

export type FilterKey = ColumnName;
export type AppliedFiltersNames = FilterKey[];
export type ColumnFiltersLoadingStatus = { [columnName: string]: LoadingStatus };

export { LoadingStatus };

export interface ColumnFiltersUpdated {
    [columnName: string]: {
        [key: string]: boolean;
    };
}

export type PlanningTableUserConfig = {
    [key: string]: {
        fixedColumnsNames?: ColumnName[];
        columnsVisiblityFilter?: ColumnsVisiblityFilter;
        sortingMode?: SortingMode;
        filters?: Filters;
        unsavedChanges?: ChangeList;
        columnsWidth?: ColumnsWidth;
        budgetItemsToDisplay?: BudgetItemTypes;
        budgetItemsApproversToDisplay?: BudgetItemApproversToDisplay;
        displayDisabledLines?: boolean;
        displayOnlyUnapproved?: boolean;
        displayOnlyWithSnapshots?: boolean;
        appliedFiltersNames: AppliedFiltersNames;
        budgetItemsToIgnoreFilters?: string[];
        showOnlyLinesWithPlanBudget: boolean;
        showOnlyLinesWithoutPlanBudget: boolean;
    };
};

export type BudgetPlanningDictionaries = Dictionary<PlainDictionary[]>;
export type BudgetPlanningUsers = Dictionary<{
    id: number;
    name: string;
}>;

export interface PageData {
    budget?: Budget;
    activityBudgets?: ActivityBudget[];
    budgetItems?: BudgetItem[];
    dictionaries?: BudgetPlanningDictionaries;
    userDictionaries?: GroupedDictionaries;
    allDictionaries?: GroupedDictionaries;
    budgetItemsToIgnoreFilters?: BudgetItem[];
    users?: UserResponseParams[];
    allUsers?: UserResponseParams[];
    dictionariesForXLSXTemplate?: DictionariesForXLSXTemplate;
    budgetItemSnapshots?: Dictionary<BudgetItemSnapshot[]>; // includes only id and creationTime (used for budgetItem snapshot marker)
}

export interface GroupedDictionaries {
    byId: Record<string, PlainDictionary>;
    byType: Partial<Record<DictionaryType, PlainDictionary[]>>;
}

export interface PageFilters {
    columnsVisiblityFilter?: ColumnsVisiblityFilter;
    sortingMode?: SortingMode;
    filters?: Filters;
    appliedFiltersNames: AppliedFiltersNames;
    displayOnlyUnapproved?: boolean;
    displayOnlyWithSnapshots?: boolean;
    displayDisabledLines?: boolean;
    validationStatus?: boolean;
    budgetItemsToDisplay: BudgetItemTypes;
    budgetItemsApproversToDisplay: BudgetItemApproversToDisplay;
    showOnlyLinesWithPlanBudget: boolean;
    showOnlyLinesWithoutPlanBudget: boolean;
}

export interface BudgetItemTypes {
    [budgetItemStatus: string]: boolean;
}

export type BudgetItemApproversToDisplay = Record<BudgetItemApproverStatus, boolean>;

export interface ColumnsVisiblityFilter {
    [columnName: string]: boolean;
}

export const enum ColumnName {
    Comment = 'comment',
    Id = 'id',
    Regionality = 'regionality',
    RegionalityCode = 'regionalityCode',
    ActivityName = 'activityName',
    ActivityType = 'activityType',
    ActivityTypeCode = 'activityTypeCode',
    Channel = 'channel',
    ChannelCode = 'channelCode',
    Direction = 'direction',
    DirectionCode = 'directionCode',
    Tool = 'tool',
    ToolCode = 'toolCode',
    Block = 'block',
    BlockCode = 'blockCode',
    Division = 'division',
    DivisionCode = 'divisionCode',
    Item = 'item',
    Resource = 'resource',
    ResourceCode = 'resourceCode',
    Responsible = 'responsible',
    Customer = 'customer',
    BusinessGoal = 'businessGoal',
    Segment = 'segment',
    SegmentCode = 'segmentCode',
    Product = 'product',
    ProductCode = 'productCode',
    Territory = 'territory',
    TerritoryCode = 'territoryCode',
    StartDate = 'startDate',
    EndDate = 'endDate',
    PlanJan = 'planJan',
    PlanFeb = 'planFeb',
    PlanMar = 'planMar',
    PlanApr = 'planApr',
    PlanMay = 'planMay',
    PlanJun = 'planJun',
    PlanJul = 'planJul',
    PlanAug = 'planAug',
    PlanSep = 'planSep',
    PlanOct = 'planOct',
    PlanNov = 'planNov',
    PlanDec = 'planDec',
    TotalPlan = 'totalPlan',
    SapComment = 'sapComment',
    Author = 'author',
    LocationDriver = 'locationDriver',
    LocationDriverCode = 'locationDriverCode',
    CostCenter = 'costCenter',
    CostCenterCode = 'costCenterCode',
    FactPreviousPeriod = 'factPreviousPeriod',
    Tags = 'tags',
    TotalPlanQuarter1 = 'totalPlanQuarter1',
    TotalPlanQuarter2 = 'totalPlanQuarter2',
    TotalPlanQuarter3 = 'totalPlanQuarter3',
    TotalPlanQuarter4 = 'totalPlanQuarter4',
}

export const ACTIVITY_FIELDS_COLUMN_NAMES = [ColumnName.ActivityName];

export const BUDGET_ITEM_FIELDS_COLUMN_NAMES = [
    ColumnName.Comment,
    ColumnName.Responsible,
    ColumnName.StartDate,
    ColumnName.EndDate,
    ColumnName.BusinessGoal,
    ColumnName.Customer,
    ColumnName.SapComment,
];

export const BUDGET_ITEM_DICTIONARY_COLUMN_NAMES = [
    ColumnName.Channel,
    ColumnName.ChannelCode,
    ColumnName.Direction,
    ColumnName.DirectionCode,
    ColumnName.Item,
    ColumnName.Resource,
    ColumnName.ResourceCode,
    ColumnName.Tool,
    ColumnName.ToolCode,
    ColumnName.Block,
    ColumnName.BlockCode,
    ColumnName.Division,
    ColumnName.DivisionCode,
    ColumnName.Product,
    ColumnName.ProductCode,
    ColumnName.Segment,
    ColumnName.SegmentCode,
    ColumnName.Territory,
    ColumnName.TerritoryCode,
    ColumnName.Regionality,
    ColumnName.RegionalityCode,
    ColumnName.ActivityType,
    ColumnName.ActivityTypeCode,
    ColumnName.LocationDriver,
    ColumnName.LocationDriverCode,
    ColumnName.CostCenter,
    ColumnName.CostCenterCode,
];

export const PLANNED_COLUMN_NAMES = [
    ColumnName.PlanJan,
    ColumnName.PlanFeb,
    ColumnName.PlanMar,
    ColumnName.PlanApr,
    ColumnName.PlanMay,
    ColumnName.PlanJun,
    ColumnName.PlanJul,
    ColumnName.PlanAug,
    ColumnName.PlanSep,
    ColumnName.PlanOct,
    ColumnName.PlanNov,
    ColumnName.PlanDec,
];

export const REQUIRED_COLUMN_NAMES_SHORT_LIST = [ColumnName.ActivityName];

export const CURRENCY_COLUMN_NAMES = [...PLANNED_COLUMN_NAMES, ColumnName.FactPreviousPeriod];

export const REQUIRED_COLUMN_NAMES_FULL_LIST = [
    ...REQUIRED_COLUMN_NAMES_SHORT_LIST,
    ColumnName.Regionality,
    ColumnName.ActivityType,
    ColumnName.Direction,
    ColumnName.SapComment,
    ColumnName.Tool,
    ColumnName.Block,
    ColumnName.Division,
    ColumnName.Item,
    ColumnName.Segment,
    ColumnName.Product,
    ColumnName.BusinessGoal,
    ColumnName.Customer,
    ColumnName.StartDate,
    ColumnName.EndDate,
];

export const BUDGET_ITEM_DICTIONARY_TYPES = [
    ActivityDictionaryType.ActivityType,
    ActivityDictionaryType.Block,
    ActivityDictionaryType.Division,
    ActivityDictionaryType.Product,
    ActivityDictionaryType.Segment,
    ActivityDictionaryType.Territory,
    ActivityDictionaryType.Regionality,
    BudgetItemDictionaryType.Channel,
    BudgetItemDictionaryType.Direction,
    BudgetItemDictionaryType.Item,
    BudgetItemDictionaryType.Resource,
    BudgetItemDictionaryType.Tool,
    BudgetItemDictionaryType.LocationDriver,
];

export const DictionaryNames = {
    [ActivityDictionaryType.ActivityType]: 'Тип проекта',
    [ActivityDictionaryType.Block]: 'Блок',
    [ActivityDictionaryType.Division]: 'Дивизион',
    [ActivityDictionaryType.Product]: 'Продукт',
    [ActivityDictionaryType.Segment]: 'Сегмент',
    [ActivityDictionaryType.Territory]: 'Территория',
    [ActivityDictionaryType.Regionality]: 'ЦА/ТБ',
    [BudgetItemDictionaryType.Channel]: 'Канал',
    [BudgetItemDictionaryType.Direction]: 'Направление',
    [BudgetItemDictionaryType.Item]: 'Статья',
    [BudgetItemDictionaryType.Resource]: 'Ресурс',
    [BudgetItemDictionaryType.Tool]: 'Инструмент',
    [BudgetItemDictionaryType.LocationDriver]: 'Драйвер аллокации (Бизнес-блок)',
};

export const MONTH_BY_COLUMN_NAMES = {
    [ColumnName.PlanJan]: Month.Jan,
    [ColumnName.PlanFeb]: Month.Feb,
    [ColumnName.PlanMar]: Month.Mar,
    [ColumnName.PlanApr]: Month.Apr,
    [ColumnName.PlanMay]: Month.May,
    [ColumnName.PlanJun]: Month.Jun,
    [ColumnName.PlanJul]: Month.Jul,
    [ColumnName.PlanAug]: Month.Aug,
    [ColumnName.PlanSep]: Month.Sept,
    [ColumnName.PlanOct]: Month.Oct,
    [ColumnName.PlanNov]: Month.Nov,
    [ColumnName.PlanDec]: Month.Dec,
};

export const TOTAL_PLAN_QUARTER_COLUMN_NAMES = [
    ColumnName.TotalPlanQuarter1,
    ColumnName.TotalPlanQuarter2,
    ColumnName.TotalPlanQuarter3,
    ColumnName.TotalPlanQuarter4,
];

export interface ColumnData {
    name: ColumnName;
    title: string;
    width: number;
    disableSorting?: boolean;
    disableFilter?: boolean;
    hiddenByDefault?: boolean;
    valueType: CellValueType;
    metaData?: { [key: string]: any };
    customCellType?: CustomCellType;
    customColumnColor?: CustomColumnColor;
    accessor?: (params: AccessorParams) => CellValue;
}

export const enum CustomCellType {
    Input = 'input',
    Dropdown = 'dropdown',
    Datepicker = 'datepicker',
}

export interface AccessorParams {
    budgetItem: BudgetItem;
    activityBudgets: ActivityBudget[];
    users: UserResponseParams[];
    tags: Dictionary<Tag>;
}

export interface TableLine {
    id: string;
    budgetItem: BudgetItem;
    activityId: string;
    status: BudgetItemStatus;
    userIsAuthor: boolean;
    canEdit: boolean;
    canMoveToExpertApprovement: boolean;
    fields: { [columnName: string]: CellValue };
    creationTime: string | Date;
    planValue: number;
}

export type TableLineGroup = {
    activityId: string;
    lines: TableLine[];
    canEdit: boolean;
};

export type CellValue = string | NumericCellValue | DateCellValue;

export interface NumericCellValue {
    title: string;
    number: number;
}

export interface DateCellValue {
    title: string;
    date: Date;
}

export const enum CellValueType {
    String = 'string',
    Number = 'number',
    Currency = 'currency',
    Date = 'date',
}

export interface SortingMode {
    columnName: ColumnName;
    order: OrderType;
}

export const enum OrderType {
    Asc = 'asc',
    Desc = 'desc',
}

export interface ColumnsWidth {
    [columnName: string]: number;
}

export interface Filters {
    [columnName: string]: ColumnFilters;
}

export interface ColumnFilters {
    [title: string]: boolean;
}

export interface TotalBudgets {
    [planId: string]: number;
}

export interface CellPosition {
    lineId: string;
    columnName: ColumnName;
}

export interface SelectableCellsList {
    [budgetId: string]: ColumnName[];
}

export const enum BudgetLineStatus {
    Approved = 'approved',
    Rejected = 'rejected',
    Closed = 'closed',
    Changed = 'changed',
    OnApprovemant = 'onApprovemant',
}

export interface UnsavedChange {
    budgetItemId: string;
    columnName: ColumnName;
    value: number | string | Date;
    originalValue: number | string | Date;
}

export type ChangeList = { [lineId: string]: UnsavedChange[] };

export type LineStatusChanges = {
    [lineId: string]: {
        status: BudgetItemStatus.Approved | BudgetItemStatus.Rejected | BudgetItemStatus.OnExpertApprovement;
        rejectComment?: string;
    };
};

export type LineStatusChange = {
    lineId: string;
    status: BudgetItemStatus.Approved | BudgetItemStatus.Rejected | BudgetItemStatus.OnExpertApprovement;
    rejectComment?: string;
};

export interface LoadFiltersPaylaod {
    columnName: FilterKey;
    filters: {
        [key: string]: boolean;
    };
}

export interface SetFiltersLoadingStatusPayload {
    columnName: FilterKey;
    loadingStatus: LoadingStatus;
}

export interface ChangeCellValueParams {
    change: UnsavedChange;
    line?: TableLine;
    tags?: Dictionary<Tag>;
}

// noop acts as skip loading marker
export const ColumnNameToFilterMap = {
    [ColumnName.Comment]: 'comment',
    [ColumnName.Id]: 'serialNumber',
    [ColumnName.Regionality]: 'dictionary.regionality',
    [ColumnName.RegionalityCode]: 'dictionary.regionality.code',
    [ColumnName.ActivityName]: 'activity.name',
    [ColumnName.Direction]: 'dictionary.direction',
    [ColumnName.DirectionCode]: 'dictionary.direction.code',
    [ColumnName.Channel]: 'dictionary.channel',
    [ColumnName.ChannelCode]: 'dictionary.channel.code',
    [ColumnName.Tool]: 'dictionary.tool',
    [ColumnName.ToolCode]: 'dictionary.tool.code',
    [ColumnName.SapComment]: 'sapComment',
    [ColumnName.Block]: 'dictionary.block',
    [ColumnName.BlockCode]: 'dictionary.block.code',
    [ColumnName.Division]: 'dictionary.division',
    [ColumnName.DivisionCode]: 'dictionary.division.code',
    [ColumnName.ActivityType]: 'dictionary.activity_type',
    [ColumnName.ActivityTypeCode]: 'dictionary.activity_type.code',
    [ColumnName.Item]: 'dictionary.item',
    [ColumnName.Resource]: 'dictionary.resource',
    [ColumnName.ResourceCode]: 'dictionary.resource.code',
    [ColumnName.Responsible]: 'responsibles',
    [ColumnName.Segment]: 'dictionary.segment',
    [ColumnName.SegmentCode]: 'dictionary.segment.code',
    [ColumnName.Product]: 'dictionary.product',
    [ColumnName.ProductCode]: 'dictionary.product.code',
    [ColumnName.Territory]: 'dictionary.territory',
    [ColumnName.TerritoryCode]: 'dictionary.territory.code',
    [ColumnName.LocationDriver]: 'dictionary.location_driver',
    [ColumnName.LocationDriverCode]: 'dictionary.location_driver.code',
    [ColumnName.StartDate]: 'realizationStart',
    [ColumnName.EndDate]: 'realizationEnd',
    [ColumnName.PlanJan]: 'plannedFunds.jan',
    [ColumnName.PlanFeb]: 'plannedFunds.feb',
    [ColumnName.PlanMar]: 'plannedFunds.mar',
    [ColumnName.PlanApr]: 'plannedFunds.apr',
    [ColumnName.PlanMay]: 'plannedFunds.may',
    [ColumnName.PlanJun]: 'plannedFunds.jun',
    [ColumnName.PlanJul]: 'plannedFunds.jul',
    [ColumnName.PlanAug]: 'plannedFunds.aug',
    [ColumnName.PlanSep]: 'plannedFunds.sept',
    [ColumnName.PlanOct]: 'plannedFunds.oct',
    [ColumnName.PlanNov]: 'plannedFunds.nov',
    [ColumnName.PlanDec]: 'plannedFunds.dec',
    [ColumnName.TotalPlan]: 'computedFunds.totalPlan',
    [ColumnName.Customer]: 'customerName',
    [ColumnName.BusinessGoal]: 'businessTarget',
    [ColumnName.Author]: 'author',
    [ColumnName.CostCenter]: 'dictionary.cost_center',
    [ColumnName.CostCenterCode]: 'dictionary.cost_center.code',
    [ColumnName.FactPreviousPeriod]: 'factPreviousPeriod',
    [ColumnName.Tags]: 'tags',
    [ColumnName.TotalPlanQuarter1]: 'computedFunds.totalPlanQuarter1',
    [ColumnName.TotalPlanQuarter2]: 'computedFunds.totalPlanQuarter2',
    [ColumnName.TotalPlanQuarter3]: 'computedFunds.totalPlanQuarter3',
    [ColumnName.TotalPlanQuarter4]: 'computedFunds.totalPlanQuarter4',
};

export const ColumnsNameWithCodes: ColumnName[] = [
    ColumnName.RegionalityCode,
    ColumnName.ActivityTypeCode,
    ColumnName.DirectionCode,
    ColumnName.ToolCode,
    ColumnName.BlockCode,
    ColumnName.ResourceCode,
    ColumnName.DivisionCode,
    ColumnName.SegmentCode,
    ColumnName.ProductCode,
    ColumnName.LocationDriverCode,
    ColumnName.CostCenterCode,
    ColumnName.TerritoryCode,
    ColumnName.ChannelCode,
];

// LinkedColumns graph
export const ColumnsWithSameData = {
    [ColumnName.Regionality]: [ColumnName.Regionality, ColumnName.RegionalityCode],
    [ColumnName.RegionalityCode]: [ColumnName.Regionality, ColumnName.RegionalityCode],
    [ColumnName.ActivityType]: [ColumnName.ActivityType, ColumnName.ActivityTypeCode],
    [ColumnName.ActivityTypeCode]: [ColumnName.ActivityType, ColumnName.ActivityTypeCode],
    [ColumnName.Direction]: [ColumnName.Direction, ColumnName.DirectionCode],
    [ColumnName.DirectionCode]: [ColumnName.Direction, ColumnName.DirectionCode],
    [ColumnName.Tool]: [ColumnName.Tool, ColumnName.ToolCode],
    [ColumnName.ToolCode]: [ColumnName.Tool, ColumnName.ToolCode],
    [ColumnName.Block]: [ColumnName.Block, ColumnName.BlockCode],
    [ColumnName.BlockCode]: [ColumnName.Block, ColumnName.BlockCode],
    [ColumnName.Resource]: [ColumnName.Resource, ColumnName.ResourceCode],
    [ColumnName.ResourceCode]: [ColumnName.Resource, ColumnName.ResourceCode],
    [ColumnName.Division]: [ColumnName.Division, ColumnName.DivisionCode],
    [ColumnName.DivisionCode]: [ColumnName.Division, ColumnName.DivisionCode],
    [ColumnName.Segment]: [ColumnName.Segment, ColumnName.SegmentCode],
    [ColumnName.SegmentCode]: [ColumnName.Segment, ColumnName.SegmentCode],
    [ColumnName.Product]: [ColumnName.Product, ColumnName.ProductCode],
    [ColumnName.ProductCode]: [ColumnName.Product, ColumnName.ProductCode],
    [ColumnName.LocationDriver]: [ColumnName.LocationDriver, ColumnName.LocationDriverCode],
    [ColumnName.LocationDriverCode]: [ColumnName.LocationDriver, ColumnName.LocationDriverCode],
    [ColumnName.CostCenter]: [ColumnName.CostCenter, ColumnName.CostCenterCode],
    [ColumnName.CostCenterCode]: [ColumnName.CostCenter, ColumnName.CostCenterCode],
    [ColumnName.Territory]: [ColumnName.Territory, ColumnName.TerritoryCode],
    [ColumnName.TerritoryCode]: [ColumnName.Territory, ColumnName.TerritoryCode],
    [ColumnName.Channel]: [ColumnName.Channel, ColumnName.ChannelCode],
    [ColumnName.ChannelCode]: [ColumnName.Channel, ColumnName.ChannelCode],
};

export const SBER_ORGANIZATIONS_IDS = [
    'e2ee630b-8fe9-4e68-a6cf-ea3c9d2709e8',
    '1f11c60a-c021-4092-8697-67c83cb5d5d9',
    '68a38c6a-79f7-49b5-9356-b779fbbb9b64',
    '6aa41a85-1716-43a7-9c91-9933c9b945a4',
];

export interface ResetFilterPayload {
    filterName: string;
}

export interface SetPreviouslyLoadedFilterPayload {
    columnName: FilterKey;
    filter: { [key: string]: boolean };
}

export interface SetPreviouslyLoadedFiltersPayload {
    filters: Filters;
}

export { ActivityDictionaryType, BudgetItemDictionaryType };
export interface DictionariesForXLSXTemplate {
    [dictionaryType: string]: {
        id: string;
        parents?: {
            id: string;
            type: DictionaryType;
        }[];
        value: string;
        type: DictionaryType;
    }[];
}

export interface XLSXImportErrorState {
    error: XLSXImportValidationError;
    fileName?: string;
    missingColumns?: string[];
}

export enum XLSXImportValidationError {
    None = 'None',
    WrongFileType = 'WrongFileType',
    MissingDataWorkbook = 'MissingDataWorkbook',
    MissingColumns = 'MissingColumns',
    ContainsRowsWithNonFilledRequiredFields = 'ContainsRowsWithNonFilledRequiredFields',
}

export enum CustomColumnColor {
    CurrencySum = '#e8f2ff',
}

export interface DropdownOptions {
    [lineId: string]: { [columnName: string]: DropdownCellOptions[] };
}
