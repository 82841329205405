// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-DAMFilesArchiveWidget-ArchiveInfo-_ArchiveInfo__root{width:100%;display:flex;justify-content:space-between;gap:0 12px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/DAMFilesArchiveWidget/ArchiveInfo/ArchiveInfo.scss"],"names":[],"mappings":"AAAA,gFACI,UAAW,CAEX,YAAa,CACb,6BAA8B,CAC9B,UAAW","sourcesContent":[".root {\n    width: 100%;\n\n    display: flex;\n    justify-content: space-between;\n    gap: 0 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-DAMFilesArchiveWidget-ArchiveInfo-_ArchiveInfo__root"
};
export default ___CSS_LOADER_EXPORT___;
