import { LineColor, TASKS_COLORS } from '../types';

const CANVAS_OFFSET_TOP = 14;
const CANVAS_PADDING_RIGHT = 8;
const LINE_HEIGHT = 50;
const STAGE_HEIGHT = 6;
// const TODAY_LINE_ROUND_RADIUS = 5;
const TODAY_LINE_THICKNESS = 1;
const HORIZONTAL_LINE_THICKNESS = 1;
const TASK_MARKER_SIZE = 8;
const TASK_ROTATE_ANGLE = (45.0 * Math.PI) / 180.0;

export interface StageDrawParams {
    lineIndex: number;
    start: number;
    end: number;
    color: LineColor;
}

export interface TaskDrawParams {
    lineIndex: number;
    position: number;
    color: TASKS_COLORS;
}

export class Drawer {
    public static setScroll(canvas: HTMLCanvasElement, scrollLeft: number) {
        Drawer.checkCanvasExistence(canvas);

        const ctx = canvas.getContext('2d');
        const scale = Drawer.getScale();

        ctx.setTransform(scale, 0, 0, scale, -scrollLeft * scale, 0);
    }

    public static drawDayLine(canvas: HTMLCanvasElement, x: number, color: string) {
        Drawer.checkCanvasExistence(canvas);

        const ctx = canvas.getContext('2d');
        const scale = Drawer.getScale();

        ctx.save();
        ctx.setTransform(scale, 0, 0, scale, 0, 0);

        ctx.beginPath();
        ctx.lineWidth = TODAY_LINE_THICKNESS;
        // ctx.strokeStyle = 'rgba(25, 187, 79, 0.8)';
        // ctx.arc(
        //     x - TODAY_LINE_THICKNESS / 2,
        //     CANVAS_OFFSET_TOP / 2,
        //     TODAY_LINE_ROUND_RADIUS,
        //     0,
        //     Math.PI * 2
        // );
        // ctx.stroke();

        ctx.fillStyle = color;
        ctx.fillRect(
            x - TODAY_LINE_THICKNESS / 2,
            // CANVAS_OFFSET_TOP - (CANVAS_OFFSET_TOP - TODAY_LINE_ROUND_RADIUS * 2) / 2,
            CANVAS_OFFSET_TOP / 2,
            TODAY_LINE_THICKNESS,
            Drawer.getCanvasHeight(canvas),
        );
        ctx.restore();
    }

    public static drawLineBackground(
        canvas: HTMLCanvasElement,
        lineIndex: number,
        params: { color: string; roundedTop?: boolean; roundedBottom?: boolean },
    ) {
        Drawer.checkCanvasExistence(canvas);

        const { roundedTop, roundedBottom, color } = params;

        const ctx = canvas.getContext('2d');
        const scale = Drawer.getScale();

        const linePositionY = this.getLinePositionByIndex(lineIndex);

        ctx.save();
        ctx.setTransform(scale, 0, 0, scale, 0, 0);
        ctx.fillStyle = color;
        ctx.strokeStyle = color;

        ctx.beginPath();
        roundedRect(ctx, {
            x: 0,
            y: linePositionY,
            width: Drawer.getCanvasWidth(canvas),
            height: LINE_HEIGHT,
            rounded: 4,
            roundedTop,
            roundedBottom,
        });
        ctx.fill();
        ctx.stroke();

        ctx.restore();
    }

    public static drawStage(canvas: HTMLCanvasElement, params: StageDrawParams) {
        Drawer.checkCanvasExistence(canvas);

        const { lineIndex, start, end, color } = params;

        const ctx = canvas.getContext('2d');
        const scale = Drawer.getScale();

        const linePositionY = this.getLinePositionByIndex(lineIndex);
        const stagePositionY = linePositionY + LINE_HEIGHT / 2 - STAGE_HEIGHT / 2;
        const stageWidth = end - start;

        ctx.save();
        ctx.setTransform(scale, 0, 0, scale, 0, 0);
        ctx.strokeStyle = color.bar;
        ctx.fillStyle = color.bar;

        if (stageWidth) {
            ctx.lineWidth = 1;
            ctx.lineCap = 'round';

            ctx.beginPath();
            roundedRect(ctx, {
                x: start,
                y: stagePositionY,
                width: stageWidth,
                height: STAGE_HEIGHT,
                rounded: 8,
            });
            ctx.fill();
            ctx.stroke();
            // ctx.moveTo(start + STAGE_HEIGHT / 2, stagePositionY + STAGE_HEIGHT / 2);
            // ctx.lineTo(start + stageWidth - STAGE_HEIGHT / 2, stagePositionY + STAGE_HEIGHT / 2);
            // ctx.stroke();
        } else {
            const canvasWidth = Drawer.getCanvasWidth(canvas);

            let x = start;

            if (x < STAGE_HEIGHT) {
                x = STAGE_HEIGHT;
            }

            if (x > canvasWidth - STAGE_HEIGHT) {
                x = canvasWidth - STAGE_HEIGHT;
            }

            ctx.beginPath();
            ctx.arc(x, linePositionY + LINE_HEIGHT / 2, STAGE_HEIGHT / 2, 0, Math.PI * 2);
            ctx.fill();
        }

        ctx.restore();
    }

    public static drawTask(canvas: HTMLCanvasElement, params: TaskDrawParams) {
        Drawer.checkCanvasExistence(canvas);

        const { lineIndex, position, color } = params;

        const ctx = canvas.getContext('2d');
        const scale = Drawer.getScale();

        const linePositionY =
            this.getLinePositionByIndex(lineIndex) +
            (LINE_HEIGHT - TASK_MARKER_SIZE - HORIZONTAL_LINE_THICKNESS) / 2 -
            1;

        ctx.save();
        ctx.scale(scale, scale);
        ctx.translate(position + TASK_MARKER_SIZE / 2, linePositionY);
        ctx.rotate(TASK_ROTATE_ANGLE);
        // ctx.setTransform(scale, 0, 0, scale, 0, 0);
        ctx.fillStyle = '#FFFFFF';
        ctx.strokeStyle = color;
        ctx.lineWidth = 2;

        ctx.beginPath();
        ctx.rect(0, 0, TASK_MARKER_SIZE, TASK_MARKER_SIZE);
        ctx.fill();
        ctx.stroke();

        ctx.restore();
    }

    public static drawHorizontalLine(canvas: HTMLCanvasElement, lineIndex: number, customColor?: string) {
        Drawer.checkCanvasExistence(canvas);

        const ctx = canvas.getContext('2d');
        const scale = Drawer.getScale();

        const linePositionY = this.getLinePositionByIndex(lineIndex) + LINE_HEIGHT / 2 - TODAY_LINE_THICKNESS / 2;
        const lineWidth = Drawer.getCanvasWidth(canvas) - CANVAS_PADDING_RIGHT;

        ctx.save();
        ctx.setTransform(scale, 0, 0, scale, 0, 0);
        ctx.fillStyle = customColor || '#dbdbdb';
        ctx.fillRect(0, linePositionY, lineWidth, HORIZONTAL_LINE_THICKNESS);
        ctx.restore();
    }

    public static clear(canvas: HTMLCanvasElement) {
        Drawer.checkCanvasExistence(canvas);

        const ctx = canvas.getContext('2d');
        const scale = Drawer.getScale();

        ctx.save();
        ctx.setTransform(scale, 0, 0, scale, 0, 0);
        ctx.clearRect(0, 0, Drawer.getCanvasWidth(canvas), Drawer.getCanvasHeight(canvas));
        ctx.restore();
    }

    public static getCanvasHeight(canvas: HTMLCanvasElement): number {
        return canvas.height / Drawer.getScale();
    }

    private static getLinePositionByIndex(lineIndex: number): number {
        return CANVAS_OFFSET_TOP + LINE_HEIGHT * lineIndex;
    }

    private static getCanvasWidth(canvas: HTMLCanvasElement): number {
        return canvas.width / Drawer.getScale();
    }

    private static getScale(): number {
        return window.devicePixelRatio || 1;
    }

    private static checkCanvasExistence(canvas: HTMLCanvasElement) {
        if (!canvas) {
            throw new Error('Canvas element doesn`t exist');
        }
    }
}

function roundedRect(
    ctx: CanvasRenderingContext2D,
    params: {
        x: number;
        y: number;
        width: number;
        height: number;
        rounded: number;
        roundedTop?: boolean;
        roundedBottom?: boolean;
    },
) {
    const { x, y, width, height, rounded, roundedTop, roundedBottom } = params;

    const quarterRadians = Math.PI / 2;

    ctx.lineTo(x, y);

    if (roundedTop) {
        ctx.lineTo(x + width - rounded, y);
        ctx.arc(x + width - rounded, y + rounded, rounded, -quarterRadians, 0);
    } else {
        ctx.lineTo(x + width, y);
    }

    if (roundedBottom) {
        ctx.lineTo(x + width, y + height - rounded);
        ctx.arc(x + width - rounded, y + height - rounded, rounded, 0, quarterRadians);
    } else {
        ctx.lineTo(x + width, y + height);
    }

    ctx.lineTo(x, y + height);
    ctx.closePath();
}
