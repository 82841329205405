import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { combineReducers } from 'redux';
import { stubFalse, stubArray, castArray, includes, unionBy } from 'lodash';

import {
    fetchMoreTasks,
    setCanBeLoadedMore,
    setActivityId,
    setType,
    setCardType,
    pushTasks,
    removeTasks,
    resetMyTasks,
    resetTasksList,
    setLastRequestDate,
    resetLastRequestDate,
    updateTaskCardById,
    updateSavedTaskCard,
    loadWorkTypes,
    setWorkTypesLoadingStatus,
} from './actions';
import { TasksList, TasksListType, TaskCardType, TaskCardParams, WorkTypesState } from './types';
import { LoadingStatus } from '@store/commonTypes';
import { resetStore } from '@store/common';

const status = reducerWithInitialState<LoadingStatus>(LoadingStatus.NOT_LOADED)
    .cases([resetMyTasks, resetTasksList, resetStore], () => LoadingStatus.NOT_LOADED)
    .case(fetchMoreTasks.started, () => LoadingStatus.LOADING)
    .case(fetchMoreTasks.failed, () => LoadingStatus.ERROR)
    .case(fetchMoreTasks.done, (state, payload) => {
        return payload.result ? LoadingStatus.LOADED : state;
    });

const type = reducerWithInitialState<TasksListType>(TasksListType.MY_TASKS)
    .cases([resetMyTasks, resetStore], () => TasksListType.MY_TASKS)
    .case(setType, (_, type) => type);

const cardType = reducerWithInitialState<TaskCardType>(TaskCardType.NORMAL).case(setCardType, (_, type) => type);

const activityId = reducerWithInitialState<number | null>(null)
    .cases([resetMyTasks, resetStore], () => null)
    .case(setActivityId, (_, value) => value);

const canBeLoadedMore = reducerWithInitialState<boolean>(false)
    .cases([resetMyTasks, resetStore], stubFalse)
    .case(setCanBeLoadedMore, (_, canBeLoadedMore) => canBeLoadedMore);

const tasks = reducerWithInitialState<TaskCardParams[]>([])
    .cases([resetMyTasks, resetTasksList, resetStore], stubArray)
    .case(pushTasks, (state, payload) => {
        const loadedIds = state.map(({ id }) => id);
        const newTasks = castArray(payload).filter(({ id }) => !includes(loadedIds, id));
        return state.concat(newTasks);
    })
    .case(removeTasks, (state, payload) => {
        const ids = castArray(payload);
        return state.filter(({ id }) => !includes(ids, id));
    })
    .case(updateTaskCardById.started, (state, taskId) => {
        const updatedTask = state.find(({ id }) => id === taskId);
        return unionBy([{ ...updatedTask, isLoading: true }], state, 'id');
    })
    .case(updateTaskCardById.done, (state, payload) => {
        const updatedTask: TaskCardParams = { ...payload.result, isLoading: false };
        return unionBy([updatedTask], state, 'id');
    })
    .case(updateSavedTaskCard, (state, payload) =>
        state.map((taskCard) => {
            if (taskCard.id === payload.taskId) {
                return { ...taskCard, ...payload.params };
            }

            return taskCard;
        }),
    );

const error = reducerWithInitialState<Error | null>(null)
    .cases([fetchMoreTasks.started, fetchMoreTasks.done, resetMyTasks, resetStore], () => null)
    .case(fetchMoreTasks.failed, (_, { error }) => error);

const lastRequestDate = reducerWithInitialState<Date>(null)
    .case(setLastRequestDate, (_, date) => date)
    .cases([resetLastRequestDate, resetTasksList], () => null);

const workTypes = reducerWithInitialState<WorkTypesState>({
    loadingStatus: LoadingStatus.NOT_LOADED,
    dictionary: {},
})
    .case(setWorkTypesLoadingStatus, (state, loadingStatus) => ({
        ...state,
        loadingStatus,
    }))
    .case(loadWorkTypes.done, (state, payload) => {
        return payload.result;
    });

export const tasksList = combineReducers<TasksList>({
    status,
    type,
    cardType,
    activityId,
    canBeLoadedMore,
    tasks,
    error,
    lastRequestDate,
    workTypes,
});
