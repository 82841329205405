// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-brief-ContentNav-_ContentNav__root{position:relative}.src-client-modules-brief-ContentNav-_ContentNav__download{width:186px;height:26px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/brief/ContentNav/ContentNav.scss"],"names":[],"mappings":"AAAA,uDACI,iBAAkB,CACrB,2DAGG,WAAY,CACZ,WAAY","sourcesContent":[".root {\n    position: relative;\n}\n\n.download {\n    width: 186px;\n    height: 26px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-brief-ContentNav-_ContentNav__root",
	"download": "src-client-modules-brief-ContentNav-_ContentNav__download"
};
export default ___CSS_LOADER_EXPORT___;
