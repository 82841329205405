import * as React from 'react';

import * as style from './Chart.scss';

import type { Position, Direction } from '@store/calendar/types';
import { VerticalDirection, HorizontalDirection } from '@store/calendar/types';

import { BodyPortal } from 'sber-marketing-ui';
import { ActivitiesInfo } from './ActivitiesInfo';
import { Sidebar } from './Sidebar';
import { DateFilter } from './DateFilter';
import { ChartList } from './ChartList';
import { HorizontalScrollbar } from './HorizontalScrollbar';
import { Tooltip } from '../Tooltip';

interface Props {
    tooltipPosition: Position;
    tooltipDirection: Direction;
    hoveredLineId: React.ReactText;
    dateFilterRef: (component: DateFilter) => void;
}

export function Chart({ tooltipPosition, tooltipDirection, hoveredLineId, dateFilterRef }: Props): JSX.Element {
    return (
        <div className={style.root}>
            <div className={style.fixedMenus}>
                <div className={style.activitiesInfo}>
                    <ActivitiesInfo />
                </div>

                <div className={style.topMenu}>
                    <div className={style.importantActivitiesFilter}></div>

                    <div className={style.dateFilter} id={'pdf-date-filter'}>
                        <DateFilter ref={dateFilterRef} />
                    </div>
                </div>
            </div>

            <div className={style.sidebar}>
                <Sidebar />
            </div>

            <div className={style.charts}>
                <ChartList />

                <div className={style.horizontalScrollbar}>
                    <HorizontalScrollbar />
                </div>
            </div>

            {tooltipPosition && hoveredLineId && (
                <BodyPortal>
                    <div
                        className={style.tooltip}
                        style={{
                            top: tooltipPosition.y,
                            left: `calc(100% - ${tooltipPosition.x}px)`,
                            transform: `
                                ${tooltipDirection.vertical == VerticalDirection.Up ? 'translateY(-100%)' : ''}
                                ${
                                    tooltipDirection.horizontal == HorizontalDirection.Right
                                        ? `translateX(calc(${tooltipPosition.x}px - (${window.innerWidth}px - ${tooltipPosition.x}px)))`
                                        : ''
                                }
                                ${
                                    tooltipDirection.horizontal == HorizontalDirection.Center
                                        ? `translateX(calc(${tooltipPosition.x}px - (${window.innerWidth}px - ${tooltipPosition.x}px) - 50%))`
                                        : ''
                                }
                                ${
                                    tooltipDirection.horizontal == HorizontalDirection.Left
                                        ? `translateX(calc(${tooltipPosition.x}px - (${window.innerWidth}px - ${tooltipPosition.x}px) - 100%))`
                                        : ''
                                }
                            `,
                        }}
                    >
                        <Tooltip />
                    </div>
                </BodyPortal>
            )}
        </div>
    );
}
