import * as React from 'react';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';

import { DataUpdateStrategy, initInstance, dropInstance, flushOnDemandUpdates } from '@store/tagsEditor';

import { VerticalPosition, TagsPreviewAndEditor } from '@modules/tags/TagsPreviewAndEditor';

import * as styles from './TagsEditorWidget.scss';

interface Props {
    lineId: string;
    onAttachedTagsChange: () => void;
}

function useInteractivity(props: Props) {
    const dispatch = useDispatch();
    const { lineId } = props;
    const tagsEditorId = React.useMemo(() => v4(), []);

    React.useEffect(() => {
        dispatch(
            initInstance({
                id: tagsEditorId,
                payload: { executionBudgetItemId: lineId },
            }),
        );

        return function reset() {
            dispatch(flushOnDemandUpdates(tagsEditorId));
            dispatch(dropInstance(tagsEditorId));
        };
    }, []);

    return { tagsEditorId };
}

export function TagsEditorWidget(props: Props): JSX.Element {
    const { lineId, onAttachedTagsChange } = props;
    const { tagsEditorId } = useInteractivity(props);

    return (
        <TagsPreviewAndEditor
            id={tagsEditorId}
            verticalPosition={VerticalPosition.Top}
            dataUpdateStrategy={DataUpdateStrategy.OnDemand}
            executionBudgetItemId={lineId}
            onAttachedTagsChange={onAttachedTagsChange}
            zIndex={60}
            className={styles.root}
        />
    );
}
