import * as React from 'react';
import { StaticSkeleton as Skeleton } from 'sber-marketing-ui';

import * as styles from './ContentPreloader.scss';

export function ContentPreloader(): JSX.Element {
    return <ContentCardPreloader />;
}

function ContentCardPreloader(): JSX.Element {
    return (
        <div className={styles.contentCard}>
            <div className={styles.contentCardRow}>
                <Skeleton loadingClassName={styles.contentCardFirstRowFirstSkeleton} />
                <Skeleton loadingClassName={styles.contentCardFirstRowSecondSkeleton} />
            </div>

            <div className={styles.contentCardRow}>
                <Skeleton loadingClassName={styles.contentCardSecondRowFirstSkeleton} />
                <Skeleton loadingClassName={styles.contentCardSecondRowFirstSkeleton} />
            </div>

            <Skeleton loadingClassName={styles.contentCardFirstSkeleton} />
            <Skeleton loadingClassName={styles.contentCardSecondSkeleton} />

            <div className={styles.contentCardHorizontalBar} />

            <Skeleton loadingClassName={styles.contentCardThirdSkeleton} />
        </div>
    );
}
