// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-TransferBudgetItemsToPlanningMenu-_TransferBudgetItemsToPlanningMenu__root{padding:11px 14px 16px 20px;width:480px;background:#FFFFFF;box-shadow:0px 2px 15px rgba(0,0,0,0.1);border-radius:6px;position:fixed;z-index:50;right:50px;bottom:60px}.src-client-modules-budget-BudgetPage-BudgetExecution-TransferBudgetItemsToPlanningMenu-_TransferBudgetItemsToPlanningMenu__title{display:flex;justify-content:space-between;align-items:center;font-family:'Open Sans';font-weight:600;font-size:14px;line-height:19px;color:#000000}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/TransferBudgetItemsToPlanningMenu/TransferBudgetItemsToPlanningMenu.scss"],"names":[],"mappings":"AAAA,iIACI,2BAA4B,CAC5B,WAAY,CAEZ,kBAAmB,CACnB,uCAA2C,CAC3C,iBAAkB,CAElB,cAAe,CACf,UAAW,CACX,UAAW,CACX,WAAY,CACf,kIAGG,YAAa,CACb,6BAA8B,CAC9B,kBAAmB,CAEnB,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CAEjB,aAAc","sourcesContent":[".root {\n    padding: 11px 14px 16px 20px;\n    width: 480px;\n\n    background: #FFFFFF;\n    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);\n    border-radius: 6px;\n\n    position: fixed;\n    z-index: 50;\n    right: 50px;\n    bottom: 60px;\n}\n\n.title {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 19px;\n\n    color: #000000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-TransferBudgetItemsToPlanningMenu-_TransferBudgetItemsToPlanningMenu__root",
	"title": "src-client-modules-budget-BudgetPage-BudgetExecution-TransferBudgetItemsToPlanningMenu-_TransferBudgetItemsToPlanningMenu__title"
};
export default ___CSS_LOADER_EXPORT___;
