import * as React from 'react';
import * as queryString from 'query-string';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getTagsState } from '@store/tags';
import { loadPageData, resetPageState, getTagsPageState } from '@store/tagsPage';

import { HeaderView } from '@common/Page';
import { QuickAccessIcons } from '@common/QuickAccessIcons';
import { DashboardHeaderItems } from '@common/DashboardHeaderItems';

import { Preloader as FiltersPreloader, Filters } from './Filters';
import { Preloder as GraphPreloader, Graph } from './Graph';
import { RightSidebar } from './RightSidebar';

import * as styles from './TagsPage.scss';

interface Props extends Partial<RouteComponentProps> {
    setHeaderView?: (view: HeaderView) => void;
}

function useInteractivity(props: Props) {
    const dispatch = useDispatch();
    const {
        match,
        history,
        location: { search },
        setHeaderView,
    } = props;

    const selectedTagId = useSelector((state: StoreState) => getTagsPageState(state).filters.tag);

    const tagsLoadingStatus = useSelector((state: StoreState) => getTagsState(state).loadingStatus);
    const showPreloader = useSelector((state: StoreState) => {
        const loadingStatus = getTagsPageState(state).loadingStatus.common;

        return loadingStatus === LoadingStatus.NOT_LOADED || loadingStatus === LoadingStatus.LOADING;
    });
    const showGraphPreloader = useSelector((state: StoreState) => {
        const loadingStatus = getTagsPageState(state).loadingStatus.selectedTagData;

        return showPreloader || loadingStatus === LoadingStatus.NOT_LOADED || loadingStatus === LoadingStatus.LOADING;
    });

    React.useEffect(() => {
        if (tagsLoadingStatus === LoadingStatus.LOADED) {
            const tagId = (queryString.parse(search).tagId as string) || null;
            dispatch(loadPageData(tagId));
        }

        return function tagsPageUnmount() {
            dispatch(resetPageState());
        };
    }, [tagsLoadingStatus]);

    React.useEffect(() => {
        const currentUrl = match.url;

        setHeaderView({
            firstLine: HeaderTop({
                currentUrl,
            }),
        });
    }, []);

    React.useEffect(() => {
        if (selectedTagId) {
            history.push(`/tags?tagId=${selectedTagId}`);
        }
    }, [selectedTagId]);

    return { showPreloader, showGraphPreloader };
}

export const TagsPage = withRouter((props: Props): JSX.Element => {
    const { showPreloader, showGraphPreloader } = useInteractivity(props);

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'tagsPage',
            }}
        >
            <div className={styles.content}>
                <div className={styles.filters}>{showPreloader ? <FiltersPreloader /> : <Filters />}</div>

                <div className={styles.graph}>{showGraphPreloader ? <GraphPreloader /> : <Graph />}</div>
            </div>

            <div className={styles.rightSidebar}>
                <RightSidebar />
            </div>
        </div>
    );
});

interface PageHeaderProps {
    currentUrl: string;
}

function HeaderTop(props: PageHeaderProps): JSX.Element {
    return (
        <div className={styles.header}>
            <div className={styles.headerMenuItems}>
                <DashboardHeaderItems {...props} />
            </div>

            <div className={styles.quickAccessIcons}>
                <QuickAccessIcons />
            </div>
        </div>
    );
}
