import gql from 'graphql-tag';

export const GET_BUDGETS_QUERY = gql`
    query getBudgets($organizationIds: [String]!) {
        budgets: getBudgets(filter: { organizationId: $organizationIds, status: execution }) {
            nodes {
                id
                year
                organizationIds
            }
        }
    }
`;
