import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as style from './BriefStrategy.scss';

import type { AutopilotRadioBriefStrategy } from 'sber-marketing-types/backend';
import type { StoreState } from '@store';

import { RadiobuttonGroup } from './RadiobuttonGroup';
import { StrategyTable } from './StrategyTable';
import { setBriefFormValues, setPreloaderStatus } from '@store/autopilotRadio/actions';
import { getBriefStageForm } from '@store/autopilotRadio/selectors';
import { Saver, Loader } from '../../../modules';

interface Props {
    readOnly: boolean;
}

export const BriefStrategy = ({ readOnly }: Props): JSX.Element => {
    const saver = Saver.getInstance();
    const loader = Loader.getInstance();

    const briefForm = useSelector((state: StoreState) => getBriefStageForm(state));

    const dispatch = useDispatch();

    const onStrategyChange = React.useCallback(
        async (value: string) => {
            dispatch(setBriefFormValues({ strategy: value as AutopilotRadioBriefStrategy }));

            dispatch(setPreloaderStatus(true));

            await saver.saveBriefForm();

            await Promise.all([loader.loadAutopilot(), loader.loadCalculatedMediaplan()]);

            dispatch(setPreloaderStatus(false));
        },
        [dispatch],
    );

    return (
        <div className={style.root}>
            <div className={style.title}>Выбрать стратегию</div>

            <div className={style.strategySelection}>
                <RadiobuttonGroup
                    items={[
                        {
                            title: 'Драйв-тайм',
                            value: 'DRIVE_TIME',
                        },
                        {
                            title: 'Выходные',
                            value: 'WEEKEND',
                        },
                        {
                            title: 'Охват',
                            value: 'REACH',
                        },
                    ]}
                    selectedValue={briefForm.strategy}
                    onSelection={onStrategyChange}
                    disabled={readOnly}
                />
            </div>

            <div className={style.legendTitle}>Предпросмотр таймслотов</div>

            <div className={style.tableLegends}>
                <div className={style.tableLegend}>
                    <div className={style.tableLegendTitle} style={{ background: '#19bb4f', color: '#ffffff' }}>
                        Зеленый фон
                    </div>

                    <div className={style.tableLegendDescription}>— диапазон выбран</div>
                </div>

                <div className={style.tableLegend}>
                    <div className={style.tableLegendTitle} style={{ background: '#ffffff', color: '#000000' }}>
                        Белый фон
                    </div>

                    <div className={style.tableLegendDescription}>— диапазон исключён, будни</div>
                </div>

                <div className={style.tableLegend}>
                    <div className={style.tableLegendTitle} style={{ background: '#f8f8f8', color: '#000000' }}>
                        Серый фон
                    </div>

                    <div className={style.tableLegendDescription}>— диапазон исключён, выходные</div>
                </div>
            </div>

            <div className={style.title}>Выбранные радиостанции</div>

            <div className={style.strategyTable}>
                <StrategyTable selectedStrategy={briefForm.strategy} />
            </div>
        </div>
    );
};
