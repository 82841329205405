// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetPlanning-Table-CellTypes-TextCell-_TextCell__root{display:flex;width:100%;height:100%;padding:0 6px;transition:opacity 0.2s ease;opacity:1;align-items:center}.src-client-modules-budget-BudgetPage-BudgetPlanning-Table-CellTypes-TextCell-_TextCell__root.src-client-modules-budget-BudgetPage-BudgetPlanning-Table-CellTypes-TextCell-_TextCell__disabled{cursor:auto;opacity:0.5}.src-client-modules-budget-BudgetPage-BudgetPlanning-Table-CellTypes-TextCell-_TextCell__title{overflow:hidden;user-select:none;white-space:nowrap;text-overflow:ellipsis;color:#262626;font-family:'Open Sans';font-size:14px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetPlanning/Table/CellTypes/TextCell/TextCell.scss"],"names":[],"mappings":"AAAA,8FACI,YAAa,CAEb,UAAW,CACX,WAAY,CACZ,aAAc,CAEd,4BAA6B,CAE7B,SAAU,CAEV,kBAAmB,CAXvB,+LAcQ,WAAY,CAEZ,WAAY,CACf,+FAID,eAAgB,CAEhB,gBAAiB,CACjB,kBAAmB,CACnB,sBAAuB,CAEvB,aAAc,CAEd,uBAAwB,CACxB,cAAe","sourcesContent":[".root {\n    display: flex;\n\n    width: 100%;\n    height: 100%;\n    padding: 0 6px;\n\n    transition: opacity 0.2s ease;\n\n    opacity: 1;\n\n    align-items: center;\n\n    &.disabled {\n        cursor: auto;\n\n        opacity: 0.5;\n    }\n}\n\n.title {\n    overflow: hidden;\n\n    user-select: none;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n\n    color: #262626;\n\n    font-family: 'Open Sans';\n    font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetPlanning-Table-CellTypes-TextCell-_TextCell__root",
	"disabled": "src-client-modules-budget-BudgetPage-BudgetPlanning-Table-CellTypes-TextCell-_TextCell__disabled",
	"title": "src-client-modules-budget-BudgetPage-BudgetPlanning-Table-CellTypes-TextCell-_TextCell__title"
};
export default ___CSS_LOADER_EXPORT___;
