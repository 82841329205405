import * as React from 'react';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { FileProps } from '../WithFileQuery';

import { FileList, FileParams } from './FileList';

interface Props {
    loadingFilesData: {
        originName: string;
        name: string;
        size: number;
        type: string;
    }[];
    files: FileProps[];
    onDeleteButtonClick: (fileId: string) => void;
}

interface State {
    isOpened: boolean;
}

export class FileListContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isOpened: false,
        };
    }

    public render(): JSX.Element {
        return (
            <FileList
                files={this.makeFileParams()}
                isOpened={this.state.isOpened}
                onOpenerClick={this.onOpenerClick}
                onDeleteButtonClick={this.props.onDeleteButtonClick}
            />
        );
    }

    @autobind
    private onOpenerClick(): void {
        this.setState({
            isOpened: !this.state.isOpened,
        });
    }

    private makeFileParams(): FileParams[] {
        const { loadingFilesData, files } = this.props;

        const formatedFiles: FileParams[] = [];

        if (!lodash.isEmpty(loadingFilesData)) {
            loadingFilesData.forEach((item) => {
                formatedFiles.push({
                    id: null,
                    name: item.name,
                    size: this.formatFileSize(item.size),
                    type: item.type,
                    originName: item.originName,
                    loading: true,
                });
            });
        }

        lodash.forEachRight(files, (item) => {
            formatedFiles.push({
                id: item.id,
                name: `${item.name}.${item.type}`,
                size: this.formatFileSize(item.size),
                type: item.type,
                originName: item.originName,
                loading: false,
            });
        });

        return formatedFiles;
    }

    private formatFileSize(sizeInBytes: number): string {
        let size = sizeInBytes;

        let i = -1;
        const byteUnits = ['kB', 'MB', 'GB'];

        do {
            size = size / 1024;
            i++;
        } while (size > 1024);

        return Math.max(size, 0.1).toFixed(1) + byteUnits[i];
    }
}
