import * as React from 'react';
import { List } from 'react-virtualized';
import { useSelector, useDispatch } from 'react-redux';
import { values, sortBy } from 'lodash';
import { LabeledInput, CustomScrollbar_new as CustomScrollbar, ScrollValues } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import * as departmentsStore from '@store/departments';
import { loadUserOrganizations, getUserOrganizationsIds } from '@store/userOrganizations';

import { UserItem } from './Items';

import * as styles from './DepartmentsUsersSelectSuggest.scss';

interface User {
    id: number;
    name: string;
    department: string;
}

interface Props {
    availableUsers: number[];
    labelTitle?: string;
    selectedUsers: {
        userId: number;
        canRemove: boolean;
    }[];
    disabled?: boolean;
    addEditingParticipants: (userIds: number[]) => void;
    removeEditingParticipants: (userIds: number[]) => void;
}

const USER_ITEM_HEIGHT = 45;

function useDepartmentUsersSelectSuggest({ availableUsers }: Props) {
    const dispatch = useDispatch();

    const [searchInputValue, setSearchInputValue] = React.useState('');
    const [usersToUse, setUsersToUse] = React.useState<User[]>([]);

    const userOrganizationsLoadingStatus = useSelector((state: StoreState) => state.userOrganizations.status);
    const appUsers = useSelector((state: StoreState) => state.appUsers.entities);
    const userOrganizationsIds = useSelector((state: StoreState) => getUserOrganizationsIds(state));

    React.useEffect(() => {
        setUsersToUse(
            values(appUsers)
                .filter(
                    (user) =>
                        userOrganizationsIds.includes(user.organizationId) &&
                        availableUsers.includes(user.id) &&
                        !!user.departmentId &&
                        user.isActive,
                )
                .map((user) => ({
                    id: user.id,
                    name: `${user.firstName} ${user.secondName}`,
                    department: user.department,
                })),
        );
    }, [appUsers, userOrganizationsIds]);

    React.useEffect(() => {
        if (userOrganizationsLoadingStatus === LoadingStatus.NOT_LOADED) {
            dispatch(loadUserOrganizations(null));
        }
        dispatch(
            departmentsStore.loadDepartments({
                store: departmentsStore.StoreTypes.ALL_DEPARTMENTS,
            }),
        );
    }, []);

    return {
        usersToUse,
        searchInputValue,
        setSearchInputValue,
    };
}

export function DepartmentsUsersSelectSuggest(props: Props): JSX.Element {
    const { labelTitle, disabled, selectedUsers, addEditingParticipants, removeEditingParticipants } = props;

    const { usersToUse, searchInputValue, setSearchInputValue } = useDepartmentUsersSelectSuggest(props);

    const listRef = React.useRef<List>();
    const scrollbarRef = React.useRef<any>();

    function addParticipants(userIds: number[]): void {
        addEditingParticipants(userIds);

        if (listRef.current) {
            listRef.current.recomputeRowHeights();
        }
    }

    function removeParticipants(userIds: number[]): void {
        removeEditingParticipants(userIds);

        if (listRef.current) {
            listRef.current.recomputeRowHeights();
        }
    }

    function onScroll(scrollValues: ScrollValues, prevValues: ScrollValues) {
        const valuesHaveChanged = scrollValues.scrollTop !== prevValues.scrollTop;

        if (valuesHaveChanged && listRef.current && scrollbarRef.current) {
            const { scrollTop, scrollLeft } = scrollValues;

            listRef.current.Grid.handleScrollEvent({
                scrollTop,
                scrollLeft,
            });

            scrollbarRef.current.scrollbar.scrollTo(scrollTop, scrollLeft);
        }
    }

    const searchInputLower = searchInputValue.toLocaleLowerCase();
    const filteredUsers = sortBy(
        usersToUse.filter((user) => user.name.toLocaleLowerCase().includes(searchInputLower)),
        (user) => {
            const selectedUser = selectedUsers.find((selectedUser) => selectedUser.userId === user.id);

            let index: string;
            if (selectedUser) {
                index = selectedUser.canRemove ? '00' : '01';
            } else {
                index = '1';
            }

            return `${index}${user.name}`;
        },
    );

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'userSelectSuggest',
            }}
        >
            <div className={styles.input}>
                <LabeledInput
                    qaId="userSelectSuggestInput"
                    disabled={disabled}
                    title={labelTitle || 'Участник'}
                    value={searchInputValue}
                    onValueChange={(value: string) => setSearchInputValue(value)}
                />
            </div>

            <div className={styles.suggestItems}>
                <CustomScrollbar hideScrollX maxHeight={200} ref={scrollbarRef} onScroll={onScroll}>
                    <React.Fragment>
                        <List
                            ref={listRef}
                            width={284}
                            height={200}
                            rowCount={filteredUsers.length}
                            rowHeight={USER_ITEM_HEIGHT}
                            rowRenderer={(listProps) => {
                                const user = filteredUsers[listProps.index];
                                const selectedUser = selectedUsers.find(
                                    (selectedUser) => selectedUser.userId === user.id,
                                );

                                return (
                                    <div key={listProps.index} style={listProps.style}>
                                        <UserItem
                                            id={user.id}
                                            department={user.department}
                                            name={user.name}
                                            isSelected={!!selectedUser}
                                            canRemove={selectedUser?.canRemove}
                                            addEditingParticipants={addParticipants}
                                            removeEditingParticipants={removeParticipants}
                                        />
                                    </div>
                                );
                            }}
                            className={styles.virtualizedList}
                        />
                    </React.Fragment>
                </CustomScrollbar>
            </div>
        </div>
    );
}
