import type { CreativeRequestParamDomain } from '../types';
import { Handlers } from '../converters';

interface Unsubscriber {
    (domain: CreativeRequestParamDomain, params: { handlers: Handlers }): void;
}

export const unsubscribeCreativeRequestParamDomain: Unsubscriber = ({ events }, { handlers }) => {
    events.offDivisionUpdated(handlers.onDivisionUpdated);
};
