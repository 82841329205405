import * as React from 'react';

import * as styles from './CommentsBlocks.scss';

import { UserCard as User } from 'sber-marketing-types/frontend';

import { TitleWithCountIndicator } from '@common/TitleWithCountIndicator';
import { CommentsList, CommentProps } from './CommentsList';
import { CommentInput } from './CommentInput';

interface Props {
    elKey: any;
    loading: boolean;
    comments: CommentProps[];
    pendingComments: CommentProps[];
    disableControls: boolean;
    mentionableUsers?: User[];
    createComment: (text: string, files: File[]) => Promise<void>;
    children?: (params: RenderParams) => React.ReactNode;
}

export interface RenderParams {
    isLoading: boolean;
    comments: CommentProps[];
    pendingComments: CommentProps[];
}

export function CommentsBlock({
    elKey,
    loading,
    comments,
    pendingComments,
    disableControls,
    mentionableUsers,
    createComment,
    children,
}: Props): JSX.Element {
    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'budgetExecutionLineModalCommentsBlock',
            }}
        >
            <div className={styles.header}>
                <TitleWithCountIndicator title={'Комментарии'} count={comments.length} zeroCountLablel={'0'} />
            </div>

            {!disableControls && (
                <CommentInput elKey={elKey} mentionableUsers={mentionableUsers} createComment={createComment} />
            )}

            {children ? (
                children({
                    isLoading: loading,
                    comments,
                    pendingComments,
                })
            ) : (
                <CommentsList isLoading={loading} comments={comments} pendingComments={pendingComments} />
            )}
        </div>
    );
}
