import * as React from 'react';

import { BudgetItemToProjectCardWithStore } from './BudgetItemToProjectCardWithStore';
import { BudgetItemToProjectCardConnected } from './BudgetItemToProjectCardConnected';
import { BudgetItemToProjectCardTemplate } from './BudgetItemToProjectCardTemplate';

interface Props {
    updateCards: () => Promise<void>;
}

export function BudgetItemToProjectCard({ updateCards }: Props): JSX.Element {
    return (
        <BudgetItemToProjectCardWithStore>
            {(storeProps) => (
                <BudgetItemToProjectCardConnected {...storeProps}>
                    {(gqlProps) => (
                        <BudgetItemToProjectCardTemplate {...storeProps} {...gqlProps} updateCards={updateCards} />
                    )}
                </BudgetItemToProjectCardConnected>
            )}
        </BudgetItemToProjectCardWithStore>
    );
}
