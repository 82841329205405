// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-CommentsBlock-_CommentsBlocks__root{position:relative;padding:16px 24px;border-bottom:1px solid #e3e9e5}.src-client-modules-common-CommentsBlock-_CommentsBlocks__header{display:grid;justify-items:start}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/CommentsBlock/CommentsBlocks.scss"],"names":[],"mappings":"AAAA,+DACI,iBAAkB,CAElB,iBAAkB,CAElB,+BAAgC,CACnC,iEAGG,YAAa,CAEb,mBAAoB","sourcesContent":[".root {\n    position: relative;\n\n    padding: 16px 24px;\n\n    border-bottom: 1px solid #e3e9e5;\n}\n\n.header {\n    display: grid;\n\n    justify-items: start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-CommentsBlock-_CommentsBlocks__root",
	"header": "src-client-modules-common-CommentsBlock-_CommentsBlocks__header"
};
export default ___CSS_LOADER_EXPORT___;
