import { actionCreatorFactory } from 'typescript-fsa';

import { CreativeUserConfig, LoadCreativeUserConfig, UpdateCreativeUserConfig } from './types';

const actionCreator = actionCreatorFactory('CREATIVE_USER_CONFIG');

const loadCreativeUserConfig = actionCreator.async<CreativeUserConfig, LoadCreativeUserConfig, Error>(
    'LOAD_CREATIVE_USER_CONFIG',
);
const updateCreativeUserConfig = actionCreator.async<UpdateCreativeUserConfig, void, Error>(
    'UPDATE_CREATIVE_USER_CONFIG',
);

export { loadCreativeUserConfig, updateCreativeUserConfig };
