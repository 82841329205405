import * as React from 'react';
import * as styles from './styles.scss';

export interface Props {
    onClick(): void;
}

export const Button: React.FC<Props> = ({ onClick = () => {} }) => {
    return (
        <div className={styles.root} onClick={onClick}>
            Открыть сервисный аккаунт в Telegram
        </div>
    );
};
