import * as React from 'react';
import classNames from 'classnames';

import * as style from './BudgetCard.scss';

import { Icon, IconType } from 'sber-marketing-ui';
import { StatusBar, Color } from './StatusBar';

export interface Props {
    id: string;
    selected: boolean;
    serialNumber: number;
    status: 'pending' | 'approved' | 'rejected';
    budgetYear: number;
    date: string;
    sapComment: string;
    tool: string;
    expertName: string;
    planSum: string;
    reserveSum: string;
    factSum: string;
    readOnly?: boolean;
    onClick: (id: string) => void;
}

export function BudgetCardTemplate({
    id,
    selected,
    serialNumber,
    status,
    budgetYear,
    date,
    sapComment,
    tool,
    expertName,
    planSum,
    reserveSum,
    factSum,
    readOnly,
    onClick,
}: Props) {
    let statusText;
    let statusColor: Color;

    switch (status) {
        case 'pending':
            statusText = 'На согласовании';
            statusColor = Color.Grey;
            break;
        case 'approved':
            statusText = 'Согласован';
            statusColor = Color.Green;
            break;
        case 'rejected':
            statusText = 'Отклонен';
            statusColor = Color.Red;
            break;
        default:
            statusText = '';
            statusColor = Color.Grey;
    }

    return (
        <div
            className={classNames(style.root, selected && style.selected, readOnly && style.readOnly)}
            onClick={!readOnly ? () => onClick(id) : null}
        >
            <div className={style.topLine}>
                <div className={style.title}>
                    ID {serialNumber}
                    &nbsp;
                    <span className={style.budgetYear}>({budgetYear})</span>
                </div>

                {status && (
                    <div className={style.status}>
                        <StatusBar color={statusColor} text={statusText} />
                    </div>
                )}

                <div className={style.date}>
                    <div className={style.dateIcon}>
                        <Icon type={IconType.CALENDAR_ICON} svgSize={16} />
                    </div>

                    {date}
                </div>
            </div>

            <div className={style.properties}>
                <div className={style.property}>
                    Название проекта (комментарий для SAP):
                    <div className={style.value}>{sapComment}</div>
                </div>

                <div className={style.property}>
                    Инструмент:&nbsp;
                    <span className={style.value}>{tool}</span>
                </div>

                <div className={style.property}>
                    Согласующий эксперт:&nbsp;
                    <span className={style.value}>{expertName}</span>
                </div>
            </div>

            <div className={style.bottomLine}>
                <div className={style.funds}>
                    <div className={style.fundsTitle}>План, тыс. ₽</div>

                    <div className={style.fundsValue}>{planSum}</div>
                </div>

                <div className={style.funds}>
                    <div className={style.fundsTitle}>Резерв, тыс. ₽</div>

                    <div className={style.fundsValue}>{reserveSum}</div>
                </div>

                <div className={style.funds}>
                    <div className={style.fundsTitle}>Факт, тыс. ₽</div>

                    <div className={style.fundsValue}>{factSum}</div>
                </div>
            </div>

            {/* <div className={style.deleteButton}>
                <div className={style.deleteButtonIcon}>
                    <Icon type={IconType.CLOSE} svgSize={11} />
                </div>
            </div> */}
        </div>
    );
}
