// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-task-pages-TaskPage-components-TaskPageParticipants-_TaskPageParticipants__root{font-size:12px;min-height:0;padding:16px 24px 24px;position:relative}\n", "",{"version":3,"sources":["webpack://./src/client/modules/task/pages/TaskPage/components/TaskPageParticipants/TaskPageParticipants.scss"],"names":[],"mappings":"AAAA,oGACE,cAAe,CACf,YAAa,CACb,sBAAuB,CACvB,iBAAkB","sourcesContent":[".root {\n  font-size: 12px;\n  min-height: 0;\n  padding: 16px 24px 24px;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-task-pages-TaskPage-components-TaskPageParticipants-_TaskPageParticipants__root"
};
export default ___CSS_LOADER_EXPORT___;
