// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-StageWidgets-RequestStageWidget-TenderDocumentation-Status-StatusApprovedContent-_styles__root{width:140px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/StageWidgets/RequestStageWidget/TenderDocumentation/Status/StatusApprovedContent/styles.scss"],"names":[],"mappings":"AAAA,2IACI,WAAY","sourcesContent":[".root {\n    width: 140px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-StageWidgets-RequestStageWidget-TenderDocumentation-Status-StatusApprovedContent-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
