import * as React from 'react';
import classnames from 'classnames';

import * as styles from '../CommonStyles.scss';

interface Props {
    text: string;
}

export function ErrorContentBlock({ text }: Props): JSX.Element {
    return <div className={classnames(styles.row, styles.rowPadding, styles.title, styles.titleRed)}>{text}</div>;
}
