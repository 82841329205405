import * as React from 'react';
import { groupBy } from 'lodash';
import { CustomScrollbar_new as CustomScrollbar, ScrollbarComponent } from 'sber-marketing-ui';

import { CreativeRequestComment, CreativeRequestItemComment } from '@api';

import { DatesFormatter } from '@common/Utils';

import * as styles from './GroupedComments.scss';

function useGroupedComments({ comments }: Props) {
    const [scrollbarRef, setScrollbarRef] = React.useState<ScrollbarComponent>();

    React.useEffect(() => {
        if (scrollbarRef?.contentEl) {
            scrollbarRef?.contentEl.scrollTo(
                0,
                scrollbarRef.contentEl.clientHeight + scrollbarRef.contentEl.scrollHeight,
            );
        }
    }, [comments]);

    return {
        setScrollbarRef,
    };
}

type Comment = CreativeRequestComment | CreativeRequestItemComment;

interface Props {
    comments: Comment[];
    commentRenderer: (props: { comment: Comment }) => JSX.Element;
}

export function GroupedComments(props: Props): JSX.Element {
    const { setScrollbarRef } = useGroupedComments(props);
    const { comments, commentRenderer } = props;

    const commentsByCreateTime = groupBy(comments, (comment) => DatesFormatter.yymmdd(comment.model.createdAt));
    const sortedCommentsDates = Object.keys(commentsByCreateTime).sort();

    return (
        <div className={styles.root}>
            <div className={styles.commentsWrapper}>
                <CustomScrollbar scrollbarRef={setScrollbarRef}>
                    {sortedCommentsDates.map((commentDate) => {
                        const formattedDate = DatesFormatter.ddMonthyyyy(
                            commentsByCreateTime[commentDate]?.[0]?.model?.createdAt,
                            {
                                withTodayMarker: true,
                            },
                        );

                        return (
                            <div className={styles.commentByDateGroup} key={commentDate}>
                                <div className={styles.commentByDateGroupTitleWrapper}>
                                    <div className={styles.commentByDateGroupTitle}>{formattedDate}</div>
                                </div>

                                <div className={styles.comments}>
                                    {commentsByCreateTime[commentDate]?.map?.((comment) => (
                                        <React.Fragment key={comment.model.id}>
                                            {commentRenderer({ comment })}
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                </CustomScrollbar>
            </div>
        </div>
    );
}
