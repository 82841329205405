import * as React from 'react';

import { useGetTaskQuery, useSubscribeTgTaskMutation, useUnsubscribeTgTaskMutation } from '@api';

import { Toggle, ToggleProps } from '@common/components';

import { TelegramModal } from '@modules/task/components';

export interface TaskTelegramToggleProps extends Omit<ToggleProps, 'checked'> {
    taskId: string;
}

export const TaskTelegramToggle: React.FC<TaskTelegramToggleProps> = ({ children, taskId, onToggle, ...props }) => {
    const { data, isLoading: isTaskLoading } = useGetTaskQuery({ id: taskId });
    const [subscribe, { isLoading: isSubscribing, data: subscribeData }] = useSubscribeTgTaskMutation();
    const [unsubscribe, { isLoading: isUnsubscribing }] = useUnsubscribeTgTaskMutation();
    const [tgLink, setTgLink] = React.useState('');

    const isUpdating = isSubscribing || isUnsubscribing;
    const isLoading = isTaskLoading || isUpdating;
    const checked = Boolean(data?.telegramStatus.task?.userConnected);
    const isWaiting = data?.telegramStatus?.user?.status === 'waiting_connection';

    React.useEffect(() => {
        setTgLink(subscribeData?.connectLink || '');
    }, [subscribeData?.connectLink]);

    if (!data?.telegramStatus?.enabled) return null;

    const handleEmailToggle = (checked: boolean) => {
        if (checked) {
            subscribe({ id: taskId });
        } else {
            unsubscribe({ id: taskId });
        }

        onToggle?.(checked);
    };

    const handleClick = () => {
        if (isWaiting) {
            subscribe({ id: taskId });
        }
    };

    const handleClose = () => {
        setTgLink('');
    };

    return (
        <>
            <Toggle
                data-qa-id="TaskTelegramToggle"
                {...props}
                onClick={handleClick}
                onToggle={handleEmailToggle}
                checked={checked}
                loading={isLoading}
            >
                {children}
            </Toggle>
            <TelegramModal link={tgLink} onClose={handleClose} />
        </>
    );
};
