import * as React from 'react';
import { StaticSkeleton } from 'sber-marketing-ui';

import * as styles from './BudgetItemLinkCard.scss';

export function BudgetItemLinkCardPreloader(): JSX.Element {
    return (
        <div className={styles.root}>
            <div className={styles.generalInfoWrapper}>
                <div className={styles.topRow}>
                    <StaticSkeleton className={styles.topRowFirstPreloader} />
                    <StaticSkeleton className={styles.topRowSecondPreloader} />
                </div>

                <div className={styles.sapCommentInputPreloaderWrapper}>
                    <StaticSkeleton className={styles.sapCommentInputFirstPreloader} />
                    <StaticSkeleton className={styles.sapCommentInputSecondPreloader} />
                </div>

                <StaticSkeleton className={styles.commonInfoFirstPreloader} />
                <StaticSkeleton className={styles.commonInfoSecondPreloader} />
                <StaticSkeleton className={styles.commonInfoSecondPreloader} />
                <StaticSkeleton className={styles.commonInfoThirdPreloader} />
            </div>

            <div className={styles.budgetInfoWrapper}>
                <BudgetInfoPreloader />
                <BudgetInfoPreloader />
                <BudgetInfoPreloader />
            </div>
        </div>
    );
}

function BudgetInfoPreloader(): JSX.Element {
    return (
        <div className={styles.budgetInfoPreloaderWrapper}>
            <StaticSkeleton className={styles.budgetInfoFirstPreloader} />
            <StaticSkeleton className={styles.budgetInfoSecondPreloader} />
        </div>
    );
}
