import * as React from 'react';
import classNames from 'classnames';

import * as style from './TextCell.scss';

interface Props {
    title: string;
    customStyle?: any;
    onClick?: () => void;
}

export const TextCellTemplate = ({ title, customStyle, onClick }: Props): JSX.Element => {
    return (
        <div
            className={classNames(style.root, !!onClick && style.clickable)}
            style={{ ...customStyle }}
            onClick={onClick}
        >
            {title}
        </div>
    );
};
