import { Dictionary, DictionaryType, CreativeRequestParamDomain, Param } from './types';

interface ConvertCreativeRequestParamDomain {
    (domain: CreativeRequestParamDomain, {}: Params): Promise<Param>;
}

interface Params {
    dictionaries: Record<DictionaryType, Dictionary[]>;
}

export const convertCreativeRequestParamDomain: ConvertCreativeRequestParamDomain = async (
    { model, events },
    { dictionaries },
) => {
    const selectedDictionaryBlock = await model.block;
    const selectedDictionaryDivision = await model.division;
    const selectedDictionarySegment = await model.segment;
    const selectedDictionaryProduct = await model.product;
    const selectedDictionaryChannel = await model.channel;

    const segmentDictionary = (await selectedDictionaryBlock?.getAvailable(DictionaryType.Segment)) || [];
    const channelDictionary = (await selectedDictionaryBlock?.getAvailable(DictionaryType.Channel)) || [];

    const isValidBlock = dictionaries[DictionaryType.Block]?.length ? !!selectedDictionaryBlock : true;
    const isValidDivision = dictionaries[DictionaryType.Division]?.length ? !!selectedDictionaryDivision : true;
    const isValidSegment = segmentDictionary.length ? !!selectedDictionarySegment : true;
    const isValidProduct = dictionaries[DictionaryType.Product]?.length ? !!selectedDictionaryProduct : true;
    const isValidChannel = channelDictionary.length ? !!selectedDictionaryChannel : true;

    return {
        id: model.id,
        type: 'blockParam',
        fields: {
            block: !!isValidBlock,
            division: !!isValidDivision,
            segment: !!isValidSegment,
            product: !!isValidProduct,
            channel: !!isValidChannel,
        },
    };
};
