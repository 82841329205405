import * as React from 'react';
import { BriefBlock, BriefScheme, Brief as BriefParams } from 'sber-marketing-types/frontend';
import { StyledPopup } from 'sber-marketing-ui';

import { Block } from './Block';
import { Header } from './Header';

import * as style from './Brief.scss';

interface Props {
    brief: BriefParams;
    organizationId: string;
    schemes: BriefScheme[];
    blocks: BriefBlock[];
    editRight: boolean;
    isBriefReady: boolean;
    isLoading: boolean;
    isBriefPasteDisabled: boolean;
    isBriefCopyDisabled: boolean;
    isBriefExportDisabled: boolean;
    isPasteCopiedBriefModalOpened: boolean;
    isExpanded: boolean;
    onBriefCopy: () => void;
    onBriefPaste: () => void;
    onBriefSchemeSelection: (schemeId: string) => void;
    onPasteCopiedBriefModalConfirmClick: () => void;
    onPasteCopiedBriefModalCancelClick: () => void;
    toggleIsExpandedState: () => void;
}

export const Brief: React.FC<Props> = ({
    brief,
    blocks,
    editRight,
    isBriefReady,
    isLoading,
    isBriefPasteDisabled,
    isBriefCopyDisabled,
    isBriefExportDisabled,
    isPasteCopiedBriefModalOpened,
    isExpanded,
    onBriefCopy,
    onBriefPaste,
    onBriefSchemeSelection,
    onPasteCopiedBriefModalConfirmClick,
    onPasteCopiedBriefModalCancelClick,
    toggleIsExpandedState,
}) => {
    return (
        <div>
            <Header
                brief={brief}
                editRight={editRight}
                isLoading={isLoading}
                isBriefCopyDisabled={isBriefCopyDisabled}
                isBriefPasteDisabled={isBriefPasteDisabled}
                isBriefExportDisabled={isBriefExportDisabled}
                isBriefReady={isBriefReady}
                isExpanded={isExpanded}
                onBriefCopy={onBriefCopy}
                onBriefPaste={onBriefPaste}
                onSchemeSelection={onBriefSchemeSelection}
                toggleIsExpandedState={toggleIsExpandedState}
            />

            {isExpanded && blocks.length ? (
                <>
                    <div className={style.blockTitle}>Содержание брифа</div>

                    {!isLoading && blocks && (
                        <div
                            className={style.blocks}
                            {...{
                                'qa-id': 'briefBlocks',
                            }}
                        >
                            {blocks.map((block, index) => (
                                <Block
                                    briefId={brief.id}
                                    blockId={block.id}
                                    key={block.id}
                                    name={block.name}
                                    fields={block.fields}
                                    isRequired={block.isRequired}
                                    editRight={editRight}
                                />
                            ))}
                        </div>
                    )}

                    {isPasteCopiedBriefModalOpened && (
                        <PasteCopiedBriefModal
                            onConfirmClick={onPasteCopiedBriefModalConfirmClick}
                            onCancelClick={onPasteCopiedBriefModalCancelClick}
                        />
                    )}
                </>
            ) : null}
        </div>
    );
};

const BRIEF_COPY_MESSAGE =
    'Внимание! Уверены, что хотите вставить новый бриф? После подтверждения старый бриф будет удален.';

interface ChangeBriefSchemeConfigModalProps {
    onConfirmClick: () => void;
    onCancelClick: () => void;
}

const PasteCopiedBriefModal: React.FunctionComponent<ChangeBriefSchemeConfigModalProps> = ({
    onConfirmClick,
    onCancelClick,
}: ChangeBriefSchemeConfigModalProps) => (
    <StyledPopup
        title={'Бриф уже существует'}
        fullMessage={BRIEF_COPY_MESSAGE}
        firstButtonText={'Изменить'}
        firstButtonHandler={onConfirmClick}
        secondButtonText={'Отмена'}
        secondButtonHandler={onCancelClick}
    />
);
