import * as React from 'react';
import classNames from 'classnames';

import * as style from './CalendarFilters.scss';

import type { FilterItem } from 'sber-marketing-ui';

import { OpacityTransition, Icon, IconType, CustomScrollbar, FilterGroup, FilterMenu } from 'sber-marketing-ui';

interface Props {
    preloader: boolean;
    organizationFilterItems: FilterItem[];
    calendarGroupsFilterItems: FilterItem[];
    // productsFilterItems: FilterItem[];
    activityTypesFilterItems: FilterItem[];
    departmentFilterItems: FilterItem[];
    responsibleUsersFilterItems: FilterItem[];
    selectedOrganizationIds: string[];
    selectedDivisionIds: string[];
    // selectedProductIds?: string[];
    selectedActivityTypeIds: string[];
    selectedDepartmentIds: string[];
    selectedResponsibleUserIds: number[];
    areFiltersApplied: boolean;
    isOpened: boolean;
    onOpenerClick: () => void;
    onResetFiltersButtonClick: () => void;
    onOrganizationsFilterChange: (checkedItemsIds: React.ReactText[]) => void;
    onDivisionsFilterChange: (checkedItemsIds: React.ReactText[]) => void;
    // onProductFilterChange: (checkedItemsIds: React.ReactText[]) => void;
    onActivityTypeFilterChange: (checkedItemsIds: React.ReactText[]) => void;
    onDepartmentFilterChange: (checkedItemsIds: React.ReactText[]) => void;
    onResponsibleUserFilterChange: (checkedItemsIds: React.ReactText[]) => void;
}

export const CalendarFilters = ({
    preloader,
    organizationFilterItems,
    activityTypesFilterItems,
    departmentFilterItems,
    responsibleUsersFilterItems,
    calendarGroupsFilterItems,
    // productsFilterItems,
    selectedOrganizationIds,
    selectedDivisionIds,
    // selectedProductIds,
    selectedActivityTypeIds,
    selectedDepartmentIds,
    selectedResponsibleUserIds,
    areFiltersApplied,
    isOpened,
    onOpenerClick,
    onResetFiltersButtonClick,
    onOrganizationsFilterChange,
    onDivisionsFilterChange,
    // onProductFilterChange,
    onActivityTypeFilterChange,
    onDepartmentFilterChange,
    onResponsibleUserFilterChange,
}: Props): JSX.Element => {
    return (
        <OpacityTransition start={!preloader}>
            <div
                className={style.root}
                {...{
                    'qa-id': 'calendarFilters',
                }}
            >
                <div
                    className={classNames(style.opener, (isOpened || areFiltersApplied) && style.active)}
                    onClick={onOpenerClick}
                    {...{
                        'qa-id': 'calendarFiltersOpenerButton',
                    }}
                >
                    <div className={style.openerIcon}>
                        <Icon type={IconType.FILTERS} svgSize={21} />
                    </div>

                    <div className={style.openerTitle}>Фильтр</div>

                    {selectedOrganizationIds.length > 1 && (
                        <div className={style.openerCounter}>{selectedOrganizationIds.length}</div>
                    )}
                </div>

                <div
                    className={style.resetFiltersButton}
                    onClick={onResetFiltersButtonClick}
                    {...{
                        'qa-id': 'calendarFiltersResetButton',
                    }}
                >
                    Сбросить
                </div>

                {isOpened && (
                    <div
                        className={style.content}
                        {...{
                            'qa-id': 'calendarFiltersContent',
                        }}
                    >
                        <CustomScrollbar hideTracksWhenNotNeeded hideHorizontalScrollbar>
                            {organizationFilterItems.length > 1 && (
                                <div className={style.filtersGroup}>
                                    <div className={style.filtersGroupTitle}>Доступные календари</div>

                                    <FilterMenu
                                        qaId="calendarFilter"
                                        qaLabel="Организация"
                                        filterTitle="Организация"
                                        items={organizationFilterItems}
                                        checkedItemsIds={selectedOrganizationIds}
                                        onItemSelection={onOrganizationsFilterChange}
                                        isExpanded
                                    />
                                </div>
                            )}

                            <div className={style.filtersGroup}>
                                <div className={style.filtersGroupTitle}>Фильтры</div>

                                <FilterGroup>
                                    {/* <FilterMenu
                                        qaId="calendarFilter"
                                        qaLabel="Продукты"
                                        filterTitle="Продукты"
                                        items={productsFilterItems}
                                        checkedItemsIds={selectedProductIds}
                                        onItemSelection={onProductFilterChange}
                                    /> */}

                                    <FilterMenu
                                        qaId="calendarFilter"
                                        qaLabel="Группы"
                                        filterTitle="Группы"
                                        items={calendarGroupsFilterItems}
                                        checkedItemsIds={selectedDivisionIds}
                                        onItemSelection={onDivisionsFilterChange}
                                    />

                                    <FilterMenu
                                        qaId="calendarFilter"
                                        qaLabel="Типы проектов"
                                        filterTitle="Типы проектов"
                                        items={activityTypesFilterItems}
                                        checkedItemsIds={selectedActivityTypeIds}
                                        onItemSelection={onActivityTypeFilterChange}
                                    />

                                    <FilterMenu
                                        qaId="calendarFilter"
                                        qaLabel="Департамент автора"
                                        filterTitle="Департамент автора"
                                        items={departmentFilterItems}
                                        checkedItemsIds={selectedDepartmentIds}
                                        onItemSelection={onDepartmentFilterChange}
                                        disableSort
                                    />

                                    <FilterMenu
                                        qaId="calendarFilter"
                                        qaLabel="Ответственные лица"
                                        filterTitle="Ответственные лица"
                                        items={responsibleUsersFilterItems}
                                        checkedItemsIds={selectedResponsibleUserIds}
                                        onItemSelection={onResponsibleUserFilterChange}
                                    />
                                </FilterGroup>
                            </div>
                        </CustomScrollbar>
                    </div>
                )}
            </div>
        </OpacityTransition>
    );
};
