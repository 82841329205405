import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import * as moment from 'moment';

import { ColumnName, TableType } from '@store/creative/types';

import { TableBodyCellParams, CellPosition, LineId, Line } from '../../types';
import {
    ValueAccessor,
    ItemsAccessor,
    TitleAccessor,
    DescriptionAccessor,
    CustomStyleAccessor,
    ReadOnlyAccessor,
    SuggestItemsAccessor,
    ValidationAccessor,
    AccessorParams,
    CellType,
    LineType,
    ColumnParams,
    ValueSetter,
} from '../../ColumnsConfig';
import type { CreativeRequestContract, CreativeRequestItemRightsDuration } from '@api';

import * as Cells from '../../CellTypes';
import { Utils } from '@common/Utils';

export const CellComponentsByCellType: Record<
    CellType,
    {
        cell: React.ClassType<any, any, any>;
        editCell?: React.ClassType<any, any, any>;
    }
> = {
    [CellType.LineHeader]: {
        cell: Cells.LineHeader,
    },
    [CellType.Text]: {
        cell: Cells.TextCell,
    },
    [CellType.Input]: {
        cell: Cells.InputCell,
        editCell: Cells.InputCell,
    },
    [CellType.Textarea]: {
        cell: Cells.TextareaCell,
        editCell: Cells.TextareaCell,
    },
    [CellType.FundsInput]: {
        cell: Cells.FundsInputCell,
        editCell: Cells.FundsInputCellEdit,
    },
    [CellType.FundsSelect]: {
        cell: Cells.FundsSelectCell,
        editCell: Cells.FundsSelectCellEdit,
    },
    [CellType.Select]: {
        cell: Cells.SelectCell,
        editCell: Cells.SelectCellEdit,
    },
    [CellType.SelectWithIcon]: {
        cell: Cells.SelectWithIconCell,
        editCell: Cells.SelectWithIconCellEdit,
    },
    [CellType.CheckboxList]: {
        cell: Cells.CheckboxListCell,
        editCell: Cells.CheckboxListCellEdit,
    },
    [CellType.Datepicker]: {
        cell: Cells.DatepickerCell,
        editCell: Cells.DatepickerCell,
    },
    [CellType.RangeDatepickerCell]: {
        cell: Cells.RangeDatepickerCell,
        editCell: Cells.RangeDatepickerCellEdit,
    },
    [CellType.Files]: {
        cell: Cells.FilesCell,
        editCell: Cells.FilesCellEdit,
    },
    [CellType.Status]: {
        cell: Cells.StatusCell,
    },
};

const BACKGROUND_COLOR_BY_LINE_TYPE = {
    [LineType.Line]: '#ffffff',
    [LineType.Total]: '#f4f4f4',
};

const ARCHIVED_LINE_BACKGROUND_COLOR = '#f8f8f8';

interface Props {
    getLine: (lineId: LineId) => Line;
    getAllLines: () => Line[];
    getContracts: () => CreativeRequestContract[];
    getColumnsConfig: () => { [columnName: string]: ColumnParams };
    getAccessorParamsData: () => Partial<AccessorParams>;
    onCellEditorClose: () => void;
    onLineArchive: (lineId: LineId) => void;
    onLineRestore: (lineId: LineId) => void;
    onCommentsButtonClick: (cellPosition: CellPosition) => void;
}

export class CellsFactory {
    private getLine: (lineId: LineId) => Line;
    private getAllLines: () => Line[];
    private getContracts: () => CreativeRequestContract[];
    private getColumnsConfig: () => { [columnName: string]: ColumnParams };
    private getAccessorParamsData: () => Partial<AccessorParams>;
    private onCellEditorClose: () => void;
    private onLineArchive: (lineId: LineId) => void;
    private onLineRestore: (lineId: LineId) => void;
    private onCommentsButtonClick: (cellPosition: CellPosition) => void;

    public constructor(props: Props) {
        this.getLine = props.getLine;
        this.getAllLines = props.getAllLines;
        this.getContracts = props.getContracts;
        this.getColumnsConfig = props.getColumnsConfig;
        this.getAccessorParamsData = props.getAccessorParamsData;
        this.onCellEditorClose = props.onCellEditorClose;
        this.onLineArchive = props.onLineArchive;
        this.onLineRestore = props.onLineRestore;
        this.onCommentsButtonClick = props.onCommentsButtonClick;
    }

    @autobind
    public async makeCellParams(cellPosition: CellPosition, edit = false): Promise<TableBodyCellParams> {
        const value = await this.getCellValue(cellPosition);

        return {
            component: value !== undefined ? this.getCellComponent(cellPosition, edit) : null,
            cellProps: value !== undefined ? await this.makeCellProps(cellPosition, edit) : null,
            readOnly: value !== undefined ? await this.checkReadOnlyStatus(cellPosition, edit) : true,
            сellBackgroundColor: this.getCellBackgroundColor(cellPosition),
            preventCloseOnClick: this.checkPreventCloseOnClickStatus(cellPosition, edit),
        };
    }

    @autobind
    public getCellComponent(cellPosition: CellPosition, edit = false): React.ClassType<any, any, any> {
        const { lineId, columnName } = cellPosition;

        const cellTypeField = lodash.get(this.getColumnsConfig(), [columnName, 'type']);

        if (!cellTypeField) {
            return null;
        }

        const lineType = this.getLineType(lineId);

        const cellType: CellType = lodash.isString(cellTypeField) ? cellTypeField : cellTypeField[lineType];

        return edit ? CellComponentsByCellType[cellType].editCell : CellComponentsByCellType[cellType].cell;
    }

    @autobind
    public async makeCellProps(cellPosition: CellPosition, edit = false): Promise<any> {
        const { lineId, columnName } = cellPosition;

        const cellTypeField = lodash.get(this.getColumnsConfig(), [columnName, 'type']);

        if (!cellTypeField) {
            return null;
        }

        const lineType = this.getLineType(lineId);

        const cellType: CellType = lodash.isString(cellTypeField) ? cellTypeField : cellTypeField[lineType];

        let cellProps: any;

        switch (cellType) {
            case CellType.LineHeader:
                cellProps = await this.makeLineHeaderProps(cellPosition);
                break;

            case CellType.Text:
                cellProps = await this.makeTextCellProps(cellPosition);
                break;

            case CellType.Input:
                cellProps = await this.makeInputCellProps(cellPosition, edit);
                break;

            case CellType.Textarea:
                cellProps = await this.makeTextareaCellProps(cellPosition, edit);
                break;

            case CellType.FundsInput:
                cellProps = await this.makeFundsInputCellProps(cellPosition, edit);
                break;

            case CellType.Datepicker:
                cellProps = await this.makeDatepickerCellProps(cellPosition, edit);
                break;

            case CellType.RangeDatepickerCell:
                cellProps = await this.makeRangeDatepickerCellProps(cellPosition, edit);
                break;

            case CellType.Select:
                cellProps = await this.makeSelectCellProps(cellPosition, edit);
                break;

            case CellType.SelectWithIcon:
                cellProps = await this.makeSelectWithIconCellProps(cellPosition, edit);
                break;

            case CellType.FundsSelect:
                cellProps = await this.makeFundsSelectCellProps(cellPosition, edit);
                break;

            case CellType.CheckboxList:
                cellProps = await this.makeCheckboxListCellProps(cellPosition, edit);
                break;

            case CellType.Files:
                cellProps = await this.makeFilesCellProps(cellPosition, edit);
                break;

            case CellType.Status:
                cellProps = await this.makeStatusCellProps(cellPosition);
                break;
        }

        cellProps = await this.applyCustomStyles(cellPosition, cellProps);

        cellProps.commentButtonProps = await this.makeCommentsButtonProps(cellPosition);
        cellProps = await this.applySnapshotData(cellPosition, cellProps);

        return cellProps;
    }

    private checkPreventCloseOnClickStatus(cellPosition: CellPosition, edit = false): boolean {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const columnTypeField = lodash.get(this.getColumnsConfig(), [columnName, 'type']);

        const columnType = lodash.isString(columnTypeField) ? columnTypeField : columnTypeField[lineType];

        if (!columnType) {
            return false;
        }

        return (
            edit &&
            [
                CellType.Input,
                CellType.Textarea,
                CellType.FundsInput,
                CellType.Datepicker,
                CellType.CheckboxList,
            ].includes(columnType)
        );
    }

    private async makeLineHeaderProps(cellPosition: CellPosition): Promise<any> {
        const { lineId } = cellPosition;

        const params = this.makeAccessorParams(cellPosition);

        const line = this.getLine(lineId);

        const isArchived = line.model.status === 'archived';

        const displayMenuButton =
            !['actSignAwaited', 'actPaymentAwaited', 'actPaid'].includes(line.model.actStatus) &&
            !(isArchived && params.creativeRequest.model.status === 'closed') &&
            (await line.model.creativeRequestGroup).value !== TableType.Ak;

        return {
            title: (await this.getCellValue(cellPosition)) || '—',
            displayMenuButton,
            lineIsArchived: isArchived,
            onLineArchive: () => this.onLineArchive(lineId),
            onLineRestore: () => this.onLineRestore(lineId),
        };
    }

    private async makeTextCellProps(cellPosition: CellPosition): Promise<any> {
        const value = await this.getCellValue(cellPosition);

        return {
            title: value || '—',
        };
    }

    private async makeInputCellProps(cellPosition: CellPosition, edit: boolean): Promise<any> {
        const value = await this.getCellValue(cellPosition);
        const validationAccessor = this.getCellValidation(cellPosition);

        const params = this.makeAccessorParams(cellPosition);

        return {
            edit,
            title: value || '',
            placeholder: '',
            suggestItems: await this.getCellSuggestItems(cellPosition),
            validateValue: validationAccessor ? (value: string) => validationAccessor(params, value) : null,
            onValueChange: this.makeValueChangeHandler(cellPosition),
        };
    }

    private async makeTextareaCellProps(cellPosition: CellPosition, edit: boolean): Promise<any> {
        const value = await this.getCellValue(cellPosition);
        const validationAccessor = this.getCellValidation(cellPosition);

        const params = this.makeAccessorParams(cellPosition);

        return {
            edit,
            title: value || '',
            placeholder: '',
            suggestItems: await this.getCellSuggestItems(cellPosition),
            preventCloseOnClick: true,
            validateValue: validationAccessor ? (value: string) => validationAccessor(params, value) : null,
            onValueChange: this.makeValueChangeHandler(cellPosition),
        };
    }

    private async makeFundsInputCellProps(cellPosition: CellPosition, edit: boolean): Promise<any> {
        const value = await this.getCellValue(cellPosition);
        const description = await this.getCellDescription(cellPosition);
        const validationAccessor = this.getCellValidation(cellPosition);

        const params = this.makeAccessorParams(cellPosition);

        return edit
            ? {
                  value,
                  placeholder: '',
                  validateValue: validationAccessor ? (value: string) => validationAccessor(params, value) : null,
                  onValueChange: this.makeValueChangeHandler(cellPosition),
              }
            : {
                  title: this.formatCurrencyValue(this.roundNumber(value)) || '—',
                  value,
                  description,
              };
    }

    private async makeDatepickerCellProps(cellPosition: CellPosition, edit: boolean): Promise<any> {
        const cellValue = await this.getCellValue(cellPosition);

        const momentValue = cellValue ? moment(cellValue) : null;

        return {
            edit,
            title: momentValue ? momentValue.format('DD.MM.YY') : '—',
            value: momentValue,
            onValueChange: this.makeValueChangeHandler(cellPosition),
        };
    }

    private async makeRangeDatepickerCellProps(cellPosition: CellPosition, edit: boolean): Promise<any> {
        const cellValue = (await this.getCellValue(cellPosition)) as CreativeRequestItemRightsDuration;

        return edit
            ? {
                  dates: [cellValue?.startDate, cellValue?.endDate],
                  onValueChange: this.makeValueChangeHandler(cellPosition),
              }
            : {
                  dates: [cellValue?.startDate, cellValue?.endDate],
              };
    }

    private async makeSelectCellProps(cellPosition: CellPosition, edit: boolean): Promise<any> {
        const cellValue = await this.getCellValue(cellPosition);
        const items = await this.getCellItems(cellPosition);

        const selectedItem = items.find((item) => item.value === cellValue);

        return edit
            ? {
                  title: selectedItem ? selectedItem.title : `—`,
                  columnTitle: this.getColumnsConfig()[cellPosition.columnName].title,
                  items,
                  selectedValue: cellValue,
                  onValueChange: this.makeValueChangeHandler(cellPosition),
              }
            : {
                  title: selectedItem ? selectedItem.title : `—`,
                  onValueChange: this.makeValueChangeHandler(cellPosition),
              };
    }

    private async makeSelectWithIconCellProps(cellPosition: CellPosition, edit: boolean): Promise<any> {
        const cellValue = await this.getCellValue(cellPosition);
        const isReadOnly: boolean = await this.checkReadOnlyStatus(cellPosition, edit);
        const items = await this.getCellItems(cellPosition);

        const selectedItem = items.find((item) => item.value === cellValue);
        const description = await this.getCellDescription(cellPosition);

        return edit
            ? {
                  title: selectedItem ? selectedItem.title : `—`,
                  description,
                  iconType: selectedItem ? selectedItem?.iconType : null,
                  columnTitle: this.getColumnsConfig()[cellPosition.columnName].title,
                  items,
                  selectedValue: cellValue,
                  onValueChange: this.makeValueChangeHandler(cellPosition),
              }
            : {
                  title: selectedItem ? selectedItem.title : `—`,
                  description,
                  iconType: selectedItem ? selectedItem?.iconType : null,
                  isReadOnly,
                  onValueChange: this.makeValueChangeHandler(cellPosition),
              };
    }

    private async makeFundsSelectCellProps(cellPosition: CellPosition, edit: boolean): Promise<any> {
        const cellValue = await this.getCellValue(cellPosition);
        const items = await this.getCellItems(cellPosition);
        let title = await this.getCellTitle(cellPosition);
        const description = await this.getCellDescription(cellPosition);

        if (title === undefined) {
            title = cellValue !== null ? items.find((item) => item.value === cellValue)?.title : `—`;
        } else {
            title = this.formatCurrencyValue(this.roundNumber(title as number, 2)) || '—';
        }

        return edit
            ? {
                  title,
                  description,
                  items,
                  selectedValue: cellValue,
                  onValueChange: this.makeValueChangeHandler(cellPosition),
              }
            : {
                  title,
                  description,
              };
    }

    private async makeCheckboxListCellProps(cellPosition: CellPosition, edit: boolean): Promise<any> {
        const { columnName } = cellPosition;

        const cellValue: React.ReactText[] = (await this.getCellValue(cellPosition)) || [];
        const items = await this.getCellItems(cellPosition);

        const selectedItems = items.filter((item) => cellValue.includes(item.value));

        const selectedItemsNames = selectedItems.map((item) => item.title);

        const title =
            selectedItemsNames.length > 3
                ? selectedItemsNames.slice(0, 2).join('\n').concat('\n...')
                : selectedItemsNames.join('\n') || '—';

        const tooltip = selectedItemsNames.join(', ');

        return edit
            ? {
                  title,
                  tooltip,
                  columnTitle: this.getColumnsConfig()[columnName].title,
                  columnName,
                  items,
                  selectedValues: cellValue,
                  onValueChange: this.makeValueChangeHandler(cellPosition),
              }
            : {
                  title,
                  tooltip,
              };
    }

    private async makeFilesCellProps(cellPosition: CellPosition, edit: boolean): Promise<any> {
        const files = await this.getCellValue(cellPosition);

        const filesCount = files.length || 0;

        return edit
            ? {
                  title: `${filesCount} ${Utils.getDeclensionByNumber(filesCount, ['файл', 'файла', 'файлов'])}`,
                  files,
                  onValueChange: this.makeValueChangeHandler(cellPosition),
              }
            : {
                  title: `${filesCount} ${Utils.getDeclensionByNumber(filesCount, ['файл', 'файла', 'файлов'])}`,
              };
    }

    private async makeStatusCellProps(cellPosition: CellPosition): Promise<any> {
        const { lineId } = cellPosition;

        const line = this.getLine(lineId);

        const lineIsApproved = (await this.getCellValue(cellPosition)) as boolean;

        const lineHasDonors = !lodash.isEmpty(line.model.donors);
        const lineHasAcceptors = !lodash.isEmpty(line.model.acceptors);
        const actIsApproved = ['actSignAwaited', 'actPaymentAwaited', 'actPaid'].includes(line.model.actStatus);
        const canApprove = line.model.approve || line.model.disapprove;

        let selectionType: 'green' | 'yellow' = null;

        if (lineIsApproved) {
            selectionType = lineHasDonors && lineHasAcceptors ? 'green' : 'yellow';
        }

        return {
            selectionType,
            disabled: !canApprove || actIsApproved,
            onClick: this.makeValueChangeHandler(cellPosition),
        };
    }

    private async makeCommentsButtonProps(cellPosition: CellPosition) {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        if (lineType === LineType.Total) {
            return null;
        }

        const line = this.getLine(lineId);

        const comments = await line.model.getComments();

        const hasNewComments = comments
            .filter((item) => item.model.column === columnName)
            .some((item) => !item.model.isRead || item.model.isFavorite);

        return {
            hasNewComments,
            onClick: () => this.onCommentsButtonClick(cellPosition),
        };
    }

    private async applySnapshotData(cellPosition: CellPosition, cellProps: any) {
        const cellSnapshot = await this.getCellSnapsot(cellPosition);

        if (!!cellSnapshot) {
            cellProps.snapshot = cellSnapshot;
            cellProps.customStyle = { ...cellProps.customStyle, backgroundColor: 'rgba(65, 126, 198, 0.1)' };
        }

        return cellProps;
    }

    private getLineType(lineId: LineId): LineType {
        let lineType: LineType = LineType.Line;

        if (lineId.toLowerCase().includes('total')) {
            lineType = LineType.Total;
        }

        return lineType;
    }

    private makeAccessorParams(cellPosition: CellPosition): AccessorParams {
        const { lineId } = cellPosition;

        const line = this.getLine(lineId);

        const { project, creativeRequest, creativeRequestLot, dictionariesByType, users } =
            this.getAccessorParamsData();

        return {
            lineId,
            line,
            allLines: this.getAllLines(),
            project,
            creativeRequest,
            creativeRequestLot,
            contracts: this.getContracts(),
            dictionariesByType,
            users,
        };
    }

    private async getCellValue(cellPosition: CellPosition): Promise<any> {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const accessorField = lodash.get(this.getColumnsConfig(), [columnName, 'getValue']);

        const accessor: ValueAccessor = lodash.isFunction(accessorField) ? accessorField : accessorField[lineType];

        if (!accessor) {
            return undefined;
        }

        const params = this.makeAccessorParams(cellPosition);

        return accessor(params);
    }

    private async getCellSnapsot(cellPosition: CellPosition): Promise<any> {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const accessorField = lodash.get(this.getColumnsConfig(), [columnName, 'getSnapshotValue']);

        if (!accessorField) {
            return undefined;
        }

        const accessor: ValueAccessor = lodash.isFunction(accessorField) ? accessorField : accessorField[lineType];

        if (!accessor) {
            return undefined;
        }

        const params = this.makeAccessorParams(cellPosition);

        return accessor(params);
    }

    @autobind
    private async getCellItems(cellPosition: CellPosition): Promise<{ title: string; value: any; iconType?: any }[]> {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const itemsAccessorField = lodash.get(this.getColumnsConfig(), [columnName, 'getItems']);

        const itemsAccessor: ItemsAccessor = lodash.isFunction(itemsAccessorField)
            ? itemsAccessorField
            : itemsAccessorField[lineType];

        if (!itemsAccessor) {
            return undefined;
        }

        const params = this.makeAccessorParams(cellPosition);

        return itemsAccessor(params);
    }

    private async getCellTitle(cellPosition: CellPosition): Promise<React.ReactText> {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const titleAccessorField = lodash.get(this.getColumnsConfig(), [columnName, 'getTitle']);

        if (!titleAccessorField) {
            return undefined;
        }

        const titleAccessor: TitleAccessor = lodash.isFunction(titleAccessorField)
            ? titleAccessorField
            : titleAccessorField[lineType];

        if (!titleAccessor) {
            return undefined;
        }

        const params = this.makeAccessorParams(cellPosition);

        return titleAccessor(params);
    }

    private async getCellSuggestItems(cellPosition: CellPosition): Promise<any[]> {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const suggestItemsField = lodash.get(this.getColumnsConfig(), [columnName, 'getSuggestItems']);

        if (!suggestItemsField) {
            return [];
        }

        const suggestItemsAccessor: SuggestItemsAccessor = lodash.isFunction(suggestItemsField)
            ? suggestItemsField
            : suggestItemsField[lineType];

        const params = this.makeAccessorParams(cellPosition);

        return suggestItemsAccessor(params);
    }

    private async getCellDescription(cellPosition: CellPosition): Promise<string> {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const descriptionAccessorField = lodash.get(this.getColumnsConfig(), [columnName, 'getDescription']);

        if (!descriptionAccessorField) {
            return undefined;
        }

        const descriptionAccessor: DescriptionAccessor = lodash.isFunction(descriptionAccessorField)
            ? descriptionAccessorField
            : descriptionAccessorField[lineType];

        if (!descriptionAccessor) {
            return undefined;
        }

        const params = this.makeAccessorParams(cellPosition);

        return descriptionAccessor(params);
    }

    private getCellValidation(cellPosition: CellPosition): ValidationAccessor {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const validationAccessorField = lodash.get(this.getColumnsConfig(), [columnName, 'validateValue']);

        if (!validationAccessorField) {
            return undefined;
        }

        const validationAccessor: ValidationAccessor = lodash.isFunction(validationAccessorField)
            ? validationAccessorField
            : validationAccessorField[lineType];

        return validationAccessor;
    }

    @autobind
    private async checkReadOnlyStatus(cellPosition: CellPosition, edit: boolean): Promise<boolean> {
        const { lineId, columnName } = cellPosition;

        const { creativeRequestLot } = this.makeAccessorParams(cellPosition);

        const cellComponent = this.getCellComponent(cellPosition, edit);

        if (!cellComponent) {
            return true;
        }

        const lineType = this.getLineType(lineId);

        if (lineType === LineType.Total) {
            return true;
        }

        const line = this.getLine(lineId);

        const isAkTableLine = (await line.model.creativeRequestGroup).value === TableType.Ak;

        if (isAkTableLine && columnName !== ColumnName.ExecutionId && columnName !== ColumnName.ProjectName) {
            return true;
        }

        const isArchived = line.model.status === 'archived';

        if (isArchived) {
            return true;
        }

        if (
            columnName !== 'actStatus' &&
            ['actSignAwaited', 'actPaymentAwaited', 'actPaid'].includes(line.model.actStatus)
        ) {
            return true;
        }

        if (columnName === ColumnName.Vat && creativeRequestLot === '1') {
            return true;
        }

        const readOnlyStatusField: boolean | ReadOnlyAccessor = lodash.get(this.getColumnsConfig(), [
            columnName,
            'readOnly',
            lineType,
        ])
            ? lodash.get(this.getColumnsConfig(), [columnName, 'readOnly', lineType])
            : lodash.get(this.getColumnsConfig(), [columnName, 'readOnly']);

        if (readOnlyStatusField === undefined) {
            return false;
        }

        const readOnlyStatus: boolean | ReadOnlyAccessor = lodash.isFunction(readOnlyStatusField)
            ? await readOnlyStatusField(this.makeAccessorParams(cellPosition))
            : readOnlyStatusField;

        return readOnlyStatus || false;
    }

    private getCellBackgroundColor(cellPosition: CellPosition): string {
        const { lineId } = cellPosition;

        const line = this.getLine(lineId);

        const isArchived = line?.model.status === 'archived';

        if (isArchived) {
            return ARCHIVED_LINE_BACKGROUND_COLOR;
        }

        const lineType = this.getLineType(lineId);

        return BACKGROUND_COLOR_BY_LINE_TYPE[lineType] || null;
    }

    private makeValueChangeHandler(cellPosition: CellPosition) {
        return async (value: any) => {
            const cellValue = await this.getCellValue(cellPosition);

            const valueChanged = this.compareValues(value, cellValue);

            if (valueChanged) {
                const { lineId, columnName } = cellPosition;

                const cellType = this.getCellType(cellPosition);

                if (![CellType.CheckboxList, CellType.Files].includes(cellType)) {
                    this.onCellEditorClose();
                }

                const lineType = this.getLineType(lineId);
                const params = this.makeAccessorParams(cellPosition);

                const valueSetterField = lodash.get(this.getColumnsConfig(), [columnName, 'setValue']);

                const valueSetter: ValueSetter = lodash.isFunction(valueSetterField)
                    ? valueSetterField
                    : valueSetterField[lineType];

                await valueSetter(params, value);
            }
        };
    }

    private getCellType(cellPosition: CellPosition): CellType {
        const { lineId, columnName } = cellPosition;

        const cellTypeField = lodash.get(this.getColumnsConfig(), [columnName, 'type']);

        if (!cellTypeField) {
            return null;
        }

        const lineType = this.getLineType(lineId);

        return lodash.isString(cellTypeField) ? cellTypeField : cellTypeField[lineType];
    }

    private async applyCustomStyles(cellPosition: CellPosition, cellProps: any): Promise<any> {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const customStyleAccessorField = lodash.get(this.getColumnsConfig(), [columnName, 'customStyle']);

        if (customStyleAccessorField) {
            const customStyleAccessor: CustomStyleAccessor = lodash.isFunction(customStyleAccessorField)
                ? customStyleAccessorField
                : customStyleAccessorField[lineType];

            if (customStyleAccessor) {
                const params = this.makeAccessorParams(cellPosition);

                const customStyle = await customStyleAccessor(params);

                cellProps = {
                    ...cellProps,
                    customStyle: { ...cellProps.customStyle, ...customStyle },
                };
            }
        }

        if (lineType === LineType.Total) {
            cellProps.customStyle = {
                ...cellProps.customStyle,
                backgroundColor: BACKGROUND_COLOR_BY_LINE_TYPE[LineType.Total],
            };

            return cellProps;
        }

        const line = this.getLine(lineId);

        const isArchived = line.model.status === 'archived';
        const actIsApproved = ['actSignAwaited', 'actPaymentAwaited', 'actPaid'].includes(line.model.actStatus);

        if (isArchived) {
            const cellType = this.getCellType(cellPosition);

            cellProps.customStyle = {
                ...cellProps.customStyle,
                opacity: '0.6',
                textDecorationLine: cellType !== CellType.LineHeader && cellProps.title !== '—' ? 'line-through' : null,
            };
        }

        if (actIsApproved) {
            cellProps.customStyle = { ...cellProps.customStyle, backgroundColor: '#edf8f1' };
        }

        return cellProps;
    }

    private roundNumber(value: number, digitsAfterComma = 2): string {
        const roundedValue = Math.round(value * 100) / 100;
        const formatedValue = roundedValue.toFixed(digitsAfterComma);

        const [decimalPart, fractionPart] = formatedValue.split('.');

        return `${decimalPart}${fractionPart ? `.${fractionPart}` : ''}`;
    }

    private formatCurrencyValue(value: number | string): string {
        let [decimalPart, fractionPart] = value.toString().split(/[.,]/);

        let sign = '';

        if (lodash.first(decimalPart) === '-') {
            decimalPart = decimalPart.substring(1);
            sign = '-';
        }

        const splittedDecimal = decimalPart.split(/(?=(?:...)*$)/).join(' ');

        return `${sign}${splittedDecimal}${fractionPart ? `,${fractionPart}` : ''}`;
    }

    private compareValues(valueA: any = null, valueB: any = null): boolean {
        return lodash.isNumber(valueA) || lodash.isNumber(valueB)
            ? parseFloat(valueA) !== parseFloat(valueB)
            : (valueA || null) !== (valueB || null);
    }
}
