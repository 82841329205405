import * as React from 'react';

import { FakeChannelButtonProps } from './types';
import { ChannelButton } from './ChannelButton';

/** Fake channel button - to display something like channel */
export const FakeChannelButton: React.SFC<FakeChannelButtonProps> = ({
    className,
    name,
    taskId,
    activityId,
    onClick,
}: FakeChannelButtonProps) => (
    <ChannelButton
        canDelete={false}
        className={className}
        id={-1}
        taskId={taskId}
        activityId={activityId}
        name={name}
        isOpened={false}
        hasUnseenComments={false}
        onClick={(event) => {
            event.preventDefault();
            onClick();
        }}
    />
);

FakeChannelButton.displayName = 'frontend::task-board::FakeChannelButton';
