import * as React from 'react';

import { Mode, StatisticsItem, StatisticsType, StatisticsTypeFilters, Period } from '../useState';
import { Color, Result, Direction } from '../../../common';

export interface BuildParams<T extends Mode = Mode> {
    (params: Params<T>): BuildParamsData[];
}

export interface Params<T extends Mode> {
    selectedStatisticsItem: StatisticsItem<T>;
    selectedPeriod: Period;
    statisticsTypeFilters: StatisticsTypeFilters;
    setStatisticsTypeFilter: (type: StatisticsType, value: boolean) => void;
}

export interface BuildParamsData {
    color: Color;
    titleComponent: React.ClassicElement<HTMLDivElement>;
    count: number;
    progress: {
        result: Result;
        direction: Direction;
        value: number;
    };
    fractionOfTotal: number;
    periodName: string;
    darken: boolean;
    onClick: () => void;
}

export { Period };
