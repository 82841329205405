import { axios } from '../lib/axios';

import { ApproveBudgetItemForm, RejectBudgetItemForm } from '@mrm/budget';

const BASE_URL = '/api/approver';

export class ApproverApi {
    public static async approveBudgetItem(params: ApproveBudgetItemForm): Promise<void> {
        await axios.post(`${BASE_URL}/approve`, params);
    }

    public static async rejectBudgetItem(params: RejectBudgetItemForm): Promise<void> {
        await axios.post(`${BASE_URL}/reject`, params);
    }
}
