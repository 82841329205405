import { SelectItem as Item } from 'sber-marketing-ui';

import { DictionaryType } from '@api';
import type {
    Dictionary,
    CreativeRequestSubject,
    CreativeRequestAgency,
    CreativeRequestContract,
    CreativeRequestContractId,
} from '@api';

type Fields = Record<FieldType, Field>;

type FieldType =
    | 'lot'
    | 'description'
    | 'initiator'
    | 'agencyExecutors'
    | 'customer'
    | 'expert'
    | 'agencies'
    | 'contract';

interface Field {
    label: string;
    value: string | string[];
    items: Item[];
    disabled: boolean;
}

export type { Field, Fields, FieldType };

export interface OnFieldChange {
    (params: OnFieldChangeParams): void;
}

export interface OnFieldChangeParams {
    type: FieldType;
    value: string | string[];
}

export type {
    Item,
    Dictionary,
    CreativeRequestSubject,
    CreativeRequestAgency,
    CreativeRequestContract,
    CreativeRequestContractId,
};
export { DictionaryType };
