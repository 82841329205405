import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

export interface ChildrenProps {
    creativeRequestId: string;
    activityId: string;
}

interface Props extends ExternalProps, Partial<RouteComponentProps<RouteParams>> {}

interface RouteParams {
    creativeRequestId: string;
    activityId: string;
}

interface ExternalProps {
    children: (props: ChildrenProps) => JSX.Element;
}

@(withRouter as any)
export class WithRouter extends React.PureComponent<Props> {
    public render(): JSX.Element {
        return this.props.children(this.getChildrenProps());
    }

    private getChildrenProps(): ChildrenProps {
        return {
            creativeRequestId: this.creativeRequestId,
            activityId: this.activityId,
        };
    }

    private get creativeRequestId(): string {
        return this.props.match.params.creativeRequestId;
    }

    private get activityId(): string {
        return this.props.match.params.activityId;
    }
}
