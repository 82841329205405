// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-dashboards-MyTasksPage-_MyTasksPage__root{position:relative}.src-client-modules-dashboards-MyTasksPage-_MyTasksPage__topLine{display:flex;margin-top:34px;justify-content:space-between}.src-client-modules-dashboards-MyTasksPage-_MyTasksPage__sortingMenu{padding-top:5px}.src-client-modules-dashboards-MyTasksPage-_MyTasksPage__cardsViewSwitch{margin-left:10px}.src-client-modules-dashboards-MyTasksPage-_MyTasksPage__activityListWrapper{display:block}\n", "",{"version":3,"sources":["webpack://./src/client/modules/dashboards/MyTasksPage/MyTasksPage.scss"],"names":[],"mappings":"AAAA,8DACI,iBAAkB,CACrB,iEAGG,YAAa,CAEb,eAAgB,CAEhB,6BAA8B,CACjC,qEAGG,eAAgB,CACnB,yEAGG,gBAAiB,CACpB,6EAGG,aAAc","sourcesContent":[".root {\n    position: relative;\n}\n\n.topLine {\n    display: flex;\n\n    margin-top: 34px;\n\n    justify-content: space-between;\n}\n\n.sortingMenu {\n    padding-top: 5px;\n}\n\n.cardsViewSwitch {\n    margin-left: 10px;\n}\n\n.activityListWrapper {\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-dashboards-MyTasksPage-_MyTasksPage__root",
	"topLine": "src-client-modules-dashboards-MyTasksPage-_MyTasksPage__topLine",
	"sortingMenu": "src-client-modules-dashboards-MyTasksPage-_MyTasksPage__sortingMenu",
	"cardsViewSwitch": "src-client-modules-dashboards-MyTasksPage-_MyTasksPage__cardsViewSwitch",
	"activityListWrapper": "src-client-modules-dashboards-MyTasksPage-_MyTasksPage__activityListWrapper"
};
export default ___CSS_LOADER_EXPORT___;
