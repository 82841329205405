import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { Filters } from '@store/calendar/types';

import { ActivitiesInfo } from './ActivitiesInfo';
import type { StoreState } from '@store';
import { setPageMode, setFilters } from '@store/calendar/actions';
import { getActivityCardGroups, getPageData, getFilters, getExpiredActivityStages } from '@store/calendar/selectors';

interface Props extends Partial<MapProps>, Partial<DispatchProps>, Partial<RouteComponentProps> {}

interface MapProps {
    activityCardsCount: number;
    activityCardsWithExpiredStagesCount: number;
    expiredStagesFilter: boolean;
}

interface DispatchProps {
    setFilters: (filters: Filters) => void;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class ActivitiesInfoContainer extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        const { activityCardsCount, activityCardsWithExpiredStagesCount, expiredStagesFilter } = this.props;

        return React.createElement(ActivitiesInfo, {
            activityCardsCount,
            weekExpiredStagesActivities: activityCardsWithExpiredStagesCount,
            expiredStagesFilter,
            onWeekExpiredStagesClick: this.onWeekExpiredStagesClick,
        });
    }

    @autobind
    protected onWeekExpiredStagesClick() {
        this.props.setFilters({
            expiredStagesFilter: !this.props.expiredStagesFilter,
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { activities } = getPageData(state);
    const { expiredStagesFilter } = getFilters(state);

    const activityCardGroups = getActivityCardGroups(state);
    const expiredActivityStages = getExpiredActivityStages(state);

    const weekActivityCards = lodash.flatMap(activityCardGroups, (item) => item.activities);

    const weekActivityCardsWithExpiredStages = weekActivityCards
        .map((card) => activities.find((item) => item.id == card.id))
        .filter((activity) => expiredActivityStages.some((item) => item.activityId == activity.id));

    return {
        activityCardsCount: weekActivityCards.length,
        activityCardsWithExpiredStagesCount: weekActivityCardsWithExpiredStages.length,
        expiredStagesFilter,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return bindActionCreators(
        {
            setPageMode,
            setFilters,
        },
        dispatch,
    );
}
