// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-CardTypes-common-WithTitle-_WithTitle__header{margin-bottom:4px;font-family:'Open Sans';font-size:10px}.src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-CardTypes-common-WithTitle-_WithTitle__title{display:inline;letter-spacing:0.4px;text-transform:uppercase;color:#7e8681}.src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-CardTypes-common-WithTitle-_WithTitle__month{display:inline-block;margin-left:6px;padding:2px 6px;color:#000000;border-radius:3px;background-color:#ffedb7}\n", "",{"version":3,"sources":["webpack://./src/client/modules/dashboard/RightSidebar/BudgetInfo/Corrections/templates/CardTypes/common/WithTitle/WithTitle.scss"],"names":[],"mappings":"AAAA,0HACI,iBAAkB,CAElB,uBAAwB,CACxB,cAAe,CAClB,yHAGG,cAAe,CAEf,oBAAqB,CACrB,wBAAyB,CAEzB,aAAc,CACjB,yHAGG,oBAAqB,CAErB,eAAgB,CAChB,eAAgB,CAEhB,aAAc,CACd,iBAAkB,CAClB,wBAAyB","sourcesContent":[".header {\n    margin-bottom: 4px;\n\n    font-family: 'Open Sans';\n    font-size: 10px;\n}\n\n.title {\n    display: inline;\n\n    letter-spacing: 0.4px;\n    text-transform: uppercase;\n\n    color: #7e8681;\n}\n\n.month {\n    display: inline-block;\n\n    margin-left: 6px;\n    padding: 2px 6px;\n\n    color: #000000;\n    border-radius: 3px;\n    background-color: #ffedb7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-CardTypes-common-WithTitle-_WithTitle__header",
	"title": "src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-CardTypes-common-WithTitle-_WithTitle__title",
	"month": "src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-CardTypes-common-WithTitle-_WithTitle__month"
};
export default ___CSS_LOADER_EXPORT___;
