import * as React from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, IconType, CloseButton, WithTooltip } from 'sber-marketing-ui';

import { CreativeRequest, MrmClient } from '@api';

import { StoreState } from '@store';
import { UserConfigType, saveUserConfig, getUserConfigState } from '@store/userConfig';
import { Tabs } from '@store/userConfig/creative';

import { Info, Users, Comments, Files } from './widgets';

import * as styles from './Sidebar.scss';
import * as commonStyles from './CommonStyles.scss';

const TabTitles: Record<Tabs, string> = {
    [Tabs.Info]: 'Информация',
    [Tabs.Users]: 'Участники',
    [Tabs.Comments]: 'Комментарии',
    [Tabs.Files]: 'Документы',
};

interface Props {
    creativeRequestId: string;
    onClose: () => void;
}

function useSidebar(creativeRequestId: string) {
    const [creativeRequest, setCreativeRequest] = React.useState<CreativeRequest>(null);

    React.useEffect(
        function fetchCreativeRequest() {
            async function worker() {
                const client = await MrmClient.getInstance();

                setCreativeRequest(await client.domain.creativeRequests.getCreativeRequest({ id: creativeRequestId }));
            }

            worker();
        },
        [creativeRequestId],
    );

    return {
        creativeRequest,
        setCreativeRequest,
    };
}

export function Sidebar({ creativeRequestId, onClose }: Props): JSX.Element {
    const { creativeRequest, setCreativeRequest } = useSidebar(creativeRequestId);

    return (
        <div className={styles.root}>
            <TitleTabs onClose={onClose} />

            <div className={commonStyles.horizontalBar} />

            {creativeRequest && (
                <React.Fragment>
                    <div className={styles.content}>
                        <Content creativeRequest={creativeRequest} />
                    </div>

                    <CreativeRequestEffect creativeRequest={creativeRequest} setCreativeRequest={setCreativeRequest} />
                </React.Fragment>
            )}
        </div>
    );
}

interface CreativeRequestEffectProps {
    creativeRequest: CreativeRequest;
    setCreativeRequest: (creativeRequest: CreativeRequest) => void;
}

// function used only for side-effects (creativeRequest.onReloaded event listener)
function CreativeRequestEffect({ creativeRequest, setCreativeRequest }: CreativeRequestEffectProps): null {
    React.useEffect(
        function initCreativeRequestListeners() {
            creativeRequest.events.onReloaded(setCreativeRequest);

            return function resetCreativeRequestListeners() {
                creativeRequest.events.offReloaded(setCreativeRequest);
            };
        },
        [creativeRequest],
    );

    return null;
}

function useTitleTabs() {
    const dispatch = useDispatch();

    const {
        fields: { sidebar },
    } = useSelector((state: StoreState) => getUserConfigState(state)[UserConfigType.Creative]);

    function setActiveTab(selectedTab: Tabs) {
        dispatch(
            saveUserConfig({
                type: UserConfigType.Creative,
                payload: {
                    sidebar: {
                        selectedTab,
                    },
                },
            }),
        );
    }

    return {
        activeTab: sidebar.selectedTab,
        setActiveTab,
    };
}

type TitleTabProps = Pick<Props, 'onClose'>;

function TitleTabs({ onClose }: TitleTabProps): JSX.Element {
    const tabProps = useTitleTabs();

    return (
        <div className={classnames(styles.titleTabs, commonStyles.sidePaddings)}>
            <Tab tab={Tabs.Info} {...tabProps} />

            <Tab tab={Tabs.Users} {...tabProps} />

            <Tab tab={Tabs.Comments} {...tabProps} />

            <Tab tab={Tabs.Files} {...tabProps} />

            <div className={styles.closeButton}>
                <CloseButton onClick={onClose} />
            </div>
        </div>
    );
}

interface TabProps {
    tab: Tabs;
    activeTab: Tabs;
    setActiveTab: (activeTab: Tabs) => void;
}

function Tab({ tab, activeTab, setActiveTab }: TabProps): JSX.Element {
    const isActive = tab === activeTab;
    const title = TabTitles[tab];

    return (
        <WithTooltip content={title} hidden={isActive}>
            <div
                className={classnames(styles.titleTab, isActive && styles.titleTabActive)}
                onClick={() => {
                    if (!isActive) {
                        setActiveTab(tab);
                    }
                }}
            >
                <TabIcon tab={tab} />

                {isActive ? title : ''}
            </div>
        </WithTooltip>
    );
}

interface TabIconProps {
    tab: Tabs;
}

function TabIcon({ tab }: TabIconProps): JSX.Element {
    let icon: IconType;
    switch (tab) {
        case Tabs.Info:
            icon = IconType.CREATIVE_INFO_TAB;
            break;
        case Tabs.Users:
            icon = IconType.CREATIVE_USERS_TAB;
            break;
        case Tabs.Comments:
            icon = IconType.CREATIVE_COMMENTS_TAB;
            break;
        case Tabs.Files:
            icon = IconType.CREATIVE_FILES_TAB;
            break;
        default:
            break;
    }

    return icon ? <Icon type={icon} svgSize={24} className={styles.titleTabIcon} /> : null;
}

interface ContentProps {
    creativeRequest: CreativeRequest;
}

function Content({ creativeRequest }: ContentProps): JSX.Element {
    const {
        fields: { sidebar },
    } = useSelector((state: StoreState) => getUserConfigState(state)[UserConfigType.Creative]);

    switch (sidebar.selectedTab) {
        case Tabs.Info:
            return <Info creativeRequest={creativeRequest} />;
        case Tabs.Users:
            return <Users creativeRequest={creativeRequest} />;
        case Tabs.Comments:
            return <Comments creativeRequest={creativeRequest} />;
        case Tabs.Files:
            return <Files creativeRequest={creativeRequest} />;
        default:
            return null;
    }
}
