// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-Titles-_Title__root{margin-bottom:8px;font-family:'Open Sans';font-weight:600;font-size:14px;line-height:19px;color:black}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/InternalTransitionSumEntering/Titles/Title.scss"],"names":[],"mappings":"AAAA,2HACI,iBAAkB,CAElB,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CAEjB,WAAY","sourcesContent":[".root {\n    margin-bottom: 8px;\n    \n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 19px;\n\n    color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-Titles-_Title__root"
};
export default ___CSS_LOADER_EXPORT___;
