import * as React from 'react';
import autobind from 'autobind-decorator';

import { Quarter } from './Quarter';
import { TimeCalculator } from '../../modules';

interface Props {
    year: number;
    quarter: number;
    onQuarterClick(quarter: number, year: number): void;
}

interface State {
    width: number;
}

export class QuarterContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            width: this.getQuarterWidth(this.props.year, this.props.quarter),
        };
    }

    public UNSAFE_componentWillReceiveProps({ year: nextYear, quarter: nextQuarter }: Props) {
        const { quarter, year } = this.props;

        if (nextYear !== quarter || nextQuarter !== year) {
            this.setState(() => ({
                width: this.getQuarterWidth(nextYear, nextQuarter),
            }));
        }
    }

    public render() {
        return React.createElement(Quarter, {
            year: this.props.year,
            quarter: this.props.quarter,
            width: this.state.width,
            onClick: this.onClick,
        });
    }

    private getQuarterWidth(year: number, quarter: number): number {
        const calculator = TimeCalculator.getInstance();

        const fullWidth = calculator.getYearViewportWidth();

        return calculator.getQuarterWidth(year, quarter, fullWidth);
    }

    @autobind
    private onClick() {
        const { year, quarter, onQuarterClick } = this.props;
        onQuarterClick(quarter, year);
    }
}
