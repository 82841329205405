import * as React from 'react';
import { findKey } from 'lodash';

import { FirstLotContent } from './FirstLotContent';
import { SecondLotContent } from './SecondLotContent';

import type { LotContentProps, LotType } from './types';
import { LOT_IDS_BY_LOT_TYPE_MAP } from './const';

interface Props extends LotContentProps {}

const contentByLotMap: Record<LotType, React.FC<LotContentProps>> = {
    first: FirstLotContent,
    second: SecondLotContent,
};

export const Content: React.FC<Props> = ({ fields, onFieldChange }) => {
    const SelectedContent = contentByLotMap[findKey(LOT_IDS_BY_LOT_TYPE_MAP, (lotId) => fields.lot.value === lotId)];

    return (
        <div>
            <SelectedContent fields={fields} onFieldChange={onFieldChange} />
        </div>
    );
};
