// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-TransferSwitch-_TransferSwitch__root{margin-left:auto;display:flex;align-items:center}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-TransferSwitch-_TransferSwitch__title{cursor:pointer;user-select:none;letter-spacing:-0.25px;color:#a1a6a3;font-family:'Open Sans';font-size:12px}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-TransferSwitch-_TransferSwitch__title.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-TransferSwitch-_TransferSwitch__selected{color:#000000}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-TransferSwitch-_TransferSwitch__toggleWrapper{margin:0 8px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/TransferSwitch/TransferSwitch.scss"],"names":[],"mappings":"AAAA,8GACI,gBAAiB,CAEjB,YAAa,CAEb,kBAAmB,CACtB,+GAGG,cAAe,CACf,gBAAiB,CACjB,sBAAuB,CAEvB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CARnB,gOAWQ,aAAc,CACjB,uHAID,YAAa","sourcesContent":[".root {\n    margin-left: auto;\n\n    display: flex;\n\n    align-items: center;\n}\n\n.title {\n    cursor: pointer;\n    user-select: none;\n    letter-spacing: -0.25px;\n\n    color: #a1a6a3;\n\n    font-family: 'Open Sans';\n    font-size: 12px;\n\n    &.selected {\n        color: #000000;\n    }\n}\n\n.toggleWrapper {\n    margin: 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-TransferSwitch-_TransferSwitch__root",
	"title": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-TransferSwitch-_TransferSwitch__title",
	"selected": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-TransferSwitch-_TransferSwitch__selected",
	"toggleWrapper": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-TransferSwitch-_TransferSwitch__toggleWrapper"
};
export default ___CSS_LOADER_EXPORT___;
