import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconType } from 'sber-marketing-ui';

import { FileInput } from '@common/FileInput';

import * as style from './DragNDrop.scss';

interface Props {
    isActive: boolean;
    isOverRootElement: boolean;
    rootRef: React.RefObject<HTMLDivElement>;
    fileInputRef: React.RefObject<FileInput>;
    onFileInputChange: (files: File[]) => void;
    onAttachButtonClick: () => void;
}

export function DragNDrop({
    isActive,
    isOverRootElement,
    rootRef,
    fileInputRef,
    onFileInputChange,
    onAttachButtonClick,
}: Props) {
    return isActive ? (
        <div
            className={classNames(
                style.root,
                isActive ? style.active : style.inactive,
                isOverRootElement && style.fileOver,
            )}
            ref={rootRef}
        >
            <>
                <div className={style.activeIcon}>
                    <Icon type={IconType.FILE24} svgSize={24} />
                </div>

                <div className={style.title}>Перетащите сюда</div>
            </>

            {/* {!isActive &&
                <>
                    <div className={style.inactiveIcon}>
                        <Icon
                            type={IconType.ATTACH_NORMAL}
                            svgSize={22}
                            color={'transparent'}
                        />
                    </div>

                    <div className={style.title}>
                        Перетащите сюда файл или&nbsp;
                        <span className={style.loadButton} onClick={onAttachButtonClick}>загрузите</span>
                    </div>
                </>
            } */}

            <FileInput ref={fileInputRef} onChange={onFileInputChange} />
        </div>
    ) : null;
}
