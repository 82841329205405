import { bindActionCreators } from 'redux';
import autobind from 'autobind-decorator';

import { store } from '@store';
import { FileApi, FileApiUploadParams } from '@api';
import { FileAsset } from '@store/commonTypes';
import { add as addFileAsset, runUploadOne } from '@store/fileAssets';

import { BriefSaver } from '../BudgetCard/Brief';

interface FileAssetParams {
    briefId: string;
    fieldId: string;
    file: File;
}

export class BriefsSaver {
    private static instance: BriefsSaver = null;
    private briefSavers: BriefSaver[] = [];
    private dispatch = {
        ...bindActionCreators(
            {
                addFileAsset,
            },
            store.dispatch,
        ),
        uploadFile: (fileAsset: FileAsset, params: FileApiUploadParams) =>
            runUploadOne({
                params,
                fileOrAssetOrId: fileAsset,
                dispatch: store.dispatch,
            }),
    };

    public static getInstance(): BriefsSaver {
        if (!BriefsSaver.instance) {
            BriefsSaver.instance = new BriefsSaver();
        }

        return BriefsSaver.instance;
    }

    public async saveBriefs() {
        await Promise.all(this.briefSavers.map((briefSaver) => briefSaver.saveBrief()));
    }

    public registerBriefSaver(briefSaver: BriefSaver): void {
        this.briefSavers.push(briefSaver);
    }

    public removeBriefSaver(briefSaver: BriefSaver): void {
        this.briefSavers = this.briefSavers.filter((currentBriefSaver) => currentBriefSaver !== briefSaver);
    }

    @autobind
    public createFileAsset({ briefId, fieldId, file }: FileAssetParams): FileAsset {
        const fileAsset = FileApi.makeFileAsset({ briefId, fieldId }, file);

        this.dispatch.addFileAsset(fileAsset);

        return fileAsset;
    }

    public async uploadFile(fieldId: string, briefId: string, fileAsset: FileAsset): Promise<FileAsset> {
        const loadedAsset: FileAsset = await this.dispatch.uploadFile(fileAsset, { fieldId, briefId });

        this.dispatch.addFileAsset(loadedAsset);

        return loadedAsset;
    }
}
