import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { UserCard } from 'sber-marketing-types/frontend';
import { ColumnName, CreativeRequestDomain, TableType } from '@store/creative/types';
import { WatchCommentsByItemAndColumnState } from '@store/creative/sidebar/types';
import { CellPosition, ColumnWidths, Line, LineId, TableBodyCellParams, TableHeaderCellParams } from './types';
import { TableEvent } from './types';
import type { StoreState } from '@store';
import {
    Project,
    ProjectBudgetItem,
    CreativeRequest,
    CreativeRequestItem,
    CreativeRequestDonor,
    CreativeRequestContract,
    Dictionary,
    DictionaryType,
} from '@api';

import { CellsStorage, TableView, CellEvent } from 'sber-marketing-ui';
import { TableTemplate } from './TableTemplate';
import {
    MakeLeftFixedColumns,
    MakeTariffTableColumns,
    MakeProductionTableColumns,
    MakeAkTableColumns,
    MakeTariffRightFixedColumns,
    MakeProductionTableRightFixedColumns,
    MakeAkTableRightFixedColumns,
    MakeColumnsConfig,
    ColumnParams,
    ColumnsConfigParams,
    AccessorParams,
    CellType,
} from './ColumnsConfig';
import { ColumnHeaderFactory, CellsFactory } from './modules';
import { setWatchCommentsByItemAndColumn } from '@store/creative/sidebar/actions';
import {
    CreativeUserConfig,
    getCreativeUserConfig,
    Tabs,
    updateCreativeUserConfig,
    UpdateCreativeUserConfig,
} from '@store/userConfig/creative';
import { getAllUsers } from '@store/appUsers/selectors';

interface Props extends Partial<MapProps & DispatchProps> {
    displayStatusColumns: boolean;
    loading: boolean;
    getProject: () => Project;
    getCreativeRequest: () => CreativeRequestDomain;
    getLine: (lineId: string) => Line;
    getLines: () => Line[];
    getCreativeRequestLot: () => string;
    getDictionaries: () => Partial<Record<DictionaryType, Dictionary[]>>;
    getContracts: () => Record<'lot1' | 'lot2', CreativeRequestContract[]>;
    createLine: (tableType: TableType) => Promise<void>;
    archiveLine: (lineId: string) => Promise<void>;
    restoreLine: (lineId: string) => Promise<void>;
}

interface MapProps {
    userConfig: CreativeUserConfig;
    users: UserCard[];
}

interface DispatchProps {
    updateCreativeUserConfig: (params: UpdateCreativeUserConfig) => void;
    setWatchCommentsByItemAndColumn: (cellPosition: WatchCommentsByItemAndColumnState) => void;
}

interface State {
    tariffLineIds: LineId[];
    productionLineIds: LineId[];
    akLineIds: LineId[];
    columns: Record<TableType, Record<'left' | 'center' | 'right', ColumnName[]>>;
    visibleColumns: Record<TableType, ColumnName[]>;
}

@(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true }) as any)
export class TableBehaviour extends React.PureComponent<Props, State> {
    private headerCellsStorage: Record<TableType, CellsStorage<string, TableHeaderCellParams>>;
    private tableCellsStorage: CellsStorage<CellPosition, TableBodyCellParams>;
    private tariffTable: TableView;
    private productionTable: TableView;
    private akTable: TableView;
    private columnHeaderFactory: ColumnHeaderFactory;
    private cellsFactory: CellsFactory;
    private fixedWidthColumns: ColumnName[];
    private defaultColumnWidths: ColumnWidths = {};
    private updateCreativeUserConfig: (params: UpdateCreativeUserConfig) => void;

    public constructor(props: Props) {
        super(props);

        const columnsConfigParams = this.makeColumnsConfigParams();

        this.state = {
            tariffLineIds: [],
            productionLineIds: [],
            akLineIds: [],
            columns: {
                [TableType.Tariff]: {
                    left: MakeLeftFixedColumns(columnsConfigParams) as ColumnName[],
                    center: MakeTariffTableColumns(columnsConfigParams) as ColumnName[],
                    right: MakeTariffRightFixedColumns(columnsConfigParams) as ColumnName[],
                },
                [TableType.Production]: {
                    left: MakeLeftFixedColumns(columnsConfigParams) as ColumnName[],
                    center: MakeProductionTableColumns(columnsConfigParams) as ColumnName[],
                    right: MakeProductionTableRightFixedColumns(columnsConfigParams) as ColumnName[],
                },
                [TableType.Ak]: {
                    left: MakeLeftFixedColumns(columnsConfigParams) as ColumnName[],
                    center: MakeAkTableColumns(columnsConfigParams) as ColumnName[],
                    right: MakeAkTableRightFixedColumns(columnsConfigParams) as ColumnName[],
                },
            },
            visibleColumns: {
                [TableType.Tariff]: [
                    ...(MakeLeftFixedColumns(columnsConfigParams) as ColumnName[]),
                    ...(MakeTariffTableColumns(columnsConfigParams) as ColumnName[]),
                    ...(MakeTariffRightFixedColumns({ displayStatusColumns: true }) as ColumnName[]),
                ],
                [TableType.Production]: [
                    ...(MakeLeftFixedColumns(columnsConfigParams) as ColumnName[]),
                    ...(MakeProductionTableColumns(columnsConfigParams) as ColumnName[]),
                    ...(MakeProductionTableRightFixedColumns({ displayStatusColumns: true }) as ColumnName[]),
                ],
                [TableType.Ak]: [
                    ...(MakeLeftFixedColumns(columnsConfigParams) as ColumnName[]),
                    ...(MakeAkTableColumns(columnsConfigParams) as ColumnName[]),
                    ...(MakeAkTableRightFixedColumns({ displayStatusColumns: true }) as ColumnName[]),
                ],
            },
        };

        this.columnHeaderFactory = new ColumnHeaderFactory({
            getColumnsConfig: this.getColumnsConfig,
            getAllLines: this.props.getLines,
            getLeftFixedColumns: this.getLeftFixedColumns,
            onColumnFixClick: this.onColumnFixClick,
        });

        this.cellsFactory = new CellsFactory({
            getLine: this.props.getLine,
            getAllLines: this.props.getLines,
            getContracts: this.getContracts,
            getColumnsConfig: this.getColumnsConfig,
            getAccessorParamsData: this.getAccessorParamsData,
            onCellEditorClose: this.onCellEditorClose,
            onLineArchive: this.onLineArchiveClick,
            onLineRestore: this.onLineRestoreClick,
            onCommentsButtonClick: this.onCommentsButtonClick,
        });

        this.headerCellsStorage = {
            [TableType.Tariff]: new CellsStorage({
                makeCellParams: (columnName: ColumnName) =>
                    this.columnHeaderFactory.makeColumnHeaderParams(TableType.Tariff, columnName),
            }),
            [TableType.Production]: new CellsStorage({
                makeCellParams: (columnName: ColumnName) =>
                    this.columnHeaderFactory.makeColumnHeaderParams(TableType.Production, columnName),
            }),
            [TableType.Ak]: new CellsStorage({
                makeCellParams: (columnName: ColumnName) =>
                    this.columnHeaderFactory.makeColumnHeaderParams(TableType.Ak, columnName),
            }),
        };

        this.tableCellsStorage = new CellsStorage({
            makeCellParams: this.cellsFactory.makeCellParams,
        });

        this.updateDefaultColumnWidths();
        this.updateFixedWidthColumns();

        this.updateCreativeUserConfig = lodash.debounce((params) => props.updateCreativeUserConfig(params), 1000);
    }

    public async componentDidMount() {
        if (this.props.userConfig.columnsWidth) {
            this.initColumnsWidthFromUserConfig();
        }
    }

    public async componentDidUpdate(prevProps: Props, prevState: State) {
        const displayStatusColumnsChanged = this.props.displayStatusColumns !== prevProps.displayStatusColumns;
        const userConfigColumnsChanged = this.props.userConfig.tableColumns !== prevProps.userConfig.tableColumns;
        const userConfigEnabledColumnsChanged =
            this.props.userConfig.enabledColumns !== prevProps.userConfig.enabledColumns;
        const userConfigColumnsWidthChanged = this.props.userConfig.columnsWidth !== prevProps.userConfig.columnsWidth;
        const loadingFinished = !this.props.loading && prevProps.loading;

        if (displayStatusColumnsChanged) {
            this.updateTableColumns();
        }

        if (userConfigColumnsChanged) {
            this.initColumnsFromUserConfig();
        }

        if (userConfigEnabledColumnsChanged) {
            this.initEnabledColumnsFromUserConfig();
        }

        if (userConfigColumnsWidthChanged) {
            this.initColumnsWidthFromUserConfig();
        }

        if (loadingFinished) {
            await this.updateTableLineIds();
        }
    }

    public render(): JSX.Element {
        const { loading } = this.props;
        const { tariffLineIds, productionLineIds, akLineIds, visibleColumns } = this.state;

        return React.createElement(TableTemplate, {
            loading,
            headerCellsStorage: this.headerCellsStorage,
            tableCellsStorage: this.tableCellsStorage,
            tableColumns: this.state.columns,
            fixedWidthColumns: this.fixedWidthColumns,
            visibleColumns,
            tariffTablelines: tariffLineIds,
            productionTablelines: productionLineIds,
            akTablelines: akLineIds,
            columnWidths: this.defaultColumnWidths,
            tariffTableRef: this.tariffTableRef,
            productionTableRef: this.productionTableRef,
            akTableRef: this.akTableRef,
            onAddButtonClick: this.onAddButtonClick,
            onCellEvent: this.onCellEvent,
            onVisibleColumnsChange: this.onVisibleColumnsChange,
            onColumnWidthsChange: this.onColumnWidthsChange,
        });
    }

    @autobind
    public scrollToCell(cellPosition: CellPosition) {
        const { lineId } = cellPosition;
        const { tariffLineIds, productionLineIds, akLineIds } = this.state;

        if (tariffLineIds.includes(lineId)) {
            this.tariffTable.scrollToCell(cellPosition);
            this.tariffTable.setHighlightPosition(cellPosition);
        }

        if (productionLineIds.includes(lineId)) {
            this.productionTable.scrollToCell(cellPosition);
            this.productionTable.setHighlightPosition(cellPosition);
        }

        if (akLineIds.includes(lineId)) {
            this.akTable.scrollToCell(cellPosition);
            this.akTable.setHighlightPosition(cellPosition);
        }
    }

    @autobind
    public async onCreativeRequestReload(newCreativeRequest: CreativeRequest) {
        if (!this.props.loading) {
            await this.updateTableLineIds();

            this.tableCellsStorage.clearAllCells();

            const visibleLinesIds = lodash.compact([
                ...this.tariffTable.getVisibleLinesIds(),
                ...this.productionTable.getVisibleLinesIds(),
                ...this.akTable.getVisibleLinesIds(),
            ]);

            await Promise.all([
                ...visibleLinesIds.map(async (lineId) => {
                    await this.updateLineCells(lineId);
                }),
                this.updateLineCells('tariffTotal'),
                this.updateLineCells('productionTotal'),
                this.updateLineCells('akTotal'),
            ]);
        }
    }

    @autobind
    public async onCreativeRequestBudgetItemUpdate(creativeRequestDonor: CreativeRequestDonor) {
        await this.updateTableLineIds();

        this.tableCellsStorage.clearAllCells();

        const visibleLinesIds = lodash.compact([
            ...this.tariffTable.getVisibleLinesIds(),
            ...this.productionTable.getVisibleLinesIds(),
            ...this.akTable.getVisibleLinesIds(),
        ]);

        await Promise.all([
            ...visibleLinesIds.map(async (lineId) => {
                await this.updateLineCells(lineId);
            }),
            this.updateLineCells('tariffTotal'),
            this.updateLineCells('productionTotal'),
            this.updateLineCells('akTotal'),
        ]);
    }

    @autobind
    public async onLineCreate(newLine: CreativeRequestItem) {
        await this.updateTableLineIds();
    }

    @autobind
    public async onLineUpdate(lineId: string) {
        const tableType = await this.getTableTypeByLineId(lineId);

        const totalLineIdByTableType = {
            [TableType.Tariff]: 'tariffTotal',
            [TableType.Production]: 'productionTotal',
            [TableType.Ak]: 'akTotal',
        };

        await Promise.all([
            this.updateTableLineIds(),
            this.updateLineCells(lineId),
            this.updateLineCells(totalLineIdByTableType[tableType]),
        ]);
    }

    @autobind
    public async onBudgetItemsAdded(budgetItem: ProjectBudgetItem): Promise<void> {
        const lines = this.props.getLines();
        await Promise.all(lines.map((line) => line.model.addAcceptor({ acceptorId: budgetItem.model.id })));

        await this.updateTableLineIds();

        this.tableCellsStorage.clearAllCells();

        const visibleLinesIds = lodash.compact([
            ...this.tariffTable.getVisibleLinesIds(),
            ...this.productionTable.getVisibleLinesIds(),
            ...this.akTable.getVisibleLinesIds(),
        ]);

        await Promise.all([
            ...visibleLinesIds.map(async (lineId) => {
                await this.updateLineCells(lineId);
            }),
            this.updateLineCells('tariffTotal'),
            this.updateLineCells('productionTotal'),
            this.updateLineCells('akTotal'),
        ]);
    }

    @autobind
    public async onBudgetItemsRemoved(budgetItem: ProjectBudgetItem): Promise<void> {
        const lines = this.props.getLines();
        await Promise.all(lines.map((line) => line.model.addAcceptor({ acceptorId: budgetItem.model.id })));

        await this.updateTableLineIds();

        this.tableCellsStorage.clearAllCells();

        const visibleLinesIds = lodash.compact([
            ...this.tariffTable.getVisibleLinesIds(),
            ...this.productionTable.getVisibleLinesIds(),
            ...this.akTable.getVisibleLinesIds(),
        ]);

        await Promise.all([
            ...visibleLinesIds.map(async (lineId) => {
                await this.updateLineCells(lineId);
            }),
            this.updateLineCells('tariffTotal'),
            this.updateLineCells('productionTotal'),
            this.updateLineCells('akTotal'),
        ]);
    }

    @autobind
    public async onLineReloaded(newLine: CreativeRequestItem) {
        const lineId = newLine.model.id;

        const tableType = await this.getTableTypeByLineId(lineId);

        const totalLineIdByTableType = {
            [TableType.Tariff]: 'tariffTotal',
            [TableType.Production]: 'productionTotal',
            [TableType.Ak]: 'akTotal',
        };

        await Promise.all([
            this.updateTableLineIds(),
            this.updateLineCells(lineId),
            this.updateLineCells(totalLineIdByTableType[tableType]),
        ]);
    }

    @autobind
    public async onLineCommentsUpdate(lineId: string) {
        await this.updateLineCells(lineId);
    }

    public setColumnWidths(columnWidths: ColumnWidths) {
        if (columnWidths) {
            this.tariffTable.setColumnWidths(columnWidths);
            this.productionTable.setColumnWidths(columnWidths);
            this.akTable.setColumnWidths(columnWidths);
        }
    }

    @autobind
    public async getTableDataForXLSX(tableType: TableType): Promise<{
        columnsWidths: number[];
        headers: string[];
        rows: React.ReactText[][];
    }> {
        const columns = this.state.columns[tableType];

        const allColumns = [...columns.left, ...columns.center, ...columns.right];

        const { tariffLineIds, productionLineIds, akLineIds } = this.state;

        const lineIdsByTableType = {
            [TableType.Tariff]: tariffLineIds,
            [TableType.Production]: productionLineIds,
            [TableType.Ak]: akLineIds,
        };

        const lineIds = lineIdsByTableType[tableType];

        const lines = lodash.compact(lineIds.map((lineId) => this.props.getLine(lineId)));

        const activeLines = lines.filter((line) => line.model.status !== 'archived');

        const tableRefsByTableType = {
            [TableType.Tariff]: this.tariffTable,
            [TableType.Production]: this.productionTable,
            [TableType.Ak]: this.akTable,
        };

        const columnWidths = tableRefsByTableType[tableType].getColumnWidths();

        const headers = await Promise.all(
            allColumns.map(async (columnName) => {
                const params = await this.headerCellsStorage[tableType].getCellParams(columnName);

                const headerTitle = params.cellProps.title.replace('\n', '');

                return headerTitle;
            }),
        );

        const rows = await Promise.all(
            activeLines.map(
                async (line) =>
                    await Promise.all(
                        allColumns.map(async (columnName) => {
                            const lineId = line.model.id;

                            const cellParams = await this.tableCellsStorage.getCellParams({ lineId, columnName });

                            const columnsConfig = this.getColumnsConfig();

                            const cellType = columnsConfig[columnName].type;

                            let title = cellParams?.cellProps?.title || '';

                            if (
                                cellType === CellType.LineHeader ||
                                cellType === CellType.Text ||
                                cellType === CellType.Select ||
                                cellType === CellType.Input
                            ) {
                                title = title.toString();
                            }

                            if (cellType === CellType.FundsInput || cellType === CellType.FundsSelect) {
                                title = title.replaceAll(' ', '');
                                title = title.replaceAll(',', '.');
                                title = parseFloat(title);

                                if (lodash.isNaN(title)) {
                                    title = '';
                                }
                            }

                            if (cellType === CellType.Status) {
                                title = cellParams?.cellProps?.isActive ? 'Да' : 'Нет';
                            }

                            return title;
                        }),
                    ),
            ),
        );

        return {
            columnsWidths: allColumns.map((columnName) => columnWidths[columnName]),
            headers,
            rows,
        };
    }

    @autobind
    protected tariffTableRef(component: TableView) {
        this.tariffTable = component || null;
    }

    @autobind
    protected productionTableRef(component: TableView) {
        this.productionTable = component || null;
    }

    @autobind
    protected akTableRef(component: TableView) {
        this.akTable = component || null;
    }

    @autobind
    protected async onAddButtonClick(tableType: TableType) {
        await this.props.createLine(tableType);
    }

    @autobind
    protected async onLineArchiveClick(lineId: LineId) {
        this.props.archiveLine(lineId);
    }

    @autobind
    protected async onLineRestoreClick(lineId: LineId) {
        this.props.restoreLine(lineId);
    }

    @autobind
    protected async onCommentsButtonClick(cellPosition: CellPosition) {
        const { lineId, columnName } = cellPosition;

        this.props.updateCreativeUserConfig({
            sidebar: {
                visibility: true,
                selectedTab: Tabs.Comments,
            },
        });

        this.props.setWatchCommentsByItemAndColumn({
            creativeRequestItemId: lineId,
            column: columnName,
        });
    }

    @autobind
    protected onCellEditorClose() {
        this.tariffTable.setCursorPosition(null);
        this.productionTable.setCursorPosition(null);
        this.akTable.setCursorPosition(null);
    }

    @autobind
    protected onVisibleColumnsChange(table: TableType, newVisibleColumns: ColumnName[]) {
        const updatedVisibleColumns = {
            ...this.state.visibleColumns,
            [table]: lodash.uniq([...newVisibleColumns, ColumnName.LineStatus, ColumnName.ActStatus]),
        };

        this.setState(
            {
                visibleColumns: updatedVisibleColumns,
            },
            () => {
                this.props.updateCreativeUserConfig({
                    enabledColumns: updatedVisibleColumns,
                });
            },
        );
    }

    @autobind
    protected onColumnWidthsChange(columnsWidth: ColumnWidths) {
        this.updateCreativeUserConfig({ columnsWidth });
    }

    @autobind
    protected onTableEvent(eventType: TableEvent, table: TableType) {
        switch (eventType) {
            case TableEvent.Scroll:
                break;

            case TableEvent.SizeChange:
                break;
        }
    }

    @autobind
    protected async onCellEvent(eventType: CellEvent, position: CellPosition) {
        switch (eventType) {
            case CellEvent.MouseSelection:
                await this.updateCell(position, true);
                this.tariffTable.setHighlightPosition(null);
                this.productionTable.setHighlightPosition(null);
                this.akTable.setHighlightPosition(null);
                break;

            case CellEvent.SelectionCancel:
                await this.updateCell(position, false);
                break;
        }
    }

    @autobind
    public getLeftFixedColumns(): Record<TableType, ColumnName[]> {
        return {
            [TableType.Tariff]: this.state.columns[TableType.Tariff].left,
            [TableType.Production]: this.state.columns[TableType.Production].left,
            [TableType.Ak]: this.state.columns[TableType.Ak].left,
        };
    }

    @autobind
    private onColumnFixClick(table: TableType, columnName: ColumnName) {
        const { columns } = this.state;

        let defaultLeftColumns = MakeLeftFixedColumns({});
        let defaultCentralColumns: ColumnName[];

        switch (table) {
            case TableType.Tariff:
                defaultCentralColumns = MakeTariffTableColumns({}) as ColumnName[];
                break;

            case TableType.Production:
                defaultCentralColumns = MakeProductionTableColumns({}) as ColumnName[];
                break;

            case TableType.Ak:
                defaultCentralColumns = MakeAkTableColumns({}) as ColumnName[];
                break;
        }

        const leftColumns = lodash.xor(columns[table].left, [columnName]);
        const centralColumns = lodash.without([...defaultLeftColumns, ...defaultCentralColumns], ...leftColumns);

        const updatedColumns = {
            ...columns,
            [table]: {
                ...columns[table],
                left: leftColumns,
                center: centralColumns,
            },
        };

        this.setState(
            {
                columns: updatedColumns,
            },
            () => {
                this.updateCreativeUserConfig({
                    tableColumns: updatedColumns,
                });
            },
        );
    }

    private initColumnsFromUserConfig() {
        const columns = lodash.clone(this.props.userConfig.tableColumns) as Record<
            TableType,
            Record<'left' | 'center' | 'right', ColumnName[]>
        >;

        let defaultLeftColumns = MakeLeftFixedColumns({});
        let defaultCentralColumns = {
            [TableType.Tariff]: MakeTariffTableColumns({}) as ColumnName[],
            [TableType.Production]: MakeProductionTableColumns({}) as ColumnName[],
            [TableType.Ak]: MakeAkTableColumns({}) as ColumnName[],
        };

        const columnsConfig = this.getColumnsConfig();

        lodash.forEach(columns, (tableColumns, tableType) => {
            tableColumns.left = tableColumns.left.filter((columnName) => !!columnsConfig[columnName]);
            tableColumns.center = lodash.without(
                [...defaultLeftColumns, ...defaultCentralColumns[tableType]],
                ...tableColumns.left,
            );
        });

        this.setState({
            columns,
        });
    }

    private initEnabledColumnsFromUserConfig() {
        let enabledColumns = lodash.clone(this.props.userConfig.enabledColumns) as Record<TableType, ColumnName[]>;

        const columnsConfig = this.getColumnsConfig();

        lodash.forEach(enabledColumns, (tableEnabledColumns, tableType) => {
            enabledColumns[tableType] = lodash.uniq([
                ...tableEnabledColumns.filter((columnName) => !!columnsConfig[columnName]),
                ColumnName.LineStatus,
                ColumnName.ActStatus,
            ]);
        });

        this.setState({
            visibleColumns: enabledColumns,
        });
    }

    private initColumnsWidthFromUserConfig() {
        const { userConfig } = this.props;

        this.defaultColumnWidths = userConfig.columnsWidth;

        const tables = [this.tariffTable, this.productionTable, this.akTable];

        tables.forEach((table) => {
            if (table) {
                table.setColumnWidths(userConfig.columnsWidth);
            }
        });
    }

    private async updateHeaderCell(table: TableType, columnName: ColumnName) {
        const cellParams = await this.columnHeaderFactory.makeColumnHeaderParams(table, columnName);

        this.headerCellsStorage[table].setCellParams(columnName, cellParams);
    }

    private async updateCell(position: CellPosition, edit = false) {
        const cellParams = await this.cellsFactory.makeCellParams(position, edit);

        this.tableCellsStorage.setCellParams(position, cellParams);
    }

    private async updateLineCells(lineId: LineId) {
        if (lineId) {
            const tableType = await this.getTableTypeByLineId(lineId);

            const table = this.getTableByType(tableType);

            const { left, center, right } = this.state.columns[tableType];

            [...left, ...center, ...right].forEach(async (columnName) => {
                const cellPosition = { lineId, columnName };

                const cellEditStatus = table.getCellEditStatus(cellPosition);

                this.updateCell(cellPosition, cellEditStatus);
            });
        }
    }

    private updateDefaultColumnWidths() {
        const columnsConfig = this.getColumnsConfig();

        this.defaultColumnWidths = lodash.mapValues(columnsConfig, (item) => item.defaultWidth);
    }

    private updateFixedWidthColumns() {
        const columnsConfig = this.getColumnsConfig();

        this.fixedWidthColumns = lodash
            .keys(columnsConfig)
            .filter((columnName) => columnsConfig[columnName].disableWidthChange) as ColumnName[];
    }

    private updateTableColumns() {
        const columnsConfigParams = this.makeColumnsConfigParams();

        this.setState({
            columns: {
                [TableType.Tariff]: {
                    left: MakeLeftFixedColumns(columnsConfigParams) as ColumnName[],
                    center: MakeTariffTableColumns(columnsConfigParams) as ColumnName[],
                    right: MakeTariffRightFixedColumns(columnsConfigParams) as ColumnName[],
                },
                [TableType.Production]: {
                    left: MakeLeftFixedColumns(columnsConfigParams) as ColumnName[],
                    center: MakeProductionTableColumns(columnsConfigParams) as ColumnName[],
                    right: MakeProductionTableRightFixedColumns(columnsConfigParams) as ColumnName[],
                },
                [TableType.Ak]: {
                    left: MakeLeftFixedColumns(columnsConfigParams) as ColumnName[],
                    center: MakeAkTableColumns(columnsConfigParams) as ColumnName[],
                    right: MakeAkTableRightFixedColumns(columnsConfigParams) as ColumnName[],
                },
            },
        });
    }

    @autobind
    private async updateTableLineIds() {
        const lines = this.props.getLines();

        lines.sort((itemA: CreativeRequestItem, itemB: CreativeRequestItem) => itemA.model.number - itemB.model.number);

        const creativeRequestGroups = await Promise.all(lines.map((item) => item.model.creativeRequestGroup));

        const items = lines.map((item, index) => ({
            id: item.model.id,
            creativeRequestGroup: creativeRequestGroups[index],
            actualCostWithoutVat: item.model.actualCostWithoutVat,
        }));

        const tariffLineIds: string[] = [];
        const productionLineIds: string[] = [];
        const akLineIds: string[] = [];

        items.forEach((item) => {
            switch (item.creativeRequestGroup.value) {
                case TableType.Tariff:
                    tariffLineIds.push(item.id);
                    break;

                case TableType.Production:
                    productionLineIds.push(item.id);
                    break;

                case TableType.Ak:
                    akLineIds.push(item.id);
                    break;
            }
        });

        if (
            items.some((item) => item.creativeRequestGroup.value === TableType.Tariff && item.actualCostWithoutVat > 0)
        ) {
            tariffLineIds.push('tariffTotal');
        }

        if (
            items.some(
                (item) => item.creativeRequestGroup.value === TableType.Production && item.actualCostWithoutVat > 0,
            )
        ) {
            productionLineIds.push('productionTotal');
        }

        if (items.some((item) => item.creativeRequestGroup.value === TableType.Ak)) {
            akLineIds.push('akTotal');
        }

        this.setState({
            tariffLineIds,
            productionLineIds,
            akLineIds,
        });
    }

    @autobind
    private getColumnsConfig(): { [columnName: string]: ColumnParams } {
        const columnsConfigParams = this.makeColumnsConfigParams();

        return MakeColumnsConfig(columnsConfigParams);
    }

    @autobind
    private getAccessorParamsData(): Partial<AccessorParams> {
        return {
            project: this.props.getProject(),
            creativeRequest: this.props.getCreativeRequest(),
            creativeRequestLot: this.props.getCreativeRequestLot(),
            dictionariesByType: this.props.getDictionaries(),
            users: this.props.users,
        };
    }

    @autobind
    private makeColumnsConfigParams(): ColumnsConfigParams {
        return {
            displayStatusColumns: this.props.displayStatusColumns,
        };
    }

    @autobind
    private async getTableTypeByLineId(lineId: LineId): Promise<TableType> {
        const tableTypeByTotalLineId = {
            tariffTotal: TableType.Tariff,
            productionTotal: TableType.Production,
            akTotal: TableType.Ak,
        };

        if (tableTypeByTotalLineId[lineId]) {
            return tableTypeByTotalLineId[lineId];
        }

        const line = this.props.getLine(lineId);

        const creativeRequestGroup = await line.model.creativeRequestGroup;

        return creativeRequestGroup.value as TableType;
    }

    private getTableByType(tableType: TableType): TableView {
        const tableByType = {
            [TableType.Tariff]: this.tariffTable,
            [TableType.Production]: this.productionTable,
            [TableType.Ak]: this.akTable,
        };

        return tableByType[tableType];
    }

    @autobind
    private getContracts(): CreativeRequestContract[] {
        const lot = this.getCreativeRequestLot();

        const contracts = this.props.getContracts();

        return contracts[`lot${lot}`];
    }

    @autobind
    private getCreativeRequestLot(): 1 | 2 {
        const lot = this.props.getCreativeRequestLot();

        return parseInt(lodash.first(lot.match(/\d/g)), 10) as 1 | 2;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        userConfig: getCreativeUserConfig(state),
        users: getAllUsers(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            updateCreativeUserConfig,
            setWatchCommentsByItemAndColumn: setWatchCommentsByItemAndColumn,
        },
        dispatch,
    );
}
