import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { AxiosError } from 'axios';

import { updateDisableRequestErrors, setNotification, NotificationType, NotificationActionType } from '@store/common';

import { TaskApi } from '@api';

import { StoreState } from '@store';
import { asyncActions } from '@store/taskPage/actions';
import { FetchTelegramError, PlugTelegramPayload, PlugTelegramSuccess } from '@store/taskPage/types';

export const plugTelegram = bindThunkAction<StoreState, PlugTelegramPayload, PlugTelegramSuccess, FetchTelegramError>(
    asyncActions.plugTelegram,
    async ({ taskId }, dispatch) => {
        let linkToConnect: string = null;

        try {
            dispatch(updateDisableRequestErrors(true));

            const response = await TaskApi.plugTelegram(taskId);
            linkToConnect = response?.connectLink || null;

            const isUserAuthorizedInTelegram = !linkToConnect;

            if (isUserAuthorizedInTelegram) {
                dispatch(
                    setNotification({
                        type: NotificationType.NEUTRAL,
                        typeAction: NotificationActionType.TELEGRAM_PLUGGED,
                        comment: 'Чат успешно создан',
                    }),
                );
            }
        } catch (error) {
            const { message } = <AxiosError>error;
            throw new FetchTelegramError(message);
        } finally {
            dispatch(updateDisableRequestErrors(false));
        }

        return {
            linkToConnect,
        };
    },
);
