import * as React from 'react';
import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import { ComponentState, getBudgetTransferMenuState } from '@store/budgetExecution/budgetTransferMenu';

import * as styles from './Title.scss';

export function Title(): JSX.Element {
    const componentState = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.componentState,
    );

    let content: string;
    switch (componentState) {
        case ComponentState.ExternalIncomeTransfer:
            content = 'Статья-источник';
            break;
        case ComponentState.ExternalOutcomeTransfer:
            content = 'Статья-получатель';
            break;
    }

    return <div className={styles.root}>{content}</div>;
}
