import { DictionaryType } from '@mrm/dictionary';

export const fieldsToUpdate = {
    [DictionaryType.Regionality]: [DictionaryType.Regionality, DictionaryType.Division],
    [DictionaryType.ActivityType]: [DictionaryType.ActivityType],
    [DictionaryType.Direction]: [
        DictionaryType.Direction,
        DictionaryType.Tool,
        DictionaryType.Item,
        DictionaryType.Resource,
    ],
    [DictionaryType.Tool]: [DictionaryType.Tool, DictionaryType.Item, DictionaryType.Resource],
    [DictionaryType.CostCenter]: [DictionaryType.CostCenter],
    [DictionaryType.Block]: [
        DictionaryType.Block,
        DictionaryType.Division,
        DictionaryType.Segment,
        DictionaryType.Product,
    ],
    [DictionaryType.Division]: [DictionaryType.Division],
    [DictionaryType.Item]: [DictionaryType.Item],
    [DictionaryType.Resource]: [DictionaryType.Resource],
    [DictionaryType.Territory]: [DictionaryType.Territory],
    [DictionaryType.LocationDriver]: [DictionaryType.LocationDriver],
    [DictionaryType.Segment]: [DictionaryType.Segment],
    [DictionaryType.Product]: [DictionaryType.Product],
};

export type Weight = number;

export type FieldPriorityWeights = {
    [P in DictionaryType]?: DictionaryType[];
};

export const fieldPriorityWeights: FieldPriorityWeights = {
    [DictionaryType.Division]: [DictionaryType.Regionality, DictionaryType.Block],
};

export const dictionaryTypes = {
    [DictionaryType.Regionality]: DictionaryType.Regionality,
    [DictionaryType.ActivityType]: DictionaryType.ActivityType,
    [DictionaryType.Direction]: DictionaryType.Direction,
    [DictionaryType.Tool]: DictionaryType.Tool,
    [DictionaryType.CostCenter]: DictionaryType.CostCenter,
    [DictionaryType.Block]: DictionaryType.Block,
    [DictionaryType.Division]: DictionaryType.Division,
    [DictionaryType.Item]: DictionaryType.Item,
    [DictionaryType.Resource]: DictionaryType.Resource,
    [DictionaryType.Territory]: DictionaryType.Territory,
    [DictionaryType.LocationDriver]: DictionaryType.LocationDriver,
    [DictionaryType.Segment]: DictionaryType.Segment,
    [DictionaryType.Product]: DictionaryType.Product,
};
