import * as React from 'react';

import * as style from './BondActivitiesWithBudgetItemId.scss';

import { RequestStatus } from './types';

import { ApprovedIndicator, RejectedIndicator, DeletedIndicator, PendingIndicator } from './indicators';
import { ControlButtons } from './ControlButtons';

interface Props {
    requestStatus: RequestStatus;
    enableControls: boolean;
    onApproveClick: () => Promise<void>;
    onRejectClick: () => Promise<void>;
    onRevertClick: () => Promise<void>;
}

export const BondActivitiesWithBudgetItemId = ({
    requestStatus,
    enableControls,
    onApproveClick,
    onRejectClick,
    onRevertClick,
}: Props): JSX.Element => {
    const indicatorProps = { enableControls, onRevertClick };
    let content = null;
    switch (requestStatus) {
        case RequestStatus.Approved:
            content = <ApprovedIndicator {...indicatorProps} />;
            break;
        case RequestStatus.Rejected:
            content = <RejectedIndicator {...indicatorProps} />;
            break;
        case RequestStatus.Deleted:
            content = <DeletedIndicator {...indicatorProps} />;
            break;
        case RequestStatus.Pending:
            if (enableControls) {
                content = (
                    <ControlButtons
                        disableRejectButton={false}
                        onApproveClick={onApproveClick}
                        onRejectClick={onRejectClick}
                    />
                );
            } else {
                content = <PendingIndicator />;
            }
            break;
    }

    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'dashboardRightSidebarBondActivitiesWithBudgetItemId',
            }}
        >
            <div className={style.title}>Связь проекта и ID бюджета</div>

            {content}
        </div>
    );
};
