import * as React from 'react';

import * as styles from './Content.scss';

import { CustomScrollbar_new as CustomScrollbar } from 'sber-marketing-ui';
import { Item, CreativeRequestItem } from './Item';

const MAX_SCROLLBAR_HEIGHT = 327;

interface Props {
    activityId: number;
    items: CreativeRequestItem[];
    onLinkClick: () => void;
}

export const Content: React.FC<Props> = ({ activityId, items, onLinkClick }) => {
    return (
        <div className={styles.root}>
            <CustomScrollbar maxHeight={MAX_SCROLLBAR_HEIGHT}>
                {items.map((item) => (
                    <div className={styles.itemWrapper} key={item.id}>
                        <Item item={item} activityId={activityId} onLinkClick={onLinkClick} />
                    </div>
                ))}
            </CustomScrollbar>
        </div>
    );
};
