import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import { getActivity } from '@store/creative/selectors';

interface UseStore {
    (): UseStoreReturn;
}

interface UseStoreReturn {
    activityName: string;
}

export const useStore: UseStore = () => {
    const activity = useSelector((state: StoreState) => getActivity(state));

    return {
        activityName: activity?.name || 'Проект',
    };
};
