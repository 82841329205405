// import { bindActionCreators } from 'redux';
// import * as lodash from 'lodash';
// import * as moment from 'moment';
// import autobind from 'autobind-decorator';

import type { AutopilotTV, AutopilotTVBrief } from 'sber-marketing-types/backend';
import { AutopilotStatus } from 'sber-marketing-types/backend';
import type { BriefStageForm } from '@store/autopilotTv/types';

import { store } from '@store';
import { getAutopilot, getBriefStageForm, getSelectedBudgetItemIds } from '@store/autopilotTv/selectors';
import { AutopilotTvApi } from '@api';

interface StoreProps {
    autopilot: AutopilotTV;
    briefForm: BriefStageForm;
    selectedBudgetItemIds: string[];
}

export class Saver {
    private static instance: Saver;

    // private dispatch = bindActionCreators({

    // }, store.dispatch);

    public static getInstance(): Saver {
        if (!Saver.instance) {
            Saver.instance = new Saver();
        }
        return Saver.instance;
    }

    public init(): void {}

    public async setAutopilotStatus(status: boolean) {
        const { autopilot } = this.getStoreProps();

        if (autopilot) {
            await AutopilotTvApi.updateAutopilot(autopilot.id, {
                status: (status ? 'on' : 'off') as AutopilotStatus,
            });
        }
    }

    public async saveBriefForm() {
        const { autopilot } = this.getStoreProps();

        if (autopilot) {
            const updateParams = this.makeAutopilotBriefParams();

            await AutopilotTvApi.updateBrief(autopilot.id, updateParams);
        }
    }

    public async saveSelectedBudgetItems() {
        const { autopilot, selectedBudgetItemIds } = this.getStoreProps();

        if (autopilot) {
            await AutopilotTvApi.updateBudget(autopilot.id, { budgetIds: selectedBudgetItemIds });
        }
    }

    public async updateMediaplan() {
        const { autopilot } = this.getStoreProps();

        if (autopilot) {
            await AutopilotTvApi.updateMediaplan(autopilot.id);
        }
    }

    private makeAutopilotBriefParams(): AutopilotTVBrief {
        const { briefForm } = this.getStoreProps();

        return {
            block: briefForm.blockId,
            product: briefForm.productId,
            budget: parseInt(briefForm.budget, 10),
            dateStart: briefForm.dateStart,
            dateEnd: briefForm.dateEnd,
            targetGroup: briefForm.targetGroup,
            videos: briefForm.videos.filter((item) => !!item.name && !!item.duration),
            secondAdvertiserEnable: briefForm.secondAdvertiserEnable || false,
            secondAdvertiserMargin: briefForm.secondAdvertiserMargin,
            manualRatingSplitEnable: briefForm.manualRatingSplitEnable || false,
            manualRatingSplit: briefForm.manualRatingSplit,
        };
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        return {
            autopilot: getAutopilot(storeState),
            briefForm: getBriefStageForm(storeState),
            selectedBudgetItemIds: getSelectedBudgetItemIds(storeState),
        };
    }
}
