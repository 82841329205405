import * as moment from 'moment';
import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { EditChanelParams } from 'sber-marketing-types/frontend';

import { StoreState } from '../..';
import { UpdateChannelPayload, Channel, DeleteChannelPayload } from '../types';
import {
    updateChannel as updateChannelAction,
    createChannel as createChannelAction,
    deleteChannel as deleteChannelAction,
} from '../actions/asyncActions';
import { TaskApi } from '../../../api';
import { getTaskInfo, getChannelById } from '../selectors';

/** Create new channel */
export const createChannel = bindThunkAction<StoreState, EditChanelParams, Channel, void>(
    createChannelAction,
    (params, _, getState) =>
        TaskApi.createChannel(getTaskInfo(getState()).id, params).then(
            ({ updateTime, createTime, ...rest }) => ({
                ...rest,
                createTime: moment(createTime),
                updateTime: moment(updateTime),
                commentsIds: [],
            }),
            () => void 0,
        ),
);

/** Update channel */
export const updateChannel = bindThunkAction<StoreState, UpdateChannelPayload, Channel, Channel>(
    updateChannelAction,
    ({ channelId, ...params }, _, getState) => {
        const taskId = getTaskInfo(getState()).id;
        const oldChannel = getChannelById(getState(), channelId);
        return TaskApi.updateChannel(taskId, channelId, params).then(
            ({ updateTime, createTime, ...rest }) => ({
                ...rest,
                createTime: moment(createTime),
                updateTime: moment(updateTime),
                commentsIds: oldChannel.commentsIds,
            }),
            () => oldChannel,
        );
    },
);

export const deleteChannel = bindThunkAction<StoreState, DeleteChannelPayload, void, Error>(
    deleteChannelAction,
    async (payload, dispatch, getState) => {
        const { taskId, channelId } = payload;

        await TaskApi.deleteChannel(taskId, channelId);
    },
);
