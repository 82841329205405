import { bindActionCreators } from 'redux';

import { ActivityApi } from '@api';

import { store } from '@store';
import { loadUsers as loadUsersWithRoles, StoreTypes as UsersStoreType } from '@store/users';
import { loadUserOrganizations } from '@store/userOrganizations/thunks';
import { loadActivity } from '@store/creative';

interface StoreProps {}

export class Loader {
    private static instance: Loader;
    private activityId: number;

    private dispatch = bindActionCreators(
        {
            loadActivity,
            loadUsersWithRoles,
            loadUserOrganizations,
        },
        store.dispatch,
    );

    public static getInstance(): Loader {
        if (!Loader.instance) {
            Loader.instance = new Loader();
        }
        return Loader.instance;
    }

    public async init(activityId: number) {
        this.activityId = activityId;

        await this.initPageData();
    }

    public async initPageData() {
        await Promise.all([this.loadActivity(), this.loadUsersData()]);
    }

    public async loadActivity() {
        const activity = await ActivityApi.getActivity(this.activityId);

        this.dispatch.loadActivity(activity);
    }

    public async loadUsersData() {
        await this.dispatch.loadUserOrganizations(null);
        await this.dispatch.loadUsersWithRoles({ store: UsersStoreType.GENERAL });
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        return {};
    }
}
