import * as React from 'react';
import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import * as moment from 'moment';

import type { BriefField, FieldValue, SwitchValue, BriefFile } from 'sber-marketing-types/frontend';
import { FieldType, SwitchType, SwitchItem, BriefCost } from 'sber-marketing-types/frontend';
import type { FieldChangeParams, FieldInput } from '@store/brief/types';
import type { UnlockableInputValue } from 'sber-marketing-ui';

import { Text, Dropdown, Budgets, SwitchGroup, UnlockableInput, FileBlock } from './FieldTypes';

import type { StoreState } from '@store';
import { setFieldValue } from '@store/brief/actions';
import { getFieldById, checkFieldValidityById } from '@store/brief/selectors';

interface Props extends MapProps, DispatchProps {
    index: number;
    activityId: number;
    divisionId: string;
    departmentId: string;
    id: string;
    briefId: string;
    uniqId?: number;
    parentUniqId?: number;
    blockId: string;
    displayValidation?: boolean;
    briefCost?: BriefCost;
    fields: BriefField[];
}

interface MapProps {
    field?: BriefField;
    fieldValue?: FieldValue;
    isValid?: boolean;
    displayValidation?: boolean;
    editRight?: boolean;
}

interface DispatchProps {
    setFieldValue?: (params: FieldChangeParams) => void;
}

const NUMERIC_REGEXP = /\d*(\.\d{0,2})?/;

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class FieldContainer extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        const {
            activityId,
            departmentId,
            divisionId,
            fields,
            field,
            fieldValue,
            briefId,
            blockId,
            uniqId,
            parentUniqId,
            briefCost,
            isValid,
            editRight,
            index,
        } = this.props;
        const { id, type, properties } = field;

        const fieldType = {
            [FieldType.TEXT]: Text,
            [FieldType.TEXTAREA]: Text,
            [FieldType.INPUT]: Text,
            [FieldType.DROPDOWN]: Dropdown,
            [FieldType.SWITCH_GROUP]: SwitchGroup,
            [FieldType.UNLOCKABLE_INPUT]: UnlockableInput,
            [FieldType.FILE]: FileBlock,
        };

        const dictsFieldType = {
            ['id']: Budgets,
            ['budget']: Budgets,
        };

        return React.createElement(dictsFieldType[properties.dictionaryType] || fieldType[type], {
            index,
            activityId,
            departmentId,
            divisionId,
            id,
            briefId,
            blockId,
            uniqId,
            parentUniqId,
            briefCost,
            value: fieldValue,
            ...properties,
            fields,
            displayError: !isValid,
            onValueChange: this.onFieldValueChange,
            disabled: !editRight,
        });
    }

    @autobind
    protected onFieldValueChange(newValue: FieldInput) {
        const { briefId, uniqId = 0, parentUniqId = 0, field } = this.props;
        const { id, type, properties } = field;

        let fieldValue: FieldValue;

        switch (type) {
            case FieldType.TEXT:
                fieldValue = properties.isMultiple
                    ? { values: (newValue as string[]).map((value) => this.makeTextFieldValue(value)) }
                    : this.makeTextFieldValue(newValue);
                break;

            case FieldType.DROPDOWN:
                fieldValue = this.makeDropdownFieldValue(newValue);
                break;

            case FieldType.SWITCH_GROUP:
                fieldValue = this.makeSwitchGroupFieldValue(newValue);
                if (properties.isHasExtraFields) {
                    properties.switches.forEach((item) => {
                        if (
                            fieldValue.switches
                                ? !fieldValue.switches.find((option) => option.id === item.id)
                                : fieldValue.selected !== item.id
                        ) {
                            this.clearSubFieldsValue(item);
                        }
                    });
                }
                break;

            case FieldType.UNLOCKABLE_INPUT:
                fieldValue = this.makeUnlockableInputFieldValue(newValue);
                break;

            case FieldType.FILE:
                fieldValue = this.makeFileFieldValue(newValue);
                break;

            default:
                fieldValue = this.makeTextFieldValue(newValue);
                break;
        }

        const fieldChangeParams = {
            briefId,
            fieldId: id,
            uniqId: uniqId,
            parentUniqId: parentUniqId,
            value: fieldValue,
        };

        this.props.setFieldValue(fieldChangeParams);
    }

    private makeTextFieldValue(newValue: FieldInput): FieldValue {
        const { isRange, isCalendar, isNumeric, isUrl } = this.props.field.properties;

        if (isRange) {
            let [from, to] = newValue as React.ReactText[];

            if (isNumeric) {
                from = from ? (from as string).match(NUMERIC_REGEXP)[0] : '';
                to = to ? (to as string).match(NUMERIC_REGEXP)[0] : '';
            }

            return { from, to };
        }

        if (isNumeric) {
            return {
                text: (newValue as string).match(NUMERIC_REGEXP)[0],
            };
        }

        if (isCalendar) {
            return {
                date: newValue ? (newValue as moment.Moment).toISOString() : null,
            };
        }

        if (isUrl) {
            return { text: lodash.trim(newValue as string) };
        }

        return { text: newValue as string };
    }

    private makeDropdownFieldValue(newValue: FieldInput): FieldValue {
        return { selected: newValue as string };
    }

    private makeSwitchGroupFieldValue(newValue: FieldInput): FieldValue {
        const { field, fieldValue: currentValue } = this.props;
        const { switchType } = field.properties;

        let fieldValue: FieldValue;

        switch (switchType) {
            case SwitchType.Radio:
                fieldValue = { selected: newValue as string };
                break;

            case SwitchType.Checkbox:
            default:
                const { id, value } = newValue as SwitchValue;
                let switches: SwitchValue[] = lodash.get(currentValue, 'switches') || [];

                if (value) {
                    const foundSwitch = switches.find((item) => item.id == id);

                    if (foundSwitch) {
                        foundSwitch.value = true;
                    } else {
                        switches.push(newValue as SwitchValue);
                    }
                } else {
                    switches = switches.filter((item) => item.id != id);
                }

                fieldValue = { switches };
                break;
        }

        return fieldValue;
    }

    private makeUnlockableInputFieldValue(newValue: FieldInput): FieldValue {
        return newValue as UnlockableInputValue;
    }

    private makeFileFieldValue(newValue: FieldInput): FieldValue {
        return { files: newValue as BriefFile[] } as FieldValue;
    }

    private clearSubFieldsValue(item: SwitchItem): void {
        const { briefId, uniqId = 0, parentUniqId = 0, fields } = this.props;

        fields
            .filter((field) => field.properties.switchPropertyId === item.id)
            .forEach((field) => {
                this.props.setFieldValue({
                    briefId,
                    fieldId: field.id,
                    uniqId: uniqId,
                    parentUniqId: parentUniqId,
                    value: undefined,
                });
            });
    }
}

function mapStateToProps(state: StoreState, props: Props): MapProps {
    const { id, briefId, uniqId, parentUniqId } = props;
    const { briefs, displayValidation } = state.briefPage;

    const brief = briefs[briefId];
    const field = getFieldById(state, { id, uniqId: 0, parentUniqId: 0, briefId });
    const fieldValue = getFieldById(state, { id, uniqId, parentUniqId, briefId });

    return {
        field,
        fieldValue: lodash.get(fieldValue, 'value'),
        isValid: checkFieldValidityById(state, { id, uniqId, parentUniqId, briefId }),
        displayValidation,
        editRight: brief && brief.canEdit,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setFieldValue,
        },
        dispatch,
    );
}
