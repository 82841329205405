import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { AxiosError } from 'axios';

import { TaskApi } from '@api';

import { StoreState } from '@store';
import { NotificationActionType, NotificationType, setNotification, updateDisableRequestErrors } from '@store/common';
import { asyncActions } from '@store/taskPage/actions';
import { FetchTelegramError, UnplugTelegramPayload, UnplugTelegramSuccess } from '@store/taskPage/types';

export const unplugTelegram = bindThunkAction<
    StoreState,
    UnplugTelegramPayload,
    UnplugTelegramSuccess,
    FetchTelegramError
>(asyncActions.unplugTelegram, async ({ taskId }, dispatch) => {
    let status: string = null;

    try {
        dispatch(updateDisableRequestErrors(true));

        const response = await TaskApi.unplugTelegram(taskId);
        status = response?.status || null;

        dispatch(
            setNotification({
                type: NotificationType.NEUTRAL,
                typeAction: NotificationActionType.TELEGRAM_UNPLUGGED,
                comment: 'Чат удален',
            }),
        );
    } catch (error) {
        const { message } = <AxiosError>error;
        throw new FetchTelegramError(message);
    } finally {
        dispatch(updateDisableRequestErrors(false));
    }

    return {
        status,
    };
});
