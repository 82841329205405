import * as React from 'react';
import * as Dotdotdot from 'react-dotdotdot';
import classNames from 'classnames';
import { groupBy } from 'lodash';
import type { TaskCardParams } from 'sber-marketing-types/frontend';

import * as style from './Tooltip.scss';

import { Stage } from '@store/calendar/types';

import { Icon, IconType } from 'sber-marketing-ui';
import { StagesCalendar } from '@common/StagesCalendar';
import { Utils } from '@common/Utils';

const MAX_STAGES_VISIBLE = 6;

interface Props {
    activityTasks: TaskCardParams[];
    dates: string;
    name: string;
    stages: Stage[];
    responsibleName: string;
    productName: string;
    activityTypeName: string;
    activityTypeColor: string;
    projectStart: Date;
    projectEnd: Date;
}

export function Tooltip({
    activityTasks,
    dates,
    name,
    stages,
    responsibleName,
    productName,
    activityTypeName,
    activityTypeColor,
    projectStart,
    projectEnd,
}: Props): JSX.Element {
    return (
        <div className={classNames(style.root, stages && style.hasStages)}>
            <div className={style.topLine}>
                <div className={style.activityType} style={{ backgroundColor: activityTypeColor }}>
                    {activityTypeName}
                </div>

                <div className={style.dates}>{dates}</div>
            </div>

            <div className={style.name}>
                <Dotdotdot clamp={2}>{name}</Dotdotdot>
            </div>

            <div className={style.responsible}>
                <div className={style.responsibleIcon}>
                    <Icon type={IconType.USER16} svgSize={16} />
                </div>

                <div className={style.responsibleName}>Ответственный: {responsibleName}</div>
            </div>

            <div className={style.product}>
                <div className={style.productIcon}>
                    <Icon type={IconType.PRODUCT16} svgSize={16} />
                </div>

                <div className={style.productName}>Продукт: {productName}</div>
            </div>

            {stages && stages.length && (
                <div className={style.stagesCalendar}>
                    <StagesCalendar
                        items={stages.slice(0, MAX_STAGES_VISIBLE).map((stage) => ({
                            id: stage.id,
                            name: stage.name,
                            start: stage.start ? new Date(stage.start) : null,
                            end: stage.end ? new Date(stage.end) : null,
                            done: stage.done,
                        }))}
                        projectStart={projectStart}
                        projectEnd={projectEnd}
                        tasksByStageId={groupBy(activityTasks, 'stageId')}
                    />
                </div>
            )}

            {stages && stages.length > MAX_STAGES_VISIBLE && (
                <div className={style.extraStagesMessage}>
                    {Utils.getDeclensionByNumber(stages.length - MAX_STAGES_VISIBLE, [
                        `Еще ${stages.length - MAX_STAGES_VISIBLE} этап в проекте`,
                        `Еще ${stages.length - MAX_STAGES_VISIBLE} этапа в проекте`,
                        `Еще ${stages.length - MAX_STAGES_VISIBLE} этапов в проекте`,
                    ])}
                </div>
            )}

            <div className={style.activityTypeIndicator} style={{ backgroundColor: activityTypeColor }} />
        </div>
    );
}
