import { RouteComponentProps } from 'react-router-dom';
import * as queryString from 'query-string';
import * as moment from 'moment';
import * as lodash from 'lodash';
import * as switcher from 'ai-switcher-translit';
import { saveAs } from 'file-saver';
import { PlainDictionary, DictionaryStatus } from '@mrm/dictionary';
import { FileResponse } from 'sber-marketing-types/frontend';
import { ImageGalleryItem, ImageGalleryItemTypes } from 'sber-marketing-ui';

import { FileApi, CreativeRequestItemFile, CreativeRequestCommentFile, FileApiUploadParams } from '@api';

const videoExtensions: string[] = require('video-extensions');
const audioExtenstions: string[] = require('audio-extensions');
const imageExtensions: string[] = ['jpg', 'jpeg', 'png', 'gif'];

// import { ActivityStage } from 'sber-marketing-types/backend';
moment.locale('ru');

enum FileSizes {
    'байт',
    'кбайт',
    'мбайт',
    'гбайт',
}

const R7_FILE_TYPES = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf'];

type PartialDictionary = Pick<PlainDictionary, 'value' | 'status'>;
type GetFileDownloadParams = (file: FileResponse) => FileApiUploadParams;

/* tslint:disable:no-magic-numbers */
export class Utils {
    public static getItemById(itemsArray: any[], id: number | string) {
        return itemsArray.filter((item) => item.id == id)[0];
    }

    public static getItemsByIdAndFieldName(itemsArray: any[], id: number, fieldName: string) {
        return itemsArray.filter((item) => item[fieldName] == id);
    }

    public static updateItemById(itemsArray: any[], id: number, newParams: any) {
        return itemsArray.map((item) => (item.id == id ? { ...item, ...newParams } : item));
    }

    public static formatDateToM(date: string): string {
        moment.locale('ru');
        return moment(date).format('MMMM');
    }

    public static formatDateToMY(date: string): string {
        moment.locale('ru');
        return moment(date).format('MMMM YYYY');
    }

    public static formatDateToDMY(date: string): string {
        moment.locale('ru');
        const formatedDate = moment(date).format('D/MMM/YY');
        return formatedDate.replace('.', '');
    }

    public static calculateDateRange(start: moment.Moment, end: moment.Moment): string {
        const difference = moment.duration(end.diff(start)).add(1, 'day');
        const years = Math.abs(difference.years());
        const months = Math.abs(difference.months());
        const days = Math.abs(difference.days());

        if (years) {
            return `${years} ${this.getDeclensionByNumber(years, ['год', 'года', 'лет'])}`;
        }
        if (months) {
            const shouldAddHalf = days > 14;
            return shouldAddHalf
                ? `${months},5 ${months > 4 ? 'месяцев' : 'месяца'}`
                : `${months} ${this.getDeclensionByNumber(months, ['месяц', 'месяца', 'месяцев'])}`;
        }

        return `${days} ${this.getDeclensionByNumber(days, ['день', 'дня', 'дней'])}`;
    }

    public static getQuery<QueryType>(): QueryType {
        return queryString.parse(location.search) as any;
    }

    public static parseQuery(queryString: string) {
        const query = {};
        const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        for (let i = 0; i < pairs.length; i++) {
            const pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    }

    public static addKeyToQuery(routeProps: RouteComponentProps<any>, key: string, value: string | number): void {
        const newQuery = Object.assign({}, Utils.getQuery());
        newQuery[key] = String(value);

        routeProps.history.replace(`${routeProps.match.url}?${queryString.stringify(newQuery)}`);
    }

    public static roundDateToDay(date: moment.Moment): moment.Moment {
        const halfOfDay = 12;

        return date.add(halfOfDay, 'hours').startOf('day');
    }

    /**
     * Titles should be in subjective, genitive and accusative case,
     * example: ['арбуз', 'арбуза', 'арбузов']
     */
    public static getDeclensionByNumber(value: number, titles: string[]) {
        const cases = [2, 0, 1, 1, 1, 2];

        const titleIndex = value % 100 > 4 && value % 100 < 20 ? 2 : cases[value % 10 < 5 ? value % 10 : 5];

        return titles[titleIndex];
    }

    public static getFilesCountDeclension(value: number) {
        return this.getDeclensionByNumber(value, ['файл', 'файла', 'файлов']);
    }

    /**
     * Format 12345678 number to '12.345.678' string
     */
    public static formatCurrencyNumber(value: number): string {
        return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }

    public static shortenString(text: string, symbolsCount: number): string {
        return text.length > symbolsCount ? `${text.substring(0, symbolsCount - 3)}...` : text;
    }

    public static formatDates(startDate: moment.Moment, endDate: moment.Moment): string {
        const datesAreSame = startDate.isSame(endDate);
        const someDatesAreInvalid = lodash.some([startDate, endDate], (date) => !date.isValid());

        const startYear = startDate.format('YY');
        const endYear = endDate.format('YY');
        const yearsAreSame = startYear == endYear;

        let result: string;

        if (datesAreSame) {
            result = `${this.formatDate(endDate)} ${endYear}`;
        } else if (yearsAreSame) {
            result = `${this.formatDate(startDate)} — ${this.formatDate(endDate)} ${endYear}`;
        } else {
            result = `${this.formatDate(startDate)} ${startYear} — ${this.formatDate(endDate)} ${endYear}`;
        }

        if (someDatesAreInvalid) {
            result = this.formatInvalidDates(startDate, endDate);
        }

        return result;
    }

    public static formatInvalidDates(startDate: moment.Moment, endDate: moment.Moment): string {
        const justOneOfDatesIsValid = lodash.compact([startDate.isValid(), endDate.isValid()]).length == 1;
        let result: string;

        if (justOneOfDatesIsValid) {
            let validDate: moment.Moment;
            startDate.isValid() ? (validDate = startDate) : (validDate = endDate);

            const yearOfValidDate = validDate.format('YY');

            result = `${this.formatDate(validDate)} ${yearOfValidDate}`;
        } else {
            result = '';
        }

        return result;
    }

    public static formatDate(date: moment.Moment, formatParams?: { day?: string; month?: string }): string {
        const dayFormat = formatParams && formatParams.day ? formatParams.day : 'D';
        const monthFormat = formatParams && formatParams.month ? formatParams.month : 'MMM';

        return formatParams
            ? date.format(`${dayFormat} ${monthFormat}`).replace('.', '')
            : `${date.format(dayFormat)} ${date.format(monthFormat).slice(0, 3)}`;
    }

    public static formatDateWithYear(date: moment.Moment): string {
        return `${Utils.formatDay(date)} ${Utils.formatMonth(date)} ${Utils.formatYear(date)}`;
    }

    public static formatDay(date: moment.Moment): string {
        const dayFormat = 'D';
        return `${date.format(dayFormat)}`;
    }

    public static formatMonth(date: moment.Moment): string {
        const monthFormat = 'MMM';
        const month = date.format(monthFormat);

        return month[0].toUpperCase() + month.slice(1, 3);
    }

    public static formatYear(date: moment.Moment): string {
        const format = 'YY';
        return `${date.format(format)}`;
    }

    public static getActivityStageName(beginDate: string, endDate: string): string {
        let result = 'проведение';

        if (moment(endDate).add(1, 'day').isBefore(moment())) {
            result = 'итоги';
        }
        if (moment(beginDate).isAfter(moment())) {
            result = 'подготовка';
        }

        return result;
    }

    public static isTouchScreen(): boolean {
        return !!('ontouchstart' in document.documentElement || 'ontouchstart' in window);
    }

    public static addClass(element: HTMLElement, className: string) {
        if (!this.hasClass(element, className)) {
            element.setAttribute('class', `${element.getAttribute('class')} ${className}`);
        }
    }

    public static removeClass(element: HTMLElement, className: string) {
        const removedClass = element.getAttribute('class').replace(new RegExp(`(\\s|^)${className}(\\s|$)`, 'g'), '$2');

        if (this.hasClass(element, className)) {
            element.setAttribute('class', removedClass);
        }
    }

    public static hasClass(element: HTMLElement, className: string) {
        return new RegExp(`(\\s|^)${className}(\\s|$)`).test(element.getAttribute('class'));
    }

    public static getSegmentsIntersection(
        segment1: { start: number; end: number },
        segment2: { start: number; end: number },
    ): { start: number; end: number } {
        const intersectionStart = segment1.start > segment2.start ? segment1.start : segment2.start;
        const intersectionEnd = segment1.end < segment2.end ? segment1.end : segment2.end;

        return intersectionEnd > intersectionStart ? { start: intersectionStart, end: intersectionEnd } : null;
    }

    public static async getBase64(file: File) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    public static getBase64Size(size: number) {
        return Math.floor(size / 3) * 4;
    }

    public static chunkBase64File(fileString: string, size: number) {
        const base64Size = this.getBase64Size(size);
        const regex = `.{1,${base64Size}}`;
        const fileData = fileString.split(',')[1];

        return fileData ? fileData.match(new RegExp(regex, 'g')) : '';
    }

    public static async createFileFromBase64(filename: string, data: string[], mime: string) {
        const file = await data.join('');
        const blob = await this.dataURIToBlob(mime, file);

        saveAs(blob, filename);
    }

    public static dataURIToBlob(mime: string, dataURI: string): Blob {
        const binaryString = atob(dataURI);
        const dataArray = new Uint8Array(binaryString.length);

        for (let i = 0; i < binaryString.length; i++) {
            dataArray[i] = binaryString.charCodeAt(i);
        }

        return new Blob([dataArray], { type: mime });
    }

    public static isVideo(extension: string): boolean {
        return this.fileBelongsToGroup(extension, videoExtensions);
    }

    public static isAudio(extenstion: string): boolean {
        return this.fileBelongsToGroup(extenstion, audioExtenstions);
    }

    public static isImage(extension: string): boolean {
        return this.fileBelongsToGroup(extension, imageExtensions);
    }

    public static isSubstring(fullString: string, substring: string) {
        const lowerFullString = lodash.toLower(fullString);
        const lowerSubstring = lodash.toLower(substring);

        return (
            lowerFullString.indexOf(lowerSubstring) !== -1 ||
            lowerFullString.indexOf(switcher.getSwitch(lowerSubstring)) !== -1 ||
            lowerFullString.indexOf(switcher.getSwitch(lowerSubstring, { type: 'rueng' })) !== -1
        );
    }

    public static downloadAsXLSX(data: Buffer, fileName: string): void {
        return Utils.downloadFile(
            data,
            `${fileName}.xlsx`,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        );
    }

    public static downloadAsZip(data: Buffer, fileName: string): void {
        return Utils.downloadFile(data, `${fileName}.zip`, 'application/zip');
    }

    // Realization from: https://gist.github.com/realmyst/1262561
    public static declOfNum(num: number, titles: string[]): string {
        const cases = [2, 0, 1, 1, 1, 2];
        return titles[num % 100 > 4 && num % 100 < 20 ? 2 : cases[num % 10 < 5 ? num % 10 : 5]];
    }

    public static formatFileSize(size: number): string {
        const powerOf1024 = Math.log(size) / Math.log(1024);
        const fileSizeIndex = Math.floor(powerOf1024);
        const fileSize = fileSizeIndex ? (size / Math.pow(1024, fileSizeIndex)).toFixed(powerOf1024 % 1 ? 1 : 0) : size;

        return `${fileSize} ${FileSizes[fileSizeIndex]}`;
    }

    public static withErrorHandler<R>(callback: () => R): R {
        let result: R;

        try {
            result = callback();
        } catch (e) {
            result = null;
        }

        return result;
    }

    public static getDictionaryValue(dictionary: PartialDictionary, defaultValue?: string): string {
        if (!dictionary) {
            return defaultValue || null;
        }

        const statusPart = dictionary.status === DictionaryStatus.DELETED ? ' (удален)' : '';

        return `${dictionary.value}${statusPart}`;
    }

    public static spacebarInsensitiveFilter<T extends {}>(
        filterBy: RegExp,
        items: T[],
        selector: (item: T) => string,
    ): T[] {
        return items.filter((item) => this.spacebarInsensitiveMatch(filterBy, item, selector));
    }

    public static spacebarInsensitiveMatch<T extends {}>(
        filterBy: RegExp,
        item: T,
        selector: (item: T) => string,
    ): boolean {
        const key = selector(item);
        const keyParts = key.split(' ');
        const partsPermutations = Utils.makePermutations(keyParts);

        return partsPermutations.some((permutation) => permutation.match(filterBy));
    }

    public static escapeRegExp(string: string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    public static fileValidForR7(fileType: string) {
        return R7_FILE_TYPES.includes(fileType);
    }

    public static mapCreativeRequestItemFilesToMediaGalleryItems(
        files: CreativeRequestItemFile[],
        getFileDownloadParams: GetFileDownloadParams,
    ): ImageGalleryItem[] {
        return Utils.mapFilesToMediaGalleryItems(
            files.map((file) => {
                const { id, name, originName, type, size, storage } = file.model;

                return {
                    id,
                    name,
                    originName,
                    type,
                    size,
                    storage,
                };
            }),
            getFileDownloadParams,
        );
    }

    public static mapCreativeRequestCommentFilesToMediaGalleryItems(
        files: CreativeRequestCommentFile[],
        getFileDownloadParams: GetFileDownloadParams,
    ): ImageGalleryItem[] {
        return Utils.mapFilesToMediaGalleryItems(
            files.map((file) => {
                const { id, name, originName, type, size, storage } = file;

                return {
                    id,
                    name,
                    originName,
                    type,
                    size: +size,
                    storage,
                };
            }),
            getFileDownloadParams,
        );
    }

    public static mapFilesToMediaGalleryItems(
        files: FileResponse[],
        getFileDownloadParams: GetFileDownloadParams,
    ): ImageGalleryItem[] {
        return files
            .filter((file) => {
                const type = file.type;

                return Utils.isImage(type) || Utils.isVideo(type) || Utils.isAudio(type);
            })
            .map((file) => {
                const { id, name, originName, type, size, storage } = file;

                const fileWithParams = FileApi.mapFile(getFileDownloadParams(file), {
                    id: id,
                    name: name,
                    originName: originName,
                    type: type,
                    size: size,
                    storage: storage,
                });

                let mediaType: ImageGalleryItemTypes;
                if (Utils.isImage(type)) {
                    mediaType = 'image';
                } else if (Utils.isVideo(type)) {
                    mediaType = 'video';
                } else if (Utils.isAudio(type)) {
                    mediaType = 'audio';
                } else {
                    console.warn(`Unknowm media type ${type}`);
                    mediaType = null;
                }

                return {
                    id,
                    originalUrl: fileWithParams.fullSizeUrl,
                    previewUrl: fileWithParams.previewUrl,
                    name,
                    originName,
                    extension: type,
                    type: mediaType,
                };
            });
    }

    private static makePermutations(items: string[]): string[] {
        if (!items.length) {
            return [''];
        }

        return items.reduce((acc: string[], item, i) => {
            const restItems = [...items.slice(0, i), ...items.slice(i + 1)];
            const restPermutations = Utils.makePermutations(restItems);

            const currPermutations = restPermutations.map(
                (permutation) => `${item}${items.length !== 1 ? ' ' : ''}${permutation}`,
            );

            return [...acc, ...currPermutations];
        }, []);
    }

    private static downloadFile(data: Buffer, fileName: string, mimeType: string): void {
        const blob = new Blob([data], { type: mimeType });

        const downloadLink = document.createElement('a');
        const link = URL.createObjectURL(blob);
        downloadLink.href = link;
        downloadLink.download = fileName;
        downloadLink.click();
        URL.revokeObjectURL(link);
    }

    private static fileBelongsToGroup(extension: string, extensions: string[]): boolean {
        const ext = (extension.startsWith('.') ? extension.substr(1) : extension).toLowerCase();
        return lodash.includes(extensions, ext);
    }
}
