// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-pivotTable-_PivotTable__root{display:flex;height:calc(100vh - 98px)}.src-client-modules-pivotTable-_PivotTable__content{display:flex;flex-direction:column;flex-grow:1;padding:16px 32px 16px calc(48px + 32px)}.src-client-modules-pivotTable-_PivotTable__table{margin-bottom:40px}.src-client-modules-pivotTable-_PivotTable__table:last-child{margin-bottom:0}.src-client-modules-pivotTable-_PivotTable__totalTable{margin-top:auto}.src-client-modules-pivotTable-_PivotTable__sidebar{width:410px;background:#ffffff;box-shadow:0 2px 10px 0 rgba(0,0,0,0.1)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/pivotTable/PivotTable.scss"],"names":[],"mappings":"AAAA,iDACI,YAAa,CACb,yBAA0B,CAC7B,oDAGG,YAAa,CACb,qBAAsB,CACtB,WAAY,CACZ,wCAAyC,CAC5C,kDAGG,kBAAmB,CADvB,6DAIQ,eAAgB,CACnB,uDAID,eAAgB,CACnB,oDAGG,WAAY,CACZ,kBAAmB,CACnB,uCAA2C","sourcesContent":[".root {\n    display: flex;\n    height: calc(100vh - 98px);\n}\n\n.content {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    padding: 16px 32px 16px calc(48px + 32px);\n}\n\n.table {\n    margin-bottom: 40px;\n\n    &:last-child {\n        margin-bottom: 0;\n    }\n}\n\n.totalTable {\n    margin-top: auto;\n}\n\n.sidebar {\n    width: 410px;\n    background: #ffffff;\n    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-pivotTable-_PivotTable__root",
	"content": "src-client-modules-pivotTable-_PivotTable__content",
	"table": "src-client-modules-pivotTable-_PivotTable__table",
	"totalTable": "src-client-modules-pivotTable-_PivotTable__totalTable",
	"sidebar": "src-client-modules-pivotTable-_PivotTable__sidebar"
};
export default ___CSS_LOADER_EXPORT___;
