import * as PropTypes from 'prop-types';
import {
    SuggestHOCOuterProps,
    SuggestHOCProps,
    getSuggestHOCOuterPropsTypes,
    getSuggestHOCPropsTypes,
} from 'sber-marketing-ui';

/** "UsersSelectSuggest" item */
export interface UsersSelectSuggestItem {
    /** User identifier */
    id: string | number;
    /** User name */
    name: string;
    /** User department */
    department: string;
    /** If "true" item will be marked as selected */
    isSelected: boolean;
    isActive?: boolean;
    canRemove?: boolean;
}

/** "UsersSelectSuggest" item types */
export const UsersSelectSuggestItemTypes: PropTypes.ValidationMap<UsersSelectSuggestItem> = {
    id: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
    name: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    isActive: PropTypes.bool,
};

/** "UsersSelectSuggest" item properties */
export interface UsersSelectSuggestItemProps extends UsersSelectSuggestItem {
    /** Suggest selection handler */
    onSuggestItemSelect(item: UsersSelectSuggestItem): Promise<void> | void;
}

/** "UsersSelectSuggest" item properties types */
export const UsersSelectSuggestItemPropsTypes: PropTypes.ValidationMap<UsersSelectSuggestItemProps> = {
    ...UsersSelectSuggestItemTypes,
    onSuggestItemSelect: PropTypes.func,
};

/** "UsersSelectSuggest" outside properties */
export interface UsersSelectSuggestProps extends SuggestHOCOuterProps<UsersSelectSuggestItem> {
    qaId?: string;
    /** Additional CSS class */
    className?: string;
    disabled?: boolean;
    /** Suggest input title */
    title: string;
    /** User's list to filter */
    users: UsersSelectSuggestItem[];
}

/** "UsersSelectSuggest" base properties types */
export const UsersSelectSuggestPropsTypes: PropTypes.ValidationMap<UsersSelectSuggestProps> = {
    ...getSuggestHOCOuterPropsTypes(),
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(PropTypes.exact(UsersSelectSuggestItemTypes)).isRequired,
};

/** "UsersSelectSuggest" template (inside) properties */
export interface UsersSelectSuggestTemplateProps extends SuggestHOCProps<UsersSelectSuggestItem> {
    qaId?: string;
    /** Additional CSS class */
    className?: string;
    disabled?: boolean;
    /** Suggest input title */
    title: string;
    /** User's list to filter */
    users: UsersSelectSuggestItem[];
    canRemove?: boolean;
}

/** "UsersSelectSuggest" template (inside) properties types */
export const UsersSelectSuggestTemplatePropsTypes: PropTypes.ValidationMap<UsersSelectSuggestTemplateProps> = {
    ...getSuggestHOCPropsTypes(PropTypes.exact(UsersSelectSuggestItemTypes)),
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(PropTypes.exact(UsersSelectSuggestItemTypes)).isRequired,
};
