// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-user-components-WorkTypeUserFormSelector-_WorkTypeUserFormSelector__root{display:flex;flex-direction:column;align-items:stretch}.src-client-modules-user-components-WorkTypeUserFormSelector-_WorkTypeUserFormSelector__workTypesPopup{width:calc(50% - 2px)}.src-client-modules-user-components-WorkTypeUserFormSelector-_WorkTypeUserFormSelector__workTypesChip{max-width:50%}.src-client-modules-user-components-WorkTypeUserFormSelector-_WorkTypeUserFormSelector__usersPopup{width:calc(50% - 2px)}.src-client-modules-user-components-WorkTypeUserFormSelector-_WorkTypeUserFormSelector__portal{margin-bottom:-8px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/user/components/WorkTypeUserFormSelector/WorkTypeUserFormSelector.scss"],"names":[],"mappings":"AAAA,6FACE,YAAa,CACb,qBAAsB,CACtB,mBAAoB,CACrB,uGAGC,qBAAsB,CACvB,sGAGC,aAAc,CACf,mGAGC,qBAAsB,CACvB,+FAGC,kBAAmB","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n}\n\n.workTypesPopup {\n  width: calc(50% - 2px);\n}\n\n.workTypesChip {\n  max-width: 50%;\n}\n\n.usersPopup {\n  width: calc(50% - 2px);\n}\n\n.portal {\n  margin-bottom: -8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-user-components-WorkTypeUserFormSelector-_WorkTypeUserFormSelector__root",
	"workTypesPopup": "src-client-modules-user-components-WorkTypeUserFormSelector-_WorkTypeUserFormSelector__workTypesPopup",
	"workTypesChip": "src-client-modules-user-components-WorkTypeUserFormSelector-_WorkTypeUserFormSelector__workTypesChip",
	"usersPopup": "src-client-modules-user-components-WorkTypeUserFormSelector-_WorkTypeUserFormSelector__usersPopup",
	"portal": "src-client-modules-user-components-WorkTypeUserFormSelector-_WorkTypeUserFormSelector__portal"
};
export default ___CSS_LOADER_EXPORT___;
