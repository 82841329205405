import { TaskPageState, TaskLoadingStatus } from '../types';

export const updateTaskStartReducer = ({ loadingStatus, ...restState }: TaskPageState): TaskPageState => ({
    ...restState,
    loadingStatus: TaskLoadingStatus.UPDATING,
});

export const updateTaskFailReducer = (state: TaskPageState): TaskPageState => state;

export const updateTaskDoneReducer = ({ loadingStatus, ...restState }: TaskPageState): TaskPageState => ({
    ...restState,
    loadingStatus: TaskLoadingStatus.LOADED,
});
