// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-Tips-_styles__root{font-family:'Open Sans';font-weight:400;font-size:14px;line-height:19px}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-Tips-_styles__label{margin-bottom:5px;color:#000000}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-Tips-_styles__text{color:#7E8681}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/CreationForm/GeneratedTextEditor/Editor/Footer/Tips/styles.scss"],"names":[],"mappings":"AAAA,+IACI,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACpB,gJAGG,iBAAkB,CAClB,aAAc,CACjB,+IAGG,aAAc","sourcesContent":[".root {\n    font-family: 'Open Sans';\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 19px;\n}\n\n.label {\n    margin-bottom: 5px;\n    color: #000000;\n}\n\n.text {\n    color: #7E8681;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-Tips-_styles__root",
	"label": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-Tips-_styles__label",
	"text": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-Tips-_styles__text"
};
export default ___CSS_LOADER_EXPORT___;
