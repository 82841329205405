import { MAX_HEIGHT } from '../consts';

interface Props {
    items: Item[];
}

interface Item {
    id: string | number;
    type: ItemType;
    height: number;
}

export const enum ItemType {
    ORGANIZATION = 'Organization',
    GROUP = 'Group',
    LINE = 'Line',
}

export class ItemsSplitter {
    private currentPageIndex: number;
    private currentPageHeight: number;
    private currentItem: Item;

    private items: Item[];
    private pages: Item[][];

    constructor(props: Props) {
        this.items = props.items;
        this.pages = [[]];

        this.currentPageIndex = 0;
        this.currentPageHeight = 0;
    }

    public split(): Item[][] {
        this.items.forEach((item) => {
            this.setCurrentItem(item);

            if (this.isAllowedHeightOfPage() || this.isAllowedAddCurrentItemType()) {
                this.addItemInCurrentPage();
                this.addHeightInCurrentPageHeight();
            } else {
                this.addItemInCurrentPage();
                this.addHeightInCurrentPageHeight();
                this.shiftNextPage();
            }
        });

        return this.pages;
    }

    private setCurrentItem(item: Item): void {
        this.currentItem = item;
    }

    private shiftNextPage(): void {
        this.shiftCurrentPage();
        this.resetCurrentPageHeight();
    }

    private shiftCurrentPage(): void {
        this.currentPageIndex = this.currentPageIndex + 1;
        this.pages[this.currentPageIndex] = [];
    }

    private addItemInCurrentPage(): void {
        this.pages[this.currentPageIndex].push(this.currentItem);
    }

    private addHeightInCurrentPageHeight(): void {
        this.currentPageHeight = this.currentPageHeight + this.currentItem.height;
    }

    private resetCurrentPageHeight(): void {
        this.currentPageHeight = 0;
    }

    private isAllowedHeightOfPage(): boolean {
        return this.currentPageHeight + this.currentItem.height < MAX_HEIGHT;
    }

    private isAllowedAddCurrentItemType(): boolean {
        return this.currentItem.type !== ItemType.LINE;
    }
}
