// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-Header-AutopilotLink-MenuItemDropdown-_style__root{position:relative}.src-client-modules-activity-Header-AutopilotLink-MenuItemDropdown-_style__opener{display:flex;height:48px;padding:0 20px;align-items:center;text-decoration:none;letter-spacing:0.6px;text-transform:uppercase;color:#262626;font-weight:600;font-size:12px;cursor:pointer}.src-client-modules-activity-Header-AutopilotLink-MenuItemDropdown-_style__menuItem{display:flex;align-items:center}.src-client-modules-activity-Header-AutopilotLink-MenuItemDropdown-_style__listWrapper{position:absolute;top:calc(100% + 4px);left:-10px}.src-client-modules-activity-Header-AutopilotLink-MenuItemDropdown-_style__arrowUp{transform:rotate(-90deg)}.src-client-modules-activity-Header-AutopilotLink-MenuItemDropdown-_style__arrowDown{transform:rotate(90deg) translateY(-2px)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/Header/AutopilotLink/MenuItemDropdown/style.scss"],"names":[],"mappings":"AAAA,gFACI,iBAAkB,CACrB,kFAGG,YAAa,CACb,WAAY,CACZ,cAAe,CACf,kBAAmB,CACnB,oBAAqB,CACrB,oBAAqB,CACrB,wBAAyB,CACzB,aAAc,CACd,eAAgB,CAChB,cAAe,CACf,cAAe,CAClB,oFAGG,YAAa,CACb,kBAAmB,CACtB,uFAGG,iBAAkB,CAClB,oBAAqB,CACrB,UAAW,CACd,mFAGG,wBAAyB,CAC5B,qFAGG,wCAAyC","sourcesContent":[".root {\n    position: relative;\n}\n\n.opener {\n    display: flex;\n    height: 48px;\n    padding: 0 20px;\n    align-items: center;\n    text-decoration: none;\n    letter-spacing: 0.6px;\n    text-transform: uppercase;\n    color: #262626;\n    font-weight: 600;\n    font-size: 12px;\n    cursor: pointer;\n}\n\n.menuItem {\n    display: flex;\n    align-items: center;\n}\n\n.listWrapper {\n    position: absolute;\n    top: calc(100% + 4px);\n    left: -10px;\n}\n\n.arrowUp {\n    transform: rotate(-90deg);\n}\n\n.arrowDown {\n    transform: rotate(90deg) translateY(-2px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-Header-AutopilotLink-MenuItemDropdown-_style__root",
	"opener": "src-client-modules-activity-Header-AutopilotLink-MenuItemDropdown-_style__opener",
	"menuItem": "src-client-modules-activity-Header-AutopilotLink-MenuItemDropdown-_style__menuItem",
	"listWrapper": "src-client-modules-activity-Header-AutopilotLink-MenuItemDropdown-_style__listWrapper",
	"arrowUp": "src-client-modules-activity-Header-AutopilotLink-MenuItemDropdown-_style__arrowUp",
	"arrowDown": "src-client-modules-activity-Header-AutopilotLink-MenuItemDropdown-_style__arrowDown"
};
export default ___CSS_LOADER_EXPORT___;
