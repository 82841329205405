import * as React from 'react';
import * as lodash from 'lodash';
import classNames from 'classnames';

import * as style from './BudgetAllocation.scss';

import type { Autopilot2MediaplanChannels } from 'sber-marketing-types/backend';

import { Icon, IconType } from 'sber-marketing-ui';
import { Slider } from '../Slider';
import { useStore } from './hooks';

interface Props {
    readOnly?: boolean;
}

export const BudgetAllocation = ({ readOnly }: Props): JSX.Element => {
    const { disabled, budget, sliderValue, channelsGroups, onAllocationChange, onGroupItemClick } = useStore(readOnly);

    if (!channelsGroups) {
        return null;
    }

    return (
        <div className={style.root}>
            <div className={style.title}>Распределение бюджета</div>

            <div className={style.selection}>
                <div className={style.scale}>
                    {lodash.times(5, (index) => (
                        <div
                            key={index}
                            className={classNames(style.scaleValue, style.disabled)}
                            onClick={!disabled ? () => onAllocationChange(index * 25) : null}
                        >
                            {index * 25} %
                        </div>
                    ))}
                </div>

                <div className={style.slider}>
                    <Slider
                        value={sliderValue}
                        minValue={0}
                        maxValue={100}
                        dotsCount={5}
                        leftColor={'#5abefe'}
                        rightColor={'#6fcf97'}
                        disabled={disabled}
                        onValueChange={onAllocationChange}
                    />
                </div>

                <div className={style.sliderDescriptions}>
                    <div className={style.sliderDescription}>Performance</div>

                    <div className={style.sliderDescription}>Media</div>
                </div>
            </div>

            <div className={style.groups}>
                <Group
                    group={channelsGroups.Performance}
                    budget={budget}
                    color={'#5abefe'}
                    disabled={disabled}
                    onGroupItemClick={onGroupItemClick}
                />

                <Group
                    group={channelsGroups.Media}
                    budget={budget}
                    color={'#6fcf97'}
                    disabled={disabled}
                    onGroupItemClick={onGroupItemClick}
                />
            </div>
        </div>
    );
};

interface GroupProps {
    group: Autopilot2MediaplanChannels;
    budget: number;
    color: string;
    disabled: boolean;
    onGroupItemClick: (params: { id: number; name: string }) => void;
}

function Group({ group, budget, color, disabled, onGroupItemClick }: GroupProps): JSX.Element {
    return (
        <div className={classNames(style.group, disabled && style.disabled)}>
            <div className={style.groupTitle} style={{ color }}>
                {group.name}
            </div>

            <div className={style.groupValue}>
                {Math.round(group.share * 100)} % — {Math.round(budget * group.share)} ₽
            </div>

            <div className={style.groupItems}>
                {group.digital_channels.map((item) => (
                    <GroupItem
                        key={`${item.digital_channel.name} ${item.digital_channel.id}`}
                        title={item.digital_channel.name}
                        checked={item.is_use}
                        disabled={disabled}
                        onClick={() =>
                            onGroupItemClick({
                                id: item.digital_channel.id,
                                name: item.digital_channel.name,
                            })
                        }
                    />
                ))}
            </div>
        </div>
    );
}

interface GroupItemProps {
    title: string;
    checked: boolean;
    disabled: boolean;
    onClick: () => void;
}

function GroupItem({ title, checked, disabled, onClick }: GroupItemProps): JSX.Element {
    return (
        <div className={style.groupItem} onClick={!disabled ? onClick : null}>
            <div className={style.groupItemCheckbox}>
                <Icon type={checked ? IconType.CHECKBOX24_CHECKED : IconType.CHECKBOX24_UNCHECKED} svgSize={24} />
            </div>

            {title}
        </div>
    );
}
