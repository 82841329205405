import * as React from 'react';

import { useStore } from './useStore';
import type { CreativeRequestTenderDocument, CreativeRequestTenderDocumentProtocolFile } from './useStore';
import { CreativeRequestTenderDocumentStatus } from './useStore';

import { FileApi } from '@api';

interface UseBehavior {
    (): UseStoreReturn;
}

interface UseStoreReturn {
    status: CreativeRequestTenderDocumentStatus;
    protocolFile: CreativeRequestTenderDocumentProtocolFile;
    protocolLimit: number;
    disabledStatus: boolean;
    disabledProtocolLimit: boolean;
    disabledUploadFile: boolean;
    disabledDownloadFile: boolean;
    disabledRemoveFile: boolean;
    loading: boolean;
    onApprove: OnApprove;
    onDisapprove: OnDisapprove;
    onAddProtocolFile: OnAddProtocolFile;
    onRemoveProtocolFile: OnRemoveProtocolFile;
    onProtocolLimitChange: OnProtocolLimitChange;
}

interface OnAddProtocolFile {
    (file: File): void;
}

interface OnRemoveProtocolFile {
    (): void;
}

interface OnProtocolLimitChange {
    (value: number): void;
}

interface OnApprove {
    (): void;
}

interface OnDisapprove {
    (): void;
}

interface OnReloaded {
    (params: CreativeRequestTenderDocument): void;
}

interface OnProtocolFileUpdated {
    (params: CreativeRequestTenderDocumentProtocolFile): void;
}

export const useBehavior: UseBehavior = () => {
    const [tenderDocumentDomain, setTenderDocumentDomain] = React.useState(null);
    const [protocolFile, setProtocolFile] = React.useState(null);

    const { tenderDocumentDomain: tenderDocumentDomainFromStore, loading } = useStore();

    const onReloaded: OnReloaded = React.useCallback((updatedTenderDocumentDomain) => {
        setTenderDocumentDomain(updatedTenderDocumentDomain);
        setProtocolFile(updatedTenderDocumentDomain?.model?.protocolFile);
    }, []);

    const onProtocolFileUpdated: OnProtocolFileUpdated = React.useCallback((protocolFile) => {
        setProtocolFile(protocolFile);
    }, []);

    React.useEffect(() => {
        setTenderDocumentDomain(tenderDocumentDomainFromStore);
        setProtocolFile(tenderDocumentDomainFromStore?.model?.protocolFile);
    }, [tenderDocumentDomainFromStore]);

    React.useEffect(() => {
        tenderDocumentDomain?.events?.onReloaded(onReloaded);
        tenderDocumentDomain?.events?.onProtocolFileUpdated(onProtocolFileUpdated);

        return () => {
            tenderDocumentDomain?.events?.offReloaded(onReloaded);
            tenderDocumentDomain?.events?.offProtocolFileUpdated(onProtocolFileUpdated);
        };
    }, [tenderDocumentDomain]);

    const onAddProtocolFile: OnAddProtocolFile = React.useCallback(
        (file) => {
            FileApi.uploadFile({}, file).then((responseFile) => {
                tenderDocumentDomain.model.setProtocolFile({ protocolFile: responseFile.id });
            });
        },
        [tenderDocumentDomain],
    );

    const onRemoveProtocolFile: OnRemoveProtocolFile = React.useCallback(() => {
        tenderDocumentDomain?.model?.removeProtocolFile();
    }, [tenderDocumentDomain]);

    const onProtocolLimitChange: OnProtocolLimitChange = React.useCallback(
        (value) => {
            tenderDocumentDomain?.model?.setProtocolLimit({ protocolLimit: value });
        },
        [tenderDocumentDomain?.model?.setProtocolLimit],
    );

    const onApprove: OnApprove = React.useCallback(() => {
        tenderDocumentDomain.model.approve();
    }, [tenderDocumentDomain]);

    const onDisapprove: OnDisapprove = React.useCallback(() => {
        tenderDocumentDomain.model.disapprove();
    }, [tenderDocumentDomain]);

    const protocolLimit: number = tenderDocumentDomain?.model?.protocolLimit;

    const canChangeStatus: boolean = React.useMemo(() => {
        return !!tenderDocumentDomain?.model?.disapprove && !!tenderDocumentDomain?.model?.approve;
    }, [tenderDocumentDomain?.model?.disapprove, tenderDocumentDomain?.mode?.approve]);

    const canChangeProtocolLimit: boolean = React.useMemo(() => {
        return !!tenderDocumentDomain?.model?.setProtocolLimit;
    }, [tenderDocumentDomain?.mode?.setProtocolLimit]);

    const canUploadFile: boolean = React.useMemo(() => {
        return !!tenderDocumentDomain?.model?.setProtocolFile;
    }, [tenderDocumentDomain?.model?.setProtocolFile]);

    const canDownloadFile: boolean = React.useMemo(() => {
        return !!tenderDocumentDomain?.model?.removeProtocolFile;
    }, [tenderDocumentDomain?.model?.removeProtocolFile]);

    const canRemoveFile: boolean = React.useMemo(() => {
        return !!tenderDocumentDomain?.model?.removeProtocolFile;
    }, [tenderDocumentDomain?.model?.removeProtocolFile]);

    return {
        status: tenderDocumentDomain?.model?.status || CreativeRequestTenderDocumentStatus.approved,
        protocolFile,
        protocolLimit,
        disabledStatus: !canChangeStatus,
        disabledProtocolLimit: !canChangeProtocolLimit,
        disabledUploadFile: !canUploadFile,
        disabledDownloadFile: !canDownloadFile,
        disabledRemoveFile: !canRemoveFile,
        loading,
        onApprove,
        onDisapprove,
        onAddProtocolFile,
        onRemoveProtocolFile,
        onProtocolLimitChange,
    };
};

export type { CreativeRequestTenderDocument, CreativeRequestTenderDocumentProtocolFile };
export { CreativeRequestTenderDocumentStatus };
