import type { WorkType } from 'sber-marketing-types/frontend';
import * as qs from 'qs';

import { rtkApi } from './rtkApi';

export const workTypeApi = rtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getWorkTypes: builder.query<WorkType.ListResponse, WorkType.ListQueryParams>({
            query: (params) => ({
                url: `/work-type/list${qs.stringify(params, { addQueryPrefix: true, arrayFormat: 'brackets' })}`,
            }),
            providesTags: (types) => types.map(({ id }) => ({ type: 'work-type', id })),
        }),
    }),
});

export const { useGetWorkTypesQuery } = workTypeApi;
