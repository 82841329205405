import type { PlainDictionary } from '@mrm/dictionary';
import { BudgetStatus } from '@mrm/budget';
import type { Budget } from '@mrm/budget';

interface WithDictionaryProps {
    dictionaries: PlainDictionary[];
    dictionariesLoading: boolean;
}
export type { PlainDictionary as Dictionary, WithDictionaryProps, Budget };
export { BudgetStatus };
