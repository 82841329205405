import gql from 'graphql-tag';

export const GET_PROJECT_LINKED_BUDGET_ITEMS = gql`
    query getProjectLinkedBudgetItems($projectId: Int!) {
        approvedLinks: getProjects(filter: { id: $projectId }) {
            nodes {
                budgetItems {
                    id
                }
            }
        }

        allLinks: getProjectLinkBudgetItemApprovalRequests(
            filter: { status: ["pending", "approved"], project: { id: $projectId } }
        ) {
            nodes {
                id
                status
                budgetItem {
                    id
                }
            }
        }
    }
`;
