import { Success, Failure } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type { Autopilot, Autopilot2, AutopilotTV, AutopilotRadio, AutopilotDOOH } from 'sber-marketing-types/backend';
import type { PageState, SwitchKeyActivityParams } from './types';
import { LoadingStatus } from '@store/commonTypes';

import { resetStore } from '@store/common/actions';
import * as actions from './actions/sync';
import * as asyncActions from './actions/async';

class Reducer {
    public static makeInitialState(): PageState {
        return {
            activity: null,
            autopilotDOOH: null,
            autopilotRadio: null,
            autopilotTv: null,
            autopilot: null,
            autopilotLegacy: null,
            loadingStatus: {
                activity: LoadingStatus.NOT_LOADED,
                autopilotDOOH: LoadingStatus.NOT_LOADED,
                autopilotRadio: LoadingStatus.NOT_LOADED,
                autopilotTv: LoadingStatus.NOT_LOADED,
                autopilot: LoadingStatus.NOT_LOADED,
                autopilotLegacy: LoadingStatus.NOT_LOADED,
            },
        };
    }

    public static setActivityLoading(state: PageState): PageState {
        return Reducer.setActivityLoadingStatus(state, LoadingStatus.LOADING);
    }

    public static setActivityLoadingError(state: PageState): PageState {
        return Reducer.setActivityLoadingStatus(state, LoadingStatus.ERROR);
    }

    public static setAutopilotDOOHLoading(state: PageState): PageState {
        return Reducer.setAutopilotDOOHLoadingStatus(state, LoadingStatus.LOADING);
    }

    public static setAutopilotDOOHLoadingError(state: PageState): PageState {
        return Reducer.setAutopilotDOOHLoadingStatus(state, LoadingStatus.ERROR);
    }

    public static setAutopilotRadioLoading(state: PageState): PageState {
        return Reducer.setAutopilotRadioLoadingStatus(state, LoadingStatus.LOADING);
    }

    public static setAutopilotRadioLoadingError(state: PageState): PageState {
        return Reducer.setAutopilotRadioLoadingStatus(state, LoadingStatus.ERROR);
    }

    public static setAutopilotTvLoading(state: PageState): PageState {
        return Reducer.setAutopilotTvLoadingStatus(state, LoadingStatus.LOADING);
    }

    public static setAutopilotTvLoadingError(state: PageState): PageState {
        return Reducer.setAutopilotTvLoadingStatus(state, LoadingStatus.ERROR);
    }

    public static setAutopilotLoading(state: PageState): PageState {
        return Reducer.setAutopilotLoadingStatus(state, LoadingStatus.LOADING);
    }

    public static setAutopilotLoadingError(state: PageState): PageState {
        return Reducer.setAutopilotLoadingStatus(state, LoadingStatus.ERROR);
    }

    public static setAutopilotLegacyLoading(state: PageState): PageState {
        return Reducer.setAutopilotLegacyLoadingStatus(state, LoadingStatus.LOADING);
    }

    public static setAutopilotLegacyLoadingError(state: PageState): PageState {
        return Reducer.setAutopilotLegacyLoadingStatus(state, LoadingStatus.ERROR);
    }

    public static loadActivity(state: PageState, payload: Success<number, Activity>): PageState {
        return {
            ...state,
            loadingStatus: {
                ...state.loadingStatus,
                activity: LoadingStatus.LOADED,
            },
            activity: payload.result,
        };
    }

    public static loadAutopilotDOOH(state: PageState, payload: Success<number, AutopilotDOOH>): PageState {
        return {
            ...state,
            loadingStatus: {
                ...state.loadingStatus,
                autopilotDOOH: LoadingStatus.LOADED,
            },
            autopilotDOOH: payload.result,
        };
    }

    public static loadAutopilotRadio(state: PageState, payload: Success<number, AutopilotRadio>): PageState {
        return {
            ...state,
            loadingStatus: {
                ...state.loadingStatus,
                autopilotRadio: LoadingStatus.LOADED,
            },
            autopilotRadio: payload.result,
        };
    }

    public static loadAutopilotTv(state: PageState, payload: Success<number, AutopilotTV>): PageState {
        return {
            ...state,
            loadingStatus: {
                ...state.loadingStatus,
                autopilotTv: LoadingStatus.LOADED,
            },
            autopilotTv: payload.result,
        };
    }

    public static loadAutopilot(state: PageState, payload: Success<number, Autopilot2>): PageState {
        return {
            ...state,
            loadingStatus: {
                ...state.loadingStatus,
                autopilot: LoadingStatus.LOADED,
            },
            autopilot: payload.result,
        };
    }

    public static loadAutopilotLegacy(state: PageState, payload: Success<number, Autopilot>): PageState {
        return {
            ...state,
            loadingStatus: {
                ...state.loadingStatus,
                autopilotLegacy: LoadingStatus.LOADED,
            },
            autopilotLegacy: payload.result,
        };
    }

    public static switchKeyActivityStarted(state: PageState, payload: SwitchKeyActivityParams): PageState {
        return {
            ...state,
            activity: {
                ...state.activity,
                isKey: payload.isKey,
            },
        };
    }

    public static switchKeyActivityFailed(
        state: PageState,
        payload: Failure<SwitchKeyActivityParams, Error>,
    ): PageState {
        return {
            ...state,
            activity: {
                ...state.activity,
                isKey: !payload.params.isKey,
            },
            loadingStatus: {
                ...state.loadingStatus,
                activity: LoadingStatus.ERROR,
            },
        };
    }

    private static setActivityLoadingStatus(state: PageState, activity: LoadingStatus): PageState {
        return {
            ...state,
            loadingStatus: {
                ...state.loadingStatus,
                activity,
            },
        };
    }

    private static setAutopilotDOOHLoadingStatus(state: PageState, autopilotDOOH: LoadingStatus): PageState {
        return {
            ...state,
            loadingStatus: {
                ...state.loadingStatus,
                autopilotDOOH,
            },
        };
    }

    private static setAutopilotRadioLoadingStatus(state: PageState, autopilotRadio: LoadingStatus): PageState {
        return {
            ...state,
            loadingStatus: {
                ...state.loadingStatus,
                autopilotRadio,
            },
        };
    }

    private static setAutopilotTvLoadingStatus(state: PageState, autopilotTv: LoadingStatus): PageState {
        return {
            ...state,
            loadingStatus: {
                ...state.loadingStatus,
                autopilotTv,
            },
        };
    }

    private static setAutopilotLoadingStatus(state: PageState, autopilot: LoadingStatus): PageState {
        return {
            ...state,
            loadingStatus: {
                ...state.loadingStatus,
                autopilot,
            },
        };
    }

    private static setAutopilotLegacyLoadingStatus(state: PageState, autopilotLegacy: LoadingStatus): PageState {
        return {
            ...state,
            loadingStatus: {
                ...state.loadingStatus,
                autopilotLegacy,
            },
        };
    }
}

export const activityPageReducer = reducerWithInitialState(Reducer.makeInitialState())
    .cases([actions.resetActivity, resetStore], Reducer.makeInitialState)
    .case(asyncActions.loadActivity.failed, Reducer.setActivityLoadingError)
    .case(asyncActions.loadActivity.started, Reducer.setActivityLoading)
    .case(asyncActions.loadActivity.done, Reducer.loadActivity)
    .case(asyncActions.loadAutopilotDOOH.started, Reducer.setAutopilotDOOHLoading)
    .case(asyncActions.loadAutopilotDOOH.failed, Reducer.setAutopilotDOOHLoadingError)
    .case(asyncActions.loadAutopilotDOOH.done, Reducer.loadAutopilotDOOH)
    .case(asyncActions.loadAutopilotRadio.started, Reducer.setAutopilotRadioLoading)
    .case(asyncActions.loadAutopilotRadio.failed, Reducer.setAutopilotRadioLoadingError)
    .case(asyncActions.loadAutopilotRadio.done, Reducer.loadAutopilotRadio)
    .case(asyncActions.loadAutopilotTv.started, Reducer.setAutopilotTvLoading)
    .case(asyncActions.loadAutopilotTv.failed, Reducer.setAutopilotTvLoadingError)
    .case(asyncActions.loadAutopilotTv.done, Reducer.loadAutopilotTv)
    .case(asyncActions.loadAutopilot.started, Reducer.setAutopilotLoading)
    .case(asyncActions.loadAutopilot.failed, Reducer.setAutopilotLoadingError)
    .case(asyncActions.loadAutopilot.done, Reducer.loadAutopilot)
    .case(asyncActions.loadAutopilotLegacy.started, Reducer.setAutopilotLegacyLoading)
    .case(asyncActions.loadAutopilotLegacy.failed, Reducer.setAutopilotLegacyLoadingError)
    .case(asyncActions.loadAutopilotLegacy.done, Reducer.loadAutopilotLegacy)
    .case(asyncActions.switchKeyActivity.started, Reducer.switchKeyActivityStarted)
    .case(asyncActions.switchKeyActivity.failed, Reducer.switchKeyActivityFailed);
