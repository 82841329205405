import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import type { FeaturesId, FeaturesValue } from '@store/autopilot/generationTextForm';
import { getFeatures, removeFeatures, changeFeatures, addFeatures } from '@store/autopilot/generationTextForm';

import { Item } from './Item';

import * as styles from './styles.scss';

interface Props {}

interface OnRemoveHandler {
    (id: FeaturesId): void;
}

interface OnChangeHandler {
    (params: { id: FeaturesId; value: FeaturesValue }): void;
}

interface OnEnterPressHandler {
    (): void;
}

export const List: React.FC<Props> = ({}) => {
    const dispatch = useDispatch();

    const features = useSelector((state: StoreState) => getFeatures(state));

    const onRemove: OnRemoveHandler = React.useCallback((id) => {
        dispatch(removeFeatures(id));
    }, []);

    const onChange: OnChangeHandler = React.useCallback(({ id, value }) => {
        dispatch(changeFeatures({ id, value }));
    }, []);

    const onEnterPress: OnEnterPressHandler = React.useCallback(() => {
        dispatch(addFeatures());
    }, []);

    return (
        <div className={styles.root}>
            {features.map(({ id, value }) => (
                <Item
                    key={id}
                    value={value}
                    onChange={(value) => onChange({ id, value: value.target.value })}
                    onRemove={() => onRemove(id)}
                    onEnterPress={onEnterPress}
                />
            ))}
        </div>
    );
};
