import * as lodash from 'lodash';
import autobind from 'autobind-decorator';

import type { LinesGroup, ActivityLineParams, TaskLineParams } from '@store/calendar/types';
import { Period, LineType, DEFAULT_TYPE_COLOR } from '@store/calendar/types';
import type { ActivityDrawParams, TaskDrawParams } from '../modules';

import { store } from '@store';
import { getCalendarPageState } from '@store/calendar/selectors';
import { GroupsFilter, Virtualizer, Scroller, Scaler, TimeCalculator, Drawer } from '../modules';

interface StoreProps {
    period: Period;
    hoveredItemId: React.ReactText;
}

export class SceneDrawer {
    private static instance: SceneDrawer;
    private oldStoreProps: StoreProps;
    private unsubscribeStore: () => void;
    private expiredTaskIcon: HTMLImageElement;
    private finishedTaskIcon: HTMLImageElement;
    private canvasList: { [groupId: string]: HTMLCanvasElement } = {};
    private timeCalculator: TimeCalculator;
    private groupsFilter: GroupsFilter;
    private virtualizer: Virtualizer;
    private scroller: Scroller;
    private scaler: Scaler;

    private constructor() {
        this.timeCalculator = TimeCalculator.getInstance();
        this.groupsFilter = GroupsFilter.getInstance();
        this.virtualizer = Virtualizer.getInstance();
        this.scroller = Scroller.getInstance();
        this.scaler = Scaler.getInstance();

        this.groupsFilter.register('sceneDrawer', this.onActivityGroupsChange);
        this.scroller.register('sceneDrawer', this.onScrollerEmit);

        this.unsubscribeStore = store.subscribe(this.onStoreUpdate);
        this.oldStoreProps = this.getStoreProps();

        this.expiredTaskIcon = new Image();
        this.expiredTaskIcon.src = '/static/images/filled_expired_task.png';

        this.finishedTaskIcon = new Image();
        this.finishedTaskIcon.src = '/static/images/finished_task.png';
    }

    public static getInstance(): SceneDrawer {
        if (!SceneDrawer.instance) {
            SceneDrawer.instance = new SceneDrawer();
        }
        return SceneDrawer.instance;
    }

    public dispose() {
        SceneDrawer.instance = null;

        this.timeCalculator = null;
        this.groupsFilter = null;
        this.virtualizer = null;
        this.scroller = null;
        this.scaler = null;

        this.oldStoreProps = null;
        this.canvasList = {};

        this.unsubscribeStore();
    }

    public addCanvas(groupId: string, canvas: HTMLCanvasElement) {
        this.canvasList[groupId] = canvas;
    }

    public drawScene() {
        this.clearScene();
        this.updateScroll();
        this.drawHorizontalLines();
        this.drawTodayLine();
        this.drawSeparatorLines();
        this.drawGroupLines();
    }

    public startWatchingPageScroll() {
        const pageContent = document.getElementById('pageContent') as HTMLDivElement;

        pageContent.addEventListener('scroll', this.onPageScroll, { passive: true });
    }

    public stopWatchingPageScroll() {
        const pageContent = document.getElementById('pageContent') as HTMLDivElement;

        pageContent.removeEventListener('scroll', this.onPageScroll);
    }

    @autobind
    private onStoreUpdate() {
        const newStoreProps = this.getStoreProps();

        const hoveredItemIdChanged = !lodash.isEqual(newStoreProps.hoveredItemId, this.oldStoreProps.hoveredItemId);

        if (hoveredItemIdChanged) {
            this.onStorePropsChange();

            this.oldStoreProps = newStoreProps;
        }
    }

    @autobind
    private onStorePropsChange() {
        this.drawScene();
    }

    @autobind
    private onActivityGroupsChange() {
        this.drawScene();
    }

    @autobind
    private onScrollerEmit() {
        this.drawScene();
    }

    @autobind
    private onPageScroll() {
        this.drawScene();
    }

    private clearScene() {
        const organizationGroups = this.groupsFilter.getFilteredGroups();

        const visibleLinesIds = this.virtualizer.getVisibleLineIds(organizationGroups);

        organizationGroups.forEach((organizationGroup) => {
            organizationGroup.groups.forEach((group) => {
                const visibleLinesIndexes: number[] = [];

                group.lines.forEach((line, lineIndex) => {
                    const lineIsVisible = lodash.includes(visibleLinesIds, line.id);

                    if (lineIsVisible) {
                        visibleLinesIndexes.push(lineIndex);
                    }
                });

                if (!lodash.isEmpty(visibleLinesIndexes)) {
                    const groupCanvas = this.canvasList[group.id];

                    if (groupCanvas) {
                        Drawer.clear(groupCanvas, visibleLinesIndexes);
                    }
                }
            });
        });
    }

    private updateScroll() {
        const organizationGroups = this.groupsFilter.getFilteredGroups();

        const visibleLinesIds = this.virtualizer.getVisibleLineIds(organizationGroups);

        const groups = lodash.flatMap(organizationGroups, (item) => item.groups);

        const visibleGroups = groups.filter((group) =>
            group.lines.some((line) => lodash.includes(visibleLinesIds, line.id)),
        );

        if (!lodash.isEmpty(visibleGroups)) {
            const scrollLeft = this.getLeftScroll();

            visibleGroups.forEach((group) => {
                const groupCanvas = this.canvasList[group.id];

                if (groupCanvas) {
                    Drawer.setScroll(groupCanvas, scrollLeft);
                }
            });
        }
    }

    private drawHorizontalLines() {
        const organizationGroups = this.groupsFilter.getFilteredGroups();

        const visibleLinesIds = this.virtualizer.getVisibleLineIds(organizationGroups);

        organizationGroups.forEach((organizationGroup) => {
            organizationGroup.groups.forEach((group) => {
                const groupCanvas = this.canvasList[group.id];

                if (groupCanvas) {
                    group.lines.forEach((line, lineIndex) => {
                        const lineIsVisible = lodash.includes(visibleLinesIds, line.id);
                        const lineIsActivity = line.type == LineType.Activity;
                        const lineIsTask = line.type == LineType.Task;

                        if (lineIsVisible && (lineIsActivity || lineIsTask)) {
                            Drawer.drawHorizontalLine(groupCanvas, lineIndex);
                        }
                    });
                }
            });
        });
    }

    private drawTodayLine() {
        const organizationGroups = this.groupsFilter.getFilteredGroups();

        const visibleLinesIds = this.virtualizer.getVisibleLineIds(organizationGroups);

        const today = new Date().toISOString();

        const dayIndex = this.timeCalculator.getDayIndexByDate(today);

        const { start, end } = this.scaler.convertDayToRangeInPx(dayIndex);

        const todayX = (start + end) / 2;

        organizationGroups.forEach((organizationGroup) => {
            organizationGroup.groups.forEach((group) => {
                const groupIsVisible = group.lines.some((line) => lodash.includes(visibleLinesIds, line.id));

                if (groupIsVisible) {
                    const groupCanvas = this.canvasList[group.id];

                    if (groupCanvas) {
                        Drawer.drawTodayLine(groupCanvas, todayX);
                    }
                }
            });
        });
    }

    private drawSeparatorLines() {
        const visibleLinesIndexesByGroup = this.getVisibleLinesIndexes();

        const separatorPositions = this.getSeparatorPositions();

        const organizationGroups = this.groupsFilter.getFilteredGroups();

        const groups = lodash.flatMap(organizationGroups, (item) => item.groups);

        const expandedGroups = groups.filter((group) => group.lines.length > 1);

        lodash.forEach(expandedGroups, (group) => {
            const groupCanvas = this.canvasList[group.id];
            const lineIndexed = visibleLinesIndexesByGroup[group.id];

            separatorPositions.forEach((positionX) => {
                if (groupCanvas) {
                    Drawer.drawSeparator(groupCanvas, positionX, lineIndexed);
                }
            });
        });
    }

    private getSeparatorPositions(): number[] {
        const { period } = this.getStoreProps();

        const periodFirstDays =
            period == Period.Year
                ? this.timeCalculator.getQuartersFirstDays()
                : this.timeCalculator.getMonthsFirstDays();

        return periodFirstDays.map((day) => this.scaler.convertDayToRangeInPx(day).start);
    }

    private drawGroupLines() {
        const organizationGroups = this.groupsFilter.getFilteredGroups();

        const visibleLinesIds = this.virtualizer.getVisibleLineIds(organizationGroups);

        organizationGroups.forEach((organizationGroup) => {
            organizationGroup.groups.forEach((group) => {
                const groupCanvas = this.canvasList[group.id];

                if (groupCanvas) {
                    group.lines.forEach((line, lineIndex) => {
                        const lineIsVisible = lodash.includes(visibleLinesIds, line.id);

                        if (lineIsVisible) {
                            switch (line.type) {
                                case LineType.GroupTitle:
                                    const text = this.makeGroupTitleText(group);

                                    Drawer.drawGroupNameText(groupCanvas, text);
                                    break;

                                case LineType.Activity:
                                    const activityDrawParams = this.makeActivityDrawParams(
                                        line as ActivityLineParams,
                                        lineIndex,
                                    );

                                    Drawer.drawActivity(groupCanvas, activityDrawParams, this.expiredTaskIcon);
                                    break;

                                case LineType.Task:
                                    const taskDrawParams = this.makeTaskDrawParams(line as TaskLineParams, lineIndex);

                                    Drawer.drawTask(
                                        groupCanvas,
                                        taskDrawParams,
                                        this.expiredTaskIcon,
                                        this.finishedTaskIcon,
                                    );
                                    break;
                            }
                        }
                    });
                }
            });
        });
    }

    private makeGroupTitleText(group: LinesGroup): string {
        let text: string;

        if (group.isExpanded && group.lines.length > 1) {
            text = '';
        }

        if (group.isExpanded && group.lines.length <= 1) {
            text = 'Активностей на данный период нет';
        }

        if (!group.isExpanded) {
            text = `Скрытые активности: ${group.activitiesCount}`;
        }

        return text;
    }

    private makeActivityDrawParams(line: ActivityLineParams, lineIndex: number): ActivityDrawParams {
        const { hoveredItemId } = this.getStoreProps();

        return {
            lineIndex,
            preparationStart: this.scaler.convertDayToRangeInPx(line.preparationStart).start,
            realizationStart: this.scaler.convertDayToRangeInPx(line.realizationStart).start,
            realizationEnd: this.scaler.convertDayToRangeInPx(line.realizationEnd).end,
            debriefingEnd: this.scaler.convertDayToRangeInPx(line.debriefingEnd).end,
            isOneDayActivity: line.isOneDayActivity,
            color: line.color || DEFAULT_TYPE_COLOR,
            hasExpiredStages: line.hasExpiredStages,
            isActive: line.isActive,
            isHovered: hoveredItemId == line.id,
        };
    }

    private makeTaskDrawParams(line: TaskLineParams, lineIndex: number): TaskDrawParams {
        const { hoveredItemId } = this.getStoreProps();

        const deadline = this.scaler.convertDayToRangeInPx(line.deadline);

        return {
            lineIndex,
            deadline: (deadline.start + deadline.end) / 2,
            deadlineExpired: line.deadlineExpired,
            taskIsClosed: line.taskIsClosed,
            color: line.color || DEFAULT_TYPE_COLOR,
            isActive: line.activityIsActive,
            isHovered: hoveredItemId == line.id,
        };
    }

    private getVisibleLinesIndexes(): { [groupId: string]: number[] } {
        const organizationGroups = this.groupsFilter.getFilteredGroups();
        const visibleLinesIds = this.virtualizer.getVisibleLineIds(organizationGroups);

        const lineIndexesByGroup = {};

        organizationGroups.forEach((organizationGroup) => {
            organizationGroup.groups.forEach((group) => {
                lineIndexesByGroup[group.id] = [];

                group.lines.forEach((line, lineIndex) => {
                    const lineIsVisible = lodash.includes(visibleLinesIds, line.id);

                    if (lineIsVisible) {
                        lineIndexesByGroup[group.id].push(lineIndex);
                    }
                });
            });
        });

        return lineIndexesByGroup;
    }

    private getLeftScroll() {
        return this.scroller.currentScroll * this.scaler.getViewportWidthInPx();
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        const { period, hoveredItemId } = getCalendarPageState(storeState);

        return {
            period,
            hoveredItemId,
        };
    }
}
