import * as React from 'react';

import * as style from './FundsActions.scss';

import {} from 'sber-marketing-ui';

interface Props {}

export const FundsActionsTemplate = ({}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.title}>
                ID исполнения:&nbsp;
                {/* <a className={style.link} href={'#'}>2045</a>&nbsp; */}
                <span className={style.description}>не привязан</span>
            </div>

            <div className={style.actions}>
                <div className={style.button} onClick={() => console.log('Внести план')}>
                    Внести план
                </div>

                <div className={style.button} onClick={() => console.log('Внести резерв')}>
                    Внести резерв
                </div>

                <div className={style.button} onClick={() => console.log('Внести факт')}>
                    Внести факт
                </div>
            </div>
        </div>
    );
};
