import * as React from 'react';

import { IndicatorList } from './IndicatorList';
import { ButtonList } from './ButtunList';

import * as style from './CardFooter.scss';

interface Props {
    indicators: JSX.Element[];
    buttons: JSX.Element[];
}

export const LeftSideFooter: React.FC = ({ children }) => <div className={style.leftSide}>{children}</div>;

export const RightSideFooter: React.FC = ({ children }) => <div className={style.rightSide}>{children}</div>;

export const CardFooter: React.FC<Props> = ({ indicators, buttons }) => (
    <div className={style.root}>
        <LeftSideFooter>
            <IndicatorList indicators={indicators} />
        </LeftSideFooter>
        <RightSideFooter>
            <ButtonList buttons={buttons} />
        </RightSideFooter>
    </div>
);
