import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';
import { LoadingStatus } from '@store/commonTypes';
import { getStatus, getTasksList } from '@store/tasksList';
import {
    getActivityDAMArchiveState,
    canEditArchive,
    showPreloader,
    loadData,
    loadTasksFiles,
    resetComponentState,
} from '@store/activityFilesDAMArchive';

import { ArchiveInfo } from './ArchiveInfo';
import { ArchiveContentPopup } from './ArchiveContentPopup';
import { NameDuplicatesPopup } from './NameDuplicatesPopup';

const SBER_ORGANIZATION = '68a38c6a-79f7-49b5-9356-b779fbbb9b64';

function useDAMFilesArchiveWidget(activityId: number) {
    const dispatch = useDispatch();

    const userOrganizationMatch = useSelector(
        (state: StoreState) => getLoginUser(state).attributes.organizationId === SBER_ORGANIZATION,
    );
    const hasEditAccess = useSelector((state: StoreState) => canEditArchive(state));

    const tasksListLoadingStatus = useSelector((state: StoreState) => getStatus(state));
    const taskList = useSelector((state: StoreState) => getTasksList(state));

    const preloaderIsVisible = useSelector((state: StoreState) => showPreloader(state));
    const hasTaskWithFiles = useSelector(
        (state: StoreState) => !!getActivityDAMArchiveState(state).tasksFiles.entities.length,
    );

    const shouldRender = (userOrganizationMatch && (hasEditAccess ? hasTaskWithFiles : true)) || preloaderIsVisible;

    React.useEffect(() => {
        dispatch(loadData(activityId));

        return function resetComponent() {
            dispatch(resetComponentState());
        };
    }, []);

    React.useEffect(() => {
        if (tasksListLoadingStatus === LoadingStatus.LOADED && hasEditAccess) {
            const taskWithLoadingStatus = taskList.find((task) => task.isLoading);
            const shouldLoadTasksFiles = taskWithLoadingStatus || tasksListLoadingStatus === LoadingStatus.LOADED;

            if (shouldLoadTasksFiles) {
                dispatch(loadTasksFiles(taskWithLoadingStatus?.id || null));
            }
        }
    }, [tasksListLoadingStatus, taskList, hasEditAccess]);

    return { shouldRender };
}

interface Props {
    activityId: number;
    className: string;
}

export function DAMFilesArchiveWidget({ activityId, className }: Props): JSX.Element {
    const { shouldRender } = useDAMFilesArchiveWidget(activityId);

    return shouldRender ? (
        <div className={className}>
            <ArchiveInfo />
            <ArchiveContentPopup />
            <NameDuplicatesPopup />
        </div>
    ) : null;
}
