// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionLineSelection-Arrow-_Arrow__root{position:absolute;top:106px;left:-13px}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionLineSelection-Arrow-_Arrow__rootRotated{transform:rotate(180deg)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/InternalTransitionLineSelection/Arrow/Arrow.scss"],"names":[],"mappings":"AAAA,4HACI,iBAAkB,CAClB,SAAU,CACV,UAAW,CACd,mIAGG,wBAAyB","sourcesContent":[".root {\n    position: absolute;\n    top: 106px;\n    left: -13px;\n}\n\n.rootRotated {\n    transform: rotate(180deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionLineSelection-Arrow-_Arrow__root",
	"rootRotated": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionLineSelection-Arrow-_Arrow__rootRotated"
};
export default ___CSS_LOADER_EXPORT___;
