import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import reactOnclickoutside from 'react-onclickoutside';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type {
    ActivityType,
    DepartmentAttributes as Department,
    OrganizationView as Organization,
} from 'sber-marketing-types/frontend';
import type { User } from '@store/user/types';
import type { ResponsibleUser, Filters, DivisionNames } from '@store/calendar/types';

import type { FilterItem } from 'sber-marketing-ui';
import { CalendarFilters } from './CalendarFilters';
import type { StoreState } from '@store';
import { getLoginUser } from '@store/user/selector';
import { setFilters } from '@store/calendar/actions';
import { getPageData, getFilters, getBlocks, getCalendarGroups, getDepartmentsByIds } from '@store/calendar/selectors';
import { isRequestInProgress } from '@store/common/selectors';
import { PlainDictionary } from '@mrm/dictionary';

interface Props extends Partial<MapProps>, Partial<DispatchProps>, Partial<RouteComponentProps> {}

interface MapProps {
    preloader: boolean;
    user: User;
    organizations: Organization[];
    activityTypes: ActivityType[];
    responsibleUsers: ResponsibleUser[];
    calendarGroups: PlainDictionary[];
    products: PlainDictionary[];
    divisions: PlainDictionary[];
    divisionNames: DivisionNames;
    departments: Department[];
    departmentsByIds: { [id: string]: Department };
    blocks: PlainDictionary[];
    selectedOrganizationIds: string[];
    selectedActivityTypeIds: string[];
    selectedDivisionIds: string[];
    selectedProductIds?: string[];
    selectedDepartmentIds?: string[];
    selectedResponsibleUserIds: number[];
}

interface DispatchProps {
    setFilters: (filters: Filters) => void;
}

interface State {
    isOpened: boolean;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
@(reactOnclickoutside as any)
export class CalendarFiltersContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isOpened: false,
        };
    }

    public render(): JSX.Element {
        return React.createElement(CalendarFilters, {
            preloader: this.props.preloader,
            organizationFilterItems: this.makeOrganizationsFilterItems(),
            calendarGroupsFilterItems: this.makeCalendarGroupFilterItems(),
            // productsFilterItems: this.makeProductsFilterItems(),
            activityTypesFilterItems: this.makeActivityTypesFilterItems(),
            departmentFilterItems: this.makeDepartmentsFilterItems(),
            responsibleUsersFilterItems: this.makeResponsibleUsersFilterItems(),
            selectedOrganizationIds: this.props.selectedOrganizationIds,
            selectedDivisionIds: this.props.selectedDivisionIds,
            // selectedProductIds: this.props.selectedProductIds,
            selectedActivityTypeIds: this.props.selectedActivityTypeIds,
            selectedDepartmentIds: this.props.selectedDepartmentIds,
            selectedResponsibleUserIds: this.props.selectedResponsibleUserIds,
            areFiltersApplied: this.defineAreFilterApplied(),
            isOpened: this.state.isOpened,
            onOpenerClick: this.onOpenerClick,
            onResetFiltersButtonClick: this.onResetFiltersButtonClick,
            onOrganizationsFilterChange: this.onOrganizationsFilterChange,
            onDivisionsFilterChange: this.onDivisionsFilterChange,
            // onProductFilterChange: this.onProductFilterChange,
            onActivityTypeFilterChange: this.onActivityTypeFilterChange,
            onDepartmentFilterChange: this.onDepartmentFilterChange,
            onResponsibleUserFilterChange: this.onResponsibleUserFilterChange,
        });
    }

    @autobind
    public handleClickOutside() {
        if (this.state.isOpened) {
            this.setState({
                isOpened: false,
            });
        }
    }

    @autobind
    protected onOpenerClick(): void {
        this.setState(({ isOpened }) => ({ isOpened: !isOpened }));
    }

    @autobind
    protected onResetFiltersButtonClick(): void {
        const { user } = this.props;

        const userOrganizationId = user.attributes.organizationId;

        this.props.setFilters({
            organizationIds: [userOrganizationId],
            activityTypeIds: [],
            divisionIds: [],
            productIds: [],
            departmentIds: [],
            responsibleUserIds: [],
        });
    }

    @autobind
    protected onOrganizationsFilterChange(checkedItemsIds: React.ReactText[]) {
        this.removeQueryFromUrl();

        this.props.setFilters({
            organizationIds: checkedItemsIds as string[],
        });
    }

    @autobind
    protected onDivisionsFilterChange(checkedItemsIds: React.ReactText[]) {
        this.removeQueryFromUrl();

        this.props.setFilters({
            divisionIds: checkedItemsIds as string[],
        });
    }

    @autobind
    protected onProductFilterChange(checkedItemsIds: React.ReactText[]) {
        this.removeQueryFromUrl();

        this.props.setFilters({
            productIds: checkedItemsIds as string[],
        });
    }

    @autobind
    protected onActivityTypeFilterChange(checkedItemsIds: React.ReactText[]) {
        this.removeQueryFromUrl();

        this.props.setFilters({
            activityTypeIds: checkedItemsIds as string[],
        });
    }

    @autobind
    protected onDepartmentFilterChange(checkedItemsIds: React.ReactText[]) {
        this.removeQueryFromUrl();

        this.props.setFilters({
            departmentIds: checkedItemsIds as string[],
        });
    }

    @autobind
    protected onResponsibleUserFilterChange(checkedItemsIds: React.ReactText[]) {
        this.removeQueryFromUrl();

        this.props.setFilters({
            responsibleUserIds: checkedItemsIds as number[],
        });
    }

    private defineAreFilterApplied(): boolean {
        const { user } = this.props;

        const userOrganizationId = user.attributes.organizationId;

        const {
            selectedOrganizationIds,
            selectedActivityTypeIds,
            selectedDivisionIds,
            selectedProductIds,
            selectedDepartmentIds,
            selectedResponsibleUserIds,
        } = this.props;

        const filtersIds = [
            ...selectedActivityTypeIds,
            ...selectedDivisionIds,
            ...selectedProductIds,
            ...selectedDepartmentIds,
            ...selectedResponsibleUserIds,
        ];

        const organizationFilterIsInDefaultState =
            selectedOrganizationIds.length == 1 && lodash.first(selectedOrganizationIds) == userOrganizationId;

        const filtersAreInDefaultState = lodash.isEmpty(filtersIds);

        return !organizationFilterIsInDefaultState || !filtersAreInDefaultState;
    }

    private makeOrganizationsFilterItems(): FilterItem[] {
        const { organizations } = this.props;

        return organizations.map((item, index) => ({
            id: item.id,
            title: item.name,
            order: index,
        }));
    }

    private makeCalendarGroupFilterItems(): FilterItem[] {
        const { calendarGroups, organizations, selectedOrganizationIds } = this.props;
        const haveSelectedOrganizationIds = Boolean(selectedOrganizationIds.length);

        const filteredCalendarGroups = haveSelectedOrganizationIds
            ? calendarGroups.filter((group) => lodash.includes(selectedOrganizationIds, group.organizationId))
            : calendarGroups;

        return filteredCalendarGroups.map((group) => {
            const organization = organizations.find((organization) => organization.id === group.organizationId);

            return {
                id: group.id,
                title: group.value,
                description: organization ? organization.name : '',
            };
        });
    }

    private makeActivityTypesFilterItems(): FilterItem[] {
        const { activityTypes, organizations, selectedOrganizationIds } = this.props;
        const haveSelectedOrganizationIds = Boolean(selectedOrganizationIds.length);

        const filteredActivityTypes = haveSelectedOrganizationIds
            ? activityTypes.filter((activityType) =>
                  lodash.includes(selectedOrganizationIds, activityType.organizationId),
              )
            : activityTypes;

        return filteredActivityTypes.map((activityType) => {
            const organization = organizations.find((organization) => organization.id === activityType.organizationId);

            return {
                id: activityType.id,
                title: activityType.name,
                description: organization ? organization.name : '',
            };
        });
    }

    private makeDepartmentsFilterItems(): FilterItem[] {
        const { departments, departmentsByIds, selectedOrganizationIds } = this.props;
        const haveSelectedOrganizationIds = !lodash.isEmpty(selectedOrganizationIds);

        const filteredDepartments = haveSelectedOrganizationIds
            ? departments.filter((item) => lodash.includes(selectedOrganizationIds, item.organizationId))
            : departments;

        const filteredDepartmentsIds = filteredDepartments.map((item) => item.id);

        const childrenIdsMap: { [parentId: string]: string[] } = lodash.groupBy(
            filteredDepartmentsIds,
            (id) => departmentsByIds[id].parentDepartmentId,
        );

        const rootDepartmentsIds = childrenIdsMap['null'] || [];

        const filterItems = rootDepartmentsIds.map((id) => makeFilterItem(id));

        function makeFilterItem(id: string): FilterItem {
            const childrenIds = childrenIdsMap[id] || [];
            const hasChildren = !lodash.isEmpty(childrenIds);

            const children = lodash.sortBy(
                childrenIds.map((childId) => makeFilterItem(childId)),
                (item) => item.title.trim(),
            );

            if (hasChildren) {
                children.unshift({
                    id,
                    title: departmentsByIds[id].name,
                });
            }

            return {
                id: hasChildren ? `root-${id}` : id,
                title: departmentsByIds[id].name,
                children,
            };
        }

        return filterItems;
    }

    private makeResponsibleUsersFilterItems(): FilterItem[] {
        const { selectedOrganizationIds, responsibleUsers } = this.props;
        const haveSelectedOrganizationIds = Boolean(selectedOrganizationIds.length);

        const filteredResponsibleUsers = haveSelectedOrganizationIds
            ? responsibleUsers.filter((user) => lodash.includes(selectedOrganizationIds, user.organizationId))
            : responsibleUsers;

        return filteredResponsibleUsers.map((responsibleUser) => {
            const organization = this.props.organizations.find(
                (organization) => organization.id === responsibleUser.organizationId,
            );

            return {
                id: responsibleUser.id,
                title: responsibleUser.name,
                description: organization ? organization.name : '',
            };
        });
    }

    // private makeProductsFilterItems(): FilterItem[] {
    //     const { products, selectedDivisionIds, selectedOrganizationIds } = this.props;

    //     const haveSelectedOrganizationIds = Boolean(selectedOrganizationIds.length);

    //     const filteredProducts = haveSelectedOrganizationIds
    //         ? products.filter(product => lodash.includes(selectedOrganizationIds, product.organizationId))
    //         : products;

    //     let visibleProducts: ProductParams[] = filteredProducts;

    //     const shouldFilterByBlocks = !lodash.isEmpty(selectedDivisionIds);

    //     if (shouldFilterByBlocks) {
    //         visibleProducts = filteredProducts.filter(product =>
    //             lodash.includes(selectedDivisionIds, product.divisionId)
    //         );
    //     }

    //     const productsFilterItems: FilterItem[] = visibleProducts.map(product => {
    //         const organization =
    //             this.props.organizations.find(organization => organization.id === product.organizationId);

    //         return {
    //             id: product.id,
    //             title: product.name,
    //             description: `${organization.name}, ${this.props.divisionNames[product.divisionId]}`
    //         };
    //     });

    //     if (!shouldFilterByBlocks) {
    //         productsFilterItems.push({
    //             id: null,
    //             title: 'Продукт не указан'
    //         });
    //     }

    //     return productsFilterItems;
    // }

    // private makeDivisionsFilterItems(): FilterItem[] {
    //     const { groupedDivisions, blocks, organizations, selectedOrganizationIds } = this.props;
    //     const haveSelectedOrganizationIds = Boolean(selectedOrganizationIds.length);

    //     const filterItems: FilterItem[] = lodash.map(groupedDivisions, (divisions, blockId) => {
    //         const block = blocks.find(item => item.id == blockId);
    //         const blockOrganization = organizations.find(organization => organization.id === block.organizationId);

    //         const isBlockOwnInSelectedOrganization = lodash.includes(selectedOrganizationIds, block.organizationId);

    //         if (!haveSelectedOrganizationIds || isBlockOwnInSelectedOrganization) {
    //             return {
    //                 id: `block${blockId}`,
    //                 title: block.value,
    //                 description: blockOrganization.name,
    //                 children: [
    //                     ...divisions.map(item => {
    //                         const divisionOrganization =
    //                             organizations.find(organization => organization.id === item.organizationId);

    //                         return {
    //                             id: item.id,
    //                             title: item.value,
    //                             description: divisionOrganization.name
    //                         };
    //                     }),
    //                     {
    //                         id: blockId,
    //                         title: 'Активности блока'
    //                     }
    //                 ]
    //             };
    //         }

    //         return null;
    //     });

    //     return lodash.sortBy(lodash.compact(filterItems), item => item.title);
    // }

    private removeQueryFromUrl() {
        this.props.history.push({
            search: '',
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { organizations, activityTypes, responsibleUsers, products, divisions, divisionNames, departments } =
        getPageData(state);
    const { organizationIds, activityTypeIds, divisionIds, productIds, departmentIds, responsibleUserIds } =
        getFilters(state);

    return {
        preloader: isRequestInProgress(state),
        user: getLoginUser(state),
        organizations,
        divisions,
        divisionNames,
        products,
        activityTypes,
        departments,
        departmentsByIds: getDepartmentsByIds(state),
        responsibleUsers,
        blocks: getBlocks(state),
        calendarGroups: getCalendarGroups(state),
        selectedOrganizationIds: organizationIds,
        selectedDivisionIds: divisionIds,
        selectedProductIds: productIds,
        selectedActivityTypeIds: activityTypeIds,
        selectedDepartmentIds: departmentIds,
        selectedResponsibleUserIds: responsibleUserIds,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setFilters,
        },
        dispatch,
    );
}
