import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';

import { BudgetItemStatus } from '@mrm/budget';
import { LineStatusChange } from '@store/budgetPlanning/types';

import { RejectMenu, RejectMenuDirection } from './RejectMenu';
import { setLineStatus, setRejectMenuLineId } from '@store/budgetPlanning';

interface Props extends Partial<DispatchProps> {
    lineId: string;
    direction: RejectMenuDirection;
}

interface DispatchProps {
    setLineStatus: (statusChange: LineStatusChange) => void;
    setRejectMenuLineId: (lineId: string) => void;
}

interface State {
    rejectComment: string;
}

@(connect(null, mapDispatchToProps) as any)
export class RejectMenuContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            rejectComment: '',
        };
    }

    public render(): JSX.Element {
        return React.createElement(RejectMenu, {
            direction: this.props.direction,
            rejectComment: this.state.rejectComment,
            onRejectCommentChange: this.onRejectCommentChange,
            onAcceptButtonClick: this.onAcceptButtonClick,
            onCancelButtonClick: this.onCancelButtonClick,
            onMaskClick: this.onMaskClick,
        });
    }

    @autobind
    protected onRejectCommentChange(newValue: string) {
        this.setState({
            rejectComment: newValue,
        });
    }

    @autobind
    protected onAcceptButtonClick() {
        this.props.setLineStatus({
            lineId: this.props.lineId,
            status: BudgetItemStatus.Rejected,
            rejectComment: this.state.rejectComment,
        });

        this.props.setRejectMenuLineId(null);
    }

    @autobind
    protected onCancelButtonClick() {
        this.props.setRejectMenuLineId(null);
    }

    @autobind
    protected onMaskClick() {
        this.props.setRejectMenuLineId(null);
    }
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setLineStatus,
            setRejectMenuLineId,
        },
        dispatch,
    );
}
