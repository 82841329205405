import * as React from 'react';
import { AutoSizer } from 'react-virtualized';

import * as style from './StagesCalendar.scss';

import { TimeUnitGroup, LineParams, Point, Direction, TasksByStageId, DateMark } from './types';

import { Scaler } from './modules';

import { Sidebar } from './Sidebar';
import { Timeline } from './Timeline';
import { Canvas } from './Canvas';
import { Tooltip } from './Tooltip';

export const ITEM_HEIGHT = 50;
export const CANVAS_OFFSET_TOP = 14;

interface Props {
    lines: LineParams[];
    hoveredLineIndex: number;
    timeUnitGroups: TimeUnitGroup[];
    tooltipTitle: string;
    tooltipPosition: Point;
    tooltipDirection: Direction;
    isEditable?: boolean;
    tasksByStageId: TasksByStageId;
    scaler: Scaler;
    canvasHeight: number;
    dateMarks: DateMark[];
    canvasRef: (element: HTMLCanvasElement) => void;
    onCanvasResize: (width: number) => void;
    onSidebarLineHover: (lineIndex: number) => void;
    onLineCheckboxClick: (lineIndex: number) => void;
    setCanShowStageDuration: (canShowStageDuration: boolean) => void;
    setDateMarkVisibility: (date: Date, isVisible: boolean) => void;
    onSelectStageClick?: (stageId: string) => void;
    createTaskByNameAndStage?: (taskName: string, stageId: string) => Promise<void>;
}

export function StagesCalendar({
    lines,
    hoveredLineIndex,
    timeUnitGroups,
    tooltipTitle,
    tooltipPosition,
    tooltipDirection,
    isEditable,
    tasksByStageId,
    scaler,
    canvasHeight,
    dateMarks,
    canvasRef,
    onCanvasResize,
    onSidebarLineHover,
    onLineCheckboxClick,
    setCanShowStageDuration,
    setDateMarkVisibility,
    onSelectStageClick,
    createTaskByNameAndStage,
}: Props): JSX.Element {
    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'stagesCalendar',
            }}
        >
            <div className={style.sidebar}>
                <Sidebar
                    lines={lines}
                    hoveredLineIndex={hoveredLineIndex}
                    editable={isEditable}
                    tasksByStageId={tasksByStageId}
                    onLineHover={onSidebarLineHover}
                    onLineCheckboxClick={onLineCheckboxClick}
                    setCanShowStageDuration={setCanShowStageDuration}
                    onSelectStageClick={onSelectStageClick}
                    createTaskByNameAndStage={createTaskByNameAndStage}
                />
            </div>

            <div className={style.chart}>
                <div className={style.timeline}>
                    <Timeline
                        timeUnitGroups={timeUnitGroups}
                        scaler={scaler}
                        canvasHeight={canvasHeight}
                        dateMarks={dateMarks}
                        setDateMarkVisibility={setDateMarkVisibility}
                    />
                </div>

                <div className={style.canvasWrapper}>
                    <div className={style.canvas} style={{ height: ITEM_HEIGHT * lines.length }}>
                        <AutoSizer onResize={({ width }) => onCanvasResize(width)} disableHeight>
                            {({ width }) => (
                                <Canvas
                                    width={width}
                                    height={ITEM_HEIGHT * lines.length + CANVAS_OFFSET_TOP}
                                    canvasRef={canvasRef}
                                />
                            )}
                        </AutoSizer>
                    </div>

                    {tooltipPosition && (
                        <div className={style.tooltip} style={{ top: tooltipPosition.y, left: tooltipPosition.x }}>
                            <Tooltip title={tooltipTitle} direction={tooltipDirection} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
