import * as React from 'react';
import classNames from 'classnames';

import { Scrollbar, ScrollbarProps } from '@common/components';

import * as styles from './Modals.scss';

export interface ModalsProps extends Omit<ScrollbarProps, 'rootRef'> {}

export const modalsRef = React.createRef<HTMLDivElement>();

export function Modals({ className, children, ...props }: ModalsProps) {
    return (
        <Scrollbar
            align="center"
            justify="center"
            {...props}
            rootRef={modalsRef}
            className={classNames(styles.root, className)}
        >
            {children}
        </Scrollbar>
    );
}
