import * as React from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { WithTooltip, TooltipAnchor, Icon, IconType } from 'sber-marketing-ui';

import { StoreState } from '@store';
import {
    getTransferBudgetItemsToPlanningMenuState,
    setLinesToTransfer,
} from '@store/budgetExecution/transferBudgetItemsToPlanningMenu';

import * as styles from './Line.scss';

interface Props {
    lineIsHovered?: boolean;
    wrapperClass: string;
    lineId: string;
}

function useInteractivity(lineId: string) {
    const dispatch = useDispatch();

    const [isDeleteButtonVisible, setisDeleteButtonVisible] = React.useState(false);

    const budgetItem = useSelector((state: StoreState) =>
        getTransferBudgetItemsToPlanningMenuState(state).data.activityBudgetItems.items.find(
            (budgetItem) => budgetItem.id === lineId,
        ),
    );
    const linesToTransfer = useSelector(
        (state: StoreState) => getTransferBudgetItemsToPlanningMenuState(state).props.linesToTransfer,
    );

    function deleteLine() {
        dispatch(setLinesToTransfer(linesToTransfer.filter((id) => id !== lineId)));
    }

    return {
        budgetItem,
        deleteLine,
        isDeleteButtonVisible,
        setisDeleteButtonVisible,
    };
}

export function Line({ lineIsHovered, wrapperClass, lineId }: Props): JSX.Element {
    const { budgetItem, deleteLine, isDeleteButtonVisible, setisDeleteButtonVisible } = useInteractivity(lineId);

    return (
        <div
            className={classNames(wrapperClass, styles.root, lineIsHovered && styles.transparentTheme)}
            onMouseEnter={lineIsHovered ? null : () => setisDeleteButtonVisible(true)}
            onMouseLeave={lineIsHovered ? null : () => setisDeleteButtonVisible(false)}
        >
            <span className={classNames(styles.black, styles.id)}>ID {budgetItem?.serialNumber}</span>

            <span className={classNames(styles.gray, styles.description)}>
                Активность:&nbsp;
                <span className={classNames(styles.black, styles.descriptionEllipsis)}>
                    {budgetItem?.activity?.name}
                </span>
            </span>

            {isDeleteButtonVisible && (
                <div className={styles.deleteButtonWrapper} onClick={deleteLine}>
                    <WithTooltip content="Убрать строку" anchor={TooltipAnchor.LEFT}>
                        <div className={styles.deleteButton}>
                            <Icon type={IconType.CLOSE_BUTTON_ROUNDED} />
                        </div>
                    </WithTooltip>
                </div>
            )}
        </div>
    );
}
