// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-CommonInfoWidget-_CommonInfoWidget__root{display:grid;font-family:\"Open Sans\";grid-template-rows:repeat(2, max-content);grid-row-gap:10px}.src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-CommonInfoWidget-_CommonInfoWidget__widgetTitle{color:#000000;font-size:13px;font-weight:600}.src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-CommonInfoWidget-_CommonInfoWidget__blocks{display:grid;grid-gap:16px;grid-template-columns:1fr 1fr 1fr}.src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-CommonInfoWidget-_CommonInfoWidget__title{letter-spacing:0.6px;text-transform:uppercase;color:#7e8681;font-size:12px}.src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-CommonInfoWidget-_CommonInfoWidget__content{margin-top:2px;color:#000000;font-size:12px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetPlanning/LineModal/WidgetsGroups/CommonInfoWidget/CommonInfoWidget.scss"],"names":[],"mappings":"AAAA,sHACI,YAAa,CAEb,uBAAwB,CAExB,yCAA0C,CAC1C,iBAAkB,CACrB,6HAGG,aAAc,CAEd,cAAe,CACf,eAAgB,CACnB,wHAGG,YAAa,CAEb,aAAc,CACd,iCAAkC,CACrC,uHAGG,oBAAqB,CACrB,wBAAyB,CAEzB,aAAc,CAEd,cAAe,CAClB,yHAGG,cAAe,CAEf,aAAc,CAEd,cAAe","sourcesContent":[".root {\n    display: grid;\n\n    font-family: \"Open Sans\";\n\n    grid-template-rows: repeat(2, max-content);\n    grid-row-gap: 10px;\n}\n\n.widgetTitle {\n    color: #000000;\n\n    font-size: 13px;\n    font-weight: 600;\n}\n\n.blocks {\n    display: grid;\n\n    grid-gap: 16px;\n    grid-template-columns: 1fr 1fr 1fr;\n}\n\n.title {\n    letter-spacing: 0.6px;\n    text-transform: uppercase;\n\n    color: #7e8681;\n\n    font-size: 12px;\n}\n\n.content {\n    margin-top: 2px;\n\n    color: #000000;\n\n    font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-CommonInfoWidget-_CommonInfoWidget__root",
	"widgetTitle": "src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-CommonInfoWidget-_CommonInfoWidget__widgetTitle",
	"blocks": "src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-CommonInfoWidget-_CommonInfoWidget__blocks",
	"title": "src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-CommonInfoWidget-_CommonInfoWidget__title",
	"content": "src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-CommonInfoWidget-_CommonInfoWidget__content"
};
export default ___CSS_LOADER_EXPORT___;
