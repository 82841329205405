import * as React from 'react';
import { Icon, IconType, WithTooltip, Skeleton } from 'sber-marketing-ui';

import { FileAsset } from '@common/FileAssetRedesign';
import { Utils } from '@common/Utils';

import * as styles from './FileCard.scss';

export interface FileProps {
    id: string;
    name: string;
    originName: string;
    size: number;
    type: string;
    author: {
        firstName: string;
        secondName: string;
    };
}

interface Props extends Partial<FileProps> {
    loading?: boolean;
    showDeleteButton: boolean;
    onCardMouseEnter: () => void;
    onCardMouseLeave: () => void;
    onDeleteButtonClick: () => void;
}

export function FileCard({
    loading,
    id,
    name,
    originName,
    size,
    type,
    author,
    showDeleteButton,
    onCardMouseEnter,
    onCardMouseLeave,
    onDeleteButtonClick,
}: Props): JSX.Element {
    return (
        <div
            className={styles.root}
            onMouseEnter={onCardMouseEnter}
            onMouseLeave={onCardMouseLeave}
            {...{
                'qa-id': 'dashboardRightSidebarFileGroupFileCard',
            }}
        >
            <div className={styles.asset}>
                <Skeleton
                    isReady={!loading && id}
                    loadingClassName={styles.fileAssetLoading}
                    customStyle={{
                        borderRadius: '4px',
                        height: '100%',
                    }}
                >
                    <FileAsset id={id} name={name} originName={originName} type={type} fileId={id} />
                </Skeleton>
            </div>

            <div className={styles.content}>
                <Skeleton isReady={!loading && originName} loadingClassName={styles.titleLoading}>
                    <div
                        className={styles.title}
                        {...{
                            'qa-id': 'dashboardRightSidebarFileGroupFileCardFileName',
                            'qa-value': originName,
                        }}
                    >
                        <WithTooltip content={originName}>
                            <React.Fragment>{originName}</React.Fragment>
                        </WithTooltip>
                    </div>
                </Skeleton>

                <Skeleton isReady={!loading && size} loadingClassName={styles.sizeLoading}>
                    <div className={styles.size}>{Utils.formatFileSize(size)}</div>
                </Skeleton>

                <Skeleton isReady={!loading && author} loadingClassName={styles.authorLoading}>
                    {author && (
                        <div className={styles.author}>
                            Загрузил:&nbsp;
                            <span
                                className={styles.authorBlack}
                                {...{
                                    'qa-id': 'dashboardRightSidebarFileGroupFileCardFileAuthor',
                                }}
                            >
                                {author.secondName} {author.firstName}
                            </span>
                        </div>
                    )}
                </Skeleton>
            </div>

            {!loading && showDeleteButton && (
                <div
                    {...{
                        'qa-id': 'dashboardRightSidebarFileGroupFileCardDeleteFileButton',
                    }}
                >
                    <Icon
                        type={IconType.DELETE24}
                        svgSize={24}
                        className={styles.deleteButton}
                        onClick={onDeleteButtonClick}
                    />

                    <div className={styles.deleteButtonBackgroundColored} />
                    <div className={styles.deleteButtonBackgroundGradient} />
                </div>
            )}
        </div>
    );
}
