import * as React from 'react';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { Budget, BudgetStatus } from '@mrm/budget';
import autobind from 'autobind-decorator';

import { StoreState } from '@store';
import { getOrganizationById } from '@store/organizations';
import { BudgetByStatusUserConfig, saveBudgetByStatusUserConfig } from '@store/userConfig/budget';

import { Utils } from '@common/Utils';

import { DropdownItem } from './DropdownItem';

interface Props extends OwnProps, Partial<MapProps & DispatchProps> {}

interface OwnProps extends Budget {
    selectedBudgetId: string;
    budgetStatus: BudgetStatus;
}

interface MapProps {
    organizationName: string;
}

interface DispatchProps {
    saveUserConfig(payload: Partial<BudgetByStatusUserConfig>): void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class DropdownItemBehaviour extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { organizationName } = this.props;

        return <DropdownItem {...this.props} organizationName={organizationName} onClick={this.onClick} />;
    }

    @autobind
    private onClick(): void {
        this.props.saveUserConfig({
            budgetId: this.props.id,
        });
    }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): MapProps {
    const { dictionaryOrganizationId } = ownProps;

    const organizationName = Utils.withErrorHandler<string>(
        () => getOrganizationById(state, dictionaryOrganizationId).name,
    );

    return {
        organizationName,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps {
    const { budgetStatus } = ownProps;

    return {
        saveUserConfig: (payload: Partial<BudgetByStatusUserConfig>) =>
            dispatch(
                saveBudgetByStatusUserConfig({
                    budgetStatus,
                    payload,
                }),
            ),
    };
}
