import autobind from 'autobind-decorator';
import { PureComponent, createElement } from 'react';

import { ChannelButtonsListTemplate } from './ChannelButtonsListTemplate';
import { ChannelButtonsListContainerProps as Props, ChannelButtonsListContainerState as State } from './types';

/** "ChannelButtonsList" container component */
export class ChannelButtonsListContainer extends PureComponent<Props, State> {
    public static readonly displayName: string = 'frontend::task::ChannelButtonsList::Container';

    public state: State = {
        isNewModalOpened: false,
    };

    public render() {
        const { className, channelId, taskId, activityId, from, isTaskOpened } = this.props;
        const { isNewModalOpened } = this.state;
        return createElement(ChannelButtonsListTemplate, {
            className,
            channelId,
            taskId,
            activityId,
            from,
            isNewModalOpened,
            isTaskOpened,
            onNewChannelOpenClick: this.onNewChannelOpenClick,
            onNewChannelCloseClick: this.onNewChannelCloseClick,
            onTaskCardClick: this.onTaskCardClick,
        });
    }

    /** New channel open button click handler */
    @autobind
    private onNewChannelOpenClick(): void {
        this.setState(() => ({ isNewModalOpened: true }));
    }

    /** New channel close button click handler */
    @autobind
    private onNewChannelCloseClick(): void {
        this.setState(() => ({ isNewModalOpened: false }));
    }

    @autobind
    private onTaskCardClick(): void {
        this.props.resetComment();
    }
}
