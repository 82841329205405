import * as style from './theme.scss';

export const theme = {
    ltr: style.ltr,
    rtl: style.rtl,
    placeholder: style.editorPlaceholder,
    paragraph: style.editorParagraph,
    quote: style.editorQuote,
    indent: style.editorIndent,
    heading: {
        h1: style.editorHeadingH1,
        h2: style.editorHeadingH2,
        h3: style.editorHeadingH3,
    },
    list: {
        ol: style.editorListOl,
        ul: style.editorListUl,
        listitem: style.editorListItem,
        listitemChecked: style.editorListItemChecked,
        listitemUnchecked: style.editorListItemUnchecked,
    },
    link: style.editorLink,
    text: {
        bold: style.editorTextBold,
        italic: style.editorTextItalic,
        underline: style.editorTextUnderline,
        strikethrough: style.editorTextStrikethrough,
        underlineStrikethrough: style.editorTextUnderlineStrikethrough,
    },
};
