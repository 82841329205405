import { Dictionary } from 'lodash';
import { DivisionParams } from 'sber-marketing-types/frontend';

import { LoadingStatus } from '@store/commonTypes';

export interface Division extends DivisionParams {
    children: Division[];
}

export interface DivisionsState {
    entities: DivisionParams[];
    byIds: Dictionary<DivisionParams>;
    byLevels: DictionaryByLevels;
    stores: Record<StoreTypes, EntitiesStore>;
}

export interface DictionaryByLevels {
    [level: number]: string[];
}

export const enum StoreTypes {
    GENERAL = 'GENERAL',
    CURRENT_ORGANIZATION = 'CURRENT_ORGANIZATION',
    MY_TASKS_FILTER = 'MY_TASKS_FILTER',
    AVAILABLE_ACTIVITIES_FILTERS = 'AVAILABLE_ACTIVITIES_FILTER',
    MY_ACTIVITIES_FILTER = 'MY_ACTIVITIES_FILTER',
    NEWS_FILTER = 'NEWS_FILTER',
    CUSTOM = 'CUSTOM',
}

export interface EntitiesStore {
    fetchersCount: number;
    loadingStatus: LoadingStatus;
    ids: string[];
}

export interface LoadDivisionsParams {
    store: StoreTypes;
    ids?: string[];
}

export interface SetStoreIdsParams {
    store: StoreTypes;
    ids: string[];
}

export interface SetLoadingStatusParams {
    store: StoreTypes;
    status: LoadingStatus;
}

export type LoadEntitiesPayload = DivisionParams[];

export const DivisionStub: DivisionParams = {
    id: null,
    organizationId: null,
    parentId: null,
    level: 1,
    name: 'Удаленный блок',
};
