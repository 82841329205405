import { createSelector } from 'reselect';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';

import { TaskEditorState } from './types';

export const getTaskEditorState = (state: StoreState): TaskEditorState => state.taskEditor2;

export const commonLoadingIsFinished = createSelector(getTaskEditorState, (state: TaskEditorState): boolean =>
    [state.data.userWorkType.loadingStatus, state.values.loadingStatus].some(
        (loadingStatus) => loadingStatus !== LoadingStatus.LOADED,
    ),
);
