import { axios } from '../lib/axios';

import { DepartmentAttributes as Department } from 'sber-marketing-types/frontend';

const BASE_PATH = '/api/department';

export class DepartmentApi {
    public static async getList(params?: any): Promise<Department[]> {
        const res = await axios.post(`${BASE_PATH}/list`, { ...params });
        return res.data;
    }

    public static async getListByParent(params?: { id: string }): Promise<Department[]> {
        const res = await axios.post(`${BASE_PATH}/listbyparent`, { ...params });
        return res.data;
    }
}
