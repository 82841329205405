import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Icon, IconType, Skeleton } from 'sber-marketing-ui';

import { ActivitySearchResult } from '@store/search';

import { DatesFormatter } from '@common/Utils';

import { Author } from '../WithSkeleton';
import { HighlightQuery, Theme } from '../HighlightQuery';

import * as styles from './ActivityCard.scss';

interface Props extends Partial<ActivitySearchResult> {}

export function ActivityCard({
    id,
    authorId,
    organization,
    block,
    product,
    realizationStart,
    realizationEnd,
    preparationDate,
    debriefingDate,
    name,
}: Props): JSX.Element {
    const date = new Date();
    let statusColor: string;

    if (date >= preparationDate && date < realizationStart) {
        statusColor = styles.backgroundYellow;
    } else if (date >= realizationStart && date < realizationEnd) {
        statusColor = styles.backgroundGreen;
    } else if (date >= realizationEnd && date < debriefingDate) {
        statusColor = styles.backgroundMediumBlue;
    } else if (organization) {
        statusColor = styles.backgroundGreen;
    }

    const datesFormatted =
        realizationStart && realizationEnd
            ? `${DatesFormatter.ddMonthyy(realizationStart)} — ${DatesFormatter.ddMonthyy(realizationEnd)}`
            : null;

    return (
        <div
            {...{
                'qa-id': 'searchActivityCard',
                'qa-activity-id': id,
            }}
        >
            <div className={classNames(styles.statusTop, statusColor)} />

            <div className={styles.row}>
                <div className={classNames(styles.text, styles.growingBlock)}>
                    <OrganizationAndBlock organization={organization} block={block} />
                </div>

                <div
                    className={classNames(styles.text, styles.dates)}
                    {...{
                        'qa-id': 'searchActivityCardDates',
                        'qa-value': datesFormatted,
                    }}
                >
                    <Skeleton isReady={realizationStart && realizationEnd} className={styles.skeleton}>
                        <Icon svgSize={16} type={IconType.CALENDAR_ICON} className={styles.icon} />
                        {datesFormatted}
                    </Skeleton>
                </div>
            </div>

            <div className={styles.row}>
                <div className={classNames(styles.text, styles.growingBlock)}>
                    <Product product={product} />
                </div>

                <div className={styles.author}>
                    <Author authorId={authorId} />
                </div>
            </div>

            <div
                className={styles.title}
                {...{
                    'qa-id': 'searchActivityCardActivityName',
                    'qa-value': name,
                }}
            >
                <Skeleton isReady={name} className={styles.skeleton}>
                    <Link to={`/activity/${id}`} target="_blank" className={styles.queryLink}>
                        <HighlightQuery theme={Theme.Bold} text={name} />
                    </Link>
                </Skeleton>
            </div>
        </div>
    );
}

type OrganizationAndBlockProps = Pick<Props, 'organization' | 'block'>;

function OrganizationAndBlock({ organization, block }: OrganizationAndBlockProps): JSX.Element {
    const isReady = organization !== undefined && block !== undefined;

    let content;
    let title;

    if (isReady && (organization !== null || block !== null)) {
        const organizationName = organization
            ? `${organization.name}${organization.status === 'deleted' ? '(Удалена)' : ''}`
            : '';
        const blockName = block ? `${block.name}${block.status === 'deleted' ? '(Удален)' : ''}` : '';

        title = `${organizationName}, ${blockName}`;
        content = `Организация и блок: ${title}`;
    } else {
        title = 'Не заданы';
        content = 'Организация и блок: не заданы';
    }

    return (
        <Skeleton isReady={isReady} className={styles.skeleton} loadingClassName={styles.organizationAndBlockLoading}>
            <div
                title={title}
                className={styles.text}
                {...{
                    'qa-id': 'searchActivityCardOrganizationAndBlock',
                    'qa-value': content,
                }}
            >
                <Icon svgSize={16} type={IconType.BLOCK_ICON} className={styles.icon} />
                {content}
            </div>
        </Skeleton>
    );
}

type ProductParams = Pick<Props, 'product'>;

function Product({ product }: ProductParams): JSX.Element {
    const isReady = product !== undefined;
    let content;
    let title;

    if (isReady && product !== null) {
        title = `${product.name} ${product.status === 'deleted' ? '(Удален)' : ''}`;
        content = `Продукт: ${title}`;
    } else {
        title = 'Не задан';
        content = 'Продукт: не задан';
    }

    return (
        <Skeleton isReady={isReady} className={styles.skeleton} loadingClassName={styles.productLoading}>
            <div
                title={title}
                className={styles.text}
                {...{
                    'qa-id': 'searchActivityCardProduct',
                    'qa-value': content,
                }}
            >
                <Icon svgSize={16} type={IconType.PRODUCT_ICON} className={styles.icon} />
                {content}
            </div>
        </Skeleton>
    );
}
