import * as React from 'react';

import * as style from './BudgetForm.scss';

import { Form, FormField, FieldValue } from 'sber-marketing-ui';

interface Props {
    fields: FormField[];
    onFieldValueChange: (id: string, value: FieldValue) => void;
}

export const BudgetForm = ({ fields, onFieldValueChange }: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <Form fields={fields} onFieldValueChange={onFieldValueChange} />
        </div>
    );
};
