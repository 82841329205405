import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { AxiosError } from 'axios';

import { StoreState } from '../..';
import { AddFileToCommentaryPayload, FetchError } from '../types';
import { asyncActions } from '../actions';
import { getTaskInfo } from '../selectors';
import { FileAsset } from '../../commonTypes';
import { runUploadOne } from '../../fileAssets';

export const addFileToCommentary = bindThunkAction<StoreState, AddFileToCommentaryPayload, FileAsset, FetchError>(
    asyncActions.addFileToCommentary,
    async ({ file, commentaryId }: AddFileToCommentaryPayload, dispatch, getState): Promise<FileAsset> => {
        const { id: taskId } = getTaskInfo(getState());
        let result: FileAsset | null = null;

        try {
            result = await runUploadOne({
                dispatch,
                params: { taskId, commentId: commentaryId },
                fileOrAssetOrId: file,
            });
        } catch (error) {
            const { response, message } = <AxiosError>error;
            if (response) {
                throw new FetchError(response.status, response.statusText, message);
            } else {
                throw new FetchError(0, 'Unknown', message);
            }
        }

        return result;
    },
);
