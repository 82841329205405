import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Success } from 'typescript-fsa';

import { State, InitParams, InitResult, LoadingStatus } from './types';

import * as syncActions from './actions/sync';
import * as asyncActions from './actions/async';

class Reducer {
    public static makeInitialState(): State {
        return {
            loadingStatus: LoadingStatus.NOT_LOADED,
            selectedActivityId: null,
            selectedExpertId: null,
            selectedBudgetItemIds: [],
            budgetItems: [],
        };
    }

    public static initStarted(state: State): State {
        return {
            ...state,
            loadingStatus: LoadingStatus.LOADING,
        };
    }

    public static initDone(state: State, { params, result }: Success<InitParams, InitResult>): State {
        return {
            ...state,
            loadingStatus: LoadingStatus.LOADED,
            selectedActivityId: Number(params?.selectedActivityId) || null,
            budgetItems: result?.budgetItems || [],
        };
    }

    public static initFailed(state: State): State {
        return {
            ...state,
            loadingStatus: LoadingStatus.LOADED,
        };
    }

    public static setActivityReferenceMenu(state: State, payload: State): State {
        return {
            ...state,
            ...payload,
        };
    }

    public static setSelectedBudgetItemIds(state: State, selectedBudgetItemIds: string[]): State {
        return {
            ...state,
            selectedBudgetItemIds,
        };
    }
}

export const activityReferenceMenuReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(syncActions.reset, Reducer.makeInitialState)
    .case(asyncActions.init.started, Reducer.initStarted)
    .case(asyncActions.init.done, Reducer.initDone)
    .case(asyncActions.init.failed, Reducer.initFailed)
    .case(syncActions.setActivityReferenceMenu, Reducer.setActivityReferenceMenu)
    .case(syncActions.setSelectedBudgetItemIds, Reducer.setSelectedBudgetItemIds);
