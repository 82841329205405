import { combineReducers } from 'redux';
import { UserConfigType } from 'sber-marketing-types/openid';

import { commonUserConfigReducer } from './common';
import { dashboardUserConfigReducer } from './dashboard';
import { budgetCorrectionsUserConfigReducer } from './budgetCorrections';
import { personalDashboardUserConfigReducer } from './personalDashboard';
import { budgetUserConfigReducer } from './budget';
import { creativeUserConfigReducer } from './creative';

export const userConfigReducer = combineReducers({
    [UserConfigType.Common]: commonUserConfigReducer,
    [UserConfigType.Dashboard]: dashboardUserConfigReducer,
    [UserConfigType.BudgetCorrections]: budgetCorrectionsUserConfigReducer,
    [UserConfigType.PersonalDashboard]: personalDashboardUserConfigReducer,
    [UserConfigType.Budget]: budgetUserConfigReducer,
    [UserConfigType.Creative]: creativeUserConfigReducer,
});
