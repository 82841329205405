// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-StageWidgets-TableWidget-Table-CellTypes-SelectWithIconCell-SearchInput-_SearchInput__root{padding:7px 8px;display:flex;align-items:center;border-radius:4px;border:1px solid #e3e9e5}.src-client-modules-activity-pages-creative-StageWidgets-TableWidget-Table-CellTypes-SelectWithIconCell-SearchInput-_SearchInput__input{margin-left:5px;font-family:'Open Sans';font-size:12px !important;line-height:16px !important;color:#7e8681}.src-client-modules-activity-pages-creative-StageWidgets-TableWidget-Table-CellTypes-SelectWithIconCell-SearchInput-_SearchInput__input::placeholder{color:#7e8681}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/StageWidgets/TableWidget/Table/CellTypes/SelectWithIconCell/SearchInput/SearchInput.scss"],"names":[],"mappings":"AAAA,uIACI,eAAgB,CAEhB,YAAa,CACb,kBAAmB,CAEnB,iBAAkB,CAClB,wBAAyB,CAC5B,wIAGG,eAAgB,CAEhB,uBAAwB,CACxB,yBAA0B,CAC1B,2BAA4B,CAE5B,aAAc,CAPlB,qJAUQ,aAAc","sourcesContent":[".root {\n    padding: 7px 8px;\n\n    display: flex;\n    align-items: center;\n\n    border-radius: 4px;\n    border: 1px solid #e3e9e5;\n}\n\n.input {\n    margin-left: 5px;\n\n    font-family: 'Open Sans';\n    font-size: 12px !important;\n    line-height: 16px !important;\n\n    color: #7e8681;\n\n    &::placeholder {\n        color: #7e8681;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-StageWidgets-TableWidget-Table-CellTypes-SelectWithIconCell-SearchInput-_SearchInput__root",
	"input": "src-client-modules-activity-pages-creative-StageWidgets-TableWidget-Table-CellTypes-SelectWithIconCell-SearchInput-_SearchInput__input"
};
export default ___CSS_LOADER_EXPORT___;
