import * as React from 'react';
import classNames from 'classnames';

import * as style from './StagesListWidget.scss';

import { Icon, IconType } from 'sber-marketing-ui';
import { AutopilotStatusButton } from './AutopilotStatusButton';
import { SidebarButton } from './SidebarButton';
import { Preloader } from './Preloader';

interface Props {
    stages: StageGroupProps[];
    preloader: boolean;
}

export const StagesListWidgetTemplate = ({ stages, preloader }: Props): JSX.Element => {
    return (
        <div className={style.root}>
            {!preloader && (
                <>
                    <div className={style.topLine}>
                        <div className={style.title}>
                            Этапы автопилота&nbsp;
                            {/* <span className={style.titleCounter}>(1/5)</span> */}
                        </div>

                        <div className={style.autopilotStatusButton}>
                            <AutopilotStatusButton />
                        </div>

                        <div className={style.sidebarButton}>
                            <SidebarButton />
                        </div>
                    </div>

                    <div className={style.groups}>
                        {stages.map((stageGroup, index) => (
                            <React.Fragment
                                key={`${stageGroup.name}:${stageGroup.stages.map((item) => item.name).toString()}`}
                            >
                                <StagesGroup {...stageGroup} />

                                {index < stages.length - 1 && <div className={style.separator} />}
                            </React.Fragment>
                        ))}
                    </div>
                </>
            )}

            {preloader && <Preloader />}
        </div>
    );
};

export interface StageGroupProps {
    name: string;
    stages: {
        name: string;
        isActive: boolean;
        isFinished: boolean;
        isDisabled: boolean;
        onClick: () => void;
    }[];
    isActive: boolean;
    isDisabled: boolean;
}

function StagesGroup({ name, stages, isActive, isDisabled }: StageGroupProps) {
    return (
        <div className={classNames(style.group, isActive && style.active, isDisabled && style.disabled)}>
            <div className={style.groupTitle}>{name}</div>

            <div className={style.stages}>
                {stages.map((stage) => (
                    <div
                        key={stage.name}
                        className={classNames(
                            style.stage,
                            stage.isActive && style.activeStage,
                            stage.isFinished && style.finishedStage,
                            stage.isDisabled && style.disabledStage,
                        )}
                        onClick={!stage.isDisabled ? stage.onClick : null}
                    >
                        <div className={style.stageIcon}>
                            <Icon type={IconType.CHECKED_ROUND} svgSize={14} />
                        </div>

                        {stage.name}
                    </div>
                ))}
            </div>
        </div>
    );
}
