import * as React from 'react';
import classNames from 'classnames';
import { ImageGalleryItem } from 'sber-marketing-ui';
import { isEmpty } from 'lodash';

import { FileAsset } from '@store/commonTypes';

import { MediaFilesGallery } from '@common/MediaFilesGallery';
import { FileAssetWithPreview } from '@common/FileAssetWithPreview';
import { Utils } from '@common/Utils';

import { FileAssetListWithGalleryTemplateProps } from './types';

import * as styles from './FileAssetListWithGallery.scss';

/** "FileAssetListWithGallery" template component */
export const FileAssetListWithGalleryTemplate: React.SFC<FileAssetListWithGalleryTemplateProps> = ({
    useR7Controls,
    className,
    canDelete,
    disableDownload,
    isGalleryOpened,
    onRemove,
    onGalleryClick,
    startIndex,
    assets = [],
    onPreviewClick,
}): JSX.Element => {
    const data = mapGalleryItems(assets);
    const items: ImageGalleryItem[] = data.map(([item]) => item);

    return (
        <div
            className={classNames(styles.root, className)}
            {...{
                'qa-id': 'taskCommentFormAssetsList',
            }}
        >
            {assets.map((asset, index) => (
                <FileAssetWithPreview
                    {...asset}
                    useR7Controls={useR7Controls}
                    className={styles.asset}
                    key={asset.name}
                    canDelete={canDelete}
                    disableDownload={disableDownload}
                    onRemove={onRemove}
                    onGalleryClick={() => onPreviewClick(index, items)}
                />
            ))}

            {isGalleryOpened && (
                <MediaFilesGallery startIndex={startIndex} items={items} onOutsideClick={() => onGalleryClick(0)} />
            )}
        </div>
    );
};

FileAssetListWithGalleryTemplate.displayName = 'FileAssetListWithGalleryTemplate';

const mapGalleryItems = (assets: FileAsset[]): [ImageGalleryItem, number][] =>
    assets.map(toTuple).filter(filterItem).map(mapGalleryItem);

const toTuple = (asset: FileAsset, index: number): [FileAsset, number] => [asset, index];

const filterItem = ([{ fullSizeUrl, type }]: [FileAsset, number]): boolean => type !== 'pdf' && !isEmpty(fullSizeUrl);

const mapGalleryItem = ([{ id, previewUrl, fullSizeUrl, originName, type }, index]: [FileAsset, number]): [
    ImageGalleryItem,
    number,
] => [
    {
        id,
        previewUrl,
        originalUrl: fullSizeUrl,
        name: originName,
        type: getGalleryItemType(type),
        extension: type,
    },
    index,
];

const getGalleryItemType = (type: string): 'image' | 'video' | 'audio' => {
    if (Utils.isVideo(type)) {
        return 'video';
    }
    if (Utils.isAudio(type)) {
        return 'audio';
    }
    return 'image';
};
