import * as React from 'react';
import { Skeleton } from 'sber-marketing-ui';

import * as style from './Preloader.scss';

export const Preloader: React.FC = () => {
    return (
        <div className={style.root}>
            <Skeleton customStyle={{ height: 400, width: '100%', borderRadius: 2 }} />
        </div>
    );
};
