import * as React from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { LabeledInput, Icon, IconType } from 'sber-marketing-ui';

import { PersonNotFound } from '../PersonNotFound';
import { PersonData } from '../PersonData';
import * as styles from './UsersSelectSuggest.scss';
import {
    UsersSelectSuggestItem,
    UsersSelectSuggestTemplateProps,
    UsersSelectSuggestTemplatePropsTypes,
    UsersSelectSuggestItemProps,
    UsersSelectSuggestItemPropsTypes,
} from './types';

/** Participators adding form component */
export const UsersSelectSuggestTemplate: React.StatelessComponent<UsersSelectSuggestTemplateProps> = ({
    qaId,
    filteredItems,
    onSuggestInputKeyUp,
    onSuggestInputBlur,
    onSuggestInputFocus,
    onSuggestInputMouseDown,
    onSuggestInputMouseUp,
    onSuggestItemsContainerBlur,
    onSuggestItemsContainerFocus,
    onSuggestItemsContainerMouseDown,
    onSuggestItemsContainerMouseUp,
    onSuggestItemSelect,
    onValueChange,
    selectedValue,
    className,
    title,
    showSuggest,
    disabled,
}: UsersSelectSuggestTemplateProps): JSX.Element => {
    const selected: UsersSelectSuggestItem = isNil(selectedValue)
        ? { id: '', name: '', department: '', isSelected: false }
        : selectedValue;

    return (
        <div className={classNames(styles.root, className)}>
            <div className={styles.input}>
                <LabeledInput
                    qaId={qaId}
                    value={selected.name}
                    onValueChange={(name: string) => onValueChange({ ...selected, name })}
                    onBlur={onSuggestInputBlur}
                    onFocus={onSuggestInputFocus}
                    onMouseDown={onSuggestInputMouseDown}
                    onMouseUp={onSuggestInputMouseUp}
                    onKeyUp={onSuggestInputKeyUp}
                    title={title}
                    disabled={disabled}
                />
            </div>

            <div
                tabIndex={-1}
                onBlur={onSuggestItemsContainerBlur}
                onFocus={onSuggestItemsContainerFocus}
                onMouseDown={onSuggestItemsContainerMouseDown}
                onMouseUp={onSuggestItemsContainerMouseUp}
                className={classNames(styles.suggestItems, !showSuggest && styles.suggestItems_hidden)}
            >
                {filteredItems.length > 0 ? (
                    filteredItems.map((item) => (
                        <UserSelectSuggestItem {...item} key={item.id} onSuggestItemSelect={onSuggestItemSelect} />
                    ))
                ) : (
                    <PersonNotFound className={styles.notFound} />
                )}
            </div>
        </div>
    );
};

UsersSelectSuggestTemplate.propTypes = UsersSelectSuggestTemplatePropsTypes;

const ICON_SIZE = 13;

const UserSelectSuggestItem: React.StatelessComponent<UsersSelectSuggestItemProps> = ({
    onSuggestItemSelect,
    ...user
}: UsersSelectSuggestItemProps): JSX.Element => {
    return (
        <div
            className={classNames(
                styles.suggestItem,
                user.isSelected && styles.suggestItem_selected,
                !user.isActive && styles.disabled,
            )}
            onClick={user.isActive ? () => onSuggestItemSelect(user) : null}
            {...{
                'qa-id': 'userSelectSuggestItem',
                'qa-label': user.name,
            }}
        >
            <PersonData
                name={user.isActive ? user.name : `${user.name} (Заблокирован)`}
                department={user.department}
                className={styles.suggestItem_person}
            />

            {user.isActive && (
                <React.Fragment>
                    <div className={styles.suggestItem_addIcon}>
                        <Icon type={IconType.TASK_PARTICIPATOR_ADD} color="green" svgSize={ICON_SIZE} />
                    </div>

                    <div className={styles.suggestItem_addedIcon}>
                        <Icon type={IconType.TASK_PARTICIPATOR_ADDED} color="green" svgSize={ICON_SIZE} />
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

UserSelectSuggestItem.propTypes = UsersSelectSuggestItemPropsTypes;
