// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-components-ActivityFavorite-_ActivityFavorite__root{font-family:'Open Sans', sans-serif;background:none;padding:0;border:none;line-height:inherit;float:left;-webkit-font-smoothing:inherit;-moz-osx-font-smoothing:inherit;-webkit-appearance:none}.src-client-modules-activity-components-ActivityFavorite-_ActivityFavorite__skeleton{width:20px;height:20px}.src-client-modules-activity-components-ActivityFavorite-_ActivityFavorite__icon{color:transparent;cursor:pointer;float:left}.src-client-modules-activity-components-ActivityFavorite-_ActivityFavorite__active{color:#f5a623}.src-client-modules-activity-components-ActivityFavorite-_ActivityFavorite__loading{opacity:.3;filter:grayscale(100%);cursor:default}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/components/ActivityFavorite/ActivityFavorite.scss"],"names":[],"mappings":"AAAA,iFACE,mCAAoC,CACpC,eAAgB,CAChB,SAAU,CACV,WAAY,CACZ,mBAAoB,CACpB,UAAW,CAGX,8BAA+B,CAC/B,+BAAgC,CAGhC,uBAAwB,CACzB,qFAGC,UAAW,CACX,WAAY,CACb,iFAGC,iBAAkB,CAClB,cAAe,CACf,UAAW,CACZ,mFAGC,aAAc,CACf,oFAOC,UAAW,CACX,sBAAuB,CACvB,cAAe","sourcesContent":[".root {\n  font-family: 'Open Sans', sans-serif;\n  background: none;\n  padding: 0;\n  border: none;\n  line-height: inherit;\n  float: left;\n\n  /* Corrects font smoothing for webkit */\n  -webkit-font-smoothing: inherit;\n  -moz-osx-font-smoothing: inherit;\n\n  /* Corrects inability to style clickable `input` types in iOS */\n  -webkit-appearance: none;\n}\n\n.skeleton {\n  width: 20px;\n  height: 20px;\n}\n\n.icon {\n  color: transparent;\n  cursor: pointer;\n  float: left;\n}\n\n.active {\n  color: #f5a623;\n}\n\n.normal {\n\n}\n\n.loading {\n  opacity: .3;\n  filter: grayscale(100%);\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-components-ActivityFavorite-_ActivityFavorite__root",
	"skeleton": "src-client-modules-activity-components-ActivityFavorite-_ActivityFavorite__skeleton",
	"icon": "src-client-modules-activity-components-ActivityFavorite-_ActivityFavorite__icon",
	"active": "src-client-modules-activity-components-ActivityFavorite-_ActivityFavorite__active",
	"loading": "src-client-modules-activity-components-ActivityFavorite-_ActivityFavorite__loading"
};
export default ___CSS_LOADER_EXPORT___;
