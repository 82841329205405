import * as React from 'react';
import autobind from 'autobind-decorator';

import { ChildrenProps } from './SAPCommentInputConnected';
import { SAPCommentInputTemplate } from './SAPCommentInputTemplate';

interface Props extends ChildrenProps {
    canEdit: boolean;
    serialNumber: number;
    expertId?: number;
    isRequestApproved: boolean;
}

interface State {
    isBudgetExpertsPopupVisible: boolean;
    sapComment: string;
    isInputActive: boolean;
}

export class SAPCommentInputContainer extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            sapComment: props.sapComment,
            isBudgetExpertsPopupVisible: false,
            isInputActive: false,
        };
    }

    public componentDidUpdate(prevProps: Props): void {
        if (this.props.sapComment !== prevProps.sapComment) {
            this.setState({
                sapComment: this.props.sapComment,
            });
        }
    }

    public render(): JSX.Element {
        const { canEdit, correctionsSAPComment, isRequestApproved } = this.props;

        return (
            <SAPCommentInputTemplate
                {...this.state}
                canEdit={canEdit}
                correctionsSAPComment={correctionsSAPComment}
                isRequestApproved={isRequestApproved}
                onEditButtonClick={this.onEditButtonClick}
                onSaveButtonClick={this.onSaveButtonClick}
                onInputChange={this.onInputChange}
                onModalClick={this.onModalClick}
                onBudgetExprtsPopupCloseButtonClick={this.onBudgetExprtsPopupCloseButtonClick}
                onBudgetExpertsPopupSubmitButtonClick={this.onBudgetExpertsPopupSubmitButtonClick}
                onRootClick={this.onRootClick}
            />
        );
    }

    @autobind
    private onEditButtonClick(event: React.MouseEvent): void {
        event.stopPropagation();

        this.setState({
            isInputActive: true,
        });
    }

    @autobind
    private async onSaveButtonClick(): Promise<void> {
        this.setState(
            {
                isInputActive: false,
            },
            this.initSAPCommentSaving,
        );
    }

    @autobind
    private onInputChange(sapComment: string): void {
        this.setState({
            sapComment,
        });
    }

    @autobind
    private onModalClick(event: React.MouseEvent): void {
        event.stopPropagation();

        this.setState({
            isInputActive: false,
            sapComment: this.props.sapComment,
        });
    }

    @autobind
    private onBudgetExprtsPopupCloseButtonClick(): void {
        this.setState({
            isBudgetExpertsPopupVisible: false,
        });
    }

    @autobind
    private onBudgetExpertsPopupSubmitButtonClick(expertId: number): void {
        this.setState(
            {
                isBudgetExpertsPopupVisible: false,
            },
            () => this.saveSAPComment(expertId),
        );
    }

    @autobind
    private initSAPCommentSaving(): void {
        if (this.props.sapComment !== this.state.sapComment) {
            if (!this.props.expertId) {
                this.setState({
                    isBudgetExpertsPopupVisible: true,
                });
            } else {
                this.saveSAPComment(this.props.expertId);
            }
        }
    }

    @autobind
    private saveSAPComment(expertId: number): void {
        this.props.saveSAPComment(this.state.sapComment, expertId);
    }

    @autobind
    private onRootClick(event: React.MouseEvent): void {
        if (this.state.isInputActive) {
            event.stopPropagation();
        }
    }
}
