import * as React from 'react';

import { OrderType } from '@store/budgetPlanning/types';

import { Sorting, SortingMenu as SortingMenuCommon } from '@budgetCommon';

import * as style from './SortingMenu.scss';
interface Props {
    sortingOrder: OrderType;
    rootRef: (element: HTMLDivElement) => void;
    onSortingChange: (sorting: Sorting) => void;
}

export const SortingMenu = ({ sortingOrder, onSortingChange, rootRef }: Props): JSX.Element => {
    let sorting: Sorting;
    switch (sortingOrder) {
        case OrderType.Asc:
            sorting = Sorting.Asc;
            break;
        case OrderType.Desc:
            sorting = Sorting.Desc;
            break;
        default:
            sorting = Sorting.None;
            break;
    }

    return (
        <div className={style.root} ref={rootRef} tabIndex={-1}>
            <SortingMenuCommon sorting={sorting} onSortingChange={onSortingChange} />
        </div>
    );
};
