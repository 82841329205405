// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-main-EmojiPicker-_EmojiPicker__root aside{--epr-picker-border-color: transparent}.src-client-modules-common-components-main-EmojiPicker-_EmojiPicker__dropdown{padding:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/main/EmojiPicker/EmojiPicker.scss"],"names":[],"mappings":"AAAA,gFACE,sCAA0B,CAC3B,8EAGC,SAAU","sourcesContent":[".root aside {\n  --epr-picker-border-color: transparent;\n}\n\n.dropdown {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-components-main-EmojiPicker-_EmojiPicker__root",
	"dropdown": "src-client-modules-common-components-main-EmojiPicker-_EmojiPicker__dropdown"
};
export default ___CSS_LOADER_EXPORT___;
