import * as React from 'react';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

import { InputBase, InputBaseOnChangeHandler, InputBaseProps, RenderInput } from '@common/components';
import { useDefaultRef, useDefaultState } from '@common/hooks';

import * as styles from './Textarea.scss';

export type TextareaResize = boolean | 'auto' | 'vertical' | 'horizontal' | 'both' | 'none';

export type TextareaOnChangeHandler = InputBaseOnChangeHandler<string, HTMLTextAreaElement>;

export interface TextareaProps extends Partial<InputBaseProps<string, HTMLTextAreaElement>> {
    resize?: TextareaResize;
    maxRows?: number;
    minRows?: number;
    onHeightChange?: (height: number) => void;
}

export function Textarea({
    resize = 'auto',
    inputRef: inputRefProp,
    minRows = 3,
    maxRows,
    style,
    inputClassName,
    className,
    value: valueProp,
    onChange,
    onHeightChange,
    ...props
}: TextareaProps) {
    const [value, setValue] = useDefaultState(valueProp, onChange, '');
    const inputRef = useDefaultRef(inputRefProp);

    const newStyle = {
        ...style,
        '--ui-textarea-resize': !resize || resize === 'auto' ? undefined : resize === true ? 'both' : resize,
        '--ui-textarea-min-height': `${minRows * 16}px`,
        '--ui-textarea-max-height': maxRows ? `${maxRows * 16}px` : undefined,
    } as React.CSSProperties;

    const handleChange: typeof onChange = (value, e) => {
        setValue(value, e);
        onChange?.(value, e);
    };

    const renderInput: RenderInput<string, HTMLTextAreaElement> = (props) =>
        resize === 'auto' ? (
            <TextareaAutosize {...(props as any)} maxRows={maxRows} minRows={minRows} onHeightChange={onHeightChange} />
        ) : (
            <textarea {...props} />
        );

    return (
        <InputBase<string, HTMLTextAreaElement>
            {...props}
            value={value}
            className={classNames(styles.root, className)}
            inputClassName={classNames(styles.input, inputClassName)}
            onChange={handleChange}
            inputRef={inputRef}
            style={newStyle}
            renderInput={renderInput}
        />
    );
}
