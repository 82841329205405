import * as React from 'react';

import type { WorkTypeParams } from 'sber-marketing-types/frontend';

import { OptionMultiple, OptionProps, SearchOptionProps, SearchOptions, titlesOptionLoader } from '@common/components';
import { UseWorkTypeOptions, useWorkTypeOptions } from '@modules/workType/hooks';

export type IWorkTypeOptionsProps<M extends OptionMultiple> = Omit<
    SearchOptionProps<M, OptionProps<WorkTypeParams>, WorkTypeParams, 'id', string, M extends true ? string[] : string>,
    'options' | 'optionId'
>;

export interface WorkTypeOptionsProps<M extends OptionMultiple> extends IWorkTypeOptionsProps<M>, UseWorkTypeOptions {}

export function WorkTypeOptions<M extends OptionMultiple>({
    ids,
    sortingOrder,
    embed,
    organizationId,
    departmentIds,
    isArchived,
    enable_rates,
    userId,
    optionsLoading,
    useUsers,
    ...props
}: WorkTypeOptionsProps<M>) {
    const params: Required<UseWorkTypeOptions> = {
        ids,
        sortingOrder,
        embed,
        organizationId,
        isArchived,
        enable_rates,
        departmentIds,
        userId,
        useUsers,
    };
    const [options, isLoading] = useWorkTypeOptions(params);

    return (
        <SearchOptions
            {...props}
            optionsLoading={isLoading || optionsLoading}
            optionId="id"
            optionsLoader={titlesOptionLoader}
            options={options}
        />
    );
}
