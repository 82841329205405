import * as React from 'react';
import classNames from 'classnames';

import { TaskBudgetApprovalForm } from 'sber-marketing-types/backend';

import { useGetBudgetQuery, useGetDictionaryItemQuery, useGetTaskQuery } from '@api';

import { Details, DetailsProps, Flex, Tag } from '@common/components';

import * as styles from './TaskBudgetDetails.scss';
import { mediaRequestOptions } from '@modules/task/constants';

export interface TaskBudgetDetailsProps extends DetailsProps {
    taskId: string;
    organizationId: string;
}

const fields: Partial<Record<keyof TaskBudgetApprovalForm, string>> = {
    clientDivisionId: 'Заказчик',
    clientName: 'Представитель заказчика',
    budgetItemId: 'ID-источник',
    productId: 'Продукт',
    segmentId: 'Сегмент',
    period: 'Период РК',
    mediaRequest: 'Лот креатива',
    naming: 'Название креатива',
    project: 'РК на основании',
};

const fieldKeys = Object.keys(fields);

export function TaskBudgetDetails({ taskId, organizationId, className, ...props }: TaskBudgetDetailsProps) {
    const { data: { task } = {}, isLoading: taskLoading } = useGetTaskQuery({ id: taskId });
    const budgetApproval = task?.budgetApproval;
    const budgetKeys = budgetApproval && Object.keys(budgetApproval);
    const clientDivisionId = budgetApproval?.clientDivisionId;
    const productId = budgetApproval?.productId;
    const segmentId = budgetApproval?.segmentId;
    const mediaRequest = budgetApproval?.mediaRequest;
    const budgetId = budgetApproval?.budgetItemId;

    const { data: clientDivision, isLoading: divisionLoading } = useGetDictionaryItemQuery(clientDivisionId, {
        skip: !clientDivisionId,
    });
    const { data: product, isLoading: productLoading } = useGetDictionaryItemQuery(productId, { skip: !productId });
    const { data: segment, isLoading: segmentLoading } = useGetDictionaryItemQuery(segmentId, { skip: !segmentId });
    const { data: budget, isLoading: budgetLoading } = useGetBudgetQuery(budgetId, { skip: !budgetId });

    const isLoading = taskLoading || divisionLoading || productLoading || segmentLoading || budgetLoading;

    const values: Partial<Record<keyof TaskBudgetApprovalForm, string>> = {
        clientDivisionId: clientDivision?.value,
        productId: product?.value,
        segmentId: segment?.value,
        mediaRequest: mediaRequestOptions.find(({ value }) => value === mediaRequest)?.title,
        budgetItemId: budget && `${budget.serialNumber} (${budget.activity.name})`,
    };

    return (
        <>
            {!budgetKeys?.length || (
                <Details {...props} className={classNames(styles.root, className)} summary="Согласование ID бюджета">
                    <Tag loading={isLoading} gap={16} vertical type="soft" align="flex-start" flat>
                        {fieldKeys
                            .filter((key) => budgetApproval[key])
                            .map((key) => (
                                <Flex key={key} gap={8} vertical>
                                    <span className={styles.label}>{fields[key]}</span>
                                    <span>{values[key] ?? budgetApproval[key]}</span>
                                </Flex>
                            ))}
                    </Tag>
                </Details>
            )}
        </>
    );
}
