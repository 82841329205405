import { ComponentType, createElement } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { omit } from 'lodash';

import { WithAppUsersMappedActions, WithAppUsersMappedState, PropsWithAppUsers } from './types';
import { getLoadingStatus, getError, getAllUsers, loadUsers } from '../../../store/appUsers';
import { StoreState } from '../../../store';
import { AppUsersWrapper } from './AppUsersWrapper';

const mapStateToProps = (state: StoreState): WithAppUsersMappedState => ({
    appUsersLoadingStatus: getLoadingStatus(state),
    appUsersError: getError(state),
    appUsers: getAllUsers(state),
});

const mapDispatchToProps = (dispatch: Dispatch<StoreState>): WithAppUsersMappedActions => ({
    loadAppUsers: () => dispatch(loadUsers(null)),
});

const withStore = connect<WithAppUsersMappedState, WithAppUsersMappedActions, any, StoreState>(
    mapStateToProps,
    mapDispatchToProps,
);

/** High-Order Component (HOC) which automatically loads all application users short data and provides them */
export function withAppUsers<P extends {} = {}>(Component: ComponentType<PropsWithAppUsers<P>>): ComponentType<P> {
    const Wrapper = class extends AppUsersWrapper<P> {
        public static displayName = `withAppUsers(${Component.displayName || Component.name || 'Unknown'})`;
        public render() {
            return createElement(
                Component,
                omit(this.props, ['children']) as PropsWithAppUsers<P>,
                this.props.children,
            );
        }
    };
    return withStore(Wrapper as any);
}
