import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StoreState } from '@store';
import { loadFeatures } from '@store/autopilot/generationTextForm';
import { getAutopilot } from '@store/autopilot/selectors';

import * as styles from './styles.scss';

interface Props {}

export const Recommendation: React.FC<Props> = ({}) => {
    const dispatch = useDispatch();

    const {
        id: autopilotId,
        brief: { product: productId },
    } = useSelector((state: StoreState) => getAutopilot(state));

    const onClickButton = React.useCallback(() => {
        dispatch(loadFeatures({ autopilotId, productId }));
    }, []);

    return (
        <div className={styles.root}>
            <div className={styles.text}>Рекомендуемые особенности</div>
            <div className={styles.button} onClick={onClickButton}>
                Выбрать
            </div>
        </div>
    );
};
