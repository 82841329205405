// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-ChannelButtonsList-_ChannelButtonsList__root{display:flex;width:100%;align-items:flex-start;flex-wrap:wrap}.src-client-modules-activity-pages-task-ChannelButtonsList-_ChannelButtonsList__item{display:inline-flex;margin-right:24px}.src-client-modules-activity-pages-task-ChannelButtonsList-_ChannelButtonsList__item:last-child{margin-right:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/ChannelButtonsList/ChannelButtonsList.scss"],"names":[],"mappings":"AAAA,qFACI,YAAa,CAEb,UAAW,CAEX,sBAAuB,CACvB,cAAe,CAClB,qFAGG,mBAAoB,CAEpB,iBAAkB,CACrB,gGAGG,cAAe","sourcesContent":[".root {\n    display: flex;\n\n    width: 100%;\n\n    align-items: flex-start;\n    flex-wrap: wrap;\n}\n\n.item {\n    display: inline-flex;\n\n    margin-right: 24px;\n}\n\n.item:last-child {\n    margin-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-ChannelButtonsList-_ChannelButtonsList__root",
	"item": "src-client-modules-activity-pages-task-ChannelButtonsList-_ChannelButtonsList__item"
};
export default ___CSS_LOADER_EXPORT___;
