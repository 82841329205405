// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-Card-CardContent-_CardContent__root{display:flex}.src-client-modules-common-Card-CardContent-_CardContent__leftSidebar{width:200px;margin-right:20px;flex-grow:1;flex-shrink:1}.src-client-modules-common-Card-CardContent-_CardContent__rightSidebar{display:flex;flex-direction:column;width:200px;align-items:flex-end;flex-grow:0;flex-shrink:0}.src-client-modules-common-Card-CardContent-_CardContent__rightSidebarItemWrapper{display:flex;width:100%;margin-bottom:2px;justify-content:flex-end}.src-client-modules-common-Card-CardContent-_CardContent__rightSidebarItemWrapper:last-child{margin-bottom:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/Card/CardContent/CardContent.scss"],"names":[],"mappings":"AAAA,+DACI,YAAa,CAChB,sEAGG,WAAY,CACZ,iBAAkB,CAElB,WAAY,CACZ,aAAc,CACjB,uEAGG,YAAa,CACb,qBAAsB,CAEtB,WAAY,CAEZ,oBAAqB,CAErB,WAAY,CACZ,aAAc,CACjB,kFAGG,YAAa,CAEb,UAAW,CACX,iBAAkB,CAElB,wBAAyB,CAN7B,6FASQ,eAAgB","sourcesContent":[".root {\n    display: flex;\n}\n\n.leftSidebar {\n    width: 200px;\n    margin-right: 20px;\n\n    flex-grow: 1;\n    flex-shrink: 1;\n}\n\n.rightSidebar {\n    display: flex;\n    flex-direction: column;\n\n    width: 200px;\n\n    align-items: flex-end;\n\n    flex-grow: 0;\n    flex-shrink: 0;\n}\n\n.rightSidebarItemWrapper {\n    display: flex;\n\n    width: 100%;\n    margin-bottom: 2px;\n\n    justify-content: flex-end;\n\n    &:last-child {\n        margin-bottom: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-Card-CardContent-_CardContent__root",
	"leftSidebar": "src-client-modules-common-Card-CardContent-_CardContent__leftSidebar",
	"rightSidebar": "src-client-modules-common-Card-CardContent-_CardContent__rightSidebar",
	"rightSidebarItemWrapper": "src-client-modules-common-Card-CardContent-_CardContent__rightSidebarItemWrapper"
};
export default ___CSS_LOADER_EXPORT___;
