import * as React from 'react';

import { HeaderView } from '@common/Page';

import { DashboardPageContainer } from './DashboardPageContainer';
import { WithCardsQuery } from './WithCardsQuery';
import { WithStore } from './WithStore';
import { WithPageModeCheck } from './WithPageModeCheck';

interface Props {
    setHeaderView?: (view: HeaderView) => void;
    scrollPageToTop: () => void;
}

export function DashboardPageConnected({ setHeaderView, scrollPageToTop }: Props): JSX.Element {
    return (
        <WithStore>
            {({
                user,
                userRoleFilter,
                cardsLoadingModeFilter,
                requestIdForStatusCheck,
                preloader,
                setCardsLoadingModeFilter,
                setSelectedCard,
                setRequestIdForStatusCheck,
                loadUserConfig,
                resetPageStore,
                loadPageData,
            }) => (
                <WithCardsQuery
                    userId={user.attributes.id}
                    userRoleFilter={userRoleFilter}
                    cardsLoadingModeFilter={cardsLoadingModeFilter}
                    scrollPageToTop={scrollPageToTop}
                >
                    {({ cards, loading, totalCardsCount, fetchMore, update }) => (
                        <WithPageModeCheck
                            requestId={requestIdForStatusCheck}
                            setRequestIdForStatusCheck={setRequestIdForStatusCheck}
                            setSelectedCard={setSelectedCard}
                            setCardsLoadingModeFilter={setCardsLoadingModeFilter}
                        >
                            {() => (
                                <DashboardPageContainer
                                    preloader={preloader}
                                    cards={cards}
                                    user={user}
                                    isLoadingCards={loading}
                                    totalCardsCount={totalCardsCount}
                                    setHeaderView={setHeaderView}
                                    updateCards={update}
                                    fetchMore={fetchMore}
                                    loadUserConfig={loadUserConfig}
                                    resetPageStore={resetPageStore}
                                    loadPageData={loadPageData}
                                />
                            )}
                        </WithPageModeCheck>
                    )}
                </WithCardsQuery>
            )}
        </WithStore>
    );
}
