import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconType, WithTooltip } from 'sber-marketing-ui';

import * as style from './CardButtonsGroup.scss';

interface Props {
    qaId: string;
    onClick: React.MouseEventHandler<HTMLDivElement>;
}

export const DeleteButton: React.FC<Props> = ({ onClick, qaId }) => (
    <WithTooltip content="Удалить">
        <div
            className={classNames(style.root, style.deleteButton)}
            onClick={onClick}
            {...{
                'qa-id': qaId,
            }}
        >
            <Icon type={IconType.TRASH_CAN} svgSize={17} />
        </div>
    </WithTooltip>
);

export const EditButton: React.FC<Props> = ({ onClick, qaId }) => (
    <WithTooltip content="Редактировать">
        <div
            className={classNames(style.root, style.editButton)}
            onClick={onClick}
            {...{
                'qa-id': qaId,
            }}
        >
            <Icon type={IconType.NOTEPAD} svgSize={19} />
        </div>
    </WithTooltip>
);

export const ShareButton: React.FC<Props> = ({ onClick, qaId }) => (
    <WithTooltip content="Поделиться">
        <div
            className={classNames(style.root, style.shareButton)}
            onClick={onClick}
            {...{
                'qa-id': qaId,
            }}
        >
            <Icon type={IconType.LINK_FOR_SHARING} svgSize={15} />
        </div>
    </WithTooltip>
);
