// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-CampaignPerformanceTable-CellTypes-CellEditorWrapper-_CellEditorWrapper__root{position:absolute;top:0;bottom:0;left:0;right:0;pointer-events:none}.src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-CampaignPerformanceTable-CellTypes-CellEditorWrapper-_CellEditorWrapper__wrapper{position:absolute;z-index:20;background:#ffffff;box-shadow:0px 2px 10px rgba(0,0,0,0.1);border-radius:4px;pointer-events:auto}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilotTv/StageWidgets/MediaplanStageWidget/CampaignPerformanceTable/CellTypes/CellEditorWrapper/CellEditorWrapper.scss"],"names":[],"mappings":"AAAA,+JACI,iBAAkB,CAClB,KAAM,CACN,QAAS,CACT,MAAO,CACP,OAAQ,CAER,mBAAoB,CACvB,kKAGG,iBAAkB,CAClB,UAAW,CACX,kBAAmB,CACnB,uCAA2C,CAC3C,iBAAkB,CAElB,mBAAoB","sourcesContent":[".root {\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n\n    pointer-events: none;\n}\n\n.wrapper {\n    position: absolute;\n    z-index: 20;\n    background: #ffffff;\n    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);\n    border-radius: 4px;\n\n    pointer-events: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-CampaignPerformanceTable-CellTypes-CellEditorWrapper-_CellEditorWrapper__root",
	"wrapper": "src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-CampaignPerformanceTable-CellTypes-CellEditorWrapper-_CellEditorWrapper__wrapper"
};
export default ___CSS_LOADER_EXPORT___;
