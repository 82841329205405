import * as React from 'react';
import * as lodash from 'lodash';
import classNames from 'classnames';

import * as style from './StrategyTable.scss';

import { AutopilotRadioBriefStrategy } from 'sber-marketing-types/backend';

const HEADERS = ['День', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];

const WEEKEND_INDEXES = [6, 7];

const PERIODS = lodash
    .times(18)
    .map((index) => `${index < 5 ? '0' : ''}${index + 5}:00-${index < 4 ? '0' : ''}${index + 6}:00`);

const SELECTED_CELLS_BY_STRATEGY = {
    ['DRIVE_TIME']: {
        columnIndexes: lodash.range(1, 8),
        periodIndexes: [...lodash.range(2, 6), ...lodash.range(13, 16)],
    },
    ['WEEKEND']: {
        columnIndexes: lodash.range(6, 8),
        periodIndexes: lodash.range(4, 16),
    },
    ['REACH']: {
        columnIndexes: lodash.range(1, 8),
        periodIndexes: lodash.range(2, 16),
    },
};

interface Props {
    selectedStrategy: AutopilotRadioBriefStrategy;
}

export function StrategyTable({ selectedStrategy }: Props): JSX.Element {
    const selectedSells = SELECTED_CELLS_BY_STRATEGY[selectedStrategy];

    return (
        <table className={style.root}>
            <thead className={style.header}>
                <tr>
                    {HEADERS.map((item) => (
                        <th key={item}>{item}</th>
                    ))}
                </tr>
            </thead>

            <tbody className={style.body}>
                {PERIODS.map((period, periodIndex) => (
                    <tr key={period}>
                        {HEADERS.map((column, columnIndex) => (
                            <td
                                key={column}
                                className={classNames(
                                    WEEKEND_INDEXES.includes(columnIndex) && style.weekend,
                                    selectedSells.columnIndexes.includes(columnIndex) &&
                                        selectedSells.periodIndexes.includes(periodIndex) &&
                                        style.selected,
                                )}
                            >
                                {columnIndex === 0 && period}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
