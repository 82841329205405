// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-ActivityReferenceMenu-BudgetItemSelect-_BudgetItemSelect__caption{margin-bottom:6px;user-select:none;letter-spacing:0.5px;text-transform:uppercase;color:#7e8681;font-size:14px;line-height:19px}.src-client-modules-budget-BudgetPage-BudgetExecution-ActivityReferenceMenu-BudgetItemSelect-_BudgetItemSelect__budgetItemSelect{margin-top:16px}.src-client-modules-budget-BudgetPage-BudgetExecution-ActivityReferenceMenu-BudgetItemSelect-_BudgetItemSelect__serialNumbersList{margin-top:12px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/ActivityReferenceMenu/CommonStyles.scss","webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/ActivityReferenceMenu/BudgetItemSelect/BudgetItemSelect.scss"],"names":[],"mappings":"AAAA,wHACI,iBAAkB,CAElB,gBAAiB,CACjB,oBAAqB,CACrB,wBAAyB,CAEzB,aAAc,CAEd,cAAe,CACf,gBAAiB,CCRrB,iIACI,eAAgB,CACnB,kIAGG,eAAgB","sourcesContent":[".caption {\n    margin-bottom: 6px;\n\n    user-select: none;\n    letter-spacing: 0.5px;\n    text-transform: uppercase;\n\n    color: #7e8681;\n\n    font-size: 14px;\n    line-height: 19px;\n}\n","@import '../CommonStyles.scss';\n\n.budgetItemSelect {\n    margin-top: 16px;\n}\n\n.serialNumbersList {\n    margin-top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"caption": "src-client-modules-budget-BudgetPage-BudgetExecution-ActivityReferenceMenu-BudgetItemSelect-_BudgetItemSelect__caption",
	"budgetItemSelect": "src-client-modules-budget-BudgetPage-BudgetExecution-ActivityReferenceMenu-BudgetItemSelect-_BudgetItemSelect__budgetItemSelect",
	"serialNumbersList": "src-client-modules-budget-BudgetPage-BudgetExecution-ActivityReferenceMenu-BudgetItemSelect-_BudgetItemSelect__serialNumbersList"
};
export default ___CSS_LOADER_EXPORT___;
