import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { VideoPreviewsState as State, SetItemPayload } from './types';

import * as actions from './actions/sync';

class Reducer {
    public static makeInitialState(): State {
        return {
            byItemId: {},
            byContainerId: {},
        };
    }

    public static setItem(state: State, payload: SetItemPayload): State {
        const { containerId, itemId, item } = payload;

        const byItemId = {
            ...state.byItemId,
            [itemId]: item,
        };

        const byContainerId = {
            ...state.byContainerId,
            [containerId]: state.byContainerId[containerId] ? [...state.byContainerId[containerId], item] : [item],
        };

        return { byItemId, byContainerId };
    }
}

export const videoPreviewsReducer = reducerWithInitialState(Reducer.makeInitialState());

videoPreviewsReducer.case(actions.setItem, Reducer.setItem);
