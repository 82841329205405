// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-SearchMenu-Results-_Results__root{height:calc(100% - 60px)}.src-client-modules-common-SearchMenu-Results-_Results__content{padding:0 18px 6px 3px}.src-client-modules-common-SearchMenu-Results-_Results__totalCount{letter-spacing:0.25px;color:#000000;font-size:16px;font-weight:600}.src-client-modules-common-SearchMenu-Results-_Results__group{margin-top:24px}.src-client-modules-common-SearchMenu-Results-_Results__group_hidden{opacity:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/SearchMenu/Results/Results.scss"],"names":[],"mappings":"AAAA,6DACI,wBAAyB,CAC5B,gEAGG,sBAAuB,CAC1B,mEAGG,qBAAsB,CAEtB,aAAc,CAEd,cAAe,CACf,eAAgB,CACnB,8DAGG,eAAgB,CACnB,qEAGG,SAAU","sourcesContent":[".root {\n    height: calc(100% - 60px);\n}\n\n.content {\n    padding: 0 18px 6px 3px;\n}\n\n.totalCount {\n    letter-spacing: 0.25px;\n\n    color: #000000;\n\n    font-size: 16px;\n    font-weight: 600;\n}\n\n.group {\n    margin-top: 24px;\n}\n\n.group_hidden {\n    opacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-SearchMenu-Results-_Results__root",
	"content": "src-client-modules-common-SearchMenu-Results-_Results__content",
	"totalCount": "src-client-modules-common-SearchMenu-Results-_Results__totalCount",
	"group": "src-client-modules-common-SearchMenu-Results-_Results__group",
	"group_hidden": "src-client-modules-common-SearchMenu-Results-_Results__group_hidden"
};
export default ___CSS_LOADER_EXPORT___;
