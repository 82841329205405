import { Observer } from './';

export class Scroller extends Observer<number> {
    private static instance: Scroller;

    get currentScroll(): number {
        return this.currentValue || 0;
    }

    public static getInstance(): Scroller {
        if (!Scroller.instance) {
            Scroller.instance = new Scroller();
        }
        return Scroller.instance;
    }

    public dispose() {
        Scroller.instance = null;

        this.currentValue = null;
        this.registrationList = [];
    }

    public setScroll(id: string, scrollValue: number) {
        this.setValue(id, scrollValue);
    }
}
