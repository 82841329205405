import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { OrganizationView } from 'sber-marketing-types/backend';

import { OrganizationApi } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';

import * as actions from './actions';
import { LoadOrganizationsParams, StoreTypes } from './types';
import { getCommonLoadingStatus, getLoadingStatus } from './selectors';

export const loadOrganizations = bindThunkAction<StoreState, LoadOrganizationsParams, void, Error>(
    actions.loadOrganizationsAsync,
    async (params, dispatch, getState) => {
        const { store, ids } = params;

        function setLoadingStatus(stores: StoreTypes[], status: LoadingStatus): void {
            stores.forEach((store) =>
                dispatch(
                    actions.setLoadingStatus({
                        store,
                        status,
                    }),
                ),
            );

            dispatch(actions.setCommonLoadingStatus(status));
        }

        try {
            dispatch(
                actions.setStoreIds({
                    store,
                    ids,
                }),
            );

            const commonLoadingStatus = getCommonLoadingStatus(getState());

            if (commonLoadingStatus === LoadingStatus.NOT_LOADED) {
                setLoadingStatus([store], LoadingStatus.LOADING);
                const organizations: OrganizationView[] = await OrganizationApi.getOrganization();
                dispatch(actions.loadEntities(organizations));

                const state = getState();
                const loadingStores = [
                    StoreTypes.ACTIVITY_PAGE_FILTERS,
                    StoreTypes.AVAILABLE_ACTIVITIES_FILTERS,
                    StoreTypes.MY_ACTIVITIES_FILTER,
                    StoreTypes.MY_TASKS_FILTER,
                ].filter((store) => getLoadingStatus(state, store) === LoadingStatus.LOADING);

                setLoadingStatus(loadingStores, LoadingStatus.LOADED);
            } else if (commonLoadingStatus === LoadingStatus.LOADING) {
                setLoadingStatus([store], LoadingStatus.LOADING);
            } else {
                setLoadingStatus([store], LoadingStatus.LOADED);
            }
        } catch (e) {
            setLoadingStatus([store], LoadingStatus.ERROR);
        }
    },
);
