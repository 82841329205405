import * as React from 'react';

import { TitleWithCountIndicator } from '@common/TitleWithCountIndicator';
import { CommentsList, CommentProps } from './CommentsList';

import { CommentInput } from './CommentInput';

import * as styles from './Comments.scss';

interface Props {
    loading: boolean;
    comments: CommentProps[];
    pendingComments: CommentProps[];
    enableControls: boolean;
    createComment: (text: string, files: File[]) => Promise<void>;
}

export function CommentsTemplate({
    loading,
    comments,
    pendingComments,
    enableControls,
    createComment,
}: Props): JSX.Element {
    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'dashboardRightSidebarComments',
            }}
        >
            <div className={styles.header}>
                <TitleWithCountIndicator title={'Комментарии'} count={comments.length} zeroCountLablel={'0'} />
            </div>

            {enableControls && <CommentInput createComment={createComment} />}

            <CommentsList isLoading={loading} comments={comments} pendingComments={pendingComments} />
        </div>
    );
}
