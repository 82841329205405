import * as React from 'react';
import classNames from 'classnames';

import * as style from './TotalLine.scss';

import { Icon, IconType } from 'sber-marketing-ui';

import { MoneyFormatter, Money } from '@common/Utils';

interface Props {
    canEdit: boolean;
    width: number;
    value: number;
    color: string;
    activityName: string;
    userCanEditOrCreateBudgetData: boolean;
    contentRef: (element: HTMLDivElement) => void;
    onEditActivityButtonClick: () => void;
    onCopyActivityButtonClick: () => void;
}

export const TotalLine = ({
    canEdit,
    width,
    value,
    color,
    activityName,
    userCanEditOrCreateBudgetData,
    contentRef,
    onEditActivityButtonClick,
    onCopyActivityButtonClick,
}: Props): JSX.Element => {
    return (
        <div className={style.root} style={{ backgroundColor: `rgb(${color})` }}>
            <div className={style.content} style={{ width }} ref={contentRef}>
                {userCanEditOrCreateBudgetData && (
                    <React.Fragment>
                        {canEdit && (
                            <div
                                className={classNames(style.editButton, style.contentItem)}
                                onClick={onEditActivityButtonClick}
                                {...{
                                    'qa-id': 'totalLineEditActivityButton',
                                }}
                            >
                                <div className={style.editIcon}>
                                    <Icon type={IconType.WRITING} svgSize={18} />
                                </div>

                                <div className={style.editTitle}>Редактирование активности</div>
                            </div>
                        )}

                        <div
                            className={classNames(style.copyButton, style.contentItem)}
                            onClick={onCopyActivityButtonClick}
                            {...{
                                'qa-id': 'totalLineCopyActivityButton',
                            }}
                        >
                            <div className={style.copyIcon}>
                                <Icon type={IconType.COPY} svgSize={18} />
                            </div>

                            <div className={style.copyIconHover}>
                                <Icon type={IconType.COPY_HOVER} svgSize={18} />
                            </div>

                            <div className={style.editTitle}>Копирование активности</div>
                        </div>
                    </React.Fragment>
                )}

                <div className={classNames(style.budgetAmount, style.contentItem)}>
                    Бюджет: <b>{MoneyFormatter.toThousands(Money.fromCopecks(value))}</b>
                </div>

                <div className={classNames(style.activityName, style.contentItem)} title={activityName}>
                    {activityName}
                </div>
            </div>
        </div>
    );
};
