import * as React from 'react';
import { AnyAction, Dispatch } from 'redux';
import { v4 } from 'uuid';
import { connect } from 'react-redux';
import { UserResponseParams } from 'sber-marketing-types/frontend';
import autobind from 'autobind-decorator';

import { FileApi } from '@api';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';
import {
    resetFilesUploadStatus,
    setFileUploadStatus,
    FileUploadStatus,
    SetFileUploadStatusPayload,
    errorIsFromAntivirus,
} from '@store/common';

import { CommentProps } from './CommentsList';
import { ChildrenProps } from './CommentsConnected';
import { CommentsTemplate } from './CommentsTemplate';

interface Props extends ChildrenProps, Partial<MapProps & DispatchProps> {
    enableControls: boolean;
}

interface MapProps {
    userAttributes: UserResponseParams;
}

interface DispatchProps {
    resetFilesUploadStatus: () => void;
    setFileUploadStatus: (payload: SetFileUploadStatusPayload) => void;
}

interface State {
    pendingComments: CommentProps[];
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class CommentsContainer extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            pendingComments: [],
        };
    }

    public render(): JSX.Element {
        return <CommentsTemplate {...this.props} {...this.state} createComment={this.createComment} />;
    }

    @autobind
    private async createComment(text: string, files: File[]): Promise<void> {
        this.props.resetFilesUploadStatus();

        const commentId = v4(); // used only for pendingComment searching

        const { id, firstName, secondName } = this.props.userAttributes;
        this.setState(
            (state) => ({
                pendingComments: [
                    ...state.pendingComments,
                    {
                        id: commentId,
                        text,
                        createTime: Date(),
                        author: {
                            firstName,
                            secondName,
                        },
                        files: files.map((file) => {
                            const extensionIndex = file.name.lastIndexOf('.');
                            const originName = file.name.slice(0, extensionIndex);
                            const type = file.name.slice(extensionIndex + 1);

                            return {
                                originName,
                                name: originName,
                                type,
                                preventDownload: true,
                            };
                        }),
                    },
                ],
            }),
            async () => {
                const uploadedFileIds: string[] = [];
                let error: Error;

                await Promise.all(
                    files.map(async (file) => {
                        try {
                            const fileId = (await FileApi.uploadFile({}, file, String(id))).id;
                            uploadedFileIds.push(fileId);
                        } catch (e) {
                            if (errorIsFromAntivirus(e)) {
                                this.props.setFileUploadStatus({
                                    fileName: file.name,
                                    status: FileUploadStatus.VirusFound,
                                });
                            }

                            error = e;
                        }
                    }),
                );

                await this.props.createComment(text, uploadedFileIds);
                await this.props.refetch();

                this.setState((state) => ({
                    pendingComments: state.pendingComments.filter((pendingComment) => pendingComment.id !== commentId),
                }));

                if (error) {
                    throw error;
                }
            },
        );
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { attributes } = getLoginUser(state);

    return {
        userAttributes: attributes,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        resetFilesUploadStatus: () => dispatch(resetFilesUploadStatus()),
        setFileUploadStatus: (payload: SetFileUploadStatusPayload) => dispatch(setFileUploadStatus(payload)),
    };
}
