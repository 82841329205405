import * as React from 'react';
import * as ReactDOM from 'react-dom';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Icon, IconType, Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';

import { ActivityContent } from '../../types';

import { StoreState } from '@store';
import { DataUpdateStrategy } from '@store/tagsEditor';
import { getOrganizationById } from '@store/organizations';

import { DatesFormatter } from '@common/Utils';
import { TagsPreviewAndEditor, VerticalPosition } from '@modules/tags/TagsPreviewAndEditor';
import { SidebarContext } from '@common/SidebarWithTabs';
import { InfoSection, InfoSectionPreloader } from '@common/SidebarWithTabs/common/InfoSection';
import { DAMFilesArchiveWidget } from '@common/DAMFilesArchiveWidget';

import * as styles from './InfoTab.scss';

interface Props {
    activityId: number;
    tagsEditorId: string;
    activityContent: ActivityContent;
    addDAMArchiveWidget: boolean;
    canEditActivity: boolean;
    zIndex: number;
}

export function InfoTab({
    activityId,
    tagsEditorId,
    activityContent,
    addDAMArchiveWidget,
    canEditActivity,
    zIndex,
}: Props): JSX.Element {
    const showPreloader = !activityContent || canEditActivity === null;

    if (showPreloader) {
        return <Preloader />;
    }

    const {
        product,
        division,
        responsible,
        preparation,
        debriefing,
        realizationStart,
        realizationEnd,
        description,
        author,
        createTime,
        type,
    } = activityContent;
    const organizationId = product?.organizationId;

    const organization = useSelector((state: StoreState) => getOrganizationById(state, organizationId));

    const realizationDate = `${DatesFormatter.ddMonthyyyy(realizationStart)} - ${DatesFormatter.ddMonthyyyy(
        realizationEnd,
    )}`;

    return (
        <React.Fragment>
            <div
                className={styles.content}
                {...{
                    'qa-id': 'activitySidebarInfoTab',
                }}
            >
                <InfoSection
                    qaId="activitySidebarInfoTabOrganizationAndBlock"
                    icon={IconType.BLOCK_ICON}
                    title="Организация"
                    content={organization?.name}
                    noContentMessage="Не задана"
                />

                <InfoSection
                    qaId="activitySidebarInfoTabProduct"
                    icon={IconType.PRODUCT_ICON}
                    title="Продукт"
                    content={product?.name}
                />

                <InfoSection
                    qaId="activitySidebarInfoTabBlock"
                    icon={IconType.BLOCK_ICON}
                    title="Блок"
                    content={division?.name}
                />

                <InfoSection
                    qaId="activitySidebarInfoTabActivityType"
                    icon={IconType.ACTIVITY_TYPE_ICON}
                    title="Тип проекта"
                    content={type?.name}
                />

                <InfoSection
                    qaId="activitySidebarInfoTabResponsible"
                    icon={IconType.USER16}
                    title="Ответственный"
                    qaContent={responsible ? `${responsible.secondName} ${responsible.firstName}` : null}
                    content={responsible ? `${responsible.secondName} ${responsible.firstName}` : null}
                />

                <InfoSection
                    qaId="activitySidebarInfoTabRealizationDate"
                    icon={IconType.CALENDAR_ICON}
                    title="Даты проведения"
                    content={realizationDate}
                />

                <InfoSection
                    qaId="activitySidebarInfoTabPreparationDate"
                    icon={IconType.CALENDAR_ICON}
                    title="Дата начала подготовки"
                    content={DatesFormatter.ddMonthyyyy(preparation)}
                />

                <InfoSection
                    qaId="activitySidebarInfoTabDebriefing"
                    icon={IconType.CALENDAR_ICON}
                    title="Дата подведения итогов"
                    content={DatesFormatter.ddMonthyyyy(debriefing)}
                />

                <InfoSection
                    qaId="activitySidebarInfoTabDescription"
                    icon={IconType.INFO}
                    title="Описание проекта"
                    content={description}
                />

                <div className={styles.sectionRow}>
                    <div className={styles.sectionRowElement}>
                        <InfoSection
                            qaId="activitySidebarInfoTabAuthor"
                            title="Автор проекта"
                            content={author ? `${author.secondName} ${author.firstName}` : null}
                        />
                    </div>

                    <div className={styles.sectionRowElement}>
                        <InfoSection
                            qaId="activitySidebarInfoTabCreateTime"
                            title="Дата создания"
                            content={DatesFormatter.ddMonthyyyy(createTime)}
                        />
                    </div>
                </div>

                <TagsPreviewAndEditor
                    dataUpdateStrategy={DataUpdateStrategy.Immediate}
                    activityId={activityId}
                    id={tagsEditorId}
                    verticalPosition={VerticalPosition.Top}
                    className={styles.subWidget}
                    zIndex={zIndex + 5}
                />

                {addDAMArchiveWidget && <DAMFilesArchiveWidget activityId={activityId} className={styles.subWidget} />}
            </div>

            <Buttons activityId={activityId} canEditActivity={canEditActivity} />
        </React.Fragment>
    );
}

function Preloader(): JSX.Element {
    return (
        <React.Fragment>
            <InfoSectionPreloader />
            <InfoSectionPreloader />
            <InfoSectionPreloader />
        </React.Fragment>
    );
}

interface ButtonsProps {
    activityId: number;
    canEditActivity: boolean;
}

function Buttons({ activityId, canEditActivity }: ButtonsProps): JSX.Element {
    const { contentRef } = React.useContext(SidebarContext);

    return ReactDOM.createPortal(
        <div className={classnames(styles.buttonsSection, canEditActivity && styles.buttonSectionMultiple)}>
            <Link
                to={`/activity/${activityId}/brief`}
                className={classnames(styles.buttonLink, styles.openBriefButtonWrapper)}
            >
                <Button theme={ButtonTheme.GhostRounded}>
                    <div
                        className={styles.openBriefButton}
                        {...{
                            'qa-id': 'activitySidebarInfoTabOpenBriefButton',
                        }}
                    >
                        Перейти в бриф
                    </div>
                </Button>
            </Link>

            {canEditActivity && (
                <Link
                    to={`/activity/${activityId}/edit`}
                    className={classnames(styles.buttonLink, styles.editActivityButtonWrapper)}
                >
                    <Button theme={ButtonTheme.GhostRoundedBlack}>
                        <div
                            className={styles.editActivityButton}
                            {...{
                                'qa-id': 'activitySidebarInfoTabEditActivityButton',
                            }}
                        >
                            <Icon type={IconType.PROJECT_STAGES_EDIT_ICON} svgSize={18} />
                            Редактировать проект
                        </div>
                    </Button>
                </Link>
            )}
        </div>,
        contentRef.current,
    );
}
