import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
// import * as queryString from 'query-string';

import type { RouteComponentProps } from 'react-router-dom';
import { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type { Autopilot2 } from 'sber-marketing-types/backend';
import { AutopilotStage, SwitchKeyActivityParams } from '@store/autopilot/types';

import { AutopilotPageTemplate } from './AutopilotPageTemplate';
import type { StoreState } from '@store';
import { setCurrentStage, resetPageState } from '@store/autopilot/actions';
import { getPreloaderStatus, getAutopilot, getActivity, getCurrentStage, getSidebar } from '@store/autopilot/selectors';
import { switchKeyActivity } from '@store/autopilot/thunks';
import { Loader } from './modules';
import { User, getLoginUser } from '@store/user';

interface Props extends Partial<MapProps & DispatchProps>, Partial<RouteComponentProps<RouteParams>> {}

interface MapProps {
    preloader: boolean;
    autopilot: Autopilot2;
    activity: Activity;
    currentStage: AutopilotStage;
    sidebarIsOpened: boolean;
    user: User;
}

interface DispatchProps {
    setCurrentStage: (stage: AutopilotStage) => void;
    switchKeyActivity: (params: SwitchKeyActivityParams) => Promise<void>;
    resetPageState: () => void;
}

interface RouteParams {
    activityId: string;
}

interface State {
    pageDataLoaded: boolean;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class AutopilotPageBehaviour extends React.PureComponent<Props, State> {
    private activityId = Number(this.props.match.params.activityId);
    private loader: Loader;

    public constructor(props: Props) {
        super(props);

        this.state = {
            pageDataLoaded: false,
        };

        this.loader = Loader.getInstance();
    }

    public async componentDidMount() {
        // this.initFromQuery();
        await this.loader.init(this.activityId);
        this.setState({ pageDataLoaded: true });
    }

    public componentWillUnmount() {
        this.props.resetPageState();
    }

    public render(): JSX.Element {
        const { preloader, autopilot, sidebarIsOpened, currentStage } = this.props;
        const { pageDataLoaded } = this.state;

        return React.createElement(AutopilotPageTemplate, {
            preloader,
            pageDataLoaded,
            userIsAutopilotClient: this.checkUserAutopilotClientAccess(),
            userIsAutopilotClientService: this.checkUserAutopilotClientServiceAccess(),
            placementsAreLaunched: this.checkLaunchedPlacements(),
            autopilotId: autopilot?.id,
            autopilotIsEnabled: autopilot?.status == 'on',
            sidebarIsOpened,
            currentStage,
        });
    }

    // private initFromQuery() {
    //     const stageNumber = this.getStageNumberFromQuery();

    //     if (stageNumber) {
    //         this.props.setCurrentStage(stageNumber);
    //         this.removeQueryFromUrl();
    //     }
    // }

    // private getStageNumberFromQuery(): number {
    //     const stage = queryString.parse(this.props.location.search).stage as string;

    //     return stage ? parseInt(stage, 10) : null;
    // }

    // private removeQueryFromUrl() {
    //     this.props.history.push({
    //         search: ''
    //     });
    // }

    private checkLaunchedPlacements(): boolean {
        const { autopilot } = this.props;

        return autopilot && autopilot.status === 'on' && !!autopilot.launcherId;
    }

    private checkUserAutopilotClientAccess(): boolean {
        // check if user has role === Autopilot Client
        return this.props.user.attributes.roles.map(({ id }) => id).includes(22);
    }

    private checkUserAutopilotClientServiceAccess(): boolean {
        // check if user has role === Autopilot Client Service
        return this.props.user.attributes.roles.map(({ id }) => id).includes(24);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        preloader: getPreloaderStatus(state),
        autopilot: getAutopilot(state),
        activity: getActivity(state),
        currentStage: getCurrentStage(state),
        sidebarIsOpened: getSidebar(state).opened,
        user: getLoginUser(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setCurrentStage,
            switchKeyActivity,
            resetPageState,
        },
        dispatch,
    );
}
