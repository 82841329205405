import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { CreativeSidebarState as State, WatchCommentsByItemAndColumnState } from './types';

import * as actions from './actions';

class Reducer {
    public static makeInitialState(): State {
        return {
            watchComentsByItemAndColumn: {
                creativeRequestItemId: null,
                column: null,
            },
        };
    }

    public static setWatchCommentsByItemAndColumn(
        state: State,
        watchComentsByItemAndColumn: WatchCommentsByItemAndColumnState,
    ): State {
        return { ...state, watchComentsByItemAndColumn };
    }
}

export const creativeSidebarReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(actions.setWatchCommentsByItemAndColumn, Reducer.setWatchCommentsByItemAndColumn)
    .case(actions.resetState, Reducer.makeInitialState);
