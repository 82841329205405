import * as React from 'react';
import { groupBy, uniq, values } from 'lodash';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { TasksListType } from '@store/tasksList';
import * as workTypesStore from '@store/workTypes';

import { TaskCard } from '../TaskCard';
import { ContentPreloader } from '../DashboardsPreloaders';

import { TasksListTemplateProps, TasksBlockProps } from './types';

import * as style from './TasksList.scss';

const FILL_BRIEF = 'Начните работу с заполнения брифа';
const WELCOME_TO_PROJECT = 'Добро пожаловать в новый проект.';
const NO_TASKS_ON_FILTERS = 'Ни одна из ваших задач не соответствует заданным условиям.';
const NO_TASKS = 'У вас нет задач.';

export const TasksList: React.FC<TasksListTemplateProps> = ({
    tasks,
    isFilterModeOn,
    isLoading,
    type,
    briefStatus,
    activityId,
    from,
    activityStages,
    taskIdWithSidebar,
    onCreateTaskButtonClick,
    onTaskDelete,
    onTaskUpdate,
    reloadStagesData,
    openTaskSidebar,
}: TasksListTemplateProps): JSX.Element => {
    const dispatch = useDispatch();
    const workTypesIds = uniq(tasks.map(({ workTypeId }) => workTypeId).filter((workTypeId) => workTypeId));

    React.useEffect(() => {
        if (workTypesIds.length) {
            dispatch(
                workTypesStore.loadWorkTypes({
                    store: workTypesStore.StoreTypes.ACTIVITY_PAGE_FILTER,
                    ids: workTypesIds,
                }),
            );
        }
    }, [workTypesIds.join()]);

    const groupedTasks = values(groupBy(tasks, 'groupName'));
    const showBriefButton: boolean = type === TasksListType.ACTIVITY_TASKS;
    const showCreateTaskButton: boolean = type === TasksListType.ACTIVITY_TASKS;
    let message: React.ReactNode;

    if (isFilterModeOn) {
        message = NO_TASKS_ON_FILTERS;
    } else if (type === TasksListType.ACTIVITY_TASKS) {
        message = (
            <div className={style.textBlock}>
                <div>{WELCOME_TO_PROJECT}</div>

                <div>{briefStatus == FILL_BRIEF}</div>
            </div>
        );
    } else {
        message = NO_TASKS;
    }

    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'dashboardTasksList',
            }}
        >
            {groupedTasks.map((group) => (
                <TasksBlock
                    key={group[0].groupName}
                    title={group[0].groupName}
                    tasks={group}
                    from={from}
                    displayActivityName={type === TasksListType.MY_TASKS}
                    activityStages={activityStages}
                    taskIdWithSidebar={taskIdWithSidebar}
                    onTaskDelete={onTaskDelete}
                    onTaskUpdate={onTaskUpdate}
                    reloadStagesData={reloadStagesData}
                    openTaskSidebar={openTaskSidebar}
                />
            ))}

            {!isLoading && !groupedTasks.length && (
                <div className={style.noTasks}>
                    <div
                        className={style.noTasksMessage}
                        {...{
                            'qa-id': 'dashboardTasksListNoTasksMarker',
                        }}
                    >
                        {message}
                    </div>

                    {showBriefButton && (
                        <div
                            {...{
                                'qa-id': 'dashboardTasksListFillBriefLink',
                            }}
                        >
                            <Link className={style.newActivityLink} to={`/activity/${activityId}/brief`}>
                                Заполнить бриф
                            </Link>
                        </div>
                    )}

                    {showCreateTaskButton && (
                        <div
                            className={style.newActivityLink}
                            onClick={onCreateTaskButtonClick}
                            {...{
                                'qa-id': 'dashboardTasksListCreateTaskButton',
                            }}
                        >
                            Создать задачу
                        </div>
                    )}
                </div>
            )}
            {isLoading && <ContentPreloader />}
        </div>
    );
};

const TasksBlock: React.SFC<TasksBlockProps> = ({
    title,
    from,
    tasks,
    onTaskDelete,
    displayActivityName,
    activityStages,
    taskIdWithSidebar,
    onTaskUpdate,
    reloadStagesData,
    openTaskSidebar,
}: TasksBlockProps): JSX.Element => (
    <div
        className={style.blockWrapper}
        {...{
            'qa-id': 'dashboardTasksGroup',
            'qa-index': title,
        }}
    >
        <div className={style.title}>{title}</div>

        <div className={style.cardsWrapper}>
            {tasks.map((task) => (
                <div key={task.id} className={style.cardWrapper}>
                    <TaskCard
                        id={task.id}
                        from={from}
                        task={task}
                        displayActivityName={displayActivityName}
                        activityStages={activityStages}
                        isOpenedInSidebar={task.id === taskIdWithSidebar}
                        onTaskDelete={onTaskDelete}
                        onTaskUpdate={onTaskUpdate}
                        reloadStagesData={reloadStagesData}
                        openTaskSidebar={openTaskSidebar}
                    />
                </div>
            ))}
        </div>
    </div>
);
