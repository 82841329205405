import * as React from 'react';
import { connect } from 'react-redux';

import { StoreState } from '@store';
import { getBudgetExecutionPageState, TableLine, ColumnName, NumericCellValue } from '@store/budgetExecution';

import { BudgetInfo, InfoBlockParams } from './BudgetInfo';
import { Money, MoneyFormatter } from '@common/Utils';

interface Props extends Partial<MapProps> {
    lineId: string;
}

interface MapProps {
    line: TableLine;
}

@(connect(mapStateToProps) as any)
export class BudgetInfoContainer extends React.Component<Props> {
    public render() {
        return React.createElement(BudgetInfo, {
            infoBlockParamsList: this.buildInfoBlockParamsList(),
        });
    }

    private buildInfoBlockParamsList(): InfoBlockParams[] {
        return [
            {
                title: 'План:',
                content: this.getPlanCurrency(),
                qaId: 'budgetExecutionLineModalBudgetInfoPlan',
            },
            {
                title: 'Резерв:',
                content: this.getReserveCurrency(),
                qaId: 'budgetExecutionLineModalBudgetInfoReserve',
            },
            {
                title: 'Факт:',
                content: this.getFactCurrency(),
                qaId: 'budgetExecutionLineModalBudgetInfoFact',
            },
            {
                title: '',
                content: '',
            },
            {
                title: 'Остаток по резерву:',
                content: this.getReserveBalanceCurrency(),
                warning: this.isNegativeReserveBalanceCurrency(),
                qaId: 'budgetExecutionLineModalBudgetInfoReserveBalance',
            },
            {
                title: 'Остаток по факту:',
                content: this.getFactBalanceCurrency(),
                warning: this.isNegativeFactBalanceCurrency(),
                qaId: 'budgetExecutionLineModalBudgetInfoFactBalance',
            },
        ];
    }

    private getPlanCurrency(): string {
        return this.getFormattedCurrencyByColumnName(ColumnName.TotalPlan);
    }

    private getReserveCurrency(): string {
        return this.getFormattedCurrencyByColumnName(ColumnName.TotalReserve);
    }

    private getFactCurrency(): string {
        return this.getFormattedCurrencyByColumnName(ColumnName.TotalFact);
    }

    private getReserveBalanceCurrency(): string {
        return this.getFormattedCurrencyByColumnName(ColumnName.ActivityBalanceYear);
    }

    private getFactBalanceCurrency(): string {
        return this.getFormattedCurrencyByColumnName(ColumnName.FactBalanceYear);
    }

    private isNegativeReserveBalanceCurrency(): boolean {
        return this.getCurrencyByColumnName(ColumnName.ActivityBalanceYear) < 0;
    }

    private isNegativeFactBalanceCurrency(): boolean {
        return this.getCurrencyByColumnName(ColumnName.FactBalanceYear) < 0;
    }

    private getFormattedCurrencyByColumnName(columnName: ColumnName): string {
        return this.formatCurrency(this.getCurrencyByColumnName(columnName));
    }

    private getCurrencyByColumnName(columnName: ColumnName): number {
        const {
            line: { fields },
        } = this.props;
        return (fields[columnName] as NumericCellValue).number;
    }

    private formatCurrency(value: number): string {
        const valueMoney = Money.fromCopecks(value);
        return MoneyFormatter.toThousands(valueMoney, { hideCaption: true });
    }
}

function mapStateToProps(store: StoreState, props: Props): MapProps {
    const { lineId } = props;

    const { tableLines } = getBudgetExecutionPageState(store).computedData;

    return {
        line: tableLines.find((line) => line.id === lineId),
    };
}
