// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-Card-CardFooter-_CardFooter__root{display:flex;margin-top:4px;padding-top:6px;border-top:solid 1px #e3e9e5}.src-client-modules-common-Card-CardFooter-_CardFooter__leftSide{width:100%;margin-right:17px}.src-client-modules-common-Card-CardFooter-_CardFooter__rightSide{height:24px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/Card/CardFooter/CardFooter.scss"],"names":[],"mappings":"AAAA,6DACI,YAAa,CAEb,cAAe,CACf,eAAgB,CAEhB,4BAA6B,CAChC,iEAGG,UAAW,CACX,iBAAkB,CACrB,kEAGG,WAAY","sourcesContent":[".root {\n    display: flex;\n\n    margin-top: 4px;\n    padding-top: 6px;\n\n    border-top: solid 1px #e3e9e5;\n}\n\n.leftSide {\n    width: 100%;\n    margin-right: 17px;\n}\n\n.rightSide {\n    height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-Card-CardFooter-_CardFooter__root",
	"leftSide": "src-client-modules-common-Card-CardFooter-_CardFooter__leftSide",
	"rightSide": "src-client-modules-common-Card-CardFooter-_CardFooter__rightSide"
};
export default ___CSS_LOADER_EXPORT___;
