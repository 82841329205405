import { convertCreativeRequestParamDomains } from './convertCreativeRequestParamDomains';
import { convertCreativeRequestBudgetItemDomains } from './convertCreativeRequestBudgetItemDomains';

import type { CreativeRequestDomain } from '../types';

interface Converter {
    (domain: CreativeRequestDomain): Promise<{
        id: string;
        name: string;
        params: {
            id: string;
            division: {
                id: string;
                name: string;
            };
        }[];
        budgetItems: {
            id: string;
            serialNumber: string;
        }[];
    }>;
}

export const convertCreativeRequestDomain: Converter = async ({ model, events }) => {
    const [creativeRequestParamDomains, creativeRequestBudgetItemDomains] = await Promise.all([
        model.getParams(),
        model.getBudgetItems(),
    ]);

    return {
        id: model.id,
        name: model.name,
        params: await convertCreativeRequestParamDomains(creativeRequestParamDomains),
        budgetItems: convertCreativeRequestBudgetItemDomains(creativeRequestBudgetItemDomains),
    };
};
