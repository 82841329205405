import * as React from 'react';
import { orderBy } from 'lodash';

import { BudgetItemApi } from '@api';
import { BudgetItem, BudgetItemSnapshot, BudgetItemStatus } from '@mrm/budget';

interface Props {
    budgetItem: BudgetItem;
}

interface ChangesHistoryOfBudgetItem {
    id: string;
    status: BudgetItemStatus;
    time: Date;
    comment?: string;
}

interface State {
    loading: boolean;
    changesHistoryOfBudgetItem: ChangesHistoryOfBudgetItem[];
}

export interface WithChangesHistoryBudgetItemProps {
    loading: boolean;
    changesHistoryOfBudgetItem: ChangesHistoryOfBudgetItem[];
    updateChangesHistoryOfBudgetItem: () => Promise<void>;
}

export const withChangesHistoryOfBudgetItem = (getProps: (props: any) => Props) => (WrappedComponent: any) =>
    // @ts-ignore
    class extends React.Component<Props, State> {
        constructor(props: Props) {
            super(props);

            this.state = {
                loading: true,
                changesHistoryOfBudgetItem: [],
            };
        }

        public async componentDidMount(): Promise<void> {
            await this.fetchData();
        }

        public async componentDidUpdate(prevProps: Props): Promise<void> {
            const shouldUpdateData = this.getProps().budgetItem.status !== prevProps.budgetItem.status;

            if (shouldUpdateData) {
                await this.updateData();
            }
        }

        public render() {
            const { loading, changesHistoryOfBudgetItem } = this.state;

            return React.createElement(WrappedComponent, {
                ...this.props,
                loading,
                changesHistoryOfBudgetItem,
            });
        }

        private async updateData(): Promise<void> {
            await this.fetchData();
        }

        private async fetchData(): Promise<void> {
            const { budgetItem } = this.getProps();

            this.setState({ loading: true });

            const snapshots = await this.fetchSnapshot(budgetItem.id);

            this.setState({
                loading: false,
                // tslint:disable-next-line:max-line-length
                changesHistoryOfBudgetItem: this.buildChangesHistoryOfBudgetItem(snapshots, budgetItem),
            });
        }

        private getProps(): Props {
            const { budgetItem } = getProps(this.props);
            return { budgetItem };
        }

        private async fetchSnapshot(budgetItemId: string): Promise<BudgetItemSnapshot[]> {
            return await BudgetItemApi.getBudgetItemSnapshots(budgetItemId, {
                id: budgetItemId,
                columns: ['id', 'creationTime', 'status', 'expertComment'],
            });
        }

        // tslint:disable-next-line:max-line-length
        private buildChangesHistoryOfBudgetItem(
            snapshots: BudgetItemSnapshot[],
            budgetItem: BudgetItem,
        ): ChangesHistoryOfBudgetItem[] {
            const changesHistory: ChangesHistoryOfBudgetItem[] = [];
            const orderedSnapshots = orderBy(snapshots, (snapshot) => snapshot.creationTime, ['desc']);

            changesHistory.push({
                id: budgetItem.id,
                status: budgetItem.status,
                time: null,
                comment: budgetItem.expertComment,
            });

            orderedSnapshots.forEach((snapshot) => {
                changesHistory.push({
                    id: snapshot.id,
                    status: snapshot.status,
                    time: snapshot.creationTime || null,
                    comment: snapshot.expertComment,
                });
            });

            const historyChangesWithUpdatedTimes = changesHistory.map((change, index) => ({
                ...change,
                time: (changesHistory[index + 1] && changesHistory[index + 1].time) || null,
            }));

            const historyChangeOfStatuses = this.getChangesHistoryOfStatuses(historyChangesWithUpdatedTimes);
            return this.filterChangesHistoryByTargetStatuses(historyChangeOfStatuses);
        }

        // tslint:disable-next-line:max-line-length
        private getChangesHistoryOfStatuses(
            changesHistory: ChangesHistoryOfBudgetItem[],
        ): ChangesHistoryOfBudgetItem[] {
            const historyChangesOfStatus: ChangesHistoryOfBudgetItem[] = [];

            changesHistory.forEach((change, index) => {
                const notLastCurrentChangeOfChangesHistory = index < changesHistory.length - 1;

                if (notLastCurrentChangeOfChangesHistory) {
                    const currentChangeStatus = change.status;
                    const previousChangeStatus = changesHistory[index + 1].status;

                    const isChangedStatus = currentChangeStatus !== previousChangeStatus;

                    if (isChangedStatus) {
                        historyChangesOfStatus.push(change);
                    }
                }
            });

            // tslint:disable-next-line:max-line-length
            return historyChangesOfStatus;
        }

        // tslint:disable-next-line:max-line-length
        private filterChangesHistoryByTargetStatuses(
            changesHistory: ChangesHistoryOfBudgetItem[],
        ): ChangesHistoryOfBudgetItem[] {
            return changesHistory.filter(
                ({ status }) => status === BudgetItemStatus.Rejected || status === BudgetItemStatus.Approved,
            );
        }
    };
