import * as React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { StoreState } from '@store';
import { getBudgetItemByLineId } from '@store/budgetExecution';

import * as styles from './RowSelectors.scss';

interface ItemProps {
    id: string;
    isSelected: boolean;
    onItemClick?: (itemId: string) => void;
}

export function RowSelectorItem({ id, isSelected, onItemClick }: ItemProps): JSX.Element {
    const serialNumber = useSelector((state: StoreState) => getBudgetItemByLineId(state, id)?.serialNumber);

    return (
        <div
            className={classnames(styles.item, !isSelected && styles.itemSelectable)}
            onClick={!isSelected && onItemClick ? () => onItemClick(id) : null}
            {...{
                'qa-id': 'internalTransitionSumEnteringRowSelectorItem',
            }}
        >
            {serialNumber}
        </div>
    );
}
