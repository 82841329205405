import { axios, AxiosResponse } from '../lib/axios';

import { Correction, CorrectionListQueryParams, ChangeCorrectionStatusForm } from '@mrm/budget';

const BASE_URL = '/api/correction';

interface GetDataAsXLSXParams {
    [sheetName: string]: {
        [columnName: string]: number | string;
    }[];
}

export class BudgetCorrectionApi {
    public static async getCorrection(correctionId: string): Promise<Correction> {
        const res: AxiosResponse<Correction> = await axios.get(`${BASE_URL}/${correctionId}`);

        return res.data;
    }

    public static async getCorrectionsList(params: CorrectionListQueryParams): Promise<Correction[]> {
        const res: AxiosResponse<Correction[]> = await axios.post(BASE_URL, params);

        return res.data;
    }

    public static async changeCorrectionStatus(params: ChangeCorrectionStatusForm): Promise<void> {
        await axios.post(`${BASE_URL}/change-status`, params);
    }

    public static async getDataAsXLSX(params: GetDataAsXLSXParams): Promise<Buffer> {
        const res: AxiosResponse<any> = await axios.post(`${BASE_URL}/convert/to-excel`, params, {
            responseType: 'arraybuffer',
        });

        return res.data;
    }

    public static async getDataAsXLSXV2(params: GetDataAsXLSXParams): Promise<Buffer> {
        const res: AxiosResponse<any> = await axios.post(`${BASE_URL}/convert/to-excel/v2`, params, {
            responseType: 'arraybuffer',
        });

        return res.data;
    }

    public static async getCorrectionDataForXLS(params: CorrectionListQueryParams): Promise<object[]> {
        const res: AxiosResponse<object[]> = await axios.post(`${BASE_URL}/export-to-excel`, params);

        return res.data;
    }
}
