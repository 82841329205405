import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';
import * as moment from 'moment';

import type { PageState, PageData, Filters } from './types';
import { PageMode, Period } from './types';
import * as actions from './actions';

export const initialState: PageState = {
    pageData: {
        organizations: [],
        activities: [],
        accessibleActivitiesIds: [],
        activityTypes: [],
        responsibleUsers: [],
        divisions: [],
        divisionNames: {},
        departments: [],
        products: [],
        activityAuthors: [],
        blocks: [],
        calendarGroups: [],
        calendarGroupsNames: {},
        stages: [],
    },
    filters: {
        onlyKeyActivities: false,
        organizationIds: [],
        activityTypeIds: [],
        divisionIds: [],
        departmentIds: [],
        productIds: [],
        responsibleUserIds: [],
        weekStart: moment().startOf('day').toDate(),
        expiredStagesFilter: false,
    },
    currentPageMode: null,
    period: Period.Year,
    expandedGroupsIds: [],
    expandedActivitiesIds: [],
    hoveredItemId: null,
    isDisabledUploadPdfButton: true,
};

export class Reducer {
    public static loadPageData(state: PageState, payload: PageData): PageState {
        return { ...state, pageData: { ...state.pageData, ...payload } };
    }

    public static resetPageState(): PageState {
        return lodash.cloneDeep(initialState);
    }

    public static setDisabledUploadPdfButton(state: PageState, payload: boolean): PageState {
        return { ...state, isDisabledUploadPdfButton: payload };
    }

    public static setFilters(state: PageState, payload: Filters): PageState {
        return { ...state, filters: { ...state.filters, ...payload } };
    }

    public static setPageMode(state: PageState, payload: PageMode): PageState {
        return state.currentPageMode === payload ? state : { ...state, currentPageMode: payload };
    }

    public static setPeriod(state: PageState, payload: Period): PageState {
        return { ...state, period: payload };
    }

    public static setExpandedGroupsIds(state: PageState, payload: string[]): PageState {
        return { ...state, expandedGroupsIds: payload };
    }

    public static setExpandedActivitiesIds(state: PageState, payload: number[]): PageState {
        return { ...state, expandedActivitiesIds: payload };
    }

    public static setHoveredItemId(state: PageState, payload: React.ReactText): PageState {
        const hoveredItemIdChanged = state.hoveredItemId !== payload;

        return hoveredItemIdChanged ? { ...state, hoveredItemId: payload } : state;
    }
}

export const calendarReducer = reducerWithInitialState(initialState)
    .case(actions.loadPageData, Reducer.loadPageData)
    .case(actions.resetPageState, Reducer.resetPageState)
    .case(actions.setDisabledUploadPdfButton, Reducer.setDisabledUploadPdfButton)
    .case(actions.setFilters, Reducer.setFilters)
    .case(actions.setPageMode, Reducer.setPageMode)
    .case(actions.setPeriod, Reducer.setPeriod)
    .case(actions.setExpandedGroupsIds, Reducer.setExpandedGroupsIds)
    .case(actions.setExpandedActivitiesIds, Reducer.setExpandedActivitiesIds)
    .case(actions.setHoveredItemId, Reducer.setHoveredItemId);
