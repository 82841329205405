import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { Dispatch, AnyAction } from 'redux';
import { BudgetStatus } from '@mrm/budget';

import { BudgetApi, BudgetItemApi } from '@api';

import { StoreState } from '@store';
import { getBudgetByStatusUserConfig } from '@store/userConfig/budget';
import { LoadingStatus } from '@store/commonTypes';
import { getBudgetItemByLineId } from '@store/budgetExecution';

import * as asyncActions from './actions/async';
import * as syncActions from './actions/sync';
import { ComponentState } from './types';
import { getTransferBudgetItemsToPlanningMenuState } from './selectors';

export const loadComponentData = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.loadComponentData,
    async (_, dispatch, getState) => {
        const { loadingStatus } = getTransferBudgetItemsToPlanningMenuState(getState()).data.planningBudgets;

        if (loadingStatus !== LoadingStatus.LOADED) {
            try {
                dispatch(syncActions.setPlanningBudgets({ loadingStatus: LoadingStatus.LOADING }));

                const planningBudgets = (await BudgetApi.getBudgetList({ status: BudgetStatus.Plan })).filter(
                    (budget) => budget.actions?.createActivity,
                );

                dispatch(
                    syncActions.setPlanningBudgets({
                        loadingStatus: LoadingStatus.LOADED,
                        items: planningBudgets,
                    }),
                );

                if (planningBudgets.length === 1) {
                    dispatch(syncActions.setPlanningBudgetId(planningBudgets[0].id));
                }
            } catch (e) {
                dispatch(syncActions.setPlanningBudgets({ loadingStatus: LoadingStatus.ERROR }));
            }
        }
    },
);

export const initActivityTransfer = bindThunkAction<StoreState, string, void, Error>(
    asyncActions.initActivityTransfer,
    async (activityId, dispatch, getState) => {
        const prevActivityId = getTransferBudgetItemsToPlanningMenuState(getState()).props.activityToSelectLines;

        if (prevActivityId !== activityId) {
            dispatch(syncActions.setLinesToTransfer([]));
            dispatch(syncActions.setComponentState(ComponentState.Opened));
            await setActivityToTransferLines(activityId, getState, dispatch);
        }
    },
);

export const initSingleBudgetItemTransfer = bindThunkAction<StoreState, string, void, Error>(
    asyncActions.initSingleBudgetItemTransfer,
    async (budgetItemId, dispatch, getState) => {
        const state = getState();
        const selectedBudgetItem = getBudgetItemByLineId(state, budgetItemId);

        dispatch(syncActions.setLinesToTransfer([budgetItemId]));
        dispatch(syncActions.setComponentState(ComponentState.Opened));
        await setActivityToTransferLines(selectedBudgetItem?.activity?.id, getState, dispatch);
    },
);

export const toggleBudgetItemForTransfer = bindThunkAction<StoreState, string, void, Error>(
    asyncActions.toggleBudgetItemForTransfer,
    async (budgetItemId, dispatch, getState) => {
        const state = getState();
        const budgetItem = getBudgetItemByLineId(state, budgetItemId);

        const linesForTransfer = getTransferBudgetItemsToPlanningMenuState(state).props.linesToTransfer;
        const removeLineFromLinesForTransfer = linesForTransfer.includes(budgetItemId);

        const linesForTransferUpdated = removeLineFromLinesForTransfer
            ? linesForTransfer.filter((lineId) => lineId !== budgetItemId)
            : [...linesForTransfer, budgetItemId];

        dispatch(syncActions.setLinesToTransfer(linesForTransferUpdated));

        if (linesForTransferUpdated.length === 1) {
            await setActivityToTransferLines(budgetItem?.activity?.id, getState, dispatch);
        } else if (!linesForTransferUpdated.length) {
            await setActivityToTransferLines(null, getState, dispatch);
        }
    },
);

async function setActivityToTransferLines(
    activityId: string,
    getState: () => StoreState,
    dispatch: Dispatch<AnyAction>,
): Promise<void> {
    const state = getState();

    const budgetId = getBudgetByStatusUserConfig(state, BudgetStatus.Execution).budgetId;
    const prevActivityId = getTransferBudgetItemsToPlanningMenuState(state).props.activityToSelectLines;

    dispatch(syncActions.setActivityToTransferLines(activityId));

    if (activityId) {
        if (prevActivityId !== activityId) {
            try {
                dispatch(syncActions.setActivityBudgetItems({ loadingStatus: LoadingStatus.LOADING }));

                const activityBudgetItems = (
                    await BudgetItemApi.getBudgetItemList({
                        budgetId,
                        filter: {
                            activity: {
                                id: activityId,
                            },
                        },
                    })
                ).filter((budgetItem) => !budgetItem.childrenBudgetItems?.length);

                const activityBudgetItemsToUse =
                    getTransferBudgetItemsToPlanningMenuState(getState()).props.activityToSelectLines !== activityId
                        ? []
                        : activityBudgetItems;

                dispatch(
                    syncActions.setActivityBudgetItems({
                        loadingStatus: LoadingStatus.LOADED,
                        items: activityBudgetItemsToUse,
                    }),
                );
            } catch (e) {
                dispatch(syncActions.setActivityBudgetItems({ loadingStatus: LoadingStatus.ERROR }));
            }
        }
    } else {
        dispatch(syncActions.setActivityBudgetItems({ items: [] }));
    }
}
