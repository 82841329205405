// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-task-pages-TaskPage-components-TaskNotFound-_TaskNotFound__root{flex:1;display:flex;align-items:center;justify-content:center;padding-left:48px;min-height:calc(100vh - 49px)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/task/pages/TaskPage/components/TaskNotFound/TaskNotFound.scss"],"names":[],"mappings":"AAAA,oFACE,MAAO,CACP,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,iBAAkB,CAClB,6BAA8B","sourcesContent":[".root {\n  flex: 1;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-left: 48px;\n  min-height: calc(100vh - 49px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-task-pages-TaskPage-components-TaskNotFound-_TaskNotFound__root"
};
export default ___CSS_LOADER_EXPORT___;
