import * as React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { WithTooltip, Skeleton } from 'sber-marketing-ui';

import * as style from './ActivityTitle.scss';

import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';

import { FlagToggle } from '@common/FlagToggle';
import { CopyTextButton } from '@common/CopyTextButton';
import { DatesFormatter } from '@common/Utils';
import { FavoriteIcon } from './FavoriteIcon';

interface Props {
    activity: Activity;
    onSwitchKeyButtonClick: () => void;
}

export function ActivityTitleTemplate(props: Props): JSX.Element {
    return (
        <div className={style.root}>
            {props.activity && (
                <>
                    <TitleContent {...props} />
                    <SubtitleContent {...props} />
                </>
            )}

            {!props.activity && <Preloader />}
        </div>
    );
}

function TitleContent({ activity, onSwitchKeyButtonClick }: Props): JSX.Element {
    const flagTooltip = activity.canSwitchKey
        ? activity.isKey
            ? 'Сделать проект стандартным'
            : 'Сделать проект ключевым'
        : 'Ключевой проект';

    return (
        <div className={style.title}>
            <div className={style.favoriteActivityButton}>
                <FavoriteIcon qaId="activityIsFavoriteToggle" activity={activity} />
            </div>

            <div className={style.keyActivityButton}>
                <FlagToggle
                    qaId="activityIsKeyToggle"
                    qaIsSelected={activity.isKey}
                    tooltip={flagTooltip}
                    selected={activity.isKey}
                    canChange={activity.canSwitchKey}
                    onClick={onSwitchKeyButtonClick}
                />
            </div>

            <WithTooltip content={activity.name} spanWrapper>
                <div
                    className={classnames(style.activityName, style.ellipsis)}
                    {...{
                        'qa-id': 'activityName',
                    }}
                >
                    <Link className={style.link} to={`/activity/${activity.id}`}>
                        {activity.name}
                    </Link>
                </div>
            </WithTooltip>

            <div className={style.copyNameButton}>
                <CopyTextButton title="Скопировать полное название" text={activity.name} />
            </div>
        </div>
    );
}

function SubtitleContent({ activity }: Props): JSX.Element {
    const startDate = DatesFormatter.ddMonthyy(activity.realizationStart);
    const endDate = DatesFormatter.ddMonthyy(activity.realizationEnd);

    const dates = `Даты проведения: ${startDate} - ${endDate}`;

    return (
        <div className={style.subtitle}>
            <WithTooltip content={dates} spanWrapper>
                <div className={style.activityDates}>
                    Даты проведения:&nbsp;
                    <span className={style.activityDatesValue}>
                        {startDate} - {endDate}
                    </span>
                </div>
            </WithTooltip>
        </div>
    );
}

function Preloader(): JSX.Element {
    return (
        <>
            <Skeleton customStyle={{ height: 22, width: '350px', borderRadius: 2, marginBottom: 1 }} />
            <Skeleton customStyle={{ height: 16, width: '370px', borderRadius: 2 }} />
        </>
    );
}
