import { Month } from '@mrm/budget';
import type { PlainDictionary } from '@mrm/dictionary';
import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type {
    AutopilotTV,
    MediaplanPlanDataTVTotalResponse as MediaplanPlanDataTVTotal,
    AutopilotTVTargetGroup,
    AutopilotTVVideoDuration,
    AutopilotTVVideo,
    AutopilotTPeriodPercent,
    AutopilotTVPlanDataInterval,
} from 'sber-marketing-types/backend';

export interface PageState {
    autopilot: AutopilotTV;
    activity: Activity;
    mediaplan: MediaplanItem[];
    mediaplanTotal: MediaplanPlanDataTVTotal;
    targetGroups: AutopilotTVTargetGroup[];
    videoDurations: AutopilotTVVideoDuration[];
    dictionaries: GroupedDictionaries;
    briefStageForm: BriefStageForm;
    selectedBudgetItemIds: string[];
    currentStage: AutopilotStage;
    preloader: boolean;
    sidebar: Sidebar;
}

export interface BriefStageForm {
    blockId: string;
    productId: string;
    budget: string;
    budgetError: string;
    dateStart: string;
    dateEnd: string;
    targetGroup: AutopilotTVTargetGroup;
    videos: AutopilotTVVideo[];
    secondAdvertiserEnable: boolean;
    secondAdvertiserMargin: number;
    manualRatingSplitEnable: boolean;
    manualRatingSplit: AutopilotTPeriodPercent[];
}

export interface MediaplanItem {
    id: string;
    code: string;
    name: string;
    affinity: number;
    intervals: AutopilotTVPlanDataInterval[];
    totalInterval: AutopilotTVPlanDataInterval;
    direction: { id: number; name: string };
}

export interface GroupedDictionaries {
    [dictionaryType: string]: PlainDictionary[];
}

export interface Sidebar {
    opened: boolean;
}

export interface SwitchKeyActivityParams {
    id: number;
    isKey: boolean;
}

export const enum AutopilotStage {
    Brief = 'brief',
    MediaplanSettings = 'mediaplan_settings',
    Mediaplan = 'mediaplan',
    Budget = 'budget',
}

export const MONTHS = [
    Month.Jan,
    Month.Feb,
    Month.Mar,
    Month.Apr,
    Month.May,
    Month.Jun,
    Month.Jul,
    Month.Aug,
    Month.Sept,
    Month.Oct,
    Month.Nov,
    Month.Dec,
];
