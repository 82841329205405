import * as React from 'react';
import { Icon, IconType, StaticSkeleton } from 'sber-marketing-ui';

import * as styles from './InfoSection.scss';

interface Props {
    qaId: string;
    icon?: IconType;
    title: string;
    content: number | string | JSX.Element;
    noContentMessage?: string;
    subContent?: string;
    qaContent?: string;
}

export function InfoSection({
    qaId,
    icon,
    title,
    content,
    noContentMessage = 'Не задан',
    subContent,
    qaContent,
}: Props): JSX.Element {
    const contentToUse = content || noContentMessage;

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': qaId,
                'qa-title': title,
                'qa-content': qaContent || contentToUse,
            }}
        >
            <div className={styles.titleRow}>
                {icon ? <Icon type={icon} svgSize={16} className={styles.icon} /> : null}

                {title}
            </div>

            <div className={styles.content}>{contentToUse}</div>

            {subContent && <div className={styles.row}>{subContent}</div>}
        </div>
    );
}

export function InfoSectionPreloader(): JSX.Element {
    return (
        <div className={styles.root}>
            <StaticSkeleton loadingClassName={styles.firstPreloader} />
            <StaticSkeleton loadingClassName={styles.secondPreloader} />
        </div>
    );
}

export function InfoSectionFieldPreloader(): JSX.Element {
    return (
        <div className={styles.root}>
            <StaticSkeleton loadingClassName={styles.firstPreloader} />
        </div>
    );
}
