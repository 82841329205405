import { StoreState } from '@store';
import { GroupedDictionaries, BudgetPlanningUsers, getBudgetPlanningPageState } from '@store/budgetPlanning';
import { DictionaryType } from '@mrm/budget';

import { getAllUsers } from '@store/appUsers';

import { FetchedData } from './FetchedData';
import { ColumnNames, ColumnParams, Columns } from '../types';

// Used to replaced vetched linkedData value by id
export class LinkedValuesToDataTransformer {
    private dictionaries: GroupedDictionaries;
    private users: BudgetPlanningUsers;
    private columnsWithLinkedData: ColumnNames[];

    public constructor(state: StoreState) {
        this._init(state);
    }

    public transform(fetchedData: FetchedData[]): FetchedData[] {
        fetchedData.forEach((dataItem) =>
            this.columnsWithLinkedData.forEach((column) => {
                const columnValue = dataItem.getFieldByName(column) as string;

                if (columnValue) {
                    const linkedValue = this._getLinkedValueId(columnValue, column);

                    if (linkedValue) {
                        dataItem.setFieldByName(column, linkedValue);
                    } else {
                        dataItem.removeFieldByName(column);
                    }
                }
            }),
        );

        return fetchedData;
    }

    private _init(state: StoreState): void {
        this.dictionaries = getBudgetPlanningPageState(state).pageData.userDictionaries;
        this.users = getAllUsers(state)
            .filter((user) => user.isActive)
            .reduce((acc, user) => {
                const name = `${user.secondName} ${user.firstName}`;

                return {
                    ...acc,
                    [name]: {
                        id: user.id,
                        name,
                    },
                };
            }, {});

        this.columnsWithLinkedData = Columns.filter((column) => column.linkedValues).map((column) => column.key);
    }

    private _getLinkedValueId(cellValue: string, column: ColumnNames): string | number {
        if (cellValue === '-') {
            return null;
        }

        let valueType = ColumnParams[column].linkedValues;

        switch (valueType) {
            case 'users':
                const user = this.users[cellValue];
                return user ? user.id : null;
            default:
                const valueDictionaries = this.dictionaries.byType[valueType as DictionaryType] || [];
                const dictionary = valueDictionaries.find((dictionary) => dictionary.value === `${cellValue}`);
                return dictionary ? dictionary.id : null;
        }
    }
}
