import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconType } from 'sber-marketing-ui';
import type { FieldValue } from 'sber-marketing-types/frontend';

import type { FieldInput } from '@store/brief/types';
import { Budget } from '../types';

import { BudgetItemLinkCard } from '../BudgetItemLinkCard';
import { ActivityReferenceMenu } from '../ActivityReferenceMenu';

import * as styles from './LinksContainerWithToggle.scss';

interface Props {
    activityId: number;
    divisionId: string;
    departmentId: string;
    value?: FieldValue;
    budget?: Budget;
    year?: number;
    userHasBudgetAccess: boolean;
    onValueChange?: (value: FieldInput) => void;
}

export function LinksContainerWithToggle({
    activityId,
    divisionId,
    departmentId,
    value,
    budget,
    year,
    userHasBudgetAccess,
    onValueChange,
}: Props): JSX.Element {
    const [isOpened, setIsOpened] = React.useState(false);
    const [isSelected, setIsSelected] = React.useState(false);

    return (
        <div
            {...{
                'qa-id': 'activitySidebarBudgetTabBudgetContainer',
                'qa-budget-id': budget?.id,
                'qa-budget-year': budget?.year,
            }}
        >
            <div className={styles.title}>
                <div
                    onClick={() => setIsOpened(!isOpened)}
                    className={styles.titleClickable}
                    {...{
                        'qa-id': 'activitySidebarBudgetTabBudgetContainerOpenToggle',
                        'qa-is-opened': isOpened ? 'true' : 'false',
                    }}
                >
                    <Icon
                        svgSize={8}
                        type={IconType.TRIANGLE8_DOWN}
                        className={classNames(styles.toggleButton, isOpened && styles.toggleButtonActive)}
                    />
                    ID бюджета ({budget?.year || year})
                </div>

                <div className={styles.counter}>{value?.selected?.length || 0}</div>

                {userHasBudgetAccess && !!budget && (
                    <div className={styles.budgetLink} onClick={() => setIsSelected(true)}>
                        <span
                            {...{
                                'qa-id': 'activitySidebarBudgetTabBudgetContainerEditLinksButton',
                            }}
                        >
                            Привязать
                        </span>
                    </div>
                )}
            </div>

            {isSelected ? (
                <ActivityReferenceMenu
                    activityId={activityId}
                    budgetId={budget?.id}
                    divisionId={divisionId}
                    departmentId={departmentId}
                    value={value}
                    onSaveButtonClick={(value) => onValueChange(value)}
                    onCancelButtonClick={() => setIsSelected(false)}
                />
            ) : null}

            {isOpened ? (
                <div className={styles.serialNumberCards}>
                    {value?.selected?.length
                        ? value?.selected?.map((id) => (
                              <div key={id} className={styles.serialNumberCard}>
                                  <BudgetItemLinkCard
                                      id={id}
                                      budgetId={budget?.id}
                                      activityId={activityId}
                                      userHasBudgetAccess={userHasBudgetAccess}
                                  />
                              </div>
                          ))
                        : 'Нет привязанных строк'}
                </div>
            ) : null}
        </div>
    );
}
