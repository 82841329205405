import * as React from 'react';
import autobind from 'autobind-decorator';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { isNil, difference } from 'lodash';
import { TaskCommentReaction } from 'sber-marketing-types/frontend';

import { FileAsset, LoadingStatus } from '@store/commonTypes';

import { TaskComment } from './TaskComment';
import { StoreState } from '@store';
import {
    ToggleCommentReactionPayload,
    selectors,
    removeCommentary,
    saveCommentary,
    removeFileFromCommentary,
    editCommentary,
    setEditingComment,
    addCommentaryAsset,
    removeCommentaryAsset,
    setCommentIdToReplyTo,
    toggleCommentReaction as toggleCommentReactionAction,
    Commentary,
    resetEditor,
} from '@store/taskPage';
import { getUserById } from '@store/appUsers';
import * as departmentsStore from '@store/departments';
import { getLoginUser } from '@store/user/selector';
import { getHeaderHeight } from '@store/common/selectors';
import { add } from '@store/fileAssets';
import { withTaskData, PropsWithTaskData } from '../../withTaskData';
import { TaskApi, FileApi } from '@api';
import { getSavingComment } from '@store/taskPage/selectors';
import { removeList } from '@store/fileAssets/actions';

const departmentsEntities = departmentsStore.StoreTypes.ALL_DEPARTMENTS;

interface Props extends Partial<MapProps>, Partial<DispatchProps>, Partial<PropsWithTaskData> {
    index?: number;
    id: string;
    className?: string;
    updateDisabled: boolean;
}

interface MapProps {
    authorName: string;
    authorDepartment: string;
    isAuthorLoginedUser: boolean;
    channelAuthorId: number;
    channelParticipantIds: number[];
    comment: Commentary | null;
    editingComment: Commentary | symbol | null;
    userId: string;
    isEditable: boolean;
    showPreloader: boolean;
    postedAt: number | string | Date;
    content: string;
    saving: boolean;
    hasAssets: boolean;
    newAssetsIds: string[];
    headerHeight: number;
    channelParticipants: {
        userId: number;
        canRemove: boolean;
    }[];
    reactions: TaskCommentReaction[];
    commentIdToReplyTo: string;
    commentChildrenIds: string[];
}

interface DispatchProps {
    removeCommentary(): void;
    saveCommentary(content: string): void;
    editCommentary(content: string): void;
    setEditingComment(value: Commentary | null): void;
    resetEditor(): void;
    removeFileFromCommentary(fileName: string): void;
    addAsset(asset: FileAsset): void;
    addCommentaryAsset(asset: FileAsset): void;
    removeCommentaryAsset(fileName: string): void;
    removeAssets(ids: string[]): void;
    setCommentIdToReplyTo: (commentId: string) => void;
    toggleCommentReaction: (payload: ToggleCommentReactionPayload) => void;
}

interface State {
    displayError: boolean;
}

@(withTaskData as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class TaskCommentContainer extends React.Component<Props, State> {
    public state: State = {
        displayError: false,
    };

    public render(): JSX.Element {
        const {
            id,
            index,
            postedAt,
            className,
            updateDisabled,
            isEditable,
            content,
            hasAssets,
            showPreloader,
            authorName,
            authorDepartment,
            isAuthorLoginedUser,
            channelAuthorId,
            channelParticipantIds,
            channelId,
            channelParticipants,
            taskId,
            reactions,
            commentChildrenIds,
            commentIdToReplyTo,
            saving,
            editingComment,
        } = this.props;

        const { displayError } = this.state;
        const updateEnable = isEditable && !updateDisabled;
        const isEditEnabled = typeof editingComment === 'symbol' ? false : editingComment?.id === id;

        return (
            <TaskComment
                taskId={taskId}
                id={id}
                authorName={authorName}
                authorDepartment={authorDepartment}
                isAuthorLoginedUser={isAuthorLoginedUser}
                channelAuthorId={channelAuthorId}
                channelParticipantIds={channelParticipantIds}
                channelId={channelId}
                index={index}
                postedAt={postedAt}
                className={className}
                isEditable={updateEnable}
                isEditEnabled={updateEnable && (isEditEnabled || saving)}
                hasAssets={hasAssets}
                content={content}
                showPreloader={showPreloader}
                displayError={displayError}
                headerHeight={this.props.headerHeight}
                channelParticipants={channelParticipants}
                reactions={reactions}
                commentChildrenIds={commentChildrenIds}
                commentIdToReplyTo={commentIdToReplyTo}
                onChange={this.onChange}
                onAddAsset={this.onAddAsset}
                onSubmit={this.onSubmit}
                onEnableEdit={this.onEnableEdit}
                onRemove={this.onRemove}
                onReset={this.onReset}
                onAssetRemoveClick={this.onAssetRemoveClick}
                onFocus={this.onFocus}
                onCloseParentCommentButtonClick={this.onCloseParentCommentButtonClick}
                onReplyButtonClick={this.onReplyButtonClick}
                toggleCommentReaction={this.toggleCommentReaction}
            />
        );
    }

    @autobind
    protected onFocus(): void {
        this.setState(() => ({ displayError: false }));
    }

    @autobind
    protected onEnableEdit(): void {
        this.props.setEditingComment(this.props.comment);
    }

    @autobind
    protected onRemove(): void {
        this.props.removeCommentary();
    }

    @autobind
    protected onChange(content: string): void {
        this.props.editCommentary(content);
        // this.setState(() => ({ displayError: false }));

        window.clearTimeout(window[`commentTimer${this.props.id}`]);

        window[`commentTimer${this.props.id}`] = window.setTimeout(
            () => TaskApi.editTaskDraft({ id: this.props.taskId, comment: { id: this.props.id, text: content } }),
            10000,
        );
    }

    @autobind
    protected onAddAsset(file: File | FileList): void {
        if (file instanceof FileList) {
            Array.from(file).forEach((file) => this.addAsset(file));
        } else {
            this.addAsset(file);
        }
    }

    @autobind
    protected addAsset(file: File) {
        const { taskId, id, userId } = this.props;
        const params = { taskId, commentId: id };

        const asset = FileApi.makeFileAsset(params, file, String(userId));
        this.props.addAsset(asset);
        this.props.addCommentaryAsset(asset);
    }

    @autobind
    protected onReset(): void {
        if (!this.props.saving) {
            this.props.removeAssets(this.props.newAssetsIds);
            this.props.resetEditor();
            this.setState(() => ({ displayError: false }));
        }
    }

    @autobind
    protected async onSubmit(): Promise<void> {
        if (this.props.content.trim() || this.props.hasAssets) {
            await this.props.saveCommentary(this.props.content.trim());
            this.props.setEditingComment(null);
            this.setState(() => ({ displayError: false }));
        } else {
            this.setState(() => ({ displayError: true }));
        }
    }

    @autobind
    protected onAssetRemoveClick(asset: FileAsset) {
        const isAssetNew = !isNil(asset) && !isNil(asset.file);

        if (isAssetNew) {
            this.props.removeCommentaryAsset(asset.name);
        } else {
            this.props.removeFileFromCommentary(asset.name);
        }
    }

    @autobind
    private onCloseParentCommentButtonClick(): void {
        this.props.setCommentIdToReplyTo(null);
    }

    @autobind
    private onReplyButtonClick(): void {
        const { id } = this.props;

        this.props.setCommentIdToReplyTo(id);
    }

    @autobind
    private toggleCommentReaction(params: { commentId: string; taskId: string }, reaction: string): void {
        this.props.toggleCommentReaction({
            ...params,
            reaction,
        });
    }
}

function mapStateToProps(state: StoreState, { id: commentId, channelId }: Props): MapProps {
    const { attributes: user } = getLoginUser(state);
    const { participants } = selectors.getTaskInfo(state);
    const comment = selectors.getCommentaryById(state, commentId);
    const savingComment = getSavingComment(state);

    let authorName = '';
    let authorDepartment = '';
    let isAuthorLoginedUser = false;
    if (comment) {
        const authorUser = getUserById(state, comment.authorId);

        authorName = `${authorUser.secondName} ${authorUser.firstName}`;
        authorDepartment =
            departmentsStore.getLoadingStatus(state, departmentsEntities) === LoadingStatus.LOADED
                ? departmentsStore.getDepartmentById(state, authorUser.departmentId)?.name
                : '';
        isAuthorLoginedUser = authorUser.id === user.id;
    }

    const { authorId: channelAuthorId, participantIds } = selectors.getChannelById(state, channelId);

    const { commentIdToReplyTo } = selectors.getTaskPageState(state);
    const assetsIds = selectors.getCommentaryAssets(state, commentId).map(({ id }) => id);
    const editingComment = selectors.getEditingComment(state);
    const editingCommentAssets = typeof editingComment === 'symbol' ? [] : editingComment?.assetsIds || assetsIds;

    return {
        saving: savingComment === comment?.id,
        content: comment?.content,
        userId: String(user.id),
        showPreloader: comment?.isLoading,
        isEditable: user.id === comment?.authorId,
        postedAt: comment?.createdAt,
        editingComment,
        authorName,
        authorDepartment,
        isAuthorLoginedUser,
        hasAssets: comment ? !!selectors.getCommentaryAssets(state, commentId)?.length : false,
        newAssetsIds: editingComment ? difference(assetsIds, editingCommentAssets) : [],
        channelAuthorId,
        channelParticipantIds: participantIds,
        channelParticipants: participants,
        headerHeight: getHeaderHeight(state),
        reactions: comment?.reactions,
        commentChildrenIds: selectors.getCommentChildrenIds(state, commentId),
        commentIdToReplyTo,
        comment,
    };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>, { id, channelId }: Props): DispatchProps {
    return {
        removeCommentary: () => dispatch(removeCommentary({ id, channelId })),
        saveCommentary: (content: string) => dispatch(saveCommentary({ id, content })),
        editCommentary: (content: string) => dispatch(editCommentary({ content })),
        setEditingComment: (value: Commentary | null) => dispatch(setEditingComment(value)),
        resetEditor: () => dispatch(resetEditor()),
        removeFileFromCommentary: (fileName: string) =>
            dispatch(removeFileFromCommentary({ fileName, commentaryId: id })),
        addCommentaryAsset: (asset: FileAsset) => dispatch(addCommentaryAsset(asset)),
        addAsset: (asset: FileAsset) => dispatch(add(asset)),
        removeCommentaryAsset: (fileName: string) => dispatch(removeCommentaryAsset({ fileName, commentaryId: id })),
        removeAssets: (ids: string[]) => dispatch(removeList(ids)),
        setCommentIdToReplyTo: (commentId: string) => dispatch(setCommentIdToReplyTo(commentId)),
        toggleCommentReaction: (payload: ToggleCommentReactionPayload) =>
            dispatch(toggleCommentReactionAction(payload)),
    };
}
