// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedActivities-BindActivityPopup-_BindActivityPopup__root{width:576px;color:#000000;border:1px solid #ffffff;border-radius:4px;background-color:#ffffff}.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedActivities-BindActivityPopup-_BindActivityPopup__headerWrapper{margin:24px 24px 28px}.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedActivities-BindActivityPopup-_BindActivityPopup__selectedParticipantsListWrapper{margin-bottom:25px}.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedActivities-BindActivityPopup-_BindActivityPopup__footerWrapper{margin:0 24px 14px}.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedActivities-BindActivityPopup-_BindActivityPopup__blockWrapper{margin:0 24px 15px}.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedActivities-BindActivityPopup-_BindActivityPopup__endBlockWrapper{margin:0 24px 45px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/LineModal/Content/RelatedActivities/BindActivityPopup/BindActivityPopup.scss"],"names":[],"mappings":"AAAA,qIACI,WAAY,CAEZ,aAAc,CACd,wBAAyB,CACzB,iBAAkB,CAClB,wBAAyB,CAC5B,8IAGG,qBAAsB,CACzB,gKAGG,kBAAmB,CACtB,8IAGG,kBAAmB,CACtB,6IAGG,kBAAmB,CACtB,gJAGG,kBAAmB","sourcesContent":[".root {\n    width: 576px;\n\n    color: #000000;\n    border: 1px solid #ffffff;\n    border-radius: 4px;\n    background-color: #ffffff;\n}\n\n.headerWrapper {\n    margin: 24px 24px 28px;\n}\n\n.selectedParticipantsListWrapper {\n    margin-bottom: 25px;\n}\n\n.footerWrapper {\n    margin: 0 24px 14px;\n}\n\n.blockWrapper {\n    margin: 0 24px 15px;\n}\n\n.endBlockWrapper {\n    margin: 0 24px 45px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedActivities-BindActivityPopup-_BindActivityPopup__root",
	"headerWrapper": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedActivities-BindActivityPopup-_BindActivityPopup__headerWrapper",
	"selectedParticipantsListWrapper": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedActivities-BindActivityPopup-_BindActivityPopup__selectedParticipantsListWrapper",
	"footerWrapper": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedActivities-BindActivityPopup-_BindActivityPopup__footerWrapper",
	"blockWrapper": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedActivities-BindActivityPopup-_BindActivityPopup__blockWrapper",
	"endBlockWrapper": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedActivities-BindActivityPopup-_BindActivityPopup__endBlockWrapper"
};
export default ___CSS_LOADER_EXPORT___;
