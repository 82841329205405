import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { isLoggedUserBudgetExpert } from '@store/user';
import {
    ComponentState,
    getBudgetTransferMenuState,
    setComponentState,
    initPlanTransfer,
    getFromLinesWithNegativePlanReserveDiff,
    getTotalTransferAmount,
} from '@store/budgetExecution/budgetTransferMenu';

import * as styles from './ControlButtons.scss';

function useStore() {
    const dispatch = useDispatch();

    const isRequestInProgress = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.isRequestInProgress,
    );
    const selectedExpert = useSelector((state: StoreState) => getBudgetTransferMenuState(state).experts.selectedExpert);
    const userIsBudgetExpert = useSelector((state: StoreState) => isLoggedUserBudgetExpert(state));
    const transferValueIsNotZero = useSelector((state: StoreState) => getTotalTransferAmount(state));
    const hasLinesWithNegativeBalance = useSelector(
        (state: StoreState) => !!getFromLinesWithNegativePlanReserveDiff(state).length,
    );

    const expertIsSelected = !!(userIsBudgetExpert || selectedExpert);
    const canStartTransaction = expertIsSelected && transferValueIsNotZero;

    const toLineSelectionMode = React.useCallback(() => {
        if (!isRequestInProgress) {
            dispatch(setComponentState(ComponentState.InternalTransferLineSelection));
        }
    }, [isRequestInProgress, dispatch, setComponentState]);

    const initCorrection = React.useCallback(
        () =>
            dispatch(
                hasLinesWithNegativeBalance
                    ? setComponentState(ComponentState.InternalTransferNegativeLineBalanceWarning)
                    : initPlanTransfer(null),
            ),
        [hasLinesWithNegativeBalance, dispatch, initPlanTransfer, setComponentState],
    );

    return {
        isRequestInProgress,
        canStartTransaction,
        toLineSelectionMode,
        initCorrection,
    };
}

export function ControlButtons(): JSX.Element {
    const { isRequestInProgress, canStartTransaction, toLineSelectionMode, initCorrection } = useStore();

    return (
        <div className={styles.root}>
            <div className={styles.column}>
                <Button
                    theme={ButtonTheme.GhostRounded}
                    onClick={toLineSelectionMode}
                    qaId="internalTransitionSumEnteringToLineSelectionButton"
                >
                    Назад
                </Button>
            </div>

            <div className={styles.column}>
                {isRequestInProgress && <div className={styles.columnDisabledMask} />}

                <Button
                    theme={ButtonTheme.GhostRoundedBlack}
                    disabled={!canStartTransaction}
                    onClick={initCorrection}
                    qaId="internalTransitionSumEnteringInitCorrectionButton"
                >
                    {isRequestInProgress ? 'На согласование...' : 'На согласование'}
                </Button>
            </div>
        </div>
    );
}
