import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as lodash from 'lodash';

import type { Autopilot2 } from 'sber-marketing-types/backend';
import type { User } from '@store/user/types';
import type { StoreState } from '@store';

import { loadAutopilot } from '@store/activityPage/thunks';
import { setPreloaderStatus } from '@store/autopilot/actions';
import { getAutopilot } from '@store/autopilot/selectors';
import { getLoginUser } from '@store/user/selector';
import { Loader, Saver } from '../../modules';

export const useHooks = () => {
    const dispatch = useDispatch();

    const saver = Saver.getInstance();
    const loader = Loader.getInstance();

    const [displayAutopilotConfirmModal, setDisplayAutopilotConfirmModal] = React.useState(false);
    const [displayPlacementsConfirmModal, setDisplayPlacementsConfirmModal] = React.useState(false);

    const autopilot = useSelector((state: StoreState) => getAutopilot(state));
    const user = useSelector((state: StoreState) => getLoginUser(state));

    const autopilotIsEnabled = autopilot?.status == 'on';
    const placementsAreLaunched = checkLaunchedPlacements(autopilot);

    const userHasAutopilotClientAccess = checkUserAutopilotClientAccess(user);
    const userHasAutopilotClientServiceAccess = checkUserAutopilotClientServiceAccess(user);

    const displayAutopilotEnableButton = userHasAutopilotClientAccess && !placementsAreLaunched && !autopilotIsEnabled;

    const displayAutopilotDisableButton = userHasAutopilotClientAccess && !placementsAreLaunched && autopilotIsEnabled;

    const displayPlacementsStopButton =
        (userHasAutopilotClientAccess || userHasAutopilotClientServiceAccess) && placementsAreLaunched;

    const onAutopilotButtonClick = React.useCallback(() => {
        setDisplayAutopilotConfirmModal(true);
    }, []);

    const onPlacementsButtonClick = React.useCallback(() => {
        setDisplayPlacementsConfirmModal(true);
    }, []);

    const onAutopilotEnableConfirm = React.useCallback(async () => {
        setDisplayAutopilotConfirmModal(false);
        dispatch(setPreloaderStatus(true));
        await saver.setAutopilotStatus(true);
        await Promise.all([dispatch(loadAutopilot(autopilot.activityId)), loader.loadAutopilot()]);
        dispatch(setPreloaderStatus(false));
    }, [dispatch]);

    const onAutopilotDisableConfirm = React.useCallback(async () => {
        setDisplayAutopilotConfirmModal(false);
        dispatch(setPreloaderStatus(true));
        await saver.setAutopilotStatus(false);
        await Promise.all([dispatch(loadAutopilot(autopilot.activityId)), loader.loadAutopilot()]);
        dispatch(setPreloaderStatus(false));
    }, [dispatch]);

    const onPlacementsStopConfirm = React.useCallback(async () => {
        setDisplayPlacementsConfirmModal(false);
        dispatch(setPreloaderStatus(true));
        await saver.stopPlacements();
        await loader.loadAutopilot();
        dispatch(setPreloaderStatus(false));
    }, []);

    const onModalCancel = React.useCallback(() => {
        setDisplayAutopilotConfirmModal(false);
        setDisplayPlacementsConfirmModal(false);
    }, []);

    return {
        displayAutopilotEnableButton,
        displayAutopilotDisableButton,
        displayPlacementsStopButton,
        displayAutopilotEnableConfirmModal: displayAutopilotConfirmModal && !autopilotIsEnabled,
        displayAutopilotDisableConfirmModal: displayAutopilotConfirmModal && autopilotIsEnabled,
        displayPlacementsConfirmModal,
        onAutopilotButtonClick,
        onPlacementsButtonClick,
        onAutopilotEnableConfirm,
        onAutopilotDisableConfirm,
        onPlacementsStopConfirm,
        onModalCancel,
    };
};

function checkLaunchedPlacements(autopilot: Autopilot2): boolean {
    return (
        autopilot?.status === 'on' &&
        !lodash.isEmpty(autopilot.placements) &&
        autopilot.placements.some((item) => item.deployStatus !== 'OFF')
    );
}

function checkUserAutopilotClientAccess(user: User) {
    // check if user has role === Autopilot Client
    return user.attributes.roles.map(({ id }) => id).includes(22);
}

function checkUserAutopilotClientServiceAccess(user: User) {
    // check if user has role === Autopilot Client Service
    return user.attributes.roles.map(({ id }) => id).includes(24);
}
