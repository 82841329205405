import { SelectItem } from 'sber-marketing-ui';
import { Moment } from 'moment';
import { UserResponseParams, ActivityParams } from 'sber-marketing-types/frontend';
import { PlainDictionary } from '@mrm/dictionary';

export interface NewActivityPageState {
    activity: ActivityParams;
    blocks: SelectItem[];
    productsByBlock: { [key: string]: SelectItem[] };
    activityTypes: SelectItem[];
    calendarGroupsByBlock: { [key: string]: SelectItem[] };
    responsibles: SelectItem[];
    isPrivate: boolean;
    bpmWasInit: boolean;
    validationMode: ValidationMode;
    values: PageValues;
}

export interface PageValues {
    name: string;
    description: string;
    divisionId: string;
    productId: string;
    calendarGroupId: string;
    activityTypeId: string;
    responsibleId: number;
    preparationDate: Moment;
    realizationStart: Moment;
    realizationEnd: Moment;
    debriefingDate: Moment;
    autoCreateTasks?: boolean;
    bpmWasInit?: boolean;
}

export const enum ValidationMode {
    All = 'all',
    Title = 'title',
    None = 'none',
}

export interface LoadPageDataPayload {
    activity: ActivityParams;
    users: UserResponseParams[];
    blocks: PlainDictionary[];
    activityTypes: PlainDictionary[];
    products: PlainDictionary[];
    calendarGroups: PlainDictionary[];
}

export interface SaveActivityPayload {
    isDraft: boolean;
    activityId?: number;
    tagsEditorId: string;
}
