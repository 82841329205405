import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import * as lodash from 'lodash';

import * as style from './MediaplanSettingsStageWidget.scss';

import type { StoreState } from '@store';

import { RegionsTable } from './RegionsTable';
import { getBriefStageForm } from '@store/autopilotDOOH/selectors';

interface Props {
    readOnly?: boolean;
}

export const MediaplanSettingsStageWidget = ({ readOnly }: Props): JSX.Element => {
    const briefForm = useSelector((state: StoreState) => getBriefStageForm(state));

    return (
        <div className={style.root}>
            <div className={style.title}>Параметры рассчёта</div>

            <div className={style.form}>
                <div className={classNames(style.field, style.line)}>
                    <div className={style.fieldTitle}>Стратегия кампании</div>

                    <div className={style.fieldTextValue}>Охватная РК</div>
                </div>

                <div className={classNames(style.field, style.line)}>
                    <div className={style.fieldTitle}>Бюджет брифа</div>

                    <div className={style.fieldTextValue}>
                        {formatCurrencyValue(roundNumber(parseInt(briefForm.budget, 10) || null))} ₽
                    </div>
                </div>

                <div className={classNames(style.field, style.line)}>
                    <div className={style.fieldTitle}>Выбор городов и количества сторон</div>

                    <div className={style.tableLegends}>
                        <div className={style.tableLegend}>
                            <div className={style.tableLegendTitle} style={{ background: '#ebf8ee' }}>
                                10
                            </div>

                            <div className={style.tableLegendDescription}>— значения, рекомендуемые алгоритмом</div>
                        </div>

                        <div className={style.tableLegend}>
                            <div className={style.tableLegendTitle} style={{ background: '#f4f9fc' }}>
                                10
                            </div>

                            <div className={style.tableLegendDescription}>
                                — значения, введенные пользователем вручную
                            </div>
                        </div>

                        <div className={style.tableLegend}>
                            <div className={style.tableLegendTitle} style={{ background: '#fbf0f0' }}>
                                10
                            </div>

                            <div className={style.tableLegendDescription}>
                                — значения, для которых превышен бюджет или количество сторон
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classNames(style.field, style.line)}>
                    <RegionsTable />
                </div>
            </div>
        </div>
    );
};

function roundNumber(value: number, digitsAfterComma = 2): string {
    const roundedValue = Math.round(value * 100) / 100;
    const formatedValue = roundedValue.toFixed(digitsAfterComma);

    const [decimalPart, fractionPart] = formatedValue.split('.');

    return `${decimalPart}${fractionPart ? `.${fractionPart}` : ''}`;
}

function formatCurrencyValue(value: number | string): string {
    let [decimalPart, fractionPart] = value.toString().split(/[.,]/);

    let sign = '';

    if (lodash.first(decimalPart) === '-') {
        decimalPart = decimalPart.substring(1);
        sign = '-';
    }

    const splittedDecimal = decimalPart.split(/(?=(?:...)*$)/).join(' ');

    return `${sign}${splittedDecimal}${fractionPart ? `,${fractionPart}` : ''}`;
}
