import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Dictionary, flatten, values, difference } from 'lodash';
import { FileResponse } from 'sber-marketing-types/frontend';
import { ActivityTasksStatusDTO, ActivityStatusDTO } from '@mrm/dam';

import { LoadingStatus } from '@store/commonTypes';

import {
    ActivityFilesDAMArchiveState as State,
    DAMArchiveLoadingStatus,
    ArchiveContentPopupState,
    ArchiveContentPopupComponentState,
    DAMArchiveTasksFiles,
} from './types';

import * as actions from './actions/sync';

class Reducer {
    public static makeInitialState(): State {
        return {
            activityId: null,
            archive: null,
            poolingInProgress: false,
            tasksFiles: {
                byTaskId: {},
                entities: [],
            },
            loadingStatus: {
                common: LoadingStatus.NOT_LOADED,
                tasksFiles: LoadingStatus.NOT_LOADED,
            },
            archiveContentPopup: Reducer.makeEmptyArchiveContentPopupState(),
        };
    }

    public static setActivityId(state: State, activityId: number): State {
        return { ...state, activityId };
    }

    public static setArchive(state: State, archive: ActivityTasksStatusDTO): State {
        return { ...state, archive };
    }

    public static setArchiveStatus(state: State, archiveStatus: ActivityStatusDTO): State {
        return { ...state, archiveStatus };
    }

    public static setPoolingInProgress(state: State, poolingInProgress: boolean): State {
        return { ...state, poolingInProgress };
    }

    public static setTasksFiles(state: State, byTaskId: Dictionary<FileResponse[]>): State {
        const tasksFiles: DAMArchiveTasksFiles = {
            byTaskId,
            entities: flatten(values(byTaskId)),
        };

        return { ...state, tasksFiles };
    }

    public static setArchiveContentPopupState(state: State, componentState: ArchiveContentPopupComponentState): State {
        let popupState: ArchiveContentPopupState = null;

        switch (componentState) {
            case ArchiveContentPopupComponentState.Hidden:
                popupState = Reducer.makeEmptyArchiveContentPopupState();
                break;
            case ArchiveContentPopupComponentState.Editor:
                const exportedFiles = flatten(state.archive?.tasks?.map((task) => task.files))
                    .filter((file) => file.damFile)
                    .map((file) => file.mrmFile.id);
                const filesToExport = difference(
                    state.tasksFiles.entities.map((file) => file.id),
                    exportedFiles,
                );

                popupState = {
                    ...state.archiveContentPopup,
                    componentState,
                    filesToExport,
                };
                break;
            case ArchiveContentPopupComponentState.ViewContent:
            default:
                popupState = { ...state.archiveContentPopup, componentState };
                break;
        }

        return Reducer.archiveContentPopupReducer(state, popupState);
    }
    public static setRenameFilesWithSameName(state: State, renameFilesWithSameName: boolean): State {
        return Reducer.archiveContentPopupReducer(state, { renameFilesWithSameName });
    }

    public static setFilesToExport(state: State, filesToExport: string[]): State {
        return Reducer.archiveContentPopupReducer(state, { filesToExport });
    }

    public static setTasksFilesLoadingStatus(state: State, tasksFiles: LoadingStatus): State {
        return Reducer.loadingStatusReducer(state, { tasksFiles });
    }

    public static setCommonLoadingStatus(state: State, common: LoadingStatus): State {
        return Reducer.loadingStatusReducer(state, { common });
    }

    private static makeEmptyArchiveContentPopupState(): ArchiveContentPopupState {
        return {
            componentState: ArchiveContentPopupComponentState.Hidden,
            renameFilesWithSameName: false,
            filesToExport: [],
        };
    }

    private static archiveContentPopupReducer(
        state: State,
        archiveContentPopup: Partial<ArchiveContentPopupState>,
    ): State {
        return { ...state, archiveContentPopup: { ...state.archiveContentPopup, ...archiveContentPopup } };
    }

    private static loadingStatusReducer(state: State, loadingStatus: Partial<DAMArchiveLoadingStatus>): State {
        return { ...state, loadingStatus: { ...state.loadingStatus, ...loadingStatus } };
    }
}

export const activityFilesDAMArchiveReducer = reducerWithInitialState(Reducer.makeInitialState());

activityFilesDAMArchiveReducer
    .case(actions.resetComponentState, Reducer.makeInitialState)
    .case(actions.setActivityId, Reducer.setActivityId)
    .case(actions.setArchive, Reducer.setArchive)
    .case(actions.setArchiveStatus, Reducer.setArchiveStatus)
    .case(actions.setPoolingInProgress, Reducer.setPoolingInProgress)
    .case(actions.setTasksFiles, Reducer.setTasksFiles);

activityFilesDAMArchiveReducer
    .case(actions.setArchiveContentPopupState, Reducer.setArchiveContentPopupState)
    .case(actions.setRenameFilesWithSameName, Reducer.setRenameFilesWithSameName)
    .case(actions.setFilesToExport, Reducer.setFilesToExport);

activityFilesDAMArchiveReducer
    .case(actions.setCommonLoadingStatus, Reducer.setCommonLoadingStatus)
    .case(actions.setTasksFilesLoadingStatus, Reducer.setTasksFilesLoadingStatus);
