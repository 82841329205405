import * as React from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { uniq, difference } from 'lodash';
import { Icon, IconType } from 'sber-marketing-ui';
import { FileResponse } from 'sber-marketing-types/frontend';

import { StoreState } from '@store';
import { getActivityTasksPageState } from '@store/activityTasksPage';
import { getActivityDAMArchiveState, getTaskFilesForExport, setFilesToExport } from '@store/activityFilesDAMArchive';

import { TitleWithCounter } from '../TitleWithCounter';
import { File as CommonFile } from '../File';

import * as styles from './EditorMode.scss';

interface Props {
    taskId: string;
    className: string;
}

function useEditorMode(taskId: string) {
    const task = useSelector((state: StoreState) => getActivityTasksPageState(state).activityTasks.byId[taskId]);
    const taskFiles = useSelector((state: StoreState) => getTaskFilesForExport(state, taskId));

    const allFileTypes = React.useMemo(() => uniq(taskFiles.map((file) => file.type)), [task]);
    const [fileTypesToDisplay, setFileTypesToDisplay] = React.useState(allFileTypes);

    return {
        task,
        taskFiles,
        allFileTypes,
        fileTypesToDisplay,
        setFileTypesToDisplay,
    };
}

export function EditorMode({ taskId, className }: Props): JSX.Element {
    const { task, taskFiles, allFileTypes, fileTypesToDisplay, setFileTypesToDisplay } = useEditorMode(taskId);

    const shouldRender = !!taskFiles.length;

    return shouldRender ? (
        <div className={className}>
            <TitleWithCounter title={task.title} count={taskFiles.length} />
            <FileTypes
                allFileTypes={allFileTypes}
                fileTypesToDisplay={fileTypesToDisplay}
                setFileTypesToDisplay={setFileTypesToDisplay}
            />
            <SelectAllButton taskId={taskId} fileTypesToDisplay={fileTypesToDisplay} />
            <Files taskId={taskId} files={taskFiles} fileTypesToDisplay={fileTypesToDisplay} />
        </div>
    ) : null;
}

interface FileTypesProps {
    allFileTypes: string[];
    fileTypesToDisplay: string[];
    setFileTypesToDisplay: (fileTypes: string[]) => void;
}

function FileTypes({ allFileTypes, fileTypesToDisplay, setFileTypesToDisplay }: FileTypesProps): JSX.Element {
    const shouldRender = allFileTypes.length > 1;

    function onFileTypeClick(fileType: string) {
        const fileTypesUpd = fileTypesToDisplay.includes(fileType)
            ? fileTypesToDisplay.filter((type) => type !== fileType)
            : [...fileTypesToDisplay, fileType];

        setFileTypesToDisplay(fileTypesUpd);
    }

    return shouldRender ? (
        <div className={styles.fileTypes}>
            Показывать файлы:
            {allFileTypes.map((fileType) => (
                <div
                    key={fileType}
                    className={classnames(
                        styles.fileType,
                        fileTypesToDisplay.includes(fileType) && styles.fileTypeActive,
                    )}
                    onClick={() => onFileTypeClick(fileType)}
                >
                    {fileType}
                </div>
            ))}
        </div>
    ) : null;
}

interface SelectAllButtonProps {
    taskId: string;
    fileTypesToDisplay: string[];
}

function useSelectAllButton(props: SelectAllButtonProps) {
    const dispatch = useDispatch();
    const { taskId, fileTypesToDisplay } = props;

    const tasksFiles = useSelector((state: StoreState) => getActivityDAMArchiveState(state).tasksFiles.byTaskId);
    const filesToExport = useSelector(
        (state: StoreState) => getActivityDAMArchiveState(state).archiveContentPopup.filesToExport,
    );

    const files = tasksFiles[taskId].filter((file) => fileTypesToDisplay.includes(file.type)).map((file) => file.id);
    const fileCountIsEnough = files.length > 1;
    let renderSelectAllButton = fileCountIsEnough;
    let renderUnselectButton = fileCountIsEnough;
    if (fileCountIsEnough) {
        const everyFileIsCheckedForExport = files.every((file) => filesToExport.includes(file));
        renderSelectAllButton = renderSelectAllButton && !everyFileIsCheckedForExport;
        renderUnselectButton = renderUnselectButton && everyFileIsCheckedForExport;
    }

    function onSelectAllButtonClick() {
        dispatch(setFilesToExport(uniq([...filesToExport, ...files])));
    }

    function onUnselectButtonClick() {
        dispatch(setFilesToExport(difference(filesToExport, files)));
    }

    return {
        renderSelectAllButton,
        onSelectAllButtonClick,
        renderUnselectButton,
        onUnselectButtonClick,
    };
}

function SelectAllButton(props: SelectAllButtonProps): JSX.Element {
    const { renderSelectAllButton, onSelectAllButtonClick, renderUnselectButton, onUnselectButtonClick } =
        useSelectAllButton(props);

    if (renderSelectAllButton) {
        return (
            <div className={styles.selectAllButton} onClick={onSelectAllButtonClick}>
                выбрать все
            </div>
        );
    }

    if (renderUnselectButton) {
        return (
            <div className={styles.selectAllButton} onClick={onUnselectButtonClick}>
                снять выделение
            </div>
        );
    }

    return null;
}

interface FilesProps {
    taskId: string;
    files: FileResponse[];
    fileTypesToDisplay: string[];
}

function Files({ taskId, files, fileTypesToDisplay }: FilesProps): JSX.Element {
    const filesToDisplay = files.filter((file) => fileTypesToDisplay.includes(file.type));

    return (
        <div className={styles.files}>
            {filesToDisplay.map((file) => (
                <File key={file.id} taskId={taskId} {...file} />
            ))}
        </div>
    );
}

interface FileProps extends FileResponse {
    taskId: string;
}

function File(props: FileProps): JSX.Element {
    const dispatch = useDispatch();
    // const fileName = `${props.originName}.${props.type}`;

    const filesToExport = useSelector(
        (state: StoreState) => getActivityDAMArchiveState(state).archiveContentPopup.filesToExport,
    );
    // const hasFilesWithSameName = useSelector((state: StoreState) => {
    //     const allFiles = getActivityDAMArchiveState(state).tasksFiles.entities;

    //     return filesToExport.some(fileId => {
    //         if (fileId === props.id) {
    //             return false;
    //         }

    //         const fileToExport = allFiles.find(file => file.id === fileId);
    //         const fileToExportName = `${fileToExport.originName}.${fileToExport.type}`;

    //         return fileName === fileToExportName;
    //     })
    // })
    const isChecked = filesToExport.includes(props.id);
    // const showError = isChecked && hasFilesWithSameName;

    function onCheckboxClick() {
        dispatch(
            setFilesToExport(
                isChecked ? filesToExport.filter((fileId) => fileId !== props.id) : [...filesToExport, props.id],
            ),
        );
    }

    return (
        <div className={styles.file}>
            <Icon
                type={isChecked ? IconType.CHECKBOX24_CHECKED : IconType.CHECKBOX24_UNCHECKED}
                svgSize={21}
                className={styles.fileCheckbox}
                onClick={onCheckboxClick}
            />

            <div className={styles.commonFile}>
                <CommonFile
                    {...props}
                    // theme={showError ? 'error' : null}
                />
            </div>
        </div>
    );
}
