import * as React from 'react';

import * as style from './FundsInputCell.scss';

import { Input_redesign as Input, InputTheme_redesign as InputTheme, Icon, IconType } from 'sber-marketing-ui';

interface Props {
    title: string;
    placeholder: string;
    customStyle?: any;
    icon?: { type: IconType; color: string; size: number };
    onValueChange: (value: string) => void;
}

export const FundsInputCellEditTemplate = ({
    title,
    placeholder,
    customStyle,
    icon,
    onValueChange,
}: Props): JSX.Element => {
    return (
        <div className={style.root} style={{ ...customStyle }}>
            <div className={style.title}>
                {icon && (
                    <div className={style.titleIcon} style={{ width: icon.size, height: icon.size }}>
                        <Icon type={icon.type} svgSize={icon.size} color={icon.color} />
                    </div>
                )}

                <Input
                    value={title}
                    placeholder={placeholder}
                    theme={InputTheme.Borderless}
                    valueType={'money'}
                    onBlur={(event, value) => onValueChange(value as string)}
                    autofocus
                />

                <div className={style.currencySign}>₽</div>
            </div>
        </div>
    );
};
