import * as React from 'react';

export function usePopup(): [boolean, (show: boolean) => void] {
    const [show, setShow] = React.useState(false);
    const popupListener = React.useCallback(() => setShow(false), []);

    React.useEffect(() => {
        if (show) {
            document.body.addEventListener('click', popupListener);
        } else {
            document.body.removeEventListener('click', popupListener);
        }

        return () => {
            document.body.removeEventListener('click', popupListener);
        };
    }, [show]);

    return [show, setShow];
}
