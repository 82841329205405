import { bindActionCreators } from 'redux';
import { v4 } from 'uuid';
import * as lodash from 'lodash';

import { Brief, BriefScheme, BriefStatus } from 'sber-marketing-types/frontend';
import { BudgetItem } from '@mrm/budget';
import { PageLoader, UpdateSchemesParams } from './types';

import { store } from '@store';
import {
    loadBriefPage,
    applyBriefDraft,
    setCurrentBrief,
    setCurrentBriefWasCopied,
    setBriefSchemes,
} from '@store/brief/actions';
import { add as addFiles } from '@store/fileAssets';
import { BriefApi, FileApi, BudgetItemApi } from '@api';

interface ConstructorParams {
    organizationId: string;
    budgetItemId?: string;
}

export class BudgetItemPageLoader implements PageLoader {
    private static instance: BudgetItemPageLoader;

    private organizationId: string;
    private budgetItemId: string;

    private budgetItem: BudgetItem;

    private brief: Brief;
    private schemes: BriefScheme[] = [];

    private dispatch = bindActionCreators(
        {
            loadBriefPage,
            applyBriefDraft,
            setCurrentBrief,
            setCurrentBriefWasCopied,
            setBriefSchemes,
            addFiles,
        },
        store.dispatch,
    );

    public static getInstance(params: ConstructorParams): BudgetItemPageLoader {
        if (!BudgetItemPageLoader.instance) {
            BudgetItemPageLoader.instance = new BudgetItemPageLoader();
        }

        BudgetItemPageLoader.instance.budgetItemId = params.budgetItemId;
        BudgetItemPageLoader.instance.organizationId = params.organizationId;

        return BudgetItemPageLoader.instance;
    }

    public async init() {
        await this.loadBudgetItem();

        if (this.hasBriefOfBudgetItem) {
            await this.loadBrief();
        } else {
            this.loadStubBrief();
        }

        await this.loadSchemes();
        this.dispatchLoadedDataToStore();
        this.resetLoadedData();
    }

    public async updateSchemes(params: UpdateSchemesParams): Promise<void> {
        this.brief = params.briefs[0];

        await this.loadSchemesFromOrganization();
        await this.loadMissingSchemesInOrganization();

        this.dispatchLoadedSchemesToStore();
        this.resetLoadedData();
    }

    private resetLoadedData(): void {
        this.brief = null;
        this.schemes = [];
        this.budgetItem = null;
    }

    private async loadBudgetItem(): Promise<void> {
        this.budgetItem = await BudgetItemApi.getBudgetItem(this.budgetItemId);
    }

    private async loadBrief(): Promise<void> {
        const brief = await BriefApi.getBrief(this.budgetItem.briefId);
        this.brief = this.formatFilesData(brief);
    }

    private loadStubBrief(): void {
        this.brief = this.createStubBrief();
    }

    private async loadSchemes() {
        await this.loadSchemesFromOrganization();

        if (this.hasBriefOfBudgetItem) {
            await this.loadMissingSchemesInOrganization();
        }
    }

    private async loadSchemesFromOrganization(): Promise<void> {
        this.schemes = await BriefApi.getBriefSchemeList({
            organizationId: this.organizationId,
            name: 'ДУД',
        });
    }

    private async loadMissingSchemesInOrganization() {
        const briefSchemeId = this.brief.schemeId;
        const loadedSchemesIds = this.schemes.map(({ id }) => id);

        const unloadedSchemesId = lodash.difference(lodash.compact([briefSchemeId]), loadedSchemesIds);

        await Promise.all(
            unloadedSchemesId.map(async (schemeId) => {
                const currentScheme = await BriefApi.getBriefSchemeById(schemeId);
                this.schemes.push(currentScheme);
            }),
        );
    }

    private get hasBriefOfBudgetItem(): boolean {
        return !lodash.isNil(this.budgetItem.briefId);
    }

    private formatFilesData(brief: Brief) {
        const updatedBrief = lodash.cloneDeep(brief);

        updatedBrief.blocks.forEach((block) =>
            block.fields.forEach((field) => {
                if (field.value && field.value.files) {
                    const files = FileApi.mapFiles(
                        {
                            fieldId: field.id,
                            briefId: brief.id,
                        },
                        field.value.files as any[],
                        '',
                    ) as any[];
                    field.value.files = files;
                    this.dispatch.addFiles(...files);
                }
            }),
        );

        return updatedBrief;
    }

    private createStubBrief(): Brief {
        return {
            id: v4(),
            status: BriefStatus.Draft,
            schemeId: null,
            blocks: [],
            createTime: null,
            updateTime: null,
            canEdit: true,
        };
    }

    private dispatchLoadedDataToStore(): void {
        this.dispatch.loadBriefPage({
            initialActivity: null,
            changedActivity: null,
            initialTasks: null,
            changedTasks: null,
            budgetItem: this.budgetItem,
            briefs: (this.brief && lodash.keyBy(lodash.cloneDeep([this.brief]), 'id')) || null,
            currentBriefs: (this.brief && lodash.keyBy(lodash.cloneDeep([this.brief]), 'id')) || null,
            briefsLoading: (this.brief && lodash.keyBy([{ id: this.brief.id, loading: false }], 'id')) || null,
            schemes: this.schemes,
            users: [],
        });
    }

    private dispatchLoadedSchemesToStore(): void {
        this.dispatch.setBriefSchemes(this.schemes);
    }
}
