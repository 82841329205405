import { uniq } from 'lodash';
import { CorrectionType, CorrectionStatus } from '@mrm/budget';

export const CorrectionTypeIds = {
    [CorrectionType.PlanFundsTransfer]: 'planTransfer',
    [CorrectionType.ReservedFunds]: 'reserveChange',
    [CorrectionType.FactFunds]: 'factChange',
    [CorrectionType.BudgetItem]: 'budgetItemProfileChange',
    [CorrectionType.Activity]: 'activityBudgetProfileChange',
    [CorrectionType.IncomeExternalPlanFundsTransfer]: 'externalBudgetTransfer',
    [CorrectionType.OutcomeExternalPlanFundsTransfer]: 'externalBudgetTransfer',
};

export const CorrectionFilterName = {
    planTransfer: [CorrectionType.PlanFundsTransfer],
    reserveChange: [CorrectionType.ReservedFunds],
    factChange: [CorrectionType.FactFunds],
    budgetItemProfileChange: [CorrectionType.BudgetItem],
    activityBudgetProfileChange: [CorrectionType.Activity],
    externalBudgetTransfer: [
        CorrectionType.IncomeExternalPlanFundsTransfer,
        CorrectionType.OutcomeExternalPlanFundsTransfer,
    ],
};

export const CorrectionTypeNames = {
    planTransfer: 'Корректировка плана',
    reserveChange: 'Корректировка резерва',
    factChange: 'Корректировка факта',
    budgetItemProfileChange: 'Корректировка параметров строки',
    activityBudgetProfileChange: 'Корректировка параметров активности',
    externalBudgetTransfer: 'Внешняя корректировка плана',
};

export const CorrectionStatusNames = {
    [CorrectionStatus.Approved]: 'Согласована',
    [CorrectionStatus.Rejected]: 'Отклонена',
    [CorrectionStatus.NeedApproving]: 'Ожидает согласования',
};

export const STATUS_FITLER_ITEMS_FOR_EXPORT_MODE = [CorrectionStatus.Approved];
export const TYPE_FILTER_ITEMS_FOR_EXPORT_MODE = uniq([
    CorrectionTypeIds[CorrectionType.PlanFundsTransfer],
    CorrectionTypeIds[CorrectionType.IncomeExternalPlanFundsTransfer],
    CorrectionTypeIds[CorrectionType.OutcomeExternalPlanFundsTransfer],
    CorrectionTypeIds[CorrectionType.BudgetItem],
]);
