import * as moment from 'moment';
import { TaskCardParams, TaskStatus } from 'sber-marketing-types/frontend';

import { LineParams, STAGE_COLORS, TASKS_COLORS, STAGE_TYPE, DateMark, TasksByStageId } from '../types';

import { Scaler, Drawer, StageDrawParams, TaskDrawParams } from '../modules';

export class SceneDrawer {
    private canvas: HTMLCanvasElement;
    private lines: LineParams[];
    private scaler: Scaler;

    constructor() {}

    public addCanvas(canvas: HTMLCanvasElement) {
        this.canvas = canvas;
    }

    public drawScene(
        lines: LineParams[],
        scaler: Scaler,
        hoveredLineIndex: number,
        dateMark: DateMark[],
        tasksByStages: TasksByStageId,
    ) {
        this.lines = lines;
        this.scaler = scaler;

        this.clearScene();
        this.drawLinesBackrgounds(hoveredLineIndex);
        dateMark.forEach((dateMark) => {
            if (dateMark.isVisible) {
                this.drawDateLine(dateMark.date, dateMark.color);
            }
        });
        this.drawHorizontalLines();
        this.drawLines(tasksByStages);
    }

    private clearScene() {
        Drawer.clear(this.canvas);
    }

    private drawLinesBackrgounds(hoveredLineIndex: number) {
        if (this.canvas) {
            this.lines.forEach((line, lineIndex) => {
                if (line.type) {
                    const lineIsHovered = lineIndex == hoveredLineIndex;
                    const lineColor = STAGE_COLORS[line.type];

                    const backgroundColor = lineIsHovered ? lineColor.hover : lineColor.normal;

                    if (backgroundColor) {
                        Drawer.drawLineBackground(this.canvas, lineIndex, {
                            color: backgroundColor,
                            roundedTop: line.roundedTop,
                            roundedBottom: line.roundedBottom,
                        });
                    }
                }
            });
        }
    }

    private drawHorizontalLines() {
        if (this.canvas) {
            this.lines.forEach((line, lineIndex) => {
                const color =
                    line.type == STAGE_TYPE.FINISHED || line.type == STAGE_TYPE.EXPIRED
                        ? STAGE_COLORS[line.type].bar
                        : null;

                Drawer.drawHorizontalLine(this.canvas, lineIndex, color);
            });
        }
    }

    private drawDateLine(date: Date, color: string) {
        if (this.canvas) {
            const dateX = this.scaler.convertDateToPx(date);
            const canvasWidth = this.scaler.getWidthInPx();

            if (dateX >= 0 && dateX <= canvasWidth) {
                Drawer.drawDayLine(this.canvas, dateX, color);
            }
        }
    }

    private drawLines(tasksByStages: TasksByStageId) {
        if (this.canvas) {
            this.lines.forEach((line, lineIndex) => {
                if (line.type) {
                    const stageDrawParams = this.makeStageDrawParams(line, lineIndex);

                    Drawer.drawStage(this.canvas, stageDrawParams);

                    (tasksByStages[line.id] || []).forEach((task) =>
                        Drawer.drawTask(this.canvas, this.makeTaskDrawParams(lineIndex, line, task)),
                    );
                }
            });
        }
    }

    private makeStageDrawParams(line: LineParams, lineIndex: number): StageDrawParams {
        return {
            lineIndex,
            start: this.scaler.convertDateToPx(this.getMiddleOfDay(line.start)),
            end: this.scaler.convertDateToPx(this.getMiddleOfDay(line.end)),
            color: STAGE_COLORS[line.type],
        };
    }

    private makeTaskDrawParams(lineIndex: number, line: LineParams, task: TaskCardParams): TaskDrawParams {
        const deadline = task.deadline instanceof Date ? task.deadline : new Date(task.deadline);

        let color: TASKS_COLORS;

        if (task.status === TaskStatus.Closed) {
            color = TASKS_COLORS.BLUE;
        } else if (task.status === TaskStatus.InProgress && deadline < new Date()) {
            color = TASKS_COLORS.RED;
        } else if (deadline > line.start && deadline < line.end && task.status !== TaskStatus.Draft) {
            color = TASKS_COLORS.GREEN;
        } else {
            color = TASKS_COLORS.GREY;
        }

        return {
            lineIndex,
            position: this.scaler.convertDateToPx(this.getStartOfDay(deadline)),
            color,
        };
    }

    private getMiddleOfDay(date: Date): Date {
        return moment(date).add(12, 'hours').toDate();
    }

    private getStartOfDay(date: Date): Date {
        return moment(date).set('hours', 12).toDate();
    }
}
