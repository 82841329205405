import * as React from 'react';
import * as lodash from 'lodash';
import classNames from 'classnames';

import * as style from './Slider.scss';

import { useDrag } from './hooks';

const DEFAULT_COLOR = '#e3e9e5';

interface Props {
    value: number;
    minValue: number;
    maxValue: number;
    dotsCount: number;
    leftColor: string;
    rightColor: string;
    disabled?: boolean;
    onValueChange: (value: number) => void;
}

export const Slider = ({
    value,
    minValue,
    maxValue,
    dotsCount,
    leftColor,
    rightColor,
    disabled,
    onValueChange,
}: Props): JSX.Element => {
    const { scaleRef, pointerPosition, onPointerMouseDown, onScaleClick } = useDrag({
        value,
        minValue,
        maxValue,
        onValueChange,
    });

    return (
        <div className={classNames(style.root, disabled && style.disabled)}>
            <div
                ref={scaleRef}
                className={style.scale}
                style={{
                    background: `linear-gradient(to right, ${leftColor || DEFAULT_COLOR} ${pointerPosition}%, ${
                        rightColor || DEFAULT_COLOR
                    } ${pointerPosition}%)`,
                }}
                onClick={!disabled ? onScaleClick : null}
            >
                {lodash.times(dotsCount, (index) => (
                    <div className={style.dotWrapper} key={index}>
                        <div
                            className={style.dot}
                            style={{
                                background:
                                    (getDotPosition(dotsCount, index) < pointerPosition ? leftColor : rightColor) ||
                                    DEFAULT_COLOR,
                            }}
                        />
                    </div>
                ))}

                <div
                    className={style.pointer}
                    style={{
                        left: `${pointerPosition}%`,
                        background: `linear-gradient(to right, ${leftColor || rightColor} 0%, ${
                            rightColor || leftColor
                        } 100%)`,
                    }}
                    onMouseDown={!disabled ? onPointerMouseDown : null}
                />
            </div>
        </div>
    );
};

function getDotPosition(dotsCount: number, index: number): number {
    if (index === 0) {
        return 0;
    }

    const segmentsCount = dotsCount - 1;
    const segmentWidth = 100 / segmentsCount;

    return index * segmentWidth;
}
