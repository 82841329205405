import * as React from 'react';
import * as lodash from 'lodash';

import type { CreativeRequestDomain } from './types';
import type { CreativeRequestItem as CreativeRequestItemDomain } from '@api';

interface UseCreativeRequestItemDomains {
    (params: UseCreativeRequestItemDomainParams): UseCreativeRequestItemDomainReturn;
}

interface UseCreativeRequestItemDomainParams {
    creativeRequestDomain: CreativeRequestDomain;
}

interface UseCreativeRequestItemDomainReturn {
    creativeRequestItemDomains: CreativeRequestItemDomain[];
}

interface LoadCreativeRequestItemDomains {
    ({ creativeRequestDomain }: { creativeRequestDomain: CreativeRequestDomain }): Promise<void>;
}

interface RequestCreativeRequestItemDomains {
    ({ creativeRequestDomain }: { creativeRequestDomain: CreativeRequestDomain }): Promise<CreativeRequestItemDomain[]>;
}

interface OnItemsAddedHandler {
    (params: CreativeRequestItemDomain): void;
}

export const useCreativeRequestItemDomains: UseCreativeRequestItemDomains = ({ creativeRequestDomain }) => {
    const [creativeRequestItemDomains, setCreativeRequestItemDomains] = React.useState<CreativeRequestItemDomain[]>([]);
    const [_, setVersion] = React.useState<number>(0);

    const onItemsAddedHandler: OnItemsAddedHandler = React.useCallback((addedCreativeRequestItemDomain) => {
        setCreativeRequestItemDomains((creativeRequestItemDomains) => [
            ...creativeRequestItemDomains,
            addedCreativeRequestItemDomain,
        ]);
    }, []);

    const onItemsUpdatedHandler = React.useCallback(() => {
        setVersion((version) => version + 1);
    }, []);

    function onItemReloadedHandler(reloadedCreativeRequestItemDomain: CreativeRequestItemDomain) {
        setCreativeRequestItemDomains((creativeRequestItems) => {
            const oldItem = creativeRequestItems.find(
                (item) => item.model.id === reloadedCreativeRequestItemDomain.model.id,
            );

            oldItem.events.offDonorsUpdated(onItemsUpdatedHandler);
            oldItem.events.offActualCostWithoutVatUpdated(onItemsUpdatedHandler);
            oldItem.events.offCommissionWithoutVatUpdated(onItemsUpdatedHandler);
            oldItem.events.offReloaded(onItemsUpdatedHandler);

            reloadedCreativeRequestItemDomain.events.onDonorsUpdated(onItemsUpdatedHandler);
            reloadedCreativeRequestItemDomain.events.onActualCostWithoutVatUpdated(onItemsUpdatedHandler);
            reloadedCreativeRequestItemDomain.events.onCommissionWithoutVatUpdated(onItemsUpdatedHandler);
            reloadedCreativeRequestItemDomain.events.onReloaded(onItemReloadedHandler);

            const updatedItems = lodash.without(creativeRequestItems, oldItem);
            updatedItems.push(reloadedCreativeRequestItemDomain);

            return updatedItems;
        });
    }

    const requestCreativeRequestItemDomains: RequestCreativeRequestItemDomains = React.useCallback(
        async ({ creativeRequestDomain }) => {
            try {
                return await creativeRequestDomain.model.getItems();
            } catch (error) {
                console.error(error);
                return [];
            }
        },
        [],
    );

    const loadCreativeRequestItemDomains: LoadCreativeRequestItemDomains = React.useCallback(async (params) => {
        const items = await requestCreativeRequestItemDomains(params);

        items.forEach(({ events }) => {
            events.onDonorsUpdated(onItemsUpdatedHandler);
            events.onActualCostWithoutVatUpdated(onItemsUpdatedHandler);
            events.onCommissionWithoutVatUpdated(onItemsUpdatedHandler);
            events.onReloaded(onItemReloadedHandler);
        });

        setCreativeRequestItemDomains(items);
    }, []);

    React.useEffect(() => {
        if (creativeRequestDomain) {
            loadCreativeRequestItemDomains({ creativeRequestDomain });
            creativeRequestDomain.events.onItemsAdded(onItemsAddedHandler);
        }
        return () => {
            creativeRequestDomain?.events?.offItemsAdded(onItemsAddedHandler);
        };
    }, [creativeRequestDomain]);

    return {
        creativeRequestItemDomains,
    };
};

export { CreativeRequestItemDomain };
