import * as React from 'react';
import classnames from 'classnames';

import * as styles from './ReactionForm.scss';

interface Props {
    formIsActive: boolean;
    className: string;
    children: React.ReactNode;
    withoutAnimation?: boolean;
}

export function ReactionForm({ formIsActive, className, children, withoutAnimation }: Props): JSX.Element {
    const [activeClassName, setActiveClassName] = React.useState<string>(null);

    React.useEffect(
        function updateActiveClassName() {
            setActiveClassName(formIsActive ? classnames(styles.reactionFormActiveVisible, className) : null);
        },
        [formIsActive],
    );

    return (
        <div
            className={classnames(
                styles.reactionForm,
                formIsActive && [styles.reactionFormActive, activeClassName],
                formIsActive && !withoutAnimation && styles.reactionFormActiveWithAnimation,
            )}
        >
            {children}
        </div>
    );
}
