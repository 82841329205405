// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-leaderDashboard-LeaderTasksDashboard-common-IntervalProgress-Value-_styles__root{display:inline-grid;grid-auto-flow:column;grid-auto-columns:max-content;grid-column-gap:3px;justify-items:center;font-family:\"Open Sans\";font-size:12px;letter-spacing:0.2px;margin-left:4px;margin-right:4px}.src-client-modules-leaderDashboard-LeaderTasksDashboard-common-IntervalProgress-Value-_styles__positive{color:#00b957}.src-client-modules-leaderDashboard-LeaderTasksDashboard-common-IntervalProgress-Value-_styles__negative{color:red}.src-client-modules-leaderDashboard-LeaderTasksDashboard-common-IntervalProgress-Value-_styles__neutral{color:#7b7b7b}\n", "",{"version":3,"sources":["webpack://./src/client/modules/leaderDashboard/LeaderTasksDashboard/common/IntervalProgress/Value/styles.scss"],"names":[],"mappings":"AAAA,qGACI,mBAAoB,CACpB,qBAAsB,CACtB,6BAA8B,CAC9B,mBAAoB,CACpB,oBAAqB,CAErB,uBAAwB,CACxB,cAAe,CACf,oBAAqB,CAErB,eAAgB,CAChB,gBAAiB,CACpB,yGAGG,aAAc,CACjB,yGAGG,SAAU,CACb,wGAGG,aAAc","sourcesContent":[".root {\n    display: inline-grid;\n    grid-auto-flow: column;\n    grid-auto-columns: max-content;\n    grid-column-gap: 3px;\n    justify-items: center;\n\n    font-family: \"Open Sans\";\n    font-size: 12px;\n    letter-spacing: 0.2px;\n\n    margin-left: 4px;\n    margin-right: 4px;\n}\n\n.positive {\n    color: #00b957;\n}\n\n.negative {\n    color: red;\n}\n\n.neutral {\n    color: #7b7b7b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-leaderDashboard-LeaderTasksDashboard-common-IntervalProgress-Value-_styles__root",
	"positive": "src-client-modules-leaderDashboard-LeaderTasksDashboard-common-IntervalProgress-Value-_styles__positive",
	"negative": "src-client-modules-leaderDashboard-LeaderTasksDashboard-common-IntervalProgress-Value-_styles__negative",
	"neutral": "src-client-modules-leaderDashboard-LeaderTasksDashboard-common-IntervalProgress-Value-_styles__neutral"
};
export default ___CSS_LOADER_EXPORT___;
