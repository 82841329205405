import { Month } from '@mrm/budget';
import { Money, MoneyFormatter } from '@common/Utils';

import * as moment from 'moment';
import * as lodash from 'lodash';

export const orderOfMonth = [
    Month.Jan,
    Month.Feb,
    Month.Mar,
    Month.Apr,
    Month.May,
    Month.Jun,
    Month.Jul,
    Month.Aug,
    Month.Sept,
    Month.Oct,
    Month.Nov,
    Month.Dec,
];

export const monthIndexMap: Record<Month, number> = {
    [Month.Jan]: 0,
    [Month.Feb]: 1,
    [Month.Mar]: 2,
    [Month.Apr]: 3,
    [Month.May]: 4,
    [Month.Jun]: 5,
    [Month.Jul]: 6,
    [Month.Aug]: 7,
    [Month.Sept]: 8,
    [Month.Oct]: 9,
    [Month.Nov]: 10,
    [Month.Dec]: 11,
};

export const formatMoney = (money: number): string => {
    return MoneyFormatter.toThousands(Money.fromCopecks(money), { hideCaption: true });
};

export const formatMoneyForTooltip = (money: number): string => {
    return MoneyFormatter.toRoubles(Money.fromCopecks(money), { hideCaption: true });
};

export const formatMoneyForCorrectionValue = (money: number): string => {
    return MoneyFormatter.toThousands(Money.fromCopecks(money), { forceSign: true, hideCaption: true });
};

export const formatMoneyForCorrectionValueTooltip = (money: number): string => {
    return MoneyFormatter.toRoubles(Money.fromCopecks(money), { forceSign: true, hideCaption: true });
};

export const getMonthName = (month: Month): string => {
    moment.locale('ru');
    const monthName = moment.months()[monthIndexMap[month]];

    return lodash.capitalize(monthName);
};
