import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { DivisionApi } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getLoginUser } from '@store/user';

import * as actions from './actions';
import { LoadDivisionsParams } from './types';
import { getLoadingStatus, getDivisionById, getFetchersCount } from './selectors';

export const loadDivisions = bindThunkAction<StoreState, LoadDivisionsParams, void, Error>(
    actions.loadDivisionsAsync,
    async (params, dispatch, getState) => {
        const { store } = params;
        let { ids } = params;

        const setStatus: (status: LoadingStatus) => void = (status) =>
            dispatch(
                actions.setLoadingStatus({
                    store,
                    status,
                }),
            );

        const state = getState();
        const loadingStatus = getLoadingStatus(state, store);
        const loginnedUser = getLoginUser(state);

        try {
            let divisions;

            if (ids) {
                const notLoadedDivisionIds = ids.filter((id) => !getDivisionById(state, id));

                if (notLoadedDivisionIds.length) {
                    dispatch(actions.incFetchersCount(store));
                    setStatus(LoadingStatus.LOADING);
                    divisions = await DivisionApi.getAllDivisions();
                }
            } else {
                if (loadingStatus !== LoadingStatus.LOADED) {
                    dispatch(actions.incFetchersCount(store));
                    setStatus(LoadingStatus.LOADING);
                    divisions = await DivisionApi.getDivisions({
                        organizationId: loginnedUser.attributes.organizationId,
                    });

                    ids = divisions.map((division) => division.id);
                }
            }

            if (divisions) {
                dispatch(actions.decFetchersCount(store));
                dispatch(actions.loadEntities(divisions));
            }
            if (ids) {
                dispatch(
                    actions.setStoreIds({
                        store,
                        ids,
                    }),
                );
            }

            const fetchersCount = getFetchersCount(getState(), store);
            if (!fetchersCount) {
                setStatus(LoadingStatus.LOADED);
            }
        } catch (e) {
            setStatus(LoadingStatus.ERROR);
        }
    },
);
