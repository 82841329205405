import * as React from 'react';

import * as style from './Header.scss';

import { ActivityParams as Activity } from 'sber-marketing-types/frontend';

import { ResponsiveLayout, RightSidebar, LeftSidebar, CentralContent } from 'sber-marketing-ui';
import { ActivityTitle } from './ActivityTitle';

interface Props {
    activity: Activity;
    rightContent?: JSX.Element;
    onSwitchKeyButtonClick(): void;
}

export function ActivityPageHeader({ activity, rightContent, onSwitchKeyButtonClick }: Props): JSX.Element {
    return (
        <div className={style.root}>
            <ResponsiveLayout>
                <LeftSidebar />

                <CentralContent>
                    <ActivityTitle activity={activity} onSwitchKeyButtonClick={onSwitchKeyButtonClick} />
                </CentralContent>

                <RightSidebar>{rightContent || []}</RightSidebar>
            </ResponsiveLayout>
        </div>
    );
}
