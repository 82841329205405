import * as React from 'react';
import type { UserCard, UsersCardQuery } from 'sber-marketing-types/frontend';

import { useGetUserCardsQuery, UserListFilters } from '@api';

import { OptionProps } from '@common/components';
import { useAuthUserOrganizationIds } from '@modules/auth/hooks';

export interface UseUserOptions extends UsersCardQuery, UserListFilters {}

export function useUserOptions({
    organizationIds: ids,
    ...options
}: UseUserOptions): [OptionProps<UserCard>[], boolean] {
    const { isLoading: isOrganizationsLoading, organizationIds = ids } = useAuthUserOrganizationIds(Boolean(ids));

    const { isLoading: isUsersLoading, data: users } = useGetUserCardsQuery(
        { organizationIds, ...options },
        { skip: !organizationIds.length },
    );
    const isLoading = isUsersLoading || isOrganizationsLoading;

    return [
        React.useMemo<OptionProps<UserCard>[]>(
            () =>
                users?.users.map((value) => ({
                    value,
                    title: `${value.secondName} ${value.firstName}`,
                    subtitle: value.department,
                })) || [],
            [users, isLoading],
        ),
        isLoading,
    ];
}
