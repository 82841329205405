import * as React from 'react';
import { CustomScrollbar_new as CustomScrollbar } from 'sber-marketing-ui';

import * as styles from './ActivitySuggest.scss';

import { ChildrenProps } from './ActivitySuggestConnected';

import { ExistingNameItem, ExistingNameItemStub } from './ExistingNameItem';
import { SuggestTooltip } from '../SuggestTooltip';

interface Props extends ChildrenProps {
    isNameInputFocused: boolean;
    hoveredItemId: string;
    budgetId: string;
    onMouseOver: (activityBudgetId: string) => void;
}

export function ActivitySuggestTemplate({
    loading,
    budgetItems,
    isNameInputFocused,
    hoveredItemId,
    budgetId,
    onMouseOver,
}: Props): JSX.Element {
    const renderContent = isNameInputFocused && (budgetItems.length || loading);

    return renderContent ? (
        <div className={styles.root}>
            <CustomScrollbar minHeight={46} maxHeight={245} freezeScrollX hideScrollX>
                <div className={styles.existingNamesWrapper}>
                    {loading && (
                        <div className={styles.stubWrapper}>
                            <ExistingNameItemStub />
                        </div>
                    )}

                    {budgetItems.map((budgetItem) => (
                        <ExistingNameItem
                            key={`${budgetItem.budget.year}-${budgetItem.id}-${budgetItem.activity.id}`}
                            {...budgetItem}
                            onMouseOver={onMouseOver}
                        />
                    ))}
                </div>
            </CustomScrollbar>

            {hoveredItemId && (
                <div className={styles.tooltip}>
                    <SuggestTooltip activityBudgetId={hoveredItemId} budgetId={budgetId} />
                </div>
            )}
        </div>
    ) : null;
}
