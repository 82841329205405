import * as React from 'react';
import { Provider } from 'mobx-react';
import { OpacityTransition, ResponsiveLayout, CentralContent, LeftSidebar, RightSidebar } from 'sber-marketing-ui';

import { QuickAccessIcons } from '@common/QuickAccessIcons';

import { TmTable } from './table/TmTable';
import { TmStore } from './store/TmStore';

import * as style from './TmRegistryPage.scss';

interface Props {
    preloader: boolean;
    shouldRenderPage: boolean;
    tmStore: TmStore;
}

export const TmRegistryPage = ({ preloader, shouldRenderPage, tmStore }: Props): JSX.Element => {
    return (
        <Provider tms={tmStore}>
            <div className={style.root}>
                <OpacityTransition start={!preloader && shouldRenderPage}>
                    <TmTable />
                </OpacityTransition>
            </div>
        </Provider>
    );
};

export function TMRegistryPageHeader(): JSX.Element {
    return (
        <ResponsiveLayout>
            <LeftSidebar />

            <CentralContent>
                <div className={style.headerTitle}>Реестр ТЗ</div>
            </CentralContent>

            <RightSidebar>
                <div className={style.quickAccessIcons}>
                    <QuickAccessIcons />
                </div>
            </RightSidebar>
        </ResponsiveLayout>
    );
}
