// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-CountChanges-_CountChanges__text{letter-spacing:0.2px;color:#7e8681;font-size:12px;font-weight:600}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetCorrections/CorrectionsList/CardTypes/common/CountChanges/CountChanges.scss"],"names":[],"mappings":"AAAA,0HACI,oBAAqB,CAErB,aAAc,CAEd,cAAe,CACf,eAAgB","sourcesContent":[".text {\n    letter-spacing: 0.2px;\n\n    color: #7e8681;\n\n    font-size: 12px;\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-CountChanges-_CountChanges__text"
};
export default ___CSS_LOADER_EXPORT___;
