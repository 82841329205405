type ListenerRecord<T> = { id: string; callback: (value: T) => void };

export class Observer<T> {
    protected currentValue: T;
    protected registrationList: ListenerRecord<T>[] = [];

    public register(id: string, callback: (value: T) => void) {
        const alreadyRegistered = this.registrationList.some((item) => item.id == id);

        if (alreadyRegistered) {
            this.unregister(id);
        }

        this.registrationList.push({ id, callback });
    }

    public unregister(id: string) {
        this.registrationList = this.registrationList.filter((item) => item.id !== id);
    }

    public setValue(id: string, value: T) {
        if (value !== this.currentValue) {
            this.currentValue = value;

            this.registrationList.filter((item) => item.id !== id).forEach((item) => item.callback(value));
        }
    }
}
