import * as React from 'react';

import { Month } from '@mrm/budget/common';
import { OutcomeExternalPlanFundsTransferCorrection, CardBudgetItem } from '../types';
import { getMonthName, orderOfMonth } from '../utils';

import { TableWithOutcomeExternalCorrection, FundCell, FundRow } from './TableWithOutcomeExternalCorrection';

interface Props {
    budgetItem: CardBudgetItem;
    correction: OutcomeExternalPlanFundsTransferCorrection;
}

export class TableWithOutcomeExternalCorrectionContainer extends React.Component<Props> {
    public render() {
        return React.createElement(TableWithOutcomeExternalCorrection, {
            fundsRows: this.buildFoundsRows(),
        });
    }

    private buildFoundsRows(): FundRow[] {
        const { reservedFunds, factFunds } = this.props.budgetItem;

        return orderOfMonth.map((month) => ({
            name: getMonthName(month),
            planned: this.buildPlanFundCell(month),
            reserve: reservedFunds[month] || 0,
            fact: factFunds[month] || 0,
        }));
    }

    private buildPlanFundCell(month: Month): FundCell {
        const { plannedFunds } = this.props.budgetItem;

        return {
            currentValue: plannedFunds[month] || 0,
            correctionValue: this.getPlanCorrectionValueByMonth(month),
        };
    }

    private getPlanCorrectionValueByMonth(month: Month): number {
        const {
            params: { donorMonth, value },
        } = this.props.correction;

        if (donorMonth === month) {
            return -value;
        }

        return 0;
    }
}
