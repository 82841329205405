// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-FiltersColumnsTagsBar-ColumnsFiltersDropdown-_styles__root{display:grid;grid-auto-flow:row;grid-auto-columns:max-content;grid-row-gap:5px;justify-items:center}.src-client-modules-budget-BudgetPage-BudgetExecution-FiltersColumnsTagsBar-ColumnsFiltersDropdown-_styles__label{font-family:\"Open Sans\";font-size:12px;letter-spacing:0.1px;color:#417EC6;cursor:pointer;transition:75ms ease-out}.src-client-modules-budget-BudgetPage-BudgetExecution-FiltersColumnsTagsBar-ColumnsFiltersDropdown-_styles__label:hover{color:#0058BF}.src-client-modules-budget-BudgetPage-BudgetExecution-FiltersColumnsTagsBar-ColumnsFiltersDropdown-_styles__dropdownWrapper{display:grid;grid-auto-flow:column;grid-auto-columns:max-content;grid-column-gap:5px;font-family:\"Open Sans\";font-size:14px;color:#7E8681}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/FiltersColumnsTagsBar/ColumnsFiltersDropdown/styles.scss"],"names":[],"mappings":"AAAA,iHACI,YAAa,CACb,kBAAmB,CACnB,6BAA8B,CAC9B,gBAAiB,CACjB,oBAAqB,CACxB,kHAGG,uBAAwB,CACxB,cAAe,CACf,oBAAqB,CACrB,aAAc,CAEd,cAAe,CACf,wBAAyB,CAP7B,wHAUQ,aAAc,CACjB,4HAID,YAAa,CACb,qBAAsB,CACtB,6BAA8B,CAC9B,mBAAoB,CAEpB,uBAAwB,CACxB,cAAe,CAEf,aAAc","sourcesContent":[".root {\n    display: grid;\n    grid-auto-flow: row;\n    grid-auto-columns: max-content;\n    grid-row-gap: 5px;\n    justify-items: center;\n}\n\n.label {\n    font-family: \"Open Sans\";\n    font-size: 12px;\n    letter-spacing: 0.1px;\n    color: #417EC6;\n\n    cursor: pointer;\n    transition: 75ms ease-out;\n\n    &:hover {\n        color: #0058BF;\n    }\n}\n\n.dropdownWrapper {\n    display: grid;\n    grid-auto-flow: column;\n    grid-auto-columns: max-content;\n    grid-column-gap: 5px;\n\n    font-family: \"Open Sans\";\n    font-size: 14px;\n\n    color: #7E8681;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-FiltersColumnsTagsBar-ColumnsFiltersDropdown-_styles__root",
	"label": "src-client-modules-budget-BudgetPage-BudgetExecution-FiltersColumnsTagsBar-ColumnsFiltersDropdown-_styles__label",
	"dropdownWrapper": "src-client-modules-budget-BudgetPage-BudgetExecution-FiltersColumnsTagsBar-ColumnsFiltersDropdown-_styles__dropdownWrapper"
};
export default ___CSS_LOADER_EXPORT___;
