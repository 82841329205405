import * as React from 'react';

import * as style from './MediaplanStageWidget.scss';

import { CampaignPerformanceTable } from './CampaignPerformanceTable';
import { MediaplanBudgetTable } from './MediaplanBudgetTable';
import { XLSBuilder } from './XLSBuilder';

interface Props {}

export const MediaplanStageWidgetTemplate = ({}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.downloadButton}>
                <XLSBuilder />
            </div>

            <div className={style.table}>
                <div className={style.title}>Сводная информация по эффективности кампании</div>

                <CampaignPerformanceTable />
            </div>

            <div className={style.table}>
                <div className={style.title}>Полная информация по медиаплану</div>

                <MediaplanBudgetTable />
            </div>
        </div>
    );
};
