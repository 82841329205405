import * as React from 'react';
import * as lodash from 'lodash';
import classNames from 'classnames';

import * as style from './BudgetStageWidget.scss';

import { Preloader, Icon, IconType } from 'sber-marketing-ui';
import { CardGroup } from './CardGroup';
import { BudgetCard, CardProps } from './BudgetCard';

interface Props {
    readOnly: boolean;
    loading: boolean;
    cards: CardProps[];
    selectedBudgetItemIds: string[];
    onAddButtonClick: () => void;
}

export const BudgetStageWidgetTemplate = ({
    readOnly,
    loading,
    cards,
    selectedBudgetItemIds,
    onAddButtonClick,
}: Props): JSX.Element => {
    return (
        <div className={classNames(style.root, style.readOnly)}>
            <div className={style.topLine}>
                <div className={style.title}>
                    ID бюджета
                    <div className={classNames(style.counter, lodash.isEmpty(selectedBudgetItemIds) && style.empty)}>
                        {selectedBudgetItemIds.length}
                    </div>
                </div>

                {!readOnly && (
                    <div className={style.addButton} onClick={onAddButtonClick}>
                        <div className={style.addButtonIcon}>
                            <Icon type={IconType.PLUS_24} svgSize={14} />
                        </div>
                        Привязать
                    </div>
                )}
            </div>

            {!loading && (
                <div className={style.list}>
                    {!lodash.isEmpty(cards) && (
                        <CardGroup
                            cards={cards.map((item) => (
                                <BudgetCard {...item} key={`${item.serialNumber} ${item.budgetYear}`} />
                            ))}
                            minCardWidth={290}
                            cardsMargin={16}
                        />
                    )}

                    {lodash.isEmpty(cards) && <div className={style.emptyListMessage}>Нет привязанных ID бюджета</div>}
                </div>
            )}

            {loading && (
                <div className={style.preloader}>
                    <Preloader />
                </div>
            )}
        </div>
    );
};
