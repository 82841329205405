import * as React from 'react';
import { get, uniqBy } from 'lodash';
import { DataProps, graphql } from 'react-apollo';

import { GET_BUDGET_ITEMS_BY_ACTIVITY_NAME_QUERY } from './queries';

export interface BudgetItemProps {
    id: string;
    activity: {
        id: string;
        name: string;
    };
    budget: {
        id: string;
        year: number;
    };
}

export interface ChildrenProps {
    loading: boolean;
    budgetItems: BudgetItemProps[];
}

interface Props extends ExternalProps, QueryProps {}

interface ExternalProps {
    activityName: string;
    budgetId: string;
    children: (props: ChildrenProps) => JSX.Element;
}

type QueryProps = DataProps<{
    budgetItems: {
        nodes: BudgetItemProps[];
    };
}>;

class ActivitySuggestConnectedWrapper extends React.PureComponent<Props> {
    public render(): JSX.Element {
        return this.props.children(this.getChildrenProps());
    }

    private getChildrenProps(): ChildrenProps {
        const budgetItems: BudgetItemProps[] = uniqBy(
            get(this.props, 'data.budgetItems.nodes') || [],
            (budgetItem) => budgetItem.activity.id,
        );
        const loading = !!get(this.props, 'data.loading');

        return {
            loading,
            budgetItems,
        };
    }
}

export const ActivitySuggestConnected = graphql<ExternalProps>(GET_BUDGET_ITEMS_BY_ACTIVITY_NAME_QUERY, {
    options: ({ activityName, budgetId }) => ({
        variables: { activityName, budgetId },
        fetchPolicy: 'no-cache',
    }),
    skip: ({ activityName, budgetId }) => !activityName || !budgetId,
})(ActivitySuggestConnectedWrapper);
