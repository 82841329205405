import * as React from 'react';

import { Direction } from '../types';

import { Tooltip } from './Tooltip';

interface Props {
    title: string;
    direction?: Direction;
}

export class TooltipContainer extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        return React.createElement(Tooltip, {
            title: this.props.title,
            direction: this.props.direction || Direction.Top,
        });
    }
}
