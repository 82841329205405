import * as React from 'react';

import { FundsActionsTemplate } from './FundsActionsTemplate';

interface Props {}

export class FundsActionsBehaviour extends React.PureComponent<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        return React.createElement(FundsActionsTemplate, {});
    }
}
