import gql from 'graphql-tag';

export const GET_CORRECTIONS_QUERY = gql`
    query Corrections($limit: Int, $filter: CorrectionFilter) {
        allCorrections: getCorrections(filter: $filter) {
            nodes {
                id
            }
        }

        corrections: getCorrections(limit: $limit, filter: $filter) {
            nodes {
                id
                type
                status
                serialNumber
                creationTime
                comment
                overwrittenSerialNumber
                rejectedSerialNumber
                params
                author {
                    firstName
                    secondName
                }
                expert {
                    firstName
                    secondName
                }
                approver {
                    firstName
                    secondName
                }
                activityCurrent {
                    id
                    name
                }
                activityBefore {
                    id
                    name
                }
                budgetItemCurrent {
                    id
                    serialNumber
                    donors
                    realizationStart
                    realizationEnd
                    businessTarget
                    responsibles {
                        firstName
                        secondName
                    }
                    customerName
                    comment
                    expertComment
                    sapComment
                    sapNumber
                    sapZns
                    previousFunds
                    dictionary {
                        activity_type {
                            id
                            value
                            status
                        }
                        block {
                            id
                            value
                            status
                        }
                        channel {
                            id
                            value
                            status
                        }
                        cost_center {
                            id
                            value
                            status
                        }
                        cost_direction {
                            id
                            value
                            status
                        }
                        direction {
                            id
                            value
                            status
                        }
                        division {
                            id
                            value
                            status
                        }
                        functional_block {
                            id
                            value
                            status
                        }
                        ifkv {
                            id
                            value
                            status
                        }
                        item {
                            id
                            value
                            status
                        }
                        location_driver {
                            id
                            value
                            status
                        }
                        objective {
                            id
                            value
                            status
                        }
                        product {
                            id
                            value
                            status
                        }
                        regionality {
                            id
                            value
                            status
                        }
                        resource {
                            id
                            value
                            status
                        }
                        resource_usage {
                            id
                            value
                            status
                        }
                        segment {
                            id
                            value
                            status
                        }
                        subcategory {
                            id
                            value
                            status
                        }
                        territory {
                            id
                            value
                            status
                        }
                        tool {
                            id
                            value
                            status
                        }
                    }
                    plannedFunds {
                        jan
                        feb
                        mar
                        apr
                        may
                        jun
                        jul
                        aug
                        sept
                        oct
                        nov
                        dec
                    }
                    reservedFunds {
                        jan
                        feb
                        mar
                        apr
                        may
                        jun
                        jul
                        aug
                        sept
                        oct
                        nov
                        dec
                    }
                    factFunds {
                        jan
                        feb
                        mar
                        apr
                        may
                        jun
                        jul
                        aug
                        sept
                        oct
                        nov
                        dec
                    }
                }
                budgetItemBefore {
                    id
                    serialNumber
                    donors
                    realizationStart
                    realizationEnd
                    businessTarget
                    responsibles {
                        firstName
                        secondName
                    }
                    customerName
                    comment
                    expertComment
                    sapComment
                    sapNumber
                    sapZns
                    previousFunds
                    dictionary {
                        activity_type {
                            id
                            value
                            status
                        }
                        block {
                            id
                            value
                            status
                        }
                        channel {
                            id
                            value
                            status
                        }
                        cost_center {
                            id
                            value
                            status
                        }
                        cost_direction {
                            id
                            value
                            status
                        }
                        direction {
                            id
                            value
                            status
                        }
                        division {
                            id
                            value
                            status
                        }
                        functional_block {
                            id
                            value
                            status
                        }
                        ifkv {
                            id
                            value
                            status
                        }
                        item {
                            id
                            value
                            status
                        }
                        location_driver {
                            id
                            value
                            status
                        }
                        objective {
                            id
                            value
                            status
                        }
                        product {
                            id
                            value
                            status
                        }
                        regionality {
                            id
                            value
                            status
                        }
                        resource {
                            id
                            value
                            status
                        }
                        resource_usage {
                            id
                            value
                            status
                        }
                        segment {
                            id
                            value
                            status
                        }
                        subcategory {
                            id
                            value
                            status
                        }
                        territory {
                            id
                            value
                            status
                        }
                        tool {
                            id
                            value
                            status
                        }
                    }
                    plannedFunds {
                        jan
                        feb
                        mar
                        apr
                        may
                        jun
                        jul
                        aug
                        sept
                        oct
                        nov
                        dec
                    }
                    reservedFunds {
                        jan
                        feb
                        mar
                        apr
                        may
                        jun
                        jul
                        aug
                        sept
                        oct
                        nov
                        dec
                    }
                    factFunds {
                        jan
                        feb
                        mar
                        apr
                        may
                        jun
                        jul
                        aug
                        sept
                        oct
                        nov
                        dec
                    }
                }
            }
        }
    }
`;
