import * as React from 'react';
import classnames from 'classnames';
import { Icon, IconType } from 'sber-marketing-ui';

import * as styles from './Item.scss';

interface Props {
    qaId: string;
    title: string;
    content: string | JSX.Element;
    icon?: IconType;
    iconClassName?: string;
}

export function Item({ qaId, title, content, icon, iconClassName }: Props): JSX.Element {
    return (
        <div
            {...{
                'qa-id': qaId,
            }}
        >
            <div className={styles.title}>
                {icon && <Icon type={icon} svgSize={16} className={classnames(styles.titleIcon, iconClassName)} />}

                {title}
            </div>

            <div className={styles.content}>{content}</div>
        </div>
    );
}
