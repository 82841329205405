// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-TaskEditor-DescriptionInput-_DescriptionInput__root{margin-top:10px;padding:4px 6px 4px 12px;border:1px solid #E3E9E5;border-radius:5px;max-height:93px}.src-client-modules-common-TaskEditor-DescriptionInput-_DescriptionInput__root textarea{font-family:'Open Sans';font-size:14px;line-height:19px;color:black}.src-client-modules-common-TaskEditor-DescriptionInput-_DescriptionInput__root textarea::placeholder{font-family:'Open Sans';font-size:14px;line-height:19px;font-weight:300;color:#7E8681 !important}.src-client-modules-common-TaskEditor-DescriptionInput-_DescriptionInput__root textarea:disabled{opacity:0.5}.src-client-modules-common-TaskEditor-DescriptionInput-_DescriptionInput__rootFocused{box-shadow:inset 0px 1px 4px rgba(0,0,0,0.1);border-color:#19B84E}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/TaskEditor/DescriptionInput/DescriptionInput.scss"],"names":[],"mappings":"AAAA,+EACI,eAAgB,CAChB,wBAAyB,CAEzB,wBAAyB,CACzB,iBAAkB,CAElB,eAAgB,CAPpB,wFAUQ,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACjB,WAAY,CAbpB,qGAgBY,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACjB,eAAgB,CAChB,wBAAyB,CApBrC,iGAwBY,WAAY,CACf,sFAKL,4CAAgD,CAChD,oBAAqB","sourcesContent":[".root {\n    margin-top: 10px;\n    padding: 4px 6px 4px 12px;\n\n    border: 1px solid #E3E9E5;\n    border-radius: 5px;\n\n    max-height: 93px;\n\n    & textarea {\n        font-family: 'Open Sans';\n        font-size: 14px;\n        line-height: 19px;\n        color: black;\n\n        &::placeholder {\n            font-family: 'Open Sans';\n            font-size: 14px;\n            line-height: 19px;\n            font-weight: 300;\n            color: #7E8681 !important;\n        }\n\n        &:disabled {\n            opacity: 0.5;\n        }\n    }\n}\n\n.rootFocused {\n    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.1);\n    border-color: #19B84E;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-TaskEditor-DescriptionInput-_DescriptionInput__root",
	"rootFocused": "src-client-modules-common-TaskEditor-DescriptionInput-_DescriptionInput__rootFocused"
};
export default ___CSS_LOADER_EXPORT___;
