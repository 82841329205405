// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-tags-TagsPreviewAndEditor-_TagsPreviewAndEditor__root{width:100%}.src-client-modules-tags-TagsPreviewAndEditor-_TagsPreviewAndEditor__title{margin-bottom:6px;font-family:'Open Sans';font-weight:400;font-size:12px;line-height:16px;letter-spacing:0.4px;color:#7E8681;text-transform:uppercase}.src-client-modules-tags-TagsPreviewAndEditor-_TagsPreviewAndEditor__skeleton{width:100%;height:33px}.src-client-modules-tags-TagsPreviewAndEditor-_TagsPreviewAndEditor__createNewTagPopupMessage{max-width:400px}.src-client-modules-tags-TagsPreviewAndEditor-_TagsPreviewAndEditor__createNewTagPopupMessageText{padding-bottom:12px;font-family:'Open Sans';font-weight:400;font-size:14px;line-height:19px;letter-spacing:0.15px;color:#000000}.src-client-modules-tags-TagsPreviewAndEditor-_TagsPreviewAndEditor__createNewTagPopupMessageTag{display:flex}\n", "",{"version":3,"sources":["webpack://./src/client/modules/tags/TagsPreviewAndEditor/TagsPreviewAndEditor.scss"],"names":[],"mappings":"AAAA,0EACI,UAAW,CACd,2EAGG,iBAAkB,CAElB,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,oBAAqB,CACrB,aAAc,CACd,wBAAyB,CAC5B,8EAGG,UAAY,CACZ,WAAY,CACf,8FAGG,eAAgB,CACnB,kGAGG,mBAAoB,CAEpB,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,qBAAsB,CACtB,aAAc,CACjB,iGAGG,YAAa","sourcesContent":[".root {\n    width: 100%;\n}\n\n.title {\n    margin-bottom: 6px;\n\n    font-family: 'Open Sans';\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 16px;\n    letter-spacing: 0.4px;\n    color: #7E8681;\n    text-transform: uppercase;\n}\n\n.skeleton {\n    width : 100%;\n    height: 33px;\n}\n\n.createNewTagPopupMessage {\n    max-width: 400px;\n}\n\n.createNewTagPopupMessageText {\n    padding-bottom: 12px;\n\n    font-family: 'Open Sans';\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 19px;\n    letter-spacing: 0.15px;\n    color: #000000;\n}\n\n.createNewTagPopupMessageTag {\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-tags-TagsPreviewAndEditor-_TagsPreviewAndEditor__root",
	"title": "src-client-modules-tags-TagsPreviewAndEditor-_TagsPreviewAndEditor__title",
	"skeleton": "src-client-modules-tags-TagsPreviewAndEditor-_TagsPreviewAndEditor__skeleton",
	"createNewTagPopupMessage": "src-client-modules-tags-TagsPreviewAndEditor-_TagsPreviewAndEditor__createNewTagPopupMessage",
	"createNewTagPopupMessageText": "src-client-modules-tags-TagsPreviewAndEditor-_TagsPreviewAndEditor__createNewTagPopupMessageText",
	"createNewTagPopupMessageTag": "src-client-modules-tags-TagsPreviewAndEditor-_TagsPreviewAndEditor__createNewTagPopupMessageTag"
};
export default ___CSS_LOADER_EXPORT___;
