import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';

import { TaskCommentTransferObject } from 'sber-marketing-types/backend';
import { TaskStatus } from 'sber-marketing-types/frontend';

import { useGetTaskQuery } from '@api';

import { useSearch } from '@common/hooks';
import { useGetChannelParticipantsCountText } from '@modules/task/hooks';

import { Card, CardProps, Flex, IconTag, Scrollbar } from '@common/components';
import { ChatContent, Comment, CommentForm } from '@modules/comment/components';

import * as styles from './ChatCard.scss';

export interface ChatCardProps extends CardProps {
    taskId: string;
    channelId: number;
}

export function ChatCard({ taskId, channelId, loading, className, children, ...props }: ChatCardProps) {
    const { data: task, isLoading: isTaskLoading } = useGetTaskQuery({ id: taskId });
    const [search, setSearch] = useSearch();
    const { text: participantsCount, isLoading: isParticipantsCountLoading } = useGetChannelParticipantsCountText(
        taskId,
        channelId,
    );
    const [editComment, setEditComment] = React.useState<TaskCommentTransferObject>();
    const positionRef = React.useRef<number>();
    const scrollbarRef = React.useRef<HTMLDivElement>();
    const comment = search.commentId
        ? task?.task.chanels.find(({ id }) => id === channelId)?.comments?.find(({ id }) => id === search.commentId)
        : null;
    const isLoading = isTaskLoading || isParticipantsCountLoading;
    const isActive = task && task.task.status === TaskStatus.InProgress;
    const isDraft = task && task.task.status === TaskStatus.Draft;

    const scrollTo = (y: number) => {
        scrollbarRef.current.scrollTo(0, y);
    };

    const scrollDown = () => {
        scrollTo(scrollbarRef.current.scrollHeight);
    };

    const handleFinish = () => {
        setEditComment(undefined);
        scrollDown();
    };

    const handleBack = () => {
        if (search.commentId) {
            setSearch({ ...search, commentId: undefined });
        }
    };

    const handleReplay = () => {
        positionRef.current = scrollbarRef.current.scrollTop;
    };

    React.useEffect(() => {
        if (!isLoading) {
            if (!comment && positionRef.current !== undefined) {
                scrollTo(positionRef.current);
                positionRef.current = undefined;
            } else {
                scrollDown();
            }
        }
    }, [isLoading, comment]);

    React.useEffect(() => {
        scrollDown();
    }, [channelId]);

    const textareaElement = isActive && (
        <CommentForm
            comment={editComment}
            onSubmit={handleFinish}
            onCancel={handleFinish}
            taskId={taskId}
            channelId={channelId}
            className={styles.textarea}
        />
    );

    const contentElement = isDraft ? (
        <Flex flex vertical align="center" justify="center">
            Чат недоступен для задач в статусе «Черновик»
        </Flex>
    ) : (
        <Flex flex vertical justify="flex-end">
            <ChatContent onReplay={handleReplay} onEditComment={setEditComment} taskId={taskId} channelId={channelId} />
        </Flex>
    );

    return (
        <Card
            flex
            vertical
            reverse
            {...props}
            loading={isLoading || loading}
            className={classNames(styles.root, isDraft && styles.draft, className)}
        >
            {textareaElement}
            <Flex flex vertical justify="flex-end" padding={[0, 2]} className={styles.content}>
                {comment && (
                    <Flex className={styles.replayHeader} gap={16} padding={[8, 0]} vertical>
                        <Flex padding={[0, 14]}>
                            <IconTag
                                data-qa-id="ChatCard__back"
                                aligned
                                ghost
                                onClick={handleBack}
                                icon={IconType.ARROW16_LEFT}
                            >
                                Обсуждение
                            </IconTag>
                        </Flex>
                        <Scrollbar vertical>
                            <Comment
                                data-qa-id="ChatCard__replayComment"
                                className={styles.comment}
                                onEdit={() => setEditComment(comment)}
                                padding={[3, 3, 3, 18]}
                                ghost
                                comment={comment}
                            />
                        </Scrollbar>
                    </Flex>
                )}
                <Scrollbar minHeight="50%" flex padding={[0, 0, 18]} vertical rootRef={scrollbarRef}>
                    {contentElement}
                </Scrollbar>
            </Flex>
            <Flex align="center" padding={[4, 20]} className={styles.header}>
                <Flex data-qa-id="ChatCard__participantsCount" gap={6} align="center">
                    <Icon type={IconType.USER} />
                    {participantsCount}
                </Flex>
            </Flex>
        </Card>
    );
}
