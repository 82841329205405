import * as React from 'react';
import classNames from 'classnames';
import * as lodash from 'lodash';
import {
    OpacityTransition,
    CustomScrollbar_new as CustomScrollbar,
    LabeledSelect_redesign as LabeledSelect,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    Preloader,
    Icon,
    IconType,
    WithTooltip,
    TooltipAnchor,
} from 'sber-marketing-ui';
import type { UserResponseParams as User } from 'sber-marketing-types/frontend';

import { ActivityFiles, ActivityNameDropdown } from '@budgetCommon';

import type { LinkRequest } from './WithLinkRequests';
import { BudgetItemSelect } from './BudgetItemSelect';
import { LinkedSerialNumbers } from './LinkedSerialNumbers';

import * as style from './ActivityReferenceMenu.scss';

const CONTENT_MAX_HEIGHT = 550;

interface Props {
    showPreloader: boolean;
    budgetId: string;
    existingLinks: LinkRequest[];
    linksAreChanged: boolean;
    expertUsers: User[];
    selectedActivityId: number;
    selectedExpertId: number;
    onActivitySelection: (activityId: number) => void;
    onExpertSelection: (expertId: number) => void;
    onSaveButtonClick: () => void;
    onCancelButtonClick: () => void;
}

export const ActivityReferenceMenu = ({
    showPreloader,
    budgetId,
    existingLinks,
    linksAreChanged,
    expertUsers,
    selectedActivityId,
    selectedExpertId,
    onActivitySelection,
    onExpertSelection,
    onSaveButtonClick,
    onCancelButtonClick,
}: Props): JSX.Element => {
    const activityIsSelected = !!selectedActivityId;
    const expertIsSelected = !!selectedExpertId;

    const saveButtonIsDisabled = !activityIsSelected || !expertIsSelected || !linksAreChanged;

    const existingLinksOfSelectedYear = existingLinks.filter((item) => item.budgetItem.budget.id === budgetId);
    const existingLinksOfOtherYears = existingLinks.filter((item) => item.budgetItem.budget.id !== budgetId);

    /* tslint:disable max-line-length */
    return (
        <OpacityTransition>
            <div className={classNames(style.root, activityIsSelected && style.activitySelected)}>
                <div className={style.title}>Связь проекта и ID бюджета</div>

                {!showPreloader && (
                    <ActivityNameDropdown
                        selectedActivityId={selectedActivityId}
                        selectActivity={onActivitySelection}
                    />
                )}

                {activityIsSelected && (
                    <>
                        <div className={style.linkedSerialNumbers}>
                            <LinkedSerialNumbers existingLinks={existingLinksOfSelectedYear} />

                            {!lodash.isEmpty(existingLinksOfOtherYears) && (
                                <div className={style.otherYearsLinksMessage}>
                                    <div className={style.infoIcon}>
                                        <WithTooltip
                                            // tslint:disable:max-line-length
                                            content={
                                                'Чтобы получить доступ к ID строк,\nсозданных в другом году, преключите\nгод в верхем левом углу таблицы'
                                            }
                                            // tslint:enable:max-line-length
                                            anchor={TooltipAnchor.LEFT}
                                        >
                                            <Icon type={IconType.EXPIRED_TASK} svgSize={14} />
                                        </WithTooltip>
                                    </div>
                                    И еще {existingLinksOfOtherYears.length} ID из других годов
                                </div>
                            )}
                        </div>

                        <div className={style.expertSelection}>
                            <LabeledSelect
                                title={'Согласующий эксперт'}
                                items={expertUsers.map((item) => ({
                                    label: `${item.secondName} ${item.firstName}`,
                                    value: item.id,
                                }))}
                                selectedValue={selectedExpertId}
                                onSelection={(value) => onExpertSelection(value as number)}
                            />
                        </div>

                        <div className={style.scrollbar}>
                            <CustomScrollbar maxHeight={CONTENT_MAX_HEIGHT} freezeScrollX hideScrollX>
                                <div className={style.scrollContent}>
                                    <BudgetItemSelect
                                        activityId={selectedActivityId}
                                        budgetId={budgetId}
                                        existingLinks={existingLinksOfSelectedYear}
                                    />

                                    <div className={style.activityFiles}>
                                        <ActivityFiles activityId={selectedActivityId} />
                                    </div>
                                </div>
                            </CustomScrollbar>
                        </div>
                    </>
                )}

                <div className={style.buttons}>
                    <div className={style.button}>
                        <Button theme={ButtonTheme.GhostRounded} onClick={onCancelButtonClick}>
                            Закрыть
                        </Button>
                    </div>

                    <div className={style.button}>
                        <Button
                            theme={ButtonTheme.MainRounded}
                            onClick={onSaveButtonClick}
                            disabled={saveButtonIsDisabled}
                        >
                            Сохранить
                        </Button>
                    </div>
                </div>

                {showPreloader && <Preloader />}
            </div>
        </OpacityTransition>
    );
    /* tslint:enable max-line-length */
};
