import * as React from 'react';
import autobind from 'autobind-decorator';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { UserConfigType } from 'sber-marketing-types/openid';

import { StoreState } from '@store';
import {
    saveUserConfig,
    NewsFilter as NewsFiltersSelectedItems,
    getNewsFilters as getNewsFiltersSelectedItems,
} from '@store/userConfig';
import { getNewsFilters as getNewsFiltersItems, loadFilters, NewsFilters } from '@store/news';

import { Filters, FiltersProps } from './Filters';

interface Props extends Partial<MapProps & DispatchProps> {}

interface MapProps {
    filters: NewsFilters;
    selectedFilters: NewsFiltersSelectedItems;
}

interface DispatchProps {
    setNewsFilter: (filter: Partial<NewsFiltersSelectedItems>) => void;
    loadFilters: () => void;
}

interface State {
    isOpened: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class FiltersContainer extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isOpened: false,
        };
    }

    public componentDidMount(): void {
        this.props.loadFilters();
    }

    public shouldComponentUpdate(props: Props, state: State): boolean {
        const stateHasChanged = state !== this.state;
        const filtersHaveChanged = !isEqual(props.filters, this.props.filters);
        const selectedFiltersHaveChanged = !isEqual(props.selectedFilters, this.props.selectedFilters);

        return stateHasChanged || filtersHaveChanged || selectedFiltersHaveChanged;
    }

    public render(): JSX.Element {
        const { isOpened } = this.state;

        return (
            <Filters
                filters={this.formatFilters()}
                isOpened={isOpened}
                toggleIsOpened={this.toggleIsOpened}
                toggleShowUnseenNews={this.toggleShowUnseenNews}
                setActivityFilter={this.setActivityFilter}
                setTypeFilter={this.setTypeFilter}
                setAuthorFilter={this.setAuthorFilter}
            />
        );
    }

    private formatFilters(): FiltersProps {
        const { filters, selectedFilters } = this.props;

        return {
            type: {
                items: filters.type,
                checkedItemIds: selectedFilters.type,
            },
            activity: {
                items: filters.activity,
                checkedItemIds: selectedFilters.activity,
            },
            author: {
                items: filters.author,
                checkedItemIds: selectedFilters.author,
            },
            showUnseenNews: selectedFilters.onlyUnseen,
        };
    }

    @autobind
    private toggleIsOpened(): void {
        this.setState((state) => ({
            isOpened: !state.isOpened,
        }));
    }

    @autobind
    private setActivityFilter(checkedItemIds: number[]): void {
        this.props.setNewsFilter({
            activity: checkedItemIds,
        });
    }

    @autobind
    private setTypeFilter(checkedItemIds: string[]): void {
        this.props.setNewsFilter({
            type: checkedItemIds,
        });
    }

    @autobind
    private setAuthorFilter(checkedItemIds: number[]): void {
        this.props.setNewsFilter({
            author: checkedItemIds,
        });
    }

    @autobind
    private toggleShowUnseenNews(): void {
        this.props.setNewsFilter({
            onlyUnseen: !this.props.selectedFilters.onlyUnseen,
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        filters: getNewsFiltersItems(state),
        selectedFilters: getNewsFiltersSelectedItems(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
    return {
        setNewsFilter: (filters: Partial<NewsFiltersSelectedItems>) =>
            dispatch(
                saveUserConfig({
                    type: UserConfigType.Dashboard,
                    payload: {
                        newsFilters: filters,
                    },
                }),
            ),
        loadFilters: () => dispatch(loadFilters(null)),
    };
}
