import * as React from 'react';
import { get } from 'lodash';
import { graphql } from 'react-apollo';

import { GET_BUDGETS_QUERY } from './query';

export interface BudgetProps {
    id: string;
    year: number;
    organizationIds: string[];
}

interface ChildrenProps {
    loading: boolean;
    budgets: BudgetProps[];
}

interface Props extends ExternalProps, QueryProps {}

interface ExternalProps {
    organizationIds: string[];
    children: (props: ChildrenProps) => JSX.Element;
}

type QueryProps = {
    budgetsQuery: {
        loading: boolean;
        budgets: {
            nodes: BudgetProps[];
        };
    };
};

class WithBudgetsWrapper extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { children } = this.props;

        return children(this.getChildrenProps());
    }

    private getChildrenProps(): ChildrenProps {
        const { budgetsQuery } = this.props;

        const loading = get(budgetsQuery, 'loading');
        const budgets: BudgetProps[] = get(budgetsQuery, 'budgets.nodes') || [];

        return {
            loading,
            budgets,
        };
    }
}

export const WithBudgets = graphql<ExternalProps>(GET_BUDGETS_QUERY, {
    name: 'budgetsQuery',
    options: ({ organizationIds }) => ({
        variables: {
            organizationIds,
        },
    }),
    skip: ({ organizationIds }) => !organizationIds.length,
})(WithBudgetsWrapper) as React.ComponentType<ExternalProps>;
