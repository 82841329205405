import * as React from 'react';
import classNames from 'classnames';
import { WithTooltip, Skeleton } from 'sber-marketing-ui';

import * as style from './CardTitle.scss';

interface Props {
    tooltip?: string;
    loading?: boolean;
}

export const CardTitle: React.FC<Props> = ({ children, tooltip, loading }) => (
    <WithTooltip content={tooltip}>
        <div className={classNames(style.root, loading && style.loading)} {...{ 'qa-id': 'cardTitle' }}>
            <Skeleton isReady={!loading}>{children}</Skeleton>
        </div>
    </WithTooltip>
);
