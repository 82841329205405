import * as React from 'react';
import type { FieldValue } from 'sber-marketing-types/frontend';
import * as lodash from 'lodash';

import { WithStore } from './WithStore';
import { ActivityReferenceMenuContainer } from './ActivityReferenceMenuContainer';

interface Props {
    activityId: number;
    budgetId: string;
    divisionId: string;
    departmentId: string;
    value: FieldValue;
    onSaveButtonClick: (value: string[]) => void;
    onCancelButtonClick: () => void;
}

export class ActivityReferenceMenuConnected extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { budgetId, activityId, divisionId, departmentId, value, onSaveButtonClick, onCancelButtonClick } =
            this.props;

        return (
            <WithStore>
                {({ budgetItems, selectedBudgetItemIds, setActivityReferenceData }) => (
                    <ActivityReferenceMenuContainer
                        showPreloader={!budgetItems?.length}
                        budgetId={budgetId}
                        divisionId={divisionId}
                        departmentId={departmentId}
                        value={value}
                        selectedActivityId={activityId}
                        selectedBudgetItemIds={selectedBudgetItemIds}
                        budgetItems={budgetItems}
                        setActivityReferenceData={setActivityReferenceData}
                        onSaveButtonClick={() => {
                            const selected: string[] = [];
                            if (selectedBudgetItemIds && selectedBudgetItemIds.length) {
                                lodash.sortBy(budgetItems, 'serialNumber').forEach((option) => {
                                    if (selectedBudgetItemIds.find((id) => id === option.id)) {
                                        selected.push(option.id);
                                    }
                                });
                            }
                            onSaveButtonClick(selected);
                            onCancelButtonClick();
                        }}
                        onCancelButtonClick={onCancelButtonClick}
                    />
                )}
            </WithStore>
        );
    }
}
