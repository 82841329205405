import { EditChanelParams } from 'sber-marketing-types/frontend';

import { factory } from './factory';
import {
    LoadTaskPayload,
    LoadTaskSuccess,
    FetchError,
    FetchTelegramError,
    Commentary,
    SaveCommentaryPayload,
    RemoveCommentaryPayload,
    AddFileToTaskPayload,
    RemoveFileFromTaskPayload,
    AddFileToCommentaryPayload,
    RemoveFileFromCommentaryPayload,
    AddCommentarySuccess,
    ChangeExecutorPayload,
    RemoveParticipatorPayload,
    LoadEditorDataSuccess,
    CreateNewTaskPayload,
    UpdateChannelPayload,
    Channel,
    BulkAddParticipatorsStartPayload,
    SwitchKeyActivityParams,
    PlugTelegramPayload,
    PlugTelegramSuccess,
    UnplugTelegramPayload,
    UnplugTelegramSuccess,
    UpdateTelegramSuccess,
    UpdateTelegramPayload,
    SetRatePayload,
    SetRateSuccess,
    ToggleCommentReactionPayload,
    DeleteChannelPayload,
} from '../types';
import { FileAsset } from '../../commonTypes';

/** Load task action */
export const loadTask = factory.async<LoadTaskPayload, LoadTaskSuccess, FetchError>('LOAD_TASK');

/** Add commentary action */
export const addCommentary = factory.async<number | null, AddCommentarySuccess, FetchError>('ADD_COMMENTARY');

/** Save commentary */
export const saveCommentary = factory.async<SaveCommentaryPayload, Commentary, FetchError>('SAVE_COMMENTARY');

/** Remove commentary from task */
export const removeCommentary = factory.async<RemoveCommentaryPayload, void, FetchError>('REMOVE_COMMENTARY');

/** Add file to task */
export const addFileToTask = factory.async<AddFileToTaskPayload, FileAsset, FetchError>('ADD_FILE_TO_TASK');

/** Remove file from task */
export const removeFileFromTask = factory.async<RemoveFileFromTaskPayload, void, FetchError>('REMOVE_FILE_FROM_TASK');

/** Add file to commentary */
export const addFileToCommentary = factory.async<AddFileToCommentaryPayload, FileAsset, FetchError>(
    'ADD_FILE_TO_COMMENTARY',
);

/** Remove file form commentary */
export const removeFileFromCommentary = factory.async<RemoveFileFromCommentaryPayload, void, FetchError>(
    'REMOVE_FILE_FROM_COMMENTARY',
);

/** Move to work task */
export const moveToWorkTask = factory.async<null, void, FetchError>('MOVE_TO_WORK_TASK');

/** Cancel task */
export const cancelTask = factory.async<null, void, FetchError>('CANCEL_TASK');

/** Change executor */
export const changeExecutor = factory.async<ChangeExecutorPayload, void, FetchError>('CHANGE_EXECUTOR');

/** Add several participators at once */
export const bulkAddParticipators = factory.async<
    BulkAddParticipatorsStartPayload,
    void,
    BulkAddParticipatorsStartPayload
>('BULK_ADD_PARTICIPATORS');

/** Remove participator */
export const removeParticipator = factory.async<RemoveParticipatorPayload, void, FetchError>('REMOVE_PARTICIPATOR');

/** Load data for task editor popup */
export const loadEditorData = factory.async<null, LoadEditorDataSuccess, FetchError>('LOAD_EDITOR_DATA');

/** Create new task at backend */
export const createNewTask = factory.async<CreateNewTaskPayload, void, FetchError>('CREATE_NEW_TASK');

/** Update task */
export const updateTask = factory.async<null, void, FetchError>('UPDATE_TASK');

export const saveTaskAsDraft = factory.async<null, void, FetchError>('SAVE_TASK_AS_DRAFT');

/** Create new channel */
export const createChannel = factory.async<EditChanelParams, Channel, void>('CREATE_CHANNEL');

/** Update channel */
export const updateChannel = factory.async<UpdateChannelPayload, Channel, Channel>('UPDATE_CHANNEL_PAYLOAD');

export const deleteChannel = factory.async<DeleteChannelPayload, void, Error>('DELETE_CHANNEL');

export const switchKeyActivityAsync = factory.async<SwitchKeyActivityParams, void, Error>('SWITCH_KEY_ACTIVITY_ASYNC');

export const updateTelegram = factory.async<UpdateTelegramPayload, UpdateTelegramSuccess, FetchError>(
    'UPDATE_TELEGRAM_ASYNC',
);

export const plugTelegram = factory.async<PlugTelegramPayload, PlugTelegramSuccess, FetchTelegramError>(
    'PLUG_TELEGRAM_ASYNC',
);

export const unplugTelegram = factory.async<UnplugTelegramPayload, UnplugTelegramSuccess, FetchTelegramError>(
    'UNPLUG_TELEGRAM_ASYNC',
);

export const setRate = factory.async<SetRatePayload, SetRateSuccess, FetchError>('SET_RATE');

export const toggleCommentReaction = factory.async<ToggleCommentReactionPayload, void, Error>(
    'TOGGLE_COMMENT_REACTION',
);
