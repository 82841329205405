import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as lodash from 'lodash';
import { UserResponseParams } from 'sber-marketing-types/frontend';
import { Tag } from '@mrm/tags';

import { StoreState } from '@store';
import { getTagsState } from '@store/tags';
import {
    getBudgetExecutionPageState,
    getUpdatedFIlters,
    LoadFiltersPaylaod,
    Filters,
    ColumnFilters,
    LoadingStatus,
    FilterKey,
} from '@store/budgetExecution';
import * as actions from '@store/budgetExecution/actions';
import * as thunk from '@store/budgetExecution/thunks';
import { DictionaryType, PlainDictionary } from '@mrm/dictionary/Dictionary';
import { ColumnsList } from '../../../../../../ColumnsConfig';

interface Props {
    columnName: FilterKey;
    children: (params: RenderParams) => React.ReactElement;
}

interface RenderParams {
    filterLoadingStatus: LoadingStatus;
    filters: ColumnFilters;
    users: UserResponseParams[];
    dictionaryType: DictionaryType;
    dictionaries: Record<string, PlainDictionary>;
    tags: lodash.Dictionary<Tag>;
    showApplyFiltersButton: boolean;
    loadFiltersByColumn: () => void;
    loadFilters: (payload: LoadFiltersPaylaod) => void;
    setFilters: (filters: Filters) => void;
}

export const WithStore: React.FC<Props> = ({ columnName, children }) => {
    const dispatch = useDispatch();

    const tags = useSelector((state: StoreState) => getTagsState(state).byId.dictionary);

    const { dictionaryType, users, userDictionaries, filters, loadingStatues, showApplyFiltersButton } = useSelector(
        (state: StoreState) => {
            const {
                pageData: { allUsers: users, userDictionaries },
                columnFilters,
                previouslyLoadedFilters,
            } = getBudgetExecutionPageState(state);
            const column = ColumnsList.find((column) => column.name === columnName);
            const dictionaryType: DictionaryType = column?.metaData?.dictionaryType;

            const filters = getUpdatedFIlters(state)[columnName];
            const areFiltersLoaded = !lodash.isEmpty(filters);

            const showApplyFiltersButton = !lodash.isEqual(columnFilters.filters, previouslyLoadedFilters);

            return {
                dictionaryType,
                users,
                userDictionaries,
                areFiltersLoaded,
                filters,
                loadingStatues: columnFilters.loadingStatus,
                showApplyFiltersButton,
            };
        },
    );

    function loadFilters(payload: LoadFiltersPaylaod) {
        dispatch(actions.loadFilters(payload));
    }
    function loadFiltersByColumn() {
        dispatch(thunk.loadFiltersByColumn(columnName));
    }
    function setFilters(filters: Filters) {
        dispatch(actions.setFilters(filters));
    }

    return children({
        filterLoadingStatus: loadingStatues[columnName],
        dictionaryType,
        users,
        dictionaries: userDictionaries.byId,
        filters,
        showApplyFiltersButton,
        tags,
        loadFilters,
        loadFiltersByColumn,
        setFilters,
    });
};
