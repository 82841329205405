import * as React from 'react';
import { connect } from 'react-redux';

import { StoreState } from '@store/index';
import { getBudgetItemByLineId } from '@store/budgetExecution';
import { BudgetItem } from '@mrm/budget';

import { Title } from './Title';

interface Props extends Partial<MapProps> {
    lineId: string;
}

interface MapProps {
    budgetItem: BudgetItem;
}

@(connect(mapStateToProps) as any)
export class TitleContainer extends React.Component<Props> {
    public render(): JSX.Element {
        const { budgetItem } = this.props;
        return React.createElement(Title, {
            id: (budgetItem.serialNumber && String(budgetItem.serialNumber)) || '',
            text: (budgetItem.activity && budgetItem.activity.name) || '',
        });
    }
}

function mapStateToProps(store: StoreState, props: Props): MapProps {
    const { lineId } = props;

    return {
        budgetItem: getBudgetItemByLineId(store, lineId),
    };
}
