import * as React from 'react';
import { StaticSkeleton } from 'sber-marketing-ui';

import * as styles from './Preloader.scss';

export function Preloader(): JSX.Element {
    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'tagsPageFiltersPreloader',
            }}
        >
            <div className={styles.listFilter}>
                <Skeleton className={styles.listFitlerTitle1} />

                <div className={styles.filterItems}>
                    <Skeleton className={styles.listFilterItem1} />
                    <Skeleton className={styles.listFilterAddButton} />
                </div>
            </div>

            <div className={styles.listFilter}>
                <Skeleton className={styles.listFitlerTitle2} />

                <div className={styles.filterItems}>
                    <Skeleton className={styles.listFilterItem2} />
                    <Skeleton className={styles.listFilterAddButton} />
                </div>
            </div>

            <div className={styles.tagGroupFilter}>
                <Skeleton className={styles.tagGroupFilterTitle} />

                <div className={styles.filterItems}>
                    <Skeleton className={styles.tagGroupFilterItem1} />
                    <Skeleton className={styles.tagGroupFilterItem2} />
                    <Skeleton className={styles.tagGroupFilterItem3} />
                    <Skeleton className={styles.tagGroupFilterItem4} />
                </div>
            </div>
        </div>
    );
}

interface SkeletonProps {
    className: string;
}

function Skeleton({ className }: SkeletonProps): JSX.Element {
    return <StaticSkeleton firstColor={'#d6e1e8'} className={className} />;
}
