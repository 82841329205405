import { rtkApi } from './rtkApi';
import { MrmClient } from '@api';
import { CreateTagParams } from '@sbermarketing/mrm-metacom-client/types/tags/module';
import { TagPicture } from '@sbermarketing/mrm-metacom-client/types/tags/types';

export interface TagDictionary {
    id: string;
    title: string;
}

export interface TagData extends TagDictionary {
    color: string;
    picture: TagPicture;
}

export interface NewTagData extends Omit<CreateTagParams, 'picture'> {
    picture: string;
}

export interface EditTagData extends Omit<NewTagData, 'name'> {
    id: string;
    picture: string;
}

export const tagApi = rtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getTagsDictionary: builder.query<TagDictionary[], void>({
            queryFn: async () => {
                try {
                    const client = await MrmClient.getInstance();
                    const result = await client.Dictionary.getByType('tag' as any);
                    const data: TagDictionary[] = await Promise.all(
                        result.map(
                            ({ id, value }): TagDictionary => ({
                                id,
                                title: value,
                            }),
                        ),
                    );

                    return { data };
                } catch (e) {
                    console.error(e);
                    return { data: [] };
                }
            },
            providesTags: (tags) => tags.map(({ id }) => ({ type: 'tag', id })),
        }),
        getTag: builder.query<TagData, string>({
            queryFn: async (tagId) => {
                try {
                    const client = await MrmClient.getInstance();
                    const result = await client.Dictionary.getByType('tag' as any);
                    const tag = result.find(({ id }) => tagId === id);

                    if (!tag) return null;

                    const tagData = await tag.getData();

                    const data: TagData = {
                        ...tagData,
                        id: tagId,
                        title: tag.value,
                    };

                    return { data };
                } catch (e) {
                    console.error(e);
                    return { error: e };
                }
            },
            providesTags: ({ id }) => [{ type: 'tag', id }],
        }),
        addTag: builder.mutation<{ id: string }, NewTagData>({
            queryFn: async (params) => {
                try {
                    const client = await MrmClient.getInstance();
                    const data = await client.api.tags.createTag({
                        ...params,
                        picture: params.picture && { type: 'emoji', text: params.picture.slice(8) },
                    });

                    return { data };
                } catch (e) {
                    console.error(e);
                    return { error: e };
                }
            },
            async onQueryStarted(params, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                const newDictionaryTag: TagDictionary = {
                    id: data.id,
                    title: params.name,
                };

                try {
                    dispatch(
                        tagApi.util.updateQueryData('getTagsDictionary', undefined, (draft) => {
                            draft.push(newDictionaryTag);
                        }),
                    );
                } catch (e) {
                    console.error(e);
                }
            },
        }),
        editTag: builder.mutation<null, EditTagData>({
            queryFn: async (params) => {
                try {
                    const client = await MrmClient.getInstance();
                    await client.api.tags.updateTag({
                        ...params,
                        picture: params.picture && { type: 'emoji', text: params.picture.slice(8) },
                    });
                    return { data: null };
                } catch (e) {
                    console.error(e);
                    return { error: e };
                }
            },
            async onQueryStarted(params, { dispatch, queryFulfilled }) {
                await queryFulfilled;

                try {
                    dispatch(
                        tagApi.util.updateQueryData('getTag', params.id, (draft) => {
                            Object.assign(draft, {
                                ...params,
                                picture: params.picture && { type: 'emoji', text: params.picture.slice(8) },
                            });
                        }),
                    );
                } catch (e) {
                    console.error(e);
                }
            },
        }),
    }),
});

export const { useGetTagsDictionaryQuery, useGetTagQuery, useAddTagMutation, useEditTagMutation } = tagApi;
