import * as React from 'react';

import { WithValidationCreativeRequestParams } from './WithValidationCreativeRequestParams';
import { StageActionsBehaviour } from './StageActionsBehaviour';

interface Props {
    creativeRequestId: string;
    currentStage: number;
}

export const StageActionsConnector: React.FC<Props> = ({ creativeRequestId, currentStage }) => {
    return (
        <WithValidationCreativeRequestParams creativeRequestId={creativeRequestId}>
            {({ params }) => (
                <StageActionsBehaviour
                    creativeRequestId={creativeRequestId}
                    currentStage={currentStage}
                    validCreativeRequestParams={params}
                />
            )}
        </WithValidationCreativeRequestParams>
    );
};
