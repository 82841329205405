import * as React from 'react';
import * as lodash from 'lodash';

import * as style from './CardGroup.scss';

interface Props {
    cards: JSX.Element[];
    cardsMargin: number;
    columnsCount: number;
    rootRef: React.RefObject<HTMLDivElement>;
}

export function CardGroupTemplate({ cards, cardsMargin, columnsCount, rootRef }: Props): JSX.Element {
    const columns = groupCardsByColumns(cards, columnsCount);

    return (
        <div className={style.root} ref={rootRef}>
            {columns.map((columnCards, columnIndex) => (
                <div
                    key={columnIndex}
                    style={{
                        width: `calc((100% - ${cardsMargin}px * ${columnsCount - 1}) / ${columnsCount})`,
                        marginRight: columnIndex !== columnsCount - 1 ? `${cardsMargin}px` : 0,
                    }}
                >
                    {columnCards.map((item, index) => (
                        <div
                            key={index}
                            className={style.card}
                            style={{
                                marginBottom: index !== columnCards.length - 1 ? `${cardsMargin}px` : 0,
                            }}
                        >
                            {item}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

function groupCardsByColumns<T>(cards: T[], columnsCount: number): T[][] {
    return lodash.times(columnsCount, (columnIndex) => {
        const columnCards: T[] = [];

        let cardIndex = 0;

        while (cardIndex < cards.length) {
            if (cardIndex % columnsCount == columnIndex) {
                columnCards.push(cards[cardIndex]);
            }

            cardIndex += 1;
        }

        return columnCards;
    });
}
