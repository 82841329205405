import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as lodash from 'lodash';

import * as style from './SocialStageActions.scss';

import { AutopilotStage } from '@store/autopilot/types';
import type { StoreState } from '@store';

import { Icon, IconType, Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';
import { setCurrentStage } from '@store/autopilot/actions';
import { getSocialStageForm } from '@store/autopilot/selectors';
import { Loader, Saver } from '../../modules';

interface Props {
    canEdit: boolean;
}

export const SocialStageActions = ({ canEdit }: Props): JSX.Element => {
    const saver = Saver.getInstance();
    const loader = Loader.getInstance();
    const dispatch = useDispatch();

    const socialStageForm = useSelector((state: StoreState) => getSocialStageForm(state));

    const counter = [socialStageForm.vkUrl, socialStageForm.okUrl].filter((item) => !lodash.isEmpty(item)).length;

    const onContinueButtonClick = React.useCallback(async () => {
        await saver.saveSocialForm();
        await loader.loadAutopilot();

        dispatch(setCurrentStage(AutopilotStage.Final));
    }, []);

    return (
        <div className={style.root}>
            <div className={style.title}>
                Добавьте ссылки на посты
                {counter == 2 && (
                    <div className={style.titleIcon}>
                        <Icon type={IconType.ACCEPT_ACTIVE} svgSize={24} />
                    </div>
                )}
                {counter < 2 && (
                    <>
                        &nbsp;
                        <span className={style.counter}>({counter}/2)</span>
                    </>
                )}
            </div>

            <div className={style.button}>
                <Button
                    disabled={!canEdit || counter < 2}
                    theme={ButtonTheme.MainRounded}
                    onClick={onContinueButtonClick}
                >
                    К финальному согласованию
                </Button>
            </div>
        </div>
    );
};
