import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StoreState } from '@store';
import { loadPage, getLoading, getStatisticsItems, StatisticsItem } from '@store/leaderDashboardPage';
import type { Mode } from '@store/leaderDashboardPage';

interface UseState {
    (): UseStateReturn;
}

interface UseStateReturn extends Selected {
    initLoadData: InitLoadData;
}

interface Selected {
    loading: boolean;
    statisticsItems: StatisticsItem[];
}

interface InitLoadData {
    (params: InitLoadDataParams): void;
}

interface InitLoadDataParams {
    mode: Mode;
}

export const useStore: UseState = () => {
    const dispatch = useDispatch();

    const { loading, statisticsItems } = useSelector<StoreState, Selected>((state) => ({
        loading: getLoading(state),
        statisticsItems: getStatisticsItems(state),
    }));

    const initLoadData: InitLoadData = React.useCallback(
        ({ mode }) => {
            dispatch(loadPage({ mode }));
        },
        [dispatch],
    );

    return {
        loading,
        statisticsItems,
        initLoadData,
    };
};

export type { StatisticsItem };
