import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { Autopilot } from 'sber-marketing-types/backend';
import type { User } from '@store/user';

import { MediaplanStageActionsTemplate } from './MediaplanStageActionsTemplate';
import type { StoreState } from '@store';
import { getLoginUser } from '@store/user';
import { setCurrentStage, setMediaplanStatus } from '@store/autopilot_legacy/actions';
import { getAutopilot, getMediaplanStatus } from '@store/autopilot_legacy/selectors';

interface Props extends MapProps, DispatchProps {
    canEdit: boolean;
}

interface MapProps {
    autopilot?: Autopilot;
    calculatingMediaplan?: boolean;
    user?: User;
}

interface DispatchProps {
    setCurrentStage?: (stage: number) => void;
    setMediaplanStatus?: (loading: boolean) => void;
}

interface State {
    displayProgressBar: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class MediaplanStageActionsBehaviour extends React.PureComponent<Props, State> {
    private rootRef: React.RefObject<HTMLDivElement>;

    public constructor(props: Props) {
        super(props);

        this.state = {
            displayProgressBar: props.calculatingMediaplan,
        };

        this.rootRef = React.createRef<HTMLDivElement>();
    }

    public async componentDidMount() {
        this.rootRef.current.addEventListener('animationend', this.onProgressBarFinish, true);
    }

    public componentWillUnmount() {
        this.rootRef.current.removeEventListener('animationend', this.onProgressBarFinish);
    }

    public render(): JSX.Element {
        const { autopilot } = this.props;

        const userIsAutopilotMediaplanner = this.checkUserAutopilotMediaplannerAccess();
        const placementsAreLanched = this.checkLaunchedPlacements();

        return React.createElement(MediaplanStageActionsTemplate, {
            canEdit: this.props.canEdit,
            mediaplanUrl: autopilot.mediaplan ? autopilot.mediaplan.url : '',
            displayUrl: userIsAutopilotMediaplanner && !placementsAreLanched,
            displayProgressbar: this.state.displayProgressBar,
            rootRef: this.rootRef,
            onReturnButtonClick: this.onReturnButtonClick,
            onContinueButtonClick: this.onContinueButtonClick,
        });
    }

    @autobind
    protected onProgressBarFinish() {
        this.setState({
            displayProgressBar: false,
        });

        this.props.setMediaplanStatus(false);
    }

    @autobind
    protected onReturnButtonClick() {
        this.props.setCurrentStage(1);
    }

    @autobind
    protected onContinueButtonClick() {
        this.props.setCurrentStage(3);
    }

    private checkLaunchedPlacements(): boolean {
        const { autopilot } = this.props;

        if (!autopilot) {
            return false;
        }

        return autopilot.status === 'on' && !lodash.isEmpty(autopilot.placements);
    }

    private checkUserAutopilotMediaplannerAccess(): boolean {
        // check if user has role Autopilot Mediaplanner or Autopilot Client Service
        const roleIds = this.props.user.attributes.roles.map(({ id }) => id);

        return roleIds.includes(23) || roleIds.includes(24);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        autopilot: getAutopilot(state),
        calculatingMediaplan: getMediaplanStatus(state),
        user: getLoginUser(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setCurrentStage,
            setMediaplanStatus,
        },
        dispatch,
    );
}
