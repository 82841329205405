import { LoadingStatus } from '@store/commonTypes';

import { ProfileMenuVacationState } from './vacation';
import { ProfileMenuNotificationsState } from './notifications';

export interface ProfileMenuState {
    vacation: ProfileMenuVacationState;
    notifications: ProfileMenuNotificationsState;
    rest: ProfileMenuRestState;
}

export enum ComponentState {
    Closed = 'Closed',
    Menu = 'Menu',
    VacationEditor = 'VacationEditor',
    NotificationEditor = 'NotificationEditor',
}

export interface ProfileMenuRestState {
    componentState: ComponentState;
    loadingStatus: LoadingStatus;
}
