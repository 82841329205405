import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { AutopilotTVVideo } from 'sber-marketing-types/backend';
import { CellParams, CellPosition, LineId } from '../../types';
import {
    Accessor,
    TitleAccessor,
    ValueSetter,
    DescriptionAccessor,
    CustomStyleAccessor,
    AccessorParams,
    CellType,
    LineType,
    ColumnParams,
    ColumnName,
} from '../../ColumnsConfig';

import { InputCell, InputCellEdit, SelectCell, SelectCellEdit, TextCell } from '../../CellTypes';

export const CellComponentsByColumnType: Record<
    CellType,
    {
        cell: React.ClassType<any, any, any>;
        editCell?: React.ClassType<any, any, any>;
    }
> = {
    [CellType.Text]: {
        cell: TextCell,
    },
    [CellType.Input]: {
        cell: InputCell,
        editCell: InputCellEdit,
    },

    [CellType.Select]: {
        cell: SelectCell,
        editCell: SelectCellEdit,
    },
};

interface Props {
    getColumnsConfig: () => { [columnName: string]: ColumnParams };
    getLine: (lineId: LineId) => AutopilotTVVideo;
    getLines: () => AutopilotTVVideo[];
    getInvalidWeeksIndexes: () => number[];
    onCellClose: () => void;
    onLineChange: (lineId: LineId) => void;
}

export class CellsFactory {
    private getColumnsConfig: () => { [columnName: string]: ColumnParams };
    private getLine: (lineId: LineId) => AutopilotTVVideo;
    private getLines: () => AutopilotTVVideo[];
    private getInvalidWeeksIndexes: () => number[];
    private onCellClose: () => void;
    private onLineChange: (lineId: LineId) => void;

    public constructor(props: Props) {
        this.getColumnsConfig = props.getColumnsConfig;
        this.getLine = props.getLine;
        this.getLines = props.getLines;
        this.getInvalidWeeksIndexes = props.getInvalidWeeksIndexes;
        this.onCellClose = props.onCellClose;
        this.onLineChange = props.onLineChange;
    }

    @autobind
    public makeCellParams(cellPosition: CellPosition, edit: boolean): CellParams {
        const value = this.getCellValue(cellPosition);

        return {
            component: value !== undefined ? this.getCellComponent(cellPosition, edit) : null,
            cellProps: value !== undefined ? this.makeCellProps(cellPosition, edit) : null,
            readOnly: value !== undefined ? this.checkReadOnlyStatus(cellPosition, edit) : true,
        };
    }

    @autobind
    public getCellComponent(cellPosition: CellPosition, edit: boolean): React.ClassType<any, any, any> {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const columnType = lodash.get(this.getColumnsConfig(), [columnName, 'type', lineType]);

        if (!columnType) {
            return null;
        }

        return edit ? CellComponentsByColumnType[columnType].editCell : CellComponentsByColumnType[columnType].cell;
    }

    @autobind
    public makeCellProps(cellPosition: CellPosition, edit: boolean): any {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const columnType = lodash.get(this.getColumnsConfig(), [columnName, 'type', lineType]);

        if (!columnType) {
            return null;
        }

        let cellProps: any;

        switch (columnType) {
            case CellType.Text:
                cellProps = this.makeTextCellProps(cellPosition);
                break;

            case CellType.Input:
                cellProps = this.makeInputCellProps(cellPosition, edit);
                break;

            case CellType.Select:
                cellProps = this.makeSelectCellProps(cellPosition, edit);
                break;
        }

        cellProps = this.applyCustomStyles(cellPosition, cellProps);

        return cellProps;
    }

    public getCellValue(cellPosition: CellPosition) {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const accessor: Accessor = lodash.get(this.getColumnsConfig(), [columnName, 'getValue', lineType]);

        if (!accessor) {
            return undefined;
        }

        const params = this.makeAccessorParams(cellPosition);

        return accessor(params);
    }

    @autobind
    public checkReadOnlyStatus(cellPosition: CellPosition, edit: boolean): boolean {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const cellComponent = this.getCellComponent(cellPosition, edit);

        if (!cellComponent) {
            return true;
        }

        return lodash.get(this.getColumnsConfig(), [columnName, 'readOnly', lineType]) || false;
    }

    private makeTextCellProps(cellPosition: CellPosition): any {
        return {
            title: this.getCellValue(cellPosition) || '—',
        };
    }

    private makeInputCellProps(cellPosition: CellPosition, edit: boolean): any {
        const value = this.getCellValue(cellPosition);

        return edit
            ? {
                  title: value !== null ? value : '—',
                  placeholder: '',
                  onValueChange: this.makeValueChangeHandler(cellPosition),
              }
            : {
                  title: value !== null ? value : '—',
              };
    }

    private makeSelectCellProps(cellPosition: CellPosition, edit: boolean): any {
        const value = this.getCellValue(cellPosition);
        const items = this.getCellItems(cellPosition);
        const description = this.getCellDescription(cellPosition);

        return edit
            ? {
                  title: value !== null ? items.find((item) => item.value === value)?.title : `—`,
                  description,
                  items,
                  selectedValue: value,
                  onValueChange: this.makeValueChangeHandler(cellPosition),
              }
            : {
                  title: value !== null ? items.find((item) => item.value === value)?.title : `—`,
                  description,
              };
    }

    public getCellTitle(cellPosition: CellPosition) {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const accessor: TitleAccessor = lodash.get(this.getColumnsConfig(), [columnName, 'getTitle', lineType]);

        if (!accessor) {
            return undefined;
        }

        const params = this.makeAccessorParams(cellPosition);

        return accessor(params);
    }

    private getCellItems(cellPosition: CellPosition): any[] {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const itemsAccessor: Accessor = lodash.get(this.getColumnsConfig(), [columnName, 'getItems', lineType]);

        if (!itemsAccessor) {
            return [];
        }

        const params = this.makeAccessorParams(cellPosition);

        return itemsAccessor(params);
    }

    private getCellDescription(cellPosition: CellPosition): string {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const descriptionAccessor: DescriptionAccessor = lodash.get(this.getColumnsConfig(), [
            columnName,
            'getDescription',
            lineType,
        ]);

        if (!descriptionAccessor) {
            return null;
        }

        const params = this.makeAccessorParams(cellPosition);

        return descriptionAccessor(params);
    }

    private getLineType(lineId: LineId): LineType {
        let lineType = LineType.Line;

        if (lineId === 'total') {
            lineType = LineType.Total;
        }

        return lineType;
    }

    private makeAccessorParams(cellPosition: CellPosition): AccessorParams {
        const { lineId } = cellPosition;

        return {
            id: lineId,
            line: this.getLine(lineId),
            lines: this.getLines(),
            onLineChange: this.onLineChange,
        };
    }

    private makeValueChangeHandler(cellPosition: CellPosition) {
        return async (value: any) => {
            const { lineId, columnName } = cellPosition;

            const lineType = this.getLineType(lineId);
            const params = this.makeAccessorParams(cellPosition);

            const valueSetter: ValueSetter = lodash.get(this.getColumnsConfig(), [columnName, 'setValue', lineType]);

            await valueSetter(params, value);

            this.onCellClose();
        };
    }

    private applyCustomStyles(cellPosition: CellPosition, cellProps: any): any {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const customStyleAccessor: CustomStyleAccessor = lodash.get(this.getColumnsConfig(), [
            columnName,
            'customStyle',
            lineType,
        ]);

        if (customStyleAccessor) {
            const params = this.makeAccessorParams(cellPosition);

            const customStyle = customStyleAccessor(params);

            cellProps = {
                ...cellProps,
                customStyle: { ...cellProps.customStyle, ...customStyle },
            };
        }

        if (columnName !== ColumnName.Name && columnName !== ColumnName.Duration) {
            const invalidWeeksIndexes = this.getInvalidWeeksIndexes();

            const columnIndex = parseInt(columnName.replace('month', ''), 10);

            const columnPercentsAreValid = !invalidWeeksIndexes.includes(columnIndex);

            if (!columnPercentsAreValid) {
                cellProps = {
                    ...cellProps,
                    customStyle: {
                        ...cellProps.customStyle,
                        backgroundColor: '#fff6f6',
                    },
                };
            }
        }

        return cellProps;
    }
}
