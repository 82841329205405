import { TaskStatus, TaskPageSortBy } from 'sber-marketing-types/frontend';
import { LoadingStatus } from '@store/commonTypes';

export interface DashboardUserConfigState {
    loadingStatus: LoadingStatus;
    fields: DashboardUserConfig;
    rawFields: DashboardUserConfig;
}

export interface DashboardUserConfig {
    myTasksFilters: TasksFilter;
    availableActivitiesFilters: ActivitiesFilter;
    myActivitiesFilters: ActivitiesFilter;
    newsFilters: NewsFilter;
}

export enum TaskParticipation {
    AUTHOR,
    EXECUTOR,
    PARTICIPANT,
}

export interface TasksFilterIds {
    workType: string[];
    department: string[];
    activityStage?: string[];
}

export interface TasksFilter extends TasksFilterIds {
    author: number[];
    executor: number[];
    status: TaskStatus;
    participation: TaskParticipation;
    sorting: TaskPageSortBy;
}

export const enum ActivityStatus {
    ACTIVE,
    ACTIVE_ALL_TASKS_CLOSED,
    FINISHED,
}

export const enum ActivitySortingMode {
    UNRESOLVED_FIRST,
    UPDATING_DATE,
    CREATION_DATE,
    START_DATE,
}

export interface ActivitiesFilterIds {
    activityType: string[];
    product: string[];
    responsibleDepartment: string[];
}

export interface ActivitiesFilter extends ActivitiesFilterIds {
    author: number[];
    responsible: number[];
    status: ActivityStatus;
    sorting: ActivitySortingMode;
}

export interface NewsFilter {
    type: string[];
    activity: number[];
    author: number[];
    onlyUnseen: boolean;
}

export interface UserConfigAvailableItems {
    myTasksFilters: TasksFilterIds;
    availableActivitiesFilters: ActivitiesFilterIds;
    myActivitiesFilters: ActivitiesFilterIds;
}

export interface UpdateDashboardUserConfig {
    userConfig: {
        [F in keyof DashboardUserConfig]?: Partial<DashboardUserConfig[F]>;
    };
}
