// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-components-TelegramButton-ErrorPopup-Header-_styles__root{display:grid;grid-template-columns:1fr auto;grid-gap:20px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/components/TelegramButton/ErrorPopup/Header/styles.scss"],"names":[],"mappings":"AAAA,kGACI,YAAa,CAEb,8BAA+B,CAC/B,aAAc","sourcesContent":[".root {\n    display: grid;\n\n    grid-template-columns: 1fr auto;\n    grid-gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-components-TelegramButton-ErrorPopup-Header-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
