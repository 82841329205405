// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-calendar-Chart-ChartList-ActivityGroup-_ActivityGroup__root{position:relative;display:flex;overflow:hidden;width:100%;border-radius:6px;background-color:#ffffff;box-shadow:0 4px 6px -4px rgba(0,0,0,0.12)}.src-client-modules-calendar-Chart-ChartList-ActivityGroup-_ActivityGroup__canvas{width:100%}.src-client-modules-calendar-Chart-ChartList-ActivityGroup-_ActivityGroup__scrollbar{position:absolute;top:0;right:0;bottom:0;left:0;width:100%;height:100%}.src-client-modules-calendar-Chart-ChartList-ActivityGroup-_ActivityGroup__scrollContent{height:100%}.src-client-modules-calendar-Chart-ChartList-ActivityGroup-_ActivityGroup__scrollContent.src-client-modules-calendar-Chart-ChartList-ActivityGroup-_ActivityGroup__itemHovered{cursor:pointer}\n", "",{"version":3,"sources":["webpack://./src/client/modules/calendar/Chart/ChartList/ActivityGroup/ActivityGroup.scss"],"names":[],"mappings":"AAAA,gFACI,iBAAkB,CAElB,YAAa,CACb,eAAgB,CAEhB,UAAW,CAEX,iBAAkB,CAClB,wBAAyB,CACzB,0CAA8C,CACjD,kFAGG,UAAW,CACd,qFAGG,iBAAkB,CAClB,KAAM,CACN,OAAQ,CACR,QAAS,CACT,MAAO,CAEP,UAAW,CACX,WAAY,CACf,yFAGG,WAAY,CADhB,+KAIQ,cAAe","sourcesContent":[".root {\n    position: relative;\n\n    display: flex;\n    overflow: hidden;\n\n    width: 100%;\n\n    border-radius: 6px;\n    background-color: #ffffff;\n    box-shadow: 0 4px 6px -4px rgba(0, 0, 0, 0.12);\n}\n\n.canvas {\n    width: 100%;\n}\n\n.scrollbar {\n    position: absolute;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n\n    width: 100%;\n    height: 100%;\n}\n\n.scrollContent {\n    height: 100%;\n\n    &.itemHovered {\n        cursor: pointer;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-calendar-Chart-ChartList-ActivityGroup-_ActivityGroup__root",
	"canvas": "src-client-modules-calendar-Chart-ChartList-ActivityGroup-_ActivityGroup__canvas",
	"scrollbar": "src-client-modules-calendar-Chart-ChartList-ActivityGroup-_ActivityGroup__scrollbar",
	"scrollContent": "src-client-modules-calendar-Chart-ChartList-ActivityGroup-_ActivityGroup__scrollContent",
	"itemHovered": "src-client-modules-calendar-Chart-ChartList-ActivityGroup-_ActivityGroup__itemHovered"
};
export default ___CSS_LOADER_EXPORT___;
