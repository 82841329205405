import * as React from 'react';

import { HeaderTextCell } from './HeaderTextCell';

interface Props {
    value: React.ReactText;
}

export class HeaderTextCellContainer extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        return React.createElement(HeaderTextCell, {
            value: this.props.value,
        });
    }
}
