import { bindActionCreators } from 'redux';
import * as lodash from 'lodash';
import * as moment from 'moment';

import type {
    ActivityParams as Activity,
    TaskResponseParams as Task,
    Brief,
    BriefScheme,
    UserResponseParams as User,
} from 'sber-marketing-types/frontend';

import { store } from '@store';
import {
    loadBriefPage,
    applyBriefDraft,
    setCurrentBrief,
    setCurrentBriefWasCopied,
    setBriefSchemes,
} from '@store/brief/actions';
// import { getBriefState } from '@store/brief/selectors';
// import { getUserOrganizationId } from '@store/user/selector';
import { add as addFiles } from '@store/fileAssets';
import { BriefApi, ActivityApi, TaskApi, FileApi, UserApi } from '@api';

import type { PageLoader, UpdateSchemesParams } from './types';

interface ConstructorParams {
    activityId: number;
    organizationId: string;
}

// interface StoreProps {
//     activity: Activity;
//     task: Task;
//     brief: Brief;
//     currentBrief: Brief;
// }

export class CommonPageLoader implements PageLoader {
    private static instance: CommonPageLoader;
    private activityId: number;
    private organizationId: string;

    private activity: Activity;
    private briefs: Brief[] = [];
    private tasks: Task[] = [];
    private schemes: BriefScheme[] = [];
    private users: User[] = [];

    private dispatch = bindActionCreators(
        {
            loadBriefPage,
            applyBriefDraft,
            setCurrentBrief,
            setCurrentBriefWasCopied,
            setBriefSchemes,
            addFiles,
        },
        store.dispatch,
    );

    public static getInstance(params: ConstructorParams): CommonPageLoader {
        if (!CommonPageLoader.instance) {
            CommonPageLoader.instance = new CommonPageLoader();
        }

        CommonPageLoader.instance.activityId = params.activityId;
        CommonPageLoader.instance.organizationId = params.organizationId;

        return CommonPageLoader.instance;
    }

    public async init() {
        await this.loadActivity();
        await this.loadActivityBrief();
        await this.loadTasksOfActivity();
        await this.loadTasksBriefs();

        this.filterTasksBriefsWithoutSchemes();

        await Promise.all([this.loadSchemes(), this.loadUsers()]);

        this.dispatchLoadedDataToStore();
        this.resetLoadedData();
    }

    public async updateSchemes(params: UpdateSchemesParams): Promise<void> {
        this.briefs = params.briefs;

        await this.loadSchemes();

        this.dispatchLoadedSchemesToStore();
        this.resetLoadedData();
    }

    private resetLoadedData(): void {
        this.activity = null;
        this.briefs = [];
        this.tasks = [];
        this.schemes = [];
        this.users = [];
    }

    private async loadActivity() {
        this.activity = await ActivityApi.getActivity(this.activityId);
    }

    private async loadTasksOfActivity() {
        this.tasks = (await TaskApi.getTaskList({ activityId: this.activity.id })) as Task[];
    }

    private async loadActivityBrief() {
        const brief = await ActivityApi.getActivityBrief(this.activityId);
        const updatedBrief = this.formatFilesData(brief);
        this.briefs.push(updatedBrief);
    }

    private async loadTasksBriefs() {
        await Promise.all(
            this.tasks.map(async ({ id }) => {
                const brief = await TaskApi.getTaskBrief(id);
                const updatedBrief = this.formatFilesData(brief);
                this.briefs.push(updatedBrief);
            }),
        );
    }

    // private async loadBriefDraft() {
    //     const { brief } = this.getStoreProps();
    //     const draft = await BriefApi.getBriefDraft(brief.id);
    //     this.dispatch.applyBriefDraft(draft);
    // }

    private async loadUsers() {
        const briefsIds = this.briefs.map(({ id }) => id);
        const filteredTasksByBriefs = this.tasks.filter((task) => lodash.includes(briefsIds, task.briefId));
        // tslint:disable-next-line:max-line-length
        const usersIdsOfTasks = lodash.flatten(
            filteredTasksByBriefs.map(({ authorId, executorId }) => [authorId, executorId]),
        );
        const usersIdsOfActivity = [this.activity.authorId];
        const unloadedUsersIds = lodash.uniq(lodash.compact([...usersIdsOfTasks, ...usersIdsOfActivity]));

        this.users = await UserApi.getUserListFiltered({ ids: unloadedUsersIds });
    }

    private async loadSchemes() {
        this.schemes = await BriefApi.getBriefSchemeList({
            organizationId: this.organizationId,
        });

        const briefsSchemesIds = this.getBriefsSchemesIdsFromBriefs();
        const loadedSchemesIds = this.schemes.map(({ id }) => id);

        const unloadedSchemesId = lodash.difference(briefsSchemesIds, loadedSchemesIds);

        await Promise.all(
            unloadedSchemesId.map(async (schemeId) => {
                const currentScheme = await BriefApi.getBriefSchemeById(schemeId);
                this.schemes.push(currentScheme);
            }),
        );
    }

    private filterTasksBriefsWithoutSchemes(): void {
        this.briefs = this.briefs.filter(({ id, schemeId }) => {
            const currentBriefBelongActivity = id === this.activity.briefId;
            return currentBriefBelongActivity || !lodash.isNil(schemeId);
        });
    }

    private getBriefsSchemesIdsFromBriefs(): string[] {
        return lodash.compact(this.briefs.map(({ schemeId }) => schemeId));
    }

    private formatFilesData(brief: Brief) {
        const updatedBrief = lodash.cloneDeep(brief);

        updatedBrief.blocks.forEach((block) =>
            block.fields.forEach((field) => {
                if (field.value && field.value.files) {
                    const files = FileApi.mapFiles(
                        {
                            activityId: String(this.activityId),
                            fieldId: field.id,
                        },
                        field.value.files as any[],
                        '',
                    ) as any[];
                    field.value.files = files;
                    this.dispatch.addFiles(...files);
                }
            }),
        );

        return updatedBrief;
    }

    private dispatchLoadedDataToStore(): void {
        this.dispatch.loadBriefPage({
            initialActivity: this.activity,
            changedActivity: {
                preparationDate: moment(this.activity.preparationDate),
                realizationStart: moment(this.activity.realizationStart),
                realizationEnd: moment(this.activity.realizationEnd),
                debriefingDate: moment(this.activity.debriefingDate),
            },
            initialTasks: lodash.keyBy(this.tasks, 'briefId'),
            changedTasks: lodash.keyBy(
                this.tasks.map(({ id, briefId, deadline }) => ({
                    id: briefId,
                    briefId,
                    deadline: moment(deadline),
                })),
                'briefId',
            ),
            budgetItem: null,
            briefs: lodash.keyBy(lodash.cloneDeep(this.briefs), 'id'),
            currentBriefs: lodash.keyBy(lodash.cloneDeep(this.briefs), 'id'),
            briefsLoading: lodash.keyBy(
                this.briefs.map(({ id }) => ({ id, loading: false })),
                'id',
            ),
            schemes: this.schemes,
            users: this.users,
        });
    }

    private dispatchLoadedSchemesToStore(): void {
        this.dispatch.setBriefSchemes(this.schemes);
    }
}
