import { createSelector } from 'reselect';
import { isNil } from 'lodash';
import { get } from 'lodash/fp';
import { UserConfigType } from 'sber-marketing-types/openid';

import { getUserConfigState } from '../selectors';
import { DashboardUserConfig } from './types';

export const getDashboardUserConfigState = createSelector(getUserConfigState, get(UserConfigType.Dashboard));

export const getDashboardUserConfig = createSelector(getDashboardUserConfigState, get('fields'));

export const getRawDashboardUserConfig = createSelector(getDashboardUserConfigState, get('rawFields'));

export const getMyTasksPageFilters = createSelector(getDashboardUserConfig, get('myTasksFilters'));

export const getRawMyTasksPageFilters = createSelector(getRawDashboardUserConfig, get('myTasksFilters'));

export const getAvailableActivitiesPageFilters = createSelector(
    getDashboardUserConfig,
    get('availableActivitiesFilters'),
);

export const getRawAvailableActivitiesPageFilters = createSelector(
    getRawDashboardUserConfig,
    get('availableActivitiesFilters'),
);

export const getMyActivitiesPageFilters = createSelector(getDashboardUserConfig, get('myActivitiesFilters'));

export const getRawMyActivitiesPageFilters = createSelector(getRawDashboardUserConfig, get('myActivitiesFilters'));

export const getNewsFilters = createSelector(getDashboardUserConfig, get('newsFilters'));

export const getLoadingStatus = createSelector(getDashboardUserConfigState, get('loadingStatus'));

export const getMyTasksFilterCount = createSelector(
    getDashboardUserConfig,
    ({ myTasksFilters }: DashboardUserConfig): number => {
        let count = 0;

        if (!isNil(myTasksFilters.status)) {
            count++;
        }

        if (!isNil(myTasksFilters.participation)) {
            count++;
        }

        if (myTasksFilters.author.length) {
            count++;
        }

        if (myTasksFilters.executor.length) {
            count++;
        }

        if (myTasksFilters.department.length) {
            count++;
        }

        if (myTasksFilters.workType.length) {
            count++;
        }

        return count;
    },
);

export const getMyActivitiesFilterCount = createSelector(
    getDashboardUserConfig,
    ({ myActivitiesFilters }: DashboardUserConfig): number => {
        let count = 0;

        if (!isNil(myActivitiesFilters.status)) {
            count++;
        }

        if (myActivitiesFilters.author.length) {
            count++;
        }

        if (myActivitiesFilters.responsible.length) {
            count++;
        }

        if (myActivitiesFilters.responsibleDepartment.length) {
            count++;
        }

        if (myActivitiesFilters.activityType.length) {
            count++;
        }

        if (myActivitiesFilters.product.length) {
            count++;
        }

        return count;
    },
);

export const getAvailableActivitiesFilterCount = createSelector(
    getDashboardUserConfig,
    ({ availableActivitiesFilters }: DashboardUserConfig): number => {
        let count = 0;

        if (!isNil(availableActivitiesFilters.status)) {
            count++;
        }

        if (availableActivitiesFilters.author.length) {
            count++;
        }

        if (availableActivitiesFilters.responsible.length) {
            count++;
        }

        if (availableActivitiesFilters.responsibleDepartment.length) {
            count++;
        }

        if (availableActivitiesFilters.activityType.length) {
            count++;
        }

        if (availableActivitiesFilters.product.length) {
            count++;
        }

        return count;
    },
);
