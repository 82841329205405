import * as React from 'react';
import { FileResponse } from 'sber-marketing-types/frontend';
import { AnimatedEllipsis, Icon, IconType } from 'sber-marketing-ui';
import { FileApi, FileApiUploadParams } from '@api';
import { Dictionary } from 'lodash';

import * as styles from './DownloadAllButton.scss';

export interface DownloadAllButtonProps extends React.HTMLProps<HTMLSpanElement> {
    fileName: string;
    files: FileResponse[];
    params: Dictionary<FileApiUploadParams>;
}

export const DownloadAllButton: React.FC<DownloadAllButtonProps> = ({ fileName, files, params, ...props }) => {
    const [isDownloadAllInProgress, setIsDownloadAllProgress] = React.useState(false);

    const handleClick = async () => {
        setIsDownloadAllProgress(true);
        try {
            await FileApi.downloadMultipleFilesAsZip(
                fileName,
                files.map((file) => ({
                    id: file.id,
                    name: file.originName,
                    type: file.type,
                    params: params[file.id],
                })),
            );
        } catch (e) {}
        setIsDownloadAllProgress(false);
    };

    return (
        <span {...props} className={styles.root} onClick={handleClick}>
            <Icon type={IconType.DOWNLOAD_ICON} svgSize={14} />
            {isDownloadAllInProgress ? <AnimatedEllipsis text="Скачиваем" /> : 'Скачать все файлы'}
        </span>
    );
};
