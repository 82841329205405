import * as React from 'react';

import * as style from './ActualCostSummary.scss';

import {} from 'sber-marketing-ui';

interface Props {}

export const ActualCostSummaryTemplate = ({}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.topLine}>
                <div className={style.title}>Согласованный итог по заявке</div>

                <a className={style.createBudgetItemButton} href={'#'}>
                    Создать новый ID исполнения
                </a>
            </div>

            <div className={style.properties}>
                <div className={style.property}>
                    <div className={style.value}>
                        0,00
                        <span className={style.currency}>₽</span>
                    </div>

                    <div className={style.propertyName}>Фактическая стоимость без НДС</div>
                </div>

                <div className={style.property}>
                    <div className={style.value}>
                        0,00
                        <span className={style.currency}>₽</span>
                    </div>

                    <div className={style.propertyName}>НДС</div>
                </div>

                <div className={style.property}>
                    <div className={style.value}>
                        0,00
                        <span className={style.currency}>₽</span>
                    </div>

                    <div className={style.propertyName}>Фактическая стоимость с НДС</div>
                </div>
            </div>
        </div>
    );
};
