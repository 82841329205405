// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-notFound-_notFound__root{position:fixed;display:flex;flex-direction:column;width:100%;height:100vh;color:#4a4a4a;background-color:#f4f4f4;align-items:center;justify-content:center}.src-client-modules-notFound-_notFound__code{font-size:100px}.src-client-modules-notFound-_notFound__message{font-size:20px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/notFound/notFound.scss"],"names":[],"mappings":"AAAA,6CACI,cAAe,CAEf,YAAa,CACb,qBAAsB,CAEtB,UAAW,CACX,YAAa,CAEb,aAAc,CACd,wBAAyB,CAEzB,kBAAmB,CACnB,sBAAuB,CAC1B,6CAGG,eAAgB,CACnB,gDAGG,cAAe","sourcesContent":[".root {\n    position: fixed;\n\n    display: flex;\n    flex-direction: column;\n\n    width: 100%;\n    height: 100vh;\n\n    color: #4a4a4a;\n    background-color: #f4f4f4;\n\n    align-items: center;\n    justify-content: center;\n}\n\n.code {\n    font-size: 100px;\n}\n\n.message {\n    font-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-notFound-_notFound__root",
	"code": "src-client-modules-notFound-_notFound__code",
	"message": "src-client-modules-notFound-_notFound__message"
};
export default ___CSS_LOADER_EXPORT___;
