import type { Spread } from 'lexical';
import {
    DOMConversionMap,
    DOMConversionOutput,
    DOMExportOutput,
    EditorConfig,
    LexicalNode,
    NodeKey,
    SerializedTextNode,
    TextNode,
} from 'lexical';

export type SerializedMentionNode = Spread<
    {
        mention: {
            id: string;
            name: string;
        };
        type: 'mention';
        version: 1;
    },
    SerializedTextNode
>;

function convertMentionElement(domNode: HTMLElement): DOMConversionOutput | null {
    const mentionText = domNode.textContent;
    const mentionId = domNode.getAttribute('data-lexical-mention-user-id');

    if (mentionText !== null) {
        const node = $createMentionNode({ id: mentionId || '', name: mentionText });
        return {
            node,
        };
    }

    return null;
}

const mentionStyle = 'background-color: rgba(24, 119, 232, 0.2)';

export class MentionNode extends TextNode {
    __mention: { id: string; name: string };

    static getType(): string {
        return 'mention';
    }

    static clone(node: MentionNode): MentionNode {
        return new MentionNode(node.__mention, node.__text, node.__key);
    }
    static importJSON(serializedNode: SerializedMentionNode): MentionNode {
        const node = $createMentionNode({ id: serializedNode.mention.id, name: serializedNode.mention.name });
        node.setTextContent(serializedNode.text);
        node.setFormat(serializedNode.format);
        node.setDetail(serializedNode.detail);
        node.setMode(serializedNode.mode);
        node.setStyle(serializedNode.style);
        return node;
    }

    constructor(mention: { id: string; name: string }, text?: string, key?: NodeKey) {
        super(text ?? mention.name, key);
        this.__mention = mention;
    }

    exportJSON(): SerializedMentionNode {
        return {
            ...super.exportJSON(),
            mention: {
                id: this.__mention.id,
                name: this.__mention.name,
            },
            type: 'mention',
            version: 1,
        };
    }

    createDOM(config: EditorConfig): HTMLElement {
        const dom = super.createDOM(config);
        dom.style.cssText = mentionStyle;
        dom.className = 'mention';
        return dom;
    }

    exportDOM(): DOMExportOutput {
        const element = document.createElement('span');
        element.setAttribute('data-lexical-mention', 'true');
        element.setAttribute('data-lexical-mention-user-id', this.__mention.id);
        element.textContent = this.__text;
        return { element };
    }

    isSegmented(): false {
        return false;
    }

    static importDOM(): DOMConversionMap | null {
        return {
            span: (domNode: HTMLElement) => {
                if (!domNode.hasAttribute('data-lexical-mention')) {
                    return null;
                }
                return {
                    conversion: convertMentionElement,
                    priority: 2,
                };
            },
        };
    }

    isTextEntity(): true {
        return true;
    }

    isToken(): true {
        return true;
    }
}

export function $createMentionNode(mention: { name: string; id: string }): MentionNode {
    const mentionNode = new MentionNode(mention);
    mentionNode.setMode('segmented').toggleDirectionless();
    return mentionNode;
}

export function $isMentionNode(node: LexicalNode | null | undefined): node is MentionNode {
    return node instanceof MentionNode;
}
