import { flatMap, includes, uniq, isNil } from 'lodash';

import {
    StatisticsItem,
    Department,
    DepartmentResponse,
    UserResponse,
    StatisticsResponse,
    Filters,
} from '@store/leaderDashboardPage/types';

import { BuilderStatisticsItem } from './types';

type BuildStatisticsItemActivity = BuilderStatisticsItem<'activities'>;
type StatisticsItemActivity = StatisticsItem<'activities'>;
type StatisticsResponseActivity = StatisticsResponse<'activities'>;

interface GroupUsers {
    id: string;
    name: string;
    parentDepartmentId: string;
    childrenUsersIds: string[];
}

export const buildStatisticsItemsActivities: BuildStatisticsItemActivity = ({
    statistics,
    departments,
    users,
    filters,
}) => {
    const departmentsWithChildrenDepartmentsIds = getDepartmentsWitchChildrenDepartmentsIds(departments);
    const groupsUsers = getGroupsUsers(users, departmentsWithChildrenDepartmentsIds, statistics);

    const usersStatisticsItems = convertStatisticsToUsersStatisticsItems(statistics, { users, groupsUsers });

    const groupUserStatisticsItems = buildGroupsUsersStatisticsItems(usersStatisticsItems, {
        statistics,
        groupsUsers,
        filters,
    });

    const departmentsStatisticsItems = buildDepartmentsStatisticsItems(
        [...usersStatisticsItems, ...groupUserStatisticsItems],
        { departments: departmentsWithChildrenDepartmentsIds, filters },
    );

    return [...departmentsStatisticsItems, ...usersStatisticsItems, ...groupUserStatisticsItems];
};

const buildDepartmentsStatisticsItems = (
    statisticsItems: StatisticsItemActivity[],
    params: {
        departments: Department[];
        filters: Filters;
    },
): StatisticsItemActivity[] => {
    return flatMap(
        params.departments
            .filter((department) => isNil(department.parentDepartmentId))
            .map((department) =>
                buildDepartmentStatisticsItem(department, {
                    statisticsItems,
                    departments: params.departments,
                    filters: params.filters,
                }),
            ),
    );
};

const buildDepartmentStatisticsItem = (
    department: Department,
    params: {
        departments: Department[];
        statisticsItems: StatisticsItemActivity[];
        filters: Filters;
    },
): StatisticsItemActivity[] => {
    const descendantsDepartmentsStatisticsItems = department.childrenDepartmentsIds.length
        ? flatMap(
              department.childrenDepartmentsIds.map((childDepartmentId) => {
                  const childDepartment = params.departments.find(({ id }) => id === childDepartmentId);
                  return buildDepartmentStatisticsItem(childDepartment, params);
              }),
          )
        : [];

    const childrenStatisticsItems = [
        ...params.statisticsItems.filter(({ meta }) => meta.parentId === department.id),
        ...descendantsDepartmentsStatisticsItems.filter(({ meta }) => meta.parentId === department.id),
    ];

    return [
        ...descendantsDepartmentsStatisticsItems,
        {
            id: department.id,
            statistics: {
                current: childrenStatisticsItems
                    .filter((childrenStatisticsItem) => getFilterValue(childrenStatisticsItem.id, params.filters))
                    .map((statisticsItem) => statisticsItem.statistics.current)
                    .reduce(
                        (prev, current) => ({
                            activeCount: prev.activeCount + current.activeCount,
                            totalCount: prev.totalCount + current.totalCount,
                            planningCount: prev.planningCount + current.planningCount,
                            withOverdueTasksCount: prev.withOverdueTasksCount + current.withOverdueTasksCount,
                            withOverdueStagesCount: prev.withOverdueStagesCount + current.withOverdueStagesCount,
                        }),
                        {
                            activeCount: 0,
                            totalCount: 0,
                            planningCount: 0,
                            withOverdueTasksCount: 0,
                            withOverdueStagesCount: 0,
                        },
                    ),
                past: childrenStatisticsItems
                    .filter((childrenStatisticsItem) => getFilterValue(childrenStatisticsItem.id, params.filters))
                    .map((statisticsItem) => statisticsItem.statistics.past)
                    .reduce(
                        (prev, current) => ({
                            activeCount: prev.activeCount + current.activeCount,
                            totalCount: prev.totalCount + current.totalCount,
                            planningCount: prev.planningCount + current.planningCount,
                            withOverdueTasksCount: prev.withOverdueTasksCount + current.withOverdueTasksCount,
                            withOverdueStagesCount: prev.withOverdueStagesCount + current.withOverdueStagesCount,
                        }),
                        {
                            activeCount: 0,
                            totalCount: 0,
                            planningCount: 0,
                            withOverdueTasksCount: 0,
                            withOverdueStagesCount: 0,
                        },
                    ),
            },
            meta: {
                name: department.name,
                type: 'department',
                parentId: department.parentDepartmentId,
                childrenIds: childrenStatisticsItems.map(({ id }) => id),
            },
        },
    ];
};

const convertStatisticsToUsersStatisticsItems = (
    statistics: StatisticsResponseActivity,
    params: {
        users: UserResponse[];
        groupsUsers: GroupUsers[];
    },
): StatisticsItemActivity[] => {
    const usersIdsFromStatistics = getUsersIdsFromStatistics(statistics);

    return usersIdsFromStatistics.map((userId) => {
        const user = params.users.find(({ id }) => id === userId);

        const groupUser = params.groupsUsers.find(({ childrenUsersIds }) => includes(childrenUsersIds, String(userId)));

        return {
            id: String(userId),
            statistics: {
                current: statistics.current
                    .filter(({ user }) => user === userId)
                    .reduce(
                        (prev, current) => ({
                            activeCount: prev.activeCount + current.activeCount,
                            totalCount: prev.totalCount + current.totalCount,
                            planningCount: prev.planningCount + current.planningCount,
                            withOverdueTasksCount: prev.withOverdueTasksCount + current.withOverdueTasksCount,
                            withOverdueStagesCount: prev.withOverdueStagesCount + current.withOverdueStagesCount,
                        }),
                        {
                            activeCount: 0,
                            totalCount: 0,
                            planningCount: 0,
                            withOverdueTasksCount: 0,
                            withOverdueStagesCount: 0,
                        },
                    ),
                past: statistics.past
                    .filter(({ user }) => user === userId)
                    .reduce(
                        (prev, current) => ({
                            activeCount: prev.activeCount + current.activeCount,
                            totalCount: prev.totalCount + current.totalCount,
                            planningCount: prev.planningCount + current.planningCount,
                            withOverdueTasksCount: prev.withOverdueTasksCount + current.withOverdueTasksCount,
                            withOverdueStagesCount: prev.withOverdueStagesCount + current.withOverdueStagesCount,
                        }),
                        {
                            activeCount: 0,
                            totalCount: 0,
                            planningCount: 0,
                            withOverdueTasksCount: 0,
                            withOverdueStagesCount: 0,
                        },
                    ),
            },
            meta: {
                name: `${user.secondName} ${user.firstName}`,
                type: 'user',
                parentId: groupUser ? groupUser.id : user.departmentId,
                childrenIds: [],
            },
        };
    });
};

export const buildGroupsUsersStatisticsItems = (
    statisticsItems: StatisticsItemActivity[],
    params: {
        groupsUsers: GroupUsers[];
        statistics: StatisticsResponseActivity;
        filters: Filters;
    },
): StatisticsItemActivity[] => {
    return params.groupsUsers.map((groupUsers) => {
        const childrenStatisticsItems = statisticsItems.filter((statisticItem) =>
            includes(groupUsers.childrenUsersIds, statisticItem.id),
        );

        return {
            id: groupUsers.id,
            statistics: {
                current: childrenStatisticsItems
                    .filter((statisticsItem) => getFilterValue(statisticsItem.id, params.filters))
                    .map((statisticsItem) => statisticsItem.statistics.current)
                    .reduce(
                        (prev, current) => ({
                            activeCount: prev.activeCount + current.activeCount,
                            totalCount: prev.totalCount + current.totalCount,
                            planningCount: prev.planningCount + current.planningCount,
                            withOverdueTasksCount: prev.withOverdueTasksCount + current.withOverdueTasksCount,
                            withOverdueStagesCount: prev.withOverdueStagesCount + current.withOverdueStagesCount,
                        }),
                        {
                            activeCount: 0,
                            totalCount: 0,
                            planningCount: 0,
                            withOverdueTasksCount: 0,
                            withOverdueStagesCount: 0,
                        },
                    ),
                past: childrenStatisticsItems
                    .filter((statisticsItem) => getFilterValue(statisticsItem.id, params.filters))
                    .map((statisticsItem) => statisticsItem.statistics.past)
                    .reduce(
                        (prev, current) => ({
                            activeCount: prev.activeCount + current.activeCount,
                            totalCount: prev.totalCount + current.totalCount,
                            planningCount: prev.planningCount + current.planningCount,
                            withOverdueTasksCount: prev.withOverdueTasksCount + current.withOverdueTasksCount,
                            withOverdueStagesCount: prev.withOverdueStagesCount + current.withOverdueStagesCount,
                        }),
                        {
                            activeCount: 0,
                            totalCount: 0,
                            planningCount: 0,
                            withOverdueTasksCount: 0,
                            withOverdueStagesCount: 0,
                        },
                    ),
            },
            meta: {
                name: groupUsers.name,
                type: 'usersGroup',
                parentId: groupUsers.parentDepartmentId,
                childrenIds: groupUsers.childrenUsersIds,
            },
        };
    });
};

const getDescendantsDepartmentIds = (department: Department, departments: Department[]): string[] => {
    const haveChildrenDepartmentsIds = Boolean(department.childrenDepartmentsIds.length);

    if (haveChildrenDepartmentsIds) {
        const childrenDepartments = departments.filter(({ id }) => includes(department.childrenDepartmentsIds, id));
        return [
            ...department.childrenDepartmentsIds,
            ...flatMap(
                childrenDepartments.map((childDepartment) => getDescendantsDepartmentIds(childDepartment, departments)),
            ),
        ];
    }

    return [];
};

const getDepartmentsWitchChildrenDepartmentsIds = (departments: DepartmentResponse[]): Department[] => {
    return departments.map((department) => ({
        ...department,
        childrenDepartmentsIds: departments
            .filter(({ parentDepartmentId }) => parentDepartmentId === department.id)
            .map(({ id }) => id),
    }));
};

const getDepartmentUsersIdsFromStatistics = ({ id }: Department, statistics: StatisticsResponseActivity): string[] => {
    return uniq([
        ...statistics.current.filter(({ department }) => department === id).map(({ user }) => String(user)),
        ...statistics.past.filter(({ department }) => department === id).map(({ user }) => String(user)),
    ]);
};

const getUsersIdsFromStatistics = (statistics: StatisticsResponseActivity): number[] => {
    return uniq([...statistics.current.map(({ user }) => user), ...statistics.past.map(({ user }) => user)]);
};

const getGroupsUsers = (
    users: UserResponse[],
    departments: Department[],
    statistics: StatisticsResponseActivity,
): GroupUsers[] => {
    return departments
        .filter((department) => department.childrenDepartmentsIds.length)
        .map((department) => ({
            id: `group-users-${department.id}`,
            name: `${department.name}`,
            parentDepartmentId: department.id,
            childrenUsersIds: getDepartmentUsersIdsFromStatistics(department, statistics),
        }));
};

const getFilterValue = (id: string, filters: Filters): boolean => {
    return filters && !isNil(filters[id]) ? filters[id] : true;
};
