import { IconType } from 'sber-marketing-ui';

import type { BuildItems } from './types';

export const buildTaskItems: BuildItems = ({ bar }) => {
    return [
        {
            name: 'Закрытые',
            count: bar.first.value,
            icon: {
                type: IconType.CIRCLE_CLOCK,
                size: 16,
                color: bar.first.color,
                marginLeft: 0,
            },
        },
        {
            name: 'Активные',
            count: bar.second.value,
            icon: {
                type: IconType.TARGET_POINT,
                size: 20,
                color: bar.second.color,
                marginLeft: -4,
            },
        },
        {
            name: 'Скоро дедлайн',
            count: bar.third.value,
            icon: {
                type: IconType.ATTENTION_CIRCLE,
                size: 16,
                color: bar.third.color,
                marginLeft: 0,
            },
        },
        {
            name: 'Просроченные',
            count: bar.fourth.value,
            icon: {
                type: IconType.CIRCLE_BOLD_FILLED_CROSS,
                size: 16,
                color: bar.fourth.color,
                marginLeft: 0,
            },
        },
    ];
};
