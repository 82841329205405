import { actionCreatorFactory } from 'typescript-fsa';

import { TagGroup, SelectedNode } from '../types';

const actionCreator = actionCreatorFactory('TAGS_PAGE_ASYNC').async;

export const loadPageData = actionCreator<string, void, Error>('LOAD_PAGE_DATA');
export const toggleTagGroupFilter = actionCreator<TagGroup, void, Error>('TOGGLE_TAG_GROUP_FILTER');
export const toggleSelectedNode = actionCreator<SelectedNode, void, Error>('TOGGLE_SELECTED_TAG');
export const setSelectedTag = actionCreator<string, void, Error>('SET_SELECTED_TAG');
