import * as React from 'react';
import { graphql, DataProps } from 'react-apollo';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { Correction } from '../types';

import { GET_CORRECTIONS_QUERY } from './graphqlQuery';

interface Props extends QueryProps, ExternalProps {
    children?: (params: RenderParams) => React.ReactNode | React.ReactNode[];
}

type QueryProps = DataProps<{
    corrections: {
        nodes: Correction[];
    };
}>;

interface ExternalProps {
    budgetId: string;
    serialNumber: number;
    children?: (params: RenderParams) => React.ReactNode | React.ReactNode[];
}

interface RenderParams {
    corrections: Correction[];
    loading: boolean;
    updateCorrections: () => Promise<void>;
}

class CorrectionsQuery extends React.Component<Props> {
    public render() {
        const { data } = this.props;

        const corrections = lodash.get(data, 'corrections.nodes') || null;

        return this.props.children({
            corrections: corrections ? this.convertCorrections(corrections) : [],
            loading: data ? data.loading : true,
            updateCorrections: this.updateCorrections,
        });
    }

    private convertCorrections(corrections: Correction[]): Correction[] {
        return corrections.map((correction) => ({
            ...correction,
            params: JSON.parse(correction.params),
        }));
    }

    @autobind
    private async updateCorrections(): Promise<void> {
        await this.props.data.refetch();
    }
}

export const WithCorrections = graphql<ExternalProps>(GET_CORRECTIONS_QUERY, {
    options: ({ budgetId, serialNumber }) => ({
        variables: {
            budgetId,
            serialNumber: [serialNumber],
        },
        fetchPolicy: 'no-cache',
    }),
    skip: ({ budgetId, serialNumber }) => !Boolean(budgetId && serialNumber),
})(CorrectionsQuery);
