import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { sortBy, isNil, get } from 'lodash';
import { Dispatch } from 'redux';

import { TaskApi, ActivityApi, FileApi } from '@api';

import { StoreState } from '@store';
import {
    editByTaskData,
    editByActivityData,
    editTitle,
    editDescription,
    reloadFiles,
    loadTask as loadTaskAction,
    saveTask as saveTaskAction,
    loadActivity as loadActivityAction,
    setPreloaderStatus,
} from '@store/taskEditor/actions';
import { getEditorStatus, getId } from '@store/taskEditor/selectors';
import * as departmentsStore from '@store/departments';
import * as workTypesStore from '@store/workTypes';
import * as usersStore from '@store/users';

import { TaskEditorStatus, SaveTaskParams } from '../types';
import { createTaskWorker } from './createTaskWorker';
import { updateTaskWorker } from './updateTaskWorker';
import { loadDepartments } from '../availableDepartments';

const workTypesEntities = workTypesStore.StoreTypes.ACTIVITY_PAGE_FILTER;
const executorsEntities = usersStore.StoreTypes.ACTIVITY_PAGE_EXECUTORS_FILTER;
const authorsEntities = usersStore.StoreTypes.ACTIVITY_PAGE_AUTHORS_FITLER;
const departmentsEntities = departmentsStore.StoreTypes.ACTIVITY_PAGE_FILTERS;

const applyDraft = async (taskId: string, dispatch: Dispatch<StoreState>) => {
    try {
        const draft = await TaskApi.getDraft(taskId);
        if (!isNil(get(draft, 'title'))) {
            dispatch(editTitle(get(draft, 'title')));
        }
        if (!isNil(get(draft, 'description'))) {
            dispatch(editDescription(get(draft, 'description')));
        }
    } catch (err) {}
};

/** Save (create or update) task on backend */
export const saveTask = bindThunkAction<StoreState, SaveTaskParams, void, Error>(
    saveTaskAction,
    async (params, dispatch, getState) => {
        dispatch(setPreloaderStatus(true));

        const status = getEditorStatus(getState());
        if (status === TaskEditorStatus.CREATING) {
            await createTaskWorker(params.isDraft, params.participants, dispatch, getState);

            dispatch(workTypesStore.resetStore(workTypesEntities));
            dispatch(usersStore.resetStore(executorsEntities));
            dispatch(usersStore.resetStore(authorsEntities));
            dispatch(departmentsStore.resetStore(departmentsEntities));
        } else {
            await updateTaskWorker(dispatch, getState, params.participants);
        }

        dispatch(setPreloaderStatus(false));
    },
);

/** Load task from backend by it's identifier */
export const loadTask = bindThunkAction<StoreState, string, void, Error>(loadTaskAction, async (id, dispatch) => {
    const task = (await TaskApi.getTask(id)).task;
    dispatch(loadDepartments(task.workTypeId));
    dispatch(editByTaskData(task));

    const files = sortBy(await FileApi.mapFiles({ taskId: id }, task.files, String(task.authorId)), 'createdAt');
    dispatch(reloadFiles(files));

    const activity = await ActivityApi.getActivity(task.activityId);
    dispatch(editByActivityData(activity));

    await applyDraft(id, dispatch);
});

/** Load activity from backend by it's identifier */
export const loadActivity = bindThunkAction<StoreState, number, void, Error>(
    loadActivityAction,
    async (id, dispatch, getState) => {
        const activity = await ActivityApi.getActivity(id);
        dispatch(editByActivityData(activity));
        await applyDraft(getId(getState()), dispatch);
    },
);
