import * as React from 'react';
import { useSelector } from 'react-redux';

import { Texts } from './Texts';
import { StoreState } from '@store';
import { contextIsFinished } from '@store/autopilot/selectors';

import * as styles from './styles.scss';

interface Props {}

export const ContextBlock: React.FC<Props> = ({}) => {
    const display = useSelector((state: StoreState) => contextIsFinished(state));

    return display ? (
        <div className={styles.root}>
            <div className={styles.title}>Креативы - Контекстная реклама</div>
            <Texts />
        </div>
    ) : null;
};
