import * as React from 'react';

import { Input, InputProps, InputValues } from '../../main';
import { FormField, useFormField } from '../Form';

export interface FormInputProps<V extends InputValues> extends Omit<InputProps<V>, keyof FormField>, FormField {}

export function FormInput<V extends InputValues>(props: FormInputProps<V>) {
    const { required, value, onChange, inputRef, name, defaultValue, errorMessage, rest } = useFormField(props);

    return (
        <Input
            {...rest}
            value={value}
            required={required}
            inputRequired={false}
            onChange={onChange}
            inputRef={inputRef}
            name={name}
            error={errorMessage}
            defaultValue={defaultValue}
        />
    );
}
