import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconType } from 'sber-marketing-ui';

import {
    formatMoneyForTooltip,
    formatMoney,
    formatMoneyForCorrectionValue,
    formatMoneyForCorrectionValueTooltip,
} from '../utils';

import * as style from './TableWithReserveCorrection.scss';

export interface FundRow {
    name: string;
    plan: number;
    reserve: FundCell;
    fact: number;
}

export interface FundCell {
    currentValue: number;
    correctionValue: number;
}

interface Props {
    fundsRows: FundRow[];
}

export const TableWithReserveCorrection: React.FC<Props> = ({ fundsRows }) => {
    const sumPlansFunds = fundsRows.reduce((acc, { plan }) => acc + plan, 0);
    const sumFactFunds = fundsRows.reduce((acc, { fact }) => acc + fact, 0);

    const sumCurrentReserveFunds = fundsRows.reduce((acc, { reserve }) => acc + reserve.currentValue, 0);
    const sumCorrectionReserveFunds = fundsRows.reduce((acc, { reserve }) => acc + reserve.correctionValue, 0);

    const isChangedSumReserveFunds =
        sumCorrectionReserveFunds !== 0 && sumCorrectionReserveFunds !== sumCurrentReserveFunds;
    const isPositiveReserveFundsDelta = sumCorrectionReserveFunds > 0;

    return (
        <div>
            <div className={classNames(style.tableRow, style.tableRowHeader)}>
                <div className={classNames(style.tableCell, style.tableCellAccent)}>Месяц</div>
                <div className={classNames(style.tableCell, style.tableCellAccent)}>План, Тыс, ₽</div>
                <div className={classNames(style.tableCell, style.tableCellAccent)}>Резерв, Тыс, ₽</div>
                <div className={classNames(style.tableCell, style.tableCellAccent)}>Факт, Тыс, ₽</div>
            </div>
            {fundsRows.map(({ name, plan, reserve, fact }) => (
                <div className={style.tableRow} key={name}>
                    <div className={style.tableCell}>{name}</div>
                    <div className={style.tableCell} title={formatMoneyForTooltip(plan)}>
                        {formatMoney(plan)}
                    </div>
                    <div className={classNames(style.tableCell, style.tableCellFound)}>
                        <div title={formatMoneyForTooltip(reserve.currentValue)}>
                            {formatMoney(reserve.currentValue)}
                        </div>
                        {reserve.correctionValue !== 0 && (
                            <div
                                title={formatMoneyForCorrectionValueTooltip(reserve.correctionValue)}
                                className={
                                    reserve.correctionValue < 0
                                        ? style.negativeCorrectionValue
                                        : style.positiveCorrectionValue
                                }
                            >
                                {formatMoneyForCorrectionValue(reserve.correctionValue)}
                            </div>
                        )}
                    </div>
                    <div className={style.tableCell} title={formatMoneyForTooltip(fact)}>
                        {formatMoney(fact)}
                    </div>
                </div>
            ))}
            <div className={classNames(style.tableRow, style.tableRowFooter)}>
                <div className={classNames(style.tableCell, style.tableCellAccent)}>Итог, тыс. ₽</div>
                <div className={style.tableCell} title={formatMoneyForTooltip(sumPlansFunds)}>
                    {formatMoney(sumPlansFunds)}
                </div>
                <div
                    className={classNames(style.tableCell, isChangedSumReserveFunds && style.tableCellWithArrow)}
                    title={formatMoneyForTooltip(sumCurrentReserveFunds + sumCorrectionReserveFunds)}
                >
                    {isChangedSumReserveFunds ? (
                        isPositiveReserveFundsDelta ? (
                            <Icon style={{ color: '#19BB4F' }} type={IconType.ARROW_UP} svgSize={10} />
                        ) : (
                            <Icon style={{ color: '#E63900' }} type={IconType.ARROW_DOWN} svgSize={10} />
                        )
                    ) : null}
                    {formatMoney(sumCurrentReserveFunds + sumCorrectionReserveFunds)}
                </div>
                <div className={style.tableCell} title={formatMoneyForTooltip(sumFactFunds)}>
                    {formatMoney(sumFactFunds)}
                </div>
            </div>
        </div>
    );
};
