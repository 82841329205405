import * as React from 'react';
import { Moment } from 'moment';
import { Icon, IconType, DatepickerContent } from 'sber-marketing-ui';

import { WithEditor } from '../WithEditor';

import { CardSubInfo } from '@common/Card';
import { Utils } from '@common/Utils';

import * as style from './Date.scss';

interface Props {
    date: Moment;
    canEdit: boolean;
    editDate?: (date: Moment) => Promise<void>;
}

export function Date(props: Props): JSX.Element {
    const { date, canEdit, editDate } = props;

    return (
        <WithEditor
            canEdit={canEdit}
            loadingClassName={style.skeleton}
            valueContent={() => (
                <CardSubInfo qaId="taskCardDate" qaTitle="Дата" qaValue={formatDate(date)}>
                    <div className={style.cardSubInfo}>
                        <CalendarIcon />

                        {formatDate(date)}
                    </div>
                </CardSubInfo>
            )}
            editorContent={(onValueEdit) => <DatepickerContent date={date} onChange={onValueEdit} />}
            editValue={editDate}
        />
    );
}

const formatDate = (date: Moment) => {
    return Utils.formatDateWithYear(date);
};

const CalendarIcon: React.FC = () => (
    <div className={style.calendarIconWrapper}>
        <Icon className={style.calendarIcon} type={IconType.CALENDAR_REDESIGN_TWO} svgSize={14} />
    </div>
);
