import * as React from 'react';
import { groupBy } from 'lodash';
import { ActivityFileGroup } from 'sber-marketing-types/frontend';

import { DragNDrop } from '@common/DragNDrop';

import { LoadingFileData } from './';
import { GroupedFiles } from './WithFileQuery';
import { FileDeleteConfirm } from './FileDeleteConfirm';
import { FileList } from './FileList';

import * as style from './ActivityFiles.scss';

interface Props {
    filesByGroup: GroupedFiles;
    loadingFilesData: LoadingFileData[];
    deletionFileName: string;
    onFileInput: (files: File[], fileCategory: ActivityFileGroup) => void;
    onDeleteClick: (fileId: string) => void;
    onDeleteConfirmButtonClick: () => void;
    onDeleteCancelButtonClick: () => void;
}

export function ActivityFiles(props: Props): JSX.Element {
    const loadingFilesDataByCategory = groupBy(props.loadingFilesData, (fileData) => fileData.fileCategory);

    return (
        <React.Fragment>
            <FilesBlock
                title={'Медиаплан'}
                category={ActivityFileGroup.MEDIAPLAN}
                {...props}
                loadingFilesData={loadingFilesDataByCategory[ActivityFileGroup.MEDIAPLAN] || []}
            />
            <FilesBlock
                title={'Заявка на креатив (если есть)'}
                category={ActivityFileGroup.CREATIVE}
                {...props}
                loadingFilesData={loadingFilesDataByCategory[ActivityFileGroup.CREATIVE] || []}
            />

            {props.deletionFileName && (
                <FileDeleteConfirm
                    fileName={props.deletionFileName}
                    onConfirmButtonClick={props.onDeleteConfirmButtonClick}
                    onCancelButtonClick={props.onDeleteCancelButtonClick}
                />
            )}
        </React.Fragment>
    );
}

interface FilesBlockProps extends Props {
    title: string;
    category: ActivityFileGroup;
}

function FilesBlock({ title, category, filesByGroup, loadingFilesData, onFileInput, onDeleteClick }: FilesBlockProps) {
    const files = filesByGroup[category];

    return (
        <div
            className={style.filesBlock}
            {...{
                'qa-id': 'budgetExecutionActivityFiles',
            }}
        >
            <div className={style.caption}>{title}</div>

            <DragNDrop onFileInput={(files) => onFileInput(files, category)} />

            {!!(files.length + loadingFilesData.length) && (
                <div
                    className={style.fileList}
                    {...{
                        'qa-id': 'budgetExecutionActivityFilesList',
                    }}
                >
                    <FileList loadingFilesData={loadingFilesData} files={files} onDeleteButtonClick={onDeleteClick} />
                </div>
            )}
        </div>
    );
}
