import { ScrollWatcher } from './ScrollWatcher';

/** News column scroll watcher */
export class NewsColumnScrollWatcher extends ScrollWatcher {
    /** Page content element identifier */
    public static readonly NEWS_COLUMN_CONTENT_ID: string = 'newsColumn';
    /** */
    public constructor() {
        super(NewsColumnScrollWatcher.NEWS_COLUMN_CONTENT_ID);
    }
}
