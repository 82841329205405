import { values } from 'lodash';

import { CreativeRequestStatus } from '@api';
import { FieldName } from '@store/creative/types';
import { RequestStageForm } from '@store/creative/types';

const DEFAULT_STATUS = CreativeRequestStatus.draft;

export type Stage = 1 | 1.5 | 2 | 3 | 4 | 5 | 6;

export const stageByStatusMap: Record<CreativeRequestStatus, Stage> = {
    [CreativeRequestStatus.draft]: 1,
    [CreativeRequestStatus.created]: 1.5,
    [CreativeRequestStatus.received]: 1.5,
    [CreativeRequestStatus.assigned]: 2,
    [CreativeRequestStatus.transferred]: 3,
    [CreativeRequestStatus.onApprovement]: 4,
    [CreativeRequestStatus.budgetAgreed]: 4,
    [CreativeRequestStatus.partiallyApproved]: 4,
    [CreativeRequestStatus.approved]: 4,
    [CreativeRequestStatus.closed]: 5,
    [CreativeRequestStatus.deleted]: 6,
};

export const getStageByStatus = (status: CreativeRequestStatus): number => {
    return stageByStatusMap[status] || stageByStatusMap[DEFAULT_STATUS];
};

const READABLE_FIELD_NAME_MAP: Record<FieldName, string> = {
    // [FieldName.Lot]: 'Лот',
    // [FieldName.Description]: 'Краткое описание',
    // [FieldName.Expert]: 'not',
    // [FieldName.AgencyExecutor]: 'Not',
    // [FieldName.Block]: 'Блок',
    // [FieldName.Division]: 'Дивизион бизнес заказчика',
    // [FieldName.Segment]: 'Сегмент',
    // [FieldName.Product]: 'Продукт',
    // [FieldName.Channel]: 'Канал',
    // [FieldName.Initiator]: 'Инициатор заявки на креатив',
    // [FieldName.Customer]: 'not',
    [FieldName.BudgetItemId]: 'not',
};

export const getReadableFieldName = (filedName: FieldName) => {
    return READABLE_FIELD_NAME_MAP[filedName] || 'Поле';
};

export const defineValidRequestStageForm = (creativeRequireForm: RequestStageForm): boolean => {
    return values(creativeRequireForm)
        .filter(({ required }) => required)
        .every(({ value }) => !!value);
};
