import * as React from 'react';

import * as style from './CancelableInputCell.scss';

import { Input_redesign as Input, InputTheme_redesign as InputTheme } from 'sber-marketing-ui';

interface Props {
    title: string;
    placeholder: string;
    customStyle?: any;
    onValueChange: (value: string) => void;
}

export const CancelableInputCellEdit = ({ title, placeholder, customStyle, onValueChange }: Props): JSX.Element => {
    return (
        <div className={style.root} style={{ ...customStyle }}>
            <Input
                value={title}
                placeholder={placeholder}
                theme={InputTheme.Borderless}
                onBlur={(event, value) => onValueChange(value as string)}
                valueType={'number'}
                autofocus
            />
        </div>
    );
};
