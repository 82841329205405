// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-ExcessReserveWarning-_ExcessReserveWarning__accentText{font-weight:600}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetCorrections/CorrectionsList/CardTypes/common/ExcessReserveWarning/ExcessReserveWarning.scss"],"names":[],"mappings":"AAAA,gJACI,eAAgB","sourcesContent":[".accentText {\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accentText": "src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-ExcessReserveWarning-_ExcessReserveWarning__accentText"
};
export default ___CSS_LOADER_EXPORT___;
