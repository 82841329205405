import * as React from 'react';
import autobind from 'autobind-decorator';

import { ColumnName, CustomColumnColor } from '@store/budgetExecution/types';

import { CellBackgroundColor } from '../../LayerManager';

import { DatepickerCell, DatepickerContent } from './DatepickerCell';

interface Props {
    lineId: string;
    columnName: ColumnName;
    title: string;
    value: Date;
    originalValue: Date;
    minValue?: Date;
    maxValue?: Date;
    bgColor: CellBackgroundColor | CustomColumnColor;
    disabled: boolean;
    tooltip?: string;
    onOpenerClick: (
        columnName: ColumnName,
        lineId: string,
        datepickerContent: JSX.Element,
        contentHeight: number,
    ) => void;
    onValueChange: (lineId: string, columnName: ColumnName, value: Date, originalValue: Date) => void;
}

interface State {
    isOpened: boolean;
}

const MEAN_DATEPICKER_HEIGHT = 205;

export class DatepickerCellContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isOpened: false,
        };
    }

    public render(): JSX.Element {
        const { title, bgColor, disabled, columnName, tooltip } = this.props;

        return React.createElement(DatepickerCell, {
            columnName,
            title,
            bgColor,
            disabled,
            tooltip,
            onOpenerClick: this.onOpenerClick,
        });
    }

    @autobind
    protected onOpenerClick() {
        this.setState(
            {
                isOpened: !this.state.isOpened,
            },
            () => {
                window.addEventListener('keydown', this.onKeyDown);
                this.updateDatepicker();
            },
        );
    }

    @autobind
    protected onMaskClick() {
        this.setState(
            {
                isOpened: false,
            },
            () => {
                this.removeKeyDownListener();

                if (this.props.onOpenerClick) {
                    this.props.onOpenerClick(this.props.columnName, this.props.lineId, null, null);
                }
            },
        );
    }

    @autobind
    protected onKeyDown(event: KeyboardEvent) {
        if (event.keyCode === 8) {
            this.onDateSelection(null);
        }
    }

    @autobind
    protected onDateSelection(value: Date) {
        const { lineId, columnName, originalValue } = this.props;

        const fixedTimeDate = new Date(value.setHours(3, 0, 0, 0));

        this.props.onValueChange(lineId, columnName, fixedTimeDate, originalValue);

        this.setState({
            isOpened: false,
        });

        if (this.props.onOpenerClick) {
            this.props.onOpenerClick(columnName, lineId, null, null);
        }

        this.removeKeyDownListener();
    }

    private updateDatepicker() {
        if (this.state.isOpened) {
            const { value, maxValue, minValue } = this.props;

            const content = DatepickerContent({
                value: value as Date,
                maxValue,
                minValue,
                onDateSelection: this.onDateSelection,
                onMaskClick: this.onMaskClick,
            });

            this.props.onOpenerClick(this.props.columnName, this.props.lineId, content, MEAN_DATEPICKER_HEIGHT);
        }
    }

    private removeKeyDownListener() {
        window.removeEventListener('keydown', this.onKeyDown);
    }
}
