// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-XLSBuilder-_XLSBuilder__root{display:inline-flex;align-items:center;height:32px;padding:0 12px;background:#ffffff;box-shadow:0px 2px 6px rgba(0,0,0,0.1);border-radius:6px;user-select:none;cursor:pointer}.src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-XLSBuilder-_XLSBuilder__title{color:#000000;font-family:'Open Sans';font-size:12px;line-height:14px}.src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-XLSBuilder-_XLSBuilder__icon{width:20px;height:20px;display:flex;align-items:center;justify-content:center;margin-right:5px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilotTv/StageWidgets/MediaplanStageWidget/XLSBuilder/XLSBuilder.scss"],"names":[],"mappings":"AAAA,8GACI,mBAAoB,CACpB,kBAAmB,CACnB,WAAY,CACZ,cAAe,CACf,kBAAmB,CACnB,sCAA0C,CAC1C,iBAAkB,CAClB,gBAAiB,CACjB,cAAe,CAClB,+GAGG,aAAc,CACd,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACpB,8GAGG,UAAW,CACX,WAAY,CACZ,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,gBAAiB","sourcesContent":[".root {\n    display: inline-flex;\n    align-items: center;\n    height: 32px;\n    padding: 0 12px;\n    background: #ffffff;\n    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);\n    border-radius: 6px;\n    user-select: none;\n    cursor: pointer;\n}\n\n.title {\n    color: #000000;\n    font-family: 'Open Sans';\n    font-size: 12px;\n    line-height: 14px;\n}\n\n.icon {\n    width: 20px;\n    height: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-XLSBuilder-_XLSBuilder__root",
	"title": "src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-XLSBuilder-_XLSBuilder__title",
	"icon": "src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-XLSBuilder-_XLSBuilder__icon"
};
export default ___CSS_LOADER_EXPORT___;
