import * as React from 'react';
import * as styles from './DownloadAllButton.scss';
import { FileApi } from '@api';
import { FileWithCreativeRequestItem } from '../hooks';
import { AnimatedInProgressIcon } from '@modules/common/AnimatedInProgressIcon';
import { Icon, IconType, TooltipAnchor, WithTooltip } from 'sber-marketing-ui';

interface DownloadAllButtonProps {
    fileGroupTitle: string;
    files: FileWithCreativeRequestItem[];
}

export function DownloadAllButton({ fileGroupTitle, files }: DownloadAllButtonProps): JSX.Element {
    const [downloadInProgress, setDownloadInProgress] = React.useState(false);

    async function initDownload() {
        setDownloadInProgress(true);

        await FileApi.downloadMultipleFilesAsZip(
            `Файлы ${fileGroupTitle}`,
            files.map((file) => {
                const { id, originName, type } = file.file.model;

                return {
                    id,
                    name: originName,
                    type,
                    params: { fileId: id },
                };
            }),
        );

        setDownloadInProgress(false);
    }

    if (files?.length < 2) {
        return null;
    }

    return downloadInProgress ? (
        <div className={styles.button}>
            <AnimatedInProgressIcon />
        </div>
    ) : (
        // <WithTooltip spanWrapper anchor={TooltipAnchor.LEFT} content="Скачать все файлы">
        <div className={styles.flex} onClick={initDownload}>
            <div className={styles.title}>Скачать все файлы</div>
            {/* <Icon
                    type={IconType.DOWNLOAD_ICON}
                    svgSize={18}
                    className={styles.buttonClickable}
                    onClick={initDownload}
                /> */}
        </div>
        // </WithTooltip>
    );
}
