// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-brief-Brief-Block-Field-FieldTypes-Dropdown-_Dropdown__root{position:relative;width:100%;height:80px;padding-top:4px}.src-client-modules-brief-Brief-Block-Field-FieldTypes-Dropdown-_Dropdown__iconTooltip{position:absolute;top:8px;right:4px;display:flex;font-size:20px}.src-client-modules-brief-Brief-Block-Field-FieldTypes-Dropdown-_Dropdown__tooltipMessage{text-align:left}\n", "",{"version":3,"sources":["webpack://./src/client/modules/brief/Brief/Block/Field/FieldTypes/Dropdown/Dropdown.scss"],"names":[],"mappings":"AAAA,gFACI,iBAAkB,CAElB,UAAW,CACX,WAAY,CACZ,eAAgB,CACnB,uFAGG,iBAAkB,CAClB,OAAQ,CACR,SAAU,CAEV,YAAa,CACb,cAAe,CAClB,0FAGG,eAAgB","sourcesContent":[".root {\n    position: relative;\n\n    width: 100%;\n    height: 80px;\n    padding-top: 4px;\n}\n\n.iconTooltip {\n    position: absolute;\n    top: 8px;\n    right: 4px;\n\n    display: flex;\n    font-size: 20px;\n}\n\n.tooltipMessage {\n    text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-brief-Brief-Block-Field-FieldTypes-Dropdown-_Dropdown__root",
	"iconTooltip": "src-client-modules-brief-Brief-Block-Field-FieldTypes-Dropdown-_Dropdown__iconTooltip",
	"tooltipMessage": "src-client-modules-brief-Brief-Block-Field-FieldTypes-Dropdown-_Dropdown__tooltipMessage"
};
export default ___CSS_LOADER_EXPORT___;
