import * as React from 'react';
import classNames from 'classnames';

import * as styles from './styles.scss';

export interface Props {
    text?: string;
    disabled: boolean;
    onClick(): void;
}

export const ConfirmButton: React.FC<Props> = ({ text = 'Подтвердить', disabled = false, onClick = () => {} }) => {
    return (
        <div className={classNames(styles.root, disabled && styles.disabled)} onClick={!disabled ? onClick : null}>
            {text}
        </div>
    );
};
