import * as React from 'react';

import { Card } from '../types';

import { CardsList } from './CardsList';
import { PageScrollWatcher } from '@common/PageScrollWatcher';

interface Props {
    cards: Card[];
    isLoadingCards: boolean;
    totalCardsCount: number;
    fetchMoreCards: () => Promise<void>;
    updateCards: () => Promise<void>;
}

export class CardsListContainer extends React.PureComponent<Props> {
    private pageScrollWatcher: PageScrollWatcher = new PageScrollWatcher();

    public componentDidMount() {
        this.pageScrollWatcher.reInit();
        this.pageScrollWatcher.on(PageScrollWatcher.SCROLLED_DOWN_EVENT, this.props.fetchMoreCards);
    }

    public componentWillUnmount() {
        this.pageScrollWatcher.dispose();
    }

    public async componentDidUpdate(prevProps: Props): Promise<void> {
        const cardsCountChanged = this.props.cards.length !== prevProps.cards.length;
        const isBodyScrolledDown = this.pageScrollWatcher.isScrolledDown();

        if (cardsCountChanged && isBodyScrolledDown) {
            this.props.fetchMoreCards();
        }
    }

    public render(): JSX.Element {
        const { ...props } = this.props;

        return <CardsList {...props} />;
    }
}
