import { UserResponseParams } from 'sber-marketing-types/frontend';
import { BudgetItemSnapshot } from '@mrm/budget';

import { DifferencesTitles, DifferenceTransformer } from './types';

export const usersDifference: DifferenceTransformer = function (prev, curr, item) {
    function transformer(obj: BudgetItemSnapshot): string {
        return obj[item]
            ? obj[item].map((item: UserResponseParams) => `${item.secondName} ${item.firstName}`).join(', ')
            : null;
    }

    return [
        {
            title: DifferencesTitles[item],
            before: transformer(prev),
            after: transformer(curr),
        },
    ];
};
