import * as React from 'react';
import * as style from './TmHeader.scss';
import { TmHeaderDropdown } from './dropdown/TmHeaderDropdown';
import { inject, observer } from 'mobx-react';
import { TmStoreProps } from '../../store/TmStore';
import { SortDirection } from '../../Trademark';

export const TmHeaderComponent = ({ tms }: TmStoreProps) => {
    const onSortingChange = (fieldName: string, direction: SortDirection) => {
        tms.setSort(fieldName, direction);
    };

    const onFilterChange = (fieldName: string, items: React.ReactText[]) => {
        tms.setFilter(fieldName, items);
    };

    return (
        <div className={style.header}>
            {Object.entries(tms.fieldNames).map(([fieldName, { name, sort, filterItems = [] }]) => (
                <TmHeaderDropdown
                    key={fieldName}
                    title={name}
                    fieldName={fieldName}
                    sort={sort}
                    onSortingChange={onSortingChange}
                    filterItems={tms.getFilterItemsByFieldName(fieldName)}
                    filterCheckedItems={filterItems}
                    onFilterChange={onFilterChange}
                />
            ))}
        </div>
    );
};

export const TmHeader = inject('tms')(observer(TmHeaderComponent));
