import { Partnership } from 'sber-marketing-types/backend';

import { useGetUserOrganizationsQuery } from '@api';

import { useAuthUserId } from '../useAuthUserId';
import { useMemo } from 'react';

export interface UseAuthUserOrganizations {
    isLoading: boolean;
    organizations?: Partnership[];
}

export function useAuthUserOrganizations(skip?: boolean): UseAuthUserOrganizations {
    const { isLoading: isUserLoading, authUserId } = useAuthUserId(skip);
    const { isLoading: isOrganisationsLoading, data: organizationsData } = useGetUserOrganizationsQuery(authUserId, {
        skip: !authUserId,
    });
    const isLoading = isUserLoading || isOrganisationsLoading;
    const organizations = useMemo(
        () => (organizationsData ? [organizationsData.organization, ...organizationsData.contractors] : []),
        [organizationsData],
    );

    return {
        isLoading,
        organizations,
    };
}
