import * as React from 'react';
import { Helmet } from 'react-helmet';

import { UploadingFilesData } from '@store/fileAssets';

export interface UploadingFilesAtTitleContainerProps extends UploadingFilesData {
    hasUploadingFiles: boolean;
}

export class UploadingFilesAtTitleContainer extends React.Component<UploadingFilesAtTitleContainerProps> {
    public static displayName = 'UploadingFilesAtTitle.Container';

    private readonly initialTitle: string = document.title;

    public render() {
        return (
            <Helmet>
                <title>{this.title}</title>
            </Helmet>
        );
    }

    public get title(): string {
        const { hasUploadingFiles, count, loadingProgress } = this.props;
        return hasUploadingFiles ? `Загрузка ${count} / ${loadingProgress}%` : this.initialTitle;
    }
}
