import * as React from 'react';

import { Month } from '@mrm/budget/common';
import { Correction, CardBudgetItem } from '../types';
import { getMonthName, orderOfMonth } from '../utils';

import { TableWithFactCorrection, FundCell, FundRow } from './TableWithFactCorrection';

interface Props {
    budgetItem: CardBudgetItem;
    correction: Correction;
    selectedCorrectionId: string;
}

export class TableWithFactCorrectionContainer extends React.Component<Props> {
    public render() {
        return React.createElement(TableWithFactCorrection, {
            fundsRows: this.buildFoundsRows(),
        });
    }

    private buildFoundsRows(): FundRow[] {
        const { plannedFunds, reservedFunds } = this.props.budgetItem;

        return orderOfMonth.map((month) => ({
            name: getMonthName(month),
            plan: plannedFunds[month] || 0,
            reserve: reservedFunds[month] || 0,
            fact: this.buildFactFundCell(month),
        }));
    }

    private buildFactFundCell(month: Month): FundCell {
        const { factFunds } = this.props.budgetItem;

        return {
            currentValue: factFunds[month] || 0,
            correctionValue: this.getFactCorrectionValueByMonth(month),
        };
    }

    private getFactCorrectionValueByMonth(month: Month): number {
        const { budgetItem, correction } = this.props;

        if (correction && correction.params.hasOwnProperty(month)) {
            return (budgetItem.factFunds[month] || 0) - (correction.params[month] || 0);
        }

        return 0;
    }
}
