import autobind from 'autobind-decorator';
import { PureComponent, createElement } from 'react';
import * as lodash from 'lodash';

import { Breadcrumbs } from './Breadcrumbs';
import { TimeCalculator } from '../../modules';

const LEFT_ARROW_KEYCODE = 37;
const RIGHT_ARROW_KEYCODE = 39;

interface Props {
    selectedYear: number;
    selectedQuarter: number | null;
    selectedMonth: number | null;
    onYearClick(value: number): void;
    onQuarterClick(value: number, year: number): void;
    onMonthClick(value: number, year: number): void;
}

export class BreadcrumbsContainer extends PureComponent<Props> {
    private timeCalculator: TimeCalculator = TimeCalculator.getInstance();

    public componentDidMount() {
        window.addEventListener('keydown', this.onKeyDown);
    }

    public componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyDown);
    }

    public render(): JSX.Element {
        const { selectedYear } = this.props;

        return createElement(Breadcrumbs, {
            selectedYear,
            isMonthSelected: this.isMonthSelected,
            isQuarterSelected: this.isQuarterSelected,
            hasPreviousMonth: this.hasPreviousMonth,
            hasPreviousQuarter: this.hasPreviousQuarter,
            hasPreviousYear: this.hasPreviousYear,
            hasNextMonth: this.hasNextMonth,
            hasNextQuarter: this.hasNextQuarter,
            hasNextYear: this.hasNextYear,
            previousMonthLabel: this.previousMonthLabel,
            previousQuarterLabel: this.previousQuarterLabel,
            previousYearLabel: this.previousYearLabel,
            currentMonthLabel: this.currentMonthLabel,
            currentQuarterLabel: this.currentQuarterLabel,
            nextMonthLabel: this.nextMonthLabel,
            nextQuarterLabel: this.nextQuarterLabel,
            nextYearLabel: this.nextYearLabel,
            onYearClick: this.onYearClick,
            onPreviousMonthClick: this.onPreviousMonthClick,
            onPreviousQuarterClick: this.onPreviousQuarterClick,
            onPreviousYearClick: this.onPreviousYearClick,
            onCurrentQuarterClick: this.onCurrentQuarterClick,
            onNextMonthClick: this.onNextMonthClick,
            onNextQuarterClick: this.onNextQuarterClick,
            onNextYearClick: this.onNextYearClick,
        });
    }

    private get previousYear(): number {
        const { selectedYear } = this.props;
        const { years } = this.timeCalculator;

        const selectedYearIndex = lodash.findIndex(years, (item) => item.year == selectedYear);

        return selectedYearIndex > 0 ? years[selectedYearIndex - 1].year : null;
    }

    private get nextYear(): number {
        const { selectedYear } = this.props;
        const { years } = this.timeCalculator;

        const selectedYearIndex = lodash.findIndex(years, (item) => item.year == selectedYear);

        return selectedYearIndex < years.length - 1 ? years[selectedYearIndex + 1].year : null;
    }

    private get isQuarterSelected(): boolean {
        return !lodash.isNil(this.props.selectedQuarter);
    }

    private get isMonthSelected(): boolean {
        return !lodash.isNil(this.props.selectedMonth);
    }

    private get hasPreviousYear(): boolean {
        return !!this.previousYear;
    }

    private get hasPreviousMonth(): boolean {
        const { selectedMonth, selectedYear } = this.props;
        let result = false;
        if (this.isMonthSelected) {
            if (this.previousYear && selectedYear > this.previousYear) {
                result = true;
            } else {
                result = selectedMonth > TimeCalculator.FIRST_MONTH;
            }
        }
        return result;
    }

    private get hasPreviousQuarter(): boolean {
        const { selectedQuarter, selectedYear } = this.props;
        let result = false;
        if (this.isQuarterSelected && !this.isMonthSelected) {
            if (this.previousYear && selectedYear > this.previousYear) {
                result = true;
            } else {
                result = selectedQuarter > TimeCalculator.FIRST_QUARTER;
            }
        }

        return result;
    }

    private get hasNextMonth(): boolean {
        const { selectedMonth, selectedYear } = this.props;
        let result = false;
        if (this.isMonthSelected) {
            if (this.nextYear && selectedYear < this.nextYear) {
                result = true;
            } else {
                result = selectedMonth < TimeCalculator.LAST_MONTH;
            }
        }
        return result;
    }

    private get hasNextQuarter(): boolean {
        const { selectedQuarter, selectedYear } = this.props;
        let result = false;
        if (this.isQuarterSelected && !this.isMonthSelected) {
            if (this.nextYear && selectedYear < this.nextYear) {
                result = true;
            } else {
                result = selectedQuarter < TimeCalculator.LAST_QUARTER;
            }
        }
        return result;
    }

    private get hasNextYear(): boolean {
        return !!this.nextYear;
    }

    private get previousMonthLabel(): string | null {
        let result: string | null = null;

        if (this.hasPreviousMonth) {
            const { selectedMonth, selectedYear } = this.props;
            const { years } = this.timeCalculator;

            let previousMonth: number;
            let yearOfPreviousMonth: number;

            if (selectedMonth === TimeCalculator.FIRST_MONTH) {
                const selectedYearIndex = lodash.findIndex(years, (item) => item.year == selectedYear);
                const previousYear = years[selectedYearIndex - 1].year;

                previousMonth = TimeCalculator.LAST_MONTH;
                yearOfPreviousMonth = previousYear;
            } else {
                previousMonth = selectedMonth - 1;
                yearOfPreviousMonth = selectedYear;
            }

            result = `${TimeCalculator.MONTH_NAMES[previousMonth - 1]} ${yearOfPreviousMonth}`;
        }

        return result;
    }

    private get previousQuarterLabel(): string | null {
        let result: string | null = null;

        if (this.hasPreviousQuarter) {
            const { selectedQuarter, selectedYear } = this.props;
            const { years } = this.timeCalculator;

            let previousQuarter: number;
            let yearOfPreviousQuarter: number;

            if (selectedQuarter === TimeCalculator.FIRST_QUARTER) {
                const selectedYearIndex = lodash.findIndex(years, (item) => item.year == selectedYear);
                const previousYear = years[selectedYearIndex - 1].year;

                previousQuarter = TimeCalculator.LAST_QUARTER;
                yearOfPreviousQuarter = previousYear;
            } else {
                previousQuarter = selectedQuarter - 1;
                yearOfPreviousQuarter = selectedYear;
            }

            result = `${previousQuarter} квартал ${yearOfPreviousQuarter}`;
        }

        return result;
    }

    private get previousYearLabel(): string | null {
        return this.previousYear ? this.previousYear.toString() : null;
    }

    private get currentMonthLabel(): string | null {
        let result: string | null = null;

        if (this.isMonthSelected) {
            result = TimeCalculator.MONTH_NAMES[this.props.selectedMonth - 1];
        }

        return result;
    }

    private get currentQuarterLabel(): string | null {
        let result: string | null = null;

        if (this.isQuarterSelected) {
            result = `${this.props.selectedQuarter} квартал`;
        }

        return result;
    }

    private get nextMonthLabel(): string | null {
        let result: string | null = null;

        if (this.hasNextMonth) {
            const { selectedMonth, selectedYear } = this.props;
            const { years } = this.timeCalculator;

            let nextMonth: number;
            let yearOfNextMonth: number;

            if (selectedMonth === TimeCalculator.LAST_MONTH) {
                const selectedYearIndex = lodash.findIndex(years, (item) => item.year == selectedYear);
                const nextYear = years[selectedYearIndex + 1].year;

                nextMonth = TimeCalculator.FIRST_MONTH;
                yearOfNextMonth = nextYear;
            } else {
                nextMonth = selectedMonth + 1;
                yearOfNextMonth = selectedYear;
            }

            result = `${TimeCalculator.MONTH_NAMES[nextMonth - 1]} ${yearOfNextMonth}`;
        }

        return result;
    }

    private get nextQuarterLabel(): string | null {
        let result: string | null = null;

        if (this.hasNextQuarter) {
            const { selectedQuarter, selectedYear } = this.props;
            const { years } = this.timeCalculator;

            let nextQuarter: number;
            let yearOfNextQuarter: number;

            if (selectedQuarter === TimeCalculator.LAST_QUARTER) {
                const selectedYearIndex = lodash.findIndex(years, (item) => item.year == selectedYear);
                const nextYear = years[selectedYearIndex + 1].year;

                nextQuarter = TimeCalculator.FIRST_QUARTER;
                yearOfNextQuarter = nextYear;
            } else {
                nextQuarter = selectedQuarter + 1;
                yearOfNextQuarter = selectedYear;
            }

            result = `${nextQuarter} квартал ${yearOfNextQuarter}`;
        }

        return result;
    }

    private get nextYearLabel(): string | null {
        return this.nextYear ? this.nextYear.toString() : null;
    }

    @autobind
    private onKeyDown(event: KeyboardEvent) {
        if (event.keyCode === LEFT_ARROW_KEYCODE) {
            event.preventDefault();
            this.onLeftArrowClick();
        }

        if (event.keyCode === RIGHT_ARROW_KEYCODE) {
            event.preventDefault();
            this.onRightArrowClick();
        }
    }

    @autobind
    private onLeftArrowClick() {
        if (this.hasPreviousYear && !this.isQuarterSelected) {
            this.onPreviousYearClick();
        }

        if (this.hasPreviousQuarter) {
            this.onPreviousQuarterClick();
        }

        if (this.hasPreviousMonth) {
            this.onPreviousMonthClick();
        }
    }

    @autobind
    private onRightArrowClick() {
        if (this.hasNextYear && !this.isQuarterSelected) {
            this.onNextYearClick();
        }

        if (this.hasNextQuarter) {
            this.onNextQuarterClick();
        }

        if (this.hasNextMonth) {
            this.onNextMonthClick();
        }
    }

    @autobind
    private onYearClick() {
        const { onYearClick, selectedYear } = this.props;
        onYearClick(selectedYear);
    }

    @autobind
    private onPreviousMonthClick() {
        const { selectedYear, selectedMonth, onMonthClick } = this.props;
        const { years } = this.timeCalculator;

        if (selectedMonth === TimeCalculator.FIRST_MONTH) {
            const selectedYearIndex = lodash.findIndex(years, (item) => item.year == selectedYear);
            const previousYear = years[selectedYearIndex - 1].year;

            onMonthClick(TimeCalculator.LAST_MONTH, previousYear);
        } else {
            onMonthClick(selectedMonth - 1, selectedYear);
        }
    }

    @autobind
    private onPreviousQuarterClick() {
        const { selectedYear, selectedQuarter, onQuarterClick } = this.props;
        const { years } = this.timeCalculator;

        if (selectedQuarter === TimeCalculator.FIRST_QUARTER) {
            const selectedYearIndex = lodash.findIndex(years, (item) => item.year == selectedYear);
            const previousYear = years[selectedYearIndex - 1].year;

            onQuarterClick(TimeCalculator.LAST_QUARTER, previousYear);
        } else {
            onQuarterClick(selectedQuarter - 1, selectedYear);
        }
    }

    @autobind
    private onPreviousYearClick() {
        const { selectedYear, onYearClick } = this.props;
        const { years } = this.timeCalculator;

        const selectedYearIndex = lodash.findIndex(years, (item) => item.year == selectedYear);
        const previousYear = years[selectedYearIndex - 1].year;

        onYearClick(previousYear);
    }

    @autobind
    private onNextMonthClick() {
        const { selectedYear, selectedMonth, onMonthClick } = this.props;
        const { years } = this.timeCalculator;

        if (selectedMonth === TimeCalculator.LAST_MONTH) {
            const selectedYearIndex = lodash.findIndex(years, (item) => item.year == selectedYear);
            const nextYear = years[selectedYearIndex + 1].year;

            onMonthClick(TimeCalculator.FIRST_MONTH, nextYear);
        } else {
            onMonthClick(selectedMonth + 1, selectedYear);
        }
    }

    @autobind
    private onNextQuarterClick() {
        const { selectedYear, selectedQuarter, onQuarterClick } = this.props;
        const { years } = this.timeCalculator;

        if (selectedQuarter === TimeCalculator.LAST_QUARTER) {
            const selectedYearIndex = lodash.findIndex(years, (item) => item.year == selectedYear);
            const nextYear = years[selectedYearIndex + 1].year;

            onQuarterClick(TimeCalculator.FIRST_QUARTER, nextYear);
        } else {
            onQuarterClick(selectedQuarter + 1, selectedYear);
        }
    }

    @autobind
    private onNextYearClick() {
        const { selectedYear, onYearClick } = this.props;
        const { years } = this.timeCalculator;

        const selectedYearIndex = lodash.findIndex(years, (item) => item.year == selectedYear);
        const nextYear = years[selectedYearIndex + 1].year;

        onYearClick(nextYear);
    }

    @autobind
    private onCurrentQuarterClick() {
        if (this.isMonthSelected) {
            const { selectedYear, selectedQuarter, onQuarterClick } = this.props;
            onQuarterClick(selectedQuarter, selectedYear);
        }
    }
}
