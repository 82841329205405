import * as React from 'react';
// import * as classnames from 'classnames';
// import * as lodash from 'lodash';

import * as style from './LinkInput.scss';

import { Input_redesign as Input, InputTheme_redesign as InputTheme } from 'sber-marketing-ui';

interface Props {
    link: string;
    value: string;
    placeholder: string;
    inEditMode: boolean;
    readOnly: boolean;
    onInputChange: (value: string) => void;
    onInputBlur: () => void;
    onClearButtonClick: () => void;
}

export const LinkInputTemplate = ({
    link,
    value,
    placeholder,
    inEditMode,
    readOnly,
    onInputChange,
    onInputBlur,
    onClearButtonClick,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            {!inEditMode && (
                <>
                    <a className={style.link} href={link} target="_blank">
                        {value}
                    </a>

                    {!readOnly && (
                        <div className={style.clearButton} onClick={onClearButtonClick}>
                            Стереть
                        </div>
                    )}
                </>
            )}

            {inEditMode && (
                <div className={style.input}>
                    <Input
                        value={value}
                        placeholder={placeholder}
                        theme={InputTheme.Borderless}
                        onInputChange={onInputChange}
                        onBlur={onInputBlur}
                    />
                </div>
            )}
        </div>
    );
};
