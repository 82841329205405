import * as React from 'react';
import * as styles from './styles.scss';

import { FilterKey } from '@store/budgetPlanning';
import { Tag } from './Tag';
import { ColumnsList } from '../../ColumnsConfig';
import { FiltersDropdown } from './FiltersDropdown';
import { FiltersValuesCounts } from '../types';

export interface Props {
    filterNames: FilterKey[];
    filtersValuesCounts: FiltersValuesCounts;
    onTagClose: (filterName: string) => void;
    onApplyFiltersButtonClick: () => void;
}

export const TagsListTemplate: React.FC<Props> = ({
    filterNames,
    filtersValuesCounts,
    onTagClose,
    onApplyFiltersButtonClick,
}) => {
    return (
        <div className={styles.root}>
            {filterNames.map((filterName) => {
                const count = filtersValuesCounts[filterName];
                const readableName =
                    ColumnsList.find((columnConfig) => columnConfig.name === filterName)?.title || filterName;
                const label: JSX.Element = (
                    <Tag name={readableName} count={count} onClose={() => onTagClose(filterName)} />
                );

                return (
                    <FiltersDropdown
                        key={filterName}
                        label={label}
                        filterName={filterName}
                        onApplyFiltersButtonClick={onApplyFiltersButtonClick}
                    />
                );
            })}
        </div>
    );
};
