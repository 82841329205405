// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-AutopilotStatus-_AutopilotStatus__root{display:inline-flex;gap:6px;background:#DEF9E6;border-radius:4px;padding:4px 8px;align-items:center}.src-client-modules-activity-AutopilotStatus-_AutopilotStatus__idle{background:#F6F6F6}.src-client-modules-activity-AutopilotStatus-_AutopilotStatus__icon{color:#18BA4E}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/AutopilotStatus/AutopilotStatus.scss"],"names":[],"mappings":"AAAA,oEACE,mBAAoB,CACpB,OAAQ,CACR,kBAAmB,CACnB,iBAAkB,CAClB,eAAgB,CAChB,kBAAmB,CACpB,oEAGC,kBAAmB,CACpB,oEAWC,aAAc","sourcesContent":[".root {\n  display: inline-flex;\n  gap: 6px;\n  background: #DEF9E6;\n  border-radius: 4px;\n  padding: 4px 8px;\n  align-items: center;\n}\n\n.idle {\n  background: #F6F6F6;\n}\n\n.active {\n\n}\n\n.done {\n\n}\n\n.icon {\n  color: #18BA4E;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-AutopilotStatus-_AutopilotStatus__root",
	"idle": "src-client-modules-activity-AutopilotStatus-_AutopilotStatus__idle",
	"icon": "src-client-modules-activity-AutopilotStatus-_AutopilotStatus__icon"
};
export default ___CSS_LOADER_EXPORT___;
