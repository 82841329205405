import * as React from 'react';
import { keys } from 'lodash';

import { TooltipOfFirstStageTemplate } from './TooltipOfFirstStageTemplate';
import type { TooltipOfFirstStageTemplateProps } from './TooltipOfFirstStageTemplate';
import type { WithTooltipProps, Param, FieldType } from './types';

import { getReadableFieldName, getReadableTypeName } from './utils';

interface Props extends WithTooltipProps {
    validCreativeRequireParams: Param[];
}

export class TooltipOfFirstStageBehavior extends React.PureComponent<Props> {
    public render(): JSX.Element {
        return React.createElement(TooltipOfFirstStageTemplate, {
            ...this.props,
            params: this.getParams(),
        });
    }

    private getParams(): TooltipOfFirstStageTemplateProps['params'] {
        const { validCreativeRequireParams } = this.props;

        return validCreativeRequireParams
            .filter(({ fields }) => !keys(fields).every((fieldKey) => fields[fieldKey]))
            .map(({ id, type, fields }) => ({
                id,
                title: getReadableTypeName(type),
                fields: keys(fields)
                    .filter((fieldType) => !fields[fieldType])
                    .map((fieldType) => ({
                        name: getReadableFieldName(fieldType as FieldType),
                    })),
            }));
    }
}
