import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { StoreState } from '../..';
import { asyncActions } from '../actions';
import { TaskApi } from '../../../api/TaskApi';
import { getTaskInfo } from '../selectors';
import { BulkAddParticipatorsStartPayload } from '../types';

export const bulkAddParticipators = bindThunkAction<
    StoreState,
    BulkAddParticipatorsStartPayload,
    void,
    BulkAddParticipatorsStartPayload
>(asyncActions.bulkAddParticipators, (payload, _, getState): Promise<void> => {
    const { id } = getTaskInfo(getState());
    const participantsIds = payload.participators.map((participant) => participant.userId);

    return TaskApi.updateTaskParticipants(id, participantsIds).then(
        () => void 0,
        () => participantsIds,
    );
});
