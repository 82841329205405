import * as React from 'react';

import { Icon, IconType } from 'sber-marketing-ui';

import { Tag, TagProps } from '@common/components';

export type IconTagProps<E extends keyof JSX.IntrinsicElements = 'div'> = TagProps<E> & {
    icon: string | IconType;
    iconSize?: number;
};

export function IconTag<E extends keyof JSX.IntrinsicElements = 'div'>({
    icon,
    iconSize,
    style,
    children,
    ...props
}: IconTagProps<E>) {
    const content = Object.values(IconType).includes(icon as IconType) ? (
        <Icon svgSize={iconSize} type={icon as IconType} />
    ) : (
        <span>{icon}</span>
    );

    return (
        <Tag gap={6} {...(props as any)} style={{ fontSize: iconSize, ...style }}>
            {content}
            {children}
        </Tag>
    );
}
