import * as React from 'react';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ActivityFileGroup } from 'sber-marketing-types/frontend';
import autobind from 'autobind-decorator';

import { FileApi } from '@api';

import { StoreState } from '@store';
import { FileAsset } from '@store/commonTypes';
import { getLoginUser } from '@store/user';
import { getSelectedCard } from '@store/dashboardPage';
import {
    resetFilesUploadStatus,
    setFileUploadStatus,
    FileUploadStatus,
    SetFileUploadStatusPayload,
    errorIsFromAntivirus,
} from '@store/common';

import { FileInput } from '@common/FileInput';

import { FileGroupTemplate } from './FileGroupTemplate';
import { FileProps } from './FileCard';

interface Props extends Partial<MapProps & DispatchProps> {
    loading: boolean;
    title: string;
    fileGroup: ActivityFileGroup;
    files: FileProps[];
    enableControls: boolean;
    reloadContent: () => Promise<void>;
    attachFile: (activityId: number, fileId: string, fileCategory: ActivityFileGroup) => Promise<void>;
}

interface MapProps {
    userId: string;
    activityId: number;
}

interface DispatchProps {
    resetFilesUploadStatus: () => void;
    setFileUploadStatus: (payload: SetFileUploadStatusPayload) => void;
}

interface State {
    pendingFiles: FileAsset[];
    deleteConfirmationFileId: string;
    showDownloadingPreloader: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class FileGroupContainer extends React.PureComponent<Props, State> {
    private fileInputRef: React.RefObject<FileInput>;

    public constructor(props: Props) {
        super(props);

        this.state = {
            pendingFiles: [],
            deleteConfirmationFileId: null,
            showDownloadingPreloader: false,
        };

        this.fileInputRef = React.createRef<FileInput>();
    }

    public render(): JSX.Element {
        const { pendingFiles, deleteConfirmationFileId, showDownloadingPreloader } = this.state;

        return (
            <FileGroupTemplate
                {...this.props}
                pendingFiles={pendingFiles}
                showDownloadingPreloader={showDownloadingPreloader}
                deletionFileName={this.getFileNameById(deleteConfirmationFileId)}
                fileInputRef={this.fileInputRef}
                onFileInput={this.onFileInput}
                onAttachButtonClick={this.onAttachButtonClick}
                onDownloadAllButtonClick={this.onDownloadAllButtonClick}
                onDeleteButtonClick={this.onDeleteButtonClick}
                onDeleteConfirm={this.onDeleteConfirm}
                onDeleteCancel={this.onDeleteCancel}
            />
        );
    }

    @autobind
    private async onFileInput(files: File[]): Promise<void> {
        if (files.length) {
            this.props.resetFilesUploadStatus();

            const { activityId, userId, fileGroup } = this.props;

            let error: Error;

            await Promise.all(
                files.map(
                    (file) =>
                        new Promise<void>((resolve) => {
                            const fileAsset = FileApi.makeFileAsset({}, file, userId);

                            this.setState(
                                (state) => ({
                                    pendingFiles: [...state.pendingFiles, fileAsset],
                                }),
                                async () => {
                                    try {
                                        await FileApi.uploadFile({ fileId: fileAsset.id }, fileAsset, userId);

                                        await this.props.attachFile(activityId, fileAsset.id, fileGroup);
                                        await this.props.reloadContent();
                                    } catch (e) {
                                        if (errorIsFromAntivirus(e)) {
                                            this.props.setFileUploadStatus({
                                                fileName: file.name,
                                                status: FileUploadStatus.VirusFound,
                                            });
                                        }

                                        error = e;
                                    }

                                    this.setState(
                                        (state) => ({
                                            pendingFiles: state.pendingFiles.filter(
                                                (pendingFile) => pendingFile.id !== fileAsset.id,
                                            ),
                                        }),
                                        resolve,
                                    );
                                },
                            );
                        }),
                ),
            );

            if (error) {
                throw error;
            }
        }
    }

    @autobind
    private onAttachButtonClick(): void {
        this.fileInputRef.current.triggerOpen();
    }

    @autobind
    private async onDownloadAllButtonClick(): Promise<void> {
        this.setState(
            {
                showDownloadingPreloader: true,
            },
            async () => {
                await FileApi.downloadMultipleFilesAsZip(
                    'Архив',
                    this.props.files.map((file) => ({
                        id: file.id,
                        name: file.originName,
                        type: file.type,
                        params: {
                            fileId: file.id,
                        },
                    })),
                );

                this.setState({
                    showDownloadingPreloader: false,
                });
            },
        );
    }

    @autobind
    private onDeleteButtonClick(fileId: string): void {
        this.setState({
            deleteConfirmationFileId: fileId,
        });
    }

    @autobind
    private onDeleteConfirm(): void {
        this.deleteFile(this.state.deleteConfirmationFileId);
    }

    @autobind
    private onDeleteCancel(): void {
        this.setState({
            deleteConfirmationFileId: null,
        });
    }

    private async deleteFile(fileId: string): Promise<void> {
        await FileApi.deleteFile(fileId);
        this.props.reloadContent();
    }

    private getFileNameById(fileId: string): string {
        if (fileId === null) {
            return null;
        }

        const foundFile = this.props.files.find((item) => item.id == fileId);

        return foundFile ? `${foundFile.originName}.${foundFile.type}` : null;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const selectedCard = getSelectedCard(state);
    const loginUser = getLoginUser(state);

    return {
        activityId: selectedCard ? Number(selectedCard.activityId) : null,
        userId: String(loginUser.attributes.id),
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        resetFilesUploadStatus: () => dispatch(resetFilesUploadStatus()),
        setFileUploadStatus: (payload: SetFileUploadStatusPayload) => dispatch(setFileUploadStatus(payload)),
    };
}
