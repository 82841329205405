import * as React from 'react';
import classNames from 'classnames';

import * as style from './CorrectionMenu.scss';

import { CorrectionStatus } from '@mrm/budget';

import { Button_redesign, ButtonTheme_redesign, Icon, IconType } from 'sber-marketing-ui';

const BUTTON_LABEL = 'Посмотреть в таблице';

interface Props {
    status: CorrectionStatus;
    userIsBudgetExpert: boolean;
    userIsAuthorCorrection: boolean;
    acceptButtonIsLocked: boolean;
    userCanEditOrCreateBudgetData: boolean;
    onEditButtonClick: () => void;
    onAcceptButtonClick: () => void;
    onRejectButtonClick: () => void;
}

export const CorrectionMenu = ({
    status,
    userIsBudgetExpert,
    userIsAuthorCorrection,
    acceptButtonIsLocked,
    userCanEditOrCreateBudgetData,
    onEditButtonClick,
    onAcceptButtonClick,
    onRejectButtonClick,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            {status == CorrectionStatus.NeedApproving && userIsBudgetExpert && renderNeedApprovingStatus()}

            {status == CorrectionStatus.NeedApproving &&
                !userIsBudgetExpert &&
                !userIsAuthorCorrection &&
                renderOnApprovingStatus()}

            {status === CorrectionStatus.NeedApproving &&
                !userIsBudgetExpert &&
                userIsAuthorCorrection &&
                renderNeedRejectedStatus()}

            {status == CorrectionStatus.Approved && renderAcceptedStatus()}

            {status == CorrectionStatus.Rejected && renderRejectedStatus()}
        </div>
    );

    function renderNeedApprovingStatus(): JSX.Element {
        return (
            <div className={style.needApprovingStatus}>
                <div className={style.editButton}>
                    <Button_redesign
                        theme={ButtonTheme_redesign.GhostRoundedUpdated}
                        onClick={onEditButtonClick}
                        qaId="correctionCardGotoTableButton"
                    >
                        {BUTTON_LABEL}
                    </Button_redesign>
                </div>

                {userCanEditOrCreateBudgetData && (
                    <React.Fragment>
                        <div
                            className={classNames(style.statusButton, style.approveButton)}
                            onClick={!acceptButtonIsLocked ? onAcceptButtonClick : null}
                            {...{
                                'qa-id': 'correctionCardAcceptButton',
                            }}
                        >
                            <Icon type={IconType.CIRCLE_CHECK} />
                        </div>

                        <div
                            className={classNames(style.statusButton, style.rejectButton)}
                            {...{
                                'qa-id': 'correctionCardRejectButton',
                            }}
                            onClick={onRejectButtonClick}
                        >
                            <Icon type={IconType.CIRCLE_CROSS} />
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }

    function renderOnApprovingStatus(): JSX.Element {
        return (
            <div className={style.onApprovingStatus}>
                <div
                    className={style.statusName}
                    {...{
                        'qa-id': 'correctionCardStatus',
                    }}
                >
                    На согласовании
                </div>
            </div>
        );
    }

    function renderNeedRejectedStatus(): JSX.Element {
        return (
            <div className={style.needRejectedStatus}>
                <div className={style.editButton}>
                    <Button_redesign
                        theme={ButtonTheme_redesign.GhostRoundedUpdated}
                        onClick={onEditButtonClick}
                        qaId="correctionCardGotoTableButton"
                    >
                        {BUTTON_LABEL}
                    </Button_redesign>
                </div>

                {userCanEditOrCreateBudgetData && (
                    <div
                        className={classNames(style.statusButton, style.rejectButton)}
                        onClick={onRejectButtonClick}
                        {...{
                            'qa-id': 'correctionCardRejectButton',
                        }}
                    >
                        <Icon type={IconType.CIRCLE_CROSS} />
                    </div>
                )}
            </div>
        );
    }

    function renderAcceptedStatus(): JSX.Element {
        return (
            <div className={style.acceptedStatus}>
                <div className={style.editButton}>
                    <Button_redesign
                        theme={ButtonTheme_redesign.GhostRoundedUpdated}
                        onClick={onEditButtonClick}
                        qaId="correctionCardGotoTableButton"
                    >
                        {BUTTON_LABEL}
                    </Button_redesign>
                </div>

                <div
                    className={style.statusName}
                    {...{
                        'qa-id': 'correctionCardStatus',
                    }}
                >
                    Принята
                </div>
            </div>
        );
    }

    function renderRejectedStatus(): JSX.Element {
        return (
            <div className={style.rejectedStatus}>
                <div className={style.editButton}>
                    <Button_redesign
                        theme={ButtonTheme_redesign.GhostRoundedUpdated}
                        onClick={onEditButtonClick}
                        qaId="correctionCardGotoTableButton"
                    >
                        {BUTTON_LABEL}
                    </Button_redesign>
                </div>

                <div
                    className={style.statusName}
                    {...{
                        'qa-id': 'correctionCardStatus',
                    }}
                >
                    Отклонена
                </div>
            </div>
        );
    }
};
