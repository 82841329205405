import * as React from 'react';
import autobind from 'autobind-decorator';
import * as moment from 'moment';
import * as lodash from 'lodash';
import { CorrectionStatus } from '@mrm/budget';

import type { UnsavedChange } from '@store/budgetCorrections/types';
import { CorrectionSapExportMode } from '@store/budgetCorrections/types';
import type { IncomeExternalPlanFundsTransferCorrection, BudgetItem, Persone, Dictionary } from '../../../types';
import { Month, PLANNED_COLUMN_NAMES } from '../../../types';

import {
    IncomeExternalCorrectionCard,
    ParticipatorData,
    CorrectionData,
    ParticipatorField,
} from './IncomeExternalCorrectionCard';

import { Utils } from '@common/Utils';

import { monthIndexMap } from '../utils';

interface Props extends IncomeExternalPlanFundsTransferCorrection {
    budgetId: string;
    dictionaries: Dictionary[];
    userIsBudgetExpert: boolean;
    userIsAuthorCorrection: boolean;
    setUnsavedChange: (unsavedChange: UnsavedChange) => void;
}

interface State {
    sapExportMode: CorrectionSapExportMode;
}

export class IncomeExternalCorrectionCardContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            sapExportMode: CorrectionSapExportMode.Upload,
        };
    }

    public render(): JSX.Element {
        const { serialNumber, creationTime, author, status, comment } = this.props;

        const authorName = this.formatUserName(author);
        const approverName = this.getApproverName();

        return React.createElement(IncomeExternalCorrectionCard, {
            serialNumber,
            authorName,
            approverName,
            userIsBudgetExpert: this.props.userIsBudgetExpert,
            userIsAuthorCorrection: this.props.userIsAuthorCorrection,
            creationDate: this.formatDate(creationTime),
            correctionData: this.makeCorrectionData(),
            acceptorData: this.makeParticipatorData(),
            status,
            url: this.makeUrl(),
            rejectComment: comment,
            onStatusChange: this.onStatusChange,
        });
    }

    @autobind
    protected onSapExportModeSelection(value: CorrectionSapExportMode) {
        this.setState({
            sapExportMode: value,
        });
    }

    @autobind
    protected onStatusChange(status: CorrectionStatus, comment?: string) {
        const { id } = this.props;

        this.props.setUnsavedChange({
            correctionId: id,
            newStatus: status,
            rejectComment: comment,
        });
    }

    private getApproverName(): string {
        const { status, expert, approver } = this.props;

        if (status === CorrectionStatus.NeedApproving) {
            return expert ? this.formatUserName(expert) : 'не задан';
        }

        return approver ? this.formatUserName(approver) : 'не задан';
    }

    private makeCorrectionData(): CorrectionData {
        const { params, dictionaries } = this.props;

        const dictionaryItem =
            params.dictionary && params.dictionary.item && params.dictionary.item.id
                ? dictionaries.find(({ id }) => id === params.dictionary.item.id)
                : null;

        return {
            dictionaryItem: Utils.getDictionaryValue(dictionaryItem),
            comment: params.comment,
        };
    }

    private makeParticipatorData(): ParticipatorData {
        const {
            budgetItemCurrent: budgetItemsCurrent,
            budgetItemBefore: budgetItemsBefore,
            status,
            params,
        } = this.props;

        const currentBudgetItem = budgetItemsCurrent.find(({ id }) => id === params.acceptorId);
        const budgetItemBefore = lodash.isEmpty(budgetItemsBefore)
            ? null
            : budgetItemsBefore.find(({ id }) => id === params.acceptorId);

        const definedBudgetItem =
            status === CorrectionStatus.NeedApproving
                ? currentBudgetItem
                : lodash.isEmpty(budgetItemBefore)
                ? currentBudgetItem
                : budgetItemBefore;

        const delta = params.value;

        const month = params.acceptorMonth;
        const beforeFond = definedBudgetItem.plannedFunds[month];

        // TODO В ответе graphQL висит поле __typename и для перебора свойст обхекта оно мешает
        delete definedBudgetItem.plannedFunds['__typename'];

        const allBeforeFond = lodash
            .values(definedBudgetItem.plannedFunds)
            .reduce<number>((acc, monthFond) => acc + monthFond, 0);

        return {
            monthFond: {
                month: this.getMonthName(month),
                delta,
                beforeFond,
                afterFond: beforeFond + delta,
            },
            allFond: {
                delta,
                beforeFond: allBeforeFond,
            },
            name: definedBudgetItem.sapComment,
            fields: this.buildParticipatorFields(definedBudgetItem),
            comment: definedBudgetItem.comment,
        };
    }

    private buildParticipatorFields(budgetItem: BudgetItem): ParticipatorField[] {
        const donors = this.getBudgetItemDonors();

        return [
            {
                name: 'ID план',
                value: donors.donorsShorted,
                title: donors.donors,
            },
            {
                name: 'ID исполнение',
                value: budgetItem.serialNumber,
            },
            {
                name: 'Тип проекта',
                value:
                    budgetItem.dictionary &&
                    budgetItem.dictionary.activity_type &&
                    budgetItem.dictionary.activity_type.value,
            },
            {
                name: 'Направление',
                value:
                    budgetItem.dictionary && budgetItem.dictionary.direction && budgetItem.dictionary.direction.value,
            },
            {
                name: 'ЦА/ТБ (Территория)',
                value: budgetItem.dictionary?.regionality
                    ? Utils.getDictionaryValue(budgetItem.dictionary.regionality)
                    : '',
            },
            {
                name: 'Территория',
                value: budgetItem.dictionary?.territory
                    ? Utils.getDictionaryValue(budgetItem.dictionary.territory)
                    : '',
            },
            {
                name: 'Дата запуска',
                value: budgetItem.realizationStart && this.formatDate(budgetItem.realizationStart),
            },
            {
                name: 'Дата окончания',
                value: budgetItem.realizationEnd && this.formatDate(budgetItem.realizationEnd),
            },
            {
                name: 'Статья',
                value: budgetItem.dictionary && budgetItem.dictionary.item && budgetItem.dictionary.item.value,
            },
            {
                name: 'Блок',
                value: budgetItem.dictionary && budgetItem.dictionary.block && budgetItem.dictionary.block.value,
                isWide: true,
            },
            {
                name: 'Инструмент',
                value: budgetItem.dictionary && budgetItem.dictionary.tool && budgetItem.dictionary.tool.value,
                isWide: true,
            },
            {
                name: 'Центр затрат',
                value: budgetItem.dictionary?.cost_center?.value,
                isWide: true,
            },
        ];
    }

    private formatUserName(user: Persone): string {
        return `${user.firstName} ${user.secondName}`;
    }

    private formatDate(date: string | Date): string {
        return moment(date).format('DD.MM.YY');
    }

    private getMonthName(month: Month): string {
        moment.locale('ru');

        const monthName = moment.months()[monthIndexMap[month]];

        return lodash.capitalize(monthName);
    }

    private makeUrl() {
        const {
            params: { acceptorMonth, acceptorId },
            budgetId,
        } = this.props;
        const monthIndex = monthIndexMap[acceptorMonth];

        const column = PLANNED_COLUMN_NAMES[monthIndex];

        return `/budget/execution?filters=id:${acceptorId}&column=${column}&budgetId=${budgetId}`;
    }

    // this.props.budgetItemBefore doesn't have donors, so wee nedd to calculate it based on a this.props.budgetItemCurrent;
    private getBudgetItemDonors() {
        const budgetItem = lodash.first(this.props.budgetItemCurrent);

        let budgetItemDonors = '';
        let budgetItemDonorsShortened = '';
        if (budgetItem.donors) {
            const budgetItemDonorsParts: number[][] = [];
            for (let i = 0; i < budgetItem.donors.length; i += 20) {
                budgetItemDonorsParts.push(budgetItem.donors.slice(i, i + 20));
            }

            budgetItemDonors = budgetItemDonorsParts.map((part) => part.join(', ')).join('\n');
            budgetItemDonorsShortened = `${budgetItem.donors.slice(0, 4).join(', ')}${
                budgetItem.donors.length > 4 ? '...' : ''
            }`;
        }

        return {
            donors: budgetItemDonors,
            donorsShorted: budgetItemDonorsShortened,
        };
    }
}
