import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import { getSelectedStatisticsItem, getStatisticsItems, StatisticsItem } from '@store/leaderDashboardPage';
import * as action from '@store/leaderDashboardPage/actions';

interface UseState {
    (): UseStateReturn;
}

interface UseStateReturn extends Selected {
    setSelectedStatisticsItemId: (id: string) => void;
}

interface Selected {
    selectedStatisticsItem: StatisticsItem;
    statisticsItems: StatisticsItem[];
}

export const useState: UseState = () => {
    const dispatch = useDispatch();

    const { selectedStatisticsItem, statisticsItems } = useSelector<StoreState, Selected>((state) => {
        return {
            selectedStatisticsItem: getSelectedStatisticsItem(state),
            statisticsItems: getStatisticsItems(state),
        };
    });

    const setSelectedStatisticsItemId = React.useCallback((id) => {
        dispatch(action.setSelectedStatisticsItemId(id));
    }, []);

    return {
        statisticsItems,
        selectedStatisticsItem,
        setSelectedStatisticsItemId,
    };
};

export type { StatisticsItem };
