import * as React from 'react';
import * as lodash from 'lodash';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import { UserConfigType } from 'sber-marketing-types/openid';
import { Budget, BudgetStatus, BudgetItem } from '@mrm/budget';

import { UserConfigApi } from '@api';

import { StoreState } from '@store';
import { isRequestInProgress } from '@store/common';
import { getBudgetByStatusUserConfig } from '@store/userConfig/budget';
import { getBudgetState } from '@store/budgetPage';
import {
    ChangeList,
    ColumnName,
    ColumnsWidth,
    ExecutionTableUserConfig,
    PageFilters,
    Filters,
    getBudgetExecutionPageState,
    getTableFilters,
    getUnsavedChanges,
} from '@store/budgetExecution';

import { Loader } from '../modules';

const USER_CONFIG_UPDATE_TIMEOUT = 1000;

interface Props extends Partial<MapProps> {
    pageDataRefetchInProgress: boolean;
}

interface MapProps {
    budgetId: string;
    budgets: Budget[];
    columnFilters: Filters;
    isRequestInProgress: boolean;
    columnsWidth: ColumnsWidth;
    fixedColumnsNames: ColumnName[];
    unsavedChanges: ChangeList;
    pageFilters: PageFilters;
    budgetItemsToIgnoreFilters: BudgetItem[];
    preloader: boolean;
}

@(connect(mapStateToProps, null, null, { forwardRef: true }) as any)
export class UserConfigEffect extends React.PureComponent<Props> {
    private tableLoader: Loader;
    private updateUserConfigDebounced = lodash.debounce(this.updateUserConfig, USER_CONFIG_UPDATE_TIMEOUT);

    public constructor(props: Props) {
        super(props);

        this.tableLoader = Loader.getInstance();
    }

    @autobind
    public async updateUserConfig() {
        const { isRequestInProgress, preloader } = this.props;
        const isLoading = isRequestInProgress || preloader;

        if (!isLoading) {
            const userConfig = await this.makeUserConfig();
            await UserConfigApi.savePageConfig(UserConfigType.BudgetExecution, userConfig);
        }
    }

    public componentDidUpdate(prevProps: Props): void {
        const columnsWidthChanged = !lodash.isEqual(prevProps.columnsWidth, this.props.columnsWidth);
        const fixedColumnsNamesChanged = !lodash.isEqual(prevProps.fixedColumnsNames, this.props.fixedColumnsNames);
        const changeListChanged = !lodash.isEqual(prevProps.unsavedChanges, this.props.unsavedChanges);
        const pageFiltersHaveChanged = !lodash.isEqual(prevProps.pageFilters, this.props.pageFilters);
        const updatedFiltersHaveChanged = !lodash.isEqual(prevProps.columnFilters, this.props.columnFilters);
        const isChangedBudgetId = prevProps.budgetId !== this.props.budgetId;
        const budgetItemsToIgnoreFiltersChanged = !lodash.isEqual(
            prevProps.budgetItemsToIgnoreFilters,
            this.props.budgetItemsToIgnoreFilters,
        );

        if (
            !this.props.pageDataRefetchInProgress &&
            (isChangedBudgetId ||
                pageFiltersHaveChanged ||
                columnsWidthChanged ||
                fixedColumnsNamesChanged ||
                changeListChanged ||
                updatedFiltersHaveChanged ||
                budgetItemsToIgnoreFiltersChanged)
        ) {
            this.updateUserConfigDebounced();
        }
    }

    public render(): JSX.Element {
        // component used only for side-effect
        return null;
    }

    private async makeUserConfig(): Promise<ExecutionTableUserConfig> {
        const userConfig = await this.tableLoader.getUserConfig();

        const {
            columnsWidth,
            fixedColumnsNames,
            unsavedChanges,
            pageFilters: {
                columnsVisiblityFilter,
                sortingMode,
                showOnlyLinesWithoutPlan,
                correctionsToDisplay,
                showOnlyLinesWithNegativeBalance,
                appliedFiltersNames,
                useLinesWithoutPlanInSorting,
                showOnlyLinesWithPlanBudget,
                showOnlyLinesWithNegativePlanFactDiff,
            },
            columnFilters,
            budgetId,
            budgetItemsToIgnoreFilters,
        } = this.props;

        const filters = Object.keys(columnFilters).reduce(
            (filtersAcc, column) => ({
                ...filtersAcc,
                [column]: Object.keys(columnFilters[column]).reduce((columnAcc, filterKey) => {
                    const res = { ...columnAcc };

                    if (columnFilters[column][filterKey]) {
                        res[filterKey] = true;
                    }

                    return res;
                }, {}),
            }),
            {},
        );

        return this.updateBudgetsKeysInUserConfig({
            ...userConfig,
            [budgetId]: {
                columnsWidth,
                fixedColumnsNames,
                columnsVisiblityFilter,
                sortingMode,
                filters,
                unsavedChanges: lodash.pickBy(unsavedChanges, (item) => !lodash.isEmpty(item)),
                showOnlyLinesWithoutPlan,
                corrections: correctionsToDisplay,
                showOnlyLinesWithNegativeBalance,
                appliedFiltersNames,
                budgetItemsToIgnoreFilters: budgetItemsToIgnoreFilters.map((budgetItem) => budgetItem.id),
                useLinesWithoutPlanInSorting,
                showOnlyLinesWithPlanBudget,
                showOnlyLinesWithNegativePlanFactDiff,
            },
        });
    }

    private updateBudgetsKeysInUserConfig(userConfig: ExecutionTableUserConfig): ExecutionTableUserConfig {
        const { budgets } = this.props;
        const updatedUserConfig = {};

        const availableBudgetsIdsForCurrentUser = budgets.map(({ id }) => id);

        availableBudgetsIdsForCurrentUser.forEach((budgetId) => {
            if (userConfig[budgetId]) {
                updatedUserConfig[budgetId] = lodash.cloneDeep(userConfig[budgetId]);
            }
        });

        return updatedUserConfig;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const {
        columnsWidth,
        fixedColumnsNames,
        preloader,
        columnFilters: { filters },
        pageData: { budgetItemsToIgnoreFilters },
    } = getBudgetExecutionPageState(state);
    const pageFilters = getTableFilters(state);

    const { budgetId } = getBudgetByStatusUserConfig(state, BudgetStatus.Execution);
    const { budgets } = getBudgetState(state, BudgetStatus.Execution);

    return {
        budgetId,
        budgets,
        columnFilters: filters,
        isRequestInProgress: isRequestInProgress(state),
        columnsWidth,
        fixedColumnsNames,
        unsavedChanges: getUnsavedChanges(state),
        pageFilters,
        preloader,
        budgetItemsToIgnoreFilters,
    };
}
