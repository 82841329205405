import createCashedSelector from 're-reselect';
import { BudgetStatus } from '@mrm/budget';

import { StoreState } from '@store';

import { BudgetState } from './types';

const getBudgetPageState = (state: StoreState) => state.budgetPage;

export const getBudgetState = createCashedSelector(
    getBudgetPageState,
    (state: StoreState, budget: BudgetStatus) => budget,
    (state, budget): BudgetState => state.budgetStatus[budget],
)((state: StoreState, budget: BudgetStatus) => budget);
