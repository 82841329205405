import * as React from 'react';
import { useParams } from 'react-router-dom';

import { CreativeRequestDomain } from '@store/creative/types';
import { MrmClient } from '@api';

import { SECOND_LOT_ID } from '../const';

interface UseVisibilityTenderDocumentation {
    (): Return;
}

interface Return {
    visibility: boolean;
}

interface OnReloaded {
    (): void;
}

export const useVisibilityTenderDocumentation: UseVisibilityTenderDocumentation = () => {
    const { creativeRequestId } = useParams();
    const [visibility, setVisibility] = React.useState<boolean>(false);
    const [creativeRequestDomain, setCreativeRequestDomain] = React.useState<CreativeRequestDomain>(null);

    const loadCreativeRequestDomain = React.useCallback(() => {
        MrmClient.getInstance()
            .then((client) => {
                return client.domain.creativeRequests.getCreativeRequest({ id: creativeRequestId });
            })
            .then((creativeRequestDomain) => {
                setCreativeRequestDomain(creativeRequestDomain);
            })
            .catch((error) => {
                console.error(error);
                setCreativeRequestDomain(null);
                setVisibility(false);
            });
    }, [creativeRequestId, setCreativeRequestDomain, setVisibility]);

    React.useEffect(() => loadCreativeRequestDomain(), []);

    React.useEffect(() => {
        creativeRequestDomain?.model?.lot
            ?.then((lot) => {
                if (lot.id === SECOND_LOT_ID) {
                    setVisibility(true);
                } else {
                    setVisibility(false);
                }
            })
            .catch((error) => {
                console.error(error);
                setVisibility(false);
            });

        creativeRequestDomain?.events?.onReloaded(onReloaded);

        return () => {
            creativeRequestDomain?.events?.offReloaded(onReloaded);
        };
    }, [creativeRequestDomain]);

    const onReloaded: OnReloaded = React.useCallback(() => loadCreativeRequestDomain(), []);

    return { visibility };
};
