// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Content-List-Content-Item-_styles__root{display:grid;grid-template-columns:max-content 1fr max-content;align-items:center}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Content-List-Content-Item-_styles__root:not(:last-child){border-bottom:1px solid #E3E9E5}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Content-List-Content-Item-_styles__selected{background-color:#F8F8F8}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/CreationForm/GeneratedTextEditor/Editor/Content/List/Content/Item/styles.scss"],"names":[],"mappings":"AAAA,6JACI,YAAa,CACb,iDAAkD,CAClD,kBAAmB,CAHvB,8KAMQ,+BAAgC,CACnC,iKAID,wBAAyB","sourcesContent":[".root {\n    display: grid;\n    grid-template-columns: max-content 1fr max-content;\n    align-items: center;\n\n    &:not(:last-child) {\n        border-bottom: 1px solid #E3E9E5;\n    }\n}\n\n.selected {\n    background-color: #F8F8F8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Content-List-Content-Item-_styles__root",
	"selected": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Content-List-Content-Item-_styles__selected"
};
export default ___CSS_LOADER_EXPORT___;
