import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { DictionaryType, PlainDictionary } from '@mrm/dictionary';
import { v4 } from 'uuid';
import { get } from 'lodash';

import { StageApi, DictionaryApi } from '@api';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';
import { LoadingStatus } from '@store/commonTypes';
import { getActivity, fillActivityTasks } from '@store/activityTasksPage';

import * as actions from './actions/sync';
import * as asyncActions from './actions/async';
import { getActivityStageState } from './selectors';
import { EditStageParams, Stage } from './types';

function stringToDate(date: string | Date): Date {
    return date ? new Date(date) : null;
}

export const loadStagesData = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.loadStagesData,
    async (_, dispatch, getState) => {
        const state = getState();
        const { loadingStatus } = getActivityStageState(state);

        if (loadingStatus !== LoadingStatus.LOADED) {
            dispatch(actions.setLoadingStatus(LoadingStatus.LOADING));

            const { organizationId } = getLoginUser(state).attributes;
            const activityId = getActivity(state).id;
            const stageTemplates = await DictionaryApi.getDictionaryList({
                types: [DictionaryType.StageTemplate],
                organizationId,
                treeview: true,
            });

            const activityStages = (await StageApi.graphqlGetActivityStages([activityId]))[0]?.stages || [];
            const selectedTemplateId: string = get(activityStages, '[0].dictionary.templateId') || null;
            const stages: Stage[] = await Promise.all(
                activityStages.map(async (stage) => {
                    const dictionaryData = await DictionaryApi.getDictionaryData(stage.dictionary.id);
                    const duration = get(dictionaryData, 'data.duration') || 0;

                    return {
                        id: stage.id,
                        dictionaryId: stage.dictionary.id,
                        name: stage.dictionary.name,
                        start: stringToDate(stage.start),
                        end: stringToDate(stage.end),
                        done: stage.isDone,
                        active: stage.isActive,
                        weight: stage.dictionary.weight,
                        duration,
                    };
                }),
            );

            dispatch(
                actions.loadStagesContent({
                    stageTemplates,
                    stages,
                    selectedTemplateId,
                }),
            );

            dispatch(actions.setLoadingStatus(LoadingStatus.LOADED));
            dispatch(loadTaskTemaplates(null));
        }
    },
);

export const selectStageTemplate = bindThunkAction<StoreState, string, void, Error>(
    asyncActions.selectStageTemplate,
    async (templateId, dispatch, getState) => {
        const state = getState();
        const { entities: existingStages, selectedTemplateId: existingStagesTemplateId } =
            getActivityStageState(state).existingStages;

        dispatch(actions.setStateRequestInProgress(true));

        let stages: Stage[];
        if (templateId === existingStagesTemplateId) {
            stages = existingStages;
        } else {
            stages = await Promise.all(
                (templateId
                    ? await DictionaryApi.getDictionaryChildren({
                          id: templateId,
                          type: DictionaryType.Stage,
                          rootPath: [templateId],
                          treeview: true,
                      })
                    : []
                ).map(async (dictionary) => {
                    const dictionaryData = await DictionaryApi.getDictionaryData(dictionary.id);
                    const duration = get(dictionaryData, 'data.duration') || 0;

                    return {
                        id: v4(),
                        dictionaryId: dictionary.id,
                        name: dictionary.value,
                        start: null,
                        end: null,
                        done: false,
                        active: true,
                        weight: dictionary.weight,
                        duration,
                    };
                }),
            );
        }

        dispatch(
            actions.fillPendingStages({
                selectedTemplateId: templateId,
                stages,
            }),
        );

        dispatch(actions.setStateRequestInProgress(false));
    },
);

export const saveActivityStages = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.saveActivityStages,
    async (_, dispatch, getState) => {
        const state = getState();
        const authorId = getLoginUser(state).attributes.id;
        const activityId = getActivity(state).id;
        const { existingStages, pendingStages } = getActivityStageState(state);

        dispatch(actions.setStateRequestInProgress(true));

        if (existingStages.selectedTemplateId !== pendingStages.selectedTemplateId) {
            // creating new stages
            await Promise.all(existingStages.entities.map((stage) => StageApi.deleteStage(stage.id)));

            await Promise.all(
                pendingStages.entities.map((stage) =>
                    StageApi.createStage({
                        id: stage.id,
                        activityId,
                        authorId,
                        dictionaryId: stage.dictionaryId,
                        templateDictionaryId: pendingStages.selectedTemplateId,
                        start: stage.start,
                        end: stage.end,
                        done: false,
                    }),
                ),
            );

            const nonActivePendingStages = pendingStages.entities.filter((stage) => !stage.active);

            if (nonActivePendingStages.length) {
                await Promise.all(
                    nonActivePendingStages.map((stage) =>
                        StageApi.editStage(stage.id, {
                            start: stage.start,
                            end: stage.end,
                            active: false,
                        }),
                    ),
                );
            }
        } else {
            // editing existing stages
            for (let i = 0; i !== pendingStages.entities.length; i++) {
                const stage = pendingStages.entities[i];

                await StageApi.editStage(stage.id, {
                    start: stage.start,
                    end: stage.end,
                    done: false,
                });
            }
        }

        dispatch(fillActivityTasks(activityId));

        dispatch(
            actions.fillExistingAndPendingStages({
                stages: pendingStages.entities,
                selectedTemplateId: pendingStages.selectedTemplateId,
            }),
        );

        dispatch(actions.setStateRequestInProgress(false));
    },
);

export const editStage = bindThunkAction<StoreState, EditStageParams, void, Error>(
    asyncActions.editStage,
    async (params, dispatch, getState) => {
        const { id: stageId, ...stage } = params;
        const { entities, byId, selectedTemplateId } = getActivityStageState(getState()).existingStages;
        const updatedStage: Stage = {
            ...byId[stageId],
            ...stage,
        };

        await StageApi.editStage(stageId, updatedStage);

        const updatedStages = entities.map((stage) => (stage.id === stageId ? updatedStage : stage));

        dispatch(
            actions.fillExistingAndPendingStages({
                selectedTemplateId,
                stages: updatedStages,
            }),
        );
    },
);

export const loadTaskTemaplates = bindThunkAction<StoreState, null, PlainDictionary[], Error>(
    asyncActions.loadTaskTemaplates,
    async (_, dispatch, getState) => {
        const organizationId = getLoginUser(getState()).attributes.organizationId;
        return await DictionaryApi.getDictionaryList({
            organizationId,
            types: [DictionaryType.Task],
        });
    },
);
