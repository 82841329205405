import * as React from 'react';
import * as Dotdotdot from 'react-dotdotdot';

import { ToggleButton } from '../ToggleButton';

export enum DisplayText {
    CLAMPED,
    NON_CLAMPED,
    BOTH,
}

interface Props {
    children: React.ReactChild | React.ReactChild[];
    lines: number;
    displayText: DisplayText;
    displayToggleButton: boolean;
    isExpanded: boolean;
    displayRef: (element: HTMLDivElement) => void;
    onToggleButtonClick: () => void;
}

export function TextClamper({
    children,
    lines,
    displayRef,
    displayText,
    displayToggleButton,
    isExpanded,
    onToggleButtonClick,
}: Props): JSX.Element {
    const displayClampedText = displayText === DisplayText.BOTH || displayText === DisplayText.CLAMPED;
    const displayNonClampedText = displayText === DisplayText.BOTH || displayText === DisplayText.NON_CLAMPED;

    return (
        <React.Fragment>
            <div ref={displayRef}>
                {displayClampedText && <Dotdotdot clamp={lines}>{children}</Dotdotdot>}

                {displayNonClampedText && <div>{children}</div>}
            </div>

            {displayToggleButton && <ToggleButton isOpened={isExpanded} onClick={onToggleButtonClick} />}
        </React.Fragment>
    );
}
