// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-tags-TagsPage-Filters-_Filters__root{width:100%;padding:9px 16px 8px 16px;background:#E6EDF1;border-radius:6px}.src-client-modules-tags-TagsPage-Filters-_Filters__filter{margin-bottom:8px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/tags/TagsPage/Filters/Filters.scss"],"names":[],"mappings":"AAAA,yDACI,UAAW,CAEX,yBAA0B,CAE1B,kBAAmB,CACnB,iBAAkB,CACrB,2DAGG,iBAAkB","sourcesContent":[".root {\n    width: 100%;\n    \n    padding: 9px 16px 8px 16px;\n\n    background: #E6EDF1;\n    border-radius: 6px;\n}\n\n.filter {\n    margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-tags-TagsPage-Filters-_Filters__root",
	"filter": "src-client-modules-tags-TagsPage-Filters-_Filters__filter"
};
export default ___CSS_LOADER_EXPORT___;
