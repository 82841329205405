// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedActivities-ActivityCardList-_ActivityCardList__activityCardWrapper{margin-bottom:16px}.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedActivities-ActivityCardList-_ActivityCardList__activityCardWrapper:last-child{margin-bottom:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/LineModal/Content/RelatedActivities/ActivityCardList/ActivityCardList.scss"],"names":[],"mappings":"AAAA,kJACI,kBAAmB,CADvB,6JAIQ,eAAgB","sourcesContent":[".activityCardWrapper {\n    margin-bottom: 16px;\n\n    &:last-child {\n        margin-bottom: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activityCardWrapper": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedActivities-ActivityCardList-_ActivityCardList__activityCardWrapper"
};
export default ___CSS_LOADER_EXPORT___;
