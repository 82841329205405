import { v4 as uuidV4 } from 'uuid';
import { Success, Failure } from 'typescript-fsa';
import * as moment from 'moment';
import { keyBy } from 'lodash';

import { TaskPageState, FetchError, LoadEditorDataSuccess, TaskLoadingStatus } from '../types';

export const loadEditorDataStartedReducer = (state: TaskPageState): TaskPageState => ({
    ...state,
    id: uuidV4(),
    title: '',
    description: '',
    assetsIds: [],
    participants: [],
    deadlineAt: moment().add(3, 'days').valueOf(),
    workType: undefined,
    departmentExecutorId: undefined,
    executorId: undefined,
    loadingStatus: TaskLoadingStatus.LOADING,
});

export const loadEditorDataErrorReducer = (
    state: TaskPageState,
    { error: { code, message, type } }: Failure<null, FetchError>,
): TaskPageState => ({
    ...state,
    errorCode: code,
    errorType: type,
    errorMessage: message,
    loadingStatus: TaskLoadingStatus.ERROR,
});

export const loadEditorDataSuccessReducer = (
    state: TaskPageState,
    { result: { workTypes, departments } }: Success<null, LoadEditorDataSuccess>,
): TaskPageState => ({
    ...state,
    workTypes: keyBy(workTypes, 'id'),
    departments: keyBy(departments, 'id'),
    loadingStatus: TaskLoadingStatus.LOADED,
});
