import * as React from 'react';

import * as style from './styles.scss';

import type { BudgetProps } from './WithBudgets';
import * as moment from 'moment';

interface Props {
    activityId: number;
    creativeRequestId: string;
    budgets: BudgetProps[];
}

export const DonorBudgetTableButtonTemplate = ({ creativeRequestId, budgets }: Props): JSX.Element => {
    function onClick() {
        try {
            const url = makeBudgetTableUrl();
            openUrlInNewTab(url.href);
        } catch (error) {
            console.error(error);
        }
    }

    function makeBudgetTableUrl(): URL {
        const currentYear = moment().year();
        const currentBudget = budgets.find(({ year }) => year === currentYear);

        if (creativeRequestId && currentBudget?.id) {
            const url = new URL(`${window.location.origin}/budget/execution`);

            url.searchParams.append('creativeRequestId', creativeRequestId);
            url.searchParams.append('budgetId', currentBudget.id);

            return url;
        }

        throw new Error('creativeRequestId or currentBudgetId is empty');
    }

    function openUrlInNewTab(url: string): void {
        window.open(url, '_blank');
    }

    return (
        <div className={style.root} onClick={onClick}>
            Прикрепить ID бюджета
        </div>
    );
};
