import * as React from 'react';
import classNames from 'classnames';

import { Direction } from '../../enums';
import { Icon, IconType } from 'sber-marketing-ui';
import * as styles from './styles.scss';

export interface Props {
    direction: Direction;
}

const classNameByDirection: Partial<Record<Direction, string>> = {
    [Direction.UP]: styles.up,
    [Direction.DOWN]: styles.down,
};

export const Arrow: React.FC<Props> = ({ direction }) => {
    return (
        <div className={classNames(styles.root, classNameByDirection[direction])}>
            <Icon type={IconType.ARROW_ROUNDED} svgSize={9} />
        </div>
    );
};
