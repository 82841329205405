import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import autobind from 'autobind-decorator';

import { StoreState } from '@store';
import { isRequestInProgress } from '@store/common/selectors';
import { setRequestInProgress } from '@store/common/actions';
import { resetPageState } from '@store/budgetCorrections';
import {
    loadPageData,
    FilterValues,
    resetComponentState as resetFiltersComponentState,
    getBudgetCorrectionsFiltersAndExportState,
    saveUserConfig,
} from '@store/budgetCorrections/filtersAndExport';

import { WithCorrectionsQueryParams } from './WithCorrectionsQuery';
import { BudgetCorrectionsTemplate } from './BudgetCorrectionsTemplate';

interface MapProps {
    isRequestInProgress: boolean;
    filters: FilterValues;
}

interface DispatchProps {
    loadPageData: () => void;
    setRequestInProgress: (requestStatus: boolean) => void;
    resetPageState: () => void;
    resetFilters: () => void;
    saveUserConfig: () => void;
}

interface Props extends WithCorrectionsQueryParams, Partial<MapProps & DispatchProps> {
    selectedBudgetId: string;
}

interface State {
    // because filters ar stored in store
    // it's necessary to be sure
    // that filters have updated before calling props.applyFilters
    needToApplyFilters: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BudgetCorrectionsBehaviour extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            needToApplyFilters: false,
        };
    }

    public componentDidUpdate(prevProps: Props): void {
        if (this.state.needToApplyFilters) {
            this.setState({ needToApplyFilters: false }, () => this.props.applyFilters(this.props.filters));
        }

        if (this.props.selectedBudgetId && prevProps.selectedBudgetId !== this.props.selectedBudgetId) {
            this.props.resetFilters();
            this.props.saveUserConfig();
        }
    }

    public componentDidMount(): void {
        this.props.loadPageData();
    }

    public componentWillUnmount() {
        this.props.resetPageState();
        this.props.resetFilters();
    }

    public render() {
        const {
            applyFiltersInProgress,
            fetchMoreInProgress,
            allCorrectionsIds,
            corrections,
            isRequestInProgress,
            selectedBudgetId,
            filters: { isExportModeEnabled },
        } = this.props;

        return (
            <BudgetCorrectionsTemplate
                applyFiltersInProgress={applyFiltersInProgress}
                fetchMoreInProgress={fetchMoreInProgress}
                allCorrectionsIds={allCorrectionsIds}
                corrections={corrections}
                isRequestInProgress={isRequestInProgress}
                budgetId={selectedBudgetId}
                isExportModeEnabled={isExportModeEnabled}
                fetchMore={this.fetchMore}
                applyFilters={this.applyFilters}
            />
        );
    }

    @autobind
    private fetchMore(): void {
        this.props.fetchMore(this.props.filters);
    }

    @autobind
    private applyFilters(): void {
        this.setState({ needToApplyFilters: true });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const filters = getBudgetCorrectionsFiltersAndExportState(state).currentFilters;

    return {
        isRequestInProgress: isRequestInProgress(state),
        filters,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return {
        setRequestInProgress: (payload: boolean) => dispatch(setRequestInProgress(payload)),
        loadPageData: () => dispatch(loadPageData(null)),
        resetPageState: () => dispatch(resetPageState()),
        resetFilters: () => dispatch(resetFiltersComponentState()),
        saveUserConfig: () => dispatch(saveUserConfig(null)),
    };
}
