import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { UserConfigType } from 'sber-marketing-types/openid';

import { StoreState } from '@store';
import { updateUserConfig } from '@store/userConfig';
import { TargetFilter } from '@store/budgetCorrections/filtersAndExport';

import {
    BudgetCorrectionsUserConfig,
    LoadBudgetCorrectionsUserConfig,
    UpdateBudgetCorrectionsUserConfig,
} from './types';
import {
    loadBudgetCorrectionsUserConfig as loadBudgetCorrectionsUserConfigAction,
    updateBudgetCorrectionsUserConfig as updateBudgetCorrectionsUserConfigAction,
} from './actions';

export const loadBudgetCorrectionsUserConfig = bindThunkAction<
    StoreState,
    BudgetCorrectionsUserConfig,
    LoadBudgetCorrectionsUserConfig,
    Error
>(loadBudgetCorrectionsUserConfigAction, async (receivedUserConfig, dispatch, getState) => {
    return {
        receivedUserConfig: {
            [TargetFilter.Status]: receivedUserConfig[TargetFilter.Status] || [],
            [TargetFilter.Types]: receivedUserConfig[TargetFilter.Types] || [],
            [TargetFilter.ActivityNames]: receivedUserConfig[TargetFilter.ActivityNames] || [],
            [TargetFilter.ActivityTypes]: receivedUserConfig[TargetFilter.ActivityTypes] || [],
            [TargetFilter.BudgetItemNames]: receivedUserConfig[TargetFilter.BudgetItemNames] || [],
            [TargetFilter.Authors]: receivedUserConfig[TargetFilter.Authors] || [],
            [TargetFilter.Approvers]: receivedUserConfig[TargetFilter.Approvers] || [],
            [TargetFilter.Blocks]: receivedUserConfig[TargetFilter.Blocks] || [],
            [TargetFilter.Directions]: receivedUserConfig[TargetFilter.Directions] || [],
            [TargetFilter.Tools]: receivedUserConfig[TargetFilter.Tools] || [],
            [TargetFilter.ExecutionIds]: receivedUserConfig[TargetFilter.ExecutionIds] || [],
            [TargetFilter.Regionalities]: receivedUserConfig[TargetFilter.Regionalities] || [],
            [TargetFilter.Territories]: receivedUserConfig[TargetFilter.Territories] || [],
            [TargetFilter.Divisions]: receivedUserConfig[TargetFilter.Divisions] || [],
            [TargetFilter.CostCenter]: receivedUserConfig[TargetFilter.CostCenter] || [],
        },
    };

    return {
        receivedUserConfig,
    };
});

export const updateBudgetCorrectionsUserConfig = bindThunkAction<
    StoreState,
    UpdateBudgetCorrectionsUserConfig,
    void,
    Error
>(updateBudgetCorrectionsUserConfigAction, async (payload, dispatch) => {
    if (!payload.preventBackendUpdate) {
        dispatch(updateUserConfig(UserConfigType.BudgetCorrections));
    }
});
