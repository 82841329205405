import * as React from 'react';

import { BudgetApi, DictionaryApi } from '@api';
import { Dictionary, Budget, BudgetStatus } from './types';

interface Props {
    budgetId: string;
}

interface State {
    loading: boolean;
    dictionaries: Dictionary[];
}

export const withDictionaries = (WrappedComponent: any) => {
    return class extends React.Component<Props, State> {
        constructor(props: Props) {
            super(props);

            this.state = {
                loading: true,
                dictionaries: [],
            };
        }

        public async componentDidMount(): Promise<void> {
            let dictionaries: Dictionary[] = [];

            try {
                const budget = await this.fetchBudget();
                if (budget && budget.dictionaryOrganizationId) {
                    dictionaries = await this.fetchDictionary(budget.dictionaryOrganizationId);
                }
            } finally {
                this.setState({
                    loading: false,
                    dictionaries,
                });
            }
        }

        public render() {
            const { dictionaries, loading } = this.state;
            return React.createElement(WrappedComponent, {
                ...this.props,
                dictionaries,
                dictionariesLoading: loading,
            });
        }

        public async fetchDictionary(dictionaryOrganizationId: string): Promise<Dictionary[]> {
            return await DictionaryApi.getDictionaryList({ organizationId: dictionaryOrganizationId, treeview: true });
        }

        public async fetchBudget(): Promise<Budget> {
            const { budgetId } = this.props;
            const budgets = await BudgetApi.getBudgetList({ status: BudgetStatus.Execution });

            return budgets.find((budget) => budget.id === budgetId);
        }
    };
};
