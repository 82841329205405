// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-TagsList-FiltersDropdown-Dropdown-_styles__slidein{0%{opacity:0}100%{opacity:1}}.src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-TagsList-FiltersDropdown-Dropdown-_styles__root{position:relative}.src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-TagsList-FiltersDropdown-Dropdown-_styles__trigger{user-select:none}.src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-TagsList-FiltersDropdown-Dropdown-_styles__listWrapper{position:fixed;z-index:19;left:0;top:calc(100% + 10px);animation-duration:75ms;animation-name:src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-TagsList-FiltersDropdown-Dropdown-_styles__slidein;animation-timing-function:ease-out}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetPlanning/FiltersColumnsTagsBar/TagsList/FiltersDropdown/Dropdown/styles.scss"],"names":[],"mappings":"AAAA,wIACI,GACI,SAAU,CAGd,KACI,SAAU,CAAA,CAIlB,2HACI,iBAAkB,CACrB,8HAGG,gBAAiB,CACpB,kIAGG,cAAe,CACf,UAAW,CACX,MAAO,CACP,qBAAsB,CAEtB,uBAAwB,CACxB,2IAAuB,CACvB,kCAAmC","sourcesContent":["@keyframes slidein {\n    0% {\n        opacity: 0;\n    }\n\n    100% {\n        opacity: 1;\n    }\n}\n\n.root {\n    position: relative;\n}\n\n.trigger {\n    user-select: none;\n}\n\n.listWrapper {\n    position: fixed;\n    z-index: 19;\n    left: 0;\n    top: calc(100% + 10px);\n\n    animation-duration: 75ms;\n    animation-name: slidein;\n    animation-timing-function: ease-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-TagsList-FiltersDropdown-Dropdown-_styles__root",
	"trigger": "src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-TagsList-FiltersDropdown-Dropdown-_styles__trigger",
	"listWrapper": "src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-TagsList-FiltersDropdown-Dropdown-_styles__listWrapper",
	"slidein": "src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-TagsList-FiltersDropdown-Dropdown-_styles__slidein"
};
export default ___CSS_LOADER_EXPORT___;
