import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { MrmClient, ActivityApi } from '@api';

import { StoreState } from '@store';

import * as actions from './actions';
import { SwitchKeyActivityParams, InitCreativeRequestDomainParams, InitCreativeRequestDomainResult } from './types';
import { getActivity } from './selectors';

export const switchKeyActivity = bindThunkAction<StoreState, SwitchKeyActivityParams, void, Error>(
    actions.switchKeyActivityAsync,
    async (params, dispatch, getState) => {
        const { id, isKey } = params;
        const { realizationStart, realizationEnd } = getActivity(getState());

        return ActivityApi.editActivity(id, {
            isKey,
            realizationEnd,
            realizationStart,
        });
    },
);

export const initCreativeRequest = bindThunkAction<
    StoreState,
    InitCreativeRequestDomainParams,
    InitCreativeRequestDomainResult,
    Error
>(actions.initCreativeRequestDomainAsync, async (params) => {
    const { creativeRequestId } = params;

    const client = await MrmClient.getInstance();

    return await client.domain.creativeRequests.getCreativeRequest({
        id: creativeRequestId,
    });
});

export const updateCreativeRequest = bindThunkAction<
    StoreState,
    InitCreativeRequestDomainParams,
    InitCreativeRequestDomainResult,
    Error
>(actions.updateCreativeRequestDomainAsync, async (params) => {
    const { creativeRequestId } = params;

    const client = await MrmClient.getInstance();

    return await client.domain.creativeRequests.getCreativeRequest({
        id: creativeRequestId,
    });
});
