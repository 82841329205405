import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';

import type { StoreState } from '@store';
import type { CreativeRequestDomain } from '@store/creative/types';

import { initCreativeRequest, updateCreativeRequest } from '@store/creative/thunks';
import { resetPageState } from '@store/creative/actions';
import { getLoading, getCreativeRequestDomain } from '@store/creative/selectors';

export interface ChildrenProps {
    loading: boolean;
}

interface Props extends ExternalProps, Partial<MapProps & DispatchProps> {}

interface MapProps {
    loading: boolean;
    creativeRequestDomain: CreativeRequestDomain;
}

interface DispatchProps {
    initCreativeRequest: ({ creativeRequestId }: { creativeRequestId: string }) => void;
    updateCreativeRequest: ({ creativeRequestId }: { creativeRequestId: string }) => void;
    resetPageState: () => void;
}

interface ExternalProps {
    creativeRequestId: string;
    children: (props: ChildrenProps) => JSX.Element;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class WithInitCreativeRequireDomain extends React.PureComponent<Props> {
    public componentDidMount() {
        this.initCreativeRequest();
    }

    public async componentDidUpdate(prevProps: Readonly<Props>) {
        const creativeRequestIdChanged = prevProps.creativeRequestId !== this.props.creativeRequestId;
        const creativeRequestDomainChanged = prevProps.creativeRequestDomain !== this.props.creativeRequestDomain;

        if (creativeRequestIdChanged) {
            this.initCreativeRequest();
        }

        if (creativeRequestDomainChanged) {
            this.subscribeCreativeRequestReload();
        }
    }

    public componentWillUnmount() {
        this.props.resetPageState();
    }

    public render(): JSX.Element {
        const { loading } = this.props;

        return this.props.children({
            loading,
        });
    }

    @autobind
    private async onCreativeRequestReload() {
        this.updateCreativeRequest();
    }

    private initCreativeRequest() {
        this.props.initCreativeRequest({
            creativeRequestId: this.props.creativeRequestId,
        });
    }

    private updateCreativeRequest() {
        this.props.updateCreativeRequest({
            creativeRequestId: this.props.creativeRequestId,
        });
    }

    private subscribeCreativeRequestReload() {
        this.props.creativeRequestDomain.events.onReloaded(this.onCreativeRequestReload);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        loading: getLoading(state),
        creativeRequestDomain: getCreativeRequestDomain(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            initCreativeRequest,
            updateCreativeRequest,
            resetPageState,
        },
        dispatch,
    );
}
