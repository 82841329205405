import { createSelector } from 'reselect';
import createCashedSelector from 're-reselect';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';

import { SearchEntities, EntitiesGroupData, SearchState } from './types';

export const getSearchState = (state: StoreState) => state.search;

export const getEntitiesGroupData = createCashedSelector(
    getSearchState,
    (state: StoreState, entityType: SearchEntities): SearchEntities => entityType,
    (state, entitityType): EntitiesGroupData<any> => {
        const { loadingStatus, totalCount, entities } = state.entities[entitityType];

        return {
            loadingStatus,
            totalCount,
            entities,
        };
    },
)((state: StoreState, entityType: SearchEntities): SearchEntities => entityType);

export const getTotalCount = createSelector(getSearchState, (state) =>
    Object.keys(state.entities).reduce(
        (acc, entityType: SearchEntities) => acc + state.entities[entityType].totalCount,
        0,
    ),
);

export const areEntitiesLoading = createSelector(getSearchState, (state) =>
    Object.keys(state.entities).some(
        (entitityType) => state.entities[entitityType].loadingStatus === LoadingStatus.LOADING,
    ),
);

export const showEntitiesGroup = createCashedSelector(
    getSearchState,
    (state: StoreState, entityType: SearchEntities): SearchEntities => entityType,
    (state, entitityType): boolean => {
        const {
            expandedGroup,
            entities: {
                [entitityType]: { totalCount, loadingStatus },
            },
        } = state;

        return (
            (loadingStatus === LoadingStatus.LOADING || !!totalCount) &&
            (!expandedGroup || expandedGroup === entitityType)
        );
    },
)((state: StoreState, entityType: SearchEntities): SearchEntities => entityType);

export const shouldAnimate = createCashedSelector(
    getSearchState,
    (state: StoreState, entityType: SearchEntities): SearchEntities => entityType,
    (state, entitityType): boolean => state.entities[entitityType].shouldAnimate,
)((state: StoreState, entityType: SearchEntities): SearchEntities => entityType);

export const getLoadingStatus = createCashedSelector(
    getSearchState,
    (state: StoreState, entityType: SearchEntities): SearchEntities => entityType,
    (state, entitityType): LoadingStatus => state.entities[entitityType].loadingStatus,
)((state: StoreState, entityType: SearchEntities): SearchEntities => entityType);

export const getEntitiesCount = createSelector(
    getSearchState,
    (state: SearchState): Record<SearchEntities, number> => ({
        [SearchEntities.Files]: state.entities[SearchEntities.Files].totalCount,
        [SearchEntities.Activities]: state.entities[SearchEntities.Activities].totalCount,
        [SearchEntities.Tasks]: state.entities[SearchEntities.Tasks].totalCount,
        [SearchEntities.Comments]: state.entities[SearchEntities.Comments].totalCount,
    }),
);
