import { axios } from '../lib/axios';
import {
    ActivityParams,
    CreateActivityParams,
    ActivityType,
    ActivityCardResponse,
    ActivityCard,
    Brief,
    GetActivityAccessResponse,
} from 'sber-marketing-types/frontend';
import {
    ActivityListQuery,
    EditActivityParams,
    UpdateActivityBudgetItemReferenceType,
} from 'sber-marketing-types/backend';

const BASE_URL = '/api/activity';

interface UserProps {
    id: number;
    firstName: string;
    secondName: string;
}

interface DictionaryProps {
    id: string;
    name: string;
}

export interface GraphqlProjectProps {
    id: string;
    name: string;
    description: string;
    status: string;
    needStages: boolean;
    isKey: boolean;
    createTime: Date;
    updateTime: Date;
    preparation: Date;
    debriefing: Date;
    realizationStart: Date;
    realizationEnd: Date;
    author: UserProps;
    responsible: UserProps;
    participants: UserProps[];
    type: DictionaryProps;
    division: DictionaryProps;
    workTypes: DictionaryProps;
    product: DictionaryProps;
    activityStage: {
        id: string;
    };
}

export class ActivityApi {
    public static async getUserActivities(params: ActivityListQuery = {}): Promise<ActivityParams[]> {
        const res = await axios.post(`${BASE_URL}/list`, params);
        return res.data;
    }

    public static async getActivity(id: number, responsible?: boolean): Promise<ActivityParams> {
        const res = await axios.get(`${BASE_URL}/${id}`, { params: { responsible } });

        return res.data;
    }

    public static async getActivityBrief(id: number): Promise<Brief> {
        const res = await axios.get(`${BASE_URL}/${id}/brief`);

        return res.data;
    }

    public static async updateActivityBrief(id: number, briefData: any): Promise<any> {
        const res = await axios.put(`${BASE_URL}/${id}/brief`, briefData);

        return res.data;
    }

    public static async createActivityBriefCopyBlock(id: number, blockData: any): Promise<any> {
        const res = await axios.post(`${BASE_URL}/${id}/brief/copy-block`, blockData);

        return res.data;
    }

    public static async removeActivityBriefCopyBlock(id: number, blockData: any): Promise<any> {
        const res = await axios.post(`${BASE_URL}/${id}/brief/remove-copy-block`, blockData);

        return res.data;
    }

    public static async getActivityCards(params: ActivityListQuery = {}): Promise<ActivityCard[]> {
        const res = await axios.post<ActivityCardResponse>(`${BASE_URL}/cards`, params);
        return res.data.cards;
    }

    public static async createActivity(activity: CreateActivityParams): Promise<number> {
        const res = await axios.post(`${BASE_URL}`, activity);
        return res.data;
    }

    public static async editActivity(id: number, activity: EditActivityParams): Promise<void> {
        await axios.put(`${BASE_URL}/${id}`, activity);
    }

    public static async deleteActivity(id: number): Promise<void> {
        await axios.delete(`${BASE_URL}/${id}`);
    }

    public static async updateActivityBudgetItems(
        activityId: number,
        params: UpdateActivityBudgetItemReferenceType,
    ): Promise<void> {
        await axios.put(`${BASE_URL}/${activityId}/budget-item`, params);
    }

    public static async pinActivity(id: number): Promise<void> {
        await axios.post(`${BASE_URL}/${id}/switchFavorite`);
    }

    public static async getActivityTypes(params?: {
        organizationId?: string;
        ids?: string[];
    }): Promise<ActivityType[]> {
        const res = await axios.get('/api/activity-type/list', { params });
        return res.data;
    }

    public static async getAllActivityTypes(): Promise<ActivityType[]> {
        const res = await axios.get('/api/activity-type/list-all');
        return res.data;
    }

    public static async getActivityAccess(id: number): Promise<GetActivityAccessResponse> {
        const res = await axios.get(`${BASE_URL}/${id}/access`);

        return res.data;
    }
}
