import * as React from 'react';
import { Icon, IconType } from 'sber-marketing-ui';

import * as styles from './List.scss';

import type { CreativeRequestItem } from './Content/Item';
import { Content } from './Content';

interface Props {
    activityId: number;
    items: CreativeRequestItem[];
    displayCreateButton: boolean;
    onCreateButtonClick: () => void;
    closeList: () => void;
}

export const ListTemplate: React.FC<Props> = ({
    activityId,
    items,
    displayCreateButton,
    onCreateButtonClick,
    closeList,
}) => {
    return (
        <div className={styles.root}>
            {displayCreateButton && (
                <div className={styles.header} onClick={onCreateButtonClick}>
                    <div className={styles.buttonIcon}>
                        <Icon svgSize={16} type={IconType.PLUS_IN_CIRCLE} />
                    </div>
                    Создать заявку на креатив
                </div>
            )}

            <div className={styles.content}>
                <Content items={items} activityId={activityId} onLinkClick={closeList} />
            </div>
        </div>
    );
};
