// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-CorrectionCardTable-_CorrectionCardTable__body{display:grid;grid-template-columns:1fr 1fr;grid-template-rows:min-content min-content}.src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-CorrectionCardTable-_CorrectionCardTable__cell:nth-child(odd){border-right:1px solid #dedede}.src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-CorrectionCardTable-_CorrectionCardTable__cellHeader{background-color:#f6f6f6}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetCorrections/CorrectionsList/CardTypes/common/CorrectionCardTable/CorrectionCardTable.scss"],"names":[],"mappings":"AAAA,wIACI,YAAa,CAEb,6BAA8B,CAC9B,0CAA2C,CAC9C,uJAIO,8BAA+B,CAClC,8IAID,wBAAyB","sourcesContent":[".body {\n    display: grid;\n\n    grid-template-columns: 1fr 1fr;\n    grid-template-rows: min-content min-content;\n}\n\n.cell {\n    &:nth-child(odd) {\n        border-right: 1px solid #dedede;\n    }\n}\n\n.cellHeader {\n    background-color: #f6f6f6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-CorrectionCardTable-_CorrectionCardTable__body",
	"cell": "src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-CorrectionCardTable-_CorrectionCardTable__cell",
	"cellHeader": "src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-CorrectionCardTable-_CorrectionCardTable__cellHeader"
};
export default ___CSS_LOADER_EXPORT___;
