// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionNegativeLineBalanceWarning-ControlButtons-_ControlButtons__root{margin-top:32px}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionNegativeLineBalanceWarning-ControlButtons-_ControlButtons__buttonsWrapper{height:40px;display:flex;justify-content:space-between}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionNegativeLineBalanceWarning-ControlButtons-_ControlButtons__button{width:320px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/InternalTransitionNegativeLineBalanceWarning/ControlButtons/ControlButtons.scss"],"names":[],"mappings":"AAAA,2JACI,eAAgB,CACnB,qKAGG,WAAY,CACZ,YAAa,CACb,6BAA8B,CACjC,6JAGG,WAAY","sourcesContent":[".root {\n    margin-top: 32px;\n}\n\n.buttonsWrapper {\n    height: 40px;\n    display: flex;\n    justify-content: space-between;\n}\n\n.button {\n    width: 320px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionNegativeLineBalanceWarning-ControlButtons-_ControlButtons__root",
	"buttonsWrapper": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionNegativeLineBalanceWarning-ControlButtons-_ControlButtons__buttonsWrapper",
	"button": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionNegativeLineBalanceWarning-ControlButtons-_ControlButtons__button"
};
export default ___CSS_LOADER_EXPORT___;
