import { createSelector } from 'reselect';

import { AuthResponse } from 'sber-marketing-types/frontend';
import { UserConfigResponse, UserConfigType } from 'sber-marketing-types/openid';

import { StoreState } from '@store';

import { rtkApi } from './rtkApi';

export const authApi = rtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getAuthUser: builder.query<AuthResponse, void>({
            query: () => '/auth/user',
            providesTags: () => ['authUser'],
        }),
        getUserConfig: builder.query<UserConfigResponse, { type: UserConfigType }>({
            query: (params) => ({
                url: '/userConfig',
                params,
            }),
            providesTags: () => ['authUser'],
        }),
    }),
});

export const selectMyUserId = createSelector(
    (state: StoreState) => authApi.endpoints.getAuthUser.select()(state),
    ({
        data: {
            user: {
                attributes: { id },
            },
        },
    }) => id,
);

export const { useGetAuthUserQuery, useGetUserConfigQuery } = authApi;
