import * as React from 'react';
import { useSelector } from 'react-redux';
import { sum, values } from 'lodash';

import { StoreState } from '@store';
import {
    InternalTransferDirection,
    getTransitionBudgetItem,
    getBudgetTransferMenuState,
    getTotalTransferAmountForDest,
} from '@store/budgetExecution/budgetTransferMenu';

import { MonthNames, Balance, YearPlanReserveDiff } from './Balance';

import * as styles from './Balance.scss';

function useStore() {
    const toRow = useSelector((state: StoreState) => {
        const {
            cells: { to },
            controls: { rowForSumEntering, internalTransferDirection },
        } = getBudgetTransferMenuState(state);

        if (internalTransferDirection === InternalTransferDirection.ManyToOne) {
            return to[0].lineId;
        } else {
            return rowForSumEntering;
        }
    });
    const toBudgetItem = useSelector((state: StoreState) => getTransitionBudgetItem(state, toRow));
    const transferAmount = useSelector((state: StoreState) => getTotalTransferAmountForDest(state, toRow));
    const planReserveDiff =
        sum(values(toBudgetItem?.plannedFunds || {})) - sum(values(toBudgetItem.reservedFunds || {})) + transferAmount;

    return {
        toBudgetItem,
        transferAmount,
        planReserveDiff,
    };
}

export function DestBalance(): JSX.Element {
    const internalTransferDirection = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.internalTransferDirection,
    );

    switch (internalTransferDirection) {
        case InternalTransferDirection.OneToMany:
            return <DestRowBalance />;
        case InternalTransferDirection.ManyToOne:
            return <DestCellBalance />;
        default:
            return null;
    }
}

function DestRowBalance(): JSX.Element {
    const { toBudgetItem, transferAmount, planReserveDiff } = useStore();

    return (
        <Balance
            qaId="internalTransitionSumEnteringDestBalance"
            title={
                <span className={styles.title}>
                    В строку{' '}
                    <span
                        className={styles.titleBold}
                        {...{
                            'qa-id': 'internalTransitionSumEnteringDestBalanceSerialNumber',
                        }}
                    >
                        ID {toBudgetItem?.serialNumber}
                    </span>{' '}
                    будет внесено:
                </span>
            }
            transferAmount={transferAmount}
            subtitle={<YearPlanReserveDiff diff={planReserveDiff} />}
        />
    );
}

function useDestCellBalanceStore() {
    const toCell = useSelector((state: StoreState) => getBudgetTransferMenuState(state).cells.to[0]);

    return {
        ...useStore(),
        toCell,
    };
}

function DestCellBalance(): JSX.Element {
    const { toCell, toBudgetItem, transferAmount, planReserveDiff } = useDestCellBalanceStore();

    return (
        <Balance
            qaId="internalTransitionSumEnteringDestBalance"
            title={
                <span className={styles.title}>
                    В ячейку{' '}
                    <span
                        className={styles.titleBold}
                        {...{
                            'qa-id': 'internalTransitionSumEnteringDestBalanceSerialNumber',
                        }}
                    >
                        ID {toBudgetItem?.serialNumber} {MonthNames[toCell?.columnName]}
                    </span>{' '}
                    будет внесено:
                </span>
            }
            transferAmount={transferAmount}
            subtitle={<YearPlanReserveDiff diff={planReserveDiff} />}
        />
    );
}
