import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconType } from 'sber-marketing-ui';

import * as styles from './NotificationBar.scss';

export enum Color {
    Yellow = 'Yellow',
    Red = 'Red',
}

interface Props {
    color: Color;
    icon: IconType;
    text: React.ReactNode;
}

export function NotificationBar({ color, icon, text }: Props): React.ReactElement {
    let colorClass;
    switch (color) {
        case Color.Yellow:
            colorClass = styles.yellow;
            break;
        case Color.Red:
            colorClass = styles.red;
            break;
        default:
            console.warn(`WARNING: no style definition for color=${color}, used yellow`);
            colorClass = styles.yellow;
    }

    return (
        <div className={classNames(styles.root, colorClass)}>
            <Icon type={icon} svgSize={16} className={styles.icon} />

            {text}
        </div>
    );
}
