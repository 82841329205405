import * as React from 'react';
import { groupBy } from 'lodash';
import { FilesCache } from '../../../hooks';
import { useEffect, useState } from 'react';
import { FileGroup } from '../../../FileGroup/FileGroup';

interface Props {
    files: FilesCache;
}

export const FileGroupByFeild = ({ files }: Props): JSX.Element => {
    const [groupedFilesByFeild, setGroupedFilesByFeild] = useState<{ [feildName: string]: FilesCache }>({});

    useEffect(() => {
        const groupedFilesByFeild = groupBy(files, (file) => file.file.model.kind);
        setGroupedFilesByFeild(groupedFilesByFeild);
    }, [files]);

    return (
        <>
            {groupedFilesByFeild?.estimate?.length ? (
                <FileGroup title={'Смета / Биддинг'} files={groupedFilesByFeild?.estimate} />
            ) : null}
            {groupedFilesByFeild?.creative?.length ? (
                <FileGroup title={'Результат работ'} files={groupedFilesByFeild?.creative} />
            ) : null}
        </>
    );
};
