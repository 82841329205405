import * as React from 'react';
import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import { ComponentState, getBudgetTransferMenuState, isCellSelected } from '@store/budgetExecution/budgetTransferMenu';

import {
    CellDescriptionThemes,
    CellDescription as GeneralCellDescription,
    CellDescriptionStub,
} from '../../CellDescription';

function useStore() {
    const componentState = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.componentState,
    );
    const hoveredCell = useSelector((state: StoreState) => getBudgetTransferMenuState(state).cells.hoveredCell);
    const selectedCell = useSelector((state: StoreState) => {
        const { from, to } = getBudgetTransferMenuState(state).cells;

        switch (componentState) {
            case ComponentState.ExternalIncomeTransfer:
                return to;
            case ComponentState.ExternalOutcomeTransfer:
                return from;
        }

        return null;
    });

    const cellIsSelected = isCellSelected(selectedCell);
    const cell = cellIsSelected ? selectedCell[0] : hoveredCell;

    return { cell, componentState, cellIsSelected };
}

export function CellDescription(): JSX.Element {
    const { cell, componentState, cellIsSelected } = useStore();

    return isCellSelected(cell) ? (
        <GeneralCellDescription
            qaId="externalTransitionMenuCellDescription"
            cell={cell}
            theme={cellIsSelected ? CellDescriptionThemes.Regular : CellDescriptionThemes.Transparent}
        />
    ) : (
        <CellDescriptionStub
            large
            qaId="externalTransitionMenuCellStub"
            text={
                componentState === ComponentState.ExternalIncomeTransfer
                    ? 'Выберите ячейку, в которую хотите перенести бюджет'
                    : 'Выберите ячейку, из которой хотите перенести бюджет'
            }
        />
    );
}
