const SINGLE_MENTION = /\[@[а-яА-ЯёЁa-zA-Z _\-()\"\'\.]*\#\d{0,4}\]/g;
const MENTION_USER = /[а-яА-ЯёЁa-zA-Z _\-()\"\'\.]/g;
const MENTION_USER_ID = /\d{0,4}/g;

function parseMentionGroup(group: string, mentionCssClass: string, users: IUser[]): string {
    const mentions = group.match(SINGLE_MENTION).map((mention) => {
        const user = mention.match(MENTION_USER).join('');
        const userId = +mention.match(MENTION_USER_ID).join('');

        const spanOpen = mentionCssClass ? `<span class="${mentionCssClass}">` : '<span>';
        const spanClose = '</span>';

        const userObj = users.find((user) => user.id === userId);
        let userContent = user;
        if (userObj && userObj?.vacation) {
            const comment = userObj.vacation.comment ? userObj.vacation.comment.replace(/\n/g, '') : '';

            userContent = `<span title="${comment}">🌴 ${user} (в отпуске)</span>`;
        }

        return `${spanOpen}${userContent}${spanClose}`;
    });

    return `${mentions.join(', ')} `;
}

interface IUser {
    id: number;
    vacation?: any;
}

const MENTION_GROUP = /(\[@[а-яА-ЯёЁa-zA-Z _\-()\"\'\.]*\#\d{0,4}\]\s?)+/g;
export function parseMessage(message: string, mentionCssClass: string, users: IUser[]): string {
    if (!message) {
        return '';
    }

    let mentionUsed = 0;
    const mentions = (message.match(MENTION_GROUP) || []).map((mentionGroup) =>
        parseMentionGroup(mentionGroup, mentionCssClass, users),
    );
    const contents = message.replace(MENTION_GROUP, () => mentions[mentionUsed++]);

    return contents.replace(/\n/g, '<br>').replace(/\t/g, '&#9;');
}
