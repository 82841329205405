import * as React from 'react';
import classNames from 'classnames';

import * as style from './MediaplanStageActions.scss';

import { Icon, IconType, Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';

interface Props {
    canEdit: boolean;
    mediaplanUrl: string;
    displayUrl: boolean;
    displayProgressbar: boolean;
    rootRef: React.RefObject<HTMLDivElement>;
    onReturnButtonClick: () => void;
    onContinueButtonClick: () => void;
}

export const MediaplanStageActionsTemplate = ({
    canEdit,
    mediaplanUrl,
    displayUrl,
    displayProgressbar,
    rootRef,
    onReturnButtonClick,
    onContinueButtonClick,
}: Props): JSX.Element => {
    return (
        <div className={style.root} ref={rootRef}>
            {!displayProgressbar && (
                <>
                    {mediaplanUrl && (
                        <>
                            {displayUrl && (
                                <a className={classNames(style.title, style.link)} href={mediaplanUrl} target="_blank">
                                    Рассчитанный медиаплан
                                </a>
                            )}

                            {!displayUrl && <div className={style.title}>Рассчитанный медиаплан</div>}

                            <div className={style.linkIcon}>
                                <Icon type={IconType.ACCEPT_ACTIVE} svgSize={24} />
                            </div>
                        </>
                    )}

                    {!mediaplanUrl && (
                        <>
                            <div className={style.title}>Для расчёта медиаплана требуется заполнить бриф</div>
                        </>
                    )}

                    <div className={style.returnButton} onClick={onReturnButtonClick}>
                        Вернуться к брифу
                    </div>

                    <span className={style.separator}>или</span>

                    <div className={style.continueButton}>
                        <Button
                            disabled={!canEdit || !mediaplanUrl}
                            theme={ButtonTheme.MainRounded}
                            onClick={onContinueButtonClick}
                        >
                            Продолжить
                        </Button>
                    </div>
                </>
            )}

            {displayProgressbar && (
                <>
                    <div className={style.progressBar}>
                        <div className={style.progressBarTitle}>Расчет медиаплана</div>
                    </div>

                    <div className={classNames(style.progressBar, style.finishedPart)}>
                        <div className={style.progressBarTitle}>Расчет медиаплана</div>
                    </div>
                </>
            )}
        </div>
    );
};
