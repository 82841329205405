import * as React from 'react';
import { Icon, IconType, Input_redesign as Input, InputTheme_redesign as InputTheme } from 'sber-marketing-ui';

import * as styles from './SearchInput.scss';

interface Props {
    qaId: string;
    value: string;
    onChange: (value: string) => void;
}

export function SearchInput({ qaId, value, onChange }: Props): JSX.Element {
    return (
        <div className={styles.root}>
            <Icon type={IconType.SEARCH} svgSize={10} />

            <Input
                qaId={qaId}
                autoFocus
                placeholder="Поиск"
                value={value}
                theme={InputTheme.Simple}
                className={styles.input}
                onInputChange={onChange}
            />
        </div>
    );
}
