import * as React from 'react';
import classNames from 'classnames';
import * as moment from 'moment/moment';

import { useGetAuthUserQuery, useGetTaskQuery, useGetUserQuery } from '@api';

import { useGetChannelParticipantsCountText, useGetTaskChannel } from '@modules/task/hooks';
import { ChannelAccessAction, useChannelAccess } from '@modules/channel/hooks';

import { EditChannelButton } from '@modules/task/components';

import * as styles from './TaskChannelInfo.scss';

export interface TaskChannelInfoProps extends React.HTMLProps<HTMLDivElement> {
    taskId: string;
    channelId: number;
}

export function TaskChannelInfo({ className, taskId, channelId, children, ...props }: TaskChannelInfoProps) {
    const { isLoading: isAuthLoading } = useGetAuthUserQuery();
    const { isLoading: isTaskLoading } = useGetTaskQuery({ id: taskId });
    const { channel, isLoading: isChannelLoading } = useGetTaskChannel(taskId, channelId);
    const { text: participantsCount, isLoading: isParticipantsCountLoading } = useGetChannelParticipantsCountText(
        taskId,
        channelId,
    );
    const {
        isLoading: isAccessLoading,
        access: [canChangeTitle, canAddParticipant],
    } = useChannelAccess([ChannelAccessAction.changeTitle, ChannelAccessAction.addParticipant], channelId, taskId);

    const { data: author, isLoading: isAuthorLoading } = useGetUserQuery(
        { id: channel?.authorId },
        { skip: !channel?.authorId },
    );

    const isLoading =
        isAuthorLoading ||
        isTaskLoading ||
        isAuthLoading ||
        isChannelLoading ||
        isParticipantsCountLoading ||
        isAccessLoading;

    const createTime = channel && moment(channel.createTime).format('DD.MM.YYYY');

    const authorElement =
        author && `${author.secondName} ${author.firstName} ${author.middleName}, ${author.departmentName}`;

    const editor =
        canChangeTitle || canAddParticipant ? (
            <EditChannelButton autoPlace view="link" taskId={taskId} channelId={channelId}>
                {canChangeTitle ? 'Редактировать' : 'Добавить участников'}
            </EditChannelButton>
        ) : null;

    return (
        <div {...props} className={classNames(styles.root, isLoading && styles.loading, className)}>
            <div>Канал создан: {createTime}</div>
            <div>Создатель: {authorElement}</div>
            <div>{participantsCount}</div>
            {editor}
        </div>
    );
}
