import * as moment from 'moment';
import { ActivityStatus } from 'sber-marketing-types/frontend';

import { resetableReducerWithInitialState, stubNull, replaceReducer } from '../../common/utils';
import {
    editActivityEndDate,
    editActivityId,
    editByTaskData,
    editActivityStatus,
    editActivityStartDate,
    editByActivityData,
    resetTaskEditor,
} from '../actions';
import { resetStore } from '../../common/actions';

export const activityId = resetableReducerWithInitialState<number | null>(stubNull, resetStore, resetTaskEditor)
    .case(editByTaskData, (_, { activityId }) => activityId)
    .case(editByActivityData, (_, { id }) => id)
    .case(editActivityId, replaceReducer);

export const activityStatus = resetableReducerWithInitialState<ActivityStatus | null>(
    stubNull,
    resetStore,
    resetTaskEditor,
)
    .case(editByActivityData, (_, { status }) => status)
    .case(editActivityStatus, replaceReducer);

export const activityStartDate = resetableReducerWithInitialState<moment.Moment>(moment, resetStore, resetTaskEditor)
    .case(editByActivityData, (_, { start }) => moment(start))
    .case(editActivityStartDate, replaceReducer);

export const activityEndDate = resetableReducerWithInitialState<moment.Moment>(moment, resetStore, resetTaskEditor)
    .case(editByActivityData, (_, { end }) => moment(end))
    .case(editActivityEndDate, replaceReducer);
