import * as React from 'react';
// import * as classnames from 'classnames';

import * as style from './Table.scss';

import type { ColumnName, LineId } from './types';

import { TableViewModel } from './TableViewModel';
import { TableView } from './TableView';

interface Props {
    viewModel: TableViewModel;
    totalColumns: ColumnName[];
    tableColumns: ColumnName[];
    lineGroups: LinesGroup[];
    tableRef: (name: string, component: TableView) => void;
}

export interface LinesGroup {
    name: string;
    lines: LineId[];
}

export const TableTemplate = ({ viewModel, totalColumns, tableColumns, lineGroups, tableRef }: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.totalTable}>
                <TableView
                    ref={(component) => tableRef('total', component)}
                    viewModel={viewModel}
                    columns={totalColumns}
                    lines={['total']}
                    lineHeight={85}
                    hideHeader
                    hideHorizontalScrollbar
                />
            </div>

            {lineGroups.map((group) => (
                <div className={style.group} key={group.name}>
                    <div className={style.groupName}>{group.name}</div>

                    <div className={style.table}>
                        <TableView
                            ref={(component) => tableRef(group.name, component)}
                            viewModel={viewModel}
                            columns={tableColumns}
                            lines={group.lines}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};
