import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { StoreState } from '@store';
import { hideLineModal } from '@store/budgetExecution';

import { Modal } from './Modal';

interface Props extends Partial<MapProps & DispatchProps> {
    lineId: string;
}

interface MapProps {
    lineYCoord: number;
}

interface DispatchProps {
    onMaskClick: () => void;
}

@(connect(null, mapDispatchToProps) as any)
export class ModalContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { children, onMaskClick } = this.props;

        return (
            <Modal lineYCoord={this.lineYCoord} onMaskClick={onMaskClick}>
                {children}
            </Modal>
        );
    }

    private get lineYCoord(): number {
        const { lineId } = this.props;
        const tableLineElement = document.querySelector<HTMLDivElement>(`div[data-table-line-id=prefix${lineId}]`);

        if (tableLineElement) {
            return tableLineElement.getBoundingClientRect().top;
        }

        return null;
    }
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
    return {
        onMaskClick: () => dispatch(hideLineModal()),
    };
}
