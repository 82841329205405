import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { GetEditorPayload } from '@mrm/R7';

import { R7Api, FileApi } from '@api';

import { StoreState } from '@store';

import * as actions from './actions/sync';
import * as asyncActions from './actions/async';

import { OpenEditorPayload, LoadDocumentStatusPayload } from './types';

function injectFavicon(editorWindow: Window, faviconSize: number) {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/png';
    (link as any).sizes = `${faviconSize}x${faviconSize}`;
    link.href = `https://mrm.sbermarketing.ru/static/images/favicon-${faviconSize}x${faviconSize}.png`;

    editorWindow.document.head.appendChild(link);
}

async function openEditorWindow(payload: GetEditorPayload) {
    const editorContent = (await R7Api.getEditor(payload)).content;
    const editorWindow = window.open('', '_blank');

    editorWindow.document.body.style.margin = '0';

    const title = document.createElement('title');
    title.innerText = `${payload.fileName}.${payload.fileType}`;
    editorWindow.document.head.appendChild(title);

    injectFavicon(editorWindow, 16);
    injectFavicon(editorWindow, 32);
    injectFavicon(editorWindow, 48);

    const frame = document.createElement('iframe');
    frame.style.width = '100vw';
    frame.style.height = '100vh';
    frame.style.border = '0';
    editorWindow.document.body.appendChild(frame);
    frame.contentDocument.write(editorContent);
}

export const openEditor = bindThunkAction<StoreState, OpenEditorPayload, void, Error>(
    asyncActions.openEditor,
    async (payload, dispatch, getState) => {
        const { fileParams, ...fileDesc } = payload;

        const { containerName, storageEngine } = await FileApi.getDownloadSettings(fileParams, fileDesc.fileId);

        await openEditorWindow({
            ...fileDesc,
            containerName,
            storageEngine,
        });
    },
);

export const loadDocumentStatus = bindThunkAction<StoreState, LoadDocumentStatusPayload, void, Error>(
    asyncActions.loadDocumentStatus,
    async (payload, dispatch, getState) => {
        const { fileId, fileParams } = payload;

        const { containerName, storageEngine } = await FileApi.getDownloadSettings(fileParams, fileId);

        const status = await R7Api.getDocumentStatus({
            fileId,
            containerName,
            storageEngine,
        });

        dispatch(
            actions.setDocumentStatus({
                documentId: payload.fileId,
                status,
            }),
        );
    },
);
