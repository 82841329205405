import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { UserVacation } from '@mrm/user-vacation';

import { LoadingStatus } from '@store/commonTypes';

import { ProfileMenuVacationState as State } from './types';

import * as actions from './actions/sync';

class Reducer {
    public static makeInitialState(): State {
        return {
            loadingStatus: LoadingStatus.NOT_LOADED,
            isRequestInProgress: false,
            isDeleteVacationPopupVisible: false,
            existingVacation: null,
            pendingVacation: null,
        };
    }

    public static setExistingVacation(state: State, existingVacation: UserVacation): State {
        return Reducer.stateReducer(state, { existingVacation });
    }

    public static setPendingVacation(state: State, pendingVacation: UserVacation): State {
        return Reducer.stateReducer(state, { pendingVacation });
    }

    public static updatePendingVacation(state: State, updPendingVacation: Partial<UserVacation>): State {
        const pendingVacation = { ...state.pendingVacation, ...updPendingVacation };

        return Reducer.stateReducer(state, { pendingVacation });
    }

    public static setLoadingStatus(state: State, loadingStatus: LoadingStatus): State {
        return Reducer.stateReducer(state, { loadingStatus });
    }

    public static setRequestInProgress(state: State, isRequestInProgress: boolean): State {
        return Reducer.stateReducer(state, { isRequestInProgress });
    }

    public static setDeleteVacationPopupVisibility(state: State, isDeleteVacationPopupVisible: boolean): State {
        return Reducer.stateReducer(state, { isDeleteVacationPopupVisible });
    }

    private static stateReducer(state: State, payload: Partial<State>): State {
        return { ...state, ...payload };
    }
}

export const profileMenuVacationReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(actions.setExistingVacation, Reducer.setExistingVacation)
    .case(actions.setPendingVacation, Reducer.setPendingVacation)
    .case(actions.updatePendingVacation, Reducer.updatePendingVacation)
    .case(actions.setLoadingStatus, Reducer.setLoadingStatus)
    .case(actions.setRequestInProgress, Reducer.setRequestInProgress)
    .case(actions.setDeleteVacationPopupVisibility, Reducer.setDeleteVacationPopupVisibility);
