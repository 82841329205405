import * as React from 'react';
import classNames from 'classnames';
import * as lodash from 'lodash';
import * as moment from 'moment';

import * as style from './BriefStageWidget.scss';

import type { BriefStageForm } from '@store/autopilotRadio/types';

import {
    OpacityTransition,
    LabeledInput,
    InputType,
    LabeledSelect,
    SelectItem,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    Icon,
    IconType,
} from 'sber-marketing-ui';

const INTERVAL_DURATION_ITEMS = lodash.times(6).map((item) => ({
    label: `${(item + 1) * 5} секунд`,
    value: (item + 1) * 5,
}));

interface Props {
    readOnly: boolean;
    loading: boolean;
    isBudgetFocus: boolean;
    briefStageForm: BriefStageForm;
    datesRange: string;
    blockItems: SelectItem[];
    productItems: SelectItem[];
    targetGroupsItems: SelectItem[];
    onBlockChange: (value: string) => void;
    onProductChange: (value: string) => void;
    onBudgetChange: (value: string) => void;
    onBudgetFocus: () => void;
    onBudgetBlur: () => void;
    onStartDateChange: (value: moment.Moment) => void;
    onEndDateChange: (value: moment.Moment) => void;
    onTargetGroupChange: (value: string) => void;
    onIntervalAddClick: () => void;
    onIntervalDurationChange: (index: number, value: number) => void;
    onIntervalDeleteClick: (index: number) => void;
}

export const BriefStageWidgetTemplate = ({
    readOnly,
    loading,
    isBudgetFocus,
    briefStageForm,
    datesRange,
    blockItems,
    productItems,
    targetGroupsItems,
    onBlockChange,
    onProductChange,
    onBudgetChange,
    onBudgetFocus,
    onBudgetBlur,
    onStartDateChange,
    onEndDateChange,
    onTargetGroupChange,
    onIntervalAddClick,
    onIntervalDurationChange,
    onIntervalDeleteClick,
}: Props): JSX.Element => {
    return (
        <OpacityTransition start={!loading}>
            <div className={style.root}>
                <div className={style.title}>Бриф запуска РК</div>

                <div className={style.form}>
                    <div className={classNames(style.field, style.line)}>
                        <LabeledSelect
                            title={'Блок *'}
                            items={blockItems}
                            selectedValue={briefStageForm.blockId}
                            onSelection={onBlockChange}
                            readOnly={readOnly}
                        />
                    </div>

                    <div className={classNames(style.field, style.line)}>
                        <LabeledSelect
                            title={'Продукт *'}
                            items={productItems}
                            selectedValue={briefStageForm.productId}
                            onSelection={onProductChange}
                            readOnly={readOnly}
                        />
                    </div>

                    <div className={classNames(style.smallField, style.line)}>
                        <LabeledInput
                            title={'Бюджет на весь период *'}
                            value={briefStageForm.budget}
                            errorMessage={briefStageForm.budgetError}
                            displayError={
                                !!briefStageForm.budget?.length &&
                                !!briefStageForm.budgetError?.length &&
                                !isBudgetFocus
                            }
                            onValueChange={onBudgetChange}
                            onFocus={onBudgetFocus}
                            onBlur={onBudgetBlur}
                            onlyNumbers
                            readOnly={readOnly}
                        />
                    </div>

                    <div className={style.line}>
                        <div className={style.smallField}>
                            <LabeledInput
                                title={'Старт проекта *'}
                                value={moment(briefStageForm.dateStart)}
                                maxDate={moment(briefStageForm.dateEnd)}
                                type={InputType.DatePicker}
                                onValueChange={onStartDateChange}
                                readOnly={readOnly}
                            />
                        </div>

                        <span className={style.separator}>—</span>

                        <div className={style.smallField}>
                            <LabeledInput
                                title={'Окончание проекта *'}
                                value={moment(briefStageForm.dateEnd)}
                                minDate={moment(briefStageForm.dateStart)}
                                type={InputType.DatePicker}
                                onValueChange={onEndDateChange}
                                readOnly={readOnly}
                            />

                            {datesRange && <div className={style.fieldMessage}>Длительность проекта: {datesRange}</div>}
                        </div>
                    </div>

                    <div className={classNames(style.field, style.line)}>
                        <LabeledSelect
                            title={'Целевая аудитория *'}
                            items={targetGroupsItems}
                            selectedValue={briefStageForm.targetGroup?.id || null}
                            onSelection={onTargetGroupChange}
                            readOnly={readOnly}
                        />
                    </div>

                    <div className={classNames(style.field, style.line)}>
                        <div className={style.fieldTitle}>Длительность роликов *</div>

                        <div className={style.addIntervalButton}>
                            <Button theme={ButtonTheme.MainRounded} onClick={onIntervalAddClick}>
                                + Добавить ролик
                            </Button>
                        </div>

                        <div className={style.intervalsList}>
                            {briefStageForm.intervals.map((item, index) => (
                                <div className={style.interval} key={index}>
                                    <div className={style.interval}>
                                        <div className={style.intervalLength}>
                                            <LabeledSelect
                                                title={'Длительность *'}
                                                items={INTERVAL_DURATION_ITEMS}
                                                selectedValue={item}
                                                onSelection={(value) =>
                                                    onIntervalDurationChange(index, value as number)
                                                }
                                                readOnly={readOnly}
                                            />
                                        </div>

                                        {briefStageForm.intervals.length > 1 && (
                                            <div
                                                className={style.deleteIntervalButton}
                                                onClick={() => onIntervalDeleteClick(index)}
                                            >
                                                <Icon type={IconType.BIN} svgSize={24} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </OpacityTransition>
    );
};
