// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-Card-CardDate-_CardDate__calendarIcon{margin:-1px -1px 0 -1px}.src-client-modules-common-Card-CardDate-_CardDate__calendarIconWrapper{margin-right:5px;line-height:0}.src-client-modules-common-Card-CardDate-_CardDate__loading{width:50px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/Card/CardDate/CardDate.scss"],"names":[],"mappings":"AAAA,iEACI,uBAAwB,CAC3B,wEAGG,gBAAiB,CAEjB,aAAc,CACjB,4DAGG,UAAW","sourcesContent":[".calendarIcon {\n    margin: -1px -1px 0 -1px;\n}\n\n.calendarIconWrapper {\n    margin-right: 5px;\n\n    line-height: 0;\n}\n\n.loading {\n    width: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendarIcon": "src-client-modules-common-Card-CardDate-_CardDate__calendarIcon",
	"calendarIconWrapper": "src-client-modules-common-Card-CardDate-_CardDate__calendarIconWrapper",
	"loading": "src-client-modules-common-Card-CardDate-_CardDate__loading"
};
export default ___CSS_LOADER_EXPORT___;
