import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';

import * as styles from './Tab.scss';

export interface TabProps<V extends string = string>
    extends Omit<React.HTMLProps<HTMLButtonElement>, 'type' | 'value' | 'onSelect'> {
    value: V;
    selected?: boolean;
    squeeze?: boolean;
    onSelect?: (value: V) => void;
    icon?: IconType;
}

export function Tab<V extends string = string>({
    className,
    selected,
    value,
    icon,
    squeeze,
    onSelect,
    onClick,
    children,
    ...props
}: TabProps<V>) {
    const handleClick: typeof onClick = (e) => {
        onSelect(value);
        onClick?.(e);
    };

    return (
        <button
            data-qa-id="Tab"
            data-qa-tab={value}
            {...props}
            onClick={handleClick}
            className={classNames(styles.root, selected && styles.selected, squeeze && styles.squeeze, className)}
        >
            {icon && <Icon type={icon} svgSize={24} className={styles.icon} />}
            {children}
        </button>
    );
}
