import { axios, AxiosResponse } from '../lib/axios';

import {
    ActivityBudget,
    ActivityBudgetListQueryParams,
    ActivitySnapshot,
    ActivitySnapshotQueryParams,
    CreateActivityBudgetForm,
    UpdateActivityBudgetForm,
} from '@mrm/budget';

const BASE_URL = '/api/activityBudget';

export class ActivityBudgetApi {
    public static async getActivityBudget(activityId: string): Promise<ActivityBudget> {
        const res: AxiosResponse<ActivityBudget> = await axios.get(`${BASE_URL}/${activityId}`);

        return res.data;
    }

    public static async getActivityBudgetList(params: ActivityBudgetListQueryParams): Promise<ActivityBudget[]> {
        const res: AxiosResponse<ActivityBudget[]> = await axios.get(BASE_URL, { params });

        return res.data;
    }

    public static async getActivityBudgetSnapshots(
        activityId: string,
        params: ActivitySnapshotQueryParams,
    ): Promise<ActivitySnapshot[]> {
        const res: AxiosResponse<ActivitySnapshot[]> = await axios.get(`${BASE_URL}/${activityId}/snapshots`, {
            params,
        });

        return res.data;
    }

    public static async createActivityBudget(params: CreateActivityBudgetForm): Promise<void> {
        await axios.post(BASE_URL, params);
    }

    public static async updateActivityBudget(params: UpdateActivityBudgetForm): Promise<void> {
        await axios.put(BASE_URL, params);
    }

    public static async graphqlGetAcvitityBudgetsByName(
        activityName: string,
        budgetId: string,
    ): Promise<ActivityBudget[]> {
        return (
            await axios.post(`/api/graphql`, {
                operationName: 'getActivityBudgets',
                query: `query getActivityBudgets { activityBudgets:findBudgetItems(filter:{activityName: "${activityName}", budgetId: "${budgetId}"}, caseSensitive: true) { nodes { activity { id name } } } }`,
            })
        ).data.data.activityBudgets.nodes.map((result: any) => result.activity);
    }
}
