import * as React from 'react';
import classNames from 'classnames';

import * as style from './StatusCell.scss';

import { Icon, IconType } from 'sber-marketing-ui';

interface Props {
    selectionType: 'green' | 'yellow';
    disabled: boolean;
    customStyle?: any;
    onClick: () => void;
}

export const StatusCell = ({ selectionType, disabled, customStyle, onClick }: Props): JSX.Element => {
    let iconType: IconType;

    switch (selectionType) {
        case 'green':
            iconType = IconType.ACCEPT_ICON_ROUND_ACTIVE;
            break;

        case 'yellow':
            iconType = IconType.ACCEPT_ICON_ROUND_ACTIVE_YELLOW;
            break;

        default:
            iconType = IconType.ACCEPT_ICON_ROUND;
            break;
    }

    return (
        <div
            className={classNames(
                style.root,
                selectionType === 'green' && style.selectionType_green,
                selectionType === 'yellow' && style.selectionType_yellow,
                disabled && style.disabled,
            )}
            style={{ ...customStyle }}
        >
            <div className={style.button} onClick={!disabled ? onClick : null}>
                <Icon type={iconType} svgSize={16} />
            </div>
        </div>
    );
};
