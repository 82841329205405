import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, AnyAction, bindActionCreators } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { BudgetStatus, BudgetItemStatus } from '@mrm/budget';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import { UserResponseParams } from 'sber-marketing-types/frontend';

import { PlanPermissionsSysNames } from 'sber-marketing-types/backend';
import {
    TotalBudgets,
    ColumnsVisiblityFilter,
    ColumnName,
    ColumnsWidth,
    ChangeList,
    LineStatusChange,
    LineStatusChanges,
    CURRENCY_COLUMN_NAMES,
    CellValueType,
    ChangeCellValueParams,
    TableLine,
    getBudgetPlanningPageState,
    getTableFilters,
    getActivitiesTotalBudgets,
    getUnsavedChanges,
    getTableLines,
    changeCellValue,
    setLineStatus,
    deleteLineStatus,
    setRejectMenuLineId,
    userCanEditOrCreateBudgetData,
} from '@store/budgetPlanning';
import { loadByActivityId } from '@store/budgetPlanning/miscBudgetItems';
import { LineCellsParams } from '../LayerManager';

import { TableBody } from './TableBody';
import { StoreState } from '@store';
import { getBudgetState } from '@store/budgetPage';
import { getBudgetByStatusUserConfig } from '@store/userConfig/budget';

import { getLoginUser } from '@store/user/selector';

import { Captions } from '@common/Utils';
import { FrameManager } from '../FrameManager';
import { ColumnsList } from '../../ColumnsConfig';

interface Props extends Partial<MapProps>, Partial<DispatchProps>, Partial<RouteComponentProps> {
    frameManager: FrameManager;
    cellsParams: { [lineId: string]: LineCellsParams };
    currentFrameIndex: number;
    rootWidth: number;
    visibleColumnsNames: ColumnName[];
    hoveredLineId: string;
    hoveredColumnName: ColumnName;
    draggedEdgeColumnName: ColumnName;
    onLineMouseEnter: (id: string) => void;
    fixedColumnsLinesRef: (element: HTMLDivElement) => void;
    totalLinesContentRef: (element: HTMLDivElement) => void;
    onLineMouseLeave: () => void;
    onInfoMouseEnter: (lineId: string) => void;
    onInfoMouseLeave: () => void;
    onDropdownCellClick: (
        columnName: ColumnName,
        budgetItemId: string,
        dropdownContent: JSX.Element,
        contentHeight: number,
    ) => void;
}

interface MapProps {
    dictionariesOrganizationId: string;
    budgetId: string;
    columnsWidth: ColumnsWidth;
    fixedColumnsNames: ColumnName[];
    columnsVisiblityFilter: ColumnsVisiblityFilter;
    activitiesTotalBudgets: TotalBudgets;
    unsavedChanges: ChangeList;
    lineStatusChanges: LineStatusChanges;
    userIsBudgetExpert: boolean;
    userIsSupervisor: boolean;
    allUsers: UserResponseParams[];
    lines: TableLine[];
    userCanEditOrCreateBudgetData: boolean;
}

interface DispatchProps {
    deleteLineStatus: (lineId: string) => void;
    changeCellValue: (correction: ChangeCellValueParams) => void;
    setLineStatus: (statusChange: LineStatusChange) => void;
    setRejectMenuLineId: (lineId: string) => void;
    loadByActivityId: (activityIds: string[]) => void;
}

interface State {
    hoveredLineId: string;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class TableBodyContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            hoveredLineId: null,
        };
    }

    public render(): JSX.Element {
        return React.createElement(TableBody, {
            frameManager: this.props.frameManager,
            cellsParams: this.props.cellsParams,
            currentFrameIndex: this.props.currentFrameIndex,
            fixedColumnsNames: this.props.fixedColumnsNames,
            activitiesTotalBudgets: this.props.activitiesTotalBudgets,
            unsavedChanges: this.props.unsavedChanges,
            columnsVisiblityFilter: this.props.columnsVisiblityFilter,
            rootWidth: this.props.rootWidth,
            columnsWidth: this.props.columnsWidth,
            visibleColumnsNames: this.props.visibleColumnsNames,
            hoveredLineId: this.props.hoveredLineId,
            hoveredColumnName: this.props.hoveredColumnName,
            draggedEdgeColumnName: this.props.draggedEdgeColumnName,
            lineStatusChanges: this.props.lineStatusChanges,
            userIsBudgetExpert: this.props.userIsBudgetExpert,
            userIsSupervisor: this.props.userIsSupervisor,
            allUsers: this.props.allUsers,
            userCanEditOrCreateBudgetData: this.props.userCanEditOrCreateBudgetData,
            fixedColumnsLinesRef: this.props.fixedColumnsLinesRef,
            totalLinesContentRef: this.props.totalLinesContentRef,
            onLineMouseEnter: this.props.onLineMouseEnter,
            onLineMouseLeave: this.props.onLineMouseLeave,
            onEditActivityButtonClick: this.onEditActivityButtonClick,
            onCopyActivityButtonClick: this.onCopyActivityButtonClick,
            onInfoMouseEnter: this.props.onInfoMouseEnter,
            onInfoMouseLeave: this.props.onInfoMouseLeave,
            onInputValueChange: this.onInputValueChange,
            onDropdownCellClick: this.props.onDropdownCellClick,
            onDropdownItemSelection: this.onDropdownItemSelection,
            onDatepickerValueChange: this.onDatepickerValueChange,
            onApproveButtonClick: this.onApproveButtonClick,
            onRejectButtonClick: this.onRejectButtonClick,
            onCellCopy: this.onCellCopy,
        });
    }

    @autobind
    protected onCellCopy(lineId: string, columnName: ColumnName, event: React.ClipboardEvent<HTMLDivElement>): void {
        const column = ColumnsList.find((column) => column.name === columnName);

        if (column.valueType === CellValueType.Currency) {
            const cellParams = this.props.cellsParams[lineId][columnName];
            const value = String(cellParams.tooltip || cellParams.title)
                .replace(/ /g, '')
                .replace(Captions.Roubles, '')
                .replace('.', ',');

            event.clipboardData.setData('text/plain', value);
            event.preventDefault();
        }
    }

    @autobind
    protected onInputValueChange(budgetItemId: string, columnName: ColumnName, value: string, originalValue: string) {
        let usedOriginalValue = originalValue || null;
        if (CURRENCY_COLUMN_NAMES.includes(columnName) && originalValue) {
            usedOriginalValue = String(Number(originalValue) / 100.0);
        }

        this.props.changeCellValue({
            change: {
                budgetItemId,
                columnName,
                value: value || null,
                originalValue: usedOriginalValue,
            },
        });

        if (columnName === ColumnName.ActivityName && value !== originalValue) {
            const activityId = this.props.lines.find((line) => line.id === budgetItemId)?.activityId;
            if (activityId) {
                this.props.loadByActivityId([activityId]);
            }
        }
    }

    @autobind
    protected onDropdownItemSelection(budgetItemId: string, columnName: ColumnName, id: string, originalId: string) {
        const { lines } = this.props;

        this.props.changeCellValue({
            change: {
                budgetItemId,
                columnName,
                value: id,
                originalValue: originalId,
            },
            line: lines.find((item) => item.id == budgetItemId),
        });
    }

    @autobind
    protected onDatepickerValueChange(budgetItemId: string, columnName: ColumnName, value: Date, originalValue: Date) {
        this.props.changeCellValue({
            change: {
                budgetItemId,
                columnName,
                value,
                originalValue,
            },
        });
    }

    @autobind
    protected onEditActivityButtonClick(activityBudgetId: string) {
        const { budgetId, dictionariesOrganizationId } = this.props;
        this.openUrlInSameTab(
            `/budget/planning/${activityBudgetId}?budgetId=${budgetId}&dictionariesOrganizationId=${dictionariesOrganizationId}`,
        );
    }

    @autobind
    protected onCopyActivityButtonClick(activityBudgetId: string) {
        const { budgetId, dictionariesOrganizationId } = this.props;
        this.openUrlInSameTab(
            `/budget/planning/${activityBudgetId}/copy?budgetId=${budgetId}&dictionariesOrganizationId=${dictionariesOrganizationId}`,
        );
    }

    @autobind
    protected onApproveButtonClick(lineId: string) {
        const statusCell = this.props.lineStatusChanges[lineId];

        if (statusCell && statusCell.status == BudgetItemStatus.Approved) {
            this.props.deleteLineStatus(lineId);
        } else {
            this.props.setLineStatus({ lineId, status: BudgetItemStatus.Approved });
        }
    }

    @autobind
    protected onRejectButtonClick(lineId: string) {
        const lineStatusChange = this.props.lineStatusChanges[lineId];

        if (lineStatusChange && lineStatusChange.status == BudgetItemStatus.Rejected) {
            this.props.deleteLineStatus(lineId);
        } else {
            this.props.setRejectMenuLineId(lineId);
        }
    }

    private openUrlInSameTab(url: string) {
        this.props.history.push(url);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { budgetId } = getBudgetByStatusUserConfig(state, BudgetStatus.Plan);
    const { budgets } = getBudgetState(state, BudgetStatus.Plan);
    const selectedBudget = budgets.find((budget) => budget.id === budgetId);

    const {
        fixedColumnsNames,
        lineStatusChanges,
        columnsWidth,
        pageData: { allUsers },
    } = getBudgetPlanningPageState(state);
    const { columnsVisiblityFilter } = getTableFilters(state);
    const user = getLoginUser(state);

    const userIsBudgetExpert = lodash.includes(user.attributes.permissions, PlanPermissionsSysNames.OrganizationAccess);
    const userIsSupervisor = lodash.includes(user.attributes.permissions, PlanPermissionsSysNames.DepartmentAccess);

    return {
        budgetId,
        columnsWidth,
        fixedColumnsNames,
        columnsVisiblityFilter,
        allUsers,
        activitiesTotalBudgets: getActivitiesTotalBudgets(state),
        unsavedChanges: getUnsavedChanges(state),
        lineStatusChanges,
        userIsBudgetExpert,
        userIsSupervisor,
        lines: getTableLines(state),
        dictionariesOrganizationId: selectedBudget ? selectedBudget.dictionaryOrganizationId : null,
        userCanEditOrCreateBudgetData: userCanEditOrCreateBudgetData(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return bindActionCreators(
        {
            changeCellValue,
            setLineStatus,
            setRejectMenuLineId,
            deleteLineStatus,
            loadByActivityId,
        },
        dispatch,
    );
}
