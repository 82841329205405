// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-dashboard-LeftSidebar-_LeftSidebar__root{padding-top:41px;font-family:'Open Sans'}.src-client-modules-dashboard-LeftSidebar-_LeftSidebar__filterStub{padding:12px 16px 16px 16px;border-radius:8px;background:#ffffff;box-shadow:0px 1px 4px rgba(0,0,0,0.1)}.src-client-modules-dashboard-LeftSidebar-_LeftSidebar__filterStubTitle{display:flex;letter-spacing:0.15px;color:#000000;font-size:14px;font-weight:600;line-height:19px;align-items:center;justify-content:space-between}.src-client-modules-dashboard-LeftSidebar-_LeftSidebar__filterStubSubtitle{padding-top:5px;letter-spacing:0.15px;color:#7e8681;font-size:12px;line-height:16px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/dashboard/LeftSidebar/LeftSidebar.scss"],"names":[],"mappings":"AAAA,6DACI,gBAAiB,CAEjB,uBAAwB,CAC3B,mEAGG,2BAA4B,CAE5B,iBAAkB,CAClB,kBAAmB,CACnB,sCAA0C,CAC7C,wEAGG,YAAa,CAEb,qBAAsB,CAEtB,aAAc,CAEd,cAAe,CACf,eAAgB,CAChB,gBAAiB,CAEjB,kBAAmB,CACnB,6BAA8B,CACjC,2EAGG,eAAgB,CAEhB,qBAAsB,CAEtB,aAAc,CAEd,cAAe,CACf,gBAAiB","sourcesContent":[".root {\n    padding-top: 41px;\n\n    font-family: 'Open Sans';\n}\n\n.filterStub {\n    padding: 12px 16px 16px 16px;\n\n    border-radius: 8px;\n    background: #ffffff;\n    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);\n}\n\n.filterStubTitle {\n    display: flex;\n\n    letter-spacing: 0.15px;\n\n    color: #000000;\n\n    font-size: 14px;\n    font-weight: 600;\n    line-height: 19px;\n\n    align-items: center;\n    justify-content: space-between;\n}\n\n.filterStubSubtitle {\n    padding-top: 5px;\n\n    letter-spacing: 0.15px;\n\n    color: #7e8681;\n\n    font-size: 12px;\n    line-height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-dashboard-LeftSidebar-_LeftSidebar__root",
	"filterStub": "src-client-modules-dashboard-LeftSidebar-_LeftSidebar__filterStub",
	"filterStubTitle": "src-client-modules-dashboard-LeftSidebar-_LeftSidebar__filterStubTitle",
	"filterStubSubtitle": "src-client-modules-dashboard-LeftSidebar-_LeftSidebar__filterStubSubtitle"
};
export default ___CSS_LOADER_EXPORT___;
