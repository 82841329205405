import * as lodash from 'lodash';
import * as moment from 'moment';

import type { Activity } from '@mrm/activity';
import type { PlainDictionary } from '@mrm/dictionary';
import type { OrganizationView as Organization, TaskAttributes as Task } from 'sber-marketing-types/frontend';
import { TaskStatus } from 'sber-marketing-types/frontend';
import type {
    OrganizationGroups,
    LinesGroup,
    LineParams,
    GroupedActivities,
    GroupedTasks,
    GroupedBlocks,
    ActivityTypeColor,
    Stage,
} from '@store/calendar/types';
import { LineType } from '@store/calendar/types';

import { store } from '@store';
import {
    getPageData,
    getCalendarGroups,
    getCalendarGroupsGroupedByOrganization,
    getActivitiesGroupedByCalendarGroup,
    getActivityTypeColors,
    getExpiredTasks,
    getExpiredActivityStages,
} from '@store/calendar/selectors';
import { TimeCalculator } from '.';

interface StoreProps {
    organizations: Organization[];
    blocks: PlainDictionary[];
    groupedBlocks: GroupedBlocks;
    groupedActivities: GroupedActivities;
    accessibleActivitiesIds: number[];
    groupedTasks: GroupedTasks;
    expiredTasks: Task[];
    expiredStages: Stage[];
    activityTypeColors: { [typeId: string]: ActivityTypeColor };
}

export class GroupsMaker {
    private static instance: GroupsMaker;
    private timeCalculator: TimeCalculator;
    private groups: OrganizationGroups[];

    private constructor() {
        this.timeCalculator = TimeCalculator.getInstance();
    }

    public static getInstance(): GroupsMaker {
        if (!GroupsMaker.instance) {
            GroupsMaker.instance = new GroupsMaker();
        }
        return GroupsMaker.instance;
    }

    public dispose() {
        GroupsMaker.instance = null;

        this.timeCalculator = null;

        this.groups = [];
    }

    public getGroups(): OrganizationGroups[] {
        if (!this.groups) {
            this.groups = this.makeGroups();
        }

        return this.groups;
    }

    private makeGroups(): OrganizationGroups[] {
        const { organizations, groupedBlocks, groupedActivities } = this.getStoreProps();

        return organizations.map((organization) => {
            const groups: LinesGroup[] = [];

            const organizationBlocks = groupedBlocks[organization.id] || [];

            organizationBlocks.forEach((block) => {
                if (groupedActivities[block.id]) {
                    const lines = this.makeGroupLines(block.id);

                    const activityLines = lines.filter((line) => line.type == LineType.Activity);

                    groups.push({
                        id: block.id,
                        lines,
                        activitiesCount: activityLines.length,
                        isExpanded: false,
                    });
                }
            });

            return {
                id: organization.id,
                name: organization.name,
                groups,
            };
        });
    }

    private makeGroupLines(blockId: string): LineParams[] {
        const { groupedActivities } = this.getStoreProps();

        const block = this.getBlockById(blockId);

        const activities = groupedActivities[blockId];

        const lines: LineParams[] = [
            {
                id: blockId,
                type: LineType.GroupTitle,
                title: block.value,
            },
        ];

        activities.forEach((activity) => {
            lines.push(...this.makeActivityLines(activity));
        });

        return lines;
    }

    private makeActivityLines(activity: Activity): LineParams[] {
        const { accessibleActivitiesIds, groupedTasks, expiredTasks, expiredStages, activityTypeColors } =
            this.getStoreProps();

        const activityTasks = groupedTasks[activity.id] || [];

        const activityIsActive = lodash.includes(accessibleActivitiesIds, activity.id);

        const lines: LineParams[] = [
            {
                type: LineType.Activity,
                isExpanded: false,
                isActive: activityIsActive,
                hasTasks: !lodash.isEmpty(activityTasks),
                hasExpiredStages: expiredStages.some((item) => item.activityId == activity.id),
                title: activity.name,
                id: activity.id,
                preparationStart: this.getDayByDate(activity.preparationDate),
                realizationStart: this.getDayByDate(activity.realizationStart),
                realizationEnd: this.getDayByDate(activity.realizationEnd),
                debriefingEnd: this.getDayByDate(activity.debriefingDate),
                isOneDayActivity: moment(activity.realizationEnd).diff(moment(activity.realizationStart), 'days') <= 1,
                color: activityTypeColors[activity.typeId],
            },
        ];

        activityTasks.forEach((task) => {
            lines.push({
                type: LineType.Task,
                title: task.title,
                id: task.id,
                activityId: activity.id,
                activityIsActive,
                deadline: this.getDayByDate(task.deadline),
                deadlineExpired: lodash.includes(expiredTasks, task),
                taskIsClosed: task.status == TaskStatus.Closed,
                color: activityTypeColors[activity.typeId],
            });
        });

        return lines;
    }

    private getDayByDate(date: string | Date): number {
        return date ? this.timeCalculator.getDayIndexByDate(date as string) : null;
    }

    private getBlockById(id: string): PlainDictionary {
        const { blocks } = this.getStoreProps();

        return blocks.find((item) => item.id == id);
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        const { organizations, accessibleActivitiesIds, tasks } = getPageData(storeState);

        return {
            organizations,
            blocks: getCalendarGroups(storeState),
            groupedBlocks: getCalendarGroupsGroupedByOrganization(storeState),
            groupedActivities: getActivitiesGroupedByCalendarGroup(storeState),
            accessibleActivitiesIds,
            groupedTasks: tasks,
            expiredTasks: getExpiredTasks(storeState),
            expiredStages: getExpiredActivityStages(storeState),
            activityTypeColors: getActivityTypeColors(storeState),
        };
    }
}
