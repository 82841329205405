import { actionCreatorFactory } from 'typescript-fsa';

const actionCreator = actionCreatorFactory('TRANSFER_BUDGET_ITEMS_TO_PLANNING_MENU_ASYNC').async;

const loadComponentData = actionCreator<null, void, Error>('LOAD_COMPONENT_DATA');
const initActivityTransfer = actionCreator<string, void, Error>('INIT_ACTIVITY_TRANSFER');
const initSingleBudgetItemTransfer = actionCreator<string, void, Error>('INIT_SINGLE_BUDGET_ITEM_TRANSFER');
const toggleBudgetItemForTransfer = actionCreator<string, void, Error>('TOGGLE_SINGLE_BUDGET_ITEM_FOR_TRANSFER');

export { loadComponentData, initActivityTransfer, initSingleBudgetItemTransfer, toggleBudgetItemForTransfer };
