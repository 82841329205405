import * as React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { HeaderView } from '../Page';

import { RouteContainer } from './RouteContainer';
import { locations } from './locations';
import { NotFound } from '../../notFound';
import { AuthErrorPage } from '../AuthErrorPage';

interface Props {
    firstLine: JSX.Element;
    additionalContent: JSX.Element;
    setHeaderView: (view: HeaderView) => void;
    withoutNavMenu: boolean;
}

export function PageRouter({ firstLine, additionalContent, setHeaderView, withoutNavMenu }: Props): JSX.Element {
    return (
        <BrowserRouter>
            <Switch>
                <Redirect exact from="/" to="/dashboard" />

                <Route exact path="/auth-error" component={AuthErrorPage} />

                {locations.map((location) => (
                    <RouteContainer
                        exact
                        path={location.url}
                        key={location.url}
                        component={location.Component}
                        setHeaderView={setHeaderView}
                        firstLine={firstLine}
                        additionalContent={additionalContent}
                        withoutNavMenu={withoutNavMenu}
                        hasAccess={location.hasAccess}
                    />
                ))}

                <Route component={NotFound} />
            </Switch>
        </BrowserRouter>
    );
}
