import * as React from 'react';

import * as styles from './FilterTitle.scss';

interface Props {
    title: string;
}

export function FilterTitle({ title }: Props): JSX.Element {
    return <div className={styles.root}>{title}</div>;
}
