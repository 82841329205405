import gql from 'graphql-tag';

export const GET_CARDS_QUERY = gql`
    query Cards($cardId: [String]) {
        cards: getProjectLinkBudgetItemApprovalRequests(filter: { id: $cardId }) {
            nodes {
                id
                budgetItem {
                    id
                    budget {
                        id
                    }
                    activity {
                        name
                    }
                    serialNumber
                    sapComment
                    plannedFunds {
                        jan
                        feb
                        mar
                        apr
                        may
                        jun
                        jul
                        aug
                        sept
                        oct
                        nov
                        dec
                    }
                    reservedFunds {
                        jan
                        feb
                        mar
                        apr
                        may
                        jun
                        jul
                        aug
                        sept
                        oct
                        nov
                        dec
                    }
                    factFunds {
                        jan
                        feb
                        mar
                        apr
                        may
                        jun
                        jul
                        aug
                        sept
                        oct
                        nov
                        dec
                    }
                    dictionary {
                        block {
                            value
                            status
                        }
                        tool {
                            value
                            status
                        }
                        product {
                            value
                            status
                        }
                    }
                }
            }
        }
    }
`;
