import * as React from 'react';
import * as lodash from 'lodash';
import classNames from 'classnames';

import * as style from './TableBody.scss';

import {
    TableLine,
    TableLineGroup,
    TotalBudgets,
    ColumnsVisiblityFilter,
    ColumnName,
    ColumnsWidth,
} from '@store/budgetExecution';
import { CellPosition, InternalTransferDirection } from '@store/budgetExecution/budgetTransferMenu';

import { Line, LINE_MENU_WIDTH } from '../Line';
import { TotalLine } from '../TotalLine';
import { ColumnsList } from '../../ColumnsConfig';
import { CellParams } from '../LayerManager';
import { FrameManager, PlainFrameManager, GroupedFrameManager } from '../FrameManager';

const SUMLINE_HEIGHT = 57;

export enum LineColor {
    White = '255, 255, 255, 1.0',
    Grey = '248, 248, 248, 1.0',
    Red = '254, 221, 221, 1.0',
    Black = '0, 0, 0, 0.15',
}

export const LineMenuColor: Record<LineColor, string> = {
    [LineColor.White]: '255, 255, 255',
    [LineColor.Grey]: '248, 248, 248',
    [LineColor.Red]: '254,221,221',
    [LineColor.Black]: '204, 208, 211',
};

// used in Line['qa-line-color']
export const LineColorName: Record<LineColor, string> = {
    [LineColor.White]: 'White',
    [LineColor.Grey]: 'Grey',
    [LineColor.Red]: 'Red',
    [LineColor.Black]: 'Black',
};

interface Props {
    shouldColorSelectedLinesForTransition: boolean;
    shouldColorSelectedLinesForLineTransferToPlanning: boolean;
    internalTransferDirection: InternalTransferDirection;
    donorCell: CellPosition[];
    acceptorCell: CellPosition[];
    frameManager: FrameManager;
    currentFrameIndex: number;
    fixedColumnsNames: ColumnName[];
    activitiesTotalBudgets: TotalBudgets;
    columnsVisiblityFilter: ColumnsVisiblityFilter;
    rootWidth: number;
    columnsWidth: ColumnsWidth;
    visibleColumnsNames: ColumnName[];
    isBudgetTransferMenuClosed: boolean;
    userCanEditOrCreateBudgetData: boolean;
    linesForTransferToPlanning: string[];
    lineForTransferBudgetItemsToPlan: string;
    currentYScroll: number;
    fixedColumnsLinesRef: (element: HTMLDivElement) => void;
    totalLinesContentRef: (element: HTMLDivElement) => void;
    onLineMouseEnter: (budgetId: string) => void;
    onLineMouseLeave: () => void;
    onCellClick: (lineId: string, columnName: ColumnName) => void;
    onCellMouseEnter: (budgetId: string, columnName: ColumnName) => void;
    onCellMouseLeave: () => void;
    onEditActivityButtonClick: (planId: string) => void;
    onCopyActivityButtonClick: (planId: string) => void;
    onInfoMouseEnter: (lineId: string) => void;
    onInfoMouseLeave: () => void;
    onInputValueChange: (
        budgetItemId: string,
        columnName: ColumnName,
        value: string,
        originalValue: string,
        generateSameValueChange: boolean,
    ) => void;
    onDropdownCellClick: (
        columnName: ColumnName,
        budgetItemId: string,
        dropdownContent: JSX.Element,
        contentHeight: number,
    ) => void;
    onCellCopy: (
        lineId: string,
        columnName: ColumnName,
        cellParams: CellParams,
        event: React.ClipboardEvent<HTMLDivElement>,
    ) => void;
    onDropdownItemSelection: (lineId: string, columnName: ColumnName, selectedId: string, originalId: string) => void;
    onDatepickerValueChange: (lineId: string, columnName: ColumnName, value: Date, originalValue: Date) => void;
    onTransferBudgetItemsToPlanningButtonClick: (activityId: string) => void;
}

export const TableBody = ({
    shouldColorSelectedLinesForTransition,
    shouldColorSelectedLinesForLineTransferToPlanning,
    linesForTransferToPlanning,
    internalTransferDirection,
    donorCell,
    acceptorCell,
    frameManager,
    currentFrameIndex,
    fixedColumnsNames,
    activitiesTotalBudgets,
    columnsVisiblityFilter,
    rootWidth,
    columnsWidth,
    visibleColumnsNames,
    isBudgetTransferMenuClosed,
    lineForTransferBudgetItemsToPlan,
    userCanEditOrCreateBudgetData,
    currentYScroll,
    fixedColumnsLinesRef,
    totalLinesContentRef,
    onLineMouseEnter,
    onLineMouseLeave,
    onCellClick,
    onCellMouseEnter,
    onCellMouseLeave,
    onEditActivityButtonClick,
    onCopyActivityButtonClick,
    onInfoMouseEnter,
    onInfoMouseLeave,
    onInputValueChange,
    onDropdownCellClick,
    onDropdownItemSelection,
    onDatepickerValueChange,
    onCellCopy,
    onTransferBudgetItemsToPlanningButtonClick,
}: Props): JSX.Element => {
    const allColumnsAreHidden = lodash.every(columnsVisiblityFilter, (item) => item === false);

    // const filteredColumns = filterColumns(allColumnsAreHidden, columnsVisiblityFilter);
    const filteredColumns = React.useMemo(
        () => (allColumnsAreHidden ? ColumnsList : ColumnsList.filter((column) => columnsVisiblityFilter[column.name])),
        [
            Object.keys(columnsVisiblityFilter)
                .filter((key) => columnsVisiblityFilter[key])
                .join(', '),
        ],
    );

    const tableHeight = frameManager ? frameManager.getTableHeight() : null;
    const frameTop = frameManager && frameManager.getFrameByIndex(currentFrameIndex).position;
    const frameHeight = frameManager && frameManager.getFrameByIndex(currentFrameIndex).getHeight();

    const lineWidth = lodash.sumBy(filteredColumns, (item) => columnsWidth[item.name]) + LINE_MENU_WIDTH;

    return (
        <div
            className={style.root}
            style={{
                height: tableHeight + SUMLINE_HEIGHT,
                width: lineWidth,
            }}
        >
            <div
                className={style.frame}
                style={{
                    top: frameTop,
                    height: frameHeight,
                }}
            >
                {frameManager instanceof PlainFrameManager &&
                    frameManager.getFrameByIndex(currentFrameIndex).lines.map((line, index) => {
                        const colorAsDonor =
                            internalTransferDirection === InternalTransferDirection.ManyToOne &&
                            donorCell.some((cell) => cell.lineId === line.id);
                        const colorAsAcceptor =
                            internalTransferDirection === InternalTransferDirection.OneToMany &&
                            acceptorCell.some((cell) => cell.lineId === line.id);

                        const useBlackColor =
                            (shouldColorSelectedLinesForTransition && (colorAsDonor || colorAsAcceptor)) ||
                            (shouldColorSelectedLinesForLineTransferToPlanning &&
                                linesForTransferToPlanning.includes(line.id));

                        let lineColor: LineColor;
                        if (useBlackColor) {
                            lineColor = LineColor.Black;
                        } else if (!line.planValue) {
                            lineColor = LineColor.Red;
                        } else if ((frameManager.getFrameByIndex(currentFrameIndex).firstLineIndex + index) % 2 == 0) {
                            lineColor = LineColor.White;
                        } else {
                            lineColor = LineColor.Grey;
                        }

                        return renderLine(line, lineColor);
                    })}

                {frameManager instanceof GroupedFrameManager &&
                    frameManager.getFrameByIndex(currentFrameIndex).groups.map((group) => renderLineGroup(group))}
            </div>
        </div>
    );

    function renderLineGroup(group: TableLineGroup) {
        const { activityId, lines } = group;

        const activityTotalBudget = activitiesTotalBudgets[activityId];

        const activityName = lodash.first(lines).fields[ColumnName.ActivityName] as string;

        return (
            <React.Fragment key={activityId}>
                {lines.map((line) => {
                    const colorAsDonor =
                        internalTransferDirection === InternalTransferDirection.ManyToOne &&
                        donorCell.some((cell) => cell.lineId === line.id);
                    const colorAsAcceptor =
                        internalTransferDirection === InternalTransferDirection.OneToMany &&
                        acceptorCell.some((cell) => cell.lineId === line.id);

                    const useBlackColor =
                        (shouldColorSelectedLinesForTransition && (colorAsDonor || colorAsAcceptor)) ||
                        (shouldColorSelectedLinesForLineTransferToPlanning &&
                            linesForTransferToPlanning.includes(line.id));

                    let lineColor: LineColor;
                    if (useBlackColor) {
                        lineColor = LineColor.Black;
                    } else if (!line.planValue) {
                        lineColor = LineColor.Red;
                    } else {
                        lineColor = LineColor.White;
                    }

                    return renderLine(line, lineColor);
                })}

                {renderTotalLine(activityId, activityName, activityTotalBudget, LineColor.White, !group.isDisabled)}
            </React.Fragment>
        );
    }

    function renderLine(line: TableLine, lineColor: LineColor) {
        return (
            <div className={style.line} key={line.id}>
                <Line
                    line={line}
                    columns={filteredColumns}
                    visibleColumnsNames={visibleColumnsNames}
                    fixedColumnsNames={fixedColumnsNames}
                    columnsWidth={columnsWidth}
                    width={rootWidth}
                    lineColor={lineColor}
                    isTransitionMenuOpened={isBudgetTransferMenuClosed}
                    currentYScroll={currentYScroll}
                    fixedColumnsLinesRef={fixedColumnsLinesRef}
                    onMouseEnter={onLineMouseEnter}
                    onMouseLeave={onLineMouseLeave}
                    onCellClick={onCellClick}
                    onCellMouseEnter={onCellMouseEnter}
                    onCellMouseLeave={onCellMouseLeave}
                    onDropdownCellClick={onDropdownCellClick}
                    onDropdownItemSelection={onDropdownItemSelection}
                    onInputValueChange={onInputValueChange}
                    onDatepickerCellClick={onDropdownCellClick}
                    onDatepickerValueChange={onDatepickerValueChange}
                    onInfoMouseEnter={onInfoMouseEnter}
                    onInfoMouseLeave={onInfoMouseLeave}
                    onCellCopy={onCellCopy}
                />
            </div>
        );
    }

    function renderTotalLine(
        activityId: string,
        activityName: string,
        totalBudget: number,
        lineColor: LineColor,
        canCreateNewLines: boolean,
    ) {
        return (
            <div className={classNames(style.line, style.totalLine)}>
                <TotalLine
                    width={rootWidth}
                    value={totalBudget}
                    color={lineColor}
                    contentRef={totalLinesContentRef}
                    activityName={activityName}
                    onEditActivityButtonClick={() => onEditActivityButtonClick(activityId)}
                    onCopyActivityButtonClick={() => onCopyActivityButtonClick(activityId)}
                    canCreateNewLines={canCreateNewLines}
                    showTransferLinesToPlanningButton={!isBudgetTransferMenuClosed && userCanEditOrCreateBudgetData}
                    isLineSelectedForTransferToPlan={lineForTransferBudgetItemsToPlan === activityId}
                    onTransferBudgetItemsToPlanningButtonClick={() =>
                        onTransferBudgetItemsToPlanningButtonClick(activityId)
                    }
                    userCanEditOrCreateBudgetData={userCanEditOrCreateBudgetData}
                />
            </div>
        );
    }
};
