import { getImageFile } from '@modules/files/utils';
import { FileData } from '@modules/files/types';

export interface GetFileOriginUrlData extends Pick<FileData, 'id' | 'type' | 'storage' | 'parent' | 'containerName'> {
    taskId: string;
}

export function getTaskFileOriginUrl({ id, type, storage, taskId, parent, containerName }: GetFileOriginUrlData) {
    const imageUrl = getImageFile({ id, type, storage });

    if (imageUrl) {
        return imageUrl;
    }

    return parent === 'comment'
        ? `/api/file/task/${taskId}/comment/${containerName}/file/${id}`
        : `/api/file/task/${taskId}/file/${id}`;
}
