import * as React from 'react';
import classNames from 'classnames';

import * as style from './LabeledInput.scss';

import { Input_redesign as Input, InputTheme_redesign as InputTheme } from 'sber-marketing-ui';

interface Props {
    qaId?: string;
    title: string;
    value?: string;
    errorMessage?: string;
    displayError?: boolean;
    isFocused: boolean;
    onlyNumbers?: boolean;
    disabled?: boolean;
    disableEllipsis?: boolean;
    maxLength?: number;
    onInputFocus: () => void;
    onInputBlur: () => void;
    onInputChange: (value: string) => void;
    inputComponentRef: React.RefObject<Input>;
    textWrapperRef?: (element: HTMLDivElement) => void;
}

export function LabeledInput({
    qaId,
    title,
    value,
    errorMessage,
    displayError,
    isFocused,
    onlyNumbers,
    disabled,
    disableEllipsis,
    maxLength,
    onInputFocus,
    onInputBlur,
    onInputChange,
    inputComponentRef,
    textWrapperRef,
}: Props): JSX.Element {
    const hasValue = !!value;
    const showTitle = isFocused || hasValue;

    return (
        <div
            className={classNames(
                style.root,
                errorMessage !== undefined && style.hasErrorMessage,
                isFocused && style.focused,
                disabled && style.disabled,
            )}
        >
            <label className={style.label}>
                {showTitle && <div className={style.title}>{title}</div>}

                <div
                    className={classNames(style.inputWrapper, displayError && style.inputWrapper_error)}
                    ref={textWrapperRef}
                >
                    <Input
                        qaId={qaId}
                        value={(value as string) || ''}
                        placeholder={showTitle ? '' : title}
                        theme={InputTheme.BriefPage}
                        valueType={onlyNumbers && 'float'}
                        disabled={disabled}
                        disableEllipsis={disableEllipsis}
                        maxLength={maxLength}
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                        onInputChange={onInputChange}
                        ref={inputComponentRef}
                    />
                </div>

                {displayError && errorMessage && <div className={style.errorMessage}>{errorMessage}</div>}
            </label>
        </div>
    );
}
