import * as React from 'react';

export interface SpaceProps extends React.HTMLProps<HTMLDivElement> {
    flex?: number;
    gap?: number | [number, number];
}

export function Space({ children, flex = 1, gap, style, ...props }: SpaceProps) {
    const normGap = gap && (Array.isArray(gap) ? gap : [gap, gap]);

    return (
        <div
            {...props}
            style={{
                flex,
                width: normGap && `${normGap[0]}px`,
                height: normGap && `${normGap[1]}px`,
                minWidth: normGap && `${normGap[0]}px`,
                minHeight: normGap && `${normGap[1]}px`,
                ...style,
            }}
        >
            {children}
        </div>
    );
}
