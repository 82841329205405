import * as React from 'react';
import classNames from 'classnames';

import * as style from './ColumnFixButton.scss';

import { Icon, IconType } from 'sber-marketing-ui';

interface Props {
    isColumnFixed: boolean;
    onClick: () => void;
}

export const ColumnFixButton = ({ isColumnFixed, onClick }: Props): JSX.Element => {
    return (
        <div className={classNames(style.root, isColumnFixed && style.active)} onClick={onClick}>
            <Icon type={IconType.PIN} svgSize={16} className={style.icon} />

            {isColumnFixed ? 'Колонка закреплена' : 'Закрепить колонку'}
        </div>
    );
};
