// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-PersonNotFound-_PersonNotFound__root{overflow-x:hidden;width:100%;height:20px;white-space:nowrap;text-overflow:ellipsis;color:#262626;font-size:11px;line-height:1.82}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/PersonNotFound/PersonNotFound.scss"],"names":[],"mappings":"AAAA,gEACI,iBAAkB,CAElB,UAAW,CACX,WAAY,CAEZ,kBAAmB,CACnB,sBAAuB,CAEvB,aAAc,CAEd,cAAe,CACf,gBAAiB","sourcesContent":[".root {\n    overflow-x: hidden;\n\n    width: 100%;\n    height: 20px;\n\n    white-space: nowrap;\n    text-overflow: ellipsis;\n\n    color: #262626;\n\n    font-size: 11px;\n    line-height: 1.82;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-PersonNotFound-_PersonNotFound__root"
};
export default ___CSS_LOADER_EXPORT___;
