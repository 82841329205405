// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-popup-Modals-_Modals__root{position:fixed;inset:0;background:#0003;z-index:99999;transition:opacity .3s;font-family:\"Open Sans\", sans-serif;font-size:14px}.src-client-modules-common-components-popup-Modals-_Modals__root:empty{opacity:0;pointer-events:none}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/popup/Modals/Modals.scss"],"names":[],"mappings":"AAAA,iEACE,cAAe,CACf,OAAQ,CACR,gBAAiB,CACjB,aAAc,CACd,sBAAuB,CACvB,mCAAoC,CACpC,cAAe,CAPjB,uEAUI,SAAU,CACV,mBAAoB","sourcesContent":[".root {\n  position: fixed;\n  inset: 0;\n  background: #0003;\n  z-index: 99999;\n  transition: opacity .3s;\n  font-family: \"Open Sans\", sans-serif;\n  font-size: 14px;\n\n  &:empty {\n    opacity: 0;\n    pointer-events: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-components-popup-Modals-_Modals__root"
};
export default ___CSS_LOADER_EXPORT___;
