import { axios } from '../lib/axios';
import { UserVacation, GetUserVacationListParams } from '@mrm/user-vacation';

const BASE_URL = `/api/user-vacation`;

export class UserVacationApi {
    public static async addUserVacation(params: UserVacation): Promise<UserVacation> {
        return (await axios.post<UserVacation>(`${BASE_URL}/`, params)).data;
    }

    public static async getUserVacation(userId: number): Promise<UserVacation> {
        return (await axios.get<UserVacation>(`${BASE_URL}/${userId}`)).data;
    }

    public static async listUserVacations(params: GetUserVacationListParams): Promise<UserVacation[]> {
        return (await axios.post<UserVacation[]>(`${BASE_URL}/list`, params)).data;
    }

    public static async deleteUserVacation(userId: number): Promise<void> {
        return (await axios.delete<void>(`${BASE_URL}/${userId}`)).data;
    }
}
