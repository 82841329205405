import { compact } from 'lodash';

import { MultiReferenceDictionaryApi } from '@api';

import { StoreState } from '@store';
import { getBudgetPlanningPageState } from '@store/budgetPlanning';

import { Columns, ColumnProps, ColumnNames } from '../../types';
import { UnvalidDictionaryValueError } from '../validationErrors/UnvalidDictionaryValueError';
import { FetchedData } from '../FetchedData';

export class DictionariesValidator {
    private dictionaryColumns: ColumnProps[];
    private multiReferenceDictionaryApi: MultiReferenceDictionaryApi;

    public constructor(state: StoreState) {
        this._init(state);
    }

    public validate(data: FetchedData[]): FetchedData[] {
        data.forEach((dataItem) => {
            const dataItemDictionaries = compact(
                this.dictionaryColumns.map((column) => dataItem.getFieldByName(column.key)),
            ) as string[];
            const validatedDictionaries = this.multiReferenceDictionaryApi.validateValue(
                this.multiReferenceDictionaryApi.makeDictionaryValue(dataItemDictionaries),
            );

            let unvalidColumns: ColumnNames[] = [];
            this.dictionaryColumns.forEach((dictionaryColumn) => {
                const columnValue = dataItem.getFieldByName(dictionaryColumn.key);

                if (columnValue && validatedDictionaries[dictionaryColumn.linkedDictionary]?.id !== columnValue) {
                    unvalidColumns.push(dictionaryColumn.key);
                }

                dataItem.setFieldByName(
                    dictionaryColumn.key,
                    validatedDictionaries[dictionaryColumn.linkedDictionary]?.id,
                );
            });

            if (unvalidColumns.length) {
                dataItem.addValidationError(
                    new UnvalidDictionaryValueError(unvalidColumns, this.dictionaryColumns[0].key),
                );
            }
        });

        return data;
    }

    private _init(state: StoreState) {
        const { multiReferenceDictionaryApi } = getBudgetPlanningPageState(state);

        this.multiReferenceDictionaryApi = multiReferenceDictionaryApi;
        this.dictionaryColumns = Columns.filter((column) => column.linkedDictionary);
    }
}
