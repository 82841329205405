import * as React from 'react';
import classNames from 'classnames';
import { BudgetItemStatus, BudgetItemApproverStatus } from '@mrm/budget';
import { UserResponseParams } from 'sber-marketing-types/frontend';

import * as style from './ApproversMenu.scss';

import { DynamicIcon, IconType, BodyPortal } from 'sber-marketing-ui';

const STATUS_TITLE = {
    [BudgetItemApproverStatus.Approved]: { color: '#19bb4f', text: 'Согласовано' },
    [BudgetItemApproverStatus.Rejected]: { color: '#ff4444', text: 'Отклонено' },
    [BudgetItemApproverStatus.Waiting]: { color: 'rgba(0, 0, 0, 0.24)', text: '–' },
};

export const enum Direction {
    Up = 'up',
    Down = 'down',
}

interface Props {
    approvers: { approver: UserResponseParams; status: BudgetItemApproverStatus }[];
    userId: number;
    direction: Direction;
    approverStatus: BudgetItemStatus;
    onApproveButtonClick: (approverId: number) => void;
    onRejectButtonClick: (approverId: number) => void;
    onMaskClick: () => void;
}

export const ApproversMenu = ({
    approvers,
    direction,
    userId,
    approverStatus,
    onApproveButtonClick,
    onRejectButtonClick,
    onMaskClick,
}: Props): JSX.Element => {
    return (
        <div
            className={classNames(
                style.root,
                direction == Direction.Up && style.directedUp,
                direction == Direction.Down && style.directedDown,
            )}
        >
            {approvers.map((item) => (
                <div className={style.approverLine} key={item.approver.id}>
                    <div className={style.approver}>
                        <div className={style.approverName}>
                            {item.approver.secondName}&nbsp;{item.approver.firstName}
                        </div>

                        <div className={style.approverDepartment}>{item.approver.departmentName}</div>
                    </div>

                    <div className={style.status}>
                        {userId == item.approver.id && item.status == BudgetItemApproverStatus.Waiting && (
                            <div className={style.buttons}>
                                <div
                                    title={'Принять'}
                                    className={style.button}
                                    onClick={() => onApproveButtonClick(item.approver.id)}
                                >
                                    <DynamicIcon
                                        common={{ svgSize: 24 }}
                                        normal={{ type: IconType.ACCEPT }}
                                        hover={{ type: IconType.ACCEPT_HOVER }}
                                        select={{ type: IconType.ACCEPT_ACTIVE }}
                                        isSelected={approverStatus == BudgetItemStatus.Approved}
                                    />
                                </div>

                                <div
                                    title={'Отклонить'}
                                    className={style.button}
                                    onClick={() => onRejectButtonClick(item.approver.id)}
                                >
                                    <DynamicIcon
                                        common={{ svgSize: 24 }}
                                        normal={{ type: IconType.REJECT }}
                                        hover={{ type: IconType.REJECT_HOVER }}
                                        select={{ type: IconType.REJECT_ACTIVE }}
                                        isSelected={approverStatus == BudgetItemStatus.Rejected}
                                    />
                                </div>
                            </div>
                        )}

                        {(userId != item.approver.id || item.status !== BudgetItemApproverStatus.Waiting) && (
                            <div className={style.statusTitle} style={{ color: STATUS_TITLE[item.status].color }}>
                                {STATUS_TITLE[item.status].text}
                            </div>
                        )}
                    </div>
                </div>
            ))}

            {approvers.length == 0 && <div className={style.errorMessage}>Нет согласующих</div>}

            <BodyPortal>
                <div className={style.mask} onClick={onMaskClick} />
            </BodyPortal>
        </div>
    );
};
