import * as React from 'react';
import { includes } from 'lodash';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';

import { StoreState } from '@store';
import { CreativeRequestDomain, getCreativeRequestDomain, CreativeRequestSubject } from '@store/creative';
import { getUserId } from '@store/user/selector';

interface Props extends ExternalProps, Partial<MapProps> {}

interface ExternalProps {
    children: (props: ChildrenProps) => JSX.Element;
}

export interface ChildrenProps {
    loading: boolean;
    permissions: Permissions;
}

interface MapProps {
    creativeRequestDomain: CreativeRequestDomain;
    userId: number;
}

interface State {
    loading: boolean;
    permissions: Permissions;
}

interface Permissions {
    canSetBudgetId: boolean;
}

@(connect(mapStateToProps) as any)
export class WithPermissions extends React.Component<Props, State> {
    private agencyExecutors: CreativeRequestSubject[] = [];

    constructor(props: Props) {
        super(props);

        this.state = {
            loading: true,
            permissions: {
                canSetBudgetId: false,
            },
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.init();
        this.setState({
            loading: false,
            permissions: await this.getPermissions(),
        });
    }

    public render(): JSX.Element {
        return this.props.children(this.getChildrenProps());
    }

    private getChildrenProps(): ChildrenProps {
        const { loading, permissions } = this.state;
        return {
            loading,
            permissions,
        };
    }

    private async init(): Promise<void> {
        await this.load();
        await this.subscribe();
    }

    private async load(): Promise<void> {
        await this.loadAgencyExecutor();
    }

    private async loadAgencyExecutor(): Promise<void> {
        this.agencyExecutors = await this.requestAgencyExecutors();
    }

    private async requestAgencyExecutors(): Promise<CreativeRequestSubject[]> {
        const { creativeRequestDomain } = this.props;

        try {
            return (await creativeRequestDomain.model.getAgencyExecutors()) || [];
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    private async subscribe(): Promise<void> {
        this.subscribeOnCreativeRequestDomainChange();
    }

    private subscribeOnCreativeRequestDomainChange(): void {
        const { creativeRequestDomain } = this.props;
        creativeRequestDomain.events.onAgencyExecutorsUpdated(this.onAgencyExecutorUpdatedHandler);
    }

    @autobind
    private async onAgencyExecutorUpdatedHandler(updatedAgencyExecutor: CreativeRequestSubject[]): Promise<void> {
        this.agencyExecutors = updatedAgencyExecutor;
        this.setState({ permissions: await this.getPermissions() });
    }

    private async getPermissions(): Promise<Permissions> {
        const isUserAgencyExecutor = includes(
            this.agencyExecutors.map(({ id }) => id),
            this.props?.userId,
        );

        return {
            canSetBudgetId: !isUserAgencyExecutor,
        };
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        creativeRequestDomain: getCreativeRequestDomain(state),
        userId: getUserId(state),
    };
}

export type { Permissions };
