import * as React from 'react';

import { TaskStatus as ApiTaskStatus } from 'sber-marketing-types/frontend';

import { useGetTaskQuery } from '@api';

import { TaskAccessAction, useChangeTaskStatus, useTaskAccess } from '@modules/task/hooks';

import { OptionProps, OptionsTag, Tag, TagProps } from '@common/components';

export interface TaskStatusProps extends Omit<TagProps, 'onChange'> {
    taskId: string;
    activityId?: number;
    className?: string;
    statuses?: Record<ApiTaskStatus, string>;
    onAskRequired?: () => void;
    onSubmit?: () => void;
}

const defaultStatuses: Record<ApiTaskStatus, string> = {
    [ApiTaskStatus.Draft]: 'Черновик',
    [ApiTaskStatus.InProgress]: 'В работе',
    [ApiTaskStatus.Closed]: 'Закрыта',
};

const getOption = (value: ApiTaskStatus) => ({ value, title: defaultStatuses[value] });

const statusOptions: Record<ApiTaskStatus, OptionProps<string>[]> = {
    [ApiTaskStatus.Draft]: [getOption(ApiTaskStatus.InProgress)],
    [ApiTaskStatus.InProgress]: [getOption(ApiTaskStatus.Closed)],
    [ApiTaskStatus.Closed]: [getOption(ApiTaskStatus.InProgress)],
};

export const TaskStatus: React.FC<TaskStatusProps> = ({
    taskId,
    activityId,
    children,
    statuses = defaultStatuses,
    loading,
    onAskRequired,
    onSubmit,
    ...props
}) => {
    const { isLoading: isTaskLoading, data: task } = useGetTaskQuery({ id: taskId });
    const { setStatus, isLoading: isUpdating } = useChangeTaskStatus(taskId, onSubmit, onAskRequired);
    const {
        isLoading: isAccessLoading,
        access: [canChange],
    } = useTaskAccess([TaskAccessAction.changeStatus], taskId, activityId);
    const isLoading = isTaskLoading || isUpdating || isAccessLoading || loading;
    const type =
        task?.task.status === ApiTaskStatus.InProgress
            ? 'accent'
            : !task || task?.task.status === ApiTaskStatus.Draft
            ? 'soft'
            : 'default';

    if (!canChange) {
        return (
            <Tag type={type} {...props} loading={isLoading}>
                {statuses[task?.task.status]}
            </Tag>
        );
    }

    return (
        <OptionsTag
            data-qa-id="TaskStatus"
            value={defaultStatuses[task?.task.status]}
            options={statusOptions[task?.task.status] || []}
            type={type}
            {...props}
            onSelect={setStatus}
            selected={task?.task.status}
            dropdownProps={{ width: 100 }}
            loading={isLoading}
            tagContent={statuses[task?.task.status]}
        />
    );
};
