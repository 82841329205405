import { combineReducers } from 'redux';
import { stubFalse, stubTrue, stubArray } from 'lodash';
import { DepartmentAttributes } from 'sber-marketing-types/frontend';

import { resetableReducerWithInitialState, replaceAsyncSuccessReducer } from '../../common/utils';
import { resetStore } from '../../common/actions';
import { resetTaskEditor } from '../actions';
import { loadDepartments } from './actions';
import { AvailableDepartments } from './types';

const isLoading = resetableReducerWithInitialState<boolean>(
    stubFalse,
    resetStore,
    resetTaskEditor,
    loadDepartments.done,
    loadDepartments.failed,
).case(loadDepartments.started, stubTrue);

const departments = resetableReducerWithInitialState<DepartmentAttributes[]>(
    stubArray,
    resetStore,
    resetTaskEditor,
    loadDepartments.failed,
).case(loadDepartments.done, replaceAsyncSuccessReducer);

export const availableDepartments = combineReducers<AvailableDepartments>({
    isLoading,
    departments,
});
