import * as React from 'react';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';

import { StoreState } from '@store';
import { CreativeRequestDomain, getCreativeRequestDomain } from '@store/creative';

export interface ChildrenProps {
    author: Author;
    sendToExecutor: () => Promise<void>;
    sendToCustomer: () => Promise<void>;
    sendToExpert: () => Promise<void>;
}

interface Author {
    firstName: string;
    middleName: string;
    secondName: string;
}

interface Props extends ExternalProps, Partial<MapProps> {}

interface MapProps {
    creativeRequestDomain: CreativeRequestDomain;
}

interface ExternalProps {
    children: (props: ChildrenProps) => JSX.Element;
}

interface State {
    author: Author;
}

@(connect(mapStateToProps) as any)
export class WithStagesListWidget extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            author: {
                firstName: '',
                middleName: '',
                secondName: '',
            },
        };
    }

    public async componentDidMount() {
        const { creativeRequestDomain } = this.props;

        const author = await creativeRequestDomain.model.getAuthor();

        this.setState({
            author: {
                firstName: author?.firstName || '',
                secondName: author?.secondName || '',
                middleName: author?.middleName || '',
            },
        });
    }

    public render(): JSX.Element {
        return this.props.children(this.getChildrenProps());
    }

    private getChildrenProps(): ChildrenProps {
        const { author } = this.state;

        return {
            author,
            sendToExecutor: this.sendToExecutor,
            sendToCustomer: this.sendToCustomer,
            sendToExpert: this.sendToExpert,
        };
    }

    @autobind
    private async sendToExecutor(): Promise<void> {
        const { creativeRequestDomain } = this.props;
        await creativeRequestDomain.model.sendToExecutor();
    }

    @autobind
    private async sendToCustomer(): Promise<void> {
        const { creativeRequestDomain } = this.props;
        await creativeRequestDomain.model.sendToCustomer();
    }

    @autobind
    private async sendToExpert(): Promise<void> {
        const { creativeRequestDomain } = this.props;
        await creativeRequestDomain.model.sendToExpert();
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        creativeRequestDomain: getCreativeRequestDomain(state),
    };
}

export { Author };
