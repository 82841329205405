import * as React from 'react';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';

import { StoreState } from '@store';
import { ComponentState, getBudgetTransferMenuState } from '@store/budgetExecution/budgetTransferMenu';

import { TransferAmountInput, Themes } from './TransferAmountInput';

interface Props extends Partial<MapProps> {
    qaIndex?: string;
    title: string;
    value: number;
    addAllAmountButton: boolean;
    maxValue?: number;
    maxButtonText?: string;
    maxButtonFullText?: string;
    maxButtonValue?: number;
    theme?: Themes;
    disabled?: boolean;
    onChange: (amount: number) => void;
}

interface MapProps {
    componentState: ComponentState;
}

interface State {
    inputValue: string;
}

@(connect(mapStateToProps) as any)
export class TransferAmountInputBehaviour extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            inputValue: this.getDefaultInputValue(),
        };
    }

    public componentDidUpdate(prevProps: Props): void {
        const defaultInputValue = this.getDefaultInputValue();

        if (this.props.componentState !== prevProps.componentState) {
            this.setState({
                inputValue: defaultInputValue,
            });
        }
    }

    public render(): JSX.Element {
        return (
            <TransferAmountInput
                {...this.props}
                {...this.state}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onAllAmountButtonClick={this.onAllAmountButtonClick}
            />
        );
    }

    @autobind
    private onChange(value: string): void {
        const { maxValue } = this.props;

        const validValue = this.validateValue(value, maxValue);

        this.props.onChange(parseFloat(validValue) * 100.0 || 0);

        this.setState({
            inputValue: validValue,
        });
    }

    @autobind
    private onFocus(): void {
        this.setState((state) => ({
            inputValue: state.inputValue === '0' ? '' : state.inputValue,
        }));
    }

    @autobind
    private onBlur(): void {
        this.setState((state) => ({
            inputValue: state.inputValue || '0',
        }));
    }

    private getDefaultInputValue(): string {
        return String(this.props.value / 100.0 || 0);
    }

    @autobind
    private onAllAmountButtonClick(): void {
        const { maxButtonValue } = this.props;

        const validMaxValue = this.validateValue(String(maxButtonValue), maxButtonValue);

        this.props.onChange(parseFloat(validMaxValue) * 100.0 || 0);

        this.setState({
            inputValue: validMaxValue,
        });
    }

    private numberValidator(value: string): string {
        const [intPart, fractionalPart] = value.split('.');
        return fractionalPart ? `${intPart}.${fractionalPart.slice(0, 2)}` : value;
    }

    private validateValue(value: string, maxValue: number): string {
        if (maxValue === 0) {
            return '0';
        }

        const numberValue = parseFloat(value) * 100.0 || 0;
        const valueToUse = maxValue ? Math.min(numberValue, maxValue) : numberValue;

        if (valueToUse === maxValue) {
            return this.numberValidator(String(maxValue / 100.0));
        }

        return this.numberValidator(value);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        componentState: getBudgetTransferMenuState(state).controls.componentState,
    };
}
