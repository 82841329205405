import { v4 as uuid } from 'uuid';
import { ActivityBudget } from '@mrm/budget';
import { FormField, FieldType } from 'sber-marketing-ui';

interface Props {
    activityBudget: ActivityBudget;
    onNameInputFocus: () => void;
    onNameInputBlur: () => void;
}

export function makeFormFields({ activityBudget, onNameInputFocus, onNameInputBlur }: Props): FormField[] {
    return (
        [
            {
                qaId: 'nameInput',
                name: 'name',
                type: FieldType.Input,
                title: 'Активность',
                value: activityBudget ? activityBudget.name : null,
                isRequired: true,
                onFocus: onNameInputFocus,
                onBlur: onNameInputBlur,
            },
        ] as FormField[]
    ).map((item) => ({
        ...item,
        id: uuid(),
        errorMessage: '',
        scrollId: item.name,
    }));
}
