import gql from 'graphql-tag';

export const GET_REQUESTS_QUERY = gql`
    query getRequests($limit: Int, $filter: ApprovalRequestFilter!) {
        requests: getProjectLinkBudgetItemApprovalRequests(limit: $limit, filter: $filter) {
            nodes {
                status
            }
        }
    }
`;

export const APPROVE_REQUEST_MUTATION = gql`
    mutation approveRequest($requestId: String!) {
        approveRequest(requestId: $requestId)
    }
`;

export const REJECT_REQUEST_MUTATION = gql`
    mutation rejectRequest($requestId: String!) {
        rejectRequest(requestId: $requestId)
    }
`;

export const REVERT_REQUEST_MUTATION = gql`
    mutation revertRequest($requestId: String!) {
        revertRequest(requestId: $requestId)
    }
`;
