import * as React from 'react';
import { graphql, DataProps } from 'react-apollo';
import * as lodash from 'lodash';

import { WITH_BUDGET_ITEM_LINKS_QUERY } from './queries';

export interface LinkProps {
    id: string;
    budgetItem: {
        serialNumber: number;
    };
    project: {
        id: number;
    };
    status: 'pending' | 'approved' | 'rejected';
}

export interface ChildrenProps {
    links: LinkProps[];
    loading: boolean;
}

interface Props extends ExternalProps, QueryProps {}

interface ExternalProps {
    linkIds: string[];
    children: (props: ChildrenProps) => JSX.Element;
}

type QueryProps = DataProps<{
    links: {
        nodes: LinkProps[];
    };
}>;

class LinkedBudgetItemsConnectedWrapper extends React.PureComponent<Props> {
    public render(): JSX.Element {
        return this.props.children(this.getChilrenProps());
    }

    private getChilrenProps(): ChildrenProps {
        const { data } = this.props;

        const links = lodash.get(data, 'links.nodes') || [];
        const loading = lodash.get(data, 'loading');

        return {
            links,
            loading,
        };
    }
}

export const LinkedBudgetItemsConnected = graphql<ExternalProps>(WITH_BUDGET_ITEM_LINKS_QUERY, {
    options: ({ linkIds }) => ({
        variables: {
            linkIds,
        },
        fetchPolicy: 'no-cache',
    }),
    skip: ({ linkIds }) => lodash.isEmpty(linkIds),
})(LinkedBudgetItemsConnectedWrapper);
