import { Success } from 'typescript-fsa';

import { LoadingStatus } from '@store/commonTypes';

import { BudgetTransferMenuState, ExpertsState as State } from '../types';

export class ExpertsReducer {
    public static makeInitialState(): State {
        return {
            loadingStatus: LoadingStatus.NOT_LOADED,
            entities: [],
            selectedExpert: null,
        };
    }

    public static mergeAfterAsyncDone(
        state: BudgetTransferMenuState,
        payload: Success<any, Partial<State>>,
    ): BudgetTransferMenuState {
        return ExpertsReducer.merge(state, payload.result);
    }

    public static merge(state: BudgetTransferMenuState, experts: Partial<State>): BudgetTransferMenuState {
        return { ...state, experts: { ...state.experts, ...experts } };
    }
}
