import * as React from 'react';
import { AutopilotPlacementPlanData } from 'sber-marketing-types/backend';

import * as style from './MediaplanStageWidget.scss';

import { Table } from './Table';

interface Props {
    mediaplan: AutopilotPlacementPlanData[];
    displayTable: boolean;
}

export const MediaplanStageWidgetTemplate = ({ mediaplan, displayTable }: Props): JSX.Element => {
    return (
        <div className={style.root}>
            {displayTable && (
                <>
                    <div className={style.title}>Медиаплан</div>

                    <div className={style.table}>
                        <Table mediaplan={mediaplan} />
                    </div>
                </>
            )}
        </div>
    );
};
