import * as React from 'react';
import * as styles from './style.scss';

import { Icon, IconType } from 'sber-marketing-ui';
import type { EstimateValue } from '../types';

interface Props {
    selectedEstimateValue: EstimateValue;
    onEstimateValueCancel: () => void;
}

export const SelectedEstimateTemplate: React.FC<Props> = ({ selectedEstimateValue, onEstimateValueCancel }) => {
    return (
        <div className={styles.root}>
            <div className={styles.estimateValue}>
                <Icon className={styles.starIcon} type={IconType.STAR_ACTIVE} svgSize={21} />
                <div className={styles.value}>{selectedEstimateValue}</div>
            </div>
            <div className={styles.button} onClick={onEstimateValueCancel}>
                Изменить оценку
            </div>
        </div>
    );
};
