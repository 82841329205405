import * as React from 'react';
import * as style from './styles.scss';

interface Props {
    params: {
        id: string;
        title: string;
        fields: { name: string }[];
    }[];
}

export const TooltipContent: React.FC<Props> = ({ params }) => {
    return (
        <div className={style.root}>
            <div className={style.title}>Заполните обязательные поля</div>
            <ul>
                {params.map((param, index) => (
                    <li className={style.paramRoot} key={param.id}>
                        <div>{param.title}</div>
                        <ul className={style.paramFields}>
                            {param.fields.map(({ name }) => (
                                <li key={name}>{name}</li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export { Props as TooltipContentProps };
