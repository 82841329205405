import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { AxiosError } from 'axios';

import { StoreState } from '../..';
import { FetchError } from '../types';
import { asyncActions, syncActions } from '../actions';
import { TaskApi } from '../../../api/TaskApi';
import { getTaskInfo } from '../selectors';

export const cancelTask = bindThunkAction<StoreState, null, void, FetchError>(
    asyncActions.cancelTask,
    async (payload, dispatch, getState): Promise<void> => {
        dispatch(syncActions.setRequestInProgress(true));

        const { id } = getTaskInfo(getState());
        try {
            await TaskApi.cancelTask(id);
        } catch (error) {
            const { response, message } = <AxiosError>error;
            if (response) {
                throw new FetchError(response.status, response.statusText, message);
            } else {
                throw new FetchError(0, 'Unknown', message);
            }
        }

        dispatch(syncActions.setRequestInProgress(false));
    },
);
