import { Color, StatisticsType } from './enums';
import * as styles from './styles.scss';
import { FilterPeriod as Period } from '@store/leaderDashboardTasksPage';

export const reportTypeColorName: Record<StatisticsType, Color> = {
    [StatisticsType.COMING_DEADLINE]: Color.MUSTARD,
    [StatisticsType.OVERDUE]: Color.RED,
    [StatisticsType.ACTIVE]: Color.GREEN,
    [StatisticsType.CLOSED]: Color.BLUE,
};

export const colorHash: Record<Color, string> = {
    [Color.RED]: '#EA9C9C',
    [Color.BLUE]: '#94B9E5',
    [Color.GREEN]: '#A7CEB4',
    [Color.MUSTARD]: '#EAC993',
};

export const colorClassNames: Record<Color, string> = {
    [Color.RED]: styles.red,
    [Color.BLUE]: styles.blue,
    [Color.GREEN]: styles.green,
    [Color.MUSTARD]: styles.mustard,
};

const keys: Record<Period, 'y' | 'Q' | 'M' | 'w'> = {
    [Period.Year]: 'y',
    [Period.Quarter]: 'Q',
    [Period.Month]: 'M',
    [Period.Week]: 'w',
};

const formatsByPeriod: Record<Period, string> = {
    [Period.Year]: 'YYYY',
    [Period.Quarter]: 'Q [квартал] YYYY',
    [Period.Month]: 'MMMM YYYY',
    [Period.Week]: 'W [неделя] Q [квартала] YYYY',
};

const DEFAULT_COLOR_NAME = colorClassNames[Color.GREEN];

export const getColorClassNames = (color: Color) => {
    return colorClassNames[color] || DEFAULT_COLOR_NAME;
};

export const getColorHash = (color: Color): string => {
    return colorHash[color] || colorHash[Color.RED];
};

export const getTimeKeyByPeriod = (period: Period): 'y' | 'Q' | 'M' | 'w' => {
    return keys[period] || keys[Period.Week];
};

export const getDateFormatByPeriod = (period: Period): string => {
    return formatsByPeriod[period] || formatsByPeriod[Period.Week];
};
