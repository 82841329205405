import * as React from 'react';

import * as style from './RegionsTable.scss';

import type {
    CellEvent,
    CellPosition,
    ColumnName,
    ColumnWidths,
    LineId,
    TableBodyCellParams,
    TableHeaderCellParams,
} from './types';

import { TableView } from './TableView';
import { CellsStorage } from './CellsStorage';
import { TableSettings } from './TableSettings';

interface Props {
    headerCellsStorage: CellsStorage<string, TableHeaderCellParams>;
    tableCellsStorage: CellsStorage<CellPosition, TableBodyCellParams>;
    columns: ColumnName[];
    leftFixedColumns: ColumnName[];
    rightFixedColumns: ColumnName[];
    lineIds: LineId[];
    columnWidths: ColumnWidths;
    tableRef: (component: TableView) => void;
    onCellEvent: (eventType: CellEvent, position: CellPosition) => void;
}

export const RegionsTableTemplate = ({
    headerCellsStorage,
    tableCellsStorage,
    columns,
    leftFixedColumns,
    rightFixedColumns,
    lineIds,
    columnWidths,
    tableRef,
    onCellEvent,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <TableSettings />

            <div className={style.table}>
                <TableView
                    ref={tableRef}
                    headerCellsStorage={headerCellsStorage}
                    tableCellsStorage={tableCellsStorage}
                    columns={columns}
                    leftFixedColumns={leftFixedColumns}
                    rightFixedColumns={rightFixedColumns}
                    lines={lineIds}
                    columnWidths={columnWidths}
                    onCellEvent={onCellEvent}
                />
            </div>
        </div>
    );
};
