import * as React from 'react';
import * as d3 from 'd3';

import { Color, ReportType } from '../../../../common';
import { Utils } from '@common/Utils/Utils';
import * as styles from './styles.scss';

interface Data {
    id: string;
    name: string;
    mainValue: number;
    additionalValue: number;
}

interface Params {
    selectorId: string;
    color: string;
    onMouseEnter: (id: string, event: React.MouseEvent<HTMLDivElement>) => void;
    onMouseLeave: (id: string, event: React.MouseEvent<HTMLDivElement>) => void;
}

const ONE_SYMBOL_PX = 5;

export const buildChart = (data: Data[], { selectorId, color, onMouseEnter, onMouseLeave }: Params) => {
    let width = data.length * 150;
    let height;
    const leftIndent =
        String(d3.max(data.map(({ mainValue, additionalValue }) => d3.max([mainValue, additionalValue])))).length *
            ONE_SYMBOL_PX +
        12;

    const container = d3
        .select(`#${selectorId}`)
        .attr(
            'style',
            '' + 'max-width: 100%;' + 'display: grid;' + 'grid-template-columns: max-content 1fr;' + 'height: 100%;',
        )
        .call((div) => {
            height = Number((div.node() as HTMLDivElement).getBoundingClientRect().height - 10);
        });

    const leftSidebarSvg = container.append('svg').attr('width', leftIndent).attr('height', height);

    const rightContainer = container
        .append('div')
        .attr('style', '' + 'position: relative;' + 'overflow: hidden;' + 'max-width: 100%;' + 'height: 100%;')
        .call((div) => {
            const widthDiv = div.node().getBoundingClientRect().width;
            if (widthDiv > width) {
                width = widthDiv;
            }
        });

    const xRange = [0, width];
    // @ts-ignore
    const yRange = [height - 25, 10];

    const X = d3.map(data, (data) => ({
        id: data.id,
        name: data.name,
    }));
    const mainY = d3.map(data, (data) => data.additionalValue);
    const additionalY = d3.map(data, (data) => data.mainValue);

    const xDomain = new d3.InternSet(d3.map(X, (X) => X.name));
    const yDomain = [0, d3.max([...mainY, ...additionalY])];

    const I = d3.range(X.length).filter((i) => xDomain.has(d3.map(X, (X) => X.name)[i]));

    const xScale = d3.scaleBand(xDomain, xRange).align(0);
    const yScale = d3.scaleLinear(yDomain, yRange);

    const xAxis = d3.axisBottom(xScale);
    const yAxis = d3.axisLeft(yScale).ticks(4);

    leftSidebarSvg
        .append('g')
        .attr('transform', `translate(${leftIndent},0)`)
        .call(yAxis)
        .call((g) => g.select('.domain').remove())
        .call((g) => g.selectAll('.tick line').remove())
        .call((g) =>
            g.selectAll('.tick').attr('style', '' + 'font-family: Open Sans;' + 'font-size: 10px;' + 'color: #7E8681;'),
        );

    const scrollDiv = rightContainer
        .append('div')
        .attr(
            'style',
            '' +
                'position: relative;' +
                'z-index: 1;' +
                'max-width: 100%;' +
                'height: 100%;' +
                'box-sizing: border-box;' +
                'overflow-x: scroll;' +
                'overflow-y: hidden;',
        );

    const svg = scrollDiv.append('svg').attr('width', `${width}px`).attr('height', height);

    const coordinateRect = {
        x: 0,
        y: yScale(d3.max([d3.max(mainY), d3.max(additionalY)])),
        height: yScale(0) - yScale(d3.max([d3.max(mainY), d3.max(additionalY)])),
        width: 0,
    };

    rightContainer
        .append('svg')
        .attr(
            'style',
            '' +
                'position: absolute;' +
                'z-index: 0;' +
                'left: 0;' +
                'top: 0;' +
                'width: 100%;' +
                `height: ${height}px;`,
        )
        .call((g) => (coordinateRect.width = g.node().getBoundingClientRect().width))
        .call(yAxis)
        .call((g) => g.select('.domain').remove())
        .call((g) => g.selectAll('.tick line').attr('stroke', '#F1F5F7').attr('x2', width))
        .call((g) => g.selectAll('.tick text').remove())
        .call((g) => g.select('.tick:first-child').remove())
        .call((g) => g.select('.tick:last-child').remove())
        .append('rect')
        .attr('x', '0.5px')
        .attr('y', `${coordinateRect.y}px`)
        .attr('height', `${coordinateRect.height}px`)
        .attr('width', `${coordinateRect.width - 1}px`)
        .attr('stroke-width', '1px')
        .attr('stroke', '#F1F5F7')
        .attr('stroke-linejoin', 'round')
        .attr('stroke-alignment', 'outer')
        .attr('rx', '2px')
        .attr('ry', '2px');

    const centerRect = xScale.bandwidth() / 2;
    const widthBins = 12;
    const innerBetweenBins = 4;

    svg.append('g')
        .selectAll('g')
        .data(I)
        .enter()
        .append('g')
        .call((g) =>
            g
                .append('rect')
                .attr(
                    'x',
                    (i) =>
                        xScale(d3.map(X, (X) => X.name)[i]) +
                        centerRect -
                        widthBins -
                        innerBetweenBins / 2 -
                        xScale.bandwidth() / 2 +
                        widthBins +
                        1,
                )
                .attr('y', yScale(d3.max([d3.max(mainY), d3.max(additionalY)])))
                .attr('width', xScale.bandwidth)
                .attr('height', () => yScale(0) - yScale(d3.max([d3.max(mainY), d3.max(additionalY)])))
                .attr('class', styles.aggregatingBin)
                .call(hover),
        )
        .append('rect')
        .attr('x', (i) => xScale(d3.map(X, (X) => X.name)[i]) + centerRect - widthBins - innerBetweenBins / 2)
        .attr('y', (i) => yScale(mainY[i]))
        .attr('height', (i) => yScale(0) - yScale(mainY[i]))
        .attr('width', widthBins)
        .attr('fill', color)
        .attr('pointer-events', 'none')
        .attr('opacity', '0.3')
        .clone()
        .attr('x', (i) => xScale(d3.map(X, (X) => X.name)[i]) + centerRect + innerBetweenBins / 2)
        .attr('y', (i) => yScale(additionalY[i]))
        .attr('height', (i) => yScale(0) - yScale(additionalY[i]))
        .attr('width', widthBins)
        .attr('fill', color)
        .attr('opacity', '1');

    svg.append('g')
        .attr('transform', `translate(0, ${Number(height) - 30})`)
        .call(xAxis)
        .call((g) => g.select('.domain').remove())
        .call((g) => g.selectAll('.tick line').remove())
        .call((g) =>
            g.selectAll('.tick').attr('style', '' + 'font-family: Open Sans;' + 'font-size: 12px;' + 'color: #7E8681;'),
        )
        .call((g) => g.selectAll('.tick text').call(wrap));

    function hover(element: { each: (arg0: (i: number) => void) => void }) {
        element.each(function (i: number) {
            // @ts-ignore
            d3.select(this)
                .on('mouseenter', (event) => onMouseEnter(X[i].id, event))
                .on('mouseout', (event) => onMouseLeave(X[i].id, event));
        });
    }

    return () => {
        leftSidebarSvg.remove();
        rightContainer.remove();
    };
};

function wrap(text: { each: (arg0: () => void) => void }) {
    text.each(function () {
        // @ts-ignore
        const text = d3.select(this);
        const words = text.text();
        text.text(Utils.shortenString(words, 23));
    });
}

export const colorClassNames: Record<Color, string> = {
    [Color.RED]: styles.red,
    [Color.BLUE]: styles.blue,
    [Color.GREEN]: styles.green,
    [Color.MUSTARD]: styles.mustard,
};

export const titles: Record<ReportType, string> = {
    [ReportType.ACTIVE]: 'Активные проекты',
    [ReportType.OVERDUE_STAGES]: 'Проекты с просроченными этапами',
    [ReportType.OVERDUE_TASKS]: 'Проекты с просроченными задачами',
    [ReportType.PLANNED_START]: 'Планируется запуск проектов',
};

const DEFAULT_COLOR_NAME = colorClassNames[Color.GREEN];

export const getColorClassNames = (color: Color) => {
    return colorClassNames[color] || DEFAULT_COLOR_NAME;
};

export const getTitleByReportType = (type: ReportType): string => {
    return titles[type] || titles[ReportType.ACTIVE];
};
