import { Color } from '../../../../common';
import {
    Mode,
    StatisticsItem,
    StatisticsTaskType,
    StatisticsActivityType,
    StatisticsTypeFilters,
    StatisticsType,
} from '@store/leaderDashboardPage';

export type BuilderReportChartConfigByMode = Record<Mode, BuildReportChartConfig>;

export interface BuildReportChartConfig<M extends Mode = Mode> {
    (params: BuildReportChartConfigParams<M>): ReportChartConfig[];
}

interface BuildReportChartConfigParams<M extends Mode = Mode> {
    statisticsItems: StatisticsItem<M>[];
    statisticsTypeFilters: StatisticsTypeFilters;
}

export interface ReportChartConfig {
    name: string;
    color: Color;
    type: StatisticsType;
    data: {
        id: string;
        name: string;
        mainValue: number;
        additionalValue: number;
    }[];
}

export { StatisticsActivityType, StatisticsTaskType };
