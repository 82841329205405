import * as React from 'react';
import { OpacityTransition, ResponsiveLayout, LeftSidebar, CentralContent, Icon, IconType } from 'sber-marketing-ui';
import classnames from 'classnames';

import { Correction } from './types';

import { Filters } from './Filters';
import { CorrectionsList } from './CorrectionsList';
import { Popup } from './Popup';
import { ExportCorrectionsButton } from './ExportCorrectionsButton';
import { ApplyFiltersPreloader, FetchMorePreloader } from './Preloaders';

import * as style from './BudgetCorrections.scss';

interface Props {
    applyFiltersInProgress: boolean;
    fetchMoreInProgress: boolean;
    allCorrectionsIds: string[];
    corrections: Correction[];
    isRequestInProgress: boolean;
    budgetId: string;
    isExportModeEnabled: boolean;
    fetchMore: () => void;
    applyFilters: () => void;
}

export function BudgetCorrectionsTemplate({
    applyFiltersInProgress,
    fetchMoreInProgress,
    allCorrectionsIds,
    corrections,
    budgetId,
    isRequestInProgress,
    isExportModeEnabled,
    fetchMore,
    applyFilters,
}: Props): JSX.Element {
    let centralContent: JSX.Element;
    if (applyFiltersInProgress) {
        centralContent = <ApplyFiltersPreloader />;
    } else {
        centralContent = corrections.length ? (
            <div className={style.contentInnerWrapper}>
                <CorrectionsList budgetId={budgetId} corrections={corrections} fetchMore={fetchMore} />

                {fetchMoreInProgress ? <FetchMorePreloader /> : null}
            </div>
        ) : (
            <NoCorrectionsMarker />
        );
    }

    return (
        <OpacityTransition start={!isRequestInProgress}>
            <div className={style.root}>
                <Popup applyFilters={applyFilters} />

                <ResponsiveLayout>
                    <LeftSidebar fixed>
                        <ExportCorrectionsButton allCorrectionsIds={allCorrectionsIds} applyFilters={applyFilters} />

                        <div
                            className={classnames(
                                style.filtersWrapper,
                                isExportModeEnabled && style.fitlersWrapperForExport,
                            )}
                        >
                            <Filters budgetId={budgetId} applyFilters={applyFilters} />
                        </div>
                    </LeftSidebar>

                    <CentralContent>{centralContent}</CentralContent>
                </ResponsiveLayout>
            </div>
        </OpacityTransition>
    );
}

function NoCorrectionsMarker(): JSX.Element {
    return (
        <div className={style.noCorrectionsMarker}>
            <Icon fontSize={21} type={IconType.WARNING_IN_TRIANGLE} className={style.noCorrectionsMarkerIcon} />
            Не найдено ни одной корректировки, удовлетворяющей заданным фильтрам
        </div>
    );
}
