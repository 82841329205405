export const enum Captions {
    Roubles = '₽',
    Thousands = 'тыс. ₽',
}

export class Money {
    private copecks: number;

    private constructor(copecks: number) {
        this.copecks = copecks;
    }

    public static fromCopecks(copecks: number): Money {
        return new Money(copecks);
    }

    public static fromStringCopecks(copecks: string): Money {
        return this.fromCopecks(copecks ? parseInt(copecks, 10) : 0);
    }

    public static fromRoubles(roubles: number): Money {
        return new Money(Math.floor(roubles * 100));
    }

    public static fromStringRoubles(roubles: string): Money {
        return this.fromRoubles(roubles ? parseFloat(roubles) : 0);
    }

    public getSign(): number {
        return Math.sign(this.copecks);
    }

    public getCopecks(): number {
        return Math.abs(this.copecks);
    }

    public getRoubles(): number {
        return Math.floor(this.getCopecks() / 100.0);
    }

    public getRoundedRoubles(): number {
        return Math.round(this.getCopecks() / 100.0);
    }

    public getThousands(): number {
        return Math.floor(this.getRoubles() / 1000.0);
    }

    public getRoundedThousands(): number {
        return Math.floor(this.getRoundedRoubles() / 1000.0);
    }
}
