import * as React from 'react';
import { connect } from 'react-redux';
import { UserResponseParams } from 'sber-marketing-types/frontend';

import { StoreState } from '@store';
import { DifferenceType } from '@store/news';
import type { DifferenceItemParams } from '@store/news';
import { getDivisionById, DivisionStub } from '@store/divisions';
import { getActivityTypeById, ActivityTypeStub } from '@store/activityTypes';
import { getUserById } from '@store/users';

import { Difference } from './Difference';

interface Props extends Partial<MapProps>, OwnProps {}

interface MapProps {
    mappedDiff?: {
        before: string;
        beforeCaption?: string;
        after: string;
        afterCaption?: string;
    };
}

interface OwnProps extends DifferenceItemParams {
    index: number;
}

@(connect(mapStateToProps) as any)
export class DifferenceContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { mappedDiff, before, beforeCaption, after, afterCaption, ...props } = this.props;

        const beforeValue: string = mappedDiff ? mappedDiff.before : before;
        const beforeCaptionValue: string = mappedDiff ? mappedDiff.beforeCaption : beforeCaption;
        const afterValue: string = mappedDiff ? mappedDiff.after : after;
        const afterCaptionValue: string = mappedDiff ? mappedDiff.afterCaption : afterCaption;

        return (
            <Difference
                before={beforeValue}
                beforeCaption={beforeCaptionValue}
                after={afterValue}
                afterCaption={afterCaptionValue}
                {...props}
            />
        );
    }
}

function mapStateToProps(state: StoreState, props: OwnProps): MapProps {
    const result: MapProps = {};
    switch (props.type) {
        case DifferenceType.DIVISION:
            const beforeDivision = getDivisionById(state, props.before) || DivisionStub;
            const afterDivision = getDivisionById(state, props.after) || DivisionStub;

            result.mappedDiff = {
                before: beforeDivision.name,
                after: afterDivision.name,
            };

            break;
        case DifferenceType.ACTIVITY:
            const beforeActivityType = getActivityTypeById(state, props.before) || ActivityTypeStub;
            const afterActivityType = getActivityTypeById(state, props.after) || ActivityTypeStub;

            result.mappedDiff = {
                before: beforeActivityType.name,
                after: afterActivityType.name,
            };

            break;
        case DifferenceType.USER:
            const beforeUser: UserResponseParams = getUserById(state, props.before);
            const afterUser: UserResponseParams = getUserById(state, props.after);

            result.mappedDiff = {
                before: `${beforeUser.secondName} ${beforeUser.firstName}`,
                beforeCaption: beforeUser.departmentName,
                after: `${afterUser.secondName} ${afterUser.firstName}`,
                afterCaption: afterUser.departmentName,
            };

            break;
    }

    return result;
}
