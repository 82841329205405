import * as React from 'react';
import * as style from './CardSubInfoGroup.scss';

import { Icon, IconType } from 'sber-marketing-ui';
import { CardSubInfo, CardSubInfoIconWrapper } from '../CardSubInfo';

interface Props {
    text: string;
    loading?: boolean;
}

const LightningIcon: React.FC = () => (
    <CardSubInfoIconWrapper>
        <Icon className={style.lightningIcon} type={IconType.LIGHTNING} svgSize={16} />
    </CardSubInfoIconWrapper>
);

const PointsIcon: React.FC = () => (
    <CardSubInfoIconWrapper>
        <Icon className={style.branchesIcon} type={IconType.BRANCHES} svgSize={15} />
    </CardSubInfoIconWrapper>
);

const CubeIcon: React.FC = () => (
    <CardSubInfoIconWrapper>
        <Icon type={IconType.CUBE} svgSize={11} />
    </CardSubInfoIconWrapper>
);

const Text: React.FC = ({ children }) => <div className={style.text}>{children}</div>;

const NestedLink: React.FC<{ url: string }> = ({ children, url }) => (
    <object>
        <a className={style.link} href={url} onClick={LinkHandler}>
            {children}
        </a>
    </object>
);

function LinkHandler(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.stopPropagation();
}

export const CardActivityNameInfo: React.FC<Props & { activityId: Number }> = ({ text, activityId }) => {
    return (
        <CardSubInfo tooltip={text} qaId="cardActivityName" qaTitle="Проект" qaValue={text}>
            <PointsIcon />
            <Text>
                Проект: <NestedLink url={`/activity/${activityId}`}>{text}</NestedLink>
            </Text>
        </CardSubInfo>
    );
};

export const CardProductSubInfo: React.FC<Props> = ({ text, loading }) => {
    return (
        <CardSubInfo tooltip={text} loading={loading} qaId="cardProduct" qaTitle="Продукт" qaValue={text}>
            <CubeIcon />
            <Text>Продукт: {text}</Text>
        </CardSubInfo>
    );
};

export const CardWorkTypeSubInfo: React.FC<Props> = ({ text, loading }) => {
    return (
        <CardSubInfo tooltip={text} loading={loading} qaId="cardWorkType" qaTitle="Тип работ" qaValue={text}>
            <LightningIcon />
            <Text>Тип работ: {text}</Text>
        </CardSubInfo>
    );
};

export const CardOrganizationAndBlockSubInfo: React.FC<Props> = ({ text, loading }) => {
    return (
        <CardSubInfo
            tooltip={text}
            loading={loading}
            qaId="cardOrganizationAndBlock"
            qaTitle="Организация и блок"
            qaValue={text}
        >
            <PointsIcon />
            <Text>Организация и блок: {text}</Text>
        </CardSubInfo>
    );
};

export const CardStage: React.FC<Props> = ({ text }) => {
    return (
        <CardSubInfo tooltip={text} qaId="cardStage" qaTitle="Этап" qaValue={text}>
            <Text>
                Этап: <span className={style.bold}>{text}</span>
            </Text>
        </CardSubInfo>
    );
};
