import { includes } from 'lodash';
import { RoleId } from 'sber-marketing-types/frontend';
import { TaskCreatedBy, TaskStatus } from 'sber-marketing-types/backend';

import { User } from '@store/user';
import { Permissions } from './types';

export const cannotChangeAnythingPermissions: Permissions = {
    canChangeExecutorDeadlineAndFiles: false,
    canChangeRest: false,
};

export const allowAllPermissions: Permissions = {
    canChangeExecutorDeadlineAndFiles: true,
    canChangeRest: true,
};

export const defaultPermissions: Permissions = {
    canChangeExecutorDeadlineAndFiles: false,
    canChangeRest: false,
};

export const directorAndExecutorPermission: Permissions = {
    canChangeExecutorDeadlineAndFiles: true,
    canChangeRest: false,
};

export const authorPermissions: Permissions = {
    canChangeExecutorDeadlineAndFiles: true,
    canChangeRest: true,
};

export interface DefinePermissionsParams {
    user: User;
    task: Task;
    activity: Activity;
}

export interface Task {
    authorId: string | number;
    executorId: string | number;
    organizationId: string;
    canEdit: boolean;
    createdBy: TaskCreatedBy;
    status: TaskStatus;
}

export interface Activity {
    authorId: string | number;
    responsibleId: string | number;
}

export const buildPermissions = ({ user, task, activity }: DefinePermissionsParams): Permissions => {
    const isCurrentUserActivityAuthor = activity.authorId === user.attributes.id;
    const isCurrentUserActivityResponsible = activity.responsibleId === user.attributes.id;
    const allowActivityAuthorOrResponsibleToEditDraftBPMTask =
        (isCurrentUserActivityAuthor || isCurrentUserActivityResponsible) &&
        task.createdBy === TaskCreatedBy.Auto &&
        task.status === TaskStatus.Draft;
    if (allowActivityAuthorOrResponsibleToEditDraftBPMTask) {
        return allowAllPermissions;
    }

    if (!task.canEdit) {
        return cannotChangeAnythingPermissions;
    }

    const isCurrentUserAuthor = task.authorId === user.attributes.id;
    const isCurrentUserExecutor = task.executorId === user.attributes.id;
    const isCurrentUserDirector = includes(
        user.attributes.roles.map(({ id }) => id),
        RoleId.Director,
    );

    if (isCurrentUserAuthor) {
        return authorPermissions;
    }

    if (isCurrentUserDirector || isCurrentUserExecutor) {
        return directorAndExecutorPermission;
    }

    return defaultPermissions;
};
