import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { AccountStatus } from 'sber-marketing-types/frontend';
import { DictionaryStatus, PlainDictionary } from '@mrm/dictionary';
import { Dictionary, sortBy } from 'lodash';
import { SelectItem } from 'sber-marketing-ui';

import { getActivityTypeTooltip } from '@common/ActivityTypeTooltips';

import { NewActivityPageState as State, PageValues, LoadPageDataPayload, ValidationMode } from './types';

import * as actions from './actions/sync';

import { Utils } from '@common/Utils';

class Reducer {
    public static makeInitialState(): State {
        return {
            activity: null,
            blocks: [],
            productsByBlock: {},
            calendarGroupsByBlock: {},
            activityTypes: [],
            responsibles: [],
            isPrivate: false,
            validationMode: ValidationMode.None,
            values: {
                name: '',
                description: '',
                divisionId: null,
                productId: null,
                calendarGroupId: null,
                activityTypeId: null,
                responsibleId: null,
                preparationDate: null,
                realizationStart: null,
                realizationEnd: null,
                debriefingDate: null,
                autoCreateTasks: false,
            },
            bpmWasInit: false,
        };
    }

    public static loadNewProjectPageData(state: State, payload: LoadPageDataPayload): State {
        const { activity, users, blocks, activityTypes, products, calendarGroups } = payload;

        const productItemsByBlock: Dictionary<SelectItem[]> =
            blocks && blocks.length
                ? blocks.reduce(
                      (acc, block) => ({
                          ...acc,
                          [block.id]: block.childrenRefs?.reduce((acc, childRef) => {
                              const product = products.find((product) => product.id === childRef.id);

                              if (product) {
                                  return [
                                      ...acc,
                                      {
                                          label: Utils.getDictionaryValue(product),
                                          value: product.id,
                                          disabled: product.status === DictionaryStatus.DELETED,
                                      },
                                  ];
                              }

                              return acc;
                          }, []),
                      }),
                      {},
                  )
                : {};

        const calendarGroupItems: Dictionary<SelectItem[]> =
            blocks && blocks.length
                ? blocks.reduce(
                      (acc, block) => ({
                          ...acc,
                          [block.id]: block.childrenRefs?.reduce((acc, childRef) => {
                              const calendarGroup = calendarGroups.find((group) => group.id === childRef.id);

                              if (calendarGroup) {
                                  return [
                                      ...acc,
                                      {
                                          label: Utils.getDictionaryValue(calendarGroup),
                                          value: calendarGroup.id,
                                          disabled: calendarGroup.status === DictionaryStatus.DELETED,
                                      },
                                  ];
                              }

                              return acc;
                          }, []),
                      }),
                      {},
                  )
                : {};

        const blockItems: SelectItem[] =
            blocks && blocks.length
                ? blocks.map((block) => ({
                      label: Utils.getDictionaryValue(block),
                      value: block.id,
                      disabled: block.status === DictionaryStatus.DELETED,
                  }))
                : [];

        const activityTypeItems: SelectItem[] =
            activityTypes && activityTypes.length
                ? sortBy(
                      activityTypes.map((activityType: PlainDictionary): SelectItem => {
                          const label = Utils.getDictionaryValue(activityType);

                          return {
                              label,
                              value: activityType.id,
                              disabled: activityType.status === DictionaryStatus.DELETED,
                              tooltip: getActivityTypeTooltip(label),
                          };
                      }),
                      'label',
                  )
                : [];

        const responsibleItems: SelectItem[] =
            users && users.length
                ? users.map((user) => {
                      const accountIsActive =
                          user.status === AccountStatus.ACTIVE || user.status === AccountStatus.NEED_UPDATE_PASSWORD;

                      return {
                          label: `${user.firstName} ${user.secondName} ${accountIsActive ? '' : '(Заблокирован)'}`,
                          value: user.id,
                          disabled: !accountIsActive,
                      };
                  })
                : [];

        return {
            ...state,
            activity,
            blocks: blockItems,
            productsByBlock: productItemsByBlock,
            calendarGroupsByBlock: calendarGroupItems,
            activityTypes: activityTypeItems,
            responsibles: responsibleItems,
        };
    }

    public static resetPageState(): State {
        return Reducer.makeInitialState();
    }

    public static setActivityPrivacy(state: State, payload: boolean): State {
        return {
            ...state,
            isPrivate: payload,
        };
    }

    public static setValidationMode(state: State, payload: ValidationMode): State {
        return {
            ...state,
            validationMode: payload,
        };
    }

    public static setValues(state: State, values: Partial<PageValues>): State {
        return {
            ...state,
            values: {
                ...state.values,
                ...values,
            },
        };
    }

    public static setBpmWasInit(state: State, bpmWasInit: boolean): State {
        return { ...state, bpmWasInit };
    }
}

export const newActivityReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(actions.loadNewActivityPageData, Reducer.loadNewProjectPageData)
    .case(actions.resetPageState, Reducer.resetPageState)
    .case(actions.setActivityPrivacy, Reducer.setActivityPrivacy)
    .case(actions.setValidationMode, Reducer.setValidationMode)
    .case(actions.setValues, Reducer.setValues)
    .case(actions.setBpmWasInit, Reducer.setBpmWasInit);
