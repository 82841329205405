// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-Texts-List-_styles__title{margin-bottom:7px;font-family:'Open Sans';font-weight:600;font-size:16px;color:#092A3A}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/Texts/List/styles.scss"],"names":[],"mappings":"AAEA,uGACI,iBAAkB,CAElB,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,aAAc","sourcesContent":[".root {}\n\n.title {\n    margin-bottom: 7px;\n\n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 16px;\n    color: #092A3A;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-Texts-List-_styles__title"
};
export default ___CSS_LOADER_EXPORT___;
