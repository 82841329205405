import { ActivityBudget, BudgetItem } from '@mrm/budget';
import { PlainDictionary } from '@mrm/dictionary';

export interface PageState {
    pageData?: PageData;
    filters?: Filters;
    period?: Period;
    expandedGroupsIds?: string[];
    expandedActivitiesIds?: string[];
    hoveredItemId?: string | number;
}

export const enum Period {
    Year = 'year',
    Quarter = 'quarter',
    Month = 'month',
}

export interface PageData {
    activityBudgets?: ActivityBudget[];
    budgetItems?: BudgetItem[];
    dictionaries?: GroupedDictionaries;
    responsibleUsers?: ResponsibleUser[];
}

export interface Filters {
    activityTypeIds?: string[];
    blockIds?: string[];
    productIds?: string[];
    responsibleUserIds?: number[];
}

export interface CalendarUserConfig {
    filters?: Filters;
}

export interface ResponsibleUser {
    id: number;
    name: string;
    organizationId: string;
}

export interface DivisionNames {
    [key: string]: string;
}

export interface ActivityTypeColor {
    realization: string;
    hover: string;
}

export const DEFAULT_ACTIVITY_TYPE_COLOR: ActivityTypeColor = {
    realization: '#95bdec',
    hover: '#578ac7',
};

export type GroupedDictionaries = { [dictionaryType: string]: PlainDictionary[] };
export type ActivityBudgetsGroupedByName = { [activityName: string]: ActivityBudget[] };

export const enum LineType {
    GroupTitle = 'groupTitle',
    Activity = 'activity',
    ActivityBudget = 'activityBudget',
}

export interface GroupParams {
    id: string;
    lines: LineParams[];
    activityLinesCount: number;
    isExpanded: boolean;
}

export type LineParams = GroupTitleParams | ActivityLineParams | ActivityBudgetLineParams;

export interface BaseLineParams {
    type: LineType;
    title: string;
}

export interface GroupTitleParams extends BaseLineParams {
    id: string;
}

export interface ActivityLineParams extends BaseLineParams {
    id: string;
    isActive: boolean;
    blockIds: string[];
    productIds: string[];
    activityTypeIds: string[];
    responsibleUserIds: number[];
    realizationStartDate: string;
    realizationEndDate: string;
    realizationStartDay: number;
    realizationEndDay: number;
    color: ActivityTypeColor;
    isExpanded: boolean;
}

export interface ActivityBudgetLineParams extends BaseLineParams {
    id: string;
    isActive: boolean;
    productIds: string[];
    responsibleUserIds: number[];
    realizationStartDate: string;
    realizationEndDate: string;
    realizationStartDay: number;
    realizationEndDay: number;
    color: ActivityTypeColor;
}

export interface Position {
    x: number;
    y: number;
}

export const ACTIVITY_TYPE_COLORS: { [dictionaryId: string]: ActivityTypeColor } = {
    '5f65c997-5bc0-44f4-8a69-580916ac9219': { realization: '#a2efe2', hover: changeColorBrightness('#a2efe2', -20) },
    '2f733ca9-4292-4359-b3d6-1935581a2195': { realization: '#c994f0', hover: changeColorBrightness('#c994f0', -20) },
    '97c1c6ce-62e1-4494-8b13-9bf509c89edb': { realization: '#baf09a', hover: changeColorBrightness('#baf09a', -20) },
    '66952d8e-569b-421a-8735-2010fe4e869b': { realization: '#e2f27b', hover: changeColorBrightness('#e2f27b', -20) },
    '38744570-f565-4472-9e92-1eb3ea89fe98': { realization: '#e69f5c', hover: changeColorBrightness('#e69f5c', -20) },
    '0da8e5e7-75e7-4e1e-bd9d-01fc051743e7': { realization: '#3bb3e8', hover: changeColorBrightness('#3bb3e8', -20) },
    '9022c229-0cf1-415b-9f6d-c11e11881c9d': { realization: '#9ac96d', hover: changeColorBrightness('#9ac96d', -20) },
    '7e64f803-216e-47bc-9d97-caf858e14181': { realization: '#b6b63c', hover: changeColorBrightness('#b6b63c', -20) },
    '3e6db99d-7501-42e6-87b9-055c34f8b0aa': { realization: '#e7808d', hover: changeColorBrightness('#e7808d', -20) },
    '9510b06b-bab1-4da1-82f0-a67648fada6e': { realization: '#a66e6e', hover: changeColorBrightness('#a66e6e', -20) },
    '77a87319-2e70-492a-837c-1c746d83bf89': { realization: '#f5dcaa', hover: changeColorBrightness('#f5dcaa', -20) },
    'ed85ece4-60bf-420b-a322-dba575258720': { realization: '#2dc232', hover: changeColorBrightness('#2dc232', -20) },
    'db4cba4d-d6ce-4252-99c1-ea6c6f44c410': { realization: '#76eb0c', hover: changeColorBrightness('#76eb0c', -20) },
    '19315d52-14f1-4d46-95d3-03dd498a4919': { realization: '#3d63c2', hover: changeColorBrightness('#3d63c2', -20) },
    'fb48318d-c47b-4a89-9bc9-a6f0bcc579dd': { realization: '#f842d2', hover: changeColorBrightness('#f842d2', -20) },
    'bc692de7-b984-4b99-979a-908c90e513a4': { realization: '#eaea19', hover: changeColorBrightness('#eaea19', -20) },
    '17bb5e69-78af-4b2d-b387-0e6640ec37e8': { realization: '#3c9618', hover: changeColorBrightness('#3c9618', -20) },
    '8fe068ec-c46b-4aad-9a94-ebc07723318b': { realization: '#0d4787', hover: changeColorBrightness('#0d4787', -20) },
    'c64d6a71-6ae5-4f59-a5f6-8ed94efcdbca': { realization: '#a32778', hover: changeColorBrightness('#a32778', -20) },
    '8aaaa3e6-f9ec-49f9-b006-38b6b2575f22': { realization: '#ed071f', hover: changeColorBrightness('#ed071f', -20) },
    'adc23303-1b69-4acd-952b-cf273c994202': { realization: '#eded87', hover: changeColorBrightness('#eded87', -20) },
    'f258fdff-e12d-4d68-95c9-bd3164a57ae5': { realization: '#bce4ef', hover: changeColorBrightness('#bce4ef', -20) },
    '77fdffb7-ed68-411c-8b79-c41952856c68': { realization: '#b6b63c', hover: changeColorBrightness('#b6b63c', -20) },
    'faedbf76-efb9-4c5d-bbc9-3707f7c6d45c': { realization: '#34846a', hover: changeColorBrightness('#34846a', -20) },
    '0b04fda6-eeb4-4bf0-b4c2-99d916d72d8c': { realization: '#eba9d1', hover: changeColorBrightness('#eba9d1', -20) },
    '8563ff2c-9399-4cdd-afa6-2cb905b21d93': { realization: '#2dc232', hover: changeColorBrightness('#2dc232', -20) },
    '526b2688-cbfe-4660-8711-6535a5b912bc': { realization: '#b6e5a5', hover: changeColorBrightness('#b6e5a5', -20) },
};

function changeColorBrightness(color: string, amount: number) {
    return (
        '#' +
        color
            .replace(/^#/, '')
            .replace(/../g, (color) =>
                ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2),
            )
    );
}
