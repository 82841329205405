export interface Point {
    x: number;
    y: number;
}

export interface Size {
    width: number;
    height: number;
}

export interface CellPosition {
    columnName: ColumnName;
    lineId: LineId;
}

export interface BaseCellParams {
    component: React.Component;
    cellProps: any;
}

export interface TableHeaderCellParams extends BaseCellParams {}

export interface TableBodyCellParams extends BaseCellParams {
    readOnly?: boolean;
    сellBackgroundColor?: string;
    preventCloseOnClick?: boolean;
}

export type ColumnName = string;
export type LineId = string;

export type ColumnWidths = Record<ColumnName, number>;
export type LineHeights = Record<LineId, number>;

export enum TableEvent {
    Scroll = 'scroll',
    SizeChange = 'size_change',
}

export const enum CellEvent {
    MouseSelection = 'mouseSelection',
    SelectionCancel = 'selectionCancel',
}

export interface Filter {
    columnName: ColumnName;
    selectedValues: React.ReactText[];
}

export interface CustomFilter {
    filterType: CustomFilterType;
    name: React.ReactText;
    params: React.ReactText[];
}

export interface SortingParams {
    columnName: ColumnName;
    orderType: OrderType;
}

export const enum CustomFilterType {
    None = 'none',
    NotClosed = 'notClosed',
}

export const enum OrderType {
    Asc = 'asc',
    Desc = 'desc',
}
