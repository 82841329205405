import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { CreativeStageForm } from '@store/autopilot_legacy/types';

import { CreativeStageActionsTemplate } from './CreativeStageActionsTemplate';
import type { StoreState } from '@store';
import { setCurrentStage } from '@store/autopilot_legacy/actions';
import { getCreativeStageForm } from '@store/autopilot_legacy/selectors';
import { Loader, Saver } from '../../modules';

interface Props extends MapProps, DispatchProps {
    canEdit: boolean;
}

interface MapProps {
    creativeStageForm?: CreativeStageForm;
}

interface DispatchProps {
    setCurrentStage?: (stage: number) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class CreativeStageActionsBehaviour extends React.PureComponent<Props> {
    private loader: Loader;
    private saver: Saver;

    public constructor(props: Props) {
        super(props);

        this.loader = Loader.getInstance();
        this.saver = Saver.getInstance();
    }

    public render(): JSX.Element {
        const { creativeStageForm } = this.props;

        const counter = [
            creativeStageForm.vkUrl,
            // creativeStageForm.facebookUrl,
            creativeStageForm.okUrl,
            // creativeStageForm.youtubeUrl
        ].filter((item) => !lodash.isEmpty(item)).length;

        return React.createElement(CreativeStageActionsTemplate, {
            counter,
            canEdit: this.props.canEdit,
            onContinueButtonClick: this.onContinueButtonClick,
        });
    }

    @autobind
    protected async onContinueButtonClick() {
        await this.saver.saveCreativeForm();

        this.props.setCurrentStage(5);

        await this.loader.loadAutopilot();
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        creativeStageForm: getCreativeStageForm(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setCurrentStage,
        },
        dispatch,
    );
}
