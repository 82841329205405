import { createSelector } from 'reselect';
import { pick, compact, toArray, identity, some, filter, sumBy } from 'lodash';

import { StoreState } from '../';
import { FileAsset } from '../commonTypes';
import { UploadingFilesData } from './types';

const getFileAssets = (state: StoreState) => state.fileAssets;

const getAssetsDictionary = createSelector(getFileAssets, ({ assets }) => assets);

const getNameIndex = createSelector(getFileAssets, ({ nameIndex }) => nameIndex);

export const getFileAssetIdByName = createSelector(
    getNameIndex,
    (_: StoreState, name: string) => name,
    (nameIndex, name): string | undefined => nameIndex[name],
);

export const getFileAssetsIdsByNames = createSelector(
    getNameIndex,
    (_: StoreState, names: string[]) => names,
    (nameIndex, names): string[] => compact(toArray(pick(nameIndex, names))),
);

export const getFileAssetById = createSelector(
    getAssetsDictionary,
    (_: StoreState, id: string) => id,
    (assets, id): FileAsset | undefined => assets[id],
);

export const getFileAssetsByIds = createSelector(
    getAssetsDictionary,
    (_: StoreState, ids: string[]) => ids,
    (assets, ids): FileAsset[] => compact(toArray(pick(assets, ids))),
);

export const getFileAssetsByTargetId = createSelector(
    getAssetsDictionary,
    (_: StoreState, targetId: string) => targetId,
    (assets, targetId): FileAsset[] => filter(assets, { targetId }),
);

export const getFileAssetByName = createSelector([<any>identity, getFileAssetIdByName], getFileAssetById);

export const getFileAssetsByNames = createSelector([<any>identity, getFileAssetsIdsByNames], getFileAssetsByIds);

export const hasUploadingFiles = createSelector(getAssetsDictionary, (assets) => some(assets, ['isLoading', true]));

export const getUploadingFilesData = createSelector(getAssetsDictionary, (assets): UploadingFilesData => {
    const uploadingAssets = filter(assets, ['isLoading', true]);
    const count = uploadingAssets.length;
    const loadingProgress = Math.floor(sumBy(uploadingAssets, 'loadingProgress') / count);
    return { count, loadingProgress };
});
