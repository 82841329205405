// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes src-client-modules-activity-pages-task-components-TelegramButton-ErrorPopup-_styles__sliding-appearance{0%{opacity:0;transform:translateY(-5px)}100%{opacity:1;transform:translateY(0px)}}.src-client-modules-activity-pages-task-components-TelegramButton-ErrorPopup-_styles__root{width:440px;background-color:#ffffff;box-shadow:0 2px 10px rgba(0,0,0,0.1);border:1px solid #ffffff;border-radius:8px;color:#000000;animation:src-client-modules-activity-pages-task-components-TelegramButton-ErrorPopup-_styles__sliding-appearance 75ms ease-out}.src-client-modules-activity-pages-task-components-TelegramButton-ErrorPopup-_styles__content{display:grid;grid-auto-flow:row;grid-auto-rows:max-content;grid-row-gap:25px;margin:0 24px 24px}.src-client-modules-activity-pages-task-components-TelegramButton-ErrorPopup-_styles__headerWrapper{margin:24px 24px 20px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/components/TelegramButton/ErrorPopup/styles.scss"],"names":[],"mappings":"AAAA,mHACI,GACI,SAAU,CACV,0BAA2B,CAG/B,KACI,SAAU,CACV,yBAA0B,CAAA,CAIlC,2FACI,WAAY,CAEZ,wBAAyB,CACzB,qCAAyC,CACzC,wBAAyB,CACzB,iBAAkB,CAClB,aAAc,CAEd,+HAA2C,CAC9C,8FAGG,YAAa,CACb,kBAAmB,CACnB,0BAA2B,CAC3B,iBAAkB,CAClB,kBAAmB,CACtB,oGAGG,qBAAsB","sourcesContent":["@keyframes sliding-appearance {\n    0% {\n        opacity: 0;\n        transform: translateY(-5px);\n    }\n\n    100% {\n        opacity: 1;\n        transform: translateY(0px);\n    }\n}\n\n.root {\n    width: 440px;\n\n    background-color: #ffffff;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    border: 1px solid #ffffff;\n    border-radius: 8px;\n    color: #000000;\n\n    animation: sliding-appearance 75ms ease-out;\n}\n\n.content {\n    display: grid;\n    grid-auto-flow: row;\n    grid-auto-rows: max-content;\n    grid-row-gap: 25px;\n    margin: 0 24px 24px;\n}\n\n.headerWrapper {\n    margin: 24px 24px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-components-TelegramButton-ErrorPopup-_styles__root",
	"sliding-appearance": "src-client-modules-activity-pages-task-components-TelegramButton-ErrorPopup-_styles__sliding-appearance",
	"content": "src-client-modules-activity-pages-task-components-TelegramButton-ErrorPopup-_styles__content",
	"headerWrapper": "src-client-modules-activity-pages-task-components-TelegramButton-ErrorPopup-_styles__headerWrapper"
};
export default ___CSS_LOADER_EXPORT___;
