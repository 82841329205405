import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import * as url from 'url';
import * as querystring from 'querystring';

import type { Activity } from '@mrm/activity';
import type { Position, Direction } from '@store/calendar/types';
import { VerticalDirection, HorizontalDirection } from '@store/calendar/types';

import { Chart } from './Chart';
import { DateFilter } from './DateFilter';
import type { StoreState } from '@store';
import { setExpandedGroupsIds, setDisabledUploadPdfButton } from '@store/calendar/actions';
import { getCalendarPageState, getPageData, getFilters } from '@store/calendar/selectors';
import { getLoginUser } from '@store/user/selector';
import { GroupsFilter } from './modules';

const HEADER_HEIGHT = 48;
const CHART_PADDING_TOP = 170;
const ORGANIZATION_NAME_HEIGHT = 40;
const GROUP_NAME_HEIGHT = 44;
const LINE_HEIGHT = 32;
const GROUP_PADDING_BOTTOM = 6;
const GROUP_MARGIN_BOTTOM = 8;
const INITIAL_SCROLL_DELAY = 300;

interface Props extends Partial<MapProps>, Partial<DispatchProps> {}

interface MapProps {
    hoveredItemId: React.ReactText;
    activities: Activity[];
    displayOrganizationNames: boolean;
}

interface DispatchProps {
    setExpandedGroupsIds: (groupIds: string[]) => void;
    setDisabledUploadPdfButton: (isDisabled: boolean) => void;
}

interface State {
    tooltipPosition: Position;
    tooltipDirection: Direction;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class ChartContainer extends React.PureComponent<Props, State> {
    private dateFilter: DateFilter;
    private groupsFilter: GroupsFilter;

    constructor(props: Props) {
        super(props);

        this.state = {
            tooltipPosition: null,
            tooltipDirection: null,
        };

        this.groupsFilter = GroupsFilter.getInstance();
    }

    public componentDidMount() {
        const query = this.getUrlQuery();

        if (query.activityId) {
            const activityId = Number(query.activityId);

            const activityIdIsValid = this.checkActivityExistence(activityId);

            if (activityIdIsValid) {
                this.showActivityById(activityId);
            }
        }

        window.addEventListener('mousemove', this.onMouseMove);
        this.groupsFilter.register('chart', this.onGroupFilterUpdate);
        this.updateDisabledUploadPdfButton();
    }

    public componentWillUnmount() {
        window.removeEventListener('mousemove', this.onMouseMove);

        this.props.setDisabledUploadPdfButton(true);
        this.groupsFilter.dispose();
    }

    public render(): JSX.Element {
        return React.createElement(Chart, {
            tooltipPosition: this.state.tooltipPosition,
            tooltipDirection: this.state.tooltipDirection,
            hoveredLineId: this.props.hoveredItemId,
            dateFilterRef: this.dateFilterRef,
        });
    }

    @autobind
    protected dateFilterRef(component: DateFilter) {
        if (component) {
            this.dateFilter = component;
        }
    }

    @autobind
    protected onMouseMove(event: MouseEvent) {
        const { hoveredItemId } = this.props;

        if (hoveredItemId) {
            const { clientX, clientY } = event;

            const cursorPosition = {
                x: clientX,
                y: clientY,
            };

            this.setState({
                tooltipPosition: cursorPosition,
                tooltipDirection: this.makeTooptipDirection(cursorPosition),
            });
        } else if (this.state.tooltipPosition) {
            this.setState({
                tooltipPosition: null,
                tooltipDirection: null,
            });
        }
    }

    @autobind
    protected onGroupFilterUpdate(): void {
        this.updateDisabledUploadPdfButton();
    }

    protected updateDisabledUploadPdfButton() {
        const organizationGroup = this.groupsFilter.getFilteredGroups();
        const haveExpandedGroups = organizationGroup.some((organizationGroup) =>
            organizationGroup.groups.some((group) => group.isExpanded),
        );

        this.props.setDisabledUploadPdfButton(!haveExpandedGroups);
    }

    private makeTooptipDirection(cursorPosition: Position): Direction {
        let horizontalDirection: HorizontalDirection;

        if (cursorPosition.x < window.innerWidth * 0.35) {
            horizontalDirection = HorizontalDirection.Right;
        } else if (cursorPosition.x > window.innerWidth * 0.65) {
            horizontalDirection = HorizontalDirection.Left;
        } else {
            horizontalDirection = HorizontalDirection.Center;
        }

        return {
            horizontal: horizontalDirection,
            vertical: cursorPosition.y > window.innerHeight / 2 ? VerticalDirection.Up : VerticalDirection.Down,
        };
    }

    private showActivityById(id: number) {
        const calendarGroupId = this.getCalendarGroupIdByActivityId(id);
        this.props.setExpandedGroupsIds([calendarGroupId]);

        const activityStartYear = this.getActivityStartYear(id);
        this.dateFilter.setYear(activityStartYear);

        setTimeout(() => {
            const newVerticalScroll = this.getActivityVerticalPositionById(id);
            this.setVerticalScroll(newVerticalScroll);
        }, INITIAL_SCROLL_DELAY);
    }

    private checkActivityExistence(id: number): boolean {
        return this.props.activities.some((item) => item.id == id);
    }

    private getCalendarGroupIdByActivityId(activityId: number): string {
        const { activities } = this.props;

        const activity = activities.find((item) => item.id == activityId);

        return activity.calendarGroupId;
    }

    private getActivityStartYear(activityId: number): number {
        const activity = this.props.activities.find((item) => item.id == activityId);

        const startDate = activity.preparationDate || activity.realizationStart;

        return new Date(startDate).getFullYear();
    }

    private getActivityVerticalPositionById(activityId: number): number {
        const { displayOrganizationNames } = this.props;

        const organizationGroups = this.groupsFilter.getFilteredGroups();

        let heightSum = CHART_PADDING_TOP;

        if (displayOrganizationNames) {
            heightSum += ORGANIZATION_NAME_HEIGHT;
        }

        let foundGroupIndex = -1;
        let foundLineIndex = -1;

        const foundOrganizationIndex = lodash.findIndex(organizationGroups, (organizationGroup) => {
            foundGroupIndex = lodash.findIndex(organizationGroup.groups, (group) => {
                foundLineIndex = lodash.findIndex(group.lines, (line) => line.id === activityId);

                return foundLineIndex !== -1;
            });

            return foundGroupIndex !== -1;
        });

        lodash.times(foundOrganizationIndex, (organizationIndex) => {
            if (displayOrganizationNames) {
                heightSum += ORGANIZATION_NAME_HEIGHT;
            }

            const groups = organizationGroups[organizationIndex].groups;

            groups.forEach((group) => {
                group.lines.forEach((line, lineIndex) => {
                    heightSum += lineIndex == 0 ? GROUP_NAME_HEIGHT : LINE_HEIGHT;
                });

                if (group.lines.length > 1) {
                    heightSum += GROUP_PADDING_BOTTOM;
                }

                heightSum += GROUP_MARGIN_BOTTOM;
            });
        });

        lodash.times(foundGroupIndex, (groupIndex) => {
            const group = organizationGroups[foundOrganizationIndex].groups[groupIndex];

            group.lines.forEach((line, lineIndex) => {
                heightSum += lineIndex == 0 ? GROUP_NAME_HEIGHT : LINE_HEIGHT;
            });

            if (group.lines.length > 1) {
                heightSum += GROUP_PADDING_BOTTOM;
            }

            heightSum += GROUP_MARGIN_BOTTOM;
        });

        lodash.times(foundLineIndex, (lineIndex) => {
            heightSum += lineIndex == 0 ? GROUP_NAME_HEIGHT : LINE_HEIGHT;
        });

        return heightSum;
    }

    private setVerticalScroll(scrollY: number) {
        const pageScrollbar = document.getElementById('pageContent');

        const windowHeight = window.innerHeight;
        const viewportHeight = windowHeight - HEADER_HEIGHT;

        if (pageScrollbar) {
            pageScrollbar.scrollTop = scrollY - viewportHeight / 2;
        }
    }

    private getUrlQuery(): querystring.ParsedUrlQuery {
        const { query } = url.parse(window.location.href);

        return querystring.parse(query);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { hoveredItemId } = getCalendarPageState(state);
    const { activities } = getPageData(state);
    const { organizationIds } = getFilters(state);

    const user = getLoginUser(state);
    const userOrganizationId = user.attributes.organizationId;

    return {
        hoveredItemId,
        activities,
        displayOrganizationNames: organizationIds.length > 1 || lodash.first(organizationIds) !== userOrganizationId,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setExpandedGroupsIds,
            setDisabledUploadPdfButton,
        },
        dispatch,
    );
}
