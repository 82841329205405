import { ActivityStatus } from 'sber-marketing-types/frontend';
import { Moment } from 'moment';

import type { AvailableDepartments } from './availableDepartments';
import { FileAsset } from '../commonTypes';

export interface SaveTaskParams {
    isDraft: boolean;
    participants: number[];
}

/** Task editor current status */
export const enum TaskEditorStatus {
    /** Task is not exists - not created or not loaded yet */
    NOT_EXISTS = 'not_exists',
    /** Task loading in progress */
    LOADING = 'loading',
    /** Task loading was failed with error */
    LOADING_ERROR = 'loading_error',
    /** Task creating in progress */
    CREATING = 'creating',
    /** Task creation was failed with error */
    CREATION_ERROR = 'creation_error',
    /** Task exists - successfully loaded from server */
    EXISTS = 'exists',
    /** Existing task updating in progress */
    UPDATING = 'updating',
    /** Existing task updating failed with error */
    UPDATE_ERROR = 'update_error',
}

/** Files data (has common reducer) */
export interface FilesData {
    /** Task files */
    filesIds: string[];
    /** Files to remove */
    filesIdsToRemove: string[];
}

/** Base task data (without technical fields) */
export interface TaskData {
    /** Task identifier */
    id: string;
    /** Task title */
    title: string;
    /** Identifier of selected type of work or "null" for not selected */
    workTypeId: string | null;
    /** Selected department or "null" for not selected */
    departmentId: string | null;
    /** Task deadline date */
    deadline: Moment;
    /** Task author identifier */
    authorId: number | null;
    /** Task executor identifier */
    executorId: number | null;
    /** Task description */
    description: string;
    /** Task participants identifiers (without author and executor) */
    participantsIds: number[];
    /** Available departments */
    availableDepartments: AvailableDepartments;
    /** Files data */
    filesData: FilesData;
    /* Task status */
    status?: string;
    stageId: string;
}

/** "REPLACE_FILES" action payload */
export interface ReplaceFilesPayload {
    /** Index of file to replace */
    index: number;
    /** File data to replace */
    value: FileAsset;
}

/** Task's activity data */
export interface ActivityData {
    /** Activity identifier or "null" for not loaded */
    activityId: number | null;
    /** Activity status */
    activityStatus: ActivityStatus | null;
    /** Activity starting date */
    activityStartDate: Moment;
    /** Activity ending date */
    activityEndDate: Moment;
}

/** Task editor technical fields */
export interface TaskEditorTechnicalData {
    /** Current status of task editor */
    editorStatus: TaskEditorStatus;
    /**
     * Editor error (for "TaskEditorStatus.LOADING_ERROR", "TaskEditorStatus.CREATION_ERROR"
     * or "TaskEditorStatus.UPDATE_ERROR" statuses). Only one error at time can be stored.
     */
    editorError: Error | null;
    showPreloader: boolean;
}

/** Task editor state */
export interface TaskEditor extends TaskData, ActivityData, TaskEditorTechnicalData {}
