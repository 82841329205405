import * as React from 'react';
import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import { ComponentState, getBudgetTransferMenuState } from '@store/budgetExecution/budgetTransferMenu';

import * as styles from './Title.scss';

export function Title(): JSX.Element {
    const componentState = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.componentState,
    );

    let content: string;
    if (componentState === ComponentState.InternalTransferSumEntering) {
        content = 'Перенос бюджета внутри таблицы';
    } else if (componentState === ComponentState.InternalTransferNegativeLineBalanceWarning) {
        content = 'Внимание!';
    } else {
        content = 'Перенос бюджета';
    }

    return <div className={styles.root}>{content}</div>;
}
