import * as React from 'react';
import autobind from 'autobind-decorator';
import { ActivityType, ProductParams, DepartmentAttributes } from 'sber-marketing-types/frontend';
import {
    GetDashboardActivitiesFiltersParams,
    GetDashboardActivitiesFiltersResponse,
    UserResponseParams,
} from 'sber-marketing-types/backend';

import { FilterApi } from '@api';

import { ActivitiesListType } from '@store/activitiesList';
import { ActivitiesFilter, ActivityStatus } from '@store/userConfig';

import { ActivityListFilters } from './ActivityListFilters';

interface Props extends OwnProps, MapProps, DispatchProps {}

interface OwnProps {
    type?: ActivitiesListType;
}

export interface MapProps {
    activityTypes: ActivityType[];
    products: ProductParams[];
    authors: UserResponseParams[];
    responsibles: UserResponseParams[];
    responsibleDepartments: DepartmentAttributes[];
    isLoading: boolean;
    shouldMergeUserConfig: boolean;
    filters: ActivitiesFilter;
    loadFiltersParams?: GetDashboardActivitiesFiltersParams;
}

export interface DispatchProps {
    loadEntitiesForFilters: (filters: GetDashboardActivitiesFiltersResponse) => void;
    setFilters: (payload: Partial<ActivitiesFilter>) => void;
    resetEntitiesStores: () => void;
    mergeUserConfig: () => void;
    save: (payload: Partial<ActivitiesFilter>) => void;
}

export class ActivityListFiltersContainer extends React.PureComponent<Props> {
    public async componentDidMount(): Promise<void> {
        const filters = await FilterApi.getDashboardActivitiesFilters(this.props.loadFiltersParams);

        this.props.loadEntitiesForFilters(filters);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Props): void {
        if (!this.props.shouldMergeUserConfig && nextProps.shouldMergeUserConfig) {
            this.props.mergeUserConfig();
        }
    }

    public componentWillUnmount(): void {
        this.props.resetEntitiesStores();
    }

    public render() {
        const { isLoading, filters, activityTypes, products, authors, responsibles, responsibleDepartments } =
            this.props;

        return (
            <ActivityListFilters
                isLoading={isLoading}
                filters={filters}
                activityTypes={activityTypes}
                products={products}
                authors={authors}
                responsibles={responsibles}
                responsibleDepartments={responsibleDepartments}
                onSave={this.onSave}
                onStatusSelection={this.onStatusSelection}
                onActivityTypeSelect={this.onActivityTypeSelect}
                onProductSelect={this.onProductsSelect}
                onAuthorSelect={this.onAuthorSelect}
                onResponsibleSelect={this.onResponsibleSelect}
                onResponsibleDepartmentSelect={this.onResponsibleDepartmentselect}
            />
        );
    }

    @autobind
    private onStatusSelection(status: ActivityStatus): void {
        this.props.setFilters({
            status,
        });
    }

    @autobind
    private onActivityTypeSelect(activityType: string[]): void {
        this.props.setFilters({
            activityType,
        });
    }

    @autobind
    private onProductsSelect(product: string[]): void {
        this.props.setFilters({
            product,
        });
    }

    @autobind
    private onAuthorSelect(author: number[]): void {
        this.props.setFilters({
            author,
        });
    }

    @autobind
    private onResponsibleSelect(responsible: number[]): void {
        this.props.setFilters({
            responsible,
        });
    }

    @autobind
    private onResponsibleDepartmentselect(responsibleDepartment: string[]): void {
        this.props.setFilters({
            responsibleDepartment,
        });
    }

    @autobind
    private onSave(): void {
        this.props.save(this.props.filters);
    }
}
