import * as React from 'react';

import { useMode } from './useMode';

import { useStore } from './useStore';
import type { StatisticsItem } from './useStore';

interface UseBehavior {
    (): UseBehaviorReturn;
}

interface UseBehaviorReturn {
    loading: boolean;
    statisticsItems: StatisticsItem[];
}

export const useBehavior: UseBehavior = () => {
    const { mode } = useMode();
    const { loading, initLoadData, statisticsItems } = useStore();

    React.useEffect(() => {
        initLoadData({ mode });
    }, [mode]);

    return {
        loading,
        statisticsItems,
    };
};
