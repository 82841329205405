// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilotRadio-StageWidgets-MediaplanSettingsStageWidget-RadioSplit-CellTypes-TextCell-_TextCell__root{height:100%;width:100%;display:flex;align-items:center;background-color:#ffffff;padding:0 6px;color:#000000;font-family:'Open Sans';font-size:12px;line-height:16px;user-select:none}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilotRadio/StageWidgets/MediaplanSettingsStageWidget/RadioSplit/CellTypes/TextCell/TextCell.scss"],"names":[],"mappings":"AAAA,0IACI,WAAY,CACZ,UAAW,CAEX,YAAa,CACb,kBAAmB,CACnB,wBAAyB,CAEzB,aAAc,CAEd,aAAc,CACd,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACjB,gBAAiB","sourcesContent":[".root {\n    height: 100%;\n    width: 100%;\n\n    display: flex;\n    align-items: center;\n    background-color: #ffffff;\n\n    padding: 0 6px;\n\n    color: #000000;\n    font-family: 'Open Sans';\n    font-size: 12px;\n    line-height: 16px;\n    user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilotRadio-StageWidgets-MediaplanSettingsStageWidget-RadioSplit-CellTypes-TextCell-_TextCell__root"
};
export default ___CSS_LOADER_EXPORT___;
