import { castArray, property } from 'lodash';
import { Dispatch as OriginDispatch } from 'redux';

import { StoreState } from '../../';
import { add } from '../../fileAssets';
import { FileAsset } from '../../commonTypes';
import { ReplaceFilesPayload } from '../types';
import {
    addFiles as addFilesAction,
    reloadFiles as reloadFilesAction,
    replaceFiles as replaceFilesAction,
} from '../actions';

type Dispatch = OriginDispatch<StoreState>;

export const addFiles =
    (payload: FileAsset | FileAsset[]): any =>
    (dispatch: Dispatch) => {
        dispatch(add(...castArray(payload)));
        dispatch(addFilesAction(payload));
    };

export const reloadFiles =
    (payload: FileAsset | FileAsset[]): any =>
    (dispatch: Dispatch) => {
        dispatch(add(...castArray(payload)));
        dispatch(reloadFilesAction(payload));
    };

export const replaceFiles =
    (payload: ReplaceFilesPayload | ReplaceFilesPayload[]): any =>
    (dispatch: Dispatch) => {
        const list = castArray(payload).map(property<ReplaceFilesPayload, FileAsset>('value'));
        dispatch(add(...list));
        dispatch(replaceFilesAction(payload));
    };
