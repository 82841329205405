import * as React from 'react';

import * as style from './ContextMenu.scss';

import { BodyPortal } from 'sber-marketing-ui';

interface Props {
    items: MenuItemProps[];
}

interface MenuItemProps {
    title: string;
    description?: string;
    onClick: () => void;
}

export const ContextMenu = ({ items }: Props): JSX.Element => {
    const [clickCoords, setClickCoords] = React.useState<{ top: number; left: number }>(null);

    const rootRef = React.useRef<HTMLDivElement>();
    const menuRef = React.useRef<HTMLDivElement>();

    React.useEffect(() => {
        if (rootRef.current?.parentNode.addEventListener) {
            rootRef.current.parentNode.addEventListener('mouseenter', onMouseEnter);
            rootRef.current.parentNode.addEventListener('mouseleave', onMouseLeave);
        }
    }, [rootRef.current]);

    function onMouseEnter() {
        rootRef.current?.parentNode.addEventListener('contextmenu', onContextMenu);
    }

    function onMouseLeave() {
        rootRef.current?.parentNode.removeEventListener('contextmenu', onContextMenu);
    }

    function onContextMenu(event: MouseEvent) {
        event.preventDefault();

        openMenu({
            top: event.clientY,
            left: event.clientX,
        });
    }

    function onPageScroll() {
        closeMenu();
    }

    function onMaskClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        event.preventDefault();
        event.stopPropagation();

        closeMenu();
    }

    function onMenuClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        event.preventDefault();
        event.stopPropagation();
    }

    function onMenuItemClick(itemProps: MenuItemProps) {
        itemProps.onClick();
        closeMenu();
    }

    function openMenu(clickCoords: { top: number; left: number }) {
        setClickCoords(clickCoords);
        document.addEventListener('scroll', onPageScroll, true);
    }

    function closeMenu() {
        setClickCoords(null);
        document.removeEventListener('scroll', onPageScroll, true);
    }

    const displayDescription = items.some((item) => item.description);

    return (
        <div className={style.root} ref={rootRef} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {clickCoords && (
                <BodyPortal>
                    <div className={style.mask} onClick={onMaskClick} onContextMenu={onMaskClick} />

                    <div
                        ref={menuRef}
                        className={style.menu}
                        style={{ top: clickCoords.top, left: clickCoords.left }}
                        onClick={onMenuClick}
                        onContextMenu={onMenuClick}
                    >
                        <table className={style.menuItems}>
                            <tbody>
                                {items.map((item) => (
                                    <tr
                                        key={`${item.title} ${item.description}`}
                                        className={style.menuItem}
                                        onClick={() => onMenuItemClick(item)}
                                    >
                                        <td className={style.menuItemTitle}>{item.title}</td>

                                        {displayDescription && (
                                            <td className={style.menuItemDescription}>{item.description}</td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </BodyPortal>
            )}
        </div>
    );
};
