// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-components-TaskCommentForm-_TaskCommentForm__root{display:flex;flex-direction:column;align-items:flex-end;justify-content:flex-start}.src-client-modules-activity-pages-task-components-TaskCommentForm-_TaskCommentForm__form,.src-client-modules-activity-pages-task-components-TaskCommentForm-_TaskCommentForm__assets{width:100%}.src-client-modules-activity-pages-task-components-TaskCommentForm-_TaskCommentForm__form{display:flex;align-items:flex-end;justify-content:space-between}.src-client-modules-activity-pages-task-components-TaskCommentForm-_TaskCommentForm__assets{margin-top:43px}.src-client-modules-activity-pages-task-components-TaskCommentForm-_TaskCommentForm__textArea{width:calc(100% - 161px)}.src-client-modules-activity-pages-task-components-TaskCommentForm-_TaskCommentForm__buttonWrap{width:105px;height:40px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/components/TaskCommentForm/TaskCommentForm.scss"],"names":[],"mappings":"AAAA,0FACI,YAAa,CACb,qBAAsB,CAEtB,oBAAqB,CACrB,0BAA2B,CAC9B,sLAIG,UAAW,CACd,0FAGG,YAAa,CAEb,oBAAqB,CACrB,6BAA8B,CACjC,4FAGG,eAAgB,CACnB,8FAGG,wBAAyB,CAC5B,gGAGG,WAAY,CACZ,WAAY","sourcesContent":[".root {\n    display: flex;\n    flex-direction: column;\n\n    align-items: flex-end;\n    justify-content: flex-start;\n}\n\n.form,\n.assets {\n    width: 100%;\n}\n\n.form {\n    display: flex;\n\n    align-items: flex-end;\n    justify-content: space-between;\n}\n\n.assets {\n    margin-top: 43px;\n}\n\n.textArea {\n    width: calc(100% - 161px);\n}\n\n.buttonWrap {\n    width: 105px;\n    height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-components-TaskCommentForm-_TaskCommentForm__root",
	"form": "src-client-modules-activity-pages-task-components-TaskCommentForm-_TaskCommentForm__form",
	"assets": "src-client-modules-activity-pages-task-components-TaskCommentForm-_TaskCommentForm__assets",
	"textArea": "src-client-modules-activity-pages-task-components-TaskCommentForm-_TaskCommentForm__textArea",
	"buttonWrap": "src-client-modules-activity-pages-task-components-TaskCommentForm-_TaskCommentForm__buttonWrap"
};
export default ___CSS_LOADER_EXPORT___;
