import * as React from 'react';
import classNames from 'classnames';
import * as styles from './styles.scss';

import { Direction, Result } from '../enums';
import { valueStylesByResult } from './utils';
import { Arrow } from './Arrow';

export interface Props {
    direction: Direction;
    result: Result;
    value: number;
}

export const Value: React.FC<Props> = ({ direction, result, value }) => {
    return (
        <div className={classNames(styles.root, valueStylesByResult[result])}>
            <Arrow direction={direction} />
            {value}%
        </div>
    );
};
