import * as React from 'react';
import { connect } from 'react-redux';
import * as lodash from 'lodash';

import type { UserCard as User } from 'sber-marketing-types/frontend';

import { WithAutopilotComments } from './WithAutopilotComments';
import { CommentsBlock } from '@common/CommentsBlock';
import type { StoreState } from '@store';
import { getAllUsers } from '@store/appUsers/selectors';
import { getUserOrganizationsIds } from '@store/userOrganizations/selectors';

interface Props extends MapProps {
    autopilotId: string;
}

interface MapProps {
    mentionableUsers?: User[];
}

@(connect(mapStateToProps) as any)
export class CommentsWidget extends React.PureComponent<Props> {
    public render() {
        const { autopilotId, mentionableUsers } = this.props;

        return (
            <WithAutopilotComments autopilotId={autopilotId}>
                {({ comments, createComment, loading }) => (
                    <CommentsBlock
                        comments={comments}
                        loading={loading}
                        elKey={null}
                        disableControls={false}
                        mentionableUsers={mentionableUsers}
                        createComment={createComment}
                    />
                )}
            </WithAutopilotComments>
        );
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const appUsers = getAllUsers(state);
    const userOrganizationsIds = getUserOrganizationsIds(state);

    return {
        mentionableUsers: appUsers.filter(
            (user) => lodash.includes(userOrganizationsIds, user.organizationId) && user.isActive,
        ),
    };
}
