import { actionCreatorFactory } from 'typescript-fsa';
import {
    SetFiltersPayload,
    LoadPagePayload,
    LoadPageDone,
    StatisticsTypeFilters,
    SetFilterPeriodPayload,
    SetFilterPeriodResult,
    SetFilterDatePayload,
    SetFilterDateResult,
    SetFilterByPayload,
    SetFilterByResult,
} from './types';

const actionCreator = actionCreatorFactory('LEADER_DASHBOARD_PAGE');

const loadPageAsync = actionCreator.async<LoadPagePayload, LoadPageDone, Error>('LOAD_PAGE_ASYNC');

const setFilterPeriodAsync = actionCreator.async<SetFilterPeriodPayload, SetFilterPeriodResult, Error>(
    'SET_FILTER_PERIOD_ASYNC',
);
const setFilterDateAsync = actionCreator.async<SetFilterDatePayload, SetFilterDateResult, Error>(
    'SET_FILTER_DATE_ASYNC',
);
const setFilterByAsync = actionCreator.async<SetFilterByPayload, SetFilterByResult, Error>('SET_FILTER_BY_ASYNC');

const setSelectedStatisticsItemId = actionCreator<string>('SET_SELECTED_STATISTICS_ITEM_ID');
const setFilters = actionCreator<SetFiltersPayload>('SET_FILTERS');
const setStatisticsTypeFilters = actionCreator<StatisticsTypeFilters>('SET_STATISTICS_TYPE_FILTERS');
const resetPageStore = actionCreator('RESET_PAGE_STORE');

export {
    loadPageAsync,
    resetPageStore,
    setSelectedStatisticsItemId,
    setFilters,
    setFilterPeriodAsync,
    setFilterDateAsync,
    setFilterByAsync,
    setStatisticsTypeFilters,
};
