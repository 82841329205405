import * as React from 'react';

import type { ChangedParam } from '../common';
import { ChangedParamList, CorrectionCardHeader, CountChanges, RejectComment, WithTitle } from '../common';

import { CorrectionStatus } from '@mrm/budget';
import * as style from './ActivityCorrectionCard.scss';

export type { ChangedParam };

const STATUS_COLOR = {
    [CorrectionStatus.NeedApproving]: '#f5a623',
    [CorrectionStatus.Approved]: '#19bb4f',
    [CorrectionStatus.Rejected]: '#e63901',
};

interface Props {
    serialNumber: number;
    authorName: string;
    approverName: string;
    activityName: string;
    creationDate: string;
    changedParams: ChangedParam[];
    status: CorrectionStatus;
    rejectComment: string;
}

export const ActivityCorrectionCard = ({
    serialNumber,
    authorName,
    approverName,
    activityName,
    creationDate,
    changedParams,
    status,
    rejectComment,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <CorrectionCardHeader
                title={'Корректировка параметров активности'}
                serialNumber={serialNumber}
                authorName={authorName}
                approverName={approverName}
                creationDate={creationDate}
                status={status}
            />

            {rejectComment && <RejectComment text={rejectComment} />}

            <div className={style.participators}>
                <div
                    className={style.participatorsRow}
                    {...{
                        'qa-id': 'correctionCardParticipatorsInfo',
                    }}
                >
                    <div
                        className={style.participatorsCell}
                        {...{
                            'qa-id': 'correctionCardParticipatorLeftColumn',
                        }}
                    >
                        <ActivitySubInfo title={'Название'} activityName={activityName} />
                    </div>
                    <div
                        className={style.participatorsCell}
                        {...{
                            'qa-id': 'correctionCardParticipatorRightColumn',
                        }}
                    >
                        <CountChangesSubInfo count={changedParams.length} />
                    </div>
                </div>
                <div
                    className={style.participatorsRow}
                    {...{
                        'qa-id': 'correctionCardCorrectionParams',
                    }}
                >
                    <div
                        className={style.participatorsCell}
                        {...{
                            'qa-id': 'correctionCardCorrectionParamsLeftColumn',
                        }}
                    ></div>
                    <div
                        className={style.participatorsCell}
                        {...{
                            'qa-id': 'correctionCardCorrectionParamsRightColumn',
                        }}
                    >
                        <div className={style.subInfoContent}>
                            <ChangedParamList params={changedParams} />
                        </div>
                    </div>
                </div>
            </div>

            <div className={style.status} style={{ backgroundColor: STATUS_COLOR[status] }} />
        </div>
    );
};

interface ActivitySubInfoProps {
    title: string;
    activityName: string;
}

const ActivitySubInfo: React.FC<ActivitySubInfoProps> = (data) => (
    <div className={style.subInfo}>
        <WithTitle title={data.title}>{data.activityName}</WithTitle>
    </div>
);

const CountChangesSubInfo: React.FC<{ count: number }> = ({ count }) => (
    <div className={style.subInfo}>
        <div className={style.countChangesWrapper}>
            <CountChanges count={count} />
        </div>
    </div>
);
