// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-layout-Typography-_Typography__root ul{display:flex;flex-direction:column;gap:16px}.src-client-modules-common-components-layout-Typography-_Typography__root ul li{display:flex;gap:8px;align-items:baseline}.src-client-modules-common-components-layout-Typography-_Typography__root ul li:before{content:'';display:inline-block;width:6px;min-width:6px;height:6px;min-height:6px;border-radius:3px;background:var(--color-green-50)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/layout/Typography/Typography.scss"],"names":[],"mappings":"AAAA,6EAEI,YAAa,CACb,qBAAsB,CACtB,QAAS,CAJb,gFAOM,YAAa,CACb,OAAQ,CACR,oBAAqB,CAT3B,uFAYQ,UAAW,CACX,oBAAqB,CACrB,SAAU,CACV,aAAc,CACd,UAAW,CACX,cAAe,CACf,iBAAkB,CAClB,gCAAiC","sourcesContent":[".root {\n  ul {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n\n    li {\n      display: flex;\n      gap: 8px;\n      align-items: baseline;\n\n      &:before {\n        content: '';\n        display: inline-block;\n        width: 6px;\n        min-width: 6px;\n        height: 6px;\n        min-height: 6px;\n        border-radius: 3px;\n        background: var(--color-green-50);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-components-layout-Typography-_Typography__root"
};
export default ___CSS_LOADER_EXPORT___;
