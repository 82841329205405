import * as React from 'react';
import { includes } from 'lodash';
import classNames from 'classnames';
import * as style from './Header.scss';

import { TabBarContext } from '../TabBarContext';

interface Props {
    disableTabsIndexes?: number[];
}

export const Header: React.FC<Props> = ({ children, disableTabsIndexes }) => {
    return (
        <TabBarContext.Consumer>
            {({ selectedTabIndex, changeSelectedTabIndex }) => (
                <div className={style.root}>
                    {React.Children.map(children, (child, index) => {
                        const isDisabledTab = includes(disableTabsIndexes, index);

                        return (
                            <div
                                className={classNames(
                                    style.tab,
                                    index === selectedTabIndex && style.selected,
                                    isDisabledTab && style.disabled,
                                )}
                                onClick={() => {
                                    if (!isDisabledTab) {
                                        changeSelectedTabIndex(index);
                                    }
                                }}
                            >
                                {child}
                            </div>
                        );
                    })}
                </div>
            )}
        </TabBarContext.Consumer>
    );
};
