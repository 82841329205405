import { TaskTelegramStatusResponse } from 'sber-marketing-types/frontend';
import { TelegramInfo, TelegramUserStatus } from './types';

export const convertTelegramStatusResponseToTelegramInfo = (
    telegramResponse: TaskTelegramStatusResponse,
): TelegramInfo => {
    return {
        hasUserAccessToChat: telegramResponse?.enabled,
        isUserCreatedChat: telegramResponse?.task?.userConnected || false,
        // @ts-ignore
        isUserAuthorized: telegramResponse?.user?.status === TelegramUserStatus.Connected,
        fetchError: null,
        loading: false,
        linkToAuthorization: null,
    };
};
