import { Funds, Month } from '@mrm/budget';
import { DictionaryStatus } from '@mrm/dictionary';

export const enum FilterType {
    UserRole = 'userRole',
    CardsLoadingMode = 'cardsLoadingMode',
}

export interface Card {
    id: string;
    status: 'pending' | 'approved' | 'rejected' | 'deleted';
    permissions: {
        read: boolean;
        delete: boolean;
    };
    author: {
        id: number;
        firstName: string;
        secondName: string;
    };
    project: {
        id: number;
        name: string;
        permissions: {
            read: boolean;
        };
    };
    budgetItem: {
        id: string;
        serialNumber: number;
        plannedFunds: Funds;
        reservedFunds: Funds;
        permissions: {
            read: boolean;
        };
        budget: {
            id: string;
        };
        dictionary: {
            tool: {
                status: DictionaryStatus;
                value: string;
            };
        };
    };
    createdAt: string;
}

export const Months = [
    Month.Jan,
    Month.Feb,
    Month.Mar,
    Month.Apr,
    Month.May,
    Month.Jun,
    Month.Jul,
    Month.Aug,
    Month.Sept,
    Month.Oct,
    Month.Nov,
    Month.Dec,
];
