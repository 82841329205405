import { tabBarActivityConfig } from './tabBarActivityConfig';
import { tabBarTaskConfig } from './tabBarTaskConfig';

import type { TabBarConfigByMode, TabBarConfig } from './types';

const tabBarConfigByMode: TabBarConfigByMode = {
    activities: tabBarActivityConfig,
    tasks: tabBarTaskConfig,
};

export { tabBarConfigByMode };

export type { TabBarConfig };
