import * as React from 'react';

import type { WorkType } from 'sber-marketing-types/frontend';

import { useWorkTypeOptions, UseWorkTypeOptions } from '@modules/workType/hooks';

import { OptionMultiple, OptionProps, OptionsChip, OptionsChipProps } from '@common/components';

export type IWorkTypeOptionsProps<M extends OptionMultiple> = Omit<
    OptionsChipProps<M, OptionProps<WorkType.Data>, WorkType.Data, 'id', string, M extends true ? string[] : string>,
    'options' | 'optionId'
>;

export interface WorkTypesChipProps<M extends OptionMultiple>
    extends Omit<IWorkTypeOptionsProps<M>, 'options' | 'optionId'>,
        UseWorkTypeOptions {
    optionsMap?: (options: OptionProps<WorkType.Data>[]) => OptionProps<WorkType.Data>[];
}

export function WorkTypesChip<M extends OptionMultiple>({
    ids,
    sortingOrder,
    embed,
    organizationId,
    isArchived,
    enable_rates,
    departmentIds,
    userId,
    loading,
    useUsers,
    optionsMap,
    ...props
}: WorkTypesChipProps<M>) {
    const params: Required<UseWorkTypeOptions> = {
        ids,
        sortingOrder,
        embed,
        organizationId,
        isArchived,
        enable_rates,
        departmentIds,
        userId,
        useUsers,
    };

    const [options, isLoading] = useWorkTypeOptions(params);

    return (
        <OptionsChip
            {...props}
            loading={loading || isLoading}
            valueLoading={loading || isLoading}
            optionId="id"
            options={optionsMap?.(options) || options}
        />
    );
}
