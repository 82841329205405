import * as React from 'react';
import { connect } from 'react-redux';
import { BudgetItem } from '@mrm/budget';

import { StoreState } from '@store';
import { getTableLines, getBudgetItemByLineId, TableLine, ColumnName, NumericCellValue } from '@store/budgetPlanning';

import { BudgetWidget } from './BudgetWidget';
import { Money, MoneyFormatter } from '@common/Utils';

interface Props extends Partial<MapProps> {
    lineId: string;
    sourceBudgetItem: BudgetItem;
}

interface MapProps {
    line: TableLine;
    planPreviousPeriod: number;
    factPreviousPeriod: number;
}

@(connect(mapStateToProps) as any)
export class BudgetWidgetContainer extends React.Component<Props> {
    public render() {
        const { planPreviousPeriod, factPreviousPeriod, sourceBudgetItem } = this.props;

        return React.createElement(BudgetWidget, {
            planSum: this.getPlanCurrency(),
            planPreviousPeriod,
            factPreviousPeriod,
            sourceBudgetItem,
        });
    }

    private getPlanCurrency(): string {
        return this.getFormattedCurrencyByColumnName(ColumnName.TotalPlan);
    }

    private getFormattedCurrencyByColumnName(columnName: ColumnName): string {
        return this.formatCurrency(this.getCurrencyByColumnName(columnName));
    }

    private getCurrencyByColumnName(columnName: ColumnName): number {
        const {
            line: { fields },
        } = this.props;
        return (fields[columnName] as NumericCellValue).number;
    }

    private formatCurrency(value: number): string {
        const valueMoney = Money.fromCopecks(value);
        return MoneyFormatter.toThousands(valueMoney, { hideCaption: true });
    }
}

function mapStateToProps(store: StoreState, props: Props): MapProps {
    const { lineId } = props;
    const { planPreviousPeriod, factPreviousPeriod } = getBudgetItemByLineId(store, props.lineId);

    return {
        planPreviousPeriod,
        factPreviousPeriod,
        line: getTableLines(store).find((line) => line.id === lineId),
    };
}
