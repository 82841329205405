import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';
import * as style from './Transition.scss';

interface Props {
    from: React.ReactText | React.ReactElement;
    to: React.ReactText | React.ReactElement;
}

export const Transition: React.FC<Props> = ({ from, to }) => {
    return (
        <div className={style.root}>
            {from}&nbsp;
            <div className={classNames(style.item, style.arrow)}>
                <Icon type={IconType.TRANSITION_ARROW} />
            </div>
            &nbsp;{to}
        </div>
    );
};
