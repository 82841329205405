import * as React from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import {
    Popup,
    WithTooltip,
    Icon,
    IconType,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    CustomScrollbar_new as CustomScrollbar,
    CloseButton,
} from 'sber-marketing-ui';

import { StoreState } from '@store';
import {
    ArchiveContentPopupComponentState,
    getActivityDAMArchiveState,
    getTotalFiles,
    setArchiveContentPopupState,
    setRenameFilesWithSameName,
    exportFiles,
    getArchiveFiles,
} from '@store/activityFilesDAMArchive';

import { EditorMode, ViewContentMode } from './TaskFiles';

import * as styles from './ArchiveContentPopup.scss';

function useArchiveContentPopup() {
    const dispatch = useDispatch();

    const shouldRender = useSelector((state: StoreState) => {
        const { componentState } = getActivityDAMArchiveState(state).archiveContentPopup;

        return (
            componentState === ArchiveContentPopupComponentState.Editor ||
            componentState === ArchiveContentPopupComponentState.ViewContent
        );
    });

    function closePopup() {
        dispatch(setArchiveContentPopupState(ArchiveContentPopupComponentState.Hidden));
    }

    return { shouldRender, closePopup };
}

export function ArchiveContentPopup(): JSX.Element {
    const { shouldRender, closePopup } = useArchiveContentPopup();

    return shouldRender ? (
        <Popup onCloseIconClick={closePopup} onOutOfContentClick={closePopup}>
            <div id="testDiv" className={styles.root}>
                <Title closePopup={closePopup} />
                <RenameFilesWithSameNameToggle />
                <FilesByTasks />
                <ControlButtons closePopup={closePopup} />
            </div>
        </Popup>
    ) : null;
}

interface WithClosePopupProps {
    closePopup: () => void;
}

function Title({ closePopup }: WithClosePopupProps): JSX.Element {
    const totalFiles = useSelector((state: StoreState) => {
        const { componentState } = getActivityDAMArchiveState(state).archiveContentPopup;

        switch (componentState) {
            case ArchiveContentPopupComponentState.Editor:
                return getTotalFiles(state) - getArchiveFiles(state);
            case ArchiveContentPopupComponentState.ViewContent:
                return getArchiveFiles(state);
            default:
                return 0;
        }
    });

    const title = useSelector((state: StoreState) => {
        const { componentState } = getActivityDAMArchiveState(state).archiveContentPopup;

        switch (componentState) {
            case ArchiveContentPopupComponentState.Editor:
                return 'Выгрузить файлы в архив';
            case ArchiveContentPopupComponentState.ViewContent:
                return 'Файлы в архиве';
            default:
                return '';
        }
    });

    return (
        <div className={styles.title}>
            <span>
                {title}&nbsp;
                <span className={styles.textGrey}>{totalFiles}</span>
            </span>

            <CloseButton onClick={closePopup} />
        </div>
    );
}

function RenameFilesWithSameNameToggle(): JSX.Element {
    const dispatch = useDispatch();

    // const shouldRender = useSelector((state: StoreState) => {
    //     const { componentState } = getActivityDAMArchiveState(state).archiveContentPopup;
    //     const hasFilesWithSameName = filesToExportContainsNameDuplicates(state);
    //     const modalIsInEditorMode = componentState === ArchiveContentPopupComponentState.Editor;

    //     return modalIsInEditorMode && hasFilesWithSameName;
    // });
    const shouldRender = false;
    const renameFilesWithSameName = useSelector(
        (state: StoreState) => getActivityDAMArchiveState(state).archiveContentPopup.renameFilesWithSameName,
    );

    function onClick() {
        dispatch(setRenameFilesWithSameName(!renameFilesWithSameName));
    }

    return shouldRender ? (
        <div className={styles.hasFilesWithSameName}>
            <span>
                <Icon type={IconType.CIRCLE_INFO} svgSize={16} className={styles.hasFilesWithSameNameIcon} />
                Есть файлы с одинаковым названием
            </span>

            <div
                className={classnames(
                    styles.hasFilesWithSameNameToggle,
                    renameFilesWithSameName && styles.hasFilesWithSameNameToggleActive,
                )}
                onClick={onClick}
            >
                Переименовать автоматически
            </div>
        </div>
    ) : null;
}

function FilesByTasks(): JSX.Element {
    const tasksFiles = useSelector((state: StoreState) => getActivityDAMArchiveState(state).tasksFiles.byTaskId);
    const componentState = useSelector(
        (state: StoreState) => getActivityDAMArchiveState(state).archiveContentPopup.componentState,
    );

    return (
        <div className={styles.filesByTasks}>
            <CustomScrollbar hideScrollX maxHeight={750}>
                <div className={styles.taskFilesWrapper}>
                    {Object.keys(tasksFiles).map((taskId) => (
                        <TaskFiles key={taskId} taskId={taskId} componentState={componentState} />
                    ))}
                </div>
            </CustomScrollbar>
        </div>
    );
}

interface TaskFilesProps {
    taskId: string;
    componentState: ArchiveContentPopupComponentState;
}

function TaskFiles({ taskId, componentState }: TaskFilesProps): JSX.Element {
    switch (componentState) {
        case ArchiveContentPopupComponentState.Editor:
            return <EditorMode taskId={taskId} className={styles.taskFiles} />;
        case ArchiveContentPopupComponentState.ViewContent:
            return <ViewContentMode taskId={taskId} className={styles.taskFiles} />;
        case ArchiveContentPopupComponentState.Hidden:
        default:
            return null;
    }
}

function useControlButtons() {
    const dispatch = useDispatch();

    const componentState = useSelector(
        (state: StoreState) => getActivityDAMArchiveState(state).archiveContentPopup.componentState,
    );
    const filesToExport = useSelector(
        (state: StoreState) => getActivityDAMArchiveState(state).archiveContentPopup.filesToExport.length,
    );
    // const hasFileNameDuplicates = useSelector((state: StoreState) => filesToExportContainsNameDuplicates(state));
    const hasFileNameDuplicates = false;

    function onExportButtonClick() {
        dispatch(
            hasFileNameDuplicates
                ? setArchiveContentPopupState(ArchiveContentPopupComponentState.NameDuplicatesPopup)
                : exportFiles(null),
        );
    }

    return {
        componentState,
        filesToExport,
        onExportButtonClick,
    };
}

function ControlButtons({ closePopup }: WithClosePopupProps): JSX.Element {
    const { componentState, filesToExport, onExportButtonClick } = useControlButtons();

    switch (componentState) {
        case ArchiveContentPopupComponentState.ViewContent:
            return (
                <Button theme={ButtonTheme.GhostRounded} onClick={closePopup}>
                    <div className={styles.controlButtonsSingleButton}>Закрыть</div>
                </Button>
            );
        case ArchiveContentPopupComponentState.Editor:
            return (
                <div className={styles.controlButtonsWrapper}>
                    <div className={styles.controlButtonsDoubleButtonsWrapper}>
                        <Button theme={ButtonTheme.GhostRounded} onClick={closePopup}>
                            <div className={styles.controlButton}>Отмена</div>
                        </Button>
                    </div>

                    <div className={styles.controlButtonsDoubleButtonsWrapper}>
                        <Button
                            theme={ButtonTheme.GhostRoundedBlack}
                            disabled={!filesToExport}
                            onClick={onExportButtonClick}
                        >
                            <WithTooltip hidden={!!filesToExport} content="Выберите файлы для выгрузки">
                                <div className={styles.controlButtonsDoubleButtons}>
                                    Выгрузить {filesToExport ? ` (${filesToExport})` : ''}
                                </div>
                            </WithTooltip>
                        </Button>
                    </div>
                </div>
            );
        case ArchiveContentPopupComponentState.Hidden:
        default:
            return null;
    }
}
