import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { LoadingStatus } from '@store/commonTypes';

import {
    TagsPageState as State,
    TagsPageFilters as Filters,
    SelectedTagData,
    TagsPageLoadingStatus,
    TagGroup,
    SelectedNode,
    SelectedNodeData,
} from './types';

import * as actions from './actions/sync';

class Reducer {
    public static makeInitialState(): State {
        return {
            loadingStatus: {
                common: LoadingStatus.NOT_LOADED,
                selectedTagData: LoadingStatus.NOT_LOADED,
                selectedNodeData: LoadingStatus.NOT_LOADED,
            },
            years: [],
            filters: {
                tag: null,
                years: [],
                groups: [TagGroup.Project, TagGroup.Task, TagGroup.ExecutionId, TagGroup.PlanId],
            },
            selectedTagData: {
                projects: [],
                tasks: [],
                executionBudgetItems: [],
                planBudgetItems: [],
            },
            selectedNode: null,
            selectedNodeData: null,
        };
    }

    public static setCommonLoadingStatus(state: State, common: LoadingStatus): State {
        return Reducer.loadingStatusReducer(state, { common });
    }

    public static setSelectedTagDataLoadingStatus(state: State, selectedTagData: LoadingStatus): State {
        return Reducer.loadingStatusReducer(state, { selectedTagData });
    }

    public static setSelectedNodeDataLoadingStatus(state: State, selectedNodeData: LoadingStatus): State {
        return Reducer.loadingStatusReducer(state, { selectedNodeData });
    }

    public static setYears(state: State, years: number[]): State {
        return Reducer.stateReducer(state, { years });
    }

    public static setSelectedTagData(state: State, selectedTagData: SelectedTagData): State {
        return Reducer.stateReducer(state, { selectedTagData });
    }

    public static setSelectedNode(state: State, selectedNode: SelectedNode): State {
        return Reducer.stateReducer(state, { selectedNode });
    }

    public static setSelectedNodeData(state: State, selectedNodeData: SelectedNodeData): State {
        return Reducer.stateReducer(state, { selectedNodeData });
    }

    public static setTagFilter(state: State, tag: string): State {
        return Reducer.filtersReducer(state, { tag });
    }

    public static setYearsFilters(state: State, years: number[]): State {
        return Reducer.filtersReducer(state, { years });
    }

    public static setTagGroupFilter(state: State, groups: TagGroup[]): State {
        return Reducer.filtersReducer(state, { groups });
    }

    private static stateReducer(state: State, payload: Partial<State>): State {
        return { ...state, ...payload };
    }

    private static filtersReducer(state: State, payload: Partial<Filters>): State {
        return Reducer.stateReducer(state, { filters: { ...state.filters, ...payload } });
    }

    private static loadingStatusReducer(state: State, payload: Partial<TagsPageLoadingStatus>): State {
        return Reducer.stateReducer(state, { loadingStatus: { ...state.loadingStatus, ...payload } });
    }
}

export const tagsPageReducer = reducerWithInitialState(Reducer.makeInitialState());

tagsPageReducer
    .case(actions.setCommonLoadingStatus, Reducer.setCommonLoadingStatus)
    .case(actions.setSelectedTagDataLoadingStatus, Reducer.setSelectedTagDataLoadingStatus)
    .case(actions.setSelectedNodeDataLoadingStatus, Reducer.setSelectedNodeDataLoadingStatus);

tagsPageReducer
    .case(actions.setYears, Reducer.setYears)
    .case(actions.setSelectedTagData, Reducer.setSelectedTagData)
    .case(actions.setSelectedNode, Reducer.setSelectedNode)
    .case(actions.setSelectedNodeData, Reducer.setSelectedNodeData)
    .case(actions.resetPageState, Reducer.makeInitialState);

tagsPageReducer
    .case(actions.setTagFilter, Reducer.setTagFilter)
    .case(actions.setYearsFilter, Reducer.setYearsFilters)
    .case(actions.setTagGroupFilter, Reducer.setTagGroupFilter);
