import * as React from 'react';
import classNames from 'classnames';
import * as lodash from 'lodash';

import * as styles from './CommentStatic.scss';

import { FileAsset } from '@store/commonTypes';

import { Description } from '../Description';
import { CommentAssets } from '../CommentAssets';

interface Props {
    taskId: string;
    commentId: string;
    content: string;
    hasAssets?: boolean;
    className?: string;
    disableAssetDownload?: boolean;
    userIsCommentAuthor: boolean;
    onAssetRemoveClick: (data: FileAsset) => void;
}

export function CommentStatic({
    taskId,
    commentId,
    content,
    onAssetRemoveClick,
    hasAssets,
    className,
    disableAssetDownload,
    userIsCommentAuthor,
}: Props): JSX.Element {
    return (
        <div className={classNames(styles.root, className)}>
            {!isEmpty(content) && <Description description={content} />}

            {hasAssets && (
                <CommentAssets
                    useR7Controls
                    userIsCommentAuthor={userIsCommentAuthor}
                    taskId={taskId}
                    commentId={commentId}
                    hideAssetDate
                    className={styles.assets}
                    disableAssetDownload={disableAssetDownload}
                    onAssetRemoveClick={onAssetRemoveClick}
                />
            )}
        </div>
    );
}

function isEmpty(content: string | FileAsset[]): boolean {
    let result = false;

    if (!content) {
        result = true;
    } else if (lodash.isString(content)) {
        result = !content.trim();
    } else if (Array.isArray(content)) {
        result = !content.length;
    }

    return result;
}
