// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-Content-List-_styles__root{border:1px solid #E3E9E5;border-radius:5px}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-Content-List-_styles__title{font-family:'Open Sans';font-weight:600;font-size:14px;line-height:19px;color:#000000}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-Content-List-_styles__item{padding:5px 12px}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-Content-List-_styles__item:not(:last-child){border-bottom:1px solid #E3E9E5}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/CreationForm/GeneratedTextEditor/Editor/Footer/ConfirmButton/Popup/Content/List/styles.scss"],"names":[],"mappings":"AAAA,2KACI,wBAAyB,CACzB,iBAAkB,CACrB,4KAGG,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,aAAc,CACjB,2KAGG,gBAAiB,CADrB,4LAIQ,+BAAgC","sourcesContent":[".root {\n    border: 1px solid #E3E9E5;\n    border-radius: 5px;\n}\n\n.title {\n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 19px;\n    color: #000000;\n}\n\n.item {\n    padding: 5px 12px;\n\n    &:not(:last-child) {\n        border-bottom: 1px solid #E3E9E5;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-Content-List-_styles__root",
	"title": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-Content-List-_styles__title",
	"item": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-Content-List-_styles__item"
};
export default ___CSS_LOADER_EXPORT___;
