import * as React from 'react';
import gql from 'graphql-tag';
import { MutateProps, graphql } from 'react-apollo';

import { Queries } from '@api';

const REMOVE_LINK_MUTATION = gql`
    mutation rejectRequest($projectId: Int!, $budgetItemId: String!) {
        unlinkBudgetItemFromProject(projectId: $projectId, budgetItemId: $budgetItemId)
    }
`;

export interface LinkControlsData {
    canEdit: boolean;
    removeLink: () => Promise<void>;
}

interface Props extends ExternalProps, QueryProps {}

interface ExternalProps {
    budgetId: string;
    activityId: number;
    budgetItemId: string;
    userHasBudgetAccess: boolean;
    forwardData: (data: LinkControlsData) => void;
}

type QueryProps = MutateProps<
    {},
    {
        activityId: number;
        budgetItemId: string;
    }
>;

interface State {
    canEdit: boolean;
}

class LinkControlsFetcherBehaviour extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            canEdit: false,
        };
    }

    public async componentDidMount(): Promise<void> {
        const { userHasBudgetAccess, budgetId, budgetItemId } = this.props;

        if (userHasBudgetAccess && budgetId) {
            this.setState(
                {
                    canEdit: await Queries.userHasBudgetItemReadPermission(budgetId, budgetItemId),
                },
                () => {
                    this.props.forwardData({
                        canEdit: this.state.canEdit,
                        removeLink: this.props.mutate as () => Promise<void>,
                    });
                },
            );
        }
    }

    public render(): JSX.Element {
        return null;
    }
}

export const LinkControlsFetcher = graphql<ExternalProps>(REMOVE_LINK_MUTATION, {
    options: ({ activityId, budgetItemId }) => ({
        variables: {
            projectId: activityId,
            budgetItemId,
        },
    }),
})(LinkControlsFetcherBehaviour);
