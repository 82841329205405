import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';

import { StoreState } from '@store';
import {
    ComponentState,
    setComponentState,
    initPlanTransfer,
    getBudgetTransferMenuState,
} from '@store/budgetExecution/budgetTransferMenu';

import { WithRequestInProgressMask } from '../../WithRequestInProgressMask';

import * as styles from './ControlButtons.scss';

function useInteractivity() {
    const dispatch = useDispatch();
    const isRequestInProgress = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.isRequestInProgress,
    );

    function toSumEntering() {
        dispatch(setComponentState(ComponentState.InternalTransferSumEntering));
    }
    function initCorrections() {
        dispatch(initPlanTransfer(null));
    }

    return {
        isRequestInProgress,
        toSumEntering,
        initCorrections,
    };
}

export function ControlButtons(): JSX.Element {
    const { isRequestInProgress, toSumEntering, initCorrections } = useInteractivity();

    return (
        <div className={styles.root}>
            <WithRequestInProgressMask>
                <div className={styles.buttonsWrapper}>
                    <div className={styles.button}>
                        <Button
                            theme={ButtonTheme.GhostRounded}
                            onClick={toSumEntering}
                            qaId="internalTransitionNegativeLineBalanceWarningToSumEnteringButtons"
                        >
                            Назад
                        </Button>
                    </div>

                    <div className={styles.button}>
                        <Button
                            theme={ButtonTheme.GhostRoundedBlack}
                            onClick={initCorrections}
                            qaId="internalTransitionNegativeLineBalanceWarningInitCorrectionsButtons"
                        >
                            {isRequestInProgress ? 'Отправляется...' : 'Отправить'}
                        </Button>
                    </div>
                </div>
            </WithRequestInProgressMask>
        </div>
    );
}
