import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MentionItem, MentionItemStatus } from 'sber-marketing-ui';
import { AccountStatus } from 'sber-marketing-types/openid';

import { FileApiUploadParams, UserApi, UserListFilters, FileApi } from '@api';

import { StoreState } from '@store';
import { FileAsset } from '@store/commonTypes';
import { getLoginUser } from '@store/user';
import { resetFilesUploadStatus, setFileUploadStatus, FileUploadStatus, errorIsFromAntivirus } from '@store/common';

import { CommentInputBehaviour } from './CommentInputBehaviour';

const SBER_ORGANIZATIONS_IDS = [
    'e2ee630b-8fe9-4e68-a6cf-ea3c9d2709e8',
    '1f11c60a-c021-4092-8697-67c83cb5d5d9',
    '68a38c6a-79f7-49b5-9356-b779fbbb9b64',
    '6aa41a85-1716-43a7-9c91-9933c9b945a4',
];

export type MakeFileUploadParams = (file: File) => [FileApiUploadParams, boolean];
const defaultMakeFileUploadParams: MakeFileUploadParams = () => [{}, false];

interface Props {
    disabled?: boolean;
    usersOrganizationsForMention?: string[];
    usersIdsForMention?: number[];
    makeFileUploadParams?: MakeFileUploadParams;
    onCommentCreationStart?: () => void;
    createComment: (text: string, files: FileAsset[]) => Promise<void>;
}

function useMentionableUsers(usersIdsForMention: number[], usersOrganizationsForMention: string[]) {
    const [mentionableUsers, setMentionableUsers] = React.useState<MentionItem[]>([]);

    const userOrganizationId = useSelector((state: StoreState) => getLoginUser(state).attributes.organizationId);

    React.useEffect(() => {
        let shouldUseFetch = true;

        async function fetcher() {
            const params: UserListFilters = { embed: ['vacation'] };
            if (usersIdsForMention) {
                params.ids = usersIdsForMention;
            } else {
                params.organizationIds =
                    usersOrganizationsForMention ||
                    (SBER_ORGANIZATIONS_IDS.includes(userOrganizationId)
                        ? SBER_ORGANIZATIONS_IDS
                        : [userOrganizationId]);
            }

            const users = await UserApi.getUserListFiltered(params);

            const mentionableUsers = users
                .filter(
                    (user) =>
                        (user.status === AccountStatus.ACTIVE || user.status === AccountStatus.NEED_UPDATE_PASSWORD) &&
                        !!user.departmentId,
                )
                .map((user) => ({
                    id: user.id,
                    title: `${user.secondName} ${user.firstName}`,
                    description: user.departmentName || 'Департамент не задан',
                    status: user.vacation ? MentionItemStatus.Vacation : null,
                }));

            if (shouldUseFetch) {
                setMentionableUsers(mentionableUsers);
            }
        }

        fetcher();

        return function resetEffect() {
            shouldUseFetch = false;
        };
    }, [usersOrganizationsForMention]);

    return mentionableUsers;
}

function useFileUploading(makeFileUploadParams: MakeFileUploadParams) {
    const dispatch = useDispatch();

    const loginedUserId = useSelector((state: StoreState) => String(getLoginUser(state).attributes.id));

    const uploadedFilesRef = React.useRef<File[]>();
    const [uploadedFiles, setUploadedFiles] = React.useState<File[]>([]);
    const [isFileUploadInProgress, setIsFileUploadInProgress] = React.useState(false);

    function addFilesToUploaded(file: File) {
        const updFiles = [...uploadedFilesRef.current, file];
        setUploadedFiles(updFiles);
        uploadedFilesRef.current = updFiles;
    }

    async function initFileUpload(files: File[]): Promise<FileAsset[]> {
        dispatch(resetFilesUploadStatus());
        setIsFileUploadInProgress(true);
        setUploadedFiles([]);
        uploadedFilesRef.current = [];

        const uploadedFiles: FileAsset[] = [];
        let error: Error;

        await Promise.all(
            files.map(async (file) => {
                try {
                    const [fileUploadParams, skipCreatingFileRecord] = makeFileUploadParams(file);
                    const uploadedFile = await FileApi.uploadFile(
                        fileUploadParams,
                        file,
                        String(loginedUserId),
                        skipCreatingFileRecord,
                    );
                    addFilesToUploaded(file);
                    uploadedFiles.push(uploadedFile);
                } catch (e) {
                    if (errorIsFromAntivirus(e)) {
                        dispatch(
                            setFileUploadStatus({
                                fileName: file.name,
                                status: FileUploadStatus.VirusFound,
                            }),
                        );
                    }

                    error = e;
                }
            }),
        );

        setIsFileUploadInProgress(false);

        if (error) {
            throw error;
        }

        return uploadedFiles;
    }

    return {
        uploadedFiles,
        isFileUploadInProgress,
        initFileUpload,
    };
}

function useCommentInput({
    createComment,
    onCommentCreationStart,
    makeFileUploadParams = defaultMakeFileUploadParams,
    usersOrganizationsForMention,
    usersIdsForMention,
}: Props) {
    const [isRequestInProgress, setIsRequestInProgress] = React.useState(false);

    const commentInputRef = React.useRef<CommentInputBehaviour>();

    const mentionableUsers = useMentionableUsers(usersIdsForMention, usersOrganizationsForMention);
    const { uploadedFiles, isFileUploadInProgress, initFileUpload } = useFileUploading(makeFileUploadParams);

    async function initCreateComment(text: string, files: File[]) {
        onCommentCreationStart?.();

        setIsRequestInProgress(true);

        const fileIds = await initFileUpload(files);
        await createComment(text, fileIds);

        setIsRequestInProgress(false);
        commentInputRef.current?.resetState();
    }

    return {
        commentInputRef,
        mentionableUsers,
        uploadedFiles,
        isFileUploadInProgress,
        isRequestInProgress,
        initCreateComment,
    };
}

export function CommentInput(props: Props): JSX.Element {
    const {
        commentInputRef,
        mentionableUsers,
        uploadedFiles,
        isFileUploadInProgress,
        isRequestInProgress,
        initCreateComment,
    } = useCommentInput(props);

    return (
        <CommentInputBehaviour
            ref={commentInputRef}
            mentionableUsers={mentionableUsers}
            uploadedFiles={uploadedFiles}
            isRequestInProgress={isRequestInProgress}
            isFileUploadInProgress={isFileUploadInProgress}
            createComment={initCreateComment}
            disabled={props.disabled}
        />
    );
}

export function CommentInputLexical(props: Props): JSX.Element {
    const {
        commentInputRef,
        mentionableUsers,
        uploadedFiles,
        isFileUploadInProgress,
        isRequestInProgress,
        initCreateComment,
    } = useCommentInput(props);

    return (
        <CommentInputBehaviour
            ref={commentInputRef}
            mentionableUsers={mentionableUsers}
            uploadedFiles={uploadedFiles}
            isRequestInProgress={isRequestInProgress}
            isFileUploadInProgress={isFileUploadInProgress}
            createComment={initCreateComment}
            disabled={props.disabled}
            useLexicalTemplate
        />
    );
}
