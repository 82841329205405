import * as React from 'react';
import { Icon, IconType } from 'sber-marketing-ui';

import * as styles from './ToggleButton.scss';

interface Props {
    toggledOnText?: string;
    toggledOffText?: string;
    isOpened: boolean;
    onClick: () => void;
}

export function ToggleButton({
    toggledOnText = 'Скрыть',
    toggledOffText = 'Показать',
    isOpened,
    onClick,
}: Props): JSX.Element {
    return (
        <div className={styles.root} onClick={onClick}>
            {isOpened ? (
                <React.Fragment>
                    {toggledOnText}
                    <Icon type={IconType.ARROW24_UP} />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {toggledOffText}
                    <Icon type={IconType.ARROW24_DOWN} />
                </React.Fragment>
            )}
        </div>
    );
}
