import * as React from 'react';

import { Icon, IconType } from 'sber-marketing-ui';

import { AutopilotPopup } from '../AutopilotPopup';

import { useAutopilotAdd } from './hooks';

import * as style from './style.scss';

interface Props {
    activityId: number;
}

export const MenuItemDropdown: React.FC<Props> = ({ activityId }) => {
    const itemRef = React.useRef();

    const [opened, setOpened] = React.useState(false);
    const handleAdd = useAutopilotAdd({ activityId });

    const onOpenerClick = React.useCallback(() => {
        setOpened(!opened);
    }, [opened]);

    const onMaskClick = React.useCallback(() => {
        setOpened(false);
    }, []);

    return (
        <div className={style.root} ref={itemRef}>
            <div className={style.opener} onClick={onOpenerClick}>
                Автопилот
                <div className={opened ? style.arrowUp : style.arrowDown}>
                    <Icon type={IconType.ARROW16_RIGHT} svgSize={16} />
                </div>
            </div>

            {opened && (
                <AutopilotPopup
                    container={itemRef}
                    onAdd={handleAdd}
                    activityId={activityId}
                    onMaskClick={onMaskClick}
                />
            )}
        </div>
    );
};
