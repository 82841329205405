import * as React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { StoreState } from '@store';
import { getBudgetTransferMenuState } from '@store/budgetExecution/budgetTransferMenu';

import * as styles from './WithRequestInProgressMask.scss';

export enum Themes {
    JoinTop,
    JoinBottom,
    JoinTopBottom,
}

interface Props {
    children: JSX.Element;
    theme?: Themes;
}

export function WithRequestInProgressMask({ children, theme }: Props): JSX.Element {
    const showMask = useSelector((state: StoreState) => getBudgetTransferMenuState(state).controls.isRequestInProgress);

    let themeClass: string;
    switch (theme) {
        case Themes.JoinTop:
            themeClass = styles.maskJoinTop;
            break;
        case Themes.JoinBottom:
            themeClass = styles.maskJoinBottom;
            break;
        case Themes.JoinTopBottom:
            themeClass = styles.maskJoinTopBottom;
            break;
        default:
            break;
    }

    return (
        <div className={styles.root}>
            {showMask && <div className={classnames(styles.mask, themeClass)} />}

            <div className={styles.content}>{children}</div>
        </div>
    );
}
