import * as lodash from 'lodash';

import type { ActivityTypeColor } from '@store/calendar/types';

const GROUP_NAME_HEIGHT = 44;
const LINE_HEIGHT = 32;
const FONT_SIZE = 11;
const ACTIVITY_HEIGHT = 16;
const TASK_RADIUS = 10;
const TASK_THICKNESS = 2;
const TASK_ICON_RADIUS = 8;
const TODAY_LINE_THICKNESS = 1;
const HORIZONTAL_LINE_THICKNESS = 1;
const PADDING_BOTTOM = 6;

export interface ActivityDrawParams {
    lineIndex: number;
    preparationStart: number;
    realizationStart: number;
    realizationEnd: number;
    debriefingEnd: number;
    isOneDayActivity: boolean;
    color: ActivityTypeColor;
    hasExpiredStages: boolean;
    isActive: boolean;
    isHovered: boolean;
}

export interface TaskDrawParams {
    lineIndex: number;
    deadline: number;
    deadlineExpired: boolean;
    taskIsClosed: boolean;
    color: ActivityTypeColor;
    isActive: boolean;
    isHovered: boolean;
}

interface ConstructorParams {
    withTopIndent: boolean;
}

export class Drawer {
    private withTopIndent: boolean;

    constructor({ withTopIndent }: ConstructorParams) {
        this.withTopIndent = withTopIndent;
    }

    public setScroll(canvas: HTMLCanvasElement, scrollLeft: number) {
        this.checkCanvasExistence(canvas);

        const ctx = canvas.getContext('2d');
        const scale = this.getScale();

        ctx.setTransform(scale, 0, 0, scale, -scrollLeft * scale, 0);
    }

    public drawGroupNameText(canvas: HTMLCanvasElement, text: string) {
        this.checkCanvasExistence(canvas);

        if (text) {
            const ctx = canvas.getContext('2d');
            const scale = this.getScale();

            const textWidth = this.getTextWidth(canvas, text) * scale;

            const textX = (this.getCanvasWidth(canvas) - textWidth) / 2;
            const textY = GROUP_NAME_HEIGHT / 2 + Math.round(FONT_SIZE / 2);

            ctx.save();
            ctx.setTransform(scale, 0, 0, scale, 0, 0);
            ctx.font = `${FONT_SIZE}px Open Sans`;
            ctx.fillStyle = '#7d7d7d';
            ctx.fillText(text, textX, textY);
            ctx.restore();
        }
    }

    public drawTodayLine(canvas: HTMLCanvasElement, x: number) {
        this.checkCanvasExistence(canvas);

        const ctx = canvas.getContext('2d');

        ctx.fillStyle = 'rgba(25, 187, 79, 0.32)';
        ctx.fillRect(x - TODAY_LINE_THICKNESS / 2, 0, TODAY_LINE_THICKNESS, this.getCanvasHeight(canvas));
    }

    public drawSeparator(canvas: HTMLCanvasElement, x: number, linesIndexes: number[]) {
        this.checkCanvasExistence(canvas);

        const ctx = canvas.getContext('2d');

        const firstVisibleLineIndex = lodash.first(linesIndexes);
        const lastVisibleLineIndex = lodash.last(linesIndexes);

        const startY = this.getLinePositionByIndex(firstVisibleLineIndex);
        let endY = this.getLinePositionByIndex(lastVisibleLineIndex);

        endY += lastVisibleLineIndex > 0 ? LINE_HEIGHT : GROUP_NAME_HEIGHT;

        ctx.setLineDash([2, 4]);

        ctx.beginPath();
        ctx.strokeStyle = 'rgba(0, 0, 0, 0.36)';
        ctx.moveTo(x, startY);
        ctx.lineTo(x, endY);
        ctx.stroke();

        ctx.setLineDash([]);
    }

    public drawActivity(canvas: HTMLCanvasElement, params: ActivityDrawParams, errorIcon: HTMLImageElement) {
        this.checkCanvasExistence(canvas);

        const {
            lineIndex,
            preparationStart,
            realizationStart,
            realizationEnd,
            debriefingEnd,
            isOneDayActivity,
            color,
            hasExpiredStages,
            isActive,
            isHovered,
        } = params;

        const ctx = canvas.getContext('2d');

        const linePositionY = this.getLinePositionByIndex(lineIndex);

        const activityPositionY = linePositionY + ACTIVITY_HEIGHT / 2;

        const realizationMiddle = (realizationStart + realizationEnd) / 2;

        if (preparationStart !== null) {
            const preparationWidth = realizationMiddle - preparationStart;

            ctx.fillStyle = color.preparation;
            ctx.fillRect(preparationStart, activityPositionY, preparationWidth, ACTIVITY_HEIGHT);
        }

        if (debriefingEnd !== null) {
            const debriefWidth = debriefingEnd - realizationMiddle;

            ctx.fillStyle = color.preparation;
            ctx.fillRect(realizationMiddle, activityPositionY, debriefWidth, ACTIVITY_HEIGHT);

            ctx.fillStyle = '#fff';
            ctx.fillRect(realizationMiddle, activityPositionY + 2, debriefWidth - 2, ACTIVITY_HEIGHT - 4);
        }

        ctx.fillStyle = isHovered ? color.realization : color.hover;

        if (isOneDayActivity) {
            const lineCenterY = linePositionY + LINE_HEIGHT / 2;

            ctx.beginPath();
            ctx.moveTo(realizationMiddle, lineCenterY + 9);
            ctx.lineTo(realizationMiddle + 9, lineCenterY);
            ctx.lineTo(realizationMiddle, lineCenterY - 9);
            ctx.lineTo(realizationMiddle - 9, lineCenterY);
            ctx.closePath();
            ctx.fill();

            if (!isActive) {
                ctx.fillStyle = '#fff';

                ctx.beginPath();
                ctx.moveTo(realizationMiddle, lineCenterY + 7);
                ctx.lineTo(realizationMiddle + 7, lineCenterY);
                ctx.lineTo(realizationMiddle, lineCenterY - 7);
                ctx.lineTo(realizationMiddle - 7, lineCenterY);
                ctx.closePath();
                ctx.fill();
            }
        } else {
            const realizationWidth = realizationEnd - realizationStart;

            ctx.fillRect(realizationStart, activityPositionY, realizationWidth, ACTIVITY_HEIGHT);

            if (!isActive) {
                ctx.fillStyle = '#fff';
                ctx.fillRect(realizationStart + 2, activityPositionY + 2, realizationWidth - 4, ACTIVITY_HEIGHT - 4);
            }
        }

        if (hasExpiredStages) {
            ctx.drawImage(errorIcon, realizationMiddle - TASK_ICON_RADIUS, linePositionY);
        }
    }

    public drawTask(
        canvas: HTMLCanvasElement,
        params: TaskDrawParams,
        expiredTaskIcon: HTMLImageElement,
        finishedTaskIcon: HTMLImageElement,
    ) {
        this.checkCanvasExistence(canvas);

        const { lineIndex, deadline, deadlineExpired, taskIsClosed, color, isHovered } = params;

        const ctx = canvas.getContext('2d');

        const linePositionY = this.getLinePositionByIndex(lineIndex) + LINE_HEIGHT / 2;

        ctx.beginPath();
        ctx.arc(deadline, linePositionY, TASK_RADIUS - TASK_THICKNESS / 2, 0, Math.PI * 2);
        ctx.fillStyle = '#fff';
        ctx.fill();

        if (deadlineExpired) {
            ctx.drawImage(expiredTaskIcon, deadline - TASK_ICON_RADIUS, linePositionY - TASK_ICON_RADIUS);
        }

        if (taskIsClosed) {
            ctx.drawImage(finishedTaskIcon, deadline - TASK_ICON_RADIUS, linePositionY - TASK_ICON_RADIUS);
        }

        ctx.lineWidth = TASK_THICKNESS;
        ctx.strokeStyle = isHovered ? color.realization : color.hover;
        ctx.stroke();
    }

    public drawHorizontalLine(canvas: HTMLCanvasElement, lineIndex: number) {
        this.checkCanvasExistence(canvas);

        const ctx = canvas.getContext('2d');
        const scale = this.getScale();

        const linePositionY = this.getLinePositionByIndex(lineIndex) + LINE_HEIGHT / 2;

        ctx.save();
        ctx.setTransform(scale, 0, 0, scale, 0, 0);
        ctx.fillStyle = '#dbdbdb';
        ctx.fillRect(0, linePositionY, this.getCanvasWidth(canvas), HORIZONTAL_LINE_THICKNESS);
        ctx.restore();
    }

    public drawBackground(canvas: HTMLCanvasElement): void {
        const ctx = canvas.getContext('2d');
        const scale = this.getScale();

        const x = 0;
        const y = 0;
        const width = this.getCanvasWidth(canvas);
        const height = this.getCanvasHeight(canvas);
        const radius = 5;

        ctx.save();
        ctx.setTransform(scale, 0, 0, scale, 0, 0);
        ctx.fillStyle = '#ffffff';
        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + width - radius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        ctx.lineTo(x + width, y + height - radius);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        ctx.lineTo(x + radius, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        ctx.lineTo(x, y + radius);
        ctx.quadraticCurveTo(x, y, x + radius, y);
        ctx.fill();
        ctx.closePath();
        ctx.restore();
    }

    public drawCanvasRootBackground(rootCanvas: HTMLCanvasElement): void {
        const rootCanvasCtx = rootCanvas.getContext('2d');

        rootCanvasCtx.fillStyle = '#f1f5f7';
        rootCanvasCtx.fillRect(0, 0, rootCanvas.width, rootCanvas.height);
    }

    public clear(canvas: HTMLCanvasElement, linesIndexes: number[]) {
        this.checkCanvasExistence(canvas);

        const ctx = canvas.getContext('2d');

        const scale = this.getScale();

        const firstVisibleLineIndex = lodash.first(linesIndexes);
        const lastVisibleLineIndex = lodash.last(linesIndexes);

        const startY = this.getLinePositionByIndex(firstVisibleLineIndex);
        let endY = this.getLinePositionByIndex(lastVisibleLineIndex);

        endY += lastVisibleLineIndex > 0 ? LINE_HEIGHT : GROUP_NAME_HEIGHT;

        const linesCount = Math.floor((this.getCanvasHeight(canvas) - GROUP_NAME_HEIGHT) / LINE_HEIGHT) + 1;

        if (lastVisibleLineIndex == linesCount - 1) {
            endY += PADDING_BOTTOM;
        }

        const height = endY - startY;

        ctx.save();
        ctx.setTransform(scale, 0, 0, scale, 0, 0);
        ctx.clearRect(0, startY, this.getCanvasWidth(canvas), height);
        ctx.restore();
    }

    private getLinePositionByIndex(lineIndex: number): number {
        if (this.withTopIndent) {
            return lineIndex > 0 ? GROUP_NAME_HEIGHT + LINE_HEIGHT * (lineIndex - 1) : 0;
        }

        return LINE_HEIGHT * lineIndex - 1;
    }

    private getTextWidth(canvas: HTMLCanvasElement, text: string): number {
        const ctx = canvas.getContext('2d');

        return ctx.measureText(text).width;
    }

    private getCanvasWidth(canvas: HTMLCanvasElement): number {
        return canvas.width / this.getScale();
    }

    private getCanvasHeight(canvas: HTMLCanvasElement): number {
        return canvas.height / this.getScale();
    }

    private getScale(): number {
        return 1;
        // return window.devicePixelRatio || 1;
    }

    private checkCanvasExistence(canvas: HTMLCanvasElement) {
        if (!canvas) {
            throw new Error('Canvas element doesn`t exist');
        }
    }
}
