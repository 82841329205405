// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-_styles__root{width:800px;padding:24px;position:relative;background:#FFFFFF;box-shadow:0 2px 10px rgba(0,0,0,0.1);border-radius:8px}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-_styles__header{margin-bottom:15px}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-_styles__footer{margin-top:40px}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-_styles__closeButton{position:absolute;top:14px;right:16px;height:32px;width:32px;display:flex;align-items:center;justify-content:center;cursor:pointer}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/CreationForm/GeneratedTextEditor/Editor/Footer/ConfirmButton/Popup/styles.scss"],"names":[],"mappings":"AAAA,8JACI,WAAY,CACZ,YAAa,CAEb,iBAAkB,CAElB,kBAAmB,CACnB,qCAAyC,CACzC,iBAAkB,CACrB,gKAGG,kBAAmB,CACtB,gKAGG,eAAgB,CACnB,qKAGG,iBAAkB,CAClB,QAAS,CACT,UAAW,CACX,WAAY,CACZ,UAAW,CACX,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,cAAe","sourcesContent":[".root {\n    width: 800px;\n    padding: 24px;\n\n    position: relative;\n\n    background: #FFFFFF;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    border-radius: 8px;\n}\n\n.header {\n    margin-bottom: 15px;\n}\n\n.footer {\n    margin-top: 40px;\n}\n\n.closeButton {\n    position: absolute;\n    top: 14px;\n    right: 16px;\n    height: 32px;\n    width: 32px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-_styles__root",
	"header": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-_styles__header",
	"footer": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-_styles__footer",
	"closeButton": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Editor-Footer-ConfirmButton-Popup-_styles__closeButton"
};
export default ___CSS_LOADER_EXPORT___;
