import { actionCreatorFactory } from 'typescript-fsa';

import {
    BudgetCorrectionsUserConfig,
    UpdateBudgetCorrectionsUserConfig,
    LoadBudgetCorrectionsUserConfig,
} from './types';

const actionCreator = actionCreatorFactory('USER_CONFIG_BUDGET_CORRECTIONS');

const loadBudgetCorrectionsUserConfig = actionCreator.async<
    BudgetCorrectionsUserConfig,
    LoadBudgetCorrectionsUserConfig,
    Error
>('LOAD_BUDGET_CORRECTIONS_USER_CONFIG');
const updateBudgetCorrectionsUserConfig = actionCreator.async<UpdateBudgetCorrectionsUserConfig, void, Error>(
    'UPDATE_BUDGET_CORRECTIONS_USER_CONFIG',
);

export { loadBudgetCorrectionsUserConfig, updateBudgetCorrectionsUserConfig };
