import * as React from 'react';
import classNames from 'classnames';

import * as style from './NewResponsibleDescription.scss';

interface Props {
    firstName: string;
    secondName: string;
    department: string;
}

export function NewResponsibleDescription({ firstName, secondName, department }: Props): JSX.Element {
    return (
        <div className={style.root}>
            <div className={style.caption}>Новый ответственный</div>

            <div className={style.content}>
                <div
                    className={classNames([style.text, style.colorGreen, style.weightBold])}
                    {...{
                        'qa-id': 'dashboardNewsColumnItemNewResponsible',
                    }}
                >
                    {secondName} {firstName}
                </div>
                <div className={style.caption}>{department}</div>
            </div>
        </div>
    );
}
