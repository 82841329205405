import * as lodash from 'lodash';
import autobind from 'autobind-decorator';
import * as url from 'url';
import * as querystring from 'querystring';

import { store } from '@store';
import { getFilters } from '@store/calendar/selectors';
import { UserConfigType } from 'sber-marketing-types/openid';

import type { Filters, CalendarUserConfig } from '@store/calendar/types';
import { UserConfigApi } from '@api';

const TIMEOUT_UPDATING_USER_CONFIG = 400;

interface StoreProps {
    filters: Filters;
}

export class Saver {
    private static instance: Saver;
    private oldStoreProps: StoreProps;
    private delayedUpdateUserConfig = lodash.debounce(this.updateUserConfig, TIMEOUT_UPDATING_USER_CONFIG);

    public static getInstance(): Saver {
        if (!Saver.instance) {
            Saver.instance = new Saver();
        }
        return Saver.instance;
    }

    public init(): void {
        store.subscribe(this.onStoreUpdate);
    }

    @autobind
    private onStoreUpdate() {
        if (this.hasStorePropsChanged()) {
            this.onStorePropsChange();
        }
    }

    private hasStorePropsChanged(): boolean {
        const newStoreProps = this.getStoreProps();
        return !lodash.isEqual(newStoreProps, this.oldStoreProps);
    }

    private onStorePropsChange() {
        const query = this.getUrlQuery();

        const shouldUpdateUserConfig = lodash.isEmpty(query);

        if (shouldUpdateUserConfig) {
            this.delayedUpdateUserConfig();
        }

        this.updateOldStoreProps();
    }

    private updateOldStoreProps(): void {
        this.oldStoreProps = this.getStoreProps();
    }

    @autobind
    private async updateUserConfig() {
        const userConfig = this.makeUserConfig();
        await UserConfigApi.savePageConfig(UserConfigType.Calendar, userConfig);
    }

    private getUrlQuery(): Object {
        const { query } = url.parse(window.location.href);

        return querystring.parse(query);
    }

    private makeUserConfig(): CalendarUserConfig {
        const { filters } = this.getStoreProps();
        const {
            onlyKeyActivities,
            organizationIds,
            activityTypeIds,
            divisionIds,
            productIds,
            departmentIds,
            responsibleUserIds,
        } = filters;

        return {
            filters: {
                onlyKeyActivities,
                organizationIds,
                activityTypeIds,
                divisionIds,
                productIds,
                departmentIds,
                responsibleUserIds,
            },
        };
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        return {
            filters: getFilters(storeState),
        };
    }
}
