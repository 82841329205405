import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { StoreState } from '@store';
import { LeaderDashboardApi, UserApi, DepartmentApi } from '@api';

import { loadPageAsync, setFilterPeriodAsync, setFilterDateAsync, setFilterByAsync } from './actions';
import { getDashboardPageState } from './selectors';
import {
    PageData,
    SetFilterPeriodPayload,
    SetFilterPeriodResult,
    SetFilterDatePayload,
    SetFilterDateResult,
    SetFilterByPayload,
    SetFilterByResult,
} from './types';

import { getUsersIdsFromStatistics, getDepartmentsIdsFromUsers, filterDepartmentResponseByIds } from './utils/common';

export const loadPage = bindThunkAction<StoreState, void, PageData, Error>(loadPageAsync, async (_, __, getState) => {
    const { selectedBy, selectedDate, selectedPeriod } = getDashboardPageState(getState());

    const taskDataResponse = await LeaderDashboardApi.getTasksData({
        period: selectedPeriod,
        by: selectedBy,
        currentDate: selectedDate,
    });

    console.log('taskDataResponse: ', taskDataResponse);

    const [users, departments] = await Promise.all([
        await UserApi.getUserList(getUsersIdsFromStatistics(taskDataResponse)),
        await DepartmentApi.getList(),
    ]);

    return {
        users,
        departments: filterDepartmentResponseByIds(departments, getDepartmentsIdsFromUsers(users)),
        statistics: taskDataResponse,
    };
});

export const setFilterPeriod = bindThunkAction<StoreState, SetFilterPeriodPayload, SetFilterPeriodResult, Error>(
    setFilterPeriodAsync,
    async ({ filterPeriod }, _, getState) => {
        const { selectedBy, selectedDate } = getDashboardPageState(getState());

        const taskDataResponse = await LeaderDashboardApi.getTasksData({
            period: filterPeriod,
            by: selectedBy,
            currentDate: selectedDate,
        });

        const [users, departments] = await Promise.all([
            await UserApi.getUserList(getUsersIdsFromStatistics(taskDataResponse)),
            await DepartmentApi.getList(),
        ]);

        return {
            filterPeriod,
            data: {
                users,
                departments: filterDepartmentResponseByIds(departments, getDepartmentsIdsFromUsers(users)),
                statistics: taskDataResponse,
            },
        };
    },
);

export const setFilterDate = bindThunkAction<StoreState, SetFilterDatePayload, SetFilterDateResult, Error>(
    setFilterDateAsync,
    async ({ filterDate }, _, getState) => {
        const { selectedBy, selectedPeriod } = getDashboardPageState(getState());

        const activityDataResponse = await LeaderDashboardApi.getTasksData({
            period: selectedPeriod,
            by: selectedBy,
            currentDate: filterDate,
        });

        const [users, departments] = await Promise.all([
            await UserApi.getUserList(getUsersIdsFromStatistics(activityDataResponse)),
            await DepartmentApi.getList(),
        ]);

        return {
            filterDate,
            data: {
                users,
                departments: filterDepartmentResponseByIds(departments, getDepartmentsIdsFromUsers(users)),
                statistics: activityDataResponse,
            },
        };
    },
);

export const setFilterBy = bindThunkAction<StoreState, SetFilterByPayload, SetFilterByResult, Error>(
    setFilterByAsync,
    async ({ by }, _, getState) => {
        const { selectedPeriod, selectedDate } = getDashboardPageState(getState());

        const activityDataResponse = await LeaderDashboardApi.getTasksData({
            period: selectedPeriod,
            currentDate: selectedDate,
            by,
        });

        const [users, departments] = await Promise.all([
            await UserApi.getUserList(getUsersIdsFromStatistics(activityDataResponse)),
            await DepartmentApi.getList(),
        ]);

        return {
            by,
            data: {
                users,
                departments: filterDepartmentResponseByIds(departments, getDepartmentsIdsFromUsers(users)),
                statistics: activityDataResponse,
            },
        };
    },
);
