import * as React from 'react';

import * as style from './RequestStageWidget.scss';

import { OpacityTransition } from 'sber-marketing-ui';
import { MainInfo } from './MainInfo';
import { ParamList } from './ParamList';
import { BudgetId } from './BudgetId';
import { TenderDocumentation, useVisibilityTenderDocumentation } from './TenderDocumentation';

interface Props {
    loading: boolean;
    creativeRequestId: string;
}

export const RequestStageWidgetTemplate = ({ loading, creativeRequestId }: Props): JSX.Element => {
    const { visibility: visibleTenderDocumentation } = useVisibilityTenderDocumentation();

    return (
        <OpacityTransition start={!loading}>
            <div className={style.root}>
                <div className={style.form}>
                    <MainInfo creativeRequestId={creativeRequestId} />
                </div>

                {visibleTenderDocumentation && (
                    <div className={style.form}>
                        <TenderDocumentation />
                    </div>
                )}

                <div className={style.form}>
                    <ParamList creativeRequestId={creativeRequestId} />
                </div>

                <div className={style.form}>
                    <BudgetId />
                </div>
            </div>
        </OpacityTransition>
    );
};
