import * as React from 'react';
import { values } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import {
    getFilterPeriod,
    getFilterDate,
    getSelectedStatisticsItem,
    getStatisticsTypeFilters,
    FilterPeriod,
    StatisticsActivityType,
    StatisticsTaskType,
} from '@store/leaderDashboardPage';
import type { StatisticsItem, StatisticsType, StatisticsTypeFilters } from '@store/leaderDashboardPage';
import * as actions from '@store/leaderDashboardPage/actions';

interface UseState {
    (): UseStateReturn;
}

interface UseStateReturn extends Selected {
    setStatisticsTypeFilter: (type: StatisticsType, value: boolean) => void;
}

interface Selected {
    selectedPeriod: FilterPeriod;
    selectedDate: string;
    selectedStatisticsItem: StatisticsItem;
    statisticsTypeFilters: StatisticsTypeFilters;
}

export const useState: UseState = () => {
    const dispatch = useDispatch();

    const { selectedPeriod, selectedDate, selectedStatisticsItem, statisticsTypeFilters } = useSelector<
        StoreState,
        Selected
    >((state) => {
        return {
            selectedPeriod: getFilterPeriod(state),
            selectedDate: getFilterDate(state),
            selectedStatisticsItem: getSelectedStatisticsItem(state),
            statisticsTypeFilters: getStatisticsTypeFilters(state),
        };
    });

    const setStatisticsTypeFilter = React.useCallback(
        (type: StatisticsType, value: boolean) => {
            const allFiltersValueTrue = values(statisticsTypeFilters).every((value) => value);
            const allFiltersValueFalse = values({ ...statisticsTypeFilters, [type]: value }).every((value) => !value);

            if (allFiltersValueTrue) {
                dispatch(
                    actions.setStatisticsTypeFilters({
                        [StatisticsActivityType.ACTIVE]: false,
                        [StatisticsActivityType.PLANNED_START]: false,
                        [StatisticsActivityType.OVERDUE_TASKS]: false,
                        [StatisticsActivityType.OVERDUE_STAGES]: false,
                        [StatisticsTaskType.CLOSED]: false,
                        [StatisticsTaskType.ACTIVE]: false,
                        [StatisticsTaskType.OVERDUE]: false,
                        [StatisticsTaskType.COMING_DEADLINE]: false,
                        [type]: !value,
                    }),
                );
            } else if (allFiltersValueFalse) {
                dispatch(
                    actions.setStatisticsTypeFilters({
                        [StatisticsActivityType.ACTIVE]: true,
                        [StatisticsActivityType.PLANNED_START]: true,
                        [StatisticsActivityType.OVERDUE_TASKS]: true,
                        [StatisticsActivityType.OVERDUE_STAGES]: true,
                        [StatisticsTaskType.CLOSED]: true,
                        [StatisticsTaskType.ACTIVE]: true,
                        [StatisticsTaskType.OVERDUE]: true,
                        [StatisticsTaskType.COMING_DEADLINE]: true,
                    }),
                );
            } else {
                dispatch(
                    actions.setStatisticsTypeFilters({
                        ...statisticsTypeFilters,
                        [type]: value,
                    }),
                );
            }
        },
        [statisticsTypeFilters],
    );

    return {
        selectedPeriod,
        selectedDate,
        selectedStatisticsItem,
        statisticsTypeFilters,
        setStatisticsTypeFilter,
    };
};

export {
    FilterPeriod as Period,
    StatisticsItem,
    StatisticsType,
    StatisticsTypeFilters,
    StatisticsActivityType,
    StatisticsTaskType,
};
