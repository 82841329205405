import autobind from 'autobind-decorator';

import type { BaseCellParams, CellUpdateHandler } from '../types';

interface Props<TPosition, TCellParams> {
    makeCellParams: (position: TPosition) => TCellParams;
}

export class CellsStorage<TPosition, TCellParams extends BaseCellParams> {
    private cells: Map<string, TCellParams> = new Map();
    private updateHandlers: Map<string, CellUpdateHandler<TCellParams>> = new Map();
    private makeCellParams: (position: TPosition) => TCellParams;

    public constructor(props: Props<TPosition, TCellParams>) {
        this.makeCellParams = props.makeCellParams;
    }

    @autobind
    public setCellParams(position: TPosition, cellParams: TCellParams): void {
        this.cells.set(stringify(position), cellParams);

        const updateHandler = this.updateHandlers.get(stringify(position));

        if (updateHandler) {
            updateHandler(cellParams);
        }
    }

    @autobind
    public getCellParams(position: TPosition, onCellParamsUpdateHandler?: CellUpdateHandler<TCellParams>): TCellParams {
        let cellParams = this.cells.get(stringify(position));

        if (!cellParams) {
            cellParams = this.makeCellParams(position);

            this.cells.set(stringify(position), cellParams);
        }

        if (onCellParamsUpdateHandler) {
            this.updateHandlers.set(stringify(position), onCellParamsUpdateHandler);
        }

        return cellParams;
    }
}

function stringify(value: any): string {
    const sortedValue = Object.keys(value)
        .sort()
        .reduce((accumulator, key) => {
            accumulator[key] = value[key];

            return accumulator;
        }, {});

    return JSON.stringify(sortedValue);
}
