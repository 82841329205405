import * as React from 'react';
import classNames from 'classnames';
import * as moment from 'moment';

import * as style from './BriefStageWidget.scss';

import type { BriefStageForm } from '@store/autopilotDOOH/types';

import { OpacityTransition, LabeledInput, InputType, LabeledSelect, SelectItem } from 'sber-marketing-ui';

interface Props {
    readOnly: boolean;
    loading: boolean;
    isBudgetFocus: boolean;
    briefStageForm: BriefStageForm;
    datesRange: string;
    blockItems: SelectItem[];
    productItems: SelectItem[];
    regionalityItems: SelectItem[];
    onBlockChange: (value: string) => void;
    onProductChange: (value: string) => void;
    onBudgetChange: (value: string) => void;
    onBudgetFocus: () => void;
    onBudgetBlur: () => void;
    onStartDateChange: (value: moment.Moment) => void;
    onEndDateChange: (value: moment.Moment) => void;
    onRegionalityChange: (value: string) => void;
}

export const BriefStageWidgetTemplate = ({
    readOnly,
    loading,
    isBudgetFocus,
    briefStageForm,
    datesRange,
    blockItems,
    productItems,
    regionalityItems,
    onBlockChange,
    onProductChange,
    onBudgetChange,
    onBudgetFocus,
    onBudgetBlur,
    onStartDateChange,
    onEndDateChange,
    onRegionalityChange,
}: Props): JSX.Element => {
    return (
        <OpacityTransition start={!loading}>
            <div className={style.root}>
                <div className={style.title}>Бриф запуска РК</div>

                <div className={style.form}>
                    <div className={classNames(style.field, style.line)}>
                        <LabeledSelect
                            title={'Блок *'}
                            items={blockItems}
                            selectedValue={briefStageForm.blockId}
                            onSelection={onBlockChange}
                            readOnly={readOnly}
                        />
                    </div>

                    <div className={classNames(style.field, style.line)}>
                        <LabeledSelect
                            title={'Продукт *'}
                            items={productItems}
                            selectedValue={briefStageForm.productId}
                            onSelection={onProductChange}
                            readOnly={readOnly}
                        />
                    </div>

                    <div className={classNames(style.smallField, style.line)}>
                        <LabeledInput
                            title={'Бюджет на весь период *'}
                            value={briefStageForm.budget}
                            errorMessage={briefStageForm.budgetError}
                            displayError={
                                !!briefStageForm?.budget?.length &&
                                !!briefStageForm?.budgetError?.length &&
                                !isBudgetFocus
                            }
                            onValueChange={onBudgetChange}
                            onFocus={onBudgetFocus}
                            onBlur={onBudgetBlur}
                            onlyNumbers
                            readOnly={readOnly}
                        />
                    </div>

                    <div className={style.line}>
                        <div className={style.smallField}>
                            <LabeledInput
                                title={'Старт проекта *'}
                                value={moment(briefStageForm.dateStart)}
                                maxDate={moment(briefStageForm.dateEnd)}
                                type={InputType.DatePicker}
                                onValueChange={onStartDateChange}
                                readOnly={readOnly}
                            />
                        </div>

                        <span className={style.separator}>—</span>

                        <div className={style.smallField}>
                            <LabeledInput
                                title={'Окончание проекта *'}
                                value={moment(briefStageForm.dateEnd)}
                                minDate={moment(briefStageForm.dateStart)}
                                type={InputType.DatePicker}
                                onValueChange={onEndDateChange}
                                readOnly={readOnly}
                            />

                            {datesRange && <div className={style.fieldMessage}>Длительность проекта: {datesRange}</div>}
                        </div>
                    </div>

                    <div className={classNames(style.field, style.line)}>
                        <div className={style.fieldTitle}>Целевая аудитория *</div>

                        <div className={style.fieldTextValue}>All 18+</div>
                    </div>

                    <div className={classNames(style.field, style.line)}>
                        <LabeledSelect
                            title={'Региональность'}
                            items={regionalityItems}
                            selectedValue={briefStageForm.regionalityId}
                            onSelection={onRegionalityChange}
                            readOnly={readOnly}
                        />
                    </div>
                </div>
            </div>
        </OpacityTransition>
    );
};
