// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilotDOOH-StageWidgets-MediaplanSettingsStageWidget-RegionsTable-CellTypes-CheckboxCell-_CheckboxCell__root{height:100%;width:100%;display:flex;align-items:center;background-color:#ffffff;padding:0 6px;user-select:none;cursor:pointer}.src-client-modules-activity-pages-autopilotDOOH-StageWidgets-MediaplanSettingsStageWidget-RegionsTable-CellTypes-CheckboxCell-_CheckboxCell__iconWrapper{width:16px;height:16px}.src-client-modules-activity-pages-autopilotDOOH-StageWidgets-MediaplanSettingsStageWidget-RegionsTable-CellTypes-CheckboxCell-_CheckboxCell__icon{width:24px;height:24px;transform:translate(-4px, -4px)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilotDOOH/StageWidgets/MediaplanSettingsStageWidget/RegionsTable/CellTypes/CheckboxCell/CheckboxCell.scss"],"names":[],"mappings":"AAAA,mJACI,WAAY,CACZ,UAAW,CAEX,YAAa,CACb,kBAAmB,CACnB,wBAAyB,CAEzB,aAAc,CAEd,gBAAiB,CACjB,cAAe,CAClB,0JAGG,UAAW,CACX,WAAY,CACf,mJAGG,UAAW,CACX,WAAY,CACZ,+BAAgC","sourcesContent":[".root {\n    height: 100%;\n    width: 100%;\n\n    display: flex;\n    align-items: center;\n    background-color: #ffffff;\n\n    padding: 0 6px;\n\n    user-select: none;\n    cursor: pointer;\n}\n\n.iconWrapper {\n    width: 16px;\n    height: 16px;\n}\n\n.icon {\n    width: 24px;\n    height: 24px;\n    transform: translate(-4px, -4px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilotDOOH-StageWidgets-MediaplanSettingsStageWidget-RegionsTable-CellTypes-CheckboxCell-_CheckboxCell__root",
	"iconWrapper": "src-client-modules-activity-pages-autopilotDOOH-StageWidgets-MediaplanSettingsStageWidget-RegionsTable-CellTypes-CheckboxCell-_CheckboxCell__iconWrapper",
	"icon": "src-client-modules-activity-pages-autopilotDOOH-StageWidgets-MediaplanSettingsStageWidget-RegionsTable-CellTypes-CheckboxCell-_CheckboxCell__icon"
};
export default ___CSS_LOADER_EXPORT___;
