import * as React from 'react';
import classNames from 'classnames';

import * as style from '../DateFilter.scss';

interface Props {
    year: number;
    quarter: number;
    width: number;
    onClick(): void;
}

export const Quarter: React.SFC<Props> = ({ year, quarter, width, onClick }: Props) => {
    const label = `${quarter} квартал ${year}`;

    return (
        <div
            className={classNames(style.sliderItem, style.button)}
            style={{ width: `${width * 100}%` }}
            onClick={onClick}
            {...{
                'qa-id': 'quarter',
                'qa-label': label,
            }}
        >
            {label}
        </div>
    );
};
