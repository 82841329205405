import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { combineReducers } from 'redux';
import { UserCard } from 'sber-marketing-types/frontend';
import { castArray, Dictionary, stubObject, keyBy } from 'lodash';

import { loadUsers, addUsers, resetUsers, setLoadingStatus } from './actions';
import { AppUsers } from './types';
import { LoadingStatus } from '@store/commonTypes';
import { resetStore } from '@store/common';

const status = reducerWithInitialState<LoadingStatus>(LoadingStatus.NOT_LOADED)
    .cases([resetStore, resetUsers], () => LoadingStatus.NOT_LOADED)
    .case(setLoadingStatus, (loadingStatus) => loadingStatus)
    .case(loadUsers.failed, () => LoadingStatus.ERROR)
    .cases([loadUsers.done, addUsers], () => LoadingStatus.LOADED);

const error = reducerWithInitialState<Error | null>(null)
    .cases([loadUsers.done, loadUsers.started, resetUsers, resetStore], () => null)
    .case(loadUsers.failed, (_, { error }) => error);

const entities = reducerWithInitialState<Dictionary<UserCard>>({})
    .cases([resetStore, resetUsers], stubObject)
    .case(addUsers, (state, payload) => {
        const usersToAdd = keyBy(castArray(payload), 'id');
        return { ...state, ...usersToAdd };
    });

export const appUsers = combineReducers<AppUsers>({
    status,
    error,
    entities,
});
