import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { StoreState } from '@store';
import {
    getDashboardPageState,
    setFilterPeriodAsync,
    SetFilterPeriodPayload,
    SetFilterPeriodResult,
} from '@store/leaderDashboardPage';
import { utilsByMode } from '@store/leaderDashboardPage/utils';

import { DepartmentApi, LeaderDashboardApi, UserApi } from '@api';

export const setFilterPeriod = bindThunkAction<StoreState, SetFilterPeriodPayload, SetFilterPeriodResult, Error>(
    setFilterPeriodAsync,
    async ({ filterPeriod, mode }, _, getState) => {
        const { selectedBy, selectedDate } = getDashboardPageState(getState());

        let utils;
        let dataResponse;
        let userIdsFromStatistics;

        if (mode === 'activities') {
            utils = utilsByMode['activities'];

            dataResponse = await LeaderDashboardApi.getActivitiesData({
                period: filterPeriod,
                by: selectedBy,
                currentDate: selectedDate,
            });

            userIdsFromStatistics = utils.getUsersIdsFromStatistics(dataResponse);
        } else {
            utils = utilsByMode['tasks'];

            dataResponse = await LeaderDashboardApi.getTasksData({
                period: filterPeriod,
                by: selectedBy,
                currentDate: selectedDate,
            });

            userIdsFromStatistics = utils.getUsersIdsFromStatistics(dataResponse);
        }

        const [users, departments] = await Promise.all([
            await UserApi.getUserList(userIdsFromStatistics),
            await DepartmentApi.getList(),
        ]);

        return {
            filterPeriod,
            mode,
            data: {
                users,
                departments: utils.filterDepartmentResponseByIds(departments, utils.getDepartmentsIdsFromUsers(users)),
                statistics: dataResponse,
            },
        };
    },
);
