import * as React from 'react';

import * as styles from './SidebarButton.scss';

// import { Icon, IconType } from 'sber-marketing-ui';
import { useStore } from './useStore';

export const SidebarButton: React.FC = () => {
    const { openedRightSidebar, openRightSidebar, closeRightSidebar } = useStore();

    const onClick = React.useCallback(() => {
        if (openedRightSidebar) {
            closeRightSidebar();
        } else {
            openRightSidebar();
        }
    }, [openedRightSidebar, openRightSidebar, closeRightSidebar]);

    return (
        <div className={styles.root} onClick={onClick}>
            {/* <Icon type={IconType.ARROW16} svgSize={16} /> */}

            {openedRightSidebar ? 'Свернуть' : 'Смотреть подробнее'}
        </div>
    );
};
