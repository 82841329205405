import * as React from 'react';
import { UserResponseParams } from 'sber-marketing-types/frontend';
import { BudgetItemSnapshot, BudgetItemStatus } from '@mrm/budget';
import { Icon, IconType, StaticSkeleton } from 'sber-marketing-ui';

import { DatesFormatter } from '@common/Utils';

import * as styles from './TitleRow.scss';

export function shouldRender(diffs: string[]): boolean {
    return diffs.some((diff) => diff === 'status');
}

interface Props {
    diffs: string[];
    curr: BudgetItemSnapshot;
    prev: BudgetItemSnapshot;
}

export function TitleRow({ diffs, curr, prev }: Props): JSX.Element {
    const { creationTime } = prev;

    return (
        <div className={styles.root}>
            {shouldRender(diffs) ? <StatusChange status={curr.status} /> : <SnapshotInitiator curr={curr} />}

            <div className={styles.date}>
                {DatesFormatter.ddMonthyyyy(creationTime)},&nbsp;
                {DatesFormatter.hhmm(creationTime)}
            </div>
        </div>
    );
}

interface StatusChangeProps {
    status: BudgetItemStatus;
}

function StatusChange({ status }: StatusChangeProps): JSX.Element {
    let title: string;
    let icon: IconType;

    switch (status) {
        case BudgetItemStatus.Draft:
            title = 'Строка переведена в черновик';
            break;
        case BudgetItemStatus.Published:
            title = 'Строка опубликована';
            break;
        case BudgetItemStatus.Approved:
            title = 'Строка согласована';
            icon = IconType.APPROVED_ICON;
            break;
        case BudgetItemStatus.Rejected:
            title = 'Строка отклонена';
            icon = IconType.REJECTED_ICON;
            break;
        case BudgetItemStatus.OnExpertApprovement:
            title = 'Строка отправлена на утверждение эксперту';
            break;
        case BudgetItemStatus.OnExecution:
            title = 'Строка переведена в исполнение';
            break;
        case BudgetItemStatus.Disabled:
            title = 'Строка удалена';
            icon = IconType.DELETED_ICON;
            break;
        case BudgetItemStatus.Archived:
            title = 'Строка архивирована';
            break;
    }

    return (
        <React.Fragment>
            {icon ? <Icon type={icon} svgSize={16} className={styles.statucChangeIcon} /> : null}

            <span className={styles.title}>{title}</span>
        </React.Fragment>
    );
}

type SnapshotInitiatorProps = Pick<Props, 'curr'>;

function SnapshotInitiator({ curr }: SnapshotInitiatorProps): JSX.Element {
    const { firstName, secondName, departmentName } = curr.initiator as UserResponseParams;
    const title = `${firstName} ${secondName}, ${departmentName}`;

    return (
        <React.Fragment>
            <Icon type={IconType.USER16} svgSize={16} className={styles.icon} />

            <span title={title} className={styles.title}>
                {firstName} {secondName},&nbsp;
                <span className={styles.subtitle}>{departmentName}</span>
            </span>
        </React.Fragment>
    );
}

export function TitleRowSkeleton(): JSX.Element {
    return (
        <div className={styles.root}>
            <StaticSkeleton loadingClassName={styles.titleSkeleton} />
            <StaticSkeleton loadingClassName={styles.dateSkeleton} />
        </div>
    );
}
