import { useGetAuthUserQuery } from '@api';

export interface UseAuthUserId {
    isLoading: boolean;
    authUserId?: number;
}

export function useAuthUserId(skip?: boolean): UseAuthUserId {
    const { isLoading, data: authUser } = useGetAuthUserQuery(undefined, { skip });

    return {
        isLoading,
        authUserId: authUser?.user.attributes.id,
    };
}
