// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-dashboards-MyProjectsPage-_MyProjectsPage__root{position:relative}.src-client-modules-dashboards-MyProjectsPage-_MyProjectsPage__sortingMenu{margin-top:34px;display:block}.src-client-modules-dashboards-MyProjectsPage-_MyProjectsPage__activityListWrapper{display:block}\n", "",{"version":3,"sources":["webpack://./src/client/modules/dashboards/MyProjectsPage/MyProjectsPage.scss"],"names":[],"mappings":"AAAA,oEACI,iBAAkB,CACrB,2EAGG,eAAgB,CAChB,aAAc,CACjB,mFAGG,aAAc","sourcesContent":[".root {\n    position: relative;\n}\n\n.sortingMenu {\n    margin-top: 34px;\n    display: block;\n}\n\n.activityListWrapper {\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-dashboards-MyProjectsPage-_MyProjectsPage__root",
	"sortingMenu": "src-client-modules-dashboards-MyProjectsPage-_MyProjectsPage__sortingMenu",
	"activityListWrapper": "src-client-modules-dashboards-MyProjectsPage-_MyProjectsPage__activityListWrapper"
};
export default ___CSS_LOADER_EXPORT___;
