import { v4 } from 'uuid';
import { Tag, TagColorType } from '@mrm/tags';

import { MrmClient } from '@api';

import { TagColors } from '@store/tags';

import { TagsEditorInstanceDescriptor } from './types';

function getTagColor(): TagColorType {
    const index = Math.ceil(Math.random() * (TagColors.length - 1));

    return TagColors[index];
}

export function makeTag(): Tag {
    return {
        id: v4(),
        title: '',
        color: getTagColor(),
    };
}

export function stringifyDescriptor(descriptor: TagsEditorInstanceDescriptor): string {
    if (descriptor.activityId !== undefined) {
        return `activity-${descriptor.activityId}`;
    } else if (descriptor.taskId !== undefined) {
        return `task-${descriptor.taskId}`;
    } else if (descriptor.executionBudgetItemId) {
        return `executionBudgetItem-${descriptor.executionBudgetItemId}`;
    } else if (descriptor.planBudgetItemId) {
        return `planBudgetItem-${descriptor.planBudgetItemId}`;
    } else {
        console.warn(`Missing case for TagsEditorInstanceDescriptor: ${JSON.stringify(descriptor)}`);

        return null;
    }
}

export async function getActivity(id: number) {
    const client = await MrmClient.getInstance();

    return client.domain.projects.getProject({ id });
}

export async function getTask(id: string) {
    const client = await MrmClient.getInstance();

    return client.domain.projects.getTask({ id });
}

export async function getBudgetItem(id: string) {
    const client = await MrmClient.getInstance();

    return (await client.domain.budgets.getBudgetItems({ budgetItemIds: [id] }))?.[0];
}
