import { actionCreatorFactory } from 'typescript-fsa';

import {
    DashboardUserConfig,
    UpdateDashboardUserConfig,
    TasksFilterIds,
    ActivitiesFilterIds,
    TasksFilter,
    ActivitiesFilter,
} from './types';

export const actionCreator = actionCreatorFactory('USER_CONFIG_DASHBOARD');

export const loadDashboardUserConfig = actionCreator.async<DashboardUserConfig, DashboardUserConfig, Error>(
    'LOAD_DASHBOARD_USER_CONFIG',
);
export const updateDashboardUserConfig = actionCreator.async<UpdateDashboardUserConfig, void, Error>(
    'UPDATE_DASHBOARD_USER_CONFIG',
);
export const mergeMyTasksPageUserConfig = actionCreator.async<void, TasksFilterIds, Error>(
    'MERGE_MY_TASKS_PAGE_USER_CONFIG',
);
export const mergeAvailableActivitiesPageUserConfig = actionCreator.async<void, ActivitiesFilterIds, Error>(
    'MERGE_AVAILABLE_ACTIVITIES_PAGE_USER_CONFIG',
);
export const mergeMyActivitiesPageUserConfig = actionCreator.async<void, ActivitiesFilterIds, Error>(
    'MERGE_MY_ACTIVITIES_PAGE_USER_CONFIG',
);
export const resetDashboardUserConfig = actionCreator('RESET_DASHBOARD_USER_CONFIG');

export const resetMyTasksFilters = actionCreator('RESET_MY_TASKS_FILTER');
export const resetAvailableActivitiesFilters = actionCreator('RESET_AVAILABLE_ACTIVITIES_FILTERS');
export const resetMyActivitiesFilters = actionCreator('RESET_MY_ACTIVITIES_FILTERS');
export const resetRawFilters = actionCreator('RESET_RAW_FILTERS');
export const setRawMyTasksFilter = actionCreator<Partial<TasksFilter>>('SET_RAW_MY_TASK_FILTER');
export const setRawAvailableActivitiesFilter = actionCreator<Partial<ActivitiesFilter>>(
    'SET_RAW_AVAILABLE_ACTIVITIES_FILTER',
);
export const setRawMyActivitiesFilter = actionCreator<Partial<ActivitiesFilter>>('SET_RAW_MY_ACTIVITIES_FILTER');
