import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { includes } from 'lodash';
import { TaskStatus } from 'sber-marketing-types/frontend';

import { StoreState } from '../../../../../store';
import { selectors, Channel, editCommentary } from '../../../../../store/taskPage';
import { getLoginUser } from '../../../../../store/user';
import { ChannelButtonsListContainer } from './ChannelButtonsListContainer';
import {
    ChannelButtonsListMappedState,
    ChannelButtonsListMappedActions,
    ChannelButtonsListStoreProps,
    ChannelData,
} from './types';
import { withTaskData } from '../withTaskData';

const mapChannel = ({ id, title: name, authorId }: Channel): ChannelData => ({ id, name, authorId });

const filterChannel = ({ id, authorId, participantIds }: Channel, userId: number): boolean =>
    id === null || userId === authorId || includes(participantIds, userId);

const mapStateToProps = (state: StoreState): ChannelButtonsListMappedState => {
    const userId = getLoginUser(state).attributes.id;
    return {
        isTaskOpened: selectors.getTaskInfo(state).status === TaskStatus.InProgress,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<StoreState>): ChannelButtonsListMappedActions => {
    return {
        resetComment: () =>
            dispatch(
                editCommentary({
                    content: '',
                }),
            ),
    };
};

const withStore = connect(mapStateToProps, mapDispatchToProps);

/** Channel buttons list with adding button */
export const ChannelButtonsListStore: ComponentType<ChannelButtonsListStoreProps> = withTaskData(
    withStore(ChannelButtonsListContainer),
);
