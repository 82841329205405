import * as React from 'react';

import type { LotContentProps } from '../types';

import { LabeledSelect, LabeledInput } from 'sber-marketing-ui';
import { LotSelector } from '../CustomFileds/LotSelector';

import * as styles from './styles.scss';

interface Props extends LotContentProps {}

export const FirstLotContent: React.FC<Props> = ({ fields, onFieldChange }) => {
    return (
        <div className={styles.root}>
            <LotSelector
                field={fields.lot}
                onChange={(value) => onFieldChange({ type: 'lot', value: value?.toString() || null })}
            />

            <div className={styles.line}>
                <LabeledInput
                    title={fields.description.label}
                    value={fields.description.value}
                    onValueChange={(value) => onFieldChange({ type: 'description', value: value?.toString() || null })}
                />
            </div>

            <div className={styles.line}>
                <div className={styles.field}>
                    <LabeledSelect
                        title={fields.initiator.label}
                        items={fields.initiator.items || []}
                        selectedValue={fields.initiator.value}
                        onSelection={(value) => onFieldChange({ type: 'initiator', value: value as string[] })}
                        disabled={fields.initiator.disabled}
                        allowMultipleItems
                    />
                </div>

                <div className={styles.field}>
                    <LabeledSelect
                        title={fields.agencyExecutors.label}
                        items={fields.agencyExecutors.items || []}
                        selectedValue={fields.agencyExecutors.value}
                        disabled={fields.agencyExecutors.disabled}
                        onSelection={(value) =>
                            onFieldChange({ type: 'agencyExecutors', value: (value as string[]) || [] })
                        }
                        allowMultipleItems
                    />
                </div>

                <div className={styles.field}>
                    <LabeledSelect
                        title={fields.expert.label}
                        items={fields.expert.items || []}
                        disabled={true}
                        selectedValue={fields.expert.value}
                        onSelection={(value) => onFieldChange({ type: 'expert', value: value as string[] })}
                        allowMultipleItems
                    />
                </div>

                <div className={styles.field}>
                    <LabeledInput
                        title={fields.customer.label}
                        value={fields.customer.value}
                        onValueChange={(value) => onFieldChange({ type: 'customer', value: value?.toString() || null })}
                    />
                </div>

                <div className={styles.field}>
                    <LabeledSelect
                        title={fields.contract.label}
                        items={fields.contract.items || []}
                        selectedValue={fields.contract.value}
                        onSelection={(value) => onFieldChange({ type: 'contract', value: value as string })}
                    />
                </div>
            </div>
        </div>
    );
};
