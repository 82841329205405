import * as React from 'react';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { ColumnName } from '@store/budgetPlanning/types';

import { ColumnFixingMenu } from './ColumnFixingMenu';
import { StoreState } from '@store';
import { getBudgetPlanningPageState } from '@store/budgetPlanning/selectors';

interface Props extends Partial<MapProps> {
    columnName: ColumnName;
    onColumnFixChange?: () => void;
}

interface MapProps {
    isFixed: boolean;
}

interface State {
    isColumnFixed: boolean;
}

@(connect(mapStateToProps) as any)
export class ColumnFixingMenuContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isColumnFixed: this.props.isFixed || false,
        };
    }

    public componentDidUpdate(prevProps: Props): void {
        const columnFixChanged = this.props.isFixed !== prevProps.isFixed;

        if (columnFixChanged) {
            this.setState({
                isColumnFixed: this.props.isFixed,
            });
        }
    }

    public render(): JSX.Element {
        return React.createElement(ColumnFixingMenu, {
            isColumnFixed: this.state.isColumnFixed,
            onColumnFixToggle: this.onColumnFixToggle,
        });
    }

    @autobind
    protected onColumnFixToggle(checked: boolean) {
        this.setState(
            {
                isColumnFixed: checked,
            },
            () => {
                if (this.props.onColumnFixChange) {
                    this.props.onColumnFixChange();
                }
            },
        );
    }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
    const { fixedColumnsNames } = getBudgetPlanningPageState(state);

    return {
        isFixed: lodash.includes(fixedColumnsNames, ownProps.columnName),
    };
}
