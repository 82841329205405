import * as React from 'react';
import classNames from 'classnames';

import * as style from './AutopilotStatusButton.scss';

import { ConfirmModal } from '../../ConfirmModal';
import { useHooks } from './hooks';

export const AutopilotStatusButton: React.FC = () => {
    const {
        displayAutopilotEnableButton,
        displayAutopilotDisableButton,
        displayAutopilotEnableConfirmModal,
        displayAutopilotDisableConfirmModal,
        onAutopilotButtonClick,
        onAutopilotEnableConfirm,
        onAutopilotDisableConfirm,
        onModalCancel,
    } = useHooks();

    return (
        <div className={style.root}>
            {displayAutopilotEnableButton && (
                <div
                    className={classNames(style.autopilotStatusButton, style.enableButton)}
                    onClick={onAutopilotButtonClick}
                >
                    Включить автопилот
                </div>
            )}

            {displayAutopilotDisableButton && (
                <div
                    className={classNames(style.autopilotStatusButton, style.disableButton)}
                    onClick={onAutopilotButtonClick}
                >
                    Выключить автопилот
                </div>
            )}

            {displayAutopilotEnableConfirmModal && (
                <ConfirmModal
                    title={'Внимание!'}
                    message={'Вы уверены, что хотите включить автопилот?'}
                    closeButtonText={'Отменить'}
                    confirmButtonText={'Включить'}
                    onCloseButtonClick={onModalCancel}
                    onConfirmButtonClick={onAutopilotEnableConfirm}
                />
            )}

            {displayAutopilotDisableConfirmModal && (
                <ConfirmModal
                    title={'Внимание!'}
                    message={'Вы уверены, что хотите выключить автопилот?'}
                    closeButtonText={'Отменить'}
                    confirmButtonText={'Выключить'}
                    onCloseButtonClick={onModalCancel}
                    onConfirmButtonClick={onAutopilotDisableConfirm}
                />
            )}
        </div>
    );
};
