import * as React from 'react';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import { UserResponseParams } from 'sber-marketing-types/frontend';
import { BudgetItem } from '@mrm/budget';

import { MultiReferenceDictionaryApi } from '@api';

import { StoreState } from '@store';
import {
    ColumnsNameWithCodes,
    GroupedDictionaries,
    ColumnName,
    CustomColumnColor,
    getBudgetPlanningPageState,
    getBudgetItemByLineId,
} from '@store/budgetPlanning';

import { ResetRegionalityLinksTooltip, ResetBlockLinksTooltip } from '@common/ResetLinksGroupTooltip';

import { CellBackgroundColor } from '../../LayerManager';
import { ColumnsList } from '../../../ColumnsConfig';

import { DropdownCell, DropdownContent, DropdownCellOptions } from './DropdownCell';

const DROPDOWN_OPTION_HEIGHT = 35;
const DROPDOWN_TOTAL_MARGIN_HEIGHT = 14;
const DROPDOWN_TOTAL_BORDER_HEIGHT = 2;
const DROPDOWN_MAX_LINES = 6;
const MAX_DROPDOWN_HEIGHT = DROPDOWN_MAX_LINES * DROPDOWN_OPTION_HEIGHT + DROPDOWN_TOTAL_MARGIN_HEIGHT;

interface Props extends OwnProps, Partial<MapProps> {}

interface OwnProps {
    lineId: string;
    columnName: ColumnName;
    customTitle?: string;
    options: DropdownCellOptions[];
    selectedId: string;
    bgColor: CellBackgroundColor | CustomColumnColor;
    disabled?: boolean;
    allowMultipleItems?: boolean;
    allUsers: UserResponseParams[];
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi;
    originalValue: string;
    onDropdownClick: (
        columnName: ColumnName,
        lineId: string,
        dropdownContent: JSX.Element,
        contentHeight: number,
    ) => void;
    onSelection: (lineId: string, columnName: ColumnName, selectedId: string, originalId: string) => void;
}

interface MapProps {
    budgetItem: BudgetItem;
    dictionaries: GroupedDictionaries;
}

interface State {
    isOpened: boolean;
    filter: string;
}

@(connect(mapStateToProps, null, null, { forwardRef: true }) as any)
export class DropdownCellContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isOpened: false,
            filter: '',
        };
    }

    public focus(): void {
        this.onOpenerClick();
    }

    public render(): JSX.Element {
        const { options, disabled, columnName } = this.props;

        const isOptionsEmpty = lodash.isEmpty(options);

        return React.createElement(DropdownCell, {
            columnName,
            title: this.getTitle(),
            filter: this.state.filter,
            isOpened: this.state.isOpened,
            isDisabled: isOptionsEmpty || disabled,
            bgColor: this.props.bgColor,
            onOpenerClick: this.onOpenerClick,
            onMaskClick: this.onMaskClick,
            onSearchChange: this.onSearchChange,
        });
    }

    @autobind
    protected onOpenerClick() {
        this.setState(
            {
                isOpened: !this.state.isOpened,
                filter: this.state.isOpened ? '' : this.state.filter,
            },
            () => this.updateDropdown(this.props.selectedId),
        );
    }

    @autobind
    protected updateDropdown(selectedId: string) {
        if (this.props.onDropdownClick && this.state.isOpened && this.props.options.length > 0) {
            const filteredOptions = this.getFilteredOptions();
            const contentHeight = this.getDropdownHeight();
            const content = DropdownContent({
                options: filteredOptions,
                selectedId,
                maxDropdownHeight: MAX_DROPDOWN_HEIGHT,
                allowMultipleItems: this.props.allowMultipleItems,
                onOptionClick: this.onOptionClick,
            });

            this.onDropdownClick(content, contentHeight);
        }
    }

    @autobind
    protected getFilteredOptions(): DropdownCellOptions[] {
        const { options, allowMultipleItems } = this.props;
        const { filter } = this.state;
        const includeEmptyOption = !allowMultipleItems;

        const filteredOptions =
            filter == ''
                ? options
                : options.filter((item) => lodash.includes(item.title.toLowerCase(), filter.toLowerCase()));

        return includeEmptyOption ? [this.getEmptyOption(), ...filteredOptions] : filteredOptions;
    }

    @autobind
    protected onMaskClick() {
        this.setState(
            {
                isOpened: false,
                filter: '',
            },
            () => {
                if (this.props.onDropdownClick) {
                    this.onDropdownClick(null, null);
                }
            },
        );
    }

    @autobind
    protected onSearchChange(value: string) {
        this.setState(
            {
                filter: value,
            },
            () => this.updateDropdown(this.props.selectedId),
        );
    }

    @autobind
    protected onOptionClick(optionId: string) {
        const { lineId, columnName, selectedId, allowMultipleItems, options, originalValue } = this.props;

        this.setState(
            {
                isOpened: false,
                filter: '',
            },
            () => {
                let selectedIds;

                if (allowMultipleItems) {
                    const selectedItemIds = selectedId ? selectedId.split(',') : [];
                    const updatedIds = selectedItemIds.includes(optionId)
                        ? selectedItemIds.filter((id) => id !== optionId)
                        : [...selectedItemIds, optionId];
                    const sortedUpdatedIds = lodash.orderBy(updatedIds, (id) => {
                        const option = options.find((option) => option.id === id);

                        return option ? option.title : null;
                    });
                    selectedIds = sortedUpdatedIds.join() || null;

                    this.props.onSelection(lineId, columnName, selectedIds, originalValue);
                } else if (selectedId !== optionId) {
                    selectedIds = optionId;

                    this.props.onSelection(lineId, columnName, selectedIds, originalValue);
                }

                this.onDropdownClick(null, null);
            },
        );
    }

    private getTitle(): string {
        const { columnName, allUsers, customTitle, options, selectedId, allowMultipleItems, budgetItem, dictionaries } =
            this.props;

        if (customTitle) {
            return customTitle;
        }

        if (!selectedId) {
            return null;
        }

        if (allowMultipleItems) {
            const selectedIds = selectedId ? selectedId.split(',') : [];

            if (columnName === ColumnName.Responsible) {
                return selectedIds
                    .map((id) => allUsers.find((user) => String(user.id) === id))
                    .map((user) => (user ? `${user.secondName} ${user.firstName}` : 'Значение не задано'))
                    .join(', ');
            } else {
                const selectedOptions = selectedIds.map((id) => options.find((option) => option.id === id));

                return selectedOptions.map((option) => (option ? option.title : 'Значение не найдено')).join(', ');
            }
        }

        const selectedOption = options?.find((option) => option.id === selectedId);

        if (selectedOption) {
            return selectedOption.title;
        }

        const column = ColumnsList.find((column) => column.name === columnName);
        const budgetItemDictionary = dictionaries.byId[budgetItem?.dictionary?.[column.metaData?.dictionaryType]?.id];

        if (budgetItemDictionary) {
            return ColumnsNameWithCodes.includes(columnName) ? budgetItemDictionary.code : budgetItemDictionary.value;
        }

        return null;
    }

    private getDropdownHeight(): number {
        const filteredOptions = this.getFilteredOptions();

        let result =
            filteredOptions.length * DROPDOWN_OPTION_HEIGHT +
            DROPDOWN_TOTAL_MARGIN_HEIGHT +
            DROPDOWN_TOTAL_BORDER_HEIGHT;

        result = result > MAX_DROPDOWN_HEIGHT ? MAX_DROPDOWN_HEIGHT : result;

        return result;
    }

    private getEmptyOption(): DropdownCellOptions {
        const { columnName, selectedId, multiReferenceDictionaryApi } = this.props;

        const column = ColumnsList.find((column) => column.name === columnName);

        let result: DropdownCellOptions = { id: null, title: null };
        if (column.metaData.dictionaryType) {
            const addResetRegionalityLinksItem =
                selectedId && multiReferenceDictionaryApi.dictionaryIsRegionalityBased(column.metaData.dictionaryType);
            const addResetBlockLinksItem =
                selectedId && multiReferenceDictionaryApi.dictionaryIsBlockBased(column.metaData.dictionaryType);

            if (addResetRegionalityLinksItem) {
                result = {
                    id: null,
                    title: 'Сброс связей',
                    tooltip: <ResetRegionalityLinksTooltip />,
                    style: { fontWeight: 600 },
                };
            } else if (addResetBlockLinksItem) {
                result = {
                    id: null,
                    title: 'Сброс связей',
                    tooltip: <ResetBlockLinksTooltip />,
                    style: { fontWeight: 600 },
                };
            }
        }

        return result;
    }

    @autobind
    protected onDropdownClick(dropdownContent: JSX.Element, contentHeight: number) {
        this.props.onDropdownClick(this.props.columnName, this.props.lineId, dropdownContent, contentHeight);
    }
}

function mapStateToProps(state: StoreState, { lineId }: OwnProps): MapProps {
    return {
        budgetItem: getBudgetItemByLineId(state, lineId),
        dictionaries: getBudgetPlanningPageState(state).pageData.allDictionaries,
    };
}
