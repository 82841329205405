import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconType, WithTooltip, TooltipAnchor } from 'sber-marketing-ui';

import * as styles from './SubscribeButton.scss';

const ICON_SIZE = 24;
const ENABLED_LABEL = 'Оповещения включены';
const DISABLED_LABEL = 'Оповещения отключены';

interface Props {
    isDisabled?: boolean;
    isHovered?: boolean;
    isUserSubscribed?: boolean;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    onMouseOver?: React.MouseEventHandler<HTMLDivElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
}

export function SubscribeButton({
    isDisabled,
    isHovered,
    isUserSubscribed,
    className,
    onClick,
    onMouseOver,
    onMouseLeave,
}: Props): JSX.Element {
    const iconType = isHovered
        ? IconType.NOTIFICATIONS_HOVER
        : isUserSubscribed
        ? IconType.NOTIFICATIONS_ACTIVE
        : IconType.NOTIFICATIONS_NORMAL;

    return (
        <React.Fragment>
            {!isDisabled && (
                <div
                    className={classNames(styles.root, className)}
                    onClick={onClick}
                    onMouseOver={onMouseOver}
                    onMouseLeave={onMouseLeave}
                    {...{
                        'qa-id': 'taskSubscribeButton',
                        'qa-is-selected': isUserSubscribed ? 'true' : 'false',
                    }}
                >
                    <WithTooltip content={isUserSubscribed ? ENABLED_LABEL : DISABLED_LABEL} anchor={TooltipAnchor.TOP}>
                        <div className={styles.icon}>
                            <Icon
                                type={iconType}
                                svgSize={ICON_SIZE}
                                color={isUserSubscribed ? '#F6A523' : 'transparent'}
                            />
                        </div>
                    </WithTooltip>
                </div>
            )}
        </React.Fragment>
    );
}
