import * as React from 'react';
import { compose, graphql } from 'react-apollo';
import { get } from 'lodash';

import { Queries } from '@api';

import * as queries from './query';

export interface ChildrenProps {
    enableControls: boolean;
    hasProjectAccess: boolean;
    hasBudgetItemAccess: boolean;
}

interface Props extends ExternalProps, QueryProps {}

interface ExternalProps {
    activityId: number;
    cardId: string;
    userHasBudgetAccess: boolean;
    children: (props: ChildrenProps) => JSX.Element;
}

interface QueryProps {
    withProjectQuery: {
        project: {
            nodes: {
                id: number;
            }[];
        };
    };
    withRequestQuery: {
        request: {
            nodes: RequestProps[];
        };
    };
}

interface RequestProps {
    project: {
        permissions: {
            read: boolean;
            write: boolean;
        };
    };
    budgetItem: {
        id: string;
        budget: {
            id: string;
        };
        permissions: {
            read: boolean;
            write: boolean;
        };
    };
}

interface State {
    budgetItemWasFetched: boolean;
    hasBudgetItemAccess: boolean;
}

class BudgetItemToProjectCardConnectedWrapper extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            budgetItemWasFetched: false,
            hasBudgetItemAccess: false,
        };
    }

    public async componentDidUpdate(): Promise<void> {
        const { withRequestQuery } = this.props;
        const { budgetItemWasFetched } = this.state;

        const budgetId = get(withRequestQuery, 'request.nodes[0].budgetItem.budget.id') || null;
        const budgetItemId = get(withRequestQuery, 'request.nodes[0].budgetItem.id') || null;

        if (!budgetItemWasFetched && budgetId && budgetItemId) {
            await this.requestBudgetItemAndUpdateStatus(budgetId, budgetItemId);
        }
    }

    public render(): JSX.Element {
        return this.props.children(this.getChildrenProps());
    }

    private getChildrenProps(): ChildrenProps {
        const enableControls = !!get(this.props.withProjectQuery, 'project.nodes.length');
        const { hasBudgetItemAccess } = this.state;

        let hasProjectAccess = false;
        const request: RequestProps = get(this.props.withRequestQuery, 'request.nodes[0]');
        if (request) {
            hasProjectAccess = request.project.permissions.read && request.project.permissions.write;
        }

        return {
            enableControls,
            hasProjectAccess,
            hasBudgetItemAccess,
        };
    }

    private async requestBudgetItemAndUpdateStatus(budgetId: string, budgetItemId: string): Promise<void> {
        const { userHasBudgetAccess } = this.props;

        let hasBudgetItemAccess = false;

        if (userHasBudgetAccess && budgetId && budgetItemId) {
            hasBudgetItemAccess = await Queries.userHasBudgetItemReadPermission(budgetId, budgetItemId);
        }

        this.setState({
            budgetItemWasFetched: true,
            hasBudgetItemAccess,
        });
    }
}

export const BudgetItemToProjectCardConnected = compose(
    graphql<ExternalProps>(queries.WITH_PROJECT_QUERY, {
        name: 'withProjectQuery',
        options: ({ activityId }) => ({
            variables: {
                id: activityId,
            },
        }),
        skip: ({ activityId }) => !activityId,
    }),
    graphql<ExternalProps>(queries.WITH_REQUEST_QUERY, {
        name: 'withRequestQuery',
        options: ({ cardId }) => ({
            variables: {
                id: cardId,
            },
        }),
        skip: ({ cardId }) => !cardId,
    }),
)(BudgetItemToProjectCardConnectedWrapper) as React.ComponentType<ExternalProps>;
