import * as React from 'react';
import classNames from 'classnames';

import { Flex, FlexProps } from '@common/components';

import * as styles from './Scrollbar.scss';

export type ScrollbarProps<E extends keyof JSX.IntrinsicElements = 'div'> = FlexProps<E> & {
    minHeight?: React.CSSProperties['minHeight'];
    maxHeight?: React.CSSProperties['maxHeight'];
    minWidth?: React.CSSProperties['minWidth'];
    maxWidth?: React.CSSProperties['maxWidth'];
    overflow?: React.CSSProperties['overflow'];
    gutter?: React.CSSProperties['scrollbarGutter'];
};

export function Scrollbar<E extends keyof JSX.IntrinsicElements = 'div'>({
    className,
    children,
    overflow = 'auto',
    minHeight,
    maxHeight,
    minWidth,
    maxWidth,
    gutter,
    style,
    ...props
}: ScrollbarProps<E>) {
    return (
        <Flex
            {...(props as any)}
            style={{ ...style, overflow, minHeight, maxHeight, minWidth, maxWidth, scrollbarGutter: gutter }}
            className={classNames(styles.root, className)}
        >
            {children}
        </Flex>
    );
}
