import gql from 'graphql-tag';

export const WITH_SAP_COMMENT_QUERY = gql`
    query withSapComment($linkId: String!) {
        link: getProjectLinkBudgetItemApprovalRequests(filter: { id: [$linkId] }) {
            nodes {
                budgetItem {
                    sapComment
                }
            }
        }
    }
`;

export const WITH_CORRECTIONS_QUERY = gql`
    query withCorrections($budgetId: String!, $serialNumber: Int!) {
        corrections: getCorrections(
            filter: { budgetId: $budgetId, planIds: [$serialNumber], status: [need_approving] }
        ) {
            nodes {
                params
            }
        }
    }
`;
