import * as React from 'react';
import autobind from 'autobind-decorator';
import { sortBy } from 'lodash';
import * as moment from 'moment';

import { MonthValue } from '@mrm/budget';
import type { CardProps } from './BudgetIdTemplate';
import type { BudgetProps } from './WithBudgets';

import { BudgetIdTemplate } from './BudgetIdTemplate';
import { Money, MoneyFormatter } from '@common/Utils';
import { MONTHS } from './types';
import type { CreativeRequestDomain, BudgetItem, Permissions } from './types';

interface Props {
    loading: boolean;
    creativeRequestDomain: CreativeRequestDomain;
    budgets: BudgetProps[];
    permissions: Permissions;
    budgetItems: BudgetItem[];
}

export class BudgetIdBehavior extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { loading, permissions } = this.props;

        return React.createElement(BudgetIdTemplate, {
            loading,
            visibleAddButton: permissions.canSetBudgetId,
            cards: this.makeCards(),
            onAddButtonClick: this.onAddButtonClick,
        });
    }

    @autobind
    protected async onRemoveButtonClick(id: string): Promise<void> {
        const { creativeRequestDomain } = this.props;
        await creativeRequestDomain.model.removeBudgetItem({ budgetItemId: id });
    }

    @autobind
    protected onAddButtonClick(): void {
        const { creativeRequestDomain, budgets } = this.props;

        const currentYear = moment().year();
        const currentBudget = budgets.find(({ year }) => year === currentYear);

        if (creativeRequestDomain?.model?.id && currentBudget?.id) {
            const url = new URL(`${window.location.origin}/budget/execution`);

            url.searchParams.append('creativeRequestId', creativeRequestDomain.model.id);
            url.searchParams.append('budgetId', currentBudget.id);

            this.openUrlInNewTab(url.href);
        } else {
            throw new Error('creativeRequestId or currentBudgetId is empty');
        }
    }

    private makeCards(): CardProps[] {
        const { budgetItems } = this.props;

        const cards: CardProps[] = budgetItems.map(
            ({
                id,
                serialNumber,
                createdAt,
                sapComment,
                budgetYear,
                block,
                division,
                plannedFunds,
                reservedFunds,
                factFunds,
            }) => ({
                id,
                serialNumber,
                budgetYear,
                date: moment(createdAt).format('D MMMM YY'),
                sapComment: sapComment || '',
                block: block || 'Не указан',
                division: division || 'Не указан',
                planSum: plannedFunds === null ? null : this.sumFunds(plannedFunds || {}),
                reserveSum: reservedFunds === null ? null : this.sumFunds(reservedFunds || {}),
                factSum: factFunds === null ? null : this.sumFunds(factFunds || {}),
                onRemoveButtonClick: this.onRemoveButtonClick,
            }),
        );

        return sortBy(cards, (item) => item.serialNumber);
    }

    private sumFunds(funds: MonthValue): string {
        const sum = MONTHS.reduce((acc, item) => acc + funds[item], 0);

        return MoneyFormatter.toThousands(Money.fromCopecks(sum), { hideCaption: true });
    }

    private openUrlInNewTab(url: string): void {
        window.open(url, '_blank');
    }
}
