import * as React from 'react';
import classNames from 'classnames';
import * as moment from 'moment';

import { Utils } from '@common/Utils';

import { FileData } from '@modules/files/types';

import { Flex, Tag, TagClose, TagProps } from '@common/components';
import { FileThumbnailButtons } from '@modules/files/components';

import * as styles from './FileTag.scss';

export interface FileTagProps extends TagProps {
    file: FileData;
    removable?: boolean;
    editable?: boolean;
    onDownload?: () => void;
    onView?: () => void;
    onRemove?: () => void;
}

export function FileTag({
    file,
    children,
    onDownload,
    onView,
    removable = false,
    editable = removable,
    onRemove,
    className,
    ...props
}: FileTagProps) {
    return (
        <Tag
            data-qa-id="FileTag"
            align="stretch"
            padding={7}
            gap={8}
            even
            relative
            {...props}
            className={classNames(styles.root, className)}
        >
            <FileThumbnailButtons editable={editable} onDownload={onDownload} onView={onView} file={file} />
            <Flex className={styles.info} flex vertical justify="center">
                <div
                    data-qa-id="FileTag__title"
                    className={classNames(styles.title, file.originName.includes(' ') && styles.twoLines)}
                >
                    {file.originName}
                </div>
                <div data-qa-id="FileTag__subtitle" className={styles.subtitle}>
                    {Utils.formatFileSize(file.size)}
                    {' • '}
                    {Utils.formatDateWithYear(moment(file.createTime))}
                </div>
            </Flex>
            {removable && <TagClose onClick={onRemove} />}
        </Tag>
    );
}
