import * as React from 'react';

import { Histogram } from './Histogram';
import { Color } from '../../../common';
import { useState, StatisticsType } from './useState';

import * as styles from './styles.scss';

interface Props {
    color: Color;
    name: string;
    type: StatisticsType;
    data: {
        id: string;
        name: string;
        mainValue: number;
        additionalValue: number;
    }[];
}

export const ReportChart: React.FC<Props> = ({ data, color, name, type }) => {
    const { selectedPeriod, selectedDate } = useState();

    const isDisplay =
        data?.length &&
        Boolean(
            data.reduce((prev, { mainValue, additionalValue }) => {
                return prev + mainValue + additionalValue;
            }, 0),
        );

    return isDisplay ? (
        <div className={styles.root}>
            <Histogram data={data} date={selectedDate} period={selectedPeriod} color={color} name={name} type={type} />
        </div>
    ) : null;
};
