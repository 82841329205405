import * as React from 'react';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { isNil, first, get } from 'lodash';

import { BriefField, FieldType } from 'sber-marketing-types/frontend';
import { Block } from './Block';
import { FieldChangeParams } from '@store/plannedBudgetEdit/types';
import { setFieldValue } from '@store/plannedBudgetEdit/actions';

interface Props extends Partial<DispatchProps> {
    briefId: string;
    blockId: string;
    name: string;
    fields: BriefField[];
    isRequired: boolean;
    editRight: boolean;
}

interface DispatchProps {
    setFieldValue?: (params: FieldChangeParams) => void;
}

@(connect(null, mapDispatchToProps) as any)
export class BlockContainer extends React.Component<Props> {
    public render() {
        const { blockId, briefId, name, isRequired, editRight } = this.props;

        return React.createElement(Block, {
            id: blockId,
            briefId,
            name,
            fields: this.getFieldsWithoutTypeToggle(),
            isRequired: isNil(isRequired) ? true : isRequired,
            isOpened: this.isOpened(),
            editRight,
            onClickToggle: this.onClickToggle,
        });
    }

    @autobind
    private onClickToggle(): void {
        const { briefId } = this.props;
        this.props.setFieldValue({
            briefId,
            fieldId: this.toggleField.id,
            value: {
                togglePosition: this.toggleFieldPosition === 'right' ? 'left' : 'right',
            },
        });
    }

    private isOpened(): boolean {
        if (this.toggleField) {
            return this.toggleFieldPosition === 'right';
        }

        return false;
    }

    private get toggleFieldPosition() {
        return get(this.toggleField, 'value.togglePosition') || 'right';
    }

    private get toggleField() {
        const fieldsWithToggleType = this.getFieldsWithTypeToggle();
        return first(fieldsWithToggleType);
    }

    private getFieldsWithoutTypeToggle() {
        const { fields } = this.props;
        return fields.filter(({ type }) => type !== FieldType.TOGGLE);
    }

    private getFieldsWithTypeToggle(props?: Props) {
        const fields = props ? props.fields : this.props.fields;
        return fields.filter(({ type }) => type === FieldType.TOGGLE);
    }
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setFieldValue,
        },
        dispatch,
    );
}
