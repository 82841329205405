import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as lodash from 'lodash';

import type { ActivityCardsGroup } from '@store/calendar/types';

import { WeekDigest } from './WeekDigest';
import type { StoreState } from '@store';
import { setDisabledUploadPdfButton } from '@store/calendar/actions';
import { getLoginUser } from '@store/user/selector';
import { getFilters, getActivityCardGroups } from '@store/calendar/selectors';

interface Props extends Partial<MapProps>, Partial<DispatchProps> {}

interface MapProps {
    activityCardGroups: ActivityCardsGroup[];
    displayOrganizationName: boolean;
    expiredStagesFilter: boolean;
}

interface DispatchProps {
    setDisabledUploadPdfButton(disabled: boolean): void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class WeekDigestContainer extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);
    }

    public componentDidMount(): void {
        this.props.setDisabledUploadPdfButton(false);
    }

    public componentWillUnmount(): void {
        this.props.setDisabledUploadPdfButton(true);
    }

    public render(): JSX.Element {
        return React.createElement(WeekDigest, {
            activityCardGroups: this.props.expiredStagesFilter
                ? this.getFilteredActivityCardGroups()
                : this.props.activityCardGroups,
            displayOrganizationName: this.props.displayOrganizationName,
        });
    }

    private getFilteredActivityCardGroups() {
        const { activityCardGroups } = this.props;

        const filteredActivityCardGroups: ActivityCardsGroup[] = [];

        activityCardGroups.forEach((group) => {
            const filteredGroupCards = group.activities.filter(
                (card) => card.stagesInfo && card.stagesInfo.hasExpiredStages,
            );

            if (!lodash.isEmpty(filteredGroupCards)) {
                filteredActivityCardGroups.push({
                    ...group,
                    activities: filteredGroupCards,
                });
            }
        });

        return filteredActivityCardGroups;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { organizationIds, expiredStagesFilter } = getFilters(state);

    const user = getLoginUser(state);
    const userOrganizationId = user.attributes.organizationId;

    const displayOrganizationName = organizationIds.length > 1 || lodash.first(organizationIds) !== userOrganizationId;

    return {
        activityCardGroups: getActivityCardGroups(state),
        displayOrganizationName,
        expiredStagesFilter,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setDisabledUploadPdfButton,
        },
        dispatch,
    );
}
