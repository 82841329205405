import { stubArray, includes, castArray, clone, property } from 'lodash';

import { FilesData, ReplaceFilesPayload } from '../types';
import { FileAsset } from '../../commonTypes';
import { resetableReducerWithInitialState } from '../../common/utils';
import { addFiles, removeFiles, reloadFiles, replaceFiles, resetTaskEditor, clearRemoveList } from '../actions';
import { resetStore } from '../../common/actions';

const getInitialState = (): FilesData => ({ filesIdsToRemove: [], filesIds: [] });

const extractName = property<FileAsset, string>('name');

const filesSubReducer = resetableReducerWithInitialState<string[]>(stubArray, resetStore, resetTaskEditor)
    .case(addFiles, (state: string[], payload: FileAsset | FileAsset[]) => [
        ...state,
        ...castArray(payload).map(extractName),
    ])
    .case(reloadFiles, (_: string[], payload: FileAsset | FileAsset[]) => {
        return castArray(payload).map(extractName);
    })
    .case(replaceFiles, (state: string[], payload: ReplaceFilesPayload) => {
        const replacements = castArray(payload);
        const result = clone(state);
        for (const { index, value } of replacements) {
            result[index] = value.name;
        }
        return result;
    });

const filesToRemoveSubReducer = resetableReducerWithInitialState<string[]>(
    stubArray,
    resetStore,
    resetTaskEditor,
    clearRemoveList,
);

export const filesData = resetableReducerWithInitialState<FilesData>(getInitialState, resetStore, resetTaskEditor)
    .case(removeFiles, ({ filesIds, filesIdsToRemove }, payload) => {
        const names = castArray(payload);
        const assetsIds = filesIds.filter((name) => includes(names, name));
        return {
            filesIds: filesIds.filter((name) => !includes(names, name)),
            filesIdsToRemove: [...filesIdsToRemove, ...assetsIds],
        };
    })
    .default(({ filesIdsToRemove, filesIds }: FilesData, action: any) => ({
        filesIdsToRemove: filesToRemoveSubReducer(filesIdsToRemove, action),
        filesIds: filesSubReducer(filesIds, action),
    }));
