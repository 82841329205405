import * as React from 'react';
import classNames from 'classnames';

import { Flex, FlexProps } from '@common/components';

import * as styles from './SkeletonText.scss';

export interface SkeletonTextProps extends FlexProps {
    width?: number;
}

export const SkeletonText: React.FC<SkeletonTextProps> = ({ style, className, width = 100, ...props }) => {
    return (
        <Flex
            inline
            loading
            {...props}
            style={{
                ...style,
                width,
            }}
            className={classNames(styles.root, className)}
        />
    );
};
