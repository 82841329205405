import * as React from 'react';

import type { Fields, OnFieldChange } from './types';

import { Content } from './Content';

import * as style from './MainInfo.scss';

interface Props {
    fields: Fields;
    onFieldChange: OnFieldChange;
}

export const MainInfoTemplate: React.FC<Props> = ({ fields, onFieldChange }) => {
    return (
        <div className={style.root}>
            <div className={style.title}>Основная информация</div>

            {fields.lot.value && <Content fields={fields} onFieldChange={onFieldChange} />}
        </div>
    );
};
