import * as React from 'react';
import { Link } from 'react-router-dom';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { BudgetItemStatus } from 'sber-marketing-types/budget';
import { BudgetItemApproverStatus } from '@mrm/budget';
import {
    Icon,
    IconType,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    Dropdown,
    FilterMenu,
    FilterCheckbox,
    FilterItem,
    CustomScrollbar_new as CustomScrollbar,
    WithTooltip,
    WithGlobalPopup,
    AbsoluteLikePositionCalculator,
} from 'sber-marketing-ui';

import { StoreState } from '@store';
import { BudgetItemTypes, BudgetItemApproversToDisplay, getBudgetPlanningPageState } from '@store/budgetPlanning';

import * as style from './TopMenu.scss';

import { FileInput } from '@common/FileInput';

import { FiltersColumnsTagsBar } from '../FiltersColumnsTagsBar';

import classNames from 'classnames';

interface Props {
    dictionariesOrganizationId: string;
    budgetId: string;
    onUndoButtonClick: () => void;
    disableUndoButton: boolean;
    onRedoButtonClick: () => void;
    disableRedoButton: boolean;
    disableResetPageViewSettingsButton: boolean;
    userIsSupervisor: boolean;
    onSendToApprovementButtonClick: () => void;
    sendButtonIsDisabled: boolean;
    onDownloadXLSXButtonClick: (useFitlers: boolean) => void;
    onResetChangesHistoryClick: () => void;
    disableResetChangesHistoryButton: boolean;
    onResetPageFiltersButtonClick: () => void;
    onResetPageViewSettingsButtonClick: () => void;
    onSaveButtonClick: () => void;
    columnsFilterItems: FilterItem[];
    selectedColumnsFilterItems: string[];
    onColumnsFilterItemSelect: (selectedItemIds: React.ReactText[]) => void;
    displayOnlyUnapproved: boolean;
    onDisplayOnlyUnapprovedButtonClick: () => void;
    displayOnlyWithSnapshots: boolean;
    onDisplayOnlyWithSnapshotsButtonClick: () => void;
    displayDisabledLines: boolean;
    onDisplayDisabledLinesButtonClick: () => void;
    budgetItemsToDisplay: BudgetItemTypes;
    budgetItemsApproversToDisplay: BudgetItemApproversToDisplay;
    onBudgetItemsToDisplayChange: (budgetItemsToDisplayIds: React.ReactText[]) => void;
    areFiltersApplied: boolean;
    onApplyFiltersButtonClick: () => void;
    isSaveButtonDisabled: boolean;
    onDownloadXLSXTemplateButtonClick: () => void;
    onLoadXLSXContentFileInput: (Files: File[]) => void;
    userCanEditOrCreateBudgetData: boolean;
    showOnlyLinesWithPlanBudget: boolean;
    onShowOnlyLinesWithPlanBudgetToggle: () => void;
    showOnlyLinesWithoutPlanBudget: boolean;
    onShowOnlyLinesWithoutPlanBudgetToggle: () => void;
}

export const TopMenu = ({
    dictionariesOrganizationId,
    budgetId,
    onUndoButtonClick,
    disableUndoButton,
    onRedoButtonClick,
    disableRedoButton,
    disableResetPageViewSettingsButton,
    userIsSupervisor,
    onSendToApprovementButtonClick,
    sendButtonIsDisabled,
    onDownloadXLSXButtonClick,
    onResetChangesHistoryClick,
    disableResetChangesHistoryButton,
    onResetPageFiltersButtonClick,
    onResetPageViewSettingsButtonClick,
    onSaveButtonClick,
    columnsFilterItems,
    selectedColumnsFilterItems,
    onColumnsFilterItemSelect,
    displayOnlyUnapproved,
    onDisplayOnlyUnapprovedButtonClick,
    displayOnlyWithSnapshots,
    onDisplayOnlyWithSnapshotsButtonClick,
    displayDisabledLines,
    onDisplayDisabledLinesButtonClick,
    budgetItemsToDisplay,
    budgetItemsApproversToDisplay,
    onBudgetItemsToDisplayChange,
    areFiltersApplied,
    onApplyFiltersButtonClick,
    isSaveButtonDisabled,
    onDownloadXLSXTemplateButtonClick,
    onLoadXLSXContentFileInput,
    userCanEditOrCreateBudgetData,
    showOnlyLinesWithPlanBudget,
    onShowOnlyLinesWithPlanBudgetToggle,
    showOnlyLinesWithoutPlanBudget,
    onShowOnlyLinesWithoutPlanBudgetToggle,
}: Props): JSX.Element => {
    const budgetItemsToDisplayCheckedItemIds = Object.keys(budgetItemsToDisplay).filter(
        (budgetItemType) => budgetItemsToDisplay[budgetItemType],
    );

    Object.keys(budgetItemsApproversToDisplay).forEach((key) => {
        const approverKey = `approver-${key}`;

        if (budgetItemsApproversToDisplay[key]) {
            budgetItemsToDisplayCheckedItemIds.push(approverKey);
        }
    });

    return (
        <div className={style.root}>
            <div className={style.leftMenu}>
                {userCanEditOrCreateBudgetData && (
                    <React.Fragment>
                        <div
                            className={classNames(style.button, style.strokeIcon, disableUndoButton && style.disabled)}
                            onClick={onUndoButtonClick}
                            {...{
                                'qa-id': 'budgetPlanningUndoButton',
                                disabled: disableUndoButton,
                            }}
                        >
                            <WithTooltip spanWrapper content="Отменить изменения">
                                <Icon type={IconType.CURLY_ARROW_BACK} />
                            </WithTooltip>
                        </div>

                        <div
                            className={classNames(style.button, style.strokeIcon, disableRedoButton && style.disabled)}
                            onClick={onRedoButtonClick}
                            {...{
                                'qa-id': 'budgetPlanningRedoButton',
                                disabled: disableRedoButton,
                            }}
                        >
                            <WithTooltip spanWrapper content="Вернуть изменения">
                                <Icon type={IconType.CURLY_ARROW_FORWARD} />
                            </WithTooltip>
                        </div>
                    </React.Fragment>
                )}

                <div
                    className={classNames(
                        style.resetViewSettingButton,
                        style.strokeIcon,
                        disableResetPageViewSettingsButton && style.disabled,
                    )}
                    onClick={onResetPageViewSettingsButtonClick}
                    {...{
                        'qa-id': 'budgetPlanningResetPageViewSettingButton',
                        disabled: disableResetPageViewSettingsButton,
                    }}
                >
                    <WithTooltip spanWrapper content="Сбросить параметры отображения">
                        <Icon type={IconType.RESET_VIEW} />
                    </WithTooltip>
                </div>

                <div className={style.separator} />

                {userCanEditOrCreateBudgetData && (
                    <>
                        <div
                            className={style.button}
                            {...{
                                'qa-id': 'budgetPlanningCreateProjectLink',
                            }}
                        >
                            <WithTooltip spanWrapper content="Создать активность">
                                <Link
                                    className={classNames(style.button, style.strokeIcon)}
                                    to={`/budget/planning/new?budgetId=${budgetId}&dictionariesOrganizationId=${dictionariesOrganizationId}`}
                                >
                                    <Icon type={IconType.ADD_PROJECT} />
                                </Link>
                            </WithTooltip>
                        </div>

                        {userIsSupervisor && (
                            <div
                                className={classNames(
                                    style.button,
                                    style.strokeIcon,
                                    sendButtonIsDisabled && style.disabled,
                                )}
                                onClick={onSendToApprovementButtonClick}
                                {...{
                                    'qa-id': 'budgetPlanningSendToApprovementButtonClick',
                                    disabled: sendButtonIsDisabled,
                                }}
                            >
                                <WithTooltip spanWrapper content="Отправить на утверждение">
                                    <Icon type={IconType.SEND_FOR_APPROVAL} />
                                </WithTooltip>
                            </div>
                        )}
                    </>
                )}

                <XLSXImportExportMenu
                    onDownloadXLSXButtonClick={onDownloadXLSXButtonClick}
                    onDownloadXLSXTemplateButtonClick={onDownloadXLSXTemplateButtonClick}
                    onLoadXLSXContentFileInput={onLoadXLSXContentFileInput}
                />

                {userCanEditOrCreateBudgetData && (
                    <>
                        <div className={style.separator} />

                        <div
                            className={classNames(
                                style.button,
                                style.strokeIcon,
                                disableResetChangesHistoryButton && style.disabled,
                            )}
                            onClick={onResetChangesHistoryClick}
                            {...{
                                'qa-id': 'budgetPlanningResetChangesHistoryButton',
                            }}
                        >
                            <WithTooltip spanWrapper content="Очистить изменения">
                                <Icon type={IconType.ERASER} />
                            </WithTooltip>
                        </div>
                    </>
                )}

                <div className={style.filter}>
                    <Dropdown
                        title={(isOpened) => (
                            <div
                                className={classNames(
                                    style.filterOpener,
                                    isOpened && style.filterOpenerOpened,
                                    !isOpened && areFiltersApplied && style.filterOpenerFiltersApplied,
                                )}
                                {...{
                                    'qa-id': 'budgetPlanningFiltersButton',
                                }}
                            >
                                <div className={style.filterOpenerIcon}>
                                    <Icon type={IconType.FILTERS} />
                                </div>

                                <div className={style.filterOpenerTitle}>Фильтр</div>
                            </div>
                        )}
                        content={
                            <div className={style.filterContent}>
                                <CustomScrollbar maxHeight={484}>
                                    <div className={style.scrollbarContent}>
                                        <FilterCheckbox
                                            qaId="budgetPlanningShowOnlyLinesWithPlanBudgetCheckbox"
                                            title="Только строки с бюджетом"
                                            tooltip="Только строки с бюджетом"
                                            isChecked={showOnlyLinesWithPlanBudget}
                                            onClick={onShowOnlyLinesWithPlanBudgetToggle}
                                        />

                                        <FilterCheckbox
                                            qaId="budgetPlanningShowOnlyLinesWithoutPlanBudgetCheckbox"
                                            title="Только строки без бюджета"
                                            tooltip="Только строки без бюджета"
                                            isChecked={showOnlyLinesWithoutPlanBudget}
                                            onClick={onShowOnlyLinesWithoutPlanBudgetToggle}
                                        />

                                        <FilterCheckbox
                                            qaId="budgetPlanningDisplayOnlyUnapprovedCheckbox"
                                            title="Только несогласованные"
                                            tooltip="Только несогласованные"
                                            isChecked={displayOnlyUnapproved}
                                            onClick={onDisplayOnlyUnapprovedButtonClick}
                                        />

                                        <FilterCheckbox
                                            qaId="budgetPlanningDisplayOnlyWithSnapshotsCheckbox"
                                            title="Строки с внесенными изменениями"
                                            tooltip="Строки с внесенными изменениями"
                                            isChecked={displayOnlyWithSnapshots}
                                            onClick={onDisplayOnlyWithSnapshotsButtonClick}
                                        />

                                        <FilterCheckbox
                                            qaId="budgetPlanningDisplayDisabledLinesCheckbox"
                                            title="Показать удаленные"
                                            tooltip="Показать удаленные"
                                            isChecked={displayDisabledLines}
                                            onClick={onDisplayDisabledLinesButtonClick}
                                        />

                                        <FilterMenu
                                            qaId="budgetPlanningBudgetItemsToDisplayFilter"
                                            filterTitle="Отображаемые строки"
                                            items={[
                                                {
                                                    id: BudgetItemStatus.Draft,
                                                    title: 'Черновики',
                                                    order: 1,
                                                },
                                                {
                                                    id: BudgetItemStatus.Published,
                                                    title: 'Опубликованные',
                                                    order: 2,
                                                },
                                                {
                                                    id: BudgetItemStatus.OnExpertApprovement,
                                                    title: 'На утверждении',
                                                    order: 3,
                                                },
                                                {
                                                    id: BudgetItemStatus.Approved,
                                                    title: 'Утверждены',
                                                    order: 4,
                                                },
                                                {
                                                    id: BudgetItemStatus.Rejected,
                                                    title: 'Отклонены',
                                                    order: 5,
                                                },
                                                {
                                                    id: `approver-${BudgetItemApproverStatus.Waiting}`,
                                                    title: 'На согласовании',
                                                    order: 6,
                                                },
                                                {
                                                    id: `approver-${BudgetItemApproverStatus.Approved}`,
                                                    title: 'Согласованы',
                                                    order: 7,
                                                },
                                                {
                                                    id: `approver-${BudgetItemApproverStatus.Rejected}`,
                                                    title: 'Не согласованы',
                                                    order: 8,
                                                },
                                            ]}
                                            checkedItemsIds={budgetItemsToDisplayCheckedItemIds}
                                            onItemSelection={onBudgetItemsToDisplayChange}
                                            isExpanded
                                        />

                                        <FilterMenu
                                            qaId="budgetPlanningSelectedColumnsFilter"
                                            filterTitle="Отображаемые колонки"
                                            items={columnsFilterItems}
                                            checkedItemsIds={selectedColumnsFilterItems}
                                            onItemSelection={onColumnsFilterItemSelect}
                                            isExpanded
                                        />
                                    </div>
                                </CustomScrollbar>
                            </div>
                        }
                        disableScroll
                    />
                </div>

                <WithTooltip spanWrapper content="Сбросить параметры фильтров">
                    <div
                        onClick={onResetPageFiltersButtonClick}
                        className={classNames(style.resetFiltersButton, style.strokeIcon)}
                        {...{
                            'qa-id': 'budgetPlanningResetPageFiltersButton',
                        }}
                    >
                        <Icon type={IconType.RESET_USER_CONFIG} />
                    </div>
                </WithTooltip>

                <div className={style.filtersColumnsTagsBarWrapper}>
                    <FiltersColumnsTagsBar onApplyFiltersButtonClick={onApplyFiltersButtonClick} />
                </div>

                <ApplyFiltersButton onClick={onApplyFiltersButtonClick} />
            </div>

            {userCanEditOrCreateBudgetData && (
                <div
                    className={style.rightMenu}
                    {...{
                        'qa-id': 'budgetPlanningSaveButton',
                    }}
                >
                    <div className={style.saveButton}>
                        <Button
                            theme={ButtonTheme.GhostRounded}
                            disabled={isSaveButtonDisabled}
                            onClick={onSaveButtonClick}
                        >
                            Сохранить и опубликовать
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

type XLSXImportExportMenuProps = Pick<
    Props,
    'onDownloadXLSXButtonClick' | 'onDownloadXLSXTemplateButtonClick' | 'onLoadXLSXContentFileInput'
>;

function XLSXImportExportMenu({
    onDownloadXLSXButtonClick,
    onDownloadXLSXTemplateButtonClick,
    onLoadXLSXContentFileInput,
}: XLSXImportExportMenuProps): JSX.Element {
    const buttonRef = React.useRef<HTMLDivElement>();
    const fileInputRef = React.useRef<FileInput>();

    const [isMenuVisible, setIsMenuVisible] = React.useState(false);

    function downloadXLSXWithFiltersButtonClickHandler(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();

        setIsMenuVisible(false);
        onDownloadXLSXButtonClick(true);
    }

    function downloadXLSXWithoutFiltersButtonClickHandler(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();

        setIsMenuVisible(false);
        onDownloadXLSXButtonClick(false);
    }

    function downloadXLSXTemplateButtonClickHandler(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();

        setIsMenuVisible(false);
        onDownloadXLSXTemplateButtonClick();
    }

    function loadXLSXContentButtonClickHandler(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();

        if (fileInputRef.current) {
            fileInputRef.current.triggerOpen();
        }
    }

    function onFileInput(files: File[]) {
        setIsMenuVisible(false);
        onLoadXLSXContentFileInput(files);
    }

    return (
        <div
            ref={buttonRef}
            className={classNames(style.button, style.strokeIcon)}
            onClick={() => setIsMenuVisible(true)}
            {...{
                'qa-id': 'budgetPlanningXLSXImportExportMenuButton',
            }}
        >
            <WithTooltip spanWrapper content="Скачать таблицу, шаблон (Excel) или загрузить данные в MRM">
                <Icon type={IconType.EXPORT_TABLE} />
            </WithTooltip>

            {isMenuVisible && (
                <WithGlobalPopup
                    container={buttonRef}
                    positionCalculator={AbsoluteLikePositionCalculator}
                    bottom={-6}
                    left={-9}
                    onMaskClick={() => setIsMenuVisible(false)}
                >
                    <div className={style.xlsxImportExportMenu}>
                        <div className={style.xlsxImportExportMenuTitle}>Скачать</div>

                        <div
                            className={style.xlsxImportExportMenuButton}
                            onClick={downloadXLSXWithFiltersButtonClickHandler}
                            {...{
                                'qa-id': 'budgetPlanningDownloadXLSXWithFiltersButton',
                            }}
                        >
                            Таблицу (excel) с учетом фильтров
                        </div>

                        <div
                            className={style.xlsxImportExportMenuButton}
                            onClick={downloadXLSXWithoutFiltersButtonClickHandler}
                            {...{
                                'qa-id': 'budgetPlanningDownloadXLSXWithoutFiltersButton',
                            }}
                        >
                            Таблицу (excel) без фильтров
                        </div>

                        <div
                            className={style.xlsxImportExportMenuButton}
                            onClick={downloadXLSXTemplateButtonClickHandler}
                            {...{
                                'qa-id': 'budgetPlanningDownloadXLSXTemplateButton',
                            }}
                        >
                            Шаблон для внесения данных
                        </div>

                        <div className={style.xlsxImportExportMenuSeparator} />

                        <div className={style.xlsxImportExportMenuTitle}>Загрузить</div>

                        <div
                            className={style.xlsxImportExportMenuButton}
                            onClick={loadXLSXContentButtonClickHandler}
                            {...{
                                'qa-id': 'budgetPlanningLoadXLSXContentButton',
                            }}
                        >
                            Строки бюджета из excel-файла
                        </div>

                        <FileInput multiple={false} ref={fileInputRef} onChange={onFileInput} />
                    </div>
                </WithGlobalPopup>
            )}
        </div>
    );
}

interface ApplyFiltersButtonProps {
    onClick: () => void;
}

function ApplyFiltersButton({ onClick }: ApplyFiltersButtonProps): JSX.Element {
    const showTagsHaveChangedMarker = useSelector(
        (state: StoreState) => getBudgetPlanningPageState(state).showTagsHaveChangedMarker,
    );
    const shouldRender = useSelector((state: StoreState) => {
        const {
            columnFilters: { filters },
            previouslyLoadedFilters,
        } = getBudgetPlanningPageState(state);

        return showTagsHaveChangedMarker || !isEqual(previouslyLoadedFilters, filters);
    });

    const title = showTagsHaveChangedMarker ? 'Изменены теги. Нажмите, чтобы обновить таблицу' : 'Применить фильтры';

    return shouldRender ? (
        <WithTooltip spanWrapper content={title}>
            <div
                className={classNames(
                    style.applyFiltersButton,
                    showTagsHaveChangedMarker && style.applyFiltersButtonNeedUpdateMarker,
                )}
                onClick={onClick}
            >
                <Icon type={IconType.PLAY} />
            </div>
        </WithTooltip>
    ) : null;
}
