// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-Sidebar-widgets-Files-FileGroupByTable-_FileGroupByTable__titleCounter{padding:1px 8px;font-family:'Open Sans';font-weight:600;font-size:12px;line-height:16px;letter-spacing:0.22px;color:#FFFFFF;background:#19BB4F;border-radius:8px}.src-client-modules-activity-pages-creative-Sidebar-widgets-Files-FileGroupByTable-_FileGroupByTable__titleContainer{display:flex;justify-content:space-between;margin-top:15px;padding:0 24px}.src-client-modules-activity-pages-creative-Sidebar-widgets-Files-FileGroupByTable-_FileGroupByTable__title{display:flex;align-items:baseline;gap:6px;font-family:'Open Sans';font-weight:600;font-size:13px;line-height:18px;color:#000000}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/Sidebar/widgets/Files/FileGroupByTable/FileGroupByTable.scss"],"names":[],"mappings":"AAAA,mHACI,eAAgB,CAEhB,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,qBAAsB,CACtB,aAAc,CAEd,kBAAmB,CACnB,iBAAkB,CACrB,qHAGG,YAAa,CACb,6BAA8B,CAC9B,eAAgB,CAChB,cAAe,CAClB,4GAGG,YAAa,CACb,oBAAqB,CACrB,OAAQ,CACR,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,aAAc","sourcesContent":[".titleCounter {\n    padding: 1px 8px;\n\n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 12px;\n    line-height: 16px;\n    letter-spacing: 0.22px;\n    color: #FFFFFF;\n\n    background: #19BB4F;\n    border-radius: 8px;\n}\n\n.titleContainer {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 15px;\n    padding: 0 24px;\n} \n\n.title {\n    display: flex;\n    align-items: baseline;\n    gap: 6px;\n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 13px;\n    line-height: 18px;\n    color: #000000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleCounter": "src-client-modules-activity-pages-creative-Sidebar-widgets-Files-FileGroupByTable-_FileGroupByTable__titleCounter",
	"titleContainer": "src-client-modules-activity-pages-creative-Sidebar-widgets-Files-FileGroupByTable-_FileGroupByTable__titleContainer",
	"title": "src-client-modules-activity-pages-creative-Sidebar-widgets-Files-FileGroupByTable-_FileGroupByTable__title"
};
export default ___CSS_LOADER_EXPORT___;
