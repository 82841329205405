import { reducerWithInitialState } from 'typescript-fsa-reducers';

import * as actions from './actions';
import { AutopilotsTabState } from './types';
import { AutopilotsMap } from '@modules/autopilot/types';
import { Failure, Success } from 'typescript-fsa';

const initialState: AutopilotsTabState = {
    activityId: null,
    loading: true,
    error: null,
    autopilots: {
        autopilot: null,
        autopilot_v2: null,
        autopilotTv: null,
        autopilotRadio: null,
        autopilotDOOH: null,
    },
};

function startAutopilotsLoadingReducer(state: AutopilotsTabState): AutopilotsTabState {
    return {
        ...state,
        loading: true,
    };
}

function setAutopilotsFailedReducer(state: AutopilotsTabState, { error }: Failure<number, Error>): AutopilotsTabState {
    return {
        ...state,
        loading: false,
        error,
    };
}

function setAutopilotsDoneReducer(
    state: AutopilotsTabState,
    { result: autopilots }: Success<number, AutopilotsMap>,
): AutopilotsTabState {
    return {
        ...state,
        loading: false,
        autopilots,
    };
}

function setActivityIdReducer(state: AutopilotsTabState, activityId: number): AutopilotsTabState {
    return {
        ...state,
        activityId,
    };
}

export const autopilotsTabReducer = reducerWithInitialState(initialState)
    .case(actions.setActivityId, setActivityIdReducer)
    .case(actions.loadAutopilotsAsync.started, startAutopilotsLoadingReducer)
    .case(actions.loadAutopilotsAsync.done, setAutopilotsDoneReducer)
    .case(actions.loadAutopilotsAsync.failed, setAutopilotsFailedReducer);
