// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-main-OptionsChip-_OptionsChip__arrow{transition:transform .3s;transform:rotateZ(0)}.src-client-modules-common-components-main-OptionsChip-_OptionsChip__openArrow{transform:rotateZ(180deg)}.src-client-modules-common-components-main-OptionsChip-_OptionsChip__selected{color:#417EC6;flex:1;min-width:0;overflow:hidden;text-overflow:ellipsis}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/main/OptionsChip/OptionsChip.scss"],"names":[],"mappings":"AAAA,2EACE,wBAAyB,CACzB,oBAAqB,CACtB,+EAGC,yBAA0B,CAC3B,8EAGC,aAAc,CACd,MAAO,CACP,WAAY,CACZ,eAAgB,CAChB,sBAAuB","sourcesContent":[".arrow {\n  transition: transform .3s;\n  transform: rotateZ(0);\n}\n\n.openArrow {\n  transform: rotateZ(180deg);\n}\n\n.selected {\n  color: #417EC6;\n  flex: 1;\n  min-width: 0;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrow": "src-client-modules-common-components-main-OptionsChip-_OptionsChip__arrow",
	"openArrow": "src-client-modules-common-components-main-OptionsChip-_OptionsChip__openArrow",
	"selected": "src-client-modules-common-components-main-OptionsChip-_OptionsChip__selected"
};
export default ___CSS_LOADER_EXPORT___;
