import { createSelector } from 'reselect';
import { UserConfigType } from 'sber-marketing-types/openid';

import { LoadingStatus } from '@store/commonTypes';
import { UserConfigState } from '@store/userConfig';
import { getUserConfigState } from '../selectors';
import { PersonalDashboardUserConfigState, PersonalDashboardUserConfig } from './types';

export const getPersonalDashboardUserConfigState = createSelector(
    getUserConfigState,
    (state: UserConfigState): PersonalDashboardUserConfigState => state[UserConfigType.PersonalDashboard],
);

export const getPersonalDashboardUserConfig = createSelector(
    getPersonalDashboardUserConfigState,
    (state: PersonalDashboardUserConfigState): PersonalDashboardUserConfig => state.fields,
);

export const getUserRoleFilter = createSelector(
    getPersonalDashboardUserConfig,
    (personalDashboardUserConfigReducer: PersonalDashboardUserConfig): string =>
        personalDashboardUserConfigReducer.userRoleFilter,
);

export const getLoadingStatus = createSelector(
    getPersonalDashboardUserConfigState,
    (state: PersonalDashboardUserConfigState): LoadingStatus => state.loadingStatus,
);
