import * as React from 'react';
import * as lodash from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { v4 } from 'uuid';

import autobind from 'autobind-decorator';

import { ActivityBudgetCard } from './ActivityBudgetCard';
import { StoreState } from '@store';
import {
    updateActivityForm,
    updateActivityFormValidation,
    updateBudgetForm,
    setActivityAlreadyExists,
} from '@store/plannedBudgetEdit/actions';
import { FormData } from '@store/plannedBudgetEdit/types';
import { isRequestInProgress } from '@store/common/selectors';
import { bindActionCreators } from 'redux';
import { FieldValue, FormField } from 'sber-marketing-ui';

interface Props extends Partial<MapProps>, Partial<DispatchProps> {
    budgetId: string;
    showActivitySuggest: boolean;
    pageIsInCopyFromExecutionMode: boolean;
}

interface MapProps {
    id: string;
    fields: FormField[];
    budgetItemForms: FormData[];
    isRequestInProgress: boolean;
    activityAlreadyExists: boolean;
}

interface DispatchProps {
    updateActivityForm: (form: FormData) => void;
    updateActivityFormValidation: () => void;
    updateBudgetForm: (form: FormData) => void;
    setActivityAlreadyExists: (activityAlreadyExists: boolean) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class ActivityBudgetCardContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { budgetId, fields, showActivitySuggest, pageIsInCopyFromExecutionMode } = this.props;

        return React.createElement(ActivityBudgetCard, {
            budgetId,
            fields,
            showActivitySuggest,
            pageIsInCopyFromExecutionMode,
            onFieldValueChange: this.onFieldValueChange,
        });
    }

    @autobind
    private onFieldValueChange(fieldId: string, newValue: FieldValue): void {
        const { id, fields, budgetItemForms, activityAlreadyExists } = this.props;

        let idToUse = id;

        const changedField = fields.find((item) => item.id == fieldId);
        const shouldResetAcitivityAlreadyExists = activityAlreadyExists && changedField.name === 'name';

        changedField.value = newValue;
        changedField.errorMessage = '';

        if (shouldResetAcitivityAlreadyExists) {
            changedField.caption = null;
            idToUse = v4();

            this.props.setActivityAlreadyExists(false);
        }

        this.props.updateActivityForm({
            id: idToUse,
            fields: lodash.clone(fields),
            collapsed: false,
        });

        budgetItemForms.forEach((form) => {
            const updatedForm = lodash.cloneDeep(form);

            const activityNameField = updatedForm.fields.find((item) => item.name === 'activityBudget');

            activityNameField.value = newValue;

            this.props.updateBudgetForm(updatedForm);
        });

        this.props.updateActivityFormValidation();
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const {
        activityForm: { id, fields },
        budgetItemForms,
        activityAlreadyExists,
    } = state.plannedBudgetEditPage;

    return {
        id,
        fields,
        budgetItemForms,
        isRequestInProgress: isRequestInProgress(state),
        activityAlreadyExists,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            updateActivityForm,
            updateActivityFormValidation,
            updateBudgetForm,
            setActivityAlreadyExists,
        },
        dispatch,
    );
}
