import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as lodash from 'lodash';
import * as moment from 'moment';
import autobind from 'autobind-decorator';

import type { ActivityCardsGroup, ActivityCardParams, Position, Direction } from '@store/calendar/types';
import { VerticalDirection, HorizontalDirection } from '@store/calendar/types';

import { ActivityGroup } from './ActivityGroup';
import type { StoreState } from '@store';
import { setHoveredItemId } from '@store/calendar/actions';
import { getCalendarPageState, getFilters } from '@store/calendar/selectors';

interface Props extends Partial<MapProps>, Partial<DispatchProps> {
    cardsGroup: ActivityCardsGroup;
}

interface MapProps {
    weekStart: Date;
    hoveredItemId: number;
}

interface DispatchProps {
    setHoveredItemId: (lineId: React.ReactText) => void;
}

interface State {
    tooltipPosition: Position;
    tooltipDirection: Direction;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class ActivityGroupContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            tooltipPosition: null,
            tooltipDirection: null,
        };
    }

    public render(): JSX.Element {
        return React.createElement(ActivityGroup, {
            blockName: this.props.cardsGroup.blockName,
            activitiesByDays: this.groupActivitiesByDays(),
            tooltipPosition: this.state.tooltipPosition,
            tooltipDirection: this.state.tooltipDirection,
            hoveredActivityCardId: this.props.hoveredItemId,
            onCardMousemove: this.onCardMousemove,
            onCardMouseleave: this.onCardMouseleave,
            onCardClick: this.onCardClick,
        });
    }

    @autobind
    protected onCardMousemove(event: React.MouseEvent, activityId: number) {
        const { clientX, clientY } = event;

        if (activityId !== this.props.hoveredItemId) {
            this.props.setHoveredItemId(activityId);
        }

        const cursorPosition = {
            x: clientX,
            y: clientY,
        };

        this.setState({
            tooltipPosition: cursorPosition,
            tooltipDirection: this.makeTooptipDirection(cursorPosition),
        });
    }

    @autobind
    protected onCardMouseleave() {
        this.setState({
            tooltipPosition: null,
        });

        this.props.setHoveredItemId(null);
    }

    @autobind
    protected onCardClick(activityId: number) {
        this.openUrlInNewTab(`/activity/${activityId}`);
    }

    private makeTooptipDirection(cursorPosition: Position): Direction {
        let horizontalDirection: HorizontalDirection;

        if (cursorPosition.x < window.innerWidth * 0.35) {
            horizontalDirection = HorizontalDirection.Right;
        } else if (cursorPosition.x > window.innerWidth * 0.65) {
            horizontalDirection = HorizontalDirection.Left;
        } else {
            horizontalDirection = HorizontalDirection.Center;
        }

        return {
            horizontal: horizontalDirection,
            vertical: cursorPosition.y > window.innerHeight / 2 ? VerticalDirection.Up : VerticalDirection.Down,
        };
    }

    private groupActivitiesByDays(): ActivityCardParams[][] {
        const { cardsGroup, weekStart } = this.props;
        const { activities } = cardsGroup;

        const result: ActivityCardParams[][] = [];

        const currentDayStart = moment(weekStart);
        const currentDayEnd = currentDayStart.clone().add(1, 'day');

        const weekStartIsSunday = currentDayStart.weekday() === 6;

        const daysCount = weekStartIsSunday ? 6 : 7;

        lodash.times(daysCount, () => {
            const weekDay = currentDayStart.weekday();

            const isSunday = weekDay === 6;

            let dayActivities: ActivityCardParams[] = [];

            dayActivities = activities.filter((item) => {
                const activityDate = moment(item.startDate);

                return isSunday && weekStartIsSunday
                    ? activityDate.isSameOrAfter(currentDayStart.clone().subtract(1, 'day')) &&
                          activityDate.isSameOrBefore(currentDayEnd)
                    : activityDate.isSameOrAfter(currentDayStart) && activityDate.isSameOrBefore(currentDayEnd);
            });

            if (isSunday && !weekStartIsSunday) {
                lodash.last(result).push(...dayActivities);
            } else {
                result.push(dayActivities);
            }

            currentDayStart.add(1, 'day');
            currentDayEnd.add(1, 'day');
        });

        return result;
    }

    private openUrlInNewTab(url: string): void {
        window.open(url, '_blank');
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { hoveredItemId } = getCalendarPageState(state);
    const { weekStart } = getFilters(state);

    return {
        weekStart,
        hoveredItemId: hoveredItemId as number,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setHoveredItemId,
        },
        dispatch,
    );
}
