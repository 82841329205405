import * as React from 'react';

import { Icon, IconType } from 'sber-marketing-ui';

import { ComponentState } from '@store/search';

import { SearchInput } from './SearchInput';
import { Results } from './Results';

import * as styles from './SearchMenu.scss';

interface Props {
    componentState: ComponentState;
    onMaskClick: () => void;
}

export function SearchMenu({ componentState, onMaskClick }: Props): JSX.Element {
    let content: JSX.Element;
    switch (componentState) {
        case ComponentState.ShowNoQueryNotification:
            content = (
                <Stub
                    qaType={ComponentState.ShowNoQueryNotification}
                    title="Вы пока ничего не искали"
                    text="А могли бы легко найти проекты, задачи, комментарии и файлы из системы"
                    icon={IconType.DASHBOARD_SEARCH_NO_QUERY}
                />
            );
            break;
        case ComponentState.ShowEmptyQueryNotification:
            content = (
                <Stub
                    qaType={ComponentState.ShowEmptyQueryNotification}
                    title="Можем поискать"
                    text="Проекты, задачи, комментарии и файлы из системы"
                    icon={IconType.DASHBOARD_SEARCH_EMPTY_QUERY}
                />
            );
            break;
        case ComponentState.ShowNoResultsNotification:
            content = (
                <Stub
                    qaType={ComponentState.ShowNoResultsNotification}
                    title="Нет результатов"
                    text="Перевернули все, но ничего не нашлось. Может, не сменили раскладку?"
                    icon={IconType.DASHBOARD_SEARCH_NO_RESULTS}
                />
            );
            break;
        case ComponentState.ShowResults:
            content = <Results />;
            break;
    }

    return (
        <React.Fragment>
            <div
                className={styles.root}
                {...{
                    'qa-id': 'searchMenu',
                }}
            >
                <div className={styles.content}>
                    <SearchInput />

                    {content}
                </div>
            </div>

            <div className={styles.mask} onClick={onMaskClick} />
        </React.Fragment>
    );
}

interface StubProps {
    title: string;
    text: string;
    icon: IconType;
    qaType: ComponentState;
}

function Stub({ title, text, icon, qaType }: StubProps): JSX.Element {
    return (
        <div
            className={styles.stub}
            {...{
                'qa-id': 'searchMenuResultsStub',
                'qa-type': qaType,
            }}
        >
            <div className={styles.stubTitle}>{title}</div>

            <div className={styles.stubText}>{text}</div>

            <div className={styles.stubIconWrapper}>
                <Icon type={icon} className={styles.stubIcon} />
            </div>
        </div>
    );
}
