import * as moment from 'moment';
import { Dispatch } from 'redux';
import { isNil, isEmpty, uniq } from 'lodash';
import { TaskStatus } from 'sber-marketing-types/frontend';

import { StoreState } from '../../';
import { fillActivityTasks } from '@store/activityTasksPage';
import { getTaskData, getActivityId } from '../selectors';
import { getLoginUser } from '../../user';
import { TaskApi } from '../../../api';
import { editByTaskData } from '../actions';
import { uploadFilesWorker } from './uploadFilesWorker';

/** Task creation worker */
export const createTaskWorker = async (
    isDraft: boolean,
    participants: number[],
    dispatch: Dispatch<StoreState>,
    getState: () => StoreState,
) => {
    const { attributes: user } = getLoginUser(getState());
    const {
        id,
        title,
        deadline,
        description,
        workTypeId,
        authorId,
        executorId,
        departmentId: departmentExecutorId,
        stageId,
    } = getTaskData(getState());
    const activityId = getActivityId(getState());
    const now = moment().toISOString();

    const task = await TaskApi.createTask({
        id,
        title,
        description,
        workTypeId,
        executorId,
        departmentExecutorId,
        activityId,
        createTime: now,
        updateTime: now,
        deadline: deadline.toISOString(),
        authorId: isNil(authorId) ? user.id : authorId,
        status: isDraft ? TaskStatus.Draft : TaskStatus.InProgress,
        stageId,
    } as any);

    if (!isEmpty(participants)) {
        await TaskApi.updateTaskParticipants(task.id, uniq([task.authorId, task.executorId, ...participants]));
    }

    uploadFilesWorker(task.id, dispatch, getState);

    dispatch(editByTaskData(task));
    dispatch(fillActivityTasks(activityId));
};
