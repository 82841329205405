import { Dispatch, Store } from 'redux';
import { noop, get } from 'lodash';

import { StoreState } from '@store';
import { FileAsset } from '@store/commonTypes';
import { FileUploadStatus } from '@store/common/types';
import { updateError, setFileUploadStatus, resetFilesUploadStatus } from '@store/common/actions';
import { FileApi, FileApiUploadParams } from '../../api/file-api';
import { addOne, removeOne } from './actions';
import { getFileAssetById } from './selectors';
import { UploadOnePayload, RunUploadOneParams, DeleteOnePayload } from './types';
import { getLoginUser } from '@store/user';

/**
 * Upload one file to server and save it at store. This is a thunk and must be passed throw store's "dispatch"
 * function, but you can handle results by "resolve" (3rd argument) and "reject" (4rd argument) functions.
 */
export const uploadOne =
    ({ skipCreatingFileRecord, fileOrAssetOrId, params, resolve = noop, reject = noop }: UploadOnePayload): any =>
    async (dispatch: Dispatch<StoreState>, getState: Store<StoreState>['getState']) => {
        dispatch(resetFilesUploadStatus());
        const uploadedBy: string | undefined = get(getLoginUser(getState()), 'attributes.id');
        const fileOrAsset: FileAsset | File =
            typeof fileOrAssetOrId === 'string' ? getFileAssetById(getState(), fileOrAssetOrId) : fileOrAssetOrId;

        const uploadProcess = FileApi.uploadFileProcess(params, fileOrAsset, uploadedBy, skipCreatingFileRecord);
        let assetId: string | null = null;
        let assetName: string;
        try {
            for await (const asset of uploadProcess) {
                assetName = `${asset.originName}.${asset.type}`;
                assetId = asset.id;
                dispatch(addOne(asset));
            }
            resolve(getFileAssetById(getState(), assetId!));
        } catch (err) {
            if (err.message.startsWith('Virus signatures detected')) {
                // dispatch(updateError('FileUploadingError'));
                dispatch(
                    setFileUploadStatus({
                        fileName: assetName,
                        status: FileUploadStatus.VirusFound,
                    }),
                );
            }

            if (assetId) {
                dispatch(removeOne(assetId));
            }
            reject(err);
        }
    };

export const deleteOne =
    ({ params, assetOrId, reject = noop, resolve = noop }: DeleteOnePayload): any =>
    async (dispatch: Dispatch<StoreState>, getState: Store<StoreState>['getState']) => {
        const asset = typeof assetOrId === 'string' ? getFileAssetById(getState(), assetOrId) : assetOrId;
        if (asset) {
            try {
                dispatch(removeOne(asset.id));
                await FileApi.removeFile(params, asset.name);
                resolve();
            } catch (err) {
                dispatch(updateError('FileDeletingError'));
                dispatch(addOne(asset));
                reject(err);
            }
        }
    };

/** Awaitable wrapper for "uploadOne" thunk */
export const runUploadOne = ({
    skipCreatingFileRecord,
    params,
    fileOrAssetOrId,
    dispatch,
}: RunUploadOneParams): Promise<FileAsset> =>
    new Promise<FileAsset>((resolve, reject) =>
        dispatch(
            uploadOne({
                skipCreatingFileRecord,
                params,
                fileOrAssetOrId,
                resolve,
                reject,
            }),
        ),
    );

export const runDeleteOne = (
    params: FileApiUploadParams,
    assetOrId: FileAsset | string,
    dispatch: Dispatch<StoreState>,
) => new Promise<void>((resolve, reject) => dispatch(deleteOne({ params, assetOrId, resolve, reject })));
