import * as React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { StoreState } from '@store';
import { ComponentState, getBudgetTransferMenuState, isCellSelected } from '@store/budgetExecution/budgetTransferMenu';

import { WithRequestInProgressMask } from '../WithRequestInProgressMask';
import { WithExpertSelect } from '../WithExpertSelect';
import { ParticipatorCommentInput } from '../ParticipatorCommentInput';
import { Title } from './Title';
import { CellDescription } from './CellDescription';
import { TransitionDirectionToggle } from './TransitionDirectionToggle';
import { SourceDestinationDescription } from './SourceDestinationDescription';
import { ParticipatorItemDictionarySelect } from './ParticipatorItemDictionarySelect';
import { TransferAmountInput } from './TransferAmountInput';
import { InitTransferButton } from './InitTransferButton';

import * as styles from './ExternalTransitionMenu.scss';

function useStore() {
    const showBottomContent = useSelector((state: StoreState) => {
        const {
            cells: { from, to },
            controls: { componentState },
        } = getBudgetTransferMenuState(state);

        let result;
        switch (componentState) {
            case ComponentState.ExternalIncomeTransfer:
                result = isCellSelected(to);
                break;
            case ComponentState.ExternalOutcomeTransfer:
                result = isCellSelected(from);
                break;
            default:
                result = false;
                break;
        }

        return result;
    });

    return { showBottomContent };
}

export function ExternalTransitionMenu(): JSX.Element {
    const { showBottomContent } = useStore();

    return (
        <div className={styles.root}>
            <WithRequestInProgressMask>
                <React.Fragment>
                    <div className={styles.titleRow}>
                        <Title />
                        <TransitionDirectionToggle />
                    </div>

                    <div className={styles.columnsWrapper}>
                        <div className={styles.column}>
                            <ParticipatorItemDictionarySelect />
                        </div>

                        <div className={styles.column}>
                            <TransferAmountInput />
                        </div>
                    </div>

                    <SourceDestinationDescription />

                    <CellDescription />

                    {showBottomContent && (
                        <div className={styles.participatorCommentInput}>
                            <ParticipatorCommentInput />
                        </div>
                    )}
                </React.Fragment>
            </WithRequestInProgressMask>

            {showBottomContent && (
                <div className={classnames(styles.columnsWrapper, styles.bottomRow)}>
                    <WithExpertSelect>
                        <InitTransferButton />
                    </WithExpertSelect>
                </div>
            )}
        </div>
    );
}
