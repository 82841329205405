import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { AutopilotApi, AutopilotDOOHApi, AutopilotLegacyApi, AutopilotRadioApi, AutopilotTvApi } from '@api';
import {
    loadAutopilot,
    loadAutopilotDOOH,
    loadAutopilotLegacy,
    loadAutopilotRadio,
    loadAutopilotTv,
} from '@store/activityPage/thunks';
import { Autopilots } from '@modules/autopilot/types';

interface Props {
    activityId: number;
}

export const useAutopilotAdd = ({ activityId }: Props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const onAutopilotCreation = React.useCallback(async () => {
        const autopilotId = uuid();

        await AutopilotApi.createAutopilot({ activityId, id: autopilotId });

        await AutopilotApi.updateAutopilot(autopilotId, {
            status: 'on',
        });

        await dispatch(loadAutopilot(activityId));

        dispatch(loadAutopilot(activityId));

        history.push(`/activity/${activityId}/autopilot_v2`);
    }, [dispatch, activityId]);

    const onAutopilotLegacyCreation = React.useCallback(async () => {
        await AutopilotLegacyApi.createAutopilot({ activityId, id: uuid() });

        dispatch(loadAutopilotLegacy(activityId));

        history.push(`/activity/${activityId}/autopilot`);
    }, [dispatch, activityId]);

    const onAutopilotTvCreation = React.useCallback(async () => {
        const autopilotId = uuid();

        await AutopilotTvApi.createAutopilot({ activityId, id: autopilotId });

        await AutopilotTvApi.updateAutopilot(autopilotId, {
            status: 'on',
        });

        dispatch(loadAutopilotTv(activityId));

        history.push(`/activity/${activityId}/autopilotTv`);
    }, [dispatch, activityId]);

    const onAutopilotRadioCreation = React.useCallback(async () => {
        const autopilotId = uuid();

        await AutopilotRadioApi.createAutopilot({ activityId, id: autopilotId });

        await AutopilotRadioApi.updateAutopilot(autopilotId, {
            status: 'on',
        });

        dispatch(loadAutopilotRadio(activityId));

        history.push(`/activity/${activityId}/autopilotRadio`);
    }, [dispatch, activityId]);

    const onAutopilotDOOHCreation = React.useCallback(async () => {
        const autopilotId = uuid();

        await AutopilotDOOHApi.createAutopilot({ activityId, id: autopilotId });

        await AutopilotDOOHApi.updateAutopilot(autopilotId, {
            status: 'on',
        });

        dispatch(loadAutopilotDOOH(activityId));

        history.push(`/activity/${activityId}/autopilotDOOH`);
    }, [dispatch, activityId]);

    const creationHandleMap: Record<Autopilots, () => void> = {
        autopilot: onAutopilotLegacyCreation,
        autopilot_v2: onAutopilotCreation,
        autopilotTv: onAutopilotTvCreation,
        autopilotRadio: onAutopilotRadioCreation,
        autopilotDOOH: onAutopilotDOOHCreation,
    };

    return React.useCallback((id: Autopilots) => creationHandleMap[id](), [dispatch, activityId]);
};
