import * as React from 'react';
import { ImageGallery, ImageGalleryItem } from 'sber-marketing-ui';
import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import { getPreviewByItemId, isPreviewValid } from '@store/videoPreviews';

interface Props {
    startIndex: number;
    items: ImageGalleryItem[];
    onOutsideClick: () => void;
}

function useMediaFilesGallery(items: ImageGalleryItem[]): ImageGalleryItem[] {
    const previews = useSelector((state: StoreState) =>
        items.reduce((acc, item) => {
            const preview = useSelector((state: StoreState) => getPreviewByItemId(state, item.id));

            if (preview) {
                return {
                    ...acc,
                    [item.id]: preview,
                };
            }

            return acc;
        }, {}),
    );

    return items.map((item) => {
        const preview = previews[item.id];

        if (item.type === 'video' && isPreviewValid(preview)) {
            return {
                ...item,
                previewUrl: preview.url,
                metaData: {
                    firstFrameUrl: preview.previews[0].url,
                },
            };
        }

        return item;
    });
}

export function MediaFilesGallery({ startIndex, onOutsideClick, ...props }: Props): JSX.Element {
    const items = useMediaFilesGallery(props.items);

    return <ImageGallery startIndex={startIndex} items={items} onOutsideClick={onOutsideClick} />;
}
