import * as React from 'react';

import { useGetTaskQuery } from '@api';

import { CopyTextIconButton, CopyTextIconButtonProps } from '@common/components';

export interface CopyTaskTitleIconButtonProps extends Omit<CopyTextIconButtonProps, 'text'> {
    taskId: string;
}

export function CopyTaskTitleIconButton({ taskId, ...props }: CopyTaskTitleIconButtonProps) {
    const { data } = useGetTaskQuery({ id: taskId });

    if (!data) return null;

    return (
        <CopyTextIconButton
            data-qa-id="CopyTaskTitleIconButton"
            title="Скопировать название задачи в буфер обмена"
            {...props}
            text={data.task.title || ''}
        />
    );
}
