import * as React from 'react';
import { debounce, flatMap, keyBy, mapValues, values } from 'lodash';

import { Icon, IconType } from 'sber-marketing-ui';
import { List } from '../List';

import { Position, ItemParams } from './types';
import { Filters } from '../../../types';

import * as styles from './styles.scss';

interface Props {
    id: string;
    name: string;
    filters: Filters;
    childrenItems: ItemParams[];
    onChange: (filters: Filters) => void;
}

export const Item: React.FC<Props> = ({ id, name, childrenItems, filters, onChange: externalOnChange }) => {
    const rootRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const [position, setPosition] = React.useState<Position>({ top: 0, left: 0 });
    const [hover, setHover] = React.useState<boolean>(false);

    const haveChildrenItems = Boolean(childrenItems.length);
    const everyChildrenFiltersEnabled = values(getChildrenFilters(childrenItems, filters)).every((value) => value);

    const delay = React.useCallback(
        debounce((callback: () => void) => callback(), 120),
        [],
    );

    React.useEffect(() => {
        delay(() => {
            if (!hover) {
                setOpen(false);
            }
        });
    }, [hover]);

    const onClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(
        (event) => {
            event.preventDefault();
            event.stopPropagation();

            if (haveChildrenItems) {
                externalOnChange({
                    [id]: !filters[id],
                    ...buildFiltersWithSelectedStatus(childrenItems, !filters[id]),
                });
            } else {
                externalOnChange({ [id]: !filters[id] });
            }
        },
        [id, filters, haveChildrenItems],
    );

    const onChange = React.useCallback(
        (changedFilters: Filters) => {
            if (haveChildrenItems) {
                const childrenChangedFilters = { ...getChildrenFilters(childrenItems, filters), ...changedFilters };
                const someFiltersOn = values(childrenChangedFilters).some((value) => value);

                externalOnChange({
                    ...changedFilters,
                    [id]: someFiltersOn,
                });
            } else {
                externalOnChange(changedFilters);
            }
        },
        [id, filters, externalOnChange, haveChildrenItems],
    );

    const onMouseEnter = React.useCallback(() => {
        setOpen(true);
        setHover(true);
        setPosition(getPosition(rootRef.current));
    }, []);

    const onMouseLeave = React.useCallback(
        (event) => {
            setHover(false);
        },
        [hover, setHover, setOpen],
    );

    return (
        <div
            ref={rootRef}
            title={name}
            className={styles.root}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
        >
            <div className={styles.indicator}>
                {!filters[id] && <Icon type={IconType.CHECKBOX24_UNCHECKED} svgSize={24} />}
                {filters[id] && everyChildrenFiltersEnabled && <Icon type={IconType.CHECKBOX24_CHECKED} svgSize={24} />}
                {filters[id] && !everyChildrenFiltersEnabled && <Icon type={IconType.CHECKBOX24_MINUS} svgSize={24} />}
            </div>
            <div className={styles.text}>{name}</div>

            {haveChildrenItems && (
                <div className={styles.arrow}>
                    <Icon type={IconType.ARROW16_RIGHT} />
                </div>
            )}

            {open && haveChildrenItems && (
                <div className={styles.listWrapper} style={{ top: `${position.top}px`, left: `${position.left}px` }}>
                    <List id={id} title={name} items={childrenItems} filters={filters} onChange={onChange} />
                </div>
            )}
        </div>
    );
};

const getChildrenFilters = (children: ItemParams[], filters: Filters): Filters => {
    const childrenItemsIds = flatMap(children.map((childItem) => getChildrenIdsFromItem(childItem))).map((id) => id);
    return childrenItemsIds.reduce(
        (currentFilters, childrenId) => ({
            ...currentFilters,
            [childrenId]: filters[childrenId],
        }),
        {},
    );
};

const buildFiltersWithSelectedStatus = (children: ItemParams[], selectedStatus: boolean): Filters => {
    const childrenItemsIds = getChildrenIdsFromItems(children).map((id) => ({ id }));
    return mapValues(keyBy(childrenItemsIds, 'id'), () => selectedStatus);
};

const getChildrenIdsFromItems = (items: ItemParams[]): string[] => {
    return flatMap(items.map((childItem) => getChildrenIdsFromItem(childItem)));
};

const getChildrenIdsFromItem = ({ id, childrenItems }: ItemParams): string[] => {
    return childrenItems.length ? [...getChildrenIdsFromItems(childrenItems), id] : [id];
};

const getPosition = (element: HTMLDivElement): Position => {
    const clientRect = element.getBoundingClientRect();

    return {
        left: clientRect.x + clientRect.width,
        top: clientRect.y,
    };
};
