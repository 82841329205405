import * as React from 'react';
import * as lodash from 'lodash';
import classNames from 'classnames';

import * as style from './SumLine.scss';

import { CustomScrollbar_new as CustomScrollbar, ScrollbarComponent, Icon, IconType } from 'sber-marketing-ui';
import {
    ColumnName,
    ColumnsWidth,
    ColumnsVisiblityFilter,
    CellValueType,
    ColumnData,
} from '@store/budgetExecution/types';

import { ColumnsList } from '../../ColumnsConfig';

const SUMLINE_HEIGHT = 57;
const LINE_MENU_WIDTH = 184;

export type CellsValues = { [columnName: string]: string };

interface Props {
    isOpened: boolean;
    columnsWidth: ColumnsWidth;
    columnsVisiblityFilter: ColumnsVisiblityFilter;
    fixedColumnsNames: ColumnName[];
    cellsParams: CellsValues;
    scrollbarRef: (component: ScrollbarComponent) => void;
    onButtonClick: () => void;
}

export const SumLine = ({
    isOpened,
    columnsWidth,
    columnsVisiblityFilter,
    fixedColumnsNames,
    cellsParams,
    scrollbarRef,
    onButtonClick,
}: Props): JSX.Element => {
    const allColumnsAreHidden = lodash.every(columnsVisiblityFilter, (item) => item === false);

    const columns = allColumnsAreHidden
        ? ColumnsList
        : ColumnsList.filter((column) => columnsVisiblityFilter[column.name]);

    const fixedColumns = fixedColumnsNames
        .filter((item) => columns.some((column) => column.name == item))
        .map((item) => columns.find((column) => column.name == item));

    const nonfixedColumns = columns.filter((item) => !lodash.includes(fixedColumnsNames, item.name));

    const fixedColumnsWidthSum = fixedColumns.reduce((acc, item) => acc + columnsWidth[item.name], 0);

    return (
        <div
            className={classNames(style.root, isOpened && style.opened)}
            {...{
                'qa-id': 'tableSumLine',
            }}
        >
            <div className={style.slider}>
                <div
                    className={style.button}
                    onClick={onButtonClick}
                    {...{
                        'qa-id': 'tableSumLineToggleButton',
                    }}
                >
                    <Icon type={isOpened ? IconType.ARROW16_DOWN_BLACK : IconType.ARROW16_UP_WHITE} />
                </div>

                <div className={style.line}>
                    <CustomScrollbar
                        fixedHeight={SUMLINE_HEIGHT}
                        scrollbarRef={scrollbarRef}
                        freezeScrollX
                        hideScrollX
                        hideScrollY
                    >
                        <div
                            className={style.cells}
                            style={{
                                paddingLeft: fixedColumnsWidthSum,
                                paddingRight: LINE_MENU_WIDTH,
                            }}
                        >
                            {nonfixedColumns.map((column) => (
                                <Cell
                                    key={column.name}
                                    width={columnsWidth[column.name]}
                                    value={cellsParams[column.name]}
                                    column={column}
                                />
                            ))}
                        </div>
                    </CustomScrollbar>

                    <div className={style.fixedCells}>
                        {fixedColumns.map((column) => (
                            <Cell
                                key={column.name}
                                width={columnsWidth[column.name]}
                                value={cellsParams[column.name]}
                                column={column}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

interface CellParams {
    width: number;
    value: string;
    column: ColumnData;
}

function Cell({ width, value, column }: CellParams): JSX.Element {
    return (
        <div
            className={style.cell}
            style={{ width }}
            {...{
                'qa-id': 'tableSumLineCell',
                'qa-index': column.name,
            }}
        >
            {column.valueType === CellValueType.Currency && (
                <>
                    <div className={style.cellTitle}>{column.title}</div>

                    <div
                        className={style.cellValue}
                        {...{
                            'qa-id': 'tableSunLineCellValue',
                        }}
                    >
                        {value}
                    </div>
                </>
            )}
        </div>
    );
}
