import * as React from 'react';
import * as lodash from 'lodash';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';

import { MultiReferenceDictionaryApi } from '@api';

import { StoreState } from '@store';
import {
    DropdownOptions,
    TableLine,
    ChangeList,
    GroupedDicitonaries,
    getBudgetExecutionPageState,
    getDropdownsOptionsByLineId,
    updateDropdownsOptions,
} from '@store/budgetExecution';
import { getBudgetTransferMenuState, isBudgetTransferMenuClosed } from '@store/budgetExecution/budgetTransferMenu';
import { UserResponseParams } from 'sber-marketing-types/backend';

interface Props extends Partial<MapProps & DispatchProps> {}

interface MapProps {
    tableLines: TableLine[];
    isBudgetTransferMenuClosed: boolean;
    unsavedChanges: ChangeList;
    users: UserResponseParams[];
    dictionaries: GroupedDicitonaries;
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi;
}

interface DispatchProps {
    updateDropdownsOptions: (dropdownOptions: DropdownOptions) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class DropdownOptionsEffect extends React.PureComponent<Props> {
    public componentDidUpdate(prevProps: Props): void {
        const unsavedChangesKeys = lodash.uniq([
            ...Object.keys(this.props.unsavedChanges),
            ...Object.keys(prevProps.unsavedChanges),
        ]);
        const linesToUpdateOptions = unsavedChangesKeys.filter((unsavedChangedKey) => {
            if (
                !this.props.unsavedChanges[unsavedChangedKey]?.length &&
                !prevProps.unsavedChanges[unsavedChangedKey]?.length
            ) {
                return false;
            }

            return this.props.unsavedChanges[unsavedChangedKey] !== prevProps.unsavedChanges[unsavedChangedKey];
        });

        this.recalcDropdownOptions(linesToUpdateOptions);
    }

    public render(): JSX.Element {
        // component used only for side-effect
        return null;
    }

    private recalcDropdownOptions(lineIds: string[]): void {
        const {
            tableLines,
            isBudgetTransferMenuClosed,
            unsavedChanges,
            users,
            dictionaries,
            multiReferenceDictionaryApi,
        } = this.props;

        const dropdownsOptions = {};

        function shouldUseLine(line: TableLine) {
            return lineIds.length ? lineIds.includes(line.id) : true;
        }

        if (isBudgetTransferMenuClosed) {
            tableLines.forEach((item) => {
                if (shouldUseLine(item)) {
                    dropdownsOptions[item.id] = getDropdownsOptionsByLineId(
                        item,
                        users,
                        dictionaries,
                        unsavedChanges[item.id] || [],
                        multiReferenceDictionaryApi,
                    );
                }
            });
        }

        this.props.updateDropdownsOptions(dropdownsOptions);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const {
        computedData: { tableLines },
        pageData: { users, allDictionaries },
        unsavedChanges,
        multiReferenceDictionaryApi,
    } = getBudgetExecutionPageState(state);

    return {
        tableLines,
        users,
        isBudgetTransferMenuClosed: isBudgetTransferMenuClosed(
            getBudgetTransferMenuState(state).controls.componentState,
        ),
        unsavedChanges,
        dictionaries: allDictionaries,
        multiReferenceDictionaryApi,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        updateDropdownsOptions: (dropdownOptions: DropdownOptions) => dispatch(updateDropdownsOptions(dropdownOptions)),
    };
}
