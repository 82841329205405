import * as React from 'react';
import autobind from 'autobind-decorator';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { GetPreviewResponse } from '@mrm/videoPreview';

import { FileApi, FileApiUploadParams, ImageService } from '@api';

import { StoreState } from '@store';
import {
    OpenEditorPayload,
    LoadDocumentStatusPayload,
    openEditor,
    loadDocumentStatus,
    documentWasEditedInR7,
} from '@store/R7';
import { LoadPreviewPaylaod, loadPreview, getPreviewByItemId } from '@store/videoPreviews';

import { Utils } from '@common/Utils';

import { FileAsset } from './FileAsset';

export interface Props extends OwnProps, Partial<MapProps & DispatchProps> {}

interface OwnProps extends FileApiUploadParams {
    id: string;
    name: string;
    originName: string;
    type: string;
    preventDownload?: boolean;
    showDownloadButton?: boolean;
    useR7Controls?: boolean;
    useMediaControls?: boolean;
    canEditInR7?: boolean;
    disableImagePreview?: boolean;
    onGalleryButtonClick?: () => void;
}

interface MapProps {
    documentWasEditedInR7: boolean;
    videoPreview: GetPreviewResponse;
}

interface DispatchProps {
    openEditor: (payload: OpenEditorPayload) => void;
    loadDocumentStatus: (payload: LoadDocumentStatusPayload) => void;
    loadPreview: (payload: LoadPreviewPaylaod) => void;
}

interface State {
    isHovered: boolean;
    previewWasLoaded: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class FileAssetContainer extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isHovered: false,
            previewWasLoaded: false,
        };
    }

    public componentDidMount(): void {
        const { id } = this.props;

        if (this.useR7()) {
            this.props.loadDocumentStatus({
                fileId: id,
                fileParams: this.getUploadParams(),
            });
        } else {
            this.loadPreviewInNecessary();
        }
    }

    public componentDidUpdate(): void {
        this.loadPreviewInNecessary();
    }

    public render(): JSX.Element {
        const {
            name,
            type,
            preventDownload,
            showDownloadButton,
            disableImagePreview,
            videoPreview,
            documentWasEditedInR7,
        } = this.props;

        const { isHovered } = this.state;

        const showImagePreview = !disableImagePreview && !preventDownload && Utils.isImage(type);
        const imagePreview = showImagePreview ? ImageService.getUrlsByIdAndType(name, type).preview : null;

        return (
            <FileAsset
                useGallery={this.useR7() || this.useMedia()}
                preventDownload={preventDownload}
                showDownloadButton={showDownloadButton}
                type={type}
                isHovered={isHovered}
                imagePreview={imagePreview}
                videoPreview={videoPreview}
                documentWasEditedInR7={documentWasEditedInR7}
                onDownloadButtonClick={this.onDownloadButtonClick}
                onGalleryButtonClick={this.onGalleryButtonClick}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            />
        );
    }

    @autobind
    private onDownloadButtonClick(): void {
        const { id, preventDownload, disableImagePreview, children, ...downloadParams } = this.props;

        if (!preventDownload) {
            FileApi.downloadFile(downloadParams, id);
        }
    }

    @autobind
    private onGalleryButtonClick(): void {
        const { id, originName, type, canEditInR7, onGalleryButtonClick } = this.props;

        if (this.useR7()) {
            this.props.openEditor({
                fileName: originName,
                fileType: type,
                fileId: id,
                editable: canEditInR7,
                fileParams: this.getUploadParams(),
            });
        } else if (this.useMedia()) {
            onGalleryButtonClick();
        }
    }

    @autobind
    private onMouseEnter(): void {
        this.setState({
            isHovered: true,
        });
    }

    @autobind
    private onMouseLeave(): void {
        this.setState({
            isHovered: false,
        });
    }

    private useR7(): boolean {
        return this.props.useR7Controls && Utils.fileValidForR7(this.props.type);
    }

    private useMedia(): boolean {
        const { useMediaControls, type, onGalleryButtonClick } = this.props;

        return (
            useMediaControls &&
            onGalleryButtonClick &&
            (Utils.isAudio(type) || Utils.isVideo(type) || Utils.isImage(type))
        );
    }

    private loadPreviewInNecessary(): void {
        const { id, originName, type, preventDownload } = this.props;

        const isVideo = Utils.isVideo(type);

        if (isVideo && !(this.state.previewWasLoaded || preventDownload)) {
            this.setState(
                {
                    previewWasLoaded: true,
                },
                () => {
                    this.props.loadPreview({
                        uploadParams: this.getUploadParams(),
                        id,
                        name: originName,
                        extension: type,
                    });
                },
            );
        }
    }

    private getUploadParams(): FileApiUploadParams {
        const { activityId, briefId, fieldId, taskId, commentId, fileId, originName, type } = this.props;

        return {
            activityId,
            briefId,
            fieldId,
            taskId,
            commentId,
            fileId,
            originName,
            type,
        };
    }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): MapProps {
    return {
        videoPreview: getPreviewByItemId(state, ownProps.id),
        documentWasEditedInR7: documentWasEditedInR7(state, ownProps.id),
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        openEditor: (payload: OpenEditorPayload) => dispatch(openEditor(payload)),
        loadDocumentStatus: (payload: LoadDocumentStatusPayload) => dispatch(loadDocumentStatus(payload)),
        loadPreview: (payload: LoadPreviewPaylaod) => dispatch(loadPreview(payload)),
    };
}
