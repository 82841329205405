// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilotTv-StageWidgets-BudgetStageWidget-CardGroup-_CardGroup__root{display:flex;width:100%}.src-client-modules-activity-pages-autopilotTv-StageWidgets-BudgetStageWidget-CardGroup-_CardGroup__card{width:100%}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilotTv/StageWidgets/BudgetStageWidget/CardGroup/CardGroup.scss"],"names":[],"mappings":"AAAA,yGACI,YAAa,CACb,UAAW,CACd,yGAGG,UAAW","sourcesContent":[".root {\n    display: flex;\n    width: 100%;\n}\n\n.card {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilotTv-StageWidgets-BudgetStageWidget-CardGroup-_CardGroup__root",
	"card": "src-client-modules-activity-pages-autopilotTv-StageWidgets-BudgetStageWidget-CardGroup-_CardGroup__card"
};
export default ___CSS_LOADER_EXPORT___;
