// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-WithExpertSelect-ExpertSelect-_ExpertSelect__title{margin-bottom:8px;letter-spacing:0.4px;text-transform:uppercase;color:#7e8681;font-size:12px;line-height:16px}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-WithExpertSelect-ExpertSelect-_ExpertSelect__itemSelect{height:22px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/WithExpertSelect/ExpertSelect/ExpertSelect.scss"],"names":[],"mappings":"AAAA,4HACI,iBAAkB,CAElB,oBAAqB,CACrB,wBAAyB,CAEzB,aAAc,CAEd,cAAe,CACf,gBAAiB,CACpB,iIAGG,WAAY","sourcesContent":[".title {\n    margin-bottom: 8px;\n\n    letter-spacing: 0.4px;\n    text-transform: uppercase;\n\n    color: #7e8681;\n\n    font-size: 12px;\n    line-height: 16px;\n}\n\n.itemSelect {\n    height: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-WithExpertSelect-ExpertSelect-_ExpertSelect__title",
	"itemSelect": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-WithExpertSelect-ExpertSelect-_ExpertSelect__itemSelect"
};
export default ___CSS_LOADER_EXPORT___;
