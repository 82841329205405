import { actionCreatorFactory } from 'typescript-fsa';

import { FilterKey, ChangeCellValueParams } from '../types';

const actionCreator = actionCreatorFactory('BUDGET_PLANNING_PAGE_ASYNC').async;

const loadFiltersByColumnName = actionCreator<FilterKey, void, Error>('LOAD_FILTERS_BY_COLUMN_NAME');
const downloadXSLSXTemplate = actionCreator<null, void, Error>('DOWNLOAD_XSLX_TEMPLATE');
const loadXLSXContent = actionCreator<File, void, Error>('LOAD_XLSX_CONTENT');
const changeCellValue = actionCreator<ChangeCellValueParams, ChangeCellValueParams, Error>('CHANGE_CELL_VALUE_ASYNC');

export { loadFiltersByColumnName, downloadXSLSXTemplate, loadXLSXContent, changeCellValue };
