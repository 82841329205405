import { TableLineGroup } from '@store/budgetPlanning/types';

import { FrameManager } from './FrameManager';
import { GroupedFrame } from './GroupedFrame';

const LINE_HEIGHT = 38;
const TOTAL_LINE_MARGIN = 10;

export class GroupedFrameManager extends FrameManager<TableLineGroup, GroupedFrame> {
    protected makeFrame(startItemIndex: number, frameIndex: number): GroupedFrame {
        const frameMinHeight = this.calculateMinFrameHeight();
        const itemsCount = this.items.length;

        const frameStart = startItemIndex;
        let frameEnd = startItemIndex + 1;

        let frameHeight = 0;

        while (frameHeight < frameMinHeight && frameEnd < itemsCount) {
            frameHeight += this.itemsHeights[frameEnd];
            frameEnd++;
        }

        const framePosition = this.itemsHeights.slice(0, frameStart).reduce((acc, item) => acc + item, 0);

        const frameIsLast = frameEnd + 1 > itemsCount - 1;

        if (frameIsLast) {
            frameEnd = itemsCount;
        }

        const frameItems = this.items.slice(frameStart, frameEnd);

        const frame = new GroupedFrame({
            index: frameIndex,
            position: framePosition,
            groups: frameItems,
            isLast: frameIsLast,
        });

        return frame;
    }

    protected calculateItemsHeights(): number[] {
        return this.items.map((item, index) => {
            const isLast = index == this.items.length - 1;

            let itemHeight = (item.lines.length + 1) * LINE_HEIGHT;

            if (!isLast) {
                itemHeight += TOTAL_LINE_MARGIN;
            }

            return itemHeight;
        });
    }
}
