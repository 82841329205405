import * as React from 'react';
import classNames from 'classnames';
import { flatMap } from 'lodash';
import { Icon, IconType } from 'sber-marketing-ui';
import { BudgetItemSnapshot } from '@mrm/budget';

import { DifferenceTransformerMap } from './DifferenceItems';

import * as styles from './DifferenceList.scss';

export function shouldRender(diffs: string[]): boolean {
    return diffs.some((diff) => shouldRenderItem(diff));
}

function shouldRenderItem(diff: string): boolean {
    return !!DifferenceTransformerMap[diff];
}

interface Props {
    diffs: string[];
    curr: BudgetItemSnapshot;
    prev: BudgetItemSnapshot;
}

const noValueText = 'Не задано';

export function DifferenceList({ diffs, curr, prev }: Props): JSX.Element {
    const diffsToDisplay = diffs.filter(shouldRenderItem);

    return diffsToDisplay.length ? (
        <div className={styles.root}>
            {flatMap(diffsToDisplay, (diff) => {
                const items = DifferenceTransformerMap[diff](prev, curr, diff);

                return items.map((item) => {
                    const before = item.before || noValueText;
                    const after = item.after || noValueText;

                    return (
                        <div className={styles.item} key={`${diff}-${item.title}`}>
                            {item.title}:&nbsp;
                            <span
                                title={item.beforeTitle || before}
                                className={classNames(styles.lineBreak, styles.before)}
                            >
                                {before}
                            </span>
                            <Icon type={IconType.BUDGET_TRANSFER_MENU_ARROW} className={styles.arrow} svgSize={20} />
                            <span
                                title={item.afterTitle || after}
                                className={classNames(styles.lineBreak, styles.after)}
                            >
                                {after}
                            </span>
                        </div>
                    );
                });
            })}
        </div>
    ) : null;
}
