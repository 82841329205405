import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import * as lodash from 'lodash';

import * as style from './PerformanceMetrics.scss';

import { AutopilotRadioMediaplanKeyValue, AutopilotRadioMediaplanSelection } from 'sber-marketing-types/backend';
import type { StoreState } from '@store';

import { Icon, IconType } from 'sber-marketing-ui';
import { Loader, Saver } from '../../../modules';
import { setPreloaderStatus } from '@store/autopilotRadio/actions';
import { getAutopilot, getMediaplan } from '@store/autopilotRadio/selectors';

interface Props {}

export const PerformanceMetrics = ({}: Props): JSX.Element => {
    const autopilot = useSelector((state: StoreState) => getAutopilot(state));
    const mediaplan = useSelector((state: StoreState) => getMediaplan(state));

    const saver = Saver.getInstance();
    const loader = Loader.getInstance();

    const dispatch = useDispatch();

    const onCampaignSelection = React.useCallback(
        async (campaignType: AutopilotRadioMediaplanSelection) => {
            const updateParams = { ...autopilot.mediaplan, selection: campaignType };

            dispatch(setPreloaderStatus(true));

            await saver.updateMediaplan(updateParams);
            await loader.loadAutopilot();

            dispatch(setPreloaderStatus(false));
        },
        [dispatch],
    );

    return (
        <div className={style.root}>
            <MetricsCard
                name={'Пользовательская'}
                properties={makeProperties(mediaplan.custom.effectivity)}
                radios={getPropertyValue(mediaplan.custom.effectivity, 'Радиостанции') as string[]}
                selected={autopilot.mediaplan?.selection === 'custom'}
                onSelection={() => onCampaignSelection('custom')}
            />

            <MetricsCard
                name={'Рекомендация алгоритма'}
                properties={makeProperties(mediaplan.recommended.effectivity)}
                radios={getPropertyValue(mediaplan.recommended.effectivity, 'Радиостанции') as string[]}
                selected={autopilot.mediaplan?.selection === 'recommended'}
                onSelection={() => onCampaignSelection('recommended')}
            />

            <MetricsCard
                name={'С пакетами'}
                properties={makeProperties(mediaplan.packet?.effectivity)}
                radios={getPropertyValue(mediaplan.packet?.effectivity, 'Радиостанции') as string[]}
                selected={autopilot.mediaplan?.selection === 'packet'}
                onSelection={() => onCampaignSelection('packet')}
            />
        </div>
    );
};

function makeProperties(values: AutopilotRadioMediaplanKeyValue[]): { name: string; value: any }[] {
    if (!values) {
        return null;
    }

    return [
        { name: 'Бюджет до НДС', value: roundNumber(getPropertyValue(values, 'Бюджет') as number) },
        { name: 'Reach 1+, тыс.', value: roundNumber(getPropertyValue(values, 'Reach 1') as number) },
        { name: 'Reach 3+, тыс.', value: roundNumber(getPropertyValue(values, 'Reach 3') as number) },
        // { name: 'Reach 1000+', value: roundNumber(getPropertyValue(values, 'Reach 1000')  as number) },
        { name: 'Reach 1+, %', value: roundNumber(getPropertyValue(values, 'Reach 1%') as number) },
        { name: 'Reach 3+, %', value: roundNumber(getPropertyValue(values, 'Reach 3%') as number) },
        { name: 'Freq', value: roundNumber(getPropertyValue(values, 'Frequency') as number) },
        { name: 'Index T/U Reach', value: Math.round(getPropertyValue(values, 'RP Index') as number) },
        { name: 'GI, тыс.', value: roundNumber(getPropertyValue(values, 'GI') as number) },
        { name: 'CPT for Reach, руб.', value: roundNumber(getPropertyValue(values, 'CPT for Reach') as number) },
    ];
}

function getPropertyValue(values: AutopilotRadioMediaplanKeyValue[], key: string) {
    return values && !lodash.isEmpty(values) ? values.find((item) => item.key === key)?.value || null : null;
}

function roundNumber(value: number, digitsAfterComma = 2): string {
    if (value === undefined) {
        return null;
    }

    const formatedValue = value.toFixed(digitsAfterComma);

    const [decimalPart, fractionPart] = formatedValue.split('.');

    return `${decimalPart}${fractionPart ? `.${fractionPart}` : ''}`;
}

interface MetricsCardProps {
    name: string;
    properties: { name: string; value: any }[];
    radios: string[];
    selected?: boolean;
    onSelection: () => void;
}

function MetricsCard({ name, properties, radios, selected, onSelection }: MetricsCardProps): JSX.Element {
    if (!properties) {
        return null;
    }

    return (
        <div className={classNames(style.card, selected && style.selected)}>
            <div className={style.title}>{name}</div>

            <div className={style.properties}>
                {properties.map((item) => (
                    <div className={style.property} key={item.name}>
                        <div className={style.propertyName}>{item.name}</div>

                        <div className={style.propertyValue}>{item.value}</div>
                    </div>
                ))}
            </div>

            <div className={style.stations}>
                <Icon type={IconType.RADIO_TOWER} svgSize={32} className={style.stationsIcon} />

                <div className={style.stationsTitle}>Радиостанции:</div>

                <div className={style.stationsList}>{radios ? radios.join(', ') : '—'}</div>
            </div>

            <div className={style.button} onClick={!selected ? onSelection : null}>
                {selected ? 'Выбрано для эстимации' : 'Выбрать для эстимации'}
            </div>
        </div>
    );
}
