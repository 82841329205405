import * as React from 'react';
import { useSelector } from 'react-redux';
import { BudgetItem as BudgetItemDTO, Funds } from '@mrm/budget';
import { DictionaryType } from '@mrm/dictionary';
import { sum, values } from 'lodash';

import { StoreState } from '@store';
import { getTagsPageState } from '@store/tagsPage';

import { Utils, Money, MoneyFormatter, DatesFormatter } from '@common/Utils';

import { Item } from '../Item';

export enum BudgetItemSource {
    Execution = 'Execution',
    Plan = 'Plan',
}

interface Props {
    source: BudgetItemSource;
}

function useStore(props: Props) {
    const { source } = props;

    const budgetItem = useSelector((state: StoreState) => {
        const { executionBudgetItem, planBudgetItem } = getTagsPageState(state).selectedNodeData;

        switch (source) {
            case BudgetItemSource.Execution:
                return executionBudgetItem;
            case BudgetItemSource.Plan:
                return planBudgetItem;
            default:
                console.warn(`Missing case-declaration for BudgetItemSource`);
                return null;
        }
    });

    return { budgetItem };
}

const NO_VALUE = '-';

function getTextValue(value: string): string {
    return value || NO_VALUE;
}

function getDictionaryValue(budgetItem: BudgetItemDTO, dictionary: DictionaryType): string {
    return Utils.getDictionaryValue(budgetItem?.dictionary?.[dictionary], NO_VALUE);
}

function getFundsValue(funds: Funds): string {
    const fundsVal = sum(values(funds)) || 0;

    return MoneyFormatter.toThousands(Money.fromCopecks(fundsVal));
}

function getFundsDiffValue(fundsA: Funds, fundsB: Funds): string {
    const diffFunds = Object.keys(fundsA).reduce(
        (acc, fundKey) => ({
            ...acc,
            [fundKey]: fundsB[fundKey] - fundsA[fundKey],
        }),
        {},
    );

    return getFundsValue(diffFunds);
}

export function BudgetItem(props: Props): JSX.Element {
    const { budgetItem } = useStore(props);

    if (!budgetItem) {
        console.warn(`Expected budgetItem data was not found in store`);

        return null;
    }

    const authorContent = getTextValue(
        budgetItem.author ? `${budgetItem?.author?.firstName} ${budgetItem?.author?.secondName}` : null,
    );
    const responsiblesContent = getTextValue(
        budgetItem?.responsibles?.length
            ? budgetItem?.responsibles?.map((user) => `${user.secondName} ${user.firstName}`)?.join(', ')
            : null,
    );

    const activityNameContent = getTextValue(budgetItem?.activity?.name);
    const blockContent = getDictionaryValue(budgetItem, DictionaryType.Block);
    const divisionContent = getDictionaryValue(budgetItem, DictionaryType.Division);

    const toolContent = getDictionaryValue(budgetItem, DictionaryType.Tool);
    const itemContent = getDictionaryValue(budgetItem, DictionaryType.Item);
    const resourceContent = getDictionaryValue(budgetItem, DictionaryType.Resource);

    const planContent = getFundsValue(budgetItem?.plannedFunds);
    const reserveContent = getFundsValue(budgetItem?.reservedFunds);
    const factContent = getFundsValue(budgetItem?.factFunds);
    const planReserveDiffContent = getFundsDiffValue(budgetItem?.plannedFunds, budgetItem?.reservedFunds);
    const planFactDiffContent = getFundsDiffValue(budgetItem?.plannedFunds, budgetItem?.factFunds);

    return (
        <React.Fragment>
            <Item qaId="tagspageRightSidebarBudgetItemContentAuthor" title="Автор" content={authorContent} />
            <Item
                qaId="tagspageRightSidebarBudgetItemContentExecutors"
                title="Исполнители"
                content={responsiblesContent}
            />
            <Item
                qaId="tagspageRightSidebarBudgetItemContentCreationTime"
                title="Дата создания"
                content={DatesFormatter.ddMonthyy(budgetItem?.creationTime)}
            />

            <Item
                qaId="tagspageRightSidebarBudgetItemActivityName"
                title="Название активности"
                content={activityNameContent}
            />
            <Item qaId="tagspageRightSidebarBudgetItemContentBlock" title="Блок" content={blockContent} />
            <Item qaId="tagspageRightSidebarBudgetItemContentDivision" title="Дивизион" content={divisionContent} />

            <Item qaId="tagspageRightSidebarBudgetItemContentTool" title="Инструмент" content={toolContent} />
            <Item qaId="tagspageRightSidebarBudgetItemContentItem" title="Статья" content={itemContent} />
            <Item qaId="tagspageRightSidebarBudgetItemContentResource" title="Ресурс" content={resourceContent} />

            <Item qaId="tagspageRightSidebarBudgetItemContentPlan" title="План" content={planContent} />
            <Item qaId="tagspageRightSidebarBudgetItemContentReserve" title="Резерв" content={reserveContent} />
            <Item qaId="tagspageRightSidebarBudgetItemContentFact" title="Факт" content={factContent} />

            <div />
            <Item
                qaId="tagspageRightSidebarBudgetItemContentPlanReserveDiff"
                title="Остаток по резерву"
                content={planReserveDiffContent}
            />
            <Item
                qaId="tagspageRightSidebarBudgetItemContentPlanFactDiff"
                title="Остаток по факту"
                content={planFactDiffContent}
            />
        </React.Fragment>
    );
}
