function injectFavicon(editorWindow: Window, faviconSize: number) {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/png';
    (link as any).sizes = `${faviconSize}x${faviconSize}`;
    link.href = `https://mrm.sbermarketing.ru/static/images/favicon-${faviconSize}x${faviconSize}.png`;

    editorWindow.document.head.appendChild(link);
}

export async function openEditorWindow(editorContent: string, originFileName: string) {
    const editorWindow = window.open('', '_blank');

    editorWindow.document.body.style.margin = '0';

    const title = document.createElement('title');
    title.innerText = originFileName;
    editorWindow.document.head.appendChild(title);

    injectFavicon(editorWindow, 16);
    injectFavicon(editorWindow, 32);
    injectFavicon(editorWindow, 48);

    const frame = document.createElement('iframe');
    frame.style.width = '100vw';
    frame.style.height = '100vh';
    frame.style.border = '0';
    editorWindow.document.body.appendChild(frame);
    frame.contentDocument.write(editorContent);
}
