// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-TaskSidebar-tabs-InfoTab-_InfoTab__subWidget{margin-top:14px}.src-client-modules-common-TaskSidebar-tabs-InfoTab-_InfoTab__budgetApproval{margin-top:14px;border-top:1px solid #eee;padding-top:14px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/TaskSidebar/tabs/InfoTab/InfoTab.scss"],"names":[],"mappings":"AAAA,wEACI,eAAgB,CACnB,6EAGG,eAAgB,CAChB,yBAA0B,CAC1B,gBAAiB","sourcesContent":[".subWidget {\n    margin-top: 14px;\n}\n\n.budgetApproval {\n    margin-top: 14px;\n    border-top: 1px solid #eee;\n    padding-top: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subWidget": "src-client-modules-common-TaskSidebar-tabs-InfoTab-_InfoTab__subWidget",
	"budgetApproval": "src-client-modules-common-TaskSidebar-tabs-InfoTab-_InfoTab__budgetApproval"
};
export default ___CSS_LOADER_EXPORT___;
