import { Success, Failure, AsyncActionCreators, ActionCreator } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { clone, isFunction, maxBy } from 'lodash';

import { LoadingStatus } from '@store/commonTypes';
import { resetStore } from './actions';

/** Returns "null" always. May be used as reducer for actions which makes state item to be "null" */
export const stubNull = (): any => null;

/** Common reducer which replaces state item by payload value */
export const replaceReducer = <S>(_: S, payload: S): S => payload;

/** Common reducer which replaces state item by done payload of async action from "typescript-fsa" */
export const replaceAsyncSuccessReducer = <P, S>(state: S, { result }: Success<P, S>) => replaceReducer(state, result);

/** Common reducer which replaces state item by failure payload of async action from "typescript-fsa" */
export const replaceAsyncFailureReducer = <P, S>(state: S, { error }: Failure<P, S>) => replaceReducer(state, error);

/** Initial state getter for "resetableReducerWithInitialState" */
export type InitialStateGetter<S> = () => S;

/**
 * Creates reducer with initial state which will be automatically reverted to
 * that initial state on "resetStore" ("COMMON/RESET_STORE") action and, optionally, your special reset actions
 */
export const resetableReducerWithInitialState = <S>(
    initialState: S | InitialStateGetter<S>,
    ...resetActions: ActionCreator<any>[]
) =>
    reducerWithInitialState(isFunction(initialState) ? initialState() : initialState).cases(
        [resetStore, ...resetActions],
        isFunction(initialState) ? initialState : () => clone(initialState),
    );

/**
 * Creates "LoadingStatus" reducer for one or more async actions.
 * This reducer has initial state ("LoadingStatus.NOT_LOADED") and
 * will be reverter to it on "resetStore" ("COMMON/RESET_STORE") and, optionally, your special reset actions
 */
export const loadingStatusReducer = (
    asyncActions: AsyncActionCreators<any, any, any>[],
    resetActions: ActionCreator<any>[] = [],
) => {
    const startActions = asyncActions.map(({ started }) => started);
    const failedActions = asyncActions.map(({ failed }) => failed);
    const doneActions = asyncActions.map(({ done }) => done);
    return resetableReducerWithInitialState<LoadingStatus>(LoadingStatus.NOT_LOADED, ...resetActions)
        .cases(startActions, () => LoadingStatus.LOADING)
        .cases(failedActions, () => LoadingStatus.ERROR)
        .cases(doneActions, () => LoadingStatus.LOADED);
};

/**
 * Create error reducer
 */
export const asyncActionErrorReducer = <E = Error>(
    initialState: E | InitialStateGetter<E>,
    asyncActions: AsyncActionCreators<any, any, E>[],
    resetActions: ActionCreator<any>[] = [],
) => {
    const failedActions = asyncActions.map(({ failed }) => failed);
    const allResetActions = [
        ...resetActions,
        ...asyncActions.map(({ started }) => started),
        ...asyncActions.map(({ done }) => done),
    ];
    return resetableReducerWithInitialState<E>(initialState, ...allResetActions).cases(
        failedActions,
        replaceAsyncFailureReducer,
    );
};

/** Priority indexes for loading statuses */
const LOADING_STATUS_PRIORITIES = {
    [LoadingStatus.NOT_LOADED]: 3,
    [LoadingStatus.LOADING]: 2,
    [LoadingStatus.ERROR]: 1,
    [LoadingStatus.LOADED]: 0,
};

/** Get priority loading status */
export const getPriorityLoadingStatus = (...statuses: LoadingStatus[]): LoadingStatus =>
    maxBy(statuses, (status: LoadingStatus): number => LOADING_STATUS_PRIORITIES[status]);

export const errorIsFromAntivirus = (error: Error) =>
    error.message === 'Virus signatures detected: Eicar-Test-Signature';
