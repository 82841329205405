import * as React from 'react';
import autobind from 'autobind-decorator';
import { get } from 'lodash';
import { DataProps, graphql } from 'react-apollo';
import { ActivityFileGroup } from 'sber-marketing-types/frontend';

import { WITH_PROJECT_FILES_QUERY } from './query';

export interface ChildrenProps {
    loading: boolean;
    filesByGroup: {
        [key: string]: RenderFileProps[];
    };
    refetch: () => Promise<any>;
}

interface Props extends ExternalProps, QueryProps {}

interface ExternalProps {
    activityId: number;
    children: (props: ChildrenProps) => JSX.Element;
}

interface FileProps {
    category: ActivityFileGroup;
    file: GraphqlFileProps;
    author: {
        firstName: string;
        secondName: string;
    };
}

interface GraphqlFileProps {
    id: string;
    name: string;
    originName: string;
    type: string;
    size: number;
}

interface RenderFileProps extends GraphqlFileProps {
    author: {
        firstName: string;
        secondName: string;
    };
}

type QueryProps = DataProps<{
    project: {
        nodes: {
            files: [];
        }[];
    };
}>;

class FileGroupsConnectedWrapper extends React.PureComponent<Props> {
    private defaultGroupedFiles: Record<ActivityFileGroup, RenderFileProps[]> = {
        [ActivityFileGroup.CREATIVE]: [],
        [ActivityFileGroup.MEDIAPLAN]: [],
    };

    public render(): JSX.Element {
        return this.props.children(this.getProps());
    }

    @autobind
    private getProps(): ChildrenProps {
        if (this.props.data && this.props.data.project) {
            const { project, loading, refetch } = this.props.data;
            const files: FileProps[] = get(project, 'nodes[0].files') || [];

            const filesByGroup = files
                ? files.reduce(
                      (acc, file) => {
                          const fileContent = {
                              ...file.file,
                              author: file.author,
                          };

                          if (!acc[file.category]) {
                              acc[file.category] = [fileContent];
                          } else {
                              acc[file.category] = [...acc[file.category], fileContent];
                          }

                          return acc;
                      },
                      { ...this.defaultGroupedFiles },
                  )
                : this.defaultGroupedFiles;

            return {
                filesByGroup,
                loading,
                refetch,
            };
        }

        return {
            filesByGroup: this.defaultGroupedFiles,
            loading: false,
            refetch: this.refetchStub,
        };
    }

    private refetchStub(): Promise<void> {
        return Promise.resolve();
    }
}

export const FileGroupsConnected = graphql<ExternalProps>(WITH_PROJECT_FILES_QUERY, {
    options: ({ activityId }) => ({
        variables: {
            projectId: activityId,
        },
        fetchPolicy: 'no-cache',
    }),
    skip: ({ activityId }) => !activityId,
})(FileGroupsConnectedWrapper);
