// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-main-Options-_Options__root{display:flex;flex-direction:column;align-self:stretch;gap:8px;flex:1;min-height:0}.src-client-modules-common-components-main-Options-_Options__virtualizedList{overflow:visible !important}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/main/Options/Options.scss"],"names":[],"mappings":"AAAA,kEACE,YAAa,CACb,qBAAsB,CACtB,kBAAmB,CACnB,OAAQ,CACR,MAAO,CACP,YAAa,CACd,6EAGC,2BAA4B","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  align-self: stretch;\n  gap: 8px;\n  flex: 1;\n  min-height: 0;\n}\n\n.virtualizedList {\n  overflow: visible !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-components-main-Options-_Options__root",
	"virtualizedList": "src-client-modules-common-components-main-Options-_Options__virtualizedList"
};
export default ___CSS_LOADER_EXPORT___;
