import * as React from 'react';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';

import { StoreState } from '@store';
import { getSearchState } from '@store/search';

import { HighlightQuery, Theme } from './HighlightQuery';

interface Props extends Partial<MapProps> {
    text: string;
    theme?: Theme;
    multiline?: boolean;
    elRef?: (el: HTMLDivElement) => void;
}

interface MapProps {
    query: string;
}

@(connect(mapStateToProps) as any)
export class HighlightQueryContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { text, theme, query, multiline } = this.props;

        return <HighlightQuery text={text} theme={theme} query={query} multiline={multiline} elRef={this.elRef} />;
    }

    @autobind
    private elRef(el: HTMLDivElement): void {
        if (this.props.elRef) {
            this.props.elRef(el);
        }
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        query: getSearchState(state).searchQuery,
    };
}
