import * as React from 'react';
import classNames from 'classnames';

import { ColumnName, CustomColumnColor } from '@store/budgetExecution';

import { CellBackgroundColor } from '../../LayerManager';
import { getQaCellColor } from '../misc';

import * as style from './TextCell.scss';

interface Props {
    columnName: ColumnName;
    title: string;
    tooltip?: string;
    bgColor?: CellBackgroundColor | CustomColumnColor;
    valueTypeIsCurrency?: boolean;
    disabled?: boolean;
    onCopy: (event: React.ClipboardEvent<HTMLDivElement>) => void;
}

export const TextCell = ({
    columnName,
    title,
    tooltip,
    bgColor,
    valueTypeIsCurrency,
    disabled,
    onCopy,
}: Props): JSX.Element => {
    const valueInRubles = valueTypeIsCurrency ? parseFloat((tooltip || '0').replace(/ /g, '')) : null;

    return (
        <div
            title={tooltip}
            className={classNames(
                style.root,
                valueTypeIsCurrency && style.valueTypeIsCurrency,
                disabled && style.disabled,
            )}
            style={{ backgroundColor: bgColor }}
            {...{
                'qa-id': 'tableCell',
                'qa-index': columnName,
                'qa-disabled': disabled ? 'true' : 'false',
                ...getQaCellColor(bgColor),
            }}
            onCopy={onCopy}
        >
            <div
                className={style.title}
                {...{
                    'qa-id': 'tableCellContent',
                }}
            >
                {title || '–'}
            </div>

            {valueTypeIsCurrency && (
                <>
                    <div className={style.valueInRubles}>{valueInRubles}</div>

                    <div className={style.rubleSign}>₽</div>
                </>
            )}
        </div>
    );
};
