import * as React from 'react';

import { WithStore } from './WithStore';
import { ColumnsFiltersDropdownTemplate } from './ColumnsFiltersDropdownTemplate';

export interface Props {
    displayLabel: boolean;
}

export const ColumnsFiltersDropdownConnector: React.FC<Props> = ({ displayLabel }) => {
    return (
        <WithStore>
            {({ filtersNames, resetFilters }) => (
                <ColumnsFiltersDropdownTemplate
                    filtersNames={filtersNames}
                    displayLabel={displayLabel}
                    onResetFilters={resetFilters}
                />
            )}
        </WithStore>
    );
};
