import * as React from 'react';

import { useGetTagsDictionaryQuery, TagDictionary } from '@api';

import { OptionMultiple, OptionProps, Selector, SelectorProps } from '@common/components';

export type ITagSelectorProps<M extends OptionMultiple> = Omit<
    SelectorProps<M, OptionProps<TagDictionary>, TagDictionary, 'id', string, M extends true ? string[] : string>,
    'options' | 'optionId'
>;

export interface TagSelectorProps<M extends OptionMultiple> extends ITagSelectorProps<M> {}

export function TagSelector<M extends OptionMultiple>({ loading, ...props }: TagSelectorProps<M>) {
    const { data, isLoading } = useGetTagsDictionaryQuery();

    const options: OptionProps<TagDictionary>[] = React.useMemo(
        () =>
            data?.map((value) => ({
                value,
                title: value.title,
            })) || [],
        [data],
    );
    return <Selector {...props} loading={isLoading || loading} optionId="id" options={options} />;
}
