import * as React from 'react';
import { StateTransitionerChildrenProps as ChildrenProps, StateTransitioner } from 'sber-marketing-ui';

export function AnimatedInProgressIcon(): JSX.Element {
    return (
        <StateTransitioner nStates={2}>
            {(stateTransitionerProps) => <StateTransitionerChildren {...stateTransitionerProps} />}
        </StateTransitioner>
    );
}

/* tslint:disable:max-line-length */
function StateTransitionerChildren({ currentState }: ChildrenProps): JSX.Element {
    return (
        <svg
            {...{ 'qa-id': 'AnimatedInProgressIcon' }}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8ZM8 14.7C11.7003 14.7 14.7 11.7003 14.7 8C14.7 4.29969 11.7003 1.3 8 1.3C4.29969 1.3 1.3 4.29969 1.3 8C1.3 11.7003 4.29969 14.7 8 14.7Z"
                fill="#7E8681"
            />

            {currentState >= 0 && (
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.59844 7.9999C5.59844 7.39239 5.10595 6.8999 4.49844 6.8999C3.89092 6.8999 3.39844 7.39239 3.39844 7.9999C3.39844 8.60742 3.89092 9.0999 4.49844 9.0999C5.10595 9.0999 5.59844 8.60742 5.59844 7.9999Z"
                    fill="#7E8681"
                />
            )}

            {currentState >= 1 && (
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.09844 7.9999C9.09844 7.39239 8.60595 6.8999 7.99844 6.8999C7.39092 6.8999 6.89844 7.39239 6.89844 7.9999C6.89844 8.60742 7.39092 9.0999 7.99844 9.0999C8.60595 9.0999 9.09844 8.60742 9.09844 7.9999Z"
                    fill="#7E8681"
                />
            )}

            {currentState >= 2 && (
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.4984 6.8999C12.106 6.8999 12.5984 7.39239 12.5984 7.9999C12.5984 8.60742 12.106 9.0999 11.4984 9.0999C10.8909 9.0999 10.3984 8.60742 10.3984 7.9999C10.3984 7.39239 10.8909 6.8999 11.4984 6.8999Z"
                    fill="#7E8681"
                />
            )}
        </svg>
    );
}
/* tslint:enable:max-line-length */
