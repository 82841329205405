import { FileAsset } from '@store/commonTypes';

export const enum FileStatus {
    ReadyToUpload = 'ReadyToUpload',
    Uploading = 'Uploading',
    Uploaded = 'Uploaded',
    Deleted = 'Deleted',
}

export interface FileParams {
    asset: FileAsset;
    status: FileStatus;
}

export interface ParticipantParams {
    userId: number;
    canRemove: boolean;
}

export interface ActivityStageParams {
    id: string;
    title: string;
    subtitle: string;
}
