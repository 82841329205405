import * as React from 'react';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';

import { UserParams, OrganizationView as Organization } from 'sber-marketing-types/frontend';

import { FilterMenu, FilterItem, Filter, SelectionStatus } from 'sber-marketing-ui';
import { StoreState } from '@store';
import { getAllLoadedOrganizations } from '@store/organizations';

interface Props extends Partial<MapProps> {
    title: string;
    users: UserParams[];
    selectedItems: number[];
    onCheckItems: (checkedItems: React.ReactText[]) => void;
    onFilterChange?: () => void;
}

interface MapProps {
    organizations: Organization[];
}

@(connect(mapStateToProps, null, null, { forwardRef: true }) as any)
export class UsersFilter extends React.PureComponent<Props> implements Filter {
    private filterMenuRef: React.RefObject<FilterMenu> = React.createRef();

    public render(): JSX.Element {
        const { title, selectedItems } = this.props;

        return (
            <FilterMenu
                sortSelectedItemsToTop
                qaId="dashboardUserFilter"
                ref={this.filterMenuRef}
                filterTitle={title}
                items={this.formatItems()}
                checkedItemsIds={selectedItems}
                onItemSelection={this.onItemSelection}
            />
        );
    }

    public getSelectionStatus(): SelectionStatus {
        return this.filterMenuRef.current ? this.filterMenuRef.current.getSelectionStatus() : SelectionStatus.None;
    }

    public check() {
        this.filterMenuRef.current.check();
    }

    public uncheck() {
        this.filterMenuRef.current.uncheck();
    }

    @autobind
    private onItemSelection(checkedItems: React.ReactText[]) {
        if (this.props.onFilterChange) {
            this.props.onFilterChange();
        }

        this.props.onCheckItems(checkedItems);
    }

    private formatItems(): FilterItem[] {
        const { users, organizations } = this.props;

        return users.map((user) => {
            if (user) {
                return {
                    id: user.id,
                    title: `${user.secondName} ${user.firstName}`,
                    description:
                        organizations.find((organization) => organization.id === user.organizationId)?.name ||
                        'Организация не найдена',
                };
            }

            return {
                id: null,
                title: 'Не задан',
            };
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        organizations: getAllLoadedOrganizations(state),
    };
}
