import * as React from 'react';
import * as lodash from 'lodash';
import { FilterMenu as FilterMenuSelector, FilterMenuBorderType, Preloader } from 'sber-marketing-ui';
import { DictionaryType, PlainDictionary } from '@mrm/dictionary';
import { ColumnName, ColumnsNameWithCodes } from '@store/budgetExecution';
import { Filters, User } from './types';
import { DatesFormatter, Money, MoneyFormatter, Utils } from '@common/Utils';

import * as styles from './styles.scss';

interface Props {
    title: string;
    dictionaryType: DictionaryType;
    columnName: ColumnName;
    users: User[];
    dictionaries: Record<string, PlainDictionary>;
    filters: Filters;
    currencySearch: boolean;
    filterNamesAreNumeral: boolean;
    isLoading: boolean;
    isExpanded: boolean;
    selectedFilters: React.ReactText[];
    onFiltersChange: (selectedFiltersIds: React.ReactText[]) => void;
    onExpandChange: (isExpanded: boolean) => void;
}

export const ItemTemplate: React.FC<Props> = ({
    title,
    dictionaryType,
    columnName,
    users,
    dictionaries,
    filters,
    currencySearch,
    isLoading,
    isExpanded,
    selectedFilters,
    filterNamesAreNumeral,
    onFiltersChange,
    onExpandChange,
}) => {
    const filterNames = lodash.keys(filters);

    let sortedFilterNames = filterNamesAreNumeral ? sortStringsAsNumbers(filterNames) : filterNames.sort();

    const nullElement = lodash.find(
        sortedFilterNames,
        (item) => item === 'null' || item === '' || item === 'undefined',
    );

    if (nullElement !== undefined) {
        sortedFilterNames = lodash.without(sortedFilterNames, nullElement);

        sortedFilterNames.push(nullElement);
    }

    return (
        <div className={styles.root}>
            {isLoading && <Preloader />}

            <FilterMenuSelector
                filterTitle={title}
                items={sortedFilterNames
                    .map((item, index) => ({
                        id: item,
                        title: getItemTitle(item, currencySearch, dictionaryType, columnName, users, dictionaries),
                        order: filterNamesAreNumeral ? index : undefined,
                    }))
                    .filter((item) => !!item.title)}
                checkedItemsIds={selectedFilters}
                border={FilterMenuBorderType.ALL}
                onItemSelection={onFiltersChange}
                currencySearch={currencySearch}
                isExpanded={isExpanded}
                onExpandChange={onExpandChange}
                preserveAllSelectedState
            />
        </div>
    );
};

function sortStringsAsNumbers(filterNames: string[]): string[] {
    return filterNames.sort(
        (itemA: string, itemB: string) =>
            parseInt(itemA.replace(/\s/g, ''), 10) - parseInt(itemB.replace(/\s/g, ''), 10),
    );
}

function getItemTitle(
    item: string,
    currencySearch: boolean,
    dictionaryType: DictionaryType,
    columnName: ColumnName,
    users: User[],
    dictionaries: Record<string, PlainDictionary>,
): string {
    if (item === 'null' || item === '' || item === 'undefined') {
        return 'Значение отсутствует';
    }

    if (columnName === ColumnName.Responsible) {
        const userId = Number(item);
        const user = users.find((user) => user.id === userId);

        return formatUser(user);
    }

    if (columnName === ColumnName.StartDate || columnName === ColumnName.EndDate) {
        return DatesFormatter.ddMonthyyyy(item);
    }

    if (columnName === ColumnName.Author) {
        const user = users.find((user) => user.id === Number(item));

        return formatUser(user);
    }

    if (dictionaryType && !lodash.includes(ColumnsNameWithCodes, columnName)) {
        const currentDict = dictionaries[item];

        return Utils.getDictionaryValue(currentDict);
    }

    if (currencySearch) {
        return MoneyFormatter.toThousands(Money.fromStringCopecks(item), { hideCaption: true });
    }

    return item;
}

function formatUser(user: User): string {
    return user ? `${user.secondName} ${user.firstName}` : 'Пользователь не найден';
}
