import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';

import { StoreState } from '@store';
import { getLoginUser, User } from '../../../store/user';
import { loadUser } from '@store/user/actions';
import { AuthErrorPageContainer } from './AuthErrorPageContainer';
import { AuthErrorPageMappedActions, AuthErrorPageMappedState } from './types';

const mapStateToProps = (state: StoreState): AuthErrorPageMappedState => {
    const user = getLoginUser(state);
    return {
        userFirstName: get(user, 'attributes.firstName'),
        userSecondName: get(user, 'attributes.secondName'),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<StoreState>): AuthErrorPageMappedActions => ({
    loadUser: (user: User) => dispatch(loadUser(user)),
});

const withStore = connect(mapStateToProps, mapDispatchToProps);

/** Auth error page */
export const AuthErrorPageStore: ComponentType = withRouter(withStore(AuthErrorPageContainer));
