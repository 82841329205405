import * as React from 'react';
import { connect } from 'react-redux';
import * as lodash from 'lodash';
import * as moment from 'moment';
import { BudgetItem } from '@mrm/budget';
import { DictionaryType } from '@mrm/dictionary';

import { Tooltip, TooltipDirection } from './Tooltip';
import { StoreState } from '@store';
import { getBudgetItems } from '@store/budgetPlanning/selectors';

interface Props extends OwnProps, Partial<MapProps> {}

interface OwnProps {
    lineId: string;
    direction: TooltipDirection;
}

interface MapProps {
    budgetItems: BudgetItem[];
}

@(connect(mapStateToProps) as any)
export class TooltipContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { lineId, budgetItems } = this.props;

        const budgetItem = budgetItems.find((item) => item.id == lineId);
        const sameActivityBudgetItems = budgetItems.filter((item) => item.activity.id == budgetItem.activity.id);

        const activityType = this.getDictionaryNameByType(budgetItem, DictionaryType.ActivityType);
        const product = this.getDictionaryNameByType(budgetItem, DictionaryType.Product);
        const segment = this.getDictionaryNameByType(budgetItem, DictionaryType.Segment);

        const directions = lodash.uniq(
            sameActivityBudgetItems.map((item) => this.getDictionaryNameByType(item, DictionaryType.Direction)),
        );

        const budgetsByTools: { [toolName: string]: number } = sameActivityBudgetItems.reduce((acc, budgetItem) => {
            const toolName = this.getDictionaryNameByType(budgetItem, DictionaryType.Tool);
            const budgetItemPlan = this.getBudgetItemPlanSum(budgetItem);

            if (toolName) {
                if (acc[toolName] == undefined) {
                    acc[toolName] = 0;
                }

                acc[toolName] += budgetItemPlan;
            }

            return acc;
        }, {});

        const formatedBudgetsByTools = lodash
            .sortBy(
                lodash.map(budgetsByTools, (value, key) => ({ name: key, value })),
                (item) => item.value,
            )
            .reverse();

        return React.createElement(Tooltip, {
            activityName: budgetItem.activity.name,
            activityType,
            product,
            segment,
            directions,
            budgetsByTools: formatedBudgetsByTools,
            activityStart: budgetItem.realizationStart ? formatDate(new Date(budgetItem.realizationStart)) : null,
            activityEnd: budgetItem.realizationEnd ? formatDate(new Date(budgetItem.realizationEnd)) : null,
            direction: this.props.direction,
        });
    }

    private getDictionaryNameByType(budgetItem: BudgetItem, dictionaryType: DictionaryType): string {
        return budgetItem.dictionary && budgetItem.dictionary[dictionaryType]
            ? budgetItem.dictionary[dictionaryType].value
            : null;
    }

    private getBudgetItemPlanSum(budgetItem: BudgetItem): number {
        return lodash.sum(lodash.values(budgetItem.plannedFunds));
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        budgetItems: getBudgetItems(state),
    };
}

function formatDate(date: Date): string {
    return moment(date).format('DD.MM.YYYY');
}
