import { Success } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';

import { LoadingStatus } from '@store/commonTypes';

import {
    PersonalDashboardUserConfigState as State,
    PersonalDashboardUserConfig,
    UpdatePersonalDashboardUserConfig,
    UserRoleFilter,
} from './types';
import * as actions from './actions';

class Reducer {
    public static initialState(): State {
        return {
            loadingStatus: LoadingStatus.NOT_LOADED,
            fields: {
                userRoleFilter: UserRoleFilter.Participant,
            },
        };
    }

    public static loadUserConfig(
        state: State,
        payload: Success<PersonalDashboardUserConfig, PersonalDashboardUserConfig>,
    ): State {
        const {} = state;
        const { result } = payload;

        return {
            ...state,
            loadingStatus: LoadingStatus.LOADED,
            fields: {
                userRoleFilter: result.userRoleFilter,
            },
        };
    }

    public static updateUserConfig(state: State, payload: UpdatePersonalDashboardUserConfig): State {
        return {
            ...state,
            fields: {
                ...lodash.assignWith(lodash.cloneDeep(state.fields), payload.userConfig, (source, dest) => ({
                    ...source,
                    ...dest,
                })),
                userRoleFilter: payload.userConfig.userRoleFilter,
            },
        };
    }
}

export const personalDashboardUserConfigReducer = reducerWithInitialState(Reducer.initialState())
    .case(actions.loadPersonalDashboardUserConfig.done, Reducer.loadUserConfig)
    .case(actions.updatePersonalDashboardUserConfig.started, Reducer.updateUserConfig)
    .case(actions.resetPersonalDashboardUserConfig, Reducer.initialState);
