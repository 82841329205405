import * as React from 'react';

import { useStore, Period } from './useStore';
import { useMode } from '../../../hooks';

interface UseBehavior {
    (): Return;
}

interface Return {
    selectedFilterPeriod: Period;
    selectedFilterDate: string;
    onDateSelect: OnDateSelect;
}

interface OnDateSelect {
    (params: OnDateSelectParams): void;
}

interface OnDateSelectParams {
    date: string;
}

export const useBehavior: UseBehavior = () => {
    const { selectedFilterPeriod, selectedFilterDate, setSelectedDate } = useStore();
    const { mode } = useMode();

    const onDateSelect = React.useCallback(
        ({ date }) => {
            setSelectedDate({
                date,
                mode,
            });
        },
        [mode, setSelectedDate],
    );

    return {
        selectedFilterDate,
        selectedFilterPeriod,
        onDateSelect,
    };
};

export { Period };
