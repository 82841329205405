// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-components-TaskEstimator-_styles__root{margin-bottom:22px}.src-client-modules-activity-pages-task-components-TaskEstimator-_styles__header{margin-bottom:14px}.src-client-modules-activity-pages-task-components-TaskEstimator-_styles__title{font-weight:600;font-size:14px;letter-spacing:0.15px;color:#000000}.src-client-modules-activity-pages-task-components-TaskEstimator-_styles__subtitle{margin-top:5px;font-weight:400;font-size:12px;line-height:16px;letter-spacing:0.15px;color:#7E8681}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/components/TaskEstimator/styles.scss"],"names":[],"mappings":"AAAA,+EACI,kBAAmB,CACtB,iFAGG,kBAAmB,CACtB,gFAKG,eAAgB,CAChB,cAAe,CACf,qBAAsB,CACtB,aAAc,CACjB,mFAGG,cAAe,CAEf,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,qBAAsB,CACtB,aAAc","sourcesContent":[".root {\n    margin-bottom: 22px;\n}\n\n.header {\n    margin-bottom: 14px;\n}\n\n.content {}\n\n.title {\n    font-weight: 600;\n    font-size: 14px;\n    letter-spacing: 0.15px;\n    color: #000000;\n}\n\n.subtitle {\n    margin-top: 5px;\n\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 16px;\n    letter-spacing: 0.15px;\n    color: #7E8681;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-components-TaskEstimator-_styles__root",
	"header": "src-client-modules-activity-pages-task-components-TaskEstimator-_styles__header",
	"title": "src-client-modules-activity-pages-task-components-TaskEstimator-_styles__title",
	"subtitle": "src-client-modules-activity-pages-task-components-TaskEstimator-_styles__subtitle"
};
export default ___CSS_LOADER_EXPORT___;
