import * as React from 'react';
import classNames from 'classnames';

import { WithTooltip } from 'sber-marketing-ui';

import { SkeletonText } from '@common/components';

import { useTaskTitle } from '@modules/task/hooks';

import * as styles from './TaskTitle.scss';

export interface TaskTitleProps {
    taskId: string;
    className?: string;
}

export const TaskTitle: React.FC<TaskTitleProps> = ({ taskId, className, children }) => {
    const { isLoading, taskTitle } = useTaskTitle(taskId);

    const content = isLoading ? (
        <SkeletonText width={180} />
    ) : (
        <>
            {taskTitle}
            {children}
        </>
    );

    return (
        <WithTooltip className={classNames(styles.root, className)} hidden={isLoading} content={taskTitle || ''}>
            <span data-qa-id="TaskTitle">{content}</span>
        </WithTooltip>
    );
};
