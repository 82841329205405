import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import { getTagsPageState, getYearsAsItems, getYearsFilterAsItems, setYearsFilter } from '@store/tagsPage';

import { FilterItem, FilterTitle, AddItemButton } from '../common';

import * as styles from './YearsFilter.scss';

function useInteractivity() {
    const dispatch = useDispatch();

    const allItems = useSelector((state: StoreState) => getYearsAsItems(state));
    const items = useSelector((state: StoreState) => getYearsFilterAsItems(state));
    const selectedItems = useSelector((state: StoreState) => getTagsPageState(state).filters.years);

    function onAddItemButtonClick(year: number) {
        const years = [...(items.map((item) => item.id) as number[]), year];

        dispatch(setYearsFilter(years));
    }

    function onRemoveItemButtonClick(year: number) {
        const years = items
            .filter((yearsFilterItem) => yearsFilterItem.id !== year)
            .map((yearsFilterItem) => yearsFilterItem.id as number);

        dispatch(setYearsFilter(years));
    }

    return {
        allItems,
        items,
        selectedItems,
        onAddItemButtonClick,
        onRemoveItemButtonClick,
    };
}

export function YearsFilter(): JSX.Element {
    const { allItems, items, selectedItems, onAddItemButtonClick, onRemoveItemButtonClick } = useInteractivity();

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'tagsPageyearsFilter',
            }}
        >
            <div className={styles.title}>
                <FilterTitle title="Год" />
            </div>

            <div className={styles.items}>
                {items.map((item) => (
                    <FilterItem key={item.id} {...item} onRemoveButtonClick={onRemoveItemButtonClick} />
                ))}

                <AddItemButton
                    title="Добавить год"
                    items={allItems}
                    selectedItemId={selectedItems}
                    onItemSelect={onAddItemButtonClick}
                />
            </div>
        </div>
    );
}
