import { RoleId } from 'sber-marketing-types/backend';

import { useGetActivityQuery, useGetAuthUserQuery, useGetTaskQuery } from '@api';

export type TaskAccessRole = 'taskAuthor' | 'executor' | 'participant' | 'projectAuthor' | 'supervisor' | 'director';

export enum TaskAccessAction {
    close = 'close',
    reopen = 'reopen',
    remove = 'remove',
    changeExecutor = 'changeExecutor',
    changeDeadline = 'changeDeadline',
    changeStatus = 'changeStatus',
    changeTitle = 'changeTitle',
    changeDescription = 'changeDescription',
    changeStage = 'changeStage',
    addChannel = 'addChannel',
    addFile = 'addFile',
    removeFile = 'removeFile',
    editFile = 'editFile',
    addParticipant = 'addParticipant',
    removeParticipant = 'removeParticipant',
    removeOwnParticipant = 'removeOwnParticipant',
}

export interface TaskAccess {
    isLoading: boolean;
    access: boolean[];
}

export type TaskAccessCondition = boolean;
export type TaskAccessConditions = Record<TaskAccessRole, TaskAccessCondition>;

export const taskAccess: Record<TaskAccessAction, TaskAccessRole[]> = {
    close: ['taskAuthor', 'executor', 'director'],
    reopen: ['taskAuthor', 'executor', 'director'],
    remove: ['taskAuthor', 'director'],
    changeExecutor: ['taskAuthor', 'executor', 'director'],
    changeTitle: ['taskAuthor'],
    changeDescription: ['taskAuthor'],
    changeStage: ['taskAuthor'],
    changeStatus: ['taskAuthor'],
    changeDeadline: ['taskAuthor', 'executor', 'director'],
    addFile: ['taskAuthor'],
    removeFile: ['taskAuthor'],
    editFile: ['taskAuthor', 'executor', 'participant'],
    addChannel: ['taskAuthor', 'executor', 'participant', 'projectAuthor', 'supervisor', 'director'],
    addParticipant: ['taskAuthor', 'executor', 'participant', 'projectAuthor', 'supervisor', 'director'],
    removeParticipant: ['taskAuthor', 'executor', 'projectAuthor', 'supervisor', 'director'],
    removeOwnParticipant: ['taskAuthor', 'executor', 'participant', 'projectAuthor', 'supervisor', 'director'],
};

export function useTaskAccess(actions: TaskAccessAction[], taskId: string, activityId?: number): TaskAccess {
    const { isLoading: isAuthUserLoading, data: authUser } = useGetAuthUserQuery();
    const { isLoading: isTaskLoading, data: { task } = {} } = useGetTaskQuery({ id: taskId });
    const taskActivityId = activityId || task?.activityId;
    const { isLoading: isActivityLoading, data: activity } = useGetActivityQuery(
        { id: taskActivityId },
        { skip: !taskActivityId },
    );
    const isLoading = isAuthUserLoading || isTaskLoading || isActivityLoading;
    const access = actions.map(() => false);

    if (!isLoading) {
        const authUserId = authUser?.user.attributes.id;
        const authUserRoles = authUser?.user.attributes.roles;

        const conditions: TaskAccessConditions = {
            taskAuthor: task?.authorId === authUserId,
            executor: task?.executorId === authUserId,
            projectAuthor: activity?.authorId === authUserId,
            participant: Boolean(task?.participants?.find(({ userId }) => userId === authUserId)),
            director: Boolean(authUserRoles?.find(({ id }) => id === RoleId.Director)),
            supervisor: Boolean(authUserRoles?.find(({ id }) => id === RoleId.Supervisor)),
        };

        for (let i = 0; i < actions.length; i++) {
            for (const role of taskAccess[actions[i]]) {
                if (conditions[role]) {
                    access[i] = true;
                    break;
                }
            }
        }
    }

    return { isLoading, access };
}
