import * as React from 'react';
import classnames from 'classnames';
import { StaticSkeleton } from 'sber-marketing-ui';

import * as styles from './Preloaders.scss';

export function ApplyFiltersPreloader(): JSX.Element {
    return (
        <div className={styles.root}>
            <div className={classnames(styles.flex, styles.margin5)}>
                <div className={styles.applyFiltersTitle}>
                    <Skeleton width={styles.width37Abs} height={styles.height24} />

                    <div className={styles.paddingLeft8}>
                        <Skeleton width={styles.width169Abs} height={styles.height18} />
                    </div>
                </div>

                <Skeleton width={styles.width251Abs} height={styles.height24} />
            </div>

            <Skeleton width={styles.width175Abs} height={styles.height14} margin={styles.margin6} />
            <Skeleton width={styles.width260Abs} height={styles.height14} margin={styles.margin9} />
            <Skeleton width={styles.widthFull} height={styles.height24} />
        </div>
    );
}

export function FetchMorePreloader(): JSX.Element {
    return (
        <div className={classnames(styles.root, styles.flex)}>
            <FetchMorePreloaderHalf />

            <FetchMorePreloaderHalf />
        </div>
    );
}

function FetchMorePreloaderHalf(): JSX.Element {
    return (
        <div className={styles.fetchMorePreloaderHalf}>
            <div className={classnames(styles.flex, styles.margin4)}>
                <div>
                    <Skeleton width={styles.width50} height={styles.height14} margin={styles.margin4} />
                    <Skeleton width={styles.width21} height={styles.height18} margin={styles.margin12} />
                    <Skeleton width={styles.width90} height={styles.height14} margin={styles.margin4} />
                    <Skeleton width={styles.width100} height={styles.height18} margin={styles.margin12} />
                    <Skeleton width={styles.width114} height={styles.height14} margin={styles.margin4} />
                    <Skeleton width={styles.width21} height={styles.height18} margin={styles.margin12} />
                    <Skeleton width={styles.width41} height={styles.height14} margin={styles.margin4} />
                    <Skeleton width={styles.width36} height={styles.height18} margin={styles.margin12} />
                    <Skeleton width={styles.width36} height={styles.height14} margin={styles.margin4} />
                    <Skeleton width={styles.width80} height={styles.height18} margin={styles.margin12} />
                    <Skeleton width={styles.width77} height={styles.height14} margin={styles.margin4} />
                    <Skeleton width={styles.width130} height={styles.height18} margin={styles.margin10} />
                    <Skeleton width={styles.width77} height={styles.height14} />
                </div>

                <div>
                    <Skeleton width={styles.width100} height={styles.height14} margin={styles.margin4} />
                    <Skeleton width={styles.width21} height={styles.height18} margin={styles.margin12} />
                    <Skeleton width={styles.width80} height={styles.height14} margin={styles.margin4} />
                    <Skeleton width={styles.width50} height={styles.height18} margin={styles.margin12} />
                    <Skeleton width={styles.width100} height={styles.height14} margin={styles.margin4} />
                    <Skeleton width={styles.width54} height={styles.height18} />
                </div>

                <div>
                    <Skeleton width={styles.width100} height={styles.height14} margin={styles.margin4} />
                    <Skeleton width={styles.width124} height={styles.height18} margin={styles.margin12} />
                    <Skeleton width={styles.width100} height={styles.height14} margin={styles.margin4} />
                    <Skeleton width={styles.width124} height={styles.height18} />
                </div>
            </div>

            <Skeleton width={styles.widthFull} height={styles.height24} />
        </div>
    );
}

interface SkeletonProps {
    height: string;
    width: string;
    margin?: string;
}

function Skeleton({ height, width, margin }: SkeletonProps): JSX.Element {
    return <StaticSkeleton loadingClassName={classnames(height, width, margin)} />;
}
