import { actionCreatorFactory } from 'typescript-fsa';

import {
    LoadEntitiesPayload,
    SetLoadingStatusParams,
    SetStoreIdsParams,
    LoadActivityTypesParams,
    StoreTypes,
} from './types';

const actionCreator = actionCreatorFactory('ACTIVITY_TYPES');

const loadActivityTypesAsync = actionCreator.async<LoadActivityTypesParams, void, Error>('LOAD_ACTIVITY_TYPES_ASYNC');

const loadEntities = actionCreator<LoadEntitiesPayload>('LOAD_ENTITIES');
const setStoreIds = actionCreator<SetStoreIdsParams>('SET_STORE_IDS');
const setLoadingStatus = actionCreator<SetLoadingStatusParams>('SET_LOADING_STATUS');
const resetStore = actionCreator<StoreTypes>('RESET_STORE');
const incFetchersCount = actionCreator<StoreTypes>('INC_FETCHERS_COUNT');
const decFetchersCount = actionCreator<StoreTypes>('DEC_FETCHERS_COUNT');

export {
    loadActivityTypesAsync,
    loadEntities,
    setStoreIds,
    setLoadingStatus,
    resetStore,
    incFetchersCount,
    decFetchersCount,
};
