import { useGetTaskQuery } from '@api';

export interface UseTaskTitle {
    taskTitle: string | undefined;
    isLoading: boolean;
}

export function useTaskTitle(taskId: string): UseTaskTitle {
    const { isLoading, data } = useGetTaskQuery({ id: taskId });

    return {
        taskTitle: data?.task.title,
        isLoading,
    };
}
