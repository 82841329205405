import * as React from 'react';

import { useGetActivityQuery } from '@api';

import { FormInput, FormSelector, FormToggle, Scrollbar, ScrollbarProps } from '@common/components';
import { DictionaryFormSelector } from '@modules/dictionary/components';
import { BudgetsFormSelector } from '@modules/budget/components';
import { mediaRequestOptions } from '@modules/task/constants';

export interface TaskExecutorFormProps extends ScrollbarProps {
    activityId: number;
}

export function TaskExecutorForm({ activityId, loading, ...props }: TaskExecutorFormProps) {
    const { isLoading: isActivityLoading, data: activity } = useGetActivityQuery({ id: activityId });
    const [isMedia, setMedia] = React.useState(false);
    const [isHasProtocol, setHasProtocol] = React.useState(false);

    const protocolFields = isHasProtocol && (
        <>
            <FormInput maxLength={200} name="naming" required label="Укажите нейминг" />
            <FormSelector
                name="project"
                placement="top"
                required
                exact
                arrow
                options={[
                    {
                        title: 'КРБ',
                        value: 'КРБ',
                    },
                    {
                        title: 'ККБ',
                        value: 'ККБ',
                    },
                    {
                        title: 'Распоряжение',
                        value: 'Распоряжение',
                    },
                ]}
                label="РК на основании"
            />
        </>
    );

    const mediaFields = isMedia && (
        <>
            <FormSelector
                name="mediaRequest"
                autoPlace="vertical"
                required
                exact
                arrow
                options={mediaRequestOptions}
                label="Если креатив по лоту, то выберите лот"
            />
            <FormToggle name="hasTitle" checked={isHasProtocol} onToggle={setHasProtocol}>
                У креатива есть согласованный в заявке/протоколе нейминг
            </FormToggle>
            {protocolFields}
        </>
    );

    return (
        <Scrollbar loading={isActivityLoading || loading} gap={12} vertical {...props}>
            <DictionaryFormSelector
                label="Подразделение-заказчик (МВЗ)"
                types={['division' as any]}
                name="clientDivisionId"
                exact
                searchable
                required
                organizationId={activity?.organizationId}
                arrow
                selectOnFocus
            />
            <FormInput maxLength={200} name="clientName" required label="ФИО представителя заказчика (МВЗ)" />
            <BudgetsFormSelector
                labelRequired
                rules={{ required: (value) => value !== undefined }}
                searchable
                organizationId={activity?.organizationId}
                name="budgetItemId"
                optionsHeight={100}
                label="ID-источник из ID Исполнения"
                selectOnFocus
                arrow
                exact
            />
            <DictionaryFormSelector
                exact
                searchable
                label="Продукт"
                name="productId"
                types={['product' as any]}
                optionsHeight={100}
                autoPlace="vertical"
                selectOnFocus
                organizationId={activity?.organizationId}
                required
                arrow
            />
            <DictionaryFormSelector
                exact
                searchable
                label="Сегмент"
                name="segmentId"
                types={['segment' as any]}
                optionsHeight={100}
                autoPlace="vertical"
                selectOnFocus
                organizationId={activity?.organizationId}
                required
                arrow
            />
            <FormInput
                pattern="^[а-я]+-[а-я]+$"
                patternMessage="Неверный формат, пример: март-май"
                placeholder="март-май"
                name="period"
                required
                label="Период РК"
            />
            <FormToggle name="hasLot" checked={isMedia} onToggle={setMedia}>
                Компания относится к направлению медиа/диджитал
            </FormToggle>
            {mediaFields}
        </Scrollbar>
    );
}
