import * as React from 'react';
import autobind from 'autobind-decorator';

import { SubscribeButton } from './SubscribeButton';

interface Props {
    className?: string;
    isUserSubscribed?: boolean;
    subscribeIsDisabled?: boolean;
    onClick?: (isUserSubscribed: boolean) => any;
}

interface State {
    isHovered: boolean;
}

export class SubscribeButtonContainer extends React.Component<Props, State> {
    public state: State = {
        isHovered: false,
    };

    public render(): JSX.Element {
        const { isUserSubscribed, className, subscribeIsDisabled } = this.props;
        const { isHovered } = this.state;

        return (
            <SubscribeButton
                isDisabled={subscribeIsDisabled}
                isHovered={isHovered}
                isUserSubscribed={isUserSubscribed}
                className={className}
                onClick={this.onClick}
                onMouseOver={this.onMouseOver}
                onMouseLeave={this.onMouseLeave}
            />
        );
    }

    @autobind
    public onClick(): void {
        if (this.props.onClick) {
            this.props.onClick(this.props.isUserSubscribed);
        }
    }

    @autobind
    public onMouseOver(): void {
        this.setState({
            isHovered: true,
        });
    }

    @autobind
    public onMouseLeave(): void {
        this.setState({
            isHovered: false,
        });
    }
}
