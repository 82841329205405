import * as React from 'react';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';

import { StoreState } from '@store';
import {
    FormData,
    updateActivityForm,
    updateBudgetForm,
    setActivityAlreadyExists,
    makeActivityBudgetParams,
    getPlannedBudgetEditPageState,
} from '@store/plannedBudgetEdit';

import { ActivityAlreadyExistsCaption } from './ActivityAlreadyExistsCaption';

interface ChildrenProps {
    nameInputValue: string;
    isNameInputFocused: boolean;
    setActivityIsAlreadyExists(activity: { id: string; name: string }): void;
}

interface Props extends Partial<MapProps & DispatchProps> {
    children: (props: ChildrenProps) => JSX.Element;
}

interface MapProps {
    nameInputValue: string;
    isNameInputFocused: boolean;
    activityForm: FormData;
    budgetItemForms: FormData[];
}

interface DispatchProps {
    updateActivityForm: (formData: Partial<FormData>) => void;
    updateBudgetItemForm: (formData: FormData) => void;
    setActivityAlreadyExists(activityAlreadyExists: boolean): void;
}

interface State {
    nameInputValueForQuery: string;
}

const NAME_CHANGE_DEBOUNCE_VALUE = 300;

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class ActivitySuggestWithStore extends React.PureComponent<Props, State> {
    private stateUpdateTimeout: NodeJS.Timeout;

    public constructor(props: Props) {
        super(props);

        this.stateUpdateTimeout = null;

        this.state = {
            nameInputValueForQuery: props.nameInputValue,
        };
    }

    public componentDidUpdate() {
        if (this.state.nameInputValueForQuery !== this.props.nameInputValue) {
            this.initStateUpdateTimeout();
        }
    }

    public render(): JSX.Element {
        const { children, isNameInputFocused } = this.props;
        const { nameInputValueForQuery } = this.state;

        return children({
            nameInputValue: nameInputValueForQuery,
            isNameInputFocused,
            setActivityIsAlreadyExists: this.setActivityIsAlreadyExists,
        });
    }

    private initStateUpdateTimeout(): void {
        if (this.stateUpdateTimeout) {
            clearTimeout(this.stateUpdateTimeout);
        }

        this.stateUpdateTimeout = setTimeout(this.updateState, NAME_CHANGE_DEBOUNCE_VALUE);
    }

    @autobind
    private updateState(): void {
        this.stateUpdateTimeout = null;

        this.setState({
            nameInputValueForQuery: this.props.nameInputValue,
        });
    }

    @autobind
    private setActivityIsAlreadyExists(activity: { id: string; name: string }): void {
        const { activityForm, budgetItemForms } = this.props;

        this.props.updateActivityForm({
            ...activityForm,
            id: activity.id,
            fields: activityForm.fields.map((field) => {
                const res = { ...field };

                if (field.name === 'name') {
                    res.caption = <ActivityAlreadyExistsCaption />;
                    res.value = activity.name;
                }

                return res;
            }),
        });

        budgetItemForms.forEach((budgetItemForm) =>
            this.props.updateBudgetItemForm({
                ...budgetItemForm,
                fields: budgetItemForm.fields.map((field) => ({
                    ...field,
                    value: field.name === 'activityBudget' ? activity.name : field.value,
                })),
            }),
        );

        this.props.setActivityAlreadyExists(true);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { name } = makeActivityBudgetParams(state);
    const { isNameInputFocused, activityForm, budgetItemForms } = getPlannedBudgetEditPageState(state);

    return {
        nameInputValue: name,
        isNameInputFocused,
        activityForm,
        budgetItemForms,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        updateActivityForm: (formData: Partial<FormData>) => dispatch(updateActivityForm(formData)),
        updateBudgetItemForm: (formData: FormData) => dispatch(updateBudgetForm(formData)),
        setActivityAlreadyExists: (activityAlreadyExists: boolean) =>
            dispatch(setActivityAlreadyExists(activityAlreadyExists)),
    };
}
