// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Screensaver-_styles__root{width:100%;height:100%;position:relative;background-color:#F8F8F8;border-radius:10px;background-repeat:no-repeat;background-size:auto 80%;background-position:bottom right;background-image:url(\"/static/images/porter.svg\")}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/CreationForm/GeneratedTextEditor/Screensaver/styles.scss"],"names":[],"mappings":"AAAA,wIACI,UAAW,CACX,WAAY,CACZ,iBAAkB,CAElB,wBAAyB,CACzB,kBAAmB,CAEnB,2BAA4B,CAC5B,wBAAyB,CACzB,gCAAiC,CACjC,iDAAkD","sourcesContent":[".root {\n    width: 100%;\n    height: 100%;\n    position: relative;\n\n    background-color: #F8F8F8;\n    border-radius: 10px;\n\n    background-repeat: no-repeat;\n    background-size: auto 80%;\n    background-position: bottom right;\n    background-image: url(\"/static/images/porter.svg\");\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-GeneratedTextEditor-Screensaver-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
