import * as React from 'react';
import { get } from 'lodash';
import { graphql, DataProps } from 'react-apollo';

import { GraphqlFilterValues } from '@store/budgetCorrections/filtersAndExport';

import { GET_FILTERS_VALUES_QUERY } from './queries';

export type QueryProps = DataProps<{
    status: {
        values: string[];
    };
    serialNumbers: {
        values: string[];
    };
    directions: {
        values: string[];
    };
    types: {
        values: string[];
    };
    activityNames: {
        values: string[];
    };
    budgetItemNames: {
        values: string[];
    };
    blocks: {
        values: string[];
    };
    planIds: {
        values: string[];
    };
    author: {
        values: string[];
    };
    approver: {
        values: string[];
    };
    activityTypes: {
        values: string[];
    };
    tools: {
        values: string[];
    };
    executionIds: {
        values: number[];
    };
    divisionIds: {
        values: string[];
    };
    costCenterIds: {
        values: string[];
    };
}>;

interface ChildrenProps {
    loading: boolean;
    filtersValues: GraphqlFilterValues;
}

interface Props extends QueryProps, ExternalProps {}

interface ExternalProps {
    budgetId: string;
    skip: boolean;
    children: (props: ChildrenProps) => JSX.Element;
}

class Filters extends React.Component<Props> {
    public componentDidUpdate(prevProps: Props): void {
        if (this.props.budgetId !== prevProps.budgetId) {
            this.props.data.refetch();
        }
    }

    public render() {
        const { data } = this.props;

        return this.props.children({
            loading: (data && data.loading) || false,
            filtersValues: this.getFiltersValues(),
        });
    }

    // tslint:disable-next-line:cyclomatic-complexity
    private getFiltersValues() {
        return {
            status: this.getValue('status.values'),
            directions: this.getValue('directions.values'),
            types: this.getValue('types.values'),
            activityNames: this.getValue('activityNames.values'),
            budgetItemNames: this.getValue('budgetItemNames.values'),
            approver: this.getValue('approver.values').map((value) => JSON.parse(value)),
            author: this.getValue('author.values').map((value) => JSON.parse(value)),
            blocks: this.getValue('blocks.values'),
            activityTypes: this.getValue('activityTypes.values'),
            tools: this.getValue('tools.values'),
            executionIds: this.getValue('executionIds.values'),
            serialNumbers: this.getValue('serialNumbers.values'),
            regionalities: this.getValue('regionalities.values'),
            territories: this.getValue('territories.values'),
            divisionIds: this.getValue('divisionIds.values'),
            costCenterIds: this.getValue('costCenterIds.values'),
        };
    }

    private getValue(target: string): any[] {
        return get(this.props?.data, target) || [];
    }
}

export const FiltersConnected = graphql<ExternalProps>(GET_FILTERS_VALUES_QUERY, {
    skip: (props) => props.skip,
    options: (props) => ({
        variables: {
            filter: {
                budgetId: props.budgetId,
            },
        },
        fetchPolicy: 'no-cache',
    }),
})(Filters);
