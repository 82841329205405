import * as lodash from 'lodash';

import type { AutopilotDOOHMediaplanCalculation } from 'sber-marketing-types/backend';

import { store } from '@store';
import { setManualMediaplanSideQuantityValues } from '@store/autopilotDOOH/actions';
import { Saver } from '../../../modules';

export const enum ColumnHeaderType {
    CheckboxWithSorting = 'checkboxWithSorting',
    TextWithSorting = 'textWithSorting',
    Text = 'text',
}

export const enum LineType {
    Line = 'line',
    Total = 'total',
}

export const enum ColumnName {
    Checkbox = 'checkbox',
    City = 'city',
    Format = 'format',
    Chrono = 'chrono',
    MaxSideQuantity = 'maxSideQuantity',
    TargetSideQuantity = 'targetSideQuantity',
    MaxReach20Plus = 'maxReach20Plus',
    Budget = 'budget',
    Cpt = 'cpt',
}

export const enum CellType {
    Text = 'text',
    Input = 'input',
    CancelableInputCell = 'cancelableInputCell',
    FundsInput = 'fundsInput',
    Checkbox = 'checkbox',
}

export interface AccessorParams {
    id: string;
    line: AutopilotDOOHMediaplanCalculation;
    allLines: AutopilotDOOHMediaplanCalculation[];
    selectedFormatTimings: Record<string, number>;
    mediaplanLineBudgetsByLineId: Record<string, number>;
    mediaplanSideQuantityByLineId: Record<string, number>;
    manualMediaplanSideQuantityValues: Record<string, number>;
}

export type Accessor = (params: AccessorParams) => any;
export type TitleAccessor = (params: AccessorParams) => React.ReactText;
export type ValueSetter = (params: AccessorParams, value: any) => Promise<void>;
export type ItemsAccessor = (params: AccessorParams) => any[];
export type DescriptionAccessor = (params: AccessorParams) => string;
export type CustomStyleAccessor = (params: AccessorParams) => React.CSSProperties;

export interface ColumnParams {
    title: string | [string, string];
    headerType: ColumnHeaderType;
    type: Record<LineType, CellType>;
    defaultWidth: number;
    disableWidthChange?: boolean;
    readOnly?: Partial<Record<LineType, boolean>>;
    customStyle?: Partial<Record<LineType, CustomStyleAccessor>>;
    linkedColumns?: Partial<Record<LineType, ColumnName[]>>;
    getTitle?: Partial<Record<LineType, TitleAccessor>>;
    getValue: Record<LineType, Accessor>;
    setValue?: Partial<Record<LineType, ValueSetter>>;
    getItems?: Partial<Record<LineType, ItemsAccessor>>;
    getDescription?: Partial<Record<LineType, DescriptionAccessor>>;
}

export interface ColumnsConfigParams {}

export const MakeColumnsConfig: (columnsConfigParams: ColumnsConfigParams) => { [columnName: string]: ColumnParams } = (
    columnsConfigParams: ColumnsConfigParams,
) => ({
    [ColumnName.Checkbox]: {
        title: '',
        headerType: ColumnHeaderType.CheckboxWithSorting,
        type: {
            [LineType.Line]: CellType.Checkbox,
            [LineType.Total]: null,
        },
        defaultWidth: 50,
        disableWidthChange: true,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => null,
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.City]: {
        title: 'Город',
        headerType: ColumnHeaderType.TextWithSorting,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: CellType.Text,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        customStyle: {
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.line.region.name,
            [LineType.Total]: (params: AccessorParams) => 'Итого',
        },
    },
    [ColumnName.Format]: {
        title: 'Формат',
        headerType: ColumnHeaderType.TextWithSorting,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: CellType.Text,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        customStyle: {
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.line.format.abbreviation,
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Chrono]: {
        title: 'Хронометраж в блоке',
        headerType: ColumnHeaderType.TextWithSorting,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: CellType.Text,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        customStyle: {
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => {
                const formatTiming = params.selectedFormatTimings[params.line.format.abbreviation] || null;

                return formatTiming ? `${formatTiming} сек` : null;
            },
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.MaxSideQuantity]: {
        title: 'Кол-во сторон max',
        headerType: ColumnHeaderType.TextWithSorting,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: CellType.Text,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        customStyle: {
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.line.side.maxSideQuantity,
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.TargetSideQuantity]: {
        title: 'Кол-во сторон целевое',
        headerType: ColumnHeaderType.TextWithSorting,
        type: {
            [LineType.Line]: CellType.CancelableInputCell,
            [LineType.Total]: CellType.Text,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Total]: true,
        },
        customStyle: {
            [LineType.Line]: (params: AccessorParams) => {
                return params.manualMediaplanSideQuantityValues[params.id] !== undefined
                    ? { backgroundColor: '#f4f9fc' }
                    : null;
            },
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.mediaplanSideQuantityByLineId[params.id] || 0,
            [LineType.Total]: (params: AccessorParams) =>
                lodash.sum(lodash.values(params.mediaplanSideQuantityByLineId)),
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: string) => {
                const updatedManualMediaplanSideQuantityValues = lodash.clone(params.manualMediaplanSideQuantityValues);

                updatedManualMediaplanSideQuantityValues[params.id] = parseInt(value, 10) || 0;

                store.dispatch(setManualMediaplanSideQuantityValues(updatedManualMediaplanSideQuantityValues));

                await Saver.getInstance().saveBriefForm();
            },
        },
    },
    [ColumnName.MaxReach20Plus]: {
        title: 'Reach 20+ Monthly max, %',
        headerType: ColumnHeaderType.TextWithSorting,
        type: {
            [LineType.Line]: CellType.Input,
            [LineType.Total]: CellType.Input,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        customStyle: {
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.line.side.maxReach20Plus,
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Budget]: {
        title: 'Стоимость размещения до НДС',
        headerType: ColumnHeaderType.TextWithSorting,
        type: {
            [LineType.Line]: CellType.FundsInput,
            [LineType.Total]: CellType.FundsInput,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        customStyle: {
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.mediaplanLineBudgetsByLineId[params.id],
            [LineType.Total]: (params: AccessorParams) =>
                lodash.sumBy(params.allLines, (item) => params.mediaplanLineBudgetsByLineId[item.rowId]),
        },
    },
    [ColumnName.Cpt]: {
        title: 'Среднее СРТ',
        headerType: ColumnHeaderType.TextWithSorting,
        type: {
            [LineType.Line]: CellType.FundsInput,
            [LineType.Total]: CellType.FundsInput,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        customStyle: {
            [LineType.Total]: (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => roundNumber(params.line.side.cpt),
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
});

export const MakeTableColumns: (columnsConfigParams: ColumnsConfigParams) => string[] = () => [
    ColumnName.Format,
    ColumnName.Chrono,
    ColumnName.MaxSideQuantity,
    ColumnName.TargetSideQuantity,
    ColumnName.MaxReach20Plus,
    ColumnName.Budget,
    ColumnName.Cpt,
];

export const leftFixedColumns: ColumnName[] = [ColumnName.Checkbox, ColumnName.City];

export const rightFixedColumns: ColumnName[] = [];

function roundNumber(value: number, digitsAfterComma = 2): string {
    if (value === undefined) {
        return null;
    }

    const formatedValue = value.toFixed(digitsAfterComma);

    const [decimalPart, fractionPart] = formatedValue.split('.');

    return `${decimalPart}${fractionPart ? `.${fractionPart}` : ''}`;
}
