import * as React from 'react';

import { DataUpdateStrategy, InstanceActionPayload, DataUpdateActionPayload } from '@store/tagsEditor';

interface DescriptorContextProps {
    id: string;
    dataUpdateStrategy: DataUpdateStrategy;
}

export const DescriptorContext = React.createContext<DescriptorContextProps>(null);
export function useDescriptor() {
    const { id, dataUpdateStrategy } = React.useContext(DescriptorContext);

    function makeActionParams<P>(payload: P): InstanceActionPayload<P> {
        return { id, payload };
    }

    function makeDataUpdateActionParams<P>(payload: P): DataUpdateActionPayload<P> {
        return { id, dataUpdateStrategy, payload };
    }

    return {
        id,
        dataUpdateStrategy,
        makeActionParams,
        makeDataUpdateActionParams,
    };
}
