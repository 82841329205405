import * as React from 'react';

import { getMonthName, orderOfMonth } from './utils';
import { Table, FundRow } from './Table';

import { Funds } from '@mrm/budget';

interface Props {
    plannedFunds: Funds;
}

export class TableContainer extends React.Component<Props> {
    public render() {
        return React.createElement(Table, {
            fundsRows: this.buildFoundsRows(),
        });
    }

    private buildFoundsRows(): FundRow[] {
        const { plannedFunds } = this.props;

        return orderOfMonth.map((month) => ({
            name: getMonthName(month),
            plan: plannedFunds[month] || 0,
        }));
    }
}
