import * as React from 'react';

import { Header } from './Header';
import { Content } from './Content';

import * as styles from './styles.scss';

interface Props {
    title: string;
    items: { id: string; value: string; selected: boolean; disabled: boolean }[];
    maxValueLength: number;
    onItemReload: (itemId: string) => void;
    onItemSelect: (params: { id: string; value: boolean }) => void;
    onItemChange: (params: { id: string; value: string }) => void;
}

export const List: React.FC<Props> = ({ title, items, maxValueLength, onItemSelect, onItemChange, onItemReload }) => {
    return (
        <div className={styles.root}>
            <Header title={title} count={items?.length || 0} />
            <Content
                items={items}
                maxValueLength={maxValueLength}
                onItemSelect={onItemSelect}
                onItemChange={onItemChange}
                onItemReload={onItemReload}
            />
        </div>
    );
};
