import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Success } from 'typescript-fsa';
import { cloneDeep, orderBy } from 'lodash';
import { PlainDictionary } from '@mrm/dictionary';

import { LoadingStatus } from '@store/commonTypes';

import * as actions from './actions/sync';
import * as asyncActions from './actions/async';
import {
    ActivityStageState as State,
    LoadStagesContentParams,
    EditStageParams,
    EntitiesStore,
    FillStagesParams,
    Stage,
    StagesStore,
    TaskTemplatesStore,
} from './types';

class Reducer {
    public static makeInitialState(): State {
        return {
            isStagesEditorPopupVisible: false,
            stageRequestInProgress: false,
            loadingStatus: LoadingStatus.NOT_LOADED,
            stageTemplates: Reducer.makeEmptyEntitiesStore<PlainDictionary>(),
            taskTemplates: Reducer.makeEmptyTaskTemplatesStore(),
            existingStages: Reducer.makeEmptyStagesStore(),
            pendingStages: Reducer.makeEmptyStagesStore(),
        };
    }

    public static loadTasksTemplatesDone(state: State, payload: Success<null, PlainDictionary[]>): State {
        const { result } = payload;

        return {
            ...state,
            taskTemplates: Reducer.fillTaskTemplatesStore(result),
        };
    }

    public static setStagesEditorPopupVisibility(state: State, isStagesEditorPopupVisible: boolean): State {
        return {
            ...state,
            isStagesEditorPopupVisible,
        };
    }

    public static setLoadingStatus(state: State, loadingStatus: LoadingStatus): State {
        return { ...state, loadingStatus };
    }

    public static loadStagesContent(state: State, payload: LoadStagesContentParams): State {
        const { stageTemplates, stages, selectedTemplateId } = payload;

        return {
            ...state,
            stageTemplates: Reducer.fillEntitiesStore(stageTemplates),
            existingStages: Reducer.fillStagesStore(cloneDeep(stages), selectedTemplateId),
            pendingStages: Reducer.fillStagesStore(stages, selectedTemplateId),
        };
    }

    public static setStageStartDate(state: State, payload: EditStageParams): State {
        const { id: stageId, start } = payload;
        const { pendingStages } = state;

        const stages = pendingStages.entities.map((stage) => {
            if (stage.id === stageId) {
                return {
                    ...stage,
                    start,
                    end: start && stage.end < start ? null : stage.end,
                };
            }

            return stage;
        });

        return {
            ...state,
            pendingStages: Reducer.fillStagesStore(stages, pendingStages.selectedTemplateId),
        };
    }

    public static setStageEndDate(state: State, payload: EditStageParams): State {
        const { id: stageId, end } = payload;
        const { pendingStages } = state;

        const stages = pendingStages.entities.map((stage) => {
            if (stage.id === stageId) {
                return {
                    ...stage,
                    end,
                    start: end && stage.start > end ? null : stage.start,
                };
            }

            return stage;
        });

        return {
            ...state,
            pendingStages: Reducer.fillStagesStore(stages, pendingStages.selectedTemplateId),
        };
    }

    public static setStageActiveStatus(state: State, payload: EditStageParams): State {
        const { id: stageId, active } = payload;
        const { pendingStages } = state;

        const stages = pendingStages.entities.map((stage) => {
            if (stage.id === stageId) {
                return { ...stage, active };
            }

            return stage;
        });

        return {
            ...state,
            pendingStages: Reducer.fillStagesStore(stages, pendingStages.selectedTemplateId),
        };
    }

    public static fillPendingStages(state: State, payload: FillStagesParams): State {
        const { selectedTemplateId, stages } = payload;

        return {
            ...state,
            pendingStages: Reducer.fillStagesStore(stages, selectedTemplateId),
        };
    }

    public static fillExistingAndPendingStages(state: State, payload: FillStagesParams): State {
        const { selectedTemplateId, stages } = payload;

        return {
            ...state,
            pendingStages: Reducer.fillStagesStore(stages, selectedTemplateId),
            existingStages: Reducer.fillStagesStore(cloneDeep(stages), selectedTemplateId),
        };
    }

    public static resetStagesEditorPopup(state: State): State {
        return {
            isStagesEditorPopupVisible: false,
            stageRequestInProgress: false,
            loadingStatus: LoadingStatus.LOADED,
            stageTemplates: state.stageTemplates,
            existingStages: state.existingStages,
            pendingStages: cloneDeep(state.existingStages),
            taskTemplates: state.taskTemplates,
        };
    }

    public static setStateRequestInProgress(state: State, stageRequestInProgress: boolean): State {
        return { ...state, stageRequestInProgress };
    }

    private static makeEmptyStagesStore(): StagesStore {
        return {
            ...Reducer.makeEmptyEntitiesStore(),
            selectedTemplateId: null,
        };
    }

    private static makeEmptyTaskTemplatesStore(): TaskTemplatesStore {
        return {
            ...Reducer.makeEmptyEntitiesStore(),
            byName: {},
        };
    }

    private static makeEmptyEntitiesStore<T>(): EntitiesStore<T> {
        return {
            entities: [],
            byId: {},
        };
    }

    private static fillStagesStore(stages: Stage[], selectedTemplateId: string): StagesStore {
        const sortedStages = orderBy(stages, 'weight', ['desc']);

        return {
            ...Reducer.fillEntitiesStore(sortedStages),
            selectedTemplateId,
        };
    }

    private static fillTaskTemplatesStore(templates: PlainDictionary[]): TaskTemplatesStore {
        const byName = templates.reduce(
            (acc, template) => ({
                ...acc,
                [template.value]: template,
            }),
            {},
        );

        return {
            ...Reducer.fillEntitiesStore(templates),
            byName,
        };
    }

    private static fillEntitiesStore<T extends { id: string }>(entities: T[]): EntitiesStore<T> {
        const byId = entities.reduce(
            (acc, item) => ({
                ...acc,
                [item.id]: item,
            }),
            {},
        );

        return { entities, byId };
    }
}

export const activityStagesReducer = reducerWithInitialState(Reducer.makeInitialState())
    // .cases([
    //     asyncActions.loadStagesContent.started,
    //     asyncActions.saveActivityStages.started,
    //     asyncActions.setStageStatus.started
    // ], Reducer.setRequestInProgress)
    // .cases([
    //     asyncActions.loadStagesContent.done,
    //     asyncActions.saveActivityStages.done,
    //     asyncActions.setStageStatus.done
    // ], Reducer.unsetRequestInProgress)
    .case(asyncActions.loadTaskTemaplates.done, Reducer.loadTasksTemplatesDone)
    .case(actions.setLoadingStatus, Reducer.setLoadingStatus)
    .case(actions.setStagesEditorPopupVisibility, Reducer.setStagesEditorPopupVisibility)
    .case(actions.loadStagesContent, Reducer.loadStagesContent)
    .case(actions.setStageStartDate, Reducer.setStageStartDate)
    .case(actions.setStageEndDate, Reducer.setStageEndDate)
    .case(actions.setStageActiveStatus, Reducer.setStageActiveStatus)
    .case(actions.fillPendingStages, Reducer.fillPendingStages)
    .case(actions.fillExistingAndPendingStages, Reducer.fillExistingAndPendingStages)
    .case(actions.resetStagesEditorPopup, Reducer.resetStagesEditorPopup)
    .case(actions.setStateRequestInProgress, Reducer.setStateRequestInProgress)
    .case(actions.resetActivityStages, Reducer.makeInitialState);
