import * as React from 'react';

import { RowIsNewIndicator } from './RowIsNewIndicator';

import * as styles from './IndicatorsCell.scss';

interface Props {
    lineId: string;
}

export function IndicatorsCell({ lineId }: Props): JSX.Element {
    return (
        <div className={styles.root}>
            <RowIsNewIndicator
                lineId={lineId}
                normalPaddingClass={styles.paddingRightNormal}
                extendedPaddingClass={styles.paddingRightExtended}
            />
        </div>
    );
}
