// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-brief-Brief-Header-SubInfo-_SubInfo__root{display:flex;overflow:hidden;max-width:100%;height:18px;white-space:nowrap;text-overflow:ellipsis;color:#7e8681;font-family:\"Open Sans\";font-size:14px;align-items:center}.src-client-modules-brief-Brief-Header-SubInfo-_SubInfo__iconWrapper{margin-right:7px;line-height:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/brief/Brief/Header/SubInfo/SubInfo.scss"],"names":[],"mappings":"AAAA,8DACI,YAAa,CACb,eAAgB,CAEhB,cAAe,CACf,WAAY,CAEZ,kBAAmB,CACnB,sBAAuB,CAEvB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CAEf,kBAAmB,CACtB,qEAGG,gBAAiB,CAEjB,aAAc","sourcesContent":[".root {\n    display: flex;\n    overflow: hidden;\n\n    max-width: 100%;\n    height: 18px;\n\n    white-space: nowrap;\n    text-overflow: ellipsis;\n\n    color: #7e8681;\n\n    font-family: \"Open Sans\";\n    font-size: 14px;\n\n    align-items: center;\n}\n\n.iconWrapper {\n    margin-right: 7px;\n\n    line-height: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-brief-Brief-Header-SubInfo-_SubInfo__root",
	"iconWrapper": "src-client-modules-brief-Brief-Header-SubInfo-_SubInfo__iconWrapper"
};
export default ___CSS_LOADER_EXPORT___;
