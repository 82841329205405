import * as React from 'react';

import * as style from './HorizontalScrollbar.scss';

import type { ScrollbarComponent } from 'sber-marketing-ui';

import { CustomScrollbar_new as CustomScrollbar } from 'sber-marketing-ui';

interface Props {
    fullWidth: number;
    rootRef: (element: HTMLDivElement) => void;
    scrollbarRef: (component: ScrollbarComponent) => void;
    onScroll: (scrollLeft: number) => void;
}

export function HorizontalScrollbar({ fullWidth, rootRef, scrollbarRef, onScroll }: Props): JSX.Element {
    return (
        <div className={style.root} ref={rootRef}>
            <CustomScrollbar scrollbarRef={scrollbarRef} onScroll={({ scrollLeft }) => onScroll(scrollLeft)}>
                <div className={style.scrollContent} style={{ width: fullWidth }} />
            </CustomScrollbar>
        </div>
    );
}
