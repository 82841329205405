import * as React from 'react';
import * as lodash from 'lodash';
import * as moment from 'moment';
import classNames from 'classnames';
import { Icon, IconType } from 'sber-marketing-ui';

import { Money, MoneyFormatter, Utils } from '@common/Utils';
import { AnimatedInProgressIcon } from '@common/AnimatedInProgressIcon';

import { Card, Months } from '../../types';

import * as style from './ActivityCard.scss';

interface Props {
    card: Card;
    cardIsSelected: boolean;
    showDeleteButton: boolean;
    isRequestInProgress: boolean;
    userHasBudgetItemAccess: boolean;
    onCardClick: () => void;
    onDeleteButtonClick: React.MouseEventHandler<SVGSVGElement>;
}

export function ActivityCard({
    card,
    cardIsSelected,
    showDeleteButton,
    isRequestInProgress,
    userHasBudgetItemAccess,
    onCardClick,
    onDeleteButtonClick,
}: Props): JSX.Element {
    const { author, project, budgetItem, createdAt, status } = card;

    const planBudget = lodash.sum(Months.map((item) => budgetItem.plannedFunds[item]));

    return (
        <div
            className={classNames(style.root, cardIsSelected && style.selected)}
            onClick={onCardClick}
            {...{
                'qa-id': 'dashboardActivityCard',
            }}
        >
            <div className={style.topLine}>
                {isRequestInProgress ? (
                    <div className={style.requestInProgressStatusIcon}>
                        <AnimatedInProgressIcon />
                    </div>
                ) : (
                    <StatusIcon status={status} />
                )}

                <div className={style.cardType}>Связь проекта и ID</div>

                <div className={style.buttons}>
                    {/* <div className={style.commentsButton}>
                        <div className={style.commentsIcon}>
                            {true
                                ? (
                                    <Icon type={IconType.COMMENT} svgSize={18}/>
                                )
                                : (
                                    <Icon type={IconType.NEWCOMMENT} svgSize={18}/>
                                )
                            }
                        </div>

                        <div className={style.commentsCount}>
                            24
                        </div>
                    </div> */}

                    {showDeleteButton && (
                        <div
                            {...{
                                'qa-id': 'dashboardActivityCardDeleteButton',
                            }}
                        >
                            <Icon
                                type={IconType.DELETE24}
                                svgSize={24}
                                onClick={onDeleteButtonClick}
                                className={style.deleteButton}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className={style.properties}>
                <div
                    className={style.property}
                    {...{
                        'qa-id': 'dashboardActivityCardSerialNumber',
                    }}
                >
                    ID:&nbsp;
                    <span className={style.valueBlack}>{budgetItem.serialNumber}</span>
                </div>

                <div
                    className={style.property}
                    {...{
                        'qa-id': 'dashboardActivityCardTool',
                    }}
                >
                    Инструмент:&nbsp;
                    <span className={style.valueBlack}>
                        {budgetItem.dictionary && budgetItem.dictionary.tool
                            ? Utils.getDictionaryValue(budgetItem.dictionary.tool)
                            : 'Не задан'}
                    </span>
                </div>

                <div
                    className={style.property}
                    {...{
                        'qa-id': 'dashboardActivityCardPlanBudget',
                    }}
                >
                    Плановый бюджет:&nbsp;
                    <span className={style.valueBlack}>{formatMoneyValue(planBudget)}</span>
                </div>

                <div
                    className={style.property}
                    {...{
                        'qa-id': 'dashboardActivityCardActivityName',
                    }}
                >
                    Проект:&nbsp;
                    <span className={style.valueBlack}>{project.name}</span>
                </div>
            </div>

            <hr className={style.separator} />

            <div className={style.properties}>
                {author && (
                    <div
                        className={style.property}
                        {...{
                            'qa-id': 'dashboardActivityCardAuthor',
                        }}
                    >
                        Инициатор:&nbsp;
                        <span className={style.valueBlack}>
                            {author.firstName} {author.secondName}
                        </span>
                    </div>
                )}

                <div
                    className={style.property}
                    {...{
                        'qa-id': 'dashboardActivityCardCreatedAt',
                    }}
                >
                    Дата создания:&nbsp;
                    <span className={style.valueBlack}>{formatDate(createdAt)}</span>
                </div>
            </div>
        </div>
    );
}

interface StatusIconProps {
    status: 'pending' | 'approved' | 'rejected' | 'deleted';
}

function StatusIcon({ status }: StatusIconProps): JSX.Element {
    if (status === 'pending') {
        return null;
    }

    let iconType: IconType;
    let iconClass: string;
    switch (status) {
        case 'approved':
            iconType = IconType.APPROVED_ICON;
            iconClass = style.approvedIcon;
            break;
        case 'rejected':
            iconType = IconType.REJECTED_ICON;
            iconClass = style.rejectedIcon;
            break;
        case 'deleted':
            iconType = IconType.DELETED_ICON;
            break;
    }

    return (
        <div
            {...{
                'qa-id': 'dashboardActivityCardStatus',
                'qa-status': status,
            }}
            className={style.statusIcon}
        >
            <Icon type={iconType} svgSize={16} className={classNames(style.statusIcon, iconClass)} />
        </div>
    );
}

function formatMoneyValue(valueInCopecks: number): string {
    return MoneyFormatter.toThousands(Money.fromCopecks(valueInCopecks));
}

function formatDate(date: string): string {
    return `${moment(date).format('D.MM.YYYY')}`;
}
