import * as React from 'react';

import type { BaseCellParams, CellUpdateHandler } from '../../types';

interface Props<TPosition, TCellParams extends BaseCellParams> {
    position: TPosition;
    getCellParams: (
        position: TPosition,
        onCellParamsUpdateHandler: CellUpdateHandler<TCellParams>,
    ) => {
        component: React.ClassType<any, any, any>;
        cellProps: TCellParams;
    };
}

interface State<TCellParams extends BaseCellParams> {
    component: React.ClassType<any, any, any>;
    cellProps: TCellParams;
}

export function Cell<TPosition, TCellParams extends BaseCellParams>({
    position,
    getCellParams,
}: Props<TPosition, TCellParams>): JSX.Element {
    const [cellParams, setCellParams] = React.useState({
        component: null,
        cellProps: null,
    } as State<TCellParams>);

    React.useEffect(() => {
        const params = getCellParams(position, onCellParamsUpdate);

        setCellParams(params);
    }, []);

    const onCellParamsUpdate = React.useCallback((newCellParams: TCellParams) => {
        setCellParams({
            component: newCellParams.component,
            cellProps: newCellParams.cellProps,
        });
    }, []);

    return cellParams.component && cellParams.cellProps
        ? React.createElement(cellParams.component, cellParams.cellProps)
        : null;
}
