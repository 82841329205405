import * as React from 'react';
import classNames from 'classnames';
import { Preloader, ErrorForm } from 'sber-marketing-ui';

import * as styles from './ChannelBoard.scss';
import { ChannelButtonsList } from '../ChannelButtonsList';
import { ChannelInfo } from '../ChannelInfo';
import { ChannelsBoardTemplateProps } from './types';

/** "ChannelsBoard" template component */
export const ChannelsBoardTemplate: React.SFC<ChannelsBoardTemplateProps> = ({
    className,
    channelId,
    isLoading,
    hasError,
}): JSX.Element => {
    const rootClassName = classNames(styles.root, isLoading && styles.root_loading, className);
    const canBeContentRendered = !isLoading && !hasError;
    const isNotBaseChannel = channelId !== null;
    return (
        <div className={rootClassName}>
            {isLoading && <Preloader />}

            {canBeContentRendered && (
                <div
                    {...{
                        'qa-id': 'taskChannelData',
                    }}
                >
                    <ChannelButtonsList className={styles.buttonsList} />

                    {isNotBaseChannel && <ChannelInfo className={styles.info} />}
                </div>
            )}
        </div>
    );
};

ChannelsBoardTemplate.displayName = 'frontend::task::ChannelsBoard::Template';
