import * as React from 'react';
import * as styles from './styles.scss';

export interface Props {
    count: number;
    onResetButtonClick: () => void;
}

export const Header: React.FC<Props> = ({ count, onResetButtonClick }) => {
    return (
        <div className={styles.root}>
            <div className={styles.title}>
                <div className={styles.text}>Фильтры</div>
                <div className={styles.count}>{count}</div>
            </div>
            <div className={styles.button} onClick={onResetButtonClick}>
                Сбросить
            </div>
        </div>
    );
};
