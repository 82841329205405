import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';
import { WithTitle } from '../WithTitle';

import * as style from './CommonFondsChange.scss';
import { Money, MoneyFormatter } from '@common/Utils';

export interface Props {
    before: number;
    delta: number;
    title: string;
    month?: string;
}

export const CommonFondsChange: React.FC<Props> = ({ before, delta, title, month }) => {
    const deltaIsPositive = delta >= 0;
    const after = before + delta;

    const sumBefore = formatMoneyWithoutSign(before);
    const sumBeforeForTooltip = formatMoneyWithoutSignForTooltip(before);

    const sumDelta = formatMoneyWithSign(delta);
    const sumDeltaForTooltip = formatMoneyWithSignForTooltip(delta);

    const sumAfter = formatMoneyWithoutSign(after);
    const sumAfterForTooltip = formatMoneyWithoutSignForTooltip(after);

    return (
        <WithTitle title={title} month={month}>
            <div
                title={sumBeforeForTooltip}
                className={style.body}
                {...{
                    'qa-id': 'correctionCardFondsChange',
                }}
            >
                <div
                    className={style.beforeFond}
                    {...{
                        'qa-id': 'correctionCardFondsChangeBefore',
                    }}
                >
                    {sumBefore}
                </div>

                {Boolean(delta) ? (
                    <div
                        title={sumDeltaForTooltip}
                        className={classNames(
                            style.deltaValue,
                            deltaIsPositive && style.positiveDelta,
                            !deltaIsPositive && style.negativeDelta,
                        )}
                        {...{
                            'qa-id': 'correctionCardFondsChangeDelta',
                        }}
                    >
                        {sumDelta}
                    </div>
                ) : (
                    <div
                        className={classNames(style.deltaValue)}
                        {...{
                            'qa-id': 'correctionCardFondsChangeDelta',
                        }}
                    >
                        0
                    </div>
                )}

                <div
                    title={deltaIsPositive ? `↑ ${sumAfterForTooltip}` : `↓ ${sumAfterForTooltip}`}
                    className={style.afterFond}
                >
                    {Boolean(delta) && (
                        <div
                            className={classNames(
                                style.deltaArrow,
                                deltaIsPositive && style.positiveDelta,
                                !deltaIsPositive && style.negativeDelta,
                            )}
                        >
                            {deltaIsPositive ? (
                                <div className={style.positiveDeltaArrowWrapper}>
                                    <Icon type={IconType.ARROW_UP} svgSize={11} />
                                </div>
                            ) : (
                                <div className={style.negativeDeltaArrowWrapper}>
                                    <Icon type={IconType.ARROW_DOWN} svgSize={11} />
                                </div>
                            )}
                        </div>
                    )}
                    <span
                        {...{
                            'qa-id': 'correctionCardFondsChangeAfter',
                        }}
                    >
                        {sumAfter}
                    </span>
                </div>
            </div>
        </WithTitle>
    );
};

function formatMoneyWithSign(money: number): string {
    return MoneyFormatter.toThousands(Money.fromCopecks(money), { forceSign: true });
}

function formatMoneyWithSignForTooltip(money: number): string {
    return MoneyFormatter.toRoubles(Money.fromCopecks(money), { forceSign: true });
}

function formatMoneyWithoutSign(money: number): string {
    return MoneyFormatter.toThousands(Money.fromCopecks(money));
}

function formatMoneyWithoutSignForTooltip(money: number): string {
    return MoneyFormatter.toRoubles(Money.fromCopecks(money));
}
