import { axios } from '../lib/axios';

import { AvailableStoragesWithLinks } from 'sber-marketing-types/frontend';

const BASE_PATH = '/api/storage';

export class StorageApi {
    public static async getAvailableStorages(): Promise<AvailableStoragesWithLinks> {
        const res = await axios.get<AvailableStoragesWithLinks>(`${BASE_PATH}`);

        return res.data;
    }
}
