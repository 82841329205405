import * as React from 'react';
import classNames from 'classnames';

import { Checkbox } from './Checkbox';
import { ReloadButton } from './ReloadButton';
import { Text } from './Text';

import * as styles from './styles.scss';

interface Props {
    selected: boolean;
    disabled: boolean;
    value: string;
    maxValueLength: number;
    onReload: () => void;
    onSelect: (value: boolean) => void;
    onChange: (value: string) => void;
}

export const Item: React.FC<Props> = ({ value, selected, disabled, maxValueLength, onSelect, onReload, onChange }) => {
    return (
        <div className={classNames(styles.root, selected ? styles.selected : '')}>
            <Checkbox selected={selected} disabled={disabled} onSelect={onSelect} />
            <Text value={value} disabled={disabled} maxValueLength={maxValueLength} onChange={onChange} />
            <ReloadButton disabled={selected || disabled} onClick={onReload} />
        </div>
    );
};
