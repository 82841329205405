import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type {
    AutopilotRadio,
    AutopilotRadioMediaplanPlanDataResponse as AutopilotRadioMediaplan,
    AutopilotRadioMediaplanSelectedStation as SelectedStation,
    AutopilotRadioMediaplanKeyValue,
    AutopilotRadioMediaplanCampaign,
} from 'sber-marketing-types/backend';
import type { CellPosition, ColumnWidths, LineId } from './types';
import { CellEvent } from './types';
import type { StoreState } from '@store';
import type { BriefStageForm } from '@store/autopilotRadio/types';

import { MediaplanTableTemplate } from './MediaplanTableTemplate';
import { TableViewModel } from './TableViewModel';
import { TableView } from './TableView';
import {
    MakeTableColumns,
    leftFixedColumns,
    rightFixedColumns,
    MakeColumnsConfig,
    ColumnParams,
    ColumnsConfigParams,
} from './ColumnsConfig';
import { ColumnHeaderFactory, CellsFactory } from './modules';
import { setBriefFormValues } from '@store/autopilotRadio/actions';
import { getBriefStageForm, getAutopilot, getMediaplan } from '@store/autopilotRadio/selectors';

interface Props extends Partial<MapProps & DispatchProps> {}

interface MapProps {
    briefStageForm: BriefStageForm;
    autopilot: AutopilotRadio;
    mediaplan: AutopilotRadioMediaplan;
}

interface DispatchProps {
    setBriefFormValues: (values: Partial<BriefStageForm>) => void;
}

interface State {
    lineIds: string[];
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class MediaplanTableBehaviour extends React.PureComponent<Props, State> {
    private cellsStorage: TableViewModel;
    private table: TableView;
    private columnHeaderFactory: ColumnHeaderFactory;
    private cellsFactory: CellsFactory;
    private tableColumns: string[] = [];
    private defaultColumnWidths: ColumnWidths = {};

    public constructor(props: Props) {
        super(props);

        this.state = {
            lineIds: [],
        };

        this.columnHeaderFactory = new ColumnHeaderFactory({
            getColumnsConfig: this.getColumnsConfig,
        });

        this.cellsFactory = new CellsFactory({
            getLine: this.getLine,
            getAllLines: this.getAllLines,
            getTotalLine: this.getTotalLine,
            getColumnsConfig: this.getColumnsConfig,
        });

        this.cellsStorage = new TableViewModel({
            makeColumnHeaderParams: this.columnHeaderFactory.makeColumnHeaderParams,
            makeCellParams: this.cellsFactory.makeCellParams,
        });

        this.updateDefaultColumnWidths();
        this.updateTableColumns();
    }

    public async componentDidMount() {
        this.updateLineIds();
    }

    public componentDidUpdate(prevProps: Props) {
        const autopilotChanged = this.props.autopilot !== prevProps.autopilot;
        const mediaplanChanged = this.props.mediaplan !== prevProps.mediaplan;

        if (autopilotChanged || mediaplanChanged) {
            this.updateLineIds();
        }
    }

    public render(): JSX.Element {
        const { lineIds } = this.state;

        return React.createElement(MediaplanTableTemplate, {
            viewModel: this.cellsStorage,
            columns: this.tableColumns,
            leftFixedColumns,
            rightFixedColumns,
            lineIds,
            columnWidths: this.defaultColumnWidths,
            tableRef: this.tableRef,
            onCellEvent: this.onCellEvent,
        });
    }

    public setColumnWidths(columnWidths: ColumnWidths) {
        if (columnWidths) {
            this.table.setColumnWidths(columnWidths);
        }
    }

    @autobind
    protected tableRef(component: TableView) {
        this.table = component ? (component as any).getInstance() : null;
    }

    @autobind
    protected async onCellEvent(eventType: CellEvent, position: CellPosition) {
        switch (eventType) {
            case CellEvent.EditStart:
                this.updateCell(position, true);
                break;

            case CellEvent.EditEnd:
                this.updateCell(position, false);
                break;

            case CellEvent.MouseSelection:
                this.updateCell(position, false);
                break;
        }
    }

    private updateCell(position: CellPosition, edit: boolean) {
        this.cellsStorage.setCellParams(position, this.cellsFactory.makeCellParams(position, edit));
    }

    @autobind
    private updateLineCells(lineId: LineId) {
        this.tableColumns.forEach((columnName) => {
            const cellPosition = { lineId, columnName };

            const cellEditStatus = this.table.getCellEditStatus(cellPosition);
            this.updateCell(cellPosition, cellEditStatus);
        });
    }

    private updateTableColumns() {
        const columnsConfigParams = this.makeColumnsConfigParams();

        this.tableColumns = MakeTableColumns(columnsConfigParams);
    }

    private updateLineIds() {
        const lines = this.getSelectedMediaplanCampaign();

        const lineIds = lines.selected.rows.map((item) => item.id);

        lineIds.push('total');

        this.setState(
            {
                lineIds,
            },
            () => {
                lineIds.forEach((lineId) => {
                    this.updateLineCells(lineId);
                });
            },
        );
    }

    private updateDefaultColumnWidths() {
        const columnsConfigParams = this.makeColumnsConfigParams();

        const columnsConfig = MakeColumnsConfig(columnsConfigParams);

        this.defaultColumnWidths = lodash.mapValues(columnsConfig, (item) => item.defaultWidth);
    }

    @autobind
    private getColumnsConfig(): { [columnName: string]: ColumnParams } {
        const columnsConfigParams = this.makeColumnsConfigParams();

        return MakeColumnsConfig(columnsConfigParams);
    }

    private makeColumnsConfigParams(): ColumnsConfigParams {
        return {};
    }

    @autobind
    private getLine(lineId: LineId): SelectedStation {
        const lines = this.getSelectedMediaplanCampaign();

        return lines.selected.rows.find((item) => item.id === lineId);
    }

    @autobind
    private getAllLines(): AutopilotRadioMediaplanCampaign {
        return this.getSelectedMediaplanCampaign();
    }

    @autobind
    private getTotalLine(): AutopilotRadioMediaplanKeyValue[] {
        const selectedMediaplanCampaign = this.getSelectedMediaplanCampaign();

        return selectedMediaplanCampaign.selected.total;
    }

    private getSelectedMediaplanCampaign(): AutopilotRadioMediaplanCampaign {
        const { autopilot, mediaplan } = this.props;

        const linesSourceByMediaplanSelection = {
            custom: mediaplan.custom,
            recommended: mediaplan.recommended,
            packet: mediaplan.packet,
        };

        return linesSourceByMediaplanSelection[autopilot.mediaplan.selection];
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        briefStageForm: getBriefStageForm(state),
        autopilot: getAutopilot(state),
        mediaplan: getMediaplan(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setBriefFormValues,
        },
        dispatch,
    );
}
