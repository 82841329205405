// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-dashboards-AccessibleProjectsPage-_AccessibleProjectsPage__root{position:relative}.src-client-modules-dashboards-AccessibleProjectsPage-_AccessibleProjectsPage__sortingMenu{display:block;margin-top:34px}.src-client-modules-dashboards-AccessibleProjectsPage-_AccessibleProjectsPage__activityListWrapper{display:block}\n", "",{"version":3,"sources":["webpack://./src/client/modules/dashboards/AccessibleProjectsPage/AccessibleProjectsPage.scss"],"names":[],"mappings":"AAAA,oFACI,iBAAkB,CACrB,2FAGG,aAAc,CACd,eAAgB,CACnB,mGAGG,aAAc","sourcesContent":[".root {\n    position: relative;\n}\n\n.sortingMenu {\n    display: block;\n    margin-top: 34px;\n}\n\n.activityListWrapper {\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-dashboards-AccessibleProjectsPage-_AccessibleProjectsPage__root",
	"sortingMenu": "src-client-modules-dashboards-AccessibleProjectsPage-_AccessibleProjectsPage__sortingMenu",
	"activityListWrapper": "src-client-modules-dashboards-AccessibleProjectsPage-_AccessibleProjectsPage__activityListWrapper"
};
export default ___CSS_LOADER_EXPORT___;
