import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconType } from 'sber-marketing-ui';

import { FileInput as Input } from '@common/FileInput';
import { DragNDrop } from '@common/DragNDrop';

import * as styles from './styles.scss';

interface Props {
    fileInputRef: React.RefObject<Input>;
    onButtonClick: () => void;
    onFileInput: (files: File[]) => void;
}

export const UploadButtonTemplate: React.FC<Props> = ({ fileInputRef, onButtonClick, onFileInput }) => {
    return (
        <div className={styles.root}>
            <div className={styles.dragNDrop}>
                <DragNDrop onFileInput={onFileInput} />
            </div>

            <div onClick={onButtonClick} className={styles.inputWrapper}>
                <Icon
                    type={IconType.ATTACHMENT_ICON}
                    svgSize={24}
                    className={classNames(styles.icon, styles.attachmentIcon)}
                />
                Перетащите или загрузите
                <Input ref={fileInputRef} onChange={onFileInput} />
            </div>
        </div>
    );
};
