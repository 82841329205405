import * as React from 'react';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';

import { StoreState } from '@store';
import { getCreativeRequestDomain } from '@store/creative';

import { convertBudgetItemDomains } from './converts';
import { CreativeRequestDomain, CreativeRequestBudgetItemDomain, CreativeRequestBudgetItemFunds } from './types';

interface Props extends ExternalProps, Partial<MapProps> {}

interface ExternalProps {
    children: (props: ChildrenProps) => JSX.Element;
}

export interface ChildrenProps {
    loading: boolean;
    budgetItems: BudgetItem[];
}

interface MapProps {
    creativeRequestDomain: CreativeRequestDomain;
}

interface State {
    loading: boolean;
    budgetItems: BudgetItem[];
}

interface BudgetItem {
    id: string;
    serialNumber: number;
    sapComment: string;
    budgetYear: number;
    plannedFunds: CreativeRequestBudgetItemFunds;
    reservedFunds: CreativeRequestBudgetItemFunds;
    factFunds: CreativeRequestBudgetItemFunds;
    createdAt: Date;
    block: string;
    division: string;
}

@(connect(mapStateToProps) as any)
export class WithBudgetItems extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loading: true,
            budgetItems: [],
        };
    }

    public async componentDidMount(): Promise<void> {
        this.init();
        this.setState({
            loading: false,
            budgetItems: await this.getBudgetItems(),
        });
    }

    public render(): JSX.Element {
        return React.createElement(this.props.children, this.getChildrenProps());
    }

    private getChildrenProps(): ChildrenProps {
        const { loading, budgetItems } = this.state;
        return {
            loading,
            budgetItems,
        };
    }

    private init(): void {
        this.subscribe();
    }

    private subscribe(): void {
        this.subscribeOnCreativeRequestDomainChange();
    }

    private subscribeOnCreativeRequestDomainChange(): void {
        const { creativeRequestDomain } = this.props;

        creativeRequestDomain.events.onBudgetItemsAdded(() => this.updateBudgetItemDomains());
        creativeRequestDomain.events.onBudgetItemsRemoved(() => this.updateBudgetItemDomains());
        creativeRequestDomain.events.onBudgetItemsUpdated(this.onBudgetItemsUpdatedHandler);
    }

    private async getBudgetItems(): Promise<BudgetItem[]> {
        const budgetItemDomains = await this.requestBudgetItemDomains();
        return await convertBudgetItemDomains(budgetItemDomains);
    }

    private async requestBudgetItemDomains(): Promise<CreativeRequestBudgetItemDomain[]> {
        const { creativeRequestDomain } = this.props;

        try {
            return await creativeRequestDomain.model.getBudgetItems();
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    @autobind
    private async updateBudgetItemDomains(): Promise<void> {
        this.setState({ loading: true });
        const budgetItems = await this.getBudgetItems();
        this.setState({ loading: false, budgetItems });
    }

    @autobind
    private async onBudgetItemsUpdatedHandler(budgetItemsDomains: CreativeRequestBudgetItemDomain[]): Promise<void> {
        this.setState({ loading: true });
        const budgetItems = await convertBudgetItemDomains(budgetItemsDomains || []);
        this.setState({ loading: false, budgetItems });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        creativeRequestDomain: getCreativeRequestDomain(state),
    };
}

export type { CreativeRequestBudgetItemDomain, BudgetItem };
