// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-CardTypes-common-SumWithTitle-_SumWithTitle__sum{letter-spacing:0.24px;color:#000000;font-family:'Open Sans';font-size:14px;font-weight:600}\n", "",{"version":3,"sources":["webpack://./src/client/modules/dashboard/RightSidebar/BudgetInfo/Corrections/templates/CardTypes/common/SumWithTitle/SumWithTitle.scss"],"names":[],"mappings":"AAAA,6HACI,qBAAsB,CAEtB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,eAAgB","sourcesContent":[".sum {\n    letter-spacing: 0.24px;\n\n    color: #000000;\n\n    font-family: 'Open Sans';\n    font-size: 14px;\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sum": "src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-CardTypes-common-SumWithTitle-_SumWithTitle__sum"
};
export default ___CSS_LOADER_EXPORT___;
