import * as React from 'react';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type {
    AutopilotDOOH,
    AutopilotDOOHMediaplanBaseByRegion,
    AutopilotDOOHMediaplanBaseByRegionFormat,
    AutopilotDOOHMediaplanTotal,
} from 'sber-marketing-types/backend';
import { CellPosition, ColumnWidths, LineId, TableBodyCellParams, TableHeaderCellParams } from './types';
import { CellEvent } from './types';
import type { StoreState } from '@store';
import type {} from '@store/autopilotDOOH/types';

import { CitiesIndicatorsTableTemplate } from './CitiesIndicatorsTableTemplate';
import { TableView } from './TableView';
import {
    MakeTableColumns,
    leftFixedColumns,
    rightFixedColumns,
    MakeColumnsConfig,
    ColumnParams,
    ColumnsConfigParams,
} from './ColumnsConfig';
import { ColumnHeaderFactory, CellsFactory } from './modules';
import { getAutopilot } from '@store/autopilotDOOH/selectors';
import { CellsStorage } from './CellsStorage';

interface Props extends Partial<MapProps> {}

interface MapProps {
    autopilot: AutopilotDOOH;
}

interface State {
    lineIds: string[];
}

@(connect(mapStateToProps, null) as any)
export class CitiesIndicatorsTableBehaviour extends React.PureComponent<Props, State> {
    private headerCellsStorage: CellsStorage<string, TableHeaderCellParams>;
    private tableCellsStorage: CellsStorage<CellPosition, TableBodyCellParams>;
    private table: TableView;
    private columnHeaderFactory: ColumnHeaderFactory;
    private cellsFactory: CellsFactory;
    private tableColumns: string[] = [];
    private defaultColumnWidths: ColumnWidths = {};

    public constructor(props: Props) {
        super(props);

        this.state = {
            lineIds: [],
        };

        this.columnHeaderFactory = new ColumnHeaderFactory({
            getColumnsConfig: this.getColumnsConfig,
        });

        this.cellsFactory = new CellsFactory({
            getLine: this.getLine,
            getAllLines: this.getAllLines,
            getColumnsConfig: this.getColumnsConfig,
            onCellValueChange: this.onCellValueChange,
        });

        this.headerCellsStorage = new CellsStorage({
            makeCellParams: this.columnHeaderFactory.makeColumnHeaderParams,
        });

        this.tableCellsStorage = new CellsStorage({
            makeCellParams: this.cellsFactory.makeCellParams,
        });

        this.updateDefaultColumnWidths();
        this.updateTableColumns();
    }

    public async componentDidMount() {
        this.updateLineIds();
    }

    public async componentDidUpdate(prevProps: Props, prevState: State) {}

    public render(): JSX.Element {
        const { lineIds } = this.state;

        return React.createElement(CitiesIndicatorsTableTemplate, {
            headerCellsStorage: this.headerCellsStorage,
            tableCellsStorage: this.tableCellsStorage,
            columns: this.tableColumns,
            leftFixedColumns,
            rightFixedColumns,
            lineIds,
            columnWidths: this.defaultColumnWidths,
            tableRef: this.tableRef,
            onCellEvent: this.onCellEvent,
        });
    }

    public setColumnWidths(columnWidths: ColumnWidths) {
        if (columnWidths) {
            this.table.setColumnWidths(columnWidths);
        }
    }

    @autobind
    protected tableRef(component: TableView) {
        this.table = component ? (component as any).getInstance() : null;
    }

    @autobind
    protected onCellValueChange() {
        this.table.setCursorPosition(null);
    }

    @autobind
    protected async onCellEvent(eventType: CellEvent, position: CellPosition) {
        switch (eventType) {
            case CellEvent.EditStart:
                this.updateCell(position, true);
                break;

            case CellEvent.EditEnd:
                this.updateCell(position, false);
                this.table.setCursorPosition(null);
                break;

            case CellEvent.MouseSelection:
                this.updateCell(position, false);
                break;
        }
    }

    private updateCell(position: CellPosition, edit = false) {
        this.tableCellsStorage.setCellParams(position, this.cellsFactory.makeCellParams(position, edit));
    }

    @autobind
    private updateLineCells(lineId: LineId) {
        const allColumns = [...this.tableColumns, ...leftFixedColumns, ...rightFixedColumns];

        allColumns.forEach((columnName) => {
            const cellPosition = { lineId, columnName };

            const cellEditStatus = this.table.getCellEditStatus(cellPosition);
            this.updateCell(cellPosition, cellEditStatus);
        });
    }

    @autobind
    private updateColumnCells(columnName: string) {
        this.state.lineIds.forEach((lineId) => {
            const cellPosition = { lineId, columnName };

            const cellEditStatus = this.table.getCellEditStatus(cellPosition);
            this.updateCell(cellPosition, cellEditStatus);
        });
    }

    private updateTableColumns() {
        const columnsConfigParams = this.makeColumnsConfigParams();

        this.tableColumns = MakeTableColumns(columnsConfigParams);
    }

    private updateLineIds() {
        const { mediaplan } = this.props.autopilot;

        const regionIds = mediaplan.byRegion.map((item) => item.region.id);

        const groupedMediaplanLines = regionIds.map((regionId) => ({
            regionId,
            groupLines: mediaplan.byRegionFormat.filter((item) => item.region.id === regionId),
            groupTotalLine: mediaplan.byRegion.find((item) => item.region.id === regionId),
        }));

        const lineIds: string[] = lodash.flatMap(groupedMediaplanLines, (group) => [
            `groupTotal|${group.regionId}`,
            ...group.groupLines.map((item) => item.id),
        ]);

        this.setState(
            {
                lineIds,
            },
            () => {
                lineIds.forEach((lineId) => {
                    this.updateLineCells(lineId);
                });
            },
        );
    }

    private updateDefaultColumnWidths() {
        const columnsConfigParams = this.makeColumnsConfigParams();

        const columnsConfig = MakeColumnsConfig(columnsConfigParams);

        this.defaultColumnWidths = lodash.mapValues(columnsConfig, (item) => item.defaultWidth);
    }

    @autobind
    private getColumnsConfig(): { [columnName: string]: ColumnParams } {
        const columnsConfigParams = this.makeColumnsConfigParams();

        return MakeColumnsConfig(columnsConfigParams);
    }

    private makeColumnsConfigParams(): ColumnsConfigParams {
        return {};
    }

    @autobind
    private getLine(lineId: LineId): {
        byRegion?: AutopilotDOOHMediaplanBaseByRegion;
        byRegionFormat?: AutopilotDOOHMediaplanBaseByRegionFormat;
        total?: AutopilotDOOHMediaplanTotal;
    } {
        const { mediaplan } = this.props.autopilot;

        if (lodash.first(lineId.split('|')) === 'groupTotal') {
            const regionId = lodash.last(lineId.split('|'));

            return { byRegion: mediaplan.byRegion.find((item) => item.region.id === regionId) };
        }

        if (lineId === 'total') {
            return { total: mediaplan.total };
        }

        return { byRegionFormat: mediaplan.byRegionFormat.find((item) => item.id === lineId) };
    }

    @autobind
    private getAllLines(): any[] {
        return [];
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        autopilot: getAutopilot(state),
    };
}
