import * as React from 'react';
import { useSelector } from 'react-redux';
import { uniq, compact, flatten, isEmpty, groupBy } from 'lodash';

import { CreativeRequest, CreativeRequestItem, CreativeRequestComment, CreativeRequestItemComment } from '@api';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';
import { getAppUsers } from '@store/appUsers';
import { TableNumberByType } from '@store/creative';

import { useCreativeRequestData } from '../../hooks';

export function useUsersForMentionOrganizationIds(creativeRequest: CreativeRequest): string[] {
    const creativeRequestRef = React.useRef<CreativeRequest>(null);
    const appUsers = useSelector((state: StoreState) => getAppUsers(state));
    const loginUserOrganization = useSelector((state: StoreState) => getLoginUser(state).attributes.organizationId);

    const [organizationIds, setOrganizationIds] = React.useState<string[]>([]);

    async function worker() {
        const creativeRequestUsers = flatten(
            await Promise.all([
                creativeRequest.model.getInitiators(),
                creativeRequest.model.getAgencyExecutors(),
                creativeRequest.model.findAvailableExperts(),
            ]),
        ).filter((user) => !isEmpty(user));

        setOrganizationIds(
            uniq(
                compact([
                    loginUserOrganization,
                    ...creativeRequestUsers.map(
                        (creativeRequestUser) => appUsers.entities[creativeRequestUser.id]?.organizationId,
                    ),
                ]),
            ),
        );
    }

    React.useEffect(() => {
        worker();
    }, [appUsers]);

    React.useEffect(() => {
        if (creativeRequestRef.current) {
            creativeRequest.events.offAgencyExecutorsUpdated(worker);
        }

        creativeRequestRef.current = creativeRequest;
        creativeRequest.events.onAgencyExecutorsUpdated(worker);
    }, [creativeRequest]);

    return organizationIds;
}

export interface CreativeRequestItemWithTitle {
    title: string;
    dto: CreativeRequestItem;
}

export function useCreativeRequestItems(creativeRequest: CreativeRequest) {
    const [items, setItems] = React.useState<CreativeRequestItemWithTitle[]>([]);

    async function getItemTitle(item: CreativeRequestItem): Promise<string> {
        const group = await item.model.creativeRequestGroup;

        return `${TableNumberByType[group?.value] || ''}.${item.model.number}`;
    }

    React.useEffect(function fetchAndListenCreativeRequestItems() {
        let itemAddedBound: (item: CreativeRequestItem) => Promise<void>;

        async function worker() {
            const items = await creativeRequest.model.getItems();
            const itemsTitles = await Promise.all(items.map((item) => getItemTitle(item)));

            setItems(
                items.map((item, i) => ({
                    dto: item,
                    title: itemsTitles[i],
                })),
            );

            itemAddedBound = async (item: CreativeRequestItem) => {
                const itemTitle = await getItemTitle(item);

                setItems((items) => [
                    ...items,
                    {
                        dto: item,
                        title: itemTitle,
                    },
                ]);
            };

            creativeRequest.events.onItemsAdded(itemAddedBound);
        }

        worker();

        return function unlistenCreativeRequestItems() {
            creativeRequest.events.offItemsAdded(itemAddedBound);
        };
    }, []);

    return items;
}

export function useCreativeRequestComments(creativeRequest: CreativeRequest) {
    let setCommentsBounded: (comment: CreativeRequestComment) => void;

    const comments = useCreativeRequestData(
        creativeRequest,
        async (creativeRequest) => [...(await creativeRequest.model.getComments())],
        (creativeRequest, setComments) => {
            setCommentsBounded = (comment: CreativeRequestComment) => setComments((comments) => [...comments, comment]);

            creativeRequest.events.onCommentsAdded(setCommentsBounded);
        },
        (creativeRequest) => creativeRequest.events.offCommentsAdded(setCommentsBounded),
    );

    return comments;
}

export function useCreativeRequestItemComments(creativeRequestItem: CreativeRequestItem) {
    const [comments, setComments] = React.useState<CreativeRequestItemComment[]>();

    async function fetchComments() {
        setComments([...(await creativeRequestItem.model.getComments())]);
    }

    async function appendComment(comment: CreativeRequestItemComment) {
        setComments((comments) => [...comments, comment]);
    }

    async function refetchComments() {
        setComments([...creativeRequestItem.model.comments]);
    }

    React.useEffect(
        function fetchCreativeRequesteItemCommentsAndInitListeners() {
            fetchComments();

            creativeRequestItem.events.onCommentsAdded(appendComment);

            return function resetListeners() {
                creativeRequestItem.events.offCommentsAdded(appendComment);
            };
        },
        [creativeRequestItem],
    );

    return { comments, refetchComments };
}

export function useCreativeRequesteItemUnreadComments(creativeRequestItem: CreativeRequestItem) {
    const [isUnread, setIsUnread] = React.useState(false);

    async function onCreativeRequestItemUpdated(payload: any) {
        if (payload.property === 'comments') {
            fillIsUnread();
        }
    }

    async function fillIsUnread() {
        setIsUnread(
            !!creativeRequestItem.model.comments?.some((comment) => !comment.model.isRead || comment.model.isFavorite),
        );
    }

    React.useEffect(
        function fetchCreativeRequestItemCommentsUnreadMarkerAndInitListeners() {
            async function worker() {
                await creativeRequestItem.model.getComments();
                fillIsUnread();
            }

            worker();

            creativeRequestItem.events.onUpdated(onCreativeRequestItemUpdated);

            return function resetListeners() {
                creativeRequestItem.events.offUpdated(onCreativeRequestItemUpdated);
            };
        },
        [creativeRequestItem],
    );

    return isUnread;
}

export function useCommentsReplyChain<T extends CreativeRequestComment | CreativeRequestItemComment>(
    comments: T[],
    commentIdToReplyTo: string,
) {
    const commentsByReplyId = groupBy(comments, (comment) => comment.model.replyId || null) || {};
    const commentToReplyTo = comments?.find((comment) => comment.model.id === commentIdToReplyTo);

    const commentsToDisplay = commentsByReplyId[commentIdToReplyTo];

    return {
        commentsByReplyId,
        commentToReplyTo,
        commentsToDisplay,
    };
}
