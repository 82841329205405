import * as React from 'react';
import autobind from 'autobind-decorator';

import { SapComment } from './SapComment';
import { BudgetItemApi } from '@api';

interface Props {
    canEditBudgetItem: boolean;
    budgetItemId: string;
    name: string;
    correctionName: string;
    className?: string;
    updateCorrections: () => Promise<void>;
    updateCard: () => Promise<void>;
}

interface State {
    value: string;
    loading: boolean;
    editEnabled: boolean;
}

export class SapCommentContainer extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            value: props.correctionName !== null ? props.correctionName : props.name,
            loading: false,
            editEnabled: false,
        };
    }

    public componentDidUpdate(prevProps: Props): void {
        const { name, correctionName } = this.props;

        const nameChanged = prevProps.name !== name;
        const correctionNameChanged = prevProps.correctionName !== correctionName;

        if (nameChanged || correctionNameChanged) {
            this.setState({
                value: correctionName !== null ? correctionName : name,
            });
        }
    }

    public render(): JSX.Element {
        const { canEditBudgetItem, name, correctionName, className } = this.props;

        return React.createElement(SapComment, {
            canEditBudgetItem,
            value: this.state.value,
            originalValue: name,
            correctionValue: correctionName,
            editEnabled: this.state.editEnabled,
            loading: this.state.loading,
            className,
            onInputChange: this.onInputChange,
            onInputSendButtonClick: this.onInputSendButtonClick,
            onEditButtonClick: this.onEditButtonClick,
            onInputOutsideClick: this.onInputOutsideClick,
        });
    }

    @autobind
    protected onInputChange(value: string) {
        this.setState({
            value,
        });
    }

    @autobind
    protected async onInputSendButtonClick() {
        await BudgetItemApi.updateBudgetItem({
            id: this.props.budgetItemId,
            sapComment: this.state.value,
        });

        await Promise.all([this.props.updateCard(), this.props.updateCorrections()]);

        this.setState({
            editEnabled: false,
        });
    }

    @autobind
    protected onEditButtonClick() {
        this.setState({
            editEnabled: true,
        });
    }

    @autobind
    protected onInputOutsideClick() {
        const { name, correctionName } = this.props;

        this.setState({
            editEnabled: false,
            value: correctionName !== null ? correctionName : name,
        });
    }
}
