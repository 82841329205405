import * as React from 'react';
import { connect } from 'react-redux';

import { StoreState } from '@store';
import { userHasBudgetAccess } from '@store/user';
import { getDashboardPageState } from '@store/dashboardPage';

interface Props extends Partial<MapProps> {
    children: (props: MapProps) => JSX.Element;
}

interface MapProps {
    cardId: string;
    activityId: number;
    userHasBudgetAccess: boolean;
}

@(connect(mapStateToProps) as any)
export class BudgetItemToProjectCardWithStore extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { cardId, activityId, userHasBudgetAccess } = this.props;

        return this.props.children({
            cardId,
            activityId,
            userHasBudgetAccess,
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { selectedCard } = getDashboardPageState(state);

    if (!selectedCard) {
        return {
            cardId: null,
            activityId: null,
            userHasBudgetAccess: false,
        };
    }

    const { id, activityId } = selectedCard;

    return {
        cardId: id,
        activityId: Number(activityId),
        userHasBudgetAccess: userHasBudgetAccess(state),
    };
}
