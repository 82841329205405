import * as React from 'react';
import * as lodash from 'lodash';
import autobind from 'autobind-decorator';

import { PlanCorrectionCard, Participant } from './PlanCorrectionCard';
import { PlanFundsTransferCorrection, CorrectionStatus } from '../../../types';

interface Props {
    correction: PlanFundsTransferCorrection;
    onHover: (correctionId: string) => void;
    onChange: () => void;
}

export class PlanCorrectionCardContainer extends React.Component<Props> {
    public render() {
        const { correction, onChange } = this.props;
        return React.createElement(PlanCorrectionCard, {
            correction,
            donor: this.getDonor(),
            acceptor: this.getAcceptor(),
            onCardMouseEnter: this.onCardMouseEnter,
            onCardMouseLeave: this.onCardMouseLeave,
            onChange,
        });
    }

    @autobind
    private onCardMouseEnter(): void {
        this.props.onHover(this.props.correction.id);
    }

    @autobind
    private onCardMouseLeave(): void {
        this.props.onHover(null);
    }

    private getDonor(): Participant {
        const {
            status,
            params,
            budgetItemCurrent: budgetItemsCurrent,
            budgetItemBefore: budgetItemsBefore,
        } = this.props.correction;

        const currentBudgetItem = budgetItemsCurrent.find(({ id }) => id === params.donorId);
        const beforeBudgetItem = lodash.isEmpty(budgetItemsBefore)
            ? null
            : budgetItemsBefore.find(({ id }) => id === params.donorId);

        const definedBudgetItem =
            status === CorrectionStatus.NeedApproving
                ? currentBudgetItem
                : lodash.isEmpty(beforeBudgetItem)
                ? currentBudgetItem
                : beforeBudgetItem;

        return {
            value: -params.value,
            budgetItemSerialNumber: definedBudgetItem.serialNumber,
            activityName: currentBudgetItem.sapComment,
        };
    }

    private getAcceptor(): Participant {
        const {
            status,
            params,
            budgetItemCurrent: budgetItemsCurrent,
            budgetItemBefore: budgetItemsBefore,
        } = this.props.correction;

        const currentBudgetItem = budgetItemsCurrent.find(({ id }) => id === params.acceptorId);
        const beforeBudgetItem = lodash.isEmpty(budgetItemsBefore)
            ? null
            : budgetItemsBefore.find(({ id }) => id === params.acceptorId);

        const definedBudgetItem =
            status === CorrectionStatus.NeedApproving
                ? currentBudgetItem
                : lodash.isEmpty(beforeBudgetItem)
                ? currentBudgetItem
                : beforeBudgetItem;

        return {
            value: params.value,
            budgetItemSerialNumber: definedBudgetItem.serialNumber,
            activityName: currentBudgetItem.sapComment,
        };
    }
}
