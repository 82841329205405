// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-Header-_Header__root{display:grid;align-items:center;justify-content:space-between;grid-template-columns:1fr max-content;grid-auto-flow:row;grid-column-gap:8px;grid-row-gap:13px}.src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-Header-_Header__title{overflow:hidden;white-space:nowrap;letter-spacing:0.2px;text-overflow:ellipsis;color:#000000;font-family:'Open Sans';font-size:14px;line-height:1.29}.src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-Header-_Header__id{font-weight:800}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetPlanning/LineModal/Header/Header.scss"],"names":[],"mappings":"AAAA,oFACI,YAAa,CAEb,kBAAmB,CACnB,6BAA8B,CAE9B,qCAAsC,CACtC,kBAAmB,CACnB,mBAAoB,CACpB,iBAAkB,CACrB,qFAGG,eAAgB,CAEhB,kBAAmB,CACnB,oBAAqB,CACrB,sBAAuB,CAEvB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACpB,kFAGG,eAAgB","sourcesContent":[".root {\n    display: grid;\n\n    align-items: center;\n    justify-content: space-between;\n\n    grid-template-columns: 1fr max-content;\n    grid-auto-flow: row;\n    grid-column-gap: 8px;\n    grid-row-gap: 13px;\n}\n\n.title {\n    overflow: hidden;\n\n    white-space: nowrap;\n    letter-spacing: 0.2px;\n    text-overflow: ellipsis;\n\n    color: #000000;\n\n    font-family: 'Open Sans';\n    font-size: 14px;\n    line-height: 1.29;\n}\n\n.id {\n    font-weight: 800;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-Header-_Header__root",
	"title": "src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-Header-_Header__title",
	"id": "src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-Header-_Header__id"
};
export default ___CSS_LOADER_EXPORT___;
