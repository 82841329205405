import * as React from 'react';

import * as style from './TableWidget.scss';

import { OpacityTransition } from 'sber-marketing-ui';
import { ActualCostSummary } from './ActualCostSummary';
import { FundsActions } from './FundsActions';
import { SummaryAmountWidget } from './SummaryAmountWidget';
import { Table } from './Table';

interface Props {
    creativeRequestId: string;
    displayActualCostSummary: boolean;
    displayFundsActions: boolean;
    displayStatusColumns: boolean;
}

export const TableWidgetTemplate = ({
    creativeRequestId,
    displayActualCostSummary,
    displayFundsActions,
    displayStatusColumns,
}: Props): JSX.Element => {
    return (
        <OpacityTransition start={true}>
            <div className={style.root}>
                {(displayActualCostSummary || displayFundsActions) && (
                    <div className={style.topLine}>
                        {displayFundsActions && (
                            <div className={style.topLineWidget}>
                                <FundsActions />
                            </div>
                        )}

                        {displayActualCostSummary && (
                            <div className={style.topLineWidget}>
                                <ActualCostSummary />
                            </div>
                        )}
                    </div>
                )}

                <div className={style.summaryAmount}>
                    <SummaryAmountWidget />
                </div>

                <div className={style.table}>
                    <Table creativeRequestId={creativeRequestId} displayStatusColumns={displayStatusColumns} />
                </div>
            </div>
        </OpacityTransition>
    );
};
