import { Success, Failure } from 'typescript-fsa';

import { RemoveFileFromCommentaryPayload, FetchError, TaskPageState } from '../types';

export const removeFileFromCommentaryStartedReducer = (
    state: TaskPageState,
    payload: RemoveFileFromCommentaryPayload,
): TaskPageState => {
    return state;
};

export const removeFileFromCommentaryErrorReducer = (
    state: TaskPageState,
    payload: Failure<RemoveFileFromCommentaryPayload, FetchError>,
): TaskPageState => state;

export const removeFileFromCommentarySuccessReducer = (
    { comments, ...restState }: TaskPageState,
    { params: { fileName, commentaryId } }: Success<RemoveFileFromCommentaryPayload, void>,
): TaskPageState => ({
    ...restState,
    comments: {
        ...comments,
        [commentaryId]: {
            ...comments[commentaryId],
            assetsIds: comments[commentaryId].assetsIds.filter((id) => id !== fileName),
        },
    },
});
