import * as React from 'react';
import { isEqual } from 'lodash';
import classNames from 'classnames';
import {
    Icon,
    IconType,
    BodyPortal,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
} from 'sber-marketing-ui';

import { ColumnFilters, ColumnName, CellValueType } from '@store/budgetPlanning';

import { ColumnsList } from '../../../ColumnsConfig';
import { SortingMenu } from '../../../SortingMenu';
import { FilterMenu } from '../../../FilterMenu';
import { ColumnFixingMenu } from '../../../ColumnFixingMenu';

import * as style from './HeaderDropdownCell.scss';
export interface HeaderDropdownCellProps {
    name: ColumnName;
    title: string;
    isHovered: boolean;
    isOpened: boolean;
    isSorted: boolean;
    isFiltered: boolean;
    isResizingColumn: boolean;
    isColumnFixed: boolean;
    onOpenerClick: () => void;
    onMaskClick: () => void;
    onColumnFixChange: () => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}

export const HeaderDropdownCell = ({
    name,
    title,
    isHovered,
    isOpened,
    isSorted,
    isFiltered,
    isResizingColumn,
    isColumnFixed,
    onOpenerClick,
    onMaskClick,
    onColumnFixChange,
    onMouseEnter,
    onMouseLeave,
}: HeaderDropdownCellProps): JSX.Element => {
    return (
        <div
            title={title}
            className={classNames(
                style.root,
                isOpened && style.opened,
                isSorted && style.sorted,
                isFiltered && style.filtered,
            )}
            onMouseEnter={() => onMouseEnter()}
            onMouseLeave={() => onMouseLeave()}
        >
            <div
                className={classNames(style.opener, isResizingColumn && style.openerResizingColumn)}
                onClick={onOpenerClick}
                {...{
                    'qa-id': 'tableHeaderOpenButton',
                    'qa-column-label': title,
                    'qa-column-id': name,
                }}
            >
                <div className={style.title}>{title}</div>

                <div className={style.arrowIcon}>
                    <Icon type={isOpened ? IconType.ARROW20_UP : IconType.ARROW20_DOWN} />
                </div>
            </div>

            {isOpened && (
                <BodyPortal>
                    <div className={style.mask} onClick={onMaskClick} />
                </BodyPortal>
            )}
        </div>
    );
};

export interface DropdownContentProps {
    columnName: ColumnName;
    filters: ColumnFilters;
    columnValueType: CellValueType;
    currencySearch: boolean;
    onFiltersChange: (filters: ColumnFilters) => void;
    onColumnFixChange: () => void;
    onApplyFiltersButtonClick: () => void;
}

export const DropdownContent = ({
    columnName,
    filters,
    columnValueType,
    currencySearch,
    onFiltersChange,
    onColumnFixChange,
    onApplyFiltersButtonClick,
}: DropdownContentProps): JSX.Element => {
    const [currentFilters, setCurrentFilters] = React.useState(filters);
    const showApplyFiltersButton = !isEqual(currentFilters, filters);

    function filtersChangeHandler(filters: ColumnFilters) {
        setCurrentFilters(filters);
        onFiltersChange(filters);
    }
    // const showApplyFiltersButton = useSelector((state: StoreState) => {
    //     const {
    //         columnFilters: {
    //             filters
    //         },
    //         previouslyLoadedFilters
    //     } = getBudgetPlanningPageState(state);

    //     return !isEqual(
    //         filters[columnName],
    //         previouslyLoadedFilters ? previouslyLoadedFilters[columnName] : null
    //     );
    // });

    const column = ColumnsList.find((column) => column.name === columnName);
    const filterNamesAreNumeral = columnValueType == CellValueType.Number || columnValueType == CellValueType.Currency;

    return (
        <div
            className={style.dropdownContent}
            {...{
                'qa-id': 'tableHeader',
            }}
        >
            <div className={style.dropdownTitle}>{column?.title}</div>

            <ColumnFixingMenu columnName={columnName} onColumnFixChange={onColumnFixChange} />

            <SortingMenu columnName={columnName} />

            <div className={style.filtersWrapper}>
                <FilterMenu
                    columnName={columnName}
                    filters={filters}
                    filterNamesAreNumeral={filterNamesAreNumeral}
                    currencySearch={currencySearch}
                    onFiltersChange={filtersChangeHandler}
                />
            </div>

            {showApplyFiltersButton && (
                <React.Fragment>
                    <div className={style.divider} />

                    <div className={style.applyFiltersButtonWrapper}>
                        <Button theme={ButtonTheme.GhostRounded} onClick={onApplyFiltersButtonClick}>
                            <div className={style.applyFiltersButtonContent}>
                                <Icon type={IconType.PLAY} svgSize={10} className={style.applyFilterButtonIcon} />
                                Применить
                            </div>
                        </Button>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};
