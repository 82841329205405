import * as React from 'react';
import { useSelector } from 'react-redux';

import { Popup } from './Popup';
import { Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { canSendSelectedTextOptions } from '@store/autopilot/generationTextForm';

interface Props {}

export const ConfirmButton: React.FC<Props> = () => {
    const [opened, open] = React.useState(false);
    const disabled = useSelector((state: StoreState) => !canSendSelectedTextOptions(state));

    return (
        <>
            <Button
                style={{
                    height: '48px',
                    padding: '0 20px',
                }}
                disabled={disabled}
                theme={ButtonTheme.MainRounded}
                onClick={() => open(true)}
            >
                Подтвердить выбор
            </Button>

            {opened && <Popup onClose={() => open(false)} />}
        </>
    );
};
