// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-tags-TagEmoji-_TagEmoji__rootSvg{display:inline-block;vertical-align:middle;fill:currentColor}\n", "",{"version":3,"sources":["webpack://./src/client/modules/tags/TagEmoji/TagEmoji.scss"],"names":[],"mappings":"AAAA,qDACI,oBAAqB,CACrB,qBAAsB,CACtB,iBAAkB","sourcesContent":[".rootSvg {\n    display: inline-block;\n    vertical-align: middle;\n    fill: currentColor;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rootSvg": "src-client-modules-tags-TagEmoji-_TagEmoji__rootSvg"
};
export default ___CSS_LOADER_EXPORT___;
