// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-CurrentRowSumInputs-TitleRow-_TitleRow__root{display:flex;justify-content:space-between}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-CurrentRowSumInputs-TitleRow-_TitleRow__rootReversed{flex-direction:row-reverse}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-CurrentRowSumInputs-TitleRow-_TitleRow__title{font-size:12px;line-height:16px;color:#7E8681}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-CurrentRowSumInputs-TitleRow-_TitleRow__titleBold{font-weight:600}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/InternalTransitionSumEntering/CurrentRowSumInputs/TitleRow/TitleRow.scss"],"names":[],"mappings":"AAAA,oJACI,YAAa,CACb,6BAA8B,CACjC,4JAGG,0BAA2B,CAC9B,qJAGG,cAAe,CACf,gBAAiB,CAEjB,aAAc,CACjB,yJAGG,eAAgB","sourcesContent":[".root {\n    display: flex;\n    justify-content: space-between;\n}\n\n.rootReversed {\n    flex-direction: row-reverse;\n}\n\n.title {\n    font-size: 12px;\n    line-height: 16px;\n\n    color: #7E8681;\n}\n\n.titleBold {\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-CurrentRowSumInputs-TitleRow-_TitleRow__root",
	"rootReversed": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-CurrentRowSumInputs-TitleRow-_TitleRow__rootReversed",
	"title": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-CurrentRowSumInputs-TitleRow-_TitleRow__title",
	"titleBold": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-CurrentRowSumInputs-TitleRow-_TitleRow__titleBold"
};
export default ___CSS_LOADER_EXPORT___;
