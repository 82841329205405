import * as React from 'react';
import { connect } from 'react-redux';
import * as lodash from 'lodash';

import type {
    Autopilot2,
    Autopilot2MediaplanPlanDataResponse as Autopilot2MediaplanPlanData,
} from 'sber-marketing-types/backend';
import type { BriefStageForm } from '@store/autopilot/types';
import type { User } from '@store/user';

import { MediaplanStageWidgetTemplate } from './MediaplanStageWidgetTemplate';
import type { StoreState } from '@store';
import { getLoginUser } from '@store/user';
import { getAutopilot, getBriefStageForm, getMediaplan } from '@store/autopilot/selectors';

interface Props extends Partial<MapProps> {
    displayStatuses?: boolean;
    readOnly?: boolean;
}

interface MapProps {
    autopilot: Autopilot2;
    mediaplan: Autopilot2MediaplanPlanData;
    briefForm: BriefStageForm;
    user: User;
}

@(connect(mapStateToProps, null) as any)
export class MediaplanStageWidgetBehaviour extends React.PureComponent<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        const { autopilot, mediaplan, briefForm, displayStatuses } = this.props;

        const userIsAutopilotMediaplanner = this.checkUserAutopilotMediaplannerAccess();
        const placementsAreLanched = this.checkLaunchedPlacements();

        return React.createElement(MediaplanStageWidgetTemplate, {
            xls: autopilot.mediaplan?.xls,
            url: autopilot.mediaplan?.url,
            displayUrl: userIsAutopilotMediaplanner && !placementsAreLanched,
            displayStatuses,
            autopilot,
            mediaplan,
            briefForm,
            displayTable: !lodash.isEmpty(mediaplan),
        });
    }

    private checkUserAutopilotMediaplannerAccess(): boolean {
        // check if user has role === Autopilot Mediaplanner
        return this.props.user.attributes.roles.map(({ id }) => id).includes(23);
    }

    private checkLaunchedPlacements(): boolean {
        const { autopilot } = this.props;

        return autopilot?.status === 'on' && !!autopilot.launcherId;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        autopilot: getAutopilot(state),
        mediaplan: getMediaplan(state),
        briefForm: getBriefStageForm(state),
        user: getLoginUser(state),
    };
}
