import * as React from 'react';
import { Icon, IconType } from 'sber-marketing-ui';

import * as styles from './NoResultsMarker.scss';

interface Props {
    text: string;
}

export function NoResultsMarker({ text }: Props): JSX.Element {
    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'noResultsMarker',
            }}
        >
            <Icon type={IconType.CIRCLE_INFO} svgSize={16} className={styles.icon} />

            {text}
        </div>
    );
}
