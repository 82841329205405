import * as React from 'react';
import * as lodash from 'lodash';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';

import { StoreState } from '@store';
import {
    ChangeList,
    TableLine,
    getBudgetExecutionPageState,
    getUnsavedChanges,
    getFilteredTableLines,
    setLineIdsWithActualChanges,
} from '@store/budgetExecution';

interface Props extends Partial<MapProps & DispatchProps> {}

interface MapProps {
    lineIdsWithActualChanges: string[];
    unsavedChanges: ChangeList;
    filteredTableLines: TableLine[];
}

interface DispatchProps {
    setLineIdsWithActualChanges: (lineIdsWithActualChanges: string[]) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class LineIdsWithActualChangesEffect extends React.PureComponent<Props> {
    public componentDidMount(): void {
        this.tryToUpdateData();
    }

    public componentDidUpdate(): void {
        this.tryToUpdateData();
    }

    public render(): JSX.Element {
        // component used only for side-effect
        return null;
    }

    private tryToUpdateData(): void {
        const { unsavedChanges, filteredTableLines, lineIdsWithActualChanges } = this.props;

        const updLineIdsWithActualChanges = Object.keys(unsavedChanges).filter(
            (changesGroupLineId) =>
                !!unsavedChanges[changesGroupLineId].length &&
                filteredTableLines.some((line) => line.id === changesGroupLineId),
        );

        if (!lodash.isEqual(lineIdsWithActualChanges, updLineIdsWithActualChanges)) {
            this.props.setLineIdsWithActualChanges(updLineIdsWithActualChanges);
        }
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        lineIdsWithActualChanges: getBudgetExecutionPageState(state).computedData.lineIdsWithActualChanges,
        unsavedChanges: getUnsavedChanges(state),
        filteredTableLines: getFilteredTableLines(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        setLineIdsWithActualChanges: (lineIdsWithActualChanges: string[]) =>
            dispatch(setLineIdsWithActualChanges(lineIdsWithActualChanges)),
    };
}
