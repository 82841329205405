// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetPlanning-Table-TableBody-_TableBody__root{position:relative;display:inline-block;min-width:100%}.src-client-modules-budget-BudgetPage-BudgetPlanning-Table-TableBody-_TableBody__frame{position:absolute;left:0;min-width:100%}.src-client-modules-budget-BudgetPage-BudgetPlanning-Table-TableBody-_TableBody__line{height:38px;border-bottom:1px solid #dbdbdb}.src-client-modules-budget-BudgetPage-BudgetPlanning-Table-TableBody-_TableBody__line:last-child{border-bottom:0}.src-client-modules-budget-BudgetPage-BudgetPlanning-Table-TableBody-_TableBody__totalLine{margin-bottom:10px}.src-client-modules-budget-BudgetPage-BudgetPlanning-Table-TableBody-_TableBody__totalLine:last-child{margin-bottom:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetPlanning/Table/TableBody/TableBody.scss"],"names":[],"mappings":"AAAA,sFACI,iBAAkB,CAElB,oBAAqB,CAErB,cAAe,CAClB,uFAGG,iBAAkB,CAClB,MAAO,CAEP,cAAe,CAClB,sFAGG,WAAY,CAEZ,+BAAgC,CAHpC,iGAMQ,eAAgB,CACnB,2FAID,kBAAmB,CADvB,sGAIQ,eAAgB","sourcesContent":[".root {\n    position: relative;\n\n    display: inline-block;\n\n    min-width: 100%;\n}\n\n.frame {\n    position: absolute;\n    left: 0;\n\n    min-width: 100%;\n}\n\n.line {\n    height: 38px;\n\n    border-bottom: 1px solid #dbdbdb;\n\n    &:last-child {\n        border-bottom: 0;\n    }\n}\n\n.totalLine {\n    margin-bottom: 10px;\n\n    &:last-child {\n        margin-bottom: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetPlanning-Table-TableBody-_TableBody__root",
	"frame": "src-client-modules-budget-BudgetPage-BudgetPlanning-Table-TableBody-_TableBody__frame",
	"line": "src-client-modules-budget-BudgetPage-BudgetPlanning-Table-TableBody-_TableBody__line",
	"totalLine": "src-client-modules-budget-BudgetPage-BudgetPlanning-Table-TableBody-_TableBody__totalLine"
};
export default ___CSS_LOADER_EXPORT___;
