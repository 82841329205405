import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import {
    getFilters,
    getChildrenOfSelectedStatisticsItem,
    getStatisticsItems,
    getSelectedStatisticsItem,
    setFilters,
} from '@store/leaderDashboardPage';

import { UseStore, Filters } from './types';

export const useStore: UseStore = ({ mode }) => {
    const dispatch = useDispatch();
    const { filters, childrenOfSelectedStatisticsItem, statisticsItems, selectedStatisticsItem } = useSelector(
        (state: StoreState) => {
            return {
                filters: getFilters(state),
                selectedStatisticsItem: getSelectedStatisticsItem(state),
                childrenOfSelectedStatisticsItem: getChildrenOfSelectedStatisticsItem(state),
                statisticsItems: getStatisticsItems(state),
            };
        },
    );

    return {
        filters,
        selectedStatisticsItem,
        statisticsItems,
        childrenOfSelectedStatisticsItem,
        setFilters: React.useCallback((filters: Filters) => {
            dispatch(
                setFilters({
                    filters,
                    mode,
                }),
            );
        }, []),
    };
};
