import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { Tag } from '@mrm/tags';

import { MrmClient } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';

import * as asyncActions from './actions/async';
import * as actions from './actions/sync';

import { clientToStoreColor, clientToStoreEmoji, storeToClientEmoji } from './misc';
import { UpdateExistingTagPayload } from './types';
import { getTagsState } from './selectors';

export const loadTags = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.loadTags,
    async (_, dispatch, getState) => {
        if (getTagsState(getState()).loadingStatus === LoadingStatus.NOT_LOADED) {
            try {
                dispatch(actions.setLoadingStatus(LoadingStatus.LOADING));

                const client = await MrmClient.getInstance();

                const tags: Tag[] = (await client.Dictionary.getByType('tag')).map((dictionary) => {
                    const { id, value } = dictionary;
                    const data = dictionary.getData();

                    const color = clientToStoreColor(data?.color);
                    const emoji = clientToStoreEmoji(data?.picture);

                    return {
                        id,
                        title: value,
                        color,
                        emoji,
                    };
                });

                dispatch(actions.setTags(tags));
                dispatch(actions.setLoadingStatus(LoadingStatus.LOADED));
            } catch (e) {
                dispatch(actions.setLoadingStatus(LoadingStatus.ERROR));
                throw e;
            }
        }
    },
);

export const createTag = bindThunkAction<StoreState, Tag, void, Error>(
    asyncActions.createTag,
    async (tag, dispatch, getState) => {
        const picture = storeToClientEmoji(tag.emoji);
        const params: any = {
            tagId: tag.id,
            name: tag.title,
        };
        if (tag.color) {
            params.color = tag.color;
        }
        if (picture) {
            params.picture = picture;
        }

        const client = await MrmClient.getInstance();
        await client.api.tags.createTag(params);

        dispatch(actions.addTag(tag));
    },
);

export const updateExistingTag = bindThunkAction<StoreState, UpdateExistingTagPayload, void, Error>(
    asyncActions.updateExistingTag,
    async (updTag, dispatch, getState) => {
        const existingTag = getTagsState(getState()).byId.dictionary[updTag.id];

        if (!existingTag) {
            console.warn(`Trying to update existing tag with id ${updTag.id}, but it's not fount in store`);
        } else {
            const client = await MrmClient.getInstance();
            const tag = { ...existingTag, ...updTag };

            await client.api.tags.updateTag({
                id: tag.id,
                color: tag.color,
                picture: storeToClientEmoji(tag.emoji),
            });

            dispatch(actions.updateExistingTag(tag));
        }
    },
);
