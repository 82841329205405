// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionNegativeLineBalanceWarning-_InternalTransitionNegativeLineBalanceWarning__root{padding-left:4px}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionNegativeLineBalanceWarning-_InternalTransitionNegativeLineBalanceWarning__title{margin-top:12px;font-family:'Open Sans';font-size:14px;line-height:19px;letter-spacing:0.15px;color:#000000}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/InternalTransitionNegativeLineBalanceWarning/InternalTransitionNegativeLineBalanceWarning.scss"],"names":[],"mappings":"AAAA,0KACI,gBAAiB,CACpB,2KAGG,eAAgB,CAEhB,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACjB,qBAAsB,CAEtB,aAAc","sourcesContent":[".root {\n    padding-left: 4px;\n}\n\n.title {\n    margin-top: 12px;\n\n    font-family: 'Open Sans';\n    font-size: 14px;\n    line-height: 19px;\n    letter-spacing: 0.15px;\n\n    color: #000000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionNegativeLineBalanceWarning-_InternalTransitionNegativeLineBalanceWarning__root",
	"title": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionNegativeLineBalanceWarning-_InternalTransitionNegativeLineBalanceWarning__title"
};
export default ___CSS_LOADER_EXPORT___;
