import { Autopilots } from '@modules/autopilot/types';

export interface AutopilotName {
    id: Autopilots;
    name: string;
}

export const autopilotNames: AutopilotName[] = [
    {
        id: 'autopilot_v2',
        name: 'Digital & Performance',
    },
    {
        id: 'autopilot',
        name: 'Продвижение имиджевых роликов',
    },
    {
        id: 'autopilotTv',
        name: 'Медиапланирование TV',
    },
    {
        id: 'autopilotRadio',
        name: 'Медиапланирование Радио',
    },
    {
        id: 'autopilotDOOH',
        name: 'Медиапланирование Цифровая Наружная Реклама',
    },
];
