import { useState, useCallback, useEffect } from 'react';
import { includes } from 'lodash';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';
import { getCurrentStage } from '@store/creative';
import type { CreativeRequestSubject } from '@store/creative';

import { useCreativeRequestDomain } from './useCreativeRequestDomain';

interface UseVisibility {
    (): UseVisibilityReturn;
}

interface UseVisibilityReturn {
    isVisibility: boolean;
}

interface OnInitiatorsUpdated {
    (initiators: CreativeRequestSubject[]): void;
}

interface LoadInitiators {
    (): Promise<void>;
}

export const useVisibility: UseVisibility = () => {
    const [isVisibility, setVisibility] = useState<boolean>(false);
    const [agencyExecutorIds, setAgencyExecutorIds] = useState<number[]>([]);

    const { creativeRequestId } = useParams<{ creativeRequestId: string }>();
    const {
        attributes: { id: userId },
    } = useSelector((state: StoreState) => getLoginUser(state));
    const currentStage = useSelector((state: StoreState) => getCurrentStage(state));
    const { creativeRequestDomain } = useCreativeRequestDomain({ creativeRequestId });

    const loadAgencyExecutor: LoadInitiators = useCallback(async () => {
        const agencyExecutors = await creativeRequestDomain?.model?.getAgencyExecutors();
        setAgencyExecutorIds(agencyExecutors.map(({ id }) => id));
    }, [creativeRequestDomain]);

    const onAgencyExecutorsUpdated: OnInitiatorsUpdated = useCallback((agencyExecutors) => {
        setAgencyExecutorIds(agencyExecutors.map(({ id }) => id));
    }, []);

    useEffect(() => {
        const isCurrentUserAgencyExecutor = includes(agencyExecutorIds, userId);
        const isTargetCurrentStage = currentStage >= 1.5;
        const haveAccessToBudgetItems = !!creativeRequestDomain?.model?.getBudgetItems;

        !isCurrentUserAgencyExecutor && isTargetCurrentStage && haveAccessToBudgetItems
            ? setVisibility(true)
            : setVisibility(false);
    }, [agencyExecutorIds, userId, creativeRequestDomain]);

    useEffect(() => {
        if (creativeRequestDomain) {
            loadAgencyExecutor();
        }
    }, [creativeRequestDomain]);

    useEffect(() => {
        creativeRequestDomain?.events?.onAgencyExecutorsUpdated(onAgencyExecutorsUpdated);
        return () => creativeRequestDomain?.events?.onAgencyExecutorsUpdated(onAgencyExecutorsUpdated);
    }, [creativeRequestDomain]);

    return { isVisibility };
};
