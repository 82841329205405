import type { TabBarConfig } from './types';
import { Color } from './types';

export const tabBarActivityConfig: TabBarConfig = [
    {
        name: 'Проср. задачи',
        color: Color.RED,
    },
    {
        name: 'Проср. этапы',
        color: Color.MUSTARD,
    },
    {
        name: 'Активные',
        color: Color.GREEN,
    },
    {
        name: 'План. запуск',
        color: Color.BLUE,
    },
];
