import * as React from 'react';
import { useParams } from 'react-router-dom';

import type {
    CreativeRequestTenderDocument,
    CreativeRequestTenderDocumentProtocolFile,
    CreativeRequestDomain,
} from '@store/creative/types';
import { CreativeRequestTenderDocumentStatus } from '@store/creative/types';

import { MrmClient } from '@api';

interface UseStore {
    (): UseStoreReturn;
}

interface UseStoreReturn {
    loading: boolean;
    tenderDocumentDomain: CreativeRequestTenderDocument;
}

interface OnReloaded {
    (updatedCreativeRequestDomain: CreativeRequestDomain): void;
}

export const useStore: UseStore = () => {
    const [creativeRequestDomain, setCreativeRequestDomain] = React.useState<CreativeRequestDomain>(null);
    const [tenderDocumentDomain, setTenderDocumentDomain] = React.useState<CreativeRequestTenderDocument>(null);

    const [loading, setLoading] = React.useState<boolean>(true);

    const { creativeRequestId } = useParams();

    const loadCreativeRequestDomain = React.useCallback(async () => {
        const client = await MrmClient.getInstance();
        const creativeRequestDomain = await client.domain.creativeRequests.getCreativeRequest({
            id: creativeRequestId,
        });

        setCreativeRequestDomain(creativeRequestDomain);
    }, [creativeRequestId]);

    const onReloaded: OnReloaded = React.useCallback(() => {
        loadCreativeRequestDomain();
    }, []);

    React.useEffect(() => {
        loadCreativeRequestDomain();
    }, []);

    React.useEffect(() => {
        if (creativeRequestDomain) {
            creativeRequestDomain.model.getTenderDocument().then((tenderDocumentDomain) => {
                setTenderDocumentDomain(tenderDocumentDomain);
                setLoading(false);
            });
        }

        creativeRequestDomain?.events?.onReloaded(onReloaded);

        return () => {
            creativeRequestDomain?.events?.offReloaded(onReloaded);
        };
    }, [creativeRequestDomain]);

    return {
        loading,
        tenderDocumentDomain,
    };
};

export type { CreativeRequestTenderDocument, CreativeRequestTenderDocumentProtocolFile };
export { CreativeRequestTenderDocumentStatus };
