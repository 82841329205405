import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconType, BodyPortal } from 'sber-marketing-ui';

import { OrderType, ColumnFilters, CellValueType, ColumnName } from '@store/budgetExecution/types';

import * as style from './HeaderDropdownCell.scss';

import { FilterMenu } from '../../../FilterMenu';

export interface HeaderDropdownCellProps {
    name: ColumnName;
    title: string;
    isHovered: boolean;
    isOpened: boolean;
    orderType: OrderType;
    isFiltered: boolean;
    isResizingColumn: boolean;
    isColumnFixed: boolean;
    onColumnFixChange: () => void;
    onOpenerClick: () => void;
    onMaskClick: () => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}

export const HeaderDropdownCell = ({
    name,
    title,
    isHovered,
    isOpened,
    orderType,
    isFiltered,
    isResizingColumn,
    isColumnFixed,
    onColumnFixChange,
    onOpenerClick,
    onMaskClick,
    onMouseEnter,
    onMouseLeave,
}: HeaderDropdownCellProps): JSX.Element => {
    return (
        <div
            title={title}
            className={classNames(
                style.root,
                isOpened && style.opened,
                !!orderType && style.sorted,
                isFiltered && style.filtered,
            )}
            onMouseEnter={() => onMouseEnter()}
            onMouseLeave={() => onMouseLeave()}
        >
            <div
                className={classNames(style.opener, isResizingColumn && style.openerResizingColumn)}
                onClick={onOpenerClick}
                {...{
                    'qa-id': 'tableHeaderOpenButton',
                    'qa-column-label': title,
                    'qa-column-id': name,
                }}
            >
                <div className={style.title}>{title}</div>

                <div className={style.arrowIcon}>
                    <Icon type={isOpened ? IconType.ARROW20_UP : IconType.ARROW20_DOWN} />
                </div>
            </div>

            {isOpened && (
                <BodyPortal>
                    <div className={style.mask} onClick={onMaskClick} />
                </BodyPortal>
            )}
        </div>
    );
};

export interface DropdownContentProps {
    columnName: ColumnName;
    filters: ColumnFilters;
    currencySearch: boolean;
    columnValueType: CellValueType;
    orderType: OrderType;
    isColumnFixed: boolean;
    onSortingChange: (sortingOrder: OrderType) => void;
    onSelectionChange: (filters: ColumnFilters) => void;
    onColumnFixChange: (checked: boolean) => void;
    onApplyFiltersButtonClick: () => void;
}

export const DropdownContent = ({
    columnName,
    filters,
    currencySearch,
    columnValueType,
    orderType,
    isColumnFixed,
    onSortingChange,
    onSelectionChange,
    onColumnFixChange,
    onApplyFiltersButtonClick,
}: DropdownContentProps): JSX.Element => {
    const filterNamesAreNumeral = columnValueType == CellValueType.Number || columnValueType == CellValueType.Currency;

    return (
        <div className={style.filterMenu}>
            <FilterMenu
                userConfigFilters={filters}
                columnName={columnName}
                filterNamesAreNumeral={filterNamesAreNumeral}
                sortingOrder={orderType}
                isColumnFixed={isColumnFixed}
                onSortingChange={onSortingChange}
                onSelectionChange={onSelectionChange}
                onColumnFixChange={onColumnFixChange}
                onApplyFiltersButtonClick={onApplyFiltersButtonClick}
                currencySearch={currencySearch}
            />
        </div>
    );
};
