import { flatten, compact, uniq } from 'lodash';

import type { CreativeRequestItem, Column, BudgetItem } from './types';
import {
    calculateSourceFoundsSum,
    calculateApprovedFactExcludingVatSum,
    calculateActualCostExcludingVatSum,
    calculateDelta,
    buildCompleteCreativeRequestItems,
    formatMoneyToRubles,
    filterBudgetItemsOwnedByCreativeRequest,
} from './utils';
import * as React from 'react';
import { Icon, IconType } from 'sber-marketing-ui';

interface UseTableColumns {
    (params: UseTableColumnsParams): UseTableColumnsReturn;
}

interface UseTableColumnsParams {
    budgetItems: BudgetItem[];
    creativeRequestItems: CreativeRequestItem[];
}

interface UseTableColumnsReturn {
    columns: Column[];
}

export const useTableColumns: UseTableColumns = ({ budgetItems, creativeRequestItems }) => {
    const completeCreativeRequestItems = buildCompleteCreativeRequestItems(creativeRequestItems);
    const budgetItemsOfCreativeRequestItems = filterBudgetItemsOwnedByCreativeRequest({
        creativeRequestItems: completeCreativeRequestItems,
        budgetItems,
    });
    const sourceFoundsSum = calculateSourceFoundsSum({ budgetItems: budgetItemsOfCreativeRequestItems });
    const approvedFactExcludingVatSum = calculateApprovedFactExcludingVatSum({
        creativeRequestItems: completeCreativeRequestItems,
    });
    const actualCostExcludingVatSum = calculateActualCostExcludingVatSum({
        creativeRequestItems,
    });
    const delta = calculateDelta({ sourceFoundsSum, actualCostExcludingVatSum });

    const sourceIdValue = uniq(
        compact(
            flatten(completeCreativeRequestItems.map(({ donors }) => donors.map(({ serialNumber }) => serialNumber))),
        ),
    ).join(', ');

    const creativeRequestSerialNumbers = completeCreativeRequestItems
        .map(({ serialNumber }) => serialNumber)
        .join(', ');

    const getDelta = () => {
        const value: string = formatMoneyToRubles(delta);
        if (value.includes('-')) {
            return (
                <span>
                    <span style={{ paddingRight: '10px' }}>
                        <Icon color="orange" type={IconType.WARNING_IN_CIRCLE} svgSize={16} />
                    </span>
                    <span>{value}</span>
                </span>
            );
        } else {
            return value;
        }
    };

    return {
        columns: [
            {
                name: 'SourceId',
                title: 'ID источника',
                value: sourceIdValue || '—',
                type: 'text',
            },
            {
                name: 'SourceFounds',
                title: 'Средства в ID источниках',
                value: formatMoneyToRubles(sourceFoundsSum),
                type: 'money',
            },
            {
                name: 'CreativeItems',
                title: 'Строки ЗНК',
                value: creativeRequestSerialNumbers || '—',
                type: 'text',
            },
            {
                name: 'ApprovedFactExcludingVat',
                title: 'Согласованная факт. стоимость без НДС',
                value: formatMoneyToRubles(approvedFactExcludingVatSum),
                type: 'money',
            },
            {
                name: 'ActualCostExcludingVat',
                title: 'Фактическая стоимость + комиссия без НДС',
                value: formatMoneyToRubles(actualCostExcludingVatSum),
                type: 'money',
            },
            {
                name: 'Delta',
                title: 'Дельта',
                value: getDelta(),
                type: 'money',
            },
        ],
    };
};
