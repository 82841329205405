import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StyledPopup } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { DATA_SHEET_TITLE } from '@store/budgetPlanning/thunks/xlsxImportExport/types';
import { XLSXImportValidationError, getBudgetPlanningPageState, setXLSXImportErrorState } from '@store/budgetPlanning';

export function XLSXImportErrorPopup(): JSX.Element {
    const error = useSelector((state: StoreState) => getBudgetPlanningPageState(state).xlsxImportErrorState.error);
    const fileName = useSelector(
        (state: StoreState) => getBudgetPlanningPageState(state).xlsxImportErrorState.fileName,
    );

    switch (error) {
        case XLSXImportValidationError.WrongFileType:
            return <WrongFileTypePopup fileName={fileName} />;
        case XLSXImportValidationError.MissingDataWorkbook:
            return <MissingDataWorkbookPopup fileName={fileName} />;
        case XLSXImportValidationError.MissingColumns:
            return <MissingColumnsPopup fileName={fileName} />;
        case XLSXImportValidationError.ContainsRowsWithNonFilledRequiredFields:
            return <ContainsRowsWithNonFilledRequiredFieldsPopup fileName={fileName} />;
        case XLSXImportValidationError.None:
        default:
            return null;
    }
}

interface ErrorPopupProps {
    message: string;
}

function ErrorPopup({ message }: ErrorPopupProps): JSX.Element {
    const dispatch = useDispatch();

    function popupButtonClick() {
        dispatch(
            setXLSXImportErrorState({
                error: XLSXImportValidationError.None,
            }),
        );
    }

    return (
        <StyledPopup
            title="Ошибка при загрузке строк"
            fullMessage={message}
            firstButtonText="ОК"
            firstButtonHandler={popupButtonClick}
        />
    );
}

interface ErrorStatePopupProps {
    fileName: string;
}

function WrongFileTypePopup({ fileName }: ErrorStatePopupProps): JSX.Element {
    return <ErrorPopup message={`Файл ${fileName} не является EXCEL-файлом`} />;
}

function MissingDataWorkbookPopup({ fileName }: ErrorStatePopupProps): JSX.Element {
    return (
        <ErrorPopup
            message={`В загружаемом документе ${fileName} отсутствует вкладка с названием "${DATA_SHEET_TITLE}"`}
        />
    );
}

function MissingColumnsPopup({ fileName }: ErrorStatePopupProps): JSX.Element {
    const missingColumns = useSelector(
        (state: StoreState) => getBudgetPlanningPageState(state).xlsxImportErrorState.missingColumns,
    );

    let message: string;
    if (missingColumns.length === 1) {
        message = `В загружаемом документе ${fileName} отсутствует колонка: ${missingColumns[0]}`;
    } else if (missingColumns.length <= 3) {
        message = `В загружаемом документе ${fileName} отсутствуют колонки: ${missingColumns.join(', ')}`;
    } else {
        message = `В загружаемом документе ${fileName} отсутвуют обязательные колонки. Пожалуйста используйте шаблон для внесенния данных сгенерированный МРМ.`;
    }

    return <ErrorPopup message={message} />;
}

function ContainsRowsWithNonFilledRequiredFieldsPopup({ fileName }: ErrorStatePopupProps): JSX.Element {
    return (
        <ErrorPopup
            message={`В загружаемом документе ${fileName} присутствуют строки с незаполненными полями "Активность" и "Название Проекта"`}
        />
    );
}
