import * as React from 'react';
import * as lodash from 'lodash';

import * as style from './CreativeTable.scss';

import type { CellPosition, ColumnName, ColumnWidths, Filter, LineId, CustomFilter } from './types';

import { CellsStorage, TableView, Icon, IconType, CellEvent } from 'sber-marketing-ui';
import { FiltersList } from './FiltersList';
import { TableSettings } from './TableSettings';
import { SpecialFilters } from './SpecialFilters';
import { Preloader } from './Preloader';

interface Props {
    loading: boolean;
    lot: number;
    creativeCount: number;
    headerCellsStorage: CellsStorage<ColumnName, any>;
    tableCellsStorage: CellsStorage<CellPosition, any>;
    columns: ColumnName[];
    leftFixedColumns: ColumnName[];
    rightFixedColumns: ColumnName[];
    fixedWidthColumns: ColumnName[];
    visibleColumns: ColumnName[];
    lineIds: LineId[];
    columnWidths: ColumnWidths;
    statusFilterIsActive: boolean;
    filters: Filter[];
    customFilter: CustomFilter;
    tableHeight: number;
    tableRef: (component: TableView) => void;
    getFilters: () => Filter[];
    setFilters: (filters: Filter[]) => void;
    getSpecialFilter: () => CustomFilter;
    setSpecialFilter: (filter: CustomFilter) => void;
    onAddButtonClick: () => void;
    onFilterButtonClick: () => void;
    onCellEvent: (eventType: CellEvent, position: CellPosition) => void;
    onColumnWidthsChange: (columnWidths: ColumnWidths) => void;
    onVisibleColumnsChange: (visibleColumns: ColumnName[]) => void;
}

export const CreativeTableTemplate = ({
    loading,
    lot,
    creativeCount,
    headerCellsStorage,
    tableCellsStorage,
    columns,
    leftFixedColumns,
    rightFixedColumns,
    fixedWidthColumns,
    visibleColumns,
    lineIds,
    columnWidths,
    statusFilterIsActive,
    filters,
    customFilter,
    tableHeight,
    tableRef,
    getFilters,
    setFilters,
    getSpecialFilter,
    setSpecialFilter,
    onAddButtonClick,
    onFilterButtonClick,
    onCellEvent,
    onColumnWidthsChange,
    onVisibleColumnsChange,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            {!loading && (
                <>
                    <div className={style.topLine}>
                        <div className={style.title}>
                            Заявки на креатив - Лот {lot} <span className={style.counter}>({creativeCount})</span>
                        </div>

                        <div className={style.Specialfilters}>
                            <SpecialFilters setFilters={setFilters} setSpecialFilter={setSpecialFilter} />
                        </div>

                        <div className={style.filtersList}>
                            <FiltersList
                                filters={filters}
                                customFilter={customFilter}
                                setFilters={setFilters}
                                setSpecialFilter={setSpecialFilter}
                            />
                        </div>

                        <div className={style.filterButton} onClick={onFilterButtonClick}>
                            <div className={style.filterButtonIcon}>
                                <Icon
                                    type={statusFilterIsActive ? IconType.EYE_OPENED : IconType.EYE_CLOSED}
                                    svgSize={24}
                                />
                            </div>

                            {statusFilterIsActive ? 'Показать удаленные строки' : 'Скрыть удаленные строки'}
                        </div>

                        <div className={style.tableSettings}>
                            <TableSettings
                                columns={columns}
                                leftFixedColumns={leftFixedColumns}
                                rightFixedColumns={rightFixedColumns}
                                visibleColumns={visibleColumns}
                                onVisibleColumnsChange={onVisibleColumnsChange}
                            />
                        </div>
                    </div>

                    <div className={style.table}>
                        <TableView
                            ref={tableRef}
                            maxHeight={tableHeight}
                            headerCellsStorage={headerCellsStorage}
                            tableCellsStorage={tableCellsStorage}
                            columns={
                                lodash.isEmpty(visibleColumns) ? columns : lodash.intersection(columns, visibleColumns)
                            }
                            leftFixedColumns={
                                lodash.isEmpty(visibleColumns)
                                    ? leftFixedColumns
                                    : lodash.intersection(leftFixedColumns, visibleColumns)
                            }
                            rightFixedColumns={
                                lodash.isEmpty(visibleColumns)
                                    ? rightFixedColumns
                                    : lodash.intersection(rightFixedColumns, visibleColumns)
                            }
                            fixedWidthColumns={fixedWidthColumns}
                            lines={lineIds}
                            columnWidths={columnWidths}
                            lineHeight={50}
                            onCellEvent={onCellEvent}
                            onColumnWidthsChange={onColumnWidthsChange}
                        />
                    </div>

                    <div className={style.addButton} onClick={onAddButtonClick}>
                        <div className={style.addButtonIcon}>
                            <Icon type={IconType.PLUS_SIGN} svgSize={10} />
                        </div>
                        Добавить строку ЗНК - Лот {lot}
                    </div>
                </>
            )}

            {loading && <Preloader />}
        </div>
    );
};
