import * as React from 'react';
import classNames from 'classnames';
import * as moment from 'moment';

import * as style from './Datepicker.scss';

import {
    DatepickerContent,
    Icon,
    IconType,
    InputTheme_redesign as InputTheme,
    AutoFocusedInput_redesign as AutoFocusedInput,
    MASK_redesign as MASK,
} from 'sber-marketing-ui';

export const enum Direction {
    Up = 'up',
    Down = 'down',
}

interface Props {
    qaId?: string;
    date?: moment.Moment;
    maxDate?: moment.Moment;
    minDate?: moment.Moment;
    placeholder?: string;
    direction: Direction;
    isOpened: boolean;
    showInput?: boolean;
    disabled?: boolean;
    rootRef: React.RefObject<HTMLDivElement>;
    onDateChange: (date: moment.Moment) => void;
    onDateClick: () => void;
    onInputBlur: (event: any) => void;
    onResetButtonClick?: () => void;
    onIconClick?: () => void;
    onMaskClick?: () => void;
    openDatepicker?: () => void;
}

export function Datepicker({
    qaId,
    date,
    maxDate,
    minDate,
    placeholder,
    direction,
    isOpened,
    showInput,
    disabled,
    rootRef,
    onDateChange,
    onDateClick,
    onInputBlur,
    onIconClick,
    onResetButtonClick,
    onMaskClick,
}: Props): JSX.Element {
    const qaProps: any = {
        'qa-id': qaId,
    };

    const value = date
        ? `${date.format('D')} ${moment.monthsShort()[date.month()]} ${date.format('YY')}`
        : placeholder || '';

    return (
        <div className={style.root} ref={rootRef}>
            <div className={style.calendarIcon} onClick={!isOpened ? onIconClick : null}>
                <Icon type={IconType.CALENDAR_ICON} svgSize={16} />
            </div>

            {showInput ? (
                <div className={style.input}>
                    <AutoFocusedInput
                        qaId={qaId}
                        value={date ? date.format('DDMMYYYY') : ''}
                        placeholder={placeholder || ''}
                        mask={MASK.date}
                        onBlur={onInputBlur}
                        theme={InputTheme.Datepicker}
                    />
                </div>
            ) : (
                <div className={classNames(style.value, !date && style.placeholder)} onClick={onDateClick} {...qaProps}>
                    {value}
                </div>
            )}

            {isOpened && (
                <div
                    className={classNames(
                        style.datepickerContent,
                        direction === Direction.Up && style.directionUp,
                        direction === Direction.Down && style.directionDown,
                    )}
                >
                    <DatepickerContent date={date} maxDate={maxDate} minDate={minDate} onChange={onDateChange} />

                    <div className={style.mask} onClick={onMaskClick} />
                </div>
            )}

            {date && (
                <div className={style.resetButton} onClick={onResetButtonClick}>
                    <Icon type={IconType.PROJECT_STAGED_CLOSE} svgSize={24} />
                </div>
            )}
        </div>
    );
}
