import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { Dispatch, AnyAction } from 'redux';

import { HistoryApi } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { checkLoadingStatus } from '@store/profileMenu';

import * as asyncActions from './actions/async';
import * as actions from './actions/sync';

import { getProfileMenuNotificationsState } from './selectors';

async function withLoadingStatus(dispatch: Dispatch<AnyAction>, request: () => Promise<void>): Promise<void> {
    dispatch(actions.setLoadingStatus(LoadingStatus.LOADING));

    try {
        await request();
    } catch (e) {
        dispatch(actions.setLoadingStatus(LoadingStatus.ERROR));
        throw e;
    }

    dispatch(actions.setLoadingStatus(LoadingStatus.LOADED));
}

async function withRequestInProgress(dispatch: Dispatch<AnyAction>, request: () => Promise<void>): Promise<void> {
    dispatch(actions.setRequestInProgress(true));
    await request();
    dispatch(actions.setRequestInProgress(false));
}

export const loadNotificationsData = bindThunkAction<StoreState, void, void, Error>(
    asyncActions.loadNotificationsData,
    async (_, dispatch, getState) => {
        await withLoadingStatus(dispatch, async () => {
            const notifications = await HistoryApi.getSettings();
            dispatch(actions.setNotifications(notifications));
        });

        dispatch(checkLoadingStatus(null));
    },
);

export const saveUserNotifications = bindThunkAction<StoreState, void, void, Error>(
    asyncActions.saveUserNotifications,
    async (_, dispatch, getState) => {
        await withRequestInProgress(dispatch, async () => {
            const notifications = getProfileMenuNotificationsState(getState()).notifications;

            await HistoryApi.putSettings(notifications);
        });
    },
);
