import * as React from 'react';
import classNames from 'classnames';

import { Tab, TabProps } from '@common/components';

import * as styles from './Tabs.scss';

export interface TabsProps<K extends string = string>
    extends Omit<React.HTMLProps<HTMLDivElement>, 'selected' | 'onSelect'> {
    tabs: TabProps<K>[];
    selected: string;
    squeeze?: boolean;
    onSelect: (key: K) => void;
}

export function Tabs<K extends string = string>({
    className,
    tabs,
    selected,
    squeeze: squeezeTabs,
    onSelect,
    children,
    ...props
}: TabsProps<K>) {
    return (
        <div {...props} className={classNames(styles.root, className)}>
            {tabs.map(({ value, squeeze = squeezeTabs, ...props }) => (
                <Tab<K>
                    {...props}
                    squeeze={squeeze}
                    selected={selected === value}
                    value={value}
                    key={value}
                    onSelect={(value) => {
                        onSelect(value);
                        props.onSelect?.(value);
                    }}
                />
            ))}
            {children}
        </div>
    );
}
