import * as React from 'react';
import type { RejectedCorrectionWarningProps } from '../RejectedCorrectionWarning';
import { RejectedCorrectionWarning } from '../RejectedCorrectionWarning';

import * as style from './RejectedCorrectionWarningList.scss';

export type { RejectedCorrectionWarningProps };

interface Props {
    warnings: RejectedCorrectionWarningProps[];
}

export const RejectedCorrectionWarningList: React.FC<Props> = ({ warnings }) => {
    return (
        <div className={style.root}>
            {warnings.map((warning, index) => (
                <div key={index} className={style.lineWarningWrapper}>
                    <RejectedCorrectionWarning {...warning} />
                </div>
            ))}
        </div>
    );
};
