import autobind from 'autobind-decorator';
import { update } from './thunks';
import { Handlers } from './converters';

export class HandlerCreator {
    private dispatch: any = null;
    private budgetId: string = null;

    public setDispatch(dispatch: any): void {
        this.dispatch = dispatch;
    }

    public setBudgetId(budgetId: string): void {
        this.budgetId = budgetId;
    }

    public getHandlers(): Handlers {
        return {
            onBudgetItemsUpdated: this.onEventHandler,
            onBudgetItemsRemoved: this.onEventHandler,
            onBudgetItemsAdded: this.onEventHandler,
            onParamsAdded: this.onEventHandler,
            onParamsRemoved: this.onEventHandler,
            onDivisionUpdated: this.onEventHandler,
        };
    }

    @autobind
    private onEventHandler(): void {
        this.dispatch(update({ budgetId: this.budgetId }));
    }
}
