import * as React from 'react';
import autobind from 'autobind-decorator';
import { groupBy } from 'lodash';

import { CreativeRequestParamDomain, Dictionary, DictionaryType } from '../types';
import type { Permissions } from './types';
import { FieldType, ParamFormBehavior, OnFieldItemSelectParams } from './ParamFormBehavior';

interface Props {
    creativeRequestParamDomain: CreativeRequestParamDomain;
    dictionaries: Dictionary[];
    visibilityRemoveButton: boolean;
}

interface State {
    loading: boolean;
    selectedDictionaryIds: Record<FieldType, Dictionary['id']>;
}

export class ParamFormConnector extends React.Component<Props, State> {
    private dictionary: Record<FieldType, Dictionary[]> = {
        block: [],
        division: [],
        segment: [],
        product: [],
        channel: [],
    };

    public constructor(props: Props) {
        super(props);

        this.state = {
            loading: true,
            selectedDictionaryIds: {
                block: null,
                division: null,
                product: null,
                channel: null,
                segment: null,
            },
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.load();
        await this.subscribe();
        this.setState({ loading: false });
    }

    public render(): JSX.Element {
        const { visibilityRemoveButton } = this.props;
        const { loading, selectedDictionaryIds } = this.state;

        return React.createElement(ParamFormBehavior, {
            loading,
            selectedItemIds: selectedDictionaryIds,
            fieldItems: this.getFieldItems(),
            permissions: this.getPermissions(),
            visibilityRemoveButton,
            onFieldItemSelect: this.onFieldItemSelect,
            onRemove: this.onRemove,
        });
    }

    private async load(): Promise<void> {
        await this.loadSelectedDictionaryIds();
        await this.loadDictionaries();
    }

    private async loadSelectedDictionaryIds(): Promise<void> {
        const { creativeRequestParamDomain } = this.props;

        const [blockDictionary, segmentDictionary, channelDictionary, productDictionary, divisionDictionary] =
            await Promise.all([
                creativeRequestParamDomain.model.block,
                creativeRequestParamDomain.model.segment,
                creativeRequestParamDomain.model.channel,
                creativeRequestParamDomain.model.product,
                creativeRequestParamDomain.model.division,
            ]);

        this.setState({
            selectedDictionaryIds: {
                block: blockDictionary?.id || null,
                segment: segmentDictionary?.id || null,
                channel: channelDictionary?.id || null,
                product: productDictionary?.id || null,
                division: divisionDictionary?.id || null,
            },
        });
    }

    private async loadDictionaries(): Promise<void> {
        const { dictionaries, creativeRequestParamDomain } = this.props;

        const dictionariesMap = groupBy(dictionaries, 'type');
        const selectedBlockDictionary = await creativeRequestParamDomain.model.block;

        const [
            availableSegmentDictionary,
            availableChannelDictionary,
            availableDivisionDictionary,
            availableProductDictionary,
        ] = await Promise.all([
            selectedBlockDictionary?.getAvailable(DictionaryType.Segment),
            selectedBlockDictionary?.getAvailable(DictionaryType.Channel),
            selectedBlockDictionary?.getAvailable(DictionaryType.Division),
            selectedBlockDictionary?.getAvailable(DictionaryType.Product),
        ]);

        this.dictionary.block = dictionariesMap[DictionaryType.Block] || [];
        this.dictionary.division = availableDivisionDictionary || dictionariesMap[DictionaryType.Division] || [];
        this.dictionary.product = availableProductDictionary || [];
        this.dictionary.segment = availableSegmentDictionary || [];
        this.dictionary.channel = availableChannelDictionary || [];
    }

    @autobind
    private async onFieldItemSelect({ type, selectedItemId }: OnFieldItemSelectParams): Promise<void> {
        const { creativeRequestParamDomain, dictionaries } = this.props;

        const selectedDictionary = dictionaries.find(({ id }) => selectedItemId === id);

        switch (type) {
            case 'block':
                this.setState({
                    selectedDictionaryIds: {
                        ...this.state.selectedDictionaryIds,
                        block: selectedItemId || null,
                    },
                });
                await creativeRequestParamDomain.model.setBlock({ block: selectedDictionary || null });
                break;
            case 'product':
                this.setState({
                    selectedDictionaryIds: {
                        ...this.state.selectedDictionaryIds,
                        product: selectedItemId || null,
                    },
                });
                await creativeRequestParamDomain.model.setProduct({ product: selectedDictionary || null });
                break;
            case 'division':
                this.setState({
                    selectedDictionaryIds: {
                        ...this.state.selectedDictionaryIds,
                        division: selectedItemId || null,
                    },
                });
                await creativeRequestParamDomain.model.setDivision({ division: selectedDictionary || null });
                break;
            case 'channel':
                this.setState({
                    selectedDictionaryIds: {
                        ...this.state.selectedDictionaryIds,
                        channel: selectedItemId || null,
                    },
                });
                await creativeRequestParamDomain.model.setChannel({ channel: selectedDictionary || null });
                break;
            case 'segment':
                this.setState({
                    selectedDictionaryIds: {
                        ...this.state.selectedDictionaryIds,
                        segment: selectedItemId || null,
                    },
                });
                await creativeRequestParamDomain.model.setSegment({ segment: selectedDictionary || null });
                break;
        }
    }

    @autobind
    private onRemove(): void {
        this.props.creativeRequestParamDomain.model.remove();
    }

    private async subscribe(): Promise<void> {
        const { creativeRequestParamDomain } = this.props;

        creativeRequestParamDomain.events.onBlockUpdated(this.onBlockUpdatedHandler);
        creativeRequestParamDomain.events.onDivisionUpdated(this.onDivisionUpdatedHandler);
        creativeRequestParamDomain.events.onSegmentUpdated(this.onSegmentUpdatedHandler);
        creativeRequestParamDomain.events.onProductUpdated(this.onProductUpdatedHandler);
        creativeRequestParamDomain.events.onChannelUpdated(this.onChannelUpdatedHandler);
    }

    @autobind
    private async onBlockUpdatedHandler(): Promise<void> {
        const { dictionaries, creativeRequestParamDomain } = this.props;
        const selectedDomainDictionary = await creativeRequestParamDomain.model.block;

        const dictionariesMap = groupBy(dictionaries, 'type');

        const [
            availableSegmentDictionary,
            availableChannelDictionary,
            availableDivisionDictionary,
            availableProductDictionary,
        ] = await Promise.all([
            selectedDomainDictionary?.getAvailable(DictionaryType.Segment),
            selectedDomainDictionary?.getAvailable(DictionaryType.Channel),
            selectedDomainDictionary?.getAvailable(DictionaryType.Division),
            selectedDomainDictionary?.getAvailable(DictionaryType.Product),
        ]);

        this.dictionary.segment = availableSegmentDictionary || [];
        this.dictionary.channel = availableChannelDictionary || [];
        this.dictionary.division = availableDivisionDictionary || dictionariesMap[DictionaryType.Division] || [];
        this.dictionary.product = availableProductDictionary || [];

        this.setState({
            selectedDictionaryIds: {
                ...this.state.selectedDictionaryIds,
                block: selectedDomainDictionary?.id || null,
            },
        });
    }

    @autobind
    private async onDivisionUpdatedHandler(): Promise<void> {
        const { creativeRequestParamDomain } = this.props;
        const selectedDivisionDictionary = await creativeRequestParamDomain.model.division;

        this.setState({
            selectedDictionaryIds: {
                ...this.state.selectedDictionaryIds,
                division: selectedDivisionDictionary?.id || null,
            },
        });
    }

    @autobind
    private async onSegmentUpdatedHandler(): Promise<void> {
        const { creativeRequestParamDomain } = this.props;
        const dictionary = await creativeRequestParamDomain.model.segment;

        this.setState({
            selectedDictionaryIds: {
                ...this.state.selectedDictionaryIds,
                segment: dictionary?.id || null,
            },
        });
    }

    @autobind
    private async onProductUpdatedHandler(): Promise<void> {
        const { creativeRequestParamDomain } = this.props;
        const dictionary = await creativeRequestParamDomain.model.product;

        this.setState({
            selectedDictionaryIds: {
                ...this.state.selectedDictionaryIds,
                product: dictionary?.id || null,
            },
        });
    }

    @autobind
    private async onChannelUpdatedHandler(): Promise<void> {
        const { creativeRequestParamDomain } = this.props;
        const dictionary = await creativeRequestParamDomain.model.channel;

        this.setState({
            selectedDictionaryIds: {
                ...this.state.selectedDictionaryIds,
                channel: dictionary?.id || null,
            },
        });
    }

    private getFieldItems() {
        return {
            block: this.buildItemsByType('block'),
            segment: this.buildItemsByType('segment'),
            channel: this.buildItemsByType('channel'),
            product: this.buildItemsByType('product'),
            division: this.buildItemsByType('division'),
        };
    }

    private getPermissions(): Permissions {
        const { creativeRequestParamDomain } = this.props;

        return {
            canChangeBlock: !!creativeRequestParamDomain?.model?.setBlock,
            canChangeSegment: !!creativeRequestParamDomain?.model?.setSegment,
            canChangeChannel: !!creativeRequestParamDomain?.model?.setChannel,
            canChangeProduct: !!creativeRequestParamDomain?.model?.setProduct,
            canChangeDivision: !!creativeRequestParamDomain?.model?.setDivision,
            canRemove: !!creativeRequestParamDomain?.model?.remove,
        };
    }

    private buildItemsByType(type: FieldType) {
        return this.dictionary[type].map(({ id, value, code }) => ({
            id,
            code,
            name: value || null,
        }));
    }
}
