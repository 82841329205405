// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-common-Params-_Params__root{font-family:\"Open Sans\"}.src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-common-Params-_Params__param{letter-spacing:0.15px;color:#000000;font-size:12px;line-height:16px}.src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-common-Params-_Params__paramName{display:inline-block;color:#7e8681}.src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-common-Params-_Params__paramValue{display:inline-block}\n", "",{"version":3,"sources":["webpack://./src/client/modules/dashboard/RightSidebar/BudgetInfo/Corrections/templates/common/Params/Params.scss"],"names":[],"mappings":"AAAA,wGACI,uBAAwB,CAC3B,yGAGG,qBAAsB,CAEtB,aAAc,CAEd,cAAe,CACf,gBAAiB,CACpB,6GAGG,oBAAqB,CAErB,aAAc,CACjB,8GAGG,oBAAqB","sourcesContent":[".root {\n    font-family: \"Open Sans\";\n}\n\n.param {\n    letter-spacing: 0.15px;\n\n    color: #000000;\n\n    font-size: 12px;\n    line-height: 16px;\n}\n\n.paramName {\n    display: inline-block;\n\n    color: #7e8681;\n}\n\n.paramValue {\n    display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-common-Params-_Params__root",
	"param": "src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-common-Params-_Params__param",
	"paramName": "src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-common-Params-_Params__paramName",
	"paramValue": "src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-common-Params-_Params__paramValue"
};
export default ___CSS_LOADER_EXPORT___;
