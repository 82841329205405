import * as React from 'react';

import * as style from './BudgetPlanning.scss';

import { ColumnName, BudgetItemTypes, BudgetItemApproversToDisplay } from '@store/budgetPlanning/types';

import { OpacityTransition, Preloader, FilterItem } from 'sber-marketing-ui';
import { TopMenu } from './TopMenu';
import { LineModal } from './LineModal';
import { Table } from './Table';
import { XLSXImportErrorPopup } from './XLSXImportErrorPopup';

interface Props {
    dictionariesOrganizationId: string;
    budgetId: string;
    preloader: boolean;
    localPreloader: boolean;
    userIsSupervisor: boolean;
    pageContentHeight: number;
    tableOffsetTop: number;
    scrollLeftColumn: ColumnName;
    maxTableHeight: number;
    displayOnlyUnapproved: boolean;
    displayOnlyWithSnapshots: boolean;
    displayDisabledLines: boolean;
    sendButtonIsDisabled: boolean;
    showLineModal: boolean;
    rootRef: (element: HTMLDivElement) => void;
    tableRef: (element: HTMLDivElement) => void;
    tableContentRef: (element: Table) => void;
    onResetPageFiltersButtonClick: () => void;
    onResetPageViewSettingsButtonClick: () => void;
    onDisplayOnlyUnapprovedButtonClick: () => void;
    onDisplayOnlyWithSnapshotsButtonClick: () => void;
    onDisplayDisabledLinesButtonClick: () => void;
    onSaveButtonClick: () => void;
    onSendToApprovementButtonClick: () => void;
    onDownloadXLSXButtonClick: (useFitlers: boolean) => Promise<void>;
    onUndoButtonClick: () => void;
    disableUndoButton: boolean;
    onRedoButtonClick: () => void;
    disableRedoButton: boolean;
    disableResetPageViewSettingsButton: boolean;
    onResetChangesHistoryClick: () => void;
    disableResetChangesHistoryButton: boolean;
    columnsFilterItems: FilterItem[];
    selectedColumnsFilterItems: string[];
    onColumnsFilterItemSelect: (selectedItemIds: React.ReactText[]) => void;
    budgetItemsToDisplay: BudgetItemTypes;
    budgetItemsApproversToDisplay: BudgetItemApproversToDisplay;
    onBudgetItemsToDisplayChange: (budgetItemsToDisplayIds: React.ReactText[]) => void;
    areFiltersApplies: boolean;
    onApplyFiltersButtonClick: () => void;
    isSaveButtonDisabled: boolean;
    onDownloadXLSXTemplateButtonClick: () => void;
    onLoadXLSXContentFileInput: (files: File[]) => void;
    userCanEditOrCreateBudgetData: boolean;
    showOnlyLinesWithPlanBudget: boolean;
    onShowOnlyLinesWithPlanBudgetToggle: () => void;
    showOnlyLinesWithoutPlanBudget: boolean;
    onShowOnlyLinesWithoutPlanBudgetToggle: () => void;
}

export function BudgetPlanning({
    dictionariesOrganizationId,
    budgetId,
    preloader,
    localPreloader,
    userIsSupervisor,
    pageContentHeight,
    tableOffsetTop,
    maxTableHeight,
    displayOnlyUnapproved,
    displayOnlyWithSnapshots,
    displayDisabledLines,
    scrollLeftColumn,
    sendButtonIsDisabled,
    showLineModal,
    rootRef,
    tableRef,
    tableContentRef,
    onResetPageFiltersButtonClick,
    onResetPageViewSettingsButtonClick,
    onDisplayOnlyUnapprovedButtonClick,
    onDisplayOnlyWithSnapshotsButtonClick,
    onDisplayDisabledLinesButtonClick,
    onSaveButtonClick,
    onSendToApprovementButtonClick,
    onDownloadXLSXButtonClick,
    onUndoButtonClick,
    disableUndoButton,
    onRedoButtonClick,
    disableRedoButton,
    disableResetPageViewSettingsButton,
    onResetChangesHistoryClick,
    disableResetChangesHistoryButton,
    columnsFilterItems,
    selectedColumnsFilterItems,
    onColumnsFilterItemSelect,
    budgetItemsToDisplay,
    budgetItemsApproversToDisplay,
    onBudgetItemsToDisplayChange,
    areFiltersApplies,
    onApplyFiltersButtonClick,
    isSaveButtonDisabled,
    onDownloadXLSXTemplateButtonClick,
    onLoadXLSXContentFileInput,
    userCanEditOrCreateBudgetData,
    showOnlyLinesWithPlanBudget,
    onShowOnlyLinesWithPlanBudgetToggle,
    showOnlyLinesWithoutPlanBudget,
    onShowOnlyLinesWithoutPlanBudgetToggle,
}: Props): JSX.Element {
    return (
        <OpacityTransition start={!preloader}>
            <div className={style.root} ref={rootRef}>
                <div className={style.topLine}>
                    <TopMenu
                        dictionariesOrganizationId={dictionariesOrganizationId}
                        budgetId={budgetId}
                        onUndoButtonClick={onUndoButtonClick}
                        disableUndoButton={disableUndoButton}
                        onRedoButtonClick={onRedoButtonClick}
                        disableRedoButton={disableRedoButton}
                        disableResetPageViewSettingsButton={disableResetPageViewSettingsButton}
                        userIsSupervisor={userIsSupervisor}
                        onSendToApprovementButtonClick={onSendToApprovementButtonClick}
                        sendButtonIsDisabled={sendButtonIsDisabled}
                        onDownloadXLSXButtonClick={onDownloadXLSXButtonClick}
                        onResetChangesHistoryClick={onResetChangesHistoryClick}
                        disableResetChangesHistoryButton={disableResetChangesHistoryButton}
                        columnsFilterItems={columnsFilterItems}
                        selectedColumnsFilterItems={selectedColumnsFilterItems}
                        onColumnsFilterItemSelect={onColumnsFilterItemSelect}
                        onSaveButtonClick={onSaveButtonClick}
                        onResetPageFiltersButtonClick={onResetPageFiltersButtonClick}
                        onResetPageViewSettingsButtonClick={onResetPageViewSettingsButtonClick}
                        displayOnlyUnapproved={displayOnlyUnapproved}
                        onDisplayOnlyUnapprovedButtonClick={onDisplayOnlyUnapprovedButtonClick}
                        displayOnlyWithSnapshots={displayOnlyWithSnapshots}
                        onDisplayOnlyWithSnapshotsButtonClick={onDisplayOnlyWithSnapshotsButtonClick}
                        displayDisabledLines={displayDisabledLines}
                        onDisplayDisabledLinesButtonClick={onDisplayDisabledLinesButtonClick}
                        budgetItemsToDisplay={budgetItemsToDisplay}
                        budgetItemsApproversToDisplay={budgetItemsApproversToDisplay}
                        onBudgetItemsToDisplayChange={onBudgetItemsToDisplayChange}
                        areFiltersApplied={areFiltersApplies}
                        onApplyFiltersButtonClick={onApplyFiltersButtonClick}
                        isSaveButtonDisabled={isSaveButtonDisabled}
                        onDownloadXLSXTemplateButtonClick={onDownloadXLSXTemplateButtonClick}
                        onLoadXLSXContentFileInput={onLoadXLSXContentFileInput}
                        userCanEditOrCreateBudgetData={userCanEditOrCreateBudgetData}
                        showOnlyLinesWithPlanBudget={showOnlyLinesWithPlanBudget}
                        onShowOnlyLinesWithPlanBudgetToggle={onShowOnlyLinesWithPlanBudgetToggle}
                        showOnlyLinesWithoutPlanBudget={showOnlyLinesWithoutPlanBudget}
                        onShowOnlyLinesWithoutPlanBudgetToggle={onShowOnlyLinesWithoutPlanBudgetToggle}
                    />
                </div>

                <div className={style.table} ref={tableRef}>
                    <Table
                        ref={tableContentRef}
                        maxHeight={maxTableHeight}
                        pageContentHeight={pageContentHeight}
                        tableOffsetTop={tableOffsetTop}
                        initColumnScroll={scrollLeftColumn}
                        onApplyFiltersButtonClick={onApplyFiltersButtonClick}
                    />
                </div>

                {localPreloader && <Preloader />}

                {showLineModal && <LineModal />}

                <XLSXImportErrorPopup />
            </div>
        </OpacityTransition>
    );
}
