/* tslint:disable:max-file-line-count */
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { combineReducers } from 'redux';
import * as lodash from 'lodash';
// import { UserResponseParams } from 'sber-marketing-types/frontend';
import * as actions from './actions';

import { budgetCorrectionsFiltersAndExportReducer } from './filtersAndExport';

import {
    // CorrectionStatusNames,
    // CorrectionTypeIds,
    // CorrectionTypeNames,
    // CorrectionTypeOrder,
    // Filter,
    // Filters,
    // ItemKey,
    BudgetCorrectionsPageState,
    PageData,
    // PageFilters,
    PageState,
    UnsavedChange,
} from './types';
// import { BudgetItem, Correction, CorrectionType } from '@mrm/budget';

// interface ToFilterParams {
//     filter: Filter;
//     key: ItemKey;
//     value: string;
//     associatedItemId: string;
//     order?: number;
// }

// function createEmptyPageFilters(): PageFilters {
//     function createEmptyFilter(): Filter {
//         return {
//             items: [],
//             allItemIds: [],
//             filteredItems: new Map()
//         };
//     }
//
//     return {
//         [Filters.Status]: createEmptyFilter(),
//         [Filters.Types]: createEmptyFilter(),
//         [Filters.ActivityNames]: createEmptyFilter(),
//         [Filters.BudgetItemNames]: createEmptyFilter(),
//         [Filters.Authors]: createEmptyFilter(),
//         [Filters.Approvers]: createEmptyFilter(),
//         [Filters.Blocks]: createEmptyFilter(),
//         [Filters.ActivityTypes]: createEmptyFilter(),
//         [Filters.Directions]: createEmptyFilter(),
//         [Filters.Tools]: createEmptyFilter(),
//         [Filters.ExecutorIds]: createEmptyFilter(),
//         [Filters.PlanIds]: createEmptyFilter(),
//         [Filters.SerialNumbers]: createEmptyFilter()
//     };
// }

const initialState: PageState = {
    pageData: {
        budget: null,
        dictionaries: [],
        users: [],
    },
    // pageFilters: createEmptyPageFilters(),
    unsavedChange: {},
};

class Reducer {
    public static loadPageData(state: PageState, payload: PageData): PageState {
        // const pageFilters = createEmptyPageFilters();

        return {
            ...state,
            pageData: {
                ...state.pageData,
                ...payload,
            },
            // pageFilters
        };
    }

    public static resetPageState(): PageState {
        return lodash.cloneDeep(initialState);
    }

    public static setUnsavedChange(state: PageState, payload: UnsavedChange): PageState {
        const { correctionId, newStatus, rejectComment, sapExportMode } = payload;

        const unsavedChange = {
            correctionId,
            newStatus,
            rejectComment,
            sapExportMode,
        };

        return { ...state, unsavedChange };
    }

    public static setRejectComment(state: PageState, payload: UnsavedChange['rejectComment']): PageState {
        return {
            ...state,
            unsavedChange: {
                ...state.unsavedChange,
                rejectComment: payload,
            },
        };
    }

    public static resetUnsavedChange(state: PageState): PageState {
        return { ...state, unsavedChange: {} };
    }

    // private static buildFilters(
    //     correction: Correction,
    //     budgetItems: BudgetItem[],
    //     pageFilters: PageFilters
    // ): void {
    //     Reducer.buildFilter(Filters.Status, correction, budgetItems, pageFilters);
    //     Reducer.buildFilter(Filters.Types, correction, budgetItems, pageFilters);
    //     Reducer.buildFilter(Filters.ActivityNames, correction, budgetItems, pageFilters);
    //     Reducer.buildFilter(Filters.BudgetItemNames, correction, budgetItems, pageFilters);
    //     Reducer.buildFilter(Filters.Authors, correction, budgetItems, pageFilters);
    //     Reducer.buildFilter(Filters.Approvers, correction, budgetItems, pageFilters);
    //     Reducer.buildFilter(Filters.Blocks, correction, budgetItems, pageFilters);
    //     Reducer.buildFilter(Filters.ActivityTypes, correction, budgetItems, pageFilters);
    //     Reducer.buildFilter(Filters.Directions, correction, budgetItems, pageFilters);
    //     Reducer.buildFilter(Filters.Tools, correction, budgetItems, pageFilters);
    //     Reducer.buildFilter(Filters.ExecutorIds, correction, budgetItems, pageFilters);
    //     Reducer.buildFilter(Filters.PlanIds, correction, budgetItems, pageFilters);
    //     Reducer.buildFilter(Filters.SerialNumbers, correction, budgetItems, pageFilters);
    // }

    // private static buildFilter(
    //     filterType: Filters,
    //     correction: Correction,
    //     budgetItems: BudgetItem[],
    //     pageFilters: PageFilters
    // ): void {
    //     let buildedStatusFilterParams: ToFilterParams[] = [];
    //
    //     switch (filterType) {
    //         case Filters.Status:
    //             buildedStatusFilterParams = Reducer.buildStatusFilterParams(correction, pageFilters);
    //             break;
    //         case Filters.Types:
    //             buildedStatusFilterParams = Reducer.buildTypesFilterParams(correction, pageFilters);
    //             break;
    //         case Filters.Authors:
    //             buildedStatusFilterParams = Reducer.buildAuthorsFilterParams(correction, pageFilters);
    //             break;
    //         case Filters.Approvers:
    //             buildedStatusFilterParams = Reducer.buildApproversFilterParams(correction, pageFilters);
    //             break;
    //         case Filters.Blocks:
    //             buildedStatusFilterParams = Reducer.buildBlocksFilterParams(correction, pageFilters);
    //             break;
    //         case Filters.ActivityTypes:
    //             buildedStatusFilterParams = Reducer.buildActivityTypesFilterParams(correction, pageFilters);
    //             break;
    //         case Filters.ExecutorIds:
    //             buildedStatusFilterParams = Reducer.buildExecutorIdsFilterParams(
    //     budgetItems,
    //     correction,
    //     pageFilters
    // );
    //             break;
    //         case Filters.Directions:
    //             buildedStatusFilterParams = Reducer.buildDirectionsFilterParams(correction, pageFilters);
    //             break;
    //         case Filters.Tools:
    //             buildedStatusFilterParams = Reducer.buildToolsFilterParams(correction, pageFilters);
    //             break;
    //         case Filters.PlanIds:
    //             buildedStatusFilterParams = Reducer.buildPlanIdsFilterParams(correction, pageFilters);
    //             break;
    //         case Filters.ActivityNames:
    //             buildedStatusFilterParams = Reducer.buildActivityNamesFilterParams(correction, pageFilters);
    //             break;
    //         case Filters.BudgetItemNames:
    //             buildedStatusFilterParams = Reducer.buildBudgetItemNamesFilterParams(correction, pageFilters);
    //             break;
    //         case Filters.SerialNumbers:
    //             buildedStatusFilterParams = Reducer.buildSerialNumbersFilterParams(correction, pageFilters);
    //             break;
    //         default:
    //             buildedStatusFilterParams = [];
    //     }
    //
    //     buildedStatusFilterParams.forEach(filterParams => {
    //         Reducer.addToFilter(filterParams);
    //     });
    // }

    // private static buildTypesFilterParams(
    //     correction: Correction,
    //     pageFilters: PageFilters
    // ): ToFilterParams[] {
    //     return [{
    //         filter: pageFilters[Filters.Types],
    //         key: CorrectionTypeIds[correction.type],
    //         value: CorrectionTypeNames[correction.type],
    //         associatedItemId: correction.id,
    //         order: CorrectionTypeOrder[correction.type]
    //     }];
    // }

    // private static buildSerialNumbersFilterParams(
    //     correction: Correction,
    //     pageFilters: PageFilters
    // ): ToFilterParams[] {
    //     return [{
    //         filter: pageFilters[Filters.SerialNumbers],
    //         key: String(correction.serialNumber),
    //         value: String(correction.serialNumber),
    //         associatedItemId: correction.id
    //     }];
    // }

    // private static buildStatusFilterParams(
    //     correction: Correction,
    //     pageFilters: PageFilters
    // ): ToFilterParams[] {
    //     return [{
    //         filter: pageFilters[Filters.Status],
    //         key: correction.status,
    //         value: CorrectionStatusNames[correction.status],
    //         associatedItemId: correction.id
    //     }];
    // }

    // private static buildAuthorsFilterParams(
    //     correction: Correction,
    //     pageFilters: PageFilters
    // ): ToFilterParams[] {
    //     return [{
    //         filter: pageFilters[Filters.Authors],
    //         key: correction.author.id,
    //         value: Reducer.formatUserName(correction.author),
    //         associatedItemId: correction.id
    //     }];
    // }

    // private static buildApproversFilterParams(
    //     correction: Correction,
    //     pageFilters: PageFilters
    // ): ToFilterParams[] {
    //     const approver = correction.approver || correction.expert;
    //
    //     if (approver) {
    //         return [{
    //             filter: pageFilters[Filters.Approvers],
    //             key: approver.id,
    //             value: Reducer.formatUserName(approver),
    //             associatedItemId: correction.id
    //         }];
    //     }
    //
    //     return [{
    //         filter: pageFilters[Filters.Approvers],
    //         key: null,
    //         value: null,
    //         associatedItemId: correction.id
    //     }];
    // }

    // tslint:disable-next-line:cyclomatic-complexity
    // private static buildBlocksFilterParams(
    //     correction: Correction,
    //     pageFilters: PageFilters
    // ): ToFilterParams[] {
    //     const filtersParams: ToFilterParams[] = [];
    //
    //     const haveDataDonor = (
    //             correction.type === CorrectionType.PlanFundsTransfer ||
    //             correction.type === CorrectionType.OutcomeExternalPlanFundsTransfer
    //         ) && (
    //             correction.data.donor.current.dictionary &&
    //             correction.data.donor.current.dictionary.block
    //         );
    //
    //     const haveDataAcceptor = (
    //             correction.type === CorrectionType.PlanFundsTransfer ||
    //             correction.type === CorrectionType.IncomeExternalPlanFundsTransfer
    //         ) && (
    //             correction.data.acceptor.current.dictionary &&
    //             correction.data.acceptor.current.dictionary.block
    //         );
    //
    //     const haveDataBudgetItem = (
    //             correction.type === CorrectionType.FactFunds ||
    //             correction.type === CorrectionType.BudgetItem ||
    //             correction.type === CorrectionType.ReservedFunds
    //         ) && (
    //             correction.data.budgetItem.current.dictionary &&
    //             correction.data.budgetItem.current.dictionary.block
    //         );
    //
    //     const notData = correction.type == CorrectionType.PlanFundsTransfer
    //         ? !haveDataDonor || !haveDataAcceptor
    //         : !haveDataDonor && !haveDataAcceptor && !haveDataBudgetItem;
    //
    //     if (haveDataDonor) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.Blocks],
    //             key: correction.data.donor.current.dictionary.block.id,
    //             value: correction.data.donor.current.dictionary.block.value,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (haveDataAcceptor) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.Blocks],
    //             key: correction.data.acceptor.current.dictionary.block.id,
    //             value: correction.data.acceptor.current.dictionary.block.value,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (haveDataBudgetItem) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.Blocks],
    //             key: correction.data.budgetItem.current.dictionary.block.id,
    //             value: correction.data.budgetItem.current.dictionary.block.value,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (notData) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.Blocks],
    //             key: null,
    //             value: null,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     return filtersParams;
    // }

    // tslint:disable-next-line:cyclomatic-complexity
    // private static buildActivityTypesFilterParams(
    //     correction: Correction,
    //     pageFilters: PageFilters
    // ): ToFilterParams[] {
    //     const filtersParams: ToFilterParams[] = [];
    //
    //     const haveDataDonor = (
    //             correction.type === CorrectionType.PlanFundsTransfer ||
    //             correction.type === CorrectionType.OutcomeExternalPlanFundsTransfer
    //         ) && (
    //             correction.data.donor.current.dictionary &&
    //             correction.data.donor.current.dictionary.activity_type
    //         );
    //
    //     const haveDataAcceptor = (
    //             correction.type === CorrectionType.PlanFundsTransfer ||
    //             correction.type === CorrectionType.IncomeExternalPlanFundsTransfer
    //         ) && (
    //             correction.data.acceptor.current.dictionary &&
    //             correction.data.acceptor.current.dictionary.activity_type
    //         );
    //
    //     const haveDataBudgetItem = (
    //         correction.type === CorrectionType.FactFunds ||
    //         correction.type === CorrectionType.BudgetItem ||
    //         correction.type === CorrectionType.ReservedFunds
    //         ) && (
    //             correction.data.budgetItem.current.dictionary &&
    //             correction.data.budgetItem.current.dictionary.activity_type
    //         );
    //
    //     const notData = correction.type == CorrectionType.PlanFundsTransfer
    //         ? !haveDataDonor || !haveDataAcceptor
    //         : !haveDataDonor && !haveDataAcceptor && !haveDataBudgetItem;
    //
    //     if (haveDataDonor) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.ActivityTypes],
    //             key: correction.data.donor.current.dictionary.activity_type.id,
    //             value: correction.data.donor.current.dictionary.activity_type.value,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (haveDataAcceptor) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.ActivityTypes],
    //             key: correction.data.acceptor.current.dictionary.activity_type.id,
    //             value: correction.data.acceptor.current.dictionary.activity_type.value,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (haveDataBudgetItem) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.ActivityTypes],
    //             key: correction.data.budgetItem.current.dictionary.activity_type.id,
    //             value: correction.data.budgetItem.current.dictionary.activity_type.value,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (notData) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.ActivityTypes],
    //             key: null,
    //             value: null,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     return filtersParams;
    // }

    // tslint:disable-next-line:cyclomatic-complexity
    // private static buildExecutorIdsFilterParams(
    //     budgetItems: BudgetItem[],
    //     correction: Correction,
    //     pageFilters: PageFilters
    // ): ToFilterParams[] {
    //     const filtersParams: ToFilterParams[] = [];
    //
    //     const isCorrectionActivityType =
    //         correction.type === CorrectionType.Activity;
    //
    //     const haveDataDonor =
    //         correction.type === CorrectionType.PlanFundsTransfer ||
    //         correction.type === CorrectionType.OutcomeExternalPlanFundsTransfer;
    //
    //     const haveDataAcceptor =
    //         correction.type === CorrectionType.PlanFundsTransfer ||
    //         correction.type === CorrectionType.IncomeExternalPlanFundsTransfer;
    //
    //     const haveDataBudgetItem =
    //         correction.type === CorrectionType.FactFunds ||
    //         correction.type === CorrectionType.BudgetItem ||
    //         correction.type === CorrectionType.ReservedFunds;
    //
    //     const notData = correction.type == CorrectionType.PlanFundsTransfer
    //         ? !haveDataDonor || !haveDataAcceptor
    //         : !haveDataDonor && !haveDataAcceptor && !haveDataBudgetItem && !isCorrectionActivityType;
    //
    //     if (haveDataDonor) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.ExecutorIds],
    //             key: correction.data.donor.current.serialNumber,
    //             value: String(correction.data.donor.current.serialNumber),
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (haveDataAcceptor) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.ExecutorIds],
    //             key: correction.data.acceptor.current.serialNumber,
    //             value: String(correction.data.acceptor.current.serialNumber),
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (haveDataBudgetItem) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.ExecutorIds],
    //             key: correction.data.budgetItem.current.serialNumber,
    //             value: String(correction.data.budgetItem.current.serialNumber),
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (isCorrectionActivityType) {
    //         const budgetItemsOfActivity =
    //             budgetItems.filter(budgetItem => budgetItem.activity.id == correction.data.activity.current.id);
    //
    //         const budgetItemsSerialNumbers = budgetItemsOfActivity.map(budgetItem => budgetItem.serialNumber);
    //
    //         budgetItemsSerialNumbers.forEach(budgetItemSerialNumber => {
    //             filtersParams.push({
    //                 filter: pageFilters[Filters.ExecutorIds],
    //                 key: Number(budgetItemSerialNumber),
    //                 value: String(budgetItemSerialNumber),
    //                 associatedItemId: correction.id
    //             });
    //         });
    //     }
    //
    //     if (notData) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.ExecutorIds],
    //             key: null,
    //             value: null,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     return filtersParams;
    // }

    // tslint:disable-next-line:cyclomatic-complexity
    // private static buildDirectionsFilterParams(
    //     correction: Correction,
    //     pageFilters: PageFilters
    // ): ToFilterParams[] {
    //     const filtersParams: ToFilterParams[] = [];
    //
    //     const haveDataDonor = (
    //             correction.type === CorrectionType.PlanFundsTransfer ||
    //             correction.type === CorrectionType.OutcomeExternalPlanFundsTransfer
    //         ) && (
    //             correction.data.donor.current.dictionary &&
    //             correction.data.donor.current.dictionary.direction
    //         );
    //
    //     const haveDataAcceptor = (
    //         correction.type === CorrectionType.PlanFundsTransfer ||
    //         correction.type === CorrectionType.IncomeExternalPlanFundsTransfer
    //         ) && (
    //             correction.data.acceptor.current.dictionary &&
    //             correction.data.acceptor.current.dictionary.direction
    //         );
    //
    //     const haveDataBudgetItem = (
    //         correction.type === CorrectionType.FactFunds ||
    //         correction.type === CorrectionType.BudgetItem ||
    //         correction.type === CorrectionType.ReservedFunds
    //         ) && (
    //             correction.data.budgetItem.current.dictionary &&
    //             correction.data.budgetItem.current.dictionary.direction
    //         );
    //
    //     const notData = correction.type == CorrectionType.PlanFundsTransfer
    //         ? !haveDataDonor || !haveDataAcceptor
    //         : !haveDataDonor && !haveDataAcceptor && !haveDataBudgetItem;
    //
    //     if (haveDataDonor) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.Directions],
    //             key: correction.data.donor.current.dictionary.direction.id,
    //             value: correction.data.donor.current.dictionary.direction.value,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (haveDataAcceptor) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.Directions],
    //             key: correction.data.acceptor.current.dictionary.direction.id,
    //             value: correction.data.acceptor.current.dictionary.direction.value,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (haveDataBudgetItem) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.Directions],
    //             key: correction.data.budgetItem.current.dictionary.direction.id,
    //             value: correction.data.budgetItem.current.dictionary.direction.value,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (notData) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.Directions],
    //             key: null,
    //             value: null,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     return filtersParams;
    // }

    // tslint:disable-next-line:cyclomatic-complexity
    // private static buildToolsFilterParams(
    //     correction: Correction,
    //     pageFilters: PageFilters
    // ): ToFilterParams[] {
    //     const filtersParams: ToFilterParams[] = [];
    //
    //     const haveDataDonor = (
    //             correction.type === CorrectionType.PlanFundsTransfer ||
    //             correction.type === CorrectionType.OutcomeExternalPlanFundsTransfer
    //         ) && (
    //             correction.data.donor.current.dictionary &&
    //             correction.data.donor.current.dictionary.tool
    //         );
    //
    //     const haveDataAcceptor = (
    //             correction.type === CorrectionType.PlanFundsTransfer ||
    //             correction.type === CorrectionType.IncomeExternalPlanFundsTransfer
    //         ) && (
    //             correction.data.acceptor.current.dictionary &&
    //             correction.data.acceptor.current.dictionary.tool
    //         );
    //
    //     const haveDataBudgetItem = (
    //             correction.type === CorrectionType.FactFunds ||
    //             correction.type === CorrectionType.BudgetItem ||
    //             correction.type === CorrectionType.ReservedFunds
    //         ) && (
    //             correction.data.budgetItem.current.dictionary &&
    //             correction.data.budgetItem.current.dictionary.tool
    //         );
    //
    //     const notData = correction.type == CorrectionType.PlanFundsTransfer
    //         ? !haveDataDonor || !haveDataAcceptor
    //         : !haveDataDonor && !haveDataAcceptor && !haveDataBudgetItem;
    //
    //     if (haveDataDonor) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.Tools],
    //             key: correction.data.donor.current.dictionary.tool.id,
    //             value: correction.data.donor.current.dictionary.tool.value,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (haveDataAcceptor) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.Tools],
    //             key: correction.data.acceptor.current.dictionary.tool.id,
    //             value: correction.data.acceptor.current.dictionary.tool.value,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (haveDataBudgetItem) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.Tools],
    //             key: correction.data.budgetItem.current.dictionary.tool.id,
    //             value: correction.data.budgetItem.current.dictionary.tool.value,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (notData) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.Tools],
    //             key: null,
    //             value: null,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     return filtersParams;
    // }

    // tslint:disable-next-line:cyclomatic-complexity
    // private static buildPlanIdsFilterParams(
    //     correction: Correction,
    //     pageFilters: PageFilters
    // ): ToFilterParams[] {
    //     const filtersParams: ToFilterParams[] = [];
    //
    //     const haveDataDonor = (
    //         correction.type === CorrectionType.PlanFundsTransfer ||
    //         correction.type === CorrectionType.OutcomeExternalPlanFundsTransfer
    //         ) &&
    //         correction.data.donor.current.donors;
    //
    //     const haveDataAcceptor = (
    //         correction.type === CorrectionType.PlanFundsTransfer ||
    //         correction.type === CorrectionType.IncomeExternalPlanFundsTransfer
    //         ) &&
    //         correction.data.acceptor.current.donors;
    //
    //     const haveBudgetItem = (
    //         correction.type === CorrectionType.FactFunds ||
    //         correction.type === CorrectionType.BudgetItem ||
    //         correction.type === CorrectionType.ReservedFunds
    //         ) &&
    //         correction.data.budgetItem.current.donors;
    //
    //     const notData = correction.type == CorrectionType.PlanFundsTransfer
    //         ? !haveDataDonor || !haveDataAcceptor
    //         : !haveDataDonor && !haveDataAcceptor && !haveBudgetItem;
    //
    //     if (haveDataDonor) {
    //         const donorId = correction.data.donor.current.donors.join(', ');
    //
    //         filtersParams.push({
    //             filter: pageFilters[Filters.PlanIds],
    //             key: donorId,
    //             value: donorId,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (haveDataAcceptor) {
    //         const donorId = correction.data.acceptor.current.donors.join(', ');
    //
    //         filtersParams.push({
    //             filter: pageFilters[Filters.PlanIds],
    //             key: donorId,
    //             value: donorId,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (haveBudgetItem) {
    //         const donorId = correction.data.budgetItem.current.donors.join(', ');
    //
    //         filtersParams.push({
    //             filter: pageFilters[Filters.PlanIds],
    //             key: donorId,
    //             value: donorId,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (notData) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.PlanIds],
    //             key: null,
    //             value: null,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     return filtersParams;
    // }

    // tslint:disable-next-line:cyclomatic-complexity
    // private static buildActivityNamesFilterParams(
    //     correction: Correction,
    //     pageFilters: PageFilters
    // ): ToFilterParams[] {
    //     const filtersParams: ToFilterParams[] = [];
    //
    //     const isCorrectionActivityType =
    //         correction.type === CorrectionType.Activity;
    //
    //     const haveDataDonor =
    //         correction.type === CorrectionType.PlanFundsTransfer ||
    //         correction.type === CorrectionType.OutcomeExternalPlanFundsTransfer;
    //
    //     const haveDataAcceptor =
    //         correction.type === CorrectionType.PlanFundsTransfer ||
    //         correction.type === CorrectionType.IncomeExternalPlanFundsTransfer;
    //
    //     const haveBudgetItem =
    //         correction.type === CorrectionType.FactFunds ||
    //         correction.type === CorrectionType.BudgetItem ||
    //         correction.type === CorrectionType.ReservedFunds;
    //
    //     const notData = correction.type == CorrectionType.PlanFundsTransfer
    //         ? !haveDataDonor || !haveDataAcceptor
    //         : !haveDataDonor && !haveDataAcceptor && !haveBudgetItem && !isCorrectionActivityType;
    //
    //     if (haveDataDonor) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.ActivityNames],
    //             key: correction.data.donor.current.activity.id,
    //             value: correction.data.donor.current.activity.name,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (haveDataAcceptor) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.ActivityNames],
    //             key: correction.data.acceptor.current.activity.id,
    //             value: correction.data.acceptor.current.activity.name,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (haveBudgetItem) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.ActivityNames],
    //             key: correction.data.budgetItem.current.activity.id,
    //             value: correction.data.budgetItem.current.activity.name,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (isCorrectionActivityType) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.ActivityNames],
    //             key: correction.data.activity.current.id,
    //             value: correction.data.activity.current.name,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (notData) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.ActivityNames],
    //             key: null,
    //             value: null,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     return filtersParams;
    // }

    // tslint:disable-next-line:cyclomatic-complexity
    // private static buildBudgetItemNamesFilterParams(
    //     correction: Correction,
    //     pageFilters: PageFilters
    // ): ToFilterParams[] {
    //     const filtersParams: ToFilterParams[] = [];
    //
    //     const haveDataDonor = (
    //         correction.type === CorrectionType.PlanFundsTransfer ||
    //         correction.type === CorrectionType.OutcomeExternalPlanFundsTransfer
    //     ) && (
    //         correction.data.donor.current.sapComment
    //     );
    //
    //     const haveDataAcceptor = (
    //         correction.type === CorrectionType.PlanFundsTransfer ||
    //         correction.type === CorrectionType.IncomeExternalPlanFundsTransfer
    //     ) && (
    //         correction.data.acceptor.current.sapComment
    //     );
    //
    //     const haveDataBudgetItem = (
    //         correction.type === CorrectionType.FactFunds ||
    //         correction.type === CorrectionType.BudgetItem ||
    //         correction.type === CorrectionType.ReservedFunds
    //     ) && (
    //         correction.data.budgetItem.current.sapComment
    //     );
    //
    //     const notData = correction.type == CorrectionType.PlanFundsTransfer
    //         ? !haveDataDonor || !haveDataAcceptor
    //         : !haveDataDonor && !haveDataAcceptor && !haveDataBudgetItem;
    //
    //     if (haveDataDonor) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.BudgetItemNames],
    //             key: correction.data.donor.current.sapComment,
    //             value: correction.data.donor.current.sapComment,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (haveDataAcceptor) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.BudgetItemNames],
    //             key: correction.data.acceptor.current.sapComment,
    //             value: correction.data.acceptor.current.sapComment,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (haveDataBudgetItem) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.BudgetItemNames],
    //             key: correction.data.budgetItem.current.sapComment,
    //             value: correction.data.budgetItem.current.sapComment,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     if (notData) {
    //         filtersParams.push({
    //             filter: pageFilters[Filters.BudgetItemNames],
    //             key: null,
    //             value: null,
    //             associatedItemId: correction.id
    //         });
    //     }
    //
    //     return filtersParams;
    // }

    // private static formatUserName(user: UserResponseParams): string {
    //     return `${user.secondName} ${user.firstName}`;
    // }

    // private static addToFilter(params: ToFilterParams): void {
    //     const { filter, key, value, associatedItemId, order }  = params;
    //
    //     let itemsByKey = filter.filteredItems.get(key);
    //
    //     if (!itemsByKey) {
    //         itemsByKey = new Set<string>();
    //         filter.filteredItems.set(key, itemsByKey);
    //
    //         filter.items.push({
    //             id: key,
    //             title: value || 'Значение не задано',
    //             order: order || null
    //         });
    //
    //         filter.allItemIds.push(key);
    //     }
    //
    //     itemsByKey.add(associatedItemId);
    // }
}

const budgetCorrectionsStateReducer = reducerWithInitialState(initialState)
    .case(actions.loadPageData, Reducer.loadPageData)
    .case(actions.resetPageState, Reducer.resetPageState)
    .case(actions.setUnsavedChange, Reducer.setUnsavedChange)
    .case(actions.resetUnsavedChange, Reducer.resetUnsavedChange)
    .case(actions.setRejectComment, Reducer.setRejectComment);

export const budgetCorrectionsPageReducer = combineReducers<BudgetCorrectionsPageState>({
    filtersAndExport: budgetCorrectionsFiltersAndExportReducer,
    restState: budgetCorrectionsStateReducer,
});
