import * as React from 'react';

import * as style from './FundsInputCell.scss';

import { Input_redesign as Input, InputTheme_redesign as InputTheme, Icon, IconType } from 'sber-marketing-ui';

interface Props {
    value: string;
    placeholder: string;
    customStyle?: any;
    icon?: { type: IconType; color: string; size: number };
    validateValue?: (value: string) => boolean;
    onValueChange: (value: string) => void;
}

export const FundsInputCellEditTemplate = ({
    value,
    placeholder,
    customStyle,
    icon,
    validateValue,
    onValueChange,
}: Props): JSX.Element => {
    function onBeforeInput(event: React.CompositionEvent<HTMLInputElement>) {
        if (validateValue) {
            const newValue = getNextValue(event);

            const newValueIsValid = validateValue(newValue);

            if (!newValueIsValid) {
                event.preventDefault();
            }
        }
    }

    function getNextValue(event: React.CompositionEvent<HTMLInputElement>) {
        const str = event.currentTarget.value;
        const sub = event.data;
        const posStart = event.currentTarget.selectionStart || 0;
        const posEnd = event.currentTarget.selectionEnd || posStart;

        return `${str.slice(0, posStart)}${sub}${str.slice(posEnd)}`;
    }

    function onBlur(event: React.FocusEvent<HTMLInputElement>, value: string) {
        onValueChange(value.replace(',', '.'));
    }

    return (
        <div className={style.root} style={{ ...customStyle }}>
            <div className={style.title}>
                {icon && (
                    <div className={style.titleIcon} style={{ width: icon.size, height: icon.size }}>
                        <Icon type={icon.type} svgSize={icon.size} color={icon.color} />
                    </div>
                )}

                <Input
                    value={value}
                    placeholder={placeholder}
                    theme={InputTheme.Borderless}
                    valueType={'money'}
                    onBeforeInput={onBeforeInput}
                    onBlur={onBlur}
                    autofocus
                />

                <div className={style.currencySign}>₽</div>
            </div>
        </div>
    );
};
