import * as React from 'react';
import classNames from 'classnames';

import * as style from '../../CommonStyles.scss';

interface Props {
    name: string;
}

export function Channel({ name }: Props): JSX.Element {
    return (
        <div className={style.newsPartWrapper}>
            <div className={style.caption}>Канал</div>

            <div
                className={classNames([style.text, style.colorGreen, style.weightBold])}
                {...{
                    'qa-id': 'dashboardNewsColumnItemChannel',
                }}
            >
                {name}
            </div>
        </div>
    );
}
