import * as React from 'react';

import * as style from '../DateFilter.scss';

interface Props {
    selectedYear: number;
    isMonthSelected: boolean;
    isQuarterSelected: boolean;
    hasPreviousMonth: boolean;
    hasPreviousQuarter: boolean;
    hasPreviousYear: boolean;
    hasNextMonth: boolean;
    hasNextQuarter: boolean;
    hasNextYear: boolean;
    previousMonthLabel: string | null;
    previousQuarterLabel: string | null;
    previousYearLabel: string | null;
    currentMonthLabel: string | null;
    currentQuarterLabel: string | null;
    nextMonthLabel: string | null;
    nextQuarterLabel: string | null;
    nextYearLabel: string | null;
    onYearClick: () => void;
    onPreviousMonthClick: () => void;
    onPreviousQuarterClick: () => void;
    onPreviousYearClick: () => void;
    onCurrentQuarterClick: () => void;
    onNextMonthClick: () => void;
    onNextQuarterClick: () => void;
    onNextYearClick: () => void;
}

export const Breadcrumbs: React.SFC<Props> = ({
    selectedYear,
    isMonthSelected,
    isQuarterSelected,
    hasPreviousMonth,
    hasPreviousQuarter,
    hasPreviousYear,
    hasNextMonth,
    hasNextQuarter,
    hasNextYear,
    previousMonthLabel,
    previousQuarterLabel,
    previousYearLabel,
    currentMonthLabel,
    currentQuarterLabel,
    nextMonthLabel,
    nextQuarterLabel,
    nextYearLabel,
    onYearClick,
    onPreviousMonthClick,
    onPreviousQuarterClick,
    onPreviousYearClick,
    onCurrentQuarterClick,
    onNextMonthClick,
    onNextQuarterClick,
    onNextYearClick,
}) => (
    <React.Fragment>
        <div
            className={style.navButton}
            {...{
                'qa-id': 'breadcrumbs',
            }}
        >
            {hasPreviousYear && !isQuarterSelected && (
                <div
                    className={style.button}
                    onClick={onPreviousYearClick}
                    {...{
                        'qa-id': 'breadcrumbsPreviousYearButton',
                    }}
                >
                    {previousYearLabel}
                </div>
            )}

            {hasPreviousQuarter && (
                <div
                    className={style.button}
                    onClick={onPreviousQuarterClick}
                    {...{
                        'qa-id': 'breadcrumbsPreviousQuarterButton',
                    }}
                >
                    {previousQuarterLabel}
                </div>
            )}

            {hasPreviousMonth && (
                <div
                    className={style.button}
                    onClick={onPreviousMonthClick}
                    {...{
                        'qa-id': 'breadcrumbsPreviousMonthButton',
                    }}
                >
                    {previousMonthLabel}
                </div>
            )}
        </div>

        <div className={style.breadcrumbs}>
            {isMonthSelected && (
                <div
                    {...{
                        'qa-id': 'breadcrumbsCurrentMonth',
                    }}
                >
                    <span className={style.selected}>{currentMonthLabel}</span>

                    <div className={style.separatorDot} />
                </div>
            )}

            {isQuarterSelected && (
                <>
                    <span
                        className={isMonthSelected ? style.button : style.selected}
                        onClick={onCurrentQuarterClick}
                        {...{
                            'qa-id': 'breadcrumbsCurrentQuarterButton',
                        }}
                    >
                        {currentQuarterLabel}
                    </span>

                    <div className={style.separatorDot} />
                </>
            )}

            <span
                className={isQuarterSelected ? style.button : style.selected}
                onClick={onYearClick}
                {...{
                    'qa-id': 'breadcrumbsSelectedYear',
                }}
            >
                {selectedYear}
            </span>
        </div>

        <div className={style.navButton}>
            {hasNextYear && !isQuarterSelected && (
                <div
                    className={style.button}
                    onClick={onNextYearClick}
                    {...{
                        'qa-id': 'breadcrumbsNextYearButton',
                    }}
                >
                    {nextYearLabel}
                </div>
            )}

            {hasNextQuarter && (
                <div
                    className={style.button}
                    onClick={onNextQuarterClick}
                    {...{
                        'qa-id': 'breadcrumbsNextQuarterButton',
                    }}
                >
                    {nextQuarterLabel}
                </div>
            )}

            {hasNextMonth && (
                <div
                    className={style.button}
                    onClick={onNextMonthClick}
                    {...{
                        'qa-id': 'breadcrumbsNextMonth  Button',
                    }}
                >
                    {nextMonthLabel}
                </div>
            )}
        </div>
    </React.Fragment>
);
