import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { StoreState } from '@store';
import { getActivityReferenceData } from '@store/brief/selectors';
import { setActivityReferenceData } from '@store/brief/actions';
import { ActivityReferenceData } from '@store/brief/types';

interface Props extends OwnProps, Partial<MapProps & DispatchProps> {}

interface OwnProps {
    children: (props: ChildrenProps) => JSX.Element;
}

interface ChildrenProps extends MapProps, DispatchProps {}

interface MapProps {
    budgetItems: any[];
    selectedBudgetItemIds: string[];
}

interface DispatchProps {
    setActivityReferenceData: (data: ActivityReferenceData) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class WithStore extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { children, ...restProps } = this.props;

        return children(restProps as ChildrenProps);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { selectedBudgetItemIds, budgetItems } = getActivityReferenceData(state);

    return {
        budgetItems,
        selectedBudgetItemIds,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        setActivityReferenceData: (data: ActivityReferenceData) => dispatch(setActivityReferenceData(data)),
    };
}
