import { Month } from '@mrm/budget';
import { CellValue, CellValueType, NumericCellValue, DateCellValue } from '@store/budgetExecution/types';

export function getFieldValue(field: CellValue, valueType: CellValueType): string | Date {
    let value: string | Date;

    switch (valueType) {
        case CellValueType.String:
            value = field as string;
            break;

        case CellValueType.Number:
            value = field && (field as NumericCellValue).title;
            break;

        case CellValueType.Currency:
            value = field && (field as NumericCellValue).title;
            break;

        case CellValueType.Date:
            value = field && (field as DateCellValue).date;
            break;
    }

    return value;
}

export function getMonthByIndex(monthIndex: number): Month {
    const months = [
        Month.Jan,
        Month.Feb,
        Month.Mar,
        Month.Apr,
        Month.May,
        Month.Jun,
        Month.Jul,
        Month.Aug,
        Month.Sept,
        Month.Oct,
        Month.Nov,
        Month.Dec,
    ];

    return months[monthIndex];
}

export function formatDateToString(value: Date): string {
    const date = new Date(value);

    return `${date.toLocaleString('ru', { day: 'numeric', month: 'long' })} ${date.getFullYear()}`;
}
