import * as React from 'react';
import { difference } from 'lodash';

import { AppliedFiltersNames, ColumnName } from '@store/budgetExecution';
import { ContentTemplate } from './ContentTemplate';

interface Props {
    columnNames: ColumnName[];
    onResetButtonClick: () => void;
}

export class ContentBehavior extends React.Component<Props> {
    private readonly prevColumnNames: AppliedFiltersNames;

    constructor(props: Props) {
        super(props);

        this.prevColumnNames = props.columnNames;
    }

    public render() {
        const { onResetButtonClick } = this.props;

        return React.createElement(ContentTemplate, {
            columnNames: this.buildColumnNames(),
            onResetButtonClick,
        });
    }

    private buildColumnNames(): AppliedFiltersNames {
        const unusedFiltersNames = difference(this.prevColumnNames, this.props.columnNames);

        return [...this.props.columnNames, ...unusedFiltersNames];
    }
}
