import { createSelector } from 'reselect';
import * as lodash from 'lodash';
import * as moment from 'moment';

import { Money } from '@common/Utils';

import { CreateActivityBudgetForm, CreateBudgetItemForm, UpdateBudgetItemForm, MonthValue, Month } from '@mrm/budget';
import { DictionaryType } from '@mrm/dictionary';
import { FormField, FieldValue } from 'sber-marketing-ui';
import {
    PageState,
    FormData,
    BriefField,
    activityBudgetRequiredFields,
    budgetItemRequiredToCreateFields,
    BriefBlock,
    FieldType,
    Brief,
} from './types';
import { StoreState } from '../';
import { BriefViewResponse } from 'sber-marketing-types/backend';

const INDEX_OF_MONTH_FIELD_IN_PAIR = 0;
const INDEX_OF_AMOUNT_FIELD_IN_PAIR = 1;

const MONTHS_KEYS_OF_FONDS = [
    Month.Jan,
    Month.Feb,
    Month.Mar,
    Month.Apr,
    Month.May,
    Month.Jun,
    Month.Jul,
    Month.Aug,
    Month.Sept,
    Month.Oct,
    Month.Nov,
    Month.Dec,
];

export const getPlannedBudgetEditPageState = (state: StoreState): PageState => state.plannedBudgetEditPage;

export const getSortedByDeletedBudgetItemForms = createSelector(
    getPlannedBudgetEditPageState,
    (pageState: PageState): FormData[] => {
        const { budgetItemForms } = pageState;

        return lodash.sortBy<FormData>(budgetItemForms, [
            (budgetItemForm: FormData) => Boolean(budgetItemForm.deleted),
        ]);
    },
);

export const makeActivityBudgetParams = createSelector(
    getPlannedBudgetEditPageState,
    (pageState: PageState): CreateActivityBudgetForm => {
        const { id, fields } = pageState.activityForm;
        const name = getFieldValueByName('name', fields) as string;

        return {
            id,
            budgetId: null,
            name: name?.trim(),
        };
    },
);

export const makeBudgetsItems = createSelector(
    getPlannedBudgetEditPageState,
    (pageState: PageState): CreateBudgetItemForm[] => {
        const { budgetItemForms } = pageState;

        const sortedByDeletedBudgetItemForms = lodash.sortBy<FormData>(budgetItemForms, [
            (budgetItemForm: FormData) => Boolean(budgetItemForm.deleted),
        ]);

        return sortedByDeletedBudgetItemForms.map((form) => {
            const { id, fields, briefId } = form;

            const responsibleIds = getFieldValueByName('responsibles', fields) as number[];
            const comment = getFieldValueByName('comment', fields) as string;
            const sapComment = getFieldValueByName('sapComment', fields) as string;
            const businessTarget = getFieldValueByName('businessTarget', fields) as string;
            const customerName = getFieldValueByName('customerName', fields) as string;
            const realizationStart = getFieldValueByName('startDate', fields) as moment.Moment;
            const realizationEnd = getFieldValueByName('endDate', fields) as moment.Moment;
            const plannedFunds = getPlannedFundsFromFields(fields);
            const factPreviousPeriod = getFieldValueByName('factPreviousPeriod', fields) as number;
            const planPreviousPeriod = getFieldValueByName('planPreviousPeriod', fields) as number;

            const dictionaryIds = [
                DictionaryType.Direction,
                DictionaryType.Tool,
                DictionaryType.Item,
                DictionaryType.Resource,
                DictionaryType.CostCenter,
                DictionaryType.LocationDriver,
                DictionaryType.ActivityType,
                DictionaryType.Block,
                DictionaryType.Division,
                DictionaryType.Segment,
                DictionaryType.Product,
                DictionaryType.Regionality,
                DictionaryType.Territory,
            ].map((item) => getFieldValueByName(item, fields) as string);

            const res: CreateBudgetItemForm = {
                id,
                briefId: briefId || null,
                responsibleIds: responsibleIds.length ? responsibleIds : null,
                activityId: null,
                budgetId: null,
                dictionaryIds: lodash.compact(dictionaryIds),
                plannedFunds,
                businessTarget,
                customerName,
                comment,
                sapComment,
                realizationStart: realizationStart ? realizationStart.toDate() : null,
                realizationEnd: realizationEnd ? realizationEnd.toDate() : null,
            };

            if (factPreviousPeriod) {
                res.factPreviousPeriod = convertAmountToCopecks(`${factPreviousPeriod}`);
            }
            if (planPreviousPeriod) {
                res.planPreviousPeriod = convertAmountToCopecks(`${planPreviousPeriod}`);
            }

            return res;
        });
    },
);

export const makeCreatedBudgetsItems = createSelector(
    getPlannedBudgetEditPageState,
    (pageState: PageState): CreateBudgetItemForm[] => {
        const { budgetItemForms, budgetItems } = pageState;

        const budgetItemsIds = budgetItems.map((budgetItem) => budgetItem.id);

        const notDeletedBudgetItemForms = budgetItemForms.filter((budgetItemForm) => !budgetItemForm.deleted);
        const currentBudgetItemsIds = notDeletedBudgetItemForms.map((form) => form.id);

        const createdBudgetItemFormsIds = lodash.difference(currentBudgetItemsIds, budgetItemsIds);

        const filteredBudgetItemForms = budgetItemForms.filter((budgetItemForm) =>
            lodash.includes(createdBudgetItemFormsIds, budgetItemForm.id),
        );

        return filteredBudgetItemForms.map((form) => {
            const { id, briefId, fields, sourceId } = form;

            const responsibleIds = getFieldValueByName('responsibles', fields) as number[];
            const comment = getFieldValueByName('comment', fields) as string;
            const sapComment = getFieldValueByName('sapComment', fields) as string;
            const businessTarget = getFieldValueByName('businessTarget', fields) as string;
            const customerName = getFieldValueByName('customerName', fields) as string;
            const realizationStart = getFieldValueByName('startDate', fields) as moment.Moment;
            const realizationEnd = getFieldValueByName('endDate', fields) as moment.Moment;
            const plannedFunds = getPlannedFundsFromFields(fields);
            const factPreviousPeriod = getFieldValueByName('factPreviousPeriod', fields) as number;
            const planPreviousPeriod = getFieldValueByName('planPreviousPeriod', fields) as number;

            const dictionaryIds = [
                DictionaryType.Direction,
                DictionaryType.Tool,
                DictionaryType.Item,
                DictionaryType.Resource,
                DictionaryType.CostCenter,
                DictionaryType.LocationDriver,
                DictionaryType.ActivityType,
                DictionaryType.Block,
                DictionaryType.Division,
                DictionaryType.Segment,
                DictionaryType.Product,
                DictionaryType.Regionality,
                DictionaryType.Territory,
            ].map((item) => getFieldValueByName(item, fields) as string);

            const res: CreateBudgetItemForm = {
                id,
                briefId: briefId || null,
                responsibleIds: responsibleIds.length ? responsibleIds : null,
                activityId: null,
                budgetId: null,
                dictionaryIds: lodash.compact(dictionaryIds),
                plannedFunds,
                businessTarget,
                customerName,
                comment,
                sapComment,
                realizationStart: realizationStart ? realizationStart.toDate() : null,
                realizationEnd: realizationEnd ? realizationEnd.toDate() : null,
            };

            if (factPreviousPeriod) {
                res.factPreviousPeriod = convertAmountToCopecks(`${factPreviousPeriod}`);
            }
            if (planPreviousPeriod) {
                res.planPreviousPeriod = convertAmountToCopecks(`${planPreviousPeriod}`);
            }
            if (sourceId) {
                res.sourceBudgetItemId = sourceId;
            }

            return res;
        });
    },
);

export const makeUpdatedBudgetsItems = createSelector(
    getPlannedBudgetEditPageState,
    (pageState: PageState): UpdateBudgetItemForm[] => {
        const { budgetItemForms } = pageState;

        const filteredBudgetItemForms = budgetItemForms.filter((budgetItemForm) => {
            const { isNew, deleted } = budgetItemForm;

            return !isNew && !deleted;
        });

        return filteredBudgetItemForms.map((form) => {
            const { id, fields, briefId, transferDestinationId } = form;

            const responsibleIds = getFieldValueByName('responsible', fields) as number[];
            const comment = getFieldValueByName('comment', fields) as string;
            const sapComment = getFieldValueByName('sapComment', fields) as string;
            const businessTarget = getFieldValueByName('businessTarget', fields) as string;
            const customerName = getFieldValueByName('customerName', fields) as string;
            const realizationStart = getFieldValueByName('startDate', fields) as moment.Moment;
            const realizationEnd = getFieldValueByName('endDate', fields) as moment.Moment;
            const plannedFunds = getPlannedFundsFromFields(fields);
            const factPreviousPeriod = getFieldValueByName('factPreviousPeriod', fields) as number;
            const planPreviousPeriod = getFieldValueByName('planPreviousPeriod', fields) as number;

            const dictionary = [
                DictionaryType.Direction,
                DictionaryType.Tool,
                DictionaryType.Item,
                DictionaryType.Resource,
                DictionaryType.CostCenter,
                DictionaryType.LocationDriver,
                DictionaryType.ActivityType,
                DictionaryType.Block,
                DictionaryType.Division,
                DictionaryType.Segment,
                DictionaryType.Product,
                DictionaryType.Regionality,
                DictionaryType.Territory,
            ].reduce((acc, dictionaryType) => {
                return {
                    ...acc,
                    [dictionaryType]: getFieldValueByName(dictionaryType, fields) as string,
                };
            }, {} as { [key in DictionaryType]: string | null });

            const updateParams: UpdateBudgetItemForm = {
                id,
                briefId: briefId || null,
                responsibleIds,
                dictionary,
                plannedFunds,
                businessTarget,
                customerName,
                comment,
                sapComment,
                realizationStart: realizationStart ? realizationStart.toDate() : null,
                realizationEnd: realizationEnd ? realizationEnd.toDate() : null,
            };

            if (factPreviousPeriod) {
                updateParams.factPreviousPeriod = convertAmountToCopecks(`${factPreviousPeriod}`);
            }
            if (planPreviousPeriod) {
                updateParams.planPreviousPeriod = convertAmountToCopecks(`${planPreviousPeriod}`);
            }

            if (transferDestinationId) {
                updateParams.activityId = transferDestinationId;
            }

            return updateParams;
        });
    },
);

export const makeDeletedBudgetsItemsIds = createSelector(
    getPlannedBudgetEditPageState,
    (pageState: PageState): string[] => {
        const { budgetItemForms, budgetItems } = pageState;

        const budgetItemsIds = budgetItems.map((budgetItem) => budgetItem.id);

        return budgetItemsIds.filter((budgetItemId) => {
            const currentBudgetItemForm = budgetItemForms.find((budgetItemForm) => budgetItemForm.id === budgetItemId);
            return Boolean(currentBudgetItemForm && currentBudgetItemForm.deleted);
        });
    },
);

export const checkActivityFormValidation = createSelector(
    getPlannedBudgetEditPageState,
    (pageState: PageState): boolean => {
        const { activityForm } = pageState;

        const fieldsAreValid = activityForm.fields
            .filter((item) => lodash.includes(activityBudgetRequiredFields, item.name))
            .every((item) => !!item.value);

        const activityNameField = activityForm.fields.find((item) => item.name == 'name');

        let activityNameIsValid = true;

        if (activityNameField) {
            activityNameIsValid = activityNameField.value && (activityNameField.value as string).length >= 3;
        }

        return fieldsAreValid && activityNameIsValid;
    },
);

export const checkBudgetItemFormsValidation = createSelector(
    getPlannedBudgetEditPageState,
    (pageState: PageState): boolean => {
        return pageState.budgetItemForms.every((form) => {
            const formHasFilledMonthAmmountPair = form.fields.some(
                (item, index) =>
                    item.name === 'month' &&
                    item.value &&
                    form.fields[index + 1].name === 'plannedAmount' &&
                    form.fields[index + 1].value,
            );

            if (!form.deleted) {
                const fieldsAreValid = form.fields
                    .filter((item) => lodash.includes(budgetItemRequiredToCreateFields, item.name))
                    .every((item) => {
                        if (!item.disabled) {
                            if (item.type === 'select' && item.items && !item.items.length) {
                                return true; // no values means we should not need to fill this value
                            } else if (item.name === 'month' || item.name === 'plannedAmount') {
                                return formHasFilledMonthAmmountPair || !!item.value;
                            }

                            return !!item.value;
                        }

                        return true;
                    });

                const sapCommentField = form.fields.find((item) => item.name == 'sapComment');

                let sapCommentIsValid = true;

                if (sapCommentField) {
                    sapCommentIsValid = sapCommentField.value && (sapCommentField.value as string).length >= 3;
                }

                const startDateField = form.fields.find((item) => item.name == 'startDate');
                const endDateField = form.fields.find((item) => item.name == 'endDate');

                let datesAreValid = true;

                if (startDateField && endDateField) {
                    datesAreValid = !startDateField.errorMessage && !endDateField.errorMessage;
                }

                return fieldsAreValid && sapCommentIsValid && datesAreValid;
            }

            return true;
        });
    },
);

export const getUnvalidFields = createSelector(getPlannedBudgetEditPageState, (state: PageState): FormField[] => {
    const { activityForm, budgetItemForms } = state;

    const budgetItemFormsWithErrorMessages = budgetItemForms.filter((form) =>
        form.fields.some((field) => field.errorMessage),
    );

    return [
        ...activityForm.fields,
        ...(budgetItemFormsWithErrorMessages.length === 1 ? budgetItemFormsWithErrorMessages[0].fields : []),
    ].filter((field) => !!field.errorMessage);
});

export const getChangedBriefState = createSelector(
    getPlannedBudgetEditPageState,
    ({ changedBriefs }: PageState) => changedBriefs,
);

export const getFieldById = createSelector(
    getChangedBriefState,
    (state: StoreState, params: { id: string; briefId: string }): { id: string; briefId: string } => params,
    (changedBriefs: lodash.Dictionary<Brief>, { id, briefId }: { id: string; briefId: string }): BriefField => {
        const blocks = (changedBriefs[briefId].blocks as BriefBlock[]) || [];

        const fields = lodash.flatMap(blocks, (item) => item.fields);

        return fields.find((item) => item.id == id);
    },
);

export const getFieldsById = createSelector(
    getChangedBriefState,
    (
        state: StoreState,
        params: { id: string; uniqId: number; parentUniqId: number; briefId: string },
    ): { id: string; uniqId: number; parentUniqId: number; briefId: string } => params,
    (
        changedBriefs: lodash.Dictionary<BriefViewResponse>,
        {
            id,
            uniqId = 0,
            parentUniqId = 0,
            briefId,
        }: { id: string; uniqId: number; parentUniqId: number; briefId: string },
    ): { field: BriefField; fields: BriefField[] } => {
        const blocks = (changedBriefs[briefId].blocks as BriefBlock[]) || [];

        const fields = lodash.flatMap(blocks, (item) => item.fields);
        const field = fields.find(
            (item) => item.id == id && (item.uniqId || 0) === uniqId && (item.parentUniqId || 0) === parentUniqId,
        );

        if ((uniqId || parentUniqId) && !field) {
            const fieldOrigin = fields.find((item) => item.id == id);

            return {
                field: {
                    ...fieldOrigin,
                    value: {},
                },
                fields,
            };
        } else {
            return { field, fields };
        }
    },
);

export const checkFieldValidityById = createSelector(
    getFieldsById,
    ({ field, fields }: { field: BriefField; fields: BriefField[] }): boolean => {
        return checkFieldValidity(field, fields);
    },
);

export const getFirstUnvalidFieldScrollId = createSelector(getPlannedBudgetEditPageState, (state) => {
    const { activityForm, budgetItemForms } = state;

    const unvalidActivityFormFields = activityForm.fields.filter((field) => !!field.errorMessage);
    const unvalidBudgetItemForm = budgetItemForms.find((form) => form.fields.some((field) => !!field.errorMessage));

    if (unvalidActivityFormFields.length) {
        return unvalidActivityFormFields[0].name;
    }

    if (unvalidBudgetItemForm) {
        const unvalidBudgetItemFormField = unvalidBudgetItemForm.fields.find((field) => !!field.errorMessage);

        return `${unvalidBudgetItemForm.id}-${unvalidBudgetItemFormField.name}`;
    }

    return null;
});

export const getUnvalidFieldsNames = createSelector(getPlannedBudgetEditPageState, (state: PageState): string[] => {
    const { activityForm, budgetItemForms } = state;

    const unvalidFieldsNames = [
        ...activityForm.fields,
        ...lodash.flatMap(budgetItemForms, (budgetItemForm) => budgetItemForm.fields),
    ]
        .filter((field) => !!field.errorMessage)
        .map((field) => field.title);

    return lodash.uniq(unvalidFieldsNames);
});

function checkFieldValidity(field: BriefField, fields: BriefField[]): boolean {
    const isRequired = lodash.get(field, 'properties.isRequired');
    const switchPropertyId = lodash.get(field, 'properties.switchPropertyId');
    const isRequiredField = switchPropertyId
        ? isRequired && fields.some((parent) => parent.value?.switches?.some((item) => item.id === switchPropertyId))
        : isRequired;

    return (isRequiredField ? checkFieldValue(field) : true) && checkFieldFormat(field);
}

export function checkFieldValue(field: BriefField): boolean {
    const { value, type } = field;

    if (!value) {
        return false;
    }

    let fieldHasValue = true;

    switch (type) {
        case FieldType.TEXT:
            fieldHasValue = !!(value.text || value.date || value.from || value.to || value.values);
            break;

        case FieldType.DROPDOWN:
            fieldHasValue = !!value.selected;
            break;

        case FieldType.SWITCH_GROUP:
            fieldHasValue = (value.switches && value.switches.some((item) => item.value)) || !!value.selected;
            break;

        case FieldType.UNLOCKABLE_INPUT:
            fieldHasValue = value.unlocked && !!value.text;
            break;

        case FieldType.FILE:
            fieldHasValue = !lodash.isEmpty(value.files);
            break;
    }

    return fieldHasValue;
}

export function checkFieldFormat(field: BriefField): boolean {
    const isUrl = lodash.get(field, 'properties.isUrl');
    const { value, type } = field;

    switch (type) {
        case FieldType.TEXT:
            if (value && value.text && isUrl) {
                const linkRegExp =
                    /\b(((http(s)?:\/\/)([\w-]{1,32}(\.|\:)[\w-]{1,32}))|([\w-]{1,32}(\@)[\w-]{1,32}(\.)[\w-]{1,32})|([\w-]{1,32}(\.)[A-Za-z]{1,32}))\b/gi;
                return linkRegExp.test(value.text);
            }
            break;
    }

    return true;
}

function getPlannedFundsFromFields(fields: FormField[]): MonthValue {
    const budgetFields = fields.filter((item) => item.name == 'month' || item.name == 'plannedAmount');
    const pairs = lodash.chunk(budgetFields, 2);

    return MONTHS_KEYS_OF_FONDS.reduce((acc, currentMonthKey) => {
        const pair = findPairByMonthKey(pairs, currentMonthKey);
        const havePairWithCurrentMonthKey = Boolean(pair);

        if (havePairWithCurrentMonthKey) {
            const currentAmount = pair[INDEX_OF_AMOUNT_FIELD_IN_PAIR].value || 0;

            return {
                ...acc,
                [currentMonthKey]: convertAmountToCopecks(String(currentAmount)),
            };
        }

        return {
            ...acc,
            [currentMonthKey]: 0,
        };
    }, {} as MonthValue);
}

function findPairByMonthKey(pairs: FormField[][], monthKey: Month): FormField[] | undefined {
    return pairs.find((pair) => {
        const pairMonthKey = pair[INDEX_OF_MONTH_FIELD_IN_PAIR].value;
        return pairMonthKey === monthKey;
    });
}

function convertAmountToCopecks(amount: string): number {
    return Money.fromStringRoubles(amount).getCopecks();
}

function getFieldValueByName(name: string, fields: FormField[]): FieldValue | FieldValue[] {
    const values = fields.filter((item) => item.name == name).map((item) => item.value);

    return values.length > 1 ? values : lodash.first(values);
}
