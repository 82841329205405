import * as React from 'react';
import { useDispatch } from 'react-redux';

import { ParamsEditor } from './ParamsEditor';
import { GeneratedTextEditor } from './GeneratedTextEditor';
import { CreationFormContext } from './CreationFormContext';

import { reset } from '@store/autopilot/generationTextForm';

import * as styles from './styles.scss';

interface Props {
    onClose: () => void;
}

export const CreationForm: React.FC<Props> = ({ onClose }) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, []);

    return (
        <div className={styles.root}>
            <CreationFormContext.Provider value={{ close: onClose }}>
                <ParamsEditor />
                <GeneratedTextEditor />
            </CreationFormContext.Provider>
        </div>
    );
};
