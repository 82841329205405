import { createSelector } from 'reselect';

import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type {
    AutopilotRadio,
    AutopilotRadioTargetGroup,
    AutopilotRadioMediaplanPlanDataResponse as AutopilotRadioMediaplan,
} from 'sber-marketing-types/backend';
import type { PageState, BriefStageForm, GroupedDictionaries, Sidebar } from './types';
import { AutopilotStage } from './types';

import type { StoreState } from '../';

export const getAutopilotPageState = (state: StoreState): PageState => state.autopilotRadio;

export const getAutopilot = createSelector(getAutopilotPageState, (state: PageState): AutopilotRadio => {
    return state.autopilot;
});

export const getActivity = createSelector(getAutopilotPageState, (state: PageState): Activity => {
    return state.activity;
});

export const getMediaplan = createSelector(getAutopilotPageState, (state: PageState): AutopilotRadioMediaplan => {
    return state.mediaplan;
});

export const getTargetGroups = createSelector(
    getAutopilotPageState,
    (state: PageState): AutopilotRadioTargetGroup[] => {
        return state.targetGroups;
    },
);

export const getDictionaries = createSelector(getAutopilotPageState, (state: PageState): GroupedDictionaries => {
    return state.dictionaries;
});

export const getBriefStageForm = createSelector(getAutopilotPageState, (state: PageState): BriefStageForm => {
    return state.briefStageForm;
});

export const getSelectedBudgetItemIds = createSelector(getAutopilotPageState, (state: PageState): string[] => {
    return state.selectedBudgetItemIds;
});

export const getCurrentStage = createSelector(getAutopilotPageState, (state: PageState): AutopilotStage => {
    return state.currentStage;
});

export const getPreloaderStatus = createSelector(getAutopilotPageState, (state: PageState): boolean => {
    return state.preloader;
});

export const getSidebar = createSelector(getAutopilotPageState, (state: PageState): Sidebar => {
    return state.sidebar;
});
