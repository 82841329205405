import { axios, AxiosResponse } from '../lib/axios';

import {
    SearchQueryParams,
    SearchResponse,
    FileSearchResult,
    ProjectSearchResult,
    TaskSearchResult,
    CommentSearchResult,
} from '@mrm/search';

const BASE_URL = '/api/search';

export class SearchApi {
    public static async getActivities(params: SearchQueryParams): Promise<SearchResponse<ProjectSearchResult>> {
        const res: AxiosResponse<SearchResponse<ProjectSearchResult>> = await axios.get(`${BASE_URL}/project`, {
            params,
        });

        return res.data;
    }

    public static async getTasks(params: SearchQueryParams): Promise<SearchResponse<TaskSearchResult>> {
        const res: AxiosResponse<SearchResponse<TaskSearchResult>> = await axios.get(`${BASE_URL}/task`, { params });

        return res.data;
    }

    public static async getComments(params: SearchQueryParams): Promise<SearchResponse<CommentSearchResult>> {
        const res: AxiosResponse<SearchResponse<CommentSearchResult>> = await axios.get(`${BASE_URL}/comment`, {
            params,
        });

        return res.data;
    }

    public static async getFiles(params: SearchQueryParams): Promise<SearchResponse<FileSearchResult>> {
        const res: AxiosResponse<SearchResponse<FileSearchResult>> = await axios.get(`${BASE_URL}/file`, { params });

        return res.data;
    }
}
