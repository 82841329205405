import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { DepartmentAttributes } from 'sber-marketing-types/frontend';
import { uniq, uniqBy, groupBy } from 'lodash';

import { LoadingStatus } from '@store/commonTypes';

import * as actions from './actions';

import {
    DepartmentsState as State,
    SetStoreIdsParams,
    SetLoadingStatusParams,
    StoreTypes,
    EntitiesStore,
} from './types';

class Reducer {
    public static emptyState(): State {
        return {
            entities: [],
            byIds: {},
            byParentIds: {},
            byOrganizationIds: {},
            stores: {
                [StoreTypes.GENERAL]: Reducer.emptyEntitiesStore(),
                [StoreTypes.ALL_DEPARTMENTS]: Reducer.emptyEntitiesStore(),
                [StoreTypes.ACTIVITY_PAGE_FILTERS]: Reducer.emptyEntitiesStore(),
                [StoreTypes.MY_TASKS_FILTER]: Reducer.emptyEntitiesStore(),
                [StoreTypes.AVAILABLE_ACTIVITIES_RESPONSIBLE_FILTERS]: Reducer.emptyEntitiesStore(),
                [StoreTypes.MY_ACTIVITIES_RESPONSIBLE_FILTERS]: Reducer.emptyEntitiesStore(),
            },
        };
    }

    public static loadEntities(state: State, departments: DepartmentAttributes[]): State {
        const entities = uniqBy([...state.entities, ...departments], (department) => department.id);

        const byIds = departments.reduce(
            (acc, department) => ({
                ...acc,
                [department.id]: department,
            }),
            state.byIds,
        );

        const byParentIds = groupBy(entities, 'parentDepartmentId');

        const byOrganizationIds = groupBy(entities, 'organizationId');

        return {
            ...state,
            entities,
            byIds,
            byParentIds,
            byOrganizationIds,
        };
    }

    public static setStoreIds(state: State, payload: SetStoreIdsParams): State {
        const { store, ids } = payload;

        return Reducer.entitiesStoreReducer(state, store, (entitiesStore) => ({
            ...entitiesStore,
            ids: uniq([...entitiesStore.ids, ...ids]),
        }));
    }

    public static setLoadingStatus(state: State, payload: SetLoadingStatusParams): State {
        const { store, status } = payload;

        return Reducer.entitiesStoreReducer(state, store, (entitiesStore) => ({
            ...entitiesStore,
            loadingStatus: status,
        }));
    }

    public static resetStore(state: State, store: StoreTypes): State {
        return Reducer.entitiesStoreReducer(state, store, (_) => Reducer.emptyEntitiesStore());
    }

    public static incFetchersCount(state: State, store: StoreTypes): State {
        return Reducer.entitiesStoreReducer(state, store, (entitiesStore) => ({
            ...entitiesStore,
            fetchersCount: entitiesStore.fetchersCount + 1,
        }));
    }

    public static decFetchersCount(state: State, store: StoreTypes): State {
        return Reducer.entitiesStoreReducer(state, store, (entitiesStore) => ({
            ...entitiesStore,
            fetchersCount: entitiesStore.fetchersCount - 1,
        }));
    }

    private static entitiesStoreReducer(
        state: State,
        store: StoreTypes,
        entitiesStoreReducer: (state: EntitiesStore) => EntitiesStore,
    ): State {
        return {
            ...state,
            stores: {
                ...state.stores,
                [store]: entitiesStoreReducer(state.stores[store]),
            },
        };
    }

    private static emptyEntitiesStore(): EntitiesStore {
        return {
            loadingStatus: LoadingStatus.NOT_LOADED,
            ids: [],
            fetchersCount: 0,
        };
    }
}

export const departmentsReducer = reducerWithInitialState(Reducer.emptyState())
    .case(actions.loadEntities, Reducer.loadEntities)
    .case(actions.setStoreIds, Reducer.setStoreIds)
    .case(actions.setLoadingStatus, Reducer.setLoadingStatus)
    .case(actions.resetStore, Reducer.resetStore)
    .case(actions.incFetchersCount, Reducer.incFetchersCount)
    .case(actions.decFetchersCount, Reducer.decFetchersCount);
