import * as React from 'react';
import { sanitize } from 'dompurify';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { StoreState } from '@store';
import { getTaskPageState } from '@store/taskPage/selectors';

import { parseMessage } from '@common/lib';

import * as styles from './Description.scss';

interface Props {
    description: string;
    className?: string;
    qaId?: string;
}

export function Description({ description, className, qaId }: Props): JSX.Element {
    const users = useSelector((state: StoreState) => getTaskPageState(state).users);

    const parsedDescription = description ? parseMessage(description, styles.mention, users) : '';

    return (
        <div
            className={classNames(styles.description, className)}
            dangerouslySetInnerHTML={{
                __html: sanitize(parsedDescription, {
                    ALLOWED_TAGS: [
                        'a',
                        'hr',
                        'br',
                        'span',
                        'b',
                        'strong',
                        'i',
                        'em',
                        'color',
                        'ul',
                        'li',
                        'ol',
                        'div',
                        'p',
                        'h1',
                        'h2',
                        'h3',
                    ],
                    ALLOWED_ATTR: ['target', 'href', 'class', 'title', 'style', 'color'],
                }),
            }}
            {...{
                'qa-id': qaId,
            }}
        />
    );
}
