import * as React from 'react';

import { UserConfigType } from 'sber-marketing-types/openid';

import { CurrentPage } from '@modules/dashboards/DashboardsPage';
import { MyTasksPageTaskFilterMenu } from '@common/TaskFiltersMenu';
import { AvailableActivitiesListFilter, MyActivitiesListFilter } from '@common/ActivityListFilters';
import { useDispatch, useSelector } from 'react-redux';
import {
    DashboardUserConfig,
    getAvailableActivitiesFilterCount,
    getMyActivitiesFilterCount,
    getMyTasksFilterCount,
    saveUserConfig,
} from '@store/userConfig';
import {
    resetMyTasksFilters,
    resetMyActivitiesFilters,
    resetAvailableActivitiesFilters,
    resetRawFilters,
} from '@store/userConfig/dashboard/actions';
import { FilterButton } from '@common/FilterButton';

export interface DashboardFilterProps {
    currentPage: CurrentPage;
}

const countSelectors = {
    [CurrentPage.MyTasks]: getMyTasksFilterCount,
    [CurrentPage.AccessibleProjects]: getAvailableActivitiesFilterCount,
    [CurrentPage.MyProjects]: getMyActivitiesFilterCount,
} as const;

const resetActions = {
    [CurrentPage.MyTasks]: resetMyTasksFilters,
    [CurrentPage.AccessibleProjects]: resetAvailableActivitiesFilters,
    [CurrentPage.MyProjects]: resetMyActivitiesFilters,
} as const;

const storeFields: Record<CurrentPage, keyof DashboardUserConfig> = {
    [CurrentPage.MyTasks]: 'myTasksFilters',
    [CurrentPage.AccessibleProjects]: 'availableActivitiesFilters',
    [CurrentPage.MyProjects]: 'myActivitiesFilters',
};

const dashboardFilterContent = {
    [CurrentPage.MyTasks]: <MyTasksPageTaskFilterMenu />,
    [CurrentPage.AccessibleProjects]: <AvailableActivitiesListFilter />,
    [CurrentPage.MyProjects]: <MyActivitiesListFilter />,
} as const;

export const DashboardFilter: React.FC<DashboardFilterProps> = ({ currentPage }) => {
    const count = useSelector(countSelectors[currentPage]);
    const dispatch = useDispatch();

    const handleShow = () => {
        dispatch(resetRawFilters());
    };

    const handleReset = () => {
        const field = storeFields[currentPage];

        dispatch(resetActions[currentPage]());
        dispatch(
            saveUserConfig({
                type: UserConfigType.Dashboard,
                payload: {
                    [field]: {},
                },
            }),
        );
    };

    return (
        <FilterButton count={count} onReset={handleReset} onShow={handleShow}>
            {dashboardFilterContent[currentPage]}
        </FilterButton>
    );
};
