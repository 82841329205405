import * as React from 'react';
import classNames from 'classnames';
import * as lodash from 'lodash';
import { useSelector } from 'react-redux';
import { UserResponseParams } from 'sber-marketing-types/frontend';

import { BudgetItemStatus } from '@mrm/budget';

import { MultiReferenceDictionaryApi } from '@api';

import { StoreState } from '@store';
import {
    ColumnData,
    ColumnName,
    CustomCellType,
    TableLine,
    NumericCellValue,
    getBudgetPlanningPageState,
} from '@store/budgetPlanning';

import { LineColor, LineColorName } from '../TableBody';
import { TextCell, IndicatorsCell, InputCell, DropdownCell, DatepickerCell } from '../CellTypes';
import { LineCellsParams, CellParams } from '../LayerManager/LayerManager';
import { LineMenu } from './LineMenu';

import * as style from './Line.scss';

export const LINE_MENU_WIDTH = 184;

function useStore() {
    return useSelector((state: StoreState) => {
        const { showRejectionCommentPopup } = getBudgetPlanningPageState(state);

        return {
            showRejectionCommentPopup,
        };
    });
}

export interface CellRef {
    focus: () => void;
}

interface Props {
    line: TableLine;
    cellsParams: { [lineId: string]: LineCellsParams };
    columns: ColumnData[];
    visibleColumnsNames: ColumnName[];
    fixedColumnsNames: ColumnName[];
    columnsWidth: { [columnName: string]: number };
    width: number;
    lineColor: LineColor;
    hoveredLineId: string;
    hoveredColumnName: string;
    draggedEdgeColumnName: string;
    lineUnsavedStatus: BudgetItemStatus.Approved | BudgetItemStatus.Rejected | BudgetItemStatus.OnExpertApprovement;
    lineHasUnsavedChanges: boolean;
    userIsBudgetExpert: boolean;
    userIsSupervisor: boolean;
    allUsers: UserResponseParams[];
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi;
    fixedColumnsLinesRef: (element: HTMLDivElement) => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
    onInputValueChange: (lineId: string, columnName: ColumnName, value: string, originalValue: string) => void;
    onDropdownCellClick: (
        lineId: string,
        columnName: ColumnName,
        dropdownContent: JSX.Element,
        contentHeight: number,
    ) => void;
    onDropdownItemSelection: (lineId: string, columnName: ColumnName, id: string, originalId: string) => void;
    onDatepickerCellClick: (
        lineId: string,
        columnName: ColumnName,
        datepickerContent: JSX.Element,
        contentHeight: number,
    ) => void;
    onDatepickerValueChange: (lineId: string, columnName: ColumnName, value: Date, originalValue: Date) => void;
    onApproveButtonClick: (lineId: string) => void;
    onRejectButtonClick: (lineId: string) => void;
    onInfoMouseEnter: (lineId: string) => void;
    onInfoMouseLeave: () => void;
    onCellCopy: (lineId: string, columnName: ColumnName, event: React.ClipboardEvent<HTMLDivElement>) => void;
    registerCellRef(columnName: ColumnName, ref: HTMLDivElement): void;
    registerCellContentRef(columnName: ColumnName, ref: CellRef): void;
    focusField(columnName: ColumnName): void;
}

export const Line = ({
    line,
    cellsParams,
    columns,
    visibleColumnsNames,
    fixedColumnsNames = [],
    columnsWidth,
    width,
    lineColor,
    hoveredLineId,
    hoveredColumnName,
    draggedEdgeColumnName,
    lineUnsavedStatus,
    lineHasUnsavedChanges,
    userIsBudgetExpert,
    userIsSupervisor,
    allUsers,
    multiReferenceDictionaryApi,
    fixedColumnsLinesRef,
    onMouseEnter,
    onMouseLeave,
    onInputValueChange,
    onDropdownCellClick,
    onDropdownItemSelection,
    onDatepickerCellClick,
    onDatepickerValueChange,
    onApproveButtonClick,
    onRejectButtonClick,
    onInfoMouseEnter,
    onInfoMouseLeave,
    onCellCopy,
    registerCellRef,
    registerCellContentRef,
    focusField,
}: Props): JSX.Element => {
    const { showRejectionCommentPopup } = useStore();

    const LINE_STATUS_COLOR = {
        [BudgetItemStatus.Draft]: LineColor.Draft,
        [BudgetItemStatus.Disabled]: LineColor.Disabled,
        [BudgetItemStatus.Approved]: LineColor.Approved,
        [BudgetItemStatus.Rejected]: LineColor.Rejected,
    };

    const lineIsHovered = line.id == hoveredLineId;

    const nonfixedColumns = columns.filter((item) => !lodash.includes(fixedColumnsNames, item.name));

    const fixedColumns = fixedColumnsNames
        .filter((item) => columns.some((column) => column.name == item))
        .map((item) => columns.find((column) => column.name == item));

    const fixedColumnsWidthSum = fixedColumns.reduce((acc, item) => acc + columnsWidth[item.name], 0);

    const statusColor = LINE_STATUS_COLOR[line.status];

    return (
        <div
            className={style.root}
            onMouseEnter={showRejectionCommentPopup ? null : onMouseEnter}
            onMouseLeave={showRejectionCommentPopup ? null : onMouseLeave}
            style={{ backgroundColor: `rgb(${statusColor || lineColor})` }}
            {...{
                'data-table-line-id': `prefix${line.id}`,
                'qa-id': 'tableLine',
                'qa-line-id': line.id,
                'qa-line-serial-number': (line.fields[ColumnName.Id] as NumericCellValue).number,
                'qa-line-color': LineColorName[statusColor || lineColor],
                'qa-activity-name': line.fields[ColumnName.ActivityName],
            }}
        >
            <div
                className={classNames(style.cells, lineIsHovered && style.lineHover)}
                style={{
                    paddingLeft: fixedColumnsWidthSum,
                    paddingRight: LINE_MENU_WIDTH,
                }}
            >
                {nonfixedColumns.map((column) => {
                    const cellParams = cellsParams[line.id][column.name];

                    return (
                        <div
                            ref={(ref) => registerCellRef(column.name, ref)}
                            className={classNames(
                                style.cell,
                                column.name == draggedEdgeColumnName && style.dragged,
                                column.name == hoveredColumnName && style.columnHover,
                                cellParams.displayValidationError && style.validationError,
                            )}
                            style={{ width: columnsWidth[column.name] }}
                            key={column.name}
                            {...{
                                'qa-id': 'tableCellWrapper',
                                'qa-index': column.name,
                            }}
                        >
                            {lodash.includes(visibleColumnsNames, column.name) && renderCell(cellParams, column)}
                        </div>
                    );
                })}
            </div>

            <div className={style.fixedColumns} ref={fixedColumnsLinesRef} style={{ width }}>
                {fixedColumns && (
                    <div
                        className={style.leftFixedColumns}
                        style={{ backgroundColor: `rgb(${statusColor || lineColor})` }}
                    >
                        <div className={classNames(style.cells, lineIsHovered && style.lineHover)}>
                            {fixedColumns.map((column) => {
                                const cellParams = cellsParams[line.id][column.name];

                                return (
                                    <div
                                        ref={(ref) => registerCellRef(column.name, ref)}
                                        className={classNames(
                                            style.cell,
                                            column.name == draggedEdgeColumnName && style.dragged,
                                            column.name == hoveredColumnName && style.columnHover,
                                            cellParams.displayValidationError && style.validationError,
                                        )}
                                        style={{ width: columnsWidth[column.name] }}
                                        key={column.name}
                                        {...{
                                            'qa-id': 'tableCellWrapper',
                                            'qa-index': column.name,
                                        }}
                                    >
                                        {renderCell(cellParams, column)}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}

                <div
                    className={style.rightFixedColumns}
                    style={{ backgroundColor: `rgb(${statusColor || lineColor})` }}
                >
                    <div className={classNames(style.cells, lineIsHovered && style.lineHover)}>
                        <div className={style.lineMenu}>
                            <LineMenu
                                activityId={line.activityId}
                                lineId={line.id}
                                backgroundColor={statusColor || lineColor}
                                lineIsHovered={lineIsHovered}
                                lineStatus={line.status}
                                onInfoMouseEnter={onInfoMouseEnter}
                                onInfoMouseLeave={onInfoMouseLeave}
                                canEdit={line.canEdit}
                                userIsSupervisor={userIsSupervisor}
                                canMoveToExpertApprovement={line.canMoveToExpertApprovement}
                            />
                        </div>

                        {/* {userIsBudgetExpert && line.status == BudgetItemStatus.OnExpertApprovement &&
                            <div className={style.statusCell}>
                                <StatusCell
                                    disabled={lineHasUnsavedChanges}
                                    isApproved={lineUnsavedStatus == BudgetItemStatus.Approved}
                                    isRejected={lineUnsavedStatus == BudgetItemStatus.Rejected}
                                    onApproveButtonClick={onApproveButtonClick}
                                    onRejectButtonClick={onRejectButtonClick}
                                />
                            </div>
                        } */}

                        <div className={style.approvingCell}>
                            <IndicatorsCell
                                line={line}
                                lineIsHovered={lineIsHovered}
                                onLineMouseLeave={onMouseLeave}
                                onApproveButtonClick={onApproveButtonClick}
                                onRejectButtonClick={onRejectButtonClick}
                                focusField={focusField}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    function renderCell(cellParams: CellParams, column: ColumnData): JSX.Element {
        return (
            <React.Fragment>
                {!column.customCellType && renderTextCell(cellParams, column)}

                {column.customCellType == CustomCellType.Input &&
                    renderInputCell(cellParams, column, registerCellContentRef)}

                {column.customCellType == CustomCellType.Dropdown &&
                    renderDropdownCell(cellParams, column, registerCellContentRef)}

                {column.customCellType == CustomCellType.Datepicker &&
                    renderDatepickerCell(cellParams, column, registerCellContentRef)}
            </React.Fragment>
        );
    }

    function renderTextCell(cellParams: CellParams, column: ColumnData): JSX.Element {
        return (
            <TextCell
                columnName={column.name}
                title={cellParams.title}
                tooltip={cellParams.tooltip}
                bgColor={cellParams.bgColor || column.customColumnColor}
                disabled={cellParams.disabled}
            />
        );
    }

    function renderInputCell(
        cellParams: CellParams,
        column: ColumnData,
        registerCellContentRef: (columnName: ColumnName, ref: CellRef) => void,
    ): JSX.Element {
        return (
            <InputCell
                lineId={line.id}
                columnName={column.name}
                title={cellParams.title}
                tooltip={cellParams.tooltip}
                value={cellParams.value as string}
                valueType={column.valueType}
                bgColor={cellParams.bgColor || column.customColumnColor}
                disabled={cellParams.disabled}
                cellInfo={cellParams.cellInfo}
                originalValue={cellParams.originalValue as string}
                ref={(ref) => registerCellContentRef(column.name, ref)}
                onValueChange={onInputValueChange}
                onCellCopy={onCellCopy}
            />
        );
    }

    function renderDropdownCell(
        cellParams: CellParams,
        column: ColumnData,
        registerCellContentRef: (columnName: ColumnName, ref: CellRef) => void,
    ): JSX.Element {
        return (
            <DropdownCell
                lineId={line.id}
                columnName={column.name}
                customTitle={cellParams.title}
                options={cellParams.options}
                selectedId={cellParams.value as string}
                bgColor={cellParams.bgColor || column.customColumnColor}
                disabled={cellParams.disabled}
                allowMultipleItems={cellParams.allowMultipleItems}
                originalValue={cellParams.originalValue as string}
                allUsers={allUsers}
                multiReferenceDictionaryApi={multiReferenceDictionaryApi}
                ref={(ref) => registerCellContentRef(column.name, ref)}
                onDropdownClick={onDropdownCellClick}
                onSelection={onDropdownItemSelection}
            />
        );
    }

    function renderDatepickerCell(
        cellParams: CellParams,
        column: ColumnData,
        registerCellContentRef: (columnName: ColumnName, ref: CellRef) => void,
    ): JSX.Element {
        return (
            <DatepickerCell
                lineId={line.id}
                columnName={column.name}
                title={cellParams.title}
                value={cellParams.value as Date}
                minValue={cellParams.minDate}
                maxValue={cellParams.maxDate}
                bgColor={cellParams.bgColor || column.customColumnColor}
                disabled={cellParams.disabled}
                tooltip={cellParams.tooltip}
                originalValue={cellParams.originalValue as Date}
                ref={(ref) => registerCellContentRef(column.name, ref)}
                onOpenerClick={onDatepickerCellClick}
                onValueChange={onDatepickerValueChange}
            />
        );
    }
};
