import * as React from 'react';

import { FinalStageWidgetTemplate } from './FinalStageWidgetTemplate';

interface Props {}

export class FinalStageWidgetBehaviour extends React.PureComponent<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        return React.createElement(FinalStageWidgetTemplate, {});
    }
}
