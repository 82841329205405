import * as React from 'react';
import autobind from 'autobind-decorator';
import { graphql, compose } from 'react-apollo';
import { v4 } from 'uuid';

import { FileAsset } from '@store/commonTypes';

import { CREATE_COMMENT_MUTATION } from './query';

export interface ChildrenProps {
    createComment: (text: string, files: FileAsset[]) => Promise<void>;
}

interface Props extends ExternalProps, QueryProps {}

interface ExternalProps {
    parrentCommentId?: string;
    onCommentCreated: (commentId: string) => void;
    children: (props: ChildrenProps) => JSX.Element;
}

type MutationFunc<V> = (params: { variables: V }) => Promise<void>;
type MutationResult = {
    loading: boolean;
};

type QueryProps = {
    createCommentMutation: MutationFunc<{
        id: string;
        text: string;
        fileIds?: any[];
    }>;
    createCommentMutationResult: MutationResult;
};

class WithCreateCommentMutationWrapper extends React.PureComponent<Props> {
    public render(): JSX.Element {
        return this.props.children(this.getChildrenProps());
    }

    private getChildrenProps(): ChildrenProps {
        return {
            createComment: this.createComment,
        };
    }

    @autobind
    private async createComment(text: string, files: FileAsset[]): Promise<void> {
        const id = v4();
        const fileIds = files.map((file) => file.id);

        const variables: any = { id, text, fileIds };
        if (this.props.parrentCommentId) {
            variables.replyId = this.props.parrentCommentId;
        }

        await this.props.createCommentMutation({ variables });

        this.props.onCommentCreated(id);
    }
}

export const WithCreateCommentMutation = compose(
    graphql<ExternalProps>(CREATE_COMMENT_MUTATION, { name: 'createCommentMutation' }),
)(WithCreateCommentMutationWrapper) as React.ComponentType<ExternalProps>;
