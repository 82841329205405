import * as React from 'react';
import type { FieldValue } from 'sber-marketing-types/frontend';

import { BudgetItemApi } from '@api';
import { BudgetItem } from '@mrm/budget';
import { ActivityReferenceData } from '@store/brief/types';

import { ActivityReferenceMenu } from './ActivityReferenceMenu';

interface Props {
    showPreloader: boolean;
    budgetId: string;
    budgetItems: BudgetItem[];
    divisionId: string;
    departmentId: string;
    value?: FieldValue;
    selectedActivityId: number;
    selectedBudgetItemIds: string[];
    setActivityReferenceData: (data: ActivityReferenceData) => void;
    onSaveButtonClick: () => void;
    onCancelButtonClick: () => void;
}

export class ActivityReferenceMenuContainer extends React.Component<Props> {
    public componentDidMount(): void {
        BudgetItemApi.getBudgetItemList({
            budgetId: this.props.budgetId,
            filter: {
                // dictionaryIds: [['division', this.props.divisionId]],
                // departmentId: this.props.departmentId,
            },
        }).then((response) => {
            this.props.setActivityReferenceData({
                selectedBudgetItemIds: (this.props.value?.selected as string[]) || [],
                selectedActivityId: this.props.selectedActivityId,
                filters: {
                    divisionIds: [this.props.divisionId],
                },
                budgetItems: response,
            });
        });
    }

    public render(): JSX.Element {
        const { showPreloader, budgetId, budgetItems, selectedActivityId, onSaveButtonClick, onCancelButtonClick } =
            this.props;

        return (
            <ActivityReferenceMenu
                showPreloader={showPreloader}
                budgetId={budgetId}
                budgetItems={budgetItems}
                selectedActivityId={selectedActivityId}
                onCancelButtonClick={onCancelButtonClick}
                onSaveButtonClick={onSaveButtonClick}
            />
        );
    }
}
