import * as React from 'react';
import { useGetAuthUserQuery, useGetUserQuery } from '@api';
import { Flex, FlexProps } from '@common/components';

export type UserFlexProps<E extends keyof JSX.IntrinsicElements = 'div'> = FlexProps<E> & {
    userId: number;
    before?: React.ReactNode;
    after?: React.ReactNode;
};

export function UserFlex<E extends keyof JSX.IntrinsicElements = 'div'>({
    userId,
    children,
    loading,
    before,
    after,
    ...props
}: UserFlexProps<E>) {
    const { data: authUser, isLoading: isAuthUserLoading } = useGetAuthUserQuery();
    const isMe = authUser?.user.attributes.id === userId;
    const { data: user, isLoading: isUserLoading } = useGetUserQuery({ id: userId }, { skip: isMe });
    const isLoading = isAuthUserLoading || isUserLoading;

    const content = isMe ? 'Вы' : `${user?.firstName} ${user?.secondName}`;

    return (
        <Flex inline {...(props as any)} loading={loading || isLoading}>
            {before}
            {content}
            {children}
            {after}
        </Flex>
    );
}
