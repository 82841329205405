import { BuildData, StatisticsTaskType } from './types';
import { Color } from '../../../../common/enums';

export const buildTaskData: BuildData<'tasks'> = ({ statisticsItem, statisticsTypeFilters }) => ({
    id: statisticsItem.id,
    name: statisticsItem.meta.name,
    bar: {
        first: {
            value: statisticsTypeFilters[StatisticsTaskType.CLOSED] ? statisticsItem.statistics.current.closedCount : 0,
            color: Color.BLUE,
        },
        second: {
            value: statisticsTypeFilters[StatisticsTaskType.ACTIVE] ? statisticsItem.statistics.current.activeCount : 0,
            color: Color.GREEN,
        },
        third: {
            value: statisticsTypeFilters[StatisticsTaskType.COMING_DEADLINE]
                ? statisticsItem.statistics.current.withDeadlineApproachingCount
                : 0,
            color: Color.MUSTARD,
        },
        fourth: {
            value: statisticsTypeFilters[StatisticsTaskType.OVERDUE]
                ? statisticsItem.statistics.current.overdueCount
                : 0,
            color: Color.RED,
        },
    },
});
