import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { UserConfigType } from 'sber-marketing-types/openid';

import { StoreState } from '@store';
import { updateUserConfig } from '@store/userConfig';

import { PersonalDashboardUserConfig, UpdatePersonalDashboardUserConfig } from './types';
import * as actions from './actions';

export const loadPersonalDashboardUserConfig = bindThunkAction<
    StoreState,
    PersonalDashboardUserConfig,
    PersonalDashboardUserConfig,
    Error
>(actions.loadPersonalDashboardUserConfig, async (receivedUserConfig, dispatch, getState) => {
    return receivedUserConfig;
});

export const updatePersonalDashboardUserConfig = bindThunkAction<
    StoreState,
    UpdatePersonalDashboardUserConfig,
    void,
    Error
>(actions.updatePersonalDashboardUserConfig, async (payload, dispatch) => {
    dispatch(updateUserConfig(UserConfigType.PersonalDashboard));
});
