import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { isLoggedUserBudgetExpert } from '@store/user';
import {
    ComponentState,
    getBudgetTransferMenuState,
    initPlanTransfer,
    getTotalTransferAmountForSource,
    getTotalTransferAmountForDest,
} from '@store/budgetExecution/budgetTransferMenu';

import * as styles from './InitTransferButton.scss';

function useStore() {
    const dispatch = useDispatch();

    const isRequestInProgress = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.isRequestInProgress,
    );
    const disabled = useSelector((state: StoreState) => {
        const {
            controls: { componentState },
            participatorData: { participatorItemId, participatorComment },
            experts: { selectedExpert },
            cells: { from, to },
        } = getBudgetTransferMenuState(state);
        const userIsBudgetExpert = isLoggedUserBudgetExpert(state);

        let transferAmount: number;
        switch (componentState) {
            case ComponentState.ExternalOutcomeTransfer:
                transferAmount = getTotalTransferAmountForSource(state, from[0].lineId);
                break;
            case ComponentState.ExternalIncomeTransfer:
                transferAmount = getTotalTransferAmountForDest(state, to[0].lineId);
                break;
            default:
                transferAmount = 0;
                break;
        }

        return !(
            transferAmount &&
            participatorItemId &&
            participatorComment &&
            (!userIsBudgetExpert ? selectedExpert : true)
        );
    });

    function initCorrections() {
        dispatch(initPlanTransfer(null));
    }

    return {
        isRequestInProgress,
        disabled,
        initCorrections,
    };
}

export function InitTransferButton(): JSX.Element {
    const { disabled, isRequestInProgress, initCorrections } = useStore();

    return (
        <div className={styles.root}>
            {isRequestInProgress && <div className={styles.rootDisabledMask} />}

            <Button
                qaId="budgetTransferMenuExternalnalTransitionMenuInitTransferButton"
                disabled={disabled}
                theme={ButtonTheme.GhostRoundedBlack}
                onClick={initCorrections}
            >
                {isRequestInProgress ? 'На согласование...' : 'На согласование'}
            </Button>
        </div>
    );
}
