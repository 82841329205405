import * as React from 'react';
import classNames from 'classnames';

import * as style from './DashboardSortingMenu.scss';

export interface Option {
    id: React.ReactText;
    title: string;
}

interface Props {
    options: Option[];
    selectedOptionId: React.ReactText;
    onSelect: (selectedOptionId: React.ReactText) => void;
}

export const DashboardSortingMenu = ({ options, selectedOptionId, onSelect }: Props): JSX.Element => {
    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'dashboardSortingMenu',
            }}
        >
            <div className={style.wrapper}>
                {options.map((option) => (
                    <DashboardSortingMenuItem
                        key={option.id}
                        {...option}
                        isSelected={option.id === selectedOptionId}
                        onSelect={onSelect}
                    />
                ))}
            </div>
        </div>
    );
};

interface ItemProps extends Option {
    isSelected: boolean;
    onSelect: (selectedOptionId: React.ReactText) => void;
}

const DashboardSortingMenuItem = ({ title, id, isSelected, onSelect }: ItemProps): JSX.Element => (
    <div
        className={classNames(style.sorting, isSelected && style.selected)}
        onClick={() => onSelect(id)}
        {...{
            'qa-id': id,
            'qa-value': title,
            'qa-is-selected': isSelected ? 'true' : 'false',
        }}
    >
        <div className={style.title}>{title}</div>
    </div>
);
