import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';
import * as styles from './styles.scss';

interface Props {
    items: Item[];
    selectedItemId: string;
    onClick: (id: string) => void;
}

export interface Item {
    id: string;
    title: string;
}

export const List: React.FC<Props> = ({ items, selectedItemId, onClick }) => {
    return (
        <div className={styles.root}>
            {items.map((item) => {
                const selected = item.id === selectedItemId;

                return (
                    <div
                        key={item.id}
                        className={classNames(styles.item, selected ? styles.disabledItem : '')}
                        onClick={!selected ? () => onClick(item.id) : () => {}}
                    >
                        <div>{item.title}</div>

                        {selected && (
                            <div className={styles.checkIconWrapper}>
                                <Icon type={IconType.CHECK_MARK} svgSize={10} />
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};
