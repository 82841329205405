// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-dashboard-RightSidebar-Comments-CommentsList-_CommentsList__root{padding-top:10px}.src-client-modules-dashboard-RightSidebar-Comments-CommentsList-_CommentsList__noCommentsStub{padding-top:15px;letter-spacing:0.15px;color:#000000;font-family:'Open Sans';font-size:12px;line-height:16px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/dashboard/RightSidebar/Comments/CommentsList/CommentsList.scss"],"names":[],"mappings":"AAAA,qFACI,gBAAiB,CACpB,+FAGG,gBAAiB,CAEjB,qBAAsB,CAEtB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,gBAAiB","sourcesContent":[".root {\n    padding-top: 10px;\n}\n\n.noCommentsStub {\n    padding-top: 15px;\n\n    letter-spacing: 0.15px;\n\n    color: #000000;\n\n    font-family: 'Open Sans';\n    font-size: 12px;\n    line-height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-dashboard-RightSidebar-Comments-CommentsList-_CommentsList__root",
	"noCommentsStub": "src-client-modules-dashboard-RightSidebar-Comments-CommentsList-_CommentsList__noCommentsStub"
};
export default ___CSS_LOADER_EXPORT___;
