import { bindActionCreators } from 'redux';
import { v4 as uuid } from 'uuid';
import * as lodash from 'lodash';

import type { PlainDictionary } from '@mrm/dictionary';
import type {
    AutopilotDOOH,
    AutopilotDOOHFormatWithTimings,
    AutopilotDOOHMediaplanCalculation,
} from 'sber-marketing-types/backend';
import type { User } from '@store/user/types';
import type { GroupedDictionaries } from '@store/autopilotDOOH/types';

import { store } from '@store';
import { getLoginUser } from '@store/user/selector';
import {
    loadAutopilot,
    loadActivity,
    loadMediaplan,
    loadRegionalities,
    loadFormats,
    loadDictionaries,
    setBriefFormValues,
    setMediaplanLinesBudgetSnapShot,
    setMediaplanLinesSideQuantitySnapShot,
    setManualMediaplanSideQuantityValues,
} from '@store/autopilotDOOH/actions';
import { getAutopilot, getMediaplan, getFormats } from '@store/autopilotDOOH/selectors';
import { loadUserOrganizations } from '@store/userOrganizations/thunks';
import { AutopilotDOOHApi, ActivityApi, DictionaryApi } from '@api';

interface StoreProps {
    user: User;
    autopilot: AutopilotDOOH;
    mediaplan: AutopilotDOOHMediaplanCalculation[];
    formats: AutopilotDOOHFormatWithTimings[];
}

export class Loader {
    private static instance: Loader;
    private activityId: number;

    private dispatch = bindActionCreators(
        {
            loadAutopilot,
            loadActivity,
            loadMediaplan,
            loadRegionalities,
            loadFormats,
            loadUserOrganizations,
            loadDictionaries,
            setBriefFormValues,
            setMediaplanLinesBudgetSnapShot,
            setMediaplanLinesSideQuantitySnapShot,
            setManualMediaplanSideQuantityValues,
        },
        store.dispatch,
    );

    public static getInstance(): Loader {
        if (!Loader.instance) {
            Loader.instance = new Loader();
        }
        return Loader.instance;
    }

    public async init(activityId: number) {
        this.activityId = activityId;

        await this.initPageData();
    }

    public async initPageData() {
        await this.loadAutopilot();

        await Promise.all([
            this.loadActivity(),
            this.loadCalculatedMediaplan(),
            this.initRegionsTableData(),
            this.loadRegionalities(),
            this.loadFormats(),
            this.loadUsersData(),
            this.loadDictionaries(),
        ]);

        this.updateFormatTimings();
    }

    public async loadAutopilot(): Promise<void> {
        let list = await AutopilotDOOHApi.getAutopilotList({ activityIds: [this.activityId] });

        let autopilot: AutopilotDOOH = list.find((item) => item.activityId === this.activityId);

        if (!autopilot) {
            await AutopilotDOOHApi.createAutopilot({ activityId: this.activityId, id: uuid() });
            list = await AutopilotDOOHApi.getAutopilotList({ activityIds: [this.activityId] });
            autopilot = list.find((item) => item.activityId === this.activityId);
        }

        this.dispatch.loadAutopilot(autopilot);
    }

    public async loadActivity(): Promise<void> {
        const activity = await ActivityApi.getActivity(this.activityId);

        this.dispatch.loadActivity(activity);
    }

    // public async loadMediaplan(): Promise<void> {
    //     const { autopilot } = this.getStoreProps();

    //     const mediaplan = await AutopilotDOOHApi.getMediaplan(autopilot.id);

    //     this.dispatch.loadMediaplan(mediaplan);
    // }

    public async loadCalculatedMediaplan(): Promise<void> {
        const { autopilot } = this.getStoreProps();

        if (autopilot.brief) {
            const mediaplan = await AutopilotDOOHApi.calculateMediaplan(autopilot.id);

            this.dispatch.loadMediaplan(mediaplan);
        }
    }

    public async loadRegionalities(): Promise<void> {
        const { autopilot } = this.getStoreProps();

        const regions = await AutopilotDOOHApi.getRegions(autopilot.id);

        this.dispatch.loadRegionalities(regions);
    }

    public async loadFormats(): Promise<void> {
        const { autopilot } = this.getStoreProps();

        const formats = await AutopilotDOOHApi.getFormats(autopilot.id);

        this.dispatch.loadFormats(formats);
    }

    public updateFormatTimings() {
        const { autopilot, mediaplan, formats } = this.getStoreProps();

        if (autopilot.brief) {
            const updatedFormatTimings: Record<string, number> = {};

            ['MF', 'DBB и DSS', 'DCF'].forEach((formatName) => {
                const format = formats.find((item) => item.abbreviation === formatName);

                updatedFormatTimings[formatName] = lodash.min(format.timings);

                const { selectedRows } = autopilot.brief;

                const foundLine = mediaplan.find(
                    (line) =>
                        line.format.abbreviation === formatName &&
                        selectedRows.some((item) => item.rowId === line.rowId),
                );

                if (foundLine) {
                    const { timing } = selectedRows.find((item) => item.rowId === foundLine.rowId);

                    if (format.timings.includes(timing)) {
                        updatedFormatTimings[formatName] = timing;
                    }
                }
            });

            this.dispatch.setBriefFormValues({ selectedFormatTimings: updatedFormatTimings });
        }
    }

    public initRegionsTableData() {
        const { autopilot } = this.getStoreProps();

        if (autopilot.brief) {
            const { budgetAutomation, selectedRows } = autopilot.brief;

            const manualMediaplanSideQuantityValues = selectedRows.reduce((acc, item) => {
                if (item.manual) {
                    acc[item.rowId] = item.sideQuantity;
                }

                return acc;
            }, {} as Record<string, number>);

            this.dispatch.setManualMediaplanSideQuantityValues(manualMediaplanSideQuantityValues);

            if (!budgetAutomation) {
                const linesBudgetsSnapShot: Record<string, number> = {};
                const linesSideQuantitySnapShot: Record<string, number> = {};

                selectedRows.forEach((item) => {
                    linesBudgetsSnapShot[item.rowId] = item.budget;
                    linesSideQuantitySnapShot[item.rowId] = item.sideQuantity;
                });

                this.dispatch.setMediaplanLinesBudgetSnapShot(linesBudgetsSnapShot);
                this.dispatch.setMediaplanLinesSideQuantitySnapShot(linesSideQuantitySnapShot);
            }
        }
    }

    public async loadUsersData(): Promise<void> {
        await this.dispatch.loadUserOrganizations(null);
    }

    public async loadDictionaries(): Promise<void> {
        const { user } = this.getStoreProps();

        const userOrganizationId = user.attributes.organizationId;

        const dictionaries = await DictionaryApi.getDictionaryList({
            organizationId: userOrganizationId,
            treeview: true,
        });

        const sortedDictionaries = this.sortDictionaries(dictionaries);

        const groupedDictionaries = this.groupDictionaries(sortedDictionaries);

        // if (userOrganizationId === '68a38c6a-79f7-49b5-9356-b779fbbb9b64') {
        //     // Сбербанк ДМИК
        //     groupedDictionaries['block'] = groupedDictionaries['block'].filter(
        //         (item) => item.id === 'e313aac8-aa95-4f45-bd97-52372a38e152',
        //     ); // Розничный бизнес
        // }

        this.dispatch.loadDictionaries(groupedDictionaries);
    }

    private sortDictionaries(dictionaries: PlainDictionary[]): PlainDictionary[] {
        return lodash.sortBy(dictionaries, (item) => item.value);
    }

    private groupDictionaries(dictionaries: PlainDictionary[]): GroupedDictionaries {
        return lodash.groupBy(dictionaries, (item) => item.type);
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        return {
            user: getLoginUser(storeState),
            autopilot: getAutopilot(storeState),
            mediaplan: getMediaplan(storeState),
            formats: getFormats(storeState),
        };
    }
}
