import * as React from 'react';
import classNames from 'classnames';

import * as styles from './SortingMenu.scss';

export enum Sorting {
    None = 'none',
    Asc = 'asc',
    Desc = 'desc',
}

interface Props {
    sorting: Sorting;
    onSortingChange: (sorting: Sorting) => void;
}

export function SortingMenu({ sorting, onSortingChange }: Props): JSX.Element {
    function onSelect(destSorting: Sorting) {
        if (sorting !== destSorting) {
            onSortingChange(destSorting);
        }
    }

    return (
        <div className={styles.root}>
            <div
                className={styles.title}
                {...{
                    'qa-id': 'tableHeaderSortingFilter',
                }}
            >
                Сортировка
            </div>

            <div className={styles.sortingItemsWrapper}>
                <SortingItem targetSorting={Sorting.Asc} currentSorting={sorting} onSelect={onSelect} />

                <SortingItem targetSorting={Sorting.Desc} currentSorting={sorting} onSelect={onSelect} />
            </div>
        </div>
    );
}

interface SortingItemProps {
    targetSorting: Sorting;
    currentSorting: Sorting;
    onSelect: (sorting: Sorting) => void;
}

function SortingItem({ targetSorting, currentSorting, onSelect }: SortingItemProps): JSX.Element {
    const [isHovered, setIsHovered] = React.useState(false);

    const isChecked = currentSorting === targetSorting;
    let from: string;
    let to: string;

    switch (targetSorting) {
        case Sorting.Asc:
            from = 'А';
            to = 'Я';
            break;
        case Sorting.Desc:
            from = 'Я';
            to = 'А';
            break;
        default:
            from = '#';
            to = '#';
            break;
    }

    return (
        <div
            className={styles.sortingItem}
            onClick={targetSorting ? () => onSelect(targetSorting) : null}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            {...{
                'qa-id': 'tableHeaderSortingFilterItem',
                'qa-lagel': targetSorting,
            }}
        >
            <div
                className={classNames(
                    styles.sortingItemCheckbox,
                    isHovered && !isChecked && styles.sortingItemCheckboxHovered,
                    isChecked && styles.sortingItemCheckboxChecked,
                )}
            />

            <div className={styles.sortingItemText}>
                {from}

                <div className={styles.sortingItemArrow}>
                    <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.3708 0.121796L12.4404 0.181942L15.8192 3.56075C16.0398 3.78129 16.0598 4.12639 15.8794 4.36957L15.8192 4.43925L12.4404 7.81806C12.1978 8.06065 11.8045 8.06065 11.5619 7.81806C11.3414 7.59752 11.3214 7.25242 11.5018 7.00924L11.5619 6.93957L13.85 4.65H0.65C0.291015 4.65 0 4.35898 0 4C0 3.67365 0.240508 3.40347 0.553948 3.35705L0.65 3.35L13.851 3.349L11.5619 1.06043C11.3414 0.839898 11.3214 0.494796 11.5018 0.251613L11.5619 0.181942C11.7825 -0.0385936 12.1276 -0.0586423 12.3708 0.121796Z"
                            fill="#7E8681"
                        />
                    </svg>
                </div>

                {to}
            </div>
        </div>
    );
}
