// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-main-Chip-_Chip__root{--border-radius: 500px;border-radius:var(--border-radius);white-space:nowrap;flex-wrap:nowrap}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/main/Chip/Chip.scss"],"names":[],"mappings":"AAAA,4DACE,sBAAgB,CAEhB,kCAAmC,CACnC,kBAAmB,CACnB,gBAAiB","sourcesContent":[".root {\n  --border-radius: 500px;\n\n  border-radius: var(--border-radius);\n  white-space: nowrap;\n  flex-wrap: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-components-main-Chip-_Chip__root"
};
export default ___CSS_LOADER_EXPORT___;
