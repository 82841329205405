import * as React from 'react';

import * as style from './Suggest.scss';

import { LabeledInput, HeightAnimation, CustomScrollbar } from 'sber-marketing-ui';

export interface Props {
    title: string;
    sign: string;
    value: string;
    items: SuggestItem[];
    disabled: boolean;
    suggestHeight: number;
    isSuggestOpened: boolean;
    inputRef: (element: HTMLInputElement) => void;
    onInputFocus: () => void;
    onValueChange: (value: string) => void;
    onItemClick: (value: React.ReactText) => void;
}

export interface SuggestItem {
    title: string;
    value: React.ReactText;
}

export const SuggestTemplate = ({
    title,
    sign,
    value,
    items,
    disabled,
    suggestHeight,
    isSuggestOpened,
    inputRef,
    onInputFocus,
    onValueChange,
    onItemClick,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <LabeledInput
                title={title}
                value={value}
                disabled={disabled}
                inputRef={inputRef}
                onFocus={onInputFocus}
                onValueChange={onValueChange}
                onlyNumbers
            />

            {sign && <div className={style.sign}>{sign}</div>}

            <div className={style.suggest}>
                <HeightAnimation>
                    <div
                        className={style.itemsWrapper}
                        style={
                            isSuggestOpened
                                ? {
                                      height: suggestHeight,
                                      boxShadow: '0 2px 11px 0px rgba(0, 0, 0, 0.1)',
                                  }
                                : {}
                        }
                    >
                        <CustomScrollbar
                            renderTrackHorizontal={() => <div style={{ display: 'none' }} />}
                            renderThumbHorizontal={() => <div style={{ display: 'none' }} />}
                            renderView={(props) => <div {...props} />}
                            hideTracksWhenNotNeeded
                        >
                            {isSuggestOpened ? (
                                items.map((item) => (
                                    <div
                                        key={item.value}
                                        className={style.item}
                                        onClick={() => onItemClick(item.value)}
                                    >
                                        {item.title}
                                    </div>
                                ))
                            ) : (
                                <div style={{ height: suggestHeight }} />
                            )}
                        </CustomScrollbar>
                    </div>
                </HeightAnimation>
            </div>
        </div>
    );
};
