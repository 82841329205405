import { ComponentType } from 'react';
import { transformProps } from 'sber-marketing-ui';

import { PropsWithAppUsers } from '../../../../common/withAppUsers';
import { LoadingStatus } from '../../../../../store/commonTypes';
import { ChannelsBoardTemplate } from './ChannelsBoardTemplate';
import { ChannelsBoardProps, ChannelsBoardTemplateProps } from './types';
import { withTaskData, PropsWithTaskData } from '../withTaskData';

const transformer = ({
    className,
    appUsersLoadingStatus,
    channelId,
    loadAppUsers: onRetryButtonClick,
}: PropsWithTaskData<PropsWithAppUsers<ChannelsBoardProps>>): ChannelsBoardTemplateProps => ({
    className,
    channelId,
    isLoading: false,
    hasError: false,
});

const transform = transformProps(transformer);

/** Channels board for task page */
export const ChannelsBoardStore: ComponentType<ChannelsBoardProps> = withTaskData(transform(ChannelsBoardTemplate));
