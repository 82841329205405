import * as React from 'react';
import classNames from 'classnames';
import { Skeleton } from 'sber-marketing-ui';

import * as style from './ActionsCell.scss';

interface Props {
    userHasAutopilotClientAccess: boolean;
    lineStatus: 'MODERATION' | 'MODERATION_SUCCESS' | 'NO_MATERIALS' | 'NO_MATCH_TT' | 'APPROVED' | 'NOT_APPROVED';
    lineHasChanges: boolean;
    onSaveButtonClick: () => void;
    onCancelEditButtonClick: () => void;
    onApproveButtonClick: () => void;
    onRejectButtonClick: () => void;
}

export const ActionsCell = ({
    userHasAutopilotClientAccess,
    lineStatus,
    lineHasChanges,
    onSaveButtonClick: onSave,
    onCancelEditButtonClick,
    onApproveButtonClick: onApprove,
    onRejectButtonClick: onReject,
}: Props): JSX.Element => {
    const [preloader, setPreloader] = React.useState(false);

    React.useEffect(() => {
        setPreloader(false);
    }, [lineHasChanges, lineStatus]);

    const onSaveButtonClick = React.useCallback(() => {
        onSave();
        setPreloader(true);
    }, []);

    const onApproveButtonClick = React.useCallback(() => {
        onApprove();
        setPreloader(true);
    }, []);

    const onRejectButtonClick = React.useCallback(() => {
        onReject();
        setPreloader(true);
    }, []);

    return (
        <div className={style.root}>
            {!preloader && (
                <>
                    <div className={style.content}>
                        {userHasAutopilotClientAccess && (
                            <>
                                <div
                                    className={classNames(
                                        style.saveButton,
                                        lineStatus === 'APPROVED' && style.selected,
                                    )}
                                    onClick={lineStatus !== 'APPROVED' ? onApproveButtonClick : null}
                                >
                                    Согласовать
                                </div>

                                <div
                                    className={classNames(
                                        style.cancelButton,
                                        lineStatus === 'NOT_APPROVED' && style.selected,
                                    )}
                                    onClick={lineStatus !== 'NOT_APPROVED' ? onRejectButtonClick : null}
                                >
                                    Вернуть на доработку
                                </div>
                            </>
                        )}

                        {!userHasAutopilotClientAccess && lineHasChanges && (
                            <>
                                <div className={style.saveButton} onClick={lineHasChanges ? onSaveButtonClick : null}>
                                    Обновить статус
                                </div>

                                <div
                                    className={style.cancelButton}
                                    onClick={lineHasChanges ? onCancelEditButtonClick : null}
                                >
                                    Отменить
                                </div>
                            </>
                        )}

                        {!userHasAutopilotClientAccess && !lineHasChanges && (
                            <div className={style.noChangesMessage}>
                                Нет доступных
                                <br />
                                действий
                            </div>
                        )}
                    </div>
                </>
            )}

            {preloader && <Skeleton customStyle={{ height: '100%', width: '100%' }} />}
        </div>
    );
};
