import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { List } from './List';

import { StoreState } from '@store';
import { getAutopilot } from '@store/autopilot/selectors';
import {
    getTextTitles,
    getTextContents,
    updateTextTitle,
    updateTextContent,
    changeTextOptionSelection,
    changeTextOptionValue,
    TEXT_LENGTH_RANGE,
} from '@store/autopilot/generationTextForm';

import * as styles from './styles.scss';

interface OnItemReloadHandler {
    (id: string): void;
}

interface OnItemSelectHandler {
    (params: { id: string; value: boolean }): void;
}

interface OnItemChangeHandler {
    (params: { id: string; value: string }): void;
}

export const Content: React.FC = () => {
    const dispatch = useDispatch();

    const titles = useSelector((state: StoreState) => getTextTitles(state));
    const contents = useSelector((state: StoreState) => getTextContents(state));
    const {
        id: autopilotId,
        brief: { product: productId },
    } = useSelector((state: StoreState) => getAutopilot(state));

    const onTitleItemReload: OnItemReloadHandler = React.useCallback(
        (titleId) => {
            dispatch(updateTextTitle({ titleId, autopilotId, productId }));
        },
        [productId, autopilotId],
    );

    const onContentItemReload: OnItemReloadHandler = React.useCallback(
        (contentId) => {
            dispatch(updateTextContent({ contentId, autopilotId, productId }));
        },
        [productId, autopilotId],
    );

    const onTitleItemSelect: OnItemSelectHandler = React.useCallback(({ id, value }) => {
        dispatch(changeTextOptionSelection({ id, type: 'titles', selected: value }));
    }, []);

    const onContentItemSelect: OnItemSelectHandler = React.useCallback(({ id, value }) => {
        dispatch(changeTextOptionSelection({ id, type: 'contents', selected: value }));
    }, []);

    const onTitleItemChange: OnItemChangeHandler = React.useCallback(({ id, value }) => {
        dispatch(changeTextOptionValue({ id, type: 'titles', value }));
    }, []);

    const onContentItemChange: OnItemChangeHandler = React.useCallback(({ id, value }) => {
        dispatch(changeTextOptionValue({ id, type: 'contents', value }));
    }, []);

    return (
        <div className={styles.root}>
            <List
                title={'Заголовки'}
                items={titles}
                maxValueLength={TEXT_LENGTH_RANGE.TITLE}
                onItemSelect={onTitleItemSelect}
                onItemChange={onTitleItemChange}
                onItemReload={onTitleItemReload}
            />
            <List
                title={'Описание'}
                items={contents}
                maxValueLength={TEXT_LENGTH_RANGE.DESCRIPTION}
                onItemSelect={onContentItemSelect}
                onItemChange={onContentItemChange}
                onItemReload={onContentItemReload}
            />
        </div>
    );
};
