import * as React from 'react';
import { IconType } from 'sber-marketing-ui';

import { NotificationBar, Color } from './NotificationBar';

interface Props {
    text: React.ReactNode;
}

export function WarningNotificationBar({ text }: Props): React.ReactElement {
    return <NotificationBar icon={IconType.WARNING_IN_TRIANGLE} color={Color.Yellow} text={text} />;
}
