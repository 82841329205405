// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-main-CopyTextIconButton-_CopyTextIconButton__button{cursor:pointer;color:#7E8681;transition:color .3s;font-family:'Open Sans', sans-serif;background:none;padding:0;border:none;line-height:inherit;float:left;-webkit-font-smoothing:inherit;-moz-osx-font-smoothing:inherit;-webkit-appearance:none}.src-client-modules-common-components-main-CopyTextIconButton-_CopyTextIconButton__button:hover{color:#19bb4f}.src-client-modules-common-components-main-CopyTextIconButton-_CopyTextIconButton__button:active{color:#18a049}.src-client-modules-common-components-main-CopyTextIconButton-_CopyTextIconButton__icon{float:left}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/main/CopyTextIconButton/CopyTextIconButton.scss"],"names":[],"mappings":"AAIA,0FACE,cAAe,CACf,aAAc,CACd,oBAAqB,CACrB,mCAAoC,CAEpC,eAAgB,CAChB,SAAU,CACV,WAAY,CACZ,mBAAoB,CACpB,UAAW,CAGX,8BAA+B,CAC/B,+BAAgC,CAGhC,uBAAwB,CAjB1B,gGAoBI,aAAc,CApBlB,iGAwBI,aAAc,CACf,wFAID,UAAW","sourcesContent":[".root {\n\n}\n\n.button {\n  cursor: pointer;\n  color: #7E8681;\n  transition: color .3s;\n  font-family: 'Open Sans', sans-serif;\n\n  background: none;\n  padding: 0;\n  border: none;\n  line-height: inherit;\n  float: left;\n\n  /* Corrects font smoothing for webkit */\n  -webkit-font-smoothing: inherit;\n  -moz-osx-font-smoothing: inherit;\n\n  /* Corrects inability to style clickable `input` types in iOS */\n  -webkit-appearance: none;\n\n  &:hover {\n    color: #19bb4f;\n  }\n\n  &:active {\n    color: #18a049;\n  }\n}\n\n.icon {\n  float: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "src-client-modules-common-components-main-CopyTextIconButton-_CopyTextIconButton__button",
	"icon": "src-client-modules-common-components-main-CopyTextIconButton-_CopyTextIconButton__icon"
};
export default ___CSS_LOADER_EXPORT___;
