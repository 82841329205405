import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';

import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type {
    AutopilotRadio,
    AutopilotRadioTargetGroup,
    AutopilotRadioMediaplanPlanDataResponse as AutopilotRadioMediaplan,
} from 'sber-marketing-types/backend';
import type { PageState, BriefStageForm, GroupedDictionaries } from './types';
import { AutopilotStage } from './types';
import * as actions from './actions';

export const initialState: PageState = {
    autopilot: null,
    activity: null,
    mediaplan: null,
    targetGroups: [],
    dictionaries: {},
    briefStageForm: {
        blockId: null,
        productId: null,
        budget: null,
        budgetError: null,
        dateStart: null,
        dateEnd: null,
        targetGroup: null,
        intervals: [null],
        selectedStations: [],
        strategy: 'DRIVE_TIME',
    },
    selectedBudgetItemIds: [],
    currentStage: AutopilotStage.Brief,
    preloader: false,
    sidebar: {
        opened: true,
    },
};

export class Reducer {
    public static loadAutopilot(state: PageState, payload: AutopilotRadio): PageState {
        const { brief, budget } = payload;

        const briefStageForm: BriefStageForm = brief
            ? {
                  blockId: brief.block,
                  productId: brief.product,
                  budget: brief.budget ? brief.budget.toString() : null,
                  budgetError: null,
                  dateStart: brief.dateStart,
                  dateEnd: brief.dateEnd,
                  targetGroup: brief.targetGroup,
                  intervals: !lodash.isEmpty(brief.intervals) ? brief.intervals : [null],
                  selectedStations: brief.selectedStations,
                  strategy: brief.strategy || 'DRIVE_TIME',
              }
            : lodash.cloneDeep(initialState.briefStageForm);

        const selectedBudgetItemIds = budget ? budget.budgetIds : [];

        return {
            ...state,
            autopilot: payload,
            briefStageForm,
            selectedBudgetItemIds,
        };
    }

    public static loadActivity(state: PageState, payload: Activity): PageState {
        return { ...state, activity: payload };
    }

    public static loadMediaplan(state: PageState, payload: AutopilotRadioMediaplan): PageState {
        const selectedStations = payload.custom.selected.rows.map((item) => item.id);

        const params: PageState = { ...state, mediaplan: payload };

        if (!lodash.isEqual(state.briefStageForm.selectedStations, selectedStations)) {
            params.briefStageForm.selectedStations = selectedStations;
        }

        return params;
    }

    public static loadTargetGroups(state: PageState, payload: AutopilotRadioTargetGroup[]): PageState {
        return { ...state, targetGroups: payload };
    }

    public static loadDictionaries(state: PageState, payload: GroupedDictionaries): PageState {
        return { ...state, dictionaries: payload };
    }

    public static resetPageState(): PageState {
        return lodash.cloneDeep(initialState);
    }

    public static setBriefFormValues(state: PageState, payload: Partial<BriefStageForm>): PageState {
        return { ...state, briefStageForm: { ...state.briefStageForm, ...payload } };
    }

    public static setSelectedBudgetItemIds(state: PageState, payload: string[]): PageState {
        return { ...state, selectedBudgetItemIds: payload };
    }

    public static setCurrentStage(state: PageState, payload: AutopilotStage): PageState {
        return { ...state, currentStage: payload };
    }

    public static setPreloaderStatus(state: PageState, payload: boolean): PageState {
        return { ...state, preloader: payload };
    }

    public static openSidebar(state: PageState): PageState {
        return { ...state, sidebar: { ...state.sidebar, opened: true } };
    }

    public static closeSidebar(state: PageState): PageState {
        return { ...state, sidebar: { ...state.sidebar, opened: false } };
    }
}

export const autopilotRadioReducer = reducerWithInitialState(initialState)
    .case(actions.loadAutopilot, Reducer.loadAutopilot)
    .case(actions.loadActivity, Reducer.loadActivity)
    .case(actions.loadMediaplan, Reducer.loadMediaplan)
    .case(actions.loadTargetGroups, Reducer.loadTargetGroups)
    .case(actions.loadDictionaries, Reducer.loadDictionaries)
    .case(actions.resetPageState, Reducer.resetPageState)
    .case(actions.setBriefFormValues, Reducer.setBriefFormValues)
    .case(actions.setSelectedBudgetItemIds, Reducer.setSelectedBudgetItemIds)
    .case(actions.setCurrentStage, Reducer.setCurrentStage)
    .case(actions.setPreloaderStatus, Reducer.setPreloaderStatus)
    .case(actions.openSidebar, Reducer.openSidebar)
    .case(actions.closeSidebar, Reducer.closeSidebar);
