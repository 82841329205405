import * as React from 'react';
import classNames from 'classnames';
import { positionValues } from 'react-custom-scrollbars';
import { CustomScrollbar as CustomScrollbar } from 'sber-marketing-ui';
import { Flipped, Flipper } from 'react-flip-toolkit';

import { SearchEntities } from '@store/search';

import { CommonFormatter } from '@common/Utils';

import { Group } from './Group';

import * as styles from './Results.scss';

interface Props {
    areEntitiesLoading: boolean;
    totalCount: number;
    showFilesGroup: boolean;
    showActivitiesGroup: boolean;
    showTasksGroup: boolean;
    showCommentsGroup: boolean;
    expandedGroup: SearchEntities;
    animatedGroup: SearchEntities;
    shouldAnimateFilesGroup: boolean;
    shouldAnimateActivitiesGroup: boolean;
    shouldAnimateTasksGroup: boolean;
    shouldAnimateCommentsGroup: boolean;
    onAnimationStarted: (element: HTMLDivElement) => void;
    onAnimationEnded: () => void;
    onScroll: (positionValues: positionValues) => void;
}

export function Results({
    areEntitiesLoading,
    totalCount,
    showFilesGroup,
    showActivitiesGroup,
    showTasksGroup,
    showCommentsGroup,
    expandedGroup,
    shouldAnimateFilesGroup,
    shouldAnimateActivitiesGroup,
    shouldAnimateTasksGroup,
    shouldAnimateCommentsGroup,
    onScroll,
    ...restProps
}: Props): JSX.Element {
    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'searchMenuResults',
            }}
        >
            <CustomScrollbar hideTracksWhenNotNeeded hideHorizontalScrollbar onScrollFrame={onScroll}>
                <div className={styles.content}>
                    <div className={styles.totalCount}>
                        {areEntitiesLoading
                            ? 'Поиск...'
                            : `Результаты поиска ${CommonFormatter.splitNumberByScales(totalCount)}:`}
                    </div>

                    <Flipper flipKey={expandedGroup}>
                        {showActivitiesGroup && (
                            <ItemGroup
                                title="Проекты"
                                entityType={SearchEntities.Activities}
                                expandedGroup={expandedGroup}
                                shouldAnimate={shouldAnimateActivitiesGroup}
                                {...restProps}
                            />
                        )}

                        {showTasksGroup && (
                            <ItemGroup
                                title="Задачи"
                                entityType={SearchEntities.Tasks}
                                expandedGroup={expandedGroup}
                                shouldAnimate={shouldAnimateTasksGroup}
                                {...restProps}
                            />
                        )}

                        {showFilesGroup && (
                            <ItemGroup
                                title="Файлы"
                                entityType={SearchEntities.Files}
                                expandedGroup={expandedGroup}
                                shouldAnimate={shouldAnimateFilesGroup}
                                {...restProps}
                            />
                        )}

                        {showCommentsGroup && (
                            <ItemGroup
                                title="Комментарии"
                                entityType={SearchEntities.Comments}
                                expandedGroup={expandedGroup}
                                shouldAnimate={shouldAnimateCommentsGroup}
                                {...restProps}
                            />
                        )}
                    </Flipper>
                </div>
            </CustomScrollbar>
        </div>
    );
}

interface ItemGroupProps {
    title: string;
    entityType: SearchEntities;
    animatedGroup: SearchEntities;
    expandedGroup: SearchEntities;
    shouldAnimate: boolean;
    onAnimationStarted: (element: HTMLDivElement) => void;
    onAnimationEnded: () => void;
}

function ItemGroup({
    title,
    entityType,
    animatedGroup,
    expandedGroup,
    shouldAnimate,
    onAnimationEnded,
    onAnimationStarted,
}: ItemGroupProps): JSX.Element {
    const isExpanded = expandedGroup === entityType;

    const content = (
        <div
            className={classNames(
                styles.group,
                !isExpanded && animatedGroup && animatedGroup !== entityType && styles.group_hidden,
            )}
            {...{
                'qa-id': 'searchMenuItemGroup',
                'qa-type': entityType,
                'qa-is-expanded': isExpanded ? 'true' : 'false',
            }}
        >
            <Group title={title} entityType={entityType} />
        </div>
    );

    return shouldAnimate ? (
        <Flipped flipId={entityType} onStart={onAnimationStarted} onComplete={onAnimationEnded} translate scale={false}>
            {content}
        </Flipped>
    ) : (
        <React.Fragment>{content}</React.Fragment>
    );
}
