import { RoleId } from '@sbermarketing/mrm-metacom-client';
import { useGetAuthUserQuery } from '@api';

export function useAccessByRoleId(roleId: RoleId) {
    const { isLoading, data: authUser } = useGetAuthUserQuery();

    return {
        isLoading,
        isAccept: Boolean(authUser?.user.attributes.roles.find(({ id }) => id === roleId)),
    };
}
