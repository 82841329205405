// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-ChannelInfo-_ChannelInfo__root{display:flex;align-items:center;justify-content:flex-start}.src-client-modules-activity-pages-task-ChannelInfo-_ChannelInfo__textLine{height:20px;margin-right:16px;font-family:Roboto;font-size:11px;line-height:1.82}.src-client-modules-activity-pages-task-ChannelInfo-_ChannelInfo__textLine_info{opacity:0.5;color:#262626}.src-client-modules-activity-pages-task-ChannelInfo-_ChannelInfo__textLine_participants{margin-right:0px;color:#3181e0}.src-client-modules-activity-pages-task-ChannelInfo-_ChannelInfo__textLine_participants:hover{cursor:pointer}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/ChannelInfo/ChannelInfo.scss"],"names":[],"mappings":"AAAA,uEACI,YAAa,CAEb,kBAAmB,CACnB,0BAA2B,CAC9B,2EAGG,WAAY,CACZ,iBAAkB,CAElB,kBAAmB,CACnB,cAAe,CACf,gBAAiB,CACpB,gFAGG,WAAY,CACZ,aAAc,CACjB,wFAGG,gBAAiB,CAEjB,aAAc,CACjB,8FAGG,cAAe","sourcesContent":[".root {\n    display: flex;\n\n    align-items: center;\n    justify-content: flex-start;\n}\n\n.textLine {\n    height: 20px;\n    margin-right: 16px;\n\n    font-family: Roboto;\n    font-size: 11px;\n    line-height: 1.82;\n}\n\n.textLine_info {\n    opacity: 0.5;\n    color: #262626;\n}\n\n.textLine_participants {\n    margin-right: 0px;\n\n    color: #3181e0;\n}\n\n.textLine_participants:hover {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-ChannelInfo-_ChannelInfo__root",
	"textLine": "src-client-modules-activity-pages-task-ChannelInfo-_ChannelInfo__textLine",
	"textLine_info": "src-client-modules-activity-pages-task-ChannelInfo-_ChannelInfo__textLine_info",
	"textLine_participants": "src-client-modules-activity-pages-task-ChannelInfo-_ChannelInfo__textLine_participants"
};
export default ___CSS_LOADER_EXPORT___;
