import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { DivisionParams } from 'sber-marketing-types/frontend';
import { Dictionary, uniq, uniqBy } from 'lodash';

import { LoadingStatus } from '@store/commonTypes';

import * as actions from './actions';

import {
    DivisionsState as State,
    DictionaryByLevels,
    StoreTypes,
    EntitiesStore,
    SetStoreIdsParams,
    SetLoadingStatusParams,
} from './types';

class Reducer {
    public static emptyState(): State {
        return {
            entities: [],
            byIds: {},
            byLevels: {},
            stores: {
                [StoreTypes.GENERAL]: Reducer.emptyEntitiesStore(),
                [StoreTypes.CURRENT_ORGANIZATION]: Reducer.emptyEntitiesStore(),
                [StoreTypes.MY_TASKS_FILTER]: Reducer.emptyEntitiesStore(),
                [StoreTypes.AVAILABLE_ACTIVITIES_FILTERS]: Reducer.emptyEntitiesStore(),
                [StoreTypes.MY_ACTIVITIES_FILTER]: Reducer.emptyEntitiesStore(),
                [StoreTypes.NEWS_FILTER]: Reducer.emptyEntitiesStore(),
                [StoreTypes.CUSTOM]: Reducer.emptyEntitiesStore(),
            },
        };
    }

    public static loadEntities(state: State, divisions: DivisionParams[]): State {
        const entities = uniqBy([...state.entities, ...divisions], (division) => division.id);

        const byIds: Dictionary<DivisionParams> = divisions.reduce(
            (acc, division) => ({
                ...acc,
                [division.id]: division,
            }),
            state.byIds,
        );

        const byLevels: DictionaryByLevels = divisions.reduce(
            (acc, division) => ({
                ...acc,
                [division.level]: acc[division.level] ? [...acc[division.level], division.id] : [division.id],
            }),
            state.byLevels,
        );

        return {
            ...state,
            entities,
            byIds,
            byLevels,
        };
    }

    public static setStoreIds(state: State, payload: SetStoreIdsParams): State {
        const { store, ids } = payload;

        return Reducer.entitiesStoreReducer(state, store, (entitiesStore) => ({
            ...entitiesStore,
            ids: uniq([...entitiesStore.ids, ...ids]),
        }));
    }

    public static setLoadingStatus(state: State, payload: SetLoadingStatusParams): State {
        const { store, status } = payload;

        return Reducer.entitiesStoreReducer(state, store, (entitiesStore) => ({
            ...entitiesStore,
            loadingStatus: status,
        }));
    }

    public static resetStore(state: State, store: StoreTypes): State {
        return Reducer.entitiesStoreReducer(state, store, (store) => ({
            ...Reducer.emptyEntitiesStore(),
            fetchersCount: store.fetchersCount,
        }));
    }

    public static incFetchersCount(state: State, store: StoreTypes): State {
        return Reducer.entitiesStoreReducer(state, store, (entitiesStore) => ({
            ...entitiesStore,
            fetchersCount: entitiesStore.fetchersCount + 1,
        }));
    }

    public static decFetchersCount(state: State, store: StoreTypes): State {
        return Reducer.entitiesStoreReducer(state, store, (entitiesStore) => ({
            ...entitiesStore,
            fetchersCount: entitiesStore.fetchersCount - 1,
        }));
    }

    private static entitiesStoreReducer(
        state: State,
        store: StoreTypes,
        entitiesStoreReducer: (state: EntitiesStore) => EntitiesStore,
    ): State {
        return {
            ...state,
            stores: {
                ...state.stores,
                [store]: entitiesStoreReducer(state.stores[store]),
            },
        };
    }

    private static emptyEntitiesStore(): EntitiesStore {
        return {
            loadingStatus: LoadingStatus.NOT_LOADED,
            ids: [],
            fetchersCount: 0,
        };
    }
}

export const divisionsReducer = reducerWithInitialState(Reducer.emptyState())
    .case(actions.loadEntities, Reducer.loadEntities)
    .case(actions.setStoreIds, Reducer.setStoreIds)
    .case(actions.setLoadingStatus, Reducer.setLoadingStatus)
    .case(actions.resetStore, Reducer.resetStore)
    .case(actions.incFetchersCount, Reducer.incFetchersCount)
    .case(actions.decFetchersCount, Reducer.decFetchersCount);
