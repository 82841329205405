import * as React from 'react';
import { sortBy, xor } from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import autobind from 'autobind-decorator';

import { FilterItem } from 'sber-marketing-ui';

import { BudgetItemSelect } from './BudgetItemSelect';
import { StoreState } from '@store';
import {
    getBudgetItems,
    getSelectedBudgetItemIds,
    getBudgetItemIdsOfSelectedCreativeRequest,
    getSerialNumbersToCreate,
    getSerialNumbersToDelete,
    setSelectedBudgetItemIds,
} from '@store/budgetExecution/creativeReferenceMenu';
import type { BudgetItem } from '@store/budgetExecution/creativeReferenceMenu/types';

interface Props extends Partial<MapProps & DispatchProps> {}

interface MapProps {
    budgetItems: BudgetItem[];
    serialNumbersToCreate: string[];
    serialNumbersToDelete: string[];
    selectedBudgetItemIds: string[];
    budgetItemIdsOfSelectedCreativeRequest: string[];
}

interface DispatchProps {
    setSelectedBudgetItemIds: (selectedBudgetItemIds: string[]) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BudgetItemSelectContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { serialNumbersToCreate, serialNumbersToDelete, selectedBudgetItemIds } = this.props;

        return (
            <BudgetItemSelect
                serialNumbersToCreate={serialNumbersToCreate}
                serialNumbersToDelete={serialNumbersToDelete}
                items={this.makeItems()}
                selectedItemIds={selectedBudgetItemIds}
                onItemSelect={this.onItemSelect}
                onSerialNumberRemoveButtonClick={this.onSerialNumberRemoveButtonClick}
            />
        );
    }

    @autobind
    private onItemSelect(selectedIds: string[]): void {
        this.props.setSelectedBudgetItemIds(selectedIds);
    }

    @autobind
    private onSerialNumberRemoveButtonClick(serialNumberToRemove: string): void {
        const { budgetItems, selectedBudgetItemIds } = this.props;

        const budgetItem = budgetItems.find((item) => item.serialNumber === serialNumberToRemove);
        const updatedSelectedBudgetItemIds = xor(selectedBudgetItemIds, [budgetItem.id]);

        this.props.setSelectedBudgetItemIds(updatedSelectedBudgetItemIds);
    }

    private makeItems(): FilterItem[] {
        const { budgetItems } = this.props;

        const filterItems = budgetItems.map((budgetItem) => ({
            id: budgetItem.id,
            title: budgetItem.serialNumber,
            description: budgetItem.activityName,
        }));

        return sortBy(filterItems, ['description', 'title']);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        selectedBudgetItemIds: getSelectedBudgetItemIds(state),
        serialNumbersToCreate: getSerialNumbersToCreate(state),
        serialNumbersToDelete: getSerialNumbersToDelete(state),
        budgetItemIdsOfSelectedCreativeRequest: getBudgetItemIdsOfSelectedCreativeRequest(state),
        budgetItems: getBudgetItems(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return {
        setSelectedBudgetItemIds: (selectedBudgetItemIds: string[]) =>
            dispatch(setSelectedBudgetItemIds({ selectedBudgetItemIds })),
    };
}
