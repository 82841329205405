// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedActivities-ActivityCardList-ActivityCard-Footer-_Footer__root{display:grid;align-items:center;grid-template-columns:1fr auto;grid-column-gap:10px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/LineModal/Content/RelatedActivities/ActivityCardList/ActivityCard/Footer/Footer.scss"],"names":[],"mappings":"AAAA,6IACI,YAAa,CAEb,kBAAmB,CAEnB,8BAA+B,CAC/B,oBAAqB","sourcesContent":[".root {\n    display: grid;\n\n    align-items: center;\n\n    grid-template-columns: 1fr auto;\n    grid-column-gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedActivities-ActivityCardList-ActivityCard-Footer-_Footer__root"
};
export default ___CSS_LOADER_EXPORT___;
