import * as React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { HistoryActionType } from 'sber-marketing-types/backend';

import { Colors } from '../../qaData';
import { TextClamper } from '../TextClamper';

import * as style from './ActivityName.scss';

interface Props {
    action: HistoryActionType;
    activityId: number;
    activityName: string;
    wasActivityAssignedToLoginedUser: boolean;
    wasTaskAssignedToLoginedUser: boolean;
}

interface StyleParams {
    styleClass: string | string[];
    qaColor: Colors;
}

const GREEN_AND_BOLD = [style.colorGreen, style.weightBold];
const YELLOW_AND_BOLD = [style.colorYellow, style.weightBold];
const RED_AND_BOLD = [style.colorRed, style.weightBold];

const PARAMS = {
    [HistoryActionType.CreateActivity]: {
        styleClass: GREEN_AND_BOLD,
        qaColor: Colors.Green,
    },
    [HistoryActionType.ChangeResponsible]: {
        toCurrentUser: {
            styleClass: GREEN_AND_BOLD,
            qaColor: Colors.Green,
        },
        toOtherUser: {
            styleClass: style.colorBlack,
            qaColor: Colors.Black,
        },
    },
    [HistoryActionType.ShareActivity]: {
        styleClass: GREEN_AND_BOLD,
        qaColor: Colors.Green,
    },
    [HistoryActionType.RevokeActivity]: {
        styleClass: YELLOW_AND_BOLD,
        qaColor: Colors.Yellow,
    },
    [HistoryActionType.DeleteActivity]: {
        styleClass: style.colorRed,
        qaColor: Colors.Red,
    },
    [HistoryActionType.RemoveTaskParticipant]: {
        styleClass: RED_AND_BOLD,
        qaColor: Colors.Black,
    },
    [HistoryActionType.UpdateActivity]: {
        styleClass: style.colorBlack,
        qaColor: Colors.Black,
    },
    [HistoryActionType.ActivityRealizationChanged]: {
        styleClass: style.colorBlack,
        qaColor: Colors.Black,
    },
    [HistoryActionType.ActivityPreparationTimingChanged]: {
        styleClass: style.colorBlack,
        qaColor: Colors.Black,
    },
    [HistoryActionType.ActivityDebriefingTimingChanged]: {
        styleClass: style.colorBlack,
        qaColor: Colors.Black,
    },
    [HistoryActionType.AddTaskParticipant]: {
        styleClass: style.colorBlack,
        qaColor: Colors.Black,
    },
    [HistoryActionType.UpdateTask]: {
        styleClass: style.colorBlack,
        qaColor: Colors.Black,
    },
    [HistoryActionType.UpdateTaskDeadline]: {
        styleClass: style.colorBlack,
        qaColor: Colors.Black,
    },
    [HistoryActionType.UpdateTaskExecutor]: {
        toCurrentUser: {
            styleClass: GREEN_AND_BOLD,
            qaColor: Colors.Green,
        },
        toOtherUser: {
            styleClass: style.colorBlack,
            qaColor: Colors.Black,
        },
    },
    [HistoryActionType.AddTaskComment]: {
        styleClass: style.colorBlack,
        qaColor: Colors.Black,
    },
    [HistoryActionType.AddTaskCommentFile]: {
        styleClass: style.colorBlack,
        qaColor: Colors.Black,
    },
    [HistoryActionType.MentionedInTaskComment]: {
        styleClass: style.colorBlack,
        qaColor: Colors.Black,
    },
    [HistoryActionType.TaskClosed]: {
        styleClass: style.colorBlack,
        qaColor: Colors.Black,
    },
    [HistoryActionType.TaskToProgress]: {
        styleClass: style.colorBlack,
        qaColor: Colors.Black,
    },
    [HistoryActionType.DeleteTask]: {
        styleClass: style.colorBlack,
        qaColor: Colors.Black,
    },
    [HistoryActionType.AddTaskChannel]: {
        styleClass: GREEN_AND_BOLD,
        qaColor: Colors.Green,
    },
    [HistoryActionType.DeleteTaskChannel]: {
        styleClass: RED_AND_BOLD,
        qaColor: Colors.Red,
    },
    [HistoryActionType.AddTaskFile]: {
        styleClass: style.colorBlack,
        qaColor: Colors.Black,
    },
    [HistoryActionType.TaskReturned]: {
        styleClass: style.colorBlack,
        qaColor: Colors.Black,
    },
    [HistoryActionType.ActivityDeadlineOverdue]: {
        styleClass: style.colorBlack,
        qaColor: Colors.Black,
    },
    [HistoryActionType.TaskDeadLineOverdue]: {
        styleClass: style.colorBlack,
        qaColor: Colors.Black,
    },
    [HistoryActionType.OneDayToTaskDeadLine]: {
        styleClass: style.colorBlack,
        qaColor: Colors.Black,
    },
};

export function ActivityName({
    action,
    activityId,
    activityName,
    wasActivityAssignedToLoginedUser,
    wasTaskAssignedToLoginedUser,
}: Props): JSX.Element {
    let params: StyleParams;

    if (action === HistoryActionType.ChangeResponsible) {
        params = wasActivityAssignedToLoginedUser
            ? PARAMS[HistoryActionType.ChangeResponsible].toCurrentUser
            : PARAMS[HistoryActionType.ChangeResponsible].toOtherUser;
    } else if (action === HistoryActionType.UpdateTaskExecutor) {
        params = wasTaskAssignedToLoginedUser
            ? PARAMS[HistoryActionType.UpdateTaskExecutor].toCurrentUser
            : PARAMS[HistoryActionType.UpdateTaskExecutor].toOtherUser;
    } else {
        params = PARAMS[action];
    }

    const shouldRenderLink: boolean =
        action !== HistoryActionType.DeleteActivity && action !== HistoryActionType.RevokeActivity;

    return (
        <div className={style.newsPartWrapper}>
            {shouldRenderLink ? (
                <TextClamper lines={2}>
                    <Link
                        to={`/activity/${activityId}`}
                        className={classNames([style.activityName, params.styleClass])}
                    >
                        <div
                            className={classNames([style.activityName, params.styleClass])}
                            {...{
                                'qa-id': 'dashboardNewsColumnItemActivityName',
                                'qa-color': params.qaColor,
                            }}
                        >
                            {activityName}
                        </div>
                    </Link>
                </TextClamper>
            ) : (
                <TextClamper lines={2}>
                    <div
                        className={classNames([style.activityName, params.styleClass])}
                        {...{
                            'qa-id': 'dashboardNewsColumnItemActivityName',
                            'qa-color': params.qaColor,
                        }}
                    >
                        {activityName}
                    </div>
                </TextClamper>
            )}
        </div>
    );
}
