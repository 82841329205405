import * as React from 'react';

import type { CreativeRequestItemDomain } from './types';

interface UseConvert {
    (params: UseConvertParams): UseConvertReturn;
}

interface UseConvertParams {
    creativeRequestItemDomains: CreativeRequestItemDomain[];
}

interface UseConvertReturn {
    creativeRequestItems: CreativeRequestItem[];
}

interface Convert {
    (): Promise<void>;
}

interface CreativeRequestItem {
    id: string;
    type: string;
    donors: Donor[];
    commissionWithoutVat: number;
    actualCostWithoutVat: number;
    createdAt: Date;
    status: 'received' | 'waiting' | 'approved' | 'archived';
}

interface Donor {
    id: string;
    serialNumber: number;
}

export const useConvert: UseConvert = ({ creativeRequestItemDomains = [] }) => {
    const [creativeRequestItems, setCreativeRequestItems] = React.useState<CreativeRequestItem[]>([]);

    const convert: Convert = React.useCallback(async () => {
        const creativeRequestItems: CreativeRequestItem[] = await Promise.all(
            creativeRequestItemDomains.map(
                async ({
                    model: {
                        id,
                        donors,
                        commissionWithoutVat,
                        actualCostWithoutVat,
                        creativeRequestGroup,
                        createdAt,
                        status,
                    },
                }) => ({
                    id,
                    type: await creativeRequestGroup.then(({ value }) => value),
                    donors: donors.map(({ id, serialNumber }) => ({ id, serialNumber })),
                    commissionWithoutVat,
                    actualCostWithoutVat,
                    createdAt,
                    status,
                }),
            ),
        );

        setCreativeRequestItems(creativeRequestItems);
    }, [creativeRequestItemDomains]);

    React.useEffect(() => {
        if (creativeRequestItemDomains.length) {
            convert();
        }
    }, [
        creativeRequestItemDomains
            .map(
                ({ model: { donors, id, actualCostWithoutVat, commissionWithoutVat, status } }) =>
                    `${donors.map(
                        ({ serialNumber }) => serialNumber,
                    )}${id}${actualCostWithoutVat}${commissionWithoutVat}${status}`,
            )
            .join('-'),
    ]);

    return {
        creativeRequestItems,
    };
};

export type { CreativeRequestItem, Donor };
