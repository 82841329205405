import * as React from 'react';
import classNames from 'classnames';

import * as styles from './Toggle.scss';

export interface ToggleProps extends React.HTMLProps<HTMLLabelElement> {
    checked?: boolean;
    disabled?: boolean;
    loading?: boolean;
    onToggle?: (checked: boolean) => void;
}

export const Toggle: React.FC<ToggleProps> = ({
    className,
    checked,
    loading,
    disabled,
    name,
    onToggle,
    children,
    ...props
}) => {
    const handleToggle: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        onToggle?.(e.target.checked);
    };

    return (
        <label
            data-qa-id="Toggle"
            data-qa-checked={checked}
            {...props}
            className={classNames(styles.root, loading && styles.loading, className)}
        >
            <input
                name={name}
                onChange={handleToggle}
                type="checkbox"
                disabled={disabled || loading}
                checked={checked}
                className={styles.input}
            />
            <span className={styles.icon} />
            {children}
        </label>
    );
};
