import * as React from 'react';
import { flatMap, keyBy, mapValues } from 'lodash';

import type { ItemParams } from './Item';
import type { Filters } from '../../types';
import { Item } from './Item';
import { Icon, IconType } from 'sber-marketing-ui';

import * as styles from './styles.scss';

interface Props {
    id: string;
    title?: string;
    items: ItemParams[];
    filters: Filters;
    onChange: (filters: Filters) => void;
}

export const List: React.FC<Props> = ({ id, title = '', items = [], filters, onChange }) => {
    const haveItems = Boolean(items.length);
    const everyFiltersEnabled = items.every((item) => filters[item.id]);
    const someFiltersEnabled = items.some((item) => filters[item.id]);

    const commonItemsCount = items.length;
    const usedItemsCount = items.reduce((prev, current) => {
        return prev + Number(filters[current.id]);
    }, 0);

    const onClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(
        (event) => {
            event.preventDefault();
            event.stopPropagation();

            onChange({
                [id]: !filters[id],
                ...buildFiltersWithSelectedStatus(items, !filters[id]),
            });
        },
        [id, filters, onChange],
    );

    return (
        <div className={styles.root} title={title || ''}>
            <div className={styles.header}>
                <div className={styles.indicator} onClick={onClick}>
                    {!everyFiltersEnabled && !someFiltersEnabled && (
                        <Icon type={IconType.CHECKBOX24_UNCHECKED} svgSize={24} />
                    )}
                    {everyFiltersEnabled && <Icon type={IconType.CHECKBOX24_CHECKED} svgSize={24} />}
                    {someFiltersEnabled && !everyFiltersEnabled && (
                        <Icon type={IconType.CHECKBOX24_MINUS} svgSize={24} />
                    )}
                </div>

                {Boolean(title) && <div className={styles.title}>{`${title}`}</div>}

                <div className={styles.countIndicator}>
                    <span className={styles.commonCountIndicator}>{commonItemsCount}</span>|
                    <span className={styles.usedCountIndicator}>{usedItemsCount}</span>
                </div>
            </div>

            {haveItems && (
                <div className={styles.items}>
                    {items.map((item) => (
                        <Item
                            key={item.id}
                            id={item.id}
                            name={item.name}
                            filters={filters}
                            childrenItems={item?.childrenItems || []}
                            onChange={onChange}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

const buildFiltersWithSelectedStatus = (children: ItemParams[], selectedStatus: boolean): Filters => {
    const childrenItemsIds = getChildrenIdsFromItems(children).map((id) => ({ id }));
    return mapValues(keyBy(childrenItemsIds, 'id'), () => selectedStatus);
};

const getChildrenIdsFromItems = (items: ItemParams[]): string[] => {
    return flatMap(items.map((childItem) => getChildrenIdsFromItem(childItem)));
};

const getChildrenIdsFromItem = ({ id, childrenItems }: ItemParams): string[] => {
    return childrenItems.length ? [...getChildrenIdsFromItems(childrenItems), id] : [id];
};

export type { ItemParams };
