import * as React from 'react';

import * as style from './MediaplanStageWidget.scss';

import { XLSBuilder } from './XLSBuilder';
import { TotalIndicatorsTable } from './TotalIndicatorsTable';
import { CitiesIndicatorsTable } from './CitiesIndicatorsTable';

interface Props {}

export const MediaplanStageWidgetTemplate = ({}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.downloadButton}>
                <XLSBuilder />
            </div>

            <div className={style.title}>Полная информация по медиаплану</div>

            <div className={style.form}>
                <div className={style.line}>
                    <div className={style.fieldTitle}>Суммарные показатели</div>

                    <TotalIndicatorsTable />
                </div>

                <div className={style.line}>
                    <div className={style.fieldTitle}>Показатели по городам</div>

                    <CitiesIndicatorsTable />
                </div>
            </div>
        </div>
    );
};
