import * as React from 'react';
import classNames from 'classnames';
import {
    Icon,
    IconType,
    Toggle,
    TogglePosition,
    ToggleThemes,
    FilterGroup,
    FilterMenu,
    FilterItem,
} from 'sber-marketing-ui';

import * as style from './Filters.scss';

interface SingleFilterProps {
    items: FilterItem[];
    checkedItemIds: React.ReactText[];
}

export interface FiltersProps {
    showUnseenNews: boolean;
    type: SingleFilterProps;
    activity: SingleFilterProps;
    author: SingleFilterProps;
}

interface Props {
    filters: FiltersProps;
    isOpened: boolean;
    toggleIsOpened: () => void;
    toggleShowUnseenNews: () => void;
    setTypeFilter: (selectedItemIds: string[]) => void;
    setActivityFilter: (selectedItemIds: number[]) => void;
    setAuthorFilter: (selectedItemIds: number[]) => void;
}

export function Filters({
    filters,
    isOpened,
    toggleIsOpened,
    toggleShowUnseenNews,
    setTypeFilter,
    setActivityFilter,
    setAuthorFilter,
}: Props): JSX.Element {
    const hasFilters =
        !!filters.type.checkedItemIds.length ||
        !!filters.activity.checkedItemIds.length ||
        !!filters.author.checkedItemIds.length;

    return (
        <React.Fragment>
            <div
                className={style.controls}
                {...{
                    'qa-id': 'dashbordNewsColumnFiltersMenu',
                }}
            >
                <div
                    className={classNames(
                        style.openButton,
                        isOpened && style.openButton_weighted,
                        hasFilters && style.openButton_withFilters,
                    )}
                    onClick={toggleIsOpened}
                    {...{
                        'qa-id': 'dashbordNewsColumnToggleFiltersButton',
                    }}
                >
                    <div className={style.filterIcon}>
                        <Icon type={IconType.FILTERS} />
                    </div>

                    <div className={style.buttonText}>Фильтр</div>

                    <div className={style.arrowIcon}>
                        {isOpened ? <Icon type={IconType.ARROW20_UP} /> : <Icon type={IconType.ARROW20_DOWN} />}
                    </div>
                </div>

                <div className={style.toggleWrapper}>
                    <div className={style.toggleText}>Новые</div>

                    <Toggle
                        qaId="dashbordNewsColumnUnseenToggle"
                        theme={ToggleThemes.GREY_AND_GREEN}
                        onClick={toggleShowUnseenNews}
                        position={filters.showUnseenNews ? TogglePosition.RIGHT : TogglePosition.LEFT}
                    />
                </div>
            </div>

            {isOpened && (
                <div className={style.content}>
                    <FilterGroup qaId="dashbordNewsColumnsFilters">
                        <FilterMenu
                            qaId="dashboardNewsColumnTypeFilter"
                            filterTitle="Тип"
                            items={filters.type.items}
                            checkedItemsIds={filters.type.checkedItemIds}
                            onItemSelection={setTypeFilter}
                        />

                        <FilterMenu
                            qaId="dashboardNewsColumnActivityFilter"
                            filterTitle="Проект"
                            items={filters.activity.items}
                            checkedItemsIds={filters.activity.checkedItemIds}
                            onItemSelection={setActivityFilter}
                        />

                        <FilterMenu
                            qaId="dashboardNewsColumnAuthorFilter"
                            filterTitle="Автор"
                            items={filters.author.items}
                            checkedItemsIds={filters.author.checkedItemIds}
                            onItemSelection={setAuthorFilter}
                        />
                    </FilterGroup>
                </div>
            )}
        </React.Fragment>
    );
}
