import * as React from 'react';

import { TimeCalculator, Scaler, Scroller } from '../../modules';
import { TodayLineIndicator } from './TodayLineIndicator';

const HALF_OF_DAY = 0.5;

export class TodayLineIndicatorContainer extends React.Component {
    private timeCalculator: TimeCalculator = TimeCalculator.getInstance();
    private scaler: Scaler = Scaler.getInstance();
    private scroller: Scroller = Scroller.getInstance();

    public render(): JSX.Element {
        const currentToday = new Date().toISOString();
        const currentDayIndex = this.timeCalculator.getDayIndexByDate(currentToday);
        const viewportWidthInDays = this.scaler.getViewportWidthInDays();
        const currentScroll = this.scroller.currentScroll;

        const offSetInDay = currentScroll * viewportWidthInDays;
        const positionTodayLineIndicatorInDay = currentDayIndex - HALF_OF_DAY - offSetInDay;
        let multiplierOfPositionInViewport = positionTodayLineIndicatorInDay / viewportWidthInDays;

        if (multiplierOfPositionInViewport < 0) {
            multiplierOfPositionInViewport = 0;
        }

        if (multiplierOfPositionInViewport > 1) {
            multiplierOfPositionInViewport = 1;
        }

        return React.createElement(TodayLineIndicator, {
            multiplyPosition: multiplierOfPositionInViewport,
        });
    }
}
