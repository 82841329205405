import * as React from 'react';

import * as style from './TextCell.scss';

import { Icon, IconType } from 'sber-marketing-ui';

interface Props {
    title: string;
    description?: string;
    icon?: { type: IconType; color: string; size: number };
    customStyle?: any;
}

export const TextCellTemplate = ({ title, description, icon, customStyle }: Props): JSX.Element => {
    return (
        <div className={style.root} style={{ ...customStyle }}>
            {icon && (
                <div className={style.icon} style={{ width: icon.size, height: icon.size }}>
                    <Icon type={icon.type} svgSize={icon.size} color={icon.color} />
                </div>
            )}

            <div className={style.title}>{title}</div>

            {description && <div className={style.description}>{description}</div>}
        </div>
    );
};
