import * as React from 'react';
import * as lodash from 'lodash';

import * as style from './TableSettings.scss';

import { Icon, IconType, FilterMenu, FilterCheckboxBorderType, BodyPortal } from 'sber-marketing-ui';

import { ColumnName } from '../types';
import { MakeColumnsConfig } from '../ColumnsConfig';

interface Props {
    columns: ColumnName[];
    leftFixedColumns: ColumnName[];
    rightFixedColumns: ColumnName[];
    visibleColumns: ColumnName[];
    onVisibleColumnsChange: (newVisibleColumns: ColumnName[]) => void;
}

export const TableSettings = ({
    columns,
    leftFixedColumns,
    rightFixedColumns,
    visibleColumns,
    onVisibleColumnsChange,
}: Props): JSX.Element => {
    const [isOpened, setOpenedStatus] = React.useState(false);

    const ColumnsConfig = MakeColumnsConfig({});

    const filterMenuItems = React.useMemo(() => {
        const allColumns = [...leftFixedColumns, ...columns, ...rightFixedColumns];

        return lodash.without(allColumns, 'lineStatus', 'actStatus').map((columnName) => ({
            id: columnName,
            title: ColumnsConfig[columnName].title as string,
        }));
    }, [columns, leftFixedColumns, rightFixedColumns]);

    const onOpenerClick = React.useCallback(() => {
        setOpenedStatus(!isOpened);
    }, [isOpened]);

    const onMaskClick = React.useCallback(() => {
        setOpenedStatus(false);
    }, []);

    return (
        <div className={style.root}>
            <div className={style.opener} onClick={onOpenerClick}>
                <div className={style.openerIcon}>
                    <Icon type={IconType.GEAR16} />
                </div>
                Настроить таблицу
            </div>

            {isOpened && (
                <>
                    <div className={style.content}>
                        <div className={style.contentDescription}>Настроить таблицу</div>

                        <FilterMenu
                            filterTitle={'Отображаемые колонки'}
                            items={filterMenuItems}
                            checkedItemsIds={visibleColumns}
                            onItemSelection={onVisibleColumnsChange}
                            border={FilterCheckboxBorderType.NONE}
                            preserveAllSelectedState
                            freezeExpansion
                            disableSort
                            isExpanded
                        />
                    </div>

                    <BodyPortal>
                        <div className={style.mask} onClick={onMaskClick} />
                    </BodyPortal>
                </>
            )}
        </div>
    );
};
