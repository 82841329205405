// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionNegativeLineBalanceWarning-LinesWithNegativeBalance-_LinesWithNegativeBalance__root{margin-top:16px}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionNegativeLineBalanceWarning-LinesWithNegativeBalance-_LinesWithNegativeBalance__item{width:100%}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionNegativeLineBalanceWarning-LinesWithNegativeBalance-_LinesWithNegativeBalance__item:not(:first-of-type){margin-top:8px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/InternalTransitionNegativeLineBalanceWarning/LinesWithNegativeBalance/LinesWithNegativeBalance.scss"],"names":[],"mappings":"AAAA,+KACI,eAAgB,CACnB,+KAGG,UAAW,CADf,mMAIQ,cAAe","sourcesContent":[".root {\n    margin-top: 16px;\n}\n\n.item {\n    width: 100%;\n\n    &:not(:first-of-type) {\n        margin-top: 8px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionNegativeLineBalanceWarning-LinesWithNegativeBalance-_LinesWithNegativeBalance__root",
	"item": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionNegativeLineBalanceWarning-LinesWithNegativeBalance-_LinesWithNegativeBalance__item"
};
export default ___CSS_LOADER_EXPORT___;
