import * as React from 'react';

export interface UsePopup {
    (): UsePopupReturn;
}

export interface UsePopupReturn {
    opened: boolean;
    setOpened: (opened: boolean) => void;
}

export const usePopup: UsePopup = () => {
    const [opened, setOpened] = React.useState(false);

    return {
        opened,
        setOpened,
    };
};
