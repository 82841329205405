import * as React from 'react';

import { WithTitle } from '../WithTitle';
import { Icon, IconType, WithTooltip } from 'sber-marketing-ui';
import * as style from './ChangedParamList.scss';

interface Value {
    value: string;
    tooltip?: string;
}

export interface ChangedParam {
    title: string;
    oldValue: Value;
    newValue: Value;
}

interface Props {
    params: ChangedParam[];
}

export const ChangedParamList: React.FC<Props> = ({ params }) => {
    return (
        <div className={style.root}>
            {params.map((param, index) => (
                <div
                    className={style.params}
                    key={index}
                    {...{
                        'qa-id': 'correctionCardParamsChange',
                        'qa-label': param.title,
                    }}
                >
                    <WithTitle title={param.title}>
                        <div className={style.paramsBody}>
                            <WithTooltip hidden={!param.oldValue.tooltip} content={param.oldValue.tooltip}>
                                <div className={style.oldParam}>
                                    <span
                                        {...{
                                            'qa-id': 'correctionCardParamsChangeBefore',
                                        }}
                                    >
                                        {param.oldValue.value || 'Значение не выбрано'}
                                    </span>
                                    <div className={style.arrowPointer}>
                                        <div className={style.arrowIconWrapper}>
                                            <Icon type={IconType.ARROW_ROUNDED} />
                                        </div>
                                    </div>
                                </div>
                            </WithTooltip>

                            <WithTooltip hidden={!param.newValue.tooltip} content={param.newValue.tooltip}>
                                <div
                                    className={style.newParam}
                                    {...{
                                        'qa-id': 'correctionCardParamsChangeAfter',
                                    }}
                                >
                                    {param.newValue.value || 'Значение не выбрано'}
                                </div>
                            </WithTooltip>
                        </div>
                    </WithTitle>
                </div>
            ))}
        </div>
    );
};
