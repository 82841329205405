import * as moment from 'moment';
import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { AccountStatus } from 'sber-marketing-types/openid';
import { ActivityStatus, ActivityParams } from 'sber-marketing-types/frontend';
import { TaskCreatedBy } from 'sber-marketing-types/backend';

import { ActivityApi, UserApi, DictionaryApi, TaskApi } from '@api';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';
import { isRequestInProgress, setRequestInProgress } from '@store/common';
import { updateInstanceDescriptor, flushOnDemandUpdates } from '@store/tagsEditor';

import * as syncActions from './actions/sync';
import * as asyncActions from './actions/async';
import { PageValues, SaveActivityPayload } from './types';
import { defineDictionaryOrganizationIdByOrganizationId } from './utils';
import { makeCreateActivityData, makeUpdateActivityData } from './selectors';
import { DictionaryType } from '@mrm/dictionary';

export const loadPageData = bindThunkAction<StoreState, number, void, Error>(
    asyncActions.loadPageData,
    async (activityId, dispatch, getState) => {
        const state = getState();
        const currentUser = getLoginUser(state).attributes;
        const userDictionaryOrganizationId = defineDictionaryOrganizationIdByOrganizationId(currentUser.organizationId);

        dispatch(setRequestInProgress(true));

        let activity: ActivityParams = null;
        let bpmWasInit = false;

        if (activityId) {
            activity = await ActivityApi.getActivity(Number(activityId));
            bpmWasInit = (await TaskApi.getFilteredTaskCardsList({ activityId })).tasks.some(
                (task) => task.createdBy === TaskCreatedBy.Auto,
            );
        }
        const dictionaryOrganizationId = activity?.dictionaryOrganizationId || userDictionaryOrganizationId;

        const [activityTypes, blocks, products, calendarGroups, users] = await Promise.all([
            DictionaryApi.getDictionaryList({
                types: [DictionaryType.ActivityType],
                organizationId: dictionaryOrganizationId,
                treeview: true,
            }),
            DictionaryApi.getDictionaryList({
                types: [DictionaryType.Block],
                organizationId: dictionaryOrganizationId,
                treeview: true,
            }),
            DictionaryApi.getDictionaryList({
                types: [DictionaryType.Product],
                organizationId: dictionaryOrganizationId,
                treeview: true,
            }),
            DictionaryApi.getDictionaryList({
                types: [DictionaryType.CalendarGroup],
                organizationId: dictionaryOrganizationId,
                treeview: true,
            }),
            UserApi.getUserListFiltered({ organizationIds: [currentUser.organizationId] }),
        ]);

        let pageValues: Partial<PageValues>;
        if (activity) {
            pageValues = {
                preparationDate: activity.preparationDate
                    ? moment(activity.preparationDate)
                    : moment(activity.realizationStart),
                realizationStart: moment(activity.realizationStart),
                realizationEnd: moment(activity.realizationEnd),
                debriefingDate: activity.debriefingDate
                    ? moment(activity.debriefingDate)
                    : moment(activity.realizationEnd),
                divisionId: activity.divisionId,
                productId: activity.productId,
                calendarGroupId: activity.calendarGroupId,
                activityTypeId: activity.typeId,
                responsibleId: activity.responsibleId,
                name: activity.name,
                description: activity.description,
                autoCreateTasks: bpmWasInit,
                bpmWasInit,
            };

            if (!users.some((user) => user.id === activity.responsibleId)) {
                const responsibleUser = await UserApi.getUser(activity.responsibleId);

                users.push(responsibleUser);
            }
            if (!users.some((user) => user.id === currentUser.id)) {
                users.push(currentUser);
            }
        } else {
            pageValues = { responsibleId: currentUser.id };
        }

        dispatch(
            syncActions.loadNewActivityPageData({
                activity,
                activityTypes,
                blocks,
                users: users.filter(
                    (user) =>
                        user.status === AccountStatus.ACTIVE || user.status === AccountStatus.NEED_UPDATE_PASSWORD,
                ),
                products,
                calendarGroups,
            }),
        );
        dispatch(syncActions.setValues(pageValues));
        dispatch(syncActions.setBpmWasInit(bpmWasInit));
        dispatch(setRequestInProgress(false));
    },
);

export const saveActivity = bindThunkAction<StoreState, SaveActivityPayload, number, Error>(
    asyncActions.saveActivity,
    async (payload, dispatch, getState) => {
        const { isDraft, tagsEditorId } = payload;
        const state = getState();

        let activityId = payload.activityId;
        if (!isRequestInProgress(state)) {
            dispatch(setRequestInProgress(true));

            if (activityId) {
                const editActivityParams = makeUpdateActivityData(state);

                await ActivityApi.editActivity(activityId, editActivityParams);
            } else {
                const createActivityParams = {
                    ...makeCreateActivityData(state),
                    status: isDraft ? ActivityStatus.Draft : ActivityStatus.Active,
                };

                activityId = await ActivityApi.createActivity(createActivityParams);

                dispatch(
                    updateInstanceDescriptor({
                        id: tagsEditorId,
                        payload: { activityId },
                    }),
                );
            }

            dispatch(flushOnDemandUpdates(tagsEditorId));
        }

        return activityId;
    },
);
