import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { castArray, isEmpty, uniq } from 'lodash';
import { TaskListFilters, TaskPageSortBy } from 'sber-marketing-types/frontend';
import { SortingOrder } from 'sber-marketing-types/backend';

import { TaskApi, WorkTypeApi } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getLoginUser } from '@store/user/selector';
import { getTaskFilters } from '@store/activityTasksPage';
import { getMyTasksPageFilters, TasksFilter, TaskParticipation } from '@store/userConfig';

import {
    fetchMoreTasks as fetchMoreTasksAction,
    updateTaskCardById as updateTaskCardByIdAction,
    deleteTasks as deleteTasksAction,
    loadWorkTypes as loadWorkTypesAction,
    setCanBeLoadedMore,
    pushTasks,
    removeTasks,
    setLastRequestDate,
    resetLastRequestDate,
    setWorkTypesLoadingStatus,
} from './actions';
import {
    getLastTask,
    getType,
    getActivityId,
    getTasksList,
    getLastRequestDate,
    getCardType,
    getTasksListState,
} from './selectors';
import { TasksListType, TaskCardParamsResponse, TaskCardParams, TaskCardType, WorkTypesState } from './types';

const NORMAL_CARD_TASK_PAGINATION_SIZE = 10;
const SHORT_CARD_TASK_PAGINATION_SIZE = 30;

export const fetchMoreTasks = bindThunkAction<StoreState, TasksListType, boolean, Error>(
    fetchMoreTasksAction,
    async (type, dispatch, getState) => {
        const state = getState();

        let filters;
        switch (type) {
            case TasksListType.MY_TASKS:
                filters = getMyTasksPageFilters(state);
                break;
            case TasksListType.ACTIVITY_TASKS:
                filters = getTaskFilters(state);
                break;
        }

        const limit =
            getCardType(state) == TaskCardType.NORMAL
                ? NORMAL_CARD_TASK_PAGINATION_SIZE
                : SHORT_CARD_TASK_PAGINATION_SIZE;

        const requestTimestamp = `${new Date()}-${Math.random()}`;

        dispatch(setLastRequestDate(requestTimestamp));
        const { hasNextPage, tasks } = await TaskApi.getFilteredTaskCardsList(
            parseFilters({
                filters,
                userId: getLoginUser(state).attributes.id,
                lastTask: getLastTask(state),
                type: getType(state),
                offset: getTasksList(state).length,
                limit,
                activityId: getActivityId(state),
            }),
        );

        // using only last request
        const lastRequestTimestamp = getLastRequestDate(getState());
        let wasLastRequest = false;
        if (requestTimestamp === lastRequestTimestamp) {
            dispatch(resetLastRequestDate());
            dispatch(setCanBeLoadedMore(hasNextPage));
            dispatch(pushTasks(tasks.map((task) => ({ ...task, isLoading: false }))));
            wasLastRequest = true;
        }

        return wasLastRequest;
    },
);

export const deleteTasks = bindThunkAction<StoreState, string | string[], void, Error>(
    deleteTasksAction,
    async (payload, dispatch) => {
        const ids = castArray(payload);
        dispatch(removeTasks(ids));
        await Promise.all(ids.map((id) => TaskApi.deleteTask(id)));
    },
);

interface ParseFiltersParams {
    filters: TasksFilter;
    userId: number;
    lastTask: TaskCardParams | null | undefined;
    type: TasksListType;
    activityId: number | null;
    offset: number;
    limit: number;
}

const parseFilters = ({
    filters: { status, sorting, workType, participation, author, executor, department, activityStage },
    userId,
    lastTask,
    type,
    activityId,
    offset,
    limit,
}: ParseFiltersParams): TaskListFilters => {
    const result: TaskListFilters = {};
    result.sortBy = sorting;
    result.offset = offset;
    result.limit = limit;

    const authorIds = [...author] || [];

    if (sorting == TaskPageSortBy.DEADLINE) {
        result.orderBy = SortingOrder.ASC;
    } else {
        result.orderBy = SortingOrder.DESC;
    }

    if (status) {
        result.status = status;
    }

    if (!isEmpty(workType)) {
        result.workTypeIds = workType;
    }

    if (!isEmpty(executor)) {
        result.executorIds = executor;
    }

    if (!isEmpty(department)) {
        result.departmentIds = department;
    }

    if (!isEmpty(activityStage)) {
        result.stageIds = activityStage;
    }

    if (participation === TaskParticipation.AUTHOR) {
        authorIds.push(userId);
    }

    if (participation === TaskParticipation.EXECUTOR) {
        result.executorIds = [userId];
    }

    if (participation === TaskParticipation.PARTICIPANT) {
        result.participant = true;
    }

    if (lastTask) {
        result.lastId = lastTask.id;
    }

    if (type === TasksListType.ACTIVITY_TASKS) {
        result.activityId = activityId;
    }

    if (authorIds.length) {
        result.authorIds = uniq(authorIds);
    }

    return result;
};

export const updateTaskCard = bindThunkAction<StoreState, string, TaskCardParamsResponse, Error>(
    updateTaskCardByIdAction,
    async (taskId) => {
        return await TaskApi.getTaskCard(taskId);
    },
);

export const loadWorkTypes = bindThunkAction<StoreState, null, WorkTypesState, Error>(
    loadWorkTypesAction,
    async (_, dispatch, getState) => {
        const workTypesState = getTasksListState(getState()).workTypes;

        if (workTypesState.loadingStatus === LoadingStatus.NOT_LOADED) {
            dispatch(setWorkTypesLoadingStatus(LoadingStatus.LOADING));

            const workTypes = await WorkTypeApi.getList();

            return {
                loadingStatus: LoadingStatus.LOADED,
                dictionary: workTypes.reduce(
                    (acc, workType) => ({
                        ...acc,
                        [workType.id]: workType,
                    }),
                    {},
                ),
            };
        }

        return workTypesState;
    },
);
