import * as React from 'react';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { Money, MoneyFormatter, Utils } from '@common/Utils';
import { orderOfMonth, Funds, Correction } from './types';
import { Card } from './WithCard';

import { BudgetInfo, InfoBlockParams } from './BudgetInfo';
import { FieldName } from './Params';

interface Props {
    hasBudgetItemAccess: boolean;
    activityId: number;
    card: Card;
    corrections: Correction[];
    loading: boolean;
    updateCard: () => Promise<void>;
    updateCorrections: () => Promise<void>;
    refetchData: () => Promise<void>;
}

interface State {
    hoveredCorrectionCardId: string;
}

export class BudgetInfoContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            hoveredCorrectionCardId: null,
        };
    }

    public render() {
        const { corrections, card, loading, hasBudgetItemAccess, updateCorrections, updateCard, refetchData } =
            this.props;
        const { hoveredCorrectionCardId } = this.state;

        return React.createElement(BudgetInfo, {
            hasBudgetItemAccess,
            loading,
            corrections,
            budgetItem: card ? card.budgetItem : null,
            hoveredCorrectionCardId,
            paramsFields: this.makeParamsFields(),
            onHoverCorrectionCard: this.onHoverCorrectionCard,
            updateCorrections,
            updateCard,
            refetchData,
        });
    }

    private makeParamsFields(): Record<FieldName, InfoBlockParams> {
        const { activityId, card, hasBudgetItemAccess } = this.props;

        if (!card) {
            return {} as Record<FieldName, InfoBlockParams>;
        }

        const { serialNumber, dictionary, sapComment, budget, activity } = card.budgetItem;

        const budgetItemLink = `/budget/execution?activityId=${activityId}&filters=serialNumber:${serialNumber}&budgetId=${budget.id}`;

        return {
            [FieldName.Id]: {
                title: 'ID',
                content: serialNumber.toString(),
                link: hasBudgetItemAccess ? budgetItemLink : null,
            },
            [FieldName.SapComment]: {
                title: 'Название проекта (комментарий для SAP)',
                content: sapComment || 'Не указано',
            },
            [FieldName.Tool]: {
                title: 'Инструмент',
                content: Utils.withErrorHandler(() => Utils.getDictionaryValue(dictionary.tool)) || 'Не задан',
            },
            [FieldName.Product]: {
                title: 'Продукт',
                content: Utils.withErrorHandler(() => Utils.getDictionaryValue(dictionary.product)) || 'Не задан',
            },
            [FieldName.Activity]: {
                title: 'Активность',
                content: lodash.get(activity, 'name') || 'Не указана',
            },
            [FieldName.Block]: {
                title: 'Блок',
                content: Utils.withErrorHandler(() => Utils.getDictionaryValue(dictionary.block)) || 'Не задан',
            },
            [FieldName.Plan]: {
                title: 'План',
                content: this.formatMoneyWithSign(this.getSumPlanFunds()),
            },
            [FieldName.Reserve]: {
                title: 'Резерв',
                content: this.formatMoneyWithSign(this.getSumReserveFunds()),
            },
            [FieldName.Fact]: {
                title: 'Факт',
                content: this.formatMoneyWithSign(this.getSumFactFunds()),
            },
        };
    }

    @autobind
    private onHoverCorrectionCard(correctionId: string): void {
        this.setState({ hoveredCorrectionCardId: correctionId });
    }

    private formatMoneyWithSign(money: number): string {
        return MoneyFormatter.toThousands(Money.fromCopecks(money));
    }

    private getSumPlanFunds(): number {
        const {
            card: { budgetItem },
        } = this.props;
        return this.calculateSumFunds(budgetItem.plannedFunds);
    }

    private getSumReserveFunds(): number {
        const {
            card: { budgetItem },
        } = this.props;
        return this.calculateSumFunds(budgetItem.reservedFunds);
    }

    private getSumFactFunds(): number {
        const {
            card: { budgetItem },
        } = this.props;
        return this.calculateSumFunds(budgetItem.factFunds);
    }

    private calculateSumFunds(funds: Funds): number {
        return orderOfMonth.reduce((acc, monthName) => acc + (funds[monthName] || 0), 0);
    }
}
