import * as React from 'react';
import classNames from 'classnames';
import * as style from './GeneralInfo.scss';

interface Props {
    infoBlockParamsList: InfoBlockParams[];
}

export interface InfoBlockParams {
    title: string;
    content: string;
    qaId: string;
}

export const GeneralInfo: React.FC<Props> = ({ infoBlockParamsList }) => {
    return (
        <div
            className={classNames(style.root, style.blockWrapper, style.bottomBorder)}
            {...{
                'qa-id': 'budgetExecutionLineModalGeneralInfo',
            }}
        >
            {infoBlockParamsList.map((infoBlockParams) => (
                <div key={infoBlockParams.title} className={style.info} {...{ 'qa-id': infoBlockParams.qaId }}>
                    <div className={style.title}>{infoBlockParams.title}</div>
                    <div className={style.content}>{infoBlockParams.content || '-'}</div>
                </div>
            ))}
        </div>
    );
};
