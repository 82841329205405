import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import {
    InternalTransferDirection,
    getBudgetTransferMenuState,
    setRowForSumEntering,
} from '@store/budgetExecution/budgetTransferMenu';

import { RowSelectorItem } from './RowSelectorItem';

import * as styles from './RowSelectors.scss';

function useInteractivity() {
    const dispatch = useDispatch();

    const to = useSelector((state: StoreState) => getBudgetTransferMenuState(state).cells.to);
    const rowForSumEntering = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.rowForSumEntering,
    );
    const canHaveOnlyOneToRow = useSelector(
        (state: StoreState) =>
            getBudgetTransferMenuState(state).controls.internalTransferDirection ===
            InternalTransferDirection.ManyToOne,
    );

    function onItemClick(itemId: string) {
        dispatch(setRowForSumEntering(itemId));
    }

    return {
        to,
        rowForSumEntering,
        canHaveOnlyOneToRow,
        onItemClick,
    };
}

export function DestRowSelector(): JSX.Element {
    const { to, rowForSumEntering, canHaveOnlyOneToRow, onItemClick } = useInteractivity();

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'internalTransitionSumEnteringDestRowSelector',
            }}
        >
            {to.map((toCell) => (
                <RowSelectorItem
                    key={toCell.lineId}
                    id={toCell.lineId}
                    isSelected={canHaveOnlyOneToRow || toCell.lineId === rowForSumEntering}
                    onItemClick={onItemClick}
                />
            ))}
        </div>
    );
}
