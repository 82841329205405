import * as React from 'react';
import { connect } from 'react-redux';
import { DataProps, graphql } from 'react-apollo';
import { get } from 'lodash';
import { GET_CARDS_QUERY } from './query';

import { StoreState } from '@store';
import { getDashboardPageState } from '@store/dashboardPage';

import { Card } from '../types';

export interface ChildrenProps {
    cards: Card[];
    loading: boolean;
}

interface Props extends QueryProps, ExternalProps {}

interface ExternalProps extends MapProps {
    loadedCards: Card[];
    children?: (props: ChildrenProps) => JSX.Element;
}

type QueryProps = DataProps<{
    cards: {
        count: number;
        nodes: Card[];
    };
}>;

interface MapProps {
    selectedCardId: string;
}

class CardsQuery extends React.PureComponent<Props> {
    public render() {
        return this.props.children(this.getChildrenProps());
    }

    private getChildrenProps(): ChildrenProps {
        const cards: Card[] = get(this.props, 'data.cards.nodes') || [];
        const loading = get(this.props.data, 'loading');

        return {
            cards,
            loading,
        };
    }
}

export const WithSelectedCard = connect(mapStateToProps)(
    graphql<ExternalProps>(GET_CARDS_QUERY, {
        options: ({ selectedCardId }) => ({
            variables: {
                filter: {
                    id: [selectedCardId],
                    type: 'linkBudgetItemToProject',
                },
                limit: 1,
            },
        }),
        skip: ({ selectedCardId, loadedCards }) =>
            !selectedCardId || loadedCards.some((card) => card.id === selectedCardId),
    })(CardsQuery),
);

function mapStateToProps(state: StoreState): MapProps {
    const { selectedCard } = getDashboardPageState(state);

    return {
        selectedCardId: selectedCard ? selectedCard.id : null,
    };
}
