import * as React from 'react';
import * as lodash from 'lodash';
import autobind from 'autobind-decorator';

import type {
    Autopilot2,
    Autopilot2MediaplanPlanDataItem as MediaplanItem,
    PredictionDigital,
} from 'sber-marketing-types/backend';
import type { BriefStageForm } from '@store/autopilot/types';
import { CellParams, CellPosition, ColumnHeaderParams, ColumnName, TableEvent } from './types';

import { TableTemplate } from './TableTemplate';
import { TableViewModel } from './TableViewModel';
import { TableView } from './TableView';
import {
    totalColumns,
    tableColumns,
    totalColumnsWithStatus,
    tableColumnsWithStatus,
    columnsConfig,
    COLUMN_NAME,
} from './ColumnsConfig';

interface Props {
    autopilot: Autopilot2;
    mediaplanItems: MediaplanItem[];
    prediction: PredictionDigital;
    briefForm: BriefStageForm;
    displayStatuses?: boolean;
}

export class TableBehaviour extends React.PureComponent<Props> {
    private viewModel: TableViewModel;
    private tables: Record<string, TableView>;

    public constructor(props: Props) {
        super(props);

        this.viewModel = new TableViewModel({
            makeColumnHeaderParams: this.makeColumnHeaderParams,
            makeCellParams: this.makeCellParams,
        });

        this.tables = {};
    }

    public async componentDidMount() {
        this.syncTablesScroll();
    }

    public render(): JSX.Element {
        const { mediaplanItems, prediction, displayStatuses } = this.props;

        const groupedLines = lodash.groupBy(mediaplanItems, (item) => item.channel);

        let lineGroups = lodash.flatMap(groupedLines, (lines, key) => ({
            name: key,
            lines: lines.map((item, index) => `${key}|${index}`),
        }));

        lineGroups = lodash.sortBy(lineGroups, (item) => item.name);

        const displayPrediction = prediction !== undefined;

        const totalTableColumns: string[] = displayStatuses ? totalColumnsWithStatus : totalColumns;

        return React.createElement(TableTemplate, {
            viewModel: this.viewModel,
            totalColumns: displayPrediction
                ? totalTableColumns
                : lodash.without(totalTableColumns, COLUMN_NAME.Prediction, COLUMN_NAME.KpiCost),
            tableColumns: displayStatuses ? tableColumnsWithStatus : tableColumns,
            lineGroups,
            tableRef: this.tableRef,
        });
    }

    @autobind
    protected tableRef(name: string, component: TableView) {
        if (component) {
            this.tables[name] = component;
        } else {
            delete this.tables[name];
        }
    }

    @autobind
    protected onTableScroll(tableName: string) {
        const newScrollValue = this.tables[tableName].getBodyScroll();

        lodash.forEach(this.tables, (component, name) => {
            if (name !== tableName && component.getBodyScroll().x !== newScrollValue.x) {
                component.setBodyScroll(newScrollValue);
            }
        });
    }

    @autobind
    protected onTableEvent(eventType: TableEvent, tableName: string) {
        switch (eventType) {
            case TableEvent.Scroll:
                this.onTableScroll(tableName);
                break;
        }
    }

    @autobind
    private makeColumnHeaderParams(columnName: ColumnName): ColumnHeaderParams {
        return {
            title: this.getColumnTitle(columnName),
        };
    }

    @autobind
    private makeCellParams(cellPosition: CellPosition): CellParams {
        const { autopilot, mediaplanItems, prediction } = this.props;
        const { columnName, lineId } = cellPosition;
        const { accessor, totalLineAccessor } = columnsConfig[columnName];

        const [groupName, index] = lineId.split('|');

        const params: CellParams = {};

        if (lineId === 'total') {
            const value = totalLineAccessor({
                allMediaplanItems: mediaplanItems,
                allPlacements: autopilot.placements,
                prediction,
            });

            params.title = value !== undefined && value !== null ? value : '—';
            params.style = {
                fontWeight: 600,
                backgroundColor: '#f6f6f6',
            };

            if (columnName === COLUMN_NAME.Status) {
                delete params.style.fontWeight;
            }

            if (
                columnName !== COLUMN_NAME.LineName &&
                columnName !== COLUMN_NAME.Targeting &&
                columnName !== COLUMN_NAME.Format &&
                columnName !== COLUMN_NAME.Status
            ) {
                params.description = this.getColumnTitle(columnName);
            }
        } else if (index === undefined) {
            if (columnName == COLUMN_NAME.LineName) {
                params.title = groupName;
                params.style = {
                    fontWeight: 600,
                };
            }
        } else {
            const line = mediaplanItems.filter((item) => item.channel === groupName)[index];
            const placement = autopilot.placements.find((item) => item.code === line.code);

            const value = accessor({
                mediaplanItem: line,
                placement,
                prediction,
            });

            params.title = value !== undefined && value !== null ? value : '—';

            if (line.ilId !== undefined) {
                params.style = {
                    backgroundColor: '#def9e6',
                };
            }
        }

        return params;
    }

    private getColumnTitle(columnName: ColumnName): string {
        const titleField = lodash.get(columnsConfig, [columnName, 'title']);

        return lodash.isFunction(titleField)
            ? titleField({
                  prediction: this.props.prediction,
              })
            : titleField;
    }

    private syncTablesScroll() {
        lodash.forEach(this.tables, (component, name) => {
            component.subscribeTableEvent(TableEvent.Scroll, () => this.onTableEvent(TableEvent.Scroll, name));
        });
    }
}
