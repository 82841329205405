import * as React from 'react';
import classNames from 'classnames';
import {
    Popup,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    Preloader,
    CustomScrollbar,
} from 'sber-marketing-ui';
import { includes, isEmpty } from 'lodash';

import * as style from './ActivitySharing.scss';
import { UserCard } from './UserCard';
import { UsersSelectSuggest } from '../../UsersSelectSuggest';
import { ActivitySharingTemplateProps } from './types';

export const ActivitySharing = ({
    isLoading,
    isActivitySharingInProgress,
    appUsers,
    ownerships,
    onSwitch,
    onFormClose,
    onSaveForm,
    newOwnerships,
    removedOwnerships,
    userIsDirector,
    userIsAuthor,
    loginnedUserId,
}: ActivitySharingTemplateProps): JSX.Element => {
    const showPreloader = isActivitySharingInProgress || isLoading;
    const hasNewOwnerships = newOwnerships > 0;
    const hasRemovedOwnerships = removedOwnerships > 0;

    return (
        <Popup>
            <div
                className={style.root}
                {...{
                    'qa-id': 'activitySharing',
                }}
            >
                {!isLoading && (
                    <React.Fragment>
                        <div className={style.popupTitle}>Поделиться проектом</div>

                        <div className={style.dropdownWrapper}>
                            <UsersSelectSuggest
                                qaId="activitySharingUserDropdown"
                                title="Имя Фамилия"
                                users={appUsers.map(({ id, isActive, firstName, secondName, department }) => ({
                                    id,
                                    isActive,
                                    department,
                                    name: `${firstName} ${secondName}`,
                                    canRemove: userIsDirector || userIsAuthor,
                                    isSelected: includes(ownerships, id),
                                }))}
                                onSuggestItemSelect={({ id }) => onSwitch(id as number)}
                            />
                        </div>

                        {!isEmpty(ownerships) && (
                            <CustomScrollbar
                                autoHeight
                                hideTracksWhenNotNeeded
                                autoHeightMin={'180px'}
                                autoHeightMax={'180px'}
                            >
                                <div className={style.userCardList}>
                                    {appUsers
                                        .filter(({ id }) => includes(ownerships, id))
                                        .map(({ id, firstName, secondName, department }) => (
                                            <UserCard
                                                key={id}
                                                mainTitle={`${firstName} ${secondName}`}
                                                subTitle={department}
                                                canRemove={userIsDirector || userIsAuthor}
                                                onDelete={() => onSwitch(id)}
                                            />
                                        ))}
                                </div>
                            </CustomScrollbar>
                        )}

                        <div className={style.buttonsWrapper}>
                            <div
                                className={style.buttonWrapper}
                                onClick={onFormClose}
                                {...{
                                    'qa-id': 'activitySharingCloseButton',
                                }}
                            >
                                <Button theme={ButtonTheme.Ghost}>
                                    <div className={style.buttonText}>Отмена</div>
                                </Button>
                            </div>

                            <div
                                className={style.buttonWrapper}
                                onClick={onSaveForm}
                                {...{
                                    'qa-id': 'activitySharingSaveButton',
                                }}
                            >
                                <Button>
                                    <div className={style.buttonText}>
                                        <span>Поделиться</span>

                                        {(hasNewOwnerships || hasRemovedOwnerships) && (
                                            <span className={style.newItems}>
                                                {hasNewOwnerships && (
                                                    <div
                                                        className={classNames(
                                                            style.countIndicator,
                                                            style.positiveCountIndicator,
                                                        )}
                                                    >
                                                        {`${newOwnerships}`}
                                                    </div>
                                                )}
                                                {hasRemovedOwnerships && (
                                                    <div
                                                        className={classNames(
                                                            style.countIndicator,
                                                            style.negativeCountIndicator,
                                                        )}
                                                    >
                                                        {`${removedOwnerships}`}
                                                    </div>
                                                )}
                                            </span>
                                        )}
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </React.Fragment>
                )}

                {showPreloader && <Preloader />}
            </div>
        </Popup>
    );
};
