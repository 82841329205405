import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import {
    getFilterPeriod,
    getFilterDate,
    getFilteredChildrenOfSelectedStatisticsItem,
    FilterPeriod,
} from '@store/leaderDashboardPage';
import type { StatisticsItem, StatisticsType } from '@store/leaderDashboardPage';

interface UseState {
    (): UseStateReturn;
}

interface UseStateReturn extends Selected {}

interface Selected {
    selectedPeriod: FilterPeriod;
    selectedDate: string;
    statisticsItems: StatisticsItem[];
}

export const useState: UseState = () => {
    const { selectedPeriod, selectedDate, statisticsItems } = useSelector<StoreState, Selected>((state) => {
        return {
            selectedPeriod: getFilterPeriod(state),
            selectedDate: getFilterDate(state),
            statisticsItems: getFilteredChildrenOfSelectedStatisticsItem(state),
        };
    });

    return {
        selectedPeriod,
        selectedDate,
        statisticsItems,
    };
};

export { StatisticsType };
