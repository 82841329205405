import * as React from 'react';
import classNames from 'classnames';

import * as style from './SwitchKeyActivityButton.scss';

import { FlagToggle } from '@common/FlagToggle';

interface Props {
    displayed: boolean;
    selected: boolean;
    hover: boolean;
    onClick: (event: EventClick) => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}

export type EventClick = React.MouseEvent<HTMLDivElement, MouseEvent>;

const FLAG_TOGGLE_SIZE = 20;

export const SwitchKeyActivityButton: React.FC<Props> = ({
    displayed,
    hover,
    selected,
    onClick,
    onMouseEnter,
    onMouseLeave,
}) => {
    return displayed ? (
        <div
            className={classNames(style.root, selected && style.selected)}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            {...{
                'qa-id': 'calendarHeaderKeyActivityToggle',
            }}
        >
            <div className={style.flagTogglePosition}>
                <FlagToggle selected={selected} canChange={true} size={FLAG_TOGGLE_SIZE} hover={hover} />
            </div>

            <div className={style.text}>Только ключевые проекты</div>
        </div>
    ) : null;
};
