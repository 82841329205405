import * as React from 'react';
import * as lodash from 'lodash';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { UserResponseParams } from 'sber-marketing-types/frontend';

import * as style from './Line.scss';

import { MultiReferenceDictionaryApi } from '@api';

import { StoreState } from '@store';
import {
    ColumnData,
    ColumnName,
    CustomCellType,
    TableLine,
    ColumnsWidth,
    NumericCellValue,
    CellValueType,
    getBudgetExecutionPageState,
} from '@store/budgetExecution';
import {
    ComponentState as BudgetTransferMenuComponentState,
    getBudgetTransferMenuState,
} from '@store/budgetExecution/budgetTransferMenu';
import { InternalTransferDirection } from '@store/budgetExecution/budgetTransferMenu';
import { LineColor, LineColorName, LineMenuColor } from '../TableBody';

import { TextCell, SelectableCell, InputCell, DropdownCell, DatepickerCell, IndicatorsCell } from '../CellTypes';
import { CellParams } from '../LayerManager';
import { LineMenu } from './LineMenu';

export const LINE_MENU_WIDTH = 184;

export interface LineProps {
    line: TableLine;
    lineHasPlanReserveCorrections: boolean;
    planIsOverrunned: boolean;
    columns: ColumnData[];
    fixedColumnsNames: ColumnName[];
    columnsWidth: ColumnsWidth;
    width: number;
    lineColor: LineColor;
    lineIsHovered: boolean;
    isTransitionMenuOpened: boolean;
    allUsers: UserResponseParams[];
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi;
    currentYScroll: number;
    fixedColumnsLinesRef: (element: HTMLDivElement) => void;
    onMouseEnter: (lineId: string) => void;
    onMouseLeave: () => void;
    onCellClick: (lineId: string, columnName: ColumnName) => void;
    onCellMouseEnter: (lineId: string, columnName: ColumnName) => void;
    onCellMouseLeave: () => void;
    onInputValueChange: (
        lineId: string,
        columnName: ColumnName,
        value: string,
        originalValue: string,
        generateSameValueChange: boolean,
    ) => void;
    onDropdownCellClick: (
        columnName: ColumnName,
        lineId: string,
        dropdownContent: JSX.Element,
        contentHeight: number,
    ) => void;
    onDropdownItemSelection: (lineId: string, columnName: ColumnName, selectedId: string, originalId: string) => void;
    onDatepickerCellClick: (
        columnName: ColumnName,
        lineId: string,
        dropdownContent: JSX.Element,
        contentHeight: number,
    ) => void;
    onDatepickerValueChange: (lineId: string, columnName: ColumnName, value: Date, originalValue: Date) => void;
    onInfoMouseEnter: (lineId: string) => void;
    onInfoMouseLeave: () => void;
    onCellCopy: (
        lineId: string,
        columnName: ColumnName,
        cellParams: CellParams,
        event: React.ClipboardEvent<HTMLDivElement>,
    ) => void;
    onLineClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

interface Props extends LineProps {}

export const Line = ({
    line,
    lineHasPlanReserveCorrections,
    planIsOverrunned,
    columns,
    fixedColumnsNames = [],
    columnsWidth,
    width,
    lineColor,
    lineIsHovered,
    isTransitionMenuOpened,
    allUsers,
    multiReferenceDictionaryApi,
    currentYScroll,
    fixedColumnsLinesRef,
    onMouseEnter,
    onMouseLeave,
    onCellClick,
    onCellMouseEnter,
    onCellMouseLeave,
    onInputValueChange,
    onDropdownCellClick,
    onDropdownItemSelection,
    onDatepickerCellClick,
    onDatepickerValueChange,
    onInfoMouseEnter,
    onInfoMouseLeave,
    onCellCopy,
    onLineClick,
}: Props): JSX.Element => {
    const nonfixedColumns = columns.filter((item) => !lodash.includes(fixedColumnsNames, item.name));

    const fixedColumns = fixedColumnsNames
        .filter((item) => columns.some((column) => column.name == item))
        .map((item) => columns.find((column) => column.name == item));

    const fixedColumnsWidthSum = fixedColumns.reduce((acc, item) => acc + columnsWidth[item.name], 0);

    return (
        <div
            className={style.root}
            onClick={onLineClick}
            onMouseEnter={() => onMouseEnter(line.id)}
            onMouseLeave={onMouseLeave}
            style={{
                backgroundColor: `rgba(${lineColor})`,
            }}
            {...{
                'data-table-line-id': `prefix${line.id}`,
                'qa-id': 'tableLine',
                'qa-line-id': line.id,
                'qa-line-serial-number': (line.fields[ColumnName.Id] as NumericCellValue).number,
                'qa-line-color': LineColorName[lineColor],
                'qa-activity-name': line.fields[ColumnName.ActivityName],
            }}
        >
            <div
                className={classNames(style.cells, lineIsHovered && style.lineHover)}
                style={{
                    paddingLeft: fixedColumnsWidthSum,
                    paddingRight: LINE_MENU_WIDTH,
                }}
            >
                {renderVisibleColumns()}
            </div>

            <div className={style.fixedColumns} ref={fixedColumnsLinesRef} style={{ width }}>
                {fixedColumns && (
                    <div
                        className={style.leftFixedColumns}
                        style={{ backgroundColor: `rgb(${LineMenuColor[lineColor]})` }}
                    >
                        <div className={classNames(style.cells, lineIsHovered && style.lineHover)}>
                            {fixedColumns.map((column) => (
                                <Cell
                                    key={column.name}
                                    line={line}
                                    column={column}
                                    columnsWidth={columnsWidth}
                                    isTransitionMenuOpened={isTransitionMenuOpened}
                                    allUsers={allUsers}
                                    multiReferenceDictionaryApi={multiReferenceDictionaryApi}
                                    onCellClick={onCellClick}
                                    onCellMouseEnter={onCellMouseEnter}
                                    onCellMouseLeave={onCellMouseLeave}
                                    onInputValueChange={onInputValueChange}
                                    onDropdownCellClick={onDropdownCellClick}
                                    onDropdownItemSelection={onDropdownItemSelection}
                                    onDatepickerCellClick={onDatepickerCellClick}
                                    onDatepickerValueChange={onDatepickerValueChange}
                                    onCellCopy={onCellCopy}
                                />
                            ))}
                        </div>

                        {(lineHasPlanReserveCorrections || planIsOverrunned) && (
                            <div
                                className={classNames(
                                    style.lineIndicator,
                                    lineHasPlanReserveCorrections && style.orangeIndicator,
                                    planIsOverrunned && style.redIndicator,
                                )}
                                {...{
                                    'qa-id': 'budgetExecutionLineIndicator',
                                    'qa-color': lineHasPlanReserveCorrections ? 'orange' : 'red',
                                }}
                            />
                        )}
                    </div>
                )}

                <div
                    className={style.rightFixedColumns}
                    style={{ backgroundColor: `rgb(${LineMenuColor[lineColor]})` }}
                >
                    <div className={classNames(style.cells, lineIsHovered && style.lineHover)}>
                        <div className={style.lineMenu}>
                            <LineMenu
                                activityId={line.activityId}
                                lineId={line.id}
                                executorId={(line.fields[ColumnName.Id] as any).number}
                                backgroundColor={lineColor}
                                lineIsHovered={lineIsHovered}
                                lineIsDisabled={line.isDisabled}
                                onInfoMouseEnter={onInfoMouseEnter}
                                onInfoMouseLeave={onInfoMouseLeave}
                            />
                        </div>

                        <div className={style.indicatorsCell}>
                            <IndicatorsCell lineId={line.id} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    function renderVisibleColumns(): JSX.Element {
        let columnsVisibleContent: JSX.Element[] = [];
        let columnsLengthBeforeVisibleContent = 0;
        let currentColumnYPos = 0;
        let visibleColumnsHaveBeenReached: boolean;

        for (let i = 0; i !== nonfixedColumns.length; i++) {
            const column = nonfixedColumns[i];
            const columnWidth = columnsWidth[column.name];
            currentColumnYPos = currentColumnYPos + columnWidth;

            const shouldRenerColumn =
                currentColumnYPos >= currentYScroll && currentColumnYPos <= currentYScroll + document.body.clientWidth;
            columnsVisibleContent.push(
                shouldRenerColumn ? (
                    <Cell
                        key={column.name}
                        line={line}
                        column={column}
                        columnsWidth={columnsWidth}
                        isTransitionMenuOpened={isTransitionMenuOpened}
                        allUsers={allUsers}
                        multiReferenceDictionaryApi={multiReferenceDictionaryApi}
                        onCellClick={onCellClick}
                        onCellMouseEnter={onCellMouseEnter}
                        onCellMouseLeave={onCellMouseLeave}
                        onInputValueChange={onInputValueChange}
                        onDropdownCellClick={onDropdownCellClick}
                        onDropdownItemSelection={onDropdownItemSelection}
                        onDatepickerCellClick={onDatepickerCellClick}
                        onDatepickerValueChange={onDatepickerValueChange}
                        onCellCopy={onCellCopy}
                    />
                ) : null,
            );
            visibleColumnsHaveBeenReached = shouldRenerColumn || visibleColumnsHaveBeenReached;

            if (!visibleColumnsHaveBeenReached) {
                columnsLengthBeforeVisibleContent += columnWidth;
            }
        }

        return (
            <React.Fragment>
                <div style={{ width: `${columnsLengthBeforeVisibleContent}px` }} />
                {columnsVisibleContent}
            </React.Fragment>
        );
    }
};

export interface CellProps {
    line: TableLine;
    column: ColumnData;
    columnsWidth: ColumnsWidth;
    isTransitionMenuOpened: boolean;
    allUsers: UserResponseParams[];
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi;
    onCellClick: (lineId: string, columnName: ColumnName) => void;
    onCellMouseEnter: (lineId: string, columnName: ColumnName) => void;
    onCellMouseLeave: () => void;
    onInputValueChange: (
        lineId: string,
        columnName: ColumnName,
        value: string,
        originalValue: string,
        generateSameValueChange: boolean,
    ) => void;
    onDropdownCellClick: (
        columnName: ColumnName,
        lineId: string,
        dropdownContent: JSX.Element,
        contentHeight: number,
    ) => void;
    onDropdownItemSelection: (lineId: string, columnName: ColumnName, selectedId: string, originalId: string) => void;
    onDatepickerCellClick: (
        columnName: ColumnName,
        lineId: string,
        dropdownContent: JSX.Element,
        contentHeight: number,
    ) => void;
    onDatepickerValueChange: (lineId: string, columnName: ColumnName, value: Date, originalValue: Date) => void;
    onCellCopy: (
        lineId: string,
        columnName: ColumnName,
        cellParams: CellParams,
        event: React.ClipboardEvent<HTMLDivElement>,
    ) => void;
}

function Cell({
    line,
    column,
    columnsWidth,
    isTransitionMenuOpened,
    allUsers,
    multiReferenceDictionaryApi,
    onCellClick,
    onCellMouseEnter,
    onCellMouseLeave,
    onInputValueChange,
    onDropdownCellClick,
    onDropdownItemSelection,
    onDatepickerCellClick,
    onDatepickerValueChange,
    onCellCopy,
}: CellProps): JSX.Element {
    const cellParams = useSelector(
        (state: StoreState) =>
            getBudgetExecutionPageState(state).computedData.tableLinesCellsParams[line.id]?.[column.name],
    );
    const columnIsResizing = useSelector(
        (state: StoreState) => getBudgetExecutionPageState(state).resizingColumnName === column.name,
    );
    const columnIsHovered = useSelector(
        (state: StoreState) => getBudgetExecutionPageState(state).hoveredColumnName === column.name,
    );

    if (!cellParams) {
        return null;
    }

    return (
        <div
            className={classNames(style.cell, columnIsResizing && style.dragged, columnIsHovered && style.columnHover)}
            style={{
                width: columnsWidth[column.name],
                ...(cellParams.displayValidationError && {
                    border: `2px solid ${cellParams.cellBorder}`,
                }),
            }}
            key={column.name}
            {...{
                'qa-id': 'tableCellWrapper',
                'qa-index': column.name,
            }}
        >
            {!column.customCellType && (
                <TextCell
                    columnName={column.name}
                    title={cellParams.title}
                    tooltip={cellParams.tooltip}
                    bgColor={cellParams.bgColor || column.customColumnColor}
                    valueTypeIsCurrency={column.valueType === CellValueType.Currency}
                    disabled={cellParams.disabled || isTransitionMenuOpened}
                    onCellCopy={(event) => onCellCopy(line.id, column.name, cellParams, event)}
                />
            )}

            {column.customCellType == CustomCellType.Input && (
                <InputCell
                    lineId={line.id}
                    columnName={column.name}
                    title={cellParams.title}
                    value={cellParams.value as string}
                    originalValue={cellParams.originalValue as string}
                    valueType={column.valueType}
                    tooltip={cellParams.tooltip}
                    bgColor={cellParams.bgColor || column.customColumnColor}
                    disabled={cellParams.disabled || isTransitionMenuOpened}
                    cellInfo={cellParams.cellInfo}
                    onValueChange={onInputValueChange}
                    onCellCopy={(event) => onCellCopy(line.id, column.name, cellParams, event)}
                />
            )}

            {column.customCellType == CustomCellType.Dropdown && (
                <DropdownCell
                    lineId={line.id}
                    columnName={column.name}
                    allowMultipleItems={cellParams.allowMultipleItems}
                    customTitle={cellParams.title}
                    options={cellParams.options}
                    selectedId={cellParams.value as string}
                    originalValue={cellParams.originalValue as string}
                    bgColor={cellParams.bgColor || column.customColumnColor}
                    disabled={cellParams.disabled || isTransitionMenuOpened}
                    allUsers={allUsers}
                    multiReferenceDicitonaryApi={multiReferenceDictionaryApi}
                    onDropdownClick={onDropdownCellClick}
                    onSelection={onDropdownItemSelection}
                />
            )}

            {column.customCellType == CustomCellType.Selectable && (
                <SelectableCellRenderer
                    line={line}
                    column={column}
                    cellParams={cellParams}
                    onCellClick={onCellClick}
                    onCellMouseEnter={onCellMouseEnter}
                    onCellMouseLeave={onCellMouseLeave}
                    onCellCopy={onCellCopy}
                />
            )}

            {column.customCellType == CustomCellType.Datepicker && (
                <DatepickerCell
                    lineId={line.id}
                    columnName={column.name}
                    title={cellParams.title}
                    value={cellParams.value as Date}
                    originalValue={cellParams.originalValue as Date}
                    minValue={cellParams.minDate}
                    maxValue={cellParams.maxDate}
                    bgColor={cellParams.bgColor || column.customColumnColor}
                    disabled={cellParams.disabled || isTransitionMenuOpened}
                    tooltip={cellParams.tooltip}
                    onOpenerClick={onDatepickerCellClick}
                    onValueChange={onDatepickerValueChange}
                />
            )}
        </div>
    );
}

interface SelectableCellRendererProps {
    line: TableLine;
    column: ColumnData;
    cellParams: CellParams;
    onCellClick: (lineId: string, columnName: ColumnName) => void;
    onCellMouseEnter: (lineId: string, columnName: ColumnName) => void;
    onCellMouseLeave: () => void;
    onCellCopy: (
        lineId: string,
        columnName: ColumnName,
        cellParams: CellParams,
        event: React.ClipboardEvent<HTMLDivElement>,
    ) => void;
}

function SelectableCellRenderer({
    line,
    column,
    cellParams,
    onCellClick,
    onCellMouseEnter,
    onCellMouseLeave,
    onCellCopy,
}: SelectableCellRendererProps): JSX.Element {
    const isClickable = useSelector((state: StoreState) => {
        const {
            controls: { internalTransferDirection },
            cells: { from, to },
        } = getBudgetTransferMenuState(state);

        const isClickableAsDonor =
            from.some((fromCell) => fromCell.lineId === line.id && fromCell.columnName === column.name) ||
            (internalTransferDirection === InternalTransferDirection.OneToMany && !from.length);
        const isClickableAsAcceptor =
            to.some((toCell) => toCell.lineId === line.id && toCell.columnName === column.name) ||
            (internalTransferDirection === InternalTransferDirection.ManyToOne && !to.length);

        return !cellParams.disabled && (isClickableAsDonor || isClickableAsAcceptor);
    });

    const isTransitionMenuOpened = useSelector(
        (state: StoreState) =>
            getBudgetTransferMenuState(state).controls.componentState !== BudgetTransferMenuComponentState.Closed,
    );
    const isLineSelectingModeEnabledForTransition = useSelector(
        (state: StoreState) =>
            getBudgetTransferMenuState(state).controls.componentState ===
            BudgetTransferMenuComponentState.InternalTransferLineSelection,
    );

    return (
        <SelectableCell
            columnName={column.name}
            title={cellParams.title}
            tooltip={cellParams.tooltip}
            isClickable={cellParams.isClickable}
            isSelected={cellParams.isSelected}
            isHovered={cellParams.isHovered}
            cellColor={cellParams.cellColor}
            disabled={
                cellParams.disabled ||
                (isTransitionMenuOpened && (!cellParams.isClickable || isLineSelectingModeEnabledForTransition))
            }
            onClick={
                isClickable
                    ? (event: React.MouseEvent<HTMLDivElement>) => {
                          event.preventDefault();
                          event.stopPropagation();

                          onCellClick(line.id, column.name);
                      }
                    : null
            }
            onMouseEnter={() => onCellMouseEnter(line.id, column.name)}
            onMouseLeave={onCellMouseLeave}
            onCellCopy={(event) => onCellCopy(line.id, column.name, cellParams, event)}
        />
    );
}
