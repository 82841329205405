// import * as lodash from 'lodash';

import type {
    AutopilotRadioMediaplanKeyValue,
    AutopilotRadioMediaplanSelectedStation as SelectedStation,
} from 'sber-marketing-types/backend';

export const enum ColumnHeaderType {
    Text = 'text',
}

export const enum LineType {
    Line = 'line',
    Total = 'total',
}

export const enum ColumnName {
    StationName = 'stationName',
    Regionality = 'regionality',
    Month = 'month',
    PlacementType = 'placementType',
    PlacementDescription = 'placementDescription',
    Interval = 'interval',
    MinutesCount = 'minutesCount',
    Count = 'count',
    Price1 = 'price1',
    Discount = 'discount',
    Price2 = 'price2',
    Vat = 'vat',
    Price3 = 'price3',
}

export const enum CellType {
    Text = 'text',
    Input = 'input',
    FundsInput = 'fundsInput',
}

export interface AccessorParams {
    id: string;
    line: SelectedStation;
    totalLine: AutopilotRadioMediaplanKeyValue[];
}

export type Accessor = (params: AccessorParams) => any;
export type TitleAccessor = (params: AccessorParams) => React.ReactText;
export type ValueSetter = (params: AccessorParams, value: any) => Promise<void>;
export type ItemsAccessor = (params: AccessorParams) => any[];
export type DescriptionAccessor = (params: AccessorParams) => string;
export type CustomStyleAccessor = (params: AccessorParams) => React.CSSProperties;

export interface ColumnParams {
    title: string | [string, string];
    headerType: ColumnHeaderType;
    type: Record<LineType, CellType>;
    defaultWidth: number;
    disableWidthChange?: boolean;
    readOnly?: Partial<Record<LineType, boolean>>;
    customStyle?: Partial<Record<LineType, CustomStyleAccessor>>;
    linkedColumns?: Partial<Record<LineType, ColumnName[]>>;
    getTitle?: Partial<Record<LineType, TitleAccessor>>;
    getValue: Record<LineType, Accessor>;
    setValue?: Partial<Record<LineType, ValueSetter>>;
    getItems?: Partial<Record<LineType, ItemsAccessor>>;
    getDescription?: Partial<Record<LineType, DescriptionAccessor>>;
}

export interface ColumnsConfigParams {}

export const MakeColumnsConfig: (columnsConfigParams: ColumnsConfigParams) => { [columnName: string]: ColumnParams } = (
    columnsConfigParams: ColumnsConfigParams,
) => ({
    [ColumnName.StationName]: {
        title: 'Станция',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: CellType.Text,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => getFieldValue(params.line.estimationDetails, 'Радиостанция'),
            [LineType.Total]: (params: AccessorParams) => 'Итого',
        },
    },
    [ColumnName.Regionality]: {
        title: 'Регион',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: CellType.Text,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => getFieldValue(params.line.estimationDetails, 'Региональность'),
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Month]: {
        title: 'Месяц',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: CellType.Text,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => getFieldValue(params.line.estimationDetails, 'Месяц'),
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.PlacementType]: {
        title: 'Тип размещения',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: CellType.Text,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => getFieldValue(params.line.estimationDetails, 'Тип размещения'),
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.PlacementDescription]: {
        title: 'Описание размещения',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: CellType.Text,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) =>
                getFieldValue(params.line.estimationDetails, 'Описание размещения'),
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Interval]: {
        title: 'Хронометражи роликов, сек',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: CellType.Text,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) =>
                getFieldValue(params.line.estimationDetails, 'Хронометраж ролика, сек'),
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.MinutesCount]: {
        title: 'Количество минут',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: CellType.Text,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) =>
                Math.round(getFieldValue(params.line.estimationDetails, 'Количество минут') as number),
            [LineType.Total]: (params: AccessorParams) =>
                Math.round(getFieldValue(params.totalLine, 'Количество минут') as number),
        },
    },
    [ColumnName.Count]: {
        title: 'Количество роликов',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: CellType.Text,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) =>
                Math.round(getFieldValue(params.line.estimationDetails, 'Количество роликов') as number),
            [LineType.Total]: (params: AccessorParams) =>
                Math.round(getFieldValue(params.totalLine, 'Количество роликов') as number),
        },
    },
    [ColumnName.Price1]: {
        title: 'Стоимость по прайсу до НДС',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.FundsInput,
            [LineType.Total]: CellType.FundsInput,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) =>
                getFieldValue(params.line.estimationDetails, 'Стоимость по прайсу до НДС'),
            [LineType.Total]: (params: AccessorParams) => getFieldValue(params.totalLine, 'Стоимость по прайсу до НДС'),
        },
    },
    [ColumnName.Discount]: {
        title: 'Скидка, %',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: CellType.Text,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) =>
                roundNumber(getFieldValue(params.line.estimationDetails, 'Скидка') as number),
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Price2]: {
        title: 'Стоимость размещения до НДС',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.FundsInput,
            [LineType.Total]: CellType.FundsInput,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) =>
                getFieldValue(params.line.estimationDetails, 'Стоимость размещения до НДС'),
            [LineType.Total]: (params: AccessorParams) =>
                getFieldValue(params.totalLine, 'Стоимость размещения до НДС'),
        },
    },
    [ColumnName.Vat]: {
        title: 'НДС (20%)',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.FundsInput,
            [LineType.Total]: CellType.FundsInput,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => getFieldValue(params.line.estimationDetails, 'НДС (20%)'),
            [LineType.Total]: (params: AccessorParams) => getFieldValue(params.totalLine, 'НДС (20%)'),
        },
    },
    [ColumnName.Price3]: {
        title: 'Стоимость размещения с НДС',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.FundsInput,
            [LineType.Total]: CellType.FundsInput,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) =>
                getFieldValue(params.line.estimationDetails, 'Стоимость размещения с НДС'),
            [LineType.Total]: (params: AccessorParams) => getFieldValue(params.totalLine, 'Стоимость размещения с НДС'),
        },
    },
});

export const MakeTableColumns: (columnsConfigParams: ColumnsConfigParams) => string[] = () => [
    ColumnName.Regionality,
    ColumnName.Month,
    ColumnName.PlacementType,
    ColumnName.PlacementDescription,
    ColumnName.Interval,
    ColumnName.MinutesCount,
    ColumnName.Count,
    ColumnName.Price1,
    ColumnName.Discount,
    ColumnName.Price2,
    ColumnName.Vat,
    ColumnName.Price3,
];

export const leftFixedColumns: ColumnName[] = [ColumnName.StationName];

export const rightFixedColumns: ColumnName[] = [];

function getFieldValue(fields: AutopilotRadioMediaplanKeyValue[], fieldName: string) {
    return fields.find((item) => item.key === fieldName)?.value || null;
}

function roundNumber(value: number, digitsAfterComma = 2): string {
    if (value === undefined) {
        return null;
    }

    const formatedValue = value.toFixed(digitsAfterComma);

    const [decimalPart, fractionPart] = formatedValue.split('.');

    return `${decimalPart}${fractionPart ? `.${fractionPart}` : ''}`;
}
