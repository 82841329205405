import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as lodash from 'lodash';

import type { StoreState } from '@store';
import type { BriefStageForm } from '@store/autopilotDOOH/types';
import type { SelectItem } from 'sber-marketing-ui';
import { SelectionType } from './Checkbox';

import { setBriefFormValues } from '@store/autopilotDOOH/actions';
import { getBriefStageForm, getFormats, getMediaplanItemsIdsByFormat } from '@store/autopilotDOOH/selectors';
import { Saver } from '../../../../modules';

export const useTableSettingsHooks = () => {
    const briefForm = useSelector((state: StoreState) => getBriefStageForm(state));
    const formats = useSelector((state: StoreState) => getFormats(state));
    const mediaplanItemsIdsByFormat = useSelector((state: StoreState) => getMediaplanItemsIdsByFormat(state));

    const { selectedFormatTimings } = briefForm;

    const briefFormRef = React.useRef<BriefStageForm>(null);

    React.useEffect(() => {
        briefFormRef.current = briefForm;
    }, [briefForm]);

    const dispatch = useDispatch();

    const selectionByFormat = {
        MF: getSelectionType(briefForm.selectedRowsIds, mediaplanItemsIdsByFormat['MF']),
        'DBB и DSS': getSelectionType(briefForm.selectedRowsIds, mediaplanItemsIdsByFormat['DBB и DSS']),
        DCF: getSelectionType(briefForm.selectedRowsIds, mediaplanItemsIdsByFormat['DCF']),
        static: getSelectionType(briefForm.selectedRowsIds, mediaplanItemsIdsByFormat['static']),
    };

    const timingsSelectItemsByFormat = ['MF', 'DBB и DSS', 'DCF'].reduce((acc, format) => {
        const timings = formats.find((item) => item.abbreviation === format)?.timings || [];

        acc[format] = timings.map((item) => ({
            label: `${item} сек`,
            value: item,
        }));

        return acc;
    }, {} as Record<string, SelectItem[]>);

    function getSelectionType(selectedIds: string[], ids: string[]): SelectionType {
        let selectionType: SelectionType;

        const allIdsAreSelected = ids.every((lineId) => selectedIds.includes(lineId));
        const someIdsAreSelected = ids.some((lineId) => selectedIds.includes(lineId));

        if (allIdsAreSelected) {
            selectionType = SelectionType.Checked;
        } else if (someIdsAreSelected) {
            selectionType = SelectionType.Minus;
        } else {
            selectionType = SelectionType.Unchecked;
        }

        return selectionType;
    }

    function onBudgetAutomationCheckboxClick() {
        const updatedBudgetAutomation = !briefFormRef.current.budgetAutomation;

        dispatch(setBriefFormValues({ budgetAutomation: updatedBudgetAutomation }));

        Saver.getInstance().saveBriefForm();
    }

    function onFormatCheckboxClick(newSelection: SelectionType, format: string) {
        let updatedSelectedRowsIds = lodash.cloneDeep(briefFormRef.current.selectedRowsIds);

        const lineIds = mediaplanItemsIdsByFormat[format];

        if (newSelection === SelectionType.Checked) {
            updatedSelectedRowsIds.push(...lineIds);
            updatedSelectedRowsIds = lodash.uniq(updatedSelectedRowsIds);
        } else {
            updatedSelectedRowsIds = lodash.without(updatedSelectedRowsIds, ...lineIds);
        }

        dispatch(setBriefFormValues({ selectedRowsIds: updatedSelectedRowsIds }));

        Saver.getInstance().saveBriefForm();
    }

    function onSelectChange(value: number, format: string) {
        const updatedSelectedFormatTimings = lodash.cloneDeep(briefFormRef.current.selectedFormatTimings);

        updatedSelectedFormatTimings[format] = value;

        dispatch(setBriefFormValues({ selectedFormatTimings: updatedSelectedFormatTimings }));

        Saver.getInstance().saveBriefForm();
    }

    return {
        budgetAutomationEnabled: briefForm.budgetAutomation,
        selectionByFormat,
        timingsSelectItemsByFormat,
        selectedFormatTimings,
        onBudgetAutomationCheckboxClick,
        onFormatCheckboxClick,
        onSelectChange,
    };
};
