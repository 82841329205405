import { PureComponent } from 'react';

import { PropsWithAppUsers } from './types';
import { LoadingStatus } from '../../../store/commonTypes';

export class AppUsersWrapper<P extends {} = {}> extends PureComponent<PropsWithAppUsers<P>> {
    public componentDidMount() {
        const { appUsersLoadingStatus, loadAppUsers } = this.props;
        if (appUsersLoadingStatus === LoadingStatus.NOT_LOADED) {
            loadAppUsers();
        }
    }
}
