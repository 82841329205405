import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CloseButton } from 'sber-marketing-ui';

import { StoreState } from '@store';
import {
    ComponentState,
    getTransferBudgetItemsToPlanningMenuState,
    loadComponentData,
    resetComponentState,
} from '@store/budgetExecution/transferBudgetItemsToPlanningMenu';

import { BudgetSelection } from './BudgetSelection';
import { LinesDescriptions } from './LinesDescriptions';
import { InitButton } from './InitButton';

import * as styles from './TransferBudgetItemsToPlanningMenu.scss';

function useStore() {
    const dispatch = useDispatch();

    React.useEffect(() => dispatch(loadComponentData(null)), []);

    const componentState = useSelector(
        (state: StoreState) => getTransferBudgetItemsToPlanningMenuState(state).props.componentState,
    );

    function closeMenu() {
        dispatch(resetComponentState());
    }

    return {
        componentState,
        closeMenu,
    };
}

export function TransferBudgetItemsToPlanningMenu(): JSX.Element {
    const { componentState, closeMenu } = useStore();

    if (componentState === ComponentState.Closed) {
        return null;
    }

    return (
        <div className={styles.root}>
            <div className={styles.title}>
                Создать строки Планирования на основании Исполнения
                <CloseButton onClick={closeMenu} />
            </div>

            <BudgetSelection />
            <LinesDescriptions />
            <InitButton />
        </div>
    );
}
