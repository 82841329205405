import * as React from 'react';
import classNames from 'classnames';

import { SkeletonBlock, SkeletonText, Tag, TagProps } from '@common/components';

import * as styles from './FileTag.scss';

export interface FileTagLoaderProps extends TagProps {}

export function FileTagLoader({ className, ...props }: FileTagLoaderProps) {
    return (
        <Tag even relative {...props} className={classNames(styles.root, className)}>
            <SkeletonBlock width={48} height={48} />
            <div className={styles.content}>
                <div className={styles.title}>
                    <SkeletonText width={40} /> <SkeletonText />
                </div>
                <div className={styles.subtitle}>
                    <SkeletonText width={58} />
                    {' • '}
                    <SkeletonText width={60} />
                </div>
            </div>
        </Tag>
    );
}
