import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { Activity } from '@mrm/activity';
import type { Period, Filters, ActivityLineParams } from '@store/calendar/types';
import { LineType, Stage } from '@store/calendar/types';

import { ActivitiesInfo } from './ActivitiesInfo';
import type { StoreState } from '@store';
import { setFilters } from '@store/calendar/actions';
import {
    getCalendarPageState,
    getPageData,
    getFilters,
    getExpiredActivityStages,
    getFilteredActivities,
} from '@store/calendar/selectors';
import { GroupsMaker, Scroller, Scaler } from '../modules';

const UPDATE_TIMEOUT = 200;

interface Props extends Partial<MapProps>, Partial<DispatchProps>, Partial<RouteComponentProps> {}

interface MapProps {
    period: Period;
    filters: Filters;
    activities: Activity[];
    filteredActivities: Activity[];
    expiredActivityStages: Stage[];
}

interface DispatchProps {
    setFilters: (filters: Filters) => void;
}

interface State {
    activitiesCount: number;
    expiredStagesActivitiesCount: number;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class ActivitiesInfoContainer extends React.PureComponent<Props, State> {
    private groupsMaker: GroupsMaker;
    private scroller: Scroller;
    private scaler: Scaler;
    private updateTimer: NodeJS.Timeout;

    constructor(props: Props) {
        super(props);

        this.state = {
            activitiesCount: null,
            expiredStagesActivitiesCount: null,
        };

        this.groupsMaker = GroupsMaker.getInstance();
        this.scroller = Scroller.getInstance();
        this.scaler = Scaler.getInstance();

        this.scroller.register('activitiesInfo', this.onScrollerEmit);
    }

    public render(): JSX.Element {
        const { expiredStagesFilter } = this.props.filters;

        return React.createElement(ActivitiesInfo, {
            totalActivities: this.state.activitiesCount,
            expiredStagesActivities: this.state.expiredStagesActivitiesCount,
            expiredStagesFilter,
            onExpiredStagesClick: this.onExpiredStagesClick,
        });
    }

    public componentDidUpdate(prevProps: Props) {
        const periodChanged = this.props.period !== prevProps.period;
        const filtersChanged = this.props.filters !== prevProps.filters;

        if (periodChanged || filtersChanged) {
            this.setUpdateTimer();
        }
    }

    @autobind
    protected onExpiredStagesClick() {
        this.props.setFilters({
            expiredStagesFilter: !this.props.filters.expiredStagesFilter,
        });
    }

    @autobind
    private onScrollerEmit() {
        this.setUpdateTimer();
    }

    private updateFilteredLinesCount() {
        const activityLines = this.getActivityLines();

        let filteresActivityLines = this.filterLinesByCalenderFilters(activityLines);

        filteresActivityLines = this.filterLinesByDates(filteresActivityLines);

        const activitiesWithExpiredActivityStages = this.filterLinesByExpiredStages(filteresActivityLines);

        if (this.state.activitiesCount !== filteresActivityLines.length) {
            this.setState({
                activitiesCount: filteresActivityLines.length,
            });
        }

        if (this.state.expiredStagesActivitiesCount !== activitiesWithExpiredActivityStages.length) {
            this.setState({
                expiredStagesActivitiesCount: activitiesWithExpiredActivityStages.length,
            });
        }
    }

    private getActivityLines(): ActivityLineParams[] {
        const { organizationIds } = this.props.filters;

        const allGroups = this.groupsMaker.getGroups();

        const filteredOrganizationGroups = lodash.isEmpty(organizationIds)
            ? allGroups
            : allGroups.filter((item) => lodash.includes(organizationIds, item.id));

        const lineGroups = lodash.flatMap(filteredOrganizationGroups, (item) => item.groups);
        const lines = lodash.flatMap(lineGroups, (item) => item.lines);

        return lines.filter((item) => item.type == LineType.Activity) as ActivityLineParams[];
    }

    private filterLinesByCalenderFilters(lines: ActivityLineParams[]): ActivityLineParams[] {
        const { filteredActivities } = this.props;

        return lines.filter((line) => filteredActivities.some((item) => item.id === line.id));
    }

    private filterLinesByDates(lines: ActivityLineParams[]): ActivityLineParams[] {
        const { start: viewportStart, end: viewportEnd } = this.scaler.getViewportPositionInDays();

        return lines.filter((line) => {
            const { preparationStart, realizationStart, realizationEnd, debriefingEnd } = line;

            const activityStart = preparationStart || realizationStart;
            const activityEnd = debriefingEnd || realizationEnd;

            return activityStart < viewportEnd && activityEnd > Math.ceil(viewportStart);
        });
    }

    private filterLinesByExpiredStages(lines: ActivityLineParams[]): ActivityLineParams[] {
        const { activities, expiredActivityStages } = this.props;

        return lines.filter((line) => {
            const activity = activities.find((item) => item.id == line.id);

            return expiredActivityStages.some((item) => item.activityId == activity.id);
        });
    }

    private setUpdateTimer() {
        if (!this.updateTimer) {
            this.updateTimer = setTimeout(() => {
                this.updateTimer = null;
                this.updateFilteredLinesCount();
            }, UPDATE_TIMEOUT);
        }
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { period } = getCalendarPageState(state);
    const { activities } = getPageData(state);
    const filters = getFilters(state);

    return {
        period,
        activities,
        filteredActivities: getFilteredActivities(state),
        expiredActivityStages: getExpiredActivityStages(state),
        filters,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return bindActionCreators(
        {
            setFilters,
        },
        dispatch,
    );
}
