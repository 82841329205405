export interface Point {
    x: number;
    y: number;
}

export interface Size {
    width: number;
    height: number;
}

export interface CellPosition {
    columnName: ColumnName;
    lineId: LineId;
}

export interface ColumnHeaderParams {
    title: string;
}

export interface CellParams {
    component: React.Component;
    cellProps: any;
    onPropsChange?: (cellProps: any) => void;
}

export type ColumnName = string;
export type LineId = string;

export type ColumnWidths = Record<ColumnName, number>;
export type LineHeights = Record<LineId, number>;

export type ColumnHeadersStorage = Record<ColumnName, ColumnHeaderParams>;
export type CellsStorage = Record<LineId, Record<ColumnName, CellParams>>;

export interface TableView {
    cursorPosition: CellPosition;
    subscribeToTableEvent: (handler: (eventType: TableEvent) => void) => void;
    subscribeToCellEvent: (handler: (eventType: CellEvent, position: CellPosition) => void) => void;
}

export enum TableEvent {
    Scroll = 'scroll',
    SizeChange = 'size_change',
}

export const enum CellEvent {
    Click = 'click',
    MouseEnter = 'mouse_enter',
    MouseLeave = 'mouse_leave',
    Selection = 'selection',
    EditStart = 'edit_start',
    EditEnd = 'edit_end',
}
