import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { UserConfigType } from 'sber-marketing-types/openid';

import { StoreState } from '@store';
import { updateUserConfig } from '@store/userConfig';

import { CreativeUserConfig, LoadCreativeUserConfig, UpdateCreativeUserConfig } from './types';
import {
    loadCreativeUserConfig as loadCreativeUserConfigAction,
    updateCreativeUserConfig as updateCreativeUserConfigAction,
} from './actions';

export const loadCreativeUserConfig = bindThunkAction<StoreState, CreativeUserConfig, LoadCreativeUserConfig, Error>(
    loadCreativeUserConfigAction,
    async (receivedUserConfig) => {
        return {
            receivedUserConfig,
        };
    },
);

export const updateCreativeUserConfig = bindThunkAction<StoreState, UpdateCreativeUserConfig, void, Error>(
    updateCreativeUserConfigAction,
    async (_, dispatch) => {
        dispatch(updateUserConfig(UserConfigType.Creative));
    },
);
