import * as React from 'react';

import { FlagToggle } from '@common/FlagToggle';

import { CardTitle } from '@common/Card';
import * as style from './Title.scss';

export interface Props {
    activityName: string;
    isKey: boolean;
    canSwitchKey: boolean;
    onSwitchKeyButtonClick: () => void;
}

export const Title: React.FC<Props> = ({ activityName, isKey, canSwitchKey, onSwitchKeyButtonClick }) => {
    return (
        <CardTitle tooltip={activityName}>
            <div className={style.flagTogglePosition}>
                <FlagToggle
                    tooltip={
                        canSwitchKey
                            ? isKey
                                ? 'Сделать проект стандартным'
                                : 'Сделать проект ключевым'
                            : 'Ключевой проект'
                    }
                    selected={isKey}
                    canChange={canSwitchKey}
                    onClick={onSwitchKeyButtonClick}
                />
            </div>
            <div className={style.text}>{activityName}</div>
        </CardTitle>
    );
};
