import * as React from 'react';
import autobind from 'autobind-decorator';
import { MutateProps, graphql } from 'react-apollo';
import { ActivityFileGroup } from 'sber-marketing-types/frontend';

import { ATTACH_FILE_MUTATION } from './query';

export interface ChildrenProps {
    attachFile: (activityId: number, fileId: string, fileCategory: ActivityFileGroup) => Promise<void>;
}

interface Props extends ExternalProps, QueryProps {}

interface ExternalProps {
    children: (props: ChildrenProps) => JSX.Element;
}

type QueryProps = MutateProps<
    {},
    {
        projectId: number;
        fileId: string;
        category: string;
    }
>;

class FileGroupConnectedWrapper extends React.PureComponent<Props> {
    public render(): JSX.Element {
        return this.props.children({
            attachFile: this.attachFile,
        });
    }

    @autobind
    private async attachFile(projectId: number, fileId: string, category: string): Promise<void> {
        await this.props.mutate({
            variables: {
                projectId,
                fileId,
                category,
            },
        });
    }
}

export const FileGroupConnected = graphql<ExternalProps>(ATTACH_FILE_MUTATION)(FileGroupConnectedWrapper);
