import * as React from 'react';
import classNames from 'classnames';

import { LoadingStatus } from '@store/commonTypes';

import { Utils } from '@common/Utils';

import { Participant } from './Participant';
import { EditParticipantsForm } from './EditParticipantsForm';
import { Tooltip } from './Tooltip';

import * as styles from './TaskParticipants.scss';

export const MAX_PARTICIPANTS_FOR_REDUCED_LIST = 5;
const ParticipantsForms = ['участник', 'участника', 'участников'];

export interface Props {
    participants: {
        userId: number;
        canRemove: boolean;
    }[];
    appUsersLoadingStatus: LoadingStatus;
    isListExpanded: boolean;
    isEditorVisible: boolean;
    isAddButtonDisabled: boolean;
    onParticipantClick?(userId: number): void;
    onParticipantsChange(participants: { userId: number; canRemove: boolean }[]): void;
    onMaskClick(): void;
    onAddButtonClick(): void;
    onExpandParticipatorsListButtonClick(): void;
    onReduceParticipatorsListButtonClick(): void;
    onCloseEditParticipantsForm(): void;
    removeParticipant(participantId: number): void;
}

export function TaskParticipants({
    participants,
    appUsersLoadingStatus,
    isListExpanded,
    isEditorVisible,
    isAddButtonDisabled,
    onParticipantsChange,
    onAddButtonClick,
    onMaskClick,
    onExpandParticipatorsListButtonClick,
    onReduceParticipatorsListButtonClick,
    onCloseEditParticipantsForm,
    removeParticipant,
    onParticipantClick,
}: Props): JSX.Element {
    const count = participants.length;
    const needExpand = !isListExpanded && participants.length > MAX_PARTICIPANTS_FOR_REDUCED_LIST;
    const participantsToShow = needExpand ? participants.slice(0, MAX_PARTICIPANTS_FOR_REDUCED_LIST) : participants;
    const moreCount = participants.length - MAX_PARTICIPANTS_FOR_REDUCED_LIST;

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'taskParticipants',
            }}
        >
            <div className={styles.buttons}>
                <div
                    className={classNames(
                        styles.button,
                        styles.button_participators_count,
                        !isEditorVisible && styles.button_active,
                    )}
                >
                    {`${count} ${Utils.getDeclensionByNumber(count, ParticipantsForms)}`}
                </div>

                <div className={styles.changeParticipantsButton}>
                    <div
                        className={classNames(
                            styles.button,
                            isEditorVisible && styles.button_active,
                            isAddButtonDisabled && styles.button_disabled,
                        )}
                        onClick={isAddButtonDisabled ? null : onAddButtonClick}
                        {...{
                            'qa-id': 'taskParticipantsMenuOpenButton',
                        }}
                    >
                        Изменить
                    </div>

                    {isAddButtonDisabled && (
                        <div className={styles.tooltip}>
                            <Tooltip />
                        </div>
                    )}
                </div>
            </div>

            <div className={styles.listContainer}>
                {appUsersLoadingStatus === LoadingStatus.LOADED &&
                    participantsToShow.map((participant) => (
                        <Participant
                            key={participant.userId}
                            id={participant.userId}
                            canRemove={participant.canRemove}
                            onParticipantClick={onParticipantClick}
                            removeParticipant={removeParticipant}
                        />
                    ))}

                {needExpand && (
                    <div
                        className={styles.loadMoreButton}
                        onClick={onExpandParticipatorsListButtonClick}
                        {...{
                            'qa-id': 'taskParticipantsExpandListButton',
                        }}
                    >
                        Еще {moreCount} {Utils.getDeclensionByNumber(moreCount, ParticipantsForms)}
                    </div>
                )}

                {isListExpanded && (
                    <div
                        className={styles.loadMoreButton}
                        onClick={onReduceParticipatorsListButtonClick}
                        {...{
                            'qa-id': 'taskParticipantsReduceListButton',
                        }}
                    >
                        Свернуть
                    </div>
                )}
            </div>

            {isEditorVisible && (
                <React.Fragment>
                    <div className={styles.mask} onClick={onMaskClick} />
                    <EditParticipantsForm
                        participants={participants}
                        onCloseForm={onCloseEditParticipantsForm}
                        onParticipantsChange={onParticipantsChange}
                    />
                </React.Fragment>
            )}
        </div>
    );
}
