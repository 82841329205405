import * as React from 'react';
import autobind from 'autobind-decorator';

import { FlagToggle, Mode, EventClick } from './FlagToggle';

export interface Props {
    qaId?: string;
    selected: boolean;
    canChange: boolean;
    tooltip?: string;
    size?: number;
    hover?: boolean;
    onClick?: () => void;
}

interface State {
    isKey: boolean;
}

export class FlagToggleContainer extends React.PureComponent<Props, State> {
    public render() {
        return React.createElement(FlagToggle, {
            qaId: this.props.qaId,
            selected: this.props.selected,
            mode: this.defineMode(),
            tooltip: this.props.tooltip,
            size: this.props.size,
            hover: this.props.hover,
            onClick: this.onClick,
        });
    }

    @autobind
    private onClick(event: EventClick): void {
        event.preventDefault();

        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    private defineMode(): Mode {
        return this.props.canChange ? Mode.CHANGE : Mode.VIEW;
    }
}
