import type { TabBarConfig } from './types';
import { Color } from './types';

export const tabBarTaskConfig: TabBarConfig = [
    {
        name: 'Закрытые',
        color: Color.BLUE,
    },
    {
        name: 'Активные',
        color: Color.GREEN,
    },
    {
        name: 'Скоро дедлайн',
        color: Color.MUSTARD,
    },
    {
        name: 'Просроченные',
        color: Color.RED,
    },
];
