import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { MultiReferenceDictionaryApi } from '@api';

import { FormData } from '@store/executionBudgetEdit/types';

import { BudgetList } from './BudgetList';
import { StoreState } from '@store';
import { UserResponseParams } from 'sber-marketing-types/backend';

interface Props extends Partial<MapProps>, Partial<RouteComponentProps<RouteParams>> {
    budgetId: string;
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi;
}

interface MapProps {
    users: UserResponseParams[];
    savedBudgetItemsIds: string[];
    budgetItemForms: FormData[];
}

interface RouteParams {
    budgetId: string;
    action: string;
}

@(withRouter as any)
@(connect(mapStateToProps, null) as any)
export class BudgetListContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { budgetId, savedBudgetItemsIds, budgetItemForms, multiReferenceDictionaryApi } = this.props;

        return React.createElement(BudgetList, {
            budgetId,
            savedBudgetItemsIds,
            forms: budgetItemForms,
            multiReferenceDictionaryApi,
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { budgetItems, budgetItemForms, users } = state.executionBudgetEditPage;

    return {
        savedBudgetItemsIds: budgetItems.map((budgetItem) => budgetItem.id),
        users,
        budgetItemForms,
    };
}
