import * as React from 'react';
import classnames from 'classnames';
import { Skeleton } from 'sber-marketing-ui';

import { BriefApi } from '@api';
import type { BriefCost } from 'sber-marketing-types/frontend';

import { Money, MoneyFormatter, Captions } from '@common/Utils';

import * as styles from './BriefSumDic.scss';

interface Props {
    briefId: string;
    briefCost?: BriefCost;
    setBriefCost?: (briefCost: BriefCost) => void;
    onStartFetch?: () => Promise<void>;
    onFinishFetch?: () => Promise<void>;
}

enum ComponentState {
    Initial = 'Initial',
    RequestInProgress = 'RequestInProgress',
    RequestError = 'RequestError',
    RequestSuccessful = 'RequestSuccessfull',
}

export function BriefSumDic({ briefId, briefCost, setBriefCost, onStartFetch, onFinishFetch }: Props): JSX.Element {
    const [componentState, setComponentState] = React.useState(ComponentState.Initial);
    const isRequestInProgress = componentState === ComponentState.RequestInProgress;

    const onButtonClick = React.useCallback(async () => {
        try {
            setComponentState(ComponentState.RequestInProgress);
            await onStartFetch();
            const briefCostResponse = await BriefApi.calculateBriefCost(briefId);
            setBriefCost(briefCostResponse);
            setComponentState(ComponentState.RequestSuccessful);
            await onFinishFetch();
        } catch (e) {
            setComponentState(ComponentState.RequestError);
            await onFinishFetch();
        }
    }, [briefId, setComponentState]);

    let content: JSX.Element;

    switch (componentState) {
        case ComponentState.RequestError:
            content = <span className={styles.error}>Произошла ошибка, попробуйте еще раз</span>;
            break;
        case ComponentState.Initial:
        case ComponentState.RequestSuccessful:
            content = (
                <span className={styles.title}>
                    <span className={styles.titleCaption}>{Captions.Roubles}</span>
                    Рекомендуемая стоимость мероприятия
                    {componentState !== ComponentState.Initial && (
                        <React.Fragment>
                            :&nbsp;
                            <span className={styles.titleSum}>
                                {briefCost ? MoneyFormatter.toRoubles(Money.fromRoubles(briefCost.cost)) : ''}
                            </span>
                        </React.Fragment>
                    )}
                </span>
            );
            break;
        case ComponentState.RequestInProgress:
        default:
            content = null;
            break;
    }

    return (
        <div className={classnames(styles.root, componentState === ComponentState.RequestError && styles.rootError)}>
            <Skeleton
                isReady={!isRequestInProgress}
                firstColor="#B8E6C6"
                secondColor="#DEF9E6"
                loadingClassName={styles.preloader}
            >
                {content}
            </Skeleton>

            <div
                className={classnames(styles.button, !isRequestInProgress && styles.buttonClickable)}
                onClick={!isRequestInProgress ? onButtonClick : null}
            >
                Рассчитать
            </div>
        </div>
    );
}
