import * as React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { orderBy, values, difference } from 'lodash';
import {
    Icon,
    IconType,
    WithTooltip,
    TooltipAnchor,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
} from 'sber-marketing-ui';

import { CreativeRequest, CreativeRequestSubject } from '@api';

import { StoreState } from '@store';
import { getUserById } from '@store/appUsers';

import { UsersDropdown } from '@common/UsersDropdown';

import {
    useInitiators,
    useAgencyExecutors,
    useExperts,
    useActClosers,
    useParticipants,
    useUpdateParticipants,
} from './hooks';

import { WithScrollbar } from '../WithScrollbar';

import * as styles from './Users.scss';
import * as commonStyles from '../../CommonStyles.scss';

enum UserRoles {
    Initiator = 'Инициатор',
    AgencyExecutor = 'Исполнитель ЗНК',
    Expert = 'Согласующий ЗНК',
    ActCloser = 'Закрывающий акт ЗНК',
    Participant = 'Участник',
}

export interface UsersCache {
    [userId: number]: {
        user: CreativeRequestSubject;
        roles: UserRoles[];
    };
}

function processUsers(cache: UsersCache, users: CreativeRequestSubject[], role: UserRoles) {
    users?.forEach((user) => {
        if (user?.id) {
            if (!cache[user.id]) {
                cache[user.id] = {
                    user,
                    roles: [role],
                };
            } else if (!cache[user.id].roles.includes(role)) {
                cache[user.id].roles.push(role);
            }
        }
    });
}

export function useUsers({ creativeRequest }: Props) {
    const initiators = useInitiators(creativeRequest);
    const agencyExecutors = useAgencyExecutors(creativeRequest);
    const experts = useExperts(creativeRequest);
    const actClosers = useActClosers(creativeRequest);
    const participants = useParticipants(creativeRequest);

    const usersCache: UsersCache = {};
    processUsers(usersCache, initiators, UserRoles.Initiator);
    processUsers(usersCache, agencyExecutors, UserRoles.AgencyExecutor);
    processUsers(usersCache, experts, UserRoles.Expert);
    processUsers(usersCache, actClosers, UserRoles.ActCloser);
    processUsers(usersCache, participants, UserRoles.Participant);

    const { updateParticipants } = useUpdateParticipants(creativeRequest);

    const [isParticipantsDropdownVisible, setIsParticipantsDropdownVisible] = React.useState(false);
    const [isDeleteParticipantsMode, isDeleteParticipantsModeSetter] = React.useState(false);
    const [participantsForDeletion, setParticipantsForDeletion] = React.useState<number[]>([]);

    function setIsDeleteParticipantsMode(isDeleteParticipantsMode: boolean) {
        isDeleteParticipantsModeSetter(isDeleteParticipantsMode);

        if (!isDeleteParticipantsMode) {
            setParticipantsForDeletion([]);
        }
    }

    function initParticipantsDeletionButtonClick() {
        updateParticipants(
            difference(
                participants.map((participant) => participant.id),
                participantsForDeletion,
            ),
        );
        setIsDeleteParticipantsMode(false);
    }

    return {
        usersCache,
        participants,
        isParticipantsDropdownVisible,
        setIsParticipantsDropdownVisible,
        isDeleteParticipantsMode,
        setIsDeleteParticipantsMode,
        participantsForDeletion,
        setParticipantsForDeletion,
        updateParticipants,
        initParticipantsDeletionButtonClick,
    };
}

interface Props {
    creativeRequest: CreativeRequest;
}

export function Users(props: Props): JSX.Element {
    const {
        usersCache,
        participants,
        isParticipantsDropdownVisible,
        setIsParticipantsDropdownVisible,
        isDeleteParticipantsMode,
        setIsDeleteParticipantsMode,
        participantsForDeletion,
        setParticipantsForDeletion,
        updateParticipants,
        initParticipantsDeletionButtonClick,
    } = useUsers(props);

    const totalCount = Object.keys(usersCache).length;

    return (
        <WithScrollbar>
            <div className={commonStyles.withScrollbarContent}>
                <div className={classnames(styles.titleRow, commonStyles.sidePaddings)}>
                    {isDeleteParticipantsMode ? (
                        <React.Fragment>
                            <div className={styles.title}>Выберите участников</div>

                            <div
                                className={styles.removeParticipantsButton}
                                onClick={() => setIsDeleteParticipantsMode(!isDeleteParticipantsMode)}
                            >
                                Отменить
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className={styles.title}>
                                Участники
                                {!!totalCount && <div className={styles.titleCounter}>{totalCount}</div>}
                                <div className={styles.addUserButton}>
                                    <WithTooltip content="Изменить участников" anchor={TooltipAnchor.LEFT}>
                                        <Icon
                                            type={IconType.PENCIL}
                                            svgSize={16}
                                            onClick={() => setIsParticipantsDropdownVisible(true)}
                                        />
                                    </WithTooltip>
                                </div>
                            </div>

                            <div
                                className={styles.removeParticipantsButton}
                                onClick={() => setIsDeleteParticipantsMode(!isDeleteParticipantsMode)}
                            >
                                Удалить участников
                            </div>
                        </React.Fragment>
                    )}
                </div>

                {orderBy(values(usersCache), (user) => `${user.user.firstName} ${user.user.secondName}`).map((user) => (
                    <User
                        key={user.user.id}
                        user={user.user}
                        roles={user.roles}
                        isDeleteParticipantsMode={isDeleteParticipantsMode}
                        participantsForDeletion={participantsForDeletion}
                        setParticipantsForDeletion={setParticipantsForDeletion}
                    />
                ))}

                <InitParticipantsDeletionButton
                    participantsForDeletion={participantsForDeletion}
                    onClick={initParticipantsDeletionButtonClick}
                />

                {isParticipantsDropdownVisible && (
                    <UsersDropdown
                        selectedUsers={participants}
                        updateSelectedUsers={updateParticipants}
                        onClose={() => setIsParticipantsDropdownVisible(false)}
                    />
                )}
            </div>
        </WithScrollbar>
    );
}

interface UserProps {
    user: CreativeRequestSubject;
    roles: UserRoles[];
    isDeleteParticipantsMode: boolean;
    participantsForDeletion: number[];
    setParticipantsForDeletion: (participantsForDeletion: number[]) => void;
}

function User({
    user,
    roles,
    isDeleteParticipantsMode,
    participantsForDeletion,
    setParticipantsForDeletion,
}: UserProps): JSX.Element {
    const department = useSelector((state: StoreState) => getUserById(state, user.id)?.department);

    const sliceUserRoles = roles.length > 1;
    const participantIsSelectedForDeletion = isDeleteParticipantsMode && participantsForDeletion.includes(user.id);
    const canDeleteUser = isDeleteParticipantsMode && roles.includes(UserRoles.Participant);

    function onDeleteParticipantRadiobuttonClick() {
        if (isDeleteParticipantsMode && canDeleteUser) {
            setParticipantsForDeletion(
                participantIsSelectedForDeletion
                    ? participantsForDeletion.filter((participantId) => participantId !== user.id)
                    : [...participantsForDeletion, user.id],
            );
        }
    }

    return (
        <div className={classnames(styles.user, commonStyles.sidePaddings)}>
            {isDeleteParticipantsMode && (
                <WithTooltip hidden={canDeleteUser} content="Пользователь не является участником">
                    <div
                        className={classnames(
                            isDeleteParticipantsMode && canDeleteUser
                                ? styles.deleteUserRadioButton
                                : styles.deleteUserRadioButtonDisabled,
                            participantIsSelectedForDeletion && styles.deleteUserRadioButtonActive,
                        )}
                        onClick={onDeleteParticipantRadiobuttonClick}
                    />
                </WithTooltip>
            )}

            <div
                className={classnames(
                    styles.userNameWrapper,
                    isDeleteParticipantsMode && styles.userNameWrapeerShrinked,
                )}
            >
                <div>
                    <div className={styles.userName}>
                        {user.firstName} {user.secondName}
                    </div>

                    <div className={styles.userTextGray}>{department}</div>
                </div>

                <div className={styles.userRole}>
                    <WithTooltip hidden={!sliceUserRoles} anchor={TooltipAnchor.LEFT} content={roles.join(', ')}>
                        <div>
                            {roles[0]}

                            {sliceUserRoles && (
                                <span className={styles.userRoleShowMore}>&nbsp; +{roles.length - 1}</span>
                            )}
                        </div>
                    </WithTooltip>
                </div>
            </div>
        </div>
    );
}

interface InitParticipantsDeletionButtonProps {
    participantsForDeletion: number[];
    onClick: () => void;
}

function InitParticipantsDeletionButton({
    participantsForDeletion,
    onClick,
}: InitParticipantsDeletionButtonProps): JSX.Element {
    const shouldRender = !!participantsForDeletion.length;

    return shouldRender ? (
        <div className={classnames(styles.initParticipantsDeletionButtonWrapper, commonStyles.sidePaddings)}>
            <Button theme={ButtonTheme.GhostRoundedRed} onClick={onClick}>
                <div className={styles.initParticipantsDeletionButton}>Удалить ({participantsForDeletion.length})</div>
            </Button>
        </div>
    ) : null;
}
