import * as React from 'react';

interface OverflowCheckResults {
    top: boolean;
    right: boolean;
    bottom: boolean;
    left: boolean;
}

function makeDefaultResults(): OverflowCheckResults {
    return {
        top: false,
        right: false,
        bottom: false,
        left: false,
    };
}

export function useElementScreenOverflowCheck<E extends HTMLElement>(ref: React.RefObject<E>) {
    const [result, setResult] = React.useState<OverflowCheckResults>(makeDefaultResults());

    React.useEffect(
        function onRefUpdated() {
            let top = false;
            let right = false;
            let bottom = false;
            let left = false;

            console.log(ref.current);

            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();

                top = rect.top < 0;
                right = rect.right > window.innerWidth;
                bottom = rect.bottom > window.innerHeight;
                left = rect.left < 0;
            }

            setResult({ top, right, bottom, left });
        },
        [ref.current],
    );

    return result;
}
