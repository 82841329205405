import { actionCreatorFactory } from 'typescript-fsa';

import { PageOptions, NotificationMessage, SetFileUploadStatusPayload } from './types';

const actionCreator = actionCreatorFactory('COMMON');

const setRequestInProgress = actionCreator<boolean>('SET_REQUEST_IN_PROGRESS');
const updateError = actionCreator<string>('UPDATE_ERROR');
const updateErrorMessage = actionCreator<string>('UPDATE_ERROR_MESSAGE');
const resetErrorState = actionCreator('RESET_ERROR_STATE');
const updateCurrentPath = actionCreator<string>('UPDATE_PATH');
const updatePageOptions = actionCreator<PageOptions>('UPDATE_PREVIOUS_URL');
const setHeaderHeight = actionCreator<number>('SET_HEADER_HEIGHT');
const resetStore = actionCreator('RESET_STORE');
const updateDisableRequestErrors = actionCreator<boolean>('UPDATE_DISABLE_REQUEST_ERROR');
const setNotification = actionCreator<NotificationMessage>('SET_REQUEST_NOTIFICATION');
const resetNotification = actionCreator<string>('RESET_REQUEST_NOTIFICATION');
const clearAllNotifications = actionCreator('CLEAR_ALL_NOTIFICATIONS');
const setFileUploadStatus = actionCreator<SetFileUploadStatusPayload>('SET_FILE_UPLOAD_STATUS');
const resetFilesUploadStatus = actionCreator('RESET_FILES_UPLOAD_STATUS');

export {
    setRequestInProgress,
    updateError,
    updateErrorMessage,
    resetErrorState,
    updatePageOptions,
    updateCurrentPath,
    setHeaderHeight,
    resetStore,
    updateDisableRequestErrors,
    setNotification,
    resetNotification,
    clearAllNotifications,
    setFileUploadStatus,
    resetFilesUploadStatus,
};
