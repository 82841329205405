// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-common-SerialNumber-_SerialNumber__root{display:flex;height:20px;margin-right:8px;padding:0 6px;letter-spacing:0.3px;color:#7e8681;border-radius:3px;background:#ebeeec;font-family:\"Open Sans\";font-size:12px;font-weight:600;align-items:center}\n", "",{"version":3,"sources":["webpack://./src/client/modules/dashboard/RightSidebar/BudgetInfo/Corrections/templates/common/SerialNumber/SerialNumber.scss"],"names":[],"mappings":"AAAA,oHACI,YAAa,CAEb,WAAY,CACZ,gBAAiB,CACjB,aAAc,CAEd,oBAAqB,CAErB,aAAc,CACd,iBAAkB,CAClB,kBAAmB,CAEnB,uBAAwB,CACxB,cAAe,CACf,eAAgB,CAEhB,kBAAmB","sourcesContent":[".root {\n    display: flex;\n\n    height: 20px;\n    margin-right: 8px;\n    padding: 0 6px;\n\n    letter-spacing: 0.3px;\n\n    color: #7e8681;\n    border-radius: 3px;\n    background: #ebeeec;\n\n    font-family: \"Open Sans\";\n    font-size: 12px;\n    font-weight: 600;\n\n    align-items: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-common-SerialNumber-_SerialNumber__root"
};
export default ___CSS_LOADER_EXPORT___;
