import { actionCreatorFactory } from 'typescript-fsa';

import { PersonalDashboardUserConfig, UpdatePersonalDashboardUserConfig } from './types';

const actionCreator = actionCreatorFactory('USER_CONFIG_DASHBOARD');

const loadPersonalDashboardUserConfig = actionCreator.async<
    PersonalDashboardUserConfig,
    PersonalDashboardUserConfig,
    Error
>('LOAD_PERSONAL_DASHBOARD_USER_CONFIG');

const updatePersonalDashboardUserConfig = actionCreator.async<UpdatePersonalDashboardUserConfig, void, Error>(
    'UPDATE_PERSONAL_DASHBOARD_USER_CONFIG',
);

const resetPersonalDashboardUserConfig = actionCreator('RESET_PERSONAL_DASHBOARD_USER_CONFIG');

export { loadPersonalDashboardUserConfig, updatePersonalDashboardUserConfig, resetPersonalDashboardUserConfig };
