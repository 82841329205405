import * as React from 'react';
import { connect } from 'react-redux';
import { UserResponseParams } from 'sber-marketing-types/frontend';

import { StoreState } from '@store';
import { getUserById } from '@store/users';

import { NewResponsibleDescription } from './NewResponsibleDescription';

interface Props extends Partial<MapProps>, OwnProps {}

interface OwnProps {
    id: number;
}

interface MapProps {
    user: UserResponseParams;
}

@(connect(mapStateToProps) as any)
export class NewResponsibleDescriptionContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { firstName, secondName, departmentName } = this.props.user;

        return <NewResponsibleDescription firstName={firstName} secondName={secondName} department={departmentName} />;
    }
}

function mapStateToProps(state: StoreState, props: OwnProps): MapProps {
    return {
        user: getUserById(state, props.id),
    };
}
