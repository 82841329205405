import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { ScrollbarComponent } from 'sber-marketing-ui';
import type { LinesGroup, ActivityLineParams, TaskLineParams } from '@store/calendar/types';
import { LineType } from '@store/calendar/types';

import { ActivityGroup } from './ActivityGroup';
import { setHoveredItemId } from '@store/calendar/actions';
import { Scaler } from '../../modules';

const BLOCK_NAME_HEIGHT = 44;
const LINE_HEIGHT = 32;

interface Props extends Partial<DispatchProps> {
    group: LinesGroup;
    fullWidth: number;
    viewportWidth: number;
    canvasRef: (element: HTMLCanvasElement, groupId: string) => void;
    scrollbarRef: (component: ScrollbarComponent, groupId: string) => void;
    onScroll: (scrollLeft: number, groupId: string) => void;
}

interface DispatchProps {
    setHoveredItemId: (lineId: React.ReactText) => void;
}

interface State {
    hoveredItemId: React.ReactText;
}

@(connect(null, mapDispatchToProps) as any)
export class ActivityGroupContainer extends React.Component<Props, State> {
    private scrollContent: HTMLDivElement;
    private scaler: Scaler;

    constructor(props: Props) {
        super(props);

        this.state = {
            hoveredItemId: null,
        };

        this.scaler = Scaler.getInstance();
    }

    public shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
        return !lodash.isEqual(this.props, nextProps) || !lodash.isEqual(this.state, nextState);
    }

    public componentDidMount() {
        this.scrollContent.addEventListener('mousemove', this.onMousemove);
        this.scrollContent.addEventListener('mouseleave', this.onGroupMouseLeave);
    }

    public componentWillUnmount() {
        this.scrollContent.removeEventListener('mousemove', this.onMousemove);
        this.scrollContent.removeEventListener('mouseleave', this.onGroupMouseLeave);
    }

    public render(): JSX.Element {
        return React.createElement(ActivityGroup, {
            group: this.props.group,
            fullWidth: this.props.fullWidth,
            viewportWidth: this.props.viewportWidth,
            activeItemHovered: this.checkLineItemClickability(this.state.hoveredItemId),
            canvasRef: this.props.canvasRef,
            scrollbarRef: this.props.scrollbarRef,
            scrollContentRef: this.scrollContentRef,
            onScroll: this.props.onScroll,
            onClick: this.onClick,
        });
    }

    @autobind
    private scrollContentRef(element: HTMLDivElement) {
        this.scrollContent = element;
    }

    @autobind
    private onMousemove(event: MouseEvent) {
        const hoveredLineId = this.getHoveredLine(event.offsetY);

        const hoveredItemId = this.getHoveredItemId(hoveredLineId, event.offsetX);

        if (hoveredItemId !== this.state.hoveredItemId) {
            this.setState({
                hoveredItemId,
            });
        }

        this.props.setHoveredItemId(hoveredItemId);
    }

    @autobind
    private onGroupMouseLeave() {
        this.setState({
            hoveredItemId: null,
        });

        this.props.setHoveredItemId(null);
    }

    @autobind
    private onClick() {
        const { hoveredItemId } = this.state;
        const { group } = this.props;

        if (hoveredItemId) {
            const line = group.lines.find((line) => line.id == hoveredItemId);

            switch (line.type) {
                case LineType.Activity:
                    const { isActive } = line as ActivityLineParams;

                    if (isActive) {
                        this.openUrlInNewTab(`/activity/${hoveredItemId}`);
                    }
                    break;

                case LineType.Task:
                    const { activityId, activityIsActive } = line as TaskLineParams;

                    if (activityIsActive) {
                        this.openUrlInNewTab(`/activity/${activityId}/task/${hoveredItemId}`);
                    }
                    break;
            }
        }
    }

    private getHoveredLine(y: number): React.ReactText {
        if (y <= BLOCK_NAME_HEIGHT) {
            return null;
        }

        const lineIndex = Math.floor((y - BLOCK_NAME_HEIGHT) / LINE_HEIGHT) + 1;

        const line = this.props.group.lines[lineIndex];

        return line ? line.id : null;
    }

    private getHoveredItemId(lineId: React.ReactText, coordX: number): React.ReactText {
        if (lineId === null) {
            return null;
        }

        const line = this.props.group.lines.find((line) => line.id == lineId);

        const hoveredDay = this.scaler.convertPxToDay(coordX);

        let itemIsHovered: boolean;

        switch (line.type) {
            case LineType.GroupTitle:
                itemIsHovered = false;
                break;

            case LineType.Activity:
                const { preparationStart, realizationStart, realizationEnd, debriefingEnd } =
                    line as ActivityLineParams;

                const activityStart = preparationStart || realizationStart;
                const activityEnd = debriefingEnd || realizationEnd;

                itemIsHovered = activityStart <= hoveredDay && hoveredDay <= activityEnd;

                break;

            case LineType.Task:
                const { deadline } = line as TaskLineParams;

                itemIsHovered = hoveredDay == deadline;

                break;
        }

        return itemIsHovered ? lineId : null;
    }

    private checkLineItemClickability(lineId: React.ReactText): boolean {
        if (!lineId) {
            return false;
        }

        const line = this.props.group.lines.find((line) => line.id == lineId);

        if (!line) {
            return false;
        }

        let hoveredItemIsActive = false;

        switch (line.type) {
            case LineType.Activity:
                const { isActive } = line as ActivityLineParams;

                hoveredItemIsActive = isActive;

                break;

            case LineType.Task:
                const { activityIsActive } = line as TaskLineParams;

                hoveredItemIsActive = activityIsActive;

                break;
        }

        return hoveredItemIsActive;
    }

    private openUrlInNewTab(url: string): void {
        window.open(url, '_blank');
    }
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setHoveredItemId,
        },
        dispatch,
    );
}
