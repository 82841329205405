import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import { MONTH_BY_COLUMN_NAMES } from '@store/budgetExecution';
import {
    ComponentState,
    getBudgetTransferMenuState,
    setTransferAmount,
    isCellSelected,
    getTransitionBudgetItem,
    getTotalTransferAmountForSource,
    getTotalTransferAmountForDest,
} from '@store/budgetExecution/budgetTransferMenu';

import { Utils } from '@common/Utils';
import { TransferAmountInput as GeneralTransferAmountInput } from '../../TransferAmountInput';

export function TransferAmountInput(): JSX.Element {
    const componentState = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.componentState,
    );

    if (componentState === ComponentState.ExternalOutcomeTransfer) {
        return <ExternalOutcomeTransferAmountInput />;
    } else {
        return <ExternalIncomeTransferAmountInput />;
    }
}

function useExternalOutcomeTransferAmountInputStore() {
    const dispatch = useDispatch();

    const cell = useSelector((state: StoreState) => getBudgetTransferMenuState(state).cells.from[0]);
    const maxTransferAmount =
        useSelector((state: StoreState) =>
            Utils.withErrorHandler(
                () => getTransitionBudgetItem(state, cell.lineId).plannedFunds[MONTH_BY_COLUMN_NAMES[cell.columnName]],
            ),
        ) || 0;
    const maxButtonTransferAmount =
        useSelector((state: StoreState) =>
            Utils.withErrorHandler<number>(() => {
                const budgetItem = getTransitionBudgetItem(state, cell.lineId);
                const month = MONTH_BY_COLUMN_NAMES[cell.columnName];

                return budgetItem.plannedFunds[month] - budgetItem.reservedFunds[month];
            }),
        ) || 0;
    const transferAmount =
        useSelector((state: StoreState) =>
            Utils.withErrorHandler(() => getTotalTransferAmountForSource(state, cell.lineId)),
        ) || 0;

    let disabled = true;
    let displayAllAmountButton = false;
    if (isCellSelected(cell)) {
        disabled = false;
        displayAllAmountButton = true;
    }

    function saveTransferAmount(amount: number) {
        if (isCellSelected(cell)) {
            dispatch(
                setTransferAmount({
                    from: cell,
                    to: null,
                    amount,
                }),
            );
        }
    }

    return {
        disabled,
        displayAllAmountButton,
        transferAmount,
        maxTransferAmount,
        maxButtonTransferAmount,
        saveTransferAmount,
    };
}

function ExternalOutcomeTransferAmountInput(): JSX.Element {
    const {
        disabled,
        displayAllAmountButton,
        transferAmount,
        maxTransferAmount,
        maxButtonTransferAmount,
        saveTransferAmount,
    } = useExternalOutcomeTransferAmountInputStore();

    return (
        <GeneralTransferAmountInput
            disabled={disabled}
            title="Введите сумму"
            value={transferAmount}
            maxValue={maxTransferAmount}
            maxButtonValue={maxButtonTransferAmount}
            addAllAmountButton={displayAllAmountButton}
            onChange={saveTransferAmount}
        />
    );
}

function useExternalIncomeTransferAmountInputStore() {
    const dispatch = useDispatch();

    const cell = useSelector((state: StoreState) => getBudgetTransferMenuState(state).cells.to[0]);

    let disabled = !isCellSelected(cell);
    let transferAmount =
        useSelector((state: StoreState) =>
            Utils.withErrorHandler(() => getTotalTransferAmountForDest(state, cell.lineId)),
        ) || 0;

    function saveTransferAmount(amount: number) {
        if (isCellSelected(cell)) {
            dispatch(
                setTransferAmount({
                    from: null,
                    to: cell,
                    amount,
                }),
            );
        }
    }

    return {
        disabled,
        transferAmount,
        saveTransferAmount,
    };
}

function ExternalIncomeTransferAmountInput(): JSX.Element {
    const { disabled, transferAmount, saveTransferAmount } = useExternalIncomeTransferAmountInputStore();

    return (
        <GeneralTransferAmountInput
            disabled={disabled}
            title="Введите сумму"
            value={transferAmount}
            addAllAmountButton={false}
            onChange={saveTransferAmount}
        />
    );
}
