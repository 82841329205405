import * as React from 'react';
import * as lodash from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import { positionValues } from 'react-custom-scrollbars';

import { StoreState } from '@store';
import { getNewsFilters, getLoadingStatus } from '@store/userConfig/dashboard';
import {
    getStatus,
    getCanBeLoadedMore,
    getNewsList,
    fetchMore,
    updateNews,
    resetNews,
    NewsItemParams,
    getNewsFiltersForRequest,
    NewsFiltersForRequest,
    GetNewsParams,
} from '@store/news';
import { LoadingStatus } from '@store/commonTypes';
import * as divisionsStore from '@store/divisions';
import * as activityTypesStore from '@store/activityTypes';
import * as usersStore from '@store/users';

import { HistoryApi } from '@api';

import { NewsColumn, IdsNews } from './NewsColumn';

const divisionsEntities = divisionsStore.StoreTypes.NEWS_FILTER;
const activityTypesEntities = activityTypesStore.StoreTypes.NEWS_FILTER;
const usersEntities = usersStore.StoreTypes.NEWS_FILTER;

export interface Props extends Partial<MapProps & DispatchProps> {}

interface MapProps {
    userConfigLoadingStatus: LoadingStatus;
    /** News loading status */
    status: LoadingStatus;
    /** Are more news can be loaded */
    canBeLoadedMoreNews: boolean;
    /** Loaded news list */
    news: NewsItemParams[];
    filters: NewsFiltersForRequest;
    showUnseenNews: boolean;
    areEntitiesLoaded: boolean;
}

interface DispatchProps {
    fetchMore(params: GetNewsParams): void;
    updateNews(params: GetNewsParams): void;
    resetNews(): void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class NewsColumnContainer extends React.Component<Props> {
    private fetchMoreDebounced: (params: GetNewsParams) => void = null;

    public constructor(props: Props) {
        super(props);

        this.fetchMoreDebounced = lodash.debounce((params) => props.fetchMore(params), 1000);
    }

    public shouldComponentUpdate(props: Props): boolean {
        const newsHaveChanged = !lodash.isEqual(props.news, this.props.news);
        const filtersHaveChanged = !lodash.isEqual(props.filters, this.props.filters);
        const miscHaveChanged =
            props.userConfigLoadingStatus !== this.props.userConfigLoadingStatus ||
            props.status !== this.props.status ||
            props.canBeLoadedMoreNews !== this.props.canBeLoadedMoreNews ||
            props.showUnseenNews !== this.props.showUnseenNews ||
            props.areEntitiesLoaded !== this.props.areEntitiesLoaded;

        return newsHaveChanged || filtersHaveChanged || miscHaveChanged;
    }

    public componentDidMount() {
        if (this.props.status === LoadingStatus.NOT_LOADED) {
            this.fetchNews();
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const filtersHaveChanged =
            !lodash.isEqual(nextProps.filters, this.props.filters) ||
            nextProps.showUnseenNews !== this.props.showUnseenNews ||
            nextProps.userConfigLoadingStatus !== this.props.userConfigLoadingStatus;

        if (filtersHaveChanged) {
            this.fetchNews(nextProps.filters);
        }
    }

    public componentWillUnmount() {
        this.props.resetNews();
    }

    public render(): JSX.Element {
        const { status, news, areEntitiesLoaded, userConfigLoadingStatus } = this.props;

        const areNewsLoading: boolean = status === LoadingStatus.NOT_LOADED || status === LoadingStatus.LOADING;

        const filtersLoaded = userConfigLoadingStatus === LoadingStatus.LOADED && areEntitiesLoaded;

        return React.createElement(NewsColumn, {
            filtersLoaded,
            showPreloader: areNewsLoading,
            newsParams: news,
            onScroll: this.onScroll,
            onCloseButtonClick: this.onCloseButtonClick,
            allDataLoaded: areEntitiesLoaded,
        });
    }

    @autobind
    private onScroll({ scrollTop, scrollHeight, clientHeight }: positionValues): void {
        const isScrolledDown = clientHeight === scrollHeight - scrollTop;

        if (isScrolledDown && this.props.canBeLoadedMoreNews) {
            this.fetchNews(this.props.filters, true);
        }
    }

    @autobind
    private fetchNews(nextFilters?: NewsFiltersForRequest, wasScrolledToBottom?: boolean): void {
        const filters = nextFilters || this.props.filters;

        this.fetchMoreDebounced({
            ...filters,
            wasScrolledToBottom,
        });
    }

    @autobind
    private async onCloseButtonClick(idsNews: IdsNews): Promise<void> {
        const referenceForQuery = this.defineReferencesForQuery(idsNews);
        await HistoryApi.setRecentView(referenceForQuery);
        await this.props.updateNews(this.props.filters);
    }

    private defineReferencesForQuery(idsNews: IdsNews): string {
        const { activityId, taskId } = idsNews;
        return taskId ? `task_${taskId}` : `activity_${activityId}`;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const divisionsLoadingStatus = divisionsStore.getLoadingStatus(state, divisionsEntities);
    const activityTypesLoadingStatus = activityTypesStore.getLoadingStatus(state, activityTypesEntities);
    const usersLoadingStatus = usersStore.getLoadingStatus(state, usersEntities);
    const userConfigLoadingStatus = getLoadingStatus(state);

    const areEntitiesLoaded =
        divisionsLoadingStatus === LoadingStatus.LOADED &&
        activityTypesLoadingStatus === LoadingStatus.LOADED &&
        usersLoadingStatus === LoadingStatus.LOADED &&
        userConfigLoadingStatus === LoadingStatus.LOADED;

    return {
        status: getStatus(state),
        canBeLoadedMoreNews: getCanBeLoadedMore(state),
        news: getNewsList(state),
        filters: getNewsFiltersForRequest(state),
        showUnseenNews: getNewsFilters(state).onlyUnseen,
        userConfigLoadingStatus,
        areEntitiesLoaded,
    };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
    return {
        fetchMore: (params: GetNewsParams) => dispatch(fetchMore(params)),
        updateNews: (params: GetNewsParams) => dispatch(updateNews(params)),
        resetNews: () => dispatch(resetNews()),
    };
}
