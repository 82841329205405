import * as React from 'react';
import classNames from 'classnames';
import {
    OpacityTransition,
    CustomScrollbar_new as CustomScrollbar,
    LabeledSelect_redesign as LabeledSelect,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    Preloader,
} from 'sber-marketing-ui';

import { BudgetItemSelect } from './BudgetItemSelect';

import * as style from './styles.scss';

const CONTENT_MAX_HEIGHT = 550;

interface Props {
    showPreloader: boolean;
    creativeRequests: any[];
    selectedCreativeRequestId: string;
    onSaveButtonClick: () => void;
    onCancelButtonClick: () => void;
    onCreativeRequestSelection: (creativeRequestId: string) => void;
}

export const CreativeReferenceMenuTemplate = ({
    showPreloader,
    creativeRequests,
    selectedCreativeRequestId,
    onSaveButtonClick,
    onCancelButtonClick,
    onCreativeRequestSelection,
}: Props): JSX.Element => {
    const saveButtonIsDisabled = false;
    const isCreativeRequestSelected = !!selectedCreativeRequestId;

    return (
        <OpacityTransition>
            <div className={classNames(style.root, isCreativeRequestSelected && style.creativeRequestSelected)}>
                <div className={style.title}>Прикрепить ID источника к ЗНК</div>

                <div className={style.activitySelect}>
                    <LabeledSelect
                        title={'Название заявки'}
                        items={creativeRequests.map(({ id, name }) => ({
                            label: name,
                            value: id,
                        }))}
                        selectedValue={selectedCreativeRequestId}
                        onSelection={(value) => onCreativeRequestSelection(value as string)}
                    />
                </div>

                {isCreativeRequestSelected && (
                    <div className={style.scrollbar}>
                        <CustomScrollbar maxHeight={CONTENT_MAX_HEIGHT} freezeScrollX hideScrollX>
                            <div className={style.scrollContent}>
                                <BudgetItemSelect />
                            </div>
                        </CustomScrollbar>
                    </div>
                )}

                <div className={style.buttons}>
                    <div className={style.button}>
                        <Button theme={ButtonTheme.GhostRounded} onClick={onCancelButtonClick}>
                            Закрыть
                        </Button>
                    </div>

                    <div className={style.button}>
                        <Button
                            theme={ButtonTheme.MainRounded}
                            onClick={onSaveButtonClick}
                            disabled={saveButtonIsDisabled}
                        >
                            Сохранить
                        </Button>
                    </div>
                </div>

                {showPreloader && <Preloader />}
            </div>
        </OpacityTransition>
    );
};
