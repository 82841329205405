import * as React from 'react';
import { Brief } from 'sber-marketing-types/frontend';

const LOCAL_STORAGE_KEY = 'copiedBrief';

interface Props {
    copiedBrief: Brief | null;
    copyBrief: (brief: Brief) => void;
}

const withCopiedBrief = (WrappedComponent: React.ComponentType<Props>) => {
    return class extends React.Component {
        constructor(props: any) {
            super(props);
            this.copyBrief = this.copyBrief.bind(this);
            this.handleStorageEvent = this.handleStorageEvent.bind(this);
        }

        public componentDidMount() {
            window.addEventListener('storage', this.handleStorageEvent);
        }

        public componentWillUnmount() {
            window.removeEventListener('storage', this.handleStorageEvent);
        }

        public render() {
            return <WrappedComponent copiedBrief={this.getCopiedBrief()} copyBrief={this.copyBrief} {...this.props} />;
        }

        private getCopiedBrief(): Brief | null {
            return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
        }

        private copyBrief(brief: Brief): void {
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(brief));
            this.forceUpdate();
        }

        private handleStorageEvent(event: StorageEvent): void {
            if (event.key === LOCAL_STORAGE_KEY) {
                this.forceUpdate();
            }
        }
    };
};

export { withCopiedBrief, Props as CopiedBriefProps };
