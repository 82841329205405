import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';

import { PageState, FormData, activityBudgetRequiredFields, budgetItemRequiredFields } from './types';
import * as actions from './actions';

const REQUIRED_FIELD_ERROR = 'Обязательное поле';
const ACTIVITY_BUDGET_NAME_LENGTH_ERROR = 'Название активности не может быть короче 3 символов';
const BUDGET_ITEM_NAME_LENGTH_ERROR = 'Название проекта не может быть короче 3 символов';

export const initialState: PageState = {
    activityBudget: null,
    budget: null,
    budgetItems: [],
    activityForm: {
        id: null,
        fields: [],
        isNew: false,
    },
    budgetItemForms: [],
    availableDictionaries: { byId: {}, byType: {} },
    usedDictionaries: { byId: {}, byType: {} },
    users: [],
    isNameInputFocused: false,
};

class Reducer {
    public static loadEditBudgetPage(state: PageState, payload: PageState): PageState {
        return { ...state, ...payload };
    }

    public static resetEditBudgetPage(state: PageState): PageState {
        return lodash.cloneDeep(initialState);
    }

    public static setBudgetCardCollapseStatus(state: PageState, payload: { id: string; status: boolean }): PageState {
        const updatedForms: FormData[] = lodash.clone(state.budgetItemForms);

        const budgetItemForm = updatedForms.find((item) => item.id == payload.id);

        if (budgetItemForm) {
            budgetItemForm.collapsed = payload.status;
        }

        return { ...state, budgetItemForms: updatedForms };
    }

    public static addBudgetForm(state: PageState, payload: FormData): PageState {
        return { ...state, budgetItemForms: [payload, ...state.budgetItemForms] };
    }

    public static removeBudgetForm(state: PageState, payload: string): PageState {
        const updatedForms: FormData[] = lodash.clone(state.budgetItemForms);

        lodash.remove(updatedForms, (item) => item.id == payload);

        return { ...state, budgetItemForms: updatedForms };
    }

    public static updateActivityForm(state: PageState, payload: FormData): PageState {
        return { ...state, activityForm: { ...payload } };
    }

    public static updateBudgetForm(state: PageState, payload: FormData): PageState {
        const updatedBudgetItemForms = state.budgetItemForms.map((budgetItemForm) => {
            return budgetItemForm.id === payload.id ? { ...budgetItemForm, ...payload } : budgetItemForm;
        });

        return { ...state, budgetItemForms: updatedBudgetItemForms };
    }

    public static updateActivityFormValidation(state: PageState): PageState {
        const { activityForm } = state;

        const updatedFields = activityForm.fields.map((item) => {
            const isRequired = lodash.includes(activityBudgetRequiredFields, item.name);
            const hasValue = !!item.value;

            return { ...item, errorMessage: isRequired && !hasValue ? REQUIRED_FIELD_ERROR : '' };
        });

        const activityNameField = updatedFields.find((item) => item.name == 'name');

        const activityNameIsTooShort = activityNameField.value && (activityNameField.value as string).length < 3;

        if (activityNameIsTooShort) {
            activityNameField.errorMessage = ACTIVITY_BUDGET_NAME_LENGTH_ERROR;
        }

        return {
            ...state,
            activityForm: {
                ...activityForm,
                fields: updatedFields,
            },
        };
    }

    public static updateBudgetFormsValidation(state: PageState): PageState {
        const { budgetItemForms } = state;

        const updatedForms = budgetItemForms.map((form) => {
            const updatedFields = form.fields.map((item) => {
                const isRequired = lodash.includes(budgetItemRequiredFields, item.name);
                const hasValue = !!item.value;

                return { ...item, errorMessage: isRequired && !hasValue ? REQUIRED_FIELD_ERROR : '' };
            });

            const sapCommentField = updatedFields.find((item) => item.name == 'sapComment');

            const sapCommentIsTooShort = sapCommentField.value && (sapCommentField.value as string).length < 3;

            if (sapCommentIsTooShort) {
                sapCommentField.errorMessage = BUDGET_ITEM_NAME_LENGTH_ERROR;
            }

            const startDate = updatedFields.find((item) => item.name == 'startDate');
            const endDate = updatedFields.find((item) => item.name == 'endDate');

            startDate.errorMessage = !startDate.value && !!endDate.value ? REQUIRED_FIELD_ERROR : '';
            endDate.errorMessage = !!startDate.value && !endDate.value ? REQUIRED_FIELD_ERROR : '';

            const shouldExpandForm = updatedFields.some((item) => !!item.errorMessage);

            return {
                ...form,
                fields: updatedFields,
                collapsed: shouldExpandForm ? false : form.collapsed,
            };
        });

        return { ...state, budgetItemForms: updatedForms };
    }

    public static setNameInputFocus(state: PageState, isNameInputFocused: boolean): PageState {
        return { ...state, isNameInputFocused };
    }

    public static setActivityInputFocus(
        state: PageState,
        payload: { budgetItemId: string; isFocused: boolean },
    ): PageState {
        const { budgetItemId, isFocused } = payload;

        const updatedForms: FormData[] = lodash.clone(state.budgetItemForms);

        const budgetItem = updatedForms.find((item) => item.id === budgetItemId);

        budgetItem.activityNameIsFocused = isFocused;

        return { ...state, budgetItemForms: updatedForms };
    }

    public static setBudgetItemTransferDestination(
        state: PageState,
        payload: { budgetItemId: string; activityBudgetId: string },
    ): PageState {
        const { budgetItemId, activityBudgetId } = payload;

        const updatedForms: FormData[] = lodash.clone(state.budgetItemForms);

        const budgetItemForm = updatedForms.find((item) => item.id === budgetItemId);

        budgetItemForm.transferDestinationId = activityBudgetId;

        return { ...state, budgetItemForms: updatedForms };
    }
}

export const executionBudgetEditPageReducer = reducerWithInitialState(initialState)
    .case(actions.loadEditBudgetPage, Reducer.loadEditBudgetPage)
    .case(actions.resetEditBudgetPage, Reducer.resetEditBudgetPage)
    .case(actions.setBudgetCardCollapseStatus, Reducer.setBudgetCardCollapseStatus)
    .case(actions.addBudgetForm, Reducer.addBudgetForm)
    .case(actions.removeBudgetForm, Reducer.removeBudgetForm)
    .case(actions.updateActivityForm, Reducer.updateActivityForm)
    .case(actions.updateBudgetForm, Reducer.updateBudgetForm)
    .case(actions.updateActivityFormValidation, Reducer.updateActivityFormValidation)
    .case(actions.updateBudgetFormsValidation, Reducer.updateBudgetFormsValidation)
    .case(actions.setNameInputFocus, Reducer.setNameInputFocus)
    .case(actions.setActivityInputFocus, Reducer.setActivityInputFocus)
    .case(actions.setBudgetItemTransferDestination, Reducer.setBudgetItemTransferDestination);
