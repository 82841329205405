// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-Header-BudgetItemStatus-ControlButtons-_ControlButtons__root{display:grid;grid-template-columns:repeat(2, 1fr);grid-gap:12px}.src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-Header-BudgetItemStatus-ControlButtons-_ControlButtons__commentInputWrapper{grid-column-start:1;grid-column-end:-1}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetPlanning/LineModal/Header/BudgetItemStatus/ControlButtons/ControlButtons.scss"],"names":[],"mappings":"AAAA,4HACI,YAAa,CAEb,oCAAqC,CACrC,aAAc,CACjB,2IAGG,mBAAoB,CACpB,kBAAmB","sourcesContent":[".root {\n    display: grid;\n\n    grid-template-columns: repeat(2, 1fr);\n    grid-gap: 12px;\n}\n\n.commentInputWrapper {\n    grid-column-start: 1;\n    grid-column-end: -1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-Header-BudgetItemStatus-ControlButtons-_ControlButtons__root",
	"commentInputWrapper": "src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-Header-BudgetItemStatus-ControlButtons-_ControlButtons__commentInputWrapper"
};
export default ___CSS_LOADER_EXPORT___;
