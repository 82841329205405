import * as React from 'react';
import { Icon, IconType } from 'sber-marketing-ui';

import * as styles from './ActivityAlreadyExistsCaption.scss';

export function ActivityAlreadyExistsCaption(): JSX.Element {
    return (
        <div className={styles.root}>
            <div className={styles.icon}>
                <Icon type={IconType.EXPIRED_TASK} svgSize={12} />
            </div>
            Строка будет привязана к уже существующей активности
        </div>
    );
}
