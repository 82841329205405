import { useGetTaskChannel } from '@modules/task/hooks';
import { useGetTaskQuery } from '@api';
import { useMemo } from 'react';

export interface UseGetChannelParticipantIds {
    participants?: number[];
    isLoading: boolean;
}

export function useGetChannelParticipantIds(taskId: string, channelId: number): UseGetChannelParticipantIds {
    const { channel, isLoading: isChannelLoading } = useGetTaskChannel(taskId, channelId, !channelId);
    const { data: task, isLoading: isTaskLoading } = useGetTaskQuery({ id: taskId }, { skip: Boolean(channelId) });

    const participants = useMemo(
        () => (channelId ? channel?.participantIds : task?.task.participants?.map(({ id }: any) => id)),
        [channelId, channel, task],
    );

    const isLoading = isChannelLoading || isTaskLoading;

    return { isLoading, participants };
}
