import { useSelector } from 'react-redux';

import type { StoreState } from '@store';
import { getAutopilot, getActivity } from '@store/autopilot/selectors';
import { AutopilotApi } from '@api';

const MEDIAPLAN_EXTRA_NAME = 'МЕДИАПЛАН СОЗДАН АВТОМАТИЧЕСКИ';

export const useHooks = () => {
    const autopilot = useSelector((state: StoreState) => getAutopilot(state));
    const activity = useSelector((state: StoreState) => getActivity(state));

    async function download() {
        try {
            const data = await AutopilotApi.getMediaplanXLS(autopilot.id);
            const href = URL.createObjectURL(data);
            const link = document.createElement('a');
            link.setAttribute('download', `${activity.name} ${MEDIAPLAN_EXTRA_NAME}.xlsx`);
            link.target = '_blank';
            link.href = href;
            document.body.appendChild(link); // Required for FF
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        } catch (error) {
            console.log(error);
        }
    }

    return {
        download,
    };
};
