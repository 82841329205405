import * as React from 'react';
import * as moment from 'moment';
import classNames from 'classnames';

import * as style from './SnapshotTooltip.scss';

import { BodyPortal, Icon, IconType } from 'sber-marketing-ui';

interface Props {
    snapshot?: {
        previousValue: string;
        newValue: string;
        timestamp: Date;
        userName: string;
    };
}

export const SnapshotTooltip = ({ snapshot }: Props): JSX.Element => {
    const [cellIsHovered, setHoveredStatus] = React.useState(false);

    const rootRef = React.useRef<HTMLDivElement>();

    React.useEffect(() => {
        if (rootRef.current?.parentNode.addEventListener) {
            rootRef.current.parentNode.addEventListener('mouseenter', onMouseEnter);
            rootRef.current.parentNode.addEventListener('mouseleave', onMouseLeave);
        }

        return () => {
            rootRef.current?.parentNode.addEventListener('mouseenter', onMouseEnter);
            rootRef.current?.parentNode.addEventListener('mouseleave', onMouseLeave);
        };
    }, [rootRef.current]);

    function onMouseEnter() {
        setHoveredStatus(true);
    }

    function onMouseLeave(event: MouseEvent) {
        event.stopPropagation();

        setHoveredStatus(false);
    }

    return (
        <div className={style.root} ref={rootRef}>
            {!!snapshot && cellIsHovered && (
                <BodyPortal>
                    <Toooltip snapshot={snapshot} rootRef={rootRef} />
                </BodyPortal>
            )}
        </div>
    );
};

interface ToooltipProps {
    rootRef: React.MutableRefObject<HTMLDivElement>;
    snapshot?: {
        previousValue: string;
        newValue: string;
        timestamp: Date;
        userName: string;
    };
}

function Toooltip({ rootRef, snapshot }: ToooltipProps) {
    const [position, setPosition] = React.useState<{ x: number; y: number }>(null);

    React.useEffect(() => {
        const { x, y, width } = rootRef.current.getBoundingClientRect();

        setPosition({
            x: x + width / 2,
            y,
        });
    }, []);

    if (!position) {
        return null;
    }

    return (
        <div className={style.tooltip} style={{ top: position.y, left: position.x }}>
            <div className={style.line}>
                <span className={style.userName}>{snapshot.userName}</span>
                &nbsp;
                <span className={style.date}>{moment(snapshot.timestamp).format('DD.MM.YYYY')}</span>
            </div>

            <div className={style.line}>
                <span className={classNames(style.oldValue, snapshot.previousValue !== null && style.strikethrough)}>
                    {snapshot.previousValue !== '' && snapshot.previousValue !== null ? snapshot.previousValue : '—'}
                </span>
                <Icon className={style.arrowIcon} type={IconType.ARROW16_STRAIGHT_RIGHT} />
                <span className={style.value}>
                    {snapshot.newValue !== '' && snapshot.newValue !== null ? snapshot.newValue : '—'}
                </span>
            </div>
        </div>
    );
}
