import * as React from 'react';
import { isEqual } from 'lodash';

import { ActivitySuggestTemplate } from './ActivitySuggestTemplate';
import { ChildrenProps } from './ActivitySuggestConnected';

interface Props extends ChildrenProps {
    isNameInputFocused: boolean;
    nameInputValue: string;
    pageIsInCopyFromExecutionMode: boolean;
    setActivityIsAlreadyExists(activity: { id: string; name: string }): void;
}

interface State {
    componentHaveReceivedData: boolean;
}

export class ActivitySuggestContainer extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            componentHaveReceivedData: false,
        };
    }

    public componentDidUpdate(prevProps: Props): void {
        const budgetItemsHaveChanged = !isEqual(this.props.budgetItems, prevProps.budgetItems);

        if (
            this.props.pageIsInCopyFromExecutionMode &&
            !this.state.componentHaveReceivedData &&
            budgetItemsHaveChanged
        ) {
            const firstBudgetItem = this.props.budgetItems[0];

            this.props.setActivityIsAlreadyExists(firstBudgetItem.activity);
        }

        if (budgetItemsHaveChanged) {
            this.setState({
                componentHaveReceivedData: true,
            });
        }
    }

    public render(): JSX.Element {
        return <ActivitySuggestTemplate {...this.props} />;
    }
}
