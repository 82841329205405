import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import type { HeaderView } from '@common/Page';
import { PageMode } from '@store/calendar/types';
import type { PageOptions } from '@store/common/types';
import type { User } from '@store/user/types';

import { CalendarPage, HeaderTop, PageUrl } from './CalendarPage';
import type { StoreState } from '@store';
import { getPageMode } from '@store/calendar/selectors';
import { updatePageOptions, setRequestInProgress } from '@store/common/actions';
import { isRequestInProgress } from '@store/common/selectors';
import { setPageMode } from '@store/calendar/actions';
import { getLoginUser } from '@store/user/selector';
import { Loader, Saver } from './modules';

const PAGE_MODE_NAMES_BY_URL = {
    [PageUrl.WeekDigest]: PageMode.WeekDigest,
    [PageUrl.Chart]: PageMode.Chart,
};

interface Props extends MapProps, DispatchProps, RouteComponentProps {
    setHeaderView?: (view: HeaderView) => void;
}

interface MapProps {
    preloader: boolean;
    user: User;
    currentPageMode: PageMode;
}

interface DispatchProps {
    updatePageOptions: (options: PageOptions) => void;
    setRequestInProgress: (requestStatus: boolean) => void;
    setPageMode: (pageMode: PageMode) => void;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class CalendarPageContainer extends React.Component<Props> {
    private loader: Loader;
    private saver: Saver;

    constructor(props: Props) {
        super(props);

        this.loader = Loader.getInstance();
        this.saver = Saver.getInstance();
        this.props.setRequestInProgress(true);

        this.updateCurrentPageMode();
        this.updateHeader();
    }

    public async componentDidMount() {
        await this.loader.init();
        this.saver.init();

        this.props.setRequestInProgress(false);
    }

    public componentDidUpdate(prevProps: Props): void {
        const pageModeChanged = this.props.match.url !== prevProps.match.url;
        const preloaderChanged = this.props.preloader !== prevProps.preloader;

        if (pageModeChanged || preloaderChanged) {
            this.updateCurrentPageMode();
            this.updateHeader();
        }
    }

    public render(): JSX.Element {
        return React.createElement(CalendarPage, {
            currentPage: this.props.currentPageMode,
            preloader: this.props.preloader,
        });
    }

    private updateCurrentPageMode() {
        this.props.setPageMode(PAGE_MODE_NAMES_BY_URL[this.props.match.url]);
    }

    private updateHeader() {
        const { match, preloader } = this.props;

        const currentUrl = match.url;

        this.props.setHeaderView({
            firstLine: HeaderTop({
                currentUrl,
                isRequestInProgress: preloader,
            }),
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        preloader: isRequestInProgress(state),
        user: getLoginUser(state),
        currentPageMode: getPageMode(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            updatePageOptions,
            setRequestInProgress,
            setPageMode,
        },
        dispatch,
    );
}
