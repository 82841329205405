import * as React from 'react';
import * as lodash from 'lodash';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { UserResponseParams } from 'sber-marketing-types/frontend';
import { BudgetItem, ActivityBudget } from '@mrm/budget';
import { Tag } from '@mrm/tags';

import { StoreState } from '@store';
import { User, getLoginUser } from '@store/user';
import { getTagsState } from '@store/tags';
import {
    ColumnName,
    TableLine,
    getBudgetExecutionPageState,
    makeLine,
    getCellValue,
    setTableLines,
} from '@store/budgetExecution';

interface Props extends Partial<MapProps & DispatchProps> {}

interface MapProps {
    loginUser: User;
    activityBudgets: ActivityBudget[];
    budgetItems: BudgetItem[];
    users: UserResponseParams[];
    tags: lodash.Dictionary<Tag>;
}

interface DispatchProps {
    setTableLines: (tableLines: TableLine[]) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class TableLinesEffect extends React.PureComponent<Props> {
    public componentDidUpdate(): void {
        this.updateTableLines();
    }

    public render(): JSX.Element {
        // component used only for side-effect
        return null;
    }

    private updateTableLines(): void {
        const { activityBudgets, budgetItems, users, tags, loginUser } = this.props;

        let updLines: TableLine[];
        if (!activityBudgets.length || !budgetItems.length || !users) {
            updLines = [];
        } else {
            updLines = lodash.sortBy(
                budgetItems.map((budgetItem) => makeLine(budgetItem, activityBudgets, users, tags, loginUser)),
                (line) => [getCellValue(line, ColumnName.ActivityName), getCellValue(line, ColumnName.Id)],
            );
        }

        this.props.setTableLines(updLines);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const {
        pageData: { activityBudgets, users },
        computedData: { pageBudgetItems },
    } = getBudgetExecutionPageState(state);

    const loginUser = getLoginUser(state);
    const tags = getTagsState(state).byId.dictionary;

    return {
        activityBudgets,
        budgetItems: pageBudgetItems,
        users,
        loginUser,
        tags,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        setTableLines: (tableLines: TableLine[]) => dispatch(setTableLines(tableLines)),
    };
}
