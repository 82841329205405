import * as React from 'react';
import { CreativeRequest, CreativeRequestParam } from '@api';

// const CREATIVE_REQUEST_ID = 'a00071b8-edd2-4ea3-b217-7e3f769e6c17';

// function useMrmClient() {
//     const [ client, setClient ] = React.useState<Client<'creativeRequests'>>(null);

//     React.useEffect(() => {
//         async function initFetch() {
//             setClient(await MrmClient.getInstance());
//         }

//         initFetch();
//     }, []);

//     return client;
// }

// function useCreativeRequest(id: string) {
//     const [ creativeRequest, setCreativeRequest ] = React.useState<CreativeRequest>(null);

//     const client = useMrmClient();

//     React.useEffect(() => {
//         async function initFecth() {
//             setCreativeRequest(await client.domain.creativeRequests.getCreativeRequest({ id }));
//         }

//         if (client) {
//             initFecth();
//         }
//     }, [ client ]);

//     return creativeRequest;
// };

export function useCreativeRequestData<T>(
    creativeRequest: CreativeRequest,
    fetcher: (creativeRequest: CreativeRequest) => Promise<T>,
    initListeners?: (creativeRequest: CreativeRequest, setData: React.Dispatch<React.SetStateAction<T>>) => void,
    removeListeners?: (creativeRequest: CreativeRequest, setData: React.Dispatch<React.SetStateAction<T>>) => void,
) {
    const [data, setData] = React.useState<T>(null);

    React.useEffect(
        function fetchAndInitListeners() {
            async function worker() {
                if (creativeRequest) {
                    setData(await fetcher(creativeRequest));

                    initListeners?.(creativeRequest, setData);
                }
            }

            worker();

            return function resetListeners() {
                if (creativeRequest) {
                    removeListeners?.(creativeRequest, setData);
                }
            };
        },
        [creativeRequest],
    );

    return data;
}

export function useParamField<T>(
    param: CreativeRequestParam,
    fetcher: () => Promise<T>,
    initListeners: (setData: (data: T) => void) => void,
    removeListeners: (setData: (data: T) => void) => void,
) {
    const [data, setData] = React.useState<T>(null);

    React.useEffect(
        function fetchAndInitListeners() {
            async function worker() {
                if (param) {
                    setData(await fetcher());

                    initListeners(setData);
                }
            }

            worker();

            return function resetListeners() {
                if (param) {
                    removeListeners(setData);
                }
            };
        },
        [param],
    );

    return data;
}
