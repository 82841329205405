import * as React from 'react';
import autobind from 'autobind-decorator';

import { NavItemDropdown } from './NavItemDropdown';

interface Props {
    title: string;
    isOpen: boolean;
    disabled: boolean;
    onClick: () => void;
}

interface State {
    isOpen: boolean;
}

export class NavItemDropdownContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isOpen: (props.isOpen && !props.disabled) || false,
        };
    }

    public render() {
        const { title, children } = this.props;
        const { isOpen } = this.state;

        const { onClick, ...restProps } = this.props;

        return React.createElement(NavItemDropdown, {
            ...restProps,
            children,
            title,
            isOpen,
            onTitleClick: this.onTitleClick,
            onArrowClick: this.onArrowClick,
        });
    }

    @autobind
    private onTitleClick(): void {
        if (!this.props.disabled) {
            this.setState(({ isOpen }) => ({ isOpen: !this.props.isOpen || !isOpen }));
        }

        this.props.onClick();
    }

    @autobind
    private onArrowClick(): void {
        this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
    }
}
