import * as React from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { uniq, orderBy } from 'lodash';
import { UserCard } from 'sber-marketing-types/frontend';
import { Icon, IconType, StaticSkeleton } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getAppUsers, loadUsers } from '@store/appUsers';

import { UsersDropdown, UserSelectStrategy } from '@common/UsersDropdown';

import * as styles from './UsersTab.scss';

export interface UserItem {
    id: number;
    role?: string;
    order?: number;
}

interface Props {
    qaId: string;
    showPreloader?: boolean;
    items: UserItem[];
    usersToUseStrategy: UserSelectStrategy;
    usersToRemoveStrategy?: UserSelectStrategy;
    updateUsers: (userIds: number[]) => void;
    showAddUsersButton: boolean;
    addUsersButtonTitle?: string;
}

function useUsersTab(items: UserItem[]) {
    const dispatch = useDispatch();

    const [usersModalVisibility, setUsersModalVisibility] = React.useState(false);

    const selectedUsers = React.useMemo(() => uniq(items.map((item) => item.id)), [items]);

    React.useEffect(() => {
        dispatch(loadUsers(null));
    }, []);

    return {
        usersModalVisibility,
        setUsersModalVisibility,
        selectedUsers,
    };
}

export function UsersTab({
    qaId,
    showPreloader,
    items,
    usersToUseStrategy,
    usersToRemoveStrategy,
    updateUsers,
    addUsersButtonTitle = 'Изменить участников',
    showAddUsersButton,
}: Props): JSX.Element {
    const { usersModalVisibility, setUsersModalVisibility, selectedUsers } = useUsersTab(items);

    return (
        <div
            {...{
                'qa-id': qaId,
            }}
        >
            {!showPreloader && showAddUsersButton && (
                <AddUsersButton title={addUsersButtonTitle} onClick={() => setUsersModalVisibility(true)} />
            )}

            <StaticUsersTab showPreloader={showPreloader} items={items} />

            {usersModalVisibility && (
                <UsersDropdown
                    selectedUsers={selectedUsers}
                    usersToUseStrategy={usersToUseStrategy}
                    usersToRemoveStrategy={usersToRemoveStrategy}
                    updateSelectedUsers={updateUsers}
                    onClose={() => setUsersModalVisibility(false)}
                />
            )}
        </div>
    );
}

interface StaticUsersTabProps {
    items: UserItem[];
    showPreloader: boolean;
}

export function StaticUsersTab({ items, showPreloader }: StaticUsersTabProps): JSX.Element {
    const appUsers = useSelector((state: StoreState) => getAppUsers(state).entities);

    if (showPreloader) {
        return <Preloader />;
    }

    const sortedItems = orderBy(
        items.map((item) => ({
            ...item,
            user: appUsers[item.id],
        })),
        [(item) => item.order || 0, (item) => `${item.user.firstName} ${item.user.secondName}`],
        ['desc', 'asc'],
    );

    return (
        <div className={styles.root}>
            {sortedItems.map((item) => (
                <User key={item.id} {...item} />
            ))}
        </div>
    );
}

interface AddUsersButtonProps {
    title: string;
    onClick: () => void;
}

export function AddUsersButton({ title, onClick }: AddUsersButtonProps): JSX.Element {
    return (
        <div
            onClick={onClick}
            className={styles.addUsersButton}
            {...{
                'qa-id': 'usersTabAddUsersButton',
            }}
        >
            <Icon type={IconType.CREATIVE_ADD_USER_BUTTON} svgSize={20} />

            {title}
        </div>
    );
}

interface UserProps extends UserItem {
    user: UserCard;
}

function User({ id, role, user }: UserProps): JSX.Element {
    return user ? (
        <div
            className={styles.user}
            {...{
                'qa-id': 'userItem',
            }}
        >
            <div className={styles.userFirstRow}>
                <div
                    className={styles.colorBlack}
                    {...{
                        'qa-id': 'userItemName',
                    }}
                >
                    {user.firstName} {user.secondName}
                </div>

                <div
                    className={styles.colorGrey}
                    {...{
                        'qa-id': 'userItemRole',
                    }}
                >
                    {role}
                </div>
            </div>

            <div className={classnames(styles.userDepartment, styles.colorGrey)}>{user.department}</div>
        </div>
    ) : null;
}

function Preloader(): JSX.Element {
    return (
        <div>
            <StaticSkeleton loadingClassName={styles.addUsersButtonPreloader} customStyle={{ borderRadius: '6px ' }} />

            <div className={styles.root}>
                <UserPreloader />
                <UserPreloader />
                <UserPreloader />
            </div>
        </div>
    );
}

function UserPreloader(): JSX.Element {
    return (
        <div>
            <div className={styles.userFirstRow}>
                <StaticSkeleton loadingClassName={styles.userFirstPreloder} />
                <StaticSkeleton loadingClassName={styles.userFirstPreloder} />
            </div>

            <StaticSkeleton loadingClassName={styles.userThirdPreloader} />
        </div>
    );
}
