import * as React from 'react';

import * as style from './Brief.scss';

import type { BudgetItem } from '@mrm/budget';
import type { BriefBlock, BriefScheme, Brief, UserResponseParams as User } from 'sber-marketing-types/frontend';
import type { Activity, Task } from '@store/brief/types';

import { StyledPopup } from 'sber-marketing-ui';
import { Block } from './Block';
import { Header } from './Header';
import { BriefSumDic } from './BriefSumDic';

interface Props {
    brief: Brief;
    activityId: number;
    organizationId: string;
    divisionId: string;
    departmentId: string;
    task: Task;
    activity: Activity;
    users: User[];
    budgetItem: BudgetItem;
    schemes: BriefScheme[];
    blocks: BriefBlock[];
    blocksRoot: BriefBlock[];
    editRight: boolean;
    isBriefReady: boolean;
    isLoading: boolean;
    isBriefPasteDisabled: boolean;
    isBriefCopyDisabled: boolean;
    isBriefExportDisabled: boolean;
    isPasteCopiedBriefModalOpened: boolean;
    isCalculated: boolean;
    onBriefCopy: () => void;
    onBriefPaste: () => void;
    onBriefExport: () => void;
    onBriefSchemeSelection: (schemeId: string) => void;
    onPasteCopiedBriefModalConfirmClick: () => void;
    onPasteCopiedBriefModalCancelClick: () => void;
    onStartFetchSum: () => Promise<void>;
    onFinishFetchSum: () => Promise<void>;
}

// Disabled shemIDs for "BriefSumDic"
const DisabledShemIDs: string[] = [
    '71087d3e-6aa8-4841-8e83-d02e1d2a814d', // ДУД: Бриф "Событийный проект 1 категории" (v. 1.4.2)
    '1447cacf-ee72-40b6-aee2-bacbc0d18bb9', // ДУД: Бриф "Событийный проект 2 категории" (v. 1.4.2)
    '1f06cf3e-bc68-4162-90c1-9c4d79b63470', // ДУД: Бриф "Событийный проект 3 категории" (v. 1.4.2)
];

export const BriefTemplate: React.FC<Props> = ({
    brief,
    task,
    activity,
    activityId,
    divisionId,
    departmentId,
    users,
    budgetItem,
    blocks,
    blocksRoot,
    editRight,
    isBriefReady,
    isLoading,
    isBriefPasteDisabled,
    isBriefCopyDisabled,
    isBriefExportDisabled,
    isPasteCopiedBriefModalOpened,
    isCalculated,
    onBriefCopy,
    onBriefPaste,
    onBriefExport,
    onBriefSchemeSelection,
    onPasteCopiedBriefModalConfirmClick,
    onPasteCopiedBriefModalCancelClick,
    onStartFetchSum,
    onFinishFetchSum,
}) => {
    const [briefCost, setBriefCost] = React.useState(null);

    React.useEffect(() => {
        setBriefCost(null);
    }, [brief.schemeId]);

    return (
        <div>
            <Header
                brief={brief}
                activity={activity}
                task={task}
                users={users}
                budgetItem={budgetItem}
                editRight={editRight}
                isLoading={isLoading}
                isBriefCopyDisabled={isBriefCopyDisabled}
                isBriefPasteDisabled={isBriefPasteDisabled}
                isBriefExportDisabled={isBriefExportDisabled}
                isBriefReady={isBriefReady}
                onBriefCopy={onBriefCopy}
                onBriefPaste={onBriefPaste}
                onBriefExport={onBriefExport}
                onSchemeSelection={onBriefSchemeSelection}
            />

            {!isLoading && blocks && (
                <div
                    className={style.blocks}
                    {...{
                        'qa-id': 'briefBlocks',
                    }}
                >
                    {blocksRoot.map((block, index) => (
                        <Block
                            key={`${block.id} ${index}`}
                            activityId={activityId}
                            divisionId={divisionId}
                            departmentId={departmentId}
                            briefId={brief.id}
                            blockId={block.id}
                            blockParentId={block.briefBlockId}
                            block={block}
                            name={block.name}
                            fields={block.fields}
                            blocks={blocks}
                            isRequired={block.isRequired}
                            isOriginal={block.isOriginal}
                            editRight={editRight}
                            briefCost={briefCost}
                        />
                    ))}

                    {isCalculated && blocks.length !== 0 && !DisabledShemIDs.includes(brief.schemeId) && (
                        <div className={style.briefSumDicWrapper}>
                            <BriefSumDic
                                briefId={brief.id}
                                setBriefCost={setBriefCost}
                                briefCost={briefCost}
                                onStartFetch={onStartFetchSum}
                                onFinishFetch={onFinishFetchSum}
                            />
                        </div>
                    )}
                </div>
            )}

            {isPasteCopiedBriefModalOpened && (
                <PasteCopiedBriefModal
                    onConfirmClick={onPasteCopiedBriefModalConfirmClick}
                    onCancelClick={onPasteCopiedBriefModalCancelClick}
                />
            )}
        </div>
    );
};

const BRIEF_COPY_MESSAGE =
    'Внимание! Уверены, что хотите вставить новый бриф? После подтверждения старый бриф будет удален.';

interface ChangeBriefSchemeConfigModalProps {
    onConfirmClick: () => void;
    onCancelClick: () => void;
}

const PasteCopiedBriefModal: React.FunctionComponent<ChangeBriefSchemeConfigModalProps> = ({
    onConfirmClick,
    onCancelClick,
}: ChangeBriefSchemeConfigModalProps) => (
    <StyledPopup
        title={'Бриф уже существует'}
        fullMessage={BRIEF_COPY_MESSAGE}
        firstButtonText={'Изменить'}
        firstButtonHandler={onConfirmClick}
        secondButtonText={'Отмена'}
        secondButtonHandler={onCancelClick}
    />
);
