import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { Dispatch, AnyAction } from 'redux';
import { UserVacation } from '@mrm/user-vacation';

import { UserVacationApi } from '@api';

import { StoreState } from '@store';
import { loadUsers } from '@store/appUsers';
import { getLoginUser } from '@store/user';
import { LoadingStatus } from '@store/commonTypes';
import { ComponentState, checkLoadingStatus, setComponentState } from '@store/profileMenu';

import * as asyncActions from './actions/async';
import * as actions from './actions/sync';

import { Notifier } from './notifier';
import { getProfileMenuVacationState } from './selectors';

function makeInitialUserVacation(userId: number): UserVacation {
    return {
        userId,
        start: null,
        end: null,
        comment: '',
    };
}

async function withLoadingStatus(dispatch: Dispatch<AnyAction>, request: () => Promise<void>): Promise<void> {
    dispatch(actions.setLoadingStatus(LoadingStatus.LOADING));

    try {
        await request();
    } catch (e) {
        dispatch(actions.setLoadingStatus(LoadingStatus.ERROR));
        throw e;
    }

    dispatch(actions.setLoadingStatus(LoadingStatus.LOADED));
}
async function withRequestInProgress(dispatch: Dispatch<AnyAction>, request: () => Promise<void>): Promise<void> {
    dispatch(actions.setRequestInProgress(true));
    await request();
    dispatch(actions.setRequestInProgress(false));
}

export const loadVacationData = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.loadVacationData,
    async (_, dispatch, getState) => {
        await withLoadingStatus(dispatch, async () => {
            const userId = getLoginUser(getState()).attributes.id;

            const existingVacation = await UserVacationApi.getUserVacation(userId);
            if (existingVacation) {
                existingVacation.start = new Date(existingVacation.start);
                existingVacation.end = new Date(existingVacation.end);
            }
            dispatch(actions.setExistingVacation(existingVacation));

            const pendingVacation = existingVacation || makeInitialUserVacation(userId);
            dispatch(actions.setPendingVacation(pendingVacation));
        });

        dispatch(checkLoadingStatus(null));
    },
);

export const saveUserVacation = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.saveUserVacation,
    async (_, dispatch, getState) => {
        await withRequestInProgress(dispatch, async () => {
            const pendingVacation = getProfileMenuVacationState(getState()).pendingVacation;

            await UserVacationApi.addUserVacation(pendingVacation);
            dispatch(actions.setExistingVacation({ ...pendingVacation }));
            dispatch(setComponentState(ComponentState.Menu));
            dispatch(loadUsers(null));
            Notifier.notifyAboutVacationSaved(pendingVacation.start, pendingVacation.end);
        });
    },
);

export const deleteUserVacation = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.deleteUserVacation,
    async (_, dispatch, getState) => {
        await withRequestInProgress(dispatch, async () => {
            const userId = getLoginUser(getState()).attributes.id;

            await UserVacationApi.deleteUserVacation(userId);
            dispatch(actions.setExistingVacation(null));
            dispatch(actions.setPendingVacation(makeInitialUserVacation(userId)));
            dispatch(setComponentState(ComponentState.Menu));
            dispatch(loadUsers(null));
            Notifier.notifyAboutVacationDeletion();
        });
    },
);
