import { Dictionary } from 'lodash';

import {
    DepartmentAttributes as DepartmentResponse,
    UserResponseParams as UserResponse,
} from 'sber-marketing-types/frontend';

import type {
    GetTasksDataForLeaderDashboardRequestDto as TasksDataRequest,
    GetTaskDataForLeaderDashboardResponseDto as TasksDataResponse,
    TaskDataForLeaderDashboardDto as TaskDadaResponseItem,
} from '@mrm-analytics-service/api-client';

import {
    LeaderDashboardDataFilterPeriod as FilterPeriod,
    LeaderDashboardDataFilterBy as DataFilterBy,
} from '@mrm-analytics-service/api-client';

export type StatisticsResponse = TasksDataResponse;
export type StatisticsItemData = TaskDadaResponseItem;

export interface PageState {
    data: PageData;
    selectedStatisticsItemId: string;
    selectedPeriod: FilterPeriod;
    selectedBy: DataFilterBy;
    selectedDate: string;
    statisticsTypeFilters: Record<StatisticsType, boolean>;
    filters: Filters;
    statisticsItems: StatisticsItem[];
    loading: boolean;
}

export const enum StatisticsType {
    COMING_DEADLINE = 'coming_deadline',
    OVERDUE = 'overdue',
    ACTIVE = 'active',
    CLOSED = 'closed',
}

export interface StatisticsItem {
    id: string;
    current: DepartmentStatisticsItem;
    past: DepartmentStatisticsItem;
    meta: StatisticsItemMeta;
}

export interface StatisticsItemMeta {
    name: string;
    type: 'department' | 'usersGroup' | 'user';
    parentId: string;
    childrenIds: string[];
}

export type Filters = Dictionary<boolean>;

export interface PageData {
    departments: DepartmentResponse[];
    statistics: StatisticsResponse;
    users: UserResponse[];
}

export interface Department extends DepartmentResponse {
    childrenDepartmentsIds: string[];
}

export interface TreeDepartment extends Department {
    children: TreeDepartment[];
}

export interface SetFilterPeriodPayload {
    filterPeriod: FilterPeriod;
}

export interface SetFilterPeriodResult extends SetFilterPeriodPayload {
    data: PageData;
}

export interface SetFilterDatePayload {
    filterDate: string;
}

export interface SetFilterDateResult extends SetFilterDatePayload {
    data: PageData;
}

export interface SetFilterByPayload {
    by: DataFilterBy;
}

export interface SetFilterByResult extends SetFilterByPayload {
    data: PageData;
}

export { FilterPeriod, DataFilterBy };

export interface DepartmentStatistics {
    id: string;
    name: string;
    statistics: {
        current: DepartmentStatisticsItem;
        past: DepartmentStatisticsItem;
    };
}

export interface CommonStatistics {
    current: CommonStatisticItem;
    past: CommonStatisticItem;
}

export interface CommonStatisticItem
    extends Pick<
        StatisticsItemData,
        'totalCount' | 'activeCount' | 'closedCount' | 'overdueCount' | 'withDeadlineApproachingCount'
    > {}

export type DepartmentStatisticsItem = CommonStatisticItem;

export type { TasksDataRequest, TasksDataResponse, DepartmentResponse, UserResponse };
