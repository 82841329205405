import { useSelector } from 'react-redux';
import * as ExcelJS from 'exceljs';

import type { StoreState } from '@store';

import { getAutopilot, getActivity, getDictionaries } from '@store/autopilotDOOH/selectors';

export const useHooks = () => {
    const autopilot = useSelector((state: StoreState) => getAutopilot(state));
    const activity = useSelector((state: StoreState) => getActivity(state));
    const dictionaries = useSelector((state: StoreState) => getDictionaries(state));
    const columnKeys = {
        empty: 'empty',
        id: 'id',
        region: 'region',
        sideQuantity: 'sideQuantity',
        format: 'format',
        budget: 'budget',
    };
    const green = 'FF52C55D';
    const grey = 'FFEAE8EF';

    async function download() {
        const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const blob = new Blob([await getXLSX()], { type: mimeType });

        const downloadLink = document.createElement('a');
        const link = URL.createObjectURL(blob);
        downloadLink.href = link;
        downloadLink.download = `${activity.name}.xlsx`;
        downloadLink.target = '_blank';
        downloadLink.click();
        URL.revokeObjectURL(link);
    }

    return {
        download,
    };

    function getXLSX() {
        const workbook = new ExcelJS.Workbook();
        styleWorkBook(workbook);

        const sheet = workbook.addWorksheet('Тотал', { views: [{ state: 'frozen' }] });
        styleSheetColumns(sheet);

        const blockId = autopilot?.brief?.block || '';
        const productId = autopilot?.brief?.product || '';
        const dateStart = autopilot?.brief?.dateStart || '';
        const dateEnd = autopilot?.brief?.dateEnd || '';
        const budgetNet = autopilot?.mediaplan?.total?.budget?.net || 0;
        const byRegionFormat = autopilot?.mediaplan?.byRegionFormat || [];

        sheet.addRows([
            ['', 'Агентство:', 'ООО "С-Маркетинг"'],
            ['', 'Рекламодатель:', 'ПАО'],
            ['', 'Блок:', `${dictionaries['block'].find(({ id }) => id === blockId)?.value || ''}`],
            ['', 'ID  бюджета:', 'ПАО'],
            ['', 'Продукт:', `${dictionaries['product'].find(({ id }) => id === productId)?.value || ''}`],
            ['', 'Название кампании:', `${activity.name}`],
            ['', '', 'МЕДИАПЛАН СОЗДАН АВТОМАТИЧЕСКИ'],
            ['', 'Тип РК:', 'Эстимация, охватная'],
            ['', 'Медиа:', 'ООН'],
            ['', 'Канал коммуникации:', 'ООН'],
            ['', 'География:', 'Россия'],
            ['', 'Начало размещения:', `${dateStart}`],
            ['', 'Окончание размещения:', `${dateEnd}`],
            ['', 'Целевая аудитория:', 'All 18+'],
            ['', 'Бюджет до НДС:', Math.round(budgetNet)],
            [],
            [],
        ]);
        styleInfo(sheet);

        const headerRow = sheet.addRow([]);
        const data = ['№', 'Город', 'Кол-во сторон', 'Форматы', 'Стоимость размещения до НДС'];
        sheet.columns.forEach((column, i) => i !== 0 && (headerRow.getCell(column.key).value = data[i - 1]));
        styleHeaderRow(headerRow);

        byRegionFormat.forEach((row, index) => {
            const lastRow = sheet.addRow([]);
            const { region, format, sideQuantity, budget } = row;

            sheet.columns.forEach((_, i) => {
                if (i === 0) return;
                lastRow.getCell(columnKeys.id).value = index + 1;
                lastRow.getCell(columnKeys.region).value = region.name;
                lastRow.getCell(columnKeys.sideQuantity).value = sideQuantity;
                lastRow.getCell(columnKeys.format).value = format.abbreviation;
                lastRow.getCell(columnKeys.budget).value = Math.round(budget.net);
            });
            styleDataRow(lastRow);
        });

        sheet.addRow([]);
        const totalRow = sheet.addRow({
            [columnKeys.format]: 'Итого, Net',
            [columnKeys.budget]: `${Math.round(budgetNet)}`,
        });
        styleTotalRow(totalRow);

        return workbook.xlsx.writeBuffer();
    }

    function styleWorkBook(workbook: ExcelJS.Workbook) {
        const date = new Date();
        workbook.creator = 'ООО "С-Маркетинг"';
        workbook.lastModifiedBy = 'ООО "С-Маркетинг"';
        workbook.created = date;
        workbook.modified = date;
    }

    function styleSheetColumns(sheet: ExcelJS.Worksheet) {
        sheet.columns = [
            { key: columnKeys.empty, width: 5 },
            {
                key: columnKeys.id,
                width: 20,
                style: {
                    font: { bold: true },
                    alignment: { horizontal: 'right' },
                },
            },
            {
                key: columnKeys.region,
                width: 35,
                style: {
                    alignment: { horizontal: 'left' },
                },
            },
            { key: columnKeys.sideQuantity, width: 20 },
            { key: columnKeys.format, width: 20 },
            { key: columnKeys.budget, width: 20 },
        ];
    }

    function styleInfo(sheet: ExcelJS.Worksheet) {
        [...Array(13).keys()]
            .map((i) => `C${i + 2}`)
            .forEach((i) => (sheet.getCell(i).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: grey } }));
        sheet.getCell('C7').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: green } };
        sheet.getCell('C7').font = { color: { argb: 'FFFFFFFF' } };
    }

    function styleHeaderRow(row: ExcelJS.Row) {
        row.height = 70;
        row.eachCell((cell) => {
            cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: green } };
            cell.font = { color: { argb: 'FFFFFFFF' }, bold: true };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            };
        });
    }

    function styleDataRow(row: ExcelJS.Row) {
        row.eachCell((cell) => {
            cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
            cell.font = { color: { argb: 'FF000000' }, bold: false };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            };
        });
    }

    function styleTotalRow(row: ExcelJS.Row) {
        row.getCell(columnKeys.format).border = {
            top: { style: 'thick' },
            left: { style: 'thick' },
            bottom: { style: 'thick' },
        };
        row.getCell(columnKeys.budget).border = {
            top: { style: 'thick' },
            bottom: { style: 'thick' },
            right: { style: 'thick' },
        };
    }
};
