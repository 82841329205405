import * as React from 'react';
import classNames from 'classnames';
import * as lodash from 'lodash';

import * as style from './WeekDigest.scss';

import type { ActivityCardsGroup } from '@store/calendar/types';

import { ActivitiesInfo } from './ActivitiesInfo';
import { TopMenu } from './TopMenu';
import { ActivityGroup } from './ActivityGroup';

interface Props {
    activityCardGroups: ActivityCardsGroup[];
    displayOrganizationName: boolean;
}

export function WeekDigest({ activityCardGroups, displayOrganizationName }: Props): JSX.Element {
    const hasExpiredStages = activityCardGroups.some((group) =>
        group.activities.some((item) => item.stagesInfo && item.stagesInfo.hasExpiredStages),
    );

    const organizationNames = lodash.uniq(activityCardGroups.map((item) => item.organizationName));
    const activityCardGroupsByOrganization = lodash.groupBy(activityCardGroups, (item) => item.organizationName);

    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'weekDigest',
            }}
        >
            <div className={style.fixedMenus}>
                <div className={style.activitiesInfo}>
                    <ActivitiesInfo />
                </div>

                <div className={style.topMenu} id={'week-digest-top-menu'}>
                    <TopMenu showExpiredStagesIndicator={hasExpiredStages} />
                </div>
            </div>

            <div
                className={classNames(style.activityGroups, displayOrganizationName && style.displayOrganizationName)}
                id={'week-digest-chart'}
            >
                {organizationNames.map((organizationName) => (
                    <React.Fragment key={organizationName}>
                        {displayOrganizationName && <div className={style.organizationName}>{organizationName}</div>}

                        {activityCardGroupsByOrganization[organizationName].map((item) => (
                            <div
                                className={classNames(
                                    style.activityGroup,
                                    lodash.last(activityCardGroupsByOrganization[organizationName]) == item &&
                                        style.lastGroupInOrganization,
                                )}
                                key={item.id}
                            >
                                <ActivityGroup cardsGroup={item} />
                            </div>
                        ))}
                    </React.Fragment>
                ))}

                {lodash.isEmpty(activityCardGroups) && (
                    <div
                        className={style.noActivityGroupsMessage}
                        {...{
                            'qa-id': 'weekDigestNoActivitiesMarker',
                        }}
                    >
                        Активностей не найдено
                    </div>
                )}
            </div>
        </div>
    );
}
