import * as React from 'react';
import { CustomScrollbar_new as CustomScrollbar } from 'sber-marketing-ui';

import * as styles from './WithScrollbar.scss';

interface Props {
    children: JSX.Element | JSX.Element[];
}

export function WithScrollbar({ children }: Props): JSX.Element {
    return (
        <CustomScrollbar>
            <div className={styles.content}>{children}</div>
        </CustomScrollbar>
    );
}
