import { createSelector } from 'reselect';
import { Module, RoleId } from 'sber-marketing-types/backend';
import type { DepartmentAttributes } from 'sber-marketing-types/frontend';
import { PlanPermissionsSysNames, RightsGroups } from 'sber-marketing-types/frontend';

import { StoreState } from '@store';
import { User, UserState } from '../types';

const getUser = (state: StoreState): User => state.user;

export const getLoginUser = createSelector(getUser, (user: User): User => {
    return user;
});

export const getUserPermissions = createSelector(getUser, (user: User): string[] => {
    return user.permissions;
});

export const getBriefRight = createSelector(getUser, (user: User): string[] => {
    const reg = new RegExp(`${RightsGroups.Brief}_`);

    return user.permissions.filter((permission) => reg.test(permission));
});

export const getTaskRight = createSelector(getUser, (user: User): string[] => {
    const reg = new RegExp(`${RightsGroups.Task}_`);

    return user.permissions.filter((permission) => reg.test(permission));
});

export const getUserGroups = createSelector(getUser, (state: UserState): DepartmentAttributes[] => {
    return state.userGroups;
});

export const getUserExecutorGroupId = createSelector(getUser, (state: UserState): string => {
    return state.attributes.departmentId;
});

export const getUserOrganizationId = createSelector(getUser, (user: User): string => {
    return user.attributes.organizationId;
});

export const getUserId = createSelector(getUser, (user: User): number => {
    return user.attributes.id;
});

export const isLoggedUserBudgetExpert = createSelector(getUser, (user: User): boolean =>
    user.attributes.permissions.includes(PlanPermissionsSysNames.OrganizationAccess),
);

export const userHasBudgetAccess = createSelector(getUser, (user: User): boolean =>
    user.attributes.modules.includes(Module.Budget),
);

// work as a function to allow usage in non-mapStateToProps functions
export const userHasPersonalDashboardAccess = (user: User): boolean => {
    const { attributes } = user;
    const roles = attributes ? attributes.roles : [];
    const userHasBudgetAccess = roles.some(
        (role) =>
            role.id === RoleId.BudgetBrandManager ||
            role.id === RoleId.BudgetSupervisor ||
            role.id === RoleId.BudgetExpert ||
            role.id === RoleId.BusinessPartnerDirection ||
            role.id === RoleId.BusinessPartnerDivision ||
            role.id > RoleId.BudgetCurator,
    );

    return userHasBudgetAccess;
};

export const userHasLeaderDashboardAccess = (user: User): boolean => {
    const { attributes } = user;
    const roles = attributes ? attributes.roles : [];

    return roles.some((role) => role.id === RoleId.Supervisor || role.id === RoleId.Director);
};

export const userHasPivotTableAccess = (user: User): boolean => {
    const { attributes } = user;
    const roles = attributes ? attributes.roles : [];

    const permissibleRoles = [
        RoleId.CreativeRequestExecutor,
        RoleId.CreativeRequestExpert,
        RoleId.CreativeRequestActCloser,
        RoleId.PivotTableAccess,
    ];

    return roles.some((role) => permissibleRoles.includes(role.id));
};
