// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-pages-TaskPage-_TaskPage__content{display:flex;width:100%;align-items:flex-start;justify-content:flex-start}.src-client-modules-activity-pages-task-pages-TaskPage-_TaskPage__task{width:calc(100% - 385px);margin-left:48px}.src-client-modules-activity-pages-task-pages-TaskPage-_TaskPage__taskWrap{width:100%}.src-client-modules-activity-pages-task-pages-TaskPage-_TaskPage__sidebar{width:337px}.src-client-modules-activity-pages-task-pages-TaskPage-_TaskPage__content,.src-client-modules-activity-pages-task-pages-TaskPage-_TaskPage__task,.src-client-modules-activity-pages-task-pages-TaskPage-_TaskPage__taskWrap,.src-client-modules-activity-pages-task-pages-TaskPage-_TaskPage__sidebar{height:calc(100vh - 98px)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/pages/TaskPage/TaskPage.scss"],"names":[],"mappings":"AAAA,0EACI,YAAa,CAEb,UAAW,CAEX,sBAAuB,CACvB,0BAA2B,CAC9B,uEAGG,wBAAyB,CACzB,gBAAiB,CACpB,2EAGG,UAAW,CACd,0EAGG,WAAY,CACf,sSAMG,yBAA0B","sourcesContent":[".content {\n    display: flex;\n\n    width: 100%;\n\n    align-items: flex-start;\n    justify-content: flex-start;\n}\n\n.task {\n    width: calc(100% - 385px);\n    margin-left: 48px;\n}\n\n.taskWrap {\n    width: 100%;\n}\n\n.sidebar {\n    width: 337px;\n}\n\n.content,\n.task,\n.taskWrap,\n.sidebar {\n    height: calc(100vh - 98px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "src-client-modules-activity-pages-task-pages-TaskPage-_TaskPage__content",
	"task": "src-client-modules-activity-pages-task-pages-TaskPage-_TaskPage__task",
	"taskWrap": "src-client-modules-activity-pages-task-pages-TaskPage-_TaskPage__taskWrap",
	"sidebar": "src-client-modules-activity-pages-task-pages-TaskPage-_TaskPage__sidebar"
};
export default ___CSS_LOADER_EXPORT___;
