import * as React from 'react';
import { LabeledTextarea } from 'sber-marketing-ui';

interface Props {
    value: string;
    errorMessage?: string;
    displayError: boolean;
    onValueChange: (value: string) => void;
    onFocus: () => void;
    onBlur: () => void;
}

export const Comment = ({ value, errorMessage, displayError, onValueChange, onFocus, onBlur }: Props): JSX.Element => (
    <div>
        <LabeledTextarea
            title="Причина отклонения"
            errorMessage={errorMessage}
            displayError={displayError}
            maxRowsCount={5}
            value={value}
            onValueChange={onValueChange}
            onFocus={onFocus}
            onBlur={onBlur}
        />
    </div>
);
