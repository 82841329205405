import * as React from 'react';
import classnames from 'classnames';
import { Icon, IconType, WithGlobalPopup, AbsoluteLikePositionCalculator } from 'sber-marketing-ui';

import * as styles from './StagesSelector.scss';

export interface StageParams {
    id: string;
    name: string;
}

interface Props {
    stages: StageParams[];
    selectedStageId: string;
    selectStage(stageId: string): void;
}

export function StageSelector(props: Props): JSX.Element {
    const { stages, selectedStageId } = props;
    const selectedStage = stages.find((stage) => stage.id === selectedStageId);

    const openerRef = React.useRef();
    const [isEditorOpened, setIsEditorOpened] = React.useState(false);

    return (
        <div className={styles.root}>
            Привязать к этапу:&nbsp;
            <div
                onClick={() => setIsEditorOpened(!isEditorOpened)}
                className={styles.opener}
                ref={openerRef}
                {...{
                    'qa-id': 'stagesCalendarSidebarAddTaskStagesSelector',
                }}
            >
                {selectedStage?.name || 'Этап не найден'}

                <Icon
                    type={IconType.ARROW8_DOWN}
                    svgSize={8}
                    className={classnames(styles.openerIcon, isEditorOpened && styles.openerIconRotated)}
                />

                {isEditorOpened && (
                    <WithGlobalPopup
                        container={openerRef}
                        positionCalculator={AbsoluteLikePositionCalculator}
                        left={0}
                        bottom={0}
                    >
                        <Editor {...props} />
                    </WithGlobalPopup>
                )}
            </div>
        </div>
    );
}

function Editor({ stages, selectedStageId, selectStage }: Props): JSX.Element {
    return (
        <div className={styles.editor}>
            <div className={styles.editorTitle}>Перененсти в этап</div>

            <EditorItem id={null} name="Без этапа" onClick={selectStage} />

            <div className={styles.editorHorizontalBar} />

            {stages.map((stage) => (stage.id ? <EditorItem key={stage.id} {...stage} onClick={selectStage} /> : null))}
        </div>
    );
}

interface EditorItemProps extends StageParams {
    onClick(stageId: string): void;
}

function EditorItem({ id, name, onClick }: EditorItemProps): JSX.Element {
    return (
        <div
            className={styles.item}
            onClick={() => onClick(id)}
            {...{
                'qa-id': 'stagesCalendarSidebarAddTaskStagesSelectorItem',
                'qa-label': name,
            }}
        >
            {name}
        </div>
    );
}
