// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-task-components-tags-TaskExecutor-_TaskExecutor__root{text-align:left;animation:none;position:static}.src-client-modules-task-components-tags-TaskExecutor-_TaskExecutor__icon{color:var(--color-primary-50)}.src-client-modules-task-components-tags-TaskExecutor-_TaskExecutor__department{color:var(--color-primary-50)}.src-client-modules-task-components-tags-TaskExecutor-_TaskExecutor__dropdown{padding:0}.src-client-modules-task-components-tags-TaskExecutor-_TaskExecutor__header{font-size:16px;font-weight:600}\n", "",{"version":3,"sources":["webpack://./src/client/modules/task/components/tags/TaskExecutor/TaskExecutor.scss"],"names":[],"mappings":"AAAA,0EACE,eAAgB,CAChB,cAAe,CACf,eAAgB,CACjB,0EAGC,6BAA8B,CAC/B,gFAGC,6BAA8B,CAC/B,8EAGC,SAAU,CACX,4EAGC,cAAe,CACf,eAAgB","sourcesContent":[".root {\n  text-align: left;\n  animation: none;\n  position: static;\n}\n\n.icon {\n  color: var(--color-primary-50);\n}\n\n.department {\n  color: var(--color-primary-50);\n}\n\n.dropdown {\n  padding: 0;\n}\n\n.header {\n  font-size: 16px;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-task-components-tags-TaskExecutor-_TaskExecutor__root",
	"icon": "src-client-modules-task-components-tags-TaskExecutor-_TaskExecutor__icon",
	"department": "src-client-modules-task-components-tags-TaskExecutor-_TaskExecutor__department",
	"dropdown": "src-client-modules-task-components-tags-TaskExecutor-_TaskExecutor__dropdown",
	"header": "src-client-modules-task-components-tags-TaskExecutor-_TaskExecutor__header"
};
export default ___CSS_LOADER_EXPORT___;
