import * as React from 'react';
import classNames from 'classnames';
import {
    Icon,
    IconType,
    LabeledInput as LabeledInput,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
} from 'sber-marketing-ui';

import * as styles from './RejectionCommentPopup.scss';

interface Props {
    closePopup: () => void;
    onRejectButtonClick: (reason: string) => void;
}

export function RejectionCommentPopup({ closePopup, onRejectButtonClick }: Props): JSX.Element {
    const [reason, setReason] = React.useState('');
    const [showBackground, setShowBackground] = React.useState(false);

    return (
        <div className={styles.root}>
            <div className={styles.titleRow}>
                <div className={styles.title}>Отклонить строку</div>

                <div
                    className={classNames(styles.closeButton, showBackground && styles.closeButtonBackground)}
                    onMouseEnter={() => setShowBackground(true)}
                    onMouseLeave={() => setShowBackground(false)}
                >
                    <Icon type={IconType.PROJECT_STAGED_CLOSE} svgSize={24} onClick={closePopup} />
                </div>
            </div>

            <div className={styles.contentRow}>
                <div className={styles.inputWrapper}>
                    <LabeledInput
                        title="Причина отклонения"
                        className={styles.input}
                        value={reason}
                        placeholder="Введите комментарий"
                        onValueChange={(value) => setReason(value as string)}
                    />
                </div>

                <div className={styles.sendButtonWrapper}>
                    <Button
                        disabled={!reason}
                        theme={ButtonTheme.GhostRoundedBlack}
                        onClick={() => {
                            closePopup();
                            onRejectButtonClick(reason);
                        }}
                    >
                        <div className={styles.sendButton}>Отправить</div>
                    </Button>
                </div>
            </div>
        </div>
    );
}
