// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-FeaturesSelector-List-_styles__root{display:grid;grid-auto-rows:auto;grid-row-gap:8px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/CreationForm/ParamsEditor/FeaturesSelector/List/styles.scss"],"names":[],"mappings":"AAAA,2IACI,YAAa,CACb,mBAAoB,CACpB,gBAAiB","sourcesContent":[".root {\n    display: grid;\n    grid-auto-rows: auto;\n    grid-row-gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-FeaturesSelector-List-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
