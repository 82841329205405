import * as React from 'react';

interface UseOutsideClick {
    (params: UseOutsideClickParams): void;
}

interface UseOutsideClickParams {
    ref: React.MutableRefObject<HTMLDivElement>;
    onOutsideClick: () => void;
}

export const useOutsideClick: UseOutsideClick = ({ ref, onOutsideClick }) => {
    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            // @ts-ignore
            if (ref.current && !ref.current.contains(event.target)) {
                onOutsideClick();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, onOutsideClick]);
};
