// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-tags-TagsPage-Graph-Preloder-_Preloader__root{width:100%;height:100%}.src-client-modules-tags-TagsPage-Graph-Preloder-_Preloader__graph{transform:translate(212.034px, 126.499px) scale(1.43491)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/tags/TagsPage/Graph/Preloder/Preloader.scss"],"names":[],"mappings":"AAAA,kEACI,UAAW,CACX,WAAY,CACf,mEAGG,wDAAyD","sourcesContent":[".root {\n    width: 100%;\n    height: 100%;\n}\n\n.graph {\n    transform: translate(212.034px, 126.499px) scale(1.43491);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-tags-TagsPage-Graph-Preloder-_Preloader__root",
	"graph": "src-client-modules-tags-TagsPage-Graph-Preloder-_Preloader__graph"
};
export default ___CSS_LOADER_EXPORT___;
