import * as React from 'react';

import { Suggest, Icon, IconType } from 'sber-marketing-ui';

import { Props } from './types';

import * as styles from './TaskExecutorSuggest.scss';

export function TaskExecutorSuggest(props: Props): JSX.Element {
    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'taskExecutorSuggestMenu',
            }}
        >
            {props.showAddExecutorButton && (
                <div
                    {...{
                        'qa-id': 'taskExecutorSuggestToggleButton',
                    }}
                >
                    <Icon className={styles.button} type={IconType.EDIT} onClick={props.onChangeExecutorClick} />
                </div>
            )}

            <Suggest qaId="taskExecutorSuggest" {...(props as any)} />
        </div>
    );
}
