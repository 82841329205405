import * as React from 'react';
import * as Dotdotdot from 'react-dotdotdot';
import classnames from 'classnames';
import { TaskCardParams } from 'sber-marketing-types/frontend';
import {
    DynamicIcon,
    Icon,
    IconType,
    WithTooltip,
    TooltipAnchor,
    WithGlobalPopup,
    AddaptivePositionCalculator,
    AbsoluteLikePositionCalculator,
} from 'sber-marketing-ui';

import { Utils } from '@common/Utils';

import { LineParams, STAGE_COLORS, STAGE_TYPE, TasksByStageId } from '../types';
import { AddTaskPopup } from './AddTaskPopup';
import { StageTasksPopup } from './StagesTaskPopup';

import * as style from './Sidebar.scss';

interface Props {
    lines: LineParams[];
    hoveredLineIndex: number;
    editable: boolean;
    tasksByStageId: TasksByStageId;
    onLineHover: (lineIndex: number) => void;
    onLineCheckboxClick: (lineIndex: number) => void;
    setCanShowStageDuration: (canShowStageDuration: boolean) => void;
    onSelectStageClick?: (stageId: string) => void;
    createTaskByNameAndStage?: (taskName: string, stageId: string) => Promise<void>;
}

export function Sidebar({
    lines,
    hoveredLineIndex,
    editable,
    tasksByStageId,
    onLineHover,
    onLineCheckboxClick,
    setCanShowStageDuration,
    onSelectStageClick,
    createTaskByNameAndStage,
}: Props): JSX.Element {
    const expiredStagesCount = lines.filter((item) => item.type == STAGE_TYPE.EXPIRED).length;

    return (
        <div
            className={classnames(style.root, editable && style.editable)}
            {...{
                'qa-id': 'stagesCalendarSidebar',
            }}
        >
            <div
                className={style.expiredStagesCounter}
                {...{
                    'qa-id': 'stagesCalendarSidebarExpiredStagesCount',
                }}
            >
                {expiredStagesCount > 0 && (
                    <>
                        <div className={style.expiredStagesIcon}>
                            <Icon type={IconType.EXPIRED_TASK} svgSize={16} />
                        </div>

                        <div className={style.expiredStagesCount}>
                            {expiredStagesCount}
                            &nbsp;
                            {Utils.getDeclensionByNumber(expiredStagesCount, [
                                'этап просрочен',
                                'этапа просрочено',
                                'этапов просрочено',
                            ])}
                        </div>
                    </>
                )}
            </div>

            <div
                className={style.items}
                {...{
                    'qa-id': 'stagesCalendarSidebarLines',
                }}
            >
                {lines.map((line, lineIndex) => (
                    <Line
                        key={lineIndex}
                        line={line}
                        lines={lines}
                        lineIndex={lineIndex}
                        hoveredLineIndex={hoveredLineIndex}
                        editable={editable}
                        stageTasks={tasksByStageId[line.id] || []}
                        onLineHover={onLineHover}
                        onLineCheckboxClick={onLineCheckboxClick}
                        setCanShowStageDuration={setCanShowStageDuration}
                        onSelectStageClick={onSelectStageClick}
                        createTaskByNameAndStage={createTaskByNameAndStage}
                    />
                ))}
            </div>
        </div>
    );
}

interface LineProps {
    line: LineParams;
    lines: LineParams[];
    lineIndex: number;
    hoveredLineIndex: number;
    editable: boolean;
    stageTasks: TaskCardParams[];
    onLineHover: (lineIndex: number) => void;
    onLineCheckboxClick: (lineIndex: number) => void;
    setCanShowStageDuration: (canShowStageDuration: boolean) => void;
    onSelectStageClick?: (stageId: string) => void;
    createTaskByNameAndStage?: (taskName: string, stageId: string) => Promise<void>;
}

function useInteractivity(
    stageId: string,
    setCanShowStageDuration: (canShowStageDuration: boolean) => void,
    createTaskByNameAndStage?: (taskName: string, stageId: string) => void,
) {
    const itemLinkRef = React.useRef();
    const addTaskButtonRef = React.useRef();

    const [addTaskPopupVisibility, setAddTaskPopupVisibility] = React.useState(false);
    const [stageTasksPopupVisibility, stageTasksPopupVisibilitySetter] = React.useState(false);

    function setStageTasksPopupVisibility(isVisible: boolean) {
        stageTasksPopupVisibilitySetter(isVisible);
        setCanShowStageDuration(isVisible ? false : true);
    }

    async function createTask(taskName: string, stageId: string) {
        await createTaskByNameAndStage(taskName, stageId);
        setAddTaskPopupVisibility(false);
    }

    return {
        addTaskButtonRef,
        itemLinkRef,
        addTaskPopupVisibility,
        stageTasksPopupVisibility,
        setAddTaskPopupVisibility,
        setStageTasksPopupVisibility,
        createTask,
    };
}

function Line({
    line,
    lines,
    lineIndex,
    hoveredLineIndex,
    editable,
    stageTasks,
    onLineHover,
    onLineCheckboxClick,
    setCanShowStageDuration,
    onSelectStageClick,
    createTaskByNameAndStage,
}: LineProps): JSX.Element {
    const {
        addTaskButtonRef,
        itemLinkRef,
        addTaskPopupVisibility,
        stageTasksPopupVisibility,
        setAddTaskPopupVisibility,
        setStageTasksPopupVisibility,
        createTask,
    } = useInteractivity(line.id as string, setCanShowStageDuration, createTaskByNameAndStage);

    const lineIsHovered = lineIndex == hoveredLineIndex;
    const canSelectStage = !!stageTasks.length;

    let lineBackgroundColor: string;

    if (line.type) {
        lineBackgroundColor = lineIsHovered ? STAGE_COLORS[line.type].hover : STAGE_COLORS[line.type].normal;
    }

    let iconType: { normal: IconType; hover: IconType };
    let isChecked: boolean;

    switch (line.type) {
        case STAGE_TYPE.PLANNED:
        default:
            iconType = {
                normal: IconType.CHECKBOX16_UNCHECKED,
                hover: IconType.CHECKBOX16_UNCHECKED_HOVER,
            };
            isChecked = false;
            break;

        case STAGE_TYPE.ALMOST_EXPIRED:
            iconType = {
                normal: IconType.CHECKBOX16_UNCHECKED,
                hover: IconType.CHECKBOX16_UNCHECKED_HOVER,
            };
            isChecked = false;
            break;

        case STAGE_TYPE.EXPIRED:
            iconType = {
                normal: IconType.CHECKBOX16_UNCHECKED_RED,
                hover: IconType.CHECKBOX16_UNCHECKED_RED_HOVER,
            };
            isChecked = true;
            break;

        case STAGE_TYPE.FINISHED:
            iconType = {
                normal: IconType.CHECKBOX16_UNCHECKED_GREEN,
                hover: IconType.CHECKBOX16_UNCHECKED_GREEN_HOVER,
            };
            isChecked = true;
            break;
    }

    return (
        <div
            className={classnames(
                style.item,
                line.roundedTop && style.roundedTop,
                line.roundedBottom && style.roundedBottom,
            )}
            style={{ backgroundColor: lineBackgroundColor }}
            onMouseEnter={() => onLineHover(lineIndex)}
            onMouseLeave={() => onLineHover(null)}
            {...{
                'qa-id': 'stagesCalendarSidebarLine',
                'qa-label': line.name,
            }}
        >
            <div
                className={classnames(style.itemCheckbox, !line.id && style.transparent)}
                onClick={editable && line.id ? () => onLineCheckboxClick(lineIndex) : null}
                {...{
                    'qa-id': 'staegsCalendarLineCheckbox',
                    'qa-is-selected': isChecked ? 'true' : 'false',
                }}
            >
                <DynamicIcon
                    common={{ svgSize: 16 }}
                    normal={{ type: iconType.normal }}
                    hover={{ type: editable ? iconType.hover : iconType.normal }}
                />
            </div>

            <div className={style.itemTitle}>
                <Dotdotdot clamp={2}>{line.name}</Dotdotdot>

                <span
                    ref={itemLinkRef}
                    className={classnames(style.itemLink, canSelectStage && style.itemLinkActive)}
                    onClick={canSelectStage && onSelectStageClick ? () => onSelectStageClick(line.id as string) : null}
                    onMouseEnter={canSelectStage ? () => setStageTasksPopupVisibility(true) : null}
                    onMouseLeave={() => setStageTasksPopupVisibility(false)}
                    {...{
                        'qa-id': 'stagesCalendarSidebarLineTasksCountMarker',
                    }}
                >
                    {canSelectStage
                        ? `${stageTasks.length} ${Utils.getDeclensionByNumber(stageTasks.length, [
                              'задача',
                              'задачи',
                              'задач',
                          ])}`
                        : 'Нет задач'}

                    {stageTasksPopupVisibility && (
                        <WithGlobalPopup
                            withoutMask
                            container={itemLinkRef}
                            bottom={-8}
                            left={-30}
                            positionCalculator={AbsoluteLikePositionCalculator}
                        >
                            <StageTasksPopup
                                stageName={line.name}
                                stageStart={line.start}
                                stageEnd={line.end}
                                tasks={stageTasks || []}
                            />
                        </WithGlobalPopup>
                    )}
                </span>
            </div>

            <div
                className={classnames(!lineIsHovered && style.transparent)}
                ref={addTaskButtonRef}
                onMouseEnter={() => setCanShowStageDuration(false)}
                onMouseLeave={() => {
                    setCanShowStageDuration(true);
                    onLineHover(lineIndex);
                }}
                {...{
                    'qa-id': 'stagesCalendarSidebarLineAddTaskButton',
                }}
            >
                <WithTooltip anchor={TooltipAnchor.RIGHT} content="Добавить задачу">
                    <Icon
                        type={IconType.PLUS_SIGN}
                        svgSize={10}
                        className={style.addTaskButton}
                        onClick={() => setAddTaskPopupVisibility(!addTaskPopupVisibility)}
                    />
                </WithTooltip>
            </div>

            {addTaskPopupVisibility && (
                <WithGlobalPopup
                    container={addTaskButtonRef}
                    onMaskClick={() => setAddTaskPopupVisibility(false)}
                    positionCalculator={AddaptivePositionCalculator}
                >
                    <AddTaskPopup
                        stages={lines as { id: string; name: string }[]}
                        stageId={line.id as string}
                        createTask={createTask}
                    />
                </WithGlobalPopup>
            )}
        </div>
    );
}
