import * as React from 'react';
import classNames from 'classnames';

import * as styles from './RemoveFileAssetPopup.scss';

import { Popup, PopupTheme, Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';
import { FileAssetType } from '../FileAsset';

export interface RemoveFileAssetPopupProps {
    className?: string;
    type: FileAssetType | string;
    originName: string;
    onRemoveClick(): void;
    onCancelClick(): void;
}

const QUESTION = 'Вы действительно хотите удалить этот файл?';

const SUBMIT = 'Удалить';

const CANCEL = 'Отмена';

export const RemoveFileAssetPopup: React.FC<RemoveFileAssetPopupProps> = ({
    className,
    type,
    originName,
    onCancelClick,
    onRemoveClick,
}: RemoveFileAssetPopupProps): JSX.Element => (
    <Popup theme={PopupTheme.createTaskTheme} onOutOfContentClick={onCancelClick}>
        <div
            className={classNames(styles.root, className)}
            {...{
                'qa-id': 'removeFileAssetPopup',
            }}
        >
            <div className={styles.question}>{QUESTION}</div>

            <div className={styles.assetLink}>
                <div className={getAssetTypeClassNames(type)}>
                    <span>{type.toUpperCase().slice(0, 3)}</span>
                </div>

                <span className={styles.assetName}>{originName}</span>
            </div>

            <div className={styles.buttons}>
                <div className={styles.button}>
                    <Button qaId="removeFileAssetPopupDeleteButton" onClick={onRemoveClick} theme={ButtonTheme.Main}>
                        {SUBMIT}
                    </Button>
                </div>

                <div className={styles.button}>
                    <Button qaId="removeFileAssetPopupCloseButton" onClick={onCancelClick} theme={ButtonTheme.Ghost}>
                        {CANCEL}
                    </Button>
                </div>
            </div>
        </div>
    </Popup>
);

function getAssetTypeClassNames(type: FileAssetType | string): string {
    const assetTypeClassList: string[] = [styles.assetType];

    switch (type) {
        case FileAssetType.TXT: {
            assetTypeClassList.push(styles.assetTypeTxt);
            break;
        }
        case FileAssetType.DOC: {
            assetTypeClassList.push(styles.assetTypeDoc);
            break;
        }
        case FileAssetType.JPG: {
            assetTypeClassList.push(styles.assetTypeJpg);
            break;
        }
        case FileAssetType.XLS: {
            assetTypeClassList.push(styles.assetTypeXls);
            break;
        }
        case FileAssetType.PNG: {
            assetTypeClassList.push(styles.assetTypePng);
            break;
        }
        case FileAssetType.PDF:
        default: {
            assetTypeClassList.push(styles.assetTypePdf);
            break;
        }
    }

    return classNames(assetTypeClassList);
}
