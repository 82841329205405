import { useFormContext } from 'react-hook-form';

import * as React from 'react';

export function useFormValue<V>(name?: string, value?: V) {
    const form = useFormContext();
    const setFormValue = (value: any) => {
        if (name && form) {
            form.setValue(name, value);
            form.clearErrors(name);
        }
    };

    React.useEffect(() => {
        setFormValue(value);
    }, [value]);

    return setFormValue;
}
