import * as React from 'react';
import * as style from './TmTextCell.scss';

export interface TmTextCellProps {
    title: string;
}

export const TmTextCell = ({ title }: TmTextCellProps) => {
    return <div className={style.cell}>{title}</div>;
};
