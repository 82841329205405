// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-StagesCalendar-_StagesCalendar__root{display:flex;width:100%;padding-bottom:16px;border-radius:4px;background-color:#ffffff}.src-client-modules-common-StagesCalendar-_StagesCalendar__sidebar{flex:none;width:160px;border-right:1px solid #e3e9e5}.src-client-modules-common-StagesCalendar-_StagesCalendar__chart{flex-grow:1}.src-client-modules-common-StagesCalendar-_StagesCalendar__timeline{width:100%;height:48px;border-bottom:1px solid #e3e9e5}.src-client-modules-common-StagesCalendar-_StagesCalendar__canvasWrapper{position:relative;padding-top:7px;padding-right:8px}.src-client-modules-common-StagesCalendar-_StagesCalendar__canvas{margin-top:-14px}.src-client-modules-common-StagesCalendar-_StagesCalendar__tooltip{position:absolute;width:0;height:0;pointer-events:none}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/StagesCalendar/StagesCalendar.scss"],"names":[],"mappings":"AAAA,gEACI,YAAa,CAEb,UAAW,CACX,mBAAoB,CAEpB,iBAAkB,CAClB,wBAAyB,CAC5B,mEAGG,SAAU,CAEV,WAAY,CAEZ,8BAA+B,CAClC,iEAGG,WAAY,CACf,oEAGG,UAAW,CACX,WAAY,CAEZ,+BAAgC,CACnC,yEAGG,iBAAkB,CAElB,eAAgB,CAChB,iBAAkB,CACrB,kEAGG,gBAAiB,CACpB,mEAGG,iBAAkB,CAElB,OAAQ,CACR,QAAS,CAET,mBAAoB","sourcesContent":[".root {\n    display: flex;\n\n    width: 100%;\n    padding-bottom: 16px;\n\n    border-radius: 4px;\n    background-color: #ffffff;\n}\n\n.sidebar {\n    flex: none;\n\n    width: 160px;\n\n    border-right: 1px solid #e3e9e5;\n}\n\n.chart {\n    flex-grow: 1;\n}\n\n.timeline {\n    width: 100%;\n    height: 48px;\n\n    border-bottom: 1px solid #e3e9e5;\n}\n\n.canvasWrapper {\n    position: relative;\n\n    padding-top: 7px;\n    padding-right: 8px;\n}\n\n.canvas {\n    margin-top: -14px;\n}\n\n.tooltip {\n    position: absolute;\n\n    width: 0;\n    height: 0;\n\n    pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-StagesCalendar-_StagesCalendar__root",
	"sidebar": "src-client-modules-common-StagesCalendar-_StagesCalendar__sidebar",
	"chart": "src-client-modules-common-StagesCalendar-_StagesCalendar__chart",
	"timeline": "src-client-modules-common-StagesCalendar-_StagesCalendar__timeline",
	"canvasWrapper": "src-client-modules-common-StagesCalendar-_StagesCalendar__canvasWrapper",
	"canvas": "src-client-modules-common-StagesCalendar-_StagesCalendar__canvas",
	"tooltip": "src-client-modules-common-StagesCalendar-_StagesCalendar__tooltip"
};
export default ___CSS_LOADER_EXPORT___;
