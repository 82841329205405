import * as React from 'react';

import type { ItemParams } from './List';
import type { Filters } from '../types';
import { List } from './List';

import * as styles from './styles.scss';

interface Props {
    id: string;
    name: string;
    items: ItemParams[];
    filters: Filters;
    onChange: (filters: Filters) => void;
}

export const Dropdown: React.FC<Props> = ({ id, name, items, filters, onChange }) => {
    return (
        <div className={styles.root}>
            <List id={id} title={name} items={items} filters={filters} onChange={onChange} />
        </div>
    );
};

export type { ItemParams as ListItemParams };
