import { PlainDictionary, DictionaryType } from '@mrm/dictionary';

export type DictionaryValue = Partial<Record<DictionaryType, PlainDictionary>>;
export type DictionariesById = Record<string, PlainDictionary>;
export type DictionariesByType = Partial<Record<DictionaryType, PlainDictionary[]>>;

export interface GroupedDictionaries {
    byId: DictionariesById;
    byType: DictionariesByType;
}

// Regionality.value === 'ЦА'
export const REGIONALITY_CENTRAL_BANK_ID = 'd6c54e60-da4f-412e-bd3b-9bcfd297e549';
// RegionalityType.value === 'ЦА'
export const REGIONALITY_TYPE_CENTRAL_BANK = 'c554b4cd-5f99-4bae-96dd-fba3827609d7';
// RegionalityType.value === 'ТБ'
export const REGIONALITY_TYPE_OTHER_BANKS = '9d2e4f3c-96d1-4208-ba46-998302b80a8a';

export const REGIONALITY_BASED_DICTIONARIES = [
    DictionaryType.Regionality,
    DictionaryType.Direction,
    DictionaryType.Tool,
    DictionaryType.Resource,
    DictionaryType.Item,
];

export const BLOCK_BASED_DICTIONARIES = [
    DictionaryType.Block,
    DictionaryType.Product,
    DictionaryType.Segment,
    DictionaryType.Channel,
];

export interface RegionalityUsecase {
    [DictionaryType.RegionalityType]: PlainDictionary;
    [DictionaryType.Direction]: PlainDictionary;
    [DictionaryType.Tool]: PlainDictionary;
    [DictionaryType.Resource]: PlainDictionary;
    [DictionaryType.Item]: PlainDictionary;
}

export function isMainRegionalitySelected(value: DictionaryValue): boolean {
    return !value[DictionaryType.Regionality] || value[DictionaryType.Regionality]?.id === REGIONALITY_CENTRAL_BANK_ID;
}
