// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-TabBar-Content-_Content__tabBarContentWrapper{height:calc(100% - 148px)}.src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-TabBar-Content-_Content__widgetWrapper{padding:12px 24px 16px;border-bottom:solid 1px #e3e9e5}.src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-TabBar-Content-_Content__tagsEditorWrapper{padding:16px 24px 24px 24px;border-bottom:solid 1px #e3e9e5}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetPlanning/LineModal/TabBar/Content/Content.scss"],"names":[],"mappings":"AAAA,6GACI,yBAA0B,CAC7B,sGAGG,sBAAuB,CAEvB,+BAAgC,CACnC,0GAGG,2BAA4B,CAE5B,+BAAgC","sourcesContent":[".tabBarContentWrapper {\n    height: calc(100% - 148px);\n}\n\n.widgetWrapper {\n    padding: 12px 24px 16px;\n\n    border-bottom: solid 1px #e3e9e5;\n}\n\n.tagsEditorWrapper {\n    padding: 16px 24px 24px 24px;\n\n    border-bottom: solid 1px #e3e9e5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabBarContentWrapper": "src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-TabBar-Content-_Content__tabBarContentWrapper",
	"widgetWrapper": "src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-TabBar-Content-_Content__widgetWrapper",
	"tagsEditorWrapper": "src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-TabBar-Content-_Content__tagsEditorWrapper"
};
export default ___CSS_LOADER_EXPORT___;
