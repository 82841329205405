import * as React from 'react';

import { SELECTED_TEXT_OPTION_LENGTH_RANGE } from '@store/autopilot/generationTextForm';
import * as styles from './styles.scss';

interface Props {
    title: string;
    count: number;
}

export const Header: React.FC<Props> = ({ title, count }) => {
    return (
        <div className={styles.root}>
            <div className={styles.title}>
                {title} {count}
            </div>
            <div className={styles.subtitle}>
                Выберите от {SELECTED_TEXT_OPTION_LENGTH_RANGE.MIN} до {SELECTED_TEXT_OPTION_LENGTH_RANGE.MAX}
            </div>
        </div>
    );
};
