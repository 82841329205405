// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-tags-TagsPage-RightSidebar-Content-Activity-_Activity__briefMissing{color:#E63900}.src-client-modules-tags-TagsPage-RightSidebar-Content-Activity-_Activity__responsibleOrganization{color:#7E8681}.src-client-modules-tags-TagsPage-RightSidebar-Content-Activity-_Activity__link{text-decoration:none;color:black}\n", "",{"version":3,"sources":["webpack://./src/client/modules/tags/TagsPage/RightSidebar/Content/Activity/Activity.scss"],"names":[],"mappings":"AAAA,wFACI,aAAc,CACjB,mGAGG,aAAc,CACjB,gFAGG,oBAAqB,CACrB,WAAY","sourcesContent":[".briefMissing {\n    color: #E63900;\n}\n\n.responsibleOrganization {\n    color: #7E8681;\n}\n\n.link {\n    text-decoration: none;\n    color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"briefMissing": "src-client-modules-tags-TagsPage-RightSidebar-Content-Activity-_Activity__briefMissing",
	"responsibleOrganization": "src-client-modules-tags-TagsPage-RightSidebar-Content-Activity-_Activity__responsibleOrganization",
	"link": "src-client-modules-tags-TagsPage-RightSidebar-Content-Activity-_Activity__link"
};
export default ___CSS_LOADER_EXPORT___;
