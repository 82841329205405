// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-StagesListWidget-SidebarButton-_SidebarButton__root{position:relative;display:flex;align-items:center;height:32px;padding:0 16px;cursor:pointer;background:#ffffff;box-shadow:0 2px 6px rgba(0,0,0,0.1);border-radius:4px;color:#7e8681;font-family:'Open Sans';font-size:14px;line-height:16px;user-select:none;margin-left:auto}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/StagesListWidget/SidebarButton/SidebarButton.scss"],"names":[],"mappings":"AAAA,gGACI,iBAAkB,CAClB,YAAa,CACb,kBAAmB,CACnB,WAAY,CACZ,cAAe,CACf,cAAe,CAEf,kBAAmB,CACnB,oCAAwC,CACxC,iBAAkB,CAElB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACjB,gBAAiB,CACjB,gBAAiB","sourcesContent":[".root {\n    position: relative;\n    display: flex;\n    align-items: center;\n    height: 32px;\n    padding: 0 16px;\n    cursor: pointer;\n\n    background: #ffffff;\n    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);\n    border-radius: 4px;\n\n    color: #7e8681;\n\n    font-family: 'Open Sans';\n    font-size: 14px;\n    line-height: 16px;\n    user-select: none;\n    margin-left: auto;\n\n    // &::after {\n    //     position: absolute;\n    //     display: block;\n\n    //     top: -3px;\n    //     right: -3px;\n\n    //     height: 10px;\n    //     width: 10px;\n\n    //     background-color: #e63900;\n    //     border-radius: 50%;\n\n    //     content: '';\n    // }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-StagesListWidget-SidebarButton-_SidebarButton__root"
};
export default ___CSS_LOADER_EXPORT___;
