import * as React from 'react';
import { useSelector } from 'react-redux';
import type { FieldValue } from 'sber-marketing-types/frontend';
import { Icon, IconType, WithTooltip } from 'sber-marketing-ui';

import { StoreState } from '@store';
import type { FieldInput } from '@store/brief/types';
import { getLoginUser, userHasBudgetAccess as userHasBudgetAccessSelector } from '@store/user';

import { BudgetsFetcher, BudgetsData } from './BudgetsFetcher';
import { LinksContainerWithToggle, LinksContainerWIthTogglePreloader } from './LinksContainerWithToggle';

import * as styles from './Budgets.scss';
const CURR_YEAR = new Date().getFullYear();

interface Props {
    activityId: number;
    divisionId: string;
    departmentId: string;
    name?: string;
    tooltipMessage?: string;
    value?: FieldValue;
    onValueChange: (value: FieldInput) => void;
}

function useBudgetsTab() {
    const userOrganizationId = useSelector((state: StoreState) => getLoginUser(state).attributes.organizationId);
    const userHasBudgetAccess = useSelector((state: StoreState) => userHasBudgetAccessSelector(state));

    const [budgetsToDisplay, setBudgetsToDisplay] = React.useState<BudgetsData>({ loading: true, budgets: null });

    function setBudgetsData(budgetsData: BudgetsData) {
        if (budgetsData?.budgets?.length) {
            const budgetsToDisplay = budgetsData?.budgets.filter((item) => CURR_YEAR === item.year);
            setBudgetsToDisplay({ loading: budgetsData.loading, budgets: budgetsToDisplay });
        } else {
            setBudgetsToDisplay({ loading: false, budgets: null });
        }
    }

    const showPreloader = budgetsToDisplay?.loading;

    return {
        showPreloader,
        userOrganizationId,
        userHasBudgetAccess,
        budgetsToDisplay: budgetsToDisplay.budgets,
        setBudgetsData,
    };
}

export function Budgets({
    activityId,
    departmentId,
    divisionId,
    name,
    tooltipMessage,
    value,
    onValueChange,
}: Props): JSX.Element {
    const hasTooltip = !!tooltipMessage;
    const { showPreloader, userOrganizationId, userHasBudgetAccess, budgetsToDisplay, setBudgetsData } =
        useBudgetsTab();

    return (
        <React.Fragment>
            <div className={styles.root}>
                <div className={styles.title}>{name || 'Название поля'}</div>
                {showPreloader ? (
                    <LinksContainerWIthTogglePreloader />
                ) : budgetsToDisplay?.length ? (
                    budgetsToDisplay?.map((budget) => (
                        <LinksContainerWithToggle
                            key={budget.id}
                            activityId={activityId}
                            divisionId={divisionId}
                            departmentId={departmentId}
                            value={value}
                            userHasBudgetAccess={userHasBudgetAccess}
                            budget={budget}
                            onValueChange={onValueChange}
                        />
                    ))
                ) : value || !userHasBudgetAccess ? (
                    <LinksContainerWithToggle
                        activityId={activityId}
                        divisionId={divisionId}
                        departmentId={departmentId}
                        value={value}
                        year={CURR_YEAR}
                        userHasBudgetAccess={userHasBudgetAccess}
                    />
                ) : userHasBudgetAccess ? (
                    'Не удалось загрузить бюджеты'
                ) : (
                    ''
                )}

                {hasTooltip && (
                    <WithTooltip
                        content={<div className={styles.tooltipMessage}>{tooltipMessage}</div>}
                        className={styles.iconTooltip}
                    >
                        <Icon type={IconType.INFO_CIRCLE} svgSize="16" />
                    </WithTooltip>
                )}
            </div>

            <BudgetsFetcher forwardData={setBudgetsData} organizationIds={[userOrganizationId]} />
        </React.Fragment>
    );
}
