import * as React from 'react';
import * as moment from 'moment/moment';

import { DatepickerContent as Datepicker } from 'sber-marketing-ui';

import { useGetTaskQuery } from '@api';

import { useDropdownOptions } from '@common/hooks';

import { SkeletonBlock } from '@common/components';

export interface TaskDeadlineCalendarProps {
    taskId: string;
    onSelect?: (date: moment.Moment) => void;
}

export const TaskDeadlineCalendar: React.FC<TaskDeadlineCalendarProps> = ({ taskId, onSelect }) => {
    const { data: { task } = {}, isLoading: isTaskLoading } = useGetTaskQuery({ id: taskId });
    const dropdown = useDropdownOptions();

    if (isTaskLoading) {
        return <SkeletonBlock width={237} height={237} />;
    }

    const handleChange = (date: moment.Moment | [Date, Date]) => {
        dropdown.close?.();
        onSelect?.(date as moment.Moment);
    };

    return (
        <Datepicker
            minDate={task.budgetApproval ? moment().subtract(5, 'days') : undefined}
            date={moment(task.deadline)}
            customDateFormat="DD MMMM YYYY"
            onChange={handleChange}
        />
    );
};
