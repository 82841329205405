import { ActivityCard as ExternalActivityCard } from 'sber-marketing-types/frontend';

import { ActivitiesFilter } from '@store/userConfig/dashboard';

import { LoadingStatus } from '../commonTypes';

export interface ActivityCard extends ExternalActivityCard {
    isLoading?: boolean;
}

/** Type of activities list */
export const enum ActivitiesListType {
    /** User personal activities */
    MY_ACTIVITIES = 'my_activities',
    /** Activities accessible for user */
    ACCESSIBLE_ACTIVITIES = 'accessible_activities',
    /** Other lsit */
    OTHER = 'other',
}

export interface ActivitiesList {
    filters: ActivitiesFilter;
    /** Activity loading status */
    status: LoadingStatus;
    /** Activity sharing in progress flag */
    isActivitySharingInProgress: boolean;
    /** Type of activities list */
    type: ActivitiesListType;
    /** Is more activities can be loaded */
    canBeLoadedMore: boolean;
    /** Loaded activities */
    activities: ActivityCard[];
    /** Loading error (or "null") */
    error: Error | null;
    lastRequestTimestamp: Date;
    pendingDeletionIds: number[];
}

export interface GroupedActivityCard extends ActivityCard {
    groupName: string;
}

/** "SHARE_ACTIVITY" action payload */
export interface ShareActivityPayload {
    /** Activity identifier */
    activityId: number;
    /** Identifiers of user's to share */
    usersIds: number[];
}

/** "UPDATE_ACTIVITY_OWNERSHIPS" action payload */
export interface UpdateActivityOwnershipsPayload {
    /** Activity identifier */
    activityId: number;
    /** New ownerships */
    ownerships: number[];
}

/**
 * Group iteration function for "getGroupedActivitiesList" selector.
 * Must return index of group to allocate activity.
 */
export interface ActivityCardsGroupIteration {
    (activity: ActivityCard, index?: number, array?: ActivityCard[]): number;
}

export interface SwitchKeyActivityPayload {
    id: number;
    isKey: boolean;
}
