import { Month } from '@mrm/budget';
import { sortBy } from 'lodash';

import { Money, MoneyFormatter, DatesFormatter } from '@common/Utils';

import { DifferenceTransformer, DifferencesTitles } from './types';

function valueTransformer(value: number): string {
    return typeof value === 'number' ? MoneyFormatter.toThousands(Money.fromCopecks(value)) : null;
}

function titleTransformer(value: number): string {
    return typeof value === 'number' ? MoneyFormatter.toRoubles(Money.fromCopecks(value)) : null;
}

export const fundsDifference: DifferenceTransformer = function (prev, curr, item) {
    const diffs = Object.keys(prev[item]).filter((key) => prev[item][key] !== curr[item][key]) as Month[];

    return sortBy(diffs, (diff) => DatesFormatter.MonthOrder.indexOf(diff)).map((diff) => ({
        title: `${DifferencesTitles[item]} ${DatesFormatter.MonthNames[diff]}`,
        before: valueTransformer(prev[item][diff]),
        beforeTitle: titleTransformer(prev[item][diff]),
        after: valueTransformer(curr[item][diff]),
        afterTitle: titleTransformer(curr[item][diff]),
    }));
};
