import * as React from 'react';
import onClickOutside from 'react-onclickoutside';
import autobind from 'autobind-decorator';

import type { AutopilotPlacementStatus } from 'sber-marketing-types/backend';

import { PlacementStatusEditorTemplate } from './PlacementStatusEditorTemplate';

interface Props {
    status: AutopilotPlacementStatus;
    onSelection: (status: AutopilotPlacementStatus) => void;
}

interface State {
    isOpened: boolean;
}

@(onClickOutside as any)
export class PlacementStatusEditorBehaviour extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isOpened: false,
        };
    }

    public render(): JSX.Element {
        return React.createElement(PlacementStatusEditorTemplate, {
            isOpened: this.state.isOpened,
            status: this.props.status,
            onOpenerClick: this.onOpenerClick,
            onSelection: this.onSelection,
        });
    }

    @autobind
    protected handleClickOutside() {
        this.setState({
            isOpened: false,
        });
    }

    @autobind
    private onOpenerClick() {
        this.setState({
            isOpened: !this.state.isOpened,
        });
    }

    @autobind
    protected onSelection(value: AutopilotPlacementStatus) {
        this.setState(
            {
                isOpened: false,
            },
            () => {
                this.props.onSelection(value);
            },
        );
    }
}
