import { actionCreatorFactory } from 'typescript-fsa';
import { Tag, TagColorType, TagEmojiType } from '@mrm/tags';

import { LoadingStatus } from '@store/commonTypes';

import { ComponentState, TagsEditorInstanceDescriptor as InstanceDescriptor, InstanceActionPayload } from '../types';

const actionCreator = actionCreatorFactory('TAGS_EDITOR');

export const resetState = actionCreator('RESET_STATE');
export const initInstance = actionCreator<InstanceActionPayload<InstanceDescriptor>>('INIT_INSTANCE');
export const dropInstance = actionCreator<string>('DROP_INSTANCE');
export const updateInstanceDescriptor =
    actionCreator<InstanceActionPayload<InstanceDescriptor>>('UPDATE_INSTANCE_DESCRIPTOR');

export const setLoadingStatus = actionCreator<InstanceActionPayload<LoadingStatus>>('SET_LOADING_STATUS');
export const setTagIdWithActiveEditor = actionCreator<InstanceActionPayload<string>>('SET_TAG_ID_WITH_ACTIVE_EDITOR');
export const setComponentState = actionCreator<InstanceActionPayload<ComponentState>>('SET_COMPONENT_STATE');
export const setNewTagInputValue = actionCreator<InstanceActionPayload<string>>('SET_NEW_TAG_INPUT_VALUE');
export const setCanEdit = actionCreator<InstanceActionPayload<boolean>>('SET_CAN_EDIT');

export const setPendingTag = actionCreator<InstanceActionPayload<Tag>>('SET_PENDING_TAG');
export const setPendingTagTitle = actionCreator<InstanceActionPayload<string>>('SET_PENDING_TAG_TITLE');
export const setPendingTagEmoji = actionCreator<InstanceActionPayload<TagEmojiType>>('SET_PENDING_TAG_EMOJI');
export const setPendingTagColor = actionCreator<InstanceActionPayload<TagColorType>>('SET_PENDING_TAG_COLOR');
export const setSelectedTags = actionCreator<InstanceActionPayload<string[]>>('SET_SELECTED_TAGS');
