import * as React from 'react';
import autobind from 'autobind-decorator';

import { OutcomeExternalCorrectionCard } from './OutcomeExternalCorrectionCard';
import { OutcomeExternalPlanFundsTransferCorrection } from '../../../types';
import { Dictionary } from '../../withDictionary';

interface Props {
    correction: OutcomeExternalPlanFundsTransferCorrection;
    dictionaries: Dictionary[];
    onHover: (correctionId: string) => void;
    onChange: () => void;
}

export class OutcomeExternalCorrectionCardContainer extends React.Component<Props> {
    public render() {
        const { correction, dictionaries, onChange } = this.props;
        return React.createElement(OutcomeExternalCorrectionCard, {
            correction,
            dictionaries,
            onCardMouseEnter: this.onCardMouseEnter,
            onCardMouseLeave: this.onCardMouseLeave,
            onChange,
        });
    }

    @autobind
    private onCardMouseEnter(): void {
        this.props.onHover(this.props.correction.id);
    }

    @autobind
    private onCardMouseLeave(): void {
        this.props.onHover(null);
    }
}
