import { Direction, DropdownAutoPlacement, DropdownPlacement, ParsedPlacement } from './types';

export const verticalPlacements: DropdownPlacement[] = [
    'topLeft',
    'top',
    'topRight',
    'bottomLeft',
    'bottom',
    'bottomRight',
];

export function parsePlacement(placement: DropdownPlacement): ParsedPlacement {
    return {
        top: placement.includes('top') || placement.includes('Bottom'),
        bottom: placement.includes('bottom') || placement.includes('Top'),
        left: placement.includes('left') || placement.includes('Right'),
        right: placement.includes('right') || placement.includes('Left'),
        vertical: verticalPlacements.includes(placement),
    };
}

export function stringifyPlacement(placement: ParsedPlacement): DropdownPlacement {
    if (placement.vertical) {
        if (placement.top) {
            return placement.left ? 'topRight' : placement.right ? 'topLeft' : 'top';
        } else if (placement.bottom) {
            return placement.left ? 'bottomRight' : placement.right ? 'bottomLeft' : 'bottom';
        }
    } else {
        if (placement.left) {
            return placement.top ? 'leftBottom' : placement.bottom ? 'leftTop' : 'left';
        } else if (placement.right) {
            return placement.top ? 'rightBottom' : placement.bottom ? 'rightTop' : 'right';
        }
    }

    throw Error(`cannot parse placement ${JSON.stringify(placement)}`);
}

export function shiftByDirection(
    direction: Direction,
    placement: ParsedPlacement,
    autoPlacement: DropdownAutoPlacement,
): ParsedPlacement {
    if (!autoPlacement) return placement;

    const { vertical, top, left, right, bottom } = placement;

    if (autoPlacement === 'vertical') {
        if (direction === 'bottom' && bottom) {
            return {
                ...placement,
                bottom: false,
                top: true,
            };
        }

        if (direction === 'top' && top) {
            return {
                ...placement,
                bottom: true,
                top: false,
            };
        }
    }

    if (autoPlacement === 'horizontal') {
        if (direction === 'left' && left) {
            return {
                ...placement,
                left: false,
                right: true,
            };
        }

        if (direction === 'right' && right) {
            return {
                ...placement,
                left: true,
                right: false,
            };
        }
    }

    if (autoPlacement !== 'vertical' && vertical && (left || right) && ['bottom', 'top'].includes(direction)) {
        return {
            ...placement,
            vertical: false,
        };
    }

    if (autoPlacement !== 'horizontal' && !vertical && (top || bottom) && ['left', 'right'].includes(direction)) {
        return {
            ...placement,
            vertical: true,
        };
    }

    if (direction === 'right') {
        if (right) {
            return {
                ...placement,
                right: false,
            };
        } else if (!left) {
            return {
                ...placement,
                left: true,
            };
        }
        return placement;
    }

    if (direction === 'left') {
        if (left) {
            return {
                ...placement,
                left: false,
            };
        } else if (!right) {
            return {
                ...placement,
                right: true,
            };
        }

        return placement;
    }

    if (direction === 'top') {
        if (top) {
            return {
                ...placement,
                top: false,
            };
        } else if (!bottom) {
            return {
                ...placement,
                bottom: true,
            };
        }
        return placement;
    }

    if (direction === 'bottom') {
        if (bottom) {
            if (left || right) {
                return {
                    ...placement,
                    bottom: false,
                };
            } else {
                return {
                    ...placement,
                    bottom: false,
                    top: true,
                };
            }
        } else if (!top) {
            return {
                ...placement,
                top: true,
            };
        }
    }

    return placement;
}
