import createCashedSelector from 're-reselect';
import { TaskAttributes } from 'sber-marketing-types/frontend';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';

import { TasksState, StoreTypes } from './types';

const getTasksState = (state: StoreState): TasksState => state.tasks;

export const getFetchersCount = createCashedSelector(
    getTasksState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: TasksState, store: StoreTypes): number => state.stores[store].fetchersCount,
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getTaskById = createCashedSelector(
    getTasksState,
    (state: StoreState, id: string): string => id,
    (state: TasksState, id: string): TaskAttributes => state.byIds[id],
)((state: StoreState, id: string): string => id);

export const getTasks = createCashedSelector(
    getTasksState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: TasksState, store: StoreTypes): TaskAttributes[] => {
        const entitiesStore = state.stores[store];

        if (entitiesStore.loadingStatus === LoadingStatus.LOADED) {
            return state.stores[store].ids.map((id: string) => state.byIds[id]);
        }

        return [];
    },
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getTaskIds = createCashedSelector(
    getTasksState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: TasksState, store: StoreTypes): string[] => state.stores[store].ids,
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getLoadingStatus = createCashedSelector(
    getTasksState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: TasksState, store: StoreTypes): LoadingStatus => state.stores[store].loadingStatus,
)((state: StoreState, store: StoreTypes): StoreTypes => store);
