// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-leaderDashboard-LeaderDashboard-Content-CommonShortReport-Header-_styles__link{display:inline;font-family:\"Open Sans\";font-weight:400;font-size:12px;color:#4a90e2;cursor:pointer;user-select:none;transition:color 75ms ease-out;margin-right:4px}.src-client-modules-leaderDashboard-LeaderDashboard-Content-CommonShortReport-Header-_styles__link:hover{color:#3f7bc2}.src-client-modules-leaderDashboard-LeaderDashboard-Content-CommonShortReport-Header-_styles__link:active{color:#32639b}.src-client-modules-leaderDashboard-LeaderDashboard-Content-CommonShortReport-Header-_styles__disabledLink{color:#7b7b7b;cursor:default}.src-client-modules-leaderDashboard-LeaderDashboard-Content-CommonShortReport-Header-_styles__disabledLink:hover,.src-client-modules-leaderDashboard-LeaderDashboard-Content-CommonShortReport-Header-_styles__disabledLink:active{color:#7b7b7b}\n", "",{"version":3,"sources":["webpack://./src/client/modules/leaderDashboard/LeaderDashboard/Content/CommonShortReport/Header/styles.scss"],"names":[],"mappings":"AAEA,mGACI,cAAe,CACf,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,aAAc,CAEd,cAAe,CACf,gBAAiB,CAEjB,8BAA+B,CAE/B,gBAAiB,CAZrB,yGAeQ,aACJ,CAhBJ,0GAmBQ,aACJ,CAAC,2GAID,aAAc,CACd,cAAe,CAFnB,mOAKQ,aAAc","sourcesContent":[".root {}\n\n.link {\n    display: inline;\n    font-family: \"Open Sans\";\n    font-weight: 400;\n    font-size: 12px;\n    color: #4a90e2;\n\n    cursor: pointer;\n    user-select: none;\n\n    transition: color 75ms ease-out;\n\n    margin-right: 4px;\n\n    &:hover {\n        color: #3f7bc2\n    }\n\n    &:active {\n        color: #32639b\n    }\n}\n\n.disabledLink {\n    color: #7b7b7b;\n    cursor: default;\n\n    &:hover, &:active {\n        color: #7b7b7b;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "src-client-modules-leaderDashboard-LeaderDashboard-Content-CommonShortReport-Header-_styles__link",
	"disabledLink": "src-client-modules-leaderDashboard-LeaderDashboard-Content-CommonShortReport-Header-_styles__disabledLink"
};
export default ___CSS_LOADER_EXPORT___;
