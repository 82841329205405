import * as React from 'react';

import { Preloader } from 'sber-marketing-ui';
import { FileInput } from './FileInput';
import { ProtocolLimitInput } from './ProtocolLimitInput';
import { Status } from './Status';

import { useBehavior } from './useBehavior';

import * as style from './styles.scss';

interface Props {}

export const TenderDocumentation: React.FC<Props> = () => {
    const {
        status,
        protocolFile,
        protocolLimit,
        disabledStatus,
        disabledProtocolLimit,
        disabledUploadFile,
        disabledDownloadFile,
        disabledRemoveFile,
        loading,
        onApprove,
        onDisapprove,
        onAddProtocolFile,
        onRemoveProtocolFile,
        onProtocolLimitChange,
    } = useBehavior();

    return (
        <div className={style.root}>
            <div className={style.title}>Документация на тендер</div>
            <div className={style.line}>
                {(protocolFile || !disabledUploadFile) && (
                    <div className={style.field}>
                        <FileInput
                            file={protocolFile}
                            disabledUpload={disabledUploadFile}
                            disabledDownload={disabledDownloadFile}
                            disabledRemove={disabledRemoveFile}
                            onAdd={onAddProtocolFile}
                            onRemove={onRemoveProtocolFile}
                        />
                    </div>
                )}
                {!loading && (
                    <div className={style.field}>
                        <ProtocolLimitInput
                            value={protocolLimit}
                            disabled={disabledProtocolLimit}
                            onChange={onProtocolLimitChange}
                        />
                    </div>
                )}
                <div className={style.status}>
                    <Status
                        status={status}
                        disabled={disabledStatus}
                        onApprove={onApprove}
                        onDisapprove={onDisapprove}
                    />
                </div>
            </div>

            {loading && <Preloader />}
        </div>
    );
};
