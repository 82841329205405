import * as React from 'react';
import * as Dotdotdot from 'react-dotdotdot';
import classNames from 'classnames';
import { TaskStatus } from 'sber-marketing-types/frontend';
import { Icon, IconType, StyledPopup, WithTooltip, TooltipAnchor } from 'sber-marketing-ui';

import { CopyTextButton } from '@common/CopyTextButton';
import { DatesFormatter } from '@common/Utils';

import { SubscribeButton } from '../SubscribeButton';
import { TelegramButton } from '../TelegramButton';

import * as style from './TaskTitle.scss';

interface Props {
    name: string;
    deadline: number;
    status: TaskStatus;
    className?: string;
    isUserSubscribed: boolean;
    subscribeIsDisabled: boolean;
    showEditButton: boolean;
    showDeleteButton: boolean;
    showTelegramButton: boolean;
    showDeleteTaskPopup: boolean;
    onSubscribeButtonClick: (isIsUserSubscribed: boolean) => void;
    onEditButtonClick: () => void;
    setDeleteTaskPopupVisibility: (isVisible: boolean) => void;
    initTaskDeletion: () => Promise<void>;
}

export function TaskTitleTemplate({
    className,
    name,
    deadline,
    status,
    isUserSubscribed,
    subscribeIsDisabled,
    showEditButton,
    showDeleteButton,
    showTelegramButton,
    showDeleteTaskPopup,
    onSubscribeButtonClick,
    onEditButtonClick,
    setDeleteTaskPopupVisibility,
    initTaskDeletion,
}: Props): JSX.Element {
    return (
        <div
            className={classNames(style.root, className)}
            {...{
                'qa-id': 'taskTitle',
            }}
        >
            <div className={style.copyTextButton}>
                <CopyTextButton
                    text={name}
                    tooltipAnchor={TooltipAnchor.TOP}
                    title="Скопировать название"
                    coppiedTitle="Скопировано"
                />
            </div>

            <div
                className={style.taskName}
                {...{
                    'qa-id': 'taskTitleTaskName',
                }}
            >
                <Dotdotdot clamp={2}>{name}</Dotdotdot>
            </div>

            <div className={style.indicators}>
                <div
                    className={style.deadline}
                    {...{
                        'qa-id': 'taskTitleDeadline',
                    }}
                >
                    <Icon type={IconType.CALENDAR_ICON} svgSize={16} className={style.deadlineIcon} />
                    Дедлайн: {DatesFormatter.ddMonthyyyy(deadline)}
                </div>

                <Status status={status} />
            </div>

            <Controls
                isUserSubscribed={isUserSubscribed}
                subscribeIsDisabled={subscribeIsDisabled}
                showEditButton={showEditButton}
                showDeleteButton={showDeleteButton}
                showTelegramButton={showTelegramButton}
                showDeleteTaskPopup={showDeleteTaskPopup}
                onSubscribeButtonClick={onSubscribeButtonClick}
                onEditButtonClick={onEditButtonClick}
                setDeleteTaskPopupVisibility={setDeleteTaskPopupVisibility}
                initTaskDeletion={initTaskDeletion}
            />
        </div>
    );
}

type StatusProps = Pick<Props, 'status'>;

function Status({ status }: StatusProps): JSX.Element {
    let className;
    let title;
    switch (status) {
        case TaskStatus.Closed:
            className = style.statusClosed;
            title = 'Закрыта';
            break;
        case TaskStatus.Draft:
            className = style.statusDraft;
            title = 'Черновик';
            break;
        case TaskStatus.InProgress:
            className = style.statusInProgress;
            title = 'В работе';
            break;
        default:
            className = style.statusDraft;
            title = 'Статус не найден';
            break;
    }

    return (
        <div
            className={classNames(style.status, className)}
            {...{
                'qa-id': 'taskTitleStatus',
            }}
        >
            {title}
        </div>
    );
}

type ControlsProps = Pick<
    Props,
    | 'isUserSubscribed'
    | 'subscribeIsDisabled'
    | 'onSubscribeButtonClick'
    | 'showEditButton'
    | 'showDeleteButton'
    | 'showTelegramButton'
    | 'showDeleteTaskPopup'
    | 'onEditButtonClick'
    | 'setDeleteTaskPopupVisibility'
    | 'initTaskDeletion'
>;

function Controls({
    isUserSubscribed,
    subscribeIsDisabled,
    showDeleteButton,
    showEditButton,
    showTelegramButton,
    showDeleteTaskPopup,
    onSubscribeButtonClick,
    onEditButtonClick,
    setDeleteTaskPopupVisibility,
    initTaskDeletion,
}: ControlsProps): JSX.Element {
    return (
        <div className={style.controls}>
            <SubscribeButton
                subscribeIsDisabled={subscribeIsDisabled}
                isUserSubscribed={isUserSubscribed}
                onClick={onSubscribeButtonClick}
            />

            {showTelegramButton && (
                <div className={classNames(style.button, style.telegramButton)}>
                    <TelegramButton />
                </div>
            )}

            {showEditButton && (
                <div
                    className={classNames(style.button, style.editButton)}
                    onClick={onEditButtonClick}
                    {...{
                        'qa-id': 'taskTitleEditButton',
                    }}
                >
                    <WithTooltip content="Редактировать задачу" anchor={TooltipAnchor.TOP}>
                        <Icon type={IconType.PROJECT_STAGES_EDIT_ICON} svgSize={20} />
                    </WithTooltip>
                </div>
            )}

            {showDeleteButton && (
                <div
                    className={classNames(style.button, style.deleteButton)}
                    onClick={() => setDeleteTaskPopupVisibility(true)}
                    {...{
                        'qa-id': 'taskTitleDeleteButton',
                    }}
                >
                    <WithTooltip content="Удалить задачу" anchor={TooltipAnchor.TOP}>
                        <Icon type={IconType.DELETE_V2} svgSize={26} />
                    </WithTooltip>
                </div>
            )}

            {showDeleteTaskPopup && (
                <StyledPopup
                    qaId={'deleteTaskPopup'}
                    title="Удалить задачу?"
                    firstButtonQaId="deleteTaskPopupFirstButton"
                    secondButtonQaId="deleteTaskPopupSecondButton"
                    firstButtonText="Да, удалить"
                    secondButtonText="Отмена"
                    firstButtonHandler={initTaskDeletion}
                    secondButtonHandler={() => setDeleteTaskPopupVisibility(false)}
                />
            )}
        </div>
    );
}
