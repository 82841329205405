import * as React from 'react';
import { debounce } from 'lodash';

import { useOutsideClick } from './useOutsideClick';
import * as styles from './styles.scss';

interface Props {
    elRef: React.Ref<{ closeDropdown: () => void }>;
    label: React.ReactNode | React.ReactNodeArray;
    content: React.ReactNode | React.ReactNodeArray;
}

interface Position {
    x: number;
    y: number;
}

const CONTENT_PADDING = 10;
const TRIGGER_PADDING = 30;

export const Dropdown: React.FC<Props> = React.forwardRef(({ label, content }, elRef) => {
    React.useImperativeHandle(elRef, () => ({
        closeDropdown() {
            setOpen(false);
        },
    }));

    const [open, setOpen] = React.useState(false);
    const [hover, setHover] = React.useState<boolean>(false);
    const [position, setPosition] = React.useState<Position>({ x: 0, y: 0 });

    const rootRef = React.useRef<HTMLDivElement>(null);
    const triggerRef = React.useRef<HTMLDivElement>(null);
    const contentRef = React.useRef<HTMLDivElement>(null);

    const delay = React.useCallback(
        debounce((callback: () => void) => callback(), 1000),
        [],
    );

    const onOutsideClick = React.useCallback(() => {
        setOpen(false);
    }, [open]);

    useOutsideClick({
        ref: rootRef,
        onOutsideClick,
    });

    React.useEffect(() => {
        delay(() => {
            if (!hover) {
                setOpen(false);
            }
        });
    }, [hover]);

    const onClickTrigger: React.MouseEventHandler<HTMLDivElement> = React.useCallback(() => {
        setOpen(!open);
    }, [open, hover]);

    const onMouseEnterList = React.useCallback(() => {
        setHover(true);
    }, []);

    const onMouseEnterTrigger = React.useCallback(() => {
        setHover(true);
    }, []);

    const onMouseLeave = React.useCallback(
        (event) => {
            setHover(false);
        },
        [hover, setHover, setOpen],
    );

    React.useEffect(() => {
        if (open) {
            const triggerDOMRect = triggerRef.current.getBoundingClientRect();
            const contentDOMRect = contentRef.current.getBoundingClientRect();

            setPosition({
                x:
                    contentDOMRect.right + CONTENT_PADDING < document.documentElement.clientWidth
                        ? triggerDOMRect.x
                        : document.documentElement.clientWidth - contentDOMRect.width - CONTENT_PADDING,
                y: triggerDOMRect.y + TRIGGER_PADDING,
            });
        }
    }, [open]);

    return (
        <div ref={rootRef} className={styles.root} onMouseLeave={onMouseLeave}>
            <div
                ref={triggerRef}
                className={styles.trigger}
                onClick={onClickTrigger}
                onMouseEnter={onMouseEnterTrigger}
            >
                {label}
            </div>

            {open && (
                <div
                    style={{
                        left: position.x,
                        top: position.y,
                    }}
                    ref={contentRef}
                    className={styles.listWrapper}
                    onMouseEnter={onMouseEnterList}
                >
                    {content}
                </div>
            )}
        </div>
    );
});
