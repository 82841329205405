import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { v4 as uuid } from 'uuid';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { BudgetItem, CreateBudgetItemForm } from '@mrm/budget';
import { FormData, GroupedDictionaries } from '@store/executionBudgetEdit/types';

import { FormField, FieldValue } from 'sber-marketing-ui';
import { BudgetCard } from './BudgetCard';
import { StoreState } from '@store';
import { MultiReferenceDictionaryApi } from '@api';
import {
    addBudgetForm,
    updateBudgetForm,
    removeBudgetForm,
    setBudgetCardCollapseStatus,
    setBudgetItemTransferDestination,
} from '@store/executionBudgetEdit/actions';
import { makeBudgetsItems } from '@store/executionBudgetEdit/selectors';

interface Props extends Partial<MapProps>, Partial<DispatchProps> {
    id: string;
    budgetId: string;
    index: number;
    fields: FormField[];
    collapsed: boolean;
    isNew: boolean;
    canBeDeleted: boolean;
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi;
}

interface MapProps {
    budgetItemForm: FormData;
    budgetItem: BudgetItem;
    currentBudgetItem?: CreateBudgetItemForm;
    dictionaries: GroupedDictionaries;
}

interface DispatchProps {
    addBudgetForm?: (form: FormData) => void;
    updateBudgetForm?: (form: FormData) => void;
    removeBudgetForm?: (id: string) => void;
    setBudgetCardCollapseStatus?: (id: string, status: boolean) => void;
    setBudgetItemTransferDestination?: (params: { budgetItemId: string; activityBudgetId: string }) => void;
}

interface State {
    hovered: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BudgetCardContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            hovered: false,
        };
    }

    public render(): JSX.Element {
        const {
            id,
            budgetId,
            index,
            fields,
            collapsed,
            isNew,
            budgetItem,
            currentBudgetItem,
            dictionaries,
            canBeDeleted,
            budgetItemForm,
            multiReferenceDictionaryApi,
        } = this.props;
        const { activityNameIsFocused, transferDestinationId, tagsEditorId } = budgetItemForm;

        return React.createElement(BudgetCard, {
            id,
            budgetId,
            index,
            fields,
            collapsed,
            isNew,
            budgetItem,
            currentBudgetItem,
            dictionaries,
            showActivitySuggest: activityNameIsFocused,
            isMarkedForTransfer: !!transferDestinationId,
            canBeDeleted,
            hovered: this.state.hovered,
            tagsEditorId,
            multiReferenceDictionaryApi,
            onCopyButtonClick: this.onCopyButtonClick,
            onDeleteButtonClick: this.onDeleteButtonClick,
            onCollapseButtonClick: this.onCollapseButtonClick,
            onSuggestItemClick: this.onSuggestItemClick,
            onMouseEnter: this.onMouseEnter,
            onMouseLeave: this.onMouseLeave,
        });
    }

    @autobind
    protected onCopyButtonClick() {
        const { budgetItemForm } = this.props;

        const newForm = this.cloneBudgetItemForm(budgetItemForm);

        this.props.addBudgetForm(newForm);
    }

    @autobind
    protected onSuggestItemClick(activityBudgetId: string, activityBudgetName: string) {
        this.setFieldValue('activityBudget', activityBudgetName);
        this.props.setBudgetItemTransferDestination({ budgetItemId: this.props.id, activityBudgetId });
    }

    @autobind
    protected onTransferInputClear() {
        const originalValue = '';

        this.setFieldValue('activityBudget', originalValue);
        this.props.setBudgetItemTransferDestination({ budgetItemId: this.props.id, activityBudgetId: null });
    }

    @autobind
    protected onDeleteButtonClick() {
        this.props.removeBudgetForm(this.props.id);
    }

    @autobind
    protected onCollapseButtonClick() {
        this.props.setBudgetCardCollapseStatus(this.props.id, !this.props.collapsed);
    }

    @autobind
    protected onMouseEnter() {
        this.setState({
            hovered: true,
        });
    }

    @autobind
    protected onMouseLeave() {
        this.setState({
            hovered: false,
        });
    }

    private cloneBudgetItemForm(budgetItemForm: FormData): FormData {
        const newForm: FormData = lodash.cloneDeep(budgetItemForm);

        newForm.id = uuid();
        newForm.tagsEditorId = uuid();

        newForm.fields.forEach((item) => {
            const fieldIsActivityName = item.name === 'activityBudget';

            item.id = uuid();
            item.disabled = fieldIsActivityName;
            item.readOnly = false;
        });

        newForm.collapsed = false;
        newForm.isNew = true;

        return newForm;
    }

    private setFieldValue(fieldName: string, value: FieldValue) {
        const { budgetItemForm } = this.props;

        const updatedForm: FormData = lodash.cloneDeep(budgetItemForm);

        const field = updatedForm.fields.find((item) => item.name === fieldName);

        field.value = value;

        this.props.updateBudgetForm(updatedForm);
    }
}

function mapStateToProps(state: StoreState, props: Props): MapProps {
    const { id, isNew } = props;
    const { budgetItemForms, budgetItems, availableDictionaries, usedDictionaries } = state.executionBudgetEditPage;

    const dictionaries = isNew ? availableDictionaries : usedDictionaries;

    const currentBudgetItems = makeBudgetsItems(state);
    const budgetItemForm = budgetItemForms.find((item) => item.id === props.id);

    return {
        budgetItemForm,
        budgetItem: budgetItems.find((budgetItem) => budgetItem.id === id),
        currentBudgetItem: currentBudgetItems.find((item) => item.id == id),
        dictionaries,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return {
        addBudgetForm: (form: FormData) => dispatch(addBudgetForm(form)),
        updateBudgetForm: (form: FormData) => dispatch(updateBudgetForm(form)),
        removeBudgetForm: (id: string) => dispatch(removeBudgetForm(id)),
        setBudgetCardCollapseStatus: (id: string, status: boolean) =>
            dispatch(setBudgetCardCollapseStatus({ id, status })),
        setBudgetItemTransferDestination: (params: { budgetItemId: string; activityBudgetId: string }) =>
            dispatch(setBudgetItemTransferDestination(params)),
    };
}
