import * as moment from 'moment';
import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { AxiosError } from 'axios';
import { flatten, isNil } from 'lodash';
import { ChanelObject, FullTaskInfoResponse } from 'sber-marketing-types/frontend';

import { TaskApi, FileApi, UserApi, ActivityApi, WorkTypeApi } from '@api';
import { DepartmentApi } from '../../../api/DepartmentApiNew';

import { StoreState } from '@store';
import { updateError } from '@store/common';
import { getLoginUser } from '@store/user/selector';
import { FileAsset } from '@store/commonTypes';
import { clearList, add } from '@store/fileAssets';
import { asyncActions, syncActions } from '@store/taskPage/actions';
import { convertTelegramStatusResponseToTelegramInfo } from '@store/taskPage/utils';
import { addUsers } from '@store/appUsers';
import {
    LoadTaskPayload,
    FetchError,
    LoadTaskSuccess,
    Commentary,
    WorkType,
    Department,
    Channel,
} from '@store/taskPage/types';
import { NEW_COMMENTARY } from '../constants';

const getTaskData = async (taskId: string): Promise<FullTaskInfoResponse> => await TaskApi.getTask(taskId);

const mapChannel = (
    { comments, id, title, participantIds, authorId, createTime, updateTime }: ChanelObject,
    taskParticipants: number[],
): Channel => ({
    id,
    title,
    participantIds: id === null ? taskParticipants : participantIds,
    authorId,
    commentsIds: isNil(comments) ? [] : comments.map(({ id }) => id),
    createTime: moment(createTime),
    updateTime: moment(updateTime),
});

const getDepartments = async (): Promise<Department[]> => {
    const departmentsRaw = await DepartmentApi.getList({ embed: 'subDepartments' });
    return departmentsRaw.map(({ subDepartments, createTime, updateTime, ...rest }) => ({
        ...rest,
        createTime: moment(createTime).unix() * 1000,
        updateTime: moment(updateTime).unix() * 1000,
        subDepartmentsIds: isNil(subDepartments) ? [] : subDepartments.map(({ id }) => id),
    }));
};

const getWorkTypes = (organizationId: string): Promise<WorkType[]> =>
    WorkTypeApi.getList({ organizationId }).then((workTypes) =>
        workTypes.map(({ id, name, organizationId, isArchived, enable_rates, departmentIds }) => ({
            id,
            name,
            organizationId,
            isArchived,
            enableRates: enable_rates || false,
            departmentIds: departmentIds || [],
        })),
    );

const getDraft = (taskId: string) => TaskApi.getDraft(taskId);

const loadAll = (
    taskId: string,
    organizationId: string,
): Promise<[FullTaskInfoResponse, Department[], WorkType[], any]> =>
    Promise.all([getTaskData(taskId), getDepartments(), getWorkTypes(organizationId), getDraft(taskId)]);

export const loadTask = bindThunkAction<StoreState, LoadTaskPayload, LoadTaskSuccess, FetchError>(
    asyncActions.loadTask,
    async ({ taskId, channelId }, dispatch, getState): Promise<LoadTaskSuccess> => {
        let result: LoadTaskSuccess | null = null;

        const userOrganizationId = getLoginUser(getState()).attributes.organizationId;

        try {
            const [taskData, departments, workTypes, draft] = await loadAll(taskId, userOrganizationId);

            const task = taskData.task;
            const telegram = taskData.telegramStatus;

            const users = await UserApi.getUserCardListFiltered({ embed: ['vacation'] }); // copied from store/appUsers/thunks/loadAll
            dispatch(addUsers(users));

            if (draft.newComment) {
                dispatch(syncActions.setEditingComment(NEW_COMMENTARY));
                dispatch(
                    syncActions.editCommentary({
                        content: draft.newComment,
                    }),
                );
            }

            const activity = await ActivityApi.getActivity(task.activityId, true);

            const taskFiles = FileApi.mapFiles({ taskId }, task.files, String(task.authorId));
            const commentsFiles = flatten(
                task.chanels.map(({ comments = [] }) =>
                    comments.map((comment) => {
                        const params = { taskId, commentId: comment.id };
                        return FileApi.mapFiles(params, comment.files, String(comment.authorId));
                    }),
                ),
            );
            const assets: FileAsset[] = taskFiles.concat(flatten(commentsFiles));
            dispatch(clearList());
            dispatch(add(...assets));

            const channels: Channel[] = task.chanels.map((channel) =>
                mapChannel(
                    channel,
                    task.participants.map((participant) => participant.userId),
                ),
            );

            const comments: Commentary[] = flatten(
                task.chanels.map(({ comments: comms = [] }) =>
                    comms.map(({ id, authorId, createTime, files, text, replyId, reactions }) => {
                        const draftComment = draft.comments && draft.comments.find((item: any) => item.id == id);

                        return {
                            id: String(id),
                            authorId,
                            isLoading: false,
                            content: (draftComment && draftComment.text) || text,
                            createdAt: moment(createTime).unix() * 1000,
                            assetsIds: files.map(({ id }) => id),
                            reactions,
                            replyId,
                        };
                    }),
                ),
            );

            const loginnedUser = getLoginUser(getState()).attributes;
            const loginnedUserId = loginnedUser.id;
            // let's rewrite it using canvas
            // oh man, this will be great
            const userIsSupervisor = loginnedUser.roles.some((role) => role.id === 5);
            let allowSupervisorToDeleteParticipant = false;
            if (userIsSupervisor) {
                const subdepartmentIds = (await DepartmentApi.getListByParent(loginnedUser.departmentId)).map(
                    (department) => department.id,
                );
                const usersBySubdepartment = users
                    .filter((user) => subdepartmentIds.includes(user.departmentId))
                    .map((user) => user.id);
                allowSupervisorToDeleteParticipant = usersBySubdepartment.some(
                    (user) => user === task.authorId || user === task.executorId,
                );
            }
            const participants = task.participants.map((participant) => {
                const { userId, invitingUserId } = participant;

                let canRemove = false;
                if (userId === task.authorId || userId === task.executorId) {
                    canRemove = false;
                } else if (
                    allowSupervisorToDeleteParticipant ||
                    loginnedUserId === task.authorId ||
                    loginnedUserId === task.executorId ||
                    loginnedUserId === invitingUserId ||
                    loginnedUserId === userId
                ) {
                    canRemove = true;
                }

                return {
                    userId,
                    canRemove,
                };
            });

            const canDelete = (activity && activity.authorId === loginnedUserId) || task.authorId === loginnedUserId;

            const userRate = (await TaskApi.getUserRate(taskId)) || null;

            if (!task.chanels.find((channel) => channel.id === channelId)) {
                dispatch(updateError('ChannelNotFound'));
            }

            /* tslint:disable:no-magic-numbers */
            result = {
                loginnedUserId,
                activity,
                assets,
                channels,
                comments,
                workTypes,
                departments,
                participants,
                canEdit: task.canEdit,
                canDelete,
                status: task.status,
                activityId: task.activityId,
                organizationId: activity.organizationId,
                assetsIds: task.files.map(({ id }) => id),
                workType: task.workTypeId,
                executorId: task.executorId,
                departmentExecutorId: task.departmentExecutorId,
                id: task.id,
                title: task.title,
                description: task.description,
                createdAt: moment(task.createTime).unix() * 1000,
                deadlineAt: moment(task.deadline).unix() * 1000,
                authorId: task.author.id,
                isUserSubscribed: task.subscription,
                isParticipantEditorFormVisible: false,
                editingParticipants: participants,
                telegram: convertTelegramStatusResponseToTelegramInfo(telegram),
                users,
                rate: userRate?.rate || null,
                createdBy: task.createdBy,
                budgetApproval: task.budgetApproval,
            };
        } catch (error) {
            const { response, message } = <AxiosError>error;

            if (response) {
                throw new FetchError(response.status, response.statusText, message);
            } else {
                throw new FetchError(0, 'Unknown', message);
            }
        }

        return result!;
    },
);
