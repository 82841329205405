import * as React from 'react';
import classNames from 'classnames';
import * as lodash from 'lodash';

import { ColumnName, CustomColumnColor } from '@store/budgetExecution';

import { CellBackgroundColor } from '../../LayerManager';
import { getQaCellColor } from '../misc';

import * as style from './DropdownCell.scss';

import {
    BodyPortal,
    Input_redesign,
    InputTheme_redesign,
    CustomScrollbar_new,
    WithTooltip,
    TooltipAnchor,
} from 'sber-marketing-ui';

export interface DropdownCellOptions {
    id: string;
    title: string;
    tooltip?: string | JSX.Element;
    order?: number;
    hide?: boolean;
    style?: React.CSSProperties;
}

interface Props {
    columnName: ColumnName;
    title: string;
    filter: string;
    isOpened: boolean;
    isDisabled: boolean;
    bgColor: CellBackgroundColor | CustomColumnColor;
    onOpenerClick: () => void;
    onMaskClick: () => void;
    onSearchChange: (value: string) => void;
}

export const DropdownCell = ({
    columnName,
    title,
    filter,
    isOpened,
    isDisabled,
    bgColor,
    onOpenerClick,
    onMaskClick,
    onSearchChange,
}: Props): JSX.Element => {
    const titleToDisplay = title || '-';

    return (
        <div
            title={titleToDisplay}
            className={classNames(style.root, isOpened && style.opened, isDisabled && style.disabled)}
            onClick={!isDisabled ? onOpenerClick : null}
            style={{ backgroundColor: bgColor }}
            {...{
                'qa-id': 'tableCell',
                'qa-index': columnName,
                'qa-disabled': isDisabled ? 'true' : 'false',
                ...getQaCellColor(bgColor),
            }}
        >
            {!isOpened ? (
                <div className={style.title}>{titleToDisplay}</div>
            ) : (
                <React.Fragment>
                    <div className={style.input}>
                        <Input_redesign
                            qaId={'tableDropdownCellInput'}
                            value={filter}
                            placeholder={'Поиск'}
                            theme={InputTheme_redesign.BriefPage}
                            onInputChange={onSearchChange}
                            autofocus
                        />
                    </div>

                    <BodyPortal>
                        <div className={style.mask} onClick={onMaskClick} />
                    </BodyPortal>
                </React.Fragment>
            )}
        </div>
    );
};

export interface DropdownContentProps {
    options: DropdownCellOptions[];
    selectedId: string;
    maxDropdownHeight: number;
    allowMultipleItems?: boolean;
    onOptionClick: (optionId: string) => void;
}

export const DropdownContent = ({
    options,
    selectedId,
    maxDropdownHeight,
    allowMultipleItems,
    onOptionClick,
}: DropdownContentProps): JSX.Element => {
    const selectedIds = allowMultipleItems ? (selectedId ? selectedId.split(',') : []) : [selectedId];

    const sortedOptions = lodash.orderBy(
        options.filter((option) => !option.hide),
        [
            (option) => selectedIds.includes(option.id),
            (option) => (option.id && option.title ? option.title.replace(/\"|\'/g, '') : ''),
        ],
        ['desc', 'asc'],
    );

    return (
        <div
            className={style.content}
            {...{
                'qa-id': 'tableCellContent',
            }}
        >
            <CustomScrollbar_new maxHeight={maxDropdownHeight}>
                <div className={style.options}>
                    {sortedOptions.map((item) => {
                        const title = item.title || '–';

                        return (
                            <WithTooltip
                                key={item.id}
                                hidden={!item.tooltip}
                                content={item.tooltip}
                                anchor={TooltipAnchor.RIGHT}
                            >
                                <div
                                    className={classNames(
                                        style.option,
                                        selectedIds.includes(item.id) && style.selected,
                                    )}
                                    onClick={() => onOptionClick(item.id)}
                                    style={{ ...item.style }}
                                    {...{
                                        'qa-id': item.id || 'none',
                                        'qa-value': title,
                                    }}
                                >
                                    <div className={style.title}>{title}</div>
                                </div>
                            </WithTooltip>
                        );
                    })}
                </div>
            </CustomScrollbar_new>
        </div>
    );
};
