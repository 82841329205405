import * as React from 'react';
import { connect } from 'react-redux';
import * as lodash from 'lodash';

import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type { Autopilot } from 'sber-marketing-types/backend';

import { FinalStageActionsBehaviour } from './FinalStageActionsBehaviour';
import type { StoreState } from '@store';
import { getLoginUser } from '@store/user/selector';
import { getActivity, getAutopilot } from '@store/autopilot_legacy/selectors';
import { WithProjectLinkedBudgetItems } from '@common/queries';
import { WithLinkedBudgetItems } from './WithLinkedBudgetItems';

interface Props extends MapProps, DispatchProps {
    canEdit: boolean;
}

interface MapProps {
    activity?: Activity;
    autopilot?: Autopilot;
    userOrganizationId?: string;
}

interface DispatchProps {
    setSelectedBudgetItemIds?: (ids: string[]) => void;
}

@(connect(mapStateToProps, null) as any)
export class FinalStageActionsConnector extends React.PureComponent<Props> {
    public render() {
        const { canEdit, activity, autopilot, userOrganizationId } = this.props;

        return (
            <WithProjectLinkedBudgetItems activityId={lodash.get(activity, 'id')}>
                {({ loading: loadingProectLinks, linkIds }) => (
                    <WithLinkedBudgetItems userOrganizationId={userOrganizationId} linkIds={linkIds}>
                        {({ loading: loadingLinkedBudgetItems, items }) =>
                            React.createElement(FinalStageActionsBehaviour, {
                                canEdit,
                                loading: loadingProectLinks || loadingLinkedBudgetItems,
                                autopilot,
                                linkedBudgetItems: items,
                            })
                        }
                    </WithLinkedBudgetItems>
                )}
            </WithProjectLinkedBudgetItems>
        );
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const currentUser = getLoginUser(state);

    return {
        autopilot: getAutopilot(state),
        activity: getActivity(state),
        userOrganizationId: currentUser.attributes.organizationId,
    };
}
