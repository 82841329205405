import * as React from 'react';
import * as lodash from 'lodash';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { UserResponseParams } from 'sber-marketing-types/frontend';
import { DictionaryType } from '@mrm/dictionary';
import { Tag } from '@mrm/tags';

import { StoreState } from '@store';
import { getTagsState } from '@store/tags';
import {
    ColumnName,
    loadFilters,
    LoadFiltersPaylaod,
    loadFiltersByColumn,
    getBudgetPlanningPageState,
    GroupedDictionaries,
} from '@store/budgetPlanning';

import { ColumnsList } from '../ColumnsConfig';

import { FilterMenu, Filters } from './FilterMenu';

interface Props extends OwnProps, Partial<MapProps & DispatchProps> {}

interface OwnProps {
    columnName: ColumnName;
    currencySearch: boolean;
    filterNamesAreNumeral?: boolean;
    onFiltersChange: (filters: Filters) => void;
}

interface DispatchProps {
    loadFiltersByColumn: () => void;
    loadFilters: (payload: LoadFiltersPaylaod) => void;
}

interface MapProps {
    showPreloader: boolean;
    areFiltersLoaded: boolean;
    filters?: Filters;
    users: UserResponseParams[];
    dictionaryType: DictionaryType;
    dictionaries: GroupedDictionaries;
    tags: lodash.Dictionary<Tag>;
}

interface State {
    selectedFilters: React.ReactText[];
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class FilterMenuContainer extends React.Component<Props, State> {
    private root: HTMLDivElement;

    constructor(props: Props) {
        super(props);

        this.state = {
            selectedFilters: Object.keys(props.filters).filter((filterItem) => props.filters[filterItem]),
        };
    }

    public async componentDidMount(): Promise<void> {
        this.root.focus();

        this.props.loadFiltersByColumn();
    }

    public render(): JSX.Element {
        const {
            filters,
            filterNamesAreNumeral,
            showPreloader,
            dictionaries,
            users,
            dictionaryType,
            columnName,
            currencySearch,
            tags,
        } = this.props;
        const { selectedFilters } = this.state;

        return React.createElement(FilterMenu, {
            showPreloader,
            filters,
            selectedFilters: [...selectedFilters],
            filterNamesAreNumeral,
            dictionaries,
            users,
            dictionaryType,
            columnName,
            currencySearch,
            tags,
            rootRef: this.rootRef,
            onFiltersChange: this.onFiltersChange,
        });
    }

    @autobind
    protected rootRef(element: HTMLDivElement) {
        this.root = element;
    }

    @autobind
    private onFiltersChange(selectedFilters: string[]): void {
        const updatedFilters = lodash.clone(this.props.filters);
        for (const filter in updatedFilters) {
            updatedFilters[filter] = selectedFilters.includes(filter);
        }

        this.props.loadFilters({
            columnName: this.props.columnName,
            filters: updatedFilters,
        });

        this.setState(
            {
                selectedFilters,
            },
            () => this.props.onFiltersChange(updatedFilters),
        );
    }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): MapProps {
    const { columnName } = ownProps;
    const {
        pageData: { allUsers, allDictionaries },
        filtersPreloader,
        columnFilters,
    } = getBudgetPlanningPageState(state);
    const column = ColumnsList.find((column) => column.name === columnName);
    const dictionaryType: DictionaryType = column.metaData && column.metaData.dictionaryType;

    const filters = columnFilters.filters[columnName];
    const areFiltersLoaded = !lodash.isEmpty(filters);

    const tags = getTagsState(state).byId.dictionary;

    return {
        showPreloader: filtersPreloader,
        users: allUsers,
        dictionaries: allDictionaries,
        areFiltersLoaded,
        filters,
        dictionaryType,
        tags,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps {
    const { columnName } = ownProps;

    return {
        loadFilters: (payload: LoadFiltersPaylaod) => dispatch(loadFilters(payload)),
        loadFiltersByColumn: () => dispatch(loadFiltersByColumn(columnName)),
    };
}
