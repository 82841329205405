import * as React from 'react';

import { useGetStagesQuery, useGetTaskQuery, useSetTaskMutation } from '@api';

import { SkeletonText, Tag, TagProps } from '@common/components';
import { TaskAccessAction, useTaskAccess } from '@modules/task/hooks';
import { StagesSelector } from '@modules/stages/components';

export interface TaskStageNameProps extends Omit<TagProps, 'value' | 'defaultValue' | 'onChange' | 'onSelect'> {
    taskId: string;
    activityId?: number;
}

export const TaskStageTag: React.FC<TaskStageNameProps> = ({
    taskId,
    activityId: propActivityId,
    children,
    ...props
}) => {
    const { isLoading: isTaskLoading, data: task } = useGetTaskQuery({ id: taskId });
    const [setTask, { isLoading: isTaskUpdating }] = useSetTaskMutation();
    const activityId = task?.task.activityId ?? propActivityId;
    const { isLoading: isStagesLoading, data: stages } = useGetStagesQuery(
        { activityIds: [activityId] },
        { skip: !activityId },
    );
    const {
        isLoading: isAccessLoading,
        access: [canChange],
    } = useTaskAccess([TaskAccessAction.changeStage], taskId, activityId);
    const isLoading = isTaskLoading || isStagesLoading || isAccessLoading;
    const isSelectable = canChange && Boolean(stages?.[0].stages?.length);

    const stageId = task?.task.stageId || null;
    const stage = stageId && stages?.[0].stages.find(({ id }) => id === stageId);

    const content = isLoading ? <SkeletonText width={58} /> : stage?.dictionary.name || 'Без этапа';

    if (!isSelectable) {
        return (
            <Tag {...props}>
                {content}
                {children}
            </Tag>
        );
    }

    const handleSelect = (value?: string) => {
        setTask({
            id: taskId,
            stageId: value || null,
        });
    };

    return activityId ? (
        <StagesSelector
            {...props}
            loading={isTaskUpdating}
            onSelect={handleSelect}
            exact
            activityId={activityId}
            selected={stageId}
        />
    ) : null;
};
