import { actionCreatorFactory } from 'typescript-fsa';
import { PlainDictionary } from '@mrm/dictionary';

import { EditStageParams } from '../types';

const creator = actionCreatorFactory('ACTIVITY_STAGES_ASYNC').async;

export const loadStagesData = creator<null, void, Error>('LOAD_STAGES_DATA');
export const saveActivityStages = creator<null, void, Error>('SAVE_ACTIVITY_STAGES');
export const editStage = creator<EditStageParams, void, Error>('SET_STAGE_STATUS');
export const selectStageTemplate = creator<string, void, Error>('SELECT_STAGE_TEMPLATE');
export const loadTaskTemaplates = creator<null, PlainDictionary[], Error>('LOAD_TASK_TEMPLATES');
