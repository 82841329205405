import * as React from 'react';
import autobind from 'autobind-decorator';
import { isNumber } from 'lodash';

import { AllAssetsStore, AssetsListMode } from './AllAssetsStore';
import { AllAssetsSortOrder } from '../../../../../../store/taskPage';

const DEFAULT_ASSETS_TO_SHOW = 3;

export interface AllAssetsContainerProps {
    className?: string;
    assetsToShow?: number;
    mode?: 'base' | 'current';
}

interface State {
    assetsToShow: number;
    mode?: AssetsListMode;
    sortOrder?: AllAssetsSortOrder;
}

/** All assets container component */
export class AllAssetsContainer extends React.Component<AllAssetsContainerProps, State> {
    public state: State = {
        assetsToShow: isNumber(this.props.assetsToShow) ? this.props.assetsToShow : DEFAULT_ASSETS_TO_SHOW,
        mode: AssetsListMode.LIST,
        sortOrder: AllAssetsSortOrder.UPLOAD_DATE,
    };

    public render(): JSX.Element {
        return (
            <AllAssetsStore
                className={this.props.className}
                onChangeDisplayModeClick={this.onChangeDisplayModeClick}
                onSortAssetsClick={this.onSortAssetsClick}
                onLoadMoreAssetsButtonClick={this.onLoadMoreAssetsButtonClick}
                sortOrder={this.state.sortOrder}
                listMode={this.state.mode}
                assetsToShow={this.state.assetsToShow}
                mode={this.props.mode}
            />
        );
    }
    /** Change display mode */
    @autobind
    protected onChangeDisplayModeClick(): void {
        this.setState((state) => ({
            mode: state.mode === AssetsListMode.LIST ? AssetsListMode.TILES : AssetsListMode.LIST,
        }));
    }
    /** Change sorting mode */
    @autobind
    protected onSortAssetsClick(): void {
        this.setState(({ sortOrder }) => ({
            sortOrder: this.getNextListMode(sortOrder),
        }));
    }
    /** Load more assets (expand hidden) */
    @autobind
    protected onLoadMoreAssetsButtonClick(assetsToShow: number): void {
        this.setState(() => ({ assetsToShow }));
    }
    /** Get next list mode */
    protected getNextListMode(currentMode: AllAssetsSortOrder): AllAssetsSortOrder {
        let result: AllAssetsSortOrder;
        switch (currentMode) {
            case AllAssetsSortOrder.UPLOAD_DATE: {
                result = AllAssetsSortOrder.NAME_ASC;
                break;
            }
            case AllAssetsSortOrder.NAME_ASC: {
                result = AllAssetsSortOrder.NAME_DESC;
                break;
            }
            case AllAssetsSortOrder.NAME_DESC: {
                result = AllAssetsSortOrder.UPLOAD_DATE;
                break;
            }
        }
        return result;
    }
}
