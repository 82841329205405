import * as React from 'react';
import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import { getFeatures } from '@store/autopilot/generationTextForm';

import * as styles from './styles.scss';

export const FeatureList: React.FC = () => {
    const features = useSelector((state: StoreState) => getFeatures(state));

    return (
        <div className={styles.root}>
            <div className={styles.title}>Ключевые особенности:</div>
            <div className={styles.list}>
                {features.map(({ value }) => (
                    <div key={value} className={styles.item}>
                        {value}
                    </div>
                ))}
            </div>
        </div>
    );
};
