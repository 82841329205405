import * as React from 'react';

import { BudgetWindow } from './BudgetWindow';
import { Mode } from './types';

interface Props {
    title: string;
    parentRef: React.RefObject<HTMLDivElement>;
    onCloseButtonClick: () => void;
    mode: Mode;
}

interface State {
    isTooltipVisible: boolean;
}

export class BudgetWindowContainer extends React.Component<Props, State> {
    public render() {
        const { title, onCloseButtonClick, parentRef, mode, children } = this.props;

        return React.createElement(BudgetWindow, {
            title,
            mode,
            parentRef,
            onCloseButtonClick,
            children,
        });
    }
}
