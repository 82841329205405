import { TaskCommentTransferObject } from 'sber-marketing-types/frontend';

import { TaskPageState } from '../types';

export const updateExistingCommentReducer = (
    state: TaskPageState,
    comment: TaskCommentTransferObject,
): TaskPageState => ({
    ...state,
    comments: {
        ...state.comments,
        [comment.id]: {
            ...state.comments[comment.id],
            reactions: comment.reactions,
        },
    },
});
