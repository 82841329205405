import * as React from 'react';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import * as moment from 'moment';

import { StoreState } from '@store';
import { getBudgetItemByLineId, getLineModalState } from '@store/budgetExecution';
import { BudgetItem } from '@mrm/budget';
import { StageName } from './types';

import { withLinkedActivities, WithCorrectionsProps } from './withLinkedActivities';
import { ActivityCardListData, RelatedActivities } from './RelatedActivities';

interface Props extends Partial<MapProps & WithCorrectionsProps> {
    lineId: string;
}

interface State {
    isOpenBindActivityPopup: boolean;
    isOpenActivitiesList: boolean;
}

interface MapProps {
    budgetItem: BudgetItem;
    activityIdToLink: string;
}

@(connect(mapStateToProps) as any)
@(withLinkedActivities as any)
export class RelatedActivitiesContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isOpenBindActivityPopup: false,
            isOpenActivitiesList: false,
        };
    }

    public componentDidMount() {
        if (this.havePresetActivityIdToLink) {
            this.setState({
                isOpenBindActivityPopup: true,
            });
        }
    }

    public render(): JSX.Element {
        const { lineId, activityIdToLink, budgetItem, linkedActivities, linkedActivitiesLoading } = this.props;

        const { isOpenBindActivityPopup, isOpenActivitiesList } = this.state;

        return React.createElement(RelatedActivities, {
            lineId,
            activityId: activityIdToLink,
            isOpenBindActivityPopup,
            isOpenActivitiesList,
            loading: linkedActivitiesLoading,
            disableBindActivityButton: !budgetItem.actions.canEdit,
            disableCreateActivityButton: !budgetItem.actions.canEdit,
            activitiesCards: this.getActivitiesCards(),
            activitiesCount: linkedActivities.length,
            onOpenActivityListButtonClick: this.onOpenActivityListButtonClick,
            onBindActivityButtonClick: this.onBindActivityPopup,
            onBindActivityFinish: this.onBindActivityFinish,
            onActivityPopupClose: this.onActivityPopupClose,
        });
    }

    @autobind
    private onActivityPopupClose(): void {
        this.setState({ isOpenBindActivityPopup: false });
    }

    @autobind
    private onBindActivityPopup(): void {
        this.setState({ isOpenBindActivityPopup: true });
    }

    @autobind
    private onOpenActivityListButtonClick(): void {
        this.setState(({ isOpenActivitiesList }) => ({ isOpenActivitiesList: !isOpenActivitiesList }));
    }

    @autobind
    private async onBindActivityFinish(): Promise<void> {
        const { updateLinkedActivities } = this.props;

        updateLinkedActivities();
    }

    private get havePresetActivityIdToLink(): boolean {
        return Boolean(this.props.activityIdToLink);
    }

    private getActivitiesCards(): ActivityCardListData[] {
        const convertedActivities = this.getConvertedLinkedActivities();
        return this.state.isOpenActivitiesList ? convertedActivities : convertedActivities.slice(0, 1);
    }

    private getConvertedLinkedActivities(): ActivityCardListData[] {
        const { budgetItem, updateLinkedActivities } = this.props;

        return this.props.linkedActivities.map(
            ({ id, name, product, author, division, realizationStart, realizationEnd, debriefing, permissions }) => ({
                id: Number(id),
                budgetItemId: budgetItem.id,
                productName: (product && product.name) || '',
                organizationAndBlockName: `${(author && author.organizationName) || ''}, ${
                    (division && division.name) || ''
                }`,
                authorName: author ? `${author.secondName || ''} ${author.firstName || ''}` : '',
                activityName: name,
                canUnbind: budgetItem.actions.canEdit,
                canRead: (permissions && permissions.read) || false,
                startDate: moment(realizationStart),
                endDate: moment(realizationEnd),
                stage: getStage(moment(realizationStart), moment(realizationEnd), moment(debriefing)),
                onUnbindActivityFinish: () => updateLinkedActivities(),
            }),
        );
    }
}

function mapStateToProps(store: StoreState, { lineId }: Props): MapProps {
    const { activityIdToLink } = getLineModalState(store);

    return {
        budgetItem: getBudgetItemByLineId(store, lineId),
        activityIdToLink,
    };
}

function getStage(start: moment.Moment, end: moment.Moment, debriefing?: moment.Moment): StageName {
    return moment().isAfter(end)
        ? debriefing
            ? moment().isAfter(debriefing)
                ? StageName.COMPLETED
                : StageName.RESULTS
            : StageName.COMPLETED
        : moment().isBefore(start)
        ? StageName.PREPARATION
        : StageName.EXECUTION;
}
