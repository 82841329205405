import { createSelector } from 'reselect';
import { HistoryActionType } from 'sber-marketing-types/backend';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getNewsFilters as getSelectedNewsFilters } from '@store/userConfig';
import { News, NewsItemParams, NewsFilters, NewsFiltersForRequest } from './types';
import createCachedSelector from 're-reselect';

export const getNewsState = (state: StoreState): News => state.news;

export const getStatus = createSelector(getNewsState, ({ status }): LoadingStatus => status);

export const getCanBeLoadedMore = createSelector(getNewsState, ({ canBeLoadedMore }): boolean => canBeLoadedMore);

export const getError = createSelector(getNewsState, ({ error }): Error | null => error);

export const getNewsList = createSelector(getNewsState, ({ entities }): NewsItemParams[] => entities);

export const getNewsFilters = createSelector(getNewsState, ({ filters }): NewsFilters => filters);

export const getNewsFiltersForRequest = createCachedSelector(
    getNewsFilters,
    getSelectedNewsFilters,
    (filters, selectedNewsFilters): NewsFiltersForRequest => {
        function shouldUseFilter(filter: string) {
            return selectedNewsFilters[filter].length && selectedNewsFilters[filter].length !== filters[filter].length;
        }

        return {
            ...(shouldUseFilter('type') && {
                types: selectedNewsFilters.type as HistoryActionType[],
            }),
            ...(shouldUseFilter('activity') && {
                activityIds: selectedNewsFilters.activity as number[],
            }),
            ...(shouldUseFilter('author') && {
                authorIds: selectedNewsFilters.author as number[],
            }),
            unseen: selectedNewsFilters.onlyUnseen,
        };
    },
)((state) => {
    const { type, activity, author, onlyUnseen } = getSelectedNewsFilters(state);

    return `${type.join()}-${activity.join()}-${author.join()}-${onlyUnseen ? 'true' : 'false'}`;
});
