import * as React from 'react';
import classNames from 'classnames';
import * as lodash from 'lodash';

import * as style from '../DateFilter.scss';

export interface DayData {
    year: number;
    month: number;
    day: number;
    width: number;
}

interface Props {
    scroll: number;
    days: DayData[];
    visibleDaysIndexes: number[];
}

export const Days: React.SFC<Props> = ({ scroll, days, visibleDaysIndexes }: Props): JSX.Element => {
    return (
        <div
            className={classNames(style.slider, style.fullWidth)}
            style={{ marginLeft: `-${scroll * 100}%` }}
            {...{
                'qa-id': 'days',
            }}
        >
            {days.map(
                ({ year, month, day, width }, index) =>
                    lodash.includes(visibleDaysIndexes, index) && (
                        <div
                            key={`year ${year} month ${month} day ${day}`}
                            className={style.daySliderItem}
                            style={{
                                left: `${width * index}%`,
                                width: `${width}%`,
                            }}
                            {...{
                                'qa-id': 'day',
                                'qa-label': day,
                            }}
                        >
                            {day}
                        </div>
                    ),
            )}
        </div>
    );
};
