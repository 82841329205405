import * as React from 'react';
import classNames from 'classnames';

import * as style from './ActivityGroup.scss';

import type { ScrollbarComponent } from 'sber-marketing-ui';
import type { LinesGroup } from '@store/calendar/types';

import { CustomScrollbar_new as CustomScrollbar } from 'sber-marketing-ui';
import { Canvas } from './Canvas';

const BLOCK_NAME_HEIGHT = 44;
const LINE_HEIGHT = 32;
const PADDING_BOTTOM = 6;

interface Props {
    group: LinesGroup;
    fullWidth: number;
    viewportWidth: number;
    activeItemHovered: boolean;
    canvasRef: (element: HTMLCanvasElement, groupId: string) => void;
    scrollbarRef: (component: ScrollbarComponent, groupId: string) => void;
    scrollContentRef: (element: HTMLDivElement, groupId: string) => void;
    onScroll: (scrollLeft: number, groupId: string) => void;
    onClick: () => void;
}

export function ActivityGroup({
    group,
    fullWidth,
    viewportWidth,
    activeItemHovered,
    canvasRef,
    scrollbarRef,
    scrollContentRef,
    onScroll,
    onClick,
}: Props): JSX.Element {
    const linesCount = group.lines.length - 1;

    let groupHeight = BLOCK_NAME_HEIGHT + LINE_HEIGHT * linesCount;

    if (group.isExpanded && group.lines.length > 1) {
        groupHeight += PADDING_BOTTOM;
    }

    return (
        <div className={style.root}>
            <Canvas
                width={viewportWidth}
                height={groupHeight}
                canvasRef={(element: HTMLCanvasElement) => canvasRef(element, group.id)}
            />

            <div className={style.scrollbar}>
                <CustomScrollbar
                    scrollbarRef={(component: ScrollbarComponent) => scrollbarRef(component, group.id)}
                    onScroll={({ scrollLeft }) => onScroll(scrollLeft, group.id)}
                    hideScrollX
                    hideScrollY
                >
                    <div
                        className={classNames(style.scrollContent, activeItemHovered && style.itemHovered)}
                        ref={(element) => scrollContentRef(element, group.id)}
                        style={{
                            width: fullWidth,
                            height: groupHeight,
                        }}
                        onClick={onClick}
                    />
                </CustomScrollbar>
            </div>
        </div>
    );
}
