import { BuildData, StatisticsActivityType } from './types';
import { Color } from '../../../../common/enums';

export const buildActivityData: BuildData<'activities'> = ({ statisticsItem, statisticsTypeFilters }) => ({
    id: statisticsItem.id,
    name: statisticsItem.meta.name,
    bar: {
        first: {
            value: statisticsTypeFilters[StatisticsActivityType.OVERDUE_TASKS]
                ? statisticsItem.statistics.current.withOverdueTasksCount
                : 0,
            color: Color.RED,
        },
        second: {
            value: statisticsTypeFilters[StatisticsActivityType.OVERDUE_STAGES]
                ? statisticsItem.statistics.current.withOverdueStagesCount
                : 0,
            color: Color.MUSTARD,
        },
        third: {
            value: statisticsTypeFilters[StatisticsActivityType.ACTIVE]
                ? statisticsItem.statistics.current.activeCount
                : 0,
            color: Color.GREEN,
        },
        fourth: {
            value: statisticsTypeFilters[StatisticsActivityType.PLANNED_START]
                ? statisticsItem.statistics.current.planningCount
                : 0,
            color: Color.BLUE,
        },
    },
});
