import { TaskStatus } from 'sber-marketing-types/frontend';

import { useGetTaskQuery } from '@api';
import { useIsTaskAuthor } from '@modules/task/hooks/useIsTaskAuthor/useIsTaskAuthor';

export function useTaskStatusButtonShow(taskId: string) {
    const { isAuthor, isLoading } = useIsTaskAuthor(taskId);
    const { data: { task } = {} } = useGetTaskQuery({ id: taskId });

    return !isLoading && isAuthor && task.status === TaskStatus.Draft;
}
