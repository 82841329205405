import { axios } from '../lib/axios';
import { Stage as GraphqlStage } from '@mrm/activity-stage';
import { Stage, CreateStageParams, EditStageParams, ListStagesParams } from '@mrm/stage';

const BASE_URL = '/api/stage';

export class StageApi {
    public static async createStage(params: CreateStageParams): Promise<void> {
        await axios.post(BASE_URL, params);
    }

    public static async editStage(id: string, params: EditStageParams): Promise<void> {
        await axios.put(`${BASE_URL}/${id}`, params);
    }

    public static async deleteStage(id: string): Promise<void> {
        await axios.delete(`${BASE_URL}/${id}`);
    }

    public static async listStages(params: ListStagesParams): Promise<Stage[]> {
        const res = await axios.post<Stage[]>(`${BASE_URL}/list`, params);

        return res.data;
    }

    public static async graphqlGetActivityStages(activityIds: number[]): Promise<GraphqlStage[]> {
        return (
            await axios.post(`/api/graphql`, {
                operationName: 'getActivityStages',
                query: `query getActivityStages { activityStages:getProjects(filter:{ids:[${activityIds}]}){ nodes { id stages { id start end isDone isActive dictionary { id name templateId weight } } }} }`,
            })
        ).data.data.activityStages.nodes;
    }
}
