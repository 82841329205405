import { axios, AxiosResponse } from '../lib/axios';

import { Calendar } from '@mrm/calendar';

const BASE_URL = '/api/calendar';

export class CalendarApi {
    public static async getCalendar(): Promise<Calendar> {
        const res: AxiosResponse<Calendar> = await axios.get(BASE_URL);

        return res.data;
    }
}
