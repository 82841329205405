import * as React from 'react';
import * as styles from './IndicatorList.scss';

interface Props {
    indicators: JSX.Element[];
}

export const IndicatorList: React.FC<Props> = ({ indicators }) => (
    <div className={styles.root}>
        {indicators.map((indicator, index) => (
            <div key={index} className={styles.indicatorWrapper}>
                {indicator}
            </div>
        ))}
    </div>
);
