import * as React from 'react';

interface Props {
    width: number;
    height: number;
    canvasRef: (element: HTMLCanvasElement) => void;
}

export function Canvas({ width, height, canvasRef }: Props): JSX.Element {
    return (
        <canvas
            style={{
                height,
                width,
            }}
            ref={canvasRef}
        />
    );
}
