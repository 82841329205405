import { createSelector } from 'reselect';
import { isNil } from 'lodash';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { UserOrganizations } from './types';

const getUserOrganizations = (state: StoreState): UserOrganizations => state.userOrganizations;

export const getStatus = createSelector(getUserOrganizations, ({ status }): LoadingStatus => status);

export const getError = createSelector(getUserOrganizations, ({ error }): Error | null => error);

export const getUserOrganizationsIds = createSelector(getUserOrganizations, ({ data }): string[] =>
    !isNil(data) ? [data.organization.id, ...data.contractors.map(({ id }) => id)] : [],
);
