import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { IconType } from 'sber-marketing-ui';
import type { Autopilot, AutopilotPlacementName, AutopilotPlacementStatus } from 'sber-marketing-types/backend';
import type { User } from '@store/user';

import { AutopilotWidgetTemplate, StageProps, PlacementItemProps } from './AutopilotWidgetTemplate';
import type { StoreState } from '@store';
import { getLoginUser } from '@store/user';
import { getAutopilotLegacy } from '@store/activityPage/selectors';
import { loadAutopilotLegacy } from '@store/activityPage/thunks';
import { AutopilotLegacyApi } from '@api';

interface Props extends MapProps, DispatchProps {}

interface MapProps {
    activityId?: number;
    autopilot?: Autopilot;
    user?: User;
}

interface DispatchProps {
    loadAutopilotLegacy?: (activityId: number) => Promise<void>;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class AutopilotWidgetBehaviour extends React.PureComponent<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        const { autopilot } = this.props;

        if (!autopilot) {
            return null;
        }

        return React.createElement(AutopilotWidgetTemplate, {
            dashboardUrl: lodash.get(autopilot, 'dashboard.url') as string,
            userHasAutopilotClientAccess: this.checkUserAutopilotClientAccess(),
            stages: this.makeStages(),
            placementItems: this.makePlacementItems(),
            onDashboardUrlChange: this.onDashboardUrlChange,
            onStatusSelection: this.onStatusSelection,
        });
    }

    @autobind
    private async onDashboardUrlChange(value: string) {
        const { activityId, autopilot } = this.props;

        await AutopilotLegacyApi.updateDashboard(autopilot.id, { url: value });
        await this.props.loadAutopilotLegacy(activityId);
    }

    @autobind
    private async onStatusSelection(name: AutopilotPlacementName, status: AutopilotPlacementStatus) {
        const { activityId, autopilot } = this.props;

        await AutopilotLegacyApi.updatePlacement(autopilot.id, { name, status });
        await this.props.loadAutopilotLegacy(activityId);
    }

    private makeStages(): StageProps[] {
        const { activityId, autopilot } = this.props;

        if (!autopilot) {
            return [];
        }

        const { brief, mediaplan, budget, creative, placements } = autopilot;

        const briefIsFinished = brief
            ? [
                  brief.block,
                  brief.product,
                  brief.budget,
                  brief.dateStart,
                  brief.dateEnd,
                  brief.age_from,
                  brief.age_to,
              ].every((item) => (typeof item == 'number' ? item !== null : !lodash.isEmpty(item)))
            : false;

        const mediaplanIsFinished = mediaplan ? !lodash.isEmpty(mediaplan.url) : false;

        const budgetIsFinished = budget ? !lodash.isEmpty(budget.budgetIds) : false;

        const creativeCounter = creative
            ? [creative.vk, creative.ok].filter((item) => !lodash.isEmpty(item)).length
            : 0;

        const creativeIsFinished = creativeCounter == 2;

        return [
            {
                stageOrder: 1,
                name: 'Бриф',
                title: briefIsFinished ? 'Заполнен' : 'Не заполнен',
                url: `/activity/${activityId}/autopilot?stage=1`,
                isFinished: briefIsFinished,
            },
            {
                stageOrder: 2,
                name: 'Медиаплан',
                title: mediaplanIsFinished ? 'Рассчитан' : 'Не рассчитан',
                url: `/activity/${activityId}/autopilot?stage=2`,
                isFinished: mediaplanIsFinished,
            },
            {
                stageOrder: 3,
                name: 'Бюджет',
                title: budgetIsFinished ? `Привязано ${budget.budgetIds.length} ID` : 'Не заполнен',
                url: `/activity/${activityId}/autopilot?stage=3`,
                isFinished: budgetIsFinished,
            },
            {
                stageOrder: 4,
                name: 'Креатив',
                title: `Заполнено ${creativeCounter}/2`,
                url: `/activity/${activityId}/autopilot?stage=4`,
                isFinished: creativeIsFinished,
            },
            {
                stageOrder: 5,
                name: 'Финальное согласование',
                title: placements && placements.length > 0 ? 'Согласовано' : 'Не согласовано',
                url: `/activity/${activityId}/autopilot?stage=5`,
                isFinished: placements && placements.length > 0 ? true : false,
            },
        ];
    }

    private makePlacementItems(): Omit<PlacementItemProps, 'canEditStatus' | 'onStatusSelection'>[] {
        const { autopilot } = this.props;

        if (!autopilot || !autopilot.placements) {
            return [];
        }

        const { placements } = autopilot;

        return [
            {
                name: 'vk',
                title: 'VK',
                icon: IconType.SOCIAL_VK,
                status: placements.find((item) => item.name === 'vk').status,
            },
            {
                name: 'ok',
                title: 'Одноклассники',
                icon: IconType.SOCIAL_OK,
                status: placements.find((item) => item.name == 'ok').status,
            },
        ];
    }

    private checkUserAutopilotClientAccess(): boolean {
        // check if user has role === Autopilot Client Service
        return this.props.user.attributes.roles.map(({ id }) => id).includes(24);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        autopilot: getAutopilotLegacy(state),
        user: getLoginUser(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            loadAutopilotLegacy,
        },
        dispatch,
    );
}
