import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { includes, values, orderBy } from 'lodash';

import { StoreState } from '@store';
import { Channel } from '../../../../../store/taskPage';
import { getLoginUser } from '../../../../../store/user';

import * as styles from './ChannelButtonsList.scss';
import { ChannelButtonsListTemplateProps, ChannelData } from './types';
import { ChannelButton, FakeChannelButton } from '../ChannelButton';
import { ChannelEditor } from '../ChannelEditor';

const ADD_CHANNEL = '+ Добавить канал';

const mapChannel = ({ id, title: name, authorId }: Channel): ChannelData => ({ id, name, authorId });

const filterChannel = ({ id, authorId, participantIds }: Channel, userId: number): boolean =>
    id === null || userId === authorId || includes(participantIds, userId);

/** "ChannelButtonsList" template component */
export const ChannelButtonsListTemplate: React.SFC<ChannelButtonsListTemplateProps> = ({
    className,
    isTaskOpened,
    isNewModalOpened,
    onNewChannelOpenClick,
    onNewChannelCloseClick,
    onTaskCardClick,
    activityId,
    taskId,
    from,
    channelId,
}: ChannelButtonsListTemplateProps): JSX.Element => {
    const userId = useSelector((state: StoreState) => getLoginUser(state).attributes.id);
    const allChannels = useSelector((state: StoreState) => state.taskPage.channels);

    const channels = orderBy(
        values(allChannels)
            .filter((channel) => filterChannel(channel, userId))
            .map(mapChannel),
        (channel) => channel.id,
        'desc',
    );

    return (
        <div className={classNames(styles.root, className)}>
            {channels.map(({ id, name, authorId, hasUnseenComments }) => (
                <ChannelButton
                    canDelete={!!id && authorId === userId}
                    key={id}
                    className={styles.item}
                    id={id}
                    name={name}
                    from={from}
                    isOpened={id === channelId}
                    hasUnseenComments={hasUnseenComments}
                    activityId={activityId}
                    taskId={taskId}
                    onClick={onTaskCardClick}
                />
            ))}

            {isTaskOpened && (
                <FakeChannelButton
                    className={styles.item}
                    name={ADD_CHANNEL}
                    activityId={activityId}
                    taskId={taskId}
                    onClick={onNewChannelOpenClick}
                />
            )}

            {isNewModalOpened && (
                <ChannelEditor
                    isNew={true}
                    isUserAuthor={true}
                    onCancel={onNewChannelCloseClick}
                    onSubmit={onNewChannelCloseClick}
                />
            )}
        </div>
    );
};

ChannelButtonsListTemplate.displayName = 'frontend::task-board::ChannelButtonsList::Template';
