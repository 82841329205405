import * as React from 'react';
import classNames from 'classnames';

import * as style from './UserCard.scss';

import { Icon, IconType } from 'sber-marketing-ui';

export interface UserCardProps {
    mainTitle: string;
    subTitle: string;
    theme?: string;
    canRemove?: boolean;
    onDelete?: () => void;
}

export const UserCardTheme = {
    Default: 'default',
    Dropdown: 'dropdown',
    Select: 'select',
};

export function UserCard(props: UserCardProps): JSX.Element {
    const { mainTitle, subTitle, theme = UserCardTheme.Default, canRemove, onDelete } = props;

    return (
        <div className={style.root}>
            <div className={style.mainTitle}>{mainTitle}</div>
            <div className={style.subTitle}>{subTitle}</div>

            <div className={style.iconsWrapper}>
                {theme == UserCardTheme.Dropdown && (
                    <div className={style.iconWrapper}>
                        <Icon type={IconType.ADD_ROUND} svgSize={'12px'} color={'#19bb4f'} />
                    </div>
                )}
                {theme == UserCardTheme.Select && (
                    <div className={classNames(style.iconWrapper, style.iconWrapper_select)}>
                        <Icon type={IconType.CHECKED_ROUND} svgSize={'12px'} color={'#19bb4f'} />
                    </div>
                )}
                {theme == UserCardTheme.Default && canRemove && (
                    <div className={classNames(style.iconWrapper, style.iconWrapper_default)} onClick={onDelete}>
                        <Icon type={IconType.DELETE_ROUND} svgSize={'12px'} color={'#999999'} />
                    </div>
                )}
            </div>
        </div>
    );
}
