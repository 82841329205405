import * as React from 'react';
import classNames from 'classnames';

import * as style from './StagesListWidget.scss';

import { Icon, IconType } from 'sber-marketing-ui';

import { ConfirmModal } from '../ConfirmModal';

interface Props {
    stages: StageProps[];
    displayAutopilotEnableButton: boolean;
    displayAutopilotDisableButton: boolean;
    displayPlacementsStopButton: boolean;
    displayAutopilotEnableConfirmModal: boolean;
    displayAutopilotDisableConfirmModal: boolean;
    displayPlacementsConfirmModal: boolean;
    onAutopilotButtonClick: () => void;
    onPlacementsButtonClick: () => void;
    onAutopilotEnableConfirm: () => void;
    onAutopilotDisableConfirm: () => void;
    onPlacementsStopConfirm: () => void;
    onModalCancel: () => void;
}

export const StagesListWidgetTemplate = ({
    stages,
    displayAutopilotEnableButton,
    displayAutopilotDisableButton,
    displayPlacementsStopButton,
    displayAutopilotEnableConfirmModal,
    displayAutopilotDisableConfirmModal,
    displayPlacementsConfirmModal,
    onAutopilotButtonClick,
    onPlacementsButtonClick,
    onAutopilotEnableConfirm,
    onAutopilotDisableConfirm,
    onPlacementsStopConfirm,
    onModalCancel,
}: Props): JSX.Element => {
    const finishedStagesCount = stages.filter((item) => item.isFinished).length;

    return (
        <div className={style.root}>
            <div className={style.topLine}>
                <div className={style.title}>
                    Этапы автопилота&nbsp;
                    <span className={style.titleCounter}>({finishedStagesCount}/5)</span>
                </div>

                {displayAutopilotEnableButton && (
                    <div className={classNames(style.button, style.enableButton)} onClick={onAutopilotButtonClick}>
                        Включить автопилот
                    </div>
                )}

                {displayAutopilotDisableButton && (
                    <div className={classNames(style.button, style.disableButton)} onClick={onAutopilotButtonClick}>
                        Выключить автопилот
                    </div>
                )}

                {displayPlacementsStopButton && (
                    <div className={classNames(style.button, style.disableButton)} onClick={onPlacementsButtonClick}>
                        Остановить размещения
                    </div>
                )}
            </div>

            <div className={style.stages}>
                {stages.map((item) => (
                    <Stage {...item} key={item.name} />
                ))}
            </div>

            {displayAutopilotEnableConfirmModal && (
                <ConfirmModal
                    title={'Внимание!'}
                    message={'Вы уверены, что хотите включить автопилот?'}
                    closeButtonText={'Отменить'}
                    confirmButtonText={'Включить'}
                    onCloseButtonClick={onModalCancel}
                    onConfirmButtonClick={onAutopilotEnableConfirm}
                />
            )}

            {displayAutopilotDisableConfirmModal && (
                <ConfirmModal
                    title={'Внимание!'}
                    message={'Вы уверены, что хотите выключить автопилот?'}
                    closeButtonText={'Отменить'}
                    confirmButtonText={'Выключить'}
                    onCloseButtonClick={onModalCancel}
                    onConfirmButtonClick={onAutopilotDisableConfirm}
                />
            )}

            {displayPlacementsConfirmModal && (
                <ConfirmModal
                    title={'Внимание!'}
                    message={'Вы уверены, что хотите выключить размещения?'}
                    closeButtonText={'Отменить'}
                    confirmButtonText={'Выключить'}
                    onCloseButtonClick={onModalCancel}
                    onConfirmButtonClick={onPlacementsStopConfirm}
                />
            )}
        </div>
    );
};

export interface StageProps {
    stageOrder: number;
    name: string;
    title: string;
    isActive: boolean;
    isFinished: boolean;
    onClick: (stageOrder: number) => void;
}

function Stage({ stageOrder, name, title, isActive, isFinished, onClick }: StageProps) {
    return (
        <div
            className={classNames(style.stage, isActive && style.active, isFinished && style.finished)}
            onClick={() => onClick(stageOrder)}
        >
            <div className={style.stageOrder}>
                {isFinished ? <Icon type={IconType.CHECK28} svgSize={28}></Icon> : stageOrder}
            </div>

            <div className={style.stageProperties}>
                <div className={style.stageName}>{name}</div>

                <div className={style.stageTitle}>{title}</div>
            </div>
        </div>
    );
}
