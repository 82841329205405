import * as React from 'react';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import * as moment from 'moment';

import {
    LineParams,
    TimeUnitGroup,
    StageCalendarItem,
    Point,
    Direction,
    TasksByStageId,
    DateMark,
    DateMarkColors,
} from './types';

import { StagesCalendar, ITEM_HEIGHT, CANVAS_OFFSET_TOP } from './StagesCalendar';
import { LinesMaker, SceneDrawer, Scaler, Drawer } from './modules';

const CANVAS_PADDING_RIGHT = 8;

interface Props {
    items: StageCalendarItem[];
    editable?: boolean;
    projectStart: Date;
    projectEnd: Date;
    tasksByStageId: TasksByStageId;
    onStageStatusChange?: (itemId: React.ReactText, newStageDoneStatus: boolean) => void;
    onSelectStageClick?: (stageId: string) => void;
    createTaskByNameAndStage?: (taskName: string, stageId: string) => Promise<void>;
}

interface State {
    lines: LineParams[];
    canvasWidth: number;
    hoveredLineIndex: number;
    canShowStageDuration: boolean;
    dateMarks: DateMark[];
}

export class StagesCalendarContainer extends React.Component<Props, State> {
    private canvas: HTMLCanvasElement;
    private viewportStart: Date;
    private viewportEnd: Date;
    private timeUnitGroups: TimeUnitGroup[];
    private sceneDrawer: SceneDrawer;
    private scaler: Scaler;

    constructor(props: Props) {
        super(props);

        this.sceneDrawer = new SceneDrawer();

        this.init();
        const dateMarks = this.initDateMarks();

        this.state = {
            lines: LinesMaker.makeStageLines(this.props.items),
            canvasWidth: null,
            hoveredLineIndex: null,
            canShowStageDuration: true,
            dateMarks,
        };
    }

    public componentDidMount() {
        this.canvas.addEventListener('mousemove', this.onMousemove);
        this.canvas.addEventListener('mouseleave', this.onGroupMouseLeave);
    }

    public componentWillUnmount() {
        this.canvas.removeEventListener('mousemove', this.onMousemove);
        this.canvas.removeEventListener('mouseleave', this.onGroupMouseLeave);
    }

    public componentDidUpdate(prevProps: Props) {
        const itemsChanged = this.props.items !== prevProps.items;

        if (itemsChanged) {
            this.init();

            this.setState(
                {
                    lines: LinesMaker.makeStageLines(this.props.items),
                },
                () => {
                    this.createScaler();
                    this.drawScene();
                },
            );
        }
    }

    public render(): JSX.Element {
        const canvasHeight = this.canvas ? Drawer.getCanvasHeight(this.canvas) : 0;

        return React.createElement(StagesCalendar, {
            dateMarks: this.state.dateMarks,
            scaler: this.scaler,
            canvasHeight,
            lines: this.state.lines,
            tasksByStageId: this.props.tasksByStageId,
            hoveredLineIndex: this.state.hoveredLineIndex,
            timeUnitGroups: this.timeUnitGroups,
            tooltipTitle: this.makeTooltipTitle(),
            tooltipPosition: this.makeTooltipPosition(),
            tooltipDirection: this.makeTooltipDirection(),
            isEditable: this.props.editable || false,
            canvasRef: this.canvasRef,
            onCanvasResize: this.onCanvasResize,
            onSidebarLineHover: this.onSidebarLineHover,
            onLineCheckboxClick: this.onLineCheckboxClick,
            setCanShowStageDuration: this.setCanShowStageDuration,
            setDateMarkVisibility: this.setDateMarkVisibility,
            onSelectStageClick: this.props.onSelectStageClick,
            createTaskByNameAndStage: this.props.createTaskByNameAndStage,
        });
    }

    @autobind
    private setCanShowStageDuration(canShowStageDuration: boolean): void {
        this.setState({ canShowStageDuration });
    }

    @autobind
    private canvasRef(element: HTMLCanvasElement) {
        this.canvas = element;

        this.sceneDrawer.addCanvas(this.canvas);
    }

    @autobind
    private onCanvasResize(width: number) {
        this.setState(
            {
                canvasWidth: width,
            },
            () => {
                this.createScaler();
                this.drawScene();
            },
        );
    }

    @autobind
    private onMousemove(event: MouseEvent) {
        const hoveredItemIndex = this.getHoveredLineIndex(event.offsetY);

        if (hoveredItemIndex !== this.state.hoveredLineIndex) {
            this.setHoveredItemIndex(hoveredItemIndex);
        }
    }

    @autobind
    private onGroupMouseLeave() {
        this.setHoveredItemIndex(null);
    }

    @autobind
    private onSidebarLineHover(lineIndex: number) {
        if (lineIndex !== this.state.hoveredLineIndex) {
            this.setHoveredItemIndex(lineIndex);
        }
    }

    @autobind
    private onLineCheckboxClick(lineIndex: number) {
        const item = this.props.items[lineIndex];

        if (this.props.onStageStatusChange) {
            this.props.onStageStatusChange(item.id, !item.done);
        }
    }

    @autobind
    private setDateMarkVisibility(date: Date, isVisible: boolean): void {
        this.setState((state) => ({
            ...state,
            dateMarks: state.dateMarks.map((dateMark) => ({
                ...dateMark,
                isVisible: date === dateMark.date ? isVisible : dateMark.isVisible,
            })),
        }));
    }

    private init() {
        const dates = lodash.compact(lodash.flatMap(this.props.items, (item) => [item.start, item.end]));

        const [minDate, maxDate] = this.getMinAndMaxDates(dates);

        const [viewportStart, viewportEnd] = this.getViewportDates(minDate, maxDate);

        this.viewportStart = viewportStart;
        this.viewportEnd = viewportEnd;

        this.timeUnitGroups = this.makeTimeUnitGroups();
    }

    private createScaler() {
        this.scaler = new Scaler({
            widthInPx: this.state.canvasWidth - CANVAS_PADDING_RIGHT,
            startDate: this.viewportStart,
            endDate: this.viewportEnd,
        });
    }

    private drawScene() {
        this.sceneDrawer.drawScene(
            this.state.lines,
            this.scaler,
            this.state.hoveredLineIndex,
            this.state.dateMarks,
            this.props.tasksByStageId,
        );
    }

    private getMinAndMaxDates(dates: Date[]): Date[] {
        const validDates = lodash.compact(dates);

        if (validDates.length <= 1) {
            return [moment().startOf('month').toDate(), moment().endOf('month').startOf('day').toDate()];
        }

        let minDate: Date = lodash.first(validDates);
        let maxDate: Date = lodash.first(validDates);

        validDates.forEach((item) => {
            if (item.valueOf() < minDate.valueOf()) {
                minDate = item;
            }

            if (item.valueOf() > maxDate.valueOf()) {
                maxDate = item;
            }
        });

        return [minDate, maxDate];
    }

    private getViewportDates(startDate: Date, endDate: Date): Date[] {
        let viewportDates: Date[];

        viewportDates = [startDate, moment(endDate).add(1, 'day').toDate()];

        return viewportDates;
    }

    private makeTimeUnitGroups(): TimeUnitGroup[] {
        const calendarStart = moment(this.viewportStart);
        const calendarEnd = moment(this.viewportEnd);

        let groups: TimeUnitGroup[];

        if (calendarEnd.diff(calendarStart, 'days') <= 31) {
            groups = this.makeDaysTimeUnitGroups(calendarStart, calendarEnd);
        } else if (calendarEnd.diff(calendarStart, 'months') <= 6) {
            groups = this.makeMonthsTimeUnitGroups(calendarStart, calendarEnd);
        } else if (calendarEnd.diff(calendarStart, 'quarters') <= 4) {
            groups = this.makeQuartersTimeUnitGroups(calendarStart, calendarEnd);
        } else {
            groups = this.makeYearsTimeUnitGroups(calendarStart, calendarEnd);
        }

        return groups.filter((item) => !lodash.isEmpty(item.items));
    }

    private makeDaysTimeUnitGroups(calendarStart: moment.Moment, calendarEnd: moment.Moment): TimeUnitGroup[] {
        let groups: TimeUnitGroup[];

        const months: moment.Moment[] = [];
        const currentDate = calendarStart.clone();

        while (calendarEnd > currentDate || currentDate.format('M') === calendarEnd.format('M')) {
            months.push(currentDate.clone());
            currentDate.add(1, 'month');
        }

        groups = months.map((month, index) => {
            const isFirstMonth = index == 0;
            const isLastMonth = index == months.length - 1;

            const start = isFirstMonth ? calendarStart.date() : 1;
            const end = isLastMonth ? calendarEnd.date() : month.daysInMonth() + 1;

            const days = lodash.range(start, end);

            return {
                title: days.length > 4 ? month.format('MMMM YYYY') : '',
                items: days.map((item) => ({
                    title: item.toString(),
                    daysCount: 1,
                })),
                daysCount: days.length,
            };
        });

        return groups;
    }

    private makeMonthsTimeUnitGroups(calendarStart: moment.Moment, calendarEnd: moment.Moment): TimeUnitGroup[] {
        let groups: TimeUnitGroup[];

        const quarters: moment.Moment[] = [];
        const currentDate = calendarStart.clone();

        while (calendarEnd > currentDate || currentDate.format('Q YYYY') === calendarEnd.format('Q YYYY')) {
            quarters.push(currentDate.clone());
            currentDate.add(1, 'quarter');
        }

        groups = quarters.map((quarter, quarterIndex) => {
            const isFirstQuarter = quarterIndex == 0;
            const isLastQuarter = quarterIndex == quarters.length - 1;

            const start = isFirstQuarter ? calendarStart.month() : 3 * (quarter.quarter() - 1);
            const end = isLastQuarter ? calendarEnd.month() : 3 * (quarter.quarter() - 1) + 2;

            const months = lodash.range(start, end + 1);

            const monthItems = months.map((month, monthIndex) => {
                const isFirstMonthInQuarter = monthIndex == 0;
                const isLastMonthInQuarter = monthIndex == months.length - 1;

                const monthStartDay = isFirstQuarter && isFirstMonthInQuarter ? calendarStart.date() : 1;
                const monthEndDay =
                    isLastQuarter && isLastMonthInQuarter
                        ? calendarEnd.date()
                        : quarter.clone().month(month).daysInMonth();

                return {
                    title: moment(month + 1, 'M').format('MMMM'),
                    daysCount: monthEndDay - monthStartDay + 1,
                };
            });

            return {
                title: `${quarter.format('Q')} квартал ${quarter.format('YYYY')}`,
                items: monthItems,
                daysCount: lodash.sumBy(monthItems, (item) => item.daysCount),
            };
        });

        groups = this.removeShortItemsTitles(groups);

        return groups;
    }

    private makeQuartersTimeUnitGroups(calendarStart: moment.Moment, calendarEnd: moment.Moment): TimeUnitGroup[] {
        let groups: TimeUnitGroup[];

        const years: moment.Moment[] = [];
        const currentDate = calendarStart.clone();

        while (calendarEnd > currentDate || currentDate.format('YYYY') === calendarEnd.format('YYYY')) {
            years.push(currentDate.clone());
            currentDate.add(1, 'year');
        }

        groups = years.map((year, index) => {
            const isFirstYear = index == 0;
            const isLastYear = index == years.length - 1;

            const start = isFirstYear ? calendarStart.quarter() : 1;
            const end = isLastYear ? calendarEnd.quarter() : 4;

            const quarters = lodash.range(start, end + 1);

            const quarterItems = quarters.map((quarter, quarterIndex) => {
                const isFirstQuarter = quarterIndex == 0;
                const isLastQuarter = quarterIndex == quarters.length - 1;

                const start = isFirstQuarter && isFirstYear ? calendarStart.month() : 3 * (quarter - 1);
                const end = isLastQuarter && isFirstYear ? calendarEnd.month() : 3 * (quarter - 1) + 2;

                const quarterMonths = lodash.range(start, end + 1);

                const quarterMonthsDaysCount = quarterMonths.map((month, monthIndex) => {
                    const isFirstMonthInQuarter = monthIndex == 0;
                    const isLastMonthInQuarter = monthIndex == quarterMonths.length - 1;

                    const monthStartDay = isFirstQuarter && isFirstMonthInQuarter ? calendarStart.date() : 1;
                    const monthEndDay =
                        isLastQuarter && isLastMonthInQuarter
                            ? calendarEnd.date()
                            : year
                                  .clone()
                                  .month(month + quarter * 3)
                                  .daysInMonth();

                    return monthEndDay - monthStartDay + 1;
                });

                return {
                    title: `${quarter} квартал ${year.format('YYYY')}`,
                    daysCount: lodash.sum(quarterMonthsDaysCount),
                };
            });

            return {
                title: year.format('YYYY'),
                items: quarterItems,
                daysCount: lodash.sumBy(quarterItems, (item) => item.daysCount),
            };
        });

        groups = this.removeShortItemsTitles(groups);

        return groups;
    }

    private makeYearsTimeUnitGroups(calendarStart: moment.Moment, calendarEnd: moment.Moment): TimeUnitGroup[] {
        let groups: TimeUnitGroup[];

        const years: moment.Moment[] = [];
        const currentDate = calendarStart.clone();

        while (calendarEnd > currentDate || currentDate.format('YYYY') === calendarEnd.format('YYYY')) {
            years.push(currentDate.clone());
            currentDate.add(1, 'year');
        }

        const title =
            years.length > 1
                ? `${lodash.first(years).format('YYYY')} — ${lodash.last(years).format('YYYY')}`
                : lodash.first(years).format('YYYY');

        groups = [
            {
                title,
                items: years.map((item, index) => {
                    let daysCountInYear: number;

                    const isFirstYear = index == 0;
                    const isLastYear = index == years.length - 1;

                    if (isFirstYear) {
                        const end = item.clone().endOf('year').add(1, 'day');

                        daysCountInYear = end.diff(calendarStart, 'days');
                    } else if (isLastYear) {
                        const start = item.clone().startOf('year');

                        daysCountInYear = calendarEnd.diff(start, 'days');
                    } else {
                        daysCountInYear = item.isLeapYear() ? 366 : 365;
                    }

                    return {
                        title: item.year().toString(),
                        daysCount: daysCountInYear,
                    };
                }),
                daysCount: years.length,
            },
        ];

        groups = this.removeShortItemsTitles(groups);

        return groups;
    }

    private removeShortItemsTitles(groups: TimeUnitGroup[]): TimeUnitGroup[] {
        return groups.map((group) => {
            const totalDaysCount = lodash.sumBy(groups, (item) => item.daysCount);

            const groupTitleDoesntFit = group.daysCount / totalDaysCount < 0.2;

            if (groupTitleDoesntFit) {
                group.title = '';
            }

            group.items.map((item) => {
                const itemTitleDoesntFit = item.daysCount / lodash.sumBy(groups, (item) => item.daysCount) < 0.2;

                if (groupTitleDoesntFit || itemTitleDoesntFit) {
                    item.title = '';
                }

                return item;
            });

            return group;
        });
    }

    private setHoveredItemIndex(hoveredLineIndex: number) {
        this.setState(
            {
                hoveredLineIndex,
            },
            () => {
                this.drawScene();
            },
        );
    }

    private getHoveredLineIndex(y: number): number {
        if (y <= CANVAS_OFFSET_TOP) {
            return null;
        }

        return Math.floor((y - CANVAS_OFFSET_TOP) / ITEM_HEIGHT);
    }

    private makeTooltipTitle(): string {
        const { lines, hoveredLineIndex } = this.state;

        if (hoveredLineIndex === null || !lines[hoveredLineIndex] || !this.scaler) {
            return null;
        }

        const { start, end } = lines[hoveredLineIndex];

        const isOneDayStage = moment(end).diff(moment(start), 'days') == 0;

        let title: string;

        if (!start || !end) {
            title = 'Даты не назначены';
        } else if (isOneDayStage) {
            title = this.formatDate(this.getMiddleOfDay(start));
        } else {
            title = `${this.formatDate(this.getMiddleOfDay(start))} — ${this.formatDate(this.getMiddleOfDay(end))}`;
        }

        return title;
    }

    private makeTooltipDirection(): Direction {
        const { lines, hoveredLineIndex } = this.state;

        if (hoveredLineIndex === null || !lines[hoveredLineIndex] || !this.scaler) {
            return null;
        }

        const { start, end } = lines[hoveredLineIndex];

        return start && end ? Direction.Top : Direction.Right;
    }

    private makeTooltipPosition(): Point {
        const { lines, hoveredLineIndex, canShowStageDuration } = this.state;

        if (!canShowStageDuration || hoveredLineIndex === null || !lines[hoveredLineIndex] || !this.scaler) {
            return null;
        }

        const { id, start, end } = lines[hoveredLineIndex];

        if (!id) {
            return null;
        }

        const x =
            start && end
                ? (this.scaler.convertDateToPx(this.getMiddleOfDay(start).toDate()) +
                      this.scaler.convertDateToPx(this.getMiddleOfDay(end).toDate())) /
                  2
                : 0;

        const y = CANVAS_OFFSET_TOP + hoveredLineIndex * ITEM_HEIGHT - ITEM_HEIGHT / 2;

        return { x, y };
    }

    private getMiddleOfDay(date: Date): moment.Moment {
        return moment(date).add(12, 'hours');
    }

    private formatDate(date: moment.Moment): string {
        return date.format('DD MMMM YYYY');
    }

    private validateDate(date: Date): Date {
        return moment(date).startOf('day').add(15, 'hours').toDate();
    }

    private initDateMarks(): DateMark[] {
        const projectStart = this.props.projectStart.getTime();
        const projectEnd = this.props.projectEnd.getTime();
        const viewportStart = this.viewportStart.getTime();
        const viewportEnd = this.viewportEnd.getTime();

        const canUseStartDate = projectStart > viewportStart && projectStart < viewportEnd;
        const canUseEndDate = projectEnd > viewportStart && projectEnd < viewportEnd;

        const dateMarksToAdd: Partial<DateMark>[] = [
            {
                date: new Date(),
                isVisible: true,
                isMarkerVisible: false,
                color: DateMarkColors.Green,
            },
        ];
        const dateMark: Partial<DateMark> = {
            isVisible: false,
            isMarkerVisible: true,
            color: DateMarkColors.Grey,
        };

        if (projectStart === projectEnd) {
            if (canUseStartDate) {
                dateMarksToAdd.push({
                    ...dateMark,
                    date: this.props.projectStart,
                    label: 'Дата старта и окончания проекта',
                });
            }
        } else {
            if (canUseStartDate) {
                dateMarksToAdd.push({
                    ...dateMark,
                    date: this.props.projectStart,
                    label: 'Дата старта проекта',
                });
            }

            if (canUseEndDate) {
                dateMarksToAdd.push({
                    ...dateMark,
                    date: this.props.projectEnd,
                    label: 'Дата окончания проекта',
                });
            }
        }

        return dateMarksToAdd.map(
            (dateMark) =>
                ({
                    ...dateMark,
                    date: this.validateDate(dateMark.date),
                } as DateMark),
        );
    }
}
