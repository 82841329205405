import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { TaskApi } from '@api';

import { StoreState } from '@store';
import { asyncActions } from '@store/taskPage/actions';
import { getTaskPageState } from '@store/taskPage/selectors';
import { SetRateSuccess, SetRatePayload, FetchError } from '@store/taskPage/types';

export const setRate = bindThunkAction<StoreState, SetRatePayload, SetRateSuccess, FetchError>(
    asyncActions.setRate,
    async ({ rate }, _, getState) => {
        try {
            const state = getState();
            const taskId = getTaskPageState(state).id;

            await TaskApi.setRate(taskId, { rate });
            return { rate };
        } catch (error) {
            return { rate: 0 };
        }
    },
);
