import * as React from 'react';

import * as style from './BudgetStageWidget.scss';

import { Preloader } from 'sber-marketing-ui';
import { CardGroup } from './CardGroup';
import { BudgetCard, CardProps } from './BudgetCard';
import { MrmClient } from '@api';
import { useParams } from 'react-router-dom';

interface Props {
    loading: boolean;
    visibleAddButton: boolean;
    cards: CardProps[];
    onAddButtonClick: () => void;
}

export const BudgetStageWidgetTemplate = ({
    loading,
    visibleAddButton,
    cards = [],
    onAddButtonClick,
}: Props): JSX.Element => {
    const { creativeRequestId } = useParams<{ creativeRequestId: string }>();
    const [hasAddBudgetItem, setHasAddBudgetItem] = React.useState<boolean>(false);

    React.useEffect(() => {
        checkAddBudgetItem();
    }, []);

    const checkAddBudgetItem = async () => {
        const client = await MrmClient.getInstance();
        const creativeRequests = await client.domain.creativeRequests.getCreativeRequest({ id: creativeRequestId });
        if (creativeRequests.model.addBudgetItem) {
            setHasAddBudgetItem(true);
        } else {
            setHasAddBudgetItem(false);
        }
    };

    const budgetItemCardComponents = cards.map((item) => (
        <BudgetCard {...item} key={`${item.serialNumber} ${item.budgetYear}`} />
    ));

    const addButtonComponent = <AddBudgetItemButton onAddButtonClick={onAddButtonClick} />;

    const cardComponents =
        visibleAddButton && hasAddBudgetItem
            ? [addButtonComponent, ...budgetItemCardComponents]
            : budgetItemCardComponents;

    return (
        <div className={style.root}>
            {!loading && (
                <div className={style.list}>
                    <CardGroup cards={cardComponents} minCardWidth={360} cardsMargin={16} />
                </div>
            )}

            {loading && (
                <div className={style.preloader}>
                    <Preloader />
                </div>
            )}
        </div>
    );
};

function AddBudgetItemButton({ onAddButtonClick }: Partial<Props>): JSX.Element {
    return (
        <div className={style.addButton} onClick={onAddButtonClick}>
            Добавить ID источника
        </div>
    );
}
