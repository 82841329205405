import { action, observable, reaction } from 'mobx';
import { computedFn } from 'mobx-utils';
import { uniq, orderBy, filter, map, keyBy, reduce } from 'lodash';
import {
    SortDirection,
    TmRegistered,
    TrademarkFieldProp,
    TrademarkFieldProps,
    TrademarkInterface,
    TrademarkCollectionInterface,
} from '../Trademark';
import { UserConfigApi } from '@api';
import { UserConfigType } from 'sber-marketing-types/openid';
import { TrademarkListInterface } from 'sber-marketing-types/trademark';

export interface TmStoreProps {
    tms?: TmStore;
}

const sortDirectionMap: Record<SortDirection, 'asc' | 'desc'> = {
    [SortDirection.Asc]: 'asc',
    [SortDirection.Desc]: 'desc',
};

const DEFAULT_HEIGHT = 40;

const defaultFieldSort = { fieldName: 'uuid', direction: SortDirection.Asc };

export class TmStore {
    @observable
    public tms: TrademarkCollectionInterface = {};

    @observable
    public fieldNames: TrademarkFieldProps = {
        id: { id: 'id', name: '№№', visible: true, sort: defaultFieldSort.direction },
        name: { id: 'name', name: 'Название', visible: true },
        logo: {
            id: 'logo',
            name: 'Логотип',
            visible: true,
            filterItem: [
                { id: 1, title: 'Есть' },
                { id: 0, title: 'Нет' },
            ],
            filterFunction: (filterItems: (string | number)[], value: any): boolean => {
                return filterItems.includes(value ? 1 : 0);
            },
        },
        isRegistered: {
            id: 'isRegistered',
            name: 'Тованый знак',
            visible: true,
            filterItem: [
                { id: TmRegistered.Registered, title: 'Зарегестрирован' },
                { id: TmRegistered.Unregistered, title: 'Не зарегестрирован' },
            ],
            filterFunction: (filterItems: (string | number)[], value: boolean): boolean => {
                return filterItems.includes(value ? TmRegistered.Registered : TmRegistered.Unregistered);
            },
        },
        organization: { id: 'organization', name: 'Подразделение/компания', visible: true },
        responsible: { id: 'responsible', name: 'Ответственное лицо', visible: true },
        contact: { id: 'contact', name: 'Контакты', visible: true },
    };

    @observable
    public getFilterItemsByFieldName = computedFn((fieldName: string) => {
        if (this.fieldNames[fieldName].filterItem) {
            return this.fieldNames[fieldName].filterItem;
        }
        const uniqValues: string[] = uniq(Object.entries(this.tms).map(([, tm]) => tm[fieldName]));
        return uniqValues.map((value) => ({
            id: value,
            title: value,
        }));
    });

    @observable
    public getTrademarks = computedFn((): TrademarkInterface[] => {
        const fieldsForSort: TrademarkFieldProp[] = filter<TrademarkFieldProps>(this.fieldNames, 'sort');
        const sortNames: string[] = map(fieldsForSort, 'id');
        const sortDirections = map(fieldsForSort, ({ sort }) => sortDirectionMap[sort]);

        const fieldsForFilter: TrademarkFieldProp[] = filter<TrademarkFieldProps>(
            this.fieldNames,
            (field) => field.filterItems && field.filterItems.length > 0,
        );

        let tms: TrademarkInterface[];
        tms = filter(this.tms, (tm) => {
            return fieldsForFilter.every((field) => {
                if (field.filterFunction) {
                    return field.filterFunction(field.filterItems, tm[field.id]);
                }
                return field.filterItems.includes(tm[field.id]);
            });
        });
        tms = orderBy(tms, sortNames, sortDirections);
        return tms;
    });

    public saveUserConfigCollection = reaction(
        () => Object.entries(this.tms).filter(([, tm]) => tm.height !== DEFAULT_HEIGHT),
        async (tms) => {
            const data = reduce(
                tms,
                (res, [, { uuid, height }]) => {
                    res[uuid] = { height };
                    return res;
                },
                {},
            );
            await UserConfigApi.savePageConfig(UserConfigType.TMRegistryCollection, data);
        },
    );

    public saveUserConfigColumn = reaction(
        () =>
            Object.entries(this.fieldNames)
                .filter(([, field]) => field.sort || field.filterItems)
                .map(([, field]) => ({
                    id: field.id,
                    sort: field.sort,
                    filterItems: field.filterItems,
                })),
        async (fields) => {
            const data = reduce(
                fields,
                (res, { id, sort, filterItems }) => {
                    res[id] = { sort, filterItems };
                    return res;
                },
                {},
            );
            await UserConfigApi.savePageConfig(UserConfigType.TMRegistryColumn, data);
        },
    );

    /**
     * @description нужно вынести абстракцию SortTypeMap по аналогии с CellTypeMap
     */
    @action
    public setSort(fieldName: string, direction?: SortDirection) {
        let usedFieldName = fieldName;
        let usedDirection = direction;

        // TODO
        if (!usedDirection) {
            usedFieldName = defaultFieldSort.fieldName;
            usedDirection = defaultFieldSort.direction;
        }

        Object.keys(this.fieldNames).forEach((name) => {
            if (usedFieldName == name) {
                this.fieldNames[name].sort = usedDirection;
            } else {
                this.fieldNames[name].sort = undefined;
            }
        });
    }

    @action
    public setFilter(fieldName: string, items: (string | number)[] = []) {
        this.fieldNames[fieldName].filterItems = items;
    }

    @action
    public resize(uuid: string, height: number) {
        this.tms[uuid].height = height;
    }

    @action
    public load(tms: TrademarkListInterface, userConfigCollection = {}, userConfigColumn = {}) {
        this.tms = keyBy(
            map(tms, (tm: TrademarkInterface) => {
                tm.height = userConfigCollection[tm.uuid] ? userConfigCollection[tm.uuid].height : DEFAULT_HEIGHT;
                tm.id = `${tm.id}`;
                return tm;
            }),
            'uuid',
        ) as TrademarkCollectionInterface;

        Object.entries(userConfigColumn).forEach(([name, props]) => {
            this.fieldNames[name].sort = props['sort'];
            this.fieldNames[name].filterItems = props['filterItems'];
        });
    }
}
