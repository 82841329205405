import * as React from 'react';

import * as style from './ColumnFixingMenu.scss';

import { FixedColumnButton } from '@budgetCommon';

interface Props {
    isColumnFixed: boolean;
    onColumnFixToggle: () => void;
}

export const ColumnFixingMenu = ({ isColumnFixed, onColumnFixToggle }: Props): JSX.Element => {
    return (
        <div className={style.root} tabIndex={-1}>
            <FixedColumnButton isColumnFixed={isColumnFixed} onClick={onColumnFixToggle} />
        </div>
    );
};
