// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-TagsList-_styles__root{display:grid;grid-auto-columns:max-content;grid-auto-flow:column;grid-column-gap:6px;align-content:center}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetPlanning/FiltersColumnsTagsBar/TagsList/styles.scss"],"names":[],"mappings":"AAAA,kGACI,YAAa,CACb,6BAA8B,CAC9B,qBAAsB,CACtB,mBAAoB,CACpB,oBAAqB","sourcesContent":[".root {\n    display: grid;\n    grid-auto-columns: max-content;\n    grid-auto-flow: column;\n    grid-column-gap: 6px;\n    align-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-TagsList-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
