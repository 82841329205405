import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import * as moment from 'moment';

import type { AutopilotTPeriodPercent } from 'sber-marketing-types/backend';
import type { CellPosition, ColumnWidths, LineId } from './types';
import { CellEvent } from './types';
import type { StoreState } from '@store';
import type { BriefStageForm } from '@store/autopilotTv/types';
import type { ColumnParams, ColumnsConfigParams } from './ColumnsConfig';

import { ManualRatingsSplitTableTemplate } from './ManualRatingsSplitTableTemplate';
import { TableViewModel } from './TableViewModel';
import { TableView } from './TableView';
import { MakeColumnsConfig, MakeTableColumns } from './ColumnsConfig';
import { ColumnHeaderFactory, CellsFactory } from './modules';
import { setBriefFormValues } from '@store/autopilotTv/actions';
import { getBriefStageForm, getManualRatingSplitValidation } from '@store/autopilotTv/selectors';

interface Props extends Partial<MapProps & DispatchProps> {
    onColumnWidthsChange?: (columnWidths: ColumnWidths) => void;
}

interface MapProps {
    dateStart: string;
    dateEnd: string;
    manualRatingSplit: AutopilotTPeriodPercent[];
    percentsAreValid: boolean;
}

interface DispatchProps {
    setBriefFormValues: (briefForm: Partial<BriefStageForm>) => void;
}

interface State {
    lineIds: string[];
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class ManualRatingsSplitTableBehaviour extends React.PureComponent<Props, State> {
    private cellsStorage: TableViewModel;
    private table: TableView;
    private columnHeaderFactory: ColumnHeaderFactory;
    private cellsFactory: CellsFactory;
    private tableColumns: string[] = [];
    private defaultColumnWidths: ColumnWidths = {};
    private weeks: { dateStart: string; dateEnd: string }[] = [];

    public constructor(props: Props) {
        super(props);

        this.state = {
            lineIds: ['total'],
        };

        this.columnHeaderFactory = new ColumnHeaderFactory({
            getColumnsConfig: this.getColumnsConfig,
        });

        this.cellsFactory = new CellsFactory({
            getColumnsConfig: this.getColumnsConfig,
            getManualRatingSplit: this.getManualRatingSplit,
            getValidation: this.getValidation,
            onCellClose: () => this.table.setCursorEditStatus(false),
        });

        this.cellsStorage = new TableViewModel({
            makeColumnHeaderParams: this.columnHeaderFactory.makeColumnHeaderParams,
            makeCellParams: this.cellsFactory.makeCellParams,
        });

        this.updateWeeks();
        this.updateDefaultColumnWidths();
        this.updateTableColumns();
    }

    public componentDidMount() {
        this.initManualRatingSplit();
    }

    public componentDidUpdate(prevProps: Props) {
        const manualRatingSplitChanged = this.props.manualRatingSplit !== prevProps.manualRatingSplit;
        const percentsAreValidChanged = this.props.percentsAreValid !== prevProps.percentsAreValid;

        if (manualRatingSplitChanged || percentsAreValidChanged) {
            this.updateLineCells('total');
        }
    }

    public render(): JSX.Element {
        const { lineIds } = this.state;

        return React.createElement(ManualRatingsSplitTableTemplate, {
            viewModel: this.cellsStorage,
            columns: this.tableColumns,
            lineIds,
            columnWidths: this.defaultColumnWidths,
            tableRef: this.tableRef,
            onCellEvent: this.onCellEvent,
            onColumnWidthsChange: this.props.onColumnWidthsChange,
        });
    }

    public setColumnWidths(columnWidths: ColumnWidths) {
        this.table.setColumnWidths(columnWidths);
    }

    @autobind
    protected tableRef(component: TableView) {
        this.table = component ? (component as any).getInstance() : null;
    }

    @autobind
    protected async onCellEvent(eventType: CellEvent, position: CellPosition) {
        switch (eventType) {
            case CellEvent.EditStart:
                this.updateCell(position, true);
                break;

            case CellEvent.EditEnd:
                this.updateCell(position, false);
                break;

            case CellEvent.MouseSelection:
                this.updateCell(position, false);
                break;
        }
    }

    private updateCell(position: CellPosition, edit: boolean) {
        this.cellsStorage.setCellParams(position, this.cellsFactory.makeCellParams(position, edit));
    }

    @autobind
    private updateLineCells(lineId: LineId) {
        this.tableColumns.forEach((columnName) => {
            const cellPosition = { lineId, columnName };

            const cellEditStatus = this.table.getCellEditStatus(cellPosition);
            this.updateCell(cellPosition, cellEditStatus);
        });
    }

    private updateDefaultColumnWidths() {
        const columnsConfigParams = this.makeColumnsConfigParams();

        const columnsConfig = MakeColumnsConfig(columnsConfigParams);

        this.defaultColumnWidths = lodash.mapValues(columnsConfig, (item) => item.defaultWidth);
    }

    private updateTableColumns() {
        const columnsConfigParams = this.makeColumnsConfigParams();

        this.tableColumns = MakeTableColumns(columnsConfigParams);
    }

    private updateWeeks() {
        const { dateStart, dateEnd } = this.props;

        const weeks: { dateStart: string; dateEnd: string }[] = [];

        let periodStart = moment(dateStart);
        let periodEnd = moment(dateStart);

        while (periodStart.isBefore(moment(dateEnd))) {
            const weekEnd = periodStart.clone().endOf('week');
            const monthEnd = periodStart.clone().endOf('month');

            periodEnd = moment.min([weekEnd, monthEnd, moment(dateEnd)]);

            weeks.push({
                dateStart: periodStart.format('YYYY-MM-DD'),
                dateEnd: periodEnd.format('YYYY-MM-DD'),
            });

            periodStart = periodEnd.clone().add(1, 'day');
        }

        this.weeks = weeks;
    }

    @autobind
    private getColumnsConfig(): { [columnName: string]: ColumnParams } {
        const columnsConfigParams = this.makeColumnsConfigParams();

        return MakeColumnsConfig(columnsConfigParams);
    }

    private makeColumnsConfigParams(): ColumnsConfigParams {
        return {
            weeks: this.weeks,
        };
    }

    private initManualRatingSplit() {
        const { dateStart, dateEnd, manualRatingSplit } = this.props;

        const manualRatingSplitIsNew = lodash.isEmpty(manualRatingSplit);

        const allWeeksChanged = this.weeks.every((week) =>
            manualRatingSplit.every((item) => item.dateStart !== week.dateStart || item.dateEnd !== week.dateEnd),
        );

        if (manualRatingSplitIsNew || allWeeksChanged) {
            this.distributePercentsEvenly();
        } else {
            const datesChanged =
                lodash.first(manualRatingSplit).dateStart !== dateStart ||
                lodash.last(manualRatingSplit).dateEnd !== dateEnd;

            if (datesChanged) {
                this.updatePercents();
            }
        }
    }

    private distributePercentsEvenly() {
        const { dateStart, dateEnd } = this.props;

        const daysCount = this.getDaysCountBetweenDates(dateStart, dateEnd) + 1;
        const dayPercent = 100 / daysCount;

        const manualRatingSplit = this.weeks.map((item) => {
            const weekDaysCount = this.getDaysCountBetweenDates(item.dateStart, item.dateEnd) + 1;

            let value = weekDaysCount * dayPercent;

            if (value < 1) {
                value = 1;
            }

            return {
                dateStart: item.dateStart,
                dateEnd: item.dateEnd,
                value: Math.floor(value),
            };
        });

        this.props.setBriefFormValues({
            manualRatingSplit,
        });
    }

    private updatePercents() {
        const { manualRatingSplit } = this.props;

        const updatedManualRatingSplit = this.weeks.map((week) => {
            const foundWeek = manualRatingSplit.find(
                (item) => item.dateStart === week.dateStart && item.dateEnd === week.dateEnd,
            );

            return {
                dateStart: week.dateStart,
                dateEnd: week.dateEnd,
                value: foundWeek ? foundWeek.value : 0,
            };
        });

        this.props.setBriefFormValues({
            manualRatingSplit: updatedManualRatingSplit,
        });
    }

    @autobind
    private getManualRatingSplit(): AutopilotTPeriodPercent[] {
        return this.props.manualRatingSplit;
    }

    @autobind
    private getValidation(): boolean {
        return this.props.percentsAreValid;
    }

    private getDaysCountBetweenDates(date1: string, date2: string): number {
        return date1 && date2 ? Math.abs(moment(date1).diff(moment(date2), 'days')) : null;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { dateStart, dateEnd, manualRatingSplit } = getBriefStageForm(state);
    const percentsAreValid = getManualRatingSplitValidation(state);

    return {
        dateStart,
        dateEnd,
        manualRatingSplit,
        percentsAreValid,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setBriefFormValues,
        },
        dispatch,
    );
}
