import * as React from 'react';
import * as style from './TmTable.scss';
import { inject, observer } from 'mobx-react';
import { TmStoreProps } from '../store/TmStore';
import { TmRow } from './row/TmRow';
import { TrademarkInterface } from '../Trademark';
import { TmHeader } from './header/TmHeader';

export interface TmItemProps {
    idx: string;
    tm: TrademarkInterface;
    onResize: (uuid: string, height: number) => void;
}

const TmTableComponent = ({ tms }: TmStoreProps) => {
    const onResize = (uuid: string, height: number) => {
        tms.resize(uuid, height);
    };

    return (
        <div className={style.table}>
            <TmHeader />
            {tms.getTrademarks().map((tm) => (
                <TmRow key={tm.uuid} idx={tm.uuid} tm={tm} onResize={onResize} />
            ))}
        </div>
    );
};

export const TmTable = inject('tms')(observer(TmTableComponent));
