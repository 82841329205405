import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { Header } from './Header';
import { Footer } from './Footer';
import { Content } from './Content';
import { Preloader } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getTextsLoadingStatus, LoadingStatus } from '@store/autopilot/generationTextForm';

import * as styles from './styles.scss';

interface Props {
    className?: string;
}

export const Editor: React.FC<Props> = ({ className }) => {
    const loading = useSelector((state: StoreState) => getTextsLoadingStatus(state));

    return (
        <div className={classNames(styles.root, className)}>
            <Header />
            <Content />
            <Footer />
            {loading === LoadingStatus.LOADING && <Preloader />}
        </div>
    );
};
