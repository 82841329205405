export enum InternalTransferDirection {
    OneToMany = 'OneToMany',
    ManyToOne = 'ManyToOne',
}

export enum ComponentState {
    Closed = 'Closed',
    ExternalIncomeTransfer = 'ExternalIncomeTransfer',
    ExternalOutcomeTransfer = 'ExternalOutcomeTransfer',
    InternalTransferCellSelection = 'InternalTransferCellSelection',
    InternalTransferLineSelection = 'InternalTransferLineSelection',
    InternalTransferSumEntering = 'InternalTransferSumEntering',
    InternalTransferNegativeLineBalanceWarning = 'InternalTransferNegativeLineBalanceWarning',
}
