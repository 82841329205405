import * as React from 'react';
import * as style from './UnbindButton.scss';

export interface Props {
    onClick(): void;
}

export const UnbindButton: React.FC<Props> = ({ onClick }) => {
    return (
        <div className={style.root} onClick={onClick}>
            Отвязать
        </div>
    );
};
