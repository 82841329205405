import { actionCreatorFactory } from 'typescript-fsa';
import { UserVacation } from '@mrm/user-vacation';

import { LoadingStatus } from '@store/commonTypes';

const actionCreator = actionCreatorFactory('PROFILE_MENU_VACATION');

export const setLoadingStatus = actionCreator<LoadingStatus>('SET_LOADING_STATUS');
export const setRequestInProgress = actionCreator<boolean>('SET_REQUEST_IN_PROGRESS');
export const setDeleteVacationPopupVisibility = actionCreator<boolean>('SET_DELETE_VACATION_POPUP_VISIBLITY');

export const setExistingVacation = actionCreator<UserVacation>('SET_EXISTING_VACATION');
export const setPendingVacation = actionCreator<UserVacation>('SET_PENDING_VACATION');
export const updatePendingVacation = actionCreator<Partial<UserVacation>>('UPDATE_PENDING_VACATION');
