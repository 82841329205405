// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-dashboard-RightSidebar-ActivityWidget-ActivityName-_ActivityName__root{font-family:'Open Sans'}.src-client-modules-dashboard-RightSidebar-ActivityWidget-ActivityName-_ActivityName__name{margin-top:3px;letter-spacing:0.15px;font-size:12px;line-height:16px}.src-client-modules-dashboard-RightSidebar-ActivityWidget-ActivityName-_ActivityName__nameLink{text-decoration:none;color:#417ec6}.src-client-modules-dashboard-RightSidebar-ActivityWidget-ActivityName-_ActivityName__modal{position:fixed;z-index:1;top:0;left:0;width:100%;height:100%}\n", "",{"version":3,"sources":["webpack://./src/client/modules/dashboard/RightSidebar/ActivityWidget/ActivityName/ActivityName.scss"],"names":[],"mappings":"AAAA,2FACI,uBAAwB,CAC3B,2FAGG,cAAe,CAEf,qBAAsB,CAEtB,cAAe,CACf,gBAAiB,CACpB,+FAGG,oBAAqB,CAErB,aAAc,CACjB,4FAGG,cAAe,CACf,SAAU,CACV,KAAM,CACN,MAAO,CAEP,UAAW,CACX,WAAY","sourcesContent":[".root {\n    font-family: 'Open Sans';\n}\n\n.name {\n    margin-top: 3px;\n\n    letter-spacing: 0.15px;\n\n    font-size: 12px;\n    line-height: 16px;\n}\n\n.nameLink {\n    text-decoration: none;\n\n    color: #417ec6;\n}\n\n.modal {\n    position: fixed;\n    z-index: 1;\n    top: 0;\n    left: 0;\n\n    width: 100%;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-dashboard-RightSidebar-ActivityWidget-ActivityName-_ActivityName__root",
	"name": "src-client-modules-dashboard-RightSidebar-ActivityWidget-ActivityName-_ActivityName__name",
	"nameLink": "src-client-modules-dashboard-RightSidebar-ActivityWidget-ActivityName-_ActivityName__nameLink",
	"modal": "src-client-modules-dashboard-RightSidebar-ActivityWidget-ActivityName-_ActivityName__modal"
};
export default ___CSS_LOADER_EXPORT___;
