import * as React from 'react';
import * as lodash from 'lodash';
import autobind from 'autobind-decorator';

import { ActivityCorrection, CorrectionStatus } from '../../../types';
import { ActivityCorrectionCard } from './ActivityCorrectionCard';

interface Props {
    correction: ActivityCorrection;
    onHover: (correctionId: string) => void;
    onChange: () => void;
}

export class ActivityCorrectionCardContainer extends React.Component<Props> {
    public render() {
        const { correction, onChange } = this.props;
        const { oldActivityName, newActivityName } = this.makeChangedParams();

        return React.createElement(ActivityCorrectionCard, {
            correction,
            oldActivityName,
            newActivityName,
            onCardMouseEnter: this.onCardMouseEnter,
            onCardMouseLeave: this.onCardMouseLeave,
            onChange,
        });
    }

    @autobind
    private onCardMouseEnter(): void {
        this.props.onHover(this.props.correction.id);
    }

    @autobind
    private onCardMouseLeave(): void {
        this.props.onHover(null);
    }

    private makeChangedParams(): any {
        const { status, activityBefore, activityCurrent, params } = this.props.correction;

        const defineActivityName =
            status === CorrectionStatus.NeedApproving
                ? lodash.first(activityCurrent).name
                : lodash.isEmpty(lodash.first(activityBefore))
                ? lodash.first(activityCurrent).name
                : lodash.first(activityBefore).name;

        return {
            oldActivityName: defineActivityName,
            newActivityName: params.name,
        };
    }
}
