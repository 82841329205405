import * as React from 'react';
import { Flipped, Flipper, spring } from 'react-flip-toolkit';
import { CustomScrollbar, Icon, IconType } from 'sber-marketing-ui';
import { positionValues } from 'react-custom-scrollbars';

import { NewsItemParams } from '@store/news';

import { NewsColumnPreloader, NewsItemPreloader, NewsFitlerPreloader } from '@common/DashboardsPreloaders';

import { Filters } from './Filters';
import { ActionType } from './ActionType';
import { Author } from './Author';
import { Date } from './Date';
import { OptionalParts } from './OptionalParts';

import * as style from './NewsColumn.scss';

export interface IdsNews {
    activityId: number;
    taskId?: string | number;
}

export interface NewsColumnProps {
    filtersLoaded: boolean;
    allDataLoaded: boolean;
    showPreloader: boolean;
    newsParams: NewsItemParams[];
    onScroll(data: positionValues): void;
    onCloseButtonClick(idsNews: IdsNews): Promise<void>;
}

const onAppear = (el: HTMLElement, index: number) =>
    spring({
        onUpdate: (val: number) => {
            el.style.opacity = `${val}`;
        },
        delay: index * 50,
    });

const onExit = (el: HTMLElement, index: number, removeElement: () => void) => {
    spring({
        config: { overshootClamping: true },
        onUpdate: (val: number) => {
            el.style.opacity = `${1 - val}`;
        },
        delay: index * 50,
        onComplete: removeElement,
    });

    return () => {
        el.style.opacity = '';
        removeElement();
    };
};

export const NewsColumn = ({
    filtersLoaded,
    allDataLoaded,
    showPreloader,
    newsParams,
    onScroll,
    onCloseButtonClick,
}: NewsColumnProps): JSX.Element => {
    const flipKey = newsParams.reduce((acc, news) => {
        return `${acc}-${news.id}`;
    }, '');

    return (
        <CustomScrollbar hideTracksWhenNotNeeded autoHide onScrollFrame={onScroll}>
            {!filtersLoaded ? <NewsFitlerPreloader /> : <Filters />}

            {!allDataLoaded ? (
                <NewsColumnPreloader />
            ) : (
                <div
                    className={style.root}
                    {...{
                        'qa-id': 'dashboardNewsColumn',
                    }}
                >
                    <div
                        className={style.content}
                        {...{
                            'qa-id': 'dashbordNewsColumnList',
                        }}
                    >
                        <Flipper flipKey={flipKey}>
                            {allDataLoaded &&
                                newsParams.map((item, index) => (
                                    <Flipped key={item.id} flipId={item.id} onAppear={onAppear} onExit={onExit}>
                                        <div
                                            className={style.newsBlockWrapper}
                                            key={item.id}
                                            {...{
                                                'qa-id': 'dashboardNewsColumnItem',
                                                'qa-index': index,
                                            }}
                                        >
                                            <ActionType
                                                action={item.action}
                                                wasActivityAssignedToLoginedUser={item.wasActivityAssignedToLoginedUser}
                                                wasTaskAssignedToLoginedUser={item.wasTaskAssignedToLoginedUser}
                                            />

                                            <OptionalParts item={item} />

                                            <HorizontalBar />

                                            <Author id={item.userId} />

                                            <Date time={item.updateTime} />

                                            {item.unseen && (
                                                <CloseButton
                                                    onClick={() => {
                                                        onCloseButtonClick({
                                                            activityId: item.activityId,
                                                            taskId: item.taskId,
                                                        });
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </Flipped>
                                ))}
                        </Flipper>
                        {showPreloader && <NewsItemPreloader />}
                    </div>
                </div>
            )}
        </CustomScrollbar>
    );
};

function HorizontalBar() {
    return (
        <div className={style.newsPartWrapper}>
            <div className={style.horizontalBar} />
        </div>
    );
}

interface CloseButtonProps {
    onClick(): void;
}

function CloseButton({ onClick }: CloseButtonProps): JSX.Element {
    return (
        <div className={style.closeButton} onClick={onClick}>
            <Icon type={IconType.CROSS} />
        </div>
    );
}
