import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';

import { DropdownChip, DropdownChipProps } from '@common/components';

import * as styles from './DropdownKebab.scss';

export interface DropdownKebabProps extends DropdownChipProps {
    iconSize?: number;
}

export function DropdownKebab({ children, className, iconSize, ...props }: DropdownKebabProps) {
    return (
        <DropdownChip
            data-qa-id="DropdownKebab"
            chipContent={<Icon svgSize={iconSize} type={IconType.KEBAB} />}
            padding={4}
            relative
            {...props}
            className={classNames(styles.root, className)}
        >
            {children}
        </DropdownChip>
    );
}
