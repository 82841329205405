import * as React from 'react';

import * as style from './InputCell.scss';

import { Input_redesign as Input, InputTheme_redesign as InputTheme, WithTooltip } from 'sber-marketing-ui';

interface Props {
    title: string;
    placeholder: string;
    customStyle?: any;
    onValueChange: (value: string) => void;
}

export const InputCellEditTemplate = ({ title, placeholder, customStyle, onValueChange }: Props): JSX.Element => {
    return (
        <div className={style.root} style={{ ...customStyle }}>
            <WithTooltip content={title} className={style.title}>
                <>
                    <Input
                        value={title}
                        placeholder={placeholder}
                        theme={InputTheme.Borderless}
                        onBlur={(event, value) => onValueChange(value as string)}
                        autofocus
                    />

                    <div className={style.sign}>%</div>
                </>
            </WithTooltip>
        </div>
    );
};
