import * as React from 'react';
import autobind from 'autobind-decorator';

import { FileApi } from '@api';

import { CommentSearchResult } from '@store/search';

import { CommentContent, ContentState } from './CommentContent';

interface Props extends CommentSearchResult {}

interface State {
    isDownloadingInProgress: boolean;
    showToggleStateButton: boolean;
    contentState: ContentState;
}

export class CommentContentBehaviour extends React.PureComponent<Props, State> {
    private nonExpandedContent: HTMLDivElement = null;
    private expandedContent: HTMLDivElement = null;

    public constructor(props: Props) {
        super(props);

        this.state = {
            isDownloadingInProgress: false,
            showToggleStateButton: false,
            contentState: ContentState.BOTH,
        };
    }

    public static getDerivedStateFromProps(props: Props): Partial<State> {
        if (props.files && props.files.length) {
            return {
                showToggleStateButton: true,
            };
        }

        return null;
    }

    public render(): JSX.Element {
        return (
            <CommentContent
                {...this.props}
                {...this.state}
                nonExpandedContentRef={this.nonExpandedContentRef}
                expandedContentRef={this.expandedContentRef}
                onToggleStateButtonClick={this.onToggleStateButtonClick}
                onDownloadAllButtonClick={this.onDownloadAllButtonClick}
            />
        );
    }

    @autobind
    private nonExpandedContentRef(nonExpandedContent: HTMLDivElement): void {
        this.nonExpandedContent = nonExpandedContent;

        this.updateExpandState();
    }

    @autobind
    private expandedContentRef(expandedContent: HTMLDivElement): void {
        this.expandedContent = expandedContent;

        this.updateExpandState();
    }

    private updateExpandState(): void {
        if (this.nonExpandedContent && this.expandedContent) {
            const showToggleStateButton = this.expandedContent.clientHeight > this.nonExpandedContent.clientHeight;
            this.setState({
                showToggleStateButton,
                contentState: ContentState.NON_EXPANDED,
            });
        }
    }

    @autobind
    private onToggleStateButtonClick(): void {
        this.setState((state) => ({
            ...state,
            contentState:
                state.contentState === ContentState.EXPANDED ? ContentState.NON_EXPANDED : ContentState.EXPANDED,
        }));
    }

    @autobind
    private async onDownloadAllButtonClick(): Promise<void> {
        const { id, activity, task } = this.props;

        const params = {
            activityId: String(activity.id),
            taskId: task.id,
            commentId: id,
        };

        await new Promise<void>((resolve) =>
            this.setState(
                {
                    isDownloadingInProgress: true,
                },
                () => resolve(),
            ),
        );

        await FileApi.downloadMultipleFilesAsZip(
            'Архив',
            this.props.files.map((file) => ({
                id: file.id,
                name: file.originName,
                type: file.type,
                params,
            })),
        );

        await new Promise<void>((resolve) =>
            this.setState(
                {
                    isDownloadingInProgress: false,
                },
                () => resolve(),
            ),
        );
    }
}
