import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType, WithTooltip } from 'sber-marketing-ui';
import { WithTitle } from '../WithTitle';

import * as style from './CommonFondsChange.scss';
import { Money, MoneyFormatter } from '@common/Utils';

export interface Props {
    before: number;
    delta: number;
    title: string;
    month?: string;
}

export const CommonFondsChange: React.FC<Props> = ({ before, delta, title, month }) => {
    const deltaIsPositive = delta >= 0;
    const after = before + delta;

    const sumBefore = formatMoneyWithoutSign(before);
    const sumBeforeForTooltip = formatMoneyWithoutSignForTooltip(before);

    const sumDelta = formatMoneyWithSign(delta);
    const sumDeltaForTooltip = formatMoneyWithSignForTooltip(delta);

    const sumAfter = formatMoneyWithoutSign(after);
    const sumAfterForTooltip = formatMoneyWithoutSignForTooltip(after);

    return (
        <WithTitle title={title} month={month}>
            <div
                className={style.body}
                {...{
                    'qa-id': 'correctionCardFondsChange',
                }}
            >
                <div
                    className={classNames(style.beforeFond, style.tooltipWrapper)}
                    {...{
                        'qa-id': 'correctionCardFondsChangeBefore',
                    }}
                >
                    <WithTooltip content={sumBeforeForTooltip}>
                        <React.Fragment>{sumBefore}</React.Fragment>
                    </WithTooltip>
                </div>

                {Boolean(delta) ? (
                    <div
                        className={classNames(
                            style.deltaValue,
                            deltaIsPositive && style.positiveDelta,
                            !deltaIsPositive && style.negativeDelta,
                            style.tooltipWrapper,
                        )}
                        {...{
                            'qa-id': 'correctionCardFondsChangeDelta',
                        }}
                    >
                        <WithTooltip content={sumDeltaForTooltip}>
                            <React.Fragment>{sumDelta}</React.Fragment>
                        </WithTooltip>
                    </div>
                ) : (
                    <div
                        className={classNames(style.deltaValue, style.tooltipWrapper)}
                        {...{
                            'qa-id': 'correctionCardFondsChangeDelta',
                        }}
                    >
                        <WithTooltip content={'0'}>
                            <React.Fragment>{0}</React.Fragment>
                        </WithTooltip>
                    </div>
                )}

                <div className={classNames(style.afterFond, style.tooltipWrapper)}>
                    <WithTooltip
                        spanWrapper
                        content={deltaIsPositive ? `↑ ${sumAfterForTooltip}` : `↓ ${sumAfterForTooltip}`}
                    >
                        <React.Fragment>
                            {Boolean(delta) && (
                                <div
                                    className={classNames(
                                        style.deltaArrow,
                                        deltaIsPositive && style.positiveDelta,
                                        !deltaIsPositive && style.negativeDelta,
                                    )}
                                >
                                    {deltaIsPositive ? (
                                        <div className={style.positiveDeltaArrowWrapper}>
                                            <Icon type={IconType.ARROW_UP} svgSize={11} />
                                        </div>
                                    ) : (
                                        <div className={style.negativeDeltaArrowWrapper}>
                                            <Icon type={IconType.ARROW_DOWN} svgSize={11} />
                                        </div>
                                    )}
                                </div>
                            )}
                            <span
                                {...{
                                    'qa-id': 'correctionCardFondsChangeAfter',
                                }}
                            >
                                {sumAfter}
                            </span>
                        </React.Fragment>
                    </WithTooltip>
                </div>
            </div>
        </WithTitle>
    );
};

function formatMoneyWithSign(money: number): string {
    return MoneyFormatter.toRoubles(Money.fromCopecks(money), { forceSign: true });
}

function formatMoneyWithSignForTooltip(money: number): string {
    return MoneyFormatter.toRoubles(Money.fromCopecks(money), { forceSign: true });
}

function formatMoneyWithoutSign(money: number): string {
    return MoneyFormatter.toRoubles(Money.fromCopecks(money));
}

function formatMoneyWithoutSignForTooltip(money: number): string {
    return MoneyFormatter.toRoubles(Money.fromCopecks(money));
}
