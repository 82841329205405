// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Header-Title-_Title__root{letter-spacing:0.2px;color:#000000;font-family:'Open Sans';font-size:14px;font-weight:600;line-height:1.29}.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Header-Title-_Title__root .src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Header-Title-_Title__id{font-weight:800}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/LineModal/Header/Title/Title.scss"],"names":[],"mappings":"AAAA,0FACI,oBAAqB,CAErB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,eAAgB,CAChB,gBAAiB,CARrB,kLAWQ,eAAgB","sourcesContent":[".root {\n    letter-spacing: 0.2px;\n\n    color: #000000;\n\n    font-family: 'Open Sans';\n    font-size: 14px;\n    font-weight: 600;\n    line-height: 1.29;\n\n    .id {\n        font-weight: 800;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Header-Title-_Title__root",
	"id": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Header-Title-_Title__id"
};
export default ___CSS_LOADER_EXPORT___;
