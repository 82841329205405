import * as React from 'react';

import { EditableTextLine } from './EditableTextLine';

import { useBehavior } from './useBehavior';
import * as styles from './styles.scss';

export const Name: React.FC = () => {
    const { name, number, disabled, onInputChange, onInputBlur } = useBehavior();

    return (
        <div className={styles.root}>
            <EditableTextLine value={name} onChange={onInputChange} onBlur={onInputBlur} disabled={disabled} />
            {!!number && <div className={styles.number}> - #{number}</div>}
        </div>
    );
};
