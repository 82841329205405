import * as React from 'react';
import autobind from 'autobind-decorator';

import { Canvas } from './Canvas';

interface Props {
    width: number;
    height: number;
    canvasRef: (element: HTMLCanvasElement) => void;
}

export class CanvasContainer extends React.Component<Props> {
    private canvas: HTMLCanvasElement;

    constructor(props: Props) {
        super(props);
    }

    public shouldComponentUpdate(nextProps: Props): boolean {
        if (this.canvas) {
            const heightChanged = this.canvas.style.height !== `${nextProps.height}px`;
            const widthChanged = this.canvas.style.width !== `${nextProps.width}px`;

            if (heightChanged) {
                this.canvas.style.height = `${nextProps.height}px`;
                this.updateVirtualHeight();
            }

            if (widthChanged) {
                this.canvas.style.width = `${nextProps.width}px`;
                this.updateVirtualWidth();
            }
        }

        return false;
    }

    public componentDidMount() {
        if (this.canvas) {
            this.updateVirtualHeight();
            this.updateVirtualWidth();
            this.updateScale();
        }
    }

    public render(): JSX.Element {
        return React.createElement(Canvas, {
            width: this.props.width,
            height: this.props.height,
            canvasRef: this.canvasRef,
        });
    }

    @autobind
    private canvasRef(element: HTMLCanvasElement) {
        this.canvas = element;

        if (this.canvas) {
            this.updateVirtualHeight();
            this.updateVirtualWidth();
            this.updateScale();
        }

        this.props.canvasRef(element);
    }

    private updateVirtualHeight() {
        const scale = this.getScale();

        this.canvas.height = parseInt(this.canvas.style.height, 10) * scale;
    }

    private updateVirtualWidth() {
        const scale = this.getScale();

        this.canvas.width = parseInt(this.canvas.style.width, 10) * scale;
    }

    private updateScale() {
        const ctx = this.canvas.getContext('2d');
        const scale = this.getScale();

        ctx.scale(scale, scale);
    }

    private getScale(): number {
        return 1;

        // return window.devicePixelRatio || 1;
    }
}
