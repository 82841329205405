import { Success } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { LoadingStatus } from '@store/commonTypes';
import { TargetFilter } from '@store/budgetCorrections/filtersAndExport';

import {
    BudgetCorrectionsUserConfigState,
    BudgetCorrectionsUserConfig,
    LoadBudgetCorrectionsUserConfig,
    UpdateBudgetCorrectionsUserConfig,
} from './types';
import * as actions from './actions';

export function makeInitialState(): BudgetCorrectionsUserConfigState {
    return {
        status: LoadingStatus.NOT_LOADED,
        fields: {
            [TargetFilter.Status]: [],
            [TargetFilter.Types]: [],
            [TargetFilter.ActivityNames]: [],
            [TargetFilter.ActivityTypes]: [],
            [TargetFilter.BudgetItemNames]: [],
            [TargetFilter.Authors]: [],
            [TargetFilter.Approvers]: [],
            [TargetFilter.Blocks]: [],
            [TargetFilter.Directions]: [],
            [TargetFilter.Tools]: [],
            [TargetFilter.ExecutionIds]: [],
            // [TargetFilter.ExecutorIds]: [],
            // [TargetFilter.PlanIds]: [],
            [TargetFilter.Regionalities]: [],
            [TargetFilter.Territories]: [],
            [TargetFilter.Divisions]: [],
            [TargetFilter.CostCenter]: [],
        },
    };
}

class Reducer {
    public static loadUserConfig(
        state: BudgetCorrectionsUserConfigState,
        payload: Success<BudgetCorrectionsUserConfig, LoadBudgetCorrectionsUserConfig>,
    ): BudgetCorrectionsUserConfigState {
        const {
            result: { receivedUserConfig },
        } = payload;

        return {
            status: LoadingStatus.LOADED,
            fields: {
                ...state.fields,
                ...receivedUserConfig,
            },
        };
    }

    public static updateUserConfig(
        state: BudgetCorrectionsUserConfigState,
        payload: UpdateBudgetCorrectionsUserConfig,
    ): BudgetCorrectionsUserConfigState {
        const { preventBackendUpdate, ...fields } = payload;

        return {
            ...state,
            fields: {
                ...state.fields,
                ...fields,
            },
        };
    }
}

export const budgetCorrectionsUserConfigReducer = reducerWithInitialState(makeInitialState())
    .case(actions.loadBudgetCorrectionsUserConfig.done, Reducer.loadUserConfig)
    .case(actions.updateBudgetCorrectionsUserConfig.started, Reducer.updateUserConfig);
