import createCashedSelector from 're-reselect';
import { ActivityCard } from 'sber-marketing-types/frontend';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';

import { ActivitiesState, StoreTypes } from './types';

const getActivitiesState = (state: StoreState): ActivitiesState => state.activities;

export const getFetchersCount = createCashedSelector(
    getActivitiesState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: ActivitiesState, store: StoreTypes): number => state.stores[store].fetchersCount,
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getActivityById = createCashedSelector(
    getActivitiesState,
    (state: StoreState, id: number): number => id,
    (state: ActivitiesState, id: number): ActivityCard => state.byIds[id],
)((state: StoreState, id: number): number => id);

export const getActivities = createCashedSelector(
    getActivitiesState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: ActivitiesState, store: StoreTypes): ActivityCard[] => {
        const entitiesStore = state.stores[store];

        if (entitiesStore.loadingStatus === LoadingStatus.LOADED) {
            return state.stores[store].ids.map((id: number) => state.byIds[id]);
        }

        return [];
    },
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getActivityIds = createCashedSelector(
    getActivitiesState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: ActivitiesState, store: StoreTypes): number[] => state.stores[store].ids,
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getLoadingStatus = createCashedSelector(
    getActivitiesState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: ActivitiesState, store: StoreTypes): LoadingStatus => state.stores[store].loadingStatus,
)((state: StoreState, store: StoreTypes): StoreTypes => store);
