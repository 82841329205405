import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import { BudgetItemStatus, BudgetItemApproverStatus } from '@mrm/budget';
import { UserResponseParams } from 'sber-marketing-types/frontend';

import { StoreState } from '@store';
import { LineStatusChange } from '@store/budgetPlanning/types';
import { getBudgetItemByLineId } from '@store/budgetPlanning/selectors';

import { ApproversMenu, Direction } from './ApproversMenu';
import { setApproverStatus, setApproverMenuLineId } from '@store/budgetPlanning';
import { getLoginUser } from '@store/user';

interface Props extends Partial<MapProps>, Partial<DispatchProps> {
    lineId: string;
    direction?: Direction;
}

interface MapProps {
    userId: number;
    approvers: { approver: UserResponseParams; status: BudgetItemApproverStatus }[];
    approverStatus: BudgetItemStatus;
}

interface DispatchProps {
    setApproverStatus: (statusChange: LineStatusChange) => void;
    setApproverMenuLineId: (lineId: string) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class ApproversMenuContainer extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        return React.createElement(ApproversMenu, {
            approvers: this.props.approvers,
            approverStatus: this.props.approverStatus,
            userId: this.props.userId,
            direction: this.props.direction || Direction.Down,
            onApproveButtonClick: this.onApproveButtonClick,
            onRejectButtonClick: this.onRejectButtonClick,
            onMaskClick: this.onMaskClick,
        });
    }

    @autobind
    protected onApproveButtonClick() {
        const { lineId, approverStatus } = this.props;

        this.props.setApproverStatus({
            lineId,
            status: approverStatus == BudgetItemStatus.Approved ? null : BudgetItemStatus.Approved,
        });
    }

    @autobind
    protected onRejectButtonClick() {
        const { lineId, approverStatus } = this.props;

        const status = approverStatus == BudgetItemStatus.Rejected ? null : BudgetItemStatus.Rejected;

        this.props.setApproverStatus({
            lineId,
            status,
        });
    }

    @autobind
    protected onMaskClick() {
        this.props.setApproverMenuLineId(null);
    }
}

function mapStateToProps(state: StoreState, props: Props): MapProps {
    const { lineId } = props;
    const { approverStatusChanges } = state.budgetPlanningPage.restState;

    const approverStatus = approverStatusChanges[lineId] ? approverStatusChanges[lineId].status : null;

    const budgetItem = getBudgetItemByLineId(state, props.lineId);

    const approvers = budgetItem.approvers || [];

    return {
        approvers,
        userId: getLoginUser(state).attributes.id,
        approverStatus,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setApproverStatus,
            setApproverMenuLineId,
        },
        dispatch,
    );
}
