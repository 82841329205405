import * as React from 'react';
import { Link } from 'react-router-dom';
import { BudgetItem } from '@mrm/budget';

import { Money, MoneyFormatter, Captions } from '@common/Utils';

import * as style from './BudgetWidget.scss';

interface Props {
    planSum: string;

    factPreviousPeriod: number;
    planPreviousPeriod: number;

    sourceBudgetItem: BudgetItem;
}

export const BudgetWidget: React.FC<Props> = ({
    planSum,
    planPreviousPeriod,
    factPreviousPeriod,
    sourceBudgetItem,
}) => {
    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'budgetPlanningLineModalBudgetWidget',
            }}
        >
            <div className={style.blocks}>
                <Block
                    qaId="budgetPlanningLineModalBudgetWidgetPlan"
                    title="План"
                    content={`${planSum} ${Captions.Thousands}`}
                />

                <Block
                    qaId="budgetPlanningLineModalBudgetWidgetPreviousPeriod"
                    title="План пред. периода"
                    content={MoneyFormatter.toThousands(Money.fromCopecks(planPreviousPeriod))}
                />

                <Block
                    qaId="budgetPlanningLineModalBudgetWidgetFactPreviousPeriod"
                    title="Факт пред. периода"
                    content={MoneyFormatter.toThousands(Money.fromCopecks(factPreviousPeriod))}
                />

                <LinkToSourceBudgetItem sourceBudgetItem={sourceBudgetItem} />
            </div>
        </div>
    );
};

interface BlockProps {
    title: string;
    content: string | JSX.Element;
    qaId: string;
}

function Block({ title, content, qaId }: BlockProps): JSX.Element {
    return (
        <div {...{ 'qa-id': qaId }}>
            <div className={style.title}>{title}</div>
            <div className={style.content}>{content}</div>
        </div>
    );
}

interface LinkToSourceBudgetItemProps {
    sourceBudgetItem: BudgetItem;
}

function LinkToSourceBudgetItem({ sourceBudgetItem }: LinkToSourceBudgetItemProps): JSX.Element {
    return sourceBudgetItem ? (
        <Block
            qaId="budgetPlanningLineModalBudgetWidgetLineToSourceBudgetItem"
            title="Строка создана на основе исполнения"
            content={
                <Link
                    to={`/budget/execution?filters=id:${sourceBudgetItem?.id}&budgetId=${sourceBudgetItem?.budgetId}`}
                    className={style.link}
                    target="_blank"
                >
                    ID {sourceBudgetItem?.serialNumber}
                </Link>
            }
        />
    ) : null;
}
