import { sortBy } from 'lodash';
import { Color } from '../../../../common';
import { BuildReportChartConfig, StatisticsTaskType } from './types';

const statisticsItemsTypes: StatisticsTaskType[] = [
    StatisticsTaskType.OVERDUE,
    StatisticsTaskType.COMING_DEADLINE,
    StatisticsTaskType.ACTIVE,
    StatisticsTaskType.CLOSED,
];

const STATISTICS_ITEMS_TYPES_COLORS_MAP: Record<StatisticsTaskType, Color> = {
    [StatisticsTaskType.OVERDUE]: Color.RED,
    [StatisticsTaskType.COMING_DEADLINE]: Color.MUSTARD,
    [StatisticsTaskType.ACTIVE]: Color.GREEN,
    [StatisticsTaskType.CLOSED]: Color.BLUE,
};

const STATISTICS_ITEMS_TYPES_NAMES_MAP: Record<StatisticsTaskType, string> = {
    [StatisticsTaskType.OVERDUE]: 'Просроченные задачи',
    [StatisticsTaskType.COMING_DEADLINE]: 'Задачи у которых скоро дедлайн',
    [StatisticsTaskType.ACTIVE]: 'Активные задачи',
    [StatisticsTaskType.CLOSED]: 'Закрытые задачи',
};

export const buildTaskReportChartConfig: BuildReportChartConfig<'tasks'> = ({
    statisticsItems,
    statisticsTypeFilters,
}) => {
    const builderDataMap = {
        [StatisticsTaskType.ACTIVE]: () =>
            sortBy(
                statisticsItems.map(({ id, statistics, meta }) => ({
                    id,
                    name: meta.name,
                    mainValue: statistics.current.activeCount,
                    additionalValue: statistics.past.activeCount,
                })),
                ({ mainValue }) => mainValue,
            ).reverse(),
        [StatisticsTaskType.CLOSED]: () =>
            sortBy(
                statisticsItems.map(({ id, statistics, meta }) => ({
                    id,
                    name: meta.name,
                    mainValue: statistics.current.closedCount,
                    additionalValue: statistics.past.closedCount,
                })),
                ({ mainValue }) => mainValue,
            ).reverse(),
        [StatisticsTaskType.COMING_DEADLINE]: () =>
            sortBy(
                statisticsItems.map(({ id, statistics, meta }) => ({
                    id,
                    name: meta.name,
                    mainValue: statistics.current.withDeadlineApproachingCount,
                    additionalValue: statistics.past.withDeadlineApproachingCount,
                })),
                ({ mainValue }) => mainValue,
            ).reverse(),
        [StatisticsTaskType.OVERDUE]: () =>
            sortBy(
                statisticsItems.map(({ id, statistics, meta }) => ({
                    id,
                    name: meta.name,
                    mainValue: statistics.current.overdueCount,
                    additionalValue: statistics.past.overdueCount,
                })),
                ({ mainValue }) => mainValue,
            ).reverse(),
    };

    return statisticsItemsTypes
        .filter((statisticsItemType) => statisticsTypeFilters[statisticsItemType])
        .map((statisticsItemType) => ({
            name: STATISTICS_ITEMS_TYPES_NAMES_MAP[statisticsItemType],
            color: STATISTICS_ITEMS_TYPES_COLORS_MAP[statisticsItemType],
            data: builderDataMap[statisticsItemType](),
            type: statisticsItemType,
        }));
};
