import { TypeResponseAutopilotGenerate } from 'sber-marketing-types/backend';
import { AutopilotApi } from '@api';
import { TEXT_LENGTH_RANGE, TEXT_OPTIONS_LENGTH } from '../const';

export interface Request {
    (params: RequestParams): RequestResult;
}

type RequestResult = Promise<TypeResponseAutopilotGenerate>;

export interface RequestParams {
    autopilotId: string;
    productId: string;
    params: {
        context: string;
    };
}

const RECURSION_DEPTH_LIMIT = 5;

export const requestGeneratedTexts: Request = async (params) => {
    try {
        return await tryRecursiveRequest({ requestParams: params });
    } catch (error) {
        console.error(error);
        return {
            title: [],
            description: [],
        };
    }
};

interface RecursiveRequest {
    (params: RecursiveRequestParams): RequestResult;
}

interface RecursiveRequestParams {
    requestParams: RequestParams;
    prevData?: TypeResponseAutopilotGenerate;
    recursionDepth?: number;
}

const tryRecursiveRequest: RecursiveRequest = async ({
    requestParams: { autopilotId, productId, params },
    prevData = { title: [], description: [] },
    recursionDepth = 1,
}) => {
    if (recursionDepth <= RECURSION_DEPTH_LIMIT) {
        const response = await AutopilotApi.generateAds({ autopilotId, productId, params });

        const currentData = {
            title: [...prevData.title, ...response.title.filter((item) => item.length <= TEXT_LENGTH_RANGE.TITLE)],
            description: [
                ...prevData.description,
                ...response.description.filter((item) => item.length <= TEXT_LENGTH_RANGE.DESCRIPTION),
            ],
        };

        if (currentData.title.length < TEXT_OPTIONS_LENGTH || currentData.description.length < TEXT_OPTIONS_LENGTH) {
            return await tryRecursiveRequest({
                requestParams: { autopilotId, productId, params },
                prevData: currentData,
                recursionDepth: recursionDepth + 1,
            });
        } else {
            return currentData;
        }
    }

    return prevData;
};
