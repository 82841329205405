// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-Header-ActivityTitle-FavoriteIcon-_FavoriteIcon__active{cursor:pointer;color:#f5a623}.src-client-modules-activity-Header-ActivityTitle-FavoriteIcon-_FavoriteIcon__normal{cursor:pointer;color:transparent}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/Header/ActivityTitle/FavoriteIcon/FavoriteIcon.scss"],"names":[],"mappings":"AAAA,qFACI,cAAe,CAEf,aAAc,CACjB,qFAGG,cAAe,CAEf,iBAAkB","sourcesContent":[".active {\n    cursor: pointer;\n\n    color: #f5a623;\n}\n\n.normal {\n    cursor: pointer;\n\n    color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"active": "src-client-modules-activity-Header-ActivityTitle-FavoriteIcon-_FavoriteIcon__active",
	"normal": "src-client-modules-activity-Header-ActivityTitle-FavoriteIcon-_FavoriteIcon__normal"
};
export default ___CSS_LOADER_EXPORT___;
