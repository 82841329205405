import * as React from 'react';
import { withRouter } from 'react-router-dom';
import autobind from 'autobind-decorator';

import { BudgetItemProps } from '../ActivitySuggestConnected';

import { ExistingNameItem } from './ExistingNameItem';

interface Props extends BudgetItemProps {
    onClick: (activityBudgetId: string, activityBudgetName: string) => void;
    onMouseOver: (activityBudgetId: string) => void;
}

@(withRouter as any)
export class ExistingNameItemContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const {
            activity: { name },
            budget: { year: budgetYear },
        } = this.props;

        return (
            <ExistingNameItem
                name={name}
                budgetYear={budgetYear}
                onClick={this.onClick}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            />
        );
    }

    @autobind
    private onClick(): void {
        const { activity } = this.props;

        this.props.onClick(activity.id, activity.name);
    }

    @autobind
    private onMouseEnter(): void {
        const { activity } = this.props;

        this.props.onMouseOver(activity.id);
    }

    @autobind
    private onMouseLeave(): void {
        this.props.onMouseOver(null);
    }
}
