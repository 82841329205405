import * as React from 'react';
import classNames from 'classnames';

import * as style from './Checkbox.scss';

import { Icon, IconType } from 'sber-marketing-ui';

export enum SelectionType {
    Checked = 'checked',
    Unchecked = 'unchecked',
    Minus = 'minus',
}

const IconTypeBySelection = {
    [SelectionType.Checked]: IconType.CHECKBOX24_CHECKED,
    [SelectionType.Unchecked]: IconType.CHECKBOX24_UNCHECKED,
    [SelectionType.Minus]: IconType.CHECKBOX24_MINUS,
};

interface Props {
    children?: React.ReactNode;
    selection?: SelectionType;
    disabled?: boolean;
    onClick?: (newSelection: SelectionType) => void;
}

export const Checkbox = ({ children, selection = SelectionType.Unchecked, disabled, onClick }: Props): JSX.Element => {
    const onCheckboxClick = React.useCallback(() => {
        if (onClick) {
            const newSelection = selection === SelectionType.Checked ? SelectionType.Unchecked : SelectionType.Checked;

            onClick(newSelection);
        }
    }, [selection]);

    return (
        <div className={classNames(style.root, disabled && style.disabled)} onClick={onCheckboxClick}>
            <div className={style.iconWrapper}>
                <Icon className={style.icon} type={IconTypeBySelection[selection]} svgSize={20} />
            </div>

            {children}
        </div>
    );
};
