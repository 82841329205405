import { compose } from 'redux';
import * as lodash from 'lodash';
import type { Dictionary } from 'lodash';
import type { BudgetItem, CreativeRequestItem, CompleteCreativeRequestItem, CreativeRequestItemDonor } from './types';
import { TARGET_CREATIVE_REQUEST_ITEM_TYPES } from './consts';
import { TableNumberByType } from '@store/creative/types';
import { MonthValue } from '@mrm/budget/common';
import { MONTHS } from '@modules/activity/pages/creative/StageWidgets/RequestStageWidget/BudgetId/BudgetStageWidget/types';

interface CalculateSourceFoundsSum {
    (params: { budgetItems: BudgetItem[] }): number;
}

export const calculateSourceFoundsSum: CalculateSourceFoundsSum = ({ budgetItems }) => {
    return lodash.sum(
        budgetItems.map(({ reservedFunds, plannedFunds }) => sumFunds(plannedFunds) - sumFunds(reservedFunds)),
    );
};

interface CalculateApprovedFactExcludingVatSum {
    (params: { creativeRequestItems: CompleteCreativeRequestItem[] }): number;
}

export const calculateApprovedFactExcludingVatSum: CalculateApprovedFactExcludingVatSum = ({
    creativeRequestItems,
}) => {
    const approvedCreativeRequestItems = creativeRequestItems.filter((item) => item.status === 'approved');

    const actualCostWithoutVatSum = lodash.sum(
        approvedCreativeRequestItems.map(({ actualCostWithoutVat }) => actualCostWithoutVat || 0),
    );
    const commissionWithoutVatSum = lodash.sum(
        approvedCreativeRequestItems.map(({ commissionWithoutVat }) => commissionWithoutVat || 0),
    );

    return actualCostWithoutVatSum + commissionWithoutVatSum;
};

interface CalculateActualCostExcludingVatSum {
    (params: { creativeRequestItems: CreativeRequestItem[] }): number;
}

export const calculateActualCostExcludingVatSum: CalculateActualCostExcludingVatSum = ({ creativeRequestItems }) => {
    const actualCostWithoutVatSum = lodash.sum(
        creativeRequestItems.map(({ actualCostWithoutVat }) => actualCostWithoutVat || 0),
    );
    const commissionWithoutVatSum = lodash.sum(
        creativeRequestItems.map(({ commissionWithoutVat }) => commissionWithoutVat || 0),
    );
    return actualCostWithoutVatSum + commissionWithoutVatSum;
};

interface CalculateDelta {
    (params: { sourceFoundsSum: number; actualCostExcludingVatSum: number }): number;
}

export const calculateDelta: CalculateDelta = ({ sourceFoundsSum, actualCostExcludingVatSum }) => {
    return sourceFoundsSum - actualCostExcludingVatSum;
};

const sumFunds = (funds: MonthValue): number => MONTHS.reduce((acc, item) => acc + funds[item], 0);

const filterByTargetType = (creativeRequestItems: CreativeRequestItem[]): CreativeRequestItem[] => {
    return creativeRequestItems.filter(({ type }) => lodash.includes(TARGET_CREATIVE_REQUEST_ITEM_TYPES, type));
};

const groupByType = (creativeRequestItems: CreativeRequestItem[]): Dictionary<CreativeRequestItem[]> => {
    return lodash.groupBy(creativeRequestItems, 'type');
};

const addSerialNumber = (
    groupedCreativeRequestItemsByTypes: Dictionary<CreativeRequestItem[]>,
): CompleteCreativeRequestItem[] => {
    return lodash.flatten(
        lodash.map(groupedCreativeRequestItemsByTypes, (creativeRequestItems) => {
            return lodash
                .sortBy(creativeRequestItems, ({ createdAt }) => createdAt)
                .map((creativeRequestItem, index) => ({
                    ...creativeRequestItem,
                    serialNumber: `${TableNumberByType[creativeRequestItem.type]}.${index + 1}`,
                }));
        }),
    );
};

const filterByAvailabilityDonors = (
    creativeRequestItems: CompleteCreativeRequestItem[],
): CompleteCreativeRequestItem[] => {
    return creativeRequestItems.filter(({ donors }) => !!donors?.length);
};

const convertCreativeRequestItems = compose(addSerialNumber, groupByType, filterByTargetType);

export const buildCompleteCreativeRequestItems = compose(filterByAvailabilityDonors, convertCreativeRequestItems);

interface FilterBudgetItemsOwnedToCreativeRequest {
    (params: { budgetItems: BudgetItem[]; creativeRequestItems: CreativeRequestItem[] }): BudgetItem[];
}

export const filterBudgetItemsOwnedByCreativeRequest: FilterBudgetItemsOwnedToCreativeRequest = ({
    budgetItems,
    creativeRequestItems,
}) => {
    const creativeRequestItemDonors = getDonorsFromCreativeRequestItems(creativeRequestItems);
    const creativeRequestItemDonorIds = creativeRequestItemDonors.map(({ id }) => id);
    return budgetItems.filter(({ id: budgetItemId }) => lodash.includes(creativeRequestItemDonorIds, budgetItemId));
};

const getDonorsFromCreativeRequestItems = (creativeRequestItems: CreativeRequestItem[]): CreativeRequestItemDonor[] => {
    return lodash.uniqBy(lodash.flatten(creativeRequestItems.map(({ donors }) => donors)), 'id');
};

export const formatMoneyToRubles = (sum: number): string => formatCurrencyValue(roundNumber(formatMoney(sum), 2));

function formatMoney(value: React.ReactText): number {
    if (!value) {
        return 0;
    }

    const parsedValue = typeof value === 'string' ? parseInt(value, 10) : value;

    return parsedValue / 100;
}

function roundNumber(value: number, digitsAfterComma = 2): string {
    const roundedValue = Math.round(value * 100) / 100;
    const formatedValue = roundedValue.toFixed(digitsAfterComma);

    const [decimalPart, fractionPart] = formatedValue.split('.');

    return `${decimalPart}${fractionPart ? `.${fractionPart}` : ''}`;
}

function formatCurrencyValue(value: React.ReactText): string {
    let [decimalPart, fractionPart] = value.toString().split(/[.,]/);

    let sign = '';

    if (lodash.first(decimalPart) === '-') {
        decimalPart = decimalPart.substring(1);
        sign = '-';
    }

    const splittedDecimal = decimalPart.split(/(?=(?:...)*$)/).join(' ');

    return `${sign}${splittedDecimal}${fractionPart ? `,${fractionPart}` : ''}`;
}
