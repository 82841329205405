import { Money, MoneyFormatter } from '@common/Utils';
import { Month } from '@mrm/budget/common';

import * as moment from 'moment';
import * as lodash from 'lodash';

import { orderOfMonth, monthIndexMap } from './types';

export const formatMoney = (money: number): string => {
    return MoneyFormatter.toThousands(Money.fromCopecks(money), { hideCaption: true });
};

export const formatMoneyForTooltip = (money: number): string => {
    return MoneyFormatter.toRoubles(Money.fromCopecks(money), { hideCaption: true });
};

export const formatMoneyForCorrectionValue = (money: number): string => {
    return MoneyFormatter.toThousands(Money.fromCopecks(money), { forceSign: true, hideCaption: true });
};

export const formatMoneyForCorrectionValueTooltip = (money: number): string => {
    return MoneyFormatter.toRoubles(Money.fromCopecks(money), { forceSign: true, hideCaption: true });
};

export const getMonthName = (month: Month): string => {
    moment.locale('ru');
    const monthName = moment.months()[monthIndexMap[month]];

    return lodash.capitalize(monthName);
};

export { orderOfMonth, monthIndexMap };
