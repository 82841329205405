import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { sum, values, isEmpty } from 'lodash';

import { StoreState } from '@store';
import { ColumnName, getPageData, hideLineModal, getBudgetItemByLineId } from '@store/budgetExecution';
import { openTransitionMenu, setAcceptorCell } from '@store/budgetExecution/budgetTransferMenu';

import { Warnings } from './Warnings';

interface Props extends OwnProps, Partial<MapProps & DispatchProps> {}

interface OwnProps {
    lineId: string;
}

interface MapProps {
    lineSerialNumber: number;
    lineBudgetId: string;
    factExcess: number;
    reserveExcess: number;
    hasUnaprovedCorrections: boolean;
}

interface DispatchProps {
    initCorrectionToLine: () => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class WarningsContainer extends React.PureComponent<Props> {
    public render(): React.ReactNode {
        const {
            lineSerialNumber,
            lineBudgetId,
            factExcess,
            reserveExcess,
            hasUnaprovedCorrections,
            initCorrectionToLine,
        } = this.props;

        const shouldRender = factExcess > 0 || reserveExcess > 0 || hasUnaprovedCorrections;

        return shouldRender ? (
            <Warnings
                lineSerialNumber={lineSerialNumber}
                lineBudgetId={lineBudgetId}
                factExcess={factExcess}
                reserveExcess={reserveExcess}
                hasUnaprovedCorrections={hasUnaprovedCorrections}
                initCorrectionToLine={initCorrectionToLine}
            />
        ) : null;
    }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): MapProps {
    const {
        corrections: {
            activityCorrections,
            budgetItemCorrections,
            factCorrections,
            planCorrections,
            reserveCorrections,
            incomeExternalPlanCorrections,
            outcomeExternalPlanCorrections,
        },
    } = getPageData(state);
    const { lineId } = ownProps;

    const linePlanCorrections = planCorrections[lineId] || [];
    const linePlanChange = sum(linePlanCorrections.map((correction) => correction.data.params.value));

    const lineReserveCorrections = reserveCorrections[lineId] || [];
    const lineReserveChange = sum(lineReserveCorrections.map((correction) => correction.data.params.value));

    const lineFactCorrections = factCorrections[lineId] || [];
    const lineFactChange = sum(lineFactCorrections.map((correction) => correction.data.params.value));

    const budgetItem = getBudgetItemByLineId(state, lineId);
    const budgetItemPlan = sum(values(budgetItem.plannedFunds));
    const budgetItemFact = sum(values(budgetItem.factFunds));
    const budgetItemReserve = sum(values(budgetItem.reservedFunds));

    const factExcess = budgetItemFact + lineFactChange - (budgetItemPlan + linePlanChange);
    const reserveExcess = budgetItemReserve + lineReserveChange - (budgetItemPlan + linePlanChange);

    const hasUnaprovedCorrections = [
        activityCorrections[lineId],
        budgetItemCorrections[lineId],
        factCorrections[lineId],
        planCorrections[lineId],
        reserveCorrections[lineId],
        incomeExternalPlanCorrections[lineId],
        outcomeExternalPlanCorrections[lineId],
    ].some((corrections) => !isEmpty(corrections));

    return {
        lineSerialNumber: budgetItem.serialNumber,
        lineBudgetId: budgetItem.budgetId,
        factExcess,
        reserveExcess,
        hasUnaprovedCorrections,
    };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>, ownProps: OwnProps): DispatchProps {
    const { lineId } = ownProps;

    return {
        initCorrectionToLine: () => {
            dispatch(hideLineModal());
            dispatch(openTransitionMenu());
            dispatch(
                setAcceptorCell({
                    lineId,
                    columnName: ColumnName.PlanJan,
                }),
            );
        },
    };
}
