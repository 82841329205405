import * as React from 'react';

import { FilterKey } from '@store/budgetExecution';

import { Dropdown } from './Dropdown';
import { Content } from './Content';

export interface Props {
    label: React.ReactNode;
    filterName: FilterKey;
    onApplyFiltersButtonClick: () => void;
}

export const FiltersDropdownTemplate: React.FC<Props> = ({ label, filterName, onApplyFiltersButtonClick }) => {
    const dropdownRef = React.useRef<{ closeDropdown: () => void }>();

    function applyFiltersClickHandler() {
        if (dropdownRef.current) {
            dropdownRef.current.closeDropdown();
        }

        onApplyFiltersButtonClick();
    }

    const content = <Content columnNames={[filterName]} onApplyFiltersButtonClick={applyFiltersClickHandler} />;

    return <Dropdown elRef={dropdownRef} label={label} content={content} />;
};
