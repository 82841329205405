import * as React from 'react';

import {
    ChangedParamList,
    CorrectionCardHeader,
    CorrectionCardTable,
    CorrectionCardCellHeader,
    CorrectionCardCellContent,
    CorrectionBody,
    CountChanges,
    RejectComment,
    WithTitle,
} from '../common';
import type { ChangedParam } from '../common';

import { CorrectionStatus, CorrectionType } from '@mrm/budget';
import * as style from './ActivityCorrectionCard.scss';

interface Props {
    serialNumber: number;
    authorName: string;
    approverName: string;
    activityName: string;
    userIsBudgetExpert: boolean;
    userIsAuthorCorrection: boolean;
    creationDate: string;
    changedParams: ChangedParam[];
    url: string;
    status: CorrectionStatus;
    rejectComment: string;
    onStatusChange: (status: CorrectionStatus) => void;
}

export const ActivityCorrectionCard = ({
    serialNumber,
    authorName,
    approverName,
    activityName,
    userIsBudgetExpert,
    userIsAuthorCorrection,
    creationDate,
    changedParams,
    status,
    url,
    rejectComment,
    onStatusChange,
}: Props): JSX.Element => {
    return (
        <CorrectionBody status={status} type={CorrectionType.Activity} serialNumber={serialNumber}>
            <CorrectionCardHeader
                title={'Корректировка параметров активности'}
                serialNumber={serialNumber}
                authorName={authorName}
                approverName={approverName}
                creationDate={creationDate}
                url={url}
                status={status}
                userIsAuthorCorrection={userIsAuthorCorrection}
                userIsBudgetExpert={userIsBudgetExpert}
                onStatusChange={onStatusChange}
            />

            {rejectComment && <RejectComment text={rejectComment} />}

            <CorrectionCardTable>
                <CorrectionCardCellHeader>
                    <ActivitySubInfo title={'Название'} activityName={activityName} />
                </CorrectionCardCellHeader>
                <CorrectionCardCellHeader>
                    <CountChangesSubInfo count={changedParams.length} />
                </CorrectionCardCellHeader>

                <CorrectionCardCellContent />
                <CorrectionCardCellContent>
                    <div className={style.subInfoContent}>
                        <ChangedParamList params={changedParams} />
                    </div>
                </CorrectionCardCellContent>
            </CorrectionCardTable>
        </CorrectionBody>
    );
};

interface ActivitySubInfoProps {
    title: string;
    activityName: string;
}

const ActivitySubInfo: React.FC<ActivitySubInfoProps> = (data) => (
    <div className={style.subInfo}>
        <WithTitle title={data.title}>{data.activityName}</WithTitle>
    </div>
);

const CountChangesSubInfo: React.FC<{ count: number }> = ({ count }) => (
    <div className={style.subInfo}>
        <div className={style.countChangesWrapper}>
            <CountChanges count={count} />
        </div>
    </div>
);

export type { ChangedParam };
