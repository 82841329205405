import * as React from 'react';

import { ItemId, DropdownItemParams, WithDropdown } from '../WithDropdown';

interface Props {
    title: string;
    items: DropdownItemParams[];
    selectedItemId: ItemId[];
    onItemSelect: (itemId: ItemId) => void;
}

export function AddItemButton({ title, items, selectedItemId, onItemSelect }: Props): JSX.Element {
    const shouldRender = items.length && items.length !== selectedItemId.length;

    return shouldRender ? (
        <WithDropdown
            title={title}
            items={items.filter((item) => !selectedItemId.includes(item.id))}
            selectedItemId={selectedItemId}
            onItemSelect={onItemSelect}
        />
    ) : null;
}
