export interface TelegramInfo {
    hasUserAccessToChat?: boolean;
    isUserAuthorized: boolean;
    isUserCreatedChat: boolean;
    linkToAuthorization: string;
    loading: boolean;
    fetchError: {
        message: string;
    };
}

export const enum TelegramUserStatus {
    WaitingConnection = 'waiting_connection',
    UsernameNotSet = 'username_not_set',
    Connected = 'connected',
}
