import * as React from 'react';
import * as lodash from 'lodash';
import { v4 } from 'uuid';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';

import { StageActionsTemplate } from './StageActionsTemplate';
import type { Param } from './WithValidationCreativeRequestParams';

import { CreativeRequestStatus, MrmClient } from '@api';

import type { StoreState } from '@store';
import { setCurrentStage, setSelectedStage } from '@store/creative/actions';
import type { CreativeRequestDomain } from '@store/creative/types';
import { TableType } from '@store/creative/types';
import { getRequestStageForm } from '@store/creative/selectors';
import { saveUserConfig, SaveUserConfig, UserConfigType } from '@store/userConfig';

interface Props extends Partial<MapProps & DispatchProps> {
    creativeRequestId: string;
    currentStage: number;
    validCreativeRequestParams: Param[];
}

interface State {
    creativeRequestDomain: CreativeRequestDomain;
}

interface MapProps {
    fieldsCount: number;
    filledFieldsCount: number;
}

interface DispatchProps {
    setCurrentStage: (stage: number) => void;
    setSelectedStage: (stage: number) => void;
    saveUserConfig: (params: SaveUserConfig<UserConfigType.Creative>) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class StageActionsBehaviour extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            creativeRequestDomain: null,
        };
    }

    public async componentDidMount() {
        const creativeRequestDomain = await this.fetchCreativeRequestDomain();
        this.setState({ creativeRequestDomain }, () => {
            this.subscribe();
        });
    }

    public componentWillUnmount() {
        this.unsubscribe();
    }

    public render(): JSX.Element {
        const { creativeRequestId, fieldsCount, filledFieldsCount, currentStage } = this.props;

        return React.createElement(StageActionsTemplate, {
            creativeRequestId,
            fieldsCount,
            filledFieldsCount,
            currentStage,
            disabledButton: this.defineDisabledButton(),
            visibilityButton: this.defineVisibilityButton(),
            onButtonClick: this.onButtonClick,
        });
    }

    @autobind
    protected async onButtonClick() {
        const { currentStage } = this.props;
        const { creativeRequestDomain } = this.state;

        switch (currentStage) {
            case 1:
                await creativeRequestDomain?.model?.publish();
                await this.createCreativeRequestItem();
                this.openSidebar();
                this.setStage(1.5);
                break;
            case 1.5:
                await creativeRequestDomain?.model?.sendToExecutor();
                this.setStage(2);
                break;
            case 2:
                await creativeRequestDomain?.model?.sendToCustomer();
                this.setStage(3);
                break;
            case 3:
                await creativeRequestDomain?.model?.sendToExpert();
                this.setStage(4);
                break;
            case 4:
                await creativeRequestDomain?.model?.close();
                this.setStage(5);
                break;
        }
    }

    private defineDisabledButton(): boolean {
        const { currentStage } = this.props;
        const { creativeRequestDomain } = this.state;

        switch (currentStage) {
            case 1:
                const isValidCreativeRequestParams = this.props.validCreativeRequestParams.length
                    ? this.props.validCreativeRequestParams.every(({ fields }) =>
                          lodash.values(fields).every((value) => value),
                      )
                    : true;

                return !(isValidCreativeRequestParams && creativeRequestDomain?.model?.publish);
            case 1.5:
                return !creativeRequestDomain?.model.sendToExecutor;
            case 2:
                return !creativeRequestDomain?.model.sendToCustomer;
            case 3:
                return !creativeRequestDomain?.model.sendToExpert;
            case 4:
                return !creativeRequestDomain?.model.close;
            default:
                return false;
        }
    }

    private defineVisibilityButton(): boolean {
        const { currentStage } = this.props;
        const { creativeRequestDomain } = this.state;

        return currentStage === 4
            ? (creativeRequestDomain?.model.status as undefined as CreativeRequestStatus) ===
                  CreativeRequestStatus.approved
            : true;
    }

    private async createCreativeRequestItem() {
        const { creativeRequestDomain } = this.state;

        const client = await MrmClient.getInstance();

        const dictionaries = await client.Dictionary.getByType('creativeRequestGroup');

        const { id: dictionaryId } = dictionaries.find(({ value }) => value === TableType.Tariff);

        return await creativeRequestDomain.model.addItem({ itemId: v4(), groupId: dictionaryId });
    }

    private setStage(stage: number): void {
        this.props.setCurrentStage(stage);
        this.props.setSelectedStage(stage);
    }

    private openSidebar(): void {
        this.props.saveUserConfig({
            type: UserConfigType.Creative,
            payload: {
                sidebar: {
                    visibility: true,
                },
            },
        });
    }

    private async fetchCreativeRequestDomain(): Promise<CreativeRequestDomain> {
        const { creativeRequestId } = this.props;

        const client = await MrmClient.getInstance();
        return await client.domain.creativeRequests.getCreativeRequest({ id: creativeRequestId });
    }

    private subscribe(): void {
        const { creativeRequestDomain } = this.state;
        creativeRequestDomain?.events.onReloaded(this.onCreativeRequestDomainReloadHandler);
    }

    private unsubscribe(): void {
        const { creativeRequestDomain } = this.state;
        creativeRequestDomain?.events.offReloaded(this.onCreativeRequestDomainReloadHandler);
    }

    @autobind
    private async onCreativeRequestDomainReloadHandler(): Promise<void> {
        this.unsubscribe();
        const updatedCreativeRequestDomain = await this.fetchCreativeRequestDomain();
        this.setState(
            {
                creativeRequestDomain: updatedCreativeRequestDomain,
            },
            () => {
                this.subscribe();
            },
        );
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const requestForm = getRequestStageForm(state);

    const fieldsValues = lodash.values(requestForm);

    const filledFields = fieldsValues.filter(
        (item) => item !== null && (!lodash.isArray(item) || !lodash.isEmpty(item)),
    );

    return {
        fieldsCount: fieldsValues.length,
        filledFieldsCount: filledFields.length,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setCurrentStage,
            setSelectedStage,
            saveUserConfig,
        },
        dispatch,
    );
}
