// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-Card-CardTitle-_CardTitle__root{display:flex;margin-top:4px;letter-spacing:0.5px;color:#000000;font-family:\"Open Sans\";font-size:14px;font-weight:600;align-items:center;justify-content:flex-start}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/Card/CardTitle/CardTitle.scss"],"names":[],"mappings":"AAAA,2DACI,YAAa,CAEb,cAAe,CAEf,oBAAqB,CAErB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,eAAgB,CAEhB,kBAAmB,CACnB,0BAA2B","sourcesContent":[".root {\n    display: flex;\n\n    margin-top: 4px;\n\n    letter-spacing: 0.5px;\n\n    color: #000000;\n\n    font-family: \"Open Sans\";\n    font-size: 14px;\n    font-weight: 600;\n\n    align-items: center;\n    justify-content: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-Card-CardTitle-_CardTitle__root"
};
export default ___CSS_LOADER_EXPORT___;
