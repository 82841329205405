import { v4 } from 'uuid';
import { ImportBudgetItem } from '@mrm/budget';

import { ColumnNames, ColumnToMonthMap } from '../types';
import { FetchedData } from './FetchedData';

export class RequestParamsGenerator {
    public static generateParams(data: FetchedData[]): ImportBudgetItem[] {
        return data.map((data) => {
            const res: ImportBudgetItem = {
                id: v4(),
                activityName: '',
                dictionaryIds: [],
                plannedFunds: {},
            };

            data.iterateOverKeys((key) => this._processDataItem(data, key, res));
            this._processValidationErrors(data, res);

            return res;
        });
    }

    private static _processDataItem(data: FetchedData, key: ColumnNames, budgetItem: ImportBudgetItem): void {
        const value = data.getFieldByName(key);

        switch (key) {
            case ColumnNames.ActivityName:
                budgetItem.activityName = String(value);
                break;
            case ColumnNames.SapComment:
                budgetItem.sapComment = String(value);
                break;
            case ColumnNames.Customer:
                budgetItem.customerName = String(value);
                break;
            case ColumnNames.Comment:
                budgetItem.comment = String(value);
                break;
            case ColumnNames.BusinessGoal:
                budgetItem.businessTarget = String(value);
                break;
            case ColumnNames.StartDate:
                budgetItem.realizationStart = value as Date;
                break;
            case ColumnNames.EndDate:
                budgetItem.realizationEnd = value as Date;
                break;
            case ColumnNames.Responsible:
                budgetItem.responsibleIds = [value as number];
                break;
            case ColumnNames.PlanJan:
            case ColumnNames.PlanFeb:
            case ColumnNames.PlanMar:
            case ColumnNames.PlanApr:
            case ColumnNames.PlanMay:
            case ColumnNames.PlanJun:
            case ColumnNames.PlanJul:
            case ColumnNames.PlanAug:
            case ColumnNames.PlanSep:
            case ColumnNames.PlanOct:
            case ColumnNames.PlanNov:
            case ColumnNames.PlanDec:
                const month = ColumnToMonthMap[key];

                if (!month) {
                    console.warn(`No ColumnNames->Month map was set for column ${key}`);
                } else {
                    budgetItem.plannedFunds[month] = RequestParamsGenerator.truncateValue(value as number);
                }

                break;
            case ColumnNames.Regionality:
            case ColumnNames.ActivityType:
            case ColumnNames.Direction:
            case ColumnNames.Tool:
            case ColumnNames.CostCenter:
            case ColumnNames.Block:
            case ColumnNames.Division:
            case ColumnNames.Item:
            case ColumnNames.Resource:
            case ColumnNames.Territory:
            case ColumnNames.LocationDriver:
            case ColumnNames.Segment:
            case ColumnNames.Product:
            case ColumnNames.Channel:
                budgetItem.dictionaryIds.push(String(value));
                break;
            case ColumnNames.FactPreviousPeriod:
                budgetItem.factPreviousPeriod = RequestParamsGenerator.truncateValue(value as number);
                break;
            default:
                break;
        }
    }

    private static _processValidationErrors(data: FetchedData, budgetItem: ImportBudgetItem): void {
        const errors = data.getValidationErrors();

        if (errors.length) {
            const errorsFormatted = errors.map((error) => error.toString()).join('; ');

            if (budgetItem.comment) {
                budgetItem.comment += '; ';
            } else {
                budgetItem.comment = '';
            }

            budgetItem.comment += `При загрузке данных из Excel обнаружены ошибки: ${errorsFormatted}`;
        }
    }

    private static truncateValue(value: number): number {
        return +(value * 100).toFixed(0) || 0;
    }
}
