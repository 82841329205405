import * as React from 'react';
import autobind from 'autobind-decorator';

import type { CellParams, CellPosition, ColumnHeaderParams, ColumnName } from './types';

import { TableTemplate } from './TableTemplate';
import { TableViewModel } from './TableViewModel';
import { AutopilotPlacementPlanData } from 'sber-marketing-types/backend';
import { columns, columnsConfig, COLUMN_NAME, lines } from './columnsConfig';

interface Props {
    mediaplan: AutopilotPlacementPlanData[];
}

export class TableBehaviour extends React.PureComponent<Props> {
    private viewModel: TableViewModel;

    public constructor(props: Props) {
        super(props);

        this.state = {
            unsavedChanges: {},
        };

        this.viewModel = new TableViewModel({
            makeColumnHeaderParams: this.makeColumnHeaderParams,
            makeCellParams: this.makeCellParams,
        });
    }

    public render(): JSX.Element {
        return React.createElement(TableTemplate, {
            viewModel: this.viewModel,
            columns,
            lines,
        });
    }

    @autobind
    private makeColumnHeaderParams(columnName: ColumnName): ColumnHeaderParams {
        const column = columnsConfig[columnName];

        return {
            title: column.title,
            style: { fontWeight: 600 },
        };
    }

    @autobind
    private makeCellParams(cellPosition: CellPosition): CellParams {
        const { mediaplan } = this.props;
        const { columnName, lineId } = cellPosition;
        const { accessor, totalLineAccessor } = columnsConfig[columnName];

        const params: CellParams = {};

        if (lineId === 'total') {
            params.title = totalLineAccessor(mediaplan);
        } else {
            const line = mediaplan.find((item) => item.name == lineId);

            params.title = accessor(line);
        }

        if (columnName === COLUMN_NAME.LineName) {
            params.style = {
                fontWeight: 600,
            };
        }

        return params;
    }
}
