import * as React from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, IconType, AnimatedEllipsis, StaticSkeleton } from 'sber-marketing-ui';

import { StoreState } from '@store';
import {
    ArchiveContentPopupComponentState,
    getActivityDAMArchiveState,
    showPreloader,
    shouldRenderArchiveControls,
    getArchiveFiles,
    getTotalFiles,
    setArchiveContentPopupState,
} from '@store/activityFilesDAMArchive';

import { DatesFormatter } from '@common/Utils';

import * as styles from './ArchiveDescription.scss';

function usePreloader() {
    return useSelector((state: StoreState) => showPreloader(state));
}

export function ArchiveDescription(): JSX.Element {
    return (
        <div className={styles.root}>
            <ArchiveLink />
            <SyncDate />
            <ExportButton />
        </div>
    );
}

const ARCHIVE_LINK_TEXT = 'Архив файлов';

function ArchiveLink(): JSX.Element {
    const showPreloader = usePreloader();
    const link = useSelector((state: StoreState) => getActivityDAMArchiveState(state).archiveStatus?.collectionLink);

    if (showPreloader) {
        return (
            <div className={styles.linkWrapper}>
                <StaticSkeleton className={styles.linkPreloader} />
            </div>
        );
    }

    if (!link) {
        return (
            <div className={classnames(styles.linkWrapper, styles.link, styles.linkUnactive)}>{ARCHIVE_LINK_TEXT}</div>
        );
    }

    return (
        <div className={styles.linkWrapper}>
            <a href={link} target="_blank" className={styles.link}>
                {ARCHIVE_LINK_TEXT}

                <Icon type={IconType.LINK_FOR_SHARING} svgSize={14} className={styles.linkOpenButton} />
            </a>
        </div>
    );
}

function SyncDate(): JSX.Element {
    const showPreloader = usePreloader();
    const date = useSelector((state: StoreState) => getActivityDAMArchiveState(state).archiveStatus?.syncDate);

    return (
        <div className={styles.syncDateWrapper}>
            {showPreloader ? (
                <StaticSkeleton className={styles.syncDatePreloader} />
            ) : (
                <div className={styles.syncDate}>
                    {date ? `архив выгружен ${DatesFormatter.ddMonthyyyy(date)}` : 'архив не выгружен'}
                </div>
            )}
        </div>
    );
}

function useExportButton() {
    const dispatch = useDispatch();

    const showPreloader = usePreloader();
    const shouldRender = useSelector((state: StoreState) => shouldRenderArchiveControls(state));
    const renderInProgressBar = useSelector((state: StoreState) => getActivityDAMArchiveState(state).poolingInProgress);

    const archiveFiles = useSelector((state: StoreState) => getArchiveFiles(state));
    const totalFiles = useSelector((state: StoreState) => getTotalFiles(state));

    function onClick() {
        dispatch(setArchiveContentPopupState(ArchiveContentPopupComponentState.Editor));
    }

    return {
        showPreloader,
        shouldRender,
        renderInProgressBar,
        onClick,
        archiveFiles,
        totalFiles,
    };
}

function ExportButton(): JSX.Element {
    const { showPreloader, shouldRender, renderInProgressBar, onClick, archiveFiles, totalFiles } = useExportButton();

    if (showPreloader) {
        return <StaticSkeleton className={styles.exportButtonPreloader} />;
    }

    if (!shouldRender) {
        return null;
    }

    if (renderInProgressBar) {
        return (
            <div className={styles.exportButtonAllFilesAreExported}>
                <span className={styles.exportButtonText}>
                    <AnimatedEllipsis text="Файлы загружаются" />
                </span>
            </div>
        );
    }

    const renderAllFilesAreExportedMarker = archiveFiles === totalFiles;

    if (renderAllFilesAreExportedMarker) {
        return (
            <div className={styles.exportButtonAllFilesAreExported}>
                <Icon type={IconType.CHECK28} svgSize={12} className={styles.exportButtonIconAllFilesAreExported} />

                <span className={styles.exportButtonText}>Все файлы загружены</span>
            </div>
        );
    }

    return (
        <div className={styles.exportButtonHasFilesToExport} onClick={onClick}>
            <Icon
                type={IconType.DAM_ARCHIVE_EXPORT_BUTTON}
                svgSize={20}
                className={styles.exportButtonIconHasFilesToExport}
            />

            <span className={styles.exportButtonText}>
                Выгрузить файлы&nbsp;<span className={styles.textGrey}>({totalFiles - archiveFiles})</span>
            </span>
        </div>
    );
}
