import * as React from 'react';

import { FilterKey } from '@store/budgetExecution';

import { List } from './List';

import * as styles from './styles.scss';

interface Props {
    columnNames: FilterKey[];
    onApplyFiltersButtonClick: () => void;
}

export const ContentTemplate: React.FC<Props> = ({ columnNames = [], onApplyFiltersButtonClick }) => {
    return (
        <div className={styles.root}>
            <List columnNames={columnNames} onApplyFiltersButtonClick={onApplyFiltersButtonClick} />
        </div>
    );
};
