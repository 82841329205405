import * as React from 'react';
import classNames from 'classnames';
import {
    Textarea_redesign as Textarea,
    Textarea_theme as TextareaThemes,
    Icon,
    IconType,
    MentionMenu,
    MentionItem,
} from 'sber-marketing-ui';

import { FileInput } from '@common/FileInput';
import { DragNDrop } from '@common/DragNDrop';

import { FileAsset } from '../FileAsset';

import * as styles from './CommentInput.scss';

interface Props {
    text: string;
    isFocused: boolean;
    fileInputRef: React.RefObject<FileInput>;
    files: File[];
    mentionableUsers: MentionItem[];
    onWrapperMouseOver: () => void;
    onWrapperMouseOut: () => void;
    onInputChange: (value: string) => void;
    onInputFocus: () => void;
    onInputBlur: () => void;
    onAttachmentIconClick: () => void;
    onAttachmentIconMouseOver: () => void;
    onAttachmentIconMouseOut: () => void;
    onSendIconClick: () => void;
    onSendIconMouseOver: () => void;
    onSendIconMouseOut: () => void;
    onFileInput: (files: File[]) => void;
    deleteFile: (file: File) => void;
}

export function CommentInput({
    text,
    isFocused,
    fileInputRef,
    files,
    mentionableUsers,
    onWrapperMouseOver,
    onWrapperMouseOut,
    onInputChange,
    onInputFocus,
    onInputBlur,
    onAttachmentIconClick,
    onAttachmentIconMouseOver,
    onAttachmentIconMouseOut,
    onSendIconClick,
    onSendIconMouseOver,
    onSendIconMouseOut,
    onFileInput,
    deleteFile,
}: Props): JSX.Element {
    return (
        <div className={styles.root}>
            <div
                className={classNames(styles.inputWrapper, isFocused && styles.inputWrapperFocused)}
                onMouseOver={onWrapperMouseOver}
                onMouseOut={onWrapperMouseOut}
            >
                <div className={styles.input}>
                    <MentionMenu items={mentionableUsers} headerHeight={49}>
                        <Textarea
                            value={text}
                            placeholder="Напишите комментарий…"
                            theme={TextareaThemes.REGULAR}
                            onChange={onInputChange}
                            onFocus={onInputFocus}
                            onBlur={onInputBlur}
                        />
                    </MentionMenu>
                </div>

                <div
                    className={classNames(styles.icon, styles.attachmentIcon)}
                    {...{
                        'qa-id': 'commentInutAttachFilesIcon',
                    }}
                >
                    <Icon
                        type={IconType.ATTACHMENT_ICON}
                        svgSize={24}
                        onClick={onAttachmentIconClick}
                        onMouseOver={onAttachmentIconMouseOver}
                        onMouseOut={onAttachmentIconMouseOut}
                    />
                </div>

                <div
                    className={classNames(styles.icon, styles.sendIcon)}
                    {...{
                        'qa-id': 'commentInputSendIcon',
                    }}
                >
                    <Icon
                        type={IconType.SEND_ICON}
                        svgSize={24}
                        onClick={onSendIconClick}
                        onMouseOver={onSendIconMouseOver}
                        onMouseOut={onSendIconMouseOut}
                    />
                </div>

                <FileInput ref={fileInputRef} onChange={onFileInput} />
            </div>

            {!!files.length && (
                <div className={styles.filesWrapper}>
                    {files.map((file, i) => (
                        <div key={`file-${i}`} className={styles.file}>
                            <FileAsset file={file} deleteFile={deleteFile} />
                        </div>
                    ))}
                </div>
            )}

            <div className={styles.dragNDrop}>
                <DragNDrop onFileInput={onFileInput} />
            </div>
        </div>
    );
}
