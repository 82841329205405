import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import * as moment from 'moment';
import { FormField, FieldValue, SelectItem } from 'sber-marketing-ui';
import { PlainDictionary, DictionaryType } from '@mrm/dictionary';
import { MultiReferenceDictionaryApi } from '@api';

import { StoreState } from '@store';
import { updateBudgetForm } from '@store/executionBudgetEdit/actions';
// import { FormData, GroupedDictionaries, IgnoreDictionariesChildren } from '@store/executionBudgetEdit/types';
import { FormData, GroupedDictionaries, Budget } from '@store/executionBudgetEdit/types';
import {
    InstanceActionPayload,
    TagsEditorInstanceDescriptor,
    initInstance as initTagsEditorInstance,
    dropInstance as dropdTagsEditorInstance,
    flushOnDemandUpdates,
} from '@store/tagsEditor';

import { Utils } from '@common/Utils';
import { getActivityTypeTooltip } from '@common/ActivityTypeTooltips';

import { BudgetForm } from './BudgetForm';
import { formatDictionaryItems } from '../BudgetForm/BudgetFormData';
import { dictionaryTypes, getConfigFromYear } from './configs';
import { ResetRegionalityLinksTooltip, ResetBlockLinksTooltip } from '@common/ResetLinksGroupTooltip';

interface Props extends OwnProps, Partial<MapProps & DispatchProps> {}

interface OwnProps {
    id: string;
    tagsEditorId: string;
    isNew: boolean;
    fields: FormField[];
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi;
}

interface MapProps {
    budget: Budget;
    dictionaries: GroupedDictionaries;
}

interface DispatchProps {
    updateBudgetForm: (form: FormData) => void;
    initTagsEditorInstance: (payload: InstanceActionPayload<TagsEditorInstanceDescriptor>) => void;
    dropdTagsEditorInstance: (payload: string) => void;
    flushOnDemandUpdates: (payload: string) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BudgetFormContainer extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        return React.createElement(BudgetForm, {
            fields: this.props.fields,
            onFieldValueChange: this.onFieldValueChange,
        });
    }

    public componentDidMount(): void {
        this.validateDictionaries(this.props.fields);
        this.props.initTagsEditorInstance({
            id: this.props.tagsEditorId,
            payload: { executionBudgetItemId: this.props.id },
        });
    }

    public componentWillUnmount(): void {
        this.props.flushOnDemandUpdates(this.props.tagsEditorId);
        this.props.dropdTagsEditorInstance(this.props.tagsEditorId);
    }

    @autobind
    protected onFieldValueChange(id: string, value: FieldValue) {
        const { fields } = this.props;
        const updatedFields = lodash.clone(fields);

        const changedField = fields.find((item) => item.id == id);
        changedField.errorMessage = '';
        const isChangedFieldDictionary = !!changedField.dictionaryType;

        if (isChangedFieldDictionary) {
            this.validateDictionaries(updatedFields, { ...changedField, value });
            // this.updateDictionaryFields(updatedFields, this.fieldsToUpdate[changedField.name]);
        } else {
            const changedField = updatedFields.find((item) => item.id == id);
            changedField.value = value;

            if (changedField.name == 'startDate') {
                this.updateEndDate(fields);
            }

            if (changedField.name == 'endDate') {
                this.updateStartDate(fields);
            }

            this.props.updateBudgetForm({
                id: this.props.id,
                fields: updatedFields,
                collapsed: false,
            });
        }
    }

    // private updateDictionaryFields(fields: FormField[], fieldsToUpdate: string[]) {
    //     fieldsToUpdate.forEach((fieldName) => {
    //         const childFieldNames = this.buildChildNames(fieldName);

    //         childFieldNames.forEach((childFieldName) => {
    //             const currentChildField = fields.find((item) => item.name == childFieldName);
    //             const parentField = this.getParentField(fields, childFieldName, fieldName);
    //             const dictionaryItem = this.getDictionaryItemOfField(parentField);

    //             const childDictionaryItems = this.props.dictionaries.byType[dictionaryTypes[childFieldName]];

    //             if (childDictionaryItems) {
    //                 const visibleDictionaryItems = parentField.value
    //                     ? childDictionaryItems.filter((childItem) =>
    //                           dictionaryItem.children.some((item) => item.id == childItem.id),
    //                       )
    //                     : [];

    //                 const updatedChildFieldItems = this.formatDictionaryItems(visibleDictionaryItems);

    //                 currentChildField.items = updatedChildFieldItems;

    //                 if (updatedChildFieldItems.length === 1) {
    //                     currentChildField.value = updatedChildFieldItems[0].value;
    //                 } else if (updatedChildFieldItems.length === 2) {
    //                     const itemValues = updatedChildFieldItems.map((item) => item.value);

    //                     if (itemValues.some((value) => !value)) {
    //                         currentChildField.value = itemValues.find((value) => value);
    //                     }
    //                 }

    //                 const oldValueIsValid = updatedChildFieldItems.some(
    //                     (item) => item.value == currentChildField.value,
    //                 );

    //                 if (!oldValueIsValid) {
    //                     currentChildField.value = null;
    //                 }
    //             }
    //         });
    //     });
    // }

    // private getParentField(fields: FormField[], childFieldName: string, parentFieldName: string): FormField {
    //     const parentFields = fields.filter((field) => {
    //         return lodash.includes(this.fieldPriorityWeights[childFieldName], field.name);
    //     });

    //     const parentFieldWithChildrenDictionary = parentFields.find((field) => {
    //         const dictionaryItem = this.getDictionaryItemOfField(field);
    //         return Boolean(dictionaryItem && dictionaryItem.children.length);
    //     });

    //     return parentFieldWithChildrenDictionary || fields.find((item) => item.name == parentFieldName);
    // }

    // private buildChildNames(fieldName: string): string[] {
    //     const dictionaryType = dictionaryTypes[fieldName];
    //     const dictionaryItems = this.props.dictionaries.byType[dictionaryType] || [];

    //     // let childTypes: DictionaryType[];

    //     // if (IgnoreDictionariesChildren.includes(dictionaryType)) {
    //     //     childTypes = [];
    //     // } else {
    //     //     childTypes = lodash.uniq(
    //     //         lodash.flatMap(dictionaryItems, item => item.children)
    //     //             .map(item => item.type as DictionaryType)
    //     //     );
    //     // }

    //     const childTypes = lodash.uniq(
    //         lodash.flatMap(dictionaryItems, (item) => item.children).map((item) => item.type as DictionaryType),
    //     );

    //     const childFieldNames: string[] = [];

    //     childTypes.forEach((childType) => {
    //         const childFieldName = lodash.findKey(dictionaryTypes, (item) => item == childType);

    //         if (childFieldName) {
    //             childFieldNames.push(childFieldName);
    //         }
    //     });

    //     return childFieldNames;
    // }

    // private getDictionaryItemOfField(field: FormField): PlainDictionary {
    //     const dictionaryType = dictionaryTypes[field.name];
    //     const dictionaryItems = this.props.dictionaries.byType[dictionaryType] || [];
    //     const currentParentValue = field.value;
    //     return dictionaryItems.find((dictionaryItem) => dictionaryItem.id == currentParentValue);
    // }

    private updateStartDate(fields: FormField[]) {
        const startDateField = fields.find((item) => item.name == 'startDate');
        const endDateField = fields.find((item) => item.name == 'endDate');

        const endDate = endDateField.value as moment.Moment;

        startDateField.maxDate = endDate || moment().add(1, 'year').endOf('year');
    }

    private updateEndDate(fields: FormField[]) {
        const startDateField = fields.find((item) => item.name == 'startDate');
        const endDateField = fields.find((item) => item.name == 'endDate');

        const startDate = startDateField.value as moment.Moment;

        endDateField.minDate = startDate || moment().add(1, 'year').startOf('year');
    }

    private validateDictionaries(fields: FormField[], updatedField?: FormField) {
        const { multiReferenceDictionaryApi } = this.props;

        const dictionaryFields = fields.filter((field) => field.dictionaryType);
        const dictionaryValues = lodash.compact(dictionaryFields.map((field) => field.value as string));

        const updatedValues = updatedField
            ? multiReferenceDictionaryApi.performDictionaryUpdate(
                  dictionaryValues,
                  updatedField.dictionaryType as DictionaryType,
                  updatedField.value as string,
              )
            : multiReferenceDictionaryApi.makeDictionaryValue(dictionaryValues);

        fields.forEach((field) => {
            if (field.dictionaryType) {
                const availableDictionaries = multiReferenceDictionaryApi.getDictionariesForValue(
                    updatedValues,
                    field.dictionaryType as DictionaryType,
                );

                const dictionaries =
                    availableDictionaries[field.dictionaryType] ||
                    this.props.dictionaries.byType[field.dictionaryType] ||
                    [];

                const isFieldRegionalityBased = multiReferenceDictionaryApi.dictionaryIsRegionalityBased(
                    field.dictionaryType as DictionaryType,
                );
                const addResetRegionalityLinksItem = !!(isFieldRegionalityBased && updatedValues[field.dictionaryType]);

                const isFieldBlockBased = multiReferenceDictionaryApi.dictionaryIsBlockBased(
                    field.dictionaryType as DictionaryType,
                );
                const addResetBlockLinksItem = !!(isFieldBlockBased && updatedValues[field.dictionaryType]);

                let resetLinksTooltip: JSX.Element = null;
                if (addResetRegionalityLinksItem) {
                    resetLinksTooltip = <ResetRegionalityLinksTooltip />;
                } else if (addResetBlockLinksItem) {
                    resetLinksTooltip = <ResetBlockLinksTooltip />;
                }

                const items = formatDictionaryItems(
                    dictionaries,
                    field.dictionaryType === DictionaryType.ActivityType ? getActivityTypeTooltip : null,
                    resetLinksTooltip,
                );
                field.items = items;
                field.value = updatedValues[field.dictionaryType]?.id;
            }
        });

        this.props.updateBudgetForm({
            id: this.props.id,
            fields,
            collapsed: false,
        });
    }

    // private formatDictionaryItems(dictionaries: PlainDictionary[]): SelectItem[] {
    //     const items: SelectItem[] = lodash.sortBy(
    //         dictionaries.map((item) => ({
    //             label: Utils.getDictionaryValue(item),
    //             value: item.id,
    //         })),
    //         (item) => (item.label ? item.label.replace(/\"|\'/g, '') : ''),
    //     );

    //     if (items.length) {
    //         items.unshift({ label: '-', value: null });
    //     }

    //     return items;
    // }

    // private get fieldsToUpdate() {
    //     return this.config.fieldsToUpdate;
    // }

    // private get fieldPriorityWeights() {
    //     return this.config.fieldPriorityWeights;
    // }

    // private get config() {
    //     return getConfigFromYear(this.props.budget.year);
    // }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): MapProps {
    const { isNew } = ownProps;

    const { availableDictionaries, usedDictionaries, budget } = state.executionBudgetEditPage;

    const dictionaries = isNew ? availableDictionaries : usedDictionaries;

    return {
        budget,
        dictionaries,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            updateBudgetForm,
            initTagsEditorInstance,
            dropdTagsEditorInstance,
            flushOnDemandUpdates,
        },
        dispatch,
    );
}
