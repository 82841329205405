import * as React from 'react';

import * as style from './Tooltip.scss';

interface Props {}

export const Tooltip = ({}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.triangle} />

            <div className={style.text}>
                Добавление участников возможно
                <br />к задачам, находящимся в работе
            </div>
        </div>
    );
};
