import * as React from 'react';
import { connect } from 'react-redux';

import { StoreState } from '@store';
import { getSelectedCard } from '@store/dashboardPage/selectors';

interface Props extends Partial<MapProps> {
    children?: (params: RenderParams) => React.ReactNode | React.ReactNode[];
}

interface MapProps {
    selectedCardId: React.ReactText;
    activityId: React.ReactText;
}

interface RenderParams extends Partial<MapProps> {}

@(connect(mapStateToProps, null) as any)
export class WithStore extends React.PureComponent<Props> {
    public render() {
        const { selectedCardId, activityId } = this.props;

        return this.props.children({
            selectedCardId,
            activityId,
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const selectedCard = getSelectedCard(state);

    return {
        selectedCardId: selectedCard && selectedCard.id,
        activityId: selectedCard && selectedCard.activityId,
    };
}
