import { TaskLoadingStatus, TaskPageState } from '../types';

export const createNewTaskStartReducer = (state: TaskPageState): TaskPageState => ({
    ...state,
    loadingStatus: TaskLoadingStatus.CREATING,
});

export const createNewTaskFailureReducer = (state: TaskPageState): TaskPageState => ({
    ...state,
    loadingStatus: TaskLoadingStatus.ERROR,
});

export const createNewTaskSuccessReducer = (state: TaskPageState): TaskPageState => ({
    ...state,
    loadingStatus: TaskLoadingStatus.LOADED,
});
