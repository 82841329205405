import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export interface IDPathParams<T> {
    id: T;
}

export const rtkApi = createApi({
    reducerPath: 'rtkApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
    }),
    tagTypes: ['activity', 'task', 'brief', 'stage', 'authUser', 'user', 'work-type', 'tag', 'task-participant'],
    endpoints: () => ({}),
});
