import * as React from 'react';

import { WithRouter } from '../../../../WithRouter';
import { WithCreativeRequestItems } from './WithCreativeRequestItems';
import { WithBudgetItems } from '../../../../WithBudgetItems';

import { SourceIdTable } from './SourceIdTable';

interface Props extends Partial<MapProps> {}

interface MapProps {
    userOrganizationId: string;
}

export const SourceIdTableConnector: React.FC<Props> = () => {
    return (
        <WithRouter>
            {({ creativeRequestId }) => (
                <WithBudgetItems>
                    {({ budgetItems }) => (
                        <WithCreativeRequestItems creativeRequestId={creativeRequestId}>
                            {({ creativeRequestItems }) => (
                                <SourceIdTable budgetItems={budgetItems} creativeRequestItems={creativeRequestItems} />
                            )}
                        </WithCreativeRequestItems>
                    )}
                </WithBudgetItems>
            )}
        </WithRouter>
    );
};
