// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-calendar-Chart-DateFilter-TodayLineIndicator-_TodayLineIndicator__root{position:absolute;bottom:0;left:0;transform:translateX(-50%);border:6px solid transparent;border-top:6px solid #19bb4f;border-bottom:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/calendar/Chart/DateFilter/TodayLineIndicator/TodayLineIndicator.scss"],"names":[],"mappings":"AAAA,2FACI,iBAAkB,CAClB,QAAS,CACT,MAAO,CAEP,0BAA2B,CAE3B,4BAA6B,CAC7B,4BAA6B,CAC7B,eAAgB","sourcesContent":[".root {\n    position: absolute;\n    bottom: 0;\n    left: 0;\n\n    transform: translateX(-50%);\n\n    border: 6px solid transparent;\n    border-top: 6px solid #19bb4f;\n    border-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-calendar-Chart-DateFilter-TodayLineIndicator-_TodayLineIndicator__root"
};
export default ___CSS_LOADER_EXPORT___;
