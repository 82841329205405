import {
    SearchQueryParams,
    FileSearchResult as FileSearchResultOrig,
    ProjectSearchResult as ActivitySearchResultOrig,
    TaskSearchResult as TaskSearchResultOrig,
    CommentSearchResult as CommentSearchResultOrig,
} from '@mrm/search';

import { LoadingStatus } from '@store/commonTypes';

export type SearchQuery = Pick<SearchQueryParams, 'query' | 'offset'>;

export enum SearchEntities {
    Activities = 'Activities',
    Tasks = 'Tasks',
    Comments = 'Comments',
    Files = 'Files',
}

export interface ActivitySearchResult
    extends Pick<ActivitySearchResultOrig, 'id' | 'name' | 'authorId' | 'organization' | 'block' | 'product'> {
    realizationStart: Date;
    realizationEnd: Date;
    preparationDate: Date;
    debriefingDate: Date;
}

export interface TaskSearchResult
    extends Pick<TaskSearchResultOrig, 'id' | 'title' | 'authorId' | 'status' | 'activity' | 'workType'> {
    deadline: Date;
}

export interface FileSearchResult
    extends Pick<
        FileSearchResultOrig,
        'id' | 'name' | 'originName' | 'type' | 'size' | 'storage' | 'activity' | 'task' | 'commentId'
    > {
    createTime: Date;
    updateTime: Date;
}

export interface CommentSearchResult
    extends Pick<CommentSearchResultOrig, 'id' | 'text' | 'authorId' | 'task' | 'activity' | 'channelId' | 'files'> {
    createTime: Date;
    updateTime: Date;
}

export interface SearchState {
    isVisible: boolean;
    singleLoadedEntityMode: boolean;
    searchQuery: string;
    componentState: ComponentState;
    expandedGroup: SearchEntities;
    animatedGroup: SearchEntities;
    entities: {
        [SearchEntities.Activities]: EntitiesState<ActivitySearchResult>;
        [SearchEntities.Tasks]: EntitiesState<TaskSearchResult>;
        [SearchEntities.Comments]: EntitiesState<CommentSearchResult>;
        [SearchEntities.Files]: EntitiesState<FileSearchResult>;
    };
}

export enum ComponentState {
    ShowNoQueryNotification = 'ShowNoQueryNotification',
    ShowEmptyQueryNotification = 'ShowEmptyQueryNotification',
    ShowNoResultsNotification = 'ShowNoResultsNotification',
    ShowResults = 'ShowResults',
}

export interface EntitiesState<T> {
    loadingStatus: LoadingStatus;
    shouldAnimate: boolean;
    entities: T[];
    totalCount: number;
    offset: number;
}

export type EntitiesGroupData<T> = Pick<EntitiesState<T>, 'totalCount' | 'loadingStatus' | 'entities'>;

export interface SetLoadingStatusPayload {
    entitiesType: SearchEntities;
    status: LoadingStatus;
}

export interface SetShouldAnimatePayload {
    entitiesType: SearchEntities;
    shouldAnimate: boolean;
}
