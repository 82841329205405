import { XLSXImportErrorState } from '@store/budgetPlanning';

export class XLSXImportError extends Error {
    public state: XLSXImportErrorState;

    public constructor(state: XLSXImportErrorState) {
        super('XLSXImportError');

        Object.setPrototypeOf(this, XLSXImportError.prototype);

        this.state = state;
    }
}
