import * as React from 'react';

import * as style from './SelectCell.scss';

import { Icon, IconType } from 'sber-marketing-ui';

interface Props {
    title: string;
    titleColor?: string;
    backgroundColor?: string;
    icon?: { type: IconType; color: string; size: number };
}

export const SelectCellTemplate = ({ title, titleColor, backgroundColor, icon }: Props): JSX.Element => {
    return (
        <div className={style.root} style={{ backgroundColor }}>
            <div className={style.title} style={{ color: titleColor }}>
                {icon && (
                    <div className={style.titleIcon} style={{ width: icon.size, height: icon.size }}>
                        <Icon type={icon.type} svgSize={icon.size} color={icon.color} />
                    </div>
                )}

                {title}
            </div>

            <div className={style.openerIcon}>
                <Icon type={IconType.TRIANGLE8_DOWN} svgSize={8} />
            </div>
        </div>
    );
};
