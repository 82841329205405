import gql from 'graphql-tag';

export const GET_BUDGET_ITEMS_BY_ACTIVITY_NAME_QUERY = gql`
    query getBudgetItemsByActivityName($activityName: String!, $budgetId: String!) {
        budgetItems: findBudgetItems(
            caseSensitive: false
            filter: { activityName: $activityName, budgetId: $budgetId }
        ) {
            nodes {
                id
                activity {
                    id
                    name
                }
                budget {
                    id
                    year
                }
            }
        }
    }
`;
