import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';

import {
    DropdownChipProps,
    DropdownChip,
    OptionProps,
    Options,
    SearchOptionProps,
    SearchOptions,
    getOptionValue,
    SkeletonText,
    OptionMultiple,
    OptionValues,
    StaticOptionsProps,
    DynamicOptionsProps,
} from '@common/components';
import { DropdownOptions, useDefaultState } from '@common/hooks';

import * as styles from './OptionsChip.scss';

export interface OptionsChipProps<
    M extends OptionMultiple,
    OP extends OptionProps<any>,
    O extends OP extends OptionProps<infer O1> ? O1 : never,
    ID extends O extends OptionValues ? never : keyof O,
    V extends O extends OptionValues ? O : ID extends keyof O ? O[ID] : never,
    SV extends M extends true ? V[] : V,
> extends StaticOptionsProps,
        DynamicOptionsProps<M, OP, O, ID, V, SV>,
        Omit<
            DropdownChipProps,
            'onChange' | keyof StaticOptionsProps | keyof DynamicOptionsProps<M, OP, O, ID, V, SV>
        > {
    optionsProps?: Omit<
        SearchOptionProps<M, OP, O, ID, V, SV>,
        keyof DynamicOptionsProps<M, OP, O, ID, V, SV> | keyof StaticOptionsProps
    >;
    arrow?: boolean;
    searchable?: boolean;
    search?: string;
    value?: string;
    valueLoading?: boolean;
    hideValue?: boolean;
    onSearch?: (value: string) => void;
    onChange?: (value: string) => void;
}

export function OptionsChip<
    M extends OptionMultiple,
    OP extends OptionProps<any>,
    O extends OP extends OptionProps<infer O1> ? O1 : never,
    ID extends O extends OptionValues ? never : keyof O,
    V extends O extends OptionValues ? O : ID extends keyof O ? O[ID] : never,
    SV extends M extends true ? V[] : V,
>({
    children,
    options,
    optionId,
    optionsProps: optionsPropsProp,
    arrow,
    disabled,
    selected,
    dropdownRef,
    searchable,
    search,
    optionHeight,
    optionsLoading,
    optionsLoader,
    optionsHeight,
    multiple,
    valueLoading,
    value,
    isDropdownShow,
    renderOption,
    preventDefaultSelect,
    optionsAfter,
    optionsBefore,
    hideValue,
    onSelect,
    onFocus,
    onBlur,
    onShowChangeDropdown,
    onMouseDown,
    onSearch,
    onChange,
    ...props
}: OptionsChipProps<M, OP, O, ID, V, SV>) {
    const [isOpen, setOpen] = useDefaultState(isDropdownShow, onShowChangeDropdown, false);
    const [currentValue, setValue] = useDefaultState(value, onChange, '');
    const [currentSearch, setSearch] = useDefaultState(search, onSearch, '');
    const [currentSelected, setSelected] = useDefaultState(selected, onSelect, null, preventDefaultSelect);
    const valueRef = React.useRef<string>();
    const { onSearch: onOptionsSearch, ...optionsProps } = optionsPropsProp || {};

    const handleShowChangeDropdown: typeof onShowChangeDropdown = (isShow) => {
        if (isShow && currentSearch !== valueRef.current) {
            setSearch(valueRef.current);
        }

        setOpen(isShow);
    };

    const handleSelect: typeof onSelect = (newValue, option) => {
        setSelected(newValue, option);
        dropdown.current.close();
    };

    const handleOptionsSearch: typeof onOptionsSearch = (newValue, option) => {
        setSearch(newValue);
        onOptionsSearch?.(newValue, option);
    };

    const rawDropdownRef = React.useRef<DropdownOptions>();
    const dropdown = dropdownRef || rawDropdownRef;

    const currentIconAfter = arrow ? IconType.ARROW16_DOWN_BLACK : undefined;
    const afterArrowIconClassName = classNames(styles.arrow, isOpen && styles.openArrow);

    const optionsCurrentProps: Required<StaticOptionsProps & DynamicOptionsProps<M, OP, O, ID, V, SV>> = {
        options,
        multiple,
        selected: currentSelected,
        optionId,
        optionsAfter,
        optionsBefore,
        renderOption,
        optionsLoading,
        optionsLoader,
        optionsHeight,
        optionHeight,
        preventDefaultSelect,
        onSelect: handleSelect,
    };

    const optionsContent = searchable ? (
        <SearchOptions
            {...optionsProps}
            {...optionsCurrentProps}
            search={currentSearch}
            onSearch={handleOptionsSearch}
        />
    ) : (
        <Options {...optionsProps} {...optionsCurrentProps} />
    );

    const handleAfterIconClick: React.MouseEventHandler<SVGSVGElement> = () => {
        if (arrow && !disabled && dropdown.current && !dropdown.current.isDropdownShow) {
            dropdown.current.show();
        }
    };

    React.useEffect(() => {
        const newTitle = options?.find(({ value }) => getOptionValue(value, optionId) === currentSelected)?.title || '';
        setValue(newTitle);
    }, [currentSelected, options]);

    const valueElement =
        !hideValue &&
        (currentSelected && valueLoading ? (
            <SkeletonText width={40} />
        ) : (
            <span className={styles.selected}>{currentValue || 'выбрать'}</span>
        ));

    const chipContent = (
        <>
            {children}
            {valueElement}
        </>
    );

    return (
        <DropdownChip
            {...props}
            disabled={disabled}
            dropdownRef={dropdown}
            chipContent={chipContent}
            isDropdownShow={isOpen}
            onShowChangeDropdown={handleShowChangeDropdown}
        >
            {optionsContent}
            {currentIconAfter && (
                <Icon onClick={handleAfterIconClick} type={currentIconAfter} className={afterArrowIconClassName} />
            )}
        </DropdownChip>
    );
}
