import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import * as style from './BriefName.scss';

import { HeaderBody, HeaderTitle } from '../common';

interface Props {
    activityId: number;
    title: string;
}

export const BriefName = ({ activityId, title }: Props): JSX.Element => {
    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'dashboardRightSidebarActivityWidgetBriefName',
            }}
        >
            <HeaderBody>
                <HeaderTitle>Бриф</HeaderTitle>
            </HeaderBody>

            {title ? (
                <Link
                    className={classNames(style.name, style.nameLink)}
                    to={`/activity/${activityId}/brief`}
                    target="_blank"
                >
                    {title}
                </Link>
            ) : (
                <div className={style.name}>Отсутствует бриф проекта</div>
            )}
        </div>
    );
};
