import * as React from 'react';

import * as style from './FundsSelectCell.scss';

import { Icon, IconType } from 'sber-marketing-ui';
import { SnapshotTooltip } from '../SnapshotTooltip';
import { CommentsButton } from '../CommentsButton';
import { ContextMenu } from '../ContextMenu';

interface Props {
    title: string;
    description?: string;
    customStyle?: any;
    snapshot?: any;
    commentButtonProps?: { hasNewComments: boolean; onClick: () => void };
}

export const FundsSelectCellTemplate = ({
    title,
    description,
    customStyle,
    snapshot,
    commentButtonProps,
}: Props): JSX.Element => {
    async function onCopyValueClick() {
        const formattedValue = title.replace(/ /g, '');

        const text = [formattedValue, description].filter((item) => item !== undefined).join(' ');

        await navigator.clipboard.writeText(text);
    }

    function onCopy(event: React.ClipboardEvent<HTMLSpanElement>) {
        const formattedValue = document.getSelection().toString().replace(/ /g, '');

        event.clipboardData.setData('text/plain', formattedValue);

        event.preventDefault();
        event.stopPropagation();
    }

    return (
        <>
            <CommentsButton {...commentButtonProps} />
            <SnapshotTooltip snapshot={snapshot} />
            <ContextMenu
                items={[
                    { title: 'Копировать', onClick: onCopyValueClick },
                    { title: 'Смотреть комментарии', onClick: commentButtonProps?.onClick },
                ]}
            />

            <div className={style.root} style={{ ...customStyle }}>
                <div className={style.title}>
                    <span className={style.titleCopyContent} onCopy={onCopy}>
                        {title}
                    </span>

                    <div className={style.currencySign}>₽</div>
                </div>

                {description && <div className={style.description}>{description}</div>}

                <div className={style.openerIcon}>
                    <Icon type={IconType.TRIANGLE8_DOWN} svgSize={8} />
                </div>
            </div>
        </>
    );
};
