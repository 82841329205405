import * as React from 'react';

import * as style from './BudgetStageActions.scss';

import { Icon, IconType, Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';

interface Props {
    canEdit: boolean;
    budgetItemSelected: boolean;
    onContinueButtonClick: () => void;
}

export const BudgetStageActionsTemplate = ({
    canEdit,
    budgetItemSelected,
    onContinueButtonClick,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.title}>
                Привяжите хотя бы один ID бюджета
                {budgetItemSelected && (
                    <div className={style.titleIcon}>
                        <Icon type={IconType.ACCEPT_ACTIVE} svgSize={24} />
                    </div>
                )}
                {!budgetItemSelected && (
                    <>
                        &nbsp;
                        <span className={style.counter}>(0/1)</span>
                    </>
                )}
            </div>

            <div className={style.button}>
                <Button
                    disabled={!canEdit || !budgetItemSelected}
                    theme={ButtonTheme.MainRounded}
                    onClick={onContinueButtonClick}
                >
                    Продолжить
                </Button>
            </div>
        </div>
    );
};
