import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { BudgetStatus } from '@mrm/budget';

import { LoadingStatus } from '@store/commonTypes';

import * as actions from './actions/sync';
import { BudgetPageState as State, BudgetState, LoadBudgetStatePayload } from './types';

class Reducer {
    public static makeInitialState(): State {
        return {
            budgetStatus: {
                [BudgetStatus.Plan]: Reducer.makeEmptyBudgetState(),
                [BudgetStatus.Execution]: Reducer.makeEmptyBudgetState(),
            },
        };
    }

    public static loadBudgetState(state: State, payload: LoadBudgetStatePayload): State {
        return Reducer.budgetStateReducer(state, payload.status, () => payload.state);
    }

    private static makeEmptyBudgetState(): BudgetState {
        return {
            loadingStatus: LoadingStatus.NOT_LOADED,
            budgets: [],
        };
    }

    private static budgetStateReducer(
        state: State,
        status: BudgetStatus,
        reducer: (budgetState: BudgetState) => BudgetState,
    ): State {
        return {
            ...state,
            budgetStatus: {
                ...state.budgetStatus,
                [status]: reducer(state.budgetStatus[status]),
            },
        };
    }
}

export const budgetPageReducer = reducerWithInitialState(Reducer.makeInitialState()).case(
    actions.loadBudgetState,
    Reducer.loadBudgetState,
);
