// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-CardTypes-common-RejectComment-_RejectComment__root{margin:13px 22px 10px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/dashboard/RightSidebar/BudgetInfo/Corrections/templates/CardTypes/common/RejectComment/RejectComment.scss"],"names":[],"mappings":"AAAA,gIACI,qBAAsB","sourcesContent":[".root {\n    margin: 13px 22px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-dashboard-RightSidebar-BudgetInfo-Corrections-templates-CardTypes-common-RejectComment-_RejectComment__root"
};
export default ___CSS_LOADER_EXPORT___;
