import * as React from 'react';

import * as style from './ProgrammaticStageWidget.scss';

import { PlacementList } from '../../PlacementList';

interface Props {
    readOnly?: boolean;
}

export const ProgrammaticStageWidget = ({ readOnly }: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <PlacementList />
        </div>
    );
};
