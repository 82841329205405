import * as React from 'react';

interface UseDragParams {
    value: number;
    minValue: number;
    maxValue: number;
    onValueChange: (value: number) => void;
}

export const useDrag = ({ value: initValue, minValue, maxValue, onValueChange }: UseDragParams) => {
    const scaleRef = React.useRef(null);
    const [value, setValue] = React.useState(initValue);
    const [pointerPosition, setPointerPosition] = React.useState(makePointerPosition(value, minValue, maxValue));
    const [gragInfo, setGragInfo] = React.useState({
        isDragging: false,
        dragStartX: null,
        dragStartValue: null,
    });

    React.useEffect(() => {
        onValueChange(value);
    }, [value]);

    React.useEffect(() => {
        if (!gragInfo.isDragging && initValue !== value) {
            setValue(initValue);
        }
    }, [initValue]);

    React.useEffect(() => {
        setPointerPosition(makePointerPosition(value, minValue, maxValue));
    }, [value, minValue, maxValue]);

    const onPointerMouseDown = React.useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (!gragInfo.isDragging) {
                setGragInfo({
                    ...gragInfo,
                    isDragging: true,
                    dragStartX: event.clientX,
                    dragStartValue: value,
                });
            }
        },
        [gragInfo, value],
    );

    const onMouseMove = React.useCallback(
        (event) => {
            if (gragInfo.isDragging) {
                const scaleElement: HTMLDivElement = scaleRef?.current;
                const scaleWidth = scaleElement?.offsetWidth;
                const currentX = event.clientX;

                const deltaX = currentX - gragInfo.dragStartX;
                const deltaValue = (deltaX / scaleWidth) * (maxValue - minValue);

                let newValue = gragInfo.dragStartValue + deltaValue;

                if (newValue < minValue) {
                    newValue = minValue;
                }

                if (newValue > maxValue) {
                    newValue = maxValue;
                }

                newValue = Math.round(newValue);

                if (newValue !== value) {
                    setValue(newValue);
                }
            }
        },
        [gragInfo, maxValue, minValue],
    );

    const onMouseUp = React.useCallback((event) => {
        setGragInfo({
            ...gragInfo,
            isDragging: false,
            dragStartX: null,
        });
    }, []);

    const onScaleClick = React.useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            const scaleElement: HTMLDivElement = scaleRef?.current;
            const scaleWidth = scaleElement?.offsetWidth;
            const currentX = event.clientX;

            const { left: scaleStartX } = scaleElement.getBoundingClientRect();

            const newPosition = (currentX - scaleStartX) / scaleWidth;

            let newValue = newPosition * (maxValue - minValue) + minValue;

            if (newValue < minValue) {
                newValue = minValue;
            }

            if (newValue > maxValue) {
                newValue = maxValue;
            }

            newValue = Math.round(newValue);

            if (newValue !== value) {
                setValue(newValue);
            }
        },
        [maxValue, minValue],
    );

    React.useEffect(() => {
        if (gragInfo.isDragging) {
            window.addEventListener('mousemove', onMouseMove);
            window.addEventListener('mouseup', onMouseUp);
        } else {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        }
        return () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        };
    }, [gragInfo, onMouseMove, onMouseUp]);

    return {
        scaleRef,
        pointerPosition,
        onPointerMouseDown,
        onScaleClick,
    };
};

function makePointerPosition(value: number, minValue: number, maxValue: number): number {
    return ((value - minValue) / (maxValue - minValue)) * 100;
}
