import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { v4 as uuid } from 'uuid';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { MultiReferenceDictionaryApi } from '@api';

import { BudgetItem, CreateBudgetItemForm } from '@mrm/budget';

import { FormField, FieldValue } from 'sber-marketing-ui';
import { BudgetCard } from './BudgetCard';
import { StoreState } from '@store';
import { GroupedDictionaries, FormData } from '@store/plannedBudgetEdit/types';

import {
    addBudgetForm,
    deleteBudgetForm,
    updateBudgetForm,
    setBudgetCardCollapseStatus,
    setBudgetCardDeletedStatus,
    setBudgetItemTransferDestination,
} from '@store/plannedBudgetEdit/actions';
import { makeBudgetsItems } from '@store/plannedBudgetEdit/selectors';

interface Props extends Partial<MapProps>, Partial<DispatchProps> {
    activityBudgetId: string;
    budgetId: string;
    index: number;
    id: string;
    fields: FormField[];
    collapsed: boolean;
    deleted: boolean;
    isNew: boolean;
    canBeCollapsed: boolean;
    canBeDeleted: boolean;
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi;
    onFinishPasteCopiedBrief: () => void;
}

interface MapProps {
    budgetItemForm: FormData;
    budgetItem: BudgetItem;
    currentBudgetItem?: CreateBudgetItemForm;
    dictionaries?: GroupedDictionaries;
}

interface DispatchProps {
    addBudgetForm?: (form: FormData) => void;
    deleteBudgetForm?: (id: string) => void;
    updateBudgetForm: (formDate: Partial<FormData>) => void;
    setBudgetCardCollapseStatus: (params: { id: string; status: boolean }) => void;
    setBudgetCardDeletedStatus: (params: { id: string; status: boolean }) => void;
    setBudgetItemTransferDestination?: (params: { budgetItemId: string; activityBudgetId: string }) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BudgetCardContainer extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        const {
            id,
            budgetId,
            index,
            fields,
            collapsed,
            deleted,
            isNew,
            currentBudgetItem,
            budgetItem,
            dictionaries,
            canBeCollapsed,
            canBeDeleted,
            budgetItemForm,
            multiReferenceDictionaryApi,
            onFinishPasteCopiedBrief,
        } = this.props;
        const { activityNameIsFocused, transferDestinationId, tagsEditorId } = budgetItemForm;

        return React.createElement(BudgetCard, {
            id,
            budgetId,
            index,
            fields,
            collapsed,
            canBeCollapsed,
            canBeDeleted,
            deleted,
            isNew,
            budgetItem,
            currentBudgetItem,
            dictionaries,
            showActivitySuggest: activityNameIsFocused,
            isMarkedForTransfer: !!transferDestinationId,
            tagsEditorId,
            multiReferenceDictionaryApi,
            onCopyButtonClick: this.onCopyButtonClick,
            onDeleteButtonClick: this.onDeleteButtonClick,
            onCollapseButtonClick: this.onCollapseButtonClick,
            onFinishCreateBrief: this.onFinishCreateBrief,
            onFinishPasteCopiedBrief,
            onSuggestItemClick: this.onSuggestItemClick,
        });
    }

    @autobind
    protected onCopyButtonClick() {
        const { budgetItemForm } = this.props;

        const newForm = this.cloneBudgetItemForm(budgetItemForm);

        this.props.addBudgetForm(newForm);
    }

    @autobind
    protected onSuggestItemClick(newActivityBudgetId: string, activityBudgetName: string) {
        this.setFieldValue('activityBudget', activityBudgetName);

        const { activityBudgetId } = this.props;

        this.props.setBudgetItemTransferDestination({
            budgetItemId: this.props.id,
            activityBudgetId: newActivityBudgetId === activityBudgetId ? null : newActivityBudgetId,
        });
    }

    @autobind
    protected onDeleteButtonClick() {
        const { id, isNew, deleted } = this.props;

        if (isNew) {
            this.props.deleteBudgetForm(id);
        } else {
            this.props.setBudgetCardDeletedStatus({
                id,
                status: !deleted,
            });
            this.props.setBudgetCardCollapseStatus({
                id,
                status: true,
            });
        }
    }

    @autobind
    protected onCollapseButtonClick() {
        this.props.setBudgetCardCollapseStatus({
            id: this.props.id,
            status: !this.props.collapsed,
        });
    }

    @autobind
    protected onFinishCreateBrief(briefId: string) {
        this.props.updateBudgetForm({
            id: this.props.id,
            briefId,
        });
    }

    private cloneBudgetItemForm(budgetItemForm: FormData): FormData {
        const newForm: FormData = lodash.cloneDeep(budgetItemForm);

        newForm.id = uuid();
        newForm.tagsEditorId = uuid();

        newForm.fields.forEach((item) => {
            const fieldIsActivityName = item.name === 'activityBudget';

            item.id = uuid();
            item.disabled = fieldIsActivityName;
            item.readOnly = false;
        });

        newForm.collapsed = false;
        newForm.isNew = true;

        return newForm;
    }

    private setFieldValue(fieldName: string, value: FieldValue) {
        const { budgetItemForm } = this.props;

        const updatedForm: FormData = lodash.cloneDeep(budgetItemForm);

        const field = updatedForm.fields.find((item) => item.name === fieldName);

        field.value = value;

        this.props.updateBudgetForm(updatedForm);
    }
}

function mapStateToProps(state: StoreState, props: Props): MapProps {
    const { id, isNew } = props;
    const { budgetItemForms, budgetItems, availableDictionaries, usedDictionaries } = state.plannedBudgetEditPage;

    const dictionaries = isNew ? availableDictionaries : usedDictionaries;

    const currentBudgetItems = makeBudgetsItems(state);
    const budgetItemForm = budgetItemForms.find((item) => item.id === props.id);

    return {
        budgetItemForm,
        budgetItem: budgetItems.find((budgetItem) => budgetItem.id === id),
        currentBudgetItem: currentBudgetItems.find((item) => item.id == id),
        dictionaries: dictionaries,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            addBudgetForm,
            deleteBudgetForm,
            updateBudgetForm,
            setBudgetCardDeletedStatus,
            setBudgetCardCollapseStatus,
            setBudgetItemTransferDestination,
        },
        dispatch,
    );
}
