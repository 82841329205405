import createCashedSelector from 're-reselect';
import { DivisionParams } from 'sber-marketing-types/frontend';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';

import { DivisionsState, Division, StoreTypes } from './types';

const getDivisionsState = (state: StoreState): DivisionsState => state.divisions;

export const getFetchersCount = createCashedSelector(
    getDivisionsState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: DivisionsState, store: StoreTypes): number => state.stores[store].fetchersCount,
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getDivisionById = createCashedSelector(
    getDivisionsState,
    (state: StoreState, id: string): string => id,
    (state: DivisionsState, id: string): DivisionParams => state.byIds[id],
)((state: StoreState, id: string): string => id);

export const getDivisions = createCashedSelector(
    getDivisionsState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: DivisionsState, store: StoreTypes): Division[] => {
        const entitiesStore = state.stores[store];

        if (entitiesStore.loadingStatus === LoadingStatus.LOADED) {
            // const rootNodes: string[] = [];
            return entitiesStore.ids.map((id) => ({
                ...state.byIds[id],
                children: [],
            }));

            // divisions.forEach(division => {
            //     if (entitiesStore.ids.includes(division.parentId)) {
            //         divisions.find(({ id }) => id === division.parentId).children.push(division);
            //     } else {
            //         rootNodes.push(division.id);
            //     }
            // });

            // return divisions.filter(division => rootNodes.includes(division.id));
        }

        return [];
    },
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getDivisionIds = createCashedSelector(
    getDivisionsState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: DivisionsState, store: StoreTypes): string[] => state.stores[store].ids,
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getLoadingStatus = createCashedSelector(
    getDivisionsState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: DivisionsState, store: StoreTypes): LoadingStatus => state.stores[store].loadingStatus,
)((state: StoreState, store: StoreTypes): StoreTypes => store);
