import * as React from 'react';
import * as moment from 'moment';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import { get, compact, isNil } from 'lodash';
import { BudgetItem } from '@mrm/budget';

import { StoreState } from '@store';
import { getBudgetItemByLineId } from '@store/budgetPlanning';
import { BriefApi } from '@api';

import { Utils } from '@common/Utils';

import { BudgetWindowMode } from '../BudgetWidget/BudgetWindow';
import { BudgetButton } from '../BudgetWidget/BudgetButton';

import { CommonInfoWidget } from './CommonInfoWidget';
import { Link } from './Link';

interface Props extends Partial<MapProps> {
    lineId: string;
    sourceBudgetItem: BudgetItem;
}

interface State {
    briefSchemeName: string;
    briefSchemeLoading: boolean;
    isBudgetWindowVisible: boolean;
    budgetWindowMode: BudgetWindowMode;
}

interface MapProps {
    budgetItem: BudgetItem;
}

@(connect(mapStateToProps) as any)
export class CommonInfoWidgetContainer extends React.Component<Props, State> {
    private ref: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: Props) {
        super(props);

        this.state = {
            briefSchemeName: null,
            briefSchemeLoading: true,
            isBudgetWindowVisible: false,
            budgetWindowMode: BudgetWindowMode.TOOLTIP,
        };
    }

    public async componentDidMount() {
        let briefSchemeName = null;

        const haveBrief = !isNil(this.props.budgetItem.briefId);
        if (haveBrief) {
            const brief = await BriefApi.getBrief(this.props.budgetItem.briefId);

            const haveSchemeIdOfBrief = !isNil(brief.schemeId);
            if (haveSchemeIdOfBrief) {
                const scheme = await BriefApi.getBriefSchemeById(brief.schemeId);
                briefSchemeName = scheme.name;
            }
        }

        this.setState({
            briefSchemeName,
            briefSchemeLoading: false,
        });
    }

    public render() {
        return React.createElement(CommonInfoWidget, {
            refObject: this.ref,
            lineId: this.props.lineId,
            sourceBudgetItem: this.props.sourceBudgetItem,
            isBudgetWindowVisible: this.state.isBudgetWindowVisible,
            budgetWindowMode: this.state.budgetWindowMode,
            plannedFunds: this.props.budgetItem.plannedFunds,
            blocks: this.buildInfoBlockParamsList(),
            onBudgetWindowClose: this.onBudgetWindowClose,
        });
    }

    private buildInfoBlockParamsList() {
        const { budgetItem } = this.props;
        const { briefSchemeName, briefSchemeLoading } = this.state;

        function shouldPick(path: string): boolean {
            return !!get(budgetItem, path);
        }

        return [
            {
                title: 'Автор:',
                content: shouldPick('author')
                    ? this.buildUserName([budgetItem.author.firstName, budgetItem.author.secondName])
                    : '–',
                qaId: 'budgetPlanningCommonInfoWidgetAuthor',
            },
            {
                title: 'Ответственные:',
                content: shouldPick('responsibles.length')
                    ? budgetItem.responsibles
                          .map((responsible) => this.buildUserName([responsible.firstName, responsible.secondName]))
                          .join(', ')
                    : '–',
                qaId: 'budgetPlanningCommonInfoWidgetResponsibles',
            },
            {
                title: 'Дата создания:',
                content: shouldPick('creationTime') ? this.formatDate(budgetItem.creationTime) : '–',
                qaId: 'budgetPlanningCommonInfoWidgetCreationTime',
            },
            {
                title: 'Название активности:',
                content: shouldPick('activity.name') ? budgetItem.activity.name : '–',
                qaId: 'budgetPlanningCommonInfoWidgetActivityName',
            },
            {
                title: 'Блок:',
                content: shouldPick('dictionary.block.value')
                    ? Utils.getDictionaryValue(budgetItem.dictionary.block)
                    : '–',
                qaId: 'budgetPlanningCommonInfoWidgetBlock',
            },
            {
                title: 'Дивизион:',
                content: shouldPick('dictionary.division.value')
                    ? Utils.getDictionaryValue(budgetItem.dictionary.division)
                    : '–',
                qaId: 'budgetPlanningCommonInfoWidgetDivision',
            },
            {
                title: 'инструмент:',
                content: shouldPick('dictionary.tool.value')
                    ? Utils.getDictionaryValue(budgetItem.dictionary.tool)
                    : '–',
                qaId: 'budgetPlanningCommonInfoWidgetTool',
            },
            {
                title: 'Статья:',
                content: shouldPick('dictionary.item.value')
                    ? Utils.getDictionaryValue(budgetItem.dictionary.item)
                    : '–',
                qaId: 'budgetPlanningCommonInfoWidgetItem',
            },
            {
                title: 'Ресурс:',
                content: shouldPick('dictionary.resource.value')
                    ? Utils.getDictionaryValue(budgetItem.dictionary.resource)
                    : '–',
                qaId: 'budgetPlanningCommonInfoWidgetResource',
            },
            {
                title: 'Бриф:',
                content: briefSchemeName ? (
                    <Link url={`/budget/planning/budgetItem/${budgetItem.id}/brief`}>{briefSchemeName}</Link>
                ) : (
                    <Link url={`/budget/planning/budgetItem/${budgetItem.id}/brief/create`}>Создать бриф</Link>
                ),
                loading: briefSchemeLoading,
                qaId: 'budgetPlanningCommonInfoWidgetBrief',
            },
            {
                title: null,
                content: null,
                loading: false,
                qaId: '',
            },
            {
                title: '',
                content: (
                    <BudgetButton
                        active={this.state.budgetWindowMode === BudgetWindowMode.TOOLTIP}
                        refObject={this.ref}
                        onClick={this.onBudgetButtonClick}
                        onMouseEnter={this.onBudgetButtonMouseEnter}
                        onMouseLeave={this.onBudgetButtonMouseLeave}
                    />
                ),
                qaId: 'budgetButton',
            },
        ];
    }

    private buildUserName(parts: string[]): string {
        return compact(parts).reduce((name, part) => `${name} ${part}`, '');
    }

    private formatDate(date: string | Date): string {
        return moment(date).format('DD MMMM YYYY');
    }

    @autobind
    private onBudgetButtonClick(): void {
        this.setState((state) => ({
            budgetWindowMode:
                state.budgetWindowMode === BudgetWindowMode.TOOLTIP
                    ? BudgetWindowMode.DRAG_WINDOW
                    : BudgetWindowMode.TOOLTIP,
            isBudgetWindowVisible: false,
        }));
    }

    @autobind
    private onBudgetWindowClose(): void {
        this.setState((state) => ({
            budgetWindowMode: BudgetWindowMode.TOOLTIP,
            isBudgetWindowVisible: false,
        }));
    }

    @autobind
    private onBudgetButtonMouseEnter(): void {
        this.setState({ isBudgetWindowVisible: true });
    }

    @autobind
    private onBudgetButtonMouseLeave(): void {
        this.setState({ isBudgetWindowVisible: false });
    }
}

function mapStateToProps(store: StoreState, props: Props): MapProps {
    const { lineId } = props;

    return {
        budgetItem: getBudgetItemByLineId(store, lineId),
    };
}
