import { bindActionCreators } from 'redux';
import * as lodash from 'lodash';

import { Brief } from 'sber-marketing-types/frontend';

import { store } from '@store';
import { replaceBrief, setCurrentBrief, setBudgetItem } from '@store/brief/actions';
import { add as addFiles } from '@store/fileAssets';
import { FileApi, BriefApi, BudgetItemApi } from '@api';
import { BriefLoader } from './types';

interface ConstructorParams {
    briefId: string;
    budgetItemId: string;
}

export class BudgetItemBriefLoader implements BriefLoader {
    private briefId: string;
    private budgetItemId: string;

    private brief: Brief;

    private dispatch = bindActionCreators(
        {
            replaceBrief,
            setCurrentBrief,
            setBudgetItem,
            addFiles,
        },
        store.dispatch,
    );

    constructor({ briefId, budgetItemId }: ConstructorParams) {
        this.briefId = briefId;
        this.budgetItemId = budgetItemId;
    }

    public setBriefId(briefId: string): void {
        this.briefId = briefId;
    }

    public async updateBrief(): Promise<void> {
        const brief = await BriefApi.getBrief(this.briefId);
        this.brief = this.formatFilesData(brief);

        this.dispatch.replaceBrief(this.brief);
    }

    public async updateBudgetItem(): Promise<void> {
        const budgetItem = await BudgetItemApi.getBudgetItem(this.budgetItemId);
        this.dispatch.setBudgetItem(budgetItem);
    }

    public pasteCurrentBrief(copiedBrief: Brief): void {
        const formattedBrief = this.formatFilesData(copiedBrief);
        this.dispatch.setCurrentBrief({
            ...this.brief,
            blocks: formattedBrief.blocks,
        });
    }

    private formatFilesData(brief: Brief) {
        const updatedBrief = lodash.cloneDeep(brief);

        updatedBrief.blocks.forEach((block) =>
            block.fields.forEach((field) => {
                if (field.value && field.value.files) {
                    const files = FileApi.mapFiles(
                        {
                            fieldId: field.id,
                            briefId: brief.id,
                        },
                        field.value.files as any[],
                        '',
                    ) as any[];
                    field.value.files = files;
                    this.dispatch.addFiles(...files);
                }
            }),
        );

        return updatedBrief;
    }
}
