// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-channel-components-Channel-_Channel__root{border-bottom:3px solid transparent;transition:border-bottom-color .3s;padding-bottom:5px;position:relative}.src-client-modules-channel-components-Channel-_Channel__active{border-bottom-color:#18ba4e}.src-client-modules-channel-components-Channel-_Channel__link{font-family:\"Open Sans\", monospace;color:#000;font-size:14px;font-weight:600}@media (pointer: fine){.src-client-modules-channel-components-Channel-_Channel__link:hover{color:#18ba4e}}\n", "",{"version":3,"sources":["webpack://./src/client/modules/channel/components/Channel/Channel.scss"],"names":[],"mappings":"AAAA,8DACE,mCAAoC,CACpC,kCAAmC,CACnC,kBAAmB,CACnB,iBAAkB,CACnB,gEAGC,2BAA4B,CAC7B,8DAGC,kCAAmC,CACnC,UAAW,CACX,cAAe,CACf,eAAgB,CAEhB,uBANF,oEAQM,aAAc,CACf","sourcesContent":[".root {\n  border-bottom: 3px solid transparent;\n  transition: border-bottom-color .3s;\n  padding-bottom: 5px;\n  position: relative;\n}\n\n.active {\n  border-bottom-color: #18ba4e;\n}\n\n.link {\n  font-family: \"Open Sans\", monospace;\n  color: #000;\n  font-size: 14px;\n  font-weight: 600;\n\n  @media (pointer: fine) {\n    &:hover {\n      color: #18ba4e;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-channel-components-Channel-_Channel__root",
	"active": "src-client-modules-channel-components-Channel-_Channel__active",
	"link": "src-client-modules-channel-components-Channel-_Channel__link"
};
export default ___CSS_LOADER_EXPORT___;
