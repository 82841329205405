import * as React from 'react';

import { TimeUnitGroup, DateMark } from '../types';
import { Scaler } from '../modules';

import { Timeline } from './Timeline';

interface Props {
    timeUnitGroups: TimeUnitGroup[];
    scaler: Scaler;
    canvasHeight: number;
    dateMarks: DateMark[];
    setDateMarkVisibility: (date: Date, isVisible: boolean) => void;
}

export class TimelineContainer extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        return React.createElement(Timeline, {
            timeUnitGroups: this.props.timeUnitGroups,
            scaler: this.props.scaler,
            canvasHeight: this.props.canvasHeight,
            dateMarks: this.props.dateMarks,
            setDateMarkVisibility: this.props.setDateMarkVisibility,
        });
    }
}
