import * as React from 'react';
import { keys, includes } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import { getBudgetExecutionPageState, getTableFilters, resetFilter, FilterKey } from '@store/budgetExecution';

export type UseStore = () => UseStoreReturn;

export interface UseStoreReturn {
    filtersNames: FilterKey[];
    filtersValuesCounts: FiltersValuesCounts;
    onTagClose: (filterName: string) => void;
}

export type FiltersValuesCounts = {
    [P in FilterKey]: number;
};

export const useStore: UseStore = () => {
    const dispatch = useDispatch();

    const appliedFiltersNames = useSelector((state: StoreState) => getTableFilters(state).appliedFiltersNames || []);
    const filters = useSelector((state: StoreState) => getBudgetExecutionPageState(state).columnFilters.filters || []);

    const onTagClose = React.useCallback((filterName: string) => {
        dispatch(resetFilter({ filterName }));
    }, []);

    const filtersValuesCounts = React.useMemo(() => {
        return keys(filters)
            .filter((filterName) => includes(appliedFiltersNames, filterName))
            .reduce(
                (filtersValuesCounts, filterName) => ({
                    ...filtersValuesCounts,
                    [filterName]: keys(filters[filterName]).reduce(
                        (count, key) => (filters[filterName][key] === true ? count + 1 : count),
                        0,
                    ),
                }),
                {},
            ) as FiltersValuesCounts;
    }, [filters, appliedFiltersNames]);

    return {
        filtersNames: appliedFiltersNames,
        filtersValuesCounts,
        onTagClose,
    };
};
