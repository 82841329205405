import * as React from 'react';
import { DataProps, graphql } from 'react-apollo';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { ActivityFileGroup } from 'sber-marketing-types/frontend';

import { GET_PROJECT_FILES_QUERY } from './query';

interface Props extends ExternalProps, QueryProps {}

interface ExternalProps {
    activityId: number;
    children: (props: ChildrenProps) => JSX.Element;
}

interface ChildrenProps {
    loading: boolean;
    filesByGroup: GroupedFiles;
    refetch: () => Promise<void>;
}

export type GroupedFiles = { [groupName in ActivityFileGroup]: FileProps[] };

type QueryProps = DataProps<{
    project: {
        nodes: {
            files: {
                category: ActivityFileGroup;
                file: FileProps;
            }[];
        }[];
    };
}>;

export interface FileProps {
    id: string;
    name: string;
    originName: string;
    type: string;
    size: number;
}

class FileQuery extends React.PureComponent<Props> {
    private defaultGroupedFiles: GroupedFiles = {
        [ActivityFileGroup.CREATIVE]: [],
        [ActivityFileGroup.MEDIAPLAN]: [],
    };

    public render(): JSX.Element {
        return this.props.children({
            refetch: this.refetch,
            loading: this.getLoadingStatus(),
            filesByGroup: this.getGroupedFiles(),
        });
    }

    @autobind
    private async refetch() {
        if (this.props.data) {
            await this.props.data.refetch();
        }
    }

    private getLoadingStatus(): boolean {
        return this.props.data ? this.props.data.loading : false;
    }

    private getGroupedFiles(): GroupedFiles {
        const { data } = this.props;

        if (!data?.project?.nodes?.length) {
            return this.defaultGroupedFiles;
        }

        const { files } = lodash.first(data.project.nodes);

        if (!files) {
            return this.defaultGroupedFiles;
        }

        return files.reduce((acc, item) => {
            if (!acc[item.category]) {
                acc[item.category] = [];
            }

            acc[item.category].push(item.file);

            return acc;
        }, lodash.cloneDeep(this.defaultGroupedFiles));
    }
}

export const WithFileQuery = graphql<ExternalProps>(GET_PROJECT_FILES_QUERY, {
    options: ({ activityId }) => ({
        variables: {
            projectId: activityId,
        },
    }),
    skip: ({ activityId }) => !activityId,
})(FileQuery);
