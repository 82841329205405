import * as React from 'react';
import classNames from 'classnames';
import * as moment from 'moment';

import { Icon, IconType } from 'sber-marketing-ui';

import { ApiAutopilot } from '@modules/autopilot/types';

import * as style from './AutopilotStatus.scss';

export type Statuses = 'idle' | 'active' | 'done';

export const statuses: Record<Statuses, string> = {
    idle: 'Планируется',
    active: 'Активен',
    done: 'Завершен',
};

export interface AutopilotStatusProps {
    autopilot: ApiAutopilot;
}

export function getAutopilotStatus(autopilot: ApiAutopilot): Statuses {
    if (!autopilot.brief || moment().isBefore(autopilot.brief.dateStart)) return 'idle';

    if (moment().add(-1, 'day').isBefore(autopilot.brief.dateEnd)) {
        return 'active';
    }

    return 'done';
}

export const AutopilotStatus: React.FC<AutopilotStatusProps> = ({ autopilot }) => {
    const status = getAutopilotStatus(autopilot);
    return (
        <span className={classNames(style.root, style[status])}>
            {status === 'done' && <Icon className={style.icon} svgSize={12} type={IconType.CIRCLE_BOLD_CHECK} />}
            {statuses[status]}
        </span>
    );
};
