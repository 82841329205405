import * as React from 'react';
import { Skeleton } from 'sber-marketing-ui';

import * as styles from './ExistingNameItem.scss';

interface Props {
    name: string;
    budgetYear: number;
    onClick: () => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}

export function ExistingNameItem({ name, budgetYear, onClick, onMouseEnter, onMouseLeave }: Props): JSX.Element {
    return (
        <div className={styles.root} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div className={styles.activityName}>{name}</div>

            <div className={styles.activityYear}>Активность {budgetYear} года</div>
        </div>
    );
}

export function ExistingNameItemStub(): JSX.Element {
    return (
        <Skeleton isReady={false} className={styles.stub} loadingClassName={styles.stubLoading}>
            {null}
        </Skeleton>
    );
}
