import { ReactText } from 'react';

import { BudgetCorrectionsUserConfig } from '@store/userConfig/budgetCorrections';

export interface BudgetCorrectionsFiltersAndExportState {
    exportInProgressState: ExportInProgressState;
    items: FilterItems;
    currentFilters: FilterValues;
    savedFilters: FilterValues;
}

export enum ExportInProgressState {
    None = 'None',
    WithFilters = 'WithFitlers',
    WithoutFilters = 'WithoutFilters',
}

export interface FilterItems {
    [TargetFilter.Status]: TargetFilterItems;
    [TargetFilter.Types]: TargetFilterItems;
    [TargetFilter.ActivityNames]: TargetFilterItems;
    [TargetFilter.BudgetItemNames]: TargetFilterItems;
    [TargetFilter.Authors]: TargetFilterItems;
    [TargetFilter.Approvers]: TargetFilterItems;
    [TargetFilter.Blocks]: TargetFilterItems;
    [TargetFilter.ActivityTypes]: TargetFilterItems;
    [TargetFilter.Directions]: TargetFilterItems;
    [TargetFilter.Tools]: TargetFilterItems;
    [TargetFilter.ExecutionIds]: TargetFilterItems;
    [TargetFilter.Regionalities]: TargetFilterItems;
    [TargetFilter.Territories]: TargetFilterItems;
    [TargetFilter.Divisions]: TargetFilterItems;
    [TargetFilter.CostCenter]: TargetFilterItems;
}

export type TargetFilterItems = {
    id: ReactText;
    title: string;
}[];

export const enum TargetFilter {
    Status = 'status',
    Types = 'type',
    ActivityNames = 'activityNames',
    BudgetItemNames = 'budgetItemNames',
    Authors = 'author',
    Approvers = 'approver',
    Blocks = 'blocks',
    ActivityTypes = 'activityTypes',
    Directions = 'directions',
    Tools = 'tools',
    ExecutionIds = 'planIds',
    // PlanIds = 'planIds'
    Regionalities = 'regionalities',
    Territories = 'territories',
    Divisions = 'divisions',
    CostCenter = 'costCenter',
}

export interface FilterValues extends BudgetCorrectionsUserConfig {
    isExportModeEnabled: boolean;
    dateRangeStart: Date;
    dateRangeEnd: Date;
}

export interface GraphqlFilterValues {
    status: string[];
    directions: string[];
    types: string[];
    activityNames: string[];
    budgetItemNames: string[];
    author: {
        id: number;
        firstName: string;
        lastName: string;
    }[];
    approver: {
        id: number;
        firstName: string;
        lastName: string;
    }[];
    blocks: string[];
    activityTypes: string[];
    tools: string[];
    executionIds: number[];
    regionalities: string[];
    territories: string[];
    divisionIds: string[];
    costCenterIds: string[];
}
