import { Success } from 'typescript-fsa';

import { BudgetTransferMenuState, ControlsState as State, ComponentState, InternalTransferDirection } from '../types';

export class ControlsReducer {
    public static makeInitialState(): State {
        return {
            componentState: ComponentState.Closed,
            internalTransferDirection: InternalTransferDirection.ManyToOne,
            isRequestInProgress: false,
            isTransferDirectionToggleHovered: false,
            isHoveredLineClickable: false,
            rowForSumEntering: null,
        };
    }

    public static mergeAfterAsyncDone(
        state: BudgetTransferMenuState,
        payload: Success<any, Partial<State>>,
    ): BudgetTransferMenuState {
        return ControlsReducer.merge(state, payload.result);
    }

    public static merge(state: BudgetTransferMenuState, controls: Partial<State>): BudgetTransferMenuState {
        return { ...state, controls: { ...state.controls, ...controls } };
    }
}
