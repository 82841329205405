import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, IconType } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { TagGroup, TagGroupColors, getTagsPageState, toggleTagGroupFilter } from '@store/tagsPage';

import * as styles from './TagGroupFilter.scss';

export function TagGroupFilter(): JSX.Element {
    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'tagsPageTagGroupFilter',
            }}
        >
            <div className={styles.title}>Показывать:</div>

            <div className={styles.items}>
                <TagGroupFilterItem group={TagGroup.Project} title="Проекты" />
                <TagGroupFilterItem group={TagGroup.Task} title="Задачи" />
                <TagGroupFilterItem group={TagGroup.ExecutionId} title="ID исполнения" />
                <TagGroupFilterItem group={TagGroup.PlanId} title="ID плана" />
            </div>
        </div>
    );
}

interface TagGroupFilterItemProps {
    group: TagGroup;
    title: string;
}

function TagGroupFilterItem({ group, title }: TagGroupFilterItemProps): JSX.Element {
    const dispatch = useDispatch();

    const isSelected = useSelector((state: StoreState) => getTagsPageState(state).filters.groups.includes(group));

    function onClick() {
        dispatch(toggleTagGroupFilter(group));
    }

    return (
        <div
            className={styles.item}
            style={
                isSelected
                    ? {
                          background: TagGroupColors[group],
                      }
                    : null
            }
            onClick={onClick}
            {...{
                'qa-id': 'tagsPageTagGroupFilterItem',
                'qa-value': group,
            }}
        >
            <Icon
                type={isSelected ? IconType.EYE_OPENED : IconType.EYE_CLOSED}
                svgSize={24}
                className={styles.itemIcon}
            />

            {title}
        </div>
    );
}
