// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-TaskCard-Executor-_Executor__skeleton{width:210px;height:24px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/TaskCard/Executor/Executor.scss"],"names":[],"mappings":"AAAA,iEACI,WAAY,CACZ,WAAY","sourcesContent":[".skeleton {\n    width: 210px;\n    height: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeleton": "src-client-modules-common-TaskCard-Executor-_Executor__skeleton"
};
export default ___CSS_LOADER_EXPORT___;
