import { createSelector } from 'reselect';
import createCashedSelector from 're-reselect';

import { StoreState } from '@store';

import {
    PageState,
    PageData,
    SelectedCard,
    UserRoleFilter,
    CardsLoadingModeFilter,
    CardType,
    RightSidebarState,
} from './types';

export const getDashboardPageState = (state: StoreState): PageState => state.dashboardPage;

export const getPageData = createSelector(getDashboardPageState, (state: PageState): PageData => {
    return state.pageData;
});

export const getSelectedCard = createSelector(getDashboardPageState, (state: PageState): SelectedCard => {
    return state.selectedCard;
});

export const getUserRoleFilter = createSelector(getDashboardPageState, (state: PageState): UserRoleFilter => {
    return state.userRoleFilter;
});

export const getCardsLoadingModeFilter = createSelector(
    getDashboardPageState,
    (state: PageState): CardsLoadingModeFilter => {
        return state.cardsLoadingModeFilter;
    },
);

export const getRightSidebarState = createCashedSelector(
    getDashboardPageState,
    (state: StoreState, cardType: CardType): CardType => cardType,
    <T extends CardType>(state: PageState, cardType: T): RightSidebarState[T] => state.rightSidebarStates[cardType],
)((state: StoreState, cardType: CardType): CardType => cardType);
