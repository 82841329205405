// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-brief-Brief-_Brief__blocks{user-select:none;background-color:#ffffff;box-shadow:0 1px 6px 0 rgba(0,0,0,0.1)}.src-client-modules-brief-Brief-_Brief__block{padding:16px 24px;border-bottom:1px solid #dbdbdb}.src-client-modules-brief-Brief-_Brief__block:last-child{border-bottom:0}.src-client-modules-brief-Brief-_Brief__blockTitle{color:#262626;font-family:'Open Sans';font-size:14px;font-weight:600;line-height:20px}.src-client-modules-brief-Brief-_Brief__blockFields{display:block}.src-client-modules-brief-Brief-_Brief__field{display:block}.src-client-modules-brief-Brief-_Brief__briefSumDicWrapper{padding:32px 24px 44px 24px}.src-client-modules-brief-Brief-_Brief__error{color:#e63900}\n", "",{"version":3,"sources":["webpack://./src/client/modules/brief/Brief/Brief.scss"],"names":[],"mappings":"AAAA,+CACI,gBAAiB,CAEjB,wBAAyB,CACzB,sCAA0C,CAC7C,8CAGG,iBAAkB,CAElB,+BAAgC,CAHpC,yDAMQ,eAAgB,CACnB,mDAID,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACpB,oDAGG,aAAc,CACjB,8CAGG,aAAc,CACjB,2DAGG,2BAA4B,CAC/B,8CAGG,aAAc","sourcesContent":[".blocks {\n    user-select: none;\n\n    background-color: #ffffff;\n    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);\n}\n\n.block {\n    padding: 16px 24px;\n\n    border-bottom: 1px solid #dbdbdb;\n\n    &:last-child {\n        border-bottom: 0;\n    }\n}\n\n.blockTitle {\n    color: #262626;\n\n    font-family: 'Open Sans';\n    font-size: 14px;\n    font-weight: 600;\n    line-height: 20px;\n}\n\n.blockFields {\n    display: block;\n}\n\n.field {\n    display: block;\n}\n\n.briefSumDicWrapper {\n    padding: 32px 24px 44px 24px;\n}\n\n.error {\n    color: #e63900;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blocks": "src-client-modules-brief-Brief-_Brief__blocks",
	"block": "src-client-modules-brief-Brief-_Brief__block",
	"blockTitle": "src-client-modules-brief-Brief-_Brief__blockTitle",
	"blockFields": "src-client-modules-brief-Brief-_Brief__blockFields",
	"field": "src-client-modules-brief-Brief-_Brief__field",
	"briefSumDicWrapper": "src-client-modules-brief-Brief-_Brief__briefSumDicWrapper",
	"error": "src-client-modules-brief-Brief-_Brief__error"
};
export default ___CSS_LOADER_EXPORT___;
