// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-components-CommentLikeCard-_CommentLikeCard__root{padding:16px 56px 24px;background-color:rgba(255,255,255,0.9);box-shadow:0 1px 6px 0 rgba(0,0,0,0.1);font-family:Roboto}@media screen and (max-width: 1350px){.src-client-modules-activity-pages-task-components-CommentLikeCard-_CommentLikeCard__root{padding:16px 16px 24px}}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/components/CommentLikeCard/CommentLikeCard.scss"],"names":[],"mappings":"AAEA,0FACI,sBAAuB,CAEvB,sCAA0C,CAC1C,sCAA0C,CAE1C,kBAAmB,CAKtB,sCAXD,0FASQ,sBAAuB,CAE9B","sourcesContent":["@import '../../../../../common/mixins/media';\n\n.root {\n    padding: 16px 56px 24px;\n\n    background-color: rgba(255, 255, 255, 0.9);\n    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);\n\n    font-family: Roboto;\n\n    @include media-m {\n        padding: 16px 16px 24px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-components-CommentLikeCard-_CommentLikeCard__root"
};
export default ___CSS_LOADER_EXPORT___;
