import type { BudgetItem, Funds } from '@mrm/budget';
import { CorrectionType, CorrectionStatus, Month, BudgetStatus } from '@mrm/budget';
import type { PlainDictionary } from '@mrm/dictionary';
import { DictionaryType } from '@mrm/dictionary';
import type { UserResponseParams } from 'sber-marketing-types/backend';
import type { UnsavedChange } from '@store/budgetCorrections/types';
import { PlanPermissionsSysNames } from 'sber-marketing-types/frontend';
import { RESERVED_COLUMN_NAMES, PLANNED_COLUMN_NAMES, FACT_COLUMN_NAMES, ColumnName } from '@store/budgetExecution';

export type IncomeExternalPlanFundsTransferCorrection = Correction<CorrectionType.IncomeExternalPlanFundsTransfer>;
export type OutcomeExternalPlanFundsTransferCorrection = Correction<CorrectionType.OutcomeExternalPlanFundsTransfer>;
export type ReservedFundsCorrection = Correction<CorrectionType.ReservedFunds>;
export type PlanFundsTransferCorrection = Correction<CorrectionType.PlanFundsTransfer>;
export type FactFundsCorrection = Correction<CorrectionType.FactFunds>;
export type BudgetItemCorrection = Correction<CorrectionType.BudgetItem>;
export type ActivityCorrection = Correction<CorrectionType.Activity>;

export interface Correction<TCorrectionType extends CorrectionType = any> {
    id: string;
    type: CorrectionType;
    status: CorrectionStatus;
    serialNumber: number;
    creationTime: Date;
    comment: string;
    author: Person;
    expert: Person;
    approver: Person;
    overwrittenSerialNumber: string;
    rejectedSerialNumber: string;
    params: Params<TCorrectionType>;
    activityCurrent: {
        id: number;
        name: string;
    }[];
    activityBefore: {
        id: number;
        name: string;
    }[];
    budgetItemCurrent: BudgetItem[];
    budgetItemBefore: BudgetItem[];
}

type Params<TCorrectionType extends CorrectionType> = CorrectionParamsTypes[TCorrectionType];

type CorrectionParamsTypes = {
    [CorrectionType.Activity]: {
        name: string;
    };
    [CorrectionType.BudgetItem]: {
        businessTarget: string;
        customerName: string;
        comment: string;
        expertComment: string;
        sapComment: string;
        sapNumber: string;
        sapZns: string;
        dictionaryIds: string[];
        responsibleIds: number[];
        realizationEnd: Date;
        realizationStart: Date;
        previousFunds: number;
    };
    [CorrectionType.FactFunds]: Funds;
    [CorrectionType.ReservedFunds]: Funds;
    [CorrectionType.PlanFundsTransfer]: {
        value: number;
        donorId: string;
        acceptorId: string;
        acceptorMonth: Month;
        donorMonth: Month;
    };
    [CorrectionType.IncomeExternalPlanFundsTransfer]: {
        value: number;
        acceptorMonth: Month;
        acceptorId: string;
        dictionary: {
            item: {
                id: string;
            };
        };
        comment: string;
    };
    [CorrectionType.OutcomeExternalPlanFundsTransfer]: {
        value: number;
        donorMonth: Month;
        donorId: string;
        dictionary: {
            item: {
                id: string;
            };
        };
        comment: string;
    };
};

export interface Person {
    id: number;
    firstName: string;
    secondName: string;
}

export type { BudgetItem, PlainDictionary as Dictionary, Funds, UserResponseParams as User, UnsavedChange };

export {
    CorrectionType,
    CorrectionStatus,
    Month,
    DictionaryType,
    BudgetStatus,
    PlanPermissionsSysNames,
    ColumnName,
    PLANNED_COLUMN_NAMES,
    RESERVED_COLUMN_NAMES,
    FACT_COLUMN_NAMES,
};

export interface CorrectionsQueryProps {
    loading: boolean;
    corrections: Correction[];
}

type MonthIndexMap = {
    [P in Month]: number;
};

export const orderOfMonth = [
    Month.Jan,
    Month.Feb,
    Month.Mar,
    Month.Apr,
    Month.May,
    Month.Jun,
    Month.Jul,
    Month.Aug,
    Month.Sept,
    Month.Oct,
    Month.Nov,
    Month.Dec,
];

export const monthIndexMap: MonthIndexMap = {
    [Month.Jan]: 0,
    [Month.Feb]: 1,
    [Month.Mar]: 2,
    [Month.Apr]: 3,
    [Month.May]: 4,
    [Month.Jun]: 5,
    [Month.Jul]: 6,
    [Month.Aug]: 7,
    [Month.Sept]: 8,
    [Month.Oct]: 9,
    [Month.Nov]: 10,
    [Month.Dec]: 11,
};
