import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { TaskApi } from '@api';

import { StoreState } from '@store';

import * as actions from '../actions';
import { ToggleCommentReactionPayload } from '../types';

export const toggleCommentReaction = bindThunkAction<StoreState, ToggleCommentReactionPayload, void, Error>(
    actions.asyncActions.toggleCommentReaction,
    async (params, dispatch, getState) => {
        const { taskId, commentId, reaction } = params;

        await TaskApi.reactToTaskComment(taskId, commentId, { reaction });

        dispatch(actions.syncActions.updateExistingComment((await TaskApi.getTaskComment(taskId, commentId)).comment));
    },
);
