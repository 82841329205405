import * as React from 'react';
import * as moment from 'moment';
import { sanitize } from 'dompurify';

import * as styles from './ReleaseNotesPage.scss';

import { ResponsiveLayout, LeftSidebar, CentralContent, RightSidebar } from 'sber-marketing-ui';

import { QuickAccessIcons } from '@common/QuickAccessIcons';

export interface ReleaseNote {
    date: string;
    message: string;
}

interface Props {
    messages: ReleaseNote[];
}

export function ReleaseNotesPage({ messages }: Props): JSX.Element {
    return (
        <ResponsiveLayout>
            <LeftSidebar />

            <CentralContent>
                <div className={styles.root}>
                    {messages.map((item) => (
                        <div className={styles.messageWrapper} key={item.date}>
                            <div className={styles.date}>{formatDate(item.date)}</div>

                            <div
                                className={styles.message}
                                dangerouslySetInnerHTML={{
                                    __html: sanitize(formatMessage(item.message), {
                                        ALLOWED_TAGS: ['br', 'b', 'img'],
                                    }),
                                }}
                            />
                        </div>
                    ))}
                </div>
            </CentralContent>

            <RightSidebar />
        </ResponsiveLayout>
    );
}

export function ReleaseNotesPageHeader(): JSX.Element {
    return (
        <ResponsiveLayout>
            <LeftSidebar />

            <CentralContent>
                <div className={styles.headerCentralContent}>
                    <div className={styles.headerTitle}>История изменений</div>

                    <div className={styles.quickAccessIcons}>
                        <QuickAccessIcons />
                    </div>
                </div>
            </CentralContent>

            <RightSidebar />
        </ResponsiveLayout>
    );
}

function formatDate(date: string) {
    moment.locale('ru');
    return moment(date, 'YYYY-MM-DD').format('D MMMM');
}

function formatMessage(message: string) {
    return message.replace(/\n/g, '<br />');
}
