import * as React from 'react';

import { WithTitle } from '../WithTitle';
import { Money, MoneyFormatter } from '@common/Utils';

import * as style from './SumWithTitle.scss';

interface Props {
    title: string;
    sum: number;
    month?: string;
}

export const SumWithTitle: React.FC<Props> = ({ title, sum, month }) => {
    const formattedSum = formatMoneyWithoutSign(sum);
    const formattedSumForTooltip = formatMoneyWithoutSignForTooltip(sum);

    return (
        <WithTitle title={title} month={month}>
            <div
                title={formattedSumForTooltip}
                className={style.sum}
                {...{
                    'qa-id': 'sumWithTitle',
                }}
            >
                {formattedSum}
            </div>
        </WithTitle>
    );
};

function formatMoneyWithoutSign(money: number): string {
    return MoneyFormatter.toThousands(Money.fromCopecks(money));
}

function formatMoneyWithoutSignForTooltip(money: number): string {
    return MoneyFormatter.toRoubles(Money.fromCopecks(money));
}
