import * as React from 'react';
import { isNil } from 'lodash';
import autobind from 'autobind-decorator';

import { DashboardHeader } from './DashboardHeader';
import { CurrentPage } from '../DashboardsPage';

export interface Props {
    currentPage?: CurrentPage;
}

export class DashboardHeaderContainer extends React.Component<Props> {
    private scrollInProgress = false;

    public render(): JSX.Element {
        const { currentPage } = this.props;
        return React.createElement(DashboardHeader, {
            currentPage,
            onMyTasksSelect: this.onMyTasksSelect,
            onAccessibleProjectsSelect: this.onAccessibleProjectsSelect,
            onMyProjectsSelect: this.onMyProjectsSelect,
        });
    }

    @autobind
    private onMyTasksSelect(event: React.MouseEvent<HTMLAnchorElement>): void {
        const { currentPage } = this.props;
        if (isNil(currentPage) || currentPage === CurrentPage.MyTasks) {
            this.scrollToTop(event);
        }
    }

    @autobind
    private onAccessibleProjectsSelect(event: React.MouseEvent<HTMLAnchorElement>): void {
        if (this.props.currentPage === CurrentPage.AccessibleProjects) {
            this.scrollToTop(event);
        }
    }

    @autobind
    private onMyProjectsSelect(event: React.MouseEvent<HTMLAnchorElement>): void {
        if (this.props.currentPage === CurrentPage.MyProjects) {
            this.scrollToTop(event);
        }
    }

    private scrollToTop(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();
        if (!this.scrollInProgress) {
            this.scrollInProgress = true;

            const pageContent = document.getElementById('pageContent');
            const initialScrollTop: number = pageContent.scrollTop;
            const initialTime: number = window.performance.now();
            const animationTime = 1000;

            const step = (timestamp: number) => {
                const progress = (timestamp - initialTime) / animationTime;
                const diff = initialScrollTop * progress;
                pageContent.scrollTop -= diff;
                if (progress <= 1) {
                    requestAnimationFrame(step);
                } else {
                    this.scrollInProgress = false;
                }
            };

            requestAnimationFrame(step);
        }
    }
}
