import type { CreativeRequestParamDomain } from '../types';
import { Handlers } from '../converters';

interface Subscriber {
    (domain: CreativeRequestParamDomain, params: { handlers: Handlers }): void;
}

export const subscribeCreativeRequestParamDomain: Subscriber = ({ events }, { handlers }) => {
    events.onDivisionUpdated(handlers.onDivisionUpdated);
};
