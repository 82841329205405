import * as React from 'react';

import type { WorkType } from 'sber-marketing-types/frontend';

import { useGetUserCardsQuery, useGetWorkTypesQuery } from '@api';

import { Utils } from '@common/Utils';

import { OptionProps } from '@common/components';
import { UserWorkTypeOption, WorkTypesChip, WorkTypesChipProps } from '@modules/workType/components';

import * as styles from './UserWorkTypeChip.scss';

export interface UserWorkTypeChipProps extends Omit<WorkTypesChipProps<false>, 'selected' | 'onSelect'> {
    selected?: [string, number];
    onSelect?: (workTypeId: string, userId: number) => void;
}

export function UserWorkTypeChip({ selected, onSelect, optionsMap, ...props }: UserWorkTypeChipProps) {
    const typeWorksDropdownDivRef = React.useRef<HTMLDivElement>();
    useGetWorkTypesQuery({ isArchived: false });
    useGetUserCardsQuery({ hasWorkTypes: true });

    const handleSelect = (workTypeId: string, userId: number) => {
        onSelect?.(workTypeId, userId);
    };

    const handleOptionsMap: typeof optionsMap = (options) => {
        const result = options.map((option) => ({
            ...option,
            subtitle:
                option.value?.usersCount !== undefined
                    ? `${option.value.usersCount} ${Utils.getDeclensionByNumber(option.value.usersCount, [
                          'пользователь',
                          'пользователя',
                          'пользователей',
                      ])}`
                    : undefined,
        }));
        return optionsMap?.(result) || result;
    };

    const workTypeOptions: UserWorkTypeChipProps['optionsProps'] = {
        preventSubtitleSearch: true,
    };

    const renderOption = ({ value, ...props }: OptionProps<WorkType.Data>) => {
        return (
            <UserWorkTypeOption
                {...props}
                isLoading={!value}
                onSelect={(userId) => {
                    handleSelect(value.id, userId);
                    props.onSelect?.(value);
                }}
                workType={value}
                portal={typeWorksDropdownDivRef}
                value={selected?.[1]}
            />
        );
    };

    return (
        <WorkTypesChip
            data-qa-id="UserWorkTypeChip"
            {...props}
            optionsAfter={<div className={styles.portal} ref={typeWorksDropdownDivRef} />}
            renderOption={renderOption}
            optionsMap={handleOptionsMap}
            selected={selected?.[0]}
            useUsers
            optionsProps={workTypeOptions}
        />
    );
}
