import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BudgetItemApproverStatus } from '@mrm/budget';
import { WithGlobalPopup, AddaptivePositionCalculator } from 'sber-marketing-ui';

import { ApproverApi } from '@api';

import { StoreState } from '@store';
import { setRejectionCommentPopupVisibility, getBudgetPlanningPageState, getBudgetItems } from '@store/budgetPlanning';
import { getLoginUser } from '@store/user';

import { Utils } from '@common/Utils';
import { AnimatedInProgressIcon } from '@common/AnimatedInProgressIcon';

import { TableLoader } from '../../../../../modules';
import { ApprovementButtons } from '../ApprovementButtons';

import { RejectionCommentPopup } from './RejectionCommentPopup';

import * as styles from '../ApprovementButtons.scss';

interface Props {
    lineId: string;
    lineIsHovered: boolean;
    wrapperRef: React.RefObject<HTMLDivElement>;
    onLineMouseLeave: () => void;
}

function useApiCalls(budgetItemId: string) {
    const tableLoader = TableLoader.getInstance();

    const [isRequestInProgress, setRequestInProgress] = React.useState(false);

    const approveBudgetItem = React.useCallback(async () => {
        setRequestInProgress(true);
        await ApproverApi.approveBudgetItem({ budgetItemId });
        await tableLoader.updateActivitiesByLineIds([budgetItemId]);
        setRequestInProgress(false);
    }, [budgetItemId]);

    const rejectBudgetItem = React.useCallback(
        async (reason: string) => {
            setRequestInProgress(true);
            await ApproverApi.rejectBudgetItem({ budgetItemId, reason });
            await tableLoader.updateActivitiesByLineIds([budgetItemId]);
            setRequestInProgress(false);
        },
        [budgetItemId],
    );

    return {
        isRequestInProgress,
        approveBudgetItem,
        rejectBudgetItem,
    };
}

function useStore(lineId: string) {
    const dispatch = useDispatch();
    const budgetItems = useSelector((state: StoreState) => getBudgetItems(state));

    return useSelector((state: StoreState) => {
        const { showRejectionCommentPopup } = getBudgetPlanningPageState(state);
        const loginnedUserId = getLoginUser(state).attributes.id;

        let userIsApprover = false;
        let status: BudgetItemApproverStatus = null;
        Utils.withErrorHandler<void>(() => {
            const budgetItem = budgetItems.find((budgetItem) => budgetItem.id === lineId);
            const approver = budgetItem.approvers.find((approver) => approver.approver.id === loginnedUserId);

            userIsApprover = !!approver;
            status = approver.status;
        });

        const setPopupVisibility = React.useCallback(
            (isVisible: boolean) => {
                dispatch(setRejectionCommentPopupVisibility(isVisible));
            },
            [dispatch],
        );
        const closeRejectionCommentPopup = React.useCallback(() => {
            setPopupVisibility(false);
        }, []);

        return {
            userIsApprover,
            status,
            showRejectionCommentPopup,
            setRejectionCommentPopupVisibility: setPopupVisibility,
            closeRejectionCommentPopup,
        };
    });
}

function useInteractivity(lineId: string) {
    const {
        userIsApprover,
        status,
        showRejectionCommentPopup,
        setRejectionCommentPopupVisibility,
        closeRejectionCommentPopup,
    } = useStore(lineId);
    const { isRequestInProgress, approveBudgetItem, rejectBudgetItem } = useApiCalls(lineId);

    return {
        userIsApprover,
        status,
        showRejectionCommentPopup,
        setRejectionCommentPopupVisibility,
        closeRejectionCommentPopup,
        isRequestInProgress,
        approveBudgetItem,
        rejectBudgetItem,
    };
}

export function ApprovingApprovementButtons({
    lineId,
    lineIsHovered,
    wrapperRef,
    onLineMouseLeave,
}: Props): JSX.Element {
    const {
        userIsApprover,
        status,
        showRejectionCommentPopup,
        setRejectionCommentPopupVisibility,
        closeRejectionCommentPopup,
        isRequestInProgress,
        approveBudgetItem,
        rejectBudgetItem,
    } = useInteractivity(lineId);

    return userIsApprover ? (
        <div className={styles.root}>
            {isRequestInProgress ? (
                <AnimatedInProgressIcon />
            ) : (
                <React.Fragment>
                    <ApprovementButtons
                        approveButtonTooltip="Принять"
                        rejectButtonTooltip="Отклонить"
                        isApproveButtonActive={status === BudgetItemApproverStatus.Approved}
                        isRejectButtonActive={status === BudgetItemApproverStatus.Rejected}
                        onApproveButtonClick={approveBudgetItem}
                        onRejectButtonClick={() => setRejectionCommentPopupVisibility(!showRejectionCommentPopup)}
                    />

                    {lineIsHovered && showRejectionCommentPopup ? (
                        <WithGlobalPopup
                            container={wrapperRef}
                            positionCalculator={AddaptivePositionCalculator}
                            onMaskClick={() => {
                                closeRejectionCommentPopup();
                                onLineMouseLeave();
                            }}
                        >
                            <div className={styles.rejectionCommentPopup}>
                                <RejectionCommentPopup
                                    closePopup={() => {
                                        closeRejectionCommentPopup();
                                        onLineMouseLeave();
                                    }}
                                    onRejectButtonClick={rejectBudgetItem}
                                />
                            </div>
                        </WithGlobalPopup>
                    ) : null}
                </React.Fragment>
            )}
        </div>
    ) : null;
}
