import { axios } from '../lib/axios';
import {
    NewsFilterResponse,
    GetDashboardActivitiesFiltersParams,
    GetDashboardActivitiesFiltersResponse,
    GetDashboardTasksFiltersParams,
    GetDashboardTasksFiltersResponse,
} from 'sber-marketing-types/backend';

const BASE_URL = '/api/filter';

export class FilterApi {
    public static async getDashboardActivitiesFilters(
        params: GetDashboardActivitiesFiltersParams,
    ): Promise<GetDashboardActivitiesFiltersResponse> {
        const res = await axios.get(`${BASE_URL}/dashboard-activities`, { params });
        return res.data;
    }

    public static async getDashboardTasksFilters(
        params: GetDashboardTasksFiltersParams,
    ): Promise<GetDashboardTasksFiltersResponse> {
        const res = await axios.get(`${BASE_URL}/dashboard-tasks`, { params });
        return res.data;
    }

    public static async getNewsFilter(): Promise<NewsFilterResponse> {
        const res = await axios.get(`${BASE_URL}/news`);
        return res.data;
    }
}
