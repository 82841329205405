import * as React from 'react';

import { Button, Modal, ModalProps, Buttons } from '@common/components';
import { useRemoveTaskParticipantMutation } from '@api';

export interface RemoveTaskParticipantModalProps extends ModalProps {
    taskId: string;
    userId: number;
    onCancel?: () => void;
    onRemove?: () => void;
}

export function RemoveTaskParticipantModal({
    taskId,
    userId,
    onCancel,
    onRemove,
    ...props
}: RemoveTaskParticipantModalProps) {
    const [removeTaskParticipant] = useRemoveTaskParticipantMutation();

    const handleRemove = () => {
        removeTaskParticipant({
            taskId,
            userId,
        });

        onRemove?.();
    };

    return (
        <Modal data-qa-id="RemoveTaskParticipantModal" width={440} title="Удаление участника" vertical {...props}>
            Вы действительно хотите удалить участника?
            <Buttons>
                <Button data-qa-id="RemoveTaskParticipantModal__cancel" flex onClick={onCancel}>
                    Отмена
                </Button>
                <Button data-qa-id="RemoveTaskParticipantModal__remove" flex onClick={handleRemove} view="danger">
                    Да, удалить
                </Button>
            </Buttons>
        </Modal>
    );
}
