import * as React from 'react';
import classNames from 'classnames';

import * as style from './ExpandableColumnHeader.scss';

import { WithTooltip, Icon, IconType } from 'sber-marketing-ui';

interface Props {
    title: string;
    isExpanded: boolean;
    onClick: () => void;
}

export const ExpandableColumnHeader = ({ title, isExpanded, onClick }: Props): JSX.Element => {
    return (
        <div className={classNames(style.root, isExpanded && style.expanded)} onClick={onClick}>
            <div className={style.openerIcon}>
                <Icon type={IconType.ARROW8_DOWN} svgSize={8} />
            </div>

            <WithTooltip content={title}>
                <div className={style.title}>{title}</div>
            </WithTooltip>
        </div>
    );
};
