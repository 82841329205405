import * as React from 'react';
import classnames from 'classnames';
import { WithTooltip } from 'sber-marketing-ui';
import { Tag as TagProps } from '@mrm/tags';

import * as styles from './Tag.scss';

import { TagEmoji } from '@modules/tags/TagEmoji';

interface Props extends TagProps {
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
    onDeleteButtonClick?: () => void;
}

export function Tag({ title, color, emoji, className, style, onClick, onDeleteButtonClick }: Props): JSX.Element {
    const titleRef = React.useRef<HTMLSpanElement>();
    const showTooltip = titleRef.current?.offsetWidth < titleRef.current?.scrollWidth;

    return (
        <div
            className={classnames(
                className,
                styles.root,
                onClick && styles.rootClickable,
                color && styles.rootWithColor,
                emoji && styles.rootWithEmoji,
            )}
            style={{
                ...(style as any),
                background: color || '#ECECEC',
            }}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                if (onClick) {
                    event.preventDefault();
                    event.stopPropagation();

                    onClick();
                }
            }}
            {...{
                'qa-id': 'tag',
                'qa-color': color || 'none',
                'qa-emoji': emoji || 'none',
            }}
        >
            {emoji && (
                <div className={styles.emoji}>
                    <TagEmoji useInTag type={emoji as any} />
                </div>
            )}

            <WithTooltip spanWrapper hidden={!showTooltip} content={title}>
                <span ref={titleRef} className={styles.title}>
                    {title}
                </span>
            </WithTooltip>

            {onDeleteButtonClick && (
                <svg
                    {...{
                        'qa-id': 'tagDeleteButton',
                    }}
                    className={styles.deleteButton}
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        onDeleteButtonClick();
                    }}
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2.1362 0.863702C1.78473 0.51223 1.21488 0.51223 0.863408 0.863702C0.511936 1.21517 0.511936 1.78502 0.863409 2.13649L2.72691 4L0.863213 5.8637C0.511742 6.21517 0.511741 6.78502 0.863213 7.13649C1.21468 7.48797 1.78453 7.48797 2.13601 7.13649L3.99971 5.27279L5.86341 7.13649C6.21488 7.48797 6.78473 7.48797 7.1362 7.13649C7.48767 6.78502 7.48767 6.21517 7.1362 5.8637L5.2725 4L7.13601 2.13649C7.48748 1.78502 7.48748 1.21517 7.13601 0.863702C6.78453 0.51223 6.21468 0.51223 5.86321 0.863702L3.99971 2.72721L2.1362 0.863702Z"
                        fill={color ? '#E3E9E5' : 'black'}
                    />
                </svg>
            )}
        </div>
    );
}
