// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-RejectedCorrectionWarning-_RejectedCorrectionWarning__body{display:flex;width:100%;justify-content:space-between}.src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-RejectedCorrectionWarning-_RejectedCorrectionWarning__accentText{margin:0 4px;font-weight:600}.src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-RejectedCorrectionWarning-_RejectedCorrectionWarning__button{cursor:pointer}.src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-RejectedCorrectionWarning-_RejectedCorrectionWarning__button:hover{color:#4b504d}.src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-RejectedCorrectionWarning-_RejectedCorrectionWarning__button.src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-RejectedCorrectionWarning-_RejectedCorrectionWarning__active:hover{color:#bb780a}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetCorrections/CorrectionsList/CardTypes/common/RejectedCorrectionWarning/RejectedCorrectionWarning.scss"],"names":[],"mappings":"AAAA,oJACI,YAAa,CAEb,UAAW,CAEX,6BAA8B,CACjC,0JAGG,YAAa,CAEb,eAAgB,CACnB,sJAGG,cAAe,CADnB,4JAIQ,aAAc,CAJtB,iTAQQ,aAAc","sourcesContent":[".body {\n    display: flex;\n\n    width: 100%;\n\n    justify-content: space-between;\n}\n\n.accentText {\n    margin: 0 4px;\n\n    font-weight: 600;\n}\n\n.button {\n    cursor: pointer;\n\n    &:hover {\n        color: #4b504d;\n    }\n\n    &.active:hover {\n        color: #bb780a;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-RejectedCorrectionWarning-_RejectedCorrectionWarning__body",
	"accentText": "src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-RejectedCorrectionWarning-_RejectedCorrectionWarning__accentText",
	"button": "src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-RejectedCorrectionWarning-_RejectedCorrectionWarning__button",
	"active": "src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-common-RejectedCorrectionWarning-_RejectedCorrectionWarning__active"
};
export default ___CSS_LOADER_EXPORT___;
