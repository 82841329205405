import * as React from 'react';
import { Skeleton } from 'sber-marketing-ui';

import { Utils } from '@common/Utils';

import * as style from './SuggestTooltip.scss';

export interface SuggestTooltipItem {
    id: string;
    sapComment: string;
    plan: string;
}

interface Props {
    loading: boolean;
    items: SuggestTooltipItem[];
}

export function SuggestTooltip({ loading, items }: Props): JSX.Element {
    return (
        <div className={style.root}>
            {loading ? (
                <Preloader />
            ) : (
                <>
                    <div className={style.title}>
                        В активности создано &nbsp;
                        {items.length}
                        &nbsp;
                        {Utils.getDeclensionByNumber(items.length, ['строка', 'строки', 'строк'])}:
                    </div>

                    {items.map((item) => (
                        <div className={style.item} key={item.id}>
                            <div className={style.property}>
                                Проект:&nbsp;
                                <span className={style.value}>{item.sapComment}</span>
                            </div>

                            <div className={style.property}>
                                Бюджет:&nbsp;
                                <span className={style.value}>{item.plan}</span>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
}

function Preloader(): JSX.Element {
    return (
        <>
            <Skeleton customStyle={{ height: 16, width: 184, borderRadius: 2, marginBottom: 8 }} />
            <Skeleton customStyle={{ height: 16, width: 130, borderRadius: 2, marginBottom: 6 }} />
            <Skeleton customStyle={{ height: 16, width: 106, borderRadius: 2, marginBottom: 10 }} />
        </>
    );
}
