import * as React from 'react';
import * as moment from 'moment';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import { MentionItem, MentionItemStatus, CloseButton } from 'sber-marketing-ui';
import { TaskCommentReaction } from 'sber-marketing-types/frontend';

import * as styles from './TaskComment.scss';

import { StoreState } from '@store';
import { getTaskPageState } from '@store/taskPage/selectors';
import { FileAsset } from '@store/commonTypes';
import { CommentReactionForm } from '@common/CommentReactionForm';

import { Utils } from '@common/Utils';

import { CommentLikeCard } from '../CommentLikeCard';
import { CommentStatic } from '../CommentStatic';
import { CommentEditable } from '../CommentEditable';
import { CommentEditDropdown } from '../CommentEditDropdown';
import { CommentAssetOpenProvider } from '../CommentAssets';

function getCommentsDeclension(num: number): string {
    return Utils.getDeclensionByNumber(num, ['комментарий', 'комментария', 'комментариев']);
}

interface Props {
    id: string;
    taskId: string;
    authorName: string;
    authorDepartment: string;
    isAuthorLoginedUser: boolean;
    channelAuthorId: number;
    channelParticipantIds: number[];
    index: number;
    postedAt: number | string | Date;
    content: string;
    hasAssets: boolean;
    isEditEnabled: boolean;
    isEditable: boolean;
    className?: string;
    displayError: boolean;
    headerHeight: number;
    showPreloader?: boolean;
    channelId: number;
    channelParticipants: {
        userId: number;
        canRemove: boolean;
    }[];
    reactions: TaskCommentReaction[];
    commentChildrenIds: string[];
    commentIdToReplyTo: string;
    onFocus?(): void | Promise<void>;
    onBlur?(): void | Promise<void>;
    onEnableEdit(): void;
    onRemove(): void;
    onReset(): void;
    onChange(message: string): void | Promise<void>;
    onAddAsset(fileData: FileList): void | Promise<void>;
    onSubmit(): void | Promise<void>;
    onAssetRemoveClick(data: FileAsset): void;
    onCloseParentCommentButtonClick(): void;
    onReplyButtonClick(): void;
    toggleCommentReaction(params: { commentId: string; taskId: string }, reaction: string): void;
}

export function TaskComment({
    id,
    taskId,
    authorName,
    authorDepartment,
    isAuthorLoginedUser,
    index,
    postedAt,
    content,
    className,
    isEditEnabled,
    isEditable,
    displayError,
    headerHeight,
    hasAssets,
    channelAuthorId,
    channelParticipantIds,
    channelId,
    channelParticipants,
    showPreloader,
    reactions,
    commentChildrenIds,
    commentIdToReplyTo,
    onChange,
    onAddAsset,
    onSubmit,
    onEnableEdit,
    onRemove,
    onReset,
    onAssetRemoveClick,
    onFocus,
    onBlur,
    onCloseParentCommentButtonClick,
    onReplyButtonClick,
    toggleCommentReaction,
}: Props): JSX.Element {
    const [isHovered, setIsHovered] = React.useState(false);

    const appUsers = useSelector((state: StoreState) => getTaskPageState(state).users);

    const mentionableUsers: MentionItem[] = React.useMemo(() => {
        const channelUserIds = [channelAuthorId, ...channelParticipantIds];

        const usersToUse = appUsers.filter(
            (user) =>
                channelParticipants.some((item) => item.userId == user.id) &&
                (channelId === null || channelUserIds.includes(user.id)),
        );
        const formattedUsers = usersToUse.map((user) => ({
            id: user.id,
            title: `${user.firstName} ${user.secondName}`,
            description: user.department,
            status: user.vacation ? MentionItemStatus.Vacation : null,
        }));

        return sortBy(formattedUsers, (item) => item.title);
    }, [appUsers]);

    const shouldHideComment = content && !content.trim() && !hasAssets;

    const displayComment = !(shouldHideComment && !isEditEnabled);

    return (
        displayComment && (
            <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                <CommentLikeCard
                    className={classNames(
                        styles.root,
                        className,
                        authorName == ' ' && styles.loading,
                        isEditEnabled && styles.commentEditable,
                    )}
                >
                    <div
                        {...{
                            'qa-id': 'taskComment',
                            'qa-index': index,
                        }}
                    >
                        <div className={styles.topLine}>
                            <div>
                                <span
                                    className={styles.author}
                                    {...{
                                        'qa-id': 'taskCommentAuthor',
                                    }}
                                >
                                    {authorName}
                                </span>
                                ,&nbsp;
                                {authorDepartment}&nbsp;
                                {isAuthorLoginedUser ? '(Вы)' : ''}
                            </div>

                            <div className={styles.topLineRightColumn}>
                                <div
                                    {...{
                                        'qa-id': 'taskCommentDate',
                                    }}
                                >
                                    {moment(postedAt).format('D MMM HH:mm')}
                                </div>

                                {isEditable && (
                                    <div className={styles.dropdownWrapper}>
                                        <CommentEditDropdown onEditClick={onEnableEdit} onRemoveClick={onRemove} />
                                    </div>
                                )}

                                {id === commentIdToReplyTo && (
                                    <div className={styles.onCloseParrentCommentButtonClick}>
                                        <CloseButton onClick={onCloseParentCommentButtonClick} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <CommentAssetOpenProvider>
                            {isEditEnabled ? (
                                <CommentEditable
                                    userIsCommentAuthor={isEditable}
                                    useR7Controls
                                    taskId={taskId}
                                    commentId={id}
                                    hideAssetDate
                                    message={content}
                                    hasAssets={hasAssets}
                                    headerHeight={headerHeight}
                                    displayError={displayError}
                                    disableAssetDownload={showPreloader}
                                    errorMessage={displayError ? 'Невозможно сохранить пустой комментарий' : undefined}
                                    mentionableUsers={mentionableUsers}
                                    onChange={onChange}
                                    onAddAsset={onAddAsset}
                                    onSubmit={onSubmit}
                                    onAssetRemoveClick={onAssetRemoveClick}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                />
                            ) : (
                                <CommentStatic
                                    userIsCommentAuthor={isEditable}
                                    taskId={taskId}
                                    commentId={id}
                                    className={styles.content}
                                    content={content}
                                    hasAssets={hasAssets}
                                    disableAssetDownload={showPreloader}
                                    onAssetRemoveClick={onAssetRemoveClick}
                                />
                            )}
                        </CommentAssetOpenProvider>
                        {!showPreloader && (
                            <div className={styles.commentReactionForm}>
                                <CommentReactionForm
                                    canInteract
                                    taskId={taskId}
                                    commentId={id}
                                    reactions={reactions}
                                    addReactionFormIsActive={isHovered}
                                    addReactionFormClassName={styles.commentReactionFormAddReaction}
                                    onReplyButtonClick={!commentIdToReplyTo ? onReplyButtonClick : null}
                                    toggleCommentReaction={toggleCommentReaction}
                                />
                            </div>
                        )}
                    </div>

                    {!!commentChildrenIds.length && (
                        <div className={styles.childrenCommentsButton} onClick={onReplyButtonClick}>
                            {commentChildrenIds.length} {getCommentsDeclension(commentChildrenIds.length)}
                        </div>
                    )}
                </CommentLikeCard>

                <div className={classNames(styles.overlay, isEditEnabled && styles.overlayShow)} onClick={onReset} />
            </div>
        )
    );
}
