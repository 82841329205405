import * as React from 'react';
import { orderBy } from 'lodash';

import { CommentProps, Comment } from './Comment';

import * as styles from './CommentsList.scss';

interface Props {
    isLoading: boolean;
    comments: CommentProps[];
    pendingComments: CommentProps[];
}

export function CommentsList({ isLoading, pendingComments, comments }: Props): JSX.Element {
    const showStub = !(pendingComments.length || comments.length || isLoading);

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'commentsBlockCommentList',
            }}
        >
            {pendingComments.map((pendingComment) => (
                <Comment key={pendingComment.id} pending {...pendingComment} />
            ))}

            {orderBy(comments, (comment) => comment.createTime, ['desc']).map((comment) => (
                <Comment {...comment} key={comment.id} />
            ))}

            {isLoading && <Comment />}

            {showStub && <div className={styles.noCommentsStub}>Комментарии отсутствуют</div>}
        </div>
    );
}
