import * as React from 'react';
import classnames from 'classnames';
import { Icon, IconType } from 'sber-marketing-ui';

import { Utils } from '@common/Utils';

import * as styles from './ExpandFilesButton.scss';

interface Props {
    isExpanded: boolean;
    filesCount: number;
    onClick: () => void;
}

export function ExpandFilesButton({ isExpanded, filesCount, onClick }: Props): JSX.Element {
    return (
        <div
            className={styles.root}
            onClick={onClick}
            {...{
                'qa-id': 'expandFilesButton',
                'qa-is-expanded': isExpanded ? 'true' : 'false',
            }}
        >
            <Icon type={IconType.ATTACHMENT_ICON} svgSize={24} />
            {filesCount} {Utils.getFilesCountDeclension(filesCount)}
            <div className={classnames(styles.expandIcon, isExpanded && styles.expandIconUnactive)}>
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.306019 2.26777C-0.102007 1.86337 -0.102007 1.2077 0.306019 0.803301C0.6849 0.427786 1.28237 0.400963 1.69248 0.722833L1.78361 0.803301L3.9995 3.0005L6.21639 0.803301C6.59527 0.427786 7.19274 0.400963 7.60285 0.722833L7.69398 0.803301C8.07286 1.17882 8.09992 1.77098 7.77517 2.17745L7.69398 2.26777L4.7388 5.1967C4.35992 5.57221 3.76244 5.59904 3.35233 5.27717L3.2612 5.1967L0.306019 2.26777Z"
                        fill="#7E8681"
                    />
                </svg>
            </div>
        </div>
    );
}
