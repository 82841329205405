import * as React from 'react';
import classNames from 'classnames';

import { Tag, TagProps } from '../Tag';

import * as styles from './Chip.scss';

export type ChipProps<E extends keyof JSX.IntrinsicElements = 'div'> = TagProps<E>;

export const Chip: React.FC<ChipProps> = ({ className, children, ...props }) => (
    <Tag {...props} className={classNames(styles.root, className)}>
        {children}
    </Tag>
);
