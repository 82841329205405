import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { UserConfigType } from 'sber-marketing-types/openid';

import { StoreState } from '@store';
import { isRequestInProgress } from '@store/common/selectors';
import { getLoginUser, User } from '@store/user';
import { loadUserConfig } from '@store/userConfig';
import {
    CardType,
    SelectedCard,
    PageData,
    UserRoleFilter,
    CardsLoadingModeFilter,
    getRightSidebarState,
    getUserRoleFilter,
    getCardsLoadingModeFilter,
    loadPageData,
    resetPageStore,
    setCardsLoadingModeFilter,
    setSelectedCard,
    setRequestIdForStatusCheck,
} from '@store/dashboardPage';

interface MapProps {
    preloader: boolean;
    user: User;
    userRoleFilter: UserRoleFilter;
    cardsLoadingModeFilter: CardsLoadingModeFilter;
    requestIdForStatusCheck: string;
}

interface DispatchProps {
    loadUserConfig: () => void;
    resetPageStore: () => void;
    loadPageData: (data: PageData) => void;
    setSelectedCard: (card: SelectedCard) => void;
    setCardsLoadingModeFilter: (filter: CardsLoadingModeFilter) => void;
    setRequestIdForStatusCheck: (requestId: string) => void;
}

interface Props extends Partial<MapProps & DispatchProps> {
    children?: (params: RenderParams) => React.ReactNode | React.ReactNode[];
}

interface RenderParams extends Partial<MapProps & DispatchProps> {}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class WithStore extends React.PureComponent<Props> {
    public render() {
        const {
            preloader,
            user,
            userRoleFilter,
            cardsLoadingModeFilter,
            requestIdForStatusCheck,
            loadUserConfig,
            resetPageStore,
            loadPageData,
            setSelectedCard,
            setRequestIdForStatusCheck,
            setCardsLoadingModeFilter,
        } = this.props;

        return this.props.children({
            preloader,
            user,
            userRoleFilter,
            cardsLoadingModeFilter,
            requestIdForStatusCheck,
            loadUserConfig,
            resetPageStore,
            loadPageData,
            setSelectedCard,
            setRequestIdForStatusCheck,
            setCardsLoadingModeFilter,
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { requestIdForStatusCheck } = getRightSidebarState(state, CardType.BudgetItemToProjectLinkRequest);

    return {
        preloader: isRequestInProgress(state),
        user: getLoginUser(state),
        userRoleFilter: getUserRoleFilter(state),
        cardsLoadingModeFilter: getCardsLoadingModeFilter(state),
        requestIdForStatusCheck,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return {
        loadUserConfig: () => dispatch(loadUserConfig(UserConfigType.Dashboard)),
        resetPageStore: () => dispatch(resetPageStore()),
        loadPageData: (data: PageData) => dispatch(loadPageData(data)),
        setSelectedCard: (card: SelectedCard) => dispatch(setSelectedCard(card)),
        setRequestIdForStatusCheck: (requestId: string) => dispatch(setRequestIdForStatusCheck(requestId)),
        setCardsLoadingModeFilter: (filter: CardsLoadingModeFilter) => dispatch(setCardsLoadingModeFilter(filter)),
    };
}
