import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AbsoluteLikePositionCalculator, Icon, IconType, WithGlobalPopup } from 'sber-marketing-ui';

import { getAutopilots } from '@store/activityPage/selectors';
import { Autopilots } from '@modules/autopilot/types';
import { AutopilotStatus } from '@modules/activity/AutopilotStatus';
import { autopilotNames } from '@modules/autopilot/constants';

import { ConfirmModal } from '../ConfirmModal';

import * as style from './AutopilotPopup.scss';

export interface AutopilotPopupProps {
    container: React.RefObject<HTMLElement>;
    activityId: number;
    onAdd: (id: Autopilots) => void;
    onMaskClick: (event: MouseEvent) => void;
}

export const AutopilotPopup: React.FC<AutopilotPopupProps> = ({ container, activityId, onAdd, onMaskClick }) => {
    const history = useHistory();
    const [modal, setModal] = React.useState<Autopilots | ''>('');
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const autopilots = useSelector(getAutopilots);
    const modalData = modal && autopilotNames.find(({ id }) => id === modal);
    const addItems = autopilotNames.filter(({ id }) => !autopilots[id]);
    const addedItems = autopilotNames.filter(({ id }) => autopilots[id]);

    const hideModal = () => setModal('');

    const handleAdd = (id: Autopilots) => () => setModal(id);
    const handleOpen = (id: Autopilots) => () => history.push(`/activity/${activityId}/${id}`);
    const handleConfirm = async () => {
        if (!modal) return;
        setLoading(true);

        await onAdd(modal);

        setLoading(false);
        hideModal();
    };

    const addedItemsElement = addedItems.length ? (
        <>
            <div className={style.title}>Созданные автопилоты</div>
            {addedItems.map(({ id, name }) => (
                <div key={id} className={style.item} onClick={handleOpen(id)}>
                    <AutopilotStatus autopilot={autopilots[id]} />
                    {name}
                </div>
            ))}
        </>
    ) : null;

    const addItemsElement = addItems.length ? (
        <>
            <div className={style.title}>Создать автопилот</div>
            {addItems.map(({ id, name }) => (
                <div key={id} className={style.item} onClick={handleAdd(id)}>
                    <Icon className={style.icon} svgSize={12} type={IconType.PLUS_IN_CIRCLE} />
                    {name}
                </div>
            ))}
        </>
    ) : null;

    return (
        <WithGlobalPopup
            positionCalculator={AbsoluteLikePositionCalculator}
            container={container}
            bottom={-4}
            left={-10}
            zIndex={45}
            onMaskClick={onMaskClick}
        >
            <div className={style.root}>
                {addedItemsElement}
                {addItemsElement}
                {modal && (
                    <ConfirmModal
                        title="Внимание!"
                        message={`Будет создан автопилот «${modalData.name}»`}
                        confirmButtonTitle="Подтвердить"
                        closeButtonTitle="Отменить"
                        isLoading={isLoading}
                        onConfirm={handleConfirm}
                        onClose={hideModal}
                    />
                )}
            </div>
        </WithGlobalPopup>
    );
};
