// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-task-pages-TaskPage-components-TaskPageAside-_TaskPageAside__root{width:410px;background:#FFFFFF;box-shadow:0 4px 6px 0 #0000001A}.src-client-modules-task-pages-TaskPage-components-TaskPageAside-_TaskPageAside__header{border-bottom:1px solid #E3E9E5}.src-client-modules-task-pages-TaskPage-components-TaskPageAside-_TaskPageAside__content{display:flex;flex-direction:column;align-items:stretch;flex:1;min-height:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/task/pages/TaskPage/components/TaskPageAside/TaskPageAside.scss"],"names":[],"mappings":"AAAA,sFACE,WAAY,CACZ,kBAAmB,CACnB,gCAAiC,CAClC,wFAGC,+BAAgC,CACjC,yFAGC,YAAa,CACb,qBAAsB,CACtB,mBAAoB,CACpB,MAAO,CACP,YAAa","sourcesContent":[".root {\n  width: 410px;\n  background: #FFFFFF;\n  box-shadow: 0 4px 6px 0 #0000001A;\n}\n\n.header {\n  border-bottom: 1px solid #E3E9E5;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  flex: 1;\n  min-height: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-task-pages-TaskPage-components-TaskPageAside-_TaskPageAside__root",
	"header": "src-client-modules-task-pages-TaskPage-components-TaskPageAside-_TaskPageAside__header",
	"content": "src-client-modules-task-pages-TaskPage-components-TaskPageAside-_TaskPageAside__content"
};
export default ___CSS_LOADER_EXPORT___;
