// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-CreativeStatusesStageWidget-Table-_Table__root{display:block}.src-client-modules-activity-pages-autopilot-StageWidgets-CreativeStatusesStageWidget-Table-_Table__group{margin-bottom:20px}.src-client-modules-activity-pages-autopilot-StageWidgets-CreativeStatusesStageWidget-Table-_Table__group:last-child{margin-bottom:0}.src-client-modules-activity-pages-autopilot-StageWidgets-CreativeStatusesStageWidget-Table-_Table__groupName{color:#1f9a48;font-weight:700;font-family:'Open Sans';font-size:14px;line-height:19px}.src-client-modules-activity-pages-autopilot-StageWidgets-CreativeStatusesStageWidget-Table-_Table__table{margin-top:8px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/CreativeStatusesStageWidget/Table/Table.scss"],"names":[],"mappings":"AAAA,yGACI,aAAc,CACjB,0GAGG,kBAAmB,CADvB,qHAIQ,eAAgB,CACnB,8GAID,aAAc,CACd,eAAgB,CAChB,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACpB,0GAGG,cAAe","sourcesContent":[".root {\n    display: block;\n}\n\n.group {\n    margin-bottom: 20px;\n\n    &:last-child {\n        margin-bottom: 0;\n    }\n}\n\n.groupName {\n    color: #1f9a48;\n    font-weight: 700;\n    font-family: 'Open Sans';\n    font-size: 14px;\n    line-height: 19px;\n}\n\n.table {\n    margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-CreativeStatusesStageWidget-Table-_Table__root",
	"group": "src-client-modules-activity-pages-autopilot-StageWidgets-CreativeStatusesStageWidget-Table-_Table__group",
	"groupName": "src-client-modules-activity-pages-autopilot-StageWidgets-CreativeStatusesStageWidget-Table-_Table__groupName",
	"table": "src-client-modules-activity-pages-autopilot-StageWidgets-CreativeStatusesStageWidget-Table-_Table__table"
};
export default ___CSS_LOADER_EXPORT___;
