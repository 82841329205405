import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { BudgetItemApprover, BudgetItemApproverStatus } from '@mrm/budget';
import { Icon, IconType, WithTooltip, TooltipAnchor } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getBudgetItems } from '@store/budgetPlanning';

import { Utils } from '@common/Utils';

import * as styles from './ApproversPopup.scss';

interface StoreProps {
    approvers: BudgetItemApprover[];
}

interface Props {
    lineId: string;
    closePopup: () => void;
}

function useStore(lineId: string): StoreProps {
    const budgetItems = useSelector((state: StoreState) => getBudgetItems(state));

    return useSelector((state: StoreState) => {
        const approvers =
            Utils.withErrorHandler<BudgetItemApprover[]>(
                () => budgetItems.find((budgetItem) => budgetItem.id === lineId).approvers,
            ) || [];

        return {
            approvers,
        };
    });
}

export function ApproversPopup({ lineId, closePopup }: Props): JSX.Element {
    const { approvers } = useStore(lineId);

    return (
        <div
            className={styles.root}
            onMouseLeave={closePopup}
            {...{
                'qa-id': 'budgetPlanningIndicatorsCellApproversPopup',
            }}
        >
            {approvers.map((approver) => (
                <Approver key={approver.approver.id} {...approver} />
            ))}
        </div>
    );
}

function Approver({ approver, ...approverProps }: BudgetItemApprover): JSX.Element {
    return (
        <div
            className={styles.approver}
            {...{
                'qa-id': 'budgetPlanningIndicatorsCellApproversPopupApprover',
            }}
        >
            <div className={styles.approverName}>
                {approver.firstName} {approver.secondName}
            </div>

            <div className={styles.approverDepartment}>{approver.departmentName}</div>

            <ApproverButtons {...approverProps} />
        </div>
    );
}

type ApproverButtonsProps = Pick<BudgetItemApprover, 'status' | 'reason'>;

function ApproverButtons({ status, reason }: ApproverButtonsProps): JSX.Element {
    const [isMouseOverReasonIcon, setIsMouseOverReasonIcon] = React.useState(false);

    let content: JSX.Element;

    switch (status) {
        case BudgetItemApproverStatus.Approved:
            content = <Icon type={IconType.ACCEPT_ICON_ROUND_ACTIVE} svgSize={16} />;
            break;
        case BudgetItemApproverStatus.Rejected:
            content = (
                <React.Fragment>
                    <Icon
                        type={IconType.REJECT_ICON_ROUND_ACTIVE}
                        svgSize={16}
                        className={styles.approverRejectedIcon}
                    />

                    <WithTooltip
                        anchor={TooltipAnchor.LEFT}
                        content={
                            <span className={styles.approverReason}>
                                Причина отклонения: <span className={styles.approverReasonBold}>{reason}</span>
                            </span>
                        }
                    >
                        <div
                            className={classNames(styles.reasonIcon, isMouseOverReasonIcon && styles.reasonIconBlack)}
                            {...{
                                'qa-id': 'budgetPlanningIndicatorsCellApproverButton',
                            }}
                        >
                            <Icon
                                type={IconType.COMMENT}
                                svgSize={16}
                                onMouseEnter={() => setIsMouseOverReasonIcon(true)}
                            />
                        </div>
                    </WithTooltip>
                </React.Fragment>
            );
            break;
        default:
            content = <span className={styles.approverWaitingText}>-</span>;
            break;
    }

    return <div className={styles.approverButtons}>{content}</div>;
}
