import * as React from 'react';
import * as style from './Title.scss';

export interface Props {
    text: string;
}

export const Title: React.FC<Props> = ({ text }) => {
    return <div className={style.root}>{text}</div>;
};
