import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';
import * as style from './Indicators.scss';

interface IndicatorProps {
    enableControls?: boolean;
    onRevertClick?: () => void;
}

const enum ColorMap {
    GREY = 'grey',
    GREEN = 'green',
    RED = 'red',
}

const colorStyleMap = {
    [ColorMap.GREY]: style.grey,
    [ColorMap.GREEN]: style.green,
    [ColorMap.RED]: style.red,
};

const EllipsesIcon: React.FC = () => {
    return (
        <div className={style.iconWrapper}>
            <Icon type={IconType.CIRCLE_BOLD_ELLIPSES} svgSize={16} />
        </div>
    );
};

const CrossIcon: React.FC = () => {
    return (
        <div className={style.iconWrapper}>
            <Icon type={IconType.CIRCLE_BOLD_CROSS} svgSize={16} />
        </div>
    );
};

const CheckIcon: React.FC = () => {
    return (
        <div className={style.iconWrapper}>
            <Icon type={IconType.CIRCLE_BOLD_CHECK} svgSize={16} />
        </div>
    );
};

const IndicatorBody: React.FC<{ color: ColorMap }> = ({ children, color }) => {
    return (
        <div
            className={classNames(style.indicatorBody, colorStyleMap[color])}
            {...{
                'qa-id': 'budgetPlanningLineModalBudgetItemStatusIndicator',
            }}
        >
            {children}
        </div>
    );
};

export const ApproveInProgressIndicator: React.FC<IndicatorProps> = () => {
    return (
        <IndicatorBody color={ColorMap.GREY}>
            <EllipsesIcon />
            Строка планирования согласуется
        </IndicatorBody>
    );
};

export const ApprovedIndicator: React.FC<IndicatorProps> = () => {
    return (
        <IndicatorBody color={ColorMap.GREEN}>
            <CheckIcon />
            Строка планирования подтверждена
        </IndicatorBody>
    );
};

export const RejectedIndicator: React.FC<IndicatorProps> = () => {
    return (
        <IndicatorBody color={ColorMap.RED}>
            <CrossIcon />
            Строка планирования отклонена
        </IndicatorBody>
    );
};
