import * as React from 'react';
import autobind from 'autobind-decorator';
import { CorrectionType } from '@mrm/budget';

import { WithCoordinator } from '../../../withCoordinator';
import * as style from './Body.scss';

interface Props {
    qaId: string;
    qaCorrectionType: CorrectionType;
    tooltipComponent: React.ReactNode;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}

interface State {
    isDisplayedTooltip: boolean;
}

export class Body extends React.Component<Props, State> {
    private cardRef: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: Props) {
        super(props);

        this.state = {
            isDisplayedTooltip: false,
        };
    }

    public render() {
        return (
            <div
                className={style.root}
                ref={this.cardRef}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                {...{
                    'qa-id': this.props.qaId,
                    'qa-correction-type': this.props.qaCorrectionType,
                }}
            >
                {this.props.children}

                {this.state.isDisplayedTooltip && (
                    <WithCoordinator parentRef={this.cardRef}>
                        {({ childrenRef }) => {
                            return (
                                <div ref={childrenRef} className={style.externalBlock}>
                                    {this.props.tooltipComponent}
                                </div>
                            );
                        }}
                    </WithCoordinator>
                )}
            </div>
        );
    }

    @autobind
    private onMouseEnter(): void {
        this.props.onMouseEnter();
        this.setState({ isDisplayedTooltip: true });
    }

    @autobind
    private onMouseLeave(): void {
        this.props.onMouseLeave();
        this.setState({ isDisplayedTooltip: false });
    }
}
