import * as React from 'react';
import { graphql, compose } from 'react-apollo';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { RequestsQueryProps, RequestStatus } from '../types';

import * as graphqlQueries from './graphqlQueries';

interface Props extends QueryProps, ExternalProps {}

interface ExternalProps {
    activityId: number;
    requestId: string;
    children?: (params: RenderParams) => React.ReactNode | React.ReactNode[];
}

type MutationFunc<V> = (params: { variables: V }) => Promise<void>;
type MutationResult = {
    loading: boolean;
};

interface QueryProps extends RequestsQueryProps {
    approveRequestMutation: MutationFunc<{ requestId: string }>;
    approveRequestMutationResult: MutationResult;
    rejectRequestMutation: MutationFunc<{ requestId: string }>;
    rejectRequestMutationResult: MutationResult;
    revertRequestMutation: MutationFunc<{ requestId: string }>;
    revertRequestMutationResult: MutationResult;
}

interface RenderParams {
    requestStatus: RequestStatus;
    approveRequest: () => Promise<void>;
    rejectRequest: () => Promise<void>;
    revertRequest: () => Promise<void>;
}

class RequestsQuery extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render() {
        const requestStatus =
            this.props.data.requests && !lodash.isEmpty(this.props.data.requests.nodes)
                ? lodash.first(this.props.data.requests.nodes).status
                : null;

        return this.props.children({
            requestStatus,
            approveRequest: this.approveRequest,
            rejectRequest: this.rejectRequest,
            revertRequest: this.revertRequest,
        });
    }

    @autobind
    private async updateRequest(): Promise<void> {
        this.props.data.refetch({
            limit: 1,
            filter: {
                id: this.props.requestId,
            },
        });
    }

    @autobind
    private async approveRequest(): Promise<void> {
        await this.permormRequestMutation(this.props.approveRequestMutation);
    }

    @autobind
    private async rejectRequest(): Promise<void> {
        await this.permormRequestMutation(this.props.rejectRequestMutation);
    }

    @autobind
    private async revertRequest(): Promise<void> {
        await this.permormRequestMutation(this.props.revertRequestMutation);
    }

    @autobind
    private async permormRequestMutation(mutation: MutationFunc<{ requestId: string }>): Promise<void> {
        await mutation({
            variables: {
                requestId: this.props.requestId,
            },
        });

        await this.updateRequest();
    }
}

export const WithRequestsQuery = compose(
    graphql<ExternalProps>(graphqlQueries.GET_REQUESTS_QUERY, {
        options: ({ requestId }) => ({
            variables: {
                limit: 1,
                filter: {
                    id: requestId,
                },
            },
            fetchPolicy: 'no-cache',
        }),
    }),
    graphql<ExternalProps>(graphqlQueries.APPROVE_REQUEST_MUTATION, { name: 'approveRequestMutation' }),
    graphql<ExternalProps>(graphqlQueries.REJECT_REQUEST_MUTATION, { name: 'rejectRequestMutation' }),
    graphql<ExternalProps>(graphqlQueries.REVERT_REQUEST_MUTATION, { name: 'revertRequestMutation' }),
)(RequestsQuery) as React.ComponentType<ExternalProps>;
