// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-tmRegistry-_TmRegistryPage__root{width:100%;padding:8px 16px 12px 64px;font-family:'Open Sans', sans-serif}.src-client-modules-tmRegistry-_TmRegistryPage__headerTitle{display:flex;height:48px;letter-spacing:0.15px;color:#000000;font-family:\"Open Sans\";font-size:16px;line-height:16px;align-items:center;justify-content:center}.src-client-modules-tmRegistry-_TmRegistryPage__quickAccessIcons{display:flex;height:48px;justify-content:flex-end}\n", "",{"version":3,"sources":["webpack://./src/client/modules/tmRegistry/TmRegistryPage.scss"],"names":[],"mappings":"AAAA,qDACI,UAAW,CACX,0BAA2B,CAE3B,mCAAoC,CACvC,4DAGG,YAAa,CAEb,WAAY,CAEZ,qBAAsB,CAEtB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CAEjB,kBAAmB,CACnB,sBAAuB,CAC1B,iEAGG,YAAa,CAEb,WAAY,CAEZ,wBAAyB","sourcesContent":[".root {\n    width: 100%;\n    padding: 8px 16px 12px 64px;\n\n    font-family: 'Open Sans', sans-serif;\n}\n\n.headerTitle {\n    display: flex;\n\n    height: 48px;\n\n    letter-spacing: 0.15px;\n\n    color: #000000;\n\n    font-family: \"Open Sans\";\n    font-size: 16px;\n    line-height: 16px;\n\n    align-items: center;\n    justify-content: center;\n}\n\n.quickAccessIcons {\n    display: flex;\n\n    height: 48px;\n\n    justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-tmRegistry-_TmRegistryPage__root",
	"headerTitle": "src-client-modules-tmRegistry-_TmRegistryPage__headerTitle",
	"quickAccessIcons": "src-client-modules-tmRegistry-_TmRegistryPage__quickAccessIcons"
};
export default ___CSS_LOADER_EXPORT___;
