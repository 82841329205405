import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { NewsView } from 'sber-marketing-types/frontend';
import { uniq } from 'lodash';

import { NewsApi, FilterApi, UserApi } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getLoginUser } from '@store/user';
import * as divisionsStore from '@store/divisions';
import * as activityTypesStore from '@store/activityTypes';
import * as usersStore from '@store/users';

import { getNewsList, getNewsState } from './selectors';
import { GetNewsParams } from './types';
import {
    pushNews,
    setCanBeLoadedMore,
    fetchMore as fetchMoreAction,
    saveFilters,
    loadFiltersAsync,
    setFiltersLoadingStatus,
} from './actions';

const divisionsEntities = divisionsStore.StoreTypes.NEWS_FILTER;
const activityTypesEntities = activityTypesStore.StoreTypes.NEWS_FILTER;
const usersEntities = usersStore.StoreTypes.NEWS_FILTER;

const NEWS_LIMIT = 10;

export const loadFilters = bindThunkAction<StoreState, null, void, Error>(
    loadFiltersAsync,
    async (_, dispatch, getState) => {
        if (getNewsState(getState()).filtersLoadingStatus === LoadingStatus.NOT_LOADED) {
            dispatch(setFiltersLoadingStatus(LoadingStatus.LOADING));

            const [filters, users] = await Promise.all([
                FilterApi.getNewsFilter(),
                UserApi.getUserCardListFiltered({}),
            ]);

            dispatch(
                saveFilters({
                    filters: filters.filters,
                    users,
                }),
            );

            dispatch(setFiltersLoadingStatus(LoadingStatus.LOADED));
        }
    },
);

/** Fetch more news */
export const fetchMore = bindThunkAction<StoreState, GetNewsParams, void, Error>(
    fetchMoreAction,
    async ({ wasScrolledToBottom, ...params }, dispatch, getState) => {
        function idsGenerator(differenceNames: string[]) {
            return (acc: (string | number)[], newsItem: NewsView): (string | number)[] => {
                const additionalItems: string[] = [];

                differenceNames.forEach((differenceName) => {
                    if (newsItem.difference && newsItem.difference[differenceName]) {
                        if (newsItem.difference[differenceName].before) {
                            additionalItems.push(newsItem.difference[differenceName].before);
                        }
                        if (newsItem.difference[differenceName].after) {
                            additionalItems.push(newsItem.difference[differenceName].after);
                        }
                    }
                });

                return [...acc, ...additionalItems];
            };
        }

        const news: NewsView[] = await NewsApi.getNews({
            limit: NEWS_LIMIT,
            offset: wasScrolledToBottom ? getNewsList(getState()).length : 0,
            ...params,
        });

        const divisionIds = uniq(news.reduce(idsGenerator(['divisionId']), [])) as string[];
        const activityTypeIds = uniq(news.reduce(idsGenerator(['typeId']), [])) as string[];
        const userIds = uniq(news.reduce(idsGenerator(['responsibleId', 'executor']), [])) as number[];

        dispatch(
            divisionsStore.loadDivisions({
                store: divisionsEntities,
                ids: divisionIds,
            }),
        );
        dispatch(
            activityTypesStore.loadActivityTypes({
                store: activityTypesEntities,
                ids: activityTypeIds,
            }),
        );
        dispatch(
            usersStore.loadUsers({
                store: usersEntities,
                ids: userIds,
            }),
        );

        const loginedUserId = getLoginUser(getState()).attributes.id;

        dispatch(setCanBeLoadedMore(news.length >= NEWS_LIMIT));
        dispatch(
            pushNews({
                news,
                loginedUserId,
                onlyUnseenNews: params.unseen,
                preserveEntities: wasScrolledToBottom,
            }),
        );
    },
);

export const updateNews = bindThunkAction<StoreState, GetNewsParams, void, Error>(
    fetchMoreAction,
    async ({ wasScrolledToBottom, ...params }, dispatch, getState) => {
        const news: NewsView[] = await NewsApi.getNews({
            limit: getNewsList(getState()).length,
            offset: 0,
            ...params,
        });

        const loginedUserId = getLoginUser(getState()).attributes.id;

        dispatch(
            pushNews({
                news,
                loginedUserId,
                onlyUnseenNews: params.unseen,
                preserveEntities: false,
            }),
        );
    },
);
