// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-tags-TagsPage-Filters-common-FilterItem-_FilterItem__root{box-shadow:0px 1px 4px rgba(0,0,0,0.1);border-radius:4px;display:flex;align-items:center;padding:4px 8px;font-family:'Open Sans';font-weight:400;font-size:12px;line-height:16px;letter-spacing:0.15px;color:#000000;background:white}.src-client-modules-tags-TagsPage-Filters-common-FilterItem-_FilterItem__removeItemButton{margin-left:7px;color:#979797;cursor:pointer}\n", "",{"version":3,"sources":["webpack://./src/client/modules/tags/TagsPage/Filters/common/FilterItem/FilterItem.scss"],"names":[],"mappings":"AAAA,8EACI,sCAA0C,CAC1C,iBAAkB,CAElB,YAAa,CACb,kBAAmB,CAEnB,eAAgB,CAEhB,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,qBAAsB,CACtB,aAAc,CAEd,gBAAiB,CACpB,0FAGG,eAAgB,CAEhB,aAAc,CAEd,cAAe","sourcesContent":[".root {\n    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);\n    border-radius: 4px;\n\n    display: flex;\n    align-items: center;\n\n    padding: 4px 8px;\n\n    font-family: 'Open Sans';\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 16px;\n    letter-spacing: 0.15px;\n    color: #000000;\n\n    background: white;\n}\n\n.removeItemButton {\n    margin-left: 7px;\n\n    color: #979797;\n\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-tags-TagsPage-Filters-common-FilterItem-_FilterItem__root",
	"removeItemButton": "src-client-modules-tags-TagsPage-Filters-common-FilterItem-_FilterItem__removeItemButton"
};
export default ___CSS_LOADER_EXPORT___;
