import * as React from 'react';
import autobind from 'autobind-decorator';
import { MentionItem } from 'sber-marketing-ui';

import { FileInput } from '@common/FileInput';

import { CommentInputTemplate } from './CommentInputTemplate';
import { CommentInputLexicalTemplate } from './CommentInputLexicalTemplate';

interface Props {
    mentionableUsers: MentionItem[];
    uploadedFiles: File[];
    isFileUploadInProgress: boolean;
    isRequestInProgress: boolean;
    useLexicalTemplate?: boolean;
    disabled?: boolean;
    createComment: (text: string, files: File[]) => Promise<void>;
}

interface State {
    text: string;
    files: File[];
    isFocused: boolean;
    isWrapperFocused: boolean;
}

export class CommentInputBehaviour extends React.PureComponent<Props, State> {
    private fileInputRef: React.RefObject<FileInput>;

    public constructor(props: Props) {
        super(props);

        this.fileInputRef = React.createRef<FileInput>();

        this.state = this.getDefaultState();
    }

    public render(): JSX.Element {
        const { text, isWrapperFocused, files } = this.state;
        const {
            disabled,
            mentionableUsers,
            uploadedFiles,
            isFileUploadInProgress,
            isRequestInProgress,
            useLexicalTemplate,
        } = this.props;

        const Template = useLexicalTemplate ? CommentInputLexicalTemplate : CommentInputTemplate;

        return (
            <Template
                text={text}
                files={files}
                disabled={disabled}
                isFocused={isWrapperFocused}
                mentionableUsers={mentionableUsers}
                uploadedFiles={uploadedFiles}
                isFileUploadInProgress={isFileUploadInProgress}
                isRequestInProgress={isRequestInProgress}
                fileInputRef={this.fileInputRef}
                onWrapperMouseOver={this.setInputWrapperFocused}
                onWrapperMouseOut={this.unsetInputWrapperFocused}
                onInputChange={this.onInputChange}
                onInputFocus={this.setInputFocused}
                onInputBlur={this.unsetInputFocused}
                onAttachmentIconClick={this.onAttachmentIconClick}
                onAttachmentIconMouseOver={this.setInputWrapperFocused}
                onAttachmentIconMouseOut={this.unsetInputWrapperFocused}
                onSendIconClick={this.onSendIconClick}
                onSendIconMouseOver={this.setInputWrapperFocused}
                onSendIconMouseOut={this.unsetInputWrapperFocused}
                onFileInput={this.onFileInput}
                deleteFile={this.deleteFile}
            />
        );
    }

    public resetState() {
        this.setState(this.getDefaultState());
    }

    @autobind
    private onInputChange(text: string): void {
        this.setState({
            text,
        });
    }

    @autobind
    private setInputFocused(): void {
        this.setState({
            isFocused: true,
        });
    }

    @autobind
    private unsetInputFocused(): void {
        this.setState({
            isFocused: false,
            isWrapperFocused: false,
        });
    }

    @autobind
    private setInputWrapperFocused(): void {
        this.setState({
            isWrapperFocused: true,
        });
    }

    @autobind
    private unsetInputWrapperFocused(): void {
        if (!this.state.isFocused) {
            this.setState({
                isWrapperFocused: false,
            });
        }
    }

    @autobind
    private onAttachmentIconClick(): void {
        if (this.fileInputRef.current) {
            this.fileInputRef.current.triggerOpen();
        }
    }

    @autobind
    private async onSendIconClick(): Promise<void> {
        const { text, files } = this.state;
        const comment = text.trim();

        if (comment || files.length) {
            await this.props.createComment(comment, files);
        }
    }

    @autobind
    private onFileInput(files: File[]): void {
        this.setState((state) => ({
            files: [...state.files, ...files],
        }));
    }

    @autobind
    private deleteFile(file: File): void {
        this.setState((state) => ({
            files: state.files.filter((stateFile) => stateFile !== file),
        }));
    }

    private getDefaultState(): State {
        return {
            text: '',
            files: [],
            isFocused: false,
            isWrapperFocused: false,
        };
    }
}
