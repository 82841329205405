import createCashedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { compact, uniq, sortBy, groupBy, values, orderBy } from 'lodash';

import { StoreState } from '../../';
import { TaskPageState, Commentary, Channel } from '../types';
import { getTaskPageState } from './lib';
import { getChannelById } from './channelsSelectors';

const sortCommentaries = (commentaries: Commentary[]): Commentary[] => sortBy(commentaries, 'createdAt').reverse();

export const getCommentaryById = createSelector(
    getTaskPageState,
    (state: StoreState, id: string): string => id,
    ({ comments }: TaskPageState, id): Commentary => comments[id],
);

export const getSendingComment = createSelector(getTaskPageState, (state) => state.sendingComment);
export const getSavingComment = createSelector(getTaskPageState, (state) => state.savingComment);

export const getCommentariesByIds = createSelector(
    (state: StoreState) => state,
    (_: StoreState, ids: string[]): string[] => uniq(ids),
    (state, ids) => compact(ids.map((id) => getCommentaryById(state, id))),
);

export const getCommentaries = createSelector(
    (state: StoreState) => state,
    getChannelById,
    (state, channel: Channel | undefined): Commentary[] => {
        let result: Commentary[] = [];
        if (channel) {
            result = sortCommentaries(getCommentariesByIds(state, channel.commentsIds));
        }
        return result;
    },
);

export const getCommentariesSortedIds = createSelector(getCommentaries, (comments): string[] => {
    const groupedComments = groupBy(comments, 'isLoading');

    return [...sortBy(groupedComments.true, 'createTime'), ...sortBy(groupedComments.false, 'createTime')]
        .filter((comment) => !comment.replyId)
        .map((comment) => comment.id);
});

export const getNewCommentaryState = createSelector(
    getTaskPageState,
    ({ newCommentary }: TaskPageState): Commentary => newCommentary,
);

export const getEditingComment = createSelector(
    getTaskPageState,
    ({ editingComment }: TaskPageState): Commentary | symbol | null => editingComment,
);

export const getCommentChildrenIds = createCashedSelector(
    getTaskPageState,
    (state: StoreState, commentId: string) => commentId,
    (state, commentId) => {
        const comments: Commentary[] = values(state.comments).filter((comment) => comment.replyId === commentId);

        return orderBy(comments, 'createdAt', 'desc').map((comment) => comment.id);
    },
)((state: StoreState, commentId: string) => commentId);
