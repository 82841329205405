import * as React from 'react';

import { Color, Direction, Result } from '../../../../common';
import { Period, StatisticsActivityType } from '../../useState';
import { getPeriodName } from '../getPeriodName';

import { BuildParams } from '../types';

import {
    ActiveActivityTitle,
    OverdueStagesTitle,
    OverdueTasksTitle,
    PlannedStartTitle,
} from '../../../../common/TitleGroup';

export const buildActivityParams: BuildParams<'activities'> = ({
    selectedStatisticsItem,
    selectedPeriod,
    statisticsTypeFilters,
    setStatisticsTypeFilter,
}) => [
    buildData({
        result:
            selectedStatisticsItem.statistics.current.withOverdueTasksCount ===
            selectedStatisticsItem.statistics.past.withOverdueTasksCount
                ? Result.NEUTRAL
                : selectedStatisticsItem.statistics.current.withOverdueTasksCount >
                  selectedStatisticsItem.statistics.past.withOverdueTasksCount
                ? Result.NEGATIVE
                : Result.POSITIVE,
        period: selectedPeriod,
        currentCount: selectedStatisticsItem.statistics.current.withOverdueTasksCount,
        pastCount: selectedStatisticsItem.statistics.past.withOverdueTasksCount,
        color: Color.RED,
        titleComponent: <OverdueTasksTitle color={Color.RED} />,
        darken: !statisticsTypeFilters[StatisticsActivityType.OVERDUE_TASKS],
        onClick: () =>
            setStatisticsTypeFilter(
                StatisticsActivityType.OVERDUE_TASKS,
                !statisticsTypeFilters[StatisticsActivityType.OVERDUE_TASKS],
            ),
    }),
    buildData({
        result:
            selectedStatisticsItem.statistics.current.withOverdueStagesCount ===
            selectedStatisticsItem.statistics.past.withOverdueStagesCount
                ? Result.NEUTRAL
                : selectedStatisticsItem.statistics.current.withOverdueStagesCount >
                  selectedStatisticsItem.statistics.past.withOverdueStagesCount
                ? Result.NEGATIVE
                : Result.POSITIVE,
        period: selectedPeriod,
        currentCount: selectedStatisticsItem.statistics.current.withOverdueStagesCount,
        pastCount: selectedStatisticsItem.statistics.past.withOverdueStagesCount,
        color: Color.MUSTARD,
        titleComponent: <OverdueStagesTitle color={Color.MUSTARD} />,
        darken: !statisticsTypeFilters[StatisticsActivityType.OVERDUE_STAGES],
        onClick: () =>
            setStatisticsTypeFilter(
                StatisticsActivityType.OVERDUE_STAGES,
                !statisticsTypeFilters[StatisticsActivityType.OVERDUE_STAGES],
            ),
    }),
    buildData({
        result:
            selectedStatisticsItem.statistics.current.activeCount === selectedStatisticsItem.statistics.past.activeCount
                ? Result.NEUTRAL
                : selectedStatisticsItem.statistics.current.activeCount ===
                  selectedStatisticsItem.statistics.past.activeCount
                ? Result.NEGATIVE
                : Result.POSITIVE,
        period: selectedPeriod,
        currentCount: selectedStatisticsItem.statistics.current.activeCount,
        pastCount: selectedStatisticsItem.statistics.past.activeCount,
        color: Color.GREEN,
        titleComponent: <ActiveActivityTitle color={Color.GREEN} />,
        darken: !statisticsTypeFilters[StatisticsActivityType.ACTIVE],
        onClick: () =>
            setStatisticsTypeFilter(
                StatisticsActivityType.ACTIVE,
                !statisticsTypeFilters[StatisticsActivityType.ACTIVE],
            ),
    }),
    buildData({
        result:
            selectedStatisticsItem.statistics.current.planningCount ===
            selectedStatisticsItem.statistics.past.planningCount
                ? Result.NEUTRAL
                : selectedStatisticsItem.statistics.current.planningCount >
                  selectedStatisticsItem.statistics.past.planningCount
                ? Result.NEGATIVE
                : Result.POSITIVE,
        period: selectedPeriod,
        currentCount: selectedStatisticsItem.statistics.current.planningCount,
        pastCount: selectedStatisticsItem.statistics.past.planningCount,
        color: Color.BLUE,
        titleComponent: <PlannedStartTitle color={Color.BLUE} />,
        darken: !statisticsTypeFilters[StatisticsActivityType.PLANNED_START],
        onClick: () =>
            setStatisticsTypeFilter(
                StatisticsActivityType.PLANNED_START,
                !statisticsTypeFilters[StatisticsActivityType.PLANNED_START],
            ),
    }),
];

const buildData = ({
    result,
    currentCount = 0,
    pastCount = 0,
    period,
    color,
    titleComponent,
    darken,
    onClick,
}: {
    result: Result;
    currentCount: number;
    pastCount: number;
    period: Period;
    color: Color;
    darken: boolean;
    titleComponent: React.ClassicElement<HTMLDivElement>;
    onClick: () => void;
}) => ({
    count: currentCount,
    fractionOfTotal:
        currentCount > pastCount
            ? Math.round(pastCount / (currentCount / 100))
            : Math.round(currentCount / (pastCount / 100)),
    color,
    periodName: getPeriodName(period),
    progress: {
        result,
        direction:
            currentCount === pastCount ? Direction.NEUTRAL : currentCount > pastCount ? Direction.UP : Direction.DOWN,
        value:
            currentCount > pastCount
                ? pastCount === 0
                    ? 100
                    : Math.round(pastCount / (currentCount / 100))
                : currentCount === 0
                ? 100
                : Math.round(currentCount / (pastCount / 100)),
    },
    darken,
    titleComponent,
    onClick,
});
