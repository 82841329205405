import { ComponentType } from 'react';
import { connect } from 'react-redux';

import { StoreState } from '../../../../../store';
import { selectors } from '../../../../../store/taskPage';
import { getUserById } from '../../../../../store/appUsers';
import { getLoginUser } from '../../../../../store/user';
import { ChannelInfoMappedState, ChannelInfoStoreProps, ChannelInfoContainerProps } from './types';
import { ChannelInfoContainer } from './ChannelInfoContainer';
import { withTaskData, PropsWithTaskData } from '../withTaskData';

const mapStateToProps = (
    state: StoreState,
    { channelId }: PropsWithTaskData<ChannelInfoStoreProps>,
): ChannelInfoMappedState => {
    const channel = selectors.getChannelById(state, channelId);
    const {
        attributes: { id: userId },
    } = getLoginUser(state);

    const author = channel ? getUserById(state, channel.authorId) : null;

    return {
        isUserAuthor: channel?.authorId === userId,
        authorFirstName: author ? author.firstName : '',
        authorMiddleName: author ? author.middleName : undefined,
        authorSecondName: author ? author.secondName : '',
        authorDepartmentName: author ? author.department : '',
        participantsCount: channel?.participantIds?.length,
        createTime: channel?.createTime,
    };
};

const mergeProps = (
    state: ChannelInfoMappedState,
    _: null,
    { className, channelId }: PropsWithTaskData<ChannelInfoStoreProps>,
): ChannelInfoContainerProps => ({
    ...state,
    className,
    channelId,
});

const withStore = connect(mapStateToProps, null, mergeProps);

/** Selected channel information */
export const ChannelInfoStore: ComponentType<ChannelInfoStoreProps> = withTaskData(withStore(ChannelInfoContainer));
