import * as React from 'react';
import classNames from 'classnames';

import * as styles from './CommentEditDropdown.scss';

interface Props {
    className?: string;
    hideItem?: 'edit' | 'remove';
    onEditClick: () => void;
    onRemoveClick: () => void;
}

export function CommentEditDropdownMenu({ className, hideItem, onEditClick, onRemoveClick }: Props) {
    return (
        <div
            className={classNames(styles.items, className)}
            {...{
                'qa-id': 'editDropdownContent',
            }}
        >
            {hideItem !== 'edit' && (
                <div className={styles.button} onClick={onEditClick} qa-id="editDropdownEditButton">
                    Редактировать
                </div>
            )}

            {hideItem !== 'remove' && (
                <div
                    className={styles.button}
                    onClick={onRemoveClick}
                    {...{
                        'qa-id': 'editDropdownDeleteButton',
                    }}
                >
                    Удалить
                </div>
            )}
        </div>
    );
}
