import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import { getTagsState } from '@store/tags';
import {
    ComponentState,
    getInstance,
    setComponentState,
    removeTagFromSelected,
    setTagIdWithActiveEditor,
} from '@store/tagsEditor';

import { Tag } from '@modules/tags/Tag';
import { useDescriptor } from '@modules/tags/TagsPreviewAndEditor/Context';

function useInteractivity(props: Props) {
    const dispatch = useDispatch();
    const { onAttachedTagsChange } = props;
    const { id, makeDataUpdateActionParams, makeActionParams } = useDescriptor();

    const tagIdWithActiveEditor = useSelector((state: StoreState) => getInstance(state, id).tagIdWithActiveEditor);
    const existingTags = useSelector((state: StoreState) => getTagsState(state).entities);
    const canEdit = useSelector((state: StoreState) => getInstance(state, id).canEdit);
    const selectedTags = useSelector((state: StoreState) => getInstance(state, id).tags.selected);

    function onDeleteButtonClick(tagId: string) {
        if (tagId === tagIdWithActiveEditor) {
            dispatch(setComponentState(makeActionParams(ComponentState.Default)));
        }

        dispatch(removeTagFromSelected(makeDataUpdateActionParams(tagId)));
        onAttachedTagsChange?.();
    }

    function onTagClick(tagId: string) {
        dispatch(setTagIdWithActiveEditor(makeActionParams(tagId)));
        dispatch(setComponentState(makeActionParams(ComponentState.TagEditorOpened)));
    }

    return {
        existingTags,
        selectedTags,
        canEdit,
        onDeleteButtonClick,
        onTagClick,
    };
}

interface Props {
    parentZIndex: number;
    onAttachedTagsChange?: () => void;
}

export function SelectedTags(props: Props): JSX.Element {
    const { existingTags, selectedTags, canEdit, onDeleteButtonClick, onTagClick } = useInteractivity(props);

    return (
        <React.Fragment>
            {selectedTags.map((tagId) => {
                const existingTag = existingTags.find((tag) => tag.id === tagId);

                return existingTag ? (
                    <Tag
                        key={tagId}
                        {...existingTag}
                        style={{ zIndex: props.parentZIndex + 1 }}
                        onDeleteButtonClick={canEdit ? () => onDeleteButtonClick(tagId) : null}
                        onClick={canEdit ? () => onTagClick(tagId) : null}
                    />
                ) : null;
            })}
        </React.Fragment>
    );
}
