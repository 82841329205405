import * as React from 'react';

import { DropdownWrapper } from './DropdownWrapper';
import { DropdownContent } from './DropdownContent';

interface Props {}

export const Dropdown: React.FC<Props> = ({}) => {
    return (
        <DropdownWrapper>
            <DropdownContent />
        </DropdownWrapper>
    );
};
