import { actionCreatorFactory } from 'typescript-fsa';

import { FileAsset } from '../commonTypes';

const creator = actionCreatorFactory('FILE_ASSETS');

/**
 * Add asset to store.
 * If asset with same identifier already exists, it will be replaced by new,
 * so you can use this action to update existing asset.
 */
export const addOne = creator<FileAsset>('ADD_ONE');

/**
 * Add assets to store.
 * If some assets with same identifiers already exists, they are will be replaced by new assets,
 * so you can use this action to update existing assets.
 */
export const addList = creator<FileAsset[]>('ADD_LIST');

export const removeOne = creator<string>('REMOVE_ONE');

export const removeList = creator<string[]>('REMOVE_LIST');
export const updateIds = creator<Record<string, string>>('UPDATE_IDS');

export const clearList = creator('CLEAR_LIST');
