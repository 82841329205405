import * as React from 'react';
import classNames from 'classnames';

import * as style from './FiltersColumnHeader.scss';

import { TableType } from '@store/creative/types';
import type { ColumnName } from '../../types';

import { Icon, IconType, BodyPortal, WithTooltip } from 'sber-marketing-ui';
import { ColumnHeaderEditorWrapper } from '../ColumnHeaderEditorWrapper';
import { ColumnFixButton } from './ColumnFixButton';

interface Props {
    table: TableType;
    title: string;
    columnName: ColumnName;
    getFixedColumns: () => Record<TableType, ColumnName[]>;
    onColumnFixClick: (table: TableType, columnName: ColumnName) => void;
}

export const FiltersColumnHeader = ({
    table,
    title,
    columnName,
    getFixedColumns,
    onColumnFixClick,
}: Props): JSX.Element => {
    const [isOpened, setOpenedStatus] = React.useState(false);

    const onOpenerClick = React.useCallback(() => {
        setOpenedStatus(!isOpened);
    }, [isOpened]);

    const onMaskClick = React.useCallback(() => {
        setOpenedStatus(false);
    }, []);

    const fixedColumns = getFixedColumns()[table] || [];

    const isFixed = fixedColumns.includes(columnName);

    return (
        <div className={classNames(style.root, isOpened && style.opened)}>
            <div className={style.opener} onClick={onOpenerClick}>
                <WithTooltip className={style.openerContent} content={title}>
                    <>
                        <div className={style.openerTitle}>{title}</div>

                        <div className={style.openerIcon}>
                            <Icon type={isOpened ? IconType.ARROW8_UP : IconType.ARROW8_DOWN} />
                        </div>
                    </>
                </WithTooltip>
            </div>

            {isOpened && (
                <ColumnHeaderEditorWrapper>
                    <div className={style.content}>
                        <div className={style.title}>{title}</div>

                        <div className={style.fixButton}>
                            <ColumnFixButton
                                isColumnFixed={isFixed}
                                onClick={() => onColumnFixClick(table, columnName)}
                            />
                        </div>
                    </div>
                </ColumnHeaderEditorWrapper>
            )}

            {isOpened && (
                <BodyPortal>
                    <div className={style.mask} onClick={onMaskClick} />
                </BodyPortal>
            )}
        </div>
    );
};
