import * as React from 'react';
import classNames from 'classnames';

import * as style from './ActionsCell.scss';

import { Icon, IconType, Skeleton } from 'sber-marketing-ui';

interface Props {
    lineIsAdded: boolean;
    onLineAdd: () => void;
    onLineRemove: () => void;
}

export const ActionsCell = ({ lineIsAdded, onLineAdd, onLineRemove }: Props): JSX.Element => {
    const [displayPreloader, setPreloader] = React.useState(false);

    const onLineAddClick = React.useCallback(() => {
        setPreloader(true);
        onLineAdd();
    }, []);

    const onLineRemoveClick = React.useCallback(() => {
        setPreloader(true);
        onLineRemove();
    }, []);

    return (
        <div className={style.root}>
            {!displayPreloader && !lineIsAdded && (
                <div className={classNames(style.button, style.addButton)} onClick={onLineAddClick}>
                    <Icon className={style.buttonIcon} type={IconType.PLUS_SIGN} svgSize={9} />
                    Добавить в список
                </div>
            )}

            {!displayPreloader && lineIsAdded && (
                <div className={classNames(style.button, style.removeButton)} onClick={onLineRemoveClick}>
                    <Icon className={style.buttonIcon} type={IconType.CROSS_BRIEF} svgSize={9} />
                    Убрать из списка
                </div>
            )}

            {displayPreloader && <Skeleton customStyle={{ height: '100%', width: '100%' }} />}
        </div>
    );
};
