import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import * as moment from 'moment';

import * as style from './ContextMenu.scss';

import type { StoreState } from '@store';

import {
    Icon,
    IconType,
    WithTooltip,
    CustomScrollbar_new as CustomScrollbar,
    CustomScrollbarTheme,
} from 'sber-marketing-ui';
import { getContracts } from '@store/pivotTable/selectors';

interface Props {
    lot: 1 | 2;
    contractId: string;
    creativeRequestId: string;
    lineCanBeTransfered: boolean;
    lineIsArchived: boolean;
    lineCanBeRestored: boolean;
    customStyle?: any;
    onButtonClick: () => void;
    onCreativeRequestInfoDisplay: (creativeRequestId: string) => void;
    onLineTransfer: (contractId: string) => void;
    onLineArchive: () => void;
    onLineRestore: () => void;
}

export const ContextMenu = ({
    lot,
    contractId,
    creativeRequestId,
    lineCanBeTransfered,
    lineIsArchived,
    lineCanBeRestored,
    onButtonClick,
    onCreativeRequestInfoDisplay,
    onLineTransfer,
    onLineArchive,
    onLineRestore,
}: Props): JSX.Element => {
    const [contractsListIsOpened, setContractsListIsOpenedStatus] = React.useState(false);

    const onCreativeRequestInfoButtonClick = React.useCallback(() => {
        onCreativeRequestInfoDisplay(creativeRequestId);
    }, []);

    const onTransferButtonClick = React.useCallback(() => {
        setContractsListIsOpenedStatus(!contractsListIsOpened);
    }, [contractsListIsOpened]);

    const onArchiveButtonClick = React.useCallback(() => {
        onLineArchive();
        onButtonClick();
    }, []);

    const onRestoreButtonClick = React.useCallback(() => {
        onLineRestore();
        onButtonClick();
    }, []);

    return (
        <div className={style.root}>
            <div className={style.title}>Действия со строкой</div>

            <div className={style.buttons}>
                {creativeRequestId && (
                    <div className={style.button} onClick={onCreativeRequestInfoButtonClick}>
                        <Icon
                            className={classNames(style.buttonIcon, style.infoIcon)}
                            type={IconType.INFO}
                            svgSize={24}
                        />
                        Показать информацию по ЗНК
                    </div>
                )}

                {lineCanBeTransfered && (
                    <div className={style.button} onClick={onTransferButtonClick}>
                        <Icon
                            className={classNames(style.buttonIcon, style.arrrowIcon)}
                            type={IconType.TRANSITION_ARROW}
                            svgSize={16}
                        />
                        Перенести в другой контракт
                        <Icon
                            className={classNames(style.buttonIcon, style.expandIcon)}
                            type={IconType.ARROW_RIGHT}
                            svgSize={20}
                        />
                        {contractsListIsOpened && (
                            <ContractsList
                                lot={lot}
                                contractId={contractId}
                                creativeRequestId={creativeRequestId}
                                onButtonClick={onButtonClick}
                                onLineTransfer={onLineTransfer}
                            />
                        )}
                    </div>
                )}

                {!lineIsArchived && (
                    <div className={style.button} onClick={onArchiveButtonClick}>
                        <Icon
                            className={classNames(style.buttonIcon, style.binIcon)}
                            type={IconType.BIN}
                            svgSize={24}
                        />
                        Удалить строку
                    </div>
                )}

                {lineIsArchived && lineCanBeRestored && (
                    <div className={style.button} onClick={onRestoreButtonClick}>
                        <Icon
                            className={classNames(style.buttonIcon, style.restoreIcon)}
                            type={IconType.RESTORE}
                            svgSize={24}
                        />
                        Восстановить строку
                    </div>
                )}

                {lineIsArchived && !lineCanBeRestored && (
                    <WithTooltip content={'Требуется восстановить строку ЗНК'}>
                        <div className={classNames(style.button, style.disabled)}>
                            <Icon
                                className={classNames(style.buttonIcon, style.restoreIcon)}
                                type={IconType.RESTORE}
                                svgSize={24}
                            />
                            Восстановить строку
                        </div>
                    </WithTooltip>
                )}
            </div>
        </div>
    );
};

export const ContractsList = ({
    lot,
    contractId,
    creativeRequestId,
    onButtonClick,
    onLineTransfer,
}: Partial<Props>): JSX.Element => {
    const contracts = useSelector((state: StoreState) => getContracts(state));

    const onItemClick = React.useCallback((contractId: string) => {
        onLineTransfer(contractId);
        onButtonClick();
    }, []);

    const items = contracts[`lot${lot}`].map((item) => ({
        id: item.model.id,
        name: item.model.name,
        startDate: item.model.startDate,
        endDate: item.model.endDate,
    }));

    if (!creativeRequestId && lot === 2) {
        items.unshift({
            id: null,
            name: 'Без контракта',
            startDate: null,
            endDate: null,
        });
    }

    return (
        <div className={style.contractsList}>
            <CustomScrollbar theme={CustomScrollbarTheme.GrayRounded} maxHeight={329}>
                {items.map((item) => (
                    <ContractItem
                        key={item.id}
                        id={item.id}
                        name={item.name}
                        startDate={item.startDate}
                        endDate={item.endDate}
                        isSelected={item.id === contractId}
                        onClick={onItemClick}
                    />
                ))}
            </CustomScrollbar>
        </div>
    );
};

interface ContractItemProps {
    id: string;
    name: string;
    startDate: Date;
    endDate: Date;
    isSelected: boolean;
    onClick: (id: string) => void;
}

function ContractItem({ id, name, startDate, endDate, isSelected, onClick }: ContractItemProps): JSX.Element {
    const displayDates = !!startDate && !!endDate;

    return (
        <div
            className={classNames(style.contract, isSelected && style.selected)}
            onClick={(event) => {
                event.stopPropagation();
                onClick(id);
            }}
        >
            {name}

            {displayDates && (
                <>
                    <br />
                    <span className={style.gray}>
                        {formatDate(startDate)} — {formatDate(endDate)}
                    </span>
                </>
            )}

            {isSelected && (
                <Icon className={style.contractIcon} type={IconType.CHECK_ICON} svgSize={10} color={'#19bb4f'} />
            )}
        </div>
    );
}

function formatDate(date: Date): string {
    if (!date) {
        return null;
    }

    return moment(date).format('DD.MM.YY');
}
