import { LoadingStatus } from '@store/commonTypes';

export interface PersonalDashboardUserConfigState {
    loadingStatus: LoadingStatus;
    fields: PersonalDashboardUserConfig;
}

export interface PersonalDashboardUserConfig {
    userRoleFilter: UserRoleFilter;
}

export const enum UserRoleFilter {
    Author = 'author',
    Participant = 'participant',
    Responsible = 'responsible',
    Supervisor = 'supervisor',
}

export interface UpdatePersonalDashboardUserConfig {
    userConfig: {
        [F in keyof PersonalDashboardUserConfig]?: Partial<PersonalDashboardUserConfig[F]>;
    };
}
