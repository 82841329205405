import * as React from 'react';
import classNames from 'classnames';
import { IconType, Icon } from 'sber-marketing-ui';

import * as styles from './CommentEditDropdown.scss';

import { CommentEditDropdownMenu } from '../CommentEditDropdownMenu';

const ICON_SIZE = 32;

const EXPANDED_ICON_COLOR = '#19bb4f';
const COLLAPSED_ICON_COLOR = '#a4a4a4';

interface Props {
    isExpanded: boolean;
    className?: string;
    hideItem?: 'edit' | 'remove';
    onExpandClick(): void;
    onEditClick(): void;
    onRemoveClick(): void;
}

export function CommentEditDropdown({
    onExpandClick,
    onEditClick,
    onRemoveClick,
    isExpanded,
    className,
    hideItem,
}: Props): JSX.Element {
    return (
        <div
            className={classNames(styles.root, className)}
            {...{
                'qa-id': 'editDropdown',
            }}
        >
            <div
                className={styles.icon}
                onClick={onExpandClick}
                {...{
                    'qa-id': 'editDropdownOpenButton',
                }}
            >
                <Icon
                    svgSize={ICON_SIZE}
                    type={isExpanded ? IconType.DOTS_GREEN : IconType.DOTS_GREY}
                    color={isExpanded ? EXPANDED_ICON_COLOR : COLLAPSED_ICON_COLOR}
                />
            </div>

            {isExpanded && (
                <CommentEditDropdownMenu
                    hideItem={hideItem}
                    onExpandClick={onExpandClick}
                    onEditClick={onEditClick}
                    onRemoveClick={onRemoveClick}
                />
            )}
        </div>
    );
}
