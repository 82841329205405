// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-pivotTable-CreativeTable-CellTypes-TextareaWithLinkCell-_TextareaWithLinkCell__root{height:100%;width:100%;display:flex;align-items:center;background-color:#ffffff;padding:0 6px;color:#000000;font-family:'Open Sans';font-size:12px;line-height:16px}.src-client-modules-pivotTable-CreativeTable-CellTypes-TextareaWithLinkCell-_TextareaWithLinkCell__tooltipWrapper{width:100%;height:100%;display:flex;align-items:center}.src-client-modules-pivotTable-CreativeTable-CellTypes-TextareaWithLinkCell-_TextareaWithLinkCell__title{display:-webkit-box;-webkit-line-clamp:3;-webkit-box-orient:vertical;overflow:hidden}.src-client-modules-pivotTable-CreativeTable-CellTypes-TextareaWithLinkCell-_TextareaWithLinkCell__link{margin-left:4px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/pivotTable/CreativeTable/CellTypes/TextareaWithLinkCell/TextareaWithLinkCell.scss"],"names":[],"mappings":"AAAA,wGACI,WAAY,CACZ,UAAW,CAEX,YAAa,CACb,kBAAmB,CACnB,wBAAyB,CAEzB,aAAc,CAEd,aAAc,CACd,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACpB,kHAGG,UAAW,CACX,WAAY,CACZ,YAAa,CACb,kBAAmB,CACtB,yGAGG,mBAAoB,CACpB,oBAAqB,CACrB,2BAA4B,CAC5B,eAAgB,CACnB,wGAGG,eAAgB","sourcesContent":[".root {\n    height: 100%;\n    width: 100%;\n\n    display: flex;\n    align-items: center;\n    background-color: #ffffff;\n\n    padding: 0 6px;\n\n    color: #000000;\n    font-family: 'Open Sans';\n    font-size: 12px;\n    line-height: 16px;\n}\n\n.tooltipWrapper {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n}\n\n.title {\n    display: -webkit-box;\n    -webkit-line-clamp: 3;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n}\n\n.link {\n    margin-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-pivotTable-CreativeTable-CellTypes-TextareaWithLinkCell-_TextareaWithLinkCell__root",
	"tooltipWrapper": "src-client-modules-pivotTable-CreativeTable-CellTypes-TextareaWithLinkCell-_TextareaWithLinkCell__tooltipWrapper",
	"title": "src-client-modules-pivotTable-CreativeTable-CellTypes-TextareaWithLinkCell-_TextareaWithLinkCell__title",
	"link": "src-client-modules-pivotTable-CreativeTable-CellTypes-TextareaWithLinkCell-_TextareaWithLinkCell__link"
};
export default ___CSS_LOADER_EXPORT___;
