import * as React from 'react';
import classNames from 'classnames';

import { formatMoneyForTooltip, formatMoney } from '../utils';

import * as style from './TableWithoutCorrection.scss';

export interface FundRow {
    name: string;
    plan: number;
    reserve: number;
    fact: number;
}

interface Props {
    fundsRows: FundRow[];
}

export const TableWithoutCorrection: React.FC<Props> = ({ fundsRows }) => {
    const sumPlansFunds = fundsRows.reduce((acc, { plan }) => acc + plan, 0);
    const sumReserveFunds = fundsRows.reduce((acc, { reserve }) => acc + reserve, 0);
    const sumFactFunds = fundsRows.reduce((acc, { fact }) => acc + fact, 0);

    return (
        <div>
            <div className={classNames(style.tableRow, style.tableRowHeader)}>
                <div className={classNames(style.tableCell, style.tableCellAccent)}>Месяц</div>
                <div className={classNames(style.tableCell, style.tableCellAccent)}>План, Тыс, ₽</div>
                <div className={classNames(style.tableCell, style.tableCellAccent)}>Резерв, Тыс, ₽</div>
                <div className={classNames(style.tableCell, style.tableCellAccent)}>Факт, Тыс, ₽</div>
            </div>
            {fundsRows.map(({ name, plan, reserve, fact }) => (
                <div className={style.tableRow} key={name}>
                    <div className={style.tableCell}>{name}</div>
                    <div className={style.tableCell} title={formatMoneyForTooltip(plan)}>
                        {formatMoney(plan)}
                    </div>
                    <div className={style.tableCell} title={formatMoneyForTooltip(reserve)}>
                        {formatMoney(reserve)}
                    </div>
                    <div className={style.tableCell} title={formatMoneyForTooltip(reserve)}>
                        {formatMoney(fact)}
                    </div>
                </div>
            ))}
            <div className={classNames(style.tableRow, style.tableRowFooter)}>
                <div className={classNames(style.tableCell, style.tableCellAccent)}>Итог, тыс. ₽</div>
                <div className={style.tableCell} title={formatMoneyForTooltip(sumPlansFunds)}>
                    {formatMoney(sumPlansFunds)}
                </div>
                <div className={style.tableCell} title={formatMoneyForTooltip(sumReserveFunds)}>
                    {formatMoney(sumReserveFunds)}
                </div>
                <div className={style.tableCell} title={formatMoneyForTooltip(sumFactFunds)}>
                    {formatMoney(sumFactFunds)}
                </div>
            </div>
        </div>
    );
};
