import * as React from 'react';
import { Icon, IconType } from 'sber-marketing-ui';

import * as styles from './styles.scss';

interface Props {
    onClick: React.MouseEventHandler<HTMLDivElement>;
}

export const Button: React.FC<Props> = ({ onClick }) => {
    return (
        <div className={styles.root} onClick={onClick}>
            <Icon className={styles.icon} type={IconType.ROUND_ELLIPSIS} />
        </div>
    );
};
