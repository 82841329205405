// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-tags-components-TagSettings-_TagSettings__root{display:flex;flex-direction:column;gap:8px;padding:6px;font-size:14px}.src-client-modules-tags-components-TagSettings-_TagSettings__emojis,.src-client-modules-tags-components-TagSettings-_TagSettings__tags{display:flex;flex-wrap:wrap;gap:8px;max-height:100px;margin-right:calc(var(--ui-scrollbar-size) * -1)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/tags/components/TagSettings/TagSettings.scss"],"names":[],"mappings":"AAAA,mEACE,YAAa,CACb,qBAAsB,CACtB,OAAQ,CACR,WAAY,CACZ,cAAe,CAChB,wIAIC,YAAa,CACb,cAAe,CACf,OAAQ,CACR,gBAAiB,CACjB,gDAAiD","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  padding: 6px;\n  font-size: 14px;\n}\n\n.emojis,\n.tags {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 8px;\n  max-height: 100px;\n  margin-right: calc(var(--ui-scrollbar-size) * -1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-tags-components-TagSettings-_TagSettings__root",
	"emojis": "src-client-modules-tags-components-TagSettings-_TagSettings__emojis",
	"tags": "src-client-modules-tags-components-TagSettings-_TagSettings__tags"
};
export default ___CSS_LOADER_EXPORT___;
