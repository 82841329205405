// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-_MediaplanStageWidget__root{position:relative;width:100%;overflow:hidden;border-radius:6px}.src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-_MediaplanStageWidget__table{margin-bottom:40px;user-select:none}.src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-_MediaplanStageWidget__table:last-child{margin-bottom:0}.src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-_MediaplanStageWidget__title{color:#092a3a;font-weight:600;font-family:'Open Sans';font-size:16px;line-height:22px;margin-bottom:8px}.src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-_MediaplanStageWidget__downloadButton{margin-bottom:24px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilotTv/StageWidgets/MediaplanStageWidget/MediaplanStageWidget.scss"],"names":[],"mappings":"AAAA,6GACI,iBAAkB,CAClB,UAAW,CACX,eAAgB,CAChB,iBAAkB,CACrB,8GAGG,kBAAmB,CACnB,gBAAiB,CAFrB,yHAKQ,eAAgB,CACnB,8GAID,aAAc,CACd,eAAgB,CAChB,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACjB,iBAAkB,CACrB,uHAGG,kBAAmB","sourcesContent":[".root {\n    position: relative;\n    width: 100%;\n    overflow: hidden;\n    border-radius: 6px;\n}\n\n.table {\n    margin-bottom: 40px;\n    user-select: none;\n\n    &:last-child {\n        margin-bottom: 0;\n    }\n}\n\n.title {\n    color: #092a3a;\n    font-weight: 600;\n    font-family: 'Open Sans';\n    font-size: 16px;\n    line-height: 22px;\n    margin-bottom: 8px;\n}\n\n.downloadButton {\n    margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-_MediaplanStageWidget__root",
	"table": "src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-_MediaplanStageWidget__table",
	"title": "src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-_MediaplanStageWidget__title",
	"downloadButton": "src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanStageWidget-_MediaplanStageWidget__downloadButton"
};
export default ___CSS_LOADER_EXPORT___;
