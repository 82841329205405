// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-components-TelegramButton-_styles__root{cursor:pointer;transition:opacity 75ms ease-out}.src-client-modules-activity-pages-task-components-TelegramButton-_styles__root:hover>.src-client-modules-activity-pages-task-components-TelegramButton-_styles__iconWrapper{transform:scale(1.1)}.src-client-modules-activity-pages-task-components-TelegramButton-_styles__rootLoading{opacity:0.7;cursor:default}.src-client-modules-activity-pages-task-components-TelegramButton-_styles__iconWrapperEnabled{color:#417EC6}.src-client-modules-activity-pages-task-components-TelegramButton-_styles__iconWrapperDisabled{color:#7E8681}.src-client-modules-activity-pages-task-components-TelegramButton-_styles__iconWrapperDisabled:hover{color:black}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/components/TelegramButton/styles.scss"],"names":[],"mappings":"AAAA,gFACI,cAAe,CAEf,gCAAiC,CAHrC,6KAKQ,oBAAqB,CACxB,uFAID,WAAY,CACZ,cAAe,CAClB,8FAKG,aAAc,CACjB,+FAGG,aAAc,CADlB,qGAIQ,WAAY","sourcesContent":[".root {\n    cursor: pointer;\n\n    transition: opacity 75ms ease-out;\n    &:hover > .iconWrapper {\n        transform: scale(1.1);\n    }\n}\n\n.rootLoading {\n    opacity: 0.7;\n    cursor: default;\n}\n\n.iconWrapper {}\n\n.iconWrapperEnabled {\n    color: #417EC6;\n}\n\n.iconWrapperDisabled {\n    color: #7E8681;\n\n    &:hover {\n        color: black;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-components-TelegramButton-_styles__root",
	"iconWrapper": "src-client-modules-activity-pages-task-components-TelegramButton-_styles__iconWrapper",
	"rootLoading": "src-client-modules-activity-pages-task-components-TelegramButton-_styles__rootLoading",
	"iconWrapperEnabled": "src-client-modules-activity-pages-task-components-TelegramButton-_styles__iconWrapperEnabled",
	"iconWrapperDisabled": "src-client-modules-activity-pages-task-components-TelegramButton-_styles__iconWrapperDisabled"
};
export default ___CSS_LOADER_EXPORT___;
