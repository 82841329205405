import * as React from 'react';

import { LeftSidebar } from './LeftSidebar';

interface Props {}

export class LeftSidebarContainer extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        return React.createElement(LeftSidebar, {});
    }
}
