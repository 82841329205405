import { Success, Failure } from 'typescript-fsa';

import { SetRateSuccess, SetRatePayload, TaskPageState, FetchError } from '../types';

export const setRateStartedReducer = (state: TaskPageState, payload: SetRatePayload): TaskPageState => {
    return {
        ...state,
        rate: payload.rate,
    };
};

export const setRateDoneReducer = (
    state: TaskPageState,
    { result }: Success<SetRatePayload, SetRateSuccess>,
): TaskPageState => {
    return {
        ...state,
        rate: result.rate,
    };
};

export const setRateErrorReducer = (
    state: TaskPageState,
    error: Failure<SetRatePayload, FetchError>,
): TaskPageState => {
    return {
        ...state,
    };
};
