import * as React from 'react';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import * as moment from 'moment';
import { CorrectionStatus } from '@mrm/budget';

import { Month, RESERVED_COLUMN_NAMES } from '../../../types';
import type { Funds, Persone, ReservedFundsCorrection, BudgetItem } from '../../../types';

import type { UnsavedChange } from '@store/budgetCorrections/types';

import { ReserveCorrectionCard } from './ReserveCorrectionCard';
import type { PlanData, ReserveData, PlanField, FondField, Warning } from './ReserveCorrectionCard';

import { Utils } from '@common/Utils';
import { monthIndexMap, orderOfMonth } from '../utils';

interface FundsByMonth {
    [month: number]: number;
}

interface Props extends ReservedFundsCorrection {
    budgetId: string;
    userIsBudgetExpert: boolean;
    userIsAuthorCorrection: boolean;
    setUnsavedChange: (unsavedChange: UnsavedChange) => void;
}

export class ReserveCorrectionCardContainer extends React.Component<Props> {
    public render(): JSX.Element {
        const { serialNumber, creationTime, status, author, overwrittenSerialNumber } = this.props;

        const authorName = this.formatUserName(author);
        const approverName = this.getApproverName();

        const reserveData = this.makeReserveData();
        const firstChangedMonth = reserveData.fields.find((item) => item.delta !== 0);

        return React.createElement(ReserveCorrectionCard, {
            serialNumber,
            authorName,
            approverName,
            userIsBudgetExpert: this.props.userIsBudgetExpert,
            userIsAuthorCorrection: this.props.userIsAuthorCorrection,
            creationDate: this.formatDate(creationTime),
            planData: this.makePlanData(),
            reserveData,
            status,
            url: this.makeUrl(firstChangedMonth ? firstChangedMonth.monthIndex : 0),
            rejectComment: this.makeRejectComment(),
            overwrittenSerialNumber,
            warnings: this.makeWarnings(),
            onStatusChange: this.onStatusChange,
        });
    }

    @autobind
    protected onStatusChange(status: CorrectionStatus) {
        const { id } = this.props;

        this.props.setUnsavedChange({
            correctionId: id,
            newStatus: status,
        });
    }

    private getApproverName(): string {
        const { status, expert, approver } = this.props;

        if (status === CorrectionStatus.NeedApproving) {
            return expert ? this.formatUserName(expert) : 'не задан';
        }

        return approver ? this.formatUserName(approver) : 'не задан';
    }

    private makeRejectComment(): string {
        const { comment, rejectedSerialNumber } = this.props;

        if (rejectedSerialNumber) {
            return `Корректировка отклонена автоматически после создания новой корректировки резерва для этой строки. Номер созданной корректировки - ${rejectedSerialNumber}`;
        }

        if (comment) {
            return comment;
        }

        return null;
    }

    private makeWarnings(): Warning[] {
        const { status } = this.props;

        // TODO В ответе graphQL висит поле __typename и для перебора свойст обхекта оно мешает
        delete this.budgetItem.plannedFunds['__typename'];
        const currentPlannedFunds = this.budgetItem.plannedFunds;

        const reserveFields = this.buildFondFieldsForReserveData();

        const beforeSumReserveFonds = reserveFields.reduce((acc, fond) => acc + fond.before, 0);
        const reservesSumDelta = reserveFields.reduce((acc, fond) => (fond.delta !== null ? acc + fond.delta : acc), 0);

        const sumCurrentPlannedFunds = lodash.values(currentPlannedFunds).reduce<number>((acc, fond) => {
            return acc + fond;
        }, 0);

        const sumReserveFunds = beforeSumReserveFonds + reservesSumDelta;

        const delta = sumReserveFunds - sumCurrentPlannedFunds;

        if (delta > 0) {
            return [
                {
                    delta,
                    budgetItemId: String(this.budgetItem.serialNumber),
                    isActive: status === CorrectionStatus.NeedApproving,
                },
            ];
        }

        return [];
    }

    private get budgetItem(): BudgetItem {
        const { status, budgetItemCurrent: budgetItemsCurrent, budgetItemBefore: budgetItemsBefore } = this.props;

        return status === CorrectionStatus.NeedApproving
            ? lodash.first(budgetItemsCurrent)
            : lodash.isEmpty(lodash.first(budgetItemsBefore))
            ? lodash.first(budgetItemsCurrent)
            : lodash.first(budgetItemsBefore);
    }

    private makePlanData(): PlanData {
        // TODO В ответе graphQL висит поле __typename и для перебора свойст обхекта оно мешает
        delete this.budgetItem.plannedFunds['__typename'];
        const sumPlannedFunds = lodash.values(this.budgetItem.plannedFunds).reduce<number>((acc, fond) => {
            return acc + fond;
        }, 0);

        const donors = this.getBudgetItemDonors();

        const fields: PlanField[] = [
            {
                name: 'ID план',
                value: donors.donorsShorted,
                title: donors.donors,
            },
            {
                name: 'ID исполнение',
                value: this.budgetItem.serialNumber,
            },
            {
                name: 'Тип проекта',
                value:
                    this.budgetItem.dictionary &&
                    this.budgetItem.dictionary.activity_type &&
                    Utils.getDictionaryValue(this.budgetItem.dictionary.activity_type),
            },
            {
                name: 'Направление',
                value:
                    this.budgetItem.dictionary &&
                    this.budgetItem.dictionary.direction &&
                    Utils.getDictionaryValue(this.budgetItem.dictionary.direction),
            },
            {
                name: 'Дата запуска',
                value: this.budgetItem.realizationStart && this.formatDate(this.budgetItem.realizationStart),
            },
            {
                name: 'Дата окончания',
                value: this.budgetItem.realizationEnd && this.formatDate(this.budgetItem.realizationEnd),
            },
            {
                name: 'ЦА/ТБ (Территория)',
                value: this.budgetItem.dictionary?.regionality
                    ? Utils.getDictionaryValue(this.budgetItem.dictionary.regionality)
                    : '',
            },
            {
                name: 'Территория',
                value: this.budgetItem.dictionary?.territory
                    ? Utils.getDictionaryValue(this.budgetItem.dictionary.territory)
                    : '',
            },
            {
                name: 'Статья',
                value:
                    this.budgetItem.dictionary &&
                    this.budgetItem.dictionary.item &&
                    Utils.getDictionaryValue(this.budgetItem.dictionary.item),
            },
            {
                name: 'Блок',
                value:
                    this.budgetItem.dictionary &&
                    this.budgetItem.dictionary.block &&
                    Utils.getDictionaryValue(this.budgetItem.dictionary.block),
                isWide: true,
            },
            {
                name: 'Инструмент',
                value:
                    this.budgetItem.dictionary &&
                    this.budgetItem.dictionary.tool &&
                    Utils.getDictionaryValue(this.budgetItem.dictionary.tool),
                isWide: true,
            },
            {
                name: 'Центр затрат',
                value: this.budgetItem.dictionary?.cost_center?.value,
                isWide: true,
            },
        ];

        return {
            name: this.budgetItem.sapComment,
            currentPlan: sumPlannedFunds,
            fields,
            comment: this.budgetItem.comment,
        };
    }

    private makeReserveData(): ReserveData {
        const fields = this.buildFondFieldsForReserveData();

        return {
            beforeSumFonds: fields.reduce((acc, fond) => acc + fond.before, 0),
            delta: fields.reduce((acc, fond) => (fond.delta !== null ? acc + fond.delta : acc), 0),
            fields,
        };
    }

    private buildFondFieldsForReserveData(): FondField[] {
        const { params } = this.props;

        const fundsByMonths = this.buildFundsByMonths(this.budgetItem.reservedFunds);

        const changedMonthFonds = params;

        return orderOfMonth.map((month) => {
            const haveChangeCurrentMonthFund = changedMonthFonds.hasOwnProperty(month);

            if (haveChangeCurrentMonthFund) {
                return this.buildFondField({
                    before: fundsByMonths[month],
                    after: changedMonthFonds[month],
                    month,
                });
            }

            return this.buildFondField({
                before: fundsByMonths[month],
                after: fundsByMonths[month],
                month,
            });
        });
    }

    private buildFondField({ before, after, month }: { before: number; after: number; month: Month }): FondField {
        const delta = after - before;
        return {
            monthIndex: monthIndexMap[month],
            before,
            after,
            delta,
            deltaIsPositive: delta >= 0,
        };
    }

    private buildFundsByMonths(funds: Funds = {}): FundsByMonth {
        return orderOfMonth.reduce(
            (fundsByMonth, month) => ({
                [month]: funds[month] || 0,
                ...fundsByMonth,
            }),
            {},
        );
    }

    private formatUserName(user: Persone): string {
        return `${user.firstName} ${user.secondName}`;
    }

    private formatDate(date: string | Date): string {
        return moment(date).format('DD.MM.YY');
    }

    private makeUrl(firstChangedMonthIndex: number) {
        const { budgetItemCurrent: budgetItemsCurrent, budgetId } = this.props;
        const currentBudgetItem = lodash.first(budgetItemsCurrent);

        const lineId = currentBudgetItem.id;
        const column = RESERVED_COLUMN_NAMES[firstChangedMonthIndex];

        return `/budget/execution?filters=id:${lineId}&column=${column}&budgetId=${budgetId}`;
    }

    // this.props.budgetItemBefore doesn't have donors, so wee nedd to calculate it based on a this.props.budgetItemCurrent;
    private getBudgetItemDonors() {
        const budgetItem = lodash.first(this.props.budgetItemCurrent);

        let budgetItemDonors = '';
        let budgetItemDonorsShortened = '';
        if (budgetItem.donors) {
            const budgetItemDonorsParts: number[][] = [];
            for (let i = 0; i < budgetItem.donors.length; i += 20) {
                budgetItemDonorsParts.push(budgetItem.donors.slice(i, i + 20));
            }

            budgetItemDonors = budgetItemDonorsParts.map((part) => part.join(', ')).join('\n');
            budgetItemDonorsShortened = `${budgetItem.donors.slice(0, 4).join(', ')}${
                budgetItem.donors.length > 4 ? '...' : ''
            }`;
        }

        return {
            donors: budgetItemDonors,
            donorsShorted: budgetItemDonorsShortened,
        };
    }
}
