// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-components-CommentsAndDescription-_CommentsAndDescription__root{padding:38px 0 80px}.src-client-modules-activity-pages-task-components-CommentsAndDescription-_CommentsAndDescription__taskTitle{margin-bottom:24px}.src-client-modules-activity-pages-task-components-CommentsAndDescription-_CommentsAndDescription__channelsBoard{margin-bottom:16px}.src-client-modules-activity-pages-task-components-CommentsAndDescription-_CommentsAndDescription__commentForm{margin-bottom:32px}.src-client-modules-activity-pages-task-components-CommentsAndDescription-_CommentsAndDescription__comment{margin-bottom:12px}.src-client-modules-activity-pages-task-components-CommentsAndDescription-_CommentsAndDescription__taskInfo{margin-bottom:32px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/components/CommentsAndDescription/CommentsAndDescription.scss"],"names":[],"mappings":"AAAA,wGACI,mBAAoB,CACvB,6GAGG,kBAAmB,CACtB,iHAGG,kBAAmB,CACtB,+GAGG,kBAAmB,CACtB,2GAGG,kBAAmB,CACtB,4GAGG,kBAAmB","sourcesContent":[".root {\n    padding: 38px 0 80px;\n}\n\n.taskTitle {\n    margin-bottom: 24px;\n}\n\n.channelsBoard {\n    margin-bottom: 16px;\n}\n\n.commentForm {\n    margin-bottom: 32px;\n}\n\n.comment {\n    margin-bottom: 12px;\n}\n\n.taskInfo {\n    margin-bottom: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-components-CommentsAndDescription-_CommentsAndDescription__root",
	"taskTitle": "src-client-modules-activity-pages-task-components-CommentsAndDescription-_CommentsAndDescription__taskTitle",
	"channelsBoard": "src-client-modules-activity-pages-task-components-CommentsAndDescription-_CommentsAndDescription__channelsBoard",
	"commentForm": "src-client-modules-activity-pages-task-components-CommentsAndDescription-_CommentsAndDescription__commentForm",
	"comment": "src-client-modules-activity-pages-task-components-CommentsAndDescription-_CommentsAndDescription__comment",
	"taskInfo": "src-client-modules-activity-pages-task-components-CommentsAndDescription-_CommentsAndDescription__taskInfo"
};
export default ___CSS_LOADER_EXPORT___;
