import * as React from 'react';
import { useDispatch } from 'react-redux';
import * as lodash from 'lodash';

import { UserConfigType } from 'sber-marketing-types/openid';
import type { ColumnName, ColumnWidths, Filter } from '../CreativeTable/types';

import { UserConfigApi } from '@api';
import { setSelectedLot1ContractId, setSelectedLot2ContractId } from '@store/pivotTable/actions';

interface PivotTableUserConfig {
    lot1: {
        contractId?: string;
        columnWidths?: ColumnWidths;
        statusFilterIsActive?: boolean;
        fixedColumns?: ColumnName[];
        visibleColumns?: ColumnName[];
        filters?: Filter[];
    };
    lot2: {
        contractId?: string;
        columnWidths?: ColumnWidths;
        statusFilterIsActive?: boolean;
        fixedColumns?: ColumnName[];
        visibleColumns?: ColumnName[];
        filters?: Filter[];
    };
}

interface Props {
    creativeTableRef: React.MutableRefObject<any>;
}

export const useUserConfigHooks = ({ creativeTableRef }: Props) => {
    const userConfig = React.useRef({
        lot1: {},
        lot2: {},
    } as PivotTableUserConfig);

    const dispatch = useDispatch();

    React.useEffect(() => {
        initFromUserConfig();
    }, []);

    const delayedUserConfigSave = lodash.debounce(saveUserConfig, 1000);

    async function onContractChange(lot: 1 | 2, contractId: string) {
        const updatedConfig = lodash.clone(userConfig.current);

        updatedConfig[`lot${lot}`].contractId = contractId;

        userConfig.current = updatedConfig;

        await delayedUserConfigSave();
    }

    async function onColumnWidthsChange(lot: 1 | 2, columnWidths: ColumnWidths) {
        const updatedConfig = lodash.clone(userConfig.current);

        updatedConfig[`lot${lot}`].columnWidths = columnWidths;

        userConfig.current = updatedConfig;

        await delayedUserConfigSave();
    }

    async function onArchivedFilterChange(lot: 1 | 2, archivedFilterStatus: boolean) {
        const updatedConfig = lodash.clone(userConfig.current);

        updatedConfig[`lot${lot}`].statusFilterIsActive = archivedFilterStatus;

        userConfig.current = updatedConfig;

        await saveUserConfig();
    }

    async function onFixedColumnsChange(lot: 1 | 2, fixedColumns: ColumnName[]) {
        const updatedConfig = lodash.clone(userConfig.current);

        updatedConfig[`lot${lot}`].fixedColumns = fixedColumns;

        userConfig.current = updatedConfig;

        await delayedUserConfigSave();
    }

    async function onVisibleColumnsChange(lot: 1 | 2, visibleColumns: ColumnName[]) {
        const updatedConfig = lodash.clone(userConfig.current);

        updatedConfig[`lot${lot}`].visibleColumns = visibleColumns;

        userConfig.current = updatedConfig;

        await delayedUserConfigSave();
    }

    async function onFiltersChange(lot: 1 | 2, filters: Filter[]) {
        const updatedConfig = lodash.clone(userConfig.current);

        updatedConfig[`lot${lot}`].filters = filters;

        userConfig.current = updatedConfig;

        await delayedUserConfigSave();
    }

    async function initFromUserConfig() {
        const loadedUserConfig = (await UserConfigApi.getPageConfig(UserConfigType.PivotTable)) as PivotTableUserConfig;

        userConfig.current = {
            lot1: { ...loadedUserConfig.lot1 },
            lot2: { ...loadedUserConfig.lot2 },
        };

        dispatch(setSelectedLot1ContractId(userConfig.current.lot1.contractId));
        dispatch(setSelectedLot2ContractId(userConfig.current.lot2.contractId));
    }

    async function saveUserConfig() {
        await UserConfigApi.savePageConfig(UserConfigType.PivotTable, userConfig.current);
    }

    function onCreativeTableLoad(lot: 1 | 2) {
        if (creativeTableRef.current && userConfig.current) {
            creativeTableRef.current.setArchivedFilter(userConfig.current[`lot${lot}`].statusFilterIsActive || false);
            creativeTableRef.current.setLeftFixedColumnList(userConfig.current[`lot${lot}`].fixedColumns, lot);
            creativeTableRef.current.setFilterValues(userConfig.current[`lot${lot}`].filters);
            creativeTableRef.current.setColumnWidths(userConfig.current[`lot${lot}`].columnWidths);
            creativeTableRef.current.setVisibleColumns(userConfig.current[`lot${lot}`].visibleColumns);
        }
    }

    return {
        onContractChange,
        onColumnWidthsChange,
        onArchivedFilterChange,
        onFixedColumnsChange,
        onVisibleColumnsChange,
        onFiltersChange,
        onCreativeTableLoad,
    };
};
