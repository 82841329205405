import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { combineReducers } from 'redux';
import { stubFalse, stubTrue, stubArray, castArray, includes } from 'lodash';
import { ActivityCard } from 'sber-marketing-types/frontend';

import {
    fetchMoreActivities,
    setCanBeLoadedMore,
    setType,
    pushActivities,
    removeActivities,
    resetMyActivities,
    clearActivitiesList,
    updateActivityOwnerships,
    shareActivity,
    switchKeyActivity,
    switchActivityFavorite,
    setLastRequestTimestamp,
    resetLastRequestTimestamp,
    setPendingDeletionIds,
} from './actions';
import { ActivitiesList, ActivitiesListType } from './types';
import { LoadingStatus } from '@store/commonTypes';
import { resetStore } from '@store/common';

const status = reducerWithInitialState<LoadingStatus>(LoadingStatus.NOT_LOADED)
    .cases([resetMyActivities, clearActivitiesList, resetStore], () => LoadingStatus.NOT_LOADED)
    .case(fetchMoreActivities.started, () => LoadingStatus.LOADING)
    .cases([fetchMoreActivities.failed, switchKeyActivity.failed], () => LoadingStatus.ERROR)
    .case(fetchMoreActivities.done, (state, payload) => {
        return payload.result ? LoadingStatus.LOADED : LoadingStatus.LOADING;
    });

const pendingDeletionIds = reducerWithInitialState<number[]>([])
    .cases([resetMyActivities, clearActivitiesList, resetStore], () => [])
    .case(setPendingDeletionIds, (_, payload) => payload);

const type = reducerWithInitialState<ActivitiesListType>(ActivitiesListType.ACCESSIBLE_ACTIVITIES)
    .cases([resetMyActivities, resetStore], () => ActivitiesListType.ACCESSIBLE_ACTIVITIES)
    .case(setType, (_, type) => type);

const canBeLoadedMore = reducerWithInitialState<boolean>(false)
    .cases([resetMyActivities, resetStore], stubFalse)
    .case(setCanBeLoadedMore, (_, canBeLoadedMore) => canBeLoadedMore);

const isActivitySharingInProgress = reducerWithInitialState<boolean>(false)
    .cases([resetMyActivities, resetStore, shareActivity.done, shareActivity.failed], stubFalse)
    .case(shareActivity.started, stubTrue);

const activities = reducerWithInitialState<ActivityCard[]>([])
    .cases([resetMyActivities, clearActivitiesList, resetStore], stubArray)
    .case(pushActivities, (state, payload) => {
        const loadedIds = state.map(({ id }) => id);
        const newActivities = castArray(payload).filter(({ id }) => !includes(loadedIds, id));
        return state.concat(newActivities);
    })
    .case(switchActivityFavorite, (state, id) => {
        return state.map((activity) =>
            activity.id == id
                ? {
                      ...activity,
                      isFavorite: !activity.isFavorite,
                  }
                : activity,
        );
    })
    .case(updateActivityOwnerships, (state, { activityId, ownerships }) =>
        state.map(({ id, ownerships: oldOwnerships, ...rest }) => ({
            ...rest,
            id,
            ownerships: id === activityId ? ownerships : oldOwnerships,
        })),
    )
    .case(removeActivities, (state, payload) => {
        const ids = castArray(payload);
        return state.filter(({ id }) => !includes(ids, id));
    })
    .case(switchKeyActivity.started, (state, payload) => {
        const { id: activityId } = payload;

        return state.map((activity) => {
            if (activity.id === activityId) {
                return {
                    ...activity,
                    isLoading: true,
                };
            }

            return activity;
        });
    })
    .case(switchKeyActivity.done, (state, payload) => {
        const { id, isKey } = payload.params;

        return state.map((activity) => {
            if (activity.id === id) {
                return {
                    ...activity,
                    isKey,
                    isLoading: false,
                };
            }

            return activity;
        });
    })
    .case(switchKeyActivity.failed, (state, payload) => {
        const { id, isKey } = payload.params;

        return state.map((activity) => {
            if (activity.id === id) {
                return {
                    ...activity,
                    isKey,
                    isLoading: true,
                };
            }

            return activity;
        });
    });

const error = reducerWithInitialState<Error | null>(null)
    .cases([fetchMoreActivities.started, fetchMoreActivities.done, resetMyActivities, resetStore], () => null)
    .case(fetchMoreActivities.failed, (_, { error }) => error);

const lastRequestTimestamp = reducerWithInitialState<Date>(null)
    .case(setLastRequestTimestamp, (state, payload) => payload)
    .cases([clearActivitiesList, resetLastRequestTimestamp], () => null);

export const activitiesList = combineReducers<ActivitiesList>({
    status,
    isActivitySharingInProgress,
    type,
    canBeLoadedMore,
    activities,
    error,
    lastRequestTimestamp,
    pendingDeletionIds,
});
