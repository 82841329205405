/* tslint:disable:max-file-line-count */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import * as queryString from 'query-string';

import {
    ActivityBudget,
    BudgetItemStatus,
    BudgetItem,
    BudgetStatus,
    Budget,
    BudgetItemApproverStatus,
} from '@mrm/budget';
import { PlanPermissionsSysNames } from 'sber-marketing-types/backend';
import { UserConfigType } from 'sber-marketing-types/openid';
import {
    BudgetItemTypes,
    ChangeList,
    ColumnName,
    ColumnsVisiblityFilter,
    ColumnsWidth,
    Filters,
    AppliedFiltersNames,
    LineStatusChanges,
    PlanningTableUserConfig,
    SortingMode,
    TableLine,
} from '@store/budgetPlanning/types';

import { BudgetPlanning } from './BudgetPlanning';
import { StoreState } from '@store';
import { setNotification, clearAllNotifications, setRequestInProgress } from '@store/common/actions';
import { isRequestInProgress } from '@store/common/selectors';
import { NotificationActionType, NotificationMessage, NotificationType } from '@store/common/types';
import {
    PageData,
    canRedoUnsavedChanges,
    canUndoUnsavedChanges,
    getBudgetPlanningPageState,
    getPageData,
    getTableFilters,
    getTableLines,
    getUnsavedChanges,
    getDefaultFilters,
    clearUnsavedChanges,
    clearUnsavedChangesByLines,
    redoUnsavedChanges,
    resetChangesHistory,
    resetPageStore,
    setBudgetItemsToDisplay,
    setBudgetItemsApproversToDisplay,
    setColumnsVisiblityFilter,
    setFilters,
    resetFilters,
    resetViewSettings,
    setPreloaderStatus,
    setDisplayOnlyUnapproved,
    setDisplayOnlyWithSnapshots,
    setDisplayDisabledLines,
    undoUnsavedChanges,
    updateInProgressData,
    getLineIdsWithActualChanges,
    getLineModalState,
    loadBudgetItems,
    initPreviouslyLoadedFilter,
    resetPreviouslyLoadedFilters,
    resetBudgetRelatedData,
    getFilteredTableLines,
    BudgetItemApproversToDisplay,
    downloadXLSXTemplate,
    loadXLSXContent,
    loadPageData,
    getBudgetItems,
    userCanEditOrCreateBudgetData as userCanEditOrCreateBudgetData_,
    setShowTagsHaveChangedMarker,
    setShowOnlyLinesWithPlanBudget,
    setShowOnlyLinesWithoutPlanBudget,
} from '@store/budgetPlanning';
import { resetState as resetMiscBudgetItemsState } from '@store/budgetPlanning/miscBudgetItems';
import { getLoginUser } from '@store/user/selector';
import { getBudgetByStatusUserConfig } from '@store/userConfig/budget';
import { getBudgetState } from '@store/budgetPage';
import { ColumnsList } from './ColumnsConfig';
import { TableLoader, TableSaver, TableValidator } from './modules';
import { UserConfigApi } from '@api';
import { Utils } from '@common/Utils';

import { Table } from './Table';

const TABLE_MARGIN_BOTTOM = 24;
const USER_CONFIG_UPDATE_TIMEOUT = 1000;

interface Props extends Partial<MapProps & DispatchProps & RouteComponentProps> {}

interface MapProps {
    dictionariesOrganizationId: string;
    budgetId: string;
    budgets: Budget[];
    preloader: boolean;
    localPreloader: boolean;
    activityBudgets: ActivityBudget[];
    budgetItems: BudgetItem[];
    columnsVisiblityFilter: ColumnsVisiblityFilter;
    fixedColumnsNames: ColumnName[];
    sortingMode?: SortingMode;
    defaultFilters: Filters;
    filters?: Filters;
    appliedFiltersNames: AppliedFiltersNames;
    columnsWidth: ColumnsWidth;
    unsavedChanges: ChangeList;
    displayOnlyUnapproved: boolean;
    displayOnlyWithSnapshots: boolean;
    displayDisabledLines: boolean;
    lineStatusChanges: LineStatusChanges;
    approverStatusChanges: LineStatusChanges;
    userIsBudgetExpert: boolean;
    userIsSupervisor: boolean;
    allLines: TableLine[];
    filteredTableLines: TableLine[];
    canUndoUnsavedChanges: boolean;
    canRedoUnsavedChanges: boolean;
    showLineModal: boolean;
    budgetItemsToDisplay: BudgetItemTypes;
    lineIdsWithActualChanges: string[];
    budgetItemsApproversToDisplay: BudgetItemApproversToDisplay;
    budgetItemsToIgnoreFilters: BudgetItem[];
    userCanEditOrCreateBudgetData: boolean;
    showOnlyLinesWithPlanBudget: boolean;
    showOnlyLinesWithoutPlanBudget: boolean;
}

interface DispatchProps {
    resetBudgetRelatedData: () => void;
    loadBudgetItems: (budgetItems: BudgetItem[]) => void;
    setRequestInProgress: (requestStatus: boolean) => void;
    clearUnsavedChanges: () => void;
    clearUnsavedChangesByLines: (lineIds: string[]) => void;
    resetBudgetExecutionPage: () => void;
    setColumnsVisiblityFilter: (filter: ColumnsVisiblityFilter) => void;
    setFilters: (filterMode: Filters) => void;
    resetFilters: () => void;
    resetViewSettings: () => void;
    setDisplayOnlyUnapproved: (displayOnlyUnapproved: boolean) => void;
    setDisplayOnlyWithSnapshots: (displayOnlyWithSnapshots: boolean) => void;
    setDisplayDisabledLines: (displayDisabledLines: boolean) => void;
    setPreloaderStatus: (preloader: boolean) => void;
    undoUnsavedChanges: () => void;
    redoUnsavedChanges: () => void;
    resetChangesHistory: () => void;
    setBudgetItemsToDisplay: (bugetItemsToDisplay: BudgetItemTypes) => void;
    setBudgetItemsApproversToDisplay: (budgetItemsApproversToDisplay: BudgetItemApproversToDisplay) => void;
    setNotification: (notification: NotificationMessage) => void;
    clearAllNotifications: () => void;
    updateInProgressData: () => void;
    initPreviouslyLoadedFilter: () => void;
    resetPreviouslyLoadedFilters: () => void;
    downloadXLSXTemplate: () => void;
    loadXLSXContent: (file: File) => void;
    loadPageData: (pageData: Partial<PageData>) => void;
    resetMiscBudgetItemsState: () => void;
    setShowTagsHaveChangedMarker: (payload: boolean) => void;
    setShowOnlyLinesWithPlanBudget: (payload: boolean) => void;
    setShowOnlyLinesWithoutPlanBudget: (payload: boolean) => void;
}

interface State {
    maxTableHeight: number;
    refetchInProgress: boolean;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BudgetPlanningContainer extends React.PureComponent<Props, State> {
    private root: HTMLDivElement;
    private tableWrapper: HTMLDivElement;
    private tableContent: Table;
    private userConfigUpdateTimer: NodeJS.Timeout;
    private tableLoader: TableLoader;
    private tableValidator: TableValidator;
    private tableSaver: TableSaver;
    private isComponentMounted: boolean;

    constructor(props: Props) {
        super(props);

        this.props.setRequestInProgress(true);

        this.state = {
            maxTableHeight: 0,
            refetchInProgress: false,
        };

        this.tableLoader = TableLoader.getInstance();
        this.tableValidator = TableValidator.getInstance();
        this.tableSaver = TableSaver.getInstance();
    }

    public async componentDidMount() {
        this.isComponentMounted = true;
        const queryParams = this.getQueryParams();

        await this.tableLoader.initPageData(true);

        this.props.clearUnsavedChanges();

        if (!lodash.isEmpty(queryParams)) {
            await this.tableLoader.initFromQuery(queryParams);
            this.removeQueryFromUrl();
        } else {
            await this.tableLoader.initFromUserConfig();
        }

        this.props.setRequestInProgress(false);

        this.updateMaxTableHeight();

        window.addEventListener('resize', this.onPageResize);
    }

    public async componentWillUnmount() {
        this.isComponentMounted = false;
        window.removeEventListener('resize', this.onPageResize);

        this.props.resetBudgetExecutionPage();
        this.props.resetMiscBudgetItemsState();
    }

    public async componentDidUpdate(prevProps: Props): Promise<void> {
        const columnsVisiblityFilterChanged = prevProps.columnsVisiblityFilter !== this.props.columnsVisiblityFilter;
        const columnsWidthChanged = prevProps.columnsWidth !== this.props.columnsWidth;
        const fixedColumnsNamesChanged = prevProps.fixedColumnsNames !== this.props.fixedColumnsNames;
        const sortingModeChanged = prevProps.sortingMode !== this.props.sortingMode;
        const filtersChanged = prevProps.filters !== this.props.filters;
        const changeListChanged = prevProps.unsavedChanges !== this.props.unsavedChanges;
        const budgetItemsToDisplayChanged = !lodash.isEqual(
            prevProps.budgetItemsToDisplay,
            this.props.budgetItemsToDisplay,
        );
        const budgetItemsApproversToDisplayChanged = !lodash.isEqual(
            prevProps.budgetItemsApproversToDisplay,
            this.props.budgetItemsApproversToDisplay,
        );
        const displayOnlyUnapprovedChanged = prevProps.displayOnlyUnapproved !== this.props.displayOnlyUnapproved;
        const displayOnlyWithSnapshotsChanged =
            prevProps.displayOnlyWithSnapshots !== this.props.displayOnlyWithSnapshots;
        const displayDisabledLinesChangd = prevProps.displayDisabledLines !== this.props.displayDisabledLines;
        const budgetIdHaveChanged = prevProps.budgetId !== this.props.budgetId;
        const budgetItemsToIgnoreFiltersChanged = !lodash.isEqual(
            prevProps.budgetItemsToIgnoreFilters,
            this.props.budgetItemsToIgnoreFilters,
        );
        const showOnlyLinesWithPlanBudgetHaveChanged =
            prevProps.showOnlyLinesWithPlanBudget !== this.props.showOnlyLinesWithPlanBudget;
        const showOnlyLinesWithoutPlanBudgetHaveChanged =
            prevProps.showOnlyLinesWithoutPlanBudget !== this.props.showOnlyLinesWithoutPlanBudget;

        if (budgetIdHaveChanged) {
            this.props.resetBudgetRelatedData();
            this.props.setPreloaderStatus(true);

            await new Promise<void>((resolve) => this.setState({ refetchInProgress: true }, resolve));

            this.tableContent?.resetScroll();
            this.tableLoader.initFilters();

            await Promise.all([this.tableLoader.initPageData(false), this.tableLoader.initFromUserConfig()]);

            await new Promise<void>((resolve) => this.setState({ refetchInProgress: false }, resolve));

            this.props.setPreloaderStatus(false);
        }

        if (
            !this.state.refetchInProgress &&
            (columnsVisiblityFilterChanged ||
                columnsWidthChanged ||
                fixedColumnsNamesChanged ||
                sortingModeChanged ||
                filtersChanged ||
                changeListChanged ||
                budgetItemsToDisplayChanged ||
                budgetItemsApproversToDisplayChanged ||
                displayOnlyUnapprovedChanged ||
                displayOnlyWithSnapshotsChanged ||
                displayDisabledLinesChangd ||
                budgetItemsToIgnoreFiltersChanged ||
                showOnlyLinesWithPlanBudgetHaveChanged ||
                showOnlyLinesWithoutPlanBudgetHaveChanged)
        ) {
            this.setUserConfigUpdateTimer();
        }

        // if (defaultFiltersChanged) {
        //     this.props.setFilters(this.mergeFilters(this.props.defaultFilters));
        // }
    }

    public render(): JSX.Element {
        const {
            lineIdsWithActualChanges,
            displayOnlyUnapproved,
            displayOnlyWithSnapshots,
            displayDisabledLines,
            canUndoUnsavedChanges,
            canRedoUnsavedChanges,
            columnsVisiblityFilter,
            budgetItemsToDisplay,
            showLineModal,
            filteredTableLines,
            budgetItemsApproversToDisplay,
            showOnlyLinesWithPlanBudget,
            showOnlyLinesWithoutPlanBudget,
        } = this.props;

        const columnsFilterItems = Object.keys(columnsVisiblityFilter).map((column, index) => ({
            id: column,
            title: ColumnsList.find((item) => item.name == column).title,
            value: columnsVisiblityFilter[column],
            order: index,
        }));

        const selectedColumnsFilterItems = Object.keys(columnsVisiblityFilter).filter(
            (column) => columnsVisiblityFilter[column],
        );

        const areFiltersApplies =
            displayOnlyUnapproved ||
            displayOnlyWithSnapshots ||
            displayDisabledLines ||
            Object.keys(budgetItemsToDisplay).some((filter) => budgetItemsToDisplay[filter]) ||
            !!selectedColumnsFilterItems.length;

        const sendButtonIsDisabled = filteredTableLines.every((line) => !line.canMoveToExpertApprovement);

        return React.createElement(BudgetPlanning, {
            dictionariesOrganizationId: this.props.dictionariesOrganizationId,
            budgetId: this.props.budgetId,
            preloader: this.props.preloader,
            localPreloader: this.props.localPreloader,
            userIsSupervisor: this.props.userIsSupervisor,
            pageContentHeight: this.root ? this.root.clientHeight : null,
            tableOffsetTop: this.tableWrapper ? this.tableWrapper.offsetTop : null,
            rootRef: this.rootRef,
            tableRef: this.tableRef,
            tableContentRef: this.tableContentRef,
            maxTableHeight: this.state.maxTableHeight,
            displayOnlyUnapproved,
            displayOnlyWithSnapshots,
            displayDisabledLines,
            scrollLeftColumn: this.getScrollLeftColumn(),
            sendButtonIsDisabled,
            showLineModal,
            onResetPageFiltersButtonClick: this.onResetPageFiltersButtonClick,
            onResetPageViewSettingsButtonClick: this.onResetPageViewSettingsButtonClick,
            onDisplayOnlyUnapprovedButtonClick: this.onDisplayOnlyUnapprovedButtonClick,
            onDisplayOnlyWithSnapshotsButtonClick: this.onDisplayOnlyWithSnapshotsButtonClick,
            onDisplayDisabledLinesButtonClick: this.onDisplayDisabledLinesButtonClick,
            onSaveButtonClick: this.onSaveButtonClick,
            onSendToApprovementButtonClick: this.onSendToApprovementButtonClick,
            onDownloadXLSXButtonClick: this.onDownloadXLSXButtonClick,
            onUndoButtonClick: this.onUndoButtonClick,
            disableUndoButton: !canUndoUnsavedChanges,
            onRedoButtonClick: this.onRedoButtonClick,
            disableRedoButton: !canRedoUnsavedChanges,
            disableResetPageViewSettingsButton: this.hasDisableResetPageViewSettingsButton(),
            onResetChangesHistoryClick: this.onResetChangesHistoryClick,
            disableResetChangesHistoryButton: !lineIdsWithActualChanges.length,
            columnsFilterItems,
            selectedColumnsFilterItems,
            onColumnsFilterItemSelect: this.onColumnsFilterItemSelect,
            budgetItemsToDisplay,
            budgetItemsApproversToDisplay,
            onBudgetItemsToDisplayChange: this.onBudgetItemsToDisplayChange,
            areFiltersApplies,
            onApplyFiltersButtonClick: this.onApplyFiltersButtonClick,
            isSaveButtonDisabled: this.isSaveButtonDisabled(),
            onDownloadXLSXTemplateButtonClick: this.onDownloadXLSXTemplateButtonClick,
            onLoadXLSXContentFileInput: this.onLoadXLSXContentFileInput,
            userCanEditOrCreateBudgetData: this.props.userCanEditOrCreateBudgetData,
            showOnlyLinesWithPlanBudget: showOnlyLinesWithPlanBudget,
            onShowOnlyLinesWithPlanBudgetToggle: this.onShowOnlyLinesWithPlanBudgetToggle,
            showOnlyLinesWithoutPlanBudget: showOnlyLinesWithoutPlanBudget,
            onShowOnlyLinesWithoutPlanBudgetToggle: this.onShowOnlyLinesWithoutPlanBudgetToggle,
        });
    }

    @autobind
    protected rootRef(element: HTMLDivElement) {
        this.root = element;
    }

    @autobind
    protected tableRef(element: HTMLDivElement) {
        this.tableWrapper = element;
    }

    @autobind
    protected tableContentRef(element: Table) {
        this.tableContent = element;
    }

    @autobind
    protected onPageResize() {
        this.updateMaxTableHeight();
    }

    @autobind
    protected onColumnsSelection(filters: ColumnsVisiblityFilter) {
        this.props.setColumnsVisiblityFilter(
            lodash.mapKeys(filters, (value, key) => ColumnsList.find((item) => item.title == key).name),
        );
    }

    @autobind
    protected onResetPageFiltersButtonClick() {
        this.props.loadBudgetItems([]);
        this.props.resetFilters();
        this.props.resetPreviouslyLoadedFilters();
    }

    @autobind
    protected onResetPageViewSettingsButtonClick() {
        this.props.resetViewSettings();
    }

    @autobind
    protected onDisplayOnlyUnapprovedButtonClick() {
        this.props.setDisplayOnlyUnapproved(!this.props.displayOnlyUnapproved);
    }

    @autobind
    protected onDisplayOnlyWithSnapshotsButtonClick() {
        this.props.setDisplayOnlyWithSnapshots(!this.props.displayOnlyWithSnapshots);
    }

    @autobind
    protected onDisplayDisabledLinesButtonClick() {
        this.props.setDisplayDisabledLines(!this.props.displayDisabledLines);
    }

    @autobind
    protected async onSaveButtonClick() {
        const { userIsBudgetExpert, lineIdsWithActualChanges, lineStatusChanges, approverStatusChanges, allLines } =
            this.props;

        const isValid = this.tableValidator.checkTableValidation();

        if (isValid) {
            this.props.setPreloaderStatus(true);

            const tableDataIsActual = await this.tableValidator.checkTableDataRelevance();

            if (tableDataIsActual) {
                this.clearAllNotify();

                await this.tableSaver.saveTable();
                await this.tableSaver.publishTable();

                if (userIsBudgetExpert) {
                    await this.tableSaver.applyTableApprovements();
                }

                await this.tableSaver.applyApproverStatuses();

                this.notifyAboutSaveAndPublish();

                const lineIdsToUpdate = lodash.uniq([
                    ...lineIdsWithActualChanges,
                    ...lodash.keys(lineStatusChanges),
                    ...lodash.keys(approverStatusChanges),
                    ...allLines.filter((line) => line.status === BudgetItemStatus.Draft).map((line) => line.id),
                ]);

                await this.tableLoader.updateActivitiesByLineIds(lineIdsToUpdate);

                this.props.resetChangesHistory();
                this.props.clearUnsavedChangesByLines(lineIdsWithActualChanges);
            } else {
                this.notifyLineDataRelevanceError();
            }

            this.props.setPreloaderStatus(false);
        } else {
            this.notifyAboutValidationError();
        }

        this.tableValidator.updateValidationDisplay();
    }

    @autobind
    protected async onSendToApprovementButtonClick() {
        const { budgetItems } = this.props;

        const isValid = this.tableValidator.checkTableValidation();

        if (isValid) {
            this.props.setPreloaderStatus(true);

            await this.tableSaver.sendLinesToExpertApprovement();

            this.notifyAboutSendTerm();

            const lineIdsToUpdate = budgetItems
                .filter((item) => item.status == BudgetItemStatus.Published && item.actions.canMoveToExpertApprovement)
                .map((item) => item.id);

            await this.tableLoader.updateActivitiesByLineIds(lineIdsToUpdate);

            this.props.clearUnsavedChangesByLines(lineIdsToUpdate);

            this.props.setPreloaderStatus(false);
        }

        this.tableValidator.updateValidationDisplay();
    }

    @autobind
    private async onDownloadXLSXButtonClick(useFilters: boolean): Promise<void> {
        this.props.setPreloaderStatus(true);
        const xlsxContent = await this.tableLoader.convertTableToXLSX(useFilters);
        Utils.downloadAsXLSX(xlsxContent, 'Планирование бюджета');
        this.props.setPreloaderStatus(false);
    }

    @autobind
    private onUndoButtonClick(): void {
        if (this.props.canUndoUnsavedChanges) {
            this.props.undoUnsavedChanges();
        }
    }

    @autobind
    private onRedoButtonClick(): void {
        if (this.props.canRedoUnsavedChanges) {
            this.props.redoUnsavedChanges();
        }
    }

    @autobind
    private onResetChangesHistoryClick(): void {
        if (this.props.lineIdsWithActualChanges.length) {
            this.props.resetChangesHistory();
            this.props.clearUnsavedChanges();
        }
    }

    @autobind
    private onColumnsFilterItemSelect(selectedItemIds: React.ReactText[]): void {
        this.props.setColumnsVisiblityFilter(
            ColumnsList.reduce(
                (acc, column) => ({
                    ...acc,
                    [column.name]: selectedItemIds.includes(column.name),
                }),
                {},
            ),
        );
    }

    @autobind
    private onBudgetItemsToDisplayChange(budgetItemsToDisplayIds: string[]): void {
        this.props.setBudgetItemsToDisplay({
            [BudgetItemStatus.Draft]: budgetItemsToDisplayIds.includes(BudgetItemStatus.Draft),
            [BudgetItemStatus.Published]: budgetItemsToDisplayIds.includes(BudgetItemStatus.Published),
            [BudgetItemStatus.OnExpertApprovement]: budgetItemsToDisplayIds.includes(
                BudgetItemStatus.OnExpertApprovement,
            ),
            [BudgetItemStatus.Approved]: budgetItemsToDisplayIds.includes(BudgetItemStatus.Approved),
            [BudgetItemStatus.Rejected]: budgetItemsToDisplayIds.includes(BudgetItemStatus.Rejected),
        });
        this.props.setBudgetItemsApproversToDisplay({
            [BudgetItemApproverStatus.Waiting]: budgetItemsToDisplayIds.includes(
                `approver-${BudgetItemApproverStatus.Waiting}`,
            ),
            [BudgetItemApproverStatus.Rejected]: budgetItemsToDisplayIds.includes(
                `approver-${BudgetItemApproverStatus.Rejected}`,
            ),
            [BudgetItemApproverStatus.Approved]: budgetItemsToDisplayIds.includes(
                `approver-${BudgetItemApproverStatus.Approved}`,
            ),
        });
    }

    @autobind
    private async onApplyFiltersButtonClick(): Promise<void> {
        this.updateUserConfig();

        this.props.initPreviouslyLoadedFilter();
        this.props.setPreloaderStatus(true);

        await this.tableLoader.loadDataByFilters();

        this.props.updateInProgressData();
        this.props.setPreloaderStatus(false);
        this.resetBudgetItemsToIgnoreFilters();

        this.props.setShowTagsHaveChangedMarker(false);
    }

    @autobind
    private onDownloadXLSXTemplateButtonClick(): void {
        this.props.downloadXLSXTemplate();
    }

    @autobind
    private onLoadXLSXContentFileInput(files: File[]): void {
        this.props.loadXLSXContent(files[0]);
    }

    // private mergeFilters(defaultFilters: Filters): Filters {
    //     const mergedFilters: Filters = lodash.clone(defaultFilters);

    //     lodash.forEach(this.props.filters, (columnFilters, columnName) => {
    //         lodash.forEach(columnFilters, (value, key) => {
    //             if (mergedFilters[columnName][key] !== undefined) {
    //                 mergedFilters[columnName][key] = value;
    //             }
    //         });
    //     });

    //     return mergedFilters;
    // }

    @autobind
    private onShowOnlyLinesWithPlanBudgetToggle(): void {
        this.props.setShowOnlyLinesWithPlanBudget(!this.props.showOnlyLinesWithPlanBudget);
    }

    @autobind
    private onShowOnlyLinesWithoutPlanBudgetToggle(): void {
        this.props.setShowOnlyLinesWithoutPlanBudget(!this.props.showOnlyLinesWithoutPlanBudget);
    }

    private setUserConfigUpdateTimer() {
        if (this.userConfigUpdateTimer) {
            clearTimeout(this.userConfigUpdateTimer);
        }

        this.userConfigUpdateTimer = setTimeout(this.updateUserConfig, USER_CONFIG_UPDATE_TIMEOUT);
    }

    @autobind
    private async updateUserConfig() {
        const { preloader, localPreloader } = this.props;
        const isLoading = preloader || localPreloader;

        if (!isLoading) {
            const userConfig = await this.makeUserConfig();
            await UserConfigApi.savePageConfig(UserConfigType.BudgetPlanning, userConfig);
        }
    }

    private async makeUserConfig(): Promise<PlanningTableUserConfig> {
        const userConfig = await this.tableLoader.getUserConfig();

        const {
            budgetId,
            columnsWidth,
            fixedColumnsNames,
            columnsVisiblityFilter,
            sortingMode,
            filters,
            unsavedChanges,
            budgetItemsToDisplay,
            displayDisabledLines,
            displayOnlyUnapproved,
            displayOnlyWithSnapshots,
            appliedFiltersNames,
            budgetItemsApproversToDisplay,
            budgetItemsToIgnoreFilters,
            showOnlyLinesWithPlanBudget,
            showOnlyLinesWithoutPlanBudget,
        } = this.props;

        return this.updateBudgetsKeysInUserConfig({
            ...userConfig,
            [budgetId]: {
                columnsWidth,
                fixedColumnsNames,
                columnsVisiblityFilter,
                sortingMode,
                filters,
                appliedFiltersNames,
                unsavedChanges: lodash.pickBy(unsavedChanges, (item) => !lodash.isEmpty(item)),
                budgetItemsToDisplay,
                displayDisabledLines,
                displayOnlyUnapproved,
                displayOnlyWithSnapshots,
                budgetItemsApproversToDisplay,
                budgetItemsToIgnoreFilters: budgetItemsToIgnoreFilters.map((budgetItem) => budgetItem.id),
                showOnlyLinesWithPlanBudget,
                showOnlyLinesWithoutPlanBudget,
            },
        });
    }

    private updateBudgetsKeysInUserConfig(userConfig: PlanningTableUserConfig): PlanningTableUserConfig {
        const { budgets } = this.props;
        const updatedUserConfig = {};

        const availableBudgetsIdsForCurrentUser = budgets.map(({ id }) => id);

        availableBudgetsIdsForCurrentUser.forEach((budgetId) => {
            if (userConfig[budgetId]) {
                updatedUserConfig[budgetId] = lodash.cloneDeep(userConfig[budgetId]);
            }
        });

        return updatedUserConfig;
    }

    private updateMaxTableHeight() {
        if (this.isComponentMounted) {
            const pageContentHeight = this.root.clientHeight;
            const tableOffsetTop = this.tableWrapper.offsetTop;

            const newMaxTableHeight = pageContentHeight - tableOffsetTop - TABLE_MARGIN_BOTTOM;

            if (newMaxTableHeight !== this.state.maxTableHeight) {
                this.setState({
                    maxTableHeight: newMaxTableHeight,
                });
            }
        }
    }

    private hasDisableResetPageViewSettingsButton(): boolean {
        return false;
    }

    private getQueryParams(): queryString.ParsedQuery {
        return queryString.parse(this.props.location.search);
    }

    private removeQueryFromUrl() {
        this.props.history.push('/budget/planning');
    }

    private getScrollLeftColumn(): ColumnName {
        const query = this.getQueryParams();

        return query.column as ColumnName;
    }

    private notifyAboutSendTerm(): void {
        this.setNotificationMessage(
            NotificationType.SUCCESS,
            NotificationActionType.SEND_TERM_ON_BUDGET_PLANNING_PAGE,
            'Строки отправлены на согласовани',
        );
    }

    private notifyAboutSaveAndPublish(): void {
        this.setNotificationMessage(
            NotificationType.SUCCESS,
            NotificationActionType.SAVE_AND_PUBLISH_ON_BUDGET_PLANNING_PAGE,
            'Изменения сохранены и опубликованы',
        );
    }

    private notifyAboutValidationError(): void {
        this.setNotificationMessage(
            NotificationType.ERROR,
            NotificationActionType.TABLE_VALIDATION_ON_BUDGET_PLANING_PAGE,
            'Нельзя опубликовать активность или строку с незаполненным обязательным полем, ' +
                'и название активности не должно быть меньше трех символов. Введите значение и опубликуйте еще раз.',
        );
    }

    private notifyLineDataRelevanceError() {
        this.setNotificationMessage(
            NotificationType.ERROR,
            NotificationActionType.DATA_IS_NOT_ACTIAL_ON_BUDGET_EXECUTION_PAGE,
            'В редактируемую строку были внесены изменения. Обновите страницу.',
        );
    }

    private clearAllNotify(): void {
        this.props.clearAllNotifications();
    }

    private setNotificationMessage(type: NotificationType, typeAction: NotificationActionType, comment: string): void {
        this.props.setNotification({ type, typeAction, comment });
    }

    private isSaveButtonDisabled(): boolean {
        const { filteredTableLines, lineIdsWithActualChanges, lineStatusChanges, approverStatusChanges } = this.props;

        const noActualChanges = !lineIdsWithActualChanges.length;
        const noStatusChanges = !filteredTableLines.some(
            (line) =>
                !!lineStatusChanges[line.id] ||
                !!approverStatusChanges[line.id] ||
                line.status === BudgetItemStatus.Draft,
        );
        const hasLineWithoutEditRights = filteredTableLines.some((line) => !line.canEdit);

        return hasLineWithoutEditRights || (noActualChanges && noStatusChanges);
    }

    private resetBudgetItemsToIgnoreFilters(): void {
        this.props.loadPageData({
            budgetItemsToIgnoreFilters: [],
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const {
        fixedColumnsNames,
        lineStatusChanges,
        approverStatusChanges,
        preloader,
        columnsWidth,
        columnFilters: { filters },
    } = getBudgetPlanningPageState(state);
    const { activityBudgets, budgetItemsToIgnoreFilters } = getPageData(state);
    const {
        columnsVisiblityFilter,
        sortingMode,
        displayOnlyUnapproved,
        displayOnlyWithSnapshots,
        displayDisabledLines,
        budgetItemsToDisplay,
        appliedFiltersNames,
        budgetItemsApproversToDisplay,
        showOnlyLinesWithPlanBudget,
        showOnlyLinesWithoutPlanBudget,
    } = getTableFilters(state);

    const { show } = getLineModalState(state);

    const user = getLoginUser(state);

    const userIsBudgetExpert = lodash.includes(user.attributes.permissions, PlanPermissionsSysNames.OrganizationAccess);
    const userIsSupervisor = lodash.includes(user.attributes.permissions, PlanPermissionsSysNames.DepartmentAccess);

    const { budgetId } = getBudgetByStatusUserConfig(state, BudgetStatus.Plan);

    const { budgets } = getBudgetState(state, BudgetStatus.Plan);
    const selectedBudget = budgets.find((budget) => budget.id === budgetId);

    const userCanEditOrCreateBudgetData =
        userCanEditOrCreateBudgetData_(state) && selectedBudget?.status === BudgetStatus.Plan;

    return {
        budgetId,
        budgets,
        preloader: isRequestInProgress(state),
        localPreloader: preloader,
        activityBudgets,
        budgetItems: getBudgetItems(state),
        fixedColumnsNames,
        sortingMode,
        appliedFiltersNames,
        defaultFilters: getDefaultFilters(state),
        filters,
        columnsWidth,
        unsavedChanges: getUnsavedChanges(state),
        displayOnlyUnapproved,
        displayOnlyWithSnapshots,
        displayDisabledLines,
        lineStatusChanges,
        approverStatusChanges,
        userIsBudgetExpert,
        userIsSupervisor,
        allLines: getTableLines(state),
        canUndoUnsavedChanges: canUndoUnsavedChanges(state),
        canRedoUnsavedChanges: canRedoUnsavedChanges(state),
        showLineModal: show,
        columnsVisiblityFilter,
        budgetItemsToDisplay,
        lineIdsWithActualChanges: getLineIdsWithActualChanges(state),
        dictionariesOrganizationId: selectedBudget ? selectedBudget.dictionaryOrganizationId : null,
        filteredTableLines: getFilteredTableLines(state),
        budgetItemsApproversToDisplay,
        budgetItemsToIgnoreFilters,
        userCanEditOrCreateBudgetData,
        showOnlyLinesWithPlanBudget,
        showOnlyLinesWithoutPlanBudget,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setRequestInProgress,
            clearUnsavedChanges,
            clearUnsavedChangesByLines,
            resetBudgetExecutionPage: resetPageStore,
            setColumnsVisiblityFilter,
            setFilters,
            resetFilters,
            resetViewSettings,
            setDisplayOnlyUnapproved,
            setDisplayOnlyWithSnapshots,
            setDisplayDisabledLines,
            setPreloaderStatus,
            undoUnsavedChanges,
            redoUnsavedChanges,
            resetChangesHistory,
            setBudgetItemsToDisplay,
            setBudgetItemsApproversToDisplay,
            setNotification,
            clearAllNotifications,
            updateInProgressData,
            loadBudgetItems,
            resetPreviouslyLoadedFilters,
            initPreviouslyLoadedFilter,
            resetBudgetRelatedData,
            downloadXLSXTemplate: () => downloadXLSXTemplate(null),
            loadXLSXContent,
            loadPageData,
            resetMiscBudgetItemsState,
            setShowTagsHaveChangedMarker,
            setShowOnlyLinesWithPlanBudget,
            setShowOnlyLinesWithoutPlanBudget,
        },
        dispatch,
    );
}
