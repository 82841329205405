import * as React from 'react';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { OrganizationGroups } from '@store/calendar/types';
import { Period } from '@store/calendar/types';

import type { ScrollbarComponent } from 'sber-marketing-ui';
import { ChartList } from './ChartList';
import type { StoreState } from '@store';
import { getCalendarPageState, getFilters } from '@store/calendar/selectors';
import { getLoginUser } from '@store/user/selector';
import { GroupsFilter, SceneDrawer, Scroller, Scaler } from '../modules';

interface Props extends Partial<MapProps> {}

interface MapProps {
    period: Period;
    displayOrganizationNames: boolean;
}

interface State {
    organizationGroups: OrganizationGroups[];
    fullWidth: number;
}

@(connect(mapStateToProps, null) as any)
export class ChartListContainer extends React.PureComponent<Props, State> {
    private root: HTMLDivElement;
    private scrollbars: { [groupId: string]: ScrollbarComponent } = {};
    private groupsFilter: GroupsFilter;
    private sceneDrawer: SceneDrawer;
    private scroller: Scroller;
    private scaler: Scaler;

    constructor(props: Props) {
        super(props);

        this.groupsFilter = GroupsFilter.getInstance();
        this.sceneDrawer = SceneDrawer.getInstance();
        this.scroller = Scroller.getInstance();
        this.scaler = Scaler.getInstance();

        this.groupsFilter.register('chartList', this.onActivityGroupsChange);

        this.state = {
            organizationGroups: this.groupsFilter.getFilteredGroups(),
            fullWidth: 0,
        };
    }

    public componentDidMount() {
        window.addEventListener('resize', this.onPageResize);
        this.sceneDrawer.startWatchingPageScroll();

        this.drawScene();
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.onPageResize);
        this.sceneDrawer.stopWatchingPageScroll();

        this.sceneDrawer.dispose();
        this.scroller.dispose();
        this.scaler.dispose();
    }

    public componentDidUpdate(prevProps: Props) {
        const periodChanged = this.props.period !== prevProps.period;

        if (periodChanged) {
            this.updateFullWidth();
        }
    }

    public render(): JSX.Element {
        return React.createElement(ChartList, {
            organizationGroups: this.state.organizationGroups,
            displayOrganizationNames: this.props.displayOrganizationNames,
            fullWidth: this.state.fullWidth,
            viewportWidth: this.getViewportWidth(),
            rootRef: this.rootRef,
            canvasRef: this.canvasRef,
            scrollbarRef: this.scrollbarRef,
            onScroll: this.onScroll,
        });
    }

    @autobind
    private rootRef(element: HTMLDivElement) {
        this.root = element;

        this.updateViewportWidth();
        this.updateFullWidth();
    }

    @autobind
    private canvasRef(element: HTMLCanvasElement, groupId: string) {
        this.sceneDrawer.addCanvas(groupId, element);
    }

    @autobind
    private scrollbarRef(component: ScrollbarComponent, groupId: string) {
        this.scrollbars[groupId] = component;

        if (component) {
            this.updateScrollLeft(groupId);

            this.scroller.register(groupId, (scrollValue: number) => this.onScrollerEmit(groupId, scrollValue));
        }
    }

    @autobind
    private onScroll(scrollLeft: number, groupId: string) {
        const viewportWidth = this.getViewportWidth();

        this.scroller.setScroll(groupId, scrollLeft / viewportWidth);
    }

    @autobind
    private onScrollerEmit(groupId: string, scrollValue: number) {
        if (this.scrollbars[groupId]) {
            const viewportWidth = this.getViewportWidth();

            this.scrollbars[groupId].scrollLeft = scrollValue * viewportWidth;

            this.updateScrollLeft(groupId);
        }
    }

    @autobind
    private onPageResize() {
        this.updateViewportWidth();
        this.updateFullWidth();
        this.updateAllScrollsLeft();
    }

    @autobind
    private onActivityGroupsChange(organizationGroups: OrganizationGroups[]) {
        this.setState(
            {
                organizationGroups,
            },
            this.drawScene,
        );
    }

    @autobind
    private drawScene() {
        this.sceneDrawer.drawScene();
    }

    private updateFullWidth() {
        if (this.root) {
            this.setState(
                {
                    fullWidth: this.scaler.getFullWidthInPx(),
                },
                this.drawScene,
            );
        }
    }

    private updateAllScrollsLeft() {
        this.state.organizationGroups.forEach((organizationGroup) => {
            organizationGroup.groups.forEach((item) => {
                this.updateScrollLeft(item.id);
            });
        });
    }

    private updateScrollLeft(groupId: string) {
        const viewportWidth = this.getViewportWidth();

        const { currentScroll } = this.scroller;

        this.scrollbars[groupId].scrollLeft = currentScroll * viewportWidth;
    }

    private updateViewportWidth() {
        if (this.root) {
            this.scaler.setViewportWidthInPx(this.root.offsetWidth);
        }
    }

    private getViewportWidth(): number {
        return this.scaler.getViewportWidthInPx();
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { period } = getCalendarPageState(state);
    const { organizationIds } = getFilters(state);

    const user = getLoginUser(state);
    const userOrganizationId = user.attributes.organizationId;

    return {
        period,
        displayOrganizationNames: organizationIds.length > 1 || lodash.first(organizationIds) !== userOrganizationId,
    };
}
