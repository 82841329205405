import * as React from 'react';
import classNames from 'classnames';

import * as style from './Switch.scss';

import { Toggle, TogglePosition } from 'sber-marketing-ui';

interface Props {
    togglePosition: TogglePosition;
    onToggleClick: () => void;
    onTitleClick: (value: TogglePosition) => void;
}

export const Switch = ({ togglePosition, onToggleClick, onTitleClick }: Props): JSX.Element => {
    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'dashboardCardGroupSwitch',
            }}
        >
            <div
                className={classNames(style.title, togglePosition == TogglePosition.LEFT && style.selected)}
                onClick={() => onTitleClick(TogglePosition.LEFT)}
            >
                текущие
            </div>

            <div className={style.toggleWrapper}>
                <Toggle position={togglePosition} onClick={onToggleClick} />
            </div>

            <div
                className={classNames(style.title, togglePosition == TogglePosition.RIGHT && style.selected)}
                onClick={() => onTitleClick(TogglePosition.RIGHT)}
            >
                архив
            </div>
        </div>
    );
};
