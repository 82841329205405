import * as React from 'react';
import { CustomScrollbar_new as CustomScrollbar } from 'sber-marketing-ui';

import { ChildrenProps } from './ActivitySuggestConnected';
import { ExistingNameItem, ExistingNameItemStub } from './ExistingNameItem';

import * as styles from './ActivitySuggest.scss';

interface Props extends ChildrenProps {
    isNameInputFocused: boolean;
    pageIsInCopyFromExecutionMode: boolean;
    setActivityIsAlreadyExists(activity: { id: string; name: string }): void;
}

export function ActivitySuggestTemplate({
    loading,
    budgetItems,
    isNameInputFocused,
    pageIsInCopyFromExecutionMode,
    setActivityIsAlreadyExists,
}: Props): JSX.Element {
    const renderContent = isNameInputFocused && (budgetItems.length || loading);

    return renderContent ? (
        <CustomScrollbar minHeight={46} maxHeight={245} freezeScrollX hideScrollX>
            <div className={styles.existingNamesWrapper}>
                {loading && (
                    <div className={styles.stubWrapper}>
                        <ExistingNameItemStub />
                    </div>
                )}

                {budgetItems.map((budgetItem) => (
                    <ExistingNameItem
                        key={`${budgetItem.budget.year}-${budgetItem.id}-${budgetItem.activity.id}`}
                        pageIsInCopyFromExecutionMode={pageIsInCopyFromExecutionMode}
                        setActivityIsAlreadyExists={setActivityIsAlreadyExists}
                        {...budgetItem}
                    />
                ))}
            </div>
        </CustomScrollbar>
    ) : null;
}
