import { actionCreatorFactory } from 'typescript-fsa';
import { UserConfigType } from 'sber-marketing-types/openid';

import { SaveUserConfig, AllUserConfigs } from './types';

const actionCreator = actionCreatorFactory('USER_CONFIG');

const loadUserConfig = actionCreator.async<UserConfigType, void, Error>('LOAD_USER_CONFIG');
const saveUserConfig = actionCreator.async<SaveUserConfig<AllUserConfigs>, void, Error>('SAVE_USER_CONFIG');
const updateUserConfig = actionCreator.async<AllUserConfigs, void, Error>('UPDATE_USER_CONFIG');

export { loadUserConfig, saveUserConfig, updateUserConfig };
