import * as React from 'react';
import classNames from 'classnames';
import { HeightAnimation, Icon, IconType, AnimatedEllipsis, Skeleton } from 'sber-marketing-ui';

import { CommentSearchResult } from '@store/search';

import { HighlightQuery } from '../../HighlightQuery';
import { AttachedFile } from './AttachedFile';

import * as styles from './CommentContent.scss';

export enum ContentState {
    EXPANDED = 'EXPANDED',
    NON_EXPANDED = 'NON_EXPANDED',
    BOTH = 'BOTH',
}

interface Props extends CommentSearchResult {
    showToggleStateButton: boolean;
    contentState: ContentState;
    isDownloadingInProgress: boolean;
    onToggleStateButtonClick: () => void;
    nonExpandedContentRef: (el: HTMLDivElement) => void;
    expandedContentRef: (el: HTMLDivElement) => void;
    onDownloadAllButtonClick: () => void;
}

export function CommentContent({
    showToggleStateButton,
    isDownloadingInProgress,
    contentState,
    id,
    text,
    files,
    activity,
    task,
    nonExpandedContentRef,
    expandedContentRef,
    onToggleStateButtonClick,
    onDownloadAllButtonClick,
}: Props): JSX.Element {
    const isExpanded = contentState === ContentState.EXPANDED || contentState === ContentState.BOTH;
    const isNonExpanded = contentState === ContentState.NON_EXPANDED || contentState === ContentState.BOTH;
    const mentionlessText = text && text.split(/\[|\#\d*\]/g).join('');

    return (
        <div className={styles.root}>
            <div className={styles.row}>
                <Skeleton isReady={text} className={styles.skeleton}>
                    <div className={styles.heightAnimationWrapper}>
                        <HeightAnimation>
                            <div
                                className={styles.contentContainer}
                                {...{
                                    'qa-id': 'taskCArdCommentText',
                                }}
                            >
                                {isNonExpanded && (
                                    <HighlightQuery text={mentionlessText} elRef={nonExpandedContentRef} />
                                )}

                                {isExpanded && (
                                    <HighlightQuery multiline text={mentionlessText} elRef={expandedContentRef} />
                                )}

                                <DownloadAllButton
                                    isDownloadingInProgress={isDownloadingInProgress}
                                    isExpanded={isExpanded}
                                    commentIncludesFiles={files && files.length > 1}
                                    onClick={onDownloadAllButtonClick}
                                />
                            </div>
                        </HeightAnimation>
                    </div>

                    <div className={classNames(styles.text, styles.controls)}>
                        {files && files.length && (
                            <React.Fragment>
                                <Icon type={IconType.ATTACHMENT_ICON} svgSize={24} />

                                {files.length}
                            </React.Fragment>
                        )}

                        {showToggleStateButton && (
                            <div
                                {...{
                                    'qa-id': 'taskCommentCardExpandButton',
                                }}
                            >
                                <Icon
                                    type={IconType.EXPAND_ICON}
                                    svgSize={24}
                                    className={classNames(
                                        styles.toggleStateButton,
                                        isExpanded && styles.toggleStateButton_active,
                                    )}
                                    onClick={onToggleStateButtonClick}
                                />
                            </div>
                        )}
                    </div>
                </Skeleton>
            </div>

            {isExpanded && files && files.length && (
                <div className={styles.attachedFilesContainer}>
                    {files.map((file) => (
                        <div key={file.id} className={styles.attachedFile}>
                            <AttachedFile {...file} activityId={String(activity.id)} taskId={task.id} commentId={id} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

interface DownloadAllButtonProps {
    isDownloadingInProgress: boolean;
    isExpanded: boolean;
    commentIncludesFiles: boolean;
    onClick: () => void;
}

function DownloadAllButton({
    isDownloadingInProgress,
    isExpanded,
    commentIncludesFiles,
    onClick,
}: DownloadAllButtonProps): JSX.Element {
    if (isExpanded) {
        if (isDownloadingInProgress) {
            return (
                <div className={styles.downloadAllFiles}>
                    <AnimatedEllipsis text="скачивается" />
                </div>
            );
        }

        if (commentIncludesFiles) {
            return (
                <div
                    className={classNames(styles.downloadAllFiles, styles.downloadAllFilesButton)}
                    onClick={onClick}
                    {...{
                        'qa-id': 'taskCommentDownloadAllButton',
                    }}
                >
                    скачать все файлы
                </div>
            );
        }
    }

    return null;
}
