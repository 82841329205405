import * as React from 'react';
import { debounce } from 'lodash';

import { Icon, IconType } from 'sber-marketing-ui';
import type { ItemType } from './List';
import { List } from './List';
import * as styles from './styles.scss';

interface Props {
    items: ItemType[];
    selectedItemId: string;
    onItemClick: (id: string) => void;
}

export const Dropdown: React.FC<Props> = ({ items, selectedItemId: initSelectedItemId, onItemClick }) => {
    const [open, setOpen] = React.useState(false);
    const [hover, setHover] = React.useState<boolean>(false);
    const [selectedItemId, setSelectedItemId] = React.useState(initSelectedItemId);

    const delay = React.useCallback(
        debounce((callback: () => void) => callback(), 1000),
        [],
    );

    React.useEffect(() => {
        delay(() => {
            if (!hover) {
                setOpen(false);
            }
        });
    }, [hover]);

    const onClickTrigger = React.useCallback(() => {
        setOpen(!open);
        setHover(!hover);
    }, [open, hover]);

    const onClickListItem = React.useCallback(
        (itemId: string) => {
            setOpen(false);
            setHover(false);
            setSelectedItemId(itemId);
            onItemClick(itemId);
        },
        [onItemClick],
    );

    const onMouseEnterList = React.useCallback(() => {
        setHover(true);
    }, []);

    const onMouseEnterTrigger = React.useCallback(() => {
        setHover(true);
    }, []);

    const onMouseLeave = React.useCallback(
        (event) => {
            setHover(false);
        },
        [hover, setHover, setOpen],
    );

    return (
        <div className={styles.root} onMouseLeave={onMouseLeave}>
            <div className={styles.trigger} onClick={onClickTrigger} onMouseEnter={onMouseEnterTrigger}>
                <div>{items.find(({ id }) => id === selectedItemId).title}</div>
                <div className={open ? styles.openIcon : ''}>
                    <Icon type={IconType.ARROW16} svgSize={18} />
                </div>
            </div>

            {open && (
                <div className={styles.listWrapper} onMouseEnter={onMouseEnterList}>
                    <List items={items} onClick={onClickListItem} selectedItemId={selectedItemId} />
                </div>
            )}
        </div>
    );
};

export type { ItemType };
