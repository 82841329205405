import * as React from 'react';
import { WithTooltip, TooltipAnchor } from 'sber-marketing-ui';

import { Money, MoneyFormatter } from '@common/Utils';

import * as styles from './CopyableValue.scss';

interface Props {
    value: number;
}

export function CopyableValue({ value }: Props): JSX.Element {
    const [isCopied, setIsCopied] = React.useState(false);

    async function copy() {
        const valueTruncated = +(value / 100.0).toFixed(2);
        await navigator.clipboard.writeText(`${valueTruncated}`);
        setIsCopied(true);
    }

    return (
        <span
            className={styles.root}
            onMouseLeave={() => setIsCopied(false)}
            onClick={copy}
            {...{
                'qa-id': 'internalTransitionSumEnteringSumHelperCopyablePlanReserveDiff',
            }}
        >
            <WithTooltip
                content={isCopied ? 'Скопировано' : 'Нажмите, чтобы скопировать сумму'}
                anchor={TooltipAnchor.RIGHT}
            >
                <React.Fragment>{MoneyFormatter.toMostAppropriate(Money.fromCopecks(value))}</React.Fragment>
            </WithTooltip>
        </span>
    );
}
