import * as React from 'react';
import classNames from 'classnames';
import { Textarea_redesign as Textarea, Textarea_theme as TextareaThemes, Icon, IconType } from 'sber-marketing-ui';

import * as styles from './CommentInput.scss';

interface Props {
    text: string;
    isFocused: boolean;
    isValid: boolean;
    refObject: React.RefObject<HTMLDivElement>;
    onCancelButtonClick: () => void;
    onWrapperMouseOver: () => void;
    onWrapperMouseOut: () => void;
    onInputChange: (value: string) => void;
    onInputFocus: () => void;
    onInputBlur: () => void;
    onSendIconClick: () => void;
    onSendIconMouseOver: () => void;
    onSendIconMouseOut: () => void;
}

export function CommentInput({
    text,
    isFocused,
    isValid,
    refObject,
    onCancelButtonClick,
    onWrapperMouseOver,
    onWrapperMouseOut,
    onInputChange,
    onInputFocus,
    onInputBlur,
    onSendIconClick,
    onSendIconMouseOver,
    onSendIconMouseOut,
}: Props): JSX.Element {
    return (
        <div
            className={classNames(styles.root, !isValid && styles.inputWrapperError)}
            ref={refObject}
            {...{
                'qa-id': 'budgetPlanningLineModalBudgetItemStatusCommentInput',
            }}
        >
            <div
                className={classNames(styles.icon, styles.crossIcon)}
                {...{
                    'qa-id': 'budgetPlanningLineModalBudgetItemStatusCommentInputCancelButton',
                }}
            >
                <Icon type={IconType.CIRCLE_BOLD_CROSS} onClick={onCancelButtonClick} svgSize={18} />
            </div>

            <div
                className={classNames(styles.inputWrapper, isFocused && styles.inputWrapperFocused)}
                onMouseOver={onWrapperMouseOver}
                onMouseOut={onWrapperMouseOut}
            >
                <div className={styles.input}>
                    <Textarea
                        qaId="budgetPlanningLineModalBudgetItemStatusCommentInputTextarea"
                        value={text}
                        placeholder="Причина отклонения…"
                        theme={TextareaThemes.REGULAR}
                        autoFocus
                        onChange={onInputChange}
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                    />
                </div>

                <div
                    className={classNames(styles.icon, styles.sendIcon)}
                    {...{
                        'qa-id': 'budgetPlanningLineModalBudgetItemStatusCommentInputSendButton',
                    }}
                >
                    <Icon
                        type={IconType.SEND_ICON}
                        svgSize={24}
                        onClick={onSendIconClick}
                        onMouseOver={onSendIconMouseOver}
                        onMouseOut={onSendIconMouseOut}
                    />
                </div>
            </div>
        </div>
    );
}
