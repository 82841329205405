import * as React from 'react';
import { groupBy } from 'lodash';
import { FilesCache } from '../hooks';
import { useEffect, useState } from 'react';
import { FileGroupByRow } from './FileGroupByRow';
import * as styles from './FileGroupByTable.scss';
import { DownloadAllButton } from '../DownloadAllButton';

interface Props {
    files: FilesCache;
}

export const FileGroupByTable = ({ files }: Props): JSX.Element => {
    const [groupedFilesByTable, setGroupedFilesByTable] = useState<{ [tableName: string]: FilesCache }>({});

    useEffect(() => {
        const groupedFilesByTable = groupBy(files, (file) => file.itemGroup.value);
        setGroupedFilesByTable(groupedFilesByTable);
    }, [files]);

    return (
        <>
            <div className={styles.titleContainer}>
                <div className={styles.title}>
                    <div>Тариф</div>
                    {groupedFilesByTable['Тариф']?.length && (
                        <div className={styles.titleCounter}>{groupedFilesByTable['Тариф']?.length}</div>
                    )}
                </div>
                <DownloadAllButton fileGroupTitle={'Тариф'} files={groupedFilesByTable['Тариф']} />
            </div>
            <FileGroupByRow files={groupedFilesByTable['Тариф']} tableName={'Тариф'} />

            <div className={styles.titleContainer}>
                <div className={styles.title}>
                    <div>Производство</div>
                    {groupedFilesByTable['Производство']?.length && (
                        <div className={styles.titleCounter}>{groupedFilesByTable['Производство']?.length}</div>
                    )}
                </div>
                <DownloadAllButton fileGroupTitle={'Производство'} files={groupedFilesByTable['Производство']} />
            </div>
            <FileGroupByRow files={groupedFilesByTable['Производство']} tableName={'Производство'} />
        </>
    );
};
