import { bindActionCreators } from 'redux';
import * as lodash from 'lodash';

import { Brief, BriefScheme } from 'sber-marketing-types/frontend';
import { FormData } from '@store/plannedBudgetEdit/types';
import type { UpdateSchemesParams } from './types';

import { store } from '@store';
import { setBriefs, setBriefsData, setChangedBrief, setBriefSchemes } from '@store/plannedBudgetEdit/actions';
import { add as addFiles } from '@store/fileAssets';
import { BriefApi, FileApi } from '@api';

const IMAGE_SERVICE_STORAGE_NAME = 'imageService';

interface ConstructorParams {
    isCopyMode: boolean;
    budgetItemForms: FormData[];
    organizationId: string;
    briefsIds: string[];
}

export class BriefsLoader {
    private static instance: BriefsLoader;
    private isCopyMode: boolean;

    private organizationId: string;
    private briefsIds: string[];
    private budgetItemForms: FormData[];

    private briefs: Brief[] = [];
    private schemes: BriefScheme[] = [];

    private dispatch = bindActionCreators(
        {
            setBriefs,
            setBriefsData,
            setChangedBrief,
            setBriefSchemes,
            addFiles,
        },
        store.dispatch,
    );

    private constructor(params: ConstructorParams) {
        this.organizationId = params.organizationId;
        this.briefsIds = params.briefsIds;
        this.isCopyMode = params.isCopyMode;
        this.budgetItemForms = params.budgetItemForms;
    }

    public static getInstance(params: ConstructorParams): BriefsLoader {
        if (!BriefsLoader.instance) {
            BriefsLoader.instance = new BriefsLoader(params);
        }

        BriefsLoader.instance.briefsIds = params.briefsIds;
        BriefsLoader.instance.organizationId = params.organizationId;
        BriefsLoader.instance.isCopyMode = params.isCopyMode;
        BriefsLoader.instance.budgetItemForms = params.budgetItemForms;

        return BriefsLoader.instance;
    }

    public async init() {
        await this.loadBriefs();
        await this.loadSchemes();

        if (this.isCopyMode) {
            await this.createBriefsFromCurrentBrief();
        }

        this.dispatchLoadedDataToStore();
        this.resetLoadedData();
    }

    public async updateSchemes(params: UpdateSchemesParams): Promise<void> {
        this.briefs = params.briefs;

        await this.loadSchemes();

        this.dispatchLoadedSchemesToStore();
        this.resetLoadedData();
    }

    private resetLoadedData(): void {
        this.briefs = [];
        this.schemes = [];
    }

    private async loadBriefs() {
        await Promise.all(
            this.briefsIds.map(async (id) => {
                const brief = await BriefApi.getBrief(id);
                const updatedBrief = this.formatFilesData(brief);
                this.briefs.push(updatedBrief);
            }),
        );
    }

    private async loadSchemes() {
        this.schemes = await BriefApi.getBriefSchemeList({
            organizationId: this.organizationId,
            name: 'ДУД',
        });

        const briefsSchemesIds = this.getBriefsSchemesIdsFromBriefs();
        const loadedSchemesIds = this.schemes.map(({ id }) => id);

        const unloadedSchemesId = lodash.difference(briefsSchemesIds, loadedSchemesIds);

        await Promise.all(
            unloadedSchemesId.map(async (schemeId) => {
                const currentScheme = await BriefApi.getBriefSchemeById(schemeId);
                this.schemes.push(currentScheme);
            }),
        );
    }

    private async createBriefsFromCurrentBrief(): Promise<void> {
        const newBriefs: Brief[] = [];

        this.budgetItemForms = await Promise.all(
            this.budgetItemForms.map(async (budgetItemForm) => {
                const briefId = budgetItemForm && budgetItemForm.briefId;

                if (briefId) {
                    const brief = this.briefs.find((brief) => briefId === brief.id);
                    const createdBrief = await BriefApi.createBrief({ schemeId: brief.schemeId });

                    const newBrief = {
                        ...createdBrief,
                        blocks: [...brief.blocks],
                    };

                    await BriefApi.updateBrief(newBrief);

                    await this.updateBriefWithUpdateFiles({
                        changedBrief: newBrief,
                        copiedBrief: brief,
                    });

                    newBriefs.push(newBrief);

                    return {
                        ...budgetItemForm,
                        briefId: createdBrief.id,
                    };
                }

                return budgetItemForm;
            }),
        );

        this.briefs = newBriefs;
    }

    private getBriefsSchemesIdsFromBriefs(): string[] {
        return lodash.compact(this.briefs.map(({ schemeId }) => schemeId));
    }

    private async updateBriefWithUpdateFiles({
        changedBrief,
        copiedBrief,
    }: {
        changedBrief: Brief;
        copiedBrief: Brief;
    }): Promise<void> {
        const notImagesFilesIds: string[] = this.getNotImageFilesIdsFromBrief(changedBrief);

        if (copiedBrief && copiedBrief.id) {
            const filesParams = notImagesFilesIds.map((fileIid) => ({
                briefId: changedBrief.id,
                oldContainerName: `brief-${copiedBrief.id}`,
                oldFileName: fileIid,
                newContainerName: `brief-${changedBrief.id}`,
                newFileName: fileIid,
            }));

            await Promise.all(filesParams.map((params) => BriefApi.updateFileBrief(params)));
        }
    }

    private getNotImageFilesIdsFromBrief(brief: Brief): string[] {
        const notFilesImagesIds: string[] = [];
        const filesImagesIds: string[] = [];

        brief.blocks.forEach((block) => {
            block.fields.forEach((field) => {
                if (field.value && field.value.files) {
                    field.value.files.forEach((file) => {
                        const isFileImage = file.storage === IMAGE_SERVICE_STORAGE_NAME;

                        if (isFileImage) {
                            filesImagesIds.push(String(file.id));
                        }
                    });
                }
            });
        });

        brief.blocks.forEach((block) => {
            block.fields.forEach((field) => {
                if (field.value && field.value.files) {
                    field.value.files.forEach((file) => {
                        const notFileImage = !lodash.includes(filesImagesIds, String(file.id));

                        if (notFileImage) {
                            notFilesImagesIds.push(String(file.id));
                        }
                    });
                }
            });
        });

        return notFilesImagesIds;
    }

    private formatFilesData(brief: Brief) {
        const updatedBrief = lodash.cloneDeep(brief);

        updatedBrief.blocks.forEach((block) =>
            block.fields.forEach((field) => {
                if (field.value && field.value.files) {
                    const files = FileApi.mapFiles(
                        {
                            fieldId: field.id,
                            briefId: brief.id,
                        },
                        field.value.files as any[],
                        '',
                    ) as any[];
                    field.value.files = files;
                    this.dispatch.addFiles(...files);
                }
            }),
        );

        return updatedBrief;
    }

    private dispatchLoadedDataToStore(): void {
        this.dispatch.setBriefsData({
            originalBriefs: lodash.keyBy(lodash.cloneDeep(this.briefs), 'id'),
            changedBriefs: lodash.keyBy(lodash.cloneDeep(this.briefs), 'id'),
            briefsLoading: lodash.keyBy(
                this.briefs.map(({ id }) => ({ id, loading: false })),
                'id',
            ),
            briefsSchemes: this.schemes,
            budgetItemForms: this.budgetItemForms,
        });
    }

    private dispatchLoadedSchemesToStore(): void {
        this.dispatch.setBriefSchemes(this.schemes);
    }
}
