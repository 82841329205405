import * as React from 'react';
import classNames from 'classnames';
import * as lodash from 'lodash';

import * as style from './BudgetList.scss';

import { FormData } from '@store/executionBudgetEdit/types';
import { MultiReferenceDictionaryApi } from '@api';

import { BudgetCard } from './BudgetCard';

export interface BudgetListProps {
    budgetId: string;
    savedBudgetItemsIds: string[];
    forms: FormData[];
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi;
}

export const BudgetList = ({
    budgetId,
    savedBudgetItemsIds,
    forms,
    multiReferenceDictionaryApi,
}: BudgetListProps): JSX.Element => {
    return (
        <div className={style.root}>
            {forms.map((item, index) => (
                <div key={item.id} className={classNames(style.budgetCard, 'section', `budget${index}`)}>
                    <BudgetCard
                        id={item.id}
                        budgetId={budgetId}
                        index={index}
                        fields={item.fields}
                        collapsed={item.collapsed}
                        isNew={item.isNew}
                        canBeDeleted={forms.length > 1 && !lodash.includes(savedBudgetItemsIds, item.id)}
                        multiReferenceDictionaryApi={multiReferenceDictionaryApi}
                    />
                </div>
            ))}
        </div>
    );
};
