import * as React from 'react';
import classNames from 'classnames';
import * as Dotdotdot from 'react-dotdotdot';
import { Icon, IconType, WithTooltip, StaticSkeleton } from 'sber-marketing-ui';
import { TaskCardParams } from 'sber-marketing-types/frontend';

import { Stage } from '@store/activityTasksPage/stages';

import { StagesCalendar } from '@common/StagesCalendar';

import { StagesEditorPopup } from './StagesEditorPopup';

import * as styles from './ActivityStages.scss';

export enum States {
    StagelessActivity,
    NoAssignedStages,
    ActivityWithStages,
}

interface Props extends StateProps {
    showPreloader: boolean;
    pageState: States;
    showStagesEditorPopup: boolean;
}

interface StateProps {
    projectStart: Date;
    projectEnd: Date;
    stages?: Stage[];
    canEditStages?: boolean;
    templateName?: string;
    tasksByStageId: {
        [stageId: string]: TaskCardParams[];
    };
    onOpenStagesEditorPopupClick: () => void;
    onSaveActivityAsStagelessButtonClick: () => void;
    onStageStatusChange?: (stageId: string, done: boolean) => void;
    onSelectStageClick?: (stageId: string) => void;
    createTaskByNameAndStage(taskName: string, stageId: string): Promise<void>;
}

export function ActivityStages({ showPreloader, pageState, showStagesEditorPopup, ...props }: Props): JSX.Element {
    let content = null;

    if (!showPreloader) {
        switch (pageState) {
            case States.StagelessActivity:
                content = <StagelessActivityState {...props} />;
                break;
            case States.NoAssignedStages:
                content = <NoAssignedStagesState {...props} />;
                break;
            case States.ActivityWithStages:
                content = <ActivityWithStagesState {...props} />;
                break;
            default:
                content = null;
        }
    }

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'activityStages',
            }}
        >
            {content}

            {showStagesEditorPopup && <StagesEditorPopup />}

            {showPreloader && <Preloader />}
        </div>
    );
}

function StagelessActivityState({ canEditStages, onOpenStagesEditorPopupClick }: StateProps): JSX.Element {
    return (
        <div className={styles.statusRow}>
            <div className={classNames(styles.status, styles.status_color_green)}>
                <div className={styles.statusIcon}>
                    <Icon type={IconType.PROJECT_STAGES_NO_STAGES_BUTTON} svgSize={24} />
                </div>

                <div
                    className={styles.statusTitle}
                    {...{
                        'qa-id': 'activityStagesStatus',
                    }}
                >
                    Проект без этапов
                </div>
            </div>

            {canEditStages && (
                <div className={styles.buttons}>
                    <div
                        className={styles.button}
                        onClick={onOpenStagesEditorPopupClick}
                        {...{
                            'qa-id': 'activityStagesOpenStagesEditorPopupButton',
                        }}
                    >
                        <div className={styles.buttonIcon}>
                            <Icon type={IconType.PROJECT_STAGES_ADD_BUTTON} svgSize={20} />
                        </div>

                        <div className={styles.buttonText}>Назначить этапы</div>
                    </div>
                </div>
            )}
        </div>
    );
}

function NoAssignedStagesState({
    canEditStages,
    onOpenStagesEditorPopupClick,
    onSaveActivityAsStagelessButtonClick,
}: StateProps): JSX.Element {
    return (
        <div className={styles.statusRow}>
            <div className={classNames(styles.status, styles.status_color_orange)}>
                <div className={styles.statusIcon}>
                    <Icon type={IconType.WARNING_IN_TRIANGULAR} svgSize={20} />
                </div>

                <div
                    className={styles.statusTitle}
                    {...{
                        'qa-id': 'activityStagesStatus',
                    }}
                >
                    Этапы не назначены
                </div>
            </div>

            {canEditStages && (
                <div className={styles.buttons}>
                    <div
                        className={styles.button}
                        onClick={onOpenStagesEditorPopupClick}
                        {...{
                            'qa-id': 'activityStagesOpenStagesEditorPopupButton',
                        }}
                    >
                        <div className={styles.buttonIcon}>
                            <Icon type={IconType.PROJECT_STAGES_ADD_BUTTON} svgSize={20} />
                        </div>

                        <div className={styles.buttonText}>Назначить этапы</div>
                    </div>

                    <div
                        className={classNames(styles.button, styles.button_noStages)}
                        onClick={onSaveActivityAsStagelessButtonClick}
                        {...{
                            'qa-id': 'activityStagesStagelessActivityButton',
                        }}
                    >
                        <div className={styles.buttonIcon}>
                            <Icon type={IconType.PROJECT_STAGES_NO_STAGES_BUTTON} svgSize={20} />
                        </div>

                        <div className={styles.buttonText}>Проект без этапов</div>
                    </div>
                </div>
            )}
        </div>
    );
}

function ActivityWithStagesState({
    projectStart,
    projectEnd,
    tasksByStageId,
    templateName,
    stages,
    canEditStages,
    onOpenStagesEditorPopupClick,
    onStageStatusChange,
    onSelectStageClick,
    createTaskByNameAndStage,
}: StateProps): JSX.Element {
    const nameToDisplay = templateName ? `Этапы «${templateName}»` : '';

    return (
        <React.Fragment>
            <div className={styles.statusRow}>
                <WithTooltip content={nameToDisplay}>
                    <div className={styles.templateName}>
                        <Dotdotdot clamp={2}>
                            {templateName && (
                                <span>
                                    Этапы «
                                    <span
                                        {...{
                                            'qa-id': 'activityStagesTemplateName',
                                        }}
                                    >
                                        {templateName}
                                    </span>
                                    »
                                </span>
                            )}
                        </Dotdotdot>
                    </div>
                </WithTooltip>

                {canEditStages && (
                    <div className={styles.buttons}>
                        <div
                            className={classNames(styles.button, styles.button_gray)}
                            onClick={onOpenStagesEditorPopupClick}
                            {...{
                                'qa-id': 'activityStagesOpenStagesEditorPopupButton',
                            }}
                        >
                            <Icon type={IconType.PROJECT_STAGES_EDIT_ICON} svgSize={20} />

                            <div className={styles.buttonText}>Редактировать этапы</div>
                        </div>
                    </div>
                )}
            </div>

            <div className={styles.calendar}>
                <StagesCalendar
                    tasksByStageId={tasksByStageId}
                    projectStart={projectStart}
                    projectEnd={projectEnd}
                    items={stages.filter((stage) => stage.active)}
                    editable={canEditStages}
                    onStageStatusChange={onStageStatusChange}
                    onSelectStageClick={onSelectStageClick}
                    createTaskByNameAndStage={createTaskByNameAndStage}
                />
            </div>
        </React.Fragment>
    );
}

function Preloader(): JSX.Element {
    return (
        <div className={styles.skeletonWrapper}>
            <StaticSkeleton className={styles.skeletonLeft} firstColor="#E6EDF1" />

            <StaticSkeleton className={styles.skeletonRight} firstColor="#E6EDF1" />
        </div>
    );
}
