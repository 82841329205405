import * as React from 'react';
import { useSelector } from 'react-redux';

import { List } from './List';

import { StoreState } from '@store';
import { getSelectedTextTitles, getSelectedTextContents } from '@store/autopilot/generationTextForm';

import * as styles from './styles.scss';

interface Props {}

export const Content: React.FC<Props> = ({}) => {
    const title = useSelector((state: StoreState) => getSelectedTextTitles(state));
    const content = useSelector((state: StoreState) => getSelectedTextContents(state));

    return (
        <div className={styles.root}>
            <List title={'Заголовки'} items={title.map(({ value }) => value)} />
            <List title={'Описания'} items={content.map(({ value }) => value)} />
        </div>
    );
};
