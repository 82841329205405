import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { ActivityApi } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';

import * as actions from './actions';
import { LoadActivitiesParams } from './types';
import { getLoadingStatus, getActivityById, getFetchersCount } from './selectors';

export const loadActivities = bindThunkAction<StoreState, LoadActivitiesParams, void, Error>(
    actions.loadActivitiesAsync,
    async (params, dispatch, getState) => {
        const { store } = params;
        let { ids } = params;

        const setStatus: (status: LoadingStatus) => void = (status) =>
            dispatch(
                actions.setLoadingStatus({
                    store,
                    status,
                }),
            );
        const state = getState();
        const loadingStatus = getLoadingStatus(state, store);

        try {
            let activities;

            if (ids) {
                const notLoadedActivityIds = ids.filter((id) => !getActivityById(state, id));

                if (notLoadedActivityIds.length) {
                    dispatch(actions.incFetchersCount(store));
                    setStatus(LoadingStatus.LOADING);
                    activities = await ActivityApi.getActivityCards({ ids: notLoadedActivityIds });
                }
            } else {
                if (loadingStatus !== LoadingStatus.LOADED) {
                    dispatch(actions.incFetchersCount(store));
                    setStatus(LoadingStatus.LOADING);
                    activities = await ActivityApi.getActivityCards();

                    ids = activities.map((activity) => activity.id);
                }
            }

            if (activities) {
                dispatch(actions.decFetchersCount(store));
                dispatch(actions.loadEntities(activities));
            }
            dispatch(
                actions.setStoreIds({
                    store,
                    ids,
                }),
            );

            const fetchersCount = getFetchersCount(getState(), store);
            if (!fetchersCount) {
                setStatus(LoadingStatus.LOADED);
            }
        } catch (e) {
            setStatus(LoadingStatus.ERROR);
        }
    },
);
