import * as React from 'react';

import * as style from './FinalStageActions.scss';

import { LaunchButton } from './LaunchButton';

interface Props {
    socialMediaIsLaunched: boolean;
    programmaticIsLaunched: boolean;
    contextIsLaunched: boolean;
    canLaunchSocialMedia: boolean;
    canLaunchProgrammatic: boolean;
    canLaunchContext: boolean;
    canStopSocialMedia: boolean;
    canStopProgrammatic: boolean;
    canStopContext: boolean;
    onPlacementsLaunch: (uiType: 'social media' | 'programmatic' | 'sea') => void;
    onPlacementsStop: (uiType: 'social media' | 'programmatic' | 'sea') => void;
}

export const FinalStageActionsTemplate = ({
    socialMediaIsLaunched,
    programmaticIsLaunched,
    contextIsLaunched,
    canLaunchSocialMedia,
    canLaunchProgrammatic,
    canLaunchContext,
    canStopSocialMedia,
    canStopProgrammatic,
    canStopContext,
    onPlacementsLaunch,
    onPlacementsStop,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.title}>Запустить размещения:</div>

            <div className={style.buttons}>
                <div className={style.button}>
                    <LaunchButton
                        title={'Социальные сети'}
                        isLaunched={socialMediaIsLaunched}
                        disabled={socialMediaIsLaunched ? !canStopSocialMedia : !canLaunchSocialMedia}
                        onLaunchConfirm={() => onPlacementsLaunch('social media')}
                        onStopConfirm={() => onPlacementsStop('social media')}
                    />
                </div>

                <div className={style.button}>
                    <LaunchButton
                        title={'Контекст'}
                        isLaunched={contextIsLaunched}
                        disabled={contextIsLaunched ? !canStopContext : !canLaunchContext}
                        onLaunchConfirm={() => onPlacementsLaunch('sea')}
                        onStopConfirm={() => onPlacementsStop('sea')}
                    />
                </div>

                <div className={style.button}>
                    <LaunchButton
                        title={'Программатик'}
                        isLaunched={programmaticIsLaunched}
                        disabled={programmaticIsLaunched ? !canStopProgrammatic : !canLaunchProgrammatic}
                        onLaunchConfirm={() => onPlacementsLaunch('programmatic')}
                        onStopConfirm={() => onPlacementsStop('programmatic')}
                    />
                </div>
            </div>
        </div>
    );
};
