import * as React from 'react';

import { Stage as StageProps } from '@store/activityTasksPage/stages';

import { CardStage } from '@common/Card';

import { WithEditor } from '../WithEditor';
import { DropdownEditor } from '../DropdownEditor';

import * as styles from './Stage.scss';

interface Props {
    canEdit: boolean;
    stage: string;
    stages: StageProps[];
    changeStage(stage: string): Promise<void>;
}

const noStageLabel = 'Без этапа';

export function Stage({ canEdit, stage, stages, changeStage }: Props): JSX.Element {
    const items = [
        { id: null, label: noStageLabel },
        ...stages.map((stage) => ({
            id: stage.id,
            label: stage.name,
        })),
    ].filter((item) => {
        if (!stage) {
            return item.id;
        }

        return item.label !== stage;
    });

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'taskCardStage',
                'qa-value': stage || noStageLabel,
            }}
        >
            <WithEditor
                canEdit={canEdit}
                loadingClassName={styles.skeleton}
                valueContent={() => <CardStage text={stage || noStageLabel} />}
                editorContent={(onValueEdit) => (
                    <DropdownEditor items={items} title="Перевести в этап" onSelect={onValueEdit} />
                )}
                editValue={changeStage}
            />
        </div>
    );
}
