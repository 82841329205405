import * as React from 'react';
import { useSelector } from 'react-redux';
import { DictionaryType, CorrectionType, Correction } from '@mrm/budget';
import { values, sum } from 'lodash';
import classnames from 'classnames';

import { StoreState } from '@store';
import { OldToNewMonth, getPageData } from '@store/budgetExecution';
import {
    CellPosition,
    InternalTransferDirection,
    getBudgetTransferMenuState,
    getTransitionBudgetItem,
    getTotalTransferAmountForSource,
    getTransferAmount,
} from '@store/budgetExecution/budgetTransferMenu';

import { ColumnsList } from '../../ColumnsConfig';
import { Utils, Money, MoneyFormatter, DatesFormatter } from '@common/Utils';

import { CopyableValue } from '../InternalTransitionSumEntering/CopyableValue';

import { ContentBlock, TitleRowContentBlock, CorrectionsPopup, ErrorContentBlock } from './Misc';

import * as styles from './CellDescription.scss';

const QA_ID = 'budgetTransferMenuCellDescription';
export const QaIds = {
    Root: QA_ID,
    CannotTurnCellToDonorWaning: `${QA_ID}CannotTurnCellToDonorWarning`,
    CannotTurnLineToDonorWaning: `${QA_ID}CannotTurnLineToDonorWarning`,
    Month: `${QA_ID}Month`,
    SapComment: `${QA_ID}SapComment`,
    Division: `${QA_ID}Division`,
    Tool: `${QA_ID}Tool`,
    Direction: `${QA_ID}Direction`,
    SerialNumber: `${QA_ID}SerialNumber`,
    Plan: `${QA_ID}Plan`,
    PlanReserveDiff: `${QA_ID}PlanReserveDiff`,
    ReservePlanDiff: `${QA_ID}ReservePlanDiff`,
    LineReservePlanDiff: `${QA_ID}LineReservePlanDiff`,
    LinePlanReserveDiff: `${QA_ID}LinePlanReserveDiff`,
    NegativePlanReserveDiffMarker: `${QA_ID}NegativePlanReserveDiffMarker`,
    LinePlanReserveDiffAfterTransfer: `${QA_ID}LinePlanReserveDiffAfterTransfer`,
};

export enum Themes {
    Regular = 'Regular',
    Transparent = 'Transparent',
    White = 'White',
    WhiteWithShadow = 'WhiteWithShadow',
    MonthInputDescriptionLeft = 'MonthInputDescriptionLeft',
    MonthInputDescriptionRight = 'MonthInputDescriptionRight',
}

export enum Templates {
    Default,
    CellFullWithLineReservePlanDiff,
    CellFullWithLinePlanReserveDiff,
    IdWithLinePlanReserveDiff,
    IdWithLineReservePlanDiff,
    SumEnteringShort,
    NegativeLineBalanceWarning,
    CellWarningCannotTurnToDonor,
    LineWarningCannotTurnToDonor,
}

export interface Props {
    qaId: string;
    theme?: Themes;
    template?: Templates;
    cell: CellPosition;
    useMonth?: boolean;
}

function formatMoney(amount: number): string {
    return MoneyFormatter.toThousands(Money.fromCopecks(amount));
}

function formatMoneyTitle(amount: number): string {
    return MoneyFormatter.toRoubles(Money.fromCopecks(amount));
}

function useTheme(props: Props) {
    switch (props.theme) {
        case Themes.Transparent:
            return styles.rootTransparent;
        case Themes.White:
            return styles.rootWhite;
        case Themes.WhiteWithShadow:
            return styles.rootWhiteWithShadow;
        case Themes.MonthInputDescriptionLeft:
            return styles.rootMonthInputDescriptionLeft;
        case Themes.MonthInputDescriptionRight:
            return styles.rootMonthInputDescriptionRight;
        case Themes.Regular:
        default:
            return null;
    }
}

interface UseBudgetItemProps {
    serialNumber: number;
    sapComment: string;
    monthIndex: number;
    division: string;
    tool: string;
    direction: string;
    planValue: number;
    reserveValue: number;
    linePlanValue: number;
    lineReserveValue: number;
}

function useBudgetItem(props: Props): UseBudgetItemProps {
    const { lineId, columnName } = props.cell;

    const budgetItem = useSelector((state: StoreState) => getTransitionBudgetItem(state, lineId));

    const serialNumber = budgetItem?.serialNumber;
    const sapComment = budgetItem?.sapComment;
    const division = Utils.withErrorHandler(() => budgetItem.dictionary[DictionaryType.Division].value);
    const tool = Utils.withErrorHandler(() => budgetItem.dictionary[DictionaryType.Tool].value);
    const direction = Utils.withErrorHandler(() => budgetItem.dictionary[DictionaryType.Direction].value);

    let monthIndex = 0;
    let planValue = 0;
    let reserveValue = 0;
    Utils.withErrorHandler<void>(() => {
        const column = ColumnsList.find((column) => column.name == columnName);

        monthIndex = column.metaData.month - 1;
        planValue = budgetItem.plannedFunds[OldToNewMonth[monthIndex + 1]];
        reserveValue = budgetItem.reservedFunds[OldToNewMonth[monthIndex + 1]];
    });

    const linePlanValue = Utils.withErrorHandler(() => sum(values(budgetItem.plannedFunds))) || 0;
    const lineReserveValue = Utils.withErrorHandler(() => sum(values(budgetItem.reservedFunds))) || 0;

    return {
        serialNumber,
        sapComment,
        monthIndex,
        division,
        tool,
        direction,
        planValue,
        reserveValue,
        linePlanValue,
        lineReserveValue,
    };
}

interface UseCorrectionsProps {
    planCorrections: Correction<CorrectionType.PlanFundsTransfer>[];
    reserveCorrections: Correction<CorrectionType.ReservedFunds>[];
}

function useCorrections(props: Props): UseCorrectionsProps {
    const planCorrections = useSelector((state: StoreState) =>
        Utils.withErrorHandler<Correction<CorrectionType.PlanFundsTransfer>[]>(
            () => getPageData(state).corrections.planCorrections[props.cell.lineId],
        ),
    );
    const reserveCorrections = useSelector((state: StoreState) =>
        Utils.withErrorHandler<Correction<CorrectionType.ReservedFunds>[]>(
            () => getPageData(state).corrections.reserveCorrections[props.cell.lineId],
        ),
    );

    return { planCorrections, reserveCorrections };
}

export function CellDescription(props: Props): JSX.Element {
    let templateToRender: React.FC<Props>;
    switch (props.template) {
        case Templates.IdWithLinePlanReserveDiff:
            templateToRender = IdWithLinePlanReserveDiff;
            break;
        case Templates.IdWithLineReservePlanDiff:
            templateToRender = IdWithLineReservePlanDiff;
            break;
        case Templates.CellFullWithLineReservePlanDiff:
            templateToRender = CellFullWithLineReservePlanDiff;
            break;
        case Templates.CellFullWithLinePlanReserveDiff:
            templateToRender = CellFullWithLinePlanReserveDiff;
            break;
        case Templates.SumEnteringShort:
            templateToRender = SumEnteringShort;
            break;
        case Templates.NegativeLineBalanceWarning:
            templateToRender = NegativeLineBalanceWarning;
            break;
        case Templates.CellWarningCannotTurnToDonor:
            templateToRender = CellWarningCannotTurnToDonor;
            break;
        case Templates.LineWarningCannotTurnToDonor:
            templateToRender = LineWarningCannotTurnToDonor;
            break;
        case Templates.Default:
        default:
            templateToRender = Default;
            break;
    }

    return React.createElement(templateToRender, props);
}

function Default(props: Props): JSX.Element {
    const themeClass = useTheme(props);
    const { monthIndex, sapComment, serialNumber, division, tool, direction, planValue } = useBudgetItem(props);

    return (
        <div
            className={classnames(styles.root, themeClass)}
            {...{
                'qa-id': `${QaIds.Root} ${props.qaId}`,
            }}
        >
            <span
                className={styles.month}
                {...{
                    'qa-id': QaIds.Month,
                }}
            >
                {DatesFormatter.months[monthIndex]}
            </span>

            <span
                title={sapComment}
                className={classnames(styles.content, styles.activityName)}
                {...{
                    'qa-id': QaIds.SapComment,
                }}
            >
                {sapComment}
            </span>

            <ContentBlock qaId={QaIds.Division} text="МВЗ/Дивизион" content={division} />

            <ContentBlock qaId={QaIds.Tool} text="Инструмент" content={tool} />

            <ContentBlock qaId={QaIds.Direction} text="Направление" content={direction} />

            <div className={classnames(styles.row, styles.rowPadding)}>
                <span
                    className={classnames(styles.content, styles.contentBold)}
                    {...{
                        'qa-id': QaIds.SerialNumber,
                    }}
                >
                    ID {serialNumber} &nbsp;
                </span>

                <ContentBlock
                    qaId={QaIds.Plan}
                    subItemTheme
                    boldValue
                    text="План"
                    content={formatMoney(planValue)}
                    contentTitle={formatMoneyTitle(planValue)}
                />
            </div>
        </div>
    );
}

function CellFullWithLineReservePlanDiff(props: Props): JSX.Element {
    const themeClass = useTheme(props);
    const { monthIndex, sapComment, serialNumber, division, tool, direction, linePlanValue, lineReserveValue } =
        useBudgetItem(props);
    const lineReservePlanDiff = lineReserveValue - linePlanValue;

    return (
        <div
            className={classnames(styles.root, themeClass)}
            {...{
                'qa-id': `${QaIds.Root} ${props.qaId}`,
            }}
        >
            <TitleRowContentBlock
                serialNumber={serialNumber}
                sapComment={sapComment}
                useMonth={props.useMonth}
                monthIndex={monthIndex}
            />

            <ContentBlock skipPadding qaId={QaIds.Division} text="МВЗ/Дивизион" content={division} />

            <ContentBlock skipPadding qaId={QaIds.Tool} text="Инструмент" content={tool} />

            <ContentBlock skipPadding qaId={QaIds.Direction} text="Направление" content={direction} />

            <div className={classnames(styles.row)}>
                <ContentBlock
                    boldValue
                    skipPadding
                    qaId={QaIds.LineReservePlanDiff}
                    text="Резерв-План за год (требуется внести)"
                    content={formatMoney(lineReservePlanDiff)}
                    contentTitle={formatMoneyTitle(lineReservePlanDiff)}
                />
            </div>
        </div>
    );
}

function useSumEnteringShortStore(props: Props) {
    const { cell } = props;
    const { monthIndex, planValue, reserveValue } = useBudgetItem(props);
    const planReserveDiff = planValue - reserveValue;

    const internalTransferDirection = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.internalTransferDirection,
    );
    const planReserveDiffWithSum = useSelector((state: StoreState) => {
        const transitionData = getBudgetTransferMenuState(state);

        switch (internalTransferDirection) {
            case InternalTransferDirection.OneToMany:
                return planReserveDiff + getTransferAmount(transitionData, transitionData.cells.from[0], cell);
            case InternalTransferDirection.ManyToOne:
                return planReserveDiff - getTransferAmount(transitionData, cell, transitionData.cells.to[0]);
            default:
                return 0;
        }
    });

    return {
        monthIndex,
        planValue,
        planReserveDiffWithSum,
    };
}

function SumEnteringShort(props: Props): JSX.Element {
    const themeClass = useTheme(props);
    const { monthIndex, planValue, planReserveDiffWithSum } = useSumEnteringShortStore(props);
    const { planCorrections, reserveCorrections } = useCorrections(props);

    return (
        <div
            className={classnames(styles.root, styles.fullRowContent, themeClass)}
            {...{
                'qa-id': `${QaIds.Root} ${props.qaId}`,
            }}
        >
            <div>
                <div className={styles.flexCenter}>
                    <ContentBlock
                        subItemTheme
                        boldValue
                        text="План"
                        qaId={QaIds.Plan}
                        content={formatMoney(planValue)}
                        contentTitle={formatMoneyTitle(planValue)}
                    />

                    <CorrectionsPopup planCorrections={planCorrections} reserveCorrections={reserveCorrections} />
                </div>

                <ContentBlock
                    subItemTheme
                    boldValue
                    qaId={QaIds.PlanReserveDiff}
                    text="План-Резерв"
                    content={formatMoney(planReserveDiffWithSum)}
                    contentTitle={formatMoneyTitle(planReserveDiffWithSum)}
                />
            </div>

            <div className={styles.monthPadding}>
                <span
                    className={styles.month}
                    {...{
                        'qa-id': QaIds.Month,
                    }}
                >
                    {DatesFormatter.months[monthIndex]}
                </span>
            </div>

            {planReserveDiffWithSum < 0 && (
                <div
                    className={styles.negativePlanReserveDiffMarker}
                    {...{
                        'qa-id': QaIds.NegativePlanReserveDiffMarker,
                    }}
                />
            )}
        </div>
    );
}

function IdWithLinePlanReserveDiff(props: Props): JSX.Element {
    const themeClass = useTheme(props);
    const { serialNumber, linePlanValue, lineReserveValue } = useBudgetItem(props);
    const { planCorrections, reserveCorrections } = useCorrections(props);
    const linePlanReserveDiff = linePlanValue - lineReserveValue;

    let content: JSX.Element;
    let themeClassToUse;
    if (linePlanReserveDiff <= 0) {
        themeClassToUse = classnames(styles.rootShort, themeClass || styles.rootRed);
        content = <ErrorContentBlock text="Все плановые деньги зарезервированы" />;
    } else {
        themeClassToUse = classnames(styles.rootShort, themeClass);
        content = (
            <div className={styles.flexCenter}>
                <ContentBlock
                    subItemTheme
                    skipPadding
                    boldValue
                    qaId={QaIds.LinePlanReserveDiff}
                    text={'План-Резерв за год (доступно)'}
                    content={formatMoney(linePlanReserveDiff)}
                    contentTitle={formatMoneyTitle(linePlanReserveDiff)}
                />

                <CorrectionsPopup planCorrections={planCorrections} reserveCorrections={reserveCorrections} />
            </div>
        );
    }

    return (
        <div
            className={classnames(styles.root, themeClassToUse)}
            {...{
                'qa-id': `${QaIds.Root} ${props.qaId}`,
            }}
        >
            <div className={styles.row}>
                <span
                    className={classnames(styles.content, styles.contentBold)}
                    {...{
                        'qa-id': QaIds.SerialNumber,
                    }}
                >
                    ID {serialNumber} &nbsp;
                </span>

                {content}
            </div>
        </div>
    );
}

function IdWithLineReservePlanDiff(props: Props): JSX.Element {
    const themeClass = useTheme(props);
    const { serialNumber, linePlanValue, lineReserveValue } = useBudgetItem(props);
    const { planCorrections, reserveCorrections } = useCorrections(props);
    const lineReservePlanDiff = lineReserveValue - linePlanValue;

    return (
        <div
            className={classnames(styles.root, styles.rootShort, themeClass)}
            {...{
                'qa-id': `${QaIds.Root} ${props.qaId}`,
            }}
        >
            <div className={styles.row}>
                <span
                    className={classnames(styles.content, styles.contentBold)}
                    {...{
                        'qa-id': QaIds.SerialNumber,
                    }}
                >
                    ID {serialNumber} &nbsp;
                </span>

                <div className={styles.flexCenter}>
                    <ContentBlock
                        subItemTheme
                        skipPadding
                        boldValue
                        qaId={QaIds.LineReservePlanDiff}
                        text={'Резерв-План за год (требуется внести)'}
                        content={formatMoney(lineReservePlanDiff)}
                        contentTitle={formatMoneyTitle(lineReservePlanDiff)}
                    />

                    <CorrectionsPopup planCorrections={planCorrections} reserveCorrections={reserveCorrections} />
                </div>
            </div>
        </div>
    );
}

function useNegativeLineBalanceWarningStore(props: Props) {
    const cellTotalTransferAmount = useSelector((state: StoreState) =>
        getTotalTransferAmountForSource(state, props.cell.lineId),
    );

    return cellTotalTransferAmount;
}

function NegativeLineBalanceWarning(props: Props): JSX.Element {
    const themeClass = useTheme(props);
    const { serialNumber, linePlanValue, lineReserveValue } = useBudgetItem(props);
    const cellTotalTransferAmount = useNegativeLineBalanceWarningStore(props);
    const linePlanReserveDiff = linePlanValue - lineReserveValue;
    const linePlanReserveDiffAfterTransfer = linePlanReserveDiff - cellTotalTransferAmount;

    return (
        <div
            className={classnames(styles.root, themeClass)}
            {...{
                'qa-id': `${QaIds.Root} ${props.qaId}`,
            }}
        >
            <span
                className={classnames(styles.content, styles.contentBold)}
                {...{
                    'qa-id': QaIds.SerialNumber,
                }}
            >
                ID {serialNumber} &nbsp;
            </span>

            <br />

            <div className={styles.flexCenter}>
                <ContentBlock
                    qaId={QaIds.LinePlanReserveDiff}
                    text={'План-Резерв за год (доступно)'}
                    content={formatMoney(linePlanReserveDiff)}
                    contentTitle={formatMoneyTitle(linePlanReserveDiff)}
                />

                <svg
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={styles.lineNegativeBalanceArrow}
                >
                    <path
                        d="M12.3708 0.121796L12.4404 0.181942L15.8192 3.56075C16.0398 3.78129 16.0598 4.12639 15.8794 4.36957L15.8192 4.43925L12.4404 7.81806C12.1978 8.06065 11.8045 8.06065 11.5619 7.81806C11.3414 7.59752 11.3214 7.25242 11.5018 7.00924L11.5619 6.93957L13.85 4.65H0.65C0.291015 4.65 0 4.35898 0 4C0 3.67365 0.240508 3.40347 0.553948 3.35705L0.65 3.35L13.851 3.349L11.5619 1.06043C11.3414 0.839898 11.3214 0.494796 11.5018 0.251613L11.5619 0.181942C11.7825 -0.0385936 12.1276 -0.0586423 12.3708 0.121796Z"
                        fill="#7E8681"
                    />
                </svg>

                <div
                    className={styles.negativeLineBalance}
                    {...{
                        'qa-id': QaIds.LinePlanReserveDiffAfterTransfer,
                    }}
                >
                    {MoneyFormatter.toMostAppropriate(Money.fromCopecks(linePlanReserveDiffAfterTransfer))}
                </div>
            </div>
        </div>
    );
}

function LineWarningCannotTurnToDonor(props: Props): JSX.Element {
    const themeClass = useTheme(props);
    const { serialNumber } = useBudgetItem(props);

    return (
        <div
            className={classnames(styles.root, styles.rootShort, themeClass || styles.rootRed, styles.rootCenter)}
            {...{
                'qa-id': `${QaIds.CannotTurnLineToDonorWaning} ${props.qaId}`,
            }}
        >
            <div className={classnames(styles.row, styles.rowCenter)}>
                <span
                    className={classnames(styles.content, styles.contentBold)}
                    {...{
                        'qa-id': QaIds.SerialNumber,
                    }}
                >
                    ID {serialNumber} &nbsp;
                </span>

                <div className={classnames(styles.lineWarningContent, styles.contentOverflow)}>
                    Не может стать источником. Плановые деньги зарезервированы.
                </div>
            </div>
        </div>
    );
}

function CellWarningCannotTurnToDonor(props: Props): JSX.Element {
    const themeClass = useTheme(props);
    const { serialNumber, sapComment, monthIndex } = useBudgetItem(props);

    return (
        <div
            className={classnames(styles.root, themeClass || styles.rootRed)}
            {...{
                'qa-id': `${QaIds.CannotTurnCellToDonorWaning} ${props.qaId}`,
            }}
        >
            <TitleRowContentBlock
                grayTheme
                serialNumber={serialNumber}
                sapComment={sapComment}
                useMonth={props.useMonth}
                monthIndex={monthIndex}
            />

            <div className={styles.fullCellWarningContent}>
                Не может стать источником.
                <br />
                Все плановые деньги зарезервированы.
            </div>
        </div>
    );
}

function CellFullWithLinePlanReserveDiff(props: Props): JSX.Element {
    const themeClass = useTheme(props);
    const { serialNumber, sapComment, monthIndex, division, tool, direction, linePlanValue, lineReserveValue } =
        useBudgetItem(props);
    const linePlanReserveDiff = linePlanValue - lineReserveValue;

    return (
        <div
            className={classnames(styles.root, themeClass)}
            {...{
                'qa-id': `${QaIds.Root} ${props.qaId}`,
            }}
        >
            <TitleRowContentBlock
                serialNumber={serialNumber}
                sapComment={sapComment}
                useMonth={props.useMonth}
                monthIndex={monthIndex}
            />

            <ContentBlock skipPadding qaId={QaIds.Division} text="МВЗ/Дивизион" content={division} />

            <ContentBlock skipPadding qaId={QaIds.Tool} text="Инструмент" content={tool} />

            <ContentBlock skipPadding qaId={QaIds.Direction} text="Направление" content={direction} />

            <div className={classnames(styles.row)}>
                <ContentBlock
                    skipPadding
                    qaId={QaIds.LinePlanReserveDiff}
                    text="План-Резерв за год (доступно)"
                    content={<CopyableValue value={linePlanReserveDiff} />}
                    contentTitle={formatMoneyTitle(linePlanReserveDiff)}
                />
            </div>
        </div>
    );
}
