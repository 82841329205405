import { useGetAuthUserQuery } from '@api';

import { PermissionSysName } from 'sber-marketing-types/backend';

export interface UsePermission {
    isLoading: boolean;
    isAccept: boolean;
}

export function usePermission(permission: PermissionSysName): UsePermission {
    const { isLoading, data: authUser } = useGetAuthUserQuery();

    return {
        isLoading,
        isAccept: Boolean(authUser?.user.attributes.permissions.includes(permission)),
    };
}
