import { Month } from '@mrm/budget';
import type { PlainDictionary } from '@mrm/dictionary';
import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type {
    AutopilotRadio,
    AutopilotRadioTargetGroup,
    AutopilotRadioMediaplanPlanDataResponse as AutopilotRadioMediaplan,
    AutopilotRadioBriefStrategy,
} from 'sber-marketing-types/backend';

export interface PageState {
    autopilot: AutopilotRadio;
    activity: Activity;
    mediaplan: AutopilotRadioMediaplan;
    targetGroups: AutopilotRadioTargetGroup[];
    dictionaries: GroupedDictionaries;
    briefStageForm: BriefStageForm;
    selectedBudgetItemIds: string[];
    currentStage: AutopilotStage;
    preloader: boolean;
    sidebar: Sidebar;
}

export interface BriefStageForm {
    blockId: string;
    productId: string;
    budget: string;
    budgetError: string;
    dateStart: string;
    dateEnd: string;
    targetGroup: AutopilotRadioTargetGroup;
    intervals: number[];
    selectedStations: string[];
    strategy: AutopilotRadioBriefStrategy;
}

export interface GroupedDictionaries {
    [dictionaryType: string]: PlainDictionary[];
}

export interface Sidebar {
    opened: boolean;
}

export interface SwitchKeyActivityParams {
    id: number;
    isKey: boolean;
}

export const enum AutopilotStage {
    Brief = 'brief',
    MediaplanSettings = 'mediaplan_settings',
    Mediaplan = 'mediaplan',
    Budget = 'budget',
}

export const MONTHS = [
    Month.Jan,
    Month.Feb,
    Month.Mar,
    Month.Apr,
    Month.May,
    Month.Jun,
    Month.Jul,
    Month.Aug,
    Month.Sept,
    Month.Oct,
    Month.Nov,
    Month.Dec,
];
