import * as React from 'react';
import * as styles from './ButtonList.scss';

interface Props {
    buttons: JSX.Element[];
}

export const ButtonList: React.FC<Props> = ({ buttons }) => (
    <div className={styles.root}>
        {buttons.map((button, index) => (
            <div key={index} className={styles.buttonWrapper}>
                {button}
            </div>
        ))}
    </div>
);
