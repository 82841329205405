import * as lodash from 'lodash';

import { Correction, CorrectionType, DictionaryType } from '@mrm/budget';
import { PlainDictionary } from '@mrm/dictionary';
import {
    ColumnData,
    ColumnName,
    TableLine,
    ChangeList,
    REQUIRED_COLUMN_NAMES,
    ColumnsWithSameData,
} from '@store/budgetExecution/types';
import { DropdownCellOptions } from '../../CellTypes';

import { CellParams, CellBackgroundColor } from '../LayerManager';
import { getFieldValue } from './Utils';

interface Props {
    line: TableLine;
    column: ColumnData;
    unsavedChanges: ChangeList;
    dictionaries: Record<string, PlainDictionary>;
    dropdownsOptions: { [columnName: string]: DropdownCellOptions[] };
    budgetItemCorrections: Correction<CorrectionType.BudgetItem>[];
    validationStatus: boolean;
    isDisabled: boolean;
}

export class DropdownCellParamsCreator {
    private line: TableLine;
    private column: ColumnData;
    private unsavedChanges: ChangeList;
    private dictionaries: Record<string, PlainDictionary>;
    private dropdownsOptions: { [columnName: string]: DropdownCellOptions[] };
    private budgetItemCorrections: Correction<CorrectionType.BudgetItem>[];
    private validationStatus: boolean;
    private isDisabled: boolean;

    constructor(props: Props) {
        const {
            line,
            column,
            unsavedChanges,
            dictionaries,
            dropdownsOptions,
            budgetItemCorrections,
            validationStatus,
            isDisabled,
        } = props;

        this.line = line;
        this.column = column;
        this.unsavedChanges = unsavedChanges;
        this.dictionaries = dictionaries;
        this.dropdownsOptions = dropdownsOptions;
        this.budgetItemCorrections = budgetItemCorrections;
        this.validationStatus = validationStatus;
        this.isDisabled = isDisabled;
    }

    public makeCellParams(): CellParams {
        let value: string;
        let color: CellBackgroundColor;
        let isDisabled = this.isDisabled;

        const unsavedValue = this.getUnsavedValue(this.column);
        const baseValue = this.getBaseValue(this.column);

        if (unsavedValue !== undefined) {
            value = unsavedValue;
            color = CellBackgroundColor.UnsavedChange;
        } else {
            const correctionValue = this.getCorrectionValue(this.column, baseValue);

            if (correctionValue !== undefined) {
                value = correctionValue;
                color = CellBackgroundColor.SavedChange;
            } else {
                value = baseValue;
            }

            const lineHasDictionaryCorrections = this.budgetItemCorrections.some(
                (item) => !!item.data.params.dictionaryIds,
            );

            if (lineHasDictionaryCorrections) {
                isDisabled = true;
            }
        }

        const options = this.dropdownsOptions && this.dropdownsOptions[this.column.name];

        return {
            options,
            value,
            originalValue: baseValue,
            bgColor: color,
            displayValidationError: this.cellIsInvalid(value),
            disabled: isDisabled,
            allowMultipleItems: this.column.name === ColumnName.Responsible,
        };
    }

    private getUnsavedValue(column: ColumnData): string {
        const changes = this.unsavedChanges[this.line.id] || [];

        const unsavedChange = lodash.last(
            changes.filter((item) => {
                const isBudgetItemMatched = item.budgetItemId === this.line.id;

                const columnsToChange: ColumnName[] = ColumnsWithSameData[item.columnName] || [item.columnName];
                const isColumnMatched = columnsToChange.some((columnName) => columnName === column.name);

                return isBudgetItemMatched && isColumnMatched;
            }),
        );

        return unsavedChange ? (unsavedChange.value as string) : undefined;
    }

    private getCorrectionValue(column: ColumnData, baseValue: string): string {
        let correctionValue: string = undefined;

        const dictionaryType = lodash.get(column, 'metaData.dictionaryType') as DictionaryType;

        if (dictionaryType) {
            lodash.forEach(this.budgetItemCorrections, (item) => {
                const correctionDictionaryIds = item.data.params.dictionaryIds;

                if (correctionDictionaryIds) {
                    const foundDictionaryId = correctionDictionaryIds.find((id) => this.dictionaries[id]);

                    if (baseValue && !foundDictionaryId) {
                        correctionValue = null;
                    } else if (baseValue !== foundDictionaryId) {
                        correctionValue = foundDictionaryId;
                    }
                }
            });
        } else if (column.name == ColumnName.Responsible) {
            lodash.forEach(this.budgetItemCorrections, (item) => {
                const correctionResponsibleIds = item.data.params.responsibleIds;

                if (correctionResponsibleIds || correctionResponsibleIds === null) {
                    correctionValue = correctionResponsibleIds
                        ? correctionResponsibleIds.map((id) => String(id)).join()
                        : null;
                }
            });
        }

        return correctionValue;
    }

    private getBaseValue(column: ColumnData): string {
        return getFieldValue(this.line.fields[column.name], column.valueType) as string;
    }

    private cellIsInvalid(value: string) {
        return REQUIRED_COLUMN_NAMES.includes(this.column.name) && this.validationStatus && !value;
    }
}
