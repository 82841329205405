import { CreativeRequest, CreativeRequestParam } from '@api';

import { useCreativeRequestData, useParamField } from '../../hooks';

export function useDescription(creativeRequest: CreativeRequest) {
    return useCreativeRequestData(
        creativeRequest,
        async (creativeRequest) => creativeRequest.model.description,
        (creativeRequest, setDescription) => creativeRequest.events.onDescriptionUpdated(setDescription),
        (creativeRequest, setDescription) => creativeRequest.events.offDescriptionUpdated(setDescription),
    );
}

export function useCreatedAt(creativeRequest: CreativeRequest) {
    return useCreativeRequestData(creativeRequest, async (creativeRequest) => creativeRequest.model.createdAt);
}

export function useParams(creativeRequest: CreativeRequest) {
    async function updateParams(creativeRequest: CreativeRequest, setParams: (params: any) => void) {
        setParams([...(await creativeRequest.model.getParams())]);
    }
    let updateParamsBounded: () => void = null;

    return useCreativeRequestData(
        creativeRequest,
        async (creativeRequest) => creativeRequest.model.getParams(),
        (creativeRequest, setParams) => {
            updateParamsBounded = updateParams.bind(null, creativeRequest, setParams);

            creativeRequest.events.onParamsAdded(updateParamsBounded);
            creativeRequest.events.onParamsRemoved(updateParamsBounded);
        },
        (creativeRequest) => {
            creativeRequest.events.offParamsAdded(updateParamsBounded);
            creativeRequest.events.offParamsRemoved(updateParamsBounded);
        },
    );
}

export function useParamBlock(param: CreativeRequestParam) {
    return useParamField(
        param,
        () => param.model.block,
        (setDictionary) => param.events.onBlockUpdated(setDictionary),
        (setDictionary) => param.events.onBlockUpdated(setDictionary),
    );
}

export function useParamDivision(param: CreativeRequestParam) {
    return useParamField(
        param,
        () => param.model.division,
        (setDictionary) => param.events.onDivisionUpdated(setDictionary),
        (setDictionary) => param.events.onDivisionUpdated(setDictionary),
    );
}

export function useParamProduct(param: CreativeRequestParam) {
    return useParamField(
        param,
        () => param.model.product,
        (setDictionary) => param.events.onProductUpdated(setDictionary),
        (setDictionary) => param.events.onProductUpdated(setDictionary),
    );
}

export function useParamSegment(param: CreativeRequestParam) {
    return useParamField(
        param,
        () => param.model.segment,
        (setDictionary) => param.events.onSegmentUpdated(setDictionary),
        (setDictionary) => param.events.onSegmentUpdated(setDictionary),
    );
}

export function useParamChannel(param: CreativeRequestParam) {
    return useParamField(
        param,
        () => param.model.channel,
        (setDictionary) => param.events.onChannelUpdated(setDictionary),
        (setDictionary) => param.events.onChannelUpdated(setDictionary),
    );
}
