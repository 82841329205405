import { Success } from 'typescript-fsa';
import { v4 as uuidV4 } from 'uuid';

import { TaskPageState, AddCommentarySuccess } from '../types';

export const resetNewCommentaryReducer = ({ newCommentary, authorId, ...restState }: TaskPageState): TaskPageState => ({
    ...restState,
    authorId,
    newCommentary: {
        authorId: newCommentary.authorId,
        isLoading: false,
        id: uuidV4(),
        content: '',
        createdAt: Date.now(),
        assetsIds: [],
        reactions: [],
    },
});

export const setSendingCommentReducer = (state: TaskPageState, sendingComment: boolean) => ({
    ...state,
    sendingComment,
});

export const setSavingCommentReducer = (state: TaskPageState, savingComment: string | null) => ({
    ...state,
    savingComment,
});

export const addCommentaryStartedReducer = ({ comments, ...restState }: TaskPageState): TaskPageState => ({
    ...restState,
    comments: {
        ...comments,
        [restState.newCommentary.id]: {
            ...restState.newCommentary,
            isLoading: true,
            createdAt: Date.now(),
            replyId: restState.commentIdToReplyTo,
        },
    },
});

export const addCommentaryDoneReducer = (
    { comments, authorId, channels, newCommentary, ...restState }: TaskPageState,
    { result, params }: Success<number | null, AddCommentarySuccess>,
): TaskPageState => ({
    ...restState,
    newCommentary,
    channels: {
        ...channels,
        [params]: {
            ...channels[params],
            commentsIds: [...channels[params].commentsIds, result.id],
        },
    },
    authorId,
    comments: {
        ...comments,
        [result.id]: result,
    },
});
