import * as React from 'react';
import { difference } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { FilterMenu, FilterMenuProps, WithTooltip, TooltipAnchor } from 'sber-marketing-ui';

import { StoreState } from '@store';
import {
    TargetFilter,
    TargetFilterItems,
    STATUS_FITLER_ITEMS_FOR_EXPORT_MODE,
    TYPE_FILTER_ITEMS_FOR_EXPORT_MODE,
    getBudgetCorrectionsFiltersAndExportState,
    setFilterValue,
} from '@store/budgetCorrections/filtersAndExport';

import * as styles from './Filter.scss';

type PartialFilterMenuProps = Omit<FilterMenuProps, 'items' | 'checkedItemsIds' | 'onItemSelection' | 'qaLabel'>;

interface Props extends PartialFilterMenuProps {
    targetFilter: TargetFilter;
}

function getCheckedItemIds(
    targetFilter: TargetFilter,
    checkedItemIds: React.ReactText[],
    isExportModeEnabled: boolean,
): React.ReactText[] {
    if (isExportModeEnabled) {
        switch (targetFilter) {
            case TargetFilter.Status:
                return STATUS_FITLER_ITEMS_FOR_EXPORT_MODE;
            case TargetFilter.Types:
                return checkedItemIds.filter((checkedItemId) =>
                    TYPE_FILTER_ITEMS_FOR_EXPORT_MODE.includes(checkedItemId as string),
                );
            default:
                return checkedItemIds;
        }
    }

    return checkedItemIds;
}

function useInteractivity(targetFilter: TargetFilter) {
    const dispatch = useDispatch();

    const isExportModeEnabled = useSelector(
        (state: StoreState) => getBudgetCorrectionsFiltersAndExportState(state).currentFilters.isExportModeEnabled,
    );
    const addTypeFitlerExportControls = isExportModeEnabled && targetFilter === TargetFilter.Types;

    const disabled = isExportModeEnabled && targetFilter === TargetFilter.Status;
    const savedCheckedItemsIds = useSelector(
        (state: StoreState) => getBudgetCorrectionsFiltersAndExportState(state).currentFilters[targetFilter],
    );
    const checkedItemsIds = getCheckedItemIds(targetFilter, savedCheckedItemsIds, isExportModeEnabled);

    const allItems = useSelector(
        (state: StoreState) => getBudgetCorrectionsFiltersAndExportState(state).items[targetFilter],
    );
    let items: TargetFilterItems = allItems;
    if (addTypeFitlerExportControls) {
        items = allItems.filter((item) => TYPE_FILTER_ITEMS_FOR_EXPORT_MODE.includes(item.id as string));
    }

    function onItemSelection(val: React.ReactText[]) {
        let value = val;
        if (addTypeFitlerExportControls) {
            // ensure that there is only 1 selected item
            if (val.length === 0) {
                value = checkedItemsIds;
            } else if (val.length === 1) {
                value = val;
            } else {
                value = difference(val, checkedItemsIds);
            }
        }

        dispatch(
            setFilterValue({
                filter: targetFilter,
                value,
            }),
        );
    }

    return {
        disabled,
        items,
        checkedItemsIds,
        onItemSelection,
    };
}

export const Filter = React.forwardRef(
    ({ targetFilter, ...filterMenuProps }: Props, ref: React.RefObject<FilterMenu>) => {
        const { disabled, items, checkedItemsIds, onItemSelection } = useInteractivity(targetFilter);

        const shouldRender = !!items.length;

        return shouldRender ? (
            <WithTooltip
                anchor={TooltipAnchor.RIGHT}
                hidden={!disabled}
                content="Данный фильтр недоступен для изменения в процессе выгрузки корректировок"
            >
                <div className={styles.root}>
                    <FilterMenu
                        ref={ref}
                        {...(filterMenuProps as FilterMenuProps)}
                        qaLabel={filterMenuProps.filterTitle}
                        items={items}
                        checkedItemsIds={checkedItemsIds}
                        onItemSelection={onItemSelection}
                    />

                    {disabled && <div className={styles.nonActiveBackground} />}
                </div>
            </WithTooltip>
        ) : null;
    },
);
