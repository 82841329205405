import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { CreativeRequestContract } from '@api';
import type { StoreState } from '@store';

import { MrmClient } from '@api';
import { loadContracts, setSelectedLot1ContractId, setSelectedLot2ContractId } from '@store/pivotTable/actions';
import { getContracts, getSelectedLot1ContractId, getSelectedLot2ContractId } from '@store/pivotTable/selectors';

export interface ChildrenProps {
    loading: boolean;
    selectedContractId: string;
    contracts: CreativeRequestContract[];
    setSelectedContractId: (contractId: string) => void;
}

interface Props extends ExternalProps, Partial<MapProps & DispatchProps> {}

interface ExternalProps {
    lot: 'lot1' | 'lot2';
    onContractChange: (lot: number, contractId: string) => void;
    children: (props: ChildrenProps) => JSX.Element;
}

interface MapProps {
    contracts: Record<'lot1' | 'lot2', CreativeRequestContract[]>;
    selectedLot1ContractId: string;
    selectedLot2ContractId: string;
}

interface DispatchProps {
    loadContracts: (contracts: Record<'lot1' | 'lot2', CreativeRequestContract[]>) => void;
    setSelectedLot1ContractId: (contractId: string) => void;
    setSelectedLot2ContractId: (contractId: string) => void;
}

interface State {
    loading: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class WithClientData extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    public async componentDidMount() {
        await this.loadContracts();

        this.setState({ loading: false });
    }

    public render(): JSX.Element {
        const childrenProps = this.makeChildrenProps();

        return this.props.children(childrenProps);
    }

    private makeChildrenProps(): ChildrenProps {
        const { lot, contracts, selectedLot1ContractId, selectedLot2ContractId } = this.props;
        const { loading } = this.state;

        return {
            loading,
            selectedContractId: lot === 'lot1' ? selectedLot1ContractId : selectedLot2ContractId,
            contracts: contracts[lot],
            setSelectedContractId: this.setSelectedContractId,
        };
    }

    private async loadContracts(): Promise<void> {
        const client = await MrmClient.getInstance();

        const contracts = await client.domain.creativeRequests.getContracts();

        const activeContracts = contracts.filter((item) => item.model.status === 'active');

        const groupedContracts = await this.groupContractsByLot(activeContracts);

        this.props.loadContracts(groupedContracts);
    }

    private async groupContractsByLot(
        contracts: CreativeRequestContract[],
    ): Promise<Record<'lot1' | 'lot2', CreativeRequestContract[]>> {
        const groupedContracts: Record<'lot1' | 'lot2', CreativeRequestContract[]> = {
            lot1: [],
            lot2: [],
        };

        await Promise.all(
            contracts.map(async (item) => {
                const lotDictionary = await item.model.lot;

                const lotNumber = lodash.first(lotDictionary.value.match(/\d/g)) as '1' | '2';

                groupedContracts[`lot${lotNumber}`].push(item);
            }),
        );

        groupedContracts.lot1 = lodash.sortBy(groupedContracts.lot1, (item) => item.model.createdAt);
        groupedContracts.lot2 = lodash.sortBy(groupedContracts.lot2, (item) => item.model.createdAt);

        return groupedContracts;
    }

    @autobind
    private setSelectedContractId(contractId: string) {
        const { lot } = this.props;

        if (lot === 'lot1') {
            this.props.setSelectedLot1ContractId(contractId);
            this.props.onContractChange(1, contractId);
        } else {
            this.props.setSelectedLot2ContractId(contractId);
            this.props.onContractChange(2, contractId);
        }
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        contracts: getContracts(state),
        selectedLot1ContractId: getSelectedLot1ContractId(state),
        selectedLot2ContractId: getSelectedLot2ContractId(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            loadContracts,
            setSelectedLot1ContractId,
            setSelectedLot2ContractId,
        },
        dispatch,
    );
}
