import type {
    ActivityLineParams,
    LineParams,
    LinesGroup,
    OrganizationGroups,
    TaskLineParams,
} from '@store/calendar/types';
import { LineType } from '@store/calendar/types';
import type {
    ActivityItem,
    ConstructorParams as SidebartDrawerConstrucotParams,
    GroupTitleItem,
    TaskItem,
} from './drawers/sidebar';
import type { ConstructorParams as ChartCanvasConstructorParams } from './drawers/chart/ChartCanvasDrawer';
import type { Page } from './organizationGroupClipper/OrganizationGroupClipper';
import type { SidesSizes, Position } from './types';

import { Drawer } from '../index';
import { DateFilterDrawer } from './drawers/dateFilter';
import { SidebarDrawer } from './drawers/sidebar';
import { ChartCanvasDrawer } from './drawers/chart/ChartCanvasDrawer';

import { GROUP_HEIGHT, LINE_HEIGHT, ORGANIZATION_HEIGHT, PADDING, SIDEBAR_WIDTH } from './consts';

interface Props {
    pageData: Page;
    isFirstPage: boolean;
    chartWidth: number;
    topIndentOfFirstPage?: number;
}

export class PageCanvasMaker {
    private currentPosition: Position;

    private pageData: Page;
    private isFirstPage: boolean;
    private topIndentOfFirstPage: number;
    private chartWidth: number;
    private pageCanvas: HTMLCanvasElement;

    public constructor(props: Props) {
        this.pageData = props.pageData;
        this.isFirstPage = props.isFirstPage;

        this.topIndentOfFirstPage = props.topIndentOfFirstPage;
        this.chartWidth = props.chartWidth;

        this.currentPosition = {
            x: 0,
            y: 0,
        };
    }

    public async getCanvas(): Promise<HTMLCanvasElement> {
        this.init();
        await this.drawPage();
        return this.pageCanvas;
    }

    private async drawPage(): Promise<void> {
        Drawer.drawCanvasRootBackground(this.pageCanvas);

        if (this.isFirstPage) {
            await this.drawDateFilter();
        }

        this.pageData.organizations.map((organization) => {
            if (organization.name) {
                this.drawOrganizationName(organization.name);
            }

            organization.groups.map((group) => {
                this.drawChart(group);
                this.drawSidebar(group);
            });
        });
    }

    private async drawDateFilter(): Promise<void> {
        const dateFilter = new DateFilterDrawer({ canvas: this.pageCanvas });
        await dateFilter.draw();
    }

    private drawSidebar(group: LinesGroup): void {
        const sidebarDrawerParams = this.buildParamsForSidebarDrawer(group.lines);
        const sidebarDrawer = new SidebarDrawer(sidebarDrawerParams);

        this.shiftPositionByVertical(this.currentPosition.y + sidebarDrawerParams.sidesSizes.height + PADDING);
        sidebarDrawer.draw();
    }

    private drawChart(group: LinesGroup): void {
        const chartCanvasDrawerParams = this.buildParamsForChartDrawer(group);
        const chartDrawer = new ChartCanvasDrawer(chartCanvasDrawerParams);
        chartDrawer.draw();
    }

    private drawOrganizationName(name: string): void {
        Drawer.drawOrganizationName(this.pageCanvas, name, {
            x: this.currentPosition.x,
            y: this.currentPosition.y + ORGANIZATION_HEIGHT / 2,
        });
        this.shiftPositionByVertical(this.currentPosition.y + ORGANIZATION_HEIGHT);
    }

    private buildParamsForSidebarDrawer(lines: LineParams[]): SidebartDrawerConstrucotParams {
        const items = lines.map((line) => {
            switch (line.type) {
                case LineType.Activity:
                    return {
                        name: line.title,
                        type: line.type,
                        hasExpiredStages: (line as ActivityLineParams).hasExpiredStages,
                    } as ActivityItem;
                case LineType.Task:
                    return {
                        name: line.title,
                        type: line.type,
                        deadlineExpired: (line as TaskLineParams).deadlineExpired,
                        taskIsClosed: (line as TaskLineParams).taskIsClosed,
                    } as TaskItem;
                case LineType.GroupTitle:
                    return {
                        name: line.title,
                        type: line.type,
                    } as GroupTitleItem;
            }
        });

        const height = items.reduce((currentHeight, item) => {
            if (item.type === LineType.GroupTitle) {
                return currentHeight + GROUP_HEIGHT;
            }
            return currentHeight + LINE_HEIGHT;
        }, 0);

        return {
            canvas: this.pageCanvas,
            params: {
                items,
            },
            startPosition: {
                x: this.currentPosition.x,
                y: this.currentPosition.y,
            },
            sidesSizes: {
                width: SIDEBAR_WIDTH,
                height,
            },
            filledExpiredTaskIcon: this.pageData.iconMap.emptyExpiredTaskIcon,
        };
    }

    private buildParamsForChartDrawer(group: LinesGroup): ChartCanvasConstructorParams {
        const height = group.lines.reduce((currentHeight, item) => {
            if (item.type === LineType.GroupTitle) {
                return currentHeight + GROUP_HEIGHT;
            }
            return currentHeight + LINE_HEIGHT;
        }, 0);

        return {
            canvas: this.pageCanvas,
            group,
            startPosition: {
                x: this.currentPosition.x + SIDEBAR_WIDTH + PADDING,
                y: this.currentPosition.y,
            },
            sidesSizes: {
                width: this.chartWidth,
                height,
            },
            finishedTaskIcon: this.pageData.iconMap.finishedTaskIcon,
            filledExpiredTaskIcon: this.pageData.iconMap.filledExpiredTaskIcon,
        };
    }

    private calculatePageSidesSizes(organizationGroups: OrganizationGroups[], isFirstPage: boolean): SidesSizes {
        const sidesSizes: SidesSizes = { width: 0, height: 0 };

        organizationGroups.forEach((organization) => {
            if (organization.id) {
                sidesSizes.height = sidesSizes.height + ORGANIZATION_HEIGHT;
            }

            organization.groups.forEach((group) => {
                if (group.id) {
                    sidesSizes.height = sidesSizes.height + PADDING;
                }

                group.lines.forEach((line) => {
                    if (line.type === LineType.GroupTitle) {
                        sidesSizes.height = sidesSizes.height + GROUP_HEIGHT;
                    } else {
                        sidesSizes.height = sidesSizes.height + LINE_HEIGHT;
                    }
                });
            });
        });

        sidesSizes.width = SIDEBAR_WIDTH + PADDING * 3 + this.chartWidth;
        sidesSizes.height = sidesSizes.height + PADDING * 2 + (isFirstPage ? this.topIndentOfFirstPage : 0);

        return sidesSizes;
    }

    private init(): void {
        this.initStartCurrentPosition();
        this.initPageCanvas();
    }

    private initStartCurrentPosition(): void {
        if (this.isFirstPage) {
            this.initStartCurrentPositionWithTopIndent();
        } else {
            this.initStartCurrentPositionWithoutTopIndent();
        }
    }

    private initStartCurrentPositionWithTopIndent(): void {
        this.currentPosition = {
            x: PADDING,
            y: this.topIndentOfFirstPage,
        };
    }

    private initStartCurrentPositionWithoutTopIndent(): void {
        this.currentPosition = {
            x: PADDING,
            y: PADDING,
        };
    }

    private shiftPositionByVertical(y: number): void {
        this.currentPosition.y = y;
    }

    private initPageCanvas(): void {
        this.pageCanvas = this.createCanvasElement();
        const pageSidesSizes = this.calculatePageSidesSizes(this.pageData.organizations, this.isFirstPage);

        this.pageCanvas.width = pageSidesSizes.width;
        this.pageCanvas.height = pageSidesSizes.height;
    }

    private createCanvasElement(): HTMLCanvasElement {
        return document.createElement('CANVAS') as HTMLCanvasElement;
    }
}
