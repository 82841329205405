import * as React from 'react';

import { WithProjectLinkedBudgetItems } from '@common/queries/WithProjectLinkedBudgetItemIds';

import { WithStore } from './WithStore';
import { WithLinkRequests } from './WithLinkRequests';
import { WithUpdateLinksMutation } from './WithUpdateLinksMutation';
import { ActivityReferenceMenuContainer } from './ActivityReferenceMenuContainer';
import { WithProjectDomain } from './WithProjectDomain';

interface Props {
    selectedActivityId: string;
    filters: any;
    onClose: () => void;
    onFinish: () => void;
}

export class ActivityReferenceMenuConnected extends React.PureComponent<Props> {
    public componentDidMount(): void {
        console.log(this.props.selectedActivityId);
    }

    public render(): JSX.Element {
        const { selectedActivityId, filters, onClose, onFinish } = this.props;

        return (
            <WithStore data={{ selectedActivityId, filters }}>
                {({
                    loading: storeLoading,
                    budgetId,
                    selectedActivityId,
                    selectedBudgetItemIds,
                    expertUsers,
                    selectedExpertId,
                    setActivityReferenceMenu,
                    setNotification,
                    loadExperts,
                }) => (
                    <WithProjectLinkedBudgetItems activityId={selectedActivityId}>
                        {({ linkIds, loading: projectLinkedBudgetItemsLoading, refetch: refetchLinkRequests }) => (
                            <WithLinkRequests linkIds={linkIds}>
                                {({ linkRequests, loading: linkRequestsLoading }) => (
                                    <WithProjectDomain projectId={selectedActivityId}>
                                        {({ addBudgetItemId, removeBudgetItemId }) => (
                                            <WithUpdateLinksMutation
                                                budgetId={budgetId}
                                                activityId={selectedActivityId}
                                                expertId={selectedExpertId}
                                                selectedBudgetItemIds={selectedBudgetItemIds}
                                                existingLinks={linkRequests}
                                                refetchLinks={refetchLinkRequests}
                                                onBudgetItemAdded={addBudgetItemId}
                                                onBudgetItemRemoved={removeBudgetItemId}
                                            >
                                                {({ loading: linkUpdatesLoading, triggerLinksUpdate }) => (
                                                    <ActivityReferenceMenuContainer
                                                        showPreloader={
                                                            projectLinkedBudgetItemsLoading ||
                                                            linkRequestsLoading ||
                                                            linkUpdatesLoading ||
                                                            storeLoading
                                                        }
                                                        budgetId={budgetId}
                                                        existingLinks={linkRequests}
                                                        expertUsers={expertUsers}
                                                        selectedActivityId={selectedActivityId}
                                                        selectedExpertId={selectedExpertId}
                                                        selectedBudgetItemIds={selectedBudgetItemIds}
                                                        triggerLinksUpdate={triggerLinksUpdate}
                                                        setActivityReferenceMenu={setActivityReferenceMenu}
                                                        setNotification={setNotification}
                                                        loadExperts={loadExperts}
                                                        onClose={onClose}
                                                        onFinish={onFinish}
                                                    />
                                                )}
                                            </WithUpdateLinksMutation>
                                        )}
                                    </WithProjectDomain>
                                )}
                            </WithLinkRequests>
                        )}
                    </WithProjectLinkedBudgetItems>
                )}
            </WithStore>
        );
    }
}
