import * as React from 'react';

import * as style from './FinalStageWidget.scss';

import {
    BudgetStageWidget,
    BriefStageWidget,
    MediaplanStageWidget,
    // CreativeStatusesStageWidget,
    // SocialStageWidget
} from '..';

import { ContextBlock } from './ContextBlock';

interface Props {}

export const FinalStageWidgetTemplate = ({}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.stageWidget}>
                <BudgetStageWidget readOnly />
            </div>

            <div className={style.stageWidget}>
                <BriefStageWidget readOnly />
            </div>

            <div className={style.stageWidget}>
                <MediaplanStageWidget displayStatuses readOnly />
            </div>

            {/* <div className={style.stageWidget}>
                <CreativeStatusesStageWidget readOnly />
            </div> */}

            {/* <div className={style.stageWidget}>
                <SocialStageWidget readOnly />
            </div> */}

            <div className={style.stageWidget}>
                <ContextBlock />
            </div>
        </div>
    );
};
