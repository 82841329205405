import * as React from 'react';

import { Icon, IconType } from 'sber-marketing-ui';
import { Budget } from '@mrm/budget';
import { getStatusName } from './utils';

import * as styles from './DropdownItem.scss';

interface Props extends Budget {
    organizationName: string;
    selectedBudgetId: string;
    onClick: () => void;
}

export function DropdownItem({ id, status, year, selectedBudgetId, organizationName, onClick }: Props): JSX.Element {
    const statusName = getStatusName(status);
    const organization = organizationName || 'Организация не найдена';
    const statusFormatted = statusName ? `(${getStatusName(status)})` : '';
    const isSelected = id === selectedBudgetId;

    return (
        <div
            className={styles.root}
            onClick={onClick}
            {...{
                'qa-id': 'budgetAndOrganizationSelectorItem',
                'qa-title': `${organization} ${year}${statusName ? ` ${statusFormatted}` : ''}`,
                'qa-is-selected': isSelected ? 'true' : 'false',
            }}
        >
            <div>
                {organization}&nbsp;
                <span className={styles.greyText}>{year}</span>&nbsp;
                {statusName && <span className={styles.greyText}>{statusFormatted}</span>}
            </div>

            {isSelected ? <Icon type={IconType.CHECK_ICON} className={styles.checkedIcon} /> : null}
        </div>
    );
}
