import * as React from 'react';

import { LeaderDashboardApi } from '@api';
import type { GetDepartmentRatesResponseDto } from '@mrm-analytics-service/api-client';
import { FilterPeriod } from '../types';

interface UseRateByDepartments {
    (params: UseRateByDepartmentsParams): UseRateByDepartmentsReturn;
}

interface UseRateByDepartmentsParams {
    period: FilterPeriod;
    date: string;
    departmentId: string;
}

interface UseRateByDepartmentsReturn {
    rate: number;
    loading: boolean;
    load: Load;
}

interface Load {
    (): Promise<void>;
}

const useRateByDepartments: UseRateByDepartments = ({ period, date, departmentId }) => {
    const [result, setResult] = React.useState<GetDepartmentRatesResponseDto>(null);
    const [loading, setLoading] = React.useState<boolean>(false);

    const rate = result?.current
        ? result?.current?.reduce((prev, current) => prev + current.avgRate, 0) / result.current.length
        : 0;

    const load = React.useCallback(async () => {
        setLoading(true);
        LeaderDashboardApi.getRate({
            period,
            departmentsIds: [departmentId],
            currentDate: date,
        }).then((result) => {
            setResult(result);
            setLoading(false);
        });
    }, [LeaderDashboardApi.getRate, departmentId, date, period, setResult]);

    return {
        rate,
        loading,
        load,
    };
};

export { useRateByDepartments };
