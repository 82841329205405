import * as React from 'react';
import * as lodash from 'lodash';

import * as styles from './TopLine.scss';

import { TopLineItem, TopLineItemProps } from '../TopLineItem';

export interface TopLineItemParams {
    title: string;
    value?: any;
    qaId?: string;
}

export interface TopLineProps {
    left: TopLineItemParams | TopLineItemParams[];
    right: TopLineItemParams | TopLineItemParams[];
}

export function TopLine({ left, right }: TopLineProps): JSX.Element {
    const leftList: TopLineItemProps[] = lodash.isArray(left) ? left : [left];
    const rightList: TopLineItemProps[] = lodash.isArray(right) ? right : [right];

    return (
        <div className={styles.topLine}>
            {leftList.length > 1 ? (
                <div className={styles.topLine_subgroup}>
                    {leftList.map(({ title, value, qaId }, index) => (
                        <TopLineItem
                            key={index}
                            title={title}
                            value={value}
                            className={styles.topLineItem}
                            qaId={qaId}
                        />
                    ))}
                </div>
            ) : (
                <TopLineItem
                    title={leftList[0].title}
                    value={leftList[0].value}
                    className={styles.topLineItem}
                    qaId={leftList[0].qaId}
                />
            )}

            {rightList.length > 1 ? (
                <div className={styles.topLine_subgroup}>
                    {rightList.map(({ title, value, qaId }, index) => (
                        <TopLineItem
                            key={index}
                            title={title}
                            value={value}
                            qaId={qaId}
                            className={styles.topLineItem}
                        />
                    ))}
                </div>
            ) : (
                <TopLineItem
                    title={rightList[0].title}
                    value={rightList[0].value}
                    qaId={rightList[0].qaId}
                    className={styles.topLineItem}
                />
            )}
        </div>
    );
}
