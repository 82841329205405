import * as React from 'react';
import classNames from 'classnames';

import * as style from './TableSettings.scss';

import type { SelectItem } from 'sber-marketing-ui';

import {
    Select_redesign as Select,
    SelectTheme_redesign as SelectTheme,
    OpenerIconType_redesign as SelectIconType,
} from 'sber-marketing-ui';
import { Checkbox, SelectionType } from './Checkbox';
import { useTableSettingsHooks } from './hooks';

interface Props {}

export const TableSettings = ({}: Props): JSX.Element => {
    const {
        budgetAutomationEnabled,
        selectionByFormat,
        timingsSelectItemsByFormat,
        selectedFormatTimings,
        onBudgetAutomationCheckboxClick,
        onFormatCheckboxClick,
        onSelectChange,
    } = useTableSettingsHooks();

    return (
        <div className={style.root}>
            <div className={style.autoAllocateCheckbox}>
                <Checkbox
                    selection={budgetAutomationEnabled ? SelectionType.Checked : SelectionType.Unchecked}
                    onClick={onBudgetAutomationCheckboxClick}
                >
                    <div className={style.autoAllocateCheckboxTitle}>Автоматически дораспределять бюджет</div>
                </Checkbox>
            </div>

            <div className={style.line}>
                <div className={style.lineContent}>
                    <div className={style.formatSelection}>
                        <Checkbox
                            selection={selectionByFormat['MF']}
                            onClick={(newSelection: SelectionType) => onFormatCheckboxClick(newSelection, 'MF')}
                        >
                            <div className={style.formatCheckboxTitle}>MF — медиафасады</div>
                        </Checkbox>

                        <div className={style.formatSelect}>
                            <Select
                                theme={SelectTheme.Rounded}
                                openerIcon={SelectIconType.Arrow8}
                                options={timingsSelectItemsByFormat['MF']}
                                value={selectedFormatTimings['MF']}
                                onChange={(item: SelectItem) => onSelectChange(item.value as number, 'MF')}
                                disabled={selectionByFormat['MF'] === SelectionType.Unchecked}
                                disableSearch
                            />
                        </div>
                    </div>

                    <div className={style.formatSelection}>
                        <Checkbox
                            selection={selectionByFormat['DBB и DSS']}
                            onClick={(newSelection: SelectionType) => onFormatCheckboxClick(newSelection, 'DBB и DSS')}
                        >
                            <div className={style.formatCheckboxTitle}>DBB и DSS — Билборды и суперсайты</div>
                        </Checkbox>

                        <div className={style.formatSelect}>
                            <Select
                                theme={SelectTheme.Rounded}
                                openerIcon={SelectIconType.Arrow8}
                                options={timingsSelectItemsByFormat['DBB и DSS']}
                                value={selectedFormatTimings['DBB и DSS']}
                                onChange={(item: SelectItem) => onSelectChange(item.value as number, 'DBB и DSS')}
                                disabled={selectionByFormat['DBB и DSS'] === SelectionType.Unchecked}
                                disableSearch
                            />
                        </div>
                    </div>

                    <div className={style.formatSelection}>
                        <Checkbox
                            selection={selectionByFormat['DCF']}
                            onClick={(newSelection: SelectionType) => onFormatCheckboxClick(newSelection, 'DCF')}
                        >
                            <div className={style.formatCheckboxTitle}>DCF — ситиформаты</div>
                        </Checkbox>

                        <div className={style.formatSelect}>
                            <Select
                                theme={SelectTheme.Rounded}
                                openerIcon={SelectIconType.Arrow8}
                                options={timingsSelectItemsByFormat['DCF']}
                                value={selectedFormatTimings['DCF']}
                                onChange={(item: SelectItem) => onSelectChange(item.value as number, 'DCF')}
                                disabled={selectionByFormat['DCF'] === SelectionType.Unchecked}
                                disableSearch
                            />
                        </div>
                    </div>

                    <div className={classNames(style.formatSelection, false && style.disabled)}>
                        <Checkbox
                            selection={selectionByFormat['static']}
                            onClick={(newSelection: SelectionType) => onFormatCheckboxClick(newSelection, 'static')}
                        >
                            <div className={style.formatCheckboxTitle}>Использовать статичные конструкции</div>
                        </Checkbox>
                    </div>
                </div>
            </div>
        </div>
    );
};
