import autobind from 'autobind-decorator';
import { PureComponent, createElement } from 'react';

import { ChannelInfoContainerProps as Props, ChannelInfoContainerState as State } from './types';
import { ChannelInfoTemplate } from './ChannelInfoTemplate';

/** "ChannelInfo" container component */
export class ChannelInfoContainer extends PureComponent<Props, State> {
    public static readonly displayName: string = 'frontend::task::ChannelInfo::Container';

    public state: State = {
        isEditorModalOpened: false,
    };

    public render() {
        const {
            className,
            channelId,
            participantsCount,
            isUserAuthor,
            authorDepartmentName,
            authorFirstName,
            authorMiddleName,
            authorSecondName,
            createTime,
        } = this.props;
        const { isEditorModalOpened } = this.state;

        return createElement(ChannelInfoTemplate, {
            isEditorModalOpened,
            className,
            channelId,
            participantsCount,
            isUserAuthor,
            authorDepartmentName,
            authorFirstName,
            authorMiddleName,
            authorSecondName,
            onEditCurrentOpen: this.onEditCurrentOpen,
            onEditCurrentClose: this.onEditCurrentClose,
            createTime,
        });
    }

    /** Open editor for current channel handler */
    @autobind
    private onEditCurrentOpen(): void {
        this.setState(() => ({ isEditorModalOpened: true }));
    }

    /** Close editor for current channel handler */
    @autobind
    private onEditCurrentClose(): void {
        this.setState(() => ({ isEditorModalOpened: false }));
    }
}
