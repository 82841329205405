import * as React from 'react';

import { DropdownParams, useDefaultRef, useDropdown } from '@common/hooks';

import { Dropdown, DropdownProps } from '@common/components';

import { Option, OptionProps } from '../Option';

export interface DropdownOptionProps<O> extends OptionProps<O>, Omit<DropdownParams, 'target'> {
    dropdownProps?: Partial<DropdownProps>;
    dropdownWidth?: React.HTMLProps<HTMLDivElement>['style']['width'];
}

export function DropdownOption<O>({
    rootRef,
    autoPlace,
    stretch,
    closeDelay,
    dropdownRef,
    children,
    dropdownClassName,
    isDropdownHide,
    isDropdownShow,
    onChangeDropdownPlacement,
    onHideChangeDropdown,
    onShowChangeDropdown,
    placement,
    dropdownWidth,
    showDelay,
    trigger,
    dropdownProps,
    ...props
}: DropdownOptionProps<O>) {
    const ref = useDefaultRef(rootRef);

    const options: Required<DropdownParams> = {
        autoPlace,
        target: ref,
        stretch,
        closeDelay,
        dropdownRef,
        dropdownClassName,
        isDropdownHide,
        isDropdownShow,
        onChangeDropdownPlacement,
        onHideChangeDropdown,
        onShowChangeDropdown,
        placement,
        trigger,
        showDelay,
    };

    const [dropdown] = useDropdown(options);

    return (
        <Option rootRef={ref} {...props}>
            <Dropdown width={dropdownWidth} {...dropdownProps} dropdown={dropdown}>
                {children}
            </Dropdown>
        </Option>
    );
}
