/** Task loading statuses */
export enum TaskLoadingStatus {
    /** Task not loaded */
    NOT_LOADED,
    /** Task loading in progress */
    LOADING,
    /** Task was loaded */
    LOADED,
    /** Error was received while task was loading */
    ERROR,
    /** Task creating in progress */
    CREATING,
    /** Task updating in progress */
    UPDATING,
}
