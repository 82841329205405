const URLS_REGEX =
    /(https?:\/\/)?(www\.)?([a-z0-9]+\-*[a-z0-9]*\.)+([a-z]{2,5})(\/[\w-.]+)*((\/)?\?[\w=%-.\\^_`{|}~\/]+)?/gi;

const PROTOCOL_REGEX = /^(https?:\/\/)/i;

export function highlightUrls(text: string, linkCssClass = ''): string {
    return text.replace(URLS_REGEX, (match) => {
        const urlHasProtocol = PROTOCOL_REGEX.test(match);

        const url = urlHasProtocol ? match : `https://${match}`;

        return `<a href="${url}" class="${linkCssClass}" target="_blank">${match}</a>`;
    });
}
