import { get, isNil } from 'lodash';
import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { UserRelatedOrganizations } from 'sber-marketing-types/frontend';

import { loadUserOrganizations as loadUserOrganizationsAction } from './actions';
import { StoreState } from '@store';
import { getLoginUser } from '@store/user';
import { UserApi } from '@api';

/** Load user's related organizations from server and put them into store */
export const loadUserOrganizations = bindThunkAction<StoreState, null | undefined, UserRelatedOrganizations, Error>(
    loadUserOrganizationsAction,
    (_1, _2, getState) => {
        const userId: number = get(getLoginUser(getState()), 'attributes.id');
        return isNil(userId)
            ? Promise.reject(new Error('Not authorized'))
            : UserApi.getUserRelatedOrganizations(userId);
    },
);
