import * as React from 'react';
import classNames from 'classnames';

import { Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';

import * as styles from './styles.scss';

interface Props {
    className?: string;
    onSaveButtonClick: () => void;
}

export const Footer: React.FC<Props> = ({ className, onSaveButtonClick }) => {
    return (
        <div className={classNames(styles.root, className)}>
            <Button style={{ height: '48px' }} theme={ButtonTheme.MainRounded} onClick={onSaveButtonClick}>
                Согласовать
            </Button>
        </div>
    );
};
