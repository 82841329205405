import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { AutopilotDOOHMediaplanCalculation } from 'sber-marketing-types/backend';
import { TableHeaderCellParams, ColumnName, SortingParams } from '../../types';
import { ColumnHeaderType, ColumnParams } from '../../ColumnsConfig';

import {
    TextColumnHeader,
    CheckboxWithSortingColumnHeader,
    TextWithSortingColumnHeader,
} from '../../ColumnHeaderTypes';

export const ColumnHeaderComponentsByType: Record<ColumnHeaderType, React.ClassType<any, any, any>> = {
    [ColumnHeaderType.CheckboxWithSorting]: CheckboxWithSortingColumnHeader,
    [ColumnHeaderType.TextWithSorting]: TextWithSortingColumnHeader,
    [ColumnHeaderType.Text]: TextColumnHeader,
};

interface Props {
    getColumnsConfig: () => { [columnName: string]: ColumnParams };
    getSortingParams: () => SortingParams;
    getAllLines: () => AutopilotDOOHMediaplanCalculation[];
    getSelectedLinesIds: () => string[];
    onCheckboxClick: () => void;
    onSortingClick: (columnName: ColumnName) => void;
}

export class ColumnHeaderFactory {
    private getColumnsConfig: () => { [columnName: string]: ColumnParams };
    private getSortingParams: () => SortingParams;
    private getAllLines: () => AutopilotDOOHMediaplanCalculation[];
    private getSelectedLinesIds: () => string[];
    private onCheckboxClick: () => void;
    private onSortingClick: (columnName: ColumnName) => void;

    public constructor(props: Props) {
        this.getColumnsConfig = props.getColumnsConfig;
        this.getSortingParams = props.getSortingParams;
        this.getAllLines = props.getAllLines;
        this.getSelectedLinesIds = props.getSelectedLinesIds;
        this.onCheckboxClick = props.onCheckboxClick;
        this.onSortingClick = props.onSortingClick;
    }

    @autobind
    public makeColumnHeaderParams(columnName: ColumnName): TableHeaderCellParams {
        return {
            component: this.getColumnHeaderComponent(columnName),
            cellProps: this.makeColumnHeaderProps(columnName),
            disableWidthChange: !this.columnWidthCanBeChanged(columnName),
        };
    }

    private getColumnHeaderComponent(columnName: ColumnName): React.ClassType<any, any, any> {
        const columnType = this.getColumnsConfig()[columnName].headerType;

        return ColumnHeaderComponentsByType[columnType];
    }

    private makeColumnHeaderProps(columnName: ColumnName): any {
        const headerType = this.getColumnsConfig()[columnName].headerType;

        let cellProps: any;

        switch (headerType) {
            case ColumnHeaderType.Text:
                cellProps = this.makeTextColumnHeaderProps(columnName as ColumnName);
                break;

            case ColumnHeaderType.CheckboxWithSorting:
                cellProps = this.makeCheckboxWithSortingColumnHeaderProps(columnName as ColumnName);
                break;

            case ColumnHeaderType.TextWithSorting:
                cellProps = this.makeTextWithSortingColumnHeaderProps(columnName as ColumnName);
                break;
        }

        return cellProps;
    }

    private makeTextColumnHeaderProps(columnName: ColumnName): any {
        return {
            title: this.getColumnsConfig()[columnName].title as string,
        };
    }

    private makeCheckboxWithSortingColumnHeaderProps(columnName: ColumnName): any {
        const sortingParams = this.getSortingParams();
        const selectedLinesIds = this.getSelectedLinesIds();
        const allLines = this.getAllLines();

        const allLinesSelected = allLines.every((item) => selectedLinesIds.includes(item.rowId));
        const someSelected = allLines.some((item) => selectedLinesIds.includes(item.rowId));

        return {
            displayChecked: allLinesSelected,
            displayMinus: !allLinesSelected && someSelected,
            sortingOrder: sortingParams?.columnName === columnName ? sortingParams?.orderType : null,
            onCheckboxClick: this.onCheckboxClick,
            onSortingClick: () => this.onSortingClick(columnName),
        };
    }

    private makeTextWithSortingColumnHeaderProps(columnName: ColumnName): any {
        const sortingParams = this.getSortingParams();

        return {
            title: this.getColumnsConfig()[columnName].title as string,
            sortingOrder: sortingParams?.columnName === columnName ? sortingParams?.orderType : null,
            onClick: () => this.onSortingClick(columnName),
        };
    }

    private columnWidthCanBeChanged(columnName: ColumnName): boolean {
        return lodash.get(this.getColumnsConfig(), [columnName, 'disableWidthChange']) !== undefined
            ? !lodash.get(this.getColumnsConfig(), [columnName, 'disableWidthChange'])
            : true;
    }
}
