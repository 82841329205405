import * as React from 'react';

import * as style from './VideoField.scss';

import { ILCreativeFieldSpec } from 'sber-marketing-types/backend';

import {} from 'sber-marketing-ui';

interface Props {
    field: ILCreativeFieldSpec;
}

export const VideoField = ({ field }: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.title}>
                {field.fieldTitle}

                {field.required && (
                    <>
                        &nbsp;
                        <span className={style.requiredIndicator}>*</span>
                    </>
                )}
            </div>

            {field.description && <div className={style.description}>{field.description}</div>}
        </div>
    );
};
