import * as React from 'react';

import { IntervalProgress, ShortReport } from '../../common';
import { useState, StatisticsItem } from './useState';
import { useMode } from '../../hooks';

import { buildParamsByMode, BuildParamsData } from './utils';
import * as styles from './styles.scss';

export const SomeShortReports: React.FC = () => {
    const { selectedStatisticsItem, selectedPeriod, statisticsTypeFilters, setStatisticsTypeFilter } = useState();
    const { mode } = useMode();

    let data: BuildParamsData[] = [];

    if (mode === 'activities') {
        data = buildParamsByMode['activities']({
            selectedPeriod,
            statisticsTypeFilters,
            selectedStatisticsItem: selectedStatisticsItem as StatisticsItem<'activities'>,
            setStatisticsTypeFilter,
        });
    }

    if (mode === 'tasks') {
        data = buildParamsByMode['tasks']({
            selectedPeriod,
            statisticsTypeFilters,
            selectedStatisticsItem: selectedStatisticsItem as StatisticsItem<'tasks'>,
            setStatisticsTypeFilter,
        });
    }

    return (
        <div className={styles.root}>
            {data.map(({ color, titleComponent, count, progress, fractionOfTotal, periodName, darken, onClick }) => (
                <ShortReport
                    key={color}
                    className={styles.reportWrapper}
                    darken={darken}
                    count={count}
                    title={titleComponent}
                    fractionOfTotal={fractionOfTotal}
                    footer={
                        <IntervalProgress
                            direction={progress.direction}
                            result={progress.result}
                            value={progress.value}
                            period={periodName}
                        />
                    }
                    color={color}
                    onClick={onClick}
                />
            ))}
        </div>
    );
};
