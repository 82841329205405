import * as React from 'react';

import { Activity } from '../types';

import { ActivityWidget } from './ActivityWidget';

interface Props {
    selectedCardId: React.ReactText;
    activityId: React.ReactText;
    activity: Activity;
    loading: boolean;
    requestActivityName: string;
    loadActivity: (activityId: React.ReactText) => void;
}

export class ActivityWidgetContainer extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);
    }

    public componentDidUpdate(prevProps: Props): void {
        const selectedCardIdChanged = this.props.selectedCardId !== prevProps.selectedCardId;

        if (selectedCardIdChanged) {
            this.props.loadActivity(this.props.activityId);
        }
    }

    public render(): JSX.Element {
        const { loading, activity, requestActivityName } = this.props;

        return React.createElement(ActivityWidget, {
            loading,
            activity,
            requestActivityName,
        });
    }
}
