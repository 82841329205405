import { Dispatch } from 'redux';
import { isNumber } from 'lodash';

import { TaskApi } from '@api';

import { StoreState } from '@store';
import { getTaskData, editByTaskData } from '@store/taskEditor';
import { uploadFilesWorker } from './uploadFilesWorker';

export const updateTaskWorker = async (
    dispatch: Dispatch<StoreState>,
    getState: () => StoreState,
    participants: number[],
) => {
    const state = getState();

    const {
        id,
        title,
        deadline,
        description,
        workTypeId,
        executorId,
        departmentId: departmentExecutorId,
    } = getTaskData(state);

    const executionOrder = isNumber(executorId) ? { workTypeId, executorId } : { workTypeId, departmentExecutorId };

    await TaskApi.updateTaskParticipants(id, participants);

    const { task } = await TaskApi.editTask(id, {
        title,
        description,
        executionOrder,
        deadline: deadline.toISOString(),
    });

    await TaskApi.deleteDraftTaskInfo(id);

    uploadFilesWorker(task.id, dispatch, getState);

    dispatch(editByTaskData(task));
};
