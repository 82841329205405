import * as html2canvasWrong from 'html2canvas';
import * as lodash from 'lodash';

import type { Html2Canvas, SidesSizes } from './types';
import { CanvasScaler } from './CanvasScaler';
import { PdfBuilder } from './PdfBuilder';

const SCALE = 2;
const PADDING = 10;

export class WeekDigestPdfMaker {
    private pdfSidesSizes: SidesSizes;

    private topMenuCanvas: CanvasScaler;
    private chartCanvas: CanvasScaler;

    private html2canvas: Html2Canvas;
    private pdfBuilder: PdfBuilder;

    constructor() {
        this.html2canvas = html2canvasWrong as any as Html2Canvas;
        this.pdfBuilder = new PdfBuilder();
    }

    public async upload(): Promise<void> {
        await this.init();
        await this.buildPdf();
        this.save();
    }

    private async init(): Promise<void> {
        await this.initTopMenuCanvas();
        await this.initChartCanvas();
        this.initPdfSideSize();
    }

    private async initTopMenuCanvas(): Promise<void> {
        const topMenuElement = document.getElementById('week-digest-top-menu');
        this.topMenuCanvas = await this.convertElementToCanvas(topMenuElement);
    }

    private async initChartCanvas(): Promise<void> {
        const chartElement = document.getElementById('week-digest-chart');
        this.chartCanvas = await this.convertElementToCanvas(chartElement);
    }

    private initPdfSideSize(): void {
        this.pdfSidesSizes = {
            width: lodash.max([this.topMenuCanvas.width, this.chartCanvas.width]) + PADDING * 2,
            height: this.topMenuCanvas.height + this.chartCanvas.height + PADDING * 3,
        };
    }

    private async buildPdf(): Promise<void> {
        this.pdfBuilder.addPage({
            sidesSizes: {
                width: this.pdfSidesSizes.width,
                height: this.pdfSidesSizes.height,
            },
        });

        this.pdfBuilder.addCanvas({
            canvas: this.topMenuCanvas.getCanvas(),
            position: {
                x: PADDING,
                y: PADDING,
            },
            sidesSizes: {
                width: this.topMenuCanvas.width,
                height: this.topMenuCanvas.height,
            },
        });

        this.pdfBuilder.addCanvas({
            canvas: this.chartCanvas.getCanvas(),
            position: {
                x: PADDING,
                y: this.topMenuCanvas.height + PADDING * 2,
            },
            sidesSizes: {
                width: this.chartCanvas.width,
                height: this.chartCanvas.height,
            },
        });
    }

    private save(): void {
        this.pdfBuilder.save('Запуски недели');
    }

    private async convertElementToCanvas(element: HTMLElement): Promise<CanvasScaler> {
        const canvas = await this.html2canvas(element, { scale: SCALE, backgroundColor: '#f1f5f7' });
        return new CanvasScaler(canvas, SCALE);
    }
}
