// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ExternalTransitionMenu-InitTransferButton-_InitTransferButton__root{position:relative;height:36px}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ExternalTransitionMenu-InitTransferButton-_InitTransferButton__rootDisabledMask{position:absolute;width:100%;height:100%;background:rgba(255,255,255,0.8)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/ExternalTransitionMenu/InitTransferButton/InitTransferButton.scss"],"names":[],"mappings":"AAAA,6IACI,iBAAkB,CAClB,WAAY,CACf,yJAGG,iBAAkB,CAClB,UAAW,CACX,WAAY,CAEZ,gCAAiC","sourcesContent":[".root {\n    position: relative;\n    height: 36px;\n}\n\n.rootDisabledMask {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n\n    background: rgba(255,255,255,0.8);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ExternalTransitionMenu-InitTransferButton-_InitTransferButton__root",
	"rootDisabledMask": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-ExternalTransitionMenu-InitTransferButton-_InitTransferButton__rootDisabledMask"
};
export default ___CSS_LOADER_EXPORT___;
