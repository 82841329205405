import * as React from 'react';

import * as style from './Table.scss';

import type { CellEvent, CellPosition, ColumnName, ColumnWidths, LineId } from './types';

import { TableViewModel } from './TableViewModel';
import { TableView } from './TableView';

interface Props {
    viewModel: TableViewModel;
    tableColumns: ColumnName[];
    lineGroups: LinesGroup[];
    readOnlyColumns: ColumnName[];
    columnWidths: ColumnWidths;
    tableRef: (component: TableView, groupName: string) => void;
    onCellEvent: (eventType: CellEvent, position: CellPosition) => void;
}

export interface LinesGroup {
    name: string;
    lines: LineId[];
}

export const TableTemplate = ({
    viewModel,
    tableColumns,
    lineGroups,
    readOnlyColumns,
    columnWidths,
    tableRef,
    onCellEvent,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            {lineGroups.map((group) => (
                <div className={style.group} key={group.name}>
                    <div className={style.groupName}>{group.name}</div>

                    <div className={style.table}>
                        <TableView
                            ref={(component: TableView) => tableRef(component, group.name)}
                            viewModel={viewModel}
                            columns={tableColumns}
                            readOnlyColumns={readOnlyColumns}
                            lines={group.lines}
                            columnWidths={columnWidths}
                            onCellEvent={onCellEvent}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};
