import { createSelector } from 'reselect';
import * as lodash from 'lodash';

import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type {
    AutopilotTV,
    MediaplanPlanDataTVTotalResponse as MediaplanPlanDataTVTotal,
    AutopilotTVTargetGroup,
    AutopilotTVVideoDuration,
} from 'sber-marketing-types/backend';
import type { PageState, BriefStageForm, GroupedDictionaries, Sidebar, MediaplanItem } from './types';
import { AutopilotStage } from './types';

import type { StoreState } from '../';

export const getAutopilotPageState = (state: StoreState): PageState => state.autopilotTv;

export const getAutopilot = createSelector(getAutopilotPageState, (state: PageState): AutopilotTV => {
    return state.autopilot;
});

export const getActivity = createSelector(getAutopilotPageState, (state: PageState): Activity => {
    return state.activity;
});

export const getMediaplan = createSelector(getAutopilotPageState, (state: PageState): MediaplanItem[] => {
    return state.mediaplan;
});

export const getMediaplanTotal = createSelector(getAutopilotPageState, (state: PageState): MediaplanPlanDataTVTotal => {
    return state.mediaplanTotal;
});

export const getTargetGroups = createSelector(getAutopilotPageState, (state: PageState): AutopilotTVTargetGroup[] => {
    return state.targetGroups;
});

export const getVideoDurations = createSelector(
    getAutopilotPageState,
    (state: PageState): AutopilotTVVideoDuration[] => {
        return state.videoDurations;
    },
);

export const getDictionaries = createSelector(getAutopilotPageState, (state: PageState): GroupedDictionaries => {
    return state.dictionaries;
});

export const getBriefStageForm = createSelector(getAutopilotPageState, (state: PageState): BriefStageForm => {
    return state.briefStageForm;
});

export const getVideosInvalidWeeksIndexes = createSelector(
    getBriefStageForm,
    (briefStageForm: BriefStageForm): number[] => {
        const { videos } = briefStageForm;

        const weeksCount = lodash.first(videos).percents.length;

        const invalidWeeksIndexes: number[] = [];

        lodash.times(weeksCount).forEach((weekIndex) => {
            const weekIsValid = lodash.sumBy(videos, (item) => item.percents[weekIndex]?.value || 0) === 100;

            if (!weekIsValid) {
                invalidWeeksIndexes.push(weekIndex);
            }
        });

        return invalidWeeksIndexes;
    },
);

export const getManualRatingSplitValidation = createSelector(
    getBriefStageForm,
    (briefStageForm: BriefStageForm): boolean => {
        const { manualRatingSplit } = briefStageForm;

        return lodash.sumBy(manualRatingSplit, (item) => item.value) === 100;
    },
);

export const getSelectedBudgetItemIds = createSelector(getAutopilotPageState, (state: PageState): string[] => {
    return state.selectedBudgetItemIds;
});

export const getCurrentStage = createSelector(getAutopilotPageState, (state: PageState): AutopilotStage => {
    return state.currentStage;
});

export const getPreloaderStatus = createSelector(getAutopilotPageState, (state: PageState): boolean => {
    return state.preloader;
});

export const getSidebar = createSelector(getAutopilotPageState, (state: PageState): Sidebar => {
    return state.sidebar;
});
