import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DataProps, graphql } from 'react-apollo';
import { get } from 'lodash';
import { parse } from 'query-string';

import { SelectedCard, CardType, CardsLoadingMode, CardsLoadingModeFilter } from '@store/dashboardPage';

import { WITH_REQUEST_BY_ID_QUERY } from './query';

interface Props extends ExternalProps, QueryProps, RouteProps {}

interface ExternalProps {
    requestId: string;
    children: () => JSX.Element;
    setRequestIdForStatusCheck: (requestId: string) => void;
    setCardsLoadingModeFilter: (filter: CardsLoadingModeFilter) => void;
    setSelectedCard: (card: SelectedCard) => void;
}

type QueryProps = DataProps<{
    request: {
        nodes: {
            status: 'pending' | 'approved' | 'rejected';
        }[];
    };
}>;

type RouteProps = Partial<
    RouteComponentProps<{
        activityId?: string;
        requestId?: string;
    }>
>;

@(withRouter as any)
class WithPageModeCheckWrapper extends React.PureComponent<Props> {
    private activityId: number = null;
    private requestId: string = null;

    public componentDidMount(): void {
        this.parseQueryParams();
    }

    public componentDidUpdate(): void {
        if (this.requestId && !this.props.data.loading) {
            const status = get(this.props, 'data.request.nodes[0].status');

            this.props.setSelectedCard({
                cardType: CardType.BudgetItemToProjectLinkRequest,
                activityId: this.activityId,
                id: this.requestId,
            });
            this.props.setCardsLoadingModeFilter({
                [CardType.BudgetItemToProjectLinkRequest]:
                    status === 'pending' ? CardsLoadingMode.Current : CardsLoadingMode.Archive,
            });

            this.activityId = null;
            this.requestId = null;
        }
    }

    public render(): JSX.Element {
        return this.props.children();
    }

    private async parseQueryParams(): Promise<void> {
        const {
            location: { search },
        } = this.props;

        const queryParams = parse(search);
        if (queryParams.activityId && queryParams.requestId) {
            this.activityId = Number(queryParams.activityId);
            this.requestId = queryParams.requestId as string;

            this.props.setRequestIdForStatusCheck(this.requestId);
            this.props.history.push('/managerDashboard');
        }
    }
}

export const WithPageModeCheck = graphql<ExternalProps>(WITH_REQUEST_BY_ID_QUERY, {
    options: ({ requestId }) => ({
        variables: {
            requestId,
        },
    }),
    skip: ({ requestId }) => !requestId,
})(WithPageModeCheckWrapper);
