// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-main-Textarea-_Textarea__root{align-items:flex-start;min-height:fit-content !important}.src-client-modules-common-components-main-Textarea-_Textarea__input{min-height:var(--ui-textarea-min-height, 18px);max-height:var(--ui-textarea-max-height, auto);resize:var(--ui-textarea-resize, none)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/main/Textarea/Textarea.scss"],"names":[],"mappings":"AAAA,oEACE,sBAAuB,CACvB,iCAAkC,CACnC,qEAGC,8CAA+C,CAC/C,8CAA+C,CAC/C,sCAAuC","sourcesContent":[".root {\n  align-items: flex-start;\n  min-height: fit-content !important;\n}\n\n.input {\n  min-height: var(--ui-textarea-min-height, 18px);\n  max-height: var(--ui-textarea-max-height, auto);\n  resize: var(--ui-textarea-resize, none);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-components-main-Textarea-_Textarea__root",
	"input": "src-client-modules-common-components-main-Textarea-_Textarea__input"
};
export default ___CSS_LOADER_EXPORT___;
