import { useState, useCallback } from 'react';

export function useDefaultState<T, F extends (...a: any[]) => any>(
    value: T,
    setValue: F,
    defaultValue?: T,
    preventDefault?: boolean,
): [T, F] {
    const [state, setState] = useState<T>(defaultValue);
    const setNewValue = useCallback<F>(
        ((...args) => {
            setValue?.(...args);

            if (value === undefined && !preventDefault) {
                setState(args[0]);
            }
        }) as F,
        [setValue, setState, value],
    );

    return [value === undefined ? state : value, setNewValue];
}
