// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-components-TelegramButton-AuthorizationPopup-Button-_styles__root{height:40px;padding:0 17px;display:flex;align-items:center;justify-content:center;cursor:pointer;transition:0.1s all ease-out;text-align:center;border-radius:5px;border:2px solid #E3E9E5;background-color:#ffffff;font-family:\"Open Sans\";font-style:normal;font-weight:600;font-size:12px;line-height:16px;letter-spacing:0.15px;color:#000000}.src-client-modules-activity-pages-task-components-TelegramButton-AuthorizationPopup-Button-_styles__root:hover{border:2px solid #cad0ca}.src-client-modules-activity-pages-task-components-TelegramButton-AuthorizationPopup-Button-_styles__root:active{border:2px solid #b0b6b0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/components/TelegramButton/AuthorizationPopup/Button/styles.scss"],"names":[],"mappings":"AAAA,0GACI,WAAY,CACZ,cAAe,CAEf,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CAEvB,cAAe,CACf,4BAA6B,CAC7B,iBAAkB,CAElB,iBAAkB,CAClB,wBAAyB,CACzB,wBAAyB,CAEzB,uBAAwB,CACxB,iBAAkB,CAClB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,qBAAsB,CACtB,aAAc,CAtBlB,gHAyBQ,wBAAyB,CAzBjC,iHA6BQ,wBAAyB","sourcesContent":[".root {\n    height: 40px;\n    padding: 0 17px;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    cursor: pointer;\n    transition: 0.1s all ease-out;\n    text-align: center;\n\n    border-radius: 5px;\n    border: 2px solid #E3E9E5;\n    background-color: #ffffff;\n\n    font-family: \"Open Sans\";\n    font-style: normal;\n    font-weight: 600;\n    font-size: 12px;\n    line-height: 16px;\n    letter-spacing: 0.15px;\n    color: #000000;\n\n    &:hover {\n        border: 2px solid #cad0ca;\n    }\n\n    &:active {\n        border: 2px solid #b0b6b0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-components-TelegramButton-AuthorizationPopup-Button-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
