import * as React from 'react';

export interface OverflowCheckResult {
    horizontal: boolean;
    vertical: boolean;
}

export function useTextOverflowCheck<E extends HTMLElement>(ref: React.RefObject<E>): OverflowCheckResult {
    const [horizontal, setHorizontal] = React.useState(false);
    const [vertical, setVertical] = React.useState(false);

    React.useEffect(() => {
        let horizontal = false;
        let vertical = false;

        if (ref?.current) {
            const { scrollHeight, clientHeight, scrollWidth, clientWidth } = ref.current;

            horizontal = scrollWidth > clientWidth;
            vertical = scrollHeight > clientHeight;
        }

        setHorizontal(horizontal);
        setVertical(vertical);
    }, [ref.current]);

    return { horizontal, vertical };
}
