import * as React from 'react';
import * as queryString from 'query-string';
import * as lodash from 'lodash';
import { UserConfigType } from 'sber-marketing-types/openid';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Module } from 'sber-marketing-types/backend';
import autobind from 'autobind-decorator';

import { StoreState } from '@store';
import { ShareActivityPayload, shareActivity, getIsActivitySharingInProgress } from '@store/activitiesList';
import { loadUserConfig } from '@store/userConfig';
import { setRequestInProgress } from '@store/common/actions';
import { getLoginUser } from '@store/user';
import { resetDashboardUserConfig } from '@store/userConfig/dashboard';

import { HeaderView } from '@common/Page';

import { DashboardsPage, CurrentPage } from './DashboardsPage';
import { DashboardHeader } from './DashboardHeader';

const DASHBOARD_PAGE_PATHNAME = '/dashboard';

export interface Props extends Partial<MapProps>, Partial<DispatchProps>, RouteComponentProps {
    setHeaderView?: (view: HeaderView) => void;
}

interface MapProps {
    userHasBudgetAccess: boolean;
    sidebarActivitySharingInProgress: boolean;
}

interface DispatchProps {
    loadUserConfig: () => void;
    setPreload: (preloadStatus: boolean) => void;
    resetDashboardUserConfig: () => void;
    shareActivity: (payload: ShareActivityPayload) => void;
}

interface State {
    activitySidebarId: number;
    taskSidebarId: string;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class DashboardsPageContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            activitySidebarId: null,
            taskSidebarId: null,
        };

        this.renderHeader();
    }

    public async componentDidMount() {
        this.props.loadUserConfig();

        if (window.location.pathname === DASHBOARD_PAGE_PATHNAME) {
            this.props.setPreload(false);
        }
    }

    public componentWillUnmount() {
        this.props.resetDashboardUserConfig();
    }

    /*public componentWillReceiveProps(nextProps: Props): void {
        if (nextProps.dataIsLoaded) {
            this.props.loadUserConfig(null);
        }
    }*/

    public componentDidUpdate(prevProps: Props) {
        if (this.getCurrentPage() !== this.getCurrentPage(prevProps)) {
            this.renderHeader();
        }
    }

    public render(): JSX.Element {
        const { sidebarActivitySharingInProgress } = this.props;
        const { activitySidebarId, taskSidebarId } = this.state;

        return React.createElement(DashboardsPage, {
            currentPage: this.getCurrentPage(),
            sidebarActivitySharingInProgress,
            activitySidebarId,
            taskSidebarId,
            shareSidebarActivity: this.shareSidebarActivity,
            openActivitySidebar: this.openActivitySidebar,
            openTaskSidebar: this.openTaskSidebar,
            closeActivitySidebar: this.closeActivitySidebar,
            closeTaskSidebar: this.closeTaskSidebar,
            goBackToActivitySidebar: this.goBackToActivitySidebar,
            onBeforeTaskDeleted: this.onBeforeTaskDeleted,
        });
    }

    private renderHeader() {
        this.props.setHeaderView({
            firstLine: null,
            additionalContent: React.createElement(DashboardHeader, {
                currentPage: this.getCurrentPage(),
            }),
        });
    }

    private getCurrentPage(props: Props = this.props): CurrentPage {
        return (queryString.parse(props.location.search).currentPage as CurrentPage) || CurrentPage.MyTasks;
    }

    @autobind
    private shareSidebarActivity(usersIds: number[]): void {
        this.props.shareActivity({
            activityId: this.state.activitySidebarId,
            usersIds,
        });
    }

    @autobind
    private openActivitySidebar(activitySidebarId: number) {
        this.setState({ activitySidebarId, taskSidebarId: null });
    }

    @autobind
    private openTaskSidebar(taskSidebarId: string) {
        this.setState({ taskSidebarId });
    }

    @autobind
    private closeActivitySidebar() {
        this.setState({ activitySidebarId: null });
    }

    @autobind
    private closeTaskSidebar() {
        this.setState({ taskSidebarId: null });
    }

    @autobind
    private goBackToActivitySidebar(activitySidebarId: number) {
        this.setState({ activitySidebarId, taskSidebarId: null });
    }

    @autobind
    private onBeforeTaskDeleted(taskId: string): void {
        if (taskId === this.state.taskSidebarId) {
            this.setState({ taskSidebarId: null });
        }
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const user = getLoginUser(state);

    return {
        userHasBudgetAccess: lodash.includes(user.attributes.modules, Module.Budget),
        sidebarActivitySharingInProgress: getIsActivitySharingInProgress(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return {
        loadUserConfig: () => dispatch(loadUserConfig(UserConfigType.Dashboard)),
        setPreload: (preloadStatus: boolean) => dispatch(setRequestInProgress(preloadStatus)),
        resetDashboardUserConfig: () => dispatch(resetDashboardUserConfig()),
        shareActivity: (payload: ShareActivityPayload) => dispatch(shareActivity(payload)),
    };
}
