import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import { getTextsLoadingStatus, LoadingStatus } from '@store/autopilot/generationTextForm';
import { Preloader } from 'sber-marketing-ui';

import * as styles from './styles.scss';

interface Props {
    className?: string;
}

export const Screensaver: React.FC<Props> = ({ className }) => {
    const loading = useSelector((state: StoreState) => getTextsLoadingStatus(state));

    return (
        <div className={classNames(styles.root, className)}>{loading === LoadingStatus.LOADING && <Preloader />}</div>
    );
};
