// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-FeatureList-_styles__root{height:fit-content;padding:12px 16px 16px 16px;display:grid;grid-auto-rows:auto;grid-row-gap:10px;font-family:Open Sans;letter-spacing:0.15px;border-radius:6px;background:#F8F8F8}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-FeatureList-_styles__title{font-size:12px;font-weight:600;color:#7E8681}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-FeatureList-_styles__list{display:grid;grid-auto-rows:auto;grid-row-gap:8px}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-FeatureList-_styles__item{font-size:14px;color:#092A3A;line-height:16px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/CreationForm/ParamsEditor/FeatureList/styles.scss"],"names":[],"mappings":"AAAA,iIACI,kBAAmB,CACnB,2BAA4B,CAE5B,YAAa,CACb,mBAAoB,CACpB,iBAAkB,CAElB,qBAAsB,CACtB,qBAAsB,CAEtB,iBAAkB,CAClB,kBAAmB,CACtB,kIAGG,cAAe,CACf,eAAgB,CAChB,aAAc,CACjB,iIAGG,YAAa,CACb,mBAAoB,CACpB,gBAAiB,CACpB,iIAGG,cAAe,CACf,aAAc,CACd,gBAAiB","sourcesContent":[".root {\n    height: fit-content;\n    padding: 12px 16px 16px 16px;\n\n    display: grid;\n    grid-auto-rows: auto;\n    grid-row-gap: 10px;\n\n    font-family: Open Sans;\n    letter-spacing: 0.15px;\n\n    border-radius: 6px;\n    background: #F8F8F8;\n}\n\n.title {\n    font-size: 12px;\n    font-weight: 600;\n    color: #7E8681;\n}\n\n.list {\n    display: grid;\n    grid-auto-rows: auto;\n    grid-row-gap: 8px;\n}\n\n.item {\n    font-size: 14px;\n    color: #092A3A;\n    line-height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-FeatureList-_styles__root",
	"title": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-FeatureList-_styles__title",
	"list": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-FeatureList-_styles__list",
	"item": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-FeatureList-_styles__item"
};
export default ___CSS_LOADER_EXPORT___;
