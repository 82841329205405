import * as React from 'react';

import * as style from './DashboardsPage.scss';

import { ResponsiveLayout, CentralContent, RightSidebar, LayoutTheme } from 'sber-marketing-ui';

import { NewsColumn } from '@common/NewsColumn';
import { ActivitySidebar } from '@common/ActivitySidebar';
import { TaskSidebar } from '@common/TaskSidebar';

import { MyTasksPage } from './MyTasksPage';
import { AccessibleProjectsPage } from './AccessibleProjectsPage';
import { MyProjectsPage } from './MyProjectsPage';

export interface Props {
    currentPage: CurrentPage;
    activitySidebarId: number;
    taskSidebarId: string;
    sidebarActivitySharingInProgress: boolean;
    shareSidebarActivity: (userIds: number[]) => void;
    openActivitySidebar: (activityId: number) => void;
    openTaskSidebar: (taskId: string) => void;
    closeActivitySidebar: () => void;
    closeTaskSidebar: () => void;
    goBackToActivitySidebar: (activityId: number) => void;
    onBeforeTaskDeleted: (taskId: string) => void;
}

export const enum CurrentPage {
    MyTasks = 'myTasks',
    AccessibleProjects = 'accessibleProjects',
    MyProjects = 'myProjects',
}

export function DashboardsPage({
    currentPage,
    activitySidebarId,
    taskSidebarId,
    sidebarActivitySharingInProgress,
    shareSidebarActivity,
    openActivitySidebar,
    openTaskSidebar,
    closeActivitySidebar,
    closeTaskSidebar,
    goBackToActivitySidebar,
    onBeforeTaskDeleted,
}: Props): JSX.Element {
    const contentElements = {
        [CurrentPage.MyTasks]: (
            <MyTasksPage
                taskIdWithSidebar={taskSidebarId}
                openTaskSidebar={openTaskSidebar}
                onBeforeTaskDeleted={onBeforeTaskDeleted}
            />
        ),
        [CurrentPage.AccessibleProjects]: (
            <AccessibleProjectsPage
                activityIdWithSidebar={activitySidebarId}
                openActivitySidebar={openActivitySidebar}
            />
        ),
        [CurrentPage.MyProjects]: (
            <MyProjectsPage activityIdWithSidebar={activitySidebarId} openActivitySidebar={openActivitySidebar} />
        ),
    } as const;

    return (
        <div className={style.root}>
            <ResponsiveLayout theme={LayoutTheme.Drawer}>
                <CentralContent>{contentElements[currentPage]}</CentralContent>
                <RightSidebar>
                    <div className={style.newsColumnWrapper}>
                        <NewsColumn />

                        {activitySidebarId && (
                            <ActivitySidebar
                                addOpenActivityButton
                                zIndex={20}
                                activityId={activitySidebarId}
                                activitySharingInProgress={sidebarActivitySharingInProgress}
                                shareActivity={shareSidebarActivity}
                                onCloseSidebarButtonClick={closeActivitySidebar}
                                openTaskSidebar={openTaskSidebar}
                            />
                        )}

                        {taskSidebarId && (
                            <TaskSidebar
                                zIndex={21}
                                taskId={taskSidebarId}
                                closeTaskSidebar={closeTaskSidebar}
                                goBackToActivitySidebar={goBackToActivitySidebar}
                            />
                        )}
                    </div>
                </RightSidebar>
            </ResponsiveLayout>
        </div>
    );
}
