import { createSelector } from 'reselect';

import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type { Autopilot, AutopilotPlacementPlanData } from 'sber-marketing-types/backend';
import type { PageState, BriefStageForm, CreativeStageForm, GroupedDictionaries } from './types';

import type { StoreState } from '../';

export const getAutopilotPageState = (state: StoreState): PageState => state.autopilot_legacy;

export const getAutopilot = createSelector(getAutopilotPageState, (state: PageState): Autopilot => {
    return state.autopilot;
});

export const getActivity = createSelector(getAutopilotPageState, (state: PageState): Activity => {
    return state.activity;
});

export const getMediaplan = createSelector(getAutopilotPageState, (state: PageState): AutopilotPlacementPlanData[] => {
    return state.mediaplan;
});

export const getDictionaries = createSelector(getAutopilotPageState, (state: PageState): GroupedDictionaries => {
    return state.dictionaries;
});

export const getBriefStageForm = createSelector(getAutopilotPageState, (state: PageState): BriefStageForm => {
    return state.briefStageForm;
});

export const getSelectedBudgetItemIds = createSelector(getAutopilotPageState, (state: PageState): string[] => {
    return state.selectedBudgetItemIds;
});

export const getCreativeStageForm = createSelector(getAutopilotPageState, (state: PageState): CreativeStageForm => {
    return state.creativeStageForm;
});

export const getCurrentStage = createSelector(getAutopilotPageState, (state: PageState): number => {
    return state.currentStage;
});

export const getMediaplanStatus = createSelector(getAutopilotPageState, (state: PageState): boolean => {
    return state.calculatingMediaplan;
});
