import createCashedSelector from 're-reselect';
import { GetPreviewResponse, VideoPreviewStatus } from '@mrm/videoPreview';
VideoPreviewStatus;
import { StoreState } from '@store';

export const getVideoPreviewsState = (state: StoreState) => state.videoPreviews;

export const getPreviewByItemId = createCashedSelector(
    getVideoPreviewsState,
    (state: StoreState, id: string) => id,
    (state, id) => state.byItemId[id],
)((state: StoreState, id: string) => id);

export const getPreviewsByContainerId = createCashedSelector(
    getVideoPreviewsState,
    (state: StoreState, containerId: string) => containerId,
    (state, containerId) => state.byContainerId[containerId],
)((state: StoreState, containerId: string) => containerId);

export function isPreviewValid(preview: GetPreviewResponse) {
    const statusIsValid =
        preview?.status === VideoPreviewStatus.Created || preview?.status === VideoPreviewStatus.Cached;
    const imagesAreValid = preview?.url && preview.previews?.[0]?.url;

    return statusIsValid && imagesAreValid;
}
