import * as React from 'react';
import classNames from 'classnames';
import * as lodash from 'lodash';

import * as style from './CheckboxListCell.scss';

import { Icon, IconType } from 'sber-marketing-ui';
import { CellEditorWrapper } from '../CellEditorWrapper';
import { ColumnName } from '@store/creative/types';
import { WithPermissions } from './WithPermissions';
import { DonorBudgetTableButton } from './DonorBudgetTableButton';
import { ExecutorBudgetTableButton } from './ExecutionBudgetTableButton';

const CHECKBOX_ICON_SIZE = 24;

interface Props {
    title: string;
    tooltip: string;
    columnTitle: string;
    columnName: ColumnName;
    items: CheckboxListItem[];
    selectedValues: React.ReactText[];
    customStyle?: any;
    onValueChange: (values: React.ReactText[]) => void;
}

interface CheckboxListItem {
    title: string;
    value: React.ReactText;
}

const budgetTableButtonComponentsByColumnNameMap = {
    [ColumnName.SourceId]: DonorBudgetTableButton,
    [ColumnName.ExecutionId]: ExecutorBudgetTableButton,
};

export const CheckboxListCellEdit = ({
    title,
    tooltip,
    columnTitle,
    columnName,
    items,
    selectedValues,
    customStyle,
    onValueChange,
}: Props): JSX.Element => {
    const onItemClick = (itemValue: React.ReactText) => {
        const updatedSelectedValues = lodash.xor(selectedValues, [itemValue]);
        onValueChange(updatedSelectedValues);
    };

    return (
        <div className={style.root} title={tooltip} style={{ ...customStyle }}>
            <div className={style.title}>{title}</div>

            <div className={classNames(style.openerIcon, style.opened)}>
                <Icon type={IconType.TRIANGLE8_UP} svgSize={8} />
            </div>

            <CellEditorWrapper>
                <div className={style.content}>
                    <div className={style.contentTitle}>{columnTitle}</div>

                    <WithPermissions>
                        {({ permissions }) =>
                            permissions.canSetBudgetId && (
                                <div className={style.budgetTableButton}>
                                    {React.createElement(budgetTableButtonComponentsByColumnNameMap[columnName])}
                                </div>
                            )
                        }
                    </WithPermissions>

                    {!lodash.isEmpty(items) && (
                        <div className={style.items}>
                            {items.map((item) => (
                                <div key={item.value} className={style.item} onClick={() => onItemClick(item.value)}>
                                    <div className={style.checkIcon}>
                                        <Icon
                                            type={
                                                selectedValues.includes(item.value)
                                                    ? IconType.CHECKBOX24_CHECKED
                                                    : IconType.CHECKBOX24_UNCHECKED
                                            }
                                            svgSize={CHECKBOX_ICON_SIZE}
                                        />
                                    </div>

                                    <div className={style.itemTitle}>{item.title}</div>
                                </div>
                            ))}
                        </div>
                    )}

                    {lodash.isEmpty(items) && <div className={style.noItemsMessage}>Ничего не найдено</div>}
                </div>
            </CellEditorWrapper>
        </div>
    );
};
