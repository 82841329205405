import { actionCreatorFactory } from 'typescript-fsa';

import { LoadingStatus } from '@store/commonTypes';

import { PushNewsParams, SaveFiltersActionParams } from './types';

const creator = actionCreatorFactory('NEWS');

// Async actions

/** Fetch more news */
export const fetchMore = creator.async<null | undefined, void, Error>('FETCH_MORE');
export const loadFiltersAsync = creator.async<null, void, Error>('LOAD_FILTERS_ASYNC');

// Sync actions

/** Set "canBeLoadedMore" value */
export const setCanBeLoadedMore = creator<boolean>('SET_CAN_BE_LOADED_MORE');

/** Reset news store */
export const resetNews = creator('RESET_NEWS');

/** Add one or more news into store */
export const pushNews = creator<PushNewsParams>('PUSH_NEWS');
export const saveFilters = creator<SaveFiltersActionParams>('SAVE_FILTERS');
export const setFiltersLoadingStatus = creator<LoadingStatus>('SET_FILTERS_LOADING_STATUS');
