import { actionCreatorFactory } from 'typescript-fsa';

import { DataUpdateActionPayload, UpdateTagColorPayload, UpdateTagEmojiPayload } from '../types';

const actionCreator = actionCreatorFactory('TAGS_EDITOR_ASYNC').async;

export const loadData = actionCreator<string, void, Error>('INIT_INSTANCE');

export const createNewTag = actionCreator<DataUpdateActionPayload<null>, void, Error>('CREATE_NEW_TAG');
export const addTagToSelected = actionCreator<DataUpdateActionPayload<string>, void, Error>('ADD_TAG_TO_SELECTED');
export const removeTagFromSelected = actionCreator<DataUpdateActionPayload<string>, void, Error>(
    'REMOVE_TAG_FROM_SELECTED',
);

export const flushOnDemandUpdates = actionCreator<string, void, Error>('FLUSH_ON_DEMAND_UPDATES');

export const updateTagColor = actionCreator<UpdateTagColorPayload, void, Error>('UPDATE_TAG_COLOR');
export const updateTagEmoji = actionCreator<UpdateTagEmojiPayload, void, Error>('UPDATE_TAG_EMOJI');
