import * as React from 'react';
import { v4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { isEqual, difference, compact } from 'lodash';
import { IconType } from 'sber-marketing-ui';
import { TaskCardParams } from 'sber-marketing-types/frontend';

import { ActivityApi, TaskApi } from '@api';

import { initInstance as initTagsEditorInstance, dropInstance as dropTagsEditorInstance } from '@store/tagsEditor';

import { SidebarWithTabs, SidebarTab } from '@common/SidebarWithTabs';
import { HeaderWithOverflowCheck } from '@common/SidebarWithTabs/common/HeaderWithOverflowCheck';

import { ActivityContent } from './types';
import { ActivityContentFetcher } from './ActivityContentFetcher';
import { InfoTab, BudgetsTab, ParticipantsTab, TasksTab, AutopilotTab } from './tabs';

export interface ActivitySidebarRef {
    refetchContent: () => void;
}

const enum Tabs {
    Info = 'Info',
    Budgets = 'Budgets',
    Participants = 'Participants',
    Tasks = 'Tasks',
    Autopilot = 'Autopilot',
}

const TabsDesc: SidebarTab[] = [
    {
        qaId: 'activitySidebarInfoTabButton',
        id: Tabs.Info,
        title: 'Информация',
        icon: IconType.ACTIVITY_SIDEBAR_INFO_ICON,
    },
    {
        qaId: 'activitySidebarBudgetsButton',
        id: Tabs.Budgets,
        title: 'Бюджеты',
        icon: IconType.ACTIVITY_SIDEBAR_BUDGETS_ICON,
    },
    {
        qaId: 'activitySidebarParticipantsButton',
        id: Tabs.Participants,
        title: 'Участники',
        icon: IconType.ACTIVITY_SIDEBAR_PARTICIPANTS_ICON,
    },
    {
        qaId: 'activitySidebarTasksButton',
        id: Tabs.Tasks,
        title: 'Задачи',
        icon: IconType.ACTIVITY_SIDEBAR_TASKS_ICON,
    },
    {
        qaId: 'activitySidebarAutopilotButton',
        id: Tabs.Autopilot,
        title: 'Автопилот',
        icon: IconType.AUTOPILOT,
    },
];

interface Props {
    sidebarRef?: React.Ref<ActivitySidebarRef>;
    activityId: number;
    activitySharingInProgress: boolean;
    addDAMArchiveWidget?: boolean;
    width?: number;
    top?: number;
    zIndex?: number;
    addOpenActivityButton?: boolean;
    shareActivity(userIds: number[]): void;
    onCloseSidebarButtonClick?: () => void;
    openTaskSidebar: (taskId: string) => void;
}

function useTasksCards(activityId: number) {
    const [isLoading, setIsLoading] = React.useState(false);
    const [taskCards, setTaskCards] = React.useState<TaskCardParams[]>(null);
    const fetcherId = React.useRef<string>(null);

    React.useEffect(() => {
        fetcherId.current = v4();
        refetchTaskCards();
    }, [activityId]);

    async function refetchTaskCards() {
        const currentFetcherId = fetcherId.current;

        setIsLoading(true);

        if (currentFetcherId === fetcherId.current) {
            setTaskCards((await TaskApi.getFilteredTaskCardsList({ activityId })).tasks);
        }

        setIsLoading(false);
    }

    return { taskCards, isLoading, refetchTaskCards };
}

function useTagsEditor(activityId: number) {
    const dispatch = useDispatch();
    const tagsEditorId = React.useMemo<string>(v4, []);

    React.useEffect(function initTagsEditorEffect() {
        dispatch(
            initTagsEditorInstance({
                id: tagsEditorId,
                payload: { activityId },
            }),
        );

        return function resetTaskEditorEffect() {
            dispatch(dropTagsEditorInstance(tagsEditorId));
        };
    }, []);

    return tagsEditorId;
}

function useCanEditActivity(activityId: number) {
    const [canEditActivity, setCanEditActivity] = React.useState(null);

    React.useEffect(
        function fetchCanEditActivity() {
            async function canEditActivityFetcher() {
                setCanEditActivity((await ActivityApi.getActivityAccess(activityId)).canEdit);
            }

            canEditActivityFetcher();
        },
        [activityId],
    );

    return canEditActivity;
}

function useActivitySidebar({ activityId, activitySharingInProgress, sidebarRef, shareActivity }: Props) {
    const [activeTab, setActiveTab] = React.useState<Tabs>(Tabs.Budgets);
    const [isLoading, setIsLoading] = React.useState(false);
    const [activityContent, setActivityContent] = React.useState<ActivityContent>(null);
    const tagsEditorId = useTagsEditor(activityId);
    const refetchRef = React.useRef<() => void>(null);
    const { taskCards, isLoading: isLoadingTaskCards, refetchTaskCards } = useTasksCards(activityId);
    const canEditActivity = useCanEditActivity(activityId);

    function updateParticipants(participantIds: number[]) {
        const participantsToUse = difference(
            participantIds,
            compact([activityContent?.author?.id, activityContent?.responsible?.id]),
        );

        shareActivity(participantsToUse);
    }

    function updateContent(content: ActivityContent, refetch: () => void) {
        if (!isEqual(content, activityContent)) {
            setActivityContent(content);
            setIsLoading(false);
            refetchRef.current = refetch;
        }
    }

    React.useEffect(() => {
        if (!activitySharingInProgress) {
            refetchContent();
        }
    }, [activitySharingInProgress]);

    React.useEffect(() => {
        setActiveTab(Tabs.Budgets);
        refetchRef?.current?.();
    }, [activityId]);

    function refetchContent() {
        setIsLoading(true);
        refetchRef?.current?.();
        refetchTaskCards();
    }

    React.useImperativeHandle(sidebarRef, () => ({
        refetchContent,
    }));

    return {
        activityContent,
        isLoading: isLoading || isLoadingTaskCards,
        taskCards,
        tagsEditorId,
        activeTab,
        canEditActivity,
        setActiveTab,
        updateContent,
        updateParticipants,
        refetchContent,
    };
}

export function ActivitySidebar(props: Props): JSX.Element {
    const {
        activityContent,
        isLoading,
        taskCards,
        tagsEditorId,
        activeTab,
        canEditActivity,
        setActiveTab,
        updateContent,
        updateParticipants,
    } = useActivitySidebar(props);
    const {
        activityId,
        activitySharingInProgress,
        addDAMArchiveWidget,
        width,
        top,
        zIndex,
        addOpenActivityButton,
        onCloseSidebarButtonClick,
        openTaskSidebar,
    } = props;

    return (
        <React.Fragment>
            <ActivityContentFetcher activityId={activityId} forwardContent={updateContent} />

            <SidebarWithTabs
                qaId="activitySidebar"
                header={
                    <Header
                        activityId={activityId}
                        activityContent={activityContent}
                        addOpenActivityButton={addOpenActivityButton}
                        onCloseSidebarButtonClick={onCloseSidebarButtonClick}
                    />
                }
                tabs={TabsDesc}
                activeTab={activeTab}
                onActiveTabChange={setActiveTab as (tabId: Tabs) => void}
                width={width}
                top={top}
                zIndex={zIndex}
                renderTab={(activeTab: Tabs) => (
                    <RenderTab
                        activeTab={activeTab}
                        activityId={activityId}
                        activityContent={activityContent}
                        taskCards={taskCards}
                        isLoading={isLoading}
                        activitySharingInProgress={activitySharingInProgress}
                        addDAMArchiveWidget={addDAMArchiveWidget}
                        tagsEditorId={tagsEditorId}
                        canEditActivity={canEditActivity}
                        zIndex={zIndex}
                        updateParticipants={updateParticipants}
                        openTaskSidebar={openTaskSidebar}
                    />
                )}
            />
        </React.Fragment>
    );
}

interface HeaderProps {
    activityId: number;
    activityContent: ActivityContent;
    addOpenActivityButton: boolean;
    onCloseSidebarButtonClick?: () => void;
}

function Header({
    activityId,
    activityContent,
    addOpenActivityButton,
    onCloseSidebarButtonClick,
}: HeaderProps): JSX.Element {
    return (
        <HeaderWithOverflowCheck
            qaId="activitySidebarTitle"
            showPreloader={!activityContent}
            sourceLink={addOpenActivityButton ? `/activity/${activityId}` : null}
            content={activityContent?.name}
            onCloseButtonClick={onCloseSidebarButtonClick}
        />
    );
}

interface RenderTabProps {
    activeTab: Tabs;
    activityId: number;
    taskCards: TaskCardParams[];
    activityContent: ActivityContent;
    activitySharingInProgress: boolean;
    isLoading: boolean;
    addDAMArchiveWidget: boolean;
    tagsEditorId: string;
    canEditActivity: boolean;
    zIndex: number;
    updateParticipants: (participnatIds: number[]) => void;
    openTaskSidebar: (taskId: string) => void;
}

function RenderTab({
    activeTab,
    activityId,
    taskCards,
    activityContent,
    activitySharingInProgress,
    isLoading,
    addDAMArchiveWidget,
    tagsEditorId,
    canEditActivity,
    zIndex,
    updateParticipants,
    openTaskSidebar,
}: RenderTabProps): JSX.Element {
    switch (activeTab) {
        case Tabs.Info:
            return (
                <InfoTab
                    activityId={activityId}
                    activityContent={activityContent}
                    addDAMArchiveWidget={addDAMArchiveWidget}
                    tagsEditorId={tagsEditorId}
                    canEditActivity={canEditActivity}
                    zIndex={zIndex}
                />
            );
        case Tabs.Budgets:
            return <BudgetsTab activityId={activityId} />;
        case Tabs.Participants:
            return (
                <ParticipantsTab
                    activityContentIsLoading={isLoading}
                    activityContent={activityContent}
                    activitySharingInProgress={activitySharingInProgress}
                    updateParticipants={updateParticipants}
                />
            );
        case Tabs.Tasks:
            return (
                <TasksTab
                    activityId={activityId}
                    taskCards={taskCards}
                    isLoading={isLoading}
                    openTaskSidebar={openTaskSidebar}
                />
            );
        case Tabs.Autopilot:
            return <AutopilotTab activityId={activityId} />;
        default:
            console.warn(`Missing tab definition for ${activeTab}`);
            return null;
    }
}
