import * as React from 'react';
import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import { getBudgetCorrectionsPageState } from '@store/budgetCorrections';

import { FiltersConnected } from './FiltersConnected';
import { FiltersContainer } from './FiltersContainer';

interface Props {
    budgetId: string;
    applyFilters: () => void;
}

export function Filters({ budgetId, applyFilters }: Props): JSX.Element {
    const skipFiltersLoading = useSelector(
        (state: StoreState) => !getBudgetCorrectionsPageState(state).pageData?.dictionaries?.length,
    );

    return (
        <FiltersConnected skip={skipFiltersLoading} budgetId={budgetId}>
            {(gqlProps) => <FiltersContainer applyFilters={applyFilters} {...gqlProps} />}
        </FiltersConnected>
    );
}
