import * as React from 'react';
import autobind from 'autobind-decorator';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { UserConfigType } from 'sber-marketing-types/openid';

import { StoreState } from '@store';
import { saveUserConfig } from '@store/userConfig';
import { getSearchState, searchAll } from '@store/search';

import { SearchInput } from './SearchInput';

interface Props extends Partial<MapProps & DispatchProps> {}

interface MapProps {
    searchQuery: string;
}

interface DispatchProps {
    searchAll: (value: string) => void;
    onSearchInputClearButtonClick: () => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class SearchInputContainer extends React.PureComponent<Props> {
    private searchAllDebounced: (value: string) => void = null;

    public constructor(props: Props) {
        super(props);

        this.searchAllDebounced = debounce(props.searchAll, 1000);
    }

    public render(): JSX.Element {
        const { searchQuery, onSearchInputClearButtonClick } = this.props;

        return (
            <SearchInput
                searchQuery={searchQuery}
                onSearchInputClearButtonClick={onSearchInputClearButtonClick}
                onSearchInputChange={this.onSearchInputChange}
            />
        );
    }

    @autobind
    private onSearchInputChange(value: string): void {
        const { searchQuery } = this.props;

        const newSearchQuery = value.trim();

        if (newSearchQuery !== searchQuery) {
            this.searchAllDebounced(newSearchQuery);
        }
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { searchQuery } = getSearchState(state);

    return {
        searchQuery,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        searchAll: (value: string) => dispatch(searchAll(value)),
        onSearchInputClearButtonClick: () => {
            dispatch(
                saveUserConfig({
                    type: UserConfigType.Dashboard,
                    payload: {
                        previousSearch: '',
                    },
                }),
            );

            dispatch(searchAll(''));
        },
    };
}
