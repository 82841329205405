import * as React from 'react';
import classNames from 'classnames';

import * as style from './TotalLine.scss';

import { Icon, IconType } from 'sber-marketing-ui';

import { MoneyFormatter, Money } from '@common/Utils';

interface Props {
    width: number;
    value: number;
    color: string;
    activityName: string;
    showTransferLinesToPlanningButton: boolean;
    isLineSelectedForTransferToPlan: boolean;
    contentRef: (element: HTMLDivElement) => void;
    onEditActivityButtonClick: () => void;
    onCopyActivityButtonClick: () => void;
    onTransferBudgetItemsToPlanningButtonClick: () => void;
    canCreateNewLines: boolean;
    userCanEditOrCreateBudgetData: boolean;
}

export const TotalLine = ({
    width,
    value,
    color,
    activityName,
    showTransferLinesToPlanningButton,
    isLineSelectedForTransferToPlan,
    contentRef,
    onEditActivityButtonClick,
    onCopyActivityButtonClick,
    onTransferBudgetItemsToPlanningButtonClick,
    canCreateNewLines,
    userCanEditOrCreateBudgetData,
}: Props): JSX.Element => {
    return (
        <div className={style.root} style={{ backgroundColor: color }}>
            <div className={style.content} style={{ width }} ref={contentRef}>
                {userCanEditOrCreateBudgetData && (
                    <React.Fragment>
                        <div
                            className={classNames([
                                style.editButton,
                                style.contentItem,
                                !canCreateNewLines && style.disabled,
                            ])}
                            onClick={canCreateNewLines ? onEditActivityButtonClick : null}
                            {...{
                                'qa-id': 'totalLineEditActivityButton',
                            }}
                        >
                            <div className={style.editIcon}>
                                <Icon type={IconType.WRITING} svgSize={18} />
                            </div>

                            <div className={style.editTitle}>Добавить строку бюджета</div>
                        </div>

                        <div
                            className={classNames(style.copyButton, style.contentItem)}
                            onClick={onCopyActivityButtonClick}
                            {...{
                                'qa-id': 'totalLineCopyActivityButton',
                            }}
                        >
                            <div className={style.copyIcon}>
                                <Icon type={IconType.COPY} svgSize={18} />
                            </div>

                            <div className={style.copyIconHover}>
                                <Icon type={IconType.COPY_HOVER} svgSize={18} />
                            </div>

                            <div className={style.editTitle}>Копирование активности</div>
                        </div>

                        {showTransferLinesToPlanningButton && !isLineSelectedForTransferToPlan && (
                            <div
                                className={classNames([style.editButton, style.contentItem])}
                                onClick={onTransferBudgetItemsToPlanningButtonClick}
                                {...{
                                    'qa-id': 'totalLineTransferBudgetItemToPlanningButton',
                                }}
                            >
                                <div className={style.editIcon}>
                                    <Icon type={IconType.TRANSFER_BUDGET_ITEMS_TO_PLANNING} svgSize={18} />
                                </div>

                                <div className={style.editTitle}>Перенести строки в планирование</div>
                            </div>
                        )}

                        {isLineSelectedForTransferToPlan && (
                            <div
                                className={classNames([style.editButton, style.contentItem])}
                                {...{
                                    'qa-id': 'totalLineTransferBudgetItemToPlanningLineActiveMarker',
                                }}
                            >
                                <div className={classNames(style.editIcon, style.editIconGreen)}>
                                    <Icon type={IconType.CIRCLE_BOLD_CHECK} svgSize={18} />
                                </div>

                                <div className={style.editTitle}>Активность для переноса в планирование</div>
                            </div>
                        )}
                    </React.Fragment>
                )}

                <div className={classNames(style.budgetAmount, style.contentItem)}>
                    Бюджет: <b>{MoneyFormatter.toThousands(Money.fromCopecks(value))}</b>
                </div>

                <div className={classNames(style.activityName, style.contentItem)}>{activityName}</div>
            </div>
        </div>
    );
};
