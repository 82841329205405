import * as React from 'react';
import { BudgetItem } from '@mrm/budget';

import { Header } from './Header';
import { Modal } from './Modal';
import { TabBarHeader, TabBarContent } from './TabBar';

import * as style from './LineModal.scss';

interface Props {
    lineId: string;
    serialNumberOfBudgetItem: number;
    projectNameOfBudgetItem: string;
    sourceBudgetItem: BudgetItem;
    onCloseButtonClick: () => void;
    onAppliedTagsChange: () => void;
}

export const LineModal: React.FC<Props> = ({
    lineId,
    projectNameOfBudgetItem,
    serialNumberOfBudgetItem,
    sourceBudgetItem,
    onCloseButtonClick,
    onAppliedTagsChange,
}) => {
    return (
        <Modal lineId={lineId}>
            <div
                className={style.modal}
                {...{
                    'qa-id': 'budgetPlanningLineModal',
                }}
            >
                <div className={style.headerWrapper}>
                    <Header
                        lineId={lineId}
                        id={serialNumberOfBudgetItem}
                        name={projectNameOfBudgetItem}
                        onCloseButtonClick={onCloseButtonClick}
                    ></Header>
                </div>

                <TabBarHeader />
                <TabBarContent lineId={lineId} onAppliedTagsChange={onAppliedTagsChange} />
            </div>
        </Modal>
    );
};
