import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StyledPopup, StyledPopupTheme } from 'sber-marketing-ui';

import { StoreState } from '@store';
import {
    ArchiveContentPopupComponentState,
    getActivityDAMArchiveState,
    setArchiveContentPopupState,
    exportFiles,
} from '@store/activityFilesDAMArchive';

function useNameDuplicatesPopup() {
    const dispatch = useDispatch();

    const shouldRender = useSelector(
        (state: StoreState) =>
            getActivityDAMArchiveState(state).archiveContentPopup.componentState ===
            ArchiveContentPopupComponentState.NameDuplicatesPopup,
    );

    function onConfirmButtonClick() {
        dispatch(exportFiles(null));
    }

    function onCancelButtonClick() {
        dispatch(setArchiveContentPopupState(ArchiveContentPopupComponentState.Editor));
    }

    return {
        shouldRender,
        onConfirmButtonClick,
        onCancelButtonClick,
    };
}

export function NameDuplicatesPopup(): JSX.Element {
    const { shouldRender, onConfirmButtonClick, onCancelButtonClick } = useNameDuplicatesPopup();

    return shouldRender ? (
        <StyledPopup
            theme={StyledPopupTheme.Redesign}
            qaId="DAMArchiveWidgetNameDuplicatesPopup"
            title="Подтвердите выгрузку файлов с одинаковым именем"
            fullMessage={
                <React.Fragment>
                    Вы собиратесь выгрузить файлы с одинаковыми именами.
                    <br />
                    Файлы с совпадающими именами будут перезаписаны.
                    <br />
                    Вы уверены?
                </React.Fragment>
            }
            firstButtonHandler={onConfirmButtonClick}
            firstButtonQaId="DAMArchiveWidgetNameDuplicatesPopupConfirmButton"
            firstButtonText="Подтвердить"
            secondButtonHandler={onCancelButtonClick}
            secondButtonQaId="DAMArchiveWidgetNameDuplicatesPopupCancelButton"
            secondButtonText="Отмена"
        />
    ) : null;
}
