import { createElement, PureComponent } from 'react';
import * as lodash from 'lodash';

import { Period } from '@store/calendar/types';
import type { DayData } from './Days';
import type { MonthData } from '../../modules';

import { Days } from './Days';
import { TimeCalculator } from '../../modules';

interface Props {
    scroll: number;
}

export class DaysContainer extends PureComponent<Props> {
    private calculator: TimeCalculator = TimeCalculator.getInstance();

    public render(): JSX.Element {
        return createElement(Days, {
            scroll: this.props.scroll,
            days: this.makeDays(),
            visibleDaysIndexes: this.visibleDaysIndexes(),
        });
    }

    private makeDays(): DayData[] {
        const months: MonthData[] = this.calculator.getAllMonths();

        const dayWidthInPercent = this.getDayWidth() * 100;

        return lodash.flatten(
            months.map(({ daysCount, year, month }) =>
                lodash.range(0, daysCount).map((dayIndex) => ({
                    year,
                    month,
                    width: dayWidthInPercent,
                    day: dayIndex + 1,
                })),
            ),
        );
    }

    private visibleDaysIndexes(): number[] {
        const { scroll } = this.props;

        const dayWidthInPercent = this.getDayWidth() * 100;

        const viewportStart = Math.floor((scroll * 100) / dayWidthInPercent);
        const viewportEnd = Math.ceil(((scroll + 1) * 100) / dayWidthInPercent);

        return lodash.range(viewportStart, viewportEnd + 1);
    }

    private getDayWidth() {
        const daysCountInPeriod = this.calculator.getPeriodDaysCount(Period.Month);

        return 1 / daysCountInPeriod;
    }
}
