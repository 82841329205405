import * as React from 'react';
import classNames from 'classnames';

import { ProgressBar } from './ProgressBar';
import { Color } from '../enums';

import * as styles from './styles.scss';

interface Props {
    className?: string;
    count: number;
    fractionOfTotal?: number;
    title: string | React.ClassicElement<HTMLDivElement>;
    footer: string | React.ClassicElement<HTMLDivElement>;
    color: Color;
    darken?: boolean;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const ShortReport: React.FC<Props> = ({
    className = '',
    count = 0,
    fractionOfTotal = 0,
    title,
    footer,
    color,
    darken = false,
    onClick = () => {},
}) => {
    return (
        <div className={classNames(styles.root, className, darken ? styles.brightnessRoot : '')} onClick={onClick}>
            <div className={styles.title}>{title}</div>
            <div>
                <div className={styles.content}>{count}</div>
                <div className={styles.progressBar}>
                    <ProgressBar progress={fractionOfTotal} color={color} />
                </div>
                <div className={styles.footer}>{footer}</div>
            </div>
        </div>
    );
};

export { Props as ShortReportProps };
