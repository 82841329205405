// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-components-TaskInfo-_TaskInfo__description{margin-top:16px}.src-client-modules-activity-pages-task-components-TaskInfo-_TaskInfo__assets{margin-top:48px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/components/TaskInfo/TaskInfo.scss"],"names":[],"mappings":"AAAA,mFACI,eAAgB,CACnB,8EAGG,eAAgB","sourcesContent":[".description {\n    margin-top: 16px;\n}\n\n.assets {\n    margin-top: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "src-client-modules-activity-pages-task-components-TaskInfo-_TaskInfo__description",
	"assets": "src-client-modules-activity-pages-task-components-TaskInfo-_TaskInfo__assets"
};
export default ___CSS_LOADER_EXPORT___;
