import * as React from 'react';

interface Props {
    time: number;
    onFinishedTimeout: () => void;
}

export class WithTimeout extends React.Component<Props> {
    private timer: NodeJS.Timeout;

    constructor(props: Props) {
        super(props);
    }

    public componentDidMount(): void {
        this.timer = setTimeout(() => {
            this.props.onFinishedTimeout();
        }, this.props.time);
    }

    public componentWillUnmount(): void {
        clearTimeout(this.timer);
    }

    public render() {
        return this.props.children;
    }
}
