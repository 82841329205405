import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';

import * as asyncActions from './actions/async';
import * as actions from './actions/sync';

import { loadVacationData, getProfileMenuVacationState } from './vacation';
import { loadNotificationsData, getProfileMenuNotificationsState } from './notifications';

export const loadProfileData = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.loadProfileData,
    async (_, dispatch, getState) => {
        dispatch(actions.setLoadingStatus(LoadingStatus.LOADING));
        dispatch(loadVacationData(null));
        dispatch(loadNotificationsData(null));
    },
);

export const checkLoadingStatus = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.checkLoadingStatus,
    async (_, dispatch, getState) => {
        const state = getState();
        const loadingStatus = [
            getProfileMenuVacationState(state).loadingStatus,
            getProfileMenuNotificationsState(state).loadingStatus,
        ];

        const hasErrors = loadingStatus.some((loadingStatus) => loadingStatus === LoadingStatus.ERROR);
        if (hasErrors) {
            dispatch(actions.setLoadingStatus(LoadingStatus.ERROR));
        } else {
            const allFinished = loadingStatus.every((loadingStatus) => loadingStatus === LoadingStatus.LOADED);

            if (allFinished) {
                dispatch(actions.setLoadingStatus(LoadingStatus.LOADED));
            }
        }
    },
);
