import * as React from 'react';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { Brief } from './Brief';
import { NotificationActionType, NotificationMessage, NotificationType } from '@store/common/types';
import { withCopiedBrief, CopiedBriefProps } from './withCopiedBrief';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { setNotification } from '@store/common/actions';
import { setBriefLoading } from '@store/brief/actions';

import { Brief as BriefParams, BriefBlock, BriefScheme, BriefStatus } from 'sber-marketing-types/frontend';
import { BriefSaver, BriefLoader } from './modules';
import { BriefsSaver } from '../../modules';
import { FileToRemove, BriefLoading } from '@store/brief/types';
import { StoreState } from '@store';
import { getHeaderHeight } from '@store/common/selectors';
import { getUserOrganizationId } from '@store/user/selector';
import { getPlannedBudgetEditPageState } from '@store/plannedBudgetEdit/selectors';

interface Props extends Partial<CopiedBriefProps & MapProps & DispatchProps> {
    id: string;
    budgetId: string;
    onFinishCreateBrief: (briefId: string) => void;
    onFinishPasteCopiedBrief: () => void;
}

interface MapProps {
    schemes: BriefScheme[];
    brief: BriefParams;
    currentBrief: BriefParams;
    filesToRemove: FileToRemove[];
    blocks: BriefBlock[];
    headerHeight: number;
    organizationId: string;
    isLoading: boolean;
}

interface DispatchProps {
    setNotification: (notification: NotificationMessage) => void;
    setBriefLoading: (briefLoading: BriefLoading) => void;
}

interface State {
    isPasteCopiedBriefModalOpened: boolean;
    isExpanded: boolean;
}

@(withCopiedBrief as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BriefContainer extends React.Component<Props, State> {
    private briefLoader: BriefLoader;
    private briefSaver: BriefSaver;
    private briefsSaver: BriefsSaver;

    constructor(props: Props) {
        super(props);

        this.state = {
            isPasteCopiedBriefModalOpened: false,
            isExpanded: false,
        };

        this.briefSaver = new BriefSaver({ briefId: props.id });
        this.briefLoader = new BriefLoader({ briefId: props.id });
        this.briefsSaver = BriefsSaver.getInstance();
    }

    public async componentDidMount() {
        this.briefsSaver.registerBriefSaver(this.briefSaver);
    }

    public componentWillUnmount() {
        this.briefsSaver.removeBriefSaver(this.briefSaver);
    }

    public render() {
        const { brief, organizationId, schemes, blocks, isLoading } = this.props;

        const { isPasteCopiedBriefModalOpened, isExpanded } = this.state;

        return React.createElement(Brief, {
            brief,
            organizationId,
            schemes,
            blocks,
            editRight: this.editRight,
            isBriefReady: this.isBriefReady,
            isLoading,
            isBriefPasteDisabled: this.isDisabledOfBriefPaste,
            isBriefCopyDisabled: this.isDisabledOfBriefCopy,
            isBriefExportDisabled: this.isDisabledOfBriefExport,
            isExpanded,
            isPasteCopiedBriefModalOpened,
            onBriefCopy: this.onBriefCopy,
            onBriefPaste: this.onBriefPaste,
            onBriefSchemeSelection: this.onBriefSchemeSelection,
            onPasteCopiedBriefModalConfirmClick: this.onPasteCopiedBriefModalConfirmClick,
            onPasteCopiedBriefModalCancelClick: this.onPasteCopiedBriefModalCancelClick,
            toggleIsExpandedState: this.toggleIsExpandedState,
        });
    }

    @autobind
    private toggleIsExpandedState(): void {
        this.setState((state) => ({
            isExpanded: !state.isExpanded,
        }));
    }

    @autobind
    private onBriefCopy(): void {
        this.props.setNotification({
            type: NotificationType.SUCCESS,
            typeAction: NotificationActionType.BRIEF_COPIED_ON_BRIEF_PAGE,
            comment: (
                <>
                    Бриф успешно скопирован в буфер обмена!
                    <br />
                    Чтобы использовать его, зайдите в бриф другого проекта или задачи и нажмите кнопку "Вставить бриф"
                </>
            ),
        });
        this.props.copyBrief(this.props.currentBrief);
    }

    @autobind
    private async onBriefPaste(): Promise<void> {
        if (this.briefAlreadyHasScheme) {
            this.openPopupAboutPasteCopiedBrief();
        } else {
            await this.pasteCopiedBrief();
        }
    }

    @autobind
    private async onBriefSchemeSelection(schemeId: string) {
        const { brief } = this.props;

        if (this.isBriefWasNotCreated()) {
            try {
                const brief = await this.briefLoader.createBrief(schemeId);
                this.briefSaver.setBriefId(brief.id);
                this.props.onFinishCreateBrief(brief.id);
            } catch (_) {}
        } else {
            try {
                this.props.setBriefLoading({ briefId: brief.id, loading: true });
                this.briefSaver.setBriefWasCopied(false);
                await this.applySchemeIdToBrief(schemeId);
            } finally {
                this.props.setBriefLoading({ briefId: brief.id, loading: false });
            }
        }
    }

    @autobind
    private async onPasteCopiedBriefModalConfirmClick() {
        this.setState({ isPasteCopiedBriefModalOpened: false });
        await this.pasteCopiedBrief();
    }

    @autobind
    private onPasteCopiedBriefModalCancelClick() {
        this.setState({ isPasteCopiedBriefModalOpened: false });
    }

    private get editRight(): boolean {
        const { currentBrief } = this.props;
        return currentBrief ? currentBrief.canEdit : true;
    }

    private get isBriefReady(): boolean {
        const { currentBrief } = this.props;

        return Boolean(currentBrief && currentBrief.status == BriefStatus.Ready);
    }

    private get briefAlreadyHasScheme(): boolean {
        const { currentBrief } = this.props;
        return Boolean(currentBrief && currentBrief.schemeId);
    }

    private get isDisabledOfBriefCopy(): boolean {
        const { currentBrief } = this.props;

        if (currentBrief && currentBrief.schemeId) {
            return lodash.isEmpty(currentBrief.schemeId);
        }

        return true;
    }

    private get isDisabledOfBriefExport(): boolean {
        return !(this.props.currentBrief && this.props.currentBrief.schemeId);
    }

    private get isDisabledOfBriefPaste(): boolean {
        return lodash.isEmpty(this.props.copiedBrief);
    }

    private openPopupAboutPasteCopiedBrief(): void {
        this.setState({ isPasteCopiedBriefModalOpened: true });
    }

    private async pasteCopiedBrief(): Promise<void> {
        if (this.isBriefWasNotCreated()) {
            const brief = await this.briefLoader.createBrief(this.props.copiedBrief.schemeId);
            this.briefSaver.setBriefId(brief.id);
            this.briefLoader.pasteCurrentBrief(this.props.copiedBrief, brief);
            this.briefSaver.setBriefWasCopied(true);
            await this.briefSaver.saveBrief();
            this.props.onFinishCreateBrief(brief.id);
        } else {
            await this.applySchemeIdToBrief(this.props.copiedBrief.schemeId);
            this.briefLoader.pasteCurrentBrief(this.props.copiedBrief, this.props.currentBrief);
            this.briefSaver.setBriefWasCopied(true);
            await this.briefSaver.saveBrief();
        }
        this.props.onFinishPasteCopiedBrief();
    }

    private async applySchemeIdToBrief(schemeId: string): Promise<void> {
        await this.briefSaver.applyBriefScheme(schemeId);
        await this.briefLoader.updateBrief();
    }

    private isBriefWasNotCreated(): boolean {
        return lodash.isNil(this.props.brief);
    }
}

function mapStateToProps(state: StoreState, props: Props): MapProps {
    const { id: briefId } = props;

    const {
        briefsSchemes: schemes,
        originalBriefs: briefs,
        changedBriefs: currentBriefs,
        filesToRemove,
        briefsLoading,
    } = getPlannedBudgetEditPageState(state);

    const blocks =
        currentBriefs[briefId] && currentBriefs[briefId].blocks
            ? lodash.sortBy(currentBriefs[briefId].blocks, (item) => item.order)
            : [];

    return {
        schemes,
        brief: briefs[briefId],
        currentBrief: currentBriefs[briefId],
        filesToRemove,
        blocks,
        headerHeight: getHeaderHeight(state),
        organizationId: getUserOrganizationId(state),
        isLoading: briefsLoading[briefId] && briefsLoading[briefId].loading,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setNotification,
            setBriefLoading,
        },
        dispatch,
    );
}
