import * as React from 'react';
import autobind from 'autobind-decorator';
import * as moment from 'moment';

import { Datepicker, Direction } from './Datepicker';

const DATEPICKER_CONTENT_HEIGHT = 205;

interface Props {
    qaId?: string;
    date?: moment.Moment;
    maxDate?: moment.Moment;
    minDate?: moment.Moment;
    placeholder?: string;
    readOnly?: boolean;
    disabled?: boolean;
    onChange?: (date: moment.Moment) => void;
    onInputFocus?: () => void;
    onInputBlur?: () => void;
    onCalendarOpen?: () => void;
    onCalendarClose?: () => void;
}

interface State {
    isOpened: boolean;
    showInput: boolean;
}

export class DatepickerContainer extends React.Component<Props, State> {
    private rootRef: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: Props) {
        super(props);

        this.state = {
            isOpened: false,
            showInput: false,
        };
    }

    public componentDidMount() {
        this.onDateChange(this.props.date);
    }

    public render() {
        return React.createElement(Datepicker, {
            qaId: this.props.qaId,
            date: this.props.date,
            maxDate: this.props.maxDate,
            minDate: this.props.minDate,
            placeholder: this.props.placeholder,
            direction: this.getContentDirection(),
            isOpened: this.state.isOpened,
            showInput: this.state.showInput,
            disabled: this.props.disabled,
            rootRef: this.rootRef,
            onDateChange: this.onDateChange,
            onDateClick: this.onDateClick,
            onInputBlur: this.onInputBlur,
            onIconClick: this.onIconClick,
            onMaskClick: this.onMaskClick,
            onResetButtonClick: this.onResetDate,
        });
    }

    @autobind
    protected onResetDate(): void {
        this.props.onChange(null);
    }

    @autobind
    protected onDateChange(date: moment.Moment) {
        const newDate = moment(date).set({ hour: 3 }).utc();

        this.setState(
            {
                isOpened: false,
                showInput: false,
            },
            () => {
                if (this.props.onChange && newDate.isValid()) {
                    this.props.onChange(newDate);
                }

                if (this.props.onCalendarClose) {
                    this.props.onCalendarClose();
                }
            },
        );
    }

    @autobind
    protected onDateClick() {
        this.setState(
            {
                showInput: true,
            },
            () => {
                if (this.props.onInputFocus) {
                    this.props.onInputFocus();
                }
            },
        );
    }

    @autobind
    protected onInputBlur(event: React.ChangeEvent<HTMLInputElement>) {
        const inputValue = event.target.value;

        let newDate: moment.Moment = null;

        if (inputValue) {
            const parsedDate = moment.utc(inputValue, 'DD.MM.YYYY');

            newDate = parsedDate.isValid() ? this.validateDate(parsedDate) : this.props.date;
        }

        this.setState(
            {
                showInput: false,
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(newDate);
                }

                if (this.props.onInputBlur) {
                    this.props.onInputBlur();
                }
            },
        );
    }

    @autobind
    protected onIconClick() {
        this.setState(
            {
                isOpened: true,
                showInput: false,
            },
            () => {
                if (this.props.onCalendarOpen) {
                    this.props.onCalendarOpen();
                }
            },
        );
    }

    @autobind
    protected onMaskClick() {
        this.setState(
            {
                isOpened: false,
                showInput: false,
            },
            () => {
                if (this.props.onCalendarClose) {
                    this.props.onCalendarClose();
                }
            },
        );
    }

    private getContentDirection(): Direction {
        const { isOpened } = this.state;
        const rootElement = this.rootRef.current;

        if (rootElement && isOpened) {
            const { top, height } = rootElement.getBoundingClientRect();
            const screenHeight = window.innerHeight;

            return screenHeight - top - height >= DATEPICKER_CONTENT_HEIGHT ? Direction.Down : Direction.Up;
        }

        return null;
    }

    private validateDate(date: moment.Moment): moment.Moment {
        const { minDate, maxDate } = this.props;

        let result: moment.Moment;

        if (minDate && date.isBefore(minDate)) {
            result = minDate.clone();
        } else if (maxDate && date.isAfter(maxDate)) {
            result = maxDate.clone();
        } else {
            result = date;
        }

        return result;
    }
}
