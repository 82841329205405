import * as React from 'react';
import classNames from 'classnames';
import { WithTooltip, Skeleton } from 'sber-marketing-ui';

import * as style from './CardSubInfo.scss';

export interface Props {
    tooltip?: string;
    loading?: boolean;
    className?: string;
    qaId: string;
    qaTitle: string;
    qaValue: string;
}

export const CardSubInfo: React.FC<Props> = ({ children, tooltip, loading, className, qaId, qaTitle, qaValue }) => (
    <div
        className={classNames(style.root, loading ? className : '')}
        {...{
            'qa-id': qaId,
            'qa-title': qaTitle,
            'qa-value': qaValue,
        }}
    >
        <Skeleton isReady={!loading}>
            <WithTooltip spanWrapper hidden={!tooltip} content={tooltip}>
                <React.Fragment>{children}</React.Fragment>
            </WithTooltip>
        </Skeleton>
    </div>
);

export const CardSubInfoIconWrapper: React.FC = ({ children }) => <div className={style.iconWrapper}>{children}</div>;
