// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-DAMFilesArchiveWidget-ArchiveContentPopup-TaskFiles-ViewContentMode-_ViewContentMode__files{margin-top:8px;display:flex;flex-direction:column;gap:6px 0}.src-client-modules-common-DAMFilesArchiveWidget-ArchiveContentPopup-TaskFiles-ViewContentMode-_ViewContentMode__file{width:calc(100% - 5px)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/DAMFilesArchiveWidget/ArchiveContentPopup/TaskFiles/ViewContentMode/ViewContentMode.scss"],"names":[],"mappings":"AAAA,uHACI,cAAe,CAEf,YAAa,CACb,qBAAsB,CACtB,SAAU,CACb,sHAGG,sBAAuB","sourcesContent":[".files {\n    margin-top: 8px;\n\n    display: flex;\n    flex-direction: column;\n    gap: 6px 0;\n}\n\n.file {\n    width: calc(100% - 5px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"files": "src-client-modules-common-DAMFilesArchiveWidget-ArchiveContentPopup-TaskFiles-ViewContentMode-_ViewContentMode__files",
	"file": "src-client-modules-common-DAMFilesArchiveWidget-ArchiveContentPopup-TaskFiles-ViewContentMode-_ViewContentMode__file"
};
export default ___CSS_LOADER_EXPORT___;
