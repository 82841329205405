import * as React from 'react';
import { FilterMenu, FilterItem } from 'sber-marketing-ui';

import { SerialNumbersList } from '../SerialNumbersList';
import { ActivitySourceBudgetItem } from '../ActivitySourceBudgetItem';

import * as style from './BudgetItemSelect.scss';

interface Props {
    activityId: number;
    budgetId: string;
    linkIdsToCreate: number[];
    linkIdsToDelete: number[];
    acceptedLinkIds: number[];
    selectItems: FilterItem[];
    selectedItemIds: string[];
    onItemSelect: (itemIds: string[]) => void;
    onRemoveLinkChangeButtonClick: (id: number) => void;
}

export function BudgetItemSelect({
    activityId,
    budgetId,
    linkIdsToCreate,
    linkIdsToDelete,
    acceptedLinkIds,
    selectItems,
    selectedItemIds,
    onItemSelect,
    onRemoveLinkChangeButtonClick,
}: Props): JSX.Element {
    return (
        <React.Fragment>
            <div className={style.budgetItemSelect}>
                <div className={style.caption}>Доступные ID</div>

                {activityId && (
                    <ActivitySourceBudgetItem
                        activityId={activityId}
                        currentBudgetId={budgetId}
                        existingLinkIds={selectedItemIds}
                        selectBudgetItem={onItemSelect}
                    />
                )}

                <FilterMenu
                    filterTitle={'ID исполнения'}
                    items={selectItems}
                    checkedItemsIds={selectedItemIds}
                    onItemSelection={onItemSelect}
                    preserveAllSelectedState
                />
            </div>

            <div className={style.serialNumbersList}>
                <SerialNumbersList
                    title={'ID будут привязаны'}
                    serialNumbers={linkIdsToCreate}
                    checkedSerialNumbers={acceptedLinkIds}
                    onRemoveButtonClick={onRemoveLinkChangeButtonClick}
                />
            </div>

            <div className={style.serialNumbersList}>
                <SerialNumbersList
                    title={'ID будут отвязаны'}
                    serialNumbers={linkIdsToDelete}
                    checkedSerialNumbers={acceptedLinkIds}
                    onRemoveButtonClick={onRemoveLinkChangeButtonClick}
                />
            </div>
        </React.Fragment>
    );
}
