// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-components-AllAssetsTitle-_AllAssetsTitle__root{display:flex;width:100%;align-items:center;justify-content:space-between}.src-client-modules-activity-pages-task-components-AllAssetsTitle-_AllAssetsTitle__title{overflow:hidden;min-width:54px;max-width:205px;height:12px;white-space:nowrap;letter-spacing:1px;text-overflow:ellipsis;color:#404040;font-family:Roboto;font-size:8px;line-height:1.5}.src-client-modules-activity-pages-task-components-AllAssetsTitle-_AllAssetsTitle__buttonsWrapper{display:flex;width:48px;height:16px;align-items:center;justify-content:space-between}.src-client-modules-activity-pages-task-components-AllAssetsTitle-_AllAssetsTitle__button:hover{cursor:pointer}.src-client-modules-activity-pages-task-components-AllAssetsTitle-_AllAssetsTitle__button_sortDate{opacity:0.6}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/components/AllAssetsTitle/AllAssetsTitle.scss"],"names":[],"mappings":"AAAA,wFACI,YAAa,CAEb,UAAW,CAEX,kBAAmB,CACnB,6BAA8B,CACjC,yFAGG,eAAgB,CAEhB,cAAe,CACf,eAAgB,CAChB,WAAY,CAEZ,kBAAmB,CACnB,kBAAmB,CACnB,sBAAuB,CAEvB,aAAc,CAEd,kBAAmB,CACnB,aAAc,CACd,eAAgB,CACnB,kGAGG,YAAa,CAEb,UAAW,CACX,WAAY,CAEZ,kBAAmB,CACnB,6BAA8B,CACjC,gGAGG,cAAe,CAClB,mGAGG,WAAY","sourcesContent":[".root {\n    display: flex;\n\n    width: 100%;\n\n    align-items: center;\n    justify-content: space-between;\n}\n\n.title {\n    overflow: hidden;\n\n    min-width: 54px;\n    max-width: 205px;\n    height: 12px;\n\n    white-space: nowrap;\n    letter-spacing: 1px;\n    text-overflow: ellipsis;\n\n    color: #404040;\n\n    font-family: Roboto;\n    font-size: 8px;\n    line-height: 1.5;\n}\n\n.buttonsWrapper {\n    display: flex;\n\n    width: 48px;\n    height: 16px;\n\n    align-items: center;\n    justify-content: space-between;\n}\n\n.button:hover {\n    cursor: pointer;\n}\n\n.button_sortDate {\n    opacity: 0.6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-components-AllAssetsTitle-_AllAssetsTitle__root",
	"title": "src-client-modules-activity-pages-task-components-AllAssetsTitle-_AllAssetsTitle__title",
	"buttonsWrapper": "src-client-modules-activity-pages-task-components-AllAssetsTitle-_AllAssetsTitle__buttonsWrapper",
	"button": "src-client-modules-activity-pages-task-components-AllAssetsTitle-_AllAssetsTitle__button",
	"button_sortDate": "src-client-modules-activity-pages-task-components-AllAssetsTitle-_AllAssetsTitle__button_sortDate"
};
export default ___CSS_LOADER_EXPORT___;
