import * as React from 'react';
import { connect } from 'react-redux';
import * as lodash from 'lodash';

import type { Autopilot, AutopilotPlacementPlanData } from 'sber-marketing-types/backend';

import { MediaplanStageWidgetTemplate } from './MediaplanStageWidgetTemplate';
import type { StoreState } from '@store';
import { getAutopilot, getMediaplan, getMediaplanStatus } from '@store/autopilot_legacy/selectors';

interface Props extends MapProps {
    readOnly?: boolean;
}

interface MapProps {
    autopilot?: Autopilot;
    mediaplan?: AutopilotPlacementPlanData[];
    calculatingMediaplan?: boolean;
}

@(connect(mapStateToProps, null) as any)
export class MediaplanStageWidgetBehaviour extends React.PureComponent<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        const { mediaplan, calculatingMediaplan } = this.props;

        return React.createElement(MediaplanStageWidgetTemplate, {
            mediaplan,
            displayTable: !calculatingMediaplan && !lodash.isEmpty(mediaplan),
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        autopilot: getAutopilot(state),
        mediaplan: getMediaplan(state),
        calculatingMediaplan: getMediaplanStatus(state),
    };
}
