import gql from 'graphql-tag';

export const GET_BUDGET_ITEM_LINKS_QUERY = gql`
    query getBudgetItemLinks($linkIds: [String]!) {
        links: getProjectLinkBudgetItemApprovalRequests(filter: { id: $linkIds }) {
            nodes {
                id
                status
                budgetItem {
                    id
                }
            }
        }
    }
`;
