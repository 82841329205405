// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-Header-MenuItemDropdown-List-Content-_Content__root{display:block}.src-client-modules-activity-Header-MenuItemDropdown-List-Content-_Content__itemWrapper:hover{background:#f8f8f8}.src-client-modules-activity-Header-MenuItemDropdown-List-Content-_Content__itemWrapper:not(:last-child){border-bottom:1px solid #e3e9e5}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/Header/MenuItemDropdown/List/Content/Content.scss"],"names":[],"mappings":"AAAA,iFACI,aAAc,CACjB,8FAIO,kBAAmB,CAF3B,yGAMQ,+BAAgC","sourcesContent":[".root {\n    display: block;\n}\n\n.itemWrapper {\n    &:hover {\n        background: #f8f8f8;\n    }\n\n    &:not(:last-child) {\n        border-bottom: 1px solid #e3e9e5;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-Header-MenuItemDropdown-List-Content-_Content__root",
	"itemWrapper": "src-client-modules-activity-Header-MenuItemDropdown-List-Content-_Content__itemWrapper"
};
export default ___CSS_LOADER_EXPORT___;
