import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useDefaultRef, useDefaultState } from '@common/hooks';

import { useFormFieldProps } from '../useFormFieldProps';
import { useFormValue } from '../useFormValue';
import { FormField } from '../../types';
import { getFormErrorMessage } from '../../utils';

export interface UseFormField<V, F extends (...a: any[]) => any, R> extends FormField<V, F> {
    rest: R;
    errorMessage: string;
}

export function useFormField<V, F extends (...a: any[]) => any, R>({
    name,
    rules,
    maxLength,
    minLength,
    pattern,
    patternMessage = 'Неверный формат',
    control,
    required,
    value: valueProp,
    onChange,
    inputRef: ref,
    defaultValue,
    ...rest
}: FormField<V, F> & R): UseFormField<V, F, R> {
    const form = useFormContext();
    const [value, setValue] = useDefaultState(valueProp, onChange, form.formState.defaultValues[name] || '');
    const inputRef = useDefaultRef(ref);
    const formFieldProps: Required<FormField<V, F>> = {
        value,
        onChange,
        inputRef,
        name,
        rules,
        maxLength,
        minLength,
        pattern,
        patternMessage,
        control,
        defaultValue,
        required,
    };

    const formProps = useFormFieldProps(
        {
            name,
            rules: { required, validate: rules, maxLength, minLength, pattern: pattern && new RegExp(pattern) },
            defaultValue,
            shouldUnregister: true,
            control,
        },
        inputRef,
    );
    const setFormValue = useFormValue(name, value);

    useEffect(() => {
        setValue(form.formState.defaultValues[name]);

        return () => setFormValue(undefined);
    }, []);

    const errorMessage: string = getFormErrorMessage(formProps, formFieldProps);

    const result: Required<UseFormField<V, F, R>> = {
        value,
        onChange: setValue,
        errorMessage,
        rest: rest as R,
        inputRef,
        name,
        rules,
        maxLength,
        minLength,
        pattern,
        patternMessage,
        control,
        defaultValue,
        required,
    };

    return result;
}
