import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { HeaderView } from '../common/Page';
import { PageOptions } from '@store/common/types';
import { User } from '@store/user/types';
import { StoreState } from '@store';
import { updatePageOptions, setRequestInProgress } from '@store/common/actions';
import { isRequestInProgress } from '@store/common/selectors';
import { getLoginUser } from '@store/user/selector';

import { TmRegistryPage, TMRegistryPageHeader } from './TmRegistryPage';
import { TmStore } from './store/TmStore';
import { TrademarkApi } from '../../api/TrademarkApi';
import { UserConfigApi } from '@api';
import { UserConfigType } from 'sber-marketing-types/openid';

interface Props extends MapProps, DispatchProps, RouteComponentProps {
    setHeaderView?: (view: HeaderView) => void;
}

interface MapProps {
    preloader: boolean;
    user: User;
}

interface DispatchProps {
    updatePageOptions: (options: PageOptions) => void;
    setRequestInProgress: (requestStatus: boolean) => void;
}

interface State {
    shouldRenderPage: boolean;
}

const tmStore: TmStore = new TmStore();

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class TmRegistryPageContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            shouldRenderPage: false,
        };
        this.props.setRequestInProgress(true);
    }

    public async componentDidMount() {
        this.renderHeader();
        const trademarks = await TrademarkApi.getAllTrademarks();
        const userConfigCollection: any = await UserConfigApi.getPageConfig(UserConfigType.TMRegistryCollection);
        const userConfigColumn: any = await UserConfigApi.getPageConfig(UserConfigType.TMRegistryColumn);
        tmStore.load(trademarks, userConfigCollection, userConfigColumn);
        this.props.setRequestInProgress(false);
        this.setState({
            shouldRenderPage: true,
        });
    }

    public render(): JSX.Element {
        return React.createElement(TmRegistryPage, {
            preloader: this.props.preloader,
            shouldRenderPage: this.state.shouldRenderPage,
            tmStore,
        });
    }

    private renderHeader(): void {
        this.props.setHeaderView({
            firstLine: TMRegistryPageHeader(),
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        preloader: isRequestInProgress(state),
        user: getLoginUser(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            updatePageOptions,
            setRequestInProgress,
        },
        dispatch,
    );
}
