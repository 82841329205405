// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-Card-CardFooter-IndicatorList-_IndicatorList__root{overflow:hidden;margin:0 -7px -5px 0}.src-client-modules-common-Card-CardFooter-IndicatorList-_IndicatorList__indicatorWrapper{display:inline-block;margin:0 7px 5px 0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/Card/CardFooter/IndicatorList/IndicatorList.scss"],"names":[],"mappings":"AAAA,8EACI,eAAgB,CAEhB,oBAAqB,CACxB,0FAGG,oBAAqB,CAErB,kBAAmB","sourcesContent":[".root {\n    overflow: hidden;\n\n    margin: 0 -7px -5px 0;\n}\n\n.indicatorWrapper {\n    display: inline-block;\n\n    margin: 0 7px 5px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-Card-CardFooter-IndicatorList-_IndicatorList__root",
	"indicatorWrapper": "src-client-modules-common-Card-CardFooter-IndicatorList-_IndicatorList__indicatorWrapper"
};
export default ___CSS_LOADER_EXPORT___;
