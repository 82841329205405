import * as React from 'react';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';

import { Period } from '@store/calendar/types';

import type { ScrollbarComponent } from 'sber-marketing-ui';
import { HorizontalScrollbar } from './HorizontalScrollbar';
import type { StoreState } from '@store';
import { getCalendarPageState } from '@store/calendar/selectors';
import { Scroller, Scaler } from '../modules';

interface Props extends Partial<MapProps> {}

interface MapProps {
    period: Period;
}

interface State {
    fullWidth: number;
}

@(connect(mapStateToProps, null) as any)
export class HorizontalScrollbarContainer extends React.PureComponent<Props, State> {
    private root: HTMLDivElement;
    private scrollbar: ScrollbarComponent;
    private scroller: Scroller;
    private scaler: Scaler;

    constructor(props: Props) {
        super(props);

        this.state = {
            fullWidth: 0,
        };

        this.scroller = Scroller.getInstance();
        this.scaler = Scaler.getInstance();

        this.scroller.register('horizontalScroll', this.onScrollerEmit);
    }

    public componentDidMount() {
        this.updateFullWidth();

        window.addEventListener('resize', this.onPageResize, { passive: true });

        setTimeout(this.updateScrollLeft);
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.onPageResize);

        this.scroller.dispose();
        this.scaler.dispose();
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.props.period !== prevProps.period) {
            this.updateFullWidth();
        }
    }

    public render(): JSX.Element {
        return React.createElement(HorizontalScrollbar, {
            fullWidth: this.state.fullWidth,
            rootRef: this.rootRef,
            scrollbarRef: this.scrollbarRef,
            onScroll: this.onScroll,
        });
    }

    @autobind
    protected rootRef(element: HTMLDivElement) {
        this.root = element;

        this.updateViewportWidth();
        this.updateFullWidth();
    }

    @autobind
    protected scrollbarRef(component: ScrollbarComponent) {
        this.scrollbar = component;
    }

    @autobind
    protected onScroll(scrollLeft: number) {
        const viewportWidth = this.getViewportWidth();

        this.scroller.setScroll('horizontalScroll', scrollLeft / viewportWidth);
    }

    @autobind
    protected onScrollerEmit() {
        if (this.scrollbar) {
            this.updateScrollLeft();
        }
    }

    @autobind
    protected onPageResize() {
        this.updateViewportWidth();
        this.updateFullWidth();
        this.updateScrollLeft();
    }

    @autobind
    private updateScrollLeft() {
        const viewportWidth = this.getViewportWidth();

        const { currentScroll } = this.scroller;

        if (this.scrollbar) {
            this.scrollbar.scrollLeft = currentScroll * viewportWidth;
        }
    }

    private updateFullWidth() {
        if (this.root) {
            this.setState({
                fullWidth: this.getFullWidth(),
            });
        }
    }

    private getFullWidth(): number {
        return this.scaler.getFullWidthInPx();
    }

    private updateViewportWidth() {
        if (this.root) {
            this.scaler.setViewportWidthInPx(this.root.offsetWidth);
        }
    }

    private getViewportWidth(): number {
        return this.scaler.getViewportWidthInPx();
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { period } = getCalendarPageState(state);

    return {
        period,
    };
}
