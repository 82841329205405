import * as React from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';

import * as styles from './PersonNotFound.scss';

/** Default not found message */
const DEFAULT_MESSAGE = 'Ничего не найдено';

/** Person not found component properties */
export interface PersonNotFoundProps {
    /** Not found message */
    message?: string;
    /** Additional CSS class */
    className?: string;
}

/** Person not found component */
export function PersonNotFound({ message, className }: PersonNotFoundProps): JSX.Element {
    const classList: string[] = [styles.root];
    if (!isNil(className)) {
        classList.push(className);
    }
    return <div className={classNames(classList)}>{isNil(message) ? DEFAULT_MESSAGE : message}</div>;
}
