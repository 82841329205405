import * as React from 'react';
import { connect } from 'react-redux';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';

interface Props extends OwnProps, Partial<MapProps> {}

interface OwnProps {
    children: (props: ChildrenProps) => JSX.Element;
}

interface ChildrenProps extends MapProps {}

interface MapProps {
    userId: number;
}

@(connect(mapStateToProps, null) as any)
export class WithStore extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { children, ...restProps } = this.props;

        return children(restProps as ChildrenProps);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        userId: getLoginUser(state).attributes.id,
    };
}
