import * as React from 'react';
import { isNil } from 'lodash';

import { Modal as ExternalModal } from 'sber-marketing-ui';
import * as style from './Modal.scss';

interface Props {
    lineYCoord: number;
    onMaskClick: () => void;
}

export const Modal: React.FC<Props> = ({ lineYCoord, children, onMaskClick }) => {
    const width = window.innerWidth - 64 - 32; // table paddings

    return (
        <ExternalModal>
            <div className={style.root}>
                <div className={style.contentContainer}>
                    <svg xmlns="http://www.w3.org/2000/svg" className={style.coloredMask} onClick={onMaskClick}>
                        <rect
                            x="0"
                            y="0"
                            width="100%"
                            height="100%"
                            fill="black"
                            fillOpacity="0.2"
                            mask="url(#lineMask)"
                        />

                        {!isNil(lineYCoord) && (
                            <mask id="lineMask">
                                <rect x="0" y="0" width="100%" height="100%" fill="white" />

                                <rect x="64" y={lineYCoord} width={width} height="37" fill="black" />
                            </mask>
                        )}
                    </svg>

                    <div className={style.content}>{children}</div>
                </div>
            </div>
        </ExternalModal>
    );
};
