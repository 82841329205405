import * as React from 'react';

import { Icon, IconType } from 'sber-marketing-ui';

import * as styles from './styles.scss';

export const SuccessIndicator: React.FC = () => {
    return (
        <div className={styles.root}>
            <Icon type={IconType.ACCEPT_ICON_ROUND_ACTIVE} svgSize={13} />
            <div className={styles.text}>Согласовано</div>
        </div>
    );
};
