import * as React from 'react';

import { DropdownOptionsProps, useDropdown } from '@common/hooks';

import { Dropdown, DropdownProps } from '../../popup';
import { Chip, ChipProps } from '../Chip';

export interface DropdownChipProps extends ChipProps, DropdownOptionsProps {
    /** A content inside the tag button */
    chipContent?: React.ReactNode;
    dropdownWidth?: React.HTMLProps<HTMLDivElement>['style']['width'];
    dropdownProps?: Partial<DropdownProps>;
}

export function DropdownChip({
    rootRef,
    chipContent,
    dropdownWidth,
    dropdownProps,
    children,
    ...rest
}: DropdownChipProps) {
    const [dropdown, ref, props] = useDropdown({ ...rest, target: rootRef }, HTMLDivElement);

    return (
        <Chip clickable {...props} rootRef={ref}>
            {chipContent}
            <Dropdown {...dropdownProps} width={dropdownWidth} dropdown={dropdown}>
                {children}
            </Dropdown>
        </Chip>
    );
}
