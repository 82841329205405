import * as moment from 'moment';
import { v4 } from 'uuid';
import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { RoleId } from 'sber-marketing-types/backend';
import { UserCard, WorkTypeParams } from 'sber-marketing-types/frontend';

import { WorkTypeApi, TaskApi, StageApi, FileApi } from '@api';
import { DepartmentApi } from '../../../api/DepartmentApiNew';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getLoginUser } from '@store/user';
import { getAllUsers } from '@store/appUsers';

import { Utils } from '@common/Utils';

import { getTaskEditorState } from '../selectors';
import {
    InitPayload,
    UserWorkTypeData,
    WorkTypeWithUsers,
    ActivityStagesData,
    TaskEditorValues,
    ParticipantParams,
    TaskFiles,
    FileParams,
    FileStatus,
    Permissions,
} from '../types';

import * as syncActions from '../actions/sync';
import * as asyncActions from '../actions/async';

type Moment = moment.Moment;

const userDeclensionsArr = ['пользователь', 'пользователя', 'пользователей'];
const taskDeclensionsArr = ['задача', 'задачи', 'задач'];
function getDeclension(count: number, declensions: string[]) {
    return `${count} ${Utils.getDeclensionByNumber(count, declensions)}`;
}

function userBelongsToWorkType(user: UserCard, workType: WorkTypeParams) {
    return workType.departmentIds.includes(user.departmentId);
}

export const initState = bindThunkAction<StoreState, InitPayload, void, Error>(
    asyncActions.initState,
    async (payload, dispatch, getState) => {
        const { taskId } = payload;

        if (taskId) {
            const task = (await TaskApi.getTask(taskId)).task;
            dispatch(syncActions.setRawTask(task));
        } else {
            dispatch(syncActions.setRawTask(null));
        }

        dispatch(
            syncActions.setTaskEditorCommon({
                ...payload,
                taskId: payload.taskId || v4(),
                taskIsCreated: !!payload.taskId,
            }),
        );

        dispatch(initPermissions(payload));
        dispatch(initEditorValues(payload));
        dispatch(initActivityStages(payload));
        dispatch(initWorkTypeUserData(payload));
    },
);

export const initEditorValues = bindThunkAction<StoreState, InitPayload, TaskEditorValues, Error>(
    asyncActions.initEditorValues,
    async (payload, dispatch, getState) => {
        const state = getState();
        const { taskId } = payload;

        const users = getAllUsers(state);
        const {
            data: { rawTask },
            values: { title: taskTitle, stageId: taskStageId },
        } = getTaskEditorState(state);
        const { id: loginnedUserId, departmentId, roles } = getLoginUser(state).attributes;
        const userIsSupervisor = roles.some((role) => role.id === RoleId.Supervisor);

        let title = taskTitle;
        let description = '';
        let workType = null;
        let budgetApproval = null;
        let executor = null;
        let deadline: Moment = null;
        let files: TaskFiles = {
            byId: {},
            entities: [],
        };
        const uploadedFiles: string[] = [];
        const uploadingFiles: string[] = [];
        let participants: ParticipantParams[] = [
            {
                userId: loginnedUserId,
                canRemove: false,
            },
        ];
        let stageId = taskStageId;

        if (taskId) {
            let allowSupervisorToDeleteParticipant = false;
            if (userIsSupervisor) {
                const subdepartmentIds = (await DepartmentApi.getListByParent(departmentId)).map(
                    (department) => department.id,
                );
                const usersBySubdepartment = users
                    .filter((user) => subdepartmentIds.includes(user.departmentId))
                    .map((user) => user.id);
                allowSupervisorToDeleteParticipant = usersBySubdepartment.some(
                    (user) => user === rawTask.authorId || user === rawTask.executorId,
                );
            }

            Utils.withErrorHandler(() => {
                title = rawTask.title;
                description = rawTask.description;
                workType = rawTask.workTypeId;
                budgetApproval = rawTask.budgetApproval;
                executor = rawTask.executorId;
                deadline = moment(rawTask.deadline);
                stageId = rawTask.stageId;

                const taskAuthor = `${rawTask.authorId}`;

                const taskFiles: FileParams[] = rawTask.files.map((file) => ({
                    asset: FileApi.mapFile({ taskId }, file, taskAuthor),
                    status: FileStatus.Uploaded,
                }));
                const filesById = taskFiles.reduce(
                    (acc, file) => ({
                        ...acc,
                        [file.asset.id]: file,
                    }),
                    {},
                );

                files = {
                    byId: filesById,
                    entities: taskFiles,
                };

                participants = rawTask.participants.map((participant) => {
                    const { userId, invitingUserId } = participant;

                    let canRemove = false;
                    if (userId === rawTask.authorId || userId === rawTask.executorId) {
                        canRemove = false;
                    } else if (
                        allowSupervisorToDeleteParticipant ||
                        loginnedUserId === rawTask.authorId ||
                        loginnedUserId === rawTask.executorId ||
                        loginnedUserId === invitingUserId ||
                        loginnedUserId === userId
                    ) {
                        canRemove = true;
                    }

                    return {
                        userId,
                        canRemove,
                    };
                });
            });
        }

        return {
            loadingStatus: LoadingStatus.LOADED,
            title,
            description,
            workType,
            budgetApproval,
            executor,
            deadline,
            files,
            uploadedFiles,
            uploadingFiles,
            participants: {
                existing: participants.map((participant) => participant.userId).sort(),
                editing: participants,
            },
            stageId,
        };
    },
);

export const initActivityStages = bindThunkAction<StoreState, InitPayload, Partial<ActivityStagesData>, Error>(
    asyncActions.initActivityStages,
    async (payload, dispatch, getState) => {
        const { activityId } = payload;

        dispatch(syncActions.setActivityStagesLoadingStatus(LoadingStatus.LOADING));
        const activityStages = (await StageApi.graphqlGetActivityStages([activityId]))[0]?.stages || [];
        const tasks = await TaskApi.getTaskList({ activityId, includeDraft: true });

        return {
            loadingStatus: LoadingStatus.LOADED,
            entities: [null, ...activityStages].map((stage) => ({
                id: stage?.id || null,
                title: stage?.dictionary?.name || 'Без этапа',
                subtitle: getDeclension(
                    tasks.filter((task) => task.stageId === (stage?.id || null)).length,
                    taskDeclensionsArr,
                ),
            })),
        };
    },
);

export const initWorkTypeUserData = bindThunkAction<StoreState, InitPayload, Partial<UserWorkTypeData>, Error>(
    asyncActions.initWorkTypeUserData,
    async (payload, dispatch, getState) => {
        const state: StoreState = getState();
        const users = getAllUsers(state);
        const { id: loginnedUserId, organizationId, departmentId } = getLoginUser(state).attributes;
        const {
            rawTask,
            userWorkType: { loadingStatus },
        } = getTaskEditorState(state).data;

        const onlyShowUsersFromUserDepartment =
            rawTask && rawTask.executorId === loginnedUserId && rawTask.authorId !== loginnedUserId;

        if (loadingStatus === LoadingStatus.NOT_LOADED) {
            dispatch(syncActions.setWorkTypeUserLoadingStatus(LoadingStatus.LOADING));

            try {
                const usersToUse = users.filter((user) => {
                    const result = user.isActive && user.department;

                    if (onlyShowUsersFromUserDepartment) {
                        return result && user.departmentId === departmentId;
                    }

                    return result;
                });
                const allWorkTypes = (await WorkTypeApi.getList()).filter((workType) => !workType.isArchived);
                const workTypesToUse = allWorkTypes.filter(
                    (workType) => workType.organizationId === organizationId || workType.id === rawTask?.workTypeId,
                );

                const usersWithWorkTypes = usersToUse.reduce((acc, user) => {
                    const userWorkTypes = workTypesToUse.filter((workType) => userBelongsToWorkType(user, workType));

                    if (!userWorkTypes.length) {
                        return acc;
                    }

                    return [
                        ...acc,
                        {
                            id: user.id,
                            title: `${user.secondName} ${user.firstName}`,
                            subTitle: user.department,
                            departmentId: user.departmentId,
                            vacation: user.vacation,
                            subItems: userWorkTypes.map((workType) => ({
                                id: workType.id,
                                title: workType.name,
                            })),
                        },
                    ];
                }, []);
                const workTypesWithUsers: WorkTypeWithUsers[] = workTypesToUse.reduce((acc, workType) => {
                    const workTypeUsers = usersToUse.filter((user) => userBelongsToWorkType(user, workType));

                    if (!workTypeUsers.length) {
                        return acc;
                    }
                    return [
                        ...acc,
                        {
                            id: workType.id,
                            title: workType.name,
                            subItems: workTypeUsers.map((user) => ({
                                id: user.id,
                                title: `${user.secondName} ${user.firstName}`,
                                vacation: user.vacation,
                            })),
                            subTitle:
                                workTypeUsers.length === 1
                                    ? `${workTypeUsers[0].secondName} ${workTypeUsers[0].firstName}`
                                    : getDeclension(workTypeUsers.length, userDeclensionsArr),
                        },
                    ];
                }, []);

                return {
                    loadingStatus: LoadingStatus.LOADED,
                    usersWithWorkTypes,
                    workTypesWithUsers,
                };
            } catch (e) {
                dispatch(syncActions.setWorkTypeUserLoadingStatus(LoadingStatus.ERROR));
            }
        }

        return {};
    },
);

export const initPermissions = bindThunkAction<StoreState, InitPayload, Partial<Permissions>, Error>(
    asyncActions.initPermissions,
    async (payload) => payload.permissions,
);
