import { TableLineGroup } from '@store/budgetPlanning/types';

const LINE_HEIGHT = 38;
const TOTAL_LINE_MARGIN = 10;

interface Params {
    index: number;
    position: number;
    groups: TableLineGroup[];
    isLast: boolean;
}

export class GroupedFrame {
    public readonly index: number;

    public readonly position: number;

    public readonly groups: TableLineGroup[];

    public readonly isLast: boolean;

    constructor({ index, position, groups, isLast }: Params) {
        this.index = index;
        this.position = position;
        this.groups = groups;
        this.isLast = isLast;
    }

    public getHeight(): number {
        let height = this.groups.reduce(
            (acc, item) => acc + (item.lines.length + 1) * LINE_HEIGHT + TOTAL_LINE_MARGIN,
            0,
        );

        if (this.isLast && height > 0) {
            height -= TOTAL_LINE_MARGIN;
        }

        return height;
    }
}
