// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-brief-ContentNav-NavItemList-_NavItemList__root{margin:10px 0 0 4px;padding-left:10px}.src-client-modules-brief-ContentNav-NavItemList-_NavItemList__startNestingLevel{letter-spacing:0.15px;color:#000000;font-family:\"Open Sans\";font-size:14px;font-weight:600}.src-client-modules-brief-ContentNav-NavItemList-_NavItemList__navItemDropdownWrapper{margin:0 0 10px 0}.src-client-modules-brief-ContentNav-NavItemList-_NavItemList__navItemDropdownWrapper:last-child{margin-bottom:0}.src-client-modules-brief-ContentNav-NavItemList-_NavItemList__childrenNavItem{margin:0 0 5px 0}.src-client-modules-brief-ContentNav-NavItemList-_NavItemList__childrenNavItem:last-child{margin-bottom:0}.src-client-modules-brief-ContentNav-NavItemList-_NavItemList__slider{width:5px;height:10px;background-color:#00b957}\n", "",{"version":3,"sources":["webpack://./src/client/modules/brief/ContentNav/NavItemList/NavItemList.scss"],"names":[],"mappings":"AAAA,oEACI,mBAAoB,CACpB,iBAAkB,CAGrB,iFAGG,qBAAsB,CAEtB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,eAAgB,CACnB,sFAGG,iBAAkB,CADtB,iGAIQ,eAAgB,CACnB,+EAID,gBAAiB,CADrB,0FAIQ,eAAgB,CACnB,sEAID,SAAU,CACV,WAAY,CAEZ,wBAAyB","sourcesContent":[".root {\n    margin: 10px 0 0 4px;\n    padding-left: 10px;\n\n    //border-left: 1px solid #dbdbdb;\n}\n\n.startNestingLevel {\n    letter-spacing: 0.15px;\n\n    color: #000000;\n\n    font-family: \"Open Sans\";\n    font-size: 14px;\n    font-weight: 600;\n}\n\n.navItemDropdownWrapper {\n    margin: 0 0 10px 0;\n\n    &:last-child {\n        margin-bottom: 0;\n    }\n}\n\n.childrenNavItem {\n    margin: 0 0 5px 0;\n\n    &:last-child {\n        margin-bottom: 0;\n    }\n}\n\n.slider {\n    width: 5px;\n    height: 10px;\n\n    background-color: #00b957;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-brief-ContentNav-NavItemList-_NavItemList__root",
	"startNestingLevel": "src-client-modules-brief-ContentNav-NavItemList-_NavItemList__startNestingLevel",
	"navItemDropdownWrapper": "src-client-modules-brief-ContentNav-NavItemList-_NavItemList__navItemDropdownWrapper",
	"childrenNavItem": "src-client-modules-brief-ContentNav-NavItemList-_NavItemList__childrenNavItem",
	"slider": "src-client-modules-brief-ContentNav-NavItemList-_NavItemList__slider"
};
export default ___CSS_LOADER_EXPORT___;
