import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import type { StoreState } from '@store';

import { AutopilotApi, AutopilotLegacyApi, AutopilotTvApi, AutopilotRadioApi, AutopilotDOOHApi } from '@api';
import { AutopilotsMap } from '@modules/autopilot/types';
import { getAutopilotsTab } from '@store/autopilotsTab/selectors';

import * as actions from './actions';

export const loadAutopilots = bindThunkAction<StoreState, number, AutopilotsMap, Error>(
    actions.loadAutopilotsAsync,
    async (activityId, dispatch, getState) => {
        const state = getAutopilotsTab(getState());

        if (state.activityId === activityId) {
            return state.autopilots;
        }

        dispatch(actions.setActivityId(activityId));

        const [[autopilot_v2], [autopilot], [autopilotTv], [autopilotRadio], [autopilotDOOH]] = await Promise.all([
            AutopilotApi.getAutopilotList({ activityIds: [activityId] }),
            AutopilotLegacyApi.getList({ activityIds: [activityId] }),
            AutopilotTvApi.getAutopilotList({ activityIds: [activityId] }),
            AutopilotRadioApi.getAutopilotList({ activityIds: [activityId] }),
            AutopilotDOOHApi.getAutopilotList({ activityIds: [activityId] }),
        ]);

        return {
            autopilot,
            autopilot_v2,
            autopilotTv,
            autopilotRadio,
            autopilotDOOH,
        };
    },
);
