import * as React from 'react';
import classNames from 'classnames';

import { Flex, FlexProps } from '@common/components';

import * as styles from './Label.scss';

export type LabelProps<E extends keyof JSX.IntrinsicElements = 'div'> = FlexProps<E> & {
    required?: boolean;
};

export function Label<E extends keyof JSX.IntrinsicElements = 'div'>({ className, required, ...props }: LabelProps<E>) {
    return <Flex {...(props as any)} className={classNames(styles.root, required && styles.required, className)} />;
}
