import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    Popup,
    PopupTheme,
    CloseButton,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
} from 'sber-marketing-ui';
import classnames from 'classnames';

import { StoreState } from '@store';
import { getUploadedFilesWithViruses } from '@store/common';

import * as styles from './FileUploadErrorPopup.scss';

interface Props {
    closePopup: () => void;
}

export function FileUploadErrorPopup({ closePopup }: Props): JSX.Element {
    const filesWithViruses = useSelector((state: StoreState) => getUploadedFilesWithViruses(state));

    let content: JSX.Element;
    if (filesWithViruses.length === 1) {
        content = (
            <div className={styles.message}>
                В целях безопасности файл&nbsp;
                <span className={styles.messageColored}>{filesWithViruses[0]}&nbsp;</span>
                удален
            </div>
        );
    } else {
        content = (
            <div className={styles.message}>
                В целях безопасности будут удалены файлы:
                <br />
                {filesWithViruses.map((fileName, i) => (
                    <div className={classnames(styles.messageColored, styles.fileName)} key={`${fileName}-${i}`}>
                        {fileName}
                    </div>
                ))}
            </div>
        );
    }

    return (
        <Popup onOutOfContentClick={closePopup} theme={PopupTheme.errorMessageTheme}>
            <div className={styles.root}>
                <div className={styles.titleRow}>
                    <span className={styles.title}>Обнаружена вирусная угроза</span>

                    <CloseButton onClick={closePopup} />
                </div>

                {content}

                <div className={styles.button}>
                    <Button theme={ButtonTheme.GhostRoundedBlack} onClick={closePopup}>
                        Ясно
                    </Button>
                </div>
            </div>
        </Popup>
    );
}
