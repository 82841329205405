import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { ColumnName } from '@store/budgetExecution/types';

import * as style from './Table.scss';

import { CustomScrollbar_new as CustomScrollbar, ScrollbarComponent } from 'sber-marketing-ui';
import { Tooltip, TooltipDirection } from './Tooltip';
import { TableHeader } from './TableHeader';
import { TableBody } from './TableBody';
import { SumLine } from './SumLine';
import { FrameManager } from './FrameManager';
import {
    CellsParamsEffect,
    PageBudgetItemsEffect,
    LineIdsWithActualChangesEffect,
    TableLinesEffect,
    DropdownOptionsEffect,
    TableScrollerHook,
} from './Effects';

const HEADER_HEIGHT = 28;
const LINE_HEIGHT = 38;

export interface TooltipPosition {
    direction: TooltipDirection;
    y: number;
}

export interface DropdownPosition {
    width: number;
    height: number;
    y: number;
    x: number;
}

interface TableProps {
    showNoLinesStub: boolean;
    frameManager: FrameManager;
    currentFrameIndex: number;
    columnsWidth: { [columnName: string]: number };
    visibleColumnsNames: ColumnName[];
    hoveredColumnName: ColumnName;
    hoveredTotalLineActivityId: string;
    currentDropdownContent: JSX.Element;
    dropdownContentX: number;
    tooltipPosition: TooltipPosition;
    rootWidth: number;
    maxBodyHeight: number;
    dropdownsContentWrapper: HTMLDivElement;
    dropdownCellContentWrapper: HTMLDivElement;
    dropdownCellContent: JSX.Element;
    dropdownCellPosition: DropdownPosition;
    currentYScroll: number;
    tableScrollbarRef: ScrollbarComponent;
    rootRef: (element: HTMLDivElement) => void;
    headerRef: (component: ScrollbarComponent) => void;
    bodyRef: (component: ScrollbarComponent) => void;
    sumLineRef: (component: ScrollbarComponent) => void;
    headerFixedColumnsRef: (element: HTMLDivElement) => void;
    fixedColumnsLinesRef: (element: HTMLDivElement) => void;
    totalLinesContentRef: (element: HTMLDivElement) => void;
    dropdownsContentRef: (element: HTMLDivElement) => void;
    dropdownCellContentRef: (element: HTMLDivElement) => void;
    onBodyScroll: () => void;
    onHeaderCellMouseEnter: (columnName: ColumnName) => void;
    onHeaderCellMouseLeave: () => void;
    onLineMouseEnter: (id: string, skipCheck?: boolean) => void;
    onLineMouseLeave: () => void;
    onHeaderDropdownClick: (columnName: ColumnName, dropdownContent: JSX.Element) => void;
    onInfoMouseEnter: (lineId: string) => void;
    onInfoMouseLeave: () => void;
    onDropdownCellClick: (
        columnName: ColumnName,
        budgetItemId: string,
        dropdownContent: JSX.Element,
        contentHeight: number,
    ) => void;
    onApplyFiltersButtonClick: () => void;
}

export const Table = ({
    showNoLinesStub,
    frameManager,
    currentFrameIndex,
    columnsWidth,
    visibleColumnsNames,
    hoveredColumnName,
    hoveredTotalLineActivityId,
    currentDropdownContent,
    dropdownContentX,
    tooltipPosition,
    rootWidth,
    maxBodyHeight,
    dropdownsContentWrapper,
    dropdownCellContentWrapper,
    dropdownCellContent,
    dropdownCellPosition,
    currentYScroll,
    tableScrollbarRef,
    rootRef,
    headerRef,
    bodyRef,
    headerFixedColumnsRef,
    fixedColumnsLinesRef,
    totalLinesContentRef,
    dropdownsContentRef,
    dropdownCellContentRef,
    sumLineRef,
    onBodyScroll,
    onHeaderCellMouseEnter,
    onHeaderCellMouseLeave,
    onLineMouseEnter,
    onLineMouseLeave,
    onHeaderDropdownClick,
    onInfoMouseEnter,
    onInfoMouseLeave,
    onDropdownCellClick,
    onApplyFiltersButtonClick,
}: TableProps): JSX.Element => {
    return (
        <div className={style.root} ref={rootRef}>
            <div className={style.tableHeader}>
                <CustomScrollbar
                    fixedHeight={HEADER_HEIGHT}
                    scrollbarRef={headerRef}
                    freezeScrollX
                    hideScrollX
                    hideScrollY
                >
                    <TableHeader
                        hoveredColumnName={hoveredColumnName}
                        headerFixedColumnsRef={headerFixedColumnsRef}
                        onCellMouseEnter={onHeaderCellMouseEnter}
                        onCellMouseLeave={onHeaderCellMouseLeave}
                        onDropdownClick={onHeaderDropdownClick}
                        onApplyFiltersButtonClick={onApplyFiltersButtonClick}
                    />
                </CustomScrollbar>

                <div
                    className={style.dropdownsContent}
                    style={{ left: `${dropdownContentX}px` }}
                    ref={dropdownsContentRef}
                >
                    {dropdownsContentWrapper && ReactDOM.createPortal(currentDropdownContent, dropdownsContentWrapper)}
                </div>
            </div>

            <div className={style.tableBody}>
                <CustomScrollbar
                    minHeight={200}
                    maxHeight={maxBodyHeight}
                    scrollbarRef={bodyRef}
                    onScroll={onBodyScroll}
                >
                    {showNoLinesStub && <NoLinesStub />}

                    <TableBody
                        currentFrameIndex={currentFrameIndex}
                        frameManager={frameManager}
                        rootWidth={rootWidth}
                        columnsWidth={columnsWidth}
                        visibleColumnsNames={visibleColumnsNames}
                        currentYScroll={currentYScroll}
                        fixedColumnsLinesRef={fixedColumnsLinesRef}
                        totalLinesContentRef={totalLinesContentRef}
                        onLineMouseEnter={onLineMouseEnter}
                        onLineMouseLeave={onLineMouseLeave}
                        onInfoMouseEnter={onInfoMouseEnter}
                        onInfoMouseLeave={onInfoMouseLeave}
                        onDropdownCellClick={onDropdownCellClick}
                    />
                </CustomScrollbar>
            </div>

            <div className={style.sumLine}>
                <SumLine scrollbarRef={sumLineRef} />
            </div>

            {tooltipPosition && (
                <div
                    className={style.tooltipWrapper}
                    style={{
                        top:
                            tooltipPosition.direction == TooltipDirection.Up
                                ? tooltipPosition.y
                                : tooltipPosition.y + LINE_HEIGHT,
                    }}
                >
                    <Tooltip lineId={hoveredTotalLineActivityId} direction={tooltipPosition.direction} />
                </div>
            )}

            <div
                className={style.dropdownCellWrapper}
                style={
                    dropdownCellPosition && {
                        left: dropdownCellPosition.x,
                        top: dropdownCellPosition.y,
                        width: dropdownCellPosition.width,
                        height: dropdownCellPosition.height,
                    }
                }
                ref={dropdownCellContentRef}
            >
                {dropdownCellContentWrapper && ReactDOM.createPortal(dropdownCellContent, dropdownCellContentWrapper)}
            </div>

            <CellsParamsEffect />
            <PageBudgetItemsEffect />
            <LineIdsWithActualChangesEffect />
            <TableLinesEffect />
            <DropdownOptionsEffect />
            <TableScrollerHook tableScrollbar={tableScrollbarRef} />
        </div>
    );
};

function NoLinesStub(): JSX.Element {
    return <div className={style.noLinesStub}>Пожалуйста, выберите фильтры и нажмите кнопку "Применить фильтры"</div>;
}
