import * as React from 'react';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type {
    TableHeaderCellParams,
    TableBodyCellParams,
    CellPosition,
    ColumnWidths,
    LineId,
    ColumnName,
    SortingParams,
} from './types';
import {
    CreativeRequestLine,
    CreativeRequestSubLine,
    CreativeRequestTableSettings,
    Dictionary,
    DictionaryType,
} from '@api';

import { CellsStorage, TableView, CellEvent } from 'sber-marketing-ui';
import { TotalTableTemplate } from './TotalTableTemplate';
import { tableColumns, leftFixedColumns, rightFixedColumns, ColumnsConfig, LineType, CellType } from './ColumnsConfig';
import { ColumnHeaderFactory, CellsFactory } from './modules';

const columnWidths: ColumnWidths = lodash.mapValues(ColumnsConfig, (item) => item.defaultWidth);

const LineIds = ['sumWithoutVat'];

interface Props {
    loading: boolean;
    getLine: (lineId: LineId) => CreativeRequestLine;
    getSubLine: (subLineId: LineId) => CreativeRequestSubLine;
    getLines: () => CreativeRequestLine[];
    getSubLines: () => CreativeRequestSubLine[];
    getSubLinesByLineId: (lineId: LineId) => CreativeRequestSubLine[];
    getTableSettings: () => CreativeRequestTableSettings;
    getDictionaries: () => Partial<Record<DictionaryType, Dictionary[]>>;
}

interface State {
    statusFilterIsActive: boolean;
    leftFixedColumns: ColumnName[];
    columns: ColumnName[];
    sortingParams: SortingParams;
    tableHeight: number;
}

export class TotalTableBehaviour extends React.PureComponent<Props, State> {
    private headerCellsStorage: CellsStorage<string, TableHeaderCellParams>;
    private tableCellsStorage: CellsStorage<CellPosition, TableBodyCellParams>;
    private table: TableView;
    private columnHeaderFactory: ColumnHeaderFactory;
    private cellsFactory: CellsFactory;
    private fixedWidthColumns: ColumnName[];

    public constructor(props: Props) {
        super(props);

        this.state = {
            statusFilterIsActive: false,
            leftFixedColumns: leftFixedColumns,
            columns: tableColumns,
            sortingParams: { columnName: null, orderType: null },
            tableHeight: 0,
        };

        this.columnHeaderFactory = new ColumnHeaderFactory({});

        this.cellsFactory = new CellsFactory({
            lot: 1,
            getLines: this.props.getLines,
            getSubLines: this.props.getSubLines,
            getTableSettings: this.props.getTableSettings,
            getDictionaries: this.props.getDictionaries,
            onCellClose: () => this.table.setCursorPosition(null),
        });

        this.headerCellsStorage = new CellsStorage({
            makeCellParams: this.columnHeaderFactory.makeColumnHeaderParams,
        });

        this.tableCellsStorage = new CellsStorage({
            makeCellParams: this.cellsFactory.makeCellParams,
        });

        this.updateFixedWidthColumns();
    }

    public async componentDidMount() {
        this.updateTableHeight();

        window.addEventListener('resize', this.onPageResize);
    }

    public async componentWillUnmount() {
        window.removeEventListener('resize', this.onPageResize);
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        const loadingFinished = !this.props.loading && prevProps.loading;

        if (loadingFinished) {
            this.updateLineCells('sumWithoutVat');
        }
    }

    public render(): JSX.Element {
        const { loading } = this.props;
        const { leftFixedColumns, columns, tableHeight } = this.state;

        return React.createElement(TotalTableTemplate, {
            loading,
            headerCellsStorage: this.headerCellsStorage,
            tableCellsStorage: this.tableCellsStorage,
            columns,
            leftFixedColumns,
            rightFixedColumns,
            fixedWidthColumns: this.fixedWidthColumns,
            lineIds: LineIds,
            columnWidths,
            tableHeight,
            tableRef: this.tableRef,
            onCellEvent: this.onCellEvent,
        });
    }

    @autobind
    protected onPageResize() {
        this.updateTableHeight();
        this.updateLineCells('sumWithoutVat');
    }

    @autobind
    public onLineCreate(lineId: string) {
        const line = this.props.getLine(lineId);

        if (line) {
            this.updateLineCells('sumWithoutVat');
        }
    }

    @autobind
    public onSubLineCreate(subLineId: string) {
        const newSubLine = this.props.getSubLine(subLineId);

        if (newSubLine) {
            this.updateLineCells('sumWithoutVat');
        }
    }

    @autobind
    public onLineUpdate() {
        this.updateLineCells('sumWithoutVat');
    }

    @autobind
    public onSubLineUpdate(subLineId: string) {
        this.updateLineCells('sumWithoutVat');
    }

    @autobind
    public onLineContractChange(lineId: string) {
        this.updateLineCells('sumWithoutVat');
    }

    @autobind
    public onTableSettingsChange() {
        this.updateLineCells('sumWithoutVat');
    }

    @autobind
    public async getTableDataForXLSX(): Promise<{
        columnsWidths: number[];
        headers: string[];
        rows: React.ReactText[][];
    }> {
        const { leftFixedColumns } = this.state;

        const columnWidths = this.table.getColumnWidths();

        const columns = [...leftFixedColumns, ...tableColumns, ...rightFixedColumns];

        const headers = await Promise.all(
            columns.map(async (columnName) => {
                const params = await this.headerCellsStorage.getCellParams(columnName);

                return params.cellProps.title;
            }),
        );

        const rows = await Promise.all(
            LineIds.map(
                async (lineId) =>
                    await Promise.all(
                        columns.map(async (columnName) => {
                            const cellParams = await this.tableCellsStorage.getCellParams({ lineId, columnName });

                            const column = ColumnsConfig[columnName];

                            const cellType = column.type[LineType.SumWithoutVat];

                            let title = cellParams?.cellProps?.title || '';

                            if (cellType === CellType.Text || cellType === CellType.Select) {
                                title = title.toString();
                            }

                            if (cellType === CellType.FundsInput || cellType === CellType.FundsSelect) {
                                title = title.replaceAll(' ', '');
                                title = title.replaceAll(',', '.');
                                title = parseFloat(title);

                                if (lodash.isNaN(title)) {
                                    title = '';
                                }
                            }

                            return title;
                        }),
                    ),
            ),
        );

        return {
            columnsWidths: columns.map((columnName) => columnWidths[columnName]),
            headers,
            rows,
        };
    }

    @autobind
    protected tableRef(component: TableView) {
        this.table = component || null;
    }

    @autobind
    protected async onCellEvent(eventType: CellEvent, position: CellPosition) {
        switch (eventType) {
            case CellEvent.MouseSelection:
                await this.updateCell(position, true);
                break;

            case CellEvent.SelectionCancel:
                await this.updateCell(position, false);
                break;
        }
    }

    private updateTableHeight() {
        const tableBodyOffsetTop = 189;
        const tableBodyOffsetBottom = 147;

        this.setState({
            tableHeight: window.innerHeight - tableBodyOffsetTop - tableBodyOffsetBottom,
        });
    }

    private async updateCell(position: CellPosition, edit = false) {
        const cellParams = await this.cellsFactory.makeCellParams(position, edit);

        this.tableCellsStorage.setCellParams(position, cellParams);
    }

    @autobind
    private updateLineCells(lineId: LineId) {
        const { leftFixedColumns, columns } = this.state;

        if (this.table) {
            const allColumns = [...leftFixedColumns, ...columns, ...rightFixedColumns];

            allColumns.forEach((columnName) => {
                const cellPosition = { lineId, columnName };

                const cellEditStatus = this.table.getCellEditStatus(cellPosition);
                this.updateCell(cellPosition, cellEditStatus);
            });
        }
    }

    private updateFixedWidthColumns() {
        this.fixedWidthColumns = lodash
            .keys(ColumnsConfig)
            .filter((columnName) => ColumnsConfig[columnName].disableWidthChange) as ColumnName[];
    }
}
