import * as React from 'react';
import { useParams } from 'react-router-dom';

import { IconType } from 'sber-marketing-ui';

import { useSearch, useSetSearch } from '@common/hooks';

import { Flex, FlexProps, TabProps, Tabs } from '@common/components';

import { ActivityTaskPageParams } from '@modules/task/pages';
import { TaskKebabMenu } from '@modules/task/components';

import { TaskPageFiles, TaskPageInfo, TaskPageParticipants } from '..';

import * as styles from './TaskPageAside.scss';

export interface TaskPageAsideProps extends FlexProps {}

const keys = ['info', 'users', 'files'] as const;

type TabKeys = typeof keys[number];

const tabs: TabProps<TabKeys>[] = [
    {
        value: 'info',
        icon: IconType.CREATIVE_INFO_TAB,
        children: 'Описание задачи',
    },
    {
        value: 'users',
        icon: IconType.CREATIVE_USERS_TAB,
        children: 'Участники',
    },
    {
        value: 'files',
        icon: IconType.ATTACHMENT_ICON,
        children: 'Вложения',
    },
];

const defaultTab: TabKeys = 'info';

export function TaskPageAside(props: TaskPageAsideProps) {
    const { taskId, activityId } = useParams<ActivityTaskPageParams>();
    const [{ taskTab = defaultTab, ...restSearch }] = useSearch();
    const setSearch = useSetSearch();
    const rawSelected = Array.isArray(taskTab) ? taskTab[0] : taskTab;

    const handleSelect = (selected: TabKeys) => {
        setSearch({ ...restSearch, taskTab: selected === defaultTab ? undefined : selected });
    };

    const tabContent: Record<TabKeys, JSX.Element> = {
        info: <TaskPageInfo />,
        users: <TaskPageParticipants />,
        files: <TaskPageFiles />,
    };

    const selected: TabKeys = keys.includes(rawSelected as TabKeys) ? (rawSelected as TabKeys) : defaultTab;

    return (
        <Flex data-qa-id="TaskPageAside" vertical {...props} className={styles.root}>
            <Flex align="flex-start" justify="space-between" padding={[16, 20, 0]} className={styles.header}>
                <Tabs<TabKeys> squeeze tabs={tabs} selected={selected} onSelect={handleSelect} />
                <TaskKebabMenu activityId={Number(activityId)} taskId={taskId} />
            </Flex>
            <div className={styles.content}>{tabContent[selected]}</div>
        </Flex>
    );
}
