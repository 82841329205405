import gql from 'graphql-tag';

export const GET_PROJECT_LINKED_BUDGET_ITEMS = gql`
    query getProjectLinkedBudgetItems($linkIds: [String]!) {
        linkRequests: getProjectLinkBudgetItemApprovalRequests(
            filter: { id: $linkIds, status: ["pending", "approved"] }
        ) {
            nodes {
                id
                status
                author {
                    id
                }
                budgetItem {
                    id
                    serialNumber
                    budget {
                        id
                    }
                }
            }
        }
    }
`;
