import { useEffect, useState } from 'react';
import { useUsers } from './Sidebar/widgets/Users/Users';
import { useSelector } from 'react-redux';
import { StoreState } from '@store/index';
import { MrmClient } from '@api';
import { useParams } from 'react-router-dom';
import { useCreatedAt, useDescription, useParams as useInfoParams } from './Sidebar/widgets/Info/hooks';

export const useTableDescriptionDataForXLSX = () => {
    const { creativeRequestId } = useParams<{ creativeRequestId: string }>();
    const [creativeRequest, setCreativeRequest] = useState(null);
    const [paramsValues, setParamsValues] = useState<any[]>([]);
    const [infoRous, setInfoRous] = useState(null);

    // users
    const usersCache = useUsers({ creativeRequest }).usersCache;
    const appUsers = useSelector((state: StoreState) => state.appUsers.entities);

    const userRows = Object.entries(usersCache).map(([id, item]) => [
        `${item.user.firstName} ${item.user.middleName} ${item.user.secondName}`,
        appUsers[id].department,
        item.roles.join(', '),
    ]);

    // info
    const description = useDescription(creativeRequest);
    const createdAt = useCreatedAt(creativeRequest);
    const params = useInfoParams(creativeRequest);

    const getCreativeRequest = async () => {
        const client = await MrmClient.getInstance();
        const creativeRequest = await client.domain.creativeRequests.getCreativeRequest({ id: creativeRequestId });
        setCreativeRequest(creativeRequest);
    };

    const getParams = async (param: any) => {
        const block = (await param.model.block)?.value || '';
        const division = (await param.model.division)?.value || '';
        const product = (await param.model.product)?.value || '';
        const segment = (await param.model.segment)?.value || '';
        const channel = (await param.model.channel)?.value || '';
        setParamsValues((value) => [
            ...value,
            [
                ['Блок', block],
                ['Дивизион Бизн. Заказч.', division],
                ['Продукт', product],
                ['Сегмент', segment],
                ['Канал', channel],
            ],
        ]);
    };

    useEffect(() => {
        getCreativeRequest();
    }, []);
    useEffect(() => {
        if (paramsValues?.length === params?.length) {
            setInfoRous([
                ['Краткое описание', description],
                ['Дата создания заявки', createdAt],
                ...paramsValues.flat(),
            ]);
        }
    }, [paramsValues]);

    useEffect(() => {
        params?.forEach((param) => {
            getParams(param);
        });
    }, [params]);

    return {
        userRows,
        infoRous,
    };
};
