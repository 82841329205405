// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-tags-TagsPage-Filters-TagsFilter-_TagsFilter__root{display:flex;align-items:baseline;flex-wrap:wrap}.src-client-modules-tags-TagsPage-Filters-TagsFilter-_TagsFilter__title{margin-right:8px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/tags/TagsPage/Filters/TagsFilter/TagsFilter.scss"],"names":[],"mappings":"AAAA,uEACI,YAAa,CACb,oBAAqB,CACrB,cAAe,CAClB,wEAGG,gBAAiB","sourcesContent":[".root {\n    display: flex;\n    align-items: baseline;\n    flex-wrap: wrap;\n}\n\n.title {\n    margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-tags-TagsPage-Filters-TagsFilter-_TagsFilter__root",
	"title": "src-client-modules-tags-TagsPage-Filters-TagsFilter-_TagsFilter__title"
};
export default ___CSS_LOADER_EXPORT___;
