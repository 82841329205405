const TERBANK_ORGANIZATION_ID = 'e2ee630b-8fe9-4e68-a6cf-ea3c9d2709e8';
const DMIK_ORGANIZATION_ID = '68a38c6a-79f7-49b5-9356-b779fbbb9b64';
const SBERBANK_BUSINESS_CUSTOMER_ORGANIZATION_ID = '6aa41a85-1716-43a7-9c91-9933c9b945a4';
const KES_ORGANIZATION_ID = 'be2a0a4b-4b76-46ae-8bab-0f52401e215d';

export type UUID = string;

type OrganizationsIdsRelationshipMap = {
    [key: string]: UUID;
};

const organizationsIdsRelationshipMap: OrganizationsIdsRelationshipMap = {
    [TERBANK_ORGANIZATION_ID]: DMIK_ORGANIZATION_ID,
    [SBERBANK_BUSINESS_CUSTOMER_ORGANIZATION_ID]: DMIK_ORGANIZATION_ID,
    [KES_ORGANIZATION_ID]: DMIK_ORGANIZATION_ID,
};

export const defineDictionaryOrganizationIdByOrganizationId = (organizationId: UUID): string => {
    return organizationsIdsRelationshipMap[organizationId] || organizationId;
};
