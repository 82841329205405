// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-tags-TagsPage-RightSidebar-Content-_Content__root{padding:12px 24px}.src-client-modules-tags-TagsPage-RightSidebar-Content-_Content__contentList{display:flex;flex-direction:column;gap:14px 0px}.src-client-modules-tags-TagsPage-RightSidebar-Content-_Content__preloader1{width:25%;margin-bottom:3px}.src-client-modules-tags-TagsPage-RightSidebar-Content-_Content__preloader2{width:45%}.src-client-modules-tags-TagsPage-RightSidebar-Content-_Content__preloader3{width:65%}.src-client-modules-tags-TagsPage-RightSidebar-Content-_Content__prelaoder4{width:90%}\n", "",{"version":3,"sources":["webpack://./src/client/modules/tags/TagsPage/RightSidebar/Content/Content.scss"],"names":[],"mappings":"AAAA,sEACI,iBAAkB,CACrB,6EAGG,YAAa,CACb,qBAAsB,CACtB,YAAa,CAChB,4EAGG,SAAU,CAEV,iBAAkB,CACrB,4EAGG,SAAU,CACb,4EAGG,SAAU,CACb,4EAGG,SAAU","sourcesContent":[".root {\n    padding: 12px 24px;\n}\n\n.contentList {\n    display: flex;\n    flex-direction: column;\n    gap: 14px 0px;\n}\n\n.preloader1 {\n    width: 25%;\n\n    margin-bottom: 3px;\n}\n\n.preloader2 {\n    width: 45%;\n}\n\n.preloader3 {\n    width: 65%;\n}\n\n.prelaoder4 {\n    width: 90%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-tags-TagsPage-RightSidebar-Content-_Content__root",
	"contentList": "src-client-modules-tags-TagsPage-RightSidebar-Content-_Content__contentList",
	"preloader1": "src-client-modules-tags-TagsPage-RightSidebar-Content-_Content__preloader1",
	"preloader2": "src-client-modules-tags-TagsPage-RightSidebar-Content-_Content__preloader2",
	"preloader3": "src-client-modules-tags-TagsPage-RightSidebar-Content-_Content__preloader3",
	"prelaoder4": "src-client-modules-tags-TagsPage-RightSidebar-Content-_Content__prelaoder4"
};
export default ___CSS_LOADER_EXPORT___;
