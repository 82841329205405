import * as React from 'react';
import classNames from 'classnames';
import * as hash from 'object-hash';
import * as lodash from 'lodash';

import * as style from './ContentNav.scss';

const ITEM_HEIGHT = 20;
const ITEM_WITH_BUDGET_HEIGHT = 34;

const ITEM_MARGIN = 12;

export interface ContentNavProps {
    items: NavItem[];
    selectedIndex: number;
    onItemClick: (name: string) => void;
}

export interface NavItem {
    name: string;
    title: string;
    budget?: number;
}

export const ContentNav = ({ items, selectedIndex, onItemClick }: ContentNavProps): JSX.Element => {
    const thumbPosition = items.slice(0, selectedIndex).reduce((acc, item) => {
        let result = acc;
        result += item.budget == null ? ITEM_HEIGHT : ITEM_WITH_BUDGET_HEIGHT;
        result += ITEM_MARGIN;

        return result;
    }, 0);

    const thumbHeight =
        items[selectedIndex] && items[selectedIndex].budget == null ? ITEM_HEIGHT : ITEM_WITH_BUDGET_HEIGHT;

    return (
        items.length > 1 && (
            <div className={style.root}>
                {items.map((item, index) => (
                    <div
                        className={classNames(style.item, index == selectedIndex && style.selected)}
                        key={hash(item) + index}
                        onClick={() => onItemClick(item.name)}
                    >
                        {item.budget == null ? (
                            <div className={style.title}>{item.title}</div>
                        ) : (
                            <>
                                <div className={style.title}>{lodash.capitalize(item.title)}</div>

                                <div className={style.budget}>{lodash.round(item.budget / 100000000, 2)} млн</div>
                            </>
                        )}
                    </div>
                ))}

                <div className={style.thumb} style={{ top: thumbPosition, height: thumbHeight }} />
            </div>
        )
    );
};
