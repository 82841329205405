import * as React from 'react';
import classNames from 'classnames';

import { Checkbox_redesign as CheckboxUI } from 'sber-marketing-ui';
import * as styles from './styles.scss';

interface Props {
    selected: boolean;
    disabled: boolean;
    onSelect: (value: boolean) => void;
}

export const Checkbox: React.FC<Props> = ({ selected, disabled, onSelect }) => {
    return (
        <div
            className={classNames(styles.root, disabled ? styles.disabled : '')}
            onClick={disabled ? () => {} : () => onSelect(!selected)}
        >
            <div className={styles.checkbox}>
                <CheckboxUI isChecked={selected} disabled={disabled} />
            </div>
        </div>
    );
};
