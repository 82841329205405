import { v4 as uuid } from 'uuid';
import { ActivityBudget } from '@mrm/budget';

import { FormField, FieldType } from 'sber-marketing-ui';

interface Props {
    activityBudget: ActivityBudget;
    pageIsInCopyMode: boolean;
    onNameInputFocus: () => void;
    onNameInputBlur: () => void;
}

export function makeFormFields({
    activityBudget,
    pageIsInCopyMode,
    onNameInputFocus,
    onNameInputBlur,
}: Props): FormField[] {
    return (
        [
            {
                name: 'name',
                qaId: 'nameInput',
                type: FieldType.Input,
                title: 'Активность',
                value: activityBudget ? activityBudget.name : null,
                onFocus: onNameInputFocus,
                onBlur: onNameInputBlur,
            },
        ] as FormField[]
    ).map((item) => ({
        ...item,
        id: uuid(),
        errorMessage: '',
        disabled: activityBudget && !pageIsInCopyMode,
    }));
}
