import * as React from 'react';
import { useSelector } from 'react-redux';
import { Icon, IconType, WithTooltip, TooltipAnchor } from 'sber-marketing-ui';
import { every } from 'lodash';

import { StoreState } from '@store';
import {
    REQUIRED_COLUMN_NAMES_FULL_LIST,
    PLANNED_COLUMN_NAMES,
    ColumnData,
    ColumnName,
    getBudgetPlanningPageState,
} from '@store/budgetPlanning';

import { LayerManager } from '../../../../Table/LayerManager';
import { ColumnsList } from '../../../../ColumnsConfig';

import * as styles from './RequiredFieldsAreEmptyMarker.scss';

interface Props {
    lineId: string;
    focusField: (columnName: ColumnName) => void;
}

const emptyPlanFieldMes = 'Хотя бы одно поле "План, тыс. руб"';

interface EmptyRequiredFieldRes {
    emptyColumns: ColumnData[];
    emptyMonthValues: boolean;
}

function getEmptyRequiredFields(lineId: string): EmptyRequiredFieldRes {
    const layerManager = LayerManager.getInstance();
    const lineCellsParams = layerManager.getCellsParams()[lineId];

    const emptyMonthValues = !PLANNED_COLUMN_NAMES.some((planColumn) => lineCellsParams[planColumn].value);

    return {
        emptyMonthValues,
        emptyColumns: REQUIRED_COLUMN_NAMES_FULL_LIST.filter((columnName) => {
            if (lineCellsParams[columnName].options && !lineCellsParams[columnName].options.length) {
                return false;
            }

            return !lineCellsParams[columnName].value;
        }).map((columnName) => ColumnsList.find((column) => column.name === columnName)),
    };
}

export function RequiredFieldsAreEmptyMarker({ lineId, focusField }: Props): JSX.Element {
    const visibleColumns = useSelector(
        (state: StoreState) => getBudgetPlanningPageState(state).pageFilters.columnsVisiblityFilter,
    );
    const emptyRequiredFields = getEmptyRequiredFields(lineId);

    function focusFieldHandler() {
        let fieldToFocus: ColumnName;
        const focusPlannedField = !emptyRequiredFields.emptyColumns.length && emptyRequiredFields.emptyMonthValues;

        if (focusPlannedField) {
            fieldToFocus = PLANNED_COLUMN_NAMES.filter((column) => visibleColumns[column])[0];
        } else {
            const allColumnsAreVisible = every(visibleColumns, (column) => !column);

            fieldToFocus = (
                allColumnsAreVisible
                    ? emptyRequiredFields.emptyColumns
                    : emptyRequiredFields.emptyColumns.filter((column) => visibleColumns[column.name])
            ).map((column) => column.name)[0];
        }

        if (fieldToFocus) {
            focusField(fieldToFocus);
        }
    }

    return emptyRequiredFields.emptyColumns.length || emptyRequiredFields.emptyMonthValues ? (
        <WithTooltip anchor={TooltipAnchor.LEFT} content={<TooltipContent emptyRequiredFields={emptyRequiredFields} />}>
            <div
                {...{
                    'qa-id': 'budgetPlanningIndicatorsCellRequiredFieldsAreEmptyMarker',
                }}
            >
                <Icon onClick={focusFieldHandler} type={IconType.WARNING_IN_CIRCLE_V2} svgSize={24} />
            </div>
        </WithTooltip>
    ) : null;
}

interface TooltipContentProps {
    emptyRequiredFields: EmptyRequiredFieldRes;
}

function TooltipContent({ emptyRequiredFields }: TooltipContentProps): JSX.Element {
    const fields = [
        ...emptyRequiredFields.emptyColumns.map((column) => column.title),
        ...(emptyRequiredFields.emptyMonthValues ? [emptyPlanFieldMes] : []),
    ];
    const title = `Заполните обязательные поля${fields.length ? ':' : ''}`;

    return (
        <div className={styles.tooltipContent}>
            <span className={styles.tooltipContentBold}>{title}</span>

            <ul className={styles.tooltipContentList}>
                {fields.map((field) => (
                    <li key={field}>{field}</li>
                ))}
            </ul>
        </div>
    );
}
