import { actionCreatorFactory } from 'typescript-fsa';

import type { InitParams, InitResult, UpdateParams, UpdateResult, SetBudgetParams, SetBudgetResult } from '../types';

const actionCreator = actionCreatorFactory('CREATIVE_REFERENCE_ASYNC').async;

const init = actionCreator<InitParams, InitResult, Error>('INIT');
const update = actionCreator<UpdateParams, UpdateResult, Error>('UPDATE');
const save = actionCreator<void, void, Error>('SAVE');
const reset = actionCreator<void, void, Error>('RESET');
const setBudget = actionCreator<SetBudgetParams, SetBudgetResult, Error>('SET_BUDGET');

export { init, save, update, reset, setBudget };
