import * as React from 'react';

import { BudgetItem, Funds } from '@mrm/budget';
import { MONTHS } from '@store/plannedBudgetEdit/types';

import { SuggestTooltip, SuggestTooltipItem } from './SuggestTooltip';
import { Money, MoneyFormatter } from '@common/Utils';
import { BudgetItemApi } from '@api';

interface Props {
    activityBudgetId: string;
    budgetId: string;
}

interface State {
    loading: boolean;
}

export class SuggestTooltipContainer extends React.PureComponent<Props, State> {
    private budgetItems: BudgetItem[] = [];
    private isComponentMounted = false;

    constructor(props: Props) {
        super(props);

        this.state = {
            loading: true,
        };

        this.loadData();
    }

    public componentDidMount(): void {
        this.isComponentMounted = true;
    }

    public componentWillUnmount(): void {
        this.isComponentMounted = false;
    }

    public componentDidUpdate(prevProps: Props): void {
        const activityBudgetIdChanged = this.props.activityBudgetId !== prevProps.activityBudgetId;

        if (activityBudgetIdChanged) {
            this.setState({ loading: true });
            this.loadData();
        }
    }

    public render(): JSX.Element {
        return <SuggestTooltip loading={this.state.loading} items={this.makeItems()} />;
    }

    private makeItems(): SuggestTooltipItem[] {
        return this.budgetItems.map((item) => ({
            id: item.id,
            sapComment: item.sapComment,
            plan: this.formatFunds(item.plannedFunds),
        }));
    }

    private formatFunds(funds: Funds): string {
        const fundsSum = MONTHS.reduce((acc, item) => acc + funds[item], 0);

        const money = Money.fromCopecks(fundsSum);

        return MoneyFormatter.toRoubles(money);
    }

    private async loadData() {
        this.budgetItems = await this.loadBudgetItems();

        if (this.isComponentMounted) {
            this.setState({ loading: false });
        }
    }

    private async loadBudgetItems(): Promise<BudgetItem[]> {
        const { activityBudgetId, budgetId } = this.props;

        return await BudgetItemApi.getBudgetItemList({
            activityIds: [activityBudgetId],
            budgetId,
        });
    }
}
