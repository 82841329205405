import * as React from 'react';

import * as style from './BriefPage.scss';

import {
    OpacityTransition,
    ResponsiveLayout,
    LayoutTheme,
    LeftSidebar,
    CentralContent,
    RightSidebar,
} from 'sber-marketing-ui';
import { ContentNav } from './ContentNav';
import { Brief } from './Brief';
import { BriefType } from './enums';

const PAGE_PADDING_TOP = 40;

interface Props {
    briefsIds: string[];
    pageMode: BriefType;
    headerHeight: number;
    selectedSectionId: string;
    loading: boolean;
    onFinishPasteCopiedBrief: () => void;
    onStartFetchBriefSum: () => Promise<void>;
}

export const BriefPage = ({
    briefsIds,
    pageMode,
    headerHeight,
    selectedSectionId,
    loading,
    onFinishPasteCopiedBrief,
    onStartFetchBriefSum,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <OpacityTransition start={!loading}>
                <ResponsiveLayout headerHeight={headerHeight + PAGE_PADDING_TOP}>
                    <LeftSidebar fixed>
                        <div className={style.sidebar}>
                            <ContentNav selectedSectionId={selectedSectionId} />
                        </div>
                    </LeftSidebar>

                    <CentralContent>
                        {briefsIds.map((briefId) => (
                            <div key={briefId} className={style.briefWrapper}>
                                <Brief
                                    id={briefId}
                                    mode={pageMode}
                                    onFinishPasteCopiedBrief={onFinishPasteCopiedBrief}
                                    onStartFetchBriefSum={onStartFetchBriefSum}
                                />
                            </div>
                        ))}
                    </CentralContent>

                    <RightSidebar />
                </ResponsiveLayout>
            </OpacityTransition>
        </div>
    );
};
