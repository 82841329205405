import { actionCreatorFactory } from 'typescript-fsa';

import {
    PageState,
    FormData,
    BriefScheme,
    FieldChangeParams,
    FileToRemove,
    Brief,
    BriefLoading,
    BriefsData,
} from './types';

const actionCreator = actionCreatorFactory('PLANNED_BUDGET_EDIT_PAGE');

const loadEditBudgetPage = actionCreator<PageState>('LOAD_EDIT_BUDGET_PAGE');
const resetEditBudgetPage = actionCreator('RESET_EDIT_BUDGET_PAGE');
const removeMarkedFilesFromBrief = actionCreator('REMOVE_MARKED_FILES_FROM_BRIEF');
const setBudgetCardCollapseStatus = actionCreator<{ id: string; status: boolean }>('SET_BUDGET_CARD_COLLAPSE_STATUS1');
const setBudgetCardDeletedStatus = actionCreator<{ id: string; status: boolean }>('SET_BUDGET_CARD_DELETE_STATUS');
const addBudgetForm = actionCreator<FormData>('ADD_BUDGET_FORM');
const deleteBudgetForm = actionCreator<string>('DELETE_BUDGET_FORM');
const updateActivityForm = actionCreator<Partial<FormData>>('UPDATE_ACTIVITY_FORM');
const updateBudgetForm = actionCreator<FormData>('UPDATE_BUDGET_FORM');
const updateActivityFormValidation = actionCreator('UPDATE_ACTIVITY_FORM_VALIDATION');
const updateBudgetFormsValidation = actionCreator('UPDATE_BUDGET_FORMS_VALIDATION');
const setNameInputFocus = actionCreator<boolean>('SET_NAME_INPUT_FOCUS');
const setActivityInputFocus = actionCreator<{ budgetItemId: string; isFocused: boolean }>('SET_ACTIVITY_INPUT_FOCUS');
const setBudgetItemTransferDestination = actionCreator<{ budgetItemId: string; activityBudgetId: string }>(
    'SET_BUDGET_ITEM_TRANSFER_DESTINATION',
);
const setFieldValue = actionCreator<FieldChangeParams>('SET_FIELD_VALUE');
const setFileListToRemove = actionCreator<FileToRemove[]>('SET_FILE_LIST_TO_REMOVE');
const setBriefsData = actionCreator<BriefsData>('SET_BRIEF_DATA');
const setBrief = actionCreator<Brief>('SET_BRIEF');
const setChangedBrief = actionCreator<Brief>('SET_CHANGED_BRIEF');
const setBriefs = actionCreator('SET_BRIEFS');
const setBriefLoading = actionCreator<BriefLoading>('SET_BRIEF_LOADING');
const setBriefSchemes = actionCreator<BriefScheme[]>('SET_BRIEF_SCHEMES');
const setActivityAlreadyExists = actionCreator<boolean>('SET_ACTIVITY_ALREADY_EXISTS');

export {
    loadEditBudgetPage,
    resetEditBudgetPage,
    removeMarkedFilesFromBrief,
    setBudgetCardCollapseStatus,
    addBudgetForm,
    deleteBudgetForm,
    setBudgetCardDeletedStatus,
    updateActivityForm,
    updateBudgetForm,
    updateActivityFormValidation,
    updateBudgetFormsValidation,
    setNameInputFocus,
    setActivityInputFocus,
    setBudgetItemTransferDestination,
    setFieldValue,
    setFileListToRemove,
    setBrief,
    setChangedBrief,
    setBriefs,
    setBriefsData,
    setBriefLoading,
    setBriefSchemes,
    setActivityAlreadyExists,
};
