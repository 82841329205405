import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import { getFilterBy, DataFilterBy as By, Mode } from '@store/leaderDashboardPage';

import * as actions from '@store/leaderDashboardPage/thunks';

interface UseStore {
    (): UseStateReturn;
}

interface UseStateReturn extends Selected {
    setFilterBy: SetFilterBy;
}

interface Selected {
    selectedFilterBy: string;
}

interface SetFilterBy {
    (params: SetFilterByParams): void;
}

interface SetFilterByParams {
    by: By;
    mode: Mode;
}

export const useStore: UseStore = () => {
    const dispatch = useDispatch();
    const { selectedFilterBy } = useSelector<StoreState, Selected>((state) => {
        return {
            selectedFilterBy: getFilterBy(state),
        };
    });

    const setFilterBy: SetFilterBy = React.useCallback(({ by, mode }) => {
        dispatch(
            actions.setFilterBy({
                by,
                mode,
            }),
        );
    }, []);

    return {
        selectedFilterBy,
        setFilterBy,
    };
};

export { By };
