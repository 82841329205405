import * as React from 'react';
import classNames from 'classnames';

import { Correction, CorrectionType } from '../types';
import { Dictionary } from './withDictionary';
import { User } from './withUsers';
import { Icon, IconType, Skeleton } from 'sber-marketing-ui';
import {
    FactCorrectionCard,
    ReserveCorrectionCard,
    IncomeExternalCorrectionCard,
    OutcomeExternalCorrectionCard,
    PlanCorrectionCard,
    BudgetItemCorrectionCard,
    ActivityCorrectionCard,
} from './templates';

import * as style from './Corrections.scss';

interface Props {
    corrections: Correction[];
    users: User[];
    dictionaries: Dictionary[];
    isOpened: boolean;
    loading: boolean;
    onTitleClick: () => void;
    onHoverCorrectionCard: (correctionId: string) => void;
    onChangeCorrections: () => void;
}

export const Corrections: React.FC<Props> = ({
    corrections,
    users,
    dictionaries,
    isOpened,
    loading,
    onTitleClick,
    onHoverCorrectionCard,
    onChangeCorrections,
}) => {
    const correctionsCount = corrections.length;

    return !loading ? (
        <div
            className={style.root}
            {...{
                'qa-id': 'dashboardRightSidebarBudgetInfoCorrections',
            }}
        >
            <div
                className={style.title}
                onClick={onTitleClick}
                {...{
                    'qa-id': 'dashboardRightSidebarBudgetInfoCorrectionsTitle',
                }}
            >
                <div>Несогласованные корректировки</div>
                <div className={style.count}>{correctionsCount}</div>
                <div className={classNames(style.switchButton, isOpened && style.switchButtonUp)}>
                    <Icon type={IconType.ARROW16} svgSize={16} />
                </div>
            </div>

            {isOpened && (
                <CorrectionsList
                    corrections={corrections}
                    users={users}
                    dictionaries={dictionaries}
                    onHoverCard={onHoverCorrectionCard}
                    onChangeCorrections={onChangeCorrections}
                />
            )}
        </div>
    ) : (
        <Preloader />
    );
};

interface CorrectionsListParams {
    correction: Correction;
    dictionaries: Dictionary[];
    users: User[];
    onHoverCard: (correctionId: string) => void;
    onChangeCorrections: () => void;
}

type CorrectionTemplateMapByType = {
    [P in CorrectionType]: (params: CorrectionsListParams) => JSX.Element;
};

const correctionTemplateMapByType: CorrectionTemplateMapByType = {
    [CorrectionType.FactFunds]: ({ correction, onHoverCard, onChangeCorrections }) => (
        <FactCorrectionCard correction={correction} onHover={onHoverCard} onChange={onChangeCorrections} />
    ),
    [CorrectionType.ReservedFunds]: ({ correction, onHoverCard, onChangeCorrections }) => (
        <ReserveCorrectionCard correction={correction} onHover={onHoverCard} onChange={onChangeCorrections} />
    ),
    [CorrectionType.PlanFundsTransfer]: ({ correction, onHoverCard, onChangeCorrections }) => (
        <PlanCorrectionCard correction={correction} onHover={onHoverCard} onChange={onChangeCorrections} />
    ),
    [CorrectionType.BudgetItem]: ({ correction, dictionaries, users, onHoverCard, onChangeCorrections }) => (
        <BudgetItemCorrectionCard
            dictionaries={dictionaries}
            correction={correction}
            users={users}
            onHover={onHoverCard}
            onChange={onChangeCorrections}
        />
    ),
    [CorrectionType.Activity]: ({ correction, onHoverCard, onChangeCorrections }) => (
        <ActivityCorrectionCard correction={correction} onHover={onHoverCard} onChange={onChangeCorrections} />
    ),
    [CorrectionType.IncomeExternalPlanFundsTransfer]: ({
        correction,
        dictionaries,
        onHoverCard,
        onChangeCorrections,
    }) => (
        <IncomeExternalCorrectionCard
            correction={correction}
            dictionaries={dictionaries}
            onHover={onHoverCard}
            onChange={onChangeCorrections}
        />
    ),
    [CorrectionType.OutcomeExternalPlanFundsTransfer]: ({
        correction,
        dictionaries,
        onHoverCard,
        onChangeCorrections,
    }) => (
        <OutcomeExternalCorrectionCard
            correction={correction}
            dictionaries={dictionaries}
            onHover={onHoverCard}
            onChange={onChangeCorrections}
        />
    ),
};

const CorrectionsList: React.FC<{
    corrections: Correction[];
    dictionaries: Dictionary[];
    users: User[];
    onHoverCard: (correctionId: string) => void;
    onChangeCorrections: () => void;
}> = ({ corrections, dictionaries, users, onHoverCard, onChangeCorrections }) => {
    return (
        <div
            className={style.corrections}
            {...{
                'qa-id': 'dashboardRightSidebarBudgetInfoCorrectionsList',
            }}
        >
            {corrections.map((correction) => {
                return (
                    <div className={style.correctionCardWrapper} key={correction.serialNumber}>
                        {correctionTemplateMapByType[correction.type]({
                            correction,
                            dictionaries,
                            users,
                            onHoverCard,
                            onChangeCorrections,
                        })}
                    </div>
                );
            })}
        </div>
    );
};

const Preloader: React.FC = () => (
    <div className={style.preloader}>
        <Skeleton
            customStyle={{
                height: 20,
                width: '40%',
                borderRadius: 2,
            }}
        />

        <div className={style.preloaderCorrectionCard}>
            <Skeleton
                customStyle={{
                    height: 20,
                    width: '32%',
                    borderRadius: 2,
                    position: 'absolute',
                    top: 5,
                    left: 16,
                }}
            />
            <Skeleton
                customStyle={{
                    height: 16,
                    width: '64%',
                    borderRadius: 2,
                    position: 'absolute',
                    top: 29,
                    left: 16,
                }}
            />
        </div>
    </div>
);
