import * as React from 'react';
import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import { InternalTransferDirection, getBudgetTransferMenuState } from '@store/budgetExecution/budgetTransferMenu';

import * as styles from './Title.scss';

interface Props {
    text: string;
}

function Title({ text }: Props): JSX.Element {
    return <div className={styles.root}>{text}</div>;
}

export function SourceTitle(): JSX.Element {
    const sigleFromRow = useSelector(
        (state: StoreState) =>
            getBudgetTransferMenuState(state).controls.internalTransferDirection ===
            InternalTransferDirection.OneToMany,
    );

    return <Title text={sigleFromRow ? 'Строка-источник' : 'Строки-источники'} />;
}

export function DestTitle(): JSX.Element {
    const sigleToRow = useSelector(
        (state: StoreState) =>
            getBudgetTransferMenuState(state).controls.internalTransferDirection ===
            InternalTransferDirection.ManyToOne,
    );

    return <Title text={sigleToRow ? 'Строка-получатель' : 'Строки-получатели'} />;
}
