import { PdfPagesCanvasMaker } from '../../Chart/modules';
import { CanvasScaler } from './CanvasScaler';
import { PdfBuilder } from './PdfBuilder';

export class ChartPdfMaker {
    private pageCanvases: CanvasScaler[];

    private pdfPageCanvasMaker: PdfPagesCanvasMaker;
    private pdfBuilder: PdfBuilder;

    constructor() {
        this.pdfBuilder = new PdfBuilder();
    }

    public async upload(): Promise<void> {
        await this.init();
        await this.buildPdf();
        this.save();
    }

    private async init(): Promise<void> {
        this.initPdfSceneDrawer();
        await this.initGroupsCanvas();
    }

    private initPdfSceneDrawer(): void {
        this.pdfPageCanvasMaker = new PdfPagesCanvasMaker();
    }

    private async buildPdf(): Promise<void> {
        this.addPagesToPdf();
    }

    private async initGroupsCanvas(): Promise<void> {
        const canvases = await this.pdfPageCanvasMaker.getCanvases();

        this.pageCanvases = canvases.map((canvas) => {
            return new CanvasScaler(canvas, 1);
        });
    }

    private addPagesToPdf(): void {
        this.pageCanvases.forEach((pageCanvas) => {
            this.addPageToPdf(pageCanvas);
        });
    }

    private addPageToPdf(canvas: CanvasScaler) {
        this.pdfBuilder.addPage({
            sidesSizes: {
                width: canvas.width,
                height: canvas.height,
            },
        });

        this.pdfBuilder.addCanvas({
            canvas: canvas.getCanvas(),
            position: {
                x: 0,
                y: 0,
            },
            sidesSizes: {
                width: canvas.width,
                height: canvas.height,
            },
        });
    }

    private save(): void {
        this.pdfBuilder.save('Календарь');
    }
}
