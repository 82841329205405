// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-release_notes-ReleaseNotesPopup-_ReleaseNotesPopup__root{position:absolute;bottom:-50vh;left:calc(-50vw + 60px);width:350px;height:500px;border-radius:3px;background-color:white;font-family:'Open Sans';font-size:11px}.src-client-modules-release_notes-ReleaseNotesPopup-_ReleaseNotesPopup__messageWrapper{padding:0 0 40px 0;border-top:1px solid #dbdbdb}.src-client-modules-release_notes-ReleaseNotesPopup-_ReleaseNotesPopup__message{padding:24px}.src-client-modules-release_notes-ReleaseNotesPopup-_ReleaseNotesPopup__date{margin:24px 0 16px 20px;letter-spacing:0.5px;font-size:14px}.src-client-modules-release_notes-ReleaseNotesPopup-_ReleaseNotesPopup__closeButton{position:absolute;top:12px;right:12px;cursor:pointer;opacity:0.5}\n", "",{"version":3,"sources":["webpack://./src/client/modules/release_notes/ReleaseNotesPopup/ReleaseNotesPopup.scss"],"names":[],"mappings":"AAAA,6EACI,iBAAkB,CAClB,YAAa,CACb,uBAAwB,CAExB,WAAY,CACZ,YAAa,CAEb,iBAAkB,CAClB,sBAAuB,CAEvB,uBAAwB,CACxB,cAAe,CAClB,uFAGG,kBAAmB,CAEnB,4BAA6B,CAChC,gFAGG,YAAa,CAChB,6EAGG,uBAAwB,CAExB,oBAAqB,CAErB,cAAe,CAClB,oFAGG,iBAAkB,CAClB,QAAS,CACT,UAAW,CAEX,cAAe,CAEf,WAAY","sourcesContent":[".root {\n    position: absolute;\n    bottom: -50vh;\n    left: calc(-50vw + 60px);\n\n    width: 350px;\n    height: 500px;\n\n    border-radius: 3px;\n    background-color: white;\n\n    font-family: 'Open Sans';\n    font-size: 11px;\n}\n\n.messageWrapper {\n    padding: 0 0 40px 0;\n\n    border-top: 1px solid #dbdbdb;\n}\n\n.message {\n    padding: 24px;\n}\n\n.date {\n    margin: 24px 0 16px 20px;\n\n    letter-spacing: 0.5px;\n\n    font-size: 14px;\n}\n\n.closeButton {\n    position: absolute;\n    top: 12px;\n    right: 12px;\n\n    cursor: pointer;\n\n    opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-release_notes-ReleaseNotesPopup-_ReleaseNotesPopup__root",
	"messageWrapper": "src-client-modules-release_notes-ReleaseNotesPopup-_ReleaseNotesPopup__messageWrapper",
	"message": "src-client-modules-release_notes-ReleaseNotesPopup-_ReleaseNotesPopup__message",
	"date": "src-client-modules-release_notes-ReleaseNotesPopup-_ReleaseNotesPopup__date",
	"closeButton": "src-client-modules-release_notes-ReleaseNotesPopup-_ReleaseNotesPopup__closeButton"
};
export default ___CSS_LOADER_EXPORT___;
