import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as queryString from 'query-string';

import { QueryParams } from './types';

interface Props extends RouteComponentProps {}

export const withQueryParams = (WrappedComponent: any) =>
    withRouter(
        // @ts-ignore
        class extends React.Component<Props> {
            constructor(props: Props) {
                super(props);

                this.removeQueryParamsInUrl = this.removeQueryParamsInUrl.bind(this);
            }
            public render() {
                const { activityIdToLink, budgetItemIdToLink } = this.getQueryParamsFromURL();

                return React.createElement(WrappedComponent, {
                    ...this.props,
                    activityIdToLink,
                    budgetItemIdToLink,
                    removeQueryParamsInUrl: this.removeQueryParamsInUrl,
                });
            }

            private getQueryParamsFromURL(): QueryParams {
                const {
                    location: { search },
                } = this.props;
                const query = queryString.parse(search);

                return {
                    activityIdToLink: (query.activityIdToLink as string) || null,
                    budgetItemIdToLink: (query.budgetItemIdToLink as string) || null,
                };
            }

            private removeQueryParamsInUrl(): void {
                const { history } = this.props;
                history.push('/budget/execution');
            }
        },
    );
