import * as React from 'react';

import { ColumnName } from '@store/budgetPlanning';
import { WithStore } from './WithStore';
import { ItemBehavior } from './ItemBehavior';

export interface Props {
    columnName: ColumnName;
    isExpanded: boolean;
    onExpandChange: (isExpanded: boolean) => void;
}

export const ItemConnector: React.FC<Props> = ({ columnName, isExpanded, onExpandChange }) => {
    return (
        <WithStore columnName={columnName}>
            {({
                dictionaryType,
                users,
                dictionaries,
                filters,
                filterLoadingStatus,
                loadFilters,
                loadFiltersByColumn,
                setFilters,
            }) => (
                <ItemBehavior
                    columnName={columnName}
                    filters={filters}
                    filterLoadingStatus={filterLoadingStatus}
                    users={users}
                    dictionaryType={dictionaryType}
                    dictionaries={dictionaries}
                    isExpanded={isExpanded}
                    loadFiltersByColumn={loadFiltersByColumn}
                    loadFilters={loadFilters}
                    setFilters={setFilters}
                    onExpandChange={onExpandChange}
                />
            )}
        </WithStore>
    );
};
