import * as React from 'react';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { compact, values, Dictionary } from 'lodash';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { MultiReferenceDictionaryApi } from '@api';

import { BudgetList } from './BudgetList';
import { StoreState } from '@store';
import { isRequestInProgress } from '@store/common/selectors';
import { FormData, Brief } from '@store/plannedBudgetEdit/types';
import { getUserOrganizationId } from '@store/user/selector';
import { getSortedByDeletedBudgetItemForms } from '@store/plannedBudgetEdit/selectors';
import { UserResponseParams } from 'sber-marketing-types/backend';

import { BriefsLoader } from './modules';

interface Props extends Partial<MapProps>, Partial<RouteComponentProps<RouteParams>> {
    budgetId: string;
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi;
}

interface MapProps {
    userOrganizationId: string;
    users: UserResponseParams[];
    briefs: Dictionary<Brief>;
    budgetItemForms: FormData[];
    isRequestInProgress: boolean;
}

interface RouteParams {
    activityBudgetId: string;
    budgetId: string;
    action: string;
}

@(withRouter as any)
@(connect(mapStateToProps, null) as any)
export class BudgetListContainer extends React.PureComponent<Props> {
    private briefsLoader: BriefsLoader;

    constructor(props: Props) {
        super(props);

        const { action } = props.match.params;
        const pageIsInCopyMode = action == 'copy';

        this.briefsLoader = BriefsLoader.getInstance({
            isCopyMode: pageIsInCopyMode,
            organizationId: props.userOrganizationId,
            briefsIds: compact(props.budgetItemForms.map((budgetItemForm) => budgetItemForm.briefId)),
            budgetItemForms: props.budgetItemForms,
        });
    }

    public async componentDidMount() {
        this.briefsLoader.init();
    }

    public render(): JSX.Element {
        const { budgetItemForms, budgetId, multiReferenceDictionaryApi } = this.props;
        const { activityBudgetId } = this.props.match.params;

        return React.createElement(BudgetList, {
            forms: budgetItemForms,
            activityBudgetId,
            budgetId,
            multiReferenceDictionaryApi,
            onFinishPasteCopiedBrief: this.onFinishPasteCopiedBrief,
        });
    }

    @autobind
    private async onFinishPasteCopiedBrief(): Promise<void> {
        await this.briefsLoader.updateSchemes({ briefs: values(this.props.briefs) });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { users, changedBriefs } = state.plannedBudgetEditPage;

    return {
        userOrganizationId: getUserOrganizationId(state),
        users,
        briefs: changedBriefs,
        budgetItemForms: getSortedByDeletedBudgetItemForms(state),
        isRequestInProgress: isRequestInProgress(state),
    };
}
