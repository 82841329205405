// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-EditExecutionBudgetPage-BudgetList-BudgetForm-_BudgetForm__root{width:100%}.src-client-modules-budget-EditExecutionBudgetPage-BudgetList-BudgetForm-_BudgetForm__tagsEditor{padding:10px 0;width:100%}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/EditExecutionBudgetPage/BudgetList/BudgetForm/BudgetForm.scss"],"names":[],"mappings":"AAAA,2FACI,UAAW,CACd,iGAGG,cAAe,CAEf,UAAW","sourcesContent":[".root {\n    width: 100%;\n}\n\n.tagsEditor {\n    padding: 10px 0;\n\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-EditExecutionBudgetPage-BudgetList-BudgetForm-_BudgetForm__root",
	"tagsEditor": "src-client-modules-budget-EditExecutionBudgetPage-BudgetList-BudgetForm-_BudgetForm__tagsEditor"
};
export default ___CSS_LOADER_EXPORT___;
