import * as React from 'react';
import * as style from './CardContent.scss';

export const CardContentLeftSidebar: React.FC = ({ children }) => {
    return (
        <div className={style.leftSidebar}>
            {React.Children.map(children, (child) => (
                <div>{child}</div>
            ))}
        </div>
    );
};

export const CardContentRightSidebar: React.FC = ({ children }) => {
    return (
        <div className={style.rightSidebar}>
            {React.Children.map(children, (child) => (
                <div className={style.rightSidebarItemWrapper}>{child}</div>
            ))}
        </div>
    );
};

export const CardContent: React.FC = ({ children }) => {
    return <div className={style.root}>{children}</div>;
};
