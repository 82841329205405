import * as React from 'react';
import classNames from 'classnames';

import { TagColorType } from '@mrm/tags';

import { Icon, IconType } from 'sber-marketing-ui';

import { useAddTagMutation, useGetTagsDictionaryQuery, useGetTaskQuery, useSetTaskTagsMutation } from '@api';

import { useDefaultRef, useDefaultState } from '@common/hooks';

import { Details, Flex, Modal, OptionMultiple, Tag } from '@common/components';
import { TagSelector, TagSelectorProps, TagSettings, TagSettingsSubmit } from '@modules/tags/components';

import * as styles from './AddTaskTagSelector.scss';

export interface AddTaskTagSelectorProps<M extends OptionMultiple> extends TagSelectorProps<M> {
    taskId: string;
    isDropdownShow?: boolean;
    onShowChangeDropdown?: (show: boolean) => void;
}

export function AddTaskTagSelector<M extends OptionMultiple>({
    taskId,
    className,
    children,
    isDropdownShow,
    onShowChangeDropdown,
    inputRef: inputRefProp,
    search: searchProp,
    onSearch,
    ...props
}: AddTaskTagSelectorProps<M>) {
    const [addTag, { isLoading: isAddingTag }] = useAddTagMutation();
    const [data, setData] = React.useState<TagSettingsSubmit>(null);
    const [name, setName] = React.useState('');
    const inputRef = useDefaultRef(inputRefProp);
    const [search, setSearch] = useDefaultState(searchProp, onSearch, '');
    const [color, setColor] = React.useState<TagColorType>(null);
    const [picture, setPicture] = React.useState<string>(null);
    const { data: { task } = {}, isLoading } = useGetTaskQuery({ id: taskId });
    const { data: tags } = useGetTagsDictionaryQuery();
    const [setTaskTags] = useSetTaskTagsMutation();
    const hasSearchTag = tags?.find(({ title }) => title === search);
    const isShowNewTag = search && !hasSearchTag;

    const taskTags = task?.tags || [];

    const reset = () => {
        setColor(null);
        setPicture(null);
    };

    React.useEffect(reset, [isShowNewTag]);

    const handleSelect = (tagId: string) => {
        setTaskTags({
            id: taskId,
            tags: Array.from(new Set([...task.tags, tagId])),
        });
        inputRef.current?.focus();
    };

    const handleShowDropdownChange: typeof onShowChangeDropdown = (show) => {
        if (!show) {
            reset();
            setSearch('');
        }

        onShowChangeDropdown?.(show);
    };

    const clearData = () => {
        setData(null);
        setName('');
        setSearch('');
    };

    const handleAdd: typeof setData = (data) => {
        setData(data);
        setName(search);
    };

    const handleSubmit = async () => {
        const result = await addTag({
            name,
            ...data,
        });

        if ('data' in result) {
            handleSelect(result.data.id);
        }

        clearData();
    };

    return (
        <>
            <TagSelector
                data-qa-id="AddTaskTagSelector"
                {...props}
                iconBefore={picture ? (picture as any) : undefined}
                color={color}
                optionsProps={{
                    searchFallback: hasSearchTag ? 'Такой тэг уже добавлен' : 'Тегов не найдено',
                }}
                onShowChangeDropdown={handleShowDropdownChange}
                preventDefaultSelect
                search={search}
                onSearch={setSearch}
                inputRef={inputRef}
                loading={isLoading}
                className={classNames(styles.root, className)}
                onSelect={handleSelect}
                exact
                searchable
                keepDropdownOnSelect
                exclude={taskTags}
            >
                {isShowNewTag && (
                    <Details className={styles.newTagDetails} summaryClassName={styles.newTagSummary} summary="Новый">
                        <TagSettings
                            color={color}
                            picture={picture as any}
                            name={search}
                            onChangePicture={setPicture}
                            onChangeColor={setColor}
                            onSubmit={handleAdd}
                        >
                            Добавить
                        </TagSettings>
                    </Details>
                )}
            </TagSelector>
            <Modal
                title="Внимание!"
                hide={!data}
                buttons={[
                    { key: 'cancel', disabled: isAddingTag, children: 'Отмена', onClick: clearData },
                    {
                        key: 'confirm',
                        loading: isAddingTag,
                        view: 'primary',
                        children: 'Добавить',
                        onClick: handleSubmit,
                    },
                ]}
            >
                После создания тега, его нельзя будет удалить.
                <br />
                Проверьте корректность:
                <Flex className={styles.tagControl}>
                    <Tag color={data?.color}>
                        {data?.picture && <Icon type={data.picture as IconType} />}
                        {name}
                    </Tag>
                </Flex>
            </Modal>
        </>
    );
}
