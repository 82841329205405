import type { LinesGroup, Position } from '@store/calendar/types';
import type { SidesSizes } from '../../types';

import { SceneDrawer } from './SceneDrawer';

export interface ConstructorParams {
    canvas: HTMLCanvasElement;
    group: LinesGroup;
    startPosition: Position;
    sidesSizes: SidesSizes;
    filledExpiredTaskIcon: HTMLImageElement;
    finishedTaskIcon: HTMLImageElement;
}

export class ChartCanvasDrawer {
    private canvas: HTMLCanvasElement;
    private group: LinesGroup;
    private startPosition: Position;
    private sidesSizes: SidesSizes;
    private sceneDrawer: SceneDrawer;

    constructor({
        canvas,
        group,
        startPosition,
        sidesSizes,
        filledExpiredTaskIcon,
        finishedTaskIcon,
    }: ConstructorParams) {
        this.canvas = canvas;
        this.group = group;
        this.startPosition = startPosition;
        this.sidesSizes = sidesSizes;

        this.sceneDrawer = new SceneDrawer({
            group: this.group,
            sidesSizes: this.sidesSizes,
            filledExpiredTaskIcon,
            finishedTaskIcon,
        });
    }

    public draw(): void {
        const chartCanvas = this.sceneDrawer.getCanvas();
        const ctx = this.canvas.getContext('2d');

        ctx.save();
        ctx.drawImage(chartCanvas, this.startPosition.x, this.startPosition.y);
        ctx.restore();
    }
}
