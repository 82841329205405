import * as React from 'react';

import { Correction, Mode, CardBudgetItem } from './types';

import { BudgetWindow } from './BudgetWindow';

interface Props {
    budgetItem: CardBudgetItem;
    corrections: Correction[];
    selectedCorrectionId: string;
    parentRef: React.RefObject<HTMLDivElement>;
    onCloseButtonClick: () => void;
    mode: Mode;
}

export class BudgetWindowContainer extends React.Component<Props> {
    public render() {
        const { onCloseButtonClick, parentRef, mode, budgetItem, corrections, selectedCorrectionId } = this.props;

        return React.createElement(BudgetWindow, {
            mode,
            selectedCorrectionId,
            parentRef,
            budgetItem,
            corrections,
            onCloseButtonClick,
        });
    }
}
