import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';

import {
    PageState,
    PageData,
    SelectedCard,
    UserRoleFilter,
    UserRole,
    CardType,
    CardsLoadingMode,
    CardsLoadingModeFilter,
    RightSidebarState,
} from './types';

import * as actions from './actions';

const initialState: PageState = {
    pageData: {
        mentionableUsers: [],
    },
    selectedCard: null,
    userRoleFilter: {
        [UserRole.Author]: false,
        [UserRole.Participant]: false,
        [UserRole.Responsible]: false,
        [UserRole.Supervisor]: false,
    },
    cardsLoadingModeFilter: {
        [CardType.BudgetItemToProjectLinkRequest]: CardsLoadingMode.Current,
    },
    rightSidebarStates: {
        [CardType.BudgetItemToProjectLinkRequest]: {
            requestIdForStatusCheck: null,
            cardIdRequestInProgress: null,
        },
    },
};

class Reducer {
    public static loadPageData(state: PageState, payload: PageData): PageState {
        return { ...state, pageData: { ...state.pageData, ...payload } };
    }

    public static resetPageStore(): PageState {
        return lodash.cloneDeep(initialState);
    }

    public static setSelectedCard(state: PageState, payload: SelectedCard): PageState {
        return { ...state, selectedCard: payload };
    }

    public static setUserRoleFilter(state: PageState, payload: UserRoleFilter): PageState {
        return { ...state, userRoleFilter: { ...state.userRoleFilter, ...payload } };
    }

    public static setCardsLoadingModeFilter(state: PageState, payload: CardsLoadingModeFilter): PageState {
        return { ...state, cardsLoadingModeFilter: { ...state.cardsLoadingModeFilter, ...payload } };
    }

    public static setRequestIdForStatusCheck(state: PageState, requestIdForStatusCheck: string): PageState {
        return Reducer.rightSidebarStateReducer(
            state,
            CardType.BudgetItemToProjectLinkRequest,
            (rightSidebarState) => ({
                ...rightSidebarState,
                requestIdForStatusCheck,
            }),
        );
    }

    public static setCardIdRequestInProgress(state: PageState, cardIdRequestInProgress: string): PageState {
        return Reducer.rightSidebarStateReducer(
            state,
            CardType.BudgetItemToProjectLinkRequest,
            (rightSidebarState) => ({
                ...rightSidebarState,
                cardIdRequestInProgress,
            }),
        );
    }

    private static rightSidebarStateReducer<T extends CardType>(
        state: PageState,
        cardType: T,
        reducer: (state: RightSidebarState[T]) => RightSidebarState[T],
    ): PageState {
        return {
            ...state,
            rightSidebarStates: {
                ...state.rightSidebarStates,
                [cardType]: reducer(state.rightSidebarStates[cardType]),
            },
        };
    }
}

export const dashboardPageReducer = reducerWithInitialState(initialState)
    .case(actions.loadPageData, Reducer.loadPageData)
    .case(actions.resetPageStore, Reducer.resetPageStore)
    .case(actions.setSelectedCard, Reducer.setSelectedCard)
    .case(actions.setUserRoleFilter, Reducer.setUserRoleFilter)
    .case(actions.setCardsLoadingModeFilter, Reducer.setCardsLoadingModeFilter)
    .case(actions.setRequestIdForStatusCheck, Reducer.setRequestIdForStatusCheck)
    .case(actions.setCardIdRequestInProgress, Reducer.setCardIdRequestInProgress);
