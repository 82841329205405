import { v4 as isUuidV4 } from 'is-uuid';
import { v4 as uuidV4 } from 'uuid';

import type {
    TaskPageState,
    EditCommentaryPayload,
    RemoveCommentaryAssetPayload,
    SetCommentaryIsLoadingPayload,
    Commentary,
} from '../types';
import { CommentEditionType } from '../types';
import { NEW_COMMENTARY } from '../constants';
import type { FileAsset } from '../../commonTypes';

export const addNewCommentaryAssetReducer = (
    { newCommentary: { assetsIds, ...restNewCommentary }, ...restState }: TaskPageState,
    asset: FileAsset,
): TaskPageState => ({
    ...restState,
    newCommentary: {
        ...restNewCommentary,
        assetsIds: [asset.name].concat(assetsIds),
    },
});

export const removeNewCommentaryAssetReducer = (
    { newCommentary: { assetsIds, ...restNewCommentary }, ...restState }: TaskPageState,
    id: string,
): TaskPageState => ({
    ...restState,
    newCommentary: {
        ...restNewCommentary,
        assetsIds: assetsIds.filter((assetId) => assetId !== id),
    },
});

const updateContent = (oldContent: string, newContent: string, editionType: CommentEditionType): string => {
    let result: string;
    switch (editionType) {
        case CommentEditionType.ADD_MENTION: {
            result = addMention(oldContent, newContent);
            break;
        }
        case CommentEditionType.APPEND: {
            result = `${oldContent}${newContent}`;
            break;
        }
        case CommentEditionType.PREPEND: {
            result = `${newContent}${oldContent}`;
            break;
        }
        case CommentEditionType.REPLACE:
        default: {
            result = newContent;
        }
    }
    return result;
};

const editNewCommentaryReducer = (
    { newCommentary: { content: oldContent, ...restNewCommentary }, ...restState }: TaskPageState,
    { content, editionType }: EditCommentaryPayload,
): TaskPageState => ({
    ...restState,
    newCommentary: {
        content: updateContent(oldContent, content, editionType),
        ...restNewCommentary,
    },
});

const editExistCommentaryReducer = (
    { comments, editingComment, ...restState }: TaskPageState,
    { content, editionType }: EditCommentaryPayload,
): TaskPageState => {
    const id = typeof editingComment === 'symbol' ? (editingComment as unknown as string) : String(editingComment?.id);

    return {
        ...restState,
        editingComment,
        comments: {
            ...comments,
            [id]: {
                ...comments[id],
                content: updateContent(comments[id].content, content, editionType),
            },
        },
    };
};

export const editCommentaryReducer = (state: TaskPageState, payload: EditCommentaryPayload): TaskPageState => {
    return state.editingComment === NEW_COMMENTARY
        ? editNewCommentaryReducer(state, payload)
        : isUuidV4(String(typeof state.editingComment === 'symbol' ? state.editingComment : state.editingComment?.id))
        ? editExistCommentaryReducer(state, payload)
        : state;
};

export const setEditingCommentReducer = (
    state: TaskPageState,
    editingComment: Commentary | symbol | null,
): TaskPageState => ({
    ...state,
    editingComment,
});

export const clearComments = (state: TaskPageState): TaskPageState => ({
    ...state,
    comments: {},
    newCommentary: {
        id: uuidV4(),
        isLoading: false,
        content: '',
        authorId: -1,
        assetsIds: [],
        createdAt: Date.now(),
        reactions: [],
        replyId: null,
    },
});

function addMention(comment: string, newMention: string): string {
    const mentionSearchRegex = /(\[@[^[\]#]+#\d+])/g;

    const currentMentions = comment.match(mentionSearchRegex);

    const mentionAlreadyExists = currentMentions ? currentMentions.some((item) => item == newMention) : false;

    return mentionAlreadyExists ? comment : `${comment}${newMention}`;
}

export const addCommentaryAssetReducer = (
    { comments, ...restState }: TaskPageState,
    asset: FileAsset,
): TaskPageState => {
    const { id, assetsIds, ...restComment } = comments[asset.targetId];
    return {
        ...restState,
        comments: {
            ...comments,
            [id]: {
                ...restComment,
                id,
                assetsIds: assetsIds.concat([asset.name]),
            },
        },
    };
};

export const removeCommentaryAssetReducer = (
    { comments, ...restState }: TaskPageState,
    { commentaryId, fileName }: RemoveCommentaryAssetPayload,
): TaskPageState => {
    const { assetsIds, id, ...restCommentary } = comments[commentaryId];
    return {
        ...restState,
        comments: {
            ...comments,
            [commentaryId]: {
                ...restCommentary,
                id,
                assetsIds: assetsIds.filter((name) => name !== fileName),
            },
        },
    };
};

export const setCommentaryIsLoadingReducer = (
    { comments, ...restState }: TaskPageState,
    { commentaryId, isLoading }: SetCommentaryIsLoadingPayload,
): TaskPageState => ({
    ...restState,
    comments: {
        ...comments,
        [commentaryId]: {
            ...comments[commentaryId],
            isLoading,
        },
    },
});
