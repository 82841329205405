import * as React from 'react';
import * as lodash from 'lodash';
import * as moment from 'moment';
import autobind from 'autobind-decorator';

import { MultiselectDatepickerTemplate, SelectionType, DatePeriod } from './MultiselectDatepickerTemplate';

interface Props {
    dates: Date[];
    minDate?: Date;
    maxDate?: Date;
    readOnly?: boolean;
    onDatesChange: (dates: Date[]) => void;
}

interface State {
    isOpened: boolean;
    selectionType: SelectionType;
    selectedDates: Date[];
    periodStart: Date;
}

export class MultiselectDatepickerBehaviour extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isOpened: false,
            selectionType: SelectionType.Date,
            selectedDates: this.limitDates(
                props.dates.map((item) => this.removeHoursFromDate(item)),
                this.removeHoursFromDate(props.minDate),
                this.removeHoursFromDate(props.maxDate),
            ),
            periodStart: null,
        };
    }

    public componentDidUpdate(prevProps: Props) {
        const datesChanged = this.props.dates !== prevProps.dates;
        const minDateChanged = !this.compateDates(this.props.minDate, prevProps.minDate);
        const maxDateChanged = !this.compateDates(this.props.maxDate, prevProps.maxDate);

        if (datesChanged || minDateChanged || maxDateChanged) {
            const updatedDates = this.limitDates(
                this.props.dates.map((item) => this.removeHoursFromDate(item)),
                this.removeHoursFromDate(this.props.minDate),
                this.removeHoursFromDate(this.props.maxDate),
            );

            const selectedDatesChanged = !this.compareDateLists(updatedDates, this.state.selectedDates);

            if (selectedDatesChanged) {
                this.setState(
                    {
                        selectedDates: updatedDates,
                    },
                    () => {
                        if (!datesChanged) {
                            this.props.onDatesChange(updatedDates);
                        }
                    },
                );
            }
        }
    }

    public render(): JSX.Element {
        return React.createElement(MultiselectDatepickerTemplate, {
            readOnly: this.props.readOnly,
            isOpened: this.state.isOpened,
            selectionType: this.state.selectionType,
            selectedDates: this.state.selectedDates,
            selectedPeriods: this.makePeriods(),
            periodStart: this.state.periodStart,
            minDate: this.removeHoursFromDate(this.props.minDate),
            maxDate: this.removeHoursFromDate(this.props.maxDate),
            onOpenerClick: this.onOpenerClick,
            onSelect: this.onSelect,
            onSelectionTypeChange: this.onSelectionTypeChange,
            onDeleteButtonClick: this.onDeleteButtonClick,
        });
    }

    @autobind
    protected onOpenerClick(): void {
        this.setState({
            isOpened: !this.state.isOpened,
        });
    }

    @autobind
    protected onSelect(value: Date | [Date, Date]): void {
        const { selectionType } = this.state;

        if (selectionType === SelectionType.Date) {
            this.onDatesSelection([value as Date]);
        }

        if (selectionType === SelectionType.Period) {
            const [startDate, endDate] = value as [Date, Date];

            if (!this.state.periodStart && !endDate) {
                this.setState({
                    periodStart: startDate,
                });
            }

            if (this.state.periodStart && endDate && !this.compateDates(endDate, this.state.periodStart)) {
                this.setState({
                    periodStart: null,
                });

                const periodDates = this.getPeriodDates(startDate, endDate);

                this.onDatesSelection(periodDates);
            }

            if (endDate && this.compateDates(endDate, this.state.periodStart)) {
                this.setState({
                    periodStart: null,
                });
            }
        }
    }

    @autobind
    protected onSelectionTypeChange(type: SelectionType): void {
        if (type !== this.state.selectionType) {
            this.setState({
                selectionType: type,
                periodStart: null,
            });
        }
    }

    @autobind
    protected onDeleteButtonClick(dates: Date[]): void {
        this.onDatesSelection(dates);
    }

    private onDatesSelection(dates: Date[]) {
        const { selectedDates } = this.state;

        let updatedSelectedDates: Date[];

        if (dates.length > 1) {
            const allDatesAreSelected = dates.every((item) => this.includesDate(selectedDates, item));

            if (allDatesAreSelected) {
                updatedSelectedDates = selectedDates.filter((item) => !this.includesDate(dates, item));
            } else {
                const datesToSelect = dates.filter((item) => !this.includesDate(selectedDates, item));

                updatedSelectedDates = [...selectedDates, ...datesToSelect];
            }
        } else {
            updatedSelectedDates = lodash
                .xor(
                    selectedDates.map((item) => item.valueOf()),
                    dates.map((item) => item.valueOf()),
                )
                .map((item) => new Date(item));
        }

        updatedSelectedDates = this.sortDates(updatedSelectedDates);

        this.setState(
            {
                selectedDates: updatedSelectedDates,
            },
            () => {
                this.props.onDatesChange(updatedSelectedDates);
            },
        );
    }

    private makePeriods(): DatePeriod[] {
        const { selectedDates } = this.state;

        const periods: DatePeriod[] = [];

        let previousDate: Date = null;
        let periodStart: Date = null;

        selectedDates.forEach((currentDate, index) => {
            if (!periodStart) {
                periodStart = currentDate;
            }

            if (previousDate) {
                const daysCount = this.getDaysCountBetweenDates(currentDate, previousDate);

                if (daysCount > 1) {
                    periods.push(this.makePeriod(periodStart, previousDate));

                    periodStart = currentDate;
                }
            }

            const dateIsLast = index === selectedDates.length - 1;

            if (dateIsLast) {
                periods.push(this.makePeriod(periodStart, currentDate));
            }

            previousDate = currentDate;
        });

        return periods;
    }

    private includesDate(dates: Date[], date: Date): boolean {
        return dates.some((item) => this.compateDates(item, date));
    }

    private compateDates(date1: Date, date2: Date): boolean {
        return new Date(date1).getTime() === new Date(date2).getTime();
    }

    private compareDateLists(dates1: Date[], dates2: Date[]): boolean {
        return dates1.every((item) => this.includesDate(dates2, item)) && dates1.length === dates2.length;
    }

    private sortDates(dates: Date[]): Date[] {
        return lodash.sortBy(dates, (item) => item.getTime());
    }

    private getPeriodDates(startDate: Date, endDate: Date): Date[] {
        const periodDates: Date[] = [];
        let currentDate = startDate;

        while (currentDate <= endDate) {
            periodDates.push(new Date(currentDate));
            currentDate = this.addDays(currentDate, 1);
        }
        return periodDates;
    }

    private getDaysCountBetweenDates(date1: Date, date2: Date): number {
        return moment(date1).diff(moment(date2), 'days');
    }

    private addDays(value: Date, days: number): Date {
        const date = new Date(value.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    private makePeriod(startDate: Date, endDate: Date): DatePeriod {
        const periodDates = this.getPeriodDates(startDate, endDate);

        return {
            title:
                periodDates.length > 1
                    ? `${this.formatDate(startDate)} — ${this.formatDate(endDate)}`
                    : this.formatDate(startDate),
            dates: periodDates,
        };
    }

    private removeHoursFromDate(date: Date): Date {
        if (!date) {
            return null;
        }

        return new Date(date.setHours(0, 0, 0, 0));
    }

    private limitDates(dates: Date[], minDate?: Date, maxDate?: Date): Date[] {
        let updatedDates = lodash.clone(dates);

        if (minDate) {
            updatedDates = updatedDates.filter((item) => item.getTime() >= minDate.getTime());
        }

        if (maxDate) {
            updatedDates = updatedDates.filter((item) => item.getTime() <= maxDate.getTime());
        }

        return updatedDates;
    }

    private formatDate(date: Date): string {
        return moment(date).format('D MMMM YYYY');
    }
}
