import * as React from 'react';

import { useStore, FilterPeriod } from './useStore';
import { useMode } from '../../hooks';

interface UseBehavior {
    (): Return;
}

interface Return {
    selectedFilterPeriod: FilterPeriod;
    onItemClick: OnItemClick;
}

interface OnItemClick {
    (period: FilterPeriod): void;
}

export const useBehavior: UseBehavior = () => {
    const { selectedFilterPeriod, setFilterPeriod } = useStore();
    const { mode } = useMode();

    const onItemClick: OnItemClick = React.useCallback(
        (period) => {
            setFilterPeriod({
                period,
                mode,
            });
        },
        [mode, setFilterPeriod],
    );

    return {
        selectedFilterPeriod,
        onItemClick,
    };
};
