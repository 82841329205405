import * as React from 'react';

import { Color, Direction, Result } from '../../../../common';
import { Period, StatisticsTaskType } from '../../useState';
import { getPeriodName } from '../getPeriodName';

import { BuildParams } from '../types';

import { ActiveTaskTitle, ClosedTitle, DeadlineApproachingTitle, OverdueTitle } from '../../../../common/TitleGroup';

export const buildTaskParams: BuildParams<'tasks'> = ({
    selectedStatisticsItem,
    selectedPeriod,
    statisticsTypeFilters,
    setStatisticsTypeFilter,
}) => [
    buildData({
        result:
            selectedStatisticsItem.statistics.current.overdueCount ===
            selectedStatisticsItem.statistics.past.overdueCount
                ? Result.NEUTRAL
                : selectedStatisticsItem.statistics.current.overdueCount >
                  selectedStatisticsItem.statistics.past.overdueCount
                ? Result.NEGATIVE
                : Result.POSITIVE,
        period: selectedPeriod,
        currentCount: selectedStatisticsItem.statistics.current.overdueCount,
        pastCount: selectedStatisticsItem.statistics.past.overdueCount,
        color: Color.RED,
        titleComponent: <OverdueTitle color={Color.RED} />,
        darken: !statisticsTypeFilters[StatisticsTaskType.OVERDUE],
        onClick: () =>
            setStatisticsTypeFilter(StatisticsTaskType.OVERDUE, !statisticsTypeFilters[StatisticsTaskType.OVERDUE]),
    }),
    buildData({
        result:
            selectedStatisticsItem.statistics.current.withDeadlineApproachingCount ===
            selectedStatisticsItem.statistics.past.withDeadlineApproachingCount
                ? Result.NEUTRAL
                : selectedStatisticsItem.statistics.current.withDeadlineApproachingCount >
                  selectedStatisticsItem.statistics.past.withDeadlineApproachingCount
                ? Result.NEGATIVE
                : Result.POSITIVE,
        period: selectedPeriod,
        currentCount: selectedStatisticsItem.statistics.current.withDeadlineApproachingCount,
        pastCount: selectedStatisticsItem.statistics.past.withDeadlineApproachingCount,
        color: Color.MUSTARD,
        titleComponent: <DeadlineApproachingTitle color={Color.MUSTARD} />,
        darken: !statisticsTypeFilters[StatisticsTaskType.COMING_DEADLINE],
        onClick: () =>
            setStatisticsTypeFilter(
                StatisticsTaskType.COMING_DEADLINE,
                !statisticsTypeFilters[StatisticsTaskType.COMING_DEADLINE],
            ),
    }),
    buildData({
        result:
            selectedStatisticsItem.statistics.current.activeCount === selectedStatisticsItem.statistics.past.activeCount
                ? Result.NEUTRAL
                : selectedStatisticsItem.statistics.current.activeCount ===
                  selectedStatisticsItem.statistics.past.activeCount
                ? Result.NEGATIVE
                : Result.POSITIVE,
        period: selectedPeriod,
        currentCount: selectedStatisticsItem.statistics.current.activeCount,
        pastCount: selectedStatisticsItem.statistics.past.activeCount,
        color: Color.GREEN,
        titleComponent: <ActiveTaskTitle color={Color.GREEN} />,
        darken: !statisticsTypeFilters[StatisticsTaskType.ACTIVE],
        onClick: () =>
            setStatisticsTypeFilter(StatisticsTaskType.ACTIVE, !statisticsTypeFilters[StatisticsTaskType.ACTIVE]),
    }),
    buildData({
        result:
            selectedStatisticsItem.statistics.current.closedCount === selectedStatisticsItem.statistics.past.closedCount
                ? Result.NEUTRAL
                : selectedStatisticsItem.statistics.current.closedCount >
                  selectedStatisticsItem.statistics.past.closedCount
                ? Result.POSITIVE
                : Result.NEGATIVE,
        period: selectedPeriod,
        currentCount: selectedStatisticsItem.statistics.current.closedCount,
        pastCount: selectedStatisticsItem.statistics.past.closedCount,
        color: Color.BLUE,
        titleComponent: <ClosedTitle color={Color.BLUE} />,
        darken: !statisticsTypeFilters[StatisticsTaskType.CLOSED],
        onClick: () =>
            setStatisticsTypeFilter(StatisticsTaskType.CLOSED, !statisticsTypeFilters[StatisticsTaskType.CLOSED]),
    }),
];

const buildData = ({
    result,
    currentCount = 0,
    pastCount = 0,
    period,
    color,
    titleComponent,
    darken,
    onClick,
}: {
    result: Result;
    currentCount: number;
    pastCount: number;
    period: Period;
    color: Color;
    darken: boolean;
    titleComponent: React.ClassicElement<HTMLDivElement>;
    onClick: () => void;
}): any => ({
    count: currentCount,
    fractionOfTotal:
        currentCount > pastCount
            ? Math.round(pastCount / (currentCount / 100))
            : Math.round(currentCount / (pastCount / 100)),
    color,
    periodName: getPeriodName(period),
    progress: {
        result,
        direction:
            currentCount === pastCount ? Direction.NEUTRAL : currentCount > pastCount ? Direction.UP : Direction.DOWN,
        value:
            currentCount > pastCount
                ? pastCount === 0
                    ? 100
                    : Math.round(pastCount / (currentCount / 100))
                : currentCount === 0
                ? 100
                : Math.round(currentCount / (pastCount / 100)),
    },
    darken,
    titleComponent,
    onClick,
});
