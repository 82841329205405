import * as React from 'react';
import { Skeleton } from 'sber-marketing-ui';

import * as style from './Preloader.scss';

export const Preloader: React.FC = () => {
    return (
        <div className={style.root}>
            <div className={style.topLine}>
                <Skeleton customStyle={{ height: 30, width: 260, borderRadius: 2 }} />
                <Skeleton customStyle={{ height: 32, width: 170, borderRadius: 2, marginLeft: 8 }} />
            </div>

            <div className={style.groups}>
                <div className={style.group}>
                    <Skeleton customStyle={{ height: 22, width: 44, borderRadius: 2 }} />
                    <Skeleton customStyle={{ height: 16, width: 120, borderRadius: 2, marginTop: 5 }} />
                </div>

                <div className={style.separator} />

                <div className={style.group}>
                    <Skeleton customStyle={{ height: 22, width: 95, borderRadius: 2 }} />
                    <Skeleton customStyle={{ height: 16, width: 90, borderRadius: 2, marginTop: 5 }} />
                    <Skeleton customStyle={{ height: 16, width: 120, borderRadius: 2, marginTop: 4 }} />
                </div>

                <div className={style.separator} />

                <div className={style.group}>
                    <Skeleton customStyle={{ height: 22, width: 44, borderRadius: 2 }} />
                    <Skeleton customStyle={{ height: 16, width: 90, borderRadius: 2, marginTop: 5 }} />
                </div>

                <div className={style.separator} />

                <div className={style.group}>
                    <Skeleton customStyle={{ height: 22, width: 75, borderRadius: 2 }} />
                    <Skeleton customStyle={{ height: 16, width: 65, borderRadius: 2, marginTop: 4 }} />
                    {/* <Skeleton customStyle={{ height: 16, width: 70, borderRadius: 2, marginTop: 5 }} /> */}
                    {/* <Skeleton customStyle={{ height: 16, width: 65, borderRadius: 2, marginTop: 4 }} /> */}
                    {/* <Skeleton customStyle={{ height: 16, width: 95, borderRadius: 2, marginTop: 4 }} /> */}
                </div>

                <div className={style.separator} />

                <div className={style.group}>
                    <Skeleton customStyle={{ height: 22, width: 39, borderRadius: 2 }} />
                    <Skeleton customStyle={{ height: 16, width: 150, borderRadius: 2, marginTop: 5 }} />
                </div>
            </div>
        </div>
    );
};
