import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconType, Modal, WithTooltip, TooltipAnchor } from 'sber-marketing-ui';

import { InputWithSendButton } from '@common/InputWithSendButton';
import { BudgetExpertsPopup } from '@common/BudgetExpertsPopup';
import { CopyTextButton } from '@modules/common/CopyTextButton';

import * as styles from './SAPCommentInput.scss';

interface Props {
    canEdit: boolean;
    isRequestApproved: boolean;
    sapComment: string;
    isInputActive: boolean;
    correctionsSAPComment: string;
    isBudgetExpertsPopupVisible: boolean;
    onEditButtonClick: React.MouseEventHandler<SVGSVGElement>;
    onSaveButtonClick: () => void;
    onInputChange: (value: string) => void;
    onModalClick: React.MouseEventHandler<HTMLDivElement>;
    onBudgetExprtsPopupCloseButtonClick: () => void;
    onBudgetExpertsPopupSubmitButtonClick: (expertId: number) => void;
    onRootClick: React.MouseEventHandler<HTMLDivElement>;
}

export function SAPCommentInputTemplate({
    isInputActive,
    isRequestApproved,
    isBudgetExpertsPopupVisible,
    onModalClick,
    onBudgetExprtsPopupCloseButtonClick,
    onBudgetExpertsPopupSubmitButtonClick,
    onRootClick,
    ...restProps
}: Props): JSX.Element {
    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'serialNumberCardSAPCommentInput',
                'qa-value': restProps.sapComment,
            }}
        >
            <div className={styles.title}>
                <InfoIcon
                    isCorrectionPresent={!!restProps.correctionsSAPComment}
                    isRequestApproved={isRequestApproved}
                />
                Название проекта (комментарий для SAP):
            </div>

            <div onClick={onRootClick} className={styles.contentWrapper}>
                <Content isInputActive={isInputActive} {...restProps} />
            </div>

            {isInputActive && <div className={styles.modal} onClick={onModalClick} />}

            {isBudgetExpertsPopupVisible && (
                <BudgetExpertsPopup
                    closePopup={onBudgetExprtsPopupCloseButtonClick}
                    submitPopup={onBudgetExpertsPopupSubmitButtonClick}
                />
            )}
        </div>
    );
}

interface InfoIconProps {
    isRequestApproved: boolean;
    isCorrectionPresent: boolean;
}

function InfoIcon({ isRequestApproved, isCorrectionPresent }: InfoIconProps): JSX.Element {
    if (isRequestApproved) {
        return null;
    }

    // tslint:disable:max-line-length
    const params = isCorrectionPresent
        ? {
              text: 'Откройте связь в дашборде, чтобы согласовать корректировку по\nназванию проекта',
              iconClass: classNames(styles.titleIcon, styles.titleIcon_colored),
          }
        : {
              text: 'Обратите внимание, что важно правильно сформурлировать\nназвание проекта (редактировать можно на данной карточке)',
              iconClass: styles.titleIcon,
          };
    // tslint:enable:max-line-length

    return (
        <div className={styles.titleIconWrapper}>
            <WithTooltip anchor={TooltipAnchor.LEFT} content={params.text}>
                <Icon type={IconType.INFO_CIRCLE} svgSize={16} className={params.iconClass} />
            </WithTooltip>
        </div>
    );
}

type ContentProps = Pick<
    Props,
    | 'canEdit'
    | 'isInputActive'
    | 'sapComment'
    | 'correctionsSAPComment'
    | 'onSaveButtonClick'
    | 'onEditButtonClick'
    | 'onInputChange'
>;

function Content({
    canEdit,
    isInputActive,
    sapComment,
    correctionsSAPComment,
    onSaveButtonClick,
    onEditButtonClick,
    onInputChange,
}: ContentProps): JSX.Element {
    if (isInputActive) {
        return (
            <div className={classNames(styles.content, styles.input)}>
                <InputWithSendButton
                    placeholder="Комментарий для SAP"
                    value={sapComment}
                    onSendButtonClick={onSaveButtonClick}
                    onInputChange={onInputChange}
                />
            </div>
        );
    }

    if (correctionsSAPComment) {
        return (
            <React.Fragment>
                <span className={styles.sapComment_edited}>{sapComment}</span>

                <span className={styles.arrowSign}>⟶</span>

                <span className={styles.sapComment}>{correctionsSAPComment}</span>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div style={{ display: 'flex', gap: 7 }}>
                <span className={styles.sapComment}>{sapComment}</span>
                <div onClick={(e) => e.stopPropagation()}>
                    <CopyTextButton title="Скопировать полное название" text={sapComment} />
                </div>
                {canEdit && (
                    <Icon
                        style={{ marginTop: 1 }}
                        type={IconType.PROJECT_STAGES_EDIT_ICON}
                        svgSize={15}
                        className={styles.editButton}
                        onClick={onEditButtonClick}
                    />
                )}
            </div>
        </React.Fragment>
    );
}
