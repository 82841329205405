import * as moment from 'moment';

import type { Autopilot, AutopilotBrief, AutopilotCreative } from 'sber-marketing-types/backend';
import { AutopilotStatus } from 'sber-marketing-types/backend';
import { BriefStageForm, CreativeStageForm } from '@store/autopilot_legacy/types';

import { store } from '@store';
import {
    getAutopilot,
    getBriefStageForm,
    getSelectedBudgetItemIds,
    getCreativeStageForm,
} from '@store/autopilot_legacy/selectors';
import { AutopilotLegacyApi as AutopilotApi } from '@api';

interface StoreProps {
    autopilot: Autopilot;
    briefForm: BriefStageForm;
    selectedBudgetItemIds: string[];
    creativeStageForm: CreativeStageForm;
}

export class Saver {
    private static instance: Saver;

    public static getInstance(): Saver {
        if (!Saver.instance) {
            Saver.instance = new Saver();
        }
        return Saver.instance;
    }

    public init(): void {}

    public async setAutopilotStatus(status: boolean) {
        const { autopilot } = this.getStoreProps();

        if (autopilot) {
            await AutopilotApi.updateAutopilot(autopilot.id, {
                status: (status ? 'on' : 'off') as AutopilotStatus,
            });
        }
    }

    public async saveBriefForm() {
        const { autopilot } = this.getStoreProps();

        if (autopilot) {
            const updateParams = this.makeAutopilotBriefParams();

            await AutopilotApi.updateBrief(autopilot.id, updateParams);
        }
    }

    public async calculateMediaplan() {
        const { autopilot } = this.getStoreProps();

        if (autopilot) {
            await AutopilotApi.updateMediaplan(autopilot.id);
        }
    }

    public async saveSelectedBudgetItems() {
        const { autopilot, selectedBudgetItemIds } = this.getStoreProps();

        if (autopilot) {
            await AutopilotApi.updateBudget(autopilot.id, { budgetIds: selectedBudgetItemIds });
        }
    }

    public async saveCreativeForm() {
        const { autopilot } = this.getStoreProps();

        if (autopilot) {
            const updateParams = this.makeAutopilotCreativeParams();

            await AutopilotApi.updateCreative(autopilot.id, updateParams);
        }
    }

    public async launchPlacements() {
        const { autopilot } = this.getStoreProps();

        if (autopilot) {
            await AutopilotApi.launchPlacements(autopilot.id);
        }
    }

    public async stopPlacements() {
        const { autopilot } = this.getStoreProps();

        if (autopilot) {
            await AutopilotApi.stopPlacements(autopilot.id);
        }
    }

    private makeAutopilotBriefParams(): AutopilotBrief {
        const { briefForm } = this.getStoreProps();

        return {
            block: briefForm.blockId,
            product: briefForm.productId,
            budget: parseInt(briefForm.budget, 10),
            dateStart: briefForm.dateStart.format('YYYY-MM-DD'),
            dateEnd: briefForm.dateEnd.format('YYYY-MM-DD'),
            peak_dates: briefForm.peakDates.map((item) => moment(item).format('YYYY-MM-DD')),
            age_from: parseInt(briefForm.targetAgeFrom, 10),
            age_to: parseInt(briefForm.targetAgeTo, 10),
        };
    }

    private makeAutopilotCreativeParams(): AutopilotCreative {
        const { creativeStageForm } = this.getStoreProps();

        return {
            vk: creativeStageForm.vkUrl,
            fb: creativeStageForm.facebookUrl,
            ok: creativeStageForm.okUrl,
            youtube: creativeStageForm.youtubeUrl,
        };
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        return {
            autopilot: getAutopilot(storeState),
            briefForm: getBriefStageForm(storeState),
            selectedBudgetItemIds: getSelectedBudgetItemIds(storeState),
            creativeStageForm: getCreativeStageForm(storeState),
        };
    }
}
