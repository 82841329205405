import * as React from 'react';
import { Link } from 'react-router-dom';
import {
    ResponsiveLayout,
    LayoutTheme,
    RightSidebar,
    LeftSidebar,
    CentralContent,
    MenuItem,
    Button_redesign as Button,
} from 'sber-marketing-ui';

import { QuickAccessIcons } from '@common/QuickAccessIcons';
import { CurrentPage } from '../DashboardsPage';
import { DashboardFilter } from '../DashboardFilter';

import * as style from './DashboardHeader.scss';

export interface DashboardsHeaderProps {
    currentPage?: CurrentPage;
    onMyTasksSelect(event: React.MouseEvent<HTMLAnchorElement>): void;
    onAccessibleProjectsSelect(event: React.MouseEvent<HTMLAnchorElement>): void;
    onMyProjectsSelect(event: React.MouseEvent<HTMLAnchorElement>): void;
}

interface Props extends DashboardsHeaderProps {}

const MY_TASKS = 'Задачи';
const ACCESSIBLE_PROJECTS = 'Доступные проекты';
const MY_PROJECTS = 'Мои проекты';

export const DashboardHeader = ({
    currentPage = CurrentPage.MyTasks,
    onMyTasksSelect,
    onAccessibleProjectsSelect,
    onMyProjectsSelect,
}: Props) => {
    return (
        <div className={style.root}>
            <ResponsiveLayout theme={LayoutTheme.Search}>
                <LeftSidebar>
                    <DashboardFilter currentPage={currentPage} />
                </LeftSidebar>
                <CentralContent>
                    <div className={style.menuList}>
                        <MenuItem
                            qaId="headerMenuMyTasks"
                            link={'/dashboard?currentPage=myTasks'}
                            active={currentPage === CurrentPage.MyTasks}
                            onClick={onMyTasksSelect}
                        >
                            <span className={style.menuItem}>{MY_TASKS}</span>
                        </MenuItem>

                        <MenuItem
                            qaId="headerMenuAvailableActivities"
                            link={'/dashboard?currentPage=accessibleProjects'}
                            active={currentPage === CurrentPage.AccessibleProjects}
                            onClick={onAccessibleProjectsSelect}
                        >
                            <span className={style.menuItem}>{ACCESSIBLE_PROJECTS}</span>
                        </MenuItem>
                        <MenuItem
                            qaId="headerMenuMyActivities"
                            link={'/dashboard?currentPage=myProjects'}
                            active={currentPage === CurrentPage.MyProjects}
                            onClick={onMyProjectsSelect}
                        >
                            <span className={style.menuItem}>{MY_PROJECTS}</span>
                        </MenuItem>
                    </div>
                </CentralContent>

                <RightSidebar>
                    <div className={style.buttonBlockWrapper}>
                        <div className={style.quickAccessIcons}>
                            <QuickAccessIcons />
                        </div>

                        <Link to="/activity/new" style={{ textDecoration: 'none' }}>
                            <div
                                className={style.buttonWrapper}
                                {...{
                                    'qa-id': 'dashboardCreateActivityButton',
                                }}
                            >
                                <Button>Новый проект</Button>
                            </div>
                        </Link>
                    </div>
                </RightSidebar>
            </ResponsiveLayout>
        </div>
    );
};
