import * as React from 'react';
import { v4 as uuid } from 'uuid';
import * as lodash from 'lodash';
import * as moment from 'moment';
import { ActivityBudget, BudgetItem, Month } from '@mrm/budget';
import { PlainDictionary, DictionaryType, DictionaryStatus } from '@mrm/dictionary';
import { UserResponseParams } from 'sber-marketing-types/frontend';
import { FormField, FieldType, SelectItem } from 'sber-marketing-ui';

import { DataUpdateStrategy } from '@store/tagsEditor';

import { VerticalPosition, TagsPreviewAndEditor } from '@modules/tags/TagsPreviewAndEditor';

import { MONTHS_INDEXES, ORDER_MONTHS_KEYS_OF_FONDS } from '../../utils';

import { Money, Utils } from '@common/Utils';

import * as styles from './BudgetForm.scss';

const MONTH_NAMES = {
    [Month.Jan]: 'Январь',
    [Month.Feb]: 'Февраль',
    [Month.Mar]: 'Март',
    [Month.Apr]: 'Апрель',
    [Month.May]: 'Май',
    [Month.Jun]: 'Июнь',
    [Month.Jul]: 'Июль',
    [Month.Aug]: 'Август',
    [Month.Sept]: 'Сентябрь',
    [Month.Oct]: 'Октябрь',
    [Month.Nov]: 'Ноябрь',
    [Month.Dec]: 'Декабрь',
};

interface Props {
    tagsEditorId: string;
    pageIsInCopyMode: boolean;
    pageIsInCopyFromExecutionMode: boolean;
    activityBudget?: ActivityBudget;
    budgetItem?: BudgetItem;
    budgetItemId: string;
    canBeTransfered: boolean;
    users: UserResponseParams[];
    onActivityInputFocus: (budgetItemId: string) => void;
    onActivityInputBlur: (budgetItemId: string) => void;
}

export function makeFormFields({
    tagsEditorId,
    pageIsInCopyMode,
    pageIsInCopyFromExecutionMode,
    activityBudget,
    budgetItem,
    budgetItemId,
    canBeTransfered,
    users,
    onActivityInputFocus,
    onActivityInputBlur,
}: Props): FormField[] {
    const realizationStart = budgetItem && budgetItem.realizationStart ? moment(budgetItem.realizationStart) : null;
    const realizationEnd = budgetItem && budgetItem.realizationEnd ? moment(budgetItem.realizationEnd) : null;

    return (
        [
            {
                qaId: 'activityBudgetInput',
                name: 'activityBudget',
                type: FieldType.Input,
                title: 'Относится к активности',
                value: activityBudget ? activityBudget.name : null,
                disabled: !canBeTransfered,
                onFocus: () => onActivityInputFocus(budgetItemId || budgetItem?.id || null),
                onBlur: () => onActivityInputBlur(budgetItemId || budgetItem?.id || null),
            },
            {
                qaId: 'regionalityDropdown',
                name: DictionaryType.Regionality,
                type: FieldType.Select,
                title: 'ЦА/ТБ (территория)',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.Regionality),
                isRequired: true,
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.Regionality,
            },
            {
                qaId: 'activityTypeDropdown',
                name: DictionaryType.ActivityType,
                type: FieldType.Select,
                title: 'Тип проекта',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.ActivityType),
                isRequired: true,
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.ActivityType,
            },
            {
                qaId: 'directionDropdown',
                name: DictionaryType.Direction,
                type: FieldType.Select,
                title: 'Направление',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.Direction),
                isRequired: true,
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.Direction,
            },
            {
                qaId: 'sapCommentInput',
                name: 'sapComment',
                type: FieldType.Input,
                title: 'Название проекта',
                value: budgetItem ? budgetItem.sapComment : null,
                isRequired: true,
            },
            {
                qaId: 'toolDropdown',
                name: DictionaryType.Tool,
                type: FieldType.Select,
                title: 'Инструмент',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.Tool),
                isRequired: true,
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.Tool,
            },
            {
                qaId: 'costCenterDropdown',
                name: DictionaryType.CostCenter,
                type: FieldType.Select,
                title: 'ЦЗ',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.CostCenter),
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.CostCenter,
            },
            {
                qaId: 'blockDropdown',
                name: DictionaryType.Block,
                type: FieldType.Select,
                title: 'Блок',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.Block),
                isRequired: true,
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.Block,
            },
            {
                qaId: 'divisionDropdown',
                name: DictionaryType.Division,
                type: FieldType.Select,
                title: 'Дивизион',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.Division),
                disableAutoSelectSingleItem: true,
                isRequired: true,
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.Division,
            },
            {
                qaId: 'itemDropdown',
                name: DictionaryType.Item,
                type: FieldType.Select,
                title: 'Статья',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.Item),
                disableAutoSelectSingleItem: true,
                isRequired: true,
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.Item,
            },
            {
                qaId: 'resourceDropdown',
                name: DictionaryType.Resource,
                type: FieldType.Select,
                title: 'Ресурс',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.Resource),
                disableAutoSelectSingleItem: true,
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.Resource,
            },
            {
                qaId: 'territoryDropdown',
                name: DictionaryType.Territory,
                type: FieldType.Select,
                title: 'Территория',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.Territory),
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.Territory,
            },
            {
                qaId: 'locationDriverDropdown',
                name: DictionaryType.LocationDriver,
                type: FieldType.Select,
                title: 'Драйвер аллокации (Бизнес-блок)',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.LocationDriver),
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.LocationDriver,
            },
            {
                qaId: 'segmentDropdown',
                name: DictionaryType.Segment,
                type: FieldType.Select,
                title: 'Сегмент',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.Segment),
                isRequired: true,
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.Segment,
            },
            {
                qaId: 'productDropdown',
                name: DictionaryType.Product,
                type: FieldType.Select,
                title: 'Продукт',
                items: [],
                value: getDictionaryValue(budgetItem, DictionaryType.Product),
                isRequired: true,
                hideDisabledOptions: true,
                dictionaryType: DictionaryType.Product,
            },
            {
                qaId: 'redponsibleDropdown',
                name: 'responsibles',
                type: FieldType.Select,
                title: 'Руководители проекта',
                allowMultipleItems: true,
                items: lodash.sortBy(
                    users.length
                        ? users.map((item) => ({
                              label: makeResponsibleLabel(item),
                              value: item.id,
                          }))
                        : [],
                    'label',
                ),
                value: budgetItem && budgetItem.responsibles ? budgetItem.responsibles.map((user) => user.id) : [],
            },
            {
                qaId: 'businessTargetDropdown',
                name: 'businessTarget',
                type: FieldType.Input,
                title: 'Бизнес-цель',
                value: budgetItem ? budgetItem.businessTarget : null,
                isRequired: true,
            },
            {
                qaId: 'customerNameInput',
                name: 'customerName',
                type: FieldType.Input,
                title: 'ФИО заказчика',
                value: budgetItem && budgetItem.customerName ? budgetItem.customerName : null,
                isRequired: true,
            },
            {
                type: FieldType.CustomJSX,
                jsxValue: (
                    <TagsPreviewAndEditor
                        id={tagsEditorId}
                        verticalPosition={VerticalPosition.Top}
                        planBudgetItemId={budgetItemId || budgetItem?.id || null}
                        dataUpdateStrategy={DataUpdateStrategy.OnDemand}
                        className={styles.tagsEditor}
                    />
                ),
            },
            {
                qaId: 'startDatePick',
                name: 'startDate',
                type: FieldType.DatePicker,
                title: 'Дата старта',
                value: realizationStart,
                isSmall: true,
                isRequired: true,
            },
            {
                qaId: 'endDatePicker',
                name: 'endDate',
                type: FieldType.DatePicker,
                title: 'Дата окончания',
                value: realizationEnd,
                isSmall: true,
                isRequired: true,
            },
            ...(pageIsInCopyFromExecutionMode
                ? ([
                      {
                          qaId: 'factPreviousPeriodInput',
                          name: 'factPreviousPeriod',
                          type: FieldType.Input,
                          title: 'Факт предыдущего периода, ₽',
                          value: Utils.withErrorHandler(
                              () => lodash.sum(lodash.values(budgetItem.factFunds || {})) / 100.0,
                          ),
                          onlyNumbers: true,
                      },
                      {
                          qaId: 'planPreviousPeriodInput',
                          name: 'planPreviousPeriod',
                          type: FieldType.Input,
                          title: 'План предыдущего периода, ₽',
                          value: Utils.withErrorHandler(
                              () => lodash.sum(lodash.values(budgetItem.plannedFunds || {})) / 100.0,
                          ),
                          onlyNumbers: true,
                          hidden: true,
                      },
                  ] as FormField[])
                : []),
            {
                qaId: 'commentInput',
                name: 'comment',
                type: FieldType.Input,
                title: 'Примечания к строке',
                value: !pageIsInCopyMode && budgetItem ? budgetItem.comment : null,
            },
            ...makeBudgetFields(budgetItem),
        ] as FormField[]
    ).map((item) => {
        let disabled = item.disabled;
        if (!disabled) {
            if (!pageIsInCopyMode && !pageIsInCopyFromExecutionMode && budgetItem) {
                disabled = !budgetItem.actions.canEdit;
            }
        }

        return {
            ...item,
            id: item.id || uuid(),
            errorMessage: '',
            disabled,
            scrollId: `${budgetItemId}-${item.name}`,
        };
    });
}

function getDictionaryValue(budgetItem: BudgetItem, dictionaryType: DictionaryType) {
    if (!budgetItem || !budgetItem.dictionary || !budgetItem.dictionary[dictionaryType]) {
        return null;
    }
    return budgetItem.dictionary[dictionaryType].id;
}

export function formatDictionaryItems(
    dictionaries: PlainDictionary[],
    tooltipAccessor?: (label: string) => JSX.Element,
    resetLinksTooltip?: JSX.Element,
): SelectItem[] {
    const items: SelectItem[] = lodash.sortBy(
        dictionaries.map((item) => {
            const label = Utils.getDictionaryValue(item);

            const res: SelectItem = {
                label,
                value: item.id,
                disabled: item.status === DictionaryStatus.DELETED,
            };

            if (tooltipAccessor) {
                res.tooltip = tooltipAccessor(label);
            }

            return res;
        }),
        (item) => (item.label ? item.label.replace(/\"|\'/g, '') : ''),
    );

    if (resetLinksTooltip) {
        items.unshift({
            label: 'Сброс связей',
            value: null,
            tooltip: resetLinksTooltip,
            style: { fontWeight: 600 },
        });
    } else if (items.length) {
        items.unshift({ label: '-', value: null });
    }

    return items;
}

function makeBudgetFields(budgetItem?: BudgetItem): FormField[] {
    let result;
    if (budgetItem && budgetItem.plannedFunds) {
        const { plannedFunds } = budgetItem;

        const monthsKeysOfFonds = lodash.keys(plannedFunds) as Month[];
        const sortedMonthsKeysOfFonds = lodash.sortBy(
            monthsKeysOfFonds,
            (monthsKeysOfFond) => MONTHS_INDEXES[monthsKeysOfFond],
        );

        const monthsKeysOfFondWithNotEmptyAmount = sortedMonthsKeysOfFonds.filter(
            (monthsKeyOfFond) => plannedFunds[monthsKeyOfFond] > 0,
        );
        const notEmptyFonds = monthsKeysOfFondWithNotEmptyAmount.length >= 12;

        result = notEmptyFonds
            ? [
                  ...lodash.flatMap(monthsKeysOfFondWithNotEmptyAmount, (monthKeyOfFond) =>
                      getMonthFields(
                          sortedMonthsKeysOfFonds,
                          monthKeyOfFond,
                          convertAmountToRoubles(plannedFunds[monthKeyOfFond]),
                      ),
                  ),
              ]
            : [
                  ...lodash.flatMap(monthsKeysOfFondWithNotEmptyAmount, (monthKeyOfFond) =>
                      getMonthFields(
                          sortedMonthsKeysOfFonds,
                          monthKeyOfFond,
                          convertAmountToRoubles(plannedFunds[monthKeyOfFond]),
                      ),
                  ),
                  ...getMonthFields(monthsKeysOfFondWithNotEmptyAmount),
              ];
    } else {
        result = getMonthFields();
    }

    return result.map((item, index) => ({
        ...item,
        isRequired: index === 0 || index === 1,
    }));
}

export function getMonthFields(
    monthsKeysOfFonds?: Month[],
    monthKeyOfFond?: Month,
    amount?: number,
    pairId?: string,
): FormField[] {
    return [
        {
            id: pairId || uuid(),
            qaId: 'monthSelect',
            name: 'month',
            type: FieldType.Select,
            title: 'Месяц',
            items: monthsKeysOfFonds
                ? getMonths().filter(
                      (item) =>
                          (!lodash.includes(monthsKeysOfFonds, item.value) || item.value === monthKeyOfFond) &&
                          (monthKeyOfFond || item.value !== null),
                  )
                : getMonths().filter((item) => item.value),
            value: monthKeyOfFond || null,
            isSmall: true,
            disableAutoSelectSingleItem: true,
            withTopPlacement: true,
        },
        {
            id: uuid(),
            qaId: 'plannedAmountInput',
            name: 'plannedAmount',
            type: FieldType.Input,
            title: 'Сумма без НДС, ₽',
            value: amount || null,
            onlyNumbers: true,
            isSmall: true,
        },
    ].map((item) => ({
        ...item,
        errorMessage: '',
    }));
}

function makeResponsibleLabel(user: UserResponseParams): string {
    const { firstName, middleName, secondName } = user;

    return middleName ? `${secondName} ${firstName} ${middleName}` : `${secondName} ${firstName}`;
}

function convertAmountToRoubles(amount: number): number {
    return Money.fromCopecks(amount).getRoubles();
}

export function getMonths(): SelectItem[] {
    return [
        {
            label: 'Не выбрано',
            value: null,
        },
        ...ORDER_MONTHS_KEYS_OF_FONDS.map((monthKeyOfFond) => ({
            label: MONTH_NAMES[monthKeyOfFond],
            value: monthKeyOfFond,
        })),
    ];
}
