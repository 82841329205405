import * as React from 'react';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import type { MentionItem } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getDashboardPageState } from '@store/dashboardPage';

import { FileInput } from '@common/FileInput';

import { CommentInput } from './CommentInput';

interface Props extends Partial<MapProps> {
    createComment: (text: string, files: File[]) => Promise<void>;
}

interface MapProps {
    selectedCardId: string;
    mentionableUsers: MentionItem[];
}

interface State {
    text: string;
    files: File[];
    isFocused: boolean;
    isWrapperFocused: boolean;
}

@(connect(mapStateToProps) as any)
export class CommentInputContainer extends React.PureComponent<Props, State> {
    private fileInputRef: React.RefObject<FileInput>;

    public constructor(props: Props) {
        super(props);

        this.fileInputRef = React.createRef<FileInput>();

        this.state = this.getDefaultState();
    }

    public componentDidUpdate(prevProps: Props) {
        if (prevProps.selectedCardId !== this.props.selectedCardId) {
            this.setState(this.getDefaultState());
            this.fileInputRef.current.clear();
        }
    }

    public render(): JSX.Element {
        const { text, isWrapperFocused, files } = this.state;
        const { mentionableUsers } = this.props;

        return (
            <CommentInput
                text={text}
                files={files}
                isFocused={isWrapperFocused}
                mentionableUsers={mentionableUsers}
                fileInputRef={this.fileInputRef}
                onWrapperMouseOver={this.setInputWrapperFocused}
                onWrapperMouseOut={this.unsetInputWrapperFocused}
                onInputChange={this.onInputChange}
                onInputFocus={this.setInputFocused}
                onInputBlur={this.unsetInputFocused}
                onAttachmentIconClick={this.onAttachmentIconClick}
                onAttachmentIconMouseOver={this.setInputWrapperFocused}
                onAttachmentIconMouseOut={this.unsetInputWrapperFocused}
                onSendIconClick={this.onSendIconClick}
                onSendIconMouseOver={this.setInputWrapperFocused}
                onSendIconMouseOut={this.unsetInputWrapperFocused}
                onFileInput={this.onFileInput}
                deleteFile={this.deleteFile}
            />
        );
    }

    @autobind
    private onInputChange(text: string): void {
        this.setState({
            text,
        });
    }

    @autobind
    private setInputFocused(): void {
        this.setState({
            isFocused: true,
        });
    }

    @autobind
    private unsetInputFocused(): void {
        this.setState({
            isFocused: false,
            isWrapperFocused: false,
        });
    }

    @autobind
    private setInputWrapperFocused(): void {
        this.setState({
            isWrapperFocused: true,
        });
    }

    @autobind
    private unsetInputWrapperFocused(): void {
        if (!this.state.isFocused) {
            this.setState({
                isWrapperFocused: false,
            });
        }
    }

    @autobind
    private onAttachmentIconClick(): void {
        if (this.fileInputRef.current) {
            this.fileInputRef.current.triggerOpen();
        }
    }

    @autobind
    private async onSendIconClick(): Promise<void> {
        const { text, files } = this.state;
        const comment = text.trim();

        if (comment || files.length) {
            this.setState({
                text: '',
                files: [],
            });

            await this.props.createComment(comment, files);
        }
    }

    @autobind
    private onFileInput(files: File[]): void {
        this.setState((state) => ({
            files: [...state.files, ...files],
        }));
    }

    @autobind
    private deleteFile(file: File): void {
        this.setState((state) => ({
            files: state.files.filter((stateFile) => stateFile !== file),
        }));
    }

    private getDefaultState(): State {
        return {
            text: '',
            files: [],
            isFocused: false,
            isWrapperFocused: false,
        };
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const {
        selectedCard,
        pageData: { mentionableUsers },
    } = getDashboardPageState(state);

    const selectedCardId = selectedCard ? selectedCard.id : null;

    return {
        selectedCardId,
        mentionableUsers,
    };
}
