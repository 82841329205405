import * as React from 'react';

import * as style from './BudgetItemCorrectionCard.scss';

import { CorrectionStatus, CorrectionType } from '@mrm/budget';

import {
    CorrectionCardHeader,
    CorrectionBody,
    CorrectionCardTable,
    CorrectionCardCellHeader,
    CorrectionCardCellContent,
    RejectComment,
    SumWithTitle,
    CountChanges,
    SubPramList,
    CorrectionCardComment,
    ChangedParamList,
    ChangedParam,
} from '../common';

export interface ChangedAttribute extends ChangedParam {
    name: string;
}

export interface PlanData {
    name: string;
    oldPlan: number;
    fields: PlanField[];
    comment: string;
}

export interface PlanField {
    name: string;
    value: React.ReactText;
    isWide?: boolean;
}

interface Props {
    serialNumber: number;
    authorName: string;
    approverName: string;
    userIsBudgetExpert: boolean;
    userIsAuthorCorrection: boolean;
    creationDate: string;
    planData: PlanData;
    url: string;
    changedAttributes: ChangedAttribute[];
    status: CorrectionStatus;
    rejectComment: string;
    onStatusChange: (status: CorrectionStatus) => void;
}

export const BudgetItemCorrectionCard = ({
    serialNumber,
    authorName,
    approverName,
    userIsBudgetExpert,
    userIsAuthorCorrection,
    creationDate,
    planData,
    changedAttributes,
    status,
    url,
    rejectComment,
    onStatusChange,
}: Props): JSX.Element => {
    return (
        !!planData && (
            <CorrectionBody status={status} serialNumber={serialNumber} type={CorrectionType.BudgetItem}>
                <CorrectionCardHeader
                    title={'Корректировка параметров строки'}
                    serialNumber={serialNumber}
                    authorName={authorName}
                    approverName={approverName}
                    creationDate={creationDate}
                    url={url}
                    status={status}
                    userIsAuthorCorrection={userIsAuthorCorrection}
                    userIsBudgetExpert={userIsBudgetExpert}
                    onStatusChange={onStatusChange}
                />

                {rejectComment && <RejectComment text={rejectComment} />}

                <CorrectionCardTable>
                    <CorrectionCardCellHeader>
                        <PlanSubInfo currentPlan={planData.oldPlan} title={planData.name} />
                    </CorrectionCardCellHeader>
                    <CorrectionCardCellHeader>
                        <CountChangesSubInfo count={changedAttributes.length} />
                    </CorrectionCardCellHeader>

                    <CorrectionCardCellContent>
                        <div className={style.subInfoContent}>
                            <SubPramList supParams={planData.fields} />
                            {planData.comment && (
                                <SubPramList
                                    supParams={[
                                        {
                                            name: 'Комментарий к строке',
                                            value: <CorrectionCardComment text={planData.comment} />,
                                            isWide: true,
                                            notCropContent: true,
                                        },
                                    ]}
                                />
                            )}
                        </div>
                    </CorrectionCardCellContent>
                    <CorrectionCardCellContent>
                        <div className={style.subInfoContent}>
                            <ChangedParamList params={changedAttributes} />
                        </div>
                    </CorrectionCardCellContent>
                </CorrectionCardTable>
            </CorrectionBody>
        )
    );
};

interface PlanSubInfoProps {
    currentPlan: number;
    title: string;
}

const PlanSubInfo: React.FC<PlanSubInfoProps> = (data) => (
    <div className={style.subInfo}>
        <div className={style.subInfoTitle}>{data.title}</div>

        <SumWithTitle sum={data.currentPlan} title={'Плановый бюджет по строке'} />
    </div>
);

const CountChangesSubInfo: React.FC<{ count: number }> = ({ count }) => (
    <div className={style.subInfo}>
        <div className={style.countChangesWrapper}>
            <CountChanges count={count} />
        </div>
    </div>
);
