import * as ExcelJS from 'exceljs';
import { sortBy } from 'lodash';
import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { UserCard } from 'sber-marketing-types/frontend';
import { DictionaryType, DictionaryStatus, PlainDictionary } from '@mrm/dictionary';

import { StoreState } from '@store';
import { getAllUsers } from '@store/appUsers';

import * as asyncActions from '../../actions/async';
import { getBudgetPlanningPageState } from '../../selectors';

import {
    DATA_SHEET_TITLE,
    ROWS_COUNT,
    DEFAULT_CELL_WIDTH,
    Columns,
    ColumnNames,
    UsedDictionaryTypes,
    CellFormula,
} from './types';

import { Utils, DatesFormatter } from '@common/Utils';

type ColumnToCellMap = Partial<Record<ColumnNames, string>>;

function addDictionaryWorksheet(
    workbook: ExcelJS.Workbook,
    dictionaries: PlainDictionary[],
    dictionaryType: DictionaryType,
) {
    const sortedDictionaries = sortBy(dictionaries || [], (dictionary) => dictionary.value);

    const sheet = workbook.addWorksheet(dictionaryType);
    sheet.state = 'hidden';
    sheet.columns = [
        { header: 'ID', key: 'id', width: 38 },
        { header: 'Value', key: 'value', width: 45 },
    ];

    // adding rows
    sheet.addRow({
        id: '-',
        value: '-',
    });
    sortedDictionaries.forEach((dictionary) =>
        sheet.addRow({
            id: dictionary.id,
            value: dictionary.value,
        }),
    );

    // adding names
    for (let i = 2; i <= sortedDictionaries.length + 2; i++) {
        sheet.getCell(`B${i}`).name = dictionaryType;
    }
}

function addUsersWorksheet(workbook: ExcelJS.Workbook, users: UserCard[]) {
    const SHEET_NAME = 'users';

    const sheet = workbook.addWorksheet(SHEET_NAME);
    sheet.state = 'hidden';
    sheet.columns = [
        { header: 'ID', key: 'id', width: 38 },
        { header: 'Value', key: 'value', width: 45 },
    ];

    const filteredUsers = sortBy(
        users.filter((user) => user.isActive),
        (user) => `${user.secondName} ${user.firstName}`,
    );

    // adding rows
    sheet.addRow({
        id: '-',
        value: '-',
    });
    filteredUsers.forEach((user) =>
        sheet.addRow({
            id: user.id,
            value: `${user.secondName} ${user.firstName}`,
        }),
    );

    // adding names
    for (let i = 2; i !== filteredUsers.length + 2; i++) {
        sheet.getCell(`B${i}`).name = SHEET_NAME;
    }
}

function addDataWorksheet(workbook: ExcelJS.Workbook) {
    const sheet = workbook.addWorksheet(DATA_SHEET_TITLE);
    sheet.columns = Columns.map((columnParams) => ({
        header: columnParams.header,
        key: columnParams.key as string,
        width: DEFAULT_CELL_WIDTH,
    }));

    for (let i = 2; i !== ROWS_COUNT + 2; i++) {
        sheet.addRow({});
        const row = sheet.getRow(i);
        const columnsToCellMap: ColumnToCellMap = {};

        // adding cell params (color, validation)
        Columns.forEach((columnParams) => {
            const cell = row.getCell(columnParams.key);
            columnsToCellMap[columnParams.key] = cell.address;

            if (columnParams.isRequired || columnParams.markAsRequired) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FCEAD8' },
                };
            }

            if (columnParams.linkedValues) {
                cell.dataValidation = {
                    type: 'list',
                    allowBlank: true,
                    formulae: [columnParams.linkedValues],
                    showErrorMessage: true,
                    error: 'Выберите значение соответствующего словаря',
                };
            }

            if (
                columnParams.customDataType === ExcelJS.ValueType.Number ||
                columnParams.customDataType === ExcelJS.ValueType.Date
            ) {
                const dataValidation = getCustomDataTypeCellValidation(columnParams.customDataType);

                if (dataValidation) {
                    cell.dataValidation = dataValidation;
                }
            } else if (columnParams.customDataType === ExcelJS.ValueType.Formula) {
                cell.value = { formula: processCellFormula(columnParams.formula, columnsToCellMap), date1904: false };
            }
        });
    }
}

function getCustomDataTypeCellValidation(dataType: ExcelJS.ValueType): ExcelJS.DataValidation {
    switch (dataType) {
        case ExcelJS.ValueType.Date:
            return {
                type: 'date',
                allowBlank: true,
                operator: 'greaterThanOrEqual',
                formulae: ['1/2/2020'],
                showErrorMessage: true,
                error: 'Введите дату в формате Д.М.Г. Минимально возможная дата - 01 января 2020',
            };
        case ExcelJS.ValueType.Number:
            return {
                type: 'decimal',
                allowBlank: true,
                operator: 'greaterThanOrEqual',
                formulae: [0],
                showErrorMessage: true,
                error: 'Введите сумму',
            };
        default:
            return null;
    }
}

function fillWorkbook(workbook: ExcelJS.Workbook, state: StoreState) {
    const { userDictionaries } = getBudgetPlanningPageState(state).pageData;
    const users = getAllUsers(state);

    addDataWorksheet(workbook);

    Object.keys(userDictionaries.byType).forEach((dictionaryType: DictionaryType) => {
        const activeDictionaries = userDictionaries.byType[dictionaryType].filter(
            (dictionary) => dictionary.status === DictionaryStatus.ACTIVE,
        );

        if (UsedDictionaryTypes.has(dictionaryType) && activeDictionaries.length) {
            addDictionaryWorksheet(workbook, activeDictionaries, dictionaryType);
        }
    });

    addUsersWorksheet(workbook, users);
}

function getWorkbookName(): string {
    const date = new Date();

    return `Шаблон планирования бюджета_${DatesFormatter.ddMonthyy(date)}_${DatesFormatter.hhmm(date, '.')}`;
}

function processCellFormula(formula: CellFormula, columnsToCellMap: ColumnToCellMap): string {
    const COLUMN_WASNT_PARSED_ERROR = "(maybe column wasn't parsed yet?)";

    switch (formula._type) {
        case 'SumRange':
            const { from, to } = formula;
            const fromDef = columnsToCellMap[from];

            if (!fromDef) {
                console.warn(`Missing fromCell column identifier ${COLUMN_WASNT_PARSED_ERROR}`);
                return '';
            }
            const toDef = columnsToCellMap[to];
            if (!toDef) {
                console.warn(`Missing toCell column identifier ${COLUMN_WASNT_PARSED_ERROR}`);
                return '';
            }

            return `SUM(${fromDef}:${toDef})`;
        case 'SumMany':
            const { args } = formula;

            const argsVals = args.map((arg) => processCellFormula(arg, columnsToCellMap));

            return argsVals.join(' + ');

        case 'CellValue':
            const { cell } = formula;

            const cellDef = columnsToCellMap[cell];
            if (!cellDef) {
                console.warn(`Missing cell column identifier ${COLUMN_WASNT_PARSED_ERROR}`);
                return '';
            }

            return cellDef;
        default:
            console.warn(`Unknown formula definition: ${JSON.stringify(formula)}`);
            return '';
    }
}

export const downloadXLSXTemplate = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.downloadXSLSXTemplate,
    async (_, dispatch, getState) => {
        const workbook = new ExcelJS.Workbook();

        fillWorkbook(workbook, getState());

        await Utils.downloadAsXLSX((await workbook.xlsx.writeBuffer()) as Buffer, getWorkbookName());
    },
);
