import * as React from 'react';

import { Table } from './Table';
import * as styles from './styles.scss';

interface Props {
    title: string;
    items: string[];
}

export const List: React.FC<Props> = ({ title, items }) => {
    return (
        <div className={styles.root}>
            <div className={styles.title}>
                {title} {items.length}
            </div>
            <Table items={items} />
        </div>
    );
};
