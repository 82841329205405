import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StyledPopup, StyledPopupTheme } from 'sber-marketing-ui';
import classnames from 'classnames';

import { StoreState } from '@store';
import { ComponentState, getProfileMenuState, closeProfileMenu, loadProfileData } from '@store/profileMenu';
import {
    getProfileMenuVacationState,
    setDeleteVacationPopupVisibility,
    deleteUserVacation,
} from '@store/profileMenu/vacation';

import { Menu } from './Menu';
import { VacationEditor } from './VacationEditor';
import { NotificationEditor } from './NotificationEditor';

import * as styles from './ProfileMenu.scss';

function useInteractivity() {
    const dispatch = useDispatch();

    const componentState = useSelector((state: StoreState) => getProfileMenuState(state).componentState);
    const isDeleteVacationPopupVisible = useSelector(
        (state: StoreState) => getProfileMenuVacationState(state).isDeleteVacationPopupVisible,
    );

    function onMaskClick() {
        dispatch(closeProfileMenu());
    }

    React.useEffect(() => dispatch(loadProfileData(null)), []);

    return { componentState, isDeleteVacationPopupVisible, onMaskClick };
}

export function ProfileMenu(): JSX.Element {
    const { componentState, isDeleteVacationPopupVisible, onMaskClick } = useInteractivity();

    let content: JSX.Element;
    switch (componentState) {
        case ComponentState.Menu:
            content = <Menu />;
            break;
        case ComponentState.VacationEditor:
            content = <VacationEditor />;
            break;
        case ComponentState.NotificationEditor:
            content = <NotificationEditor />;
            break;
        case ComponentState.Closed:
        default:
            return null;
    }

    if (content) {
        return (
            <React.Fragment>
                <div
                    className={classnames(
                        styles.root,
                        componentState === ComponentState.NotificationEditor ? styles.notifications : '',
                    )}
                    {...{
                        'qa-id': 'profileMenu',
                    }}
                >
                    {content}
                </div>

                <div className={styles.mask} onClick={onMaskClick} />

                {isDeleteVacationPopupVisible && <DeleteVacationPopup />}
            </React.Fragment>
        );
    }

    return null;
}

function useDeleteVacationPopupInteractivity() {
    const dispatch = useDispatch();

    function closePopup() {
        dispatch(setDeleteVacationPopupVisibility(false));
    }

    function onConfirmButtonClick() {
        closePopup();
        dispatch(deleteUserVacation(null));
    }

    return { onConfirmButtonClick, closePopup };
}

function DeleteVacationPopup(): JSX.Element {
    const { onConfirmButtonClick, closePopup } = useDeleteVacationPopupInteractivity();

    return (
        <StyledPopup
            theme={StyledPopupTheme.Redesign}
            title="Вы действительно хотите отменить ваш отпуск?"
            qaId="profileMenuVacationEditorDeleteVacationPopup"
            firstButtonText="Да, отменить отпуск"
            firstButtonQaId="profileMenuVacationEditorDeleteVacationPopupConfirmButton"
            firstButtonHandler={onConfirmButtonClick}
            secondButtonText="Отмена"
            secondButtonQaId="profileMenuVacationEditorDeleteVacationPopupCancelButton"
            secondButtonHandler={closePopup}
        />
    );
}
