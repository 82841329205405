// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-Sidebar-widgets-Files-FileGroupByTable-FileGroupByRow-_FileGroupByRow__titleContainer{display:flex;align-items:center;padding:0 24px}.src-client-modules-activity-pages-creative-Sidebar-widgets-Files-FileGroupByTable-FileGroupByRow-_FileGroupByRow__title{display:flex;align-items:baseline;gap:6px;padding-right:10px;margin-top:7px;font-family:'Open Sans';font-weight:600;font-size:13px;line-height:18px;color:#7E8681}.src-client-modules-activity-pages-creative-Sidebar-widgets-Files-FileGroupByTable-FileGroupByRow-_FileGroupByRow__divider{margin-top:7px;flex-grow:1;width:auto;height:1px;background-color:#7e868154}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/Sidebar/widgets/Files/FileGroupByTable/FileGroupByRow/FileGroupByRow.scss"],"names":[],"mappings":"AAAA,kIACI,YAAa,CACb,kBAAmB,CACnB,cAAe,CAClB,yHAEG,YAAa,CACb,oBAAqB,CAErB,OAAQ,CACR,kBAAmB,CACnB,cAAe,CACf,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,aAAc,CACjB,2HAEG,cAAe,CACf,WAAY,CACZ,UAAW,CACX,UAAW,CACX,0BAA2B","sourcesContent":[".titleContainer {\n    display: flex;\n    align-items: center;\n    padding: 0 24px;\n}\n.title {\n    display: flex;\n    align-items: baseline;\n    \n    gap: 6px;\n    padding-right: 10px;\n    margin-top: 7px;\n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 13px;\n    line-height: 18px;\n    color: #7E8681;\n}\n.divider {\n    margin-top: 7px;\n    flex-grow: 1;\n    width: auto;\n    height: 1px;\n    background-color: #7e868154;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleContainer": "src-client-modules-activity-pages-creative-Sidebar-widgets-Files-FileGroupByTable-FileGroupByRow-_FileGroupByRow__titleContainer",
	"title": "src-client-modules-activity-pages-creative-Sidebar-widgets-Files-FileGroupByTable-FileGroupByRow-_FileGroupByRow__title",
	"divider": "src-client-modules-activity-pages-creative-Sidebar-widgets-Files-FileGroupByTable-FileGroupByRow-_FileGroupByRow__divider"
};
export default ___CSS_LOADER_EXPORT___;
