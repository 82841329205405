// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-TagsList-FiltersDropdown-Content-_styles__root{background:#FFFFFF;box-shadow:0 2px 6px rgba(0,0,0,0.1);min-width:320px;border-radius:6px;overflow:hidden}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetPlanning/FiltersColumnsTagsBar/TagsList/FiltersDropdown/Content/styles.scss"],"names":[],"mappings":"AAAA,0HACI,kBAAmB,CACnB,oCAAwC,CACxC,eAAgB,CAChB,iBAAkB,CAClB,eAAgB","sourcesContent":[".root {\n    background: #FFFFFF;\n    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);\n    min-width: 320px;\n    border-radius: 6px;\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetPlanning-FiltersColumnsTagsBar-TagsList-FiltersDropdown-Content-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
