import * as React from 'react';

import * as style from './FileDeleteConfirm.scss';

import {
    Icon,
    IconType,
    BodyPortal,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
} from 'sber-marketing-ui';

interface Props {
    fileName: string;
    onConfirmButtonClick: () => void;
    onCancelButtonClick: () => void;
}

export function FileDeleteConfirm({ fileName, onConfirmButtonClick, onCancelButtonClick }: Props) {
    return (
        <div className={style.root}>
            <div className={style.title}>Удалить файл?</div>

            <div className={style.text}>
                Подтвердите удаление файла&nbsp;
                <span className={style.fileName}>{fileName}</span>
            </div>

            <div className={style.buttons}>
                <div className={style.button}>
                    <Button theme={ButtonTheme.GhostRounded} onClick={onCancelButtonClick}>
                        Отменить
                    </Button>
                </div>

                <div className={style.button}>
                    <Button theme={ButtonTheme.MainRounded} onClick={onConfirmButtonClick}>
                        Удалить
                    </Button>
                </div>
            </div>

            <div className={style.closeButton} onClick={onCancelButtonClick}>
                <div className={style.closeIcon}>
                    <Icon type={IconType.CLOSE_SEMIBOLD} svgSize={16} />
                </div>
            </div>

            <BodyPortal>
                <div className={style.mask} />
            </BodyPortal>
        </div>
    );
}
