import * as React from 'react';
import * as lodash from 'lodash';
import { ActivityParams } from 'sber-marketing-types/frontend';
import {
    Input_redesign as Input,
    InputTheme_redesign as InputTheme,
    CustomScrollbar_new as CustomScrollbar,
    StaticSkeleton,
    Icon,
    IconType,
} from 'sber-marketing-ui';
import * as styles from './ActivityNameDropdown.scss';

import { ActivityApi } from '@api';

interface Props {
    selectedActivityId: number;
    selectActivity: (activityId: number) => void;
    ignoreActivity?: (activity: ActivityParams) => void;
}

interface ActivitiesByNameState {
    activities: ActivityParams[];
    query: string;
    isRequestInProgress: boolean;
}

export function ActivityNameDropdown({ selectedActivityId, selectActivity, ignoreActivity }: Props): JSX.Element {
    const [isDropdownOpened, setIsDropdownOpened] = React.useState(false);
    const [inputValue, setInputValue] = React.useState('');
    const [activitiesByNameState, setActivitiesByNameState] = React.useState<ActivitiesByNameState>({
        activities: [],
        query: '',
        isRequestInProgress: false,
    });

    const setInputValueDebounced = lodash.debounce(setInputValue, 500);

    function onActivitySelected(activityId: number) {
        const updSelectedActivityId = selectedActivityId === activityId ? null : activityId;

        setIsDropdownOpened(false);
        selectActivity(updSelectedActivityId);
    }

    React.useEffect(
        function updateActivitiesByName() {
            let shouldUseRequest = true;

            async function worker() {
                const shouldPerformUpdate =
                    inputValue && isDropdownOpened && inputValue !== activitiesByNameState.query;

                if (shouldPerformUpdate) {
                    setActivitiesByNameState((state) => ({ ...state, isRequestInProgress: true }));
                    const activities = await ActivityApi.getUserActivities({ name: inputValue });
                    const filteredActivities = ignoreActivity ? activities.filter(ignoreActivity) : activities;

                    setActivitiesByNameState((state) => {
                        if (shouldUseRequest) {
                            return {
                                activities: filteredActivities,
                                isRequestInProgress: false,
                                query: inputValue,
                            };
                        } else {
                            return { ...state, isRequestInProgress: false };
                        }
                    });
                }
            }

            worker();

            return function updateActivitiesByNameReset() {
                shouldUseRequest = false;
            };
        },
        [inputValue, isDropdownOpened],
    );

    React.useEffect(function onMounted() {
        async function worker() {
            if (selectedActivityId) {
                const activity = await ActivityApi.getActivity(selectedActivityId);

                if (!activity) {
                    console.warn(`Missing activity with id ${selectedActivityId}`);
                } else {
                    setInputValue(activity.name);
                    setActivitiesByNameState({
                        activities: [activity],
                        isRequestInProgress: false,
                        query: activity.name,
                    });
                }
            }
        }

        worker();
    }, []);

    const renderDropdown =
        isDropdownOpened &&
        (activitiesByNameState.isRequestInProgress ||
            (!activitiesByNameState.isRequestInProgress && !!activitiesByNameState.activities.length));

    return (
        <div className={styles.root}>
            <Input
                placeholder="Название проекта"
                value={inputValue}
                theme={InputTheme.Simple}
                className={styles.input}
                onInputChange={setInputValueDebounced}
                onFocus={() => setIsDropdownOpened(true)}
                onBlur={() => setTimeout(() => setIsDropdownOpened(false), 200)}
            />

            {renderDropdown && (
                <div className={styles.dropdown}>
                    <CustomScrollbar
                        maxHeight={330}
                        minHeight={32}
                        freezeScrollX
                        hideScrollX
                        hideScrollY={activitiesByNameState.isRequestInProgress}
                    >
                        {activitiesByNameState.isRequestInProgress ? (
                            <StaticSkeleton className={styles.preloader} />
                        ) : (
                            activitiesByNameState.activities.map((activity) => (
                                <Activity
                                    key={activity.id}
                                    activity={activity}
                                    selectedActivityId={selectedActivityId}
                                    selectActivity={onActivitySelected}
                                />
                            ))
                        )}
                    </CustomScrollbar>
                </div>
            )}
        </div>
    );
}

interface ActivityProps {
    activity: ActivityParams;
    selectedActivityId: number;
    selectActivity: (activityId: number) => void;
}

function Activity({ activity, selectedActivityId, selectActivity }: ActivityProps): JSX.Element {
    return (
        <div onClick={() => selectActivity(activity.id)} className={styles.activity}>
            {selectedActivityId === activity.id && (
                <Icon type={IconType.CHECK_ICON} svgSize={10} className={styles.activitySelectedIcon} />
            )}

            {activity.name}
        </div>
    );
}
