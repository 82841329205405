import * as React from 'react';
import * as lodash from 'lodash';

import * as style from './NavItemList.scss';

import type { NavItem as NavItemParams } from '../types';

import { NavItem } from './NavItem';
import { NavItemDropdown } from './NavItemDropdown';

interface Props {
    navItems: NavItemParams[];
    nestingLevel?: number;
}

export const NavItemList: React.FC<Props> = ({ navItems, nestingLevel }) => {
    const isStartNestingLevel = lodash.isNil(nestingLevel);

    return (
        <div
            className={!isStartNestingLevel ? style.root : ''}
            {...{
                'qa-id': 'briefNavItemList',
            }}
        >
            {navItems.map((navItem) => {
                const isNavItemHaveChildren = Boolean(navItem.childrenNavItems);

                return isNavItemHaveChildren ? (
                    <ParentItem
                        id={navItem.id}
                        key={navItem.id}
                        title={navItem.title}
                        childrenNavItems={navItem.childrenNavItems}
                        isSelected={navItem.isSelected}
                        nestingLevel={nestingLevel}
                        onClick={navItem.onClick}
                    />
                ) : (
                    <ChildrenItem
                        id={navItem.id}
                        key={navItem.id}
                        title={navItem.title}
                        isSelected={navItem.isSelected}
                        nestingLevel={nestingLevel}
                        onClick={navItem.onClick}
                    />
                );
            })}
        </div>
    );
};

interface ParentItemProps extends NavItemParams {
    nestingLevel: number;
}

const ParentItem: React.FC<ParentItemProps> = ({ title, isSelected, childrenNavItems, nestingLevel, onClick }) => {
    const isStartNestingLevel = lodash.isNil(nestingLevel);
    const updatedNestingLevel = isStartNestingLevel ? 0 : nestingLevel + 1;
    const haveNavItems = !childrenNavItems.length;

    return (
        <div
            className={style.navItemDropdownWrapper}
            {...{
                'qa-id': 'briefNavItemListParentItem',
            }}
        >
            {isStartNestingLevel ? (
                <div>
                    <NavItemDropdown title={title} isOpen={isSelected} onClick={onClick} disabled={haveNavItems}>
                        <NavItemList navItems={childrenNavItems} nestingLevel={updatedNestingLevel} />
                    </NavItemDropdown>
                </div>
            ) : (
                <div>
                    <NavItem title={title} onClick={onClick} isSelected={isSelected} />
                    <NavItemList navItems={childrenNavItems} nestingLevel={nestingLevel} />
                </div>
            )}
        </div>
    );
};

const ChildrenItem: React.FC<NavItemParams> = ({ id, title, isSelected, onClick }) => {
    return (
        <div className={style.childrenNavItem}>
            <NavItem title={title} isSelected={isSelected} onClick={onClick} />
        </div>
    );
};
