import * as React from 'react';
import classNames from 'classnames';
import * as lodash from 'lodash';

import * as style from './BudgetCard.scss';

import { BudgetItem, CreateBudgetItemForm } from '@mrm/budget';
import { DictionaryType } from '@mrm/dictionary';
import { GroupedDictionaries } from '@store/executionBudgetEdit/types';
import { MultiReferenceDictionaryApi } from '@api';

import { FormField, DynamicIcon, Icon, IconType, HeightAnimation } from 'sber-marketing-ui';
import { BudgetForm } from '../BudgetForm';
import { ActivitySuggest } from '../ActivitySuggest';
import { Money, MoneyFormatter, Utils } from '@common/Utils';

interface Props {
    index: number;
    hovered: boolean;
    id: string;
    budgetId: string;
    fields: FormField[];
    collapsed: boolean;
    isNew: boolean;
    budgetItem: BudgetItem;
    currentBudgetItem: CreateBudgetItemForm;
    dictionaries: GroupedDictionaries;
    showActivitySuggest: boolean;
    isMarkedForTransfer: boolean;
    canBeDeleted: boolean;
    tagsEditorId: string;
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi;
    onCopyButtonClick: () => void;
    onDeleteButtonClick: () => void;
    onCollapseButtonClick: () => void;
    onSuggestItemClick: (activityBudgetId: string, activityBudgetName: string) => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}

export const BudgetCard = ({
    hovered,
    id,
    budgetId,
    index,
    fields,
    collapsed,
    isNew,
    budgetItem,
    currentBudgetItem,
    dictionaries,
    showActivitySuggest,
    isMarkedForTransfer,
    canBeDeleted,
    tagsEditorId,
    multiReferenceDictionaryApi,
    onCopyButtonClick,
    onDeleteButtonClick,
    onCollapseButtonClick,
    onSuggestItemClick,
    onMouseEnter,
    onMouseLeave,
}: Props): JSX.Element => {
    const { sapComment } = currentBudgetItem;

    const qaProps: any = {
        'qa-id': 'budgetItemRow',
        'qa-row-id': id,
        'qa-row-index': index,
    };

    return (
        <div
            className={classNames(style.root, collapsed && style.collapsed, isNew && style.newCard)}
            onClick={collapsed ? onCollapseButtonClick : null}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            {...qaProps}
        >
            <HeightAnimation>
                <div className={style.topLine}>
                    <div className={style.titleIcon}>
                        <Icon type={IconType.ARROW_ROUNDED} svgSize={8} />
                    </div>

                    <div className={style.title}>
                        {collapsed ? sapComment || 'Название проекта (SAP): Не указано' : 'Строка бюджета'}
                    </div>

                    {!collapsed && <div className={style.collapseButton} onClick={onCollapseButtonClick} />}

                    <div className={style.buttons}>
                        <div
                            className={classNames(style.button, style.copyButton)}
                            title={'Копировать строку'}
                            onClick={onCopyButtonClick}
                        >
                            <DynamicIcon
                                common={{ svgSize: 24 }}
                                normal={{ type: IconType.COPY }}
                                hover={{ type: IconType.COPY_HOVER }}
                            />
                        </div>

                        {canBeDeleted && (
                            <div
                                className={classNames(style.button, style.deleteButton)}
                                title={'Удалить'}
                                onClick={onDeleteButtonClick}
                            >
                                <Icon type={IconType.DELETE_V2} svgSize={24} />
                            </div>
                        )}
                    </div>
                </div>

                {collapsed ? (
                    renderCard()
                ) : (
                    <div className={style.form}>
                        <BudgetForm
                            id={id}
                            fields={fields}
                            isNew={isNew}
                            tagsEditorId={tagsEditorId}
                            multiReferenceDictionaryApi={multiReferenceDictionaryApi}
                        />

                        {isMarkedForTransfer && (
                            <div className={style.transferMessage}>
                                <div className={style.transferMessageIcon}>
                                    <Icon type={IconType.EXPIRED_TASK} svgSize={12} />
                                </div>
                                Cтрока будет перенесена в новую активность
                            </div>
                        )}

                        {showActivitySuggest && (
                            <div className={style.activitySuggest}>
                                <ActivitySuggest
                                    budgetId={budgetId}
                                    budgetItemId={id}
                                    onItemClick={onSuggestItemClick}
                                />
                            </div>
                        )}
                    </div>
                )}
            </HeightAnimation>
        </div>
    );

    function renderCard() {
        const direction = getDictionaryValue(currentBudgetItem, dictionaries, DictionaryType.Direction);
        const tool = getDictionaryValue(currentBudgetItem, dictionaries, DictionaryType.Tool);

        const totalBudget = budgetItem
            ? Object.keys(budgetItem.plannedFunds).reduce((acc, item) => acc + budgetItem.plannedFunds[item], 0)
            : 0;

        return (
            <div className={style.properties}>
                <div className={style.property}>Направление: {direction || 'Не выбрано'}</div>

                <div className={style.property}>Инструмент: {tool || 'Не выбран'}</div>

                <div className={style.property}>Итоговая сумма: {formatFunds(totalBudget) || 'Не указана'}</div>
            </div>
        );
    }
};

function getDictionaryValue(
    budgetItem: CreateBudgetItemForm,
    dictionaries: GroupedDictionaries,
    dictionaryType: DictionaryType,
): string {
    if (!budgetItem) {
        return null;
    }

    const dictionaryItems = dictionaries.byType[dictionaryType] || [];

    const foundDictionary = dictionaryItems.find((item) => lodash.includes(budgetItem.dictionaryIds, item.id));

    return Utils.getDictionaryValue(foundDictionary);
}

function formatFunds(value: number): string {
    return MoneyFormatter.toRoubles(Money.fromCopecks(value));
}
