import * as React from 'react';

import { CommentsBlock } from '@common/CommentsBlock';

import { CommentsWidgetConnected } from './CommentsWidgetConnected';

interface Props {
    lineId: string;
}

export const CommentsWidget: React.FC<Props> = ({ lineId }) => {
    return (
        <CommentsWidgetConnected budgetItemId={lineId}>
            {({ comments, createComment, loading }) => (
                <CommentsBlock
                    comments={comments}
                    loading={loading}
                    createComment={createComment}
                    elKey={lineId}
                    disableControls={false}
                />
            )}
        </CommentsWidgetConnected>
    );
};
