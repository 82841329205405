import { flatMap, includes, uniq, isNil } from 'lodash';

import { StatisticsItem, Department, DepartmentResponse, UserResponse, StatisticsResponse, Filters } from '../types';

interface BuilderStatisticsItem {
    (params: Params): StatisticsItem[];
}

interface Params {
    statistics: StatisticsResponse;
    departments: DepartmentResponse[];
    users: UserResponse[];
    filters?: Filters;
}

interface GroupUsers {
    id: string;
    name: string;
    parentDepartmentId: string;
    childrenUsersIds: string[];
}

export const buildStatisticsItems: BuilderStatisticsItem = ({ statistics, departments, users, filters }) => {
    const departmentsWithChildrenDepartmentsIds = getDepartmentsWitchChildrenDepartmentsIds(departments);
    const groupsUsers = getGroupsUsers(users, departmentsWithChildrenDepartmentsIds, statistics);

    const usersStatisticsItems = convertStatisticsToUsersStatisticsItems(statistics, { users, groupsUsers });

    const groupUserStatisticsItems = buildGroupsUsersStatisticsItems(usersStatisticsItems, {
        statistics,
        groupsUsers,
        filters,
    });

    const departmentsStatisticsItems = buildDepartmentsStatisticsItems(
        [...usersStatisticsItems, ...groupUserStatisticsItems],
        { departments: departmentsWithChildrenDepartmentsIds, filters },
    );

    return [...departmentsStatisticsItems, ...usersStatisticsItems, ...groupUserStatisticsItems];
};

const buildDepartmentsStatisticsItems = (
    statisticsItems: StatisticsItem[],
    params: {
        departments: Department[];
        filters: Filters;
    },
): StatisticsItem[] => {
    return flatMap(
        params.departments
            .filter((department) => isNil(department.parentDepartmentId))
            .map((department) =>
                buildDepartmentStatisticsItem(department, {
                    statisticsItems,
                    departments: params.departments,
                    filters: params.filters,
                }),
            ),
    );
};

const buildDepartmentStatisticsItem = (
    department: Department,
    params: {
        departments: Department[];
        statisticsItems: StatisticsItem[];
        filters: Filters;
    },
): StatisticsItem[] => {
    const descendantsDepartmentsStatisticsItems = department.childrenDepartmentsIds.length
        ? flatMap(
              department.childrenDepartmentsIds.map((childDepartmentId) => {
                  const childDepartment = params.departments.find(({ id }) => id === childDepartmentId);
                  return buildDepartmentStatisticsItem(childDepartment, params);
              }),
          )
        : [];

    const childrenStatisticsItems = [
        ...params.statisticsItems.filter(({ meta }) => meta.parentId === department.id),
        ...descendantsDepartmentsStatisticsItems.filter(({ meta }) => meta.parentId === department.id),
    ];

    return [
        ...descendantsDepartmentsStatisticsItems,
        {
            id: department.id,
            current: childrenStatisticsItems
                .filter((childrenStatisticsItem) => getFilterValue(childrenStatisticsItem.id, params.filters))
                .map((statisticsItem) => statisticsItem.current)
                .reduce(
                    (prev, current) => ({
                        activeCount: prev.activeCount + current.activeCount,
                        totalCount: prev.totalCount + current.totalCount,
                        closedCount: prev.closedCount + current.closedCount,
                        overdueCount: prev.overdueCount + current.overdueCount,
                        withDeadlineApproachingCount:
                            prev.withDeadlineApproachingCount + current.withDeadlineApproachingCount,
                    }),
                    {
                        activeCount: 0,
                        totalCount: 0,
                        closedCount: 0,
                        overdueCount: 0,
                        withDeadlineApproachingCount: 0,
                    },
                ),
            past: childrenStatisticsItems
                .filter((childrenStatisticsItem) => getFilterValue(childrenStatisticsItem.id, params.filters))
                .map((statisticsItem) => statisticsItem.past)
                .reduce(
                    (prev, current) => ({
                        activeCount: prev.activeCount + current.activeCount,
                        totalCount: prev.totalCount + current.totalCount,
                        closedCount: prev.closedCount + current.closedCount,
                        overdueCount: prev.overdueCount + current.overdueCount,
                        withDeadlineApproachingCount:
                            prev.withDeadlineApproachingCount + current.withDeadlineApproachingCount,
                    }),
                    {
                        activeCount: 0,
                        totalCount: 0,
                        closedCount: 0,
                        overdueCount: 0,
                        withDeadlineApproachingCount: 0,
                    },
                ),
            meta: {
                name: department.name,
                type: 'department',
                parentId: department.parentDepartmentId,
                childrenIds: childrenStatisticsItems.map(({ id }) => id),
            },
        },
    ];
};

const convertStatisticsToUsersStatisticsItems = (
    statistics: StatisticsResponse,
    params: {
        users: UserResponse[];
        groupsUsers: GroupUsers[];
    },
): StatisticsItem[] => {
    const usersIdsFromStatistics = getUsersIdsFromStatistics(statistics);

    return usersIdsFromStatistics.map((userId) => {
        const user = params.users.find(({ id }) => id === userId);

        const groupUser = params.groupsUsers.find(({ childrenUsersIds }) => includes(childrenUsersIds, String(userId)));

        return {
            id: String(userId),
            current: statistics.current
                .filter(({ user }) => user === userId)
                .reduce(
                    (prev, current) => ({
                        activeCount: prev.activeCount + current.activeCount,
                        totalCount: prev.totalCount + current.totalCount,
                        closedCount: prev.closedCount + current.closedCount,
                        overdueCount: prev.overdueCount + current.overdueCount,
                        withDeadlineApproachingCount:
                            prev.withDeadlineApproachingCount + current.withDeadlineApproachingCount,
                    }),
                    {
                        activeCount: 0,
                        totalCount: 0,
                        closedCount: 0,
                        overdueCount: 0,
                        withDeadlineApproachingCount: 0,
                    },
                ),
            past: statistics.past
                .filter(({ user }) => user === userId)
                .reduce(
                    (prev, current) => ({
                        activeCount: prev.activeCount + current.activeCount,
                        totalCount: prev.totalCount + current.totalCount,
                        closedCount: prev.closedCount + current.closedCount,
                        overdueCount: prev.overdueCount + current.overdueCount,
                        withDeadlineApproachingCount:
                            prev.withDeadlineApproachingCount + current.withDeadlineApproachingCount,
                    }),
                    {
                        activeCount: 0,
                        totalCount: 0,
                        closedCount: 0,
                        overdueCount: 0,
                        withDeadlineApproachingCount: 0,
                    },
                ),
            meta: {
                name: `${user.secondName} ${user.firstName}`,
                type: 'user',
                parentId: groupUser ? groupUser.id : user.departmentId,
                childrenIds: [],
            },
        };
    });
};

export const buildGroupsUsersStatisticsItems = (
    statisticsItems: StatisticsItem[],
    params: {
        groupsUsers: GroupUsers[];
        statistics: StatisticsResponse;
        filters: Filters;
    },
): StatisticsItem[] => {
    return params.groupsUsers.map((groupUsers) => {
        const childrenStatisticsItems = statisticsItems.filter((statisticItem) =>
            includes(groupUsers.childrenUsersIds, statisticItem.id),
        );

        return {
            id: groupUsers.id,
            current: childrenStatisticsItems
                .filter((statisticsItem) => getFilterValue(statisticsItem.id, params.filters))
                .map((statisticsItem) => statisticsItem.current)
                .reduce(
                    (prev, current) => ({
                        activeCount: prev.activeCount + current.activeCount,
                        totalCount: prev.totalCount + current.totalCount,
                        closedCount: prev.closedCount + current.closedCount,
                        overdueCount: prev.overdueCount + current.overdueCount,
                        withDeadlineApproachingCount:
                            prev.withDeadlineApproachingCount + current.withDeadlineApproachingCount,
                    }),
                    {
                        activeCount: 0,
                        totalCount: 0,
                        closedCount: 0,
                        overdueCount: 0,
                        withDeadlineApproachingCount: 0,
                    },
                ),
            past: childrenStatisticsItems
                .filter((statisticsItem) => getFilterValue(statisticsItem.id, params.filters))
                .map((statisticsItem) => statisticsItem.past)
                .reduce(
                    (prev, current) => ({
                        activeCount: prev.activeCount + current.activeCount,
                        totalCount: prev.totalCount + current.totalCount,
                        closedCount: prev.closedCount + current.closedCount,
                        overdueCount: prev.overdueCount + current.overdueCount,
                        withDeadlineApproachingCount:
                            prev.withDeadlineApproachingCount + current.withDeadlineApproachingCount,
                    }),
                    {
                        activeCount: 0,
                        totalCount: 0,
                        closedCount: 0,
                        overdueCount: 0,
                        withDeadlineApproachingCount: 0,
                    },
                ),
            meta: {
                name: groupUsers.name,
                type: 'usersGroup',
                parentId: groupUsers.parentDepartmentId,
                childrenIds: groupUsers.childrenUsersIds,
            },
        };
    });
};

const getDescendantsDepartmentIds = (department: Department, departments: Department[]): string[] => {
    const haveChildrenDepartmentsIds = Boolean(department.childrenDepartmentsIds.length);

    if (haveChildrenDepartmentsIds) {
        const childrenDepartments = departments.filter(({ id }) => includes(department.childrenDepartmentsIds, id));
        return [
            ...department.childrenDepartmentsIds,
            ...flatMap(
                childrenDepartments.map((childDepartment) => getDescendantsDepartmentIds(childDepartment, departments)),
            ),
        ];
    }

    return [];
};

const getDepartmentsWitchChildrenDepartmentsIds = (departments: DepartmentResponse[]): Department[] => {
    return departments.map((department) => ({
        ...department,
        childrenDepartmentsIds: departments
            .filter(({ parentDepartmentId }) => parentDepartmentId === department.id)
            .map(({ id }) => id),
    }));
};

const getDepartmentUsersIdsFromStatistics = ({ id }: Department, statistics: StatisticsResponse): string[] => {
    return uniq([
        ...statistics.current.filter(({ department }) => department === id).map(({ user }) => String(user)),
        ...statistics.past.filter(({ department }) => department === id).map(({ user }) => String(user)),
    ]);
};

const getUsersIdsFromStatistics = (statistics: StatisticsResponse): number[] => {
    return uniq([...statistics.current.map(({ user }) => user), ...statistics.past.map(({ user }) => user)]);
};

const getGroupsUsers = (
    users: UserResponse[],
    departments: Department[],
    statistics: StatisticsResponse,
): GroupUsers[] => {
    return departments
        .filter((department) => department.childrenDepartmentsIds.length)
        .map((department) => ({
            id: `group-users-${department.id}`,
            name: `${department.name}`,
            parentDepartmentId: department.id,
            childrenUsersIds: getDepartmentUsersIdsFromStatistics(department, statistics),
        }));
};

const getFilterValue = (id: string, filters: Filters): boolean => {
    return filters && !isNil(filters[id]) ? filters[id] : true;
};
