import type { CreativeRequestParamDomain } from '../types';
import { Handlers } from '../converters';

import { subscribeCreativeRequestParamDomain } from './subscribeCreativeRequestParamDomain';

interface Subscriber {
    (domain: CreativeRequestParamDomain[], params: { handlers: Handlers }): void;
}

export const subscribeCreativeRequestParamDomains: Subscriber = (domains, params) => {
    domains.forEach((domain) => subscribeCreativeRequestParamDomain(domain, params));
};
