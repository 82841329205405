import createCashedSelector from 're-reselect';
import { WorkTypeParams, TaskCardParams } from 'sber-marketing-types/frontend';
import { uniq } from 'lodash';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';

import { WorkTypesState, StoreTypes } from './types';

const getWorkTypesState = (state: StoreState): WorkTypesState => state.workTypes;

export const getFetchersCount = createCashedSelector(
    getWorkTypesState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: WorkTypesState, store: StoreTypes): number => state.stores[store].fetchersCount,
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getWorkTypeById = createCashedSelector(
    getWorkTypesState,
    (state: StoreState, id: string): string => id,
    (state: WorkTypesState, id: string): WorkTypeParams => state.byIds[id],
)((state: StoreState, id: string): string => id);

export const getWorkTypes = createCashedSelector(
    getWorkTypesState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: WorkTypesState, store: StoreTypes): WorkTypeParams[] => {
        const entitiesStore = state.stores[store];

        if (entitiesStore.loadingStatus === LoadingStatus.LOADED) {
            return state.stores[store].ids.map((id) => state.byIds[id]);
        }

        return [];
    },
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getWorkTypeIds = createCashedSelector(
    getWorkTypesState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: WorkTypesState, store: StoreTypes): string[] => state.stores[store].ids,
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getLoadingStatus = createCashedSelector(
    getWorkTypesState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: WorkTypesState, store: StoreTypes): LoadingStatus => state.stores[store].loadingStatus,
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getTasksWorkTypes = createCashedSelector(
    getWorkTypesState,
    (state: StoreState, tasks: TaskCardParams[]) => tasks,
    (state: WorkTypesState, tasks: TaskCardParams[]) =>
        uniq(tasks.map((task) => task.workTypeId).filter((id) => !!id)).map((id) => state.byIds[id]),
)((state: StoreState, tasks: TaskCardParams[]) =>
    uniq(tasks.map((task) => task.workTypeId).filter((id) => !!id)).join(),
);
