import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconType } from 'sber-marketing-ui';

import * as style from './File.scss';

interface Props {
    fileName: string;
}

export function File({ fileName }: Props): JSX.Element {
    return (
        <div className={style.root}>
            <Icon type={IconType.FILE} className={style.icon} />

            <div
                className={classNames([style.fileName, style.colorGreen, style.weightBold])}
                {...{
                    'qa-id': 'dashboardNewsColumnItemFile',
                }}
            >
                {fileName}
            </div>
        </div>
    );
}
