import * as React from 'react';
import { BudgetItemSnapshot } from '@mrm/budget';

import { shouldRender as shouldRenderTitleRow, TitleRow, TitleRowSkeleton } from './TitleRow';
import { shouldRender as shouldRenderContent, Content, ContentSkeleton } from './Content';

import * as styles from './HistoryItem.scss';

interface Props {
    prev: BudgetItemSnapshot;
    curr: BudgetItemSnapshot;
}

export function HistoryItem(props: Props): JSX.Element {
    const diffs = Object.keys(props.curr).filter(
        (key) => JSON.stringify(props.prev[key]) !== JSON.stringify(props.curr[key]),
    );

    const shouldRender = shouldRenderTitleRow(diffs) || shouldRenderContent(diffs, props.curr);

    return shouldRender ? (
        <div className={styles.root}>
            <TitleRow {...props} diffs={diffs} />
            <Content {...props} diffs={diffs} />
        </div>
    ) : null;
}

export function HistoryItemSkeleton(): JSX.Element {
    return (
        <div className={styles.root}>
            <TitleRowSkeleton />
            <ContentSkeleton />
        </div>
    );
}
