import createCashedSelector from 're-reselect';
import { Tag } from '@mrm/tags';

import { StoreState } from '@store';

import { TagsState as State } from './types';

export const getTagsState = (state: StoreState): State => state.tags;

export const getTagById = createCashedSelector(
    getTagsState,
    (store: StoreState, id: string) => id,
    (state: State, id: string): Tag => state.byId.dictionary[id],
)((store: StoreState, id: string) => id);

export const getTagByTitle = createCashedSelector(
    getTagsState,
    (store: StoreState, title: string) => title,
    (state: State, title: string): Tag => state.byTitle.dictionary[title],
)((store: StoreState, title: string) => title);
