import * as React from 'react';
import { get, uniq } from 'lodash';
import { graphql } from 'react-apollo';

import type { LinkedBudgetItem } from './types';

import { GET_BUDGET_ITEM_LINKS_QUERY } from './query';

interface ChildrenProps {
    loading: boolean;
    items: LinkedBudgetItem[];
    organizationIds: string[];
}

interface Props extends ExternalProps, QueryProps {}

interface ExternalProps {
    userOrganizationId: string;
    linkIds: string[];
    children: (props: ChildrenProps) => JSX.Element;
}

type QueryProps = {
    budgetItemLinksQuery: {
        loading: boolean;
        links: {
            nodes: LinkedBudgetItem[];
        };
    };
};

class WithLinkedBudgetItemsWrapper extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { children } = this.props;

        return children(this.getChildrenProps());
    }

    private getChildrenProps(): ChildrenProps {
        const { budgetItemLinksQuery, userOrganizationId } = this.props;

        const loading = get(budgetItemLinksQuery, 'loading');
        const items: LinkedBudgetItem[] = get(budgetItemLinksQuery, 'links.nodes') || [];
        const organizationIds = uniq([
            ...items.map((item) => item.budgetItem.author.organizationId),
            userOrganizationId,
        ]);

        return {
            loading,
            items,
            organizationIds,
        };
    }
}

export const WithLinkedBudgetItems = graphql<ExternalProps>(GET_BUDGET_ITEM_LINKS_QUERY, {
    name: 'budgetItemLinksQuery',
    options: ({ linkIds }) => ({
        variables: {
            linkIds,
        },
        fetchPolicy: 'no-cache',
    }),
    skip: ({ linkIds }) => !linkIds.length,
})(WithLinkedBudgetItemsWrapper);
