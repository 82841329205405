import { axios } from '../lib/axios';

const BASE_URL = '/api/department';

/** Departments list filter */
export interface DepartmentListFilter {
    /** Identifiers */
    ids?: string;
    /** User identifiers */
    userIds?: number[];
    /** Sorting order */
    sortingOrder?: string;
    /** Embedded entities */
    embed?: string;
    /** Parent department */
    parentDepartmentId?: string;
    /** Organizations identifiers */
    organizationIds?: string;
}

/** Department signature */
export interface Department {
    /** Identifier */
    id: string;
    /** Name */
    name: string;
    /** Parent identifier */
    parentDepartmentId: string;
    /** Organization identifier */
    organizationId: string;
    /** Date of creation */
    createTime: string | number | Date;
    /** Date of updating */
    updateTime: string | number | Date;
    /** Sub departments */
    subDepartments?: Department[];
}

/** Departments API */
export class DepartmentApi {
    /** Get filtered list of departments */
    public static async getList(filter?: DepartmentListFilter): Promise<Department[]> {
        const { data } = await axios.post<Department[]>(`${BASE_URL}/list`, filter);
        return data;
    }

    public static async getListByParent(id: string): Promise<Department[]> {
        const res = await axios.post(`${BASE_URL}/listbyparent`, { id });
        return res.data;
    }
}
