import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Popup as PopupUI, Icon, IconType, Preloader } from 'sber-marketing-ui';
import { CreationFormContext } from '../../../../../CreationFormContext';
import { Header } from './Header';
import { Content } from './Content';
import { Footer } from './Footer';

import { StoreState } from '@store';
import { getAutopilot } from '@store/autopilot/selectors';
import { save } from '@store/autopilot/generationTextForm';

import { Loader } from '@modules/activity/pages/autopilot/modules';

import * as styles from './styles.scss';

interface Props {
    onClose: () => void;
}

export const Popup: React.FC<Props> = ({ onClose }) => {
    const loaderRef = React.useRef<Loader>(null);

    const dispatch = useDispatch();
    const { close } = React.useContext(CreationFormContext);
    const { id: autopilotId } = useSelector((state: StoreState) => getAutopilot(state));
    const [loading, setLoading] = React.useState(false);

    const onSaveButtonClick = React.useCallback(() => {
        setLoading(true);
        dispatch(save({ autopilotId })).finally(() => {
            loaderRef.current.loadAutopilot();
            setLoading(false);
            close();
        });
    }, [loaderRef.current, autopilotId, close]);

    React.useEffect(() => {
        loaderRef.current = Loader.getInstance();
    }, []);

    return (
        <PopupUI onCloseIconClick={onClose} onOutOfContentClick={onClose}>
            <div className={styles.root}>
                <Header className={styles.header} />
                <Content />
                <Footer className={styles.footer} onSaveButtonClick={onSaveButtonClick} />
                <div className={styles.closeButton} onClick={onClose}>
                    <Icon type={IconType.CROSS} />
                </div>
                {loading && <Preloader />}
            </div>
        </PopupUI>
    );
};
