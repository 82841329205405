// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-comment-components-CommentForm-_CommentForm__root{transition:background .3s, max-height .3s;max-height:48px;overflow:hidden;font-size:24px;color:var(--color-primary-50)}.src-client-modules-comment-components-CommentForm-_CommentForm__active{max-height:346px;background:var(--color-primary-10)}.src-client-modules-comment-components-CommentForm-_CommentForm__attach{margin-left:-104px}.src-client-modules-comment-components-CommentForm-_CommentForm__attach:after{font-size:12px}.src-client-modules-comment-components-CommentForm-_CommentForm__file{position:absolute;left:-9999px;top:-9999px;visibility:hidden}.src-client-modules-comment-components-CommentForm-_CommentForm__files{font-size:12px}.src-client-modules-comment-components-CommentForm-_CommentForm__editHeader{font-size:12px;background-color:var(--color-primary-15);border-bottom:1px solid var(--color-surface-blue)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/comment/components/CommentForm/CommentForm.scss"],"names":[],"mappings":"AAAA,sEACE,yCAA0C,CAC1C,eAAgB,CAChB,eAAgB,CAChB,cAAe,CACf,6BAA8B,CAC/B,wEAGC,gBAAiB,CACjB,kCAAmC,CACpC,wEAGC,kBAAmB,CADrB,8EAII,cAAe,CAChB,sEAID,iBAAkB,CAClB,YAAa,CACb,WAAY,CACZ,iBAAkB,CACnB,uEAGC,cAAe,CAChB,4EAGC,cAAe,CACf,wCAAyC,CACzC,iDAAkD","sourcesContent":[".root {\n  transition: background .3s, max-height .3s;\n  max-height: 48px;\n  overflow: hidden;\n  font-size: 24px;\n  color: var(--color-primary-50);\n}\n\n.active {\n  max-height: 346px;\n  background: var(--color-primary-10);\n}\n\n.attach {\n  margin-left: -104px;\n\n  &:after {\n    font-size: 12px;\n  }\n}\n\n.file {\n  position: absolute;\n  left: -9999px;\n  top: -9999px;\n  visibility: hidden;\n}\n\n.files {\n  font-size: 12px;\n}\n\n.editHeader {\n  font-size: 12px;\n  background-color: var(--color-primary-15);\n  border-bottom: 1px solid var(--color-surface-blue);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-comment-components-CommentForm-_CommentForm__root",
	"active": "src-client-modules-comment-components-CommentForm-_CommentForm__active",
	"attach": "src-client-modules-comment-components-CommentForm-_CommentForm__attach",
	"file": "src-client-modules-comment-components-CommentForm-_CommentForm__file",
	"files": "src-client-modules-comment-components-CommentForm-_CommentForm__files",
	"editHeader": "src-client-modules-comment-components-CommentForm-_CommentForm__editHeader"
};
export default ___CSS_LOADER_EXPORT___;
