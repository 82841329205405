import * as React from 'react';
import classNames from 'classnames';
import { sanitize } from 'dompurify';
import { useSelector } from 'react-redux';
import { Icon, IconType, WithTooltip, AnimatedEllipsis, Skeleton } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getAllUsers } from '@store/appUsers';

import { DatesFormatter, Utils } from '@common/Utils';
import { FileAsset } from '@common/FileAssetRedesign';
import { parseMessage } from '@common/lib/parseMessage';

import * as styles from './Comment.scss';

export interface CommentProps {
    id: string;
    createTime: string;
    text: string;
    author: {
        firstName: string;
        secondName: string;
        departmentName?: string;
        organizationName?: string;
    };
    files?: FileProps[];
}

interface Props extends Partial<CommentProps> {
    isExpanded: boolean;
    pending?: boolean;
    onExpandButtonClick: () => void;
}

export function Comment({
    id,
    author,
    createTime,
    text,
    files,
    isExpanded,
    pending,
    onExpandButtonClick,
}: Props): JSX.Element {
    const appUsers = useSelector((state: StoreState) => getAllUsers(state));

    const filesDeclension = Utils.getDeclensionByNumber(files ? files.length : 0, ['файл', 'файла', 'файлов']);

    const authorToDisplay = author ? `${author.firstName} ${author.secondName}` : 'Автор не задан';
    const organization = author ? author.organizationName : 'Организация не задана';
    const department = author ? author.departmentName : 'Департамент не задан';
    const organizationAndDepartment = organization && department ? `${organization}, ${department}` : '';

    let commentText = parseMessage(text, styles.mention, appUsers);
    // let commentText: JSX.Element | string = text;
    // if (text) {
    //     const splittedByMentionsText = text.split(/\[([@\w+а-яА-Я -]*?)\#\d+\]/g);

    //     if (splittedByMentionsText.length !== 1) {
    //         commentText = splittedByMentionsText.reduce((acc: JSX.Element, part, i) => {
    //             if (part.startsWith('@')) {
    //                 return <React.Fragment>{acc} <span className={styles.mention}>{part}</span></React.Fragment>;
    //             }

    //             if (part) {
    //                 return <React.Fragment>{acc} {part}</React.Fragment>;
    //             }

    //             return acc;
    //         }, null);
    //     }
    // }

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'commentsBlockComment',
            }}
        >
            <div className={styles.title}>
                <Skeleton isReady={author} loadingClassName={styles.titleAuthorLoading}>
                    <div
                        className={styles.titleAuthor}
                        {...{
                            'qa-id': 'commentsBlockCommentAuthor',
                        }}
                    >
                        <Icon type={IconType.USER_ICON} svgSize={16} className={styles.icon} />

                        {authorToDisplay}
                    </div>
                </Skeleton>

                <div className={styles.titleDateTime}>
                    {!pending && (
                        <div
                            className={classNames(styles.titleBlock, styles.date)}
                            {...{
                                'qa-id': 'commentsBlockCommentDate',
                            }}
                        >
                            <Skeleton isReady={createTime} loadingClassName={styles.titleBlockLoading}>
                                <Icon type={IconType.CALENDAR_ICON} svgSize={16} className={styles.icon} />

                                {DatesFormatter.ddMonthyy(createTime)}
                            </Skeleton>
                        </div>
                    )}

                    <div
                        className={styles.titleBlock}
                        {...{
                            'qa-id': 'commentsBlockCommentTime',
                        }}
                    >
                        <Skeleton isReady={createTime} loadingClassName={styles.titleBlockLoading}>
                            <Icon type={IconType.CLOCK_ICON} svgSize={16} className={styles.icon} />

                            {pending ? <AnimatedEllipsis text="Отправка" /> : DatesFormatter.hhmm(createTime)}
                        </Skeleton>
                    </div>
                </div>
            </div>

            <div
                className={styles.subtitle}
                {...{
                    'qa-id': 'commentsBlockCommentOrganizationAndDepartment',
                }}
            >
                <Skeleton isReady={author} loadingClassName={styles.subtitleLoading}>
                    {organizationAndDepartment}
                </Skeleton>
            </div>

            <div
                className={styles.text}
                {...{
                    'qa-id': 'commentsBlockCommentText',
                }}
            >
                <Skeleton isReady={text !== undefined}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: sanitize(commentText, {
                                ALLOWED_TAGS: ['a', 'br', 'span'],
                                ALLOWED_ATTR: ['target', 'href', 'class', 'title'],
                            }),
                        }}
                    />
                </Skeleton>
            </div>

            <div className={styles.filesWrapper}>
                <Skeleton
                    isReady={files !== undefined}
                    loadingClassName={styles.fileLoading}
                    customStyle={{
                        borderRadius: '4px',
                    }}
                >
                    {files && !!files.length && (
                        <div>
                            <div className={styles.filesCaption}>
                                <Icon type={IconType.ATTACHMENT_ICON} className={styles.icon} svgSize={20} />
                                {files.length} {filesDeclension}
                                <div
                                    className={classNames(styles.expandIcon, isExpanded && styles.expandIconActive)}
                                    {...{
                                        'qa-id': 'commentsBlockCommentFilesExpandIcon',
                                    }}
                                >
                                    <Icon type={IconType.EXPAND_ICON} svgSize={16} onClick={onExpandButtonClick} />
                                </div>
                            </div>

                            {isExpanded && (
                                <div className={styles.files}>
                                    {files.map((file, i) => (
                                        <File key={`file-${i}`} {...file} />
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </Skeleton>

                <Skeleton
                    isReady={files !== undefined}
                    loadingClassName={styles.fileLoading}
                    customStyle={{
                        borderRadius: '4px',
                    }}
                >
                    {
                        null // used only for skeleton
                    }
                </Skeleton>

                <Skeleton
                    isReady={files !== undefined}
                    loadingClassName={styles.fileLoading}
                    customStyle={{
                        borderRadius: '4px',
                    }}
                >
                    {
                        null // used only for skeleton
                    }
                </Skeleton>
            </div>
        </div>
    );
}

interface FileProps {
    id?: string;
    name: string;
    originName: string;
    type: string;
    preventDownload?: boolean;
}

function File({ id, name, originName, type, preventDownload }: FileProps): JSX.Element {
    return (
        <div
            className={styles.fileWrapper}
            {...{
                'qa-id': 'commentsListCommentFile',
                'qa-title': `${originName}.${type}`,
            }}
        >
            <WithTooltip content={`${originName}.${type}`}>
                <div className={styles.file}>
                    <FileAsset
                        id={id}
                        name={name}
                        originName={originName}
                        type={type}
                        fileId={id}
                        preventDownload={preventDownload}
                    />
                </div>
            </WithTooltip>

            <div className={styles.fileName}>{originName}</div>
        </div>
    );
}
