import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { BudgetStatus } from '@mrm/budget';
import autobind from 'autobind-decorator';

import { StoreState } from '@store';
import { User } from '@store/user/types';
import { setNotification } from '@store/common/actions';
import { getLoginUser } from '@store/user';
import { getBudgetByStatusUserConfig } from '@store/userConfig/budget';
import {
    ComponentState,
    getTransferBudgetItemsToPlanningMenuState,
    initSingleBudgetItemTransfer,
} from '@store/budgetExecution/transferBudgetItemsToPlanningMenu';
import { NotificationActionType, NotificationMessage, NotificationType } from '@store/common/types';
import {
    ColumnName,
    CorrectionPopup,
    UnsavedChange,
    setPreloaderStatus,
    clearUnsavedChangesByLines,
    setCorrectionPopup,
    getUnsavedChangesByLineId,
    getBudgetItemsWithPlanOverrun,
    showLineModal,
    ShowLineModalParams,
    getPageData,
    calculateCorrectedPlanReserveBudget,
    getBudgetItemByLineId,
    userCanEditOrCreateBudgetData,
} from '@store/budgetExecution';
import { CellPosition, openTransitionMenu, setAcceptorCell } from '@store/budgetExecution/budgetTransferMenu';

import { LineColor } from '../../TableBody';
import { Saver, Loader, Validator } from '../../../modules';
import { LineMenu } from './LineMenu';

interface Props extends Partial<MapProps>, Partial<DispatchProps>, Partial<RouteComponentProps> {
    activityId: string;
    lineId: string;
    lineIsDisabled: boolean;
    executorId: number;
    backgroundColor: LineColor;
    lineIsHovered: boolean;
    onInfoMouseEnter: (lineId: string) => void;
    onInfoMouseLeave: () => void;
}

interface MapProps {
    budgetId: string;
    linePlanOverruned: boolean;
    lineHasPlanReserveCorrections: boolean;
    user: User;
    unsavedChanges: UnsavedChange[];
    showTransferBudgetItemsToPlanningButton: boolean;
    lineHasChildrenBudgetItems: boolean;
    userCanEditOrCreateBudgetData: boolean;
}

interface DispatchProps {
    openTransitionMenu: () => void;
    setAcceptorCell: (payload: CellPosition) => void;
    setPreloaderStatus: (preloaderStatus: boolean) => void;
    clearUnsavedChangesByLines: (lineIds: string[]) => void;
    setNotification: (notification: NotificationMessage) => void;
    setCorrectionPopup: (correctionPopup: CorrectionPopup) => void;
    showLineModal: (params: ShowLineModalParams) => void;
    initSingleBudgetItemTransfer: (budgetItemId: string) => void;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class LineMenuContainer extends React.PureComponent<Props> {
    private tableLoader: Loader;
    private tableValidator: Validator;
    private tableSaver: Saver;

    constructor(props: Props) {
        super(props);

        this.tableLoader = Loader.getInstance();
        this.tableValidator = Validator.getInstance();
        this.tableSaver = Saver.getInstance();
    }

    public render(): JSX.Element {
        const {
            lineId,
            backgroundColor,
            lineIsHovered,
            linePlanOverruned,
            lineHasPlanReserveCorrections,
            showTransferBudgetItemsToPlanningButton,
            lineHasChildrenBudgetItems,
            userCanEditOrCreateBudgetData,
            onInfoMouseEnter,
            onInfoMouseLeave,
        } = this.props;

        return React.createElement(LineMenu, {
            lineId,
            backgroundColor,
            lineIsHovered,
            linePlanOverruned,
            lineHasPlanReserveCorrections,
            showTransferBudgetItemsToPlanningButton,
            isDisabledSendCorrectionButton: !this.hasChangesInLine(),
            lineHasChildrenBudgetItems,
            onRootClick: this.onRootClick,
            onEditActivityButtonClick: this.onEditActivityButtonClick,
            onCopyLineButtonClick: this.onCopyLineButtonClick,
            onInfoMouseEnter,
            onInfoMouseLeave,
            onInfoClick: this.onInfoClick,
            onSendToCorrectionButtonClick: this.onSendToCorrectionButtonClick,
            onOpenCorrectionsButtonClick: this.onOpenCorrectionsButtonClick,
            isSendCorrectionButtonDisabled: !this.hasChangesInLine(),
            isEditActivityButtonDisabled: this.props.lineIsDisabled,
            onPlanTransferClick: this.onPlanTransferClick,
            onTransferBudgetItemsToPlanningButtonClick: this.onTransferBudgetItemsToPlanningButtonClick,
            userCanEditOrCreateBudgetData,
        });
    }

    @autobind
    private onRootClick(event: React.MouseEvent<HTMLDivElement>): void {
        event.preventDefault();
        event.stopPropagation();
    }

    @autobind
    protected onEditActivityButtonClick() {
        const { budgetId } = this.props;
        this.openUrlInSameTab(`/budget/execution/${this.props.activityId}?budgetId=${budgetId}`);
    }

    @autobind
    protected onCopyLineButtonClick() {
        const { budgetId, lineId } = this.props;
        this.openUrlInSameTab(
            `/budget/execution/${this.props.activityId}/copy?budgetId=${budgetId}&budgetItemId=${lineId}`,
        );
    }

    @autobind
    private async onSendToCorrectionButtonClick(): Promise<void> {
        if (this.hasChangesInLine()) {
            const { lineId, user } = this.props;

            this.props.setPreloaderStatus(true);

            const isValid = this.tableValidator.checkLineValidation(lineId);

            if (isValid) {
                const lineDataIsActual = await this.tableValidator.checkLineDataRelevance(lineId);

                if (lineDataIsActual) {
                    const lineDictionariesAreActual = await this.tableValidator.checkLineDictionariesRelevance(lineId);

                    if (lineDictionariesAreActual) {
                        if (this.isUserBudgetExpert()) {
                            await this.saveChange(user.attributes.id);
                            this.notifyAboutSuccess();
                        } else {
                            this.showCorrectionPopup();
                        }
                    } else {
                        this.notifyLineDictionariesRelevance();
                    }
                } else {
                    this.notifyLineDataRelevanceError();
                }
            } else {
                this.notifyValidationError();
            }

            this.props.setPreloaderStatus(false);

            this.tableValidator.updateValidationDisplay();
        }
    }

    @autobind
    private onOpenCorrectionsButtonClick(): void {
        const { executorId, budgetId } = this.props;

        this.openUrlInNewTab(`/budget/corrections?executorId=${executorId}&budgetId=${budgetId}`);
    }

    @autobind
    private onPlanTransferClick(): void {
        this.props.openTransitionMenu();
        this.props.setAcceptorCell({
            lineId: this.props.lineId,
            columnName: ColumnName.PlanJan,
        });
    }

    @autobind
    private onInfoClick(): void {
        const { lineId } = this.props;
        this.props.showLineModal({ lineId });
    }

    @autobind
    private onTransferBudgetItemsToPlanningButtonClick(): void {
        const { lineId } = this.props;
        this.props.initSingleBudgetItemTransfer(lineId);
    }

    private async saveChange(approverId: number): Promise<void> {
        const { lineId } = this.props;

        this.props.setPreloaderStatus(true);

        await this.tableSaver.saveLine(lineId, approverId);
        await this.tableLoader.updatePageDataByLineIds([lineId]);

        this.props.clearUnsavedChangesByLines([lineId]);

        this.props.setPreloaderStatus(false);
    }

    private openUrlInSameTab(url: string) {
        this.props.history.push(url);
    }

    private openUrlInNewTab(url: string): void {
        window.open(url, '_blank');
    }

    private hasChangesInLine(): boolean {
        const unsavedChanges = this.props.unsavedChanges;
        return !!unsavedChanges && !!unsavedChanges.length;
    }

    private isUserBudgetExpert(): boolean {
        return this.props.user.attributes.roles.map(({ id }) => id).includes(9);
    }

    private notifyAboutSuccess() {
        this.setNotificationMessage(
            NotificationType.SUCCESS,
            NotificationActionType.CHANGES_SENT_TO_APPROVAL_ON_BUDGET_EXECUTION_PAGE,
            'Изменения отправлены на согласование',
        );
    }

    private notifyValidationError() {
        this.setNotificationMessage(
            NotificationType.ERROR,
            NotificationActionType.TABLE_VALIDATION_ON_BUDGET_EXECUTION_PAGE,
            'Нельзя отправить на согласование активность или строку с незаполненным обязательным полем, и название ' +
                'активности не должно быть меньше трех символов. Введите значение и отправьте на согласование еще раз.',
        );
    }

    private notifyLineDataRelevanceError() {
        this.setNotificationMessage(
            NotificationType.ERROR,
            NotificationActionType.DATA_IS_NOT_ACTIAL_ON_BUDGET_EXECUTION_PAGE,
            'В редактируемую строку были внесены изменения. Обновите страницу.',
        );
    }

    private notifyLineDictionariesRelevance() {
        this.setNotificationMessage(
            NotificationType.ERROR,
            NotificationActionType.DATA_IS_NOT_ACTIAL_ON_BUDGET_EXECUTION_PAGE,
            'В редактируемой строке существует неутверждённая корректировка. Утвердите или отклоните её',
        );
    }

    private setNotificationMessage(type: NotificationType, typeAction: NotificationActionType, comment: string): void {
        this.props.setNotification({ type, typeAction, comment });
    }

    private showCorrectionPopup(): void {
        const { lineId } = this.props;

        this.props.setCorrectionPopup({
            show: true,
            lineId,
        });
    }
}

function mapStateToProps(state: StoreState, props: Props): MapProps {
    const { budgetId } = getBudgetByStatusUserConfig(state, BudgetStatus.Execution);
    const pageData = getPageData(state);
    const budgetItem = getBudgetItemByLineId(state, props.lineId);
    const correctedPlanReserveBudget = calculateCorrectedPlanReserveBudget(pageData, budgetItem);

    const linePlanOverruned = getBudgetItemsWithPlanOverrun(state).includes(props.lineId);

    const { componentState, linesToTransfer } = getTransferBudgetItemsToPlanningMenuState(state).props;

    const showTransferBudgetItemsToPlanningButton =
        userCanEditOrCreateBudgetData(state) && (componentState !== ComponentState.Opened || !linesToTransfer.length);

    return {
        budgetId,
        user: getLoginUser(state),
        unsavedChanges: getUnsavedChangesByLineId(state, props.lineId),
        linePlanOverruned,
        lineHasPlanReserveCorrections: correctedPlanReserveBudget.before !== correctedPlanReserveBudget.after,
        showTransferBudgetItemsToPlanningButton,
        lineHasChildrenBudgetItems: !!budgetItem?.childrenBudgetItems?.length,
        userCanEditOrCreateBudgetData: userCanEditOrCreateBudgetData(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setPreloaderStatus,
            clearUnsavedChangesByLines,
            setNotification,
            setCorrectionPopup,
            openTransitionMenu,
            setAcceptorCell,
            showLineModal,
            initSingleBudgetItemTransfer,
        },
        dispatch,
    );
}
