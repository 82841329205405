import * as React from 'react';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import * as lodash from 'lodash';
import autobind from 'autobind-decorator';
import { v4 as uuid } from 'uuid';
import { ActivityFileGroup } from 'sber-marketing-types/frontend';

import { FileApi } from '@api';

import {
    resetFilesUploadStatus,
    setFileUploadStatus,
    FileUploadStatus,
    SetFileUploadStatusPayload,
    errorIsFromAntivirus,
} from '@store/common';

import { GroupedFiles } from './WithFileQuery';

import { ActivityFiles } from './ActivityFiles';

interface Props extends Partial<DispatchProps> {
    userId: number;
    filesByGroup: GroupedFiles;
    updateFiles: () => Promise<void>;
    attachFile: (fileId: string, fileCategory: ActivityFileGroup) => Promise<void>;
    deleteFile: (fileId: string) => Promise<void>;
}

interface DispatchProps {
    resetFilesUploadStatus: () => void;
    setFileUploadStatus: (payload: SetFileUploadStatusPayload) => void;
}

interface State {
    loadingFilesData: { [id: string]: LoadingFileData };
    deletionFileId: string;
}

export interface LoadingFileData {
    name: string;
    size: number;
    type: string;
    originName: string;
    fileCategory: ActivityFileGroup;
}
@(connect(null, mapDispatchToProps) as any)
export class ActivityFilesContainer extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            loadingFilesData: {},
            deletionFileId: null,
        };
    }

    public componentDidMount(): void {
        window.addEventListener('keydown', this.onKeydown);
    }

    public componentWillUnmount(): void {
        window.removeEventListener('keydown', this.onKeydown);
    }

    public render(): JSX.Element {
        const { deletionFileId } = this.state;

        const loadingFilesData = lodash.values(this.state.loadingFilesData);

        return (
            <ActivityFiles
                filesByGroup={this.props.filesByGroup}
                loadingFilesData={loadingFilesData}
                deletionFileName={this.getFileNameById(deletionFileId)}
                onFileInput={this.onFileInput}
                onDeleteClick={this.onDeleteClick}
                onDeleteConfirmButtonClick={this.onDeleteConfirmButtonClick}
                onDeleteCancelButtonClick={this.onDeleteCancelButtonClick}
            />
        );
    }

    @autobind
    private onKeydown(event: KeyboardEvent): void {
        if (event.code === 'Escape' && this.state.deletionFileId) {
            this.changeFileDeletionStatus(null);
        }
    }

    @autobind
    private async onFileInput(files: File[], fileCategory: ActivityFileGroup) {
        this.props.resetFilesUploadStatus();

        if (!lodash.isEmpty(files)) {
            const { userId } = this.props;

            await Promise.all(
                files.map((file) => {
                    return new Promise<void>(async (resolve) => {
                        const tempFileId = uuid();

                        const extensionIndex = file.name.lastIndexOf('.');
                        const name = file.name.slice(0, extensionIndex);
                        const type = file.name.slice(extensionIndex + 1);

                        const fileData = {
                            name,
                            type,
                            size: file.size,
                            originName: name,
                            fileCategory,
                        };

                        await this.onFileLoadingStart(tempFileId, fileData);

                        try {
                            const fileAsset = await FileApi.uploadFile({}, file, userId.toString());
                            await this.props.attachFile(fileAsset.id, fileCategory);
                        } catch (e) {
                            if (errorIsFromAntivirus(e)) {
                                this.props.setFileUploadStatus({
                                    fileName: file.name,
                                    status: FileUploadStatus.VirusFound,
                                });
                            }

                            throw e;
                        } finally {
                            await this.onFileLoadingEnd(tempFileId);

                            resolve();
                        }
                    });
                }),
            );

            await this.props.updateFiles();
        }
    }

    @autobind
    private async onDeleteClick(fileId: string) {
        this.changeFileDeletionStatus(fileId);
    }

    @autobind
    private async onDeleteConfirmButtonClick() {
        const fileId = this.state.deletionFileId;

        this.changeFileDeletionStatus(null);

        await FileApi.deleteFile(fileId);
        await this.props.updateFiles();
    }

    @autobind
    private async onDeleteCancelButtonClick() {
        this.changeFileDeletionStatus(null);
    }

    private onFileLoadingStart(fileId: string, fileData: LoadingFileData): Promise<void> {
        return new Promise<void>((resolve) => {
            this.setState(
                (state) => {
                    const { loadingFilesData } = state;

                    const updatedLoadingFilesData = lodash.clone(loadingFilesData);

                    updatedLoadingFilesData[fileId] = fileData;

                    return { loadingFilesData: updatedLoadingFilesData };
                },
                () => resolve(),
            );
        });
    }

    private onFileLoadingEnd(fileId: string): Promise<void> {
        return new Promise<void>((resolve) => {
            this.setState(
                (state) => {
                    const { loadingFilesData } = state;

                    const updatedLoadingFilesData = lodash.clone(loadingFilesData);

                    delete updatedLoadingFilesData[fileId];

                    return { loadingFilesData: updatedLoadingFilesData };
                },
                () => resolve(),
            );
        });
    }

    private getFileNameById(fileId: string): string {
        if (fileId === null) {
            return null;
        }

        const { filesByGroup } = this.props;

        const files = [...filesByGroup[ActivityFileGroup.CREATIVE], ...filesByGroup[ActivityFileGroup.MEDIAPLAN]];

        const foundFile = files.find((item) => item.id === fileId);

        return foundFile ? `${foundFile.name}.${foundFile.type}` : null;
    }

    private changeFileDeletionStatus(fileId: string) {
        this.setState({
            deletionFileId: fileId,
        });
    }
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        resetFilesUploadStatus: () => dispatch(resetFilesUploadStatus()),
        setFileUploadStatus: (payload: SetFileUploadStatusPayload) => dispatch(setFileUploadStatus(payload)),
    };
}
