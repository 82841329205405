import { buildActivityParams } from './buildActivityParams';
import { buildTaskParams } from './buildTaskParams';

import { BuildParams } from '../types';

interface BuildParamsByMode {
    activities: BuildParams<'activities'>;
    tasks: BuildParams<'tasks'>;
}

export const buildParamsByMode: BuildParamsByMode = {
    activities: buildActivityParams,
    tasks: buildTaskParams,
};
