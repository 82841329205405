import * as React from 'react';

import * as style from './SelectCell.scss';

import { Icon, IconType } from 'sber-marketing-ui';
import { CommentsButton } from '../CommentsButton';
import { ContextMenu } from '../ContextMenu';

interface Props {
    title: string;
    customStyle?: any;
    commentButtonProps?: { hasNewComments: boolean; onClick: () => void };
    onValueChange: (value: React.ReactText) => void;
}

export const SelectCell = ({ title, customStyle, commentButtonProps, onValueChange }: Props): JSX.Element => {
    function onClearValueClick() {
        onValueChange(null);
    }

    return (
        <>
            <CommentsButton {...commentButtonProps} />
            <ContextMenu
                items={[
                    { title: 'Смотреть комментарии', onClick: commentButtonProps?.onClick },
                    { title: 'Очистить', onClick: onClearValueClick },
                ]}
            />

            <div className={style.root} title={title} style={{ ...customStyle }}>
                <div className={style.title}>{title}</div>

                <div className={style.openerIcon}>
                    <Icon type={IconType.TRIANGLE8_DOWN} svgSize={8} />
                </div>
            </div>
        </>
    );
};
