import * as React from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import { StoreState } from '@store';
import { ColumnFilters, ColumnName, CellValueType, getBudgetPlanningPageState } from '@store/budgetPlanning';

import { DropdownContent } from './DropdownContent';

interface Props extends Partial<MapProps> {
    columnName: ColumnName;
    filters: ColumnFilters;
    columnValueType: CellValueType;
    currencySearch: boolean;
    onFiltersChange: (filters: ColumnFilters) => void;
    onColumnFixChange: () => void;
    onApplyFiltersButtonClick: () => void;
}

interface MapProps {
    showApplyFiltersButton: boolean;
}

@(connect(mapStateToProps) as any)
export class DropdownContentContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        return <DropdownContent {...this.props} showApplyFiltersButton={this.props.showApplyFiltersButton} />;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const {
        columnFilters: { filters },
        previouslyLoadedFilters,
    } = getBudgetPlanningPageState(state);

    const showApplyFiltersButton = !isEqual(filters, previouslyLoadedFilters);

    return {
        showApplyFiltersButton,
    };
}
