import * as React from 'react';

import * as style from './TextInput.scss';

import { Input_redesign as Input, InputTheme_redesign as InputTheme } from 'sber-marketing-ui';

interface Props {
    value: string;
    placeholder: string;
    readOnly?: boolean;
    onValueChange: (value: string) => void;
}

export const TextInput = ({ value: initValue, placeholder, readOnly, onValueChange }: Props): JSX.Element => {
    const [value, setValue] = React.useState(initValue);
    const [lastClearedValue, setLastClearedValue] = React.useState(null);
    const [editModeEnabled, setEditMode] = React.useState(!value);

    React.useEffect(() => {
        if (value !== initValue) {
            onValueChange(value);
        }
    }, [value]);

    React.useEffect(() => {
        if (initValue !== value) {
            setValue(initValue);
        }
    }, [initValue]);

    const onInputChange = React.useCallback(
        (newValue: string) => {
            if (newValue !== value) {
                setValue(newValue);
            }
        },
        [value],
    );

    const onInputBlur = React.useCallback(() => {
        if (value !== '') {
            setEditMode(false);
        }
    }, [value]);

    const onClearButtonClick = React.useCallback(() => {
        setLastClearedValue(value);
        setValue('');
        setEditMode(true);
    }, [value]);

    return (
        <div className={style.root}>
            {!editModeEnabled && (
                <>
                    <div className={style.value}>{value}</div>

                    {!readOnly && (
                        <div className={style.clearButton} onClick={onClearButtonClick}>
                            Стереть
                        </div>
                    )}
                </>
            )}

            {editModeEnabled && (
                <div className={style.input}>
                    <Input
                        value={value}
                        placeholder={placeholder}
                        theme={InputTheme.Borderless}
                        onInputChange={onInputChange}
                        onBlur={onInputBlur}
                    />
                </div>
            )}
        </div>
    );
};
