// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-task-ChannelsBoard-_ChannelBoard__root,.src-client-modules-activity-pages-task-ChannelsBoard-_ChannelBoard__buttonsList,.src-client-modules-activity-pages-task-ChannelsBoard-_ChannelBoard__info{width:100%}.src-client-modules-activity-pages-task-ChannelsBoard-_ChannelBoard__root_loading{position:relative;display:flex;height:90px;align-items:center;justify-content:center}.src-client-modules-activity-pages-task-ChannelsBoard-_ChannelBoard__buttonsList{margin-bottom:8px}.src-client-modules-activity-pages-task-ChannelsBoard-_ChannelBoard__error{width:100%;font-size:14px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/task/ChannelsBoard/ChannelBoard.scss"],"names":[],"mappings":"AAAA,qOAGI,UAAW,CACd,kFAGG,iBAAkB,CAElB,YAAa,CAEb,WAAY,CAEZ,kBAAmB,CACnB,sBAAuB,CAC1B,iFAGG,iBAAkB,CACrB,2EAGG,UAAW,CAEX,cAAe","sourcesContent":[".root,\n.buttonsList,\n.info {\n    width: 100%;\n}\n\n.root_loading {\n    position: relative;\n\n    display: flex;\n\n    height: 90px;\n\n    align-items: center;\n    justify-content: center;\n}\n\n.buttonsList {\n    margin-bottom: 8px;\n}\n\n.error {\n    width: 100%;\n\n    font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-task-ChannelsBoard-_ChannelBoard__root",
	"buttonsList": "src-client-modules-activity-pages-task-ChannelsBoard-_ChannelBoard__buttonsList",
	"info": "src-client-modules-activity-pages-task-ChannelsBoard-_ChannelBoard__info",
	"root_loading": "src-client-modules-activity-pages-task-ChannelsBoard-_ChannelBoard__root_loading",
	"error": "src-client-modules-activity-pages-task-ChannelsBoard-_ChannelBoard__error"
};
export default ___CSS_LOADER_EXPORT___;
