import * as React from 'react';

import {
    CommonFondsChange,
    CorrectionCardComment,
    CorrectionBody,
    CorrectionCardHeader,
    CorrectionCardTable,
    CorrectionCardCellHeader,
    CorrectionCardCellContent,
    ExcessReserveWarningList,
    FondsByMonthTable,
    RejectComment,
    RejectedCorrectionWarningList,
    SubPramList,
    SumWithTitle,
} from '../common';
import type { ExcessReserveWarningProps as Warning, FondField } from '../common';

import { CorrectionStatus, CorrectionType } from '@mrm/budget';

import * as style from './ReserveCorrectionCard.scss';

export interface PlanData {
    name: string;
    currentPlan: number;
    fields: PlanField[];
    comment: string;
}

export interface PlanField {
    name: string;
    value: React.ReactText;
    isWide?: boolean;
    title?: string;
}

export interface ReserveData {
    beforeSumFonds: number;
    delta: number;
    fields: FondField[];
}

interface Props {
    serialNumber: number;
    authorName: string;
    approverName: string;
    userIsBudgetExpert: boolean;
    userIsAuthorCorrection: boolean;
    creationDate: string;
    planData: PlanData;
    reserveData: ReserveData;
    status: CorrectionStatus;
    url: string;
    rejectComment: string;
    overwrittenSerialNumber: string;
    warnings: Warning[];
    onStatusChange: (status: CorrectionStatus) => void;
}

export const ReserveCorrectionCard = ({
    serialNumber,
    authorName,
    approverName,
    userIsBudgetExpert,
    userIsAuthorCorrection,
    creationDate,
    planData,
    reserveData,
    status,
    url,
    rejectComment,
    overwrittenSerialNumber,
    warnings,
    onStatusChange,
}: Props): JSX.Element => {
    const haveWarnings = Boolean(warnings.length);

    return (
        !!planData &&
        !!reserveData && (
            <CorrectionBody status={status} serialNumber={serialNumber} type={CorrectionType.ReservedFunds}>
                <CorrectionCardHeader
                    title={'Корректировка резерва'}
                    serialNumber={serialNumber}
                    authorName={authorName}
                    approverName={approverName}
                    creationDate={creationDate}
                    url={url}
                    status={status}
                    userIsAuthorCorrection={userIsAuthorCorrection}
                    userIsBudgetExpert={userIsBudgetExpert}
                    onStatusChange={onStatusChange}
                />

                {rejectComment && <RejectComment text={rejectComment} />}

                {haveWarnings && <ExcessReserveWarningList warnings={warnings} />}

                {overwrittenSerialNumber && (
                    <RejectedCorrectionWarningList
                        warnings={[
                            {
                                correctionSerialNumberId: overwrittenSerialNumber,
                                isActive: status === CorrectionStatus.NeedApproving,
                            },
                        ]}
                    />
                )}

                <CorrectionCardTable>
                    <CorrectionCardCellHeader>
                        <PlanSubInfo
                            comment={planData.comment}
                            name={planData.name}
                            currentPlan={planData.currentPlan}
                            fields={planData.fields}
                        />
                    </CorrectionCardCellHeader>
                    <CorrectionCardCellHeader>
                        <ReserveSubInfo
                            fields={reserveData.fields}
                            beforeSumFonds={reserveData.beforeSumFonds}
                            delta={reserveData.delta}
                        />
                    </CorrectionCardCellHeader>

                    <CorrectionCardCellContent>
                        <div className={style.subInfoContent}>
                            <SubPramList supParams={planData.fields} />
                            {planData.comment && (
                                <SubPramList
                                    supParams={[
                                        {
                                            name: 'Комментарий к строке',
                                            value: <CorrectionCardComment text={planData.comment} />,
                                            isWide: true,
                                            notCropContent: true,
                                        },
                                    ]}
                                />
                            )}
                        </div>
                    </CorrectionCardCellContent>
                    <CorrectionCardCellContent>
                        <div className={style.subInfoContent}>
                            <FondsByMonthTable fields={reserveData.fields} />
                        </div>
                    </CorrectionCardCellContent>
                </CorrectionCardTable>
            </CorrectionBody>
        )
    );
};

const PlanSubInfo: React.FC<PlanData> = (data) => (
    <div className={style.subInfo}>
        <div className={style.subInfoTitle}>{data.name}</div>

        <SumWithTitle sum={data.currentPlan} title={'Плановый бюджет по строке'} />
    </div>
);

const ReserveSubInfo: React.FC<ReserveData> = (data) => (
    <div className={style.subInfo}>
        <div className={style.commonFondsChangeWrapper}>
            <CommonFondsChange title={'Резерв по строке'} before={data.beforeSumFonds} delta={data.delta} />
        </div>
    </div>
);

export type { Warning, FondField };
