// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-files-components-FileTag-_FileTag__root{font-size:12px;min-height:64px}.src-client-modules-files-components-FileTag-_FileTag__info{min-width:0}.src-client-modules-files-components-FileTag-_FileTag__title{overflow:hidden;text-overflow:ellipsis}.src-client-modules-files-components-FileTag-_FileTag__title:not(.src-client-modules-files-components-FileTag-_FileTag__twoLines){white-space:nowrap}.src-client-modules-files-components-FileTag-_FileTag__twoLines{display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical}.src-client-modules-files-components-FileTag-_FileTag__subtitle{color:#7e8681}\n", "",{"version":3,"sources":["webpack://./src/client/modules/files/components/FileTag/FileTag.scss"],"names":[],"mappings":"AAAA,4DACE,cAAe,CACf,eAAgB,CACjB,4DAGC,WAAY,CACb,6DAGC,eAAgB,CAChB,sBAAuB,CAFzB,kIAKI,kBAAmB,CACpB,gEAID,mBAAoB,CACpB,oBAAqB,CACrB,2BAA4B,CAC7B,gEAGC,aAAc","sourcesContent":[".root {\n  font-size: 12px;\n  min-height: 64px;\n}\n\n.info {\n  min-width: 0;\n}\n\n.title {\n  overflow: hidden;\n  text-overflow: ellipsis;\n\n  &:not(.twoLines) {\n    white-space: nowrap;\n  }\n}\n\n.twoLines {\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n}\n\n.subtitle {\n  color: #7e8681;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-files-components-FileTag-_FileTag__root",
	"info": "src-client-modules-files-components-FileTag-_FileTag__info",
	"title": "src-client-modules-files-components-FileTag-_FileTag__title",
	"twoLines": "src-client-modules-files-components-FileTag-_FileTag__twoLines",
	"subtitle": "src-client-modules-files-components-FileTag-_FileTag__subtitle"
};
export default ___CSS_LOADER_EXPORT___;
