import * as moment from 'moment';
import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { AxiosError } from 'axios';

import { StoreState } from '../..';
import { removeList as removeAssets, updateIds } from '@store/fileAssets/actions';
import { AddCommentarySuccess, FetchError } from '../types';
import { asyncActions, syncActions } from '../actions';
import { TaskApi } from '../../../api/TaskApi';
import { getTaskInfo, getNewCommentaryState, getAssetsByIds, getTaskPageState } from '../selectors';
import { FileAsset } from '../../commonTypes';
import { runUploadOne } from '../../fileAssets';
import {
    resetFilesUploadStatus,
    setFileUploadStatus,
    FileUploadStatus,
    errorIsFromAntivirus,
    setNotification,
    NotificationType,
    NotificationActionType,
} from '@store/common';
import { setSendingComment } from '@store/taskPage';

export const addCommentary = bindThunkAction<StoreState, number | null, AddCommentarySuccess, FetchError>(
    asyncActions.addCommentary,
    async (chanelId, dispatch, getState): Promise<AddCommentarySuccess> => {
        const state = getState();
        const { content, id, assetsIds } = getNewCommentaryState(state);
        const { commentIdToReplyTo: replyId } = getTaskPageState(state);
        const { id: taskId } = getTaskInfo(state);

        let result: AddCommentarySuccess | null = null;
        const fileAssets: FileAsset[] = getAssetsByIds(state, assetsIds);
        const assets: FileAsset[] = [];

        dispatch(setSendingComment(true));

        try {
            const params = { taskId, commentId: String(id) };
            for (const asset of fileAssets) {
                try {
                    const upload = await runUploadOne({
                        skipCreatingFileRecord: true,
                        params,
                        fileOrAssetOrId: asset,
                        dispatch,
                    });
                    assets.push(upload);
                } catch (e) {
                    if (errorIsFromAntivirus(e)) {
                        dispatch(
                            setFileUploadStatus({
                                fileName: `${asset.originName}.${asset.type}`,
                                status: FileUploadStatus.VirusFound,
                            }),
                        );
                    }

                    throw e;
                }
            }

            const { text, authorId, createTime, files } = await TaskApi.createComment(taskId, {
                text: content.length ? content : '',
                id,
                chanelId,
                files: assets as any[],
                replyId,
            });

            const newAssets = assets.map((asset, i) => ({ ...asset, id: files[i].id }));
            const assetsIdMap = files.reduce((rest, file, i) => ({ ...rest, [assets[i].id]: file.id }), {});

            result = {
                assets: newAssets,
                id: String(id),
                authorId,
                isLoading: false,
                content: text,
                createdAt: moment(createTime).unix() * 1000,
                assetsIds: newAssets.map((asset) => asset.id),
                replyId,
                reactions: [],
            };

            await TaskApi.deleteDraftNewComment(taskId);
            window.clearTimeout(window['newCommentTimer']);

            dispatch(syncActions.resetNewCommentary());
            dispatch(resetFilesUploadStatus());
            dispatch(updateIds(assetsIdMap));
            if (fileAssets.length) {
                dispatch(
                    setNotification({
                        type: NotificationType.NEUTRAL,
                        typeAction: NotificationActionType.COMMENT_ASSETS_SEND,
                        comment: 'Все файлы загружены',
                    }),
                );
            }
        } catch (error) {
            const loadedAssetIds = assets.map((asset) => asset.id);
            const notLoadedAssetIds = fileAssets
                .filter((asset) => !loadedAssetIds.includes(asset.id))
                .map((asset) => asset.id);
            dispatch(removeAssets(notLoadedAssetIds));

            console.error(error);
            const { response, message } = <AxiosError>error;
            if (response) {
                throw new FetchError(response.status, response.statusText, message);
            } else {
                throw new FetchError(0, 'Unknown', message);
            }
        }

        dispatch(setSendingComment(false));

        return result!;
    },
);
