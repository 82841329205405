import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { CorrectionType } from '@mrm/budget';
import {
    ColumnsWidth,
    ColumnsVisiblityFilter,
    ColumnData,
    ColumnName,
    CellValueType,
    TableLine,
    NumericCellValue,
    GroupedCorrections,
} from '@store/budgetExecution/types';

import { ScrollbarComponent } from 'sber-marketing-ui';
import { SumLine, CellsValues } from './SumLine';
import { StoreState } from '@store';
import {
    getBudgetExecutionPageState,
    getPageData,
    getTableFilters,
    getFilteredTableLines,
} from '@store/budgetExecution/selectors';
import { ColumnsList } from '../../ColumnsConfig';

import { Money, MoneyFormatter } from '@common/Utils';

interface Props extends Partial<MapProps>, Partial<DispatchProps> {
    scrollbarRef: (component: ScrollbarComponent) => void;
}

interface MapProps {
    columnsWidth: ColumnsWidth;
    columnsVisiblityFilter: ColumnsVisiblityFilter;
    fixedColumnsNames: ColumnName[];
    lines: TableLine[];
    planCorrections: GroupedCorrections<CorrectionType.PlanFundsTransfer>;
    reserveCorrections: GroupedCorrections<CorrectionType.ReservedFunds>;
}

interface DispatchProps {}

interface State {
    isOpened: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class SumLineContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isOpened: false,
        };
    }

    public render(): JSX.Element {
        return React.createElement(SumLine, {
            isOpened: this.state.isOpened,
            columnsWidth: this.props.columnsWidth,
            columnsVisiblityFilter: this.props.columnsVisiblityFilter,
            fixedColumnsNames: this.props.fixedColumnsNames,
            cellsParams: this.makeCellsValues(),
            scrollbarRef: this.props.scrollbarRef,
            onButtonClick: this.onButtonClick,
        });
    }

    @autobind
    protected onButtonClick() {
        this.setState({
            isOpened: !this.state.isOpened,
        });
    }

    private makeCellsValues(): CellsValues {
        const values: CellsValues = {};

        const budgetColumns = ColumnsList.filter((column) => column.valueType == CellValueType.Currency);

        budgetColumns.forEach((column) => {
            values[column.name] = this.makeCellValue(column);
        });

        return values;
    }

    private makeCellValue(column: ColumnData): string {
        const { lines } = this.props;

        const columnValues = lines.map((item) => {
            const value = item.fields[column.name] as NumericCellValue;

            return value ? value.number : 0;
        });

        const columnSum = lodash.sum(columnValues);

        return MoneyFormatter.toThousands(Money.fromCopecks(columnSum), {
            hideCaption: true,
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { columnsWidth, fixedColumnsNames } = getBudgetExecutionPageState(state);
    const { planCorrections, reserveCorrections } = getPageData(state).corrections;
    const { columnsVisiblityFilter } = getTableFilters(state);

    return {
        columnsWidth,
        columnsVisiblityFilter,
        fixedColumnsNames,
        lines: getFilteredTableLines(state),
        planCorrections,
        reserveCorrections,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators({}, dispatch);
}
