import * as React from 'react';
import * as moment from 'moment';
import { TaskStatus } from 'sber-marketing-types/frontend';

import { Utils } from '@common/Utils';

import { Status, StatusType, TemplateProps as StatusTempalteProps } from './Status';

interface Props {
    canEdit: boolean;
    status: TaskStatus;
    deadline: moment.Moment;
    changeStatus: (status: TaskStatus) => Promise<void>;
}

export class StatusContainer extends React.Component<Props> {
    public render() {
        const templateProps = this.definePropsToTemplate();

        return templateProps ? (
            <Status {...templateProps} canEdit={this.props.canEdit} changeStatus={this.props.changeStatus} />
        ) : null;
    }

    private definePropsToTemplate(): StatusTempalteProps {
        const { status } = this.props;

        if (status === TaskStatus.InProgress) {
            const editDropdownItems = [
                {
                    id: TaskStatus.Closed,
                    label: 'Закрыта',
                },
            ];

            if (this.isDeadlineExpired()) {
                return {
                    type: StatusType.EXPIRED_DEADLINE,
                    editDropdownItems,
                };
            } else if (this.haveCriticalFewDaysToDeadline()) {
                return {
                    type: StatusType.CRITICAL_FEW_DAYS_TO_DEADLINE,
                    text: this.buildTextWithRestDaysToDeadline(),
                    editDropdownItems,
                };
            } else if (this.haveFewDaysToDeadline()) {
                return {
                    type: StatusType.FEW_DAYS_TO_DEADLINE,
                    text: this.buildTextWithRestDaysToDeadline(),
                    editDropdownItems,
                };
            } else {
                return {
                    type: StatusType.IN_WORK,
                    editDropdownItems,
                };
            }
        } else {
            const editDropdownItems = [
                {
                    id: TaskStatus.InProgress,
                    label: 'В работе',
                },
            ];

            if (status === TaskStatus.Draft) {
                return {
                    type: StatusType.DRAFT,
                    editDropdownItems,
                };
            } else if (status === TaskStatus.Closed) {
                return {
                    type: StatusType.CLOSED_TASK,
                    editDropdownItems,
                };
            }
        }

        return null;
    }

    private buildTextWithRestDaysToDeadline(): string {
        const restDays = this.getDiffCurrentDateAndDeadlineDate();
        return `${restDays} ${Utils.declOfNum(restDays, ['День', 'Дня', 'Дней'])}`;
    }

    private haveFewDaysToDeadline(): boolean {
        return this.toDeadlineLessThan(5);
    }

    private haveCriticalFewDaysToDeadline(): boolean {
        return this.toDeadlineLessThan(3);
    }

    private toDeadlineLessThan(days: number): boolean {
        const { deadline } = this.props;
        return moment().add(days, 'day').isAfter(deadline);
    }

    private isDeadlineExpired(): boolean {
        return this.getDiffCurrentDateAndDeadlineDate() <= 0;
    }

    private getDiffCurrentDateAndDeadlineDate(): number {
        const { deadline } = this.props;
        const currentDate = moment().add(-1, 'day');

        return deadline.diff(currentDate, 'day');
    }
}
