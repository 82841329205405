import * as React from 'react';
import {
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    Input_redesign as Input,
    InputTheme_redesign as InputTheme,
} from 'sber-marketing-ui';

import { StageParams, StageSelector } from './StagesSelector';

import * as styles from './AddTaskPopup.scss';

interface Props {
    stageId: string;
    stages: StageParams[];
    createTask: (taskName: string, stageId: string) => Promise<void>;
}

function useInteractivity(props: Props) {
    const { createTask } = props;
    const [taskName, setTaskName] = React.useState('');
    const [stageId, setStageId] = React.useState(props.stageId);
    const [isRequestInProgress, setIsRequestInProgress] = React.useState(false);

    async function initTaskCreate() {
        if (taskName) {
            setIsRequestInProgress(true);
            await createTask(taskName, stageId);
            setIsRequestInProgress(false);
        }
    }

    React.useEffect(() => {
        function onKeyDown(event: KeyboardEvent) {
            if (event.code === 'Enter') {
                initTaskCreate();
            }
        }

        window.addEventListener('keydown', onKeyDown);

        return () => window.removeEventListener('keydown', onKeyDown);
    });

    return {
        isRequestInProgress,
        initTaskCreate,
        taskName,
        setTaskName,
        stageId,
        setStageId,
    };
}

export function AddTaskPopup(props: Props): JSX.Element {
    const { isRequestInProgress, initTaskCreate, taskName, setTaskName, stageId, setStageId } = useInteractivity(props);

    const disableButton = isRequestInProgress || !taskName;

    return (
        <div className={styles.root}>
            <div className={styles.title}>Добавить задачу</div>

            <StageSelector stages={props.stages} selectedStageId={stageId} selectStage={setStageId} />

            <div className={styles.inputWrapper}>
                <Input
                    qaId="stagesCalendarSidebarAddTaskPopupInput"
                    autoFocus
                    className={styles.input}
                    value={taskName}
                    placeholder="Введите название задачи"
                    theme={InputTheme.Simple}
                    onInputChange={(value) => setTaskName(value)}
                />

                <div className={styles.inputSubmitButtonWrapper}>
                    <Button
                        qaId="stagesCalendarSidebarAddTaskPopupButton"
                        theme={ButtonTheme.GhostRoundedBlack}
                        disabled={disableButton}
                        onClick={initTaskCreate}
                    >
                        <div className={styles.inputSubmitButton}>
                            {isRequestInProgress ? 'Создание...' : 'Создать'}
                        </div>
                    </Button>
                </div>
            </div>

            <div className={styles.subtitle}>Нажмите Enter, чтобы создать задачу</div>
        </div>
    );
}
