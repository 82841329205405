import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';
import { TagColorType, TagEmojiType } from '@mrm/tags';

import { useDefaultState } from '@common/hooks';

import { Button, Buttons, Scrollbar, Tag } from '@common/components';

import * as styles from './TagSettings.scss';

const colors = Object.values(TagColorType) as TagColorType[];
const emojis = Object.values(TagEmojiType).map((emoji) => `TagEmoji${emoji}`) as TagEmojiType[];

export interface TagSettingsSubmit {
    color: TagColorType;
    picture: string;
}

export interface TagSettingsProps extends Omit<React.HTMLProps<HTMLDivElement>, 'onSubmit'> {
    color?: TagColorType;
    picture?: string;
    onChangeColor?: (color?: TagColorType) => void;
    onChangePicture?: (picture?: string) => void;
    onSubmit?: (data: TagSettingsSubmit) => void;
}

export function TagSettings({
    className,
    children,
    color: colorProp,
    picture: pictureProp,
    onChangeColor,
    onChangePicture,
    onSubmit,
    ...props
}: TagSettingsProps) {
    const [color, setColor] = useDefaultState(colorProp, onChangeColor);
    const [picture, setPicture] = useDefaultState(pictureProp, onChangePicture);

    const handleAddTag = () => {
        onSubmit({
            color,
            picture,
        });
    };

    return (
        <div data-qa-id="TagSettings" {...props} className={classNames(styles.root, className)}>
            <Scrollbar wrap gutter="stable" className={styles.tags}>
                {colors.map((curColor) => (
                    <Tag
                        data-qa-id="TagSettings__color"
                        onClick={() => setColor(color === curColor ? null : curColor)}
                        padding={4}
                        color={curColor}
                        key={curColor}
                    >
                        {color === curColor && <Icon type={IconType.CHECK_ICON} />}
                    </Tag>
                ))}
            </Scrollbar>
            <Scrollbar wrap gutter="stable" className={styles.emojis}>
                {emojis.map((emoji: string) => (
                    <Tag
                        padding={4}
                        type={picture === emoji ? 'accent' : 'default'}
                        onClick={() => setPicture(picture === emoji ? null : emoji)}
                        key={emoji}
                    >
                        <Icon data-qa-id="TagSettings__icon" type={emoji as any} />
                    </Tag>
                ))}
            </Scrollbar>
            <Buttons>
                <Button data-qa-id="TagSettings__save" onClick={handleAddTag} flex view="primary">
                    {children}
                </Button>
            </Buttons>
        </div>
    );
}
