import * as React from 'react';

import { WithStore } from './WithStore';
import { CreativeReferenceMenuBehavior } from './CreativeReferenceMenuBehavior';

interface Props {
    onClose: () => void;
    creativeRequestId: string;
}

export const CreativeReferenceMenuConnector: React.FC<Props> = ({ creativeRequestId, onClose }) => {
    return (
        <WithStore creativeRequestId={creativeRequestId}>
            {({
                loading,
                creativeRequests,
                selectedCreativeRequestId,
                setCreativeRequestId,
                setNotification,
                save,
            }) => (
                <CreativeReferenceMenuBehavior
                    loading={loading}
                    creativeRequests={creativeRequests}
                    selectedCreativeRequestId={selectedCreativeRequestId}
                    setCreativeRequestId={setCreativeRequestId}
                    setNotification={setNotification}
                    onSave={save}
                    onClose={onClose}
                />
            )}
        </WithStore>
    );
};
