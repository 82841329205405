import * as React from 'react';
import * as style from './ControlButtons.scss';
import { Button_redesign as Button, ButtonTheme_redesign as ButtonTheme, WithTooltip } from 'sber-marketing-ui';

interface Props {
    disableRejectButton: boolean;
    onApproveClick: () => void;
    onRejectClick: () => void;
}

export const ControlButtons = ({ disableRejectButton, onApproveClick, onRejectClick }: Props): JSX.Element => {
    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'dashboardRightSidebarBondActivitiesWithBudgetItemIdControlButtons',
            }}
        >
            <Button
                theme={ButtonTheme.GhostRounded}
                onClick={onRejectClick}
                qaId="dashboardRightSidebarBondActivitiesWithBudgetItemIdControlButtonsRejectButton"
            >
                Отклонить
            </Button>

            <WithTooltip content="Cогласуйте название проекта" hidden={!disableRejectButton}>
                <Button
                    disabled={disableRejectButton}
                    theme={ButtonTheme.MainRoundedUpdated}
                    onClick={onApproveClick}
                    qaId="dashboardRightSidebarBondActivitiesWithBudgetItemIdControlButtonsApproveButton"
                >
                    Согласовать
                </Button>
            </WithTooltip>
        </div>
    );
};
