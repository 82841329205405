import * as React from 'react';

import * as style from './PivotTable.scss';

import type { HeaderView } from '@common/Page';

import { HeaderTop, HeaderBottom } from './Header';
import { WithClientData } from './WithClientData';
import { CreativeTable } from './CreativeTable';
import { Lot1TotalTable } from './Lot1TotalTable';
import { Lot2TotalTable } from './Lot2TotalTable';
import { Sidebar } from '@modules/activity/pages/creative/Sidebar';
import { useUrlHooks, useUserConfigHooks, useDownloadXLSXHooks } from './hooks';

interface Props {
    setHeaderView?: (view: HeaderView) => void;
}

export const PivotTable = ({ setHeaderView }: Props) => {
    const creativeTableRef = React.useRef<CreativeTable>(null);
    const totalTableRef = React.useRef<Lot1TotalTable | Lot2TotalTable>(null);

    const { lot, creativeRequestId, setLot, setCreativeRequestId, clearQuery } = useUrlHooks();

    const {
        onContractChange,
        onColumnWidthsChange,
        onArchivedFilterChange,
        onFixedColumnsChange,
        onVisibleColumnsChange,
        onFiltersChange,
        onCreativeTableLoad,
    } = useUserConfigHooks({
        creativeTableRef,
    });

    const { onDownloadXLSXButtonClick } = useDownloadXLSXHooks({
        lot,
        creativeTableRef,
        totalTableRef,
    });

    React.useEffect(() => {
        if (!lot) {
            setLot(1);
        }
    }, []);

    React.useEffect(() => {
        setHeaderView({
            firstLine: <HeaderTop />,
            additionalContent: (
                <HeaderBottom
                    onDownloadXLSXButtonClick={onDownloadXLSXButtonClick}
                    onContractChange={onContractChange}
                />
            ),
        });
    }, [lot]);

    function onSidebarClose() {
        clearQuery();
    }

    return (
        <div className={style.root}>
            {!!lot && (
                <>
                    <div className={style.content}>
                        <WithClientData
                            lot={lot}
                            creativeTableRef={creativeTableRef}
                            totalTableRef={totalTableRef}
                            creativeRequestId={creativeRequestId}
                            setLot={setLot}
                            onLoad={() => onCreativeTableLoad(lot)}
                            onContractChange={onContractChange}
                        >
                            {({
                                loading,
                                getLine,
                                getSubLine,
                                getLines,
                                getSubLines,
                                getSubLinesByLineId,
                                getTableSettings,
                                getDictionaries,
                                createLine,
                                createSubLine,
                                transferLine,
                                archiveLine,
                                restoreLine,
                            }) => (
                                <>
                                    <CreativeTable
                                        ref={creativeTableRef}
                                        lot={lot}
                                        loading={loading}
                                        setCreativeRequestId={setCreativeRequestId}
                                        getLine={getLine}
                                        getSubLine={getSubLine}
                                        getLines={getLines}
                                        getSubLines={getSubLines}
                                        getSubLinesByLineId={getSubLinesByLineId}
                                        getDictionaries={getDictionaries}
                                        createLine={createLine}
                                        createSubLine={createSubLine}
                                        transferLine={transferLine}
                                        archiveLine={archiveLine}
                                        restoreLine={restoreLine}
                                        onColumnWidthsChange={onColumnWidthsChange}
                                        onArchivedFilterChange={onArchivedFilterChange}
                                        onFixedColumnsChange={onFixedColumnsChange}
                                        onVisibleColumnsChange={onVisibleColumnsChange}
                                        onFiltersChange={onFiltersChange}
                                    />

                                    <div className={style.totalTable}>
                                        {lot === 1 && (
                                            <Lot1TotalTable
                                                ref={totalTableRef as React.MutableRefObject<Lot1TotalTable>}
                                                loading={loading}
                                                getLine={getLine}
                                                getSubLine={getSubLine}
                                                getLines={getLines}
                                                getSubLines={getSubLines}
                                                getSubLinesByLineId={getSubLinesByLineId}
                                                getTableSettings={getTableSettings}
                                                getDictionaries={getDictionaries}
                                            />
                                        )}

                                        {lot === 2 && (
                                            <Lot2TotalTable
                                                ref={totalTableRef as React.MutableRefObject<Lot2TotalTable>}
                                                loading={loading}
                                                getLine={getLine}
                                                getSubLine={getSubLine}
                                                getLines={getLines}
                                                getSubLines={getSubLines}
                                                getSubLinesByLineId={getSubLinesByLineId}
                                                getTableSettings={getTableSettings}
                                                getDictionaries={getDictionaries}
                                            />
                                        )}
                                    </div>
                                </>
                            )}
                        </WithClientData>
                    </div>

                    {creativeRequestId && (
                        <div className={style.sidebar}>
                            <Sidebar creativeRequestId={creativeRequestId} onClose={onSidebarClose} />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
