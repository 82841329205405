import * as lodash from 'lodash';
import * as moment from 'moment';

import type { AutopilotTPeriodPercent } from 'sber-marketing-types/backend';

import { store } from '@store';
import { setBriefFormValues } from '@store/autopilotTv/actions';

export const enum ColumnHeaderType {
    Text = 'text',
}

export const enum LineType {
    Line = 'line',
}

export const enum ColumnName {
    Name = 'name',
    Sum = 'sum',
}

export const enum CellType {
    Text = 'text',
    Input = 'input',
    Select = 'select',
}

export interface AccessorParams {
    id: string;
    manualRatingSplit: AutopilotTPeriodPercent[];
}

export type Accessor = (params: AccessorParams) => any;
export type TitleAccessor = (params: AccessorParams) => string;
export type ValueSetter = (params: AccessorParams, value: any) => Promise<void>;
export type ItemsAccessor = (params: AccessorParams) => any[];
export type DescriptionAccessor = (params: AccessorParams) => string;
export type CustomStyleAccessor = (params: AccessorParams) => React.CSSProperties;

export interface ColumnParams {
    title: string;
    headerType: ColumnHeaderType;
    type: Record<LineType, CellType>;
    defaultWidth: number;
    disableWidthChange?: boolean;
    readOnly?: Partial<Record<LineType, boolean>>;
    customStyle?: Partial<Record<LineType, CustomStyleAccessor>>;
    linkedColumns?: Partial<Record<LineType, ColumnName[]>>;
    getTitle?: Partial<Record<LineType, TitleAccessor>>;
    getValue: Record<LineType, Accessor>;
    setValue?: Partial<Record<LineType, ValueSetter>>;
    getItems?: Partial<Record<LineType, ItemsAccessor>>;
    getDescription?: Partial<Record<LineType, DescriptionAccessor>>;
}

export interface ColumnsConfigParams {
    weeks: { dateStart: string; dateEnd: string }[];
}

export const MakeColumnsConfig: (columnsConfigParams: ColumnsConfigParams) => { [columnName: string]: ColumnParams } = (
    columnsConfigParams: ColumnsConfigParams,
) => ({
    [ColumnName.Name]: {
        title: '',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
        },
        customStyle: {
            [LineType.Line]: (params: AccessorParams) => ({
                fontWeight: 600,
            }),
        },
        defaultWidth: 200,
        readOnly: {
            [LineType.Line]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => `TRP's, %`,
        },
    },
    [ColumnName.Sum]: {
        title: 'Всего',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
        },
        defaultWidth: 180,
        disableWidthChange: true,
        readOnly: {
            [LineType.Line]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => lodash.sumBy(params.manualRatingSplit, (item) => item.value),
        },
    },
    ...columnsConfigParams.weeks.reduce((acc, item, index) => {
        acc[`month${index}`] = {
            title: makeMonthTitle(item.dateStart, item.dateEnd),
            headerType: ColumnHeaderType.Text,
            type: {
                [LineType.Line]: CellType.Input,
            },
            defaultWidth: 180,
            disableWidthChange: true,
            readOnly: {
                [LineType.Line]: false,
            },
            getValue: {
                [LineType.Line]: (params: AccessorParams) => params.manualRatingSplit[index]?.value,
            },
            setValue: {
                [LineType.Line]: async (params: AccessorParams, value: any) => {
                    const updatedManualRatingSplit = lodash.clone(params.manualRatingSplit);

                    const parsedValue = value !== '' ? parseInt(value, 10) : 0;

                    updatedManualRatingSplit[index].value = parsedValue;

                    store.dispatch(
                        setBriefFormValues({
                            manualRatingSplit: updatedManualRatingSplit,
                        }),
                    );
                },
            },
        };

        return acc;
    }, {}),
});

export const MakeTableColumns: (columnsConfigParams: ColumnsConfigParams) => string[] = (columnsConfigParams) => [
    ColumnName.Name,
    ColumnName.Sum,
    ...columnsConfigParams.weeks.map((item, index) => `month${index}`),
];

export const leftFixedColumns: ColumnName[] = [];

export const rightFixedColumns: ColumnName[] = [];

function makeMonthTitle(dateStart: string, dateEnd: string): string {
    let title: string;

    const startDate = moment(dateStart);
    const endDate = moment(dateEnd);

    if (startDate.clone().startOf('month').isSame(endDate.clone().startOf('month'))) {
        title = `${startDate.format('MMMM')}\n${startDate.format('D')} — ${endDate.format('D')}`;
    } else {
        title = `${startDate.format('MMMM D')} —\n${endDate.format('MMMM D')}`;
    }

    return title;
}
