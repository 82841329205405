import * as React from 'react';
import type { ExcessReserveWarningProps } from '../ExcessReserveWarning';
import { ExcessReserveWarning } from '../ExcessReserveWarning';

import * as style from './ExcessReserveWarningList.scss';

interface Props {
    warnings: ExcessReserveWarningProps[];
}

const ExcessReserveWarningList: React.FC<Props> = ({ warnings }) => {
    return (
        <div className={style.root}>
            {warnings.map((warning, index) => (
                <div key={index} className={style.lineWarningWrapper}>
                    <ExcessReserveWarning {...warning} />
                </div>
            ))}
        </div>
    );
};

export type { ExcessReserveWarningProps };
export { ExcessReserveWarningList };
