import * as React from 'react';
import classNames from 'classnames';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';

import { FileData } from '@modules/files/types';
import { getFileUrl } from '@modules/files/utils';

import { Flex, FlexProps } from '@common/components';

import * as styles from './DownloadFiles.scss';

export interface DownloadFilesProps extends FlexProps<'button'> {
    files: FileData[];
    fileName: string;
}

export function DownloadFiles({
    className,
    loading,
    files,
    fileName,
    children,
    onClick,
    ...props
}: DownloadFilesProps) {
    const [isLoading, setLoading] = React.useState(false);
    if (!files.length) return null;

    const handleClick: typeof onClick = async (e) => {
        setLoading(true);
        const links = await Promise.all(files.map((file) => getFileUrl(file)));
        const zip = new JSZip();
        const fileNames: Record<string, boolean> = {};

        links.forEach((url: string, index) => {
            const file = files[index];
            let { originName } = file;
            let normName = originName;
            let i = 1;

            while (fileNames[normName]) {
                normName = `${originName} (${i})`;
            }

            fileNames[normName] = true;

            zip.file(
                `${normName}.${file.type}`,
                fetch(url).then((data) => data.blob()),
                { binary: true },
            );
        });

        zip.generateAsync({ type: 'blob' })
            .then((data) => saveAs(data, fileName))
            .finally(() => {
                setLoading(false);
            });

        onClick?.(e);
    };

    return (
        <Flex
            data-qa-id="DownloadFiles"
            {...props}
            loading={loading || isLoading}
            element="button"
            onClick={handleClick}
            className={classNames(styles.root, className)}
        >
            {children}
        </Flex>
    );
}
