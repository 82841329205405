import { Dictionary } from 'lodash';
import { DepartmentAttributes } from 'sber-marketing-types/frontend';

import { LoadingStatus } from '@store/commonTypes';

export interface DepartmentsState {
    entities: DepartmentAttributes[];
    byIds: Dictionary<DepartmentAttributes>;
    byParentIds: Dictionary<DepartmentAttributes[]>;
    byOrganizationIds: Dictionary<DepartmentAttributes[]>;
    stores: Record<StoreTypes, EntitiesStore>;
}

export interface EntitiesStore {
    fetchersCount: number;
    loadingStatus: LoadingStatus;
    ids: string[];
}

export const enum StoreTypes {
    GENERAL = 'GENERAL',
    ALL_DEPARTMENTS = 'ALL_DEPARTMENTS',
    MY_TASKS_FILTER = 'MY_TASKS_FILTER',
    ACTIVITY_PAGE_FILTERS = 'ACTIVITY_PAGE_FILTERS',
    AVAILABLE_ACTIVITIES_RESPONSIBLE_FILTERS = 'AVAILABLE_ACTIVITIES_RESPONSIBLE_FILTERS',
    MY_ACTIVITIES_RESPONSIBLE_FILTERS = 'AVAILABLE_ACTIVITIES_RESPONSIBLE_FILTERS',
}

export interface LoadDepartmentsParams {
    store: StoreTypes;
    ids?: string[];
}

export interface SetStoreIdsParams {
    store: StoreTypes;
    ids: string[];
}

export interface SetLoadingStatusParams {
    store: StoreTypes;
    status: LoadingStatus;
}

export type LoadEntitiesPayload = DepartmentAttributes[];
