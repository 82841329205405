import { actionCreatorFactory } from 'typescript-fsa';
import { UserRelatedOrganizations } from 'sber-marketing-types/frontend';

const creator = actionCreatorFactory('USER_ORGANIZATIONS');

/** Load user's related organizations from server and put them into store */
export const loadUserOrganizations = creator.async<null | undefined, UserRelatedOrganizations, Error>(
    'LOAD_USER_ORGANIZATIONS',
);

/** Reset user's related organizations */
export const resetUserOrganizations = creator('RESET_USER_ORGANIZATIONS');
