import * as React from 'react';
import { orderBy } from 'lodash';

import { Comment } from '@common/CommentsBlock';
import type { CommentProps } from '@common/CommentsBlock';
import { BudgetItemStatus } from './BudgetItemStatus';
import type { BudgetItemStatusProps } from './BudgetItemStatus';
import * as styles from './CommentsList.scss';

interface Props {
    isLoading: boolean;
    comments: CommentProps[];
    changesBudgetItemStatus: BudgetItemStatusProps[];
    pendingComments: CommentProps[];
}

export const CommentsList: React.FC<Props> = ({ isLoading, pendingComments, comments, changesBudgetItemStatus }) => {
    const showStub = !(pendingComments.length || comments.length || isLoading);

    const list = [
        ...comments.map((comment) => ({ ...comment, time: comment.createTime, type: 'comment' })),
        ...changesBudgetItemStatus.map((changedBudgetItemStatus) => ({ ...changedBudgetItemStatus, type: 'status' })),
    ];

    return (
        <div className={styles.root}>
            {pendingComments.map((pendingComment) => (
                <Comment key={pendingComment.id} pending {...pendingComment} />
            ))}

            {orderBy(list, (listItem) => listItem.time, ['desc']).map((listItem) => {
                switch (listItem.type) {
                    case 'comment':
                        return <Comment key={listItem.id} {...listItem} />;
                    case 'status':
                        const { id, status, time, comment } = listItem as BudgetItemStatusProps;
                        return (
                            <div className={styles.listItemWrapper} key={`${id}-${time}`}>
                                <BudgetItemStatus id={id} status={status} time={time} comment={comment} />
                            </div>
                        );
                    default:
                        return <Comment key={listItem.id} {...listItem} />;
                }
            })}

            {isLoading && <Comment />}

            {showStub && <div className={styles.noCommentsStub}>Комментарии отсутствуют</div>}
        </div>
    );
};
