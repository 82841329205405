import * as React from 'react';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { BudgetItem } from '@mrm/budget';

import { BudgetItemApi } from '@api';

import { StoreState } from '@store';
import {
    getLineModalState,
    hideLineModal,
    clearLineModal,
    getBudgetItemByLineId,
    setShowTagsHaveChangedMarker,
} from '@store/budgetPlanning';

import { LineModal } from './LineModal';

interface Props extends Partial<MapProps & DispatchProps> {}

interface MapProps {
    lineId: string;
    budgetItem: BudgetItem;
}

interface State {
    sourceBudgetItem: BudgetItem;
    lineTagshaveChanged: boolean;
}

interface DispatchProps {
    hideLineModal: () => void;
    clearLineModal: () => void;
    setShowTagsHaveChangedMarker: (payload: boolean) => void;
}

@(connect(mapStoreToProps, mapDispatchToProps) as any)
export class LineModalContainer extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            sourceBudgetItem: null,
            lineTagshaveChanged: false,
        };
    }

    public async componentDidMount(): Promise<void> {
        if (this.props.budgetItem?.sourceBudgetItemId) {
            const sourceBudgetItem = await BudgetItemApi.getBudgetItem(this.props.budgetItem?.sourceBudgetItemId);

            this.setState({
                sourceBudgetItem,
            });
        }
    }

    public componentWillUnmount(): void {
        if (this.state.lineTagshaveChanged) {
            this.props.setShowTagsHaveChangedMarker(true);
        }

        this.props.clearLineModal();
    }

    public render(): JSX.Element {
        const { lineId, budgetItem } = this.props;
        const { sourceBudgetItem } = this.state;

        return React.createElement(LineModal, {
            lineId,
            sourceBudgetItem,
            serialNumberOfBudgetItem: (budgetItem && budgetItem.serialNumber) || null,
            projectNameOfBudgetItem: (budgetItem && budgetItem.activity && budgetItem.activity.name) || null,
            onCloseButtonClick: this.onCloseButtonClick,
            onAppliedTagsChange: this.onAppliedTagsChange,
        });
    }

    @autobind
    private onAppliedTagsChange(): void {
        this.setState({ lineTagshaveChanged: true });
    }

    @autobind
    private onCloseButtonClick(): void {
        this.props.hideLineModal();
    }
}

function mapStoreToProps(store: StoreState): MapProps {
    const { lineId } = getLineModalState(store);

    return {
        lineId,
        budgetItem: getBudgetItemByLineId(store, lineId),
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return {
        hideLineModal: () => dispatch(hideLineModal()),
        clearLineModal: () => dispatch(clearLineModal()),
        setShowTagsHaveChangedMarker: (payload: boolean) => dispatch(setShowTagsHaveChangedMarker(payload)),
    };
}
