import * as React from 'react';
import gql from 'graphql-tag';
import { get, uniq } from 'lodash';
import { graphql } from 'react-apollo';

import { BudgetItemLink } from './types';

const MONTHS_FRAGMENT = 'jan feb mar apr may jun jul aug sept oct nov dec';
const GET_BUDGET_ITEM_LINKS_QUERY = gql`
    query getBudgetItemLinks($linkIds: [String]!) {
        links: getProjectLinkBudgetItemApprovalRequests(filter: { id: $linkIds }) {
            nodes {
                id
                status
                createdAt
                permissions {
                    read
                    delete
                }
                project {
                    id
                    permissions {
                        read
                    }
                }
                budgetItem {
                    id
                    serialNumber
                    author {
                        organizationId
                    }
                    permissions {
                        read
                        write
                    }
                    budget {
                        id
                        year
                        organizationIds
                        actions {
                            createActivity
                        }
                    }
                    dictionary {
                        tool {
                            value
                            status
                        }
                        segment {
                            value
                        }
                        product {
                            value
                        }
                    }
                    plannedFunds { ${MONTHS_FRAGMENT} }
                    reservedFunds { ${MONTHS_FRAGMENT} }
                    factFunds { ${MONTHS_FRAGMENT} }
                }
                author {
                    firstName
                    secondName
                }
                approver {
                    id
                    firstName
                    secondName
                }
            }
        }
    }
`;

export interface BudgetItemLinksData {
    loading: boolean;
    items: BudgetItemLink[];
    organizationIds: string[];
}

interface Props extends ExternalProps, QueryProps {}

interface ExternalProps {
    userOrganizationId: string;
    linkIds: string[];
    forwardData: (props: BudgetItemLinksData) => void;
}

type QueryProps = {
    budgetItemLinksQuery: {
        loading: boolean;
        links: {
            nodes: BudgetItemLink[];
        };
    };
};

class BudgetItemLinksFetcherBehaviour extends React.PureComponent<Props> {
    public componentDidUpdate(prevProps: Props): void {
        if (!this.props.budgetItemLinksQuery?.loading && prevProps.budgetItemLinksQuery?.loading) {
            this.forwardData();
        }
    }

    public render(): JSX.Element {
        return null;
    }

    private forwardData(): void {
        const { budgetItemLinksQuery, userOrganizationId } = this.props;

        const loading = budgetItemLinksQuery ? budgetItemLinksQuery.loading : true;
        const items: BudgetItemLink[] = get(budgetItemLinksQuery, 'links.nodes') || [];
        const organizationIds = uniq([
            ...items.map((item) => item.budgetItem.author.organizationId),
            userOrganizationId,
        ]);

        this.props.forwardData({ loading, items, organizationIds });
    }
}

export const BudgetItemLinksFetcher = graphql<ExternalProps>(GET_BUDGET_ITEM_LINKS_QUERY, {
    name: 'budgetItemLinksQuery',
    options: ({ linkIds }) => ({
        variables: {
            linkIds,
        },
        fetchPolicy: 'no-cache',
    }),
    skip: ({ linkIds }) => !linkIds?.length,
})(BudgetItemLinksFetcherBehaviour);
