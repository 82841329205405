import * as React from 'react';
import * as lodash from 'lodash';

import { BudgetInfoContainer } from './BudgetInfoContainer';
import { WithStore } from './WithStore';
import { WithCard } from './WithCard';
import { WithCorrections } from './WithCorrections';

interface Props {
    hasBudgetItemAccess: boolean;
}

export class BudgetInfoConnected extends React.PureComponent<Props> {
    public render() {
        const { hasBudgetItemAccess } = this.props;

        return (
            <WithStore>
                {({ cardId, activityId }) => (
                    <WithCard cardId={cardId}>
                        {({ card, loading: cardLoading, updateCard, refetch }) => (
                            <WithCorrections
                                budgetId={lodash.get(card, 'budgetItem.budget.id')}
                                serialNumber={lodash.get(card, 'budgetItem.serialNumber')}
                            >
                                {({ corrections, loading: correctionsLoading, updateCorrections }) =>
                                    React.createElement(BudgetInfoContainer, {
                                        hasBudgetItemAccess,
                                        activityId,
                                        card,
                                        corrections,
                                        loading: cardLoading || correctionsLoading,
                                        updateCard,
                                        updateCorrections,
                                        refetchData: refetch,
                                    })
                                }
                            </WithCorrections>
                        )}
                    </WithCard>
                )}
            </WithStore>
        );
    }
}
