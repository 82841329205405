// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-main-Link-_Link__root{color:#417EC6;text-decoration:none;transition:color .3s}@media (pointer: fine){.src-client-modules-common-components-main-Link-_Link__root:hover{color:#2159b8}}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/main/Link/Link.scss"],"names":[],"mappings":"AAAA,4DACE,aAAc,CACd,oBAAqB,CACrB,oBAAqB,CAErB,uBALF,kEAOM,aAAc,CACf","sourcesContent":[".root {\n  color: #417EC6;\n  text-decoration: none;\n  transition: color .3s;\n\n  @media (pointer: fine) {\n    &:hover {\n      color: #2159b8;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-components-main-Link-_Link__root"
};
export default ___CSS_LOADER_EXPORT___;
