import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconType, WithTooltip } from 'sber-marketing-ui';

import * as style from './FlagToggle.scss';

interface Props {
    qaId?: string;
    selected: boolean;
    mode: Mode;
    onClick: Onclick;
    tooltip?: string;
    size?: number;
    hover?: boolean;
}

type Onclick = (event: EventClick) => void;

export type EventClick = React.MouseEvent<HTMLDivElement, MouseEvent>;

export const enum Mode {
    VIEW = 'view',
    CHANGE = 'change',
}

const SVG_SIZE = 20;

type TemplateMap = {
    [P in Mode]: (props: Props) => JSX.Element;
};

const templateMap: TemplateMap = {
    [Mode.CHANGE]: (props: Props) => (
        <WithTooltip content={props.tooltip} hidden={!props.tooltip}>
            <div
                className={classNames(
                    style.root,
                    style[props.mode],
                    props.hover && style.hover,
                    props.selected ? style.selected : style.noSelected,
                )}
                onClick={props.onClick}
                {...{
                    'qa-id': 'flagToggle',
                    'qa-is-selected': props.selected ? 'true' : 'false',
                }}
            >
                {props.selected ? (
                    <Icon type={IconType.FULL_FLAG} svgSize={props.size || SVG_SIZE} />
                ) : (
                    <Icon type={IconType.EMPTY_FLAG} svgSize={props.size || SVG_SIZE} />
                )}
            </div>
        </WithTooltip>
    ),
    [Mode.VIEW]: (props: Props) => {
        return props.selected ? (
            <WithTooltip content={props.tooltip} hidden={!props.tooltip}>
                <div
                    className={classNames(style.root, style.selected)}
                    {...{
                        'qa-id': 'flagToggle',
                        'qa-is-selected': props.selected ? 'true' : 'false',
                    }}
                >
                    <Icon type={IconType.FULL_FLAG} svgSize={props.size || SVG_SIZE} />
                </div>
            </WithTooltip>
        ) : null;
    },
};

export const FlagToggle: React.FC<Props> = (props) => {
    return <>{templateMap[props.mode](props)}</>;
};
