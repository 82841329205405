import * as React from 'react';

import { usePopup } from '@common/hooks';

const hideContext = React.createContext<() => void>(undefined);

export const HideFilterProvider = hideContext.Provider;

export function useHideFilter() {
    return React.useContext(hideContext);
}

export function useFilterButtonPopup(): [boolean, boolean, (show: boolean) => void] {
    const [show, setShow] = usePopup();
    const shown = React.useRef(false);

    return [
        show,
        shown.current,
        (value) => {
            if (value) {
                shown.current = true;
            }
            setShow(value);
        },
    ];
}
