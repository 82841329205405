import * as React from 'react';
import * as lodash from 'lodash';
import classNames from 'classnames';
import { BudgetStatus } from '@mrm/budget';
import { MenuItem } from 'sber-marketing-ui';

import { QuickAccessIcons } from '@common/QuickAccessIcons';

import { BudgetPlanning } from './BudgetPlanning';
import { BudgetExecution } from './BudgetExecution';
import { BudgetCorrections } from './BudgetCorrections';
import { BudgetAndOrganizationSelector } from './BudgetAndOrganizationSelector';
import { Captions, Money, MoneyFormatter } from '@common/Utils';

import * as style from './BudgetPage.scss';

export const enum PageMode {
    PlanTable = 'planTable',
    ExecutionTable = 'executionTable',
    CorrectionsList = 'correctionsList',
    ArchivePage = 'archivePage',
}

export const enum PageUrl {
    PlanTable = '/budget/planning',
    ExecutionTable = '/budget/execution',
    CorrectionsList = '/budget/corrections',
    ArchivePage = '',
}

const TOP_LINK_OPTIONS: LinkOptions[] = [
    {
        qaId: 'headerMenuBudgetPlanning',
        link: PageUrl.PlanTable,
        title: 'Планирование',
    },
    {
        qaId: 'headerMenuBudgetExecution',
        link: PageUrl.ExecutionTable,
        title: 'Исполнение',
        contains: [PageUrl.ExecutionTable, PageUrl.CorrectionsList],
    },
];

const BOTTOM_LINK_OPTIONS: LinkOptions[] = [
    {
        qaId: 'headerMenuBudgetExecutionTable',
        link: PageUrl.ExecutionTable,
        title: 'Таблица',
    },
    {
        qaId: 'headerMenuBudgetExecutionCorrections',
        link: PageUrl.CorrectionsList,
        title: 'Корректировки',
    },
];

interface Props {
    selectedPlanBudgetId: string;
    selectedExecutionBudgetId: string;
    canRenderPlanPage?: boolean;
    canRenderExecutionPage?: boolean;
    currentPage: PageMode;
    scrollPageToTop: () => void;
}

interface HeaderProps {
    currentPage: PageMode;
    currentUrl?: string;
    budgetStatus?: BudgetStatus;
    totalBudget?: number;
}

export interface LinkOptions {
    qaId?: string;
    title: string;
    link: PageUrl;
    contains?: PageUrl[];
    disabled?: boolean;
}

export const BudgetListPage = ({
    selectedPlanBudgetId,
    selectedExecutionBudgetId,
    currentPage,
    scrollPageToTop,
    canRenderPlanPage,
    canRenderExecutionPage,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            {currentPage == PageMode.PlanTable && canRenderPlanPage && <BudgetPlanning />}

            {currentPage == PageMode.ExecutionTable && canRenderExecutionPage && <BudgetExecution />}

            {currentPage == PageMode.CorrectionsList && canRenderExecutionPage && (
                <BudgetCorrections scrollPageToTop={scrollPageToTop} />
            )}
        </div>
    );
};

export const HeaderTop: React.FC<HeaderProps> = ({ currentUrl, budgetStatus, totalBudget }) => {
    return (
        <div className={style.headerRoot}>
            <div className={style.headerLeftColumn}>
                <div className={style.headerLeftColumnContent}>
                    <BudgetAndOrganizationSelector budgetStatus={budgetStatus} />
                </div>
            </div>

            <div className={style.headerCentralColumn}>
                {TOP_LINK_OPTIONS.map((item) => (
                    <MenuItem
                        qaId={item.qaId}
                        key={item.link}
                        currentUrl={currentUrl}
                        link={item.link}
                        disabled={item.disabled}
                        active={item.link == currentUrl || lodash.includes(item.contains, currentUrl)}
                    >
                        <span>{item.title}</span>
                    </MenuItem>
                ))}
            </div>

            <div className={style.headerRightColumn}>
                <TotalBudget value={totalBudget} />
                <QuickAccessIcons />
            </div>
        </div>
    );
};

export const HeaderBottom = ({ currentPage, currentUrl }: HeaderProps): JSX.Element => {
    const useCorrectionsPageStyles = currentPage === PageMode.CorrectionsList;
    const activeTopLink = TOP_LINK_OPTIONS.find(
        (item) => item.link == currentUrl || lodash.includes(item.contains, currentUrl),
    );

    const bottomLinks = BOTTOM_LINK_OPTIONS.filter(
        (item) => activeTopLink.contains && lodash.includes(activeTopLink.contains, item.link),
    );

    return (
        !!bottomLinks.length && (
            <div className={classNames(style.headerRoot, useCorrectionsPageStyles && style.headerRootCorrectionsPage)}>
                <div className={style.headerBottom}>
                    <div className={style.headerBottomContent}>
                        {bottomLinks.map((item) => (
                            <MenuItem
                                key={item.link}
                                currentUrl={currentUrl}
                                link={item.link}
                                disabled={item.disabled}
                                active={item.link == currentUrl}
                            >
                                <span>{item.title}</span>
                            </MenuItem>
                        ))}
                    </div>
                </div>
            </div>
        )
    );
};

interface TotalBudgetProps {
    value: number;
}

const TotalBudget: React.FC<TotalBudgetProps> = ({ value }) => {
    const moneyValue = MoneyFormatter.toThousands(Money.fromCopecks(value), {
        hideCaption: true,
    });

    return (
        <div className={style.totalBudget}>
            Общий бюджет:&nbsp;
            <span className={style.totalBudgetSum}>
                <span
                    {...{
                        'qa-id': 'tableTotalBudget',
                    }}
                >
                    {moneyValue}
                </span>
                &nbsp;{Captions.Thousands}
            </span>
        </div>
    );
};
