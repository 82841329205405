import * as React from 'react';
import { UserResponseParams } from 'sber-marketing-types/frontend';
import {
    SelectItem,
    Select_redesign as Select,
    SelectTheme_redesign as SelectTheme,
    OpenerIconType_redesign,
} from 'sber-marketing-ui';

import * as styles from './ExpertSelect.scss';

interface Props {
    experts: UserResponseParams[];
    selectedExpert: number;
    onSelect: (item: SelectItem) => void;
}

export function ExpertSelect({ experts, selectedExpert, onSelect }: Props): JSX.Element {
    return (
        <React.Fragment>
            <div className={styles.title}>Согласующий эксперт</div>

            <div className={styles.itemSelect}>
                <Select
                    qaId="budgetTransferMenuExpertSelect"
                    placeholder="Выберите"
                    theme={SelectTheme.BudgetTransferMenu}
                    openerIcon={OpenerIconType_redesign.BudgetTransferAppor}
                    options={experts.map((expert) => ({
                        label: `${expert.firstName} ${expert.secondName}`,
                        value: expert.id,
                    }))}
                    value={selectedExpert}
                    onChange={onSelect}
                />
            </div>
        </React.Fragment>
    );
}
