import * as React from 'react';
import * as lodash from 'lodash';
import classNames from 'classnames';

import * as style from './AutopilotWidget.scss';

import { AutopilotPlacementName, AutopilotPlacementStatus } from 'sber-marketing-types/backend';

import { Icon, IconType } from 'sber-marketing-ui';
import { DashboardUrlEditor } from './DashboardUrlEditor';
import { PlacementStatusEditor } from './PlacementStatusEditor';

interface Props {
    dashboardUrl: string;
    userHasAutopilotClientAccess: boolean;
    stages: StageProps[];
    placementItems: Omit<PlacementItemProps, 'canEditStatus' | 'onStatusSelection'>[];
    onDashboardUrlChange: (value: string) => void;
    onStatusSelection: (name: AutopilotPlacementName, value: AutopilotPlacementStatus) => void;
}

export const AutopilotWidgetTemplate = ({
    dashboardUrl,
    userHasAutopilotClientAccess,
    stages,
    placementItems,
    onDashboardUrlChange,
    onStatusSelection,
}: Props): JSX.Element => {
    const finishedStagesCount = stages.filter((item) => item.isFinished).length;
    const canEditDashboardUrl = userHasAutopilotClientAccess;
    const canEditPlacementStatus = userHasAutopilotClientAccess;

    return (
        <div className={style.root}>
            <div className={style.topLine}>
                {finishedStagesCount < 5 && (
                    <div className={style.title}>
                        Этапы автопилота&nbsp;
                        <span className={style.titleCounter}>({finishedStagesCount}/5)</span>
                    </div>
                )}

                {finishedStagesCount == 5 && (
                    <>
                        <div className={style.title}>
                            Продвижение имиджевых роликов
                            {(dashboardUrl || canEditDashboardUrl) && (
                                <>
                                    ,&nbsp;
                                    <a className={style.link} href={dashboardUrl}>
                                        посмотреть в дашборде
                                    </a>
                                </>
                            )}
                            {canEditDashboardUrl && (
                                <div className={style.urlEditor}>
                                    <DashboardUrlEditor value={dashboardUrl} onSave={onDashboardUrlChange} />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>

            {!lodash.isEmpty(placementItems) && (
                <div className={style.placementItems}>
                    {placementItems.map((item) => (
                        <PlacementItem
                            {...item}
                            canEditStatus={canEditPlacementStatus}
                            onStatusSelection={onStatusSelection}
                            key={item.name}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export interface StageProps {
    stageOrder: number;
    name: string;
    title: string;
    url: string;
    isFinished: boolean;
}
export interface PlacementItemProps {
    name: AutopilotPlacementName;
    title: string;
    icon: IconType;
    status: AutopilotPlacementStatus;
    canEditStatus: boolean;
    onStatusSelection: (name: string, value: AutopilotPlacementStatus) => void;
}

function PlacementItem({ name, title, icon, status, canEditStatus, onStatusSelection }: PlacementItemProps) {
    return (
        <div className={style.placementItem}>
            <div className={style.itemIcon}>
                <Icon type={icon} svgSize={24} />
            </div>

            <div className={style.itemTitle}>{title}</div>

            {(name !== 'youtube' || !canEditStatus) && (
                <div
                    className={classNames(
                        style.itemStatus,
                        ['Запущено', 'Завершено'].includes(status) && style.running,
                        ['Ошибка размещения', 'Отклонено модератором'].includes(status) && style.stopped,
                        ['На модерации', 'Приостановлено', 'Неизвестный статус'].includes(status) && style.moderation,
                    )}
                >
                    {status}
                </div>
            )}

            {name === 'youtube' && canEditStatus && (
                <div className={style.statusEditor}>
                    <PlacementStatusEditor status={status} onSelection={(value) => onStatusSelection(name, value)} />
                </div>
            )}
        </div>
    );
}
