import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { UserConfigType } from 'sber-marketing-types/openid';

import { StoreState } from '@store';
import { saveUserConfig, updateUserConfig } from '@store/userConfig';

import {
    BudgetUserConfig,
    LoadBudgetUserConfig,
    UpdateBudgetUserConfig,
    SaveBudgetByStatusUserConfigPayload,
} from './types';
import {
    loadBudgetUserConfig as loadBudgetUserConfigAction,
    updateBudgetUserConfig as updateBudgetUserConfigAction,
    saveBudgetByStatusUserConfig as saveBudgetByStatusUserConfigAction,
} from './actions';

import { getBudgetUserConfig } from './selectors';

export const loadBudgetUserConfig = bindThunkAction<StoreState, BudgetUserConfig, LoadBudgetUserConfig, Error>(
    loadBudgetUserConfigAction,
    async (receivedUserConfig, dispatch, getState) => {
        return {
            receivedUserConfig,
        };
    },
);

export const updateBudgetUserConfig = bindThunkAction<StoreState, UpdateBudgetUserConfig, void, Error>(
    updateBudgetUserConfigAction,
    async (_, dispatch) => {
        dispatch(updateUserConfig(UserConfigType.Budget));
    },
);

export const saveBudgetByStatusUserConfig = bindThunkAction<
    StoreState,
    SaveBudgetByStatusUserConfigPayload,
    void,
    Error
>(saveBudgetByStatusUserConfigAction, async (params, dispatch, getState) => {
    const existingUserConfig = getBudgetUserConfig(getState());

    const payload: BudgetUserConfig = {
        ...existingUserConfig,
        budgetStatus: {
            ...existingUserConfig.budgetStatus,
            [params.budgetStatus]: {
                ...existingUserConfig.budgetStatus[params.budgetStatus],
                ...params.payload,
            },
        },
    };

    dispatch(
        saveUserConfig({
            type: UserConfigType.Budget,
            payload,
        }),
    );
});
