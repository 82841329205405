import * as React from 'react';

import * as style from './MediaplanSettingsStageWidget.scss';

import { RadioSplit } from './RadioSplit';
import { BriefStrategy } from './BriefStrategy';

interface Props {
    readOnly?: boolean;
}

export const MediaplanSettingsStageWidget = ({ readOnly }: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.title}>Сплит радиостанций</div>

            <div className={style.form}>
                <RadioSplit />
            </div>

            <div className={style.title}>Расстановка роликов</div>

            <div className={style.form}>
                <BriefStrategy readOnly={readOnly} />
            </div>
        </div>
    );
};
