// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-BudgetItemHistoryWidget-HistoryItem-_HistoryItem__root{padding:10px 17px 12px 16px;border-radius:6px;background:#ffffff;box-shadow:0px 2px 10px rgba(0,0,0,0.1);font-family:'Open Sans'}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetPlanning/LineModal/WidgetsGroups/BudgetItemHistoryWidget/HistoryItem/HistoryItem.scss"],"names":[],"mappings":"AAAA,oIACI,2BAA4B,CAE5B,iBAAkB,CAClB,kBAAmB,CACnB,uCAA2C,CAE3C,uBAAwB","sourcesContent":[".root {\n    padding: 10px 17px 12px 16px;\n\n    border-radius: 6px;\n    background: #ffffff;\n    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);\n\n    font-family: 'Open Sans';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetPlanning-LineModal-WidgetsGroups-BudgetItemHistoryWidget-HistoryItem-_HistoryItem__root"
};
export default ___CSS_LOADER_EXPORT___;
