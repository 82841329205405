import { actionCreatorFactory } from 'typescript-fsa';

import { PageData, UnsavedChange } from './types';

const actionCreator = actionCreatorFactory('BUDGET_CORRECTIONS_PAGE');

const loadPageData = actionCreator<PageData>('LOAD_PAGE_DATA');
const resetPageState = actionCreator('RESET_PAGE_STATE');
const setUnsavedChange = actionCreator<UnsavedChange>('SET_UNSAVED_CHANGE');
const resetUnsavedChange = actionCreator('RESET_UNSAVED_CHANGE');
const setRejectComment = actionCreator<UnsavedChange['rejectComment']>('SET_REJECT_COMMENT');

export { loadPageData, resetPageState, setUnsavedChange, setRejectComment, resetUnsavedChange };
