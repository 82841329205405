import * as React from 'react';
import * as lodash from 'lodash';
import { UserResponseParams } from 'sber-marketing-types/frontend';
import { DictionaryType } from '@mrm/dictionary';
import { Tag } from '@mrm/tags';
import {
    FilterMenu as FilterMenuSelector,
    FilterMenuFilterControls,
    FilterCheckboxBorderType,
    Preloader,
} from 'sber-marketing-ui';

import { ColumnName, ColumnsNameWithCodes, GroupedDictionaries } from '@store/budgetPlanning';

import { Utils, Money, MoneyFormatter, DatesFormatter } from '@common/Utils';

import * as style from './FilterMenu.scss';

export interface Filters {
    [title: string]: boolean;
}

interface Props {
    showPreloader: boolean;
    filters: Filters;
    selectedFilters: React.ReactText[];
    filterNamesAreNumeral: boolean;
    currencySearch: boolean;
    dictionaryType: DictionaryType;
    columnName: ColumnName;
    users: UserResponseParams[];
    dictionaries: GroupedDictionaries;
    tags: lodash.Dictionary<Tag>;
    rootRef: (element: HTMLDivElement) => void;
    onFiltersChange: (selectedFiltersIds: React.ReactText[]) => void;
}

export const FilterMenu = ({
    showPreloader,
    filters,
    selectedFilters,
    filterNamesAreNumeral,
    currencySearch,
    dictionaries,
    dictionaryType,
    columnName,
    users,
    tags,
    rootRef,
    onFiltersChange,
}: Props): JSX.Element => {
    const filterNames = lodash.keys(filters);

    let sortedFilterNames = filterNamesAreNumeral ? sortStringsAsNumbers(filterNames) : filterNames.sort();

    const nullElement = lodash.find(sortedFilterNames, (item) => item == 'null' || item == '');

    if (nullElement !== undefined) {
        sortedFilterNames = lodash.without(sortedFilterNames, nullElement);

        sortedFilterNames.push(nullElement);
    }

    return (
        <div className={style.root} ref={rootRef} tabIndex={-1}>
            {showPreloader ? (
                <Preloader />
            ) : (
                <FilterMenuSelector
                    qaId="tableHeaderValuesToDisplayFilter"
                    filterTitle="Отображаемые значения"
                    filterControls={
                        currencySearch ? FilterMenuFilterControls.Interval : FilterMenuFilterControls.Regular
                    }
                    items={sortedFilterNames
                        .map((item, index) => ({
                            id: item,
                            title: getItemTitle(
                                item,
                                currencySearch,
                                dictionaryType,
                                columnName,
                                users,
                                dictionaries,
                                tags,
                            ),
                            order: filterNamesAreNumeral ? index : undefined,
                        }))
                        .filter((item) => !!item.title)}
                    checkedItemsIds={selectedFilters}
                    isExpanded
                    onItemSelection={onFiltersChange}
                    border={FilterCheckboxBorderType.NONE}
                    preserveAllSelectedState
                />
            )}
        </div>
    );
};

function sortStringsAsNumbers(filterNames: string[]): string[] {
    return filterNames.sort((itemA: string, itemB: string) => parseInt(itemA, 10) - parseInt(itemB, 10));
}

function formatUser(user: UserResponseParams): string {
    return user ? `${user.secondName} ${user.firstName}` : 'Пользователь не найден';
}

function getItemTitle(
    item: string,
    currencySearch: boolean,
    dictionaryType: DictionaryType,
    columnName: ColumnName,
    users: UserResponseParams[],
    dictionaries: GroupedDictionaries,
    tags: lodash.Dictionary<Tag>,
): string {
    if (item === 'null' || item === '' || item === 'undefined') {
        return 'Значение отсутствует';
    }

    if (columnName === ColumnName.Responsible) {
        const userId = Number(item);
        const user = users.find((user) => user.id === userId);

        return formatUser(user);
    }

    if (columnName === ColumnName.StartDate || columnName === ColumnName.EndDate) {
        return DatesFormatter.ddMonthyyyy(item);
    }

    if (columnName === ColumnName.Author) {
        const user = users.find((user) => user.id === Number(item));

        return formatUser(user);
    }

    if (columnName === ColumnName.Tags) {
        return tags[item]?.title || 'Тег не найден';
    }

    if (dictionaryType && !lodash.includes(ColumnsNameWithCodes, columnName)) {
        const currentDict = dictionaries.byId[item];

        return Utils.getDictionaryValue(currentDict);
    }

    if (currencySearch) {
        return MoneyFormatter.toThousands(Money.fromStringCopecks(item), { hideCaption: true });
    }

    return item;
}
