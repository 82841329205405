import { RoleId } from 'sber-marketing-types/backend';

import { useGetAuthUserQuery } from '@api';
import { useGetTaskChannel } from '@modules/task/hooks';

export type ChannelAccessRole = 'channelAuthor' | 'channelParticipant' | 'director';

export enum ChannelAccessAction {
    close = 'close',
    changeTitle = 'changeTitle',
    addParticipant = 'addParticipant',
    removeParticipant = 'removeParticipant',
    comment = 'comment',
}

export interface ChannelAccess {
    isLoading: boolean;
    access: boolean[];
}

export type ChannelAccessCondition = boolean;
export type ChannelAccessConditions = Record<ChannelAccessRole, ChannelAccessCondition>;

export const channelAccess: Record<ChannelAccessAction, ChannelAccessRole[]> = {
    close: ['channelAuthor', 'director'],
    changeTitle: ['channelAuthor'],
    addParticipant: ['channelAuthor', 'channelParticipant'],
    removeParticipant: ['channelAuthor'],
    comment: ['channelAuthor', 'channelParticipant'],
};

export function useChannelAccess(
    actions: ChannelAccessAction[],
    channelId: number,
    taskId: string,
    skip?: boolean,
): ChannelAccess {
    const { isLoading: isAuthUserLoading, data: authUser } = useGetAuthUserQuery(null, { skip });
    const { isLoading: isChannelLoading, channel } = useGetTaskChannel(taskId, channelId, skip);
    const access = actions.map(() => false);

    if (skip) return { isLoading: false, access };

    const isLoading = isAuthUserLoading || isChannelLoading;

    if (!isLoading) {
        const authUserId = authUser?.user.attributes.id;
        const authUserRoles = authUser?.user.attributes.roles;

        const conditions: ChannelAccessConditions = {
            channelAuthor: channel?.authorId === authUserId,
            channelParticipant: Boolean(channel?.participantIds?.includes(authUserId)),
            director: Boolean(authUserRoles?.find(({ id }) => id === RoleId.Director)),
        };

        for (let i = 0; i < actions.length; i++) {
            for (const role of channelAccess[actions[i]]) {
                if (conditions[role]) {
                    access[i] = true;
                    break;
                }
            }
        }
    }

    return { isLoading, access };
}
