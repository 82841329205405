import { EstimateValue } from './EstimateBarTemplate';
import { CSSProperties } from 'react';

const estimateValueColorMap: Record<EstimateValue, { textColor: string; backgroundColor: string }> = {
    1: {
        textColor: '#E63900',
        backgroundColor: '#FFEBEE',
    },
    2: {
        textColor: '#D94F0C',
        backgroundColor: '#FFEDE5',
    },
    3: {
        textColor: '#CE6216',
        backgroundColor: '#FFEFDF',
    },
    4: {
        textColor: '#C5701F',
        backgroundColor: '#FFF0D9',
    },
    5: {
        textColor: '#BE852F',
        backgroundColor: '#FFF4D7',
    },
    6: {
        textColor: '#B7953B',
        backgroundColor: '#FFF8D5',
    },
    7: {
        textColor: '#90973F',
        backgroundColor: '#F9F9D8',
    },
    8: {
        textColor: '#809740',
        backgroundColor: '#F2F9DC',
    },
    9: {
        textColor: '#5F9843',
        backgroundColor: '#E8F9E1',
    },
    10: {
        textColor: '#1F9A48',
        backgroundColor: '#DEF9E6',
    },
};

interface GetEstimateValueStyle {
    (params: GetEstimateValueStyleParams): CSSProperties;
}

interface GetEstimateValueStyleParams {
    currentEstimateValue: EstimateValue;
    hoveredEstimateValue: EstimateValue;
}

export const getEstimateValueStyle: GetEstimateValueStyle = ({ currentEstimateValue, hoveredEstimateValue }) => {
    return hoveredEstimateValue >= currentEstimateValue
        ? {
              backgroundColor: estimateValueColorMap[hoveredEstimateValue].backgroundColor,
              color: estimateValueColorMap[hoveredEstimateValue].textColor,
          }
        : null;
};
