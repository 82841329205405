export const TEXT_LENGTH_RANGE = {
    TITLE: 56,
    DESCRIPTION: 81,
};

export const TEXT_OPTIONS_LENGTH = 10;

export const SELECTED_TEXT_OPTION_LENGTH_RANGE = {
    MIN: 3,
    MAX: 5,
};
