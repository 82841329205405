import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';
import { WithTimeout } from './WithTimeout';
import { NotificationMessage, NotificationType } from '@store/common/types';

import * as style from './Notification.scss';

interface Props {
    index: number;
    type: NotificationMessage['type'];
    comment?: NotificationMessage['comment'];
    onClose: () => void;
}

type TemplateMap = {
    [P in NotificationType]: (props: Props) => JSX.Element;
};

export const Notification = (props: Props): JSX.Element => {
    return templateMap[props.type]({ ...props });
};

const Success = ({ type, onClose, comment, index }: Props): JSX.Element => {
    return (
        <WithTimeout time={5000} onFinishedTimeout={onClose}>
            <div
                className={classNames(style.root, style.success)}
                {...{
                    'qa-id': 'notification',
                    'qa-index': index,
                    'qa-type': 'success',
                }}
            >
                <div
                    className={style.closeButton}
                    onClick={onClose}
                    {...{
                        'qa-id': 'notificationCloseButton',
                    }}
                >
                    <Icon type={IconType.CROSS} />
                </div>

                <div
                    className={style.message}
                    {...{
                        'qa-id': 'notificationComment',
                    }}
                >
                    {comment}
                </div>
            </div>
        </WithTimeout>
    );
};

const Neutral = ({ type, onClose, comment, index }: Props): JSX.Element => {
    return (
        <WithTimeout time={5000} onFinishedTimeout={onClose}>
            <div
                className={style.neutral}
                {...{
                    'qa-id': 'notification',
                    'qa-index': index,
                    'qa-type': 'success',
                }}
            >
                <div
                    className={style.neutralMessage}
                    {...{
                        'qa-id': 'notificationComment',
                    }}
                >
                    {comment}
                </div>

                <div
                    className={style.neutralCloseButton}
                    onClick={onClose}
                    {...{
                        'qa-id': 'notificationCloseButton',
                    }}
                >
                    <Icon type={IconType.CROSS} />
                </div>
            </div>
        </WithTimeout>
    );
};

const Error = ({ type, onClose, comment, index }: Props): JSX.Element => {
    return (
        <div
            className={classNames(style.root, style.error)}
            {...{
                'qa-id': 'notification',
                'qa-index': index,
                'qa-type': 'error',
            }}
        >
            <div
                className={style.closeButton}
                onClick={onClose}
                {...{
                    'qa-id': 'notificationCloseButton',
                }}
            >
                <Icon type={IconType.CROSS} />
            </div>

            <div
                className={style.message}
                {...{
                    'qa-id': 'notificationComment',
                }}
            >
                {comment}
            </div>
        </div>
    );
};

const templateMap: TemplateMap = {
    [NotificationType.SUCCESS]: Success,
    [NotificationType.ERROR]: Error,
    [NotificationType.NEUTRAL]: Neutral,
};
