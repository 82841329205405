import * as React from 'react';
import classNames from 'classnames';

import * as style from '../DateFilter.scss';

interface Props {
    label: string;
    width: number;
    onMonthClick(): void;
}

export const Month: React.SFC<Props> = ({ label, width, onMonthClick }: Props): JSX.Element => (
    <div
        className={classNames(style.sliderItem, style.button)}
        style={{ width: `${width}%` }}
        onClick={onMonthClick}
        {...{
            'qa-id': 'month',
            'qa-label': label,
        }}
    >
        {label}
    </div>
);
