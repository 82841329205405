import * as React from 'react';
import classNames from 'classnames';

import { Toggle, TogglePosition, ToggleThemes } from 'sber-marketing-ui';

import { useMode } from '../../hooks';
import type { Mode } from '../../hooks';
import { useBehavior, By } from './useBehavior';

import * as styles from './styles.scss';

const togglePositionByRole: Record<By, TogglePosition> = {
    [By.Responsible]: TogglePosition.RIGHT,
    [By.Author]: TogglePosition.LEFT,
};

const toggleNameByMode: Record<Mode, { left: string; right: string }> = {
    activities: {
        left: 'Авторы проектов',
        right: 'Ответственные за проект',
    },
    tasks: {
        left: 'Авторы задач',
        right: 'Исполнители задач',
    },
};

export const UsersToggle: React.FC = () => {
    const { selectedFilterBy, onFilterByChange } = useBehavior();
    const { mode } = useMode();

    const onClick = React.useCallback(() => {
        onFilterByChange({
            by: selectedFilterBy === By.Responsible ? By.Author : By.Responsible,
        });
    }, [selectedFilterBy, onFilterByChange]);

    const togglePosition = togglePositionByRole[selectedFilterBy];
    const toggleNames = toggleNameByMode[mode];

    return (
        <div className={styles.root}>
            <div className={classNames(styles.item, togglePosition === TogglePosition.LEFT && styles.selectedItem)}>
                {toggleNames?.left || 'Не установлен'}
            </div>

            <Toggle theme={ToggleThemes.BOTH_GREEN_LARGE} position={togglePosition} onClick={onClick} />

            <div className={classNames(styles.item, togglePosition === TogglePosition.RIGHT && styles.selectedItem)}>
                {toggleNames?.right || 'Не установлен'}
            </div>
        </div>
    );
};
