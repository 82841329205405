// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-StageWidgets-TableWidget-Table-CellTypes-RangeDatepickerCell-_RangeDatepickerCell__root{position:relative;height:100%;width:100%;display:flex;flex-direction:column;justify-content:center;background-color:#ffffff;padding:2px 8px;color:#000000;font-family:'Open Sans';font-size:12px;line-height:16px}.src-client-modules-activity-pages-creative-StageWidgets-TableWidget-Table-CellTypes-RangeDatepickerCell-_RangeDatepickerCell__noSelectionMessage{color:#7e8681}.src-client-modules-activity-pages-creative-StageWidgets-TableWidget-Table-CellTypes-RangeDatepickerCell-_RangeDatepickerCell__hoveredDate{color:#7e8681}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/StageWidgets/TableWidget/Table/CellTypes/RangeDatepickerCell/RangeDatepickerCell.scss"],"names":[],"mappings":"AAAA,oIACI,iBAAkB,CAClB,WAAY,CACZ,UAAW,CAEX,YAAa,CACb,qBAAsB,CACtB,sBAAuB,CACvB,wBAAyB,CAEzB,eAAgB,CAEhB,aAAc,CACd,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACpB,kJAGG,aAAc,CACjB,2IAGG,aAAc","sourcesContent":[".root {\n    position: relative;\n    height: 100%;\n    width: 100%;\n\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    background-color: #ffffff;\n\n    padding: 2px 8px;\n\n    color: #000000;\n    font-family: 'Open Sans';\n    font-size: 12px;\n    line-height: 16px;\n}\n\n.noSelectionMessage {\n    color: #7e8681;\n}\n\n.hoveredDate {\n    color: #7e8681;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-StageWidgets-TableWidget-Table-CellTypes-RangeDatepickerCell-_RangeDatepickerCell__root",
	"noSelectionMessage": "src-client-modules-activity-pages-creative-StageWidgets-TableWidget-Table-CellTypes-RangeDatepickerCell-_RangeDatepickerCell__noSelectionMessage",
	"hoveredDate": "src-client-modules-activity-pages-creative-StageWidgets-TableWidget-Table-CellTypes-RangeDatepickerCell-_RangeDatepickerCell__hoveredDate"
};
export default ___CSS_LOADER_EXPORT___;
