import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DictionaryType } from '@mrm/dictionary';
import {
    SelectItem,
    Select_redesign as Select,
    SelectTheme_redesign as SelectTheme,
    OpenerIconType_redesign,
} from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getPageData } from '@store/budgetExecution';
import {
    ComponentState,
    getBudgetTransferMenuState,
    setParticipatorItemId,
} from '@store/budgetExecution/budgetTransferMenu';

import * as styles from './ParticipatorItemDictionarySelect.scss';

function useStore() {
    const dispatch = useDispatch();

    const mapProps = useSelector((state: StoreState) => {
        const { userDictionaries } = getPageData(state);
        const {
            participatorData: { participatorItemId },
            controls: { componentState },
        } = getBudgetTransferMenuState(state);

        return {
            itemDictionaries: userDictionaries.byType[DictionaryType.Item] || [],
            participatorItemId,
            componentState,
        };
    });

    const selectParticipatorItem = React.useCallback(
        (item: SelectItem) => dispatch(setParticipatorItemId(item.value as string)),
        [],
    );

    return { ...mapProps, selectParticipatorItem };
}

export function ParticipatorItemDictionarySelect(): JSX.Element {
    const { itemDictionaries, participatorItemId, componentState, selectParticipatorItem } = useStore();

    let title: string;
    switch (componentState) {
        case ComponentState.ExternalOutcomeTransfer:
            title = 'Статья получателя';
            break;
        case ComponentState.ExternalIncomeTransfer:
            title = 'Статья источника';
            break;
    }

    return (
        <React.Fragment>
            <div className={styles.title}>{title}</div>

            <div className={styles.itemSelect}>
                <Select
                    qaId="budgetTransferMenuExternalTransitionMenuParticipatorItemDropdown"
                    placeholder="Выберите"
                    theme={SelectTheme.BudgetTransferMenu}
                    openerIcon={OpenerIconType_redesign.BudgetTransferAppor}
                    options={itemDictionaries.map((dictionary) => ({
                        label: dictionary.value,
                        value: dictionary.id,
                    }))}
                    value={participatorItemId}
                    maxItemsVisibleCount={4}
                    onChange={selectParticipatorItem}
                />
            </div>
        </React.Fragment>
    );
}
