import * as React from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { TagColorType, TagEmojiType } from '@mrm/tags';
import {
    StyledPopup,
    StyledPopupTheme,
    WithGlobalPopup,
    AbsoluteLikePositionCalculator,
    StaticSkeleton,
} from 'sber-marketing-ui';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getTagsState } from '@store/tags';
import {
    DataUpdateStrategy,
    ComponentState,
    TagsEditorInstanceDescriptor,
    loadData,
    getInstance,
    createNewTag,
    setComponentState,
    setPendingTagColor,
    setPendingTagEmoji,
    updateTagColor,
    updateTagEmoji,
} from '@store/tagsEditor';

import { Tag } from '@modules/tags/Tag';

import { TagsInput } from './TagsInput';
import { TagsPreview } from './TagsPreview';
import { TagEditor as CommonTagEditor } from './TagEditor';

import { DescriptorContext, useDescriptor } from './Context';

import * as styles from './TagsPreviewAndEditor.scss';

export enum VerticalPosition {
    Top = 'Top',
    Bottom = 'Bottom',
}

function useInteractivity() {
    const dispatch = useDispatch();
    const { id, makeActionParams } = useDescriptor();

    const [popupContentHeight, setPopupContentHeight] = React.useState(0);

    const shouldRenderContent = useSelector((state: StoreState) => {
        const instance = getInstance(state, id);
        const tags = getTagsState(state).byId.dictionary;

        return instance?.canEdit || instance?.tags?.selected?.filter((tagId) => tags[tagId])?.length;
    });

    const rootRef = React.useRef<HTMLDivElement>();
    const tagsPreviewPopupRef = React.useRef<WithGlobalPopup>();

    const previousComponentState = useSelector((state: StoreState) => getInstance(state, id).previousComponentState);
    const loadingStatus = useSelector((state: StoreState) => getInstance(state, id).loadingStatus);
    const tagsLoadingStatus = useSelector((state: StoreState) => getTagsState(state).loadingStatus);
    const componentState = useSelector((state: StoreState) => getInstance(state, id).componentState);
    const selectedTags = useSelector((state: StoreState) => getInstance(state, id).tags.selected);

    const isTagsPreviewVisible = componentState === ComponentState.TagsPreviewOpened;
    const isTagEditorOpened = componentState === ComponentState.TagEditorOpened;
    const isCreateNewTagPopupVisible = componentState === ComponentState.CreateNewTagPopup;

    function closeTagsPreview() {
        dispatch(setComponentState(makeActionParams(ComponentState.Default)));
    }
    function closeTagEditor() {
        dispatch(setComponentState(makeActionParams(previousComponentState)));
    }

    React.useEffect(() => dispatch(loadData(id)), []);
    React.useEffect(() => tagsPreviewPopupRef.current?.redraw?.(), [selectedTags, tagsLoadingStatus]);

    return {
        shouldRenderContent,
        rootRef,
        tagsPreviewPopupRef,
        popupContentHeight,
        setPopupContentHeight,
        loadingStatus,
        isTagsPreviewVisible,
        isTagEditorOpened,
        isCreateNewTagPopupVisible,
        closeTagsPreview,
        closeTagEditor,
    };
}

interface Props extends TagsEditorInstanceDescriptor {
    id: string;
    dataUpdateStrategy: DataUpdateStrategy;
    zIndex?: number;
    className?: string;
    verticalPosition?: VerticalPosition;
    onAttachedTagsChange?: () => void;
}

export function TagsPreviewAndEditor({
    dataUpdateStrategy,
    id,
    zIndex,
    className,
    verticalPosition,
    onAttachedTagsChange,
}: Props): JSX.Element {
    // const descriptor = React.useMemo(() => {
    //     const res: TagsEditorInstanceDescriptor = {};

    //     if (props.activityId !== undefined) {
    //         res.activityId = props.activityId;
    //     } else if (props.taskId !== undefined) {
    //         res.taskId = props.taskId;
    //     } else if (props.executionBudgetItemId !== undefined) {
    //         res.executionBudgetItemId = props.executionBudgetItemId;
    //     } else if (props.planBudgetItemId !== undefined) {
    //         res.planBudgetItemId = props.planBudgetItemId;
    //     } else {
    //         console.warn(`Missing case for TagsEditorInstanceDescriptor: ${stringifyDescriptor(props)}`);
    //     }

    //     return res;
    // }, [ props.activityId, props.taskId, props.executionBudgetItemId, props.planBudgetItemId ]);

    const shouldRender = useSelector((state: StoreState) => !!getInstance(state, id));

    return shouldRender ? (
        <DescriptorContext.Provider value={{ id, dataUpdateStrategy }}>
            <TagsPreviewAndEditorContent
                zIndex={zIndex}
                className={className}
                verticalPosition={verticalPosition}
                onAttachedTagsChange={onAttachedTagsChange}
            />
        </DescriptorContext.Provider>
    ) : (
        <div className={styles.root}>
            <StaticSkeleton className={styles.skeleton} />
        </div>
    );
}

type ContentProps = Pick<Props, 'zIndex' | 'onAttachedTagsChange' | 'className' | 'verticalPosition'>;

function TagsPreviewAndEditorContent({
    zIndex = 10,
    className,
    verticalPosition = VerticalPosition.Bottom,
    onAttachedTagsChange,
}: ContentProps): JSX.Element {
    const {
        shouldRenderContent,
        rootRef,
        tagsPreviewPopupRef,
        popupContentHeight,
        setPopupContentHeight,
        loadingStatus,
        isTagsPreviewVisible,
        isTagEditorOpened,
        isCreateNewTagPopupVisible,
        closeTagsPreview,
        closeTagEditor,
    } = useInteractivity();

    const zIndexToUse = isTagsPreviewVisible || isTagEditorOpened ? zIndex : null;

    const withGlobalPopupProps: any = {
        container: rootRef,
        positionCalculator: AbsoluteLikePositionCalculator,
        left: 0,
        maskZIndex: zIndexToUse,
        zIndex: zIndexToUse ? zIndex + 1 : null,
    };

    switch (verticalPosition) {
        case VerticalPosition.Top:
            withGlobalPopupProps.top = -popupContentHeight - 5;
            break;
        case VerticalPosition.Bottom:
        default:
            withGlobalPopupProps.bottom = -1;
    }

    return shouldRenderContent ? (
        <div
            className={classnames(styles.root, className)}
            {...{
                'qa-id': 'tagsPreviewAndEditor',
            }}
        >
            <div ref={rootRef}>
                {loadingStatus === LoadingStatus.LOADED ? (
                    <React.Fragment>
                        <div className={styles.title}>Теги</div>

                        <TagsInput parentZIndex={zIndexToUse} onAttachedTagsChange={onAttachedTagsChange} />
                    </React.Fragment>
                ) : (
                    <StaticSkeleton className={styles.skeleton} />
                )}

                {isCreateNewTagPopupVisible && <CreateNewTagPopup onAttachedTagsChange={onAttachedTagsChange} />}

                {isTagsPreviewVisible && (
                    <WithGlobalPopup {...withGlobalPopupProps} ref={tagsPreviewPopupRef} onMaskClick={closeTagsPreview}>
                        <TagsPreview
                            wrapper={rootRef}
                            onAttachedTagsChange={onAttachedTagsChange}
                            onRerender={setPopupContentHeight}
                        />
                    </WithGlobalPopup>
                )}

                {isTagEditorOpened && (
                    <WithGlobalPopup {...withGlobalPopupProps} onMaskClick={closeTagEditor}>
                        <TagEditor onRerender={setPopupContentHeight} />
                    </WithGlobalPopup>
                )}
            </div>
        </div>
    ) : null;
}

function useCreateNewTagPopupInteractivity(props: CreateNewTagPopupProps) {
    const dispatch = useDispatch();
    const { onAttachedTagsChange } = props;
    const { id, makeActionParams, makeDataUpdateActionParams } = useDescriptor();

    const pendingTag = useSelector((state: StoreState) => getInstance(state, id).tags.pending);

    function onConfirmClick() {
        dispatch(setComponentState(makeActionParams(ComponentState.TagsPreviewOpened)));
        dispatch(createNewTag(makeDataUpdateActionParams(null)));
        onAttachedTagsChange?.();
    }

    function onCancelClick() {
        dispatch(setComponentState(makeActionParams(ComponentState.TagsPreviewOpened)));
    }

    return {
        pendingTag,
        onConfirmClick,
        onCancelClick,
    };
}

type CreateNewTagPopupProps = Pick<Props, 'onAttachedTagsChange'>;

function CreateNewTagPopup(props: CreateNewTagPopupProps): JSX.Element {
    const { pendingTag, onConfirmClick, onCancelClick } = useCreateNewTagPopupInteractivity(props);

    return (
        <StyledPopup
            qaId="tagsEditorCreatenewTagPopup"
            theme={StyledPopupTheme.Redesign}
            title="Внимание!"
            fullMessage={
                <div className={styles.createNewTagPopupMessage}>
                    <div className={styles.createNewTagPopupMessageText}>
                        После создания тега, его нельзя будет его удалить
                        <br />
                        или отредактировать. Проверьте корректность:
                    </div>

                    <div className={styles.createNewTagPopupMessageTag}>
                        <Tag {...pendingTag} />
                    </div>
                </div>
            }
            firstButtonText="Создать"
            firstButtonHandler={onConfirmClick}
            firstButtonQaId="tagsEditorCreatenewTagPopupConfirmButton"
            secondButtonText="Изменить"
            secondButtonHandler={onCancelClick}
            secondButtonQaId="tagsEditorCreatenewTagPopupCancelButton"
        />
    );
}

function useTagEditorInteractivity() {
    const dispatch = useDispatch();
    const { id, makeActionParams } = useDescriptor();

    const rootRef = React.useRef<HTMLDivElement>();

    const isEditingNewTag = useSelector((state: StoreState) => {
        const {
            tagIdWithActiveEditor,
            tags: { pending },
        } = getInstance(state, id);

        return tagIdWithActiveEditor === pending.id;
    });
    const tag = useSelector((state: StoreState) => {
        const {
            tagIdWithActiveEditor,
            tags: { pending },
        } = getInstance(state, id);
        const existing = getTagsState(state).entities;

        if (isEditingNewTag) {
            return pending;
        }

        return existing.find((tag) => tag.id === tagIdWithActiveEditor);
    });
    const previousComponentState = useSelector((state: StoreState) => getInstance(state, id).previousComponentState);

    function onColorChange(color: TagColorType) {
        if (isEditingNewTag) {
            dispatch(setPendingTagColor(makeActionParams(color)));
        } else {
            dispatch(
                updateTagColor(
                    makeActionParams({
                        id: tag.id,
                        color,
                    }),
                ),
            );
        }
    }
    function onEmojiChange(emoji: TagEmojiType) {
        if (isEditingNewTag) {
            dispatch(setPendingTagEmoji(makeActionParams(emoji)));
        } else {
            dispatch(
                updateTagEmoji(
                    makeActionParams({
                        id: tag.id,
                        emoji,
                    }),
                ),
            );
        }
    }

    function onBackButtonClick() {
        dispatch(setComponentState(makeActionParams(previousComponentState)));
    }

    return {
        tag,
        rootRef,
        onColorChange,
        onEmojiChange,
        onBackButtonClick,
    };
}

interface TagEditorProps {
    onRerender: (height: number) => void;
}

function TagEditor({ onRerender }: TagEditorProps): JSX.Element {
    const { tag, rootRef, onColorChange, onEmojiChange, onBackButtonClick } = useTagEditorInteractivity();

    React.useEffect(() => onRerender(rootRef.current?.clientHeight));

    return (
        <div ref={rootRef}>
            <CommonTagEditor
                tag={tag}
                onColorChange={onColorChange}
                onEmojiChange={onEmojiChange}
                onBackButtonClick={onBackButtonClick}
            />
        </div>
    );
}
