import { TableLine } from '@store/budgetPlanning/types';

const LINE_HEIGHT = 38;

interface Params {
    index: number;
    position: number;
    lines: TableLine[];
    isLast: boolean;
}

export class PlainFrame {
    public readonly index: number;

    public readonly position: number;

    public readonly lines: TableLine[];

    public readonly isLast: boolean;

    constructor({ index, position, lines, isLast }: Params) {
        this.index = index;
        this.position = position;
        this.lines = lines;
        this.isLast = isLast;
    }

    public getHeight(): number {
        return this.lines.length * LINE_HEIGHT;
    }
}
