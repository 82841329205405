import { axios, AxiosResponse } from '../lib/axios';
import { TrademarkListInterface } from 'sber-marketing-types/trademark';

const API_URL = '/api/trademark';

export class TrademarkApi {
    public static async getAllTrademarks() {
        const res: AxiosResponse<TrademarkListInterface> = await axios.get(API_URL);
        return res.data || [];
    }
}
