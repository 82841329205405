import * as React from 'react';
import { Icon, IconType } from 'sber-marketing-ui';

import { ColumnNameTitles } from '@store/creative';

import * as styles from './ColumnMarker.scss';

import { TableContext } from '../../../../Context';

interface Props {
    creativeRequestItemId: string;
    column: string;
}

export function ColumnMarker({ creativeRequestItemId, column }: Props): JSX.Element {
    const tableRef = React.useContext(TableContext);

    function onClick() {
        if (tableRef?.current) {
            tableRef.current.scrollToCell({ lineId: creativeRequestItemId, columnName: column });
        }
    }

    return column ? (
        <div className={styles.root} onClick={onClick}>
            <Icon type={IconType.CREATIVE_REQUEST_ITEM_COMMENT_COLUMN_MARKER} svgSize={24} />

            {ColumnNameTitles[column]}
        </div>
    ) : null;
}
