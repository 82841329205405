// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-WithExpertSelect-_WithExpertSelect__root{display:flex;justify-content:space-between;align-items:flex-end;width:100%}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-WithExpertSelect-_WithExpertSelect__singleColumn{width:100%}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-WithExpertSelect-_WithExpertSelect__halfColumn{width:calc(50% - 6px)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/WithExpertSelect/WithExpertSelect.scss"],"names":[],"mappings":"AAAA,kHACI,YAAa,CACb,6BAA8B,CAC9B,oBAAqB,CACrB,UAAW,CACd,0HAGG,UAAW,CACd,wHAGG,qBAAsB","sourcesContent":[".root {\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-end;\n    width: 100%;\n}\n\n.singleColumn {\n    width: 100%;\n}\n\n.halfColumn {\n    width: calc(50% - 6px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-WithExpertSelect-_WithExpertSelect__root",
	"singleColumn": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-WithExpertSelect-_WithExpertSelect__singleColumn",
	"halfColumn": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-WithExpertSelect-_WithExpertSelect__halfColumn"
};
export default ___CSS_LOADER_EXPORT___;
