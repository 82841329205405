import { bindActionCreators } from 'redux';
import * as lodash from 'lodash';
import * as moment from 'moment';

import type {
    ActivityParams as Activity,
    TaskResponseParams as Task,
    Brief,
    BriefScheme,
    UserResponseParams as User,
} from 'sber-marketing-types/frontend';

import { store } from '@store';
import {
    loadBriefPage,
    applyBriefDraft,
    setCurrentBrief,
    setCurrentBriefWasCopied,
    setBriefSchemes,
} from '@store/brief/actions';
// import { getBriefState } from '@store/brief/selectors';
import { add as addFiles } from '@store/fileAssets';
import { ActivityApi, BriefApi, FileApi, TaskApi, UserApi } from '@api';

import { PageLoader, UpdateSchemesParams } from './types';

interface ConstructorParams {
    activityId: number;
    taskId: string;
    organizationId: string;
}

// interface StoreProps {
//     activity: Activity;
//     task: Task;
//     brief: Brief;
//     currentBrief: Brief;
// }

export class TaskPageLoader implements PageLoader {
    private static instance: TaskPageLoader;

    private taskId: string;
    private activityId: number;
    private organizationId: string;

    private activity: Activity;
    private task: Task;
    private brief: Brief;
    private schemes: BriefScheme[] = [];
    private users: User[];

    private dispatch = bindActionCreators(
        {
            loadBriefPage,
            applyBriefDraft,
            setCurrentBrief,
            setCurrentBriefWasCopied,
            setBriefSchemes,
            addFiles,
        },
        store.dispatch,
    );

    public static getInstance(params: ConstructorParams): TaskPageLoader {
        if (!TaskPageLoader.instance) {
            TaskPageLoader.instance = new TaskPageLoader();
        }

        TaskPageLoader.instance.activityId = params.activityId;
        TaskPageLoader.instance.taskId = params.taskId;
        TaskPageLoader.instance.organizationId = params.organizationId;

        return TaskPageLoader.instance;
    }

    public async init() {
        await Promise.all([this.loadActivity(), this.loadTask()]);
        await this.loadTasksBrief();
        await Promise.all([this.loadSchemes(), this.loadUsers()]);

        this.dispatchLoadedDataToStore();
        this.resetLoadedData();
    }

    public async updateSchemes(params: UpdateSchemesParams): Promise<void> {
        this.brief = params.briefs[0];

        await this.loadSchemes();

        this.dispatchLoadedSchemesToStore();
        this.resetLoadedData();
    }

    private resetLoadedData(): void {
        this.activity = null;
        this.brief = null;
        this.task = null;
        this.schemes = [];
        this.users = [];
    }

    private async loadActivity(): Promise<void> {
        this.activity = await ActivityApi.getActivity(this.activityId);
    }

    private async loadTask(): Promise<void> {
        this.task = (await TaskApi.getTask(this.taskId)).task;
    }

    private async loadTasksBrief(): Promise<void> {
        const brief = await TaskApi.getTaskBrief(this.taskId);
        this.brief = this.formatFilesData(brief);
    }

    // private async loadBriefDraft() {
    //     const { brief } = this.getStoreProps();
    //     const draft = await BriefApi.getBriefDraft(brief.id);
    //     this.dispatch.applyBriefDraft(draft);
    // }

    private async loadSchemes() {
        this.schemes = await BriefApi.getBriefSchemeList({
            organizationId: this.organizationId,
        });

        const briefSchemeId = this.brief.schemeId;
        const loadedSchemesIds = this.schemes.map(({ id }) => id);

        const unloadedSchemesId = lodash.difference(lodash.compact([briefSchemeId]), loadedSchemesIds);

        await Promise.all(
            unloadedSchemesId.map(async (schemeId) => {
                const currentScheme = await BriefApi.getBriefSchemeById(schemeId);
                this.schemes.push(currentScheme);
            }),
        );
    }

    private async loadUsers() {
        const usersIdsOfTasks = lodash.uniq(lodash.compact([this.task.authorId, this.task.executorId]));
        this.users = await UserApi.getUserListFiltered({ ids: usersIdsOfTasks });
    }

    private formatFilesData(brief: Brief) {
        const updatedBrief = lodash.cloneDeep(brief);

        updatedBrief.blocks.forEach((block) =>
            block.fields.forEach((field) => {
                if (field.value && field.value.files) {
                    const files = FileApi.mapFiles(
                        {
                            activityId: String(this.activityId),
                            fieldId: field.id,
                        },
                        field.value.files as any[],
                        '',
                    ) as any[];
                    field.value.files = files;
                    this.dispatch.addFiles(...files);
                }
            }),
        );

        return updatedBrief;
    }

    private dispatchLoadedDataToStore(): void {
        this.dispatch.loadBriefPage({
            initialActivity: this.activity,
            changedActivity: {
                preparationDate: moment(this.activity.preparationDate),
                realizationStart: moment(this.activity.realizationStart),
                realizationEnd: moment(this.activity.realizationEnd),
                debriefingDate: moment(this.activity.debriefingDate),
            },
            initialTasks: lodash.keyBy([this.task], 'briefId'),
            changedTasks: lodash.keyBy(
                [this.task].map(({ id, briefId, deadline }) => ({
                    id: briefId,
                    briefId,
                    deadline: moment(deadline),
                })),
                'briefId',
            ),
            briefs: lodash.keyBy(lodash.cloneDeep([this.brief]), 'id'),
            currentBriefs: lodash.keyBy(lodash.cloneDeep([this.brief]), 'id'),
            briefsLoading: lodash.keyBy([{ id: this.brief.id, loading: false }], 'id'),
            schemes: this.schemes,
            budgetItem: null,
            users: this.users,
        });
    }

    private dispatchLoadedSchemesToStore(): void {
        this.dispatch.setBriefSchemes(this.schemes);
    }
}
