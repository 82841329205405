import { omit } from 'lodash';
import { Success, Failure } from 'typescript-fsa';
import { EditChanelParams } from 'sber-marketing-types/frontend';

import { TaskPageState, Channel, UpdateChannelPayload, DeleteChannelPayload } from '../types';

const channelReducer = ({ channels, ...restState }: TaskPageState, channel: Channel) => ({
    ...restState,
    channels: {
        ...omit(channels, [channel.id]),
        [channel.id]: channel,
    },
});

export const createChannelDoneReducer = (
    state: TaskPageState,
    { result }: Success<EditChanelParams, Channel>,
): TaskPageState => channelReducer(state, result);

export const updateChannelFailedReducer = (
    state: TaskPageState,
    { error }: Failure<UpdateChannelPayload, Channel>,
): TaskPageState => channelReducer(state, error);

export const updateChannelDoneReducer = (
    state: TaskPageState,
    { result }: Success<UpdateChannelPayload, Channel>,
): TaskPageState => channelReducer(state, result);

export const deleteChannelDoneReducer = (
    state: TaskPageState,
    { params }: Success<DeleteChannelPayload, void>,
): TaskPageState => ({
    ...state,
    channels: {
        ...omit(state.channels, [params.channelId]),
    },
});
