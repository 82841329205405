import * as React from 'react';

import type { CreativeRequestItem, BudgetItem } from './types';
import { Table } from './Table';

import { useTableColumns } from './useTableColumns';

import * as styles from './styles.scss';

interface Props {
    budgetItems: BudgetItem[];
    creativeRequestItems: CreativeRequestItem[];
}

export const SourceIdTable: React.FC<Props> = ({ budgetItems = [], creativeRequestItems }) => {
    const { columns } = useTableColumns({
        creativeRequestItems,
        budgetItems,
    });

    return (
        <div className={styles.root}>
            <div className={styles.title}>Расчет финансирования по ID источникам</div>
            <Table columns={columns} />
        </div>
    );
};
