import { actionCreatorFactory } from 'typescript-fsa';

import { LoadingStatus } from '@store/commonTypes';

import { ShowLineModalParams, LoadCommentsParams, LoadBoundActivitiesParams, Comment } from '../types';

const actionCreator = actionCreatorFactory('BUDGET_EXECUTION_LINE_MODAL_SYNC');

const showLineModal = actionCreator<ShowLineModalParams>('SHOW_LINE_MODAL');
const hideLineModal = actionCreator('HIDE_LINE_MODAL');
const setCommentsLoadingStatus = actionCreator<LoadingStatus>('SET_COMMENTS_LOADING_STATUS');
const setBoundActivitiesLoadingStatus = actionCreator<LoadingStatus>('SET_BOUND_ACTIVITIES_LOADING_STATUS');
const loadComments = actionCreator<LoadCommentsParams>('LOAD_COMMENTS_PARAMS');
const loadBoundActivities = actionCreator<LoadBoundActivitiesParams>('LOAD_BOUND_ACTIVITIES');
const setPendingComment = actionCreator<Comment>('SET_PENDING_COMMENT');
const clearLineModal = actionCreator('CLEAR_LINE_MODAL');

export {
    showLineModal,
    hideLineModal,
    setCommentsLoadingStatus,
    setBoundActivitiesLoadingStatus,
    loadComments,
    loadBoundActivities,
    setPendingComment,
    clearLineModal,
};
