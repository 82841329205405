// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageActions-FinalStageActions-_FinalStageActions__root{position:relative;height:48px;display:flex;align-items:center;padding:0 16px;border-radius:6px;background:#ffffff;box-shadow:0px 2px 6px rgba(0,0,0,0.1);overflow:hidden}.src-client-modules-activity-pages-autopilot-StageActions-FinalStageActions-_FinalStageActions__title{color:#092a3a;font-family:'Open Sans';font-weight:600;font-size:16px;line-height:22px}.src-client-modules-activity-pages-autopilot-StageActions-FinalStageActions-_FinalStageActions__buttons{display:flex;align-items:center;margin-left:auto}.src-client-modules-activity-pages-autopilot-StageActions-FinalStageActions-_FinalStageActions__button{margin-right:16px}.src-client-modules-activity-pages-autopilot-StageActions-FinalStageActions-_FinalStageActions__button:last-child{margin-right:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageActions/FinalStageActions/FinalStageActions.scss"],"names":[],"mappings":"AAAA,qGACI,iBAAkB,CAClB,WAAY,CACZ,YAAa,CACb,kBAAmB,CACnB,cAAe,CAEf,iBAAkB,CAClB,kBAAmB,CACnB,sCAA0C,CAE1C,eAAgB,CACnB,sGAGG,aAAc,CACd,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACpB,wGAGG,YAAa,CACb,kBAAmB,CACnB,gBAAiB,CACpB,uGAGG,iBAAkB,CADtB,kHAIQ,cAAe","sourcesContent":[".root {\n    position: relative;\n    height: 48px;\n    display: flex;\n    align-items: center;\n    padding: 0 16px;\n\n    border-radius: 6px;\n    background: #ffffff;\n    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);\n\n    overflow: hidden;\n}\n\n.title {\n    color: #092a3a;\n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 22px;\n}\n\n.buttons {\n    display: flex;\n    align-items: center;\n    margin-left: auto;\n}\n\n.button {\n    margin-right: 16px;\n\n    &:last-child {\n        margin-right: 0;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageActions-FinalStageActions-_FinalStageActions__root",
	"title": "src-client-modules-activity-pages-autopilot-StageActions-FinalStageActions-_FinalStageActions__title",
	"buttons": "src-client-modules-activity-pages-autopilot-StageActions-FinalStageActions-_FinalStageActions__buttons",
	"button": "src-client-modules-activity-pages-autopilot-StageActions-FinalStageActions-_FinalStageActions__button"
};
export default ___CSS_LOADER_EXPORT___;
