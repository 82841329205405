import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { MultiReferenceDictionaryApi } from '@api';

import { DictionaryType, PlainDictionary, DictionaryStatus } from '@mrm/dictionary';
import { FormData, GroupedDictionaries } from '@store/plannedBudgetEdit/types';
import {
    InstanceActionPayload,
    TagsEditorInstanceDescriptor,
    initInstance as initTagsEditorInstance,
    dropInstance as dropdTagsEditorInstance,
    flushOnDemandUpdates,
} from '@store/tagsEditor';

import { FieldValue, FormField, SelectItem } from 'sber-marketing-ui';
import { BudgetForm } from './BudgetForm';
import { MONTHS_INDEXES } from '../../utils';
import { StoreState } from '@store';
import { updateBudgetForm, updateBudgetFormsValidation } from '@store/plannedBudgetEdit/actions';
import { getMonthFields, getMonths, formatDictionaryItems } from './BudgetFormData';
import { Month } from '@mrm/budget/common';
import { fieldsToUpdate, fieldPriorityWeights, dictionaryTypes } from './configs';
import { Utils } from '@common/Utils';
import { getActivityTypeTooltip } from '@common/ActivityTypeTooltips';
import { ResetRegionalityLinksTooltip, ResetBlockLinksTooltip } from '@common/ResetLinksGroupTooltip';

interface Props extends OwnProps, Partial<MapProps & DispatchProps> {}

interface OwnProps {
    id: string;
    fields: FormField[];
    isNew: boolean;
    tagsEditorId: string;
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi;
}

interface MapProps {
    dictionaries: GroupedDictionaries;
}

interface DispatchProps {
    updateBudgetForm: (form: FormData) => void;
    updateBudgetFormsValidation: () => void;
    initTagsEditorInstance: (payload: InstanceActionPayload<TagsEditorInstanceDescriptor>) => void;
    dropdTagsEditorInstance: (payload: string) => void;
    flushOnDemandUpdates: (payload: string) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BudgetFormContainer extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        return React.createElement(BudgetForm, {
            fields: this.props.fields,
            onFieldValueChange: this.onFieldValueChange,
        });
    }

    public componentDidMount(): void {
        this.validateDictionaries(this.props.fields);
        this.props.initTagsEditorInstance({
            id: this.props.tagsEditorId,
            payload: { planBudgetItemId: this.props.id },
        });
    }

    public componentWillUnmount(): void {
        this.props.flushOnDemandUpdates(this.props.tagsEditorId);
        this.props.dropdTagsEditorInstance(this.props.tagsEditorId);
    }

    @autobind
    private onFieldValueChange(id: string, value: FieldValue) {
        const { fields } = this.props;
        const updatedFields = lodash.clone(fields);

        const changedField = fields.find((field) => field.id == id);
        changedField.errorMessage = '';
        const isChangedFieldDictionary = fieldsToUpdate[changedField.name];

        if (isChangedFieldDictionary) {
            this.validateDictionaries(updatedFields, { ...changedField, value });
        } else {
            const changedField = updatedFields.find((item) => item.id == id);
            changedField.value = value;

            if (changedField.name == 'startDate') {
                this.validateStartDate(fields);
            }

            if (changedField.name == 'endDate') {
                this.validateEndDate(fields);
            }

            if (changedField.name == 'month') {
                this.updateMonthBudgets(fields);
            }

            if (
                changedField.name === 'plannedAmount' ||
                changedField.name === 'factPreviousPeriod' ||
                changedField.name === 'planPreviousPeriod'
            ) {
                this.validateSum(changedField);
            }

            this.props.updateBudgetForm({
                id: this.props.id,
                fields: [...fields],
                collapsed: false,
            });
        }
        this.props.updateBudgetFormsValidation();
    }

    // private updateDictionaryFields(fields: FormField[], fieldsToUpdate: string[]): void {
    //     fieldsToUpdate.forEach((fieldName) => {
    //         const childFieldNames = this.buildChildNames(fieldName);

    //         childFieldNames.forEach((childFieldName) => {
    //             const currentChildField = fields.find((item) => item.name == childFieldName);
    //             const parentField = this.getParentField(fields, childFieldName, fieldName);
    //             const dictionaryItem = this.getDictionaryItemOfField(parentField);

    //             const childDictionaryItems = this.dictionaries[dictionaryTypes[childFieldName]];

    //             const visibleDictionaryItems = parentField.value
    //                 ? childDictionaryItems.filter((childItem) =>
    //                       dictionaryItem.children.some((item) => item.id == childItem.id),
    //                   )
    //                 : [];

    //             const updatedChildFieldItems = this.formatDictionaryItems(visibleDictionaryItems);

    //             currentChildField.items = updatedChildFieldItems;

    //             if (updatedChildFieldItems.length === 1) {
    //                 currentChildField.value = updatedChildFieldItems[0].value;
    //             } else if (updatedChildFieldItems.length === 2) {
    //                 const itemValues = updatedChildFieldItems.map((item) => item.value);

    //                 if (itemValues.some((value) => !value)) {
    //                     currentChildField.value = itemValues.find((value) => value);
    //                 }
    //             }

    //             const oldValueIsValid = updatedChildFieldItems.some((item) => item.value == currentChildField.value);

    //             if (!oldValueIsValid) {
    //                 currentChildField.value = null;
    //             }
    //         });
    //     });
    // }

    // private getParentField(fields: FormField[], childFieldName: string, parentFieldName: string): FormField {
    //     const parentFields = fields.filter((field) => {
    //         return lodash.includes(fieldPriorityWeights[childFieldName], field.name);
    //     });

    //     const parentFieldWithChildrenDictionary = parentFields.find((field) => {
    //         const dictionaryItem = this.getDictionaryItemOfField(field);
    //         return Boolean(dictionaryItem && dictionaryItem.children.length);
    //     });

    //     return parentFieldWithChildrenDictionary || fields.find((item) => item.name == parentFieldName);
    // }

    // private buildChildNames(fieldName: string): string[] {
    //     const dictionaryType = dictionaryTypes[fieldName];
    //     const dictionaryItems = this.dictionaries[dictionaryType] || [];

    //     const childTypes = lodash.uniq(
    //         lodash.flatMap(dictionaryItems, (item) => item.children).map((item) => item.type as DictionaryType),
    //     );

    //     const childFieldNames: string[] = [];

    //     childTypes.forEach((childType) => {
    //         const childFieldName = lodash.findKey(dictionaryTypes, (item) => item == childType);

    //         if (childFieldName) {
    //             childFieldNames.push(childFieldName);
    //         }
    //     });

    //     return childFieldNames;
    // }

    // private getDictionaryItemOfField(field: FormField): PlainDictionary {
    //     const dictionaryType = dictionaryTypes[field.name];
    //     const dictionaryItems = this.dictionaries[dictionaryType] || [];
    //     const currentParentValue = field.value;
    //     return dictionaryItems.find((dictionaryItem) => dictionaryItem.id == currentParentValue);
    // }
    private validateDictionaries(fields: FormField[], updatedField?: FormField) {
        const { multiReferenceDictionaryApi } = this.props;

        const dictionaryFields = fields.filter((field) => field.dictionaryType);
        const dictionaryValues = lodash.compact(dictionaryFields.map((field) => field.value as string));

        const updatedValues = updatedField
            ? multiReferenceDictionaryApi.performDictionaryUpdate(
                  dictionaryValues,
                  updatedField.dictionaryType as DictionaryType,
                  updatedField.value as string,
              )
            : multiReferenceDictionaryApi.makeDictionaryValue(dictionaryValues);

        fields.forEach((field) => {
            if (field.dictionaryType) {
                const availableDictionaries = multiReferenceDictionaryApi.getDictionariesForValue(
                    updatedValues,
                    field.dictionaryType as DictionaryType,
                );

                const dictionaries =
                    availableDictionaries[field.dictionaryType] ||
                    this.props.dictionaries.byType[field.dictionaryType] ||
                    [];

                const isFieldRegionalityBased = multiReferenceDictionaryApi.dictionaryIsRegionalityBased(
                    field.dictionaryType as DictionaryType,
                );
                const addResetRegionalityLinksItem = !!(isFieldRegionalityBased && updatedValues[field.dictionaryType]);

                const isFieldBlockBased = multiReferenceDictionaryApi.dictionaryIsBlockBased(
                    field.dictionaryType as DictionaryType,
                );
                const addResetBlockLinksItem = !!(isFieldBlockBased && updatedValues[field.dictionaryType]);

                let resetLinksTooltip: JSX.Element = null;
                if (addResetRegionalityLinksItem) {
                    resetLinksTooltip = <ResetRegionalityLinksTooltip />;
                } else if (addResetBlockLinksItem) {
                    resetLinksTooltip = <ResetBlockLinksTooltip />;
                }

                const items = formatDictionaryItems(
                    dictionaries,
                    field.dictionaryType === DictionaryType.ActivityType ? getActivityTypeTooltip : null,
                    resetLinksTooltip,
                );
                field.items = items;
                field.value = updatedValues[field.dictionaryType]?.id;
            }
        });

        this.props.updateBudgetForm({
            id: this.props.id,
            fields,
            collapsed: false,
        });
    }

    private updateMonthBudgets(fields: FormField[]) {
        const index = fields.findIndex((item) => item.name == 'month' || item.name == 'plannedAmount');
        const budgetFields = lodash.remove(fields, (item) => item.name == 'month' || item.name == 'plannedAmount');

        let pairs = lodash.chunk(budgetFields, 2);

        pairs = lodash.sortBy(pairs, (item) => MONTHS_INDEXES[item[0].value as Month]);

        const [lastMonth] = lodash.last(pairs);

        if (lastMonth.value && pairs.length < 12) {
            pairs.push(getMonthFields());
        }

        pairs = lodash.uniqBy(pairs, (item) => item[0].value);

        pairs.forEach((pair) => {
            const pairQaIndex = (pair[0].value as string) || 'none';
            pair[0].qaIndex = pairQaIndex;
            pair[1].qaIndex = pairQaIndex;

            const valuesExceptCurrent = pairs
                .filter((item) => item != pair && item[0].value)
                .map((item) => item[0].value as number);

            pair[0].items = getMonths().filter(
                (item) => !lodash.includes(valuesExceptCurrent, item.value) && !(!pair[0].value && item.value == null),
            );
        });

        fields.splice(index, 0, ...lodash.flatten(pairs));
    }

    private validateSum(field: FormField) {
        const [intPart, fractionalPart] = (field.value as string).split('.');

        if (fractionalPart) {
            field.value = `${intPart}.${fractionalPart.slice(0, 2)}`;
        }
    }

    private validateStartDate(fields: FormField[]) {
        const startDateField = fields.find((item) => item.name == 'startDate');
        const endDateField = fields.find((item) => item.name == 'endDate');

        if (startDateField.value > endDateField.value) {
            endDateField.value = null;
        }
    }

    private validateEndDate(fields: FormField[]) {
        const startDateField = fields.find((item) => item.name == 'startDate');
        const endDateField = fields.find((item) => item.name == 'endDate');

        if (endDateField.value < startDateField.value) {
            startDateField.value = null;
        }
    }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): MapProps {
    const { isNew } = ownProps;

    const { availableDictionaries, usedDictionaries } = state.plannedBudgetEditPage;

    const dictionaries = isNew ? availableDictionaries : usedDictionaries;

    return {
        dictionaries,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            updateBudgetForm,
            updateBudgetFormsValidation,
            initTagsEditorInstance,
            dropdTagsEditorInstance,
            flushOnDemandUpdates,
        },
        dispatch,
    );
}
