import * as React from 'react';
import classNames from 'classnames';
import * as moment from 'moment';

import * as style from './LabeledDatepicker.scss';

import { Datepicker } from '../Datepicker';

interface Props {
    qaId?: string;
    title: string;
    value?: moment.Moment;
    maxDate?: moment.Moment;
    minDate?: moment.Moment;
    errorMessage?: string;
    displayError?: boolean;
    isFocused: boolean;
    disabled?: boolean;
    onInputFocus: () => void;
    onInputBlur: () => void;
    onDateChange: (date: moment.Moment) => void;
}

export function LabeledDatepicker({
    qaId,
    title,
    value,
    maxDate,
    minDate,
    errorMessage,
    displayError,
    isFocused,
    disabled,
    onInputFocus,
    onInputBlur,
    onDateChange,
}: Props): JSX.Element {
    const hasValue = !!value;
    const showTitle = isFocused || hasValue;

    return (
        <div
            className={classNames(
                style.root,
                errorMessage !== undefined && style.hasErrorMessage,
                displayError && style.displayError,
                disabled && style.disabled,
            )}
        >
            <label className={style.label}>
                {showTitle && <div className={style.title}>{title}</div>}

                <div className={style.datepicker}>
                    <Datepicker
                        qaId={qaId}
                        date={value}
                        placeholder={showTitle ? '' : title}
                        onChange={onDateChange}
                        onInputBlur={onInputBlur}
                        onInputFocus={onInputFocus}
                        onCalendarOpen={onInputFocus}
                        onCalendarClose={onInputBlur}
                        disabled={disabled}
                        maxDate={maxDate}
                        minDate={minDate}
                    />
                </div>

                {displayError && errorMessage && <div className={style.errorMessage}>{errorMessage}</div>}
            </label>
        </div>
    );
}
