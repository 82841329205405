import { compact, uniq } from 'lodash';

import { StatisticsResponse, Mode, UserResponse, DepartmentResponse, Filters, StatisticsItem } from '../../types';

import {
    filterDepartmentResponseByIds,
    getDepartmentsIdsFromUsers,
    convertStatisticsItemsToFilters,
    convertDepartmentsToFilters,
    getAncestorStatisticsItem,
} from './common';

type UtilsByMode = {
    activities: Params<'activities'>;
    tasks: Params<'tasks'>;
};

interface Params<T extends Mode> {
    getUsersIdsFromStatistics: (statistics: StatisticsResponse<T>) => number[];
    getDepartmentsIdsFromUsers: (users: UserResponse[]) => string[];
    filterDepartmentResponseByIds: (departments: DepartmentResponse[], ids: string[]) => DepartmentResponse[];
    convertStatisticsItemsToFilters: (statisticsItems: StatisticsItem<T>[]) => Filters;
    convertDepartmentsToFilters: (departments: DepartmentResponse[]) => Filters;
    getAncestorStatisticsItem: (statisticsItems: StatisticsItem<T>[]) => StatisticsItem<T>;
}

export const utilsByMode: UtilsByMode = {
    tasks: {
        getUsersIdsFromStatistics: (statistics) => {
            const usersIdsFromCurrentStatistics = statistics.current.map(({ user }) => user);
            const usersIdsFromPastStatistics = statistics.past.map(({ user }) => user);

            return compact(uniq([...usersIdsFromCurrentStatistics, ...usersIdsFromPastStatistics]));
        },
        getDepartmentsIdsFromUsers,
        filterDepartmentResponseByIds,
        convertStatisticsItemsToFilters,
        convertDepartmentsToFilters,
        getAncestorStatisticsItem,
    },
    activities: {
        getUsersIdsFromStatistics: (statistics) => {
            const usersIdsFromCurrentStatistics = statistics.current.map(({ user }) => user);
            const usersIdsFromPastStatistics = statistics.past.map(({ user }) => user);

            return compact(uniq([...usersIdsFromCurrentStatistics, ...usersIdsFromPastStatistics]));
        },
        getDepartmentsIdsFromUsers,
        filterDepartmentResponseByIds,
        convertStatisticsItemsToFilters,
        convertDepartmentsToFilters,
        getAncestorStatisticsItem,
    },
};

export const getUtilsByMode = (mode: Mode) => {
    return utilsByMode[mode];
};
