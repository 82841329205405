import * as React from 'react';

import { UserRoleFilter, CardsLoadingModeFilter, CardType } from '@store/dashboardPage/types';
import { Card } from '../types';

import {
    getStatusByLoadingMode,
    ChildrenProps as WithCardsByFiltersProps,
    WithCardsByFilters,
} from './WithCardsByFilters';
import { ChildrenProps as WithSelectedCardProps, WithSelectedCard } from './WithSelectedCard';

interface Props extends ExternalProps {}

interface ExternalProps {
    userId: number;
    userRoleFilter: UserRoleFilter;
    cardsLoadingModeFilter: CardsLoadingModeFilter;
    scrollPageToTop: () => void;
    children?: (props: ChildrenProps) => JSX.Element;
}

interface ChildrenProps {
    cards: Card[];
    totalCardsCount: number;
    loading: boolean;
    fetchMore: () => Promise<void>;
    update: () => Promise<void>;
}

export class WithCardsQuery extends React.PureComponent<Props> {
    public render() {
        const { userId, userRoleFilter, cardsLoadingModeFilter, scrollPageToTop, children } = this.props;

        return (
            <WithCardsByFilters
                userId={userId}
                userRoleFilter={userRoleFilter}
                cardsLoadingModeFilter={cardsLoadingModeFilter}
                scrollPageToTop={scrollPageToTop}
            >
                {(withCardsByFiltersProps) => (
                    <WithSelectedCard loadedCards={withCardsByFiltersProps.cards}>
                        {(withSelectedCardProps) =>
                            children({
                                ...this.getChildrenProps(withCardsByFiltersProps, withSelectedCardProps),
                            })
                        }
                    </WithSelectedCard>
                )}
            </WithCardsByFilters>
        );
    }

    private getChildrenProps(
        withCardsByFiltersProps: WithCardsByFiltersProps,
        withSelectedCardProps: WithSelectedCardProps,
    ): ChildrenProps {
        const { totalCardsCount, fetchMore, update } = withCardsByFiltersProps;
        const statusToUse = getStatusByLoadingMode(
            this.props.cardsLoadingModeFilter[CardType.BudgetItemToProjectLinkRequest],
        );

        const cards = [...withCardsByFiltersProps.cards, ...withSelectedCardProps.cards].filter((card) =>
            statusToUse.includes(card.status),
        );

        const loading = withCardsByFiltersProps.loading || withSelectedCardProps.loading;

        return {
            cards,
            loading,
            totalCardsCount,
            fetchMore,
            update,
        };
    }
}
