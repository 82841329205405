import * as React from 'react';
import { uniq, compact } from 'lodash';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';

import { StoreState } from '@store';
import { CreativeRequestParamDomain } from './types';
import { CreativeRequestDomain, getCreativeRequestDomain } from '@store/creative';

interface Props extends ExternalProps, Partial<MapProps> {}

interface ExternalProps {
    children: (props: ChildrenProps) => JSX.Element;
}

export interface ChildrenProps {
    loading: boolean;
    filters: Filters;
}

interface MapProps {
    creativeRequestDomain: CreativeRequestDomain;
}

interface State {
    loading: boolean;
    filters: Filters;
}

interface Filters {
    divisionIds: string[];
}

@(connect(mapStateToProps) as any)
export class WithFilters extends React.Component<Props, State> {
    private creativeRequireParamDomains: CreativeRequestParamDomain[];

    constructor(props: Props) {
        super(props);

        this.state = {
            loading: true,
            filters: {
                divisionIds: [],
            },
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.init();
        this.setState({ loading: false });
    }

    public render(): JSX.Element {
        return this.props.children(this.getChildrenProps());
    }

    private getChildrenProps(): ChildrenProps {
        const { loading, filters } = this.state;
        return {
            loading,
            filters,
        };
    }

    private async init(): Promise<void> {
        await this.load();
        this.subscribe();

        this.setState({ filters: await this.getFilters() });
    }

    private async load(): Promise<void> {
        await this.loadCreativeRequestDomains();
    }

    private subscribe(): void {
        this.subscribeOnCreativeRequestDomainChange();
        this.subscribeOnCreativeRequestParamDomainsChange();
    }

    private async loadCreativeRequestDomains(): Promise<void> {
        const { creativeRequestDomain } = this.props;
        this.creativeRequireParamDomains = await creativeRequestDomain.model.getParams();
    }

    private subscribeOnCreativeRequestDomainChange(): void {
        const { creativeRequestDomain } = this.props;

        creativeRequestDomain.events.onParamsAdded(this.onParamsAddedHandler);
        creativeRequestDomain.events.onParamsRemoved(this.onParamsRemovedHandler);
    }

    private subscribeOnCreativeRequestParamDomainsChange(): void {
        this.creativeRequireParamDomains.map((creativeRequestParamDomain) =>
            this.subscribeOnCreativeRequestParamDomainChange(creativeRequestParamDomain),
        );
    }

    private subscribeOnCreativeRequestParamDomainChange({ events }: CreativeRequestParamDomain): void {
        events.onDivisionUpdated(this.onCreativeRequestParamDomainChangeHandler);
    }

    @autobind
    private async onParamsAddedHandler(addedCreativeRequestParamDomain: CreativeRequestParamDomain): Promise<void> {
        this.subscribeOnCreativeRequestParamDomainChange(addedCreativeRequestParamDomain);
        this.addCreativeRequestParamDomain(addedCreativeRequestParamDomain);

        this.setState({ filters: await this.getFilters() });
    }

    @autobind
    private async onParamsRemovedHandler(removedCreativeRequestParam: CreativeRequestParamDomain): Promise<void> {
        this.removeCreativeRequestParamDomain(removedCreativeRequestParam);

        this.setState({ filters: await this.getFilters() });
    }

    @autobind
    private async onCreativeRequestParamDomainChangeHandler(): Promise<void> {
        this.setState({ filters: await this.getFilters() });
    }

    private addCreativeRequestParamDomain(addedCreativeRequestParamDomain: CreativeRequestParamDomain): void {
        this.creativeRequireParamDomains = [...this.creativeRequireParamDomains, addedCreativeRequestParamDomain];
    }

    private removeCreativeRequestParamDomain(removedCreativeRequestParamDomain: CreativeRequestParamDomain): void {
        this.creativeRequireParamDomains = this.creativeRequireParamDomains.filter(
            ({ model }) => model.id !== removedCreativeRequestParamDomain.model.id,
        );
    }

    private async getFilters(): Promise<Filters> {
        const selectedDivisions = await Promise.all(
            this.creativeRequireParamDomains.map(async ({ model }) => (await model.division) || null),
        );

        return {
            divisionIds: uniq(compact(selectedDivisions).map(({ id }) => id)),
        };
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        creativeRequestDomain: getCreativeRequestDomain(state),
    };
}

export type { Filters };
