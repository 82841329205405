import * as React from 'react';

import * as style from './TextCell.scss';

import { Icon, IconType } from 'sber-marketing-ui';

interface Props {
    title: string;
    titleColor?: string;
    backgroundColor?: string;
    icon?: { type: IconType; color: string; size: number };
    customStyle?: any;
}

export const TextCellTemplate = ({ title, titleColor, backgroundColor, icon, customStyle }: Props): JSX.Element => {
    return (
        <div className={style.root} style={{ ...customStyle, color: titleColor, backgroundColor }}>
            {icon && (
                <div className={style.icon} style={{ width: icon.size, height: icon.size }}>
                    <Icon type={icon.type} svgSize={icon.size} color={icon.color} />
                </div>
            )}

            {title}
        </div>
    );
};
