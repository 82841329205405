import * as React from 'react';
import { useSelector } from 'react-redux';
import { StaticSkeleton } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { TagGroup, getTagsPageState } from '@store/tagsPage';

import { Activity } from './Activity';
import { Task } from './Task';
import { BudgetItemSource, BudgetItem } from './BudgetItem';

import * as styles from './Content.scss';

function useStore() {
    const showPreloader = useSelector((state: StoreState) => {
        const loadingStatus = getTagsPageState(state).loadingStatus.selectedNodeData;

        return loadingStatus !== LoadingStatus.LOADED;
    });

    const selectedNode = useSelector((state: StoreState) => getTagsPageState(state).selectedNode);

    return { showPreloader, selectedNode };
}

export function Content(): JSX.Element {
    const { showPreloader, selectedNode } = useStore();

    let content: JSX.Element;
    if (showPreloader) {
        content = <Preloader />;
    } else {
        switch (selectedNode?.group) {
            case TagGroup.Project:
                content = <Activity />;
                break;
            case TagGroup.Task:
                content = <Task />;
                break;
            case TagGroup.ExecutionId:
                content = <BudgetItem source={BudgetItemSource.Execution} />;
                break;
            case TagGroup.PlanId:
                content = <BudgetItem source={BudgetItemSource.Plan} />;
                break;
            default:
                console.warn(`Missing case-clause for TagGroup.${selectedNode?.group}`);
                content = null;
        }
    }

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'tagsPageRightSidebarContent',
            }}
        >
            <div className={styles.contentList}>{content}</div>
        </div>
    );
}

function Preloader(): JSX.Element {
    return (
        <div
            {...{
                'qa-id': 'tagsPageRightSidebarContentPreloader',
            }}
        >
            <PreloaderItem />
            <PreloaderItem />
            <PreloaderItem />
        </div>
    );
}

function PreloaderItem(): JSX.Element {
    return (
        <React.Fragment>
            <div>
                <StaticSkeleton className={styles.preloader1} />
                <StaticSkeleton className={styles.preloader2} />
            </div>

            <div>
                <StaticSkeleton className={styles.preloader1} />
                <StaticSkeleton className={styles.preloader3} />
            </div>

            <div>
                <StaticSkeleton className={styles.preloader1} />
                <StaticSkeleton className={styles.preloader4} />
            </div>
        </React.Fragment>
    );
}
