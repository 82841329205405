import * as React from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import { useSearch } from '@common/hooks';
import { Channels } from '@modules/channel/components';
import { ChatCard } from '@modules/comment/components';
import { TaskChannelInfo } from '@modules/task/components';

import { ActivityTaskPageParams } from '@modules/task/pages';

import * as styles from './TaskPageContent.scss';

export interface TaskCommentsProps extends React.HTMLProps<HTMLDivElement> {}

export function TaskPageContent({ className, ...props }: TaskCommentsProps) {
    const { taskId } = useParams<ActivityTaskPageParams>();
    const [{ channelId }] = useSearch();

    return (
        <div {...props} className={classNames(styles.root, className)}>
            <Channels taskId={taskId} />
            {channelId && <TaskChannelInfo taskId={taskId} channelId={Number(channelId)} />}
            <ChatCard taskId={taskId} channelId={channelId ? Number(channelId) : null} />
        </div>
    );
}
