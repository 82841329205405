import * as React from 'react';
import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import { getActivityTasksPageState } from '@store/activityTasksPage';
import { getArchiveTaskFiles } from '@store/activityFilesDAMArchive';

import { TitleWithCounter } from '../TitleWithCounter';
import { File } from '../File';

import * as styles from './ViewContentMode.scss';

interface Props {
    taskId: string;
    className: string;
}

function useViewContentMode(taskId: string) {
    const task = useSelector((state: StoreState) => getActivityTasksPageState(state).activityTasks.byId[taskId]);
    const archiveFiles = useSelector((state: StoreState) => getArchiveTaskFiles(state, taskId));

    return {
        task,
        archiveFiles,
    };
}

export function ViewContentMode({ taskId, className }: Props): JSX.Element {
    const { task, archiveFiles } = useViewContentMode(taskId);

    const shouldRender = !!archiveFiles.length;

    return shouldRender ? (
        <div className={className}>
            <TitleWithCounter title={task.title} count={archiveFiles.length} />

            <div className={styles.files}>
                {archiveFiles.map((file) => (
                    <div key={file.mrmFile.id} className={styles.file}>
                        <File {...file.mrmFile} size={+file.mrmFile.size} taskId={taskId} />
                    </div>
                ))}
            </div>
        </div>
    ) : null;
}
