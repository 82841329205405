import { FileData } from '@modules/files/types';
import { getImageFile } from '@modules/files/utils';
import axios from 'axios';

export async function getFileUrl(file: FileData, options?: Record<string, any>): Promise<string> {
    const img = getImageFile(file);

    if (img) return img;

    const { data } = await axios.post('/api/object/cloud/download-link', {
        containerName: file.containerName,
        fileName: file.id,
        originName: file.originName,
        ...options,
    });

    return data.link;
}
