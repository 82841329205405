import * as React from 'react';
import autobind from 'autobind-decorator';

import { Mentionable, Input_redesign as Input } from 'sber-marketing-ui';
import { LabeledInput } from './LabeledInput';

interface Props {
    qaId?: string;
    qaIndex?: string;
    title: string;
    value?: string;
    errorMessage?: string;
    displayError?: boolean;
    onlyNumbers?: boolean;
    disabled?: boolean;
    disableEllipsis?: boolean;
    maxLength?: number;
    onValueChange?: (value: string) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    onMouseDown?: () => void;
    onMouseUp?: () => void;
    textWrapperRef?: (element: HTMLDivElement) => void;
    handleValueChange?: () => void;
}

interface State {
    value: string;
    isFocused: boolean;
}

export class LabeledInputContainer extends React.Component<Props, State> implements Mentionable {
    private inputRef: React.RefObject<Input> = React.createRef();

    constructor(props: Props) {
        super(props);

        this.state = {
            value: this.props.value,
            isFocused: false,
        };
    }

    public UNSAFE_componentWillReceiveProps(newProps: Props) {
        const valueChanged = newProps.value !== this.state.value;

        if (valueChanged) {
            this.setState({
                value: newProps.value,
            });
        }
    }

    public render(): JSX.Element {
        const { value, isFocused } = this.state;

        return React.createElement(LabeledInput, {
            qaId: this.props.qaId,
            title: this.props.title,
            value,
            errorMessage: this.props.errorMessage,
            displayError: this.props.displayError,
            isFocused,
            onInputFocus: this.onInputFocus,
            onInputBlur: this.onInputBlur,
            onInputChange: this.onInputChange,
            onlyNumbers: this.props.onlyNumbers,
            disabled: this.props.disabled,
            disableEllipsis: this.props.disableEllipsis,
            maxLength: this.props.maxLength,
            inputComponentRef: this.inputRef,
            textWrapperRef: this.props.textWrapperRef,
        });
    }

    @autobind
    public getValue(): string {
        return this.state.value.toString();
    }

    @autobind
    public setValue(value: string) {
        this.setState({
            value,
        });
    }

    @autobind
    public getTextElement(): HTMLInputElement | HTMLTextAreaElement {
        return this.inputRef.current ? this.inputRef.current.getTextElement() : null;
    }

    @autobind
    protected onInputFocus() {
        this.setState({
            isFocused: true,
        });

        if (this.props.onFocus) {
            this.props.onFocus();
        }
    }

    @autobind
    protected onInputBlur() {
        this.setState({
            isFocused: false,
        });

        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    @autobind
    protected onInputChange(newValue: string) {
        this.setState(
            {
                value: newValue,
            },
            () => {
                if (this.props.onValueChange) {
                    this.props.onValueChange(newValue);
                }

                if (this.props.handleValueChange) {
                    this.props.handleValueChange();
                }
            },
        );
    }
}
