import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconType } from 'sber-marketing-ui';

import {
    formatMoneyForTooltip,
    formatMoney,
    formatMoneyForCorrectionValue,
    formatMoneyForCorrectionValueTooltip,
} from '../utils';

export interface FundRow {
    name: string;
    planned: FundCell;
    reserve: number;
    fact: number;
}

export interface FundCell {
    currentValue: number;
    correctionValue: number;
}

import * as style from './TableWithPlanTransferCorrection.scss';

interface Props {
    fundsRows: FundRow[];
}

export const TableWithPlanTransferCorrection: React.FC<Props> = ({ fundsRows }) => {
    const sumCurrentPlannedFunds = fundsRows.reduce((acc, { planned }) => acc + planned.currentValue, 0);
    const sumCorrectionPlannedFunds = fundsRows.reduce((acc, { planned }) => acc + planned.correctionValue, 0);
    const sumReserveFunds = fundsRows.reduce((acc, { reserve }) => acc + reserve, 0);
    const sumFactFunds = fundsRows.reduce((acc, { fact }) => acc + fact, 0);

    const isChangedSumPlannedFunds =
        sumCorrectionPlannedFunds !== 0 && sumCorrectionPlannedFunds !== sumCurrentPlannedFunds;
    const isPositivePlannedFundsDelta = sumCorrectionPlannedFunds > 0;

    return (
        <div>
            <div className={classNames(style.tableRow, style.tableRowHeader)}>
                <div className={classNames(style.tableCell, style.tableCellAccent)}>Месяц</div>
                <div className={classNames(style.tableCell, style.tableCellAccent)}>План, Тыс, ₽</div>
                <div className={classNames(style.tableCell, style.tableCellAccent)}>Резерв, Тыс, ₽</div>
                <div className={classNames(style.tableCell, style.tableCellAccent)}>Факт, Тыс, ₽</div>
            </div>
            {fundsRows.map(({ name, planned, reserve, fact }) => (
                <div className={style.tableRow} key={name}>
                    <div className={style.tableCell}>{name}</div>
                    <div className={classNames(style.tableCell, style.tableCellFound)}>
                        <div title={formatMoneyForTooltip(planned.currentValue)}>
                            {formatMoney(planned.currentValue)}
                        </div>
                        {planned.correctionValue !== 0 && (
                            <div
                                title={formatMoneyForCorrectionValueTooltip(planned.correctionValue)}
                                className={
                                    planned.correctionValue < 0
                                        ? style.negativeCorrectionValue
                                        : style.positiveCorrectionValue
                                }
                            >
                                {formatMoneyForCorrectionValue(planned.correctionValue)}
                            </div>
                        )}
                    </div>
                    <div className={style.tableCell} title={formatMoneyForTooltip(reserve)}>
                        {formatMoney(reserve)}
                    </div>
                    <div className={style.tableCell} title={formatMoneyForTooltip(fact)}>
                        {formatMoney(fact)}
                    </div>
                </div>
            ))}
            <div className={classNames(style.tableRow, style.tableRowFooter)}>
                <div className={classNames(style.tableCell, style.tableCellAccent)}>Итог, тыс. ₽</div>
                <div
                    className={classNames(style.tableCell, isChangedSumPlannedFunds && style.tableCellWithArrow)}
                    title={formatMoneyForTooltip(sumCurrentPlannedFunds + sumCorrectionPlannedFunds)}
                >
                    {isChangedSumPlannedFunds ? (
                        isPositivePlannedFundsDelta ? (
                            <Icon style={{ color: '#19BB4F' }} type={IconType.ARROW_UP} svgSize={10} />
                        ) : (
                            <Icon style={{ color: '#E63900' }} type={IconType.ARROW_DOWN} svgSize={10} />
                        )
                    ) : null}
                    {formatMoney(sumCurrentPlannedFunds + sumCorrectionPlannedFunds)}
                </div>
                <div className={style.tableCell} title={formatMoneyForTooltip(sumReserveFunds)}>
                    {formatMoney(sumReserveFunds)}
                </div>
                <div className={style.tableCell} title={formatMoneyForTooltip(sumFactFunds)}>
                    {formatMoney(sumFactFunds)}
                </div>
            </div>
        </div>
    );
};
