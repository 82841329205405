// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot_legacy-StageWidgets-MediaplanStageWidget-_MediaplanStageWidget__root{position:relative;width:100%;overflow:hidden;border-radius:6px}.src-client-modules-activity-pages-autopilot_legacy-StageWidgets-MediaplanStageWidget-_MediaplanStageWidget__title{color:#092a3a;font-weight:600;font-family:'Open Sans';font-size:14px;line-height:19px}.src-client-modules-activity-pages-autopilot_legacy-StageWidgets-MediaplanStageWidget-_MediaplanStageWidget__table{width:100%;margin-top:8px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot_legacy/StageWidgets/MediaplanStageWidget/MediaplanStageWidget.scss"],"names":[],"mappings":"AAAA,kHACI,iBAAkB,CAClB,UAAW,CACX,eAAgB,CAChB,iBAAkB,CACrB,mHAGG,aAAc,CACd,eAAgB,CAChB,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACpB,mHAGG,UAAW,CACX,cAAe","sourcesContent":[".root {\n    position: relative;\n    width: 100%;\n    overflow: hidden;\n    border-radius: 6px;\n}\n\n.title {\n    color: #092a3a;\n    font-weight: 600;\n    font-family: 'Open Sans';\n    font-size: 14px;\n    line-height: 19px;\n}\n\n.table {\n    width: 100%;\n    margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot_legacy-StageWidgets-MediaplanStageWidget-_MediaplanStageWidget__root",
	"title": "src-client-modules-activity-pages-autopilot_legacy-StageWidgets-MediaplanStageWidget-_MediaplanStageWidget__title",
	"table": "src-client-modules-activity-pages-autopilot_legacy-StageWidgets-MediaplanStageWidget-_MediaplanStageWidget__table"
};
export default ___CSS_LOADER_EXPORT___;
