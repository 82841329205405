import * as React from 'react';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';

import { StoreState } from '@store';
import { CardType, SelectedCard, setSelectedCard, getDashboardPageState } from '@store/dashboardPage';

import { LinkProps, BudgetItemLink } from './BudgetItemLink';

interface Props extends OwnProps, Partial<MapProps & DispatchProps> {}

interface OwnProps extends LinkProps {}

interface MapProps {
    selectedCardId: string;
}

interface DispatchProps {
    setSelectedCard(card: SelectedCard): void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BudgetItemLinkContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { selectedCardId, ...props } = this.props;

        return <BudgetItemLink {...props} selectedCardId={selectedCardId} onClick={this.onClick} />;
    }

    @autobind
    private onClick(): void {
        this.props.setSelectedCard({
            cardType: CardType.BudgetItemToProjectLinkRequest,
            id: this.props.id,
            activityId: this.props.project.id,
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { selectedCard } = getDashboardPageState(state);

    return {
        selectedCardId: selectedCard ? selectedCard.id : null,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        setSelectedCard: (selectedCard: SelectedCard) => dispatch(setSelectedCard(selectedCard)),
    };
}
