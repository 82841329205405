import { BudgetStatus } from '@mrm/budget';

const statusName: Partial<Record<BudgetStatus, string>> = {
    [BudgetStatus.ArchiveExecution]: 'архив',
    [BudgetStatus.ArchivePlan]: 'архив',
};

export const getStatusName = (status: BudgetStatus): string => {
    return statusName[status] || '';
};
