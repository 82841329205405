import * as React from 'react';

import { CancelButton } from './CancelButton';
import { ConfirmButton } from './ConfirmButton';

import * as style from './Footer.scss';

export interface Props {
    onConfirmButtonClick(): void;
    onCancelButtonClick(): void;
}

export const Footer: React.FC<Props> = ({ onCancelButtonClick, onConfirmButtonClick }) => {
    return (
        <div className={style.root}>
            <CancelButton onClick={onCancelButtonClick} />
            <ConfirmButton text={'Отключить и удалить чат'} disabled={false} onClick={onConfirmButtonClick} />
        </div>
    );
};
