// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-Sidebar-widgets-Comments-ColumnMarker-_ColumnMarker__root{display:inline-flex;align-items:center;height:24px;padding:0 8px 0 4px;background:#FFFFFF;border:1px solid #E6EDF1;border-radius:4px;cursor:pointer;font-family:'Open Sans';font-weight:400;font-size:12px;line-height:16px;letter-spacing:0.3px;color:#7E8681}.src-client-modules-activity-pages-creative-Sidebar-widgets-Comments-ColumnMarker-_ColumnMarker__root:hover{border-color:#5ABEFE;color:black}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/Sidebar/widgets/Comments/ColumnMarker/ColumnMarker.scss"],"names":[],"mappings":"AAAA,sGACI,mBAAoB,CACpB,kBAAmB,CAEnB,WAAY,CACZ,mBAAoB,CAEpB,kBAAmB,CACnB,wBAAyB,CACzB,iBAAkB,CAElB,cAAe,CAEf,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,oBAAqB,CACrB,aAAc,CAlBlB,4GAqBQ,oBAAqB,CACrB,WAAY","sourcesContent":[".root {\n    display: inline-flex;\n    align-items: center;\n\n    height: 24px;\n    padding: 0 8px 0 4px;\n    \n    background: #FFFFFF;\n    border: 1px solid #E6EDF1;\n    border-radius: 4px;\n\n    cursor: pointer;\n\n    font-family: 'Open Sans';\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 16px;\n    letter-spacing: 0.3px;\n    color: #7E8681;\n\n    &:hover {\n        border-color: #5ABEFE;\n        color: black;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-Sidebar-widgets-Comments-ColumnMarker-_ColumnMarker__root"
};
export default ___CSS_LOADER_EXPORT___;
