import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BudgetStatus } from '@mrm/budget';
import { Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getBudgetByStatusUserConfig } from '@store/userConfig/budget';
import {
    getTransferBudgetItemsToPlanningMenuState,
    resetComponentState,
} from '@store/budgetExecution/transferBudgetItemsToPlanningMenu';

import * as styles from './InitButton.scss';

const BUDGET_ITEM_URI_PARAM = '&budgetItemIds=';

function useStore() {
    const dispatch = useDispatch();

    const activityToSelectLines = useSelector(
        (state: StoreState) => getTransferBudgetItemsToPlanningMenuState(state).props.activityToSelectLines,
    );
    const linesToTransfer = useSelector(
        (state: StoreState) => getTransferBudgetItemsToPlanningMenuState(state).props.linesToTransfer,
    );

    const currentExecutionBudgetId = useSelector(
        (state: StoreState) => getBudgetByStatusUserConfig(state, BudgetStatus.Execution).budgetId,
    );
    const selectedPlanBudget = useSelector((state: StoreState) => {
        const {
            data: { planningBudgets },
            props: { planningBudgetId },
        } = getTransferBudgetItemsToPlanningMenuState(state);

        return planningBudgets.items.find((budget) => budget.id === planningBudgetId);
    });

    const budgetIsSelected = useSelector(
        (state: StoreState) => getTransferBudgetItemsToPlanningMenuState(state).props.planningBudgetId,
    );
    const linesAreSelected = useSelector(
        (state: StoreState) => getTransferBudgetItemsToPlanningMenuState(state).props.linesToTransfer.length,
    );

    const shouldRender = !!(budgetIsSelected && linesAreSelected);

    function initTransfer() {
        dispatch(resetComponentState());

        const budgetItemIds = linesToTransfer.join(BUDGET_ITEM_URI_PARAM);
        const budgetId = selectedPlanBudget?.id;
        const dictionariesOrganizationId = selectedPlanBudget?.dictionaryOrganizationId;

        const canRedirect =
            activityToSelectLines &&
            budgetItemIds &&
            budgetId &&
            dictionariesOrganizationId &&
            currentExecutionBudgetId;
        if (canRedirect) {
            const url = `/budget/planning/${activityToSelectLines}/copyFromExecution?budgetItemIds=${budgetItemIds}&budgetId=${budgetId}&dictionariesOrganizationId=${dictionariesOrganizationId}&sourceBudgetId=${currentExecutionBudgetId}`;

            window.open(url, '_blank');
        } else {
            console.warn(
                `Cannot redirect: ${activityToSelectLines}, ${budgetItemIds}, ${budgetId}, ${dictionariesOrganizationId}, ${currentExecutionBudgetId}`,
            );
        }
    }

    return {
        shouldRender,
        initTransfer,
    };
}

export function InitButton(): JSX.Element {
    const { shouldRender, initTransfer } = useStore();

    return shouldRender ? (
        <div className={styles.root}>
            <Button theme={ButtonTheme.GhostRoundedBlack} onClick={initTransfer}>
                <div className={styles.button}>Создать строки в планировании</div>
            </Button>
        </div>
    ) : null;
}
