import * as React from 'react';
import * as styles from './styles.scss';

export const Separator: React.FC = () => {
    return (
        <div className={styles.root}>
            <div className={styles.text}>или</div>
        </div>
    );
};
