// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanSettingsStageWidget-ManualRatingsSplitTable-TableView-StickyWrapper-_StickyWrapper__root{position:absolute;left:0;right:0;z-index:20}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilotTv/StageWidgets/MediaplanSettingsStageWidget/ManualRatingsSplitTable/TableView/StickyWrapper/StickyWrapper.scss"],"names":[],"mappings":"AAAA,8JACI,iBAAkB,CAClB,MAAO,CACP,OAAQ,CAER,UAAW","sourcesContent":[".root {\n    position: absolute;\n    left: 0;\n    right: 0;\n\n    z-index: 20;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanSettingsStageWidget-ManualRatingsSplitTable-TableView-StickyWrapper-_StickyWrapper__root"
};
export default ___CSS_LOADER_EXPORT___;
