import * as React from 'react';

import { Flex, FlexProps, Label, TagClose } from '@common/components';

import { UserChip } from '@modules/user/components';
import { ChannelAccessAction, useChannelAccess } from '@modules/channel/hooks';
import { useGetTaskChannel } from '@modules/task/hooks';

export interface ChannelParticipantsProps extends FlexProps {
    channelId?: number;
    taskId?: string;
    participantIds: number[];
    onChangeParticipantIds: (userIds: number[]) => void;
    removable?: boolean;
}

export function ChannelParticipants({
    className,
    children,
    channelId,
    taskId,
    participantIds,
    removable,
    onChangeParticipantIds,
    loading,
    ...props
}: ChannelParticipantsProps) {
    const skip = !channelId || !taskId;
    const {
        isLoading: isAccessLoading,
        access: [canRemove],
    } = useChannelAccess([ChannelAccessAction.removeParticipant], channelId, taskId, skip);
    const { isLoading: isChannelLoading, channel } = useGetTaskChannel(taskId, channelId, skip);
    const isLoading = loading || isAccessLoading || isChannelLoading;

    const handleRemove = (userId: number) => {
        onChangeParticipantIds(participantIds.filter((id) => id !== userId));
    };

    return participantIds.length ? (
        <Flex gap={8} vertical {...props} loading={isLoading}>
            <Label>Участники</Label>
            <Flex gap={8} wrap>
                {participantIds.map((userId) => (
                    <UserChip key={userId} userId={userId}>
                        {canRemove || !channel?.participantIds.includes(userId) ? (
                            <TagClose onClick={() => handleRemove(userId)} />
                        ) : null}
                    </UserChip>
                ))}
            </Flex>
        </Flex>
    ) : null;
}
