import * as React from 'react';

import * as styles from './TaskPage.scss';

import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type { Permissions } from '@store/taskEditor2';

import { OpacityTransition, ResponsiveLayout, CentralContent, CustomScrollbar } from 'sber-marketing-ui';
import { CommentsAndDescription } from '../../components/CommentsAndDescription';
import { TaskSideForm } from '../../components/TaskSideForm';
import { TaskEditor } from '@common/TaskEditor';

type Props = {
    selectedParticipants: {
        userId: number;
        canRemove: boolean;
    }[];
    visibilityEstimator: boolean;
    activity: Activity;
    isBaseChannel?: boolean;
    taskId?: string;
    taskEditorPermissions: Permissions;
    isTaskEditorOpen: boolean;
    previousUrl: string;
    tagsEditorId: string;
    onTaskEditClick(): void | Promise<void>;
    onTaskCreated(): void | Promise<void>;
    onTaskEditorPopupClose(): void | Promise<void>;
    onParticipantsChange(
        participants: {
            userId: number;
            canRemove: boolean;
        }[],
    ): void;
    onParticipantClick(userId: number): void;
};

export function TaskPage({
    activity,
    taskId,
    taskEditorPermissions,
    isBaseChannel,
    isTaskEditorOpen,
    previousUrl,
    tagsEditorId,
    onTaskEditClick,
    onTaskCreated,
    onTaskEditorPopupClose,
    selectedParticipants,
    visibilityEstimator,
    onParticipantsChange,
    onParticipantClick,
}: Props): JSX.Element {
    return (
        <OpacityTransition start>
            <div className={styles.content}>
                <div className={styles.task}>
                    <CustomScrollbar autoHide hideTracksWhenNotNeeded>
                        <div className={styles.taskWrap}>
                            <ResponsiveLayout>
                                <CentralContent>
                                    <CommentsAndDescription
                                        previousUrl={previousUrl}
                                        onTaskEditClick={onTaskEditClick}
                                    />
                                </CentralContent>
                            </ResponsiveLayout>
                        </div>
                    </CustomScrollbar>
                </div>

                <TaskSideForm
                    tagsEditorId={tagsEditorId}
                    taskId={taskId}
                    className={styles.sidebar}
                    isBaseChannel={isBaseChannel}
                    selectedParticipants={selectedParticipants}
                    visibilityEstimator={visibilityEstimator}
                    onParticipantsChange={onParticipantsChange}
                    onParticipantClick={onParticipantClick}
                />
            </div>

            {isTaskEditorOpen && (
                <TaskEditor
                    permissions={taskEditorPermissions}
                    activityId={activity.id}
                    taskId={taskId}
                    onTaskCreated={onTaskCreated}
                    closePopup={onTaskEditorPopupClose}
                />
            )}
        </OpacityTransition>
    );
}

export type { Permissions };
