import * as React from 'react';
import {
    TaskStatus,
    WorkTypeParams,
    UserResponseParams,
    DepartmentAttributes,
    TaskCardParams,
} from 'sber-marketing-types/frontend';
import { CheckboxGroupFilter, FilterGroup } from 'sber-marketing-ui';

import { Stage } from '@store/activityTasksPage/stages';
import { TasksFilter, TaskParticipation } from '@store/userConfig';
import { ConfirmFilterButton } from '@common/ConfirmFilterButton';
import { useHideFilter } from '@common/FilterButton';

import { FilterMenuPreloader } from '../DashboardsPreloaders';
import { WorkTypesFilter, UsersFilter, DepartmentsFilter, ActivityStagesFilter } from '../Filters';

import * as style from './TaskFiltersMenu.scss';

const STATUS = [
    {
        id: TaskStatus.InProgress,
        title: 'Текущие',
    },
    {
        id: TaskStatus.Closed,
        title: 'Закрытые',
    },
];

const PARTICIPATIONS = [
    {
        id: TaskParticipation.AUTHOR,
        title: 'Я - автор',
    },
    {
        id: TaskParticipation.EXECUTOR,
        title: 'Я - исполнитель',
    },
    {
        id: TaskParticipation.PARTICIPANT,
        title: 'Я - участник',
    },
];

export interface TaskFiltersMenuTemplateProps {
    myTasksFiltersPage?: boolean;
    isLoading: boolean;
    filters: TasksFilter;
    workTypes: WorkTypeParams[];
    authors: UserResponseParams[];
    executors: UserResponseParams[];
    departments: DepartmentAttributes[];
    activityStages?: Stage[];
    tasksByStageId?: {
        [stageId: string]: TaskCardParams[];
    };
    onSave(): void;
    onWorkTypeSelect(selectedItemIds: React.ReactText[]): void;
    onStatusSelection(status: TaskStatus | undefined): void;
    onParticipationSelect(participation: TaskParticipation): void;
    onAuthorSelect(selectedItemIds: React.ReactText[]): void;
    onExecutorSelect(selectedItemIds: React.ReactText[]): void;
    onDepartmentSelect(selectedItemIds: React.ReactText[]): void;
    onActivityStageSelect(selectedItemIds: React.ReactText[]): void;
}

export function TaskFiltersMenu({
    myTasksFiltersPage,
    isLoading,
    filters,
    workTypes,
    departments,
    authors,
    executors,
    activityStages,
    tasksByStageId,
    onStatusSelection,
    onWorkTypeSelect,
    onParticipationSelect,
    onAuthorSelect,
    onExecutorSelect,
    onDepartmentSelect,
    onActivityStageSelect,
    onSave,
}: TaskFiltersMenuTemplateProps): JSX.Element {
    const hideFilter = useHideFilter();
    const handleSave = () => {
        onSave();
        hideFilter?.();
    };

    const content = isLoading ? (
        <FilterMenuPreloader />
    ) : (
        <>
            <div className={style.filterWrapper}>
                <CheckboxGroupFilter
                    qaId="dashboardParticipationFilter"
                    title="Мое участие"
                    items={PARTICIPATIONS}
                    checkedItemId={filters.participation}
                    onItemSelection={onParticipationSelect}
                />
            </div>

            <div className={style.filterWrapper}>
                <CheckboxGroupFilter
                    qaId="dashbordStatusFilter"
                    title="Статус"
                    items={STATUS}
                    checkedItemId={filters.status}
                    onItemSelection={onStatusSelection}
                />
            </div>

            <FilterGroup hideAll>
                {!myTasksFiltersPage && (
                    <ActivityStagesFilter
                        activityStages={activityStages}
                        tasksByStageId={tasksByStageId}
                        selectedItems={filters.activityStage}
                        onCheckItems={onActivityStageSelect}
                    />
                )}

                <UsersFilter
                    title="Автор"
                    users={authors}
                    selectedItems={filters.author}
                    onCheckItems={onAuthorSelect}
                />

                <UsersFilter
                    title={'Исполнитель'}
                    users={executors}
                    selectedItems={filters.executor}
                    onCheckItems={onExecutorSelect}
                />

                <DepartmentsFilter
                    title="Департамент исполнителя"
                    departments={departments}
                    selectedItems={filters.department}
                    onCheckItems={onDepartmentSelect}
                />

                <WorkTypesFilter
                    workTypes={workTypes}
                    selectedItems={filters.workType}
                    onCheckItems={onWorkTypeSelect}
                />
            </FilterGroup>

            <ConfirmFilterButton onClick={handleSave}>Применить</ConfirmFilterButton>
        </>
    );

    return (
        <div className={style.root} qa-id="dashboardTasksFilter">
            {content}
        </div>
    );
}
