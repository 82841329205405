import * as React from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Input_redesign as Input, InputTheme_redesign as InputTheme } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { ComponentMode, getTaskEditorState, setTitle } from '@store/taskEditor2';

import * as styles from './TitleInput.scss';

function useInteractivity() {
    const dispatch = useDispatch();
    const [isInputFocused, setIsInputFocused] = React.useState(false);

    const { title, disabled } = useSelector((state: StoreState) => {
        const { values, permissions } = getTaskEditorState(state);

        return {
            title: values.title,
            disabled: !permissions.canChangeRest,
        };
    });

    function saveTitle(title: string) {
        dispatch(setTitle(title));
    }

    return {
        title,
        saveTitle,
        isInputFocused,
        setIsInputFocused,
        disabled,
    };
}

interface Props {
    componentMode: ComponentMode;
}

export function TitleInput({ componentMode }: Props): JSX.Element {
    const { title, saveTitle, isInputFocused, setIsInputFocused, disabled } = useInteractivity();
    const showRequiredMarker = componentMode === ComponentMode.enterRequiredFieldsForExistingTask;

    return (
        <div className={styles.root}>
            <div className={styles.title}>
                Название задачи
                {showRequiredMarker ? <React.Fragment>&nbsp;*</React.Fragment> : null}
            </div>

            <div className={classnames(styles.input, isInputFocused && styles.inputFocussed)}>
                <Input
                    qaId="taskEditorTitleInput"
                    value={title}
                    placeholder="Введите название"
                    disabled={disabled}
                    theme={InputTheme.Simple}
                    onInputChange={saveTitle}
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => setIsInputFocused(false)}
                />
            </div>
        </div>
    );
}
