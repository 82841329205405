import * as React from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';

import { TaskLoadingError } from '../../../../../../../store/taskPage';
import * as styles from './TaskPageError.scss';

/** Task page error properties */
export interface TaskPageErrorProps extends TaskLoadingError {
    className?: string;
}

/** Task page error component */
export function TaskPageError({ errorCode, errorType, errorMessage, className }: TaskPageErrorProps): JSX.Element {
    const classList: string[] = [styles.root];
    if (!isNil(className)) {
        classList.push(className);
    }
    return (
        <div className={classNames(classList)}>
            <div className={styles.message}>Данная задача уже не существует</div>
        </div>
    );
}
