import * as React from 'react';

import { ActivitySuggestWithStore } from './ActivitySuggestWithStore';
import { ActivitySuggestConnected } from './ActivitySuggestConnected';
import { ActivitySuggestContainer } from './ActivitySuggestContainer';

interface Props {
    budgetId: string;
    budgetItemId: string;
    onItemClick: (activityBudgetId: string, activityBudgetName: string) => void;
}

export function ActivitySuggest({ budgetId, budgetItemId, onItemClick }: Props): JSX.Element {
    return (
        <ActivitySuggestWithStore budgetItemId={budgetItemId}>
            {(containerProps) => (
                <ActivitySuggestConnected activityName={containerProps.nameInputValue} budgetId={budgetId}>
                    {(gqlProps) => (
                        <ActivitySuggestContainer
                            {...containerProps}
                            {...gqlProps}
                            budgetId={budgetId}
                            onItemClick={onItemClick}
                        />
                    )}
                </ActivitySuggestConnected>
            )}
        </ActivitySuggestWithStore>
    );
}
