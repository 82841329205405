import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';

import { StoreState } from '@store';
import {
    ComponentState,
    InternalTransferDirection,
    getBudgetTransferMenuState,
    isCellSelected,
    setComponentState,
    setRowForSumEntering,
} from '@store/budgetExecution/budgetTransferMenu';

import * as styles from './ToSumEnteringButton.scss';

function useStore() {
    const dispatch = useDispatch();

    const internalTransferDirection = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.internalTransferDirection,
    );
    const from = useSelector((state: StoreState) => getBudgetTransferMenuState(state).cells.from);
    const to = useSelector((state: StoreState) => getBudgetTransferMenuState(state).cells.to);

    let shouldRender: boolean;
    switch (internalTransferDirection) {
        case InternalTransferDirection.OneToMany:
            shouldRender = isCellSelected(from) && to.length && to.every((toCell) => !!toCell.lineId);
            break;
        case InternalTransferDirection.ManyToOne:
            shouldRender = isCellSelected(to) && from.length && from.every((fromCell) => !!fromCell.lineId);
            break;
        default:
            shouldRender = false;
            break;
    }

    function toSumEnteringMode() {
        switch (internalTransferDirection) {
            case InternalTransferDirection.OneToMany:
                dispatch(setRowForSumEntering(to[0].lineId));
                break;
            case InternalTransferDirection.ManyToOne:
                dispatch(setRowForSumEntering(from[0].lineId));
                break;
            default:
                break;
        }

        dispatch(setComponentState(ComponentState.InternalTransferSumEntering));
    }

    return { shouldRender, toSumEnteringMode };
}

export function ToSumEnteringButton(): JSX.Element {
    const { shouldRender, toSumEnteringMode } = useStore();

    return shouldRender ? (
        <div className={styles.root}>
            <Button
                theme={ButtonTheme.GhostRoundedBlack}
                onClick={toSumEnteringMode}
                qaId="internalTransitionLineSelectionToSumEnteringButton"
            >
                К выбору суммы
            </Button>
        </div>
    ) : null;
}
