import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type {
    AutopilotRadioMediaplanPlanDataResponse as AutopilotRadioMediaplan,
    AutopilotRadioMediaplanAvailableStation as AvailableStation,
    AutopilotRadioMediaplanSelectedStation as SelectedStation,
    AutopilotRadioMediaplanKeyValue,
    AutopilotRadioMediaplanCampaign,
} from 'sber-marketing-types/backend';
import type { CellPosition, ColumnWidths, LineId } from './types';
import { CellEvent } from './types';
import type { StoreState } from '@store';
import type { BriefStageForm } from '@store/autopilotRadio/types';

import { RadioSplitTemplate } from './RadioSplitTemplate';
import { TableViewModel } from './TableViewModel';
import { TableView } from './TableView';
import {
    MakeSelectedRadiosTableColumns,
    MakeAvailableRadiosTableColumns,
    leftFixedColumns,
    selectedRadiosTableRightFixedColumns,
    availableRadiosTableRightFixedColumns,
    MakeColumnsConfig,
    ColumnParams,
    ColumnsConfigParams,
} from './ColumnsConfig';
import { ColumnHeaderFactory, CellsFactory } from './modules';
import { setBriefFormValues } from '@store/autopilotRadio/actions';
import { getBriefStageForm, getMediaplan } from '@store/autopilotRadio/selectors';
import { Loader, Saver } from '../../../modules';

interface Props extends Partial<MapProps & DispatchProps> {}

interface MapProps {
    briefStageForm: BriefStageForm;
    mediaplan: AutopilotRadioMediaplan;
}

interface DispatchProps {
    setBriefFormValues: (values: Partial<BriefStageForm>) => void;
}

interface State {
    selectedRadiosTableLineIds: string[];
    availableRadiosTableLineIds: string[];
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class RadioSplitBehaviour extends React.PureComponent<Props, State> {
    private loader: Loader;
    private saver: Saver;
    private cellsStorage: TableViewModel;
    private selectedTable: TableView;
    private availableTable: TableView;
    private columnHeaderFactory: ColumnHeaderFactory;
    private cellsFactory: CellsFactory;
    private selectedRadiosTableColumns: string[] = [];
    private availableRadiosTableColumns: string[] = [];
    private defaultColumnWidths: ColumnWidths = {};

    public constructor(props: Props) {
        super(props);

        this.state = {
            selectedRadiosTableLineIds: [],
            availableRadiosTableLineIds: [],
        };

        this.loader = Loader.getInstance();
        this.saver = Saver.getInstance();

        this.columnHeaderFactory = new ColumnHeaderFactory({
            getColumnsConfig: this.getColumnsConfig,
        });

        this.cellsFactory = new CellsFactory({
            getLine: this.getLine,
            getAllLines: this.getAllLines,
            getTotalLine: this.getTotalLine,
            getColumnsConfig: this.getColumnsConfig,
            onLineAdd: this.onLineAdd,
            onLineRemove: this.onLineRemove,
        });

        this.cellsStorage = new TableViewModel({
            makeColumnHeaderParams: this.columnHeaderFactory.makeColumnHeaderParams,
            makeCellParams: this.cellsFactory.makeCellParams,
        });

        this.updateDefaultColumnWidths();
        this.updateTableColumns();
    }

    public async componentDidMount() {
        this.updateLineIds();
    }

    public componentDidUpdate(prevProps: Props) {
        const mediaplanChanged = this.props.mediaplan !== prevProps.mediaplan;

        if (mediaplanChanged) {
            this.updateLineIds();
        }
    }

    public render(): JSX.Element {
        const { selectedRadiosTableLineIds, availableRadiosTableLineIds } = this.state;

        return React.createElement(RadioSplitTemplate, {
            viewModel: this.cellsStorage,
            selectedRadiosTableColumns: this.selectedRadiosTableColumns,
            availableRadiosTableColumns: this.availableRadiosTableColumns,
            leftFixedColumns,
            selectedRadiosTableRightFixedColumns,
            availableRadiosTableRightFixedColumns,
            selectedRadiosTableLineIds,
            availableRadiosTableLineIds,
            columnWidths: this.defaultColumnWidths,
            selectedTableRef: this.selectedTableRef,
            availableTableRef: this.availableTableRef,
            onCellEvent: this.onCellEvent,
            onRecommendedRadiosButtonClick: this.onRecommendedRadiosButtonClick,
        });
    }

    public setColumnWidths(columnWidths: ColumnWidths) {
        if (columnWidths) {
            this.selectedTable.setColumnWidths(columnWidths);
            this.availableTable.setColumnWidths(columnWidths);
        }
    }

    @autobind
    protected selectedTableRef(component: TableView) {
        this.selectedTable = component ? (component as any).getInstance() : null;
    }

    @autobind
    protected availableTableRef(component: TableView) {
        this.availableTable = component ? (component as any).getInstance() : null;
    }

    @autobind
    protected async onLineAdd(lineId: LineId) {
        const { briefStageForm } = this.props;

        const updatedStations = [...briefStageForm.selectedStations, lineId];

        this.props.setBriefFormValues({ selectedStations: updatedStations });

        await this.saver.saveBriefForm();

        await Promise.all([this.loader.loadAutopilot(), this.loader.loadCalculatedMediaplan()]);

        this.updateLineIds();
        this.updateAllLines();
    }

    @autobind
    protected async onLineRemove(lineId: LineId) {
        const { briefStageForm } = this.props;

        const updatedStations = lodash.without(briefStageForm.selectedStations, lineId);

        this.props.setBriefFormValues({ selectedStations: updatedStations });

        await this.saver.saveBriefForm();

        await Promise.all([this.loader.loadAutopilot(), this.loader.loadCalculatedMediaplan()]);

        this.updateLineIds();
        this.updateAllLines();
    }

    @autobind
    protected async onRecommendedRadiosButtonClick() {
        const { mediaplan } = this.props;

        const lines = [...mediaplan.custom.available.rows, ...mediaplan.custom.selected.rows];

        const recommendedRadiosIds = lines.filter((item) => item.primary).map((item) => item.id);

        this.props.setBriefFormValues({ selectedStations: recommendedRadiosIds });

        await this.saver.saveBriefForm();

        await Promise.all([this.loader.loadAutopilot(), this.loader.loadCalculatedMediaplan()]);

        this.updateLineIds();
        this.updateAllLines();
    }

    @autobind
    protected async onCellEvent(eventType: CellEvent, position: CellPosition) {
        switch (eventType) {
            case CellEvent.EditStart:
                this.updateCell(position, true);
                break;

            case CellEvent.EditEnd:
                this.updateCell(position, false);
                break;

            case CellEvent.MouseSelection:
                this.updateCell(position, false);
                break;
        }
    }

    private updateCell(position: CellPosition, edit: boolean) {
        this.cellsStorage.setCellParams(position, this.cellsFactory.makeCellParams(position, edit));
    }

    private updateAllLines() {
        const { selectedRadiosTableLineIds, availableRadiosTableLineIds } = this.state;

        const lineIds = [...selectedRadiosTableLineIds, ...availableRadiosTableLineIds, 'total'];

        lineIds.forEach((lineId) => {
            this.updateLineCells(lineId);
        });
    }

    @autobind
    private updateLineCells(lineId: LineId) {
        const columnsConfigParams = this.makeColumnsConfigParams();

        const columns = lodash.uniq([
            ...leftFixedColumns,
            ...MakeSelectedRadiosTableColumns(columnsConfigParams),
            ...MakeAvailableRadiosTableColumns(columnsConfigParams),
            ...this.selectedRadiosTableColumns,
            ...this.availableRadiosTableColumns,
        ]);

        columns.forEach((columnName) => {
            const cellPosition = { lineId, columnName };

            const { selectedRadiosTableLineIds } = this.state;

            const cellEditStatus = selectedRadiosTableLineIds.includes(lineId)
                ? this.selectedTable.getCellEditStatus(cellPosition)
                : this.availableTable.getCellEditStatus(cellPosition);

            this.updateCell(cellPosition, cellEditStatus);
        });
    }

    private updateTableColumns() {
        const columnsConfigParams = this.makeColumnsConfigParams();

        this.selectedRadiosTableColumns = MakeSelectedRadiosTableColumns(columnsConfigParams);
        this.availableRadiosTableColumns = MakeAvailableRadiosTableColumns(columnsConfigParams);
    }

    private updateLineIds() {
        const selectedRadiosTableLineIds = this.props.mediaplan.custom.selected.rows.map((item) => item.id);
        const availableRadiosTableLineIds = this.props.mediaplan.custom.available.rows.map((item) => item.id);

        selectedRadiosTableLineIds.push('total');

        this.setState({
            selectedRadiosTableLineIds,
            availableRadiosTableLineIds,
        });
    }

    private updateDefaultColumnWidths() {
        const columnsConfigParams = this.makeColumnsConfigParams();

        const columnsConfig = MakeColumnsConfig(columnsConfigParams);

        this.defaultColumnWidths = lodash.mapValues(columnsConfig, (item) => item.defaultWidth);
    }

    @autobind
    private getColumnsConfig(): { [columnName: string]: ColumnParams } {
        const columnsConfigParams = this.makeColumnsConfigParams();

        return MakeColumnsConfig(columnsConfigParams);
    }

    private makeColumnsConfigParams(): ColumnsConfigParams {
        return {};
    }

    @autobind
    private getLine(lineId: LineId): SelectedStation | AvailableStation {
        const { mediaplan } = this.props;

        let foundStation: SelectedStation | AvailableStation = mediaplan.custom.selected.rows.find(
            (item) => item.id === lineId,
        );

        if (!foundStation) {
            foundStation = mediaplan.custom.available.rows.find((item) => item.id === lineId);
        }

        return foundStation;
    }

    @autobind
    private getAllLines(): AutopilotRadioMediaplanCampaign {
        const { mediaplan } = this.props;

        return mediaplan.custom;
    }

    @autobind
    private getTotalLine(): AutopilotRadioMediaplanKeyValue[] {
        const { mediaplan } = this.props;

        return mediaplan.custom.selected.total;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        briefStageForm: getBriefStageForm(state),
        mediaplan: getMediaplan(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setBriefFormValues,
        },
        dispatch,
    );
}
