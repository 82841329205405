import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';

import { StoreState } from '@store';
import { setSortingMode } from '@store/budgetPlanning';
import { getTableFilters } from '@store/budgetPlanning/selectors';
import { SortingMode, OrderType, ColumnName } from '@store/budgetPlanning/types';

import { Sorting } from '@budgetCommon';

import { SortingMenu } from './SortingMenu';

interface Props extends Partial<MapProps>, Partial<DispatchProps> {
    columnName: ColumnName;
}

interface MapProps {
    sortingOrder: OrderType;
}

interface DispatchProps {
    setSortingMode: (sortingMode: SortingMode) => void;
}

interface State {
    sortingOrder: OrderType;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class SortingMenuContainer extends React.Component<Props, State> {
    private root: HTMLDivElement;

    constructor(props: Props) {
        super(props);

        this.state = {
            sortingOrder: this.props.sortingOrder || null,
        };
    }

    public componentDidMount() {
        this.root.focus();
    }

    public render(): JSX.Element {
        const { sortingOrder } = this.state;

        return React.createElement(SortingMenu, {
            sortingOrder,
            onSortingChange: this.onSortingChange,
            rootRef: this.rootRef,
        });
    }

    @autobind
    protected rootRef(element: HTMLDivElement) {
        this.root = element;
    }

    @autobind
    private onSortingChange(sorting: Sorting): void {
        const sortingOrder = sorting === Sorting.Asc ? OrderType.Asc : OrderType.Desc;

        this.setState(
            {
                sortingOrder,
            },
            () =>
                this.props.setSortingMode({
                    columnName: sortingOrder ? this.props.columnName : null,
                    order: sortingOrder,
                }),
        );
    }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
    const { sortingMode } = getTableFilters(state);

    return {
        sortingOrder: sortingMode.columnName === ownProps.columnName ? sortingMode.order : null,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setSortingMode,
        },
        dispatch,
    );
}
