import * as React from 'react';

import type {
    Autopilot2,
    Autopilot2MediaplanPlanDataResponse as Autopilot2MediaplanPlanData,
} from 'sber-marketing-types/backend';
import type { BriefStageForm } from '@store/autopilot/types';

import * as style from './MediaplanStageWidget.scss';

import { XLSBuilder } from './XLSBuilder';
import { Table } from './Table';

interface Props {
    xls: string;
    url: string;
    displayUrl: boolean;
    displayStatuses: boolean;
    autopilot: Autopilot2;
    mediaplan: Autopilot2MediaplanPlanData;
    briefForm: BriefStageForm;
    displayTable: boolean;
}

export const MediaplanStageWidgetTemplate = ({
    xls,
    url,
    displayUrl,
    displayStatuses,
    autopilot,
    mediaplan,
    briefForm,
    displayTable,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            {displayTable && (
                <>
                    {xls && (
                        <div className={style.downloadButton}>
                            <XLSBuilder />
                        </div>
                    )}

                    <div className={style.title}>
                        Итоговый медиаплан
                        {displayUrl && (
                            <a className={style.url} href={url} target="_blank">
                                Открыть в МПМ
                            </a>
                        )}
                    </div>

                    <div className={style.table}>
                        <Table
                            autopilot={autopilot}
                            mediaplanItems={mediaplan.items}
                            prediction={mediaplan.prediction}
                            briefForm={briefForm}
                            displayStatuses={displayStatuses}
                        />
                    </div>

                    <div className={style.legend}>
                        <div className={style.legendItem}>
                            <div className={style.legendTitle} style={{ backgroundColor: '#def9e6' }}>
                                строка
                            </div>
                            — площадки, которые будут запущены в автоматическом режиме
                        </div>

                        <div className={style.legendItem}>
                            <div className={style.legendTitle} style={{ color: '#e63900', fontWeight: 600 }}>
                                ячейка
                            </div>
                            — площадки, для которых превышены лимиты бюджета
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
