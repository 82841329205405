import { createSelector } from 'reselect';

import { TaskPageState, TaskInfo } from '../types';
import { getTaskPageState } from './lib';

/** Returns base task information */
export const getTaskInfo = createSelector(
    getTaskPageState,
    ({
        id,
        title,
        createdAt,
        authorId,
        deadlineAt,
        description,
        participants,
        executorId,
        workType,
        departmentExecutorId,
        activityId,
        organizationId,
        assetsIds,
        status,
        editingParticipants,
        telegram,
        rate,
        isParticipantEditorFormVisible,
        isRequestInProgress,
        canEdit,
        canDelete,
        createdBy,
        budgetApproval,
    }: TaskPageState): TaskInfo => ({
        id,
        title,
        description,
        createdAt,
        authorId,
        deadlineAt,
        participants,
        executorId,
        workType,
        departmentExecutorId,
        activityId,
        organizationId,
        assetsIds,
        status,
        editingParticipants,
        telegram,
        rate,
        isParticipantEditorFormVisible,
        isRequestInProgress,
        canEdit,
        canDelete,
        createdBy,
        budgetApproval,
    }),
);
