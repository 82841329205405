import * as React from 'react';
import * as style from './TmRow.scss';
import { TmItemProps } from '../TmTable';
import { CellTypeMap } from './CellTypeMap';
import classNames from 'classnames';
import { throttle } from 'lodash';

const MIN_HEIGHT = 40;
const MAX_HEIGHT = 300;
const THROTTLE_MS = 10;

export interface TmItemPropsState {
    isDrag: boolean;
    initialHeight: number;
    initialMouseY: number;
    height?: number;
}

export class TmRow extends React.Component<TmItemProps, TmItemPropsState> {
    constructor(props: TmItemProps) {
        super(props);
        this.state = {
            isDrag: false,
            initialMouseY: 0,
            initialHeight: props.tm.height,
            height: props.tm.height,
        };
    }

    public render(): JSX.Element {
        const { idx, tm, onResize } = this.props;

        const onMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
            this.setState({
                ...this.state,
                isDrag: true,
                initialHeight: tm.height,
                initialMouseY: event.clientY,
            });
            document.body.classList.add(style.mask);
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
        };

        const onMouseMove = throttle((event: MouseEvent) => {
            const delta = event.clientY - this.state.initialMouseY;
            let height = this.state.initialHeight + delta;
            if (height < MIN_HEIGHT) {
                height = MIN_HEIGHT;
            }
            if (height > MAX_HEIGHT) {
                height = MAX_HEIGHT;
            }
            this.setState({
                ...this.state,
                height,
            });
        }, THROTTLE_MS);

        const onMouseUp = () => {
            this.setState({
                ...this.state,
                isDrag: false,
            });
            onResize(idx, this.state.height);
            document.body.classList.remove(style.mask);
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

        return (
            <div
                className={classNames(style.row, this.state.isDrag && style.dragged)}
                style={this.state.height ? { height: `${this.state.height}px` } : null}
            >
                {Object.entries(CellTypeMap).map(([fieldName, cellType]) => {
                    return React.createElement(cellType.class, {
                        ...cellType.mapToProps(tm[fieldName]),
                        key: fieldName,
                    });
                })}
                <div className={style.dragzone} onMouseDown={onMouseDown} />
            </div>
        );
    }
}
