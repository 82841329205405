import * as React from 'react';
import { Icon, IconType, Skeleton } from 'sber-marketing-ui';

import * as styles from './FileCard.scss';

import { FileAsset } from '@common/FileAssetRedesign';
import { Utils } from '@common/Utils';

export interface FileProps {
    id: string;
    name: string;
    originName: string;
    size: number;
    type: string;
}

interface Props extends Partial<FileProps> {
    loading?: boolean;
    showDeleteButton: boolean;
    onCardMouseEnter: () => void;
    onCardMouseLeave: () => void;
    onDeleteButtonClick: () => void;
    onGalleryButtonClick: () => void;
}

export function FileCard({
    loading = false,
    id,
    name,
    originName,
    size,
    type,
    showDeleteButton,
    onCardMouseEnter,
    onCardMouseLeave,
    onDeleteButtonClick,
    onGalleryButtonClick,
}: Props): JSX.Element {
    return (
        <div className={styles.root} onMouseEnter={onCardMouseEnter} onMouseLeave={onCardMouseLeave}>
            <div className={styles.asset}>
                <Skeleton
                    isReady={!loading && id}
                    loadingClassName={styles.fileAssetLoading}
                    customStyle={{
                        borderRadius: '4px',
                    }}
                >
                    <FileAsset
                        useR7Controls
                        useMediaControls
                        canEditInR7
                        showDownloadButton
                        id={id}
                        name={name}
                        originName={originName}
                        type={type}
                        fileId={id}
                        onGalleryButtonClick={onGalleryButtonClick}
                    />
                </Skeleton>
            </div>

            <div className={styles.content}>
                <Skeleton isReady={!loading && originName} loadingClassName={styles.titleLoading}>
                    <div className={styles.title} title={originName}>
                        {originName}
                    </div>
                </Skeleton>

                <Skeleton isReady={!loading && size} loadingClassName={styles.sizeLoading}>
                    <div className={styles.size}>{Utils.formatFileSize(size)}</div>
                </Skeleton>

                {/*<Skeleton isReady={!loading && author} loadingClassName={styles.authorLoading}>*/}
                {/*    {author && <div className={styles.author}>Загрузил:&nbsp;*/}
                {/*        <span className={styles.authorBlack}>{author.secondName} {author.firstName}</span>*/}
                {/*    </div>}*/}
                {/*</Skeleton>*/}
            </div>

            {!loading && showDeleteButton && (
                <>
                    <Icon
                        type={IconType.DELETE24}
                        svgSize={24}
                        className={styles.deleteButton}
                        onClick={onDeleteButtonClick}
                    />
                </>
            )}
        </div>
    );
}
