import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';

import { TaskEstimatorTemplate } from './TaskEstimatorTemplate';
import type { EstimateValue } from './TaskEstimatorTemplate';
import type { Mode } from './types';

import { StoreState } from '@store';
import { setRate } from '@store/taskPage';
import { getTaskInfo } from '@store/taskPage/selectors';

interface Props extends Partial<MapProps & DispatchProps> {
    taskId: string;
}

interface MapProps {
    rate: number;
}

interface DispatchProps {
    setRate: ({ rate }: { rate: number }) => void;
}

interface State {
    mode: Mode;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class TaskEstimatorBehavior extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            mode: props.rate ? 'display' : 'edit',
        };
    }

    public render() {
        const { rate } = this.props;
        const { mode } = this.state;

        return React.createElement(TaskEstimatorTemplate, {
            mode,
            selectedEstimateValue: rate as EstimateValue,
            onEstimateValueSelect: this.onEstimateValueSelect,
            onEstimateValueCancel: this.onEstimateValueCancel,
        });
    }

    @autobind
    private onEstimateValueSelect(estimateValue: EstimateValue): void {
        const { setRate } = this.props;
        this.setState({ mode: 'display' });
        setRate({ rate: estimateValue });
    }

    @autobind
    private onEstimateValueCancel(): void {
        this.setState({ mode: 'edit' });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const task = getTaskInfo(state);

    return {
        rate: task?.rate || null,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setRate,
        },
        dispatch,
    );
}
