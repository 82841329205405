import * as React from 'react';
import classNames from 'classnames';
import * as lodash from 'lodash';

import { MultiReferenceDictionaryApi } from '@api';

import * as style from './BudgetCard.scss';

import { BudgetItem, CreateBudgetItemForm } from '@mrm/budget';
import { DictionaryType } from '@mrm/dictionary';
import { GroupedDictionaries } from '@store/plannedBudgetEdit/types';

import { FormField, HeightAnimation, DynamicIcon, Icon, IconType } from 'sber-marketing-ui';
import { BudgetForm } from '../BudgetForm';
import { ActivitySuggest } from '../ActivitySuggest';
import { Brief } from './Brief';
import { Money, MoneyFormatter, Utils } from '@common/Utils';

interface Props {
    id: string;
    budgetId: string;
    index: number;
    fields: FormField[];
    collapsed: boolean;
    deleted: boolean;
    isNew: boolean;
    canBeCollapsed: boolean;
    canBeDeleted: boolean;
    budgetItem: BudgetItem;
    currentBudgetItem: CreateBudgetItemForm;
    dictionaries: GroupedDictionaries;
    showActivitySuggest: boolean;
    isMarkedForTransfer: boolean;
    tagsEditorId: string;
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi;
    onCopyButtonClick: () => void;
    onDeleteButtonClick: () => void;
    onCollapseButtonClick: () => void;
    onFinishCreateBrief: (briefId: string) => void;
    onFinishPasteCopiedBrief: () => void;
    onSuggestItemClick: (activityBudgetId: string, activityBudgetName: string) => void;
}

export const BudgetCard = ({
    id,
    budgetId,
    index,
    fields,
    collapsed,
    deleted,
    isNew,
    canBeCollapsed,
    canBeDeleted,
    budgetItem,
    currentBudgetItem,
    dictionaries,
    showActivitySuggest,
    isMarkedForTransfer,
    tagsEditorId,
    multiReferenceDictionaryApi,
    onCopyButtonClick,
    onDeleteButtonClick,
    onCollapseButtonClick,
    onFinishCreateBrief,
    onFinishPasteCopiedBrief,
    onSuggestItemClick,
}: Props): JSX.Element => {
    const { sapComment } = currentBudgetItem;

    const qaProps: any = {
        'qa-id': 'budgetItemRow',
        'qa-row-id': id,
        'qa-row-index': index,
    };

    return (
        <div
            className={classNames(
                style.root,
                collapsed && style.collapsed,
                deleted && style.disabled,
                isNew && style.newCard,
            )}
            onClick={canBeCollapsed && collapsed ? onCollapseButtonClick : null}
            {...qaProps}
        >
            <HeightAnimation>
                <div className={style.topLine}>
                    <div className={style.title}>
                        {collapsed ? sapComment || 'Название проекта (SAP): Не указано' : 'Строка бюджета'}

                        {/*{status == 'restored' && (*/}
                        {/*    <span className={style.restored}>Восстановлена</span>*/}
                        {/*)}*/}
                    </div>

                    {!collapsed && canBeCollapsed && (
                        <div className={style.collapseButton} onClick={onCollapseButtonClick} />
                    )}

                    <div className={style.buttons}>
                        {!deleted && (
                            <div
                                {...{
                                    'qa-id': 'copyRowButton',
                                }}
                                className={classNames(style.button, style.copyButton)}
                                title={'Копировать строку'}
                                onClick={onCopyButtonClick}
                            >
                                <DynamicIcon
                                    common={{ svgSize: 24 }}
                                    normal={{ type: IconType.COPY }}
                                    hover={{ type: IconType.COPY_HOVER }}
                                />
                            </div>
                        )}

                        {canBeDeleted && (
                            <div
                                {...{
                                    'qa-id': 'deleteOrRestoreRowButton',
                                }}
                                className={classNames(style.button, deleted ? style.restoreButton : style.deleteButton)}
                                title={deleted ? 'Восстановить' : 'Удалить'}
                                onClick={onDeleteButtonClick}
                            >
                                <Icon type={deleted ? IconType.RESTORE : IconType.DELETE_V2} svgSize={24} />
                            </div>
                        )}
                    </div>
                </div>

                {collapsed ? (
                    renderCard()
                ) : (
                    <div className={style.form}>
                        <BudgetForm
                            id={id}
                            fields={fields}
                            tagsEditorId={tagsEditorId}
                            isNew={isNew}
                            multiReferenceDictionaryApi={multiReferenceDictionaryApi}
                        />

                        {isMarkedForTransfer && (
                            <div className={style.transferMessage}>
                                <div className={style.transferMessageIcon}>
                                    <Icon type={IconType.EXPIRED_TASK} svgSize={12} />
                                </div>
                                Cтрока будет перенесена в новую активность
                            </div>
                        )}

                        {showActivitySuggest && (
                            <div className={style.activitySuggest}>
                                <ActivitySuggest
                                    budgetId={budgetId}
                                    budgetItemId={id}
                                    onItemClick={onSuggestItemClick}
                                />
                            </div>
                        )}
                    </div>
                )}

                {!collapsed && (
                    <Brief
                        id={currentBudgetItem.briefId}
                        budgetId={budgetId}
                        onFinishCreateBrief={onFinishCreateBrief}
                        onFinishPasteCopiedBrief={onFinishPasteCopiedBrief}
                    />
                )}
            </HeightAnimation>
        </div>
    );

    function renderCard() {
        const direction = getDictionaryValue(currentBudgetItem, dictionaries, DictionaryType.Direction);
        const tool = getDictionaryValue(currentBudgetItem, dictionaries, DictionaryType.Tool);

        const totalBudget = budgetItem
            ? Object.keys(budgetItem.plannedFunds).reduce((acc, item) => acc + budgetItem.plannedFunds[item], 0)
            : 0;

        return (
            <div className={style.properties}>
                <div className={style.property}>Направление: {direction || 'Не выбрано'}</div>

                <div className={style.property}>Инструмент: {tool || 'Не выбран'}</div>

                <div className={style.property}>Итоговая сумма: {formatFunds(totalBudget) || 'Не указана'}</div>
            </div>
        );
    }
};

function getDictionaryValue(
    budgetItem: CreateBudgetItemForm,
    dictionaries: GroupedDictionaries,
    dictionaryType: DictionaryType,
) {
    if (!budgetItem) {
        return null;
    }

    const dictionaryItems = dictionaries.byType[dictionaryType] || [];

    const foundDictionary = dictionaryItems.find((item) => lodash.includes(budgetItem.dictionaryIds, item.id));

    return Utils.getDictionaryValue(foundDictionary);
}

function formatFunds(value: number): string {
    return MoneyFormatter.toRoubles(Money.fromCopecks(value));
}
