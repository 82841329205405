import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import autobind from 'autobind-decorator';

import { ErrorHandler } from './ErrorHandler';
import { StoreState } from '@store';
import { resetErrorState } from '@store/common';
import { getErrorCode, getErrorMessage } from '@store/common';

interface ErrorHandlerContainerProps extends Partial<MapProps>, Partial<DispatchProps> {}

interface MapProps {
    errorCode: string;
    errorMessage: string;
}

interface DispatchProps {
    unsetErrorState: () => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class ErrorHandlerContainer extends React.PureComponent<ErrorHandlerContainerProps> {
    public render() {
        const { errorCode, errorMessage, children } = this.props;
        return (
            <ErrorHandler
                errorCode={errorCode}
                errorMessage={errorMessage}
                children={children}
                closePopup={this.closePopup}
            />
        );
    }

    @autobind
    protected closePopup(): void {
        this.props.unsetErrorState();
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        errorCode: getErrorCode(state),
        errorMessage: getErrorMessage(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<ErrorHandlerContainerProps>): DispatchProps {
    return {
        unsetErrorState: () => dispatch(resetErrorState(null)),
    };
}
