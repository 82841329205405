import * as React from 'react';
import { Icon, IconType } from 'sber-marketing-ui';

import { DatesFormatter } from '@common/Utils';
import { BudgetItemStatus as BudgetItemStatusValues } from '@mrm/budget';
import * as styles from './BudgetItemStatus.scss';

export interface Props {
    id: string;
    status: BudgetItemStatusValues;
    time: Date;
    comment?: string;
}

const CrossIcon: React.FC = () => {
    return (
        <div className={styles.iconWrapper}>
            <Icon type={IconType.CIRCLE_BOLD_CROSS} svgSize={16} />
        </div>
    );
};

const CheckIcon: React.FC = () => {
    return (
        <div className={styles.iconWrapper}>
            <Icon type={IconType.CIRCLE_BOLD_CHECK} svgSize={16} />
        </div>
    );
};

export const BudgetItemStatus: React.FC<Props> = ({ id, status, time, comment }) => {
    return (
        <div className={styles.root}>
            <div className={styles.header}>
                {status === BudgetItemStatusValues.Approved && <CheckIcon />}
                {status === BudgetItemStatusValues.Rejected && <CrossIcon />}
                <div className={styles.title}>
                    {(status === BudgetItemStatusValues.Approved && 'Строка планирования подтверждена') || ''}
                    {(status === BudgetItemStatusValues.Rejected && 'Строка планирования отклонена') || ''}
                </div>
                <div className={styles.date}>
                    <div className={styles.dataBlock}>
                        <Icon className={styles.dataIcon} type={IconType.CALENDAR_ICON} svgSize={16} />
                        {DatesFormatter.ddMonthyy(time)}
                    </div>
                    <div className={styles.dataBlock}>
                        <Icon className={styles.dataIcon} type={IconType.CLOCK_ICON} svgSize={16} />
                        {DatesFormatter.hhmm(time)}
                    </div>
                </div>
            </div>

            {comment && status === BudgetItemStatusValues.Rejected && (
                <div className={styles.comment}>
                    <span className={styles.commentLabel}>Причина отклонения: </span>
                    {comment}
                </div>
            )}
        </div>
    );
};
