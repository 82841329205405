import { actionCreatorFactory } from 'typescript-fsa';

import { WatchCommentsByItemAndColumnState } from './types';

const actionCreator = actionCreatorFactory('CREATIVE_SIDEBAR');

export const setWatchCommentsByItemAndColumn = actionCreator<WatchCommentsByItemAndColumnState>(
    'SET_WATCH_COMMENTS_BY_ITEM_AND_COLUMN',
);
export const resetState = actionCreator('RESET_STATE');
