import * as React from 'react';

import * as style from './BriefStageActions.scss';

import { Icon, IconType, Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';

const FIELDS_COUNT = 7;

interface Props {
    canEdit: boolean;
    hasErrors: boolean;
    filledFieldsCount: number;
    onButtonClick: () => void;
}

export const BriefStageActionsTemplate = ({
    canEdit,
    hasErrors,
    filledFieldsCount,
    onButtonClick,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.title}>
                Заполните форму брифа
                {filledFieldsCount === FIELDS_COUNT && !hasErrors && (
                    <div className={style.titleIcon}>
                        <Icon type={IconType.ACCEPT_ACTIVE} svgSize={24} />
                    </div>
                )}
                {(filledFieldsCount < FIELDS_COUNT || hasErrors) && (
                    <>
                        &nbsp;
                        <span className={style.counter}>
                            ({filledFieldsCount}/{FIELDS_COUNT})
                        </span>
                    </>
                )}
            </div>

            <div className={style.button}>
                <Button
                    disabled={!canEdit || filledFieldsCount < FIELDS_COUNT || hasErrors}
                    theme={ButtonTheme.MainRounded}
                    onClick={onButtonClick}
                >
                    К параметрам медиаплана
                </Button>
            </div>
        </div>
    );
};
