import * as React from 'react';

import { Dropdown } from './Dropdown';
import { Selector } from './Selector';

import { buildItems } from './utils';
import { useBehavior } from './useBehavior';

import * as styles from './styles.scss';

export const RangeSelector: React.FC = () => {
    const items = buildItems();

    const { selectedFilterPeriod, onItemClick } = useBehavior();

    return (
        <div className={styles.root}>
            <div className={styles.dropdownWrapper}>
                <div>Данные на дашборде за</div>
                <Dropdown items={items} selectedItemId={selectedFilterPeriod} onItemClick={onItemClick} />
            </div>
            <Selector />
        </div>
    );
};
