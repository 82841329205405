import * as React from 'react';
import { connect } from 'react-redux';

import { MediaplanStageWidgetTemplate } from './MediaplanStageWidgetTemplate';
import type { StoreState } from '@store';

interface Props extends Partial<MapProps> {
    readOnly?: boolean;
}

interface MapProps {}

@(connect(mapStateToProps, null) as any)
export class MediaplanStageWidgetBehaviour extends React.PureComponent<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        return React.createElement(MediaplanStageWidgetTemplate, {});
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {};
}
