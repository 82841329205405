// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanSettingsStageWidget-BudgetSplitTable-_BudgetSplitTable__root{display:block}.src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanSettingsStageWidget-BudgetSplitTable-_BudgetSplitTable__table{margin-bottom:21px}.src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanSettingsStageWidget-BudgetSplitTable-_BudgetSplitTable__table:last-child{margin-bottom:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilotTv/StageWidgets/MediaplanSettingsStageWidget/BudgetSplitTable/BudgetSplitTable.scss"],"names":[],"mappings":"AAAA,kIACI,aAAc,CACjB,mIAGG,kBAAmB,CADvB,8IAIQ,eAAgB","sourcesContent":[".root {\n    display: block;\n}\n\n.table {\n    margin-bottom: 21px;\n\n    &:last-child {\n        margin-bottom: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanSettingsStageWidget-BudgetSplitTable-_BudgetSplitTable__root",
	"table": "src-client-modules-activity-pages-autopilotTv-StageWidgets-MediaplanSettingsStageWidget-BudgetSplitTable-_BudgetSplitTable__table"
};
export default ___CSS_LOADER_EXPORT___;
