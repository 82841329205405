import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Toggle, TogglePosition, ToggleThemes } from 'sber-marketing-ui';
import classnames from 'classnames';

import { StoreState } from '@store';
import { isLoggedUserBudgetExpert } from '@store/user';
import {
    ComponentState,
    setComponentState,
    getBudgetTransferMenuState,
    isInternalTransferState,
    isExternalTransferState,
} from '@store/budgetExecution/budgetTransferMenu';

import * as style from './TransferSwitch.scss';

function useInteractivity() {
    const dispatch = useDispatch();

    const componentState = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.componentState,
    );
    const isRequestInProgress = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.isRequestInProgress,
    );
    const shouldRender = useSelector((state: StoreState) => {
        const isUserBudgetExpert = isLoggedUserBudgetExpert(state);
        const shouldBeVisible =
            componentState !== ComponentState.InternalTransferSumEntering &&
            componentState !== ComponentState.InternalTransferNegativeLineBalanceWarning;

        return isUserBudgetExpert && shouldBeVisible;
    });

    const internalTransferState = isInternalTransferState(componentState);
    const externalTransferState = isExternalTransferState(componentState);

    function toInternalTransfer() {
        if (!isRequestInProgress) {
            dispatch(setComponentState(ComponentState.InternalTransferCellSelection));
        }
    }
    function toExternalTransfer() {
        if (!isRequestInProgress) {
            dispatch(setComponentState(ComponentState.ExternalOutcomeTransfer));
        }
    }

    function onToggleClick() {
        if (internalTransferState) {
            toExternalTransfer();
        } else if (externalTransferState) {
            toInternalTransfer();
        }
    }

    return {
        shouldRender,
        internalTransferState,
        externalTransferState,
        onToggleClick,
        toInternalTransfer,
        toExternalTransfer,
    };
}

export const TransferSwitch = (): JSX.Element => {
    const {
        shouldRender,
        internalTransferState,
        externalTransferState,
        onToggleClick,
        toInternalTransfer,
        toExternalTransfer,
    } = useInteractivity();

    return shouldRender ? (
        <div
            className={style.root}
            {...{
                'qa-id': 'budgetTransferMenuTransferSwitch',
            }}
        >
            <div
                className={classnames(style.title, internalTransferState && style.selected)}
                onClick={toInternalTransfer}
                {...{
                    'qa-id': 'budgetTransferMenuTransferSwitchToInternalTransferButton',
                }}
            >
                таблица
            </div>

            <div className={style.toggleWrapper}>
                <Toggle
                    qaId="budgetTransferMenuTransferSwitchToggle"
                    theme={ToggleThemes.LARGE}
                    onClick={onToggleClick}
                    position={internalTransferState ? TogglePosition.LEFT : TogglePosition.RIGHT}
                />
            </div>

            <div
                className={classnames(style.title, externalTransferState && style.selected)}
                onClick={toExternalTransfer}
                {...{
                    'qa-id': 'budgetTransferMenuTransferSwitchToExternalTransferButton',
                }}
            >
                внешние корректировки
            </div>
        </div>
    ) : null;
};
