import * as React from 'react';
import * as lodash from 'lodash';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import {
    Icon,
    IconType,
    Toggle,
    ToggleThemes,
    TogglePosition,
    Checkbox_redesign as Checkbox,
    Button_redesign as Button,
    ButtonTheme_redesign as Theme,
    Select_redesign as Select,
    OpenerIconType_redesign as SelectIconType,
    SelectTheme_redesign as SelectTheme,
} from 'sber-marketing-ui';

import { StoreState } from '@store';
import { userHasBudgetAccess } from '@store/user';
import { ComponentState, setComponentState } from '@store/profileMenu';
import {
    getProfileMenuNotificationsState,
    setNotifications,
    saveUserNotifications,
} from '@store/profileMenu/notifications';

import * as commonStyles from '../CommonStyles.scss';
import * as styles from './NotificationEditor.scss';

const DAYS = [
    { label: 'Пн', value: 1 },
    { label: 'Вт', value: 2 },
    { label: 'Ср', value: 3 },
    { label: 'Чт', value: 4 },
    { label: 'Пт', value: 5 },
    { label: 'Сб', value: 6 },
    { label: 'Вс', value: 7 },
];

const TIMES = [
    { label: '0:00', value: '00:00' },
    { label: '0:30', value: '00:30' },
    { label: '1:00', value: '01:00' },
    { label: '1:30', value: '01:30' },
    { label: '2:00', value: '02:00' },
    { label: '2:30', value: '02:30' },
    { label: '3:00', value: '03:00' },
    { label: '3:30', value: '03:30' },
    { label: '4:00', value: '04:00' },
    { label: '4:30', value: '04:30' },
    { label: '5:00', value: '05:00' },
    { label: '5:30', value: '05:30' },
    { label: '6:00', value: '06:00' },
    { label: '6:30', value: '06:30' },
    { label: '7:00', value: '07:00' },
    { label: '7:30', value: '07:30' },
    { label: '8:00', value: '08:00' },
    { label: '8:30', value: '08:30' },
    { label: '9:00', value: '09:00' },
    { label: '9:30', value: '09:30' },
    { label: '10:00', value: '10:00' },
    { label: '10:30', value: '10:30' },
    { label: '11:00', value: '11:00' },
    { label: '11:30', value: '11:30' },
    { label: '12:00', value: '12:00' },
    { label: '12:30', value: '12:30' },
    { label: '13:00', value: '13:00' },
    { label: '13:30', value: '13:30' },
    { label: '14:00', value: '14:00' },
    { label: '14:30', value: '14:30' },
    { label: '15:00', value: '15:00' },
    { label: '15:30', value: '15:30' },
    { label: '16:00', value: '16:00' },
    { label: '16:30', value: '16:30' },
    { label: '17:00', value: '17:00' },
    { label: '17:30', value: '17:30' },
    { label: '18:00', value: '18:00' },
    { label: '18:30', value: '18:30' },
    { label: '19:00', value: '19:00' },
    { label: '19:30', value: '19:30' },
    { label: '20:00', value: '20:00' },
    { label: '20:30', value: '20:30' },
    { label: '21:00', value: '21:00' },
    { label: '21:30', value: '21:30' },
    { label: '22:00', value: '22:00' },
    { label: '22:30', value: '22:30' },
    { label: '23:00', value: '23:00' },
    { label: '23:30', value: '23:30' },
];

const MAX_SETTINGS = 5;
const PREVIEW_SETTINGS = MAX_SETTINGS;

const budgetModules = {
    budgetPlanning: true,
    budgetExecution: true,
    budgetProject: true,
};

const moduleTitles = {
    activity: 'Проекты',
    task: 'Задачи',
    budgetPlanning: 'Планирование',
    budgetExecution: 'Исполнение - корректировки',
    budgetProject: 'Исполнение - связь строки',
};

interface SaveSettingsPayload {
    type: 'single' | 'module';
    code: string;
}

interface Props {
    notifications: any;
    isRequestInProgress: boolean;
    onSaveModule: (module: string, status: string, value: boolean) => void;
    onSaveSettings: (payload: SaveSettingsPayload, status: string, value: boolean) => void;
    onSaveSchedule: (schedule: any) => void;
    onSaveReport: (report: any) => void;
}

interface ContentProps {
    isRequestInProgress: boolean;
}

function useInteractivity(): Props {
    const dispatch = useDispatch();
    const saveHandler = () => {
        dispatch(saveUserNotifications());
    };

    const debouncedChangeHandler = React.useCallback(lodash.debounce(saveHandler, 1500), []);

    const hasBudgetAccess = useSelector((state: StoreState) => userHasBudgetAccess(state));
    const notifications = useSelector((state: StoreState) => getProfileMenuNotificationsState(state).notifications);
    const isRequestInProgress = useSelector(
        (state: StoreState) => getProfileMenuNotificationsState(state).isRequestInProgress,
    );

    function onSaveModule(module: string, status: string, value: boolean) {
        const notificationsUpdated = lodash.cloneDeep(notifications);
        notificationsUpdated.moduleSettings = notificationsUpdated.moduleSettings.map((item) => {
            if ((!module && item.status[status].editable) || item.module === module) {
                return {
                    ...item,
                    status: {
                        ...item.status,
                        [status]: {
                            ...item.status[status],
                            enabled: value,
                        },
                    },
                };
            }

            return item;
        });
        dispatch(setNotifications(notificationsUpdated));
        debouncedChangeHandler();
    }

    function onSaveSettings(payload: SaveSettingsPayload, status: string, value: boolean) {
        const { type, code } = payload;

        const notificationsUpdated = lodash.cloneDeep(notifications);

        const codesToUpdate =
            type === 'single'
                ? [code]
                : notificationsUpdated.notificationSettings
                      .filter((item) => item.module === code)
                      .map((item) => item.code);

        notificationsUpdated.notificationSettings = notificationsUpdated.notificationSettings.map((item) => {
            if (item.status[status].editable && codesToUpdate.includes(item.code)) {
                return {
                    ...item,
                    status: {
                        ...item.status,
                        [status]: {
                            ...item.status[status],
                            enabled: value,
                        },
                    },
                };
            }

            return item;
        });

        dispatch(setNotifications(notificationsUpdated));
        debouncedChangeHandler();
    }

    function onSaveSchedule(schedule: any) {
        const notificationsUpdated = lodash.cloneDeep(notifications);
        notificationsUpdated.schedule = schedule;
        dispatch(setNotifications(notificationsUpdated));
        debouncedChangeHandler();
    }

    function onSaveReport(report: any) {
        const notificationsUpdated = lodash.cloneDeep(notifications);
        notificationsUpdated.weeklyReport = report;
        dispatch(setNotifications(notificationsUpdated));
        debouncedChangeHandler();
    }

    return {
        hasBudgetAccess,
        notifications,
        isRequestInProgress,
        onSaveModule,
        onSaveSettings,
        onSaveSchedule,
        onSaveReport,
    };
}

export function NotificationEditor(): JSX.Element {
    const {
        hasBudgetAccess,
        notifications,
        isRequestInProgress,
        onSaveReport,
        onSaveSchedule,
        onSaveModule,
        onSaveSettings,
    } = useInteractivity();

    return (
        <div>
            <div className={classnames(commonStyles.paddingT, commonStyles.paddingB, commonStyles.paddingRL)}>
                <Title isRequestInProgress={isRequestInProgress} />
            </div>
            <div className={classnames(styles.content)}>
                <div className={classnames(styles.reportBlock, commonStyles.paddingRL)}>
                    <Toggle
                        theme={ToggleThemes.LARGE}
                        position={notifications?.weeklyReport?.enabled ? TogglePosition.RIGHT : TogglePosition.LEFT}
                        onClick={() =>
                            onSaveReport({
                                ...notifications?.weeklyReport,
                                enabled: !notifications?.weeklyReport?.enabled,
                            })
                        }
                    />{' '}
                    Отправлять еженедельный отчет
                    {notifications?.weeklyReport?.enabled && (
                        <div className={styles.reportFields}>
                            <div className={styles.formatSelect}>
                                <Select
                                    theme={SelectTheme.Rounded}
                                    openerIcon={SelectIconType.Arrow8}
                                    placeholder={'-'}
                                    value={notifications?.weeklyReport?.notificationWeekDay}
                                    onChange={(item) =>
                                        onSaveReport({
                                            ...notifications?.weeklyReport,
                                            notificationWeekDay: item.value,
                                        })
                                    }
                                    options={DAYS}
                                />
                            </div>
                            <div className={styles.formatSelectTime}>
                                <Select
                                    theme={SelectTheme.Rounded}
                                    openerIcon={SelectIconType.Arrow8}
                                    placeholder={'-'}
                                    value={notifications?.weeklyReport?.notificationTime}
                                    onChange={(item) =>
                                        onSaveReport({
                                            ...notifications?.weeklyReport,
                                            notificationTime: item.value,
                                        })
                                    }
                                    options={TIMES}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className={classnames(styles.scheduleBlock, commonStyles.paddingRL)}>
                    Когда не присылать уведомления
                    <div className={styles.scheduleForm}>
                        <div className={styles.scheduleLabel}>Выбрать часы:</div>
                        <div className={styles.scheduleFields}>
                            <div className={styles.formatSelectTime}>
                                <Select
                                    theme={SelectTheme.Rounded}
                                    openerIcon={SelectIconType.Arrow8}
                                    placeholder={'-'}
                                    value={notifications?.schedule?.silenceStart}
                                    disabled={notifications?.schedule.sendAnyTime}
                                    onChange={(item) =>
                                        onSaveSchedule({
                                            ...notifications?.schedule,
                                            silenceStart: item.value,
                                        })
                                    }
                                    options={TIMES}
                                />
                            </div>
                            <div className={styles.formatTime}>{' — '}</div>
                            <div className={styles.formatSelectTime}>
                                <Select
                                    theme={SelectTheme.Rounded}
                                    openerIcon={SelectIconType.Arrow8}
                                    placeholder={'-'}
                                    value={notifications?.schedule?.silenceEnd}
                                    disabled={notifications?.schedule.sendAnyTime}
                                    onChange={(item) =>
                                        onSaveSchedule({
                                            ...notifications?.schedule,
                                            silenceEnd: item.value,
                                        })
                                    }
                                    options={TIMES}
                                />
                            </div>
                            <div className={styles.formatCheckbox}>
                                <Checkbox
                                    isChecked={notifications?.schedule?.sendAnyTime}
                                    onSwitch={() =>
                                        onSaveSchedule({
                                            ...notifications?.schedule,
                                            sendAnyTime: !notifications?.schedule.sendAnyTime,
                                        })
                                    }
                                />{' '}
                                Присылать уведомления в любое время
                            </div>
                        </div>
                        <div className={styles.scheduleLabel}>Выбрать дни:</div>
                        <div className={styles.scheduleFields}>
                            <div className={styles.formatButtons}>
                                {DAYS.map((item) => (
                                    <Button
                                        theme={
                                            notifications?.schedule?.daysOf?.find((day: number) => day === item.value)
                                                ? Theme.Main
                                                : Theme.Ghost
                                        }
                                        isSelected={notifications?.schedule?.daysOf?.find(
                                            (day: number) => day === item.value,
                                        )}
                                        onClick={() => {
                                            let hasDay = false;
                                            const daysOf = (notifications?.schedule?.daysOf || []).filter(
                                                (day: number) => {
                                                    if (!hasDay) {
                                                        hasDay = day === item.value;
                                                    }
                                                    return day !== item.value;
                                                },
                                            );

                                            onSaveSchedule({
                                                ...notifications?.schedule,
                                                daysOf: hasDay ? daysOf : [...daysOf, item.value],
                                            });
                                        }}
                                    >
                                        {item.label}
                                    </Button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={commonStyles.paddingRL}>
                    {(notifications?.moduleSettings || []).map((item, index) =>
                        hasBudgetAccess || !budgetModules[item.module] ? (
                            <Table
                                key={`${item.module}-${index}`}
                                blockTitle={item.title || moduleTitles[item.module] || item.module}
                                blockId={item.module}
                                module={item}
                                notifications={notifications}
                                onSaveModule={onSaveModule}
                                onSaveSettings={onSaveSettings}
                            />
                        ) : null,
                    )}
                </div>
            </div>
        </div>
    );
}

function Title({ isRequestInProgress }: ContentProps): JSX.Element {
    const dispatch = useDispatch();

    function onBackButtonClick() {
        dispatch(setComponentState(ComponentState.Menu));
    }

    return (
        <div className={styles.title}>
            <svg
                width="16"
                height="8"
                viewBox="0 0 16 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.titleArrow}
                onClick={isRequestInProgress ? null : onBackButtonClick}
            >
                <path
                    d="M3.62924 0.121796L3.55957 0.181942L0.180755 3.56075C-0.0397797 3.78129 -0.0598297 4.12639 0.120608 4.36957L0.180755 4.43925L3.55957 7.81806C3.80216 8.06065 4.19547 8.06065 4.43806 7.81806C4.65859 7.59752 4.67864 7.25242 4.4982 7.00924L4.43806 6.93957L2.15 4.65H15.35C15.709 4.65 16 4.35898 16 4C16 3.67365 15.7595 3.40347 15.4461 3.35705L15.35 3.35L2.149 3.349L4.43806 1.06043C4.65859 0.839898 4.67864 0.494796 4.4982 0.251613L4.43806 0.181942C4.21752 -0.0385936 3.87242 -0.0586423 3.62924 0.121796Z"
                    fill="#7E8681"
                />
            </svg>
            Настройки уведомлений
        </div>
    );
}

interface TableProps {
    showTooltip?: boolean;
    blockTitle: string;
    blockId: string;
    module: any;
    notifications: any;
    onSaveModule: (module: string, status: string, value: boolean) => void;
    onSaveSettings: (payload: SaveSettingsPayload, status: string, value: boolean) => void;
}

function Table({
    module,
    notifications,
    onSaveModule,
    onSaveSettings,
    showTooltip,
    blockTitle,
    blockId,
}: TableProps): JSX.Element {
    const notificationsSettings = (notifications?.notificationSettings || []).filter(
        (item) => item?.module === blockId,
    );
    const settings = lodash.keyBy(notificationsSettings || [], 'code');
    const hideEnabled = notificationsSettings.length > MAX_SETTINGS;

    const [isHideAll, setIsHideAll] = React.useState(hideEnabled);

    const allFeed = !(notificationsSettings || []).some(
        (item) => !item?.status?.feed?.enabled && item?.status?.feed?.editable,
    );
    const allEmail = !(notificationsSettings || []).some(
        (item) => !item?.status?.email?.enabled && item?.status?.email?.editable,
    );

    const options = isHideAll ? notificationsSettings.slice(0, PREVIEW_SETTINGS) : notificationsSettings;

    return (
        <div
            key={blockId}
            className={classnames(
                (!module?.status?.email?.editable || !module?.status?.feed?.editable) && styles.tableShort,
                styles.table,
            )}
        >
            <div className={styles.tableHeader}>
                <div className={styles.tableCellHeaderTitle} />
                {module?.status?.email?.editable && (
                    <div
                        className={classnames(
                            styles.tableCell,
                            module?.status?.feed?.editable && styles.tableHideTitleTop,
                        )}
                    >
                        Почта
                    </div>
                )}
                {module?.status?.feed?.editable && <div className={styles.tableCell}>Лента новостей</div>}
            </div>
            <div className={styles.tableHeader}>
                <div className={styles.tableCellHeaderTitle}>
                    <b>{blockTitle}</b>
                </div>
                {module?.status?.email?.editable && (
                    <div
                        className={classnames(
                            styles.tableCellCheckbox,
                            !module?.status?.email?.editable && styles.disabledToggle,
                        )}
                    >
                        <Toggle
                            theme={ToggleThemes.LARGE}
                            position={module?.status?.email?.enabled ? TogglePosition.RIGHT : TogglePosition.LEFT}
                            disabled={!module?.status?.email?.editable}
                            onClick={() => onSaveModule(blockId, 'email', !module?.status?.email?.enabled)}
                        />
                    </div>
                )}
                {module?.status?.feed?.editable && (
                    <div
                        className={classnames(
                            styles.tableCellCheckbox,
                            !module?.status?.feed?.editable && styles.disabledToggle,
                        )}
                    >
                        <Toggle
                            theme={ToggleThemes.LARGE}
                            position={module?.status?.feed?.enabled ? TogglePosition.RIGHT : TogglePosition.LEFT}
                            disabled={!module?.status?.feed?.editable}
                            onClick={() => onSaveModule(blockId, 'feed', !module?.status?.feed?.enabled)}
                        />
                    </div>
                )}
            </div>
            <div className={styles.tableRow}>
                <div className={styles.tableCellTitle}>
                    {showTooltip && (
                        <>
                            <Icon type={IconType.INFO_CIRCLE} /> Уведомления не будут приходить на почту
                        </>
                    )}
                </div>
                {module?.status?.email?.editable && (
                    <div
                        className={classnames(
                            module?.status?.email?.editable && styles.tableCellBack,
                            styles.tableCellBase,
                            styles.tableCellCheckbox,
                        )}
                    >
                        <div
                            className={styles.openLink}
                            onClick={() => onSaveSettings({ type: 'module', code: blockId }, 'email', !allEmail)}
                        >
                            {allEmail ? 'сбросить все' : 'выбрать все'}
                        </div>
                    </div>
                )}
                {module?.status?.feed?.editable && (
                    <div
                        className={classnames(
                            module?.status?.feed?.editable && styles.tableCellBack,
                            styles.tableCellBase,
                            styles.tableCellCheckbox,
                        )}
                    >
                        <div
                            className={styles.openLink}
                            onClick={() => onSaveSettings({ type: 'module', code: blockId }, 'feed', !allFeed)}
                        >
                            {allFeed ? 'сбросить все' : 'выбрать все'}
                        </div>
                    </div>
                )}
            </div>
            {options.map((item, index) =>
                item?.code ? (
                    <div
                        key={`${item?.code}-${index}`}
                        className={classnames(styles.tableRow, index + 1 === options.length ? styles.tableRowLast : '')}
                    >
                        <div className={styles.tableCellTitle}>{item?.title || ''}</div>
                        {module?.status?.email?.editable && (
                            <div
                                className={classnames(
                                    module?.status?.email?.editable && styles.tableCellBack,
                                    styles.tableCellBase,
                                    styles.tableCellCheckbox,
                                )}
                            >
                                <Checkbox
                                    isChecked={lodash.get(settings[item?.code], 'status.email.enabled')}
                                    disabled={
                                        !lodash.get(module, 'status.email.enabled') ||
                                        !lodash.get(settings[item?.code], 'status.email.editable')
                                    }
                                    onSwitch={() =>
                                        onSaveSettings(
                                            { type: 'single', code: item?.code },
                                            'email',
                                            !lodash.get(settings[item?.code], 'status.email.enabled'),
                                        )
                                    }
                                />
                            </div>
                        )}
                        {module?.status?.feed?.editable && (
                            <div
                                className={classnames(
                                    module?.status?.feed?.editable && styles.tableCellBack,
                                    styles.tableCellBase,
                                    styles.tableCellCheckbox,
                                )}
                            >
                                <Checkbox
                                    isChecked={lodash.get(settings[item?.code], 'status.feed.enabled')}
                                    disabled={
                                        !lodash.get(module, 'status.feed.enabled') ||
                                        !lodash.get(settings[item?.code], 'status.feed.editable')
                                    }
                                    onSwitch={() =>
                                        onSaveSettings(
                                            { type: 'single', code: item?.code },
                                            'feed',
                                            !lodash.get(settings[item?.code], 'status.feed.enabled'),
                                        )
                                    }
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <div
                        key={item?.title}
                        className={classnames(styles.tableRow, index + 1 === options.length ? styles.tableRowLast : '')}
                    >
                        <div className={styles.tableCellTitle}>
                            <b>{item?.title || ''}</b>
                        </div>
                        <div
                            className={classnames(
                                module?.status?.email?.editable && styles.tableCellBack,
                                styles.tableCellBase,
                                styles.tableCellCheckbox,
                            )}
                        ></div>
                        <div
                            className={classnames(
                                module?.status?.feed?.editable && styles.tableCellBack,
                                styles.tableCellBase,
                                styles.tableCellCheckbox,
                            )}
                        ></div>
                    </div>
                ),
            )}
            {hideEnabled && (
                <div className={styles.tableHideTitle}>
                    <div className={styles.openLink} onClick={() => setIsHideAll(!isHideAll)}>
                        {isHideAll ? 'Показать все' : 'Свернуть'}
                    </div>
                </div>
            )}
        </div>
    );
}
