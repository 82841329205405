// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-tags-TagsPage-RightSidebar-Description-_Description__root{width:calc(100% - 44px);height:100%;padding:14px 24px 15px 20px;display:flex;align-items:center}.src-client-modules-tags-TagsPage-RightSidebar-Description-_Description__description{max-width:calc(100% - 57px);font-family:'Open Sans';font-weight:600;font-size:14px;line-height:19px;letter-spacing:0.25px;color:#000000;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.src-client-modules-tags-TagsPage-RightSidebar-Description-_Description__icon{margin-right:4px;color:#7E8681}.src-client-modules-tags-TagsPage-RightSidebar-Description-_Description__openSourceLink{text-decoration:none;margin-left:5px;color:#7E8681}.src-client-modules-tags-TagsPage-RightSidebar-Description-_Description__openSourceLink:hover{color:#417EC6}\n", "",{"version":3,"sources":["webpack://./src/client/modules/tags/TagsPage/RightSidebar/Description/Description.scss"],"names":[],"mappings":"AAAA,8EACI,uBAAwB,CACxB,WAAY,CAEZ,2BAA4B,CAE5B,YAAa,CACb,kBAAmB,CACtB,qFAGG,2BAA4B,CAE5B,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,qBAAsB,CACtB,aAAc,CAEd,eAAgB,CAChB,sBAAuB,CACvB,kBAAmB,CACtB,8EAGG,gBAAiB,CAEjB,aAAc,CACjB,wFAGG,oBAAqB,CAErB,eAAgB,CAEhB,aAAc,CALlB,8FAQQ,aAAc","sourcesContent":[".root {\n    width: calc(100% - 44px);\n    height: 100%;\n\n    padding: 14px 24px 15px 20px;\n\n    display: flex;\n    align-items: center;\n}\n\n.description {\n    max-width: calc(100% - 57px);\n\n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 19px;\n    letter-spacing: 0.25px;\n    color: #000000;\n\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n.icon {\n    margin-right: 4px;\n\n    color: #7E8681;\n}\n\n.openSourceLink {\n    text-decoration: none;\n\n    margin-left: 5px;\n\n    color: #7E8681;\n\n    &:hover {\n        color: #417EC6;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-tags-TagsPage-RightSidebar-Description-_Description__root",
	"description": "src-client-modules-tags-TagsPage-RightSidebar-Description-_Description__description",
	"icon": "src-client-modules-tags-TagsPage-RightSidebar-Description-_Description__icon",
	"openSourceLink": "src-client-modules-tags-TagsPage-RightSidebar-Description-_Description__openSourceLink"
};
export default ___CSS_LOADER_EXPORT___;
