import * as React from 'react';
import classNames from 'classnames';
import { HeightAnimation } from 'sber-marketing-ui';

import { DifferenceItemParams } from '@store/news';

import { ToggleButton } from '../ToggleButton';

import { Difference } from './Difference';

import * as style from './DifferenceList.scss';

interface Props {
    differences: DifferenceItemParams[];
    isOpened: boolean;
    onToggleButtonClick: () => void;
}

export function DifferenceList({ differences, isOpened, onToggleButtonClick }: Props): JSX.Element {
    const [firstDiff, ...restDiff] = differences;

    return (
        <HeightAnimation>
            <div className={style.newsPartWrapper}>
                <div className={classNames([style.diffWrapper, style.diffWrapperFirst])}>
                    <Difference {...firstDiff} index={0} />
                </div>

                {isOpened &&
                    restDiff.map((diff, index) => (
                        <div className={style.diffWrapper} key={diff.id}>
                            <Difference {...diff} index={index + 1} />
                        </div>
                    ))}

                {restDiff.length ? (
                    <ToggleButton
                        toggledOffText={`Показать еще ${restDiff.length}`}
                        isOpened={isOpened}
                        onClick={onToggleButtonClick}
                    />
                ) : null}
            </div>
        </HeightAnimation>
    );
}
