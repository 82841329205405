import * as React from 'react';
import { connect } from 'react-redux';
import * as lodash from 'lodash';
import * as moment from 'moment';

import { TopMenu } from './TopMenu';
import type { StoreState } from '@store';
import { getFilters } from '@store/calendar/selectors';

interface Props extends Partial<MapProps> {
    showExpiredStagesIndicator: boolean;
}

interface MapProps {
    weekStart: Date;
}

@(connect(mapStateToProps, null) as any)
export class TopMenuContainer extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        return React.createElement(TopMenu, {
            columnsTitles: this.makeColumnsTitles(),
            showExpiredStagesIndicator: this.props.showExpiredStagesIndicator,
        });
    }

    private makeColumnsTitles(): { dayName: string; dayNumber: string }[] {
        const result: { dayName: string; dayNumber: string }[] = [];

        const currentDay = moment(this.props.weekStart);

        while (result.length < 6) {
            const weekDay = currentDay.weekday();

            const isSaturday = weekDay === 5;
            const isSunday = weekDay === 6;

            if (isSaturday) {
                result.push({
                    dayName: `${currentDay.format('dddd')}-${currentDay.clone().add(1, 'day').format('dddd')}`,
                    dayNumber: `${currentDay.format('D')}-${currentDay.clone().add(1, 'day').format('D')}`,
                });
            } else if (isSunday && lodash.isEmpty(result)) {
                result.push({
                    dayName: `${currentDay.clone().subtract(1, 'day').format('dddd')}-${currentDay.format('dddd')}`,
                    dayNumber: `${currentDay.clone().subtract(1, 'day').format('D')}-${currentDay.format('D')}`,
                });
            } else if (!isSunday) {
                result.push({
                    dayName: currentDay.format('dddd'),
                    dayNumber: currentDay.format('D'),
                });
            }

            currentDay.add(1, 'day');
        }

        return result;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { weekStart } = getFilters(state);

    return {
        weekStart,
    };
}
