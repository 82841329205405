import { Dictionary } from 'lodash';
import { HistoryActionType, HistoryType, TaskStatus } from 'sber-marketing-types/frontend';

/** "getNewsStyle" result signature */
export interface NewsStyle {
    /** Type of origin item */
    type: HistoryType;
    /** Title of news */
    title: string;
    /** Color of news */
    color: string;
    /** Text color of news */
    textColor: string;
}

const BRIEF_STATUSES: Dictionary<Partial<NewsStyle>> = {
    [HistoryActionType.UpdateBrief]: {
        title: 'Отредактирован бриф',
        color: '#f5a623',
        textColor: '#ffffff',
    },
};

const ACTIVITY_STATUSES: Dictionary<Partial<NewsStyle>> = {
    [HistoryActionType.ActivityDeadlineChanged]: {
        title: 'Изменены сроки',
        color: '#f5a623',
        textColor: '#ffffff',
    },
    [HistoryActionType.ActivityDeadlineOverdue]: {
        title: 'Просрочена',
        color: '#f5a623',
        textColor: '#ffffff',
    },
    [HistoryActionType.UpdateActivity]: {
        title: 'Обновлена',
        color: '#f5a623',
        textColor: '#ffffff',
    },
};

const TASK_STATUSES: Dictionary<Partial<NewsStyle>> = {
    [HistoryActionType.CreateTask]: {
        title: 'Создана задача',
        color: '#f5a623',
        textColor: '#ffffff',
    },
    [HistoryActionType.UpdateTask]: {
        title: 'Задача обновлена',
        color: '#f5a623',
        textColor: '#ffffff',
    },
    [HistoryActionType.DeleteTaskComment]: {
        title: 'Удален комментарий',
        color: '#f5a623',
        textColor: '#ffffff',
    },
    [HistoryActionType.AddTaskComment]: {
        title: 'Новый комментарий',
        color: '#f2f2f2',
    },
    [HistoryActionType.AddTaskCommentFile]: {
        title: 'Добавлен файл в комментарий',
        color: '#f2f2f2',
    },
    [HistoryActionType.UpdateTaskComment]: {
        title: 'Изменен комментарий',
        color: '#f2f2f2',
    },
    [HistoryActionType.UpdateTaskDeadline]: {
        title: 'Сроки изменены',
        color: '#19bb4f',
        textColor: '#ffffff',
    },
    [HistoryActionType.AddTaskFile]: {
        title: 'Новый файл',
        color: '#f2f2f2',
    },
    [HistoryActionType.UpdateTaskTitle]: {
        title: 'Изменено название',
        color: '#f2f2f2',
    },
    [HistoryActionType.UpdateTaskDescription]: {
        title: 'Изменено описание',
        color: '#f2f2f2',
    },
    [HistoryActionType.UpdateTaskStatus]: {
        title: 'Задача в работе',
        color: '#f5a623',
        textColor: '#ffffff',
    },
    [HistoryActionType.DeleteTask]: {
        title: 'Задача удалена',
        color: 'rgba(74,144,226,0.25)',
    },
    /*[HistoryActionType.DeadLineOverdue]: {
        title: 'Просрочена',
        color: '#d0021b',
        textColor: '#ffffff'
    },*/
    [HistoryActionType.UpdateTaskExecutor]: {
        title: 'Изменен исполнитель',
        color: '#f2f2f2',
    },
    [HistoryActionType.TaskReturned]: {
        title: 'Задача возвращена в работу',
        color: '#f5a623',
        textColor: '#ffffff',
    },
};

export function getNewsStyle(
    newsType: HistoryType | undefined,
    newsViewAction: HistoryActionType | undefined,
    status?: TaskStatus,
): NewsStyle {
    let result: NewsStyle;

    const BASE_TEXT_COLOR = '#000000';

    let STATUSES: Dictionary<Partial<NewsStyle>> = null;

    switch (newsType) {
        case HistoryType.Task:
            STATUSES = TASK_STATUSES;
            break;
        case HistoryType.Comment:
            STATUSES = TASK_STATUSES;
            break;
        case HistoryType.Activity:
            STATUSES = ACTIVITY_STATUSES;
            break;
        case HistoryType.Brief:
            STATUSES = BRIEF_STATUSES;
            break;
        default:
            STATUSES = {};
            break;
    }

    result = {
        type: newsType,
        title: 'Новость',
        color: 'rgba(74,144,226,0.25)',
        textColor: BASE_TEXT_COLOR,
        ...(STATUSES[newsViewAction] || {}),
    };

    if (status && status === TaskStatus.Closed && newsViewAction === HistoryActionType.UpdateTaskStatus) {
        result.title = 'Задача закрыта';
        result.color = 'rgba(74,144,226,0.25)';
    }

    return result;
}
