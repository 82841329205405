import * as React from 'react';
import { FilterMenu, FilterItem } from 'sber-marketing-ui';

import { SerialNumbersList } from '../SerialNumbersList';

import * as style from './BudgetItemSelect.scss';

interface Props {
    serialNumbersToCreate: string[];
    serialNumbersToDelete: string[];
    items: FilterItem[];
    selectedItemIds: string[];
    onItemSelect: (itemIds: string[]) => void;
    onSerialNumberRemoveButtonClick: (serialNumberId: string) => void;
}

export function BudgetItemSelect({
    serialNumbersToCreate,
    serialNumbersToDelete,
    items,
    selectedItemIds,
    onItemSelect,
    onSerialNumberRemoveButtonClick,
}: Props): JSX.Element {
    return (
        <React.Fragment>
            <div className={style.budgetItemSelect}>
                <div className={style.caption}>Доступные ID</div>

                <FilterMenu
                    filterTitle={'ID исполнения'}
                    items={items}
                    checkedItemsIds={selectedItemIds}
                    onItemSelection={onItemSelect}
                    preserveAllSelectedState
                />
            </div>

            <div className={style.serialNumbersList}>
                <SerialNumbersList
                    title={'ID будут привязаны'}
                    serialNumbers={serialNumbersToCreate}
                    onRemoveButtonClick={onSerialNumberRemoveButtonClick}
                />
            </div>

            <div className={style.serialNumbersList}>
                <SerialNumbersList
                    title={'ID будут отвязаны'}
                    serialNumbers={serialNumbersToDelete}
                    onRemoveButtonClick={onSerialNumberRemoveButtonClick}
                />
            </div>
        </React.Fragment>
    );
}
