// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilotDOOH-StageWidgets-MediaplanSettingsStageWidget-RegionsTable-TableSettings-Checkbox-_Checkbox__root{display:flex;align-items:center;user-select:none;cursor:pointer}.src-client-modules-activity-pages-autopilotDOOH-StageWidgets-MediaplanSettingsStageWidget-RegionsTable-TableSettings-Checkbox-_Checkbox__root.src-client-modules-activity-pages-autopilotDOOH-StageWidgets-MediaplanSettingsStageWidget-RegionsTable-TableSettings-Checkbox-_Checkbox__disabled{cursor:auto}.src-client-modules-activity-pages-autopilotDOOH-StageWidgets-MediaplanSettingsStageWidget-RegionsTable-TableSettings-Checkbox-_Checkbox__iconWrapper{width:16px;height:16px}.src-client-modules-activity-pages-autopilotDOOH-StageWidgets-MediaplanSettingsStageWidget-RegionsTable-TableSettings-Checkbox-_Checkbox__icon{width:24px;height:24px;transform:translate(-4px, -4px)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilotDOOH/StageWidgets/MediaplanSettingsStageWidget/RegionsTable/TableSettings/Checkbox/Checkbox.scss"],"names":[],"mappings":"AAAA,+IACI,YAAa,CACb,kBAAmB,CACnB,gBAAiB,CACjB,cAAe,CAJnB,iSAOQ,WAAY,CACf,sJAID,UAAW,CACX,WAAY,CACf,+IAGG,UAAW,CACX,WAAY,CACZ,+BAAgC","sourcesContent":[".root {\n    display: flex;\n    align-items: center;\n    user-select: none;\n    cursor: pointer;\n\n    &.disabled {\n        cursor: auto;\n    }\n}\n\n.iconWrapper {\n    width: 16px;\n    height: 16px;\n}\n\n.icon {\n    width: 24px;\n    height: 24px;\n    transform: translate(-4px, -4px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilotDOOH-StageWidgets-MediaplanSettingsStageWidget-RegionsTable-TableSettings-Checkbox-_Checkbox__root",
	"disabled": "src-client-modules-activity-pages-autopilotDOOH-StageWidgets-MediaplanSettingsStageWidget-RegionsTable-TableSettings-Checkbox-_Checkbox__disabled",
	"iconWrapper": "src-client-modules-activity-pages-autopilotDOOH-StageWidgets-MediaplanSettingsStageWidget-RegionsTable-TableSettings-Checkbox-_Checkbox__iconWrapper",
	"icon": "src-client-modules-activity-pages-autopilotDOOH-StageWidgets-MediaplanSettingsStageWidget-RegionsTable-TableSettings-Checkbox-_Checkbox__icon"
};
export default ___CSS_LOADER_EXPORT___;
