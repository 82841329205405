// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetCorrections-Filters-Filter-_Filter__root{position:relative}.src-client-modules-budget-BudgetPage-BudgetCorrections-Filters-Filter-_Filter__nonActiveBackground{display:block;position:absolute;z-index:10;top:0;width:100%;height:100%;border-radius:4px;background-color:rgba(0,0,0,0.25)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetCorrections/Filters/Filter/Filter.scss"],"names":[],"mappings":"AAAA,qFACI,iBAAkB,CACrB,oGAGG,aAAc,CACd,iBAAkB,CAClB,UAAW,CACX,KAAM,CAEN,UAAW,CACX,WAAY,CACZ,iBAAkB,CAClB,iCAAqC","sourcesContent":[".root {\n    position: relative;\n}\n\n.nonActiveBackground {\n    display: block;\n    position: absolute;\n    z-index: 10;\n    top: 0;\n\n    width: 100%;\n    height: 100%;\n    border-radius: 4px;\n    background-color: rgba(0, 0, 0, 0.25);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetCorrections-Filters-Filter-_Filter__root",
	"nonActiveBackground": "src-client-modules-budget-BudgetPage-BudgetCorrections-Filters-Filter-_Filter__nonActiveBackground"
};
export default ___CSS_LOADER_EXPORT___;
