import * as React from 'react';

import * as styles from './HeaderContent.scss';

import { QuickAccessIcons } from '@common/QuickAccessIcons';

interface Props {}

export function HeaderContentTemplate({}: Props): JSX.Element {
    return (
        <div className={styles.rightSidebarContent}>
            <div className={styles.quickAccessIcons}>
                <QuickAccessIcons />
            </div>
        </div>
    );
}
