import * as React from 'react';

import * as style from './SelectCell.scss';

import { Icon, IconType } from 'sber-marketing-ui';
import { CommentsButton } from '../CommentsButton';
import { ContextMenu } from '../ContextMenu';

interface Props {
    title: string;
    description?: string | null;
    iconType?: any;
    customStyle?: any;
    commentButtonProps?: { hasNewComments: boolean; onClick: () => void };
    isReadOnly: boolean;
    onValueChange: (value: React.ReactText) => void;
}

export const SelectWithIconCell = ({
    title,
    description,
    iconType,
    customStyle,
    commentButtonProps,
    isReadOnly,
    onValueChange,
}: Props): JSX.Element => {
    function onClearValueClick() {
        onValueChange(null);
    }

    return (
        <>
            <CommentsButton {...commentButtonProps} />
            <ContextMenu
                items={[
                    { title: 'Смотреть комментарии', onClick: commentButtonProps?.onClick },
                    { title: 'Очистить', onClick: onClearValueClick },
                ]}
            />

            <div className={style.root} title={title} style={{ ...customStyle }}>
                <div className={style.flexCenter}>
                    {iconType && (
                        <div className={style.iconWrapper}>
                            <Icon type={iconType} svgSize={16} />
                        </div>
                    )}
                    <div className={style.flexColumn}>
                        <div className={style.title}>{title}</div>
                        {description && <div className={style.description}>{description}</div>}
                    </div>
                </div>
                {!isReadOnly && (
                    <div className={style.openerIcon}>
                        <Icon type={IconType.TRIANGLE8_DOWN} svgSize={8} />
                    </div>
                )}
            </div>
        </>
    );
};
