import * as React from 'react';

import * as style from './ConfirmModal.scss';

import {
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    Icon,
    IconType,
    BodyPortal,
} from 'sber-marketing-ui';

interface Props {
    title: string;
    message: string;
    closeButtonText: string;
    confirmButtonText: string;
    onCloseButtonClick: () => void;
    onConfirmButtonClick: () => void;
}

export const ConfirmModalTemplate = ({
    title,
    message,
    closeButtonText,
    confirmButtonText: acceptButtonText,
    onCloseButtonClick,
    onConfirmButtonClick,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.title}>{title}</div>

            <div className={style.message}>{message}</div>

            <div className={style.buttons}>
                <div className={style.button}>
                    <Button theme={ButtonTheme.GhostRounded} onClick={onCloseButtonClick}>
                        {closeButtonText}
                    </Button>
                </div>

                <div className={style.button}>
                    <Button theme={ButtonTheme.MainRounded} onClick={onConfirmButtonClick}>
                        {acceptButtonText}
                    </Button>
                </div>
            </div>

            <div className={style.closeButton} onClick={onCloseButtonClick}>
                <Icon type={IconType.THIN_CROSS} svgSize={32} />
            </div>

            <BodyPortal>
                <div className={style.mask} />
            </BodyPortal>
        </div>
    );
};
