import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconType } from 'sber-marketing-ui';

import * as styles from './BudgetItemLink.scss';

export interface LinkProps {
    id: string;
    budgetItem: {
        serialNumber: number;
    };
    project: {
        id: number;
    };
    status: 'pending' | 'approved' | 'rejected';
}

interface Props extends LinkProps {
    selectedCardId: string;
    onClick: () => void;
}

export function BudgetItemLink({ id, budgetItem, status, selectedCardId, onClick }: Props): JSX.Element {
    const clickable = selectedCardId !== id;

    return (
        <div
            className={classNames(styles.root, clickable && styles.root_clickable)}
            onClick={clickable ? onClick : null}
            {...{
                'qa-id': 'dashboardRightSidebarActivityWidgetLinkedBudgetItem',
                'qa-status': status,
            }}
        >
            <div className={styles.serialNumber}>{budgetItem.serialNumber}</div>

            {status === 'approved' && (
                <div className={styles.statusIcon}>
                    <Icon type={IconType.CHECK_UPDATED} svgSize={14} color={'#19bb4f'} />
                </div>
            )}
        </div>
    );
}
