import createCashedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { BudgetItem } from '@mrm/budget';
import { SelectItem } from 'sber-marketing-ui';
import { values, sum } from 'lodash';

import { StoreState } from '@store';
import { MONTH_BY_COLUMN_NAMES } from '@store/budgetExecution';

import { InternalTransferDirection, ComponentState, BudgetTransferMenuState, CellPosition } from './types';
import { cellBelongsToLine, checkCellsEquality } from './misc';

export const getBudgetTransferMenuState = (state: StoreState): BudgetTransferMenuState =>
    state.budgetExecutionPage.budgetTransferMenuState;

export function isBudgetTransferMenuClosed(componentState: ComponentState) {
    return componentState === ComponentState.Closed;
}

export const getTransitionBudgetItems = createSelector(
    (state: StoreState) => state,
    getBudgetTransferMenuState,
    (state: StoreState, transitionData: BudgetTransferMenuState) => [
        ...state.budgetExecutionPage.restState.pageData.budgetItems,
        ...state.budgetExecutionPage.restState.pageData.budgetItemsToIgnoreFilters,
        ...transitionData.data.budgetItems,
    ],
);

export const getExpertsAsSelectItems = createSelector(getBudgetTransferMenuState, (state): SelectItem[] =>
    state.experts.entities.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.secondName}`,
    })),
);

export const isLineDonor = createCashedSelector(
    getBudgetTransferMenuState,
    (state: StoreState, lineId: string) => lineId,
    (state: BudgetTransferMenuState, lineId: string): boolean =>
        state.cells.from.some((fromCell) => cellBelongsToLine(fromCell, lineId)),
)((state: StoreState, lineId: string) => lineId);

export const isCellDonor = createCashedSelector(
    getBudgetTransferMenuState,
    (state: StoreState, cell: CellPosition) => cell,
    (state: BudgetTransferMenuState, cell: CellPosition): boolean =>
        state.cells.from.some((fromCell) => checkCellsEquality(fromCell, cell)),
)((state: StoreState, cell: CellPosition) => `${cell.columnName}-${cell.lineId}`);

export const isLineAcceptor = createCashedSelector(
    getBudgetTransferMenuState,
    (state: StoreState, lineId: string) => lineId,
    (state: BudgetTransferMenuState, lineId: string): boolean =>
        state.cells.to.some((toCell) => cellBelongsToLine(toCell, lineId)),
)((state: StoreState, lineId: string) => lineId);

export const isCellAcceptor = createCashedSelector(
    getBudgetTransferMenuState,
    (state: StoreState, cell: CellPosition) => cell,
    (state: BudgetTransferMenuState, cell: CellPosition): boolean =>
        state.cells.to.some((toCell) => checkCellsEquality(toCell, cell)),
)((state: StoreState, cell: CellPosition) => `${cell.columnName}-${cell.lineId}`);

export const getTransferAmount = (state: BudgetTransferMenuState, from: CellPosition, to: CellPosition) => {
    return (
        state.transferDescriptors.find(
            (transferDescriptor) =>
                transferDescriptor.from?.lineId === from.lineId &&
                transferDescriptor.from?.columnName === from.columnName &&
                transferDescriptor.to?.lineId === to.lineId &&
                transferDescriptor.to?.columnName === to.columnName,
        )?.amount || 0
    );
};

export const getTotalTransferAmount = createSelector(
    getBudgetTransferMenuState,
    (state: BudgetTransferMenuState): number => sum(state.transferDescriptors.map((descriptor) => descriptor.amount)),
);

export const getTotalTransferAmountForSource = createCashedSelector(
    getBudgetTransferMenuState,
    (state: StoreState, lineId: string): string => lineId,
    (state, lineId) =>
        sum(
            state.transferDescriptors
                .filter(
                    (transferDescriptor) =>
                        transferDescriptor.from?.lineId === lineId &&
                        transferDescriptor.from?.lineId !== transferDescriptor.to?.lineId,
                )
                .map((transferDesctriptor) => transferDesctriptor.amount),
        ) || 0,
)((state: StoreState, lineId: string): string => lineId);

export const getTotalTransferAmountForDest = createCashedSelector(
    getBudgetTransferMenuState,
    (state: StoreState, lineId: string): string => lineId,
    (state: BudgetTransferMenuState, lineId: string) =>
        sum(
            state.transferDescriptors
                .filter(
                    (transferDescriptor) =>
                        transferDescriptor.to?.lineId === lineId &&
                        transferDescriptor.from?.lineId !== transferDescriptor.to?.lineId,
                )
                .map((transferDesctriptor) => transferDesctriptor.amount),
        ) || 0,
)((state: StoreState, lineId: string): string => lineId);

export const getTransferDesctiptors = createSelector(getBudgetTransferMenuState, (state: BudgetTransferMenuState) => {
    const {
        cells: { from, to },
        controls: { rowForSumEntering, internalTransferDirection },
        transferDescriptors,
    } = state;

    let fromLine: string;
    let toLine: string;
    switch (internalTransferDirection) {
        case InternalTransferDirection.OneToMany:
            fromLine = from[0].lineId;
            toLine = rowForSumEntering;
            break;
        case InternalTransferDirection.ManyToOne:
            fromLine = rowForSumEntering;
            toLine = to[0].lineId;
            break;
        default:
            fromLine = null;
            toLine = null;
            break;
    }

    return transferDescriptors.filter(
        (transferAmount) => transferAmount.from.lineId === fromLine && transferAmount.to.lineId === toLine,
    );
});

export const getTransitionBudgetItem = createCashedSelector(
    (state: StoreState) => state,
    getBudgetTransferMenuState,
    (state: StoreState, id: string): string => id,
    (state: StoreState, transitionState: BudgetTransferMenuState, id: string): BudgetItem => {
        const budgetItems = [
            ...state.budgetExecutionPage.restState.pageData.budgetItems,
            ...state.budgetExecutionPage.restState.pageData.budgetItemsToIgnoreFilters,
            ...transitionState.data.budgetItems,
        ];
        const budgetItem = budgetItems.find((budgetItem) => budgetItem.id === id);

        if (!budgetItem) {
            console.warn(
                `BudgetItem with id ${id} was requested by transitionData, but was not found in pageData, returning first available`,
            );

            return budgetItems[0] || null;
        }

        return budgetItem;
    },
)((state: StoreState, id: string): string => id);

export const getFromLinesWithNegativePlanReserveDiff = createSelector(
    (state: StoreState) => state,
    getBudgetTransferMenuState,
    (state: StoreState, transitionData: BudgetTransferMenuState): CellPosition[] => {
        const {
            cells: { from },
            data: { budgetItems },
        } = transitionData;

        return from.filter((fromCell) => {
            const fromBudgetItem = budgetItems.find((budgetItem) => budgetItem.id === fromCell.lineId);
            const transferAmount = getTotalTransferAmountForSource(state, fromCell.lineId);

            return (
                sum(values(fromBudgetItem?.plannedFunds || {})) -
                    sum(values(fromBudgetItem?.reservedFunds || {})) -
                    transferAmount <
                0
            );
        });
    },
);

export const canMoveAcceptorCellToDonor = createSelector(
    (state: StoreState) => state,
    getBudgetTransferMenuState,
    (state: StoreState, transitionData: BudgetTransferMenuState): boolean => {
        const internalTransferDirection = transitionData.controls.internalTransferDirection;
        const toCell = transitionData.cells.to[0];

        const checkCell = toCell && internalTransferDirection === InternalTransferDirection.ManyToOne;
        if (checkCell) {
            const toBudgetItem = getTransitionBudgetItem(state, toCell.lineId);

            return !!toBudgetItem.plannedFunds[MONTH_BY_COLUMN_NAMES[toCell.columnName]];
        }

        return true;
    },
);

export const getLinesMovebaleToDonor = createSelector(
    (state: StoreState) => state,
    getBudgetTransferMenuState,
    (state: StoreState, transitionData: BudgetTransferMenuState): string[] => {
        const { to, from } = transitionData.cells;

        return to
            .filter((toCell) => {
                const toBudgetItemFunds = { ...getTransitionBudgetItem(state, toCell.lineId).plannedFunds };

                if (from[0] && toCell.lineId === from[0].lineId) {
                    delete toBudgetItemFunds[MONTH_BY_COLUMN_NAMES[from[0].columnName]];
                }

                return !!values(toBudgetItemFunds).some((plan) => !!plan);
            })
            .map((toCell) => toCell.lineId);
    },
);

export const canMoveAcceptorLineToDonor = createCashedSelector(
    (state: StoreState) => state,
    getBudgetTransferMenuState,
    (state: StoreState, lineId: string): string => lineId,
    (state: StoreState, transitionData: BudgetTransferMenuState, lineId: string): boolean => {
        const internalTransferDirection = transitionData.controls.internalTransferDirection;
        const toCell = transitionData.cells.to.find((toCell) => toCell.lineId === lineId);

        const checkCell = toCell && internalTransferDirection === InternalTransferDirection.OneToMany;
        if (checkCell) {
            const toBudgetItem = getTransitionBudgetItem(state, toCell.lineId);

            return !!values(toBudgetItem.plannedFunds).some((plan) => !!plan);
        }

        return true;
    },
)((state: StoreState, lineId: string): string => lineId);
