import { ComponentType } from 'react';
import { connect } from 'react-redux';

import { StoreState } from '@store';
import { getUploadingFilesData, hasUploadingFiles } from '@store/fileAssets';
import { UploadingFilesAtTitleContainer, UploadingFilesAtTitleContainerProps } from './UploadingFilesAtTitleContainer';

const mapStateToProps = (state: StoreState): UploadingFilesAtTitleContainerProps => ({
    ...getUploadingFilesData(state),
    hasUploadingFiles: hasUploadingFiles(state),
});

const withStore = connect(mapStateToProps);

const UploadingFilesAtTitleStore = withStore(UploadingFilesAtTitleContainer) as ComponentType;
UploadingFilesAtTitleStore.displayName = 'UploadingFilesAtTitle.Store';

export { UploadingFilesAtTitleStore };
