import * as React from 'react';
import autobind from 'autobind-decorator';

import { CommentEditDropdown } from './CommentEditDropdown';

interface Props {
    className?: string;
    hideItem?: 'edit' | 'remove';
    onEditClick?(): any;
    onRemoveClick?(): any;
}

interface State {
    isExpanded: boolean;
}

export class CommentEditDropdownContainer extends React.Component<Props, State> {
    public state: State = {
        isExpanded: false,
    };

    public render(): JSX.Element {
        return React.createElement(CommentEditDropdown, {
            onExpandClick: this.onExpandClick,
            onEditClick: this.onEditClick,
            onRemoveClick: this.onRemoveClick,
            isExpanded: this.state.isExpanded,
            className: this.props.className,
            hideItem: this.props.hideItem,
        });
    }

    @autobind
    protected onExpandClick(): void {
        if (this.state.isExpanded) {
            this.collapse();
        } else {
            this.expand();
        }
    }

    @autobind
    protected onEditClick(): void {
        if (this.props.onEditClick) {
            this.props.onEditClick();
        }

        this.collapse();
    }

    @autobind
    protected onRemoveClick(): void {
        if (this.props.onRemoveClick) {
            this.props.onRemoveClick();
        }

        this.collapse();
    }

    protected expand(): void {
        this.setState({ isExpanded: true });
    }

    protected collapse(): void {
        this.setState({ isExpanded: false });
    }
}
