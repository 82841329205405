import { resetableReducerWithInitialState, stubNull, replaceAsyncFailureReducer } from '@store/common/utils';
import { TaskEditorStatus } from '../types';
import { loadTask, loadActivity, saveTask, resetTaskEditor, setPreloaderStatus } from '../actions';
import { resetStore } from '@store/common';

const stubNotExists = () => TaskEditorStatus.NOT_EXISTS;

export const showPreloader = resetableReducerWithInitialState<boolean>(false, resetStore, resetTaskEditor).case(
    setPreloaderStatus,
    (state, showPreloader) => showPreloader,
);

export const editorStatus = resetableReducerWithInitialState<TaskEditorStatus>(
    stubNotExists,
    resetStore,
    resetTaskEditor,
)
    .case(saveTask.started, (prev) =>
        prev === TaskEditorStatus.NOT_EXISTS || prev === TaskEditorStatus.CREATION_ERROR
            ? TaskEditorStatus.CREATING
            : TaskEditorStatus.UPDATING,
    )
    .case(saveTask.failed, (prev) =>
        prev === TaskEditorStatus.CREATING ? TaskEditorStatus.CREATION_ERROR : TaskEditorStatus.UPDATE_ERROR,
    )
    .case(loadActivity.done, () => TaskEditorStatus.NOT_EXISTS)
    .cases([saveTask.done, loadTask.done], () => TaskEditorStatus.EXISTS)
    .cases([loadTask.started, loadActivity.started], () => TaskEditorStatus.LOADING)
    .cases([loadTask.failed, loadActivity.failed], () => TaskEditorStatus.LOADING_ERROR);

export const editorError = resetableReducerWithInitialState<Error | null>(
    stubNull,
    resetStore,
    resetTaskEditor,
    saveTask.done,
    loadTask.done,
    loadActivity.done,
    saveTask.started,
    loadTask.started,
    loadActivity.started,
).cases([loadTask.failed, saveTask.failed, loadActivity.failed], replaceAsyncFailureReducer);
