import { createSelector } from 'reselect';
import { Moment } from 'moment';
import { ActivityStatus } from 'sber-marketing-types/frontend';
import { identity, uniqBy } from 'lodash';

import { StoreState } from '../';
import { TaskEditor, TaskData, TaskEditorTechnicalData, TaskEditorStatus, ActivityData, FilesData } from './types';
import { getFileAssetsByNames, getFileAssetsByTargetId } from '../fileAssets';

export const getTaskEditor = (state: StoreState): TaskEditor => state.taskEditor;

export const getTaskData = createSelector(
    getTaskEditor,
    ({
        id,
        title,
        workTypeId,
        departmentId,
        deadline,
        authorId,
        executorId,
        description,
        participantsIds,
        availableDepartments,
        filesData,
        status,
        stageId,
    }): TaskData => ({
        id,
        title,
        workTypeId,
        departmentId,
        deadline,
        authorId,
        executorId,
        description,
        participantsIds,
        availableDepartments,
        filesData,
        status,
        stageId,
    }),
);

export const getActivityData = createSelector(
    getTaskEditor,
    ({ activityId, activityEndDate, activityStartDate, activityStatus }): ActivityData => ({
        activityId,
        activityEndDate,
        activityStartDate,
        activityStatus,
    }),
);

export const getTaskEditorTechnicalData = createSelector(
    getTaskEditor,
    ({ editorError, editorStatus, showPreloader }): TaskEditorTechnicalData => ({
        editorError,
        editorStatus,
        showPreloader,
    }),
);

export const getEditorError = createSelector(
    getTaskEditorTechnicalData,
    ({ editorError }): Error | null => editorError,
);

export const getEditorStatus = createSelector(
    getTaskEditorTechnicalData,
    ({ editorStatus }): TaskEditorStatus => editorStatus,
);

export const getId = createSelector(getTaskData, ({ id }): string | null => id);

export const getTitle = createSelector(getTaskData, ({ title }): string => title);

export const getWorkTypeId = createSelector(getTaskData, ({ workTypeId }): string | null => workTypeId);

export const getDepartmentId = createSelector(getTaskData, ({ departmentId }): string | null => departmentId);

export const getDeadline = createSelector(getTaskData, ({ deadline }): Moment => deadline);

export const getAuthorId = createSelector(getTaskData, ({ authorId }): number | null => authorId);

export const getExecutorId = createSelector(getTaskData, ({ executorId }): number | null => executorId);

export const getDescription = createSelector(getTaskData, ({ description }): string => description);

export const getParticipantsIds = createSelector(getTaskData, ({ participantsIds }): number[] => participantsIds);

export const getActivityId = createSelector(getActivityData, ({ activityId }): number | null => activityId);

export const getActivityStatus = createSelector(
    getActivityData,
    ({ activityStatus }): ActivityStatus => activityStatus,
);

export const getActivityStartDate = createSelector(
    getActivityData,
    ({ activityStartDate }): Moment => activityStartDate,
);

export const getActivityEndDate = createSelector(getActivityData, ({ activityEndDate }): Moment => activityEndDate);

export const getFilesData = createSelector(getTaskData, ({ filesData }): FilesData => filesData);

export const getFilesIds = createSelector(getFilesData, ({ filesIds }) => filesIds);

const getFilesByTaskId = createSelector([identity, getId], getFileAssetsByTargetId);

const getFilesBySavedNames = createSelector([identity, getFilesIds], getFileAssetsByNames);

export const getFiles = createSelector(getFilesBySavedNames, getFilesByTaskId, (saved, byTaskId) =>
    uniqBy([...saved, ...byTaskId], 'name'),
);

export const getFilesIdsToRemove = createSelector(getFilesData, ({ filesIdsToRemove }) => filesIdsToRemove);

export const getFilesToRemove = createSelector([identity, getFilesIdsToRemove], getFileAssetsByNames);
