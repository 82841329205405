import { CellPosition } from '../../types';

import * as React from 'react';

interface Props {
    position: CellPosition;
    getCellParams: (
        position: CellPosition,
        onCellParamsUpdateHandler: (cellParams: { component: React.ClassType<any, any, any>; cellProps: any }) => void,
    ) => {
        component: React.ClassType<any, any, any>;
        cellProps: any;
    };
}

interface State {
    component: React.ClassType<any, any, any>;
    cellProps: any;
}

export const Cell: React.FC<Props> = ({ position, getCellParams }: Props) => {
    const [cellParams, setCellParams] = React.useState({
        component: null,
        cellProps: null,
    } as State);

    React.useEffect(() => {
        const params = getCellParams(position, onCellParamsUpdate);

        setCellParams(params);
    }, []);

    const onCellParamsUpdate = React.useCallback(
        (newCellParams: { component: React.ClassType<any, any, any>; cellProps: any }) => {
            setCellParams(newCellParams);
        },
        [],
    );

    return cellParams.component && cellParams.cellProps
        ? React.createElement(cellParams.component, cellParams.cellProps)
        : null;
};
