export enum LineModalTabs {
    GeneralInfo = 'GeneralInfo',
    BudgetItemSnapshotStory = 'BudgetItemSnapshotStory',
}

export interface LineModalState {
    show: boolean;
    lineId?: string;
    selectedTab: LineModalTabs;
}

export interface ShowLineModalParams {
    lineId: string;
    selectedTab?: LineModalTabs;
}
