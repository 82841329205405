import { Failure } from 'typescript-fsa';

import { TaskPageState, SwitchKeyActivityParams } from '../types';

export const switchKeyActivityStartedReducer = (
    { activity, ...restState }: TaskPageState,
    params: SwitchKeyActivityParams,
): TaskPageState => ({
    ...restState,
    activity: {
        ...activity,
        isKey: params.isKey,
    },
});

export const switchKeyActivityFailedReducer = (
    { activity, ...restState }: TaskPageState,
    payload: Failure<SwitchKeyActivityParams, Error>,
): TaskPageState => ({
    ...restState,
    activity: {
        ...activity,
        isKey: !payload.params.isKey,
    },
});
