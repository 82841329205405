// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-Sidebar-_CommonStyles__horizontalBar{width:100%;min-height:1px;background:#E3E9E5}.src-client-modules-activity-pages-creative-Sidebar-_CommonStyles__sidePaddings{padding-left:24px;padding-right:24px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/Sidebar/CommonStyles.scss"],"names":[],"mappings":"AAAA,iFACI,UAAW,CACX,cAAe,CAEf,kBAAmB,CACtB,gFAGG,iBAAkB,CAClB,kBAAmB","sourcesContent":[".horizontalBar {\n    width: 100%;\n    min-height: 1px;\n\n    background: #E3E9E5;\n}\n\n.sidePaddings {\n    padding-left: 24px;\n    padding-right: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"horizontalBar": "src-client-modules-activity-pages-creative-Sidebar-_CommonStyles__horizontalBar",
	"sidePaddings": "src-client-modules-activity-pages-creative-Sidebar-_CommonStyles__sidePaddings"
};
export default ___CSS_LOADER_EXPORT___;
