import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { BudgetItemApi } from '@api';

import { StoreState } from '@store';
import * as asyncActions from './actions/async';
import type { InitParams, InitResult } from './types';

export const init = bindThunkAction<StoreState, InitParams, InitResult, Error>(
    asyncActions.init,
    async ({ budgetId, filters }) => {
        const budgetItems = await BudgetItemApi.getBudgetItemList({
            budgetId,
            filter: filters
                ? {
                      dictionary: {
                          division: {
                              id: filters.divisionIds,
                          },
                      },
                  }
                : null,
        });

        return {
            budgetItems: budgetItems || [],
        };
    },
);
