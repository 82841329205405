import * as React from 'react';

import { useAddTaskFileMutation } from '@api';

import { Button, ButtonProps } from '@common/components';

import { useAddFiles } from '@modules/files/hooks';

export const DEFAULT_PREVIEW_WIDTH = 160;

import * as styles from './AddTaskFileButton.scss';

export interface AddTaskFileButtonProps extends Omit<ButtonProps<'label'>, 'type'> {
    taskId: string;
}

export function AddTaskFileButton({ taskId, loading, children, ...props }: AddTaskFileButtonProps) {
    const [addTaskFile, { isLoading: isAddTaskFileLoading }] = useAddTaskFileMutation();
    const [addFiles, { isAddFileLoading, progress }] = useAddFiles({ taskId });
    const isLoading = loading || isAddFileLoading || isAddTaskFileLoading;

    const handleInput: React.FormEventHandler<HTMLInputElement> = async (e) => {
        // @ts-ignore
        const files: FileList = e.target.files;
        const results = await addFiles(Array.from(files));

        for (const { name, size, originName, type, storage } of results) {
            await addTaskFile({
                taskId,
                name,
                id: name,
                size,
                originName,
                type,
                storage,
            });
        }
    };

    return (
        <Button
            data-qa-id="AddTaskFileButton"
            {...props}
            loading={isLoading}
            data-skeleton-content={progress ? `${progress | 0}%` : undefined}
            element="label"
        >
            <input className={styles.input} multiple onInput={handleInput} type="file" />
            {children}
        </Button>
    );
}
