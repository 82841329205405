// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-CreativeStatusesStageWidget-_CreativeStatusesStageWidget__root{position:relative;width:100%;overflow:hidden;border-radius:6px}.src-client-modules-activity-pages-autopilot-StageWidgets-CreativeStatusesStageWidget-_CreativeStatusesStageWidget__title{color:#092a3a;font-family:'Open Sans';font-weight:600;font-size:16px;line-height:22px;user-select:none}.src-client-modules-activity-pages-autopilot-StageWidgets-CreativeStatusesStageWidget-_CreativeStatusesStageWidget__table{margin-top:8px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/CreativeStatusesStageWidget/CreativeStatusesStageWidget.scss"],"names":[],"mappings":"AAAA,yHACI,iBAAkB,CAClB,UAAW,CACX,eAAgB,CAChB,iBAAkB,CACrB,0HAGG,aAAc,CACd,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,gBAAiB,CACpB,0HAGG,cAAe","sourcesContent":[".root {\n    position: relative;\n    width: 100%;\n    overflow: hidden;\n    border-radius: 6px;\n}\n\n.title {\n    color: #092a3a;\n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 22px;\n    user-select: none;\n}\n\n.table {\n    margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-CreativeStatusesStageWidget-_CreativeStatusesStageWidget__root",
	"title": "src-client-modules-activity-pages-autopilot-StageWidgets-CreativeStatusesStageWidget-_CreativeStatusesStageWidget__title",
	"table": "src-client-modules-activity-pages-autopilot-StageWidgets-CreativeStatusesStageWidget-_CreativeStatusesStageWidget__table"
};
export default ___CSS_LOADER_EXPORT___;
