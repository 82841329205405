import * as React from 'react';
import classnames from 'classnames';
import { times } from 'lodash';
import { FileResponse } from 'sber-marketing-types/frontend';
import { WithTooltip, TooltipAnchor, StaticSkeleton } from 'sber-marketing-ui';

import { FileApiUploadParams } from '@api';

import { Utils, DatesFormatter } from '@common/Utils';
import { FileAsset } from '@common/FileAssetRedesign';
import { MediaFilesGallery } from '@common/MediaFilesGallery';
import { useTextOverflowCheck } from '@common/hooks';

import * as styles from './FileCardsList.scss';

export enum Theme {
    Regular = 'Regular',
    LargeWithBorders = 'LargeWithBorders',
}

interface MediaGalleryState {
    isVisible: boolean;
    index: number;
}

interface Props {
    files: FileResponse[];
    theme?: Theme;
    displayCount?: number;
    tooltipAnchor?: TooltipAnchor;
    useR7Controls?: boolean;
    canEditInR7?: boolean | ((file: FileResponse) => boolean);
    getFileDownloadParams: (file: FileResponse) => FileApiUploadParams;
}

function useTheme(theme: Theme) {
    switch (theme) {
        case Theme.LargeWithBorders:
            return styles.rootLargeWithBordersTheme;
        case Theme.Regular:
        default:
            return null;
    }
}

function makeDefaultMediaGalleryState(): MediaGalleryState {
    return {
        isVisible: false,
        index: null,
    };
}

export function FileCardsList({
    files,
    theme,
    displayCount,
    tooltipAnchor,
    useR7Controls,
    canEditInR7,
    getFileDownloadParams,
}: Props): JSX.Element {
    const displayCountToUse = displayCount || files.length;

    const [mediaGalleryState, setMediaGalleryState] = React.useState<MediaGalleryState>(makeDefaultMediaGalleryState());

    const nameRef = React.useRef<HTMLDivElement>();
    const { horizontal: nameOverflow } = useTextOverflowCheck(nameRef);

    function onGalleryButtonClick(fileId: string) {
        const index = mediaGalleryItems.findIndex((file) => file.id === fileId);

        setMediaGalleryState({
            isVisible: true,
            index,
        });
    }

    function closeMediaGallery() {
        setMediaGalleryState(makeDefaultMediaGalleryState());
    }

    const themeClass = useTheme(theme);

    const mediaGalleryItems = React.useMemo(
        () => Utils.mapFilesToMediaGalleryItems(files, getFileDownloadParams),
        [files],
    );

    return (
        <React.Fragment>
            <div
                className={classnames(styles.root, themeClass)}
                {...{
                    'qa-id': 'fileCardsList',
                }}
            >
                {times(displayCountToUse).map((i) => {
                    const file = files[i];
                    const canEditInR7Value = typeof canEditInR7 === 'function' ? canEditInR7(file) : canEditInR7;

                    return (
                        <div
                            key={file.id}
                            className={styles.card}
                            {...{
                                'qa-id': 'fileCarsListFileCard',
                            }}
                        >
                            <div className={styles.extension}>
                                <FileAsset
                                    {...file}
                                    {...getFileDownloadParams(file)}
                                    useMediaControls
                                    showDownloadButton
                                    useR7Controls={useR7Controls}
                                    canEditInR7={canEditInR7Value}
                                    onGalleryButtonClick={() => onGalleryButtonClick(file.id)}
                                />
                            </div>

                            <div className={styles.infoWrapper}>
                                <WithTooltip anchor={tooltipAnchor} hidden={!nameOverflow} content={file.originName}>
                                    <div
                                        ref={nameRef}
                                        className={styles.fileName}
                                        {...{
                                            'qa-id': 'fileCarsListFileCardTitle',
                                        }}
                                    >
                                        {file.originName}
                                    </div>
                                </WithTooltip>

                                <Info file={file} theme={theme} tooltipAnchor={tooltipAnchor} />
                            </div>
                        </div>
                    );
                })}
            </div>

            {mediaGalleryState.isVisible && (
                <MediaFilesGallery
                    startIndex={mediaGalleryState.index}
                    items={mediaGalleryItems}
                    onOutsideClick={closeMediaGallery}
                />
            )}
        </React.Fragment>
    );
}

interface InfoProps {
    file: FileResponse;
    theme: Theme;
    tooltipAnchor: TooltipAnchor;
}

function Info({ file, theme, tooltipAnchor }: InfoProps): JSX.Element {
    const infoRef = React.useRef<HTMLDivElement>();
    const { horizontal: infoOverflow } = useTextOverflowCheck(infoRef);

    let content: string;
    const size = Utils.formatFileSize(file.size);
    switch (theme) {
        case Theme.LargeWithBorders:
            content = `${size} • ${DatesFormatter.ddMonthyy(file.createTime)}`;
            break;
        case Theme.Regular:
        default:
            content = size;
            break;
    }

    return (
        <WithTooltip anchor={tooltipAnchor} hidden={!infoOverflow} content={content}>
            <div
                ref={infoRef}
                className={styles.fileInfo}
                {...{
                    'qa-id': 'fileCarsListFileCardInfo',
                }}
            >
                {content}
            </div>
        </WithTooltip>
    );
}

interface PreloaderProps {
    cardsCount?: number;
    theme?: Theme;
}

export function FileCardsListPreloader({ cardsCount = 3, theme }: PreloaderProps): JSX.Element {
    const themeClass = useTheme(theme);

    return (
        <div className={classnames(styles.root, themeClass)}>
            {times(cardsCount, (i) => (
                <FilePreloader key={i} />
            ))}
        </div>
    );
}

function FilePreloader(): JSX.Element {
    return (
        <div className={styles.card}>
            <StaticSkeleton loadingClassName={styles.extension} />

            <div className={styles.infoWrapper}>
                <StaticSkeleton loadingClassName={styles.fileNamePreloader} />
                <StaticSkeleton loadingClassName={styles.fileInfoPreloader} />
            </div>
        </div>
    );
}
