import * as React from 'react';
import classnames from 'classnames';
import { Icon, IconType, WithTooltip } from 'sber-marketing-ui';

import * as styles from './ApprovementButtons.scss';
import * as commonStyles from '../IndicatorsCell.scss';

interface Props {
    disableApproveButton?: boolean;
    disableRejectButton?: boolean;
    approveButtonTooltip: string;
    rejectButtonTooltip: string;
    isApproveButtonActive: boolean;
    isRejectButtonActive: boolean;
    onRejectButtonClick: () => void;
    onApproveButtonClick: () => void;
}

export function ApprovementButtons({
    disableApproveButton,
    disableRejectButton,
    approveButtonTooltip,
    rejectButtonTooltip,
    isApproveButtonActive,
    isRejectButtonActive,
    onApproveButtonClick,
    onRejectButtonClick,
}: Props): JSX.Element {
    return (
        <React.Fragment>
            <StatusButton
                qaId="approvementButtonRejectButton"
                disabled={disableRejectButton}
                tooltipText={rejectButtonTooltip}
                isActive={isRejectButtonActive}
                icon={IconType.REJECT_ICON_ROUND}
                activeIcon={IconType.REJECT_ICON_ROUND_ACTIVE}
                activeClassName={styles.rejectButton}
                onClick={onRejectButtonClick}
            />

            <div className={commonStyles.buttonsMargin}>
                <StatusButton
                    qaId="approvementButtonApproveButton"
                    disabled={disableApproveButton}
                    tooltipText={approveButtonTooltip}
                    isActive={isApproveButtonActive}
                    icon={IconType.ACCEPT_ICON_ROUND}
                    activeIcon={IconType.ACCEPT_ICON_ROUND_ACTIVE}
                    activeClassName={styles.approveButton}
                    onClick={onApproveButtonClick}
                />
            </div>
        </React.Fragment>
    );
}

interface StatusButtonProps {
    disabled: boolean;
    isActive: boolean;
    activeClassName: string;
    icon: IconType;
    activeIcon: IconType;
    tooltipText: string;
    qaId: string;
    onClick: () => void;
}

function StatusButton({
    qaId,
    disabled,
    isActive,
    activeClassName,
    icon,
    activeIcon,
    tooltipText,
    onClick,
}: StatusButtonProps): JSX.Element {
    const [isHovered, setIsHovered] = React.useState(false);
    const activeDesign = !disabled && (isActive || isHovered);

    let className: string;
    if (disabled) {
        className = styles.buttonDisabled;
    } else if (activeDesign) {
        className = classnames(!isActive && styles.buttonClickable, activeClassName);
    } else {
        className = styles.buttonNonColored;
    }

    return (
        <WithTooltip hidden={disabled || !activeDesign} content={tooltipText}>
            <div className={className} {...{ 'qa-id': qaId }}>
                <Icon
                    type={activeDesign ? activeIcon : icon}
                    svgSize={20}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={!isActive ? onClick : null}
                />
            </div>
        </WithTooltip>
    );
}
