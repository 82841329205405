import * as React from 'react';
import classNames from 'classnames';

import * as styles from './PersonData.scss';

/** Person data themes */
export enum PersonDataTheme {
    DEFAULT,
    THIN_DEPARTMENT,
    TRANSLUCENT,
}

/** Person data properties */
export interface PersonDataProps {
    /** Person name */
    name?: string;
    /** Person department */
    department?: React.ReactNode;
    /** Additional CSS class */
    className?: string;
    /** Click event handler */
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    /** Theme */
    theme?: PersonDataTheme;
    /** Is multiline department */
    multilineDepartment?: boolean;
}

const themeClassDictionary = new Map([
    [PersonDataTheme.THIN_DEPARTMENT, styles.root_thinDepartment],
    [PersonDataTheme.TRANSLUCENT, styles.root_translucent],
    [PersonDataTheme.DEFAULT, styles.root_default],
]);

const rootClassNames = (theme: PersonDataTheme, multilineDepartment: boolean, className?: string): string =>
    classNames(
        styles.root,
        themeClassDictionary.get(theme),
        multilineDepartment && styles.root_multilineDepartment,
        className,
    );

/** Person data - small common component with person name and department */
export const PersonData: React.SFC<PersonDataProps> = ({
    className,
    onClick,
    multilineDepartment,
    theme = PersonDataTheme.DEFAULT,
    name = '',
    department = '',
}: PersonDataProps): JSX.Element => (
    <div className={rootClassNames(theme, multilineDepartment, className)} onClick={onClick}>
        <div className={styles.name}>{name}</div>

        <div className={styles.department}>{department}</div>
    </div>
);
