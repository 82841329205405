import { axios } from '../lib/axios';

import { NewsView, NewsQueryParams } from 'sber-marketing-types/frontend';

const BASE_PATH = '/api/news';

export class NewsApi {
    public static async getNews(params?: NewsQueryParams): Promise<NewsView[]> {
        const res = await axios.get(`${BASE_PATH}`, { params });

        return res.data;
    }
}
