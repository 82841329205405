import * as React from 'react';
import autobind from 'autobind-decorator';

import { CellValueType, ColumnName, CustomColumnColor } from '@store/budgetPlanning/types';

import { CellBackgroundColor } from '../../LayerManager';

import { InputCell } from './InputCell';

interface Props {
    lineId: string;
    originalValue: string;
    columnName: ColumnName;
    title: string;
    tooltip?: string;
    value: string;
    valueType: CellValueType;
    bgColor?: CellBackgroundColor | CustomColumnColor;
    disabled?: boolean;
    cellInfo?: string | JSX.Element;
    onValueChange?: (lineId: string, columnName: ColumnName, value: string, originalValue: string) => void;
    onCellCopy: (lineId: string, columnName: ColumnName, event: React.ClipboardEvent<HTMLDivElement>) => void;
}

interface State {
    editMode: boolean;
}

export class InputCellContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            editMode: false,
        };
    }

    public focus(): void {
        this.onRootClick();
    }

    public render(): JSX.Element {
        return React.createElement(InputCell, {
            columnName: this.props.columnName,
            title: this.props.title,
            tooltip: this.props.tooltip,
            value: this.props.value,
            valueType: this.props.valueType,
            editMode: this.state.editMode,
            bgColor: this.props.bgColor,
            disabled: this.props.disabled,
            cellInfo: this.props.cellInfo,
            onRootClick: this.onRootClick,
            onInputBlur: this.onInputBlur,
            onValueChange: this.onValueChange,
            onCellCopy: this.onCellCopy,
        });
    }

    @autobind
    protected onRootClick() {
        this.setState(() => ({
            editMode: true,
        }));
    }

    @autobind
    protected onInputBlur() {
        this.setState(() => ({
            editMode: false,
        }));

        const value = this.removeTrailingZeros(this.props.value);

        this.onValueChange(value?.trim());
    }

    private removeTrailingZeros(value: string): string {
        if (!value) {
            return value;
        }
        const trailingZerosRegex = /^((([1-9][0-9]*?)|0)\.([0-9]*[1-9]+)?)0+$/;
        const trailingDotRegex = /\.$/;

        return value.replace(trailingZerosRegex, '$1').replace(trailingDotRegex, '');
    }

    @autobind
    private onValueChange(value: string): void {
        const { lineId, columnName, originalValue } = this.props;

        this.props.onValueChange(lineId, columnName, value, originalValue);
    }

    @autobind
    private onCellCopy(event: React.ClipboardEvent<HTMLDivElement>) {
        this.props.onCellCopy(this.props.lineId, this.props.columnName, event);
    }
}
