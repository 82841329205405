import * as React from 'react';
import { uniq, compact } from 'lodash';
import { useSelector } from 'react-redux';
import { RoleId } from 'sber-marketing-types/frontend';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';

import { UserItem, UsersTab } from '@common/SidebarWithTabs/common/UsersTab';
import { makeExcludeUsersStrategy } from '@common/UsersDropdown';

import { ActivityContent } from '../../types';

interface Props {
    activityContent: ActivityContent;
    activityContentIsLoading: boolean;
    activitySharingInProgress: boolean;
    updateParticipants: (participantIds: number[]) => void;
}

function useParticipantsButtonVisibility({ activityContent }: Props) {
    const loginUser = useSelector((state: StoreState) => getLoginUser(state).attributes);

    const userIsAuthor = activityContent?.author?.id === loginUser.id;
    const userIsResponsible = activityContent?.responsible?.id === loginUser.id;

    const userIsDirector = loginUser.roles?.some((role) => role.id === RoleId.Director);
    const userIsInSameOrganization = loginUser.organizationId === activityContent?.product?.organizationId;

    const showAddParticipantsButton = userIsAuthor || userIsResponsible || (userIsDirector && userIsInSameOrganization);

    return showAddParticipantsButton;
}

function useUsersDropdownStrategy({ activityContent }: Props) {
    const usersDropdownStrategy = React.useMemo(() => {
        const authorAndResponsible = compact([activityContent?.author?.id, activityContent?.responsible?.id]);

        return makeExcludeUsersStrategy(authorAndResponsible);
    }, [activityContent?.author, activityContent?.responsible]);

    return usersDropdownStrategy;
}

function useUserItems({ activityContent }: Props) {
    const userItems: UserItem[] = React.useMemo(() => {
        const allUsers = compact(
            uniq(
                [activityContent?.author, activityContent?.responsible, ...(activityContent?.participants || [])].map(
                    (user) => user?.id,
                ),
            ),
        );

        return allUsers.map((userId) => {
            const roles: string[] = [];
            if (userId === activityContent?.author?.id) {
                roles.push('Автор');
            }
            if (userId === activityContent?.responsible.id) {
                roles.push('Ответственный');
            }

            return {
                id: userId,
                role: roles.join(', '),
                order: roles.length ? 1 : 0,
            };
        });
    }, [activityContent]);

    return userItems;
}

function useParticipantsTab(props: Props) {
    const showAddParticipantsButton = useParticipantsButtonVisibility(props);
    const usersDropdownStrategy = useUsersDropdownStrategy(props);
    const userItems = useUserItems(props);

    return {
        showAddParticipantsButton,
        usersDropdownStrategy,
        userItems,
    };
}

export function ParticipantsTab(props: Props): JSX.Element {
    const { showAddParticipantsButton, usersDropdownStrategy, userItems } = useParticipantsTab(props);
    const { activityContent, activityContentIsLoading, activitySharingInProgress, updateParticipants } = props;

    const showPreloader = !activityContent || activitySharingInProgress || activityContentIsLoading;

    return (
        <UsersTab
            qaId="activitySidebarParticipantsTab"
            showPreloader={showPreloader}
            items={userItems}
            showAddUsersButton={showAddParticipantsButton}
            usersToUseStrategy={usersDropdownStrategy}
            updateUsers={updateParticipants}
        />
    );
}
