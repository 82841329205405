// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-CopyableValue-_CopyableValue__root{display:inline-block;font-family:'Open Sans';font-size:12px;line-height:16px;letter-spacing:0.15px;color:#417EC6;cursor:pointer}.src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-CopyableValue-_CopyableValue__root:hover{color:#0058BF}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/BudgetTransferMenu/InternalTransitionSumEntering/CopyableValue/CopyableValue.scss"],"names":[],"mappings":"AAAA,0IACI,oBAAqB,CAErB,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACjB,qBAAsB,CAEtB,aAAc,CACd,cAAe,CATnB,gJAYQ,aAAc","sourcesContent":[".root {\n    display: inline-block;\n\n    font-family: 'Open Sans';\n    font-size: 12px;\n    line-height: 16px;\n    letter-spacing: 0.15px;\n\n    color: #417EC6;\n    cursor: pointer;\n\n    &:hover {\n        color: #0058BF;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-BudgetTransferMenu-InternalTransitionSumEntering-CopyableValue-_CopyableValue__root"
};
export default ___CSS_LOADER_EXPORT___;
