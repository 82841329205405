import * as React from 'react';

import { ActivitiesList } from '@common/ActivitiesList';
import { ActivitiesListType } from '@store/activitiesList';
import { MyActivitiesSortingMenu } from '@common/DashboardSortingMenu';

import * as style from './MyProjectsPage.scss';

export interface Props {
    activityIdWithSidebar: number;
    openActivitySidebar: (activityId: number) => void;
}

export function MyProjectsPage({ activityIdWithSidebar, openActivitySidebar }: Props): JSX.Element {
    return (
        <div className={style.root}>
            <div className={style.sortingMenu}>
                <MyActivitiesSortingMenu />
            </div>

            <div className={style.activityListWrapper}>
                <ActivitiesList
                    key={ActivitiesListType.MY_ACTIVITIES}
                    type={ActivitiesListType.MY_ACTIVITIES}
                    activityIdWithSidebar={activityIdWithSidebar}
                    openActivitySidebar={openActivitySidebar}
                />
            </div>
        </div>
    );
}
