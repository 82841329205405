import * as React from 'react';
import classNames from 'classnames';

import * as styles from './StatusBar.scss';

export enum Color {
    Grey = 'Grey',
    Green = 'Green',
    Red = 'Red',
}

interface Props {
    color: Color;
    text: string;
}

export function StatusBar({ color, text }: Props): JSX.Element {
    let colorClass: string;
    switch (color) {
        case Color.Green:
            colorClass = styles.rootGreen;
            break;
        case Color.Red:
            colorClass = styles.rootRed;
            break;
        case Color.Grey:
        default:
            colorClass = styles.rootGrey;
            break;
    }

    return (
        <div
            className={classNames(styles.root, colorClass)}
            {...{
                'qa-id': 'statusBar',
                'qa-value': text,
            }}
        >
            {text}
        </div>
    );
}
