import * as React from 'react';
import * as lodash from 'lodash';
import { compose, graphql } from 'react-apollo';
import autobind from 'autobind-decorator';

import * as queries from './graphqlQueries';

interface Activity {
    id: number;
    name: string;
    brief: {
        schema: {
            name: string;
        };
    };
    workTypes: {
        name: string;
    }[];
    product: {
        name: string;
    };
    budgetItems: {
        serialNumber: number;
    }[];
}

interface Props extends QueryProps, ExternalProps {
    children?: (params: RenderParams) => React.ReactNode | React.ReactNode[];
}

type QueryProps = {
    getActivityQuery: {
        loading: boolean;
        activities: {
            nodes: Activity[];
        };
        refetch: (params: {
            cardsFilter: {
                id: React.ReactText;
            };
        }) => Promise<void>;
    };
    getRequestQuery: {
        loading: boolean;
        request: {
            nodes: {
                project: {
                    name: string;
                };
            }[];
        };
    };
};

interface ExternalProps {
    activityId: React.ReactText;
    requestId: string;
    children?: (params: RenderParams) => React.ReactNode | React.ReactNode[];
}

interface RenderParams {
    activity: Activity;
    requestActivityName: string;
    loading: boolean;
    loadActivity: (activityId: React.ReactText) => void;
}

class ActivityQuery extends React.Component<Props> {
    public render() {
        return this.props.children(this.getChildrenProps());
    }

    private getChildrenProps(): RenderParams {
        const { getActivityQuery, getRequestQuery } = this.props;

        const activity = lodash.get(getActivityQuery, 'activities.nodes[0]') || null;
        const loading = getActivityQuery ? getActivityQuery.loading : true;
        const requestActivityName = lodash.get(getRequestQuery, 'request.nodes[0].project.name') || null;

        return {
            activity,
            loading,
            requestActivityName,
            loadActivity: this.loadActivity,
        };
    }

    @autobind
    private loadActivity(activityId: React.ReactText): void {
        this.props.getActivityQuery.refetch({
            cardsFilter: {
                id: activityId,
            },
        });
    }
}

export const WithActivityQuery = compose(
    graphql<ExternalProps>(queries.GET_ACTIVITY_QUERY, {
        name: 'getActivityQuery',
        options: ({ activityId }) => ({
            variables: {
                cardsFilter: {
                    id: activityId,
                },
            },
            fetchPolicy: 'no-cache',
        }),
        skip: ({ activityId }) => !activityId,
    }),
    graphql<ExternalProps>(queries.GET_REQUEST_QUERY, {
        name: 'getRequestQuery',
        options: ({ requestId }) => ({
            variables: {
                requestId,
            },
        }),
        skip: ({ requestId }) => !requestId,
    }),
)(ActivityQuery) as React.ComponentType<ExternalProps>;
