// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-StageWidgets-TableWidget-SummaryAmountWidget-_styles__root{display:grid;grid-template-rows:repeat(2, max-content);grid-row-gap:16px}.src-client-modules-activity-pages-creative-StageWidgets-TableWidget-SummaryAmountWidget-_styles__title{font-family:'Open Sans';font-weight:600;font-size:18px;line-height:25px;color:#092A3A}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/StageWidgets/TableWidget/SummaryAmountWidget/styles.scss"],"names":[],"mappings":"AAAA,uGACI,YAAa,CACb,yCAA0C,CAC1C,iBAAkB,CACrB,wGAGG,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,aAAc","sourcesContent":[".root {\n    display: grid;\n    grid-template-rows: repeat(2, max-content);\n    grid-row-gap: 16px;\n}\n\n.title {\n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 18px;\n    line-height: 25px;\n    color: #092A3A;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-StageWidgets-TableWidget-SummaryAmountWidget-_styles__root",
	"title": "src-client-modules-activity-pages-creative-StageWidgets-TableWidget-SummaryAmountWidget-_styles__title"
};
export default ___CSS_LOADER_EXPORT___;
