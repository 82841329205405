import * as React from 'react';
import classNames from 'classnames';

import { Label } from '../../layout';

import * as styles from './Field.scss';

export interface FieldProps extends React.HTMLProps<HTMLLabelElement & HTMLDivElement> {
    required?: boolean;
    label?: string;
    wide?: boolean;
    vertical?: boolean;
    loading?: boolean;
    clickable?: boolean;
    relative?: boolean;
}

export const Field: React.FC<FieldProps> = ({
    required,
    label,
    wide,
    clickable,
    loading,
    relative,
    vertical,
    children,
    className,
    ...props
}) => {
    const rootClassName = classNames(
        styles.root,
        {
            [styles.loading]: loading,
            [styles.required]: required,
            [styles.relative]: relative,
            [styles.vertical]: vertical,
        },
        className,
    );

    const Element = clickable ? 'label' : 'div';

    const content = !loading && (
        <>
            {label && (
                <Label padding={[6, 0]} className={styles.label}>
                    {label}
                </Label>
            )}
            {children}
        </>
    );

    return (
        <Element {...props} className={rootClassName}>
            {content}
        </Element>
    );
};
