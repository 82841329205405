/* tslint:disable:max-file-line-count */
import * as lodash from 'lodash';
import { bindActionCreators } from 'redux';
import * as queryString from 'query-string';
import { Tag } from '@mrm/tags';
import { ActivityBudget, BudgetItem, BudgetItemSnapshot, BudgetStatus, BudgetItemStatus, Budget } from '@mrm/budget';
import { PlainDictionary, DictionaryType } from '@mrm/dictionary';
import { UserResponseParams } from 'sber-marketing-types/frontend';
import { UserConfigType } from 'sber-marketing-types/openid';

import {
    ActivityBudgetApi,
    BudgetItemApi,
    DictionaryApi,
    UserApi,
    UserConfigApi,
    BudgetCorrectionApi,
    BudgetApi,
    MultiReferenceDictionaryApi,
} from '@api';

import { store } from '@store';
import { getTagsState } from '@store/tags';
import { User, getLoginUser } from '@store/user';
import { getBudgetState } from '@store/budgetPage';
import { saveBudgetByStatusUserConfig, getBudgetByStatusUserConfig } from '@store/userConfig/budget';
import {
    PageData,
    PlanningTableUserConfig,
    TableLine,
    NumericCellValue,
    CellValue,
    DateCellValue,
    CellValueType,
    Filters,
    ColumnsVisiblityFilter,
    ColumnName,
    TableLineGroup,
    SortingMode,
    CustomCellType,
    ColumnData,
    ColumnNameToFilterMap,
    GroupedDictionaries,
    loadPageData,
    setColumnsVisiblityFilter,
    setFixedColumnsNames,
    setSortingMode,
    setFilters,
    setColumnsWidth,
    initUnsavedChanges,
    setBudgetItemsToDisplay,
    setBudgetItemsApproversToDisplay,
    setDisplayDisabledLines,
    setDisplayOnlyUnapproved,
    setDisplayOnlyWithSnapshots,
    loadFilters,
    loadFiltersByColumn,
    getPageData,
    getTableLines,
    getDefaultFilters,
    getTableFilters,
    getBudgetPlanningPageState,
    getFilteredTableLines,
    getLinesGroupedByActivities,
    setPreviouslyLoadedFilters,
    ColumnFilters,
    SBER_ORGANIZATIONS_IDS,
    ColumnsNameWithCodes,
    setAppliedFiltersNames,
    DictionariesForXLSXTemplate,
    setShowOnlyLinesWithPlanBudget,
    setShowOnlyLinesWithoutPlanBudget,
    setMultiReferenceDictionaryApi,
    DropdownOptions,
    ChangeList,
    getDropdownsOptions,
    makeLine,
    getDropdownsOptionsByLineId,
} from '@store/budgetPlanning';
import { loadById as loadMiscBudgetItems } from '@store/budgetPlanning/miscBudgetItems';

import { ColumnsList } from '../ColumnsConfig';
import { LayerManager } from '../Table/LayerManager';

interface StoreProps {
    activities: ActivityBudget[];
    budgetId: string;
    budgetItems: BudgetItem[];
    budgetItemsToIgnoreFilters: BudgetItem[];
    budgetItemSnapshots: lodash.Dictionary<BudgetItemSnapshot[]>;
    lines: TableLine[];
    defaultFilters: Filters;
    columnsVisiblityFilter: ColumnsVisiblityFilter;
    fixedColumnsNames: ColumnName[];
    sortingMode: SortingMode;
    filteredLines: TableLine[];
    lineGroups: TableLineGroup[];
    filters: Filters;
    selectedBudget: Budget;
    allUsers: UserResponseParams[];
    groupedDictionaries: GroupedDictionaries;
    unsavedChanges: ChangeList;
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi;
    loginUser: User;
    tags: lodash.Dictionary<Tag>;
    dropdownOptions: DropdownOptions;
    user: User;
}

export class TableLoader {
    private static instance: TableLoader;

    private dispatch = bindActionCreators(
        {
            loadPageData,
            setColumnsVisiblityFilter,
            setFixedColumnsNames,
            setSortingMode,
            setFilters,
            setColumnsWidth,
            initUnsavedChanges,
            setBudgetItemsToDisplay,
            setBudgetItemsApproversToDisplay,
            setDisplayDisabledLines,
            setDisplayOnlyUnapproved,
            setDisplayOnlyWithSnapshots,
            loadFilters,
            setPreviouslyLoadedFilters,
            loadFiltersByColumn,
            saveBudgetByStatusUserConfig,
            setAppliedFiltersNames,
            loadMiscBudgetItems,
            setShowOnlyLinesWithPlanBudget,
            setShowOnlyLinesWithoutPlanBudget,
            setMultiReferenceDictionaryApi,
        },
        store.dispatch,
    );

    public static getInstance(): TableLoader {
        if (!TableLoader.instance) {
            TableLoader.instance = new TableLoader();
        }
        return TableLoader.instance;
    }

    public async init() {
        await this.initPageData(true);

        this.initFilters();

        await this.initFromUserConfig();
    }

    public async updateActivitiesByLineIds(lineIds: string[]) {
        const activityIds = lodash.uniq(lineIds.map((lineId) => this.getActivityByLineId(lineId).id));

        const [updatedActivities, updatedBudgetItems] = await Promise.all([
            Promise.all(activityIds.map((id) => ActivityBudgetApi.getActivityBudget(id))),
            Promise.all(lineIds.map((id) => BudgetItemApi.getBudgetItem(id))),
        ]);

        const { activities, budgetItems, budgetItemsToIgnoreFilters } = this.getStoreProps();

        const updatedActivityList = this.mergeActivityBudgets(activities, updatedActivities);
        const updatedBudgetItemList = this.mergeBudgetItems(budgetItems, updatedBudgetItems, lineIds);
        const updatedBudgetItemToIgnoreFiltersList = this.mergeBudgetItems(
            budgetItemsToIgnoreFilters,
            updatedBudgetItems,
            lineIds,
        );

        this.dispatch.loadPageData({
            activityBudgets: updatedActivityList,
            budgetItems: updatedBudgetItemList,
            budgetItemsToIgnoreFilters: updatedBudgetItemToIgnoreFiltersList,
        });

        this.loadBudgetItemSnapsnots(updatedBudgetItems);
    }

    public deleteLines(lineIds: string[]): void {
        const { budgetItems, budgetItemsToIgnoreFilters } = this.getStoreProps();

        const filteredBudgetItems = budgetItems.filter((budgetItem) => !lineIds.includes(budgetItem.id));
        const filteredBudgetItemsToIgnoreFitlers = budgetItemsToIgnoreFilters.filter(
            (budgetItem) => !lineIds.includes(budgetItem.id),
        );

        this.dispatch.loadPageData({
            budgetItems: filteredBudgetItems,
            budgetItemsToIgnoreFilters: filteredBudgetItemsToIgnoreFitlers,
        });

        // this.initFilters();
    }

    public async initPageData(loadRestData: boolean) {
        const pageData = {
            ...(await this.getBudgetRelatedPageData()),
            ...(loadRestData ? await this.getRestPageData() : {}),
        };

        const multiReferenceDictionaryApi = new MultiReferenceDictionaryApi();
        multiReferenceDictionaryApi.init(pageData.userDictionaries);
        this.dispatch.setMultiReferenceDictionaryApi(multiReferenceDictionaryApi);

        this.dispatch.loadPageData(pageData);
    }

    public async initFromUserConfig() {
        const userConfig = await this.getUserConfig();
        await this.applyFiltersFromUserConfig(userConfig);
    }

    public async getUserConfig(): Promise<PlanningTableUserConfig> {
        return (await UserConfigApi.getPageConfig(UserConfigType.BudgetPlanning)) as PlanningTableUserConfig;
    }

    public async initFromQuery(query: queryString.ParsedQuery) {
        if (query.budgetId) {
            this.dispatch.saveBudgetByStatusUserConfig({
                budgetStatus: BudgetStatus.Plan,
                payload: {
                    budgetId: query.budgetId as string,
                },
            });
        }

        const userConfig = (await UserConfigApi.getPageConfig(UserConfigType.BudgetPlanning)) as any;

        const queryBudgetId = query?.budgetId as string;

        if (queryBudgetId) {
            userConfig[queryBudgetId].selectedBudgetId = query.budgetId;
        }

        userConfig[queryBudgetId].filters = await this.parseFiltersFromQuery(query);
        userConfig[queryBudgetId].displayDisabledLines = true;

        if (userConfig[queryBudgetId].columnsVisiblityFilter) {
            const columnsVisibilityFilterColumns = Object.keys(userConfig[queryBudgetId].columnsVisiblityFilter);

            const allColumnsAreVisible =
                columnsVisibilityFilterColumns.every(
                    (column) => userConfig[queryBudgetId].columnsVisiblityFilter[column],
                ) ||
                columnsVisibilityFilterColumns.every(
                    (column) => !userConfig[queryBudgetId].columnsVisiblityFilter[column],
                );

            userConfig[queryBudgetId].columnsVisiblityFilter[query.column as string] = !allColumnsAreVisible;
        }

        await this.applyFiltersFromUserConfig(userConfig);
    }

    public async loadDataByFilters(userConfigFilters?: Filters): Promise<void> {
        const { selectedBudget, filters } = this.getStoreProps();

        const filtersToUse = userConfigFilters || filters;

        const appliedFilters = Object.keys(filtersToUse).reduce((acc, column) => {
            let selectedValues: any[] = Object.keys(filtersToUse[column])
                .filter((value) => !!filtersToUse[column][value])
                .map((item) => (item === 'null' ? null : item));

            if (selectedValues.length) {
                const res = { ...acc };

                let filterPropertyPath;

                if (column === ColumnName.Responsible) {
                    filterPropertyPath = 'responsibleIds';
                } else if (column === ColumnName.Author) {
                    filterPropertyPath = 'author.id';
                    selectedValues = selectedValues.map((item) => Number(item)).filter((item) => !!item);
                } else {
                    filterPropertyPath = `${ColumnNameToFilterMap[column]}${
                        ColumnNameToFilterMap[column].startsWith('dictionary') &&
                        !lodash.includes(ColumnsNameWithCodes, column)
                            ? '.id'
                            : ''
                    }`;
                }

                if (filterPropertyPath) {
                    // constructing filter object from selector
                    lodash.set(res, filterPropertyPath, selectedValues);
                }

                return res;
            }

            return acc;
        }, {});

        const budgetItems =
            !Object.keys(appliedFilters).length && selectedBudget
                ? []
                : await BudgetItemApi.getBudgetItemList({
                      budgetId: selectedBudget.id,
                      filter: appliedFilters,
                  });
        const activityBudgets = selectedBudget
            ? await ActivityBudgetApi.getActivityBudgetList({ budgetId: selectedBudget.id })
            : [];

        this.dispatch.loadPageData({
            activityBudgets,
            budgetItems,
        });

        this.loadBudgetItemSnapsnots(budgetItems);
    }

    public initFilters() {
        const { defaultFilters } = this.getStoreProps();

        this.dispatch.setFilters(defaultFilters);
    }

    public async convertTableToXLSX(useFilters: boolean): Promise<Buffer> {
        const storeProps = this.getStoreProps();
        const {
            budgetId,
            budgetItems,
            columnsVisiblityFilter,
            fixedColumnsNames,
            sortingMode,
            lineGroups,
            filteredLines,
            allUsers,
            activities,
            groupedDictionaries,
            unsavedChanges,
            multiReferenceDictionaryApi,
            loginUser,
            tags,
        } = storeProps;

        const hasVisibleColumns = Object.keys(columnsVisiblityFilter).some(
            (columnName) => columnsVisiblityFilter[columnName],
        );
        const everyColumnIsVisible = !Object.keys(columnsVisiblityFilter).every(
            (columnName) => columnsVisiblityFilter[columnName],
        );

        // generating array of columns in display order
        const visibleColumns: ColumnName[] = [
            ...fixedColumnsNames.filter((columnName) => everyColumnIsVisible || columnsVisiblityFilter[columnName]),
        ];

        for (const columnName in columnsVisiblityFilter) {
            if (hasVisibleColumns) {
                const shouldAddColumn =
                    lodash.includes(ColumnsNameWithCodes, columnName) ||
                    (columnsVisiblityFilter[columnName] && visibleColumns.indexOf(columnName as any) === -1);

                if (shouldAddColumn) {
                    visibleColumns.push(columnName as any);
                }
            } else {
                visibleColumns.push(columnName as any);
            }
        }
        // generating column data
        const columnsToUpload = visibleColumns.reduce((acc, column) => {
            const columnData = ColumnsList.find((c) => c.name === column);

            return {
                ...acc,
                [column]: columnData,
            };
        }, {});

        // generating lines to process
        let lines: TableLine[];
        let dropdownOptions: DropdownOptions;
        if (useFilters) {
            const linesAreDisplayedByGroup = sortingMode.columnName == ColumnName.ActivityName;

            lines = linesAreDisplayedByGroup
                ? lineGroups.reduce((acc, lineGroup) => [...acc, ...lineGroup.lines], [])
                : filteredLines;

            dropdownOptions = storeProps.dropdownOptions;
        } else {
            lines = (
                await BudgetItemApi.getBudgetItemList({ budgetId, sort: [{ field: 'serialNumber', order: 'ASC' }] })
            )
                .filter((budgetItem) => budgetItem.status !== BudgetItemStatus.Draft)
                .map((budgetItem) => makeLine(budgetItem, activities, allUsers, tags, loginUser));

            dropdownOptions = lines.reduce(
                (acc, line) => ({
                    ...acc,
                    [line.id]: getDropdownsOptionsByLineId(
                        line,
                        allUsers,
                        groupedDictionaries,
                        unsavedChanges[line.id] || [],
                        multiReferenceDictionaryApi,
                    ),
                }),
                {} as DropdownOptions,
            );
        }
        const cells = LayerManager.getInstance().makeTalbeCellsParamsForXLSXExport(lines, dropdownOptions);

        function getCellValue(
            line: TableLine,
            cell: any,
            columnParams: ColumnData,
            valueTransformer: (value: number) => number,
        ): string | number {
            if (columnParams.name === ColumnName.Responsible) {
                const ids: string[] = cell.value ? cell.value.split(',') : null;

                return ids
                    ? ids
                          .map((id) => {
                              const user = allUsers.find((user) => user.id == (id as any));
                              return user ? `${user.secondName} ${user.firstName}` : 'Значение не найдено';
                          })
                          .join(', ')
                    : '-';
            }

            if (columnParams.customCellType === CustomCellType.Dropdown) {
                const selectedOptions = cell.options.find((option: any) => option.id === cell.value);

                if (selectedOptions) {
                    return selectedOptions.title;
                }

                const column = ColumnsList.find((column) => column.name === columnParams.name);
                const budgetItemDictionary =
                    groupedDictionaries.byId[line.budgetItem?.dictionary?.[column.metaData?.dictionaryType]?.id];

                if (budgetItemDictionary) {
                    return ColumnsNameWithCodes.includes(columnParams.name)
                        ? budgetItemDictionary.code
                        : budgetItemDictionary.value;
                }

                return '-';
            }

            if (columnParams.valueType === CellValueType.Currency) {
                const value = cell.tooltip.replace(/ /g, '').replace(',', '.');

                return valueTransformer(value ? parseFloat(value as string) : 0);
            }

            return cell.title || '-';
        }

        function setColumnValue(row: object, optText = '') {
            return (row[' '] = optText);
        }

        function generateSumLine(data: any[], titleTransformer: (title: string) => string): any {
            const sumRow = {};
            for (const column in columnsToUpload) {
                const columnParams = columnsToUpload[column];
                const title = titleTransformer(columnParams.title);

                if (columnParams.valueType === CellValueType.Currency) {
                    sumRow[title] = data.reduce((acc, row) => acc + row[title], 0);
                } else {
                    sumRow[title] = '-';
                }
            }

            return sumRow;
        }

        // processing lines
        const rublesTitleTransofrmer = (title: string) => title.replace('тыс. ₽', '₽');
        const thousandsTitleTransformer = (title: string) => title;
        const rublesCurrencyData: any[] = lines.map((line: any) => {
            const row = {};
            setColumnValue(row);

            for (const column in columnsToUpload) {
                const columnParams = columnsToUpload[column];

                row[rublesTitleTransofrmer(columnParams.title)] = getCellValue(
                    line,
                    cells[line.id][column],
                    columnParams,
                    (value) => +value.toFixed(2),
                );
            }

            let statusCol;

            switch (line.status) {
                case BudgetItemStatus.Draft:
                    statusCol = 'В черновике';
                    break;
                case BudgetItemStatus.Published:
                    statusCol = 'Опубликован';
                    break;
                case BudgetItemStatus.Rejected:
                    statusCol = 'Отклонен';
                    break;
                case BudgetItemStatus.OnExpertApprovement:
                    statusCol = 'На одобрении';
                    break;
                case BudgetItemStatus.Approved:
                    statusCol = 'Одобрен';
                    break;
                case BudgetItemStatus.Disabled:
                    statusCol = 'Скрыт';
                    break;
                default:
                    break;
            }
            row['Статус'] = statusCol;

            row['Комментарий к статусу'] =
                line.status === BudgetItemStatus.Rejected
                    ? budgetItems.find((item) => item.id === line.id).expertComment
                    : '-';

            return row;
        });

        const thousandsCurrencyData: any[] = lines.map((line: any) => {
            const row = {};
            setColumnValue(row);

            for (const column in columnsToUpload) {
                const columnParams = columnsToUpload[column];

                row[columnParams.title] = getCellValue(
                    line,
                    cells[line.id][column],
                    columnParams,
                    (value) => +(value / 1000.0).toFixed(5),
                );
            }

            let statusCol;

            switch (line.status) {
                case BudgetItemStatus.Draft:
                    statusCol = 'В черновике';
                    break;
                case BudgetItemStatus.Published:
                    statusCol = 'Опубликован';
                    break;
                case BudgetItemStatus.Rejected:
                    statusCol = 'Отклонен';
                    break;
                case BudgetItemStatus.OnExpertApprovement:
                    statusCol = 'На утверждении';
                    break;
                case BudgetItemStatus.Approved:
                    statusCol = 'Утвержден';
                    break;
                case BudgetItemStatus.Disabled:
                    statusCol = 'Удален';
                    break;
                default:
                    break;
            }
            row['Статус'] = statusCol;

            row['Комментарий к статусу'] =
                line.status === BudgetItemStatus.Rejected
                    ? budgetItems.find((item) => item.id === line.id).expertComment
                    : '-';

            return row;

            return row;
        });

        // adding sum string
        const rublesSumLine = generateSumLine(rublesCurrencyData, rublesTitleTransofrmer);
        const thousandsSumLine = generateSumLine(thousandsCurrencyData, thousandsTitleTransformer);
        setColumnValue(rublesSumLine, 'Сумма:');
        setColumnValue(thousandsSumLine, 'Сумма:');

        rublesCurrencyData.push({});
        rublesCurrencyData.push(rublesSumLine);
        thousandsCurrencyData.push({});
        thousandsCurrencyData.push(thousandsSumLine);

        const result = {
            'В тысячах рублей': thousandsCurrencyData,
            'В рублях': rublesCurrencyData,
        };

        return BudgetCorrectionApi.getDataAsXLSX(result);
    }

    public async updateAfterImportFromXLSX(budgetItemsToIgnoreFiltersIds: string[]): Promise<void> {
        const { selectedBudget } = this.getStoreProps();

        const [activityBudgets, budgetItemsToIgnoreFilters] = await Promise.all([
            ActivityBudgetApi.getActivityBudgetList({ budgetId: selectedBudget.id }),
            this.fetchMoreBudgetItemsToIgnoreFilters(budgetItemsToIgnoreFiltersIds),
        ]);

        this.dispatch.loadPageData({ activityBudgets, budgetItemsToIgnoreFilters });
    }

    private async getBudgetRelatedPageData(): Promise<PageData> {
        const { selectedBudget, user } = this.getStoreProps();

        const [
            budget,
            activityBudgets,
            // budgetItems,
            userDictionariesData,
            allDictionariesData,
        ] = await Promise.all([
            selectedBudget ? await BudgetApi.getBudget(selectedBudget ? selectedBudget.id : null) : null,
            selectedBudget ? ActivityBudgetApi.getActivityBudgetList({ budgetId: selectedBudget.id }) : [],
            // budget ? BudgetItemApi.getBudgetItemList({ budgetId: budget.id }) : [],
            selectedBudget
                ? DictionaryApi.getDictionariesForBudget({
                      organizationId: selectedBudget.dictionaryOrganizationId,
                      userId: user.attributes.id,
                      budgetId: selectedBudget.id,
                      treeview: true,
                  })
                : [],
            selectedBudget
                ? DictionaryApi.getDictionaryList({
                      organizationId: selectedBudget.dictionaryOrganizationId,
                      treeview: true,
                  })
                : [],
        ]);

        return {
            budget,
            activityBudgets,
            // budgetItems,
            userDictionaries: this.groupDictionaries(userDictionariesData),
            allDictionaries: this.groupDictionaries(allDictionariesData),
            dictionariesForXLSXTemplate: this.prepareDictionariesForXLSXTemplate(userDictionariesData),
        };
    }

    private async getRestPageData(): Promise<Partial<PageData>> {
        const users = await UserApi.getFullUserList();

        return {
            users: this.filterUsers(users),
            allUsers: users,
        };
    }

    private groupDictionaries(dictionaries: PlainDictionary[]): GroupedDictionaries {
        const byId = dictionaries.reduce(
            (acc, dictionary) => ({
                ...acc,
                [dictionary.id]: dictionary,
            }),
            {} as Record<string, PlainDictionary>,
        );
        const byType: Partial<Record<DictionaryType, PlainDictionary[]>> = lodash.groupBy(
            dictionaries,
            (dictionary) => dictionary.type,
        );

        return { byId, byType };
    }

    private prepareDictionariesForXLSXTemplate(dictionaries: PlainDictionary[]): DictionariesForXLSXTemplate {
        const dictionariesWithParents = dictionaries.reduce(
            (acc, dictionary) => ({
                ...acc,
                [dictionary.id]: {
                    id: dictionary.id,
                    value: dictionary.value,
                    type: dictionary.type,
                    parents: [],
                },
            }),
            {},
        );
        dictionaries.forEach((dictionary) => {
            dictionary.childrenRefs
                ?.filter((childDictionary) => dictionariesWithParents[childDictionary.id])
                ?.forEach((childDictionary) =>
                    dictionariesWithParents[childDictionary.id].parents.push({
                        id: dictionary.id,
                        type: dictionary.type,
                    }),
                );
        });

        const dictionariesByType = Object.keys(dictionariesWithParents).reduce((acc, dictionaryId) => {
            const dictionary = dictionariesWithParents[dictionaryId];

            if (acc[dictionary.type]) {
                acc[dictionary.type].push(dictionary);
            } else {
                acc[dictionary.type] = [dictionary];
            }

            return acc;
        }, {});

        return dictionariesByType;
    }

    private filterUsers(users: UserResponseParams[]): UserResponseParams[] {
        const userOrganizationId = this.getUserOrganizationId();

        const organizationIds = lodash.includes(SBER_ORGANIZATIONS_IDS, userOrganizationId)
            ? SBER_ORGANIZATIONS_IDS
            : [userOrganizationId];

        return users.filter((item) => lodash.includes(organizationIds, item.organizationId));
    }

    private getUserOrganizationId(): string {
        const storeState = store.getState();

        const user = getLoginUser(storeState);

        return user.attributes.organizationId;
    }

    private mergeActivityBudgets(dest: ActivityBudget[], source: ActivityBudget[]): ActivityBudget[] {
        const sourceIds = source.map((activityBudget) => activityBudget.id);

        return [...dest.filter((activityBudget) => !sourceIds.includes(activityBudget.id)), ...source];
    }

    private mergeBudgetItems(dest: BudgetItem[], source: BudgetItem[], updatedLineIds: string[]): BudgetItem[] {
        const destIds = dest.map((budgetItem) => budgetItem.id);

        return [
            ...dest.filter((budgetItem) => !updatedLineIds.includes(budgetItem.id)),
            ...source.filter((budgetItem) => destIds.includes(budgetItem.id)),
        ];
    }

    private async applyFiltersFromUserConfig(userConfig: PlanningTableUserConfig): Promise<void> {
        const { budgetId } = this.getStoreProps();

        const existingColumns = ColumnsList.map((column) => column.name);

        if (userConfig[budgetId]?.budgetItemsToIgnoreFilters) {
            const budgetItemsToIgnoreFilters = await this.fetchMoreBudgetItemsToIgnoreFilters(
                userConfig[budgetId].budgetItemsToIgnoreFilters,
            );
            this.dispatch.loadPageData({ budgetItemsToIgnoreFilters });
        }

        if (userConfig[budgetId]?.columnsWidth) {
            this.dispatch.setColumnsWidth(userConfig[budgetId].columnsWidth);
        }

        if (userConfig[budgetId]?.fixedColumnsNames) {
            this.dispatch.setFixedColumnsNames(
                userConfig[budgetId].fixedColumnsNames.filter((column) => existingColumns.includes(column)),
            );
        }

        if (userConfig[budgetId]?.columnsVisiblityFilter) {
            const columnsVisibilityFilterFilter = Object.keys(userConfig[budgetId].columnsVisiblityFilter)
                .filter((column: ColumnName) => existingColumns.includes(column))
                .reduce(
                    (acc, column: ColumnName) => ({
                        ...acc,
                        [column]: userConfig[budgetId].columnsVisiblityFilter[column],
                    }),
                    {},
                );

            this.dispatch.setColumnsVisiblityFilter(columnsVisibilityFilterFilter);
        }

        if (userConfig[budgetId]?.sortingMode) {
            this.dispatch.setSortingMode(userConfig[budgetId].sortingMode);
        }

        if (userConfig[budgetId]?.showOnlyLinesWithPlanBudget) {
            this.dispatch.setShowOnlyLinesWithPlanBudget(userConfig[budgetId].showOnlyLinesWithPlanBudget);
        }

        if (userConfig[budgetId]?.showOnlyLinesWithoutPlanBudget) {
            this.dispatch.setShowOnlyLinesWithoutPlanBudget(userConfig[budgetId].showOnlyLinesWithoutPlanBudget);
        }

        if (userConfig[budgetId]?.filters) {
            const { defaultFilters } = this.getStoreProps();

            Object.keys(userConfig[budgetId].filters).forEach((columnName: ColumnName) => {
                this.dispatch.loadFilters({
                    columnName,
                    filters: userConfig[budgetId].filters[columnName],
                });

                // TODO Спросить у Егора для чего это
                // if (lodash.values(userConfig[budgetId].filters[columnName]).some(value => value)) {
                //     this.dispatch.loadFiltersByColumn(columnName);
                // }
            });

            await this.loadDataByFilters(userConfig[budgetId].filters);

            const mergedFilters = this.mergeFilters(defaultFilters, userConfig[budgetId].filters);

            this.dispatch.setFilters(mergedFilters);
            this.dispatch.setPreviouslyLoadedFilters({
                filters: mergedFilters,
            });
        }

        if (userConfig[budgetId]?.appliedFiltersNames) {
            this.dispatch.setAppliedFiltersNames(userConfig[budgetId].appliedFiltersNames);
        }

        if (userConfig[budgetId]?.unsavedChanges) {
            const updatedUnsavedChanges = lodash.cloneDeep(userConfig[budgetId].unsavedChanges);

            lodash.forEach(userConfig[budgetId].unsavedChanges, (lineChanges, lineId) => {
                lineChanges.forEach((change) => {
                    const currentValue = this.getValue(lineId, change.columnName);

                    change.originalValue = currentValue;
                });

                userConfig[budgetId].unsavedChanges[lineId] = lineChanges.filter(
                    (item) => item.value !== item.originalValue,
                );
            });

            this.dispatch.initUnsavedChanges(updatedUnsavedChanges);

            const activityNameUnsavedChangesLineIds = lodash
                .flatten(lodash.values(updatedUnsavedChanges))
                .filter((unsavedChange) => unsavedChange.columnName == ColumnName.ActivityName)
                .map((unsavedChange) => unsavedChange.budgetItemId);
            if (activityNameUnsavedChangesLineIds.length) {
                this.dispatch.loadMiscBudgetItems(activityNameUnsavedChangesLineIds);
            }
        }

        if (userConfig[budgetId]?.budgetItemsToDisplay) {
            this.dispatch.setBudgetItemsToDisplay(userConfig[budgetId].budgetItemsToDisplay);
        }

        if (userConfig[budgetId]?.budgetItemsApproversToDisplay) {
            this.dispatch.setBudgetItemsApproversToDisplay(userConfig[budgetId].budgetItemsApproversToDisplay);
        }

        if (userConfig[budgetId]?.displayDisabledLines !== undefined) {
            this.dispatch.setDisplayDisabledLines(!!userConfig[budgetId]?.displayDisabledLines);
        }

        if (userConfig[budgetId]?.displayOnlyUnapproved !== undefined) {
            this.dispatch.setDisplayOnlyUnapproved(!!userConfig[budgetId]?.displayOnlyUnapproved);
        }

        if (userConfig[budgetId]?.displayOnlyWithSnapshots !== undefined) {
            this.dispatch.setDisplayOnlyWithSnapshots(!!userConfig[budgetId]?.displayOnlyWithSnapshots);
        }
    }

    private async fetchMoreBudgetItemsToIgnoreFilters(budgetItemsIds: string[]): Promise<BudgetItem[]> {
        const { budgetId, budgetItemsToIgnoreFilters } = this.getStoreProps();

        if (budgetItemsIds?.length) {
            const budgetItemsToIgnoreFiltersUpd = await BudgetItemApi.getBudgetItemList({
                budgetId,
                filter: {
                    id: budgetItemsIds,
                },
            });

            return lodash.uniqBy(
                [...budgetItemsToIgnoreFilters, ...budgetItemsToIgnoreFiltersUpd],
                (budgetItem) => budgetItem.id,
            );
        }

        return budgetItemsToIgnoreFilters;
    }

    private mergeFilters(defaultFilters: Filters, userConfigFilters: Filters): Filters {
        const mergedFilters: Filters = lodash.cloneDeep(defaultFilters);

        lodash.forEach(userConfigFilters, (columnFilters, columnName) => {
            if (userConfigFilters.hasOwnProperty(columnName)) {
                mergedFilters[columnName] = userConfigFilters[columnName];
            }
        });

        return mergedFilters;
    }

    private getValue(lineId: string, columnName: ColumnName) {
        const { lines } = this.getStoreProps();

        const line = lines.find((item) => item.id == lineId);

        const column = ColumnsList.find((item) => item.name == columnName);

        let value;

        if (!line) {
            return null;
        }

        switch (column.valueType) {
            case CellValueType.String:
                value = getStringValue(line.fields[columnName]);
                break;

            case CellValueType.Number:
                value = getNumberValue(line.fields[columnName]);
                break;

            case CellValueType.Currency:
                value = getNumberValue(line.fields[columnName]);
                break;

            case CellValueType.Date:
                value = getDateValue(line.fields[columnName]);
                break;
        }

        return value;

        function getStringValue(cellValue: CellValue): string {
            return cellValue as string;
        }

        function getNumberValue(cellValue: CellValue): number {
            const value = cellValue as NumericCellValue;

            return value ? value.number : null;
        }

        function getDateValue(cellValue: CellValue): Date {
            const value = cellValue as DateCellValue;

            return value ? value.date : null;
        }
    }

    private getActivityByLineId(lineId: string): ActivityBudget {
        const { budgetItems, budgetItemsToIgnoreFilters } = this.getStoreProps();

        return [...budgetItems, ...budgetItemsToIgnoreFilters].find((budgetItem) => budgetItem.id === lineId).activity;
    }

    private async parseFiltersFromQuery(queryParams: queryString.ParsedQuery): Promise<Filters> {
        const { defaultFilters } = this.getStoreProps();
        const { filters } = queryParams;

        const parcedQueryFilters = filters
            ? Array.isArray(filters)
                ? this.parseFilterFieldsFromQuery(filters)
                : this.parseFilterFieldsFromQuery([filters])
            : {};

        const updatedFilters = lodash.cloneDeep(defaultFilters);

        await Promise.all(
            lodash.map(parcedQueryFilters, async (filterValues, filterName) => {
                if (filterName == 'id') {
                    const serialNumbers = await this.getLinesSerialNumberByLineIds(filterValues);
                    updatedFilters[ColumnName.Id] = this.applyQueryFilters(
                        updatedFilters[ColumnName.Id],
                        serialNumbers,
                    );
                }
            }),
        );

        return updatedFilters;
    }

    private async getLinesSerialNumberByLineIds(lineIds: string[]): Promise<string[]> {
        const {
            selectedBudget: { id: budgetId },
        } = this.getStoreProps();

        return (
            await BudgetItemApi.getBudgetItemList({
                budgetId,
                filter: {
                    id: lineIds,
                },
            })
        ).map((budgetItem) => `${budgetItem.serialNumber}`);
    }

    private applyQueryFilters(columnFilters: ColumnFilters, values: string[]): ColumnFilters {
        const updatedColumnFilter = lodash.mapValues(columnFilters, () => false);

        values.forEach((item) => (updatedColumnFilter[item] = true));

        return updatedColumnFilter;
    }

    private parseFilterFieldsFromQuery(filters: string[]): { [field: string]: string[] } {
        const parsedFilters = {};

        filters.forEach((item) => {
            const filterName = item.split(':')[0];
            const fieldNames = item.split(':')[1].split(',');

            parsedFilters[filterName] = fieldNames;
        });

        return parsedFilters;
    }

    // do not use await with this function (to prevent page UI block during snapshots loading)
    private async loadBudgetItemSnapsnots(budgetItems: BudgetItem[]): Promise<void> {
        const { budgetItemSnapshots } = this.getStoreProps();

        const budgetItemIdsForSnapshotLoading = budgetItems
            .filter((budgetItem) => !budgetItemSnapshots[budgetItem.id])
            .map((budgetItem) => budgetItem.id);

        if (budgetItemIdsForSnapshotLoading.length) {
            const snapshots = await Promise.all(
                budgetItemIdsForSnapshotLoading.map((id) =>
                    BudgetItemApi.getBudgetItemSnapshots(id, { id, columns: ['id', 'creationTime'] }),
                ),
            );

            const updBudgetItemSnapshots = snapshots.reduce((acc, snapshots) => {
                if (snapshots.length) {
                    const budgetItemId = snapshots[0].id;

                    return {
                        ...acc,
                        [budgetItemId]: [...(acc[budgetItemId] || []), ...snapshots],
                    };
                }

                return acc;
            }, budgetItemSnapshots);

            this.dispatch.loadPageData({ budgetItemSnapshots: updBudgetItemSnapshots });
        }
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        const {
            activityBudgets,
            allUsers,
            budgetItems,
            budgetItemsToIgnoreFilters,
            budgetItemSnapshots,
            allDictionaries,
        } = getPageData(storeState);
        const { columnsVisiblityFilter, sortingMode } = getTableFilters(storeState);
        const {
            fixedColumnsNames,
            columnFilters: { filters },
            unsavedChanges,
            multiReferenceDictionaryApi,
        } = getBudgetPlanningPageState(storeState);
        const { budgetId } = getBudgetByStatusUserConfig(storeState, BudgetStatus.Plan);
        const { budgets } = getBudgetState(storeState, BudgetStatus.Plan);
        const selectedBudget = budgets.find((budget) => budget.id === budgetId);

        const loginUser = getLoginUser(storeState);
        const tags = getTagsState(storeState).byId.dictionary;
        const dropdownOptions = getDropdownsOptions(storeState);

        return {
            activities: activityBudgets,
            budgetId,
            budgetItems,
            budgetItemsToIgnoreFilters,
            budgetItemSnapshots,
            lines: getTableLines(storeState),
            defaultFilters: getDefaultFilters(storeState),
            columnsVisiblityFilter,
            fixedColumnsNames,
            sortingMode,
            filteredLines: getFilteredTableLines(storeState),
            lineGroups: getLinesGroupedByActivities(storeState),
            filters,
            selectedBudget,
            allUsers,
            groupedDictionaries: allDictionaries,
            unsavedChanges,
            multiReferenceDictionaryApi,
            loginUser,
            tags,
            dropdownOptions,
            user: getLoginUser(storeState),
        };
    }
}
