// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetAndOrganizationSelector-DropdownItem-_DropdownItem__root{display:flex;padding:5px 14px 4px 15px;cursor:pointer;letter-spacing:0.15px;color:#000000;font-size:12px;line-height:16px;align-items:center;justify-content:space-between}.src-client-modules-budget-BudgetPage-BudgetAndOrganizationSelector-DropdownItem-_DropdownItem__root:hover{background:#f6f6f6}.src-client-modules-budget-BudgetPage-BudgetAndOrganizationSelector-DropdownItem-_DropdownItem__greyText{color:#8d948f}.src-client-modules-budget-BudgetPage-BudgetAndOrganizationSelector-DropdownItem-_DropdownItem__checkedIcon{color:#19bb4f}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetAndOrganizationSelector/DropdownItem/DropdownItem.scss"],"names":[],"mappings":"AAAA,qGACI,YAAa,CAEb,yBAA0B,CAE1B,cAAe,CACf,qBAAsB,CAEtB,aAAc,CAEd,cAAe,CACf,gBAAiB,CAEjB,kBAAmB,CACnB,6BAA8B,CAdlC,2GAiBQ,kBAAmB,CACtB,yGAID,aAAc,CACjB,4GAGG,aAAc","sourcesContent":[".root {\n    display: flex;\n\n    padding: 5px 14px 4px 15px;\n\n    cursor: pointer;\n    letter-spacing: 0.15px;\n\n    color: #000000;\n\n    font-size: 12px;\n    line-height: 16px;\n\n    align-items: center;\n    justify-content: space-between;;\n\n    &:hover {\n        background: #f6f6f6;\n    }\n}\n\n.greyText {\n    color: #8d948f;\n} \n\n.checkedIcon {\n    color: #19bb4f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetAndOrganizationSelector-DropdownItem-_DropdownItem__root",
	"greyText": "src-client-modules-budget-BudgetPage-BudgetAndOrganizationSelector-DropdownItem-_DropdownItem__greyText",
	"checkedIcon": "src-client-modules-budget-BudgetPage-BudgetAndOrganizationSelector-DropdownItem-_DropdownItem__checkedIcon"
};
export default ___CSS_LOADER_EXPORT___;
