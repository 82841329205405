import { TaskPageState } from '../types';

export const resetEditorReducer = (state: TaskPageState): TaskPageState => {
    if (typeof state.editingComment === 'symbol' || !state.editingComment) {
        return state;
    }

    return {
        ...state,
        editingComment: null,
        comments: {
            ...state.comments,
            [state.editingComment.id]: state.editingComment,
        },
    };
};
