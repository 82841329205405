import * as React from 'react';
import * as style from './TmImageCell.scss';
import { Icon, IconType } from 'sber-marketing-ui';

export interface TmImageCellProps {
    src: string;
}

export const TmImageCell = ({ src }: TmImageCellProps) => {
    return (
        <div className={style.image}>
            {src ? (
                <a href={src} target="_blank" title="Скачать файл">
                    <img src={src} alt="logo" />
                    <span />
                    <Icon type={IconType.DOWNLOAD_FILE} color="white" svgSize="22" />
                </a>
            ) : (
                ''
            )}
        </div>
    );
};
