// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-TaskBudgetApproval-_TaskBudgetApproval__root{position:relative;margin-top:14px;border-top:1px solid #eee;padding-top:14px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/TaskBudgetApproval/TaskBudgetApproval.scss"],"names":[],"mappings":"AAAA,wEACI,iBAAkB,CAClB,eAAgB,CAChB,yBAA0B,CAC1B,gBAAiB","sourcesContent":[".root {\n    position: relative;\n    margin-top: 14px;\n    border-top: 1px solid #eee;\n    padding-top: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-TaskBudgetApproval-_TaskBudgetApproval__root"
};
export default ___CSS_LOADER_EXPORT___;
