import { bindActionCreators } from 'redux';
import autobind from 'autobind-decorator';

import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type { FileAsset } from '@store/commonTypes';

import { store } from '@store';
import { getBriefState } from '@store/brief/selectors';
import { FileApi, FileApiUploadParams } from '@api';
import { add as addFileAsset, runUploadOne } from '@store/fileAssets';

interface StoreProps {
    initialActivity: Activity;
}

import { BriefSaver } from '../../Brief';

interface FileAssetParams {
    briefId: string;
    fieldId: string;
    uniqId?: number;
    parentUniqId?: number;
    file: File;
}

export class PageSaver {
    private static instance: PageSaver = null;
    private briefSavers: BriefSaver[] = [];
    private dispatch = {
        ...bindActionCreators(
            {
                addFileAsset,
            },
            store.dispatch,
        ),
        uploadFile: (fileAsset: FileAsset, params: FileApiUploadParams) =>
            runUploadOne({
                params,
                fileOrAssetOrId: fileAsset,
                dispatch: store.dispatch,
            }),
    };
    private constructor() {}

    public static getInstance(): PageSaver {
        if (!PageSaver.instance) {
            PageSaver.instance = new PageSaver();
        }

        return PageSaver.instance;
    }

    public async saveBriefs() {
        await Promise.all(this.briefSavers.map((briefSaver) => briefSaver.saveBrief()));
    }

    public registerBriefSaver(briefSaver: BriefSaver): void {
        this.briefSavers.push(briefSaver);
    }

    public removeBriefSaver(briefSaver: BriefSaver): void {
        this.briefSavers = this.briefSavers.filter((currentBriefSaver) => currentBriefSaver !== briefSaver);
    }

    @autobind
    public createFileAsset({ briefId, fieldId, uniqId, parentUniqId, file }: FileAssetParams): FileAsset {
        const { initialActivity } = this.getStoreProps();
        const activityId = initialActivity ? String(initialActivity.id) : null;

        const params = activityId
            ? { fieldId, uniqId, parentUniqId, briefId, activityId }
            : { fieldId, uniqId, parentUniqId, briefId };

        const fileAsset = FileApi.makeFileAsset(params, file);
        this.dispatch.addFileAsset(fileAsset);

        return fileAsset;
    }

    public async uploadFile(
        fieldId: string,
        uniqId: number,
        parentUniqId: number,
        briefId: string,
        fileAsset: FileAsset,
    ): Promise<FileAsset> {
        const { initialActivity } = this.getStoreProps();
        const activityId = initialActivity ? String(initialActivity.id) : null;

        const params = activityId
            ? { fieldId, uniqId, parentUniqId, briefId, activityId }
            : { fieldId, uniqId, parentUniqId, briefId };

        const loadedAsset: FileAsset = await this.dispatch.uploadFile(fileAsset, params);

        this.dispatch.addFileAsset(loadedAsset);

        return loadedAsset;
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        const { initialActivity } = getBriefState(storeState);

        return {
            initialActivity,
        };
    }
}
