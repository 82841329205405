import * as React from 'react';

import { ActivitySuggestWithStore } from './ActivitySuggestWithStore';
import { ActivitySuggestConnected } from './ActivitySuggestConnected';
import { ActivitySuggestContainer } from './ActivitySuggestContainer';

interface Props {
    budgetId: string;
}

export function ActivitySuggest({ budgetId }: Props): JSX.Element {
    return (
        <ActivitySuggestWithStore>
            {(containerProps) => (
                <ActivitySuggestConnected activityName={containerProps.nameInputValue} budgetId={budgetId}>
                    {(gqlProps) => <ActivitySuggestContainer {...containerProps} {...gqlProps} budgetId={budgetId} />}
                </ActivitySuggestConnected>
            )}
        </ActivitySuggestWithStore>
    );
}
