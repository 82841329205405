// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilotTv-StageWidgets-BudgetStageWidget-BudgetCard-StatusBar-_StatusBar__root{height:100%;padding:0 6px;white-space:nowrap;letter-spacing:0.3px;border-radius:3px;font-size:12px;line-height:16px}.src-client-modules-activity-pages-autopilotTv-StageWidgets-BudgetStageWidget-BudgetCard-StatusBar-_StatusBar__statusGrey{color:#878e8a;background:#ebeeec}.src-client-modules-activity-pages-autopilotTv-StageWidgets-BudgetStageWidget-BudgetCard-StatusBar-_StatusBar__statusGreen{color:#18b84e;background:#e9fff0}.src-client-modules-activity-pages-autopilotTv-StageWidgets-BudgetStageWidget-BudgetCard-StatusBar-_StatusBar__statusRed{color:#e63900;background:#ffebee}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilotTv/StageWidgets/BudgetStageWidget/BudgetCard/StatusBar/StatusBar.scss"],"names":[],"mappings":"AAAA,oHACI,WAAY,CAEZ,aAAc,CAEd,kBAAmB,CACnB,oBAAqB,CAErB,iBAAkB,CAElB,cAAe,CACf,gBAAiB,CACpB,0HAGG,aAAc,CACd,kBAAmB,CACtB,2HAGG,aAAc,CACd,kBAAmB,CACtB,yHAGG,aAAc,CACd,kBAAmB","sourcesContent":[".root {\n    height: 100%;\n\n    padding: 0 6px;\n\n    white-space: nowrap;\n    letter-spacing: 0.3px;\n\n    border-radius: 3px;\n\n    font-size: 12px;\n    line-height: 16px;\n}\n\n.statusGrey {\n    color: #878e8a;\n    background: #ebeeec;\n}\n\n.statusGreen {\n    color: #18b84e;\n    background: #e9fff0;\n}\n\n.statusRed {\n    color: #e63900;\n    background: #ffebee;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilotTv-StageWidgets-BudgetStageWidget-BudgetCard-StatusBar-_StatusBar__root",
	"statusGrey": "src-client-modules-activity-pages-autopilotTv-StageWidgets-BudgetStageWidget-BudgetCard-StatusBar-_StatusBar__statusGrey",
	"statusGreen": "src-client-modules-activity-pages-autopilotTv-StageWidgets-BudgetStageWidget-BudgetCard-StatusBar-_StatusBar__statusGreen",
	"statusRed": "src-client-modules-activity-pages-autopilotTv-StageWidgets-BudgetStageWidget-BudgetCard-StatusBar-_StatusBar__statusRed"
};
export default ___CSS_LOADER_EXPORT___;
