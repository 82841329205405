import createCashedSelector from 're-reselect';
import { ActivityType } from 'sber-marketing-types/frontend';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';

import { ActivityTypesState, StoreTypes } from './types';

const getActivityTypesState = (state: StoreState): ActivityTypesState => state.activityTypes;

export const getFetchersCount = createCashedSelector(
    getActivityTypesState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: ActivityTypesState, store: StoreTypes): number => state.stores[store].fetchersCount,
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getActivityTypeById = createCashedSelector(
    getActivityTypesState,
    (state: StoreState, id: string): string => id,
    (state: ActivityTypesState, id: string): ActivityType => state.byIds[id],
)((state: StoreState, id: string): string => id);

export const getActivityTypes = createCashedSelector(
    getActivityTypesState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: ActivityTypesState, store: StoreTypes): ActivityType[] => {
        const entitiesStore = state.stores[store];

        if (entitiesStore.loadingStatus === LoadingStatus.LOADED) {
            return state.stores[store].ids.map((id) => state.byIds[id]);
        }

        return [];
    },
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getActivityTypeIds = createCashedSelector(
    getActivityTypesState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: ActivityTypesState, store: StoreTypes): string[] => state.stores[store].ids,
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getLoadingStatus = createCashedSelector(
    getActivityTypesState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: ActivityTypesState, store: StoreTypes): LoadingStatus => state.stores[store].loadingStatus,
)((state: StoreState, store: StoreTypes): StoreTypes => store);
