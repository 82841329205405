import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';
import * as style from './Header.scss';

interface ButtonProps {
    onClick: () => void;
}

export const ApprovedIndicator: React.FC = () => {
    return <div className={classNames(style.indicator, style.approved)}>Согласовано</div>;
};

export const CancelButton: React.FC<ButtonProps> = ({ onClick }) => {
    return (
        <div className={classNames(style.button, style.cancelButton)} onClick={onClick}>
            <div className={classNames(style.buttonIcon, style.buttonApproveIcon)}>
                <Icon className={style.buttonIconNotHover} type={IconType.CIRCLE_BOLD_CROSS} svgSize={16} />
                <Icon className={style.buttonIconOnHover} type={IconType.CIRCLE_BOLD_FILLED_CROSS} svgSize={16} />
            </div>
            Отменить согласование
        </div>
    );
};

export const ApproveButton: React.FC<ButtonProps> = ({ onClick }) => {
    return (
        <div className={classNames(style.button, style.approveButton)} onClick={onClick}>
            <div className={classNames(style.buttonIcon, style.buttonApproveIcon)}>
                <Icon className={style.buttonIconNotHover} type={IconType.CIRCLE_BOLD_CHECK} svgSize={16} />
                <Icon className={style.buttonIconOnHover} type={IconType.CIRCLE_BOLD_FILLED_CHECK} svgSize={16} />
            </div>
            Согласовать
        </div>
    );
};

export const EditButton: React.FC<ButtonProps> = ({ onClick }) => {
    return (
        <div className={classNames(style.button, style.editButton)} onClick={onClick}>
            <div className={style.buttonIcon}>
                <Icon type={IconType.PROJECT_STAGES_EDIT_ICON} svgSize={18} />
            </div>
            Редактировать
        </div>
    );
};

export const NotApprovedIndicator: React.FC = () => {
    return <div className={classNames(style.indicator, style.notApproved)}>Не согласовано</div>;
};

export const Title: React.FC = ({ children }) => {
    return <div className={style.title}>{children}</div>;
};

export const HeaderBody: React.FC = ({ children }) => {
    return <div className={style.root}>{children}</div>;
};
