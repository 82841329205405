import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { compact, uniq, concat, flatten } from 'lodash';
import { BudgetItem } from '@mrm/budget';

import { UserApi } from '@api';

import { StoreState } from '@store';
import { getLoginUser, isLoggedUserBudgetExpert } from '@store/user';
import { LoadingStatus } from '@store/commonTypes';
import { setNotification } from '@store/common/actions';
import { NotificationActionType, NotificationType } from '@store/common/types';
import { PLANNED_COLUMN_NAMES, ColumnName, getPageData, getFilteredTableLines } from '@store/budgetExecution';

import { LayerManager } from '../../../modules/budget/BudgetPage/BudgetExecution/Table/LayerManager';
import { Loader, Saver } from '../../../modules/budget/BudgetPage/BudgetExecution/modules';

import * as asyncActions from './actions/async';
import * as actions from './actions/sync';
import { Reducer } from './reducers';
import {
    BudgetTransferMenuState,
    ComponentState,
    InternalTransferDirection,
    ExpertsState,
    CellPosition,
    UserOrganizationToExpertOrganizationMap,
    TransferDescriptorsState,
    CellsState,
    ControlsState,
} from './types';
import { getBudgetTransferMenuState, isCellAcceptor, isCellDonor, isLineDonor, isLineAcceptor } from './selectors';
import { isCellSelected, isInternalTransferState } from './misc';

export const setComponentState = bindThunkAction<StoreState, ComponentState, Partial<BudgetTransferMenuState>, Error>(
    asyncActions.setComponentState,
    async (componentState, dispatch, getState) => {
        const state = getState();
        const transitionDataState = getBudgetTransferMenuState(state);
        const prevComponentState = transitionDataState.controls.componentState;

        const initialState: BudgetTransferMenuState = {
            ...Reducer.makeInitialState(),
            data:
                componentState === ComponentState.Closed
                    ? {
                          budgetItems: [],
                      }
                    : transitionDataState.data,
        };

        const shouldKeepInternalTransferDirection =
            prevComponentState === ComponentState.InternalTransferLineSelection &&
            componentState === ComponentState.InternalTransferCellSelection;
        const shouldResetView =
            componentState === ComponentState.Closed ||
            componentState === ComponentState.ExternalIncomeTransfer ||
            componentState === ComponentState.ExternalOutcomeTransfer ||
            componentState === ComponentState.InternalTransferCellSelection;
        const shouldUpdateTransferDescriptors =
            prevComponentState === ComponentState.InternalTransferLineSelection &&
            componentState === ComponentState.InternalTransferSumEntering;

        if (shouldKeepInternalTransferDirection) {
            return {
                ...initialState,
                controls: {
                    ...initialState.controls,
                    componentState,
                    internalTransferDirection: transitionDataState.controls.internalTransferDirection,
                },
            };
        } else if (shouldResetView) {
            return {
                ...initialState,
                controls: {
                    ...initialState.controls,
                    componentState,
                },
                experts: {
                    ...transitionDataState.experts,
                    selectedExpert: null,
                },
            };
        }

        if (shouldUpdateTransferDescriptors) {
            // dellay to allow state update
            setTimeout(() => dispatch(updateTransferDescriptors(null)));
        }

        return {
            ...transitionDataState,
            controls: {
                ...transitionDataState.controls,
                componentState,
            },
        };
    },
);

export const openTransitionMenu = setComponentState.bind(null, ComponentState.InternalTransferCellSelection);
export const closeTransitionMenu = setComponentState.bind(null, ComponentState.Closed);

export const loadExperts = bindThunkAction<StoreState, null, ExpertsState, Error>(
    asyncActions.loadExperts,
    async (_, dispatch, getState) => {
        const state = getState();
        const userOrganizationId = getLoginUser(state).attributes.organizationId;
        let result: ExpertsState = getBudgetTransferMenuState(state).experts;

        if (result.loadingStatus === LoadingStatus.NOT_LOADED) {
            const expertsOrganizationIds = compact(
                concat(UserOrganizationToExpertOrganizationMap[userOrganizationId], userOrganizationId),
            );

            dispatch(actions.startLoadingExperts());
            const entities = await UserApi.getOrganizationExperts({
                organizationIds: expertsOrganizationIds,
            });

            result = {
                loadingStatus: LoadingStatus.LOADED,
                entities,
                selectedExpert: entities[0] ? entities[0].id : null,
            };
        }

        return result;
    },
);

export const onCellClick = bindThunkAction<StoreState, CellPosition, Partial<CellsState>, Error>(
    asyncActions.onCellClick,
    async (cell, dispatch, getState) => {
        const state = getState();
        const {
            controls: { componentState, internalTransferDirection },
            cells: { from: initialFrom, to: initialTo },
        } = getBudgetTransferMenuState(state);

        const isInternalTransfer = isInternalTransferState(componentState);

        const removeCellFromDonor = isCellDonor(state, cell);
        const removeCellFromAcceptor = isCellAcceptor(state, cell);

        const removeLineFromDonor =
            componentState === ComponentState.InternalTransferLineSelection &&
            internalTransferDirection === InternalTransferDirection.ManyToOne &&
            isLineDonor(state, cell.lineId);
        const removeLineFromAcceptor =
            componentState === ComponentState.InternalTransferLineSelection &&
            internalTransferDirection === InternalTransferDirection.OneToMany &&
            isLineAcceptor(state, cell.lineId);

        let from: CellPosition[] = initialFrom;
        let to: CellPosition[] = initialTo;
        if (removeLineFromDonor) {
            from = initialFrom.filter((fromCell) => fromCell.lineId !== cell.lineId);
        } else if (removeLineFromAcceptor) {
            to = initialTo.filter((toCell) => toCell.lineId !== cell.lineId);
        } else if (removeCellFromDonor || removeCellFromAcceptor) {
            from = [];
            to = [];
        } else {
            const donorIsSelected = isCellSelected(initialFrom);
            const acceptorIsSelected = isCellSelected(initialTo);

            const setToCell =
                componentState === ComponentState.ExternalIncomeTransfer ||
                (isInternalTransfer &&
                    internalTransferDirection === InternalTransferDirection.ManyToOne &&
                    !donorIsSelected);
            const setFromCell =
                componentState === ComponentState.ExternalOutcomeTransfer ||
                (isInternalTransfer &&
                    internalTransferDirection === InternalTransferDirection.OneToMany &&
                    !acceptorIsSelected);

            if (setToCell) {
                to = [...to, cell];
            }
            if (setFromCell) {
                from = [...from, cell];
            }
        }

        const result: Partial<CellsState> = { from, to };
        if (isInternalTransfer) {
            const gotoLineSelection =
                (internalTransferDirection === InternalTransferDirection.OneToMany && isCellSelected(from)) ||
                (internalTransferDirection === InternalTransferDirection.ManyToOne && isCellSelected(to));

            if (gotoLineSelection) {
                dispatch(setComponentState(ComponentState.InternalTransferLineSelection));
            } else {
                dispatch(setComponentState(ComponentState.InternalTransferCellSelection));
            }
        } else {
            // dellay to allow state update
            setTimeout(() => dispatch(updateTransferDescriptors(null)), 0);
        }

        dispatch(updateBudgetItems([...from, ...to]));

        return result;
    },
);

export const toggleLineStatus = bindThunkAction<StoreState, string, Partial<CellsState>, Error>(
    asyncActions.toggleLineStatus,
    async (lineId, dispatch, getState) => {
        const {
            controls: { internalTransferDirection },
            cells: { from, to },
        } = getBudgetTransferMenuState(getState());

        const result: Partial<CellsState> = {};
        switch (internalTransferDirection) {
            case InternalTransferDirection.OneToMany:
                result.to = to.some((toCell) => toCell.lineId === lineId)
                    ? to.filter((toCell) => toCell.lineId !== lineId)
                    : [
                          ...to,
                          {
                              lineId,
                              columnName: null,
                          },
                      ];
                break;
            case InternalTransferDirection.ManyToOne:
                result.from = from.some((fromCell) => fromCell.lineId === lineId)
                    ? from.filter((fromCell) => fromCell.lineId !== lineId)
                    : [
                          ...from,
                          {
                              lineId,
                              columnName: null,
                          },
                      ];
                break;
            default:
                break;
        }

        dispatch(updateBudgetItems([...(result.from || from), ...(result.to || to)]));

        return result;
    },
);

export const updateBudgetItems = bindThunkAction<StoreState, CellPosition[], BudgetItem[], Error>(
    asyncActions.updateBudgetItems,
    async (cells, dispatch, getState) => {
        const state = getState();
        const { budgetItems: pageBudgetItems, budgetItemsToIgnoreFilters } = getPageData(state);
        const allBudgetItems = [...pageBudgetItems, ...budgetItemsToIgnoreFilters];

        const ids = uniq([...cells].map((cell) => cell.lineId));
        const budgetItems = ids.reduce((acc, id) => {
            const budgetItem = allBudgetItems.find((budgetItem) => budgetItem.id === id);

            if (!budgetItem) {
                console.warn(`BudgetItem with id ${id} was requested by transitionData, but was not found in pageData`);
                return acc;
            }

            return [...acc, budgetItem];
        }, []);

        return budgetItems;
    },
);

export const initPlanTransfer = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.initPlanTransfer,
    async (_, dispatch, getState) => {
        const state = getState();
        const tableLoader = Loader.getInstance();
        const tableSaver = Saver.getInstance();
        const userIsBudgetExpert = isLoggedUserBudgetExpert(state);
        const {
            cells: { from, to },
            experts: { selectedExpert },
            transferDescriptors,
        } = getBudgetTransferMenuState(state);

        dispatch(actions.setRequestInProgress(true));

        await tableSaver.createPlanCorrection(
            selectedExpert,
            transferDescriptors.filter((descriptor) => !!descriptor.amount),
        );
        const linesToUpdate = compact([...from, ...to].map((cell) => cell.lineId));

        await Promise.all([tableLoader.updatePageDataByLineIds(linesToUpdate), tableLoader.updateLockedLinesData()]);

        dispatch(
            setNotification({
                type: NotificationType.SUCCESS,
                typeAction: NotificationActionType.CREATE_PLANNED_BUDGET_IN_TRANSFER_MENU_BUDGET,
                comment: 'Корректировка плана бюджета внесена',
            }),
        );

        if (!userIsBudgetExpert) {
            dispatch(
                setNotification({
                    type: NotificationType.SUCCESS,
                    typeAction: NotificationActionType.PLANNED_BUDGET_SEND_TO_APPROVEMENT,
                    comment: 'Корректировка плана бюджета отправлена на согласование',
                }),
            );
        }

        dispatch(closeTransitionMenu());
    },
);

export const toggleInternalTransitionDirectrion = bindThunkAction<StoreState, null, Partial<ControlsState>, Error>(
    asyncActions.toggleInternalTransitionDirectrion,
    async (_, dispatch, getState) => {
        const {
            controls: { internalTransferDirection },
            cells: { from, to },
        } = getBudgetTransferMenuState(getState());

        const result: Partial<ControlsState> = {};
        let needSwapFromTo: boolean;
        switch (internalTransferDirection) {
            case InternalTransferDirection.OneToMany:
                result.internalTransferDirection = InternalTransferDirection.ManyToOne;
                needSwapFromTo = true;
                break;
            case InternalTransferDirection.ManyToOne:
                result.internalTransferDirection = InternalTransferDirection.OneToMany;
                needSwapFromTo = true;
                break;
            default:
                break;
        }

        if (needSwapFromTo) {
            dispatch(
                actions.updateCells({
                    to: from,
                    from: to,
                }),
            );
        }

        return result;
    },
);

export const updateIsHoveredLineClickable = bindThunkAction<StoreState, string, Partial<ControlsState>, Error>(
    asyncActions.updateIsHoveredLineClickable,
    async (lineId, dispatch, getState) => {
        const state = getState();
        const lines = getFilteredTableLines(state);
        const {
            controls: { internalTransferDirection },
            cells: { to },
        } = getBudgetTransferMenuState(state);
        const cellsParams = LayerManager.getInstance().getCellsParams();

        let isHoveredLineClickable: boolean;
        if (lineId) {
            switch (internalTransferDirection) {
                case InternalTransferDirection.OneToMany:
                    isHoveredLineClickable = true;

                    break;
                case InternalTransferDirection.ManyToOne:
                    const lineIsEditable = !lines.find((line) => line.id === lineId).isDisabled;

                    if (lineIsEditable) {
                        const lineCellsParams = cellsParams[lineId];
                        const acceptorDesc = to.find((toCell) => toCell.lineId === lineId);

                        isHoveredLineClickable = PLANNED_COLUMN_NAMES.some((columnName: ColumnName) => {
                            if (acceptorDesc && acceptorDesc.columnName === columnName) {
                                return false;
                            }

                            return lineCellsParams[columnName].isClickable;
                        });
                    } else {
                        isHoveredLineClickable = false;
                    }

                    break;
                default:
                    break;
            }
        } else {
            isHoveredLineClickable = true;
        }

        return { isHoveredLineClickable };
    },
);

const updateTransferDescriptors = bindThunkAction<StoreState, null, TransferDescriptorsState, Error>(
    asyncActions.updateTransferDescriptors,
    async (_, dispatch, getState) => {
        const {
            controls: { componentState, internalTransferDirection },
            cells: { from, to },
        } = getBudgetTransferMenuState(getState());
        const cellsParams = LayerManager.getInstance().getCellsParams();

        let transferDescriptors: TransferDescriptorsState;
        if (componentState === ComponentState.ExternalIncomeTransfer) {
            transferDescriptors = [
                {
                    from: null,
                    to: to[0],
                    amount: 0,
                },
            ];
        } else if (componentState === ComponentState.ExternalOutcomeTransfer) {
            transferDescriptors = [
                {
                    from: from[0],
                    to: null,
                    amount: 0,
                },
            ];
        } else {
            switch (internalTransferDirection) {
                case InternalTransferDirection.OneToMany:
                    transferDescriptors = flatten(
                        to.map((toCell) => {
                            const columnsToUse =
                                from[0].lineId === toCell.lineId
                                    ? PLANNED_COLUMN_NAMES.filter((columnName) => columnName !== from[0].columnName)
                                    : PLANNED_COLUMN_NAMES;

                            return columnsToUse.map((columnName) => ({
                                from: from[0],
                                to: {
                                    lineId: toCell.lineId,
                                    columnName,
                                },
                                amount: 0,
                            }));
                        }),
                    );
                    break;
                case InternalTransferDirection.ManyToOne:
                    transferDescriptors = flatten(
                        from.map((fromCell) => {
                            const cellParams = cellsParams[fromCell.lineId];
                            const fromColumns = PLANNED_COLUMN_NAMES.filter((columnName) => {
                                if (fromCell.lineId === to[0].lineId && to[0].columnName === columnName) {
                                    return false;
                                }

                                return cellParams[columnName].isClickable;
                            });

                            return fromColumns.map((columnName) => ({
                                from: {
                                    lineId: fromCell.lineId,
                                    columnName,
                                },
                                to: to[0],
                                amount: 0,
                            }));
                        }),
                    );
                    break;
                default:
                    transferDescriptors = [];
                    break;
            }
        }

        return transferDescriptors;
    },
);
