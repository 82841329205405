import * as React from 'react';
import autobind from 'autobind-decorator';
import { isEqual } from 'lodash';
import { TaskStatus, UserResponseParams, DepartmentAttributes, TaskCardParams } from 'sber-marketing-types/frontend';
import {
    WorkTypeParams,
    GetDashboardTasksFiltersParams,
    GetDashboardTasksFiltersResponse,
} from 'sber-marketing-types/backend';

import { FilterApi } from '@api';

import { Division } from '@store/divisions';
import { Stage } from '@store/activityTasksPage/stages';
import { TasksFilter, TaskParticipation } from '@store/userConfig';

import { TaskFiltersMenu } from './TaskFiltersMenu';

export interface Props extends MapProps, DispatchProps {
    delayLoading?: boolean;
}

export interface MapProps {
    workTypes: WorkTypeParams[];
    authors: UserResponseParams[];
    executors: UserResponseParams[];
    divisions?: Division[];
    departments: DepartmentAttributes[];
    activityStages?: Stage[];
    tasksByStageId?: {
        [stageId: string]: TaskCardParams[];
    };
    loadFiltersParams?: GetDashboardTasksFiltersParams;
    isLoading: boolean;
    shouldMergeUserConfig?: boolean;
    filters: TasksFilter;
    myTasksFiltersPage?: boolean;
}

export interface DispatchProps {
    loadFiltersEntities: (filters: GetDashboardTasksFiltersResponse) => void;
    setFilters: (payload: Partial<TasksFilter>) => void;
    save: (payload: Partial<TasksFilter>) => void;
    resetEntitiesStores: () => void;
    mergeUserConfig?: () => void;
}

export class TaskFiltersMenuContainer extends React.Component<Props> {
    public async componentDidMount(): Promise<void> {
        if (!this.props.delayLoading) {
            this.loadFilters();
        }
    }

    public async UNSAFE_componentWillReceiveProps(nextProps: Props): Promise<void> {
        const needFilterLoading =
            (this.props.delayLoading && !nextProps.delayLoading) || (!this.props.isLoading && nextProps.isLoading);

        if (needFilterLoading) {
            this.loadFilters();
        }

        if (!this.props.shouldMergeUserConfig && nextProps.shouldMergeUserConfig) {
            this.props.mergeUserConfig();
        }
    }

    public shouldComponentUpdate(props: Props): boolean {
        return !isEqual(props, this.props);
    }

    public componentWillUnmount(): void {
        this.props.resetEntitiesStores();
    }

    public render(): JSX.Element {
        const {
            isLoading,
            myTasksFiltersPage,
            filters,
            workTypes,
            departments,
            authors,
            executors,
            activityStages,
            tasksByStageId,
        } = this.props;

        return (
            <TaskFiltersMenu
                myTasksFiltersPage={myTasksFiltersPage}
                isLoading={isLoading}
                filters={filters}
                workTypes={workTypes}
                departments={departments}
                authors={authors}
                executors={executors}
                activityStages={activityStages}
                tasksByStageId={tasksByStageId}
                onSave={this.onSave}
                onStatusSelection={this.onStatusSelection}
                onWorkTypeSelect={this.onWorkTypeSelect}
                onParticipationSelect={this.onParticipationSelect}
                onAuthorSelect={this.onAuthorSelect}
                onExecutorSelect={this.onExecutorSelect}
                onDepartmentSelect={this.onDepartmentsSelect}
                onActivityStageSelect={this.onActivityStageSelect}
            />
        );
    }

    private async loadFilters(): Promise<void> {
        const { loadFiltersParams } = this.props;

        const filters = await FilterApi.getDashboardTasksFilters(loadFiltersParams);

        this.props.loadFiltersEntities(filters);
    }

    @autobind
    private onStatusSelection(status: TaskStatus): void {
        this.props.setFilters({
            status,
        });
    }

    @autobind
    private onWorkTypeSelect(workType: string[]): void {
        this.props.setFilters({
            workType,
        });
    }

    @autobind
    private onDepartmentsSelect(department: string[]): void {
        this.props.setFilters({
            department,
        });
    }

    @autobind
    private onActivityStageSelect(activityStage: string[]): void {
        this.props.setFilters({
            activityStage,
        });
    }

    @autobind
    private onParticipationSelect(participation: TaskParticipation): void {
        const filters: Partial<TasksFilter> = {
            participation,
        };

        if (participation === TaskParticipation.EXECUTOR) {
            filters.executor = [];
        }

        this.props.setFilters(filters);
    }

    @autobind
    private onAuthorSelect(author: number[]): void {
        this.props.setFilters({
            author,
        });
    }

    @autobind
    private onExecutorSelect(executor: number[]): void {
        const filters: Partial<TasksFilter> = {
            executor,
        };

        if (this.props.filters.participation === TaskParticipation.EXECUTOR) {
            filters.participation = null;
        }

        this.props.setFilters(filters);
    }

    @autobind
    private onSave(): void {
        this.props.save(this.props.filters);
    }
}
