import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';

import { getAutopilots } from '@store/activityPage/selectors';

import { AutopilotName, autopilotNames } from '@modules/autopilot/constants';
import { AutopilotStatus } from '@modules/activity/AutopilotStatus';

import * as style from './AutopilotsList.scss';

const headers = ['Название автопилота', 'Статус', 'Старт кампании', 'Окончание кампании', 'Ссылка'];

type DivProps = JSX.IntrinsicElements['div'];

export interface AutopilotsListProps extends DivProps {
    activityId: number;
}

export const AutopilotsList: React.FC<AutopilotsListProps> = ({ activityId, className, ...props }) => {
    const autopilots = useSelector(getAutopilots);
    const availableAutopilots = autopilotNames.filter(({ id }) => autopilots[id]);

    if (!availableAutopilots.length) return null;

    const renderAutopilot = ({ id, name }: AutopilotName) => {
        const autopilot = autopilots[id];
        return (
            <React.Fragment key={id}>
                <div className={classNames(style.cell, style.name)}>{name}</div>
                <div className={style.cell}>
                    <AutopilotStatus autopilot={autopilot} />
                </div>
                <div className={style.cell}>
                    <Icon className={style.calendar} svgSize={16} type={IconType.CALENDAR_ICON} />
                    {autopilot.brief?.dateStart ? new Date(autopilot.brief.dateStart).toLocaleDateString() : '–'}
                </div>
                <div className={style.cell}>
                    <Icon className={style.calendar} svgSize={16} type={IconType.CALENDAR_ICON} />
                    {autopilot.brief?.dateEnd ? new Date(autopilot.brief.dateEnd).toLocaleDateString() : '–'}
                </div>
                <div className={style.cell}>
                    <Link className={style.link} to={`/activity/${activityId}/${id}`}>
                        Перейти в автопилот
                    </Link>
                </div>
            </React.Fragment>
        );
    };

    return (
        <div {...props} className={classNames(style.root, className)}>
            <h2 className={style.header}>Автопилоты</h2>
            <div className={style.grid}>
                {headers.map((header) => (
                    <div key={header} className={style.head}>
                        {header}
                    </div>
                ))}
                {availableAutopilots.map(renderAutopilot)}
            </div>
        </div>
    );
};
