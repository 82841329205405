import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { PlainDictionary } from '@mrm/dictionary';
import { UserResponseParams } from 'sber-marketing-types/frontend';
import {
    ColumnName,
    ColumnsVisiblityFilter,
    SortingMode,
    OrderType,
    Filters,
    ColumnFilters,
    CustomCellType,
    ColumnsWidth,
    ColumnsNameWithCodes,
    GroupedDicitonaries,
} from '@store/budgetExecution/types';

import { TableHeader } from './TableHeader';
import { StoreState } from '@store';
import {
    setColumnsWidth,
    setResizingColumnName,
    setHoveredColumnName,
    setSortingMode,
    setFilters,
    toggleColumnFix,
} from '@store/budgetExecution/actions';
import { getBudgetExecutionPageState, getTableFilters, getPageData } from '@store/budgetExecution/selectors';
import { ColumnsList } from '../../ColumnsConfig';

const MAX_COLUMN_WIDTH = 1500;
const MIN_COLUMN_WIDTH = 45;

interface Props extends Partial<MapProps>, Partial<DispatchProps> {
    hoveredColumnName: ColumnName;
    headerFixedColumnsRef: (element: HTMLDivElement) => void;
    onCellMouseEnter: (columnName: ColumnName) => void;
    onCellMouseLeave: () => void;
    onDropdownClick: (columnName: ColumnName, dropdownContent: JSX.Element) => void;
    onApplyFiltersButtonClick: () => void;
}

interface MapProps {
    users: UserResponseParams[];
    columnsWidth: ColumnsWidth;
    resizingColumnName: ColumnName;
    fixedColumnsNames: ColumnName[];
    columnsVisiblityFilter: ColumnsVisiblityFilter;
    sortingMode: SortingMode;
    filters: Filters;
    dictionaries: GroupedDicitonaries;
}

interface DispatchProps {
    setColumnsWidth: (columnsWidth: ColumnsWidth) => void;
    setResizingColumnName: (columnName: ColumnName) => void;
    setHoveredColumnName: (columnName: ColumnName) => void;
    setSortingMode: (sortingMode: SortingMode) => void;
    setFilters: (filterMode: Filters) => void;
    toggleColumnFix: (columnName: ColumnName) => void;
}

interface State {
    isResizingColumn: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class TableHeaderContainer extends React.Component<Props, State> {
    private resizingColumnInitialWidth: number;
    private columnEdgeDragStartX: number;

    constructor(props: Props) {
        super(props);

        this.state = {
            isResizingColumn: false,
        };
    }

    public render(): JSX.Element {
        const { filters: receivedFilters, users, dictionaries } = this.props;

        const filters = lodash.clone(receivedFilters);

        ColumnsList.forEach((column) => {
            if (column.name === ColumnName.Responsible) {
                filters[column.name] = Object.keys(filters[column.name]).reduce((acc, userId) => {
                    const numberUserId = Number(userId);
                    const user = users.find((user) => user.id == numberUserId);
                    const key = user ? `${user.secondName} ${user.firstName}` : null;

                    return {
                        ...acc,
                        [key]: filters[column.name][userId],
                    };
                }, {});
            } else if (column.customCellType === CustomCellType.Dropdown) {
                const columnName = column.name;

                const keys = lodash.keys(filters[columnName]);

                const mappedFilters = {};

                keys.forEach((key) => {
                    const value = dictionaries[key];

                    const valueName = value ? value.value : key;

                    mappedFilters[valueName] = filters[columnName][key];
                });

                filters[columnName] = mappedFilters;
            }
        });

        return React.createElement(TableHeader, {
            columnsVisiblityFilter: this.props.columnsVisiblityFilter,
            columnsWidth: this.props.columnsWidth,
            sortingMode: this.props.sortingMode,
            filters,
            fixedColumnsNames: this.props.fixedColumnsNames,
            hoveredColumnName: this.props.hoveredColumnName,
            draggedEdgeColumnName: this.props.resizingColumnName,
            isResizingColumn: this.state.isResizingColumn,
            headerFixedColumnsRef: this.props.headerFixedColumnsRef,
            onCellMouseEnter: this.props.onCellMouseEnter,
            onCellMouseLeave: this.props.onCellMouseLeave,
            onDropdownClick: this.props.onDropdownClick,
            onSortingChange: this.onSortingChange,
            onFiltersSelection: this.onFiltersSelection,
            onColumnFixChange: this.onColumnFixChange,
            onColumnEdgeMousedown: this.onColumnEdgeMousedown,
            onApplyFiltersButtonClick: this.props.onApplyFiltersButtonClick,
        });
    }

    @autobind
    protected onColumnEdgeMousedown(columnName: ColumnName, mouseDownX: number) {
        this.resizingColumnInitialWidth = this.props.columnsWidth[columnName];
        this.columnEdgeDragStartX = mouseDownX;

        this.props.setResizingColumnName(columnName);

        document.body.style.cursor = 'col-resize';

        document.addEventListener('mousemove', this.onColumnEdgeMousemove);
        document.addEventListener('mouseup', this.onColumnEdgeMouseup);

        this.setState({
            isResizingColumn: true,
        });
    }

    @autobind
    protected onColumnEdgeMousemove(event: MouseEvent) {
        const deltaX = event.clientX - this.columnEdgeDragStartX;

        let columnWidth = this.resizingColumnInitialWidth + deltaX;

        if (columnWidth > MAX_COLUMN_WIDTH) {
            columnWidth = MAX_COLUMN_WIDTH;
        }

        if (columnWidth < MIN_COLUMN_WIDTH) {
            columnWidth = MIN_COLUMN_WIDTH;
        }

        this.props.setColumnsWidth({
            ...this.props.columnsWidth,
            [this.props.resizingColumnName]: columnWidth,
        });
    }

    @autobind
    protected onColumnEdgeMouseup() {
        this.resizingColumnInitialWidth = null;
        this.columnEdgeDragStartX = null;

        this.props.setResizingColumnName(null);

        document.body.style.cursor = '';

        document.removeEventListener('mousemove', this.onColumnEdgeMousemove);
        document.removeEventListener('mouseup', this.onColumnEdgeMouseup);

        this.setState({
            isResizingColumn: false,
        });
    }

    @autobind
    protected onSortingChange(columnName: ColumnName, sortingOrder: OrderType) {
        this.props.setSortingMode({
            columnName: sortingOrder ? columnName : null,
            order: sortingOrder,
        });
    }

    @autobind
    protected onFiltersSelection(columnName: ColumnName, columnFilters: ColumnFilters) {
        const { dictionaries } = this.props;

        const column = ColumnsList.find((column) => column.name === columnName);
        let updatedFilters = columnFilters;

        if (columnName !== ColumnName.Responsible && column.customCellType === CustomCellType.Dropdown) {
            const columnTitles = lodash.keys(columnFilters);
            const newFilters = {};

            if (ColumnsNameWithCodes.includes(columnName)) {
                const targetColumnn = ColumnsList.find((column) => column.name === columnName);

                const dictionariesByCode = Object.values(
                    dictionaries.byType[targetColumnn?.metaData?.dictionaryType] || [],
                ).reduce(
                    (acc: Record<string, PlainDictionary>, dictionary: PlainDictionary) => ({
                        ...acc,
                        [`${dictionary.code}`]: dictionary,
                    }),
                    {},
                );

                columnTitles.forEach((key) => {
                    const value = dictionariesByCode[key];
                    const valueCode = value ? value.code : null;

                    newFilters[valueCode] = columnFilters[key];
                });
            } else {
                columnTitles.forEach((key) => {
                    const value = dictionaries.byId[key];
                    const valueId = value ? value.id : null;

                    newFilters[valueId] = columnFilters[key];
                });
            }

            updatedFilters = newFilters;
        }

        this.props.setFilters({
            [columnName]: updatedFilters,
        });
    }

    @autobind
    protected onColumnFixChange(columnName: ColumnName) {
        this.props.toggleColumnFix(columnName);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const {
        fixedColumnsNames,
        columnsWidth,
        resizingColumnName,
        columnFilters: { filters },
    } = getBudgetExecutionPageState(state);
    const { allDictionaries, users } = getPageData(state);
    const { columnsVisiblityFilter, sortingMode } = getTableFilters(state);

    return {
        columnsVisiblityFilter,
        columnsWidth,
        resizingColumnName,
        sortingMode,
        filters,
        dictionaries: allDictionaries,
        fixedColumnsNames,
        users,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setColumnsWidth,
            setResizingColumnName,
            setHoveredColumnName,
            setSortingMode,
            setFilters,
            toggleColumnFix,
        },
        dispatch,
    );
}
