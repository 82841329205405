import { createSelector } from 'reselect';
import { SelectItem } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getBudgetItemByLineId } from '@store/budgetExecution';

import { TransferBudgetItemsToPlanningMenuState, ComponentState } from './types';

export const getTransferBudgetItemsToPlanningMenuState = (state: StoreState): TransferBudgetItemsToPlanningMenuState =>
    state.budgetExecutionPage.transferBudgetItemsToPlannningMenu;

export const isTransferBudgetItemsToPlanningMenuClosed = createSelector(
    getTransferBudgetItemsToPlanningMenuState,
    (state) => state.props.componentState === ComponentState.Closed,
);

export const getBudgetsAsSelectItems = createSelector(
    getTransferBudgetItemsToPlanningMenuState,
    (state: TransferBudgetItemsToPlanningMenuState): SelectItem[] =>
        state.data.planningBudgets.items.map((budget) => ({
            value: budget.id,
            label: `Планирование ${budget.year}`,
        })),
);

export const isHoveredLineClickable = createSelector(
    getTransferBudgetItemsToPlanningMenuState,
    (state) => state,
    (state: TransferBudgetItemsToPlanningMenuState, storeState: StoreState): boolean => {
        const { hoveredLineId, activityToSelectLines } = state.props;

        const budgetItem = getBudgetItemByLineId(storeState, hoveredLineId);

        if (budgetItem?.childrenBudgetItems?.length) {
            return false;
        } else if (!activityToSelectLines) {
            return true;
        }

        return budgetItem?.activity?.id === activityToSelectLines;
    },
);
