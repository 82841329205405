import * as React from 'react';

import * as style from './RadioSplit.scss';

import type { CellEvent, CellPosition, ColumnName, ColumnWidths, LineId } from './types';

import { TableView } from './TableView';
import { TableViewModel } from './TableViewModel';

interface Props {
    viewModel: TableViewModel;
    selectedRadiosTableColumns: ColumnName[];
    availableRadiosTableColumns: ColumnName[];
    leftFixedColumns: ColumnName[];
    selectedRadiosTableRightFixedColumns: ColumnName[];
    availableRadiosTableRightFixedColumns: ColumnName[];
    selectedRadiosTableLineIds: LineId[];
    availableRadiosTableLineIds: LineId[];
    columnWidths: ColumnWidths;
    selectedTableRef: (component: TableView) => void;
    availableTableRef: (component: TableView) => void;
    onCellEvent: (eventType: CellEvent, position: CellPosition) => void;
    onRecommendedRadiosButtonClick: () => void;
}

export const RadioSplitTemplate = ({
    viewModel,
    selectedRadiosTableColumns,
    availableRadiosTableColumns,
    leftFixedColumns,
    selectedRadiosTableRightFixedColumns,
    availableRadiosTableRightFixedColumns,
    selectedRadiosTableLineIds,
    availableRadiosTableLineIds,
    columnWidths,
    selectedTableRef,
    availableTableRef,
    onCellEvent,
    onRecommendedRadiosButtonClick,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.tableLegends}>
                <div className={style.tableLegend}>
                    <div className={style.tableLegendTitle} style={{ background: '#ebf8ee' }}>
                        Радиостанция
                    </div>

                    <div className={style.tableLegendDescription}>— основные, рекомендуемые к выбору</div>
                </div>

                <div className={style.tableLegend}>
                    <div className={style.tableLegendTitle} style={{ background: '#f4f9fc' }}>
                        Радиостанция
                    </div>

                    <div className={style.tableLegendDescription}>— дополнительные, рекомендуемые к выбору</div>
                </div>
            </div>

            <div className={style.selectedRadiosTable}>
                <div className={style.tableTitle}>
                    Выбранные радиостанции
                    <div className={style.button} onClick={onRecommendedRadiosButtonClick}>
                        Заполнить рекомендуемыми станциями
                    </div>
                </div>

                <div className={style.table}>
                    <TableView
                        ref={selectedTableRef}
                        viewModel={viewModel}
                        columns={selectedRadiosTableColumns}
                        leftFixedColumns={leftFixedColumns}
                        rightFixedColumns={selectedRadiosTableRightFixedColumns}
                        lines={selectedRadiosTableLineIds}
                        columnWidths={columnWidths}
                        onCellEvent={onCellEvent}
                    />
                </div>
            </div>

            <div className={style.availableRadiosTable}>
                <div className={style.tableTitle}>Доступные для выбора радиостанции</div>

                <div className={style.table}>
                    <TableView
                        ref={availableTableRef}
                        viewModel={viewModel}
                        columns={availableRadiosTableColumns}
                        leftFixedColumns={leftFixedColumns}
                        rightFixedColumns={availableRadiosTableRightFixedColumns}
                        lines={availableRadiosTableLineIds}
                        columnWidths={columnWidths}
                        onCellEvent={onCellEvent}
                    />
                </div>
            </div>
        </div>
    );
};
