import { createSelector } from 'reselect';

import { StoreState } from '../';
import { CommonState, NotificationMessage, PageOptionsStub, FileUploadStatus } from './types';

const getCommonState = (state: StoreState) => state.common;

export const isRequestInProgress = createSelector(getCommonState, (state: CommonState) => state.isRequestInProgress);

export const getErrorCode = createSelector(getCommonState, (state: CommonState) => state.errorCode);

export const getErrorMessage = createSelector(getCommonState, (state: CommonState) => state.errorMessage);

export const getPageOptions = createSelector(
    getCommonState,
    (state: CommonState) => state.pageOptions[state.currentPath] || PageOptionsStub,
);

export const getWithoutNavMenu = createSelector(getPageOptions, (state) => state.withoutNavMenu);

export const getHeaderHeight = createSelector(getCommonState, (state: CommonState) => state.headerHeight);

export const getNotificationList = createSelector(
    getCommonState,
    (state: CommonState): { [key: string]: NotificationMessage } => state.notificationList,
);

export const getUploadedFilesWithViruses = createSelector(getCommonState, (state) =>
    Object.keys(state.filesToUploadStatus).filter(
        (fileName) => state.filesToUploadStatus[fileName] === FileUploadStatus.VirusFound,
    ),
);
