import * as url from 'url';
import { AuthResponse, AuthorizationTimeLeftResponse } from 'sber-marketing-types/frontend';

import { axios, AxiosResponse } from '../lib/axios';

const BASE_URL = '/api/auth';

export class AuthApi {
    public static async getTimeLeft(): Promise<number> {
        const res: AxiosResponse<AuthorizationTimeLeftResponse> = await axios.get(`${BASE_URL}/time-left`);

        return res.data.timeLeft;
    }

    public static async setTimeLeft(): Promise<void> {
        await axios.put(`${BASE_URL}/time-left`);
    }

    public static logout(): void {
        const { protocol, host } = url.parse(window.location.href);
        window.location.href = url.format({
            protocol,
            host,
            pathname: `${BASE_URL}/logout`,
        });
    }

    public static async getAuthorizedUser(): Promise<AuthResponse> {
        const res: AxiosResponse<AuthResponse> = await axios.get(`${BASE_URL}/user`);

        return res.data;
    }

    public static login(from: string): void {
        const { protocol, host } = url.parse(window.location.href);
        window.location.href = url.format({
            protocol,
            host,
            pathname: `${BASE_URL}/login`,
            query: {
                from: encodeURIComponent(from),
            },
        });
    }
}
