import * as React from 'react';
import { times } from 'lodash';
import { positionValues } from 'react-custom-scrollbars';
import { CustomScrollbar } from 'sber-marketing-ui';
import { BudgetItemSnapshot } from '@mrm/budget';

import { HistoryItem, HistoryItemSkeleton } from './HistoryItem';

import * as styles from './BudgetItemHistoryWidget.scss';

interface Props {
    isLoading: boolean;
    items: BudgetItemSnapshot[];
    onScroll(data: positionValues): void;
}

export function BudgetItemHistoryWidgetTemplate({ isLoading, items, onScroll }: Props): JSX.Element {
    const itemsToUse = times(items.length - 1);

    return (
        <CustomScrollbar hideTracksWhenNotNeeded onScrollFrame={onScroll}>
            <div className={styles.root}>
                {itemsToUse.map((item) => (
                    <div key={item} className={styles.item}>
                        <HistoryItem prev={items[item + 1]} curr={items[item]} />
                    </div>
                ))}

                {isLoading ? (
                    <React.Fragment>
                        <div className={styles.item}>
                            <HistoryItemSkeleton />
                        </div>

                        <div className={styles.item}>
                            <HistoryItemSkeleton />
                        </div>

                        <div className={styles.item}>
                            <HistoryItemSkeleton />
                        </div>
                    </React.Fragment>
                ) : null}
            </div>
        </CustomScrollbar>
    );
}
