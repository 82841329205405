// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-BackButton-_styles__root{width:max-content;display:grid;grid-template-columns:max-content 1fr;grid-column-gap:5px;align-items:center}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-BackButton-_styles__icon{font-size:24px;color:#7E8681;line-height:0;transition:85ms ease-out;transform:rotate(180deg);cursor:pointer;user-select:none}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-BackButton-_styles__icon:hover{transform:translateX(-2px) rotate(180deg)}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-BackButton-_styles__text{font-family:'Open Sans';font-weight:600;font-size:14px;color:#000000}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/CreationForm/ParamsEditor/BackButton/styles.scss"],"names":[],"mappings":"AAAA,gIACI,iBAAkB,CAClB,YAAa,CACb,qCAAsC,CACtC,mBAAoB,CACpB,kBAAmB,CACtB,gIAGG,cAAe,CACf,aAAc,CACd,aAAc,CACd,wBAAyB,CACzB,wBAAyB,CAEzB,cAAe,CACf,gBAAiB,CARrB,sIAWQ,yCAA0C,CAC7C,gIAID,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,aAAc","sourcesContent":[".root {\n    width: max-content;\n    display: grid;\n    grid-template-columns: max-content 1fr;\n    grid-column-gap: 5px;\n    align-items: center;\n}\n\n.icon {\n    font-size: 24px;\n    color: #7E8681;\n    line-height: 0;\n    transition: 85ms ease-out;\n    transform: rotate(180deg);\n\n    cursor: pointer;\n    user-select: none;\n\n    &:hover {\n        transform: translateX(-2px) rotate(180deg);\n    }\n}\n\n.text {\n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 14px;\n    color: #000000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-BackButton-_styles__root",
	"icon": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-BackButton-_styles__icon",
	"text": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-BackButton-_styles__text"
};
export default ___CSS_LOADER_EXPORT___;
