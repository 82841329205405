// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-Table-CellTypes-IndicatorsCell-_IndicatorsCell__root{display:flex;width:100%;height:100%;align-items:center;padding-left:5px;justify-content:center;position:relative}.src-client-modules-budget-BudgetPage-BudgetExecution-Table-CellTypes-IndicatorsCell-_IndicatorsCell__paddingRightNormal{padding-right:15px}.src-client-modules-budget-BudgetPage-BudgetExecution-Table-CellTypes-IndicatorsCell-_IndicatorsCell__paddingRightExtended{padding-right:30px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/Table/CellTypes/IndicatorsCell/IndicatorsCell.scss"],"names":[],"mappings":"AAAA,2GACI,YAAa,CAEb,UAAW,CACX,WAAY,CAEZ,kBAAmB,CACnB,gBAAiB,CAEjB,sBAAuB,CAEvB,iBAAkB,CACrB,yHAGG,kBAAmB,CACtB,2HAGG,kBAAmB","sourcesContent":[".root {\n    display: flex;\n\n    width: 100%;\n    height: 100%;\n\n    align-items: center;\n    padding-left: 5px;\n\n    justify-content: center;\n\n    position: relative;\n}\n\n.paddingRightNormal {\n    padding-right: 15px;\n}\n\n.paddingRightExtended {\n    padding-right: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-Table-CellTypes-IndicatorsCell-_IndicatorsCell__root",
	"paddingRightNormal": "src-client-modules-budget-BudgetPage-BudgetExecution-Table-CellTypes-IndicatorsCell-_IndicatorsCell__paddingRightNormal",
	"paddingRightExtended": "src-client-modules-budget-BudgetPage-BudgetExecution-Table-CellTypes-IndicatorsCell-_IndicatorsCell__paddingRightExtended"
};
export default ___CSS_LOADER_EXPORT___;
