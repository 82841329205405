import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';

import { StoreState } from '@store';
import {
    SelectedCard,
    CardType,
    CardsLoadingMode,
    getRightSidebarState,
    getSelectedCard,
    getCardsLoadingModeFilter,
    setSelectedCard,
} from '@store/dashboardPage';

import { Card } from '../../types';

import { ActivityCard } from './ActivityCard';

interface Props extends Partial<MapProps & DispatchProps> {
    card: Card;
    userHasBudgetItemAccess: boolean;
    onDeleteButtonClick: (cardId: string) => void;
}

interface MapProps {
    isRequestInProgress: boolean;
    cardIsSelected: boolean;
    showDeleteButton: boolean;
}

interface DispatchProps {
    setSelectedCard: (value: SelectedCard) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class ActivityCardBehaviour extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { card, cardIsSelected, showDeleteButton, isRequestInProgress, userHasBudgetItemAccess } = this.props;

        return (
            <ActivityCard
                card={card}
                cardIsSelected={cardIsSelected}
                showDeleteButton={showDeleteButton}
                isRequestInProgress={isRequestInProgress}
                userHasBudgetItemAccess={userHasBudgetItemAccess}
                onCardClick={this.onCardClick}
                onDeleteButtonClick={this.onDeleteButtonClick}
            />
        );
    }

    @autobind
    protected onCardClick() {
        if (this.props.cardIsSelected) {
            this.props.setSelectedCard(null);
        } else {
            this.props.setSelectedCard({
                cardType: CardType.BudgetItemToProjectLinkRequest,
                id: this.props.card.id,
                activityId: this.props.card.project.id,
            });
        }
    }

    @autobind
    protected onDeleteButtonClick(event: React.MouseEvent) {
        this.props.onDeleteButtonClick(this.props.card.id);
        event.stopPropagation();
    }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
    const {
        card: { id, permissions },
    } = ownProps;
    const selectedCard = getSelectedCard(state);
    const loadingMode = getCardsLoadingModeFilter(state)[CardType.BudgetItemToProjectLinkRequest];

    const cardIsSelected = selectedCard && selectedCard.id == id;

    const { cardIdRequestInProgress } = getRightSidebarState(state, CardType.BudgetItemToProjectLinkRequest);
    const isRequestInProgress = id === cardIdRequestInProgress;

    const showDeleteButton = loadingMode === CardsLoadingMode.Current && !isRequestInProgress && permissions.delete;

    return {
        cardIsSelected,
        showDeleteButton,
        isRequestInProgress,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setSelectedCard,
        },
        dispatch,
    );
}
