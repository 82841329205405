import * as React from 'react';
import { Moment } from 'moment';

import { ActivityCard } from './ActivityCard';
import { StageName } from '../types';
import * as style from './ActivityCardList.scss';

interface Props {
    loading: boolean;
    data: Data[];
}

export interface Data {
    id: number;
    budgetItemId: string;
    productName: string;
    organizationAndBlockName: string;
    authorName: string;
    activityName: string;
    canUnbind: boolean;
    canRead: boolean;
    startDate: Moment;
    endDate: Moment;
    stage: StageName;
    onUnbindActivityFinish: () => void;
}

export const ActivityCardList: React.FC<Partial<Props>> = ({ loading, data }) => {
    return (
        <div>
            {loading && (
                <div className={style.activityCardWrapper}>
                    <ActivityCard
                        id={null}
                        budgetItemId={null}
                        productName={''}
                        organizationAndBlockName={''}
                        authorName={''}
                        activityName={''}
                        startDate={null}
                        endDate={null}
                        stage={null}
                        loading={true}
                        canUnbind={false}
                        canRead={false}
                        onUnbindActivityFinish={() => {}}
                    />
                </div>
            )}

            {!loading &&
                data.map((activity) => (
                    <div key={activity.id} className={style.activityCardWrapper}>
                        <ActivityCard {...activity} />
                    </div>
                ))}
        </div>
    );
};
