import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';

import { Utils } from '@common/Utils';
import * as styles from './styles.scss';

interface Props {
    value: string;
    disabled: boolean;
    maxValueLength: number;
    onChange: (value: string) => void;
}

type Mode = 'editing' | 'display';

export const Text: React.FC<Props> = ({ value, disabled, maxValueLength, onChange }) => {
    const [mode, setMode] = React.useState<Mode>('display');

    const onChangeButtonClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(() => {
        setMode('editing');
    }, []);

    const onInputChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
        ({ target: { value } }) => {
            onChange(value);
        },
        [onChange],
    );

    const onInputBlur: React.FocusEventHandler<HTMLInputElement> = React.useCallback(() => {
        setMode('display');
    }, []);

    const onInputKeyPress: React.KeyboardEventHandler<HTMLInputElement> = React.useCallback(({ key }) => {
        if (key === 'Enter') {
            setMode('display');
        }
    }, []);

    return (
        <div className={classNames(styles.root, disabled ? styles.disabled : '')}>
            <div
                className={classNames(styles.button, disabled ? styles.buttonDisabled : '')}
                onClick={disabled ? () => {} : onChangeButtonClick}
            >
                <Icon type={IconType.PROJECT_STAGES_EDIT_ICON} />
            </div>
            <div>
                {mode === 'editing' ? (
                    <input
                        className={styles.input}
                        type="text"
                        value={value}
                        maxLength={maxValueLength}
                        autoFocus
                        onChange={onInputChange}
                        onBlur={onInputBlur}
                        onKeyPress={onInputKeyPress}
                    />
                ) : (
                    <div className={styles.text}>{value}</div>
                )}
                <div className={styles.count}>
                    {value?.length || 0}{' '}
                    {Utils.getDeclensionByNumber(value?.length || 0, ['символ', 'символа', 'символов'])}
                </div>
            </div>
        </div>
    );
};
