import * as React from 'react';
import { CorrectionStatus, CorrectionType } from '@mrm/budget';

import {
    CommonFondsChange,
    CorrectionCardHeader,
    CorrectionCardTable,
    CorrectionCardCellHeader,
    CorrectionCardCellContent,
    SubPramList,
    CorrectionCardComment,
    CorrectionBody,
    RejectComment,
} from '../common';

import * as style from './IncomeExternalCorrectionCard.scss';

export interface CorrectionData {
    dictionaryItem: string;
    comment: string;
}

interface PlanData {
    delta: number;
    beforeSumFonds: number;
}

export interface ParticipatorData {
    monthFond: {
        month: string;
        delta: number;
        beforeFond: number;
        afterFond: number;
    };
    allFond: {
        delta: number;
        beforeFond: number;
    };
    name: string;
    fields: ParticipatorField[];
    comment: string;
}

export interface ParticipatorField {
    name: string;
    value: React.ReactText;
    isWide?: boolean;
    title?: string;
}

interface Props {
    serialNumber: number;
    authorName: string;
    approverName: string;
    userIsBudgetExpert: boolean;
    userIsAuthorCorrection: boolean;
    creationDate: string;
    acceptorData: ParticipatorData;
    correctionData: CorrectionData;
    status: CorrectionStatus;
    url: string;
    rejectComment: string;
    onStatusChange: (status: CorrectionStatus, comment?: string) => void;
}

export const IncomeExternalCorrectionCard = ({
    serialNumber,
    authorName,
    approverName,
    userIsBudgetExpert,
    userIsAuthorCorrection,
    creationDate,
    correctionData,
    acceptorData,
    status,
    url,
    rejectComment,
    onStatusChange,
}: Props): JSX.Element => {
    return (
        !!acceptorData && (
            <CorrectionBody
                serialNumber={serialNumber}
                status={status}
                type={CorrectionType.IncomeExternalPlanFundsTransfer}
            >
                <CorrectionCardHeader
                    title={'Внешняя корректировка плана'}
                    serialNumber={serialNumber}
                    authorName={authorName}
                    approverName={approverName}
                    creationDate={creationDate}
                    url={url}
                    status={status}
                    userIsAuthorCorrection={userIsAuthorCorrection}
                    userIsBudgetExpert={userIsBudgetExpert}
                    onStatusChange={onStatusChange}
                />

                {rejectComment && <RejectComment text={rejectComment} />}

                <CorrectionCardTable>
                    <CorrectionCardCellHeader>
                        <MonthPlanSubInfo
                            name={acceptorData.name}
                            month={acceptorData.monthFond.month}
                            beforeFonds={acceptorData.monthFond.beforeFond}
                            delta={acceptorData.monthFond.delta}
                        />
                    </CorrectionCardCellHeader>
                    <CorrectionCardCellHeader>
                        <PlanSubInfo
                            beforeSumFonds={acceptorData.allFond.beforeFond}
                            delta={acceptorData.allFond.delta}
                        />
                    </CorrectionCardCellHeader>

                    <CorrectionCardCellContent>
                        <div className={style.subInfoContent}>
                            <SubPramList
                                supParams={[
                                    {
                                        name: 'Статья источника',
                                        value: correctionData.dictionaryItem,
                                        isWide: true,
                                    },
                                ]}
                            />
                            <SubPramList
                                supParams={[
                                    {
                                        name: 'Комментарий к корректировке',
                                        value: <CorrectionCardComment text={correctionData.comment} />,
                                        isWide: true,
                                        notCropContent: true,
                                    },
                                ]}
                            />
                        </div>
                    </CorrectionCardCellContent>
                    <CorrectionCardCellContent>
                        <div className={style.subInfoContent}>
                            <SubPramList supParams={acceptorData.fields} />
                            {acceptorData.comment && (
                                <SubPramList
                                    supParams={[
                                        {
                                            name: 'Комментарий к строке',
                                            value: <CorrectionCardComment text={acceptorData.comment} />,
                                            isWide: true,
                                            notCropContent: true,
                                        },
                                    ]}
                                />
                            )}
                        </div>
                    </CorrectionCardCellContent>
                </CorrectionCardTable>
            </CorrectionBody>
        )
    );
};

interface MonthPlanSubInfoProps {
    name: string;
    month: string;
    beforeFonds: number;
    delta: number;
}

const MonthPlanSubInfo: React.FC<MonthPlanSubInfoProps> = (data) => (
    <div className={style.subInfo}>
        <div className={style.subInfoTitle}>{data.name}</div>

        <CommonFondsChange title={'Бюджет'} month={data.month} before={data.beforeFonds} delta={data.delta} />
    </div>
);

const PlanSubInfo: React.FC<PlanData> = (data) => (
    <div className={style.subInfo}>
        <div className={style.сommonFondsChangeWrapper}>
            <CommonFondsChange title={'Плановый бюджет по строке'} before={data.beforeSumFonds} delta={data.delta} />
        </div>
    </div>
);
