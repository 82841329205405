// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-task-components-tags-TaskDescription-_TaskDescription__onlyRead{white-space:pre-wrap}\n", "",{"version":3,"sources":["webpack://./src/client/modules/task/components/tags/TaskDescription/TaskDescription.scss"],"names":[],"mappings":"AAIA,oFACE,oBAAqB","sourcesContent":[".root {\n\n}\n\n.onlyRead {\n  white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"onlyRead": "src-client-modules-task-components-tags-TaskDescription-_TaskDescription__onlyRead"
};
export default ___CSS_LOADER_EXPORT___;
