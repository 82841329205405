import * as React from 'react';
import { graphql, compose } from 'react-apollo';
import autobind from 'autobind-decorator';

import { LinkRequest } from '../WithLinkRequests';

import { CREATE_LINK_MUTATION, REMOVE_LINK_MUTATION } from './query';

export interface ChildrenProps {
    loading: boolean;
    triggerLinksUpdate: () => Promise<void>;
}

interface Props extends ExternalProps, CreateLinkMutationProps, RemoveLinkMutationProps {}

interface ExternalProps {
    budgetId: string;
    activityId: number;
    expertId: number;
    existingLinks: LinkRequest[];
    selectedBudgetItemIds: string[];
    refetchLinks: () => void;
    onBudgetItemAdded: (budgetItemId: string) => Promise<void>;
    onBudgetItemRemoved: (budgetItemId: string) => Promise<void>;
    children: (props: ChildrenProps) => JSX.Element;
}

interface CreateLinkMutationProps {
    createLinkMutation: (params: {
        variables: {
            projectId: number;
            budgetItemId: string;
            approverId: number;
        };
    }) => Promise<void>;
    createLinkMutationResult: {
        loading: boolean;
    };
}

interface RemoveLinkMutationProps {
    removeLinkMutation: (params: {
        variables: {
            projectId: number;
            budgetItemId: string;
        };
    }) => Promise<void>;
    removeLinkMutationResult: {
        loading: boolean;
    };
}

class WithUpdateLinksMutationConnected extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { children, createLinkMutationResult, removeLinkMutationResult } = this.props;

        const loading = createLinkMutationResult.loading || removeLinkMutationResult.loading;

        return children({
            loading,
            triggerLinksUpdate: this.triggerLinksUpdate,
        });
    }

    @autobind
    private async triggerLinksUpdate(): Promise<void> {
        const {
            budgetId,
            activityId,
            expertId,
            selectedBudgetItemIds,
            existingLinks,
            createLinkMutation,
            onBudgetItemAdded,
            onBudgetItemRemoved,
            removeLinkMutation,
            refetchLinks,
        } = this.props;

        const existingLinksOfSelectedYear = existingLinks.filter((item) => item.budgetItem.budget.id === budgetId);

        const existingLinksBudgetItemIds = existingLinksOfSelectedYear.map((link) => link.budgetItem.id);

        const linksToCreate = selectedBudgetItemIds.filter((id) => !existingLinksBudgetItemIds.includes(id));

        const linksToRemove = existingLinksOfSelectedYear.filter(
            (link) => !selectedBudgetItemIds.includes(link.budgetItem.id),
        );

        await Promise.all([
            ...linksToCreate.map((budgetItemId) =>
                createLinkMutation({
                    variables: {
                        projectId: activityId,
                        budgetItemId,
                        approverId: expertId,
                    },
                }),
            ),
            ...linksToRemove.map((link) =>
                removeLinkMutation({
                    variables: {
                        projectId: activityId,
                        budgetItemId: link.budgetItem.id,
                    },
                }),
            ),
        ]);

        await Promise.all([
            ...linksToCreate.map((budgetItemId) => onBudgetItemAdded(budgetItemId)),
            ...linksToRemove.map((link) => onBudgetItemRemoved(link.budgetItem.id)),
        ]);

        refetchLinks();
    }
}

export const WithUpdateLinksMutation = compose(
    graphql<CreateLinkMutationProps>(CREATE_LINK_MUTATION, { name: 'createLinkMutation' }),
    graphql<RemoveLinkMutationProps>(REMOVE_LINK_MUTATION, { name: 'removeLinkMutation' }),
)(WithUpdateLinksMutationConnected) as React.ComponentType<ExternalProps>;
