import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { UserConfigType } from 'sber-marketing-types/openid';
import { compact, uniqBy, uniq } from 'lodash';
import { CorrectionListQueryParams, CorrectionStatus, CorrectionType, BudgetStatus } from '@mrm/budget';

import { BudgetCorrectionApi, UserApi, DictionaryApi } from '@api';

import { StoreState } from '@store';
import { getBudgetState } from '@store/budgetPage';
import { getBudgetByStatusUserConfig } from '@store/userConfig/budget';
import { saveUserConfig as generalSaveUserConfig } from '@store/userConfig';
import { loadPageData as loadBudgetCorrectionsPageData } from '@store/budgetCorrections';

import { DatesFormatter, Utils } from '@common/Utils';

import * as asyncActions from './actions/async';
import * as syncActions from './actions/sync';

import {
    GraphqlFilterValues,
    TargetFilterItems,
    DownloadCorrectionsPayload,
    ExportInProgressState,
    TargetFilter,
    CorrectionTypeIds,
    CorrectionTypeNames,
    CorrectionStatusNames,
} from './types';
import { getBudgetCorrectionsFiltersAndExportState } from './selectors';

// located here becausse store/budgetCorrections doesn't have any other thunks
export const loadPageData = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.loadPageData,
    async (_, dispatch, getState) => {
        const state = getState();
        const { budgetId } = getBudgetByStatusUserConfig(state, BudgetStatus.Execution);
        const { budgets } = getBudgetState(state, BudgetStatus.Execution);
        const budget = budgets.find((budget) => budget.id === budgetId);

        const [users, dictionaries] = await Promise.all([
            UserApi.getFullUserList(),
            DictionaryApi.getDictionaryList({ organizationId: budget.dictionaryOrganizationId, treeview: true }),
        ]);

        dispatch(loadBudgetCorrectionsPageData({ budget, users, dictionaries }));
    },
);

export const saveUserConfig = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.updateUserConfig,
    async (_, dispatch, getState) => {
        const { dateRangeStart, dateRangeEnd, isExportModeEnabled, ...userConfig } =
            getBudgetCorrectionsFiltersAndExportState(getState()).currentFilters;

        dispatch(
            generalSaveUserConfig({
                type: UserConfigType.BudgetCorrections,
                payload: userConfig,
            }),
        );
    },
);

export const loadFilterItems = bindThunkAction<StoreState, GraphqlFilterValues, void, Error>(
    asyncActions.loadFilterItems,
    async (filters, dispatch, getState) => {
        const {
            status,
            directions,
            types,
            activityNames,
            budgetItemNames,
            author,
            approver,
            blocks,
            activityTypes,
            tools,
            executionIds,
            regionalities,
            territories,
            divisionIds,
            costCenterIds,
        } = filters;

        function mapStatusFilterItem(filterValue: any) {
            return {
                id: filterValue,
                title: CorrectionStatusNames[filterValue] || filterValue,
            };
        }

        function mapUserFilterItem(filterValue: any) {
            return {
                id: filterValue.id,
                title: `${filterValue.secondName} ${filterValue.firstName}`,
            };
        }

        function mapTypeFilterItem(filterValue: any) {
            const id = CorrectionTypeIds[filterValue];

            return {
                id,
                title: CorrectionTypeNames[id],
            };
        }

        function mapDictionaryItem(filterValue: any): any {
            const dictionary = JSON.parse(filterValue);

            return {
                id: dictionary?.id,
                title: dictionary?.value || 'Не найдено',
                description: dictionary?.code,
            };
        }

        function mapDefaultFilterItem(filterValue: any) {
            return {
                id: filterValue,
                title: String(filterValue),
            };
        }

        dispatch(
            syncActions.setFilterItems({
                [TargetFilter.Status]: makeFilterParams(status, mapStatusFilterItem),
                [TargetFilter.Directions]: makeFilterParams(directions, mapDefaultFilterItem),
                [TargetFilter.Types]: makeFilterParams(types, mapTypeFilterItem),
                [TargetFilter.Authors]: makeFilterParams(author, mapUserFilterItem),
                [TargetFilter.Approvers]: makeFilterParams(approver, mapUserFilterItem),
                [TargetFilter.ActivityNames]: makeFilterParams(activityNames, mapDefaultFilterItem),
                [TargetFilter.BudgetItemNames]: makeFilterParams(budgetItemNames, mapDefaultFilterItem),
                [TargetFilter.Blocks]: makeFilterParams(blocks, mapDefaultFilterItem),
                [TargetFilter.ActivityTypes]: makeFilterParams(activityTypes, mapDefaultFilterItem),
                [TargetFilter.Tools]: makeFilterParams(tools, mapDefaultFilterItem),
                [TargetFilter.ExecutionIds]: makeFilterParams(executionIds, mapDefaultFilterItem),
                [TargetFilter.Regionalities]: makeFilterParams(regionalities, mapDefaultFilterItem),
                [TargetFilter.Territories]: makeFilterParams(territories, mapDefaultFilterItem),
                [TargetFilter.Divisions]: makeFilterParams(divisionIds, mapDictionaryItem),
                [TargetFilter.CostCenter]: makeFilterParams(costCenterIds, mapDictionaryItem),
            }),
        );
    },
);

function makeFilterParams(
    filterValues: any[],
    toItemTransformer: (filterValue: any) => { id: string; title: string },
): TargetFilterItems {
    return uniqBy(compact(filterValues).map(toItemTransformer), (item) => item.id);
}

export const downloadCorrections = bindThunkAction<StoreState, DownloadCorrectionsPayload, void, Error>(
    asyncActions.downloadCorrections,
    async (params, dispatch, getState) => {
        const state = getState();
        const filters = prepareFiltersForUpload(state, params);
        const title = makeXLSXDocumentTitle(state, params);

        dispatch(
            syncActions.setExportInProgressState(
                params.useFilters ? ExportInProgressState.WithFilters : ExportInProgressState.WithoutFilters,
            ),
        );

        const correctionsData = await BudgetCorrectionApi.getCorrectionDataForXLS(filters);
        const data = { Корректировки: correctionsData };
        const xlsxContent: Buffer = await BudgetCorrectionApi.getDataAsXLSXV2(data as any);

        Utils.downloadAsXLSX(xlsxContent, title);

        dispatch(syncActions.setExportInProgressState(ExportInProgressState.None));
    },
);

function prepareFiltersForUpload(state: StoreState, params: DownloadCorrectionsPayload): CorrectionListQueryParams {
    const { budgetId } = getBudgetByStatusUserConfig(state, BudgetStatus.Execution);
    const { dateRangeStart, dateRangeEnd } = getBudgetCorrectionsFiltersAndExportState(state).currentFilters;

    const result: CorrectionListQueryParams = {
        budgetId,
        filter: {
            status: CorrectionStatus.Approved,
        },
        sort: [{ field: 'creationTime', order: 'DESC' }],
    };

    if (params.useFilters) {
        result.filter.id = params.allCorrectionsIds;

        if (dateRangeStart && dateRangeEnd) {
            result.dateRange = [dateRangeStart, dateRangeEnd];
        }
    } else {
        result.filter.type = CorrectionType.PlanFundsTransfer;
    }

    return result;
}

function makeXLSXDocumentTitle(state: StoreState, params: DownloadCorrectionsPayload): string {
    const useDateRange = params.useFilters;
    const { dateRangeStart, dateRangeEnd, type } = getBudgetCorrectionsFiltersAndExportState(state).currentFilters;

    const date = DatesFormatter.ddMonthyy(new Date());

    let range = '';
    if (useDateRange && dateRangeStart && dateRangeEnd) {
        range = ` (в период с ${DatesFormatter.ddMonthyy(dateRangeEnd)} по ${DatesFormatter.ddMonthyy(dateRangeEnd)})`;
    }

    const typeDesc = type.length ? ` (${uniq(type.map((type) => CorrectionTypeNames[type])).join(', ')})` : '';

    return `Корректировки ${typeDesc} от ${date}${range}`;
}
