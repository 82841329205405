import * as React from 'react';

import { getMonthName, orderOfMonth } from '../utils';
import { CardBudgetItem } from '../types';

import { TableWithoutCorrection, FundRow } from './TableWithoutCorrection';

interface Props {
    budgetItem: CardBudgetItem;
}

export class TableWithoutCorrectionContainer extends React.Component<Props> {
    public render() {
        return React.createElement(TableWithoutCorrection, {
            fundsRows: this.buildFoundsRows(),
        });
    }

    private buildFoundsRows(): FundRow[] {
        const { plannedFunds, reservedFunds, factFunds } = this.props.budgetItem;

        return orderOfMonth.map((month) => ({
            name: getMonthName(month),
            plan: plannedFunds[month] || 0,
            reserve: reservedFunds[month] || 0,
            fact: factFunds[month] || 0,
        }));
    }
}
