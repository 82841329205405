import * as React from 'react';
import { graphql } from 'react-apollo';
import autobind from 'autobind-decorator';

import { DELETE_REQUEST_MUTATION } from './graphqlQueries';

interface Props extends QueryProps, ExternalProps {}

interface ExternalProps {
    children?: (params: RenderParams) => React.ReactNode | React.ReactNode[];
}

type MutationFunc<V> = (params: { variables: V }) => Promise<void>;
type MutationResult = {
    loading: boolean;
};

interface QueryProps {
    deleteRequestMutation: MutationFunc<{ requestId: string }>;
    deleteRequestMutationResult: MutationResult;
}

interface RenderParams {
    loading: boolean;
    deleteRequest: (requestId: string) => Promise<void>;
}

class RequestsQuery extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render() {
        const loading = this.props.deleteRequestMutationResult.loading;

        return this.props.children({
            loading,
            deleteRequest: this.deleteRequest,
        });
    }

    @autobind
    private async deleteRequest(requestId: string): Promise<void> {
        await this.permormRequestMutation(this.props.deleteRequestMutation, requestId);
    }

    @autobind
    private async permormRequestMutation(
        mutation: MutationFunc<{ requestId: string }>,
        requestId: string,
    ): Promise<void> {
        await mutation({
            variables: {
                requestId,
            },
        });
    }
}

export const WithRequestsQuery = graphql<ExternalProps>(DELETE_REQUEST_MUTATION, {
    name: 'deleteRequestMutation',
})(RequestsQuery) as React.ComponentType<ExternalProps>;
