import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconType } from 'sber-marketing-ui';

import * as styles from './Item.scss';

export interface Props {
    title: string;
    department?: string;
    isSelected: boolean;
    canBeUnselected: boolean;
    onClick: () => void;
}

export function Item({ title, department, isSelected, canBeUnselected, onClick }: Props): JSX.Element {
    const isButtonEnabled = !isSelected || canBeUnselected;
    const iconType = isSelected ? IconType.TASK_PARTICIPATOR_ADDED : IconType.TASK_PARTICIPATOR_ADD;
    const iconColor = isSelected && !canBeUnselected ? 'orange' : 'green';

    return (
        <div
            className={classNames(styles.root, !isButtonEnabled && styles.root_nonClickable)}
            onClick={isButtonEnabled ? onClick : null}
            {...{
                'qa-id': 'userSelectSuggestItem',
                'qa-label': title,
            }}
        >
            <div className={styles.row}>{title}</div>

            {department && (
                <div className={classNames(styles.row, styles.description, styles.department)}>{department}</div>
            )}

            <div className={classNames(styles.icon, !isSelected && styles.icon_hideOnUnhover)}>
                <Icon type={iconType} color={iconColor} svgSize={13} />
            </div>
        </div>
    );
}
