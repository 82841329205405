import * as React from 'react';
import { useDispatch } from 'react-redux';

import { addFeatures } from '@store/autopilot/generationTextForm';

import * as styles from './styles.scss';

interface Props {}

export const AddButton: React.FC<Props> = () => {
    const dispatch = useDispatch();

    const onClick = React.useCallback(() => {
        dispatch(addFeatures());
    }, []);

    return (
        <div className={styles.root} onClick={onClick}>
            + Добавить особенность
        </div>
    );
};
