import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { AxiosError } from 'axios';

import { StoreState } from '../..';
import { RemoveFileFromCommentaryPayload, FetchError } from '../types';
import { asyncActions } from '../actions';
import { getTaskInfo } from '../selectors';
import { runDeleteOne, getFileAssetByName } from '../../fileAssets';

export const removeFileFromCommentary = bindThunkAction<StoreState, RemoveFileFromCommentaryPayload, void, FetchError>(
    asyncActions.removeFileFromCommentary,
    async ({ fileName, commentaryId }: RemoveFileFromCommentaryPayload, dispatch, getState): Promise<void> => {
        const { id: taskId } = getTaskInfo(getState());
        try {
            await runDeleteOne({ taskId, commentId: commentaryId }, getState().fileAssets.assets[fileName], dispatch);
        } catch (error) {
            const { response, message } = <AxiosError>error;
            if (response) {
                throw new FetchError(response.status, response.statusText, message);
            } else {
                throw new FetchError(0, 'Unknown', message);
            }
        }
    },
);
