import * as React from 'react';
import * as lodash from 'lodash';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { CorrectionType, BudgetItem } from '@mrm/budget';

import { StoreState } from '@store';
import {
    PageData,
    GroupedDicitonaries,
    TableLine,
    ColumnName,
    SortingMode,
    ChangeList,
    GroupedCorrections,
    SelectableCellsList,
    UserRole,
    TableLinesCellsParams,
    getBudgetExecutionPageState,
    getTableFilters,
    getPageData,
    getFilteredTableLines,
    getUnsavedChanges,
    getSelectableCellsList,
    setTableLinesCellsParams,
    DropdownOptions,
} from '@store/budgetExecution';
import { BudgetTransferMenuState, getBudgetTransferMenuState } from '@store/budgetExecution/budgetTransferMenu';
import { getMiscBudgetItemsState } from '@store/budgetExecution/miscBudgetItems';

import { LayerManager } from '../LayerManager';

interface Props extends Partial<MapProps & DispatchProps> {}

interface MapProps {
    pageData: PageData;
    userRole: UserRole;
    allLines: TableLine[];
    lines: TableLine[];
    validationStatus: boolean;
    resizingColumnName: ColumnName;
    hoveredColumnName: ColumnName;
    sortingMode: SortingMode;
    unsavedChanges: ChangeList;
    groupedDictionaries: GroupedDicitonaries;
    dropdownOptions: DropdownOptions;
    activityCorrections: GroupedCorrections<CorrectionType.Activity>;
    budgetItemCorrections: GroupedCorrections<CorrectionType.BudgetItem>;
    planCorrections: GroupedCorrections<CorrectionType.PlanFundsTransfer>;
    reserveCorrections: GroupedCorrections<CorrectionType.ReservedFunds>;
    incomeExternalPlanCorrections: GroupedCorrections<CorrectionType.IncomeExternalPlanFundsTransfer>;
    outcomeExternalPlanCorrections: GroupedCorrections<CorrectionType.OutcomeExternalPlanFundsTransfer>;
    transitionData: BudgetTransferMenuState;
    selectableCells: SelectableCellsList;
    budgetItemsByActivityId: lodash.Dictionary<BudgetItem[]>;
    useLinesWithoutPlanInSorting: boolean;
}

interface DispatchProps {
    setTableLinesCellsParams: (payload: TableLinesCellsParams) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class CellsParamsEffect extends React.PureComponent<Props> {
    private layerManager: LayerManager;

    public componentDidMount() {
        this.createLayerManager();
        this.updateCellsParams();
    }

    public componentDidUpdate(prevProps: Props): void {
        const pageDataChanged = prevProps.pageData !== this.props.pageData;
        const validationStatusChanged = prevProps.validationStatus !== this.props.validationStatus;
        const linesChanged = !lodash.isEqual(this.props.allLines, prevProps.allLines);
        const budgetItemsByActivityIdhaveChanged = !lodash.isEqual(
            prevProps.budgetItemsByActivityId,
            this.props.budgetItemsByActivityId,
        );

        if (pageDataChanged || linesChanged || validationStatusChanged || budgetItemsByActivityIdhaveChanged) {
            this.createLayerManager();
            this.updateCellsParams();
        } else {
            const unsavedChangesChanged = prevProps.unsavedChanges !== this.props.unsavedChanges;
            const dropdownsOptionsChanged = prevProps.dropdownOptions !== this.props.dropdownOptions;
            const transitionDataChanged = prevProps.transitionData !== this.props.transitionData;
            const selectableCellsChanged = !lodash.isEqual(prevProps.selectableCells, this.props.selectableCells);

            if (unsavedChangesChanged) {
                this.layerManager.applyUnsavedChanges(this.props.unsavedChanges);
            }

            if (dropdownsOptionsChanged) {
                this.layerManager.updateDropdownsOptions(this.props.dropdownOptions);
            }

            if (transitionDataChanged) {
                this.layerManager.updateTransitionDataState(this.props.transitionData);
            }

            if (selectableCellsChanged) {
                this.layerManager.updateSelectableCellsList(this.props.selectableCells);
            }

            if (unsavedChangesChanged || dropdownsOptionsChanged || transitionDataChanged || selectableCellsChanged) {
                this.updateCellsParams();
            }
        }
    }

    public render(): JSX.Element {
        // component used only for side-effect
        return null;
    }

    private createLayerManager() {
        this.layerManager = LayerManager.getInstance({
            lines: this.props.allLines,
            dropdownsOptions: this.props.dropdownOptions,
            unsavedChanges: this.props.unsavedChanges,
            userRole: this.props.userRole,
            dictionaries: this.props.groupedDictionaries.byId,
            activityCorrections: this.props.activityCorrections,
            budgetItemCorrections: this.props.budgetItemCorrections,
            planCorrections: this.props.planCorrections,
            reserveCorrections: this.props.reserveCorrections,
            incomeExternalPlanCorrections: this.props.incomeExternalPlanCorrections,
            outcomeExternalPlanCorrections: this.props.outcomeExternalPlanCorrections,
            transitionData: this.props.transitionData,
            selectableCells: this.props.selectableCells,
            validationStatus: this.props.validationStatus,
            budgetItemsByActivityId: this.props.budgetItemsByActivityId,
        });
    }

    private updateCellsParams() {
        const updTalbeLinesCellsParams = this.layerManager.getCellsParams();
        this.props.setTableLinesCellsParams({ ...updTalbeLinesCellsParams });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const {
        resizingColumnName,
        hoveredColumnName,
        pageData: {
            userDictionaries,
            corrections: {
                activityCorrections,
                budgetItemCorrections,
                planCorrections,
                reserveCorrections,
                incomeExternalPlanCorrections,
                outcomeExternalPlanCorrections,
            },
            userRole,
        },
        computedData: { tableLines, dropdownOptions },
    } = getBudgetExecutionPageState(state);
    const { sortingMode, validationStatus, useLinesWithoutPlanInSorting } = getTableFilters(state);

    return {
        pageData: getPageData(state),
        userRole,
        allLines: tableLines,
        lines: getFilteredTableLines(state),
        validationStatus,
        resizingColumnName,
        hoveredColumnName,
        sortingMode,
        unsavedChanges: getUnsavedChanges(state),
        groupedDictionaries: userDictionaries,
        dropdownOptions,
        activityCorrections,
        budgetItemCorrections,
        planCorrections,
        reserveCorrections,
        incomeExternalPlanCorrections,
        outcomeExternalPlanCorrections,
        transitionData: getBudgetTransferMenuState(state),
        selectableCells: getSelectableCellsList(state),
        budgetItemsByActivityId: getMiscBudgetItemsState(state).stores.byActivityId,
        useLinesWithoutPlanInSorting,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        setTableLinesCellsParams: (payload: TableLinesCellsParams) => dispatch(setTableLinesCellsParams(payload)),
    };
}
