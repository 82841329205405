import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { MentionItem, AnimatedEllipsis } from 'sber-marketing-ui';

import { FileAsset } from '@store/commonTypes';

import { getSavingComment, getSendingComment } from '@store/taskPage/selectors';
import { LexicalTextarea } from '@common/LexicalTextarea';
import { AddAsset } from '../AddAsset';
import { CommentAssets, useCommentAssetsOpen } from '../CommentAssets';

import * as styles from './CommentEditable.scss';

const BUTTON_LABEL = 'Отправить';
const PLACEHOLDER = 'Добавьте комментарий или перетащите файл';

export interface Props {
    useR7Controls: boolean;
    taskId?: string;
    hasAssets: boolean;
    commentId?: string;
    assetsIds?: string[];
    hideAssetDate?: boolean;
    assetIsDeletable?: boolean;
    disableAssetDownload?: boolean;
    shortLexical?: boolean;
    showAssets?: boolean;
    message?: string;
    headerHeight?: number;
    className?: string;
    errorMessage?: string;
    displayError?: boolean;
    mentionableUsers?: MentionItem[];
    showAssetsByDefault?: boolean;
    userIsCommentAuthor: boolean;
    onAssetRemoveClick: (data: FileAsset) => void;
    onFocus?(): void | Promise<void>;
    onBlur?(): void | Promise<void>;
    onChange(message: string): void | Promise<void>;
    onAddAsset(fileData: FileList): void | Promise<void>;
    onSubmit(): void | Promise<void>;
}

export function CommentEditable({
    useR7Controls,
    taskId,
    hasAssets,
    commentId,
    assetsIds,
    hideAssetDate,
    assetIsDeletable,
    disableAssetDownload,
    shortLexical,
    message,
    onChange,
    onAddAsset,
    onSubmit,
    errorMessage,
    displayError,
    mentionableUsers = [],
    onAssetRemoveClick,
    className,
    showAssetsByDefault,
    userIsCommentAuthor,
    onFocus,
    onBlur,
}: Props): JSX.Element {
    // for some reason, after value change LabeledTextarea wrapped with MentionMenu rerenders with old props
    // (it's possible because onChange triggers async store update)
    // this fix ensures that LabeledTextarea always has actual message value
    const [value, setValue] = React.useState(message);
    const [, setIsOpenedAssets] = useCommentAssetsOpen();
    const sending = useSelector(getSendingComment);
    const savingComment = useSelector(getSavingComment);
    const loading = commentId ? savingComment === commentId : sending;

    const rootClassName = classNames(
        styles.root,
        className,
        displayError && styles.hasError,
        loading && styles.sending,
    );

    React.useEffect(() => setValue(message), [message]);

    const handleAddAsset = (fileData: FileList) => {
        onAddAsset(fileData);
        setIsOpenedAssets(true);
    };

    const onPaste: React.ClipboardEventHandler<HTMLTextAreaElement> = (e) => {
        const { files } = e.clipboardData;

        if (files.length) {
            e.preventDefault();
            handleAddAsset(files);
        }
    };

    const onDrop: React.DragEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleAddAsset(e.dataTransfer.files);
            e.dataTransfer.clearData();
        }
    };

    const onDragOver: React.DragEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    function onChangeHandler(value: string) {
        setValue(value);
        onChange(value);
    }

    return (
        <div
            onDrop={onDrop}
            onDragOver={onDragOver}
            className={rootClassName}
            {...{
                'qa-id': 'taskCommentForm',
            }}
        >
            <div className={styles.form}>
                <div className={styles.textAreaWrap}>
                    <LexicalTextarea
                        qaId="taskCommentFormTextarea"
                        onFocus={onFocus}
                        onBlur={onBlur}
                        value={value}
                        onPaste={onPaste}
                        onValueChange={onChangeHandler}
                        title={PLACEHOLDER}
                        errorMessage={displayError ? errorMessage : undefined}
                        displayError={displayError}
                        focusOnValueChange
                        mentions={mentionableUsers}
                        shortLexical={shortLexical}
                    />
                </div>
                <div
                    className={styles.button}
                    onClick={onSubmit}
                    {...{
                        'qa-id': 'taskCommentFormSaveButton',
                    }}
                >
                    <div className={styles.buttonLabel}>
                        {loading ? <AnimatedEllipsis text={BUTTON_LABEL} /> : BUTTON_LABEL}
                    </div>
                </div>
                <AddAsset className={styles.addAssetLexical} onFileAdd={handleAddAsset} />
            </div>

            {hasAssets && (
                <CommentAssets
                    useR7Controls={useR7Controls}
                    userIsCommentAuthor={userIsCommentAuthor}
                    taskId={taskId}
                    commentId={commentId}
                    assetsIds={assetsIds}
                    hideAssetDate={hideAssetDate}
                    assetIsDeletable={assetIsDeletable}
                    disableAssetDownload={disableAssetDownload}
                    className={styles.assets}
                    showByDefault={showAssetsByDefault}
                    onAssetRemoveClick={onAssetRemoveClick}
                />
            )}
        </div>
    );
}
