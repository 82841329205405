import { DatesFormatter } from '@common/Utils';

import { DifferenceTransformer, DifferencesTitles } from './types';

function transformer(date: string): string {
    return date ? DatesFormatter.ddMonthyyyy(date) : null;
}

export const datesDifference: DifferenceTransformer = function (prev, curr, item) {
    return [
        {
            title: DifferencesTitles[item],
            before: transformer(prev[item]),
            after: transformer(curr[item]),
        },
    ];
};
