import * as React from 'react';
import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import { isLoggedUserBudgetExpert } from '@store/user';

import { WithRequestInProgressMask } from '../WithRequestInProgressMask';
import { ExpertSelect } from './ExpertSelect';

import * as styles from './WithExpertSelect.scss';

interface Props {
    children: JSX.Element;
}

export function WithExpertSelect(props: Props): JSX.Element {
    const userIsBudgetExpert = useSelector((state: StoreState) => isLoggedUserBudgetExpert(state));

    return (
        <div className={styles.root}>
            {userIsBudgetExpert ? <ExpertContent {...props} /> : <NonExpertContent {...props} />}
        </div>
    );
}

function ExpertContent({ children }: Props): JSX.Element {
    return <div className={styles.singleColumn}>{children}</div>;
}

function NonExpertContent({ children }: Props): JSX.Element {
    return (
        <React.Fragment>
            <div className={styles.halfColumn}>
                <WithRequestInProgressMask>
                    <ExpertSelect />
                </WithRequestInProgressMask>
            </div>

            <div className={styles.halfColumn}>{children}</div>
        </React.Fragment>
    );
}
