import * as React from 'react';
import { Icon, IconType } from 'sber-marketing-ui';

import { CardType } from '@store/dashboardPage';

import { BudgetItemToProjectCard } from './Content';

import * as style from './RightSidebar.scss';

interface Props {
    selectedCardType: React.ReactText;
    updateCards: () => Promise<void>;
}

export function RightSidebar({ selectedCardType, updateCards }: Props): JSX.Element {
    return (
        <div className={style.root}>
            {selectedCardType === null && <NoCardSelectionMessage />}

            {selectedCardType == CardType.BudgetItemToProjectLinkRequest && (
                <BudgetItemToProjectCard updateCards={updateCards} />
            )}
        </div>
    );
}

function NoCardSelectionMessage() {
    return (
        <div className={style.noCardSelection}>
            <div className={style.noCardMessage}>
                <div className={style.noCardMessageIcon}>
                    <Icon type={IconType.PIN} svgSize={35} />
                </div>

                <div className={style.noCardMessageTitle}>Дополнительная информация</div>

                <div className={style.noCardMessageText}>Выберите карточку, чтобы посмотреть подробную информацию</div>
            </div>
        </div>
    );
}
