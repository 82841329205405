import * as React from 'react';

import * as style from './FinalStageWidget.scss';

import { BudgetStageWidget, BriefStageWidget, MediaplanStageWidget, CreativeStageWidget } from '..';

interface Props {}

export const FinalStageWidgetTemplate = ({}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.stageWidget}>
                <BudgetStageWidget readOnly />
            </div>

            <div className={style.stageWidget}>
                <BriefStageWidget readOnly />
            </div>

            <div className={style.stageWidget}>
                <MediaplanStageWidget readOnly />
            </div>

            <div className={style.stageWidget}>
                <CreativeStageWidget readOnly />
            </div>
        </div>
    );
};
