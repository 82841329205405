import * as React from 'react';
import { Icon, IconType } from 'sber-marketing-ui';

import * as styles from './FileCard.scss';

import { FileAsset } from '@common/FileAssetRedesign';
// import { Skeleton } from '@common/Skeleton';
import { Utils } from '@common/Utils';

export interface FileProps {
    id: string;
    name: string;
    originName: string;
    size: number;
    type: string;
}

interface Props extends Partial<FileProps> {
    loading?: boolean;
    showDeleteButton: boolean;
    showDownloadButton: boolean;
    onCardMouseEnter: () => void;
    onCardMouseLeave: () => void;
    onDeleteButtonClick: () => void;
}

export function FileCard({
    loading = false,
    id,
    name,
    originName,
    size,
    type,
    showDeleteButton,
    showDownloadButton,
    onCardMouseEnter,
    onCardMouseLeave,
    onDeleteButtonClick,
}: Props): JSX.Element {
    return (
        <div className={styles.root} onMouseEnter={onCardMouseEnter} onMouseLeave={onCardMouseLeave}>
            <div className={styles.asset}>
                {/*<Skeleton*/}
                {/*    isReady={!loading && id}*/}
                {/*    loadingClassName={styles.fileAssetLoading}*/}
                {/*    customStyle={{*/}
                {/*        borderRadius: '4px'*/}
                {/*    }}*/}
                {/*>*/}
                <FileAsset
                    useR7Controls
                    canEditInR7
                    originName={name}
                    id={id}
                    name={name}
                    type={type}
                    fileId={id}
                    showDownloadButton={showDownloadButton}
                />
                {/*</Skeleton>*/}
            </div>

            <div className={styles.content}>
                {/*<Skeleton isReady={!loading && originName} loadingClassName={styles.titleLoading}>*/}
                <div className={styles.title} title={originName}>
                    {originName}
                </div>
                {/*</Skeleton>*/}

                {/*<Skeleton isReady={!loading && size} loadingClassName={styles.sizeLoading}>*/}
                <div className={styles.size}>{Utils.formatFileSize(size)}</div>
                {/*</Skeleton>*/}

                {/*<Skeleton isReady={!loading && author} loadingClassName={styles.authorLoading}>*/}
                {/*    {author && <div className={styles.author}>Загрузил:&nbsp;*/}
                {/*        <span className={styles.authorBlack}>{author.secondName} {author.firstName}</span>*/}
                {/*    </div>}*/}
                {/*</Skeleton>*/}
            </div>

            {!loading && showDeleteButton && (
                <>
                    <Icon
                        type={IconType.DELETE24}
                        svgSize={24}
                        className={styles.deleteButton}
                        onClick={onDeleteButtonClick}
                    />
                </>
            )}
        </div>
    );
}
