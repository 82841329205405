import * as React from 'react';

import { CreativeRequest, CreativeRequestItem, CreativeRequestItemFile } from '@api';

import { useCreativeRequestItems, useFilesCache } from './hooks';

import { WithScrollbar } from '../WithScrollbar';

import { FileGroupByTable } from './FileGroupByTable';

interface Props {
    creativeRequest: CreativeRequest;
}

export function Files({ creativeRequest }: Props): JSX.Element {
    const items = useCreativeRequestItems(creativeRequest);
    const { cache, addFiles, deleteFile } = useFilesCache();

    return (
        <WithScrollbar>
            <FileGroupByTable files={cache} />
            <React.Fragment>
                {items?.map((item) => (
                    <ItemEffect key={item.model.id} item={item} addFiles={addFiles} deleteFile={deleteFile} />
                ))}
            </React.Fragment>
        </WithScrollbar>
    );
}

interface ItemEffectProps {
    item: CreativeRequestItem;
    addFiles: (creativeRequestItem: CreativeRequestItem, files: CreativeRequestItemFile[]) => void;
    deleteFile: (file: CreativeRequestItemFile) => void;
}

// component used only for side-effect (loading creativeRequestItem images)
function ItemEffect({ item, addFiles, deleteFile }: ItemEffectProps): null {
    function onFileAdded(files_: CreativeRequestItemFile | CreativeRequestItemFile[]) {
        const files = files_ instanceof Array ? files_ : [files_];

        addFiles(item, files);
    }

    React.useEffect(function listenFilesUpdate() {
        async function loadFiles() {
            onFileAdded(await item.model.getFiles());
        }

        loadFiles();

        item.events.onFilesAdded(onFileAdded);
        item.events.onFilesRemoved(deleteFile);

        return function resetFilesListeners() {
            item.events.offFilesAdded(onFileAdded);
            item.events.offFilesRemoved(deleteFile);
        };
    }, []);

    return null;
}
