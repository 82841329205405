import * as React from 'react';
import { connect } from 'react-redux';

import { StoreState } from '@store';
import { CardType, getSelectedCard } from '@store/dashboardPage';

import { RightSidebar } from './RightSidebar';

interface Props extends Partial<MapProps> {
    updateCards: () => Promise<void>;
}

interface MapProps {
    selectedCardType: CardType;
}

@(connect(mapStateToProps, null) as any)
export class RightSidebarContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { selectedCardType, updateCards } = this.props;

        return <RightSidebar selectedCardType={selectedCardType} updateCards={updateCards} />;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const selectedCard = getSelectedCard(state);

    return {
        selectedCardType: selectedCard ? selectedCard.cardType : null,
    };
}
