import * as React from 'react';
import classNames from 'classnames';
import { WithTooltip } from 'sber-marketing-ui';

import * as style from './NavItem.scss';

interface Props {
    title: string;
    isSelected: boolean;
    onClick: () => void;
}

export const NavItem: React.FC<Props> = ({ title, isSelected = false, onClick }) => {
    return (
        <WithTooltip content={title}>
            <div
                className={classNames(style.root, isSelected && style.selected)}
                onClick={onClick}
                {...{
                    'qa-id': 'briefNavItemListItem',
                }}
            >
                {title}
            </div>
        </WithTooltip>
    );
};
