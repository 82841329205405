import * as React from 'react';
import { CorrectionType } from '@mrm/budget';

import { Money, MoneyFormatter } from '@common/Utils';
import { Dictionary } from '../../withDictionary';
import { IncomeExternalCorrectionCard as IncomeExternalCorrectionCardTooltip } from '../CardTypes';
import { IncomeExternalPlanFundsTransferCorrection } from '../../../types';
import { Menu, Params, SerialNumber, Body } from '../common';

import * as style from './IncomeExternalCorrectionCard.scss';

interface Props {
    correction: IncomeExternalPlanFundsTransferCorrection;
    dictionaries: Dictionary[];
    onCardMouseEnter: () => void;
    onCardMouseLeave: () => void;
    onChange: () => void;
}

export const IncomeExternalCorrectionCard: React.FC<Props> = ({
    correction,
    dictionaries,
    onCardMouseEnter,
    onCardMouseLeave,
    onChange,
}) => {
    return (
        <Body
            qaId="dashboardRightSidebarBudgetInfoIncomeExternalCorrectionCard"
            qaCorrectionType={CorrectionType.IncomeExternalPlanFundsTransfer}
            tooltipComponent={<IncomeExternalCorrectionCardTooltip {...correction} dictionaries={dictionaries} />}
            onMouseEnter={onCardMouseEnter}
            onMouseLeave={onCardMouseLeave}
        >
            <div className={style.header}>
                <SerialNumber>{correction.serialNumber}</SerialNumber>
                <div className={style.title}>Внешняя корректировка строки</div>
                <Menu
                    status={correction.status}
                    correctionId={correction.id}
                    correctionAuthorId={correction.author.id}
                    onChange={onChange}
                />
            </div>
            <Params
                data={[
                    {
                        name: 'План по строке',
                        value: (
                            <div className={style.positiveValue}>{formatMoneyWithSign(correction.params.value)}</div>
                        ),
                    },
                ]}
            />
        </Body>
    );
};

function formatMoneyWithSign(money: number): string {
    return MoneyFormatter.toThousands(Money.fromCopecks(money), { forceSign: true });
}
