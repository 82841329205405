// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-TaskCard-Date-_Date__root{position:relative}.src-client-modules-common-TaskCard-Date-_Date__calendarIcon{margin:-1px -1px 0 -1px}.src-client-modules-common-TaskCard-Date-_Date__calendarIconWrapper{margin-right:5px;line-height:0}.src-client-modules-common-TaskCard-Date-_Date__skeleton{width:75px;height:18px}.src-client-modules-common-TaskCard-Date-_Date__cardSubInfo{display:flex;align-items:center}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/TaskCard/Date/Date.scss"],"names":[],"mappings":"AAAA,qDACI,iBAAkB,CACrB,6DAGG,uBAAwB,CAC3B,oEAGG,gBAAiB,CAEjB,aAAc,CACjB,yDAGG,UAAW,CACX,WAAY,CACf,4DAGG,YAAa,CACb,kBAAmB","sourcesContent":[".root {\n    position: relative;\n}\n\n.calendarIcon {\n    margin: -1px -1px 0 -1px;\n}\n\n.calendarIconWrapper {\n    margin-right: 5px;\n\n    line-height: 0;\n}\n\n.skeleton {\n    width: 75px;\n    height: 18px;\n}\n\n.cardSubInfo {\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-TaskCard-Date-_Date__root",
	"calendarIcon": "src-client-modules-common-TaskCard-Date-_Date__calendarIcon",
	"calendarIconWrapper": "src-client-modules-common-TaskCard-Date-_Date__calendarIconWrapper",
	"skeleton": "src-client-modules-common-TaskCard-Date-_Date__skeleton",
	"cardSubInfo": "src-client-modules-common-TaskCard-Date-_Date__cardSubInfo"
};
export default ___CSS_LOADER_EXPORT___;
