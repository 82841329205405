import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import { getContext } from '@store/autopilot/selectors';

import * as styles from './styles.scss';
import { List } from './List';

interface Props {
    className?: string;
}

export const Texts: React.FC<Props> = ({ className }) => {
    const { titles, descriptions } = useSelector((state: StoreState) => getContext(state));

    return (
        <div className={classNames(styles.root, className)}>
            {!!titles.length && <List title={'Заголовки'} items={titles} />}
            {!!descriptions.length && <List title={'Описания'} items={descriptions} />}
        </div>
    );
};
