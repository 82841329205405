import * as React from 'react';
import autobind from 'autobind-decorator';
import { Moment } from 'moment';

import { ActivityCard } from './ActivityCard';
import { StageName } from '../../types';

interface Props {
    id: number;
    budgetItemId: string;
    productName: string;
    organizationAndBlockName: string;
    authorName: string;
    activityName: string;
    startDate: Moment | null;
    endDate: Moment | null;
    stage: StageName;
    canUnbind: boolean;
    canRead: boolean;
    loading?: boolean;
    onUnbindActivityFinish: () => void;
}

interface State {
    isOpenUnbindActivityPopup: boolean;
}

export class ActivityCardContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isOpenUnbindActivityPopup: false,
        };
    }

    public render() {
        const {
            id,
            budgetItemId,
            productName,
            organizationAndBlockName,
            authorName,
            activityName,
            startDate,
            endDate,
            stage,
            loading,
            canUnbind,
            canRead,
        } = this.props;

        const { isOpenUnbindActivityPopup } = this.state;

        return React.createElement(ActivityCard, {
            id,
            budgetItemId,
            productName,
            organizationAndBlockName,
            authorName,
            activityName,
            startDate,
            endDate,
            stage,
            loading,
            canUnbind,
            disabled: loading || !canRead,
            isOpenUnbindActivityPopup,
            onDeleteButtonClick: this.onDeleteButtonClick,
            onUnbindActivityPopupClose: this.onUnbindActivityPopupClose,
            onUnbindActivityPopupFinish: this.onUnbindActivityPopupFinish,
        });
    }

    @autobind
    private onDeleteButtonClick(event: React.MouseEvent<HTMLDivElement>): void {
        event.preventDefault();
        this.setState({
            isOpenUnbindActivityPopup: true,
        });
    }

    @autobind
    private onUnbindActivityPopupClose(): void {
        this.setState({
            isOpenUnbindActivityPopup: false,
        });
    }

    @autobind
    private onUnbindActivityPopupFinish(): void {
        this.props.onUnbindActivityFinish();
    }
}
