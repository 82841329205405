import * as React from 'react';

import { TabBarContext } from './TabBarContext';

interface Props {
    selectedTabIndex: number;
    onChangeSelectedTabIndex: (tabIndex: number) => void;
}

export const TabBar: React.FC<Props> = ({ children, selectedTabIndex, onChangeSelectedTabIndex }) => {
    return (
        <TabBarContext.Provider
            value={{
                selectedTabIndex,
                changeSelectedTabIndex: onChangeSelectedTabIndex,
            }}
        >
            <React.Fragment>{children}</React.Fragment>
        </TabBarContext.Provider>
    );
};
