import * as React from 'react';
import { ActivityFileGroup } from 'sber-marketing-types/frontend';

import { ChildrenProps } from './FileGroupsConnected';
import { FileGroup } from './FileGroup';

interface Props extends ChildrenProps {
    enableControls: boolean;
}

export function FileGroupsTemplate({ loading, filesByGroup, enableControls, refetch }: Props): JSX.Element {
    return (
        <React.Fragment>
            <FileGroup
                loading={loading}
                title="Медиаплан"
                fileGroup={ActivityFileGroup.MEDIAPLAN}
                files={filesByGroup[ActivityFileGroup.MEDIAPLAN]}
                enableControls={enableControls}
                reloadContent={refetch}
            />
            <FileGroup
                loading={loading}
                title="Заявка на креатив"
                fileGroup={ActivityFileGroup.CREATIVE}
                files={filesByGroup[ActivityFileGroup.CREATIVE]}
                enableControls={enableControls}
                reloadContent={refetch}
            />
        </React.Fragment>
    );
}
