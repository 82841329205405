import * as React from 'react';
import classNames from 'classnames';

import * as style from './ChartList.scss';

import type { OrganizationGroups } from '@store/calendar/types';

import type { ScrollbarComponent } from 'sber-marketing-ui';
import { ActivityGroup } from './ActivityGroup';

interface Props {
    organizationGroups: OrganizationGroups[];
    displayOrganizationNames: boolean;
    fullWidth: number;
    viewportWidth: number;
    rootRef: (element: HTMLDivElement) => void;
    canvasRef: (element: HTMLCanvasElement, groupId: string) => void;
    scrollbarRef: (component: ScrollbarComponent, groupId: string) => void;
    onScroll: (scrollLeft: number, groupId: string) => void;
}

export function ChartList({
    organizationGroups,
    displayOrganizationNames,
    fullWidth,
    viewportWidth,
    rootRef,
    canvasRef,
    scrollbarRef,
    onScroll,
}: Props): JSX.Element {
    return (
        <div className={style.root} ref={rootRef}>
            {organizationGroups.map((organizationGroup) =>
                organizationGroup.groups.map((group, groupIndex) => (
                    <div
                        className={classNames(
                            style.activityGroup,
                            displayOrganizationNames && groupIndex == 0 && style.displayOrganizationNames,
                        )}
                        key={group.id}
                    >
                        <ActivityGroup
                            group={group}
                            fullWidth={fullWidth}
                            viewportWidth={viewportWidth}
                            canvasRef={canvasRef}
                            scrollbarRef={scrollbarRef}
                            onScroll={onScroll}
                        />
                    </div>
                )),
            )}
        </div>
    );
}
