import { Failure } from 'typescript-fsa';

import { TaskPageState, BulkAddParticipatorsStartPayload } from '../types';

export const bulkAddParticipatorsStartReducer = (
    { participants, ...restState }: TaskPageState,
    payload: BulkAddParticipatorsStartPayload,
): TaskPageState => ({
    ...restState,
    participants: payload.participators,
    editingParticipants: payload.participators,
});

export const bulkAddParticipatorsErrorReducer = (
    { participants, ...restState }: TaskPageState,
    { error: rollback }: Failure<BulkAddParticipatorsStartPayload, BulkAddParticipatorsStartPayload>,
): TaskPageState => ({
    ...restState,
    participants: rollback.participators,
    editingParticipants: rollback.participators,
});
