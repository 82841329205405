// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-StageWidgets-RequestStageWidget-TenderDocumentation-FileInput-_styles__root{padding:12px 16px;background:rgba(235,238,236,0.4);border-radius:6px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/StageWidgets/RequestStageWidget/TenderDocumentation/FileInput/styles.scss"],"names":[],"mappings":"AAAA,wHACI,iBAAkB,CAClB,gCAAoC,CACpC,iBAAkB","sourcesContent":[".root {\n    padding: 12px 16px;\n    background: rgba(235, 238, 236, 0.4);\n    border-radius: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-StageWidgets-RequestStageWidget-TenderDocumentation-FileInput-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
