import * as moment from 'moment';

import { StageCalendarItem, LineParams, STAGE_TYPE } from '../types';

export class LinesMaker {
    public static makeStageLines(stages: StageCalendarItem[]): LineParams[] {
        const lines: LineParams[] = [];

        stages.forEach((stage) => {
            lines.push({
                id: stage.id,
                name: stage.name,
                start: stage.start,
                end: stage.end,
                type: LinesMaker.getStageType(stage),
            });
        });

        // additional line for stageless tasks
        lines.push({
            id: null,
            name: 'Вне этапов',
            start: null,
            end: null,
            type: STAGE_TYPE.PLANNED,
        });

        this.addRoundedCorners(lines);

        return lines;
    }

    private static getStageType(stage: StageCalendarItem): STAGE_TYPE {
        const today = new Date();

        const { start, end } = stage;

        if (!start || !end) {
            return stage.done ? STAGE_TYPE.FINISHED : STAGE_TYPE.PLANNED;
        }

        let result: STAGE_TYPE;

        if (stage.done) {
            result = STAGE_TYPE.FINISHED;
        } else {
            const startPassed = today.valueOf() > start.valueOf();
            const daysLeft = moment(end).diff(moment(today), 'days');

            if (startPassed && daysLeft <= 3) {
                const endPassed = today.valueOf() > end.valueOf();

                result = endPassed ? STAGE_TYPE.EXPIRED : STAGE_TYPE.ALMOST_EXPIRED;
            } else {
                result = STAGE_TYPE.PLANNED;
            }
        }

        return result;
    }

    private static addRoundedCorners(lines: LineParams[]) {
        const coloredLinesIndexes = lines
            .map((line, index) => {
                const lineHasColor = line.type == STAGE_TYPE.FINISHED || line.type == STAGE_TYPE.EXPIRED;

                return lineHasColor ? index : null;
            })
            .filter((item) => item !== null);

        lines.forEach((line, index) => {
            const lineHasColor = coloredLinesIndexes.includes(index);
            const previousLineHasColor = coloredLinesIndexes.includes(index - 1);
            const nextLineHasColor = coloredLinesIndexes.includes(index + 1);

            line.roundedTop = !(previousLineHasColor && lineHasColor);
            line.roundedBottom = !(lineHasColor && nextLineHasColor);
        });
    }
}
