import * as React from 'react';
import classNames from 'classnames';
import { HistoryActionType } from 'sber-marketing-types/backend';

import { Colors } from '../qaData';

import * as style from './ActionType.scss';

interface Props {
    action: HistoryActionType;
    wasActivityAssignedToLoginedUser: boolean;
    wasTaskAssignedToLoginedUser: boolean;
}

interface StyleParams {
    label: string;
    colorClass: string;
    qaColor: Colors;
}

const PARAMS = {
    [HistoryActionType.CreateActivity]: {
        label: 'Создан новый проект',
        colorClass: style.backgroundGreen,
        qaColor: Colors.Green,
    },
    [HistoryActionType.ChangeResponsible]: {
        toCurrentUser: {
            label: 'На вас назначен проект',
            colorClass: style.backgroundGreen,
            qaColor: Colors.Green,
        },
        toOtherUser: {
            label: 'Назначен новый ответственный',
            colorClass: style.backgroundYellow,
            qaColor: Colors.Yellow,
        },
    },
    [HistoryActionType.ShareActivity]: {
        label: 'Вам открыли доступ к проекту',
        colorClass: style.backgroundGreen,
        qaColor: Colors.Green,
    },
    [HistoryActionType.RevokeActivity]: {
        label: 'Вам закрыли доступ к проекту',
        colorClass: style.backgroundYellow,
        qaColor: Colors.Yellow,
    },
    [HistoryActionType.UpdateActivity]: {
        label: 'Проект отредактирован',
        colorClass: style.backgroundYellow,
        qaColor: Colors.Yellow,
    },
    [HistoryActionType.ActivityRealizationChanged]: {
        label: 'Изменены сроки проекта',
        colorClass: style.backgroundYellow,
        qaColor: Colors.Yellow,
    },
    [HistoryActionType.ActivityPreparationTimingChanged]: {
        label: 'Изменены сроки подготовки',
        colorClass: style.backgroundYellow,
        qaColor: Colors.Yellow,
    },
    [HistoryActionType.ActivityDebriefingTimingChanged]: {
        label: 'Изменена дата подведения итогов',
        colorClass: style.backgroundYellow,
        qaColor: Colors.Yellow,
    },
    [HistoryActionType.DeleteActivity]: {
        label: 'Проект удален',
        colorClass: style.backgroundRed,
        qaColor: Colors.Red,
    },
    [HistoryActionType.AddTaskParticipant]: {
        label: 'Вас добавили к задаче',
        colorClass: style.backgroundGreen,
        qaColor: Colors.Green,
    },
    [HistoryActionType.UpdateTask]: {
        label: 'Задача отредактирована',
        colorClass: style.backgroundYellow,
        qaColor: Colors.Yellow,
    },
    [HistoryActionType.UpdateTaskDeadline]: {
        label: 'Изменен дедлайн задачи',
        colorClass: style.backgroundYellow,
        qaColor: Colors.Yellow,
    },
    [HistoryActionType.UpdateTaskExecutor]: {
        toCurrentUser: {
            label: 'На вас назначена задача',
            colorClass: style.backgroundGreen,
            qaColor: Colors.Green,
        },
        toOtherUser: {
            label: 'Назначен новый исполнитель задачи',
            colorClass: style.backgroundYellow,
            qaColor: Colors.Yellow,
        },
    },
    [HistoryActionType.RemoveTaskParticipant]: {
        label: 'Вас удалили из задачи',
        colorClass: style.backgroundRed,
        qaColor: Colors.Red,
    },
    [HistoryActionType.AddTaskComment]: {
        label: 'Новый комментарий в задаче',
        colorClass: style.backgroundLightGrey,
        qaColor: Colors.LightGrey,
    },
    [HistoryActionType.MentionedInTaskComment]: {
        label: 'Вас отметили в комментарии',
        colorClass: style.backgroundLightGrey,
        qaColor: Colors.LightGrey,
    },
    [HistoryActionType.AddTaskFile]: {
        label: 'Добавлены файлы к задаче',
        colorClass: style.backgroundLightGrey,
        qaColor: Colors.LightGrey,
    },
    [HistoryActionType.AddTaskCommentFile]: {
        label: 'Добавлены файлы в канале',
        colorClass: style.backgroundLightGrey,
        qaColor: Colors.LightGrey,
    },
    [HistoryActionType.TaskClosed]: {
        label: 'Задача закрыта',
        colorClass: style.backgroundGreen,
        qaColor: Colors.Green,
    },
    [HistoryActionType.TaskToProgress]: {
        label: 'Задача возвращена в работу',
        colorClass: style.backgroundGreen,
        qaColor: Colors.Green,
    },
    [HistoryActionType.DeleteTask]: {
        label: 'Задача удалена',
        colorClass: style.backgroundRed,
        qaColor: Colors.Red,
    },
    [HistoryActionType.AddTaskChannel]: {
        label: 'Вас добавили в канал',
        colorClass: style.backgroundGreen,
        qaColor: Colors.Green,
    },
    [HistoryActionType.DeleteTaskChannel]: {
        label: 'Вас удалили из канала',
        colorClass: style.backgroundRed,
        qaColor: Colors.Red,
    },
    [HistoryActionType.TaskReturned]: {
        label: 'Задача возвращена в работу',
        colorClass: style.backgroundGreen,
        qaColor: Colors.Green,
    },
    [HistoryActionType.TaskDeadLineOverdue]: {
        label: 'Просрочен дедлайн задачи',
        colorClass: style.backgroundRed,
        qaColor: Colors.Red,
    },
    [HistoryActionType.OneDayToTaskDeadLine]: {
        label: 'Один день до дедлайна задачи',
        colorClass: style.backgroundYellow,
        qaColor: Colors.Yellow,
    },
};

export function ActionType({
    action,
    wasActivityAssignedToLoginedUser,
    wasTaskAssignedToLoginedUser,
}: Props): JSX.Element {
    let params: StyleParams;

    if (action === HistoryActionType.ChangeResponsible) {
        if (wasActivityAssignedToLoginedUser) {
            params = PARAMS[HistoryActionType.ChangeResponsible].toCurrentUser;
        } else {
            params = PARAMS[HistoryActionType.ChangeResponsible].toOtherUser;
        }
    } else if (action === HistoryActionType.UpdateTaskExecutor) {
        if (wasTaskAssignedToLoginedUser) {
            params = PARAMS[HistoryActionType.UpdateTaskExecutor].toCurrentUser;
        } else {
            params = PARAMS[HistoryActionType.UpdateTaskExecutor].toOtherUser;
        }
    } else {
        params = PARAMS[action];
    }

    return (
        <div className={style.newsPartWrapper}>
            <div
                className={classNames([style.actionType, params.colorClass])}
                {...{
                    'qa-id': 'dashboardNewsColumnItemActionType',
                    'qa-color': params.qaColor,
                }}
            >
                {params.label}
            </div>
        </div>
    );
}
