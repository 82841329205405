import { useParams, useLocation, useHistory } from 'react-router-dom';
import * as queryString from 'query-string';
import * as lodash from 'lodash';

interface RouteParams {
    lot: 'lot1' | 'lot2';
}

export const useUrlHooks = () => {
    const params = useParams<RouteParams>();
    const location = useLocation();
    const history = useHistory();

    const lot = parseInt(lodash.first(params.lot?.match(/\d/g)), 10) as 1 | 2;

    const query = queryString.parse(location.search);
    const creativeRequestId = query.creativeRequestId as string;

    function setLot(lot: 1 | 2) {
        history.push(`/pivotTable/lot${lot}${location.search || ''}`);
    }

    function setCreativeRequestId(creativeRequestId: string) {
        history.push({ search: `creativeRequestId=${creativeRequestId}` });
    }

    function clearQuery() {
        history.push({ search: '' });
    }

    return {
        lot,
        creativeRequestId,
        setLot,
        setCreativeRequestId,
        clearQuery,
    };
};
