import { actionCreatorFactory } from 'typescript-fsa';
import { BudgetItem } from '@mrm/budget';

import {
    BudgetTransferMenuState,
    ExpertsState,
    CellsState,
    ComponentState,
    ControlsState,
    CellPosition,
    TransferDescriptorsState,
} from '../types';

const actionCreator = actionCreatorFactory('BUDGET_EXECUTION_TRANSITION_DATA_ASYNC').async;

const setComponentState = actionCreator<ComponentState, Partial<BudgetTransferMenuState>, Error>('SET_COMPONENT_STATE');
const loadExperts = actionCreator<null, ExpertsState, Error>('LOAD_EXPERTS');
const onCellClick = actionCreator<CellPosition, CellsState, Error>('ON_CELL_CLICK');
const toggleLineStatus = actionCreator<string, Partial<CellsState>, Error>('TOGGLE_LINE_STATUS');
const updateBudgetItems = actionCreator<CellPosition[], BudgetItem[], Error>('UPDATE_BUDGET_ITEM');
const initPlanTransfer = actionCreator<null, void, Error>('INIT_PLAN_TRANSFER');
const toggleInternalTransitionDirectrion = actionCreator<null, Partial<ControlsState>, Error>(
    'TOGGLE_INTERNAL_TRANSITION_DIRECTION',
);
const updateIsHoveredLineClickable = actionCreator<string, Partial<ControlsState>, Error>(
    'UPDATE_IS_HOVERED_LINE_CLICKABLE',
);
const updateTransferDescriptors = actionCreator<null, TransferDescriptorsState, Error>('UPDATE_TRANSFER_DESCRIPTORS');

export {
    setComponentState,
    loadExperts,
    onCellClick,
    toggleLineStatus,
    initPlanTransfer,
    updateBudgetItems,
    toggleInternalTransitionDirectrion,
    updateIsHoveredLineClickable,
    updateTransferDescriptors,
};
