export const enum ReportType {
    OVERDUE_STAGES = 'overdue_stages',
    OVERDUE_TASKS = 'overdue_tasks',
    ACTIVE = 'active',
    PLANNED_START = 'planned_start',
}

export const enum Color {
    MUSTARD = 'mustard',
    RED = 'red',
    GREEN = 'green',
    BLUE = 'blue',
}
