import { TaskPageState, AddNewTaskParticipatorPayload } from '../types';

export const addNewTaskParticipator = (
    { participants, ...restState }: TaskPageState,
    payload: AddNewTaskParticipatorPayload,
): TaskPageState => ({
    ...restState,
    participants: [...participants, payload],
});

export const removeNewTaskParticipator = (
    { participants, ...restState }: TaskPageState,
    payload: AddNewTaskParticipatorPayload,
): TaskPageState => ({
    ...restState,
    participants: participants.filter((participant) => participant.userId !== payload.userId),
});
