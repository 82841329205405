import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { WorkTypeApi } from '@api';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';
import { LoadingStatus } from '@store/commonTypes';

import * as actions from './actions';
import { LoadWorkTypesParams } from './types';
import { getLoadingStatus, getWorkTypeById, getFetchersCount } from './selectors';

export const loadWorkTypes = bindThunkAction<StoreState, LoadWorkTypesParams, void, Error>(
    actions.loadWorkTypesAsync,
    async (params, dispatch, getState) => {
        const { store } = params;
        let { ids } = params;

        const setStatus: (status: LoadingStatus) => void = (status) =>
            dispatch(
                actions.setLoadingStatus({
                    store,
                    status,
                }),
            );

        const state = getState();
        const loadingStatus = getLoadingStatus(state, store);
        const loginnedUser = getLoginUser(state);

        try {
            let workTypes;

            if (ids) {
                const notLoadedWorkTypeIds = ids.filter((id) => !getWorkTypeById(state, id));

                if (notLoadedWorkTypeIds.length) {
                    dispatch(actions.incFetchersCount(store));
                    setStatus(LoadingStatus.LOADING);
                    workTypes = await WorkTypeApi.getList({
                        ids: notLoadedWorkTypeIds,
                    });
                }
            } else {
                if (loadingStatus !== LoadingStatus.LOADED) {
                    dispatch(actions.incFetchersCount(store));
                    setStatus(LoadingStatus.LOADING);
                    workTypes = await WorkTypeApi.getList({
                        organizationId: loginnedUser.attributes.organizationId,
                    });

                    ids = workTypes.map((workType) => workType.id);
                }
            }

            if (workTypes) {
                dispatch(actions.decFetchersCount(store));
                dispatch(actions.loadEntities(workTypes));
            }
            if (ids) {
                dispatch(
                    actions.setStoreIds({
                        store,
                        ids,
                    }),
                );
            }

            const fetchersCount = getFetchersCount(getState(), store);
            if (!fetchersCount) {
                setStatus(LoadingStatus.LOADED);
            }
        } catch (e) {
            setStatus(LoadingStatus.ERROR);
        }
    },
);
