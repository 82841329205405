// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-brief-Brief-Block-Field-FieldTypes-FileBlock-FileUploader-_FileUploader__root{display:flex}\n", "",{"version":3,"sources":["webpack://./src/client/modules/brief/Brief/Block/Field/FieldTypes/FileBlock/FileUploader/FileUploader.scss"],"names":[],"mappings":"AAAA,kGACI,YAAa","sourcesContent":[".root {\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-brief-Brief-Block-Field-FieldTypes-FileBlock-FileUploader-_FileUploader__root"
};
export default ___CSS_LOADER_EXPORT___;
