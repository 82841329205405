// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-TaskCard-WithEditor-_WithEditor__root{position:relative;max-width:100%}.src-client-modules-common-TaskCard-WithEditor-_WithEditor__rootActive:hover::before{content:'';position:absolute;z-index:10;top:0;left:0;width:100%;height:100%;border-radius:2px;background:linear-gradient(0deg, rgba(0,0,0,0.03), rgba(0,0,0,0.03)),#F6F6F6;opacity:0.3}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/TaskCard/WithEditor/WithEditor.scss"],"names":[],"mappings":"AAAA,iEACI,iBAAkB,CAClB,cAAe,CAClB,qFAGG,UAAW,CACX,iBAAkB,CAClB,UAAW,CACX,KAAM,CACN,MAAO,CACP,UAAW,CACX,WAAY,CAEZ,iBAAkB,CAElB,4EAAoF,CACpF,WAAY","sourcesContent":[".root {\n    position: relative;\n    max-width: 100%;\n}\n\n.rootActive:hover::before {\n    content: '';\n    position: absolute;\n    z-index: 10;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n\n    border-radius: 2px;\n    \n    background: linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)), #F6F6F6;\n    opacity: 0.3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-TaskCard-WithEditor-_WithEditor__root",
	"rootActive": "src-client-modules-common-TaskCard-WithEditor-_WithEditor__rootActive"
};
export default ___CSS_LOADER_EXPORT___;
