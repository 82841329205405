// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-common-ActivityFiles-_ActivityFiles__caption{margin-bottom:6px;user-select:none;letter-spacing:0.5px;text-transform:uppercase;color:#7e8681;font-size:14px;line-height:19px}.src-client-modules-budget-common-ActivityFiles-_ActivityFiles__filesBlock{margin-bottom:24px;user-select:none}.src-client-modules-budget-common-ActivityFiles-_ActivityFiles__filesBlock:last-of-type{margin-bottom:0}.src-client-modules-budget-common-ActivityFiles-_ActivityFiles__fileList{margin-top:8px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/common/ActivityFiles/ActivityFiles.scss"],"names":[],"mappings":"AAAA,wEACI,iBAAkB,CAElB,gBAAiB,CACjB,oBAAqB,CACrB,wBAAyB,CAEzB,aAAc,CAEd,cAAe,CACf,gBAAiB,CACpB,2EAIG,kBAAmB,CAEnB,gBAAiB,CAHrB,wFAMQ,eAAgB,CACnB,yEAID,cAAe","sourcesContent":[".caption {\n    margin-bottom: 6px;\n\n    user-select: none;\n    letter-spacing: 0.5px;\n    text-transform: uppercase;\n\n    color: #7e8681;\n\n    font-size: 14px;\n    line-height: 19px;\n}\n\n\n.filesBlock {\n    margin-bottom: 24px;\n\n    user-select: none;\n\n    &:last-of-type {\n        margin-bottom: 0;\n    }\n}\n\n.fileList {\n    margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"caption": "src-client-modules-budget-common-ActivityFiles-_ActivityFiles__caption",
	"filesBlock": "src-client-modules-budget-common-ActivityFiles-_ActivityFiles__filesBlock",
	"fileList": "src-client-modules-budget-common-ActivityFiles-_ActivityFiles__fileList"
};
export default ___CSS_LOADER_EXPORT___;
