// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-layout-HtmlText-_HtmlText__root>*:first-child{margin-top:0}.src-client-modules-common-components-layout-HtmlText-_HtmlText__root>*:last-child{margin-bottom:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/layout/HtmlText/HtmlText.scss"],"names":[],"mappings":"AAAA,oFAEI,YAAa,CAFjB,mFAMI,eAAgB","sourcesContent":[".root {\n  & > *:first-child {\n    margin-top: 0;\n  }\n\n  & > *:last-child {\n    margin-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-components-layout-HtmlText-_HtmlText__root"
};
export default ___CSS_LOADER_EXPORT___;
