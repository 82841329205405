import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { castArray, isNil } from 'lodash';
import {
    SortingOrder,
    ActivityStatus as BackendActivityStatus,
    ActivitySortingParam,
} from 'sber-marketing-types/backend';
import { ActivityListQuery } from 'sber-marketing-types/frontend';

import { ActivityApi, OwnershipApi } from '@api';

import { StoreState } from '@store';
import { updateActivity } from '@store/activityTasksPage';
import { ActivitiesFilter, ActivityStatus, ActivitySortingMode } from '@store/userConfig';

import {
    setPendingDeletionIds,
    pushActivities,
    removeActivities,
    setCanBeLoadedMore,
    updateActivityOwnerships,
    fetchMoreActivities as fetchMoreActivitiesAction,
    deleteActivities as deleteActivitiesAction,
    makeActivitiesFavorite as makeActivitiesFavoriteAction,
    shareActivity as shareActivityAction,
    switchActivityFavorite,
    setLastRequestTimestamp,
    resetLastRequestTimestamp,
    switchKeyActivity as switchKeyActivityAction,
} from './actions';
import { getType, getActivitiesList, getActivityById, getLasrActivityId, getLastRequestTimestamp } from './selectors';
import { ActivitiesListType, ShareActivityPayload, SwitchKeyActivityPayload } from './types';

const LIMIT_DEFAULT = 20;

// tslint:disable:cyclomatic-complexity
const mapQuery = (
    filters: ActivitiesFilter,
    type: ActivitiesListType,
    offset: number,
    lastId: number,
): ActivityListQuery => {
    const { author, status, activityType, sorting, product, responsible, responsibleDepartment } = filters;

    const result: ActivityListQuery = {
        offset,
        orderBy: SortingOrder.DESC,
        favoriteFirst: true,
        limit: LIMIT_DEFAULT,
    };

    if (lastId) {
        result.lastId = lastId;
    }

    if (activityType.length) {
        result.typeIds = activityType;
    }

    if (product.length) {
        result.productIds = product;
    }

    if (responsible.length) {
        result.responsibleIds = responsible;
    }

    if (responsibleDepartment.length) {
        result.responsibleDepartmentIds = responsibleDepartment;
    }

    if (author.length) {
        result.authorIds = author;
    }

    switch (status) {
        case ActivityStatus.ACTIVE:
            result.status = BackendActivityStatus.Active;
            break;
        case ActivityStatus.ACTIVE_ALL_TASKS_CLOSED:
            result.status = BackendActivityStatus.Active;
            result.allTaskClosed = true;
            break;
        case ActivityStatus.FINISHED:
            result.status = BackendActivityStatus.Finished;
            break;
    }

    switch (sorting) {
        case ActivitySortingMode.CREATION_DATE: {
            result.sortedBy = ActivitySortingParam.CreateTime;
            break;
        }
        case ActivitySortingMode.UPDATING_DATE: {
            result.sortedBy = ActivitySortingParam.UpdateTime;
            break;
        }
        case ActivitySortingMode.START_DATE: {
            result.sortedBy = ActivitySortingParam.StartDate;
            break;
        }
        case ActivitySortingMode.UNRESOLVED_FIRST:
        default:
            result.unseenFirst = true;
            break;
    }

    if (type === ActivitiesListType.MY_ACTIVITIES) {
        result.authorOrResponsible = true;
    }

    if (type === ActivitiesListType.ACCESSIBLE_ACTIVITIES) {
        result.authorOrResponsible = false;
    }

    return result;
};

/** Fetch more activities by settled filters */
export const fetchMoreActivities = bindThunkAction<StoreState, ActivitiesFilter, boolean, Error>(
    fetchMoreActivitiesAction,
    async (filters, dispatch, getState) => {
        const query = mapQuery(
            filters,
            getType(getState()),
            getActivitiesList(getState()).length,
            getLasrActivityId(getState()),
        );
        const requestTimestamp = new Date();
        dispatch(setLastRequestTimestamp(requestTimestamp));
        const cards = await ActivityApi.getActivityCards(query);

        // using only last request
        const lastRequestTimestamp = getLastRequestTimestamp(getState());
        let wasLastRequest = false;
        if (requestTimestamp === lastRequestTimestamp) {
            dispatch(resetLastRequestTimestamp());
            dispatch(setCanBeLoadedMore(cards.length >= LIMIT_DEFAULT));
            dispatch(pushActivities(cards));
            wasLastRequest = true;
        }

        return wasLastRequest;
    },
);

/** Delete activities by identifiers */
export const deleteActivities = bindThunkAction<StoreState, number | number[], void, Error>(
    deleteActivitiesAction,
    async (payload, dispatch) => {
        const ids = castArray(payload);
        dispatch(setPendingDeletionIds(ids));
        await Promise.all(ids.map((id) => ActivityApi.deleteActivity(id)));
        dispatch(removeActivities(ids));
        dispatch(setPendingDeletionIds([]));
    },
);

/** Make activities favorite */
export const makeActivitiesFavorite = bindThunkAction<StoreState, number, void, Error>(
    makeActivitiesFavoriteAction,
    async (id, dispatch) => {
        await ActivityApi.pinActivity(id);
        dispatch(switchActivityFavorite(id));
    },
);

/** Share activity with users */
export const shareActivity = bindThunkAction<StoreState, ShareActivityPayload, void, Error>(
    shareActivityAction,
    async ({ activityId, usersIds }, dispatch, getState) => {
        await OwnershipApi.updateActivityOwners(activityId, usersIds);

        const activity = getActivityById(getState(), activityId);
        if (!isNil(activity)) {
            dispatch(updateActivityOwnerships({ activityId, ownerships: usersIds }));
        } else {
            dispatch(
                updateActivity({
                    participantIds: usersIds,
                }),
            );
        }
    },
);

export const switchKeyActivity = bindThunkAction<StoreState, SwitchKeyActivityPayload, void, Error>(
    switchKeyActivityAction,
    async (params, dispatch, getState) => {
        const { id, isKey } = params;

        const { realizationStart, realizationEnd } = getActivityById(getState(), id);

        return ActivityApi.editActivity(id, {
            isKey,
            realizationStart: realizationStart as string | Date,
            realizationEnd: realizationEnd as string | Date,
        });
    },
);
