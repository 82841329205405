import * as React from 'react';
// import * as lodash from 'lodash';

import * as style from './TableView.scss';

import type {
    CellPosition,
    // Point,
    ColumnName,
    ColumnWidths,
    LineHeights,
    LineId,
    // CellParams,
    // TableHeaderCellParams
} from '../types';
import { CellEvent } from '../types';

import { ScrollbarComponent } from 'sber-marketing-ui';
import { TableHeader } from './TableHeader';
import { TableBody } from './TableBody';
import { Cursor } from './Cursor';
// import { Cell } from './Cell';
// import { StickyWrapper, StickyWrapperPosition } from './StickyWrapper';
import { CellsStorage } from '../CellsStorage';

interface Props {
    allColumns: ColumnName[];
    columns: ColumnName[];
    leftFixedColumns: ColumnName[];
    rightFixedColumns: ColumnName[];
    lines: LineId[];
    cursorPosition: CellPosition;
    visibleColumnsIndexes: number[];
    visibleLinesIndexes: number[];
    columnWidths: ColumnWidths;
    lineHeights: LineHeights;
    columnsSumWidth: number;
    leftFixedSumWidth: number;
    rightFixedSumWidth: number;
    sumHeight: number;
    tableBodyMaxHeight: number;
    rootRef: React.RefObject<HTMLDivElement>;
    viewportRef: React.RefObject<HTMLDivElement>;
    tableHeaderRef: (component: ScrollbarComponent) => void;
    stickyTableHeaderRef: (component: ScrollbarComponent) => void;
    tableBodyRef: (component: ScrollbarComponent) => void;
    leftFixedColumnsRef: (component: ScrollbarComponent) => void;
    rightFixedColumnsRef: (component: ScrollbarComponent) => void;
    stickyScrollbarRef: (component: ScrollbarComponent) => void;
    cursorRef: (component: Cursor) => void;
    headerCellsStorage: CellsStorage<any, any>;
    tableCellsStorage: CellsStorage<any, any>;
    // getColumnHeader: (columnName: string) => TableHeaderCellParams;
    // getCellParams: (position: CellPosition, onCellParamsUpdateHandler?: (cellProps: any) => void) => CellParams;
    selectCell: (position: CellPosition) => void;
    onBodyScroll: () => void;
    onLeftFixedColumnsScroll: () => void;
    onRightFixedColumnsScroll: () => void;
    onStickyScrollbarScroll: () => void;
    onColumnEdgeMousedown: (columnName: ColumnName, mouseDownX: number) => void;
    onCellEvent: (eventType: CellEvent, position: CellPosition) => void;
}

export const TableViewTemplate = ({
    allColumns,
    columns,
    leftFixedColumns,
    rightFixedColumns,
    lines,
    cursorPosition,
    visibleColumnsIndexes,
    visibleLinesIndexes,
    columnWidths,
    lineHeights,
    columnsSumWidth,
    leftFixedSumWidth,
    rightFixedSumWidth,
    sumHeight,
    tableBodyMaxHeight,
    rootRef,
    viewportRef,
    tableHeaderRef,
    stickyTableHeaderRef,
    tableBodyRef,
    leftFixedColumnsRef,
    rightFixedColumnsRef,
    stickyScrollbarRef,
    cursorRef,
    headerCellsStorage,
    tableCellsStorage,
    selectCell,
    onBodyScroll,
    onLeftFixedColumnsScroll,
    onRightFixedColumnsScroll,
    onStickyScrollbarScroll,
    onColumnEdgeMousedown,
    onCellEvent,
}: Props): JSX.Element => {
    return (
        <div className={style.root} ref={rootRef}>
            <TableHeader
                columns={columns}
                leftFixedColumns={leftFixedColumns}
                rightFixedColumns={rightFixedColumns}
                visibleColumnsIndexes={visibleColumnsIndexes}
                columnWidths={columnWidths}
                columnsSumWidth={columnsSumWidth}
                leftFixedSumWidth={leftFixedSumWidth}
                rightFixedSumWidth={rightFixedSumWidth}
                rootRef={rootRef}
                tableHeaderRef={tableHeaderRef}
                stickyTableHeaderRef={stickyTableHeaderRef}
                getColumnHeader={headerCellsStorage.getCellParams}
                onColumnEdgeMousedown={onColumnEdgeMousedown}
            />

            <TableBody
                allColumns={allColumns}
                columns={columns}
                leftFixedColumns={leftFixedColumns}
                rightFixedColumns={rightFixedColumns}
                lines={lines}
                cursorPosition={cursorPosition}
                visibleColumnsIndexes={visibleColumnsIndexes}
                visibleLinesIndexes={visibleLinesIndexes}
                columnWidths={columnWidths}
                lineHeights={lineHeights}
                columnsSumWidth={columnsSumWidth}
                leftFixedSumWidth={leftFixedSumWidth}
                rightFixedSumWidth={rightFixedSumWidth}
                sumHeight={sumHeight}
                tableBodyMaxHeight={tableBodyMaxHeight}
                rootRef={rootRef}
                viewportRef={viewportRef}
                tableBodyRef={tableBodyRef}
                leftFixedColumnsRef={leftFixedColumnsRef}
                rightFixedColumnsRef={rightFixedColumnsRef}
                stickyScrollbarRef={stickyScrollbarRef}
                cursorRef={cursorRef}
                getCellParams={tableCellsStorage.getCellParams}
                selectCell={selectCell}
                onBodyScroll={onBodyScroll}
                onLeftFixedColumnsScroll={onLeftFixedColumnsScroll}
                onRightFixedColumnsScroll={onRightFixedColumnsScroll}
                onStickyScrollbarScroll={onStickyScrollbarScroll}
                onCellEvent={onCellEvent}
            />
        </div>
    );
};
