import { LiteralUnion, RegisterOptions, UseControllerReturn } from 'react-hook-form';

import { placeholder } from '@common/Utils';

import { FormField, getCharCountText } from '@common/components';

export const errorInputTypes: Partial<Record<LiteralUnion<keyof RegisterOptions, string>, string>> = {
    required: 'Обязательное поле',
    maxLength: 'Максимум {maxLength}',
    minLength: 'Минимум {minLength}',
};

export function getFormErrorMessage(formProps: UseControllerReturn, data: FormField) {
    if (!formProps?.fieldState?.error) return null;

    const errorData = formProps.fieldState.error;

    if (errorData.message) return errorData.message;
    if (data.patternMessage && errorData.type === 'pattern') return data.patternMessage;

    return placeholder(errorInputTypes[errorData.type as any] || 'Неизвестная ошибка', {
        ...data,
        maxLength: data.maxLength && getCharCountText(data.maxLength),
        minLength: data.minLength && getCharCountText(data.minLength),
    });
}
