import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, IconType, WithTooltip, TooltipAnchor } from 'sber-marketing-ui';

import { StoreState } from '@store';
import {
    InternalTransferDirection,
    CellPosition,
    getBudgetTransferMenuState,
    toggleLineStatus,
    getLinesMovebaleToDonor,
} from '@store/budgetExecution/budgetTransferMenu';

import { CellDescription, CellDescriptionTemplates } from '../../../CellDescription';

import * as styles from './Lines.scss';

interface Props {
    itemClass: string;
}

const noResultStub: CellPosition[] = [];

function useStore() {
    const from = useSelector((state: StoreState) => getBudgetTransferMenuState(state).cells.from);
    const to = useSelector((state: StoreState) => getBudgetTransferMenuState(state).cells.to);
    const internalTransferDirection = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.internalTransferDirection,
    );

    let lines: CellPosition[];
    switch (internalTransferDirection) {
        case InternalTransferDirection.OneToMany:
            lines = to;
            break;
        case InternalTransferDirection.ManyToOne:
            lines = from;
            break;
        default:
            lines = noResultStub;
            break;
    }

    return lines;
}

export function Lines(props: Props): JSX.Element {
    const lines = useStore();

    return (
        <React.Fragment>
            {lines.map((line) => (
                <Line {...props} key={line.lineId} cell={line} />
            ))}
        </React.Fragment>
    );
}

interface LineProps extends Props {
    cell: CellPosition;
}

function useLineInteractivity(props: LineProps) {
    const { cell } = props;
    const dispatch = useDispatch();
    const ref = React.useRef<HTMLDivElement>();

    const linesMovableToDonor = useSelector((state: StoreState) => getLinesMovebaleToDonor(state));
    const canMoveLineToDonor = linesMovableToDonor.includes(cell.lineId);
    const internalTransferDirection = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.internalTransferDirection,
    );
    const isTransferDirectionToggleHovered = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.isTransferDirectionToggleHovered,
    );
    const lineIsFirstIsNonMovable = useSelector((state: StoreState) => {
        const { to } = getBudgetTransferMenuState(state).cells;

        const nonMovableLines = to
            .filter((toCell) => !linesMovableToDonor.includes(toCell.lineId))
            .map((toCell) => toCell.lineId);

        return nonMovableLines[0] === cell.lineId;
    });

    const [isHovered, setIsHovered] = React.useState(false);

    function deleteCell() {
        dispatch(toggleLineStatus(cell.lineId));
    }

    let template: CellDescriptionTemplates;
    switch (internalTransferDirection) {
        case InternalTransferDirection.OneToMany:
            template =
                isTransferDirectionToggleHovered && !canMoveLineToDonor
                    ? CellDescriptionTemplates.LineWarningCannotTurnToDonor
                    : CellDescriptionTemplates.IdWithLineReservePlanDiff;
            break;
        case InternalTransferDirection.ManyToOne:
            template = CellDescriptionTemplates.IdWithLinePlanReserveDiff;
            break;
        default:
            break;
    }

    React.useEffect(() => {
        if (lineIsFirstIsNonMovable && ref.current) {
            ref.current.scrollIntoView();
        }
    }, [isTransferDirectionToggleHovered]);

    return {
        ref,
        isHovered,
        template,
        setIsHovered,
        deleteCell,
    };
}

function Line(props: LineProps): JSX.Element {
    const { ref, isHovered, template, setIsHovered, deleteCell } = useLineInteractivity(props);

    return (
        <div
            ref={ref}
            className={props.itemClass}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <CellDescription qaId="internalTaransitionLineSelectionLine" cell={props.cell} template={template} />

            {isHovered && (
                <div
                    className={styles.deleteButtonWrapper}
                    onClick={deleteCell}
                    {...{
                        'qa-id': 'internalTransitionLineSelectedLineDeleteButton',
                    }}
                >
                    <WithTooltip content="Убрать строку" anchor={TooltipAnchor.LEFT}>
                        <div className={styles.deleteButton}>
                            <Icon type={IconType.CLOSE_BUTTON_ROUNDED} />
                        </div>
                    </WithTooltip>
                </div>
            )}
        </div>
    );
}
