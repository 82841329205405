import * as React from 'react';
import classNames from 'classnames';

import { useDefaultRef } from '@common/hooks';

import { Flex } from '@common/components';

import { Label } from '../../layout';
import { Tag, TagProps } from '../Tag';

import * as styles from './InputBase.scss';

export type SimpleInputValues = string | number;
export type InputValues = SimpleInputValues | SimpleInputValues[];

export type InputBaseOnChangeHandler<V extends InputValues, E extends InputElements> = (
    value: V,
    e: React.ChangeEvent<E>,
) => void;
export type InputElements = HTMLInputElement | HTMLTextAreaElement;
export type RenderInput<V extends InputValues, E extends InputElements> = (
    props: InputRenderProps<V, E>,
) => React.ReactNode;

export interface InputOnlyProps<V extends InputValues, E extends InputElements> {
    value?: V;
    defaultValue?: V;
    name?: string;
    error?: string;
    placeholder?: string;
    readOnly?: boolean;
    onFocus?: React.FocusEventHandler<E>;
    onBlur?: React.FocusEventHandler<E>;
}

export type InputRenderProps<V extends InputValues, E extends InputElements> = InputOnlyProps<V, E> &
    Omit<React.HTMLProps<E>, 'value'> & {
        disabled?: boolean;
        ref?: React.MutableRefObject<E>;
        onChange?: React.ChangeEventHandler<E>;
    };

export interface InputBaseProps<V extends InputValues, E extends InputElements>
    extends Omit<TagProps, 'onFocus' | 'onBlur' | 'onChange' | 'relative' | 'value' | 'label' | 'defaultValue'>,
        InputOnlyProps<V, E> {
    renderInput: RenderInput<V, E>;
    label?: React.ReactNode;
    required?: boolean;
    inputRequired?: boolean;
    labelRequired?: boolean;
    before?: React.ReactNode;
    after?: React.ReactNode;
    selectOnFocus?: boolean;
    loading?: boolean;
    inputRef?: React.MutableRefObject<E>;
    inputProps?: React.HTMLProps<E>;
    inputClassName?: string;
    onChange?: InputBaseOnChangeHandler<V, E>;
}

export function InputBase<V extends InputValues, E extends InputElements>({
    value,
    label,
    required,
    inputRequired = required,
    labelRequired = required,
    placeholder,
    className,
    before,
    after,
    children,
    inputRef: inputRefProp,
    inputClassName,
    readOnly,
    inputProps,
    selectOnFocus,
    ghost,
    type,
    loading,
    defaultValue,
    renderInput,
    name,
    error,
    onChange,
    onFocus,
    onBlur,
    ...props
}: InputBaseProps<V, E>) {
    const inputRef = useDefaultRef(inputRefProp);

    const handleChange: React.ChangeEventHandler<E> = (e) => {
        onChange?.(e.target.value as V, e);
    };

    const handleFocus: typeof onFocus = (e) => {
        onFocus?.(e);

        if (selectOnFocus) {
            setTimeout(() => {
                if (inputRef.current instanceof HTMLInputElement) {
                    inputRef.current.select();
                }
            }, 200);
        }
    };

    const handleBlur: typeof onBlur = (e) => {
        onBlur?.(e);
    };

    return (
        <Tag
            {...props}
            type={error ? 'danger' : type}
            ghost={ghost}
            relative
            loading={loading}
            className={classNames(
                styles.root,
                { [styles.loading]: loading, [styles.withLabel]: label || error, [styles.ghost]: ghost },
                className,
            )}
        >
            {before}
            {children}
            {renderInput({
                ...inputProps,
                name,
                required: inputRequired,
                disabled: props.disabled,
                readOnly,
                value,
                defaultValue: defaultValue as any,
                ref: inputRef,
                className: classNames(styles.input, readOnly && styles.readonly, inputClassName, inputProps?.className),
                placeholder,
                onBlur: handleBlur,
                onFocus: handleFocus,
                onChange: handleChange,
            })}
            <div className={styles.background} />
            <div className={styles.border} />
            {after}
            {label || error ? (
                <Flex align="flex-end" justify="space-between" className={styles.header}>
                    {label && (
                        <Label style={{ minWidth: 40 }} flex required={labelRequired}>
                            <div className={styles.label}>{label}</div>
                        </Label>
                    )}
                    {error && <span className={styles.error}>{error}</span>}
                </Flex>
            ) : null}
        </Tag>
    );
}
