import * as React from 'react';

import { Item } from './Item';
import * as styles from './styles.scss';

interface Props {
    items: { id: string; value: string; selected: boolean; disabled: boolean }[];
    maxValueLength: number;
    onItemReload: (id: string) => void;
    onItemSelect: (params: { id: string; value: boolean }) => void;
    onItemChange: (params: { id: string; value: string }) => void;
}

export const Content: React.FC<Props> = ({ items, maxValueLength, onItemSelect, onItemReload, onItemChange }) => {
    return (
        <div className={styles.root}>
            {items.map(({ id, value, selected, disabled }) => (
                <Item
                    key={id}
                    disabled={disabled}
                    selected={selected}
                    value={value}
                    maxValueLength={maxValueLength}
                    onReload={() => onItemReload(id)}
                    onSelect={(value) => onItemSelect({ id, value })}
                    onChange={(value) => onItemChange({ id, value })}
                />
            ))}
        </div>
    );
};
