import * as React from 'react';
import { WithGlobalPopup, AddaptivePositionCalculator, Skeleton } from 'sber-marketing-ui';
import classNames from 'classnames';

import * as style from './WithEditor.scss';

interface Props {
    canEdit: boolean;
    loadingClassName: string;
    valueContent: () => JSX.Element;
    editorContent: (onValueEdit: (value: any) => Promise<void>) => JSX.Element;
    editValue: (value: any) => Promise<void>;
}

function useInteractivity({ canEdit, editValue }: Props) {
    const ref = React.useRef();
    const [isEditorOpened, saveIsEditorOpened] = React.useState(false);
    const [isPreloaderVisible, setIsPreloaderVisible] = React.useState(false);

    const setIsEditorOpened = React.useCallback(
        (event: React.MouseEvent | MouseEvent, isEditorOpened: boolean) => {
            if (canEdit) {
                event.preventDefault();
                event.stopPropagation();

                saveIsEditorOpened(isEditorOpened);
            }
        },
        [canEdit, saveIsEditorOpened],
    );

    const onValueEdit = React.useCallback(
        async (value) => {
            if (editValue) {
                setIsPreloaderVisible(true);
                await editValue(value);
                setIsPreloaderVisible(false);
            }

            saveIsEditorOpened(false);
        },
        [editValue, saveIsEditorOpened],
    );

    return {
        ref,
        isEditorOpened,
        isPreloaderVisible,
        setIsEditorOpened,
        onValueEdit,
    };
}

export function WithEditor(props: Props): JSX.Element {
    const { canEdit, loadingClassName, valueContent, editorContent } = props;

    const { ref, isEditorOpened, isPreloaderVisible, setIsEditorOpened, onValueEdit } = useInteractivity(props);

    return (
        <Skeleton isReady={!isPreloaderVisible} loadingClassName={loadingClassName}>
            <div
                ref={ref}
                className={classNames(style.root, canEdit && style.rootActive)}
                onClick={(event: React.MouseEvent<HTMLDivElement>) => setIsEditorOpened(event, true)}
            >
                {valueContent()}

                {isEditorOpened && (
                    <WithGlobalPopup
                        container={ref}
                        positionCalculator={AddaptivePositionCalculator}
                        maskZIndex={20}
                        onMaskClick={(event: MouseEvent) => setIsEditorOpened(event, false)}
                    >
                        {editorContent(onValueEdit)}
                    </WithGlobalPopup>
                )}
            </div>
        </Skeleton>
    );
}
