import * as React from 'react';
import classNames from 'classnames';

import * as style from './CalendarUploadButton.scss';

import { Icon, IconType, OpacityTransition, WithTooltip, TooltipAnchor } from 'sber-marketing-ui';

interface Props {
    preloader: boolean;
    disabled: boolean;
    onClick(): void;
}

export const CalendarUploadButton: React.FC<Props> = ({ preloader, disabled, onClick }) => {
    return (
        <OpacityTransition start={!preloader}>
            <div className={classNames(style.root, disabled && style.disabled)} onClick={!disabled ? onClick : null}>
                <WithTooltip content="Загрузить календарь" anchor={TooltipAnchor.LEFT}>
                    <Icon type={IconType.UPLOADING_CALENDAR} svgSize={24} />
                </WithTooltip>
            </div>
        </OpacityTransition>
    );
};
