// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetPlanning-ColumnFixingMenu-_ColumnFixingMenu__root{margin:12px 0 12px 8px;width:100%}.src-client-modules-budget-BudgetPage-BudgetPlanning-ColumnFixingMenu-_ColumnFixingMenu__root:focus{outline:0}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetPlanning/ColumnFixingMenu/ColumnFixingMenu.scss"],"names":[],"mappings":"AAAA,8FACI,sBAAuB,CACvB,UAAW,CAFf,oGAKQ,SAAU","sourcesContent":[".root {\n    margin: 12px 0 12px 8px;\n    width: 100%;\n\n    &:focus {\n        outline: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetPlanning-ColumnFixingMenu-_ColumnFixingMenu__root"
};
export default ___CSS_LOADER_EXPORT___;
