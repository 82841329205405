// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-tags-TagsPage-Filters-YearsFilter-_YearsFilter__root{width:100%;display:flex;align-items:baseline}.src-client-modules-tags-TagsPage-Filters-YearsFilter-_YearsFilter__title{margin-right:8px}.src-client-modules-tags-TagsPage-Filters-YearsFilter-_YearsFilter__items{display:flex;align-items:baseline;flex-wrap:wrap;gap:6px 8px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/tags/TagsPage/Filters/YearsFilter/YearsFilter.scss"],"names":[],"mappings":"AAAA,yEACI,UAAW,CAEX,YAAa,CACb,oBAAqB,CACxB,0EAGG,gBAAiB,CACpB,0EAGG,YAAa,CACb,oBAAqB,CACrB,cAAe,CAEf,WAAY","sourcesContent":[".root {\n    width: 100%;\n    \n    display: flex;\n    align-items: baseline;\n}\n\n.title {\n    margin-right: 8px;\n}\n\n.items {\n    display: flex;\n    align-items: baseline;\n    flex-wrap: wrap;\n\n    gap: 6px 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-tags-TagsPage-Filters-YearsFilter-_YearsFilter__root",
	"title": "src-client-modules-tags-TagsPage-Filters-YearsFilter-_YearsFilter__title",
	"items": "src-client-modules-tags-TagsPage-Filters-YearsFilter-_YearsFilter__items"
};
export default ___CSS_LOADER_EXPORT___;
