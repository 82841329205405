// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-FactCorrectionCard-_FactCorrectionCard__subInfo{position:relative;display:flex;flex-direction:column;height:100%;padding:8px 22px 9px;background-color:#f6f6f6}.src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-FactCorrectionCard-_FactCorrectionCard__subInfoTitle{margin-bottom:6px;letter-spacing:0.2px;color:#000000;font-family:'Open Sans';font-size:12px;font-weight:600}.src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-FactCorrectionCard-_FactCorrectionCard__subInfoContent{display:flex;flex-direction:column;height:100%;padding-bottom:16px;justify-content:space-between}.src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-FactCorrectionCard-_FactCorrectionCard__commonFondsChangeWrapper{margin-top:auto}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetCorrections/CorrectionsList/CardTypes/FactCorrectionCard/FactCorrectionCard.scss"],"names":[],"mappings":"AAAA,kIACI,iBAAkB,CAElB,YAAa,CACb,qBAAsB,CAEtB,WAAY,CACZ,oBAAqB,CAErB,wBAAyB,CAC5B,uIAGG,iBAAkB,CAElB,oBAAqB,CAErB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CACf,eAAgB,CACnB,yIAGG,YAAa,CACb,qBAAsB,CAEtB,WAAY,CACZ,mBAAoB,CAEpB,6BAA8B,CACjC,mJAGG,eAAgB","sourcesContent":[".subInfo {\n    position: relative;\n\n    display: flex;\n    flex-direction: column;\n\n    height: 100%;\n    padding: 8px 22px 9px;\n\n    background-color: #f6f6f6;\n}\n\n.subInfoTitle {\n    margin-bottom: 6px;\n\n    letter-spacing: 0.2px;\n\n    color: #000000;\n\n    font-family: 'Open Sans';\n    font-size: 12px;\n    font-weight: 600;\n}\n\n.subInfoContent {\n    display: flex;\n    flex-direction: column;\n\n    height: 100%;\n    padding-bottom: 16px;\n\n    justify-content: space-between;\n}\n\n.commonFondsChangeWrapper {\n    margin-top: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subInfo": "src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-FactCorrectionCard-_FactCorrectionCard__subInfo",
	"subInfoTitle": "src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-FactCorrectionCard-_FactCorrectionCard__subInfoTitle",
	"subInfoContent": "src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-FactCorrectionCard-_FactCorrectionCard__subInfoContent",
	"commonFondsChangeWrapper": "src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-CardTypes-FactCorrectionCard-_FactCorrectionCard__commonFondsChangeWrapper"
};
export default ___CSS_LOADER_EXPORT___;
