import * as React from 'react';
import classnames from 'classnames';

import { useStyleContext } from '../BudgetTransferMenu';

import { LinesWithNegativeBalance } from './LinesWithNegativeBalance';
import { ControlButtons } from './ControlButtons';

import * as styles from './InternalTransitionNegativeLineBalanceWarning.scss';

export function InternalTransitionNegativeLineBalanceWarning(): JSX.Element {
    const paddingClass = useStyleContext();

    return (
        <div
            className={classnames(styles.root, paddingClass)}
            {...{
                'qa-id': 'internalTransitionNegativeLineBalanceWarning',
            }}
        >
            <div className={styles.title}>
                Есть строки, у которых План-Резерв за год станет отрицательным.
                <br />
                Уверены, что хотите отправить на согласование?
            </div>

            <LinesWithNegativeBalance />

            <ControlButtons />
        </div>
    );
}
