import * as React from 'react';
import * as lodash from 'lodash';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';

import { StoreState } from '@store';
import { OrderType, ColumnFilters, CellValueType, ColumnName, getUpdatedFIlters } from '@store/budgetExecution';

import { HeaderDropdownCell, DropdownContent } from './HeaderDropdownCell';

interface Props extends OwnProps, Partial<MapProps> {}
interface OwnProps {
    name: ColumnName;
    title: string;
    currencySearch: boolean;
    filters: ColumnFilters;
    columnValueType: CellValueType;
    orderType: OrderType;
    isColumnFixed: boolean;
    isResizingColumn: boolean;
    onOpenStatusChange: (dropdownContent: JSX.Element) => void;
    onSortingChange: (sortingOrder: OrderType) => void;
    onSelectionChange: (filters: ColumnFilters) => void;
    onColumnFixChange?: () => void;
    onApplyFiltersButtonClick: () => void;
}

interface MapProps {
    isFiltered: boolean;
}

interface State {
    isOpened: boolean;
    isHovered: boolean;
}

@(connect(mapStateToProps) as any)
export class HeaderDropdownCellContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isHovered: false,
            isOpened: false,
        };
    }

    public render(): JSX.Element {
        const { title, orderType, name, isFiltered, isColumnFixed, isResizingColumn } = this.props;
        const { isOpened, isHovered } = this.state;

        return React.createElement(HeaderDropdownCell, {
            name,
            title,
            orderType,
            isFiltered,
            isResizingColumn,
            isOpened,
            isHovered,
            isColumnFixed,
            onColumnFixChange: this.onColumnFixChange,
            onOpenerClick: this.onOpenerClick,
            onMaskClick: this.onMaskClick,
            onMouseEnter: this.onMouseEnter,
            onMouseLeave: this.onMouseLeave,
        });
    }

    @autobind
    protected onOpenerClick() {
        const {
            name,
            filters,
            columnValueType,
            currencySearch,
            orderType,
            isColumnFixed,
            onSortingChange,
            onSelectionChange,
            onOpenStatusChange,
        } = this.props;

        this.setState(
            {
                isOpened: !this.state.isOpened,
            },
            () => {
                if (onOpenStatusChange) {
                    onOpenStatusChange(
                        this.state.isOpened
                            ? React.createElement(DropdownContent, {
                                  columnName: name,
                                  filters,
                                  currencySearch,
                                  columnValueType,
                                  orderType,
                                  isColumnFixed,
                                  onSortingChange,
                                  onSelectionChange,
                                  onColumnFixChange: this.onColumnFixChange,
                                  onApplyFiltersButtonClick: this.onApplyFiltersButtonClick,
                              })
                            : null,
                    );
                }
            },
        );
    }

    @autobind
    protected onMaskClick() {
        this.setState(
            {
                isOpened: false,
                isHovered: false,
            },
            () => {
                if (this.props.onOpenStatusChange) {
                    this.props.onOpenStatusChange(null);
                }
            },
        );
    }

    @autobind
    protected onColumnFixChange() {
        this.setState(
            {
                isOpened: false,
            },
            () => {
                this.props.onColumnFixChange();

                if (this.props.onOpenStatusChange) {
                    this.props.onOpenStatusChange(null);
                }
            },
        );
    }

    @autobind
    private onMouseEnter() {
        this.setState({
            isHovered: true,
        });
    }

    @autobind
    private onMouseLeave() {
        this.setState({
            isHovered: false,
        });
    }

    @autobind
    private onApplyFiltersButtonClick(): void {
        this.onMaskClick();
        this.props.onApplyFiltersButtonClick();
    }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): MapProps {
    const filters = getUpdatedFIlters(state)[ownProps.name];
    const isFiltered = lodash.some(filters, (filter) => filter == true);

    return {
        isFiltered,
    };
}
