import * as React from 'react';
import * as moment from 'moment';

import * as style from './RangeDatepickerCell.scss';

import { DatepickerContent } from 'sber-marketing-ui';
import { CellEditorWrapper } from '../CellEditorWrapper';

interface Props {
    dates: [Date, Date];
    onValueChange: (value: [Date, Date]) => void;
}

export const RangeDatepickerCellEdit = ({ dates, onValueChange }: Props): JSX.Element => {
    const [selectedStartDate, setSelectedStartDate] = React.useState<Date>(null);
    const [hoveredStartDate, setHoveredStartDate] = React.useState<Date>(null);
    const [hoveredEndDate, setHoveredEndDate] = React.useState<Date>(null);

    const onDateHover = React.useCallback(
        (date: Date) => {
            if (!selectedStartDate) {
                setHoveredStartDate(date);
            } else {
                setHoveredStartDate(null);
                setHoveredEndDate(date);
            }
        },
        [selectedStartDate],
    );

    const onDateChange = React.useCallback(
        (value: [Date, Date]) => {
            const [date] = value;

            if (!selectedStartDate) {
                setHoveredStartDate(null);
                setSelectedStartDate(date);
            } else {
                onValueChange([selectedStartDate, hoveredEndDate]);
            }
        },
        [selectedStartDate, hoveredEndDate],
    );

    // const highlightDates = [];

    // if (selectedStartDate) {
    //     highlightDates.push(selectedStartDate);
    // }

    return (
        <div className={style.root}>
            {!selectedStartDate && !hoveredStartDate && (
                <span className={style.noSelectionMessage}>Выберите период</span>
            )}

            {selectedStartDate && (
                <>
                    {formatDate(selectedStartDate)}
                    <br />
                </>
            )}

            {!selectedStartDate && hoveredStartDate && (
                <span className={style.hoveredDate}>{formatDate(hoveredStartDate)}</span>
            )}

            {selectedStartDate && hoveredEndDate && (
                <span className={style.hoveredDate}>{formatDate(hoveredEndDate)}</span>
            )}

            <CellEditorWrapper>
                <DatepickerContent
                    minDate={selectedStartDate ? moment(selectedStartDate) : null}
                    // highlightDates={getPeriodDates(selectedStartDate || hoveredStartDate, hoveredEndDate)}
                    // highlightDates={selectedStartDate ? [selectedStartDate] : []}
                    startDate={selectedStartDate || null}
                    customDateFormat={'DD MMMM YYYY'}
                    onChange={onDateChange}
                    onHover={onDateHover}
                    shouldCloseOnSelect={false}
                    selectsRange
                />
            </CellEditorWrapper>
        </div>
    );
};

function getPeriodDates(startDate: Date, endDate: Date): Date[] {
    let periodDates: Date[];

    if (!startDate) {
        periodDates = [];
    } else if (!endDate) {
        periodDates = [startDate];
    } else {
        let currentDate = startDate;

        while (currentDate <= endDate) {
            periodDates.push(new Date(currentDate));
            currentDate = addDays(currentDate, 1);
        }
    }

    return periodDates;
}

function addDays(value: Date, days: number): Date {
    const date = new Date(value.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

function formatDate(date: Date): string {
    return moment(date).format('DD.MM.YY');
}
