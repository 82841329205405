import type { ChanelObject } from 'sber-marketing-types/frontend';

import { rtkApi } from './rtkApi';
import { taskApi } from './task';

export interface AddChannelPathParams {
    taskId: string;
}

export interface ChannelPathParams extends AddChannelPathParams {
    channelId: number;
}

export interface DeleteChannelMutation extends ChannelPathParams {}
export interface AddChannelMutation extends AddChannelPathParams {
    title: string;
    participantIds: string;
}

export interface EditChannelMutation extends AddChannelMutation, ChannelPathParams {}

export const channelApi = rtkApi.injectEndpoints({
    endpoints: (builder) => ({
        deleteChannel: builder.mutation<void, DeleteChannelMutation>({
            query: ({ taskId, channelId }) => ({
                url: `/task/${taskId}/chanel/${channelId}`,
                method: 'DELETE',
            }),
            async onQueryStarted({ taskId, channelId }, { dispatch, queryFulfilled }) {
                const patches = dispatch(
                    taskApi.util.updateQueryData('getTask', { id: taskId }, (draft) => {
                        draft.task.chanels = draft.task.chanels.filter(({ id }) => id !== channelId);
                    }),
                );

                try {
                    await queryFulfilled;
                } catch {
                    patches.undo();
                }
            },
        }),
        addChannel: builder.mutation<ChanelObject, AddChannelMutation>({
            query: ({ taskId, ...body }) => ({
                url: `/task/${taskId}/chanel`,
                method: 'POST',
                body,
            }),
            async onQueryStarted({ taskId }, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;

                try {
                    dispatch(
                        taskApi.util.updateQueryData('getTask', { id: taskId }, (draft) => {
                            const [mainChannel, ...restChannels] = [...draft.task.chanels].reverse();
                            draft.task.chanels = [...restChannels.reverse(), data, mainChannel];
                        }),
                    );
                } catch (e) {
                    console.error(e);
                }
            },
        }),
        editChannel: builder.mutation<ChanelObject, EditChannelMutation>({
            query: ({ taskId, channelId, ...body }) => ({
                url: `/task/${taskId}/chanel/${channelId}`,
                method: 'PUT',
                body,
            }),
            async onQueryStarted({ taskId, channelId }, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;

                try {
                    dispatch(
                        taskApi.util.updateQueryData('getTask', { id: taskId }, (draft) => {
                            Object.assign(
                                draft.task.chanels.find(({ id }) => id === channelId),
                                data,
                            );
                        }),
                    );
                } catch (e) {
                    console.error(e);
                }
            },
        }),
    }),
});

export const { useDeleteChannelMutation, useAddChannelMutation, useEditChannelMutation } = channelApi;
