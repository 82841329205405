import * as React from 'react';
import autobind from 'autobind-decorator';

import { TabBar } from './TabBar';

interface Props {}

interface State {
    selectedTabIndex: number;
}

export class TabBarContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedTabIndex: 0,
        };
    }

    public render(): JSX.Element {
        return React.createElement(
            TabBar,
            {
                selectedTabIndex: this.state.selectedTabIndex,
                onChangeSelectedTabIndex: this.onChangeSelectedTabIndex,
            },
            this.props.children,
        );
    }

    @autobind
    private onChangeSelectedTabIndex(tabIndex: number): void {
        this.setState({ selectedTabIndex: tabIndex });
    }
}
