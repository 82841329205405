import { CSSProperties } from 'react';

export interface Point {
    x: number;
    y: number;
}

export interface Size {
    width: number;
    height: number;
}

export interface CellPosition {
    columnName: ColumnName;
    lineId: LineId;
}

export interface ColumnHeaderParams {
    title: string;
    style?: CSSProperties;
}

export interface CellParams {
    title?: React.ReactText;
    hoverTitle?: React.ReactText;
    tooltipTitle?: React.ReactText;
    description?: React.ReactText;
    style?: CSSProperties;
}

export type ColumnName = string;
export type LineId = string;

export type ColumnWidths = Record<ColumnName, number>;
export type LineHeights = Record<ColumnName, number>;

export type ColumnHeadersStorage = Record<ColumnName, ColumnHeaderParams>;
export type CellsStorage = Record<LineId, Record<ColumnName, CellParams>>;

export enum TableEvent {
    Scroll = 'scroll',
    SizeChange = 'size_change',
}
