import * as React from 'react';
import autobind from 'autobind-decorator';

import { LexicalTextarea } from './LexicalTextarea';
import { Mentionable } from 'sber-marketing-ui';

export interface Props {
    qaId?: string;
    title: string;
    value?: string;
    errorMessage?: string;
    displayError?: boolean;
    onPaste?: React.ClipboardEventHandler<HTMLTextAreaElement>;
    onValueChange?: (value: string) => void;
    readOnly?: boolean;
    disabled?: boolean;
    focusOnValueChange?: boolean;
    shortLexical?: boolean;
    mentions?: any[];
    onFocus?: () => void | Promise<void>;
    onBlur?: () => void | Promise<void>;
    textWrapperRef?: (element: HTMLDivElement) => void;
    handleValueChange?: () => void;
}

interface State {
    value: string;
    isFocused: boolean;
}

export class LexicalTextareaContainer extends React.Component<Props, State> implements Mentionable {
    constructor(props: Props) {
        super(props);

        this.state = {
            value: this.props.value,
            isFocused: false,
        };
    }

    public UNSAFE_componentWillReceiveProps(newProps: Props) {
        if (newProps.value !== this.state.value) {
            this.setState({
                value: newProps.value,
            });
        }
    }

    public render(): JSX.Element {
        const {
            qaId,
            title,
            errorMessage,
            displayError,
            readOnly,
            disabled,
            focusOnValueChange,
            shortLexical,
            mentions,
            onPaste,
        } = this.props;

        const { value, isFocused } = this.state;

        return React.createElement(LexicalTextarea, {
            qaId,
            title,
            value,
            errorMessage,
            displayError,
            isFocused,
            mentions,
            onPaste,
            onInputFocus: this.handleInputFocus,
            onInputBlur: this.handleInputBlur,
            onInputChange: this.handleInputChange,
            readOnly,
            disabled,
            focusOnValueChange,
            shortLexical,
            textWrapperRef: this.props.textWrapperRef,
        });
    }

    @autobind
    public getValue(): string {
        return this.state.value;
    }

    @autobind
    public setValue(value: string) {
        this.setState(
            {
                value,
            },
            () => {
                if (this.props.onValueChange) {
                    this.props.onValueChange(value);
                }
            },
        );
    }

    @autobind
    public getTextElement(): HTMLInputElement | HTMLTextAreaElement {
        return null;
    }

    @autobind
    protected handleInputFocus() {
        this.setState(
            {
                isFocused: true,
            },
            () => {
                if (this.props.onFocus) {
                    this.props.onFocus();
                }
            },
        );
    }

    @autobind
    protected handleInputBlur() {
        this.setState(
            {
                isFocused: false,
            },
            () => {
                if (this.props.onBlur) {
                    this.props.onBlur();
                }
            },
        );
    }

    @autobind
    protected handleInputChange(value: string) {
        if (this.props.onValueChange) {
            this.props.onValueChange(value);
        }

        if (this.props.handleValueChange) {
            this.props.handleValueChange();
        }
        this.setState({
            value,
        });
    }
}
