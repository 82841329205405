import * as React from 'react';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';
import { UserResponseParams } from 'sber-marketing-types/frontend';
import { BudgetStatus } from '@mrm/budget';
import { PlainDictionary, DictionaryType } from '@mrm/dictionary';
import { Tag } from '@mrm/tags';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getTagsState } from '@store/tags';
import { getBudgetByStatusUserConfig } from '@store/userConfig/budget';
import {
    OrderType,
    ColumnName,
    getUpdatedFIlters,
    getBudgetExecutionPageState,
    loadFilters,
    LoadFiltersPaylaod,
    loadFiltersByColumn,
} from '@store/budgetExecution';

import { Sorting } from '@budgetCommon/SortingMenu';

import { ColumnsList } from '../ColumnsConfig';

import { FilterMenu, Filters } from './FilterMenu';

interface Props extends OwnProps, Partial<MapProps & DispatchProps> {}

interface OwnProps {
    userConfigFilters: Filters;
    columnName: ColumnName;
    currencySearch: boolean;
    filterNamesAreNumeral?: boolean;
    sortingOrder?: OrderType;
    isColumnFixed?: boolean;
    disableSorting?: boolean;
    disableSearch?: boolean;
    disableColumnFix?: boolean;
    onSortingChange?: (sortingOrder: OrderType) => void;
    onSelectionChange?: (filters: Filters) => void;
    onColumnFixChange?: (checked: boolean) => void;
    onApplyFiltersButtonClick: () => void;
}

interface MapProps {
    showPreloader: boolean;
    areFiltersLoaded: boolean;
    budgetId: string;
    filters?: Filters;
    users: UserResponseParams[];
    dictionaryType: DictionaryType;
    dictionaries: Record<string, PlainDictionary>;
    showApplyFiltersButton: boolean;
    tags: lodash.Dictionary<Tag>;
}

interface DispatchProps {
    loadFiltersByColumn: () => void;
    loadFilters: (payload: LoadFiltersPaylaod) => void;
}

interface State {
    sortingOrder: OrderType;
    selectedFilters: React.ReactText[];
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class FilterMenuContainer extends React.Component<Props, State> {
    private root: HTMLDivElement;

    public constructor(props: Props) {
        super(props);

        this.state = {
            sortingOrder: props.sortingOrder,
            selectedFilters: Object.keys(props.filters).filter((filterItem) => props.filters[filterItem]),
        };
    }

    public async componentDidMount(): Promise<void> {
        this.root.focus();

        this.props.loadFiltersByColumn();
    }

    public render(): JSX.Element {
        const {
            showPreloader,
            dictionaryType,
            columnName,
            users,
            filters,
            currencySearch,
            isColumnFixed,
            filterNamesAreNumeral,
            disableSorting,
            disableColumnFix,
            dictionaries,
            showApplyFiltersButton,
            tags,
            onApplyFiltersButtonClick,
        } = this.props;

        const { sortingOrder, selectedFilters } = this.state;

        return React.createElement(FilterMenu, {
            showPreloader,
            dictionaryType,
            columnName,
            users,
            dictionaries,
            filters,
            currencySearch,
            selectedFilters: [...selectedFilters],
            filterNamesAreNumeral,
            sortingOrder,
            isColumnFixed,
            disableSorting,
            disableFilter: lodash.isEmpty(filters),
            disableColumnFix,
            showApplyFiltersButton,
            tags,
            onColumnFixToggle: this.onColumnFixToggle,
            rootRef: this.rootRef,
            onSortingChange: this.onSortingChange,
            onFiltersChange: this.onFiltersChange,
            onApplyFiltersButtonClick,
        });
    }

    @autobind
    private rootRef(element: HTMLDivElement) {
        this.root = element;
    }

    @autobind
    private onColumnFixToggle() {
        this.props.onColumnFixChange(!this.props.isColumnFixed);
    }

    @autobind
    private onSortingChange(sorting: Sorting): void {
        const sortingOrder = sorting === Sorting.Asc ? OrderType.Asc : OrderType.Desc;

        this.setState(
            {
                sortingOrder,
            },
            () => this.props.onSortingChange(sortingOrder),
        );
    }

    @autobind
    private onFiltersChange(selectedFilters: string[]): void {
        const updatedFilters = lodash.clone(this.props.filters);

        for (const filter in updatedFilters) {
            updatedFilters[filter] = selectedFilters.includes(filter);
        }

        this.props.loadFilters({
            columnName: this.props.columnName,
            filters: updatedFilters,
        });

        this.setState(
            {
                selectedFilters,
            },
            () => this.props.onSelectionChange(updatedFilters),
        );
    }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): MapProps {
    const { columnName } = ownProps;

    const {
        pageData: { allUsers: users, allDictionaries },
        previouslyLoadedFilters,
        columnFilters,
    } = getBudgetExecutionPageState(state);
    const { budgetId } = getBudgetByStatusUserConfig(state, BudgetStatus.Execution);
    const column = ColumnsList.find((column) => column.name === columnName);
    const dictionaryType: DictionaryType = column.metaData && column.metaData.dictionaryType;

    const filters = getUpdatedFIlters(state)[columnName];
    const areFiltersLoaded = !lodash.isEmpty(filters);

    const showApplyFiltersButton = !lodash.isEqual(columnFilters.filters, previouslyLoadedFilters);

    const tags = getTagsState(state).byId.dictionary;

    const showPreloader = columnFilters.loadingStatus[columnName] !== LoadingStatus.LOADED;

    return {
        showPreloader,
        dictionaryType,
        users,
        dictionaries: allDictionaries.byId,
        areFiltersLoaded,
        budgetId,
        filters,
        showApplyFiltersButton,
        tags,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps {
    const { columnName } = ownProps;

    return {
        loadFilters: (payload: LoadFiltersPaylaod) => dispatch(loadFilters(payload)),
        loadFiltersByColumn: () => dispatch(loadFiltersByColumn(columnName)),
    };
}
