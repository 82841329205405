import * as React from 'react';
import autobind from 'autobind-decorator';
import { isNil } from 'lodash';

import { AddAsset } from './AddAsset';

/** Add asset container component properties */
export interface AddAssetContainerProps {
    /** If "true" upload input is disabled */
    isDisabled?: boolean;
    /** Additional CSS class */
    className?: string;
    /** File add event handler */
    onFileAdd?(file: FileList): void | Promise<void>;
}

/** Add asset container component */
export class AddAssetContainer extends React.Component<AddAssetContainerProps> {
    /** Input element */
    private inputElement: HTMLInputElement | null = null;
    /** @override */
    public render(): JSX.Element {
        return (
            <AddAsset
                inputRef={this.onInputMount}
                onClick={this.onClick}
                onChange={this.onChange}
                isDisabled={this.props.isDisabled}
                className={this.props.className}
            />
        );
    }
    /** Input element mount handler */
    @autobind
    protected onInputMount(inputElement: HTMLInputElement): void {
        this.inputElement = inputElement;
    }
    /** Click event handler */
    @autobind
    protected onClick(): void {
        if (!isNil(this.inputElement) && !this.props.isDisabled) {
            this.inputElement.click();
        }
    }
    /** Change event handler */
    @autobind
    protected onChange(): void {
        if (!isNil(this.inputElement) && !this.props.isDisabled) {
            const fileList: FileList = this.inputElement.files;
            if (!isNil(this.props.onFileAdd) && !isNil(fileList)) {
                this.props.onFileAdd(fileList);
            }
            this.inputElement.value = null;
        }
    }
}
