import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import * as moment from 'moment';

import type { AutopilotDOOHRegionality } from 'sber-marketing-types/backend';
import { DictionaryStatus, DictionaryType } from '@mrm/dictionary';
import type { SelectItem } from 'sber-marketing-ui';
import type { BriefStageForm, GroupedDictionaries } from '@store/autopilotDOOH/types';

import { BriefStageWidgetTemplate } from './BriefStageWidgetTemplate';
import type { StoreState } from '@store';
import { setBriefFormValues } from '@store/autopilotDOOH/actions';
import { getBriefStageForm, getDictionaries, getRegionalities } from '@store/autopilotDOOH/selectors';
import { Utils } from '@common/Utils';

interface Props extends Partial<MapProps>, Partial<DispatchProps> {
    readOnly?: boolean;
}

interface State {
    isBudgetFocus: boolean;
}

interface MapProps {
    briefStageForm: BriefStageForm;
    dictionaries: GroupedDictionaries;
    regionalities: AutopilotDOOHRegionality[];
}

interface DispatchProps {
    setBriefFormValues: (values: Partial<BriefStageForm>) => void;
}

const MIN_BUDGET = 300;

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BriefStageWidgetBehaviour extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isBudgetFocus: false,
        };
    }

    public async componentDidMount() {
        const { readOnly, briefStageForm } = this.props;

        if (!readOnly && !briefStageForm.blockId) {
            const blockItems = this.makeBlockItems();

            if (blockItems.length === 1) {
                const newBlockId = lodash.first(blockItems).value as string;

                this.onBlockChange(newBlockId);
            }
        }
    }

    public render(): JSX.Element {
        const { readOnly, briefStageForm, dictionaries } = this.props;

        return React.createElement(BriefStageWidgetTemplate, {
            readOnly: readOnly || false,
            loading: !briefStageForm || lodash.isEmpty(dictionaries),
            isBudgetFocus: this.state.isBudgetFocus,
            briefStageForm,
            datesRange: this.getDatesRange(),
            blockItems: this.makeBlockItems(),
            productItems: this.makeProductItems(),
            regionalityItems: this.makeRegionalityItems(),
            onBlockChange: this.onBlockChange,
            onProductChange: this.onProductChange,
            onBudgetChange: this.onBudgetChange,
            onBudgetFocus: this.onBudgetFocus,
            onBudgetBlur: this.onBudgetBlur,
            onStartDateChange: this.onStartDateChange,
            onEndDateChange: this.onEndDateChange,
            onRegionalityChange: this.onRegionalityChange,
        });
    }

    @autobind
    protected onBlockChange(newBlockId: string): void {
        const { productId } = this.props.briefStageForm;

        const updateParams: Partial<BriefStageForm> = {
            blockId: newBlockId,
        };

        const blockChildProductIds = this.getBlockChildProductIds(newBlockId);

        const currentProductIsValid = this.blockIncludesChildProduct(newBlockId, productId);

        if (blockChildProductIds.length === 1) {
            updateParams.productId = lodash.first(blockChildProductIds);
        } else if (!currentProductIsValid) {
            updateParams.productId = null;
        }

        this.props.setBriefFormValues(updateParams);
    }

    @autobind
    protected onProductChange(value: string): void {
        this.props.setBriefFormValues({ productId: value });
    }

    @autobind
    protected onBudgetChange(budget: string): void {
        this.props.setBriefFormValues({
            budget,
            budgetError: this.getBudgetError(budget),
        });
    }

    @autobind
    protected onBudgetFocus(): void {
        this.setState({ isBudgetFocus: true });
    }

    @autobind
    protected onBudgetBlur(): void {
        this.setState({ isBudgetFocus: false });
    }

    @autobind
    protected onStartDateChange(value: moment.Moment): void {
        this.props.setBriefFormValues({ dateStart: value.format('YYYY-MM-DD') });
    }

    @autobind
    protected onEndDateChange(value: moment.Moment): void {
        this.props.setBriefFormValues({ dateEnd: value.format('YYYY-MM-DD') });
    }

    @autobind
    protected onRegionalityChange(value: string): void {
        this.props.setBriefFormValues({ regionalityId: value });
    }

    private getDatesRange(): string {
        const { dateStart, dateEnd } = this.props.briefStageForm;

        const start = dateStart || null;
        const end = dateEnd || null;

        const daysCount = this.getDaysCountBetweenDates(start, end);

        return daysCount !== null
            ? `${daysCount} ${Utils.getDeclensionByNumber(daysCount, ['день', 'дня', 'дней'])}`
            : null;
    }

    private makeBlockItems(): SelectItem[] {
        const { briefStageForm, dictionaries } = this.props;
        const { blockId } = briefStageForm;

        const blocks = dictionaries[DictionaryType.Block] || [];

        const filteredBlocks = blocks.filter((item) => item.status === DictionaryStatus.ACTIVE || item.id === blockId);

        return filteredBlocks.map((item) => ({
            label: item.status === DictionaryStatus.ACTIVE ? item.value : `${item.value} (Удалён)`,
            value: item.id,
        }));
    }

    private makeProductItems(): SelectItem[] {
        const { briefStageForm, dictionaries } = this.props;

        const { blockId, productId } = briefStageForm;

        const blocks = dictionaries[DictionaryType.Block] || [];
        const products = dictionaries[DictionaryType.Product] || [];
        const filteredProducts = products.filter(
            (item) => item.status === DictionaryStatus.ACTIVE || item.id === productId,
        );

        const selectedBlock = blocks.find((item) => item.id == blockId);

        if (!selectedBlock) {
            return [];
        }

        const blockChildDictionaryIds = selectedBlock.childrenRefs.map((item) => item.id);

        const blockProducts = filteredProducts.filter((item) => lodash.includes(blockChildDictionaryIds, item.id));

        return blockProducts.map((item) => ({
            label: item.status === DictionaryStatus.ACTIVE ? item.value : `${item.value} (Удалён)`,
            value: item.id,
        }));
    }

    private makeRegionalityItems(): SelectItem[] {
        const { regionalities } = this.props;

        return regionalities.map((item) => ({
            label: item.name,
            value: item.id,
        }));
    }

    private getDaysCountBetweenDates(date1: string, date2: string): number {
        return date1 && date2 ? Math.abs(moment(date1).diff(moment(date2), 'days')) : null;
    }

    private getBlockChildProductIds(blockId: string): string[] {
        const { dictionaries } = this.props;

        if (!blockId) {
            return [];
        }

        const blocks = dictionaries[DictionaryType.Block] || [];
        const products = dictionaries[DictionaryType.Product] || [];

        const productIds = products.map((item) => item.id);

        const block = blocks.find((item) => item.id == blockId);

        const blockChildDictionaryIds = block.childrenRefs.map((item) => item.id);

        return lodash.intersection(blockChildDictionaryIds, productIds);
    }

    private blockIncludesChildProduct(blockId: string, productId: string): boolean {
        if (!blockId || !productId) {
            return false;
        }

        const blockChildProductIds = this.getBlockChildProductIds(blockId);

        return lodash.includes(blockChildProductIds, productId);
    }

    private getBudgetError(budget: string): string {
        const convertedBudget = Number(budget.replace(/ /g, ''));
        return convertedBudget < MIN_BUDGET ? `Минимальный бюджет ${MIN_BUDGET}` : null;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        briefStageForm: getBriefStageForm(state),
        dictionaries: getDictionaries(state),
        regionalities: getRegionalities(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setBriefFormValues,
        },
        dispatch,
    );
}
