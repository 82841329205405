import { createSelector } from 'reselect';
import { includes } from 'lodash';
import { StoreState } from '@store';

import { PageState, PageData, FilterPeriod, StatisticsItem, Filters, StatisticsType } from './types';

export const getDashboardPageState = (state: StoreState): PageState => state.leaderDashboardTasksPage;

export const getPageData = createSelector(getDashboardPageState, (state: PageState): PageData => state.data);

export const getLoading = createSelector(getDashboardPageState, (state: PageState): boolean => state.loading);

export const getStatisticsItems = createSelector(
    getDashboardPageState,
    (state: PageState): StatisticsItem[] => state.statisticsItems,
);

export const getFilters = createSelector(getDashboardPageState, (state): Filters => state.filters);

export const getSelectedStatisticsItem = createSelector(
    getDashboardPageState,
    getStatisticsItems,
    ({ selectedStatisticsItemId }: PageState, statisticsItems): StatisticsItem => {
        return statisticsItems.find((statisticsItem) => statisticsItem.id === selectedStatisticsItemId);
    },
);

export const getStatisticsTypeFilters = createSelector(
    getDashboardPageState,
    ({ statisticsTypeFilters }: PageState): Record<StatisticsType, boolean> => {
        return statisticsTypeFilters;
    },
);

export const getChildrenOfSelectedStatisticsItem = createSelector(
    getStatisticsItems,
    getSelectedStatisticsItem,
    (statisticsItems, selectedStatisticsItem): StatisticsItem[] => {
        return statisticsItems.filter(({ id }) => includes(selectedStatisticsItem.meta.childrenIds, id));
    },
);

export const getFilteredChildrenOfSelectedStatisticsItem = createSelector(
    getFilters,
    getChildrenOfSelectedStatisticsItem,
    (filters, statisticsItems): StatisticsItem[] => {
        return statisticsItems.filter(({ id }) => filters[id]);
    },
);

export const getFilterPeriod = createSelector(
    getDashboardPageState,
    (state: PageState): FilterPeriod => state.selectedPeriod,
);

export const getFilterDate = createSelector(getDashboardPageState, (state: PageState): string => state.selectedDate);

export const getFilterBy = createSelector(getDashboardPageState, (state: PageState): string => state.selectedBy);
