import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { difference } from 'lodash';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { createTag, updateExistingTag, storeToClientEmoji } from '@store/tags';

import { getInstance } from './selectors';
import { makeTag, getActivity, getTask, getBudgetItem } from './misc';
import {
    TagsEditorInstanceState as InstanceState,
    TagsEditorInstanceDescriptor as InstanceDescriptor,
    DataUpdateStrategy,
    InstanceActionPayload,
    DataUpdateActionPayload,
    UpdateTagColorPayload,
    UpdateTagEmojiPayload,
} from './types';

import * as asyncActions from './actions/async';
import * as actions from './actions/sync';

async function withInstanceCheck(
    state: StoreState,
    id: string,
    callback: (state: InstanceState, makeActionParams: <P>(payload: P) => InstanceActionPayload<P>) => Promise<void>,
): Promise<void> {
    const instance = getInstance(state, id);

    function makeActionParams<P>(payload: P): InstanceActionPayload<P> {
        return { id, payload };
    }

    if (!instance) {
        console.warn(`TagsEditorInstance by id ${id} was requested, but wasn't found`);
    } else {
        await callback(instance, makeActionParams);
    }
}

async function performUpdateIfPossible(
    instance: InstanceState,
    dataUpdateStrategy: DataUpdateStrategy,
    callback: () => Promise<void>,
): Promise<void> {
    if (instance.canEdit && dataUpdateStrategy === DataUpdateStrategy.Immediate) {
        await callback();
    }
}

const addTagAction = 'addTag';
type GetDataRes = [string[], boolean];
async function getSelectedTagsAndEditRights(descriptor: InstanceDescriptor): Promise<GetDataRes> {
    async function withKeyCheck(
        keys: (keyof InstanceDescriptor)[],
        callback: (id: string | number) => Promise<GetDataRes>,
    ): Promise<GetDataRes> {
        const key = keys.find((key) => descriptor[key]);

        let result: GetDataRes = [[], true];
        if (key) {
            result = (await callback(descriptor[key])) || result;
        } else {
            result = [[], true];
        }

        return result;
    }

    let result: GetDataRes;
    if (descriptor.activityId !== undefined) {
        result = await withKeyCheck(['activityId'], async (id: number) => {
            const activity = await getActivity(id);

            return [activity?.model?.tags || [], !!activity?.contract?.actions?.includes(addTagAction)];
        });
    } else if (descriptor.taskId !== undefined) {
        result = await withKeyCheck(['taskId'], async (id: string) => {
            const task = await getTask(id);

            return [task?.model?.tags || [], !!task?.contract?.actions?.includes(addTagAction)];
        });
    } else if (descriptor.executionBudgetItemId !== undefined || descriptor.planBudgetItemId !== undefined) {
        result = await withKeyCheck(['executionBudgetItemId', 'planBudgetItemId'], async (id: string) => {
            const budgetItem = await getBudgetItem(id);

            return [
                budgetItem?.model?.tags || [],
                budgetItem?.contract?.actions ? !!budgetItem?.contract?.actions?.includes(addTagAction) : true,
            ];
        });
    } else {
        console.warn(`Missing if-caluse for descriptor: ${JSON.stringify(descriptor)}`);
    }

    return result;
}

export const loadData = bindThunkAction<StoreState, string, void, Error>(
    asyncActions.loadData,
    async (id, dispatch, getState) => {
        await withInstanceCheck(getState(), id, async (instance, makeActionParams) => {
            const { loadingStatus, descriptor } = instance;

            if (loadingStatus === LoadingStatus.NOT_LOADED) {
                try {
                    dispatch(actions.setLoadingStatus(makeActionParams(LoadingStatus.LOADING)));

                    const [selectedTags, canEdit] = await getSelectedTagsAndEditRights(descriptor);

                    dispatch(actions.setSelectedTags(makeActionParams(selectedTags)));
                    dispatch(actions.setCanEdit(makeActionParams(!!canEdit)));
                    dispatch(actions.setLoadingStatus(makeActionParams(LoadingStatus.LOADED)));
                } catch (e) {
                    dispatch(actions.setLoadingStatus(makeActionParams(LoadingStatus.ERROR)));
                }
            }
        });
    },
);

export const createNewTag = bindThunkAction<StoreState, DataUpdateActionPayload<null>, void, Error>(
    asyncActions.createNewTag,
    async (payload, dispatch, getState) => {
        const { id, dataUpdateStrategy } = payload;

        await withInstanceCheck(getState(), id, async (instance, makeActionParams) => {
            const { pending } = instance.tags;

            dispatch(createTag(pending));
            dispatch(actions.setPendingTag(makeActionParams(makeTag())));
            dispatch(actions.setNewTagInputValue(makeActionParams('')));

            dispatch(
                addTagToSelected({
                    ...makeActionParams(pending.id),
                    dataUpdateStrategy,
                }),
            );
        });
    },
);

export const addTagToSelected = bindThunkAction<StoreState, DataUpdateActionPayload<string>, void, Error>(
    asyncActions.addTagToSelected,
    async (payload, dispatch, getState) => {
        const { id, dataUpdateStrategy, payload: tagId } = payload;

        await withInstanceCheck(getState(), id, async (instance, makeActionParams) => {
            const { descriptor } = instance;

            await performUpdateIfPossible(instance, dataUpdateStrategy, async () => {
                if (descriptor.activityId) {
                    (await getActivity(descriptor.activityId))?.model?.addTag({ tagId });
                } else if (descriptor.taskId) {
                    (await getTask(descriptor.taskId))?.model?.addTag({ tagId });
                } else if (descriptor.executionBudgetItemId || descriptor.planBudgetItemId) {
                    const id = descriptor.executionBudgetItemId || descriptor.planBudgetItemId;

                    (await getBudgetItem(id))?.model.addTag({ tagId });
                } else {
                    console.warn(`Missing if-clause for descriptor: ${JSON.stringify(descriptor)}`);
                }
            });

            const selectedTags = [...instance.tags.selected, tagId];
            dispatch(actions.setSelectedTags(makeActionParams(selectedTags)));
        });
    },
);

export const removeTagFromSelected = bindThunkAction<StoreState, DataUpdateActionPayload<string>, void, Error>(
    asyncActions.removeTagFromSelected,
    async (payload, dispatch, getState) => {
        const { id, dataUpdateStrategy, payload: tagId } = payload;

        await withInstanceCheck(getState(), id, async (instance, makeActionParams) => {
            const { descriptor } = instance;

            await performUpdateIfPossible(instance, dataUpdateStrategy, async () => {
                if (descriptor.activityId) {
                    (await getActivity(descriptor.activityId)).model.removeTag({ tagId });
                } else if (descriptor.taskId) {
                    (await getTask(descriptor.taskId)).model.removeTag({ tagId });
                } else if (descriptor.executionBudgetItemId || descriptor.planBudgetItemId) {
                    const id = descriptor.executionBudgetItemId || descriptor.planBudgetItemId;

                    (await getBudgetItem(id))?.model.removeTag({ tagId });
                } else {
                    console.warn(`Missing if-clause for descriptor: ${JSON.stringify(descriptor)}`);
                }
            });

            const selectedTags = instance.tags.selected.filter((selectedTagId) => selectedTagId !== tagId);
            dispatch(actions.setSelectedTags(makeActionParams(selectedTags)));
        });
    },
);

function splitTags(selected: string[], existing: string[]): [string[], string[]] {
    const toAdd = difference(selected, existing);
    const toRemove = difference(existing, selected);

    return [toAdd, toRemove];
}

export const flushOnDemandUpdates = bindThunkAction<StoreState, string, void, Error>(
    asyncActions.flushOnDemandUpdates,
    async (id, dispatch, getState) => {
        await withInstanceCheck(getState(), id, async (instance, makeActionParams) => {
            const {
                canEdit,
                descriptor,
                tags: { selected },
            } = instance;

            if (canEdit) {
                if (descriptor.activityId) {
                    const activity = await getActivity(descriptor.activityId);
                    const [toAdd, toRemove] = splitTags(selected, activity?.model?.tags);

                    await Promise.all(toAdd.map((tagId) => activity.model.addTag({ tagId })));
                    await Promise.all(toRemove.map((tagId) => activity.model.removeTag({ tagId })));
                } else if (descriptor.taskId) {
                    const task = await getTask(descriptor.taskId);
                    const [toAdd, toRemove] = splitTags(selected, task?.model?.tags);

                    await Promise.all(toAdd.map((tagId) => task.model.addTag({ tagId })));
                    await Promise.all(toRemove.map((tagId) => task.model.removeTag({ tagId })));
                } else if (descriptor.executionBudgetItemId || descriptor.planBudgetItemId) {
                    const id = descriptor.executionBudgetItemId || descriptor.planBudgetItemId;
                    const budgetItem = await getBudgetItem(id);
                    const [toAdd, toRemove] = splitTags(selected, budgetItem?.model?.tags);

                    await Promise.all(toAdd.map((tagId) => budgetItem.model.addTag({ tagId })));
                    await Promise.all(toRemove.map((tagId) => budgetItem.model.removeTag({ tagId })));
                } else {
                    console.warn(`Missing if-clause for descriptor: ${JSON.stringify(descriptor)}`);
                }
            }
        });
    },
);

export const updateTagColor = bindThunkAction<StoreState, UpdateTagColorPayload, void, Error>(
    asyncActions.updateTagColor,
    async (payload, dispatch, getState) => {
        const {
            id,
            payload: { id: tagId, color },
        } = payload;

        await withInstanceCheck(getState(), id, async (instance, makeActionParams) => {
            const { descriptor } = instance;

            if (descriptor.activityId) {
                (await getActivity(descriptor.activityId))?.model.setTagColor({ tagId, color });
            } else if (descriptor.taskId) {
                (await getTask(descriptor.taskId))?.model.setTagColor({ tagId, color });
            } else if (descriptor.executionBudgetItemId || descriptor.planBudgetItemId) {
                const id = descriptor.executionBudgetItemId || descriptor.planBudgetItemId;
                (await getBudgetItem(id))?.model.setTagColor({ tagId, color });
            } else {
                console.warn(`Missing if-clause for descriptor: ${JSON.stringify(descriptor)}`);
            }

            dispatch(
                updateExistingTag({
                    id: tagId,
                    color,
                }),
            );
        });
    },
);

export const updateTagEmoji = bindThunkAction<StoreState, UpdateTagEmojiPayload, void, Error>(
    asyncActions.updateTagColor,
    async (payload, dispatch, getState) => {
        const {
            id,
            payload: { id: tagId, emoji },
        } = payload;
        const picture = storeToClientEmoji(emoji);

        await withInstanceCheck(getState(), id, async (instance, makeActionParams) => {
            const { descriptor } = instance;

            if (descriptor.activityId) {
                (await getActivity(descriptor.activityId))?.model.setTagPicture({ tagId, picture });
            } else if (descriptor.taskId) {
                (await getTask(descriptor.taskId))?.model.setTagPicture({ tagId, picture });
            } else if (descriptor.executionBudgetItemId || descriptor.planBudgetItemId) {
                const id = descriptor.executionBudgetItemId || descriptor.planBudgetItemId;
                (await getBudgetItem(id))?.model.setTagPicture({ tagId, picture });
            } else {
                console.warn(`Missing if-clause for descriptor: ${JSON.stringify(descriptor)}`);
            }

            dispatch(
                updateExistingTag({
                    id: tagId,
                    emoji,
                }),
            );
        });
    },
);
