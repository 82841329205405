import * as React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { StoreState } from '@store';
import { isLoggedUserBudgetExpert } from '@store/user';

import { useStyleContext } from '../BudgetTransferMenu';
import { ParticipatorCommentInput } from '../ParticipatorCommentInput';
import { WithExpertSelect } from '../WithExpertSelect';

import { SourceTitle, DestTitle } from './Titles';
import { SourceRowSelector, DestRowSelector } from './RowSelectors';
import { SourceCellDescription, DestCellDescription } from './CellDescriptions';
import { SourceBalance, DestBalance } from './Balance';
import { CurrentRowSumInputs } from './CurrentRowSumInputs';
import { ControlButtons } from './ControlButtons';

import * as styles from './InternalTransitionSumEntering.scss';

export function InternalTransitionSumEntering(): JSX.Element {
    const paddingClass = useStyleContext();
    const userIsNotBudgetExpert = useSelector((state: StoreState) => !isLoggedUserBudgetExpert(state));

    return (
        <div>
            <div className={classnames(paddingClass, styles.withMaskContent)}>
                <div className={styles.columnsWrapper}>
                    <div className={styles.column}>
                        <SourceTitle />

                        <SourceRowSelector />
                    </div>

                    <div className={styles.column}>
                        <DestTitle />

                        <DestRowSelector />
                    </div>
                </div>

                <div className={styles.columnsWrapper}>
                    <div className={styles.column}>
                        <SourceCellDescription />
                    </div>

                    <div className={styles.column}>
                        <DestCellDescription />
                    </div>
                </div>

                <div className={classnames(styles.balanceWrapper, styles.columnsWrapper)}>
                    <div className={classnames(styles.column, styles.columnWithDivider)}>
                        <SourceBalance />
                    </div>

                    <svg
                        className={styles.arrow}
                        width="27"
                        height="8"
                        viewBox="0 0 27 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M4.77899e-08 3.99242C5.20161e-08 3.63802 0.262808 3.34513 0.603784 3.29878L0.698577 3.29239L24.3055 3.2874L22.219 1.19503C21.9689 0.944428 21.9481 0.551105 22.1565 0.276721L22.219 0.205034C22.4691 -0.0455625 22.8616 -0.066446 23.1354 0.142385L23.2069 0.205034L26.5 3.505C26.7501 3.7556 26.7709 4.14892 26.5625 4.42331L26.5 4.495L23.2069 7.79497C22.9341 8.06834 22.4918 8.06834 22.219 7.79497C21.9689 7.54437 21.9481 7.15105 22.1565 6.87666L22.219 6.80498L24.3334 4.68746L0.698577 4.69245C0.312764 4.69245 4.31796e-08 4.37903 4.77899e-08 3.99242Z"
                            fill="#7E8681"
                        />
                    </svg>

                    <div className={classnames(styles.column, styles.columnWithDivider)}>
                        <DestBalance />
                    </div>
                </div>
            </div>

            <CurrentRowSumInputs />

            {userIsNotBudgetExpert && (
                <div className={classnames(styles.participatorCommentInput, paddingClass)}>
                    <ParticipatorCommentInput />
                </div>
            )}

            <div className={paddingClass}>
                <WithExpertSelect>
                    <ControlButtons />
                </WithExpertSelect>
            </div>
        </div>
    );
}
