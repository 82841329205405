import * as React from 'react';

import { OptionMultiple, OptionProps, OptionValues, Selector, SelectorProps } from '../../main';
import { FormField, useFormField } from '../Form';

export interface FormSelectorProps<
    M extends OptionMultiple,
    OP extends OptionProps<any>,
    O extends OP extends OptionProps<infer O1> ? O1 : never,
    ID extends O extends OptionValues ? never : keyof O,
    V extends O extends OptionValues ? O : ID extends keyof O ? O[ID] : never,
    SV extends M extends true ? V[] : V,
> extends Omit<SelectorProps<M, OP, O, ID, V, SV>, keyof FormField>,
        FormField {}

export function FormSelector<
    M extends OptionMultiple,
    OP extends OptionProps<any>,
    O extends OP extends OptionProps<infer O1> ? O1 : never,
    ID extends O extends OptionValues ? never : keyof O,
    V extends O extends OptionValues ? O : ID extends keyof O ? O[ID] : never,
    SV extends M extends true ? V[] : V,
>(props: FormSelectorProps<M, OP, O, ID, V, SV>) {
    const { required, value, onChange, inputRef, name, defaultValue, errorMessage, rest } = useFormField({
        ...props,
        value: props.selected,
        onChange: (value, options) => {
            if (options) {
                props.onSelect?.(value, options);
            }
        },
    });

    return (
        <Selector
            {...rest}
            required={required}
            inputRequired={false}
            selected={value}
            value={props.value}
            onSelect={onChange}
            onChange={props.onChange}
            inputRef={inputRef}
            name={name}
            error={errorMessage}
            defaultValue={defaultValue}
        />
    );
}
