import * as React from 'react';

import { ColumnName } from '@store/budgetPlanning';
import { Item } from './Item';
import * as styles from './styles.scss';

interface Props {
    columnNames: ColumnName[];
}

export const List: React.FC<Props> = ({ columnNames }) => {
    const [openItemIndex, setOpenItemIndex] = React.useState(0);

    const buildHandlerOnExpandedChange = React.useCallback(
        (index: number) => (isExpanded: boolean) => {
            if (isExpanded) {
                setOpenItemIndex(index);
            }
        },
        [],
    );

    return (
        <div className={styles.root}>
            {columnNames.map((columnName, index) => (
                <Item
                    key={columnName}
                    isExpanded={index == openItemIndex}
                    columnName={columnName}
                    onExpandChange={buildHandlerOnExpandedChange(index)}
                />
            ))}
        </div>
    );
};
