import * as React from 'react';
import { MutateProps, graphql } from 'react-apollo';
import autobind from 'autobind-decorator';

import { ActivityFileGroup } from 'sber-marketing-types/frontend';

import { ATTACH_FILE_MUTATION } from './query';

interface Props extends ExternalProps, QueryProps {}

interface ExternalProps {
    activityId: number;
    children: (props: ChildrenProps) => JSX.Element;
}

interface ChildrenProps {
    attachFile: (fileId: string, category: ActivityFileGroup) => Promise<void>;
    deleteFile: (fileId: string) => Promise<void>;
}

type QueryProps = MutateProps<
    {},
    {
        projectId: number;
        fileId: string;
        category: string;
    }
>;

class FileMutations extends React.PureComponent<Props> {
    public render(): JSX.Element {
        return this.props.children({
            attachFile: this.attachFile,
            deleteFile: this.deleteFile,
        });
    }

    @autobind
    private async attachFile(fileId: string, category: ActivityFileGroup): Promise<void> {
        await this.props.mutate({
            variables: {
                projectId: this.props.activityId,
                fileId,
                category,
            },
        });
    }

    @autobind
    private async deleteFile(fileId: string): Promise<void> {}
}

export const WithFileMutations = graphql<ExternalProps>(ATTACH_FILE_MUTATION)(FileMutations);
