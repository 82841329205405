import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { ProductApi } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getLoginUser } from '@store/user';

import * as actions from './actions';
import { LoadProductsParams } from './types';
import { getLoadingStatus, getFetchersCount, getProductById } from './selectors';

export const loadProducts = bindThunkAction<StoreState, LoadProductsParams, void, Error>(
    actions.loadProductsAsync,
    async (params, dispatch, getState) => {
        const { store, ids } = params;

        const setStatus: (status: LoadingStatus) => void = (status) =>
            dispatch(
                actions.setLoadingStatus({
                    store,
                    status,
                }),
            );
        const state = getState();
        const loadingStatus = getLoadingStatus(state, store);
        const loginnedUser = getLoginUser(state);

        try {
            let products;

            if (ids) {
                const notLoadedProductIds = ids.filter((id) => !getProductById(state, id));
                if (notLoadedProductIds.length) {
                    dispatch(actions.incFetchersCount(store));
                    setStatus(LoadingStatus.LOADING);
                    products = await ProductApi.getProducts({
                        ids,
                    });
                }

                dispatch(
                    actions.setStoreIds({
                        store,
                        ids,
                    }),
                );
            } else if (loadingStatus === LoadingStatus.NOT_LOADED) {
                dispatch(actions.incFetchersCount(store));
                setStatus(LoadingStatus.LOADING);
                products = await ProductApi.getProducts({
                    organizationId: loginnedUser.attributes.organizationId,
                });

                dispatch(
                    actions.setStoreIds({
                        store,
                        ids: products.map((product) => product.id),
                    }),
                );
            }

            if (products) {
                dispatch(actions.decFetchersCount(store));
                dispatch(actions.loadEntities(products));
            }

            const fetchersCount = getFetchersCount(getState(), store);
            if (!fetchersCount) {
                setStatus(LoadingStatus.LOADED);
            }
        } catch (e) {
            setStatus(LoadingStatus.ERROR);
        }
    },
);
