import * as React from 'react';

import { CentralContent, MenuItem, ResponsiveLayout } from 'sber-marketing-ui';

import { PageMode } from '../ActivityPageTemplate';

import { MenuItemDropdown } from './MenuItemDropdown';
import { AutopilotLink } from './AutopilotLink';

import { ActivityTasksFilter } from '../pages/activityTasks/ActivityTasksFilter';

import * as style from './Header.scss';

interface Props {
    activityId: number;
    activityName: string;
    currentUrl: string;
    currentPage: PageMode;
    displayCreative: boolean;
    displayAutopilot: boolean;
    canCreateCreativeRequest: boolean;
    rightContent?: JSX.Element;
}

export function ActivityPageSubHeader({
    activityId,
    activityName,
    currentUrl,
    currentPage,
    displayCreative,
    displayAutopilot,
    canCreateCreativeRequest,
    rightContent,
}: Props): JSX.Element {
    return (
        <div className={style.root}>
            {currentPage === PageMode.Tasks && (
                <ActivityTasksFilter className={style.tasksFilter} activityId={activityId} />
            )}
            <ResponsiveLayout>
                <CentralContent>
                    <div className={style.menuList}>
                        <MenuItem
                            qaId="activityTasksButton"
                            link={`/activity/${activityId}/tasks`}
                            active={currentPage === PageMode.Tasks || currentPage === PageMode.Task}
                        >
                            <span className={style.menuItem}>Задачи</span>
                        </MenuItem>

                        <MenuItem
                            qaId="activityBriefButton"
                            link={`/activity/${activityId}/brief`}
                            active={currentPage === PageMode.Brief || currentPage === PageMode.TaskBrief}
                        >
                            <span className={style.menuItem}>Брифы</span>
                        </MenuItem>

                        <MenuItem
                            qaId="activityCalendarButton"
                            link={`/calendar/chart?activityId=${activityId}`}
                            openInNewTab
                        >
                            <span className={style.menuItem}>Календарь</span>
                        </MenuItem>

                        {displayCreative && (
                            <MenuItemDropdown
                                qaId="activityCreativeButton"
                                link={currentUrl}
                                active={currentPage === PageMode.Creative}
                                activityId={activityId}
                                activityName={activityName}
                                canCreateCreativeRequest={canCreateCreativeRequest}
                            />
                        )}

                        {displayAutopilot && <AutopilotLink activityId={activityId} />}
                    </div>
                </CentralContent>
            </ResponsiveLayout>

            {rightContent}
        </div>
    );
}
