import * as React from 'react';
import { WithTooltip, TooltipAnchor } from 'sber-marketing-ui';

import { DatesFormatter } from '@common/Utils';

import { TimeUnitGroup, DateMark } from '../types';
import { Scaler } from '../modules';

import * as style from './Timeline.scss';

interface Props {
    timeUnitGroups: TimeUnitGroup[];
    scaler: Scaler;
    canvasHeight: number;
    dateMarks: DateMark[];
    setDateMarkVisibility: (date: Date, isVisible: boolean) => void;
}

export function Timeline({
    timeUnitGroups,
    scaler,
    canvasHeight,
    dateMarks,
    setDateMarkVisibility,
}: Props): JSX.Element {
    const totalDaysCount = timeUnitGroups.reduce((acc, item) => acc + item.daysCount, 0);

    return (
        <div className={style.root}>
            <div className={style.line}>
                {timeUnitGroups.map((group, index) => (
                    <div
                        key={index}
                        className={style.group}
                        style={{ width: `${(group.daysCount / totalDaysCount) * 100}%` }}
                    >
                        <div className={style.groupTitle}>{group.title}</div>
                    </div>
                ))}
            </div>

            <div className={style.line}>
                {timeUnitGroups.map((group, index) => (
                    <div
                        key={index}
                        className={style.group}
                        style={{ width: `${(group.daysCount / totalDaysCount) * 100}%` }}
                    >
                        {group.items.map((item, index) => (
                            <div key={index} className={style.item} style={{ flexGrow: item.daysCount }}>
                                <div className={style.itemTitle}>{item.title}</div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            {scaler && (
                <React.Fragment>
                    {dateMarks.map((dateMark) =>
                        dateMark.isMarkerVisible ? (
                            <DateMarker
                                key={dateMark.date.toString()}
                                canvasHeight={canvasHeight}
                                scaler={scaler}
                                date={dateMark.date}
                                title={dateMark.label}
                                setDateMarkVisibility={setDateMarkVisibility}
                            />
                        ) : null,
                    )}
                </React.Fragment>
            )}
        </div>
    );
}

interface DateMarkerProps {
    scaler: Scaler;
    date: Date;
    canvasHeight: number;
    title: string;
    setDateMarkVisibility: (date: Date, isVisible: boolean) => void;
}

function DateMarker({ scaler, date, canvasHeight, title, setDateMarkVisibility }: DateMarkerProps): JSX.Element {
    const left = scaler.convertDateToPx(date) - 7;
    const tooltipTitle = `${title}: ${DatesFormatter.ddMonthyyyy(date)}`;

    return (
        <div
            className={style.dateMarker}
            style={{ left }}
            onMouseEnter={() => setDateMarkVisibility(date, true)}
            onMouseLeave={() => setDateMarkVisibility(date, false)}
        >
            <WithTooltip anchor={TooltipAnchor.TOP} content={tooltipTitle}>
                <div className={style.dateMarkerContent}>
                    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.4982 1.36401L6.67439 6.18786C6.43952 6.42273 6.05872 6.42273 5.82385 6.18786L1 1.36401"
                            stroke="#19BB4F"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    {/* <div className={style.dateMarkerIcon} /> */}

                    <div className={style.dateMarkerHorizontalLine} style={{ height: canvasHeight }} />
                </div>
            </WithTooltip>
        </div>
    );
}
