import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import type { User } from '@store/user/types';
import type { StoreState } from '@store';

import { loadAutopilotTv } from '@store/activityPage/thunks';
import { setPreloaderStatus } from '@store/autopilotDOOH/actions';
import { getAutopilot } from '@store/autopilotDOOH/selectors';
import { getLoginUser } from '@store/user/selector';
import { Loader, Saver } from '../../modules';

export const useHooks = () => {
    const dispatch = useDispatch();

    const saver = Saver.getInstance();
    const loader = Loader.getInstance();

    const [displayAutopilotConfirmModal, setDisplayAutopilotConfirmModal] = React.useState(false);

    const autopilot = useSelector((state: StoreState) => getAutopilot(state));
    const user = useSelector((state: StoreState) => getLoginUser(state));

    const autopilotIsEnabled = autopilot?.status == 'on';

    const userHasAutopilotClientAccess = checkUserAutopilotClientAccess(user);

    const displayAutopilotEnableButton = userHasAutopilotClientAccess && !autopilotIsEnabled;
    const displayAutopilotDisableButton = userHasAutopilotClientAccess && autopilotIsEnabled;

    const onAutopilotButtonClick = React.useCallback(() => {
        setDisplayAutopilotConfirmModal(true);
    }, []);

    const onAutopilotEnableConfirm = React.useCallback(async () => {
        setDisplayAutopilotConfirmModal(false);
        dispatch(setPreloaderStatus(true));
        await saver.setAutopilotStatus(true);
        await Promise.all([dispatch(loadAutopilotTv(autopilot.activityId)), loader.loadAutopilot()]);
        dispatch(setPreloaderStatus(false));
    }, [dispatch]);

    const onAutopilotDisableConfirm = React.useCallback(async () => {
        setDisplayAutopilotConfirmModal(false);
        dispatch(setPreloaderStatus(true));
        await saver.setAutopilotStatus(false);
        await Promise.all([dispatch(loadAutopilotTv(autopilot.activityId)), loader.loadAutopilot()]);
        dispatch(setPreloaderStatus(false));
    }, [dispatch]);

    const onModalCancel = React.useCallback(() => {
        setDisplayAutopilotConfirmModal(false);
    }, []);

    return {
        displayAutopilotEnableButton,
        displayAutopilotDisableButton,
        displayAutopilotEnableConfirmModal: displayAutopilotConfirmModal && !autopilotIsEnabled,
        displayAutopilotDisableConfirmModal: displayAutopilotConfirmModal && autopilotIsEnabled,
        onAutopilotButtonClick,
        onAutopilotEnableConfirm,
        onAutopilotDisableConfirm,
        onModalCancel,
    };
};

function checkUserAutopilotClientAccess(user: User) {
    // check if user has role === Autopilot Client
    return user.attributes.roles.map(({ id }) => id).includes(22);
}
