import { actionCreatorFactory } from 'typescript-fsa';

import { SearchQuery } from '../types';

const actionCreator = actionCreatorFactory('SEARCH_ASYNC').async;

const setVisibility = actionCreator<boolean, void, Error>('SET_VISIBILITY');
const searchAll = actionCreator<string, void, Error>('SEARCH_ALL');
const performUpdatesAfterSearch = actionCreator<string, void, Error>('PERFORM_UPDATES_AFTER_SEARCH');
const performSingleLoadedEntityCheck = actionCreator<void, void, Error>('PERFORM_SINGLE_LOADED_ENTITY_CHECK');
const performShouldAnimateLogic = actionCreator<void, void, Error>('PERFORM_SHOULD_ANIMATE_LOGIC');
const triggerFetch = actionCreator<void, void, Error>('TRIGGER_FETCH');

const searchActivities = actionCreator<SearchQuery, void, Error>('SEARCH_ACTIVITIES');
const searchTasks = actionCreator<SearchQuery, void, Error>('SEARCH_TASKS');
const searchFiles = actionCreator<SearchQuery, void, Error>('SEARCH_FILES');
const searchComments = actionCreator<SearchQuery, void, Error>('SEARCH_COMMENTS');

export {
    setVisibility,
    searchAll,
    performUpdatesAfterSearch,
    performSingleLoadedEntityCheck,
    performShouldAnimateLogic,
    triggerFetch,
    searchActivities,
    searchTasks,
    searchFiles,
    searchComments,
};
