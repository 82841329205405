import { OrganizationType } from 'sber-marketing-types/frontend';

import { OrganizationTypeState } from '../types';

export class OrganizationTypeReducer {
    public static load(state: OrganizationTypeState, organizationType: OrganizationType.OrganizationTypeParams[]) {
        return {
            ...state,
            organizationType,
        };
    }
}
