// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-tags-TagsPage-RightSidebar-Content-Item-_Item__base,.src-client-modules-tags-TagsPage-RightSidebar-Content-Item-_Item__title,.src-client-modules-tags-TagsPage-RightSidebar-Content-Item-_Item__content{font-family:'Open Sans';font-weight:400;font-size:12px;line-height:16px;letter-spacing:0.4px}.src-client-modules-tags-TagsPage-RightSidebar-Content-Item-_Item__title{margin-bottom:3px;display:flex;align-items:center;text-transform:uppercase;color:#7E8681}.src-client-modules-tags-TagsPage-RightSidebar-Content-Item-_Item__titleIcon{margin-right:3px}.src-client-modules-tags-TagsPage-RightSidebar-Content-Item-_Item__content{color:#000000}\n", "",{"version":3,"sources":["webpack://./src/client/modules/tags/TagsPage/RightSidebar/Content/Item/Item.scss"],"names":[],"mappings":"AAAA,4NACI,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,oBAAqB,CACxB,yEAKG,iBAAkB,CAElB,YAAa,CACb,kBAAmB,CAEnB,wBAAyB,CACzB,aAAc,CACjB,6EAGG,gBAAiB,CACpB,2EAKG,aAAc","sourcesContent":[".base {\n    font-family: 'Open Sans';\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 16px;\n    letter-spacing: 0.4px;\n}\n\n.title {\n    @extend .base;\n\n    margin-bottom: 3px;\n\n    display: flex;\n    align-items: center;\n\n    text-transform: uppercase;\n    color: #7E8681;\n}\n\n.titleIcon {\n    margin-right: 3px;\n}\n\n.content {\n    @extend .base;\n    \n    color: #000000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "src-client-modules-tags-TagsPage-RightSidebar-Content-Item-_Item__base",
	"title": "src-client-modules-tags-TagsPage-RightSidebar-Content-Item-_Item__title",
	"content": "src-client-modules-tags-TagsPage-RightSidebar-Content-Item-_Item__content",
	"titleIcon": "src-client-modules-tags-TagsPage-RightSidebar-Content-Item-_Item__titleIcon"
};
export default ___CSS_LOADER_EXPORT___;
