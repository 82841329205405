import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Dictionary } from 'lodash';
import { Tag } from '@mrm/tags';

import { LoadingStatus } from '@store/commonTypes';

import { TagsState as State, EntitiesStore } from './types';

import * as actions from './actions/sync';

class Reducer {
    public static makeInitialState(): State {
        return {
            loadingStatus: LoadingStatus.NOT_LOADED,
            entities: [],
            byId: {
                keys: [],
                dictionary: {},
            },
            byTitle: {
                keys: [],
                dictionary: {},
            },
        };
    }

    public static setLoadingStatus(state: State, loadingStatus: LoadingStatus): State {
        return { ...state, loadingStatus };
    }

    public static setTags(state: State, entities: Tag[]): State {
        return {
            ...state,
            entities,
            byId: Reducer.makeStore(entities, 'id'),
            byTitle: Reducer.makeStore(entities, 'title'),
        };
    }

    public static addTag(state: State, tag: Tag): State {
        const entities = [...state.entities, tag];

        return {
            ...state,
            entities,
            byId: Reducer.injectIntoStore(state.byId, 'id', tag),
            byTitle: Reducer.injectIntoStore(state.byTitle, 'title', tag),
        };
    }

    public static updateExistingTag(state: State, tag: Tag): State {
        const entities = state.entities.map((existingTag) => (existingTag.id === tag.id ? tag : existingTag));

        return {
            ...state,
            entities,
            byId: Reducer.replaceInStore(state.byId, 'id', tag),
            byTitle: Reducer.replaceInStore(state.byTitle, 'title', tag),
        };
    }

    private static makeStore(entities: Tag[], key: keyof Tag): EntitiesStore {
        const keys: string[] = [];
        const dictionary: Dictionary<Tag> = {};

        entities.forEach((tag) => {
            const entityKey = tag[key];

            keys.push(entityKey);
            dictionary[entityKey] = tag;
        });

        return { keys, dictionary };
    }

    private static injectIntoStore(store: EntitiesStore, key: keyof Tag, tag: Tag): EntitiesStore {
        const entityKey = tag[key];

        const keys = [...store.keys, entityKey];
        const dictionary = { ...store.dictionary };

        if (dictionary[entityKey]) {
            console.warn(`Tag with ${key} ${entityKey} already exists, replacing it in store`);
        }

        dictionary[entityKey] = tag;

        return { keys, dictionary };
    }

    private static replaceInStore(store: EntitiesStore, key: keyof Tag, tag: Tag): EntitiesStore {
        const entityKey = tag[key];

        const dictionary = { ...store.dictionary };
        if (!dictionary[entityKey]) {
            console.warn(
                `Tag with ${key} $${entityKey} was requested for replace, but was't found, injecting it instead`,
            );
        }
        dictionary[entityKey] = tag;

        return { keys: store.keys, dictionary };
    }
}

export const tagsReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(actions.setLoadingStatus, Reducer.setLoadingStatus)
    .case(actions.setTags, Reducer.setTags)
    .case(actions.addTag, Reducer.addTag)
    .case(actions.updateExistingTag, Reducer.updateExistingTag);
