import * as React from 'react';
import { useSelector } from 'react-redux';
import { Icon, IconType, WithTooltip, TooltipAnchor } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getBudgetExecutionPageState } from '@store/budgetExecution';

import * as styles from './RowIsNewIndicator.scss';

interface Props {
    lineId: string;
    normalPaddingClass: string;
    extendedPaddingClass: string;
}

export function RowIsNewIndicator({ lineId, normalPaddingClass, extendedPaddingClass }: Props): JSX.Element {
    const shouldRender = useSelector((state: StoreState) =>
        getBudgetExecutionPageState(state).pageData.budgetItemsToIgnoreFilters.some(
            (budgetItem) => budgetItem.id === lineId,
        ),
    );

    if (!shouldRender) {
        return <div className={normalPaddingClass}></div>;
    }

    return (
        <React.Fragment>
            <div className={extendedPaddingClass}></div>

            <div className={styles.root}>
                <WithTooltip
                    anchor={TooltipAnchor.LEFT}
                    content={
                        'Новая строка бюджета. В случае применения фильтров строка бюджета потеряет статус "Новая"'
                    }
                >
                    <Icon svgSize={32} type={IconType.NEW_ROW_INDICATOR} />
                </WithTooltip>
            </div>
        </React.Fragment>
    );
}
