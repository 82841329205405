import * as React from 'react';
import { Funds, BudgetItem } from '@mrm/budget';
import { Skeleton } from 'sber-marketing-ui';

import { BudgetWidget } from '../BudgetWidget';
import { BudgetWindow, BudgetWindowMode } from '../BudgetWidget/BudgetWindow';
import { Table } from '../BudgetWidget/Table';

import * as style from './CommonInfoWidget.scss';

interface Props {
    refObject: React.RefObject<HTMLDivElement>;
    lineId: string;
    plannedFunds: Funds;
    blocks: {
        title: string;
        content: React.ReactNode;
        qaId: string;
        loading?: boolean;
    }[];
    sourceBudgetItem: BudgetItem;
    isBudgetWindowVisible: boolean;
    budgetWindowMode: BudgetWindowMode;
    onBudgetWindowClose: () => void;
}

export const CommonInfoWidget: React.FC<Props> = ({
    refObject,
    lineId,
    blocks,
    sourceBudgetItem,
    isBudgetWindowVisible,
    budgetWindowMode,
    plannedFunds,
    onBudgetWindowClose,
}) => {
    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'budgetPlanningLineModalCommonInfo',
            }}
        >
            <div className={style.widgetTitle}>Общая информация</div>
            <div className={style.blocks}>
                {blocks.length &&
                    blocks.map((blockParams) => {
                        if (!blockParams) {
                            return null;
                        }

                        const { title, content, loading, qaId } = blockParams;

                        return (
                            <div
                                key={title}
                                {...{
                                    'qa-id': qaId,
                                }}
                            >
                                <div className={style.title}>{title}</div>

                                {loading ? (
                                    <Skeleton
                                        customStyle={{ height: 18, width: '40%', borderRadius: 2, marginTop: 2 }}
                                    />
                                ) : (
                                    <div className={style.content}>{content}</div>
                                )}
                            </div>
                        );
                    })}
            </div>

            {(isBudgetWindowVisible || budgetWindowMode === BudgetWindowMode.DRAG_WINDOW) && (
                <BudgetWindow
                    title={'План по месяцам'}
                    parentRef={refObject}
                    mode={budgetWindowMode}
                    onCloseButtonClick={onBudgetWindowClose}
                >
                    <Table plannedFunds={plannedFunds} />
                </BudgetWindow>
            )}

            <BudgetWidget lineId={lineId} sourceBudgetItem={sourceBudgetItem} />
        </div>
    );
};
