import { HistoryActionType } from 'sber-marketing-types/backend';
import { NewsView, UserCard } from 'sber-marketing-types/frontend';
import { FilterItem } from 'sber-marketing-ui';

import { LoadingStatus } from '../commonTypes';

export interface GetNewsParams {
    authorIds?: number[];
    types?: HistoryActionType[];
    unseen?: boolean;
    activityIds?: number[];
    wasScrolledToBottom?: boolean;
}

/** News state signature */
export interface News {
    /** News loading status */
    status: LoadingStatus;
    /** Is more news can be loaded */
    canBeLoadedMore: boolean;
    /** Loading error (or "null") */
    error: Error | null;
    /** Loaded news list */
    entities: NewsItemParams[];
    filters: NewsFilters;
    filtersLoadingStatus: LoadingStatus;
}

export interface PushNewsParams {
    news: NewsView[];
    onlyUnseenNews: boolean;
    loginedUserId: number;
    preserveEntities: boolean;
}

export interface NewsItemParams {
    id: string | number;
    userId: number;
    action: HistoryActionType;
    shouldDisplayActivityName: boolean;
    updateTime: Date;
    activityId: number;
    activityName: string;
    shouldDisplayTaskName: boolean;
    unseen: boolean;
    taskId?: string | number;
    fileName?: string;
    commentText?: string;
    taskName?: string;
    taskChannelName?: string;
    difference?: DifferenceItemParams[];
    wasActivityAssignedToLoginedUser: boolean;
    newResponsibleId?: number;
    wasTaskAssignedToLoginedUser?: boolean;
}

export enum DifferenceType {
    TEXT = 'TEXT',
    ACTIVITY = 'ACTIVITY',
    DIVISION = 'DIVISION',
    USER = 'USER',
}

export interface DifferenceItemParams {
    id?: number | string;
    type: DifferenceType;
    caption?: string;
    before: any;
    beforeCaption?: string;
    after: any;
    afterCaption?: string;
    afterLink?: string;
}

export interface NewsFilters {
    type: FilterItem[];
    activity: FilterItem[];
    author: FilterItem[];
}

export interface NewsFiltersForRequest {
    authorIds?: number[];
    types?: HistoryActionType[];
    unseen?: boolean;
    activityIds?: number[];
}

export interface ContentFilter {
    items: FilterItem[];
}

export interface SaveFiltersActionParams {
    filters: {
        activityData: ActivityData[];
        authorIds: number[];
        types: HistoryActionType[];
    };
    users: UserCard[];
}

type ActivityData = {
    activityId: number;
    activityName: string;
};

export const NewsTypeFilterText = {
    [HistoryActionType.CreateActivity]: 'Создание проекта',
    [HistoryActionType.ChangeResponsible]: 'Назначение проекта',
    [HistoryActionType.ShareActivity]: 'Открытие доступа к проекту',
    [HistoryActionType.RevokeActivity]: 'Отзыв доступа к проекту',
    [HistoryActionType.UpdateActivity]: 'Редактирование проекта',
    [HistoryActionType.ActivityRealizationChanged]: 'Изменение сроков проекта',
    [HistoryActionType.ActivityPreparationTimingChanged]: 'Изменение сроков подготовки',
    [HistoryActionType.ActivityDebriefingTimingChanged]: 'Изменение даты подведения итогов',
    [HistoryActionType.DeleteActivity]: 'Удаление проекта',
    [HistoryActionType.AddTaskParticipant]: 'Добавление к задаче',
    [HistoryActionType.UpdateTask]: 'Редактирование задачи',
    [HistoryActionType.UpdateTaskDeadline]: 'Изменение дедлайна задачи',
    [HistoryActionType.UpdateTaskExecutor]: 'Назначение исполнителя задачи',
    [HistoryActionType.RemoveTaskParticipant]: 'Удаление из задачи',
    [HistoryActionType.AddTaskComment]: 'Новый комментарий в задаче',
    [HistoryActionType.MentionedInTaskComment]: 'Отметка в комментарии',
    [HistoryActionType.AddTaskFile]: 'Добавление файла к задаче',
    [HistoryActionType.AddTaskCommentFile]: 'Добавление файла в канале',
    [HistoryActionType.TaskClosed]: 'Закрытие задачи',
    [HistoryActionType.TaskToProgress]: 'Задача взята в работу',
    [HistoryActionType.DeleteTask]: 'Удаление задачи',
    [HistoryActionType.AddTaskChannel]: 'Добавление в канал',
    [HistoryActionType.DeleteTaskChannel]: 'Удаление из канала',
    [HistoryActionType.TaskReturned]: 'Задача вернулась в работу',
    [HistoryActionType.TaskDeadLineOverdue]: 'Просрочен дедлайн задачи',
    [HistoryActionType.OneDayToTaskDeadLine]: 'Один день до дедлайна задачи',
};
