/**
 * @fileoverview Here is draftable actions - simple synchronous actions which must be save
 * their results at task draft at background (as side effect)
 */

import { Dispatch } from 'redux';

import { editTitle as editTitleAction, editDescription as editDescriptionAction } from '../actions';
import { StoreState } from '../../';
import { TaskDraftScheduler } from '../../../api';
import { getId, getTitle, getDescription } from '../selectors';

const editDraft = (getState: () => StoreState) => {
    const state = getState();
    const id = getId(state);
    const title = getTitle(state);
    const description = getDescription(state);
    TaskDraftScheduler.getInstance().scheduleUpdate(id, title, description);
};

/** Edit task title */
export const editTitle =
    (title: string): any =>
    (dispatch: Dispatch<StoreState>, getState: () => StoreState) => {
        dispatch(editTitleAction(title));
        editDraft(getState);
    };

/** Edit task description text */
export const editDescription =
    (description: string): any =>
    (dispatch: Dispatch<StoreState>, getState: () => StoreState) => {
        dispatch(editDescriptionAction(description));
        editDraft(getState);
    };
