import * as React from 'react';
import { get } from 'lodash';
import { DataValue, compose, graphql } from 'react-apollo';
import autobind from 'autobind-decorator';

import { BudgetItemApi } from '@api';

import * as queries from './query';

export interface ChildrenProps {
    loading: boolean;
    sapComment: string;
    correctionsSAPComment: string;
    saveSAPComment(sapComment: string, expertId: number): Promise<void>;
}

interface Props extends ExternalProps, QueryProps {}

interface ExternalProps {
    linkId: string;
    budgetItemId: string;
    budgetId: string;
    serialNumber: number;
    children: (props: ChildrenProps) => JSX.Element;
}

type QueryProps = {
    withSapCommentQuery: DataValue<{
        link: {
            nodes: {
                budgetItem: {
                    sapComment: string;
                };
            }[];
        };
    }>;
    withCorrectionsQuery: DataValue<{
        corrections: {
            nodes: {
                params: string;
            }[];
        };
    }>;
};

class SAPCommentInputWrapper extends React.PureComponent<Props> {
    public render(): JSX.Element {
        return this.props.children(this.getProps());
    }

    private getProps(): ChildrenProps {
        const { withSapCommentQuery, withCorrectionsQuery } = this.props;

        const sapCommentLoading = get(withSapCommentQuery, 'loading');
        const correctionsLoading = get(withCorrectionsQuery, 'loading');

        const sapComment = get(withSapCommentQuery, 'link.nodes[0].budgetItem.sapComment');
        const correctionsSAPComment =
            JSON.parse(get(withCorrectionsQuery, 'corrections.nodes[0].params') || '{}').sapComment || null;

        return {
            loading: sapCommentLoading || correctionsLoading,
            sapComment,
            correctionsSAPComment,
            saveSAPComment: this.saveSAPComment,
        };
    }

    @autobind
    private async saveSAPComment(sapComment: string, expertId: number): Promise<void> {
        const {
            budgetItemId: id,
            withSapCommentQuery: { refetch },
        } = this.props;

        if (!expertId) {
            throw new Error('Missing expertId');
        }

        await BudgetItemApi.updateBudgetItem({
            id,
            expertId,
            sapComment,
        });

        refetch();
    }
}

export const SAPCommentInputConnected = compose(
    graphql<ExternalProps>(queries.WITH_SAP_COMMENT_QUERY, {
        name: 'withSapCommentQuery',
        options: ({ linkId }) => ({
            variables: {
                linkId,
            },
        }),
        skip: ({ linkId }) => !linkId,
    }),
    graphql<ExternalProps>(queries.WITH_CORRECTIONS_QUERY, {
        name: 'withCorrectionsQuery',
        options: ({ budgetId, serialNumber }) => ({
            variables: {
                budgetId,
                serialNumber,
            },
        }),
        skip: ({ budgetId, serialNumber }) => !budgetId || !serialNumber,
    }),
)(SAPCommentInputWrapper) as React.ComponentType<ExternalProps>;
