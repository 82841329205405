import { actionCreatorFactory } from 'typescript-fsa';

import { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type {
    Autopilot2,
    Autopilot2MediaplanPlanDataResponse as Autopilot2MediaplanPlanData,
    Autopilot2MediaplanChannelsGroups,
    ILPlacementSpec,
} from 'sber-marketing-types/backend';
import type {
    BriefStageForm,
    MediaplanSettingsForm,
    SocialStageForm,
    GroupedDictionaries,
    SwitchKeyActivityParams,
} from './types';
import { AutopilotStage } from './types';

const actionCreator = actionCreatorFactory('AUTOPILOT_PAGE');

const loadAutopilot = actionCreator<Autopilot2>('LOAD_AUTOPILOT');
const loadActivity = actionCreator<Activity>('LOAD_ACTIVITY');
const loadMediaplan = actionCreator<Autopilot2MediaplanPlanData>('LOAD_MEDIAPLAN');
const loadDictionaries = actionCreator<GroupedDictionaries>('LOAD_DICTIONARIES');
const loadMediaplanChannelsGroups = actionCreator<Autopilot2MediaplanChannelsGroups>('LOAD_MEDIAPLAN_CHANNELS_GROUPS');
const loadPlacementSpecs = actionCreator<ILPlacementSpec[]>('LOAD_PLACEMENT_SPECS');
const resetPageState = actionCreator('RESET_PAGE_STATE');
const setBriefFormValues = actionCreator<Partial<BriefStageForm>>('SET_BRIEF_FORM_VALUES');
const setMediaplanSettingsFormValues = actionCreator<Partial<MediaplanSettingsForm>>(
    'SET_MEDIAPLAN_SETTINGS_FORM_VALUES',
);
const setSelectedBudgetItemIds = actionCreator<string[]>('SET_SELECTED_BUDGET_ITEM_IDS');
const setChangedCreativeStatuses = actionCreator<Record<string, string>>('SET_CHANGED_CREATIVE_STATUSES');
const setSocialFormValues = actionCreator<Partial<SocialStageForm>>('SET_SOCIAL_FORM_VALUES');
const setCurrentStage = actionCreator<AutopilotStage>('SET_CURRENT_STAGE');
const setMediaplanStatus = actionCreator<boolean>('SET_MEDIAPLAN_STATUS');
const setPreloaderStatus = actionCreator<boolean>('SET_PRELOADER_STATUS');
const openSidebar = actionCreator('OPEN_SIDEBAR');
const closeSidebar = actionCreator('CLOSE_SIDEBAR');

const switchKeyActivityAsync = actionCreator.async<SwitchKeyActivityParams, void, Error>('SWITCH_KEY_ACTIVITY_ASYNC');

export {
    loadAutopilot,
    loadActivity,
    loadMediaplan,
    loadDictionaries,
    loadMediaplanChannelsGroups,
    loadPlacementSpecs,
    resetPageState,
    setBriefFormValues,
    setMediaplanSettingsFormValues,
    setSelectedBudgetItemIds,
    setChangedCreativeStatuses,
    setSocialFormValues,
    setCurrentStage,
    setMediaplanStatus,
    setPreloaderStatus,
    openSidebar,
    closeSidebar,
    switchKeyActivityAsync,
};
