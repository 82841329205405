import { createSelector } from 'reselect';
import * as lodash from 'lodash';
import * as moment from 'moment';

import { CreateActivityBudgetForm, CreateBudgetItemForm } from '@mrm/budget';
import { FormField, FieldValue } from 'sber-marketing-ui';
import { PageState, activityBudgetRequiredFields, budgetItemRequiredFields } from './types';
import { StoreState } from '../';

export const getExecutionBudgetEditPageState = (state: StoreState): PageState => state.executionBudgetEditPage;

export const makeActivityBudgetParams = createSelector(
    getExecutionBudgetEditPageState,
    (pageState: PageState): CreateActivityBudgetForm => {
        const { id, fields } = pageState.activityForm;
        const name = getFieldValueByName('name', fields) as string;

        return {
            id,
            budgetId: null,
            name: name?.trim(),
        };
    },
);

export const makeBudgetsItems = createSelector(
    getExecutionBudgetEditPageState,
    (pageState: PageState): CreateBudgetItemForm[] => {
        const { budgetItemForms } = pageState;

        return budgetItemForms.map((form) => {
            const { id, fields } = form;

            const responsibleIds = getFieldValueByName('responsibles', fields) as number[];
            const comment = getFieldValueByName('comment', fields) as string;
            const sapComment = getFieldValueByName('sapComment', fields) as string;
            const businessTarget = getFieldValueByName('businessTarget', fields) as string;
            const customerName = getFieldValueByName('customerName', fields) as string;
            const realizationStart = getFieldValueByName('startDate', fields) as moment.Moment;
            const realizationEnd = getFieldValueByName('endDate', fields) as moment.Moment;

            const dictionaryIds = [
                'direction',
                'tool',
                'item',
                'resource',
                'costCenter',
                'locationDriver',
                'activityType',
                'block',
                'division',
                'segment',
                'product',
                'regionality',
                'territory',
                'channel',
            ].map((item) => getFieldValueByName(item, fields) as string);

            return {
                id,
                responsibleIds: responsibleIds.length ? responsibleIds : null,
                activityId: null,
                budgetId: null,
                dictionaryIds: lodash.compact(dictionaryIds),
                comment,
                sapComment,
                realizationStart: realizationStart ? realizationStart.toDate() : null,
                realizationEnd: realizationEnd ? realizationEnd.toDate() : null,
                businessTarget,
                customerName,
            };
        });
    },
);

export const checkActivityFormValidation = createSelector(
    getExecutionBudgetEditPageState,
    (pageState: PageState): boolean => {
        const { activityForm } = pageState;

        const fieldsAreValid = activityForm.fields
            .filter((item) => lodash.includes(activityBudgetRequiredFields, item.name))
            .every((item) => !!item.value);

        const activityNameField = activityForm.fields.find((item) => item.name == 'name');

        let activityNameIsValid = true;

        if (activityNameField) {
            activityNameIsValid = activityNameField.value && (activityNameField.value as string).length >= 3;
        }

        return fieldsAreValid && activityNameIsValid;
    },
);

export const checkBudgetItemFormsValidation = createSelector(
    getExecutionBudgetEditPageState,
    (pageState: PageState): boolean => {
        return pageState.budgetItemForms.every((form) => {
            const fieldsAreValid = form.fields
                .filter((item) => lodash.includes(budgetItemRequiredFields, item.name))
                .every((item) => !!item.value);

            const sapCommentField = form.fields.find((item) => item.name == 'sapComment');

            let sapCommentIsValid = true;

            if (sapCommentField) {
                sapCommentIsValid = sapCommentField.value && (sapCommentField.value as string).length >= 3;
            }

            const startDateField = form.fields.find((item) => item.name == 'startDate');
            const endDateField = form.fields.find((item) => item.name == 'endDate');

            let datesAreValid = true;

            if (startDateField && endDateField) {
                datesAreValid =
                    (!!startDateField.value && !!endDateField.value) || (!startDateField.value && !endDateField.value);
            }

            return fieldsAreValid && sapCommentIsValid && datesAreValid;
        });
    },
);

export const getUnvalidFieldsNames = createSelector(getExecutionBudgetEditPageState, (state: PageState): string[] => {
    const { activityForm, budgetItemForms } = state;

    return [...activityForm.fields, ...lodash.flatMap(budgetItemForms, (budgetItemForm) => budgetItemForm.fields)]
        .filter((field) => !!field.errorMessage)
        .map((field) => field.title);
});

function getFieldValueByName(name: string, fields: FormField[]): FieldValue | FieldValue[] {
    const values = fields.filter((item) => item.name == name).map((item) => item.value);

    return values.length > 1 ? values : lodash.first(values);
}
