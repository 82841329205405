import * as React from 'react';

import { ReportChart } from './ReportChart';

import { builderReportChartConfigByMode } from './utils';
import type { ReportChartConfig } from './utils';

import { useState } from './useState';
import { useMode } from '../../hooks';

import * as styles from './styles.scss';

export const SomeReportsCharts: React.FC = () => {
    const { statisticsItems, statisticsTypeFilters } = useState();
    const { mode } = useMode();

    let reportChartConfig: ReportChartConfig[] = [];

    if (mode === 'activities') {
        reportChartConfig = builderReportChartConfigByMode['activities']({
            statisticsItems,
            statisticsTypeFilters,
        });
    }

    if (mode === 'tasks') {
        reportChartConfig = builderReportChartConfigByMode['tasks']({
            statisticsItems,
            statisticsTypeFilters,
        });
    }

    return (
        <div className={styles.root}>
            {reportChartConfig.map(({ name, color, data, type }) => (
                <ReportChart key={color} color={color} name={name} type={type} data={data} />
            ))}
        </div>
    );
};
