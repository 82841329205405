import * as React from 'react';
import * as lodash from 'lodash';

import { LinkRequest } from '../WithLinkRequests';

import { SerialNumbersList } from '../SerialNumbersList';

interface Props {
    existingLinks: LinkRequest[];
}

export class LinkedSerialNumbersContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { existingLinks } = this.props;

        const existingLinksIds = lodash.uniq(existingLinks.map((item) => item.budgetItem.serialNumber)).sort();

        const acceptedLinkIds = existingLinks
            .filter((item) => item.status == 'approved')
            .map((item) => item.budgetItem.serialNumber);

        return (
            <SerialNumbersList
                title={'ID, привязанные к проекту'}
                serialNumbers={existingLinksIds}
                checkedSerialNumbers={acceptedLinkIds}
            />
        );
    }
}
