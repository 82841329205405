import * as React from 'react';
import * as style from './BudgetItemStatus.scss';

import { ApprovedIndicator, ApproveInProgressIndicator, RejectedIndicator } from './indicators';
import { ControlButtons } from './ControlButtons';

interface Props {
    status: Status;
    showStatus: boolean;
    showControlButtons: boolean;
    onApproveButtonClick: () => void;
    onRejectButtonClick: (comment: string) => void;
}

export const enum Status {
    Approved = 'approved',
    Rejected = 'rejected',
    ApproveInProgress = 'approveInProgress',
}

const indicatorByStatus: Record<Status, React.FunctionComponent> = {
    [Status.ApproveInProgress]: ApproveInProgressIndicator,
    [Status.Approved]: ApprovedIndicator,
    [Status.Rejected]: RejectedIndicator,
};

export const BudgetItemStatus: React.FC<Props> = ({
    status,
    showStatus,
    showControlButtons,
    onApproveButtonClick,
    onRejectButtonClick,
}) => {
    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'budgetPlanningLineModalBudgetItemStatus',
            }}
        >
            {showControlButtons && (
                <ControlButtons
                    disableRejectButton={false}
                    onApproveClick={onApproveButtonClick}
                    onRejectClick={onRejectButtonClick}
                />
            )}

            {showStatus && status && React.createElement(indicatorByStatus[status])}
        </div>
    );
};
