import * as React from 'react';
import classNames from 'classnames';

import * as style from './SelectCell.scss';

import { CustomScrollbar_new as CustomScrollbar, Icon, IconType } from 'sber-marketing-ui';
import { CellEditorWrapper } from '../CellEditorWrapper';

const MAX_SCROLLBAR_HEIGHT = 320;

interface Props {
    title: string;
    items: SelectItem[];
    selectedValue: React.ReactText;
    titleColor?: string;
    backgroundColor?: string;
    icon?: { type: IconType; color: string; size: number };
    onValueChange: (value: React.ReactText) => void;
}

export interface SelectItem {
    title: string;
    value: React.ReactText;
    disabled?: boolean;
}

export const SelectCellEditTemplate = ({
    title,
    items,
    selectedValue,
    titleColor,
    backgroundColor,
    icon,
    onValueChange,
}: Props): JSX.Element => {
    return (
        <div className={style.root} style={{ backgroundColor }}>
            <div className={style.title} style={{ color: titleColor }}>
                {icon && (
                    <div className={style.titleIcon} style={{ width: icon.size, height: icon.size }}>
                        <Icon type={icon.type} svgSize={icon.size} color={icon.color} />
                    </div>
                )}

                {title}
            </div>

            <div className={classNames(style.openerIcon, style.opened)}>
                <Icon type={IconType.TRIANGLE8_UP} svgSize={8} />
            </div>

            <CellEditorWrapper>
                <div className={style.content}>
                    <CustomScrollbar maxHeight={MAX_SCROLLBAR_HEIGHT} hideScrollX freezeScrollX>
                        {items.map((item) => (
                            <div
                                key={item.value}
                                className={classNames(style.item, item.disabled && style.disabled)}
                                onClick={!item.disabled ? () => onValueChange(item.value) : null}
                            >
                                {item.title}

                                {item.value === selectedValue && (
                                    <div className={style.checkIcon}>
                                        <Icon type={IconType.CHECK_ICON} svgSize={10} />
                                    </div>
                                )}
                            </div>
                        ))}
                    </CustomScrollbar>
                </div>
            </CellEditorWrapper>
        </div>
    );
};
