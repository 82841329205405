import * as React from 'react';

import { WithTooltipProps } from '../types';
import { WithValidationCreativeRequestParams } from '../../WithValidationCreativeRequestParams';
import { TooltipOfFirstStageBehavior } from './TooltipOfFirstStageBehavior';

interface Props extends WithTooltipProps {}

export const TooltipOfFirstStageConnector: React.FC<Props> = (props) => (
    <WithValidationCreativeRequestParams creativeRequestId={props.creativeRequestId}>
        {({ params }) => <TooltipOfFirstStageBehavior {...props} validCreativeRequireParams={params} />}
    </WithValidationCreativeRequestParams>
);
