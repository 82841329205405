import { actionCreatorFactory } from 'typescript-fsa';
import { HistoryView, RecentView } from 'sber-marketing-types/frontend';

const creator = actionCreatorFactory('HISTORY');

// Sync actions

/** Add news into store */
export const addHistories = creator<HistoryView | HistoryView[]>('ADD_HISTORIES');

/** Add recent views into store */
export const addRecentViews = creator<RecentView | RecentView[]>('ADD_RECENT_VIEWS');
