import { FilterPeriod } from '@store/leaderDashboardPage';
import { ItemType } from './Dropdown';

const filterPeriodNames: Record<FilterPeriod, string> = {
    [FilterPeriod.Year]: 'год',
    [FilterPeriod.Quarter]: 'квартал',
    [FilterPeriod.Month]: 'месяц',
    [FilterPeriod.Week]: 'неделю',
};

const filterPeriodsOrder = [FilterPeriod.Year, FilterPeriod.Quarter, FilterPeriod.Month, FilterPeriod.Week];

export const buildItems = (): ItemType[] => {
    return filterPeriodsOrder.map((filterPeriod) => ({
        id: filterPeriod,
        title: filterPeriodNames[filterPeriod],
    }));
};
