import { axios } from '../lib/axios';
import { StageTemplate } from '@mrm/activity-stage';

const BASE_URL = '/api/stage-template';

export class StageTemplateApi {
    public static async getStageTemplates(): Promise<StageTemplate[]> {
        const res = await axios.get(`${BASE_URL}`);

        return res.data;
    }
}
