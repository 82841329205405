import * as React from 'react';
import { Input_redesign as Input, InputTheme_redesign as InputTheme } from 'sber-marketing-ui';
import classnames from 'classnames';

import * as styles from './TransferAmountInput.scss';

export enum Themes {
    Regular = 'Regular',
    WithoutLeftBorder = 'WithoutLeftBorder',
    WithoutRightBorder = 'WithoutRightBorder',
}

interface Props {
    qaIndex?: string;
    title: string;
    value: number;
    inputValue: string;
    addAllAmountButton: boolean;
    maxValue?: number;
    maxButtonText?: string;
    maxButtonFullText?: string;
    maxButtonValue?: number;
    theme?: Themes;
    disabled?: boolean;
    onChange: (value: string) => void;
    onFocus: () => void;
    onBlur: () => void;
    onAllAmountButtonClick: () => void;
}

export function TransferAmountInput({
    qaIndex,
    title,
    value,
    maxButtonText,
    maxButtonFullText,
    maxButtonValue,
    addAllAmountButton,
    theme,
    disabled,
    inputValue,
    onChange,
    onFocus,
    onBlur,
    onAllAmountButtonClick,
}: Props): JSX.Element {
    const maxMaxIsReached = maxButtonValue !== undefined && value >= maxButtonValue;

    let themeClass: string;
    switch (theme) {
        case Themes.WithoutLeftBorder:
            themeClass = styles.rootWithoutLeftBorder;
            break;
        case Themes.WithoutRightBorder:
            themeClass = styles.rootWithoutRightBorder;
            break;
        case Themes.Regular:
        default:
            break;
    }

    return (
        <div className={classnames(styles.root, themeClass)}>
            <div className={styles.titleRow}>
                <div className={styles.title}>{title}</div>

                {addAllAmountButton && (
                    <div
                        className={classnames(
                            styles.maxAmountButton,
                            maxMaxIsReached && styles.maxAmountButtonDisabled,
                        )}
                        onClick={maxMaxIsReached ? null : onAllAmountButtonClick}
                        {...{
                            'qa-id': 'budgetTransferMenuTransferAmountInputAllAmountButton',
                        }}
                    >
                        {maxMaxIsReached ? maxButtonFullText || 'максимальная' : maxButtonText || 'вся доступная сумма'}
                    </div>
                )}
            </div>

            <div className={styles.inputWrapper}>
                <Input
                    qaId="budgetTransferMenuTransferAmountInput"
                    qaIndex={qaIndex}
                    disabled={disabled}
                    value={inputValue}
                    valueType="floatPositive"
                    placeholder="Введите сумму"
                    theme={InputTheme.BudgetTransferMenu}
                    onInputChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
            </div>
        </div>
    );
}
