import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';

import { BudgetCorrectionApi } from '@api';

import { StoreState } from '@store';
import { setNotification } from '@store/common/actions';
import { NotificationMessage, NotificationType, NotificationActionType } from '@store/common/types';
import { getUnsavedChange } from '@store/budgetCorrections/selectors';
import { UnsavedChange } from '@store/budgetCorrections/types';
import { resetUnsavedChange, setRejectComment } from '@store/budgetCorrections/actions';

import { Popup } from './Popup';
import { CorrectionStatus } from '@mrm/budget';

interface MapProps {
    unsavedChange: UnsavedChange;
}

interface DispatchProps {
    setNotification: (requestNotification: NotificationMessage) => void;
    resetUnsavedChange: () => void;
    setRejectComment: (rejectComment: string) => void;
}

interface Props extends Partial<MapProps & DispatchProps> {
    applyFilters: () => void;
}

interface State {
    commentIsValid: boolean;
    preloaderIsActive: boolean;
}

const RequestNotificationCommentMap: Partial<Record<CorrectionStatus, string>> = {
    [CorrectionStatus.Approved]: 'Корректировка утверждена',
    [CorrectionStatus.Rejected]: 'Корректировка отклонена',
    [CorrectionStatus.NeedApproving]: 'Корректировка сохранена',
};

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class PopupContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            commentIsValid: true,
            preloaderIsActive: false,
        };
    }

    public render(): JSX.Element {
        const { preloaderIsActive, commentIsValid } = this.state;

        return React.createElement(Popup, {
            unsavedChange: this.props.unsavedChange,
            preloaderIsActive,
            commentIsValid,
            closePopup: this.closePopup,
            onConfirm: this.onConfirm,
            onChangeCommentStatus: this.onChangeCommentStatus,
        });
    }

    @autobind
    private async onConfirm() {
        const { unsavedChange } = this.props;

        await new Promise<void>((resolve) => this.setState({ preloaderIsActive: true }, resolve));
        await BudgetCorrectionApi.changeCorrectionStatus({
            id: unsavedChange.correctionId,
            status: unsavedChange.newStatus as any,
            comment: unsavedChange.rejectComment || '',
        });

        this.props.resetUnsavedChange();
        this.props.setNotification({
            type: NotificationType.SUCCESS,
            typeAction: NotificationActionType.SAVING_CORRECTION_ON_BUDGET_CORRECTION_PAGE,
            comment: RequestNotificationCommentMap[unsavedChange.newStatus],
        });

        await new Promise<void>((resolve) => this.setState({ preloaderIsActive: false }, resolve));

        this.props.applyFilters();
    }

    @autobind
    private onChangeCommentStatus(error: string): void {
        this.setState({
            commentIsValid: !!error,
        });
    }

    @autobind
    private closePopup(): void {
        this.props.resetUnsavedChange();
        this.setState({
            commentIsValid: true,
            preloaderIsActive: false,
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        unsavedChange: getUnsavedChange(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setNotification,
            resetUnsavedChange,
            setRejectComment,
        },
        dispatch,
    );
}
