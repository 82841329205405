import * as React from 'react';
import { SkeletonText } from '../../layout';
import { OptionProps } from '../Option';

export const defaultOptionsLoader: OptionProps<any>[] = [
    {
        value: 0,
        titleContent: (
            <>
                <SkeletonText width={42} /> <SkeletonText width={50} />
            </>
        ),
        subtitleContent: <SkeletonText width={120} />,
    },
    {
        value: 1,
        titleContent: (
            <>
                <SkeletonText width={60} /> <SkeletonText width={40} />
            </>
        ),
        subtitleContent: <SkeletonText />,
    },
    {
        value: 2,
        titleContent: (
            <>
                <SkeletonText width={25} /> <SkeletonText width={60} />
            </>
        ),
        subtitleContent: <SkeletonText width={80} />,
    },
];
