import * as React from 'react';
import classNames from 'classnames';

import { useGetR7StatusQuery, useGetVideoPreviewQuery } from '@api';

import { getFileTooltip, getImagePreview } from '@modules/files/utils';
import { FileData } from '@modules/files/types';

import { SimpleFileThumbnail, SimpleFileThumbnailProps } from '../SimpleFileThumbnail';

import * as styles from './FileThumbnail.scss';

const videoExtensions: string[] = require('video-extensions');

export interface FileThumbnailProps extends Omit<SimpleFileThumbnailProps, 'fileType' | 'hideType'> {
    file: FileData;
}

export function FileThumbnail({ file, style, className, children, ...props }: FileThumbnailProps) {
    const isVideo = videoExtensions.includes(file.type);
    const { data: { url } = {}, isLoading: isVideoPreviewLoading } = useGetVideoPreviewQuery(
        {
            fileName: file.name,
            containerName: file.containerName,
            originName: file.originName,
            storageEngine: file.storage as any,
        },
        { skip: !isVideo },
    );
    const { data: status } = useGetR7StatusQuery({
        fileId: file.id,
        containerName: file.containerName,
        storageEngine: file.storage as any,
    });
    const tooltip = getFileTooltip(file.originName, file.createTime, file.type, status?.updatedBy?.name);

    const imagePreview = isVideoPreviewLoading ? null : url || getImagePreview(file);

    return (
        <SimpleFileThumbnail
            title={tooltip}
            {...props}
            fileType={file.type}
            hideType={Boolean(imagePreview)}
            style={{
                ...style,
                backgroundImage: imagePreview && `url("${imagePreview}")`,
            }}
            className={classNames(styles.root, imagePreview && styles.image, className)}
        >
            {children}
            {status && (
                <div data-qa-id="FileThumbnail__changed" className={styles.changed}>
                    Изм
                </div>
            )}
        </SimpleFileThumbnail>
    );
}
