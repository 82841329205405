import * as React from 'react';

import * as style from './CheckboxListCell.scss';

import { Icon, IconType } from 'sber-marketing-ui';
import { SnapshotTooltip } from '../SnapshotTooltip';
import { CommentsButton } from '../CommentsButton';
import { ContextMenu } from '../ContextMenu';

interface Props {
    title: string;
    tooltip: string;
    customStyle?: any;
    snapshot?: any;
    commentButtonProps?: { hasNewComments: boolean; onClick: () => void };
}

export const CheckboxListCell = ({ title, tooltip, customStyle, snapshot, commentButtonProps }: Props): JSX.Element => {
    async function onCopyValueClick() {
        const formattedValue = tooltip.replace(/ /g, '');

        await navigator.clipboard.writeText(`${formattedValue}`);
    }

    return (
        <>
            <CommentsButton {...commentButtonProps} />
            <SnapshotTooltip snapshot={snapshot} />
            <ContextMenu
                items={[
                    { title: 'Копировать', onClick: onCopyValueClick },
                    { title: 'Смотреть комментарии', onClick: commentButtonProps?.onClick },
                ]}
            />

            <div className={style.root} title={tooltip} style={{ ...customStyle }}>
                <div className={style.title}>{title}</div>

                <div className={style.openerIcon}>
                    <Icon type={IconType.TRIANGLE8_DOWN} svgSize={8} />
                </div>
            </div>
        </>
    );
};
