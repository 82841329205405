import * as React from 'react';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import { UserResponseParams } from 'sber-marketing-types/frontend';
import { DictionaryType } from '@mrm/dictionary';
import { Tag } from '@mrm/tags';

import {
    CellValueType,
    ColumnData,
    ColumnFilters,
    ColumnName,
    CustomCellType,
    Filters,
    LoadFiltersPaylaod,
    LoadingStatus,
    FilterKey,
    GroupedDictionaries,
} from '@store/budgetPlanning';

import { ItemTemplate } from './ItemTemplate';
import { getColumnByName } from './utils';
import { ColumnsList } from '../../../../../../ColumnsConfig';

interface Props {
    columnName: FilterKey;
    filters: ColumnFilters;
    filterLoadingStatus: LoadingStatus;
    users: UserResponseParams[];
    dictionaryType: DictionaryType;
    dictionaries: GroupedDictionaries;
    tags: lodash.Dictionary<Tag>;
    isExpanded: boolean;
    showApplyFiltersButton: boolean;
    loadFiltersByColumn: () => void;
    loadFilters: (payload: LoadFiltersPaylaod) => void;
    setFilters: (filters: Filters) => void;
    onExpandChange: (isExpanded: boolean) => void;
    onApplyFiltersButtonClick: () => void;
}

interface State {
    selectedFilters: React.ReactText[];
}

export class ItemBehavior extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            selectedFilters: Object.keys(props.filters).filter((filterItem) => props.filters[filterItem]),
        };
    }

    public async componentDidMount(): Promise<void> {
        this.props.loadFiltersByColumn();
    }

    public componentDidUpdate() {
        const { isExpanded, filterLoadingStatus } = this.props;

        const needUpdateFilters = filterLoadingStatus == LoadingStatus.NOT_LOADED && isExpanded;

        if (needUpdateFilters) {
            this.props.loadFiltersByColumn();
        }
    }

    public render(): JSX.Element {
        const {
            dictionaryType,
            columnName,
            users,
            filters,
            dictionaries,
            isExpanded,
            showApplyFiltersButton,
            tags,
            onExpandChange,
            onApplyFiltersButtonClick,
        } = this.props;

        const { selectedFilters } = this.state;

        return React.createElement(ItemTemplate, {
            title: this.title,
            dictionaryType,
            columnName,
            users,
            dictionaries,
            filters,
            currencySearch: this.isColumnOfCurrencyType,
            isLoading: this.isLoading,
            isExpanded,
            selectedFilters: [...selectedFilters],
            filterNamesAreNumeral: this.isFilterOfNumeral,
            showApplyFiltersButton,
            tags,
            onFiltersChange: this.onFiltersChange,
            onExpandChange,
            onApplyFiltersButtonClick,
        });
    }

    @autobind
    private onFiltersChange(selectedFilters: string[]): void {
        const updatedFilters = lodash.clone(this.props.filters);

        for (const filter in updatedFilters) {
            updatedFilters[filter] = selectedFilters.includes(filter);
        }

        this.props.loadFilters({
            columnName: this.props.columnName,
            filters: updatedFilters,
        });

        this.setState(
            {
                selectedFilters,
            },
            () => this.onFiltersSelection(updatedFilters),
        );
    }

    @autobind
    private onFiltersSelection(columnFilters: ColumnFilters): void {
        const { dictionaries, columnName } = this.props;
        let updatedFilters = columnFilters;

        const column = ColumnsList.find((column) => column.name === columnName);

        if (columnName !== ColumnName.Responsible && column.customCellType === CustomCellType.Dropdown) {
            const columnTitles = lodash.keys(columnFilters);

            const newFilters = {};

            columnTitles.forEach((key) => {
                const value = dictionaries.byId[key];
                const valueId = value ? value.id : null;

                newFilters[valueId] = columnFilters[key];
            });

            updatedFilters = newFilters;
        }

        this.props.setFilters({
            [columnName]: updatedFilters,
        });
    }

    private get isLoading(): boolean {
        const { filterLoadingStatus } = this.props;
        return filterLoadingStatus === LoadingStatus.LOADING;
    }

    private get isColumnOfCurrencyType(): boolean {
        return this.column.valueType === CellValueType.Currency;
    }

    private get isFilterOfNumeral(): boolean {
        return this.column.valueType == CellValueType.Number || this.column.valueType == CellValueType.Currency;
    }

    private get title(): string {
        return this.column.title || '';
    }

    private get column(): ColumnData {
        return getColumnByName(this.props.columnName);
    }
}
