import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { CellParams, CellPosition, CellsStorage, ColumnHeaderParams, ColumnHeadersStorage } from '../types';

interface Props {
    makeColumnHeaderParams: (columnName: string) => ColumnHeaderParams;
    makeCellParams: (cellPosition: CellPosition) => CellParams;
}

export class TableViewModel {
    private columnHeaders: ColumnHeadersStorage = {};
    private cells: CellsStorage = {};
    private makeColumnHeaderParams: (columnName: string) => ColumnHeaderParams;
    private makeCellParams: (cellPosition: CellPosition) => CellParams;

    public constructor(props: Props) {
        this.makeColumnHeaderParams = props.makeColumnHeaderParams;
        this.makeCellParams = props.makeCellParams;
    }

    @autobind
    public setColumnHeader(columnName: string, columnHeader: ColumnHeaderParams): void {
        this.columnHeaders[columnName] = columnHeader;
    }

    @autobind
    public getColumnHeader(columnName: string): ColumnHeaderParams {
        if (!this.columnHeaders[columnName]) {
            this.columnHeaders[columnName] = this.makeColumnHeaderParams(columnName);
        }

        return this.columnHeaders[columnName];
    }

    @autobind
    public setCell(position: CellPosition, cell: CellParams): void {
        const { columnName, lineId } = position;

        if (!this.cells[lineId]) {
            this.cells[lineId] = {};
        }

        this.cells[lineId][columnName] = cell;
    }

    @autobind
    public getCell(position: CellPosition): CellParams {
        const { columnName, lineId } = position;

        let cell = lodash.get(this.cells, [lineId, columnName]);

        if (!cell) {
            cell = this.makeCellParams(position);

            if (!this.cells[lineId]) {
                this.cells[lineId] = {};
            }

            this.cells[lineId][columnName] = cell;
        }

        return cell;
    }
}
