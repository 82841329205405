import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';

import { StoreState } from '@store';
import { BudgetItemStatus as BudgetItemStatusValues } from '@mrm/budget';
import {
    getBudgetItemByLineId,
    getLineModalState,
    setLineStatus,
    setRejectMenuLineId,
    LineStatusChange,
    getBudgetPlanningPageState,
    LineStatusChanges,
} from '@store/budgetPlanning';

import { TableLoader, TableSaver } from '../../../modules';
import { BudgetItemStatus, Status } from './BudgetItemStatus';

interface Props extends Partial<MapProps & DispatchProps> {
    lineId: string;
}

interface MapProps {
    userIsBudgetExpert: boolean;
    budgetItemStatus: BudgetItemStatusValues;
    lineStatusChanges: LineStatusChanges;
}

interface DispatchProps {
    setLineStatus: (lineStatusChange: LineStatusChange) => void;
    setRejectMenuLineId: (lineId: string) => void;
}

@(connect(mapStoreToProps, mapDispatchToProps) as any)
export class BudgetItemStatusContainer extends React.Component<Props> {
    private tableSaver: TableSaver;
    private tableLoader: TableLoader;

    constructor(props: Props) {
        super(props);

        this.tableLoader = TableLoader.getInstance();
        this.tableSaver = TableSaver.getInstance();
    }

    public render() {
        const status = this.getStatus();

        return status
            ? React.createElement(BudgetItemStatus, {
                  status,
                  showControlButtons: this.needApprovingOfCurrentUser(),
                  showStatus: !this.needApprovingOfCurrentUser(),
                  onApproveButtonClick: this.onApproveButtonClick,
                  onRejectButtonClick: this.onRejectButtonClick,
              })
            : null;
    }

    @autobind
    private async onApproveButtonClick(): Promise<void> {
        const { lineId, setLineStatus } = this.props;
        setLineStatus({ lineId, status: BudgetItemStatusValues.Approved });
        await this.tableSaver.applyLineApprovement(lineId);
        await this.tableLoader.updateActivitiesByLineIds([lineId]);
    }

    @autobind
    private async onRejectButtonClick(comment: string): Promise<void> {
        const { lineId, setLineStatus } = this.props;
        setLineStatus({ lineId, status: BudgetItemStatusValues.Rejected, rejectComment: comment });
        await this.tableSaver.applyLineApprovement(lineId);
        await this.tableLoader.updateActivitiesByLineIds([lineId]);
    }

    private needApprovingOfCurrentUser(): boolean {
        const { userIsBudgetExpert, budgetItemStatus } = this.props;
        return budgetItemStatus === BudgetItemStatusValues.OnExpertApprovement && userIsBudgetExpert;
    }

    private getStatus(): Status {
        switch (this.props.budgetItemStatus) {
            case BudgetItemStatusValues.Rejected:
                return Status.Rejected;
            case BudgetItemStatusValues.Approved:
                return Status.Approved;
            case BudgetItemStatusValues.OnExpertApprovement:
                return Status.ApproveInProgress;
            default:
                return null;
        }
    }
}

function mapStoreToProps(store: StoreState): MapProps {
    const { lineId } = getLineModalState(store);
    const { lineStatusChanges } = getBudgetPlanningPageState(store);

    const { status, actions } = getBudgetItemByLineId(store, lineId);

    return {
        userIsBudgetExpert: (actions && actions.canApproveAsExpert && actions.canRejectAsExpert) || false,
        budgetItemStatus: status,
        lineStatusChanges,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setLineStatus,
            setRejectMenuLineId,
        },
        dispatch,
    );
}
