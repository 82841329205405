import * as html2canvasWrong from 'html2canvas';

import type { Html2Canvas, ConstructorParams } from './types';

import { CanvasScaler } from './CanvasScaler';
import { PADDING } from '../../consts';

export class DateFilterDrawer {
    private dateFilterCanvas: CanvasScaler;
    private pageCanvas: HTMLCanvasElement;
    private html2canvas: Html2Canvas;

    constructor({ canvas }: ConstructorParams) {
        this.pageCanvas = canvas;
        this.html2canvas = html2canvasWrong as any as Html2Canvas;
    }

    public async draw(): Promise<void> {
        await this.initDateFilterCanvas();
        await this.addDateFilterToPage();
    }

    private async initDateFilterCanvas(): Promise<void> {
        const dateFilterElement = this.getDateFilterElement();
        this.dateFilterCanvas = await this.convertElementToCanvas(dateFilterElement);
    }

    private getDateFilterElement(): HTMLElement {
        return document.getElementById('pdf-date-filter');
    }

    private async addDateFilterToPage(): Promise<void> {
        const ctx = this.pageCanvas.getContext('2d');

        ctx.drawImage(
            this.dateFilterCanvas.getCanvas(),
            320 + PADDING + PADDING,
            10,
            this.dateFilterCanvas.width,
            this.dateFilterCanvas.height,
        );
    }

    private async convertElementToCanvas(element: HTMLElement): Promise<CanvasScaler> {
        const SCALE = 2;
        const canvas = await this.html2canvas(element, { scale: SCALE, backgroundColor: '#f1f5f7' });
        return new CanvasScaler(canvas, SCALE);
    }
}
