// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-ChangeButton-_styles__root{height:48px;padding:0 20px;display:flex;align-items:center;justify-content:center;font-family:'Open Sans';font-size:12px;line-height:16px;letter-spacing:0.15px;color:#000000;background:#ffffff;border:1px solid #7e8681;border-radius:6px;user-select:none;cursor:pointer}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-ChangeButton-_styles__disabled{opacity:0.5;cursor:auto;user-select:none}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/CreationForm/ParamsEditor/ChangeButton/styles.scss"],"names":[],"mappings":"AAAA,kIACI,WAAY,CACZ,cAAe,CAEf,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CAEvB,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACjB,qBAAsB,CACtB,aAAc,CAEd,kBAAmB,CACnB,wBAAyB,CACzB,iBAAkB,CAElB,gBAAiB,CACjB,cAAe,CAClB,sIAGG,WAAY,CACZ,WAAY,CACZ,gBAAiB","sourcesContent":[".root {\n    height: 48px;\n    padding: 0 20px;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    font-family: 'Open Sans';\n    font-size: 12px;\n    line-height: 16px;\n    letter-spacing: 0.15px;\n    color: #000000;\n\n    background: #ffffff;\n    border: 1px solid #7e8681;\n    border-radius: 6px;\n\n    user-select: none;\n    cursor: pointer;\n}\n\n.disabled {\n    opacity: 0.5;\n    cursor: auto;\n    user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-ChangeButton-_styles__root",
	"disabled": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-CreationForm-ParamsEditor-ChangeButton-_styles__disabled"
};
export default ___CSS_LOADER_EXPORT___;
