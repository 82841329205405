// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-files-components-SimpleFileThumbnail-_SimpleFileThumbnail__root{aspect-ratio:1;width:var(--file-thumbnail-size);min-width:var(--file-thumbnail-size);font-size:calc(var(--file-thumbnail-size) / 4);font-weight:600;text-transform:uppercase;box-sizing:border-box;overflow:hidden;padding:calc(var(--file-thumbnail-size) / 8 - 1px)}.src-client-modules-files-components-SimpleFileThumbnail-_SimpleFileThumbnail__type{flex:1;min-width:0;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;text-align:center}\n", "",{"version":3,"sources":["webpack://./src/client/modules/files/components/SimpleFileThumbnail/SimpleFileThumbnail.scss"],"names":[],"mappings":"AAAA,oFACE,cAAe,CACf,gCAAiC,CACjC,oCAAqC,CACrC,8CAA+C,CAC/C,eAAgB,CAChB,wBAAyB,CACzB,qBAAsB,CACtB,eAAgB,CAChB,kDAAmD,CACpD,oFAGC,MAAO,CACP,WAAY,CACZ,eAAgB,CAChB,kBAAmB,CACnB,sBAAuB,CACvB,iBAAkB","sourcesContent":[".root {\n  aspect-ratio: 1;\n  width: var(--file-thumbnail-size);\n  min-width: var(--file-thumbnail-size);\n  font-size: calc(var(--file-thumbnail-size) / 4);\n  font-weight: 600;\n  text-transform: uppercase;\n  box-sizing: border-box;\n  overflow: hidden;\n  padding: calc(var(--file-thumbnail-size) / 8 - 1px);\n}\n\n.type {\n  flex: 1;\n  min-width: 0;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-files-components-SimpleFileThumbnail-_SimpleFileThumbnail__root",
	"type": "src-client-modules-files-components-SimpleFileThumbnail-_SimpleFileThumbnail__type"
};
export default ___CSS_LOADER_EXPORT___;
