// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-leaderDashboard-LeaderDashboard-Content-_styles__root{display:grid;grid-template-columns:466px 1fr;grid-template-rows:max-content 575px;grid-column-gap:16px;grid-row-gap:20px;background-color:#e6edf1;padding:16px 24px;border-radius:6px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/leaderDashboard/LeaderDashboard/Content/styles.scss"],"names":[],"mappings":"AAAA,0EACI,YAAa,CACb,+BAAgC,CAChC,oCAAqC,CACrC,oBAAqB,CACrB,iBAAkB,CAElB,wBAAyB,CACzB,iBAAkB,CAClB,iBAAkB","sourcesContent":[".root {\n    display: grid;\n    grid-template-columns: 466px 1fr;\n    grid-template-rows: max-content 575px;\n    grid-column-gap: 16px;\n    grid-row-gap: 20px;\n\n    background-color: #e6edf1;\n    padding: 16px 24px;\n    border-radius: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-leaderDashboard-LeaderDashboard-Content-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
