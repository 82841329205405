import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import { UserResponseParams } from 'sber-marketing-types/frontend';
import { PlainDictionary } from '@mrm/dictionary';

import {
    ColumnName,
    ColumnsVisiblityFilter,
    ColumnsWidth,
    SortingMode,
    Filters,
    ColumnFilters,
    CustomCellType,
    GroupedDictionaries,
    ColumnsNameWithCodes,
} from '@store/budgetPlanning/types';
import { PlanPermissionsSysNames } from 'sber-marketing-types/backend';

import { TableHeader } from './TableHeader';
import { StoreState } from '@store';
import { setSortingMode, setFilters } from '@store/budgetPlanning';
import { getBudgetPlanningPageState, getTableFilters, getPageData } from '@store/budgetPlanning/selectors';
import { getLoginUser } from '@store/user';
import { ColumnsList } from '../../ColumnsConfig';

interface Props extends Partial<MapProps>, Partial<DispatchProps> {
    hoveredColumnName: ColumnName;
    draggedEdgeColumnName: ColumnName;
    isResizingColumn: boolean;
    headerFixedColumnsRef: (element: HTMLDivElement) => void;
    onCellMouseEnter: (columnName: ColumnName) => void;
    onCellMouseLeave: () => void;
    onDropdownClick: (columnName: ColumnName, dropdownContent: JSX.Element) => void;
    onColumnEdgeMousedown: (columnName: ColumnName, mouseDownX: number) => void;
    onApplyFiltersButtonClick: () => void;
}

interface MapProps {
    users: UserResponseParams[];
    columnsWidth: ColumnsWidth;
    fixedColumnsNames: ColumnName[];
    columnsVisiblityFilter: ColumnsVisiblityFilter;
    sortingMode: SortingMode;
    filters: Filters;
    dictionaries: GroupedDictionaries;
    userIsBudgetExpert: boolean;
}

interface DispatchProps {
    setSortingMode: (sortingMode: SortingMode) => void;
    setFilters: (filterMode: Filters) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class TableHeaderContainer extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        const { filters: receivedFilters, users, dictionaries } = this.props;

        const filters = lodash.clone(receivedFilters);

        ColumnsList.forEach((column) => {
            if (column.name === ColumnName.Responsible) {
                filters[column.name] = Object.keys(filters[column.name]).reduce((acc, userId) => {
                    const numberUserId = Number(userId);
                    const user = users.find((user) => user.id == numberUserId);
                    const key = user ? `${user.secondName} ${user.firstName}` : null;

                    return {
                        ...acc,
                        [key]: filters[column.name][userId],
                    };
                }, {});
            } else if (column.customCellType === CustomCellType.Dropdown) {
                const columnName = column.name;

                const keys = lodash.keys(filters[columnName]);

                const mappedFilters = {};

                keys.forEach((key) => {
                    const value = dictionaries.byId[key];
                    const valueName = value ? value.value : key;

                    mappedFilters[valueName] = filters[columnName][key];
                });

                filters[columnName] = mappedFilters;
            }
        });

        return React.createElement(TableHeader, {
            columnsVisiblityFilter: this.props.columnsVisiblityFilter,
            columnsWidth: this.props.columnsWidth,
            filters,
            fixedColumnsNames: this.props.fixedColumnsNames,
            hoveredColumnName: this.props.hoveredColumnName,
            draggedEdgeColumnName: this.props.draggedEdgeColumnName,
            isStatusColumnVisible: this.props.userIsBudgetExpert,
            headerFixedColumnsRef: this.props.headerFixedColumnsRef,
            onCellMouseEnter: this.props.onCellMouseEnter,
            onCellMouseLeave: this.props.onCellMouseLeave,
            onDropdownClick: this.props.onDropdownClick,
            onFiltersSelection: this.onFiltersSelection,
            onColumnEdgeMousedown: this.props.onColumnEdgeMousedown,
            isResizingColumn: this.props.isResizingColumn,
            onApplyFiltersButtonClick: this.props.onApplyFiltersButtonClick,
        });
    }

    @autobind
    protected onFiltersSelection(columnName: ColumnName, columnFilters: ColumnFilters) {
        const { users, dictionaries } = this.props;

        const column = ColumnsList.find((column) => column.name === columnName);
        let updatedFilters = columnFilters;

        if (columnName === ColumnName.Responsible) {
            updatedFilters = Object.keys(columnFilters).reduce((acc, key) => {
                const user = users.find((user) => `${user.secondName} ${user.firstName}` === key);
                const newKey = user ? user.id : null;

                return {
                    ...acc,
                    [newKey]: columnFilters[key],
                };
            }, {});
        } else if (column.customCellType === CustomCellType.Dropdown) {
            const columnTitles = lodash.keys(columnFilters);

            const newFilters = {};

            if (ColumnsNameWithCodes.includes(columnName)) {
                const targetColumnn = ColumnsList.find((column) => column.name === columnName);

                const dictionariesByCode = Object.values(
                    dictionaries.byType[targetColumnn?.metaData?.dictionaryType] || [],
                ).reduce(
                    (acc: Record<string, PlainDictionary>, dictionary: PlainDictionary) => ({
                        ...acc,
                        [`${dictionary.code}`]: dictionary,
                    }),
                    {},
                );

                columnTitles.forEach((key) => {
                    const value = dictionariesByCode[key];
                    const valueCode = value ? value.code : null;

                    newFilters[valueCode] = columnFilters[key];
                });
            } else {
                columnTitles.forEach((key) => {
                    const value = dictionaries.byId[key];
                    const valueId = value ? value.id : null;

                    newFilters[valueId] = columnFilters[key];
                });
            }

            updatedFilters = newFilters;
        }

        this.props.setFilters({
            [columnName]: updatedFilters,
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { fixedColumnsNames, columnsWidth } = getBudgetPlanningPageState(state);
    const { allDictionaries, users } = getPageData(state);
    const { columnsVisiblityFilter, sortingMode, filters } = getTableFilters(state);

    const user = getLoginUser(state);

    const userIsBudgetExpert = lodash.includes(user.attributes.permissions, PlanPermissionsSysNames.OrganizationAccess);

    return {
        columnsWidth,
        columnsVisiblityFilter,
        sortingMode,
        filters,
        dictionaries: allDictionaries,
        fixedColumnsNames,
        userIsBudgetExpert,
        users,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setSortingMode,
            setFilters,
        },
        dispatch,
    );
}
