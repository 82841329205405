import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import { getFilterPeriod, getFilterDate, FilterPeriod } from '@store/leaderDashboardPage';
import type { Mode } from '@store/leaderDashboardPage';

import * as actions from '@store/leaderDashboardPage/thunks';

interface UseStore {
    (): UseStateReturn;
}

interface UseStateReturn extends Selected {
    setSelectedDate: SetSelectedDate;
}

interface Selected {
    selectedFilterPeriod: FilterPeriod;
    selectedFilterDate: string;
}

interface SetSelectedDate {
    (params: SetSelectedDateParams): void;
}

interface SetSelectedDateParams {
    date: string;
    mode: Mode;
}

export const useStore: UseStore = () => {
    const dispatch = useDispatch();

    const { selectedFilterDate, selectedFilterPeriod } = useSelector<StoreState, Selected>((state) => {
        return {
            selectedFilterPeriod: getFilterPeriod(state),
            selectedFilterDate: getFilterDate(state),
        };
    });

    const setSelectedDate: SetSelectedDate = React.useCallback(({ date, mode }) => {
        dispatch(
            actions.setFilterDate({
                filterDate: date,
                mode,
            }),
        );
    }, []);

    return {
        selectedFilterPeriod,
        selectedFilterDate,
        setSelectedDate,
    };
};

export { FilterPeriod as Period };
