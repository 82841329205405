import * as React from 'react';
import { useParams } from 'react-router-dom';

import { UserResponseParams } from 'sber-marketing-types/frontend';

import { Flex, OptionProps } from '@common/components';

import { ActivityTaskPageParams } from '@modules/task/pages';
import { AddTaskParticipant, TaskParticipantOptions } from '@modules/task/components';

import * as styles from './TaskPageParticipants.scss';

export interface SelectParticipantEventData {
    id: number;
    user: OptionProps<UserResponseParams>;
}

export const TaskPageParticipants: React.FC = () => {
    const { taskId } = useParams<ActivityTaskPageParams>();

    const handleSelect = (id: number, user: OptionProps<UserResponseParams>) => {
        document.dispatchEvent(
            new CustomEvent('clickParticipant', {
                detail: {
                    id,
                    user,
                } as SelectParticipantEventData,
            }),
        );
    };

    return (
        <Flex vertical flex gap={16} className={styles.root}>
            <AddTaskParticipant taskId={taskId} />
            <TaskParticipantOptions
                preventDefaultSelect
                hideSearch={13}
                optionsHeight={Infinity}
                deletable
                taskId={taskId}
                onSelect={handleSelect}
            />
        </Flex>
    );
};
