import * as React from 'react';
import autobind from 'autobind-decorator';

import { TextClamper, DisplayText } from './TextClamper';

interface Props {
    children: React.ReactChild | React.ReactChild[];
    lines: number;
}

interface State {
    displayText: DisplayText;
    displayToggleButton: boolean;
    isExpanded: boolean;
}

export class TextClamperContainer extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            displayText: DisplayText.BOTH,
            displayToggleButton: false,
            isExpanded: false,
        };
    }

    public render(): JSX.Element {
        const { children, lines } = this.props;

        const { displayText, displayToggleButton, isExpanded } = this.state;

        return (
            <TextClamper
                children={children}
                lines={lines}
                displayText={displayText}
                displayToggleButton={displayToggleButton}
                isExpanded={isExpanded}
                displayRef={this.checkVisibility}
                onToggleButtonClick={this.onToggleButtonClick}
            />
        );
    }

    @autobind
    private checkVisibility(el: HTMLDivElement): void {
        if (this.state.displayText === DisplayText.BOTH) {
            const clampedText = el.children[0];
            const nonClampedText = el.children[1];

            const isClampNeeded = nonClampedText.clientHeight > clampedText.clientHeight;
            this.setState({
                displayText: isClampNeeded ? DisplayText.CLAMPED : DisplayText.NON_CLAMPED,
                displayToggleButton: isClampNeeded,
            });
        }
    }

    @autobind
    private onToggleButtonClick(): void {
        this.setState((state) => ({
            isExpanded: !state.isExpanded,
            displayText: state.displayText === DisplayText.CLAMPED ? DisplayText.NON_CLAMPED : DisplayText.CLAMPED,
        }));
    }
}
