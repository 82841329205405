// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-calendar-Chart-HorizontalScrollbar-_HorizontalScrollbar__root{width:100%}.src-client-modules-calendar-Chart-HorizontalScrollbar-_HorizontalScrollbar__scrollContent{height:4px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/calendar/Chart/HorizontalScrollbar/HorizontalScrollbar.scss"],"names":[],"mappings":"AAAA,kFACI,UAAW,CACd,2FAGG,UAAW","sourcesContent":[".root {\n    width: 100%;\n}\n\n.scrollContent {\n    height: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-calendar-Chart-HorizontalScrollbar-_HorizontalScrollbar__root",
	"scrollContent": "src-client-modules-calendar-Chart-HorizontalScrollbar-_HorizontalScrollbar__scrollContent"
};
export default ___CSS_LOADER_EXPORT___;
