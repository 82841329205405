import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LabeledInput } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getBudgetTransferMenuState, setParticipatorComment } from '@store/budgetExecution/budgetTransferMenu';

import * as styles from './ParticipatorCommentInput.scss';

function useInteractivity() {
    const dispatch = useDispatch();
    const participatorComment = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).participatorData.participatorComment,
    );

    const saveComment = React.useCallback(
        (comment: string) => dispatch(setParticipatorComment(comment)),
        [dispatch, setParticipatorComment],
    );

    return {
        participatorComment,
        saveComment,
    };
}

export function ParticipatorCommentInput(): JSX.Element {
    const { participatorComment, saveComment } = useInteractivity();

    return (
        <div className={styles.root}>
            <LabeledInput
                qaId="budgetTransferMenuparticipatorCommentInput"
                title="Комментарий"
                value={participatorComment}
                onValueChange={saveComment}
            />
        </div>
    );
}
