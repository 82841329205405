import * as React from 'react';

import { SourceIdTable, useVisibility as useVisibilitySourceIdTable } from './SourceIdTable';
import * as styles from './styles.scss';

export const SummaryAmountWidget: React.FC = () => {
    const { isVisibility: isVisibilitySourceIdTable } = useVisibilitySourceIdTable();

    return (
        <div className={styles.root}>
            {isVisibilitySourceIdTable && (
                <>
                    <div className={styles.title}>Сводные суммы</div>
                    <SourceIdTable />
                </>
            )}
        </div>
    );
};
