import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { ActivityApi } from '@api';

import { StoreState } from '@store';

import * as actions from './actions';
import { SwitchKeyActivityParams } from './types';
import { getActivity } from './selectors';

export const switchKeyActivity = bindThunkAction<StoreState, SwitchKeyActivityParams, void, Error>(
    actions.switchKeyActivityAsync,
    async (params, dispatch, getState) => {
        const { id, isKey } = params;
        const { realizationStart, realizationEnd } = getActivity(getState());

        return ActivityApi.editActivity(id, {
            isKey,
            realizationEnd,
            realizationStart,
        });
    },
);
