import { Success } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { LoadingStatus } from '@store/commonTypes';

import { CommonUserConfigState, CommonUserConfig, LoadCommonUserConfig, UpdateCommonUserConfig } from './types';
import * as actions from './actions';

const initialState: CommonUserConfigState = {
    status: LoadingStatus.NOT_LOADED,
    fields: {
        lastSeenRelease: '01-01-2018',
        previousSearch: '',
    },
};

class Reducer {
    public static loadUserConfig(
        state: CommonUserConfigState,
        payload: Success<CommonUserConfig, LoadCommonUserConfig>,
    ): CommonUserConfigState {
        const {
            result: { receivedUserConfig },
        } = payload;

        return {
            status: LoadingStatus.LOADED,
            fields: {
                ...state.fields,
                ...receivedUserConfig,
            },
        };
    }

    public static updateUserConfig(
        state: CommonUserConfigState,
        payload: UpdateCommonUserConfig,
    ): CommonUserConfigState {
        return {
            ...state,
            fields: {
                ...state.fields,
                ...payload,
            },
        };
    }
}

export const commonUserConfigReducer = reducerWithInitialState(initialState)
    .case(actions.loadCommonUserConfig.done, Reducer.loadUserConfig)
    .case(actions.updateCommonUserConfig.started, Reducer.updateUserConfig);
