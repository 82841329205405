// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-TaskCard-Stage-_Stage__root{display:flex;overflow:hidden}.src-client-modules-common-TaskCard-Stage-_Stage__skeleton{width:150px;height:17px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/TaskCard/Stage/Stage.scss"],"names":[],"mappings":"AAAA,uDACI,YAAa,CACb,eAAgB,CACnB,2DAGG,WAAY,CACZ,WAAY","sourcesContent":[".root {\n    display: flex;\n    overflow: hidden;\n}\n\n.skeleton {\n    width: 150px;\n    height: 17px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-TaskCard-Stage-_Stage__root",
	"skeleton": "src-client-modules-common-TaskCard-Stage-_Stage__skeleton"
};
export default ___CSS_LOADER_EXPORT___;
