import * as React from 'react';
import classnames from 'classnames';

import * as commonStyles from '../../CellDescription.scss';
import * as styles from './CellDescriptionStub.scss';

export enum CellDescriptionStubThemes {
    Regular,
    Red,
}

interface Props {
    qaId: string;
    text: string;
    large?: boolean;
    theme?: CellDescriptionStubThemes;
}

export function CellDescriptionStub({ qaId, text, large, theme }: Props): JSX.Element {
    let themeClass: string;
    switch (theme) {
        case CellDescriptionStubThemes.Red:
            themeClass = styles.colorRed;
            break;
        case CellDescriptionStubThemes.Regular:
        default:
            break;
    }

    return (
        <div
            className={classnames(commonStyles.root, styles.root, large && styles.rootLarge, themeClass)}
            {...{
                'qa-id': `budgetTransferMenuCellDescriptionStub ${qaId}`,
            }}
        >
            {text}
        </div>
    );
}
