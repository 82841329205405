import * as React from 'react';
import classnames from 'classnames';
import { FileResponse } from 'sber-marketing-types/frontend';
import { WithTooltip } from 'sber-marketing-ui';

import { FileAsset } from '@common/FileAssetRedesign';
import { Utils, DatesFormatter } from '@common/Utils';
import { useTextOverflowCheck } from '@common/hooks';

import * as styles from './File.scss';

interface Props extends FileResponse {
    taskId: string;
    theme?: 'error';
}

export function File({ id, type, name, originName, createTime, size, theme, taskId }: Props): JSX.Element {
    const titleRef = React.useRef<HTMLDivElement>();
    const { horizontal: showTooltip } = useTextOverflowCheck(titleRef);

    const title = `${originName}.${type}`;

    let themeClass: string;
    switch (theme) {
        case 'error':
            themeClass = styles.rootError;
            break;
        default:
            themeClass = null;
            break;
    }

    return (
        <div className={classnames(styles.root, themeClass)}>
            <div className={styles.asset}>
                <FileAsset id={id} name={name} originName={originName} type={type} taskId={taskId} />
            </div>

            <div className={styles.titleAndInfo}>
                <WithTooltip hidden={!showTooltip} content={title}>
                    <div ref={titleRef} className={styles.title}>
                        {title}
                    </div>
                </WithTooltip>

                <div className={styles.info}>
                    {DatesFormatter.ddMonthyyyy(createTime)}, {Utils.formatFileSize(size)}
                </div>
            </div>
        </div>
    );
}
