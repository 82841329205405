export const enum Month {
    Jan = 'jan',
    Feb = 'feb',
    Mar = 'mar',
    Apr = 'apr',
    May = 'may',
    Jun = 'jun',
    Jul = 'jul',
    Aug = 'aug',
    Sept = 'sept',
    Oct = 'oct',
    Nov = 'nov',
    Dec = 'dec'
}

export const enum BudgetStatus {
    Plan = 'plan',
    ArchivePlan = 'archive_plan',
    Execution = 'execution',
    ArchiveExecution = 'archive_execution',
    Archive = 'archive'
}

export const enum CorrectionType {
    Activity = 'activity',
    BudgetItem = 'budget_item',
    ReservedFunds = 'reserved_funds',
    FactFunds = 'fact_funds',
    PlanFundsTransfer = 'plan_funds_transfer',
    IncomeExternalPlanFundsTransfer = 'income_external_plan_funds_transfer',
    OutcomeExternalPlanFundsTransfer = 'outcome_external_plan_funds_transfer',
}

export const enum CorrectionStatus {
    NeedApproving = 'need_approving',
    Approved = 'approved',
    Rejected = 'rejected',
    Disabled = 'disabled'
}

export const enum DictionaryType {
    ActivityType = 'activity_type',
    AutopilotYandexMetrikaTarget = 'autopilot_yandex-metrika-target',
    Block = 'block',
    CalendarGroup = 'calendar_group',
    Channel = 'channel',
    CostCenter = 'cost_center',
    CostDirection = 'cost_direction',
    Direction = 'direction',
    Division = 'division',
    FunctionalBlock = 'functional_block',
    IFKV = 'ifkv',
    Item = 'item',
    LocationDriver = 'location_driver',
    Objective = 'objective',
    Product = 'product',
    Regionality = 'regionality',
    Resource = 'resource',
    ResourceUsage = 'resource_usage',
    Segment = 'segment',
    Stage = 'stage',
    StageTemplate = 'stage_template',
    Subcategory = 'subcategory',
    Task = 'task',
    Territory = 'territory',
    Tool = 'tool',
    Lot = 'lot',
    Tag = 'tag', // from metacom-client,
    RegionalityType = 'regionality_type',
    RegionalityUsecase = 'regionality_usecase'
}

export const enum BudgetItemStatus {
    Draft = 'draft',
    Published = 'published',
    Approved = 'approved',
    Rejected = 'rejected',
    OnExpertApprovement = 'on_expert_approvement',
    OnExecution = 'on_execution',
    Disabled = 'disabled',
    Archived = 'archived'
}

export const enum BudgetItemApproverStatus {
    Approved = 'approved',
    Rejected = 'rejected',
    Waiting = 'waiting'
}

export const enum DictionaryStatus {
    ACTIVE = 'active',
    DELETED = 'deleted',
}

export const enum AutopilotCampaignStatus {
    RUNNING = 'running',
    STOPPED = 'stopped',
}

export const enum TagColorType {
    Color1 = '#75C87C',
    Color2 = '#E3D552',
    Color3 = '#E29E46',
    Color4 = '#D1694D',
    Color5 = '#5C8BCE',
    Color6 = '#95C1E4',
    Color7 = '#80CBBE',
    Color8 = '#B1DB58',
    Color9 = '#7E76D3',
    Color10 = '#B76ED9',
    Color11 = '#E471CB',
    Color12 = '#000000'
}

export const enum TagEmojiType {
    Profile = 'Profile',
    Popup = 'Popup',
    Phone = 'Phone',
    Chain = 'Chain',
    Folder = 'Folder',
    Calendar = 'Calendar',
    Lighbulb = 'Lighbulb',
    Money = 'Money',
    Pencil = 'Pencil',
    Star = 'Star',
    Question = 'Question',
    Gear = 'Gear',
    PatternDiagonalStripes = 'PatternDiagonalStripes',
    PatternStars = 'PatternStars',
    PatternDashes = 'PatternDashes',
    PatternHorizontalStripes = 'PatternHorizontalStripes',
    PatternDots = 'PatternDots',
    PatternBackArrow = 'PatternBackArrow'
}

export enum DAMArchiveStatus {
    NotExists = 'not_exists',
    Creating = 'creating',
    Processing = 'processing',
    Connected = 'connected'
}

export enum VideoPreviewStatus {
    Creating = 'creating',
    Created = 'created',
    Cached = 'cached',
    Error = 'error' 
}
