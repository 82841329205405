import * as React from 'react';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { MediaplanItem } from '@store/autopilotTv/types';
import type { CellPosition, ColumnWidths, LineId } from './types';
import { CellEvent } from './types';
import type { StoreState } from '@store';

import { MediaplanBudgetTableTemplate } from './MediaplanBudgetTableTemplate';
import { TableViewModel } from './TableViewModel';
import { TableView } from './TableView';
import {
    MakeTableColumns,
    leftFixedColumns,
    rightFixedColumns,
    MakeColumnsConfig,
    ColumnParams,
    ColumnsConfigParams,
} from './ColumnsConfig';
import { ColumnHeaderFactory, CellsFactory } from './modules';
import { getMediaplan } from '@store/autopilotTv/selectors';

interface Props extends Partial<MapProps> {
    displayPreloader?: boolean;
}

interface State {
    lineIds: string[];
    periods: { dateStart: string; dateEnd: string }[];
    collapsedGroupIds: number[];
}

interface MapProps {
    mediaplanItems: MediaplanItem[];
}

@(connect(mapStateToProps, null) as any)
export class MediaplanBudgetTableBehaviour extends React.PureComponent<Props, State> {
    private cellsStorage: TableViewModel;
    private table: TableView;
    private columnHeaderFactory: ColumnHeaderFactory;
    private cellsFactory: CellsFactory;
    private tableColumns: string[] = [];
    private defaultColumnWidths: ColumnWidths = {};

    public constructor(props: Props) {
        super(props);

        this.state = {
            lineIds: [],
            periods: this.makePeriods(),
            collapsedGroupIds: [],
        };

        this.columnHeaderFactory = new ColumnHeaderFactory({
            getLine: this.getLine,
            getAllLines: this.getAllLines,
            getColumnsConfig: this.getColumnsConfig,
        });

        this.cellsFactory = new CellsFactory({
            getLine: this.getLine,
            getAllLines: this.getAllLines,
            getGroup: this.getGroup,
            getGroupLines: this.getGroupLines,
            getColumnsConfig: this.getColumnsConfig,
            onGroupNameClick: this.onGroupNameClick,
        });

        this.cellsStorage = new TableViewModel({
            makeColumnHeaderParams: this.columnHeaderFactory.makeColumnHeaderParams,
            makeCellParams: this.cellsFactory.makeCellParams,
        });

        this.updateDefaultColumnWidths();
        this.updateTableColumns();
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        const periodsChanged = this.state.periods !== prevState.periods;

        if (periodsChanged) {
            this.state.lineIds.forEach((lineId) => {
                this.updateLineCells(lineId);
            });
        }
    }

    public async componentDidMount() {
        this.updateLineIds();
    }

    public render(): JSX.Element {
        const { displayPreloader } = this.props;
        const { lineIds } = this.state;

        return React.createElement(MediaplanBudgetTableTemplate, {
            loading: displayPreloader,
            viewModel: this.cellsStorage,
            columns: this.tableColumns,
            leftFixedColumns,
            rightFixedColumns,
            lineIds,
            columnWidths: this.defaultColumnWidths,
            tableRef: this.tableRef,
            onCellEvent: this.onCellEvent,
        });
    }

    public setColumnWidths(columnWidths: ColumnWidths) {
        if (columnWidths) {
            this.table.setColumnWidths(columnWidths);
        }
    }

    @autobind
    protected onGroupNameClick(lineId: string) {
        const groupId = parseInt(lodash.last(lineId.split('|')), 10);

        this.setState(
            {
                collapsedGroupIds: lodash.xor(this.state.collapsedGroupIds, [groupId]),
            },
            () => {
                this.updateLineIds();
            },
        );
    }

    @autobind
    protected tableRef(component: TableView) {
        this.table = component ? (component as any).getInstance() : null;
    }

    @autobind
    protected async onCellEvent(eventType: CellEvent, position: CellPosition) {
        switch (eventType) {
            case CellEvent.EditStart:
                this.updateCell(position, true);
                break;

            case CellEvent.EditEnd:
                this.updateCell(position, false);
                break;

            case CellEvent.MouseSelection:
                this.updateCell(position, false);
                break;
        }
    }

    private updateCell(position: CellPosition, edit: boolean) {
        this.cellsStorage.setCellParams(position, this.cellsFactory.makeCellParams(position, edit));
    }

    @autobind
    private updateLineCells(lineId: LineId) {
        const allColumns = [...this.tableColumns, ...leftFixedColumns, ...rightFixedColumns];

        allColumns.forEach((columnName) => {
            const cellPosition = { lineId, columnName };

            const cellEditStatus = this.table.getCellEditStatus(cellPosition);
            this.updateCell(cellPosition, cellEditStatus);
        });
    }

    private makePeriods() {
        const intervals = lodash.first(this.props.mediaplanItems).intervals;

        return intervals.map((item) => ({
            dateStart: item.dateStart,
            dateEnd: item.dateEnd,
        }));
    }

    private updateTableColumns() {
        const columnsConfigParams = this.makeColumnsConfigParams();

        this.tableColumns = MakeTableColumns(columnsConfigParams);
    }

    private updateLineIds() {
        const { mediaplanItems } = this.props;
        const { collapsedGroupIds } = this.state;

        const groupedMediaplanItems = lodash.groupBy(mediaplanItems, (item) => item.direction.id);

        const groupIds = lodash.keys(groupedMediaplanItems).sort();

        const lineIds: string[] = [
            'totalTop',
            'separator|0',
            ...lodash.flatMap(groupIds, (groupId, groupIndex) => [
                `groupName|${groupId}`,
                ...(!collapsedGroupIds.includes(parseInt(groupId, 10))
                    ? groupedMediaplanItems[groupId].map((item) => item.id)
                    : []),
                `groupTotal|${groupId}`,
                `separator|${groupIndex + 1}`,
            ]),
            'totalBottom',
        ];

        this.setState({
            lineIds,
        });
    }

    private updateDefaultColumnWidths() {
        const columnsConfigParams = this.makeColumnsConfigParams();

        const columnsConfig = MakeColumnsConfig(columnsConfigParams);

        this.defaultColumnWidths = lodash.mapValues(columnsConfig, (item) => item.defaultWidth);
    }

    @autobind
    private getColumnsConfig(): { [columnName: string]: ColumnParams } {
        const columnsConfigParams = this.makeColumnsConfigParams();

        return MakeColumnsConfig(columnsConfigParams);
    }

    private makeColumnsConfigParams(): ColumnsConfigParams {
        return {
            periods: this.state.periods,
        };
    }

    @autobind
    private getLine(lineId: LineId): MediaplanItem {
        return this.props.mediaplanItems.find((item) => item.id === lineId) || null;
    }

    @autobind
    private getGroup(lineId: LineId): { id: number; name: string } {
        let group: { id: number; name: string } = null;

        if (lodash.first(lineId.split('|')) === 'groupName' || lodash.first(lineId.split('|')) === 'groupTotal') {
            const groupId = parseInt(lodash.last(lineId.split('|')), 10);

            group = this.props.mediaplanItems.find((item) => item.direction.id === groupId).direction;
        }

        if (!lineId.includes('|') && !lineId.includes('total')) {
            const foundItem = this.props.mediaplanItems.find((item) => item.id === lineId);

            group = foundItem.direction;
        }

        return group;
    }

    @autobind
    private getGroupLines(groupId: number): MediaplanItem[] {
        const groupedMediaplanItems = lodash.groupBy(this.props.mediaplanItems, (item) => item.direction.id);

        return groupedMediaplanItems[groupId];
    }

    @autobind
    private getAllLines(): MediaplanItem[] {
        return this.props.mediaplanItems || [];
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        mediaplanItems: getMediaplan(state),
    };
}
