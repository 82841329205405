// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-SearchMenu-SearchInput-_SearchInput__root{position:relative;width:100%;height:40px;margin-bottom:20px}.src-client-modules-common-SearchMenu-SearchInput-_SearchInput__clearButton{position:absolute;top:10px;right:8px;display:flex;cursor:pointer}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/SearchMenu/SearchInput/SearchInput.scss"],"names":[],"mappings":"AAAA,qEACI,iBAAkB,CAElB,UAAW,CACX,WAAY,CACZ,kBAAmB,CACtB,4EAGG,iBAAkB,CAClB,QAAS,CACT,SAAU,CAEV,YAAa,CAEb,cAAe","sourcesContent":[".root {\n    position: relative;\n\n    width: 100%;\n    height: 40px;\n    margin-bottom: 20px;\n}\n\n.clearButton {\n    position: absolute;\n    top: 10px;\n    right: 8px;\n\n    display: flex;\n\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-SearchMenu-SearchInput-_SearchInput__root",
	"clearButton": "src-client-modules-common-SearchMenu-SearchInput-_SearchInput__clearButton"
};
export default ___CSS_LOADER_EXPORT___;
