import * as React from 'react';
import * as style from './TodayLineIndicator.scss';

interface Props {
    multiplyPosition: number;
}

export const TodayLineIndicator: React.FC<Props> = ({ multiplyPosition }) => {
    return <div className={style.root} style={{ left: `${multiplyPosition * 100}%` }} />;
};
