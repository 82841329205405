import { useMemo } from 'react';

import { UserResponseParams } from 'sber-marketing-types/backend';

import { useGetTaskParticipantsQuery } from '@api';

import { useGetChannelParticipantIds, useGetTaskChannel } from '@modules/task/hooks';

export interface UseGetChannelParticipants {
    participants?: UserResponseParams[];
    isLoading: boolean;
}

export function useGetChannelParticipants(taskId: string, channelId: number): UseGetChannelParticipants {
    const { data: taskParticipants, isLoading: isParticipantsLoading } = useGetTaskParticipantsQuery({ taskId });
    const { channel, isLoading: isChannelLoading } = useGetTaskChannel(taskId, channelId);
    const { participants: channelParticipants, isLoading: isChannelParticipantsLoading } = useGetChannelParticipantIds(
        taskId,
        channelId,
    );
    const isLoading = isParticipantsLoading || isChannelParticipantsLoading || isChannelLoading;

    const participants = useMemo(
        () =>
            (channelId
                ? taskParticipants?.filter(({ id }) => channel?.authorId === id || channelParticipants?.includes(id))
                : taskParticipants) || [],
        [channelId, channelParticipants, taskParticipants],
    );

    return { isLoading, participants };
}
