import * as lodash from 'lodash';
import * as moment from 'moment';

import { Period } from '@store/calendar/types';
import type { YearData, TimeInfo, QuarterData, MonthData } from './types';

import { store } from '@store';
import { getYears } from '@store/calendar/selectors';

interface StoreProps {
    years: number[];
}

export class TimeCalculator {
    public static readonly QUARTERS_AT_YEAR: number = 4;

    public static readonly MONTHS_AT_QUARTER: number = 3;

    public static readonly MONTHS_AT_YEAR: number = 12;

    public static readonly DAYS_AT_YEAR: number = 365;

    public static readonly DAYS_AT_LEAP_YEAR: number = 366;

    public static readonly FIRST_MONTH: number = 1;

    public static readonly FIRST_MONTH_INDEX: number = TimeCalculator.FIRST_MONTH - 1;

    public static readonly LAST_MONTH: number = 12;

    public static readonly LAST_MONTH_INDEX: number = TimeCalculator.LAST_MONTH - 1;

    public static readonly FIRST_QUARTER: number = 1;

    public static readonly LAST_QUARTER: number = 4;

    public static readonly DAYS_IN_PERIOD = {
        [Period.Month]: 31,
        [Period.Quarter]: 92,
        [Period.Year]: 366,
    };

    public static readonly MS_IS_SECOND = 1000;
    public static readonly SECONDS_IN_MINUTE = 60;
    public static readonly MINUTES_IN_HOUR = 60;
    public static readonly HOURS_IN_DAY = 24;
    public static readonly MS_IN_DAY =
        TimeCalculator.MS_IS_SECOND *
        TimeCalculator.SECONDS_IN_MINUTE *
        TimeCalculator.MINUTES_IN_HOUR *
        TimeCalculator.HOURS_IN_DAY;

    public static readonly MONTH_NAMES: string[] = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ];

    public static readonly SHORT_MONTH_NAMES: string[] = [
        'Янв',
        'Фев',
        'Март',
        'Апр',
        'Май',
        'Июн',
        'Июл',
        'Авг',
        'Сен',
        'Окт',
        'Ноя',
        'Дек',
    ];

    public static readonly MONTH_INDEXES_BY_QUARTERS: number[][] = lodash.chunk(
        lodash.range(0, TimeCalculator.MONTHS_AT_YEAR),
        TimeCalculator.MONTHS_AT_QUARTER,
    );

    public static readonly MIDDLE_INDEX = 2 / 3;

    private static instance: TimeCalculator;

    public years: YearData[] = [];

    private quarters: QuarterData[] = [];

    private months: MonthData[] = [];

    private constructor() {
        this.init();
    }

    public static getInstance(): TimeCalculator {
        if (!TimeCalculator.instance) {
            TimeCalculator.instance = new TimeCalculator();
        }
        return TimeCalculator.instance;
    }

    public getCurrentYear(): number {
        return new Date().getFullYear();
    }

    public getFirstYear(): number {
        return lodash.first(this.years).year;
    }

    public getLastYear(): number {
        return lodash.last(this.years).year;
    }

    public isYearLeap(year: number): boolean {
        return this.getYearData(year).isYearLeap;
    }

    public getDayIndexByDate(date: string): number {
        const { years } = this.getStoreProps();

        const momentDate = moment(date);

        const dayOfYear = momentDate.dayOfYear();

        const year = momentDate.year();
        const previousYears = this.years.slice(0, lodash.indexOf(years, year));
        const previousYearsDays = lodash.sumBy(previousYears, (item) => item.daysCount);

        return previousYearsDays + dayOfYear;
    }

    public daysCountByScrollIndex(scrollIndex: number, period: Period): number {
        const multiplier = this.getMultiplier(period);
        const scrollFixed = scrollIndex / multiplier;
        let daysCount: number;
        if (scrollFixed > 0 && scrollFixed < 1) {
            daysCount = Math.ceil(scrollFixed / this.getDayWidth());
        } else if (scrollFixed >= 1) {
            daysCount = this.getAllYearsDaysCount();
        } else {
            daysCount = 0;
        }

        return daysCount;
    }

    public scrollIndexByDaysCount(daysCount: number, period: Period): number {
        const multiplier = this.getMultiplier(period);
        const allYearsDaysCount = this.getAllYearsDaysCount();

        let result: number;

        if (daysCount > 0 && daysCount < allYearsDaysCount) {
            result = daysCount * this.getDayWidth() * multiplier;
        } else if (daysCount >= allYearsDaysCount) {
            result = multiplier;
        } else {
            result = 0;
        }

        return result;
    }

    public getAllYearsDaysCount(): number {
        return lodash.sumBy(this.years, ({ daysCount }) => daysCount);
    }

    public getDayWidth(fullWidth = 1): number {
        return fullWidth / this.getAllYearsDaysCount();
    }

    public getDayWidthByPeriod(period: Period): number {
        return this.getPeriodDaysCount(period) / this.getAllYearsDaysCount();
    }

    public getPeriodDaysCount(period: Period): number {
        return TimeCalculator.DAYS_IN_PERIOD[period];
    }

    public getYearViewportWidth(fullWidth = 1): number {
        const multiplier = lodash.sumBy(this.years, ({ isYearLeap }) => {
            const dividend = isYearLeap ? TimeCalculator.DAYS_AT_LEAP_YEAR : TimeCalculator.DAYS_AT_YEAR;
            return dividend / TimeCalculator.DAYS_AT_LEAP_YEAR;
        });

        return fullWidth * multiplier;
    }

    public getQuarterWidth(year: number, quarter: number, fullWidth = 1): number {
        const { daysPerQuarter } = this.getYearData(year);
        const dayWidth = this.getDayWidth(fullWidth);
        return daysPerQuarter[quarter - 1] * dayWidth;
    }

    public getMonthWidth(year: number, month: number): number {
        const { daysPerMonth } = this.getYearData(year);
        const daysCountInPeriod = this.getPeriodDaysCount(Period.Quarter);

        const oneDayInParrot = daysPerMonth[month - 1] / daysCountInPeriod;
        return oneDayInParrot * 100;
    }

    public getDaysBeforeYear(year: number): number {
        let result = 0;
        let i = 0;

        while (i < this.years.length && this.years[i].year !== year) {
            result += this.years[i].daysCount;
            i++;
        }

        return result;
    }

    public getDaysBeforeQuarter(year: number, quarter: number): number {
        let result: number = this.getDaysBeforeYear(year);
        const yearData = this.getYearData(year);

        result += lodash.sum(yearData.daysPerQuarter.slice(0, quarter - 1));

        return result;
    }

    public getDaysBeforeMonth(year: number, month: number): number {
        let result: number = this.getDaysBeforeYear(year);
        const yearData = this.getYearData(year);

        result += lodash.sum(yearData.daysPerMonth.slice(0, month - 1));

        return result;
    }

    public getTimeInfoByDays(daysCount: number, period: Period): TimeInfo {
        let result: TimeInfo;

        const allYearsDaysCount = this.getAllYearsDaysCount();

        if (daysCount <= 0) {
            result = {
                year: lodash.first(this.years).year,
                quarter: 1,
                month: 1,
            };
        } else if (daysCount >= allYearsDaysCount) {
            result = {
                year: lodash.last(this.years).year,
                quarter: TimeCalculator.QUARTERS_AT_YEAR,
                month: TimeCalculator.MONTHS_AT_YEAR,
            };
        } else {
            const viewportWidthInDays = this.getPeriodDaysCount(period);
            const viewportCenterDay = daysCount + viewportWidthInDays / 2;

            let daysSum = 0;
            let yearIndex = 0;

            while (daysSum + this.years[yearIndex].daysCount < viewportCenterDay) {
                daysSum += this.years[yearIndex].daysCount;
                yearIndex += 1;
            }

            const previousYearsDays = daysSum;
            const dayOfYear = viewportCenterDay - previousYearsDays;

            daysSum = 0;
            let quarterIndex = 0;

            while (daysSum + this.years[yearIndex].daysPerQuarter[quarterIndex] < dayOfYear) {
                daysSum += this.years[yearIndex].daysPerQuarter[quarterIndex];
                quarterIndex += 1;
            }

            daysSum = 0;
            let monthIndex = 0;

            while (daysSum + this.years[yearIndex].daysPerMonth[monthIndex] < dayOfYear) {
                daysSum += this.years[yearIndex].daysPerMonth[monthIndex];
                monthIndex += 1;
            }

            result = {
                year: this.years[yearIndex].year,
                quarter: quarterIndex + 1,
                month: monthIndex + 1,
            };
        }

        return result;
    }

    public getAllQuarters(): QuarterData[] {
        return this.quarters;
    }

    public getAllMonths(): MonthData[] {
        return this.months;
    }

    public getSeparatorsPositions(width: number, period: Period = Period.Year): number[] {
        const dayWidth = this.getDayWidth(width);
        const result: number[] = [];
        if (period === Period.Year) {
            for (let i = 1; i < this.quarters.length; i++) {
                const allDaysCount = lodash.sumBy(this.quarters.slice(0, i), ({ daysCount }) => daysCount);
                result.push(allDaysCount * dayWidth);
            }
        } else {
            for (let i = 1; i < this.months.length; i++) {
                const allDaysCount = lodash.sumBy(this.months.slice(0, i), ({ daysCount }) => daysCount);
                result.push(allDaysCount * dayWidth);
            }
        }
        return result;
    }

    public getMultiplier(period: Period = Period.Year): number {
        return this.getAllYearsDaysCount() / this.getPeriodDaysCount(period);
    }

    public getQuartersFirstDays(): number[] {
        const result: number[] = [];

        let sum = 1;

        this.years.forEach((year) => {
            year.daysPerQuarter.forEach((quarterDaysCount) => {
                sum += quarterDaysCount;

                result.push(sum);
            });
        });

        return result;
    }

    public getMonthsFirstDays(): number[] {
        const result: number[] = [];

        let sum = 1;

        this.years.forEach((year) => {
            year.daysPerMonth.forEach((monthDaysCount) => {
                sum += monthDaysCount;

                result.push(sum);
            });
        });

        return result;
    }

    private init() {
        const { years } = this.getStoreProps();

        years.forEach((year) => {
            this.addYearData(year);
        });

        this.quarters = this.generateQuarters();
        this.months = this.generateMonths();
    }

    private getYearData(year: number) {
        return this.years.find((item) => item.year === year);
    }

    private addYearData(year: number) {
        const mYear = moment([year]);

        const isYearLeap = mYear.isLeapYear();
        const daysCount = isYearLeap ? TimeCalculator.DAYS_AT_LEAP_YEAR : TimeCalculator.DAYS_AT_YEAR;

        let daysPerQuarter: number[] = [];
        const daysPerMonth: number[] = [];
        let daysPerQuarterAndMonth: number[][] = [];

        for (let i = 0; i < TimeCalculator.MONTHS_AT_YEAR; i++) {
            const mMonth = moment([year, i]);
            const daysInMonth = mMonth.daysInMonth();
            daysPerQuarter.push(daysInMonth);
            daysPerMonth.push(daysInMonth);
        }

        daysPerQuarterAndMonth = lodash.chunk(daysPerQuarter, TimeCalculator.MONTHS_AT_QUARTER);
        daysPerQuarter = daysPerQuarterAndMonth.map(lodash.sum);

        this.years.push({
            year,
            isYearLeap,
            daysCount,
            daysPerQuarter,
            daysPerMonth,
            daysPerQuarterAndMonth,
        });
    }

    private generateQuarters(): QuarterData[] {
        return lodash.flatten(
            this.years.map(({ year, daysPerQuarter }) =>
                daysPerQuarter.map((daysCount, index) => ({
                    year,
                    daysCount,
                    isYearLeap: this.isYearLeap(year),
                    quarter: index + 1,
                })),
            ),
        );
    }

    private generateMonths(): MonthData[] {
        return lodash.flatten(
            this.years.map(({ year, daysPerMonth }) =>
                daysPerMonth.map((daysCount, index) => ({
                    year,
                    daysCount,
                    isYearLeap: this.isYearLeap(year),
                    month: index + 1,
                    monthName: TimeCalculator.MONTH_NAMES[index],
                    monthShortName: TimeCalculator.SHORT_MONTH_NAMES[index],
                })),
            ),
        );
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        return {
            years: getYears(storeState),
        };
    }
}
