import { actionCreatorFactory } from 'typescript-fsa';

import { CommonUserConfig, UpdateCommonUserConfig, LoadCommonUserConfig } from './types';

const actionCreator = actionCreatorFactory('USER_CONFIG_COMMON');

const loadCommonUserConfig = actionCreator.async<CommonUserConfig, LoadCommonUserConfig, Error>(
    'LOAD_COMMON_USER_CONFIG',
);
const updateCommonUserConfig = actionCreator.async<UpdateCommonUserConfig, void, Error>('UPDATE_COMMON_USER_CONFIG');

export { loadCommonUserConfig, updateCommonUserConfig };
