import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import * as lodash from 'lodash';

import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type { Autopilot, Autopilot2, AutopilotTV, AutopilotRadio, AutopilotDOOH } from 'sber-marketing-types/backend';
import type { StoreState } from '@store';
import type { SwitchKeyActivityParams } from './types';

import * as actions from './actions/sync';
import * as asyncActions from './actions/async';
import { getActivity } from './selectors';

import {
    ActivityApi,
    AutopilotApi,
    AutopilotLegacyApi,
    AutopilotTvApi,
    AutopilotRadioApi,
    AutopilotDOOHApi,
} from '@api';

export const loadActivity = bindThunkAction<StoreState, number, Activity, Error>(
    asyncActions.loadActivity,
    async (activityId, dispatch, getState) => {
        let res;
        try {
            res = await ActivityApi.getActivity(activityId, true);
        } catch (e) {}

        return res;
    },
);

export const loadAutopilotDOOH = bindThunkAction<StoreState, number, AutopilotDOOH, Error>(
    asyncActions.loadAutopilotDOOH,
    async (activityId, dispatch, getState) => {
        const list = await AutopilotDOOHApi.getAutopilotList({ activityIds: [activityId] });

        const autopilot: AutopilotDOOH = lodash.first(list);

        return autopilot || null;
    },
);

export const loadAutopilotRadio = bindThunkAction<StoreState, number, AutopilotRadio, Error>(
    asyncActions.loadAutopilotRadio,
    async (activityId, dispatch, getState) => {
        const list = await AutopilotRadioApi.getAutopilotList({ activityIds: [activityId] });

        const autopilot: AutopilotRadio = lodash.first(list);

        return autopilot || null;
    },
);

export const loadAutopilotTv = bindThunkAction<StoreState, number, AutopilotTV, Error>(
    asyncActions.loadAutopilotTv,
    async (activityId, dispatch, getState) => {
        const list = await AutopilotTvApi.getAutopilotList({ activityIds: [activityId] });

        const autopilot: AutopilotTV = lodash.first(list);

        return autopilot || null;
    },
);

export const loadAutopilot = bindThunkAction<StoreState, number, Autopilot2, Error>(
    asyncActions.loadAutopilot,
    async (activityId, dispatch, getState) => {
        const list = await AutopilotApi.getAutopilotList({ activityIds: [activityId] });

        const autopilot: Autopilot2 = lodash.first(list);

        return autopilot || null;
    },
);

export const loadAutopilotLegacy = bindThunkAction<StoreState, number, Autopilot, Error>(
    asyncActions.loadAutopilotLegacy,
    async (activityId, dispatch, getState) => {
        const list = await AutopilotLegacyApi.getList({ activityIds: [activityId] });

        const autopilot: Autopilot = lodash.first(list);

        return autopilot || null;
    },
);

export const switchKeyActivity = bindThunkAction<StoreState, SwitchKeyActivityParams, void, Error>(
    asyncActions.switchKeyActivity,
    async (params, dispatch, getState) => {
        const { id, isKey } = params;
        const { realizationStart, realizationEnd } = getActivity(getState());

        await ActivityApi.editActivity(id, { isKey, realizationEnd, realizationStart });
    },
);

export const resetActivityPage = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.resetActivityPage,
    async (_, dispatch, getState) => {
        dispatch(actions.resetActivity());
    },
);
