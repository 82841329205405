import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconType, WithTooltip, TooltipAnchor } from 'sber-marketing-ui';

import { Popup, usePopup } from './Popup';
import { ErrorPopup } from './ErrorPopup';
import { AuthorizationPopup } from './AuthorizationPopup';

import { useStore } from './useStore';

import * as styles from './styles.scss';

export const TelegramButton: React.FC = () => {
    const { opened: openedPopup, setOpened: setOpenedPopup } = usePopup();
    const { opened: openedErrorPopup, setOpened: setOpenedErrorPopup } = usePopup();
    const { opened: openedAuthorizationPopup, setOpened: setOpenedAuthorizationPopup } = usePopup();

    const { telegram, plug: plugTelegram, unplug: unplugTelegram, update: updateTelegram } = useStore();

    const { linkToAuthorization, isUserCreatedChat, fetchError, loading } = telegram;

    React.useEffect(() => {
        if (fetchError) {
            setOpenedErrorPopup(true);
        }
    }, [fetchError]);

    React.useEffect(() => {
        if (linkToAuthorization) {
            setOpenedAuthorizationPopup(true);
        }
    }, [linkToAuthorization]);

    function onClick() {
        if (isUserCreatedChat) {
            setOpenedPopup(true);
        } else {
            plugTelegram();
        }
    }

    return (
        <>
            <div
                className={classNames(styles.root, loading ? styles.rootLoading : '')}
                onClick={loading ? null : onClick}
                {...{
                    'qa-id': 'taskTelegramButton',
                    'qa-is-selected': isUserCreatedChat ? 'true' : 'false',
                }}
            >
                <WithTooltip
                    content={`Telegram ${isUserCreatedChat ? 'подключен' : 'отключен'}`}
                    anchor={TooltipAnchor.TOP}
                >
                    {isUserCreatedChat ? (
                        <div className={classNames(styles.iconWrapper, styles.iconWrapperEnabled)}>
                            <Icon type={IconType.TELEGRAM_FILLED} svgSize={19} />
                        </div>
                    ) : (
                        <div className={classNames(styles.iconWrapper, styles.iconWrapperDisabled)}>
                            <Icon type={IconType.TELEGRAM_HOLLOW} svgSize={19} />
                        </div>
                    )}
                </WithTooltip>
            </div>

            {openedPopup && (
                <Popup
                    onClose={() => setOpenedPopup(false)}
                    onConfirm={() => {
                        unplugTelegram();
                        setOpenedPopup(false);
                    }}
                />
            )}

            {openedErrorPopup && <ErrorPopup message={fetchError.message} onClose={() => setOpenedErrorPopup(false)} />}

            {openedAuthorizationPopup && (
                <AuthorizationPopup
                    link={linkToAuthorization}
                    onClose={() => {
                        setOpenedAuthorizationPopup(false);
                        updateTelegram();
                    }}
                />
            )}
        </>
    );
};
