import { actionCreatorFactory } from 'typescript-fsa';

import { LoadingStatus } from '@store/commonTypes';

import { TagGroup, SelectedTagData, SelectedNode, SelectedNodeData } from '../types';

const actionCreator = actionCreatorFactory('TAGS_PAGE_SYNC');

export const setCommonLoadingStatus = actionCreator<LoadingStatus>('SET_COMMON_LOADING_STATUS');
export const setSelectedTagDataLoadingStatus = actionCreator<LoadingStatus>('SET_SELECTED_TAG_DATA_LOADING_STATUS');
export const setSelectedNodeDataLoadingStatus = actionCreator<LoadingStatus>('SET_SELECTED_NODE_DATA_LOADING_STATUS');

export const setYears = actionCreator<number[]>('SET_YEARS');
export const setSelectedTagData = actionCreator<SelectedTagData>('SET_SELECTED_TAG_DATA');
export const setSelectedNode = actionCreator<SelectedNode>('SET_SELECTED_NODE');
export const setSelectedNodeData = actionCreator<SelectedNodeData>('SET_SELECTED_NODE_DATA');
export const resetPageState = actionCreator('RESET_PAGE_STATE');

export const setTagFilter = actionCreator<string>('SET_TAG_FILTER');
export const setYearsFilter = actionCreator<number[]>('SET_YEARS_FILTER');
export const setTagGroupFilter = actionCreator<TagGroup[]>('SET_TAG_GROUP_FILTER');
