import * as React from 'react';

import { Icon, IconType } from 'sber-marketing-ui';
import * as styles from './styles.scss';

interface Props {
    value: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    onRemove: React.MouseEventHandler<HTMLDivElement>;
    onEnterPress: () => void;
}

type OnKeyPressHandler = React.KeyboardEventHandler<HTMLInputElement>;

export const Item: React.FC<Props> = ({ value, onChange, onRemove, onEnterPress }) => {
    const onKeyPress: OnKeyPressHandler = React.useCallback(
        ({ key }) => {
            if (key === 'Enter') {
                onEnterPress();
            }
        },
        [onEnterPress],
    );

    return (
        <div className={styles.root}>
            <input
                autoFocus
                className={styles.input}
                type="text"
                value={value}
                onChange={onChange}
                onKeyPress={onKeyPress}
            />
            <div className={styles.button} onClick={onRemove}>
                <Icon type={IconType.CROSS} />
            </div>
        </div>
    );
};
