import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { AxiosError } from 'axios';
import * as moment from 'moment';
import { isNil } from 'lodash';

import { setSavingComment } from '@store/taskPage';
import { updateIds } from '@store/fileAssets/actions';

import { StoreState } from '../..';
import { SaveCommentaryPayload, Commentary, FetchError } from '../types';
import { asyncActions, syncActions } from '../actions';
import { TaskApi } from '../../../api/TaskApi';
import { getAssetsByIds, getCommentaryById } from '../selectors';
import { FileAsset } from '../../commonTypes';
import { runUploadOne } from '../../fileAssets';

export const saveCommentary = bindThunkAction<StoreState, SaveCommentaryPayload, Commentary, FetchError>(
    asyncActions.saveCommentary,
    async ({ id: commentId, content }: SaveCommentaryPayload, dispatch, getState): Promise<Commentary> => {
        const {
            taskPage: { id: taskId },
        } = getState();
        const { assetsIds }: Commentary = getCommentaryById(getState(), commentId);
        const assets: FileAsset[] = getAssetsByIds(getState(), assetsIds);
        const assetsToUpload: FileAsset[] = [];
        const namesToRemove: string[] = [];
        for (let i = 0; i < assets.length; i++) {
            if (!isNil(assets[i].file)) {
                assetsToUpload.push(assets[i]);
                namesToRemove.push(assets[i].name);
            }
        }
        let result: Commentary | null = null;

        dispatch(syncActions.setCommentaryIsLoading({ commentaryId: commentId, isLoading: true }));
        dispatch(setSavingComment(commentId));

        try {
            await Promise.all(
                assetsToUpload.map((asset) =>
                    runUploadOne({
                        dispatch,
                        params: { taskId, commentId },
                        fileOrAssetOrId: asset,
                    }),
                ),
            );

            for (const nameToRemove of namesToRemove) {
                dispatch(syncActions.removeNewCommentaryAsset(nameToRemove));
            }

            const { id, text, files, authorId, createTime } = await TaskApi.updateComment(taskId, commentId, {
                text: content,
            });
            await TaskApi.deleteDraftComment(taskId, id);
            window.clearTimeout(window[`commentTimer${id}`]);

            const assetsIdMap = files.reduce((rest, file, i) => ({ ...rest, [assetsIds[i]]: file.id }), {});

            result = {
                id: String(id),
                authorId,
                isLoading: false,
                content: text,
                createdAt: moment(createTime).unix() * 1000,
                assetsIds: files.map(({ id }) => id),
                reactions: [],
            };
            dispatch(updateIds(assetsIdMap));
        } catch (error) {
            dispatch(syncActions.setCommentaryIsLoading({ commentaryId: commentId, isLoading: false }));

            const { response, message } = <AxiosError>error;
            if (response) {
                throw new FetchError(response.status, response.statusText, message);
            } else {
                throw new FetchError(0, 'Unknown', message);
            }
        }

        dispatch(setSavingComment(null));

        return result!;
    },
);
