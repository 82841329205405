import * as React from 'react';
import classNames from 'classnames';

import * as style from './LineHeader.scss';

import { Icon, IconType, WithTooltip, TooltipAnchor, BodyPortal } from 'sber-marketing-ui';
import { ContextMenuWrapper } from './ContextMenuWrapper';

interface Props {
    title: string;
    displayMenuButton: boolean;
    lineIsArchived: boolean;
    customStyle?: any;
    onLineArchive: () => void;
    onLineRestore: () => void;
}

export const LineHeader = ({
    title,
    displayMenuButton,
    lineIsArchived,
    customStyle,
    onLineArchive,
    onLineRestore,
}: Props): JSX.Element => {
    const [isOpened, setOpenedStatus] = React.useState(false);

    const onOpenerButtonClick = React.useCallback(() => {
        setOpenedStatus(!isOpened);
    }, [isOpened]);

    const onMaskClick = React.useCallback(() => {
        setOpenedStatus(false);
    }, []);

    const onArchiveButtonClick = React.useCallback(() => {
        onLineArchive();
        setOpenedStatus(false);
    }, []);

    const onRestoreButtonClick = React.useCallback(() => {
        onLineRestore();
        setOpenedStatus(false);
    }, []);

    return (
        <div className={classNames(style.root, isOpened && style.opened)} style={{ ...customStyle }}>
            <div className={style.title}>{title}</div>

            {displayMenuButton && (
                <div className={style.openerButton} onClick={onOpenerButtonClick}>
                    <Icon type={IconType.KEBAB} svgSize={9} />
                </div>
            )}

            {false && (
                <WithTooltip
                    content={'Имеются пустые ячейки в текущей строке заявки на креатив'}
                    anchor={TooltipAnchor.RIGHT}
                >
                    <div className={style.warningIcon}>
                        <Icon type={IconType.EXPIRED_TASK} svgSize={13} />
                    </div>
                </WithTooltip>
            )}

            {isOpened && (
                <>
                    <ContextMenuWrapper>
                        <div className={style.content}>
                            <div className={style.menuTitle}>Действия со строкой</div>

                            <div className={style.buttons}>
                                {!lineIsArchived && (
                                    <div className={style.menuButton} onClick={onArchiveButtonClick}>
                                        <div className={classNames(style.menuButtonIcon, style.binIcon)}>
                                            <Icon type={IconType.BIN} svgSize={24} />
                                        </div>
                                        Удалить строку
                                    </div>
                                )}

                                {lineIsArchived && (
                                    <div className={style.menuButton} onClick={onRestoreButtonClick}>
                                        <div className={classNames(style.menuButtonIcon, style.restoreIcon)}>
                                            <Icon type={IconType.RESTORE} svgSize={24} />
                                        </div>
                                        Восстановить строку
                                    </div>
                                )}
                            </div>
                        </div>
                    </ContextMenuWrapper>

                    <BodyPortal>
                        <div className={style.mask} onClick={onMaskClick} />
                    </BodyPortal>
                </>
            )}
        </div>
    );
};
