import * as React from 'react';
import autobind from 'autobind-decorator';
import onClickOutside from 'react-onclickoutside';

import { CommentInput } from './CommentInput';

interface Props {
    createComment: (comment: string) => void;
    unsetInputFocused: (comment: string) => void;
    onCancel: () => void;
}

interface State {
    text: string;
    isValid: boolean;
    isFocused: boolean;
    isWrapperFocused: boolean;
}

@(onClickOutside as any)
export class CommentInputContainer extends React.PureComponent<Props, State> {
    private ref: React.RefObject<HTMLDivElement> = React.createRef();

    public constructor(props: Props) {
        super(props);

        this.state = this.getDefaultState();
    }

    public componentDidMount() {
        this.ref.current.addEventListener('animationend', this.handlerTransitionend, true);
    }

    public componentWillUnmount() {
        this.ref.current.removeEventListener('animationend', this.handlerTransitionend);
    }

    public render(): JSX.Element {
        const { text, isWrapperFocused, isValid } = this.state;

        return (
            <CommentInput
                text={text}
                isFocused={isWrapperFocused}
                isValid={isValid}
                refObject={this.ref}
                onCancelButtonClick={this.onCancelButtonClick}
                onWrapperMouseOver={this.setInputWrapperFocused}
                onWrapperMouseOut={this.unsetInputWrapperFocused}
                onInputChange={this.onInputChange}
                onInputFocus={this.setInputFocused}
                onInputBlur={this.unsetInputFocused}
                onSendIconClick={this.onSendIconClick}
                onSendIconMouseOver={this.setInputWrapperFocused}
                onSendIconMouseOut={this.unsetInputWrapperFocused}
            />
        );
    }

    @autobind
    public handlerTransitionend(): void {
        this.setState({ isValid: true });
    }

    @autobind
    public handleClickOutside() {
        const { unsetInputFocused } = this.props;
        const { text } = this.state;

        this.setState(
            {
                isFocused: false,
                isWrapperFocused: false,
            },
            () => unsetInputFocused(text),
        );
    }

    @autobind
    private onCancelButtonClick(): void {
        this.props.onCancel();
    }

    @autobind
    private onInputChange(text: string): void {
        this.setState({
            text,
        });
    }

    @autobind
    private setInputFocused(): void {
        this.setState({
            isFocused: true,
        });
    }

    @autobind
    private unsetInputFocused(): void {
        this.setState({
            isFocused: false,
            isWrapperFocused: false,
        });
    }

    @autobind
    private setInputWrapperFocused(): void {
        this.setState({
            isWrapperFocused: true,
        });
    }

    @autobind
    private unsetInputWrapperFocused(): void {
        if (!this.state.isFocused) {
            this.setState({
                isWrapperFocused: false,
            });
        }
    }

    @autobind
    private async onSendIconClick(): Promise<void> {
        const { text } = this.state;
        const comment = text.trim();

        if (comment) {
            this.setState({ text: '' });

            await this.props.createComment(comment);
        } else {
            this.setState({ isValid: false });
        }
    }

    private getDefaultState(): State {
        return {
            text: '',
            isValid: true,
            isFocused: false,
            isWrapperFocused: false,
        };
    }
}
