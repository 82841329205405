import { actionCreatorFactory } from 'typescript-fsa';

import { ShowLineModalParams, LineModalTabs } from '../types';

const actionCreator = actionCreatorFactory('BUDGET_EXECUTION_LINE_MODAL_SYNC');

const showLineModal = actionCreator<ShowLineModalParams>('SHOW_LINE_MODAL');
const hideLineModal = actionCreator('HIDE_LINE_MODAL');
const clearLineModal = actionCreator('CLEAR_LINE_MODAL');
const setSelectedTab = actionCreator<LineModalTabs>('SET_SELECTED_TAB');

export { showLineModal, hideLineModal, clearLineModal, setSelectedTab };
