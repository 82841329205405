import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import { MONTH_BY_COLUMN_NAMES } from '@store/budgetExecution';
import {
    InternalTransferDirection,
    BudgetTransferDesciptor,
    getBudgetTransferMenuState,
    getTransitionBudgetItem,
    setTransferAmount,
    getTotalTransferAmountForSource,
    getTransferAmount,
} from '@store/budgetExecution/budgetTransferMenu';

import { Utils } from '@common/Utils';
import { TransferAmountInput, TransferAmountInputThemes } from '../../../TransferAmountInput';
import {
    CellDescription,
    CellDescriptionThemes,
    CellDescriptionTemplates,
    CellDescriptionProps,
} from '../../../CellDescription';

import * as styles from './MonthInput.scss';

interface Props {
    descriptor: BudgetTransferDesciptor;
}

function useStore(props: Props) {
    const dispatch = useDispatch();

    const { from, to } = props.descriptor;

    const internalTransferDirection = useSelector(
        (state: StoreState) => getBudgetTransferMenuState(state).controls.internalTransferDirection,
    );

    const maxAmount = useSelector((state: StoreState) => {
        switch (internalTransferDirection) {
            case InternalTransferDirection.OneToMany:
                return Utils.withErrorHandler<number>(() => {
                    const planAmount = getTransitionBudgetItem(state, from.lineId).plannedFunds[
                        MONTH_BY_COLUMN_NAMES[from.columnName]
                    ];
                    const alreadyEnteredValue =
                        getTotalTransferAmountForSource(state, from.lineId) -
                        getTransferAmount(getBudgetTransferMenuState(state), from, to);

                    return planAmount - alreadyEnteredValue;
                });
            case InternalTransferDirection.ManyToOne:
                return Utils.withErrorHandler<number>(
                    () =>
                        getTransitionBudgetItem(state, from.lineId).plannedFunds[
                            MONTH_BY_COLUMN_NAMES[from.columnName]
                        ],
                );
            default:
                return null;
        }
    });
    const maxButtonAmount = useSelector((state: StoreState) => {
        switch (internalTransferDirection) {
            case InternalTransferDirection.OneToMany:
                const destPlanReserveDiff =
                    Utils.withErrorHandler<number>(() => {
                        const budgetItem = getTransitionBudgetItem(state, to.lineId);
                        const month = MONTH_BY_COLUMN_NAMES[to.columnName];

                        return budgetItem.plannedFunds[month] - budgetItem.reservedFunds[month];
                    }) || 0;

                return destPlanReserveDiff >= 0 ? 0 : Math.min(-destPlanReserveDiff, maxAmount);
            case InternalTransferDirection.ManyToOne:
                const sourcePlanReserveDiff =
                    Utils.withErrorHandler<number>(() => {
                        const budgetItem = getTransitionBudgetItem(state, from.lineId);
                        const month = MONTH_BY_COLUMN_NAMES[from.columnName];

                        return budgetItem.plannedFunds[month] - budgetItem.reservedFunds[month];
                    }) || 0;

                return sourcePlanReserveDiff <= 0 ? 0 : sourcePlanReserveDiff;
            default:
                return 0;
        }
    });
    const maxButtonText = useSelector((state: StoreState) => {
        const { internalTransferDirection } = getBudgetTransferMenuState(state).controls;

        switch (internalTransferDirection) {
            case InternalTransferDirection.OneToMany:
                const destPlanReserveDiff = Math.abs(
                    Utils.withErrorHandler<number>(() => {
                        const budgetItem = getTransitionBudgetItem(state, to.lineId);
                        const month = MONTH_BY_COLUMN_NAMES[to.columnName];

                        return budgetItem.plannedFunds[month] - budgetItem.reservedFunds[month];
                    }) || 0,
                );

                return maxButtonAmount >= destPlanReserveDiff ? 'вся нужная сумма' : 'вся доступная сумма';
            case InternalTransferDirection.ManyToOne:
                return 'вся доступная сумма';
            default:
                return '';
        }
    });

    function setAmount(amount: number) {
        dispatch(
            setTransferAmount({
                ...props.descriptor,
                amount,
            }),
        );
    }

    return {
        internalTransferDirection,
        maxAmount,
        maxButtonAmount,
        maxButtonText,
        setAmount,
    };
}

export function MonthInput(props: Props): JSX.Element {
    const { from, to, amount } = props.descriptor;
    const { internalTransferDirection, maxAmount, maxButtonAmount, maxButtonText, setAmount } = useStore(props);

    const transferAmountInputProps = {
        addAllAmountButton: true,
        title: 'Введите сумму переноса',
        value: amount,
        maxValue: maxAmount,
        maxButtonValue: maxButtonAmount,
        maxButtonText,
        onChange: setAmount,
    };

    const cellDescriptionProps: CellDescriptionProps = {
        qaId: null,
        cell: null,
        template: CellDescriptionTemplates.SumEnteringShort,
    };
    switch (internalTransferDirection) {
        case InternalTransferDirection.OneToMany:
            cellDescriptionProps.cell = to;
            break;
        case InternalTransferDirection.ManyToOne:
            cellDescriptionProps.cell = from;
            break;
        default:
            cellDescriptionProps.cell = null;
            break;
    }

    let content: JSX.Element;
    switch (internalTransferDirection) {
        case InternalTransferDirection.OneToMany:
            content = (
                <React.Fragment>
                    <div className={styles.column}>
                        <TransferAmountInput
                            qaIndex="oneToManySumInput"
                            theme={TransferAmountInputThemes.WithoutRightBorder}
                            maxButtonFullText="достаточно"
                            {...transferAmountInputProps}
                        />
                    </div>

                    <div className={styles.column}>
                        <CellDescription
                            qaId="oneToManyCellDescription"
                            theme={CellDescriptionThemes.MonthInputDescriptionRight}
                            {...cellDescriptionProps}
                        />
                    </div>
                </React.Fragment>
            );
            break;
        case InternalTransferDirection.ManyToOne:
            content = (
                <React.Fragment>
                    <div className={styles.column}>
                        <CellDescription
                            qaId="manyToOneCellDescription"
                            theme={CellDescriptionThemes.MonthInputDescriptionLeft}
                            {...cellDescriptionProps}
                        />
                    </div>

                    <div className={styles.column}>
                        <TransferAmountInput
                            qaIndex="manyToOneSumInput"
                            theme={TransferAmountInputThemes.WithoutLeftBorder}
                            {...transferAmountInputProps}
                        />
                    </div>
                </React.Fragment>
            );
            break;
        default:
            content = null;
            break;
    }

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'internalTransitionSumEnteringCurrentRowSumInputsMonthInput',
                'qa-month': cellDescriptionProps.cell?.columnName,
            }}
        >
            {content}
        </div>
    );
}
