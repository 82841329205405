import * as React from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Textarea_redesign as Textarea } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getTaskEditorState, setDescription } from '@store/taskEditor2';

import * as styles from './DescriptionInput.scss';

function useInteractivity() {
    const dispatch = useDispatch();
    const [isTextareaFocused, setIsTextareaFocused] = React.useState(false);

    const { description, disabled } = useSelector((state: StoreState) => {
        const { values, permissions } = getTaskEditorState(state);

        return {
            description: values.description,
            disabled: !permissions.canChangeRest,
        };
    });

    function saveDescription(title: string) {
        dispatch(setDescription(title));
    }

    return {
        description,
        saveDescription,
        isTextareaFocused,
        setIsTextareaFocused,
        disabled,
    };
}

export function DescriptionInput(): JSX.Element {
    const { description, saveDescription, isTextareaFocused, setIsTextareaFocused, disabled } = useInteractivity();

    return (
        <div className={classnames(styles.root, isTextareaFocused && styles.rootFocused)}>
            <Textarea
                qaId="taskEditorDescriptionInput"
                maxRows={4}
                placeholder="Описание задачи"
                value={description}
                disabled={disabled}
                onChange={saveDescription}
                onFocus={() => setIsTextareaFocused(true)}
                onBlur={() => setIsTextareaFocused(false)}
            />
        </div>
    );
}
