import * as React from 'react';

import { CommentWithTitle } from '../CommentWithTitle';
import * as style from './RejectComment.scss';

interface Props {
    text: string;
}

export const RejectComment: React.FC<Props> = ({ text }) => (
    <div className={style.root}>
        <CommentWithTitle title={'Причина отклонения'} text={text} />
    </div>
);
