import type { SidesSizes } from '../../types';

export type { SidesSizes };

import type { Position } from '@store/calendar/types';
import { LineType } from '@store/calendar/types';

export const enum Color {
    RED = '#e63900',
    GREEN = '#19bb4f',
    GRAY = '#f1f1f5',
    BLACK = '#000000',
}

export interface ConstructorParams {
    canvas: HTMLCanvasElement;
    params: Params;
    startPosition: Position;
    sidesSizes: SidesSizes;
    filledExpiredTaskIcon: HTMLImageElement;
}

export interface Params {
    items: Item[];
}

export type Item = ActivityItem | TaskItem | GroupTitleItem;

export interface GroupTitleItem extends BaseItem {}

export interface ActivityItem extends BaseItem {
    hasExpiredStages: boolean;
}

export interface TaskItem extends BaseItem {
    deadlineExpired: boolean;
    taskIsClosed: boolean;
}

export interface BaseItem {
    type: LineType;
    name: string;
}
