import { ComponentType, PureComponent, createElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { omit } from 'lodash';
import { parse as parseQuery } from 'query-string';

import { TaskPathDataRaw, TaskPathData, TaskQueryParams, PropsWithTaskData } from './types';

/** High-Order Component (HOC) which maps base task data from URL */
export const withTaskData = <P extends {} = {}>(Component: ComponentType<PropsWithTaskData<P>>): ComponentType<P> => {
    const Wrapper = class extends PureComponent<RouteComponentProps<TaskPathDataRaw> & P> {
        public render() {
            return createElement(
                Component,
                {
                    ...this.pathData,
                    ...this.query,
                    ...(<any>this.originProps),
                    gotoChannel: this.gotoChannel,
                },
                this.props.children,
            );
        }

        private get originProps() {
            return omit(this.props, ['history', 'location', 'match', 'staticContext', 'channelsIds', 'children']);
        }

        private get pathData(): TaskPathData {
            const {
                match: { params },
            } = this.props;
            return {
                taskId: params.taskId,
                activityId: Number(params.activityId),
            };
        }

        private get query(): TaskQueryParams {
            const {
                location: { search },
            } = this.props;
            const parsed = parseQuery(search);
            let channelId: number | null = Number(parsed.channelId);
            if (isNaN(channelId)) {
                channelId = null;
            }
            return {
                channelId,
                from: <string>parsed.from,
            };
        }

        gotoChannel = (channelId: number) => {
            const { activityId, taskId } = this.pathData;

            this.props.history.push(
                `/activity/${activityId}/task/${taskId}${channelId ? `?channelId=${channelId}` : ''}`,
            );
        };
    };

    return withRouter(Wrapper as any) as any;
};
