import * as React from 'react';

import { useGetTaskQuery, useSubscribeTaskMutation, useUnsubscribeTaskMutation } from '@api';

import { Toggle, ToggleProps } from '@common/components';

export interface TaskSubscriptionToggleProps extends Omit<ToggleProps, 'checked'> {
    taskId: string;
}

export const TaskSubscriptionToggle: React.FC<TaskSubscriptionToggleProps> = ({
    children,
    taskId,
    onToggle,
    ...props
}) => {
    const { isFetching, data } = useGetTaskQuery({ id: taskId });
    const [subscribe, { isLoading: subscribing }] = useSubscribeTaskMutation();
    const [unsubscribe, { isLoading: unsubscribing }] = useUnsubscribeTaskMutation();
    const isLoading = isFetching || subscribing || unsubscribing;

    const checked = data ? data.task.subscription !== isLoading : false;

    const handleEmailToggle = (checked: boolean) => {
        if (checked) {
            subscribe({ id: taskId });
        } else {
            unsubscribe({ id: taskId });
        }

        onToggle?.(checked);
    };

    return (
        <Toggle
            data-qa-id="TaskSubscriptionToggle"
            {...props}
            onToggle={handleEmailToggle}
            checked={checked}
            loading={isLoading}
        >
            {children}
        </Toggle>
    );
};
