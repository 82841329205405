import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconType, Input_redesign as Input, InputTheme_redesign as InputTheme } from 'sber-marketing-ui';

import * as styles from './InputWithSendButton.scss';

interface Props {
    value: string;
    placeholder: string;
    isFocused: boolean;
    sendButtonDisabled?: boolean;
    onContainerMouseOver: () => void;
    onContainerMouseOut: () => void;
    onClearButtonClick: () => void;
    onSendButtonClick: () => void;
    onInputFocus: () => void;
    onClearButtonMouseOver: () => void;
    onSendButtonMouseOver: () => void;
    onInputChange?: (value: string) => void;
    onInputBlur: () => void;
    onClearButtonMouseOut: () => void;
    onSendButtonMouseOut: () => void;
}

export function InputWithSendButton({
    value,
    placeholder,
    isFocused,
    sendButtonDisabled,
    onContainerMouseOver,
    onContainerMouseOut,
    onClearButtonClick,
    onSendButtonClick,
    onInputFocus,
    onClearButtonMouseOut,
    onSendButtonMouseOut,
    onInputChange,
    onInputBlur,
    onClearButtonMouseOver,
    onSendButtonMouseOver,
}: Props): JSX.Element {
    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'InputWithSendButton',
            }}
        >
            <div
                className={classNames(styles.borderWrapper, isFocused && styles.borderWrapperFocused)}
                onMouseOver={onContainerMouseOver}
                onMouseOut={onContainerMouseOut}
            >
                <Icon
                    type={IconType.REJECT_ICON_ROUND}
                    svgSize={16}
                    className={classNames(styles.button, styles.clearButton)}
                    onClick={onClearButtonClick}
                    onMouseOver={onClearButtonMouseOver}
                    onMouseOut={onClearButtonMouseOut}
                />

                <Input
                    qaId="inputWithSendButtonInput"
                    value={value}
                    placeholder={placeholder}
                    theme={InputTheme.Borderless}
                    onInputChange={onInputChange}
                    onFocus={onInputFocus}
                    onBlur={onInputBlur}
                />
            </div>

            <div
                className={classNames(
                    styles.button,
                    styles.sendButton,
                    (!value || sendButtonDisabled) && styles.sendButtonDisabled,
                )}
                onClick={onSendButtonClick}
                onMouseOver={onSendButtonMouseOver}
                onMouseOut={onSendButtonMouseOut}
                {...{
                    'qa-id': 'inputWithSendButtonSaveButton',
                }}
            >
                Сохранить
            </div>
        </div>
    );
}
