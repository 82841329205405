import * as lodash from 'lodash';

import type { OrganizationGroups } from '@store/calendar/types';

import { store } from '@store';
import { getLoginUser } from '@store/user/selector';
import { getFilters } from '@store/calendar/selectors';

const HEADER_HEIGHT = 48;
const CHART_PADDING_TOP = 170;
const ORGANIZATION_NAME_HEIGHT = 40;
const GROUP_NAME_HEIGHT = 44;
const LINE_HEIGHT = 32;
const GROUP_PADDING_BOTTOM = 6;
const GROUP_MARGIN_BOTTOM = 8;

interface StoreProps {
    displayOrganizationNames: boolean;
}

export class Virtualizer {
    private static instance: Virtualizer;

    public static getInstance(): Virtualizer {
        if (!Virtualizer.instance) {
            Virtualizer.instance = new Virtualizer();
        }
        return Virtualizer.instance;
    }

    public getVisibleLineIds(organizationGroups: OrganizationGroups[]): React.ReactText[] {
        const { displayOrganizationNames } = this.getStoreProps();

        const windowHeight = window.innerHeight;
        const viewportHeight = windowHeight - HEADER_HEIGHT;
        const pageScroll = this.getPageScroll();

        const viewportTop = pageScroll;
        const viewportBottom = pageScroll + viewportHeight;

        const visibleLineIds: React.ReactText[] = [];

        let heightSum = CHART_PADDING_TOP;

        if (displayOrganizationNames) {
            heightSum += ORGANIZATION_NAME_HEIGHT;
        }

        let organizationIndex = 0;
        let groupIndex = 0;
        let lineIndex = 0;

        let allVisibleLinesAreFound = false;

        while (!allVisibleLinesAreFound && organizationIndex < organizationGroups.length) {
            const group = organizationGroups[organizationIndex].groups[groupIndex];

            const allGroupsAreChecked = groupIndex >= organizationGroups[organizationIndex].groups.length;

            if (allGroupsAreChecked) {
                organizationIndex += 1;
                groupIndex = 0;
                lineIndex = 0;

                if (displayOrganizationNames) {
                    heightSum += ORGANIZATION_NAME_HEIGHT;
                }
            } else {
                const allGroupLinesAreChecked = lineIndex >= group.lines.length;

                if (allGroupLinesAreChecked) {
                    groupIndex += 1;
                    lineIndex = 0;

                    heightSum += GROUP_MARGIN_BOTTOM;

                    if (group.lines.length > 1) {
                        heightSum += GROUP_PADDING_BOTTOM;
                    }
                } else {
                    const lineHeight = lineIndex == 0 ? GROUP_NAME_HEIGHT : LINE_HEIGHT;

                    const lineIsVisible = heightSum + lineHeight >= viewportTop;

                    if (lineIsVisible) {
                        const line = group.lines[lineIndex];

                        visibleLineIds.push(line.id);
                    }

                    lineIndex += 1;
                    heightSum += lineHeight;
                }
            }

            allVisibleLinesAreFound = heightSum > viewportBottom;
        }

        return visibleLineIds;
    }

    private getPageScroll() {
        const pageScrollbar = document.getElementById('pageContent') as HTMLDivElement;

        return pageScrollbar ? pageScrollbar.scrollTop : 0;
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        const { organizationIds } = getFilters(storeState);

        const user = getLoginUser(storeState);
        const userOrganizationId = user.attributes.organizationId;

        return {
            displayOrganizationNames:
                organizationIds.length > 1 || lodash.first(organizationIds) !== userOrganizationId,
        };
    }
}
