import * as React from 'react';
import * as lodash from 'lodash';

import * as style from './TableView.scss';

import type {
    CellParams,
    CellPosition,
    ColumnHeaderParams,
    ColumnName,
    ColumnWidths,
    LineHeights,
    LineId,
} from '../types';

import { CustomScrollbar_new as CustomScrollbar, ScrollbarComponent } from 'sber-marketing-ui';

interface Props {
    columns: ColumnName[];
    lines: LineId[];
    visibleColumnsIndexes: number[];
    visibleLinesIndexes: number[];
    columnWidths: ColumnWidths;
    lineHeights: LineHeights;
    sumWidth: number;
    sumHeight: number;
    tableBodyMaxHeight: number;
    rootRef: React.RefObject<HTMLDivElement>;
    tableHeaderRef: (component: ScrollbarComponent) => void;
    tableBodyRef: (component: ScrollbarComponent) => void;
    getColumnHeader: (columnName: string) => ColumnHeaderParams;
    getCell: (position: CellPosition) => CellParams;
    onBodyScroll: () => void;
}

export const TableViewTemplate = ({
    columns,
    lines,
    visibleColumnsIndexes,
    visibleLinesIndexes,
    columnWidths,
    lineHeights,
    sumWidth,
    sumHeight,
    tableBodyMaxHeight,
    rootRef,
    tableHeaderRef,
    tableBodyRef,
    getColumnHeader,
    getCell,
    onBodyScroll,
}: Props): JSX.Element => {
    return (
        <div className={style.root} ref={rootRef}>
            <div className={style.tableHeader}>
                <CustomScrollbar scrollbarRef={tableHeaderRef} hideScrollX hideScrollY freezeScrollY>
                    <div className={style.headScroller} style={{ width: sumWidth }}>
                        {renderColumnHeaders()}
                    </div>
                </CustomScrollbar>
            </div>

            <div className={style.tableBody}>
                <CustomScrollbar scrollbarRef={tableBodyRef} onScroll={onBodyScroll} maxHeight={tableBodyMaxHeight}>
                    <div className={style.bodyScroller} style={{ width: sumWidth, height: sumHeight }}>
                        {renderCells()}
                    </div>
                </CustomScrollbar>
            </div>
        </div>
    );

    function renderColumnHeaders(): JSX.Element[] {
        const columnHeaders: JSX.Element[] = [];
        let sum = 0;

        if (!lodash.isEmpty(visibleColumnsIndexes)) {
            columns.forEach((columnName, columnIndex) => {
                if (visibleColumnsIndexes.includes(columnIndex)) {
                    columnHeaders.push(
                        <div
                            key={columnName}
                            className={style.columnHeader}
                            style={{
                                left: sum,
                                width: columnWidths[columnName],
                                ...getColumnHeader(columnName).style,
                            }}
                        >
                            {getColumnHeader(columnName).title}
                        </div>,
                    );
                }

                sum += columnWidths[columnName] + 1;
            });
        }

        return columnHeaders;
    }

    function renderCells(): JSX.Element[] {
        const cells: JSX.Element[] = [];
        let heightSum = 0;
        let widthSum = 0;

        if (!lodash.isEmpty(visibleLinesIndexes) && !lodash.isEmpty(visibleColumnsIndexes)) {
            lines.forEach((lineId, lineIndex) => {
                if (visibleLinesIndexes.includes(lineIndex)) {
                    widthSum = 0;

                    columns.forEach((columnName, columnIndex) => {
                        if (visibleColumnsIndexes.includes(columnIndex)) {
                            cells.push(
                                <div
                                    className={style.cell}
                                    key={`${columnName} ${lineId}`}
                                    style={{
                                        top: heightSum,
                                        left: widthSum,
                                        width: columnWidths[columnName],
                                        height: lineHeights[lineId],
                                        ...getCell({ columnName, lineId }).style,
                                    }}
                                >
                                    {getCell({ columnName, lineId }).title}
                                </div>,
                            );
                        }

                        widthSum += columnWidths[columnName] + 1;
                    });
                }

                heightSum += lineHeights[lineId] + 1;
            });
        }

        return cells;
    }
};
