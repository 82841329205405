// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-_CorrectionsList__root{max-width:985px}.src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-_CorrectionsList__card{width:100%}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetCorrections/CorrectionsList/CorrectionsList.scss"],"names":[],"mappings":"AAAA,+FACI,eAAgB,CACnB,+FAGG,UAAW","sourcesContent":[".root {\n    max-width: 985px;\n}\n\n.card {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-_CorrectionsList__root",
	"card": "src-client-modules-budget-BudgetPage-BudgetCorrections-CorrectionsList-_CorrectionsList__card"
};
export default ___CSS_LOADER_EXPORT___;
