import { ColumnNames, ColumnParams } from '../../types';
import { ValidationError } from './ValidationError';

export class UnvalidDictionaryValueError extends ValidationError {
    private fields: ColumnNames[];

    public constructor(fields: ColumnNames[], parentField: ColumnNames) {
        super();

        this.fields = fields;
    }

    public toString(): string {
        const fields = this.fields.map((field) => ColumnParams[field].header).join(', ');

        return `Выбранные словари ${fields} не соответствуют заданным в системе связям и будут удалены`;
    }
}
