// import { bindActionCreators } from 'redux';
// import * as lodash from 'lodash';
// import * as moment from 'moment';
// import autobind from 'autobind-decorator';

import type {
    AutopilotRadio,
    AutopilotRadioBrief,
    UpdateAutopilotRadioMediaplanParams,
} from 'sber-marketing-types/backend';
import { AutopilotStatus } from 'sber-marketing-types/backend';
import type { BriefStageForm } from '@store/autopilotRadio/types';

import { store } from '@store';
import { getAutopilot, getBriefStageForm, getSelectedBudgetItemIds } from '@store/autopilotRadio/selectors';
import { AutopilotRadioApi } from '@api';

interface StoreProps {
    autopilot: AutopilotRadio;
    briefForm: BriefStageForm;
    selectedBudgetItemIds: string[];
}

export class Saver {
    private static instance: Saver;

    // private dispatch = bindActionCreators({

    // }, store.dispatch);

    public static getInstance(): Saver {
        if (!Saver.instance) {
            Saver.instance = new Saver();
        }
        return Saver.instance;
    }

    public init(): void {}

    public async setAutopilotStatus(status: boolean) {
        const { autopilot } = this.getStoreProps();

        if (autopilot) {
            await AutopilotRadioApi.updateAutopilot(autopilot.id, {
                status: (status ? 'on' : 'off') as AutopilotStatus,
            });
        }
    }

    public async saveBriefForm() {
        const { autopilot } = this.getStoreProps();

        if (autopilot) {
            const updateParams = this.makeAutopilotBriefParams();

            await AutopilotRadioApi.updateBrief(autopilot.id, updateParams);
        }
    }

    public async saveSelectedBudgetItems() {
        const { autopilot, selectedBudgetItemIds } = this.getStoreProps();

        if (autopilot) {
            await AutopilotRadioApi.updateBudget(autopilot.id, { budgetIds: selectedBudgetItemIds });
        }
    }

    public async createMediaplan() {
        const { autopilot } = this.getStoreProps();

        if (autopilot) {
            await AutopilotRadioApi.createMediaplan(autopilot.id);
        }
    }

    public async updateMediaplan(params: UpdateAutopilotRadioMediaplanParams) {
        const { autopilot } = this.getStoreProps();

        if (autopilot) {
            await AutopilotRadioApi.updateMediaplan(autopilot.id, params);
        }
    }

    private makeAutopilotBriefParams(): AutopilotRadioBrief {
        const { briefForm } = this.getStoreProps();

        return {
            block: briefForm.blockId,
            product: briefForm.productId,
            budget: parseInt(briefForm.budget, 10),
            dateStart: briefForm.dateStart,
            dateEnd: briefForm.dateEnd,
            targetGroup: briefForm.targetGroup,
            intervals: briefForm.intervals,
            selectedStations: briefForm.selectedStations,
            strategy: briefForm.strategy,
        };
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        return {
            autopilot: getAutopilot(storeState),
            briefForm: getBriefStageForm(storeState),
            selectedBudgetItemIds: getSelectedBudgetItemIds(storeState),
        };
    }
}
