import * as React from 'react';
import autobind from 'autobind-decorator';

import { TogglePosition } from 'sber-marketing-ui';
import { Switch } from './Switch';

interface Props {
    position: TogglePosition;
    onSwitch: (position: TogglePosition) => void;
}

export class SwitchContainer extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <Switch
                togglePosition={this.props.position}
                onToggleClick={this.onToggleClick}
                onTitleClick={this.onTitleClick}
            />
        );
    }

    @autobind
    protected onToggleClick() {
        const newPosition = this.props.position == TogglePosition.LEFT ? TogglePosition.RIGHT : TogglePosition.LEFT;

        this.props.onSwitch(newPosition);
    }

    @autobind
    protected onTitleClick(position: TogglePosition) {
        if (position !== this.props.position) {
            this.props.onSwitch(position);
        }
    }
}
