import { includes } from 'lodash';
import { CreativeRequestDomain, Param } from './types';

interface ConvertCreativeRequestDomain {
    (domain: CreativeRequestDomain): Promise<Param>;
}

export const convertCreativeRequestDomain: ConvertCreativeRequestDomain = async ({ model }) => {
    const availableContractsIds = await model
        .findAvailableContracts()
        .then((contracts) => contracts.map(({ id }) => id))
        .catch(() => []);

    const isValidContract = includes(availableContractsIds, model?.contractId);

    return {
        id: model.id,
        type: 'mainInfo',
        fields: {
            contract: isValidContract,
        },
    };
};
