import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as moment from 'moment';

import type { Filters } from '@store/calendar/types';

import { WeekSelection } from './WeekSelection';
import type { StoreState } from '@store';
import { setFilters } from '@store/calendar/actions';
import { getFilters } from '@store/calendar/selectors';

const LEFT_ARROW_KEYCODE = 37;
const RIGHT_ARROW_KEYCODE = 39;

interface Props extends Partial<MapProps>, Partial<DispatchProps> {}

interface MapProps {
    weekStart: Date;
}

interface DispatchProps {
    setFilters: (filters: Filters) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class WeekSelectionContainer extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    public componentDidMount() {
        window.addEventListener('keydown', this.onKeyDown);
    }

    public componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyDown);
    }

    public render(): JSX.Element {
        return React.createElement(WeekSelection, {
            title: this.makeTitle(),
            onWeekChangeForward: this.onWeekChangeForward,
            onWeekChangeBackward: this.onWeekChangeBackward,
        });
    }

    @autobind
    protected onWeekChangeForward() {
        this.props.setFilters({
            weekStart: moment(this.props.weekStart).add(1, 'week').toDate(),
        });
    }

    @autobind
    protected onWeekChangeBackward() {
        this.props.setFilters({
            weekStart: moment(this.props.weekStart).subtract(1, 'week').toDate(),
        });
    }

    @autobind
    protected onKeyDown(event: KeyboardEvent) {
        if (event.keyCode === LEFT_ARROW_KEYCODE) {
            this.onWeekChangeBackward();
        }

        if (event.keyCode === RIGHT_ARROW_KEYCODE) {
            this.onWeekChangeForward();
        }
    }

    private makeTitle(): string {
        const weekStart = moment(this.props.weekStart);
        const weekEnd = weekStart.clone().add(1, 'week');

        const yearIsSame = weekStart.year() == weekEnd.year();

        return yearIsSame
            ? `${weekStart.format('D MMMM')} — ${weekEnd.format('D MMMM YYYY')}`
            : `${weekStart.format('D MMMM YYYY')} — ${weekEnd.format('D MMMM YYYY')}`;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { weekStart } = getFilters(state);

    return {
        weekStart,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setFilters,
        },
        dispatch,
    );
}
