import * as React from 'react';
import { FileStorage } from 'sber-marketing-types/backend';

import { ImageGallery, ImageGalleryItem } from 'sber-marketing-ui';

import { getImagePreview } from '@modules/files/utils';
import { getTaskFileOriginUrl } from '@modules/task/utils';
import { FileData } from '@modules/files/types';

const audioExtensions: string[] = require('audio-extensions');
const videoExtensions: string[] = require('video-extensions');

export interface TaskFilesGalleryProps {
    taskId: string;
    file?: FileData;
    files: FileData[];
    onShowFile: (file?: FileData) => void;
}

function TaskFilesGalleryActive({ taskId, file, files, onShowFile }: TaskFilesGalleryProps) {
    const items: ImageGalleryItem[] = React.useMemo(
        () =>
            files
                .filter(
                    ({ storage, type }) =>
                        storage === FileStorage.IMAGE_SERVICE ||
                        audioExtensions.includes(type) ||
                        videoExtensions.includes(type),
                )
                .map(({ id, type, originName, storage, parent, containerName }) => {
                    const fileType = videoExtensions.includes(type)
                        ? 'video'
                        : audioExtensions.includes(type)
                        ? 'audio'
                        : 'image';

                    return {
                        id,
                        type: fileType,
                        name: originName,
                        extension: type,
                        previewUrl: fileType === 'image' ? getImagePreview({ id, type, storage }) : null,
                        originalUrl: getTaskFileOriginUrl({ id, type, storage, taskId, parent, containerName }),
                    };
                }),
        [files],
    );

    const index = file ? items.findIndex(({ id }) => id === file.id) : -1;

    const handleViewClose = () => {
        onShowFile(undefined);
    };

    return <>{index > -1 && <ImageGallery startIndex={index} items={items} onOutsideClick={handleViewClose} />}</>;
}

export function TaskFilesGallery(props: TaskFilesGalleryProps) {
    return <>{props.file && <TaskFilesGalleryActive {...props} />}</>;
}
