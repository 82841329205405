import gql from 'graphql-tag';

const MONTHS_FRAGMENT = 'jan feb mar apr may jun jul aug sept oct nov dec';

export const GET_BUDGET_ITEM_LINKS_QUERY = gql`
    query getBudgetItemLinks($linkIds: [String]!) {
        links: getProjectLinkBudgetItemApprovalRequests(filter: { id: $linkIds }) {
            nodes {
                id
                status
                createdAt
                permissions {
                    read
                    delete
                }
                project {
                    permissions {
                        read
                    }
                }
                budgetItem {
                    id
                    serialNumber
                    sapComment
                    author {
                        organizationId
                    }
                    permissions {
                        read
                        write
                    }
                    budget {
                        id
                        year
                        organizationIds
                    }
                    dictionary {
                        tool {
                            value
                        }
                    }
                    plannedFunds { ${MONTHS_FRAGMENT} }
                    reservedFunds { ${MONTHS_FRAGMENT} }
                    factFunds { ${MONTHS_FRAGMENT} }
                }
                author {
                    firstName
                    secondName
                }
                approver {
                    id
                    firstName
                    secondName
                }
            }
        }
    }
`;
