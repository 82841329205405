import * as React from 'react';
import classNames from 'classnames';

import { useDeleteChannelMutation } from '@api';

import { DropdownOptionsProps, useDropdown, useSearch } from '@common/hooks';

import { Button, Buttons, Dropdown, TagClose, TagCloseProps } from '@common/components';

import * as styles from './ChannelCloseIcon.scss';

export interface ChannelCloseIconProps extends Omit<TagCloseProps, 'onClick'>, DropdownOptionsProps {
    taskId: string;
    channelId: number;
    rootRef?: React.MutableRefObject<SVGSVGElement>;
}

export function ChannelCloseIcon({
    taskId,
    channelId,
    className,
    children,
    rootRef,
    dropdownClassName,
    ...rest
}: ChannelCloseIconProps) {
    const [deleteChannel] = useDeleteChannelMutation();
    const [dropdown, ref, props] = useDropdown(
        { ...rest, dropdownClassName: classNames(styles.dropdown, dropdownClassName), target: rootRef },
        SVGSVGElement,
    );
    const [search, setSearch] = useSearch();

    const handleDeleteChannel = () => {
        if (search.channelId === String(channelId)) {
            setSearch({ ...search, channelId: undefined });
        }

        deleteChannel({ channelId, taskId });
    };

    const handleCancel = () => {
        dropdown.close();
    };

    return (
        <>
            <TagClose {...props} rootRef={ref} className={classNames(styles.root, className)} />
            <Dropdown dropdown={dropdown}>
                Вы уверены, что хотите удалить канал? Все комментарии и файлы канала также будут удалены.
                <Buttons>
                    <Button flex onClick={handleCancel}>
                        Отмена
                    </Button>
                    <Button flex view="danger" onClick={handleDeleteChannel}>
                        Да, удалить
                    </Button>
                </Buttons>
            </Dropdown>
        </>
    );
}
