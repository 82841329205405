import * as React from 'react';
import * as moment from 'moment';
import classnames from 'classnames';
import { isNil } from 'lodash';

import * as style from './Text.scss';

import type { FieldValue, FieldProperties, BriefCost } from 'sber-marketing-types/frontend';
import type { FieldInput } from '@store/brief/types';
import { Money, MoneyFormatter } from '@common/Utils';

import { LabeledInput, InputType, LabeledTextarea, Icon, IconType, WithTooltip } from 'sber-marketing-ui';

const LATIN_INPUT_REGEXP = /[^\w\d\s\\!\@\#$%\^\&\*\(\)_\+\{\}\|\:\"\<\>\?\,\.\/\;\'\[\]\-\=]+/gi;
const URL_INPUT_REGEXP = /[^\w\d\\!\@\#$%\^\&\*\(\)_\+\{\}\|\:\"\<\>\?\,\.\/\;\'\[\]\-\=]+/gi;

const DEFAULT_VALIDATION_MESSAGE = 'Обязательное поле';
const URL_FORMAT_VALIDATION_MESSAGE = 'Некорректный формат ссылки';

interface Props extends FieldProperties {
    id: string;
    blockId: string;
    uniqId?: number;
    parentUniqId?: number;
    index?: number;
    value?: FieldValue;
    displayError?: boolean;
    disabled?: boolean;
    briefCost?: BriefCost;
    onValueChange: (value: FieldInput) => void;
    onMultipleRemove: () => void;
}

function TextComponent({
    id,
    blockId,
    uniqId,
    parentUniqId,
    index,
    value = {},
    tooltipMessage,
    validationMessage,
    displayError,
    name,
    isMultiple,
    isRange,
    isCalendar,
    isCalendarDefault,
    isInput,
    isNumeric,
    isLatin,
    isUrl,
    isBlockCalculation,
    briefCost,
    onValueChange,
    onMultipleRemove,
    disabled,
}: Props): JSX.Element {
    const hasTooltip = !!tooltipMessage;
    let type: InputType;
    let inputValue: string | string[] | moment.Moment;

    React.useEffect(() => {
        if (isCalendar && isCalendarDefault && !value.date) {
            onValueChange(moment());
        }
    }, []);

    if (isRange) {
        type = InputType.Range;
        inputValue = [value.from, value.to] as string[];
    }

    if (isCalendar) {
        type = InputType.DatePicker;
        inputValue = value.date ? moment(value.date) : null;
    }

    if (!isRange && !isCalendar) {
        type = InputType.Input;
        inputValue = value.text;
    }

    if (isBlockCalculation && briefCost) {
        type = InputType.Input;

        const fieldCost = (briefCost.calculationFieldCosts || []).find(
            (item) => item.blockId === blockId && item.uniqId === uniqId && item.parentUniqId === parentUniqId,
        );
        inputValue = fieldCost ? MoneyFormatter.toRoubles(Money.fromRoubles(fieldCost.cost)) : '';
    }

    return (
        <div
            className={classnames(style.root, isBlockCalculation && style.inputCalculation)}
            {...{
                'qa-id': 'briefBlockField',
                'qa-index': index,
                'qa-field-type': 'Text',
            }}
        >
            {isUrl ? (
                <LabeledInput
                    qaId="briefBlockTextFieldInput"
                    title={name || 'Новое текстовое поле'}
                    value={inputValue}
                    onValueChange={(value) => onValueChange((value as string).replace(URL_INPUT_REGEXP, ''))}
                    errorMessage={
                        displayError && inputValue
                            ? URL_FORMAT_VALIDATION_MESSAGE
                            : validationMessage || DEFAULT_VALIDATION_MESSAGE
                    }
                    displayError={displayError}
                />
            ) : isInput ? (
                isBlockCalculation ? (
                    <LabeledInput
                        qaId="briefBlockTextFieldInput"
                        value={inputValue}
                        title={name || 'Новое текстовое поле'}
                        errorMessage={validationMessage || DEFAULT_VALIDATION_MESSAGE}
                        displayError={displayError}
                        type={type}
                        readOnly
                        disableEllipsis
                    />
                ) : (
                    <LabeledInput
                        qaId="briefBlockTextFieldInput"
                        value={inputValue}
                        title={name || 'Новое текстовое поле'}
                        errorMessage={validationMessage || DEFAULT_VALIDATION_MESSAGE}
                        displayError={displayError}
                        type={type}
                        onValueChange={(value) =>
                            isLatin
                                ? onValueChange((value as string).replace(LATIN_INPUT_REGEXP, ''))
                                : onValueChange(value)
                        }
                        disabled={disabled}
                        onlyNumbers={!isNil(isNumeric) && isNumeric}
                        disableEllipsis
                    />
                )
            ) : (
                <LabeledTextarea
                    qaId="briefBlockTextFieldInput"
                    value={value.text}
                    title={name || 'Новое текстовое поле'}
                    errorMessage={validationMessage || DEFAULT_VALIDATION_MESSAGE}
                    displayError={displayError}
                    onValueChange={(value) =>
                        isLatin
                            ? onValueChange((value as string).replace(LATIN_INPUT_REGEXP, ''))
                            : onValueChange(value)
                    }
                    disabled={disabled}
                />
            )}

            {hasTooltip && (
                <WithTooltip
                    content={<div className={style.tooltipMessage}>{tooltipMessage}</div>}
                    className={style.iconTooltip}
                >
                    <Icon type={IconType.INFO_CIRCLE} svgSize="16" />
                </WithTooltip>
            )}

            {isMultiple && onMultipleRemove && (
                <div
                    className={classnames(style.iconMultiple, isCalendar && style.iconMultipleCalendar)}
                    onClick={onMultipleRemove}
                >
                    <Icon type={IconType.MINUS} />
                </div>
            )}
        </div>
    );
}

export function Text(props: Props): JSX.Element {
    let addValue: string | string[];
    let inputValue: string[] | string[][] | moment.Moment[];

    React.useEffect(() => {
        if (props.isMultiple && !props.value?.values) {
            props.onValueChange(['']);
        }
    }, []);

    if (props.isMultiple && props.value?.values) {
        if (props.isRange) {
            inputValue = props.value.values.map((value) => [value.from, value.to]) as string[][];
            addValue = ['', ''];
        }

        if (props.isCalendar) {
            inputValue = props.value.values.map((value) => (value.date ? moment(value.date) : null)) as moment.Moment[];
            addValue = null;
        }

        if (!props.isRange && !props.isCalendar) {
            inputValue = props.value.values.map((value) => value.text);
            addValue = '';
        }
    }

    return props.isMultiple ? (
        <div className={style.multiple}>
            {(props.value?.values || [{}]).map((value: FieldValue, index) => (
                <TextComponent
                    {...props}
                    value={value}
                    onValueChange={(value) => {
                        let newValue = [...inputValue];
                        newValue[index] = value as string;
                        props.onValueChange(newValue as string[]);
                    }}
                    onMultipleRemove={
                        index
                            ? () => {
                                  props.onValueChange((inputValue as string[]).filter((v, i) => i !== index));
                              }
                            : null
                    }
                />
            ))}
            {inputValue?.length < 10 ? (
                <div
                    className={style.multipleAdd}
                    onClick={() => props.onValueChange([...inputValue, addValue] as string[])}
                >
                    <Icon type={IconType.PLUS_20} /> Добавить
                </div>
            ) : null}
        </div>
    ) : (
        <TextComponent {...props} />
    );
}
