import { JSXElementConstructor } from 'react';
import { TmTextCell, TmTextCellProps } from './cell/text/TmTextCell';
import { TmImageCell, TmImageCellProps } from './cell/image/TmImageCell';
import { TmEnumCell, TmEnumCellProps } from './cell/enum/TmEnumCell';
import { TmRegistered } from '../../Trademark';

export interface CellTypeMapInterface {
    [fieldName: string]: CellTypeInterface<TmTextCellProps | TmImageCellProps | TmEnumCellProps<any>>;
}

export interface CellTypeInterface<P> {
    class: JSXElementConstructor<P>;
    mapToProps: (value: any) => P;
}

export const CellTypeMap: CellTypeMapInterface = {
    id: {
        class: TmTextCell,
        mapToProps: (value) => ({ title: value }),
    },
    name: {
        class: TmTextCell,
        mapToProps: (value) => ({ title: value }),
    },
    logo: {
        class: TmImageCell,
        mapToProps: (value) => ({ src: value }),
    },
    isRegistered: {
        class: <JSXElementConstructor<TmEnumCellProps<TmRegistered>>>TmEnumCell,
        mapToProps: (value) => ({
            value: value ? TmRegistered.Registered : TmRegistered.Unregistered,
            labels: {
                [TmRegistered.Registered]: 'Зарегестрирован',
                [TmRegistered.Unregistered]: 'Нет',
            },
        }),
    },
    organization: {
        class: TmTextCell,
        mapToProps: (value) => ({ title: value }),
    },
    responsible: {
        class: TmTextCell,
        mapToProps: (value) => ({ title: value }),
    },
    contact: {
        class: TmTextCell,
        mapToProps: (value) => ({ title: value }),
    },
};
