import * as React from 'react';

const HEIGHT_HEADER = 48;
const WIDTH_RIGHT_SIDEBAR = 550;
const PADDING = 12;

interface ExternalProps {
    parentRef: React.RefObject<HTMLDivElement>;
}

interface RenderParams {
    childrenRef: React.RefObject<HTMLDivElement>;
}

interface Props extends ExternalProps {
    children?: (params: RenderParams) => React.ReactNode | React.ReactNode[];
}

export class WithCoordinator extends React.Component<Props> {
    private childrenRef: React.RefObject<HTMLDivElement> = React.createRef();

    public componentDidMount() {
        const parentElement = this.props.parentRef.current;
        const positionedElement = this.childrenRef.current;

        const positionedElementRect = positionedElement.getBoundingClientRect();
        const parentElementRect = parentElement.getBoundingClientRect();
        const viewportHeight = document.documentElement.clientHeight;

        const innerElementTop = parentElementRect.top + parentElementRect.height / 2;

        const positionedElementPosition = {
            top: innerElementTop - positionedElementRect.height / 2 + PADDING,
            right: WIDTH_RIGHT_SIDEBAR + PADDING,
            bottom: innerElementTop + positionedElementRect.height / 2 + PADDING,
        };

        if (positionedElementPosition.bottom > viewportHeight) {
            positionedElementPosition.top = viewportHeight - positionedElementRect.height - PADDING;
        }

        if (positionedElementPosition.top <= HEIGHT_HEADER + PADDING) {
            positionedElementPosition.top = HEIGHT_HEADER + PADDING;
        }

        positionedElement.style.top = `${positionedElementPosition.top}px`;
        positionedElement.style.right = `${positionedElementPosition.right}px`;
    }

    public render() {
        return this.props.children({
            childrenRef: this.childrenRef,
        });
    }
}
