import { difference } from 'lodash';

import { CreativeRequest, CreativeRequestSubject } from '@api';

import { useCreativeRequestData } from '../../hooks';

export function useInitiators(creativeRequest: CreativeRequest) {
    return useCreativeRequestData(
        creativeRequest,
        (creativeRequest) => creativeRequest.model.getInitiators(),
        (creativeRequest, setInitiators) => creativeRequest.events.onInitiatorsUpdated(setInitiators),
        (creativeRequest, setInitiators) => creativeRequest.events.offInitiatorsUpdated(setInitiators),
    );
}

export function useAgencyExecutors(creativeRequest: CreativeRequest) {
    function updateExecutor(
        setter: (executor: CreativeRequestSubject) => void,
        payload: { agencyExecutor: CreativeRequestSubject },
    ) {
        setter(payload.agencyExecutor);
    }
    let updateExecutorBound: (payload: CreativeRequestSubject[]) => void;

    return useCreativeRequestData(
        creativeRequest,
        (creativeRequest) => creativeRequest.model.getAgencyExecutors(),
        (creativeRequest, setAgencyExecutor) => {
            updateExecutorBound = updateExecutor.bind(null, setAgencyExecutor);
            creativeRequest.events.onAgencyExecutorsUpdated(updateExecutorBound);
        },
        (creativeRequest) => creativeRequest.events.offAgencyExecutorsUpdated(updateExecutorBound),
    );
}

export function useExperts(creativeRequest: CreativeRequest) {
    return useCreativeRequestData(creativeRequest, (creativeRequest) => creativeRequest.model.findAvailableExperts());
}

export function useActClosers(creativeRequest: CreativeRequest) {
    return useCreativeRequestData(creativeRequest, (creativeRequest) =>
        creativeRequest.model.findAvailableActClosers(),
    );
}

export function useParticipants(creativeRequest: CreativeRequest) {
    return useCreativeRequestData(
        creativeRequest,
        (creativeRequest) => creativeRequest.model.getParticipants(),
        (creativeRequest, setParticipants) => creativeRequest.events.onParticipantsUpdated(setParticipants),
        (creativeRequest, setParticipants) => creativeRequest.events.offParticipantsUpdated(setParticipants),
    );
}

export function useUpdateParticipants(creativeRequest: CreativeRequest) {
    async function updateParticipants(participants: number[]) {
        if (creativeRequest) {
            const existingParticipantsIds = (await creativeRequest.model.getParticipants())?.map(
                (participant) => participant.id,
            );

            const participantsToRemove = difference(existingParticipantsIds, participants);
            const participantsToAdd = difference(participants, existingParticipantsIds);

            await Promise.all([
                ...participantsToAdd.map((userId) => creativeRequest.model.addParticipant({ userId })),
                ...participantsToRemove.map((userId) => creativeRequest.model.removeParticipant({ userId })),
            ]);
        }
    }

    return {
        updateParticipants,
    };
}
