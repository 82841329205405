import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
import * as lodash from 'lodash';

import { Module } from 'sber-marketing-types/backend';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';
import { getLoadingStatus } from '@store/userConfig/dashboard';
import {
    fetchMoreActivities,
    getFilteredActivitiesList,
    getStatus,
    getError,
    getCanBeLoadedMore,
    setType,
    resetMyActivities,
    clearActivitiesList,
    ActivitiesListType,
    deleteActivities,
    makeActivitiesFavorite,
    switchKeyActivity,
    getActivitiesListState,
} from '@store/activitiesList';
import { getAvailableActivitiesPageFilters, getMyActivitiesPageFilters } from '@store/userConfig';

import { ActivitiesListContainer } from './ActivitiesListContainer';
import { ActivitiesListStoreProps, ActivitiesListMappedState, ActivitiesListMappedActions } from './types';

const mapStateToProps = (state: StoreState, ownProps: ActivitiesListStoreProps): ActivitiesListMappedState => {
    const user = getLoginUser(state);
    let filters;
    switch (ownProps.type) {
        case ActivitiesListType.ACCESSIBLE_ACTIVITIES:
            filters = getAvailableActivitiesPageFilters(state);
            break;
        case ActivitiesListType.MY_ACTIVITIES:
            filters = getMyActivitiesPageFilters(state);
            break;
    }

    const userHasBudgetAccess = lodash.includes(user.attributes.modules, Module.Budget);

    return {
        pendingDeletionIds: getActivitiesListState(state).pendingDeletionIds,
        filters,
        loadingStatus: getStatus(state),
        error: getError(state),
        activities: getFilteredActivitiesList(state, user.attributes.id),
        userOrganizationId: user.attributes.organizationId,
        canBeLoadedMore: getCanBeLoadedMore(state),
        userConfigLoadingStatus: getLoadingStatus(state),
        userHasBudgetAccess,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<StoreState>): ActivitiesListMappedActions => ({
    deleteActivities: (...ids) => dispatch(deleteActivities(ids)),
    makeActivitiesFavorite: (id) => dispatch(makeActivitiesFavorite(id)),
    fetchMoreActivities: lodash.debounce((filters) => dispatch(fetchMoreActivities(filters)), 500),
    setListType: (type) => dispatch(setType(type)),
    resetMyActivities: () => dispatch(resetMyActivities()),
    clearActivitiesList: () => dispatch(clearActivitiesList()),
    switchKeyActivity: (payload) => dispatch(switchKeyActivity(payload)),
});

const withStore = connect(mapStateToProps, mapDispatchToProps);

export const ActivitiesListStore: ComponentType<ActivitiesListStoreProps> = withStore(
    withRouter(ActivitiesListContainer),
);
