import * as React from 'react';
import { IconType, Icon } from 'sber-marketing-ui';
import { compact } from 'lodash';
import classNames from 'classnames';

import * as styles from './AllAssetsTitle.scss';
import { AllAssetsSortOrder } from '../../../../../../store/taskPage';

const TITLE = 'ВЛОЖЕНИЯ';
const ICON_SIZE = 16;

export interface AllAssetsTitleProps {
    /** "true" is must be used tiles icon for change display mode icon */
    isTiles: boolean;
    /** Additional title */
    additionalTitle?: string;
    /** If "true" reversed sorting icon will be showed */
    sortOrder: AllAssetsSortOrder;
    /** Additional CSS class */
    className?: string;
    /** Change display mode button click handler */
    onChangeDisplayModeClick(): any;
    /** Sort assets button click */
    onSortAssetsClick(): any;
}
/* tslint:disable:no-magic-numbers */
export function AllAssetsTitle({
    onChangeDisplayModeClick,
    additionalTitle,
    onSortAssetsClick,
    isTiles,
    sortOrder,
    className,
}: AllAssetsTitleProps): JSX.Element {
    const title = `${TITLE} ${additionalTitle || ''}`.trim();
    return (
        <div className={classNames(compact([styles.root, className]))}>
            <div className={styles.title} title={title}>
                {title}
            </div>

            <div className={styles.buttonsWrapper}>
                <div
                    {...{
                        'qa-id': 'taskAssetsChangeDisplayModeButton',
                        'qa-value': isTiles ? 'list-normal' : 'tiles',
                    }}
                >
                    <Icon
                        type={isTiles ? IconType.LIST_NORMAL : IconType.TILES}
                        onClick={onChangeDisplayModeClick}
                        className={styles.button}
                        svgSize={ICON_SIZE}
                        color="#999999"
                    />
                </div>

                <div
                    {...{
                        'qa-id': 'taskAssetsSortAssetsButton',
                    }}
                >
                    <Icon
                        type={getIconTypeBySortOrder(sortOrder)}
                        onClick={onSortAssetsClick}
                        className={classNames(
                            compact([
                                styles.button,
                                sortOrder === AllAssetsSortOrder.UPLOAD_DATE ? styles.button_sortDate : null,
                            ]),
                        )}
                        svgSize={ICON_SIZE}
                        color="#999999"
                    />
                </div>
            </div>
        </div>
    );
}

function getIconTypeBySortOrder(sortOrder: AllAssetsSortOrder): IconType {
    let result: IconType;
    switch (sortOrder) {
        case AllAssetsSortOrder.UPLOAD_DATE:
        case AllAssetsSortOrder.NAME_ASC: {
            result = IconType.SORT_LETTERS;
            break;
        }
        case AllAssetsSortOrder.NAME_DESC: {
            result = IconType.SORT_LETTERS_REVERSED;
            break;
        }
    }
    return result;
}
