import * as React from 'react';

import { WithClientData } from './WithClientData';
import { TableBehaviour } from './TableBehaviour';
import { TableContext } from '../../../Context';

interface Props {
    creativeRequestId: string;
    displayStatusColumns: boolean;
}

export const TableConnector: React.FC<Props> = ({ creativeRequestId, displayStatusColumns }) => {
    const tableRef = React.useContext(TableContext);

    return (
        <WithClientData creativeRequestId={creativeRequestId} tableRef={tableRef}>
            {({
                loading,
                getProject,
                getCreativeRequest,
                getLine,
                getLines,
                getCreativeRequestLot,
                getDictionaries,
                getContracts,
                createLine,
                archiveLine,
                restoreLine,
            }) => (
                <TableBehaviour
                    ref={tableRef}
                    displayStatusColumns={displayStatusColumns}
                    loading={loading}
                    getProject={getProject}
                    getCreativeRequest={getCreativeRequest}
                    getLine={getLine}
                    getLines={getLines}
                    getCreativeRequestLot={getCreativeRequestLot}
                    getDictionaries={getDictionaries}
                    getContracts={getContracts}
                    createLine={createLine}
                    archiveLine={archiveLine}
                    restoreLine={restoreLine}
                />
            )}
        </WithClientData>
    );
};
