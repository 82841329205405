import * as React from 'react';
import * as lodash from 'lodash';
import { UserResponseParams } from 'sber-marketing-types/frontend';
import { PlainDictionary, DictionaryType } from '@mrm/dictionary';
import { Tag } from '@mrm/tags';
import {
    Preloader,
    FilterMenu as FilterMenuSelector,
    FilterMenuBorderType,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    FilterMenuFilterControls,
} from 'sber-marketing-ui';

import { OrderType, ColumnName, ColumnsNameWithCodes } from '@store/budgetExecution/types';

import { Utils, Money, MoneyFormatter, DatesFormatter } from '@common/Utils';
import { Sorting, SortingMenu, FixedColumnButton } from '@budgetCommon';

import { ColumnsList } from '../ColumnsConfig';

import * as style from './FilterMenu.scss';

export interface Filters {
    [title: string]: boolean;
}

interface Props {
    showPreloader: boolean;
    dictionaryType: DictionaryType;
    columnName: ColumnName;
    users: UserResponseParams[];
    dictionaries: Record<string, PlainDictionary>;
    filters: Filters;
    currencySearch?: boolean;
    selectedFilters: React.ReactText[];
    filterNamesAreNumeral: boolean;
    sortingOrder: OrderType;
    isColumnFixed: boolean;
    disableSorting: boolean;
    disableFilter: boolean;
    disableColumnFix: boolean;
    showApplyFiltersButton: boolean;
    tags: lodash.Dictionary<Tag>;
    onColumnFixToggle: () => void;
    rootRef: (element: HTMLDivElement) => void;
    onSortingChange: (sorting: Sorting) => void;
    onFiltersChange: (selectedFiltersIds: React.ReactText[]) => void;
    onApplyFiltersButtonClick: () => void;
}

export const FilterMenu = ({
    showPreloader,
    dictionaryType,
    columnName,
    users,
    dictionaries,
    filters,
    currencySearch,
    selectedFilters,
    filterNamesAreNumeral,
    sortingOrder,
    isColumnFixed,
    disableSorting,
    disableFilter,
    disableColumnFix,
    showApplyFiltersButton,
    tags,
    onApplyFiltersButtonClick,
    onColumnFixToggle,
    rootRef,
    onSortingChange,
    onFiltersChange,
}: Props): JSX.Element => {
    const column = ColumnsList.find((column) => column.name === columnName);
    const filterNames = lodash.keys(filters);

    let sortedFilterNames = filterNamesAreNumeral ? sortStringsAsNumbers(filterNames) : filterNames.sort();

    const nullElement = lodash.find(
        sortedFilterNames,
        (item) => item === 'null' || item === '' || item === 'undefined',
    );

    if (nullElement !== undefined) {
        sortedFilterNames = lodash.without(sortedFilterNames, nullElement);

        sortedFilterNames.push(nullElement);
    }

    let sorting: Sorting;
    switch (sortingOrder) {
        case OrderType.Asc:
            sorting = Sorting.Asc;
            break;
        case OrderType.Desc:
            sorting = Sorting.Desc;
            break;
        default:
            sorting = Sorting.None;
            break;
    }

    return (
        <div
            className={style.root}
            ref={rootRef}
            tabIndex={-1}
            {...{
                'qa-id': 'tableHeader',
            }}
        >
            {showPreloader && <Preloader />}

            <div className={style.title}>{column?.title}</div>

            {!disableColumnFix && (
                <div className={style.fixedColumnButton}>
                    <FixedColumnButton isColumnFixed={isColumnFixed} onClick={onColumnFixToggle} />
                </div>
            )}

            {!disableSorting && <SortingMenu sorting={sorting} onSortingChange={onSortingChange} />}

            {!disableFilter && !showPreloader && (
                <FilterMenuSelector
                    qaId="tableHeaderValuesToDisplayFilter"
                    filterTitle="Отображаемые значения"
                    filterControls={
                        currencySearch ? FilterMenuFilterControls.Interval : FilterMenuFilterControls.Regular
                    }
                    items={sortedFilterNames
                        .map((item, index) => ({
                            id: item,
                            title: getItemTitle(
                                item,
                                currencySearch,
                                dictionaryType,
                                columnName,
                                users,
                                dictionaries,
                                tags,
                            ),
                            order: filterNamesAreNumeral ? index : undefined,
                        }))
                        .filter((item) => !!item.title)}
                    checkedItemsIds={selectedFilters}
                    isExpanded
                    onItemSelection={onFiltersChange}
                    border={FilterMenuBorderType.NONE}
                    currencySearch={currencySearch}
                    preserveAllSelectedState
                />
            )}

            {showApplyFiltersButton && (
                <React.Fragment>
                    <div className={style.divider} />

                    <div className={style.applyFiltersButtonWrapper}>
                        <Button theme={ButtonTheme.GhostRounded} onClick={onApplyFiltersButtonClick}>
                            <div className={style.applyFiltersButtonContent}>
                                <svg
                                    width="10"
                                    height="10"
                                    viewBox="0 0 10 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={style.applyFilterButtonIcon}
                                >
                                    <path
                                        d="M9.07274 5.07264C9.07274 5.45675 8.84757 5.78786 8.51644 5.9733L1.48343 9.60242C1.1788 9.76136 0.79469 9.76136 0.490061 9.57593C0.185431 9.3905 0 9.05939 0 8.70176V1.03299C0 0.675383 0.19867 0.344257 0.516547 0.132339C0.675485 0.0263805 0.847671 -0.000113487 1.03309 -0.000113487C1.21852 -0.000113487 1.3907 0.0528641 1.54964 0.132339L8.58266 4.14558C8.88729 4.33101 9.07274 4.68855 9.07274 5.07264Z"
                                        fill="black"
                                    />
                                </svg>
                                Применить
                            </div>
                        </Button>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

function sortStringsAsNumbers(filterNames: string[]): string[] {
    return filterNames.sort(
        (itemA: string, itemB: string) =>
            parseInt(itemA.replace(/\s/g, ''), 10) - parseInt(itemB.replace(/\s/g, ''), 10),
    );
}

function getItemTitle(
    item: string,
    currencySearch: boolean,
    dictionaryType: DictionaryType,
    columnName: ColumnName,
    users: UserResponseParams[],
    dictionaries: Record<string, PlainDictionary>,
    tags: lodash.Dictionary<Tag>,
): string {
    if (item === 'null' || item === '' || item === 'undefined') {
        return 'Значение отсутствует';
    }

    if (columnName === ColumnName.Responsible) {
        const userId = Number(item);
        const user = users.find((user) => user.id === userId);

        return user ? `${user.secondName} ${user.firstName}` : 'Пользователь не найден';
    }

    if (columnName === ColumnName.StartDate || columnName === ColumnName.EndDate) {
        return DatesFormatter.ddMonthyyyy(item);
    }

    if (columnName === ColumnName.Author) {
        const user = users.find((user) => user.id === Number(item));

        return formatUser(user);
    }

    if (columnName === ColumnName.Tags) {
        return tags[item]?.title || 'Тег не найден';
    }

    if (dictionaryType && !lodash.includes(ColumnsNameWithCodes, columnName)) {
        const currentDict = dictionaries[item];

        return Utils.getDictionaryValue(currentDict);
    }

    if (currencySearch) {
        return MoneyFormatter.toThousands(Money.fromStringCopecks(item), { hideCaption: true });
    }

    return item;
}

function formatUser(user: UserResponseParams): string {
    return user ? `${user.secondName} ${user.firstName}` : 'Пользователь не найден';
}
