import { bindActionCreators } from 'redux';
import { v4 as uuid } from 'uuid';
import * as lodash from 'lodash';

import type { PlainDictionary } from '@mrm/dictionary';
import type { Autopilot2 } from 'sber-marketing-types/backend';
import type { User } from '@store/user/types';
import type { GroupedDictionaries } from '@store/autopilot/types';

import { store } from '@store';
import { getLoginUser } from '@store/user/selector';
import {
    loadAutopilot,
    loadActivity,
    loadDictionaries,
    loadMediaplan,
    loadMediaplanChannelsGroups,
    loadPlacementSpecs,
} from '@store/autopilot/actions';
import { getAutopilot } from '@store/autopilot/selectors';
import { loadUserOrganizations } from '@store/userOrganizations/thunks';
import { AutopilotApi, ActivityApi, DictionaryApi } from '@api';

interface StoreProps {
    user: User;
    autopilot: Autopilot2;
}

export class Loader {
    private static instance: Loader;
    private activityId: number;

    private dispatch = bindActionCreators(
        {
            loadAutopilot,
            loadActivity,
            loadMediaplan,
            loadMediaplanChannelsGroups,
            loadPlacementSpecs,
            loadUserOrganizations,
            loadDictionaries,
        },
        store.dispatch,
    );

    public static getInstance(): Loader {
        if (!Loader.instance) {
            Loader.instance = new Loader();
        }
        return Loader.instance;
    }

    public async init(activityId: number) {
        this.activityId = activityId;

        await this.initPageData();
    }

    public async initPageData() {
        await this.loadAutopilot();

        await Promise.all([
            this.loadActivity(),
            this.loadMediaplanChannelsGroups(),
            this.loadMediaplan(),
            this.loadPlacementSpecs(),
            this.loadUsersData(),
            this.loadDictionaries(),
        ]);
    }

    public async loadAutopilot(): Promise<void> {
        let list = await AutopilotApi.getAutopilotList({ activityIds: [this.activityId] });

        let autopilot: Autopilot2 = list.find((item) => item.activityId === this.activityId);

        if (!autopilot) {
            await AutopilotApi.createAutopilot({ activityId: this.activityId, id: uuid() });
            list = await AutopilotApi.getAutopilotList({ activityIds: [this.activityId] });
            autopilot = list.find((item) => item.activityId === this.activityId);
        }

        this.dispatch.loadAutopilot(autopilot);
    }

    public async loadActivity(): Promise<void> {
        const activity = await ActivityApi.getActivity(this.activityId);

        this.dispatch.loadActivity(activity);
    }

    public async loadMediaplan(): Promise<void> {
        const { autopilot } = this.getStoreProps();

        const mediaplan = await AutopilotApi.getMediaplan(autopilot.id);

        this.dispatch.loadMediaplan(mediaplan);
    }

    public async loadMediaplanChannelsGroups(): Promise<void> {
        const { autopilot } = this.getStoreProps();

        const channelsgroups = await AutopilotApi.getChannelsGroups(autopilot.id);

        this.dispatch.loadMediaplanChannelsGroups(channelsgroups);
    }

    public async loadUsersData(): Promise<void> {
        await this.dispatch.loadUserOrganizations(null);
    }

    public async loadPlacementSpecs(): Promise<void> {
        const { autopilot } = this.getStoreProps();

        const placementSpecs = await AutopilotApi.getPlacementSpecs(autopilot.id);

        this.dispatch.loadPlacementSpecs(placementSpecs.items);
    }

    public async loadDictionaries(): Promise<void> {
        const { user } = this.getStoreProps();

        const userOrganizationId = user.attributes.organizationId;

        const dictionaries = await DictionaryApi.getDictionaryList({
            organizationId: userOrganizationId,
            treeview: true,
        });

        const sortedDictionaries = this.sortDictionaries(dictionaries);

        const groupedDictionaries = this.groupDictionaries(sortedDictionaries);

        // if (userOrganizationId === '68a38c6a-79f7-49b5-9356-b779fbbb9b64') {
        //     // Сбербанк ДМИК
        //     groupedDictionaries['block'] = groupedDictionaries['block'].filter(
        //         (item) => item.id === 'e313aac8-aa95-4f45-bd97-52372a38e152',
        //     ); // Розничный бизнес
        // }

        this.dispatch.loadDictionaries(groupedDictionaries);
    }

    private sortDictionaries(dictionaries: PlainDictionary[]): PlainDictionary[] {
        return lodash.sortBy(dictionaries, (item) => item.value);
    }

    private groupDictionaries(dictionaries: PlainDictionary[]): GroupedDictionaries {
        return lodash.groupBy(dictionaries, (item) => item.type);
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        return {
            user: getLoginUser(storeState),
            autopilot: getAutopilot(storeState),
        };
    }
}
