import { createSelector } from 'reselect';

import { StoreState } from '@store';

import { PageState, PageData, UnsavedChange } from './types';

export const getBudgetCorrectionsPageState = (state: StoreState): PageState => state.budgetCorrectionsPage.restState;

export const getPageData = createSelector(
    getBudgetCorrectionsPageState,
    (state: PageState): PageData => state.pageData,
);

export const getUnsavedChange = createSelector(
    getBudgetCorrectionsPageState,
    (state: PageState): UnsavedChange => state.unsavedChange,
);

export const userCanEditOrCreateBudgetData = createSelector(
    getPageData,
    (pageData: PageData) => pageData.budget?.actions?.createActivity,
);
