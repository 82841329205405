import * as React from 'react';

import type { UserCard } from 'sber-marketing-types/frontend';

import { UseWorkTypeOptions } from '@modules/workType/hooks';

import { DropdownPortalOption, DropdownPortalOptionProps, Option } from '@common/components';
import { WorkTypeOptions } from '@modules/workType/components';

export interface WorkTypeUserOptionProps
    extends DropdownPortalOptionProps<string>,
        Omit<UseWorkTypeOptions, 'departmentId'> {
    portal: React.MutableRefObject<HTMLElement>;
    user: UserCard;
    isLoading?: boolean;
}

export function WorkTypeUserOption({
    value,
    onSelect,
    portal,
    user,
    isLoading = !user,
    ids,
    sortingOrder,
    embed,
    organizationId,
    isArchived,
    enable_rates,
    userId,
    useUsers,
    ...props
}: WorkTypeUserOptionProps) {
    const params: Required<UseWorkTypeOptions> = {
        ids,
        sortingOrder,
        embed,
        organizationId,
        isArchived,
        enable_rates,
        departmentIds: [user?.departmentId],
        userId,
        useUsers,
    };

    return isLoading ? (
        <Option value={value} {...props} />
    ) : user.workTypeIds.length < 2 ? (
        <Option value={value} {...props} onSelect={() => onSelect(user.workTypeIds[0])} />
    ) : (
        <DropdownPortalOption
            {...props}
            value={user}
            params={{ options: { trigger: 'hover', placement: 'leftTop', stretch: true } }}
            portal={portal}
        >
            <WorkTypeOptions {...params} selected={value} onSelect={onSelect} />
        </DropdownPortalOption>
    );
}
