import * as React from 'react';
import { Link } from 'react-router-dom';
import { StyledPopup } from 'sber-marketing-ui';

import { ContentPreloader } from '../DashboardsPreloaders';
import { ActivityCard, ActivityCardProps } from '../ActivityCard';
import { ActivitiesListTemplateProps } from './types';
import { ActivitySharing } from './ActivitySharing';

import * as style from './ActivitiesList.scss';

interface ActivityGroupProps {
    title: string;
    activities: ActivityCardProps[];
    openActivitySidebar: (activityId: number) => void;
}

/** "ActivitiesList" template component */
export const ActivitiesListTemplate: React.FC<ActivitiesListTemplateProps> = ({
    isLoading,
    isFilterModeOn,
    isShareModalOpen,
    activityToShareId,
    groupedActivities,
    isRemoveModalOpened,
    deleteActivity,
    closeShareModal,
    closeDeleteModal,
    openActivitySidebar,
}: ActivitiesListTemplateProps): JSX.Element => {
    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'dashboardActivitiesList',
            }}
        >
            {groupedActivities.map(({ title, cards }) => (
                <div className={style.blockWrapper} key={title}>
                    <ActivityGroup title={title} activities={cards} openActivitySidebar={openActivitySidebar} />
                </div>
            ))}

            {!isLoading && !groupedActivities.length && (
                <div className={style.noActivities}>
                    <div
                        className={style.noActivitiesMessage}
                        {...{
                            'qa-id': 'dashboardActivitiessListNoActivitiesMarker',
                        }}
                    >
                        {isFilterModeOn
                            ? 'Ни один из проектов не соответствует заданным условиям.'
                            : 'У вас нет проектов.'}
                    </div>

                    {!isFilterModeOn && (
                        <div
                            className={style.newActivityLink}
                            {...{
                                'qa-id': 'dashboardActivitiesListCreateActivityLink',
                            }}
                        >
                            <Link
                                className={style.newActivityLink}
                                to="/activity/new"
                                style={{ textDecoration: 'none' }}
                            >
                                Создать проект
                            </Link>
                        </div>
                    )}
                </div>
            )}

            {isLoading && <ContentPreloader />}

            {isRemoveModalOpened && (
                <StyledPopup
                    title={'Удалить проект?'}
                    firstButtonText={'Отмена'}
                    firstButtonHandler={closeDeleteModal}
                    secondButtonText={'Да, удалить'}
                    secondButtonHandler={deleteActivity}
                />
            )}

            {isShareModalOpen && <ActivitySharing activityId={activityToShareId!} onClose={closeShareModal} />}
        </div>
    );
};

const ActivityGroup: React.FC<ActivityGroupProps> = ({
    title,
    activities,
    openActivitySidebar,
}: ActivityGroupProps): JSX.Element => {
    return (
        <div
            {...{
                'qa-id': 'dashboardActivitiesGroup',
                'qa-index': title,
            }}
        >
            <div className={style.title}>{title}</div>

            <div className={style.cardsWrapper}>
                {activities.map((activity) => (
                    <div className={style.cardWrapper} key={activity.activityId}>
                        <ActivityCard {...activity} openActivitySidebar={openActivitySidebar} />
                    </div>
                ))}
            </div>
        </div>
    );
};
