import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import type { ActivityParams as Activity, UserResponseParams as User } from 'sber-marketing-types/frontend';
import { RoleId } from 'sber-marketing-types/frontend';
import type { Autopilot, Autopilot2 } from 'sber-marketing-types/backend';

import type { StoreState } from '@store';
import type { HeaderView } from '@common/Page';
import type { PageOptions } from '@store/common/types';
import type { SwitchKeyActivityParams } from '@store/activityTasksPage/types';
import { updatePageOptions } from '@store/common/actions';
import { getLoginUser } from '@store/user/selector';
import { LoadingStatus } from '@store/commonTypes';
import { resetActivity } from '@store/activityPage/actions';
import {
    loadActivity,
    loadAutopilot,
    loadAutopilotLegacy,
    loadAutopilotTv,
    loadAutopilotRadio,
    loadAutopilotDOOH,
} from '@store/activityPage/thunks';
import { getActivity, getActivityLoadingStatus, getAutopilot, getAutopilotLegacy } from '@store/activityPage/selectors';
import { switchKeyActivity } from '@store/activityTasksPage/thunks';

import { ActivityPageTemplate, PageMode, PagePath } from './ActivityPageTemplate';
import { ActivityPageHeader, ActivityPageSubHeader } from './Header';
import { MrmClient } from '@api';
import type { CreativeRequestDto } from '@api';

const PAGE_MODE_NAMES_BY_URL = {
    [PagePath.Brief]: PageMode.Brief,
    [PagePath.Tasks]: PageMode.Tasks,
    [PagePath.Task]: PageMode.Task,
    [PagePath.TaskBrief]: PageMode.TaskBrief,
    [PagePath.AutopilotDOOH]: PageMode.AutopilotDOOH,
    [PagePath.AutopilotRadio]: PageMode.AutopilotRadio,
    [PagePath.AutopilotTv]: PageMode.AutopilotTv,
    [PagePath.Autopilot]: PageMode.Autopilot,
    [PagePath.Autopilot_legacy]: PageMode.Autopilot_legacy,
    [PagePath.Creative]: PageMode.Creative,
};

interface Props extends MapProps, DispatchProps, RouteComponentProps<RouteParams> {
    pageLabel: string;
    setHeaderView?: (view: HeaderView) => void;
}

interface RouteParams {
    activityId: string;
}

interface MapProps {
    user: User;
    activity: Activity;
    activityPreloader: boolean;
    autopilot: Autopilot2;
    autopilot_legacy: Autopilot;
}

interface DispatchProps {
    loadActivity: (activityId: number) => Promise<void>;
    loadAutopilot: (activityId: number) => Promise<void>;
    loadAutopilotLegacy: (activityId: number) => Promise<void>;
    loadAutopilotTv: (activityId: number) => Promise<void>;
    loadAutopilotRadio: (activityId: number) => Promise<void>;
    loadAutopilotDOOH: (activityId: number) => Promise<void>;
    updatePageOptions: (options: PageOptions) => void;
    switchKeyActivity: (params: SwitchKeyActivityParams) => Promise<void>;
    resetActivity: () => void;
}

interface State {
    hasUserRightToCreativeRequestPage: boolean;
    hasUserRightToCreateCreativeRequest: boolean;
    currentPageMode: PageMode;
    headerRightContent: JSX.Element;
    subHeaderRightContent: JSX.Element;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class ActivityPageBehaviour extends React.Component<Props, State> {
    private activityId = Number(this.props.match.params.activityId);
    private creativeRequests: CreativeRequestDto[] = [];

    constructor(props: Props) {
        super(props);

        this.state = {
            hasUserRightToCreativeRequestPage: false,
            hasUserRightToCreateCreativeRequest: false,
            currentPageMode: this.getCurrentPageMode(),
            headerRightContent: null,
            subHeaderRightContent: null,
        };

        this.updateHeader();
    }

    public async componentDidMount() {
        await Promise.all([
            this.props.loadActivity(this.activityId),
            this.props.loadAutopilot(this.activityId),
            this.props.loadAutopilotLegacy(this.activityId),
            this.props.loadAutopilotTv(this.activityId),
            this.props.loadAutopilotRadio(this.activityId),
            this.props.loadAutopilotDOOH(this.activityId),
            this.loadCreativeRequests(),
        ]);

        this.setState({
            hasUserRightToCreativeRequestPage: await this.checkUserRightToAccessCreativePage(),
            hasUserRightToCreateCreativeRequest: await this.checkUserRightToCreateCreativeRequest(),
        });
    }

    public componentWillUnmount(): void {
        this.props.resetActivity();
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        const pageModeChanged = this.props.match.url !== prevProps.match.url;
        const activityPreloaderChanged = this.props.activityPreloader !== prevProps.activityPreloader;
        const userRightToRequestPageChanged =
            this.state.hasUserRightToCreativeRequestPage !== prevState.hasUserRightToCreativeRequestPage;
        const userRightToCreateCreativeRequestChanged =
            this.state.hasUserRightToCreateCreativeRequest !== prevState.hasUserRightToCreateCreativeRequest;

        const currentPageMode = this.getCurrentPageMode();

        if (!currentPageMode) {
            this.openUrlInSameTab(`/activity/${this.activityId}/tasks`);
        } else if (pageModeChanged) {
            this.setState(
                {
                    currentPageMode,
                },
                () => {
                    this.updateHeader();
                },
            );
        } else if (
            activityPreloaderChanged ||
            userRightToRequestPageChanged ||
            userRightToCreateCreativeRequestChanged
        ) {
            this.updateHeader();
        }
    }

    public render(): JSX.Element {
        return React.createElement(ActivityPageTemplate, {
            currentPage: this.state.currentPageMode,
            setHeaderView: this.props.setHeaderView,
            setHeaderRightContent: this.setHeaderRightContent,
            setSubHeaderRightContent: this.setSubHeaderRightContent,
        });
    }
    @autobind
    private async onSwitchKeyButtonClick() {
        const { activity } = this.props;

        await this.props.switchKeyActivity({
            id: activity.id,
            isKey: !activity.isKey,
        });

        await this.props.loadActivity(this.activityId);

        this.updateHeader();
    }

    @autobind
    private setHeaderRightContent(content: JSX.Element) {
        this.setState(
            {
                headerRightContent: content,
            },
            this.updateHeader,
        );
    }

    @autobind
    private setSubHeaderRightContent(content: JSX.Element) {
        this.setState(
            {
                subHeaderRightContent: content,
            },
            this.updateHeader,
        );
    }

    @autobind
    private updateHeader() {
        const { activity, autopilot, autopilot_legacy } = this.props;
        const { currentPageMode, hasUserRightToCreativeRequestPage, hasUserRightToCreateCreativeRequest } = this.state;

        const userCanEditAutopilot = this.checkUserRightToEditAutopilot();
        const autopilotIsEnabled = autopilot?.status == 'on' || autopilot_legacy?.status == 'on';

        this.props.setHeaderView({
            firstLine: ActivityPageHeader({
                activity,
                rightContent: this.state.headerRightContent,
                onSwitchKeyButtonClick: this.onSwitchKeyButtonClick,
            }),
            additionalContent: ActivityPageSubHeader({
                activityId: this.activityId,
                activityName: activity?.name || '',
                currentUrl: this.props.match.url,
                currentPage: currentPageMode,
                displayCreative: hasUserRightToCreativeRequestPage,
                displayAutopilot:
                    userCanEditAutopilot ||
                    autopilotIsEnabled ||
                    currentPageMode === PageMode.Autopilot ||
                    currentPageMode === PageMode.Autopilot_legacy,
                canCreateCreativeRequest: hasUserRightToCreateCreativeRequest,
                rightContent: this.state.subHeaderRightContent,
            }),
        });
    }

    private checkUserRightToEditAutopilot(): boolean {
        const hasAutopilotClientRole = this.props.user.roles.map(({ name }) => name).includes('Autopilot Client');
        const hasAutopilotClientServiceRole = this.props.user.roles
            .map(({ name }) => name)
            .includes('Autopilot Client Service');
        return hasAutopilotClientRole || hasAutopilotClientServiceRole;
    }

    private async checkUserRightToAccessCreativePage(): Promise<boolean> {
        const hasUserCreativeRequests = await this.checkUserForCreativeRequests();
        const hasUserCreativeRequestRole = await this.checkUserForRoleToCreativeRequest();

        return hasUserCreativeRequests || hasUserCreativeRequestRole;
    }

    private async checkUserRightToCreateCreativeRequest(): Promise<boolean> {
        const [haveRoleToCreativeRequest, haveInitiatorToCreativeRequest] = await Promise.all([
            this.checkUserForRoleToCreativeRequest(),
            this.checkUserForInitiatorToCreativeRequests(),
        ]);

        return haveRoleToCreativeRequest || haveInitiatorToCreativeRequest;
    }

    private async checkUserForCreativeRequests(): Promise<boolean> {
        try {
            return !!this.creativeRequests.length;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    private async checkUserForRoleToCreativeRequest(): Promise<boolean> {
        return Boolean(
            lodash.intersection(
                [
                    RoleId.CreativeRequestExecutor,
                    RoleId.CreativeRequestExpert,
                    RoleId.CreativeRequestActCloser,
                    // TODO проверить роль 32 на актуальность
                    32,
                ],
                this.props.user.roles.map(({ id }) => id),
            ).length,
        );
    }

    private async checkUserForInitiatorToCreativeRequests(): Promise<boolean> {
        try {
            const {
                user: { id: userId },
            } = this.props;
            const creativeRequestInitiatorsIds = lodash.flatten(
                this.creativeRequests.map(({ initiator }) => initiator),
            );

            return lodash.includes(creativeRequestInitiatorsIds, userId);
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    private async loadCreativeRequests(): Promise<void> {
        try {
            const client = await MrmClient.getInstance();
            this.creativeRequests = await client.api.creativeRequests.getCreativeRequests({
                projectId: this.activityId,
            });
        } catch (error) {
            console.error(error);
            this.creativeRequests = [];
        }
    }

    private getCurrentPageMode(): PageMode {
        return PAGE_MODE_NAMES_BY_URL[this.props.match.path];
    }

    private openUrlInSameTab(url: string) {
        this.props.history.push(url);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const activityLoadingStatus = getActivityLoadingStatus(state);

    const { attributes: user } = getLoginUser(state);

    return {
        user,
        activity: getActivity(state),
        activityPreloader: activityLoadingStatus !== LoadingStatus.LOADED,
        autopilot: getAutopilot(state),
        autopilot_legacy: getAutopilotLegacy(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            loadActivity,
            loadAutopilot,
            loadAutopilotLegacy,
            loadAutopilotTv,
            loadAutopilotRadio,
            loadAutopilotDOOH,
            updatePageOptions,
            switchKeyActivity,
            resetActivity,
        },
        dispatch,
    );
}
