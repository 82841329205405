import * as React from 'react';

import { useSetTaskMutation } from '@api';

import { Tag, TagProps, Textarea } from '@common/components';

import { TaskAccessAction, useTaskAccess, useTaskTitle } from '@modules/task/hooks';

type TagPropsExclusion = 'value' | 'defaultValue' | 'onChange' | 'onFocus' | 'onBlur';

export interface TaskTitleTagProps extends Omit<TagProps, TagPropsExclusion> {
    taskId: string;
    activityId?: number;
    inputClassName?: string;
}

export function TaskTitleTag({ taskId, activityId, loading, children, inputClassName, ...props }: TaskTitleTagProps) {
    const { taskTitle, isLoading: isTitleLoading } = useTaskTitle(taskId);
    const {
        isLoading: isAccessLoading,
        access: [canChange],
    } = useTaskAccess([TaskAccessAction.changeTitle], taskId, activityId);
    const [value = taskTitle, setValue] = React.useState<string>();
    const [setTask, { isLoading: isUpdating }] = useSetTaskMutation();
    const isLoading = loading || isAccessLoading || isTitleLoading;

    if (!canChange) {
        return (
            <Tag {...props} loading={isLoading}>
                {children}
                {taskTitle}
            </Tag>
        );
    }

    const handleChangeValue = (val: string) => {
        setValue(val.replaceAll('\n', ''));
    };

    const handleSubmit = () => {
        if (value !== taskTitle) {
            setTask({ id: taskId, title: value });
        }
    };

    return (
        <Textarea
            data-qa-id="TaskTitleTag"
            inputClassName={inputClassName}
            minRows={1}
            {...props}
            loading={loading || isUpdating}
            value={value}
            onBlur={handleSubmit}
            onChange={handleChangeValue}
        />
    );
}
