import * as React from 'react';
import {
    Icon,
    IconType,
    Popup,
    LabeledSelect,
    SelectItem,
    Button_redesign as Button,
    Preloader,
} from 'sber-marketing-ui';

import * as style from './BudgetExpertsPopup.scss';

interface Props {
    experts: SelectItem[];
    selectedExpert: any;
    disabledSubmitButton: boolean;
    showPreloader: boolean;
    closePopup: () => void;
    submitPopup: () => void;
    onExpertSelection: (value: string) => void;
}

export const BudgetExpertsPopup = ({
    experts,
    selectedExpert,
    disabledSubmitButton,
    showPreloader,
    closePopup,
    submitPopup,
    onExpertSelection,
}: Props): JSX.Element => (
    <Popup onOutOfContentClick={closePopup}>
        <div
            className={style.root}
            {...{
                'qa-id': 'budgetExpertsPopup',
            }}
        >
            <div className={style.titleWrapper}>
                <div className={style.title}>Укажите согласующего корректировки</div>

                <div
                    className={style.closeButton}
                    onClick={closePopup}
                    {...{
                        'qa-id': 'budgetExpertsPopupCloseButton',
                    }}
                >
                    <Icon type={IconType.CROSS} />
                </div>
            </div>

            <div className={style.selection}>
                <LabeledSelect
                    qaId="BudgetExpertsPopupDropdown"
                    title={'Эксперт по бюджетированию'}
                    items={experts}
                    selectedValue={selectedExpert}
                    onSelection={(value) => onExpertSelection(value as string)}
                />
            </div>

            <Button
                onClick={submitPopup}
                disabled={disabledSubmitButton}
                style={{ height: '30px' }}
                qaId="budgetExpertsPopupSubmitButton"
            >
                Отправить
            </Button>

            {showPreloader && <Preloader />}
        </div>
    </Popup>
);
