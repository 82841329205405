import * as React from 'react';

import * as style from './TextColumnHeader.scss';

import { WithTooltip } from 'sber-marketing-ui';

interface Props {
    title: string;
}

export const TextColumnHeader = ({ title }: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <WithTooltip content={title}>
                <div className={style.title}>{title}</div>
            </WithTooltip>
        </div>
    );
};
