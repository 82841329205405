import { axios } from '../lib/axios';
import type {
    GetActivitiesDataForLeaderDashboardRequestDto,
    GetActivityDataForLeaderDashboardResponseDto,
    GetTasksDataForLeaderDashboardRequestDto,
    GetTaskDataForLeaderDashboardResponseDto,
    GetDepartmentRatesRequestDto,
    GetDepartmentRatesResponseDto,
} from '@mrm-analytics-service/api-client';

const BASE_URL = '/api/leader-dashboard';

export class LeaderDashboardApi {
    public static async getActivitiesData(
        params: Omit<GetActivitiesDataForLeaderDashboardRequestDto, 'users'>,
    ): Promise<GetActivityDataForLeaderDashboardResponseDto> {
        const res = await axios.get(`${BASE_URL}/activity`, { params });
        return res.data;
    }

    public static async getTasksData(
        params: Omit<GetTasksDataForLeaderDashboardRequestDto, 'users'>,
    ): Promise<GetTaskDataForLeaderDashboardResponseDto> {
        const res = await axios.get(`${BASE_URL}/task`, { params });
        return res.data;
    }

    public static async getRate(
        params: Omit<GetDepartmentRatesRequestDto, 'users'>,
    ): Promise<GetDepartmentRatesResponseDto> {
        const res = await axios.get(`${BASE_URL}/rate`, { params });
        return res.data;
    }
}
