import { createSelector } from 'reselect';
import { UserConfigType } from 'sber-marketing-types/openid';

import { LoadingStatus } from '@store/commonTypes';
import { UserConfigState } from '@store/userConfig';
import { getUserConfigState } from '../selectors';

import type { CreativeUserConfigState, CreativeUserConfig } from './types';

export const getCreativeUserConfigState = createSelector(
    getUserConfigState,
    (state: UserConfigState): CreativeUserConfigState => state[UserConfigType.Creative],
);

export const getCreativeUserConfig = createSelector(
    getCreativeUserConfigState,
    (state: CreativeUserConfigState): CreativeUserConfig => state.fields,
);

export const getLoadingStatus = createSelector(
    getCreativeUserConfigState,
    (state: CreativeUserConfigState): LoadingStatus => state.status,
);
