// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-dashboard-CardsList-CardGroup-Switch-_Switch__root{display:flex;align-items:center}.src-client-modules-dashboard-CardsList-CardGroup-Switch-_Switch__title{cursor:pointer;user-select:none;letter-spacing:-0.25px;color:#a1a6a3;font-family:'Open Sans';font-size:12px}.src-client-modules-dashboard-CardsList-CardGroup-Switch-_Switch__title.src-client-modules-dashboard-CardsList-CardGroup-Switch-_Switch__selected{cursor:auto;color:#000000}.src-client-modules-dashboard-CardsList-CardGroup-Switch-_Switch__toggleWrapper{margin:0 8px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/dashboard/CardsList/CardGroup/Switch/Switch.scss"],"names":[],"mappings":"AAAA,uEACI,YAAa,CAEb,kBAAmB,CACtB,wEAGG,cAAe,CACf,gBAAiB,CACjB,sBAAuB,CAEvB,aAAc,CAEd,uBAAwB,CACxB,cAAe,CARnB,kJAWQ,WAAY,CAEZ,aAAc,CACjB,gFAID,YAAa","sourcesContent":[".root {\n    display: flex;\n\n    align-items: center;\n}\n\n.title {\n    cursor: pointer;\n    user-select: none;\n    letter-spacing: -0.25px;\n\n    color: #a1a6a3;\n\n    font-family: 'Open Sans';\n    font-size: 12px;\n\n    &.selected {\n        cursor: auto;\n\n        color: #000000;\n    }\n}\n\n.toggleWrapper {\n    margin: 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-dashboard-CardsList-CardGroup-Switch-_Switch__root",
	"title": "src-client-modules-dashboard-CardsList-CardGroup-Switch-_Switch__title",
	"selected": "src-client-modules-dashboard-CardsList-CardGroup-Switch-_Switch__selected",
	"toggleWrapper": "src-client-modules-dashboard-CardsList-CardGroup-Switch-_Switch__toggleWrapper"
};
export default ___CSS_LOADER_EXPORT___;
