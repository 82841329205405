// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-CreativeStatusesStageWidget-Table-TableView-Cursor-_Cursor__root{height:100%;width:100%;position:relative}.src-client-modules-activity-pages-autopilot-StageWidgets-CreativeStatusesStageWidget-Table-TableView-Cursor-_Cursor__mask{position:fixed;z-index:9;top:0;right:0;bottom:0;left:48px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/CreativeStatusesStageWidget/Table/TableView/Cursor/Cursor.scss"],"names":[],"mappings":"AAAA,2HACI,WAAY,CACZ,UAAW,CACX,iBAAkB,CACrB,2HAGG,cAAe,CACf,SAAU,CACV,KAAM,CACN,OAAQ,CACR,QAAS,CACT,SAAU","sourcesContent":[".root {\n    height: 100%;\n    width: 100%;\n    position: relative;\n}\n\n.mask {\n    position: fixed;\n    z-index: 9;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-CreativeStatusesStageWidget-Table-TableView-Cursor-_Cursor__root",
	"mask": "src-client-modules-activity-pages-autopilot-StageWidgets-CreativeStatusesStageWidget-Table-TableView-Cursor-_Cursor__mask"
};
export default ___CSS_LOADER_EXPORT___;
