// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-dashboard-RightSidebar-BondActivitiesWithBudgetItemId-_BondActivitiesWithBudgetItemId__root{padding:16px 24px;border-bottom:1.3px solid #e3e9e5;font-family:\"Open Sans\"}.src-client-modules-dashboard-RightSidebar-BondActivitiesWithBudgetItemId-_BondActivitiesWithBudgetItemId__title{margin-bottom:11px;user-select:none;font-size:14px;font-weight:600}.src-client-modules-dashboard-RightSidebar-BondActivitiesWithBudgetItemId-_BondActivitiesWithBudgetItemId__descriptionWrapper{margin-top:8px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/dashboard/RightSidebar/BondActivitiesWithBudgetItemId/BondActivitiesWithBudgetItemId.scss"],"names":[],"mappings":"AAAA,gHACI,iBAAkB,CAElB,iCAAkC,CAElC,uBAAwB,CAC3B,iHAGG,kBAAmB,CAEnB,gBAAiB,CAEjB,cAAe,CACf,eAAgB,CACnB,8HAGG,cAAe","sourcesContent":[".root {\n    padding: 16px 24px;\n\n    border-bottom: 1.3px solid #e3e9e5;\n\n    font-family: \"Open Sans\";\n}\n\n.title {\n    margin-bottom: 11px;\n\n    user-select: none;\n\n    font-size: 14px;\n    font-weight: 600;\n}\n\n.descriptionWrapper {\n    margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-dashboard-RightSidebar-BondActivitiesWithBudgetItemId-_BondActivitiesWithBudgetItemId__root",
	"title": "src-client-modules-dashboard-RightSidebar-BondActivitiesWithBudgetItemId-_BondActivitiesWithBudgetItemId__title",
	"descriptionWrapper": "src-client-modules-dashboard-RightSidebar-BondActivitiesWithBudgetItemId-_BondActivitiesWithBudgetItemId__descriptionWrapper"
};
export default ___CSS_LOADER_EXPORT___;
