import * as React from 'react';
import classNames from 'classnames';

import * as style from './TextWithSortingColumnHeader.scss';

import { WithTooltip, Icon, IconType } from 'sber-marketing-ui';
import { OrderType } from '../../types';

interface Props {
    title: string;
    sortingOrder: OrderType;
    onClick: () => void;
}

export const TextWithSortingColumnHeader = ({ title, sortingOrder, onClick }: Props): JSX.Element => {
    return (
        <div className={style.root} onClick={onClick}>
            <WithTooltip content={title}>
                <div className={style.title}>{title}</div>
            </WithTooltip>

            <Icon
                className={classNames(
                    style.sortingIcon,
                    !!sortingOrder && style.enabled,
                    sortingOrder === OrderType.Asc && style.sortedAsc,
                )}
                type={IconType.SORTING}
                svgSize={12}
            />
        </div>
    );
};
