import * as React from 'react';

import * as styles from './CommentAssets.scss';
import { CommentAssetsHeader } from '../CommentAssetsHeader';
import { Assets, AssetsProps } from '../Assets';

export interface CommentAssetsProps extends AssetsProps {}

export type CommentAssetsOpenContext = [boolean, (value: boolean) => void];

export const commentAssetsOpen = React.createContext<CommentAssetsOpenContext>(null);
const { Provider } = commentAssetsOpen;

export const CommentAssetOpenProvider: React.FC = ({ children }) => {
    const value = React.useState(false);

    return <Provider value={value}>{children}</Provider>;
};

export const useCommentAssetsOpen = () => {
    const context = React.useContext(commentAssetsOpen);

    if (!context) {
        throw Error('You cannot use useCommentAssetsOpen outside of CommentAssetOpenProvider');
    }

    return context;
};

export const CommentAssets: React.FC<CommentAssetsProps> = (props) => {
    const [isOpenedAssets, setIsOpenedAssets] = useCommentAssetsOpen();
    const handleOpenToggle = () => setIsOpenedAssets(!isOpenedAssets);

    const header = !props.showByDefault && (
        <CommentAssetsHeader
            commentId={props.commentId}
            taskId={props.taskId}
            open={isOpenedAssets}
            onToggle={handleOpenToggle}
        />
    );

    return (
        <div className={styles.root}>
            {header}
            {isOpenedAssets && <Assets {...props} />}
        </div>
    );
};
