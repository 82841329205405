// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-tags-TagsPage-Graph-_Graph__root{width:100%;height:100%}\n", "",{"version":3,"sources":["webpack://./src/client/modules/tags/TagsPage/Graph/Graph.scss"],"names":[],"mappings":"AAAA,qDACI,UAAW,CACX,WAAY","sourcesContent":[".root {\n    width: 100%;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-tags-TagsPage-Graph-_Graph__root"
};
export default ___CSS_LOADER_EXPORT___;
