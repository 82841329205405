import * as React from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { DAMArchiveStatus } from '@mrm/dam';
import { Icon, IconType, WithTooltip, TooltipAnchor, StaticSkeleton } from 'sber-marketing-ui';

import { StoreState } from '@store';
import {
    ArchiveContentPopupComponentState,
    shouldRenderArchiveControls,
    getActivityDAMArchiveState,
    showPreloader,
    getArchiveFiles,
    getTotalFiles,
    setArchiveContentPopupState,
} from '@store/activityFilesDAMArchive';

import * as styles from './FilesInfo.scss';

function useFilesInfo() {
    const dispatch = useDispatch();

    const shouldRender = useSelector((state: StoreState) => shouldRenderArchiveControls(state));

    const hasArchive = useSelector(
        (state: StoreState) => getActivityDAMArchiveState(state).archiveStatus?.status === DAMArchiveStatus.Connected,
    );
    const preloaderIsVisible = useSelector((state: StoreState) => showPreloader(state));

    const archiveFiles = useSelector((state: StoreState) => getArchiveFiles(state));
    const totalFiles = useSelector((state: StoreState) => getTotalFiles(state));

    function onClick() {
        dispatch(setArchiveContentPopupState(ArchiveContentPopupComponentState.ViewContent));
    }

    return {
        shouldRender,
        hasArchive,
        preloaderIsVisible,
        onClick,
        archiveFiles,
        totalFiles,
    };
}

export function FilesInfo(): JSX.Element {
    const { shouldRender, hasArchive, preloaderIsVisible, ...props } = useFilesInfo();

    if (preloaderIsVisible) {
        return <Preloader />;
    }

    if (!shouldRender) {
        return null;
    }

    const renderWithArchive = hasArchive && props.archiveFiles;

    return renderWithArchive ? <WithArchive {...props} /> : <WithoutArchive {...props} />;
}

function Preloader(): JSX.Element {
    return (
        <div className={styles.root}>
            <div className={styles.iconsWrapper}>
                <Icon type={IconType.DAM_ARHIVE_FOLER_ICON} svgSize={48} className={styles.folderIcon} />
            </div>

            <StaticSkeleton className={styles.skeleton1} />
            <StaticSkeleton className={styles.skeleton2} />
        </div>
    );
}

interface RenderProps {
    archiveFiles: number;
    totalFiles: number;
    onClick: () => void;
}

function WithoutArchive(props: RenderProps): JSX.Element {
    return (
        <div className={styles.root}>
            <div className={styles.iconsWrapper}>
                <Icon type={IconType.DAM_ARHIVE_FOLER_ICON} svgSize={48} className={styles.folderIcon} />
            </div>

            <Info {...props} />
        </div>
    );
}

function WithArchive({ onClick, ...props }: RenderProps): JSX.Element {
    return (
        <WithTooltip anchor={TooltipAnchor.TOP} content="Посмотреть файлы, загруженные в архив">
            <div className={classnames(styles.root, styles.rootClickable)} onClick={onClick}>
                <div className={styles.iconsWrapper}>
                    <Icon type={IconType.DAM_ARHIVE_FOLER_ICON} svgSize={48} className={styles.folderIcon} />

                    <Icon type={IconType.EYE_OPENED} svgSize={24} className={styles.eyeIcon} />
                </div>

                <Info {...props} />
            </div>
        </WithTooltip>
    );
}

type InfoProps = Pick<RenderProps, 'archiveFiles' | 'totalFiles'>;

function Info({ archiveFiles, totalFiles }: InfoProps): JSX.Element {
    return (
        <React.Fragment>
            {archiveFiles} / {totalFiles}
            <br />
            файлов
        </React.Fragment>
    );
}
