import * as React from 'react';
import { Popup, PopupTheme, Preloader } from 'sber-marketing-ui';

import { Header } from './Header';
import { Footer } from './Footer';

import * as style from './ActivityPopup.scss';

export interface Props {
    title: string;
    activityId: number;
    activityName: string;
    loading: boolean;
    onCloseButtonClick(): void;
    onUnbindButtonClick(): void;
    onCancelButtonClick(): void;
}

export const UnbindActivityPopup: React.FC<Props> = ({
    title,
    activityId,
    activityName,
    loading,
    onCloseButtonClick,
    onCancelButtonClick,
    onUnbindButtonClick,
}) => {
    const linkTo = `/activity/${activityId}`;

    return (
        <Popup theme={PopupTheme.bindActivityTheme}>
            <div className={style.root}>
                <div className={style.headerWrapper}>
                    <Header titleText={title} onCloseButtonClick={onCloseButtonClick} />
                </div>

                <div className={style.contentWrapper}>
                    Уверены, что хотите отвязать&nbsp;
                    <a className={style.link} href={linkTo} target="_blank">
                        {activityName}
                    </a>
                    &nbsp; от данной строки?
                </div>

                <div className={style.footerWrapper}>
                    <Footer onUnbindButtonClick={onUnbindButtonClick} onCancelButtonClick={onCancelButtonClick} />
                </div>

                {loading && <Preloader />}
            </div>
        </Popup>
    );
};
