import * as React from 'react';
import classNames from 'classnames';
import { CustomScrollbar } from 'sber-marketing-ui';

import * as styles from './TaskSideForm.scss';

import { TaskParticipants } from '@common/TaskParticipants';
import { TaskBudgetApproval } from '@common/TaskBudgetApproval';

import { TaskExecutor } from '../../TaskExecutor';
import { TaskEstimator } from '../TaskEstimator';
import { AllAssets } from '../AllAssets';

export interface TaskSideFormProps {
    taskId: string;
    className?: string;
    isBaseChannel?: boolean;
    selectedParticipants: {
        userId: number;
        canRemove: boolean;
    }[];
    visibilityEstimator: boolean;
    tagsEditorId: string;
    onParticipantsChange: (
        participants: {
            userId: number;
            canRemove: boolean;
        }[],
    ) => void;
    onParticipantClick: (userId: number) => void;
}

export function TaskSideForm({
    taskId,
    className,
    isBaseChannel,
    selectedParticipants,
    visibilityEstimator,
    tagsEditorId,
    onParticipantsChange,
    onParticipantClick,
}: TaskSideFormProps): JSX.Element {
    return (
        <div
            className={classNames(styles.root, className)}
            {...{
                'qa-id': 'taskSideMenu',
            }}
        >
            <CustomScrollbar autoHide hideTracksWhenNotNeeded>
                <div className={styles.internalWrap}>
                    {visibilityEstimator && <TaskEstimator taskId={taskId} />}

                    <TaskExecutor taskId={taskId} tagsEditorId={tagsEditorId} className={styles.taskExecutor} />

                    <TaskParticipants
                        participants={selectedParticipants}
                        onParticipantsChange={onParticipantsChange}
                        onParticipantClick={onParticipantClick}
                    />

                    <AllAssets mode="base" className={styles.assets} />

                    {!isBaseChannel && <AllAssets mode="current" className={styles.assets} />}

                    <TaskBudgetApproval />
                </div>
            </CustomScrollbar>
        </div>
    );
}
