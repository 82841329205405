import * as React from 'react';
import { difference } from 'lodash';

import { AppliedFiltersNames, FilterKey } from '@store/budgetPlanning';
import { ContentTemplate } from './ContentTemplate';

interface Props {
    columnNames: FilterKey[];
    onApplyFiltersButtonClick: () => void;
}

export class ContentBehavior extends React.Component<Props> {
    private readonly prevColumnNames: AppliedFiltersNames;

    constructor(props: Props) {
        super(props);

        this.prevColumnNames = props.columnNames;
    }

    public render() {
        return React.createElement(ContentTemplate, {
            columnNames: this.buildColumnNames(),
            onApplyFiltersButtonClick: this.props.onApplyFiltersButtonClick,
        });
    }

    private buildColumnNames(): AppliedFiltersNames {
        const unusedFiltersNames = difference(this.prevColumnNames, this.props.columnNames);

        return [...this.props.columnNames, ...unusedFiltersNames];
    }
}
